import { mapGetters } from 'vuex'
import {powerWords} from "./PowerWords"
const SeoScoreMixin = {
  computed: {
    ...mapGetters(['getBlogSubmissionSelection', 'getBlogEditorSelector'])
  },
  methods: {
    /**
     * Title Length, words count,
     * and check keyword exist in title
     */

    seoTitleNumbers(){
      var regex = /\d+/g;
      if(this.getBlogTitle()===''){
        this.title_Nub = 0
        this.title_bar_checks.number = false;
      }
      var matches =  this.getBlogTitle().match(regex);
      if(matches){
        if (!this.title_bar_checks.number) {
          this.title_Nub = 0.033333333
          this.title_bar_checks.number = true;
      }
    }else if(matches===null){
        this.title_Nub = 0
        this.title_bar_checks.number = false;
    }
     },
    seoTitleLength () {
      if (
        this.getTextLength(this.getBlogTitle()) >= 50 &&
        this.getTextLength(this.getBlogTitle()) <= 60
      ) {
        if (!this.title_bar_checks.character) {
          this.title_bar += 0.1
          this.title_bar_checks.character = true
        }
      } else {
        if (this.title_bar_checks.character) {
          this.title_bar -= 0.1
          this.title_bar_checks.character = false
        }
      }
    },
    // seoTitleWordLength () {
    //   if (
    //     this.getTextWordsLength(this.getBlogTitle()) >= 4 &&
    //     this.getTextWordsLength(this.getBlogTitle()) <= 16
    //   ) {
    //     if (!this.title_bar_checks.word) {
    //       this.title_bar += 0.2
    //       this.title_bar_checks.word = true
    //     }
    //   } else {
    //     if (this.title_bar_checks.word) {
    //       this.title_bar -= 0.2
    //       this.title_bar_checks.word = false
    //     }
    //   }
    // },
    seoTitleKeyword () {
      if (
        this.getTextLength(
          this.getBlogSubmissionSelection.keyword.toLowerCase()
        ) >= 1 &&
        this.checkStringExist(
          this.getBlogSubmissionSelection.keyword.toLowerCase(),
          this.getBlogTitle()
        ) > 0
      ) {
        if (!this.title_bar_checks.keyword) {
          this.title_bar += 0.633
          this.title_bar_checks.keyword = true
        }
      } else {
        if (this.title_bar_checks.keyword) {
          this.title_bar -= 0.633
          this.title_bar_checks.keyword = false
        }
      }
    },

    /**
     * Description Length, words count,
     * and check keyword exist in title
     */
    // seoDescriptionLength () {
    //   console.log("getBlogDescription",this.getBlogPostingDetails());
    //   if (
    //     this.getTextLength(this.getBlogDescription()) >= 120 &&
    //     this.getTextLength(this.getBlogDescription()) <= 155
    //   ) {
    //     if (!this.desc_bar_checks.character) {
    //       this.desc_bar += 0.1
    //       this.desc_bar_checks.character = true
    //     }
    //   } else {
    //     if (this.desc_bar_checks.character) {
    //       this.desc_bar -= 0.1
    //       this.desc_bar_checks.character = false
    //     }
    //   }
    // },
    seoDescriptionWordLength () {
      if (
        this.getTextWordsLength(this.getBlogDescription()) >= 2 &&
        this.getTextWordsLength(this.getBlogDescription()) <= 50
      ) {
        if (!this.desc_bar_checks.word) {
          this.desc_bar += 0.2
          this.desc_bar_checks.word = true
        }
      } else {
        if (this.desc_bar_checks.word) {
          this.desc_bar -= 0.2
          this.desc_bar_checks.word = false
        }
      }
    },
    seoDescriptionKeyword () {
      if (
        this.getTextLength(
          this.getBlogSubmissionSelection.keyword.toLowerCase()
        ) >= 1 &&
        this.checkStringExist(
          this.getBlogSubmissionSelection.keyword.toLowerCase(),
          this.getBlogDescription()
        ) > 0
      ) {
        if (!this.desc_bar_checks.keyword) {

          this.desc_bar += 0.13
          this.desc_bar_checks.keyword = true
        }
      } else {
        if (this.desc_bar_checks.keyword) {
          this.desc_bar -= 0.13
          this.desc_bar_checks.keyword = false
        }
      }
    },

    /**
     * Heading H2 tags count,
     * H2 Keyword, H2 Words count,
     * H3-to-H6 count, H3-to-H6 KeyWord Exist
     */
    seoHeadingH2Tags () {
      if (this.h2_tags > 0) {
        if (!this.heading_bar_checks.h2_tags) {
          this.h2_bar = 0.5
          this.heading_bar_checks.h2_tags = true
        }
      } else {
        if (this.heading_bar_checks.h2_tags) {
          this.h2_bar -= 0.5
          this.heading_bar_checks.h2_tags = false
        }
      }
    },
    seoHeadingH3Tags () {
      if (this.h3_tags > 0) {
        if (!this.heading_bar_checks.h3_tags) {
          this.h3_bar = 0.25
          this.heading_bar_checks.h3_tags = true
        }
      } else {
        if (this.heading_bar_checks.h3_tags) {
          this.h3_bar -= 0.25
          this.heading_bar_checks.h3_tags = false
        }
      }
    },
    // seoHeadingH2Keywords () {
    //   if (this.h2_keywords > 0) {
    //     if (!this.heading_bar_checks.h2_keywords) {
    //       this.heading_bar += 0.25
    //       this.heading_bar_checks.h2_keywords = true
    //     }
    //   } else {
    //     if (this.heading_bar_checks.h2_keywords) {
    //       this.heading_bar = 0
    //       this.heading_bar_checks.h2_keywords = false
    //     }
    //   }
    // },
    // seoHeadingH3Keywords () {
    //   if (this.h3_keywords > 0) {
    //     if (!this.heading_bar_checks.h3_keywords) {
    //       this.heading_bar += 0.25
    //       this.heading_bar_checks.h3_keywords = true
    //     }
    //   } else {
    //     if (this.heading_bar_checks.h3_keywords) {
    //       this.heading_bar = 0
    //       this.heading_bar_checks.h3_keywords = false
    //     }
    //   }
    // },
    seoHeadingH2h3Keywords(){
      if (this.h2_keywords > 0 || this.h3_keywords > 0 ) {
        if (!this.heading_bar_checks.h3_keywords && !this.heading_bar_checks.h2_keywords ) {
          this.heading_bar += 0.25
          this.heading_bar_checks.h3_keywords = true
          this.heading_bar_checks.h2_keywords = true
        }
      } else {
        if (this.heading_bar_checks.h3_keywords || this.heading_bar_checks.h2_keywords) {
          this.heading_bar = 0
          this.heading_bar_checks.h3_keywords = false
          this.heading_bar_checks.h2_keywords = false
        }
      }
    },


    // seoHeadingH2Words () {
    //   if (this.h2_words === 'ok') {
    //     if (!this.heading_bar_checks.h2_words) {
    //       this.heading_bar += 0.2
    //       this.heading_bar_checks.h2_words = true
    //     }
    //   } else {
    //     if (this.heading_bar_checks.h2_words) {
    //       this.heading_bar -= 0.2
    //       this.heading_bar_checks.h2_words = false
    //     }
    //   }
    // },
    // seoHeadingH3ToH6 () {
    //   if (this.h3Toh6 > 0) {

    //     if (!this.heading_bar_checks.h3Toh6) {
    //       this.heading_bar += 0.2
    //       this.heading_bar_checks.h3Toh6 = true
    //     }
    //   } else {
    //     if (this.heading_bar_checks.h3Toh6) {
    //       this.heading_bar -= 0.2
    //       this.heading_bar_checks.h3Toh6 = false
    //     }
    //   }
    // },
    // seoHeadingH3ToH6Keywords () {
    //   if (this.h3Toh6_keywords > 0) {
    //     if (!this.heading_bar_checks.h3Toh6_keywords) {
    //       this.heading_bar += 0.25
    //       this.heading_bar_checks.h3Toh6_keywords = true
    //     }
    //   } else {
    //     if (this.heading_bar_checks.h3Toh6_keywords) {
    //       this.heading_bar -= 0.25
    //       this.heading_bar_checks.h3Toh6_keywords = false
    //     }
    //   }
    // },
    seoHeadingH2ToH4Keywords () {
      if (this.h2Toh4_keywords > 0) {
        if (!this.heading_bar_checks.h20Toh4_keywords) {
          this.heading_bar = 0.25
          this.heading_bar_checks.h2Toh4_keywords = true
        }
      } else {
        if (this.heading_bar_checks.h2Toh4_keywords) {
          this.heading_bar = 0
          this.heading_bar_checks.h2Toh4_keywords = false
        }
      }
    },

    /**
     * Content Seo Total count,
     * Content Density, check Seo Keyword use in first 50 words
     * Check Seo KeyWord Use in Image Alt Tag
     * Check Content contain any Bold tag
     * check Content Contain Keyword Bold Tag
     */
    seoContentTotalCount () {
      if (this.total_content >= 1500) {
        if (!this.content_bar_checks.total_content) {
          this.content_bar += 0.125
          this.content_bar_checks.total_content = true
        }
      } else {
        if (this.content_bar_checks.total_content) {
          this.content_bar -= 0.125
          this.content_bar_checks.total_content = false
        }
      }
    },
    seoContentDensity () {
      if (
        this.content_keyword >= this.min_range &&
        this.content_keyword <= this.max_range &&
        this.max_range !== 0 &&
        this.min_range !== 0
      ) {
        if (!this.content_bar_checks.content_keyword) {
          this.miscellaneous_bar += 0.153
          this.content_bar_checks.content_keyword = true
        }
      } else {
        if (this.content_bar_checks.content_keyword) {
          this.miscellaneous_bar -=0.153
          this.content_bar_checks.content_keyword = false
        }
      }
    },
    seoContentKeywordUseInStart () {
      if (this.in_all_content > 0) {
        if (!this.content_bar_checks.in_all_content) {
           this.content_bar += 0.5625
          this.content_bar_checks.in_all_content = true
        }
      } else {
        if (this.content_bar_checks.in_all_content) {
           this.content_bar -= 0.5625
          this.content_bar_checks.in_all_content = false
        }
      }
    },
    seoContentKeywordUseInTenPercent () {
      if (this.in_first_10_content > 0) {
        if (!this.content_bar_checks.in_first_10_keyword) {
          this.content_bar += 0.3125
          this.content_bar_checks. in_first_10_keyword = true
        }
      } else {
        if (this.content_bar_checks.in_first_10_keyword) {
          this.content_bar -= 0.3125
          this.content_bar_checks. in_first_10_keyword = false
        }
      }
    },
    seoContentUrls () {
      if(this.getBlogSubmissionSelection.url && this.getBlogSubmissionSelection.url.length <=75 && this.getBlogSubmissionSelection.url.length !==0){
        if (!this.content_bar_checks.url_char) {
               this.Url_bar += 0.2727
            this.content_bar_checks.url_char = true
            this.url_char=0
        }

      }else{
        if (this.content_bar_checks.url_char) {
             this.Url_bar -= 0.2727
             this.content_bar_checks.url_char = false
             this.url_char=1
        }
      }
      const test= this.getBlogSubmissionSelection.url && this.getBlogSubmissionSelection.url.toLowerCase().split('.')
      const abc= this.getBlogSubmissionSelection.keyword && this.getBlogSubmissionSelection.keyword.toLowerCase().trim().split(' ')
      const intersection = test && test.filter(element => abc.includes(element));
      if(intersection && intersection.length){
        if (!this.content_bar_checks.target_url) {
           this.Url_bar += 0.7272
           this.content_bar_checks.target_url = true
          }
      }else{
        if (this.content_bar_checks.target_url) {
           this.Url_bar -= 0.7272
          this.content_bar_checks.target_url = false
         }
      }
    },
    seoContentKeywordInAlt () {
      if (this.keyword_in_alt > 0) {
        if (!this.content_bar_checks.keyword_in_alt) {
          this.media_bar += 0.4
          this.content_bar_checks.keyword_in_alt = true
        }
      } else {
        if (this.content_bar_checks.keyword_in_alt) {
          this.media_bar -= 0.4
          this.content_bar_checks.keyword_in_alt = false
        }
      }
    },
    seoContentMoreImages (){
      if (this.more_images > 0) {
        if (!this.content_bar_checks.more_images) {
          this.media_bar += 0.2
          this.content_bar_checks.more_images = true
        }
      } else {
        if (this.content_bar_checks.more_images) {
          this.media_bar -= 0.2
          this.content_bar_checks.more_images = false
        }
      }
    },
    seoContentBoldTag () {
      if (this.bold_tag > 0 && this.bold_tag <= 11) {
        if (!this.content_bar_checks.bold_tag) {
          this.content_bar += 0.1
          this.content_bar_checks.bold_tag = true
        }
      } else {
        if (this.content_bar_checks.bold_tag) {
          this.content_bar -= 0.1
          this.content_bar_checks.bold_tag = false
        }
      }
    },
    seoContentBoldKeyword () {
      if (this.bold_keyword > 0) {
        if (!this.content_bar_checks.bold_keyword) {
          this.content_bar += 0.1
          this.content_bar_checks.bold_keyword = true
        }
      } else {
        if (this.content_bar_checks.bold_keyword) {
          this.content_bar -= 0.1
          this.content_bar_checks.bold_keyword = false
        }
      }
    },
    seoFeaturedImage (){
      if(this.getBlogPostingDetails.image.link){
        if (!this.content_bar_checks.featured_image) {
            this.media_bar += 0.4
            this.content_bar_checks.featured_image = true
          }
        }else{
            if (this.content_bar_checks.featured_image) {
              this.media_bar -= 0.4
              this.content_bar_checks.featured_image = false
            }
        }
    },

    checkTitleKeywords(){
      //  console.log("am working fine");
    },
    checkTitleKeywordsInTitle (){
      let title=this.getBlogTitle().split(' ')[0]
      if (
        this.checkStringExist(
          this.getBlogSubmissionSelection.keyword.toLowerCase(),
            title
        ) > 0
      ) {
        if (!this.content_bar_checks.target_keyword) {
          this.readability_bar += 0.416666667
          this.content_bar_checks.target_keyword = true
        }
      }
      else {
        if (this.content_bar_checks.target_keyword) {
          this.readability_bar -= 0.416666667
          this.content_bar_checks.target_keyword = false
        }
      }

    },

    checkTitleKeyPowerWords(){
      let title=this.getBlogTitle().trim().split(' ')
      const lowerWords = powerWords.map(element => {
        return element.toLowerCase();
      });
      const intersection = lowerWords.filter(element => title.includes(element));
      if(intersection.length){
        if (!this.content_bar_checks.power_words) {
                this.power_bar = 0.166666667
                this.content_bar_checks.power_words = true
          }
      }else{
        if (this.content_bar_checks.power_words) {
                this.power_bar -= 0.166666667
                this.content_bar_checks.power_words = false
         }
      }
    },

    // italic

    seoContentItalicTag () {
      if (this.italic_tag > 0) {
        if (!this.miscellaneous_bar_checks.italic_tag) {
          this.miscellaneous_bar += 0.5
          this.miscellaneous_bar_checks.italic_tag = true
        }
      } else {
        if (this.miscellaneous_bar_checks.italic_tag) {
          this.miscellaneous_bar -= 0.5
          this.miscellaneous_bar_checks.italic_tag = false
        }
      }
    },
    seoContentItalicKeyword () {
      if (this.italic_keyword > 0) {
        if (!this.miscellaneous_bar_checks.italic_keyword) {
          this.miscellaneous_bar += 0.5
          this.miscellaneous_bar_checks.italic_keyword = true
        }
      } else {
        if (this.miscellaneous_bar_checks.italic_keyword) {
          this.miscellaneous_bar -= 0.5
          this.miscellaneous_bar_checks.italic_keyword = false
        }
      }
    },

    // page scores methods start

    title_progress () {
      this.seoTitleLength()
      this.seoTitleKeyword()
      this.seoTitleNumbers()
    },

    description_progress () {
      // this.seoDescriptionLength()
      this.seoDescriptionKeyword()
    },

    heading_progress () {
      this.seoHeadingH2Tags()
      this.seoHeadingH3Tags()
      // this.seoHeadingH2Keywords()
      // this.seoHeadingH3Keywords()
      this.seoHeadingH2h3Keywords()
    },

    content_process () {
      this.seoContentTotalCount()
      this.seoContentDensity()
      this.seoContentKeywordUseInStart()
      this.seoContentKeywordUseInTenPercent()
      this.seoContentKeywordInAlt()
      this.seoContentMoreImages()
      // this.seoContentBoldTag()
       this.checkTitleKeywords()
      this.seoContentBoldKeyword()
    },
    optionalDescription_process(){
    if (
      this.getTextLength(this.getBlogPostingDetails.optionalDescription) >= 120 &&
      this.getTextLength(this.getBlogPostingDetails.optionalDescription) <= 155
    ) {
      if (!this.desc_bar_checks.character) {
        this.desc_bar += 0.1
        this.desc_bar_checks.character = true
      }
    } else {
      if (this.desc_bar_checks.character) {
        this.desc_bar -= 0.1
        this.desc_bar_checks.character = false
      }
    }
    },
    url_process(){
      this.seoContentUrls()
    },
    featured_image_process(){
      this.seoFeaturedImage()
    },
    content_ReadabilityProcess(){
       this.checkTitleKeywordsInTitle()
       this.checkTitleKeyPowerWords()
    },
    media_contentProcess(){

    },

    miscellaneous_process () {
      this.seoContentItalicTag()
      this.seoContentItalicKeyword()
    },

    main_process () {
      let mainBarValue = ((this.title_bar+this.desc_bar+this.title_Nub) * 30) / 100
      mainBarValue += ((this.heading_bar+this.h3_bar+this.h2_bar) * 8) / 100
      mainBarValue += (this.content_bar * 16) / 100
      mainBarValue += ((this.readability_bar + this.power_bar) * 12) / 100
      mainBarValue += (this.media_bar * 10) / 100
      mainBarValue += (this.Url_bar * 11) / 100
      mainBarValue += ((this.miscellaneous_bar+this.external_resources_bar) * 13) / 100
      this.main_bar = mainBarValue
      // this.Circle.animate(this.main_bar)
    },

    /**
     * Content Dropzone methods,
     * h2 tags count, h3toh6 tags count,
     * tags contents,
     * Content Density,
     * images tags processing and bold tags processing
     */
    h2TagsKeywords (me, innerHtml) {
      // for h2 tags length
      me.h2_tags = innerHtml.getElementsByTagName('h2').length
      // for second check h2 contain keyword
      if (me.h2_tags === 0) {
        me.h2_keywords = 0
        me.h2_words = 'minimum'
      } else {
        let allH2KeywordExist = 0
        const allH2Tags = innerHtml.getElementsByTagName('h2')
        const h2WordsCount = []
        for (let i = 0; i < allH2Tags.length; i++) {
          allH2KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH2Tags[i].innerText
          )
          const str = allH2Tags[i].innerText.trim()
          try {
            str
              ? h2WordsCount.push(str.match(/(\w+)/g).length)
              : h2WordsCount.push(0)
          } catch (m) {
            h2WordsCount.push(0)
          }
        }
        me.h2_keywords = allH2KeywordExist
        // for third check h2 contain words minimum maximum and number of words etc
        let totalWordsH2 = 0
        let valueCheck = false
        for (let i = 0; i < h2WordsCount.length; i++) {
          totalWordsH2 += h2WordsCount[i]
          if (valueCheck) continue
          if (h2WordsCount[i] === 0) {
            me.h2_words = 'minimum'
            valueCheck = true
            continue
          }
          if (h2WordsCount[i] > 16) {
            me.h2_words = 'maximum'
            valueCheck = true
            continue
          }
          if (h2WordsCount[i] > 0 && h2WordsCount[i] <= 16) me.h2_words = 'ok'
        }
        me.h2_total = totalWordsH2
      }
    },
    h3TagsKeywords (me, innerHtml) {
      // for h2 tags length
      me.h3_tags = innerHtml.getElementsByTagName('h3').length
      // for second check h2 contain keyword
      if (me && me.h3_tags === 0) {
        me.h3_keywords = 0
        me.h3_words = 'minimum'
      } else {
        let allH3KeywordExist = 0
        const allH3Tags = innerHtml.getElementsByTagName('h3')
        const h3WordsCount = []
        for (let i = 0; i < allH3Tags.length; i++) {
          allH3KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH3Tags[i].innerText
          )
          const str = allH3Tags[i].innerText.trim()
          try {
            str
              ? h3WordsCount.push(str.match(/(\w+)/g).length)
              : h3WordsCount.push(0)
          } catch (m) {
            h3WordsCount.push(0)
          }
        }
        me.h3_keywords = allH3KeywordExist
        // for third check h2 contain words minimum maximum and number of words etc
        let totalWordsH3 = 0
        let valueCheck = false
        for (let i = 0; i < h3WordsCount.length; i++) {
          totalWordsH3 += h3WordsCount[i]
          if (valueCheck) continue
          if (h3WordsCount[i] === 0) {
            me.h3_words = 'minimum'
            valueCheck = true
            continue
          }
          if (h3WordsCount[i] > 16) {
            me.h3_words = 'maximum'
            valueCheck = true
            continue
          }
          if (h3WordsCount[i] > 0 && h3WordsCount[i] <= 16) me.h3_words = 'ok'
        }
        me.h3_total = totalWordsH3
      }
    },
    h3ToH6TagsKeywords (me, innerHtml) {
      let h3Toh6 = innerHtml.getElementsByTagName('h3').length
      h3Toh6 += innerHtml.getElementsByTagName('h4').length
      h3Toh6 += innerHtml.getElementsByTagName('h5').length
      h3Toh6 += innerHtml.getElementsByTagName('h6').length
      me.h3Toh6 = h3Toh6
      if (me.h3Toh6 === 0) {
        me.h3Toh6_keywords = 0
      } else {
        let allH3h4h5h6KeywordExist = 0
        const allH3Tags = innerHtml.getElementsByTagName('h3')
        const allH4Tags = innerHtml.getElementsByTagName('h4')
        const allH5Tags = innerHtml.getElementsByTagName('h5')
        const allH6Tags = innerHtml.getElementsByTagName('h6')
        for (let i = 0; i < allH3Tags.length; i++) {
          allH3h4h5h6KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH3Tags[i].innerText
          )
        }
        for (let i = 0; i < allH4Tags.length; i++) {
          allH3h4h5h6KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH4Tags[i].innerText
          )
        }
        for (let i = 0; i < allH5Tags.length; i++) {
          allH3h4h5h6KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH5Tags[i].innerText
          )
        }
        for (let i = 0; i < allH6Tags.length; i++) {
          allH3h4h5h6KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH6Tags[i].innerText
          )
        }

        me.h3Toh6_keywords = allH3h4h5h6KeywordExist
      }
    },


    h2ToH4TagsKeywords (me, innerHtml) {
      let h2Toh4 = innerHtml.getElementsByTagName('h4').length
      me.h2Toh4 = h2Toh4
      if (me.h2Toh4 === 0) {
        me.h2Toh4_keywords = 0
      } else {
        let allH2h4KeywordExist = 0
        const allH4Tags = innerHtml.getElementsByTagName('h4')
        for (let i = 0; i < allH4Tags.length; i++) {
          allH2h4KeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allH4Tags[i].innerText
          )
        }
        me.h2Toh4_keywords = allH2h4KeywordExist
      }
    },

    contentDensity (me, innerHtml) {
      // get text content of all drop zone

      let allContent = innerHtml.textContent
      allContent = allContent.replace(/\s+/g, ' ')
      allContent = allContent.replace(
        'Add an item by clicking the buttons below or drag an item from the sidebar in here.',
        ''
      )
      allContent = allContent.replace('Add text', '')
      allContent = allContent.replace('Add image', '')
      me.total_content = this.getTextWordsLength(allContent)

      // getting keyword density in text content

      me.min_range = Math.ceil(me.total_content * (0.5 / 100))
      me.max_range = Math.ceil(me.total_content * (2.5 / 100))
      me.content_keyword = me.checkStringExist(
        me.getBlogSubmissionSelection.keyword.toLowerCase(),
        allContent
      )

      if (me.total_content > 0) {
        let AllWordsInContent = allContent.split(' ')
        let tenPercentContent=AllWordsInContent.length/10
        let IstTenfindContent= allContent.split(' ',tenPercentContent)
        IstTenfindContent=IstTenfindContent.join(' ')
        AllWordsInContent = AllWordsInContent.join(' ')
        me.in_first_10_content=me.checkStringExist(
          me.getBlogSubmissionSelection.keyword.toLowerCase(),
          IstTenfindContent
        )
        me.in_all_content = me.checkStringExist(
          me.getBlogSubmissionSelection.keyword.toLowerCase(),
          AllWordsInContent
        )
        me.total_content_density=me.checkStringExist(
          me.getBlogSubmissionSelection.keyword.toLowerCase(),
          AllWordsInContent
        )
      } else {
        me.in_all_content = 0
        me.in_first_10_content=0
        me.total_content_density=0
      }
    },
    imageBoldTags (me, innerHtml) {
      // get the all images alt tag and check keyword is exist in it or not
      const allImages = innerHtml.getElementsByTagName('img')
      let altTagKeyword = 0
      let totalImages = allImages.length
      if(totalImages >0){
        me.more_images=totalImages
      }
      for (let i = 0; i < allImages.length; i++) {
        if (
          allImages[i].getAttribute('src') &&
          (allImages[i]
            .getAttribute('src')
            .indexOf('curation/drag_and_drop.svg') !== -1 ||
            allImages[i]
              .getAttribute('src')
              .indexOf('staticflickr.com/3916/32864562143_810b382700.jpg') !==
              -1)
        ) {
          totalImages = totalImages - 1
        }
      }
      if (totalImages > 0) {
        for (let i = 0; i < allImages.length; i++) {
          altTagKeyword += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allImages[i].getAttribute('alt')
          )
        }
        me.keyword_in_alt = altTagKeyword
        me.more_images=totalImages
      } else {
        me.keyword_in_alt = 0
        me.more_images=0
      }
      me.total_images = totalImages

      // getting bold tag data

      me.bold_tag =
        innerHtml.getElementsByTagName('strong').length +
        innerHtml.getElementsByTagName('b').length
      if (me.bold_tag === 0) {
        me.bold_keyword = 0
      } else {
        let allBoldKeywordExist = 0
        const allStrongTags = innerHtml.getElementsByTagName('strong')
        const allBoldTags = innerHtml.getElementsByTagName('b')
        for (let i = 0; i < allStrongTags.length; i++) {
          allBoldKeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allStrongTags[i].innerText
          )
        }
        for (let i = 0; i < allBoldTags.length; i++) {
          allBoldKeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allBoldTags[i].innerText
          )
        }
        me.bold_keyword = allBoldKeywordExist
      }
    },

    italicTagsKeywords (me, innerHtml) {
      me.italic_tag = innerHtml.getElementsByTagName('i').length
      if (me.italic_tag === 0) {
        me.italic_keyword = 0
      } else {
        let allEmKeywordExist = 0
        const allEmTags = innerHtml.getElementsByTagName('i')
        for (let i = 0; i < allEmTags.length; i++) {
          allEmKeywordExist += me.checkStringExist(
            me.getBlogSubmissionSelection.keyword.toLowerCase(),
            allEmTags[i].innerText
          )
        }
        me.italic_keyword = allEmKeywordExist
      }
    },
   detectURLs(content) {
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var matchUrl=content.match(urlRegex);
      if(matchUrl)
      return matchUrl.toString()
    },
    seoContentUrl(me,innerHtml){
      let allContent = innerHtml.textContent
       let urls= this.detectURLs(allContent)
      //  external_resources
       if(urls){
        if (!this.miscellaneous_bar_checks.external_resources) {
          this.external_resources_bar = 0.307
          this.miscellaneous_bar_checks.external_resources = true
        }
       }
       else{
        if (this.miscellaneous_bar_checks.external_resources) {
          this.external_resources_bar -= 0.307
          this.miscellaneous_bar_checks.external_resources = false
        }
       }
     if(urls && me.getBlogSubmissionSelection.url){
        me.internal_link = me.checkStringExist(
          me.getBlogSubmissionSelection.url.toLowerCase(),
          urls
        )
        if (me.internal_link > 0) {
          if (!this.miscellaneous_bar_checks.internal_link) {
            this.miscellaneous_bar += 0.538
            this.miscellaneous_bar_checks.internal_link = true
          }
        }
        }
        else {
          if (this.miscellaneous_bar_checks.internal_link) {
            this.miscellaneous_bar -= 0.538
            this.miscellaneous_bar_checks.internal_link = false
          }
        }
    },
    checkParagraph(me,innerHtml){
      const element = innerHtml.getElementsByTagName('div')[0]
      const allParagraph = element && element.innerText ? element.innerText.split(' ').length : 0

      let allContent = innerHtml.textContent
      if(allParagraph <=120 && allContent){
        if (!this.content_bar_checks.short_paragraph) {
          this.readability_bar += 0.416666667
          this.content_bar_checks.short_paragraph = true
    }
      }else{
        if (this.content_bar_checks.short_paragraph) {
          this.readability_bar -=0.416666667
          this.content_bar_checks.short_paragraph = false
    }
      }
    },
    dropzone_change_html (me) {
      const stateObject = this
      setTimeout(function () {

        let description = stateObject.getBlogPostingHTML
        description = description.toLowerCase()
        const innerHtml = $('<div />', { html: description })[0]
        stateObject.description_progress()
        stateObject.h2TagsKeywords(me, innerHtml)
        stateObject.seoContentUrl(me,innerHtml)
        stateObject.h3TagsKeywords(me,innerHtml)
        // stateObject.h3ToH6TagsKeywords(me, innerHtml)
        stateObject.h2ToH4TagsKeywords(me, innerHtml)
        stateObject.contentDensity(me, innerHtml)
        stateObject.imageBoldTags(me, innerHtml)
        stateObject.italicTagsKeywords(me, innerHtml)
        stateObject.seoHeadingH2ToH4Keywords()
        stateObject.checkTitleKeywords(me,innerHtml)
        stateObject.checkParagraph(me,innerHtml)
      }, 200)
    }

    // page scores methods end
  }
}

export { SeoScoreMixin }
