<template>
  <div class="write_block" :class="isRoot ? '' : 'inbox_reply_comment'">
    <SavedReplyModal
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>
    <div class="d-flex align-items-start">
      <div class="image_block" style="margin: auto">
        <div
          class="img"
          :style="
            'background: url(' +
            commonMethods.getSocialDetail(temp, post).image +
            ')'
          "
        ></div>
      </div>
      <div class="text_area relative">
        <div
          class="comment_tool"
          style="padding-top: 0.45rem; padding-right: 0.45rem"
        >
          <i
            v-tooltip.bottom-center="'Saved Replies'"
            class="icon-Save attachment_btn m-3"
            style="cursor: pointer"
            @click="$bvModal.show('savedReplyModal')"
          />

          <div
            v-tooltip.bottom-center="'Add Attachment'"
            class="attachment_btn pr-1"
          >
            <label for="attachment_file">
              <i class="option_icon icon-Image"></i>
            </label>
            <input
              id="attachment_file"
              ref="inputFile"
              type="file"
              accept="*"
              @change="attachMedia($event)"
            />
          </div>
        </div>
        <b-form-textarea
          id="mainComment"
          v-model="typedComment"
          style="
            box-shadow: none;
            overflow-y: hidden;
            padding: 15px;
            min-height: 60px;
          "
          placeholder="Write a comment..."
          name=""
          max-rows="6"
          @keydown.enter.exact.prevent="
            replyToComments(
              isRoot ? null : rootCommentIndex,
              $event,
              isRoot ? 'new' : ''
            )
          "
        >
        </b-form-textarea>
      </div>
    </div>

    <span
      v-if="attachment !== null"
      v-tooltip.top-center="attachment.name"
      class="attachment_file attachment_file_post"
    >
      <i
        class="d-flex align-items-center justify-content-center attachment-type fal fa-file"
      ></i>
      {{ commonMethods.limitText(attachment.name, 15) }}
      <i
        class="d-flex align-items-center justify-content-center cross_btn fal fa-times"
        @click="removeMedia"
      ></i>
    </span>
  </div>
</template>

<style lang="less" scoped>
.inbox_reply_comment {
  padding-left: 50px;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import SavedReplyModal from '../../savereplies/SavedReplyModal'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {
    SavedReplyModal,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => ({}),
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    rootCommentIndex: {
      type: Number,
      default: 0,
    },
    rootCommentId: {
      type: String,
      default: '',
    },
    temp: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    appendComment: {
      type: Function,
      default: () => {},
    },
    removeComment: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      commonMethods: commonMethods,
      typedComment: null,
      attachment: null,
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  mounted() {},
  methods: {
    ...mapActions(['reply']),
    attachMedia(event) {
      if (event.target.files.length > 0) {
        this.attachment = event.target.files[0]
      } else {
        this.removeMedia()
      }
    },
    removeMedia() {
      this.attachment = null
      this.$refs.inputFile.value = null
    },
    async replyToComments(index, e, type) {
      // if user press enter + shift it will only add next line in textarea...
      // else case only enter key will send message
      const canSend = e === null ? true : e.keyCode === 13 && !e.shiftKey
      if (canSend) {
        if (e != null) {
          e.preventDefault()
        }
        const payload = this.createPayload()
        if (payload === null) {
          return
        }

        const commentBoxPayload = {
          comment_message: this.typedComment,
          attachment: this.attachment,
          comment_id:
            this.post.platform_id + '_' + Math.round(Math.random() * 100000),
          event_type: 'comment',
          sending: true,
          has_failed: false,
          type: type,
          index: index,
          payload: payload,
        }

        this.appendComment(type, commentBoxPayload, index)
        this.resetToDefault()

        const response = await this.reply(payload)
        if (response.isValid) {
          EventBus.$emit('refresh-inbox-detail')
          this.removeComment(
            this.isRoot ? commentBoxPayload.comment_id : this.rootCommentId,
            this.isRoot ? null : commentBoxPayload.comment_id
          )
          this.appendComment(type, response.response, index)
        } else {
          commentBoxPayload.has_failed = true
          commentBoxPayload.error_message = response.response?.error
            ? response.response.error
            : response.message
          this.appendComment(type, commentBoxPayload, index)
        }
      }
    },
    resetToDefault() {
      this.typedComment = ''
      this.removeMedia()
    },
    createPayload() {
      const message = this.typedComment
      const attachment = this.attachment
      let attachmentType = ''
      console.log(attachment)
      if (attachment != null) {
        attachmentType = this.getMessageType(attachment)
        if (attachmentType === '') {
          this.alertMessage('Attachment type not supported.', 'error')
          return null
        }
      } else {
        if (message === '' || message === null) {
          this.alertMessage(
            'Message and attachment both cannot be empty.',
            'error'
          )
          return null
        }
      }
      const payload = new FormData()
      payload.append('platform', this.post.platform)
      payload.append('platform_id', this.post.platform_id)
      payload.append('comment_id', this.isRoot ? null : this.rootCommentId)
      payload.append('workspace_id', this.post.workspace_id)
      payload.append('reply_for', 'post')
      payload.append('element_id', this.post.element_details.element_id)
      payload.append('message', message)
      payload.append('attachment_file', attachment)
      payload.append('attachment_type', attachmentType)
      payload.append('replied_by', this.getProfile._id)
      payload.append('is_private_reply', false)
      payload.append('is_for', this.isRoot ? 'reply_post' : 'reply_comment')
      return payload
    },
    applySavedReply(applyText) {
      this.typedComment = applyText
    },
    getMessageType(attachment) {
      if (attachment) {
        const mediaTypes = ['gif', 'image', 'video']
        for (const type of mediaTypes) {
          if (attachment.type.indexOf(type) >= 0) {
            return type
          }
        }
      }
      return ''
    },
  },
}
</script>
