<template>
  <div>
    <div class="row">
      <template v-for="(platform, key) in account_performance">
        <div class="col-6" v-if="key !== 'overall'" :key="key">
          <div class="analytics-engagement-box">
            <div class="analytics-engagement-box__header">
              <i :class="getIconClass(key)"></i>
              <h3>{{ capitalizeFirstLetter(key) }}</h3>
            </div>

            <div class="analytics-engagement-box__chart">
              <div class="flex-center-center-column">
                <PieInnerChart
                  v-on:mounted="getPieRef"
                  :chartRef="`${key}-analytics-pie`"
                  :width="280"
                  :height="280"
                  :colors="getColors(key)"
                  :data="getData(platform)"
                  :seriesName="capitalizeFirstLetter(key)"
                  :title="platform['total_engagement']"
                />
                <small
                  >Total Engagements from
                  <b>{{ platform['total_posts'] }}</b> Posts Sent</small
                >
              </div>
            </div>

            <div class="analytics-engagement-box__detail">
              <div class="analytics-legend-wrapper">
                <template v-for="(obj, i) in legends[`${key}-analytics-pie`]">
                  <div
                    class="analytics-engagement-box__detail-item"
                    :key="i"
                    @click="legendClick(obj, obj.name !== 'No Data Found')"
                  >
                    <div class="analytics-engagement-box__detail-label">
                      <span
                        :style="{ borderColor: obj.color }"
                        class="analytics-engagement-box__detail-label-icon"
                      ></span>
                      {{ capitalizeFirstLetter(obj.name) }}
                    </div>
                    <div
                      class="analytics-engagement-box__detail-value"
                      v-if="obj.name !== 'No Data Found'"
                    >
                      {{ obj.y }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SocialEngagement from '@src/modules/analytics/views/overview/components/charts/SocialEngagement'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsEngagement',
  props: ['account_performance'],
  data () {
    return {
      legends: {}
    }
  },
  components: {
    SocialEngagement,
    PieInnerChart
  },
  methods: {
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    getColors (network) {
      switch (network) {
        case 'facebook':
          return ['#6186d2', '#91A9DB', '#c3cde2']
        case 'instagram':
          return ['#e58cf8', '#ad98ef', '#c199e1', '#b29fee']
        case 'linkedin':
          return ['#1177b5', '#2a90cb', '#1177b5']
        case 'twitter':
          return ['#1cadee', '#188abe', '#7bd6ff']
      }
    },
    getData (account) {
      const data = []
      for (const value in account) {
        if (value !== 'total_engagement' && value !== 'total_posts') {
          data.push([this.capitalizeFirstLetter(value), account[value]])
        }
      }
      return data
    }
  }
}
</script>
