<template>
  <div class="setting_component">
    <add-category></add-category>
    <add-slot></add-slot>
    <remove-global-category-confirmation></remove-global-category-confirmation>
    <div class="component_inner">
      <div class="ps_side_bar_block">
        <setting-sidebar></setting-sidebar>
        <div class="max_container_1800 right_content">
          <transition name="settings-route" mode="out-in">
            <keep-alive>
              <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingSidebar from './SettingSidebar'
const AddCategory = () => import('./content-categories/dialogs/AddCategory')
const RemoveGlobalCategoryConfirmation = () =>
  import('./content-categories/dialogs/RemoveGlobalCategoryConfirmation')
const AddSlot = () => import('./content-categories/dialogs/AddSlot')

export default {
  components: {
    SettingSidebar,
    AddCategory,
    AddSlot,
    RemoveGlobalCategoryConfirmation
  },

  data () {
    return {}
  },

  created () {
    // if (this.$route.name !== 'profile' && this.$route.name !== 'notifications') {
    //   // redirect user ro planner if user role is approver in that workspace
    //   let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
    //   if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
    //     this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    //   }
    // }
  },
  mounted () {},

  computed: {
    ...mapGetters(['getWorkspaces'])
  },
  methods: {}
}
</script>
<style>
.settings-route {
  transition: all 300ms ease-in-out 50ms;
}

.settings-route-enter-active {
  transition: all 0.3s;
}

.settings-route-leave-active {
  transition: all 0s;
}

.settings-route-enter {
  opacity: 0;
  transform: translateX(-45px);
}

.settings-route-leave-to {
  opacity: 0;
  transform: translateX(-45px);
}
</style>
