<template>
  <div>
    <b-modal
      id="profile-tagging-modal"
      centered
      header-class="cs-modal-header"
      hide-footer
      no-close-on-backdrop
      size="lg"
      title-class="cs-modal-title"
      @hide="onCloseConfirmation"
    >
      <template slot="modal-title"> Tag Instagram Public Profiles</template>
      <div class="mx-2 my-4">
        <div class="relative" @click="handleClick">
          <div @mousemove.capture="handleMouseover">
            <img
              draggable="false"
              class="w-full select-none cursor-crosshair"
              :src="imageUrl"
              alt=""
              @load="onImgLoad"
            />
          </div>
          <template v-if="isImageLoaded">
            <div
              v-for="(mention, key) in mentions"
              :key="key"
              class="absolute z-20"
              :style="`top: calc(${
                mention.y * height
              }px - 0.5rem); left: calc(${mention.x * width}px - 0.5rem)`"
            >
              <div
                class="h-5 w-5 flex justify-center items-center rounded-full bg-green-50 shadow bg-opacity-50 cursor-pointer hover:bg-opacity-75"
                @click="deleteMention(mention.id)"
              >
                <i class="far fa-close"></i>
              </div>

              <div
                class="absolute shadow bottom-full left-1/2 mb-2.5 transform -translate-x-1/2 rounded px-4 right-0 bottom-full text-base w-max h-max"
                :class="{
                  'bg-black-100 text-white py-1 ': mention.username,
                  'bg-gray-50 text-black-100 py-3 w-[22rem]': !mention.username,
                }"
              >
                <template v-if="mention.username">
                  {{ mention.username }}
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <CstInputFields
                      ref="mentionInput"
                      :maxlength="30"
                      :value="tagName"
                      class="w-100 mr-2"
                      placeholder="Enter Instagram Tag"
                      size="small"
                      type="text"
                      @value="(val) => (tagName = val)"
                    />
                    <CstButton
                      class="flex-shrink-0"
                      :disabled="tagName.trim() === ''"
                      @click="() => addNewMention(mention.id)"
                      >Add</CstButton
                    >
                  </div>
                  <div v-if="tagName.trim()" class="mt-2.5">
                    <p class="text-sm mb-1">
                      Due to Instagram API limitations, we cannot show matching
                      profiles to choose from. Please enter valid public
                      profile.
                    </p>
                    <a
                      class="text-xs text-blue-400 hover:text-blue-500 cursor-pointer inline-flex"
                      :href="`https://www.instagram.com/${tagName}/`"
                      target="_blank"
                    >
                      Verify<span class="truncate inline-block max-w-xxs mx-1"
                        >@{{ tagName.trim() }}</span
                      >account exists on Instagram
                      <i class="far fa-external-link ml-1.5"></i>
                    </a>
                  </div>
                </template>
                <svg
                  class="absolute text-black-100 h-2 w-full left-0 top-full"
                  x="0px"
                  y="0px"
                  viewBox="0 0 255 255"
                  :class="{
                    'text-black-100': mention.username,
                    'text-gray-50': !mention.username,
                  }"
                  xml:space="preserve"
                >
                  <polygon
                    class="fill-current"
                    points="0,0 127.5,127.5 255,0"
                  />
                </svg>
              </div>
            </div>
          </template>

          <!--          <div-->
          <!--            class="border-t border-b-0 border-r-0 border-l-0 border-solid border-gray-50 absolute left-0 w-full opacity-20 cross cursor-crosshair"-->
          <!--            :style="`top: ${y}px`"-->
          <!--          >-->
          <!--          </div>-->
          <!--          <div-->
          <!--            class="border-t-0 border-b-0 border-r-0 border-l border-solid border-gray-50 absolute top-0 h-full opacity-20 cross cursor-crosshair"-->
          <!--            :style="`left: ${x}px`"-->
          <!--          >-->
          <!--          </div>-->
        </div>
      </div>

      <!--    Footer    -->
      <div class="flex justify-between mt-3.5 mb-4">
        <div class="flex items-center">
          <img
            src="@assets/img/composer/warning-icon.svg"
            alt=" "
            class="w-5 h-5 mx-2"
          />
          <span
            >Instagram will not publish your post if private accounts are
            tagged.</span
          >
        </div>
        <div>
          <CstButton
            size="large"
            variant="secondary"
            class="mr-3 w-28"
            text="Cancel"
            @click="EventBus.$emit('profile-tagging-modal-hide')"
          />
          <CstButton
            variant="primary"
            size="large"
            text="Apply"
            class="w-28"
            @click="setMentions"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CstButton from '@ui/Button/CstButton'
import CstInputFields from '@ui/Input/CstInputFields'
import { swalAttributes } from '@common/constants/common-attributes'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'ProfileTaggingModal',
  components: {
    CstButton,
    CstInputFields,
  },
  data() {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      mentions: [],
      isImageLoaded: false,
      tagName: '',
      imageUrl: '',
      modalClosable: false,
      emitter: () => {},
      mentionsLimit: 30,
    }
  },
  computed: {
    EventBus() {
      return EventBus
    }
  },
  mounted() {
    EventBus.$on(
      'profile-tagging-modal-show',
      (
          {
            mentions = [], imageUrl, emitter = () => {}
          }
      ) => {
        this.clearModalState()
        this.$bvModal.show('profile-tagging-modal')

        this.mentions = mentions.map((mention) => ({
          ...mention,
          id: this.generatedId(),
        }))
        this.imageUrl = imageUrl

        this.emitter = emitter
      }
    )

    EventBus.$on('profile-tagging-modal-hide', () => {
      this.$bvModal.hide('profile-tagging-modal')
    })
  },
  beforeDestroy() {
    EventBus.$off('profile-tagging-modal-show')
    EventBus.$off('profile-tagging-modal-hide')
  },
  methods: {
    async onCloseConfirmation(event) {
      if (this.modalClosable || !this.mentions.length) {
        this.modalClosable = false
      } else {
        event.preventDefault()
        const res = await this.$root.$bvModal.msgBoxConfirm(
          'Are you sure you want to close?',
          {
            title: 'Close Instagram Tagging',
            ...swalAttributes(),
          }
        )
        if (res) {
          this.modalClosable = true
          this.$bvModal.hide('profile-tagging-modal')
          this.clearModalState()
        }
      }
    },
    setMentions() {
      this.modalClosable = true
      this.emitter(
        this.mentions.filter((mention) => mention.username !== ''),
        this.imageUrl
      )
      EventBus.$emit('profile-tagging-modal-hide')
    },
    /*
     *  Method to clear state
     */
    clearModalState() {
      this.x = 0
      this.y = 0
      this.width = 0
      this.height = 0
      this.mentions = []
      this.isImageLoaded = false
      this.tagName = ''
    },

    /*
     *  Method to add new mention on image
     */
    addNewMention(id) {
      console.log(id)
      this.mentions.map((mention) => {
        if (mention.id === id) {
          mention.username = `${this.tagName.trim().replace(/@/g, '')}`
          this.tagName = ''
        }
      })
    },

    /*
     *  This method is called when the image is fully loaded
     */
    onImgLoad(event) {
      this.width = getComputedStyle(event.target).width.replace('px', '')
      this.height = getComputedStyle(event.target).height.replace('px', '')
      this.isImageLoaded = true
    },

    /*
     *  Method to generate random id of mentions
     */
    generatedId() {
      const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      )
    },

    /*
     *  Method to remove the mention from the list
     *  @param {number} id - The id of the mention
     */
    deleteMention(id) {
      console.log('deleteMention', id)
      this.mentions = this.mentions.filter((mention) => mention.id !== id)
    },

    /*
     *  Method to handle click on the image
     */
    handleClick(event) {
      if (
        event.target.classList.contains('cursor-crosshair') &&
        this.isImageLoaded
      ) {
        if (this.mentions.length >= this.mentionsLimit) {
          this.alertMessage(
            'Instagram allows tagging up to 30 public profiles only.',
            'error'
          )
          return
        }
        const mentions = this.mentions.filter(
          (mention) => mention.username !== ''
        )
        mentions.push({
          x: this.x / this.width,
          y: this.y / this.height,
          username: '',
          id: this.generatedId(),
        })

        this.mentions = [...mentions]

        this.tagName = ''

        // Auto focus on the input field
        setTimeout(() => {
          if (this.$refs.mentionInput && this.$refs.mentionInput.length) {
            this.$refs.mentionInput[0].$el.focus()
          }
        }, 100)
      }
    },

    /*
     *  Method to show grid on the image
     */
    handleMouseover(event) {
      if (this.isImageLoaded) {
        // Get the target
        const target = event.target

        // Get the bounding rectangle of target
        const rect = target.getBoundingClientRect()

        // Mouse position
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        this.x = x
        this.y = y

        this.width = getComputedStyle(event.target).width.replace('px', '')
        this.height = getComputedStyle(event.target).height.replace('px', '')
      }
    },
  },
}
</script>
