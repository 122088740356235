export default {
  methods: {
    requiredCheck(value) {
      return (
        !value ||
        value.length === 0 ||
        value.toString().replace(/^\s+/, '').replace(/\s+$/, '') === ''
      )
    },
    minLength(value, length) {
      return value && value.length >= length
    },
    maxLength(value, length) {
      return value && value.length <= length
    },
  },
}
