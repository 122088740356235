<template>
  <div
    v-if="member && member.status === 'joined' && member.user"
    class="checkbox_input_image profile_selection d-block"
  >
    <label class="checkbox_right with_check_icon d-block">
      <div class="profile_picture d-flex align-items-center">
        <div class="picture_block picture_block_background">
          <div
            v-if="member.user.image"
            class="img"
            :style="{ background: 'url(' + member.user.image + ')' }"
          ></div>
          <div
            v-else
            class="img"
            style="
              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
            "
          ></div>
        </div>
        <div class="text_block">
          <p v-if="member.user.firstname && member.user.lastname" class="text">{{ member.user.firstname }} {{ member.user.lastname }}</p>
          <p v-else class="text">{{ member.user.email }} </p>
          <p class="sub_text role_text" :class="getRoleClass(member.role)">{{
            getMutatedUserRole(member.role)
          }}</p>
          <p
            v-if="approver.status === 'approve'"
            class="sub_text role_text"
            style="color: limegreen"
            >Approved</p
          >
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    member: {},
    approver: {},
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters([]),
  },

  methods: {},
}
</script>
