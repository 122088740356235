<template>
  <confirm-box
    id="removePublications"
    :callBackAction="removePublications"
    confirmText="Yes"
    cancelText="No"
  >
    <template slot="header">
      <h2>Remove Publications</h2>
    </template>

    <div class="text-center" slot="footer">
      <p>You won't be able to undo.</p>
    </div>
  </confirm-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'

export default {
  components: {
    ConfirmBox
  },

  computed: {
    ...mapGetters(['getEvergreenSelection', 'getPublicationBulkSelection'])
  },

  methods: {
    ...mapActions(['setConfirmActionStage']),
    removePublications (confirmation, publication) {
      console.debug('Method:removePublications', confirmation, publication)

      if (confirmation.status) {
        const payload = {
          id: this.getPublicationBulkSelection.ids,
          type: 'bulk'
        }
        this.removePublicationRequest(payload)
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    }
  }
}
</script>
