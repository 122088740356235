<template>
  <div class="analytic_component">
    <div class="analyze_container">
      <instagram-header></instagram-header>
      <div class="section">
        <div class="bottom_radius_zero white_box">
          <div class="flex-row">
            <h1>Instagram Profiles</h1>
            <p
              >Review your aggregate profile metrics from the reporting
              period.</p
            >
          </div>
        </div>
        <div class="section table_container">
          <div class="d-flex flex-row table_head">
            <div class="col col-2">
              Profile <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>

            <div class="col col-1">
              Followers <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>

            <div class="col col-2">
              Net Followers Growth
              <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>

            <div class="col col-2">
              Published Posts & Stories<i
                class="sorting_icon far fa-sort-amount-up-alt"
              ></i>
            </div>

            <div class="col col-1">
              Impressions <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>
            <div class="col col-2">
              Engagements <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>
            <div class="col col-2">
              Engagement Rate (per Impression)
              <i class="sorting_icon far fa-sort-amount-up-alt"></i>
            </div>
          </div>

          <div class="table_body flex-row white_box top_radius_zero">
            <div class="table_item">
              <div class="col col-2">
                <div class="user_profile_text d-flex align-items-center">
                  <div class="w_img">
                    <img
                      src="https://storage.googleapis.com/lumotive-web-storage/settings/5e43a252d2ab75000615ceb2/cHZqQZUUm9weWHjB4SI5.jpeg"
                      alt=""
                    />
                  </div>
                  <span>
                    <span class="name">ContentStudio</span>
                    <span class="title">workspace</span>
                  </span>
                </div>
              </div>

              <div class="col col-1"> 1,990 </div>

              <div class="col col-2"> 81 </div>

              <div class="col col-2"> 2 </div>

              <div class="col col-1"> 240,555 </div>
              <div class="col col-2"> 77 </div>
              <div class="col col-2"> 0% </div>
            </div>

            <div class="table_item">
              <div class="col col-2">
                <div class="user_profile_text d-flex align-items-center">
                  <div class="w_img">
                    <img
                      src="https://storage.googleapis.com/lumotive-web-storage/settings/5e43a252d2ab75000615ceb2/cHZqQZUUm9weWHjB4SI5.jpeg"
                      alt=""
                    />
                  </div>
                  <span>
                    <span class="name">ContentStudio</span>
                    <span class="title">workspace</span>
                  </span>
                </div>
              </div>

              <div class="col col-1"> 1,990 </div>

              <div class="col col-2"> 81 </div>

              <div class="col col-2"> 2 </div>

              <div class="col col-1"> 240,555 </div>
              <div class="col col-2"> 77 </div>
              <div class="col col-2"> 0% </div>
            </div>

            <div class="table_item">
              <div class="col col-2">
                <div class="user_profile_text d-flex align-items-center">
                  <div class="w_img">
                    <img
                      src="https://storage.googleapis.com/lumotive-web-storage/settings/5e43a252d2ab75000615ceb2/cHZqQZUUm9weWHjB4SI5.jpeg"
                      alt=""
                    />
                  </div>
                  <span>
                    <span class="name">ContentStudio</span>
                    <span class="title">workspace</span>
                  </span>
                </div>
              </div>

              <div class="col col-1"> 1,990 </div>

              <div class="col col-2"> 81 </div>

              <div class="col col-2"> 2 </div>

              <div class="col col-1"> 240,555 </div>
              <div class="col col-2"> 77 </div>
              <div class="col col-2"> 0% </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstagramHeader from './InstagramHeader'
export default {
  name: 'InstagramProfile',
  components: {
    InstagramHeader
  }
}
</script>

<style scoped></style>
