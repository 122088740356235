<template>
  <div class="optimize_block with_opt_box_shadow canva_image">
    <div class="opt_box">
      <div class="head_text">
        <h3>Featured Image</h3>
      </div>

      <div class="d-flex align-items-center">
        <div
          data-cy="platform-selection"
          class="dropdown default_style_dropdown"
        >
          <canva-types
            :dropdownPosition="'dropdown-menu-left'"
            type="Blog"
          ></canva-types>
        </div>

        <div
          class="info_sec"
          v-if="
            getBlogPostingDetails.image.link &&
            getBlogPostingDetails.image.source === 'Dropzone'
          "
        >
          <a
            id="ImageEnhance"
            @click.prevent="triggerSharingImageEnhance('Blog')"
            class="btn red remove_btn enhance_image"
          >
            <i class="fas fa-magic"></i>&nbsp; Enhance Image</a
          >
        </div>
      </div>

      <div
        class="image_upload_block d-flex align-items-center justify-content-between"
      >
        <div class="drop_img" @click.prevent="displayFile('Dropzone')">
          <vue2-dropzone
            ref="blogImageDropzone"
            id="blogImageDropzone"
            :options="dropzoneOptions"
            v-on:vdropzone-sending="sendingEvent"
            v-on:vdropzone-success="vDropzoneCallback"
            v-on:vdropzone-error="vDropzoneCallback"
            v-on:vdropzone-removed-file="changeBlogPostingImage(null)"
          ></vue2-dropzone>
        </div>

        <div class="or_sep" v-if="separatorSectionVisibilityStatus">
          <span class="sep_text">OR</span>
        </div>

        <template v-if="getBlogPostingLoaders.upload_blog_image">
          <div class="new_image loader_overlay_with_loader">
            <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
            <div class="image_icon_gray">
              <i class="cs-gallery"></i>
            </div>
            <p>Uploading...</p>
          </div>
        </template>

        <template
          v-else-if="
            getBlogPostingDetails.image.link &&
            getBlogPostingDetails.image.source !== 'Dropzone'
          "
        >
          <div class="new_image">
            <img
              :src="getBlogPostingDetails.image.link"
              alt=""
              @click.prevent="displayFile(getBlogPostingDetails.image.source)"
            />
            <div class="info_sec">
              <a
                @click.prevent="triggerSharingImageEnhance('Blog')"
                class="btn gradient_btn enhance_btn"
                >Enhance</a
              >
              <a
                @click.prevent="changeBlogPostingImage(null)"
                class="btn red remove_btn"
                >Remove</a
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style>
@import '~vue2-dropzone/dist/vue2Dropzone.min.css';
</style>
<script>
import { mapGetters } from 'vuex'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import vue2Dropzone from 'vue2-dropzone'
import CanvaTypes from '@src/modules/publish/components/posting/CanvaTypes'
export default {
  components: {
    CanvaTypes,
    vue2Dropzone
  },
  data () {
    return {}
  },
  mounted () {
    this.processBlogImage()
  },
  computed: {
    ...mapGetters(['getBlogPostingDetails', 'getBlogPostingLoaders']),
    separatorSectionVisibilityStatus () {
      return (
        this.getBlogPostingLoaders.upload_blog_image ||
        (this.getBlogPostingDetails.image.link &&
          this.getBlogPostingDetails.image.source !== 'Dropzone')
      )
    }
  },
  methods: {
    sendingEvent (file, xhr, formData) {
      formData.append('workspace_id', this.getActiveWorkspace._id)
    },
    displayFile (source) {
      console.debug('Method::displayFile')
      if (
        this.getBlogPostingDetails.image.link &&
        this.getBlogPostingDetails.image.source === source
      ) {
        EventBus.$emit('displayFile', {
          type: 'image',
          media: [this.getBlogPostingDetails.image.link],
          index: 0
        })
        this.$bvModal.show('display-file-modal')
      }
    },
    processBlogImage () {
      console.debug('Method::processBlogImage')
      setTimeout(() => {
        this.$store.commit(blogPosting.SET_BLOG_POSTING_DROPZONE_STATUS, false)
        if (
          this.getBlogPostingDetails.image.source === 'Dropzone' &&
          this.getBlogPostingDetails.image.link
        ) {
          this.$refs.blogImageDropzone.manuallyAddFile(
            { name: 'Feature Image', size: 123, type: 'image/png' },
            this.getBlogPostingDetails.image.link
          )
        }
      }, 500)
    },
    changeBlogPostingImage (image) {
      this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, image)
    }
  },
  watch: {
    'getBlogPostingDetails.blogDropzoneStatus' (status) {
      if (status) this.processBlogImage()
    },
    'getBlogPostingDetails.image' (image) {
      console.debug('Watch::getBlogPostingDetails.image', image)
      if (
        this.getBlogPostingDetails.image.source !== 'Dropzone' ||
        this.getBlogPostingDetails.image.link === ''
      ) {
        this.$refs.blogImageDropzone.removeAllFiles()
        $('#blogImageDropzone').removeClass('dz-started')
        $('#blogImageDropzone .dz-image-preview').remove()
        this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, image)
        return true
      }
      if (
        this.getBlogPostingDetails.image.source === 'Dropzone' ||
        this.getBlogPostingDetails.image.link
      ) {
        this.processBlogImage()
      }
    }
  }
}
</script>
