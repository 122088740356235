<template>
  <div class="topics-pane">
    <topics-sidebar />
    <div class="topics-top-pane force-white-bg">
      <div class="topics-top-pane__inner">
        <div class="topics-top-pane__inner__left_section">
          <div class="topics-top-pane__inner__left_section__inner">
            <h3>Custom Topic</h3>
            <h2 v-if="custom_topic.filters">{{
              custom_topic.filters.topics.name
            }}</h2>
          </div>
        </div>
        <div class="topics-top-pane__inner__right_section">
          <div class="topics-top-pane__inner__right_section__content_types">
            <div
              class="topics-top-pane__inner__right_section__content_types__buttons"
            >
              <router-link
                :to="{
                  name: 'discover_topics_custom_web_content',
                  params: { topic_id: $route.params.topic_id },
                }"
              >
                <i class="icon-Content-Feed_icon"></i>
                <span>Content Feed</span>
              </router-link>
              <router-link
                href="javascript:;"
                :to="{
                  name: 'discover_topics_custom_web_insights',
                  params: { topic_id: $route.params.topic_id },
                }"
              >
                <i class="icon-Insights_icon"></i>
                <span>Insights</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <curated-and-custom-topics-channel :module="'custom'" />
        <curated-and-custom-topics-filters
          :total_results="total_results"
          :date="getCustomTopicDateRange"
          :region="getCustomTopicRegion"
          :language="getCustomTopicLanguage"
          :sort="getCustomTopicSort"
          :is_custom_topic="true"
        />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import TopicsSidebar from '../../sub-components/TopicsSidebar'
import TopicsFilterResults from '../../sub-components/TopicsFilterResults'
import CuratedAndCustomTopicsFilters from '../../channel-filters/CuratedAndCustomTopicsFilters'
import CuratedAndCustomTopicsChannel from '../../sub-components/CuratedAndCustomTopicsChannel'
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
export default {
  data () {
    return {
      total_results: 0,
      custom_topic: {}
    }
  },
  components: {
    TopicsSidebar,
    TopicsFilterResults,
    CuratedAndCustomTopicsChannel,
    CuratedAndCustomTopicsFilters
  },
  computed: {
    getCustomTopicDateRange () {
      if (this.custom_topic.filters) {
        return this.custom_topic.filters.topics.date.value
      }
      return ''
    },
    getCustomTopicRegion () {
      if (this.custom_topic.filters) {
        return this.custom_topic.filters.topics.region.code
      }
      return ''
    },
    getCustomTopicLanguage () {
      if (this.custom_topic.filters) {
        return this.custom_topic.filters.topics.language.code
      }
      return ''
    },
    getCustomTopicSort () {
      if (this.custom_topic.filters) {
        return this.custom_topic.filters.topics.sort
      }
      return 'most shared'
    }
  },
  created () {
    this.fetchCustomTopic()
  },
  mounted () {
    EventBus.$on('archive-post-item', (data) => {
      this.total_results -= 1
    })
  },
  beforeDestroy () {
    EventBus.$off('archive-post-item')
  },
  methods: {
    fetchCustomTopic () {
      proxy
        .post(`${discoveryBaseUrl}v2/topics/retrieve_custom_topic_by_id`, {
          workspace_id: this.$store.getters.getActiveWorkspace._id,
          topic_id: this.$route.params.topic_id
        })
        .then((res) => {
          if (res.data.data) {
            this.custom_topic = res.data.data
          }
        })
        .catch((err) => {})
    }
  },
  watch: {
    '$route.params.topic_id' (value) {
      this.custom_topic = {}
      this.fetchCustomTopic()
    }
  }
}
</script>
