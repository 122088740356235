var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"tweets_detail_modals","hide-footer":"","hide-header":"","modal-class":"full_height threads_response_modal right side_slide_right w_200"},on:{"hide":_vm.hideModal}},[[_c('div',{staticClass:"modal_head d-flex align-items-center"},[_c('h3',[_vm._v("Threaded Tweets Status")]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.hideModal(false)}}},[_vm._v("× ")])]),_c('div',{staticClass:"modal_body"},[_c('div',{staticClass:"social_preview_block__social_preview_inner__social_post_preview"},[_c('div',{staticClass:"twitter_post_preview multiple-threads-box"},[_c('div',{staticClass:"twitter_post_preview__head"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture__picture_block thread-profile"},[_c('img',{attrs:{"src":_vm.profileImage,"alt":""}})]),_c('div',{staticClass:"twitter_post_preview__head__profile_picture__text_block"},[_c('p',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.paltformName)),_c('br')])])])]),_c('div',{staticClass:"twitter_post_preview__head__profile_picture__preview_desc thread-desc"},[(_vm.details.message)?_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
                _vm.processSharingPreviewDesciption(_vm.details.message, 'Twitter')
              )}}):_vm._e()]),(_vm.details.video && _vm.details.video.link)?[_c('div',{staticClass:"social_preview_block__social_preview_inner__social_post_preview__preview_video"},[_c('div',{staticClass:"img",style:({
                  'background-image': 'url(' + _vm.details.video.thumbnail + ')',
                })},[_c('div',{staticClass:"twitter_vid_icon vid_icon"},[_c('span',{staticClass:"icon"})])])])]:(
              !_vm.details.url && _vm.details.image && _vm.details.image.length > 0
            )?_c('div',{staticClass:"twitter_post_preview__preview_images"},[(_vm.details.image.length === 1)?_c('div',{staticClass:"ratio ratio-16x9"},[_c('img',{staticClass:"single_image",attrs:{"src":_vm.details.image[0],"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}})]):(_vm.details.image.length === 2)?_c('div',{staticClass:"multiple_images two_images"},_vm._l((_vm.details.image),function(image,index){return _c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})}),0):(_vm.details.image.length === 3)?_c('div',{staticClass:"multiple_images three_images"},[_c('div',{staticClass:"img_left"},[_c('div',{staticClass:"img",style:({
                    'background-image': 'url(' + _vm.details.image[0] + ')',
                  })})]),_c('div',{staticClass:"img_right"},[_vm._l((_vm.details.image),function(image,index){return (index > 0)?[_c('div',{staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})]:_vm._e()})],2)]):_c('div',{staticClass:"multiple_images four_images"},_vm._l((_vm.details.image),function(image,index){return (index < 4)?_c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })}):_vm._e()}),0)]):_vm._e(),(_vm.getLocationName)?_c('div',{staticClass:"location_preview"},[_c('h3',[_c('img',{attrs:{"src":require("@src/assets/img/locate.png")}}),_vm._v(" "+_vm._s(_vm.getLocationName)+" ")])]):_vm._e()],2),_vm._l((_vm.threaded_tweets),function(threaded_tweet,key){return _c('div',{key:key},[_c('div',{staticClass:"twitter_post_preview multiple-threads-box"},[_c('div',{staticClass:"single-thread"},[_c('div',{staticClass:"thread-line"}),_c('div',{staticClass:"twitter_post_preview__head"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture__picture_block thread-profile"},[_c('img',{attrs:{"src":_vm.profileImage,"alt":""}})]),_c('div',{staticClass:"twitter_post_preview__head__profile_picture__text_block"},[_c('p',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.paltformName)),_c('br')])])])]),_c('div',{staticClass:"thread-content"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture__preview_desc thread-desc"},[_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
                      _vm.processSharingPreviewDesciption(
                        threaded_tweet.message,
                        'ThreadedTweets_' + key,
                      )
                    )}})]),(threaded_tweet.video && threaded_tweet.video.link)?[_c('div',{staticClass:"social_preview_block__social_preview_inner__social_post_preview__preview_video"},[_c('div',{staticClass:"img",style:({
                        'background-image':
                          'url(' + threaded_tweet.video.thumbnail + ')',
                      })},[_c('div',{staticClass:"twitter_vid_icon vid_icon"},[_c('span',{staticClass:"icon"})])])])]:(
                    !threaded_tweet.url && threaded_tweet.image && threaded_tweet.image.length > 0
                  )?_c('div',{staticClass:"twitter_post_preview__preview_images"},[(threaded_tweet.image.length === 1)?_c('div',{staticClass:"ratio ratio-16x9"},[_c('img',{staticClass:"single_image",attrs:{"src":threaded_tweet.image[0],"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}})]):(threaded_tweet.image.length === 2)?_c('div',{staticClass:"multiple_images two_images"},_vm._l((threaded_tweet.image),function(image,index){return _c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})}),0):(threaded_tweet.image.length === 3)?_c('div',{staticClass:"multiple_images three_images"},[_c('div',{staticClass:"img_left"},[_c('div',{staticClass:"img",style:({
                          'background-image':
                            'url(' + threaded_tweet.image[0] + ')',
                        })})]),_c('div',{staticClass:"img_right"},[_vm._l((threaded_tweet.image),function(image,index){return (index > 0)?[_c('div',{staticClass:"img",style:({
                            'background-image': 'url(' + image + ')',
                          })})]:_vm._e()})],2)]):_c('div',{staticClass:"multiple_images four_images"},_vm._l((threaded_tweet.image),function(image,index){return (index < 4)?_c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })}):_vm._e()}),0)]):_vm._e(),_c('div',{staticClass:"threads-status-box"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.threadsPostingStatus(key))}})])],2)])])])})],2)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }