<template>
  <div
    :id="id"
    ref="confirmationBox"
    tabindex="-1"
    role="dialog"
    class="modal fade alertBox"
  >
    <div class="modal-dialog d-flex align-items-center">
      <div class="modal-content">
        <!--<div class="modal_head">-->
        <!--<button type="button" class="close" data-dismiss="modal">&times;</button>-->
        <!--</div>-->
        <div class="modal_body">
          <div
            v-if="getConfirmActionDetails.stage === 'success'"
            class="svg_animation_success"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-
                miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                class="path check"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
          </div>

          <div
            v-else-if="getConfirmActionDetails.stage === 'fail'"
            class="svg_animation_success"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <line
                class="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                class="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="95.8"
                y1="38"
                x2="34.4"
                y2="92.2"
              />
            </svg>
          </div>

          <div v-else class="col-sm-12 alert_content">
            <slot name="header"></slot>

            <slot name="description"></slot>

            <slot name="alert_list"></slot>

            <slot name="footer"></slot>
          </div>

          <div
            v-if="getConfirmActionDetails.stage === ''"
            class="col-sm-12 footer_box"
          >
            <button
              class="btn gray cancel_btn"
              data-cy="remove-no"
              @click="cancelClick"
              >{{ cancelText }}</button
            >
            <button
              class="btn confirm_btn"
              :class="{ 'gray_color_btn cursor-not-allowed': loader }"
              :disabled="loader"
              data-cy="remove-yes"
              @click="confirmClick"
              >{{ confirmText }}
              <span v-if="loader">
                <clip-loader
                  :color="'#fdfdfd'"
                  :size="'13px'"
                  class="spinner inline-block ml-2"
                  variant="info"
                ></clip-loader>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      default: 'confirmationBox',
    },

    confirmText: {
      type: String,
      default: 'Process',
    },
    greenColor: {
      type: String,
      default: '',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    callBackAction: {
      type: Function,
      default: () => {},
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['getConfirmActionDetails']),
  },

  methods: {
    confirmClick() {
      const confirmationStatus = {
        type: this.id,
        status: true,
      }
      this.callBackAction(confirmationStatus, this.getConfirmActionDetails.data)
    },

    cancelClick() {
      const confirmationStatus = {
        type: this.id,
        status: false,
      }

      this.callBackAction(confirmationStatus, this.getConfirmActionDetails.data)
    },
  },
}
</script>
