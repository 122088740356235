import {
  logoutUrl,
  joinTokenStatusURL,
} from '@src/modules/account/config/api-utils'
import { mapGetters, mapActions } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'

export const accountMixin = {
  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
    ...mapActions(['setJoinTeamStatusLoader', 'fetchProfile']),
    getJoinTokenStatus() {
      console.debug('Method:getJoinTokenStatus')

      this.setJoinTeamStatusLoader(true)

      this.$http
        .post(joinTokenStatusURL, {
          reff_enc: this.$route.params.reff,
          token: this.$route.params.token,
        })
        .then(
          (response) => {
            if (response.data.status === false) {
              this.alertMessage(response.data.message, 'error')
              this.$router.push({ name: 'login' })
            } else {
              if (response.data.newUser) {
                this.setJoinTeamStatusLoader(false)
              } else {
                // this.$router.push({ name: 'workspaces' })
                this.$store.commit(
                  authenticationTypes.SET_JWT_TOKEN,
                  response.data.token
                )
                this.$store.commit(
                  authenticationTypes.SET_LOGGED_USER,
                  response.data.logged_user
                )
                this.$store.commit(
                  authenticationTypes.SET_IS_AUTHENTICATED,
                  true
                )
                this.fetchProfile()
                if (response.data.user && response.data.user.onBoarding) {
                  if (response.data.user.migration) {
                    this.fetchOnboardingWorkspaces()
                    this.$router.push({ name: 'welcome' })
                  } else {
                    this.fetchWorkspaces()
                    this.$router.push({ name: 'onboardingWorkspace' })
                  }
                } else {
                  this.fetchWorkspaces()
                  console.log('getJoinTokenStatus: Redirecting to workspaces')
                  this.$router.push({ name: 'workspaces' })
                }
              }
            }
          },
          (response) => {
            console.debug('Exception in getJoinTokenStatus', response)
            this.$router.push({ name: 'login' })
            this.alertMessage(
              'Uh-oh! An unknown error occurred, support has been notified.',
              'error'
            )
          }
        )
    },

    /**
     * logout the logged in user.
     */
    logout() {
      console.debug('Method:logout')
      const profileId = this.getProfile._id
      const stateObject = this
      this.postRequest(
        logoutUrl,
        {},
        (response) => {
          this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
          this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
          this.logoutBroadcastUser(profileId)
          this.analyticsUnsubscribeUser()
          stateObject.$router.push('/login')
          this.resetDefaultStates()
          // stateObject.$router.go(0)
        },
        (error) => {
          console.debug('Exception in logout', error)
          this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
          this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
          this.logoutBroadcastUser(profileId)
          stateObject.$router.push('/login')
          stateObject.$router.go(0)
        }
      )
    },

    featureFlagAffiliate() {
      const email = this.$store.getters.getProfile.email
      if (email)
        return (
          email.includes('@contentstudio.io') ||
          email.includes('@d4interactive.io')
        )
      return false
    },
  },
}
