import proxy from '@common/lib/http-common'
import {
  archivedPostURL,
  unArchivedPostURL,
} from '@src/modules/discovery/config/api-utils'
import {
  POST_ARCHIVED_ADDED,
  POST_ARCHIVED_REMOVED,
  UNKNOWN_ERROR,
} from '@common/constants/messages'
import { topicTypes } from '@src/modules/discovery/store/mutation-types'
import { appContainer } from '@src/main'

export default {
  state: {},
  getters: {},
  actions: {
    archive({ commit, state, getters, dispatch }, payload) {
      if (payload.index === null) {
        // archiving from modal
        getters.getPosts.filter((item, index) => {
          if (item.source_id === payload.post_id) {
            getters.getPosts.splice(index, 1)
          }
        })
      } else {
        getters.getPosts.splice(payload.index, 1)
      }

      proxy
        .post(archivedPostURL, {
          post_id: payload.post_id,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', {
              message: POST_ARCHIVED_ADDED,
              type: 'success',
            })
            // if archiving from preview modal
            commit(topicTypes.SET_POSTS_TOTAL, getters.getPostsTotal - 1)
            // $('#article_preview_modal').modal('hide')
            appContainer.$bvModal.hide('article_preview_modal')
          }
        })
        .catch(() => {
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
        })
    },
    unarchive({ commit, state, getters, dispatch }, payload) {
      // no need to wait for the ajax request.
      if (getters.getTopicType === 'archived') {
        getters.getPosts.splice(payload.index, 1)
        commit(topicTypes.SET_POSTS_TOTAL, getters.getPostsTotal - 1)
      }

      proxy
        .post(unArchivedPostURL, {
          post_id: payload.post_id,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', {
              message: POST_ARCHIVED_REMOVED,
              type: 'success',
            })
          }
        })
        .catch(() => {
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
        })
    },
  },
  mutations: {},
}
