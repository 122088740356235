<template>
  <div class="w-100 my-1 overflow-hidden border rounded-lg">
    <div class="flex items-center px-3 py-3 ">
      <img class="rounded-full object-cover border border-solid border-black" width="42" height="42"
           :src="getAccountImage"
           alt=""
           @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                ">
      <div class="px-2 font-bold">
        {{getAccountName}}
        <span class="block font-normal text-xs text-gray-800">
          {{getWorkspaceTimezonCurrentTime('D MMM YYYY')}}
        </span>
      </div>
    </div>
    <div v-if="description" class="items-center px-3 py-1 pb-1">
      <span class="" v-html="processSharingPreviewDesciption(description, 'Facebook')"></span>
    </div>
    <div class="w-100 px-4">
      <carousel id="preview-carousel" class="facebook-carousel-preview" :navigation-next-label="navigationNext" :navigation-prev-label="navigationPrev" :navigationEnabled="true" :paginationEnabled="false" :scrollPerPage="true"  :perPage="1">
        <template v-for="(card, index) in carousel.cards">
          <Slide :key="index">
            <div class="block w-100 bg-white border rounded-md">
              <div class="shadow-sm w-100 h-64 max-h-64 bg-cover bg-no-repeat bg-center border"
                   :style="{ 'background-image': 'url(' + card.picture + ')' }"
              ></div>
              <div  class="footer w-100 h-18 max-h-18 min-h-18 py-2 px-2 overflow-hidden cursor-pointer" style="background-color:#e4e6eb33;min-height:80px;">
                <div class="relative w-100">
                    <div v-show="carousel.call_to_action !== 'NO_BUTTON'" class="absolute right-0 top-2 mx-2 z-40">
                         <span class="flex py-1.5 px-2 border items-center justify-center font-medium cursor-default rounded-md" style="color:#4b4f56;background-color:#e4e6eb;">
                           {{facebook_call_to_action.find(action => action.type === carousel.call_to_action) .name}}
                         </span>
                    </div>
                </div>
                <div class="title-default"
                      :class="{ 'mirror-title': carousel.call_to_action !== 'NO_BUTTON' }"
                >{{card.name}}</div>
                <div class="description-default"
                     :class="{ 'mirror-description': carousel.call_to_action !== 'NO_BUTTON' }"
                >{{card.description}}</div>
              </div>
            </div>
          </Slide>
        </template>
        <Slide v-if="carousel.end_card && carousel.end_card_url">
          <div class="block w-100 bg-white border rounded-md">
            <div class=" my-3  mx-auto w-80 h-52 max-h-52 bg-contain bg-no-repeat bg-center"
                 :style="{ 'background-image': 'url(' + getAccountImage + ')' }"
            ></div>
            <div  class="footer w-100 h-20 max-h-20 py-3 px-2 overflow-hidden cursor-pointer text-center" style="background-color:#e4e6eb33">
              <div class="w-100 font-bold">See more at</div>
              <div class="w-100 overflow-hidden  font-normal text-gray-800 uppercase">{{getHostName}}</div>
            </div>
          </div>
        </Slide>
      </carousel>
    </div>
    <div class="rection_icons facebook_r">
      <span><i class="far fa-thumbs-up"></i> Like </span>
      <span><i class="far fa-comment-alt"></i> Comments </span>
      <span><i class="fas fa-share"></i> Share </span>
    </div>
    <div
        class="first-comment-preview"
        v-if="
            getSocialSharingFirstComment.has_first_comment &&
            getSocialSharingFirstComment.first_comment_message.trim().length &&
            hasEnableFirstComment(getPreviewAccount.facebook.facebook_id)
          "
    >
      <div class="first-comment-preview__img">
        <img
            :src="facebookImage(getPreviewAccount.facebook)"
            alt=""
            style="border: 1px solid #eff4f8"
            @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
        />
      </div>
      <div
          class="first-comment-preview__text"
          v-html="n2br(getSocialSharingFirstComment.first_comment_message)"
      >
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { FacebookCallToAction } from '@common/constants/common-attributes'
import {encode} from 'html-entities'
import {mapGetters} from 'vuex'
export default {
  name: "FacebookCarouselPreview",
  components: {
    Carousel,
    Slide
  },
  props:{
    carousel:{
      type:Object,
      required:true,
    },
    location:{
      type:String,
      default:''
    },
    description:{
      type:String,
      default:''
    },
  },
  data () {
    return {
      facebook_call_to_action: FacebookCallToAction
    }
  },
  computed:{
    ...mapGetters(['getSocialSharingFirstComment','getPreviewAccount','getFacebookAccounts']),
    navigationNext: function() { return `<i class="fas fa-chevron-right"></i>` },
    navigationPrev: function() { return `<i class="fas fa-chevron-left"></i>` },
    getAccountImage(){
    const account = this.getFacebookAccounts.items.find(
          (item) =>
              item.facebook_id === this.carousel.accounts[0]

   )
      return account ? account.image : ''
    },
    getAccountName(){
      if(this.carousel.accounts[0]) {
        return this.getFacebookAccounts.items.find(
            (item) =>
                item.facebook_id === this.carousel.accounts[0]).name
      }
      return 'Default'
    },
    getHostName(){
      const urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(this.carousel.end_card_url)
      return urlParts[1] ? urlParts[1] : ''
    }
  },
  methods:{
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    hasEnableFirstComment (id) {
      return (
          this.getSocialSharingFirstComment.first_comment_accounts &&
          this.getSocialSharingFirstComment.first_comment_accounts.includes(id)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.title-default{
  font-weight:500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width:100%;
  max-height: 40px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.mirror-title{
  width:250px!important;
  @media (max-width: 1440px) {
    width:60%!important;
  }
}
.description-default{
  font-weight:400;
  font-size:14px;
  color: #747474;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width:100%;
  max-height: 25px;
  white-space: pre-wrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.mirror-description{
   width:250px!important;
  @media (max-width: 1440px) {
    width:60%!important;
  }
}
</style>