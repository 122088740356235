<template>
  <!-- Inbox reply modal start here -->

  <div class="saveInboxReply">
    <div class="modal_body basic_form m_t_15">
      <div class="flex-row" ref="create_new_reply">
        <div class="flex-row">
          <div class="basic_form">
            <div class="col input_field">
              <label class="categoryHeader">Reply Name:</label>
              <input
                type="text"
                placeholder="Name this reply..."
                v-model="title"
              />
              <div v-if="titleIsEmpty" class="errorMessage"
                >* Reply name is compulsory!</div
              >
            </div>
            <div class="col input_field">
              <label class="categoryHeader" style="margin-top: 22px"
                >Message:</label
              >
              <div
                class="dropdown_header d-flex align-items-center dropdown-toggle"
                data-toggle="dropdown"
              >
                <span class="text">Insert Variables</span>
                <span class="ml-auto">
                  <i class="dropdown_arrow icon_last cs-angle-down"></i>
                </span>
              </div>
              <div class="dropdown-menu dropdown-menu-left">
                <div
                  v-for="(variables, header) in savedVariables"
                  :key="header"
                >
                  <p class="variableHeader">{{ header }}</p>
                  <div style="height: 1px; background: lightgray"></div>
                  <ul class="inner">
                    <li
                      v-for="variable in variables"
                      :key="variable.key"
                      class="variableItem"
                      @click="savedVariableIsSelected(variable)"
                      >{{ variable.description }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col input_field mt-0">
              <textarea
                ref="descriptionTextArea"
                row="5"
                v-model="description"
                placeholder="The text for the reply..."
              >
              </textarea>
              <div v-if="messageIsEmpty" class="errorMessage"
                >* Reply message is compulsory!</div
              >
            </div>
          </div>
          <div class="flex-row d-flex">
            <div class="text-left d-flex w-100">
              <div v-if="editReply" class="used-item">
                <p class="usage-details"
                  ><b>Used</b> <br />{{ editPayload.times_used }} times.</p
                >
              </div>
              <div v-if="editReply" class="used-item">
                <p v-if="editPayload.last_used" class="usage-details"
                  ><b>Last Used</b> <br />{{
                    commonMethods.timesAgo(editPayload.last_used)
                  }}.
                </p>
                <p v-else class="usage-details"><b>Last Used</b> <br />Never</p>
              </div>
            </div>
            <div class="text-right d-flex pr-1">
              <clip-loader
                v-if="isLoading"
                class="d-inline-block align-middle ml-1"
                style="margin-top: 15px"
                color="#989eb5"
                :size="'30px'"
              />
              <button
                class="btn blue royal_blue m-2 white-space-nowrap"
                @click="createUpdateReply"
              >
                <span>{{ editReply ? 'Update' : 'Create' }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Inbox reply modal end here -->
</template>
<style lang="less" scoped>
.usage-details {
  font-weight: 500;
}
.categoryHeader {
  font-weight: 700;
}

.variableHeader {
  height: 24px;
  font-size: 1rem;
  padding-left: 0.625rem;
  font-weight: 500;
}

.variableItem {
  font-size: 0.875rem;

  &:hover {
    background: #eaeaea;
    cursor: pointer;
  }
}

.errorMessage {
  font-size: 12px;
  color: red;
  padding-top: 5px;
  padding-left: 0.625rem;
}

.dropdown-toggle::after {
  display: none;
}

.saveInboxReply .modal_body textarea {
  height: 283px;
}

.saveInboxReply .dropdown-menu {
  left: 0 !important;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '../../store/common-methods'

export default {
  props: [
    'activeInboxDetail',
    'editReply',
    'editPayload',
    'shouldShowReplyListing'
  ],
  components: {},
  data () {
    return {
      isLoading: false,
      title: null,
      description: null,
      commonMethods: commonMethods,
      savedVariables: {
        Customer: [
          {
            description: 'Full Name',
            key: '{{%customer.full_name%}}',
            db_key: 'name'
          },
          {
            description: 'Email Address',
            key: '{{%customer.email_address%}}',
            db_key: 'email'
          },
          {
            description: 'Contact Number',
            key: '{{%customer.contact_number%}}',
            db_key: 'phone'
          },
          {
            description: 'Location',
            key: '{{%customer.location%}}',
            db_key: 'location'
          },
          {
            description: 'Job Title',
            key: '{{%customer.job_title%}}',
            db_key: 'job_title'
          },
          {
            description: 'Company',
            key: '{{%customer.company%}}',
            db_key: 'company'
          }
        ],
        User: [
          {
            description: 'Full Name',
            key: '{{%user.full_name%}}',
            db_key: 'full_name'
          },
          {
            description: 'First Name',
            key: '{{%user.first_name%}}',
            db_key: 'firstname'
          },
          {
            description: 'Last Name',
            key: '{{%user.last_name%}}',
            db_key: 'lastname'
          },
          {
            description: 'Email Address',
            key: '{{%user.email_address%}}',
            db_key: 'email'
          },
          {
            description: 'Contact Number',
            key: '{{%user.contact_number%}}',
            db_key: 'phone'
          }
        ]
      },
      titleIsEmpty: false,
      messageIsEmpty: false
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {
    this.initialSetup()
  },
  methods: {
    ...mapActions([
      'fetchSavedReplyVariables',
      'createSaveReply',
      'updateSaveReply',
      'deleteSaveReply',
      'selectSavedReply'
    ]),
    initialSetup () {
      if (this.editReply) {
        this.title = this.editPayload.title
        this.description = this.editPayload.description
      } else {
        this.title = null
        this.description = null
      }
    },
    async createUpdateReply () {
      if (!this.isLoading) {
        if (
          (typeof this.title === 'undefined' ||
            this.title === null ||
            this.title === '') &&
          (typeof this.description === 'undefined' ||
            this.description === null ||
            this.description === '')
        ) {
          this.titleIsEmpty = true
          this.messageIsEmpty = true
          return
        }

        if (
          typeof this.title === 'undefined' ||
          this.title === null ||
          this.title === ''
        ) {
          this.titleIsEmpty = true
          return
        }

        if (
          typeof this.description === 'undefined' ||
          this.description == null ||
          this.description === ''
        ) {
          this.messageIsEmpty = true
          return
        }

        this.isLoading = true
        var payload = {
          title: this.title,
          description: this.description,
          workspace_id: this.activeInboxDetail.workspace_id
        }

        if (this.editReply) {
          payload.saved_reply_id = this.editPayload._id
        }

        const resp = this.editReply
          ? await this.updateSaveReply(payload)
          : await this.createSaveReply(payload)

        if (resp.isValid) {
          this.isLoading = false
          this.alertMessage(resp.message, 'success')
          this.shouldShowReplyListing()
        } else {
          this.isLoading = false
          this.alertMessage(resp.message, 'error')
        }
      }
    },
    savedVariableIsSelected (variable) {
      if (this.description == null || this.description.length === 0) {
        this.description = variable.key
      } else {
        const originalText = this.description
        const textarea = this.$refs.descriptionTextArea
        const cursorPosition = textarea.selectionStart

        const beforeCursorPositionText = originalText.substring(
          0,
          cursorPosition
        )
        const afterCursorPositionText = originalText.substring(cursorPosition)

        this.description =
          beforeCursorPositionText + variable.key + afterCursorPositionText
      }
    }
  },
  watch: {
    title: function (newVal, oldVal) {
      if (newVal !== 'undefined' || newVal !== null || newVal !== '') {
        this.titleIsEmpty = false
      }
    },
    description: function (newVal, oldVal) {
      if (newVal !== 'undefined' || newVal !== null || newVal !== '') {
        this.messageIsEmpty = false
      }
    }
  }
}
</script>
