import {
  getDefaultVideoSharingDetails,
  pinterestSharingTypes
} from '../mutation-types'

const getDefaultPinterestSharingDetails = () => {
  return {
    pinterest_title: '',
    message: '',
    image: '',
    video: getDefaultVideoSharingDetails(),
    url: '',
    source_url: ''
  }
}
export default {
  state: {
    selection: getDefaultPinterestSharingDetails(),
    cancelledLink: '',
    progressBar: {
      image: 0,
      video: 0
    }
  },
  actions: {
    setPinterestSharingImage ({ commit }, image) {
      commit('setPinterestSharingImage', image)
    },

    setPinterestSharingMessage ({ commit }, message) {
      commit('setPinterestSharingMessage', message)
    },

    setPinterestSharingLink ({ commit }, url) {
      commit('setPinterestSharingLink', url)
    },

    setPinterestSharingSourceLink ({ commit }, url) {
      commit('setPinterestSharingSourceLink', url)
    },

    setPinterestSharingPreview ({ commit }, preview) {
      commit('setPinterestSharingPreview', preview)
    },

    setPinterestSharingDetails ({ commit }, data) {
      commit('setPinterestSharingDetails', data)
    },

    setDefaultPinterestSharing ({ commit }) {
      commit('setDefaultPinterestSharing')
    },

    setPinterestImagePercentage ({ commit }, percent) {
      commit('setPinterestImagePercentage', percent)
    },

    setPinterestCancelledLink ({ commit }, link) {
      commit('setPinterestCancelledLink', link)
    }
  },
  mutations: {
    setPinterestSharingImage (state, image) {
      state.selection.image = image
    },

    setPinterestSharingMessage (state, message) {
      state.selection.message = message
    },

    setPinterestSharingLink (state, url) {
      state.selection.url = url
      state.selection.source_url = url
    },

    setPinterestSharingSourceLink (state, url) {
      state.selection.source_url = url
    },

    setPinterestSharingPreview (state, preview) {
      if (preview) {
        state.selection.image = preview.image
      } else {
        state.selection.image = ''
        state.selection.url = ''
        state.selection.source_url = ''
      }
    },

    setPinterestSharingDetails (state, data) {
      if (data == null) {
        state.selection = getDefaultPinterestSharingDetails()
      } else {
        state.selection = data
      }
      state.cancelledLink = ''
    },

    setDefaultPinterestSharing (state) {
      state.selection = getDefaultPinterestSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        video: 0
      }
    },

    setPinterestImagePercentage (state, percent) {
      state.progressBar.image = percent
    },

    SET_PINTEREST_SHARING_VIDEO (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultVideoSharingDetails()
      }
    },

    SET_PINTEREST_SHARING_VIDEO_INITIALIZATION (state) {
      state.selection.image = ''
      state.selection.url = ''
      state.selection.source_url = ''
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    SET_PINTEREST_SHARING_VIDEO_PERCENTAGE (state, percent) {
      state.progressBar.video = percent
    },

    SET_PINTEREST_SHARING_VIDEO_THUMBNAIL (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    SET_PINTEREST_SHARING_VIDEO_SUGGESTIONS (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },

    setPinterestCancelledLink (state, link) {
      state.cancelledLink = link
    },
    [pinterestSharingTypes.RESET_PINTEREST_SHARING_DETAILS] (state) {
      state.selection = getDefaultPinterestSharingDetails()
      state.selection.progressBar = {
        image: 0,
        video: 0
      }
    }
  },
  getters: {
    getPinterestSharingDetails: (state) => {
      return state.selection
    },

    getPinterestSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getPinterestSharingProgressBar: (state) => {
      return state.progressBar
    }
  }
}
