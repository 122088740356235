function userPermission(workspace, user) {
  let permission = false
  if (user && workspace._id && workspace.members) {
    workspace.members.forEach(function (member) {
      if (member.user_id === user) {
        permission = member
        return false
      }
    })
  }
  return permission
}

function memberRole(workspace, user) {
  const permission = userPermission(workspace, user)
  if (permission) {
    if (permission.role === '1' || permission.role === 1) {
      return 'Admin'
    }
    if (permission.role === '0' || permission.role === 0) {
      return 'Collaborator'
    }
    return 'Super Admin'
  }
  return ''
}

function checkPlatformAccess(workspace, user, account, type) {
  if (user === account.user_id) {
    return true
  }
  const result = userPermission(workspace, user)
  let key = ''
  switch (type) {
    case 'wordpress':
      key = '_id'
      break
    case 'pinterest':
      key = 'pinterest_id'
      break
    case 'tumblr_blogs':
    case 'tumblr_profiles':
    case 'gmb':
      key = 'name'
      break
    default:
      key = type + '_id'
  }

  if (result && result.permissions && result.permissions[type]) {
    if (result.permissions[type].indexOf(account[key]) > -1) {
      return true
    }
    if (
      type === 'pinterest' &&
      result.permissions[type].indexOf(account.profile_id) > -1
    ) {
      return true
    }
  }

  return false
}

export { userPermission, memberRole, checkPlatformAccess }
