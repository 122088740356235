<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <div class="my-4 flex items-center">
      <div class="w-2/12">Publish As</div>
      <div class="w-10/12 flex items-center">
        <div class="w-8/12 md:w-12/12">
          <CstRadio
            id="insta_direct_publishing"
            :disabled="disabledAPI"
            :model-value="instagramPostingMethod"
            class="mr-2 mt-2"
            name="insta_posting_options"
            value="api"
            @change="(val) => (instagramPostingMethod = val)"
          >
            <div class="flex flex-col">
              <span
                >Direct Publishing via API
                <a
                  v-floatingtip.top-center="
                    'Learn about posting via Instagram API'
                  "
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="6017f963a4cefb30ae5c5e12"
                >
                  <i
                    class="far fa-question-circle ml-2 text-base text-blue-900"
                  ></i>
                </a>
              </span>
              <span class="text-gray-800 font-normal"
                >For Single/Multiple Images and Video/Reel Posts</span
              >
            </div>
          </CstRadio>
        </div>
        <div class="w-4/12 md:w-6/12">
          <CstRadio
            id="insta_mobile_notifications"
            :disabled="disabledMobile"
            :model-value="instagramPostingMethod"
            class="mr-2 mt-2"
            name="insta_posting_options"
            value="mobile"
            @change="(val) => (instagramPostingMethod = val)"
          >
            <div class="flex flex-col">
              <span
                >Mobile Notifications
                <a
                  v-floatingtip.top-center="
                    'Learn about posting via Mobile Notifications'
                  "
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="5ff6b174fd168b77735325f3"
                >
                  <i
                    class="far fa-question-circle ml-2 text-base text-blue-900"
                  ></i>
                </a>
              </span>
              <span class="text-gray-800 font-normal"
                >For Stories and IGTV</span
              >
            </div>
          </CstRadio>
        </div>
      </div>
    </div>
    <div
      v-if="instagramPostingMethod === 'api' && isVideoPost"
      class="my-4 flex items-center"
    >
      <div class="w-2/12">Post Type</div>
      <div class="w-10/12 flex items-center">
        <CstRadio
          id="feed"
          class="w-max mr-4"
          name="instagram_post_type"
          :model-value="instagramPostType"
          value="feed"
          @change="setInstagramPostType"
        >
          Feed
        </CstRadio>
        <CstRadio
          id="reel"
          class="w-max mr-4"
          name="instagram_post_type"
          :model-value="instagramPostType"
          value="reel"
          @change="setInstagramPostType"
        >
          Reel
        </CstRadio>
        <CstRadio
          id="feed_reel"
          class="w-max mr-4"
          name="instagram_post_type"
          :model-value="instagramPostType"
          value="feed_reel"
          @change="setInstagramPostType"
        >
          Feed + Reel
        </CstRadio>
      </div>
    </div>
    <div
      v-if="instagramPostingMethod === 'api' && instagramPostType !== 'feed'"
      class="my-2 flex items-center"
    >
      <div class="w-2/12"></div>
      <div class="w-10/12 flex items-center">
        <div class="bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
            <span class="font-semibold">Reels Tab Eligibility:</span>
            Only reels with a duration between 5 and 90 seconds and aspect
            ratios of 9:16 are eligible to appear in the Reels tab.
          </span>
          <span class="block mt-1 leading-4">
            Due to specific protocols set by the Instagram algorithm, the video
            file may not appear in the Reels tab if they don't meet the
            eligibility criteria, it will be available in the Feed section only.
            <a
              href="#"
              data-beacon-article-modal="62e10979b828a850e9e61a35"
              target="_blank"
              >Learn more.</a
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'

export default {
  name: 'InstagramOptions',
  components: { CstRadio },
  mixins: [ComposerHelperMixin],
  props: {
    instagramSelection: {
      type: Object,
      default: () => {},
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    isVideoPost: {
      type: Boolean,
      default: false,
    },
    instaPostingMethod: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      disabledAPI: false,
      disabledMobile: false,
    }
  },
  watch: {
    instagramSelection: {
      handler() {
        this.showInstagramOptions()
      },
      deep: true,
    },
    instagramPostingMethod: function (newVal) {
      this.$emit('setInstaPostingApi', newVal)
    },
  },
  methods: {
    /**
     * Checks for and assigns instagram api type
     */
    showInstagramOptions() {
      console.log('METHOD::showInstagramOptions')
      let postingMethod = this.instaPostingMethod
      this.disabledAPI =
        this.instagramSelection.no_access_accounts.length > 0 &&
        this.instagramSelection.all_access_accounts.length === 0
      this.disabledMobile = this.getMobileDevices.length === 0

      if (!this.instaPostingMethod) {
        postingMethod = !this.disabledAPI
          ? 'api'
          : !this.disabledMobile
          ? 'mobile'
          : null
      } else {
        if (
          this.disabledAPI &&
          !this.disabledMobile &&
          this.instaPostingMethod === 'api'
        ) {
          postingMethod = 'mobile'
        } else if (
          !this.disabledAPI &&
          this.disabledMobile &&
          this.instaPostingMethod === 'mobile'
        ) {
          postingMethod = 'api'
        } else if (this.disabledAPI && this.disabledMobile) {
          postingMethod = null
        }
      }

      this.instagramPostingMethod = postingMethod
      this.$emit('setInstaPostingApi', postingMethod)
    },
    setInstagramPostType(val) {
      this.$emit('setInstagramPostType', val)
    },
  },
}
</script>

<style lang="scss" scoped></style>
