<template>
  <div class="info_box">
    <img src="../../../../assets/img/analytics/data_fetching.gif" alt="" />
    <template v-if="isPageValid && name_list.length <= 0">
      <p
        >Navigate to the <i class="icon-settings-cs"></i> (Gear Icon) located at
        the top left of this view, to Add/Manage Competitors for this page.</p
      >
    </template>
    <template v-else>
      <p v-if="name_list.length == 1">
        We are fetching data and crunching numbers for
        <span>{{ name_list[0].name }} ,</span> page. It may take up to half an
        hour for the competitor analytics to be available.
      </p>
      <p v-else>
        We are fetching data and crunching numbers for
        <span v-for="(obj, idx) in name_list"
          >{{ obj.name
          }}<template v-if="idx !== name_list.length - 1">, </template>
        </span>
        pages. It may take up to half an hour for the competitor analytics to be
        available.
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    name_list: [],
    isPageValid: false
  }
}
</script>

<style scoped></style>
