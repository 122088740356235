var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"approval-modal","centered":"","hide-footer":"","header-class":"cs-modal-header","modal-class":"custom-thumbnail-modal","title-class":"cs-modal-title","no-close-on-backdrop":"","size":"xl"},on:{"hidden":() => {
        _vm.selectedCount = 0
        _vm.message = ''
        _vm.type = 'anyone'
        _vm.selectedApprover = []
        _vm.selectedData = []
        _vm.isDisabled = false
        _vm.memberSearch = ''
      }}},[_c('template',{slot:"modal-title"},[_vm._v("Send for Approval")]),_c('div',{staticClass:"px-2 2 h-auto"},[_c('div',{staticClass:"relative flex flex-col w-full lg:h-fit py-1 px-5 items-center overflow-auto grow",attrs:{"id":"modalBody"}},[_c('div',{staticClass:"flex flex-col md:flex-row justify-between items-center w-full"},[_c('div',{staticClass:"grid grid-cols-1 place-items-center"},[_c('p',{staticClass:"text-gray-900 text-sm"},[_vm._v("Selected approver(s): "),_c('span',{staticClass:"font-bold text-black"},[_vm._v(_vm._s(_vm.selectedData.length))])])]),_c('div',{staticClass:"search-bar-input"},[_c('div',{staticClass:"search-bar-inner"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberSearch),expression:"memberSearch"}],attrs:{"type":"text","placeholder":"Search by name..."},domProps:{"value":(_vm.memberSearch)},on:{"input":function($event){if($event.target.composing)return;_vm.memberSearch=$event.target.value}}}),_c('button',{staticClass:"search_btn"},[_c('i',{staticClass:"icon-Search"})])])])]),_c('div',{staticClass:"w-full h-72 max-h-96 relative mt-5 overflow-auto py-1 px-2"},[(_vm.filteredMembers.length >= 1)?_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full h-auto m-auto"},[_vm._l((_vm.filteredMembers),function(card,key){return [_c('CstCardCheckbox',{key:key,staticClass:"w-full overflow-hidden",attrs:{"value":card.user_id,"title":card.user.firstname ? card.user.full_name : card.user.email},model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"rounded-full w-10 h-10",attrs:{"src":card.user.image
                        ? card.user.image
                        : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',"alt":""},on:{"error":function($event){$event.target.src =
                        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'}}}),_c('div',{staticClass:"flex flex-col justify-center items-start w-full ml-4 truncate"},[(card.user.firstname && card.user.lastname)?_c('span',{staticClass:"font-medium text-sm"},[_vm._v(" "+_vm._s(card.user.firstname)+" "+_vm._s(card.user.lastname)+" ")]):_c('span',{staticClass:"font-medium text-sm"},[_vm._v(_vm._s(card.user.email))]),_c('span',{staticClass:"text-xs",class:_vm.getRoleClass(card.role)},[_vm._v(" "+_vm._s(_vm.getMutatedUserRole(card.role))+" ")])])])])]})],2):(_vm.filteredMembers.length < 1)?_c('div',{staticClass:"w-full h-auto text-lg p-4 flex justify-center items-center"},[_vm._v(" No member found. ")]):_vm._e()]),_c('div',{staticClass:"w-full h-auto h-1/5 sm:h-fit"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"block p-2.5 h-16 w-full border-2 border-gray-400 rounded-lg focus:outline-none focus:border-blue-400 hover:border-blue-300 resize-none",attrs:{"id":"message","rows":"4","placeholder":"Add a note to the approver(s)"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}})])]),_c('div',{staticClass:"flex flex-col md:flex-row w-full h-fit py-2 md:py-4 px-5 justify-between items-center",attrs:{"id":"modalFooter"}},[_c('div',{staticClass:"grid lg:grid-cols-2 lg:gap-x-3 xl:gap-x-5 pb-4 md:pb-0 md:py-0"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('p',{staticClass:"text-sm"},[_vm._v("Post needs approval from: ")])]),_c('div',{staticClass:"grid grid-cols-2 gap-x-3"},[_c('CstRadio',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                _vm.selectedData.length < 2
                  ? 'Please select at least two team members to set the approval rule.'
                  : 'Approval from any of the assigned team member will schedule the post.'
              ),expression:"\n                selectedData.length < 2\n                  ? 'Please select at least two team members to set the approval rule.'\n                  : 'Approval from any of the assigned team member will schedule the post.'\n              ",modifiers:{"hover":true}}],attrs:{"name":"thumbnail-type","value":"anyone","checked":"","disabled":_vm.selectedData.length < 2},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_vm._v(" Anyone ")]),_c('CstRadio',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                _vm.selectedData.length < 2
                  ? 'Please select at least two team members to set the approval rule.'
                  : 'All of the assigned team members would need to approve the post in order to be scheduled.'
              ),expression:"\n                selectedData.length < 2\n                  ? 'Please select at least two team members to set the approval rule.'\n                  : 'All of the assigned team members would need to approve the post in order to be scheduled.'\n              ",modifiers:{"hover":true}}],attrs:{"name":"thumbnail-type","value":"everyone","disabled":_vm.selectedData.length < 2},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_vm._v(" Everyone ")])],1)]),_c('div',{staticClass:"flex justify-end"},[_c('CstButton',{staticClass:"mr-3 w-24",attrs:{"size":"large","variant":"secondary","text":"Cancel"},on:{"click":_vm.closeModal}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
              !_vm.filteredMembers || _vm.selectedData.length === 0
                ? 'You must select an approver'
                : 'Send for approval'
            ),expression:"\n              !filteredMembers || selectedData.length === 0\n                ? 'You must select an approver'\n                : 'Send for approval'\n            ",modifiers:{"hover":true}}]},[_c('CstButton',{staticClass:"w-20",attrs:{"variant":"primary","size":"large","text":"Send","disabled":!_vm.filteredMembers || _vm.selectedData.length === 0 || _vm.isDisabled},on:{"click":_vm.sendForApproval}})],1)],1)])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }