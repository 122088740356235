<template>
  <ul class="inner max-height">
    <!--<li class="status_option_list">-->
    <!--<div class="checkbox_input_image">-->
    <!--<input id="all_plans" type="checkbox" checked="checked"  :checked="composerAllStatusSelected">-->
    <!--<label for="all_plans" class="checkbox_right">-->
    <!--<span class="">Select All</span>-->
    <!--</label>-->
    <!--</div>-->
    <!--</li>-->

    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="select_all"
        >
          <span class="status all fa fa-toggle-on"></span> Select All

          <input
            class="float-right"
            id="select_all"
            :checked="true"
            v-model="getPublications.check_all_statuses"
            value="Select All"
            type="checkbox"
            @change="selectAllStatus()"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>

    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
           <span class="status all fa fa-toggle-on"></span>
          <input id="select_all" :checked="true"
                 v-model="getPublications.check_all_statuses" value="Select All" type="checkbox" @change="selectAllStatus()" class="float-right">
          <label for="select_all" class="checkbox_right">
              <span>Select All</span>
          </label>
        </div>
      </li> -->

    <!-- in progress status  -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="in_progress"
        >
          <span class="status inProgress fas fa-spinner"></span> In Progress
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('In Progress')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="in_progress"
            value="In Progress"
            :checked="isComposerStatusSelected('In Progress')"
            v-model="getPublications.status"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status inProgress fas fa-spinner"></span>
          <input id="in_progress" value="In Progress" :checked="isComposerStatusSelected('In Progress')"
                 v-model="getPublications.status" type="checkbox" class="float-right" >
          <label for="in_progress" class="checkbox_right">
            <span>In Progress<a @click.prevent="changeComposerPlansStatusOnlyFilter('In Progress')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="scheduled_plans"
        >
          <span class="status scheduled far fa-clock"></span> Scheduled
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Scheduled')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="scheduled_plans"
            :checked="isComposerStatusSelected('Scheduled')"
            v-model="getPublications.status"
            value="Scheduled"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>

    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status scheduled far fa-clock"></span>
          <input id="scheduled_plans" :checked="isComposerStatusSelected('Scheduled')"
                 v-model="getPublications.status" value="Scheduled" type="checkbox" class="float-right" >
          <label for="scheduled_plans" class="checkbox_right">
              <span>Scheduled<a @click.prevent="changeComposerPlansStatusOnlyFilter('Scheduled')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="published_plans"
        >
          <span class="status published far fa-check-circle"></span> Published
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Published')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="published_plans"
            :checked="isComposerStatusSelected('Published')"
            v-model="getPublications.status"
            value="Published"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="partially_published_plans"
        >
          <span
            class="status failed fas fa-adjust"
            style="transform: rotate(270deg)"
          ></span>
          Partially Failed
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Partial')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="partially_published_plans"
            :checked="isComposerStatusSelected('Partial')"
            v-model="getPublications.status"
            value="Partial"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!--<li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status published far fa-check-circle"></span>
          <input id="published_plans" :checked="isComposerStatusSelected('Published')"
                 v-model="getPublications.status" value="Published" type="checkbox" class="float-right" >
          <label for="published_plans" class="checkbox_right">
            <span>Published<a @click.prevent="changeComposerPlansStatusOnlyFilter('Published')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="failed_plans"
        >
          <span class="status failed far fa-ban"></span> Failed
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Failed')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="failed_plans"
            :checked="isComposerStatusSelected('Failed')"
            v-model="getPublications.status"
            value="Failed"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status failed far fa-ban"></span>
          <input id="failed_plans" :checked="isComposerStatusSelected('Failed')"
                 v-model="getPublications.status" value="Failed" type="checkbox" class="float-right" >
          <label for="failed_plans" class="checkbox_right">
            <span>Failed<a @click.prevent="changeComposerPlansStatusOnlyFilter('Failed')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="rejected_plans"
        >
          <span class="status reject far fa-times-circle"></span> Rejected
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Rejected')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="rejected_plans"
            value="Rejected"
            :checked="isComposerStatusSelected('Rejected')"
            v-model="getPublications.status"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status reject far fa-times-circle"></span>
          <input id="rejected_plans" value="Rejected" :checked="isComposerStatusSelected('Rejected')"  v-model="getPublications.status"
                 type="checkbox" class="float-right" >
          <label for="rejected_plans" class="checkbox_right">
            <span>Rejected<a @click.prevent="changeComposerPlansStatusOnlyFilter('Rejected')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="under_review_plans"
        >
          <span class="status underReview far fa-eye"></span> Under Review
          <a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Under Review')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="under_review_plans"
            :checked="isComposerStatusSelected('Under Review')"
            v-model="getPublications.status"
            value="Under Review"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status underReview far fa-eye"></span>
          <input id="under_review_plans" :checked="isComposerStatusSelected('Under Review')"
                 v-model="getPublications.status" value="Under Review" type="checkbox" class="float-right" >
          <label for="under_review_plans" class="checkbox_right">
            <span>Under Review<a @click.prevent="changeComposerPlansStatusOnlyFilter('Under Review')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="missed_review_plans"
        >
          <span class="status missedReview far fa-eye-slash"></span> Missed
          Review
          <a
            @click.prevent="
              changeComposerPlansStatusOnlyFilter('Missed Review')
            "
            class="onlyLink"
            >Only</a
          >
          <input
            id="missed_review_plans"
            :checked="isComposerStatusSelected('Missed Review')"
            v-model="getPublications.status"
            value="Missed Review"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status missedReview far fa-eye-slash"></span>
          <input id="missed_review_plans" :checked="isComposerStatusSelected('Missed Review')"
                 v-model="getPublications.status" value="Missed Review" type="checkbox" class="float-right" >
          <label for="missed_review_plans" class="checkbox_right">
            <span>Missed Review<a @click.prevent="changeComposerPlansStatusOnlyFilter('Missed Review')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
    <li class="field_group status_option_list">
      <div class="checkbox_container checkbox_input_image">
        <label
          class="checkbox_right d-flex align-items-center"
          for="draft_plans"
        >
          <span class="status draft far fa-folder-plus"></span>Draft<a
            @click.prevent="changeComposerPlansStatusOnlyFilter('Draft')"
            class="onlyLink"
            >Only</a
          >
          <input
            id="draft_plans"
            value="Draft"
            :checked="isComposerStatusSelected('Draft')"
            v-model="getPublications.status"
            type="checkbox"
            class="float-right"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>

    <!-- <li class="status_option_list">
        <div class="checkbox_input_image showlink d-flex align-items-center">
          <span class="status draft far fa-folder-plus"></span>
          <input id="draft_plans" value="Draft" :checked="isComposerStatusSelected('Draft')"
                 v-model="getPublications.status" type="checkbox" class="float-right" >
          <label for="draft_plans" class="checkbox_right">
            <span>Draft<a @click.prevent="changeComposerPlansStatusOnlyFilter('Draft')" class="onlyLink">Only</a></span>
          </label>
        </div>
      </li> -->
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { composer } from '@src/modules/composer/store/composer-mutation-type'

export default {
  props: [],
  components: {},

  data () {
    return {}
  },

  created () {},

  computed: {
    ...mapGetters(['getPublications'])
  },

  methods: {
    isComposerStatusSelected (status) {
      this.$store.commit(
        composer.SET_CHECK_ALL_STATUSES,
        this.getPublications.status.length === 9
      )
      return this.getPublications.status.indexOf(status) >= 0
    },
    composerAllStatusSelected () {
      if (this.getPublications.check_all_statuses) {
        const status = [
          'Scheduled',
          'Published',
          'Partial',
          'Failed',
          'Rejected',
          'Under Review',
          'Missed Review',
          'Draft',
          'In Progress'
        ]
        this.getPublications.status = [...status]
      }
    },
    changeComposerPlansStatusOnlyFilter (status) {
      this.$store.commit(composer.SET_PUBLICATIONS_STATUS, [status])
    },
    selectAllStatus () {
      if (this.getPublications.check_all_statuses) {
        // Select All Status
        const status = [
          'Scheduled',
          'Published',
          'Partial',
          'Failed',
          'Rejected',
          'Under Review',
          'Missed Review',
          'Draft',
          'In Progress'
        ]
        this.$store.commit(composer.SET_PUBLICATIONS_STATUS, status)
      } else {
        // unselect All Status
        this.$store.commit(composer.SET_PUBLICATIONS_STATUS, [])
      }
    }
  },

  watch: {
    'getPublications.status' () {
      if (this.$route.name === 'composer') this.refetchPublications()
    }
  }
}
</script>
<style lang="less">
.onlyLink {
  visibility: hidden;
  text-decoration: underline;
  font-size: 11px !important;
  border: 1px solid #dee2e8;
  padding: 2px 9px;
  border-radius: 13px;
}
.onlyLink:hover {
  font-weight: bold;
}
.showlink:hover .onlyLink {
  visibility: visible;
}
.status_option_list {
  padding: 0.6rem 1.4rem;
  .status {
    display: inline-block;
    float: left;
    margin-right: 0.6rem;
  }
  .status.all {
    color: #2f8ae0;
  }
  .status.scheduled {
    color: #ff9300;
  }
  .status.published {
    color: #1ecb7b;
  }
  .status.failed {
    color: #c00000;
  }
  .status.reject {
    color: #ea7070;
  }
  .status.underReview {
    color: #dde044;
  }
  .status.missedReview {
    color: #7d7aff;
  }
  .status.inProgress {
    color: #2f8ae0;
  }
  label {
    color: #3a4557 !important;
    display: flex;
    align-items: center;
    span {
      overflow: hidden;
      white-space: nowrap;
      max-width: 65%;
      text-overflow: ellipsis;
    }
  }
  .checkbox_right {
    width: 100% !important;
    &:after {
      left: 95% !important;
    }
    &:before {
      left: 95% !important;
    }
    .onlyLink {
      margin: 0 0.5rem;
      padding: 1px 9px;
    }
  }
}
</style>
