<template>
  <div
    class="filter_section_inner width_400"
    :class="{ 'no-padding-content-filter': !padding }"
  >
    <div class="icon_tabs">
      <!--indicator_animation-->

      <ul class="tab_ul tab_ul_2" data-cy="discovery_content_options">
        <li class="tab_li" :class="{ active: getTopicModule === 'insights' }">
          <a
            class="tab_a gray_dark active_gradient"
            href="javascript:;"
            :class="{ active: getTopicModule === 'insights' }"
            @click.prevent="changeSearchFeed('insights')"
          >
            <i class="icon-Insights_icon"></i>
            <span>Insights</span>
          </a>
        </li>
        <li class="tab_li" :class="{ active: getTopicModule === 'content' }">
          <a
            class="tab_a gray_dark active_gradient"
            href="javascript:;"
            :class="{ active: getTopicModule === 'content' }"
            @click.prevent="changeSearchFeed('content')"
          >
            <i class="icon-Content-Feed_icon"></i>
            <span>Content Feed</span>
          </a>
        </li>
        <!--<li class="tab_li beta-li" :class="{'active': getTopicModule === 'influencers'}">-->
        <!--<a class="tab_a gray_dark active_gradient" st href="javascript:;"-->
        <!--:class="{'active': getTopicModule === 'influencers'}" data-cy="discovery_influencer"-->
        <!--@click.prevent="changeSearchFeed('influencers')">-->
        <!--<i class="influencer_icon cs-group-o"></i>-->
        <!--<span>Influencers</span>-->
        <!--<span class="nav-beta">Beta</span>-->
        <!--</a>-->
        <!--</li>-->
        <!--<li class="tab_li_indicator"></li>-->
      </ul>
    </div>
    <!--<button class="btn" data-target="#influencer_detail_modal" data-toggle="modal">test influencer detail modal</button>-->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
  articlesTypes as articleTypes,
  insightTypes
} from '../../../store/mutation-types'

export default {
  props: {
    padding: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getTopicModule', 'getFilters'])
  },
  methods: {
    ...mapMutations([
      'SET_TOPIC_TYPE',
      'SET_TOPIC_MODULE',
      articleTypes.SET_CONTENT_LOADER,
      insightTypes.SET_INSIGHTS_LOADER,
      insightTypes.SET_INSIGHTS_CHARTS_LOADER
    ]),
    ...mapActions(['articles', 'articlesInsights', 'fetchInfluencers']),
    async changeSearchFeed (type) {
      console.log('method: ChangeSearchFeed')
      switch (type) {
        case 'insights':
          this.SET_TOPIC_MODULE('insights')
          this.SET_TOPIC_TYPE('search')

          if (!this.getFilters.displayTopics) this.articlesInsights()
          // this.changeTopicModule('insights', 'article_view')
          break
        case 'content':
          this[articleTypes.SET_CONTENT_LOADER](true)
          this.SET_TOPIC_MODULE('content')
          this.SET_TOPIC_TYPE('content')
          if (!this.getFilters.displayTopics) this.articles()
          break
        case 'influencers':
          this.SET_TOPIC_MODULE('influencers')
          break
      }
    }
  }
}
</script>

<style scoped>
.no-padding-content-filter {
  padding-top: 0 !important;
}
</style>
