export default {
  state: {
    filters: {
      page: 0,
      limit: 30,
      sort: 'recent',
      pagetoken: null,
      language: {
        languageName: 'All',
        code: 'all'
      },
      placeId: null,
      sinceId: null
    },
    tweets: [],
    scroll: true,

    content: 'topic',

    loader: {
      search: {
        button: {
          search: false
        },
        content: {
          twitterLoader: false,
          infiniteLoader: false
        }
      }
    }
  },
  actions: {
    setTweetsContent ({ commit }, tweets) {
      commit('setTweetsContent', tweets)
    },
    setTwitterScroll ({ commit }, status) {
      commit('setTwitterScroll', status)
    },
    setTwitterPaginationLoader ({ commit }, status) {
      commit('setTwitterPaginationLoader', status)
    },
    setDiscoveryTwitterLoader ({ commit }, status) {
      commit('setDiscoveryTwitterLoader', status)
    },
    setTwitterPageToken ({ commit }, status) {
      commit('setTwitterPageToken', status)
    },
    setTwitterContentPage ({ commit }, page) {
      commit('setTwitterContentPage', page)
    },
    setTwitterContentLanguage ({ commit }, language) {
      commit('setTwitterContentLanguage', language)
    },
    resetTwitter ({ commit }) {
      commit('resetTwitter')
    },

    setTwitterContentType ({ commit }, status) {
      commit('setTwitterContentType', status)
    }
  },
  mutations: {
    setTweetsContent (state, tweets) {
      state.tweets = tweets
    },
    setTwitterScroll (state, status) {
      state.scroll = status
    },

    setTwitterPaginationLoader (state, status) {
      state.loader.search.content.infiniteLoader = status
    },
    setDiscoveryTwitterLoader (state, status) {
      state.loader.search.content.twitterLoader = status
    },
    setTwitterPageToken (state, token) {
      state.filters.pagetoken = token
    },
    setTwitterContentPage (state, page) {
      state.filters.page = page
    },
    setTwitterContentLanguage (state, language) {
      if (language) {
        state.filters.language = language
      } else {
        state.filters.language = {
          languageName: 'All',
          code: 'all'
        }
      }
    },
    setTwitterContentType (state, type) {
      state.content = type
    },
    resetTwitter (state) {
      state.filters = {
        page: 0,
        limit: 30,
        sort: 'recent',
        pagetoken: null,
        language: {
          languageName: 'All',
          code: 'all'
        },
        placeId: null,
        sinceId: null
      }
      state.tweets = []
      state.scroll = true

      state.content = 'topic'

      state.loader = {
        search: {
          button: {
            search: false
          },
          content: {
            twitterLoader: false,
            infiniteLoader: false
          }
        }
      }
    }
  },
  getters: {
    getTwitterContentFilter: (state) => {
      return state.filters
    },
    getTwitterContentPage: (state) => {
      return state.filters.page
    },
    getTwitterLanguage: (state) => {
      return state.filters.language
    },
    getTweetsContent: (state) => {
      return state.tweets
    },
    getTwitterPaginationLoader: (state) => {
      return state.loader.search.content.infiniteLoader
    },
    getDiscoveryTwitterLoader: (state) => {
      return state.loader.search.content.twitterLoader
    },
    getTwitterScroll: (state) => {
      return state.scroll
    },
    // getYoutubeSort: state => {
    //   return state.filters.youtube.sort
    // },
    //
    getTwitterContentType: (state) => {
      return state.content
    }
  }
}
