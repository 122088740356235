/*
 * Helper file to manage settings api calls
 */

import proxy from '@common/lib/http-common'
import { authenticationBaseUrl } from '@src/config/api-utils'

export const ACTION_TYPES = {
  ENABLE_2FA: 'ENABLE_2FA',
  DISABLE_2FA: 'DISABLE_2FA',
  VALIDATE_2FA: 'VALIDATE_2FA',
  GENERATOR_BACKUP_CODES: 'GENERATOR_BACKUP_CODES',
  FETCH_BACKUP_CODES: 'FETCH_BACKUP_CODES',
}

/**
 * Service method to handle all 2FA related calls
 * @param {string} action - action to be performed
 * @param {object} payload - payload to be sent
 */
export const serviceGoogle2fa = (action = '', payload = {}) => {
  let apiUrl = ``
  if (action === ACTION_TYPES.ENABLE_2FA) {
    apiUrl = `${authenticationBaseUrl}2fa/generator/google`
  } else if (action === ACTION_TYPES.DISABLE_2FA) {
    apiUrl = `${authenticationBaseUrl}2fa/disable`
  } else if (action === ACTION_TYPES.VALIDATE_2FA) {
    apiUrl = `${authenticationBaseUrl}2fa/enable/google`
  } else if (action === ACTION_TYPES.GENERATOR_BACKUP_CODES) {
    apiUrl = `${authenticationBaseUrl}2fa/generator/backup_codes`
  } else if (action === ACTION_TYPES.FETCH_BACKUP_CODES) {
    apiUrl = `${authenticationBaseUrl}2fa/fetch/backup_codes`
  }

  return new Promise((resolve, reject) => {
    proxy
      .post(apiUrl, payload, {})
      .then((response) => {
        if (response?.data?.status) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) reject(error.response.data)
        else reject(error)
      })
  })
}

/**
 * Service method to download pdf of backup codes
 * @param {object} payload - payload to be sent
 */
export const downloadPdfBackupCodes = (payload = {}) => {
  return new Promise((resolve, reject) => {
    proxy
      .post(`${authenticationBaseUrl}2fa/print/backup_codes`, payload, {
        responseType: 'blob',
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error?.response?.data?.message) reject(error.response.data)
        else reject(error)
      })
  })
}
