<template>
  <div class="warning_box_zapier">
    <div class="text_container">
      <div v-if="no_access_accounts.length > 0">
        <div class="header">
          <i class="fas fa-exclamation-triangle"></i>
          <span
            v-tooltip="{
              content: channelTooltipMultipleHTML(
                'instagram',
                no_access_accounts,
              ),
              classes: 'team_tooltip',
            }"
            v-html="getDescriptionForPermissionLinkedAccounts()"
          ></span>
        </div>
      </div>
<!--      <div v-if="all_access_accounts.length > 0 && multiple_images">-->
<!--        <div class="header">-->
<!--          <i class="fas fa-exclamation-triangle"></i>-->
<!--          <span-->
<!--            v-tooltip="{-->
<!--              content: channelTooltipMultipleHTML(-->
<!--                'instagram',-->
<!--                all_access_accounts,-->
<!--              ),-->
<!--              classes: 'team_tooltip',-->
<!--            }"-->
<!--            v-html="getDescriptionForAPILinkedAccounts()"-->
<!--          ></span>-->
<!--        </div>-->

<!--        <ul>-->
<!--          <li v-if="multiple_images"-->
<!--            >Up to 10 images can be posted as a Carousel post through the Instagram API.</li-->
<!--          >-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </div>
</template>

<style lang="less" scoped>
.warning_box_zapier {
  margin-top: 20px;
  border-radius: 0.625rem;
  background: #faf2dc;

  .header {
    color: #8e8c1a;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .unlinked {
    color: #8e8c1a;
    font-size: 0.875rem;
    font-weight: 500;
  }

  ul {
    margin-left: 50px;
  }

  li {
    list-style-type: initial;
  }

  i {
    padding: 7px;
    color: #d9d623;
  }
}

.text_container {
  padding: 0.625rem;
}
</style>

<script>
/* eslint-disable */

import { mapGetters, mapActions } from 'vuex'

export default {
  props: [
    'multiple_images',
    'aspect_ratio',
    'no_access_accounts',
    'all_access_accounts',
  ],
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    getDescriptionForPermissionLinkedAccounts() {
      if (this.no_access_accounts.length > 0) {
        let postingAccounts =
          this.no_access_accounts.length > 1 // if count > 1
            ? '<u>' + this.no_access_accounts.length + ' accounts</u>' // then with count add 'account' in the underline
            : '<u>' + this.no_access_accounts[0].name + '</u> account' // else only count should be underline
        return (
          'Instagram direct publishing method cannot be used on ' +
          postingAccounts +
          ' because ' +
          'you need to reconnect this account to provide the required permissions.'
        )
      }
    },
    // getDescriptionForAPILinkedAccounts() {
    //   if (this.all_access_accounts.length > 0) {
    //     let postingAccounts =
    //       this.all_access_accounts.length > 1 // if count > 1
    //         ? '<u>' + this.all_access_accounts.length + ' account</u>' // then with count add 'account' in the underline
    //         : '<u>' + this.all_access_accounts[0].name + '</u> account' // else only count should be underline
    //     return (
    //       'This post will fail to publish via Instagram API on ' +
    //       postingAccounts +
    //       ' for the following reason(s):'
    //     )
    //   }
    // },
  },
  watch: {},
}
</script>
