<template>
  <div
    class="cst-editor-threaded-tweets color-border rounded-xl px-3 py-3 mt-5 p-relative"
  >
    <div class="flex items-center">
      <div class="w-48 ml-1 flex items-center">
        <div
          class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-gray-400"
        >
          <img
            src="@assets/img/composer/threaded-tweet-icon.svg"
            alt=""
            class="w-5 h-5"
          />
        </div>
        <span class="mx-2 font-medium text-sm text-black-300"
          >Threaded Tweet</span
        >
        <v-popover
          trigger="hover"
          placement="bottom-end"
          popover-class="threaded-tweets__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
            ><img
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template slot="popover">
            <div> Threaded Tweets are available for: </div>
            <ul>
              <li> <i class="fab fa-twitter"></i> Profiles </li>
            </ul>
            <p class="threaded-tweets__message-box"
              >How to add threaded tweets?
              <a
                v-close-popover="true"
                href="#"
                class="beacon"
                data-beacon-article-modal="61826fd112c07c18afde3ed5"
                >Click here</a
              ></p
            >
          </template>
        </v-popover>
      </div>
      <div>
        <CstSwitch
          v-model="twitterOptions.has_threaded_tweets"
          class="mx-2"
          :disabled="accounts.length < 1"
          size="small"
        />
      </div>
      <div class="ml-auto">
        <template v-for="(account, index) in accounts">
          <CstAccountCheckBox
            :key="index"
            :account="account"
            size="small"
            :channel-id="account[account.channel_identifier]"
            name="some"
            class="px-1 mb-0"
            :account-image="account.image"
            :account-name="account.name"
            collapsed
            :disabled="false"
            :is-threaded-tweet="true"
            account-type="Page"
            account-platform="twitter"
            :is-checked="true"
          />
        </template>
      </div>
    </div>

    <template>
      <div class="mt-1">
        <draggable
          :list="twitterOptions.threaded_tweets"
          handle=".handle"
          @start="sortStart"
          @end="sortEnd"
        >
          <template v-for="(tweet, index) in twitterOptions.threaded_tweets">
            <div
              :key="index"
              class="mt-3 p-relative flex"
              :class="{ 'opacity-0': index === focusElement }"
            >
              <span
                v-show="twitterOptions.threaded_tweets.length > 1"
                class="handle flex flex-col justify-center text-sm cursor-move bg-cs-secondary rounded-l-lg text-black-100 border-0 px-2 w-max"
              >
                <img src="@assets/img/icons/sorting_gray.svg" width="12" />
              </span>
              <LiteEditorBox
                :tweet-index="index"
                :widgets="getEditorSettings.widgets"
                :max-limit="getEditorSettings.maxLimit"
                :twitter-text-enable="getEditorSettings.twitterTextEnable"
                :sharing-details="tweet"
                :accounts-data="accountsData"
                :apply-hashtag="applyHashtag"
                type="threaded-tweet"
                @onEditorBoxTextChange="
                  (type, message, count) =>
                    onEditorBoxTextChange(type, message, index, count)
                "
                @initializeVideoUpload="(type) => initializeVideoUpload(index)"
                @addMedia="
                  (type, media, mediaType) => addMedia(index, media, mediaType)
                "
                @removeMedia="
                  (type, mediaType, media, removeAll) =>
                    handleRemoveMedia(index, mediaType, media, removeAll)
                "
                @handle-change="
                  (thread, type) => onChangeThreadDetails(thread, index)
                "
                @handle-lite-editor-hashtags="
                  (flag, data) =>
                    handleThreadedTweetHashtagsChanges(index, flag, data)
                "
                v-on="$listeners"
              />
            </div>
          </template>
        </draggable>
      </div>
      <div class="my-4 flex items-center">
        <div>
          <span
            class="ml-0.5 text-sm cursor-pointer rounded-lg bg-cs-secondary text-black-100 border-0 px-2 py-2"
            @click.prevent="addThreadedTweet"
          >
            <i class="fas fa-plus before:text-black-100 mr-1"></i>
            Add another tweet
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CstAccountCheckBox from '@ui/CheckBox/CstAccountCheckBox'
import {
  defaultTwitterOptions,
  videoDefaultSharingDetails,
} from '@src/modules/composer_v2/views/composerInitialState'

import editorInitialValue from '@src/modules/composer_v2/components/EditorBox/editorInitialValue'
import { twitterPostLimit } from '@src/modules/publish/config/api-utils'
import LiteEditorBox from '@src/modules/composer_v2/components/EditorBox/LiteEditorBox'
import CstSwitch from '@ui/Switch/CstSwitch'
import draggable from 'vuedraggable'
import {EventBus} from "@common/lib/event-bus";
const clone = require('rfdc/default')

export default {
  name: 'EditorThreadedTweetsBox',
  components: {
    LiteEditorBox,
    CstAccountCheckBox,
    CstSwitch,
    draggable,
  },
  props: {
    twitterOptions: {
      type: Object,
      default: () => {
        return { ...defaultTwitterOptions }
      },
    },
    accounts: {
      type: Array,
      default: () => {
        return []
      },
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      focusElement: null,
    }
  },

  computed: {
    getEditorSettings() {
      return {
        toolbar: {
          ...editorInitialValue.toolbar,
          threadedTweets: false,
          location: true,
        },
        twitterTextEnable: true,
        maxLimit: twitterPostLimit,
      }
    },

    // canAddAnotherTweet() {
    //   const lastThread = this.twitterOptions.threaded_tweets[this.twitterOptions.threaded_tweets.length - 1]
    //   return (lastThread.message || (lastThread.image && lastThread.image.length) || (lastThread.video && lastThread.video.link))
    // },
  },

  mounted() {
    console.debug('Threaded Tweets mounted')
    EventBus.$on(
      'add-media-from-media-library-threaded-tweet',
      ({type, media, mediaType, threadIndex}) => {
        console.debug(
          'add-media-from-media-library-threaded-tweet',
          type,
          media,
          mediaType,
          threadIndex
        )
        this.addMedia(threadIndex, media, mediaType)
      }
    )
  },

  beforeDestroy() {
    EventBus.$off('add-media-from-media-library-threaded-tweet')
  },

  methods: {
    sortStart(event) {
      this.focusElement = event.oldIndex
    },
    sortEnd(event) {
      this.focusElement = null
    },
    /**
     * @description Add another threaded tweet
     */
    addThreadedTweet() {
      this.$emit('addThreadedTweet')
    },

    /**
     * @description Close or remove a threaded tweet
     * @param {number} index
     */
    removeThreadedTweet(index) {
      this.$emit('removeThreadedTweet', index)
    },

    /**
     * @description Event handlers on Editor Box Text Change for Threaded Tweets
     * @param type {string} type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param message {string} message
     * @param index {int} thread index
     * @param count character count
     */
    onEditorBoxTextChange: function (type, message, index, count) {
      const thread = clone(this.twitterOptions.threaded_tweets[index])
      thread.message = message
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Remove link preview
     * @param index index of thread
     * @param removeImage remove link preview image from details (true/false) default false
     * @param triggerEvent trigger event to parent component (true/false) default true
     * @param details Thread details object default null
     */
    removeLinkPreview: function (
      index,
      removeImage = false,
      triggerEvent = true,
      details = null
    ) {
      details = details || clone(this.twitterOptions.threaded_tweets[index])
      details.title = details.description = details.website = details.url = ''
      if (removeImage) {
        details.image = []
      }
      if (triggerEvent) {
        this.onChangeThreadDetails(details, index)
      }
      return details
    },

    /**
     * @description handle Sharing Link event
     * @param index index of thread
     * @param link
     */
    handleSharingLink(index, link) {
      const thread = clone(this.twitterOptions.threaded_tweets[index])
      thread.url = link
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Set link preview for Thread
     * @param index index of thread
     * @param preview link preview object
     */
    setLinkPreview(index, preview) {
      const thread = clone(this.twitterOptions.threaded_tweets[index])
      // using same images if images are already added
      if (thread.image.length) {
        preview.image = thread.image
      }
      thread.title = preview.twitter_title
      thread.description = preview.twitter_description
      thread.image = preview.twitter_image
      thread.website = preview.twitter_website
      thread.image_suggestions = preview.image_suggestions

      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Initialize video upload from editor for Thread
     * @param index index of thread
     */
    initializeVideoUpload: function (index) {
      let thread = clone(this.twitterOptions.threaded_tweets[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, true, false, thread)
      thread.video = videoDefaultSharingDetails
      thread.image_suggestions = []
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Add media to editor for Thread
     * @param index index of thread
     * @param media media
     * @param mediaType media type (image, video, etc)
     */
    addMedia(index, media, mediaType) {
      let thread = clone(this.twitterOptions.threaded_tweets[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, false, false, thread)
      if (mediaType === 'image') {
        this.addImages(index, thread, media)
      } else if (mediaType === 'video') {
        this.addVideo(index, thread, media)
      }
    },

    /**
     * @description Add images to editor for Thread
     * @param index index of thread
     * @param thread thread
     * @param images array
     */
    addImages(index, thread, images) {
      // remove video
      thread.video = clone(videoDefaultSharingDetails)
      thread.image = images.length > 3 ? images.slice(0, 4) : images

      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Add video to editor for Thread
     * @param index index of thread
     * @param thread thread
     * @param video video object
     */
    addVideo(index, thread, video) {
      thread.image = []
      thread.video = video[0]
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Handle remove media for thread
     * @param index index of thread
     * @param mediaType mediaType string (image, video)
     * @param media media to remove object
     * @param removeAll remove all images (true/false) default false
     */
    handleRemoveMedia: function (index, mediaType, media, removeAll = false) {
      if (mediaType === 'image') {
        this.removeImage(index, media, removeAll)
      } else if (mediaType === 'video') {
        this.removeVideo(index, media)
      }
    },

    /**
     * @description Remove video from editor for Thread
     * @param index index of thread
     */
    removeVideo: function (index) {
      const thread = clone(this.twitterOptions.threaded_tweets[index])
      thread.video = videoDefaultSharingDetails
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Remove image from editor media box for Thread
     * @param threadIndex index of thread
     * @param image image to remove
     * @param removeAll remove all images (true/false) default false
     */
    removeImage: function (threadIndex, image, removeAll = false) {
      const thread = { ...this.twitterOptions.threaded_tweets[threadIndex] }
      let index = -1
      const self = this
      if (thread && thread.image && thread.image.length > 0 && !removeAll) {
        setTimeout(function () {
          index = thread.image.indexOf(image)
          if (index > -1) {
            thread.image.splice(index, 1)
          }
          self.onChangeThreadDetails(thread, threadIndex)
        }, 200)
      } else if (removeAll) {
        thread.image = []
        this.onChangeThreadDetails(thread, threadIndex)
      }
    },

    /**
     * @description Handle Location change for Thread
     * @param index index of thread
     * @param locationType
     * @param value location value
     */
    handleLocationChanges(index, locationType, value) {
      const thread = clone(this.twitterOptions.threaded_tweets[index])
      thread.location = value
      this.onChangeThreadDetails(thread, index)
    },

    handleThreadedTweetHashtagsChanges(index, flag, data) {
      this.$emit('handle-hashtags', 'twitter-thread', flag, data, index)
    },

    /**
     * @description Event handlers on change thread details
     * @param thread {Object} thread details
     * @param index {int} thread index
     */
    onChangeThreadDetails: function (thread, index) {
      this.$emit('onChangeThreadDetails', thread, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-editor-threaded-tweets {
  border-top: 1px solid #dee2e6;
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  //&:focus-within {
  //  background-color: #fff;
  //  //border-color: #80bdff;
  //  border-color: #80bdff30;
  //  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
  //  outline: 0;
  //}
  textarea {
    background-color: #f4f7fa;
  }
}
</style>
