<template>
  <div v-on-clickaway="closeDropdownFixed" class=" fixed right-10 bottom-10 z-1050" id="help_dropdown_fixed">
    <!--v-on-clickaway="closeToggleDropdownFixed()"-->
    <div class=" relative">

      <div
        v-if="helpScoutChat"
        class="flex align-items-center justify-content-center bg-black-400 hover:bg-black-500 transition-all ease-in-out rounded-full w-16 h-16 text-center  border-solid border-1.5 border-white cursor-pointer shadow-sm"
        @click.prevent="closeHelpChat"
      >
        <i class="cs-cross text-xl text-white"></i>
      </div>
      <div
        v-else-if="toggleDropdownFixedStatus"
        class="flex align-items-center justify-content-center bg-black-400 hover:bg-black-500 transition-all ease-in-out rounded-full w-16 h-16 text-center  border-solid border-1.5 border-white cursor-pointer shadow-sm"
        @click.prevent="toggleDropdownFixed"
      >
        <i class="cs-cross text-xl text-white"></i>
      </div>

      <div
        v-else
        class="flex align-items-center justify-content-center bg-black-400 hover:bg-black-500 transition-all ease-in-out rounded-full w-16 h-16 text-center  border-solid border-1.5 border-white cursor-pointer shadow-sm"
        @click.prevent="toggleDropdownFixed"
      >
        <i class="cs-question-mark text-xl text-white"></i>
      </div>


      <div
        :class="{ 'visible': toggleDropdownFixedStatus, 'hidden': !toggleDropdownFixedStatus  }"
        style="min-width: 12.625rem"
        class=" absolute bottom-20 right-4 bg-white rounded drop-shadow-sm border border-solid border-gray-50 p-2"
      >
        <ul class=" text-gray-900 hover:text-black-900 z-1050 ">
          <li v-if="!isLifetimePlan" class="">
            <a
              href="https://contentstudio.io/book-a-demo"
              target="_blank"
              class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out block hover:bg-blue-50"
            >

              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
              <img src="@src/assets/img/icons/help-widget/book-a-demo.svg"/>
              </div>
              <div class="text-sm">Schedule a Demo</div>
            </a>
          </li>
          <li>
            <a
              href="https://contentstudio.frill.co/b/90pqo1vj/feature-requests"
              target="_blank"
              class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out block hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/request-feature.svg"/>
              </div>
              <div class="text-sm">Request a Feature</div>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/c/ContentStudioContentmarketing/videos"
              target="_blank"
              class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out  block hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/video-tutorials.svg"/>
              </div>
              <div class="text-sm">Video Tutorials</div>
            </a>
          </li>
          <li>
            <a
              href="https://docs.contentstudio.io/"
              target="_blank"
              class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out  block hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
              <img src="@src/assets/img/icons/help-widget/help-docs.svg"/>
              </div>
              <div class="text-sm">Help Docs</div>
            </a>

          </li>
          <li @click.prevent="openHelpChat" class="text-gray-900 hover:text-black-900 flex py-2 cursor-pointer  block hover:bg-blue-50">
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                  <img src="@src/assets/img/icons/help-widget/contact-support.svg"/>
              </div>
            <div>Contact Support</div>

          </li>

          <li>
            <a
              href="https://status.contentstudio.io/"
              target="_blank"
              class="text-gray-900 hover:text-black-900 flex flex-col py-2 transition-all ease-in-out  block hover:bg-blue-50"
            >
              <div class="flex">
                <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                  <img src="@src/assets/img/icons/help-widget/status.svg"/>
                </div>
                <div class="text-sm">
                  ContentStudio Status

                </div>
              </div>


              <small
                    v-if="appStatus.is_down"
                    class="small_text-variant-1 ml-8 mt-1"
                    :style="{ color: appStatus.color }"
                    >
                    <i class="fad fa-scrubber inner-icon"></i> Some Systems
                  Degraded</small
                  >
                  <small
                    v-else
                    class="small_text-variant-1 ml-8 mt-1"
                    :style="{ color: appStatus.color }"
                    ><i class="fad fa-scrubber inner-icon"></i> All Systems
                  Operational</small
                  >
            </a>

            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../../lib/event-bus'
export default {
  data() {
    return {
      toggleDropdownFixedStatus: false,
      helpScoutChat: false,
    }
  },
  computed: {
    ...mapGetters(['getAppStatus']),
    appStatus() {
      if (this.getAppStatus.is_down !== undefined) {
        if (this.getAppStatus.is_down) return {is_down: true, color: '#e67e22'}
        if (!this.getAppStatus.is_down) return {is_down: false, color: '#6dd123'}
      }
      else {
        return {is_down: false, color: '#6dd123'}
      }
    },
  },
  mounted() {
    console.debug('Status', this.getAppStatus)
    EventBus.$on('open-help-widget', () => {
      Beacon('open')
      this.helpScoutChat = true
    })

    Beacon('on', 'close', () => this.helpScoutChat = false)

  },
  beforeDestroy() {
    EventBus.$off('open-help-widget')
    Beacon('off', 'close')
  },
  methods: {
    isLifetimePlan() {
      const plan = this.$store.getters.getPlan.subscription
      if (plan.display_name || plan.fastspring_name) {
        if (plan.display_name.includes('Lifetime')) return true
        if (plan.fastspring_name.includes('lifetime')) return true
        if (
          plan.fastspring_name.includes('jvzoo') ||
          plan.fastspring_name.includes('appsumo')
        )
          return true
      }
      return false
    },
    toggleDropdownFixed() {
      this.toggleDropdownFixedStatus = !this.toggleDropdownFixedStatus
    },
    closeDropdownFixed() {
      this.toggleDropdownFixedStatus = false
    },
    // closeToggleDropdownFixed () {
    //   // this.toggleDropdownFixedStatus = false
    // },
    openHelpChat() {
      Beacon('open')
      this.helpScoutChat = true
    },
    closeHelpChat() {
      Beacon('close')
      this.helpScoutChat = false
    },
  },
}
</script>

<style scoped></style>
