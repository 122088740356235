<template>
  <div class="flex-row pl-2">
    <h1>{{ name }}</h1>
    <p>{{ tooltips[name] }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {}
  },
  data () {
    return {
      tooltips: {
        'Instagram Performance Summary':
          'Key profile performance metrics from the reporting period.',
        'Instagram Audience Growth':
          'See how audience grew during the reporting period.',
        'Instagram Publishing Behavior':
          'The different types of media you published during the selected time period.',
        'Instagram Top Posts':
          'Top posts published during the selected time period, based on the post lifetime performance.',
        'Instagram Active Users':
          'Active users demographics in the reporting period at the specific day or hour.',
        'Instagram Outbound Hashtag Performance':
          'Most used Hashtags during the reporting period and the hashtags that drew the most engagement.',
        'Instagram Impressions':
          'Analyze the trend of your content seen by the Instagram community during reporting period.',
        'Instagram Engagement':
          'See how people are engaging with your posts and stories during the reporting period.',
        'Instagram Stories Performance':
          'Review how people are interacting with stories that you published during selected date range.',
        'Instagram Audience Demographics':
          'Audience demographics in the reporting time period by Age, Gender, Country and City.'
      }
    }
  }
}
</script>
