import moment from 'moment'
import { mapGetters } from 'vuex'

export const dateTimeMixin = {
  computed: {
    ...mapGetters(['getWorkspaces']),
  },

  methods: {
    // TODO::use utcTimePassingCheck
    /**
     * @deprecated use timePassed in date-time.js
     * @param time
     * @returns {boolean}
     */
    timePassingCheck(time) {
      return moment(time).isBefore(moment())
    },

    utcTimePassingCheck(dateTime) {
      const now = moment.utc().format('YYYY-MM-DD HH:mm')
      return moment.utc(dateTime, 'YYYY-MM-DD HH:mm:ss').isBefore(now)
    },

    // return date in required format
    getDateTimeFormat(dateTime, format) {
      return moment(dateTime).format(format)
    },

    // TODO::use utcTimePassingCheck
    dateDifferenceInDays(start, end) {
      start = moment(start, 'YYYY-MM-DD HH:mm')
      end = moment(end, 'YYYY-MM-DD HH:mm')
      return end.diff(start, 'days')
    },

    checkTimeDifference(start, end, unit) {
      start = moment(start, 'YYYY-MM-DD HH:mm')
      end = moment(end, 'YYYY-MM-DD HH:mm')
      return end.diff(start, unit)
    },

    getWorkspaceTimeZoneTime(dateTime, format) {
      return this.getWorkspaces.activeWorkspace.timezone
        ? moment
            .utc(dateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.getWorkspaces.activeWorkspace.timezone)
            .format(format)
        : moment.utc(dateTime, 'YYYY-MM-DD HH:mm:ss')
    },

    getWorkspaceTimezonCurrentTime(format) {
      return moment
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .format(format)
    },

    getUtcTimeAgoFromNow(dateTime) {
      return moment.utc(dateTime, 'YYYY-MM-DD HH:mm:ss').fromNow()
    },

    getUtcFromWorkspaceTimeZoneFromNow(dateTime) {
      return moment
        .tz(dateTime, this.getWorkspaces.activeWorkspace.timezone)
        .utc()
        .fromNow()
    },

    getUtcFromWorkspaceTimeZone(dateTime, format) {
      return moment
        .tz(dateTime, this.getWorkspaces.activeWorkspace.timezone)
        .utc()
        .format(format)
    },
    timePassedFetch(startTime) {
      if (!startTime) {
        return
      }
      startTime.replace(' ', 'T')
      const date = moment(startTime).tz(
        this.getWorkspaces.activeWorkspace.timezone
      )
      return moment(date).fromNow()
    },
    timePassingCheckCSV(time) {
      const now = moment.utc().format('YYYY-MM-DD HH:mm')
      return moment(time, 'YYYY-MM-DD HH:mm').isBefore(now)
    },
    previousAnalyticsPeriodDate(startDate, endDate) {
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      console.log('startDate, endDate')
      console.log(startDate, endDate)
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return new Date(moment(startDate).subtract(diffDays, 'day').toISOString())
        .toISOString()
        .slice(0, 10)
    },
  },
}
