import { render, staticRenderFns } from "./GmbPreview.vue?vue&type=template&id=2a83a091&scoped=true&"
import script from "./GmbPreview.vue?vue&type=script&lang=js&"
export * from "./GmbPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a83a091",
  null
  
)

export default component.exports