<template>
  <div class="p-4 text-sm rounded-lg flex" :class="classObject" role="alert">
    <template v-if="!hideIcon">
      <svg
        aria-hidden="true"
        class="flex-shrink-0 inline w-5 h-5 mr-2.5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </template>
    <div>
      <slot>
        <span class="font-medium">Special Alert!</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
      validator: function (value) {
        // The value must match one of these strings
        return ['success', 'info', 'warn', 'error'].indexOf(value) !== -1
      },
    },
  },
  computed: {
    classObject: function () {
      switch (this.type) {
        case 'info':
          return {
            'text-blue-500 bg-blue-50 ': true,
          }
        case 'warn':
          return {
            'text-yellow-700 bg-orange-50': true,
          }
        case 'error':
          return {
            'text-red-700 bg-red-100': true,
          }
        default:
          return {
            'text-green-700 bg-green-200': true,
          }
      }
    },
  },
}
</script>
