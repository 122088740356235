const state = {
  updateProfile: false,
  changePassword: false,
  saveProfileImage: false,
  saveCompanyImage: false,
  saveCompanyName: false,
  // for workspaces
  fetchWorkspaces: false,
  fetchWorkspace: false,
  uploadWorkspaceLogo: false,
  fetchWorkspaceLogoSuggessions: false,
  saveWorkspace: false,
  removeWorkspace: false,
  changeDefaultWorkspace: false,
  saveTeamMember: false,
  removeTeamMember: false,
  joinTeamStatusLoader: false,
  joinTeamLoader: false,
  fetchImportantNotifications: false
}

// getters
const getters = {
  getSettingLoaders: (state) => {
    return state
  }
}

// actions
const actions = {
  setUpdateProfileLoader ({ commit }, status) {
    commit('setUpdateProfileLoader', status)
  },

  setChangePasswordLoader ({ commit }, status) {
    commit('setChangePasswordLoader', status)
  },

  setSaveProfileImageLoader ({ commit }, status) {
    commit('setSaveProfileImageLoader', status)
  },
  setCompanyImageLoader ({ commit }, status) {
    commit('setCompanyImageLoader', status)
  },
  setCompanyNameLoader ({ commit }, status) {
    commit('setCompanyNameLoader', status)
  },
  // for workspaces
  setFetchWorkspacesLoader ({ commit }, status) {
    commit('setFetchWorkspacesLoader', status)
  },

  setFetchWorkspaceLoader ({ commit }, status) {
    commit('setFetchWorkspaceLoader', status)
  },

  setUploadWorkspaceLogoLoader ({ commit }, status) {
    commit('setUploadWorkspaceLogoLoader', status)
  },

  setFetchWorkspaceLogoSuggessionsLoader ({ commit }, status) {
    commit('setFetchWorkspaceLogoSuggessionsLoader', status)
  },

  setSaveWorkspaceLoader ({ commit }, status) {
    commit('setSaveWorkspaceLoader', status)
  },

  setRemoveWorkspaceLoader ({ commit }, status) {
    commit('setRemoveWorkspaceLoader', status)
  },

  setChangeDefaultWorkspaceLoader ({ commit }, status) {
    commit('setChangeDefaultWorkspaceLoader', status)
  },

  // for team members

  setSaveTeamMemberLoader ({ commit }, status) {
    commit('setSaveTeamMemberLoader', status)
  },

  setRemoveTeamMemberLoader ({ commit }, status) {
    commit('setRemoveTeamMemberLoader', status)
  },

  setJoinTeamStatusLoader ({ commit }, status) {
    commit('setJoinTeamStatusLoader', status)
  },

  setJoinTeamLoader ({ commit }, status) {
    commit('setJoinTeamLoader', status)
  },

  setFetchImportantNotificationsLoader ({ commit }, status) {
    commit('setFetchImportantNotificationsLoader', status)
  }
}

// mutations
const mutations = {
  setUpdateProfileLoader (state, status) {
    state.updateProfile = status
  },

  setChangePasswordLoader (state, status) {
    state.changePassword = status
  },

  setSaveProfileImageLoader (state, status) {
    state.saveProfileImage = status
  },

  setCompanyImageLoader (state, status) {
    state.saveCompanyImage = status
  },
  setCompanyNameLoader (state, status) {
    state.saveCompanyName = status
  },
  // for workspaces
  setFetchWorkspacesLoader (state, status) {
    state.fetchWorkspaces = status
  },

  setFetchWorkspaceLoader (state, status) {
    state.fetchWorkspace = status
  },

  setUploadWorkspaceLogoLoader (state, status) {
    state.uploadWorkspaceLogo = status
  },

  setFetchWorkspaceLogoSuggessionsLoader (state, status) {
    state.fetchWorkspaceLogoSuggessions = status
  },

  setSaveWorkspaceLoader (state, status) {
    state.saveWorkspace = status
  },

  setRemoveWorkspaceLoader (state, status) {
    state.removeWorkspace = status
  },

  setChangeDefaultWorkspaceLoader (state, status) {
    state.changeDefaultWorkspace = status
  },

  // team members

  setSaveTeamMemberLoader (state, status) {
    state.saveTeamMember = status
  },

  setRemoveTeamMemberLoader (state, status) {
    state.removeTeamMember = status
  },

  setJoinTeamStatusLoader (state, status) {
    state.joinTeamStatusLoader = status
  },

  setJoinTeamLoader (state, status) {
    state.joinTeamLoader = status
  },

  setFetchImportantNotificationsLoader (state, status) {
    state.fetchImportantNotifications = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
