<template>
  <div
    class="page_404 page_maintainance d-flex align-items-center justify-content-center"
  >
    <div class="page_inner">
      <img src="../assets/img/maintenance.svg" alt="" />
      <p
        >We're down for scheduled maintenance, we will be live again within a
        few hours.</p
      >
      <a href="https://status.contentstudio.io/" class="large_btn btn btn_round"
        >View Status Page</a
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {}
  },
  computed: {},

  watch: {},

  mounted() {},
}
</script>
