<script>
import { mapGetters } from 'vuex'

export default {
  props: ['device_id'],
  data () {
    return {
      removeLoader: false,
      stage: ''
    }
  },
  computed: {
    ...mapGetters([
      'getPocket',
      'getFeedly',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getPinterestAccounts'
    ]),
    isRemoveLoader () {
      return this.removeLoader
    },
    isNormalStage () {
      return this.stage === ''
    },
    isSuccessStage () {
      return this.stage === 'success'
    },
    isFailStage () {
      return this.stage === 'fail'
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('removeNotificationDevice')
    },
    spliceStateItem (getter, key) {
      const stateObject = this
      this[getter].items.forEach(function (element, index) {
        if (element[key] === stateObject.platform) {
          stateObject[getter].items.splice(index, 1)
          return false
        }
      })
    },

    async removeNotificationDevice () {
      console.debug('Method::removeNotificationDevice', this.device_id)
      this.removeLoader = true
      const response = await this.$store.dispatch('removeNotificationDevice', {
        device_id: this.device_id
      })
      this.stage = response ? 'success' : 'fail'
      this.removeLoader = false
      const stateObject = this
      setTimeout(function () {
        setTimeout(function () {
          stateObject.stage = ''
        }, 500)
        stateObject.$bvModal.hide('removeNotificationDevice')
      }, 1000)
    }
  }
}
</script>
<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox"
    id="removeNotificationDevice"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div v-if="isSuccessStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-
            miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>

      <div v-if="isFailStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>

      <div v-if="isNormalStage" class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Remove</h2>
          <p class="text-center">This action cannot be undone.</p>
        </div>
        <div class="col-sm-12 footer_box">
          <button
            class="btn gray cancel_btn"
            :disabled="isRemoveLoader"
            @click="hideModal()"
            data-cy="remove-no"
            >No</button
          >
          <button
            class="btn red confirm_btn btn_loader loader_right"
            :disabled="isRemoveLoader"
            @click.prevent="removeNotificationDevice()"
            data-cy="remove-yes"
          >
            <span>Yes</span>
            <clip-loader
              :color="'#ffffff'"
              :size="'16px'"
              v-if="isRemoveLoader"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
