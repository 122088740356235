<template>
  <!-- this is an old one, we no longer use i think-->

  <div class="articles_list">
    <div class="article_inner">
      <template v-if="getDiscoveryVideoLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <youtube></youtube>
        <template v-if="getVideoPaginationLoader && getVideos.length == 0">
          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                  alt=""
                />
              </div>
              <p>No results found for your query.</p>
            </div>
          </div>
        </template>
        <template
          v-else-if="getVideoPaginationLoader && getVideos.length <= 19"
        >
          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                  alt=""
                />
              </div>
              <p>No results found for your query.</p>
            </div>
          </div>
        </template>
        <infinite-loading
          v-else
          @infinite="onInfiniteVideo"
          ref="infiniteLoading"
        >
          <span slot="spinner" class="d-block pt-3 pb-4">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>

          <span slot="no-more" class="not_found_text">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p>No results found for your query.</p>
              </div>
            </div>
          </span>
          <span slot="no-results">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p v-if="getVideos.length == 0"
                  >No results found for your query.</p
                >
                <p v-else>No more results found for your query.</p>
              </div>
            </div>
          </span>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

const Youtube = () => import('../../../common/Youtube')
export default {
  components: {
    Youtube,
    InfiniteLoading
  },
  computed: {
    ...mapGetters([
      'getDiscoveryVideoLoader',
      'getVideoPaginationLoader',
      'getVideos',
      'getVideoScroll',
      'getVideoPage'
    ])
  },
  created () {
    // this.changeSearchContentType('youtube')
  },
  methods: {
    ...mapActions(['setVideoPage']),
    onInfiniteVideo ($state) {
      console.log('On video infinite', this.getVideoScroll, this.getVideoPage)
      if (this.getVideoScroll) {
        this.setVideoPage(this.getVideoPage + 1)
        this.getYoutubeContentByTopic($state)
      }
    }
  }
}
</script>
