export const isoLanguages = {
  ach: {
    nativeName: 'Lwo',
    englishName: 'Acholi',
  },
  ady: {
    nativeName: 'Адыгэбзэ',
    englishName: 'Adyghe',
  },
  af: {
    nativeName: 'Afrikaans',
    englishName: 'Afrikaans',
  },
  af_NA: {
    nativeName: 'Afrikaans (Namibia)',
    englishName: 'Afrikaans (Namibia)',
  },
  af_ZA: {
    nativeName: 'Afrikaans (South Africa)',
    englishName: 'Afrikaans (South Africa)',
  },
  ak: {
    nativeName: 'Tɕɥi',
    englishName: 'Akan',
  },
  ar: {
    nativeName: 'العربية',
    englishName: 'Arabic',
  },
  ar_AR: {
    nativeName: 'العربية',
    englishName: 'Arabic',
  },
  ar_MA: {
    nativeName: 'العربية',
    englishName: 'Arabic (Morocco)',
  },
  ar_SA: {
    nativeName: 'العربية (السعودية)',
    englishName: 'Arabic (Saudi Arabia)',
  },
  ay_BO: {
    nativeName: 'Aymar aru',
    englishName: 'Aymara',
  },
  az: {
    nativeName: 'Azərbaycan dili',
    englishName: 'Azerbaijani',
  },
  az_AZ: {
    nativeName: 'Azərbaycan dili',
    englishName: 'Azerbaijani',
  },
  be_BY: {
    nativeName: 'Беларуская',
    englishName: 'Belarusian',
  },
  bg: {
    nativeName: 'Български',
    englishName: 'Bulgarian',
  },
  bg_BG: {
    nativeName: 'Български',
    englishName: 'Bulgarian',
  },
  bn: {
    nativeName: 'বাংলা',
    englishName: 'Bengali',
  },
  bn_IN: {
    nativeName: 'বাংলা (ভারত)',
    englishName: 'Bengali (India)',
  },
  bn_BD: {
    nativeName: 'বাংলা(বাংলাদেশ)',
    englishName: 'Bengali (Bangladesh)',
  },
  bs_BA: {
    nativeName: 'Bosanski',
    englishName: 'Bosnian',
  },
  ca: {
    nativeName: 'Català',
    englishName: 'Catalan',
  },
  ca_ES: {
    nativeName: 'Català',
    englishName: 'Catalan',
  },
  cak: {
    nativeName: 'Maya Kaqchikel',
    englishName: 'Kaqchikel',
  },
  ck_US: {
    nativeName: 'ᏣᎳᎩ (tsalagi)',
    englishName: 'Cherokee',
  },
  cs: {
    nativeName: 'Čeština',
    englishName: 'Czech',
  },
  cs_CZ: {
    nativeName: 'Čeština',
    englishName: 'Czech',
  },
  cy: {
    nativeName: 'Cymraeg',
    englishName: 'Welsh',
  },
  cy_GB: {
    nativeName: 'Cymraeg',
    englishName: 'Welsh',
  },
  da: {
    nativeName: 'Dansk',
    englishName: 'Danish',
  },
  da_DK: {
    nativeName: 'Dansk',
    englishName: 'Danish',
  },
  de: {
    nativeName: 'Deutsch',
    englishName: 'German',
  },
  de_AT: {
    nativeName: 'Deutsch (Österreich)',
    englishName: 'German (Austria)',
  },
  de_DE: {
    nativeName: 'Deutsch (Deutschland)',
    englishName: 'German (Germany)',
  },
  de_CH: {
    nativeName: 'Deutsch (Schweiz)',
    englishName: 'German (Switzerland)',
  },
  dsb: {
    nativeName: 'Dolnoserbšćina',
    englishName: 'Lower Sorbian',
  },
  el: {
    nativeName: 'Ελληνικά',
    englishName: 'Greek',
  },
  el_GR: {
    nativeName: 'Ελληνικά',
    englishName: 'Greek (Greece)',
  },
  en: {
    nativeName: 'English',
    englishName: 'English',
  },
  en_GB: {
    nativeName: 'English (UK)',
    englishName: 'English (UK)',
  },
  en_AU: {
    nativeName: 'English (Australia)',
    englishName: 'English (Australia)',
  },
  en_CA: {
    nativeName: 'English (Canada)',
    englishName: 'English (Canada)',
  },
  en_IE: {
    nativeName: 'English (Ireland)',
    englishName: 'English (Ireland)',
  },
  en_IN: {
    nativeName: 'English (India)',
    englishName: 'English (India)',
  },
  en_PI: {
    nativeName: 'English (Pirate)',
    englishName: 'English (Pirate)',
  },
  en_UD: {
    nativeName: 'English (Upside Down)',
    englishName: 'English (Upside Down)',
  },
  en_US: {
    nativeName: 'English (US)',
    englishName: 'English (US)',
  },
  en_ZA: {
    nativeName: 'English (South Africa)',
    englishName: 'English (South Africa)',
  },
  'en@pirate': {
    nativeName: 'English (Pirate)',
    englishName: 'English (Pirate)',
  },
  eo: {
    nativeName: 'Esperanto',
    englishName: 'Esperanto',
  },
  eo_EO: {
    nativeName: 'Esperanto',
    englishName: 'Esperanto',
  },
  es: {
    nativeName: 'Español',
    englishName: 'Spanish',
  },
  es_AR: {
    nativeName: 'Español (Argentine)',
    englishName: 'Spanish (Argentina)',
  },
  es_419: {
    nativeName: 'Español (Latinoamérica)',
    englishName: 'Spanish (Latin America)',
  },
  es_CL: {
    nativeName: 'Español (Chile)',
    englishName: 'Spanish (Chile)',
  },
  es_CO: {
    nativeName: 'Español (Colombia)',
    englishName: 'Spanish (Colombia)',
  },
  es_EC: {
    nativeName: 'Español (Ecuador)',
    englishName: 'Spanish (Ecuador)',
  },
  es_ES: {
    nativeName: 'Español (España)',
    englishName: 'Spanish (Spain)',
  },
  es_LA: {
    nativeName: 'Español (Latinoamérica)',
    englishName: 'Spanish (Latin America)',
  },
  es_NI: {
    nativeName: 'Español (Nicaragua)',
    englishName: 'Spanish (Nicaragua)',
  },
  es_MX: {
    nativeName: 'Español (México)',
    englishName: 'Spanish (Mexico)',
  },
  es_US: {
    nativeName: 'Español (Estados Unidos)',
    englishName: 'Spanish (United States)',
  },
  es_VE: {
    nativeName: 'Español (Venezuela)',
    englishName: 'Spanish (Venezuela)',
  },
  et: {
    nativeName: 'eesti keel',
    englishName: 'Estonian',
  },
  et_EE: {
    nativeName: 'Eesti (Estonia)',
    englishName: 'Estonian (Estonia)',
  },
  eu: {
    nativeName: 'Euskara',
    englishName: 'Basque',
  },
  eu_ES: {
    nativeName: 'Euskara',
    englishName: 'Basque',
  },
  fa: {
    nativeName: 'فارسی',
    englishName: 'Persian',
  },
  fa_IR: {
    nativeName: 'فارسی',
    englishName: 'Persian',
  },
  fb_LT: {
    nativeName: 'Leet Speak',
    englishName: 'Leet',
  },
  ff: {
    nativeName: 'Fulah',
    englishName: 'Fulah',
  },
  fi: {
    nativeName: 'Suomi',
    englishName: 'Finnish',
  },
  fi_FI: {
    nativeName: 'Suomi',
    englishName: 'Finnish',
  },
  fo_FO: {
    nativeName: 'Føroyskt',
    englishName: 'Faroese',
  },
  fr: {
    nativeName: 'Français',
    englishName: 'French',
  },
  fr_CA: {
    nativeName: 'Français (Canada)',
    englishName: 'French (Canada)',
  },
  fr_FR: {
    nativeName: 'Français (France)',
    englishName: 'French (France)',
  },
  fr_BE: {
    nativeName: 'Français (Belgique)',
    englishName: 'French (Belgium)',
  },
  fr_CH: {
    nativeName: 'Français (Suisse)',
    englishName: 'French (Switzerland)',
  },
  fy_NL: {
    nativeName: 'Frysk',
    englishName: 'Frisian (West)',
  },
  ga: {
    nativeName: 'Gaeilge',
    englishName: 'Irish',
  },
  ga_IE: {
    nativeName: 'Gaeilge (Gaelic)',
    englishName: 'Irish (Gaelic)',
  },
  gl: {
    nativeName: 'Galego',
    englishName: 'Galician',
  },
  gl_ES: {
    nativeName: 'Galego',
    englishName: 'Galician',
  },
  gn_PY: {
    nativeName: "Avañe'ẽ",
    englishName: 'Guarani',
  },
  gu_IN: {
    nativeName: 'ગુજરાતી',
    englishName: 'Gujarati',
  },
  gx_GR: {
    nativeName: 'Ἑλληνική ἀρχαία',
    englishName: 'Classical Greek',
  },
  he: {
    nativeName: 'עברית‏',
    englishName: 'Hebrew',
  },
  he_IL: {
    nativeName: 'עברית‏',
    englishName: 'Hebrew',
  },
  hi: {
    nativeName: 'हिन्दी',
    englishName: 'Hindi',
  },
  hi_IN: {
    nativeName: 'हिन्दी',
    englishName: 'Hindi',
  },
  hr: {
    nativeName: 'Hrvatski',
    englishName: 'Croatian',
  },
  hr_HR: {
    nativeName: 'Hrvatski',
    englishName: 'Croatian',
  },
  hsb: {
    nativeName: 'Hornjoserbšćina',
    englishName: 'Upper Sorbian',
  },
  ht: {
    nativeName: 'Kreyòl',
    englishName: 'Haitian Creole',
  },
  hu: {
    nativeName: 'Magyar',
    englishName: 'Hungarian',
  },
  hu_HU: {
    nativeName: 'Magyar',
    englishName: 'Hungarian',
  },
  hy_AM: {
    nativeName: 'Հայերեն',
    englishName: 'Armenian',
  },
  id: {
    nativeName: 'Bahasa Indonesia',
    englishName: 'Indonesian',
  },
  id_ID: {
    nativeName: 'Bahasa Indonesia',
    englishName: 'Indonesian',
  },
  is: {
    nativeName: 'Íslenska',
    englishName: 'Icelandic',
  },
  is_IS: {
    nativeName: 'Íslenska (Iceland)',
    englishName: 'Icelandic (Iceland)',
  },
  it: {
    nativeName: 'Italiano',
    englishName: 'Italian',
  },
  it_IT: {
    nativeName: 'Italiano',
    englishName: 'Italian',
  },
  ja: {
    nativeName: '日本語',
    englishName: 'Japanese',
  },
  ja_JP: {
    nativeName: '日本語',
    englishName: 'Japanese',
  },
  jv_ID: {
    nativeName: 'Basa Jawa',
    englishName: 'Javanese',
  },
  ka_GE: {
    nativeName: 'ქართული',
    englishName: 'Georgian',
  },
  kk_KZ: {
    nativeName: 'Қазақша',
    englishName: 'Kazakh',
  },
  km: {
    nativeName: 'ភាសាខ្មែរ',
    englishName: 'Khmer',
  },
  km_KH: {
    nativeName: 'ភាសាខ្មែរ',
    englishName: 'Khmer',
  },
  kab: {
    nativeName: 'Taqbaylit',
    englishName: 'Kabyle',
  },
  kn: {
    nativeName: 'ಕನ್ನಡ',
    englishName: 'Kannada',
  },
  kn_IN: {
    nativeName: 'ಕನ್ನಡ (India)',
    englishName: 'Kannada (India)',
  },
  ko: {
    nativeName: '한국어',
    englishName: 'Korean',
  },
  ko_KR: {
    nativeName: '한국어 (韩国)',
    englishName: 'Korean (Korea)',
  },
  ku_TR: {
    nativeName: 'Kurdî',
    englishName: 'Kurdish',
  },
  la: {
    nativeName: 'Latin',
    englishName: 'Latin',
  },
  la_VA: {
    nativeName: 'Latin',
    englishName: 'Latin',
  },
  lb: {
    nativeName: 'Lëtzebuergesch',
    englishName: 'Luxembourgish',
  },
  li_NL: {
    nativeName: 'Lèmbörgs',
    englishName: 'Limburgish',
  },
  lt: {
    nativeName: 'Lietuvių',
    englishName: 'Lithuanian',
  },
  lt_LT: {
    nativeName: 'Lietuvių',
    englishName: 'Lithuanian',
  },
  lv: {
    nativeName: 'Latviešu',
    englishName: 'Latvian',
  },
  lv_LV: {
    nativeName: 'Latviešu',
    englishName: 'Latvian',
  },
  mai: {
    nativeName: 'मैथिली, মৈথিলী',
    englishName: 'Maithili',
  },
  mg_MG: {
    nativeName: 'Malagasy',
    englishName: 'Malagasy',
  },
  mk: {
    nativeName: 'Македонски',
    englishName: 'Macedonian',
  },
  mk_MK: {
    nativeName: 'Македонски (Македонски)',
    englishName: 'Macedonian (Macedonian)',
  },
  ml: {
    nativeName: 'മലയാളം',
    englishName: 'Malayalam',
  },
  ml_IN: {
    nativeName: 'മലയാളം',
    englishName: 'Malayalam',
  },
  mn_MN: {
    nativeName: 'Монгол',
    englishName: 'Mongolian',
  },
  mr: {
    nativeName: 'मराठी',
    englishName: 'Marathi',
  },
  mr_IN: {
    nativeName: 'मराठी',
    englishName: 'Marathi',
  },
  ms: {
    nativeName: 'Bahasa Melayu',
    englishName: 'Malay',
  },
  ms_MY: {
    nativeName: 'Bahasa Melayu',
    englishName: 'Malay',
  },
  mt: {
    nativeName: 'Malti',
    englishName: 'Maltese',
  },
  mt_MT: {
    nativeName: 'Malti',
    englishName: 'Maltese',
  },
  my: {
    nativeName: 'ဗမာစကာ',
    englishName: 'Burmese',
  },
  no: {
    nativeName: 'Norsk',
    englishName: 'Norwegian',
  },
  nb: {
    nativeName: 'Norsk (bokmål)',
    englishName: 'Norwegian (bokmal)',
  },
  nb_NO: {
    nativeName: 'Norsk (bokmål)',
    englishName: 'Norwegian (bokmal)',
  },
  ne: {
    nativeName: 'नेपाली',
    englishName: 'Nepali',
  },
  ne_NP: {
    nativeName: 'नेपाली',
    englishName: 'Nepali',
  },
  nl: {
    nativeName: 'Nederlands',
    englishName: 'Dutch',
  },
  nl_BE: {
    nativeName: 'Nederlands (België)',
    englishName: 'Dutch (Belgium)',
  },
  nl_NL: {
    nativeName: 'Nederlands (Nederland)',
    englishName: 'Dutch (Netherlands)',
  },
  nn_NO: {
    nativeName: 'Norsk (nynorsk)',
    englishName: 'Norwegian (nynorsk)',
  },
  oc: {
    nativeName: 'Occitan',
    englishName: 'Occitan',
  },
  or_IN: {
    nativeName: 'ଓଡ଼ିଆ',
    englishName: 'Oriya',
  },
  pa: {
    nativeName: 'ਪੰਜਾਬੀ',
    englishName: 'Punjabi',
  },
  pa_IN: {
    nativeName: 'ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)',
    englishName: 'Punjabi (India)',
  },
  pl: {
    nativeName: 'Polski',
    englishName: 'Polish',
  },
  pl_PL: {
    nativeName: 'Polski',
    englishName: 'Polish',
  },
  ps_AF: {
    nativeName: 'پښتو',
    englishName: 'Pashto',
  },
  pt: {
    nativeName: 'Português',
    englishName: 'Portuguese',
  },
  pt_BR: {
    nativeName: 'Português (Brasil)',
    englishName: 'Portuguese (Brazil)',
  },
  pt_PT: {
    nativeName: 'Português (Portugal)',
    englishName: 'Portuguese (Portugal)',
  },
  qu_PE: {
    nativeName: 'Qhichwa',
    englishName: 'Quechua',
  },
  rm_CH: {
    nativeName: 'Rumantsch',
    englishName: 'Romansh',
  },
  ro: {
    nativeName: 'Română',
    englishName: 'Romanian',
  },
  ro_RO: {
    nativeName: 'Română',
    englishName: 'Romanian',
  },
  ru: {
    nativeName: 'Русский',
    englishName: 'Russian',
  },
  ru_RU: {
    nativeName: 'Русский',
    englishName: 'Russian',
  },
  sa_IN: {
    nativeName: 'संस्कृतम्',
    englishName: 'Sanskrit',
  },
  se_NO: {
    nativeName: 'Davvisámegiella',
    englishName: 'Northern Sámi',
  },
  si_LK: {
    nativeName: 'පළාත',
    englishName: 'Sinhala (Sri Lanka)',
  },
  sk: {
    nativeName: 'Slovenčina',
    englishName: 'Slovak',
  },
  sk_SK: {
    nativeName: 'Slovenčina (Slovakia)',
    englishName: 'Slovak (Slovakia)',
  },
  sl: {
    nativeName: 'Slovenščina',
    englishName: 'Slovenian',
  },
  sl_SI: {
    nativeName: 'Slovenščina',
    englishName: 'Slovenian',
  },
  so_SO: {
    nativeName: 'Soomaaliga',
    englishName: 'Somali',
  },
  sq: {
    nativeName: 'Shqip',
    englishName: 'Albanian',
  },
  sq_AL: {
    nativeName: 'Shqip',
    englishName: 'Albanian',
  },
  sr: {
    nativeName: 'Српски',
    englishName: 'Serbian',
  },
  sr_RS: {
    nativeName: 'Српски (Serbia)',
    englishName: 'Serbian (Serbia)',
  },
  su: {
    nativeName: 'Basa Sunda',
    englishName: 'Sundanese',
  },
  sv: {
    nativeName: 'Svenska',
    englishName: 'Swedish',
  },
  sv_SE: {
    nativeName: 'Svenska',
    englishName: 'Swedish',
  },
  sw: {
    nativeName: 'Kiswahili',
    englishName: 'Swahili',
  },
  sw_KE: {
    nativeName: 'Kiswahili',
    englishName: 'Swahili (Kenya)',
  },
  ta: {
    nativeName: 'தமிழ்',
    englishName: 'Tamil',
  },
  ta_IN: {
    nativeName: 'தமிழ்',
    englishName: 'Tamil',
  },
  te: {
    nativeName: 'తెలుగు',
    englishName: 'Telugu',
  },
  te_IN: {
    nativeName: 'తెలుగు',
    englishName: 'Telugu',
  },
  tg: {
    nativeName: 'забо́ни тоҷикӣ́',
    englishName: 'Tajik',
  },
  tg_TJ: {
    nativeName: 'тоҷикӣ',
    englishName: 'Tajik',
  },
  th: {
    nativeName: 'ภาษาไทย',
    englishName: 'Thai',
  },
  th_TH: {
    nativeName: 'ภาษาไทย (ประเทศไทย)',
    englishName: 'Thai (Thailand)',
  },
  tl: {
    nativeName: 'Filipino',
    englishName: 'Filipino',
  },
  tl_PH: {
    nativeName: 'Filipino',
    englishName: 'Filipino',
  },
  tlh: {
    nativeName: 'tlhIngan_Hol',
    englishName: 'Klingon',
  },
  tr: {
    nativeName: 'Türkçe',
    englishName: 'Turkish',
  },
  tr_TR: {
    nativeName: 'Türkçe',
    englishName: 'Turkish',
  },
  tt_RU: {
    nativeName: 'татарча',
    englishName: 'Tatar',
  },
  uk: {
    nativeName: 'Українська',
    englishName: 'Ukrainian',
  },
  uk_UA: {
    nativeName: 'Українська',
    englishName: 'Ukrainian',
  },
  ur: {
    nativeName: 'اردو',
    englishName: 'Urdu',
  },
  ur_PK: {
    nativeName: 'اردو',
    englishName: 'Urdu',
  },
  uz: {
    nativeName: "O'zbek",
    englishName: 'Uzbek',
  },
  uz_UZ: {
    nativeName: "O'zbek",
    englishName: 'Uzbek',
  },
  vi: {
    nativeName: 'Tiếng Việt',
    englishName: 'Vietnamese',
  },
  vi_VN: {
    nativeName: 'Tiếng Việt',
    englishName: 'Vietnamese',
  },
  xh_ZA: {
    nativeName: 'isiXhosa',
    englishName: 'Xhosa',
  },
  yi: {
    nativeName: 'ייִדיש',
    englishName: 'Yiddish',
  },
  yi_DE: {
    nativeName: 'ייִדיש (German)',
    englishName: 'Yiddish (German)',
  },
  zh: {
    nativeName: '中文',
    englishName: 'Chinese',
  },
  zh_Hans: {
    nativeName: '中文简体',
    englishName: 'Chinese Simplified',
  },
  zh_Hant: {
    nativeName: '中文繁體',
    englishName: 'Chinese Traditional',
  },
  zh_CN: {
    nativeName: '中文（中国）',
    englishName: 'Chinese Simplified (China)',
  },
  zh_HK: {
    nativeName: '中文（香港）',
    englishName: 'Chinese Traditional (Hong Kong)',
  },
  zh_SG: {
    nativeName: '中文（新加坡）',
    englishName: 'Chinese Simplified (Singapore)',
  },
  zh_TW: {
    nativeName: '中文（台灣）',
    englishName: 'Chinese Traditional (Taiwan)',
  },
  zu_ZA: {
    nativeName: 'isiZulu',
    englishName: 'Zulu',
  },
}
