<template>
  <div class="task_box">
    <div class="task_header" v-if="getTasks.length > 0">
      <div class="d-flex align-items-center clear">
        <h2 class="title"
          >Tasks ({{ getTaskscCompletedCount }}/{{ getTasks.length }})</h2
        >
        <!--<div v-if="getTasks.length>0" class="ml-auto prevent_close_dropdown dropdown default_style_dropdown taskDropdown">-->
        <!--<div class="dropdown_header" data-toggle="dropdown">-->
        <!--Delete All-->
        <!--</div>-->
        <!--<div class="dropdown-menu dropdown-menu-right">-->
        <!--<ul class="inner">-->
        <!--<div class="head_sec">-->
        <!--<p>Confirmation</p>-->
        <!--</div>-->
        <!--<div class="body_text">-->
        <!--<p>Are you sure? <a data-toggle="dropdown" class="red" @click="removeTask()">Yes</a> -<a data-toggle="dropdown">No</a></p>-->
        <!--</div>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->
      </div>

      <div v-if="getTasks.length > 0" class="head_top">
        <div class="circle_box">
          <!--<div id="task_stats_circle"></div>-->

          <radial-progress-bar
            innerStrokeColor="rgba(184, 189, 209, 0.16)"
            startColor="#4165ed"
            stopColor="#4165ed"
            :diameter="100"
            :completed-steps="getTasksCompletedPercentage"
            :total-steps="100"
            :width="70"
          >
            <p>{{ getTasksCompletedPercentage }}%</p>
          </radial-progress-bar>
        </div>
      </div>

      <!--<div class="progress_bar d-flex  align-items-center ">-->
      <!--<div class="text">{{getTasksCompletedPercentage}}%</div>-->
      <!--<div class="bar_count" >-->
      <!--<div class="bar_left" :style="{width: getTasksCompletedPercentage +'%'}" ></div>-->
      <!--</div>-->
      <!--</div>-->
    </div>

    <div class="f_account_list">
      <div
        v-if="getTasks.length > 0"
        class="task_inner_head d-flex align-items-center clear"
      >
        <h2 class="title">Task List</h2>
        <div
          class="ml-auto prevent_close_dropdown dropdown default_style_dropdown taskDropdown"
        >
          <div class="dropdown_header" data-toggle="dropdown"> Delete All </div>
          <div class="dropdown-menu dropdown-menu-right">
            <ul class="inner">
              <!--<div class="head_sec">-->
              <!--<p>Confirmation</p>-->
              <!--</div>-->
              <div class="body_text">
                <p
                  >Are you sure?
                  <a
                    data-toggle="dropdown"
                    class="red"
                    @click="callRemoveTask()"
                    >Yes</a
                  >
                  -<a data-toggle="dropdown">No</a></p
                >
              </div>
            </ul>
          </div>
        </div>
      </div>

      <!--<clip-loader v-if="getTasksLoader" color="#4165ed" :size="'16px'" ></clip-loader>-->
      <template v-if="getTasks.length > 0">
        <div
          class="checkbox_list"
          id="task_list"
          :class="{
            editing: !(newTaskAdd || (!newTaskAdd && !getNewTask._id)),
          }"
        >
          <div
            v-for="(task, index) in getTasks"
            class="check_list_item field_group"
            :class="{ highlight_comment: task._id && task._id === taskId }"
          >
            <!--<div class="field_group">-->

            <!--<div class="checkbox_container">-->
            <!--<label>-->
            <!--Needs Approval-->
            <!--<input type="checkbox" checked="checked">-->
            <!--<span class="check"></span>-->
            <!--</label>-->
            <!--</div>-->

            <!--</div>-->

            <div
              v-if="getNewTask._id !== task._id"
              class="checkbox_container checkbox_input_image block d-flex align-items-start"
            >
              <label :for="task._id" class="checkbox_left">
                <input
                  :id="task._id"
                  @click.prevent="
                    callChangeTaskStatus({
                      id: task._id,
                      status: !task.status,
                      index: index,
                    })
                  "
                  type="checkbox"
                  name="option22"
                  :checked="task.status"
                />
                <span class="check"></span>
                <div
                  class="desc"
                  v-html="getMentionedUsersNames(task.text)"
                ></div>
                <template v-if="getTaskImage(task.text)">
                  <img
                    v-for="taskImage in getTaskImage(task.text)"
                    :src="taskImage"
                  />
                </template>
              </label>
              <div class="right ml-auto text-right d-flex">
                <i
                  v-tooltip.top-center="'Edit'"
                  @click.prevent="UPDATE_NEW_TASK(task)"
                  v-if="!newTaskAdd"
                  class="edit_icon icon-edit-cs"
                ></i>
                <div
                  class="align-middle d-inline-block prevent_close_dropdown dropdown default_style_dropdown taskDropdown"
                >
                  <i
                    v-tooltip.top-center="'Remove'"
                    class="d-block icon-delete-cs cross_icon"
                    data-toggle="dropdown"
                  ></i>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <!--<div class="head_sec">-->
                      <!--<p>Confirmation</p>-->
                      <!--</div>-->
                      <div class="body_text">
                        <p
                          >Are you sure?
                          <a
                            @click="
                              callRemoveTask({ task: task, index: index })
                            "
                            class="red"
                            >Yes</a
                          >
                          -<a data-toggle="dropdown">No</a></p
                        >
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--......edit  button click case ........-->
            <div
              v-if="getNewTask._id && getNewTask._id === task._id"
              class="add_check_list"
            >
              <div class="edit_input">
                <div class="input_field mt-0 atwho_dropdown">
                  <at-ta
                    v-model="getNewTask.text"
                    :members="getActiveWorkspaceMembersName"
                  >
                    <textarea
                      @keyup.ctrl.enter="callSaveTask(index)"
                      :value="getNewTask.text"
                      placeholder="Write a task..."
                    ></textarea>
                  </at-ta>
                </div>
                <div class="btn_block">
                  <button
                    @click.prevent="
                      RESET_NEW_TASK()
                      showAddTaskDiv(false)
                    "
                    class="btn red border_btn"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    @click.prevent="callSaveTask(index)"
                    class="btn gradient_btn"
                  >
                    <span class="text">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="!getTasks.length > 0 && !newTaskAdd"
        class="no_blog_content mb-3 mt-5 content-state-img"
      >
        <img
          :src="
            require('../../../../../assets/img/states/no-tasks-added-yet-icon.svg')
          "
          alt=""
        />
        <p class="bold">No tasks yet</p>
        <p class="light"
          >Organize your team and stay on track by adding <br />tasks here.</p
        >
      </div>

      <!--add task section-->
      <div class="add_check_list add_tasksec">
        <div v-if="newTaskAdd" class="edit_input">
          <!--......add new button click case ........-->
          <div class="input_field mt-0">
            <div class="input_field mt-0 atwho_dropdown">
              <at-ta
                v-model="getNewTask.text"
                :members="getActiveWorkspaceMembersName"
              >
                <textarea
                  @keyup.ctrl.enter="
                    checkPostExistTask()
                    showAddTaskDiv(false)
                  "
                  :value="getNewTask.text"
                  placeholder="Write a task..."
                ></textarea>
              </at-ta>
            </div>
            <div class="btn_block">
              <!--......add new button click case ........-->
              <button
                :disabled="getCreateTaskLoader"
                @click.prevent="
                  checkPostExistTask()
                  showAddTaskDiv(false)
                "
                class="btn gradient_btn"
                :class="{ disabledSave: getNewTask.text == '' }"
              >
                <span>Save</span>
              </button>
              <button
                @click.prevent="
                  RESET_NEW_TASK()
                  showAddTaskDiv(false)
                "
                class="btn red border_btn"
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
        <button
          @click.prevent="showAddTaskDiv(true)"
          v-if="!newTaskAdd"
          class="btn btn-studio-theme-space"
        >
          <span class="text">Add Task</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.content-state-img {
  img {
    width: 140px;
    max-width: 140px !important;
  }
}
</style>
<script>
import RadialProgressBar from 'vue-radial-progress'
import { pusherSocketPublish } from '@common/lib/pusher'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AtTa from 'vue-at/dist/vue-at-textarea'
// var ProgressBar = require('progressbar.js')
export default {
  props: ['taskId'],
  components: {
    AtTa,
    RadialProgressBar
  },

  data () {
    return {
      newTaskAdd: false,
      completedPercentage: '0%',
      completedCount: 0,
      channel: ''
    }
  },

  created () {
    const self = this
    this.main_bar = this.getTasksCompletedPercentage / 100
    setTimeout(function () {
      if (self.taskId) {
        if (self.getTasks.find((task) => task._id === self.taskId)) {
          const elem = document.getElementById(self.taskId)
          self.scrollToDiv(
            document.getElementById('task_list'),
            elem.offsetTop - 180,
            600
          )
        }
      }
    }, 2000)
  },
  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `tasks_${this.getActiveWorkspace._id}`
    )
    this.bindPusherPostTasks()
    this.fetchCompletedTaskPercentage()
    this.fetchCompletedTasks()
  },
  beforeDestroy () {
    if (this.channel) this.channel.unsubscribe()
    this.channel.unbind('store_task')
    this.channel.unbind('delete_task')
    this.channel.unbind('delete_all_task')
  },

  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getTasks',
      'getPublishSelection',
      'getActiveWorkspaceMembersName',
      'getNewTask',
      'getTasksCompletedPercentage',
      'getTaskscCompletedCount',
      'getCreateTaskLoader'
    ])
  },
  methods: {
    ...mapMutations(['UPDATE_NEW_TASK', 'RESET_NEW_TASK', 'SET_TASKS']),
    ...mapActions([
      'saveTask',
      'removeTask',
      'changeTaskStatus',
      'fetchCompletedTasks',
      'fetchCompletedTaskPercentage'
    ]),

    bindPusherPostTasks () {
      this.channel.bind('store_task', (data) => {
        console.log('composer post store tasks payload => ', data)
        if (
          this.$route.params.id === data.plan_id ||
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          const task = this.getTasks.find((item) => item._id === data._id)
          if (!task) {
            this.getTasks.unshift(data)
          } else {
            const taskIndex = this.getTasks.findIndex(
              (item) => item._id === data._id
            )
            if (taskIndex >= 0) {
              this.getTasks.splice(taskIndex, 1, data)
            }
          }
        }
      })
      this.channel.bind('delete_task', (data) => {
        console.log('composer post delete task payload => ', data)

        if (
          this.$route.params.id === data.plan_id ||
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          const taskIndex = this.getTasks.findIndex(
            (item) => item._id === data._id
          )
          if (taskIndex >= 0) {
            this.getTasks.splice(taskIndex, 1)
          }
        }
      })
      this.channel.bind('delete_all_task', (data) => {
        console.log('composer post delete all task payload => ', data)
        if (
          this.$route.params.id === data.plan_id ||
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          this.SET_TASKS([])
        }
      })
    },
    showAddTaskDiv (status) {
      if (status) this.RESET_NEW_TASK()
      this.newTaskAdd = status
    },
    async callSaveTask (index) {
      console.debug('Method:callSaveTask', index)
      const taskStatus = await this.saveTask(index)
      // if (taskStatus) this.emitBlogActivities('task')
    },
    async callRemoveTask (data) {
      console.debug('Method:callRemoveTask')
      const taskStatus = await this.removeTask(data)
      // if (taskStatus) this.emitBlogActivities('task')
    },
    async callChangeTaskStatus (data) {
      console.debug('Method:callChangeTaskStatus')
      const taskStatus = await this.changeTaskStatus(data)
      // if (taskStatus) this.emitBlogActivities('task')
    }
  },

  watch: {
    'getTasks.length' (value) {
      this.fetchCompletedTaskPercentage()
      this.fetchCompletedTasks()
    }
  }
}
</script>
