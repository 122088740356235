var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-preview min-h-full flex flex-col bg-cs-light-gray"},[_c('div',{staticClass:"flex items-center justify-between px-6 h-16 max-h-16 min-h-16 preview-head"},[_c('span',{staticClass:"font-medium text-base"},[_vm._v("Post Preview")]),_c('CstIconSwitch',{model:{value:(_vm.togglePreview),callback:function ($$v) {_vm.togglePreview=$$v},expression:"togglePreview"}})],1),_c('hr',{staticClass:"m-0 hr-color"}),(_vm.hasPostDetail())?_c('div',{staticClass:"my-2 mx-1 text-center text-sm text-gray-700 leading-5 bg-cs-light-gray"},[_c('img',{staticClass:"w-5 h-5",attrs:{"src":require("@assets/img/composer/info-icon-gray.svg")}}),_c('span',[_vm._v(" The generated previews are not an exact depiction of what the post will look like on the platform, there will be some minor difference. ")])]):_vm._e(),_c('div',{staticClass:"py-3.5 px-4 flex-grow flex-shrink flex-basis-auto overflow-y-scroll h-0 cs-hr-scroll scroll-padding",class:{ 'relative overflow-hidden': _vm.togglePreview }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"web",class:{ 'mobile-preview w-11/12 absolute': _vm.togglePreview }},[_c('div',{class:{ 'mobile-inner snap-y': _vm.togglePreview }},[(Object.keys(_vm.previewAccounts).length === 0)?_c('div',{staticClass:"h-100 text-center py-8"},[_c('img',{staticClass:"mt-4 w-52 opacity-70",attrs:{"src":require("@assets/img/composer/no-preview.png"),"alt":""}}),_c('span',{staticClass:"mt-6 block text-md text-gray-800 leading-5"},[_vm._v(" You have not selected any social accounts yet. ")])]):(_vm.hasPostDetail())?[(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'facebook') &&
                _vm.previewAccounts['facebook']
              )?_c('FacebookPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"common-box-status":_vm.status,"account":_vm.getAccountDetail('facebook'),"detail":_vm.getSharingDetail('facebook'),"publish-as":_vm.getFacebookPublishAs,"carousel":_vm.carousel,"carousel-account":_vm.getFirstCarouselAccount(),"facebook-post-type":_vm.facebookPostType,"toggle-preview":_vm.togglePreview,"comment":_vm.getComment('facebook')}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'instagram') &&
                _vm.previewAccounts['instagram']
              )?_c('InstagramPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"common-box-status":_vm.status,"account":_vm.getAccountDetail('instagram'),"detail":_vm.getSharingDetail('instagram'),"instagram-post-type":_vm.instagramPostType,"toggle-preview":_vm.togglePreview,"comment":_vm.getComment('instagram'),"user-tags":_vm.getUserTags(_vm.status ? 'common' : 'instagram')}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'twitter') &&
                _vm.previewAccounts['twitter']
              )?_c('TwitterPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"common-box-status":_vm.status,"account":_vm.getAccountDetail('twitter'),"detail":_vm.getSharingDetail('twitter'),"link-detail":_vm.getTwitterUrl(),"thread":[..._vm.getThreadedTweet()]}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'linkedin') &&
                _vm.previewAccounts['linkedin']
              )?_c('LinkedinPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('linkedin'),"detail":_vm.getSharingDetail('linkedin'),"comment":_vm.getComment('linkedin')}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'pinterest') &&
                _vm.previewAccounts['pinterest']
              )?_c('PinterestPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('pinterest'),"detail":_vm.getSharingDetail('pinterest'),"followers-count":180,"source-url":""}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'gmb') &&
                _vm.previewAccounts['gmb']
              )?_c('GmbPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('gmb'),"detail":_vm.getSharingDetail('gmb'),"cta":_vm.getCTA(_vm.status ? 'common' : 'gmb'),"event-title":_vm.getGmbEventTitle(),"event-date":_vm.getGmbEventDate()}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'youtube') &&
                _vm.previewAccounts['youtube']
              )?_c('YoutubePreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('youtube'),"detail":_vm.getSharingDetail('youtube'),"video-title":_vm.youtubeTitle,"youtube-post-type":_vm.youtubePostType,"comment":_vm.getComment('youtube')}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'tiktok') &&
                _vm.previewAccounts['tiktok']
              )?_c('TikTokPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('tiktok'),"detail":_vm.getSharingDetail('tiktok')}}):_vm._e(),(
                _vm.hasPlatformDetail(_vm.status ? 'common' : 'tumblr') &&
                _vm.previewAccounts['tumblr']
              )?_c('TumblrPreview',{staticClass:"mb-3 scroll-snap-start",attrs:{"account":_vm.getAccountDetail('tumblr'),"detail":_vm.getSharingDetail('tumblr')}}):_vm._e()]:_c('div',{staticClass:"h-100 text-center py-8"},[_c('img',{staticClass:"mt-4 w-52 opacity-70",attrs:{"src":require("@assets/img/composer/no-preview.png"),"alt":""}}),_c('span',{staticClass:"mt-6 block text-md text-gray-800 leading-5"},[_vm._v(" You have not composed any message or attached any image/video yet.")])])],2)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }