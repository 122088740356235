var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"authentication_component"},[_c('div',{staticClass:"auth_right login_block setPassword_block"},[_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("×")]),_c('div',{staticClass:"d-flex align-items-center height",staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"_wrapper m-auto"},[_vm._m(0),_c('div',{staticClass:"auth_right_inner"},[_vm._m(1),_c('div',{staticClass:"line_input clear"},[_c('form',[_c('div',{staticClass:"flex flex-col space-y-3 mb-3"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('label',{staticClass:"flex flex-col space-y-1 w-full",attrs:{"for":"first-name"}},[_vm._m(2),_c('CstInputFields',{attrs:{"size":"large","placeholder":"e.g Carl"},on:{"change":(val) => {
                          _vm.firstName = val
                        }}})],1),_c('label',{staticClass:"flex flex-col space-y-1 w-full",attrs:{"for":"last-name"}},[_vm._m(3),_c('CstInputFields',{attrs:{"size":"large","placeholder":"e.g Frederick"},on:{"change":(val) => {
                          _vm.lastName = val
                        }}})],1)]),_c('label',{staticClass:"flex flex-col space-y-1",attrs:{"for":"email"}},[_vm._m(4),_c('CstInputFields',{staticClass:"!bg-gray-500 !text-gray-700",attrs:{"type":"email","size":"large","placeholder":"e.g example@domain.com","value":_vm.getEmail,"disabled":"disabled"}})],1),_c('label',{staticClass:"flex flex-col space-y-1",attrs:{"for":"password"}},[_vm._m(5),_c('CstInputFields',{attrs:{"type":"password","size":"large","placeholder":"e.g ********"},on:{"change":(val) => {
                        _vm.password = val
                      }}})],1),_c('label',{staticClass:"flex flex-col space-y-1",attrs:{"for":"confirm-password"}},[_vm._m(6),_c('CstInputFields',{attrs:{"type":"password","size":"large","placeholder":"e.g ********"},on:{"change":(val) => {
                        _vm.cpassword = val
                      }}})],1)]),_c('div',{staticClass:"btn_block"},[_c('button',{staticClass:"btn btn_loader loader_right",attrs:{"disabled":_vm.loader || _vm.disable},on:{"click":function($event){$event.preventDefault();return _vm.setPassword.apply(null, arguments)}}},[(_vm.loader)?_c('span',[_vm._v("Processing")]):_c('span',[_vm._v("Sign in")]),(_vm.loader)?_c('img',{staticStyle:{"width":"20px","margin-left":"8px"},attrs:{"src":require("../../assets/img/common/gif_loader_white.gif"),"alt":""}}):_vm._e()])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_logo d-block"},[_c('img',{attrs:{"src":require("../../assets/img/logo/logo_text_logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_block"},[_c('h2',[_vm._v("Welcome! You are just one step away!")]),_c('p',[_vm._v("To keep your account secured and fully accessible,")]),_c('p',[_vm._v("we'd love if you'd be up for adding password.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 font-medium"},[_vm._v("First name"),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 font-medium"},[_vm._v("Last name"),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 font-medium"},[_vm._v("E-mail"),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 font-medium"},[_vm._v("Password"),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 font-medium"},[_vm._v("Confirm password"),_c('span',{staticClass:"text-red-600 ml-1"},[_vm._v("*")])])
}]

export { render, staticRenderFns }