<template>
  <div class="card_v1">
    <div class="card_inner">
      <div class="card_head">
        <h2>Average Engagement by Network</h2>
        <ul class="ds_item_highlight" id="average_engagement_item_highlight">
        </ul>
      </div>

      <div class="card_content" :class="{ card_content_blur: isZero() }">
        <template v-if="isZero()">
          <div class="no_data_for_chart">{{ noDataMessage }}</div>
        </template>
        <div :class="{ blur_div: isZero() }">
          <highcharts
            :options="averageEngagement"
            ref="average_engagement_chart"
          ></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'
const itemsList = [
  '<i class=" cs-facebook"></i>Facebook',
  '<i class="cs-reddit"></i>Reddit',
  '<i class=" cs-pinterest"></i>Pinterest'
]

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      averageEngagement: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f} at {point.name}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#average_engagement_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#6f8cc7', '#ff4500', '#cb2026', '#0078b5', '#39475d'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['Facebook', 'Reddit', 'Pinterest', 'Grapes', 'Bananas'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          formatter: function () {
            return itemsList[this.value]
          },
          useHtml: true
        },
        yAxis: {
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          gridLineColor: '#eaf0f4',
          title: {
            text: 'Number of Engagements',
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          min: 0
          // categories: ['0', '5', '10', '15', '20', '25']
        },
        plotOptions: {
          series: {
            pointWidth: 25,
            borderWidth: 0,
            lineWidth: 3
          },
          column: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            colorByPoint: true
          }
        },
        series: [
          {
            name: 'Average Engagement',
            data: [
              ['Facebook', 100],
              ['Reddit', 30],
              ['Pinterest', 70]
            ]
          }
        ]
      }
    }
  },
  created () {
    if (this.isZero() == false) {
      console.log('Is not zero')
      this.averageEngagement.series[0].data = [
        ['Facebook', parseInt(this.getInsightsEngagement.average.facebook)],
        ['Reddit', parseInt(this.getInsightsEngagement.average.reddit)],
        ['Pinterest', parseInt(this.getInsightsEngagement.average.pinterest)]
      ]
    }
  },
  computed: {
    ...mapGetters(['getInsightsEngagement'])
  },
  methods: {
    isZero () {
      if (
        parseInt(this.getInsightsEngagement.average.facebook) +
          parseInt(this.getInsightsEngagement.average.reddit) +
          parseInt(this.getInsightsEngagement.average.pinterest) ==
        0
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
