<template>
  <div
    class="flex items-center bg-[#3a45570a] ml-0 rounded-xl p-4 m-[0.4rem] hover:bg-[#eaeaea]"
  >
    <div
      class="w-[80%] lg:w-[88%] cursor-pointer"
      @click="updateAndAddTextToEditor(caption)"
    >
      <h4 class="text-base">{{ caption?.title || '---' }}</h4>
      <p class="truncate w-full text-[#3a4557] text-opacity-50">{{
        caption?.description || '---'
      }}</p>
    </div>
    <div class="flex">
      <div class="dropdown relative inline-block">
        <i
          v-tooltip="'Delete'"
          data-toggle="dropdown"
          class="far fa-trash-alt text-[#ff0000] mr-2.5 w-[38px] h-[40px] flex justify-center items-center rounded-xl bg-white font-normal cursor-pointer hover:border-[1px] hover:border-solid hover:border-[#2f8ae0]"
        ></i>
        <div
          class="w-full mt-4 p-0 bg-white border-[1px] border-solid border-[#666b850d] shadow-md dropdown-menu dropdown-menu-right min-w-[13.438rem] py-4 rounded-xl"
        >
          <p class="text-[#989eb5] text-sm my-4 mx-5"
            >Are you sure?
            <span
              class="red ml-2 cursor-pointer underline"
              @click="deleteCaptions"
              >Yes</span
            >
            -<span class="ml-2 cursor-pointer underline text-gray-700">No</span>
          </p>
        </div>
      </div>
      <i
        v-tooltip="'Edit'"
        class="icon-edit-cs w-[38px] h-[40px] flex justify-center items-center rounded-xl bg-white cursor-pointer font-normal text-[#2f8ae0] hover:border-[1px] hover:border-solid hover:border-[#2f8ae0]"
        @click.prevent="setCaptionToUpdate(caption)"
      ></i>
    </div>
    <div></div>
  </div>
</template>

<script>
// libraries
import { defineComponent, ref } from 'vue'
import { useStore } from '@state/base'

// constants
import proxy from '@common/lib/http-common'
import { deleteCaptionApiUrl } from '@src/config/api-utils'

export default defineComponent({
  name: 'CaptionItem',
  props: {
    caption: {
      type: Object,
      default: () => {},
    },
    removeCaptionFromList: {
      type: Function,
      default: () => {},
    },
    setCaptionToUpdate: {
      type: Function,
      default: () => {},
    },
    updateAndAddTextToEditor: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore()
    const captionDeleting = ref(false)

    // methods
    /**
     * delete caption
     * @returns {Promise<void>}
     */
    const deleteCaptions = async () => {
      // process caption
      try {
        captionDeleting.value = true
        const response = await proxy.delete(
          `${deleteCaptionApiUrl}${props.caption._id}`
        )
        await store.dispatch('toastNotification', {
          message: response?.data?.message,
          type: 'success',
        })
        captionDeleting.value = false
        props.removeCaptionFromList(props.caption._id)
      } catch (error) {
        captionDeleting.value = false
        await store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }

    return {
      deleteCaptions,
    }
  },
})
</script>
