<template>
  <div class="card_v1">
    <div class="card_inner">
      <div class="card_head">
        <h2>Popular Word Count</h2>
        <ul class="ds_item_highlight" id="popular_word_count_item_highlight">
        </ul>
      </div>
      <div class="card_content" :class="{ card_content_blur: isZero() }">
        <template v-if="isZero()">
          <div class="no_data_for_chart">{{ noDataMessage }}</div>
        </template>
        <div :class="{ blur_div: isZero() }">
          <highcharts
            :options="popularWordCount"
            ref="popular_reading_chart"
          ></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      popularWordCount: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{point.key} Word Count</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} articles.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_word_count_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#5e69bc', '#8ca8dd'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 40,
            borderRadiusTopRight: 40,
            pointPadding: 0,
            groupPadding: 0.3
          }
        },
        series: [
          {
            type: 'column',
            name: 'Engagement Per Article',
            data: [11, 13, 4, 73, 3]
          },
          {
            type: 'column',
            name: 'Number of Articles',
            data: [11, 23, 4, 23, 43],
            yAxis: 1
          }
        ]
      }
    }
  },
  created () {
    if (this.isZero() == false) {
      this.popularWordCount.series[0].data =
        this.getInsightsPopularWordCount.interactions
      this.popularWordCount.series[1].data =
        this.getInsightsPopularWordCount.articles
      this.popularWordCount.xAxis.categories =
        this.getInsightsPopularWordCount.categories
    }
  },
  methods: {
    isZero () {
      if (
        this.getInsightsPopularWordCount.categories.length == 0 &&
        this.getInsightsPopularWordCount.articles.length == 0 &&
        this.getInsightsPopularWordCount.interactions.length == 0
      ) {
        return true
      }
      return false
    }
  },
  computed: {
    ...mapGetters(['getInsightsPopularWordCount'])
  }
}
</script>
