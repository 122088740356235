<script>
import { mapGetters } from 'vuex'
import integrations from '@common/lib/integrations'

export default {
  props: ['details'],
  data () {
    return {
      integrations: integrations
    }
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getGmbOptions',
      'getCommonSharingDetails',
      'getGmbSharingDetails'
    ]),
    previewAccountImage () {
      return this.channelImage( this.getPreviewAccount.gmb,'gmb')
    },
    isCtaTopicSelection () {
      return this.getGmbOptions.topic_type === 'STANDARD'
    },
    isOfferTopicSelection () {
      return this.getGmbOptions.topic_type === 'OFFER'
    },
    postingTimeText () {
      return this.getWorkspaceTimezonCurrentTime('MMM D, YYYY')
    },
    isImagePost () {
      return this.details.image && this.details.image.length > 0
    },
    postingImage () {
      return this.details.image.constructor === Array &&
        this.details.image.length
        ? this.details.image[0]
        : this.details.image
    },
    isVideoPost () {
      return this.details.video && this.details.video.link
    },
    showPreviewTitle () {
      return (
        !this.isCtaTopicSelection &&
        this.getGmbOptions.title &&
        this.hasSchedule
      )
    },
    scheduleTimeText () {
      return (
        'Valid ' +
        this.getDateTimeFormat(this.getGmbOptions.start_date, 'MMM D') +
        ' - ' +
        this.getDateTimeFormat(this.getGmbOptions.end_date, 'MMM D')
      )
    },
    showCallToAction () {
      return (
        !this.isOfferTopicSelection &&
        this.getGmbOptions.action_type &&
        (this.getGmbOptions.action_type === 'CALL' ||
          this.isValidUrl(this.postingCallToActionLink))
      )
    },
    postingCallToActionLink () {
      if (this.getGmbOptions.action_type === 'CALL') return false
      const link = this.getCommonBoxOptions.status
        ? this.getCommonSharingDetails.cta_link
        : this.getGmbSharingDetails.cta_link
      return this.parseLink(link)
    },
    callToActionButtonText () {
      const text = this.getGmbOptions.action_type
        .toLowerCase()
        .replace('_', ' ')
      return text.charAt(0).toUpperCase() + text.slice(1)
    },
    hasCouponCode () {
      return this.getGmbOptions.coupon_code
    },
    hasSchedule () {
      return this.getGmbOptions.start_date && this.getGmbOptions.end_date
    },
    couponScheduleTimeText () {
      return (
        'Valid ' +
        this.getDateTimeFormat(
          this.getGmbOptions.start_date,
          'M/D/YY, h:mm A'
        ) +
        ' - ' +
        this.getDateTimeFormat(this.getGmbOptions.end_date, 'M/D/YY, h:mm A')
      )
    },
    postingRedeemOnlineUrl () {
      return this.parseLink(this.getGmbOptions.redeem_online_url)
    },
    hasValidRedeemOnlineUrl () {
      return this.isValidUrl(this.getGmbOptions.redeem_online_url)
    },
    hasTermsConditions () {
      return this.getGmbOptions.terms_conditions
    }
  },
  methods: {
    parseLink (link) {
      if (
        link &&
        link.indexOf('https://') === -1 &&
        link.indexOf('http://') === -1
      ) {
        link = 'http://' + link
      }
      return link
    }
  }
}
</script>
<template>
  <div id="gmb_tab">
    <div class="gmb_post_preview">
      <div class="gmb_post_preview__head">
        <div class="profile_picture d-flex align-items-center">
          <div class="picture_block">
            <img
              :src="previewAccountImage"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div class="text_block">
            <p class="text">{{ getPreviewAccount.gmb.location_name }}</p>
            <p class="sub_text">{{ postingTimeText }}</p>
          </div>
        </div>
      </div>

      <div class="preview_images" v-if="isImagePost">
        <img class="single_image" :src="postingImage" alt="" />
      </div>

      <template v-if="isVideoPost">
        <div class="preview_video">
          <!--<span class="facebook_vid_icon vid_icon">-->
          <!--<span class="icon"></span>-->
          <!--</span>-->
          <img
            class="img"
            :src="details.video.thumbnail"
            alt=""
            style="width: 100%"
          />
        </div>
      </template>

      <div class="preview_title" v-if="showPreviewTitle">
        <h3>{{ getGmbOptions.title }}</h3>
        <p>{{ scheduleTimeText }}</p>
      </div>

      <div class="sec_padding preview_desc" v-if="!isVideoPost"
        v-html="processSharingPreviewDesciption(details.message, 'Gmb')"
      ></div>

      <div class="btn_block" v-if="showCallToAction">
        <a :href="postingCallToActionLink" class="btn gradient_btn">
          <span>{{ callToActionButtonText }}</span>
        </a>
      </div>

      <template v-if="isOfferTopicSelection">
        <div class="discount_block" v-if="hasCouponCode">
          <div class="discount_block_inner">
            <p class="gray">Show this code at the shop</p>
            <h3>{{ getGmbOptions.coupon_code }}</h3>
            <p class="dark" v-if="hasSchedule"
              ><b>{{ couponScheduleTimeText }}</b></p
            >
          </div>
        </div>

        <div class="redeem_link" v-if="hasValidRedeemOnlineUrl">
          <a :href="postingRedeemOnlineUrl" target="_blank">Redeem online</a>
        </div>

        <div class="term_block" v-if="hasTermsConditions">
          <div class="term_block_inner">{{
            getGmbOptions.terms_conditions
          }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
