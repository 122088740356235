import { rewritingTypes } from '@src/modules/publish/store/states/mutation-types'

export const getDefaultRewritingSelection = () => {
  return {
    type: 'none',
    spinner: {
      type: 'quillbot',
      wordai: {
        type: 'turing',
        quality: 'Regular',
        reg_quality: 50
      },
      spinrewriter: {
        confidence_level: 'high',
        nested_spintax: true,
        auto_sentences: true,
        auto_paragraphs: false
      },
      quillbot: {
        strength: 3,
        autoflip: 0.5
      }
    },
    translater: {
      language: 'en'
    }
  }
}

export default {
  state: {
    selection: getDefaultRewritingSelection(),
    progress: {
      total: 0,
      processed: 0
    }
  },
  actions: {
    setRewritingType ({ commit }, type) {
      commit('setRewritingType', type)
    },

    setSpinnerType ({ commit }, type) {
      commit('setSpinnerType', type)
    }
  },
  mutations: {
    [rewritingTypes.SET_REWRITING_SELECTION] (state, value) {
      if (typeof value.spinner.quillbot === 'undefined') { value.spinner.quillbot = { strength: 3, autoflip: 0.5 } }
      if (typeof value.spinner.quillbot.autoflip === 'undefined') { value.spinner.quillbot.autoflip = 0.5 }
      state.selection = value
    },
    [rewritingTypes.RESET_REWRITING_SELECTION] (state) {
      state.selection = getDefaultRewritingSelection()
    },

    setRewritingType (state, type) {
      state.selection.type = type
    },

    setSpinnerType (state, type) {
      state.selection.spinner.type = type
    },

    SET_REWRITING_TOTAL (state, total) {
      state.progress.total = total
    },

    SET_REWRITING_PROCESSED (state, processed) {
      state.progress.processed = processed
    }
  },
  getters: {
    getRewritingSelection: (state) => {
      return state.selection
    },
    getRewritingProgress: (state) => {
      return state.progress
    }
  }
}
