export default {
  state: {
    fetchAutomationsCounts: false,

    // evergreen
    saveEvergreenAutomation: false,
    fetchEvergreenAutomations: false,
    removeEvergreenAutomation: false,
    fetchEvergreenAutomation: false,
    changeEvergreenAutomationStatus: false,
    evergreenAutomationBulkOperation: false,

    // bulk csv

    fetchCSVAutomations: false,

    // rss
    saveRssAutomation: false,
    fetchRssAutomations: false,
    removeRssAutomation: false,
    fetchRssAutomation: false,
    changeRssAutomationStatus: false,
    rssAutomationBulkOperation: false,

    // video
    fetchVideoBlogAutomations: false,
    fetchVideoSocialAutomations: false,
    removeVideoAutomation: false,
    restartVideoAutomation: false,
    fetchVideoAutomation: false,
    saveVideoAutomation: false,
    changeVideoAutomationStatus: false,
    videoAutomationBulkOperation: false,

    // article
    fetchArticleBlogAutomations: false,
    fetchArticleSocialAutomations: false,
    removeArticleAutomation: false,
    fetchArticleAutomation: false,
    changeArticleAutomationStatus: false,
    articleAutomationBulkOperation: false
  },
  actions: {
    setFetchAutomationsCountsLoader ({ commit }, status) {
      commit('setFetchAutomationsCountsLoader', status)
    },

    // evergreen
    setFetchEvergreenAutomationsLoader ({ commit }, status) {
      commit('setFetchEvergreenAutomationsLoader', status)
    },

    setSaveEvergreenAutomationLoader ({ commit }, status) {
      commit('setSaveEvergreenAutomationLoader', status)
    },

    setRemoveEvergreenAutomationLoader ({ commit }, status) {
      commit('setRemoveEvergreenAutomationLoader', status)
    },

    setFetchEvergreenAutomationLoader ({ commit }, status) {
      commit('setFetchEvergreenAutomationLoader', status)
    },

    setChangeEvergreenAutomationStatusLoader ({ commit }, status) {
      commit('setChangeEvergreenAutomationStatusLoader', status)
    },

    setEvergreenAutomationBulkOperationLoader ({ commit }, status) {
      commit('setEvergreenAutomationBulkOperationLoader', status)
    },

    // bulk csv

    setFetchCSVAutomationsLoader ({ commit }, status) {
      commit('setFetchCSVAutomationsLoader', status)
    },

    // rss
    setFetchRssAutomationsLoader ({ commit }, status) {
      commit('setFetchRssAutomationsLoader', status)
    },

    setSaveRssAutomationLoader ({ commit }, status) {
      commit('setSaveRssAutomationLoader', status)
    },

    setRemoveRssAutomationLoader ({ commit }, status) {
      commit('setRemoveRssAutomationLoader', status)
    },

    setFetchRssAutomationLoader ({ commit }, status) {
      commit('setFetchRssAutomationLoader', status)
    },

    setChangeRssAutomationStatusLoader ({ commit }, status) {
      commit('setChangeRssAutomationStatusLoader', status)
    },

    setRssAutomationBulkOperationLoader ({ commit }, status) {
      commit('setRssAutomationBulkOperationLoader', status)
    },

    // video

    setFetchVideoBlogAutomationsLoader ({ commit }, status) {
      commit('setFetchVideoBlogAutomationsLoader', status)
    },

    setFetchVideoSocialAutomationsLoader ({ commit }, status) {
      commit('setFetchVideoSocialAutomationsLoader', status)
    },

    setRemoveVideoAutomationLoader ({ commit }, status) {
      commit('setRemoveVideoAutomationLoader', status)
    },

    setRestartVideoAutomationLoader ({ commit }, status) {
      commit('setRestartVideoAutomationLoader', status)
    },

    setFetchVideoAutomationLoader ({ commit }, status) {
      commit('setFetchVideoAutomationLoader', status)
    },

    setSaveVideoAutomationLoader ({ commit }, status) {
      commit('setSaveVideoAutomationLoader', status)
    },

    setChangeVideoAutomationStatusLoader ({ commit }, status) {
      commit('setChangeVideoAutomationStatusLoader', status)
    },

    // article

    setFetchArticleBlogAutomationsLoader ({ commit }, status) {
      commit('setFetchArticleBlogAutomationsLoader', status)
    },

    setFetchArticleAutomationLoader ({ commit }, status) {
      commit('setFetchArticleAutomationLoader', status)
    },

    setSaveArticleAutomationLoader ({ commit }, status) {
      commit('setSaveArticleAutomationLoader', status)
    },

    setChangeArticleAutomationStatusLoader ({ commit }, status) {
      commit('setChangeArticleAutomationStatusLoader', status)
    },

    setArticleAutomationBulkOperationLoader ({ commit }, status) {
      commit('setArticleAutomationBulkOperationLoader', status)
    },

    setVideoAutomationBulkOperationLoader ({ commit }, status) {
      commit('setVideoAutomationBulkOperationLoader', status)
    }
  },
  mutations: {
    setFetchAutomationsCountsLoader (state, status) {
      state.fetchAutomationsCounts = status
    },

    // evergreen
    setFetchEvergreenAutomationsLoader (state, status) {
      state.fetchEvergreenAutomations = status
    },

    setSaveEvergreenAutomationLoader (state, status) {
      state.saveEvergreenAutomation = status
    },

    setRemoveEvergreenAutomationLoader (state, status) {
      state.removeEvergreenAutomation = status
    },

    setFetchEvergreenAutomationLoader (state, status) {
      state.fetchEvergreenAutomation = status
    },

    setChangeEvergreenAutomationStatusLoader (state, status) {
      state.changeEvergreenAutomationStatus = status
    },

    setEvergreenAutomationBulkOperationLoader (state, status) {
      state.evergreenAutomationBulkOperation = status
    },

    // bulk csv
    setFetchCSVAutomationsLoader (state, status) {
      state.fetchCSVAutomations = status
    },

    // rss
    setFetchRssAutomationsLoader (state, status) {
      state.fetchRssAutomations = status
    },

    setSaveRssAutomationLoader (state, status) {
      state.saveRssAutomation = status
    },

    setRemoveRssAutomationLoader (state, status) {
      state.removeRssAutomation = status
    },

    setFetchRssAutomationLoader (state, status) {
      state.fetchRssAutomation = status
    },

    setChangeRssAutomationStatusLoader (state, status) {
      state.changeRssAutomationStatus = status
    },

    setRssAutomationBulkOperationLoader (state, status) {
      state.rssAutomationBulkOperation = status
    },

    // video

    setFetchVideoBlogAutomationsLoader (state, status) {
      state.fetchVideoBlogAutomations = status
    },

    setFetchVideoSocialAutomationsLoader (state, status) {
      state.fetchVideoSocialAutomations = status
    },

    setRemoveVideoAutomationLoader (state, status) {
      state.removeVideoAutomation = status
    },
    setRestartVideoAutomationLoader (state, status) {
      state.restartVideoAutomation = status
    },

    setFetchVideoAutomationLoader (state, status) {
      state.fetchVideoAutomation = status
    },

    setSaveVideoAutomationLoader (state, status) {
      state.saveVideoAutomation = status
    },

    setChangeVideoAutomationStatusLoader (state, status) {
      state.changeVideoAutomationStatus = status
    },

    // article

    setFetchArticleBlogAutomationsLoader (state, status) {
      state.fetchArticleBlogAutomations = status
    },

    setFetchArticleAutomationLoader (state, status) {
      state.fetchArticleAutomation = status
    },

    setSaveArticleAutomationLoader (state, status) {
      state.saveArticleAutomation = status
    },

    setChangeArticleAutomationStatusLoader (state, status) {
      state.changeArticleAutomationStatus = status
    },

    setVideoAutomationBulkOperationLoader (state, status) {
      state.videoAutomationBulkOperation = status
    }
  },
  getters: {
    getAutomationLoaders: (state) => {
      return state
    }
  }
}
