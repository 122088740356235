var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AnalyticsSummaryBlock',{attrs:{"heading":"All Profile Performance Summary","subtitle":"View your key profile performance metrics from the reporting period."}},[(_vm.fetchingStatistics)?[_c('div',{staticClass:"flex-5c-row"},_vm._l((5),function(i){return _c('div',{key:i,staticClass:"flex-fc-col",class:{ 'pl-0': i === 1, 'pr-0': i === 5 }},[_c('SkeletonBox',{attrs:{"width":'100%',"height":"92px","radius":"10px"}})],1)}),0)]:_c('div',{staticClass:"flex-5c-row"},[_c('div',{staticClass:"flex-fc-col pl-0"},[(
            _vm.aggregations &&
            typeof _vm.aggregations.total_posts != 'undefined' &&
            _vm.aggregations.total_posts.value != null
          )?_c('SummaryCard',{attrs:{"title":"Posts","total":_vm.aggregations.total_posts.value,"reach":_vm.getReachCurrentPrevious(
              _vm.aggregations.total_posts.value,
              _vm.previous_aggregations.total_posts.value
            ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
              _vm.aggregations.total_posts.value,
              _vm.previous_aggregations.total_posts.value
            ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Posts","total":"0","reach":"0%","variant":"rollup"}})],1),_c('div',{staticClass:"flex-fc-col"},[(
            _vm.aggregations &&
            typeof _vm.aggregations.total_retweets != 'undefined' &&
            _vm.aggregations.total_retweets.value != null
          )?_c('SummaryCard',{attrs:{"title":"Retweets","total":_vm.aggregations.total_retweets.value,"reach":_vm.getReachCurrentPrevious(
              _vm.aggregations.total_retweets.value,
              _vm.previous_aggregations.total_retweets.value
            ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
              _vm.aggregations.total_retweets.value,
              _vm.previous_aggregations.total_retweets.value
            ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Retweets","total":"0","reach":"0%","variant":"rollup"}})],1),_c('div',{staticClass:"flex-fc-col"},[(
            _vm.aggregations &&
            typeof _vm.aggregations.total_engagement != 'undefined' &&
            _vm.aggregations.total_engagement.value != null
          )?_c('SummaryCard',{attrs:{"title":"Engagements","total":_vm.aggregations.total_engagement.value,"reach":_vm.getReachCurrentPrevious(
              _vm.aggregations.total_engagement.value,
              _vm.previous_aggregations.total_engagement.value
            ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
              _vm.aggregations.total_engagement.value,
              _vm.previous_aggregations.total_engagement.value
            ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Engagements","total":"0","reach":"0%","variant":"rollup"}})],1),_c('div',{staticClass:"flex-fc-col"},[(
            _vm.aggregations &&
            typeof _vm.aggregations.total_following != 'undefined' &&
            _vm.aggregations.total_following.value != null
          )?_c('SummaryCard',{attrs:{"title":"Following","total":_vm.aggregations.total_following.value,"reach":_vm.getReachCurrentPrevious(
              _vm.aggregations.total_following.value,
              _vm.previous_aggregations.total_following.value
            ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
              _vm.aggregations.total_following.value,
              _vm.previous_aggregations.total_following.value
            ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Following","total":"0","reach":"0%","variant":"rollup"}})],1),_c('div',{staticClass:"flex-fc-col pr-0"},[(
            _vm.aggregations &&
            typeof _vm.aggregations.total_followers_count != 'undefined' &&
            _vm.aggregations.total_followers_count.value != null
          )?_c('SummaryCard',{attrs:{"title":"Followers","total":_vm.aggregations.total_followers_count.value,"reach":_vm.getReachCurrentPrevious(
              _vm.aggregations.total_followers_count.value,
              _vm.previous_aggregations.total_followers_count.value
            ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
              _vm.aggregations.total_followers_count.value,
              _vm.previous_aggregations.total_followers_count.value
            ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Followers","total":"0","reach":"0%","variant":"rollup"}})],1)])],2),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-9 p-0"},[_c('section',{staticClass:"mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"},[_vm._m(0),(_vm.fetchingPublishedPostsWithEngagement)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
            _vm.published_posts_with_engagement &&
            _vm.published_posts_with_engagement.time_line &&
            !(
              _vm.isEmptyBucket(_vm.published_posts_with_engagement.posts) &&
              _vm.isEmptyBucket(_vm.published_posts_with_engagement.engagements)
            )
          )?_c('SplineBasicChart',{attrs:{"categories":_vm.published_posts_with_engagement.time_line,"series":_vm.setSeriesForPublishedPostsEngagements(
              _vm.published_posts_with_engagement
            )}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(1)])],2)]),_c('div',{staticClass:"col-3 p-0 m-0 analytics-slide-summary"},[_c('section',{staticClass:"mt-0"},[_c('h2',[_vm._v("Net Publish Post with Engagement")]),(_vm.fetchingAggregations)?_vm._l((3),function(i){return _c('div',{key:i,staticStyle:{"margin-top":"1.3rem"}},[_c('SkeletonBox',{attrs:{"width":'100%',"height":"92px","radius":"10px"}})],1)}):[(
              _vm.aggregations &&
              typeof _vm.aggregations.total_posts != 'undefined' &&
              _vm.aggregations.total_posts.value != null
            )?_c('SummaryCard',{attrs:{"title":"Number of Posts","total":_vm.aggregations.total_posts.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.total_posts.value,
                _vm.previous_aggregations.total_posts.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.total_posts.value,
                _vm.previous_aggregations.total_posts.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Number of Posts","total":"0","reach":"0%","variant":"rollup"}}),(
              _vm.aggregations &&
              typeof _vm.aggregations.avg_engagements != 'undefined' &&
              _vm.aggregations.avg_engagements.value != null
            )?_c('SummaryCard',{attrs:{"title":"Avg Engagements (Likes + RTs/Post)","total":_vm.aggregations.avg_engagements.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.avg_engagements.value,
                _vm.previous_aggregations.avg_engagements.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.avg_engagements.value,
                _vm.previous_aggregations.avg_engagements.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Avg Engagements (Likes + RTs/Post)","total":"0","reach":"0%","variant":"rollup"}})]],2)])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-9 p-0"},[_c('section',{staticClass:"mr-0 analytics-fb-audience h-chart-section-wrapper"},[_vm._m(2),(_vm.fetchingFans)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
            _vm.fans &&
            !(_vm.isEmptyBucket(_vm.fans.favourites) && _vm.isEmptyBucket(_vm.fans.retweets))
          )?_c('ColumnCrossChart',{attrs:{"categories":_vm.fans.timeLine,"series":_vm.setSeriesForEngagements(_vm.fans)}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(3)])],2)]),_c('div',{staticClass:"col-3 p-0 m-0 analytics-slide-summary"},[_c('section',[_c('h2',[_vm._v("Engagement Rollup")]),(_vm.fetchingFans)?_vm._l((4),function(i){return _c('div',{key:i,staticStyle:{"margin-top":"1.3rem"}},[_c('SkeletonBox',{attrs:{"width":'100%',"height":"92px","radius":"10px"}})],1)}):[(
              _vm.aggregations &&
              typeof _vm.aggregations.total_engagement != 'undefined' &&
              _vm.aggregations.total_engagement.value != null
            )?_c('SummaryCard',{attrs:{"title":"Total Engagement","total":_vm.aggregations.total_engagement.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.total_engagement.value,
                _vm.previous_aggregations.total_engagement.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.total_engagement.value,
                _vm.previous_aggregations.total_engagement.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Total Engagement","total":0,"reach":"0%","variant":"rollup"}}),(
              _vm.aggregations &&
              typeof _vm.aggregations.total_retweets != 'undefined' &&
              _vm.aggregations.total_retweets.value != null
            )?_c('SummaryCard',{attrs:{"title":"Retweet","total":_vm.aggregations.total_retweets.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.total_retweets.value,
                _vm.previous_aggregations.total_retweets.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.total_retweets.value,
                _vm.previous_aggregations.total_retweets.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Retweet","total":0,"reach":"0%","variant":"rollup"}}),(
              _vm.aggregations &&
              typeof _vm.aggregations.favorites != 'undefined' &&
              _vm.aggregations.favorites.value != null
            )?_c('SummaryCard',{attrs:{"title":"Likes","total":_vm.aggregations.favorites.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.favorites.value,
                _vm.previous_aggregations.favorites.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.favorites.value,
                _vm.previous_aggregations.favorites.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Likes","total":0,"reach":"0%","variant":"rollup"}})]],2)])]),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"1.6rem"}},[_c('div',{staticClass:"col-6 p-0"},[_c('section',{staticClass:"mr-0 analytics-fb-audience h-chart-section-wrapper"},[_vm._m(4),(_vm.fetchingContentDistributionParsing)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
            _vm.content_distribution_daily &&
            !_vm.isEmptyBucket(_vm.content_distribution_daily.distribution)
          )?_c('ColumnChart',{attrs:{"categories":_vm.content_distribution_daily.time_line,"series":_vm.setSeriesForDensity(_vm.content_distribution_daily)}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(5)])],2)]),_c('div',{staticClass:"col-6 p-0 m-0 analytics-slide-summary"},[_c('section',{staticClass:"mr-0 analytics-fb-audience h-chart-section-wrapper"},[_vm._m(6),(_vm.fetchingBestTimeForContent)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
            _vm.best_time_for_content && !_vm.isLastEmptyBucket(_vm.best_time_for_content)
          )?_c('HeatmapChart',{attrs:{"series":_vm.setSeriesForHeatMap(_vm.best_time_for_content)}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(7)])],2)])]),_c('section',{staticClass:"analytics-fb-audience h-chart-section-wrapper",staticStyle:{"margin-top":"3.2rem"}},[_vm._m(8),(_vm.fetchingPublishPostsWithMentions)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
        _vm.publish_posts_with_mentions &&
        _vm.publish_posts_with_mentions.time_line &&
        !(
          _vm.isEmptyBucket(_vm.publish_posts_with_mentions.posts) &&
          _vm.isEmptyBucket(_vm.publish_posts_with_mentions.engagements)
        )
      )?_c('SplineBasicChart',{attrs:{"categories":_vm.publish_posts_with_mentions.time_line,"series":_vm.setSeriesForAwarenessThroughMentions(_vm.publish_posts_with_mentions)}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(9)])],2),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"1.6rem"}},[_c('div',{staticClass:"col-9 p-0"},[_c('section',{staticClass:"mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"},[_vm._m(10),(_vm.fetchingAudience)?[_c('div',{staticClass:"h-chart flex-center-center",staticStyle:{"height":"90%"}},[_c('SkeletonBox',{attrs:{"height":"250px","width":"300px","type":"chart","radius":"4px"}})],1)]:(
            _vm.audience &&
            !(
              _vm.isEmptyBucket(_vm.audience.followers) &&
              _vm.isEmptyBucket(_vm.audience.following)
            )
          )?_c('SplineBasicChart',{attrs:{"categories":_vm.audience.time_line,"series":_vm.setSeriesForAudienceGrowth(_vm.audience)}}):_c('div',{staticClass:"flex-center-center",staticStyle:{"height":"90%"}},[_vm._m(11)])],2)]),_c('div',{staticClass:"col-3 p-0 m-0 analytics-slide-summary"},[_c('section',{staticClass:"mt-0"},[_c('h2',[_vm._v("Audience Growth Rollup")]),(_vm.fetchingAggregations)?_vm._l((4),function(i){return _c('div',{key:i,staticStyle:{"margin-top":"1.3rem"}},[_c('SkeletonBox',{attrs:{"width":'100%',"height":"92px","radius":"10px"}})],1)}):[(
              _vm.aggregations &&
              typeof _vm.aggregations.total_followers_count != 'undefined' &&
              _vm.aggregations.total_followers_count.value != null
            )?_c('SummaryCard',{attrs:{"title":"Total Followers","total":_vm.aggregations.total_followers_count.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.total_followers_count.value,
                _vm.previous_aggregations.total_followers_count.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.total_followers_count.value,
                _vm.previous_aggregations.total_followers_count.value
              ),"variant":"rollup"}}):_c('SummaryCard',{attrs:{"title":"Total Followers","total":"0","reach":"0%","variant":"rollup"}}),(
              _vm.aggregations &&
              typeof _vm.aggregations.total_following != 'undefined' &&
              _vm.aggregations.total_following.value != null
            )?_c('SummaryCard',{attrs:{"title":"People that you are following","variant":"rollup","total":_vm.aggregations.total_following.value,"reach":_vm.getReachCurrentPrevious(
                _vm.aggregations.total_following.value,
                _vm.previous_aggregations.total_following.value
              ) + '%',"reachUp":_vm.getReachUpCurrentPrevious(
                _vm.aggregations.total_following.value,
                _vm.previous_aggregations.total_following.value
              )}}):_c('SummaryCard',{attrs:{"title":"People that you are following","total":"0","reach":"0%","variant":"rollup"}})]],2)])]),_c('section',[_c('h2',[_vm._v("Twitters Top Posts")]),_c('p',[_vm._v("Review your top posts published during the selected time period, based on the post’s lifetime performance.")]),_c('div',{staticClass:"row"},[(_vm.fetchingTopPosts)?[_vm._l((3),function(i){return [_c('div',{key:i,staticClass:"col-4"},[_c('div',{staticClass:"analytics-post-card"},[_c('div',{staticClass:"analytics-post-card__header"},[_c('div',{staticClass:"analytics-post-card__header-left"},[_c('div',{staticClass:"analytics-post-card__header-img"},[_c('SkeletonBox',{attrs:{"height":"2.8rem","width":"2.8rem","radius":"50%"}})],1),_c('div',{staticClass:"analytics-post-card__header-detail"},[_c('div',{staticClass:"d-flex",staticStyle:{"flex-direction":"column","margin-left":"10px"}},[_c('div',{staticClass:"mb-1"},[_c('SkeletonBox',{attrs:{"width":"100px","radius":".2rem"}})],1),_c('SkeletonBox',{attrs:{"radius":".2rem"}})],1)])]),_vm._m(12,true)]),_c('div',{staticClass:"analytics-post-card__content"},[_c('div',{staticClass:"mb-1"},[_c('SkeletonBox',{attrs:{"width":"100%","radius":".2rem"}})],1),_c('div',{staticClass:"mb-2"},[_c('SkeletonBox',{attrs:{"radius":".2rem"}})],1),_c('div',{staticClass:"mb-2"},[_c('SkeletonBox',{attrs:{"width":"100%","height":"150px","radius":".2rem"}})],1)]),_c('ul',{staticClass:"analytics-post-card__content-ele"},_vm._l((4),function(i){return _c('li',{key:i},[_c('SkeletonBox',{attrs:{"width":"40%","radius":".2rem"}}),_c('SkeletonBox',{attrs:{"width":"1.1rem","radius":".2rem"}})],1)}),0)])])]})]:(_vm.top3Posts && _vm.top3Posts.length)?_vm._l((_vm.top3Posts),function(post,index){return _c('div',{key:index,staticClass:"col-4"},[_c('PostCard',{attrs:{"platform_type":'twitter',"post":post}})],1)}):[_c('div',{staticClass:"d-flex justify_center analytics-post-card-row w-100"},[[_vm._m(13)]],2)]],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Published Posts With Engagement")]),_c('p',[_vm._v("See how your audience grew during the reporting period.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Publishing Behavior")]),_c('p',[_vm._v("View the different types of posts you published during the selected time period.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Post Density - Daily")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Engagement by day/time")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Awareness through Mentions")]),_c('p',[_vm._v("View the different types of posts you published during the selected time period.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-section-header"},[_c('h2',[_vm._v("Audience Growth")]),_c('p',[_vm._v("View how your audience has grown over the selected time period.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no-analytical-data-available.svg"),"alt":""}}),_c('p',[_vm._v(" No Data Found!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-post-card__header-right",staticStyle:{"filter":"grayscale(100%)","opacity":"0.6"}},[_c('i',{staticClass:"icon-twitter_icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-no-data-found"},[_c('img',{attrs:{"src":require("../../../assets/imgs/no_data_images/no_post_found.svg"),"alt":""}}),_c('p',[_vm._v("You do not have any posts published in the selected time period.")])])
}]

export { render, staticRenderFns }