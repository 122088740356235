<template>
  <div class="card_container">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Top Posts by Engagement</h2>
        </div>
        <div class="card_content">
          <div class="social_table top_post_table">
            <div class="table_wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 62%">
                      <span> Pin </span>
                    </th>

                    <th class="text-center"
                      >Engagements
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder(
                              '_source.total_engagement',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.total_engagement',
                              'asc',
                            )
                          "
                        >
                        </i>

                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder(
                              '_source.total_engagement',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.total_engagement',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th class="text-center"
                      >Repins

                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder('_source.repins_count'),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.repins_count',
                              'asc',
                            )
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder(
                              '_source.repins_count',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.repins_count',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th class="text-center"
                      >Comments
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder(
                              '_source.comments_count',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.comments_count',
                              'asc',
                            )
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder(
                              '_source.comments_count',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderTwitter(
                              '_source.comments_count',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-if="posts.length > 0">
                    <tr v-for="post in getOrderedPosts">
                      <td
                        @click.prevent="
                          EventBus.$emit(
                            'pinterest-analytics-preview',
                            post._source,
                          )
                        "
                      >
                        <PinterestPostView
                          :post="post._source"
                        ></PinterestPostView>
                      </td>
                      <td class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.total_engagement)
                        }}
                      </td>
                      <td class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.repins_count)
                        }}
                      </td>
                      <td class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.comments_count)
                        }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="4" class="make-text-center">
                      You do not have published any pins.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'
import analyticsUtilsMixin from '../../common/analyticsUtilsMixin'
import PinterestPostView from '../../common/posts/PinterestPostView'
import { EventBus } from "@common/lib/event-bus";

export default {
  components: { PinterestPostView },
  mixins: [analyticsUtilsMixin],
  props: {
    posts: {
      type: Array
    },
    report: {
      default: false
    }
  },

  data () {
    return {
      sortByRow: '_source.total_engagement',
      sortByOrder: 'desc',
      v_ascending: 'Ascending Order',
      v_descending: 'Descending Order',
      EventBus
    }
  },
  mounted () {},

  computed: {
    ...mapGetters(['getPinterestBoards']),
    getOrderedPosts () {
      const totalPosts = orderBy(this.posts, this.sortByRow, this.sortByOrder)
      if (this.report) {
        return totalPosts.slice(0, 8)
      }
      return totalPosts
    }
  },
  methods: {
    ...mapActions([]),
    changeSortOrderTwitter (key, order) {
      this.sortByOrder = order
      this.sortByRow = key
    },
    checkAscendingOrder (row) {
      return this.sortByRow === row && this.sortByOrder === 'asc'
    },
    checkDescendingOrder (row) {
      return this.sortByRow === row && this.sortByOrder === 'desc'
    },
    getActiveBoardImage (boardId) {
      if (this.getPinterestBoards.length > 0) {
        const boards = this.getPinterestBoards.filter((item) => {
          if (item.board_id === boardId) return true
        })
        if (boards.length > 0) {
          return boards[0].image
        }
      }
      return null
    }
  }
}
</script>

<style lang="less">
.statistics {
  font-weight: bold;
  font-size: 16px !important;
}
</style>
