import Highcharts from 'highcharts'
import Vue, { version } from 'vue'

import loadHighchartsMore from 'highcharts/highcharts-more'
import loadSolidGauge from 'highcharts/modules/solid-gauge'
import heatMap from 'highcharts/modules/heatmap'
import wordcloud from 'highcharts/modules/wordcloud'
import generateVueComponent from './generateVueComponent'

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})
heatMap(Highcharts)
wordcloud(Highcharts)
loadHighchartsMore(Highcharts)
loadSolidGauge(Highcharts)

const Chart = generateVueComponent(Highcharts, version || Vue.version)

export default Chart
// export default function install (Vue, options = {}) {
//     Vue.component(
//         options.tagName || 'highcharts',
//         generateVueComponent(options.highcharts || Highcharts, Vue.version)
//     )
// }
