<template slot="modal-header">
  <div
    class="w-full flex items-center justify-between pb-4 border-solid border-t-0 border-r-0 border-l-0 border-b-2 border-[#989eb50d]"
  >
    <!--   Saved Caption -->
    <template v-if="viewMode === 'list'">
      <h5 class="text-xl font-bold">Saved Captions</h5>
      <button
        v-tooltip="'Close'"
        class="close h-9 text-lg bg-[#f6f6f6] !font-normal"
        data-dismiss="modal"
        @click="handleModalClose"
      >
        &times;
      </button>
    </template>
    <!--   Add or Update Caption -->
    <template v-else-if="viewMode === 'create' || viewMode === 'edit'">
      <div class="flex items-center">
        <button
          v-tooltip="'Back'"
          class="p-0 ml-0 text-[#3a4557] !font-normal w-8 text-[1.563rem] rounded-xl bg-[#f6f6f6] h-9 border-0"
          data-dismiss="modal"
          @click="changeViewMode('list')"
        >
          <i class="far fa-angle-left"></i>
        </button>
        <h5 class="text-xl font-bold ml-2"
          >{{ viewMode === 'edit' ? 'Update' : 'New' }} Caption</h5
        >
      </div>
      <div
        v-if="viewMode === 'edit'"
        class="flex text-right flex ml-auto mr-[1.25rem] mt-2"
      >
        <div>
          <p class="text-sm"
            >Updated
            {{
              commonMethods.timesAgo(captionToUpdate?.value?.updated_time)
            }}</p
          >
          <p class="text-xs"
            >by
            <b>{{
              captionToUpdate?.value?.updated_by?.full_name || '---'
            }}</b></p
          >
        </div>
        <img
          :src="
            captionToUpdate?.value?.updated_by?.image ||
            'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
          "
          alt="profile image"
          class="rounded-full h-10 w-10 ml-2.5"
        />
      </div>
    </template>
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

// components

// constants
import { commonMethods } from '@src/modules/inbox/store/common-methods'

export default defineComponent({
  name: 'SaveCaptionHeader',
  computed: {
    commonMethods() {
      return commonMethods
    },
  },
  components: {},
  props: {
    handleModalClose: {
      type: Function,
      default: () => {},
    },
    viewMode: {
      type: String,
      default: 'list',
    },
    changeViewMode: {
      type: Function,
      default: () => {},
    },
    captionToUpdate: {
      type: Object,
      default: () => {},
    },
  },
})
</script>
