import { render, staticRenderFns } from "./FeaturesSlider.vue?vue&type=template&id=c91ff4f2&scoped=true&"
import script from "./FeaturesSlider.vue?vue&type=script&lang=js&"
export * from "./FeaturesSlider.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesSlider.vue?vue&type=style&index=0&id=c91ff4f2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c91ff4f2",
  null
  
)

export default component.exports