<template>
  <div class="topics-filters-pane__container__left_section">
    <div class="topics-filters-pane__container__left_section__tabs">
      <a
        v-tooltip.top-center="'Web'"
        @click.prevent="changeContentType('article')"
        :class="{
          disable_click: getTopicDisabled,
          active: getTopicContentType === 'article',
        }"
        href="javascript:;"
      >
        <i class="icon-Globe_icon"></i>
        <span>Web</span>
      </a>
      <a
        v-tooltip.top-center="'Youtube'"
        href="javascript:;"
        @click.prevent="changeContentType('youtube')"
        :class="{
          disable_click: getTopicDisabled,
          active: getTopicContentType === 'youtube',
        }"
        v-if="!getTrendingStories"
      >
        <i class="icon-Youtube_icon"></i>
        <span>YouTube</span>
      </a>
      <a
        v-tooltip.top-center="'Twitter'"
        href="javascript:;"
        @click.prevent="changeContentType('twitter')"
        v-if="!getTrendingStories"
        :class="{
          disable_click: getTopicDisabled,
          active: getTopicContentType === 'twitter',
        }"
      >
        <i class="icon-twitter_icon"></i>
        <span>Twitter</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'getTopicDisabled',
      'getTopicContentType',
      'getTopicType',
      'getTrendingStories'
    ])
  }
}
</script>
