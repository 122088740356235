<template>
  <div>
    <div
        class="header-profile-menu__navigation__dropdown__inner__profile_details__logout_section float-right mr-10"
    >
      <a href="javascript:;" data-cy="signout" @click.prevent="logout">
        <i class="cs-logout"></i>

        <span class="text align-middle">Sign Out</span>
      </a>
    </div>

    <div class="onboarding-welcome">
      <div class="col-md-5 mx-auto onboarding-welcome__wrapper">
        <h2 class="mb-10">Welcome to ContentStudio</h2>

        <div class="embed-responsive embed-responsive-16by9">
          <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/6mrp_SRynW0?showinfo=0"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
          ></iframe>
        </div>

        <!-- <router-link :to="{name: 'onboardingWorkspace'}" data-cy ="start" ,class="btn btn-studio-theme-transparent btn-size-large">Let's Start</router-link>-->

        <button
            class="btn btn-studio-theme-transparent btn-size-large mr-3"
            data-cy="get-strated"
            @click="updateUserPackage()"
        >Start Your Trial</button
        >
      </div>
    </div>
  </div>
</template>


<script>
import {startOnboardingStepsURL, updateUserPackageURL} from "@src/modules/account/config/api-utils";

export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
    const CapterraScriptImg = document.createElement('img')
    CapterraScriptImg.src =
        'https://ct.capterra.com/capterra_tracker.gif?vid=2122508&vkey=886ad10505cca9b51b6fbcd1cda8ac22'
    document.head.appendChild(CapterraScriptImg)
    // this.setOnboardingAccountsIntilize(false)
    // this.setOnboardingTeamIntilize(false)
    // if (this.getProfile.onBoarding && !this.getProfile.migration) {
    // this.$router.push({ name: 'onboardingWorkspace' })
    //
  },
  mounted() {
    // FB tracking code
    const StartTrialtrack = document.createElement('script')
    StartTrialtrack.setAttribute('type', 'text/javascript')
    StartTrialtrack.innerHTML = "fbq('track', 'StartTrial');"
    document.body.appendChild(StartTrialtrack)
  },
  methods: {
    // ...mapActions(['setOnboardingAccountsIntilize', 'setOnboardingTeamIntilize'])
    async updateUserPackage() {
      await this.postRequest(
          updateUserPackageURL,
          {},
          (response) => {
            if (response.data.status === true) {
              this.$router.push({ name: 'onboardingWorkspace' })
            }
          },
          (response) => {
            console.debug('Exception in logout', error)
          }
      )

      this.$router.push({ name: 'onboardingWorkspace' })
    },
    redirectForLogOut() {
      console.log('test')
    },
  },
}
</script>