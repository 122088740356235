import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getAudienceGrowthSeries (audience) {
      return [
        {
          name: 'Fans By Likes',
          data: audience.audience_growth.data.page_fans_by_like,
          color: '#ffe202'
        },
        {
          name: 'Fans By Unlikes',
          data: audience.audience_growth.data.page_fans_by_unlike,
          color: '#2fe095'
        },
        {
          name: 'Fans Count',
          data: audience.audience_growth.data.fan_count,
          color: '#e02f5f'
        }
      ]
    },
    getPublishingBehaviourSeries (publishing_behaviour) {
      return [
        {
          name: 'Published Texts',
          data: publishing_behaviour.publishing_behaviour.data.status,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Published Photos',
          data: publishing_behaviour.publishing_behaviour.data.photo,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Published Links',
          data: publishing_behaviour.publishing_behaviour.data.link,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Published Videos',
          data: publishing_behaviour.publishing_behaviour.data.video,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Total Engagements',
          data: publishing_behaviour.publishing_behaviour.data.engagement,
          color: '#000000',
          type: 'spline'
        }
      ]
    },
    getActiveUsersSeries (active_users, period) {
      if (period === 'days') {
        return [
          {
            name: 'Active Users By Days',
            data: active_users.active_users_days.values,
            color: '#2fe095'
          }
        ]
      }
      if (period === 'hours') {
        return [
          {
            name: 'Active Users By Hours',
            data: active_users.active_users_hours.values,
            color: '#6173ff'
          }
        ]
      }
    },
    getImpressionsSeries (impressions) {
      return [
        {
          name: 'Paid Post Impressions',
          data: impressions.impressions.data.post_impressions_paid,
          color: '#2fe095'
        },
        {
          name: 'Viral Post Impressions',
          data: impressions.impressions.data.post_impressions_viral,
          color: '#ffe202'
        },
        {
          name: 'Organic Post Impressions',
          data: impressions.impressions.data.post_impressions_organic,
          color: '#e02f5f'
        }
      ]
    },
    getEngagementSeries (engagement) {
      return [
        {
          name: 'Total Engagement',
          data: engagement.engagement.data.total_engagement,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Total Posts',
          data: engagement.engagement.data.posts_count,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Reactions',
          data: engagement.engagement.data.reactions,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Comments',
          data: engagement.engagement.data.comments,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Shares',
          data: engagement.engagement.data.shares,
          color: '#f99350',
          type: 'column'
        },
        {
          name: 'Post Clicks',
          data: engagement.engagement.data.post_clicks,
          color: '#000000',
          type: 'spline'
        }
      ]
    },
    getVideoColors (value) {
      switch (value) {
        case 'engagement':
          return ['#6173fc', '#2fe095', '#ffe202']
        case 'organic':
          return ['#6173fc', '#2fe095']
        case 'play':
          return ['#6173fc', '#2fe095']
      }
    },
    getVideoData (video_analytics, value) {
      switch (value) {
        case 'engagement':
          return [
            [
              'Total Video Play Count',
              video_analytics.current.total_video_play_count.value
            ],
            [
              'Total Video Views',
              video_analytics.current.total_video_views.value
            ],
            [
              'Total Video Engagements',
              video_analytics.current.total_video_engagement.value
            ]
          ]
        case 'organic':
          return [
            [
              'Paid Video Views',
              video_analytics.current.total_video_views_paid.value
            ],
            [
              'Organic Video Views',
              video_analytics.current.total_video_views_organic.value
            ]
          ]
        case 'play':
          return [
            [
              'Total Videos Clicked To Play',
              video_analytics.current.total_video_views_clicked_to_play.value
            ],
            [
              'Total Videos Autoplayed',
              video_analytics.current.total_video_views_autoplayed.value
            ]
          ]
      }
    }
  }
}
