<template>
  <div v-if="getPublishSelection.plan_id" class="activity_box">
    <div class="d-flex align-items-center clear">
      <h2 class="title">Activities ({{ getActivities.data.length }})</h2>
    </div>
    <div class="f_account_list">
      <clip-loader
        v-if="fetch_activities_loader"
        color="#4165ed"
        :size="'16px'"
      ></clip-loader>
      <div v-else-if="getActivities.data.length > 0" class="activity_list">
        <div
          v-for="activity in getActivities.data"
          class="activity_item"
          :key="activity._id"
        >
          <div class="activity_item_inner">
            <div
              class="profile_picture d-flex align-items-start"
              :class="getActivityTypeClass(activity)"
            >
              <div class="picture_block mt-1">
                <img
                  v-if="activity.user && activity.user.image"
                  :src="activity.user.image"
                />
                <img
                  v-else
                  src="../../../../../assets/img/profile_default.svg"
                />
                <div
                  style="padding-top: 0"
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <i :class="getActivityTypeClassIcon(activity)"></i>
                </div>
              </div>
              <div class="text_block w-100">
                <div class="text title-text" v-if="activity.user">
                  {{ activity.user.full_name }}
                  <span class="time">
                    - {{ $filters.relative(activity._source.created_at) }}</span
                  >
                </div>
                <p class="text" v-html="activityText(activity)"></p>

                <div class="comment-data" v-if="activity._source.text">
                  <div class="comment-data-inner">
                    <p
                      v-html="getMentionedUsersNames(activity._source.text)"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center p-3" v-else>
        <div class="content-image-state">
          <img
            :src="
              require('../../../../../assets/img/states/no-activities-created-yet-icon.svg')
            "
            alt=""
          />
        </div>
        No Activities found in this post</div
      >
    </div>
  </div>
</template>
<style lang="less" scoped>
.content-image-state {
  img {
    max-width: 140px;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import { pusherSocketPublish } from '@common/lib/pusher'
import { activityTypes } from '../../../store/states/mutation-types'

export default {
  data () {
    return {
      fetch_activities_loader: false,
      more_loader: 0,
      page: 1,
      channel: ''
    }
  },

  created () {},
  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `activities_${this.getActiveWorkspace._id}`
    )
    this.bindPusherPostActivities()
  },

  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getActivities',
      'getActiveWorkspace'
    ])
  },
  beforeDestroy () {
    if (this.channel) this.channel.unsubscribe()
    this.channel.unbind('store_activity')
  },
  methods: {
    bindPusherPostActivities () {
      this.channel.bind('store_activity', (data) => {
        console.log('Post activities store payload => ', data)
        if (
          data &&
          data.plan_id &&
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          const item = this.getActivities.data.filter(
            (item) => item._id === data.data._id
          )
          if (!item || item.length === 0) {
            this.getActivities.data.unshift(
              JSON.parse(JSON.stringify(data.data))
            )
            this.$store.commit(
              activityTypes.SET_TOTAL_ACTIVITIES,
              this.getActivities.totalActivities + 1
            )
          }
        }
      })
    },
    getActivityTypeClass (activity) {
      if (activity._source.type.includes('task')) {
        return 'task-type'
      } else if (activity._source.type.includes('comment')) {
        return 'comment-type'
      } else if (activity._source.type.includes('plan')) {
        return 'plan-type'
      } else if (activity._source.type.includes('label')) {
        return 'label-type'
      } else if (activity._source.type === 'pending_approval') {
        return 'plan-type-pending'
      } else if (activity._source.type === 'approve_approval') {
        return 'plan-type-approve'
      } else if (activity._source.type === 'reject_approval') {
        return 'plan-type-reject'
      }
      return ''
    },

    getActivityTypeClassIcon (activity) {
      if (activity._source.type.includes('task')) {
        return 'fas fa-clipboard-list'
      } else if (activity._source.type.includes('comment')) {
        return 'fas fa-comment'
      } else if (activity._source.type.includes('plan')) {
        return 'fas fa-calendar-check'
      } else if (activity._source.type.includes('label')) {
        return 'fas fa-tag'
      } else if (activity._source.type === 'pending_approval') {
        return 'fas fa-hourglass'
      } else if (activity._source.type === 'approve_approval') {
        return 'fas fa-check'
      } else if (activity._source.type === 'reject_approval') {
        return 'fas fa-ban'
      }
      return ''
    },

    activityText (activity) {
      const userVariable =
        activity._source.mentioned_users &&
        activity._source.mentioned_users.length > 1
          ? 'users'
          : 'user'
      switch (activity._source.type) {
        case 'plan_created':
          return ' created this post.'
        case 'task_created':
          return ' created a new task.'
        case 'task_updated':
          return ' updated task.'
        case 'task_check':
          return ' marked task as completed.'
        case 'task_uncheck':
          return ' marked task as uncomplete.'
        case 'task_deleted':
          return ' deleted a task.'
        case 'all_task_deleted':
          return ' deleted all tasks.'
        case 'task_mention':
          return `mentioned ${userVariable} in the task.`

        case 'comment_created':
          return ' added a new comment.'
        case 'comment_updated':
          return ' updated comment.'
        case 'comment_deleted':
          return ' deleted a comment.'
        case 'comment_mention':
          return `mentioned ${userVariable} in the comment.`

        case 'plan_posting':
          return activity._source.activity

        case 'label_add':
          return '  assigned label (' + activity._source.name + ').'
        case 'label_remove':
          return ' removed label (' + activity._source.name + ').'

        case 'pending_approval':
          return 'Sent the content to approval'
        case 'approve_approval':
          return 'Approved the content'
        case 'reject_approval':
          return 'Rejects the content'
      }
      return ''
    }
  }
}
</script>

<style scoped></style>
