export const filterLimitedLanguages = [
  {
    code: 'world',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'fr',
        languageName: 'Français',
      },
      {
        code: 'nl',
        languageName: 'Netherlands',
      },
      {
        code: 'de',
        languageName: 'Deutsch',
      },
      {
        code: 'es',
        languageName: 'Español',
      },
      {
        code: 'tr',
        languageName: 'Turkish',
      },
      {
        code: 'pl',
        languageName: 'Polski',
      },
      {
        code: 'it',
        languageName: 'Italiano',
      },
      {
        code: 'pt',
        languageName: 'Português',
      },
      {
        code: 'sv',
        languageName: 'Swedish',
      },
      {
        code: 'sq',
        languageName: 'Albanian',
      },
      {
        code: 'da',
        languageName: 'Danish',
      },
      {
        code: 'sr',
        languageName: 'Serbian',
      },
      {
        code: 'hu',
        languageName: 'Hungarian',
      },
      {
        code: 'ru',
        languageName: 'Russian',
      },
      {
        code: 'id',
        languageName: 'Indonesian',
      },
      {
        code: 'th',
        languageName: 'Thai',
      },
      {
        code: 'hi',
        languageName: 'Hindi',
      },
      {
        code: 'he',
        languageName: 'Hebrew',
      },

      {
        code: 'ar',
        languageName: 'Arabic',
      },

      {
        code: 'sl',
        languageName: 'Slovenian',
      },

      {
        code: 'ms',
        languageName: 'Malay',
      },
      {
        code: 'vi',
        languageName: 'Vietnamese',
      },
      {
        code: 'zh',
        languageName: 'Chinese',
      },
    ],
  },
  {
    code: 'au',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'be',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'nl',
        languageName: 'Dutch',
      },
    ],
  },
  {
    code: 'ca',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'us',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'gb',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'fr',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'fr',
        languageName: 'Français',
      },
    ],
  },
  {
    code: 'de',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'de',
        languageName: 'Deutsch',
      },
    ],
  },
  {
    code: 'in',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'hi',
        languageName: 'Hindi',
      },
    ],
  },
  {
    code: 'it',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'it',
        languageName: 'Italiano',
      },
    ],
  },
  {
    code: 'es',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'es',
        languageName: 'Español',
      },
    ],
  },
  {
    code: 'ch',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'de',
        languageName: 'Deutsch',
      },
    ],
  },
  {
    code: 'pl',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'pl',
        languageName: 'Polish',
      },
    ],
  },
  {
    code: 'pk',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ur',
        languageName: 'Urdu',
      },
    ],
  },
  {
    code: 'ua',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'uk',
        languageName: 'Ukranian',
      },
    ],
  },
  {
    code: 'br',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'pt',
        languageName: 'Português',
      },
    ],
  },
  {
    code: 'at',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'de',
        languageName: 'German',
      },
    ],
  },
  {
    code: 'nz',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'ru',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ru',
        languageName: 'Russian',
      },
    ],
  },
  {
    code: 'ie',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ie',
        languageName: 'Interlingue',
      },
    ],
  },
  {
    code: 'no',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'nb',
        languageName: 'Norwegian',
      },
    ],
  },
  {
    code: 'hu',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'hu',
        languageName: 'Hungarian',
      },
    ],
  },
  {
    code: 'ro',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ro',
        languageName: 'Romanian',
      },
    ],
  },
  {
    code: 'dk',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'da',
        languageName: 'Danish',
      },
    ],
  },
  {
    code: 'fi',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'fi',
        languageName: 'Finnish',
      },
    ],
  },
  {
    code: 'sk',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sk',
        languageName: 'Slovak',
      },
    ],
  },
  {
    code: 'cz',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'cs',
        languageName: 'Czech',
      },
    ],
  },
  {
    code: 'gr',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'el',
        languageName: 'Greek',
      },
    ],
  },
  {
    code: 'ba',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'bs',
        languageName: 'Bosnian',
      },
    ],
  },
  {
    code: 'rs',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sr',
        languageName: 'Serbian',
      },
    ],
  },
  {
    code: 'ke',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sw',
        languageName: 'Swahili',
      },
    ],
  },
  {
    code: 'eg',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ar',
        languageName: 'Arabic',
      },
    ],
  },
  {
    code: 'et',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'am',
        languageName: 'Amharic',
      },
    ],
  },
  {
    code: 'pt',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'pt',
        languageName: 'portuguese',
      },
    ],
  },
  {
    code: 'tr',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'tr',
        languageName: 'Turkish',
      },
    ],
  },
  {
    code: 'mx',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'es',
        languageName: 'Español',
      },
    ],
  },
  {
    code: 'se',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sv',
        languageName: 'Swedish',
      },
    ],
  },
  {
    code: 'nl',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'nl',
        languageName: 'Dutch',
      },
    ],
  },
  {
    code: 'hr',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'hr',
        languageName: 'Croatian',
      },
    ],
  },
  {
    code: 'mk',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'mk',
        languageName: 'Macedonian',
      },
    ],
  },
  {
    code: 'do',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'es',
        languageName: 'Español',
      },
    ],
  },
  {
    code: 'al',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sq',
        languageName: 'Albanian',
      },
    ],
  },
  {
    code: 'id',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'id',
        languageName: 'Indonesian',
      },
    ],
  },
  {
    code: 'ph',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'fl',
        languageName: 'Filipino',
      },
    ],
  },
  {
    code: 'il',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'he',
        languageName: 'Hebrew',
      },
    ],
  },
  {
    code: 'si',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'sl',
        languageName: 'Slovenian',
      },
    ],
  },

  {
    code: 'my',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'ms',
        languageName: 'Malay',
      },
    ],
  },

  {
    code: 'sg',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'za',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'zu',
        languageName: 'Zulu',
      },
    ],
  },
  {
    code: 'mw',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'gh',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'ph',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },
  {
    code: 'ng',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
    ],
  },

  {
    code: 'vn',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'vi',
        languageName: 'Vietnamese',
      },
    ],
  },

  {
    code: 'cn',
    languageList: [
      {
        code: 'all',
        languageName: 'All Languages',
      },
      {
        code: 'en',
        languageName: 'English',
      },
      {
        code: 'zh',
        languageName: 'Chinese',
      },
    ],
  },

  // {
  //     code: 'jp',
  //     languageList: [{
  //         code: 'all',
  //         languageName: 'All Languages'
  //     }, {
  //         code: 'en',
  //         languageName: 'English'
  //     },
  //         {
  //             code: 'ja',
  //             languageName: 'Japanese'
  //         },
  //     ]
  // },
]
