import { mapGetters } from 'vuex'

export const labelsMixin = {
  computed: {
    ...mapGetters(['getLabels']),
  },
  methods: {
    fetchColor(id, clr) {
      const colors = {
        color_1: 'label_green',
        color_2: 'label_blue',
        color_3: 'label_red',
        color_4: 'label_light_red',
        color_5: 'label_red2',
        color_6: 'label_purple',
        color_7: 'label_orange',
        color_8: 'label_pink',
        color_9: 'label_dark_blue',
        color_10: 'label_dark_pink',
        color_11: 'color_11',
        color_12: 'color_12',
        color_13: 'color_13',
        color_14: 'color_14',
        color_15: 'color_15',
        color_16: 'color_16',
        color_17: 'color_17',
        color_18: 'color_18',
        color_19: 'color_19',
        color_20: 'color_20',
      }
      if (id) {
        for (const key in colors) {
          if (colors.hasOwnProperty(key)) {
            if (key === id) {
              return colors[key]
            }
          }
        }
      }
      if (clr) {
        for (const key in colors) {
          if (colors.hasOwnProperty(key)) {
            if (colors[key] === clr) {
              return key
            }
          }
        }
      } else return colors
    },
    getLabelById(id) {
      const foundIndex = this.getLabels.findIndex((x) => x._id === id)
      return this.getLabels[foundIndex]
    },
    getLabelColorById(id) {
      const label = this.getLabelById(id)
      if (label && label._id) {
        return this.fetchColor(label.color)
      }
    },
    bindPusherLabels(channel, type = 'planner') {
      channel.bind('store_label', (data) => {
        console.log('pusher labels planner', data)

        // iterating over the plan items
        let plan = null
        if (type === 'planner')
          plan = this.getPlans.items.find((item) => item._id === data.plan_id)
        else
          plan = this.getPublications.publications.find(
            (item) => item._id === data.plan_id
          )
        console.log('pusher comment feed view -> plan', plan)
        if (plan)
          if (data.labels) plan.labels = JSON.parse(JSON.stringify(data.labels))
      })
    },
    getLabelsIds() {
      const labels = []
      this.getLabels.forEach((label, index) => {
        labels.push(label._id)
      })
      return labels
    },
  },
}
