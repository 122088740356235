<template>
  <ul
    class="topics-left-pane-items"
    :class="{ disable_click: getTopicDisabled }"
    v-if="
      getCustomSidebar.quick_topics.status ||
      getSidebarCustomization.customization
    "
  >
    <li
      class="topics-left-pane-items__heading"
      v-bind:class="[sibarItemStatus('quickTopics') ? '' : 'opacity']"
    >
      <i class="icon-reports_icon"></i>
      <span class="topics-left-pane-items__heading__text">Curated Topics</span>
      <button
        v-if="
          !getSidebarCustomization.customization &&
          hasPermission('can_save_topic')
        "
        @click.prevent="followCuratedTopicsModal()"
        class="topics-left-pane-items__heading__button"
        v-tooltip.top-center="'Add Curated Topic'"
        data-cy="add-topic"
      >
        <i class="far fa-plus d-block"></i>
      </button>

      <div
        class="topics-left-pane-items__heading__customization"
        v-if="getSidebarCustomization.customization"
      >
        <i
          class="view_icon"
          v-bind:class="[
            sibarItemStatus('quickTopics') ? 'cs-eye ' : 'cs-eye-close ',
          ]"
          @click.prevent="selectionSibarItem('quickTopics')"
        >
        </i>
      </div>
    </li>

    <template v-if="getMyCuratedTopicsList.length > 0">
      <transition-group name="topics-list" tag="div">
        <li
          :key="topic._id"
          class="topics-left-pane-items__list"
          v-for="(topic, index) in getMyCuratedTopicsList"
          v-bind:class="[
            sibarItemStatus('quickTopics', topic.name) ? '' : 'opacity',
            getSidebarCustomization.customization
              ? 'topics-left-pane-items__list__customization'
              : '',
            $route.params.topic_id === topic._id ? 'active' : '',
          ]"
          v-if="
            getSidebarCustomization.customization ||
            !getCustomSidebar.quick_topics.options.includes(topic.name)
          "
        >
          <router-link
            :to="{
              name: 'discover_topics_curated_web_content',
              params: { topic_id: topic._id },
            }"
            :class="{ active: $route.params.topic_id === topic._id }"
            >{{ topic.name }}</router-link
          >
          <div class="topics-left-pane-items__list__action_buttons">
            <i
              style="right: 0"
              class="view_icon"
              v-tooltip.top-center="'Show / Hide'"
              v-if="getSidebarCustomization.customization"
              @click.prevent="selectionSibarItem('quickTopics', topic.name)"
              v-bind:class="[
                sibarItemStatus('quickTopics', topic.name)
                  ? 'cs-eye '
                  : 'cs-eye-close  ',
              ]"
            >
            </i>

            <!--<i v-tooltip.top-center="'Remove'"-->
            <!--v-else-if="hasPermission('can_save_topic')"-->
            <!--class="topics-left-pane-items__list__action_buttons__remove icon-delete-cs"-->
            <!--@click.prevent="unfollowCuratedTopic({topic:topic, index: index})">-->
            <!--</i>-->

            <i
              :id="topic._id"
              v-tooltip.top-center="'Remove'"
              v-else-if="hasPermission('can_save_topic')"
              class="topics-left-pane-items__list__action_buttons__remove icon-delete-cs"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>

            <div
              :aria-labelledby="topic._id"
              class="dropdown-menu dropdown-confirmation__menu"
            >
              <div class="dropdown-confirmation__menu__header">
                Confirmation
              </div>
              <div class="dropdown-confirmation__menu__actions">
                Are you sure?
                <span
                  class="dropdown-confirmation__menu__actions__delete"
                  @click.prevent="
                    unfollowCuratedTopic({ topic: topic, index: index })
                  "
                  >Yes</span
                >
                <span class="dropdown-confirmation__menu__actions__cancel"
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--<div class="line_animate" :class="{'active animate': getActiveTopicTab === topic._id}"></div>-->
        </li>
      </transition-group>
      <!--:class="{selected_disable: !sibarItemStatus('quickTopics',topic.name),disable_box : getSidebarCustomization}"-->
    </template>

    <template v-else>
      <p class="topics-left-pane-items__no_results"
        >You are not following any curated topic.</p
      >
    </template>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  created () {
    /* if(this.getMyCuratedTopicsList.length > 0)
      {
          this.getContentByTopic()
      } */
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions([
      'setInsightsLoader',
      'setVideoPage',
      'setVideoPageToken',
      'setDiscoveryVideoLoader',
      'setPosts',
      'setTrendingStatus',
      'unfollowCuratedTopic'
    ])
  },
  computed: {
    ...mapGetters([
      'getMyCuratedTopicsList',
      'getActiveTopicTab',
      'getTopicModule',
      'getTopicDisabled',
      'getCustomSidebar',
      'getSidebarCustomization',
      'getTopicContentType',
      'getActiveChannelRoute'
    ])
  }
}
</script>
