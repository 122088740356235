<template>
  <div class="h-100 d-flex flex-column">
    <InboxConversationHead
      :active-inbox-detail="state.activeInboxData"
    ></InboxConversationHead>
    <div class="conversation_messages">
      <div v-if="state.activeInboxData" class="message m_in _comment_post">
        <div class="profile_picture d-flex align-items-start">
          <div class="text_block w-100">
            <div class="__text">
              <!--// for activeInboxDetail section...-->
              <div class="_comment">
                <div class="w-full cst-drop-shadow bg-white rounded-xl py-2">
                  <LinkedinPostContent
                    :inbox-data="state.activeInboxData"
                    @preview-image="handlePreview"
                  ></LinkedinPostContent>

                  <LinkedinPostReply
                    :inbox-data="state.activeInboxData"
                    :comments="state.allComments ? state.allComments : []"
                    :total-comments="
                      state.totalComments ? state.totalComments : 0
                    "
                    :temp-count="state.tempCount ? state.tempCount : 0"
                    :loading-state="state.isLoading"
                    @preview-image="handlePreview"
                    @comment-count="handleCommentCount"
                  ></LinkedinPostReply>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueEasyLightbox
      :imgs="state.imageLink"
      :visible="state.visible"
      @hide="handleHide"
    ></VueEasyLightbox>
  </div>
</template>

<script>
import InboxConversationHead from '@src/modules/inbox/components/InboxConversationHead'
import LinkedinPostContent from '@src/modules/inbox/components/reusable/Linkedin-post/LinkedinPostContent'
import LinkedinPostReply from '@src/modules/inbox/components/reusable/Linkedin-post/LinkedinPostReply'
import VueEasyLightbox from 'vue-easy-lightbox'
import { computed, reactive, watch, onMounted, nextTick } from 'vue'
import { useStore } from '@state/base'

export default {
  name: 'LinkedinPost',
  components: {
    InboxConversationHead,
    LinkedinPostContent,
    LinkedinPostReply,
    VueEasyLightbox,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { dispatch } = useStore()
    const state = reactive({
      activeInboxData: computed(() => props.activeInboxDetail),
      imageLink: '',
      visible: false,
      isLoading: false,
      allComments: null,
      commentsResponse: null,
      totalComments: 0,
      tempCount: 0,
    })

    const payload = reactive({
      post_id: computed(
        () => props.activeInboxDetail.element_details.element_id
      ),
      workspace_id: state.activeInboxData.workspace_id,
      platform: state.activeInboxData.platform,
      sort_order: '',
      page: 1,
      limit: 7,
    })

    onMounted(() => {
      fetchPostComments(payload)
    })

    watch(
      () => state.activeInboxData.element_details.element_id,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          state.imageLink = ''
          state.visible = false
          state.isLoading = false
          state.allComments = null
          state.commentsResponse = null
          state.totalComments = 0
          state.tempCount = 0
          fetchPostComments(payload)
        } else {
          console.log('LinkedIn:: WATCHER: No')
        }
      }
    )

    const fetchPostComments = async (payload) => {
      state.isLoading = true
      try {
        state.commentsResponse = await dispatch('fetchPostComments', payload)
        state.allComments = state.commentsResponse.comments
        state.totalComments = state.commentsResponse.total
        state.isLoading = false
        if (!state.commentsResponse.status) {
          if (
            state.commentsResponse.message ===
            'No alive nodes found in your cluster'
          ) {
            dispatch('toastNotification', {
              message: "Couldn't fetch comments.",
              type: 'error',
            })
          }
        }
        await nextTick()
      } catch (exception) {
        state.isLoading = false
        dispatch('toastNotification', {
          message: "Couldn't fetch comments.",
          type: 'error',
        })
      }
    }

    /**
     * @description data emitted from LinkedinReplyBox(grandchild), to preview the attached image through VueEasyLightBox
     * @param data
     */
    const handlePreview = (data) => {
      state.imageLink = data.link
      state.visible = data.visible
    }

    /**
     * @description hide the previewed image.
     */
    const handleHide = () => {
      state.visible = false
    }

    const handleCommentCount = (type) => {
      if (type === 'add') {
        state.tempCount = state.tempCount + 1
      } else {
        state.tempCount = state.tempCount - 1
      }
    }

    return {
      state,
      handlePreview,
      handleHide,
      handleCommentCount,
    }
  },
}
</script>

<style scoped></style>
