<template>
  <div class="w-full flex items-center justify-center">
    <CstDropdown
      class="my-2"
      size="small"
      dropdown-placement="top"
      button-classes="flex justify-between bg-cs-light-gray"
    >
      <template v-slot:selected>
        {{ hour | formatNumber }}
      </template>
      <template v-slot:arrow>
        <i class="far fa-angle-down"></i>
      </template>
      <CstDropdownItem
        v-for="h in hours"
        :key="h"
        :disabled="checkTimeDisable(h)"
        :value="h"
        @click="handleSelect(h, 'hours', checkTimeDisable(h))"
        >{{ h | formatNumber }}
      </CstDropdownItem>
    </CstDropdown>

    <span class="mx-1.5 font-bold">:</span>
    <CstDropdown
      class="my-2"
      size="small"
      dropdown-placement="top"
      button-classes="flex justify-between bg-cs-light-gray"
    >
      <template v-slot:selected>
        {{ minute | formatNumber }}
      </template>
      <template v-slot:arrow>
        <i class="far fa-angle-down"></i>
      </template>
      <CstDropdownItem
        v-for="m in minutes"
        :key="m"
        :disabled="checkTimeMintDisable(m)"
        :value="m"
        @click="handleSelect(m, 'minutes', checkTimeMintDisable(m))"
        >{{ m | formatNumber }}
      </CstDropdownItem>
    </CstDropdown>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import moment from 'moment-timezone'

export default {
  name: 'SelectTime',
  filters: {
    formatNumber: (value) => {
      if (value < 10) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
  components: {
    CstDropdown,
    CstDropdownItem,
  },
  props: {
    selectedDate: {
      type: Date,
      default: new Date(),
    },
    emit: {
      type: Function,
      default: () => {},
    },
    hour24: {
      type: Boolean,
      default: true,
    },
    miniuteIncrement: {
      type: Number,
      default: 5,
    },
    timezone: {
      type: String,
      default() {
        return 'UTC'
      },
    },
  },
  data() {
    const currentTime = moment(this.selectedDate)
    const hours = currentTime.hours()
    return {
      hour: this.hour24 ? hours : hours % 12 || 12,
      minute:
        currentTime.minutes() - (currentTime.minutes() % this.miniuteIncrement),
      second: currentTime.seconds(),
      ampm: hours < 12 ? 'AM' : 'PM',
    }
  },
  computed: {
    hours() {
      const values = []
      const max = this.hour24 ? 24 : 12
      for (let i = 0; i < max; i++) {
        values.push(this.hour24 ? i : i + 1)
      }
      return values
    },
    minutes() {
      const values = []
      const max = 60
      for (let i = 0; i < max; i = i + this.miniuteIncrement) {
        values.push(i)
      }
      return values
    },
  },

  watch: {
    selectedDate: function (val) {
      const currentTime = moment(val)
      const hours = currentTime.hours()

      let hour = this.hour24 ? hours : hours % 12 || 12
      let minute =
        currentTime.minutes() - (currentTime.minutes() % this.miniuteIncrement)

      while (this.checkTimeDisable(hour)) {
        hour++
      }

      while (this.checkTimeMintDisable(minute)) {
        minute += this.miniuteIncrement
      }

      this.hour = hour
      this.minute = minute
    },
  },
  methods: {
    checkTimeDisable(hour) {
      const todaysDate = moment.tz(this.timezone)
      const selectedDate = moment(this.selectedDate)

      /* const hr = todaysDate.hour()
      const mn = todaysDate.minutes()


      todaysDate = moment.utc(todaysDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }).tz(this.timezone)
      selectedDate = moment.utc(selectedDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }) */

      console.log(
        'checkTimeDisable',
        todaysDate.toString(),
        selectedDate.toString()
      )

      if (
        todaysDate.date() === selectedDate.date() &&
        todaysDate.month() === selectedDate.month() &&
        todaysDate.year() === selectedDate.year()
      ) {
        if (todaysDate.hour() > hour) {
          return true
        }
      }
      return false
    },
    checkTimeMintDisable(mint) {
      const todaysDate = moment.tz(this.timezone)
      const selectedDate = moment(this.selectedDate)
      if (
        todaysDate.date() === selectedDate.date() &&
        todaysDate.month() === selectedDate.month() &&
        todaysDate.year() === selectedDate.year()
      ) {
        if (todaysDate.minutes() + 5 > mint && this.hour <= todaysDate.hour()) {
          return true
        }
      }

      return false
    },
    handleSelect(value, type, disabled) {
      if (!disabled) {
        if (type === 'hours') {
          this.hour = value
        } else {
          this.minute = value
        }

        this.emit(
          new Date(
            this.selectedDate.setHours(
              this.hour,
              this.minute,
              this.selectedDate.getSeconds()
            )
          )
        )
      }
    },
  },
}
</script>
