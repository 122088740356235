<template>
  <div class="no-influencer-twitter">
    <p
      >In order to follow users and perform other Twitter actions you’ll need to
      connect your account first.</p
    >
    <router-link :to="{ name: 'social' }" class="btn large_btn gradient_btn">
      <span>Connect an account</span>
    </router-link>
  </div>
</template>
<script>
export default {
  components: {}
}
</script>
