<template>
  <chart-render
    :id="'content_distribution_highlight'"
    :options="distributionContent()"
    :title="'Post Density - Daily'"
    :ref_value="'content_distribution'"
    :is_content_available="isContentNotAvailable"
    :tooltip_message="tooltip_message"
  ></chart-render>
</template>

<script>
import {
  columnXAxis,
  splineChart,
  splineLegend,
  splineTooltip,
  splineYAxis
} from '../helper'
import { FACEBOOK_ANALYTICS_POST_DENSITY } from '@common/constants/messages'
import ChartRender from '../ChartRender'

export default {
  components: {
    ChartRender
  },
  props: {
    distribution: {}
  },
  data () {
    return {
      tooltip_message: FACEBOOK_ANALYTICS_POST_DENSITY
    }
  },
  computed: {
    totalDocs () {
      let count = 0
      Object.entries(this.distribution).forEach((item) => {
        count += item[1]
      })
      return count
    },
    isContentNotAvailable () {
      return this.totalDocs === 0
    }
  },
  methods: {
    distributionContent () {
      const days = []
      const weekdays = []
      Object.entries(this.distribution).forEach((element) => {
        console.log(element)
        days.push(element[1])
        weekdays.push(element[0])
      })

      /*  if (this.isContentNotAvailable) {
                                                retweets.forEach(item => {
                                                  item[1] = this.randomNumber()
                                                })
                                                favorites.forEach(item => {
                                                  item[1] = this.randomNumber()
                                                })
                                              } */

      // let docCount = element.doc_count || 0
      // let averageStats = element.avg_stats.value || 0
      // articlesPublishedArrayPostsCount.push([parsedDate, docCount])
      const tooltip = splineTooltip()
      tooltip.pointFormat =
        '<p class="engagement_point"><span>{point.y:f}</span></p>'

      return {
        credits: splineLegend(),
        tooltip: tooltip,
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: splineChart('#content_distribution_highlight').events
        },
        legend: splineLegend(),
        colors: ['#536eff'],
        title: false,
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          labels: {
            enabled: true
          },
          categories: weekdays
        },
        yAxis: splineYAxis(),
        plotOptions: {
          column: {
            stacking: 'normal'
          },

          series: {
            pointWidth: 25,
            borderWidth: 0,
            lineWidth: 3,
            lineColor: '#3a4557'
          }
        },
        series: [
          {
            name: 'Posts',
            data: days,
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#6c8aff'],
                [1, '#96aaff']
              ]
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
