var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topic_inner"},[_vm._m(0),(_vm.getPreviewSuggestionLoader)?[_c('div',{staticClass:"pt-3 pb-3"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]:_vm._e(),_c('div',{staticClass:"topic_listed_items"},[(_vm.getPreviewSuggestion.posts.length > 0)?_vm._l((_vm.getPreviewSuggestion.posts),function(video,index){return _c('div',{key:index,staticClass:"topic_content_box video_content_box"},[(_vm.getVideoAutomationDetails.video.source === 'Youtube')?[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"image_block"},[(video.snippet && video.snippet.thumbnails.maxres)?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
                    _vm.videoImageURL(video.snippet.thumbnails.maxres, 'youtube')
                  ),expression:"\n                    videoImageURL(video.snippet.thumbnails.maxres, 'youtube')\n                  ",arg:"background-image"}],staticClass:"img"},[_c('i',{staticClass:"cs-play",on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
                        _vm.getVideoTitle(video),
                        _vm.getVideoDescription(video),
                        _vm.videoEmbedUrl(video.id, 'youtube'),
                        'Youtube'
                      )}}})]):(video.snippet && video.snippet.thumbnails.high)?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
                    _vm.videoImageURL(video.snippet.thumbnails.high, 'youtube')
                  ),expression:"\n                    videoImageURL(video.snippet.thumbnails.high, 'youtube')\n                  ",arg:"background-image"}],staticClass:"img"},[_c('i',{staticClass:"cs-play",on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
                        _vm.getVideoTitle(video),
                        _vm.getVideoDescription(video),
                        _vm.videoEmbedUrl(video.id, 'youtube'),
                        'Youtube'
                      )}}})]):(
                    video.snippet && video.snippet.thumbnails.default
                  )?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
                    _vm.videoImageURL(video.snippet.thumbnails.default, 'youtube')
                  ),expression:"\n                    videoImageURL(video.snippet.thumbnails.default, 'youtube')\n                  ",arg:"background-image"}],staticClass:"img"},[_c('i',{staticClass:"cs-play",on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
                        _vm.getVideoTitle(video),
                        _vm.getVideoDescription(video),
                        _vm.videoEmbedUrl(video.id, 'youtube'),
                        'Youtube'
                      )}}})]):_vm._e()]),_c('div',{staticClass:"content_block"},[_c('div',{staticClass:"content_block_inner"},[_c('h3',{on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
                        _vm.getVideoTitle(video),
                        _vm.getVideoDescription(video),
                        _vm.videoEmbedUrl(video.id, 'youtube'),
                        'Youtube'
                      )}}},[_vm._v(" "+_vm._s(_vm.limitTextLength(_vm.getVideoTitle(video), 62)))]),_c('div',{staticClass:"detail_box d-flex align-items-center"},[(video.snippet && video.snippet.publishedAt)?_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.videoDate('youtube', video.snippet.publishedAt)))]):_vm._e()])])])]),_c('div',{staticClass:"footer clear"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('View'),expression:"'View'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"cs-eye"}),(video.statistics)?_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(video.statistics.viewCount)))]):_vm._e()]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Like'),expression:"'Like'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"cs-thumbs-up"}),(video.statistics)?_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(video.statistics.likeCount)))]):_vm._e()]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Comment'),expression:"'Comment'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"cs-comment"}),(video.statistics)?_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(video.statistics.commentCount)))]):_vm._e()])])])]:(_vm.getVideoAutomationDetails.video.source === 'Dailymotion')?[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"image_block"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
                    _vm.videoImageURL(video, 'dailymotion')
                  ),expression:"\n                    videoImageURL(video, 'dailymotion')\n                  ",arg:"background-image"}],staticClass:"img"},[_c('i',{staticClass:"cs-play",on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
                        video.title,
                        '',
                        _vm.videoEmbedUrl(video, 'dailymotion'),
                        'Dailymotion'
                      )}}})])]),_c('div',{staticClass:"content_block"},[_c('div',{staticClass:"content_block_inner"},[_c('h3',[_vm._v(_vm._s(_vm.limitTextLength(video.title, 42)))]),_c('div',{staticClass:"detail_box d-flex align-items-center"},[_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.videoDate('dailymotion', video)))])])])])]),_c('div',{staticClass:"footer clear"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('View'),expression:"'View'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"cs-eye"}),_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(video.views_total)))])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Like'),expression:"'Like'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"cs-thumbs-up"}),_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(video.likes_total)))])])])])]:_vm._e()],2)}):[(!_vm.getPreviewSuggestionLoader)?_c('div',{staticClass:"no_data_found_content"},[_c('no-results-found')],1):_vm._e()],(
        _vm.getPreviewSuggestion.posts &&
        _vm.getPreviewSuggestion.posts.length >= 20 &&
        _vm.getPreviewSuggestionInfiniteLoader == false &&
        _vm.getPreviewSuggestionNoMoreResult == false
      )?[_c('span',{staticClass:"pt-5 d-block"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]:_vm._e(),(
        _vm.getPreviewSuggestionNoMoreResult &&
        _vm.getPreviewSuggestion.posts &&
        _vm.getPreviewSuggestion.posts.length > 20
      )?[_c('no-results-found',{attrs:{"more":true,"message":'No more results found. Please try adding more keywords or domains'}})]:_vm._e(),(
        _vm.getPreviewSuggestionInfiniteLoader &&
        _vm.getPreviewSuggestionNoMoreResult == false
      )?_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.onInfinite}},[_c('span',{staticClass:"pt-5 d-block",attrs:{"slot":"spinner"},slot:"spinner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1),_c('span',{staticClass:"not_found_text",attrs:{"slot":"no-more"},slot:"no-more"},[_c('no-results-found',{attrs:{"more":true}})],1)]):_vm._e()],2)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('h3',[_vm._v("Preview")]),_c('p',[_vm._v(" This is the type of content you will get through this automation campaign. ")])])
}]

export { render, staticRenderFns }