<template>
  <b-modal
    :id="modalId"
    centered
    modal-class="upgrade-limits-modal"
    header-class="cs-modal-header"
    title-class="cs-modal-title"
    no-close-on-backdrop
    size="xl"
    hide-footer
    @hide="hideModal"
  >
    <template slot="modal-title">
      <span v-if="addOnsData.type">Subscriptions Limits</span>
      <span v-else>Upgrade Limits</span>
    </template>
    <!-- view limits -->
    <div v-if="addOnsData.type">
      <AddOnsLimitsComponent :add-ons-data="addOnsData.data" />
    </div>
    <!-- increase limits -->
    <div v-else>
      <IncreaseLimitsComponent />
    </div>
  </b-modal>
</template>

<style lang="less"></style>

<script>
import IncreaseLimitsComponent from '@src/modules/setting/components/billing/dialogs/IncreaseLimitsComponent'
import AddOnsLimitsComponent from '@src/modules/setting/components/billing/dialogs/AddOnsLimitsComponent'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'IncreaseLimitsDialog',
  components: {
    IncreaseLimitsComponent,
    AddOnsLimitsComponent,
  },
  data() {
    return {
      modalId: 'increase-limits-dialog',
      addOnsData: {
        type: false,
        data: null,
      },
    }
  },
  mounted() {
    EventBus.$on('show-addons-limit', (data) => {
      this.addOnsData.type = data.type
      this.addOnsData.data = JSON.parse(data.orderId)
      this.$bvModal.show('increase-limits-dialog')
    })
  },
  methods: {
    hideModal() {
      this.addOnsData.type = false
      this.addOnsData.data = null
      this.$bvModal.hide('increase-limits-dialog')
    },
  },
}
</script>
