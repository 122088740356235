<template>
  <div class="scroll_content influencer-tweets">
    <template v-if="getInfluencerLoaders.posts">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="tweets && tweets.length > 0">
      <div
        class="twitter_post_box_grid fb_quote_box_grid article_box_grid"
        v-for="(tweet, index) in tweets"
      >
        <div class="box_inner">
          <div class="content_block">
            <div class="content_block_height">
              <div class="users_detail">
                <div class="profile_picture d-flex align-items-start">
                  <div
                    class="picture_block rounded_picture"
                    :style="getTweetUserImage(tweet.user)"
                  ></div>
                  <div class="text_block">
                    <a
                      target="_blank"
                      class="text"
                      :href="getTweetRedirectLink(tweet)"
                      :data-cy="'twitter_name_' + index"
                      >{{ limitTextLength(tweet.user.name, 15) }}</a
                    >
                    <p class="sub_text" v-if="tweet.created_at">{{
                      $filters.relative(tweet.created_at)
                    }}</p>
                  </div>
                </div>
              </div>
              <p
                class="desc"
                v-html="
                  twitterText(
                    tweet.text,
                    tweet.entities.hashtags,
                    tweet.entities.urls,
                    tweet.entities.user_mentions
                  )
                "
              ></p>
            </div>

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item">
                  <div class="name">
                    <p>Retweet</p>
                  </div>
                  <div class="value">
                    <h3 :data-cy="'twitter_retweet_count_' + index">{{
                      intToString(tweet.retweet_count)
                    }}</h3>
                  </div>
                </div>
                <div class="stat_item">
                  <div class="name">
                    <p>Favorite</p>
                  </div>
                  <div class="value">
                    <h3 :data-cy="'twitter_favorite_count_' + index">{{
                      intToString(tweet.favorite_count)
                    }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_block text-center">
              <div class="btn_block_inner d-flex">
                <div
                  class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div
                    class="dropdown_header"
                    data-toggle="dropdown"
                    @click="initializeTwitterPostCreation(tweet)"
                    :data-cy="'preview_post_share_' + index"
                  >
                    <button
                      class="btn light_gray"
                      v-tooltip.top-center="'Share this post'"
                    >
                      <i class="icon-Share_icon d-block"></i>
                    </button>
                  </div>
                </div>

                <div
                  class="article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div
                    class="dropdown_header"
                    @click.prevent="initializeTwitterRetweet(tweet)"
                    :data-cy="'preview_retweet_' + index"
                  >
                    <button
                      v-tooltip.top-center="'Retweet'"
                      @click="$bvModal.show('retweet_share_modal')"
                      class="btn blue"
                    >
                      <i class="cs-refresh d-block"></i>
                    </button>
                  </div>
                </div>

                <div
                  class="article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header">
                    <a
                      target="_blank"
                      :href="getTweetRedirectLink(tweet)"
                      class="btn blue"
                    >
                      <i class="cs-link d-block"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <!--<h2>No version history found</h2>-->
            <p>No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    tweets: {}
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders'])
  }
}
</script>

<style lang="less" scoped>
.scroll_content {
  padding: 0 20px !important;
  margin-top: 20px;
  margin-bottom: 70px;
  .content_block_height {
    height: auto !important;
    .desc {
      -webkit-line-clamp: 10 !important;
    }
  }
}
</style>
