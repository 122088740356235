var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('re-tweet'),_c('div',{staticClass:"article_inner"},_vm._l((_vm.getTweetsContent),function(tweet,index){return _c('div',{staticClass:"twitter_post_box_grid fb_quote_box_grid article_box_grid"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"content_block"},[_c('div',{staticClass:"content_block_height"},[_c('div',{staticClass:"users_detail"},[_c('div',{staticClass:"profile_picture d-flex align-items-start"},[_c('div',{staticClass:"picture_block rounded_picture",style:(_vm.getTweetUserImage(tweet.user))}),_c('div',{staticClass:"text_block"},[_c('a',{staticClass:"text",attrs:{"target":"_blank","href":_vm.getTweetRedirectLink(tweet)}},[_vm._v(_vm._s(_vm.limitTextLength(tweet.user.name, 15)))]),(tweet.created_at)?_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.$filters.relative(tweet.created_at)))]):_vm._e()])])]),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
                _vm.twitterText(
                  tweet.text,
                  tweet.entities.hashtags,
                  tweet.entities.urls,
                  tweet.entities.user_mentions
                )
              )}})]),_c('div',{staticClass:"social_stats"},[_c('div',{staticClass:"social_stats_inner d-flex"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Retweets'),expression:"'Retweets'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(0,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(tweet.retweet_count)))])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Favorite'),expression:"'Favorite'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(1,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(tweet.favorite_count)))])])])])]),_c('div',{staticClass:"btn_block d-flex text-center"},[_c('div',{staticClass:"btn_block_inner"},[_c('div',{staticClass:"article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"},[_c('div',{staticClass:"dropdown_header",attrs:{"data-toggle":"dropdown"},on:{"click":function($event){return _vm.initializeTwitterPostCreation(tweet)}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Share this post'),expression:"'Share this post'",modifiers:{"top-center":true}}],staticClass:"btn light_gray"},[_c('i',{staticClass:"icon-Share_icon d-block"})])])]),_c('div',{staticClass:"article_post_dropdown inline_block dropdown default_style_dropdown"},[_c('div',{staticClass:"dropdown_header",on:{"click":function($event){$event.preventDefault();return _vm.initializeTwitterRetweet(tweet)}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Retweet'),expression:"'Retweet'",modifiers:{"top-center":true}}],staticClass:"btn blue",on:{"click":function($event){return _vm.$bvModal.show('retweet_share_modal')}}},[_c('i',{staticClass:"cs-refresh d-block"})])])])])])])])])}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_vm._v(" Retweet ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_vm._v(" Favorite ")])])
}]

export { render, staticRenderFns }