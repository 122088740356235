<template>
    <div class="cst-tags-dropdown inline-block text-left w-full" v-on-clickaway="handleClickAway" :class="{ 'relative z-100': isOpen }">
      <div>
        <div class="flex flex-wrap items-start">
          <transition-group name="fade-list-inline-item">
            <div v-for="(tag, i) in selectedTags" :key="tag.id" :data-tag-id="tag.id" class="inline-flex justify-center
                items-center
                text-xs
                capitalize
                font-weight-500
                leading-tight
                rounded-xl
                px-2.5
                py-2
                mr-2
                mb-2.5
                bg-blue-100
                bg-opacity-25
                text-blue-500
                text-opacity-80
                font-inter
                focus:outline-none focus:ring-0">
              {{ tag.text }}
              <div><i @click="handleRemove(tag.id)" class="far fa-times ml-1.5 cursor-pointer hover:text-blue-700"></i></div>
            </div>
          </transition-group>
        </div>

        <div class="inline-flex overflow-hidden text-ellipsis whitespace-pre justify-between items-center w-full rounded-lg color-border shadow-xs bg-white font-normal text-gray-800 hover:bg-gray-50 focus:outline-none px-3 py-2.5 text-sm flex justify-between bg-main-gray">
          <div class="flex items-center justify-between w-full" >
            <input type="text" :placeholder="placeholder"
                   v-model="search"
                   @focus="isOpen = true"
                   @input="isOpen = true"
                   class="cst-tags-dropdown-input
                      border-0
                      bg-transparent
                      flex-1
                      w-full
                      text-sm
                  ">
            <i class="far fa-angle-down transition cursor-pointer" @click="isOpen = !isOpen" :class="isOpen ? 'fa-rotate-180' : ''"></i>
          </div>
        </div>
      </div>
      <div v-show="isOpen"
           class="max-h-72 overflow-y-auto origin-top-right absolute right-0 mt-2 w-100 rounded-xl shadow-lg bg-white focus:outline-none z-50" >
        <div  ref="dropdownList" class="py-2" role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
              :data-active="selected"
              tabindex="-1"
            >
          <div v-if="tagsList.length" class="text-gray-900 block cursor-pointer hover:bg-gray-50 active:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 px-3 py-2.5 text-sm">
            <div class="field_group m-0">
              <div class="checkbox_container">
                <label class="m-0 p-0 font-weight-500 d-flex justify-between items-center">

                  Select All

                  <input
                      @change="handleSelectAll"
                      :checked="selectedTags.length === tags.length"
                      type="checkbox"
                  />
                  <span class="check mr-1.5 relative flex flex-shrink-0"></span>

                </label>
              </div>
            </div>
          </div>

          <div class="px-3 py-2.5 text-sm" v-else>
            <slot name="no-data">
              No Data Found!
            </slot>
          </div>
          <div v-for="(tag, i) in tagsList" :key="i" class="text-gray-900 block cursor-pointer hover:bg-gray-50 active:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 px-3 py-2.5 text-sm">
            <div class="field_group m-0">
              <div class="checkbox_container">
                <label class="m-0 p-0 d-flex justify-between items-center">
                  {{ tag.text }}
                  <input
                      @change="handleSelect(tag)"
                      type="checkbox"
                      :checked="selectedTags.includes(tag)"
                  />
                  <span class="check mr-1.5 relative flex flex-shrink-0"></span>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "CstTagsDropdown.vue",
  props: {
    tags: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: 'Type here...'
    },
  },
  data () {
    return {
      isOpen: false,
      search: '',
      selected: 0,
      selectedTags: [],
      tagsList: [],
    }
  },
  watch: {
    tags(val, old){
      if (!_.isEqual(val, old)) {
        this.tagsList = val

        let tempArr = []
        val.forEach(tag => {
          if (tag.checked) {
            tempArr.push(tag)
          }
        })

        this.selectedTags = [...tempArr]
      }

    },
    search(newVal) {
      let sorted = this.tagsList.sort((a, b) => {
        if (a.text.toLowerCase().includes(newVal.toLowerCase())) {
          return -1;
        }
        if (b.text.toLowerCase().includes(newVal.toLowerCase())) {
          return 1;
        }
        if (a.text.startsWith(newVal) && b.text.startsWith(newVal)) {
          return a.text.localeCompare(b.text);
        }

        return 0;
      });

      this.tagsList = sorted;
    },
    selectedTags(newVal) {
      this.$emit('change', newVal);
    }
  },
  mounted() {
    this.tagsList = [...this.tags];
  },
  methods: {
    handleClickAway() {
      this.isOpen = false
    },
    handleRemove (id) {
      this.selectedTags = this.selectedTags.filter(tag => tag.id !== id);
      this.isOpen = false
    },
    handleSelectAll (event) {
      if (event.target.checked) {
        this.selectedTags = [...this.tagsList];
        this.isOpen = false
      } else {
        this.selectedTags = [];
      }
      this.search = ''
    },
    handleSelect (tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter(t => t.id !== tag.id);
      } else {
        this.selectedTags = this.selectedTags.concat(tag);
      }

    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
        setTimeout(()=> {
          this.$refs.dropdownList.focus()
          this.$refs.dropdownList.scrollTop = 0;
        },100)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  .cst-tags-dropdown-input {
    min-width: 60px;
  }

  .checkbox_container {
    label {
      padding: 0 !important;
      margin: 0;
    }
  }
</style>