<template>
  <b-modal id="add_hashtag_modal" hide-footer hide-header>
    <div class="modal_head">
      <h4 v-if="getNewWorkspaceHashtag && getNewWorkspaceHashtag._id"
        >Edit your hashtags</h4
      >
      <h4 v-else>Add a new set of hashtags</h4>
      <button
        type="button"
        class="modal_head__close"
        id="close"
        @click="$bvModal.hide('add_hashtag_modal')"
        >&times;</button
      >
    </div>
    <div class="modal_body">
      <hashtag-options type="miscellaneous"></hashtag-options>

      <div class="field_group">
        <button
          @click.prevent="saveWorkspaceHashtag()"
          class="btn btn-studio-theme-space"
          :disabled="getNewWorkspaceHashtagLoader"
          :class="{ disabled: getNewWorkspaceHashtagLoader }"
          data-cy="save-hashtag"
        >
          <span>Save</span>
          <clip-loader
            :color="'#ffffff'"
            :size="'12px'"
            v-if="getNewWorkspaceHashtagLoader"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const HashtagOptions = () =>
  import('../../publish/components/posting/options/HashtagOptions')
export default {
  components: {
    HashtagOptions
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getNewWorkspaceHashtag', 'getNewWorkspaceHashtagLoader'])
  },
  created () {},
  mounted () {},

  methods: {
    // ...mapActions(['saveWorkspaceHashtag'])
    saveWorkspaceHashtag () {
      this.$store.dispatch('saveWorkspaceHashtag', {
        type: 'miscellaneous',
        state: this
      })
    }
  }
}
</script>
