import { AppAnalyticsMixin } from '@src/app_analytics'

const defaultUserDisplayState = () => {
  return {
    notifications: {
      current: {}
    },
    upgrade_offer: true,
    upgrade_offer_for_starter_plan: true,
    billing_action_required: true
  }
}
const state = defaultUserDisplayState()

// getters
const getters = {
  getCurrentNotification: (state) => {
    return state.notifications.current
  },
  getUpgradeOfferStatus: (state) => {
    return state.upgrade_offer
  },
  getUpgradeOfferForStarterPlan: (state) => {
    return state.upgrade_offer_for_starter_plan
  },
  getBillingActionRequiredStatus: (state) => {
    return state.billing_action_required
  }
}

// actions
const actions = {
  /**
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param payload
   * @param payload['event'] = (string) such as signed_up, email_verified etc.
   */
  async trackEvent ({ commit, getters, dispatch }, payload) {
    const profile = getters.getProfile

    AppAnalyticsMixin.methods.analyticsTrackEvent(payload.event, profile)

    switch (payload.event) {
      // account workflow
      case 'signed_up':
        _cio.track(payload.event)
        _gs('event', payload.event)
        break
      case 'email_verified':
      case 'workspace_created':
      case 'team_members_added':
      case 'utm_tracking_created':
      case 'hashtags_created':
      case 'curated_topics_followed': // discovery
      case 'custom_topics_created':
      case 'query_searched':
      case 'favorite_folders_created':
      case 'insights_unlocked':
      case 'composer_campaigns_created': // composer
      case 'labels_created':
      case 'comments_created':
      case 'tasks_created':
      case 'article_to_social_recipe_created': // automation
      case 'article_to_blog_recipe_created':
      case 'rss_recipe_created':
      case 'bulk_upload_recipe_created':
      case 'evergreen_recipe_created':
        _cio.track(payload.event)
        _gs('event', payload.event)
        break
      case 'blog_accounts_connected':
        break
      case 'social_accounts_connected':
        break
      case 'replug_connected':
        break
      case 'social_content_shared':
        break
      case 'blog_content_shared':
        break
    }
  }
}

// mutations
const mutations = {
  UPGRADE_OFFER_STATUS (state, status) {
    state.upgrade_offer = status
  },
  UPGRADE_OFFER_STATUS_FOR_STARTER_PLAN (state, status) {
    state.upgrade_offer_for_starter_plan = status
  },
  SET_BILLING_ACTION_REQUIRED_STATUS (state, status) {
    state.billing_action_required = status
  },
  SET_CURRENT_NOTIFICATIONS (state, value) {
    state.notifications.current = value
  },
  SET_IMPORTANT_NOTIFICATIONS_ITEMS (state, value) {
    state.notifications.current = value
  }
  /* SET_IMPORTANT_NOTIFICATIONS_VISIBILITY (state, value) {
    state.importantNotifications.visibility = value
  } */
}

export default {
  state,
  getters,
  actions,
  mutations
}
