<template>
  <div class="table_filter d-flex align-items-center">
    <div class="f_left">
      <a
        class="btn btn-studio-theme-space"
        @click.prevent="createRssAutomationProcess()"
      >
        <i class="icon_left add_icon far fa-plus"> </i>
        <span>New Campaign</span>
      </a>

      <template v-if="getRssAutomationListing.selected.length > 0">
        <div class="ml-2 dropdown option_dropdown default_style_dropdown">
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="align-middle text">Select Operation</span>
            <i class="align-middle cs-angle-down icon_last"></i>
          </div>

          <div class="dropdown-menu dropdown-menu-left">
            <ul class="inner">
              <li
                class="list_item"
                @click.prevent="bulkOperationConfirmationModal('Active')"
              >
                <a class="dropdown-item">Active</a>
              </li>
              <li
                class="list_item"
                @click.prevent="bulkOperationConfirmationModal('Inactive')"
              >
                <a class="dropdown-item">Inactive</a>
              </li>
              <li
                class="list_item"
                @click.prevent="bulkOperationConfirmationModal('Delete')"
              >
                <a class="dropdown-item">Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>

    <div class="f_right ml-auto d-flex">
      <div
        class="platform_filters_planner prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown"
      >
        <platform-filters
          :dropdownPosition="'dropdown-menu-right'"
          type="Social"
          source="rss"
        ></platform-filters>
      </div>

      <div class="ml-2 search-bar-input">
        <div class="search-bar-inner">
          <input
            type="text"
            placeholder="Search for campaign..."
            v-model="getRssAutomationListing.search"
            v-on:keyup.enter="fetchRSSAutomationsList()"
          />
          <button class="search_btn" @click.prevent="fetchRSSAutomationsList()">
            <i class="icon-Search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PlatformFilters from '../../../../publish/components/PlatformFilters'
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  components: {
    PlatformFilters
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getRssAutomationListing'])
  },
  methods: {
    ...mapActions(['fetchRSSAutomationsList']),

    bulkOperationConfirmationModal (state) {
      this.$bvModal
        .msgBoxConfirm('This action cannot be undone.', {
          title: 'Perform Bulk Operation',
          ...swalAttributes()
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('bulkOperationRSS', state)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    }
  },

  watch: {}
}
</script>
