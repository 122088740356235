<template>
  <div>
    <div class="v-calendar-view">
      <div class="event_inner">
        <!--<div class="custom_loader hide">-->
        <!--<div class="loader_inner"></div>-->
        <!--</div>-->
        <div class="top_tags_category">
          <div
            v-if="item.content_category"
            v-tooltip.top-center="'Content Category'"
            class="top-category"
            :style="{ 'background-color': item.content_category.color_code }"
            >{{ item.content_category.name }}
          </div>
          <div
            v-if="getItemTag"
            v-tooltip.top-center="'Content Type'"
            class="list_tag"
            >{{ getItemTag }}
          </div>
        </div>

        <!--<div title="Content Category" class="top-category">adasdasd</div>-->

        <div class="event_head d-flex align-items-center">
          <div class="head_left">
            <p class="d-flex align-items-center">
              <i
                v-if="item.render_class"
                v-tooltip="
                  item['partially_failed']
                    ? 'Partially Failed'
                    : item.render_class.tooltip
                "
                :class="
                  item['partially_failed']
                    ? 'fas fa-adjust , partially_failed partial'
                    : [item.render_class.icon, item.post_state]
                "
                class="status_color left-icon d-flex align-items-center justify-content-center"
              ></i>
              <span v-if="item.execution_time">
                {{
                  useGetWorkspaceTimeZoneTime(
                    item['execution_time']['date'],
                    getWorkspaces.activeWorkspace.timezone,
                    'hh:mm a'
                  )
                }}</span
              >
              <!--                                <input type="checkbox" id="calendarItemCheckbox" :name="'plan_actions'+ item._id" :value="item._id" v-model="getPlans.selected_plans">-->
              <!--                                <label :for="'plan_actions'+ item._id" class="no_text checkbox_left"></label>-->
            </p>
          </div>
          <!-- note, this is slowing down  the speed -->
          <div class="head_right">
            <PlanPlatforms :item="item"></PlanPlatforms>
          </div>
        </div>

        <div class="event_content">
          <p v-html="parseDescription(planHeadText(item, 'blog'), 100)"></p>

          <div v-if="getPlanAttachment(item)" class="img preview-here">
            <!--data-plan will be use to find plan media to display in the modal-->
            <img
              id="viewItemAttachment"
              :src="getResizedImageURL(getPlanAttachment(item), 580, 0)"
              :data-plan="item._id"
              alt=""
              @error="
                $event.target.src = !isStorageLink(getPlanAttachment(item))
                  ? `${getPlanAttachment(item)}`
                  : `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
              "
            />
          </div>

          <div class="filter_label">
            <template v-for="(label, key) in item.labels">
              <div
                v-if="getLabelById(label)"
                :key="key"
                class="label_tag"
                :class="[getLabelById(label).color]"
                >{{ getLabelById(label).name }}
              </div>
            </template>
          </div>
        </div>

        <!--v-if="item.post_state === 'published'"-->
        <template v-if="item.post_state === 'published'">
          <div class="states">
            <div class="stat_item new-tooltip">
              <div class="icon">
                <i v-tooltip.top-center="'Total Likes'" class="fal fa-heart">
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.likes }}</h3>
              </div>
            </div>

            <div class="stat_item new-tooltip">
              <div class="icon">
                <i v-tooltip.top-center="'Total Shares'" class="fal fa-repeat">
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.shares }}</h3>
              </div>
            </div>

            <div class="stat_item new-tooltip">
              <div class="icon">
                <i
                  v-tooltip.top-center="'Total Comments'"
                  class="fal fa-comment-alt-dots"
                >
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.comments }}</h3>
              </div>
            </div>
          </div>
        </template>

        <div class="bottom_action calendar-btn d-flex">
          <!--<template v-if="item.can_perform.approval">-->
          <!--<i id="approveCalendarItem"  title="Approve" class="green_icon calendar_actions check far fa-check"></i>-->
          <!--<i id="rejectCalendarItem" title="Reject" class="red_icon calendar_actions cross far fa-times"></i>-->
          <!--</template>-->

          <template v-if="item.status === 'review' || item.status === 'draft'">
            <template v-if="item.status === 'review' && !item.approval">
              <template v-if="item.can_perform.approval">
                <i
                  v-tooltip.top-center="'Approve'"
                  data-event="approveCalendarItem"
                  class="green_icon calendar_actions check far fa-check"
                ></i>
                <i
                  v-tooltip.top-center="'Reject'"
                  data-event="rejectCalendarItem"
                  class="red_icon calendar_actions cross far fa-times"
                ></i>
              </template>
            </template>

            <template
              v-else-if="
                item.approval &&
                item.approval.status === 'pending_approval' &&
                checkApprovalStatus(item.approval)
              "
            >
              <!--<button  type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'approve')">Approve</button>-->
              <i
                v-tooltip.top-center="'Approve'"
                data-event="approvalCalendarItemApprove"
                class="green_icon calendar_actions check far fa-check"
              ></i>

              <!--<button type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'reject')">Reject</button>-->
              <i
                v-tooltip.top-center="'Reject'"
                data-event="approvalCalendarItemReject"
                class="red_icon calendar_actions cross far fa-times"
              ></i>
            </template>
          </template>

          <template
            v-if="
              getTeamMembership === 'team' &&
              item.status === 'draft' &&
              item.publish_time_options &&
              item.publish_time_options.plan_status === 'Approved' &&
              item.type === 'Composer Social'
            "
          >
            <div class="min_width dropdown_header" data-toggle="dropdown">
              <i
                v-if="item.publish_time_options.hide_client"
                v-tooltip.top-center="hideFromClientMessage(item)"
                data-event="hideClientCalendarItem"
                class="far fa-eye-slash"
              ></i>
              <i
                v-else
                v-tooltip.top-center="hideFromClientMessage(item)"
                data-event="hideClientCalendarItem"
                class="far fa-eye"
              ></i>
            </div>
            <div
              id="test"
              class="dropdown-menu dropdown-menu-right custom-popup eye-visible-pop visibility-popup"
            >
              <div class="popup-inner">
                <template v-if="item.publish_time_options.hide_client">
                  <p>Make this post visible to clients?</p>
                  <div class="buttons">
                    <button data-event="visibleBtn" type="">No</button>
                    <button
                      data-event="visibleBtn"
                      @click.prevent="
                        hideFromClientAction(
                          plans.filter((plan) => plan._id === item._id)[0],
                          false
                        )
                      "
                      >Yes
                    </button>
                  </div>
                </template>
                <template v-else>
                  <p>Make this post Invisible to clients?</p>
                  <div class="buttons">
                    <button data-event="visibleBtn" type="">No</button>
                    <button
                      data-event="visibleBtn"
                      @click.prevent="
                        hideFromClientAction(
                          plans.filter((plan) => plan._id === item._id)[0],
                          true
                        )
                      "
                      >Yes
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-if="!isInProgress(item)">
            <i
              v-if="item.can_perform.replace"
              v-tooltip.top-center="
                'This action will fetch a new post with the same rules you have set in the automation campaign'
              "
              data-event="replaceCalendarItem"
              class="calendar_actions refresh far fa-refresh"
            ></i>
            <i
              v-if="canEditPost(item)"
              v-tooltip.top-center="'Edit'"
              data-event="editCalendarItem"
              class="calendar_actions edit icon-edit-cs"
            ></i>
            <i
              v-if="item.can_perform.duplicate"
              v-tooltip.top-center="'Duplicate'"
              data-event="duplicateCalendarItem"
              class="calendar_actions clone far fa-clone"
            ></i>
            <i
              v-if="item.can_perform.delete"
              v-tooltip.top-center="'Delete'"
              data-event="deleteCalendarItem"
              class="delete_icon calendar_actions trash icon-delete-cs"
            ></i>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlatformName } from '@common/lib/integrations'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import {
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformVisibleStatus,
} from '@common/lib/planner'
import { memoizeCallback, parseDescription } from '@common/lib/helper'
import PlanPlatforms from '@src/modules/planner/components/view/PlanPlatforms'
import { mapGetters } from 'vuex'
import plannerUtilsMixin from '@common/mixins/publish/planner-mixin'

// Memoize the callback to avoid unnecessary re-renders
const usePlanHeadAttachment = memoizeCallback(planHeadAttachment, true)
const useGetWorkspaceTimeZoneTime = memoizeCallback(
  getWorkspaceTimeZoneTime,
  true
)

const importedMethods = {
  getPlatformName,
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformVisibleStatus,
  parseDescription,
  useGetWorkspaceTimeZoneTime,
}
export default {
  components: {
    PlanPlatforms,
  },
  mixins: [plannerUtilsMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    plans: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPlannerLoaders',
      'getProfile',
      'getActiveWorkspace',
      'getPublishSelection',
      'getSocialIntegrationsNames',
    ]),
    getItemTag() {
      if (this.item.evergreen_automation_id) return 'Evergreen'
      if (this.item.rss_automation_id) return 'RSS'
      if (this.item.repeat_post) return 'Repeat'
      return ''
    },
  },
  methods: {
    ...importedMethods,
    hideFromClientMessage(item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    getPlanAttachment(item) {
      // const attachment = planHeadAttachment(item)
      const attachment = usePlanHeadAttachment(item)

      if (attachment.url) {
        return attachment.type === 'image'
          ? attachment.url
          : attachment.thumbnail
      }
      return false
    },

    isInProgress(item) {
      return item.status === 'queued' || item.status === 'processing'
    },
    onClickCalendarEvent() {
      console.log('METHOD::onClickCalendarEvent')
      this.$emit('preview-plan', this.item._id)
    },
  },
}
</script>

<style lang="scss" scoped>
i.partially_failed {
  transform: rotate(270deg);
  color: #c00000;
}
</style>
