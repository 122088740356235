<template>
  <div class="ranges">
    <ul v-if="ranges">
      <li
        v-for="(value, key) in ranges"
        :key="key"
        :data-range-key="key"
        :class="{ active: key === label }"
        @click="$emit('clickRange', value, key, $event)"
        >{{ key }}</li
      >
    </ul>
  </div>
</template>

<script>
export default {
  props: ['ranges', 'label'],
}
</script>
