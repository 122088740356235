<template>
  <b-modal
    id="curated_topic_modal"
    modal-class="curated_topic_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Follow Curated Topics</h4>
      <!--<button type="button" id="close" class="modal_head__close" @click="$bvModal.hide('curated_topic_modal')">&times;</button>-->
      <button
        type="button"
        id="close"
        class="modal_head__close"
        @click="curatedTopicModal"
        >&times;</button
      >
    </div>
    <div class="modal_body">
      <div class="row mt-2">
        <div class="search-bar-input w-50 m-auto">
          <div class="search-bar-inner">
            <input
              ref="CuratedTopicField"
              v-model="getFollowCurated.search"
              @input="changeSearchKeyDownCuratedTopic($event)"
              @keyup.enter="searchCuratedTopic()"
              type="text"
              placeholder="What are you interested in?"
              class="dark-bd"
              data-cy="serach-topic"
            />
            <button class="search_btn">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="curated_main_content d-flex align-items-stretch w-100">
          <div class="curated_left">
            <div class="">
              <template v-if="getFollowCurated.suggested.length > 0">
                <label class="big_label green" for="">Suggested Topics</label>
                <div class="curated_topic_list suggested_topic_list">
                  <div
                    class="checkbox_input_image"
                    @click.prevent="
                      ADD_TO_SELECTED_LIST({
                        topic: suggested_topic,
                        index: index,
                      })
                    "
                    v-for="(
                      suggested_topic, index
                    ) in getFollowCurated.suggested"
                  >
                    <input type="checkbox" id="suggested_topic.id" />
                    <label
                      for="suggested_topic.id"
                      class="checkbox_left"
                      data-cy="select-topic"
                      >{{ suggested_topic.name }}</label
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <template v-if="getFollowCuratedLoader.auto_complete">
                  <beat-loader :color="'#436aff'"></beat-loader>
                </template>
                <template v-else>
                  <p class="text-center"
                    >No curated topic found for your searched query.</p
                  >
                </template>
              </template>
            </div>
          </div>
          <div
            class="curated_right"
            v-show="getFollowCurated.selected.length > 0"
          >
            <div class="input_field mt-0">
              <label class="big_label" for="">Selected Topics</label>
              <div class="curated_topic_list select_topic_list">
                <div
                  class="checkbox_input_image"
                  @click.prevent="
                    ADD_TO_SUGGESTED_LIST({ topic: topic, index: index })
                  "
                  v-for="(topic, index) in getFollowCurated.selected"
                >
                  <input type="checkbox" class="" id="topic.id" />
                  <label for="topic.id" class="checkbox_left"
                    >{{ topic.name }} <i class="fa fa-times"></i
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field_group text-center">
        <button
          :disabled="
            getFollowCurated.selected.length == 0 || getFollowCuratedLoader.save
          "
          @click.prevent="saveCuratedTopicsList()"
          class="btn btn-studio-theme-space"
          data-cy="add-topic-button"
        >
          <span>Done - Add to Topics</span>
          <clip-loader
            :color="'#ffffff'"
            :size="'12px'"
            v-if="getFollowCuratedLoader.save"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import { followBatchTopicsURL } from '../../config/api-utils'
import { curatedTypes } from '../../store/mutation-types'

export default {
  components: {},
  computed: {
    ...mapGetters(['getFollowCurated', 'getFollowCuratedLoader'])
  },

  data () {
    return {
      source: false
    }
  },

  created () {
    // this.searchCuratedTopic()
  },
  mounted () {},

  methods: {
    ...mapMutations([
      curatedTypes.ADD_TO_SELECTED_LIST,
      curatedTypes.ADD_TO_SUGGESTED_LIST
    ]),
    ...mapActions([
      'saveCuratedTopics',
      'searchCuratedTopic',
      'autocompleteCuratedTopic'
    ]),
    changeSearchKeyDownCuratedTopic: debounce(function (event) {
      console.debug('Method:CuratedTopicSuggestion')
      console.debug('changeSearchKeyDown', event)

      if (
        event.key !== 'Enter' &&
        (this.getFollowCurated.search.length >= 3 ||
          this.getFollowCurated.search.length === 0)
      ) {
        console.log(
          'Autocomplete characters are greater than 3 and perfomring search...'
        )
        this.autocompleteCuratedTopic()
      } else {
        /*
              //NOTE: not sure why are we calling this...
              Was not sure why are we calling this.
              console.log('Autocomplete performing search...')
              this.setArticleSuggestion(false)
              this.setDomain(null)
              if (event.key === 'Enter') {
                this.changeSearch()
              } */

        $('.header_search_input').blur()
      }
    }, 250),
    async saveCuratedTopicsList () {
      await this.saveCuratedTopics(this)
      // this.$store.dispatch('trackEvent', { event: 'curated_topics_followed' })

      if (this.$route.name === 'discover_topics_follow') {
        this.$router.push({ name: 'discover_topics_cover_stories' })
      }

      if (!this.getActiveWorkspace.onboarding_steps.discover_content.status) {
        await this.onboardingStepsCompleted('discover_content')
      }
    },
    curatedTopicModal () {
      this.getFollowCurated.search = ''
      this.autocompleteCuratedTopic()
      this.$bvModal.hide('curated_topic_modal')
    }
  }
}
</script>
