const articlesTypes = {
  SET_CONTENT_TAB_STATUS: 'SET_CONTENT_TAB_STATUS',
  SET_DID_YOU_MEAN_TEXT: 'SET_DID_YOU_MEAN_TEXT',
  SET_TRENDING_STATUS: 'SET_TRENDING_STATUS',
  SET_DISCOVERY_TYPE: 'SET_DISCOVERY_TYPE',
  SET_SCROLL: 'SET_SCROLL',
  SET_POSTS: 'SET_POSTS',
  SET_DOMAIN: 'SET_DOMAIN',
  SET_RELATED_STATUS: 'SET_RELATED_STATUS',
  SET_CONTENT_PREVIEW: 'SET_CONTENT_PREVIEW',
  SET_RELATED_TOPICS: 'SET_RELATED_TOPICS',
  SET_SIMILAR_ARTICLES: 'SET_SIMILAR_ARTICLES',
  SET_CONTENT_PREVIEW_LOADER: 'SET_CONTENT_PREVIEW_LOADER',
  SET_SEARCH: 'SET_SEARCH',
  SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_KEYWORD: 'SET_SEARCH_KEYWORD',
  SET_SEARCH_SUGGESTED_KEYWORDS: 'SET_SEARCH_SUGGESTED_KEYWORDS',
  SET_SEARCH_SUGGESTED_TOPICS: 'SET_SEARCH_SUGGESTED_TOPICS',
  SET_SEARCH_REQUESTS: 'SET_SEARCH_REQUESTS',
  SET_SEARCH_CONTENT_TYPE: 'SET_SEARCH_CONTENT_TYPE',
  SET_SEARCH_DISPLAY_TOPICS: 'SET_SEARCH_DISPLAY_TOPICS',
  SET_SEARCH_ARTICLES_TOPIC: 'SET_SEARCH_ARTICLES_TOPIC',
  SET_SEARCH_BUTTON_LOADER: 'SET_SEARCH_BUTTON_LOADER',
  SET_ARTICLE_SHOW_SUGGESTIONS: 'SET_ARTICLE_SHOW_SUGGESTIONS',
  SET_ARTICLES_TYPE: 'SET_ARTICLES_TYPE',
  SET_ARTICLES_SORT: 'SET_ARTICLES_SORT',
  SET_ARTICLES_DATE_RANGE: 'SET_ARTICLES_DATE_RANGE',
  SET_ARTICLES_PAGE: 'SET_ARTICLES_PAGE',
  SET_ARTICLES_REGION: 'SET_ARTICLES_REGION',
  SET_ARTICLES_LANGUAGE: 'SET_ARTICLES_LANGUAGE',
  SET_ARTICLES_LOADER: 'SET_ARTICLES_LOADER',
  SET_ARTICLES_NO_MORE_RESULTS: 'SET_ARTICLES_NO_MORE_RESULTS',
  SET_ARTICLES_MODULE_TYPE: 'SET_ARTICLES_MODULE_TYPE',
  SET_ARTICLE_PREVIEW_CONTENT: 'SET_ARTICLE_PREVIEW_CONTENT',
  SET_ARTICLE_PREVIEW_CONTENT_LOADER: 'SET_ARTICLE_PREVIEW_CONTENT_LOADER',
  SET_CONTENT_LOADER: 'SET_CONTENT_LOADER',
  RESET_ARTICLES: 'RESET_ARTICLES'
}

const curatedTypes = {
  SET_CURATED_TOPICS_ALL: 'SET_CURATED_TOPICS_ALL',
  SET_SELECTED_LIST: 'SET_SELECTED_LIST',
  ADD_TO_SELECTED_LIST: 'ADD_TO_SELECTED_LIST',
  SET_SUGGESTED_LIST: 'SET_SUGGESTED_LIST',
  SET_AUTOCOMPLETE_CURATED_TOPICS_LOADER:
    'SET_AUTOCOMPLETE_CURATED_TOPICS_LOADER',
  ADD_TO_SUGGESTED_LIST: 'ADD_TO_SUGGESTED_LIST',
  SET_CURATED_SAVE_LOADER: 'SET_CURATED_SAVE_LOADER',
  SET_CURATED_VIEW_LOADER: 'SET_CURATED_VIEW_LOADER'
}

const topicTypes = {
  RESET_SELECTION_ON_WORKSPACE_CHANGE: 'RESET_SELECTION_ON_WORKSPACE_CHANGE',
  RESET_TOPICS_FILTER: 'RESET_TOPICS_FILTER',
  SET_POSTS_TOTAL: 'SET_POSTS_TOTAL',
  SET_TOPIC_MODULE: 'SET_TOPIC_MODULE',
  SET_ACTIVE_TOPIC_TAB: 'SET_ACTIVE_TOPIC_TAB',
  SET_CURATED_STORIES_POSTS: 'SET_CURATED_STORIES_POSTS',
  SET_CUSTOM_STORIES_POSTS: 'SET_CUSTOM_STORIES_POSTS',
  SET_CUSTOM_TOPICS_LOADER: 'SET_CUSTOM_TOPICS_LOADER',
  SET_COVERED_STORIES_STATUS: 'SET_COVERED_STORIES_STATUS',
  SET_TOPIC_SELECTION: 'SET_TOPIC_SELECTION',
  SET_BEFORE_SORT: 'SET_BEFORE_SORT',
  SET_BEFORE_DATE: 'SET_BEFORE_DATE',
  SET_BEFORE_LANGUAGE: 'SET_BEFORE_LANGUAGE',
  SET_BEFORE_REGION: 'SET_BEFORE_REGION',
  SET_BEFORE_TOPIC_NAME: 'SET_BEFORE_TOPIC_NAME',
  SET_TOPIC_DISABLED: 'SET_TOPIC_DISABLED',
  SET_TOPIC_QUOTA_STATUS: 'SET_TOPIC_QUOTA_STATUS',
  SET_TOPIC_CONTENT_TYPE: 'SET_TOPIC_CONTENT_TYPE',
  SET_TOPIC_TYPE: 'SET_TOPIC_TYPE',
  SET_TOPIC_NAME: 'SET_TOPIC_NAME',
  SET_TOPICS_LOADER: 'SET_TOPICS_LOADER',
  SET_TOPICS_PAGINATION_LOADER: 'SET_TOPICS_PAGINATION_LOADER',
  SET_TOPICS_PAGE: 'SET_TOPICS_PAGE',
  SET_TOPICS_SCROLL: 'SET_TOPICS_SCROLL',
  SET_TOPICS_LAYOUT: 'SET_TOPICS_LAYOUT',
  SET_TOPICS_TYPE: 'SET_TOPICS_TYPE',
  SET_TOPICS_SORT: 'SET_TOPICS_SORT',
  SET_TOPICS_LANGUAGE: 'SET_TOPICS_LANGUAGE',
  SET_TOPICS_REGION: 'SET_TOPICS_REGION',
  SET_TOPICS_DATE_RANGE: 'SET_TOPICS_DATE_RANGE',
  SET_CHANGE_TOPIC_FILTERS: 'SET_CHANGE_TOPIC_FILTERS',
  SET_CUSTOM_TOPICS: 'SET_CUSTOM_TOPICS',
  ADD_TO_CURATED_LIST: 'ADD_TO_CURATED_LIST',
  SET_CURATED_TOPICS_LIST: 'SET_CURATED_TOPICS_LIST',
  SET_TOPICS_PAGE_LOADED: 'SET_TOPICS_PAGE_LOADED',
  REMOVE_FROM_CURATED_LIST: 'REMOVE_FROM_CURATED_LIST'
}
const topicCustomizedSidebarTypes = {
  SET_SIDEBAR_CUSTOMIZATION: 'SET_SIDEBAR_CUSTOMIZATION',
  SET_CUSTOM_SIDEBAR_OPTIONS: 'SET_CUSTOM_SIDEBAR_OPTIONS',
  SET_SIDEBAR_CUSTOMIZATION_FETCH_LOADER:
    'SET_SIDEBAR_CUSTOMIZATION_FETCH_LOADER',
  SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER:
    'SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER'
}
const insightTypes = {
  SET_INSIGHTS_COUNT: 'SET_INSIGHTS_COUNT',
  SET_INSIGHTS_SENTIMENT_COUNT: 'SET_INSIGHTS_SENTIMENT_COUNT',
  SET_INSIGHTS_CONTENT_TYPES: 'SET_INSIGHTS_CONTENT_TYPES',
  SET_INSIGHTS_ENGAGEMENT: 'SET_INSIGHTS_ENGAGEMENT',
  SET_INSIGHTS_READING_LEVELS: 'SET_INSIGHTS_READING_LEVELS',
  SET_INSIGHTS_WORD_COUNT: 'SET_INSIGHTS_WORD_COUNT',
  SET_INSIGHTS_POPULAR_DAYS: 'SET_INSIGHTS_POPULAR_DAYS',
  SET_INSIGHTS_POPULAR_READING_WORD_COUNT:
    'SET_INSIGHTS_POPULAR_READING_WORD_COUNT',
  SET_INSIGHTS_TOP_DOMAINS: 'SET_INSIGHTS_TOP_DOMAINS',
  SET_INSIGHTS_TOP_DOMAINS_FOR_CHART: 'SET_INSIGHTS_TOP_DOMAINS_FOR_CHART',
  SET_INSIGHTS_TOP_AUTHORS: 'SET_INSIGHTS_TOP_AUTHORS',
  SET_INSIGHTS_WORDCLOUD: 'SET_INSIGHTS_WORDCLOUD',
  SET_INSIGHTS_LOADER: 'SET_INSIGHTS_LOADER',
  SET_INSIGHTS_CHARTS_LOADER: 'SET_INSIGHTS_CHARTS_LOADER',
  SET_ARTICLES_PUBLISHED_OVER_TIME_POSTS_COUNT:
    'SET_ARTICLES_PUBLISHED_OVER_TIME_POSTS_COUNT',
  SET_ARTICLES_PUBLISHED_OVER_TIME_ENGAGEMENT:
    'SET_ARTICLES_PUBLISHED_OVER_TIME_ENGAGEMENT',
  SET_ARTICLES_PUBLISHED_OVER_TIME_DATE:
    'SET_ARTICLES_PUBLISHED_OVER_TIME_DATE',
  SET_ARTICLES_PUBLISHED_OVER_TIME_SENTIMENTS:
    'SET_ARTICLES_PUBLISHED_OVER_TIME_SENTIMENTS'
}

const previewTypes = {
  SET_PREVIEW_SUGGESTION_PAGE: 'SET_PREVIEW_SUGGESTION_PAGE',
  SET_PREVIEW_SUGGESTION_SCROLL: 'SET_PREVIEW_SUGGESTION_SCROLL',
  SET_PREVIEW_SUGGESTION_POSTS: 'SET_PREVIEW_SUGGESTION_POSTS',
  SET_PREVIEW_SUGGESTION_TOTAL: 'SET_PREVIEW_SUGGESTION_TOTAL',
  SET_PREVIEW_SUGGESTION_NO_MORE_RESULT:
    'SET_PREVIEW_SUGGESTION_NO_MORE_RESULT',
  SET_PREVIEW_SUGGESTION_INFINITE_LOADER:
    'SET_PREVIEW_SUGGESTION_INFINITE_LOADER',
  SET_PREVIEW_SUGGESTION_TOKEN: 'SET_PREVIEW_SUGGESTION_TOKEN',
  SET_PREVIEW_SUGGESTION_LOADER: 'SET_PREVIEW_SUGGESTION_LOADER',
  SET_PREVIEW_SUGGESTION_POST_AGE: 'SET_PREVIEW_SUGGESTION_POST_AGE',
  SET_PREVIEW_SUGGESTION_ENGAGEMENT: 'SET_PREVIEW_SUGGESTION_ENGAGEMENT'
}

const pocketTypes = {
  SET_POCKET_PREVIEW_CONTENT: 'SET_POCKET_PREVIEW_CONTENT',
  SET_POCKET_PREVIEW_CONTENT_LOADER: 'SET_POCKET_PREVIEW_CONTENT_LOADER'
}

const influencerTypes = {
  SET_INFLUENCER_LIST: 'SET_INFLUENCER_LIST',
  ADD_INFLUENCER_TO_LIST: 'ADD_INFLUENCER_TO_LIST',
  REMOVE_INFLUENCER_FROM_LIST: 'REMOVE_INFLUENCER_FROM_LIST',
  RESET_INFLUENCER_STATES: 'RESET_INFLUENCER_STATES',
  SET_INFLUENCER_PAGINATION: 'SET_INFLUENCER_PAGINATION',
  SET_INFLUENCER_FOLDER_LIST: 'SET_INFLUENCER_FOLDER_LIST',
  ADD_INFLUENCER_TO_FOLDER_LIST: 'ADD_INFLUENCER_TO_FOLDER_LIST',
  REMOVE_INFLUENCER_FROM_FOLDER_LIST: 'REMOVE_INFLUENCER_FROM_FOLDER_LIST',
  SET_INFLUENCER_LOADER_PREVIEW: 'SET_INFLUENCER_LOADER_PREVIEW',
  SET_INFLUENCER_TWEET_TEXT: 'SET_INFLUENCER_TWEET_TEXT',
  SET_INFLUENCER_TWEET_LOADER: 'SET_INFLUENCER_TWEET_LOADER',
  SET_INFLUENCER_FOLLOW_DATA: 'SET_INFLUENCER_FOLLOW_DATA',
  SET_INFLUENCER_FOLLOW_LOADER: 'SET_INFLUENCER_FOLLOW_LOADER',
  SET_FETCH_INFLUENCER_FOLLOWERS_LOADER:
    'SET_FETCH_INFLUENCER_FOLLOWERS_LOADER',
  SET_INFLUENCER_FOLLOWERS: 'SET_INFLUENCER_FOLLOWERS',

  SET_INFLUENCER_LOADER_TOP_MENTIONED: 'SET_INFLUENCER_LOADER_TOP_MENTIONED',
  SET_INFLUENCER_LOADER_SHARED_ARTICLES:
    'SET_INFLUENCER_LOADER_SHARED_ARTICLES',

  SET_INFLUENCER_LOADER_POSTS: 'SET_INFLUENCER_LOADER_POSTS',

  SET_CREATE_LIST_INFLUENCER: 'SET_CREATE_LIST_INFLUENCER',
  CLEAR_INFLUENCERS_SIDEBAR_FILTER: 'CLEAR_INFLUENCERS_SIDEBAR_FILTER',
  SET_INFLUENCER_FILTER_LOADER: 'SET_INFLUENCER_FILTER_LOADER',

  RESET_INFLUENCERS_FILTER: 'RESET_INFLUENCERS_FILTER',

  // instagram

  CLEAR_INSTAGRAM_INFLUENCERS_SIDEBAR_FILTER:
    'CLEAR_INSTAGRAM_INFLUENCERS_SIDEBAR_FILTER',

  // youtube
  CLEAR_YOUTUBE_INFLUENCERS_SIDEBAR_FILTER:
    'CLEAR_YOUTUBE_INFLUENCERS_SIDEBAR_FILTER'
}

const feederTypes = {
  SET_GROUP_STORE_ITEM: 'SET_GROUP_STORE_ITEM',
  ADD_ITEM_TO_GROUP: 'ADD_TO_GROUP_ITEM',
  REMOVE_ITEM_FROM_GROUP: 'REMOVE_ITEM_FROM_GROUP',
  UPDATE_ITEM_TO_GROUP: 'UPDATE_ITEM_TO_GROUP',
  SET_GROUP_ITEMS: 'SET_GROUP_ITEMS',
  CHANGE_ITEM_VIEW_PREFERENCE: 'CHANGE_ITEM_VIEW_PREFERENCE',
  RESET_CREATE_GROUP_STATE: 'RESET_CREATE_GROUP_STATE'
}
const discoverTypes = {
  SET_DISCOVER_CURATED_TOPIC: 'SET_DISCOVER_CURATED_TOPIC',
  RESET_DISCOVER_CURATED_TOPIC: 'RESET_DISCOVER_CURATED_TOPIC',
  SET_DISCOVER_SEARCH_TOTAL_RESULTS: 'SET_DISCOVER_SEARCH_TOTAL_RESULTS'
}
const folderTypes = {
  SET_FOLDERS_LIST: 'SET_FOLDERS_LIST'
}
export {
  articlesTypes,
  curatedTypes,
  topicTypes,
  insightTypes,
  previewTypes,
  pocketTypes,
  influencerTypes,
  feederTypes,
  discoverTypes,
  folderTypes,
  topicCustomizedSidebarTypes
}
