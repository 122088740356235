<template>
  <div class="setting_groups_component">
    <!--<time-queue></time-queue>-->
    <!--<add-group></add-group>-->

    <!--<div class="component_inner">-->
    <!--<div class="social_block">-->

    <!--<div class="head d-flex align-items-center">-->
    <!--<div class="head_left">-->
    <!--<a href="" class="btn blue_gradient"><span>Connect a new Facebook page/group</span></a>-->

    <!--<button  data-toggle="modal"-->
    <!--data-target="#time_queue_modal"-->
    <!--class="btn blue_gradient min_width"><span>open test queue modal</span></button>-->

    <!--<button  data-toggle="modal"-->
    <!--data-target="#addGroup" @click="createGroup()"-->
    <!--class="btn blue_gradient min_width"><span>open test create board modal</span></button>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div class="head_right">&ndash;&gt;-->
    <!--&lt;!&ndash;&nbsp;&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="social_table">-->
    <!--<div class="table_wrapper">-->
    <!--<table class="table">-->
    <!--<thead>-->
    <!--<tr>-->
    <!--<th>Name</th>-->
    <!--<th>Accounts</th>-->
    <!--<th style="text-align: right;width: 200px;">Actions</th>-->
    <!--</tr>-->
    <!--</thead>-->
    <!--<clip-loader color="#4165ed" :size="'16px'" v-if="getGroupLoader"></clip-loader>-->
    <!--<tbody v-else-if="getGroupStatus">-->

    <!--<tr v-for="group in getGroup">-->
    <!--<td>-->
    <!--<div class="profile_picture">-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{group.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</td>-->
    <!--<td>-->
    <!--<div class="accounts">-->
    <!--<div v-if="group.wordpress" class="profile_picture pin">-->
    <!--<div v-if="getWordpressPlatformByID(group.wordpress)" class="picture_block">-->
    <!--<div class="icon">-->
    <!--<i class="cs-wordpress "></i>-->
    <!--</div>-->
    <!--<img src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.tumblr.blogs" class="profile_picture pin">-->
    <!--<div v-if="getTumblrPlatformByName(group.tumblr.blogs,'Blog')">-->
    <!--<div class="picture_block">-->
    <!--<div class="icon">-->
    <!--<i class="cs-tumblr  tum_bg"></i>-->
    <!--</div>-->
    <!--<img src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.medium" class="profile_picture pin">-->
    <!--<div v-if="getMediumPlatformByID(group.medium)" >-->
    <!--<div class="picture_block">-->
    <!--<div class="icon">-->
    <!--<i class="cs-medium   medium_bg"></i>-->
    <!--</div>-->
    <!--<img :src="mediumImage(getMediumPlatformByID(group.medium))" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.facebook" class="profile_picture fb">-->
    <!--<div v-for="account in group.facebook" class="picture_block">-->
    <!--<div v-if="getFacebookPlatformByID(account)">-->
    <!--<div class="icon">-->
    <!--<i class="cs-facebook"></i>-->
    <!--</div>-->
    <!--<img v-if="facebookImage(getFacebookPlatformByID(account))"  :src="facebookImage(getFacebookPlatformByID(account))"  alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.twitter" class="profile_picture fb">-->
    <!--<div v-if="getTwitterPlatformByID(group.twitter)">-->
    <!--<div class="icon">-->
    <!--<i class="cs-twitter"></i>-->
    <!--</div>-->
    <!--<img v-if="twitterImage(getTwitterPlatformByID(group.twitter))"  :src="twitterImage(getTwitterPlatformByID(group.twitter))"  alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.linkedin" class="profile_picture fb">-->
    <!--<div v-for="account in group.linkedin" class="picture_block">-->
    <!--<div v-if="getLinkedinPlatformByID(account)">-->
    <!--<div class="icon">-->
    <!--<i class="cs-twitter "></i>-->
    <!--</div>-->
    <!--<img v-if="linkedinImage(getLinkedinPlatformByID(account))"  :src="linkedinImage(getLinkedinPlatformByID(account))"  alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.tumblr.profiles" class="profile_picture fb">-->
    <!--<div v-for="account in group.tumblr.profiles" class="picture_block">-->
    <!--<div v-if="getTumblrPlatformByName(account,'Profile')">-->
    <!--<div class="icon">-->
    <!--<i class="cs-tumblr "></i>-->
    <!--</div>-->
    <!--<img v-if="tumblrImage(getTumblrPlatformByName(account,'Profile'))"  :src="tumblrImage(getTumblrPlatformByName(account,'Profile'))"  alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div v-if="group.pinterest" class="profile_picture fb">-->
    <!--<div v-for="account in group.pinterest" class="picture_block">-->
    <!--<div v-if="getPinterestPlatformByID(account)" >-->
    <!--<div class="icon">-->
    <!--<i class="cs-pinterest"></i>-->
    <!--</div>-->
    <!--<img v-if="pinterestImage(getPinterestPlatformByID(account))"  :src="pinterestImage(getPinterestPlatformByID(account))"  alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</td>-->
    <!--<td class="text-right">-->
    <!--<div class="action_icons">-->
    <!--<i v-tooltip.top-center="'Edit'" data-toggle="modal"-->
    <!--data-target="#addGroup" @click="createGroup(group)"-->
    <!--class="icon_table edit_icon far fa-pencil">-->
    <!--</i>-->
    <!--<i v-tooltip.top-center="'Remove'" @click="deleteGroup(group._id)"-->
    <!--class="icon_table remove_icon far fa-trash">-->
    <!--</i>-->
    <!--</div>-->
    <!--</td>-->
    <!--</tr>-->
    <!--</tbody>-->
    <!--<div v-else>No groups created</div>-->

    <!--</table>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const TimeQueue = () => import('../../integration/components/dialogs/TimeQueue')
const AddGroup = () => import('./AddGroup')
export default {
  components: {
    TimeQueue,
    AddGroup
  },

  data () {
    return {}
  },

  created () {
    // this.initializeGroupSection()
    // this.fetchGroup()
  },
  mounted () {},

  computed: {
    ...mapGetters(['getGroupStatus', 'getGroup', 'getGroupLoader'])
  },

  methods: {
    ...mapActions(['setCurrentGroup']),
    createGroup (data) {
      $('#addGroup').modal('show')
      this.setCurrentGroup(data)
    }
  }
}
</script>
