import { discoveryBaseUrl, feederBaseURL } from '@src/config/api-utils'

export const influencerFolderPrefix = discoveryBaseUrl + 'influencers/list/'
export const influencerPrefix = discoveryBaseUrl + 'influencers/'
export const influencerInstagramPrefix =
  discoveryBaseUrl + 'influencers/instagram/'
export const influencerYoutubePrefix = discoveryBaseUrl + 'influencers/youtube/'

export const getArticlesURL = discoveryBaseUrl + 'content'
export const getArticleContentURL = discoveryBaseUrl + 'search/article'
export const reportPostURL = discoveryBaseUrl + 'search/article/report'
export const getFoldersURL = discoveryBaseUrl + 'folders'
export const setFavoriteURL = discoveryBaseUrl + 'folder/favorite'
export const unsetFavoriteURL = discoveryBaseUrl + 'folder/unfavorite'
export const saveFolderURL = discoveryBaseUrl + 'folders/save'
export const deleteFolderURL = discoveryBaseUrl + 'folders/delete'
export const updateFolderURL = discoveryBaseUrl + 'folders/update'
export const archivedPostURL = discoveryBaseUrl + 'search/post/archive'
export const unArchivedPostURL = discoveryBaseUrl + 'search/post/unarchive'
export const getKeywordsSuggestionURL = discoveryBaseUrl + 'search/autocomplete'
export const twitterSharesURL = discoveryBaseUrl + 'search/twitter/shares'
export const similarArticlesURL = discoveryBaseUrl + 'search/similar'

// quotes

export const getQuotesURL = discoveryBaseUrl + 'search/quotes'

// custom sidebar

export const getCustomSidebarURL = discoveryBaseUrl + 'getCustomSidebar'
export const setCustomSidebarURL = discoveryBaseUrl + 'setCustomSidebar'

// feeds

export const getFeedsAutoCompleteURL =
  discoveryBaseUrl + 'search/feeds/autocomplete'
export const getRssFeedAutoCompleteURL =
  discoveryBaseUrl + 'search/rss/autocomplete'

// topics

export const getCustomTopicsURL = discoveryBaseUrl + 'topics/all'
export const getCuratedTopicsURL = discoveryBaseUrl + 'topics/curated'
export const discoverShowURL = discoveryBaseUrl + 'topics/show'
export const getCuratedSuggestionURL =
  discoveryBaseUrl + 'topics/curated/suggestion'
export const getFollowCuratedTopicsURL =
  discoveryBaseUrl + 'topics/curated/followed'
export const followTopicURL = discoveryBaseUrl + 'topic/follow'
export const followBatchTopicsURL = discoveryBaseUrl + 'topic/followTopics'
export const unfollowTopicURL = discoveryBaseUrl + 'topic/unfollow'
export const previewSuggestionURL = discoveryBaseUrl + 'topic/preview'
export const saveCustomTopicURL = discoveryBaseUrl + 'topic/save'
export const removeCustomTopicURL = discoveryBaseUrl + 'topic/remove'
export const getContentByTopicURL = discoveryBaseUrl + 'topic/content'
export const getContentInsightsURL = discoveryBaseUrl + 'topic/insights'
export const getSearchInsightsURL = discoveryBaseUrl + 'strategyInsights'
export const unlockInsightsURL = discoveryBaseUrl + 'topic/insights/unlock'

export const getYoutubeContentByTopicURL = discoveryBaseUrl + 'topic/youtube'
export const getDailymotionContentByTopicURL =
  discoveryBaseUrl + 'topic/dailymotion'
export const previewVideoSuggestionURL =
  discoveryBaseUrl + 'topic/video/preview'

export const getTwitterContentURL = discoveryBaseUrl + 'topic/twitter'
export const getFacebookContentURL = discoveryBaseUrl + 'topic/facebook'
export const relatedTopicsURL = discoveryBaseUrl + 'topic/related'
export const getImagesContentURL = discoveryBaseUrl + 'getPhotos'
export const getPocketContentURL = discoveryBaseUrl + 'getPocketContent'
export const getFacebookPostLinkURL = discoveryBaseUrl + 'getFacebookPostLink'

export const saveFeedlyTopicsURL = discoveryBaseUrl + 'saveFeedlyTopics'

// newly implemented urls

export const getCuratedTopicByIDURL = discoveryBaseUrl + 'getCuratedTopicById'
export const getPocketContentDetailsUrl =
  discoveryBaseUrl + 'getPocketContentDetails'

// influencer

export const fetchInfluencerByKeyword = influencerPrefix + 'show'
export const fetchInfluencerStats = influencerPrefix + 'stats'
export const fetchInfluencerPreviewURL = influencerPrefix + 'preview'
export const fetchInfluencerTopMentionedURL = influencerPrefix + 'topMentioned'
export const fetchInfluencerTopSharerURL = influencerPrefix + 'sharers'
export const fetchInfluencerTweetsURL = influencerPrefix + 'tweets'
export const fetchInfluencerSharedArticlesURL = influencerPrefix + 'articles'
export const followInfluencerURL = influencerPrefix + 'follow'
export const fetchInfluencerFollowersURL = influencerPrefix + 'followers'

export const exportInfluencersURL = influencerPrefix + 'export'

export const addInfluencerURL = influencerPrefix + 'add'

export const fetchInfluencerFolderURL = influencerFolderPrefix + 'show'
export const saveInfluencerFolder = influencerFolderPrefix + 'save'
export const removeInfluencerListURL = influencerFolderPrefix + 'remove'

export const fetchInstagramInfluencerByKeyword =
  influencerInstagramPrefix + 'show'
export const fetchInstagramInfluencerStats = influencerInstagramPrefix + 'stats'
export const fetchInstagramInfluencerPreviewURL =
  influencerInstagramPrefix + 'preview'
export const fetchInstagramInfluencerTopMentionedURL =
  influencerInstagramPrefix + 'topMentioned'
export const fetchInfluencerInstagramPostsURL =
  influencerInstagramPrefix + 'posts'

// youtube
export const fetchYoutubefluencerByKeyword = influencerYoutubePrefix + 'show'
export const fetchYoutubeInfluencerStats = influencerYoutubePrefix + 'stats'
export const fetchYoutubeInfluencerPreviewURL =
  influencerYoutubePrefix + 'preview'
export const fetchYoutubeInfluencerVideosURL =
  influencerYoutubePrefix + 'videos'

// influencer shortlisting

export const saveInfluencerShortlist = influencerFolderPrefix + 'shortlist'

export const feederGroupURI = feederBaseURL + 'feeder/groups/'
export const feederAggregateURI = feederBaseURL + 'feeder/aggregate'
export const feederUserFeedURI = feederBaseURL + 'feeder/feed/user/'
export const feederPostURI = feederBaseURL + 'feeder/post/'
export const feederFeedURI = feederBaseURL + 'feeder/feed/'
export const feederOPMLURI = feederBaseURL + 'feeder/opml/'
export const feederFeedSuggestionURI = feederBaseURL + 'feeder/suggestion/'

// discover url

export const fetchDiscoverSearchURL = discoveryBaseUrl + 'v1/search'
export const fetchDiscoverSearchURLUsingKnowledgeGraph =
  discoveryBaseUrl + 'v2/search'
export const fetchDiscoverInsightsURLUsingKnowledgeGraph =
  discoveryBaseUrl + 'v2/insights'
export const fetchDiscoverInsightsURL = discoveryBaseUrl + 'v1/insights'
export const fetchDiscoverSearchHistoryURL =
  discoveryBaseUrl + 'v1/search_history'
export const deleteIndividualSearchHistoryURL =
  discoveryBaseUrl + 'v1/search_history/delete/individual'
export const deleteAllSearchHistoryURL =
  discoveryBaseUrl + 'v1/search_history/delete/all'
export const fetchCuratedTopicByTagURL = discoveryBaseUrl + 'v1/topics/find'
