import {
  getDefaultSharingProgressBar,
  getDefaultVideoSharingDetails,
  twitterSharingTypes
} from '../mutation-types'

const getDefaultTwitterSharingDetails = () => {
  return {
    message: '',
    title: '',
    description: '',
    image: [],
    video: getDefaultVideoSharingDetails(),
    url: '',
    website: '',
    image_suggestions: [],
    location: {
      id: '',
      name: ''
    }
  }
}

export default {
  state: {
    selection: getDefaultTwitterSharingDetails(),
    cancelledLink: '',
    progressBar: getDefaultSharingProgressBar(),
    retweetSelection: {
      retweetId: '',
      message: '',
      username: '',
      comment: ''
    }
  },
  actions: {
    setTwitterSharingImage ({ commit }, image) {
      commit('setTwitterSharingImage', image)
    },

    setTwitterSharingImageSuggestions ({ commit }, images) {
      commit('setTwitterSharingImageSuggestions', images)
    },

    setTwitterSharingMessage ({ commit }, message) {
      commit('setTwitterSharingMessage', message)
    },

    setTwitterSharingLink ({ commit }, url) {
      commit('setTwitterSharingLink', url)
    },

    setTwitterSharingPreview ({ commit }, preview) {
      commit('setTwitterSharingPreview', preview)
    },

    setTwitterSharingDetails ({ commit }, data) {
      commit('setTwitterSharingDetails', data)
    },

    setDefaultTwitterSharing ({ commit }) {
      commit('setDefaultTwitterSharing')
    },

    setTwitterImagePercentage ({ commit }, percent) {
      commit('setTwitterImagePercentage', percent)
    },

    setTwitterSharingNumberOfImages ({ commit }, images) {
      commit('setTwitterSharingNumberOfImages', images)
    },

    setTwitterRetweetSelection ({ commit }, selection) {
      commit('setTwitterRetweetSelection', selection)
    },

    setTwitterCancelledLink ({ commit }, link) {
      commit('setTwitterCancelledLink', link)
    }
  },
  mutations: {
    setTwitterSharingImage (state, image) {
      state.selection.image = image
    },

    setTwitterSharingImageSuggestions (state, images) {
      state.selection.image_suggestions = images
    },

    setTwitterSharingMessage (state, message) {
      state.selection.message = message
    },

    setTwitterSharingLink (state, url) {
      state.selection.url = url
    },

    setTwitterSharingPreview (state, preview) {
      if (preview) {
        state.selection.title = preview.title
        state.selection.description = preview.description
        state.selection.website = preview.website
        state.selection.image = preview.image
        state.selection.image_suggestions = preview.image_suggestions
      } else {
        state.selection.title = ''
        state.selection.description = ''
        state.selection.url = ''
        state.selection.website = ''
      }
    },

    setTwitterSharingDetails (state, data) {
      state.cancelledLink = ''
      if (data == null) {
        state.selection = getDefaultTwitterSharingDetails()
        return true
      }
      if (typeof data.location === 'undefined') { data.location = { id: '', name: '' } }
      state.selection = data
    },

    setDefaultTwitterSharing (state) {
      state.selection = getDefaultTwitterSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        numberOfImages: 0,
        video: 0
      }
    },

    setTwitterImagePercentage (state, percent) {
      state.progressBar.image = percent
    },

    setTwitterSharingNumberOfImages (state, images) {
      state.progressBar.numberOfImages = images
    },

    setTwitterRetweetSelection (state, selection) {
      state.retweetSelection = selection
    },

    setTwitterCancelledLink (state, link) {
      state.cancelledLink = link
    },

    SET_TWITTER_SHARING_VIDEO (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultVideoSharingDetails()
      }
    },

    SET_TWITTER_SHARING_VIDEO_INITIALIZATION (state) {
      state.selection.title = ''
      state.selection.description = ''
      state.selection.url = ''
      state.selection.website = ''
      state.selection.image = []
      state.selection.image_suggestions = []
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    SET_TWITTER_SHARING_VIDEO_PERCENTAGE (state, percent) {
      state.progressBar.video = percent
    },

    SET_TWITTER_SHARING_VIDEO_THUMBNAIL (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    SET_TWITTER_SHARING_VIDEO_SUGGESTIONS (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },

    [twitterSharingTypes.SET_LOCATION] (state, location) {
      state.selection.location = location
    },

    [twitterSharingTypes.RESET_TWITTER_SHARING_DETAILS] (state) {
      state.selection = getDefaultTwitterSharingDetails()
      state.progressBar = getDefaultSharingProgressBar()
    }
  },
  getters: {
    getTwitterSharingDetails: (state) => {
      return state.selection
    },

    getTwitterSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getTwitterSharingProgressBar: (state) => {
      return state.progressBar
    },

    getTwitterRetweetSelection: (state) => {
      return state.retweetSelection
    }
  }
}
