var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll_content influencer-tweets social_preview_no_tab"},[_c('instagram-video-preview',{attrs:{"loader":_vm.loaderValue,"asset_url":_vm.asset_url,"notFound":_vm.notFound,"videoValue":_vm.videoCode,"videoHeight":_vm.videoHeight,"videoWidth":_vm.videoWidth}}),(_vm.getInfluencerLoaders.posts)?[_c('beat-loader',{staticClass:"pt-4",attrs:{"color":'#436aff'}})]:(_vm.posts && _vm.posts.length > 0)?[_c('div',{staticClass:"influencer-instagram-posts clear"},[_vm._l((_vm.posts),function(post,index){return _c('div',{key:index,staticClass:"instagram_post_preview_main",staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"instagram_post_preview"},[_c('div',{staticClass:"sec_padding head flex"},[_c('div',{staticClass:"profile_picture d-flex align-items-center"},[_c('div',{staticClass:"picture_block"},[_c('img',{attrs:{"src":_vm.preview.profile_image,"alt":""}})]),_c('div',{staticClass:"text_block"},[_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.preview.full_name)}})])]),(_vm.platform == 'instagram')?[_c('a',{staticClass:"btn ml-auto",attrs:{"target":"_blank","href":post.permalink}},[_c('i',{staticClass:"far fa-external-link-square-alt text-lg"})])]:_vm._e()],2),_c('div',{staticClass:"preview_images"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.preview.profile_th)}}),(post.is_video)?_c('div',{staticClass:"img play_icon instacover",style:({
                background: 'url(https://storage.googleapis.com/contentstudio-media-library-nearline/media_library/602a03c8a3f9461870212fd2/uncategorized/original/C5Nq95D2yp72sJ2p9GLT8rfXkpREkHFyuT3JaoL7.jpg)'
                  // 'url(https://instagram.com/p/' +
                  // post.short_code +
                  // '/media/?size=m)',
              })},[_c('i',{staticClass:"fal fa-play-circle",on:{"click":function($event){return _vm.showInstagramVideoAsset(post.asset_url)}}})]):_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(post.asset_url),expression:"post.asset_url",arg:"background-image"}],staticClass:"img"})]),_c('div',{staticClass:"sec_padding preview_desc",domProps:{"innerHTML":_vm._s(post.description.substring(0, 350) + '...')}}),_c('div',{staticClass:"social_stats"},[_c('div',{staticClass:"social_stats_inner d-flex"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Likes'),expression:"'Likes'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(0,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(post.likes)))])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Comments'),expression:"'Comments'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(1,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(post.comments)))])])]),(post.is_video)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Video views'),expression:"'Video views'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(2,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(post.video_view_count)))])])]):_vm._e()])])])])}),_c('InfiniteLoading',{ref:"infiniteLoading",attrs:{"direction":"bottom"},on:{"infinite":_vm.infiniteHandler}},[_c('span',{staticClass:"pt-3 d-block",attrs:{"slot":"spinner"},slot:"spinner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)])],2)]:[_vm._m(3)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"fal fa-heart"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"fal fa-comment"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"fal fa-eye"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"not_found_text pt-2"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',[_vm._v("No data found.")])])])])
}]

export { render, staticRenderFns }