<template>
  <div>
    <template v-if="isTopic || automation">
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first far fa-globe"></i>
        <span class="text">{{ getTopicsRegion.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="inner">
          <li
            class="list_item"
            v-for="region in sortedRegions"
            @click.prevent="changeRegion('topics', region)"
            :value="region.code"
          >
            <a href="#">{{ region.name }}</a>
          </li>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first far fa-globe"></i>
        <span class="text">{{ getArticlesRegion.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="inner">
          <li
            class="list_item"
            v-for="region in sortedRegions"
            @click.prevent="changeRegion('articles', region)"
            :value="region.code"
          >
            <a href="#">{{ region.name }}</a>
          </li>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { filterLimitedRegions } from '@common/constants/regions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import orderBy from 'lodash/orderBy'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getArticlesRegion', 'getTopicsRegion']),
    sortedRegions () {
      const regionsList = orderBy(
        filterLimitedRegions.slice(1, filterLimitedRegions.length),
        ['name'],
        ['asc']
      )
      regionsList.unshift(filterLimitedRegions[0])
      return regionsList
    }
  },
  methods: {
    ...mapActions([
      'articles',
      'getContent',
      'topicsContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations(['SET_TOPICS_REGION', 'SET_TOPICS_LANGUAGE']),

    async changeRegion (type, value) {
      switch (type) {
        case 'topics':
          this.SET_TOPICS_REGION(value)
          this.SET_TOPICS_LANGUAGE()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        case 'articles':
          await this.getContent({ filter: { key: 'region', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        default:
          break
      }
    }
  }
}
</script>
