import { storeTriggerURL } from '@src/modules/setting/config/api-utils'
import http from '../lib/http-common'
import { draftConfirmationTypes } from './mutation-types'

export default {
  state: {
    confirmAction: {
      data: '',
      stage: '',
    },
    draftCancel: false,
    draftClickAway: false,
  },
  actions: {
    triggerAction({ commit, getters, dispatch }, type) {
      http
        .post(storeTriggerURL, { type: type })
        .then((res) => {})
        .catch(() => {})
    },

    setConfirmActionData({ commit }, data) {
      commit('setConfirmActionData', data)
    },

    setConfirmActionStage({ commit }, stage) {
      commit('setConfirmActionStage', stage)
    },

    setDraftCancelAction({ commit }, status) {
      commit('setDraftCancelAction', status)
    },
  },
  mutations: {
    setConfirmActionData(state, data) {
      state.confirmAction.data = data
    },

    setConfirmActionStage(state, stage) {
      state.confirmAction.stage = stage
    },
    setDraftCancelAction(state, status) {
      state.draftCancel = status
    },
    [draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION](state, value) {
      state.draftCancel = value
    },
    [draftConfirmationTypes.SET_DRAFT_CLICKAWAY](state, value) {
      state.draftClickAway = value
    },
  },
  getters: {
    getConfirmActionDetails: (state) => {
      return state.confirmAction
    },
    getDraftCancelAction: (state) => {
      return state.draftCancel
    },
    getDraftClickAway: (state) => {
      return state.draftClickAway
    },
  },
}
