<template>
  <div>
    <video-preview></video-preview>
    <div
      class="article_box_grid video_box_grid"
      v-for="(video, index) in getVideos"
      :key="index"
    >
      <div class="box_inner">
        <div class="image_block">
          <div
            v-if="video.snippet.thumbnails.maxres"
            class="img"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.maxres, 'youtube')
            "
          ></div>
          <div
            v-else-if="video.snippet.thumbnails.high"
            class="img"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.high, 'youtube')
            "
          ></div>
          <div
            v-else-if="video.snippet.thumbnails.default"
            class="img"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.default, 'youtube')
            "
          ></div>
        </div>
        <div class="content_block">
          <div class="content_block_height">
            <h2
              @click.prevent="
                previewVideo(
                  video.snippet.title,
                  video.snippet.description,
                  videoEmbedUrl(video.id, 'youtube'),
                  'Youtube'
                )
              "
              v-html="video.snippet.title"
              >{{ video.snippet.title }}</h2
            >
            <div class="detail clear">
              <div class="author_detail">
                <p>{{ videoDate('youtube', video.snippet.publishedAt) }}</p>
                <p
                  >By <span>{{ video.snippet.channelTitle }}</span></p
                >
              </div>
            </div>
          </div>
          <div class="social_stats">
            <div class="social_stats_inner d-flex">
              <div class="stat_item">
                <div class="name">
                  <p>View Count</p>
                </div>
                <div class="value">
                  <h3>{{ intToString(video.statistics.viewCount) }}</h3>
                </div>
              </div>
              <div class="stat_item">
                <div class="name">
                  <p>Like Count</p>
                </div>
                <div class="value">
                  <h3>{{ intToString(video.statistics.likeCount) }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_block d-flex">
            <div class="btn_block_inner">
              <div
                class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
              >
                <div class="dropdown_header" data-toggle="dropdown">
                  <button
                    class="btn light_gray"
                    v-tooltip.top-center="'Share this post'"
                  >
                    <i class="icon-Share_icon d-block"></i>
                    <!--<span class="circle_count">7</span>-->
                  </button>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner">
                    <li
                      class="list_item_li"
                      @click.prevent="
                        initializeVideoPostCreation(video, 'social_share_modal')
                      "
                    >
                      <i class="cs-social-share"></i>
                      <span class="icon_text">Social Media</span>
                    </li>
                    <li
                      class="list_item_li"
                      @click.prevent="
                        initializeVideoPostCreation(video, 'blog_share_modal')
                      "
                    >
                      <i class="cs-rss"></i>
                      <span class="icon_text">Blog Post</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="inline_block dropdown default_style_dropdown">
                <a
                  target="_blank"
                  class="btn light_gray"
                  :href="
                    savePocketContent(
                      'https://www.youtube.com/watch?v=' + video.id
                    )
                  "
                  v-tooltip.top-center="'Save to Pocket'"
                >
                  <i class="cs-pocket d-block"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPreview from './preview/VideoPreview'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Youtube',
  components: {
    VideoPreview
  },
  computed: {
    ...mapGetters(['getVideos'])
  },
  created () {},
  methods: {
    ...mapActions([
      'setVideos',
      'setVideoPage',
      'setVideoScroll',
      'setDiscoveryVideoLoader',
      'setVideoPaginationLoader',
      'setVideoPageToken'
    ])
  }
}
</script>
