<template>
  <div class="ds_inner_pages article-filters-pane">
    <!-- TODO: add the show condition -->

    <div class="ds_article_filter ds_article_filter_updated">
      <div class="d-flex align-items-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
