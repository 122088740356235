var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max_container_1800 automation_main_component"},[_c('div',{staticClass:"component_inner"},[_c('RemoveCampaign'),_c('ConfirmBox',{attrs:{"id":"videoAutomationBulkOperation","confirm-text":"Yes","cancel-text":"No","call-back-action":_vm.videoAutomationBulkOperation}},[_c('template',{slot:"header"},[_c('h2',[_vm._v("Perform bulk operation")])]),_c('template',{slot:"footer"},[_c('p',{staticClass:"text-center"},[_vm._v("This action cannot be undone.")])])],2),_c('div',{staticClass:"top_content_listing"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"col-md-4 text-left nopad-l"},[_c('router-link',{staticClass:"btn btn-studio-theme-grey-space",attrs:{"to":{
              name: 'automation',
              params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
            }}},[_c('i',{staticClass:"cs-angle-left icon_left arrow_right_active",staticStyle:{"color":"#ffffff"}}),_c('span',[_vm._v("Back")])])],1),_c('h2',{staticClass:"col-md-4 text-center"},[_vm._v("View Existing Campaigns")]),_c('p',{staticClass:"col-md-4 text-right nopad-r"},[_vm._v("Timezone: "+_vm._s(_vm.getWorkspaces.activeWorkspace.timezone)+" ( "),_c('clock',{attrs:{"time-format":"hh:mm:ss A","timezone":_vm.getWorkspaces.activeWorkspace.timezone}}),_vm._v(" ) ")],1)]),_c('img',{attrs:{"src":require("../../../../../assets/img/automation/video_blog.svg"),"alt":""}}),_c('h3',[_vm._v("Videos to Blog")]),_c('p',[_vm._v("Share videos on your blog with smart rules and filters.")])]),_c('div',{staticClass:"automation_listing"},[_c('div',{staticClass:"page_inner"},[_c('ListingFilters',{attrs:{"type":"Blog"}}),_c('div',{staticClass:"table_box"},[_c('table',{staticClass:"table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('div',{staticClass:"field_group"},[_c('div',{staticClass:"checkbox_container"},[_c('label',{class:{
                          disabled:
                            _vm.getVideoBlogAutomationListing.items.length < 1,
                        },attrs:{"for":"select_all"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getVideoAutomationListing.select_all),expression:"getVideoAutomationListing.select_all"}],attrs:{"id":"select_all","type":"checkbox","disabled":_vm.getVideoBlogAutomationListing.items.length < 1},domProps:{"checked":Array.isArray(_vm.getVideoAutomationListing.select_all)?_vm._i(_vm.getVideoAutomationListing.select_all,null)>-1:(_vm.getVideoAutomationListing.select_all)},on:{"change":[function($event){var $$a=_vm.getVideoAutomationListing.select_all,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.getVideoAutomationListing, "select_all", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.getVideoAutomationListing, "select_all", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.getVideoAutomationListing, "select_all", $$c)}},_vm.changeVideoAutomationSelectAllStatus]}}),_c('span',{staticClass:"check"})])])])]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th',[_vm._v("Name")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"200px"}},[_vm._v("Platforms Selected")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"200px"}},[_vm._v("Next Refill Time "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
                      'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'
                    ),expression:"\n                      'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'\n                    ",modifiers:{"top-center":true}}],staticClass:"fas fa-info-circle",staticStyle:{"margin-left":"5px"}})]),_c('th',{staticClass:"text-center"},[_vm._v("Published Posts")]),_c('th',{staticStyle:{"text-align":"center","width":"200px"}},[_vm._v("Actions")])])]),_c('tbody',[(
                  _vm.getAutomationLoaders.fetchVideoBlogAutomations ||
                  _vm.getAutomationLoaders.fetchVideoAutomation
                )?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):(_vm.getVideoBlogAutomationListing.items.length)?[_vm._l((_vm.getVideoBlogAutomationListing.items),function(automation,index){return [_c('tr',{key:index,class:{ pause_automation: !automation.status }},[_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"field_group"},[_c('div',{staticClass:"checkbox_container"},[_c('label',{attrs:{"for":index}},[_c('input',{attrs:{"id":index,"type":"checkbox","disabled":automation.lock !== 0},domProps:{"checked":_vm.getVideoAutomationListing.selected.includes(
                                  automation._id
                                )},on:{"change":function($event){return _vm.changeAutomationSelection(
                                  _vm.getVideoAutomationListing.selected,
                                  automation._id,
                                  'Video'
                                )}}}),_c('span',{staticClass:"check"})])])])]),_c('td',{staticClass:"text-center d-flex justify-content align-items-center"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Toggle ON/OFF'),expression:"'Toggle ON/OFF'",modifiers:{"top-center":true}}],staticClass:"switch-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(automation.status),expression:"automation.status"}],attrs:{"id":"automation_toggle","type":"checkbox","disabled":automation.lock !== 0},domProps:{"checked":Array.isArray(automation.status)?_vm._i(automation.status,null)>-1:(automation.status)},on:{"change":[function($event){var $$a=automation.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(automation, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(automation, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(automation, "status", $$c)}},function($event){return _vm.changeVideoAutomationStatus(
                              automation,
                              _vm.youtubeKey
                            )}]}}),_c('div',{staticClass:"slider round",class:{ disabled: automation.lock !== 0 }})]),_c('v-popover',{attrs:{"placement":"top-center","container":'#errortooltip-' + automation._id,"trigger":"hover","popover-class":"tooltip-danger"}},[(
                            !automation.lock &&
                            (automation.state === 'limit_exceeded' ||
                              automation.state ===
                                'partial_content_planned' ||
                              automation.state === 'empty_token' ||
                              automation.state === 'invalid_token')
                          )?_c('i',{staticClass:"fas fa-exclamation-triangle ml-2",staticStyle:{"color":"#f43838","font-size":"0.84rem","cursor":"pointer"},attrs:{"id":'errortooltip-' + automation._id}}):_vm._e(),(automation.state === 'empty_token')?_c('template',{slot:"popover"},[_vm._v(" You do not have any YouTube Data API Key, please add one by going to Settings > Other Integrations. You can "),_c('router-link',{attrs:{"to":{
                              name: 'integrations',
                              params: {
                                workspace: _vm.getWorkspaces.activeWorkspace.slug,
                              },
                            }}},[_vm._v("Click here")]),_vm._v(" to add your API key. ")],1):(automation.state === 'limit_exceeded')?_c('template',{slot:"popover"},[_vm._v(" You have reached YouTube API quota limit for today, your campaign will be running again at your next refill time. Alternatively, you can restart your campaign if you want to schedule new posts. ")]):(
                            automation.state === 'partial_content_planned'
                          )?_c('template',{slot:"popover"},[_vm._v(" Your posts have been scheduled but we have reached YouTube Quota for your campaign. You may see less no. of scheduled posts for your campaign due to this. ")]):(automation.state === 'invalid_token')?_c('template',{slot:"popover"},[_vm._v(" Your YouTube Data API Key is invalid. Please make sure to add a correct YouTube API key. ")]):_vm._e()],2)],1),_c('td',[_vm._v(_vm._s(automation.name))]),_c('SelectedPlatforms',{attrs:{"blog_selection":automation.blog_selection,"account_selection":automation.account_selection}}),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.automationNextRefill(automation, 'VideoAutomation')))]),(automation.posts)?_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(automation.posts))]):_c('td',{staticClass:"text-center"},[_vm._v("0")]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex align-items-center"},[(automation.lock)?_c('clip-loader',{staticClass:"inline_loader",attrs:{"color":"#4165ed","size":'16px'}}):_vm._e(),[(
                              automation.status &&
                              !automation.lock &&
                              (automation.state === 'limit_exceeded' ||
                                automation.state ===
                                  'partial_content_planned' ||
                                automation.state === 'invalid_token')
                            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Restart Automation'),expression:"'Restart Automation'",modifiers:{"top-center":true}}],staticClass:"action_icon sm cs-refresh",on:{"click":function($event){return _vm.restartVideoAutomation(
                                automation._id,
                                automation.name
                              )}}}):_vm._e(),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('View Posts'),expression:"'View Posts'",modifiers:{"top-center":true}}],staticClass:"action_icon sm icon-Listview_icon",on:{"click":function($event){return _vm.viewAutomationPosts(
                                automation._id,
                                'video_automation'
                              )}}}),(
                              automation.policy && automation.policy.can_edit
                            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Edit'),expression:"'Edit'",modifiers:{"top-center":true}}],staticClass:"action_icon sm icon-edit-cs",on:{"click":function($event){return _vm.fetchVideoAutomation(automation._id)}}}):_vm._e(),(
                              automation.policy && automation.policy.can_edit
                            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Duplicate'),expression:"'Duplicate'",modifiers:{"top-center":true}}],staticClass:"action_icon sm fa fa-files-o",on:{"click":function($event){return _vm.fetchVideoAutomation(automation._id, true)}}}):_vm._e(),(
                              automation.policy &&
                              automation.policy.can_remove
                            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"action_icon sm far fa-trash",on:{"click":function($event){$event.preventDefault();_vm.confirmAction(
                                'removeVideoAutomation',
                                _vm.removeVideoAutomationPayload(
                                  automation,
                                  index
                                )
                              )}}}):_vm._e()]],2)])],1)]})]:_c('tr',[(_vm.getVideoBlogAutomationListing.search)?_c('td',{staticClass:"text-center"},[_vm._v("No results found for your search query, please try again.")]):_c('td',{staticClass:"text-center",attrs:{"colspan":"12"}},[_vm._v("You have not created any automation yet. ")])])],2)])]),(
            _vm.getVideoBlogAutomationListing.nextPage ||
            _vm.getVideoBlogAutomationListing.previousPage
          )?_c('div',{staticClass:"field_group text-center"},[_c('button',{staticClass:"btn btn-studio-theme-transparent-grey mr-1",attrs:{"disabled":!_vm.getVideoBlogAutomationListing.previousPage},on:{"click":function($event){return _vm.processVideoBlogAutomationPagination('previous')}}},[_c('i',{staticClass:"icon_left cs-angle-left"}),_c('span',[_vm._v("Prev")])]),_c('button',{staticClass:"btn btn-studio-theme-space",attrs:{"disabled":!_vm.getVideoBlogAutomationListing.nextPage},on:{"click":function($event){return _vm.processVideoBlogAutomationPagination('next')}}},[_c('span',[_vm._v("Next")]),_c('i',{staticClass:"icon_right arrow_right_active cs-angle-right"})])]):_vm._e()],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }