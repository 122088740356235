import {
  blogPosting,
  blogPostingTypes
} from '@src/modules/publish/store/states/mutation-types'
import moment from 'moment'
import proxy from '@common/lib/http-common'
import {
  UNKNOWN_ERROR,
  BLOG_CONNECTION_ERROR
} from '@common/constants/messages'
import { fetchBlogOptionsURL } from '../../config/api-utils'
import { blogIntegrationsConfigrations } from '../../../integration/config/api-utils'

const getDefaultBlogOptions = () => {
  const options = {}
  for (const key in blogIntegrationsConfigrations) {
    options[key] = JSON.parse(
      JSON.stringify(blogIntegrationsConfigrations[key].posting_options)
    )
  }
  return options
}

const getDefaultBlogSelection = () => {
  const options = {}
  for (const key in blogIntegrationsConfigrations) {
    options[key] = JSON.parse(
      JSON.stringify(blogIntegrationsConfigrations[key].posting_selection)
    )
  }
  return options
}

const getDefaultBlogPostingDetails = () => {
  return {
    stage: 'content',
    type: '',
    title: '',
    optionalDescription: '',
    html: '',
    link: '',
    image: {
      source: 'Dropzone',
      link: ''
    },
    metaDescription: '',
    metaTitle: '',
    metaUrl: '',
    customizeSeoPreview: false,
    id: '',
    blogDropzoneStatus: false,
    post_variations: 'post_title',
    words_count:0
  }
}

const getDefaultPrimaryTime = () => {
  return {
    time_type: 'now',
    postSelectedDate: ''
  }
}

const getDefaultEditValues = () => {
  return {
    primary: false,
    secondary: false,
    secondaryIndex: null,
    secondaryAccountId: null
  }
}

const getBlogPrimarySecondaryDefaultSelection = () => {
  return {
    platform_name: '',
    primaryBlog: getDefaultBlogSelection(),
    primaryTime: getDefaultPrimaryTime(),
    primaryBlogOption: getDefaultBlogOptions(),
    secondaryBlog: [],
    secondaryAccountsId: []
  }
}

export default {
  state: {
    options: getDefaultBlogOptions(),
    selection: getDefaultBlogSelection(),
    details: getDefaultBlogPostingDetails(),
    blogContentEmpty: false,
    blogSelection: getBlogPrimarySecondaryDefaultSelection(),
    edit: getDefaultEditValues(),
    loaders: {
      upload_blog_image: false
    },
    webflowCollections: [],
    webflowLoading: false
  },
  getters: {
    getBlogOptions: (state) => state.options,
    getSingleBlogSelection: (state) => state.selection,
    getBlogPostingDetails: (state) => state.details,
    getBlogPostingTitle: (state) => state.details.title,
    getBlogPostingHTML: (state) => state.details.html,
    getBlogPostingCustomizeSeoPreviewStatus: (state) =>
      state.details.customizeSeoPreview,
    getBlogPostingMetaTitle: (state) => state.details.metaTitle,
    getBlogPostingMetaDescription: (state) => state.details.metaDescription,
    getBlogPostingContentExistenceStatus: (state) => state.blogContentEmpty,
    getBlogSelection: (state) => state.blogSelection,
    getSecondaryBlogSelection: (state) => state.blogSelection.secondaryBlog,
    getBlogSelectionEdit: (state) => state.edit,
    getBlogPostingLoaders: (state) => state.loaders,
    getWebflowCollections: (state) => state.webflowCollections,
    getWebflowLoading: (state) => state.webflowLoading,
    getWordsCount:(state)=>state.details.words_count
  },
  actions: {
    async processPrimarySecondaryBlogSelection (
      { commit, getters, rootGetters, dispatch },
      selection
    ) {
      console.debug('Action::processPrimarySecondaryBlogSelection', selection)
      if (selection && selection.primary_blog) {
        if (selection.primary_blog.selection) {
          commit(
            blogPosting.SET_PRIMARY_BLOG_SELECTION,
            selection.primary_blog.selection
          )
          commit(
            blogPosting.SET_PRIMARY_BLOG_OPTION,
            await dispatch('processOptionsBaseOnSelection', {
              item: selection.primary_blog,
              type: 'Primary'
            })
          )
        } else {
          commit(blogPosting.SET_PRIMARY_BLOG_SELECTION, null)
          commit(blogPosting.SET_PRIMARY_BLOG_OPTION, null)
        }

        if (selection.primary_blog.time) {
          const primaryTime = selection.primary_blog.time
          if (
            primaryTime.time_type === 'schedule' &&
            selection.primary_blog.execution_time
          ) {
            primaryTime.postSelectedDate = moment
              .utc(
                selection.primary_blog.execution_time.date,
                'YYYY-MM-DD HH:mm:ss'
              )
              .tz(getters.getWorkspaces.activeWorkspace.timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          }
          commit(blogPosting.SET_PRIMARY_BLOG_TIME, primaryTime)
        } else {
          commit(blogPosting.SET_PRIMARY_BLOG_TIME, null)
        }
      } else {
        commit(blogPosting.RESET_PRIMARY_BLOG_SELECTION)
      }

      const secondaryBlog = []
      if (selection && selection.secondary_blog) {
        selection.secondary_blog.forEach(async (element, index) => {
          const blog = {}
          blog.option = await dispatch('processOptionsBaseOnSelection', {
            item: element,
            type: 'Secondary'
          })
          for (const key in blogIntegrationsConfigrations) {
            if (!element.selection[key]) {
              element.selection[key] = JSON.parse(
                JSON.stringify(
                  blogIntegrationsConfigrations[key].posting_selection
                )
              )
            }
          }
          blog.selection = element.selection
          blog.status = element.status
          blog.id = element.id
          blog.execution_time = element.execution_time
          if (element.time) {
            const secondaryTime = element.time
            if (
              secondaryTime.time_type === 'schedule' &&
              element.execution_time
            ) {
              secondaryTime.postSelectedDate = moment
                .utc(element.execution_time.date, 'YYYY-MM-DD HH:mm:ss')
                .tz(getters.getWorkspaces.activeWorkspace.timezone)
                .format('YYYY-MM-DD HH:mm:ss')
            }
            blog.time = secondaryTime
          } else {
            blog.time = {
              time_type: 'now',
              postSelectedDate: ''
            }
          }
          secondaryBlog.push(blog)
        })
      }
      console.debug('secondaryBlog', secondaryBlog)
      commit(blogPosting.SET_SECONDARY_BLOG_SELECTION, secondaryBlog)
    },

    async processOptionsBaseOnSelection (
      { commit, getters, rootGetters, dispatch },
      details
    ) {
      console.debug('Action::processOptionsBaseOnSelection', details)

      let primary = ''
      const options = getDefaultBlogOptions()

      if (
        details.item.selection &&
        details.item.selection.wordpress &&
        details.item.selection.wordpress.website
      ) {
        primary = 'wordpress'
        options.wordpress.selection = true
        if (details.item.authors && details.item.categories) {
          options.wordpress.authors = details.item.authors
          options.wordpress.categories = details.item.categories
          options.wordpress.siteStatus = true
        }
      }

      if (
        details.item.selection &&
        details.item.selection.tumblr &&
        details.item.selection.tumblr.website
      ) {
        primary = 'tumblr'
        options.tumblr = true
      }

      if (
        details.item.selection &&
        details.item.selection.medium &&
        details.item.selection.medium.website
      ) {
        primary = 'medium'
        options.medium = true
      }

      if (
        details.item.selection &&
        details.item.selection.shopify &&
        details.item.selection.shopify.website
      ) {
        primary = 'shopify'
        options.shopify.selection = true
        if (details.item.authors && details.item.tags) {
          options.shopify.authors = details.item.authors
          options.shopify.tags = details.item.tags
          options.shopify.site_status = true
        }
      }

      if (
        details.item.selection &&
        details.item.selection.webflow &&
        details.item.selection.webflow.website
      ) {
        primary = 'webflow'
        options.webflow = true
      }

      if (details.type === 'Primary') { commit(blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME, primary) }
      return options
    },

    async removeInvalidSelection (
      { commit, getters, rootGetters, dispatch },
      details
    ) {
      console.debug('Action::removeInvalidSelection', details)
      const defaultValue = getDefaultBlogSelection()
      defaultValue[details.selected_type] =
        details.selection[details.selected_type]
      console.debug('defaultValue', defaultValue)
      return defaultValue
    },

    /**
     *
     * @param getters
     * @param type string automation or blog_post
     * @returns {{primary_blog: {selection: ({wordpress: {website: string, author: string, categories: Array, status: string}, tumblr: {website: string}, medium: {website: string, repurpose: boolean, status: string}}|*)}, secondary_blog: Array}}
     */
    blogSelectionForPrimarySecondary ({ getters }, type) {
      const blogSelection = getters.getBlogSelection
      const primaryBlog = {
        selection: blogSelection.primaryBlog
      }

      if (type !== 'automation') {
        primaryBlog.time = blogSelection.primaryTime
      }

      const secondaryBlog = []

      blogSelection.secondaryBlog.forEach(function (element, index) {
        const blog = {
          selection: element.selection
        }

        if (type !== 'automation') blog.time = element.time
        if (type === 'blog_post') {
          blog.id = element.id
          blog.status = element.status
        }
        secondaryBlog.push(blog)
      })

      return {
        primary_blog: primaryBlog,
        secondary_blog: secondaryBlog
      }
    },

    async fetchBlogOptions (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Action::fetchBlogOptions', payload)
      console.log('Action::fetchBlogOptions', payload)

      switch (payload.type) {
        case 'wordpress':
          commit(blogPosting.SET_WORDPRESS_AUTHORS, [])
          commit(blogPosting.SET_WORDPRESS_CATEGORIES, [])
          commit(blogPosting.SET_WORDPRESS_SELECTED_AUTHOR, '')
          commit(blogPosting.SET_WORDPRESS_SELECTED_CATEGORIES, [])
          if (!payload.platform_identifier) { commit(blogPosting.SET_WORDPRESS_SITE_STATUS, false) }
          break
        case 'shopify':
          commit(blogPosting.SET_SHOPIFY_AUTHORS, [])
          commit(blogPosting.SET_SHOPIFY_TAGS, [])
          commit(blogPosting.SET_SHOPIFY_SELECTED_AUTHOR, '')
          commit(blogPosting.SET_SHOPIFY_SELECTED_TAGS, [])
          if (!payload.platform_identifier) { commit(blogPosting.SET_SHOPIFY_SITE_STATUS, false) }
          break
        case 'webflow':
          commit(blogPosting.SET_WEBFLOW_COLLECTIONS, [])
          commit(blogPosting.SET_WEBFLOW_LOADING, true)
          break
      }
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
      return proxy
        .post(fetchBlogOptionsURL, payload)
        .then((response) => {
          commit(blogPosting.SET_WEBFLOW_LOADING, false)
          if (response.data.status === true) {
            switch (payload.type) {
              case 'wordpress':
                commit(blogPosting.SET_WORDPRESS_AUTHORS, response.data.authors)
                commit(
                  blogPosting.SET_WORDPRESS_CATEGORIES,
                  response.data.categories
                )
                commit(blogPosting.SET_WORDPRESS_SITE_STATUS, true)
                break
              case 'shopify':
                const authors = response.data.authors
                const name = getters.getProfile.full_name
                if (!authors.includes(name)) authors.push(name)
                commit(blogPosting.SET_SHOPIFY_AUTHORS, authors)
                commit(blogPosting.SET_SHOPIFY_TAGS, response.data.tags)
                commit(blogPosting.SET_SHOPIFY_SITE_STATUS, true)
                break
              case 'webflow':
                commit(
                  blogPosting.SET_WEBFLOW_COLLECTIONS,
                  JSON.parse(response.data.collections)
                )
            }
            return true
          }
          switch (payload.type) {
            case 'wordpress':
              commit(blogPosting.SET_WORDPRESS_SITE_STATUS, false)
              break
            case 'shopify':
              commit(blogPosting.SET_SHOPIFY_SITE_STATUS, false)
              break
          }
          dispatch('toastNotification', {
            message: response.data.message
              ? response.data.message
              : BLOG_CONNECTION_ERROR,
            type: 'error'
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in fetchBlogOptions', error)
          switch (payload.type) {
            case 'wordpress':
              commit(blogPosting.SET_WORDPRESS_SITE_STATUS, false)
              break
            case 'shopify':
              commit(blogPosting.SET_SHOPIFY_SITE_STATUS, false)
              break

            case 'webflow':
              commit(blogPosting.SET_WEBFLOW_LOADING, false)
              break
          }
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    }
  },
  mutations: {
    [blogPosting.SET_WORDPRESS_AUTHORS] (state, authors) {
      state.options.wordpress.authors = authors
    },

    [blogPosting.SET_WORDPRESS_CATEGORIES] (state, categories) {
      state.options.wordpress.categories = categories
    },

    [blogPosting.SET_WORDPRESS_SITE_STATUS] (state, status) {
      state.options.wordpress.siteStatus = status
    },

    [blogPosting.SET_WORDPRESS_SELECTED_AUTHOR] (state, author) {
      state.selection.wordpress.author = author
    },

    [blogPosting.SET_WORDPRESS_SELECTED_CATEGORIES] (state, categories) {
      state.selection.wordpress.categories = categories
    },

    [blogPosting.SET_SHOPIFY_AUTHORS] (state, authors) {
      state.options.shopify.authors = authors
    },

    [blogPosting.SET_SHOPIFY_TAGS] (state, tags) {
      state.options.shopify.tags = tags
    },

    [blogPosting.SET_SHOPIFY_SITE_STATUS] (state, status) {
      state.options.shopify.site_status = status
    },

    [blogPosting.SET_SHOPIFY_SELECTED_AUTHOR] (state, author) {
      state.selection.shopify.author = author
    },

    [blogPosting.SET_SHOPIFY_SELECTED_TAGS] (state, tags) {
      state.selection.shopify.tags = tags
    },

    [blogPosting.SET_BLOG_OPTIONS] (state, options) {
      state.options = options
    },

    [blogPosting.SET_BLOG_SELECTION] (state, selection) {
      state.selection = selection
    },

    [blogPosting.RESET_BLOG_SELECTION] (state) {
      console.debug('getDefaultBlogSelection', getDefaultBlogSelection())
      console.debug('getDefaultBlogOptions', getDefaultBlogOptions())
      state.selection = getDefaultBlogSelection()
      state.options = getDefaultBlogOptions()
    },

    [blogPosting.SET_BLOG_POSTING_STAGE] (state, stage) {
      state.details.stage = stage
    },

    [blogPosting.SET_BLOG_POSTING_TYPE] (state, type) {
      state.details.type = type
    },

    [blogPosting.SET_BLOG_POSTING_TITLE] (state, title) {
      state.details.title = title
    },

    [blogPosting.SET_BLOG_POSTING_HTML] (state, html) {
      state.details.html = html
    },
    [blogPosting.SET_WORDS_COUNT] (state, words) {
      state.details.words_count = words
    },
    [blogPosting.SET_BLOG_POSTING_LINK] (state, link) {
      state.details.link = link
    },

    [blogPosting.SET_BLOG_POSTING_IMAGE] (state, image) {
      if (image) {
        state.details.image = image
        return true
      }
      state.details.image = { source: 'Dropzone', link: '' }
    },

    [blogPosting.SET_BLOG_POSTING_OPTIONAL_DESCRIPTION] (state, desc) {
      state.details.optionalDescription = desc
    },

    [blogPosting.SET_BLOG_POSTING_CUSTOMIZE_SEO_PREVIEW_STATUS] (state, status) {
      state.details.customizeSeoPreview = status
    },

    [blogPosting.SET_BLOG_POSTING_META_TITLE] (state, title) {
      state.details.metaTitle = title
    },

    [blogPosting.SET_BLOG_POSTING_META_URL] (state, url) {
      state.details.metaUrl = url
    },

    [blogPosting.SET_BLOG_POSTING_META_DESCRIPTION] (state, description) {
      state.details.metaDescription = description
    },

    [blogPosting.SET_BLOG_POSTING_ID] (state, id) {
      state.details.id = id
    },

    [blogPosting.SET_BLOG_POSTING_DROPZONE_STATUS] (state, status) {
      state.details.blogDropzoneStatus = status
    },

    [blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS] (state, status) {
      state.blogContentEmpty = status
    },

    [blogPosting.RESET_BLOG_SELECTION_EDIT_STATES] (state) {
      state.edit = getDefaultEditValues()
    },

    // primary and secondary account selection

    [blogPosting.SET_PRIMARY_BLOG_SELECTION] (state, account) {
      console.debug('account', account)
      if (account) {
        if (!account.shopify) {
          account.shopify = {
            website: '',
            author: '',
            tags: [],
            published: 'Visible'
          }
        }
        state.blogSelection.primaryBlog = account
        return false
      }
      state.blogSelection.primaryBlog = getDefaultBlogSelection()
    },

    [blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME] (state, name) {
      state.blogSelection.platform_name = name
    },

    [blogPosting.SET_PRIMARY_BLOG_TIME] (state, time) {
      if (time) {
        state.blogSelection.primaryTime = time
        return false
      }
      state.blogSelection.primaryTime = getDefaultPrimaryTime()
    },

    [blogPosting.SET_PRIMARY_BLOG_DATE] (state, date) {
      state.blogSelection.primaryTime.postSelectedDate = date
    },

    [blogPosting.SET_PRIMARY_BLOG_OPTION] (state, option) {
      state.blogSelection.primaryBlogOption = option
    },

    [blogPosting.RESET_PRIMARY_BLOG_SELECTION] (state) {
      state.blogSelection.platform_name = ''
      state.blogSelection.primaryBlog = getDefaultBlogSelection()
      state.blogSelection.primaryBlogOption = getDefaultBlogOptions()
      state.blogSelection.primaryTime = getDefaultPrimaryTime()
    },

    [blogPosting.SET_SECONDARY_BLOG_SELECTION] (state, blog) {
      state.blogSelection.secondaryBlog = blog
    },

    [blogPosting.SET_SECONDARY_BLOG_IDS] (state, id) {
      state.blogSelection.secondaryAccountsId = id
    },

    [blogPosting.RESET_BLOG_POSTING] (state) {
      state.details = getDefaultBlogPostingDetails()
      state.blogSelection = getBlogPrimarySecondaryDefaultSelection()
      state.edit = getDefaultEditValues()
    },

    [blogPosting.RESET_BLOG_EDIT_VALUES] (state) {
      state.edit = getDefaultEditValues()
    },

    [blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER] (state, status) {
      state.loaders.upload_blog_image = status
    },

    [blogPosting.SET_WEBFLOW_COLLECTIONS] (state, collections) {
      state.webflowCollections = collections
    },
    [blogPosting.SET_WEBFLOW_LOADING] (state, loading) {
      state.webflowLoading = loading
    }
  }
}
