import Vue from 'vue'
import Toasted from 'vue-toasted'
import { VueRenderer } from '@ui/TextArea/helpers/VueRenderer'
import CstToast from '@ui/Toast/CstToast'

Vue.use(Toasted)

export default {
  state: {
    is_new_version_available: false,
    is_new_version_available_display: true,
  },
  getters: {
    getIsNewVersionAvailable: (state) => {
      return state.is_new_version_available
    },
    getIsNewVersionAvailableDisplay: (state) => {
      return state.is_new_version_available_display
    },
  },

  actions: {
    toastNotification({ commit, getters }, payload) {
      // set the payload to success if no property defined.
      if (this.$route && this.$route.name === 'analytics_pdf_report') {
        return
      }
      if (!('type' in payload)) {
        payload.type = 'success'
      }

      const type = payload.type
      const message = payload.message
      console.debug('notification detail message ==== ', message, type)

      const createAlertMessage = (type, message) => {
        const component = new VueRenderer(CstToast, {
          // using vue 2:
          propsData: {
            type,
            message,
          },
        })

        return component.element.outerHTML
      }

      const toast = Vue.toasted.show(createAlertMessage(type, message), {
        theme: 'primary',
        position: payload.position ? payload.position : 'top-right',
        duration: 5000,
        className: 'toast-alert',
      })
      const closeIcon = toast.el.firstElementChild.lastElementChild
      closeIcon.addEventListener('click', (e) => {
        toast.goAway(0)
        /* Velocity(toast_item, { 'opacity': 0, marginTop: '-40px' }, {
           duration: 375,
           easing: 'easeOutExpo',
           queue: false,
           complete: function () {
             toast.el.remove()
           }
         }) */
      })
    },
  },
  mutations: {
    setIsNewVersionAvailable(state, value) {
      console.log('setIsNewVersionAvailable')
      state.is_new_version_available = value
    },
    setDisplayNewVersionAvailable(state, value) {
      state.is_new_version_available_display = value
    },
  },
}
