<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_right_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Tag cloud of top keywords extracted from the articles </h2>
    <div
      class="topics-insights-container__chart_legends"
      id="wordCloudTopics"
    />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        :options="wordCloudInsights()"
        ref="highcharts_articles_published"
      ></highcharts>
    </div>
  </div>
</template>
<script>
import { NO_CHART_DATA } from '@common/constants/messages'

export default {
  props: {
    wordcloud_list: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      return (this.wordcloud_list && this.wordcloud_list.length) === 0
    }
  },

  methods: {
    wordCloudInsights () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point">{point.name} appeared <b>{point.weight}</b> times.</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        series: [
          {
            minFontSize: 10,
            colors: [
              '#69c366',
              '#5cc6ff',
              '#ff6462',
              '#ff5f31',
              '#864de9',
              '#e7af4d',
              '#fea28b',
              '#fa6ab6',
              '#0095f3',
              '#dc70ea'
            ],
            type: 'wordcloud',
            data:
              this.wordcloud_list && this.wordcloud_list.length > 0
                ? this.wordcloud_list
                : [
                  { name: 'Content Marketing', weight: 100 },
                  { name: 'Digital Marketing', weight: 30 },
                  { name: 'Business', weight: 20 },
                  { name: 'SEO', weight: 33 },
                  { name: 'Search Engine Optimization', weight: 15 },
                  { name: 'Mobile Optimization', weight: 35 },
                  { name: 'Digital World', weight: 25 }
                ],
            name: 'Occurrences'
          }
        ],
        title: {
          text: ''
        }
      }
      return chartOptions
    }
  }
}
</script>
