<template>
  <div>
    <FilterbarCompetitor
      :selected-competitor="selected_account.facebook_id"
      @updatedCompetitor="initializePageWithData"
      @set-date-range="setDateRange"
    />

    <div class="competitor-overview__wrapper">
      <div
        v-if="
          inactiveCompetitor.length > 0 &&
          selectedCompetitors.length >= 2 &&
          isSelectedCompetitorValid()
        "
        class="tp_text"
      >
        We are fetching data and crunching numbers for
        <b>{{ inactiveCompetitor[0].name }}</b>
        <template v-if="inactiveCompetitor.length > 1">
          and
          <span class="ml-auto position-relative">
            <span
              v-b-popover.hover.html.leftbottom="compToolTip()"
              class="cursor-pointer"
              ><b>{{ inactiveCompetitor.length - 1 }} other.</b></span
            >
          </span></template
        >
      </div>
      <!-- header section start here -->
      <div class="competitor-overview__header">
        <div class="competitor-overview__header-left top-comp-filter">
          <b-dropdown
            ref="dropdown"
            variant="studio-theme"
            toggle-class="min-width"
            size="lg"
            class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image"
            :disabled="connectedAccounts.length === 0"
            no-caret
          >
            <template v-slot:button-content>
              <template v-if="connectedAccounts.length === 0">
                <i
                  class="fad fa-user-circle mr-0"
                  style="font-size: 1.8rem"
                ></i>
                <span
                  class="capitalize_text d-inline-flex justify_content_start text-left ml-3 text-bold-500"
                >
                  <small>No Account Connected</small>
                </span>
              </template>
              <template v-else :disabled="selectedCompetitors.length <= 0">
                <div v-if="!selected_account" class="d-flex align-items-center">
                  <i
                    class="fad fa-user-circle mr-0"
                    style="font-size: 1.8rem"
                  ></i>
                  <span
                    class="capitalize_text d-inline-flex justify_content_start text-left ml-3 text-bold-500"
                  >
                    <small>Please Select An Account</small>
                  </span>
                </div>
                <div
                  v-else
                  class="d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <span class="connected-accounts-images">
                      <div>
                        <img
                          class="selected"
                          :src="channelImage(selected_account, 'facebook')"
                          alt=""
                          @error="
                            $event.target.src = fallbackImagePreview(selected_account.name[0])
                          "
                        />
                      </div>
                    </span>
                    <span
                      class="capitalize_text d-flex flex-column justify_content_start text-left ml-3 text-bold-500"
                    >
                      {{ selected_account.name }}
                      <small>{{ selected_account.type }}</small>
                    </span>
                  </div>

                  <i class="icon-dropdown-cs"></i>
                </div>
              </template>
            </template>

            <div v-for="(account, i) in connectedAccounts" :key="i">
              <b-dropdown-item @click="changeSelectedAccount(account)">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label>
                      <div class="dropdown-img-wrapper">
                        <img
                          class="selected"
                          :src="channelImage(account, 'facebook')"
                          alt=""
                          @error="
                            $event.target.src = fallbackImagePreview(account.name[0])
                          "
                        />
                        <i :class="getIconClass('facebook')"></i>
                      </div>
                      <span class="dropdown-item-label">
                        <span>{{ account.name }}</span>
                        <small>{{ getProfileType('facebook') }}</small>
                      </span>
                    </label>
                  </div>
                </div>
              </b-dropdown-item>
            </div>
          </b-dropdown>

          <span
            v-if="
              selectedCompetitors.length > 1 ||
              (!isSelectedCompetitorValid() && selectedCompetitors.length > 0)
            "
            class="mx-4"
            ><i>vs</i></span
          >
          <!--          <div-->
          <!--              v-if="selectedCompetitors.length <= 0"-->
          <!--              class="opacity-70"-->
          <!--              style="display: inline-flex; justify-content: center"-->
          <!--          >-->
          <!--            <clip-loader-->
          <!--                class="ml-2"-->
          <!--                :color="'#5773fa'"-->
          <!--                :size="'20px'"-->
          <!--            ></clip-loader>-->
          <!--          </div>-->
          <div v-if="selectedCompetitors.length > 0" class="d-inline-flex flex-wrap">
            <div
              v-for="(competitor, i) in selectedCompetitors.filter(
                (obj) => obj.competitor_id !== selected_account.facebook_id,
              )"
              :key="i"
              class="competitor-overview__comp-box"
            >
              <div
                class=""
                :class="
                  competitor.enabled ? 'rounded_pill_selected' : 'rounded_pill'
                "
                debounce="500"
                @click="updateCompState(competitor)"
              >
                <span class="competitor-overview__comp-box-image">
                  <img :src="competitor.image" alt="" />
                </span>
                <span
                  class="capitalize_text flex-column justify_content_start text-left ml-2 text-bold-500 truncate"
                >
                  {{ competitor.name }}
                </span>
              </div>

              <span
                v-if="
                  selectedCompetitors.length -
                    (i + (isSelectedCompetitorValid() ? 1 : 0)) >
                  1
                "
                class="mx-4"
                ><i>vs</i></span
              >
            </div>
          </div>
        </div>

        <!--        <div class="competitor-overview__header-right"></div>-->
      </div>

      <template
        v-if="selectedCompetitors.length >= 2 && isSelectedCompetitorValid()"
      >
        <!-- Ranking section start here -->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header">Rankings</div>
          <div class="competitor-overview__card-body">
            <div class="grid-flex">
              <div
                v-for="(ranking, i) in rankings"
                :key="i"
                class="cell-lg-4 pr-0 mb-2"
                :class="{ 'pl-0': i === 0 }"
              >
                <CompareBox :ranking="ranking" />
              </div>
            </div>
          </div>
        </div>

        <!-- Data Table  start here-->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header">
            Comparative Table
          </div>
          <div class="competitor-overview__card-body">
            <ComparativeTable
              :talking-about="getUpdatedValue(talkingAbout)"
              :fields="fields"
              :max-post="getUpdatedValue(maxPost)"
              :max-fan-change="getUpdatedValue(fansMaxChange)"
              :max-fan-engagement="getUpdatedValue(maxFanEngagement)"
            />
          </div>
        </div>
        <!-- Data Graphs  start here-->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header">
            Growth and history charts
          </div>
          <div class="competitor-overview__card-body">
            <div class="grid-flex">
              <div class="cell-lg-6 mb-3 pr-0 pl-0">
                <GrowthChart
                  title="Fan Count"
                  type="datetime"
                  :series="getFansCount()"
                  :desc="fanCountDesc"
                />
              </div>
              <!-- Bar Graph -->
              <div class="cell-lg-6 mb-3 pr-0">
                <GrowthChart
                  title="Fan growth"
                  type="datetime"
                  :series="getFansGrowth()"
                  :desc="fanGrowthDesc"
                />
              </div>
              <div class="cell-lg-6 mb-3 pr-0 pl-0">
                <GrowthChart
                  title="Average Post Engagement Per Day"
                  type="datetime"
                  :series="getAvgEngagement()"
                  :desc="avgPostEngDesc"
                />
              </div>
              <div class="cell-lg-6 mb-3 pr-0">
                <GrowthChart
                  title="Post Engagement Per Day"
                  type="datetime"
                  :series="getUpdatedValue(engagement)"
                  :desc="postEngDesc"
                />
              </div>
              <!-- Bar Graph -->
              <div class="cell-lg-6 mb-3 pr-0 pl-0">
                <GrowthChart
                  title="Engagement By Post Type"
                  type="category"
                  :series="getUpdatedValue(postTypeEngagement)"
                  :desc="postTypeEngDesc"
                />
              </div>
              <div class="cell-lg-6 mb-3 pr-0">
                <GrowthChart
                  title="Fans Post Engagement / Day"
                  type="datetime"
                  :series="getUpdatedValue(fanPostEngagement)"
                  :desc="fanPostDesc"
                />
              </div>
              <!-- Pir Chart -->
              <div class="cell-lg-12 mb-6 pr-0 pl-0">
                <competitorPieChart
                  :series="getUpdatedValue(postTypeEngagement)"
                  chart-ref="competitor-overall-analytics-pie"
                  title="Post Type Distribution"
                  :desc="postTypeDesc"
                />
              </div>
              <!-- Pie Chart -->
              <div class="cell-lg-12 mb-6 pr-0">
                <competitorPieChart
                  :series="getUpdatedValue(engagementDistribution)"
                  chart-ref="competitor-overall-analytics-pie-2"
                  title="Distribution of User Engagement"
                  :desc="userEngDesc"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Top Posts -->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header d-flex"
            >Top Posts
            <div class="ml-auto">
              <i
                v-b-popover.hover.html.topleft="topPostsDesc"
                class="fa fa-info info_hover"
              ></i>
            </div>
          </div>
          <div class="competitor-overview__card-body">
            <div
              v-if="getUpdatedValue(topPosts) === null"
              class="opacity-70"
              style="display: flex; justify-content: center"
            >
              <clip-loader
                class="ml-2"
                :color="'#5773fa'"
                :size="'20px'"
              ></clip-loader>
            </div>
            <p
              v-if="
                getUpdatedValue(topPosts) !== null &&
                getUpdatedValue(topPosts).length <= 0
              "
              class="text-center"
            >
              Data not found for the selected pages and filters
            </p>
            <template v-if="
                getUpdatedValue(topPosts) !== null &&
                getUpdatedValue(topPosts).length > 0
              ">
              <div
                  v-for="(posts, index) in getUpdatedValue(topPosts)"
                  :key="index"
                  class="grid-flex mb-3"
              >
                <div
                    v-for="(post, key) in posts.data"
                    :key="key"
                    class="cell-md-4"
                >
                  <PostCardCompetitors
                      :post="post._source"
                      platform_type="facebook"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div v-else>
        <CompetitorAnalyticsLoading
          :name_list="inactiveCompetitor"
          :is-page-valid="isSelectedCompetitorValid()"
        ></CompetitorAnalyticsLoading>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.top-comp-filter {
  background: #fff;
  padding: 0.8rem;
  border: 1px;
  border-radius: 13px;
}
</style>

<script>
import {intToString} from '@common/lib/helper'
import moment from 'moment'
import axios from 'axios'
import {mapGetters} from 'vuex'
import {EventBus} from "@common/lib/event-bus";
import {
  fetchAvgEngagement,
  fetchEngagement,
  fetchEngagementByPostType,
  fetchEngagementDistribution,
  fetchFansGrowth,
  fetchFbCompetitors,
  fetchTopPost,
  fetchTotalPosts
} from '@src/modules/analytics/config/api-utils'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import FilterbarCompetitor from '../../common/FilterbarCompetitor'
import CompareBox from '../../common/CompareBox'
import competitorPieChart from '../../common/CompetitorPieChart'

import ComparativeTable from '../../common/ComparativeTable'

import GrowthChart from '../../common/GrowthChart'

import PostCardCompetitors from '../../common/PostCardCompetitors'
import CompetitorAnalyticsLoading from '../../common/CompetitorAnalyticsLoading'

// Variable for DATA API (URL/ENDPOINTS)

export default {
  name: 'FacebookCompetitorOverview',
  components: {
    FilterbarCompetitor,
    CompareBox,
    ComparativeTable,
    GrowthChart,
    PostCardCompetitors,
    competitorPieChart,
    CompetitorAnalyticsLoading
  },
  mixins: [analyticsUtilsMixin],
  data () {
    return {
      maxFanEngagement: {},
      fansMaxChange: {},
      maxPost: {},
      talkingAbout: {},
      CancelToken: axios.CancelToken,
      proxy: axios,
      requestCancelPromise: {},
      engagementDistribution: [],
      fanPostEngagement: [],
      disabledCompetitors: [],
      postTypeEngagement: [],
      topPosts: null,
      engagement: [],
      avgEngagement: [],
      fansData: [],
      postMetrics: {},
      selected_account: {},
      selectedCompetitors: [],
      startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
      rankings: [
        {
          title: 'Fans (Page Likes)',
          competitors: [],
          description:
              '<span class="font-0-75rem">This section displays total fan likes with their relevant pages, helps in comparison' +
              ' of page mass in term of fans.</span>'
        },
        {
          title: 'Total Posts',
          competitors: [],
          description:
              '<span class="font-0-75rem">This section displays total page posts with their relevant pages, can be as useful ' +
              'metric to compare your post frequency with other platform users.</span>'
        },
        {
          title: 'Average Engagement rate',
          competitors: [],
          description:
              '<span class="font-0-75rem">This section displays fans engagement rate with reference to page posts, the ' +
              'metric is calculated by:</br><table class="formula-table"><tr><td>Total Engagement On Page</td></tr> <tr><td>Total Number Of Posts On Page</td></tr></table>' +
              'The metric is calculated with in the bounds of applied date filter.</span>'
        }
      ],
      fields: [],
      topPostsDesc:
          '<span class="font-0-75rem">The section displays top 3 posts of competitor with respect to engagement. The notch on the top-right can be used to navigate to actual post. Some useful data metrics are also visible in post cards</span>',
      fanCountDesc:
          '<span class="font-0-75rem">The graph displays page\'s fans count on the number of days in the applied filter. The graph can be helpful to get an overview of fans count over a specific period of time. The graph will display data from the day competitor is connected.</span>',
      fanGrowthDesc:
          '<span class="font-0-75rem">The graph displays page\'s fans growth on the number of days in the applied filter. The graph can be helpful to get an overview of fans growth over a specific period of time. The metric displays fans difference between two subsequent days. The graph will display data from the 2nd day competitor is connected.</span>',
      avgPostEngDesc:
          '<span class="font-0-75rem">The graph displays a series of data about page\'s average post engagement. Each data point represents average post engagement on a certain day. The metric is calculated by:</br><table class="formula-table"><tr><td>Total Engagement On A Certain Day</td></tr> <tr><td>Total Number Of Posts On That Day</td></tr></table></span>',
      postEngDesc:
          '<span class="font-0-75rem">The graph displays a series of data about page\'s daily post engagement. Each data point represents post engagement on a certain day. The metric can be useful to overview page\'s engagement trends over a time period.</span>',
      postTypeEngDesc:
          '<span class="font-0-75rem">The chart displays engagement values with respect to the data type of posts. This chart can be helpful to visualize what kind of posts are getting better engagement for a certain domain.</span>',
      fanPostDesc:
          '<span class="font-0-75rem">The graph displays a series of data about page\'s daily fan post engagement. Each data point represents fan post engagement on a certain day. The metric can be useful to overview how much engagement are coming from fan/visitor posts. The data will only be visible if the page has any fan/visitor post over time period in specified filter.</span>',
      postTypeDesc:
          '<span class="font-0-75rem">The pie-chart display post count on a page distributed by post type. The chart can be helpful in visualizing posts distribution by post type. </span>',
      userEngDesc:
          '<span class="font-0-75rem">The pie-chart display engagement count on a page distributed by engagement type. The chart can be helpful in visualizing engagement distribution by engagement type.</span>',
      inactiveCompetitor: []
    }
  },
  computed: {
    ...mapGetters(['getJWTToken']),
    connectedAccounts () {
      // function returns connected accounts for user->workspace
      return this.getPlatformAccounts('facebook').sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    }
  },
  mounted () {
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.cancelPendingRequest('all')
      this.$router.push({
        name: 'facebookCompetitor'
      })
    })

    this.initializePageWithData()
  },
  beforeDestroy () {
    EventBus.$off('workspace-changed')
    this.selected_account = null
    this.selectedCompetitors = []
    this.rankings = []
    this.fields = []
    this.emptyExistingViews()
  },
  methods: {
    updateCompState (competitor) {
      // The function is responsible to manages competitors states (enabled/disabled)
      competitor.enabled = !competitor.enabled
      if (!competitor.enabled) {
        this.disabledCompetitors.push(competitor.competitor_id)
      } else {
        this.disabledCompetitors = this.disabledCompetitors.filter(
          (val) => val !== competitor.competitor_id
        )
      }
      // calls to reload all data of the view.
      this.emptyExistingViews(false)
      this.loadReport()
    },
    changeSelectedAccount (account) {
      // the function is responsible make report and view changes whenever user selects a different fb account.
      this.selectedCompetitors = []
      this.emptyExistingViews()
      this.$router.push({
        name: 'facebookCompetitorOverview_v3',
        params: { accountId: account._id }
      })
      this.selected_account = account
      // function call to fetch competitors for the newly selected account.
      this.getCompetitors(account)
    },
    emptyExistingViews (overlayFlag = true) {
      // the function is responsible to empty all currently populated views and data variables.
      // The function is usually called whenever user apply some filters or reload data.
      this.rankings[0].competitors = []
      this.rankings[1].competitors = []
      this.rankings[2].competitors = []
      this.engagement = []
      this.avgEngagement = []
      this.fansData = []
      this.topPosts = []
      this.fanPostEngagement = []
      this.postTypeEngagement = []
      this.engagementDistribution = []
      this.fields = []
      this.talkingAbout = {}
      this.maxPost = {}
      this.fansMaxChange = {}
      this.maxFanEngagement = {}
      if (overlayFlag) {
        this.inactiveCompetitor = []
      }
    },
    getCompetitors (account) {
      // The function is responsible to fetch competitors for a selected fb account.
      // It also makes calls to reload the report.
      let cancel
      this.cancelPendingRequest('get_comp')
      if (account && account.facebook_id) {
        this.proxy
          .get(
            fetchFbCompetitors +
              `?facebook_id=${account.facebook_id}&workspace_id=${account.workspace_id}`,
            {
              cancelToken: new this.CancelToken(function executor (c) {
                cancel = c
              })
            }
          )
          .then((res) => {
            if (res.data) {
              this.$nextTick(() => {
                res.data.competitors.forEach((compObj) => {
                  if (!Object.hasOwn(compObj, 'last_analytics_updated_at')) {
                    this.inactiveCompetitor.push(compObj)
                  } else {
                    compObj.enabled = true
                    this.selectedCompetitors.push(compObj)
                  }
                })
                this.selectedCompetitors.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                )
                console.log(this.selectedCompetitors)
                this.loadReport()
              })
            }
          })
          .catch((err) => {
            console.error(err)
          })
        this.requestCancelPromise.get_comp.push(cancel)
      }
    },
    getPayload (visitorPosts = 'not-included') {
      // The function is responsible to generate and return payload for any API call, with specific filters.
      if (this.selected_account && this.selected_account.facebook_id) {
        return {
          workspace_id: this.selected_account.workspace_id,
          date: this.startDate + ' - ' + this.endDate,
          accounts: this.selectedCompetitors
              .filter((comObj) => comObj.enabled)
              .map((comObj) => comObj.competitor_id),
          visitor_posts: visitorPosts,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      } else {
        return {}
      }
    },
    cancelPendingRequest (key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundency and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
        // The following chunk update request header with a valid auth token
        this.proxy.defaults.headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getJWTToken
        }
      }
    },
    getFans () {
      // The function is responsible to fetch fans data from api, parse it, reshape it and store it in data variables.
      const fansGrowth = []
      const aliasTable = {}
      const maxFansChange = {}
      let cancel
      // call to cancel any pending request of the same category.
      this.cancelPendingRequest('fans_growth')
      this.proxy
        .get(fetchFansGrowth, {
          params: this.getPayload(),
          cancelToken: new this.CancelToken(function executor (c) {
            cancel = c
          })
        })
        .then((res) => {
          if (res.data) {
            this.$nextTick(() => {
              // Initializing metric with title for data table.
              aliasTable.title = 'Talking about Page'
              // Initializing metric with title for data table.
              maxFansChange.title = 'Max Change in Fans'
              const dataAlias = {}
              this.fansData =
                res.data.fans_growth.length > 0 ? res.data.fans_growth : [null]
              // Iterating on the API response to extract data
              res.data.fans_growth.forEach((dataObj) => {
                dataAlias[dataObj.page_id] = {}
                dataAlias[dataObj.page_id].sum_fans =
                  dataObj.data[dataObj.data.length - 1].sum_fans.value
                dataAlias[dataObj.page_id].talking_about_this =
                  dataObj.data[dataObj.data.length - 1].talking_about_this.value
                // console.log('deltafelta',deltaFanCount,)
                if (dataObj.data.length === 1) {
                  dataAlias[dataObj.page_id].max_change = 'No Data'
                } else {
                  const deltaFanCountIdx = dataObj.data
                    .filter((obj) => obj.delta_fan_count)
                    .map((obj) => Math.abs(obj.delta_fan_count.value))
                    .reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0)
                  const fanChangeValue =
                    dataObj.data[deltaFanCountIdx + 1].delta_fan_count.value
                  dataAlias[dataObj.page_id].max_change = {
                    val:
                      fanChangeValue >= 0
                        ? intToString(fanChangeValue)
                        : '- ' + intToString(fanChangeValue),
                    date: new Date(
                      dataObj.data[deltaFanCountIdx + 1].key_as_string
                    )
                  }
                  dataAlias[dataObj.page_id].max_change.date =
                    dataAlias[dataObj.page_id].max_change.date.toDateString()
                }
              })
              // parsing and reshaping extracted data into data variable to be presented in html template.
              this.selectedCompetitors.forEach((compObj) => {
                if (dataAlias[compObj.competitor_id.toString()]) {
                  const fansData = {
                    name: compObj.name,
                    value: intToString(
                      dataAlias[compObj.competitor_id].sum_fans
                    ),
                    logo: compObj.image,
                    isUser:
                      compObj.competitor_id.toString() ===
                      this.selected_account.facebook_id
                  }
                  aliasTable[compObj.competitor_id] = intToString(
                    dataAlias[compObj.competitor_id].talking_about_this
                  )
                  maxFansChange[compObj.competitor_id] =
                    dataAlias[compObj.competitor_id].max_change

                  fansGrowth.push(fansData)
                } else if (
                  !this.disabledCompetitors.includes(compObj.competitor_id)
                ) {
                  // If the competitor has no data, initializing its object with empty/placeholder data.
                  const fansData = {
                    name: compObj.name,
                    value: 0,
                    logo: compObj.image,
                    isUser:
                      compObj.competitor_id.toString() ===
                      this.selected_account.facebook_id
                  }
                  aliasTable[compObj.competitor_id] = 0
                  maxFansChange[compObj.competitor_id] = 'No Data'
                  fansGrowth.push(fansData)
                }
              })
            })
          }
          this.rankings[0].competitors = fansGrowth
          this.talkingAbout = aliasTable
          this.fansMaxChange = maxFansChange
        })
        .catch((err) => {
          console.error(err)
        })
      this.requestCancelPromise.fans_growth.push(cancel)
    },
    getEngagementByPosts () {
      // The function is responsible to fetch Posts avg engagement data from API, parse it, reshape it and store it in data variables.
      const fansEngagement = []
      let cancel
      this.cancelPendingRequest('avg_engagement')
      // API call
      this.proxy
        .get(fetchAvgEngagement, {
          params: this.getPayload(),
          cancelToken: new this.CancelToken(function executor (c) {
            cancel = c
          })
        })
        .then((res) => {
          if (res.data) {
            this.$nextTick(() => {
              const maxObj = {}
              console.log('i am in error', res)
              this.avgEngagement = []
              this.avgEngagement =
                res.data.avg_engagement_per_day.length > 0
                  ? res.data.avg_engagement_per_day
                  : [null]
              // extracting data received from api response into local function variables
              res.data.avg_engagement_post.forEach((dataObj) => {
                maxObj[dataObj.page_id] = dataObj.data
              })
              // parsing and reshaping extrated data into component's data variables to be populated in html template.
              this.selectedCompetitors.forEach((compObj) => {
                if (maxObj[compObj.competitor_id.toString()]) {
                  const fansData = {
                    name: compObj.name,
                    value: intToString(
                      maxObj[compObj.competitor_id].post_engagement
                    ),
                    logo: compObj.image,
                    isUser:
                      compObj.competitor_id.toString() ===
                      this.selected_account.facebook_id
                  }
                  fansEngagement.push(fansData)
                } else if (
                  !this.disabledCompetitors.includes(compObj.competitor_id)
                ) {
                  // inititalizing placeholder/empty data objects for competitors with no data.
                  const fansData = {
                    name: compObj.name,
                    value: 0,
                    logo: compObj.image,
                    isUser:
                      compObj.competitor_id.toString() ===
                      this.selected_account.facebook_id
                  }
                  fansEngagement.push(fansData)
                }
              })
              this.rankings[2].competitors = fansEngagement
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
      this.requestCancelPromise.avg_engagement.push(cancel)
    },
    getTopPosts () {
      // The function is responsible to fetch Top Posts data from API, parse it, reshape it and store it in data variables.
      let topPosts = []
      const availableComp = []
      let cancel
      this.cancelPendingRequest('top_posts')
      // API call
      if (this.selected_account && this.selected_account.facebook_id) {
        this.proxy
          .get(fetchTopPost, {
            params: this.getPayload(),
            cancelToken: new this.CancelToken(function executor (c) {
              cancel = c
            })
          })
          .then((data) => {
            if (data.data) {
              this.$nextTick(() => {
                // extracting and storing data received from api response into local function variables
                data.data.top_posts.forEach((dataObj) => {
                  availableComp.push(dataObj.page_id)
                  topPosts.push(dataObj)
                })
                // inititalizing placeholder/empty data objects for competitors with no data.
                this.selectedCompetitors.forEach((compObj) => {
                  if (
                    !availableComp.includes(compObj.competitor_id) &&
                    !this.disabledCompetitors.includes(compObj.competitor_id)
                  ) {
                    topPosts.push({ page_id: compObj.competitor_id, data: [] })
                  }
                })
                console.log('topPosts', data.data.top_posts, topPosts)
                topPosts = topPosts.filter((obj) => obj.data.length > 0)
                this.topPosts = this.sortDataLists(topPosts, true)
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        this.requestCancelPromise.top_posts.push(cancel)
      }
    },
    loadDataTable () {
      // The function is responsible to populate the column fields in data data table
      this.selectedCompetitors.forEach((dataObj) => {
        if (!this.disabledCompetitors.includes(dataObj.competitor_id)) {
          this.fields.push({
            name: dataObj.name,
            image: dataObj.image,
            id: dataObj.competitor_id
          })
        }
      })
      this.fields = this.sortDataLists(this.fields)
    },
    getFansGrowth () {
      // The function is responsible to fetch fans data from Component's Data Variable, extract fans growth data from it and store it in data variables.
      const fansGrowth = []
      const availableComp = []
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0 &&
        this.fansData.length > 0
      ) {
        console.log(
          'fansData',
          fansGrowth,
          this.fansData,
          this.selectedCompetitors
        )
        // Parsing and reshaping fans data to be populated in data graphs
        if (!this.fansData.includes(null)) {
          this.fansData.forEach((fansObj) => {
            const data = {}
            availableComp.push(fansObj.page_id)
            console.log(
              'checkit fans',
              this.selectedCompetitors.filter(
                (comp) => comp.competitor_id === fansObj.page_id
              )
            )
            data.name = this.getCompPageName(fansObj.page_id, false)
            data.id = fansObj.page_id
            data.data = fansObj.data.map((dataObj) => {
              if (dataObj.delta_fan_count) {
                return [
                  Date.parse(dataObj.key_as_string.split('T')[0]),
                  dataObj.delta_fan_count.value
                ]
              } else {
                return [Date.parse(dataObj.key_as_string.split('T')[0]), 0]
              }
            })
            data.type = 'column'
            fansGrowth.push(data)
          })
        }
        // inititalizing placeholder/empty data objects for competitors with no data.
        this.selectedCompetitors.forEach((compObj) => {
          if (
            !availableComp.includes(compObj.competitor_id) &&
            !this.disabledCompetitors.includes(compObj.competitor_id)
          ) {
            fansGrowth.push({
              name: this.getCompPageName(false, compObj.name),
              data: [],
              type: 'column',
              id: compObj.competitor_id
            })
          }
        })
        console.log('fansData', fansGrowth, this.fansData)
      }

      if (
        fansGrowth.filter((obj) => obj.data.length <= 1).length ===
        fansGrowth.length
      ) {
        return [{ data: [] }]
      }
      return this.sortDataLists(fansGrowth)
    },
    getFansCount () {
      // The function is responsible to fetch fans data from Component's Data Variable, extract fans count data from it and store it in data variables.
      const fansCount = []
      const availableComp = []
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0 &&
        this.fansData.length > 0
      ) {
        console.log(
          'fansData',
          fansCount,
          this.fansData,
          this.selectedCompetitors
        )
        // Parsing and reshaping fans data to be populated in data graphs
        if (!this.fansData.includes(null)) {
          this.fansData.forEach((fansObj) => {
            const data = {}
            availableComp.push(fansObj.page_id)
            data.name = this.getCompPageName(fansObj.page_id, false)
            data.id = fansObj.page_id
            data.data = fansObj.data.map((dataObj) => [
              Date.parse(dataObj.key_as_string.split('T')[0]),
              dataObj.sum_fans.value
            ])
            fansCount.push(data)
          })
        }
        // inititalizing placeholder/empty data objects for competitors with no data.
        this.selectedCompetitors.forEach((compObj) => {
          if (
            !availableComp.includes(compObj.competitor_id) &&
            !this.disabledCompetitors.includes(compObj.competitor_id)
          ) {
            fansCount.push({
              name: this.getCompPageName(false, compObj.name),
              data: [],
              id: compObj.competitor_id
            })
          }
        })
      }
      console.log('fansCount', fansCount, this.fansData)

      return this.sortDataLists(fansCount)
    },
    getAvgEngagement () {
      // The function is responsible to fetch Post Average Engagement data from Component's Data Variable,
      // extract fans count data from it and store it in data variables.
      const avgEngagement = []
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0 &&
        this.avgEngagement.length > 0
      ) {
        console.log('avgData', avgEngagement, this.avgEngagement)
        const availableComp = []
        // Parsing and reshaping avg engagement data to be populated in data graphs
        if (!this.avgEngagement.includes(null)) {
          this.avgEngagement.forEach((avgObj) => {
            console.log('checkit avgEng')
            availableComp.push(avgObj.page_id)
            const data = {}
            data.name = this.getCompPageName(avgObj.page_id, false)
            data.id = avgObj.page_id
            data.data = avgObj.data
              .filter((obj) => obj.key_as_string.split('T')[0] <= this.endDate)
              .map((dataObj) => [
                Date.parse(dataObj.key_as_string.split('T')[0]),
                dataObj.avg_engagement.value === null
                  ? 0
                  : Math.round(
                    (dataObj.avg_engagement.value + Number.EPSILON) * 100
                  ) / 100
              ])
            avgEngagement.push(data)
          })
        }
        // initializing placeholder/empty data objects for competitors with no data.
        this.selectedCompetitors.forEach((compObj) => {
          if (
            !availableComp.includes(compObj.competitor_id) &&
            !this.disabledCompetitors.includes(compObj.competitor_id)
          ) {
            avgEngagement.push({
              name: this.getCompPageName(false, compObj.name),
              data: [],
              id: compObj.competitor_id
            })
          }
        })
        console.log('avgData', avgEngagement, this.avgEngagement)
      }
      return this.sortDataLists(avgEngagement)
    },
    getEngagement (fanPost = false) {
      // The function is responsible to fetch Posts engagement data from API, parse it, reshape it and store it in data variables.
      const engagement = []
      const maxFanEngagement = {}
      let cancel
      fanPost
        ? this.cancelPendingRequest('engagement')
        : this.cancelPendingRequest('engagement_fans')
      const fanCheck = fanPost ? 'only' : 'not-included'
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0
      ) {
        console.log('engagementData', engagement)
        // API call
        this.proxy
          .get(fetchEngagement, {
            params: this.getPayload(fanCheck),
            cancelToken: new this.CancelToken(function executor (c) {
              cancel = c
            })
          })
          .then((data) => {
            if (data.data) {
              this.$nextTick(() => {
                const availableComp = []
                // initializing data table variable for max post engagement.
                maxFanEngagement.title = 'Max Post Engagement'
                if (!fanPost) {
                  data.data.max_engagement_per_day
                    .filter((obj) => obj.date <= this.endDate)
                    .forEach((engObj) => {
                      maxFanEngagement[engObj.page_id] = {
                        val: intToString(engObj.max_engagement_per_day),
                        date: new Date(engObj.date)
                      }
                      maxFanEngagement[engObj.page_id].date =
                        maxFanEngagement[engObj.page_id].date.toDateString()
                    })
                }
                // extracting data received from api response into local function variables
                data.data.engagement_per_day.forEach((engObj) => {
                  availableComp.push(engObj.page_id)
                  console.log('checkit eng', engObj, this.selectedCompetitors)
                  const engData = {}
                  engData.name = this.getCompPageName(engObj.page_id, false)
                  engData.id = engObj.page_id
                  engData.data = engObj.data
                    .filter(
                      (obj) => obj.key_as_string.split('T')[0] <= this.endDate
                    )
                    .map((eng) => [
                      Date.parse(eng.key_as_string.split('T')[0]),
                      Number(
                        eng.comments_count.value +
                          eng.reaction_count.value +
                          eng.shares_count.value
                      )
                    ])
                  engagement.push(engData)
                })
                // inititalizing placeholder/empty data objects for competitors with no data.
                this.selectedCompetitors.forEach((compObj) => {
                  if (
                    !availableComp.includes(compObj.competitor_id) &&
                    !this.disabledCompetitors.includes(compObj.competitor_id)
                  ) {
                    engagement.push({
                      name: this.getCompPageName(false, compObj.name),
                      data: [],
                      id: compObj.competitor_id
                    })
                    maxFanEngagement[compObj.competitor_id] = 'No Data'
                  }
                })
                if (fanPost) {
                  this.fanPostEngagement = this.sortDataLists(engagement)
                  console.log('fanpostVal', data)
                } else {
                  this.engagement = this.sortDataLists(engagement)
                  this.maxFanEngagement = maxFanEngagement
                }
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        fanPost
          ? this.requestCancelPromise.engagement.push(cancel)
          : this.requestCancelPromise.engagement_fans.push(cancel)
      }
    },
    getPostTypeEngagement () {
      // The function is responsible to fetch Posts type engagement data from API, parse it, reshape it and store it in data variables.
      const postTypeEngagement = []
      let cancel
      this.cancelPendingRequest('engagement_by_post_type')
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0
      ) {
        // API call
        this.proxy
          .get(fetchEngagementByPostType, {
            params: this.getPayload(),
            cancelToken: new this.CancelToken(function executor (c) {
              cancel = c
            })
          })
          .then((data) => {
            if (data.data) {
              this.$nextTick(() => {
                console.log('updating engagement distribution data a ', data)
                const availableComp = []
                const keys = ['video', 'photo', 'link', 'album', 'text']
                // extracting data received from api response into local function variables
                data.data.engagement_by_type.forEach((engObj) => {
                  console.log(
                    'updating engagement distribution data an ',
                    engObj,
                    postTypeEngagement
                  )
                  const engData = {}
                  availableComp.push(engObj.page_id)
                  engData.name = this.getCompPageName(engObj.page_id, false)
                  engData.id = engObj.page_id
                  engData.data = engObj.data
                    .map((eng) => [eng.key, Number(eng.total_engagement.value)])
                    .filter((obj) => obj[1] > 0)
                  const reshapedPieData = {}
                  engObj.data.forEach((eng) => {
                    reshapedPieData[eng.key] = Number(eng.doc_count)
                  })
                  engData.pieData = keys.map((key) => [
                    key,
                    reshapedPieData[key] || 0
                  ])
                  engData.type = 'column'
                  console.log(
                    'updating engagement distribution data ------------',
                    engData.data
                  )
                  postTypeEngagement.push(engData)
                })
                // inititalizing placeholder/empty data objects for competitors with no data.
                this.selectedCompetitors.forEach((compObj) => {
                  if (
                    !availableComp.includes(compObj.competitor_id) &&
                    !this.disabledCompetitors.includes(compObj.competitor_id)
                  ) {
                    postTypeEngagement.push({
                      name: this.getCompPageName(false, compObj.name),
                      id: compObj.competitor_id,
                      data: [],
                      pieData: [],
                      type: 'column'
                    })
                  }
                })
                this.postTypeEngagement = this.sortDataLists(postTypeEngagement)
                console.log(
                  'updating engagement distribution data ',
                  this.postTypeEngagement
                )
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        this.requestCancelPromise.engagement_by_post_type.push(cancel)
      }
    },
    getEngagementDistribution () {
      // The function is responsible to fetch Posts engagement distribution data from API, parse it, reshape it and store it in data variables.
      const engagementDistribution = []
      let cancel
      this.cancelPendingRequest('engagement_distribution')
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0
      ) {
        // API call
        this.proxy
          .get(fetchEngagementDistribution, {
            params: this.getPayload(),
            cancelToken: new this.CancelToken(function executor (c) {
              cancel = c
            })
          })
          .then((data) => {
            if (data.data) {
              this.$nextTick(() => {
                const availableComp = []
                data.data.engagement_distribution.forEach((engObj) => {
                  const engData = {}
                  availableComp.push(engObj.key)

                  engData.name = this.getCompPageName(engObj.key, false)
                  engData.id = engObj.key
                  engData.pieData = []
                  engData.pieData.push(['haha', engObj.total_haha.value])
                  engData.pieData.push(['like', engObj.total_like.value])
                  engData.pieData.push(['angry', engObj.total_angry.value])
                  engData.pieData.push(['sad', engObj.total_sad.value])
                  engData.pieData.push(['wow', engObj.total_wow.value])
                  engData.pieData.push(['love', engObj.total_love.value])
                  engData.pieData.push(['share', engObj.total_share.value])
                  engData.pieData.push(['comment', engObj.total_comment.value])
                  // engData['data'] = engData['pieData'].filter((obj) => obj[1] > 0)
                  engagementDistribution.push(engData)
                  console.log('engagementDistributionData', engObj, engData)
                })
                // inititalizing placeholder/empty data objects for competitors with no data.
                this.selectedCompetitors.forEach((compObj) => {
                  if (
                    !availableComp.includes(compObj.competitor_id) &&
                    !this.disabledCompetitors.includes(compObj.competitor_id)
                  ) {
                    engagementDistribution.push({
                      name: this.getCompPageName(false, compObj.name),
                      pieData: [],
                      id: compObj.competitor_id
                    })
                  }
                })
                this.engagementDistribution = this.sortDataLists(
                  engagementDistribution
                )
                console.log(
                  'engagementDistributionData',
                  this.engagementDistribution
                )
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        this.requestCancelPromise.engagement_distribution.push(cancel)
      }
    },
    getTotalPosts () {
      // The function is responsible to fetch Total Posts data from API, parse it, reshape it and store it in data variables.
      const totalPosts = []
      const aliasTable = {}
      let cancel
      this.cancelPendingRequest('total_posts')
      if (
        this.selected_account &&
        this.selected_account.facebook_id &&
        this.selectedCompetitors.length > 0
      ) {
        // API call
        this.proxy
          .get(fetchTotalPosts, {
            params: this.getPayload(),
            cancelToken: new this.CancelToken(function executor (c) {
              cancel = c
            })
          })
          .then((data) => {
            if (data.data) {
              this.$nextTick(() => {
                aliasTable.title = 'Posts the most on'
                const alias = {}
                // extracting data received from api response into local function variables
                data.data.total_posts.forEach((postObj) => {
                  alias[postObj.key] = {}
                  alias[postObj.key].total_posts =
                    postObj.per_day.buckets.reduce(
                      (sum, { doc_count }) => sum + doc_count,
                      0
                    )
                  alias[postObj.key].max_posts = postObj.per_day.buckets.reduce(
                    (prev, current) =>
                      prev.doc_count > current.doc_count ? prev : current
                  )
                })
                // parsing and reshaping extrated data into component's data variables to be populated in html template.
                this.selectedCompetitors.forEach((compObj) => {
                  if (alias[compObj.competitor_id.toString()]) {
                    const fansData = {
                      name: compObj.name,
                      value: intToString(
                        alias[compObj.competitor_id.toString()].total_posts
                      ),
                      logo: compObj.image,
                      isUser:
                        compObj.competitor_id.toString() ===
                        this.selected_account.facebook_id
                    }
                    totalPosts.push(fansData)
                    // Most post on a day calculation.
                    let dateData = new Date(
                      alias[
                        compObj.competitor_id.toString()
                      ].max_posts.key_as_string
                    )
                    dateData = {
                      val:
                        dateData.toLocaleString('default', {
                          weekday: 'short'
                        }) +
                        ', ' +
                        dateData.getDate() +
                        ' ' +
                        dateData.toLocaleString('default', { month: 'short' }) +
                        ' (' +
                        alias[compObj.competitor_id.toString()].max_posts
                          .doc_count +
                        ')',
                      // dateData.toLocaleString('en-us', { weekday: 'long' }) +
                      // ` (${
                      //   alias[compObj.competitor_id.toString()]['max_posts']['doc_count']
                      // })`,
                      date: dateData.toDateString()
                    }
                    aliasTable[compObj.competitor_id.toString()] = dateData
                  } else if (
                    // inititalizing placeholder/empty data objects for competitors with no data.
                    !this.disabledCompetitors.includes(compObj.competitor_id)
                  ) {
                    const fansData = {
                      name: compObj.name,
                      value: 0,
                      logo: compObj.image,
                      isUser:
                        compObj.competitor_id.toString() ===
                        this.selected_account.facebook_id
                    }
                    totalPosts.push(fansData)
                    aliasTable[compObj.competitor_id.toString()] = 'No Data'
                  }
                })
                this.rankings[1].competitors = totalPosts
                this.maxPost = aliasTable
                console.log('i am here2', this.maxPost, aliasTable)
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        this.requestCancelPromise.total_posts.push(cancel)
      }
    },
    getUpdatedValue (dataLst) {
      if (dataLst === null) {
        return dataLst
      }
      // this function is responsible to receive an object (list/dict) if its empty return empty(list/dict) else return actual object
      console.log('updatedval', dataLst, typeof dataLst)
      if (typeof dataLst === 'object' && !Array.isArray(dataLst)) {
        if (Object.keys(dataLst).length > 0) {
          return dataLst
        } else {
          return {}
        }
      } else {
        if (dataLst.length > 0) {
          return dataLst
        } else {
          return []
        }
      }
    },
    loadReport () {
      // the function is responsible to make all subsequent function calls to populate each sub-view/component in the template.
      this.$nextTick(() => {
        console.log('watcheronthewall1', this.disabledCompetitors)
        this.getFans()
        this.getEngagementByPosts()
        this.getTotalPosts()
        this.loadDataTable()
        this.getEngagement(false)
        this.getEngagement(true)
        this.getTopPosts()
        this.getPostTypeEngagement()
        this.getEngagementDistribution()
      })
    },
    setDateRange (startDate, endDate) {
      // this function is responsible to update date range every time user update the date filter.
      console.log('newDateRange', startDate, endDate)
      this.startDate = startDate
      this.endDate = endDate
      this.emptyExistingViews(false)
      this.loadReport()
    },
    initializePageWithData () {
      // the function is reponsible to initialize whole page with data send set selected accounts base on the id recieved in url.
      this.$root.$emit('bv::hide::modal', 'modal-1')
      if (this.$route.params.accountId) {
        console.log('checkit mounted')
        this.selected_account = this.connectedAccounts.filter((account) => {
          return account._id === this.$route.params.accountId
        })[0]
        console.log('fetching data on page load')
        this.selectedCompetitors = []
        this.emptyExistingViews()
        this.getCompetitors(this.selected_account)
      }
    },
    getCompPageName (competitorId, pageName) {
      // the function is responsible to return page name truncated upto 16 characters, it either recieves page name or extract it from competitor id.
      let nameVal = ''
      if (!pageName) {
        nameVal = this.selectedCompetitors.filter(
          (comp) => comp.competitor_id === competitorId
        )[0].name
      } else {
        nameVal = pageName
      }
      nameVal = nameVal.length > 14 ? nameVal.substring(0, 11) + '...' : nameVal
      return nameVal
    },
    sortDataLists (dataLst, topPost = false) {
      if (dataLst.length <= 0) {
        return dataLst
      }
      const ourId = this.selected_account.facebook_id
      let ourObj = {}
      let sortedList = []
      if (topPost) {
        if (dataLst.filter((obj) => obj.page_id === ourId).length > 0) {
          ourObj = dataLst.filter((obj) => obj.page_id === ourId)[0]
        }
        sortedList = dataLst.filter((obj) => obj.page_id !== ourId)
        sortedList = sortedList.sort((a, b) =>
          a.data[0]._source.page_name > b.data[0]._source.page_name ? 1 : -1
        )
      } else {
        ourObj = dataLst.filter((obj) => obj.id === ourId)[0]
        sortedList = dataLst.filter((obj) => obj.id !== ourId)
        sortedList = sortedList.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
      if (Object.keys(ourObj).length > 0) {
        sortedList = [ourObj].concat(sortedList)
      }
      return sortedList
    },
    compToolTip () {
      let template = ''
      this.inactiveCompetitor.forEach((obj, idx) => {
        if (idx === 0) {
        } else {
          template =
            template +
            '<div class="tooltip_compair_box">' +
            '<img src="' +
            obj.image +
            '" alt="" class="compare-box__ranking-logo">' +
            '<div class="compare-box__ranking-label label">' +
            obj.name +
            '</div></div>'
        }
      })
      return template + ''
    },
    isSelectedCompetitorValid () {
      const id = this.selected_account.facebook_id
      let flag = false
      this.selectedCompetitors.forEach((obj) => {
        if (
          obj.competitor_id === id &&
          Object.hasOwn(obj, 'last_analytics_updated_at')
        ) {
          flag = true
        }
      })
      return flag
    }
  },
}
</script>
