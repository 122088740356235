<template>
  <div class="articles_list view_list">
    <div class="article_inner">
      <template v-if="getDiscoveryVideoLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <youtube></youtube>

        <template v-if="getVideoPaginationLoader && getVideos.length == 0">
          <no-results-found></no-results-found>
        </template>
        <template
          v-else-if="getVideoPaginationLoader && getVideos.length <= 19"
        >
          <!-- FIXME: add a better image for this case -->
          <!--<span >No more results found for your query.</span>-->
        </template>
        <infinite-loading
          v-else
          @infinite="onInfiniteVideo"
          ref="infiniteLoading"
        >
          <span slot="spinner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
          <no-results-found :more="true"></no-results-found>
          <span slot="no-results">
            <no-results-found :more="true"></no-results-found>
          </span>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../NoResultsFound'
import { topicTypes } from '../../../store/mutation-types'

const Youtube = () => import('../../common/Youtube')

export default {
  components: {
    InfiniteLoading,
    NoResultsFound,
    Youtube
  },
  computed: {
    ...mapGetters([
      'getTopicModule',
      'getTopicContentType',
      'getDiscoveryVideoLoader',
      'getVideoPaginationLoader',
      'getVideos',
      'getVideoScroll',
      'getVideoPage'
    ])
  },
  mounted () {
    if (!this.$route.params.module) {
      this.$router.push({
        name: 'cover_stories',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
    let res
    switch (this.$route.params.module) {
      case 'custom':
        this.RESET_VIDEO()
        res = this.isCustomTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'custom_topic',
            contentType: 'youtube',
            module: this.getTopicModule
          })
        }
        break
      case 'curated':
        this.RESET_VIDEO()
        res = this.isCuratedTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'curated',
            contentType: 'youtube',
            module: this.getTopicModule
          })
        }
        break
    }
    this.getYoutubeContentByTopic()
    // this.discoveryMounted()
  },
  methods: {
    ...mapActions(['setVideoPage']),
    ...mapMutations(['RESET_VIDEO']),
    onInfiniteVideo ($state) {
      console.log('On video infinite', this.getVideoScroll, this.getVideoPage)
      if (this.getVideoScroll) {
        this.setVideoPage(this.getVideoPage + 1)
        this.getYoutubeContentByTopic($state)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Youtube View:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')
    next()
  }
}
</script>
