<template>
  <div class="topics-filters-pane__container__right_section">
    <div class="view_change_btn d-flex align-items-center mb-3">
      <div class="view_left">
        <h3
          v-if="total_results > 0"
          class="topics-filters-pane__container__right_section__total_results"
        >
          {{ $filters.numberToCommas(total_results) }}
          <template v-if="total_results === 1"> Result </template>
          <template v-else> Results </template>
        </h3>
      </div>

      <b-dropdown
        id="dropdown-right"
        variant="studio-theme"
        class="studio-theme-dropdown article-filters-pane__right_section__dropdown"
        no-caret
      >
        <template slot="button-content">
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{
            getCuratedAndCustomTopicSharedTitle
          }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item
          :active="
            $route.query.sort === 'recent' ||
            (!$route.query.sort && this.sort === 'Recent')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('recent')"
          >Recent
        </b-dropdown-item>
        <b-dropdown-item
          :active="
            $route.query.sort === 'most_shared' ||
            (!$route.query.sort && this.sort === 'Most Shared')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('most_shared')"
          >Most Shared
        </b-dropdown-item>
        <b-dropdown-item
          :active="
            $route.query.sort === 'facebook_shares' ||
            (!$route.query.sort && this.sort === 'Facebook Shares')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('facebook_shares')"
          >Facebook Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="
            $route.query.sort === 'twitter_shares' ||
            (!$route.query.sort && this.sort === 'Twitter Shares')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('twitter_shares')"
        >
          Twitter Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="
            $route.query.sort === 'pinterest_shares' ||
            (!$route.query.sort && this.sort === 'Pinterest Shares')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('pinterest_shares')"
        >
          Pinterest Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="
            $route.query.sort === 'reddit_shares' ||
            (!$route.query.sort && this.sort === 'Reddit Shares')
          "
          variant="studio-theme"
          @click.prevent="redirectSortView('reddit_shares')"
          >Reddit Shares
        </b-dropdown-item>
      </b-dropdown>
      <!-- don't show these filters if the user has selected the type favorite or archived -->
      <div
        class="topic_date_picker d-inline-block align-middle topics-filters-pane__container__right_section__datepicker"
      >
        <span class="d-inline-block mr-2 topics-datepicker">
          <DateRangePicker
            ref="contentdatepicker"
            class="datepicker_input"
            :opens="date_range_pickers.normal.opens"
            :start-date="getFilterFromDate"
            :end-date="getFilterToDate"
            :max-date="getMaxDate"
            :locale-data="date_range_pickers.normal.locale"
            :single-date-picker="date_range_pickers.normal.singleDatePicker"
            :time-picker="date_range_pickers.normal.timePicker"
            :time-picker24hour="date_range_pickers.normal.timePicker24Hour"
            :show-week-numbers="date_range_pickers.normal.showWeekNumbers"
            :show-dropdowns="date_range_pickers.normal.showDropdowns"
            :auto-apply="date_range_pickers.normal.autoApply"
            :ranges="date_range_pickers.normal.ranges"
            @update="redirectDateView"
          >
            <div
              slot="input"
              slot-scope="picker"
              class="datepicker_content discovery_white ml-2"
            >
              <i class="icon-Calendar"></i>
              <span
                v-if="getSearchDatePicker(picker.startDate, picker.endDate)"
              >
                {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
              </span>
              <span v-else-if="picker.label">
                {{ picker.label }}
              </span>
              <span v-else> Last 30 days </span>
            </div>
          </DateRangePicker>
        </span>
      </div>
      <template v-if="is_custom_topic">
        <b-dropdown
          v-if="getCuratedAndCustomTopicRegionTitle"
          id="dropdown-right"
          variant="studio-theme"
          class="studio-theme-dropdown ml-2 topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region"
          no-caret
        >
          <template slot="button-content">
            <i class="flag_icon studio-theme-icon far fa-globe"></i>
            <span class="capitalize_text">{{
              getCuratedAndCustomTopicRegionTitle.name
            }}</span>
            <i class="icon-dropdown-cs"></i>
          </template>

          <div class="topics-filters-pane__container__right_section">
            <div class="search-bar-input p-2">
              <div class="search-bar-inner">
                <input
                  v-model="search_region"
                  type="text"
                  placeholder="Search by country name"
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
          </div>

          <b-dd-divider />

          <template v-if="sortedRegions.length > 0">
            <b-dropdown-item
              v-for="region in sortedRegions"
              :key="region.code"
              :active="getCuratedAndCustomTopicRegionTitle.code === region.code"
              variant="studio-theme"
              @click.prevent="redirectRegionView(region.code)"
            >
              <template v-if="region.flag"
                ><span class="region_flag" v-html="region.flag"></span>
              </template>
              <template v-if="!region.flag && region.code === 'world'"
                ><i class="far fa-globe globe_icon worldwide-flag-icon"></i
              ></template>
              {{ region.name }}
            </b-dropdown-item>
          </template>
          <template v-else>
            <p
              class="topics-filters-pane__container__right_section__dropdown__no_matches"
            >
              No matches found
            </p>
          </template>
        </b-dropdown>
        <b-dropdown
          v-if="getCuratedAndCustomTopicLanguageTitle"
          id="dropdown-right"
          variant="studio-theme"
          class="studio-theme-dropdown topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region ml-2"
          no-caret
        >
          <template slot="button-content">
            <i class="flag_icon studio-theme-icon far cs-flag"></i>
            <span class="capitalize_text">{{
              getCuratedAndCustomTopicLanguageTitle.languageName
            }}</span>
            <i class="icon-dropdown-cs"></i>
          </template>

          <div class="topics-filters-pane__container__right_section">
            <div class="search-bar-input p-2">
              <div class="search-bar-inner">
                <input
                  v-model="search_language"
                  type="text"
                  placeholder="Search by language"
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
          </div>
          <b-dd-divider />

          <template v-if="getLanguageList.length > 0">
            <b-dropdown-item
              v-for="language in getLanguageList"
              :key="language.code"
              :active="
                getCuratedAndCustomTopicLanguageTitle.code === language.code
              "
              variant="studio-theme"
              @click.prevent="redirectLanguageView(language.code)"
            >
              {{ language.languageName }}
            </b-dropdown-item>
          </template>
          <template v-else>
            <p
              class="topics-filters-pane__container__right_section__dropdown__no_matches"
            >
              No matches found
            </p>
          </template>
        </b-dropdown>
      </template>
      <div class="topics-filters-pane__container__right_section__view_change">
        <span
          v-tooltip.top-center="'Grid View'"
          class="grid_icon"
          :class="{ active: getDiscoveryView === 'grid' }"
          @click.prevent="changeViewLayout('grid')"
         >
          <i class="icon-groups-cs"></i>
        </span>
        <span
          v-tooltip.top-center="'List View'"
          class="list_icon"
          :class="{ active: getDiscoveryView === 'list' }"
          @click.prevent="changeViewLayout('list')"
        >
          <i class="icon-Listview_icon"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { filterLimitedRegions } from '@common/constants/regions'
import orderBy from 'lodash.orderby'
import { filterLimitedLanguages } from '@common/constants/languages'
import proxy from "@common/lib/http-common";
import {discoveryDefaultView} from "@src/modules/publish/config/api-utils";
export default {
  components: {
    DateRangePicker
  },
  props: {
    total_results: {},
    date: {},
    region: {},
    language: {},
    sort: {
      type: String,
      default: 'Most Shared'
    },
    is_custom_topic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      search_region: '',
      search_language: '',
      languages: filterLimitedLanguages,
      date_range_pickers: {
        normal: {
          locale: {
            format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
          },

          opens: 'left',
          startDate: '2017-09-19',
          endDate: '2017-10-09',
          minDate: '2016-09-02',
          maxDate: this.getMaxDate,
          dateRange: {
            endDate: moment().utc()
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          checkOpen: false,
          ranges: {
            'All Time': [moment.utc().subtract(5, 'years'), moment.utc()],
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
            'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
            'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
            'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
            'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
            'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
          }
        }
      }
    }
  },
  created () {
    // this.initDateView()
  },
  mounted () {
    this.$nextTick(() => {
      // this.initDateView()
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
  },
  computed: {
    ...mapGetters([
      'getTopicContentType',
      'getPostsTotal',
      'getTopicType',
      'getTopicCreationModal',
      'getTopicModule',
      'getTopicsSort',
        'getDiscoveryView'
    ]),
    sortedRegions () {
      const regionsList = orderBy(
        filterLimitedRegions.slice(1, filterLimitedRegions.length),
        ['name'],
        ['asc']
      )
      regionsList.unshift(filterLimitedRegions[0])
      if (this.search_region.length > 0) {
        return regionsList.filter((item) =>
          item.name.toLowerCase().includes(this.search_region.toLowerCase())
        )
      }
      return regionsList
    },
    getMaxDate () {
      return moment.utc().format('YYYY-MM-DD')
    },
    getFilterFromDate () {
      const fromDate = this.date
        ? moment(this.date.split(' - ')[0]).format('YYYY-MM-DD')
        : moment().utc().subtract(30, 'days').format('YYYY-MM-DD')
      return this.$route.query.from_date || fromDate
    },
    getFilterToDate () {
      const toDate = this.date
        ? moment(this.date.split(' - ')[1]).format('YYYY-MM-DD')
        : moment().utc().format('YYYY-MM-DD')
      return this.$route.query.to_date || toDate
    },

    getCuratedAndCustomTopicSharedTitle () {
      return this.$route.query.sort
        ? this.$route.query.sort.replace('_', ' ')
        : this.sort
    },
    getCuratedAndCustomTopicRegionTitle () {
      const findByRegionCode = this.$route.query.region
        ? this.$route.query.region
        : this.region
      return filterLimitedRegions.find((item) => item.code === findByRegionCode)
    },
    getCuratedAndCustomTopicLanguageTitle () {
      const findByLanguageCode = this.$route.query.language
        ? this.$route.query.language
        : this.language
      console.log(findByLanguageCode)
      let languageObject = {}
      filterLimitedLanguages.find((item) => {
        if (
          this.getCuratedAndCustomTopicRegionTitle &&
          item.code === this.getCuratedAndCustomTopicRegionTitle.code
        ) {
          languageObject = item.languageList.find(
            (language) => language.code === findByLanguageCode
          )
        }
      })
      console.log(languageObject)
      return languageObject
    },
    getLanguageList () {
      let languageList = []
      this.languages.forEach((element) => {
        if (
          this.getCuratedAndCustomTopicRegionTitle &&
          element.code === this.getCuratedAndCustomTopicRegionTitle.code
        ) {
          languageList = element.languageList
        }
      })
      if (this.search_language.length > 0) {
        languageList = languageList.filter((language) =>
          language.languageName
            .toLowerCase()
            .includes(this.search_language.toLowerCase())
        )
      }
      return orderBy(languageList, ['languageName'], ['asc'])
    },
    query () {
      return this.$route.query || {}
    }
  },
  // watch: {
  //   // 'query': function (newVal, oldVal) {
  //   //   if (Object.keys(newVal).length === 0) {
  //   //     this.$router.replace({
  //   //       query: {
  //   //         ...oldVal
  //   //       }
  //   //     })
  //   //   }
  //   }
  // },
  methods: {
    ...mapActions(['setDiscoveryDefaultView']),
    closeDatePicker () {
      if (this.$refs.contentdatepicker) {
        this.$refs.contentdatepicker.clickAway()
      }
    },
    redirectSortView (sort) {
      if (
        Object.keys(this.$route.query).length === 0 ||
        (Object.keys(this.$route.query).length &&
          this.$route.query.from_date === undefined)
      ) {
        const startDate = moment(this.getFilterFromDate)
        const toDate = moment(this.getFilterToDate)

        this.$router.replace({
          query: {
            ...this.$route.query,
            sort: sort,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: toDate.format('YYYY-MM-DD')
          }
        })
      } else {
        this.$router.replace({ query: { ...this.$route.query, sort: sort } })
      }
    },
    redirectRegionView (region) {
      this.$router.replace({ query: { ...this.$route.query, region: region } })
    },
    redirectLanguageView (language) {
      this.$router.replace({
        query: { ...this.$route.query, language: language }
      })
    },
    initDateView () {
      // if there are no query parameters or if no date parem set
      if (
        Object.keys(this.$route.query).length === 0 ||
        (Object.keys(this.$route.query).length &&
          this.$route.query.from_date === undefined)
      ) {
        const fromDate = this.date
          ? moment(this.date.split(' - ')[0]).format('YYYY-MM-DD')
          : moment().utc().subtract(30, 'days').format('YYYY-MM-DD')
        const toDate = this.date
          ? moment(this.date.split(' - ')[1]).format('YYYY-MM-DD')
          : moment().utc().format('YYYY-MM-DD')

        const startDate = moment(fromDate)
        const endDate = moment(toDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD')
          }
        })
      }
    },
    redirectDateView (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD')
          }
        })
      }
    },
    /**
     * change view to grid or list
     * @param view
     * @returns {Promise<void>}
     */
    async changeViewLayout(view) {
      if (this.getDiscoveryView === view) {
        return
      }
      try {
        await this.setDiscoveryDefaultView(view)
        await this.$router.replace({query: {...this.$route.query, view: view}})
      }catch(e){
        console.log(e)
      }
    },
  }
}
</script>
