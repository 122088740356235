<template>
  <div
    id="component-dropdown"
    class="components-uitkit right-side-uitkit-container"
  >
    <h3>Dropdown</h3>
    <p>
      For the Dropdowns, we have different variations running. But, for the
      future, we will be using the following variation for the dropdown items.
    </p>

    <div class="uikit-codemirror">
      <codemirror v-model="single_dropdown" :options="cmOptions" />
    </div>

    <b-dropdown
      id="dropdown-right"
      variant="studio-theme"
      class="studio-theme-dropdown mr-2"
      no-caret
    >
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i>
        <span class="capitalize_text">Your Text</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>
    <hr />
    <h4> Dropdowns with different sizes </h4>

    <p> You can use the below example classes to your dropdown menu. </p>
    <ul>
      <li>Large e.g dropdown-size-large</li>
      <li>Medium e.g dropdown-size-medium</li>
      <li>Small e.g dropdown-size-small</li>
    </ul>

    <div class="uikit-codemirror">
      <codemirror v-model="dropdown_with_sizes" :options="cmOptions" />
    </div>
    <b-dropdown
      id="dropdown-right"
      variant="studio-theme"
      class="studio-theme-dropdown mr-2 dropdown-size-small"
      no-caret
    >
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i>
        <span class="capitalize_text">Your Text</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>
    <b-dropdown
      id="dropdown-right"
      variant="studio-theme"
      class="studio-theme-dropdown mr-2 dropdown-size-medium"
      no-caret
    >
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i>
        <span class="capitalize_text">Your Text</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>
    <b-dropdown
      id="dropdown-right"
      variant="studio-theme"
      class="studio-theme-dropdown dropdown-size-large"
      no-caret
    >
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i>
        <span class="capitalize_text">Your Text</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>

    <div class="uikit-codemirror">
      <codemirror v-model="checkbox_dropdown" :options="cmOptions" />
    </div>

    <b-dropdown
      ref="dropdown"
      text="Filter by type"
      variant="studio-theme"
      class="studio-theme-dropdown studio-theme-dropdown--checkbox m-3"
      no-caret
    >
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i>
        <span class="capitalize_text">Filter by type</span>
        <i class="icon-dropdown-cs"></i>
      </template>

      <b-dropdown-item @click.native.capture.stop>
        <div class="field_group">
          <div class="checkbox_container">
            <label>
              All
              <input type="checkbox" checked="checked" />
              <span class="check"></span>
            </label>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click.native.capture.stop>
        <div class="field_group">
          <div class="checkbox_container">
            <label>
              Private Replies
              <input type="checkbox" checked="checked" />
              <span class="check"></span>
            </label>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style lang="less">
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/base16-dark.css';
</style>

<script>
import { codemirror } from 'vue-codemirror'
export default {
  components: {
    codemirror,
  },
  data() {
    return {
      cmOptions: {
        tabSize: 4,
        mode: 'text/html',
        // theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        // more CodeMirror options...
      },

      single_dropdown: `    <b-dropdown variant="studio-theme" id="dropdown-right" class="studio-theme-dropdown mr-2 " no-caret>
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i> <span
          class="capitalize_text">Your Text</span> <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>`,
      dropdown_with_sizes: `
    <!-- small size dropdown-->
    <b-dropdown variant="studio-theme" id="dropdown-right" class="studio-theme-dropdown mr-2 dropdown-size-small" no-caret>
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i> <span
          class="capitalize_text">Your Text</span> <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>

    <!-- medium size dropdown-->
    <b-dropdown variant="studio-theme" id="dropdown-right" class="studio-theme-dropdown mr-2 dropdown-size-medium" no-caret>
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i> <span
          class="capitalize_text">Your Text</span> <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>

    <!-- large size dropdown-->
    <b-dropdown variant="studio-theme" id="dropdown-right" class="studio-theme-dropdown dropdown-size-large" no-caret>
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i> <span
          class="capitalize_text">Your Text</span> <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item>Your Item 1</b-dropdown-item>
      <b-dropdown-item>Your Item 2</b-dropdown-item>
    </b-dropdown>`,
      checkbox_dropdown: `<b-dropdown text="Filter by type"  ref="dropdown"  variant="studio-theme" class="studio-theme-dropdown studio-theme-dropdown--checkbox m-3" no-caret>
      <template slot="button-content">
        <i class="cs-trending-chart studio-theme-icon"></i> <span
          class="capitalize_text">Filter by type</span> <i class="icon-dropdown-cs"></i>
      </template>


      <b-dropdown-item @click.native.capture.stop>
        <div class="field_group">

          <div class="checkbox_container">
            <label>
              All
              <input type="checkbox" checked="checked">
              <span class="check"></span>
            </label>
          </div>

        </div>
      </b-dropdown-item>
      <b-dropdown-item @click.native.capture.stop>
        <div class="field_group">

          <div class="checkbox_container">
            <label>
              Private Replies
              <input type="checkbox" checked="checked">
              <span class="check"></span>
            </label>
          </div>

        </div>
      </b-dropdown-item>
    </b-dropdown>`,
    }
  },
}
</script>
