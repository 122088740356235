<template>
  <div
    class="modal fade normal_modal facebook_preview_modal"
    id="facebook_preview_modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Post Preview</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div
            class="fb_quote_box_grid article_box_grid article_box_grid_modal"
            v-if="getFacebookPreview._source"
          >
            <div class="box_inner">
              <div class="image_block">
                <template
                  v-if="getFacebookPreview._source.link.includes('videos')"
                >
                  <iframe
                    :src="
                      getFacebookVideoPreview(getFacebookPreview._source.link)
                    "
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allowFullScreen="true"
                  ></iframe>
                </template>
                <template v-else>
                  <img
                    :src="
                      backgroundFacebookImageURL(getFacebookPreview._source)
                    "
                  />
                </template>
              </div>

              <div class="content_block">
                <div class="users_detail">
                  <div class="profile_picture d-flex align-items-start fb">
                    <div
                      class="picture_block"
                      v-tooltip.top-center="
                        getPageName(getFacebookPreview._source)
                      "
                    >
                      <div class="icon">
                        <i class="cs-facebook"></i>
                      </div>
                      <img
                        :src="getPageImagePost(getFacebookPreview._source)"
                        alt=""
                      />
                    </div>
                    <div class="text_block">
                      <p class="text"
                        ><span class="bold">{{
                          getPageName(getFacebookPreview._source)
                        }}</span></p
                      >
                      <p class="sub_text">{{
                        $filters.relative(getArticlePreviewPostDate)
                      }}</p>
                    </div>
                  </div>
                </div>

                <template
                  v-if="
                    getFacebookPreview._source.description &&
                    getFacebookPreview._source.description.length > 2
                  "
                >
                  <p
                    class="desc"
                    v-html="getFacebookPreview._source.description"
                  ></p>
                </template>
                <template v-else>
                  <p
                    class="desc"
                    v-html="getFacebookPreview._source.message"
                  ></p>
                </template>

                <div class="social_stats">
                  <div class="social_stats_inner d-flex">
                    <div class="stat_item" v-tooltip.top-center="'Reactions'">
                      <div class="name">
                        <p>
                          <i class="cs-thumbs-up"></i>
                        </p>
                      </div>
                      <div class="value">
                        <h3>{{
                          intToString(
                            getFacebookPreview._source.reactions_count
                          )
                        }}</h3>
                      </div>
                    </div>
                    <div class="stat_item" v-tooltip.top-center="'Comments'">
                      <div class="name">
                        <p>
                          <i class="cs-comment-square"></i>
                        </p>
                      </div>
                      <div class="value">
                        <h3>{{
                          intToString(getFacebookPreview._source.comments)
                        }}</h3>
                      </div>
                    </div>
                    <div class="stat_item" v-tooltip.top-center="'Shares'">
                      <div class="name">
                        <p>
                          <i class="cs-share"></i>
                        </p>
                      </div>
                      <div class="value">
                        <h3>{{
                          intToString(getFacebookPreview._source.shares)
                        }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getFacebookPostID', 'getFacebookPreview'])
  },

  created () {},
  mounted () {},

  methods: {
    getFacebookImageURL (url) {
      console.log('background-url: ("' + url + '");')
      return 'background-url: (' + url + ');'
    },
    getFacebookVideoPreview (videoLink) {
      return (
        'https://www.facebook.com/plugins/video.php?href=' +
        videoLink +
        '%2F&show_text=0&width=560'
      )
    }
  }
}
</script>
