<template>
  <div class="insights_filters right_filter">
    <div class="date_filter">
      <!-- date picker -->

      <DateRangePicker
        ref="contentdatepicker"
        class="datepicker_input ml-2"
        :opens="date_range_pickers.normal.opens"
        :start-date="getFilterFromDate"
        :end-date="getFilterToDate"
        :max-date="getMaxDate"
        :locale-data="date_range_pickers.normal.locale"
        :single-date-picker="date_range_pickers.normal.singleDatePicker"
        :time-picker="date_range_pickers.normal.timePicker"
        :time-picker24hour="date_range_pickers.normal.timePicker24Hour"
        :show-week-numbers="date_range_pickers.normal.showWeekNumbers"
        :show-dropdowns="date_range_pickers.normal.showDropdowns"
        @update="redirectInsightsDateView"
        :auto-apply="date_range_pickers.normal.autoApply"
        :ranges="date_range_pickers.normal.ranges"
      >
        <div
          slot="input"
          slot-scope="picker"
          class="datepicker_content discovery_white"
        >
          <i class="icon-Calendar"></i>

          <span v-if="getSearchDatePicker(picker.startDate, picker.endDate)">
            {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
          </span>
          <span v-else-if="picker.label">
            {{ picker.label }}
          </span>
          <span v-else> Last 3 months </span>
        </div>
      </DateRangePicker>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  components: {
    DateRangePicker
  },
  data () {
    return {
      date_range_pickers: {
        normal: {
          locale: {
            format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
          },

          opens: 'left',
          startDate: '2017-09-19',
          endDate: '2017-10-09',
          minDate: '2016-09-02',
          maxDate: this.getMaxDate,
          dateRange: {
            endDate: moment().utc()
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          ranges: {
            'All Time': [moment.utc().subtract(1.5, 'years'), moment.utc()],
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
            'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
            'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
            'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
            'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()]
            // 'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
          }
        }
      }
    }
  },
  computed: {
    getMaxDate () {
      console.log(moment.utc().format('YYYY-MM-DD'))
      return moment.utc().format('YYYY-MM-DD')
    },
    getFilterFromDate () {
      return (
        this.$route.query.from_date ||
        moment().utc().subtract(90, 'days').format('YYYY-MM-DD')
      )
    },
    getFilterToDate () {
      return this.$route.query.to_date || moment().utc().format('YYYY-MM-DD')
    }
  },
  methods: {
    redirectInsightsDateView (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD')
          }
        })
      }
    }
  }
}
</script>
