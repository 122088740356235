<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getEvergreenAutomationBulk'])
  },
  methods: {
    ...mapActions(['uploadCsvFileEvergreen'])
  }
}
</script>
<template>
  <div class="c_box">
    <label for="b_upload" class="box_inner d-block">
      <div class="img_icon">
        <img
          class="selected_img"
          src="../../../../../assets/img/evergreen/bulk_active.png"
          alt=""
        />
        <img
          class="normal_img"
          src="../../../../../assets/img/evergreen/bulk_inactive.png"
          alt=""
        />
      </div>
      <h3 class="icon_text">Bulk Upload via CSV</h3>
      <p v-if="getEvergreenAutomationBulk.csvFileUpload" class="uploading_text"
        >Uploading Your Posts...</p
      >
    </label>

    <input
      id="b_upload"
      :disabled="getEvergreenAutomationBulk.csvFileUpload"
      accept=".csv, text/csv"
      style="display: none"
      class="hide"
      @change="uploadCsvFileEvergreen($event)"
      type="file"
    />
    <p class="bulk_example"
      >Please use the following template for the Bulk Upload:
      <a
        href="https://storage.cloud.google.com/lumotive-web-storage/evergreen_csv_template.csv"
        >Click Here</a
      ></p
    >
  </div>
</template>
