var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
    (_vm.getPublishLoaders.fetchHashTagsSuggestions ||
      (_vm.getSocialSharingSelection.hashTagsSuggestions.data &&
        _vm.getSocialSharingSelection.hashTagsSuggestions.data.length)) &&
    _vm.type === _vm.getSocialSharingSelection.hashTagsSuggestions.box
  )?_c('div',{staticClass:"social_mention_dropdown social_hashtag_dropdown",class:{
    move_left:
      _vm.getSocialSharingSelection.hashTagsSuggestions.dropdown.left > 530,
  },style:({
    left: _vm.getSocialSharingSelection.hashTagsSuggestions.dropdown.left + 'px',
    top: _vm.getSocialSharingSelection.hashTagsSuggestions.dropdown.top + 'px',
  })},[(_vm.getPublishLoaders.fetchHashTagsSuggestions)?_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"no_text"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):[_vm._m(0),_c('ul',{staticClass:"inner",on:{"keyup":_vm.nextItem,"keydown":_vm.selectItem}},[_vm._l((_vm.getSocialSharingSelection
          .hashTagsSuggestions.data),function(suggestion,idx){return [(suggestion.name)?_c('li',{key:idx,staticClass:"hashtag-suggestions-dropdown-list-item",class:{ 'active-item': _vm.currentItem === idx },on:{"click":function($event){return _vm.addSharingHashtags(suggestion, _vm.type)}}},[_c('div',{staticClass:"profile_picture d-flex align-items-center"},[_c('div',{staticClass:"text_block"},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"text_left"},[_vm._v("#"+_vm._s(suggestion.name)+" "),(suggestion.popularity)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Popularity for this hashtag'),expression:"'Popularity for this hashtag'",modifiers:{"top-center":true}}],staticClass:"hashtagnumber",class:_vm.getTweetColors(suggestion.popularity),staticStyle:{"background":"rgb(243 243 243)"}},[_vm._v(_vm._s(suggestion.popularity))]):_vm._e()])])])])]):_vm._e()]})],2)]],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"padding":"1em"}},[_c('b',[_vm._v("Suggested Hashtags")])])
}]

export { render, staticRenderFns }