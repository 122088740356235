<template>
  <div>
    <CstDropdown
      class="mr-3"
      size="small"
      button-classes="bg-blue-500 text-white hover:bg-blue-600 rounded-lg px-3.5"
      container-classes="!w-max"
    >
      <template v-slot:selected>
        <span class="font-medium">Compose</span>
      </template>
      <template v-slot:arrow>
        <i class="fas fa-angle-down text-lg"></i>
      </template>
      <template>
        <div class="p-4 text-center">
          <span class="block font-semibold text-base mb-4"
            >What would you like to Compose?</span
          >
          <div class="block">
            <div
              class="inline-block cursor-pointer border border-blue-500 hover:bg-gray-100 rounded-xl text-center w-max p-2 min-w-[130px] mx-3"
              @click.prevent="changePublishType('Composer Social')"
            >
              <img
                class="w-16"
                src="@assets/img/composer/social_post_.svg"
                alt=""
              />
              <span class="mt-3 block font-medium text-sm"
                >Social Media Post</span
              >
            </div>
            <div
              class="inline-block cursor-pointer border border-blue-500 hover:bg-gray-100 rounded-xl text-center w-max p-2 min-w-[130px] mx-3"
              @click.prevent="changePublishType('Composer Article')"
            >
              <img
                class="w-12"
                src="@assets/img/composer/blog_post_.svg"
                alt=""
              />
              <span class="mt-3 block font-medium text-sm">Blog Post</span>
            </div>
          </div>
        </div>
      </template>
    </CstDropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CstDropdown from '@ui/Dropdown/CstDropdown'
export default {
  components: {
    CstDropdown,
  },
  computed: {
    ...mapGetters([]),
  },
}
</script>

<style scoped></style>
