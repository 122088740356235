<template>
  <div
    v-if="type === 'chart'"
    :style="{ height, width: computedWidth, borderRadius: radius }"
    class="skeletonBox skeletonBox-chart"
  >
    <span style="height: 90%" :style="{ backgroundColor: color }"></span>
    <span style="height: 70%" :style="{ backgroundColor: color }"></span>
    <span style="height: 50%" :style="{ backgroundColor: color }"></span>
    <span style="height: 80%" :style="{ backgroundColor: color }"></span>
  </div>
  <span
    v-else
    :style="{
      height,
      width: computedWidth,
      borderRadius: radius,
      backgroundColor: color,
    }"
    class="skeletonBox"
    :class="{ 'skeletonBox--inner': innerFill }"
  />
</template>

<script>
export default {
  name: 'SkeletonBox',
  props: {
    type: {
      default: 'simple',
      type: String,
    },
    maxWidth: {
      // The default maxiumum width is 100%.
      default: 100,
      type: Number,
    },
    minWidth: {
      // Lines have a minimum width of 80%.
      default: 80,
      type: Number,
    },
    height: {
      // Make lines the same height as text.
      default: '1rem',
      type: String,
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String,
    },
    radius: {
      // Border radius of the box.
      default: '.2rem',
      type: String,
    },
    color: {
      // background color of the box.
      default: '#f0f0f0',
      type: String,
    },
    innerFill: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedWidth() {
      // Either use the given fixed width or
      // a random width between the given min
      // and max values.
      return (
        this.width ||
        `${Math.floor(
          Math.random() * (this.maxWidth - this.minWidth) + this.minWidth
        )}%`
      )
    },
  },
}
</script>

<style lang="scss">
.skeletonBox {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f0f0f0;

  &--inner {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 70%;
      width: 70%;
      background: #fff;
      border-radius: 50%;
    }
  }

  &:not(.skeletonBox--noanimcation)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 0.8s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeletonBox-chart {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  border: 3px solid #f0f0f0;
  background: transparent;
  padding: 0.6rem;

  span {
    width: calc(100% / 4);
    margin: 0 0.8rem;
  }
}
</style>
