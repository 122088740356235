<template>
  <div
    class="cursor-pointer w-full h-full rounded-sm border border-[1.5px] border-[#99a3a982] bg-transparent bg-opacity-70 text-cs-primary flex justify-center items-center text-[0.65rem]"
    :class="{ '!border-blue-500': checked }"
  >
    <template v-if="checked">
      <i class="far fa-check text-[#549aee]"></i>
    </template>
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CheckBox',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
