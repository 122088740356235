var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showLinkPreview)?_c('div',{staticClass:"link_preview d-flex align-items-start",attrs:{"data-cy":"link-preview"}},[_c('i',{staticClass:"cross_icon cs-cross",on:{"click":function($event){$event.preventDefault();return _vm.cancelSocialPreview(_vm.type)}}}),_c('div',{staticClass:"img_section"},[(
        _vm.details.image &&
        _vm.details.image.constructor === Array &&
        _vm.details.image.length
      )?_c('img',{staticClass:"img-cover img 1",attrs:{"draggable":"false","src":_vm.getResizedImageURL(_vm.details.image[0], 250, 0),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}}):(
        _vm.details.image && _vm.details.image.constructor !== Array && _vm.details.image
      )?_c('img',{staticClass:"img-cover img 2",attrs:{"draggable":"false","src":_vm.getResizedImageURL(_vm.details.image, 250, 0),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}}):_c('div',{staticClass:"img",staticStyle:{"background":"url('https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png')"}})]),_c('div',{staticClass:"link_preview_content"},[_c('h3',[_vm._v(_vm._s(_vm.details.title))]),_c('p',{staticClass:"link"},[_vm._v(_vm._s(_vm.details.website))]),_c('p',[_vm._v(_vm._s(_vm.details.description))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }