<template>
  <div>
    <div class="competitor-overview__card-header d-flex align-items-center"
    >
      <div class="compare-box__platform">
        <i class="fab fa-instagram ins "></i>
      </div>
      Top Posts
      <div class="ml-auto">
        <i
            class="fa fa-info info_hover"
            v-b-popover.hover.html.topleft="description"
        ></i>
      </div>
    </div>
    <div class="competitor-overview__card-body">
      <div
          v-if="!state"
          class="opacity-70"
          style="display: flex; justify-content: center"
      >
        <clip-loader
            class="ml-2"
            :color="'#5773fa'"
            :size="'20px'"
        ></clip-loader>
      </div>
      <p
          class="text-center"
          v-if="Object.keys(competitors_data).length <= 0"
      >
        No Competitor Available
      </p>
      <div
          class="grid-flex mb-3"
          v-for="(val, key) in active_competitors"
          :key="key"
          v-if="Object.keys(competitors_data).length > 0"
      >
        <div class="cell-md-12 rounded pb-4" v-if="competitors_data.hasOwnProperty(key)" style="background: #f4f7fa">
          <div class=" d-flex align-items-center mt-3 ml-4 mb-3">
            <div class="analytics-post-card__header-img mr-2">
              <img :src="val.image" alt=""
                   @error="$event.target.src = fallbackImagePreview(val.display_name[0]) "/>
            </div>
            <div class="analytics-post-card__header-detail">
              <h3>Top Posts By {{ val.display_name }}</h3></div>
          </div>
          <div class="grid-flex">
            <div v-if="competitors_data[key].length == 0" class="cell-md-12">
              <div class="flex flex-col justify_center items-center">
                <img style="height:15rem; width:100%"
                     src="../../../../assets/imgs/no_data_images/no_post_found.svg"
                     alt=""
                />
                <p style="margin-top: 1.6rem">No data found within applied filters.</p>
              </div>
            </div>
            <div
                class="cell-md-3"
                v-for="(post, index) in competitors_data[key]"
                :key="index"
                v-else
            >
              <PostCardCompetitors
                  :post="post"
                  platform_type="instagram"
                  v-if="!post.hasOwnProperty('status')"
              />
              <!--              <div v-else>-->
              <!--                <div class="analytics-post-card">-->
              <!--                  <div class="analytics-post-card__header">-->
              <!--                    <div class="analytics-post-card__header-left">-->
              <!--                      <div class="analytics-post-card__header-img">-->
              <!--                        <SkeletonBox class="skeletonBox&#45;&#45;noanimcation"-->
              <!--                            height="50px"-->
              <!--                            width="50px"-->
              <!--                            radius="50%"-->
              <!--                        />-->
              <!--                      </div>-->
              <!--                      <div class="analytics-post-card__header-detail">-->
              <!--                        <div-->
              <!--                            class="d-flex"-->
              <!--                            style="flex-direction: column; margin-left: 10px"-->
              <!--                        >-->
              <!--                          <div class="mb-1">-->
              <!--                            <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" width="100px" radius=".2rem" />-->
              <!--                          </div>-->
              <!--                          <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" radius=".2rem" />-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div-->
              <!--                        class="analytics-post-card__header-right"-->
              <!--                        style="filter: grayscale(100%); opacity: 0.6"-->
              <!--                    >-->
              <!--                      &lt;!&ndash; Dynamic Icon bases on props &ndash;&gt;-->
              <!--                      <i class="icon-instagram_icon"></i>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                  <div class="analytics-post-card__content">-->
              <!--                    <div class="mb-1">-->
              <!--                      <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" width="100%" radius=".2rem" />-->
              <!--                    </div>-->
              <!--                    <div class="mb-2">-->
              <!--                      <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" radius=".2rem" />-->
              <!--                    </div>-->
              <!--                    <div class="mb-2">-->
              <!--                      <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" width="100%" height="260px" radius="15px" style="margin-top: 1rem" />-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                  <ul class="analytics-post-card__content-ele">-->
              <!--                    <li v-for="i in 3" :key="i">-->
              <!--                      <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" width="40%" height="21px" radius=".2rem" />-->
              <!--                      <SkeletonBox class="skeletonBox&#45;&#45;noanimcation" width="1.1rem" radius=".2rem" />-->
              <!--                    </li>-->
              <!--                  </ul>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import api_ops from "@src/modules/analytics/views/common/competitors/instagram/api_ops";
import PostCardCompetitors from './PostCardCompetitors'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'

export default {
  name: "postCards",
  props: ['reload', 'selected_competitors', 'time_period', 'selected_account_id'],
  mixins: [analyticsUtilsMixin, api_ops],
  components: {PostCardCompetitors, SkeletonBox},
  data() {
    return {
      description: "<span class=\"font-0-75rem\">The section displays top 4 posts of each competitor with respect to engagement. The notch on the top-right can be used to navigate to actual post. Some useful data metrics are also visible in post cards</span>",
      requestCancelPromise: {},
      active_competitors: {},
      state: false,
      competitors_data: {}

    }
  },
  watch: {
    reload(newValue, oldValue) {
      this.active_competitors = {}
      this.competitors_data = {}
      this.state = false
      this.fetchChartData()
    }
  },

  mounted() {
    this.state = false
    this.fetchChartData()
  },
  methods: {
    fetchChartData() {
      let our = {}
      let theirs = this.selected_competitors.filter((obj) => {
        if (obj.competitor_id == this.selected_account_id) {
          our = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.display_name > b.display_name ? 1 : -1)
      let competitors = [our, ...theirs]
      competitors.forEach((obj) => {
        if (obj['enabled'] && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj['competitor_id']] = obj
        }
      })
      this.cancelPendingRequest('pagePosts')
      let ids = Object.keys(this.active_competitors);
      let promises = []
      ids.forEach((id) => {
        let promise = this.getIgTopPosts([id], this.time_period)
        this.requestCancelPromise['pagePosts'].push(promise[0])
        promises.push(promise[1])
        promise[1].then((res) => {
          if (res.data.data.length > 0) {
            this.competitors_data[id] = [...res.data.data]
          } else {
            this.competitors_data[id] = []
          }
          // let iter = 4 - this.competitors_data[id].length
          // for (let i = 0; i < iter; i++) {
          //   console.log('pikachu', this.competitors_data[id].length, i, this.active_competitors[id].display_name)
          //   if(iter == 4){
          //   this.competitors_data[id].push({'status': null})}
          // }
        })

      })
      Promise.all(promises).then(() => {
        this.state = true
      })

    },

    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
  }

}
</script>

<style scoped>

</style>
