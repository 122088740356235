var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 d-flex flex-column"},[_c('InboxConversationHead',{attrs:{"active-inbox-detail":_vm.activeInboxDetail}}),_c('SavedReplyModal',{attrs:{"active-inbox-detail":_vm.activeInboxDetail,"apply-saved-reply":_vm.applySavedReply}}),(_vm.activeMessages)?_c('div',{staticClass:"conversation_messages",attrs:{"id":"chat_conversation_block"}},[(_vm.timestamp === null)?_c('div',{staticClass:"sep_line"},[_c('strong',{staticClass:"text"},[_vm._v("This is the start of this conversation")])]):(_vm.activeMessages.length <= 0)?_c('div',{staticClass:"sep_line"},[_c('strong',{staticClass:"text"},[_vm._v("No messages found")])]):_c('div',{staticClass:"loader"},[(_vm.isScrollActive)?_c('InfiniteLoading',{attrs:{"spinner":"spiral","direction":"top","distance":_vm.scrollDistance()},on:{"infinite":_vm.infiniteHandler}}):_vm._e(),_c('clip-loader',{staticClass:"d-inline-block align-middle ml-1",attrs:{"color":"#989eb5","size":'20px'}})],1),_vm._l((_vm.activeMessages),function(messages,index){return [_c('div',{key:index,staticClass:"sep_line"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(index))])]),_vm._l((messages),function(message,message_index){return [(
            message.event_type === 'assigned' ||
            message.event_type === 'marked_done' ||
            message.event_type === 'archived'
          )?_c('MessageStatus',{key:message.uuid,attrs:{"message":message}}):(message.sending)?_c('MessageSending',{key:message.uuid,attrs:{"message":message,"message-loader":_vm.messageLoader,"temp":_vm.temp,"index":index,"message-index":message_index,"append-in-active-messages":_vm.appendInActiveMessages,"remove-message":_vm.removeMessage,"emit-event-for-note":_vm.emitEventForNote}}):_c('MessageDialogue',{key:message.uuid,attrs:{"message":message,"temp":_vm.temp,"index":index,"message-index":message_index,"update-bookmark-status":_vm.updateBookmarkStatus}})]})]}),(_vm.typingUsers.length > 0)?_c('div',{staticClass:"message m_out"},[_c('div',{staticClass:"sep_line conversation_notification"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"user_block d-flex align-items-center"},[_c('div',{staticClass:"user_content"},[_c('p',{staticClass:"name"}),_c('p',{staticClass:"name"},[_vm._l((_vm.typingUsers),function(typingUser,index){return _c('span',{key:index},[_c('strong',[_vm._v(_vm._s(typingUser))]),(
                      _vm.typingUsers.length === 1 ||
                      _vm.typingUsers.length - 1 === index
                    )?_c('span'):(_vm.typingUsers.length - 2 === index)?_c('span',[_vm._v(" and ")]):(_vm.typingUsers.length > 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),_vm._v(" is typing... ")],2)])])])])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"chat_tool",class:{ _note_active: _vm.sendAs === 'Note' }},[_c('div',{staticClass:"chat_tool_inner"},[_c('div',{staticClass:"c_bottom"},[(_vm.sendAs !== 'Note')?[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageToSend),expression:"messageToSend"}],ref:"common_sharing_message_inbox",attrs:{"id":"common_sharing_message","name":"","placeholder":_vm.getPlaceholder(),"disabled":_vm.messageLoader,"cols":"30","rows":"10","maxlength":_vm.activeInboxDetail.platform === 'instagram' ? '1000' : ''},domProps:{"value":(_vm.messageToSend)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.send($event)},"input":[function($event){if($event.target.composing)return;_vm.messageToSend=$event.target.value},_vm.typingStatusChanged]}})]:[_c('AtTa',{ref:"at",attrs:{"members":_vm.teamMembers,"name-key":"name"},on:{"insert":_vm.mentionMember},scopedSlots:_vm._u([{key:"item",fn:function(s){return _c('div',{key:s.item.id,attrs:{"value":s.item.id}},[_c('img',{attrs:{"src":s.item.image
                    ? s.item.image
                    : _vm.commonMethods.getDefaultImage(),"height":"20","width":"20"}}),_c('span',{domProps:{"textContent":_vm._s(s.item.name)}})])}}])},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageToSend),expression:"messageToSend"}],ref:"common_sharing_note_message_inbox",attrs:{"id":"common_sharing_note_message","name":"","placeholder":'Add a note to this conversation...',"disabled":_vm.messageLoader,"cols":"30","rows":"10"},domProps:{"value":(_vm.messageToSend)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.send($event)},"change":_vm.typingStatusChanged,"input":function($event){if($event.target.composing)return;_vm.messageToSend=$event.target.value}}})])]],2),_c('div',{staticClass:"c_top d-flex align-items-center"},[_c('div',{staticClass:"top_left"},[(_vm.sendAs !== 'Note')?_c('div',{staticClass:"attachment_btn mr-3"},[_c('label',{attrs:{"for":"attachment_file"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Attach a file'),expression:"'Attach a file'",modifiers:{"bottom-center":true}}],staticClass:"far fa-paperclip"})]),_c('input',{ref:"fileInput",attrs:{"id":"attachment_file","type":"file","data-index":"","accept":_vm.activeInboxDetail.platform === 'instagram'
                  ? 'image/x-png,image/jpeg'
                  : '*'},on:{"change":function($event){return _vm.attachMedia($event)}}}),(_vm.attachment !== null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.attachment.name),expression:"attachment.name",modifiers:{"top-center":true}}],staticClass:"attachment_file"},[_c('i',{staticClass:"d-flex align-items-center justify-content-center attachment-type fal fa-file"}),_vm._v(" "+_vm._s(_vm.commonMethods.limitText(_vm.attachment.name, 15))+" "),_c('i',{staticClass:"d-flex align-items-center justify-content-center cross_btn fal fa-times",on:{"click":_vm.removeMedia}})]):_vm._e()]):_vm._e(),_c('Emoji',{attrs:{"dropdown-position":"top_side","type":"Common"},model:{value:(_vm.messageToSend),callback:function ($$v) {_vm.messageToSend=$$v},expression:"messageToSend"}}),(_vm.sendAs !== 'Note')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Saved Replies'),expression:"'Saved Replies'",modifiers:{"bottom-center":true}}],staticClass:"icon-Save attachment_btn m-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$bvModal.show('savedReplyModal')}}}):_vm._e()],1),_c('div',{staticClass:"top_right ml-auto d-flex align-items-center"},[(
              (_vm.messageToSend !== '' || _vm.attachment !== null) &&
              _vm.messageLoader === false
            )?_c('div',{staticClass:"d-flex align-items-center justify-content-left failed_option",staticStyle:{"margin-bottom":"0px","margin-right":"0.625rem"},on:{"click":function($event){_vm.messageToSend = ''
              _vm.attachment = null}}},[_c('span',{staticClass:"link"},[_vm._v("Clear ")])]):_vm._e(),_c('div',{staticClass:"btn_dropdown btn-group default_style_dropdown inbox_message_dropdown radio_dropdown"},[_c('button',{staticClass:"btn_click btn",attrs:{"type":"button","disabled":_vm.messageToSend === '' &&
                _vm.attachment === null &&
                _vm.messageLoader === false},on:{"click":function($event){return _vm.send(null)}}},[_vm._v(" "+_vm._s(_vm.sendAs === 'Message' ? 'Send ' : 'Add ')+" "+_vm._s(_vm.sendAs)+" "),(_vm.messageLoader)?_c('clip-loader',{staticClass:"d-inline-block align-middle ml-1",attrs:{"color":"#ffffff","size":'14px'}}):_vm._e()],1),_vm._m(0),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('ul',{staticClass:"inner"},_vm._l((_vm.sendingOptions),function(option){return _c('li',{key:option,staticClass:"list_item_li"},[_c('div',{staticClass:"radio_input_image d-block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendAs),expression:"sendAs"}],attrs:{"id":option,"checked":"","type":"radio","name":"sendMessageType"},domProps:{"value":option,"checked":_vm._q(_vm.sendAs,option)},on:{"change":function($event){_vm.sendAs=option}}}),_c('label',{staticClass:"radio_right with_checked_icon d-block",attrs:{"for":option}},[_c('div',{staticClass:"user_block d-flex align-items-center"},[_c('div',{staticClass:"user_content"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(option))])])])])])])}),0)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn_arrow btn dropdown-toggle dropdown-toggle-split",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"dropdown_arrow icon_last cs-angle-down"})])
}]

export { render, staticRenderFns }