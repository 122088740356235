<template>
  <div class="compare-box height-100">
    <div class="compare-box__header flex align-items-center">
      <div class="compare-box__platform">
        <i class="fab fa-instagram ins "></i>
      </div>
      {{ chartTypes[type]['title'] }}
      <div class="ml-auto">
        <i
            class="fa fa-info info_hover"
            v-b-popover.hover.html.topleft="chartTypes[type]['description'] "
        ></i>
      </div>
    </div>

    <div
        v-if="!state"
        class="opacity-70"
        style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'20px'"></clip-loader>
    </div>
    <div class="h-chart" v-else>
      <p class="text-center" v-if="false">
        Data not found for the selected pages and filters
      </p>
      <highcharts
          ref="growth-chart-container"
          :options="dataOptions"
      >
      </highcharts>
    </div>
  </div>
</template>

<script>
import api_ops from "./api_ops";
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import {themeColors} from '@src/theme'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: 'ColumnChart',
  mixins: [analyticsUtilsMixin, api_ops],
  data() {
    return {
      dataOptions: {
        title: {
          text: ' '
        },
        chart: {
          marginTop: 50,
          type: 'line'
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },

        xAxis: {},

        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          y: -30,
          itemMarginBottom: 0,
          itemStyle: {
            fontSize: '12px',
            fontWeight: 'bold',
            textOverflow: 'ellipsis'
          }
        },

        tooltip: {
          headerFormat:
              '<table ><tr><th class="pb-2" style="border-bottom: 1px solid #ddd; padding-right: 6rem; color: #595c5f;" colspan="2">{point.key}</th></tr><tbody><tr style="outline: thin solid transparent;\n' +
              '  height: 10px;"></tr>',
          pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
              '<td style="text-align: right" class="font-semibold">{point.y} </td></tr>',
          footerFormat: '</tbody></table>',
          backgroundColor: '#FFFFFF',
          borderColor: themeColors.blue[800],
          borderRadius:25,
          borderWidth:1,
          shared: true,
          useHTML: true,
          crosshairs: [true]
        },
        colors: [
          themeColors.purple[500],
          themeColors.green[500],
          themeColors.pink[500],
          themeColors.orange[600],
          themeColors.red[500],
          themeColors.blue[500],
        ],
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              radius: 3,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 1,
              lineColor: null // inherit from series
            },
            shadow: false,
            lineWidth: 2,
            pointWidth: 5
          }
        },
        series: []
      },
      chartTypes: {
        "FanCount": {
          "title": "Followers Count",
          "chart_type": "line",
          "datatype": {
            'type': 'datetime',
            'labels': {
              'format': '{value:%e-%b}'
            }
          },
          "x": {"column": "timestamp", "dtype": "date"},
          "y": {"column": "followers", "dtype": "int"},
          "description": "The graph displays page\'s fans count on the number of days in the applied filter. The graph can be helpful to get an overview of fans count over a specific period of time. The graph will display data from the day competitor is connected.</span>",
          "promise": this.getIgFanCount
        },
        "FanGrowth": {
          "title": "Followers Growth",
          "chart_type": "column",
          "datatype": {
            'type': 'datetime',
            'labels': {
              'format': '{value:%e-%b}'
            }
          },
          "x": {"column": "timestamp", "dtype": "date"},
          "y": {"column": "followers_change", "dtype": "int"},
          "description": "<span class=\"font-0-75rem\">The graph displays page\'s fans growth on the number of days in the applied filter. The graph can be helpful to get an overview of fans growth over a specific period of time. The metric displays fans difference between two subsequent days. The graph will display data from the 2nd day competitor is connected.</span>",
          "promise": this.getIgFanChange
        },
        "AvgPostEng": {
          "title": "Average Post Engagement Per Day",
          "chart_type": "line",
          "datatype": {
            'type': 'datetime',
            'labels': {
              'format': '{value:%e-%b}'
            }
          },
          "x": {"column": "timestamp", "dtype": "date"},
          "y": {"column": "avg_day_engagement", "dtype": "int"},
          "description": "<span class=\"font-0-75rem\">The graph displays a series of data about page\'s average post engagement. Each data point represents average post engagement on a certain day. The metric is calculated by:</br><table class=\"formula-table\"><tr><td>Total Engagement On A Certain Day</td></tr> <tr><td>Total Number Of Posts On That Day</td></tr></table></span>",
          "promise": this.getIgAvgPostEngagement
        },
        "PostEng": {
          "title": "Post Engagement Per Day",
          "chart_type": "line",
          "datatype": {
            'type': 'datetime',
            'labels': {
              'format': '{value:%e-%b}'
            }
          },
          "x": {"column": "timestamp", "dtype": "date"},
          "y": {"column": "day_engagement", "dtype": "int"},
          "description": "<span class=\"font-0-75rem\">The graph displays a series of data about page\'s daily post engagement. Each data point represents post engagement on a certain day. The metric can be useful to overview page\'s engagement trends over a time period.</span>",
          "promise": this.getIgPostEngagement
        },
        "EngPostType": {
          "title": "Engagement By Post Type",
          "chart_type": "column",
          "datatype": {
            'type': 'category'
          },
          "x": {"column": "post_type", "dtype": "string"},
          "y": {"column": "sum_engagement", "dtype": "int"},
          "description": "<span class=\"font-0-75rem\">The chart displays engagement values with respect to the data type of posts. This chart can be helpful to visualize what kind of posts are getting better engagement for a certain domain.</span>",
          "promise": this.getIgPostTypeEngagement
        }
      },
      requestCancelPromise: {},
      active_competitors: {},
      state: false

    }
  },
  props: ['type', 'reload', 'selected_competitors', 'time_period', 'selected_account_id'],

  watch: {
    reload(newValue, oldValue) {
      this.active_competitors = {}
      this.dataOptions.xAxis = {}
      this.dataOptions.series = []
      this.state = false
      this.fetchChartData()
    }
  },

  mounted() {
    this.state = false
    this.fetchChartData()
  },
  methods: {
    fetchChartData() {

      let our = {}
      let colors = [...this.dataOptions.colors]
      let theirs = this.selected_competitors.filter((obj) => {
        if (obj.competitor_id == this.selected_account_id) {
          our = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.display_name > b.display_name ? 1 : -1)
      let competitors = [our, ...theirs]
      competitors.forEach((obj) => {
        if (obj['enabled'] && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj['competitor_id']] = obj
        }
      })

      this.cancelPendingRequest(this.type)
      let ids = Object.keys(this.active_competitors);
      let promises = []
      ids.forEach((id) => {
        let promise = this.chartTypes[this.type]['promise']([id], this.time_period)
        this.requestCancelPromise[this.type].push(promise[0])
        promises.push(promise[1])
        let paint = colors.pop()
        promise[1].then((res) => {
          let page_name = this.active_competitors[res.data.data[0]['ig_id']].display_name
          this.dataOptions.series.push({
            'data': [],
            'name': page_name,
            'id': page_name,
            'color':paint,
            marker: {
              enabled: true
            }
          })
          this.dataOptions.series.push({
            'name': page_name,
            'linkedTo': page_name,
            'color':paint,
            'data': res.data.data.map((obj) => {
              let x = this.getDataByType(obj[this.chartTypes[this.type]['x']['column']], this.chartTypes[this.type]['x']['dtype'])
              let y = this.getDataByType(obj[this.chartTypes[this.type]['y']['column']], this.chartTypes[this.type]['y']['dtype'])
              return [x, y]
            })
          })
          this.dataOptions = {...this.dataOptions}
        })
      })
      Promise.all(promises).then(() => {
        this.dataOptions.series = this.dataOptions.series.sort((a, b) => a.name > b.name ? 1 : -1)
        this.dataOptions.xAxis = this.chartTypes[this.type]['datatype']
        if (this.chartTypes[this.type]['datatype']['type'] == 'datetime') {
          this.dataOptions.xAxis.tickInterval = this.getTickVal(5)
        }
        this.dataOptions.chart.type = this.chartTypes[this.type]['chart_type']
        this.dataOptions = {...this.dataOptions}
        this.state = true
      })

    },
    getDataByType(value, type) {
      if (type == 'date') {
        value = new Date(value).valueOf()
      } else if (type == 'int') {
        value = parseInt(value)
      } else if (type == 'string') {
        value = value.toString()
      }
      return value
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
    getTickVal(points) {
      let time_filter = this.time_period.split(" - ");
      let days = (Date.parse(time_filter[1]) - Date.parse(time_filter[0])) / (1000 * 3600 * 24)
      return (Math.round(days / points) * (24 * 3600 * 1000))
    }
  }
}
</script>
