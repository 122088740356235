var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown default_style_dropdown emoji_dropdown prevent_close_dropdown ml-2"},[_c('div',{staticClass:"dropdown_header",attrs:{"data-toggle":"dropdown"},on:{"click":function($event){_vm.loaded = true}}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Add an emoji'),expression:"'Add an emoji'",modifiers:{"bottom-center":true}}],staticClass:"fal fa-smile-beam d-block",class:{ 'thread-emoji': _vm.type.includes('ThreadedTweets_') }})]),_c('div',{staticClass:"dropdown-menu emojis-list-position",class:{
      'dropdown-menu-left': _vm.dropdownPosition === 'left_side',
      'dropdown-menu-bottom': _vm.dropdownPosition === 'bottom_side',
      'dropdown-menu-right': _vm.dropdownPosition === 'right_side',
      'dropdown-menu-right': !_vm.dropdownPosition,
      'dropdown-menu-top': _vm.dropdownPosition === 'top_side',
    },staticStyle:{"top":"-425px !important","transform":"unset !important"}},[_c('ul',{staticClass:"inner"},[_c('ul',[_c('div',{staticClass:"emoji_list"},[(_vm.loaded)?[_c('Picker',{staticClass:"emoji-mart-wrap",attrs:{"data":_vm.index,"emoji-size":18,"emoji-tooltip":true,"native":false,"emoji":"point_up","title":"Pick your emoji..."},on:{"select":_vm.addEmoji}})]:_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }