<template>
  <confirm-box
    id="saveVideoAutomation"
    :callBackAction="saveVideoAutomation"
    confirmText="Yes"
    cancelText="No"
  >
    <template slot="header">
      <h2>Activate Campaign</h2>
    </template>

    <template slot="footer">
      <p>Your campaign is not active. Would you like to activate?</p>
    </template>
  </confirm-box>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
