<template>
  <div>
    <chart-render
      :id="'followers_rate_highlight'"
      :options="followersRate()"
      :title="'Followers Growth'"
      :ref_value="'followers_chart'"
      :is_content_available="isContentNotAvailable"
      :tooltip_message="tooltip_message"
    ></chart-render>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import {
  NO_CHART_DATA,
  PINTEREST_ANALYTICS_AUDIENCE_GROWTH
} from '@common/constants/messages'
import {
  splineChart,
  splineCredits,
  splineLegend,
  splinePlotOptions,
  splineTooltip,
  splineXAxis,
  splineYAxis
} from '../../common/helper'

import ChartRender from '../../common/ChartRender'

export default {
  props: {
    followers: {},

    total_followers: {}
  },
  components: {
    ChartRender
  },
  mounted () {},

  data () {
    return {
      noEngagementRateLinkedin: false,
      noData: NO_CHART_DATA,
      tooltip_message: PINTEREST_ANALYTICS_AUDIENCE_GROWTH
    }
  },

  computed: {
    totalDocs () {
      let count = 0
      this.followers.buckets.forEach((item) => {
        count += item.doc_count
      })
      return count
    },
    isContentNotAvailable () {
      return (
        this.followers &&
        this.followers.buckets &&
        this.followers.buckets.length > 0 &&
        this.totalDocs === 0
      )
    }
  },
  methods: {
    followersRate () {
      const followers = []

      if (this.followers) {
        this.followers.buckets.forEach((item) => {
          const parsedDate = moment(item.key_as_string).unix() * 1000
          followers.push([
            parsedDate,
            item.total_followers.value
              ? Math.round(item.total_followers.value)
              : 0
          ])
        })
      }

      if (this.isContentNotAvailable) {
        followers.forEach((item) => {
          item[1] = this.randomNumber()
        })
      }

      return {
        credits: splineCredits(),
        tooltip: splineTooltip(),
        chart: splineChart('#followers_rate_highlight'),
        legend: splineLegend(),

        colors: ['#cb2027'],
        title: false,
        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        plotOptions: splinePlotOptions(),
        series: [
          {
            name: 'Followers',
            data: followers
          }
        ]
      }
    }
  }
}
</script>
