<template>
  <div class="analytic_component">
    <div class="analyze_container">
      <div class="section">
        <template v-if="loader">
          <div class="component_inner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </template>
        <div v-else class="summary_row white_box">
          <div class="d-flex flex-row">
            <div class="left">
              <section-titles
                :name="'Instagram Audience Demographics'"
              ></section-titles>
            </div>
          </div>
          <template v-if="metrics.hasOwnProperty('status')">
            <div class="d-flex flex-row">
              <div class="box">
                <div class="box_inner align-items-center">
                  <div class="text flex-row">
                    <h4 class="top_text">Audience by Age </h4>
                  </div>
                  <div class="text flex-row">
                    <!-- audience by age bar  start here -->

                    <div class="line_bars">
                      <template v-for="age in metrics.audience_age.value">
                        <div class="bar_item">
                          <label for=""
                            >{{ age['key'].replace('_', '-') }}
                            <span>{{
                              parseInt(age['event_value']['value'])
                            }}</span>
                          </label>
                          <div class="item_inner">
                            <div
                              class="limit_add"
                              :style="
                                'width: ' +
                                (age['event_value']['value'] /
                                  metrics.audience_age.max) *
                                  100 +
                                '%;'
                              "
                            ></div>
                            <div class="limit_exceed"></div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <!-- Audience by age bar  end here -->
                  </div>
                </div>
              </div>

              <div class="box">
                <div class="box_inner align-items-center">
                  <div class="text flex-row">
                    <h4 class="top_text">Audience by Gender </h4>
                  </div>
                  <div class="text flex-row">
                    <fans-by-gender
                      :fans="metrics.audience_gender"
                    ></fans-by-gender>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="box">
                <div class="box_inner text-center">
                  <div class="text flex-row">
                    <h4 class="normal-text">
                      <b>{{
                        getGender(metrics.audience_gender_age.event_key[0])
                      }}</b>
                      between the ages of
                      <b>{{
                        metrics.audience_gender_age.event_key
                          .substring(2)
                          .replace('_', '-')
                      }}</b>
                      appear to be the leading force among your followers.
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row">
              <div class="box">
                <div class="box_inner align-items-center">
                  <div class="text flex-row">
                    <h4 class="top_text">Audience Top Countries </h4>
                  </div>
                  <div class="flex-row">
                    <template
                      v-if="index < 10"
                      v-for="(
                        audience_value, audience_key, index
                      ) in metrics.audience_country"
                    >
                      <top-country
                        :type="'country'"
                        :name="audience_key"
                        :value="audience_value"
                      ></top-country>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box">
                <div class="box_inner text-center">
                  <div class="text flex-row">
                    <h4 class="top_text">Audience Top Cities </h4>
                  </div>
                  <div class="flex-row">
                    <template
                      v-if="index < 10"
                      v-for="(
                        audience_value, audience_key, index
                      ) in metrics.audience_city"
                    >
                      <top-country
                        :name="audience_key"
                        :value="audience_value"
                        :type="'city'"
                      ></top-country>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex flex-row">
              <div class="box">
                <div class="box_inner align-items-center">
                  <no-demographics></no-demographics>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstagramHeader from './InstagramHeader'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import TopCountry from '@src/modules/analytics/components/instagram/statistics/TopCountry'
import FansByGender from '@src/modules/analytics/components/instagram/charts/FansByGender'
import NoDemographics from './common/NoDemographics'
import SectionTitles from './common/SectionTitles'

export default {
  name: 'InstagramDemographics',
  props: {
    data: null
  },
  components: {
    SectionTitles,
    FansByGender,
    NoDemographics,
    InstagramHeader,
    TopCountry
  },
  data () {
    return dataValues().instagram
  },
  computed: {
    ...mapGetters([
      'getInstagramAccounts',
      'getAnalyticsServiceStatus',
      'getInstagramIds'
    ])
  },
  async mounted () {
    // if (this.$route.params.accountId) {
    if (this.data != null) {
      this.initialize()
    }
  },
  methods: {
    ...mapActions(['getAnalyticsService']),
    initialize () {
      this.metrics = {}
      this.selected_account = this.data.selected_account
      this.previous_start_date = this.data.previous_start_date
      this.previous_end_date = this.data.previous_end_date
      this.getAnalyticsForInstagram({
        workspace_id: this.getActiveWorkspace._id,
        timezone: this.getActiveWorkspace.timezone,
        date: `${this.data.start_date} - ${this.data.end_date}`,
        accounts: this.selected_account,
        // accounts: ["36971002400"],
        previous_date: `${this.previous_start_date} - ${this.previous_end_date}`
      })
    },
    formatDate (date) {
      return moment(date).format('llll')
    },
    showActiveAccount (account) {
      return account.instagram_id === this.selected_account
    },
    getGender (value) {
      return value === 'm' || value === 'M'
        ? 'Men'
        : value === 'f' || value === 'F'
          ? 'Women'
          : 'Nonbinary/Unspecified'
    },
    async getAnalyticsForInstagram (payload = {}) {
      this.loader = true
      payload.type = 'instagram'
      payload.section = 'demographics'
      const resp = await this.getAnalyticsService(payload)
      console.log(resp)
      this.metrics = Object.assign(this.metrics, resp)
      console.log(this.metrics)
      this.loader = false
    },
    limitText (value, limit) {
      if (value && value.length > limit) {
        // console.log(value.substring(0, 15)
        return value.substring(0, limit) + '...'
      }
      return value
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.initialize()
      }
    }
  }
}
</script>

<style scoped></style>
