<template>
  <div
    class="distribute_block"
    :class="{ 'blog_steps_common blog_steps_none_modal': nomodel }"
  >
    <template v-if="isWorkspaceOnHold">
      <div class="input_field">
        <div class="warning_box warning-alert">
          <p v-if="hasPermission('can_change_hold_status')">
            <i class="fa fa-warning"></i> Note: You cannot select accounts
            because you've paused publishing for this workspace,<router-link
              :to="{ name: 'workspaces' }"
              class="ml-2"
              >Click here to resume publishing</router-link
            ></p
          >
          <p v-if="!hasPermission('can_change_hold_status')">
            <i class="fa fa-warning"></i> Note: You cannot select accounts
            because the admin of this workspace has paused publishing for this
            workspace.</p
          >
        </div>
      </div>
    </template>

    <template v-if="!isWorkspaceOnHold">
      <div class="opt_box basic_form">
        <div class="head_text">
          <h3>Primary Channel</h3>
          <p
            >Primary channel should be your main blogging platform. This is
            where your post gets published first. You can select only 1 primary
            channel here to avoid duplicate content issue.</p
          >
        </div>

        <template
          v-if="
            getBlogSelection.platform_name === '' ||
            getBlogSelectionEdit.primary
          "
        >
          <div class=" mt-0">
            <div class="d-flex align-items-center">
              <label class="text-base mr-4">Select blog platform</label>
              <blog-selection-links></blog-selection-links>
            </div>
          </div>
          <blog-selection></blog-selection>

          <div class="btn_block mt-4" v-if="checkOptionSelection">
            <button
              class="btn btn-studio-theme-space"
              @click.prevent="addPrimarySelection()"
              :disabled="fetch_blog_options_loader || isNullSelected"
              data-cy="add-button"
            >
              <span v-if="getBlogSelectionEdit.primary">Update</span>
              <span v-else> Add </span>
            </button>
            <button
              class="btn btn-studio-theme-transparent-grey ml-2"
              @click.prevent="cancelEditPrimary()"
            >
              <span>Cancel</span>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="added_boxes row">
            <div class="blog_box_item">
              <div class="inner">
                <div
                  class="box_head d-flex align-items-center"
                  :class="getBlogSelection.platform_name"
                >
                  <div class="h_left">
                    <i
                      :class="
                        configrations[getBlogSelection.platform_name]
                          .background_classes
                      "
                    ></i>
                    <span class="text">{{
                      startCaseText(getBlogSelection.platform_name) ===
                      'Wordpress'
                        ? 'WordPress'
                        : startCaseText(getBlogSelection.platform_name)
                    }}</span>
                  </div>
                  <div class="h_right" v-if="checkPrimaryEditPermission()">
                    <i
                      class="icon-edit-cs"
                      @click.prevent="editPrimaryAccount()"
                    ></i>
                    <i
                      class="cs-cross ml-2"
                      @click.prevent="removePrimaryAccount()"
                    ></i>
                  </div>
                </div>
                <div class="blog_content basic_form">
                  <div class="input_field mt-0">
                    <div class="profile_picture">
                      <div class="picture_block">
                        <img
                          :src="
                            getBlogLogo(
                              getBlogSelection.platform_name,
                              getBlogSelection.primaryBlog[
                                getBlogSelection.platform_name
                              ].website
                            )
                          "
                          alt=""
                          data-cy="blog-platform"
                        />
                      </div>
                      <div class="text_block">
                        <p class="text">{{
                          getBlogURL(
                            getBlogSelection.platform_name,
                            getBlogSelection.primaryBlog[
                              getBlogSelection.platform_name
                            ].website
                          )
                        }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="input_field"
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].author
                    "
                  >
                    <label for="">Author</label>
                    <p
                      class="p_text"
                      v-if="getBlogSelection.platform_name === 'wordpress'"
                      >{{
                        getWordpressAuthorName(
                          getBlogSelection.primaryBlogOption[
                            getBlogSelection.platform_name
                          ].authors,
                          getBlogSelection.primaryBlog[
                            getBlogSelection.platform_name
                          ].author
                        )
                      }}</p
                    >
                    <p class="p_text" v-else>{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].author
                    }}</p>
                  </div>
                  <div
                    class="input_field"
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].categories
                    "
                  >
                    <label for="">Category</label>
                    <p
                      class="p_text"
                      v-for="category in getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].categories"
                      >{{
                        getWordpressCategoryName(
                          getBlogSelection.primaryBlogOption[
                            getBlogSelection.platform_name
                          ].categories,
                          category
                        )
                      }}</p
                    >
                  </div>
                  <div
                    class="input_field"
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].post_tags
                    "
                  >
                    <label for="">Tags</label>
                    <div>
                      <p
                        class="p_text d-inline-block mr-1"
                        :key="index"
                        v-for="(tag, index) in getBlogSelection.primaryBlog[
                          getBlogSelection.platform_name
                        ].post_tags"
                      >
                        {{ tag
                        }}{{
                          index !==
                          getBlogSelection.primaryBlog[
                            getBlogSelection.platform_name
                          ].post_tags.length -
                            1
                            ? ', '
                            : ''
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="input_field"
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ] &&
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].status
                    "
                  >
                    <label for="">Publish status</label>
                    <p class="p_text">{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].status
                    }}</p>
                  </div>
                  <div
                    class="input_field"
                    v-else-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ] &&
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].published
                    "
                  >
                    <label for="">Publish status</label>
                    <p class="p_text">{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].published
                    }}</p>
                  </div>
                  <div class="input_field" v-else>
                    <label for="">Publish status</label>
                    <p class="p_text">Publish</p>
                  </div>
                </div>
                <template v-if="type !== 'Automations'">
                  <div class="footer_block">
                    <template v-if="getPublishSelection.status === 'published'">
                      Published on
                      {{
                        getWorkspaceTimeZoneTime(
                          getPublishSelection.planExecutionTime['date'],
                          'MMM DD, hh:mm a'
                        )
                      }}
                    </template>
                    <template
                      v-else-if="getPublishSelection.status === 'failed'"
                    >
                      Failed on this platform
                    </template>
                    <template v-else-if="checkPrimaryMissedReview()">
                      Missed Review on this platform
                    </template>
                    <template
                      v-else-if="getPublishSelection.status === 'rejected'"
                    >
                      Rejected on this platform
                    </template>
                    <template v-else>
                      <div class="input_field mt-0">
                        <div class="radio_input_image">
                          <input
                            value="now"
                            type="radio"
                            id="primaryAccountPostNow"
                            name="primaryAccountPostNow"
                            :disabled="!hasPermission('can_schedule_plan')"
                            v-model="getBlogSelection.primaryTime.time_type"
                          />
                          <label for="primaryAccountPostNow" class="radio_left"
                            >Publish Immediately
                          </label>
                        </div>
                      </div>

                      <div
                        style="min-height: 48px"
                        class="input_field mt-2 d-flex align-items-center"
                      >
                        <div class="radio_input_image">
                          <input
                            value="schedule"
                            type="radio"
                            id="primaryAccountSchedule"
                            name="primaryAccountSchedule"
                            v-model="getBlogSelection.primaryTime.time_type"
                          />
                          <label for="primaryAccountSchedule" class="radio_left"
                            >Schedule</label
                          >
                        </div>

                        <div
                          class="datepicker_style primaryDatePicker_style w-auto ml-auto"
                          v-if="
                            getBlogSelection.primaryTime.time_type == 'schedule'
                          "
                        >
                          <!--<input class="with_bg_white" readonly type="text" id="primaryDatePicker"-->
                          <!--v-model="getBlogSelection.primaryTime.postSelectedDate"-->
                          <!--min="0" placeholder="Select specific date...">-->

                          <!--                                                    <date-range-picker class="datepicker_input"-->
                          <!--                                                                       :opens="opens"-->
                          <!--                                                                       :startDate="getPrimaryStartDate"-->
                          <!--                                                                       @update="updatePrimaryValues"-->
                          <!--                                                                       :locale-data="locale"-->
                          <!--                                                                       :minDate="getPrimaryMinDate"-->
                          <!--                                                                       :singleDatePicker="singleDatePicker"-->
                          <!--                                                                       :timePicker="timePicker"-->
                          <!--                                                                       :timePicker24Hour="timePicker24Hour"-->
                          <!--                                                                       :showWeekNumbers="showWeekNumbers"-->
                          <!--                                                                       :showDropdowns="showDropdowns"-->
                          <!--                                                                       :autoApply="autoApply"-->
                          <!--                                                                       :ranges="show_ranges ? undefined : false">-->
                          <!--                                                        <div slot="input" class="datepicker_content with_bg_white "  slot-scope="picker" style="min-width: 250px;">-->
                          <!--                                                            <i class="icon_irst  cs-calendar-fill"></i>-->
                          <!--                                                            <span>{{ picker.startDate | date}}</span>-->
                          <!--                                                        </div>-->
                          <!--                                                    </date-range-picker>-->

                          <customize-date-range-picker
                            class="datepicker_input"
                            :opens="opens"
                            :startDate="getPrimaryStartDate"
                            @update="updatePrimaryValues"
                            :locale-data="locale"
                            :minDate="getPrimaryMinDate"
                            :singleDatePicker="singleDatePicker"
                            :timePicker="timePicker"
                            :timePicker24Hour="timePicker24Hour"
                            :showWeekNumbers="showWeekNumbers"
                            :showDropdowns="showDropdowns"
                            :autoApply="autoApply"
                            :ranges="show_ranges ? undefined : false"
                            :timezone="getWorkspaces.activeWorkspace.timezone"
                          >
                            <div
                              slot="input"
                              class="datepicker_content with_bg_white"
                              slot-scope="picker"
                              style="min-width: 250px"
                            >
                              <i class="icon-Calendar"></i>
                              <span>{{ picker.startDate | date }}</span>
                            </div>
                          </customize-date-range-picker>

                          <i class="dropdown_arrow icon_last cs-angle-down"></i>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        class="input_field"
        v-if="
          getBlogSelection.primaryBlog[getBlogSelection.platform_name] &&
          getBlogSelection.primaryBlog[getBlogSelection.platform_name]
            .website &&
          (getBlogSelection.primaryBlog[getBlogSelection.platform_name]
            .status === 'draft' ||
            getBlogSelection.primaryBlog[getBlogSelection.platform_name]
              .published === 'Hidden') &&
          !getBlogSelectionEdit.primary
        "
      >
        <div class="warning_box text-center">
          <p
            >Note: Hidden
            {{ startCaseText(getBlogSelection.platform_name) }} posts will not
            be shared on secondary & social media channels.</p
          >
        </div>
      </div>

      <!--v-else-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary"-->
      <div
        class="opt_box basic_form"
        v-else-if="!getBlogSelectionEdit.primary"
        :class="{ 'overlay-area': getBlogSelection.platform_name === '' }"
      >
        <div class="head_text">
          <h3>Secondary Channels</h3>
          <p
            >Secondary channels help in spreading your new blog post to multiple
            channels. Our publishing system takes care of all the necessary
            measures to not affect your SEO such as using rel canonical tag. You
            can select multiple Secondary channels here.</p
          >
        </div>

        <template v-if="getBlogSelection.platform_name">
          <div class="input_field mt-0">
            <div class="d-flex align-items-center">
              <label class="text w-25">Select blog platform</label>
              <blog-selection-links></blog-selection-links>
            </div>
          </div>
          <blog-selection></blog-selection>

          <div class="btn_block mt-4" v-if="checkOptionSelection">
            <button
              class="btn btn-studio-theme-space"
              @click.prevent="addSecondarySelection()"
              :disabled="fetch_blog_options_loader || isNullSelected"
            >
              <span v-if="getBlogSelectionEdit.secondary">Update</span>
              <span v-else>Add</span>
            </button>
            <button
              class="btn border_btn large_btn border-0 ml-3"
              @click.prevent="cancelEditSecondary()"
            >
              <span>Cancel</span>
            </button>
          </div>

          <div class="added_boxes row" v-if="!getBlogSelectionEdit.secondary">
            <template v-for="(blog, index) in getSecondaryBlogSelection">
              <div class="blog_box_item">
                <div class="inner">
                  <div
                    class="box_head d-flex align-items-center"
                    :class="blogCategoryName(blog.option)"
                  >
                    <div class="h_left">
                      <i
                        :class="
                          configrations[blogCategoryName(blog.option)]
                            .background_classes
                        "
                      ></i>
                      <span class="text">{{
                        startCaseText(blogCategoryName(blog.option))
                      }}</span>
                    </div>
                    <div
                      class="h_right"
                      v-if="checkSecondaryEditPermission(blog)"
                    >
                      <i
                        class="icon-edit-cs"
                        @click.prevent="
                          editSecondaryAccount(
                            index,
                            blog.selection[blogCategoryName(blog.option)]
                              .website
                          )
                        "
                      ></i>
                      <i
                        class="cs-cross ml-2"
                        @click.prevent="removeSecondaryAccount(index)"
                      ></i>
                    </div>
                  </div>
                  <div class="blog_content basic_form">
                    <div class="input_field mt-0">
                      <div class="profile_picture">
                        <div class="picture_block">
                          <img
                            :src="
                              getBlogLogo(
                                blogCategoryName(blog.option),
                                blog.selection[blogCategoryName(blog.option)]
                                  .website
                              )
                            "
                            alt=""
                          />
                        </div>
                        <div class="text_block">
                          <p class="text">{{
                            getBlogURL(
                              blogCategoryName(blog.option),
                              blog.selection[blogCategoryName(blog.option)]
                                .website
                            )
                          }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="input_field"
                      v-if="
                        blog.selection[blogCategoryName(blog.option)].author
                      "
                    >
                      <label for="">Author</label>
                      <p
                        class="p_text"
                        v-if="blogCategoryName(blog.option) === 'wordpress'"
                        >{{
                          getWordpressAuthorName(
                            blog.option[blogCategoryName(blog.option)].authors,
                            blog.selection[blogCategoryName(blog.option)].author
                          )
                        }}</p
                      >
                      <p class="p_text" v-else>{{
                        blog.selection[blogCategoryName(blog.option)].author
                      }}</p>
                    </div>
                    <div
                      class="input_field"
                      v-if="
                        blog.selection[blogCategoryName(blog.option)].categories
                      "
                    >
                      <label for="">Category</label>
                      <p
                        class="p_text"
                        v-for="category in blog.selection[
                          blogCategoryName(blog.option)
                        ].categories"
                        >{{
                          getWordpressCategoryName(
                            blog.option[blogCategoryName(blog.option)]
                              .categories,
                            category
                          )
                        }}</p
                      >
                    </div>
                    <div
                      class="input_field"
                      v-if="
                        blog.selection[blogCategoryName(blog.option)].post_tags
                      "
                    >
                      <label for="">Tags</label>
                      <div>
                        <p
                          class="p_text d-inline-block mr-1"
                          :key="index"
                          v-for="(tag, index) in blog.selection[
                            blogCategoryName(blog.option)
                          ].post_tags"
                        >
                          {{ tag
                          }}{{
                            index !==
                            blog.selection[blogCategoryName(blog.option)]
                              .post_tags.length -
                              1
                              ? ', '
                              : ''
                          }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="input_field"
                      v-if="
                        blog.selection[blogCategoryName(blog.option)] &&
                        blog.selection[blogCategoryName(blog.option)].status
                      "
                    >
                      <label for="">Publish status</label>
                      <p class="p_text">{{
                        blog.selection[blogCategoryName(blog.option)].status
                      }}</p>
                    </div>
                    <div
                      class="input_field"
                      v-else-if="
                        blog.selection[blogCategoryName(blog.option)] &&
                        blog.selection[blogCategoryName(blog.option)].published
                      "
                    >
                      <label for="">Publish status</label>
                      <p class="p_text">{{
                        blog.selection[blogCategoryName(blog.option)].published
                      }}</p>
                    </div>
                    <div class="input_field" v-else>
                      <label for="">Publish status</label>
                      <p class="p_text">Publish</p>
                    </div>
                  </div>

                  <template v-if="type !== 'Automations'">
                    <div class="footer_block">
                      <template v-if="blog.status === 'published'">
                        Published on
                        {{
                          getWorkspaceTimeZoneTime(
                            blog.execution_time['date'],
                            'MMM DD, hh:mm a'
                          )
                        }}
                      </template>
                      <template v-else-if="blog.status === 'failed'">
                        Failed on this platform
                      </template>
                      <template v-else-if="checkSecondaryMissedReview(blog)">
                        Missed Review on this platform
                      </template>

                      <template v-else-if="blog.status === 'rejected'">
                        Rejected on this platform
                      </template>

                      <template v-else>
                        <div class="input_field mt-0">
                          <div class="radio_input_image">
                            <input
                              value="now"
                              type="radio"
                              :id="
                                getBlogAccountId(blog) +
                                '_secondaryAccountPostNow'
                              "
                              v-model="blog.time.time_type"
                            />
                            <label
                              class="radio_left"
                              :for="
                                getBlogAccountId(blog) +
                                '_secondaryAccountPostNow'
                              "
                              >Publish Immediately after primary
                            </label>
                          </div>
                        </div>

                        <div
                          style="min-height: 48px"
                          class="input_field mt-2 d-flex align-items-center"
                        >
                          <div class="radio_input_image">
                            <input
                              value="schedule"
                              type="radio"
                              :id="
                                getBlogAccountId(blog) +
                                '_secondaryAccountSchedule'
                              "
                              v-model="blog.time.time_type"
                            />
                            <label
                              class="radio_left"
                              :for="
                                getBlogAccountId(blog) +
                                '_secondaryAccountSchedule'
                              "
                              >Schedule</label
                            >
                          </div>

                          <div
                            :id="
                              getBlogAccountId(blog) +
                              '_secondaryDatePicker_parent'
                            "
                            class="datepicker_style w-auto ml-auto"
                            :class="{
                              hide: blog.time.time_type !== 'schedule',
                            }"
                          >
                            <!--<i class="icon-Calendar"></i>-->

                            <!--<input class="with_bg_white" readonly type="text"  :id="getBlogAccountId(blog) + '_secondaryDatePicker'"-->
                            <!--v-model="blog.time.postSelectedDate"-->
                            <!--min="0" placeholder="Select specific date...">-->
                            <!--                                                        <date-range-picker class="datepicker_input"-->
                            <!--                                                                           :opens="opens"-->
                            <!--                                                                           :startDate="startDateSecondary(index)"-->
                            <!--                                                                           :locale-data="locale"-->
                            <!--                                                                           :minDate="getSecondaryMinDate(index)"-->
                            <!--                                                                           :singleDatePicker="singleDatePicker"-->
                            <!--                                                                           :timePicker="timePicker"-->
                            <!--                                                                           :timePicker24Hour="timePicker24Hour"-->
                            <!--                                                                           :showWeekNumbers="showWeekNumbers"-->
                            <!--                                                                           :showDropdowns="showDropdowns"-->
                            <!--                                                                           :autoApply="autoApply"-->
                            <!--                                                                           :ranges="show_ranges ? undefined : false"-->
                            <!--                                                        >-->

                            <!--                                                            <div slot="input" class="datepicker_content with_bg_white " slot-scope="picker" style="min-width: 250px;">-->
                            <!--                                                                <i class="icon_firt  cs-calendar-fill"></i>-->
                            <!--                                                                <span>{{updateSecondaryValues(picker.startDate, index)  | date}}</span>-->
                            <!--                                                            </div>-->
                            <!--                                                        </date-range-picker>-->

                            <customize-date-range-picker
                              class="datepicker_input"
                              :opens="opens"
                              :startDate="startDateSecondary(index)"
                              :locale-data="locale"
                              :minDate="getSecondaryMinDate(index)"
                              :singleDatePicker="singleDatePicker"
                              :timePicker="timePicker"
                              :timePicker24Hour="timePicker24Hour"
                              :showWeekNumbers="showWeekNumbers"
                              :showDropdowns="showDropdowns"
                              :autoApply="autoApply"
                              :ranges="show_ranges ? undefined : false"
                              :timezone="getWorkspaces.activeWorkspace.timezone"
                              :secondary="true"
                            >
                              <div
                                slot="input"
                                class="datepicker_content with_bg_white"
                                slot-scope="picker"
                                style="min-width: 250px"
                              >
                                <i class="icon-Calendar"></i>
                                <span>{{
                                  updateSecondaryValues(picker.startDate, index)
                                    | date
                                }}</span>
                              </div>
                            </customize-date-range-picker>

                            <i
                              class="dropdown_arrow icon_last cs-angle-down"
                            ></i>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
        <!--<account-selection :showLabel="true" v-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary" :type="type"></account-selection>-->
      </div>

      <!--/// this box is for social account..........-->
      <!--v-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary"-->
      <div
        :class="{ 'overlay-area': getBlogSelection.platform_name === '' }"
        v-if="!getBlogSelectionEdit.primary"
        class="opt_box basic_form"
      >
        <div class="head_text">
          <h3>Social Sharing</h3>
        </div>

        <template v-if="type === 'Automations'">
          <account-selection
              class="mb-4"
              :showLabel="true"
              :type="type"
              :module="'automation'"
              :blog="true"
          ></account-selection>
        </template>
        <template v-else>
          <account-selection
              class="mb-4"
              :showLabel="true"
              :type="type"
              :blog="true"
          ></account-selection>
        </template>


        <replug-selection class="auto_width"></replug-selection>
        <hashtag-selection class="auto_width"></hashtag-selection>

        <div class="input_field" v-if="showVariationsOptions">
          <label for="">Post Variations</label>
          <div class="radio_list">
            <div class="radio_input_image">
              <input
                v-if="type === 'Automations'"
                id="without_post_variations"
                type="radio"
                name="variations_type"
                value="post_title"
                v-model="getArticleAutomationDetails.post_variations"
              />
              <input
                v-else
                id="without_post_variations"
                type="radio"
                name="variations_type"
                value="post_title"
                v-model="getBlogPostingDetails.post_variations"
              />
              <label for="without_post_variations" class="radio_left"
                >Use Post Titles + URL</label
              >
            </div>
            <div class="radio_input_image">
              <input
                v-if="type === 'Automations'"
                id="with_post_variations"
                type="radio"
                name="variations_type"
                value="body_content"
                v-model="getArticleAutomationDetails.post_variations"
              />
              <input
                v-else
                id="with_post_variations"
                type="radio"
                name="variations_type"
                value="body_content"
                v-model="getBlogPostingDetails.post_variations"
              />
              <label for="with_post_variations" class="radio_left"
                >Use intelligent post variation (extracted from body content) as
                caption</label
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ReplugSelection from '../../../../automation/components/Selection/ReplugSelection'
import HashtagSelection from '../../../../automation/components/Selection/HashtagSelection'
import BlogSelection from './BlogSelection'
import BlogSelectionLinks from './BlogSelectionLinks'
import { mapGetters } from 'vuex'
import AccountSelection from '../social/AccountSelection'

import DateRangePicker from 'vue2-daterange-picker'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import { getPlatformByIdentifier } from '@common/lib/integrations'
import {
  blogIntegrationsNames,
  blogIntegrationsConfigrations
} from '../../../../integration/config/api-utils'
import {
  BLOG_CONNECTION_ERROR,
  COMPOSER_BLOG_PRIMARY_ALREADY_EXIST,
  COMPOSER_BLOG_SECONDARY_ALREADY_EXIST,
  NULL_MEDIUM_BLOG_SELECTION_ERROR,
  NULL_SHOPIFY_AUTHOR_SELECTION_ERROR,
  NULL_SHOPIFY_BLOG_SELECTION_ERROR,
  NULL_TUMBLR_BLOG_SELECTION_ERROR,
  NULL_WORDPORESS_AUTHOR_SELECTION_ERROR,
  NULL_WORDPORESS_BLOG_SELECTION_ERROR,
  WORDPORESS_BLOG_CONNECTION_ERROR
} from '@common/constants/messages'
import CustomizeDateRangePicker from '@common/components/Customize-date-range-picker/CustomizeDateRangePicker'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  props: ['type', 'nomodel', 'variations_options'],
  components: {
    CustomizeDateRangePicker,
    AccountSelection,
    BlogSelection,
    BlogSelectionLinks,
    DateRangePicker,
    HashtagSelection,
    ReplugSelection
  },
  data () {
    return {
      integrations: blogIntegrationsNames,
      configrations: blogIntegrationsConfigrations,
      locale: {
        format: 'YYYY-MM-DD  HH:mm:ss' // format of the dates displayed
      },

      opens: 'center',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: '2019-10-02',
      dateRange: {
        startDate: this.getPrimaryMinDate
      },
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      fetch_blog_options_loader: false
    }
  },
  created () {
    if (this.getPublishSelection.plan_id) {
      this.showBlogCurationPrimaryDatePickers()
      this.initializeSecondaryDatePickers()
    }
  },
  computed: {
    ...mapGetters([
      'getBlogOptions',
      'getBlogSelection',
      'getBlogSelectionEdit',
      'getPublishSelection',
      'getSecondaryBlogSelection',
      'getWorkspaces',
      'getArticleAutomationDetails'
    ]),
    isNullSelected (flag = true) {
      console.log(
        'BlogDistribute :: getBlogOptions -----> ',
        this.getBlogOptions
      )
      console.log(
        'BlogDistribute :: getSingleBlogSelection -----> ',
        this.getSingleBlogSelection
      )
      if (flag) {
        if (
          this.getBlogOptions.wordpress.selection === true &&
          this.getSingleBlogSelection.wordpress.website === ''
        ) {
          return true
        }
        if (
          this.getBlogOptions.tumblr === true &&
          this.getSingleBlogSelection.tumblr.website === ''
        ) {
          return true
        }
        if (
          this.getBlogOptions.medium === true &&
          this.getSingleBlogSelection.medium.website === ''
        ) {
          return true
        }
        if (
          this.getBlogOptions.shopify.selection === true &&
          this.getSingleBlogSelection.shopify.website === ''
        ) {
          return true
        }
        if (
          this.getBlogOptions.webflow === true &&
          (this.getSingleBlogSelection.webflow.website === '' ||
            this.getSingleBlogSelection.webflow.collection === null)
        ) {
          return true
        }

        return (
          this.getBlogOptions.wordpress.selection === false &&
          this.getBlogOptions.tumblr === false &&
          this.getBlogOptions.medium === false &&
          this.getBlogOptions.shopify.selection === false &&
          this.getBlogOptions.webflow === false
        )
      }
    },
    getPrimaryMinDate () {
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      if (this.getBlogSelection.primaryTime.postSelectedDate === '') {
        this.$store.commit(
          blogPosting.SET_PRIMARY_BLOG_DATE,
          minDate.format('YYYY-MM-DD HH:mm:ss')
        )
      }
      return minDate.format('MMMM DD, YYYY HH:mm')
    },
    getPrimaryStartDate () {
      if (this.getBlogSelection.primaryTime.postSelectedDate) {
        return moment(
          this.getBlogSelection.primaryTime.postSelectedDate
        ).format('MMMM DD, YYYY HH:mm')
      }
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },
    checkOptionSelection () {
      let selection = false
      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
          options[integration].hasOwnProperty('selection') &&
          options[integration].selection
        ) {
          selection = true
          return
        }
        if (
          !options[integration].hasOwnProperty('selection') &&
          options[integration]
        ) {
          selection = true
        }
      })
      return selection
    },
    isWorkspaceOnHold () {
      return !!(
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.on_hold
      )
    },
    showVariationsOptions () {
      return this.variations_options
    }
  },
  methods: {
    startCaseText (text) {
      return _.startCase(text)
    },

    updatePrimaryValues (values) {
      const myDate = moment(values.startDate)
      this.$store.commit(
        blogPosting.SET_PRIMARY_BLOG_DATE,
        myDate.format('YYYY-MM-DD HH:mm:ss')
      )
      const stateObject = this
      this.getSecondaryBlogSelection.forEach(function (element, index) {
        if (element.time.time_type === 'schedule') {
          stateObject.getSecondaryBlogSelection[index].time.postSelectedDate =
            ''
        }
      })
    },

    updateSecondaryValues (values, index) {
      const myDate = moment(values)
      this.getSecondaryBlogSelection[index].time.postSelectedDate =
        myDate.format('YYYY-MM-DD HH:mm:ss')
      return values
    },
    startDateSecondary (index) {
      if (this.getSecondaryBlogSelection[index].time.postSelectedDate === '') {
        if (this.getBlogSelection.primaryTime.time_type === 'now') {
          const startDate = moment()
            .tz(this.getWorkspaces.activeWorkspace.timezone)
            .add(1, 'hours')
        } else {
          const startDate = moment(
            this.getBlogSelection.primaryTime.postSelectedDate,
            'YYYY-MM-DD HH:mm:ss'
          ).add(1, 'hours')
        }
        return startDate.format('YYYY-MM-DD HH:mm:ss')
      } else {
        return this.getSecondaryBlogSelection[index].time.postSelectedDate
      }
    },
    getSecondaryMinDate (index) {
      let minDate = null
      if (this.getBlogSelection.primaryTime.time_type === 'now') {
        minDate = moment()
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .add(1, 'hours')
      } else {
        minDate = moment(
          this.getBlogSelection.primaryTime.postSelectedDate,
          'YYYY-MM-DD HH:mm:ss'
        ).add(1, 'hours')
      }

      if (this.getSecondaryBlogSelection[index].time.postSelectedDate === '') {
        this.getSecondaryBlogSelection[index].time.postSelectedDate =
          minDate.format('YYYY-MM-DD HH:mm:ss')
      }
      return minDate.format('YYYY-MM-DD HH:mm:ss')
    },

    checkSecondaryMissedReview (blog) {
      if (
        blog.status === 'review' &&
        blog.execution_time.date < moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return true
      }
      return false
    },
    checkPrimaryEditPermission () {
      if (
        this.getPublishSelection.status === 'published' ||
        this.getPublishSelection.status === 'failed' ||
        this.getPublishSelection.status === 'rejected' ||
        this.checkPrimaryMissedReview()
      ) {
        return false
      }
      return true
    },
    checkSecondaryEditPermission (blog) {
      if (
        blog.status === 'published' ||
        blog.status === 'failed' ||
        this.checkSecondaryMissedReview(blog) ||
        blog.status === 'rejected'
      ) {
        return false
      }
      return true
    },
    getBlogLogo (type, platform) {
      console.debug('Method::getBlogLogo', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_logo : ''
    },

    getBlogURL (type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    },
    async addPrimarySelection () {
      console.debug('Method:addPrimarySelection')
      if (!this.blogSelectionValidation()) return false
      const alreadySecondaryExistStatus =
        this.checkSecondaryBlogAccountAlreadyExist()
      if (alreadySecondaryExistStatus) {
        return this.alertMessageReturn(alreadySecondaryExistStatus, 'error')
      }

      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
          options[integration].hasOwnProperty('selection') &&
          options[integration].selection
        ) {
          this.$store.commit(
            blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME,
            integration
          )
          return
        }
        if (
          !options[integration].hasOwnProperty('selection') &&
          options[integration]
        ) {
          this.$store.commit(
            blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME,
            integration
          )
        }
      })

      const blogSelection = await this.$store.dispatch(
        'removeInvalidSelection',
        {
          selection: this.getSingleBlogSelection,
          selected_type: this.getBlogSelection.platform_name
        }
      )
      console.log('Method:addPrimarySelection ~ blogSelection', blogSelection)
      this.$store.commit(
        blogPosting.SET_PRIMARY_BLOG_OPTION,
        JSON.parse(JSON.stringify(this.getBlogOptions))
      )
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_SELECTION, blogSelection)
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_TIME, null)
      this.getBlogSelectionEdit.primary = false
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    async addSecondarySelection () {
      console.debug('Method:addSecondarySelection')
      if (!this.blogSelectionValidation()) return false
      const secondaryBlog = {
        option: JSON.parse(JSON.stringify(this.getBlogOptions)),
        selection: JSON.parse(JSON.stringify(this.getSingleBlogSelection)),
        time: {
          time_type: 'now',
          postSelectedDate: ''
        }
      }
      // checking account already exist as primary
      const alreadyExistStatus =
        this.checkBlogAccountAlreadyExist(secondaryBlog)
      if (alreadyExistStatus) {
        return this.alertMessageReturn(alreadyExistStatus, 'error')
      }

      // checking account already exist as secondary
      const alreadySecondaryExistStatus =
        this.checkSecondaryBlogAccountAlreadyExist()
      if (alreadySecondaryExistStatus) {
        return this.alertMessageReturn(alreadySecondaryExistStatus, 'error')
      }

      let selected_type = ''
      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
          options[integration].hasOwnProperty('selection') &&
          options[integration].selection
        ) {
          selected_type = integration
          return
        }
        if (
          !options[integration].hasOwnProperty('selection') &&
          options[integration]
        ) {
          selected_type = integration
        }
      })

      const blogSelection = await this.$store.dispatch(
        'removeInvalidSelection',
        {
          selection: this.jsonStringifyData(secondaryBlog.selection),
          selected_type: selected_type
        }
      )
      secondaryBlog.selection = blogSelection

      if (this.getBlogSelectionEdit.secondary === true) {
        // removing existing blog before adding new
        const secondaryBlogsIDs = this.getBlogSelection.secondaryAccountsId
        if (
          this.getBlogSelection.secondaryAccountsId.indexOf(
            this.getBlogSelectionEdit.secondaryAccountId
          ) !== -1
        ) {
          secondaryBlogsIDs.splice(
            this.getBlogSelection.secondaryAccountsId.indexOf(
              this.getBlogSelectionEdit.secondaryAccountId
            ),
            1
          )
          this.$store.commit(
            blogPosting.SET_SECONDARY_BLOG_IDS,
            secondaryBlogsIDs
          )
        }

        // adding blog in editing index
        const addedBlogs = this.getSecondaryBlogSelection
        addedBlogs[this.getBlogSelectionEdit.secondaryIndex] = secondaryBlog
        this.$store.commit(
          blogPosting.SET_SECONDARY_BLOG_SELECTION,
          JSON.parse(JSON.stringify(addedBlogs))
        )
        this.initializeSecondaryDatePickers()

        // adding new added blog in sendondary ids maintained array
        this.secondaryAccountId('add', secondaryBlog)
        this.cancelEditSecondary()
        this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
        return
      }
      this.$store.commit(
        blogPosting.SET_SECONDARY_BLOG_SELECTION,
        this.getSecondaryBlogSelection.concat(secondaryBlog)
      )
      this.secondaryAccountId('add', secondaryBlog)
      this.cancelEditSecondary()
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    cancelEditSecondary () {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    removeSecondaryAccount (index) {
      const secondaryBlogs = this.getSecondaryBlogSelection
      this.secondaryAccountId('remove', secondaryBlogs[index])
      secondaryBlogs.splice(index, 1)
      this.$store.commit(
        blogPosting.SET_SECONDARY_BLOG_SELECTION,
        secondaryBlogs
      )
    },

    cancelEditPrimary () {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    editPrimaryAccount () {
      console.debug('Method:editPrimaryAccount', this.getBlogSelection)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.getBlogSelectionEdit.primary = true
      this.$store.commit(
        blogPosting.SET_BLOG_OPTIONS,
        JSON.parse(JSON.stringify(this.getBlogSelection.primaryBlogOption))
      )
      this.$store.commit(
        blogPosting.SET_BLOG_SELECTION,
        JSON.parse(JSON.stringify(this.getBlogSelection.primaryBlog))
      )
    },

    removePrimaryAccount () {
      this.$store.commit(blogPosting.RESET_PRIMARY_BLOG_SELECTION)
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME, '')
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    editSecondaryAccount (index, accountId) {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.getBlogSelectionEdit.secondary = true
      this.getBlogSelectionEdit.secondaryIndex = index
      this.getBlogSelectionEdit.secondaryAccountId = accountId
      this.$store.commit(
        blogPosting.SET_BLOG_OPTIONS,
        JSON.parse(JSON.stringify(this.getSecondaryBlogSelection[index].option))
      )
      this.$store.commit(
        blogPosting.SET_BLOG_SELECTION,
        JSON.parse(
          JSON.stringify(this.getSecondaryBlogSelection[index].selection)
        )
      )
    },

    checkSecondaryBlogAccountAlreadyExist () {
      const type = this.blogCategoryName(this.getBlogOptions)
      if (type) {
        if (
          this.getBlogSelection.secondaryAccountsId.indexOf(
            this.getSingleBlogSelection[type].website
          ) !== -1 &&
          (!this.getBlogSelectionEdit.secondary ||
            this.getBlogSelectionEdit.secondaryAccountId !==
              this.getSingleBlogSelection[type].website)
        ) {
          return COMPOSER_BLOG_SECONDARY_ALREADY_EXIST
        }
      }
      return false
    },

    checkBlogAccountAlreadyExist (blog) {
      console.debug('Method:checkBlogAccountAlreadyExist', blog)
      const type = this.blogCategoryName(blog.option)
      if (type) {
        const primaryType = this.blogCategoryName(
          this.getBlogSelection.primaryBlogOption
        )
        if (
          primaryType === type &&
          blog.selection[type].website ===
            this.getBlogSelection.primaryBlog[type].website
        ) {
          return COMPOSER_BLOG_PRIMARY_ALREADY_EXIST
        }
      }
      return false
    },

    initializeSecondaryDatePickers (defaultState = false) {
      const stateObject = this
      this.getSecondaryBlogSelection.forEach(function (element, index) {
        if (defaultState && element.time.time_type === 'schedule') {
          stateObject.getSecondaryBlogSelection[index].time.postSelectedDate =
            ''
        }
        stateObject.showBlogCurationSecondaryDatePickers(
          stateObject.getBlogAccountId(element) + '_secondaryDatePicker',
          index
        )
      })
    },

    checkPrimaryMissedReview () {
      return !!(
        this.getPublishSelection.status === 'review' &&
        this.getPublishSelection.planExecutionTime.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      )
    },
    getBlogAccountId (blog) {
      const selectedBlog = this.blogCategoryName(blog.option)
      console.debug('selectedBlog', selectedBlog, blog.selection)
      return this.integrations.indexOf(selectedBlog) >= 0
        ? blog.selection[selectedBlog].website
        : null
    },
    secondaryAccountId (type, blog) {
      console.debug(blog)
      const selectedBlog = this.blogCategoryName(blog.option)
      const websiteId =
        this.integrations.indexOf(selectedBlog) >= 0
          ? blog.selection[selectedBlog].website
          : null
      if (websiteId) {
        if (type === 'add') {
          if (
            this.getBlogSelection.secondaryAccountsId.indexOf(websiteId) === -1
          ) {
            this.$store.commit(
              blogPosting.SET_SECONDARY_BLOG_IDS,
              this.getBlogSelection.secondaryAccountsId.concat(websiteId)
            )
          }
        } else if (type === 'remove') {
          const secondaryBlogsIDs = this.getBlogSelection.secondaryAccountsId
          if (
            this.getBlogSelection.secondaryAccountsId.indexOf(websiteId) !== -1
          ) {
            secondaryBlogsIDs.splice(
              this.getBlogSelection.secondaryAccountsId.indexOf(websiteId),
              1
            )
            this.$store.commit(
              blogPosting.SET_SECONDARY_BLOG_IDS,
              secondaryBlogsIDs
            )
          }
        }
      }
    },

    blogCategoryName (options) {
      console.debug('Method::blogCategoryName', options)
      let selection = ''
      blogIntegrationsNames.forEach((integration) => {
        if (
          options[integration].hasOwnProperty('selection') &&
          options[integration].selection
        ) {
          selection = integration
          return
        }
        if (
          !options[integration].hasOwnProperty('selection') &&
          options[integration]
        ) {
          selection = integration
        }
      })
      return selection
    }
  },
  watch: {
    'getBlogSelection.primaryTime.time_type' (type) {
      if (type === 'schedule') {
        this.showBlogCurationPrimaryDatePickers()
      }
      this.initializeSecondaryDatePickers(true)
    },
    'getBlogSelection.primaryTime.postSelectedDate' () {
      this.initializeSecondaryDatePickers(true)
    },
    'getSecondaryBlogSelection.length' () {
      this.initializeSecondaryDatePickers()
    }
  }
}
</script>
