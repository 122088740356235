import { tumblr } from '@src/modules/integration/store/states/mutation-types'

const getDefaultTumblrAccountsState = () => {
  return {
    items: [],
    all_items: [],
  }
}

export default {
  state: {
    accounts: getDefaultTumblrAccountsState(),
  },
  getters: {
    getTumblrAccounts: (state) => state.accounts,
  },
  actions: {},
  mutations: {
    [tumblr.SET_ACCOUNTS](state, account) {
      state.accounts.items = account.sort((a, b) =>
        a.platform_name
          .toLowerCase()
          .localeCompare(b.platform_name.toLowerCase())
      )
    },

    [tumblr.SET_ALL_ACCOUNTS](state, account) {
      state.accounts.all_items = account.sort((a, b) =>
        a.platform_name
          .toLowerCase()
          .localeCompare(b.platform_name.toLowerCase())
      )
    },

    [tumblr.SET_DEFAULT](state) {
      state.accounts = getDefaultTumblrAccountsState()
    },
  },
}
