<template>
  <b-modal
      id="removeOnboardingWidget"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="alertBox socialConfirmationDialog"
      hide-footer
      hide-header
  >
    <div class="modal_body modal-xl">
      <div class="alert_content">
        <h3 class=" mb-4 text-center">Are you sure you want to remove widget from this specific workspace?</h3>

        <div class="field_group">
          <div class="checkbox_container">
            <label for="never-checkbox">
              <input
                  id="never-checkbox"
                  v-model="enableNeverBtn"
                  type="checkbox"
              />
              <span class="check"></span>Do not show widget in any of the workspaces already created or new ones.

            </label>
          </div>
        </div>
        <div class="text-gray-800 ml-8 pl-0.5" >
          You can get the widget back from settings anytime. <a v-tooltip.top-center="'Learn more'" href="javascript:;" data-beacon-article-modal="623038cb2ce7ed0fb09155b3" class="beacon ml-1 "><i class="far fa-question-circle"></i></a>
        </div>
<!--        <p class="text-center"-->
<!--        >In case of permanently deleting the media asset which is used in any-->
<!--          of the scheduled posts, the post will fail to publish.</p-->
<!--        >-->

<!--        <div class="compose_warning_box text-center">-->
<!--          <p style="color: #444343"-->
<!--          >Note: Archived assets are shown in the Archive folder and will not-->
<!--            affect the scheduled posts.</p-->
<!--          >-->
<!--        </div>-->
      </div>

      <div class="footer_box flex justify-end">
        <button class="btn gray cancel_btn"
          @click="closeThisModal">
          Cancel
        </button>
        <button
            v-if="!enableNeverBtn"
            class="btn confirm_btn"
            @click="onboardingCompleted(true)">
          Remove widget
        </button>
        <button v-else class="btn confirm_btn" @click="neverSeeOnboardingWidget(true, false)">
          Never see the widget again!
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      enableNeverBtn: false
    }
  },
  methods: {
    closeThisModal() {
      this.$bvModal.hide('removeOnboardingWidget')
    },
  },
}
</script>
