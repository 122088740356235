<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import {
  getPlatformName,
  pinterestProfileName,
  platformImage,
  redirectConnectorRequest,
} from '@common/lib/integrations'
import { swalAttributes } from '@common/constants/common-attributes'
import { commonMethods } from '@common/store/common-methods'
import proxy from '@common/lib/http-common'
import {
  instagram,
  social,
} from '@src/modules/integration/store/states/mutation-types'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import {
  bulkRemoveIntegrationURL,
  changeGroupPostedAsURL,
} from '@src/modules/integration/config/api-utils'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CreateBoard from '../../dialogs/CreateBoard'
import NotificationDevices from '../../dialogs/instagram/NotificationDevices'
import RemoveDevice from '../../dialogs/remove/RemoveDevice'

import InstagramConnectModal from '../../modals/InstagramConnectModal'
import AccountSelectionDropdown from '../../../../../components/common/AccountSelectionDropdown'

const vueCookie = require('vue-cookie')
export default {
  components: {
    CstDropdownItem,
    RemoveDevice,
    NotificationDevices,
    CreateBoard,
    InstagramConnectModal,
    AccountSelectionDropdown,
    CstSimpleCheckbox,
    CstDropdown,
  },
  mixins: [ComposerHelper],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      link_visibility: true,
      follow_contentstudio: true,
      pinterest: {
        visible: 'board',
        profile: '',
      },
      commonMethods: commonMethods,
      device_id: '',
      tooltipHtml: `<div class='team_detail_tooltip'>
                  <div class='t_inner'>
                      <div class='t_content'>
                          <p style="margin-top: 0;font-size: 0.875rem;font-weight: normal;">Mobile app is not installed so you'll not be able to use the push notification method. <a href="https://docs.contentstudio.io/article/696-how-to-connect-instagram-to-contentstudio"></a> Learn more!</p>
                      </div>
                  </div>
              </div>`,
      fetch_slots_loader: false,
      facebookSdkReady: false,
      connectingIGBusiness: false,
      selectAll: false,
      selectedAccounts: [],
      bulkActions: {
        remove: 'remove',
        reconnect: 'reconnect',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getSocialAuthorization',
      'getSocialLoader',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getMobileDevices',
      'getWorkspaces',
      'getYoutubeAccounts',
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'getTiktokAccounts',
      'getActiveWorkspace',
      'getPlatformsList',
    ]),
    headingClasses() {
      return [this.type + '_social_icon ' + this.type + '_icon_color']
    },
    // eslint-disable-next-line vue/return-in-computed-property
    iconClasses() {
      switch (this.type) {
        case 'facebook':
          return ['cs-facebook fb']
        case 'twitter':
          return ['cs-twitter tw']
        case 'linkedin':
          return ['cs-linkedin lin']
        case 'pinterest':
          return ['cs-pinterest pin']
        case 'tumblr':
          return ['cs-tumblr tum']
        case 'instagram':
          return ['cs-instagram ins']
        case 'gmb':
          return ['fab fa-google google-b']
        case 'youtube':
          return ['fab fa-youtube youtube-b']
        case 'tiktok':
          return ['fab fa-tiktok tiktok-b']
      }
    },
    listingHeading() {
      if (this.type === 'youtube') return 'YouTube'
      return this.isGmbListing
        ? 'Google My Business'
        : this.type.charAt(0).toUpperCase() + this.type.slice(1)
    },
    // eslint-disable-next-line vue/return-in-computed-property
    connectionText() {
      switch (this.type) {
        case 'facebook':
          return 'Connect a new Facebook page/group'
        case 'linkedin':
          return 'Connect a new LinkedIn profile/page'
        case 'pinterest':
          return 'Connect a new Pinterest profile/board'
        case 'twitter':
        case 'tumblr':
        case 'gmb':
          return 'Connect a new ' + this.listingHeading + ' profile'
        case 'instagram':
          return 'Connect Instagram Business Profile'
        case 'youtube':
          return 'Connect YouTube'
        case 'tiktok':
          return 'Connect TikTok'
      }
    },
    connectionLink() {
      // if (this.isInstagramListing) return true
      return this.getSocialAuthorization[this.type]
    },
    hasAccountItems() {
      return (
        !this.accountLoadingStatus &&
        this.accountItems &&
        this.accountItems.length
      )
    },
    pinterestBoardCount() {
      const accounts = []
      this.getPinterestAccounts.items.forEach(function (account) {
        if (account.type === 'Board') {
          accounts.push(account)
        }
      })
      return accounts.length
    },
    pinterestProfileCount() {
      const accounts = []
      this.getPinterestAccounts.items.forEach(function (account) {
        if (account.type === 'Profile') {
          accounts.push(account)
        }
      })
      return accounts.length
    },
    accountItems() {
      switch (this.type) {
        case 'pinterest':
          // eslint-disable-next-line no-case-declarations
          const accounts = []
          // eslint-disable-next-line no-case-declarations
          const stateObject = this
          this.getPinterestAccounts.items.forEach(function (account) {
            if (
              account.type ===
              stateObject.pinterest.visible.charAt(0).toUpperCase() +
                stateObject.pinterest.visible.slice(1)
            ) {
              accounts.push(account)
            }
          })
          return accounts
        default:
          return this[
            'get' +
              this.type.charAt(0).toUpperCase() +
              this.type.slice(1) +
              'Accounts'
          ].items
      }
    },
    showAppInstallationWarning() {
      return this.isInstagramListing && this.getMobileDevices.length === 0
    },
    showQueueTime() {
      return this.type !== 'pinterest' || this.pinterest.visible === 'board'
    },
    showTokenStatus() {
      return [
        'facebook',
        'twitter',
        'linkedin',
        'pinterest',
        'instagram',
        'tiktok',
      ].includes(this.type)
    },
    isFacebookListing() {
      return this.type === 'facebook'
    },
    isTwitterListing() {
      return this.type === 'twitter'
    },
    isLinkedinListing() {
      return this.type === 'linkedin'
    },
    isPinterestListing() {
      return this.type === 'pinterest'
    },
    isTikTokListing() {
      return this.type === 'tiktok'
    },
    isYoutubeListing() {
      return this.type === 'youtube'
    },
    isPinterestBoardListing() {
      return this.isPinterestListing && this.pinterest.visible === 'board'
    },
    isPinterestProfileListing() {
      return this.isPinterestListing && this.pinterest.visible === 'profile'
    },
    isTumblrListing() {
      return this.type === 'tumblr'
    },
    isGmbListing() {
      return this.type === 'gmb'
    },
    isInstagramListing() {
      return this.type === 'instagram'
    },
    canSaveSocialAccounts() {
      return this.hasPermission('can_save_social')
    },
    connectLinkVisibility() {
      return (
        this.link_visibility &&
        this.connectionLink &&
        this.canSaveSocialAccounts
      )
    },
    accountLoadingStatus() {
      return this.getSocialLoader.fetch_accounts
    },
    showConnectionDisabledActions() {
      return !this.connectLinkVisibility && !this.link_visibility
    },
    /**
     * Checks if the bulk action dropdown show be shown
     * @returns {boolean}
     */
    showBulkEdit() {
      return this.selectedAccounts.length > 1
    },
    /**
     * List of accounts that can be bulk reconnected.
     * @returns {boolean}
     */
    canBulkReconnect() {
      return ['facebook', 'instagram', 'linkedin', 'gmb'].includes(this.type) && this.connectionLink
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.type === 'pinterest') {
        if (this.pinterestProfileCount > 0 && this.pinterestBoardCount === 0) {
          this.pinterest.visible = 'profile'
        }
      }
    })
  },
  beforeDestroy() {},
  methods: {
    onPublishAccountChange(groupId, pageId, index) {
      console.debug('onPublishAccountChange', groupId, pageId)

      proxy
        .post(changeGroupPostedAsURL, {
          group_id: groupId,
          page_id: pageId,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status === true) {
            this.getFacebookAccounts.items[index].posted_as =
              response.data.posted_as
            console.debug('onPublishAccountChange resp', response)
            this.alertMessage(
              'Default publish account for group changed!',
              'success'
            )
          }
        })
    },
    platformImage(platform) {
      return platformImage(platform)
    },
    pinterestProfileName(profileId) {
      return pinterestProfileName(
        this.getPinterestAccounts.all_items,
        profileId
      )
    },
    accountType(account) {
      if (this.isGmbListing) return 'Locations'
      if (account.is_inbox) return 'Business Profile'
      return account.type ? _.startCase(account.type) : 'Profile'
    },
    accountName(account) {
      return getPlatformName(account)
    },
    getPinterestProfileName(account) {
      return account.username
    },
    // todo::use getPlatformIdentifierValue
    accountConnectorId(account) {
      switch (this.type) {
        case 'facebook':
        case 'twitter':
        case 'linkedin':
        case 'instagram':
          return account[this.type + '_id']
        case 'pinterest':
          return account.board_id
        case 'youtube':
        case 'tiktok':
        case 'tumblr':
          return account.platform_identifier
        case 'gmb':
          return account.name
        default:
          return ''
      }
    },
    triggerBoardCreation(profile) {
      this.pinterest.profile = profile
      this.$bvModal.show('createBoard')
    },
    async fetchAccountQueueSlots(account) {
      console.log('Method::fetchAccountQueueSlots ~ account -> ', account)
      account.platform_type = this.type
      this.fetch_slots_loader = true
      this.$bvModal.show('account_queue_slot_modal')
      const response = await this.$store.dispatch(
        'fetchAccountQueueSlots',
        account
      )
      if (!response) {
        this.$bvModal.hide('account_queue_slot_modal')
      }
      this.fetch_slots_loader = false
    },
    async shuffleQueue(account) {
      this.$bvModal
        .msgBoxConfirm(
          'By performing this action, your existing scheduled posts belonging to this account queue will be shuffled.',
          {
            title: 'Shuffle Posts',
            ...swalAttributes(),
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch('shuffleQueuePosts', account)
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        })
    },
    accountExpirationStates(account) {
      if (account) {
        if (account.validity) {
          // returning status expired in case of invalid and expired validity
          if (
            account.validity === 'invalid' ||
            account.validity === 'expired'
          ) {
            return '<span class="red">Expired</span>'
          }

          if (account.validity === 'limit_exceeded') {
            return '<span class="red">Daily Limit Reached</span>'
          }
        }

        if (account.days_remaining !== undefined) {
          // returning in case of remaining days are greater than 7
          if (account.days_remaining > 0) {
            if (!this.isLinkedinListing || account.days_remaining < 30) {
              return (
                '<span class="green">Expiring in ' +
                account.days_remaining +
                ' days</span>'
              )
            }
          }
          return '<span class="green">Valid</span>'
        }

        if (account && account.expires_at) {
          // returning status expired in case of expired_at time passed
          if (this.utcTimePassingCheck(account.expires_at.date)) {
            return '<span class="red">Expired</span>'
          }

          const now = moment.utc().format('YYYY-MM-DD HH:mm')
          const days = this.checkTimeDifference(
            now,
            account.expires_at.date,
            'days'
          )

          // checking remaining time in case of expiring on today
          if (!this.isLinkedinListing && days === 0) {
            const hours = this.checkTimeDifference(
              account.expires_at.date,
              now,
              'hours'
            )
            if (hours === 0) {
              const minutes = this.checkTimeDifference(
                now,
                account.expires_at.date,
                'minutes'
              )
              if (minutes === 0) {
                return (
                  '<span class="yellow">Expiring in ' +
                  this.checkTimeDifference(
                    now,
                    account.expires_at.date,
                    'seconds'
                  ) +
                  ' seconds</span>'
                )
              }
              return (
                '<span class="yellow">Expiring in ' +
                minutes +
                ' minutes</span>'
              )
            }
            return '<span class="yellow">Expiring in ' + hours + ' hours</span>'
          }

          // returning in case of remaining days less than 7
          if (days <= 7) {
            return '<span class="yellow">Expiring in ' + days + ' days</span>'
          }

          // returning in case of remaining days are greater than 7
          if (!this.isLinkedinListing || days < 30) {
            return '<span class="green">Expiring in ' + days + ' days</span>'
          }
        }
        return '<span class="green">Valid</span>'
      }
      return ''
    },
    limitExceededTooltip(account) {
      if (account && account.validity === 'limit_exceeded') {
        const name = this.accountName(account)
        return (
          'Your daily limit for access token against ' +
          name +
          ' has been reached. Please make sure to spread out the posts throughout the day to avoid reaching the daily rate limit.'
        )
      }
      return ''
    },
    showAccountNotificationDevices(account) {
      this.$store.commit(
        instagram.SET_NOTIFICATION_DEVICES,
        JSON.parse(JSON.stringify(account))
      )
      this.$bvModal.show('notification_devices')
    },
    enableDevicesCount(account) {
      let devices = this.getMobileDevices.length
      this.getMobileDevices.forEach(function (device) {
        if (
          (account.disabled_devices &&
            account.disabled_devices.includes(device.device_id) === true) ||
          device.status_code
        ) {
          devices = devices - 1
        }
      })
      return devices
    },
    getQueuedTimesCount(account) {
      console.log('Method::getQueuedTimesCount ~ account -> ', account)
      let count = 0
      if (account.QueueSlots) {
        account.QueueSlots.forEach(function (slots) {
          if (slots && slots.status) count += slots.times.length
        })
      }
      return count + ' times per week'
    },
    accountIdentifiar(account) {
      let key = ''
      switch (this.type) {
        case 'pinterest':
          key = account.board_id ? 'board_id' : 'pinterest_id'
          break
        case 'tumblr':
        case 'tiktok':
        case 'youtube':
          key = 'platform_identifier'
          break
        case 'gmb':
          key = 'name'
          break
        default:
          key = this.type.toLowerCase() + '_id'
      }
      return account[key]
    },
    removeAccount(account) {
      console.debug('Method::removeAccount', account)
      this.$parent.type = this.type
      this.$parent.platform = this.accountIdentifiar(account)
      this.$bvModal.show('removeIntegration')
    },
    redirectToHelp(helpDocLink = 'https://docs.contentstudio.io/') {
      window.open(helpDocLink, '_blank')
    },
    connectAccount(process, account) {
      console.debug(
        'Method:connectAccount',
        process,
        account,
        this.connectionLink
      )
      let link = this.connectionLink
      const details = { process }
      // takes in the details object and merges connector_id(string) or connector_ids(array) in case of bulk_reconnect.
      this.getSelectedAccountConnectorIds(details, account)
      if (
        (this.isFacebookListing || this.isInstagramListing) &&
        ['reconnect', 'bulk_reconnect'].includes(process)
      ) {
        link = link.replace('rerequest', 'reauthenticate,rerequest')
      }
      if (this.isTwitterListing) details.follow = this.follow_contentstudio
      if (this.isTumblrListing) {
        details.type = 'tumblr_social'
        details.oauth_token = vueCookie.get('tumblr_social_oauth_token')
        details.oauth_token_secret = vueCookie.get(
          'tumblr_social_oauth_token_secret'
        )
      }
      if (this.isYoutubeListing || this.isTikTokListing)
        details.type = this.type

      this.link_visibility = false
      redirectConnectorRequest(
        details,
        link,
        this.getWorkspaces.activeWorkspace
      )
    },
    getSelectedAccountConnectorIds(details, account) {
      switch (details.process) {
        case 'reconnect':
          if (account) details.connector_id = this.accountConnectorId(account)
          break
        case 'bulk_reconnect':
          details.connector_ids = this.accountItems
            .filter((acc) => this.selectedAccounts.includes(acc._id))
            .map((acc) => this.accountConnectorId(acc))
          break
        default:
          console.log(
            'process is neither reconnect nor bulk_reconnect',
            details.process
          )
      }
    },
    removeInstagramExternalIntegration(platform, type) {
      console.log('Method::removeInstagramExternalIntegration', platform)

      // confirm removal request from the user
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove integration?', {
          title: 'Remove Integration',
          ...swalAttributes(),
        })
        .then((value) => {
          // if user confirm removal request
          if (value) {
            const integrations = platform.linked_to
            integrations[type] = false
            this.$store.dispatch('removeInstagramExternalIntegration', {
              integrations: integrations,
              platform_identifier: platform.instagram_id,
              workspace_id: platform.workspace_id,
            })
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        })
    },
    /**
     * Click event handler for selecting all social accounts.
     * Selects all social accounts of particular platform type
     */
    onChangeSelectAll() {
      this.selectedAccounts = this.selectAll
        ? this.accountItems.map((account) => account._id)
        : []
    },
    /**
     * Click event handler for selecting a social account
     * Toggles select all checkbox value based on the accounts selected
     */
    onSelectSocialAccount() {
      this.selectAll = this.selectedAccounts.length === this.accountItems.length
    },
    /**
     * Click event handler for selecting between pinterest profile and boards.
     * Clears the selected accounts data and select all checkbox
     */
    togglePinterestBoardProfile() {
      this.selectedAccounts = []
      this.selectAll = false
    },
    /**
     * Bulk action handler for removing or reconnecting social accounts
     * @param action
     */
    bulkActionHandler(action) {
      switch (action) {
        case this.bulkActions.remove:
          this.bulkRemoveAccounts()
          break
        case this.bulkActions.reconnect:
          this.connectAccount('bulk_reconnect')
          break
        default:
      }
    },
    /**
     * Action for removing selected accounts
     * @returns {Promise<void>}
     */
    async bulkRemoveAccounts() {
      console.log('METHOD::bulkRemoveAccounts')
      const self = this
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete selected accounts?',
        {
          title: 'Remove Posts',
          ...swalAttributes(),
        }
      )
      if (res) {
        const payload = {
          workspace_id: self.getActiveWorkspace._id,
          channel_type: self.type,
          selected_accounts: self.selectedAccounts,
        }
        proxy.post(bulkRemoveIntegrationURL, payload).then(({ data }) => {
          const { status, message } = data
          if (status) {
            // on status: true; we have to remove the selectedAccounts from the PlatformsList(vuex) that is being used in composer and planner.
            this.$store.commit(
              social.SET_PLATFORM_LIST,
              this.getPlatformsList.filter(
                (account1) =>
                  !this.selectedAccounts.some(
                    (account2) => account1._id === account2
                  )
              )
            )
            // on status: true we have to remove the selectedAccounts from the account type's vuex store.
            const sanitizedChannelName =
              payload.channel_type.charAt(0).toUpperCase() +
              payload.channel_type.slice(1)
            const items = this[`get${sanitizedChannelName}Accounts`].items
            this[`get${sanitizedChannelName}Accounts`].items = items.filter(
              (account1) =>
                !this.selectedAccounts.some(
                  (account2) => account1._id === account2
                )
            )
          }
          this.alertMessage(message, status ? 'success' : 'error')
          this.selectedAccounts = []
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.disableEvents {
  background: linear-gradient(to right, #f43838, #e02f5f);
  box-shadow: 0 1px 0 0 rgba(152, 158, 181, 0.3);
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  width: 635px;
  cursor: pointer;
}
</style>

<template>
  <div :id="type" class="bg-white rounded-xl mb-7 overflow-hidden">
    <CreateBoard
      v-if="isPinterestListing"
      :board_profile="pinterest.profile"
    ></CreateBoard>
    <template v-if="isInstagramListing">
      <InstagramConnectModal></InstagramConnectModal>
      <NotificationDevices></NotificationDevices>
      <RemoveDevice :device_id="device_id"></RemoveDevice>
    </template>
    <div class="flex px-4 py-4 items-center justify-between">
      <div class="flex items-center">
        <div
          :class="
            'inline-flex justify-center items-center w-10 h-10 rounded-lg ' +
            getSocialBackground(type)
          "
        >
          <img
            :src="require('@assets/img/integration/' + getSocialIcon(type))"
            alt=""
            class="w-6"
          />
        </div>
        <span class="mx-2 font-medium text-lg">{{ listingHeading }}</span>
        <!--BulkAction::start-->
        <div v-if="showBulkEdit" class="mx-3 w-[11rem]">
          <CstDropdown class="w-full">
            <template v-slot:selected>
              <span> <i class="far fa-layer-group mr-2"></i>Bulk Actions</span>
            </template>
            <template v-slot>
              <CstDropdownItem @click="bulkActionHandler(bulkActions.remove)"
                >Remove Selected</CstDropdownItem
              >
              <CstDropdownItem
                v-if="canBulkReconnect"
                @click="bulkActionHandler(bulkActions.reconnect)"
                >Reconnect Accounts</CstDropdownItem
              >
            </template>
          </CstDropdown>
        </div>
        <!--BulkAction::end-->
      </div>
      <div class="head_right ml-auto">
        <clip-loader
          v-if="isInstagramListing && connectingIGBusiness"
          class="d-inline-block align-middle"
          style="margin-right: 10px"
          color="#989eb5"
          :size="'25px'"
        />

        <template v-if="connectLinkVisibility">
          <div v-if="isTwitterListing" class="field_group inline-block">
            <div class="checkbox_container">
              <label for="223">
                Follow Contentstudio
                <input
                  id="223"
                  v-model="follow_contentstudio"
                  name="option2"
                  type="checkbox"
                />
                <span class="check"></span>
              </label>
            </div>
          </div>
          <a
            v-if="type === 'youtube' || type === 'gmb'"
            class="my-3 cursor-pointer"
            @click.prevent="connectAccount('connect')"
          >
            <img
              src="@assets/img/btn_google_signin.png"
              alt="Sign in button"
              width="170px"
            />
          </a>

          <a
            v-else
            class="btn btn-studio-theme-space"
            @click.prevent="connectAccount('connect')"
            ><span>{{ connectionText }}</span></a
          >
        </template>
        <template v-if="showConnectionDisabledActions">
          <a
            v-if="type === 'youtube' || type === 'gmb'"
            class="my-3 cursor-not-allowed"
          >
            <img
              src="@assets/img/btn_google_signin.png"
              alt="Sign in button"
              width="170px"
            />
          </a>
          <a v-else class="btn btn-studio-theme-space disabled"
            ><span>{{ connectionText }}</span></a
          >
        </template>
      </div>
    </div>
    <div>
      <div
        v-if="isPinterestListing && hasAccountItems"
        class="flex items-center py-2 px-6"
      >
        <div class="flex basic_form items-center">
          <div class="field_group">
            <label class="radio_container">
              <input
                id="pin_board"
                v-model="pinterest.visible"
                name="profile_board_toggle"
                type="radio"
                value="board"
                @change="togglePinterestBoardProfile"
              />
              <div>
                Show Boards ({{ pinterestBoardCount }})
                <span class="check"></span>
              </div>
            </label>
          </div>
          <div class="field_group">
            <label class="radio_container">
              <input
                id="pin_profile"
                v-model="pinterest.visible"
                name="profile_board_toggle"
                type="radio"
                value="profile"
                @change="togglePinterestBoardProfile"
              />
              <div>
                Show Profiles ({{ pinterestProfileCount }})
                <span class="check"></span>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="w-full rounded-xl px-4 pb-2">
        <table class="w-full p-2">
          <thead
            v-if="hasAccountItems"
            class="focus:outline-none h-16 bg-gray-50"
          >
            <tr>
              <th class="text-center">
                <CstSimpleCheckbox
                  v-model="selectAll"
                  @change="onChangeSelectAll"
                ></CstSimpleCheckbox>
              </th>
              <th class="pl-3">Name</th>
              <th class="text-center">Type</th>
              <th v-if="type === 'facebook'" class="text-center">
                Publish As
                <a href="javascript:;">
                  <i
                    v-tooltip="
                      'Set the person or page on whose behalf you would like to post as on a Facebook Group. If you don\'t see the drop down please reconnect your group.'
                    "
                    class="ml-1 far fa-question-circle cursor_pointer"
                  ></i>
                </a>
              </th>
              <th v-if="isPinterestBoardListing" class="text-center"
                >Integrations</th
              >
              <th v-if="isPinterestBoardListing" class="text-center"
                >Profile</th
              >
              <th v-if="showQueueTime" class="text-center">Queue Time</th>
              <th v-if="showTokenStatus" class="text-center">Token Status</th>
              <th v-if="isInstagramListing" class="text-center"
                >Notifications</th
              >
              <th v-if="canSaveSocialAccounts" class="text-center">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="accountLoadingStatus">
              <td colspan="4" class="text-center">
                <clip-loader color="#4165ed" :size="'12px'"></clip-loader>
              </td>
            </tr>

            <template v-if="!accountLoadingStatus">
              <template v-if="hasAccountItems">
                <template v-for="(account, key) in accountItems">
                  <tr
                    :key="key"
                    style="border-top: 1px solid #f1f1f1"
                    class="focus:outline-none h-16"
                  >
                    <td class="text-center">
                      <CstSimpleCheckbox
                        v-model="selectedAccounts"
                        :name="account._id"
                        @change="onSelectSocialAccount"
                      ></CstSimpleCheckbox>
                    </td>
                    <td class="pl-3">
                      <div class="profile_picture">
                        <div class="picture_block">
                          <img
                            v-if="type === 'Gmb'"
                            src="/img/google-business.png"
                            alt=""
                          />
                          <img
                            v-else
                            :src="platformImage(account)"
                            alt=""
                            onerror="this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                          />
                        </div>
                        <div class="text_block">
                          <p
                            v-if="
                              isPinterestProfileListing &&
                              !commonMethods.isPinterestAccountIsManuallyConnected(
                                account
                              )
                            "
                            class="text"
                            >{{ getPinterestProfileName(account) }}
                          </p>
                          <p v-else class="text"
                            >{{ accountName(account) }}
                            <i
                              v-if="showAppInstallationWarning"
                              v-tooltip="{
                                content: tooltipHtml,
                                classes: 'team_tooltip',
                              }"
                              class="fas fa-exclamation warning_icon"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">{{ accountType(account) }}</td>
                    <td v-if="isPinterestBoardListing" class="text-center">
                      <p
                        v-if="
                          !commonMethods.isPinterestAccountIsManuallyConnected(
                            account
                          )
                        "
                        >API</p
                      >
                      <template
                        v-else-if="commonMethods.showZapierIntegration(account)"
                      >
                        <i
                          v-if="commonMethods.showZapierIntegration(account)"
                          v-tooltip.top-center="'Remove'"
                          style="
                            margin-left: 5px;
                            font-size: 1em;
                            color: orangered;
                          "
                          class="c-pointer icon-zapier_icon d-inline-block align-middle"
                          @click.prevent="
                            removeInstagramExternalIntegration(
                              account,
                              'zapier'
                            )
                          "
                        >
                        </i>
                      </template>
                      <p
                        v-if="
                          !commonMethods.showZapierIntegration(account) &&
                          commonMethods.isPinterestAccountIsManuallyConnected(
                            account
                          )
                        "
                        >-</p
                      >
                    </td>
                    <td v-if="type === 'facebook'" class="text-center">
                      <div
                        v-if="
                          type === 'facebook' &&
                          account['type'] === 'Group' &&
                          account['posted_as'] &&
                          account['posted_as'].length
                        "
                        class="flex items-center justify-center"
                      >
                        <AccountSelectionDropdown
                          :accounts="account['posted_as']"
                          type="single"
                          @select="
                            (id) =>
                              onPublishAccountChange(account['_id'], id, key)
                          "
                        />
                        <v-popover
                          v-if="account['posted_as'].length > 1"
                          class="d-inline-flex ml-2"
                          placement="bottom-start"
                          popover-class="first-comment__info-popover"
                          trigger="hover"
                        >
                          <!-- This will be the popover target (for the events and position) -->
                          <a href="#"><i class="far fa-question-circle"></i></a>
                          <!-- This will be the content of the popover -->
                          <template slot="popover">
                            <div>
                              <span>
                                Your posts on this Facebook group will be
                                published either from a Profile or a Page
                                depending upon what option you select here.
                                <br /><br />
                                However, you can post First Comment on this
                                Facebook Group only when a Page option is
                                selected here.
                              </span>
                            </div>
                          </template>
                        </v-popover>
                      </div>
                    </td>
                    <!--<td>{{account.added_by}}</td>-->
                    <td v-if="isPinterestBoardListing" class="text-center"
                      >{{ pinterestProfileName(account.profile_id) }}
                    </td>
                    <td v-if="showQueueTime" class="text-center">{{
                      getQueuedTimesCount(account)
                    }}</td>
                    <td v-if="showTokenStatus" class="text-center">
                      <div
                        class="flex items-center justify-center w-full status_list"
                      >
                        <div>
                          <div
                            v-tooltip.top-center="account.validity_error"
                            v-html="accountExpirationStates(account)"
                          >
                          </div>
                          <div
                            v-tooltip.top-center="limitExceededTooltip(account)"
                            class="status_items"
                          ></div>
                        </div>
                        <template
                          v-if="
                            canSaveSocialAccounts &&
                            !commonMethods.isPinterestAccountIsManuallyConnected(
                              account
                            )
                          "
                        >
                          <i
                            v-if="connectLinkVisibility"
                            v-tooltip.top-center="'Reconnect'"
                            class="icon_table cs-refresh cursor-pointer ml-1"
                            @click.prevent="
                              connectAccount('reconnect', account)
                            "
                          >
                          </i>
                        </template>
                      </div>
                    </td>
                    <td
                      v-if="isInstagramListing && canSaveSocialAccounts"
                      class="text-center"
                    >
                      <div class="device_text">
                        <span @click="showAccountNotificationDevices(account)"
                          >{{ enableDevicesCount(account) }}/{{
                            getMobileDevices.length
                          }}
                          device(s)</span
                        >

                        <!--<span v-else>{{enableDevicesCount(account)}}/{{getMobileDevices.length}} device(s)</span>-->
                      </div>
                    </td>
                    <td v-if="canSaveSocialAccounts" class="text-center">
                      <div class="action_icons">
                        <i
                          v-if="false"
                          v-tooltip.top-center="'Create Board'"
                          class="action_icon sm far fa-plus"
                          @click.prevent="
                            triggerBoardCreation(account.pinterest_id)
                          "
                        ></i>
                        <template v-if="!isPinterestProfileListing">
                          <i
                            v-tooltip.top-center="'Change Queue Schedule'"
                            class="action_icon sm icon-Calendar"
                            data-cy="fetch-queue-slots"
                            @click.prevent="fetchAccountQueueSlots(account)"
                          ></i>
                        </template>
                        <i
                          v-if="
                            connectLinkVisibility &&
                            !commonMethods.isPinterestAccountIsManuallyConnected(
                              account
                            )
                          "
                          v-tooltip.top-center="'Reconnect'"
                          class="action_icon sm cs-refresh"
                          @click.prevent="connectAccount('reconnect', account)"
                        ></i>
                        <i
                          v-if="account.policy && account.policy.can_remove"
                          v-tooltip.top-center="'Remove'"
                          class="action_icon sm icon-delete-cs"
                          @click.prevent="removeAccount(account)"
                        ></i>

                        <i
                          v-tooltip.top-center="'Shuffle'"
                          class="action_icon sm fa fa-random"
                          @click.prevent="
                            shuffleQueue(accountConnectorId(account))
                          "
                        ></i>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div
        v-if="!hasAccountItems"
        class="flex flex-col items-center justify-center py-4 mb-2"
      >
        <p v-if="canSaveSocialAccounts" class="text-base">
          You have not connected any account yet, click on the button to get
          started.</p
        >
        <p v-else class="text-base">You have not connected any account.</p>
        <div v-if="isInstagramListing">
          <a
            href="https://docs.contentstudio.io/article/722-how-to-connect-instagram-business-account-with-contentstudio"
            target="_blank"
            class="insta-link"
            >Learn how to connect Instagram with ContentStudio.
          </a>
        </div>
        <div v-if="isPinterestListing">
          <a
            href="https://docs.contentstudio.io/article/833-how-to-connect-pinterest-boards-accounts-with-contentstudio"
            target="_blank"
            class="insta-link"
            >Learn how to connect Pinterest with ContentStudio.
          </a>
        </div>
      </div>
      <div v-if="isInstagramListing" class="py-4 px-4">
        <span class="text-base font-normal">
          Want to learn everything from connection of Instagram business account
          to posting?</span
        >
        <a
          href="https://docs.contentstudio.io/article/729-getting-started-with-instagram-publishing"
          target="_blank"
          class="text-base"
        >
          Read through our help document.</a
        >
      </div>
      <div v-if="isPinterestListing" class="py-4 px-4">
        <span class="text-base font-normal"
          >Want to learn everything from connection of Pinterest account
          toposting?</span
        >
        <a
          href="https://docs.contentstudio.io/article/842-getting-started-with-pinterest-publishing"
          target="_blank"
          class="text-base"
        >
          Read through our help document.</a
        >
      </div>
    </div>
  </div>
</template>
