<template>
  <div>
    <div class="top_filter_section bottom_border relative">
      <!-- showing the search bar-->
      <div class="filter_section_inner width_750">
        <!--<h3 class="filter_section_heading">What are you interested in today?</h3>-->

        <!-- search bar -->
        <div class="search_input with_icon_right d-flex align-items-center">
          <div class="search_inner">
            <input
              type="text"
              v-model="getQuotesFilter.search"
              @keyup.enter="searchQuotesByKeyword()"
              placeholder='Search for a keyword, e.g motivation quotes or "fitness quotes"'
            />
            <button
              class="search_btn input_icon search_icon gradient_color"
              style="top: 1.125rem"
              @click.prevent="searchQuotesByKeyword()"
            >
              <i class="icon-Search"></i>
              <!--<beat-loader :color="'#ffffff'" :size="'1.125rem'" v-if="getSearchLoader.button.search"></beat-loader>-->
            </button>
          </div>
          <search-assistant-table></search-assistant-table>
        </div>
      </div>
    </div>

    <div class="ds_article_filter">
      <div class="d-flex align-items-center">
        <!-- accounts channel left filter -->
        <div class="left_filter">
          <div class="icon_tabs">
            <!-- Commented this as we will be adding Facebook quotes later on. <ul class="tab_ul">
                            <li class="tab_li">
                                <router-link :to="{'name': 'web'}"  class="tab_a d-flex align-items-center justify-content-center  quotes_gray_icon">
                                    <span class="icon"></span>
                                </router-link>
                            </li>
                            <li class="tab_li" v-if="false">
                                <router-link :to="{'name': 'facebook'}"
                                             class="tab_a d-flex align-items-center justify-content-center  facebook_gray_icon">
                                    <span class="icon"></span>
                                </router-link>

                            </li>

                        </ul>-->
          </div>
        </div>
      </div>
    </div>
    <web-quotes></web-quotes>
  </div>
</template>
<script>
import moment from 'moment'
// import bootstrapDatepicker from 'bootstrap-daterangepicker'
import WebQuotes from './WebQuotes'

import { mapActions, mapGetters } from 'vuex'
import SearchAssistantTable from '../search/SearchAssistantTable'

export default {
  components: {
    SearchAssistantTable,
    WebQuotes
  },

  data () {
    return {}
  },
  created () {},
  computed: {
    ...mapGetters(['getQuotesFilter'])
  },
  mounted () {
    // $('.datepicker_filter_quote input[name="daterange"]').daterangepicker({
    //   // autoUpdateInput: false,
    //   opens: 'left',
    //   ranges: {
    //     '1 hour': [moment.utc().subtract(1, 'hours'), moment.utc()],
    //     '3 hours': [moment.utc().subtract(3, 'hours'), moment.utc()],
    //     '6 hours': [moment.utc().subtract(6, 'hours'), moment.utc()],
    //     '12 hours': [moment.utc().subtract(12, 'hours'), moment.utc()],
    //     '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
    //     '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
    //     'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
    //     'Last 7 Days': [moment().subtract(6, 'days'), moment()]
    //   },
    //   locale: {
    //     format: 'YYYY-MM-DD'
    //   },
    //   // startDate: moment().subtract(6, 'days'),
    //   endDate: new Date()
    //   // showCustomRangeLabel: false,
    //   // alwaysShowCalendars: false
    //
    // }, function (start, end, label) {
    //   console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')')
    // })
  },

  methods: {
    ...mapActions([])
  },

  watch: {}
}
</script>
