<template>
  <div class="contains-filter-btn">
    <template v-if="type !== 'report'">
      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown mr-2 post-filter-btn dropdown-size-small"
        no-caret
        :style="!top_posts_loader ? 'top: -8px;' : ''"
        :disabled="top_posts_loader"
      >
        <template slot="button-content">
          <i class="cs-world-fill studio-theme-icon"></i>
          <span class="capitalize_text">Top {{ limit }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item @click="limit = 5">Top 5</b-dropdown-item>
        <b-dropdown-item @click="limit = 10">Top 10</b-dropdown-item>
        <b-dropdown-item @click="limit = 15">Top 15</b-dropdown-item>
      </b-dropdown>
    </template>

    <b-tabs
      nav-class="analytics-tabs"
      content-class="mt-3 analytics-tabs-content"
    >
      <template v-if="top_posts_loader">
        <div class="grid-flex">
          <div class="cell-4 cell-xl-6 cell-xxl-4 analytics-tab-body__item">
            <div class="analytics-tab-body__item-image">
              <SkeletonBox width="140px" height="140px" radius=".625rem" />
            </div>
            <div
              class="analytics-tab-body__item-profile"
              style="
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                width: 100%;
              "
            >
              <div class="analytics-tab-body__item-profile-header">
                <SkeletonBox height="2.8rem" width="2.8rem" radius="50%" />
                <div
                  class="d-flex"
                  style="flex-direction: column; margin-left: 10px"
                >
                  <div class="mb-1">
                    <SkeletonBox width="80px" radius=".2rem" />
                  </div>
                  <SkeletonBox width="140px" radius=".2rem" />
                </div>
              </div>
              <div class="analytics-tab-body__item-profile-body">
                <div class="mb-1">
                  <SkeletonBox width="100%" radius=".2rem" />
                </div>
                <div class="mb-1">
                  <SkeletonBox width="100%" radius=".2rem" />
                </div>
                <SkeletonBox width="100%" radius=".2rem" />
              </div>
            </div>
          </div>
          <div class="cell-2 analytics-tab-body__item">
            <SkeletonBox width="60px" radius=".2rem" />
          </div>
          <div class="cell-2 cell-xl-1 cell-xxl-2 analytics-tab-body__item">
            <SkeletonBox width="60px" radius=".2rem" />
          </div>
          <div class="col-2 analytics-tab-body__item">
            <SkeletonBox width="60px" radius=".2rem" />
          </div>
          <div class="cell-2 cell-xl-1 cell-xxl-2 analytics-tab-body__item">
            <SkeletonBox width="60px" radius=".2rem" />
          </div>
        </div>
      </template>
      <template v-else v-for="(top_post, index_key) in top_posts">
        <b-tab
          :key="index_key"
          :title="capitalizeFirstLetter(index_key)"
          :active="index_key === 'overall'"
          lazy
        >
          <div
            class="analytics-tab-header"
            :class="{ disabled: top_post.length <= 0 }"
          >
           <div class="grid grid-cols-12 px-2.5 w-full">
             <div class="col-span-4 xl:col-span-6 2xl:col-span-8 analytics-tab-header__item">
               <span>Posts</span>
             </div>
             <template v-for="(sort, key) in sort_types">
               <div
                   class="analytics-tab-header__item pr-0"
                   :class="
                  key === 'engagements' || key === 'comments'
                    ? 'col-span-2 2xl:col-span-1'
                    : 'col-span-2 lg:col-span-1'
                "
                   :key="index_key + key + sort.up + '123'"
               >
                 <span>{{ capitalizeFirstLetter(key) }}</span>

                 <!-- Controls -->
                 <template v-if="showSort(index_key, key)">
                  <span class="analytics-tab-header__item-controls">
                    <i
                        class="fas fa-caret-up"
                        :class="sort.up ? 'active' : ''"
                        @click="changeSort(key, 'up', index_key)"
                    ></i>
                    <i
                        class="fas fa-caret-down"
                        :class="sort.down ? 'active' : ''"
                        @click="changeSort(key, 'down', index_key)"
                    ></i>
                  </span>
                 </template>
               </div>
             </template>
           </div>
          </div>
          <div class="analytics-tab-body">
            <template>
              <template v-if="top_post.length <= 0">
                <div class="analytics-no-data-found">
                  <img
                    src="../../../assets/imgs/no_data_images/no_post_found.svg"
                    alt=""
                  />
                  <p>You have not published any posts yet.</p>
                </div>
              </template>
              <template v-else>
                <template v-for="(posts, index) in top_post">
                  <div v-if="index < limit" :key="index">
                    <PostRow :posts="posts" :index="index_key" />
                    <hr
                      class="analytics-tab-body-divider"
                      v-if="index !== limit - 1"
                    />
                  </div>
                </template>
              </template>
            </template>
          </div>
        </b-tab>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PostRow from '@src/modules/analytics/views/common/PostRow'

// Skeleton
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import * as _ from 'underscore'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsTopPosts',
  props: ['top_posts_loader', 'top_posts', 'type'],
  data () {
    return {
      limit: 5,
      existing_sort: 'engagements_up',
      sort_types: {
        engagements: {
          up: true,
          down: false
        },
        likes: {
          up: false,
          down: false
        },
        comments: {
          up: false,
          down: false
        },
        shares: {
          up: false,
          down: false
        }
      }
    }
  },
  mounted () {},
  components: {
    PostRow,
    SkeletonBox
  },
  methods: {
    ...mapActions(['getAnalyzeService']),

    changeSort (field, type, section) {
      this.top_posts_loader = true
      const res = this.existing_sort.split('_')
      if (field === res[0] && type === res[1]) {
        this.top_posts_loader = false
        return
      }
      let sort_field = false
      if (section === 'overall') {
        sort_field = 'total_engagement'
      } else {
        switch (field) {
          case 'engagements':
            sort_field = this.getEngagementField(section)
            break
          case 'comments':
            sort_field = this.getCommentField(section)
            break
          case 'likes':
            sort_field = this.getLikeField(section)
            break
          case 'shares':
            sort_field = this.getSharesField(section)
            break
        }
      }
      if (sort_field === false) {
        this.top_posts_loader = false
        return
      }
      if (type === 'up') {
        this.top_posts[section] = _.sortBy(
          this.top_posts[section],
          sort_field
        ).reverse()
      } else {
        this.top_posts[section] = _.sortBy(this.top_posts[section], sort_field)
      }

      this.existing_sort = field + '_' + type
      this.sort_types[field][type] = true
      this.sort_types[res[0]][res[1]] = false
      this.top_posts_loader = false
    },
    showSort (index_key, key) {
      if (index_key === 'overall') {
        if (key !== 'engagements') {
          return false
        }
      }
      return true
    }
  },
  watch: {}
}
</script>
