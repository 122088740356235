<template>
  <div class="ds_inner_pages" v-if="getTopicModule == 'insights'">
    <template v-if="getInsightsLoader">
      <beat-loader :color="'#436aff'"></beat-loader>
    </template>
    <template v-else>
      <overview :module="'topics'"></overview>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { topicTypes } from '../../../store/mutation-types'

const Overview = () => import('../../insights/Overview.vue')

export default {
  components: {
    Overview
  },
  computed: {
    ...mapGetters(['getTopicModule', 'getInsightsLoader'])
  },
  methods: {
    ...mapMutations(['SET_TOPIC_MODULE', topicTypes.SET_CHANGE_TOPIC_FILTERS]),
    ...mapActions(['topicInsights'])
  },
  mounted () {
    this.SET_TOPIC_MODULE('insights')
    let res
    switch (this.$route.params.module) {
      case 'custom':
        res = this.isCustomTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'custom_topic',
            contentType: this.getTopicContentType,
            module: this.getTopicModule
          })
          this.topicInsights()
        }
        break
      case 'curated':
        res = this.isCuratedTopicAvailable()
        if (res) {
          this.SET_CHANGE_TOPIC_FILTERS({
            topic: res.topic,
            type: 'curated',
            contentType: this.getTopicContentType,
            module: this.getTopicModule
          })

          this.topicInsights()
        }
        break
    }

    // this.discoveryMounted()
  },

  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Insights View:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')
    next()
  }
}
</script>
