<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
      type="linkedin"
      :style="{
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
      :updating="fetching_data"
      :getAccounts="getAccounts"
      :getDate="getDate"
      :getPreviousDate="getPreviousDate"
    ></AnalyticsFilterBar>
    <div
      class="analytics-main-errors"
      v-if="getPlatformAccounts('linkedin').length <= 0 && !fetching_data"
    >
      <img
        draggable="false"
        src="../../assets/imgs/no_data_images/no-account-connected.svg"
        alt=""
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect Linkedin Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getPlatformAccounts('linkedin').length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selected_account.state === 'Added'">
        <analytics-loading :name="selected_account.name"></analytics-loading>
      </template>
      <template v-else>
        <LinkedInTabs v-if="!getFetchSocialStatus">
          <!-- overview tab -->
          <template slot="overview" v-if="selected_account">
            <OverviewTab
              :account="selected_account"
              :date="date"
              :previous_date="previous_date"
              :getOverviewLoader="getOverviewLoader"
              :profile="selected_account.type"
            ></OverviewTab>
          </template>

          <!-- posts tab -->
          <template slot="posts">
            <PostsTab
              :account="selected_account"
              :date="date"
              :previous_date="previous_date"
              :getPostsLoader="getPostsLoader"
            ></PostsTab>
          </template>
        </LinkedInTabs>
      </template>

      <div class="analytics-content-sync-box">
        <!--        <template v-if="selected_account.last_analytics_updated_at">-->
        <!--          <p class="mr-4">Updated {{ timesAgo(selected_account.last_analytics_updated_at) }}</p>-->
        <!--        </template>-->
        <!--        <div class="update_box d-flex ml-2" style="cursor: pointer"-->
        <!--             :class="{'disable_click':selected_account.state === 'Syncing'}" @click="reFetchData()">-->
        <!--          <template-->
        <!--              v-if="getSyncServiceStatus===true || selected_account.state === 'Syncing'">-->
        <!--            <i class="fas fa-sync"></i> <span>  Syncing...</span>-->
        <!--          </template>-->
        <!--          <template v-else>-->
        <!--              <i class="fas fa-sync"></i> <span>  Sync</span>-->
        <!--          </template>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import LinkedInTabs from '@src/modules/analytics/views/linkedin/components/LinkedInTabs'

// Tabs
import OverviewTab from '@src/modules/analytics/views/linkedin/tabs/OverviewTab'
import PostsTab from '@src/modules/analytics/views/linkedin/tabs/PostsTab'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { mapActions, mapGetters } from 'vuex'
import AnalyticsLoading from '../../components/common/AnalyticsLoading'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { linkedin } from '@src/modules/integration/store/states/mutation-types'
import { SYNC_ERROR } from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsOverview',

  components: {
    AnalyticsFilterBar,
    LinkedInTabs,
    OverviewTab,
    PostsTab,
    AnalyticsLoading
  },
  data () {
    return {
      selected_account: [],
      date: '',
      previous_date: '',
      fetching_data: false
    }
  },
  computed: {
    ...mapGetters(['getFetchSocialStatus', 'getSyncServiceStatus'])
  },
  created () {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.resetState()

      const pusher = pusherAuthSocketAnalytics()
      this.analytics_channel = pusher.subscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
      if (this.selected_account.linkedin_id) {
        this.bindSocketListeners()
      }
    })
  },
  mounted () {
    const pusher = pusherAuthSocketAnalytics()
    this.analytics_channel = pusher.subscribe(
      'analytics-channel-' + this.getActiveWorkspace._id
    )
    if (this.selected_account.linkedin_id) {
      this.bindSocketListeners()
    }
  },
  beforeDestroy () {
    this.removeSocketListners(this.selected_account.linkedin_id)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
        'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account.linkedin_id
      )
      this.analytics_channel.unsubscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  methods: {
    ...mapActions(['syncUpdateService']),
    getAccounts (account) {
      this.selected_account = account
      console.log('account changed')
      console.log(this.selected_account)
    },
    getDate (date) {
      this.date = date
    },
    getPreviousDate (date) {
      this.previous_date = date
    },
    getOverviewLoader (value) {
      this.fetching_data = value
    },
    getPostsLoader (value) {
      this.fetching_data = value
    },
    async reFetchData () {
      if (this.selected_account.state === 'Syncing') {
        return
      }
      if (
        this.hourDifference(this.selected_account.last_analytics_updated_at) < 4
      ) {
        this.alertMessage(SYNC_ERROR, 'error')
        return
      }
      const payload = {
        selected_account: this.selected_account.linkedin_id,
        channel: 'linkedin'
      }
      const resp = await this.syncUpdateService(payload)
      if (resp.status) {
        this.setLIState(
          resp.state,
          this.selected_account.linkedin_id,
          this.selected_account.last_analytics_updated_at
        )
      }
      console.log(resp)
    },

    setLIState (state, checkFor, last_analytics_updated_at) {
      var updatedItems = []
      for (const item in this.getLinkedinAccounts.items) {
        console.log('ffdsdfdfs')
        console.log(item)
        const update = this.getLinkedinAccounts.items[item]
        if (update.linkedin_id === checkFor) {
          update.state = state
          // eslint-disable-next-line camelcase
          update.last_analytics_updated_at = last_analytics_updated_at
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'linkedin',
        all_item_setter: linkedin.SET_ALL_ACCOUNTS,
        item_setter: linkedin.SET_ACCOUNTS
      })
    },
    removeSocketListners (value) {
      console.log('removing socket for linkedin ... ')
      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners () {
      const event =
        'syncing-' +
        this.getActiveWorkspace._id +
        '-' +
        this.selected_account.linkedin_id
      this.analytics_channel.bind(event, (data) => {
        console.log('message data --> ', data)
        this.setLIState(
          data.state,
          data.account,
          data.last_analytics_updated_at
        )
      })
    }
  },
  watch: {
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
      }
    }
  }
}
</script>
