<template>
  <div class="name popover-social-statistics__container">
    <p class="engagement_text">Engagement</p>
    <div class="popover-social-statistics__container__box">
      <div class="popover-social-statistics__container__box__inner">
        <div
          class="popover-social-statistics__container__box__inner__item"
          v-tooltip.top-center="'Facebook Shares'"
        >
          <span class="icon_item facebook_color">
            <i class="fb fab fa-facebook-f"></i>
          </span>

          <span v-if="post._source.facebook_shares">{{
            intToString(post._source.facebook_shares)
          }}</span>

          <!--<h3>{{intToString(calculateEngagements(post._source))}}</h3>-->

          <span v-else>0</span>
        </div>
        <div
          class="popover-social-statistics__container__box__inner__item"
          v-tooltip.top-center="'Twitter Shares'"
        >
          <span class="icon_item">
            <i class="tw fab fa-twitter"></i>
          </span>
          <span v-if="post._source.twitter_shares">{{
            intToString(post._source.twitter_shares)
          }}</span>
          <span v-else>0</span>
        </div>
        <div
          class="popover-social-statistics__container__box__inner__item"
          v-tooltip.top-center="'Pinterest Shares'"
        >
          <span class="icon_item">
            <i class="pin fab fa-pinterest-p"></i>
          </span>
          <span v-if="post._source.pins">{{
            intToString(post._source.pins)
          }}</span>
          <span v-else>0</span>
        </div>
        <div
          class="popover-social-statistics__container__box__inner__item"
          v-tooltip.top-center="'Reddit Shares'"
        >
          <span class="icon_item">
            <i class="reddit fab fa-reddit"></i>
          </span>
          <span v-if="post._source.reddit">{{
            intToString(post._source.reddit)
          }}</span>
          <span v-else>0</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {}
  }
}
</script>
