import { swalAttributes } from '@common/constants/common-attributes'

export default {
  methods: {
    exitConfirmation (to = 'automation', title, slug = '') {
      this.$bvModal
        .msgBoxConfirm(`You are about to exit ${title} builder, you have unsaved changes that will be lost. Are you sure you want to exit?`, {
          title: 'Please Confirm',
          ...swalAttributes()
        })
        .then((value) => {
          console.debug('msgBoxConfirm', value)
          if (typeof value !== 'object') {
            if (value) {
              this.$router.push({ name: to, params: { workspace: slug } })
            }
          }
        })
        .catch((err) => {
          // An error occurred
          console.error('msgBoxConfirm', err)
        })
    }
  }
}
