<template>
  <div ref="line-chart-container" class="h-chart w-100">
    <highcharts :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: 'LineBasicChart',
  props: {
    categories: {
      type: Array,
      default: []
    },
    series: {
      default: [
        {
          name: 'Active Users',
          data: [43934, 52503, 57177, 97031, 119931, 69658, 154175, 137133],
          color: '#2fe095'
        }
      ]
    }
  },
  data () {
    return {
      dataOptions: {}
    }
  },
  mounted () {
    this.initializeDataOptions()
    this.$nextTick(function () {
      if (this.$refs['line-chart-container']) {
        this.dataOptions.chart.height =
          this.$refs['line-chart-container'].offsetHeight - 32
      }
    })
  },
  methods: {
    initializeDataOptions () {
      this.dataOptions = {
        title: {
          text: ' '
        },

        chart: {
          type: 'line'
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },

        xAxis: {
          categories: this.categories
        },

        plotOptions: {
          series: {
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            },
            shadow: true,
            lineWidth: 4
          }
        },

        series: this.series
      }
    }
  }
}
</script>
