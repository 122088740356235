var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('create-publication-folder'),_c('b-modal',{attrs:{"id":"social_share_modal","modal-class":"social_share_modal social_share_only top_left_design_box","static":"","no-enforce-focus":"","hide-footer":"","hide-header":""},on:{"hide":_vm.onhide}},[_c('model-layout',{attrs:{"type":"Social","isCSV":_vm.isCSV}}),_c('div',{staticClass:"modal_body",staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"modal_inner_content basic_form",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"d-flex align-items-stretch"},[_c('div',{staticClass:"content",staticStyle:{"padding-right":"20px"}},[_c('div',{staticClass:"content_inner"},[(
                  !_vm.getPublishLoaders.initializeArticlePostCreation &&
                  !_vm.getPublishSelection.queueStatus &&
                  _vm.getUserRole !== 'Approver'
                )?_c('content-category-selection',{attrs:{"module":'publish'}}):_vm._e(),(
                  !_vm.getPublishLoaders.initializeArticlePostCreation &&
                  !_vm.getPublishSelection.queueStatus &&
                  _vm.isGlobalContentCategory
                )?_c('content-category-note'):_vm._e(),(
                  !_vm.getPublishLoaders.initializeArticlePostCreation &&
                  !_vm.isGlobalContentCategory
                )?_c('account-selection',{attrs:{"showLabel":false,"showPublishAs":true}}):_vm._e(),(_vm.getPublishLoaders.initializeArticlePostCreation)?_c('span',{staticClass:"text-center",staticStyle:{"display":"block"}},[_c('clip-loader',{staticStyle:{"margin-bottom":"0.625rem"},attrs:{"color":"#4165ed","size":'16px'}}),(_vm.$route.path.includes('/discovery/'))?_c('span',[_vm._v("Fetching data & creating post...")]):_vm._e()],1):_vm._e(),(_vm.getCommonBoxOptions.globalCategoryStatus)?[_c('beat-loader',{attrs:{"color":'#436aff'}})]:_vm._e(),(_vm.getAccountSelection.instagram.length > 0)?_c('div',{staticClass:"input_field"},[_c('div',{staticClass:"compose_warning_box text-center"},[_c('i',{staticClass:"far fa-question-circle test"}),_c('p',[_vm._v(" Want to learn how Instagram publishing works? Learn about posting via "),_c('a',{staticClass:"insta-link",attrs:{"href":"https://docs.contentstudio.io/article/829-how-to-post-through-instagram-api","target":"_blank"}},[_vm._v("Instagram API ")]),_vm._v(" or "),_c('a',{staticClass:"insta-link",attrs:{"href":"https://docs.contentstudio.io/article/732-how-to-post-through-push-notifications","target":"_blank"}},[_vm._v("Mobile Notifications ")])])])]):_vm._e(),(!_vm.getPublishLoaders.initializeArticlePostCreation)?_c('toggle-selection'):_vm._e(),_c('single-box',{class:{
                  hide:
                    !_vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus,
                }}),_c('facebook-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.facebook.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.facebook),
                }}),_c('twitter-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.twitter.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.twitter),
                }}),(
                  !_vm.getCommonBoxOptions.status &&
                  _vm.getAccountSelection['twitter'] &&
                  _vm.getAccountSelection['twitter'].length > 0 &&
                  _vm.getTwitterOptions.has_threaded_tweets === false
                )?_c('div',{staticClass:"post_share_option post_share_option_social_threaded_tweet basic_form_inline clear-both"},[_c('div',{staticClass:"d-flex align-items-start mb-3"},[_c('div',{staticClass:"left_input"},[_c('p',{staticClass:"label"},[_vm._v("Add threaded Tweets "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
                          'Learn more about threaded tweets'
                        ),expression:"\n                          'Learn more about threaded tweets'\n                        ",modifiers:{"top-center":true}}],staticClass:"beacon ml-2",attrs:{"data-beacon-article-modal":"61826fd112c07c18afde3ed5","href":"#"}},[_c('i',{staticClass:"far fa-question-circle"})])])]),_c('div',{staticClass:"right_input d-flex align_center"},[_c('label',{staticClass:"switch-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getTwitterOptions.has_threaded_tweets),expression:"getTwitterOptions.has_threaded_tweets"}],attrs:{"type":"checkbox","disabled":!_vm.isThreadedTweetsEnabled},domProps:{"checked":Array.isArray(_vm.getTwitterOptions.has_threaded_tweets)?_vm._i(_vm.getTwitterOptions.has_threaded_tweets,null)>-1:(_vm.getTwitterOptions.has_threaded_tweets)},on:{"change":function($event){var $$a=_vm.getTwitterOptions.has_threaded_tweets,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.getTwitterOptions, "has_threaded_tweets", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.getTwitterOptions, "has_threaded_tweets", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.getTwitterOptions, "has_threaded_tweets", $$c)}}}}),_c('div',{staticClass:"slider round",class:{ 'disabled grey': !_vm.isThreadedTweetsEnabled }})])])])]):_vm._e(),(_vm.getCommonBoxOptions.status)?_c('error-section',{attrs:{"errors":_vm.socialPostContentErrors(),"warnings":_vm.socialPostContentWarnings()}}):_vm._e(),_c('transition',{attrs:{"name":"slide-bottom"}},[_c('ThreadedTweetsSection',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.getTwitterOptions.has_threaded_tweets &&
                    _vm.getAccountSelection['twitter'].length > 0
                  ),expression:"\n                    getTwitterOptions.has_threaded_tweets &&\n                    getAccountSelection['twitter'].length > 0\n                  "}]})],1),_c('linkedin-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.linkedin.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.linkedin),
                }}),_c('pinterest-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.pinterest.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.pinterest),
                }}),_c('tumblr-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.tumblr.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.tumblr),
                }}),_c('instagram-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.instagram.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.instagram),
                }}),(_vm.instagramSelection.no_access_accounts.length > 0)?_c('zapier-warnings',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.instagram.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.instagram),
                },attrs:{"aspect_ratio":false,"no_access_accounts":_vm.instagramSelection.no_access_accounts}}):_vm._e(),_c('youtube-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.youtube.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.youtube),
                }}),_c('tiktok-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.tiktok.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.tiktok),
                }}),_c('gmb-box',{class:{
                  hide:
                    _vm.getCommonBoxOptions.status ||
                    _vm.getPublishLoaders.initializeArticlePostCreation ||
                    _vm.getCommonBoxOptions.globalCategoryStatus ||
                    (_vm.getAccountSelection.gmb.length === 0 &&
                      !_vm.isGlobalContentCategorySelected) ||
                    (_vm.isGlobalContentCategorySelected &&
                      !_vm.getCommonBoxOptions.globalCategoryChannels.gmb),
                }}),(!_vm.getCommonBoxOptions.status)?_c('error-section',{attrs:{"errors":_vm.socialPostContentErrors(),"warnings":_vm.socialPostContentWarnings()}}):_vm._e(),(_vm.instagramSelection.no_access_accounts.length > 0)?_c('zapier-warnings',{class:{
                  hide:
                    !_vm.getCommonBoxOptions.status ||
                    _vm.getCommonBoxOptions.globalCategoryStatus,
                },attrs:{"aspect_ratio":false,"no_access_accounts":_vm.instagramSelection.no_access_accounts}}):_vm._e(),(!_vm.getPublishLoaders.initializeArticlePostCreation)?[_c('hashtag-suggessions'),(_vm.getPublishSelection.status !== 'published')?_c('div',{staticClass:"post_share_option"},[(_vm.getPublishSelection.reopen)?_c('div',{staticClass:"text-center warning_box mt-3"},[_c('p',[_vm._v("This post is either missed review, failed or rejected thats why publishing time options have been reset.")])]):_vm._e(),(_vm.getAccountSelection.gmb.length)?_c('gmb-options'):_vm._e(),(_vm.getAccountSelection.youtube.length)?_c('youtube-options'):_vm._e(),(_vm.getPublishSelection.queueStatus)?_c('div',{staticClass:"post_footer text-right clear"},[_c('div',{staticClass:"warning_box pull-left"},[_vm._v("This post is queued and scheduled on "),_c('strong',[_vm._v(" "+_vm._s(_vm.getWorkspaceTimeZoneTime( _vm.getPublishSelection.planExecutionTime['date'], 'MMM DD, hh:mm a' ))+" ")]),(_vm.getUserRole !== 'Approver')?_c('i',{staticClass:"icon-edit-cs",on:{"click":function($event){$event.preventDefault();return _vm.editQueuePost()}}}):_vm._e()]),(!_vm.checkPendingApproval)?_c('button',{staticClass:"btn btn-studio-theme-space",attrs:{"disabled":_vm.getPublishLoaders.processSocialShare ||
                        _vm.sharingPendingProcessStatus ||
                        _vm.getPublishLoaders.processSavePublication},on:{"click":function($event){$event.preventDefault();return _vm.processSocialShare()}}},[_c('span',[_vm._v("Update")]),(_vm.getPublishLoaders.processSocialShare)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"slide-bottom"}},[_c('CarouselPostSection',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCarouselOptions.is_carousel_post),expression:"getCarouselOptions.is_carousel_post"}],attrs:{"isCarouselPostEnabled":_vm.isCarouselPostEnabled}})],1),_c('transition',{attrs:{"name":"slide-bottom"}},[_c('FirstCommentSection',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFirstCommentSection),expression:"showFirstCommentSection"}],attrs:{"isFirstCommentEnabled":_vm.isFirstCommentEnabled},on:{"firstCommentChange":_vm.handlefirstCommentChange},model:{value:(_vm.first_comment),callback:function ($$v) {_vm.first_comment=$$v},expression:"first_comment"}})],1),_c('publishing-time-options',{attrs:{"first_comment":_vm.first_comment,"isFirstCommentEnabled":_vm.isFirstCommentEnabled,"isCarouselPostEnabled":_vm.isCarouselPostEnabled,"isFacebookSelected":_vm.getAccountSelection.facebook.length > 0,"isInstagramSelected":_vm.instagramSelection.no_access_accounts.length > 0 ||
                      _vm.instagramSelection.all_access_accounts.length > 0,"no_access_accounts":_vm.instagramSelection.no_access_accounts,"all_access_accounts":_vm.instagramSelection.all_access_accounts,"zapierErrors":_vm.zapierError,"type":"Social"},on:{"firstCommentChange":_vm.handlefirstCommentChange}}),(
                      _vm.getPublishSelection.queueStatus &&
                      _vm.getUserRole !== 'Approver'
                    )?_c('div',{staticClass:"post_footer text-right clear"},[_c('div',{staticClass:"warning_box justify-content-center"},[_vm._v("This post is queued and scheduled on "),_c('strong',[_vm._v(" "+_vm._s(_vm.getWorkspaceTimeZoneTime( _vm.getPublishSelection.planExecutionTime['date'], 'MMM DD, hh:mm a' ))+" ")]),_c('i',{staticClass:"icon-edit-cs",on:{"click":function($event){$event.preventDefault();return _vm.editQueuePost()}}})]),(!_vm.checkPendingApproval)?_c('button',{staticClass:"btn btn-studio-theme-space mt-3",attrs:{"disabled":_vm.getPublishLoaders.processSocialShare ||
                        _vm.sharingPendingProcessStatus ||
                        _vm.getPublishLoaders.processSavePublication},on:{"click":function($event){$event.preventDefault();return _vm.processSocialShare()}}},[_c('span',[_vm._v("Update")]),(_vm.getPublishLoaders.processSocialShare)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):_vm._e()],1):_vm._e()]):_vm._e()],1):_c('div',{staticClass:"post_share_option"},[_c('div',{staticClass:"post_footer"},[_c('div',{staticClass:"d-inline-block success_box warning_box_left"},[_c('p',[_vm._v("Published on "+_vm._s(_vm.getWorkspaceTimeZoneTime( _vm.getPublishSelection.planExecutionTime['date'], 'MMM DD, hh:mm a' )))])])])])]:_vm._e()],2)]),_c('model-sidebar',{staticClass:"social_post_side_filter_model social_share_model_sidebar",attrs:{"type":"Social","isCSV":_vm.isCSV}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }