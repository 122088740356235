var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-post-card"},[_c('div',{staticClass:"analytics-post-card__header"},[_c('div',{staticClass:"analytics-post-card__header-left"},[_c('div',{staticClass:"analytics-post-card__header-img"},[_c('img',{attrs:{"src":_vm.getImage(_vm.platform_type, _vm.post),"alt":""}})]),_c('div',{staticClass:"analytics-post-card__header-detail"},[_c('h3',[_vm._v(_vm._s(_vm.getPostName(_vm.platform_type, _vm.post)))]),_c('p',{staticClass:"mb-0"},[_c('small',[_c('b',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.getPostUsername(_vm.platform_type, _vm.post)),expression:"getPostUsername(platform_type, post)",modifiers:{"top-center":true}}]},[_vm._v(_vm._s(_vm.trimString(_vm.getPostUsername(_vm.platform_type, _vm.post), 20))+" ")]),_vm._v(" • "+_vm._s(_vm.getPostDate(_vm.platform_type, _vm.post)))])])])]),_c('div',{staticClass:"analytics-post-card__header-right"},[_c('a',{staticClass:"btn",attrs:{"href":_vm.getPostLink(_vm.post),"target":"_blank"}},[_c('i',{staticClass:"far fa-external-link-square-alt"})])])]),_c('div',{staticClass:"analytics-post-card__content"},[(_vm.isPostTextAvailable(_vm.platform_type, _vm.post))?_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.trimString(
          _vm.isHashtag(
            _vm.isLink(_vm.nextLineReplacement(_vm.getPostText(_vm.platform_type, _vm.post)))
          ),
          80
        )
      )}}):_vm._e(),(_vm.isPostImageAvailable(_vm.platform_type, _vm.post))?_c('img',{attrs:{"src":_vm.getPostImage(_vm.post),"alt":""},on:{"error":function($event){$event.target.src =
          'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'}}}):_c('img',{attrs:{"draggable":"false","src":require("../../assets/imgs/no_data_images/not-found.png"),"alt":"No Picture Found"}})]),_c('ul',{staticClass:"analytics-post-card__content-ele"},[_c('li',[_c('span',[_vm._v("Total Engagements")]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.roudValues(_vm.post.post_engagement)))])])]),_c('li',[_c('span',[_vm._v(_vm._s(_vm.getPostLikesText(_vm.platform_type)))]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.roudValues(_vm.post.like)))])])]),(_vm.isPostCommentsAvailable(_vm.platform_type))?_c('li',[_c('span',[_vm._v("Comments")]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.roudValues(_vm.getPostComments(_vm.platform_type, _vm.post))))])])]):_vm._e(),(_vm.isPostSharesAvailable(_vm.platform_type))?_c('li',[_c('span',[_vm._v(_vm._s(_vm.getPostSharesText(_vm.platform_type)))]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.roudValues(_vm.getPostShares(_vm.platform_type, _vm.post))))])])]):_vm._e(),(_vm.isPostImpressionsAvailable(_vm.platform_type))?_c('li',[_c('span',[_vm._v("Total Post Reactions")]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.roudValues(_vm.post.total_post_reactions)))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }