<template>
  <div class="center-modal-content center-modal-content-add-on">
    <div class="relative mt-5 z-50">
      <div class="top-content">
        <h2
          >Introduce more power to your ContentStudio account with Analytics,
          Influencers and Social Inbox add-ons.
        </h2>
        <!-- <div class="line"></div>
               <h3 class="price_tag mb-3 text-white">Introductory price expires in</h3>
                <div class="countdown countdown_head_bottom">
                    <span class="countdown-span"><countdown deadline="November 15, 2019 11:59 GMT"></countdown></span>
                </div>-->
        <div class="line mb-3 mt-4"></div>
        <p
          >Being on a mission to make ContentStudio the best tool for your
          content marketing needs, we are continuously evolving our features and
          products according to your desires and feedback.</p
        >
        <div
          class="d-flex align-items-center mt-3 mb-2 demo_videos justify-content-center"
        >
          <div class="video-box mr-3">
            <h4 class="mb-3">Analyze Demo</h4>
            <iframe
              src="//fast.wistia.net/embed/iframe/7zxtwfzccf"
              allowtransparency="true"
              frameborder="0"
              scrolling="no"
              class="wistia_embed"
              name="wistia_embed"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
              width="420"
              height="240"
            ></iframe>
          </div>
          <div class="video-box">
            <h4 class="mb-3">Influencers Demo</h4>
            <iframe
              src="//fast.wistia.net/embed/iframe/waxdh8kdet"
              allowtransparency="true"
              frameborder="0"
              scrolling="no"
              class="wistia_embed"
              name="wistia_embed"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
              width="420"
              height="235"
            ></iframe>
          </div>
        </div>
        <!--      <p class="mt-3">It starts with $10/month for monthly customers but for lifetime members,
                    we are giving it away at only <strong>$99</strong> per add-on.</p>
-->
        <div class="btn_block mt-3 pb-3">
          <button
            class="btn_purchase btn"
            @click="smoothScroll('pricing_section_boxes')"
            ><span>Buy Now</span>
          </button>
        </div>
      </div>
      <AnalyzeDescription></AnalyzeDescription>
      <InfluencersDescription></InfluencersDescription>
      <SocialInboxDescription></SocialInboxDescription>

      <div class="influencer_page" style="background: #ffffff">
        <div
          style="
            background: white;
            margin-bottom: 0px;
            padding-bottom: 40px;
            padding-top: 40px;
          "
          class="sec_heading"
          ><h2 class="pt-4">Special lifetime offer</h2>
          <p
            >These new additions are full-fledged products where we will keep on
            adding new features according to your feedback. Other companies are
            charging monthly payments starting from $50/month up to $999/month
            each.
          </p>
          <p class="mt-4"
            >We could easily charge in the same range but we’ve decided to give
            our lifetime users a chance to purchase these add-ons on a small
            early-bird <strong>one-time cost</strong>.
          </p>
          <p class="pt-4" style="font-size: 22px"
            ><strong>Don’t miss the early-bird lifetime offer</strong></p
          >
        </div>
      </div>
      <div
        id="pricing_section_boxes"
        style="background: #ffffff"
        class="pt-5 pb-5 d-flex align-items-center"
      >
        <div class="price-box">
          <div class="box-inner">
            <h3>Analytics</h3>

            <p class="cut_price">
              <span class="price">&#36;299</span>
              <span class="duration">/lifetime</span>
            </p>
            <h2>
              <span dclass="sign">&#36;</span>
              <span class="price">199</span>
              <span class="text">/lifetime</span>
            </h2>
            <p><i class="cs-checked"></i> <span>Full Access</span></p>
            <button
              class="btn"
              @click="addonLifetimePurchase('analytics', 199)"
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
        <div class="price-box">
          <div class="box-inner">
            <h3>Influencers</h3>
            <p class="cut_price">
              <span class="price">&#36;299</span>
              <span class="duration">/lifetime</span>
            </p>
            <h2>
              <span class="sign">&#36;</span>
              <span class="price">199</span>
              <span class="text">/lifetime</span>
            </h2>
            <p><i class="cs-checked"></i> <span>Full Access</span></p>
            <button
              class="btn"
              @click="addonLifetimePurchase('influencers', 199)"
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
        <!--<div class="price-box active-box">
                    <div class="box-inner">
                        <h4 class="best-value ">More Value</h4>
                        <h3 class="mt-0">
                            <p><strong>Analytics</strong></p>
                            <p><strong>Influencers</strong></p>
&lt;!&ndash;                            <p><strong>Social Inbox</strong></p>&ndash;&gt;
                        </h3>
                        <p class="cut_price">
                            <span class="price">&#36;349</span>
                            <span class="duration">/lifetime</span>
                        </p>
                        <h2>
                            <span class="sign">&#36;</span>
                            <span class="price">249</span>
                            <span class="text">/lifetime</span>
                        </h2>
                        <p><i class="cs-checked"></i> <span>Full Access</span></p>
                        <button class="btn" @click="addonLifetimePurchase('analytics_and_influencers_and_social_inbox', 249)">
                            <span>Buy Now</span>
                        </button>
                    </div>
                </div>-->
        <div class="price-box">
          <div class="box-inner">
            <h3>Social Inbox</h3>
            <p class="cut_price">
              <span class="price">&#36;299</span>
              <span class="duration">/lifetime</span>
            </p>
            <h2>
              <span class="sign">&#36;</span>
              <span class="price">199</span>
              <span class="text">/lifetime</span>
            </h2>
            <p><i class="cs-checked"></i> <span>Full Access</span></p>
            <button
              class="btn"
              @click="addonLifetimePurchase('social_inbox', 199)"
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div style="background: #ffffff" class="bottom_boxes clear">
        <div class="sec_heading mt-0 pt-5 mb-3">
          <h2 class="mb-0">Combo Pricing</h2>
        </div>
        <div class="clearfix"></div>
        <div class="price-box active-box">
          <div class="box-inner">
            <h4 class="best-value">More Value</h4>
            <h3 class="mt-0">
              <p><strong>Analytics</strong></p>
              <p><strong>Influencers</strong></p>
              <!--                            <p><strong>Social Inbox</strong></p>-->
            </h3>
            <p class="cut_price">
              <span class="price">&#36;399</span>
              <span class="duration">/lifetime</span>
            </p>
            <h2>
              <span class="sign">&#36;</span>
              <span class="price">299</span>
              <span class="text">/lifetime</span>
            </h2>
            <p><i class="cs-checked"></i> <span>Full Access</span></p>
            <button
              class="btn"
              @click="addonLifetimePurchase('analytics_and_influencers', 299)"
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
        <div class="price-box active-box">
          <div class="box-inner">
            <h4 class="best-value">Best Value</h4>
            <h3 class="mt-0">
              <p><strong>Analytics</strong></p>
              <p><strong>Influencers</strong></p>
              <p><strong>Social Inbox</strong></p>
            </h3>
            <p class="cut_price">
              <span class="price">&#36;599</span>
              <span class="duration">/lifetime</span>
            </p>
            <h2>
              <span class="sign">&#36;</span>
              <span class="price">399</span>
              <span class="text">/lifetime</span>
            </h2>
            <p><i class="cs-checked"></i> <span>Full Access</span></p>
            <button
              class="btn"
              @click="
                addonLifetimePurchase(
                  'analytics_and_influencers_and_social_inbox',
                  399,
                )
              "
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { overridePriceURL } from '@src/modules/setting/config/api-utils'
import Countdown from 'vuejs-countdown'
import http from '../../lib/http-common'

const AnalyzeDescription = () =>
  import('./product-description/AnalyzeDescription')
const SocialInboxDescription = () =>
  import('./product-description/SocialInboxDescription')
const InfluencersDescription = () =>
  import('./product-description/InfluencersDescription')

export default {
  components: {
    Countdown,
    AnalyzeDescription,
    SocialInboxDescription,
    InfluencersDescription,
  },
  methods: {
    smoothScroll(id) {
      const target = document.getElementById(id)
      window.smoothScroll = this.moveToDiv(target)
    },

    moveToDiv(target) {
      var scrollContainer = target
      do {
        // find scroll container
        scrollContainer = scrollContainer.parentNode
        if (!scrollContainer) return
        scrollContainer.scrollTop += 1
      } while (scrollContainer.scrollTop == 0)

      var targetY = 0
      do {
        // find the top of target relatively to the container
        if (target == scrollContainer) break
        targetY += target.offsetTop
      } while ((target = target.offsetParent))

      scroll = function (c, a, b, i) {
        i++
        if (i > 30) return
        c.scrollTop = a + ((b - a) / 30) * i
        setTimeout(function () {
          scroll(c, a, b, i)
        }, 20)
      }
      // start scrolling
      scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
    },
    addonLifetimePurchase(addonType, addonPrice = '99') {
      let title
      switch (addonType) {
        case 'analytics':
          title = 'Analytics Add-on'
          break
        case 'influencers':
          title = 'Influencers Add-on'
          break
        case 'social_inbox':
          title = 'Social Inbox Add-on'
          break
        case 'analytics_and_influencers':
          title = 'Analytics & Influencers Add-on'
          break
        case 'analytics_and_influencers_and_social_inbox':
          title = 'Analytics & Influencers Add-on'
          break
      }
      console.log('addonType', addonType, typeof addonType)
      const productId = 569632
      http
        .post(overridePriceURL, {
          paddle_id: productId,
          price: addonPrice,
          email: this.getProfile.email,
          passthrough: { type: addonType },
          title: title
        })
        .then((resp) => {
          const respPayload = resp.data
          if (respPayload.status) {
            const paddleOptions = {
              override: resp.data.url,
              successCallback: function (data) {
                alert('Thanks for your purchase.')
                window.location.reload()
              },
            }
            Paddle.Checkout.open(paddleOptions)
          } else {
            this.$store.dispatch('toastNotification', {
              message:
                'Unable to purchase addons, please contact support.'
            })
          }
        })
        .catch()
    },
  },
}
</script>

<style lang="less">
@green: #1ecb7b;
.bottom_boxes {
  text-align: center;
  .price-box {
    float: none;
    display: inline-block;
  }
}

.active-box {
  .box-inner {
    border: 1px solid #53d87e;
    box-shadow: 0px 7px 0px 0px @green;
    .btn {
      background: @green;
    }
    h3 {
      &:after {
        background: @green;
      }
    }
    .best-value {
      color: @green;
    }
  }
}
</style>
