<script>
import MediaRemoveConfirmBox from '@src/modules/common/components/dialogs/MediaRemoveConfirmBox'
import { mapGetters } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import moment from 'moment'
import SocialApprovalModal from '@src/modules/common/components/dialogs/approval/SocialApprovalModal'
import * as _ from 'underscore'
import MediaStorageLimitsExceededModal from '@src/modules/common/components/subscription-limits-exceeded/MediaStorageLimitsExceededModal'
import IncreaseLimitsDialog from '@src/modules/setting/components/billing/dialogs/IncreaseLimitsDialog'
import CardInfoDialog from '@src/modules/setting/components/billing/dialogs/Card-info-dialog'
import SubscriptionLimitsExceededModal from '@src/modules/common/components/subscription-limits-exceeded/SubscriptionLimitsExceededModal'
import createWorkspaceModal from '@src/modules/account/components/CreateWorkspaceModel'

import SocialModal from '@src/modules/composer_v2/views/SocialModal'
import { EventBus } from '@common/lib/event-bus'
import { planner } from './modules/planner/store/mutation-types'

// loading common components
import SocialShare from './modules/publish/components/dialogs/SocialShare'
import BlogShare from './modules/publish/components/dialogs/BlogPost'
import TopHeaderBar from './components/layout/TopHeaderBar.vue'

import GettingStarted from './modules/common/components/widgets/GettingStarted'

import UploadMediaModal from './modules/publish/components/media-library/components/UploadMediaModal'

import WorkspaceLimitsDialog from './modules/setting/components/billing/dialogs/WorkspaceLimitsDialog'
import { baseUrl } from './config/api-utils'

const UpdateFeaturePlan = () =>
  import('@common/components/dialogs/UpdateFeaturePlan')
const CommentImagePreview = () =>
  import('./modules/planner/components/view/feed-view/ImagePreview')
const ActivateVideoCampaign = () =>
  import('./modules/automation/components/video/dialogs/ActivateCampaign')
const RemoveCsvPlansConfirmation = () =>
  import(
    './modules/automation/components/csv/dialogs/RemoveCsvPlansConfirmation'
  )
const RemoveCsvPlanConfirmation = () =>
  import(
    './modules/automation/components/csv/dialogs/RemoveCsvPlanConfirmation'
  )
const InsertFileModal = () =>
  import('./modules/publish/components/widget/file/InsertFileModal')
const PasteFromWord = () =>
  import('./modules/publish/components/widget/file/PasteFromWord')
// const ContentRewritingSectionModal = () => import('./../../publish/components/posting/blog/ContentRewritingSectionModal')
const DisplayFile = () =>
  import('./modules/publish/components/dialogs/DisplayFile')

const EnhanceImagePintura = () =>
  import('./modules/publish/components/dialogs/EnhanceImagePintura')
const HelpDropdown = () => import('@common/components/dropdowns/HelpDropdown')
const offlineJs = () =>
  import('@src/modules/common/components/offline-status/OfflineJs')
const HeadNotificationSlider = () =>
  import('@common/components/header-notifications/HeadNotificationSlider')
const HeaderBillingNotifications = () =>
  import('@common/components/header-notifications/HeaderBillingNotifications')

const StickyBanner = () => import('./components/common/StickyBanner')
const NoticeBanner = () => import('./components/common/NoticeBanner')
const ChangeTrialPlanDialog = () =>
  import(
    '@src/modules/setting/components/billing/dialogs/ChangeTrialPlanDialog'
  )
export default {
  components: {
    createWorkspaceModal,
    EnhanceImagePintura,
    MediaRemoveConfirmBox,
    SubscriptionLimitsExceededModal,
    UploadMediaModal,
    UpdateFeaturePlan,
    TopHeaderBar,
    CommentImagePreview,
    ActivateVideoCampaign,
    RemoveCsvPlanConfirmation,
    RemoveCsvPlansConfirmation,
    InsertFileModal,
    PasteFromWord,
    // ContentRewritingSectionModal,
    SocialShare,
    SocialApprovalModal,
    BlogShare,
    DisplayFile,
    HelpDropdown,
    offlineJs,
    HeadNotificationSlider,
    HeaderBillingNotifications,
    StickyBanner,
    NoticeBanner,
    MediaStorageLimitsExceededModal,
    IncreaseLimitsDialog,
    CardInfoDialog,
    WorkspaceLimitsDialog,
    GettingStarted,
    ChangeTrialPlanDialog,
    SocialModal,
  },
  data() {
    return {
      is_loading: true,
    }
  },
  /* onIdle() {
      this.logoutBroadcastUser(this.getProfile._id)
    },
    onActive() {
      this.loadUserBroadCasters()
    }, */
  computed: {
    ...mapGetters([
      'getActiveWorkspaceTriggers',
      'getJWTToken',
      'getCurrentNotification',
      'getPlans',
      'getActiveWorkspace',
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'getUpgradeOfferForStarterPlan',
      'getInstagramAccounts',
      'getBillingDetails',
      'getPaymentFailedDetails',
      'getWorkspaces',
    ]),
    /**
     * checking application is loaded or not
     **/
    showLoader() {
      return this.is_loading
    },

    /**
     * to show onboarding widget
     **/
    showOnboardingWidget() {
      return (
        !this.getActiveWorkspace.onboarding &&
        (this.getUserRole === 'Super Admin' || this.getUserRole === 'Admin') &&
        this.$route.name !== 'workspaces' &&
        this.$route.name !== 'composerSocial' &&
        this.$route.params.workspace
      )
    },

    /**
     * checking top_notification is required or not
     **/
    addTopNotificationClass() {
      return (
        this.isStarterPlan &&
        this.$store.getters.getInAppNotifications.upgrade_starter_limits &&
        this.getUpgradeOfferForStarterPlan &&
        this.$route &&
        this.$route.name !== 'upgrade_your_limits' &&
        this.canViewUpgradeOffer()
      )
    },

    /**
     * Show the notification to the user if their billing is failed.
     **/
    isBillingActionRequired() {
      const profile = this.getWorkspaceSuperAdminDetails()
      return !!(profile && profile.state === 'past_due')
    },

    showTopHeaderBar() {
      return (
        this.showTopNavigationBar() &&
        this.$route.name !== 'onboardingWorkspace' &&
        Object.hasOwn(this.$route.meta, 'guest') === false &&
        (!this.getProfile.state ||
          (this.getProfile.state !== 'canceled' &&
            this.getProfile.state !== 'cancelled'))
      )
    },

    addPostingComponents() {
      return (
        this.$route.name === 'list_plans' ||
        this.$route.name === 'calender_plans' ||
        this.$route.name === 'feed_view'
      )
    },

    isVideoAutomationSavingRoute() {
      return (
        this.$route.name === 'saveVideoBlogAutomation' ||
        this.$route.name === 'saveVideoSocialAutomation'
      )
    },

    isCSVAutomationSavingRoute() {
      return this.$route.name === 'saveBulkCsvAutomation'
    },

    /**
     * Show the notification to the user if their billing is failed.
     **/
    isPurchasedLifetimeAddons() {
      return (
        this.getActiveWorkspaceAddonsLifetime.analytics &&
        this.getActiveWorkspaceAddonsLifetime.influencers
      )
    },
  },

  watch: {
    '$route.params.workspace'(slug) {
      console.log('I change', slug)
      if (slug) {
        EventBus.$emit('workspace-changed')
      }
    },
    'getActiveWorkspace._id'(id) {
      console.debug('Home.vue::Watch::getActiveWorkspace._id', id)
      if (id) {
        if (
          this.$route.params.workspace &&
          (this.getActiveWorkspace.super_admin_state === 'trial_finished' ||
            this.getActiveWorkspace.super_admin_state === 'deleted')
        ) {
          console.debug('Workspace locked', id)
          this.$router.replace({ name: 'workspaces' })
        }
        // this.$store.dispatch('fetchLabels')
        // this.$store.dispatch('fetchWorkspaceHashtags')
        // this.$store.dispatch('fetchContentCategories')
        // show the update lifetime plan if the user is on the lifetime plan
        if (
          this.isOnLifetimePlan() &&
          !this.getActiveWorkspaceTriggers.influencers_and_analytics &&
          this.getUserRole !== 'Approver'
        ) {
          setTimeout(() => {
            this.$bvModal.show('update-feature-plan')
          }, 3000)
        }
      }
    },
  },
  created() {
    console.debug('Created::Home.vue', this.getWorkspaces)
    this.loadApplication()
    this.loadJqueryOnCreated()
  },
  mounted() {
    console.debug('Mounted::Home.vue', this.getWorkspaces)
    this.loadJqueryOnMounted()
    // this.checkWorkspaceExistance()
    EventBus.$on('workspace-changed', async () => {
      console.log('workspace-changed')
      // await this.fetchWorkspaces(true, true)
      if (this.getActiveWorkspace.super_admin_state === 'deleted') {
        console.debug('Workspace Super Admin deleted')
        window.location.href = baseUrl + 'workspaces'
      }

      if (
        this.getActiveWorkspace.onboarding_steps &&
        !this.getActiveWorkspace.onboarding_steps.watch_video.status
      ) {
        this.$bvModal.show('get-started-modal')
      }
    })
  },

  beforeDestroy() {
    EventBus.$off('workspace-changed')
  },

  // async beforeRouteUpdate (to, from, next) {
  //   console.debug('home beforeRouteUpdate', jitsu.track)
  //   // react to route changes...
  //   // don't forget to call next()
  //   if (this.getProfile && this.getProfile._id) {
  //     console.debug('TEST Jitsu')
  //     await jitsu.track('pageview')
  //   }
  //   next()
  // },

  /* eslint-disable no-undef, camelcase */
  methods: {
    // /**
    //  * show onboarding video
    //  */
    // checkOnboardingWatchVideo () {
    //   console.log('VIDEO', this.getProfile.onBoarding)
    //   if (this.getProfile.onBoarding !== true) {
    //     this.$bvModal.show('get-started-modal')
    //   }
    // },
    isActiveUserSuperAdmin() {
      const teamMembers =
        this.$store.getters.getWorkspaces.activeWorkspace.members
      if (teamMembers) {
        for (var i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (
            member.user &&
            this.$store.getters.getProfile.email === member.user.email
          ) {
            return member.role === 'super_admin'
          }
        }
      }

      return false
    },
    /**
     * @returns {boolean}
     */
    async loadApplication() {
      console.debug('Method::loadApplication')

      // logging out user if the route in logout
      if (this.$route.name === 'logout') {
        await this.isLogout()
        return
      }

      // checking user is logged in or not
      if (this.$store.getters.isUserAuthenticated) {
        // fetching the common data which is required to load application(e.g profile,workspace, accounts etc)
        await this.loadRequiredData(false)
        this.is_loading = false
        return true
      }

      // clearing token states and redirecting to logout if token is invalid
      await this.isLogout()
      this.is_loading = false
    },

    /**
     * clearing token states and redirecting to login page
     * @returns {Promise<any>}
     */
    isLogout() {
      return new Promise((resolve, reject) => {
        this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
        this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)

        // checking redirected_url
        const ignore_redirected = ['/', '/login', '/logout']
        const path = this.$route.fullPath
        ignore_redirected.includes(path) === false
          ? this.$router.push({
              name: 'login',
              query: { redirected_url: this.$route.fullPath },
            })
          : this.$router.push({ name: 'login' })
        this.is_loading = true
        resolve()
      })
    },

    loadJqueryOnCreated() {
      console.debug('Method::loadJqueryOnCreated')
      const stateObject = this
      // ................JQUERY CODE FOR ONLOAD..................
      $(document).ready(() => {
        setTimeout(() => {
          console.debug('change log')
          const HW_config = {
            selector: '.change_logs', // CSS selector where to inject the badge
            account: 'LJ1b0y', // your account ID
          }
          if (window.Headway) Headway.init(HW_config)
        }, 10000)

        // body overflow issue for multiple model...
        $('.modal').on('hidden.bs.modal', (e) => {
          if ($('.modal:visible').length) {
            $('body').addClass('modal-open')
          }
        })

        // TODO::Check Not in Use
        $(document).on('click', '.calendar_actions ', (e) => {
          let item = false
          const plans = stateObject.getPlans.items
          const id = $(e.currentTarget)
            .parents('.planer_event_box_week')
            .attr('data-id')

          plans.forEach((plan) => {
            if (plan._id === id) {
              item = plan
              return false
            }
          })

          console.debug('Method::calendar_actions click', id, item)
          if (item) {
            if ($(e.currentTarget).hasClass('check')) {
              $(e.currentTarget).siblings('.custom_loader').removeClass('hide')
              stateObject.$store.dispatch('changePlanStatus', {
                id: item._id,
                status: 'scheduled',
              })
              return false
            }

            if ($(e.currentTarget).hasClass('cross')) {
              $(e.currentTarget).siblings('.custom_loader').removeClass('hide')
              stateObject.$store.dispatch('changePlanStatus', {
                id: item._id,
                status: 'rejected',
              })
              return false
            }

            if ($(e.currentTarget).hasClass('refresh')) {
              stateObject.$store.commit(planner.SET_CONFIRMATION_PLAN, item)
              stateObject.$bvModal.show('replacePlan')
              return false
            }

            if ($(e.currentTarget).hasClass('edit')) {
              item.stateObject = stateObject
              stateObject.$store.dispatch('editPlan', item)
              return false
            }

            if ($(e.currentTarget).hasClass('clone')) {
              item.stateObject = stateObject
              stateObject.$store.dispatch('clonePlan', item)
              return false
            }

            if ($(e.currentTarget).hasClass('trash')) {
              stateObject.$store.commit(planner.SET_CONFIRMATION_PLAN, item._id)
              stateObject.$bvModal.show('removePlan')
              return false
            }
          }
        })

        // make box editable

        $(document).on('click', '.edit_box', (e) => {
          const loaderID = stateObject.generateRandomId() + '_loader'
          var currentObject = e.currentTarget
          var editor_dragable_box = stateObject.getClosestClass(
            currentObject,
            'editor_dragable_box'
          )

          if (editor_dragable_box.classList.contains('editor_flickr_block')) {
            const url = editor_dragable_box
              .querySelector('.flickr_inner_embed')
              .getAttribute('data-link')

            editor_dragable_box.querySelector(
              '.flickr_inner_embed'
            ).innerHTML = `
                               <div class="inner_embed"><br>
                                   <div id="${loaderID}" class="custom_loader">
                                      <div class="loader_inner"></div>
                                   </div>
                                   <a data-flickr-embed="true">
                                     <img src="${url}">
                                   </a>
                                   <br>

                                </div>
                             `

            // for removing loader
            stateObject.processLoaderPreview(loaderID)
          } else if (
            editor_dragable_box.classList.contains('editor_twitter_block')
          ) {
            const tweetDescription = $(editor_dragable_box)
              .find('.twitter_inner_embed .tweet_description')
              .html()
            const tweetScreenname = $(editor_dragable_box)
              .find('.twitter_inner_embed .tweet_screenname')
              .html()
            const tweetTitle = $(editor_dragable_box)
              .find('.twitter_inner_embed .tweet_title')
              .html()
            const tweetLink = $(editor_dragable_box)
              .find('.twitter_inner_embed .tweet_link')
              .html()
            $(editor_dragable_box)
              .find('.twitter_inner_embed')
              .attr('data-description', tweetDescription)
            $(editor_dragable_box)
              .find('.twitter_inner_embed')
              .attr('data-screenname', tweetScreenname)
            $(editor_dragable_box)
              .find('.twitter_inner_embed')
              .attr('data-title', tweetTitle)
            $(editor_dragable_box)
              .find('.twitter_inner_embed')
              .attr('data-link', tweetLink)

            editor_dragable_box.querySelector(
              '.twitter_inner_embed'
            ).innerHTML = `
                                <div id="${loaderID}"  class="custom_loader">
                                      <div class="loader_inner"></div>
                                   </div>
                                   <div class="inner_embed" id=""><blockquote class=" twitter-tweet"  data-width="350">
                        <p lang="en" dir="ltr">${tweetDescription}</p>
                        <p>— ${tweetTitle} (${tweetScreenname})
                        <a href="${tweetLink}"><span class="hide">November 24, 2016</span></a></p>
                      </blockquote>

                                </div>

                             `

            // for removing loader
            stateObject.processLoaderPreview(loaderID)
          }

          stateObject
            .getClosestClass(currentObject, 'top_option')
            .classList.add('top_option_remove')
          $(editor_dragable_box)
            .find('.editor_dragable_box_inner')
            .children()
            .unwrap()
          $(editor_dragable_box).children().unwrap()
          $('.top_option_remove').remove()
          stateObject.loadFlickrPreview(500)
          if (editor_dragable_box.classList.contains('editor_twitter_block')) {
            stateObject.loadTwitterPreview(0)
          }
        })

        // remove box
        // $(document).on('click', '.remove_box', () => {
        //   $(this).parents('.editor_dragable_box').remove()
        // })
        $(document).on('click', '.remove_box', function () {
          $(this).parents('.editor_dragable_box').remove()
        })

        window.notificationActionPerform = () => {
          stateObject.onClickNotification(stateObject.getCurrentNotification)
        }

        // align box left
        $(document).on('click', '.move_left', (e) => {
          //                        var self_inner = $(this).closest(".top_option");
          var self_inner = stateObject.getClosestClass(
            e.currentTarget,
            'top_option'
          )
          $(self_inner)
            .siblings('.align_box,.text_alignment')
            .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'left',
          })
        })

        // align box center
        $(document).on('click', '.move_center', (e) => {
          var self_inner = stateObject.getClosestClass(
            e.currentTarget,
            'top_option'
          )
          $(self_inner)
            .siblings('.align_box,.text_alignment')
            .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'center',
          })
        })

        // align box right
        $(document).on('click', '.move_right', (e) => {
          const self_inner = stateObject.getClosestClass(
            e.currentTarget,
            'top_option'
          )
          $(self_inner)
            .siblings('.align_box,.text_alignment')
            .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'right',
          })
        })

        // Bootstrap slider
        $(document).on(
          'click',
          '.prevent_close_dropdown .dropdown-menu',
          (e) => {
            e.stopPropagation()
          }
        )

        $(document).on('click', '.close_this', (e) => {
          e.stopPropagation()
        })
      })
    },

    loadJqueryOnMounted() {
      console.debug('Method::loadJqueryOnMounted')
      // set the gtm
      $('.add_class_on_hide').on('hide.bs.modal', () => {
        setTimeout(() => {
          $('body').addClass('modal-open')
        }, 500)
      })

      $(document).on('show.bs.modal', '.modal', (event) => {
        const zIndex = 1040 + 10 * $('.modal:visible').length
        $(this).css('z-index', zIndex)
        setTimeout(() => {
          $('.modal-backdrop')
            .not('.modal-stack')
            .css('z-index', zIndex - 1)
            .addClass('modal-stack')
        }, 0)

        setTimeout(() => {
          $('body').addClass('modal-open')
        }, 500)
      })
    },

    // Find closest up ward parent node
    getClosestClass(eventTarget, className) {
      // Traverse the DOM up with a while loop
      console.debug(eventTarget, '-----Method:getClosestClass-----', className)
      // while (eventTarget.className != className) {
      if (eventTarget && eventTarget.classList) {
        while (!eventTarget.classList.contains(className)) {
          // Increment the loop to the parent node
          eventTarget = eventTarget.parentNode
          if (!eventTarget) {
            return null
          }
        }
      }

      // At this point, the while loop has stopped and `el` represents the element that has
      // the class you specified in the second parameter of the function `clazz`

      // Then return the matched element
      return eventTarget
    },
    getInstagramAccountsWithPermissionsIssue() {
      var selectedAccounts = [] // will contain all the details for the IG accounts selected
      for (const acc in this.getInstagramAccounts.all_items) {
        if (Object.hasOwn(this.getInstagramAccounts.all_items, 'acc')) {
          selectedAccounts.push(this.getInstagramAccounts.all_items[acc])
        }
      }

      const allAccessAccounts = [] // will contain profiles with IG publishing permissions
      const noAccessAccounts = [] // will contain profiles which do not have IG publishing permissions

      // if selected accounts are found ...
      if (selectedAccounts.length > 0) {
        for (let x = 0; x < selectedAccounts.length; x++) {
          // loop over each account and segregate into seperate arrays accordingly
          const item = selectedAccounts[x]
          if (!this.hasInstaDirectAPIPermission(item)) {
            noAccessAccounts.push(item)
          } else {
            allAccessAccounts.push(item)
          }
        }
      }

      // update the instagramSelection variable from data to the new selected data
      return {
        no_access_accounts: noAccessAccounts,
        all_access_accounts: allAccessAccounts,
      }
    },
    hasInstaDirectAPIPermission(channel) {
      if (channel.permissions) {
        const publishAPIPermission = _.findWhere(channel.permissions, {
          permission: 'instagram_content_publish',
        })
        return publishAPIPermission
          ? publishAPIPermission.status === 'granted'
          : false
      }
      return false
    },
    cardHasExpired() {
      if (this.getBillingDetails.payment_information) {
        const expiryDate =
          this.getBillingDetails.payment_information.expiry_date
        if (expiryDate) {
          var now = moment()
          var f = moment(expiryDate, 'MM/YYYY')
          var diff = f.diff(now, 'days')

          if (diff > 0 && diff <= 30) {
            // has not expired yet .. still 30 days to go
            return false
          } else if (diff <= 0) {
            // card has expired or will expire today
            return true
          } else {
            return null
          }
        }
      }

      return null
    },
  },
}
</script>

<template>
  <div class="application-box">
    <MediaRemoveConfirmBox />

    <div
      class="app-wrapper sticky_bar"
      :class="{
        top_notification: addTopNotificationClass,
        sticky_bar:
          isTrialPlan() ||
          getInstagramAccountsWithPermissionsIssue().no_access_accounts.length >
            0 ||
          cardHasExpired(),
      }"
    >
      <div
        v-if="showLoader"
        class="loading-logo flex justify-center items-center"
      >
        <img
          style="width: 50px"
          src="@assets/img/logo/logo-animation.gif"
          alt="ContentStudio"
        />
      </div>
      <template v-if="!showLoader">
        <div class="fixed bottom-0 left-1/4 w-max z-[1049]">
          <!--          <NewVersionAvailableWidget-->
          <!--            v-if="$store.getters.getIsNewVersionAvailable"-->
          <!--          />-->
          <HeadNotificationSlider
            v-if="
              !(
                this.$route.name === 'analytics_pdf_report' ||
                this.$route.name === 'social'
              )
            "
          ></HeadNotificationSlider>

          <!--          <StarterPlanHeaderNotificationBar-->
          <!--            v-if="showStarterPlanHeaderBar"-->
          <!--          ></StarterPlanHeaderNotificationBar>-->

          <HeaderBillingNotifications
            :display-billing-notification="isBillingActionRequired"
          >
          </HeaderBillingNotifications>
        </div>

        <ChangeTrialPlanDialog />
        <WorkspaceLimitsDialog />
        <upgrade-plan-dialog />
        <IncreaseLimitsDialog />
        <CardInfoDialog />
        <UpdateFeaturePlan></UpdateFeaturePlan>
        <createWorkspaceModal />
        <SubscriptionLimitsExceededModal
          v-if="
            checkIfActiveWorkspaceHasLimitExceeded() &&
            $route.name !== 'manageLimits'
          "
          :is-super-admin="isActiveUserSuperAdmin()"
        ></SubscriptionLimitsExceededModal>

        <transition name="slidedown">
          <StickyBanner
            v-if="showTopHeaderBar"
            :profile="getProfile"
            :show="isTrialPlan() && hasPermission('can_see_subscription')"
          />
        </transition>

        <NoticeBanner
          v-if="cardHasExpired() !== null"
          :is_for="'credit_card_expire'"
          :has_expired="cardHasExpired()"
        />

        <NoticeBanner
          v-if="
            getInstagramAccountsWithPermissionsIssue().no_access_accounts
              .length > 0 &&
            isTrialPlan() === false &&
            showTopHeaderBar
          "
          :is_for="'instagram'"
          :no_access_accounts="
            getInstagramAccountsWithPermissionsIssue().no_access_accounts
          "
        />

        <TopHeaderBar v-if="showTopHeaderBar"></TopHeaderBar>
        <div
          class="main_inner_container"
          :style="{
            'padding-top':
              this.$route.name === 'email_verification' ? '0px!important' : '',
          }"
          :class="{
            workspace_main_page: isWorkspaceMainPageRoute(),
            is_guest_page:
              $route.meta.hasOwnProperty('guest') && $route.meta.guest,
          }"
        >
          <CommentImagePreview></CommentImagePreview>
          <ActivateVideoCampaign
            v-if="isVideoAutomationSavingRoute"
          ></ActivateVideoCampaign>
          <template v-if="isCSVAutomationSavingRoute">
            <RemoveCsvPlanConfirmation></RemoveCsvPlanConfirmation>
            <RemoveCsvPlansConfirmation></RemoveCsvPlansConfirmation>
            <SocialApprovalModal></SocialApprovalModal>
          </template>
          <template v-if="addPostingComponents">
            <UploadMediaModal type="BlogEditorFile"></UploadMediaModal>
            <InsertFileModal
              class="planner_insertFile_modal"
              type="BlogEditorFile"
            ></InsertFileModal>
            <PasteFromWord class="planner_pasteWord_modal"></PasteFromWord>
            <!--<content-rewriting-section-modal-->
            <!--class="planner_content_rewrite_modal"></content-rewriting-section-modal>-->
            <SocialShare class="planner_socialshare_modal"></SocialShare>
            <BlogShare class="planner_blogshare_modal"></BlogShare>
          </template>

          <MediaStorageLimitsExceededModal
            v-if="!(this.$route.name === 'analytics_pdf_report')"
            :is-super-admin="isActiveUserSuperAdmin()"
          ></MediaStorageLimitsExceededModal>

          <!--We are using this to show the preview of the video and image-->
          <DisplayFile></DisplayFile>
          <!-- Older Version of Doka -->
          <!--                    <enhance-image></enhance-image>-->
          <!-- New Version of Doka (Pintura Editor) -->
          <EnhanceImagePintura></EnhanceImagePintura>
          <div v-if="getWorkspaces.items.length > 0">
            <transition name="slide-bottom">
              <GettingStarted v-if="showOnboardingWidget"></GettingStarted>
            </transition>
          </div>
          <HelpDropdown></HelpDropdown>
          <offline-js></offline-js>
          <SocialModal />

          <router-view></router-view>
        </div>
      </template>
    </div>
  </div>
</template>
