<template>
  <div>
    <div
      class="assistant_box _imgur _photo"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
      v-for="(photo, key) in getImagesPosts"
      :key="key"
      v-if="photoImgurImageURL(photo)"
    >
      <div
        class="box_inner"
        @dragstart="dragstartHandler($event, photo)"
        :draggable="$route.name === 'composerBlog'"
      >
        <div
          class="image_block"
          @click="previewImage(photoImgurImageURL(photo))"
        >
          <div class="play_icon">
            <i class="cs-eye"></i>
          </div>
          <div
            class="img"
            v-lazy:background-image="backgroundImgurImageURL(photo)"
          ></div>
        </div>
        <div class="content_block">
          <p style="min-height: 1.125rem" class="desc mt-0">
            {{ limitTextLength(photo.title, 20) }}
          </p>
        </div>
        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div class="stat_item" v-tooltip.top-center="'View'">
              <div class="name">
                <p>
                  <i class="cs-eye"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(photo['views']) }}</h3>
              </div>
            </div>
            <div class="stat_item" v-tooltip.top-center="'Score'">
              <div class="name">
                <p>
                  <i class="cs-star"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(photo['score']) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_imgur_block editor_dragable_box editor_image_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option no_flickr">
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip ui-sortable-handle"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>
            <br />
            <p style="text-align: center">
              <img
                style="max-width: 100%"
                class=""
                :src="photoImgurImageURL(photo)"
                alt=""
              />
            </p>
            <br />
            <p class="text_alignment" style="text-align: center">{{
              photo.title
            }}</p>
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getImagesPosts'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    backgroundImgurImageURL (photo) {
      if (photo.url && photo.url.indexOf('http://i.imgur.com/') == 0) {
        const index = photo.url.lastIndexOf('/')
        if (index != -1) {
          const index2 = photo.url.lastIndexOf('.')
          if (index2 != -1) {
            const name = photo.url.substr(index + 1, 7)
            const extension = photo.url.substr(index2)
            return 'http://i.imgur.com/' + name + 't' + extension
          }
        }
      }
    },
    photoImgurImageURL (photo) {
      if (photo.url && photo.url.indexOf('http://i.imgur.com/') == 0) {
        return photo.url
      }
      return false
    },
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', photo)

      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'image',
          heading: '',
          media: this.photoImgurImageURL(photo),
          p: photo.title || '',
          link: this.photoImgurImageURL(photo)
        })
      )
    }
  }
}
</script>
