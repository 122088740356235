<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <div class="top_content_main">
        <h2>Automation Recipes</h2>
        <p
          >Using the automation recipes given below, you can create highly
          customized campaigns by setting up a variety of filters and rules
          according to your needs.</p
        >
      </div>

      <div class="automation_pinterest_warning">
        <i
            class="fas fa-exclamation-triangle">
        </i>
        <p>
          Pinterest removal from automation campaigns (except 'Bulk Uploader') to adhere to Pinterest's policies, starting 12th May, 2022.
          <a class="beacon ml-2"
             href="#" data-beacon-article-modal="627b500db2de5178f8882990">
            Read More.
          </a>
        </p>
      </div>

      <template v-if="isWorkspaceOnHold">
        <div class="input_field">
          <div class="warning_box warning-alert">
            <p v-if="hasPermission('can_change_hold_status')"
              ><i class="fa fa-warning"></i> Note: You cannot select accounts
              because you've paused publishing for this workspace,
              <router-link :to="{ name: 'workspaces' }" class="ml-2"
                >Click here to resume publishing
              </router-link>
            </p>
            <p v-if="!hasPermission('can_change_hold_status')"
              ><i class="fa fa-warning"></i> Note: You cannot select accounts
              because the admin of this workspace has paused publishing for this
              workspace.</p
            >
          </div>
        </div>
      </template>

      <div class="automation_boxes">
        <!--   &lt;!&ndash; repurpose for social recipe &ndash;&gt;
                   <div class="a_box">
                       <div class="box_inner">
                           <div class="tag-most-used float-right"><span><i class="far fa-star mr-2"></i>Most used</span></div>
                           <div class="a_img">
                               <img src="../../../app/src/assets/img/automation/rss_feed.svg" alt="">
                           </div>
                           <h3>Repurpose</h3>
                           <p>Repurpose content from your blog to social channel for a year.</p>
                           <div class="bottom_btn d-flex align-items-center"
                                :class="{'justify-content-center': !getAutomationCounts.rssAutomation}">

                               <a class="btn new_campaign" @click.prevent="createRssAutomationProcess()">
                                   <i class="icon_left add_icon cs-plus">
                                   </i>
                                   <span>New Campaign</span>
                               </a>

                               <router-link v-if="getAutomationCounts.rssAutomation" class="btn view_campaign ml-auto"
                                            :to="{name: 'rssAutomationListing', params: { workspace: getWorkspaces.activeWorkspace.slug}}">
                                   <span>View existing ({{getAutomationCounts.rssAutomation}})</span>
                               </router-link>
                           </div>
                       </div>
                   </div>-->

        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right"
              ><span><i class="far fa-star mr-2"></i>Featured</span>
            </div>
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/article_social.svg"
                alt=""
              />
            </div>
            <h3>Article Links to Social Media</h3>
            <p
              >Share article links on your social media profiles, pages and
              groups with smart rules and filters.</p
            >
            <div
              class="d-flex align-items-center"
              v-if="!isWorkspaceOnHold"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.articleSocialAutomation,
              }"
            >
              <a
                class="btn btn-studio-theme-transparent btn-size-small"
                @click="
                  createArticleAutomationProcess('saveArticleSocialAutomation')
                "
                data-cy="articles-links"
              >
                <i class="icon_left add_icon cs-plus"></i>
                <span>New Campaign</span>
              </a>

              <router-link
                v-if="getAutomationCounts.articleSocialAutomation"
                class="btn btn-studio-theme-transparent ml-auto btn-size-small"
                :to="{
                  name: 'articleSocialAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <span
                  >View existing ({{
                    getAutomationCounts.articleSocialAutomation
                  }})</span
                >
              </router-link>
            </div>
          </div>
        </div>
        <!-- rss feed to social -->
        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right"
              ><span><i class="far fa-star mr-2"></i>Most used</span>
            </div>
            <div class="a_img">
              <img src="../../../assets/img/automation/rss_feed.svg" alt="" />
            </div>
            <h3>RSS Feed to Social Media</h3>
            <p>Share RSS feeds content on your social media.</p>
            <div
              class="d-flex align-items-center"
              v-if="!isWorkspaceOnHold"
              :class="{
                'justify-content-center': !getAutomationCounts.rssAutomation,
              }"
            >
              <a
                class="btn btn-studio-theme-transparent btn-size-small"
                @click.prevent="createRssAutomationProcess()"
                data-cy="rss-links"
              >
                <i class="icon_left add_icon cs-plus"> </i>
                <span>New Campaign</span>
              </a>

              <router-link
                v-if="getAutomationCounts.rssAutomation"
                class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                :to="{
                  name: 'rssAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <span
                  >View existing ({{ getAutomationCounts.rssAutomation }})</span
                >
              </router-link>
            </div>
          </div>
        </div>

        <!-- evergreen recipe -->
        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right"
              ><span><i class="far fa-star mr-2"></i>Most used</span>
            </div>
            <div class="a_img">
              <img
                src="../../../assets/img/automation/article_social.svg"
                alt=""
              />
            </div>
            <h3>Evergreen</h3>
            <p
              >Recycle your evergreen posts at pre-defined time intervals over
              and over again for increased engagement.</p
            >
            <template>
              <div
                class="d-flex align-items-center"
                v-if="!isWorkspaceOnHold"
                :class="{
                  'justify-content-center':
                    !getAutomationCounts.evergreenAutomation,
                }"
              >
                <a
                  class="btn btn btn-studio-theme-transparent btn-size-small"
                  @click.prevent="newEvergreenAutomation()"
                >
                  <i
                    class="icon_left add_icon cs-plus"
                    data-cy="evergreen-links"
                  >
                  </i>
                  <span>New Campaign</span>
                </a>

                <router-link
                  v-if="getAutomationCounts.evergreenAutomation"
                  class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                  :to="{
                    name: 'evergreenAutomationListing',
                    params: { workspace: getWorkspaces.activeWorkspace.slug },
                  }"
                >
                  <span
                    >View existing ({{
                      getAutomationCounts.evergreenAutomation
                    }})</span
                  >
                </router-link>
              </div>
            </template>
          </div>
        </div>

        <!-- bulk uploader-->
        <!--<div class="a_box ">-->

        <!--<div class="box_inner">-->
        <!--<div class="a_img">-->
        <!--<img style="width: 45px;" src="../../../app/src/assets/img/automation/bulk_upload.svg" alt="">-->
        <!--</div>-->
        <!--<h3>Bulk Uploader</h3>-->
        <!--<p>Schedule multiple messages to your social media channels via CSV data import.</p>-->
        <!--<div class="bottom_btn d-flex align-items-center" v-if="!isWorkspaceOnHold"-->
        <!--:class="{'justify-content-center': !getAutomationCounts.bulkAutomation}">-->
        <!--&lt;!&ndash;<router-link class="btn new_campaign"&ndash;&gt;-->
        <!--&lt;!&ndash;:to="{name: 'saveBulkCsvAutomation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">&ndash;&gt;-->
        <!--&lt;!&ndash;<i class="icon_left add_icon cs-plus"></i>&ndash;&gt;-->
        <!--&lt;!&ndash;<span>New Upload</span>&ndash;&gt;-->
        <!--&lt;!&ndash;</router-link>&ndash;&gt;-->

        <!--<a class="btn new_campaign"-->
        <!--@click="createBulkAutomationProcess()">-->
        <!--<i class="icon_left add_icon cs-plus">-->
        <!--</i>-->
        <!--<span>New Upload</span>-->
        <!--</a>-->

        <!--<router-link v-if="getAutomationCounts.bulkAutomation"-->
        <!--class="btn view_campaign ml-auto"-->
        <!--:to="{name: 'bulkAutomationListing', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
        <!--<span>View existing ({{getAutomationCounts.bulkAutomation}})</span>-->
        <!--</router-link>-->

        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img
                style="width: 45px"
                src="../../../assets/img/automation/bulk_upload.svg"
                alt=""
              />
            </div>
            <h3>Bulk Uploader</h3>
            <p
              >Schedule multiple messages to your social media channels via CSV
              data import.</p
            >
            <div class="text-center" v-if="!isWorkspaceOnHold">
              <router-link
                class="btn btn btn-studio-theme-transparent btn-size-small"
                :to="{
                  name: 'saveBulkCsvAutomation',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <i class="icon_left add_icon cs-plus"></i>
                <span>New Upload</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/article_blog.svg"
                alt=""
              />
            </div>
            <h3>Articles to Blog</h3>
            <p>Share articles on your blog with smart rules and filters.</p>
            <div
              class="d-flex align-items-center"
              v-if="!isWorkspaceOnHold"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.articleBlogAutomation,
              }"
            >
              <a
                class="btn btn-studio-theme-transparent btn-size-small"
                @click="
                  createArticleAutomationProcess('saveArticleBlogAutomation')
                "
              >
                <i class="icon_left add_icon cs-plus"> </i>
                <span>New Campaign</span>
              </a>

              <router-link
                v-if="getAutomationCounts.articleBlogAutomation"
                class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                :to="{
                  name: 'articleBlogAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <span
                  >View existing ({{
                    getAutomationCounts.articleBlogAutomation
                  }})</span
                >
              </router-link>
            </div>
          </div>
        </div>
        <!--              v-if="getAutomationCounts.videoSocialAutomation"-->
        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/video_social.svg"
                alt=""
              />
            </div>
            <h3>Videos to Social Media</h3>
            <p
              >Share videos on your social media profiles, pages and groups with
              smart rules and filters.</p
            >
            <div
              class="d-flex align-items-center"
              v-if="!isWorkspaceOnHold"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.videoSocialAutomation,
              }"
            >
              <a
                class="btn btn-studio-theme-transparent btn-size-small"
                @click="
                  createVideoAutomationProcess('saveVideoSocialAutomation')
                "
              >
                <i class="icon_left add_icon cs-plus"> </i>
                <span>New Campaign</span>
              </a>

              <router-link
                v-if="getAutomationCounts.videoSocialAutomation"
                class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                :to="{
                  name: 'videoSocialAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <span
                  >View existing ({{
                    getAutomationCounts.videoSocialAutomation
                  }})</span
                >
              </router-link>
            </div>
          </div>
        </div>

        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/video_blog.svg"
                alt=""
              />
            </div>
            <h3>Videos to Blog</h3>
            <p>Share videos on your blog with smart rules and filters.</p>
            <div
              class="d-flex align-items-center"
              v-if="!isWorkspaceOnHold"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.videoBlogAutomation,
              }"
            >
              <a
                class="btn btn-studio-theme-transparent btn-size-small"
                @click="createVideoAutomationProcess('saveVideoBlogAutomation')"
              >
                <i class="icon_left add_icon cs-plus"> </i>
                <span>New Campaign</span>
              </a>

              <router-link
                v-if="getAutomationCounts.videoBlogAutomation"
                class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                :to="{
                  name: 'videoBlogAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                <span
                  >View existing ({{
                    getAutomationCounts.videoBlogAutomation
                  }})</span
                >
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.tag-most-used {
  margin-top: -1rem;
  margin-right: -20px;
  span {
    border-top-right-radius: 4px;
    font-size: 0.8rem;
    padding: 0.5rem 0.625rem;
    color: #3a4557;
    background: #f4f7fa;
  }
}

.automation_pinterest_warning {
  display: flex;
  padding: 1rem;
  color: #3a4557;
  background: #faf2dc;
  align-items: center;
  border-radius: 1rem;
  max-width: fit-content;
  margin: 0.4rem auto;

  i {
    color: red;
    font-size: 0.9rem;
  }

  p {
    color: #3d4450;
    margin-left: 10px;
    font-size: 0.9rem;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { evergreen } from '../store/automation-mutation-type'

export default {
  components: {},

  data () {
    return {
      showLoader: false,
      evergreenView: 'on'
    }
  },

  created () {
    console.debug('automationMain Call')
    // redirect user ro planner if user role is approver in that workspace
    // let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
    // if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
    //   this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    // }
    this.initializeAutomationSection()

    // Instantiate the SDK. CDN will expose splitio globally
    /* let factory = splitio({
           core: {
             authorizationKey: 'anm6pje0e442dmo8nen3roabl0sr1dpijkdb',
             // the customer id can be the logged in
             // user id, or the account id that
             // the logged in user belongs to.
             // The type of customer (user, account, custom)
             // is chosen during Split's sign-up process.
             key: this.getProfile.email
             // an OPTIONAL traffic type, if provided will be
             // used for event tracking with the SDK client.
           }
         }) */

    // And get the client instance you'll use
    /* let client = factory.client()
         let attributes = {
           'first_name': this.getProfile.firstname,
           'last_name': this.getProfile.lastname,
           '_id': this.getProfile.id
         }
         let self = this
         client.on(client.Event.SDK_READY, function () {
           var treatment = client.getTreatment('CS_Evergreen', attributes)

           if (treatment == 'on') {
             self.evergreenView = 'on'
             console.log('treatment on')
             // insert code here to show on treatment
           } else if (treatment == 'off') {
             console.log('treatment off')
             // insert code here to show off treatment
           } else {
             console.log('treatment else')
             // insert your control treatment code here
           }
           self.showLoader = false
         }) */
  },
  mounted () {},

  computed: {
    ...mapGetters(['getAutomationCounts', 'getWorkspaces', 'getProfile']),
    isWorkspaceOnHold () {
      return !!(
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.on_hold
      )
    }
  },
  methods: {
    ...mapActions([
      'setAccountSelection',
      'setAutomationScheduleOptions',
      'setEditQueueStatus'
    ]),
    newEvergreenAutomation () {
      this.$store.commit(evergreen.SET_EVERGREEN_DEFAULT_STATES)
      this.setEditQueueStatus(false)
      this.$store.commit('setAccountSelection', null)
      this.$store.commit('setAutomationScheduleOptions', null)
      this.$router.push({
        name: 'saveEvergreenAutomation',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    },
    createBulkAutomationProcess () {
      this.$router.push({
        name: 'saveBulkCsvAutomation',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
  },

  watch: {}
}
</script>
