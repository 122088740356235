var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{attrs:{"id":"manage-competitors-modal","centered":"","header-class":"cs-modal-header","title-class":"cs-modal-title","no-close-on-backdrop":"","dialog-class":"!w-[28rem]"},on:{"show":_vm.onShowManageModal,"hide":_vm.onHideManageModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Add Competitors")]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"flex w-full justify-between items-center gap-2"},[_c('div',{staticClass:"bg-[#faf2dc] flex justify-center items-center rounded-lg gap-2 px-2 py-1"},[_c('i',{staticClass:"far fa-exclamation-circle text-[#2f8ae0]"}),_c('p',{staticClass:"text-xs text-[#b57e00]"},[_vm._v("Upto 5 competitors can be added.")])]),_c('div',{staticClass:"flex justify-center items-center"},[_c('CstButton',{staticClass:"mr-3 w-28",attrs:{"variant":"secondary","text":"Cancel"},on:{"click":function($event){return _vm.$bvModal.hide('manage-competitors-modal')}}}),_c('CstButton',{staticClass:"w-28",attrs:{"text":"Save","disabled":!_vm.state.selectedCompetitors.length || _vm.state.loadingData},on:{"click":_vm.saveCompetitors}})],1)])]},proxy:true}])},[_c('div',{staticClass:"flex flex-col p-2 !h-[31rem]"},[_c('div',{staticClass:"mb-1.5 analytic-competitors-addform__search-wrapper"},[_c('b-form-input',{staticClass:"!h-11 !pl-3",attrs:{"type":"text","debounce":"1000","placeholder":"Enter title here..."},model:{value:(_vm.state.title),callback:function ($$v) {_vm.$set(_vm.state, "title", $$v)},expression:"state.title"}})],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content:
          _vm.state.selectedCompetitors.length === 5
            ? `You've already added five competitors. Please remove one of them to add another.`
            : '',
        classes: 'w-[14rem]',
      }),expression:"{\n        content:\n          state.selectedCompetitors.length === 5\n            ? `You've already added five competitors. Please remove one of them to add another.`\n            : '',\n        classes: 'w-[14rem]',\n      }",modifiers:{"top":true}}],staticClass:"analytic-competitors-addform__search-wrapper",class:_vm.state.selectedCompetitors.length === 5 ? '!cursor-not-allowed' : ''},[_c('b-input-group',{staticClass:"mt-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"far fa-search"})]},proxy:true},{key:"append",fn:function(){return [(_vm.state.loadingData && _vm.state.searchedText)?_c('clip-loader',{staticClass:"append mr-2",attrs:{"color":'#5773fa',"size":'22px'}}):_vm._e()]},proxy:true}])},[_c('b-form-input',{staticClass:"!h-11",class:_vm.state.selectedCompetitors.length === 5
              ? '!cursor-not-allowed'
              : '',attrs:{"type":"text","debounce":"500","placeholder":"Enter competitor’s slug/url here...","disabled":_vm.state.selectedCompetitors.length === 5},on:{"update":_vm.searchQuery},model:{value:(_vm.state.searchedText),callback:function ($$v) {_vm.$set(_vm.state, "searchedText", $$v)},expression:"state.searchedText"}})],1),(_vm.state.showDropDown && _vm.state.searchedText)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onClickAway),expression:"onClickAway"}],staticClass:"relative z-20"},[_c('transition',{attrs:{"name":"slide-bottom"}},[_c('div',{staticClass:"w-full p-2 border border-[#E9EFF6] shadow-lg !bg-white rounded-lg absolute top-2"},[(_vm.showData('success'))?_c('div',{staticClass:"py-1 flex items-center rounded-lg px-4 hover:bg-gray-50 cursor-pointer",on:{"click":function($event){return _vm.addCompetitors(_vm.state.successResult)}}},[_c('img',{staticClass:"w-10 h-10 object-cover border rounded-full border-[#E9EFF6]",attrs:{"src":_vm.state.successResult.image,"alt":_vm.state.successResult.name},on:{"error":function($event){$event.target.src =
                    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'}}}),_c('div',{staticClass:"ml-3 font-medium"},[_vm._v(" "+_vm._s(_vm.state.successResult.name ? _vm.state.successResult.name : _vm.state.successResult.slug)+" ")])]):_vm._e(),(_vm.showData('error'))?_c('div',{staticClass:"p-3 !bg-white !cursor-default"},[_c('p',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.state.errorResult.error))])]):_vm._e()])])],1):_vm._e()],1),(_vm.state.selectedCompetitors.length)?_c('div',{staticClass:"w-full flex flex-col items-center justify-center mt-4"},_vm._l((_vm.state.selectedCompetitors),function(competitor,index){return _c('div',{key:`competitors_list_${index}`,staticClass:"flex items-center justify-between w-full px-4 m-4"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: `${competitor.slug ? competitor.slug : competitor.name}`,
          }),expression:"{\n            content: `${competitor.slug ? competitor.slug : competitor.name}`,\n          }",modifiers:{"top":true}}],staticClass:"flex justify-start items-center select-none"},[_c('img',{staticClass:"h-10 w-10 mr-3 rounded-full overflow-hidden object-cover border border-[#E9EFF6]",attrs:{"src":competitor.image,"alt":""},on:{"error":function($event){$event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'}}}),_c('div',{staticClass:"w-64"},[_c('p',{staticClass:"font-bold truncate"},[_vm._v(_vm._s(competitor.name ? competitor.name : competitor.slug))])])]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Competitor'),expression:"'Remove Competitor'"}],staticClass:"w-7 h-7 rounded-full flex justify-center items-center bg-cs-white drop-shadow-lg z-10 cursor-pointer",on:{"click":function($event){return _vm.removeCompetitor(competitor.competitor_id)}}},[_c('i',{staticClass:"fa fa-times text-red-500",attrs:{"aria-hidden":"true"}})])])}),0):_c('div',{staticClass:"w-full h-full flex justify-center items-center"},[_c('p',[_vm._v("No competitors selected.")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }