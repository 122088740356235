<template>
  <b-modal
    id="public-password-protected"
    centered
    header-class="cs-modal-header"
    hide-footer
    modal-class="public-password-protected"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="lg"
    title-class="cs-modal-title"
  >
    <template slot="modal-title">
      <div class="py-4"> This link is password protected </div>
    </template>
    <div class="px-2 py-4">
      <CstAlert type="info" class="mb-6">
        The link you are trying to access is password protected. If you don't
        have the password, please contact the owner of the link.
      </CstAlert>
      <CstFloatingLabelInput
        id="special-input"
        v-model="state.password"
        class="mb-6"
        type="password"
        label="Please provide a valid password for this link to gain access to the shared posts"
        autocomplete="new-password"
        :disabled="state.loading"
        @keydown.enter="verifyPassword"
      />

      <!--    Footer    -->
      <div class="flex justify-end mt-3.5">
        <CstButton
          variant="primary"
          size="large"
          text="Authenticate"
          :loading="state.loading"
          :disabled="state.loading || !state.password.trim()"
          @click="verifyPassword"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstButton from '@ui/Button/CstButton'
import CstAlert from '@ui/Alert/CstAlert'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'PublicPasswordProtectedModal',
  components: {
    CstFloatingLabelInput,
    CstButton,
    CstAlert,
  },
  props: {
    linkId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { dispatch } = useStore()

    const state = reactive({
      password: '',
      loading: false,
    })

    /**
     * Method to verify the password
     */
    const verifyPassword = async () => {
      state.loading = true
      try {
        const response = await serviceShareLink(ACTION_TYPES.VERIFY_PASSWORD, {
          link_id: props.linkId,
          password: state.password,
        })

        emit('onVerify', response.token)
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false
      }
    }

    return {
      state,
      verifyPassword,
    }
  },
})
</script>

<style lang="scss">
#public-password-protected___BV_modal_outer_ {
  .modal-backdrop {
    opacity: 1 !important;
  }
}
</style>
