<template>
  <div class="relative h-100 bg-cs-light-gray">
    <div
      class="flex items-center justify-between px-6 h-16 max-h-16 min-h-16 preview-head"
    >
      <span class="font-bold text-base">Assistant</span>
    </div>
    <hr class="m-0 hr-color" />
    <div v-if="!(preview || videoPreview || imagePreview)" class="p-3 pb-0">
      <div class="content_tabs">
        <ul
          class="flex w-100 rounded-md py-1 px-1 justify-between bg-white"
          style="box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8)"
        >
          <li
            v-floatingtip.hover.top="{ content: 'Article', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'article' }"
            @click="handleContentChange('article')"
          >
            <i class="far fa-globe"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Youtube', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'youtube' }"
            @click="handleContentChange('youtube')"
          >
            <i class="fab fa-youtube"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Dailymotion', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'dailymotion' }"
            @click="handleContentChange('dailymotion')"
          >
            <i class="cs-dailymotion"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Twitter', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'twitter' }"
            @click="handleContentChange('twitter')"
          >
            <i class="fab fa-twitter"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Flickr', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'flickr' }"
            @click="handleContentChange('flickr')"
          >
            <i class="fab fa-flickr"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Imgur', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'imgur' }"
            @click="handleContentChange('imgur')"
          >
            <i class="cs-imgur"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Pixabay', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'pixabay' }"
            @click="handleContentChange('pixabay')"
          >
            <i class="cs-pixabay"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Giphy', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'giphy' }"
            @click="handleContentChange('giphy')"
          >
            <i class="cs-giphy"></i>
          </li>
          <li
            v-floatingtip.hover.top="{ content: 'Pocket', distance: 12 }"
            class="content_tab_item"
            :class="{ active_link: getContentType === 'pocket' }"
            @click="handleContentChange('pocket')"
          >
            <i class="fab fa-get-pocket"></i>
          </li>
          <li
            v-floatingtip.hover.top="{
              content:
                getContentType[0].toUpperCase() + getContentType.substring(1),
              distance: 12,
            }"
            :class="{
              hidden:
                getContentType !== 'quotes' || getContentType !== 'favorite',
              active_link:
                getContentType === 'quotes' || getContentType === 'favorite',
            }"
          >
            <i
              :class="{
                'cs-left-quote': getContentType === 'quotes',
                'cs-star': getContentType === 'favorite',
              }"
            ></i>
          </li>
          <li class="dropdown content_tab_item">
            <IconDropdown
              size="small"
              align-menu="left"
              button-classes="border-0 cursor-pointer text-black-700 rounded text-lg bg-white shadow-xs font-normal hover:!bg-cs-secondary focus:outline-none"
            >
              <template v-slot:selected>
                <i :title="'More Options'" class="cs-dots-v"></i>
              </template>
              <template v-slot>
                <IconDropdownItem
                  v-floatingtip.hover.left="{ content: 'Quotes', distance: 12 }"
                  @click="handleContentChange('quotes')"
                >
                  <i class="cs-left-quote"></i>
                </IconDropdownItem>
                <IconDropdownItem
                  v-floatingtip.hover.left="{
                    content: 'Favorite',
                    distance: 12,
                  }"
                  @click="handleContentChange('favorite')"
                >
                  <i class="cs-star"></i>
                </IconDropdownItem>
              </template>
            </IconDropdown>
          </li>
        </ul>
      </div>
      <div
        v-if="contentType !== 'favorite'"
        class="search_bar relative my-3 flex items-center justify-center w-100 z-0"
      >
        <input
          id="search"
          ref="search"
          v-model="searchKeyword"
          type="text"
          placeholder="Search by Keyword"
          class="w-100 rounded-md border py-2 px-2 text-grey-darkest focus:border-2"
          @keyup.enter="handleSearch"
        />
        <i
          class="fas fa-search cursor-pointer absolute right-2 px-1 py-1"
          @click="handleSearch"
        ></i>
      </div>
      <!--      <hr class="hr-color">-->
    </div>
    <!--  content of assistant tab starts here -->
    <Preview
      v-if="preview"
      :preview-loader="previewLoader"
      :preview-post="previewPost"
      @handle-preview="handlePreviewChanges"
    ></Preview>
    <VideoPreview
      v-else-if="videoPreview"
      :preview-post="previewPost"
      @handle-preview="handlePreviewChanges"
    />
    <ImagePreview
      v-else-if="imagePreview"
      :preview-post="previewPost"
      @handle-preview="handlePreviewChanges"
    />
    <template v-else>
      <div
        class="cs-hr-scroll w-full px-3 overflow-y-auto absolute h-4/5 scroll-padding"
      >
        <!-- separate component for all content type -->
        <template v-if="searched || contentType === 'pocket'">
          <Articles
            v-if="contentType === 'article'"
            :loader="loader"
            :posts="posts"
            :sort-type="sort"
            :region="region"
            :region-list="getRegionsList"
            :language="language"
            :language-list="getLanguageList"
            @handle-change="handleArticleChanges"
          />
          <Youtube
            v-else-if="contentType === 'youtube'"
            :loader="loader"
            :posts="posts"
            :sort-type="videosSort"
            @handle-change="handleYoutubeChanges"
          />
          <Dailymotion
            v-else-if="contentType === 'dailymotion'"
            :loader="loader"
            :posts="posts"
            :sort-type="videosSort"
            @handle-change="handleDailymotion"
          />
          <Twitter
            v-else-if="contentType === 'twitter'"
            :loader="loader"
            :posts="posts"
            :language="tweetLanguage"
            :language-list="getAllLanguageList"
            @handle-change="handleTwitterChanges"
          />
          <Flickr
            v-else-if="contentType === 'flickr'"
            :loader="loader"
            :posts="posts"
            :license="flickrLicense"
            @handle-change="handleFlickrChanges"
          />
          <Imgur
            v-else-if="contentType === 'imgur'"
            :loader="loader"
            :posts="posts"
            :sort="imgurSort"
            :section="imgurSection"
            @handle-change="handleImgurChanges"
          />
          <Pixabay
            v-else-if="contentType === 'pixabay'"
            :loader="loader"
            :posts="posts"
            :sort="pixabaySort"
            :type="pixabayType"
            @handle-change="handlePixabayChanges"
          />
          <Giphy
            v-else-if="contentType === 'giphy'"
            :loader="loader"
            :posts="posts"
            @handle-change="handleGiphyChanges"
          />
          <Pocket
            v-else-if="contentType === 'pocket'"
            :loader="loader"
            :posts="posts"
            :account="pocketAccount"
            :accounts-list="pocketAccountsList"
            :sort="pocketSort"
            :type="pocketType"
            @handle-change="handlePocketChanges"
          />
          <Quotes
            v-else-if="contentType === 'quotes'"
            :loader="loader"
            :posts="posts"
            @handle-change="handleQuotesChanges"
          />
          <Favorite
            v-else-if="contentType === 'favorite'"
            :loader="loader"
            :posts="posts"
            :sort="favoriteSort"
            :folders="folders"
            :selected-folder="selectedFolder"
            @handle-change="handleFavoriteChanges"
          />
        </template>
        <template v-else>
          <div
            class="flex w-full py-2.5 px-2 rounded-md bg-white"
            style="border: 1px solid #eef1f4"
          >
            <i
              class="far fa-search my-1 text-blue-400 text-lg font-semibold"
            ></i>
            <div class="mx-2">
              <span class="text-blue-400 text-xs font-medium uppercase"
                >Step 1</span
              >
              <span class="block font-medium">Search</span>
              <p class="text-gray-800 text-xs my-2"
                >Select a channel and find content suggestion with your own
                keywords.</p
              >
            </div>
          </div>
          <div
            class="flex w-full mt-3 py-2.5 px-2 rounded-md bg-white"
            style="border: 1px solid #eef1f4"
          >
            <i
              class="far fa-hand-pointer my-1 text-blue-400 text-lg font-semibold"
            ></i>
            <div class="mx-2">
              <span class="text-blue-400 text-xs font-medium uppercase"
                >Step 2</span
              >
              <span class="block font-medium">Select and add to editor</span>
              <p class="text-gray-800 text-xs my-2"
                >Pick the best items your audience will like, drag and drop into
                the editor.</p
              >
              <img
                src="@assets/img/common/drag_and_drop_icon.png"
                alt=""
                class="text-center opacity-50"
                style="width: 85%"
              />
            </div>
          </div>
        </template>
        <!-- **** -->
      </div>
    </template>
    <!-- Body Ends here -->
  </div>
</template>

<script>
import Router from '@src/router'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import IconDropdown from '@ui/Dropdown/IconDropdown'
import IconDropdownItem from '@ui/Dropdown/IconDropdownItem'
import {
  getArticleContentURL,
  getArticlesURL,
  getDailymotionContentByTopicURL,
  getFoldersURL,
  getImagesContentURL,
  getPocketContentURL,
  getQuotesURL,
  getTwitterContentURL,
  getYoutubeContentByTopicURL,
} from '@src/modules/discovery/config/api-utils'
import orderBy from 'lodash/orderBy'
import { filterLimitedLanguages } from '@common/constants/languages'
import { filterLimitedRegions } from '@common/constants/regions'
import Preview from './Preview'
import VideoPreview from './VideoPreview'
import ImagePreview from './ImagePreview'
import Articles from './Article.vue'
import Youtube from './Youtube.vue'
import Dailymotion from './Dailymotion.vue'
import Twitter from './Twitter.vue'
import Flickr from './Flickr.vue'
import Imgur from './Imgur.vue'
import Pixabay from './Pixabay.vue'
import Giphy from './Giphy.vue'
import Pocket from './Pocket.vue'
import Quotes from './Quotes.vue'
import Favorite from './Favorite.vue'

export default {
  name: 'AssistantMain',
  components: {
    IconDropdown,
    IconDropdownItem,
    Preview,
    VideoPreview,
    ImagePreview,
    Youtube,
    Dailymotion,
    Articles,
    Twitter,
    Flickr,
    Imgur,
    Pixabay,
    Giphy,
    Pocket,
    Quotes,
    Favorite,
  },
  props: {},
  data() {
    return {
      loader: false,
      preview: false,
      videoPreview: false,
      imagePreview: false,
      previewLoader: false,
      previewPost: {},
      searchKeyword: '',
      searched: false,
      lastSearched: '',
      contentType: 'article',
      page: 0,
      sort: 'Most Shared',
      youtubeSort: 'Relevance',
      videosSort: 'Relevance',
      imgurSort: 'Viral',
      imgurSection: 'Hot',
      pixabaySort: 'Latest',
      pixabayType: 'All',
      isHD: false,
      date: {
        label: 'Last 30 Days',
        value: this.getDefaultDate,
      },
      region: {
        code: 'world',
        native_language: {
          code: 'en',
          languageName: 'English',
        },
        name: 'Worldwide',
        flag_position: '0 0',
      },
      language: {
        languageName: 'English',
        code: 'en',
      },
      tweetLanguage: {
        languageName: 'All',
        code: 'all',
      },
      flickrLicense: {
        code: 0,
        name: 'Any License',
      },
      pocketAccount: '',
      youtubeToken: null,
      pocketAccountsList: [],
      pocketSort: 'Newest',
      pocketType: 'All',
      folders: [],
      selectedFolder: {},
      favoriteSort: 'Most Shared',
      posts: [],
      languageList: filterLimitedLanguages,
      regionList: filterLimitedRegions,
    }
  },
  computed: {
    getRegionsList() {
      const regionsList = orderBy(
        this.regionList.slice(1, this.regionList.length),
        ['name'],
        ['asc']
      )
      regionsList.unshift(this.regionList[0])
      return regionsList
    },
    getLanguageList() {
      let languageList = []
      this.languageList.forEach(function (element) {
        if (element.code === this.region.code) {
          languageList = element.languageList
        }
      }, this)
      return orderBy(languageList, ['languageName'], ['asc'])
    },
    getAllLanguageList() {
      let languageList = []
      this.languageList[0].languageList.forEach(function (element) {
        languageList = languageList.concat(element)
      }, this)
      return languageList
      // return orderBy(languageList, ['languageName'], ['asc'])
    },
    getContentType() {
      return this.contentType
    },
    getDefaultDate() {
      const startDate = moment(moment().subtract(30, 'days').toDate())
      const endDate = moment(moment().toDate())
      return (
        startDate.format('YYYY-MM-DD HH:mm:ss') +
        ' - ' +
        endDate.format('YYYY-MM-DD HH:mm:ss')
      )
    },
    getVideosSortCode: function () {
      if (this.contentType === 'youtube') {
        return this.videosSort === 'Relevance'
          ? 'Relevance'
          : this.videosSort === 'Most Viewed'
          ? 'ViewCount'
          : this.videosSort === 'Recent'
          ? 'Date'
          : 'relevance'
      }
      return this.videosSort === 'Relevance'
        ? 'relevance'
        : this.videosSort === 'Most Viewed'
        ? 'visited'
        : this.videosSort === 'Trending'
        ? 'trending'
        : 'relevance'
    },
  },
  watch: {
    // we can validate or abort in case of repeated values
    contentType: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (
          this.searchKeyword ||
          newVal === 'pocket' ||
          newVal === 'favorite'
        ) {
          switch (newVal) {
            case 'article':
              this.resetPosts()
              this.fetchArticles()
              break
            case 'youtube':
              this.resetPosts()
              this.fetchYoutube()
              break
            case 'dailymotion':
              this.resetPosts()
              this.fetchDailymotion()
              break
            case 'twitter':
              this.resetPosts()
              this.fetchTwitter()
              break
            case 'flickr':
              this.resetPosts()
              this.fetchImages()
              break
            case 'imgur':
              this.resetPosts()
              this.fetchImages()
              break
            case 'pixabay':
              this.resetPosts()
              this.fetchImages()
              break
            case 'giphy':
              this.resetPosts()
              this.fetchImages()
              break
            case 'pocket':
              this.posts = []
              if (this.getPocket.length > 0) {
                this.pocketAccountsList = [...this.getPocket]
                this.pocketAccount = this.pocketAccountsList[0].username
                this.resetPosts('pocket')
              }
              break
            case 'quotes':
              this.resetPosts()
              this.fetchQuotes()
              break
            case 'favorite':
              this.resetPosts()
              this.fetchFolders()
              break
          }
        }
      }
    },
    sort: function () {
      this.resetPosts()
      this.fetchArticles()
    },
    date: function () {
      this.resetPosts()
      this.fetchArticles()
    },
    region: function () {
      this.resetPosts()
      this.fetchArticles()
    },
    language: function () {
      this.resetPosts()
      this.fetchArticles()
    },
    videosSort: function () {
      this.resetPosts()
      if (this.contentType === 'youtube') {
        this.fetchYoutube()
      }
      if (this.contentType === 'dailymotion') {
        this.fetchDailymotion()
      }
    },
    isHD: function () {
      this.resetPosts()
      this.fetchYoutube()
    },
    tweetLanguage: function () {
      this.resetPosts()
      this.fetchTwitter()
    },
    flickrLicense: function () {
      this.resetPosts()
      this.fetchImages()
    },
    imgurSort: function () {
      this.resetPosts()
      this.fetchImages()
    },
    imgurSection: function () {
      this.resetPosts()
      this.fetchImages()
    },
    pixabaySort: function () {
      this.resetPosts()
      this.fetchImages()
    },
    pixabayType: function () {
      this.resetPosts()
      this.fetchImages()
    },
    pocketSort: function () {
      if (this.pocketAccount) {
        this.resetPosts('pocket')
        this.fetchPocket()
      }
    },
    pocketType: function () {
      if (this.pocketAccount) {
        this.resetPosts('pocket')
        this.fetchPocket()
      }
    },
    pocketAccount: function () {
      this.resetPosts('pocket')
      this.fetchPocket()
    },
    favoriteSort: function () {
      this.resetPosts()
      this.fetchFavorite()
    },
    'selectedFolder._id': function () {
      this.resetPosts()
      this.fetchFavorite()
    },
  },
  methods: {
    // all method which handle events also from child components. start with handle word
    handleSearch() {
      // const value = this.$refs.search.value
      if (!this.searchKeyword) {
        this.alertMessage('Enter something to search', 'error')
        return
      }
      if (!this.lastSearched || this.lastSearched !== this.searchKeyword) {
        switch (this.contentType) {
          case 'article':
            this.resetPosts()
            this.fetchArticles()
            break
          case 'youtube':
            this.resetPosts()
            this.youtubeToken = null
            this.fetchYoutube()
            break
          case 'dailymotion':
            this.resetPosts()
            this.fetchDailymotion()
            break
          case 'twitter':
            this.resetPosts()
            this.fetchTwitter()
            break
          case 'flickr':
            this.resetPosts()
            this.fetchImages()
            break
          case 'imgur':
            this.resetPosts()
            this.fetchImages()
            break
          case 'pixabay':
            this.resetPosts()
            this.fetchImages()
            break
          case 'giphy':
            this.resetPosts()
            this.fetchImages()
            break
          case 'pocket':
            this.fetchPocket()
            break
          case 'quotes':
            this.resetPosts()
            this.fetchQuotes()
            break
        }
        this.searched = true
        this.lastSearched = this.searchKeyword
      }
    },
    handlePreviewChanges(type, value) {
      switch (type) {
        case 'close':
          this.preview = value
          break
        case 'closeVideo':
          this.videoPreview = value
          break
        case 'closeImage':
          this.imagePreview = value
          break
        default:
          break
      }
    },
    handleContentChange(value) {
      this.contentType = value
    },
    handleArticleChanges(type, value) {
      console.log('value', value)
      switch (type) {
        case 'date':
          if (value && value[0] && value[1]) {
            const startDate = moment(value[0])
            const endDate = moment(value[1])
            const labelValue = this.checkTimeDurationLabel(startDate, endDate)
            this.date = {
              label: labelValue,
              value:
                startDate.format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                endDate.format('YYYY-MM-DD HH:mm:ss'),
            }
          }
          break
        case 'scroll':
          this.page += 1
          this.fetchArticles(value)
          break
        case 'sort':
          this.sort = value
          break
        case 'region':
          this.region = value
          break
        case 'language':
          this.language = value
          break
        case 'preview':
          this.fetchArticle(value)
          break
        default:
          break
      }
    },
    handleYoutubeChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchYoutube(value)
          break
        case 'sort':
          this.youtubeToken = null
          this.videosSort = value
          break
        case 'HD':
          this.youtubeToken = null
          this.isHD = value
          break
        case 'preview':
          this.previewPost = value
          this.previewLoader = true
          this.videoPreview = true
          break
        default:
          break
      }
    },
    handleDailymotion(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchDailymotion(value)
          break
        case 'sort':
          this.videosSort = value
          break
        case 'preview':
          this.previewPost = value
          this.previewLoader = true
          this.videoPreview = true
          break
        default:
          break
      }
    },
    handleTwitterChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchTwitter(value)
          break
        case 'language':
          this.tweetLanguage = value
          break
      }
    },
    handleFlickrChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchImages(value)
          break
        case 'license':
          this.flickrLicense = value
          break
        case 'preview':
          this.previewPost = { url: value }
          this.imagePreview = true
          break
      }
    },
    handleImgurChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchImages(value)
          break
        case 'sort':
          this.imgurSort = value
          break
        case 'section':
          this.imgurSection = value
          break
        case 'preview':
          this.previewPost = { url: value }
          this.imagePreview = true
          break
      }
    },
    handlePixabayChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchImages(value)
          break
        case 'sort':
          this.pixabaySort = value
          break
        case 'type':
          this.pixabayType = value
          break
        case 'preview':
          this.previewPost = { url: value }
          this.imagePreview = true
          break
      }
    },
    handleGiphyChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchImages(value)
          break
        case 'preview':
          this.previewPost = { url: value }
          this.imagePreview = true
          break
      }
    },
    handlePocketChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchPocket(value)
          break
        case 'sort':
          this.pocketSort = value
          break
        case 'type':
          this.pocketType = value
          break
        case 'account':
          this.pocketAccount = value
          break
      }
    },
    handleQuotesChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchQuotes(value)
          break
        case 'preview':
          this.previewPost = { url: value }
          this.imagePreview = true
          break
      }
    },
    handleFavoriteChanges(type, value) {
      switch (type) {
        case 'scroll':
          this.page += 1
          this.fetchFavorite(value)
          break
        case 'folder':
          this.selectedFolder = value
          break
        case 'sort':
          this.favoriteSort = value
          break
        case 'preview':
          this.fetchArticle(value)
          break
      }
    },

    // all methods which makes request start with fetch word
    fetchArticles($state = null) {
      this.loader = true
      const payload = {
        filters: {
          articles: {
            date: this.date,
            domain: null,
            language: this.language,
            limit: 20,
            page: this.page,
            region: this.region,
            sort: this.sort,
            type: 'Any',
            related_id: null,
          },
          search: this.searchKeyword,
          search_type: 'title',
          related: false,
          topic: null,
          category: null,
        },
        route: 'composerSocial',
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        module_type: 'search',
      }
      // route: Router.history.current.name,

      proxy
        .post(getArticlesURL, payload)
        .catch((error) => {
          this.loader = false
          console.error('fetchArticle+error', error)
          if ($state) $state.complete()
        })
        .then((response) => {
          response.data.posts.forEach((item) => {
            item._source.showfull = false
          })
          if (response.data.status) {
            this.loader = false
            this.posts = this.posts.concat(response.data.posts)
            if ($state) {
              $state.loaded()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
    },
    fetchArticle(payload) {
      this.previewPost = {}
      this.previewLoader = true
      this.preview = true

      proxy
        .post(getArticleContentURL, {
          id: payload.id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .catch((error) => {
          console.debug(error)
        })
        .then((resp) => {
          if (resp && resp.data && resp.data.status) {
            resp.data.post.index_id = payload.index
            this.previewPost = resp.data.post
            this.previewLoader = false
          }
        })
    },
    fetchYoutube($state = null) {
      this.loader = true
      const payload = {
        filters: {
          search: '',
          page: this.page,
          limit: 20,
          youtube: {
            pagetoken: this.youtubeToken,
            sort: {
              name: this.videosSort,
              code: this.getVideosSortCode,
            },
            region: {
              name: 'United States',
              code: 'US',
            },
            hd: this.isHD,
          },
          dailymotion: {
            sort: {
              name: this.videosSort,
              code: this.getVideosSortCode,
            },
          },
        },
        type: 'search',
        search: this.searchKeyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(getYoutubeContentByTopicURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (response.data.videos || response.data.videos.length === 20) {
              this.loader = false
              this.posts = this.posts.concat(response.data.videos)
              if ($state) $state.loaded()
            }
            if (response.data.page_token) {
              this.youtubeToken = response.data.page_token
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchDailymotion($state = null) {
      this.loader = true
      const payload = {
        filters: {
          search: '',
          page: this.page,
          limit: 20,
          youtube: {
            pagetoken: null,
            sort: {
              name: this.videosSort,
              code: this.getVideosSortCode,
            },
            region: {
              name: 'United States',
              code: 'US',
            },
            hd: this.isHD,
          },
          dailymotion: {
            sort: {
              name: this.videosSort,
              code: this.getVideosSortCode,
            },
          },
        },
        search: this.searchKeyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(getDailymotionContentByTopicURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (response.data.items || response.data.items.length === 20) {
              this.loader = false
              this.posts = this.posts.concat(response.data.items)
              if ($state) $state.loaded()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchTwitter($state = null) {
      this.loader = true
      const payload = {
        filters: {
          language: this.tweetLanguage,
          page: this.page,
          limit: 30,
          pagetoken: null,
          placeId: null,
          sinceId: null,
          sort: 'recent',
        },
        type: 'search',
        search: this.searchKeyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(getTwitterContentURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (response.data.tweets || response.data.tweets.length === 20) {
              this.loader = false
              this.posts = this.posts.concat(response.data.tweets)
              if ($state) $state.loaded()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchImages($state = null) {
      this.loader = true
      const payload = {
        filters: {
          page: this.page,
          limit: 20,
          flickr: { license: this.flickrLicense },
          imgur: {
            sort: this.imgurSort.toLowerCase(),
            section: this.imgurSection.toLowerCase(),
          },
          pixabay: {
            sort: this.pixabaySort.toLowerCase(),
            type: this.pixabayType.toLowerCase(),
          },
        },
        source: this.contentType,
        search: this.searchKeyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(getImagesContentURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (response.data.photos && response.data.photos.length >= 1) {
              this.loader = false
              this.posts = this.posts.concat(response.data.photos)
              if ($state) $state.loaded()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchPocket($state = null) {
      if (!this.pocketAccount) return
      this.loader = true
      const payload = {
        filters: {
          account: this.pocketAccount,
          page: this.page,
          limit: 20,
          sort: { code: this.pocketSort.toLowerCase(), name: this.pocketSort },
          type: { code: this.pocketType.toLowerCase(), name: this.pocketType },
        },
        search: this.searchKeyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(getPocketContentURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (response.data.pocket && response.data.pocket.length > 0) {
              this.loader = false
              this.posts = this.posts.concat(response.data.pocket)
              if ($state) $state.loaded()
            } else {
              this.loader = false
              if ($state) $state.complete()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchQuotes($state = null) {
      this.loader = true
      const payload = {
        page: this.page,
        limit: 20,
        search: this.searchKeyword,
      }
      proxy
        .post(getQuotesURL, payload)
        .then((response) => {
          if (response.data.status) {
            if (
              response.data.quotes.hits &&
              response.data.quotes.hits.length > 0
            ) {
              this.loader = false
              this.posts = this.posts.concat(response.data.quotes.hits)
              if ($state) $state.loaded()
            } else {
              this.loader = false
              if ($state) $state.complete()
            }
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.debug(error)
        })
    },
    fetchFolders() {
      proxy
        .post(getFoldersURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .catch((error) => {
          console.debug(error)
        })
        .then((resp) => {
          if (resp && resp.data.folders && resp.data.folders.length > 0) {
            this.folders = [...resp.data.folders]
            this.selectedFolder = this.folders[0]
          } else {
            this.folders = []
          }
        })
    },
    fetchFavorite($state = null) {
      this.loader = true
      const payload = {
        filters: {
          topics: {
            date: this.date,
            language: this.language,
            limit: 20,
            page: this.page,
            region: this.region,
            sort: this.favoriteSort,
            min_social_engagement: 0,
            must_include_featured_image: true,
            type: 'Any',
          },
        },
        route: Router.history.current.name,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        module_type: 'favorite',
        topic: this.selectedFolder._id,
      }
      proxy
        .post(getArticlesURL, payload)
        .catch((error) => {
          this.loader = false
          if ($state) $state.complete()
          console.error('fetchArticle+error', error)
        })
        .then((response) => {
          response.data.posts.forEach((item) => {
            item._source.showfull = false
          })
          if (response.data.status && response.data.posts.length > 0) {
            this.loader = false
            this.posts = this.posts.concat(response.data.posts)
            if ($state) $state.loaded()
          } else {
            this.loader = false
            if (this.page <= 1) this.posts = []
            if ($state) $state.complete()
          }
        })
    },

    // ***
    resetPosts(type = '') {
      // if(type === 'pocket') this.page = 0
      this.page = 0
      this.posts = []
    },
  },
}
</script>

<style lang="scss" scoped>
.content_tabs {
  .active_link {
    color: #fff !important;
    background: #005fd0 !important;
  }
  ul {
    .content_tab_item {
      @apply h-8 w-8 flex justify-center items-center cursor-pointer text-gray-700 rounded text-lg mx-px hover:bg-cs-secondary;
    }
  }
}
</style>
