var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-router social_share_modal blog_share_modal social_share_only top_left_design_box"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeShareModal),expression:"closeShareModal"}],staticClass:"modal-dialog",class:[_vm.closeStatus ? 'animated fadeInDown' : 'animated fadeOutUp']},[_c('div',{staticClass:"modal-content"},[_c('model-layout',{attrs:{"type":"Blog"}}),_c('div',{staticClass:"modal_body",staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"modal_inner_content basic_form blog_steps_common",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"d-flex align-items-stretch"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"steps_arrow_links"},[_c('ul',[_c('li',{class:{
                      active: _vm.getBlogSharingSelection.stage === 'content',
                      disabled:
                        _vm.getPublishLoaders.initializeArticlePostCreation,
                    },on:{"click":function($event){$event.preventDefault();return _vm.setBlogSharingStage('content')}}},[_vm._v("Content")]),_vm._m(0),_c('li',{class:{
                      active: _vm.getBlogSharingSelection.stage === 'settings',
                      disabled:
                        _vm.getPublishLoaders.initializeArticlePostCreation,
                    },on:{"click":function($event){$event.preventDefault();return _vm.setBlogSharingStage('settings')}}},[_vm._v("Settings")]),_vm._m(1),_c('li',{class:{
                      active:
                        _vm.getBlogSharingSelection.stage === 'distribution',
                      disabled:
                        _vm.getPublishLoaders.initializeArticlePostCreation,
                    },on:{"click":function($event){$event.preventDefault();return _vm.setBlogSharingStage('distribution')}}},[_vm._v("Distribution")]),_vm._m(2),_c('li',{class:{
                      active: _vm.getBlogSharingSelection.stage === 'publish',
                      disabled:
                        _vm.getPublishLoaders.initializeArticlePostCreation,
                    },on:{"click":function($event){$event.preventDefault();return _vm.setBlogSharingStage('publish')}}},[_vm._v("Review & Publish")])])]),_c('content-creation-section-feeder',{class:{ hide: _vm.getBlogSharingSelection.stage !== 'content' }}),_c('image-section',{class:{
                  hide: _vm.getBlogSharingSelection.stage !== 'settings',
                }}),_c('blog-distribute',{class:{
                  hide: _vm.getBlogSharingSelection.stage !== 'distribution',
                },attrs:{"variations_options":"true"}}),_c('blog-review-publish',{class:{ hide: _vm.getBlogSharingSelection.stage !== 'publish' },attrs:{"type":"discovery"}})],1),_c('model-sidebar',{staticClass:"social_post_side_filter_model social_share_model_sidebar",attrs:{"type":"Blog"}})],1)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})])
}]

export { render, staticRenderFns }