<script>
// libraries
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableData',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    day: {
      type: String,
      default: '',
    },
    addSlotModal: {
      type: Function,
      default: () => {},
    },
  },
})
</script>

<template>
  <td
    class="relative h-[2rem] box-content border border-2 border-[#2a8bc3] align-top lg:px-2 sm:px-1"
    :data-date-time="`${day}-${
      index > 12 ? index % 12 : index === 12 ? 0 : index
    }-${index >= 12 ? 'PM' : 'AM'}`"
  >
    <div class="all-slots"></div>
    <div
      class="group custom-slot-btn cursor-pointer absolute w-full bottom-0 h-full left-1.5"
      @click="
        addSlotModal({
          hour: index > 12 ? index % 12 : index,
          minutes: 0,
          period: index >= 12 ? 'PM' : 'AM',
          day: day,
        })
      "
    >
      <button
        class="h-[2rem] absolute bottom-0 w-full hidden group-hover:block duration-300 bg-[#0068e5] text-white w-11/12 rounded-md border-0"
      >
        Add a slot
      </button>
    </div>
  </td>
</template>
