// eslint-disable-next-line camelcase
const paddle_ids = {
  develop: {
    contentstudio_starter_monthly: 35196,
    contentstudio_starter_annual: 35201,
    basic: 35197,
    basic_annual: 35202,
    professional: 35198,
    professional_annual: 35203,
    agency: 35199,
    agency_annual: 35204,
    business_large: 35200,
    business_large_annual: 35205,
    trial_starter: 0,
    trial_professional: 35197,
    trial_business_agency_small: 35198,
    trial_business_agency_medium: 35199,
    trial_business_agency_large: 35200,
  },
  staging: {
    contentstudio_starter_monthly: 35215,
    contentstudio_starter_annual: 35220,
    basic: 35216,
    basic_annual: 35221,
    professional: 35217,
    professional_annual: 35222,
    agency: 35218,
    agency_annual: 35223,
    business_large: 35219,
    business_large_annual: 35224,
    trial_starter: 0,
    trial_professional: 35216,
    trial_business_agency_small: 35217,
    trial_business_agency_medium: 35218,
    trial_business_agency_large: 35219,
  },
  'qa-features': {
    contentstudio_starter_monthly: 0,
    contentstudio_starter_annual: 0,
    basic: 0,
    basic_annual: 0,
    professional: 0,
    professional_annual: 0,
    agency: 0,
    agency_annual: 0,
    business_large: 0,
    business_large_annual: 0,
    trial_starter: 0,
    trial_professional: 0,
    trial_business_agency_small: 0,
    trial_business_agency_medium: 0,
    trial_business_agency_large: 0,
  },
  uat: {
    contentstudio_starter_monthly: 0,
    contentstudio_starter_annual: 0,
    basic: 0,
    basic_annual: 0,
    professional: 0,
    professional_annual: 0,
    agency: 0,
    agency_annual: 0,
    business_large: 0,
    business_large_annual: 0,
    trial_starter: 0,
    trial_professional: 0,
    trial_business_agency_small: 0,
    trial_business_agency_medium: 0,
    trial_business_agency_large: 0,
  },
  production: {
    contentstudio_starter_monthly: 775040,
    contentstudio_starter_annual: 775041,
    basic: 555960,
    basic_annual: 555961,
    professional: 555962,
    professional_annual: 555963,
    agency: 555964,
    agency_annual: 555965,
    business_large: 555966,
    business_large_annual: 555967,
    trial_starter: 775042, // not existed on paddle account
    trial_professional: 555960,
    trial_business_agency_small: 555962,
    trial_business_agency_medium: 555964,
    trial_business_agency_large: 555966,
  },
}

const pricingList = {
  plans: [
    {
      paddle_name: 'ContentStudio - Starter Monthly',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT]
          .contentstudio_starter_monthly,
      fs_name: 'contentstudio-starter-monthly',
      display: 'Starter',
      display_tag: '',
      image_name: 'starter-plan.svg',
      price: '$25',
      price_int: 25,
      workspaces: 1,
      profiles: 5,
      blogs: 0,
      topics: 5,
      automations: 0,
      media_storage: 10,
      caption_generation_credits: 10000,
      image_generation_credits: 10,
      members: 0,
      level: 1,
      upgrade: true,
      type: 'monthly',
      loader: false,
    },
    {
      paddle_name: 'ContentStudio - Starter Annual',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT]
          .contentstudio_starter_annual,
      fs_name: 'contentstudio-starter-annual',
      display: 'Starter',
      display_tag: '',
      image_name: 'starter-plan.svg',
      price_billed_annually_int: 240,
      price: '$20',
      price_int: 20,
      workspaces: 1,
      profiles: 5,
      blogs: 0,
      topics: 5,
      automations: 0,
      media_storage: 10,
      caption_generation_credits: 10000,
      image_generation_credits: 10,
      members: 0,
      level: 2,
      upgrade: true,
      type: 'annually',
      loader: false,
    },
    {
      paddle_name: 'pro',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].basic,
      fs_name: 'basic',
      display: 'Pro',
      display_tag: '',
      image_name: 'professional.svg',
      price: '$49',
      price_int: 49,
      workspaces: 1,
      profiles: 10,
      blogs: 3,
      topics: '',
      automations: '10',
      media_storage: 10,
      caption_generation_credits: 20000,
      image_generation_credits: 10,
      members: 1,
      level: 3,
      upgrade: true,
      type: 'monthly',
      loader: false,
    },
    {
      paddle_name: 'pro-annual',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].basic_annual,
      fs_name: 'basic-annual',
      display: 'Pro',
      display_tag: '',
      image_name: 'professional.svg',
      price_billed_annually_int: 470,
      price_billed_annually_50: 294,
      price_billed_annually_70: 176,
      price_int_50: 24,
      price_int_70: 14,
      price: '$39',
      price_int: 39,
      crossed_price: '$49',
      workspaces: 1,
      profiles: 10,
      blogs: 3,
      topics: '',
      automations: '10',
      media_storage: 10,
      caption_generation_credits: 20000,
      image_generation_credits: 10,
      members: 1,
      level: 4,
      upgrade: true,
      type: 'annually',
      loader: false,
    },
    {
      paddle_name: 'business-agency-small',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].professional,
      fs_name: 'professional',
      display: 'Business & Agency',
      display_tag: 'Small',
      image_name: 'business-agency-small.svg',
      price: '$99',
      price_int: 99,
      workspaces: 5,
      profiles: 25,
      blogs: 10,
      topics: '',
      automations: '',
      media_storage: 20,
      caption_generation_credits: 50000,
      image_generation_credits: 20,
      members: 5,
      level: 5,
      upgrade: true,
      type: 'monthly',
      loader: false,
    },
    {
      paddle_name: 'small-annual',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT].professional_annual,
      fs_name: 'professional-annual',
      display_tag: 'Small',
      display: 'Business & Agency',
      image_name: 'business-agency-small.svg',
      price_billed_annually_int: 950,
      price: '$79',
      price_int: 79,
      price_billed_annually_50: 594,
      price_int_50: 49,
      crossed_price: '$99',
      workspaces: 5,
      profiles: 25,
      blogs: 10,
      topics: '',
      automations: '',
      media_storage: 20,
      caption_generation_credits: 50000,
      image_generation_credits: 20,
      members: 5,
      level: 6,
      upgrade: true,
      type: 'annually',
      loader: false,
    },
    {
      paddle_name: 'medium',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].agency,
      fs_name: 'agency',
      display: 'Business & Agency',
      display_tag: 'Medium',
      image_name: 'business-agency-medium.svg',
      price_int: 199,
      price: '$199',
      workspaces: 10,
      profiles: 50,
      blogs: 20,
      topics: '',
      automations: '',
      media_storage: 50,
      caption_generation_credits: 100000,
      image_generation_credits: 30,
      members: 10,
      level: 7,
      upgrade: true,
      type: 'monthly',
      loader: false,
    },
    {
      paddle_name: 'medium-annual',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].agency_annual,
      fs_name: 'agency-annual',
      display: 'Business & Agency',
      display_tag: 'Medium',
      image_name: 'business-agency-medium.svg',
      price_billed_annually_int: 1910,
      price_int: 159,
      price_billed_annually_50: 1194,
      price_int_50: 99,
      price: '$159',
      crossed_price: '$199',
      workspaces: 10,
      profiles: 50,
      blogs: 20,
      topics: '',
      automations: '',
      media_storage: 50,
      caption_generation_credits: 100000,
      image_generation_credits: 30,
      members: 10,
      level: 8,
      upgrade: true,
      type: 'annually',
      loader: false,
    },
    {
      paddle_name: 'large',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].business_large,
      fs_name: 'business-large',
      display: 'Business & Agency',
      display_tag: 'Large',
      image_name: 'business-agency-large.svg',
      price_int: 299,
      price: '$299',
      workspaces: 20,
      profiles: 150,
      blogs: 50,
      topics: '',
      automations: '',
      media_storage: 100,
      caption_generation_credits: 150000,
      image_generation_credits: 50,
      members: 20,
      level: 9,
      upgrade: true,
      type: 'monthly',
      loader: false,
    },
    {
      paddle_name: 'large-annual',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT].business_large_annual,
      fs_name: 'business-large-annual',
      display: 'Business & Agency',
      display_tag: 'Large',
      image_name: 'business-agency-large.svg',
      price_int: 239,
      price_billed_annually_int: 2870,
      price: '$239',
      price_billed_annually_50: 1794,
      price_int_50: 149,
      crossed_price: '$299',
      workspaces: 20,
      profiles: 150,
      blogs: 50,
      topics: '',
      automations: '',
      media_storage: 100,
      caption_generation_credits: 150000,
      image_generation_credits: 50,
      members: 20,
      level: 10,
      upgrade: true,
      type: 'annually',
      loader: false,
    },
  ],
}

const trialPricingList = {
  plans: [
    {
      paddle_name: 'Starter',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].trial_starter,
      fs_name: 'trial-starter',
      display: 'Starter',
      display_tag: '',
      image_name: 'professional.svg',
      price: '$25',
      price_int: 25,
      workspaces: 1,
      profiles: 5,
      blogs: 0,
      topics: 5,
      automations: 0,
      media_storage: 10,
      caption_generation_credits: 10000,
      image_generation_credits: 10,
      members: 0,
      level: 1,
      upgrade: true,
      type: 'month',
      loader: false,
    },
    {
      paddle_name: 'trial',
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].trial_professional,
      fs_name: 'trial-professional', // tackling the use case where  some users are on default trial vs. trial - professional
      display: 'Professional',
      display_tag: '',
      image_name: 'professional.svg',
      price: '$49',
      price_int: 49,
      workspaces: 1,
      profiles: 10,
      blogs: 3,
      topics: '',
      automations: '10',
      media_storage: 10,
      caption_generation_credits: 20000,
      image_generation_credits: 10,
      members: 1,
      level: 2,
      upgrade: true,
      type: 'month',
      loader: false,
    },
    {
      paddle_name: 'trial-business-agency-small',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT].trial_business_agency_small,
      fs_name: 'trial-business-agency-small',
      display: 'Business & Agency',
      display_tag: 'Small',
      image_name: 'business-agency-small.svg',
      price: '$99',
      price_int: 99,
      workspaces: 5,
      profiles: 25,
      blogs: 10,
      topics: '',
      automations: '',
      media_storage: 20,
      caption_generation_credits: 50000,
      image_generation_credits: 20,
      members: 5,
      level: 3,
      upgrade: true,
      type: 'month',
      loader: false,
    },
    {
      paddle_name: 'trial-business-agency-medium',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT]
          .trial_business_agency_medium,
      fs_name: 'trial-business-agency-medium',
      display: 'Business & Agency',
      display_tag: 'Medium',
      image_name: 'business-agency-medium.svg',
      price_int: 199,
      price: '$199',
      workspaces: 10,
      profiles: 50,
      blogs: 20,
      topics: '',
      automations: '',
      media_storage: 50,
      caption_generation_credits: 100000,
      image_generation_credits: 30,
      members: 10,
      level: 4,
      upgrade: true,
      type: 'month',
      loader: false,
    },
    {
      paddle_name: 'trial-business-agency-large',
      paddle_id:
        paddle_ids[process.env.VUE_APP_ENVIRONMENT].trial_business_agency_large,
      fs_name: 'trial-business-agency-large',
      display: 'Business & Agency',
      display_tag: 'Large',
      image_name: 'business-agency-large.svg',
      price_int: 299,
      price: '$299',
      workspaces: 20,
      profiles: 150,
      blogs: 50,
      topics: '',
      automations: '',
      media_storage: 100,
      caption_generation_credits: 150000,
      image_generation_credits: 50,
      members: 20,
      level: 5,
      upgrade: true,
      type: 'month',
      loader: false,
    },
  ],
}

export { pricingList, trialPricingList }
