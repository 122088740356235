<template>
  <div class="card_container">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Reactions Overview</h2>
        </div>
        <div class="card_content reaction_chart_content">
          <highcharts
            :options="lineChartPerformance()"
            ref="lineChart"
          ></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import highcharts from "@ui/Highcharts";
import { lineChartTooltip } from '../helper'

export default {
  components: {
    highcharts
  },
  props: {
    like: {},
    love: {},
    haha: {},
    wow: {},
    sad: {},
    angry: {}
  },
  mounted () {},

  data () {
    return {}
  },

  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    lineChartPerformance () {
      const lineChartTooltipModified = lineChartTooltip()

      lineChartTooltipModified.pointFormat =
        '<p class="engagement_point"><span>{point.y:f}</span></p>'
      lineChartTooltipModified.headerFormat =
        '<div class="chart_tooltip engagement_tooltip">'

      return {
        credits: {
          enabled: false
        },
        tooltip: lineChartTooltipModified,
        chart: {
          type: 'bar',
          spacingBottom: 0,
          spacingTop: 0,
          spacingRight: 0,
          marginTop: 0,
          marginRight: 0,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {}
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          y: 10,
          floating: true,
          borderWidth: 1,
          shadow: false,
          enabled: false,
          backgroundColor: 'white',
          borderColor: '#f6f7fb',
          itemStyle: {
            font: "'Source Sans Pro', sans-serif'",
            color: '#64717c',
            fontWeight: 'normal'
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          labels: {
            formatter: function () {
              switch (this.value) {
                case 0:
                  return '<img src="/img/facebook_emoji/f-thumb.svg" >'
                case 1:
                  return '<img src="/img/facebook_emoji/f-heart.svg" >'
                case 2:
                  return '<img src="/img/facebook_emoji/f-shocked.svg" >'
                case 3:
                  return '<img src="/img/facebook_emoji/f-laugh.svg" >'
                case 4:
                  return '<img src="/img/facebook_emoji/f-sad.svg" >'
                case 5:
                  return '<img src="/img/facebook_emoji/f-angry.svg" >'
              }
            },
            useHTML: true
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ' '
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal',

            pointWidth: 34,

            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50
          }
        },
        series: [
          {
            name: '',
            data: [
              {
                y: this.like,
                // color: '#6a98cf',
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#6a98cf'],
                    [1, '#9ec2ee']
                  ]
                }
              },
              {
                y: this.love,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#f41054'],
                    [1, '#ff618f']
                  ]
                },
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50
              },
              {
                y: this.wow,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#ffe38c'],
                    [1, '#fcdb6a']
                  ]
                },
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50
              },
              {
                y: this.haha,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#e5c761'],
                    [1, '#f3d980']
                  ]
                },
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50
              },
              {
                y: this.sad,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#ffeaa4'],
                    [1, '#fae9b0']
                  ]
                },
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50
              },
              {
                y: this.angry,
                color: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                  },
                  stops: [
                    [0, '#e25b5b'],
                    [1, '#f18e8e']
                  ]
                },
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
