import axios from "axios";

import {mapGetters} from "vuex";
import {
    addNewIgCompetitor,
    fetchIgAvgPostEngagement,
    fetchIgCompetitors,
    fetchIgEngagementDistribution,
    fetchIgFanChange,
    fetchIgFanCount,
    fetchIgHashtags,
    fetchIgMaxFansChange,
    fetchIgPostCountByType,
    fetchIgPostEngagement,
    fetchIgPostTypeEngagement,
    fetchIgRankings,
    fetchIgTableMetrics,
    fetchIgTopPosts,
    searchIgAccount,
    updateIgCompetitor,
} from '@src/modules/analytics/config/api-utils'


export default {
    data() {
        return {
            CancelToken: axios.CancelToken,
            proxy: axios
        }
    },
    computed: {
        ...mapGetters(['getJWTToken', 'getActiveWorkspace', 'getProfile', 'getWorkspaces'])
    },
    mounted() {
    },
    methods: {
        makeApiCall(url, payload, method = "POST") {
            this.proxy.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.getJWTToken
            }
            let cancel
            const promise = new Promise((resolve, reject) => {
                axios.post(url, payload, {
                    cancelToken: new this.CancelToken(function executor(c) {
                        cancel = c
                    })
                }).then(response => {
                    resolve(response)
                }).catch((error) => reject(error));
            })

            return [cancel, promise]
        },

        searchIgAccount(slug) {
            const url = searchIgAccount
            const payload = {
                "search": slug
            }
            return this.makeApiCall(url, payload)
        },
        getIgCompetitors(instagram_id) {
            const url = fetchIgCompetitors
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "instagram_id": instagram_id
            }
            return this.makeApiCall(url, payload)
        },
        addIgCompetitors(instagram_id, ig_name, igCompetitors) {

            const data = igCompetitors.map((obj)=>{
                return {
                    "workspace_id": this.getWorkspaces.activeWorkspace._id,
                    "type": "Page",
                    "instagram_id": instagram_id,
                    "competitor_id": obj.competitor_id,
                    "name": obj.name,
                    "display_name": obj.display_name,
                    "added_by": ig_name,
                    "user_id": this.getProfile._id,
                    "image": obj.image
                }

            });
            const url = addNewIgCompetitor
            const payload = {
                "data": data
            }
            return this.makeApiCall(url, payload)
        },

        delIgCompetitors(competitorObj) {
            const url = updateIgCompetitor
            competitorObj.is_active = false
            const payload = competitorObj
            return this.makeApiCall(url, payload)
        },
        getIgRanking(instagram_id, time_period) {
            const url = fetchIgRankings
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgTableMetrics(instagram_id, time_period) {
            const url = fetchIgTableMetrics
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgMaxFansChange(instagram_id, time_period) {
            const url = fetchIgMaxFansChange
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgFanCount(instagram_id, time_period) {
            const url = fetchIgFanCount
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgFanChange(instagram_id, time_period) {
            const url = fetchIgFanChange
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgPostEngagement(instagram_id, time_period) {
            const url = fetchIgPostEngagement
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgAvgPostEngagement(instagram_id, time_period) {
            const url = fetchIgAvgPostEngagement
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgPostTypeEngagement(instagram_id, time_period) {
            const url = fetchIgPostTypeEngagement
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgPostCountByType(instagram_id, time_period) {
            const url = fetchIgPostCountByType
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgEngagementDistribution(instagram_id, time_period) {
            const url = fetchIgEngagementDistribution
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgTopPosts(instagram_id, time_period) {
            const url = fetchIgTopPosts
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        },
        getIgHashtags(instagram_id, time_period) {
            const url = fetchIgHashtags
            const payload = {
                "workspace_id": this.getWorkspaces.activeWorkspace._id,
                "accounts": instagram_id,
                "time_zone": this.getWorkspaces.activeWorkspace.timezone,
                "date": time_period
            }
            return this.makeApiCall(url, payload)
        }
    }

}
