import { mapActions, mapGetters } from 'vuex'
import {
  approvalStages,
  publish,
} from '@src/modules/publish/store/states/mutation-types'
import moment from 'moment'
import {
  PLAN_APPROVAL_SUCCESS,
  PLAN_REJECT_SUCCESS,
} from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

export const approvalMixin = {
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getProfile',
      'getActiveWorkspaceMembersName',
      'getActiveWorkspaceMembersIds',
      'getApprovalData',
      'getPlans',
    ]),
    checkPendingApproval() {
      return (
        this.getPublishSelection.planApprovalData &&
        this.getPublishSelection.planApprovalData.approvers &&
        this.getPublishSelection.planApprovalData.approvers.length > 0 &&
        this.getPublishSelection.planApprovalData.status === 'pending_approval'
      )
    },
    isApprovalPost() {
      return (
        this.getPublishSelection.planApprovalData &&
        this.getPublishSelection.planApprovalData.approvers &&
        this.getPublishSelection.planApprovalData.approvers.length > 0
      )
    },
  },
  methods: {
    ...mapActions([
      'changePlanStatus',
      'planApprovalAction',
      'setPublishTimeSelection',
      'setPublishingTimeType',
    ]),

    /**
     * can edit post
     * @param item
     * @returns boolean
     */
    canEditPost(item) {
      if (item?.can_perform?.edit) {
        if (this.getUserRole !== 'Approver') return true
        if (
          item.approval &&
          item.approval.status === 'pending_approval' &&
          this.checkApprovalStatus(item.approval)
        )
          return true
      }
      return false
    },

    /**
     * generate approval rule text
     *  if they are different then change workspace according to notification workspace
     * @param option
     * @returns string
     */
    getApprovalOptionText(option) {
      switch (option) {
        case 'everyone':
          return '<span class="font-bold">Approval Rule: </span> Everyone has to approve'
        case 'anyone':
          return '<span class="font-bold">Approval Rule: </span> Anyone can approve'
      }
      return ''
    },

    /**
     * @Deprecated - Defined in useApproval composable. To-Do: Remove later from mixins
     * check specific user approval status is pending or not
     * @param data
     * @param itemRequired
     * @returns boolean
     */
    checkApprovalStatus(data, itemRequired = false) {
      if (data.approvers) {
        const approvalUser = data.approvers.find(
          (user) => user.user_id === this.getProfile._id
        )
        if (approvalUser && approvalUser.status === 'pending') {
          if (itemRequired) return approvalUser
          return true
        }
      }
      return false
    },

    /**
     *  call change plan status method
     *  show missed reviewed modal in case of past date
     * @param status
     * @param item
     * @param plannerV2
     * @returns null
     */
    async changePlanStatusMethod(status, item, plannerV2 = false) {
      console.log(
        'METHOD::changePlanStatusMethod ~ status, item, plannerV2 = false -> ',
        status,
        item,
        plannerV2
      )
      if (
        status === 'scheduled' &&
        item.execution_time.date < moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        this.initializeMissedReviewedDatePicker(item, 'review')
        return false
      }
      await this.$store.dispatch('changePlanStatus', {
        id: item._id,
        status: status,
        plannerV2,
        context: this.$root,
      })
      // this.$root.$emit('refreshPlannerTableV2')
    },

    /**
     *  approve / Reject Plan Method
     * @param plan
     * @param status
     * @param comment
     * @returns boolean
     */
    async processPlanApproval(plan, status, comment = false) {
      console.debug('method:processPlanApproval', plan, status)

      // if approve with comment or reject with comment than verify that comment exist
      /* if (
        comment &&
        (!plan.new_comment || plan.new_comment.trim().length === 0)
      ) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      } */

      // if specific user approval status is pending
      const approvalUser = this.checkApprovalStatus(plan.approval, true)
      if (approvalUser && approvalUser.user_id) {
        // if post date is in past show missed reviewed modal
        if (
          plan.status === 'review' &&
          status === 'approve' &&
          plan.execution_time.date < moment.utc().format('YYYY-MM-DD HH:mm:ss')
        ) {
          this.initializeMissedReviewedDatePicker(plan)
          return false
        }

        plan.is_processing = true

        // prepare payload for sending request
        const payload = {
          planId: plan._id,
          status: status,
          approvalUserid: approvalUser.user_id,
          last_action_note: '',
        }
        if (comment && plan.new_comment && plan.new_comment.trim().length > 0) {
          payload.last_action_note = plan.new_comment.trim()
        }

        // approve/reject request send
        const res = await this.$store.dispatch('planApprovalAction', payload)
        if (res && res.data && res.data.status) {
          // next line for old planner
          this.changePlanData(plan._id, res)
          // dispatch to other user for realtime changes
          this.dispatchApprovalSocket(res, plan._id)
          // show Date Picker to schedule draft post after approval
          if (status === 'approve' && res.data.plan_status === 'draft') {
            this.initializeMissedReviewedDatePicker(plan, 'draft')
          } else {
            EventBus.$emit('update-plan-status', {id: plan._id, res})
            EventBus.$emit('update-plans-count')
          }
          plan.is_processing = false
          // this.$router.push({name: this.$route.name, query: this.$route.query})
          return true
        } else {
          this.$store.dispatch('toastNotification', {
            message: 'Unable to perform this action.',
            type: 'error',
          })
          plan.is_processing = false
          return false
        }
      }
    },

    /**
     *  change approval plan data after approve or reject plan
     * @param res
     * @returns null
     */
    changeApprovalPlanData(res) {
      this.getApprovalData.plan.execution_time = res.data.execution_time
      this.getApprovalData.plan.status = res.data.plan_status

      switch (res.data.plan_status) {
        case 'scheduled':
          this.getApprovalData.plan.post_state = 'scheduled'
          this.getApprovalData.plan.render_class.icon = 'far fa-clock'
          this.getApprovalData.plan.render_class.tooltip = 'Scheduled'
          break
        case 'rejected':
          this.getApprovalData.plan.post_state = 'rejected'
          this.getApprovalData.plan.render_class.icon = 'far fa-times-circle'
          this.getApprovalData.plan.render_class.tooltip = 'Rejected'
          break
        case 'review':
          if (
            res.data.execution_time.date <
            moment.utc().format('YYYY-MM-DD HH:mm:ss')
          ) {
            this.getApprovalData.plan.post_state = 'missedReview'
            this.getApprovalData.plan.render_class.icon = 'far fa-eye-slash'
            this.getApprovalData.plan.render_class.tooltip = 'Missed review'
          } else {
            this.getApprovalData.plan.post_state = 'reviewed'
            this.getApprovalData.plan.render_class.icon = 'far fa-eye'
            this.getApprovalData.plan.render_class.tooltip = 'In review'
          }
          break
        case 'draft':
          this.getApprovalData.plan.post_state = 'draft'
          this.getApprovalData.plan.render_class.icon = 'far fa-folder-plus'
          this.getApprovalData.plan.render_class.tooltip = 'Draft'
          break
      }
    },

    /**
     *  change plan data after approve or reject plan
     * @param planId
     * @param res
     * @returns null
     */
    changePlanData(planId, res) {
      const planData = this.getPlans.items.find((item) => item._id === planId)
      if (planData) {
        if (res.data.data) planData.approval = res.data.data
        planData.execution_time = res.data.execution_time
        planData.status = res.data.plan_status

        switch (planData.status) {
          case 'scheduled':
            planData.post_state = 'scheduled'
            planData.render_class.icon = 'far fa-clock'
            planData.render_class.tooltip = 'Scheduled'
            break
          case 'rejected':
            planData.post_state = 'rejected'
            planData.render_class.icon = 'far fa-times-circle'
            planData.render_class.tooltip = 'Rejected'
            break
          case 'review':
            if (
              planData.execution_time.date <
              moment.utc().format('YYYY-MM-DD HH:mm:ss')
            ) {
              planData.post_state = 'missedReview'
              planData.render_class.icon = 'far fa-eye-slash'
              planData.render_class.tooltip = 'Missed review'
            } else {
              planData.post_state = 'reviewed'
              planData.render_class.icon = 'far fa-eye'
              planData.render_class.tooltip = 'In review'
            }
            break
          case 'draft':
            planData.post_state = 'draft'
            planData.render_class.icon = 'far fa-folder-plus'
            planData.render_class.tooltip = 'Draft'
            break
        }
        const statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : [
              'scheduled',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft',
            ]
        if (
          res.data.plan_status === 'scheduled' &&
          !statuses.includes('scheduled')
        ) {
          this.$store.dispatch('toastNotification', {
            message: PLAN_APPROVAL_SUCCESS,
            type: 'success',
          })
          // eslint-disable-next-line no-undef
          $('#' + planId).fadeOut(250, (e) => {
            // eslint-disable-next-line no-undef
            $(this).hide()
          })
        }
        if (
          res.data.plan_status === 'rejected' &&
          !statuses.includes('rejected')
        ) {
          this.$store.dispatch('toastNotification', {
            message: PLAN_REJECT_SUCCESS,
            type: 'success',
          })
          // eslint-disable-next-line no-undef
          $('#' + planId).fadeOut(250, (e) => {
            // eslint-disable-next-line no-undef
            $(this).hide()
          })
        }
      }
    },

    initializeMissedReviewedDatePicker(plan, type = 'approval') {
      if (type === 'draft') {
        const publishTime = plan.publish_time_options
        publishTime.post_date = moment
          .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .format('MMMM DD, YYYY HH:mm')
        this.setPublishTimeSelection(publishTime)
      } else this.setPublishTimeSelection(null)
      this.$store.commit(
        publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE,
        plan.content_category_id
      )
      plan.content_category_id && plan.content_category_id.length > 3
        ? this.setPublishingTimeType('content_category')
        : plan.queued
        ? this.setPublishingTimeType('queued')
        : this.setPublishingTimeType('schedule')
      // this.setPublishingTimeType('schedule')
      this.$store.commit(approvalStages.SET_APPROVAL_PLAN, plan)
      this.$store.commit(approvalStages.SET_APPROVAL_TYPE, type)

      this.$bvModal.show('publishingTimeFeedView')
    },

    mentionedUserIdsList(comment) {
      var regex = /@[A-Za-z]*/g
      let mentionedUsersIds = []
      const mentionedUsers = comment.match(regex)
      if (mentionedUsers) {
        // filter the name and after that get the value by index.
        mentionedUsers.filter((name, index) => {
          const mentionedId = this.getActiveWorkspaceMembersName.indexOf(
            name.substr(1)
          )
          mentionedUsersIds.push(this.getActiveWorkspaceMembersIds[mentionedId])
        })
        // push the keys to the array
        mentionedUsersIds = Array.from(new Set(mentionedUsersIds))
      }
      return mentionedUsersIds
    },

    /**
     * dispatch realtime after someone added comment
     * @param comment
     * @param planId
     * @param type
     * @returns null
     */
    dispatchCommentSocket(comment, planId, type) {
      const stateObject = this
      this.getWorkspaces.activeWorkspace.members.forEach((workspaceMember) => {
        if (workspaceMember.status === 'joined' && workspaceMember.user) {
          console.debug(workspaceMember.user_id)
          const payload = {
            comment: comment,
            planId: planId,
            workspace_id:
              stateObject.$store.getters.getWorkspaces.activeWorkspace._id,
            user_id: workspaceMember.user_id,
            type: type,
          }
          window.webSockets.emit('feed_comment', payload)
          // stateObject.emitSocket('feed_comment', payload)
        }
      })
    },

    /**
     * dispacth realtime after someone approve/ reject plan
     * @param data
     * @param planId
     * @returns null
     */
    dispatchApprovalSocket(data, planId) {
      const stateObject = this
      this.getWorkspaces.activeWorkspace.members.forEach((workspaceMember) => {
        if (workspaceMember.status === 'joined' && workspaceMember.user) {
          const payload = {
            data: data,
            planId: planId,
            workspace_id:
              stateObject.$store.getters.getWorkspaces.activeWorkspace._id,
            user_id: workspaceMember.user_id,
          }
          // stateObject.emitSocket('feed_approval', payload)
          window.webSockets.emit('feed_approval', payload)
        }
      })
    },

    // pusher codebase

    bindPusherComments() {
      console.log('METHOD::bindPusherComments')
      this.channel.bind('store_comment', (data) => {
        console.log('pusher comment feed view', data)

        // iterating over the plan items
        const plan = this.getPlans.items.find(
          (item) => item._id === data.plan_id
        )
        console.log('pusher comment feed view -> plan', plan)
        if (plan) {
          if (plan.comments) {
            console.log('pusher comment feed view -> comments are available')
            if (plan.comments.length > 0) {
              console.log(
                'pusher comment feed view -> comments length is greater than 0'
              )
              // if comments are available, check out their length.
              const comment = plan.comments.find(
                (commentItem) => commentItem._id === data._id
              )
              console.log(
                'pusher comment feed view -> finding comment, if it is available or not',
                comment
              )
              if (!comment) {
                console.log(
                  'pusher comment feed view -> pushing comment to an array'
                )
                plan.comments.unshift(data)
                console.log(
                  'pusher comment feed view -> plan comments',
                  plan.comments
                )
              } else {
                const commentItemIndex = plan.comments.findIndex(
                  (commentItem) => commentItem._id === data._id
                )
                if (commentItemIndex >= 0) {
                  plan.comments.splice(commentItemIndex, 1, data)
                }
              }
            } else {
              // if no comments available
              console.log(
                'pusher comment feed view -> comments length is 0, pushing data to array'
              )
              plan.comments.unshift(data)
              console.log(
                'pusher comment feed view -> plan comments',
                plan.comments
              )
            }
          }
          console.log(plan)
        }
      })
      this.channel.bind('delete_comment', (data) => {
        console.log('pusher comment feed view delete action', data)
        const plan = this.getPlans.items.find(
          (item) => item._id === data.plan_id
        )
        if (plan) {
          const getCommentIndex = plan.comments.findIndex(
            (commentItem) => commentItem._id === data._id
          )
          if (getCommentIndex >= 0) {
            plan.comments.splice(getCommentIndex, 1)
          }
        }
      })
    },
  },
}
