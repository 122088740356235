<template>
  <div id="ckeditor-wrapper" class="ckeditor-wrapper">
    <div class="editor_loader_area py-5" v-show="editorLoaderStatus">
      <clip-loader :color="'#4165ed'" :size="'16px'"></clip-loader>
    </div>

    <ckeditor
      v-show="!editorLoaderStatus"
      v-on:ready="handleReady"
      :editor="build"
      v-model="editorData"
      :config="editorConfiguration"
    ></ckeditor>
    <div
      v-show="!editorLoaderStatus"
      class="ckeditor-count d-flex justify-content-end p-2"
    >
      <div class="mr-2"> Characters: {{ wordCount.characters }} </div>
      <div> Words: {{ wordCount.words }} </div>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@seeratawan01/ckeditor5-custom-build'
import { mapGetters } from 'vuex'
import { blogPosting } from '../../../publish/store/states/mutation-types'
import { storageBaseUrl } from '../../../../config/api-utils'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'app',

  data() {
    return {
      build: ClassicEditor,
      editorData: '',
      editor: null,
      editorLoaderStatus: true,
      wordCount: {
        characters: 0,
        words: 0,
      },
    }
  },
  components: {
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapGetters(['getJWTToken', 'getWorkspaces', 'getBlogPostingHTML']),
    editorConfiguration() {
      const self = this
      const IFRAME_SRC = 'https://cdn.iframe.ly/api/iframe'
      const API_KEY = 'e40f048005f7c337ac3aaae8e31df0cb'

      return {
        autosave: {
          waitingTime: 5000,
          save(editor) {
            return self.autoSave(editor.getData())
          },
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: storageBaseUrl + 'media_library/assets/blogImageUpload',

          // // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,
          //
          // // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            'X-WORKSPACE-ID': this.getWorkspaces.activeWorkspace._id,
            Authorization: 'Bearer ' + this.getJWTToken,
          },
        },
        wordCount: {
          onUpdate: (stats) => {
            // Prints the current content statistics.
            self.wordCount.characters = stats.characters
            self.wordCount.words = stats.words

            console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`)
             this.$store.commit(blogPosting.SET_WORDS_COUNT,stats.words)
          }
        },
        mediaLibrary: {
          callback: () => {
            this.showInsertFileModel('BlogEditorFile')
          },
        },
        // Configure 'mediaEmbed' with Iframely previews.
        mediaEmbed: {
          // Previews are always enabled if there’s a provider for a URL (below regex catches all URLs)
          // By default `previewsInData` are disabled, but let’s set it to `false` explicitely to be sure
          previewsInData: false,

          providers: [
            {
              // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
              name: 'iframely previews',

              // Match all URLs or just the ones you need:
              url: /.+/,

              html: (match) => {
                const url = match[0]

                var iframeUrl =
                  IFRAME_SRC +
                  '?app=1&key=' +
                  API_KEY +
                  '&url=' +
                  encodeURIComponent(url)
                // alternatively, use &key= instead of &api_key with the MD5 hash of your api_key
                // more about it: https://iframely.com/docs/allow-origins

                return (
                  // If you need, set maxwidth and other styles for 'iframely-embed' class - it's yours to customize
                  '<div class="iframely-embed">' +
                  '<div class="iframely-responsive">' +
                  `<iframe src="${iframeUrl}" ` +
                  'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                  '</iframe>' +
                  '</div>' +
                  '</div>'
                )
              },
            },
          ],
        },
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   let t = document.createElement('template')
    //   t.innerHTML = this.getBlogPostingHTML
    //
    //   if (t.innerHTML) {
    //     console.log('mounted innerHTML', t.content.firstChild.innerHTML)
    //     this.editorData = String(t.content.firstChild.innerHTML)
    //   }
    // })

    EventBus.$on('blog-keyword', (keyword) => { this.insertTag(keyword) });
    EventBus.$on('blog-Questions', (questions) => { this.insertQuestions(questions) });


    if (document.getElementById('iframeScript')) {
      return
    }
    const src =
      'https://cdn.iframe.ly/embed.js?key=e40f048005f7c337ac3aaae8e31df0cb'
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('charset', 'utf-8')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'iframeScript')
    document.head.appendChild(script)

    EventBus.$on('insert-image-to-blog', ({link, type = 'image'}) => {
      console.log('insert-image-to-blog', link)

      this.editor.model.change((writer) => {
        if (type === 'image') {
          const imageElement = writer.createElement('imageBlock', {
            src: link,
          })

          this.editor.model.insertContent(
            imageElement,
            this.editor.model.document.selection
          )
        } else {
          // const mediaElement = writer.createElement('iframe', { src: link })
          //
          // this.editor.model.insertContent(mediaElement, this.editor.model.document.selection)
        }
      })

      // editor.model.change(writer => {
      //   const insertPosition = editor.model.document.selection.getFirstPosition()
      //   writer.insertText('foo', insertPosition)
      // })
    })
  },
  beforeDestroy() {
    const el = document.getElementById('iframeScript')
    if (el) {
      el.remove()
    }
    EventBus.$off('blog-keyword')
    EventBus.$off('blog-Questions')
  },
  watch: {
    editorData(val) {
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_HTML,
        `<div class='cs-blog-content'>${val}</div>`
      )
    },

    getBlogPostingHTML(val) {
      if (val !== `<div class='cs-blog-content'>${this.editorData}</div>`) {
        this.editorData = val
      }
    },
  },
  methods: {
    autoSave(data) {
      this.blogPostAutoSave()
      this.$emit('autosave', data)
    },
    insertTag(keyword='') {
      const model = this.editor.model;
      let text= " " + keyword.trim()
      const selection = this.editor.model.document.selection;
      model.change( writer => {
     const currentAttributes = model.document.selection.getAttributes();
      const insertPosition = selection.focus;
    model.insertContent(writer.createText( text, currentAttributes ,insertPosition));
} );
  },
     insertQuestions(questions='') {
      const model = this.editor.model;
      let htmlString = `<br><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">${questions}</span>`
      // Convert an HTML string to a view document fragment:
      const viewFragment = this.editor.data.processor.toView( htmlString );
      const modelFragment = this.editor.data.toModel( viewFragment );
      model.insertContent(modelFragment);
  },
    handleReady(editor) {
      console.log('Method::handleReady', this.getBlogPostingHTML)
      this.editor = editor

      this.editorLoaderStatus = false
      // this.editorData = 'Test'
      // setTimeout(() => {
      //   this.editorData = "<div class='cs-blog-content'><p>1235</p></div>"
      // }, 2000)

      // console.log('handleReady', editor, this.$_instance)
      //
      // if (this.editor) {
      //   this.editor.model.change(writer => {
      //     let element = writer.createElement('paragraph')
      //     this.editor.model.insertContent(element, 'ssss')
      //   })
      // }

      this.editor.editing.view.document.on('drop', () => {
        console.log('drop')
      })

      this.editor.editing.view.document.on('dragover', () => {
        console.log('dragover')
      })
    },
  },
}
</script>
<style lang="scss">
.ckeditor-wrapper {
  box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
}
.editor__editable,
main .ck-editor[role='application'] .ck.ck-content,
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
.ck.editor[role='textbox'] {
  width: 100%;
  background: #fff;
  font-size: 1rem;
  line-height: 1.6em;
  min-height: 400px;
  padding: 1.5rem 2rem;

  ul,
  ol {
    padding-left: 2rem;
  }

  ul:not(.todo-list) {
    li {
      list-style: disc;
    }
  }

  ol:not(.todo-list) {
    li {
      list-style: decimal;
    }
  }
}

.ck.ck-balloon-panel {
  z-index: 99999 !important;
}

.ckeditor-count {
  border: 1px solid #c4c4c4;
  border-top: 0;
  background: #fff;
  //box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ck .ck-link_selected,
.ck-content a {
  pointer-events: none;
}
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: -350px;
}
 @media only screen and (max-width: 1366px) {
      .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    left: -70px !important;
}
 }
</style>
