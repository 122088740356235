<template>
  <div
    class="article-grid-view-container__inner__content_block__social_statistics__inner__item"
  >
    <div class="name">
      <p>Sentiment</p>
    </div>
    <div class="value" v-if="sentiment && sentiment.length > 0">
      <i
        class="far fa-frown sad"
        v-tooltip="'Negative'"
        v-if="sentiment[0].label == 'negative'"
      >
      </i>
      <i
        class="far fa-smile smile"
        v-tooltip="'Positive'"
        v-if="
          sentiment[0].label == 'positive' && sentiment[0].probability >= 0.65
        "
      >
      </i>
      <i
        class="far fa-meh normal"
        v-tooltip="'Neutral'"
        v-else-if="
          sentiment[0].label == 'positive' && sentiment[0].probability < 0.65
        "
      >
      </i>
      <i
        class="far fa-meh normal"
        v-tooltip="'Neutral'"
        v-if="sentiment[0].label == 'neutral'"
      >
      </i>
    </div>
    <div class="value" v-else>
      <i class="far fa-meh normal" v-tooltip="'Neutral'"> </i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sentiment: {}
  }
}
</script>
