<template>
  <div class="workspace_component">
    <div class="component_inner">
      <router-link class="add_icon" :to="{ name: 'workspaces' }">
        <button type="button" class="close_icon float-right">&times;</button>
      </router-link>

      <div class="create_workspace">
        <div class="w_top_heading">
          <h2>New Workspace</h2>
          <p
            >A workspace is a place where you keep all of your content, accounts
            and teams for one brand in one single place. So, create a different
            workspace for each brand or client you have.</p
          >
        </div>

        <div class="box_inner">
          <workspace-fields></workspace-fields>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import workspaceFields from './WorkspaceFields'
export default {
  components: {
    workspaceFields
  },

  data () {
    return {}
  },
  computed: {},

  created () {},
  mounted () {},

  methods: {}
}
</script>
