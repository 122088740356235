<template>
  <div class="box">
    <div class="box_inner d-flex align-items-center">
      <div class="text">
        <h4 class="perform_top_text">{{ title }} </h4>
        <div>
          <h2>
            {{ newValue
            }}<span :class="{ 'brown-text': getGrowth() < 0 }"
              >{{ Math.abs(getGrowth()) }}%</span
            >
            <img
              v-if="getGrowth() >= 0"
              src="../../../../../assets/img/analytics/up_arrow.svg"
            />
            <img v-else src="../../../../../assets/img/analytics/up_down.svg" />
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: '',
    newValue: '',
    oldValue: ''
  },
  data: function () {
    return {
      growth: 0
    }
  },

  computed: {},
  methods: {
    getGrowth: function () {
      const previous =
        this.oldValue === 0 || !this.oldValue ? this.newValue : this.oldValue
      this.growth =
        previous === 0
          ? 0
          : (((this.newValue - previous) / previous) * 100).toFixed(2)

      return this.growth
    },
    isGrowthPostivegrowth () {
      return '../../../../../app/src/assets/img/analytics/' + this.growth >= 0
        ? 'up_arrow.svg'
        : 'up_down.svg'
    }
  }
}
</script>

<style scoped></style>
