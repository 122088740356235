<template>
  <b-modal
    size="xl"
    id="asset-preview-modal"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <template slot="modal-title"> Media details </template>
    <div class="modal-content">
      <div class="grid-flex">
        <div class="cell-md-6 text-center">
          <video v-if="isVideo(item['mime_type'])" controls>
            <source :src="item.link" :type="item['mime_type']" />
            Your browser does not support the video tag.
          </video>

          <img v-else class="img-fluid" :src="item.link" :alt="item.name" />
        </div>

        <div class="cell-md-6">
          <ul class="list-unstyled">
            <li v-if="item.name">
              <b>File Name: </b>{{ truncateString(item.name, 40) }}
            </li>
            <li v-if="item.mime_type">
              <b>File type: </b>{{ item.mime_type }}
            </li>
            <li v-if="updatedAt"> <b>Uploaded at: </b>{{ updatedAt }} </li>
            <li v-if="item.size">
              <b>File size: </b>{{ bytesToSize(item.size) }}
            </li>
            <li v-if="item.source" class="text-capitalize">
              <b>Source: </b>{{ item.source }}
            </li>
            <li v-if="item.h && item.w">
              <b>Dimensions: </b> {{ item.w }} x {{ item.h }} pixels
            </li>
          </ul>

          <!--         <div class="simple-form__text-field pl-0">-->
          <!--           <input type="text" placeholder="Title" >-->
          <!--         </div>-->

          <div class="d-flex">
            <div class="simple-form__text-field pl-0 flex-grow-1">
              <input
                id="asset-link"
                type="text"
                placeholder="Link"
                :value="item.link"
                readonly
              />
            </div>

            <div class="simple-form__text-field pl-0 ml-3">
              <button
                class="icon-btn"
                @click="copyLink(item.link)"
                v-b-tooltip.hover
                :title="!copied ? 'Copy to clipboard' : 'Copied'"
              >
                <i class="icon-link_icon"></i>
              </button>
            </div>

            <div class="simple-form__text-field pl-0 ml-3">
              <button
                class="icon-btn icon-btn--danger"
                v-b-tooltip.hover
                :title="isMediaDeleting ? 'Removing the Asset' : 'Remove Asset'"
                @click.prevent="deleteItem"
                :disabled="isMediaDeleting"
              >
                <clip-loader
                  v-if="isMediaDeleting"
                  class="spinner"
                  :color="'#ffffff'"
                  :size="'16px'"
                ></clip-loader>
                <i v-else class="far fa-trash-alt"></i>
              </button>

              <!--             <button class="btn btn-studio-theme-transparent-red" @click.prevent="deleteItem" :disabled="isMediaDeleting">-->
              <!--               <i class="far fa-trash-alt"></i>-->
              <!--               <clip-loader v-if="isMediaDeleting" class="spinner ml-2" :color="'#adacac'" :size="'16px'"></clip-loader>-->
              <!--             </button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'AssetPreviewModal',
  data () {
    return {
      item: {
        _id: '',
        name: '',
        mime_type: '',
        size: 0,
        updated_at: null,
        link: ''
      },
      copied: false
    }
  },
  props: ['isMediaDeleting'],

  computed: {
    updatedAt () {
      return this.item.updated_at
        ? this.getWorkspaceTimeZoneTime(
          this.item.created_at,
          'DD-MM-YYYY hh:mm A'
        )
        : ''
    }
  },
  methods: {
    isVideo (mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },

    deleteItem () {
      console.debug('deleteItem')
      this.$emit('delete', this.item._id, this.item.link)
    },

    copyLink (txt) {
      console.debug(txt)
      window.getSelection().removeAllRanges()

      const copyText = document.querySelector('#asset-link')

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      try {
        document.execCommand('copy')
        this.copied = true
      } catch (err) {}

      /* unselect the range */
      // testingCodeToCopy.setAttribute('type', 'hidden')
      // window.getSelection().removeAllRanges()

      this.copied = true
    }
  },
  mounted () {
    // Clear Data on modal hide
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'asset-preview-modal') {
        this.item = {
          _id: '',
          name: '',
          mime_type: '',
          size: 0,
          updated_at: null,
          link: ''
        }
        this.copied = false
      }
    })

    EventBus.$on('open-media-preview', (item) => {
      this.item = item
      this.$bvModal.show('asset-preview-modal')
    })
  }
}
</script>
