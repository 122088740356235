<script>
import { mapGetters, mapActions } from 'vuex'
import textHelper from '@common/lib/text-helper'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {},

  data () {
    return {
      accounts: [],
      type: null,
      email: null,
      start_date: null,
      end_date: null,
      email_list: []
    }
  },

  created () {},
  computed: {
    ...mapGetters(['getActiveWorkspace'])
  },
  mounted () {
    EventBus.$on(
      'send-report-by-email',
      ({accounts, network, startDate, endDate}) => {
        this.accounts = accounts
        this.type = network
        this.start_date = startDate
        this.end_date = endDate
      }
    )
  },

  methods: {
    ...mapActions(['saveReportsService']),
    addEmailToList (event) {
      console.log(event)
      let email = this.email
      // check if it is a comma, then substring last element which will be a comma
      if (event.key === ',') {
        email = email.substring(0, email.length - 1)
      }

      // validate email address

      if (this.validateEmail(email)) {
        // if it is a valid one push to the list and empty the v-model value
        if (!this.email_list.includes(email)) {
          // not a duplicate email
          this.email_list.push(email)
          this.email = null
          console.log('Valid email address pushed to the reports list.')
        } else {
          this.$store.dispatch('toastNotification', {
            message: 'Email address already added.',
            type: 'error'
          })
        }
      }
    },
    removeEmailFromList (index) {
      this.email_list.splice(index, 1)
    },
    async sendEmailReport () {
      if (this.email_list.length === 0) {
        this.$store.dispatch('toastNotification', {
          message:
            'Please add email address where we should be sending the report.',
          type: 'error'
        })
        return
      }
      const date = textHelper.onlyDateStringIntlTime(
        `${this.start_date} - ${this.end_date}`
      )
      const fileName =
        textHelper.ucFirst(this.type) +
        ` Report (${textHelper.ucFirst(
          this.getActiveWorkspace.slug
        )}) - ${date}.pdf`
      const res = await this.saveReportsService({
        date: `${this.start_date} - ${this.end_date}`,
        accounts: this.accounts,
        type: this.type,
        action: 'email',
        email_list: this.email_list,
        file_name: fileName
      })
    }
  }
}
</script>

<template>
  <div class="modal fade sendPDFmodal normal_modal" id="sendReportByEmail">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Send PDF</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form m_t_15">
          <div class="input_field mt-0">
            <div class="multi_select">
              <label for="">Email To</label>
              <div class="multi_input">
                <input
                  type="text"
                  placeholder="Enter up to 25 email address..."
                  v-model="email"
                  @keyup.enter="addEmailToList($event)"
                  v-on:keyup.188="addEmailToList($event)"
                />
                <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
              </div>
              <div class="multi_tags">
                <ul>
                  <li class="green" v-for="(item, key) in email_list">
                    <span class="value">{{ item }}</span>
                    <span class="remove" @click="removeEmailFromList(key)"
                      >&times;</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="field_group mt-4">
            <button
              @click="sendEmailReport()"
              class="btn btn-studio-theme-space"
            >
              <span>Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
