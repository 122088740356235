import { authenticationBaseUrl } from '@src/config/api-utils'
import axios from 'axios'

/**
 * Service to log in user
 * @param {object} payload - payload to be sent
 */
export const login = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authenticationBaseUrl}login`, payload, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response?.data?.status) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) reject(err.response.data)
        else reject(err)
      })
  })
}

/**
 * Service to submit authentication code
 * @param {object} payload - payload to be sent
 * @param {boolean} backup - flag to check if backup code is used
 */
export const verify2FACode = (payload, backup = false) => {
  const apiUrl = backup
    ? `${authenticationBaseUrl}2fa/validator/backup_codes`
    : `${authenticationBaseUrl}2fa/validator/google`
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl, payload, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response?.data?.status) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) reject(err.response.data)
        else reject(err)
      })
  })
}
