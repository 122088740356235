<template>
  <b-modal
    id="influencerVideoPreview"
    ref="refInfluencerVideoPreview"
    modal-class="video_preview normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Video Preview</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('influencerVideoPreview')"
        >&times;</button
      >
    </div>

    <div class="modal_body" v-if="!notFound">
      <div v-if="loader" class="p-3">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
      <div v-else class="instagram_post_ifram video_box_iframe">
        <!--              <video width="320" height="240" controls>-->
        <!--                <source src="asset_url" type="video/mp4">-->
        <!--                Your browser does not support the video tag.-->
        <!--              </video>-->

        <b-embed
          type="iframe"
          aspect="16by9"
          :src="asset_url"
          allowfullscreen
        ></b-embed>

        <!--<iframe v-if="getVideoPreview.heightRatio" :height="getVideoPreview.heightRatio" :src="getVideoPreview.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>-->
      </div>
    </div>
    <div v-else class="text-center py-3">
      Media not found, the link may be broken, or the media may have been
      removed.
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    videoValue: {
      default: null
    },
    videoHeight: {
      default: null
    },
    videoWidth: {
      default: null
    },
    loader: {
      default: null
    },
    notFound: {
      default: false
    },
    asset_url: {
      default: null
    }
  },
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions([])
  }
}
</script>
