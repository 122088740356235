import {
  getDefaultSharingProgressBar,
  getDefaultVideoSharingDetails,
  linkedinSharingTypes
} from '../mutation-types'

const getDefaultLinkedinSharingDetails = () => {
  return {
    message: '',
    title: '',
    description: '',
    image: [],
    video: getDefaultVideoSharingDetails(),
    url: '',
    website: '',
    image_suggestions: []
  }
}

export default {
  state: {
    selection: getDefaultLinkedinSharingDetails(),
    cancelledLink: '',
    progressBar: getDefaultSharingProgressBar(),
    loaders: {
      upload_sharing_image: { status: false, position: -1 }
    }
  },
  getters: {
    getLinkedinSharingDetails: (state) => state.selection,
    getLinkedinSharingCancelledLink: (state) => state.cancelledLink,
    getLinkedinSharingProgressBar: (state) => state.progressBar,
    getLinkedinSharingLoaders: (state) => state.loaders
  },
  actions: {},
  mutations: {
    [linkedinSharingTypes.SET_SHARING_MESSAGE] (state, message) {
      state.selection.message = message
    },

    [linkedinSharingTypes.SET_SHARING_URL] (state, url) {
      state.selection.url = url
    },

    [linkedinSharingTypes.SET_SHARING_CANCELLED_PREVIEW] (state, link) {
      state.cancelledLink = link
    },

    [linkedinSharingTypes.SET_SHARING_DETAILS] (state, details) {
      state.cancelledLink = ''
      if (details == null) {
        state.selection = getDefaultLinkedinSharingDetails()
        return false
      }
      if (details.image.constructor !== Array) { details.image = details.image.length ? [details.image] : [] }
      state.selection = details
    },

    [linkedinSharingTypes.SET_SHARING_IMAGE_PERCENTAGE] (state, percent) {
      state.progressBar.image = percent
    },

    [linkedinSharingTypes.SET_SHARING_IMAGE] (state, image) {
      state.selection.image = image
    },

    [linkedinSharingTypes.SET_SHARING_NUMBER_OF_IMAGES] (state, images) {
      state.progressBar.numberOfImages = images
    },

    [linkedinSharingTypes.SET_SHARING_PREVIEW] (state, preview) {
      if (preview) {
        state.selection.title = preview.title
        state.selection.description = preview.description
        state.selection.website = preview.website
        state.selection.image = preview.image
        state.selection.image_suggestions = preview.image_suggestions
        return false
      }
      state.selection.title = ''
      state.selection.description = ''
      state.selection.url = ''
      state.selection.website = ''
    },

    [linkedinSharingTypes.SET_SHARING_VIDEO] (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultVideoSharingDetails()
      }
    },

    [linkedinSharingTypes.SET_SHARING_VIDEO_INITIALIZATION] (state) {
      state.selection.title = ''
      state.selection.description = ''
      state.selection.url = ''
      state.selection.website = ''
      state.selection.image = []
      state.selection.image_suggestions = []
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    [linkedinSharingTypes.SET_SHARING_VIDEO_PERCENTAGE] (state, percent) {
      state.progressBar.video = percent
    },

    [linkedinSharingTypes.SET_SHARING_VIDEO_THUMBNAIL] (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    [linkedinSharingTypes.SET_SHARING_VIDEO_SUGGESTIONS] (
      state,
      thumbnailsSuggestions
    ) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },

    [linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER] (state, loader) {
      state.loaders.upload_sharing_image = loader
    },

    [linkedinSharingTypes.SET_DEFAULT_SHARING] (state) {
      state.selection = getDefaultLinkedinSharingDetails()
      state.cancelledLink = ''
      state.progressBar = getDefaultSharingProgressBar()
    }
  }
}
