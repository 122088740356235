import { store } from '@src/store/base'

/***************************
 * !!!!!!!!!!!!!!!!!!!!!! CHANGING NAME OF ANY ROUTE CAN EFFECT "App.vue" PAGE CONDITIONS  !!!!!!!!!!!!!!!!!!!!!!!
 *****************************/

const onboardingMain = () =>
  import('../../../account/views/onboarding/OnBoardingMain')
const CreateWorkspace = () =>
  import('../../../account/views/onboarding/CreateWorkspaces')


export const onboardingRoutes = {
  path: '/onboarding',
  name: 'onboardingMain',
  redirect: '/onboarding/create-workspace',
  component: onboardingMain,
  meta: {
    title: 'Welcome',
    no_authentication: true
  },
  children: [

    {
      path: 'create-workspace/',
      name: 'onboardingWorkspace',
      component: CreateWorkspace,
      meta: {
        title: 'Create Workspace | ContentStudio',
        no_authentication: true
      }
    },
  ]
}
