<template>
  <div
    id="common_sharing_box"
    class="social_content_box"
    style="border-radius: 10px"
    :class="{ editor_hover: isFileHover }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Common' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Common' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Common' })"
  >
    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div
      v-if="getAccountSelection.pinterest.length"
      class="d-flex align-items-center"
    >
      <b-form-input
        v-model="getCommonSharingDetails.pinterest_title"
        :state="
          getCommonSharingDetails.pinterest_title.length <= 100 ? null : false
        "
        class="pinterest_title px-0 mx-2"
        placeholder="Pinterest Post Title..."
        @click.native="focusSharingBox('Pinterest')"
      ></b-form-input>
    </div>

    <div class="text_content text_content_main">
      <textarea-autosize
        id="common_sharing_message"
        ref="common_sharing_message"
        class="content_textarea"
        :min-height="90"
        spellcheck="false"
        data-cy="textarea-composer"
        @input.exact="handleRerender"
        @keydown.native.exact="handleKeysOnPopup"
        @input.native.exact="sharingMessageChanges($event, 'Common')"
        @paste.native.exact="sharingMessageChanges($event, 'Common')"
        @keyup.space.native.exact="sharingMessageChanges($event, 'Common')"
        @keydown.ctrl.66.native.exact="sharingMessageFormatting('Common', 'bs')"
        @keydown.ctrl.73.native.exact="sharingMessageFormatting('Common', 'is')"
      ></textarea-autosize>

      <div id="clone_txt_common_tab" class="clone_txt">
        <span class="desc"></span><span class="exceed"></span>
      </div>
      <Mention type="Common"></Mention>

      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="d-flex justify-content-end align-items-center">
          <!--          <div class="text_content_bottom">-->
          <!--            <button-->
          <!--              class="btn btn-studio-theme-space caption-btn"-->
          <!--              @click.prevent="$bvModal.show('upgrade-plan-dialog')"-->
          <!--            >-->
          <!--              <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>-->
          <!--              <img-->
          <!--                v-floatingtip.top="{-->
          <!--                  content: getLockBasedOnSubAddonsMessage('caption_generation'),-->
          <!--                  placement: 'top-center',-->
          <!--                }"-->
          <!--                src="@assets/img/common/cyrus-bot.svg"-->
          <!--                alt=""-->
          <!--                class="w-10 h-10"-->
          <!--              />-->
          <!--            </button>-->
          <!--          </div>-->

          <div
            v-if="
              checkValidVariationRoute &&
              getCommonSharingDetails.url &&
              !getPublishLoaders.initializeArticlePostCreation &&
              getTwitterVariations.length > 1
            "
            class="text_content_bottom"
          >
            <button
              class="btn variation_btn border_btn"
              @click.prevent="changeVariation"
            >
              <span>Change Variation</span>
              <span v-if="getTwitterVariations.length">
                ({{ getTwitterVariations.length }})</span
              >
            </button>
          </div>
        </div>
      </template>
    </div>

    <Options
      :type="type"
      :evergreen-box="evergreenBox"
      @fetchAIHashtags="fetchAIHashtags"
    ></Options>

    <clip-loader
      v-if="
        getPublishLoaders.fetchCommonShortLinks ||
        getPublishLoaders.fetchCommonSharingPreview
      "
      class="link_loader"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>
    <template v-else>
      <LinkSection
        v-if="getCommonSharingDetails.url"
        type="Common"
        :details="getCommonSharingDetails"
      ></LinkSection>
      <VideoSection
        v-else-if="
          (getCommonSharingDetails.video &&
            getCommonSharingDetails.video.link) ||
          getPublishLoaders.upload_common_sharing_video
        "
        type="Common"
        :details="getCommonSharingDetails"
      ></VideoSection>
      <ImageSection
        v-else-if="
          getPublishLoaders.uploadCommonSharingImage.status ||
          getCommonSharingDetails.image.length > 0 ||
          (getCommonSharingDetails.image_suggestions &&
            getCommonSharingDetails.image_suggestions.length > 0) ||
          imageUploading
        "
        type="Common"
        :details="getCommonSharingDetails"
        :image-uploading="imageUploading"
      ></ImageSection>
    </template>

    <EditorAIHashtags
      ref="single_AI_hashtags"
      :is-evergreen="true"
      @addHashtag="handleAddHashtags"
      @reGenerate="fetchAIHashtags(true)"
    ></EditorAIHashtags>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getHashtagsFromText } from '@common/lib/helper'
import {
  commonTypes,
  sharingTypes,
} from '@src/modules/publish/store/states/mutation-types'
import EditorAIHashtags from '@src/modules/composer_v2/components/EditorBox/EditorAIHashtags.vue'
import proxy from '@common/lib/http-common'
import { fetchAIHashTags } from '@src/modules/publish/config/api-utils'
import Options from '../options/Options'
import LinkSection from '../sections/LinkSection'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import Mention from '../options/Mention'

export default {
  components: {
    EditorAIHashtags,
    Mention,
    Options,
    ImageSection,
    VideoSection,
    LinkSection,
  },
  props: {
    type: {
      type: String,
      default: 'Common',
    },
    evergreenBox: {
      type: String,
      default: 'true',
    },
    imageUploading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      'getCommonSharingDetails',
      'getPublishLoaders',
      'getCommonBoxOptions',
      'getTwitterVariationsIndex',
      'getTwitterVariations',
      'getSocialSharingSelection',
      'getAccountSelection',
      'getParagraphValue',
      'getArticleValue',
      'getTweetValue',
      'getPinterestOptions',
    ]),
    checkValidVariationRoute() {
      return this.$route.name !== 'saveBulkCsvAutomation'
      // && this.$router.currentRoute.name !== 'calender_plans' &&
      // this.$router.currentRoute.name !== 'list_plans' && this.$router.currentRoute.name !== 'feed_view'
      // && this.$router.currentRoute.name !== 'composerSocial'
    },
    isFileHover() {
      return this.getSocialSharingSelection.drag_over_status.common
    },
  },
  watch: {
    'getCommonSharingDetails.message'(value) {
      this.setSharingBoxHtml(
        this.processSharingBoxHtml(value, 'Common'),
        'Common'
      )
    },
  },
  mounted() {
    this.setCommonboxRef(this.$refs.common_sharing_message)
  },
  beforeDestroy() {
    console.log('reseting caption value...')
    this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)
    // this.resetCaptionValue()
  },
  methods: {
    ...mapActions(['setCommonboxRef']),

    handleKeysOnPopup(e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender(event) {
      console.debug('Method::handleRerender ~ event', event)
      const text = document.getElementById('common_sharing_message').value
      if (!text) {
        const message = this.getCommonSharingDetails.message
        document.getElementById('common_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Common'),
          'Common'
        )
      }
      /* if (this.getCommonSharingDetails.url && getTwitterVariations.length < 1)
        this.fetchComposerVariation(this.getCommonSharingDetails.url); */
    },
    changeVariation() {
      const message = this.getCommonSharingDetails.message
      const tags = getHashtagsFromText(message)
      const links = this.getLinksFromText(message)
      const mentions = this.getTextMentions(message)
      this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      let index = 0
      if (
        this.getTwitterVariationsIndex !==
        this.getTwitterVariations.length - 1
      ) {
        index = this.getTwitterVariationsIndex + 1
      }
      this.$store.commit(commonTypes.SET_ACTIVE_TWITTER_VARIATION_INDEX, index)

      let newMessage = this.getTwitterVariations[index]
      if (mentions) {
        for (const prop in mentions) {
          newMessage = newMessage + ' ' + mentions[prop]
        }
      } // apply mentions
      if (tags) {
        for (const prop in tags) newMessage = newMessage + ' ' + tags[prop]
      } // apply hashtags
      if (links) {
        for (const prop in links) newMessage = newMessage + ' ' + links[prop]
      } // apply links

      this.setSharingMessage('Common', newMessage)
    },
    async fetchAIHashtags(reGenerate = false) {
      if (this.getCommonSharingDetails.message.length < 100) {
        this.alertMessage(
          'To generate hashtags, the content needs to be at least 100 characters long.',
          'info'
        )
        return
      }
      const hashtags = this.$refs.single_AI_hashtags
      hashtags.setLoader(true)
      hashtags.setHashtags([])
      const payload = {
        workspace_id: this.$store.getters.getActiveWorkspace._id,
        prompt: this.getCommonSharingDetails.message,
        regenerate: reGenerate,
      }
      await proxy
        .post(fetchAIHashTags, payload)
        .then((res) => {
          if (!res.data.status) {
            if (res.data.creditFull)
              this.$store.getters.getPlan.used_limits.caption_generation_credit =
                res.data.usedCredits
            this.alertMessage(res.data.message, 'error')
            hashtags.setLoader(false)
            return
          }
          this.$store.getters.getPlan.used_limits.caption_generation_credit =
            res.data.limits.used
          hashtags.setHashtags(res.data.hashtags)
          hashtags.setLoader(false)
        })
        .catch((err) => {
          console.log('FetchAiHashtagsCall', err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
          hashtags.setLoader(false)
        })
    },
    handleAddHashtags(tags, index = 0, isBulk = false) {
      console.log('reached', tags, index, isBulk)
      let newTags = ''
      if (isBulk && tags.length > 0) {
        tags.map((item) => (newTags += ' ' + item))
        this.addSocialSharesTags(newTags, this.type)
        return
      }
      this.addSocialSharesTags(tags, this.type)
    },
    disableScroll() {
      const getScroll = document.getElementById('stopScroll').scrollTop

      document.getElementById('stopScroll').scrollTo(0, getScroll)

      console.log(getScroll)
    },
  },
}
</script>
