<template>
  <div>
    <div class="compare-box" ref="chartWindow">
      <div class="compare-box__header flex">
        <div class="compare-box__platform">
          <i class="fab fa-instagram ins "></i>
        </div>
        {{ charts[type]['title'] }}
        <div class="ml-auto">
          <i
              class="fa fa-info info_hover"
              v-b-popover.hover.html.topleft="charts[type]['description']"
          ></i>
        </div>
      </div>
      <div
          v-if="!state"
          class="opacity-70"
          style="display: flex; justify-content: center"
      >
        <clip-loader
            class="ml-2"
            :color="'#5773fa'"
            :size="'20px'"
        ></clip-loader>
      </div>
      <div class="grid-flex justify-center">
        <div
            :class="competitors_data.length == 1?'col-sm-12 ':(competitors_data.length==2?'col-sm-6':([3,5].includes(competitors_data.length)?'col-sm-4':'col-sm-3'))"
            v-for="(value, idx) in getSortedChartData(competitors_data)" v-if="state" :key="idx">
          <PieInnerChart
              :title="value['name']"
              v-on:mounted="getPieRef"
              :chartRef="value['name'] + idx"
              :data="value['data']"
              :colors="color"
              :seriesName="charts[type]['title']"
              class="flex-center-center"
              style="flex-direction: column"
              :width="responsiveWidth"
              :height="270"
              :thickness="'60%'"
              :titleFontSize="'1.0rem'"
          />
          <div class="force-flex justify_center analytics-pie-legends pie-region">
            <template
                v-for="(obj, i) in setAliasLegends(
                    legends[value['name'] + idx],
                    idx
                  )"
            >
              <div
                  class="analytics-pie-legends__detail-item center-legend"
                  :key="i"
                  :class="obj.y === 0 ? 'disable-legend' : ''"
                  @click="legendClick($event, obj, obj.name !== 'No Data Found')"
              >
                <div class="analytics-pie-legends__detail-label v-metrics">
                      <span
                          :style="{ borderColor: obj.color }"
                          class="analytics-pie-legends__detail-label-icon"
                      ></span>
                  {{ capitalizeFirstLetter(obj.name) }}
                </div>
                <div
                    class="analytics-pie-legends__detail-value"
                    v-if="obj.name !== 'No Data Found'"
                >
                  {{ intToString(obj.y) }}
                </div>
              </div>
            </template>
          </div>
          <hr/>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="less" scoped>
.force-flex {
  display: flex !important;
}
.disable-legend {
  pointer-events: none;
  opacity: 0.7;
  user-select: none;
}

.pie-region {
  margin: auto;
  max-width: 515px;
}

hr {
  width: 54%;
  border-color: #f0f0f0;
}

.center-legend {
  width: fit-content !important;
}
</style>
<script>
import PieInnerChart from '../../../common/Infographics/PieInnerChart'
import {intToString} from '@common/lib/helper'
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import api_ops from "@src/modules/analytics/views/common/competitors/instagram/api_ops";
import {themeColors} from "@src/theme";

export default {
  name: 'competitorPieChart',
  components: {
    PieInnerChart
  },
  mixins: [analyticsUtilsMixin, api_ops],
  props: ['type', 'reload', 'selected_competitors', 'time_period', 'selected_account_id'],
  data() {
    return {
      isActive: false,
      responsiveWidth: 270,
      legends: {},
      color: [
        themeColors.blue[200],
        themeColors.green[400],
        themeColors.purple[400],
        themeColors.pink[500],
        themeColors.orange[500],
        themeColors.red[500],
      ],
      requestCancelPromise: {},
      active_competitors: {},
      competitors_data: [],
      state: false,
      charts: {
        "postType": {
          'title': "Post Type Distribution",
          'description': "<span class=\"font-0-75rem\">The pie-chart display post count on a page distributed by post type. The chart can be helpful in visualizing posts distribution by post type. </span>",
          'promise': this.getIgPostCountByType,
          'placeholder': {"VIDEO": 0, "IMAGE": 0, "CAROUSEL ALBUM": 0},
          'fields': ['VIDEO', 'IMAGE', 'CAROUSEL ALBUM'],
          'data_options': {
            'data': [],
            'length': 3,
            'name': ''
          }
        },
        "userEngagement": {
          'title': "Distribution of User Engagement",
          'description': "<span class=\"font-0-75rem\">The pie-chart display engagement count on a page distributed by engagement type. The chart can be helpful in visualizing engagement distribution by engagement type.</span>",
          'promise': this.getIgEngagementDistribution,
          'placeholder': {"LIKES": 0, "COMMENTS": 0},
          'fields': ['LIKES', 'COMMENTS'],
          'data_options': {
            'data': [],
            'length': 2,
            'name': ''
          }
        }
      }
    }
  },
  watch: {
    reload(newValue, oldValue) {
      this.active_competitors = {}
      this.competitors_data = []
      this.state = false
      this.fetchChartData()
    }
  },
  mounted() {
    this.state = false
    this.getColWidth()
    this.fetchChartData()
  },
  created() {
    window.addEventListener('resize', this.getColWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getColWidth)
  },
  methods: {
    getColWidth(){
      let original = 1491
      let updatedWidth = parseInt(original - parseInt(this.$refs.chartWindow.clientWidth))
      console.log('anyways',updatedWidth)
      if(updatedWidth > 0 ){
        this.responsiveWidth = parseInt(270-(updatedWidth*0.16))
        this.competitors_data = [...this.competitors_data]
      }else{
        this.responsiveWidth = 270
      }
    },
    legendClick(event, point, flag) {
      const element = event.target

      if (element) {
        element.classList.toggle('analytics-pie-legends__detail-item--fade')
      }

      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef(chart, name) {
      const tempObj = {...this.legends}
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    fetchChartData() {

      let our = {}
      let theirs = this.selected_competitors.filter((obj) => {
        if (obj.competitor_id == this.selected_account_id) {
          our = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.display_name > b.display_name ? 1 : -1)
      let competitors = [our, ...theirs]
      competitors.forEach((obj) => {
        if (obj['enabled'] && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj['competitor_id']] = obj
        }
      })

      this.cancelPendingRequest(this.type)
      let ids = Object.keys(this.active_competitors);
      let promises = []
      ids.forEach((id) => {
        let promise = this.charts[this.type]['promise']([id], this.time_period)
        this.requestCancelPromise[this.type].push(promise[0])
        promises.push(promise[1])
        promise[1].then((res) => {
          let placeholder_data = {...this.charts[this.type]['placeholder']}
          let payload = this.processPayload(res.data.data)
          console.log('payday', payload)
          if (payload) {
            this.charts[this.type]['fields'].forEach((key) => {
              console.log('payday', key, payload)
              if (payload.hasOwnProperty(key)) {
                placeholder_data[key] = parseInt(payload[key])
              }
            })
            let dataoptions = {...this.charts[this.type]['data_options']}
            dataoptions['data'] = Object.keys(placeholder_data).map(function (key, index) {
              return [key, placeholder_data[key]]
            })
            dataoptions['name'] = this.trimName(this.active_competitors[id].display_name)
            dataoptions['ig_id'] = id
            this.competitors_data.push({...dataoptions})
          } else {
            let alias = {...this.charts[this.type]['data_options']}
            alias['name'] = this.trimName(this.active_competitors[id].display_name)
            alias['ig_id'] = id
            let empty_data = {...this.charts[this.type]['placeholder']}
            alias['data'] = Object.keys(empty_data).map(function (key, index) {
              return [key, empty_data[key]]
            })
            this.competitors_data.push({...alias})
          }

        })

      })
      Promise.all(promises).then(() => {
        console.log('we process pie chart', this.competitors_data)
        this.state = true
      })

    },
    trimName(name){
      if(name.length > 12){
        return name.substring(0, 9)+"..."
      }
      else{return name}
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
    setAliasLegends(legendList, idx) {
      if (typeof legendList === 'undefined') {
        return legendList
      }
      console.log('okaysss', legendList)
      if (legendList[0].name === 'No Data Found') {
        if (this.competitors_data[idx].length > 0) {
          const aliasLegends = []
          for (let j = 0; j < this.competitors_data[idx].length; j++) {
            aliasLegends.push({
              name: this.competitors_data[idx].data[j][0],
              color: this.color[j],
              y: 0
            })
          }
          legendList = aliasLegends
        }
      }
      return legendList
    },
    processPayload(payload) {
      let formatted = {}
      if (payload.length > 0) {
        if (this.type == 'postType') {
          console.log('i am sad', payload)
          payload.forEach((obj) => {
            obj['post_count'] = obj['post_count'].replace('_',' ')
              formatted[obj['post_count']] = obj['sum_post']
          })
        } else if (this.type == 'userEngagement') {
          formatted = payload[0]
        }

        return formatted
      } else {
        return false
      }
    },
    getSortedChartData(data) {
      let ours = {}
      let theirs = []
      theirs = data.filter((obj) => {
        if (obj['ig_id'] == this.selected_account_id) {
          ours = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.name > b.name ? 1 : -1)
      return [ours, ...theirs]
    }
  }
}
</script>
