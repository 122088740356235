<template>
  <div>
    <beat-loader :color="'#436aff'"></beat-loader>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import proxy from '@common/lib/http-common'
import { verifyEmailURL } from '../../config/api-utils'
import { authenticationTypes } from '../../store/mutation-types'

export default {
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  async created() {
    await this.verifyEmailToken()
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    ...mapActions(['fetchProfile']),
    async verifyEmailToken() {
      console.debug('VerifyEmailToken')
      let verificationToken = ''
      if (this.$route.query && this.$route.query.token) {
        verificationToken = this.$route.query.token
      } else {
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
        this.$router.push({ name: 'login' })
        return
      }
      try {
        const resp = await proxy.post(verifyEmailURL, {
          token: verificationToken,
        })

        // token verified successfully
        if (resp.data.status) {
          this.alertMessage(resp.data.message, 'success')

          await this.asyncUserProfile()
          console.log('profile', this.getProfile)

          if (this.getProfile.onBoarding) {
            await this.isUserOnboarded()
          }
          await this.fetchWorkspaces()
          // await this.$store.dispatch('trackEvent', { event: 'email_verified' })

          if (
            this.getWorkspaces.activeWorkspace &&
            this.getWorkspaces.activeWorkspace.slug
          ) {
            this.$router.push({
              name: 'dashboard',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
          } else {
            // redirect to the wrokspaces page.
            if (this.getProfile.onBoarding === false) {
              console.debug('VerifyEmail: Redirecting to workspaces')
              this.$router.push({ name: 'workspaces' })
            }
          }
        } else {
          this.alertMessage(resp.data.message, 'error')
          this.$router.push({ name: 'email_verification' })
        }
      } catch (e) {
        if (e.response.status === 401) {
          if (e.response.data && e.response.data.message)
            this.alertMessage(e.response.data.message, 'success')
          this.$router.push({ name: 'login' })
        }
      }
    },
  },
}
</script>
