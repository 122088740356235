<template>
  <div class="article_search_curated_topics">
    <div
      class="article_search_curated_topics__block"
      v-for="explore_item in getCuratedTopicsList"
    >
      <div class="article_search_curated_topics__block__head">
        <h3 v-if="explore_item.heading">{{ explore_item.heading }}</h3>
        <h4>{{ explore_item.sub_heading }}</h4>
      </div>
      <div class="article_search_curated_topics__block__list">
        <template v-if="!explore_item.show_all">
          <div
            class="article_search_curated_topics__block__list__item"
            :key="'story_' + index"
            v-for="(story, index) in explore_item.list"
            v-if="index < 4"
          >
            <div
              class="article_search_curated_topics__block__list__item__inner"
            >
              <div
                class="article_search_curated_topics__block__list__item__inner__content"
                @click.prevent="searchOnCuratedTopic(story)"
              >
                <div
                  class="article_search_curated_topics__block__list__item__inner__image"
                  v-lazy:background-image="story.picture"
                ></div>
              </div>
              <div
                class="article_search_curated_topics__block__list__item__inner__button"
              >
                <p
                  @click.prevent="searchOnCuratedTopic(story)"
                  :data-cy="'discovery_search_topic_' + story.id"
                  >{{ story.name }}</p
                >
                <button
                  v-if="story.followed"
                  @click.prevent="
                    unfollowCuratedTopic({ topic: story, index: null })
                  "
                  class="btn article_search_curated_topics__block__list__item__inner__button__unfollow"
                >
                  <i class="cs-checked"></i>
                  <i class="unfollow_icon cs-cross"></i>
                  <span>Following</span>
                  <span class="unfollow_text">Unfollow</span>
                </button>
                <button
                  v-else
                  @click.prevent="handleFollowCuratedTopic({ topic: story })"
                  class="btn article_search_curated_topics__block__list__item__inner__button__follow"
                >
                  <span>Follow</span>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="article_search_curated_topics__block__list__item"
            v-for="(story, index) in explore_item.list"
          >
            <div
              class="article_search_curated_topics__block__list__item__inner"
            >
              <div
                class="article_search_curated_topics__block__list__item__inner__content"
                @click.prevent="searchOnCuratedTopic(story)"
              >
                <div
                  class="article_search_curated_topics__block__list__item__inner__image"
                  v-lazy:background-image="story.picture"
                ></div>
              </div>
              <div
                class="article_search_curated_topics__block__list__item__inner__button"
              >
                <p
                  @click.prevent="searchOnCuratedTopic(story)"
                  :data-cy="'discovery_search_topic_' + story.id"
                  >{{ story.name }}</p
                >
                <button
                  v-if="story.followed"
                  @click.prevent="
                    unfollowCuratedTopic({ topic: story, index: null })
                  "
                  class="btn article_search_curated_topics__block__list__item__inner__button__unfollow"
                >
                  <i class="cs-checked"></i>
                  <i class="unfollow_icon cs-cross"></i>
                  <span>Following</span>
                  <span class="unfollow_text">Unfollow</span>
                </button>
                <button
                  v-else
                  @click.prevent="handleFollowCuratedTopic({ topic: story })"
                  class="btn article_search_curated_topics__block__list__item__inner__button__follow"
                >
                  <span>Follow</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="article_search_curated_topics__block__footer"
        v-if="!explore_item.show_all"
      >
        <p @click.prevent="explore_item.show_all = !explore_item.show_all"
          >Show All</p
        >
      </div>
    </div>

    <!--<div class="category_block text-center">-->
    <!--<a href="https://builderall-plans.r-e-generate.com/plans" target="_blank">-->
    <!--<img class="category_list w-100" src="../../../../../app/src/assets/img/discovery_banner.png">-->
    <!--</a>-->
    <!--</div>-->
  </div>
</template>
<style lang="less" scoped></style>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    is_type_of: {}
  },
  computed: {
    ...mapGetters(['getCuratedTopicsList'])
  },
  methods: {
    ...mapActions(['followCuratedTopic', 'unfollowCuratedTopic']),
    async handleFollowCuratedTopic(topic) {

      await this.followCuratedTopic(topic)

      if (!this.getActiveWorkspace.onboarding_steps.discover_content.status) {
        await this.onboardingStepsCompleted('discover_content')
      }
    },
    searchOnCuratedTopic (story) {
      console.log(story)
      const routeName =
          this.is_type_of === 'content'
              ? 'discover_search_web'
              : 'discover_insights_web'
      this.$router.push({ name: routeName, query: { tag: story.name } })
    }
  },

}
</script>
