import {
  addBlockSourceURL,
  addedSourcesURL,
  addSourceFromSuggestionURL,
  addSourcesURL,
  allBlockSourcesURL,
  changeBlockedSourceStatusURL,
  changeSourceStatusURL,
  checkBlogURL,
  feedSourcePreviewURL,
  OPMLHistoryURL,
  removeBlockedSourceURL,
  removeSourceURL,
  searchKeywordsBySourceURL,
  updateSourcesCustomTopicsURL
} from '../../config/api-utils'
import proxy from '@common/lib/http-common'
import Router from '../../../../router'
import { getFeedsAutoCompleteURL } from '../../../discovery/config/api-utils'
import { sourceTypes } from './mutation-types'

const getDefaultPreviewSourceState = () => {
  return {
    // for the preview section

    preview_url: '',
    preview_rss_url: '',
    preview_state: '',
    is_non_rss: false,
    posts: []
  }
}

const getDefaultTopicsSourceState = () => {
  return {
    topicsSelected: [],
    topicsSelectedIds: []
  }
}

const getDefaultSourcesSearchState = () => {
  return {
    rss_change: false,
    suggested_feeds: [], // by keyword like ads etc. suggestion
    suggested_rss_links: [], // add rss feed etc. the link user enter for the feeds.
    show_selected_suggestion: false,
    selected_rss_link: false
  }
}

const getDefaultSourceLoaders = () => {
  return {
    loaders: {
      button: false,
      add: false,
      check_blog: false,
      global: false,
      keyword: false,
      preview: false
    }
  }
}

const getDefaultBlogState = () => {
  return {
    blog_checked: false,
    blog_url: ''
  }
}

const sourcesState = {
  sources: {
    blocked: [],
    added: [],
    opml_history: [],
    global: {
      suggestions: [],
      search: ''
    },

    // search related

    ...getDefaultPreviewSourceState(),
    ...getDefaultTopicsSourceState(),
    ...getDefaultSourcesSearchState(),
    ...getDefaultSourceLoaders(),
    ...getDefaultBlogState(),
    keyword: ''
  },
  // global feeds database
  global: {
    search: '',
    suggestions: [],
    loader: false
  },
  added: {
    feeds: []
  },

  loaders: {
    button: false,
    add: false,
    checkBlog: false
  }
}

const state = sourcesState

// getters
const getters = {
  getSourcesTopics: (state) => {
    return state.sources
  },
  getSources: (state) => {
    return state.sources
  },
  getSourcesTopicsIds: (state) => {
    return state.sources.topicsSelectedIds
  },
  getGlobalSources: (state) => {
    return state.sources.global
  },
  getAddedSources: (state) => {
    return state.sources.added
  },
  getSourcesLoader: (state) => {
    return state.sources.loaders
  },

  getBlockedSources: (state) => {
    return state.sources.blocked
  },
  getOPMLHistory: (state) => {
    return state.sources.opml_history
  }
}

// actions
const actions = {
  /**
   * Get a list of added sources.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  fetchAddedSources ({ commit, getters, state, dispatch }) {
    proxy
      .post(addedSourcesURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_ADDED_FEEDS', resp.data.feeds)
        }
      })
  },

  /**
   * Get list of OPML history, by default if no opml history, it wont be visible on the frontend.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  fetchOPMLHistory ({ commit, getters, state, dispatch }) {
    proxy
      .post(OPMLHistoryURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_OPML_HISTORY', resp.data.history)
        }
      })
      .catch()
  },

  /**
   * Get list of blocked sources against workspace
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  fetchBlockedSources ({ commit, getters, state, dispatch }) {
    proxy
      .post(allBlockSourcesURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_BLOCKED_SOURCES', resp.data.feeds)
        }
      })
      .catch()
  },

  /**
   * Changing blocked source status
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param source object
   */
  changeBlockedSourceStatus ({ commit, getters, state, dispatch }, source) {
    if (source.state === 'enabled') {
      source.state = 'disabled'
    } else {
      source.state = 'enabled'
    }
    proxy
      .post(changeBlockedSourceStatusURL, {
        id: source._id,
        state: source.state,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then()
      .catch()
  },

  removeBlockedSource ({ commit, getters, state, dispatch }, source) {
    commit('REMOVE_FROM_BLOCKED_SOURCES', source)
    proxy
      .post(removeBlockedSourceURL, {
        id: source._id,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {})
      .catch()
  },

  /**
   * Changing added source status
   *
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param source
   */
  changeSourceStatus ({ commit, getters, state, dispatch }, source) {
    if (source.state == 'enabled') {
      source.state = 'disabled'
    } else {
      source.state = 'enabled'
    }

    proxy
      .post(changeSourceStatusURL, {
        id: source._id,
        url: source.url,
        custom_topics: source.custom_topics,
        state: source.state,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then()
      .catch()
  },

  removeSource ({ commit, getters, state, dispatch }, source) {
    commit('REMOVE_FROM_ADDED_FEEDS', source)
    proxy
      .post(removeSourceURL, {
        id: source._id,
        custom_topics: source.custom_topics,
        url: source.url,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {})
      .catch()
  },

  async addIndividualSource ({ commit, getters, state, dispatch }) {
    commit('SET_ADD_SOURCES_LOADER', true)

    // prepare the url for the payload
    let url = ''
    if (getters.getSources.blog_url.length > 3) {
      url = getters.getSources.blog_url
    } else {
      if (getters.getSources.preview_url.length > 2) {
        url = getters.getSources.preview_url
      } else if (getters.getSources.preview_rss_url.length > 2) {
        url = getters.getSources.preview_rss_url
      }
    }

    const resp = await proxy.post(addSourcesURL, {
      url: url,
      custom_topics: getters.getSources.topicsSelected,
      non_rss: getters.getSources.is_non_rss,
      workspace_id: getters.getWorkspaces.activeWorkspace._id
    })

    // hide modal

    $('#add_source_modal').modal('hide')

    // add the source to the added feeds

    if (resp.data.status) {
      if (resp.data.feed) {
        // if item does not exist, add to the item
        const existIndex = getters.getAddedSources.findIndex(
          (feed) => feed._id === resp.data.feed._id
        )
        if (existIndex < 0) {
          commit('ADD_TO_ADDED_FEEDS', resp.data.feed)
          dispatch('toastNotification', {
            message: 'Your feed has been added.'
          })
        } else {
          dispatch('toastNotification', {
            message: 'You have already added this feed.'
          })
        }
      }
      commit(sourceTypes.RESET_SOURCE_STATES)
    } else {
      if (resp.data.message) {
        dispatch('toastNotification', {
          message: resp.data.message,
          type: 'error'
        })
      }
    }
    commit('SET_ADD_SOURCES_LOADER', false)
  },

  /**
   * Giving a user an option to search from the global database and add those feeds to the added feeds.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param item objet with feed and index
   */
  addFromGlobalSources ({ commit, getters, state, dispatch }, item) {
    const feed = item.feed
    const index = item.index
    getters.getGlobalSources.suggestions.splice(index, 1)

    const payload = {
      domain_id: feed._source.id,
      url: feed._source.url,
      rss_id: feed._source.rss_id,
      is_parent: feed._source.is_parent,
      favicon_16: feed._source.favicon_16,
      favicon_32: feed._source.favicon_32,
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      custom_topics: []
    }

    proxy.post(addSourceFromSuggestionURL, payload).then((resp) => {
      if (resp.data.status) {
        if (resp.data.feed) {
          commit('ADD_TO_ADDED_FEEDS', resp.data.feed)
        }
      }
    })
  },

  /**
   * Verifying the user entered domain has a blog or not, if blog, we will give an option to start crawling that url.
   * @param commit
   * @param dispatch
   * @param getters
   * @param state
   */
  verifyBlogSource ({ commit, dispatch, getters, state }) {
    commit('SET_CHECK_BLOG_SOURCES_LOADER', true)

    proxy
      .post(checkBlogURL, {
        url: getters.getSources.preview_rss_url
      })
      .then((resp) => {
        commit('SET_CHECK_BLOG_SOURCES_LOADER', false)
        commit('SET_SOURCES_BLOG_CHECKED', true)
        if (resp.data.status) {
          commit('SET_SOURCES_BLOG_URL', resp.data.link)
        } else {
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'warn'
          })
        }
      })
  },

  /**
   * Change the custom topics for a source user have added.
   * @param commit
   * @param dispatch
   * @param getters
   * @param state
   * @param payload
   * @returns {Promise<void>}
   */
  async changeSourcesForCustomTopics (
    { commit, dispatch, getters, state },
    payload
  ) {
    payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
    await proxy.post(updateSourcesCustomTopicsURL, payload)
  },

  /**
   * Searching for the url in our database.
   * @param commit
   * @param dispatch
   * @param getters
   * @param state
   * @param search keyword entered by a user.
   * @returns {Promise<void>}
   */
  async searchGlobalSources (
    { commit, dispatch, getters, state },
    search = null
  ) {
    let query = ''
    if (search) {
      query = search
    } else {
      query = getters.getGlobalSources.search
    }

    commit('SET_GLOBAL_SOURCES_LOADER', true)
    commit('SET_GLOBAL_SOURCES_SUGGESTIONS', [])

    const resp = await proxy.post(getFeedsAutoCompleteURL, { keyword: query })

    commit('SET_GLOBAL_SOURCES_LOADER', false)
    const items = []
    const addedURLS = []

    // iterate data feeds and check if they are already in the added sources or not.
    // if not add them to the list.

    getters.getAddedSources.forEach((element) => {
      addedURLS.push(element.url)
    })

    resp.data.forEach((item) => {
      if (!addedURLS.includes(item._source.url)) {
        items.push(item)
      }
    })

    commit('SET_GLOBAL_SOURCES_SUGGESTIONS', items)
  },

  /**
   * Reset search suggestions by keyword
   * @param commit
   */
  resetSearchSuggestionsByKeyword ({ commit }) {
    commit('SET_SOURCES_RSS_CHANGE', false)
    commit('SET_SOURCES_SHOW_RSS_SUGGESTIONS', false) // show the dropdown for the rss
    commit('SET_SOURCES_KEYWORD_SUGGESTION_LOADER', true)
    commit('SET_SOURCES_RSS_SUGGESTIONS_LIST', [])
  },

  /**
   * Search for the RSS suggestions from NBlur by keyword.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  searchSourcesSuggestionByKeyword ({ commit, getters, state, dispatch }) {
    if (getters.getSources.keyword.length > 0) {
      proxy
        .post(searchKeywordsBySourceURL, { value: getters.getSources.keyword })
        .then((resp) => {
          commit('SET_SOURCES_KEYWORD_SUGGESTION_LOADER', false)
          commit('SET_SOURCES_FEEDS_SUGGESTIONS_LIST', resp.data)
        })
    }
  },

  async getSourcePreview ({ commit, state, getters, dispatch }, payload) {
    const feedObject = {
      select: false,
      isKeyword: false
    }
    payload = Object.assign({}, feedObject, payload)

    // hide the dropdown menu if select is true.
    // use case is when we show a user options for the RSS links and they click on it, we need to hide the dropdown menu.
    if (payload.select) {
      // hide the suggestion for the keywords if selected
      if (payload.isKeyword === true) {
        commit('SET_SOURCES_SHOW_RSS_SUGGESTIONS', true)
      } else {
        // hide the suggestion for the add domain by rss.
        commit('SET_SOURCES_SELECTED_RSS_LINK', true)
      }
    } else {
      // default scenario hide it.
      if (payload.isKeyword === false) {
        commit('SET_SOURCES_SELECTED_RSS_LINK', false)
      }
    }

    // we are setting the value null because both of the url will start showing on the preview place.
    // if the type is of keyword, use the preview url.
    if (payload.isKeyword) {
      commit('SET_SOURCES_PREVIEW_URL', payload.url)
      commit('SET_SOURCES_PREVIEW_RSS_URL', '')
    } else {
      // use the type rss url.

      commit('SET_SOURCES_PREVIEW_URL', '')
      commit('SET_SOURCES_PREVIEW_RSS_URL', payload.url)
    }
    // set the loader for the preview of the content.

    commit('SET_SOURCES_PREVIEW_LOADER', true)
    commit('SET_SOURCES_PREVIEW_POSTS', [])
    commit('SET_SOURCES_PREVIEW_STATE', '')
    commit('SET_SOURCES_IS_NON_RSS', false)
    commit('SET_SOURCES_BLOG_CHECKED', false)

    await proxy
      .post(feedSourcePreviewURL, { url: payload.url })
      .then((resp) => {
        if (resp.data.status) {
          if (resp.data.non_rss) {
            dispatch('toastNotification', {
              message: resp.data.message,
              type: 'warn'
            })
            commit('SET_SOURCES_IS_NON_RSS', true)
          } else if (resp.data.preview) {
            commit('SET_SOURCES_PREVIEW_POSTS', resp.data.preview)
          } else if (resp.data.rss_links) {
            commit('SET_SOURCES_RSS_SUGGESTIONS_LIST', resp.data.rss_links)
          }
        } else {
          if (resp.data.state) {
            commit('SET_SOURCES_PREVIEW_STATE', resp.data.state)
          }
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'error'
          })
        }
      })
      .catch((err) => {})
    commit('SET_SOURCES_PREVIEW_LOADER', false)
  },

  /**
   * To hide the option so that the user enter a new URL
   * @param commit
   */
  noOptionSelected ({ commit }) {
    commit('SET_SOURCES_IS_NON_RSS', true)
    commit('SET_SOURCES_PREVIEW_RSS_URL', '')
  }
}

// mutations
const mutations = {
  SET_ADDED_FEEDS (state, sources) {
    state.sources.added = sources
  },
  REMOVE_FROM_ADDED_FEEDS (state, source) {
    const index = state.sources.added.indexOf(source)
    state.sources.added.splice(index, 1)
  },

  ADD_TO_ADDED_FEEDS (state, source) {
    state.sources.added.push(source)
  },

  SET_BLOCKED_SOURCES (state, sources) {
    state.sources.blocked = sources
  },

  REMOVE_FROM_BLOCKED_SOURCES (state, source) {
    const index = state.sources.blocked.indexOf(source)
    state.sources.blocked.splice(index, 1)
  },
  SET_OPML_HISTORY (state, history) {
    state.sources.opml_history = history
  },
  RESET_SOURCES_STATE (state) {
    state.sources = JSON.parse(JSON.stringify(sourcesState.sources))
  },

  SET_SOURCES_BLOG_CHECKED (state, value) {
    state.sources.blog_checked = value
  },

  SET_SOURCES_BLOG_URL (state, value) {
    state.sources.blog_url = value
  },

  // searching for a RSS feed by keyword

  SET_SOURCES_RSS_CHANGE (state, value) {
    state.sources.rss_change = value
  },
  SET_SOURCES_SHOW_RSS_SUGGESTIONS (state, value) {
    state.sources.show_selected_suggestion = value
  },
  // if the results are already showing for the add rss feeds. We will hide those one. (Add Feed) section.
  SET_SOURCES_RSS_SUGGESTIONS_LIST (state, value) {
    state.sources.suggested_rss_links = value
  },
  SET_SOURCES_FEEDS_SUGGESTIONS_LIST (state, value) {
    state.sources.suggested_feeds = value
  },

  SET_SOURCES_SELECTED_RSS_LINK (state, value) {
    state.sources.selected_rss_link = value
  },

  // preview

  SET_SOURCES_PREVIEW_URL (state, value) {
    state.sources.preview_url = value
  },

  SET_SOURCES_PREVIEW_RSS_URL (state, value) {
    state.sources.preview_rss_url = value
  },

  SET_SOURCES_PREVIEW_LOADER (state, value) {
    state.sources.loaders.preview = value
  },
  SET_SOURCES_PREVIEW_POSTS (state, posts) {
    state.sources.posts = posts
  },
  SET_SOURCES_PREVIEW_STATE (state, value) {
    state.sources.preview_state = value
  },

  SET_SOURCES_IS_NON_RSS (state, value) {
    state.sources.is_non_rss = value
  },

  [sourceTypes.RESET_SOURCE_STATES] (state) {
    state.sources = Object.assign(state.sources, {
      ...getDefaultBlogState(),
      ...getDefaultPreviewSourceState(),
      ...getDefaultSourceLoaders(),
      ...getDefaultSourcesSearchState(),
      ...getDefaultTopicsSourceState(),
      keyword: ''
    })
  },

  // loaders

  SET_ADD_SOURCES_LOADER (state, value) {
    state.sources.loaders.button = value
  },
  SET_CHECK_BLOG_SOURCES_LOADER (state, value) {
    state.sources.loaders.check_blog = value
  },

  SET_GLOBAL_SOURCES_LOADER (state, value) {
    state.sources.loaders.global = value
  },
  SET_SOURCES_KEYWORD_SUGGESTION_LOADER (state, value) {
    state.sources.loaders.keyword = value
  },

  SET_GLOBAL_SOURCES_SUGGESTIONS (state, feeds) {
    state.sources.global.suggestions = feeds
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
