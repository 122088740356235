<template>
  <b-modal
    id="socialConfirmationDialog"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox socialConfirmationDialog"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div class="col-sm-12 alert_content">
        <h2>Save this draft?</h2>
        <p class="text-center">Would you like to work on this draft later?</p>
      </div>

      <div class="col-sm-12 footer_box">
        <button class="btn gray cancel_btn" @click="closeThisModal"
          >Cancel</button
        >
        <button
          class="btn gray_color_btn gray cancel_btn"
          @click="closeAllModal"
          >Don't save</button
        >
        <button
          :disabled="
            getPublishLoaders.processBlogPost ||
            getPublishLoaders.processSavePublication
          "
          class="btn green_color_btn confirm_btn"
          @click.prevent="saveDraft"
          >Save
          <clip-loader
            v-if="getPublishLoaders.processSavePublication"
            class="d-inline-block align-middle ml-1"
            :color="'#ffffff'"
            :size="'14px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { draftConfirmationTypes } from '../../store/mutation-types'

export default {
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['getBlogPostingDetails', 'getPublishLoaders']),
  },

  methods: {
    ...mapActions(['setDraftCancelAction']),
    closeThisModal() {
      this.$bvModal.hide('socialConfirmationDialog')
      setTimeout(function () {
        $('body').addClass('modal-open')
      }, 400)
      // edge case click away , calling it after 300 ms otherwise it opens again and again.
      setTimeout(() => {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CLICKAWAY, false)
      }, 300)
    },
    closeAllModal() {
      this.$bvModal.hide('socialConfirmationDialog')
      this.$store.commit(draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION, true)
      if (this.$route.query.module && this.$route.query.module === 'feeds') {
        console.log('closeAllModal --> go back to previous route...')
        this.$router.go('-1')
      } else {
        this.$bvModal.hide('social_share_modal')
        this.$bvModal.hide('blog_share_modal')
      }
      // edge case click away , calling it after 300 ms otherwise it opens again and again.
      setTimeout(() => {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CLICKAWAY, false)
      }, 300)
    },
    saveDraft() {
      if (this.$route.query.module && this.$route.query.module === 'feeds') {
        this.$bvModal.hide('socialConfirmationDialog')
      }
      if (this.getBlogPostingDetails.type === 'Discovery Article') {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION, true)
        this.processBlogPost(true)
      } else if (this.getBlogPostingDetails.type === '') {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION, true)
        this.processSocialShare(true)
      }
      // edge case click away , calling it after 300 ms otherwise it opens again and again.
      setTimeout(() => {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CLICKAWAY, false)
      }, 300)
    },
  },
}
</script>
