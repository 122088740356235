<template>
  <chart-render
    :id="'published_stories_highlight'"
    :options="publishedStories()"
    :ref_value="'published_stories'"
    :is_content_available="isContentNotAvailable"
  ></chart-render>
</template>

<script>
import {
  darkBlueColor,
  splineChart,
  splineLegend,
  splineTooltip,
  splineYAxis
} from '../../common/helper'
import { INSTAGRAM_ANALYTICS_POST_DENSITY } from '@common/constants/messages'
import ChartRender from '../../common/ChartRender'
import moment from 'moment'

export default {
  components: {
    ChartRender
  },
  props: {
    published_stories: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_POST_DENSITY
    }
  },
  computed: {
    totalDocs () {
      let count = 0
      Object.entries(this.published_stories).forEach((item) => {
        count += item[1]
      })
      return count
    },
    isContentNotAvailable () {
      return this.totalDocs === 0
    }
  },
  methods: {
    publishedStories () {
      const data = []
      const keys = []
      this.published_stories.buckets.forEach((item) => {
        keys.push(moment(item.key_as_string).format('DD MMM'))
        data.push(item.doc_count)
      })

      const tooltip = splineTooltip()
      tooltip.pointFormat =
        '<p class="engagement_point"><span>{point.y:f}</span></p>'

      return {
        credits: splineLegend(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: tooltip,
        chart: {
          type: 'column',
          height: 350,
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: splineChart('#published_stories_highlight').events
        },
        legend: splineLegend(),
        colors: ['#536eff'],
        title: false,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          labels: {
            enabled: true
          },
          categories: keys
        },
        yAxis: splineYAxis(),
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
            stacking: 'normal'
          },
          series: {
            lineColor: '#3a4557'
          }
        },
        series: [
          {
            name: 'Stories Published',
            data: data,
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            color: darkBlueColor()
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
