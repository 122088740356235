<template>
  <label
    class="flex items-center shadow-sm justify-start p-3 rounded-lg cursor-pointer border-2 border-solid min-w-fit"
    :class="[
      isChecked
        ? 'border-opacity-40 border-2 border-solid border-cs-primary bg-cs-slate'
        : 'hover:border-blue-300 border-gray-300 border-2 border-opacity-50',
    ]"
  >
    <input
      class="hidden"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
    />
    <span>
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'CstCardCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: { type: [String, Number], default: '' },
    modelValue: { default: '', type: [String, Array, Number] },
    trueValue: { default: true, type: Boolean },
    falseValue: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    },
  },
}
</script>
