<template>
  <div>
    <div
      class="assistant_box _dailymotion _video"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
      :key="index"
      v-for="(video, index) in getVideos"
    >
      <div
        class="box_inner"
        @dragstart="dragstartHandler($event, video)"
        :draggable="$route.name === 'composerBlog'"
      >
        <div class="image_block">
          <!--.......For video icon..............-->
          <div
            @click.prevent="
              previewVideo(
                video.title,
                '',
                videoEmbedUrl(video, 'dailymotion'),
                'Dailymotion'
              )
            "
            class="play_icon facebook_vid_icon"
          >
            <i class="cs-play"></i>
          </div>
          <div
            class="img"
            v-lazy:background-image="videoImageURL(video, 'dailymotion')"
          ></div>
        </div>

        <div class="content_block">
          <p class="desc">
            {{ limitTextLength(video.title, 42) }}
          </p>
          <div class="detail">
            <p class="date"
              ><span>{{ videoDate('dailymotion', video) }}</span></p
            >
          </div>
        </div>
        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div class="stat_item" v-tooltip.top-center="'View'">
              <div class="name">
                <p>
                  <i class="cs-eye"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.views_total) }}</h3>
              </div>
            </div>
            <div class="stat_item" v-tooltip.top-center="'Like'">
              <div class="name">
                <p>
                  <i class="cs-thumbs-up"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.likes_total) }}</h3>
              </div>
            </div>
            <!--<div class="stat_item" v-tooltip.top-center="'Comment'">-->
            <!--<div class="name">-->
            <!--<p>-->
            <!--<span class="comment_quote">-->
            <!--<span class="icon"></span>-->
            <!--</span>-->
            <!--</p>-->
            <!--</div>-->
            <!--<div class="value">-->
            <!--<h3>0</h3>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_video_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option">
              <div class="left_block">
                <i
                  class="move_left fa fa-align-left editor_tooltip"
                  title="Align Left"
                ></i>
                <i
                  class="move_center fa fa-align-center editor_tooltip"
                  title="Align Center "
                ></i>
                <i
                  class="move_right fa fa-align-right editor_tooltip"
                  title="Align Right"
                ></i>
              </div>
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div
              class="align_box dailymotion_inner_embed"
              :data-link="videoEmbedUrl(video, 'dailymotion')"
              style="text-align: center; display: block"
            >
              <div class="inner_embed">
                <div class="custom_loader">
                  <div class="loader_inner"></div>
                </div>
                <iframe
                  frameborder="0"
                  style="width: 470px; height: 330px"
                  src=""
                  allowfullscreen
                ></iframe
                ><br />
              </div>
            </div>
            <p
              class="text_alignment"
              style="text-align: center"
              :data-link="video.url"
              :data-embed="videoEmbedUrl(video, 'dailymotion')"
              >{{ video.title }}</p
            >
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Youtube',
  components: {},
  computed: {
    ...mapGetters(['getVideos'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    dragstartHandler (ev, video) {
      // Add the target element's id to the data transfer object

      if (video && video.url) {
        ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'embed',
            heading: '',
            media: video.url,
            p: '',
            link: ''
          })
        )
      }
    }
  }
}
</script>
