<template>
  <div>
    <template v-if="getLanguageList.length > 0 && (isTopic || automation)">
      <!-- &&  getRelatedStatus == false-->

      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first cs-flag"></i>
        <span class="text">{{ getTopicsLanguage.languageName }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li
            class="list_item"
            v-if="getLanguageList.length > 0"
            v-for="language in getLanguageList"
            @click.prevent="changeLanguage('topics', language)"
            :value="language.code"
          >
            <a href="#">{{ language.languageName }}</a>
          </li>
        </ul>
      </div>
    </template>

    <template v-else-if="getLanguageList.length > 0">
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first cs-flag"></i>
        <span class="text">{{ getArticlesLanguage.languageName }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li
            class="list_item"
            v-if="getLanguageList.length > 0"
            v-for="language in getLanguageList"
            @click.prevent="changeLanguage('articles', language)"
            :value="language.code"
          >
            <a href="#">{{ language.languageName }}</a>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
import { filterLimitedLanguages } from '@common/constants/languages'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import orderBy from 'lodash/orderBy'
import { topicTypes } from '../../store/mutation-types'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  data () {
    return {
      languages: filterLimitedLanguages
    }
  },
  computed: {
    ...mapGetters([
      'getArticlesRegion',
      'getTopicsRegion',
      'getArticlesLanguage',
      'getRelatedStatus',
      'getTopicsLanguage'
    ]),

    getLanguageList () {
      let languageList = []
      this.languages.forEach(function (element) {
        if (this.isCreateTopic || this.isTopic) {
          if (element.code === this.getTopicsRegion.code) {
            languageList = element.languageList
          }
        } else {
          if (element.code === this.getArticlesRegion.code) {
            languageList = element.languageList
          }
        }
      }, this)
      return orderBy(languageList, ['languageName'], ['asc'])
    }
  },
  methods: {
    ...mapActions([
      'articles',
      'topicsContent',
      'getContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations([topicTypes.RESET_TOPICS_FILTER]),
    async changeLanguage (type, value) {
      switch (type) {
        case 'topics':
          this.SET_TOPICS_LANGUAGE(value)
          this[topicTypes.RESET_TOPICS_FILTER]()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        case 'articles':
          await this.getContent({ filter: { key: 'language', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        default:
          break
      }
    }
  }
}
</script>
