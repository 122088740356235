<template>
  <!-- for the topics page -->
  <div v-if="isTopic" class="topic_date_picker d-inline-block align-middle">
    <template v-if="getTrendingStories === true">
      <span class="d-inline-block mr-2 trending-stories-datepicker">
        <DateRangePicker
          class="datepicker_input"
          :opens="trending.opens"
          :start-date="trending.startDate"
          :locale-data="trending.locale"
          :single-date-picker="trending.singleDatePicker"
          :time-picker="trending.timePicker"
          :time-picker24hour="trending.timePicker24Hour"
          :show-week-numbers="trending.showWeekNumbers"
          :show-dropdowns="trending.showDropdowns"
          :auto-apply="trending.autoApply"
          :ranges="trending.ranges"
          @update="updateDateRange"
        >
          <div
            slot="input"
            slot-scope="picker"
            class="datepicker_content discovery_white ml-2"
          >
            <i class="icon-Calendar"></i>
            <span v-if="picker.label">
              {{ picker.label }}
            </span>
            <span v-else>
              {{ getTopicLabel.date.label }}
            </span>
          </div>
        </DateRangePicker>
      </span>
    </template>

    <template v-else>
      <span class="d-inline-block mr-2 topics-datepicker">
        <DateRangePicker
          class="datepicker_input"
          :opens="topics.opens"
          :start-date="getTopicsDateRange.start"
          :end-date="getTopicsDateRange.end"
          :max-date="getMaxDate"
          :locale-data="topics.locale"
          :single-date-picker="topics.singleDatePicker"
          :time-picker="topics.timePicker"
          :time-picker24hour="topics.timePicker24Hour"
          :show-week-numbers="topics.showWeekNumbers"
          :show-dropdowns="topics.showDropdowns"
          @update="updateDateRange"
          :auto-apply="topics.autoApply"
          :ranges="topics.ranges"
        >
          <div
            slot="input"
            slot-scope="picker"
            class="datepicker_content discovery_white ml-2"
          >
            <i class="icon-Calendar"></i>
            <span v-if="getDatePickerLabel(picker.startDate, picker.endDate)">
              {{ getDatePickerLabel(picker.startDate, picker.endDate) }}
            </span>
            <span v-else-if="picker.label">
              {{ picker.label }}
            </span>
            <span v-else> Last 30 days </span>
          </div>
        </DateRangePicker>
      </span>
    </template>
    <!-- for the articles page -->
  </div>
  <span v-else>
    <template v-if="getArticlesSort === 'Trending'">
      <span class="d-inline-block mr-2 trending-stories-datepicker">
        <DateRangePicker
          class="datepicker_input"
          :opens="trending.opens"
          :start-date="trending.startDate"
          :locale-data="trending.locale"
          :single-date-picker="trending.singleDatePicker"
          :time-picker="trending.timePicker"
          :time-picker24hour="trending.timePicker24Hour"
          :show-week-numbers="trending.showWeekNumbers"
          :show-dropdowns="trending.showDropdowns"
          :auto-apply="trending.autoApply"
          :ranges="trending.ranges"
          @update="updateDateRange"
        >
          <div
            slot="input"
            slot-scope="picker"
            class="datepicker_content discovery_white ml-2"
          >
            <i class="icon-Calendar"></i>
            <span v-if="picker.label">
              {{ picker.label }}
            </span>
            <span v-else> Last 7 days </span>
          </div>
        </DateRangePicker>
      </span>
    </template>
    <template v-else>
      <span class="d-inline-block mr-2 article">
        <DateRangePicker
          ref="articledatepicker"
          class="datepicker_input"
          :opens="topics.opens"
          :start-date="getArticlesDateRange.start"
          :end-date="getArticlesDateRange.end"
          :max-date="getMaxDate"
          :locale-data="topics.locale"
          :single-date-picker="topics.singleDatePicker"
          :time-picker="topics.timePicker"
          :time-picker24hour="topics.timePicker24Hour"
          :show-week-numbers="topics.showWeekNumbers"
          :show-dropdowns="topics.showDropdowns"
          @update="updateDateRange"
          :auto-apply="topics.autoApply"
          :ranges="topics.ranges"
        >
          <div
            slot="input"
            slot-scope="picker"
            class="datepicker_content discovery_white ml-2 text-left"
          >
            <i class="icon-Calendar"></i>
            <span v-if="getDatePickerLabel(picker.startDate, picker.endDate)">
              {{ getDatePickerLabel(picker.startDate, picker.endDate) }}
            </span>
            <span v-else-if="picker.label">
              {{ picker.label }}
            </span>
            <span v-else> Last 30 days </span>
          </div>
        </DateRangePicker>
      </span>
    </template>
  </span>
</template>

<script>
// let moment = require('moment-timezone/builds/moment-timezone-with-data.min')
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
  articlesTypes as articleTypes,
  topicTypes
} from '../../store/mutation-types'
// import bootstrapDatepicker from 'bootstrap-daterangepicker'

export default {
  components: { DateRangePicker },
  props: {
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },
  data () {
    return {
      trending: {
        locale: {
          format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
        },
        opens: 'center',
        endDate: '2017-10-09',
        // minDate: '2016-09-02',
        // maxDate: '2019-10-02',
        dateRange: {
          startDate: moment().utc()
        },
        show_ranges: true,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showDropdowns: false,
        autoApply: true,
        showWeekNumbers: false,
        ranges: {
          '6 hours': [moment.utc().subtract(6, 'hours'), moment.utc()],
          '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
          '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
          'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
          'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()]
        }
      },
      topics: {
        locale: {
          format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
        },

        opens: 'left',
        startDate: '2017-09-19',
        endDate: '2017-10-09',
        minDate: '2016-09-02',
        maxDate: this.getMaxDate,
        dateRange: {
          endDate: moment().utc()
        },
        show_ranges: true,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: true,
        showDropdowns: true,
        autoApply: true,
        showWeekNumbers: true,
        ranges: {
          'All Time': [moment.utc().subtract(5, 'years'), moment.utc()],
          '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
          '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
          'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
          'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
          'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
          'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
          'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
          'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
          'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getFilters',
      'getArticlesSort',
      'getTrendingStatus',
      'getTopicsFilters',
      'getTopicsSort',
      'getTopicModule',
      'getTopicCreationModal',
      'getTopicLabel'
    ]),
    getMaxDate () {
      return moment.utc().format('YYYY-MM-DD')
    },
    getTopicsDateRange () {
      const splitDates = this.getTopicsFilters.topics.date.value.split(' - ')
      if (splitDates.length > 0) {
        return {
          start: moment(splitDates[0]).format('YYYY-MM-DD HH:mm'),
          end: moment(splitDates[1]).format('YYYY-MM-DD HH:mm')
        }
      } else {
        return {}
      }
    },
    getArticlesDateRange () {
      const splitDates = this.getFilters.articles.date.value.split(' - ')
      if (splitDates.length > 0) {
        return {
          start: moment(splitDates[0]).format('YYYY-MM-DD HH:mm'),
          end: moment(splitDates[1]).format('YYYY-MM-DD HH:mm')
        }
      } else {
        return {}
      }
    }
  },
  mounted () {},
  methods: {
    ...mapMutations([
      articleTypes.SET_ARTICLES_DATE_RANGE,
      topicTypes.SET_TOPICS_DATE_RANGE,
      topicTypes.RESET_TOPICS_FILTER
    ]),
    ...mapActions([
      'articlesInsights',
      'topicInsights',
      'topicsContent',
      'articlesPreviewSuggestion'
    ]),
    async updateDateRange (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      let range = null

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        const labelValue = this.checkTimeDurationLabel(
          values.startDate,
          values.endDate
        )
        range = {
          label: labelValue,
          value:
            startDate.format('YYYY-MM-DD HH:mm:ss') +
            ' - ' +
            endDate.format('YYYY-MM-DD HH:mm:ss')
        }
      }
      // calling the content mixing method to reflect the range filter.
      if (this.isTopic || this.automation) {
        console.log('Topics Date Range', range)
        switch (this.getTopicModule) {
          case 'insights':
            this[topicTypes.SET_TOPICS_DATE_RANGE](range)
            this.topicInsights()
            break
          default:
            this[topicTypes.RESET_TOPICS_FILTER]()
            this[topicTypes.SET_TOPICS_DATE_RANGE](range)
            if (this.isCreateTopic || this.automation) {
              this.articlesPreviewSuggestion()
            } else {
              this.topicsContent()
            }
        }
      } else {
        switch (this.getTopicModule) {
          case 'insights':
            this[articleTypes.SET_ARTICLES_DATE_RANGE](range)
            this.articlesInsights()
            break
          default:
            await this.getContent({ filter: { key: 'date', value: range } })
            await this.articles()
            this.checkComposerDragDrop()
        }

        // this.changeArticlesDateRange(range)
      }
    }
  }
}
</script>

<style>
.trending-stories-datepicker .daterangepicker.show-ranges {
  min-width: 100% !important;
}

.trending-stories-datepicker .drp-calendar.col.left {
  display: none;
}

.trending-stories-datepicker .drp-calendar.col.right {
  display: none;
}
</style>
