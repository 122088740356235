<template>
  <b-modal
    modal-class="normal_modal bv-modal-upgrade-plan w_900"
    :id="modalId"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Change your trial plan to try more features with higher limits</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide(modalId)"
        >&times;
      </button>
    </div>

    <div
      class="modal_body basic_form"
      style="margin-top: 1rem; margin-bottom: 1rem"
    >
      <div class="upgrade-plan-box">
        <div
          class="upgrade-box-column"
          :key="index"
          v-for="(pricingPlan, index) in getTrialPricing"
          :class="{ active: pricingPlan.level === level }"
        >
          <billing-plan-tile
            :billing_period="'trial'"
            :pricingPlan="pricingPlan"
          ></billing-plan-tile>
          <template v-if="level === pricingPlan.level">
            <b-button variant="studio-theme-active">Current Plan</b-button>
          </template>
          <template v-else>
            <b-button
              :disabled="pricingPlan.loader"
              @click="changeTrialPlanAction(pricingPlan)"
              variant="studio-theme-transparent-grey"
            >
              Choose Plan
              <span class="ml-2" v-if="pricingPlan.loader">
                <clip-loader :size="'14px'" :color="'#3a4557'"></clip-loader>
              </span>
            </b-button>
          </template>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<style lang="less"></style>
<script>
import { trialPricingList } from '@common/constants/pricing'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import BillingPlanTile from "@src/modules/setting/components/billing/reusable/BillingPlanTile";

export default {
  name: 'change-trial-plan-dialog',
  components: {
    BillingPlanTile
  },
  data () {
    return {
      modalId: 'change-trial-plan-dialog',
      level: 0,
      trial_pricing_plans_list: trialPricingList
    }
  },
  methods: {
    async changeTrialPlanAction (plan) {
      this.$set(plan, 'loader', true)

      const res = await proxy
        .post(`${billingBaseUrl}trialChange`, {
          name: plan.fs_name
        })
        .then((resp) => {
          if (resp.data.status) {
          }
          return true
        })
        .catch((err) => {
          return false
        })
      console.log(`plan change service call result is ${res}`)
      if (res) {
        // retrieve the plan

        await this.$store.dispatch('fetchPlan')

        // toast messages and hide the loaders.

        this.$store.dispatch('toastNotification', {
          message: 'Your plan has been updated successfully!',
          type: 'success'
        })
        this.$set(plan, 'loader', false)
        this.$bvModal.hide(this.modalId)
      }
    }
  },
  computed: {
    getTrialPricing () {
      // check the subscription name

      const subscriptionName =
        this.$store.getters.getPlan.subscription.fastspring_name

      // check if the subscription matches in paddle list names

      this.trial_pricing_plans_list.plans.forEach((plan) => {
        if (plan.paddle_name === subscriptionName) {
          this.level = plan.level
        }
        // check if the subscription matches in fastspring names

        if (plan.fs_name === subscriptionName) {
          this.level = plan.level
        }
      })

      return this.trial_pricing_plans_list.plans
    }
  }
}
</script>
