import { checkMaskingValidation } from './helper'
import { timePassed } from './date-time'

function scheduleOptionsValidation(selection, automation) {
  console.debug('Automation library scheduleOptionsValidation')
  if (selection.time_type === 'Custom') {
    if (
      selection.custom.days[0].status === false &&
      selection.custom.days[1].status === false &&
      selection.custom.days[2].status === false &&
      selection.custom.days[3].status === false &&
      selection.custom.days[4].status === false &&
      selection.custom.days[5].status === false &&
      selection.custom.days[6].status === false
    ) {
      return { status: false, message: 'Please select some time day(s).' }
    }
    if (selection.custom.days[0].status === true) {
      if (selection.custom.days[0].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for sunday.',
        }
      }

      // loop on times for validation
      let message = ''
      $(selection.custom.days[0].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for sunday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[1].status === true) {
      if (selection.custom.days[1].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for monday.',
        }
      }

      // loop on times for validation
      let message = ''
      $(selection.custom.days[1].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for monday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[2].status === true) {
      if (selection.custom.days[2].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for tuesday.',
        }
      }

      // loop on times for validation
      let message = ''
      $(selection.custom.days[2].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for tuesday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[3].status === true) {
      if (selection.custom.days[3].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for wednesday.',
        }
      }

      // loop on times for validation
      let message = ''
      $(selection.custom.days[3].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for wednesday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[4].status === true) {
      if (selection.custom.days[4].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for thursday.',
        }
      }

      // loop on times for validation
      let message = ''
      $(selection.custom.days[4].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for thursday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[5].status === true) {
      if (selection.custom.days[5].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for friday.',
        }
      }
      // loop on times for validation
      let message = ''
      $(selection.custom.days[5].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for friday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
    if (selection.custom.days[6].status === true) {
      if (selection.custom.days[6].times.length === 0) {
        return {
          status: false,
          message: 'Please select some time(s) for saturday.',
        }
      }
      // loop on times for validation
      let message = ''
      $(selection.custom.days[6].times).each(function (index, el) {
        if (!checkMaskingValidation(el)) {
          message =
            'Please Enter valid time value for saturday time box ' +
            (index + 1) +
            '.'
          return false
        }
      })
      if (message) {
        return { status: false, message: message }
      }
    }
  }

  if (selection.schedule_type === 'fix') {
    if (selection.start_time === '') {
      return { status: false, message: 'Please select start date.' }
    }
    if (
      (automation.id === '' || automation.remove_posts === true) &&
      timePassed(selection.start_time)
    ) {
      return {
        status: false,
        message: 'Starting time has passed.Please change start time.',
      }
    }
  }

  return { status: true }
}

export { scheduleOptionsValidation }
