<template>
  <div>
    <hr class="mt-2 mb-0 mx-0 hr-color" />
    <!--    like && comment-->
    <div
      class="flex items-center justify-end mx-2 px-1 py-2 text-base text-gray-800"
    >
      <div v-show="state.loader" class="w-full flex justify_end items-center">
        <clip-loader
          class="d-inline-block align-middle mr-1 mt-1"
          color="rgba(152, 158, 181, 0.8)"
          :size="'12px'"
        ></clip-loader>
      </div>
      <div class="w-full flex justify-end items-center h-[2rem] rounded-lg">
        <i class="far fa-comment-lines"></i>
        <p class="font-bold text-sm mx-1">{{ tempCount + totalComments }} </p>
        <span class="font-bold text-sm"> Comments </span>
      </div>
    </div>

    <!--      main reply box-->
    <div
      class="w-full px-2"
      :class="[
        state.postingLoader || state.deletingLoader
          ? 'opacity-50 select-none'
          : '',
      ]"
    >
      <LinkedInReplyBox
        id="mainCommentReplyBox"
        :inbox-data="state.activeInboxData"
        :loader="state.postingLoader"
        v-on="$listeners"
        @post-comment="handlePostComment"
      ></LinkedInReplyBox>
    </div>

    <!--    commentBody-->
    <div
      v-if="state.showComment && !state.loader"
      class="flex flex-column items-center justify-center"
    >
      <!--      other comments-->
      <template v-for="(comment, key) in state.allComments">
        <LinkedinInboxComment
          :key="`main_comment_${comment.comment_id}_${key}`"
          :comment="comment"
          :inbox-data="state.activeInboxData"
          class="w-full px-2"
          v-on="$listeners"
          @comment-posted="addComment"
          @comment-deleted="deleteComment"
        ></LinkedinInboxComment>
      </template>
      <div
        v-if="
          tempComments + totalComments > state.allComments.length &&
          totalComments > 7
        "
        class="flex justify-center items-center cursor-pointer hover:bg-gray-100 p-2 !rounded-lg"
        :class="{ 'w-60': state.isLoading }"
        @click="loadMoreComments"
      >
        <p class="font-bold text-sm text-gray-800">Load more comments</p>
        <div
          v-show="state.isLoading"
          class="flex justify-center items-center ml-2 mb-1"
        >
          <clip-loader
            class="d-inline-block align-middle"
            color="rgba(152, 158, 181, 0.8)"
            :size="'12px'"
          ></clip-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch, nextTick } from 'vue'
import LinkedInReplyBox from '@src/modules/inbox/components/reusable/Linkedin-post/LinkedInReplyBox'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import LinkedinInboxComment from '@src/modules/inbox/components/reusable/Linkedin-post/LinkedinInboxComment'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default {
  name: 'LinkedinPostReply',
  components: {
    LinkedinInboxComment,
    LinkedInReplyBox,
  },
  props: {
    inboxData: {
      type: Object,
      default: () => {},
    },
    comments: {
      type: Array,
      default: () => [],
    },
    loadingState: {
      type: Boolean,
      default: false,
    },
    totalComments: {
      type: Number,
      default: 0,
    },
    tempCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { dispatch, getters } = useStore()
    const state = reactive({
      activeInboxData: computed(() => props.inboxData),
      showReplyBox: false,
      showComment: false,
      commentsResponse: null,
      postingResponse: null,
      postingLoader: false,
      deletingLoader: false,
      allComments: computed(() => props.comments),
      loader: computed(() => props.loadingState),
      commentsCount: 0,
      isLoading: false,
    })
    const userId = computed(() => getters.getProfile._id)
    const payload = reactive({
      post_id: computed(() => props.inboxData.element_details.element_id),
      workspace_id: state.activeInboxData.workspace_id,
      platform: state.activeInboxData.platform,
      sort_order: '',
      page: 1,
      limit: 7,
    })
    const tempComments = ref([...state.allComments])

    watch(
      () => [state.activeInboxData.element_details.element_id, state.loader],
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          resetStates({
            ...defaultStates(),
          })
          payload.page = 1
          state.showComment = !state.loader
          console.log(
            'WATCHER:: LINKEDIN_POST_REPLY:: ',
            state.activeInboxData.element_details.element_id,
            state.showComment,
            state.loader
          )
        }
      }
    )

    const defaultStates = () => {
      return {
        showReplyBox: false,
        showComment: false,
        commentsResponse: null,
        commentsCount: 0,
        page: 1,
        isLoading: false,
      }
    }

    const resetStates = (newState) => {
      Object.assign(state, newState)
    }

    const previewMediaImage = (image) => {
      console.log('openImageLightBox', image)
      if (image) {
        const imageData = {
          link: image,
          visible: true,
        }
        emit('preview-image', imageData)
      }
    }

    /**
     * @description handles the post of the main reply box, posts a comment on the post
     * @param comment
     * @param attachment
     */
    const handlePostComment = async (comment, attachment) => {
      state.postingLoader = true
      const commentPayload = new FormData()
      commentPayload.append('workspace_id', state.activeInboxData.workspace_id)
      commentPayload.append('platform', state.activeInboxData.platform)
      commentPayload.append('platform_id', state.activeInboxData.platform_id)
      commentPayload.append('reply_for', 'post')
      commentPayload.append(
        'element_id',
        state.activeInboxData.element_details.element_id
      )
      commentPayload.append('replied_by', userId.value)
      commentPayload.append('is_private_reply', false)
      commentPayload.append('is_for', 'reply_post')

      if (attachment) {
        commentPayload.append('attachment_file', attachment)
        commentPayload.append('attachment_type', 'image')
      }
      if (comment) {
        commentPayload.append('message', comment)
      }
      try {
        state.postingResponse = await dispatch('reply', commentPayload)
        if (state.postingResponse?.isValid) {
          EventBus.$emit('refresh-inbox-detail')
          await nextTick()
          dispatch('toastNotification', {
            message: 'Comment has been posted.',
            type: 'success',
          })
          emit('comment-count', 'add')
          state.allComments.unshift(state.postingResponse.response)
          EventBus.$emit('reset-replyBox')
        } else {
          dispatch('toastNotification', {
            message: 'Could not post the comment.',
            type: 'error',
          })
        }
        state.postingLoader = false
      } catch (error) {
        state.postingLoader = false
        dispatch('toastNotification', {
          message: error.message,
          type: 'error',
        })
      }
    }

    /**
     * @description if the post has more than 7 comments(not replies), then it will get the next comments(max 7 at a time)
     */
    const loadMoreComments = async () => {
      state.isLoading = true
      payload.page = payload.page + 1
      try {
        state.commentsResponse = await dispatch('fetchPostComments', payload)
        state.allComments.push(...state.commentsResponse.comments)
        state.isLoading = false
      } catch (exception) {
        payload.page = payload.page - 1
        state.isLoading = false
        dispatch('toastNotification', {
          message: "Couldn't fetch comments.",
          type: 'error',
        })
      }
    }

    /**
     * @description handles the temporary update of replies in the current comments list
     * @param data
     * @param comment
     */
    const addComment = (data, comment) => {
      EventBus.$emit('reset-replyBox')
      let childIndex = null
      const parentIndex = state.allComments.findIndex(
        (item) => item.comment_id === data.comment_id
      )
      if (parentIndex >= 0) {
        state.allComments[parentIndex].comment_replies.push(comment)
      } else {
        state.allComments.every((item, index) => {
          if (item.comment_replies) {
            childIndex = item.comment_replies.findIndex(
              (subItem) => subItem.comment_id === data.comment_id
            )
            if (childIndex >= 0) {
              state.allComments[index].comment_replies.push(comment)
            }
          }
        })
      }
    }

    /**
     * @description handles the temporary deletion of a reply or a comment in the current comment list
     * @param data
     * @param comment
     */
    const deleteComment = (data, comment) => {
      let childIndex = null
      const parentIndex = state.allComments.findIndex(
        (item) => item.comment_id === data.comment_id
      )
      if (parentIndex >= 0) {
        state.allComments.splice(parentIndex, 1)
        emit('comment-count', 'remove')
      } else {
        state.allComments.forEach((item, index) => {
          if (item.comment_replies.length) {
            childIndex = item.comment_replies.findIndex(
              (subItem) => subItem.comment_id === data.comment_id
            )
            if (childIndex >= 0) {
              state.allComments[index].comment_replies.splice(childIndex, 1)
            }
          }
        })
      }
    }

    return {
      state,
      payload,
      tempComments,
      commonMethods,
      previewMediaImage,
      handlePostComment,
      loadMoreComments,
      addComment,
      deleteComment,
    }
  },
}
</script>
