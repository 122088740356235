var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-router social_share_modal social_share_only top_left_design_box"},[_c('div',{staticClass:"modal-dialog",class:[_vm.closeStatus ? 'animated fadeInDown' : 'animated fadeOutUp']},[_c('div',{staticClass:"modal-content"},[_c('model-layout',{attrs:{"status":_vm.closeStatus,"type":"Social","isCSV":_vm.isCSV}}),_c('div',{staticClass:"modal_body",staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"modal_inner_content basic_form",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"d-flex align-items-stretch"},[_c('div',{staticClass:"content",staticStyle:{"padding-right":"20px"}},[_c('div',{staticClass:"content_inner"},[(!_vm.getPublishLoaders.initializeArticlePostCreation)?_c('content-category-selection',{attrs:{"module":'publish'}}):_vm._e(),(
                    !_vm.getPublishLoaders.initializeArticlePostCreation &&
                    _vm.isGlobalContentCategory
                  )?_c('content-category-note'):_vm._e(),(
                    !_vm.getPublishLoaders.initializeArticlePostCreation &&
                    !_vm.isGlobalContentCategory
                  )?_c('account-selection',{attrs:{"showLabel":false}}):_vm._e(),(_vm.getPublishLoaders.initializeArticlePostCreation)?_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}}):_vm._e(),(_vm.getAccountSelection.instagram.length > 0)?_c('div',{staticClass:"input_field"},[_vm._m(0)]):_vm._e(),(
                    !_vm.getPublishLoaders.initializeArticlePostCreation &&
                    !_vm.isGlobalContentCategory
                  )?_c('toggle-selection'):_vm._e(),_c('single-box',{class:{
                    hide:
                      !_vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation,
                  }}),_c('facebook-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.facebook.length === 0,
                  }}),_c('twitter-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.twitter.length === 0,
                  }}),_c('linkedin-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.linkedin.length === 0,
                  }}),_c('pinterest-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.pinterest.length === 0,
                  }}),_c('tumblr-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.tumblr.length === 0,
                  }}),_c('instagram-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.instagram.length === 0,
                  }}),(
                    _vm.instagramSelection.no_access_accounts.length > 0 ||
                    (_vm.instagramSelection.all_access_accounts.length > 0 &&
                      _vm.zapierAttachmentError(_vm.getCommonSharingDetails)
                        .multiple_images)
                  )?_c('zapier-warnings',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getCommonBoxOptions.globalCategoryStatus ||
                      (_vm.getAccountSelection.instagram.length === 0 &&
                        !_vm.isGlobalContentCategorySelected) ||
                      (_vm.isGlobalContentCategorySelected &&
                        !_vm.getCommonBoxOptions.globalCategoryChannels
                          .instagram),
                  },attrs:{"multiple_images":_vm.zapierAttachmentError(_vm.getInstagramSharingDetails)
                      .multiple_images,"aspect_ratio":false,"no_access_accounts":_vm.instagramSelection.no_access_accounts,"all_access_accounts":_vm.instagramSelection.all_access_accounts}}):_vm._e(),_c('gmb-box',{class:{
                    hide:
                      _vm.getCommonBoxOptions.status ||
                      _vm.getPublishLoaders.initializeArticlePostCreation ||
                      _vm.getAccountSelection.gmb.length === 0,
                  }}),_c('error-section',{attrs:{"errors":_vm.socialPostContentErrors(),"warnings":_vm.socialPostContentWarnings()}}),(
                    _vm.instagramSelection.no_access_accounts.length > 0 ||
                    (_vm.instagramSelection.all_access_accounts.length > 0 &&
                      _vm.zapierAttachmentError(_vm.getCommonSharingDetails)
                        .multiple_images)
                  )?_c('zapier-warnings',{class:{
                    hide:
                      !_vm.getCommonBoxOptions.status ||
                      _vm.getCommonBoxOptions.globalCategoryStatus,
                  },attrs:{"multiple_images":_vm.zapierAttachmentError(_vm.getCommonSharingDetails)
                      .multiple_images,"aspect_ratio":false,"no_access_accounts":_vm.instagramSelection.no_access_accounts,"all_access_accounts":_vm.instagramSelection.all_access_accounts}}):_vm._e(),(!_vm.getPublishLoaders.initializeArticlePostCreation)?[_c('hashtag-suggessions'),(_vm.getPublishSelection.status !== 'published')?_c('div',{staticClass:"post_share_option"},[(_vm.getAccountSelection.gmb.length)?_c('gmb-options'):_vm._e(),(_vm.getPublishSelection.queueStatus)?_c('div',{staticClass:"post_footer text-right clear"},[_c('div',{staticClass:"warning_box pull-left"},[_vm._v("This post is queued and scheduled on "),_c('strong',[_vm._v(_vm._s(_vm.getWorkspaceTimeZoneTime( _vm.getPublishSelection.planExecutionTime['date'], 'MMM DD, hh:mm a' )))]),_c('i',{staticClass:"icon-edit-cs",on:{"click":function($event){$event.preventDefault();return _vm.editQueuePost()}}})]),_c('button',{staticClass:"btn btn-studio-theme-space",attrs:{"disabled":_vm.getPublishLoaders.processSocialShare ||
                          _vm.sharingPendingProcessStatus ||
                          _vm.getPublishLoaders.processSavePublication},on:{"click":function($event){$event.preventDefault();return _vm.processSocialShare()}}},[_c('span',[_vm._v("Update")]),(_vm.getPublishLoaders.processSocialShare)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'12px'}}):_vm._e()],1)]):_vm._e(),_c('publishing-time-options',{attrs:{"isInstagramSelected":_vm.instagramSelection.no_access_accounts.length > 0 ||
                        _vm.instagramSelection.all_access_accounts.length > 0,"no_access_accounts":_vm.instagramSelection.no_access_accounts,"all_access_accounts":_vm.instagramSelection.all_access_accounts,"zapierErrors":_vm.zapierAttachmentError(_vm.getCommonSharingDetails),"type":"Social"}})],1):_c('div',{staticClass:"post_share_option"},[_c('div',{staticClass:"post_footer"},[_c('div',{staticClass:"d-inline-block success_box warning_box_left"},[_c('p',[_vm._v("Published on "+_vm._s(_vm.getWorkspaceTimeZoneTime( _vm.getPublishSelection.planExecutionTime['date'], `MMMDD, hh:mm a` ))+" ")])])])])]:_vm._e()],2)]),_c('model-sidebar',{staticClass:"social_post_side_filter_model social_share_model_sidebar",attrs:{"type":"Social","isCSV":_vm.isCSV}})],1)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"compose_warning_box text-center"},[_c('p',[_c('a',{staticClass:"insta-link",attrs:{"href":"https://docs.contentstudio.io/article/487-publishing-scheduling-to-instagram-using-contentstudio","target":"_blank"}},[_c('i',{staticClass:"far fa-question-circle"}),_vm._v(" Click for the guide to Instagram publishing")])])])
}]

export { render, staticRenderFns }