<template>
  <ul class="inner p-4 align_center" v-if="getContentCategoryList.length === 0">
    <p class="no_label">You have not created any content categories yet.</p>
  </ul>

  <ul class="inner p-2" v-else-if="getContentCategoryList.length > 0">
    <!-- select All Categories checkbox  -->
    <!-- <li class="field_group status_option_list">
      <div class="checkbox_container">
        <label class="checkbox_right">
          <span>{{ ('Select All', 21) }}</span>
          <input
            v-model="getPublications.check_all_categories"
            type="checkbox"
            @change="selectAllCategories()"
          />
          <span class="check"></span>
        </label>
      </div>
    </li> -->
    <li
      class="field_group status_option_list"
      v-for="category in sortContentCategory(getContentCategoryList)"
    >
      <div class="checkbox_container">
        <label :for="category._id" class="checkbox_right">
          <span>{{ category.name }}</span>
          <a
            @click.prevent="changeComposerPlansCategoryOnlyFilter(category._id)"
            class="onlyLink"
            >Only</a
          >
          <input
            :id="category._id"
            :checked="isComposerCategorySelected(category._id)"
            v-model="getPublications.category"
            :value="category._id"
            type="checkbox"
          />
          <span class="check"></span>
        </label>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import sortBy from 'lodash.sortby'
import { EventBus } from '@common/lib/event-bus'

export default {
  props: [],
  components: {},

  data() {
    return {}
  },

  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getContentCategoryList.forEach((category) => {
        const categoriesId = []

        this.getContentCategoryList.forEach((category) => {
          categoriesId.push(category._id)
        })

        this.getPublications.category = [...categoriesId]
      })
    })

    EventBus.$on('set_members_for_composer', () => {
      if (this.$route.name === 'composer') {
        const categoriesId = []

        this.getContentCategoryList.forEach((category) => {
          categoriesId.push(category._id)
        })

        this.getPublications.category = [...categoriesId]
      }
    })
  },

  beforeDestroy() {
    EventBus.$off('set_members_for_composer')
  },

  computed: {
    ...mapGetters(['getPublications', 'getContentCategoryList']),
  },

  methods: {
    isComposerCategorySelected(category) {
      this.$store.commit(
        composer.SET_CHECK_ALL_CATEGORIES,
        this.getPublications.category.length ===
          this.getContentCategoryList.length
      )
      return this.getPublications.category.indexOf(category) >= 0
    },
    changeComposerPlansCategoryOnlyFilter(category) {
      this.$store.commit(composer.SET_PUBLICATIONS_CATEGORY, [category])
    },

    sortContentCategory(data) {
      return sortBy(data, function (item) {
        return [item.name.toLowerCase()]
      })
    },
    selectAllCategories() {
      if (this.getPublications.check_all_categories) {
        // Select All Categories
        this.$store.commit(
          composer.SET_PUBLICATIONS_CATEGORY,
          this.getPublicationCategoriesIds()
        )
      } else {
        // Unselect All Categories
        this.$store.commit(composer.SET_PUBLICATIONS_CATEGORY, [])
      }
    },
    // get ids of all categories
    getPublicationCategoriesIds() {
      const ids = []
      this.getContentCategoryList.forEach((category, index) => {
        ids.push(category._id)
      })
      return ids
    },
  },

  watch: {
    'getPublications.category'() {
      // console.log('umair bhai',this.getContentCategoryList,this.getPublications.category)
      if (this.$route.name === 'composer') {
        this.refetchPublications(
          this.getContentCategoryList.length ===
            this.getPublications.category.length
        )
      }
    },
  },
}
</script>
<style lang="less">
.onlyLink {
  visibility: hidden;
  text-decoration: underline;
  font-size: 11px !important;
  border: 1px solid #dee2e8;
  padding: 2px 9px;
  border-radius: 13px;
}
.onlyLink:hover {
  font-weight: bold;
}
.showlink:hover .onlyLink {
  visibility: visible;
}
.field_group.status_option_list {
  padding: 0rem 0.5rem;
}
.status_option_list {
  padding: 0.6rem 1rem;
  span.far {
    margin-right: 10px;
  }
  .checkbox_right {
    width: 100% !important;
    &:after {
      left: 95% !important;
    }
    &:before {
      left: 95% !important;
    }
    .onlyLink {
      margin: 0 0.5rem;
    }
  }
  &:hover {
    .onlyLink {
      visibility: unset;
      display: unset;
    }
  }
}
</style>
