import proxy from '@common/lib/http-common'
import {
  fetchImportantNotificationsUrl,
  fetchWorkspaceNotificationsUrl,
  markNotificationRead,
} from '@src/modules/setting/config/api-utils.js'
import { notificationTypes } from './mutation-types'

const defaultState = {
  important_notifications: [],
  important_notifications_visibility: true,
  workspace_notifications: {
    all_list: {
      data: [],
      current_page: 1,
      last_page: 1,
    },
    all_count: 0,

    // system
    system_list: {
      data: [],
      current_page: 1,
      last_page: 1,
    },
    system_count: 0,

    // team
    team_list: {
      data: [],
      current_page: 1,
      last_page: 1,
    },
    team_count: 0,

    // inbox
    inbox_list: {
      data: [],
      current_page: 1,
      last_page: 1,
    },
    inbox_count: 0,
    page: 1,
    scroll_status: false,

    // loaders
    loaders: {
      more: true,
    },
  },
}

const state = defaultState

// getters
const getters = {
  getImportantNotifications: (state) => state.important_notifications,
  getImportantNotificationsVisibility: (state) =>
    state.important_notifications_visibility,
  getWorkspaceNotifications: (state) => {
    return state.workspace_notifications
  },
  getTotalWorkspaceNotificationsCount: (state) => {
    const total =
      state.workspace_notifications.team_count +
      state.workspace_notifications.system_count +
      state.workspace_notifications.inbox_count
    console.log('total count', total)
    return total
  },
  getSystemWorkspaceNotificationsCount: (state) => {
    return state.workspace_notifications.system_count
  },
  getTeamWorkspaceNotificationsCount: (state) => {
    return state.workspace_notifications.team_count
  },
  getInboxWorkspaceNotificationsCount: (state) => {
    return state.workspace_notifications.inbox_count
  },
}

// actions
const actions = {
  fetchImportantNotifications({ commit, getters, dispatch }) {
    if (getters.getWorkspaces.activeWorkspace !== false) {
      proxy
        .post(fetchImportantNotificationsUrl, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            commit(
              notificationTypes.SET_IMPORTANT_NOTIFICATIONS_LIST,
              res.data.notifications
            )
          }
        })
        .catch(() => {})
    }
  },

  fetchWorkspaceNotifications(
    { commit, getters, dispatch, rootGetters, state },
    payload
  ) {
    commit('SET_WORKSPACE_NOTIFICATIONS_MORE', true)
    if (getters.getWorkspaces.activeWorkspace !== false) {
      proxy
        .post(fetchWorkspaceNotificationsUrl, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          current_page_all: state.workspace_notifications.all_list.current_page,
          current_page_system:
            state.workspace_notifications.system_list.current_page,
          current_page_team:
            state.workspace_notifications.team_list.current_page,
          current_page_inbox:
            state.workspace_notifications.inbox_list.current_page,
        })
        .then((resp) => {
          commit('SET_NOTIFICATIONS_SCROLL_STATUS', false)
          if (resp.data.status) {
            commit('SET_ALL_NOTIFICATIONS', resp.data.list.all)
            commit('SET_SYSTEM_NOTIFICATIONS', resp.data.list.system)
            commit('SET_TEAM_NOTIFICATIONS', resp.data.list.team)
            commit('SET_INBOX_NOTIFICATIONS', resp.data.list.inbox)

            commit('SET_ALL_NOTIFICATIONS_COUNT', resp.data.count.all_count)
            commit(
              'SET_SYSTEM_NOTIFICATIONS_COUNT',
              resp.data.count.system_count
            )
            commit('SET_TEAM_NOTIFICATIONS_COUNT', resp.data.count.team_count)
            commit('SET_INBOX_NOTIFICATIONS_COUNT', resp.data.count.inbox_count)

            // for lazy loading
            if (
              resp.data.list.all.data &&
              resp.data.list.all.data.length === 10
            ) {
              setTimeout(function () {
                commit('SET_NOTIFICATIONS_SCROLL_STATUS', true)
                if (payload.$state) payload.$state.loaded()
              }, 200)
              return false
            }
            if (payload.$state) payload.$state.complete()
          }
          commit('SET_WORKSPACE_NOTIFICATIONS_MORE', false)
        })
        .catch((error) => {
          console.log(error)
          commit('SET_WORKSPACE_NOTIFICATIONS_MORE', false)
          commit('SET_NOTIFICATIONS_SCROLL_STATUS', false)
        })
    }
  },

  markAllNotificationsRead({ commit, getters }) {
    proxy
      .post(markNotificationRead, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      })
      .then((resp) => {
        // set the notification value to read.

        state.workspace_notifications.all_list.data.filter((notification) => {
          if (notification.is_read === false) notification.is_read = true
        })

        state.workspace_notifications.system_list.data.filter(
          (notification) => {
            if (notification.is_read === false) notification.is_read = true
          }
        )

        state.workspace_notifications.team_list.data.filter((notification) => {
          if (notification.is_read === false) notification.is_read = true
        })
        state.workspace_notifications.inbox_list.data.filter((notification) => {
          if (notification.is_read === false) notification.is_read = true
        })
        state.workspace_notifications.all_count = 0
        state.workspace_notifications.system_count = 0
        state.workspace_notifications.team_count = 0
        state.workspace_notifications.inbox_count = 0
      })
      .catch((error) => {
        console.log(error)
      })
  },

  markNotificationRead({ commit, getters, dispatch }, notification) {
    // if the notification set to is_read false.

    if (notification.is_read === false) {
      notification.is_read = true

      dispatch('unsetNotificationsCount', notification)

      proxy
        .post(markNotificationRead, {
          workspace_id: notification.workspace_id,
          id: notification._id,
        })
        .then((resp) => {})
        .catch((error) => {
          console.log(error)
        })
    }
  },

  // add notifications from the browser to the state.
  addToNotifications({ commit }, data) {
    commit('ADD_ALL_NOTIFICATION', data)
    if (data.team) {
      commit('ADD_TEAM_NOTIFICATION', data)
    } else {
      if (data.type && data.type === 'inbox') {
        commit('ADD_INBOX_NOTIFICATION', data)
      } else {
        commit('ADD_SYSTEM_NOTIFICATION', data)
      }
    }
  },

  unsetNotificationsCount({ commit }, notification) {
    commit('UNSET_ALL_NOTIFICATIONS_COUNT')
    if (notification.team) {
      commit('UNSET_TEAM_NOTIFICATIONS_COUNT')
    } else {
      if (
        notification.notification_type &&
        notification.notification_type === 'inbox'
      ) {
        commit('UNSET_INBOX_NOTIFICATIONS_COUNT')
      } else {
        commit('UNSET_SYSTEM_NOTIFICATIONS_COUNT')
      }
    }
  },

  resetNotifications({ commit }) {
    commit('RESET_USER_NOTIFICATIONS')
  },
}

// mutations
const mutations = {
  ADD_ALL_NOTIFICATION(state, notification) {
    state.workspace_notifications.all_list.data.unshift(notification)
    state.workspace_notifications.all_count += 1
  },

  ADD_TEAM_NOTIFICATION(state, notification) {
    state.workspace_notifications.team_list.data.unshift(notification)
    state.workspace_notifications.team_count += 1
  },

  UNSET_ALL_NOTIFICATIONS_COUNT(state) {
    state.workspace_notifications.all_count -= 1
  },

  UNSET_TEAM_NOTIFICATIONS_COUNT(state) {
    state.workspace_notifications.team_count -= 1
  },

  SET_TEAM_NOTIFICATIONS(state, resp) {
    // if the page is 1, we just add the data to the array
    if (resp.current_page === 1) {
      state.workspace_notifications.team_list.data = resp.data
    } else {
      console.log('concat data...')
      state.workspace_notifications.team_list.data =
        state.workspace_notifications.team_list.data.concat(resp.data)
    }

    state.workspace_notifications.team_list.last_page = resp.lastPage
    state.workspace_notifications.team_list.current_page = resp.current_page + 1
  },

  SET_TEAM_NOTIFICATIONS_COUNT(state, data) {
    state.workspace_notifications.team_count = data
  },

  UNSET_INBOX_NOTIFICATIONS_COUNT(state) {
    state.workspace_notifications.inbox_count -= 1
  },

  ADD_INBOX_NOTIFICATION(state, notification) {
    state.workspace_notifications.inbox_list.data.unshift(notification)
    state.workspace_notifications.inbox_count += 1
  },

  SET_INBOX_NOTIFICATIONS(state, resp) {
    // if the page is 1, we just add the data to the array
    if (resp.current_page === 1) {
      state.workspace_notifications.inbox_list.data = resp.data
    } else {
      console.log('concat data...')
      state.workspace_notifications.inbox_list.data =
        state.workspace_notifications.inbox_list.data.concat(resp.data)
    }

    state.workspace_notifications.inbox_list.last_page = resp.lastPage
    state.workspace_notifications.inbox_list.current_page =
      resp.current_page + 1
  },

  SET_INBOX_NOTIFICATIONS_COUNT(state, data) {
    state.workspace_notifications.inbox_count = data
  },

  ADD_SYSTEM_NOTIFICATION(state, notification) {
    state.workspace_notifications.system_list.data.unshift(notification)
    state.workspace_notifications.system_count += 1
  },

  UNSET_SYSTEM_NOTIFICATIONS_COUNT(state) {
    state.workspace_notifications.system_count -= 1
  },

  SET_ALL_NOTIFICATIONS(state, resp) {
    if (resp.current_page === 1) {
      state.workspace_notifications.all_list.data = resp.data
    } else {
      state.workspace_notifications.all_list.data =
        state.workspace_notifications.all_list.data.concat(resp.data)
    }
    state.workspace_notifications.all_list.last_page = resp.lastPage
    state.workspace_notifications.all_list.current_page = resp.current_page + 1
  },

  SET_SYSTEM_NOTIFICATIONS(state, resp) {
    if (resp.current_page === 1) {
      state.workspace_notifications.system_list.data = resp.data
    } else {
      state.workspace_notifications.system_list.data =
        state.workspace_notifications.system_list.data.concat(resp.data)
    }
    state.workspace_notifications.system_list.last_page = resp.lastPage
    state.workspace_notifications.system_list.current_page =
      resp.current_page + 1
  },

  SET_ALL_NOTIFICATIONS_COUNT(state, data) {
    state.workspace_notifications.all_count = data
  },

  SET_SYSTEM_NOTIFICATIONS_COUNT(state, data) {
    state.workspace_notifications.system_count = data
  },

  SET_WORKSPACE_NOTIFICATIONS_MORE(state, value) {
    state.workspace_notifications.loaders.more = value
  },

  SET_NOTIFICATIONS_SCROLL_STATUS(state, status) {
    state.workspace_notifications.scroll_status = status
  },

  RESET_USER_NOTIFICATIONS(state) {
    state.workspace_notifications.all_list = {
      data: [],
      current_page: 1,
      last_page: 1,
    }
    state.workspace_notifications.all_count = 0

    state.workspace_notifications.system_list = {
      data: [],
      current_page: 1,
      last_page: 1,
    }
    state.workspace_notifications.system_count = 0

    state.workspace_notifications.team_list = {
      data: [],
      current_page: 1,
      last_page: 1,
    }
    state.workspace_notifications.team_count = 0

    state.workspace_notifications.inbox_list = {
      data: [],
      current_page: 1,
      last_page: 1,
    }
    state.workspace_notifications.inbox_count = 0

    state.workspace_notifications.page = 1
    state.workspace_notifications.loaders = {
      more: true,
    }
  },

  [notificationTypes.SET_IMPORTANT_NOTIFICATIONS_VISIBILITY](state, value) {
    state.important_notifications_visibility = value
  },

  [notificationTypes.SET_IMPORTANT_NOTIFICATIONS_LIST](state, value) {
    state.important_notifications = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
