<template>
  <div class="topics-insights-container__statistics">
    <h2>Summary</h2>
    <div class="topics-insights-container__statistics__box__container">
      <div class="topics-insights-container__statistics__box">
        <div
          class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_first_child"
        >
          <h4> Article Analyzed </h4>
          <h3>{{ intToString(total_articles) }}</h3>
        </div>
      </div>
      <div class="topics-insights-container__statistics__box">
        <div class="topics-insights-container__statistics__box__inner">
          <h4> Total Engagements </h4>
          <h3>{{ intToString(total_engagements) }}</h3>
        </div>
      </div>
      <div class="topics-insights-container__statistics__box">
        <div class="topics-insights-container__statistics__box__inner">
          <h4> Avg. Engagements </h4>
          <h3>{{ intToString(avg_engagements) }}</h3>
        </div>
      </div>
      <div class="topics-insights-container__statistics__box">
        <div
          class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_last_child"
        >
          <h4> Avg. Engagement By Channel </h4>
          <div
            class="topics-insights-container__statistics__box__inner__channels"
          >
            <div
              class="topics-insights-container__statistics__box__inner__channels__item"
            >
              <i class="fb fab fa-facebook-f"></i>
              <h3>{{ intToString(average_facebook_engagement) }}</h3>
            </div>
            <div
              class="topics-insights-container__statistics__box__inner__channels__item"
            >
              <i class="reddit fab fa-reddit"></i>
              <h3>{{ intToString(average_reddit_engagement) }}</h3>
            </div>
            <div
              class="topics-insights-container__statistics__box__inner__channels__item"
            >
              <i class="pin fab fa-pinterest-p"></i>
              <h3>{{ intToString(average_pinterest_engagement) }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total_articles: { default: 0 },
    total_engagements: { default: 0 },
    avg_engagements: { default: 0 },
    average_facebook_engagement: { default: 0 },
    average_pinterest_engagement: { default: 0 },
    average_twitter_engagement: { default: 0 },
    average_reddit_engagement: { default: 0 }
  }
}
</script>
