<template>
  <div class="authentication_component">
    <AuthenticationLeft></AuthenticationLeft>
    <div class="auth_right login_block">
      <div class="d-flex align-items-center height" style="overflow: auto">
        <div class="_wrapper m-auto">
          <div class="right_logo">
            <img src="../../assets/img/logo/logo_text_logo.png" alt="" />
          </div>
          <div v-if="loader" class="auth_right_inner">
            <div class="top_block">
              <beat-loader :color="'#436aff'"></beat-loader>
            </div>
          </div>
          <div v-else class="auth_right_inner">
            <div class="top_block">
              <h2>Link invalid or expired</h2>
              <p
                >Your token is either invalid or has been expired. Please
                request for the new Magic link from here.</p
              >
              <div class="btn_block mt-4">
                <button
                  class="btn btn_loader loader_right"
                  @click.prevent="$router.push({ name: 'magicLogin' })"
                >
                  <span>Request New Link</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { authenticationTypes } from '@state/mutation-types'
import { checkValidMagicTokenURL } from '@src/config/api-utils.js'
import AuthenticationLeft from './AuthenticationLeft.vue'

export default {
  components: {
    AuthenticationLeft,
  },
  data() {
    return {
      is_valid_token: false,
      password: '',
      confirm_password: '',
      loader: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getJWTToken']),
  },
  created() {
    this.verifyToken()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    async verifyToken() {
      this.loader = true
      let magicToken = ''
      if (this.$route.query && this.$route.query.magic_token) {
        magicToken = this.$route.query.magic_token
      }
      const resp = await this.$http
        .post(checkValidMagicTokenURL, { token: magicToken })
        .then(
          async (response) => {
            if (response.data.status) {
              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                  },
                })
                return
              }

              // set token of logged user
              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
            } else {
              this.loader = false
              return false
            }
            return response
          },
          (response) => {
            this.loader = false
            return false
          }
        )
      if (
        (resp && resp.data.status) ||
        (this.getJWTToken && this.getJWTToken.length > 5)
      ) {
        if (this.isDeletedAccount() === true) return
        if (this.isEmailVerified() === false) return
        if (this.isSubscriptionExpired() === true) return
        if (this.isTrialExpired() === true) return

        // fetch profile and workspace data
        await this.fetchProfile()

        if (this.getProfile && this.getProfile.need_to_reset_password) {
          this.$router.push({ name: 'setPassword' })
          return
        }

        if (this.$route.query.url) {
          window.location.href = this.$route.query.url
        } else {
          this.fetchWorkspaces()
          this.$router.push({ name: 'workspaces' })
        }
      }
    },
  },
}
</script>
