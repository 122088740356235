var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll_content"},[(_vm.getInfluencerLoaders.top_mentioned)?[_c('beat-loader',{staticClass:"pt-4",attrs:{"color":'#436aff'}})]:(_vm.top_mentioned && _vm.top_mentioned.length > 0)?_vm._l((_vm.top_mentioned),function(mention,index){return _c('div',{staticClass:"twitter_influencer_box"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"t_left"},[_c('div',{staticClass:"d-flex item_row"},[_c('div',{staticClass:"row_left"},[_c('div',{staticClass:"picture_box"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(mention.profile_image_url_https),expression:"mention.profile_image_url_https",arg:"background-image"}],staticClass:"img"})])]),_c('div',{staticClass:"row_right border_bottom"},[_c('div',{staticClass:"top_content"},[_c('h3',{attrs:{"data-cy":'top_mention_name_' + index},domProps:{"innerHTML":_vm._s(_vm.twitterUsernameHtml(mention, index))}}),(mention.description)?_c('p',{domProps:{"innerHTML":_vm._s(mention.description)}}):_vm._e()])])]),_c('div',{staticClass:"d-flex item_row"},[_c('div',{staticClass:"row_left"},[(mention.created_at)?[_c('p',[_vm._v(_vm._s(_vm.$filters.relative(mention.created_at)))])]:_vm._e()],2),(mention.status && mention.status.text)?_c('div',{staticClass:"row_right"},[_c('div',{staticClass:"bottom_content"},[_c('p',{domProps:{"innerHTML":_vm._s(
                      _vm.twitterText(
                        mention.status.text,
                        mention.status.entities.hashtags,
                        mention.status.entities.urls,
                        mention.status.entities.user_mentions
                      )
                    )}})])]):_vm._e()])]),_c('div',{staticClass:"t_right"},[_c('p',[_vm._v("Followers")]),_c('h3',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.stringToInt(mention.followers_count)),expression:"stringToInt(mention.followers_count)",modifiers:{"top-center":true}}],attrs:{"data-cy":'top_mention_followers_' + index}},[_vm._v(_vm._s(_vm.intToString(mention.followers_count)))])])])])])}):[_vm._m(0)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"not_found_text pt-2"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',{attrs:{"data-cy":"top_mention_no_data"}},[_vm._v(" No data found.")])])])])
}]

export { render, staticRenderFns }