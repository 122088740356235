<template>
  <div class="right_filter" v-if="getSearchContentType === 'article'">
    <!-- if the filter is topic and posts length is 0, do not show the filters.-->
    <!--<template v-if="getFilters.topic && getPosts.length == 0 ">-->
    <!--</template>-->
    <!-- if the content has been searched by a keyword, show all of the filters-->
    <template>
      <div
        v-tooltip.top-center="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <filter-sort></filter-sort>
      </div>

      <filter-date></filter-date>
      <div
        v-if="isRegionFiltersAllowed"
        v-tooltip.top-center="'Region'"
        class="ml-2 dropdown option_dropdown default_style_dropdown"
      >
        <filter-region></filter-region>
      </div>
      <div
        v-if="isRegionFiltersAllowed"
        v-tooltip.top-center="'Language'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <filter-language></filter-language>
      </div>
      <div
        v-tooltip.top-center="'Length'"
        class="dropdown option_dropdown default_style_dropdown hide"
      >
        <filter-type></filter-type>
      </div>
    </template>
  </div>
</template>
<script>
import FilterType from '../../../filters/FilterType'
import FilterSort from '../../../filters/FilterSort'
import FilterRegion from '../../../filters/FilterRegion'
import FilterLanguage from '../../../filters/FilterLanguage'
import FilterDate from '../../../filters/FilterDate'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    FilterType,
    FilterSort,
    FilterRegion,
    FilterLanguage,
    FilterDate
  },
  computed: {
    ...mapGetters(['getSearchContentType']),
    isRegionFiltersAllowed () {
      return this.$route.query.q && this.$route.query.q.length > 0
    }
  }
}
</script>
