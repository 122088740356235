<template> </template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  created() {
    // this.$router.push({'name': 'login'})
  },
  mounted() {},
  computed: {
    ...mapGetters(['getJWTToken']),
  },
  methods: {
    ...mapActions(['setExpireToken', 'setLoggedUserExpire']),
  },
}
</script>
