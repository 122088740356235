<template>
  <div :class="{ disable_click: getTopicDisabled }">
    <div
      v-tooltip.top-center="'Sort By'"
      class="dropdown option_dropdown default_style_dropdown"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="sorting_icon icon_first cs-filter"></i>
        <span class="text">{{ getDailymotionSort.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li
            class="list_item"
            @click.prevent="changeDailymotionSort('Relevance', 'relevance')"
          >
            <a class="dropdown-item">Relevance</a>
          </li>
          <li
            class="list_item"
            @click.prevent="changeDailymotionSort('Trending', 'trending')"
          >
            <a class="dropdown-item">Trending</a>
          </li>
          <li
            class="list_item"
            @click.prevent="changeDailymotionSort('Most Viewed', 'visited')"
          >
            <a class="dropdown-item">Most Viewed</a>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="false"
      v-tooltip.top-center="'Region'"
      class="dropdown option_dropdown default_style_dropdown"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first cs-flag"></i>
        <span class="text">{{ getDailymotionRegion.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-left">
        <div class="inner">
          <li
            class="list_item"
            v-for="region in regions"
            @click.prevent="changeDailymotionRegion(region)"
            :value="region.code"
          >
            <a href="#">{{ region.name }}</a>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { videosRegion } from '@common/constants/regions'

export default {
  data () {
    return {
      videosRegion: videosRegion
    }
  },
  created () {
    this.regions = videosRegion
  },
  computed: {
    ...mapGetters(['getTopicDisabled', 'getDailymotionSort'])
  },
  methods: {}
}
</script>
