import { confirmation } from './mutation-types'

export default {
  state: {
    paylaod: [],
    stage: '',
  },
  getters: {
    getConfirmationPaylaod: (state) => {
      return state.paylaod
    },
    getConfirmationStage: (state) => {
      return state.stage
    },
  },
  actions: {
    hideConfirmationBox({ commit, getters, rootGetters, dispatch }, model) {
      setTimeout(function () {
        commit(confirmation.SET_PAYLOAD, [])
        setTimeout(function () {
          commit(confirmation.SET_STAGE, '')

          // remove this in future when we combine confirmationBoxes
          commit(confirmation.SET_CONFIRMATION_STAGE, '')
        }, 1000)

        $('#' + model).modal('hide')
      }, 1000)
    },
  },
  mutations: {
    [confirmation.SET_PAYLOAD](state, paylaod) {
      state.paylaod = paylaod
    },

    [confirmation.SET_STAGE](state, stage) {
      state.stage = stage
    },
  },
}
