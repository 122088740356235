<template>
  <div class="grid-flex">
    <div class="cell-lg-4 pr-0 mb-2 pl-0">
      <div class="compare-box">
        <div class="compare-box__header d-flex align-items-center">
          <div class="compare-box__platform">
            <i class="fab fa-instagram ins "></i>
          </div>
          Fans (Page Followers)
          <div class="ml-auto position-relative">
            <i
                v-b-popover.hover.html.topleft="fans_description"
                class="fa fa-info info_hover"
            ></i>
          </div>
        </div>
        <div
            v-if="ranking.length <= 0"
            class="opacity-70"
            style="display: flex; justify-content: center"
        >
          <clip-loader
              class="ml-2"
              :color="'#5773fa'"
              :size="'20px'"
          ></clip-loader>
        </div>
        <div v-for="(competitor, i) in ranking" :key="i" class="compare-box__ranking">
          <div
              v-if="competitor.ig_id == selected_account_id"
              class="compare-box__ranking-cs our-color"
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id]['image']"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.followers) }}</p>
            </div>
          </div>
          <div
              v-else
              class="compare-box__ranking-competitor their-color "
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id]['image']"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.followers) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell-lg-4 pr-0 mb-2">
      <div class="compare-box">
        <div class="compare-box__header d-flex align-items-center">
          <div class="compare-box__platform">
            <i class="fab fa-instagram ins "></i>
          </div>
          Total Posts
          <div class="ml-auto position-relative">
            <i
                v-b-popover.hover.html.topleft="post_description"
                class="fa fa-info info_hover"
            ></i>
          </div>
        </div>
        <div
            v-if="ranking.length <= 0"
            class="opacity-70"
            style="display: flex; justify-content: center"
        >
          <clip-loader
              class="ml-2"
              :color="'#5773fa'"
              :size="'20px'"
          ></clip-loader>
        </div>
        <div v-for="(competitor, i) in ranking" :key="i" class="compare-box__ranking">
          <div
              v-if="competitor.ig_id == selected_account_id"
              class="compare-box__ranking-cs our-color"
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id].image"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.post_count) }}</p>
            </div>
          </div>
          <div
              v-else
              class="compare-box__ranking-competitor their-color"
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id].image"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.post_count) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell-lg-4 pr-0 mb-2">
      <div class="compare-box">
        <div class="compare-box__header d-flex align-items-center">
          <div class="compare-box__platform">
            <i class="fab fa-instagram ins"></i>
          </div>
          Average Engagement Rate
          <div class="ml-auto position-relative">
            <i
                v-b-popover.hover.html.topleft="avg_eng_description"
                class="fa fa-info info_hover"
            ></i>
          </div>
        </div>
        <div
            v-if="ranking.length <= 0"
            class="opacity-70"
            style="display: flex; justify-content: center"
        >
          <clip-loader
              class="ml-2"
              :color="'#5773fa'"
              :size="'20px'"
          ></clip-loader>
        </div>
        <div v-for="(competitor, i) in ranking" :key="i" class="compare-box__ranking">
          <div
              v-if="competitor.ig_id == selected_account_id"
              class="compare-box__ranking-cs our-color"
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id].image"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.avg_engagement) }}</p>
            </div>
          </div>
          <div
              v-else
              class="compare-box__ranking-competitor their-color"
              :class="i + 1 == ranking.length ? '' : 'mb-3'"
          >
            <div class="compare-box__ranking-competitor__ranking-c-content">
              <img
                  class="compare-box__ranking-logo"
                  :src="active_competitors[competitor.ig_id].image"
                  alt=""
                  @error="$event.target.src = fallbackImagePreview(active_competitors[competitor.ig_id].display_name[0])"
              />
              <div class="compare-box__ranking-label">
                <div>{{ active_competitors[competitor.ig_id].display_name }}</div>
              </div>
            </div>
            <div class="compare-box__ranking-value align-items-center d-flex flex-shrink-0 mr-2">
              <p>{{ intToString(competitor.avg_engagement) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="less">
.our-color {
  background: #3797f314;
  padding: 5px;
  border-radius: 8px;
}

.their-color {
  background: #0000000b;
  padding: 5px;
  border-radius: 8px;
}
</style>

<script>
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import api_ops from "./api_ops";

export default {
  name: 'CompareBox',
  mixins: [analyticsUtilsMixin, api_ops],
  props: ['reload', 'selected_competitors', 'time_period', 'selected_account_id'],
  data() {
    return {
      ranking: [],
      requestCancelPromise: {},
      fans_description: '<span class="font-0-75rem">This section displays total followers count with their relevant pages,' +
          ' helps in comparison of page mass in terms of followers.</span>',
      post_description: '<span class="font-0-75rem">This section displays total page posts with their relevant pages, can be a useful ' +
          'metric to compare your post frequency with other platform users.</span>',
      avg_eng_description: '<span class="font-0-75rem">This section displays fans engagement rate with reference to page posts, the ' +
          'metric is calculated by:</br><table class="formula-table"><tr><td>Total Engagement On Page</td></tr> <tr><td>Total Number Of Posts On Page</td></tr></table>' +
          'The metric is calculated with in the bounds of applied date filter.</span>',
      active_competitors: {}
    }
  },
  watch: {
    reload(newValue, oldValue) {
      this.active_competitors = {}
      this.ranking = []
      this.fetchRankingData()
    }
  },
  mounted() {
    this.fetchRankingData()
  },
  methods: {
    fetchRankingData() {
      this.selected_competitors.forEach((obj) => {
        if (obj.enabled && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj.competitor_id] = obj
        }
      })
      this.cancelPendingRequest('getRankings')
      const ids = Object.keys(this.active_competitors);
      const promise = this.getIgRanking(ids, this.time_period)
      this.requestCancelPromise.getRankings.push(promise[0])
      promise[1].then((res) => {
        let ours = {}
        let theirs = res.data.data.filter((obj) => {
          if (obj.ig_id == this.selected_account_id) {
            ours = obj
          } else {
            return obj
          }
        })
        theirs = theirs.sort((a, b) => this.active_competitors[a.ig_id].display_name > this.active_competitors[b.ig_id].display_name ? 1 : -1)
        this.ranking = [ours, ...theirs]
      })
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
  }

}
</script>
