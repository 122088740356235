<template>
  <div
    v-on-clickaway="closeSearchHistoryDropdown"
    class="search_dropdown search_history_items"
  >
    <template v-if="Object.keys(this.keywords).length > 0">
      <div class="search_dropdown_inner">
        <div class="recent_searches">
          <div class="text"> Recent Searches </div>
          <div class="clear_all_action" @click="clearAllSearchHistoryAction()">
            Clear All
          </div>
        </div>
        <ul
          v-if="Object.keys(this.keywords).length > 0"
          class="search_history_item_element"
        >
          <!--<li class="head">Related Keywords</li>-->
          <template
            v-if="index <= 4"
            v-for="(value, index) in getSearchKeywords"
          >
            <li
              class="item"
              :class="{ active: value === search_key_input.key }"
            >
              <span
                class="li_text"
                @click="
                  influencerSearchQueryFromSuggestion(value.toLowerCase())
                "
              >
                <i class="far fa-clock"></i>{{ value }}
              </span>
              <span
                class="li_action"
                v-tooltip="'Remove'"
                @click="clearIndividualSearchHistoryAction(value, index)"
              >
                <i class="far fa-times"></i>
              </span>
            </li>
          </template>
        </ul>
        <div class="show_history_operators">
          <h5 @click="show_search_operators = !show_search_operators"
            ><i
              class="fas fa-caret-right"
              :class="{ 'carret-rotate-animation': show_search_operators }"
            ></i
            >Search Operators</h5
          >
          <transition name="slide-fade">
            <influencers-search-operators-list
              v-if="show_search_operators"
            ></influencers-search-operators-list>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="less" scoped>
@ds-darkGray: #4a4a4a; // article social stats text color
@ds-lightGray: #989eb5;
@ds-lightGray_3: #72778a; // social tab icon background // search input border color
.carret-rotate-animation {
  transform: rotate(90deg);
}

.search_history_items {
  margin-top: 12px;

  .recent_searches {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(58, 69, 87, 0.08);

    .text {
      color: @ds-lightGray;
      flex: 2 0 0;
    }

    .clear_all_action {
      color: @ds-lightGray;
      cursor: pointer;
      -webkit-transition: all 0.2s ease-in !important;
      -moz-transition: all 0.2s ease-in !important;
      -o-transition: all 0.2s ease-in !important;
      transition: all 0.2s ease-in !important;

      &:hover {
        color: @ds-lightGray_3;
        text-decoration: underline;
      }
    }
  }

  .search_history_item_element {
    padding-bottom: 0 !important;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 !important;
      margin: 5px !important;
      -webkit-transition: all 0.1s ease-in !important;
      -moz-transition: all 0.1s ease-in !important;
      -o-transition: all 0.1s ease-in !important;
      transition: all 0.1s ease-in !important;

      i {
        margin-left: 5px;
        padding-right: 0.625rem;
      }

      .li_text {
        flex: 2 0 0;
        padding: 5px 0;
      }

      .li_action {
        i {
          &:hover {
            color: @ds-lightGray_3 !important;
          }
        }
      }

      .li_action:hover > i {
        color: @ds-lightGray_3 !important;
      }
    }

    .item.active {
      background: #f5f9fc !important;
    }
  }

  .show_history_operators {
    border-top: 2px solid rgba(58, 69, 87, 0.08);
    padding: 10px 0;
    padding-left: 20px;
    color: @ds-lightGray;

    h5 {
      width: 100%;
      display: flex;
      font-size: 0.875rem;
      cursor: pointer;

      i {
        margin-right: 15px;
        -webkit-transition: all 0.2s ease-in !important;
        -moz-transition: all 0.2s ease-in !important;
        -o-transition: all 0.2s ease-in !important;
        transition: all 0.2s ease-in !important;
      }
    }

    .search_dropdown_inner {
      box-shadow: 0 0 0 0 !important;
      border: 0 !important;
    }
  }
}
</style>
<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import InfluencersSearchOperatorsList from './InfluencersSearchOperatorsList'

export default {
  components: {
    InfluencersSearchOperatorsList
  },
  props: {
    keywords: {},
    search_key_input: {}
  },
  data () {
    return {
      show_search_operators: false,
      show_twitter_search_operators: false
    }
  },
  computed: {
    ...mapGetters([]),
    getSearchKeywords () {
      return this.keywords
    }
  },
  created () {
    console.debug('in search History', Object.keys(this.keywords).length)
  },
  methods: {
    closeSearchHistoryDropdown () {
      EventBus.$emit('show-influencers-search-history')
    },
    influencerSearchQueryFromSuggestion (value) {
      this.$router.push({ query: { ...this.$route.query, q: value } })
      this.$parent.searchKeyword = value
      this.$parent.searchInfluencersByKeyword()
    },
    async clearIndividualSearchHistoryAction (value, index) {
      const res = await this.$store.dispatch('deleteIndividualSearchHistory', {
        query: value,
        type: 'influencers'
      })
      if (res && res.data.status) {
        EventBus.$emit('clear-influencer-individual-search-history', {
          query: value,
          index: index
        })
      }
    },
    async clearAllSearchHistoryAction () {
      const res = await this.$store.dispatch(
        'deleteAllSearchHistory',
        'influencers'
      )
      if (res && res.data.status) {
        EventBus.$emit('clear-influencer-search-history')
      }
    }
  }
}
</script>
