<template>
  <div class="cursor-pointer flex-shrink-0"
       @click.stop="() => displayFile()"
  >
    <img class="rounded pointer-events-none object-cover mr-3.5"
         :class="isCompactView ? 'w-20 h-14' : 'w-32 h-24'"
         :src="getResizedImageURL(getPlanAttachment, 150, 0)"
         alt=""
         @error="onErrorHandle($event)">
  </div>
</template>

<script>

import {getPlanMedia, planHeadAttachment} from "@common/lib/planner";
import {EventBus} from "@common/lib/event-bus";

export default {
  props: ['item', 'isCompactView'],
  computed: {
    getPlanAttachment () {
      const attachment = planHeadAttachment(this.item)
      if (attachment.url) {
        return attachment.type === 'image'
            ? attachment.url
            : attachment.thumbnail
      }
      return false
    },
  },
  methods: {
    onErrorHandle(event) {
      event.target.src = require('@src/assets/img/no_data_images/no_media_found.svg');
    },
    displayFile () {
      console.debug('Method::displayFile')
      const attachment = getPlanMedia(this.item)
      if (attachment) {
        EventBus.$emit('displayFile', {
          type: attachment.type,
          media: attachment.media,
          index: 0
        })
        this.$bvModal.show('display-file-modal')
      }
    }
  }
};
</script>
