import { pinterest } from '@src/modules/integration/store/states/mutation-types'
import proxy from '@common/lib/http-common'
import {
  CREATE_BOARD_ERROR,
  CREATE_BOARD_SUCCESS,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import {
  createBoardURL,
  createProfileAndBoardManuallyURL
} from '@src/modules/integration/config/api-utils'

const defaultPinterestAccountsState = {
  items: [],
  all_items: []
}

const getDefaultPinterestAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultPinterestAccountsState()
  },
  getters: {
    getPinterestAccounts: (state) => state.accounts,
    getPinterestProfiles: (state) => {
      if (state.accounts.items.length > 0) {
        return state.accounts.items.filter((item) => {
          return item.type.toLowerCase() === 'profile'
        })
      }
      return []
    },
    getPinterestBoards: (state) => {
      if (state.accounts.items.length > 0) {
        return state.accounts.items.filter((item) => {
          return item.type.toLowerCase() === 'board'
        })
      }
      return []
    }
  },
  actions: {
    async createProfileAndBoardsManually (
      { commit, getters, rootGetters, dispatch },
      data
    ) {
      return proxy
        .post(createProfileAndBoardManuallyURL, data)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: response.data.message,
              type: 'success'
            })
          } else {
            dispatch('toastNotification', {
              message: response.data.message
                ? response.data.message
                : 'Failed to create Pinterest Profile and Boards.',
              type: 'error'
            })
          }
          return response
        })
        .catch((error) => {
          console.debug('Exception in processBoardCreation', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    async processBoardCreation (
      { commit, getters, rootGetters, dispatch },
      data
    ) {
      console.debug('Action::processBoardCreation', data)

      return proxy
        .post(createBoardURL, data)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: CREATE_BOARD_SUCCESS,
              type: 'success'
            })
          } else {
            dispatch('toastNotification', {
              message: response.data.message
                ? response.data.message
                : CREATE_BOARD_ERROR,
              type: 'error'
            })
          }
          return response
        })
        .catch((error) => {
          console.debug('Exception in processBoardCreation', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    }
  },
  mutations: {
    [pinterest.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> {
        if(a.name && b.name){
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        }
      })
    },

    [pinterest.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> {
        if(a.name && b.name){
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        }
      })
    },

    [pinterest.SET_DEFAULT] (state) {
      state.accounts = getDefaultPinterestAccountsState()
    }
  }
}
