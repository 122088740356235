var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytic_common_component custom_report_main"},[_c('div',{staticClass:"component_inner"},[_c('div',{staticClass:"report_list clear"},[_c('h2',{staticClass:"heading"},[_vm._v("Download Reports")]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"card_content mt-3"},[_c('div',{staticClass:"social_table"},[_c('div',{staticClass:"table_wrapper"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',[(_vm.getDownloadReports.list.length > 0)?_vm._l((_vm.getDownloadReports.list),function(item,key){return _c('tr',[_c('td',[_c('div',{},[(item.type === 'group')?[_vm._l((item.accounts),function(account){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                content: _vm.channelTooltipHTML(
                                  account.account_type,
                                  account
                                ),
                                classes: 'team_tooltip',
                              }),expression:"{\n                                content: channelTooltipHTML(\n                                  account.account_type,\n                                  account\n                                ),\n                                classes: 'team_tooltip',\n                              }"}],staticClass:"d-inline-block mr-3"},[_c('individual-channel-tooltip',{attrs:{"account":account,"type":account.account_type}})],1)]})]:[_vm._l((item.accounts),function(account){return (_vm.channelItem(account, item.type))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                content: _vm.channelTooltipHTML(
                                  item.type,
                                  _vm.channelItem(account, item.type)
                                ),
                                classes: 'team_tooltip',
                              }),expression:"{\n                                content: channelTooltipHTML(\n                                  item.type,\n                                  channelItem(account, item.type)\n                                ),\n                                classes: 'team_tooltip',\n                              }"}],staticClass:"d-inline-block mr-3"},[_c('individual-channel-tooltip',{attrs:{"account":_vm.channelItem(account, item.type),"type":item.type}})],1)]:_vm._e()})]],2)]),_c('td',{staticClass:"make-text-center uppercase"},[_vm._v(" "+_vm._s(item.type)+" Report ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" PDF ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" "+_vm._s(_vm.$filters.convertToWeekdaySplit(item.date))+" ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" "+_vm._s(_vm.$filters.convertToWeekday(item.created_at))+" ")]),_c('td',{},[_c('div',{staticClass:"action_icons text-right"},[_c('a',{attrs:{"href":item.export_url,"target":"_blank"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Download Report'),expression:"'Download Report'"}],staticClass:"icon_table fa fa-download"})]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Report'),expression:"'Remove Report'"}],staticClass:"icon_table icon-delete-cs",on:{"click":function($event){return _vm.removeDownloadReportService({
                              reportId: item._id,
                              index: key,
                            })}}})])])])}):[_vm._m(1)]],2)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"35%"}},[_vm._v("Accounts")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"200px"}},[_vm._v("Type")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"124px"}},[_vm._v("Format")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"300px"}},[_vm._v("Period")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"180px"}},[_vm._v("Creation date")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"120px"}},[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"make-text-center",attrs:{"colspan":"6"}},[_vm._v(" You have not exported any report yet. ")])])
}]

export { render, staticRenderFns }