<template>
  <div class="net_follower_box">
    <div class="box_inner d-flex align-items-center">
      <div class="left">
        <div class="d-flex text flag_text">
          <img
            v-if="type === 'city'"
            class="locate-img"
            src="../../../../../assets/img/map-pin.svg"
          />
          <img
            v-else
            class="fl-img"
            :src="
              '~@src/assets/img/analytics/flags/' + name.toLowerCase() + '.svg'
            "
          />
          <h3 class="center flag_text p-3">
            {{ getOriginalName() }}
          </h3>
        </div>
      </div>
      <div class="righ ml-auto">
        <div class="text">
          <h4 class="top_text">{{ value }} </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isoCountries } from '@common/constants/iso-countries'

export default {
  props: {
    name: '',
    type: '',
    value: 0
  },
  methods: {
    getOriginalName () {
      return this.type === 'country' ? isoCountries[this.name] : this.name
    }
  }
}
</script>
