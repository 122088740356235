<template>
  <div>
    <div v-if="status === false" class="top_head_slider top_side offline_bar">
      <div class="notificationCarousel pink">
        <div class="d-flex align-items-center justify-content-between">
          <span class="">&nbsp;</span>
          <span class="text">Connection lost, Please reload.</span>
          <button class="ml-4 btn btn_white large_btn" @click="refreshPage"
            >Reconnect Now</button
          >
        </div>
        <!--<button type="button" class="close close_slider" >&times;</button>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Offlinejs from 'offline-js'

const publicPath = process.env.BASE_URL

export default {
  data() {
    return {
      status: null,
    }
  },
  mounted() {
    // Set our options for the Offline detection library
    Offline.options = {
      checkOnLoad: false,
      checks: {
        image: {
          url: function () {
            return publicPath + 'img/statusCheckDontRemove.png'
          },
        },
        active: 'image',
      },
    }
    // binding events for status
    Offline.on('up', this.internetUp)
    Offline.on('down', this.internetDown)

    // Checking if status online or offline
    if (Offline.state == 'up') {
      this.status = true
    } else if (Offline.state == 'down') {
      this.status = false
    } else {
      this.status = null
    }
  },
  computed: {
    ...mapGetters([]),
  },

  methods: {
    refreshPage() {
      window.location.reload()
    },
    internetUp() {
      console.log('Internet is up.')
      this.status = true
      this.loadUserBroadCasters()
      // this.setInboxNotificationBroadcast()
    },

    internetDown() {
      console.log('Internet is down.')
      this.status = false
    },
  },
}
</script>
