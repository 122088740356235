<template>
  <div ref="heatmap-chart-container" class="h-chart">
    <highcharts :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import { heatMapXAxis } from '../../../components/common/helper'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: 'HeatmapChart',
  data () {
    return {
      dataOptions: {}
    }
  },
  props: {
    categories: {},
    series: {
      default: [
        {
          name: '',
          borderWidth: 1.3,
          borderColor: '#FFFFFF',
          data: [
            [0, 0, 10],
            [0, 1, 19],
            [0, 2, 8],
            [0, 3, 24],
            [0, 4, 67],
            [1, 0, 92],
            [1, 1, 58],
            [1, 2, 78],
            [1, 3, 117],
            [1, 4, 48],
            [2, 0, 35],
            [2, 1, 15],
            [2, 2, 123],
            [2, 3, 64],
            [2, 4, 52],
            [3, 0, 72],
            [3, 1, 132],
            [3, 2, 114],
            [3, 3, 19],
            [3, 4, 16],
            [4, 0, 38],
            [4, 1, 5],
            [4, 2, 8],
            [4, 3, 117],
            [4, 4, 115],
            [5, 0, 88],
            [5, 1, 32],
            [5, 2, 12],
            [5, 3, 6],
            [5, 4, 120],
            [6, 0, 13],
            [6, 1, 44],
            [6, 2, 88],
            [6, 3, 98],
            [6, 4, 96],
            [7, 0, 31],
            [7, 1, 1],
            [7, 2, 82],
            [7, 3, 32],
            [7, 4, 30],
            [8, 0, 85],
            [8, 1, 97],
            [8, 2, 123],
            [8, 3, 64],
            [8, 4, 84],
            [9, 0, 47],
            [9, 1, 114],
            [9, 2, 31],
            [9, 3, 48],
            [9, 4, 91]
          ],
          dataLabels: {
            enabled: false
          }
        }
      ]
    }
  },
  mounted () {
    this.initializeDataOptions()
    this.$nextTick(function () {
      this.dataOptions.chart.height =
        this.$refs['heatmap-chart-container'].offsetHeight - 32
    })
  },
  methods: {
    getPointCategoryName (point, dimension) {
      const series = point.series
      const isY = dimension === 'y'
      const axis = series[isY ? 'yAxis' : 'xAxis']
      return axis.categories[point[isY ? 'y' : 'x']]
    },
    initializeDataOptions () {
      this.dataOptions = {
        title: {
          text: ' '
        },

        chart: {
          type: 'heatmap',
          marginTop: 20,
          height: this.$refs['heatmap-chart-container'].offsetHeight - 32
        },
        credits: {
          enabled: false
        },
        yAxis: {
          categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          title: null
        },

        xAxis: heatMapXAxis(),

        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              const ix = point.index + 1
              const xName = this.getPointCategoryName(point, 'x')
              const yName = this.getPointCategoryName(point, 'y')
              const val = point.value
              return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
            }
          }
        },

        colorAxis: {
          min: 0,
          minColor: '#f8f8f8',
          maxColor: '#2fe095'
        },

        legend: {
          enabled: false
        },

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                yAxis: {
                  labels: {
                    formatter: function () {
                      return this.value.charAt(0)
                    }
                  }
                }
              }
            }
          ]
        },

        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>Engagement</h4>',
          pointFormat:
            '<p class="engagement_point"><strong>{point.value} total engagement</strong> at {point.x}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            // width: 50,
            color: '#fafafa'
          }
        },

        series: this.series
      }
    }
  }
}
</script>
