import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import { addSourcesFileURL } from '@src/modules/setting/config/api-utils'
import {
  FILE_BIG,
  NO_FILE_SELECTED,
  UNKNOWN_ERROR,
} from '../../constants/messages'

export const sourcesMixin = {
  computed: {
    ...mapGetters([
      'getTopics',
      'getWorkspaces',
      'getSourcesTopics',
      'getSources',
      'getAddedSources',
    ]),
  },

  created() {},
  mounted() {},

  methods: {
    ...mapActions([
      'fetchOPMLHistory',
      'fetchAddedSources',
      'searchGlobalSources',
      'searchSourcesSuggestionByKeyword',
      'resetSearchSuggestionsByKeyword',
    ]),

    hideSourcesModal() {
      $('#add_source_modal').modal('hide')
      this.$router.push({
        name: 'topics',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    },

    /**
     * Using debounce method so that when the user types for the keyword, we show him the suggestion.
     */
    searchKeywordSuggestion: debounce(function (event) {
      this.resetSearchSuggestionsByKeyword()
      this.searchSourcesSuggestionByKeyword()
    }, 250),
    /**
     * Preview feed method to show the content for the RSS feed.
     * @param url RSS feed URL
     * @param select if the user has selected the option from the RSS links section for add by domains.
     * @param isKeyword is of the type from Keyword.
     */
    // added sources work...
    inputDebounce: debounce(function (query) {
      if (query.length > 3) {
        this.searchGlobalSources(query)
      }
    }, 350),

    // for the tabular form

    getTopicName(topicId) {
      let topicName = ''
      this.getTopics.forEach(function (el) {
        if (el._id === topicId) {
          topicName = el.filters.topics.name
        }
      })
      return topicName
    },
    // file upload work through opml

    uploadSourcesFile(event) {
      console.debug('Method:uploadSourcesFile')
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        files = files[0]
        console.log('OPML source file', files.type)

        if (files.size > 655360) {
          this.alertMessage(FILE_BIG, 'error')
        } else {
          if (
            files.type == 'text/x-opml+xml' ||
            files.type != 'application/xml' ||
            files.type != 'text/xml' ||
            files.type != 'text/x-opml'
          ) {
            this.fileType = 'opml'
          }
          this.sourceFile = files
          this.fileName = files.name
        }
        event.target.value = ''
      } else {
        this.alertMessage(NO_FILE_SELECTED, 'error')
      }
    },
    addOpmlFile(file) {
      console.debug('Method:addSourceFile')

      this.sourceFileLoader = true
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', this.fileType)
      formData.append('workspace_id', this.getWorkspaces.activeWorkspace._id)
      formData.append('custom_topics', this.getSources.topicsSelected)
      this.postRequest(
        addSourcesFileURL,
        formData,
        (response) => {
          if (response.data.status == true) {
            this.alertMessage(response.data.message, 'success')
            this.fetchAddedSources()
            this.sourceFile = null
            this.fileName = null
            this.fileType = null
            $('#add_source_modal').modal('hide')
            this.fetchOPMLHistory()
          } else {
            this.alertMessage(response.data.message, 'error')
          }

          this.sourceFileLoader = false
        },
        (response) => {
          this.sourceFileLoader = false
          console.debug('Exception in addSourceFile', response)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
  },
}
