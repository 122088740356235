const Blog = () => import('../../components/blog/Blog.vue')
const Social = () => import('../../components/social/Social.vue')
export const composerRoutes = {
  composerBlog: {
    path: '/:workspace/composer/blog/:id?/:type?/:typeId?',
    name: 'composerBlog',
    component: Blog,
    meta: {
      title: 'Blog | Composer'
    }
  },
  composerSocial: {
    path: '/:workspace/composer/social/:id?/:type?/:typeId?',
    name: 'composerSocial',
    component: Social,
    meta: {
      title: 'Social | Composer'
    }
  }
}
