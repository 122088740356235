<template>
  <!-- group reporting -->
  <div v-if="is_group" class="card_activity">
    <div class="box_inner d-flex flex-row">
      <div class="profile_picture">
        <div class="picture_block">
          <div class="icon">
            <i class="cs-facebook"></i>
          </div>
          <img
            v-if="post.page_id"
            :src="
              'http://graph.facebook.com/' +
              post.page_id +
              '/picture?type=square'
            "
            alt=""
          />
          <img
            v-else
            src="../../../../../assets/img/profile_default.svg"
            alt=""
          />
        </div>
      </div>
      <div class="description">
        <h2 v-if="post.page_name"
          ><span class="bold">{{ post.page_name }}</span></h2
        >
        <h2 v-else
          ><span class="bold">{{ post.name }}</span></h2
        >
        <p class="time"> {{ post.created_time | publishedDate }}</p>
        <p v-html="limitTextLength(post.message, 120)" v-if="is_report"></p>
        <p v-html="post.message" v-else></p>
      </div>
      <div class="right_block">
        <div class="d-flex flex-row align-items-center">
          <div v-if="post.full_picture">
            <img class="img" width="200px" :src="post.full_picture" alt="" />
          </div>

          <div class="icons">
            <p
              class="d-flex align-items-center"
              v-if="
                (post.post_impressions != 0 && post.total > 0) ||
                post.post_impressions > 0
              "
            >
              <i class="cs-group" v-tooltip="'Post Impressions'"></i>
              <span class="ml-auto">{{
                $filters.numberToCommas(post.post_impressions)
              }}</span>
            </p>

            <p class="d-flex align-items-center">
              <i class="cs-refresh" v-tooltip="'Post Clicks'"></i>
              <span class="ml-auto">{{
                $filters.numberToCommas(post.post_clicks)
              }}</span>
            </p>
            <p class="d-flex align-items-center">
              <i class="cs-heart" v-tooltip="'Post Total Engagement'"></i>
              <span class="ml-auto">{{
                $filters.numberToCommas(post.total_engagement)
              }}</span>
            </p>
            <p class="d-flex align-items-center">
              <i class="cs-comment" v-tooltip="'Post Comments'"></i>
              <span class="ml-auto">{{
                $filters.numberToCommas(post.comments)
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- individual reporting -->
  <div v-else class="d-flex align-items-start post_block">
    <div class="profile_picture d-flex align-items-start">
      <div class="picture_block">
        <img
          v-if="post.page_id"
          :src="
            'http://graph.facebook.com/' + post.page_id + '/picture?type=square'
          "
          alt=""
        />
        <img
          v-else
          src="../../../../../assets/img/profile_default.svg"
          alt=""
        />
      </div>
      <div class="text_block">
        <p class="text">{{ post.name }}</p>
        <p class="sub_text"> {{ post.created_time | publishedDate }}</p>

        <template>
          <p
            v-html="limitTextLength(post.message, 70)"
            class="desc"
            v-if="is_report"
          ></p>
          <p v-html="isHashtag(isLink(post.message))" class="desc" v-else></p>
        </template>
      </div>
    </div>
    <div v-if="post.full_picture">
      <img class="img_block" width="200px" :src="post.full_picture" alt="" />
    </div>
  </div>
</template>
<script>
import analyticsUtilsMixin from '../analyticsUtilsMixin'

export default {
  mixins: [analyticsUtilsMixin],
  props: {
    post: {},
    is_group: {
      default: false
    },
    is_report: {
      default: false
    }
  }
}
</script>
