<template>
  <div>
    <twitter-influencer />
    <article-preview />
    <curated-topic />
    <create-topic />
    <div class="topics-loading-information" v-if="is_topics_loading">
      <beat-loader />
      <p>Loading...</p>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import {
  folderTypes,
  topicCustomizedSidebarTypes,
  topicTypes
} from '../../../store/mutation-types'
import CuratedTopic from '../CuratedTopic'
import CreateTopic from '../CreateTopic'
const ArticlePreview = () => import('../../common/preview/ArticlePreview')
const TwitterInfluencer = () => import('../../common/TwitterInfluencer')
export default {
  components: {
    CuratedTopic,
    CreateTopic,
    ArticlePreview,
    TwitterInfluencer
  },
  data () {
    return {
      is_topics_loading: true
    }
  },
  async created () {
    await this.actionGetTopicsAndFavorites()
    console.log('All done.')
  },
  methods: {
    async actionGetTopicsAndFavorites () {
      await this.actionFetchTopicsAndFavorites()
      this.$store.dispatch('fetchDiscoverCuratedTopicSuggestions')
      this.is_topics_loading = false
      if (
        this.$store.getters.getTopics.length === 0 &&
        this.$store.getters.getMyCuratedTopicsList.length === 0 &&
        this.$store.getters.getFolders.length === 0
      ) {
        this.$router.push({ name: 'discover_topics_follow' })
      } else {
        if (
          this.$route.name === 'discover_topics' ||
          this.$route.name === 'discover_topics_follow'
        ) {
          this.$router.push({ name: 'discover_topics_cover_stories' })
        }
      }
      return true
    },
    async actionFetchTopicsAndFavorites () {
      const res = await this.$store
        .dispatch('fetchTopicsAndFavorites')
        .then((res) => {
          if (res.data.status) {
            console.log(res.data)
            this.$store.commit(folderTypes.SET_FOLDERS_LIST, res.data.favorites)
            this.$store.commit(
              topicTypes.SET_CURATED_TOPICS_LIST,
              res.data.curated
            )
            this.$store.commit(topicTypes.SET_CUSTOM_TOPICS, res.data.custom)
            this.$store.commit(
              topicCustomizedSidebarTypes.SET_CUSTOM_SIDEBAR_OPTIONS,
              res.data.customized_sidebar
            )
            console.log('All data set against the topic.')
          }
          return res
        })
        .catch((err) => {
          console.log(err)
        })
      return res
    }
  },
  watch: {
    async 'getWorkspaces.activeWorkspace.slug' (slug) {
      this.is_topics_loading = true
      const res = await this.actionFetchTopicsAndFavorites()
      this.is_topics_loading = false
      if (
        this.$store.getters.getTopics.length === 0 &&
        this.$store.getters.getMyCuratedTopicsList.length === 0 &&
        this.$store.getters.getFolders.length === 0
      ) {
        this.$router.push({ name: 'discover_topics_follow' })
      } else {
        if (
          this.$route.name === 'discover_topics' ||
          this.$route.name === 'discover_topics_follow' ||
          this.$route.name.includes('discover_topics_')
          // we have added this because such as routes like custom topics, and others will cause an error.
          // If any error occurs, we need to remove this _conditional check.
        ) {
          this.$router.push({ name: 'discover_topics_cover_stories' })
        }
      }
    }
  }
}
</script>
