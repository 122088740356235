<script>
import { mapGetters, mapActions } from 'vuex'
import ModelLayout from '../posting/ModelLayout'
import ModelSidebar from '../posting/ModelSidebar'
import ContentCreationSection from '../posting/blog/ContentCreationSection'
import BlogReviewPublish from '../posting/blog/BlogReviewPublish'
import ImageSection from '../posting/blog/ImageSection'
import BlogDistribute from '../posting/blog/BlogDistribute'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'

export default {
  data () {
    return {}
  },
  components: {
    BlogDistribute,
    ImageSection,
    BlogReviewPublish,
    ContentCreationSection,
    ModelLayout,
    ModelSidebar
  },
  created () {
    this.initializePublishSection('Blog')
  },
  mounted () {
    // body overflow issue for multiple model...
    // $('.modal').on('hidden.bs.modal', function (e) {
    //   if ($('.modal:visible').length) {
    //     $('body').addClass('modal-open')
    //   }
    // })
  },
  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getPublishLoaders',
      'getPublishSelection',
      'getDraftCancelAction'
    ])
  },

  methods: {
    ...mapActions(['setDraftCancelAction']),
    validateProcessBlogPost (draft = false) {
      console.debug('Method:validateProcessBlogPost')
      // if (this.getBlogPostingDetails.title === '') {
      //   this.validations.postTitle = true
      //   return false
      // }
      if (draft) this.processBlogPost(true, true)
      else this.processBlogPost()
    },
    onhide (event) {
      if (
        this.$router.currentRoute.name !== 'calender_plans' &&
        this.$router.currentRoute.name !== 'list_plans' &&
        this.$router.currentRoute.name !== 'feed_view'
      ) {
        if (!this.getPublishSelection.plan_id && !this.getDraftCancelAction) {
          let validForSave = false
          if (
            this.getBlogPostingDetails.title &&
            this.getBlogPostingDetails.title.length > 0
          ) {
            validForSave = true
          } else if (
            this.blogPrimarySecondarySelectionValidation(false) === true
          ) {
            validForSave = true
          }
          // here we also check about editor value
          if (validForSave) {
            event.preventDefault()
            this.setDraftCancelAction(false)
            this.$bvModal.show('socialConfirmationDialog')
            return false
          }
        }
      }
    },
    changeBlogPostingStage (stage) {
      this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, stage)
    }
  }
}
</script>
<template>
  <b-modal
    @hide="onhide"
    id="blog_share_modal"
    modal-class="social_share_modal blog_share_modal top_left_design_box"
    static
    no-enforce-focus
    hide-footer
    hide-header
  >
    <model-layout type="Blog"></model-layout>

    <div class="modal_body" style="min-height: 300px">
      <div
        class="modal_inner_content basic_form blog_steps_common"
        style="overflow: visible"
      >
        <div class="d-flex align-items-stretch">
          <div class="content">
            <div class="steps_arrow_links">
              <ul>
                <li
                  :class="{
                    active: getBlogPostingDetails.stage === 'content',
                    disabled: getPublishLoaders.initializeArticlePostCreation,
                  }"
                  @click.prevent="changeBlogPostingStage('content')"
                  >Content</li
                >
                <li class="arrow_right_gray">
                  <i class="cs-angle-right"></i>
                </li>
                <li
                  :class="{
                    active: getBlogPostingDetails.stage === 'settings',
                    disabled: getPublishLoaders.initializeArticlePostCreation,
                  }"
                  @click.prevent="changeBlogPostingStage('settings')"
                  >Settings</li
                >
                <li class="arrow_right_gray">
                  <i class="cs-angle-right"></i>
                </li>
                <li
                  :class="{
                    active: getBlogPostingDetails.stage === 'distribution',
                    disabled: getPublishLoaders.initializeArticlePostCreation,
                  }"
                  @click.prevent="changeBlogPostingStage('distribution')"
                  >Distribution</li
                >
                <li class="arrow_right_gray">
                  <i class="cs-angle-right"></i>
                </li>
                <li
                  :class="{
                    active: getBlogPostingDetails.stage === 'publish',
                    disabled: getPublishLoaders.initializeArticlePostCreation,
                  }"
                  @click.prevent="changeBlogPostingStage('publish')"
                  >Review & Publish</li
                >
              </ul>
            </div>

            <content-creation-section
              :class="{ hide: getBlogPostingDetails.stage !== 'content' }"
            ></content-creation-section>
            <image-section
              :class="{ hide: getBlogPostingDetails.stage !== 'settings' }"
            ></image-section>
            <blog-distribute
              :class="{ hide: getBlogPostingDetails.stage !== 'distribution' }"
              variations_options="true"
            ></blog-distribute>
            <blog-review-publish
              :class="{ hide: getBlogPostingDetails.stage !== 'publish' }"
              type="discovery"
            ></blog-review-publish>
          </div>

          <model-sidebar
            class="social_post_side_filter_model social_share_model_sidebar"
            type="Blog"
          ></model-sidebar>
        </div>
      </div>
    </div>
  </b-modal>
</template>
