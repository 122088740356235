var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articles_list"},[_c('div',{staticClass:"article_inner"},[(_vm.getTwitterAccounts.items.length === 0)?_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_vm._m(0),_c('p',[_vm._v("To get content from Twitter, you need to connect your Twitter account first.")]),_c('router-link',{staticClass:"mt-3 btn blue_gradient",attrs:{"to":{
            name: 'social',
            hash: '#twitter_tab',
            params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
          }}},[_c('span',{staticClass:"text"},[_vm._v("Connect a Twitter account")])])],1)]):[(_vm.getDiscoveryTwitterLoader)?[_c('beat-loader',{attrs:{"color":'#436aff'}})]:[_c('twitter'),(_vm.getTwitterPaginationLoader && _vm.getTweetsContent.length == 0)?[_vm._m(1)]:_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.onInfiniteTwitter}},[_c('span',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1),_c('span',{staticClass:"not_found_text",attrs:{"slot":"no-more"},slot:"no-more"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',[_vm._v("No more results found for your query.")])])])]),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),(_vm.getTweetsContent.length == 0)?_c('p',[_vm._v("No results found for your query.")]):_c('p',[_vm._v("No more results found for your query.")])])])])])]]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',[_vm._v("No more results found for your query.")])])])
}]

export { render, staticRenderFns }