<template>
  <div class="analytic_component instagram_analytics">
    <template v-if="loader || getAnalyticsServiceStatus">
      <div class="component_inner">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <template v-else>
      <template v-if="metrics.hasOwnProperty('status')">
        <div class="analyze_container">
          <!-- Performance section Summary start here -->
          <div class="section">
            <div class="summary_row white_box">
              <SectionTitles
                :name="'Instagram Performance Summary'"
              ></SectionTitles>
              <div class="d-flex flex-row row_padding">
                <OverviewBox
                  :new-value="metrics.overview.current.total_impressions.value"
                  :old-value="metrics.overview.previous.total_impressions.value"
                  :title="'Impressions'"
                ></OverviewBox>

                <OverviewBox
                  :new-value="metrics.overview.current.total_engagement.value"
                  :old-value="metrics.overview.previous.total_engagement.value"
                  :title="'Engagements'"
                ></OverviewBox>

                <OverviewBox
                  :new-value="metrics.overview.current.profile_views_day.value"
                  :old-value="metrics.overview.previous.profile_views_day.value"
                  :title="'Profile Actions'"
                ></OverviewBox>

                <OverviewBox
                  :new-value="metrics.overview.current.followers_count"
                  :old-value="metrics.overview.previous.followers_count"
                  :title="'Followers'"
                ></OverviewBox>

                <OverviewBox
                  :new-value="metrics.overview.current.total_comments.value"
                  :old-value="metrics.overview.previous.total_comments.value"
                  :title="'Comments'"
                ></OverviewBox>
              </div>
            </div>
          </div>
          <!-- Performance section Summary end here -->

          <!-- Instagram Audience Growth start here -->
          <div class="section audience_growth">
            <div class="d-flex flex-row row_padding">
              <div class="box col-8">
                <div class="summary_row white_box">
                  <SectionTitles
                    :name="'Instagram Audience Growth'"
                  ></SectionTitles>
                  <div class="flex-row">
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <template v-else>
                      <AudienceGrowth
                        :audience_growth="metrics.aggregations.audience_growth"
                      >
                      </AudienceGrowth>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box col-4">
                <div class="summary_row white_box">
                  <div class="flex-row pl-2">
                    <h1>Audience Metrics</h1>
                  </div>

                  <div class="flex-row row_padding">
                    <NetFollowerBox
                      :new-value="metrics.overview.current.followers_count"
                      :old-value="metrics.overview.previous.followers_count"
                      title="Followers"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.followers_count -
                        metrics.overview.previous.followers_count
                      "
                      :title="
                        'Followers ' +
                        (metrics.overview.current.followers_count -
                          metrics.overview.previous.followers_count >=
                        0
                          ? 'Gained'
                          : 'Lost')
                      "
                    ></NetFollowerBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Instagram Audience Growth end here -->

          <!-- Instagram Publishing Behavior start here -->
          <div class="section audience_growth">
            <div class="d-flex flex-row row_padding">
              <div class="box col-8">
                <div class="summary_row white_box">
                  <SectionTitles
                    :name="'Instagram Publishing Behavior'"
                  ></SectionTitles>

                  <div class="flex-row">
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <template v-else>
                      <PublishingBehaviour
                        :publishing_behaviour="
                          metrics.aggregations.publishing_behaviour
                        "
                      >
                      </PublishingBehaviour>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box col-4">
                <div class="summary_row white_box">
                  <div class="flex-row pl-2">
                    <h1>Publishing Behavior by Media Type</h1>
                  </div>

                  <div class="flex-row row_padding">
                    <NetFollowerBox
                      :new-value="metrics.statistics.current.total_posts_count"
                      :title="'Total Published Posts'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="metrics.statistics.current.post_types.video"
                      :title="'Published Videos'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="metrics.statistics.current.post_types.image"
                      :title="'Published Photo'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.statistics.current.post_types.carousel_album
                      "
                      :title="'Published Carousels'"
                    ></NetFollowerBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Instagram Publishing Behavior end here -->

          <!-- Instagram Top Posts & Stories start here -->
          <div class="section account_detail_section">
            <div class="summary_row white_box">
              <div class="d-flex flex-row">
                <div class="left">
                  <SectionTitles
                    :name="'Instagram Top Posts'"
                  ></SectionTitles>
                </div>

                <!--                <div class="right ml-auto">-->
                <!--                  <a href="" class="blue-link">-->
                <!--                    <b>View Post Performance Report &nbsp;<i class="icon-dropdown-cs"></i> </b>-->
                <!--                  </a>-->
                <!--                </div>-->
              </div>
              <template v-if="loader || getAnalyticsServiceStatus">
                <div class="component_inner">
                  <beat-loader :color="'#436aff'"></beat-loader>
                </div>
              </template>
              <div v-else class="d-flex flex-row row_padding">
                <template v-if="metrics.top_posts.length > 0">
                  <template v-for="index in 3">
                    <div
                      v-if="metrics.top_posts[index - 1]"
                      :key="index"
                      class="box"
                    >
                      <div class="box_inner align-items-center">
                        <div class="flex-row d-flex">
                          <div class="left">
                            <div class="user_name d-flex flex-row">
                              <div class="left">
                                <img
                                  v-if="
                                    metrics.top_posts[index - 1][
                                      'profile_picture_url'
                                    ]
                                  "
                                  :src="
                                    metrics.top_posts[index - 1][
                                      'profile_picture_url'
                                    ]
                                  "
                                />
                                <img
                                  v-else
                                  src="../../../../assets/img/profile_default.svg"
                                />
                              </div>
                              <div class="right right-text">
                                <h3 class="name">{{
                                  metrics.top_posts[index - 1].name
                                }}</h3>
                                <p class="date"
                                  >@{{ metrics.top_posts[index - 1].username }}
                                  •
                                  {{
                                    formatDate(
                                      metrics.top_posts[index - 1][
                                        'post_created_at'
                                      ],
                                    )
                                  }}</p
                                >
                              </div>
                            </div>
                          </div>

                          <div class="right ml-auto">
                            <a
                              v-tooltip.bottom-center="{
                                content: 'View on Instagram',
                                trigger: 'hover',
                              }"
                              :href="metrics.top_posts[index - 1]['permalink']"
                              target="_blank"
                              class="far fa-share action_icon"
                            ></a>
                          </div>
                        </div>

                        <div class="flex-row d-flex">
                          <p style="height: 22px">{{
                            limitText(
                              metrics.top_posts[index - 1]['caption'],
                              130,
                            )
                          }}</p>
                        </div>

                        <div class="flex-row d-flex">
                          <div
                            class="cover_image"
                            :style="{
                              background:
                                'url(' +
                                metrics.top_posts[index - 1]['media_url'] +
                                ')',
                            }"
                          >
                          </div>
                        </div>

                        <div class="flex-row">
                          <template
                            v-for="(metric, value) in top_posts_metrics"
                          >
                            <div class="net_follower_box">
                              <div
                                class="top_post_info d-flex align-items-center"
                              >
                                <div class="left">
                                  <div class="text">
                                    <h4 class="top_text">{{ value }}</h4>
                                  </div>
                                </div>

                                <div class="right ml-auto">
                                  <h2>{{
                                    metrics.top_posts[index - 1][metric]
                                  }}</h2>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <NoPosts></NoPosts>
                </template>
              </div>
            </div>
          </div>
          <!-- Instagram Top Posts & Stories end here -->
          <!-- Instagram Active Users start here -->
          <div class="section">
            <div class="summary_row white_box">
              <SectionTitles :name="'Instagram Active Users'"></SectionTitles>
              <template v-if="loader || getAnalyticsServiceStatus">
                <div class="component_inner">
                  <beat-loader :color="'#436aff'"></beat-loader>
                </div>
              </template>
              <div v-else class="d-flex flex-row row_padding">
                <div class="box">
                  <div class="box_inner d-flex align-items-center">
                    <div class="text w-100">
                      <h4 class="top_text pl-3">Active Users by Hours </h4>
                      <ActiveUsersByHours
                        :active_users="getActiveUsers"
                      ></ActiveUsersByHours>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="box_inner d-flex align-items-center">
                    <div class="text w-100">
                      <h4 class="top_text pl-3">Active Users by Days </h4>
                      <ActiveUsersByDay
                        :active_users_days="getActiveUsersDays"
                      ></ActiveUsersByDay>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Instagram Active Users end here -->
          <!-- Instagram Outbound Hashtag Performance start here -->
          <div class="section">
            <div class="summary_row white_box">
              <SectionTitles
                :name="'Instagram Outbound Hashtag Performance'"
              ></SectionTitles>
              <div class="d-flex flex-row row_padding">
                <div class="box">
                  <div class="box_inner align-items-center">
                    <div class="text flex-row">
                      <h4 class="top_text">Most Used Hashtags </h4>
                    </div>
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <div v-else class="text flex-row">
                      <!-- most use hashtags bar  start here -->
                      <template
                        v-if="metrics.top_hashtags.hashtags.length <= 0"
                      >
                        <p> You don't have any hashtags in this time period </p>
                      </template>
                      <div v-else class="line_bars">
                        <template
                          v-for="(value, hashtag, index) in metrics.top_hashtags
                            .hashtags"
                          v-if="index < 10"
                        >
                          <div class="bar_item">
                            <label :for="hashtag"
                              >#{{ hashtag }}
                              <span>{{ value }}</span>
                            </label>
                            <div class="item_inner">
                              <div
                                class="limit_add"
                                :style="
                                  'width: ' +
                                  (value / metrics.top_hashtags.top_value) *
                                    100 +
                                  '%;'
                                "
                              >
                              </div>
                              <div class="limit_exceed"></div>
                            </div>
                          </div>
                        </template>
                      </div>

                      <!-- most use hashtags bar  start here -->
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="box_inner align-items-center">
                    <div class="text flex-row">
                      <h4 class="top_text"
                        >Top Hashtags by Lifetime Engagements
                      </h4>
                    </div>
                    <div class="text flex-row">
                      <!-- most use hashtags bar  start here -->

                      <div class="line_bars">
                        <template v-if="loader || getAnalyticsServiceStatus">
                          <div class="component_inner">
                            <beat-loader :color="'#436aff'"></beat-loader>
                          </div>
                        </template>
                        <template
                          v-else-if="
                            metrics.top_engagement_hashtags.hashtags.length <= 0
                          "
                        >
                          <p class="text">
                            You do not have any hashtags in this time period.
                          </p>
                        </template>
                        <template
                          v-for="(value, hashtag, index) in metrics
                            .top_engagement_hashtags.hashtags"
                          v-if="index < 10"
                        >
                          <div class="bar_item">
                            <label :for="hashtag"
                              >#{{ hashtag }}
                              <span>{{ value }}</span>
                            </label>
                            <div class="item_inner">
                              <div
                                class="limit_add"
                                :style="
                                  'width: ' +
                                  (value /
                                    metrics.top_engagement_hashtags.top_value) *
                                    100 +
                                  '%;'
                                "
                              >
                              </div>
                              <div class="limit_exceed"></div>
                            </div>
                          </div>
                        </template>
                      </div>

                      <!-- most use hashtags bar  start here -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Instagram Outbound Hashtag Performance end here -->

          <!-- Instagram Impressions start here -->

          <div class="section audience_growth">
            <div class="d-flex flex-row row_padding">
              <div class="box col-8">
                <div class="summary_row white_box">
                  <SectionTitles
                    :name="'Instagram Impressions'"
                  ></SectionTitles>
                  <div class="flex-row">
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <template v-else>
                      <Impressions
                        :impressions="metrics.aggregations.impressions"
                      >
                      </Impressions>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box col-4">
                <div class="summary_row white_box">
                  <div class="flex-row pl-2">
                    <h1>Impressions Metrics</h1>
                  </div>
                  <template v-if="loader || getAnalyticsServiceStatus">
                    <div class="component_inner">
                      <beat-loader :color="'#436aff'"></beat-loader>
                    </div>
                  </template>
                  <div v-else class="flex-row row_padding">
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.total_impressions.value
                      "
                      :old-value="
                        metrics.overview.previous.total_impressions.value
                      "
                      :title="'Impressions'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.avg_total_impressions.value
                      "
                      :title="'Average Impressions Per Post'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="metrics.overview.current.reach.value"
                      :old-value="metrics.overview.previous.reach.value"
                      :title="'Reach'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="metrics.overview.current.saved.value"
                      :old-value="metrics.overview.previous.saved.value"
                      :title="'Saved'"
                    ></NetFollowerBox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Instagram Impressions end here -->
          <!-- Instagram Engagement start here -->

          <div class="section audience_growth">
            <div class="d-flex flex-row row_padding">
              <div class="box col-8">
                <div class="summary_row white_box">
                  <SectionTitles
                    :name="'Instagram Engagement'"
                  ></SectionTitles>

                  <div class="flex-row">
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <template v-else>
                      <EngagementRate
                        :engagements="metrics.aggregations.engagements"
                      >
                      </EngagementRate>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box col-4">
                <div class="summary_row white_box">
                  <div class="flex-row pl-2">
                    <h1>Engagement Metrics</h1>
                  </div>

                  <div class="flex-row row_padding">
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.total_engagement.value
                      "
                      :old-value="
                        metrics.overview.previous.total_engagement.value
                      "
                      :title="'Engagements'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.avg_total_engagement.value
                      "
                      :title="'Average Engagement Per Post'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="metrics.overview.current.total_comments.value"
                      :old-value="
                        metrics.overview.previous.total_comments.value
                      "
                      :title="'Comments'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.overview.current.total_reactions.value
                      "
                      :old-value="
                        metrics.overview.previous.total_reactions.value
                      "
                      :title="'Reactions'"
                    ></NetFollowerBox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Instagram Engagement end here -->
          <!-- Instagram Stories Performance start here -->

          <div class="section audience_growth">
            <div class="d-flex flex-row row_padding">
              <div class="box col-8">
                <div class="summary_row white_box">
                  <SectionTitles
                    :name="'Instagram Stories Performance'"
                  ></SectionTitles>
                  <div class="flex-row">
                    <template v-if="loader || getAnalyticsServiceStatus">
                      <div class="component_inner">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </div>
                    </template>
                    <template v-else>
                      <StoriesPublished
                        :published_stories="
                          metrics.aggregations.stories_performance
                            .stories_performance
                        "
                      >
                      </StoriesPublished>
                      <div class="pt-4 pb-2">
                        <h1>Stories Performance</h1>
                        <StoriesPerformance
                          :stories_performance="
                            metrics.aggregations.stories_performance
                              .stories_performance
                          "
                        >
                        </StoriesPerformance>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="box col-4">
                <div class="summary_row white_box">
                  <div class="flex-row pl-2">
                    <h1>Stories Metrics</h1>
                  </div>

                  <div class="flex-row row_padding">
                    <NetFollowerBox
                      :new-value="metrics.statistics.current.total_stories"
                      :title="'Total Published Stories'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance.story_reply
                          .value
                      "
                      :title="'Story Replies'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance.story_taps_back
                          .value
                      "
                      :title="'Story Tap Backs'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance
                          .story_taps_forward.value
                      "
                      :title="'Story Taps Forward'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance.story_exits
                          .value
                      "
                      :title="'Story Exits'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance.story_reach
                          .value
                      "
                      :title="'Story Reach'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance
                          .story_impressions.value
                      "
                      :title="'Story Impressions'"
                    ></NetFollowerBox>
                    <NetFollowerBox
                      :new-value="
                        metrics.aggregations.stories_performance
                          .avg_story_impressions.value
                      "
                      :title="'Average Story Impressions'"
                    ></NetFollowerBox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Instagram Stories Performance end here -->
        </div>
      </template>
      <template v-else>
        <div class="analyze_container">
          <div class="section no-post-found">
            <p>No Posts for the Page.</p>
            <img
              src="../../../../assets/img/analytics/no_post.svg"
              width="300"
              height="300"
            />
          </div>
        </div>
      </template>
    </template>
    <!--container end here -->
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import PublishedPostsWithEngagement from '../common/charts/PublishedPostsWithEngagement'
import { dataValues } from '../common/helper'
import analyticsUtilsMixin from '../common/analyticsUtilsMixin'
import ContentDistributionDaily from '../common/charts/ContentDistributionDaily'
import ReactionChart from '../common/charts/ReactionChart'
import PostTypeRate from './statistics/PostTypeRate'
import Impressions from './charts/Impressions'
import EngagementRate from './charts/EngagementRate'
import TopPosts from './statistics/TopPosts'
import AudienceGrowth from './charts/AudienceGrowth'
import PublishingBehaviour from './charts/PublishingBehavior'
import InstagramHeader from './InstagramHeader'
import ActiveUsersByDay from './charts/ActiveUsersByDay'
import ActiveUsersByHours from './charts/ActiveUsersByHours'
import StoriesPerformance from './charts/StoriesPerformance'
import StoriesPublished from './charts/StoriesPublished'
import NetFollowerBox from './statistics/NetFollowerBox'
import OverviewBox from './statistics/OverviewBox'
import SectionTitles from './common/SectionTitles'
import NoPosts from './common/NoPosts'

export default {
  name: 'InstagramOverview',
  components: {
    NoPosts,
    DateRangePicker,
    PublishedPostsWithEngagement,
    Impressions,
    EngagementRate,
    ActiveUsersByDay,
    ActiveUsersByHours,
    TopPosts,
    PostTypeRate,
    ContentDistributionDaily,
    AudienceGrowth,
    PublishingBehaviour,
    StoriesPerformance,
    StoriesPublished,
    ReactionChart,
    InstagramHeader,
    NetFollowerBox,
    OverviewBox,
    SectionTitles
  },
  mixins: [analyticsUtilsMixin],
  props: { data: null },
  computed: {
    ...mapGetters([
      'getInstagramAccounts',
      'getAnalyticsServiceStatus',
      'getInstagramIds'
    ]),
    getMaxDate () {
      return moment.utc().format('YYYY-MM-DD')
    },
    getActiveUsers () {
      return this.metrics.aggregations.active_users_hours
        ? this.metrics.aggregations.active_users_hours
        : []
    },
    getActiveUsersDays () {
      return this.metrics.aggregations.active_users_days
        ? this.metrics.aggregations.active_users_days
        : []
    }
  },
  data () {
    return dataValues().instagram
  },
  async mounted () {
    console.log('I AM PARAMS')
    console.log(this.data)
    // check if the route contains account id
    // this.selected_account = this.$route.params.accountId
    if (this.data != null) {
      this.initialize()
    }
  },
  methods: {
    ...mapActions(['getAnalyticsService']),
    initialize () {
      this.metrics = {}
      this.data.hash_count = 0
      this.selected_account = this.data.selected_account
      this.previous_start_date = this.data.previous_start_date
      this.previous_end_date = this.data.previous_end_date
      this.getAnalyticsForInstagram({
        workspace_id: this.getActiveWorkspace._id,
        timezone: this.getActiveWorkspace.timezone,
        date: `${this.data.start_date} - ${this.data.end_date}`,
        accounts: this.selected_account,
        // accounts: ["36971002400"],
        previous_date: `${this.previous_start_date} - ${this.previous_end_date}`
      })
    },
    formatDate (date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).format('llll')
    },
    hashtagCount () {
      this.data.hash_count += 1
      return this.data.hash_count < 11
    },
    async getAnalyticsForInstagram (payload = {}) {
      this.loader = true
      payload.type = 'instagram'
      const resp = await this.getAnalyticsService(payload)
      this.metrics = Object.assign(this.metrics, resp)
      console.log(this.metrics)
      this.loader = false
    },
    limitText (value, limit) {
      if (value && value.length > limit) {
        // console.log(value.substring(0, 15)
        return value.substring(0, limit) + '...'
      }
      return value
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.data.hash_count = 0
        this.initialize()
      }
    }
  }
}
</script>
