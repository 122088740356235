<template>
  <div class="component_inner">
    <!--v-if="!hasInfluencersPurchased"-->
    <div
      v-if="!hasInfluencersPurchased"
      class="top_filter_section bottom_border"
    >
      <div class="filter_section_inner width_900">
        <h3 class="filter_section_heading"
          >Find the best
          <span v-if="checkInfluencerType === 'twitter'">Twitter</span>
          <span v-else-if="checkInfluencerType === 'instagram'">Instagram</span>
          <span v-else-if="checkInfluencerType === 'youtube'">YouTube</span>
          influencers for your business.</h3
        >

        <div
          class="search_input search_input_discovery with_icon_right d-flex align-items-center"
        >
          <div
            class="dropdown option_dropdown mr-2 default_style_dropdown influencer-search-dropdown"
          >
            <div
              class="dropdown_header d-flex align-items-center transparent-border"
              data-toggle="dropdown"
              data-cy="influencers_accounts"
            >
              <span
                v-if="checkInfluencerType === 'twitter'"
                class="space_nowrap text d-flex align-items-center font-0-87rem"
              >
                <i class="icon-twitter_icon tw mr-2"></i> Twitter
              </span>

              <span
                v-if="checkInfluencerType === 'instagram'"
                class="space_nowrap text d-flex align-items-center font-0-87rem"
              >
                <i class="icon-instagram_icon ins mr-2"></i> Instagram
              </span>

              <!--              <span-->
              <!--                class="space_nowrap text d-flex align-items-center font-0-87rem"-->
              <!--                v-if="checkInfluencerType === 'youtube'"-->
              <!--              >-->
              <!--                <i class="icon-Youtube_icon youtube mr-2"></i> YouTube-->
              <!--              </span>-->

              <span class="ml-auto">
                <i class="dropdown_arrow icon_last icon-dropdown-cs"></i>
              </span>
            </div>

            <div
              class="dropdown-menu dropdown-menu-left"
              style="min-width: 140px"
            >
              <ul class="inner">
                <li
                  class="list_item_li"
                  data-cy="influencers_account_selection"
                  :class="{
                    disable_click:
                      getInfluencerLoaders.filter_count ||
                      loaders.search ||
                      checkInfluencerType === 'twitter',
                    active: checkInfluencerType === 'twitter',
                  }"
                  @click.prevent="changeInfluencerType('twitter')"
                >
                  <i class="icon-twitter_icon tw"></i>
                  <span>Twitter</span>
                </li>
                <li
                  class="list_item_li"
                  data-cy="influencers_account_selection"
                  :class="{
                    disable_click:
                      getInfluencerLoaders.filter_count ||
                      loaders.search ||
                      checkInfluencerType === 'instagram',
                    active: checkInfluencerType === 'instagram',
                  }"
                  @click.prevent="changeInfluencerType('instagram')"
                >
                  <i class="fab fa-instagram ins"></i>
                  <span>Instagram</span>
                </li>
                <!--                <li-->
                <!--                  class="list_item_li"-->
                <!--                  :class="{-->
                <!--                    disable_click:-->
                <!--                      getInfluencerLoaders.filter_count ||-->
                <!--                      loaders.search ||-->
                <!--                      checkInfluencerType === 'youtube',-->
                <!--                    active: checkInfluencerType === 'youtube',-->
                <!--                  }"-->
                <!--                  @click.prevent="changeInfluencerType('youtube')"-->
                <!--                >-->
                <!--                  <i class="icon-Youtube_icon youtube"></i>-->
                <!--                  <span>YouTube</span>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>

          <div class="search_inner">
            <!--@input="changeSearchKeyDown($event)"-->
            <input
              id
              v-model="searchKeyword"
              class="discoverySearchInput"
              style="padding-top: 12px; padding-bottom: 12px"
              type="text"
              placeholder=" Search by #hashtag, @username or @mention to narrow your search further."
              data-cy="discovery_search_bar"
              @focus="searchInfluencersHistory"
              @keyup.38="influencerInputSelectItemFromList('up')"
              @keyup.40="influencerInputSelectItemFromList('down')"
              @keyup.enter="searchInfluencersByKeyword"
            />

            <button
              class="search_btn input_icon search_icon gradient_color"
              data-cy="discovery_search_button"
              @click.prevent="searchInfluencersByKeyword"
              ><i class="icon-Search" data-cy="influencers_search_icon"></i>
            </button>

            <!--<search-suggestions></search-suggestions>-->
            <!--<influencers-search-suggestions></influencers-search-suggestions>-->
            <transition name="slide-bottom">
              <InfluencerSearchHistory
                v-if="
                  showSearchHistory &&
                  (!getInfluencerSearchKeyword ||
                    (getInfluencerSearchKeyword &&
                      getInfluencerSearchKeyword.trim().length === 0))
                "
                :keywords="searchHistoryKeywords"
                :search_key_input="active_key_type"
              ></InfluencerSearchHistory>
            </transition>
          </div>
          <template
            v-if="
              checkInfluencerType === 'twitter' && influencers.data.length > 0
            "
          >
            <InfluencerExport></InfluencerExport>
          </template>

          <clip-loader
            v-if="getInfluencerLoaders.filter_count || loaders.search"
            class="spinner ml-3"
            :color="'#9da6ac'"
            :size="'16px'"
          ></clip-loader>
        </div>
      </div>
    </div>

    <div class="ds_inner_pages">
      <div class="discovery_influencer_component">
        <div class="component_inner">
          <div
            v-if="hasInfluencersPurchased"
            class="update-feature-plan-outter"
          >
            <UpgradeLifetimeAddons></UpgradeLifetimeAddons>
          </div>
          <template v-else>
            <TwitterInfluencer></TwitterInfluencer>
            <InfluencerFolder></InfluencerFolder>
            <InfluencerRetweet></InfluencerRetweet>
            <InfluencerFollow></InfluencerFollow>
            <VideoPreview></VideoPreview>

            <!--<influencer-detail  v-if="showPreview" :preview="preview" :top_mentioned="top_mentioned" :tweets="tweets"-->
            <!--:shared_articles="shared_articles" :posts="posts"></influencer-detail>-->
            <InfluencerDetail v-if="showPreview"></InfluencerDetail>
            <ReTweet></ReTweet>
            <ArticlePreview></ArticlePreview>

            <template v-if="checkInfluencerType === 'twitter'">
              <InfluencerFilterSidebar
                :toggle="toggle"
                :influencer-filters="influencerFilters"
              ></InfluencerFilterSidebar>
            </template>
            <template v-else-if="checkInfluencerType === 'instagram'">
              <InfluencerFilterInstagramSidebar
                :toggle="toggle"
                :influencer-filters="instagramInfluencerFilters"
              ></InfluencerFilterInstagramSidebar>
            </template>
            <template v-else>
              <InfluencerFilterYoutubeSidebar
                :toggle="toggle"
                :influencer-filters="youtubeInfluencerFilters"
              ></InfluencerFilterYoutubeSidebar>
            </template>

            <div class="ds_article_filter pl-0 pr-0">
              <div class="d-flex align-items-center">
                <div v-if="!loaders.search" class="left_filter">
                  <h4 v-if="getInfluencerSearchKeyword === ''" class="results"
                    >Discover Top Influencers</h4
                  >
                  <h4 v-else-if="influencers.total_results > 0" class="results">
                    {{ $filters.numberToCommas(influencers.total_results) }}
                    <template v-if="influencers.total_results === 1">
                      Result
                    </template>
                    <template v-else> Results </template>
                    <template v-if="$route.query && $route.query.list">
                      in List
                      <b
                        >"{{ getInfluencerListName($route.query.list) }}"</b
                      ></template
                    >
                  </h4>
                </div>

                <div class="right_filter">
                  <div
                    class="dropdown article_post_dropdown option_dropdown default_style_dropdown"
                    data-cy="twitter_influencer_list_filter_dropdown"
                  >
                    <InfluencerListFilter></InfluencerListFilter>
                  </div>

                  <div
                    class="dropdown article_post_dropdown option_dropdown default_style_dropdown"
                    data-cy="twitter_influencer_sort_dropdown"
                  >
                    <InfluencerSortFilter
                      :type="checkInfluencerType"
                    ></InfluencerSortFilter>
                  </div>

                  <div
                    class="dropdown option_dropdown default_style_dropdown planner-filter-dropdown"
                    data-cy="twitter_influencer_sidebar_filter_dropdown"
                    @click="toggle = true"
                  >
                    <div class="dropdown_header d-flex align-items-center">
                      <i class="trending_icon icon_first far fa-filter"></i>
                      <span class="text">
                        <span
                          v-if="getFilterText() > 0"
                          class="checkbox_counter"
                          data-cy="twitter_influencer_sidebar_filter_count"
                          >{{ getFilterText() }}</span
                        >Filter
                      </span>
                    </div>
                  </div>

                  <div class="view_change_btn p-0 d-inline-block ml-2">
                    <div class="view_change_btn_inner border30">
                      <p
                        v-tooltip.top-center="'Grid View'"
                        class="grid_icon"
                        data-cy="influencers_grid_view"
                        :class="{ active: toggleViewStatus === 'grid' }"
                        @click.prevent="toggleView('grid')"
                      >
                        <i class="icon-groups-cs"></i>
                      </p>
                      <p
                        v-tooltip.top-center="'List View'"
                        class="list_icon"
                        data-cy="influencers_list_view"
                        :class="{ active: toggleViewStatus === 'list' }"
                        @click.prevent="toggleView('list')"
                      >
                        <i class="icon-Listview_icon"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <router-view
              :show-add-influencer-btn="showAddInfluencerBtn"
              :show-add-influencer-message="showAddInfluencerMessage"
              :add-influencer-loader="addInfluencerLoader"
              @add-new-influencer="addNewInfluencer"
            ></router-view>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  articlesTypes,
  influencerTypes,
} from '@src/modules/discovery/store/mutation-types'
import InfluencerFolder from '@src/modules/discovery/components/influencers/dialogs/InfluencerFolder'
import InfluencerRetweet from '@src/modules/discovery/components/influencers/InfluencerRetweet'
import InfluencerFollow from '@src/modules/discovery/components/influencers/InfluencerFollow'
import InfluencerDetail from '@src/modules/discovery/components/influencers/dialogs/InfluencerDetail'
import InfluencerSortFilter from '@src/modules/discovery/components/influencers/filters/InfluencerSortFilter'
import InfluencerListFilter from '@src/modules/discovery/components/influencers/filters/InfluencerListFilter'
import InfluencerFilterSidebar from '@src/modules/discovery/components/influencers/InfluencerFilterSidebar'
import InfluencerFilterInstagramSidebar from '@src/modules/discovery/components/influencers/instagram/InfluencerFilterInstagramSidebar'
import InfluencerFilterYoutubeSidebar from '@src/modules/discovery/components/influencers/youtube/InfluencerFilterYoutubeSidebar'
import UpgradeLifetimeAddons from '@common/components/dialogs/UpgradeLifetimeAddons'
import ReTweet from '@src/modules/discovery/components/common/ReTweet'
import InfluencerExport from '@src/modules/discovery/components/influencers/InfluencerExport'
import orderBy from 'lodash.orderby'
import VideoPreview from '@src/modules/discovery/components/common/preview/VideoPreview'

import ArticlePreview from '@src/modules/discovery/components/common/preview/ArticlePreview'

import InfluencerSearchHistory from '@src/modules/discovery/components/influencers/InfluencerSearchHistory'

import { EventBus } from '@common/lib/event-bus'

import TwitterInfluencer from '@src/modules/discovery/components/common/TwitterInfluencer'

const getDefaultKeyActive = () => {
  return {
    key: '',
    items: [],
    index_of_key: 0,
  }
}

export default {
  components: {
    InfluencerFolder,
    InfluencerRetweet,
    InfluencerFollow,
    InfluencerFilterSidebar,
    InfluencerSortFilter,
    InfluencerDetail,
    InfluencerListFilter,
    UpgradeLifetimeAddons,
    InfluencerFilterInstagramSidebar,
    ReTweet,
    InfluencerFilterYoutubeSidebar,
    VideoPreview,
    ArticlePreview,
    TwitterInfluencer,
    InfluencerSearchHistory,
    InfluencerExport,
  },
  data() {
    return {
      textLength: null,
      toggle: false,
      toggleViewStatus: 'grid',
      influencers: {
        data: [],
        total_pages: 0,
        total_results: 0,

        current_page: 1,
        next_page: 2,
        prev_page: null,
      },
      preview: {},
      top_mentioned: {},
      tweets: {},
      shared_articles: {},
      posts: {},
      loaders: {
        search: false,
        preview: false,
      },
      influencerFilters: {},
      instagramInfluencerFilters: {},
      youtubeInfluencerFilters: {},

      searchKeyword: '',
      showSearchHistory: false,
      searchHistoryKeywords: [],
      active_key_type: getDefaultKeyActive(),
      showPreview: false,
      showAddInfluencerBtn: true,
      showAddInfluencerMessage: false,
      addInfluencerLoader: false,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getInfluencerFilters',
      'getActiveWorkspaceAddonsLifetime',
      'getInstagramInfluencerFilters',
      'getInfluencerLoaders',
      'getWorkspaces',
      'getYoutubeInfluencerFilters',
      'getInfluencerFolders',
    ]),
    hasInfluencersPurchased() {
      return (
        this.isOnLifetimePlan() &&
        !this.getActiveWorkspaceAddonsLifetime.influencers
      )
    },
    checkInfluencerType() {
      if (
        this.$route.name === 'twitter_influencers' ||
        this.$route.name === 'twitter_influencer_preview'
      ) {
        return 'twitter'
      }
      if (
        this.$route.name === 'instagram_influencers' ||
        this.$route.name === 'instagram_influencer_preview'
      ) {
        return 'instagram'
      }
      if (
        this.$route.name === 'youtube_influencers' ||
        this.$route.name === 'youtube_influencer_preview'
      ) {
        return 'youtube'
      }
      return 'twitter'
    },
    getInfluencerSearchKeyword() {
      return this.$route.query.q
    },
  },

  watch: {
    'getActiveWorkspace._id'(id) {
      if (id) {
        console.log('InfluencersMain.vue.vue -> workspace id -> ', id)
        this.fetchInfluencerFolder()
        this.getInfluencers(this.checkInfluencerType)
        this.getInfluencersFilterStats(this.checkInfluencerType)
      } else {
        console.log('InfluencersMain.vue.vue -> without workspace id -> ', id)
      }
    },
    '$route.query.q'() {
      this.searchKeyword = this.$route.query.q
      this.getInfluencers(this.checkInfluencerType)
      this.getInfluencersFilterStats(this.checkInfluencerType)
    },
    '$route.query.sort'() {
      this.getInfluencers(this.checkInfluencerType)
    },
    '$route.query.type'() {
      this.getInfluencers(this.checkInfluencerType)
    },
    '$route.query.page'(value) {
      let page = value ? Number.parseInt(value) : 1
      if (Number.isNaN(page)) page = 1
      this.getInfluencerData(this.checkInfluencerType, page)
      // this.getInfluencers(this.checkInfluencerType, page)
    },
  },
  created() {
    this.searchKeyword = this.$route.query.q
    this.$store.commit(influencerTypes.RESET_INFLUENCERS_FILTER)
    this.fetchInfluencerFolder()
    if (
      this.$route.name === 'twitter_influencer_preview' ||
      this.$route.name === 'instagram_influencer_preview' ||
      this.$route.name === 'youtube_influencer_preview'
    ) {
      this.showPreview = true
    }
  },

  async mounted() {
    const self = this
    // eslint-disable-next-line no-undef
    $('.app_container ').mouseup((e) => {
      console.debug('test')
      // eslint-disable-next-line no-undef
      var container = $('.prevent_click')

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        self.toggle = false
      }
    })
    EventBus.$on('show-influencers-search-history', () => {
      this.showSearchHistory = false
    })
    EventBus.$on('clear-influencer-search-history', () => {
      this.searchHistoryKeywords = []
    })
    EventBus.$on('clear-influencer-individual-search-history', (res) => {
      if (res.query) {
        const keywordsList = this.searchHistoryKeywords
        keywordsList.splice(keywordsList.indexOf(res.query), 1)
        this.searchHistoryKeywords = keywordsList
      }
    })
  },
  beforeDestroy() {
    this.resetDiscoveryStates()
    this.SET_TOPIC_MODULE('content')
    console.log('before destroy influencers > ')
    // eslint-disable-next-line no-undef
    $('.app_container ').unbind('mouseup')
    EventBus.$off('show-influencers-search-history')
    EventBus.$off('clear-influencer-search-history')
    EventBus.$off('clear-influencer-individual-search-history')
  },
  methods: {
    ...mapActions([
      'fetchInfluencers',
      'fetchInfluencerPreview',
      'fetchInfluencerFolder',
      'saveShortlistInfluencer',
      'setAccountSelection',
      'fetchInfluencerFollowers',
      'fetchInfluencerTopMentioned',
      'fetchInfluencerPosts',
      'fetchInfluencerSharedArticles',
      'setSocialSharingTagsSuggestionsItems',
      'fetchInfluencersStats',
      'setSharingTagsReplaceStatus',
      'setSearch',
      'fetchInfluencerSearchHistory',
      'addInfluencer',
    ]),

    ...mapMutations([
      articlesTypes.SET_SEARCH_DISPLAY_TOPICS,
      articlesTypes.SET_SEARCH_ARTICLES_TOPIC,
      articlesTypes.SET_SEARCH_CONTENT_TYPE,
      articlesTypes.SET_SEARCH_KEYWORD,
      articlesTypes.SET_SEARCH_TEXT,
      articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS,
    ]),

    async addNewInfluencer(platform = 'twitter') {
      console.debug('addNewInfluencer', platform)
      this.addInfluencerLoader = true
      const payload = {
        platform,
        handle: this.searchKeyword,
        add_to_list: true,
      }
      const result = await this.addInfluencer(payload)
      this.showAddInfluencerMessage = !!result
      this.showAddInfluencerBtn = false
      this.addInfluencerLoader = false
    },

    sortInterest(interests) {
      console.debug('sortInterest', interests)
      if (interests) return orderBy(interests, ['count'], ['desc'])
      return interests
    },

    searchInfluencersByKeyword() {
      this.showAddInfluencerBtn = true
      if (this.searchKeyword && this.searchKeyword.trim().length > 0) {
        this.$router.push({
          query: { ...this.$route.query, q: this.searchKeyword },
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.q
        this.$router.push({ query })
      }
    },

    resetDiscoveryStates() {
      this.SET_SEARCH_DISPLAY_TOPICS(true)
      this.SET_SEARCH_ARTICLES_TOPIC(null)
      this.SET_SEARCH_CONTENT_TYPE('article')
      this.SET_SEARCH_KEYWORD('')
      this.SET_POSTS([])
    },

    toggleView(type) {
      if (type === this.toggleViewStatus) return false
      this.loaders.search = true
      // used setTimeout because loader is not working
      // later will find solution

      const page =
        this.$route.query && this.$route.query.page ? this.$route.query.page : 1
      setTimeout(
        function () {
          this.toggleViewStatus = type
          this.getInfluencers(this.checkInfluencerType, page)
          this.getInfluencersFilterStats(this.checkInfluencerType)
        }.bind(this),
        10
      )
    },

    changeInfluencerTopic(interest, type = 'twitter') {
      let label = ''
      if (type === 'instagram' || type === 'youtube') label = interest.label
      else if (type === 'twitter') label = interest.topic
      this.searchKeyword = label
      this.$router.push({
        query: { ...this.$route.query, q: this.searchKeyword },
      })
    },

    changeInfluencerSortOrder(sortField, type) {
      console.debug('Method:changeInfluencerSortOrder', sortField)
      if (sortField) {
        this.$router.push({ query: { ...this.$route.query, sort: sortField } })
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.sort
        this.$router.push({ query })
      }
    },

    influencerBackgroundImageURL(url) {
      return `background:url('${url}')`
    },

    tweetInfluencer(influencer) {
      this.setAccountSelection(null)
      this.$store.commit(
        influencerTypes.SET_INFLUENCER_TWEET_TEXT,
        '@' + influencer.screen_name + ' '
      )
      this.$bvModal.show('retweet_influencer_modal')
    },

    openFollowModal(influencer) {
      this.$store.commit(
        influencerTypes.SET_INFLUENCER_FOLLOW_DATA,
        influencer.screen_name
      )
      this.$store.dispatch('fetchInfluencerFollowers', {
        influencer: influencer.screen_name,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      })
      // eslint-disable-next-line no-undef
      $('#influencer_follow_modal').modal('show')
    },

    isShortlisted(folders, folderId) {
      return folders.indexOf(folderId) >= 0
    },

    createInfluencerList(influencer) {
      EventBus.$emit('influencer-folder-reset')
      this.$store.commit(influencerTypes.SET_CREATE_LIST_INFLUENCER, influencer)
      // eslint-disable-next-line no-undef
      $('#createTwitterList').modal('show')
    },

    getFilterText() {
      let filter = this.getInfluencerFilters
      if (this.checkInfluencerType === 'instagram') {
        filter = this.getInstagramInfluencerFilters
      }
      if (this.checkInfluencerType === 'youtube') {
        filter = this.getYoutubeInfluencerFilters
      }
      let filterCount = filter.location.length + filter.languages.length
      if (filter.influencer_category && filter.influencer_category.length > 0) {
        filterCount = filterCount + filter.influencer_category.length
      }
      if (filter.is_active) filterCount = filterCount + 1
      if (filter.verified) filterCount = filterCount + 1
      return filterCount === 0
        ? filterCount
        : filterCount === 1
        ? filterCount
        : filterCount
    },

    changeInfluencerType(type) {
      this.showAddInfluencerBtn = true
      if (type === this.checkInfluencerType) return false
      const query = {}
      if (this.$route.query.q) query.q = this.$route.query.q
      if (this.$route.query.list) query.list = this.$route.query.list
      this.$store.commit(influencerTypes.RESET_INFLUENCERS_FILTER)
      if (type === 'twitter') {
        this.$router.push({
          name: 'twitter_influencers',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: query,
        })
      } else if (type === 'instagram') {
        this.$router.push({
          name: 'instagram_influencers',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: query,
        })
      } else if (type === 'youtube') {
        this.$router.push({
          name: 'youtube_influencers',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: query,
        })
      }
    },

    async getInfluencers(type = 'twitter', pageNo = 1) {
      console.debug('getInfluencers', pageNo, this.$route.query)
      let paginationChange = true
      // don't change condition here , things will distrube
      if (
        this.influencers.current_page === pageNo ||
        (this.$route.query && this.$route.query.page === pageNo)
      ) {
        paginationChange = false
      }
      this.influencers.current_page = pageNo
      if (pageNo > 1) {
        this.$router.push({ query: { ...this.$route.query, page: pageNo } })
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.page
        this.$router.push({ query: query })
      }
      if (!paginationChange) this.getInfluencerData(type, pageNo)
    },

    async getInfluencerData(type, pageNo) {
      this.influencers.current_page = pageNo
      this.loaders.search = true
      const res = await this.fetchInfluencers({
        page_number: pageNo,
        type: type,
      })
      this.loaders.search = false
      if (res && res.data) {
        console.log(res.data)
        Object.assign(this.influencers, res.data)
      }
    },

    async getInfluencersFilterStats(type = 'twitter') {
      this.$store.commit(influencerTypes.SET_INFLUENCER_FILTER_LOADER, true)
      const res = await this.fetchInfluencersStats({
        page_number: 1,
        type: type,
      })
      if (res && res.data) {
        if (type === 'twitter') this.influencerFilters = res.data
        else if (type === 'instagram') {
          this.instagramInfluencerFilters = res.data
        } else this.youtubeInfluencerFilters = res.data
        this.$store.commit(influencerTypes.SET_INFLUENCER_FILTER_LOADER, false)
      }
    },

    /**
     * Shortlist influ
     * encer if the user has selected that.
     * @param payload
     * @param payload['screen_name'],
     * @param payload['action']
     * @param payload['influencer']
     * @param payload['influencer_folder_id']
     */
    async shortlistInfluencer(payload) {
      // push/pop folder ids
      switch (payload.action) {
        case 'add':
          if (
            payload.influencer.influencer_folders.indexOf(
              payload.influencer_folder_id
            ) === -1
          ) {
            payload.influencer.influencer_folders.push(
              payload.influencer_folder_id
            )
          }
          break
        case 'remove':
          payload.influencer.influencer_folders.splice(
            payload.influencer.influencer_folders.indexOf(
              payload.influencer_folder_id
            ),
            1
          )
          break
      }

      // delete the influencer payload so that is not being saved.
      delete payload.influencer

      const res = await this.saveShortlistInfluencer(payload)
      if (res && res.status) {
        if (payload.action === 'add') {
          this.alertMessage('Successfully added to the list.', 'success')
        } else {
          this.alertMessage('Successfully removed from the list.', 'success')
        }
      }
    },

    redirectTwitterPreview(influencer) {
      console.debug('redirectTwitterPreview', influencer)
      this.$router.push({
        name: 'twitter_influencer_preview',
        params: { influencer_id: influencer.user_id },
        query: this.$route.query,
      })
      // let stateObject = this
      // setTimeout(function () {
      //   stateObject.showPreview = true
      // }, 1000)
      this.showPreview = true
    },

    redirectInstagramPreview(influencer) {
      console.debug('redirectInstagramPreview', influencer)
      this.$router.push({
        name: 'instagram_influencer_preview',
        params: { influencer_id: influencer.user_id },
        query: this.$route.query,
      })
      this.showPreview = true
    },

    redirectYoutubePreview(influencer) {
      this.$router.push({
        name: 'youtube_influencer_preview',
        params: { influencer_id: influencer.channel_id },
        query: this.$route.query,
      })
      this.showPreview = true
    },

    async searchInfluencersHistory() {
      console.log('searchHistoryFocus Event')
      if (
        !this.getInfluencerSearchKeyword ||
        (this.getInfluencerSearchKeyword &&
          this.getInfluencerSearchKeyword.length < 1)
      ) {
        const res = await this.$store.dispatch(
          'fetchInfluencerSearchHistory',
          'influencers'
        )
        if (res && res.data.status) {
          if (res.data.data) {
            this.searchHistoryKeywords = res.data.data
            this.showSearchHistory = true
          } else {
            this.showSearchHistory = false
          }
        }
      }
    },

    influencerInputSelectItemFromList(keyValue) {
      this.active_key_type.items = this.searchHistoryKeywords
      // if the items has length greater than 0.
      if (this.active_key_type.items.length > 0) {
        if (keyValue === 'up') {
          // if the user does not have entered any key term and index key is set to 0
          // decrement from the original length.

          if (
            !this.active_key_type.key ||
            this.active_key_type.index_of_key === 0
          ) {
            this.active_key_type.key =
              this.active_key_type.items[this.active_key_type.items.length - 1]
            this.active_key_type.index_of_key =
              this.active_key_type.items.length - 1
          } else {
            this.active_key_type.key =
              this.active_key_type.items[this.active_key_type.index_of_key - 1]
            this.active_key_type.index_of_key -= 1
          }
          console.log('arrow up')
        } else {
          // if the length and the user key indexes are the same one
          if (
            this.active_key_type.index_of_key ===
            this.active_key_type.items.length - 1
          ) {
            this.active_key_type.key = this.active_key_type.items[0]
            this.active_key_type.index_of_key = 0
            return
          }
          // dealing with the use case if the user have not entered any value and index of key is set to 0.

          if (
            !this.active_key_type.key &&
            this.active_key_type.index_of_key === 0
          ) {
            this.active_key_type.key =
              this.active_key_type.items[this.active_key_type.index_of_key]
            this.active_key_type.index_of_key = 0
          } else {
            // increment the key and increase the index.
            this.active_key_type.index_of_key += 1
            this.active_key_type.key =
              this.active_key_type.items[this.active_key_type.index_of_key]
          }
          console.log('arrow down')
        }
      }
    },

    getInfluencerListName(id) {
      if (id) {
        const item = this.getInfluencerFolders.data.find(
          (list) => list._id === id
        )
        if (item) return item.name
      }
      return ''
    },

    isInfluencerTypeSelected(status) {
      const types =
        this.$route.query.type && this.$route.query.type.split(',')
          ? this.$route.query.type.split(',')
          : []
      return !!(types.indexOf(status) >= 0)
    },
    changeInfluencerCategoryType(type) {
      console.debug('Method:changeInfluencerCategoryType')
      const types = this.$route.query.type
        ? this.$route.query.type.split(',')
        : []
      types.indexOf(type) >= 0
        ? types.splice(types.indexOf(type), 1)
        : types.push(type)

      if (types.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, type: types.join() },
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.type
        this.$router.push({ query: query })
      }
    },
  },
}
</script>

<style lang="less">
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s;
}

.fade-in-enter, .fade-in-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.is_verified {
  color: #1da1f2;
  margin-left: 5px;
}

.influencer_detail {
  cursor: pointer;
  .username {
    color: #007bff !important;
    display: inline-block;
    margin-left: 5px;
    font-weight: normal !important;
  }
}
.width_900 {
  max-width: 900px;
  margin: 0 auto;
}
</style>
