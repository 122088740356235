<script>
import DateRangePicker from 'vue2-daterange-picker'

import { mapGetters } from 'vuex'
import { social } from '../../../store/states/mutation-types'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export default {
  components: {
    DateRangePicker
  },
  props: [],

  data () {
    return {
      locale: {
        format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
      },
      opens: 'center',
      startDate: '',
      endDate: '',
      minDate: '',
      maxDate: '',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false
    }
  },
  computed: {
    ...mapGetters([
      'getGmbOptions',
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getGmbSharingDetails'
    ]),

    isCtaTopicSelection () {
      return this.getGmbOptions.topic_type === 'STANDARD'
    },

    isOfferTopicSelection () {
      return this.getGmbOptions.topic_type === 'OFFER'
    },

    showActionType () {
      return (
        this.getGmbOptions.action_type &&
        this.getGmbOptions.action_type !== 'CALL'
      )
    },

    isCommonBox () {
      return this.getCommonBoxOptions.status
    },

    getStartMinDate () {
      let minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone)
      minDate = minDate.format('MMMM DD, YYYY HH:mm')
      if (!this.getGmbOptions.start_date) {
        this.$store.commit(social.SET_GMB_OPTIONS_START_DATE, minDate)
      }
      return minDate
    },

    getEndMinDate () {
      return this.getGmbOptions.start_date
    },

    getStartDate () {
      if (this.getGmbOptions.start_date) {
        return this.getGmbOptions.start_date
      }
      const minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone)
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    getEndStartDate () {
      if (this.getGmbOptions.end_date) {
        return this.getGmbOptions.end_date
      }
      const endDate = moment(
        this.getGmbOptions.start_date,
        'MMMM DD, YYYY HH:mm'
      ).add(15, 'days')
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        endDate.format('MMMM DD, YYYY HH:mm')
      )
      return endDate.format('MMMM DD, YYYY HH:mm')
    }
  },
  methods: {
    updateStartDate (values) {
      console.debug('Method::updateStartDate', values)
      const myDate = moment(values.startDate)
      this.$store.commit(
        social.SET_GMB_OPTIONS_START_DATE,
        myDate.format('MMMM DD, YYYY HH:mm')
      )
      const endDate = moment(myDate, 'MMMM DD, YYYY HH:mm').add(15, 'days')
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        endDate.format('MMMM DD, YYYY HH:mm')
      )
    },

    updateEndDate (values) {
      console.debug('Method::updateEndDate', values)
      const myDate = moment(values.startDate)
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        myDate.format('MMMM DD, YYYY HH:mm')
      )
    }
  }
}
</script>

<template>
  <transition name="slide-bottom">
    <div
      class="post_share_option post_share_option_gmb post_share_option_social basic_form_inline"
    >
      <p>Select Google My Business Options</p>

      <div v-if="(getCommonBoxOptions.status && getCommonSharingDetails.video.link) || (!getCommonBoxOptions.status && getGmbSharingDetails.video.link)" class="warning_box mt-3 ml-2  flex justify-between">
        <p>Incase of posting video to GMB, the post will go as media upload and the caption would not be passed.</p>
        <a v-tooltip.top-center="'Learn more about video posting'" class="beacon ml-2"
           href="#" data-beacon-article-modal="621de4f1aca5bb2b753c77b2" style="display: block;">
          <i class="far fa-question-circle p-0" style="background: transparent;padding: 0;"></i>
        </a>
      </div>

      <div v-if="(getCommonBoxOptions.status && !getCommonSharingDetails.video.link) || (!getCommonBoxOptions.status && !getGmbSharingDetails.video.link)" class="schedule_option_block">
        <div class="option_selection">
          <div class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Select post type</p>
            </div>
            <div class="right_input">
              <div class="field_group">
                <select v-model="getGmbOptions.topic_type">
                  <option value="STANDARD">Call To Action</option>
                  <option value="EVENT">Event</option>
                  <option value="OFFER">Offer</option>
                </select>
                <i class="icon-dropdown-cs"></i>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isCtaTopicSelection" class="option_selection cta_option">
          <div class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Start date</p>
            </div>
            <div class="right_input field_group">
              <DateRangePicker
                class="datepicker_input"
                :start-date="getStartDate"
                :opens="opens"
                :locale-data="locale"
                :min-date="getStartMinDate"
                :single-date-picker="singleDatePicker"
                :time-picker="timePicker"
                :time-picker24hour="timePicker24Hour"
                :show-week-numbers="showWeekNumbers"
                :show-dropdowns="showDropdowns"
                :auto-apply="autoApply"
                :ranges="show_ranges ? undefined : false"
                @update="updateStartDate"
              >
                <div
                  slot="input"
                  slot-scope="picker"
                  class="datepicker_content"
                >
                  <i class="icon-Calendar"></i>
                  <span v-if="getGmbOptions.start_date">{{
                    picker.startDate | date
                  }}</span>
                  <span v-else>Select start date</span>
                </div>
              </DateRangePicker>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">End date</p>
            </div>
            <div class="right_input field_group">
              <DateRangePicker
                class="datepicker_input"
                :start-date="getEndStartDate"
                :opens="opens"
                :locale-data="locale"
                :min-date="getEndMinDate"
                :single-date-picker="singleDatePicker"
                :time-picker="timePicker"
                :time-picker24hour="timePicker24Hour"
                :show-week-numbers="showWeekNumbers"
                :show-dropdowns="showDropdowns"
                :auto-apply="autoApply"
                :ranges="show_ranges ? undefined : false"
                @update="updateEndDate"
              >
                <!--with_bg-->
                <div
                  slot="input"
                  slot-scope="picker"
                  class="datepicker_content"
                  style="min-width: 220px"
                >
                  <i class="icon-Calendar"></i>
                  <span v-if="getGmbOptions.end_date">{{
                    picker.startDate | date
                  }}</span>
                  <span v-else>Select end date</span>
                </div>
              </DateRangePicker>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Title</p>
            </div>
            <div class="right_input">
              <div class="field_group">
                <input
                  v-model="getGmbOptions.title"
                  type="text"
                  placeholder="Example : Sale this week"
                  class="full_label"
                  maxlength="57"
                />
              </div>
            </div>
          </div>
          <div v-if="isOfferTopicSelection" class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Coupon code (optional)</p>
            </div>
            <div class="right_input field_group">
              <input
                v-model="getGmbOptions.coupon_code"
                type="text"
                maxlength="58"
                placeholder="Example: 15% off in store"
                class="full_label"
              />
            </div>
          </div>
          <template v-if="isOfferTopicSelection">
            <div class="d-flex align-items-center">
              <div class="left_input field_group">
                <p class="label">Link to redeem offer (optional)</p>
              </div>
              <div class="right_input field_group">
                <input
                  v-model="getGmbOptions.redeem_online_url"
                  type="text"
                  placeholder="Link to redeem offer"
                  class="full_label"
                />
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="left_input field_group">
                <p class="label">Terms and conditions (optional)</p>
              </div>
              <div class="right_input field_group">
                <textarea
                  v-model="getGmbOptions.terms_conditions"
                  class="full_label"
                  placeholder="Terms and conditions"
                ></textarea>
              </div>
            </div>
          </template>
        </div>

        <template v-if="!isOfferTopicSelection">
          <div class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Button Label</p>
            </div>
            <div class="right_input">
              <div class="field_group">
                <select
                  id=""
                  v-model="getGmbOptions.action_type"
                  name=""
                  class=""
                >
                  <option value="">No Button</option>
                  <option value="BOOK">Book</option>
                  <option value="ORDER">Order</option>
                  <option value="SHOP">Shop</option>
                  <option value="LEARN_MORE">Learn more</option>
                  <option value="SIGN_UP">Sign up</option>
                  <option value="CALL">Call</option>
                </select>
                <i class="icon-dropdown-cs"></i>
              </div>
            </div>
          </div>

          <div v-if="showActionType" class="d-flex align-items-center">
            <div class="left_input field_group">
              <p class="label">Button Link</p>
            </div>
            <div class="right_input field_group">
              <input
                v-if="isCommonBox"
                v-model="getCommonSharingDetails.cta_link"
                class="full_label"
                type="url"
                placeholder="https://example.com"
              />
              <input
                v-else
                v-model="getGmbSharingDetails.cta_link"
                class="full_label"
                type="url"
                placeholder="https://example.com"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>
