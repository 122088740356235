var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topics-pane"},[_c('topics-sidebar'),_c('div',{staticClass:"topics-top-pane force-white-bg"},[_c('div',{staticClass:"topics-top-pane__inner"},[_c('div',{staticClass:"topics-top-pane__inner__left_section"},[_c('div',{staticClass:"topics-top-pane__inner__left_section__inner"},[_c('h3',[_vm._v("Curated Topic")]),_c('h2',[_vm._v(_vm._s(_vm.curated_topic.name))])]),_c('div',{staticClass:"topics-top-pane__inner__left_section__follow_container"},[(_vm.displayFollowButton)?_c('button',{staticClass:"btn topics-top-pane__inner__left_section__follow_button",on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('followCuratedTopic', { topic: _vm.curated_topic })}}},[_c('span',[_vm._v("FOLLOW")])]):_vm._e()])]),_c('div',{staticClass:"topics-top-pane__inner__right_section"},[_c('div',{staticClass:"topics-top-pane__inner__right_section__content_types"},[_c('div',{staticClass:"topics-top-pane__inner__right_section__content_types__buttons"},[_c('router-link',{class:{
                active: _vm.$route.name === 'discover_topics_curated_trending',
              },attrs:{"to":{
                name: 'discover_topics_curated_web_content',
                params: { topic_id: _vm.$route.params.topic_id },
              }}},[_c('i',{staticClass:"icon-Content-Feed_icon"}),_c('span',[_vm._v("Content Feed")])]),_c('router-link',{attrs:{"href":"javascript:;","to":{
                name: 'discover_topics_curated_web_insights',
                params: { topic_id: _vm.$route.params.topic_id },
                query: { topic: _vm.$route.query.topic },
              }}},[_c('i',{staticClass:"icon-Insights_icon"}),_c('span',[_vm._v("Insights")])])],1)])])])]),_c('div',{staticClass:"topics-filters-pane force-white-bg"},[_c('div',{staticClass:"topics-filters-pane__container"},[_c('curated-and-custom-topics-channel',{attrs:{"module":'curated'}}),_c('curated-and-custom-topics-filters',{attrs:{"total_results":_vm.total_results}})],1)]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }