<template>
  <div>
    <template v-if="loader">
      <clip-loader
        class="spinner mt-4 mb-3"
        :color="'#6d76ff'"
        :size="'20px'"
      ></clip-loader>
    </template>
    <template v-else>
      <div v-if="!(teams.length > 0)" class="text-center px-3 py-3">
        You have not added any any team member yet.
      </div>
      <b-table
        v-else
        class="onboarding-team__table mt-1"
        thead-tr-class="onboarding-team__table-header"
        tbody-tr-class="onboarding-team__table-body"
        table-class="text-left"
        responsive
        borderless
        :items="teams"
        :fields="teamsFields"
      >
        <template slot="cell(status)" slot-scope="data">
          <button
            :class="
              'btn onboarding-team__status onboarding-team__status--' +
              data.item.status
            "
          >
            {{ data.item.status }}</button
          >
        </template>

        <template slot="cell(actions)" slot-scope="data">
          <button
            v-if="data.item.role !== 'Super Admin'"
            class="btn onboarding-team__trash"
            @click.prevent="removeTeamMemberFromWS(data.item)"
            ><i class="fas fa-trash"></i
          ></button>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  name: 'TeamsTab',
  props: ['workspace'],
  data () {
    return {
      teamsFields: [
        { key: 'name' },
        { key: 'email' },
        { key: 'role' },
        { key: 'actions', label: 'Action' }
      ],
      teams: [],
      loader: false
    }
  },
  watch: {
    'workspace.workspace.members' () {
      console.log('updated')

      this.getTeamMemberDetails()
    }
  },
  mounted () {
    this.getTeamMemberDetails()
  },
  methods: {
    getTeamMemberDetails () {
      this.loader = true
      const wsTeamMembers = this.workspace.workspace.members

      const teamContainer = []
      this.teams = []
      for (let x = 0; x < wsTeamMembers.length; x++) {
        const memberDetails = wsTeamMembers[x]
        // if (memberDetails.role !== 'super_admin') {
        const payload = {
          _id: memberDetails._id,
          name: memberDetails.user.full_name,
          email: memberDetails.user.email,
          role: this.getUserRoles(memberDetails.role),
          team: this.getUsertype(memberDetails),
          status: this.getStatus(memberDetails)
        }

        teamContainer.push(payload)
        // }
      }

      this.teams = teamContainer
      this.loader = false
    },
    getUserRoles (role) {
      if (role === 'super_admin') return 'Super Admin'
      if (role === 'admin') return 'Admin'
      if (role === 'collaborator') return 'Collaborator'
      if (role === 'approver') return 'Approver'
      return ''
    },
    getStatus (member) {
      if (member.status === 'invited') {
        return 'Invited'
      } else if (member.status === 'joined') {
        return 'joined'
      } else {
        return member.status
      }
    },

    getUsertype (member) {
      return member.membership && member.membership === 'client'
        ? 'Client'
        : 'Team'
    },

    async removeTeamMemberFromWS (data) {
      const payload = {
        member_id: data._id,
        workspace_id: this.workspace.workspace_id
      }
      let resp = []
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to remove a team member.',
          {
            title: 'Remove Team Member',
            ...swalAttributes()
          }
        )
        .then((value) => {
          if (value) {
            resp = this.removeWSTeamMember(payload)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })

      // let resp = await this.removeWSTeamMember(payload)

      console.log('hey?!', resp)
    }
  }
}
</script>
