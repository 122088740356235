<template>
  <!-- this is an old one, we no longer use i think-->

  <div class="articles_list">
    <div class="article_inner">
      <template v-if="getDiscoveryFacebookLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <facebook></facebook>

        <template
          v-if="getFacebookPaginationLoader && getFacebookPosts.length == 0"
        >
          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                  alt=""
                />
              </div>
              <p>No more results found for your query.</p>
            </div>
          </div>
        </template>
        <template
          v-else-if="
            getFacebookPaginationLoader && getFacebookPosts.length <= 19
          "
        >
          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                  alt=""
                />
              </div>
              <p>No more results found for your query.</p>
            </div>
          </div>
        </template>
        <infinite-loading
          v-else
          @infinite="onInfiniteFacebook"
          ref="infiniteLoading"
        >
          <span slot="spinner" class="d-block pt-3 pb-4">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>

          <span slot="no-more" class="not_found_text">
            <span class="not_found_text">
              <i class="fa fa-frown-o" aria-hidden="true"></i>
              <span>No more results found for your query.</span>
            </span>
          </span>

          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                  alt=""
                />
              </div>
              <p v-if="getFacebookPosts.length == 0"
                >No results found for your query.</p
              >
              <p v-else>No more results found for your query.</p>
            </div>
          </div>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

const Facebook = () => import('../../../common/Facebook')
export default {
  components: {
    Facebook,
    InfiniteLoading
  },
  created () {
    // this.changeSearchContentType('facebook')
  },
  computed: {
    ...mapGetters([
      'getDiscoveryFacebookLoader',
      'getFacebookPaginationLoader',
      'getFacebookPosts',
      'getFacebookScroll',
      'getFacebookPage'
    ])
  },
  methods: {
    ...mapActions(['setFacebookPage']),
    onInfiniteFacebook ($state) {
      console.log(
        'On facebook infinite',
        this.getFacebookScroll,
        this.getFacebookPage
      )
      if (this.getFacebookScroll) {
        this.setFacebookPage(this.getFacebookPage + 1)
        this.getFacebookData($state)
      }
    }
  }
}
</script>
