<template>
  <!--Post Type chart-->
  <div>
    <!--Post type by Day table-->
    <div class="card_container align_70_30">
      <div class="card_v1">
        <div class="card_inner">
          <div class="card_head">
            <h2>Post Type</h2>
          </div>
          <div class="card_content align_chart_items">
            <div class="d-flex align-items-center">
              <ul
                class="item_highlight_inline item_highlight horizontal"
                :id="post_type_id"
              ></ul>
              <div class="chart">
                <highcharts
                  :options="postTypeRate()"
                  :ref="post_type_id"
                ></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card_v1">
        <div class="box_no_icon_left box_no_icon_left_analytic">
          <div class="box_inner">
            <div class="text">
              <p class="top_text">Total Post</p>
              <h2>{{ getTotalPosts }}</h2>
              <!--green-->

              <div v-tooltip="getPostRateChangeTooltip">
                <template v-if="getDifference">
                  <p v-if="getDifference < 0" class="bottom_text red">
                    <i class="cs-arrow-down"></i>
                    {{ getDifference }}%
                  </p>
                  <p v-else class="bottom_text green">
                    <i class="cs-arrow-up"></i>
                    {{ getDifference }}%
                  </p>
                </template>
                <template v-else>
                  <p class="bottom_text green">0%</p>
                </template>
                <p class="bottom_text dark"> since previous date range </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NO_CHART_DATA } from '@common/constants/messages'
import {
  pieChart,
  pieDataLabels,
  piePlotOptions,
  pieTitle,
  pieTooltip,
  splineCredits
} from '../../common/helper'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    post_type: {},
    post_types_count: {},
    previous_post_types_count: {},
    previous_start_date: {},
    previous_end_date: {}
  },
  data () {
    return {
      post_type_id: 'post_type_rate_highlight',

      no_data_available: NO_CHART_DATA,
      total: {
        videos: 0,
        links: 0,
        photos: 0
      }
    }
  },
  computed: {
    getTotalPosts () {
      this.post_types_count.forEach((item) => {
        switch (item.key) {
          case 'link':
            this.total.links = item.doc_count
            break
          case 'video':
            this.total.videos = item.doc_count
            break
          case 'photo':
            this.total.photos = item.doc_count
            break
        }
      })

      return this.total.links + this.total.photos + this.total.videos
    },
    getDifference () {
      let rate =
        ((this.getTotalPosts - this.previous_post_types_count) /
          this.previous_post_types_count) *
        100
      rate = rate.toFixed(2)
      return isNaN(rate) === true || !isFinite(rate) ? 0 : rate
    },

    countTotalPosts () {
      let count = 0
      this.post_type.forEach((element) => {
        count += element.doc_count ? element.doc_count : 0
      })
      return count
    },
    isContentNotAvailable () {
      return this.post_type.length > 0 && this.countTotalPosts === 0
    },
    getPostRateChangeTooltip () {
      if (this.previous_start_date) {
        return `The number of posts rate has increased <strong>${
          this.getDifference
        }%</strong> compared to the previous period <br> ${
          this.previous_start_date
        } - ${this.previous_end_date.slice(0, 10)}`
      }
    }
  },
  methods: {
    postTypeRate () {
      const tooltip = pieTooltip()

      tooltip.pointFormat =
        '<p class="engagement_point"><span><b>{point.y}</b> {point.name}</span></p>'
      const plot = piePlotOptions()
      plot.pie.dataLabels = pieDataLabels()

      return {
        credits: splineCredits(),
        chart: pieChart(`#${this.post_type_id}`, true),
        plotOptions: plot,
        colors: ['#536eff', '#ffdf46', '#27ca7a'],
        tooltip: tooltip,
        title: pieTitle(),
        series: [
          {
            id: `#${this.post_type_id}`,
            name: 'Post Type',
            size: '80%',
            innerSize: '55%',
            data: [
              // ['Impressions', this.interaction.facebook.total_impressions.value],
              ['Videos', this.total.videos],
              ['Photos', this.total.photos],
              ['Links', this.total.links]
            ]
          }
        ]
      }
    }
  }
}
</script>
