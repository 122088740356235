<template>
  <div
    v-if="!queueStatus && postStatus !== 'published'"
    class="mx-8 ml-14 mb-4"
  >
    <div class="my-2 flex flex-row items-center">
      <div class="w-2/12">When to post</div>
      <div class="w-10/12 flex items-center">
        <button
          v-b-modal.timeRecommendationModal
          :disabled="
            !hasAccounts ||
            isContentCategorySelected ||
            !isRecommendedAccountSelection
          "
          class="focus:outline-none border-0 bg-transparent"
          :class="{
            'text-gray-800':
              !hasAccounts ||
              isContentCategorySelected ||
              isRecommendedAccountSelection,
            'text-blue-900 cursor-pointer':
              hasAccounts &&
              !isContentCategorySelected &&
              isRecommendedAccountSelection,
          }"
          @click="fetchRecommendedTimeData"
          >View Recommended Best Time to Post
        </button>
        <v-popover
          class="mx-2"
          trigger="hover"
          placement="bottom-end"
          popover-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
            ><i class="far fa-question-circle text-base text-blue-900"></i
          ></a>
          <!-- This will be the content of the popover -->
          <template slot="popover">
            <div>Available for:</div>
            <ul>
              <li>
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-instagram"></i>
                <i class="fab fa-twitter tw_bg text-white"></i>
                <i class="fab fa-linkedin-in"></i>
              </li>
            </ul>
            <p class="first-comment__message-box">
              How to add recommended best time?
              <a
                v-close-popover="true"
                href="#"
                class="beacon"
                data-beacon-article-modal="61fb84dcd86136157d9a059a"
                >Click here</a
              >
            </p>
          </template>
        </v-popover>
      </div>
    </div>
    <!--Posting Options-->
    <div
      v-if="!isGlobalContentCategory && getUserRole !== 'Approver'"
      class="my-2 flex flex-row items-center"
    >
      <div class="w-2/12"></div>
      <div class="w-10/12">
        <template>
          <div
            class="grid lg:grid-flow-row desktop:grid-flow-col auto-cols-auto"
          >
            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="post_now"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="now"
                :disabled="
                  !hasPermission('can_schedule_plan') ||
                  isContentCategorySelected
                "
              >
                Post Now
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="schedule"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="schedule"
                :disabled="isContentCategorySelected"
                @change="onClickSchedule"
              >
                Schedule
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="add_to_queue"
                v-model="publishTimeOptions.time_type"
                :disabled="isContentCategorySelected"
                name="post_options"
                value="queued"
              >
                Add to Queue
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="add_to_content_category"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="content_category"
              >
                Add To Content Category
              </CstRadio>
            </div>
            <div class="flex items-center py-2">
              <CstRadio
                id="draft"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="draft"
                @change="onClickDraft"
              >
                Draft
              </CstRadio>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!--Date Picker-->
    <div
      v-if="isSchedulePost || isDraftPost"
      class="my-2 flex flex-row items-center"
    >
      <div class="w-2/12"></div>
      <div class="w-10/12">
        <template>
          <transition name="slide-bottom">
            <div class="flex items-center">
              <DatePicker
                ref="posting-schedule-date-picker"
                class="w-60"
                :value="
                  moment(publishTimeOptions.post_date).format(
                    'MMMM DD, YYYY hh:mm A'
                  )
                "
                :default-value="
                  moment(publishTimeOptions.post_date).format(
                    'MMMM DD, YYYY hh:mm A'
                  )
                "
                :disabled-date="disabledDate"
                :clearable="false"
                type="datetime"
                :show-time-panel="showTimePanel"
                format="MMMM DD, YYYY hh:mm A"
                title-format="MMMM DD, YYYY  hh:mm A"
                value-type="format"
                :append-to-body="false"
                @change="onChangePostDate"
                @close="onClosePostDate"
              >
                <template v-slot:footer="{ emit, value }">
                  <SelectTime
                    :emit="emit"
                    :selected-date="value"
                    :timezone="getWorkspaces.activeWorkspace.timezone"
                  />
                </template>
              </DatePicker>

              <p class="text-xs text-gray-900 ml-2">
                {{ getWorkspaces.activeWorkspace.timezone }} (
                <clock
                  time-format="MMMM DD, YYYY hh:mm A"
                  :timezone="getWorkspaces.activeWorkspace.timezone"
                ></clock>
                )
              </p>
            </div>
          </transition>
        </template>
      </div>
    </div>
    <!--Info Box-->
    <div v-if="showInfoBox" class="my-2 flex flex-row items-center">
      <div class="w-2/12"></div>
      <div class="w-10/12">
        <template>
          <div
            class="rounded-lg p-3"
            :class="{
              'bg-orange-100 text-orange-700':
                !isContentCategorySelected || selectedCategoryHasSlots,
              'bg-orange-100 text-orange-700 border border-red-900':
                isContentCategorySelected && !selectedCategoryHasSlots,
            }"
          >
            <template v-if="isQueuePost">
              <span class="font-semibold">Note:</span>
              There will be a separate post for each social account according to
              the next time-slot available in the respective account's queue.
              <a href="#">learn more here</a>
            </template>
            <template v-if="globalContentCategory">
              <span class="font-semibold">Note:</span>
              This post will be published according to the time slots and social
              channels selected for this global category in each workspace.
            </template>
            <template
              v-if="
                isContentCategorySelected &&
                selectedCategoryHasSlots &&
                !globalContentCategory
              "
            >
              <div
                class="flex flex-col align-center justify-center text-center"
              >
                <div>
                  <span class="font-semibold">Note:</span> Your post will be
                  published at
                  {{
                    getDateTimeFormat(
                      nextContentCategorySlot.date,
                      'ddd MMM, DD YYYY - hh:mm A'
                    )
                  }}
                  ({{
                    getUtcFromWorkspaceTimeZoneFromNow(
                      nextContentCategorySlot.date
                    )
                  }}) from now according to your slot.
                </div>
                <div class="font-0-75rem italic text-gray-800">
                  The delay in starting the campaign is because of the next
                  available slot for the
                  <span class="font-semibold">{{
                    selectedContentCategory.name
                  }}</span
                  >, if you want to start immediately either choose custom
                  slots, regular intervals or add a slot in your selected
                  content category.
                </div>
              </div>
            </template>
            <template
              v-if="
                isContentCategorySelected &&
                !selectedCategoryHasSlots &&
                !globalContentCategory
              "
            >
              <div
                class="flex flex-col align-center justify-center text-center bg-orange-100 text-orange-700"
              >
                <div>
                  You have not created any slots for your category. You need to
                  add slots before you can add content to the category.
                  <a
                    class="text-blue-500 cursor-pointer"
                    href="#"
                    @click="
                      $router.push({ name: 'content_categories' }),
                        $bvModal.hide('composer-modal')
                    "
                  >
                    Click here to create slots.</a
                  >
                  <!--                  <router-link :to="{ name: 'content_categories' }">Click here-->
                  <!--                    to create slots.-->
                  <!--                  </router-link>-->
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!--Repeat Post-->
    <div v-if="showRepeatPostOption" class="my-3 flex flex-row items-center">
      <div class="w-2/12">Repeat Post?</div>
      <div class="w-10/12">
        <CstSwitch v-model="repeatPost" size="small"></CstSwitch>
      </div>
    </div>
    <!--Repeat Post Options-->
    <div
      v-if="repeatPost && showRepeatPostOption"
      class="my-2 flex flex-row items-center"
    >
      <div class="w-2/12"></div>
      <div class="w-10/12">
        <template>
          <div class="col-start-2 col-span-2">
            <div class="flex flex-col space-y-4">
              <div class="flex items-center">
                <div class="w-36">Repeat</div>
                <div class="my-auto">
                  <input
                    v-model="publishTimeOptions.repeat_times"
                    class="w-14 border rounded-md"
                    style="margin-bottom: 0"
                    max="99"
                    min="1"
                    type="number"
                  />
                </div>
                <div class="my-auto ml-2"
                  >time(s)
                  <i
                    v-b-tooltip.hover
                    class="far fa-question-circle"
                    title="Repeat times must be between 1 to 99"
                  ></i>
                </div>
              </div>
              <div class="flex items-center">
                <div class="w-36">With the interval of</div>
                <div class="flex flex-row space-x-2">
                  <input
                    v-model="publishTimeOptions.repeat_gap"
                    type="number"
                    min="1"
                    style="margin-bottom: 0"
                    class="w-14 my-auto border rounded-md"
                  />
                  <div class="relative">
                    <select
                      v-model="publishTimeOptions.repeat_type"
                      class="appearance-none px-4 pr-8 text-black-800 border rounded-md p-1 bg-white cursor-pointer opacity-80"
                    >
                      <option value="Hour">Hour(s)</option>
                      <option value="Day">Day(s)</option>
                      <option value="Week">Week(s)</option>
                      <option value="Month">Month(s)</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-start-2 col-span-2 mt-4">
            <div class="grid grid-cols-4 gap-2">
              <div
                v-for="(time, key) in processPostTimings"
                :key="key"
                class="border border-2 p-1 rounded-md border-green-600 text-green-600 text-center"
              >
                {{ getDateTimeFormat(time, 'MMMM DD, HH:mm') }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <b-modal
      id="timeRecommendationModal"
      centered
      header-class="cs-modal-header"
      hide-footer
      modal-class="time-recommendation-modal"
      no-close-on-backdrop
      size="xl"
      title-class="cs-modal-title"
    >
      <template slot="modal-title">
        Recommended Times
        <a
          v-tooltip.top-center="'Learn more about recommended times'"
          class="beacon ml-2 font-1rem"
          data-beacon-article-modal="61fb84dcd86136157d9a059a"
          href="#"
        >
          <i class="far fa-question-circle"></i>
        </a>
      </template>
      <TimeRecommendations
        :plot-data="plotData"
        :plot-dates="plotDates"
        :plot-title="'Best Time For Social Posting'"
        :recommended-best-time="recommendedBestTime"
        :state="recommendedTimeResponse"
        :user-timezone="getWorkspaces.activeWorkspace.timezone"
        @setRecommendedTime="setRecommendedTime"
      ></TimeRecommendations>
    </b-modal>
  </div>
  <div
    v-else
    class="bg-orange-100 flex mx-auto my-4 p-4 rounded-lg text-orange-700 w-max"
  >
    <div class="text-center">
      <template v-if="postStatus === 'published'"
        >This post is published on</template
      >
      <template v-else>This post is queued and scheduled for</template>
      <span class="font-semibold">
        {{
          moment(publishTimeOptions.post_date).format('MMMM DD, YYYY hh:mm A')
        }}
      </span>
      <i
        v-if="postStatus !== 'published'"
        class="bg-cs-white cursor-pointer icon-edit-cs mx-2 p-2 rounded-md shadow-sm text-black-200"
        @click.prevent="editQueuedPost"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CstSwitch from '@ui/Switch/CstSwitch'
import DatePicker from 'vue2-datepicker'
import { nextAvailableSlotCategoryURL } from '@src/modules/setting/config/api-utils'
import proxy from '@src/modules/common/lib/http-common'
import TimeRecommendations from '@src/modules/publish/components/posting/TimeRecommendation'
import { getTimeRecommendationUrl } from '@src/modules/analytics/config/api-utils'
import CstRadio from '@ui/Radio/CstRadio'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
const clone = require('rfdc/default')

export default {
  name: 'PostingSchedule',
  components: {
    CstSwitch,
    DatePicker,
    TimeRecommendations,
    CstRadio,
    SelectTime,
  },
  mixins: [ComposerHelperMixin],
  props: {
    isInstagramSelected: {
      type: Boolean,
      required: true,
    },
    selectedContentCategory: {
      type: Object,
      default: () => {},
    },
    nextContentCategorySlot: {
      type: Object,
      default: () => {},
    },
    accountSelection: {
      type: Object,
      default: () => ({
        facebook: [],
        twitter: [],
        linkedin: [],
        instagram: [],
      }),
    },
    publishTimeOptions: {
      type: Object,
      default: () => ({}),
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    planId: {
      type: String,
      default: null,
    },
    queueStatus: {
      type: [Boolean, Number],
      default: null,
    },
    postStatus: {
      type: String,
      default: 'draft',
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      startHours: 0,
      repeatPost: false,
      disabledAPI: false,
      disabledMobile: false,
      // instagramPostingMethod: '',  // transferred to composerHelperMixin
      loadingSlots: false,
      recommendedBestTime: [],
      plotData: [],
      plotDates: [],
      recommendedTimeResponse: true,
      moment: moment,
      hasAccounts: false,
      showTimePanel: false,
      postingScheduleState: 'now',
    }
  },
  computed: {
    ...mapGetters(['getMobileDevices', 'getWorkspaces', 'getActiveWorkspace']),

    /**
     * If the content category selected is of type 'global'
     * @returns {boolean|boolean}
     */
    isGlobalContentCategory() {
      return this.selectedContentCategory !== null
        ? this.selectedContentCategory.category_state === 'global'
        : false
    },

    /**
     * If content category is selected or is null
     * @returns {boolean}
     */
    isContentCategorySelected() {
      return this.selectedContentCategory !== null
    },

    /**
     * If 'Add to Queue' option is selected.
     * @returns {boolean}
     */
    isQueuePost() {
      return this.publishTimeOptions.time_type === 'queued'
    },

    /**
     * If 'Schedule' option is selected
     * @returns {boolean}
     */
    isSchedulePost() {
      return this.publishTimeOptions.time_type === 'schedule'
    },
    isDraftPost() {
      return this.publishTimeOptions.time_type === 'draft'
    },

    /**
     * Calculates and returns a start date.
     * @returns {string|*}
     */
    getStartDate() {
      if (this.publishTimeOptions.post_date) {
        return this.publishTimeOptions.post_date
      }
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },
    getMinDate() {
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    /**
     * Shows information box based on the positng option selected. (Error/Info) Box.
     * @returns {boolean}
     */
    showInfoBox() {
      return (
        (this.isQueuePost ||
          this.globalContentCategory ||
          this.selectedContentCategory) &&
        !this.loadingSlots &&
        this.publishTimeOptions.time_type !== 'draft'
      )
    },

    /**
     * Calculates post timings when repeat post option is selected.
     * @returns {*[]}
     */
    processPostTimings() {
      const times = []
      let time = ''

      const selection = this.publishTimeOptions

      if (selection.repeat === 'repeat') {
        if (selection.time_type === 'now') {
          time = this.getWorkspaceTimezonCurrentTime('YYYY-MM-DD HH:mm')
        } else if (selection.time_type === 'schedule') {
          time = selection.post_date
        }

        if (time) {
          times.push(time)

          if (selection.repeat_type === 'Hour') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'h')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Day') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'd')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Week') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'w')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Month') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'M')
                times.push(time)
              }
            }
          }
        }
      }

      return times
    },

    /**
     * If the content category that has been selected has slots.
     * @returns {boolean|*}
     */
    selectedCategoryHasSlots() {
      return (
        this.isContentCategorySelected && this.nextContentCategorySlot !== null
      )
    },

    /**
     * repeat post options is shown when post_now or scheduled options is selected.
     * @returns {boolean}
     */
    showRepeatPostOption() {
      return (
        (this.publishTimeOptions.time_type === 'now' ||
          this.publishTimeOptions.time_type === 'schedule') &&
        this.accountSelection.twitter.length === 0
      )
    },

    isRecommendedAccountSelection() {
      return (
        this.accountSelection.facebook.length > 0 ||
        this.accountSelection.twitter.length > 0 ||
        this.accountSelection.linkedin.length > 0 ||
        this.accountSelection.instagram.length > 0
      )
    },
  },
  watch: {
    publishTimeOptions: {
      handler(val, oldVal) {
        console.log(
          'WATCH::publishTimeOptions ~ newVal, oldVal -> ',
          val,
          oldVal
        )
        if (val.repeat_times < 1) {
          val.repeat_times = 1
        }
        if (val.repeat_times > 99) {
          val.repeat_times = 99
        }

        if (val.repeat_gap < 1) {
          val.repeat_gap = 1
        }
      },
      deep: true,
    },
    planId: function (newVal) {},
    selectedContentCategory: {
      handler(value) {
        console.log('WATCH::selectedContentCategory ~ value -> ', value)
        if (value && value._id) {
          this.fetchContentCategoriesSlots(this.planId, value._id)
        }
      },
      deep: true,
      immediate: true,
    },
    accountSelection: {
      handler(value) {
        let temp = false
        for (const channelName in value) {
          if (value[channelName].length) {
            temp = true
          }
        }
        this.hasAccounts = temp
      },
      deep: true,
      immediate: true,
    },
    repeatPost: function (newVal) {
      if (newVal) {
        this.publishTimeOptions.repeat = 'repeat'
      } else {
        this.publishTimeOptions.repeat = ''
      }
    },
    /**
     * toggle repeat post to false if the option isn't shown
     * @param val
     */
    showRepeatPostOption(val) {
      if (!val && this.repeatPost) {
        this.repeatPost = val
      }
    },
  },
  mounted() {
    console.debug(
      'Posting Schedule Options Mounted ~ socialPostType -> ',
      this.socialPostType
    )
    if (this.socialPostType === 'edit') {
      if (this.publishTimeOptions.time_type === 'schedule')
        this.postingScheduleState = 'schedule'
      if (this.publishTimeOptions.time_type === 'draft')
        this.postingScheduleState = 'draft'
    }
  },
  methods: {
    /**
     * Triggers when user clicks on select time button in the time picker.
     */
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel
    },
    /**
     * Triggers when user clicks on View Recommendations button.
     */
    fetchRecommendedTimeData() {
      if (
        !this.hasAccounts ||
        this.isContentCategorySelected ||
        !this.isRecommendedAccountSelection
      )
        return
      console.log('fetchRecommendedTimeData', this.accountSelection)

      this.plotData = []
      this.plotDates = []
      this.recommendedTimeResponse = true
      const accounts = []
      const tv = moment().tz(this.getWorkspaces.activeWorkspace.timezone)

      this.accountSelection.facebook.forEach((id) => {
        accounts.push({ account_type: 'facebook', facebook_id: id })
      })
      this.accountSelection.instagram.forEach((id) => {
        accounts.push({ account_type: 'instagram', instagram_id: id })
      })
      this.accountSelection.twitter.forEach((id) => {
        accounts.push({ account_type: 'twitter', twitter_id: id })
      })
      this.accountSelection.linkedin.forEach((id) => {
        accounts.push({ account_type: 'linkedin', linkedin_id: id })
      })
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        date:
          tv.format('YYYY-MM-DD') + ' - ' + tv.add(6, 'd').format('YYYY-MM-DD'),
        accounts: accounts,
        timezone: this.getWorkspaces.activeWorkspace.timezone,
        state: 'merged',
      }

      proxy
        .post(getTimeRecommendationUrl, payload)
        .then((resp) => {
          if (resp?.data) {
            resp = JSON.parse(JSON.stringify(resp))
            this.plotDates = resp.data.data[0].keys
            this.plotDates = this.plotDates.map((val) => {
              const formatted = moment(val, 'YYYY-MM-DD')
              return formatted.format('DD-MMM-YYYY')
            })
            this.plotData = resp.data.data[0].plot
            this.recommendedTimeResponse = resp.data.data[0].state
            this.recommendedBestTime = resp.data.data[0].best
          }
        })
        .catch((error) => {
          console.log('ERROR::fetchRecommendedTimeData -> ', error)
        })
    },

    editQueuedPost() {
      this.$emit('editQueuedPost')
    },
    duplicatePost() {
      this.$emit('duplicatePost')
    },

    /**
     * Triggers when user select recomeded time
     */
    setRecommendedTime(recommendedTime) {
      console.log('we here one more time' + recommendedTime)
      const values = {}
      values.startDate = moment(recommendedTime, 'DD-MMM-YYYY hh:mm aa')
      console.log('we here one more time' + values)
      this.publishTimeOptions.post_date = values.startDate.format(
        'MMMM DD, YYYY hh:mm A'
      )
      this.$bvModal.hide('timeRecommendationModal')
      this.publishTimeOptions.time_type = 'schedule'
    },

    /**
     * Fetches next content category available slot. Invoked on Content Category change.
     * @param {String} planId - plan id
     * @param categoryId
     */
    fetchContentCategoriesSlots(planId, categoryId) {
      console.log(
        'METHOD::fetchContentCategoriesSlots ~ categoryId -> ',
        categoryId
      )
      const self = this
      self.loadingSlots = true

      proxy
        .post(nextAvailableSlotCategoryURL, {
          category_id: categoryId,
          workspace_id: self.getActiveWorkspace._id,
          plan_id: planId || null,
        })
        .then((res) => {
          if (res.data.status) {
            this.$emit('set-category-slot', res.data.data)
          }
          self.loadingSlots = false
        })
        .catch((err) => {
          console.log('ERROR::err -> ', err)
          self.loadingSlots = false
        })
    },

    /**
     * onChange event for publish time options when post now option is clicked.
     */
    onClickPostNow() {
      console.log('METHOD::onClickPostNow ~ ')
      this.publishTimeOptions.post_date = moment().format('MMM DD, YYYY HH:mm')
    },

    /**
     * onchange event for publish time options when schedule option is clicked (time calculation added).
     */
    async onClickSchedule() {
      console.log('METHOD::onClickSchedule')
      await this.$nextTick()
      this.$refs['posting-schedule-date-picker'].openPopup()

      if (
        this.postingScheduleState === 'draft' ||
        this.postingScheduleState === 'schedule'
      )
        return

      this.postingScheduleState = 'schedule'
      this.publishTimeOptions.post_date = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
        .format('MMM DD, YYYY HH:mm')
    },

    /**
     * onChange event for publish time options when draft options is clicked (time calculation added).
     */
    onClickDraft() {
      console.log('METHOD::onClickDraft')
      if (
        this.postingScheduleState === 'draft' ||
        this.postingScheduleState === 'schedule'
      )
        return

      this.postingScheduleState = 'draft'
      this.publishTimeOptions.post_date = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
        .format('MMM DD, YYYY HH:mm')
    },

    /**
     * onClose event for when the user apply or click outside the picker
     */
    onClosePostDate() {
      this.showTimePanel = false
    },

    /**
     * on Change event for when the user modifies the date time to the customized time.
     * @param dateTime
     */
    onChangePostDate(dateTime) {
      console.log('METHOD::onChangePostDate ~ event -> ', dateTime)
      this.publishTimeOptions.post_date = clone(
        moment(dateTime).format('MMM DD, YYYY HH:mm')
      )
    },

    /**
     * Range calculator for disabled dates.
     * @param date
     * @returns {boolean}
     */
    disabledDate(date, currentValue) {
      // Converting current time to given timezone.
      const timeStart = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: this.getActiveWorkspace.timezone,
        })
      )
      const timeEnd = new Date(date)

      // Removing 1 day from current time.
      const now = timeStart.setHours(
        timeStart.getHours() - 24,
        timeStart.getMinutes(),
        timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
        timeEnd.getHours(),
        timeEnd.getMinutes(),
        timeEnd.getSeconds()
      )

      return value < now
    },

    /**
     * Range calculator for disabled time.
     * @param date
     * @returns {boolean}
     */
    disabledTime(date) {
      // Converting current time to given timezone.
      const timeStart = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: this.getActiveWorkspace.timezone,
        })
      )
      const timeEnd = new Date(date)

      // Adding 10 minutes into the current time.
      const now = timeStart.setHours(
        timeStart.getHours(),
        timeStart.getMinutes() + 10,
        timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
        timeEnd.getHours(),
        timeEnd.getMinutes(),
        timeEnd.getSeconds()
      )

      return value < now
    },
  },
}
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  opacity: 1;
}
</style>
