<template>
  <div class="tabs_outer">
    <b-tabs pills  align="center">
      <b-tab title="keyWords" active title-item-class="mytabs-costum">
        <div class="d-flex justify-content-between flex-wrap" >
        <div class="tabs-content"v-for="(data,key) in keywords">
            <p>{{data[0]}}</p>
            <span>{{Math.floor(data[1]*100)}}%</span>
          </div>
          <!-- <div class="tabs-content">
            <p> Humor {{sentiments}}</p>
            <span>10%</span>
          </div>
          <div class="tabs-content second">
            <p> Define Humour</p>
            <span>10%</span>
          </div>
          <div class="tabs-content second">
            <p> Amusement</p>
            <span>10%</span>
          </div>
          <div class="tabs-content">
            <p> Important</p>
            <span>10%</span>
          </div>
          <div class="tabs-content memes">
            <p> Funny Twitter memes</p>
            <span>10%</span>
          </div>
          <div class="tabs-content experiences">
            <p> tendency of experiences</p>
            <span>10%</span>
          </div>
          <div class="tabs-content">
            <p> Important</p>
            <span>10%</span>
          </div>
          <div class="tabs-content">
            <p> Amusement</p>
            <span>10%</span>
          </div> -->
        </div>
      </b-tab>
      <b-tab  title-item-class="mytabs-costum" title="Question">
        <div class="d-flex justify-content-between flex-wrap" >
        <div class="tabs-content questions "v-for="(data,key) in questions">
            <p> {{data}}</p>
          </div>
        </div>
      </b-tab>
       <b-tab  title-item-class="mytabs-costum" title="Topics">
        <div class="d-flex justify-content-between flex-wrap" >
        <div class="tabs-content"v-for="(data,key) in topics">
            <p> {{data.text}}</p>
              <span>{{Math.floor(data.relevance*100)}}%</span>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { computed } from 'vue2-dropzone'
export default {
  name: 'HelperTabs',
  props:{
    keywords:{type:Array,required:true},
     questions:{type:Array,required:true},
     topics:{type:Array,required:true}
  },
}
</script>
