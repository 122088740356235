<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  INVALID_FEED_URL_ERROR,
  NULL_AUTOMATION_NAME_ERROR,
  NULL_FEED_URL_ERROR,
  NULL_SOCIAL_ACCOUNT_SELECTION_ERROR,
  RSS_AUTOMATION_GREATER_POST_COUNT_ERROR,
  RSS_AUTOMATION_LESS_POST_COUNT_ERROR,
} from '@common/constants/messages'
import { rssTypes } from '@src/modules/automation/store/recipes/mutation-types'
import { swalAttributes } from '@common/constants/common-attributes'
import { publish } from '@src/modules/publish/store/states/mutation-types'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import AccountSelection from '../../../publish/components/posting/social/AccountSelection'
import ContentCategorySelection from '../../../publish/components/posting/social/ContentCategorySelection'
import ContentCategoriesSlotTime from '../../../publish/components/posting/social/ContentCategoriesSlotTime'
import QueuePostNote from '../../../publish/components/posting/social/QueuePostNote'
import HashtagSelection from '../Selection/HashtagSelection'
import ReplugSelection from '../Selection/ReplugSelection'
// import PreviewSuggestion from '../../../../discovery/components/topics/PreviewSuggestion'
// import FeedUrlSuggessions from '../FeedUrlSuggessions'

export default {
  components: {
    ReplugSelection,
    AccountSelection,
    HashtagSelection,
    ContentCategorySelection,
    ContentCategoriesSlotTime,
    QueuePostNote,
  },
  mixins: [AutoMixin],

  data() {
    return {
      hashtag: false,
      rssShow: [],
      isIncludeDomainsLoading: false,
      include_domains_suggestion_list: [],
      showSuggestion: true,
      multidrop: true,
      validations: {
        name: false,
        social_accounts: false,
        url: false,
        url_valid: false,
        max_post: false,
      },
      messages: {
        name: NULL_AUTOMATION_NAME_ERROR,
        social_accounts: NULL_SOCIAL_ACCOUNT_SELECTION_ERROR,
        url: NULL_FEED_URL_ERROR,
        url_valid: INVALID_FEED_URL_ERROR,
        max_post: this.getMaxPostMessage,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getRssAutomationDetails',
      'getRssAutomationTabStatus',
      'getWorkspaces',
      'getPublishSelection',
      'getTopicSelection',
      'getWorkspaceHashtagValidation',
      'getRSSAutomationLoaders',
    ]),
    getMaxPostMessage() {
      return this.getRssAutomationDetails.max_post < 1
        ? RSS_AUTOMATION_LESS_POST_COUNT_ERROR
        : this.getRssAutomationDetails.max_post > 10
        ? RSS_AUTOMATION_GREATER_POST_COUNT_ERROR
        : ''
    },
  },
  watch: {
    'getRssAutomationDetails.posting_type'(value) {
      if (value === 'content_category') {
        const categoryId = this.getRssAutomationDetails.content_category_id
        if (categoryId && categoryId.length > 3) {
          this.$store.dispatch('nextAvailableSlot', categoryId)
        }
      }
    },
    'getRssAutomationDetails.name'(value) {
      if (value && value.trim().length > 0) this.validations.name = false
    },
    'getRssAutomationDetails.feed_url'(value) {
      if (value && value.trim().length > 0) {
        this.validations.url = false
        if (value.length > 6) {
          if (this.validations.url_valid) {
            this.validations.url_valid = !this.isValidUrl(value)
          }
        }
      }
    },
  },

  created() {
    this.$store.commit(publish.SET_PUBLISH_PLAN_ID, '')
    this.initializeSaveRssAutomationSection()
  },

  mounted() {
    document.addEventListener('keyup', this.keyPressListener)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyPressListener)
  },

  methods: {
    ...mapMutations([rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS]),

    checkAccountValidation(value) {
      this.validations.social_accounts = value
    },

    closeSuggestionDropDown() {
      this.showSuggestion = false
    },
    checkIfFeeds() {
      var str = 'Visit W3Schools'
      var patt = /w3schools/i
      var result = str.match(patt)
      document.getElementById('demo').innerHTML = result
    },
    showRssFeeds(rss) {
      this.$store.commit(rssTypes.SET_RSS_FEED_URL_AUTOMATION, rss)
      this.showSuggestion = false
      // this.include_domains_suggestion_list = []
      // let getText = e.target.innerText
      // let AutomationRssUrl = document.getElementById('AutomationRssUrl')
      // AutomationRssUrl.value = getText
      //
      // console.log(getText)
      // let parser = new DOMParser()
      // xmlDoc = parser.parseFromString(text,"text/xml");

      //  fetch('https://www.usda.gov/rss/latest-releases.xml')
      // // fetch(this.getRssAutomationDetails.feed_url)
      //      .then(RssResponse => RssResponse.text())
      //       .then(str => parser.parseFromString(str, "text/xml"))
      //      .then(RssData => this.rssShow = RssData)
      //
      // fetch(AutomationRssUrl.value)
      //   .then(response => response.text())
      //   .then(RssData => this.rssShow = RssData)

      // let RSS_URL = 'https://www.usda.gov/rss/latest-releases.xml'
      //
      //  fetch(RSS_URL)
      //      .then(response => response.text())
      //      .then(str => new DOMParser().parseFromString(str, "text/xml"))
      //      .then(data => {
      //
      //          const items = data.querySelectorAll("item");
      //          let html = ``;
      //          items.forEach(el => {
      //              html += `<article>
      //                          <h2>
      //                            <a href="${el.querySelector("link").innerHTML}">
      //                              ${el.querySelector("title").innerHTML}
      //                            </a>
      //                          </h2>
      //                     </article>`;
      //          });
      //          document.body.insertAdjacentHTML("beforeend", html);
      //      });
    },

    keyPressListener(event) {
      console.debug('Method::keyPressListener', event.keyCode)
      if (this.$route.name === 'saveRssAutomation') {
        let tab = 'first'

        // if the user presses the escape code.
        const activeTab = this.getRssAutomationTabStatus
        if (event.keyCode === 27) {
          if (activeTab.first === true) {
            this.$router.push({
              name: 'rssAutomationListing',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return false
          }
          tab = activeTab.second ? 'first' : activeTab.third ? 'second' : null
          // the status for the tab will be changed and he will be shown the active one.
          this.$store.commit(rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS, tab)
        }

        // if the enter key is being pressed.
        if (
          event.keyCode === 13 &&
          !event.target.classList.contains('prevent-listener')
        ) {
          // if the user is on the third tab, store the campaign

          if (activeTab.third === true) {
            this.validateAndStoreRSS()
            return false
          }
          // for the first and second tab, validate it first.
          tab = activeTab.first
            ? 'first'
            : activeTab.second
            ? 'second'
            : 'first'
          this.validateRSSAutomation(tab)
        }
      }
    },

    async validateRSSAutomation(tab) {
      const automation = this.getRssAutomationDetails
      const publish = this.getPublishSelection

      // validating the first tab

      if (tab === 'first' || tab === 'All') {
        this.validations.name = this.requiredCheck(automation.name)
        this.validations.social_accounts =
          !this.socialAccountSelectionValidation('RssAutomation')
        if (this.validations.social_accounts || this.validations.name) {
          return false
        }
        this.$store.commit(
          rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS,
          'second'
        )
      }

      // validating the second tab

      if (tab === 'second' || tab === 'All') {
        this.validations.feed_url = this.requiredCheck(automation.feed_url)
        this.validations.url_valid = !this.isValidUrl(automation.feed_url)
        if (this.validations.url || this.validations.url_valid) return false

        // validating the extra options such as days etc.
        if (publish.replug.status) {
          if (this.replugValidation() === false) return false
        }

        if (publish.hashtag.status) {
          await this.$store.dispatch('validateHashtagInput', 'RssAutomation')
          if (this.getWorkspaceHashtagValidation === false) return false
        }

        this.$store.commit(
          rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS,
          'third'
        )
      }

      // validating the third tab

      if (tab === 'third' || tab === 'All') {
        this.validations.max_post =
          automation.max_post < 1 ? true : automation.max_post > 10
        if (this.validations.max_post) return false
      }

      return true
    },
    async validateAndStoreRSS() {
      const self = this
      const publish = this.getPublishSelection
      const saveValidation = await this.validateRSSAutomation('All')
      Promise.resolve(saveValidation).then((value) => {
        if (value === true) {
          if (publish.hashtag.status && publish.hashtag.selected === '') {
            // after hashtag has been saved
            console.log('RSS Saving Confirmation with hashtags part...')
            this.$store
              .dispatch('saveWorkspaceHashtag', 'RssAutomation', this)
              .then((resp) => {
                if (publish.hashtag.selected) {
                  self.showRSSConfirmationModal()
                }
              })
          } else {
            console.log('RSS Saving Confirmation else part...')
            this.showRSSConfirmationModal()
          }
        }
      })
    },

    async showRSSConfirmationModal() {
      const scheduling = this.getRssAutomationDetails
      console.log('[rss confirmation] modal', scheduling)
      if (scheduling.id && scheduling.status === 0) {
        const ok = await this.$bvModal
          .msgBoxConfirm(
            'Your campaign is not active, would you like to activate?',
            {
              title: 'Activate Campaign',
              ...swalAttributes(),
            }
          )
          .then((res) => {
            return res
          })
          .catch(() => {
            return null
          })

        if (ok) {
          const resp = await this.$store.dispatch('storeRSSAutomation', true)
          this.isInvalidURLResponse(resp)
        }
      } else {
        const resp = await this.$store.dispatch('storeRSSAutomation')
        this.isInvalidURLResponse(resp)
      }
    },
    /**
     * Validating the response, if it the invalid url, redirect the user to the second tab.
     * @param res
     */
    isInvalidURLResponse(res) {
      console.log('[invalid response]', res)
      if (res && !res.data.status) {
        if (res.data.invalid_url) {
          this.validations.url_valid = true
          this.$store.commit(
            rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS,
            'second'
          )
        }
      }
    },
  },
}
</script>

<template>
  <div class="automation_main_component create_automation_main_component">
    <div class="component_inner">
      <!--            <router-link class="close_icon float-right"-->
      <!--                         :to="{'name': 'automation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--                &times;-->
      <!--            </router-link>-->

      <button
        class="close_icon float-right"
        @click="
          exitConfirmation(
            'automation',
            ' RSS Feed to Social Media',
            getWorkspaces.activeWorkspace.slug
          )
        "
      >
        &times;
      </button>

      <!--<router-link class="close_icon float-right"-->
      <!--:to="{'name': 'rssAutomationListing', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--&times;-->
      <!--</router-link>-->

      <div class="top_content">
        <h2>New Campaign</h2>
        <img src="../../../../assets/img/automation/rss_feed.svg" alt="" />
        <h3>RSS Feed to Social Media</h3>
        <p>Share RSS feeds content on your social media</p>
      </div>

      <div class="steps_count">
        <div class="count_inner">
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getRssAutomationTabStatus.first === true,
              past: getRssAutomationTabStatus.first === false,
            }"
            @click="SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS('first')"
          >
            <span>1</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getRssAutomationTabStatus.second === true,
              past:
                getRssAutomationTabStatus.first === false &&
                getRssAutomationTabStatus.second === false,
            }"
            @click="validateRSSAutomation('first')"
          >
            <span>2</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{ active: getRssAutomationTabStatus.third === true }"
            @click="validateRSSAutomation('second')"
          >
            <span>3</span>
          </a>
        </div>
      </div>

      <div
        v-show="getRssAutomationTabStatus.first"
        class="page_1 connect_social_accounts"
      >
        <div class="automation_page width_medium">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 1 - Campaign Name and Channels</h3>
            </div>

            <div class="field_group field_no_placeholder">
              <input
                v-model="getRssAutomationDetails.name"
                :class="{ 'input-field-error': validations.name }"
                type="text"
                placeholder="Campaign name e.g marketing posts"
                maxlength="70"
                data-cy="campaign-name"
              />
              <label class="label-animated">Campaign Name</label>
              <span
                v-if="validations.name"
                :class="{ 'input-error': validations.name }"
              >
                {{ messages.name }}
              </span>
            </div>

            <!--<div class="input_field">-->
            <!--<label for="">Campaign Name</label>-->
            <!--<input :class="{'input-field-error': validations.name}" type="text"-->
            <!--placeholder="Enter campaign name..."-->
            <!--v-model="getRssAutomationDetails.name">-->
            <!--<span v-if="validations.name" :class="{'input-error': validations.name}">-->
            <!--{{messages.name}}-->
            <!--</span>-->
            <!--</div>-->

            <div class="field_group">
              <ContentCategorySelection
                position="rss-listing"
                :module="'rss_automation'"
              ></ContentCategorySelection>
            </div>
            <AccountSelection
              :module="'automation'"
              :show-label="true"
              :social_accounts_validation="validations.social_accounts"
              @account-selected="checkAccountValidation"
            ></AccountSelection>
            <span v-if="validations.social_accounts" class="input-error">{{
              messages.social_accounts
            }}</span>

            <!--End Select blog platforms-->

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey mr-1"
                disabled="disabled"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>

              <button
                class="btn btn-studio-theme-space"
                data-cy="step1-button"
                @click.prevent="validateRSSAutomation('first')"
              >
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="getRssAutomationTabStatus.second"
        class="page_2 post_optimization"
      >
        <div class="automation_page d-flex align-items-start">
          <div class="page_left">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 2 - Optimize your posts</h3>
              </div>

              <div class="field_group suggestion_field outside_label">
                <label for="">Feed URL</label>
                <input
                  id="AutomationRssUrl"
                  v-model="getRssAutomationDetails.feed_url"
                  :class="{
                    'input-field-error':
                      validations.url || validations.url_valid,
                    open_multi:
                      include_domains_suggestion_list.length > 0 &&
                      getRssAutomationDetails.feed_url.length > 0 &&
                      showSuggestion,
                  }"
                  type="text"
                  placeholder="Add feed URL here such as ‘http://rss.cnn.com/rss/edition.rss’"
                  autocomplete="off"
                  data-cy="rss-url"
                  @input="
                    asyncIncludeRssResults(getRssAutomationDetails.feed_url)
                  "
                  @click="showSuggestion = true"
                />

                <span
                  v-if="validations.url || validations.url_valid"
                  :class="{
                    'input-error': validations.url || validations.url_valid,
                  }"
                >
                  <template v-if="validations.url">
                    {{ messages.url }}
                  </template>
                  <template v-else-if="validations.url_valid">
                    {{ messages.url_valid }}
                  </template>
                </span>

                <div
                  v-if="
                    include_domains_suggestion_list.length > 0 &&
                    getRssAutomationDetails.feed_url.length > 0 &&
                    showSuggestion
                  "
                  v-on-clickaway="closeSuggestionDropDown"
                  class="field_multi_dropdown"
                  :class="{
                    open_dropdown:
                      include_domains_suggestion_list.length > 0 &&
                      getRssAutomationDetails.feed_url.length > 0,
                  }"
                >
                  <ul>
                    <h4> Feeds Suggestions</h4>
                    <template
                      v-for="(
                        suggested_rss, key
                      ) in include_domains_suggestion_list"
                    >
                      <li :key="key">
                        <span
                          @click.prevent="showRssFeeds(suggested_rss)"
                          v-html="suggested_rss"
                        ></span>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>

              <ReplugSelection></ReplugSelection>
              <HashtagSelection></HashtagSelection>

              <div class="field_group">
                <label>Post Variations</label>

                <div class="pt-2">
                  <label class="radio_container">
                    <input
                      id="automation_without_post_variations"
                      v-model="getRssAutomationDetails.post_variations"
                      type="radio"
                      name="automation_type"
                      value="post_title"
                    />
                    <div for="automation_without_post_variations">
                      Use Post Titles + URL
                      <span class="check"></span>
                    </div>
                  </label>

                  <label class="radio_container">
                    <input
                      id="automation_with_post_variations"
                      v-model="getRssAutomationDetails.post_variations"
                      type="radio"
                      name="automation_type"
                      value="body_content"
                    />
                    <div for="automation_without_post_variations"
                      >Use intelligent post variation (extracted from body
                      content) as caption
                      <span class="check"></span>
                    </div>
                  </label>

                  <label class="radio_container">
                    <input
                      id="automation_with_post_variations_meta"
                      v-model="getRssAutomationDetails.post_variations"
                      type="radio"
                      name="automation_type"
                      value="meta_description"
                    />
                    <div for="automation_with_post_variations_meta"
                      >Use meta description
                      <span class="check"></span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click="SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS('first')"
                >
                  <i class="icon_left cs-angle-left"></i>
                  <span>Prev</span>
                </button>

                <button
                  class="btn btn-studio-theme-space"
                  data-cy="step2-button"
                  @click.prevent="validateRSSAutomation('second')"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <!--<div class="page_right" :class="{'open': getRssAutomationDetails.feed_url.length > 0}">-->
          <!--<div class="page_inner basic_form">-->
          <!--<feed-url-suggessions :rssShow="rssShow"></feed-url-suggessions>-->
          <!--</div>-->
          <!--</div>-->
        </div>
      </div>

      <div
        class="page_3 schedule_campaign"
        :class="{ 'd-none': getRssAutomationTabStatus.third === false }"
      >
        <div class="automation_page width_medium">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 3 - Schedule and Finalize</h3>
            </div>

            <div class="input_field d-flex align-items-center">
              <label for="" class="mb-0 mr-3">Update Frequency</label>

              <div style="min-width: 300px">
                <select
                  id=""
                  v-model="getRssAutomationDetails.pull_frequency"
                  name=""
                  class="with_bg"
                >
                  <option value="5m">Every 5 minutes</option>
                  <option value="15m">Every 15 minutes</option>
                  <option value="30m">Every 30 minutes</option>
                  <option value="1h">Every hour</option>
                  <option value="3h">Every 3 hours</option>
                  <option value="6h">Every 6 hours</option>
                  <option value="12h">Every 12 hours</option>
                  <option value="24h">Once a day</option>
                </select>
              </div>
            </div>

            <div class="input_field">
              <label for="">When new posts are found in this feed</label>
              <div class="radio_list">
                <div class="radio_input_image">
                  <input
                    id="schedule_immediately"
                    v-model="getRssAutomationDetails.posting_type"
                    value="Immediately"
                    type="radio"
                    name="postingType"
                  />
                  <label for="schedule_immediately" class="radio_left"
                    >Post immediately
                  </label>
                </div>

                <div class="radio_input_image">
                  <input
                    id="schedule_queue"
                    v-model="getRssAutomationDetails.posting_type"
                    type="radio"
                    value="Queue"
                    name="postingType"
                  />
                  <label for="schedule_queue" class="radio_left"
                    >Add to Queue
                    <a
                      v-tooltip.top-center="'Learn more about queue slots'"
                      href="https://docs.contentstudio.io/article/691-what-are-social-queues"
                      target="_blank"
                      ><i class="far fa-question-circle ml-1"></i
                    ></a>
                  </label>
                </div>
                <div class="radio_input_image">
                  <input
                    id="content_category"
                    v-model="getRssAutomationDetails.posting_type"
                    :disabled="!getRssAutomationDetails.content_category_id"
                    type="radio"
                    value="content_category"
                    name="postingType"
                  />
                  <label for="content_category" class="radio_left"
                    >Add to Content Category</label
                  >
                  <i
                    v-if="!getRssAutomationDetails.content_category_id"
                    v-tooltip="
                      'Please select content category from first step to add content to the category'
                    "
                    class="far fa-question-circle cursor_pointer m-2"
                    aria-hidden="true"
                  ></i>
                  <a
                    v-else
                    v-tooltip.top-center="'Learn more about Content Category'"
                    href="https://docs.contentstudio.io/article/692-what-are-the-queues-in-content-categories"
                    target="_blank"
                    ><i class="far fa-question-circle ml-1"></i
                  ></a>
                </div>
              </div>
            </div>

            <div
              v-if="getRssAutomationDetails.posting_type === 'Queue'"
              class="input_field"
            >
              <label for="">Where to add new posts in the queue?</label>
              <div class="radio_list">
                <div class="radio_input_image">
                  <input
                    id="first_empty_slot"
                    v-model="getRssAutomationDetails.queue_slot_option"
                    value="first"
                    type="radio"
                    name="queueType"
                  />
                  <label for="first_empty_slot" class="radio_left"
                    >Add to the first empty slot available
                    <a
                      v-tooltip.top-center="
                        'If you delete a post from the planned queue, that slot will be empty and filled first'
                      "
                      href="javascript:;"
                      ><i class="far fa-question-circle ml-1"></i
                    ></a>
                  </label>
                </div>

                <div class="radio_input_image">
                  <input
                    id="bottom_of_queue"
                    v-model="getRssAutomationDetails.queue_slot_option"
                    type="radio"
                    value="bottom"
                    name="queueType"
                  />
                  <label for="bottom_of_queue" class="radio_left"
                    >Add to the bottom of the queue
                    <a
                      v-tooltip.top-center="
                        'Any empty slots between the planned posts will be ignored'
                      "
                      href="javascript:;"
                      ><i class="far fa-question-circle ml-1"></i
                    ></a>
                  </label>
                </div>
              </div>
            </div>

            <transition name="slide-bottom">
              <template
                v-if="
                  getRssAutomationDetails.posting_type === 'content_category'
                "
              >
                <ContentCategoriesSlotTime></ContentCategoriesSlotTime>
              </template>
              <template v-if="getRssAutomationDetails.posting_type === 'Queue'">
                <QueuePostNote></QueuePostNote>
              </template>
            </transition>

            <div class="recurring_block input_field">
              <div class="d-flex align-items-center">
                <p class="text mr-3">Max posts per feed update</p>
                <input
                  v-model="getRssAutomationDetails.max_post"
                  :class="{ 'input-field-error': validations.max_post }"
                  style="min-width: 200px"
                  class="w-auto d-inline-block"
                  type="number"
                  placeholder="Max posts per feed"
                  min="1"
                  max="10"
                />
                <!-- <span v-if="validations.max_post" class="input-error">
                  {{ messages.max_post }}
                </span> -->
              </div>
            </div>

            <!--<div class="input_field">-->

            <!--<label for="">Post Variations</label>-->

            <!--<div class="radio_list">-->
            <!--<div class="radio_input_image">-->
            <!--<input id="automation_without_post_variations" type="radio" name="automation_type"-->
            <!--:value="false"-->
            <!--v-model="getRssAutomationDetails.post_variations">-->
            <!--<label for="automation_without_post_variations" class="radio_left">Use Post Titles +-->
            <!--URL</label>-->
            <!--</div>-->
            <!--<div class="radio_input_image">-->
            <!--<input id="automation_with_post_variations" type="radio" name="automation_type"-->
            <!--:value="true"-->
            <!--v-model="getRssAutomationDetails.post_variations">-->
            <!--<label for="automation_with_post_variations" class="radio_left">Use intelligent post-->
            <!--variation (extracted from body content) as caption</label>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->

            <div
              v-if="getRssAutomationDetails.posting_type === 'Queue'"
              class="input_field"
            >
              <div class="checkbox_input_image">
                <input
                  id="reviewContent"
                  v-model="getRssAutomationDetails.review_content"
                  type="checkbox"
                />
                <label for="reviewContent" class="checkbox_left"
                  >I’d like to review content before it is published</label
                >
              </div>
            </div>

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey mr-1"
                @click="SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS('second')"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                :class="{ btn_disable: getRSSAutomationLoaders.store }"
                class="btn btn-studio-theme-space"
                data-cy="done-button"
                @click.prevent="validateAndStoreRSS"
              >
                <span>Done</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
                <img
                  v-if="getRSSAutomationLoaders.store"
                  style="width: 20px"
                  src="../../../../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
