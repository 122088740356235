<template>
  <b-modal
    id="retweetDialog"
    modal-class="retweet_model normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Quote Tweet</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('retweetDialog')"
      >
        &times;
      </button>
    </div>

    <div class="modal_body">
      <div class="social_accounts social_accounts_list">
        <p class="label">Select Account(s)</p>

        <div
          class="checkbox_input_image checkbox_social_profile"
          v-for="account in getTwitterAccounts.items"
        >
          <input
            :id="'selection_' + account.twitter_id"
            :value="account.twitter_id"
            type="checkbox"
            v-model="getAccountSelection.twitter"
            :data-cy="'retweet_account_' + account.twitter_id"
          />
          <label
            v-tooltip.top-center="account.name"
            :for="'selection_' + account.twitter_id"
            class="checkbox_right"
          >
            <div class="profile_picture w_40 tw">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-twitter"></i>
                </div>
                <img
                  v-if="twitterImage(account)"
                  :src="twitterImage(account)"
                  alt=""
                />
                <img
                  v-else
                  src="../../../../assets/img/profile_default.svg"
                  alt=""
                />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="basic_form">
        <div class="input_field">
          <textarea
            name=""
            id=""
            cols="30"
            rows="8"
            placeholder="Write here..."
            data-cy="retweet_textbox"
            v-model="getTwitterRetweetSelection.comment"
          ></textarea>
        </div>
        <div class="input_field">
          <button
            class="btn gradient_btn loader_right btn_loader large_btn"
            data-cy="retweet_submit"
            @click.prevent="processRetweetShare()"
            :disabled="getPublishLoaders.processRetweetShare"
          >
            <span>Retweet</span>
            <clip-loader
              :color="'#ffffff'"
              :size="'16px'"
              v-if="getPublishLoaders.processRetweetShare"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getTwitterRetweetSelection', 'getPublishLoaders'])
  },

  created () {},
  mounted () {},

  methods: {}
}
</script>
