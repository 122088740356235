var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full cst-drop-shadow bg-white rounded-xl"},[_vm._m(0),(_vm.detail.image && _vm.detail.image.length > 0)?_c('div',{staticClass:"w-100 h-100"},[_c('div',{staticClass:"flex flex-wrap relative rounded-t-3xl overflow-hidden"},[_c('div',{staticClass:"bg-cover bg-no-repeat bg-center w-full max-h-[500px] min-h-[300px]",style:({ 'background-image': 'url(' + _vm.detail.image[0] + ')' }),on:{"click":_vm.handleImagePreview}},[_c('img',{staticClass:"max-w-full max-h-full w-full opacity-0",attrs:{"src":_vm.detail.image[0],"alt":""},on:{"error":(event) => {
              event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
              event.target.classList.remove('opacity-0')
            }}})])])]):(_vm.detail.video.link)?_c('div',[_c('video',{ref:"pinterestPreview",staticClass:"w-100 h-100",attrs:{"controls":"","poster":_vm.getThumbnail}},[_c('source',{attrs:{"src":_vm.detail.video.link,"type":_vm.detail.video['mime_type']}}),_vm._v(" Your browser does not support the video tag. ")])]):_c('img',{staticClass:"w-100 min-h-48 max-h-[33rem]",attrs:{"src":require("@src/assets/img/no_data_images/no_media_found.svg"),"alt":""}}),_c('div',{staticClass:"flex items-center px-3 py-2"},[_c('img',{staticClass:"rounded-full object-cover border border-solid border-black",attrs:{"width":"40","height":"40","src":_vm.account.image,"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}),_c('div',{staticClass:"px-2 font-bold"},[_vm._v(" "+_vm._s(_vm.account.name)+" "),(_vm.followersCount)?_c('span',{staticClass:"block font-normal text-xs text-gray-800"},[_vm._v(_vm._s(_vm.followersCount)+" followers")]):_vm._e()]),_c('span',{staticClass:"ml-auto block font-bold bg-gray-200 py-2 px-3 rounded-full"},[_vm._v("Follow")])]),(_vm.detail.pinterest_title || _vm.detail.message)?_c('div',{staticClass:"flex items-center px-4 py-2"},[_c('div',{staticClass:"text-center text-black w-100"},[(_vm.detail.pinterest_title)?_c('span',{staticClass:"block font-bold text-lg py-1"},[_vm._v(_vm._s(_vm.detail.pinterest_title))]):_vm._e(),_c('span',{staticClass:"block font-normal text-sm whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.getDescription)}})])]):_vm._e(),(_vm.type === 'composer')?_c('div',{staticClass:"flex items-center px-3 py-3 justify-between reaction-icons"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center px-3 py-2"},[_c('div',{staticClass:"ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"},[_c('img',{staticClass:"w-5",attrs:{"src":require("@assets/img/integration/pinterest-icon.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-2xl block"},[_c('i',{staticClass:"fas fa-comment mx-2"}),_c('i',{staticClass:"far fa-heart mx-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('span',{staticClass:"font-bold bg-gray-200 py-2 px-3 rounded-full mx-1"},[_vm._v("View")]),_c('span',{staticClass:"font-bold pin_bg py-2 px-3 rounded-full text-white mx-1"},[_vm._v("Save")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('i',{staticClass:"fas fa-ellipsis-h"})])
}]

export { render, staticRenderFns }