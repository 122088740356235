var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"application-box"},[_c('MediaRemoveConfirmBox'),_c('div',{staticClass:"app-wrapper sticky_bar",class:{
      top_notification: _vm.addTopNotificationClass,
      sticky_bar:
        _vm.isTrialPlan() ||
        _vm.getInstagramAccountsWithPermissionsIssue().no_access_accounts.length >
          0 ||
        _vm.cardHasExpired(),
    }},[(_vm.showLoader)?_c('div',{staticClass:"loading-logo flex justify-center items-center"},[_c('img',{staticStyle:{"width":"50px"},attrs:{"src":require("@assets/img/logo/logo-animation.gif"),"alt":"ContentStudio"}})]):_vm._e(),(!_vm.showLoader)?[_c('div',{staticClass:"fixed bottom-0 left-1/4 w-max z-[1049]"},[(
            !(
              this.$route.name === 'analytics_pdf_report' ||
              this.$route.name === 'social'
            )
          )?_c('HeadNotificationSlider'):_vm._e(),_c('HeaderBillingNotifications',{attrs:{"display-billing-notification":_vm.isBillingActionRequired}})],1),_c('ChangeTrialPlanDialog'),_c('WorkspaceLimitsDialog'),_c('upgrade-plan-dialog'),_c('IncreaseLimitsDialog'),_c('CardInfoDialog'),_c('UpdateFeaturePlan'),_c('createWorkspaceModal'),(
          _vm.checkIfActiveWorkspaceHasLimitExceeded() &&
          _vm.$route.name !== 'manageLimits'
        )?_c('SubscriptionLimitsExceededModal',{attrs:{"is-super-admin":_vm.isActiveUserSuperAdmin()}}):_vm._e(),_c('transition',{attrs:{"name":"slidedown"}},[(_vm.showTopHeaderBar)?_c('StickyBanner',{attrs:{"profile":_vm.getProfile,"show":_vm.isTrialPlan() && _vm.hasPermission('can_see_subscription')}}):_vm._e()],1),(_vm.cardHasExpired() !== null)?_c('NoticeBanner',{attrs:{"is_for":'credit_card_expire',"has_expired":_vm.cardHasExpired()}}):_vm._e(),(
          _vm.getInstagramAccountsWithPermissionsIssue().no_access_accounts
            .length > 0 &&
          _vm.isTrialPlan() === false &&
          _vm.showTopHeaderBar
        )?_c('NoticeBanner',{attrs:{"is_for":'instagram',"no_access_accounts":_vm.getInstagramAccountsWithPermissionsIssue().no_access_accounts}}):_vm._e(),(_vm.showTopHeaderBar)?_c('TopHeaderBar'):_vm._e(),_c('div',{staticClass:"main_inner_container",class:{
          workspace_main_page: _vm.isWorkspaceMainPageRoute(),
          is_guest_page:
            _vm.$route.meta.hasOwnProperty('guest') && _vm.$route.meta.guest,
        },style:({
          'padding-top':
            this.$route.name === 'email_verification' ? '0px!important' : '',
        })},[_c('CommentImagePreview'),(_vm.isVideoAutomationSavingRoute)?_c('ActivateVideoCampaign'):_vm._e(),(_vm.isCSVAutomationSavingRoute)?[_c('RemoveCsvPlanConfirmation'),_c('RemoveCsvPlansConfirmation'),_c('SocialApprovalModal')]:_vm._e(),(_vm.addPostingComponents)?[_c('UploadMediaModal',{attrs:{"type":"BlogEditorFile"}}),_c('InsertFileModal',{staticClass:"planner_insertFile_modal",attrs:{"type":"BlogEditorFile"}}),_c('PasteFromWord',{staticClass:"planner_pasteWord_modal"}),_c('SocialShare',{staticClass:"planner_socialshare_modal"}),_c('BlogShare',{staticClass:"planner_blogshare_modal"})]:_vm._e(),(!(this.$route.name === 'analytics_pdf_report'))?_c('MediaStorageLimitsExceededModal',{attrs:{"is-super-admin":_vm.isActiveUserSuperAdmin()}}):_vm._e(),_c('DisplayFile'),_c('EnhanceImagePintura'),(_vm.getWorkspaces.items.length > 0)?_c('div',[_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.showOnboardingWidget)?_c('GettingStarted'):_vm._e()],1)],1):_vm._e(),_c('HelpDropdown'),_c('offline-js'),_c('SocialModal'),_c('router-view')],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }