var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question_icon article-search-bar-pane__search__input__question"},[_c('div',{staticClass:"dropdown dropdown-hints-suggestions default_style_dropdown prevent_close_dropdown"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Search Tips'),expression:"'Search Tips'",modifiers:{"top-center":true}}],staticClass:"dropdown_header",attrs:{"data-toggle":"dropdown"}},[_c('i',{staticClass:"icon-help-cs"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('div',{staticClass:"search_detail"},[_c('p',[_vm._v("Search by")]),(_vm.getTopicModule === 'influencers')?_c('table',{staticClass:"table"},[_vm._m(0)]):_c('table',{staticClass:"table"},[_c('tbody',[_vm._m(1),_vm._m(2),_vm._m(3),(!_vm.getCuratedQuotes)?[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]:_vm._e()],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',[_c('td',[_vm._v("Search for a keyword:")]),_c('td',[_vm._v("marketing")])]),_c('tr',[_c('td',[_vm._v("Search for a domain:")]),_c('td',[_vm._v("techcrunch.com")])]),_c('tr',[_c('td',[_vm._v("Search by Influencer:")]),_c('td',[_vm._v("@realDonaldTrump")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for a keyword:")]),_c('td',[_vm._v("marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for two keywords:")]),_c('td',[_vm._v("content marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for an exact phrase:")]),_c('td',[_vm._v("\"digital marketing\"")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Remove irrelevant results:")]),_c('td',[_vm._v("marketing -seo -stitcher.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for multiple keywords:")]),_c('td',[_vm._v("search marketing OR SEO OR SEM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for multiple keywords:")]),_c('td',[_vm._v("content AND marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search by site:")]),_c('td',[_vm._v("nytimes.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search by site and multiple keywords:")]),_c('td',[_vm._v("site:foxnews.com trump AND putin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search for keywords in a site:")]),_c('td',[_vm._v("forbes.com marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search by author:")]),_c('td',[_vm._v("author:'Joe Pulizzi'")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Search by sharer:")]),_c('td',[_vm._v("@jaybaer")])])
}]

export { render, staticRenderFns }