var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"add_category","centered":"","header-class":"cs-modal-header","hide-footer":"","size":"lg","title-class":"cs-modal-title"},on:{"hidden":_vm.handleModalClose}},[_c('template',{slot:"modal-title"},[_vm._v(" Add New Category")]),_c('div',{staticClass:"modal_body"},[_c('div',{staticClass:"grid grid-cols-2"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"font-medium mt-4"},[_vm._v("Category Name")]),_c('div',{staticClass:"w-full flex flex-col mt-1 relative"},[_c('CstInputFields',{staticClass:"w-full required-input",attrs:{"value":_vm.getContentCategoryAdd.name,"type":"text","placeholder":"Articles, Tips, Podcasts, Webinars"},on:{"value":(value) => {
                _vm.getContentCategoryAdd.name = value
              },"focus":(e) => {
                _vm.validations.name = false
              }}}),(_vm.validations.name)?_c('span',{staticClass:"absolute top-10 w-max input-error"},[_vm._v(" "+_vm._s(_vm.messages.name)+" ")]):_vm._e()],1),_c('div',{staticClass:"font-medium mt-4"},[_vm._v("Choose Color")]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"add_category_color_boxes px-2"},[_vm._l((_vm.fetchColor(null, null)),function(value,key){return [_c('div',{key:key,staticClass:"float-left w-10 h-10 m-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getContentCategoryAdd.color),expression:"getContentCategoryAdd.color"}],staticClass:"icon",attrs:{"id":key,"type":"radio","name":"color_select"},domProps:{"value":key,"checked":_vm._q(_vm.getContentCategoryAdd.color,key)},on:{"change":function($event){return _vm.$set(_vm.getContentCategoryAdd, "color", key)}}}),_c('label',{class:value +
                    ' item_inner w-full h-full cursor-pointer rounded-md',attrs:{"for":key,"data-cy":"new-category-color"}},[_c('i',{staticClass:"check_icon_planner cs-checked flex h-full items-center justify-center text-white text-xl"})])])]})],2)]),(!_vm.getContentCategoryAdd._id)?_c('div',{staticClass:"clear-both"},[_c('div',{staticClass:"block font-medium mt-4"},[_vm._v("Category Type")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"field_group"},[_c('label',{staticClass:"radio_container",attrs:{"for":"local","data-cy":"local-category"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getContentCategoryAdd.category_state),expression:"getContentCategoryAdd.category_state"}],attrs:{"id":"local","value":"local","type":"radio","name":"category_type"},domProps:{"checked":_vm._q(_vm.getContentCategoryAdd.category_state,"local")},on:{"change":function($event){return _vm.$set(_vm.getContentCategoryAdd, "category_state", "local")}}}),_c('div',[_vm._v(" Local Category "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                      'With local category, you can select your accounts and distribute content within this workspace.'
                    ),expression:"\n                      'With local category, you can select your accounts and distribute content within this workspace.'\n                    "}],staticClass:"far fa-question-circle ml-1"}),_c('span',{staticClass:"check"})])])]),_c('div',{staticClass:"field_group"},[_c('label',{staticClass:"radio_container",attrs:{"for":"global","data-cy":"global-category"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getContentCategoryAdd.category_state),expression:"getContentCategoryAdd.category_state"}],attrs:{"id":"global","value":"global","type":"radio","disabled":!_vm.isEnableGlobalCategoryOption,"name":"category_type"},domProps:{"checked":_vm._q(_vm.getContentCategoryAdd.category_state,"global")},on:{"change":function($event){return _vm.$set(_vm.getContentCategoryAdd, "category_state", "global")}}}),_c('div',[_vm._v(" Global Category "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                      _vm.isEnableGlobalCategoryOption
                        ? 'With global category, you will be able to distribute content to all of your social channels under all workspaces which you created.'
                        : 'Global Content Category can only be created by Super Administrators or Internal Team members.'
                    ),expression:"\n                      isEnableGlobalCategoryOption\n                        ? 'With global category, you will be able to distribute content to all of your social channels under all workspaces which you created.'\n                        : 'Global Content Category can only be created by Super Administrators or Internal Team members.'\n                    "}],staticClass:"far fa-question-circle ml-1"}),_c('span',{staticClass:"check"})])])])])]):_vm._e()]),_c('div',{staticClass:"flex flex-col px-4"},[(
            _vm.getContentCategoryAdd.category_state === 'local' ||
            (_vm.getContentCategoryAdd.category_state === 'global' &&
              _vm.getContentCategoryAdd._id)
          )?[_c('transition',{attrs:{"name":"slide-bottom"}},[_c('div',[_c('div',{staticClass:"flex w-full items-center justify-between mt-4"},[_c('div',{staticClass:"font-medium"},[_vm._v("Select Account(s) "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                      'Select which social profiles this category should be used  for.NB: existing posts will not automatically be copied to any new profiles selected.'
                    ),expression:"\n                      'Select which social profiles this category should be used  for.NB: existing posts will not automatically be copied to any new profiles selected.'\n                    "}],staticClass:"far fa-question-circle ml-1"})]),_c('div',{staticClass:"w-max flex items-center"},[_c('label',{staticClass:"mx-1 !mb-0 cursor-pointer font-medium select-none",attrs:{"for":"selectAllCategoriesAccounts","data-cy":"social-selection-all"}},[_vm._v("Select All")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.select_all_categories_accounts),expression:"select_all_categories_accounts"}],staticClass:"cursor-pointer",attrs:{"id":"selectAllCategoriesAccounts","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.select_all_categories_accounts)?_vm._i(_vm.select_all_categories_accounts,null)>-1:(_vm.select_all_categories_accounts)},on:{"input":_vm.selectAllAccounts,"change":function($event){var $$a=_vm.select_all_categories_accounts,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.select_all_categories_accounts=$$a.concat([$$v]))}else{$$i>-1&&(_vm.select_all_categories_accounts=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.select_all_categories_accounts=$$c}}}})])]),_c('div',{staticClass:"input_field social_accounts mt-2"},[(_vm.totalAddedAccountsCount > 0)?[(_vm.totalSelectedAccount > 0)?_c('div',{staticClass:"flex items-center w-full h-12 px-2"},[_vm._l((_vm.getSelectedAccount),function(account,index){return [(index < 10)?_c('img',{key:index,staticClass:"w-8 h-8 border rounded-full -ml-1",attrs:{"src":account.channel_type === 'gmb'
                            ? 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
                            : account.image,"alt":""},on:{"error":(event) => {
                            event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                          }}}):_vm._e()]}),(_vm.totalSelectedAccount > 10)?_c('span',{staticClass:"w-8 h-8 bg-gray-600 font-medium rounded-full text-center pt-2"},[_vm._v("+"+_vm._s(_vm.totalSelectedAccount - 10))]):_vm._e()],2):_c('div',{staticClass:"flex items-center w-full h-12 px-2 font-medium"},[_vm._v(" No Account Selected ")]),_c('label',{staticClass:"w-full flex items-center !mb-0 relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"bg-cs-light-gray color-border focus:outline-none font-normal px-3 py-2.5 rounded-t-lg shadow-xs text-gray-900 text-sm w-full",attrs:{"placeholder":"Search Account By Name","type":"text"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),(_vm.searchQuery.length)?_c('i',{staticClass:"fas fa-times-circle absolute cursor-pointer opacity-80 right-3 hover:text-red-400",on:{"click":function($event){_vm.searchQuery = ''}}}):_vm._e()]),_c('div',{staticClass:"border !border-t-0 min-h-[18rem] rounded-b-lg max-h-72 overflow-y-scroll"},[(_vm.searchQuery)?[(_vm.searchedAccounts.length > 0)?[_vm._l((_vm.searchedAccounts),function(account,index){return [_c('div',{key:index,staticClass:"text-gray-800 block cursor-pointer hover:bg-gray-100 focus:outline-none text-sm"},[_c('SimpleCheckbox',{staticClass:"group px-3 py-2",attrs:{"name":account.channel_identifier},model:{value:(
                                _vm.getContentCategoryAdd[account.channel_type]
                              ),callback:function ($$v) {_vm.$set(_vm.getContentCategoryAdd, account.channel_type, $$v)},expression:"\n                                getContentCategoryAdd[account.channel_type]\n                              "}},[_c('template',{slot:"label"},[_c('span',{staticClass:"flex items-center"},[_c('span',{staticClass:"relative"},[_c('img',{staticClass:"rounded-full object-cover border",attrs:{"src":_vm.channelImage(
                                          account,
                                          account.channel_type
                                        ),"alt":"","data-fallback":"0","height":"32","width":"32"},on:{"error":(event) => {
                                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                        }}}),_c('img',{staticClass:"absolute -right-0.5 -bottom-0.5 w-5 h-5",attrs:{"src":require('@assets/img/integration/' +
                                          _vm.getSocialImage(
                                            account.channel_type
                                          )),"alt":""}})]),_c('span',{staticClass:"flex flex-col ml-3"},[_c('span',{staticClass:"flex flex-row"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                          content:
                                            'platform_name' in account
                                              ? account.platform_name
                                              : account.name,
                                        }),expression:"{\n                                          content:\n                                            'platform_name' in account\n                                              ? account.platform_name\n                                              : account.name,\n                                        }"}],staticClass:"text-sm text-ellipsis font-normal text-black-100 select-none"},[_vm._v(" "+_vm._s(_vm.truncateString( 'platform_name' in account ? account.platform_name : account.name, 32 ))+" ")])]),_c('span',{staticClass:"block text-xs select-none text-gray-800"},[_vm._v(" "+_vm._s('type' in account ? account.type : 'Profile')+" ")])])])])],2)],1)]})]:[_c('div',{staticClass:"text-center w-full pt-5 font-medium"},[_c('span',[_vm._v("No Account Found")])])]]:_vm._l((_vm.getAddedAccount),function(account,index){return [_c('div',{key:index,staticClass:"text-gray-800 block cursor-pointer hover:bg-gray-100 focus:outline-none text-sm"},[_c('SimpleCheckbox',{staticClass:"group px-3 py-2",attrs:{"name":account.channel_identifier},model:{value:(
                            _vm.getContentCategoryAdd[account.channel_type]
                          ),callback:function ($$v) {_vm.$set(_vm.getContentCategoryAdd, account.channel_type, $$v)},expression:"\n                            getContentCategoryAdd[account.channel_type]\n                          "}},[_c('template',{slot:"label"},[_c('span',{staticClass:"flex items-center"},[_c('span',{staticClass:"relative"},[_c('img',{staticClass:"rounded-full object-cover border",attrs:{"src":_vm.channelImage(
                                      account,
                                      account.channel_type
                                    ),"alt":"","data-fallback":"0","height":"32","width":"32"},on:{"error":(event) => {
                                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                    }}}),_c('img',{staticClass:"absolute -right-0.5 -bottom-0.5 w-5 h-5",attrs:{"src":require('@assets/img/integration/' +
                                      _vm.getSocialImage(account.channel_type)),"alt":""}})]),_c('span',{staticClass:"flex flex-col ml-3"},[_c('span',{staticClass:"flex flex-row"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                      content:
                                        'platform_name' in account
                                          ? account.platform_name
                                          : account.name,
                                    }),expression:"{\n                                      content:\n                                        'platform_name' in account\n                                          ? account.platform_name\n                                          : account.name,\n                                    }"}],staticClass:"text-sm text-ellipsis font-normal text-black-100 select-none"},[_vm._v(" "+_vm._s(_vm.truncateString( 'platform_name' in account ? account.platform_name : account.name, 32 ))+" ")])]),_c('span',{staticClass:"block text-xs select-none text-gray-800"},[_vm._v(" "+_vm._s('type' in account ? account.type : 'Profile')+" ")])])])])],2)],1)]})],2)]:[_c('span',[_vm._v("You have not connected any account yet.")]),_c('a',{staticClass:"ml-2",staticStyle:{"text-decoration":"underline"},attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.GoToSocial.apply(null, arguments)}}},[_vm._v("Connect an account")])]],2)])])]:[_c('transition',{attrs:{"name":"slide-bottom"}},[_c('div',{staticClass:"input_field text_block"},[_c('div',{staticClass:"warning_box"},[_c('ul',{staticClass:"add_category_list_items"},[_c('li',[_vm._v(" Global content category will be created in all workspaces with the same name. ")]),_c('li',[_vm._v(" By default, all accounts in all workspaces will be selected. You'll be able to select/change accounts in each workspace individually after creation of this category. ")]),_c('li',[_vm._v(" You'll be able to create slots in one or all workspaces using the \"Add slot\" button. We recommend having different slots in different workspaces to post the same content at different times. ")]),_c('li',[_vm._v(" Global Content Category can only be created by Super Administrators or Internal Team members. ")])])])])])]],2)]),_c('div',{staticClass:"flex w-full justify-end"},[_c('div',{staticClass:"field_group"},[_c('button',{staticClass:"btn btn-studio-theme-space createContentCategoryBtn",class:{
            enable:
              (!_vm.getContentCategoryAdd.name.trim() === '' &&
                _vm.checkAccountStatus() === true) ||
              (!_vm.getContentCategoryAdd.name.trim() === '' &&
                _vm.getContentCategoryAdd.category_state === 'global'),
          },attrs:{"id":"","data-cy":"create-category","disabled":_vm.btnDisable},on:{"click":_vm.validateAndStoreCategory}},[_c('span',[_vm._v(" "+_vm._s(_vm.getCategoryStoreButton)+" ")]),(_vm.getContentCategoryLoaders.store)?_c('clip-loader',{attrs:{"color":"#ffffff","size":'12px'}}):_vm._e()],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }