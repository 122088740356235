<script>
import Emoji from '@src/modules/publish/components/posting/social/emoji/Emoji'
import { mapGetters, mapActions } from 'vuex'
import { planner } from '@src/modules/planner/store/mutation-types'
import AtTa from 'vue-at/dist/vue-at-textarea'
import {
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  getPlanMedia,
  itemSelectedPlatformVisibleStatus,
} from '@common/lib/planner'
import { parseDescription } from '@common/lib/helper'
import { swalAttributes } from '@common/constants/common-attributes'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'
import PlanPlatforms from '@src/modules/planner/components/view/PlanPlatforms'
import FeedViewApprovalStatus from '../feed-view/FeedViewApprovalStatus'
import FeedViewComment from '../feed-view/FeedViewComment'
import ItemDetails from './ItemDetails'

const importedMethods = {
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  getPlanMedia,
  itemSelectedPlatformVisibleStatus,
  parseDescription,
}

export default {
  components: {
    PlanPlatforms,
    ItemDetails,
    FeedViewComment,
    FeedViewApprovalStatus,
    AtTa,
    Emoji,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      toggleStatus: true,
      commentActionStatus: '',
      file_uploading: false,
      messageType: 'message',
      hover: false,
      showComment: false,
    }
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getProfile',
      'getActiveWorkspaceMembersDetails',
      'getSocialSharingMediaDetails',
    ]),
    getItemId() {
      return this.item._id
    },
    getItemTag() {
      if (this.item.evergreen_automation_id) return 'Evergreen'
      if (this.item.rss_automation_id) return 'RSS'
      if (this.item.repeat_post) return 'Repeat'
      return ''
    },
    getItemCheckboxIdentifier() {
      return 'plan_' + this.item._id
    },
    isPublishedPost() {
      return getStatusClass(this.item) === 'published'
    },
    isFailedPost() {
      return getStatusClass(this.item) === 'failed'
    },
    getAddedByName() {
      return this.item.added_by
    },
    isActionProcessing() {
      return this.item.is_processing
    },
    changePlanStatusActionVisibility() {
      return (
        getStatusClass(this.item) === 'reviewed' &&
        this.item.permissions.change_status
      )
    },
    editPlanStatusActionVisibility() {
      return (
        (!this.isPublishedPost || this.item.blog_reference) &&
        !this.item.retweet_selection
      )
    },
    clonePlanStatusActionVisibility() {
      return this.isPublishedPost && !this.item.retweet_selection
    },
    removePlanStatusActionVisibility() {
      return this.item.permissions.delete_plan
    },
    isItemActive() {
      return this.item.is_active
    },
    replacePlanStatusActionVisibility() {
      return (
        (this.item.article_automation_id || this.item.video_automation_id) &&
        !this.isPublishedPost &&
        !this.isFailedPost
      )
    },
    getPlanAttachmentIcon() {
      const attachment = planHeadAttachment(this.item)
      return attachment.type === 'image' ? 'far fa-eye' : 'fa fa-play'
    },
    getPlanAttachment() {
      const attachment = planHeadAttachment(this.item)
      if (attachment.url) {
        return attachment.type === 'image'
          ? attachment.url
          : attachment.thumbnail
      }
      return false
    },
    hasExecutionTime() {
      return this.item.execution_time
    },
    getFilteredCommentsList() {
      if (this.comments && this.comments.length > 0) {
        return this.show_all_comments ? this.comments : [this.comments[0]]
      }

      return null
    },
  },
  methods: {
    ...importedMethods,
    ...mapActions([]),
    getFileExtention(file) {
      const mediaDetails = file
      let extention = ''
      const imagePatt = /image\//gi
      const gifPatt = /gif/gi
      const videoPatt = /video\//gi
      const optMime = ['jpg', 'png', 'jpeg']

      if (mediaDetails && mediaDetails.mime_type) {
        // If images
        if (
          imagePatt.test(mediaDetails.mime_type) ||
          optMime.includes(mediaDetails.mime_type)
        ) {
          if (gifPatt.test(mediaDetails.mime_type)) {
            extention = 'GIF'
          } else {
            extention = 'PHOTO'
          }
          // eslint-disable-next-line brace-style
        }
        // If video
        else if (videoPatt.test(mediaDetails.mime_type)) {
          extention = 'VIDEO'
        }
      }
      return extention
    },
    hideCommentBox() {
      if (this.showComment) {
        this.showComment = false
      }
    },
    hideFromClientMessage(item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    togglePlanDetails(index) {
      console.debug('Method::togglePlanDetails', index)
      this.toggleStatus = true
      this.$set(this.item, 'write_comment', false)
      this.$set(this.item, 'new_comment', '')
      const plans = this.getPlans.items
      plans[index].is_active = !plans[index].is_active
      this.$store.commit(planner.SET_ITEMS, plans)
    },
    clonePlan(item) {
      console.debug('Method::clonePlan', item)
      item.stateObject = this
      this.$store.dispatch('clonePlan', item)
    },
    editPlan(item) {
      console.debug('Method::editPlan', item)
      item.stateObject = this
      this.$store.dispatch('editPlan', item)
    },
    replacePlan(plan) {
      this.$store.commit(planner.SET_CONFIRMATION_PLAN, plan)
      this.$bvModal.show('replacePlan')
    },
    async removePlan(plan) {
      console.debug('Method::removePlan', plan)
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your post?',
        {
          title: 'Remove Post',
          ...swalAttributes(),
        }
      )
      if (res) {
        const response = await this.$store.dispatch('removePlan', { id: plan })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your post, please try again.',
            type: 'error',
          })
        }
        // else {
        // this.getPlans.items.splice(this.getPlans.items.findIndex(item => item._id === plan), 1)
        // }
      }
    },

    async callProcessPlanApproval(plan, status, comment = false) {
      const res = this.processPlanApproval(plan, status, comment)
      if (res && comment) await this.addCommentAction(plan, true)
    },

    allowWritingComment() {
      this.$set(this.item, 'write_comment', true)
      this.$set(this.item, 'new_comment', '')
      this.messageType = 'message'
    },
    disallowWritingComment() {
      this.$set(this.item, 'write_comment', false)
      this.$set(this.item, 'new_comment', '')
      this.toggleStatus = true
    },

    addCommentStatus(type, index) {
      this.commentActionStatus = type
      this.$set(this.item, 'new_comment', '')
      this.toggleStatus = false
      this.getPlans.items[index].is_active = true
      if (
        this.item.status === 'review' &&
        type === 'approve' &&
        this.item.execution_time.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        this.initializeMissedReviewedDatePicker(this.item)
      }
    },
    async addCommentAction(item, notification = false) {
      console.debug('addCommentAction', item)

      if (!item.new_comment || item.new_comment.trim().length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      }
      this.$set(item, 'loader_store', true)

      const payload = {
        workspace_id: item.workspace_id,
        plan_id: item._id,
        comment: item.new_comment,
        mentioned_user: this.mentionedUserIdsList(item.new_comment),
        type: this.$route.name,
        title: item.title || null,
        is_note: this.messageType === 'note',
        media: this.item.new_comment_media ? this.item.new_comment_media : [],
        notification: notification,
      }

      const res = await this.$store.dispatch('storeFeedViewComment', payload)
      this.$set(item, 'loader_store', false)
      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          if (!item.comments) item.comments = []
          // item.comments.unshift(res.data.comment)
          item.new_comment = ''
          item.write_comment = false
          item.new_comment_media = []
          this.messageType = 'message'
          // this.dispatchCommentSocket(res.data.comment, item._id, 'add')
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
        this.toggleStatus = true
      }
    },
    async uploadCommentsImage(event) {
      this.file_uploading = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.file_uploading = false
      if (res) {
        if (res.data.status) {
          if (this.item.new_comment_media) {
            this.item.new_comment_media.push(res.data.media)
          } else {
            this.$set(this.item, 'new_comment_media', [res.data.media])
          }
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },

    triggerAt() {
      const { ed } = this.$refs
      console.debug(ed, 'asdasdadadasd')
      ed.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },
    textareaBlur() {
      console.debug(
        this.$refs.approval_textarea,
        ' this.$refs.approval_textarea'
      )
      this.$refs.approval_textarea.closePanel()
    },

    displayFile() {
      console.debug('Method::displayFile')
      const attachment = getPlanMedia(this.item)
      if (attachment) {
        EventBus.$emit('displayFile', {
          type: attachment.type,
          media: attachment.media,
          index: 0,
        })
        this.$bvModal.show('display-file-modal')
      }
    },
  },
}
</script>

<style lang="less">
.is_hide_client {
  background: rgba(255, 175, 46, 0.15) !important;
}
</style>

<template>
  <div :id="getItemId" class="item_content" :data-cy="'plan_id_' + getItemId">
    <div
      class="content_top d-flex align-items-stretch c-pointer"
      :class="[
        getStatusClass(item),
        {
          is_hide_client:
            item.publish_time_options && item.publish_time_options.hide_client,
        },
        { border_bottom_zero: isItemActive },
      ]"
      @click.self.prevent="togglePlanDetails(index)"
    >
      <!--<div class="list-eye-ico">-->

      <!--<div class="min_width dropdown_header" data-toggle="dropdown">-->
      <!--<i class="fa fa-eye-slash" title="Only visible to team member"></i>-->
      <!--</div>-->

      <!--<div class="dropdown-menu dropdown-menu-right custom-popup visibility-popup" id="test">-->
      <!--<div class="popup-inner">-->
      <!--<p>Make this post visible to clients?</p>-->
      <!--<div class="buttons">-->
      <!--<button type="">No</button>-->
      <!--<button type="">Yes</button>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->

      <!--<div class="list-eye-ico" v-if="getTeamMembership === 'team' && item.status === 'draft'-->
      <!--&& item.publish_time_options.plan_status === 'Approved' && item.type === 'Composer Social'">-->
      <!--<div class="min_width dropdown_header" data-toggle="dropdown">-->
      <!--<i v-if="item.publish_time_options.hide_client" class="fa fa-eye-slash" title="Hide from clients"></i>-->
      <!--<i v-else class="fa fa-eye" title="Unhide from clients"></i>-->
      <!--</div>-->

      <!--<div class="dropdown-menu dropdown-menu-right custom-popup visibility-popup" id="test">-->
      <!--<div class="popup-inner">-->

      <!--<template v-if="item.publish_time_options.hide_client">-->
      <!--<p >Make this post visible to clients?</p>-->
      <!--<div class="buttons">-->
      <!--<button type="">No</button>-->
      <!--<button @click.prevent="hideFromClientAction(item,false)">Yes</button>-->
      <!--</div>-->
      <!--</template>-->
      <!--<template v-else>-->
      <!--<p >Make this post Invisible to clients?</p>-->
      <!--<div class="buttons">-->
      <!--<button type="">No</button>-->
      <!--<button @click.prevent="hideFromClientAction(item,true)">Yes</button>-->
      <!--</div>-->
      <!--</template>-->

      <!--</div>-->
      <!--</div>-->
      <!--</div>-->

      <div
        class="content_left"
        :class="{ 'padding-top': item.content_category || getItemTag }"
        style="pointer-events: none"
      >
        <div class="top_tags_category" style="pointer-events: auto">
          <div
            v-if="item.content_category"
            v-tooltip.top-center="'Content Category'"
            class="top-category"
            :style="{ 'background-color': item.content_category.color_code }"
            >{{ item.content_category.name }}
          </div>
          <div
            v-if="getItemTag"
            v-tooltip.top-center="'Content Type'"
            class="list_tag"
            >{{ getItemTag }}
          </div>
        </div>

        <div
          style="pointer-events: auto"
          class="item_checkbox checkbox_input_image"
        >
          <input
            :id="getItemCheckboxIdentifier"
            v-model="getPlans.selected_plans"
            type="checkbox"
            name="option2"
            :value="getItemId"
            @change="$parent.$parent.planSelectionChange"
          />
          <label
            :for="getItemCheckboxIdentifier"
            class="no_text checkbox_left"
          ></label>
        </div>

        <div class="content_row d-flex align-items-center">
          <div class="row_left">
            <div class="row_head d-flex align-items-center">
              <i
                v-tooltip="
                  item['partially_failed']
                    ? 'Partially Failed'
                    : item.render_class.tooltip
                "
                style="pointer-events: auto"
                :class="
                  item['partially_failed']
                    ? 'fas fa-adjust , partially_failed partial'
                    : [item.render_class.icon, item.post_state]
                "
                class="status_color left-icon d-flex align-items-center justify-content-center"
              ></i>
              <p v-if="hasExecutionTime">{{
                getWorkspaceTimeZoneTime(
                  item['execution_time']['date'],
                  'hh:mm a'
                )
              }}</p>
              <PlanPlatforms
                style="pointer-events: auto"
                :item="item"
              ></PlanPlatforms>
            </div>

            <p
              :data-cy="'plan_id_' + getItemId + '_description'"
              class="desc"
              v-html="parseDescription(planHeadText(item, 'blog'), 320)"
            ></p>
          </div>

          <!--<div class="row_right img_row ml-auto" v-if="getPlanAttachment">-->
          <!--<div class="right_img d-flex align-items-center justify-content-center">-->
          <!--<img @click="displayFile()" class="" :src="getPlanAttachment" alt="">-->
          <!--</div>-->
          <!--</div>-->
        </div>

        <div class="content_row">
          <div class="tags_block">
            <template v-for="(label, key) in item.labels">
              <span
                v-if="getLabelById(label)"
                :key="key"
                class="tag"
                :class="[getLabelById(label).color]"
                >{{ getLabelById(label).name }}</span
              >
            </template>
          </div>
        </div>

        <div class="content_row d-flex align-items-center">
          <div class="row_left">
            <p class="post_detail">
              Created By : <span class="bold">{{ getAddedByName }}</span> on
              <span class="date">{{
                getWorkspaceTimeZoneTime(item['created_at'], 'MMM DD, hh:mm a')
              }}</span>
              <span class="ml-1" v-html="item.created_from"></span>
            </p>
            <p class="post_detail mt-1">
              Last Updated At :
              <span class="date">{{
                getWorkspaceTimeZoneTime(item['updated_at'], 'MMM DD, hh:mm a')
              }}</span>
            </p>
          </div>
          <div class="row_right ml-auto row_btn listitem-btns">
            <clip-loader
              v-if="isActionProcessing"
              class="align-middle d-inline-block"
              color="#4165ed"
              :size="'16px'"
            ></clip-loader>

            <template
              v-if="item.status === 'review' || item.status === 'draft'"
            >
              <template v-if="item.status === 'review' && !item.approval">
                <template v-if="item.can_perform.approval">
                  <div
                    class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown w-100p"
                  >
                    <button
                      v-tooltip.top-center="'Approve'"
                      :disabled="item.is_processing"
                      type="button"
                      class="btn_click btn white-space-nowrap"
                      @click.prevent="changePlanStatusMethod('scheduled', item)"
                      >Approve</button
                    >
                    <!--@click.prevent="changePlanStatus( { id: item._id, status: 'scheduled' })"-->
                  </div>
                  <div
                    class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown feed_toggle_dropdown_reject w-100p ml-2"
                  >
                    <button
                      v-tooltip.top-center="'Reject'"
                      :disabled="item.is_processing"
                      type="button"
                      class="btn_click btn white-space-nowrap"
                      @click.prevent="changePlanStatusMethod('rejected', item)"
                      >Reject</button
                    >
                    <!--@click.prevent="changePlanStatus({ id: item._id, status: 'rejected' })"-->
                  </div>
                </template>
              </template>

              <template
                v-else-if="
                  item.approval &&
                  item.approval.status === 'pending_approval' &&
                  checkApprovalStatus(item.approval)
                "
              >
                <div
                  class="w-auto btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown"
                >
                  <button
                    type="button"
                    class="btn_click btn white-space-nowrap"
                    :disabled="item.is_processing"
                    @click.prevent="callProcessPlanApproval(item, 'approve')"
                    >Approve</button
                  >
                  <button
                    type="button"
                    class="btn_arrow btn dropdown-toggle dropdown-toggle-split"
                    :disabled="item.is_processing"
                    data-toggle="dropdown"
                  >
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div
                      class="list_item"
                      @click.prevent="
                        addCommentStatus('approve', index), (showComment = true)
                      "
                      >Approve with comment</div
                    >
                  </div>
                </div>
                <div
                  class="w-auto btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown feed_toggle_dropdown_reject ml-2"
                >
                  <button
                    type="button"
                    class="btn_click btn white-space-nowrap"
                    :disabled="item.is_processing"
                    @click.prevent="callProcessPlanApproval(item, 'reject')"
                    >Reject</button
                  >
                  <button
                    type="button"
                    class="btn_arrow btn dropdown-toggle dropdown-toggle-split white-space-nowrap"
                    :disabled="item.is_processing"
                    data-toggle="dropdown"
                  >
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div
                      class="list_item"
                      @click.prevent="
                        addCommentStatus('reject', index), (showComment = true)
                      "
                      >Reject with comment</div
                    >
                  </div>
                </div>
              </template>
            </template>

            <template
              v-if="
                getTeamMembership === 'team' &&
                item.status === 'draft' &&
                item.publish_time_options &&
                item.publish_time_options.plan_status === 'Approved' &&
                item.type === 'Composer Social'
              "
            >
              <div class="min_width dropdown_header" data-toggle="dropdown">
                <button
                  v-tooltip.top-center="hideFromClientMessage(item)"
                  class="btn btn_square eye-ico"
                >
                  <i
                    v-if="item.publish_time_options.hide_client"
                    class="far fa-eye-slash"
                  ></i>
                  <i v-else class="far fa-eye"></i>
                </button>
              </div>
              <div
                id="test"
                class="dropdown-menu dropdown-menu-right custom-popup visibility-popup"
              >
                <div class="popup-inner">
                  <template v-if="item.publish_time_options.hide_client">
                    <p>Make this post visible to clients?</p>
                    <div class="buttons">
                      <button type="">No</button>
                      <button @click.prevent="hideFromClientAction(item, false)"
                        >Yes
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <p>Make this post Invisible to clients?</p>
                    <div class="buttons">
                      <button type="">No</button>
                      <button @click.prevent="hideFromClientAction(item, true)"
                        >Yes</button
                      >
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <button
              v-if="item.can_perform.replace"
              v-tooltip.top-center="
                'This action will fetch a new post with the same rules you have set in the automation campaign'
              "
              :disabled="isActionProcessing"
              class="btn btn_square refresh_icon_planner"
              @click.prevent="replacePlan(item)"
            >
              <i class="far fa-refresh"></i>
            </button>

            <v-popover trigger="hover" placement="top-center">
              <!-- This will be the popover target (for the events and position) -->
              <button
                v-if="canEditPost(item)"
                class="tooltip-target btn btn_square"
                :disabled="isActionProcessing"
                @click.prevent="editPlan(item)"
              >
                <i class="icon-edit-cs"></i
              ></button>

              <!-- This will be the content of the popover -->
              <template slot="popover"> Edit </template>
            </v-popover>

            <button
              v-tooltip.top-center="'Comments'"
              class="btn btn_square"
              :class="{ active_comment: showComment }"
              @click="showComment = !showComment"
            >
              <i class="far fa-comment"></i>

              <div
                v-if="item.comments && item.comments.length > 0"
                class="comment_more"
              >
                <span class="listitem-comment-count">
                  {{ item.comments.length }}
                </span>
              </div>
            </button>

            <button
              v-if="item.can_perform.duplicate"
              v-tooltip.top-center="'Duplicate'"
              :disabled="isActionProcessing"
              class="btn btn_square"
              @click.prevent="clonePlan(item)"
            >
              <i class="far fa-clone"></i>
            </button>

            <!--            <button-->
            <!--              v-if="item.can_perform.delete"-->
            <!--              v-tooltip.top-center="'Delete'"-->
            <!--              :disabled="isActionProcessing"-->
            <!--              class="btn btn_square remove_btn "-->
            <!--              @click.prevent="removePlan(item._id)"-->
            <!--            >-->
            <!--              <i class="icon-delete-cs"></i>-->
            <!--            </button>-->

            <v-popover trigger="hover" placement="top-center">
              <!-- This will be the popover target (for the events and position) -->
              <button
                v-if="item.can_perform.delete"
                class="tooltip-target btn btn_square remove_btn"
                :disabled="isActionProcessing"
                @click.prevent="removePlan(item._id)"
              >
                <i class="icon-delete-cs"></i
              ></button>

              <!-- This will be the content of the popover -->
              <template slot="popover"> Delete </template>
            </v-popover>

            <button
              v-tooltip.top-center="'View Details'"
              :data-cy="'plan_id_' + getItemId + '_status'"
              :class="{ active: isItemActive }"
              class="toggle_bt btn btn_square"
              @click="togglePlanDetails(index)"
            >
              <i
                class="far fa-angle-down relative"
                style="padding-top: 1px"
              ></i>
            </button>

            <div v-if="getPlanAttachment" class="list_preview_image">
              <img
                class=""
                :src="getResizedImageURL(getPlanAttachment, 80, 0)"
                alt=""
                @click="displayFile"
                @error="
                  $event.target.src = !isStorageLink(getPlanAttachment)
                    ? getPlanAttachment
                    : `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                "
              />

              <div
                v-if="
                  getFileExtention(
                    item.sharing_media_details[
                      Object.keys(item.sharing_media_details)[0]
                    ]
                  )
                "
                class="planner-file-format"
                >{{
                  getFileExtention(
                    item.sharing_media_details[
                      Object.keys(item.sharing_media_details)[0]
                    ]
                  )
                }}</div
              >
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isPublishedPost"
        class="ml-auto content_right d-flex align-items-center justify-content-center"
      >
        <div class="states">
          <div class="stat_item d-flex align-items-center">
            <div class="icon">
              <i v-tooltip.top-center="'Likes'" class="cs-thumbs-up"></i>
            </div>
            <div class="value ml-auto">
              <h3>{{ item.posting_stats.likes }}</h3>
            </div>
          </div>

          <div class="stat_item d-flex align-items-center">
            <div class="icon">
              <i v-tooltip.top-center="'Shares'" class="cs-share"></i>
            </div>
            <div class="value ml-auto">
              <h3>{{ item.posting_stats.shares }}</h3>
            </div>
          </div>

          <div class="stat_item d-flex align-items-center">
            <div class="icon">
              <i v-tooltip.top-center="'Comments'" class="cs-comment"></i>
            </div>
            <div class="value ml-auto">
              <h3>{{ item.posting_stats.comments }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-bottom">
      <ItemDetails :item="item"></ItemDetails>
    </transition>

    <div v-if="showComment" v-on-clickaway="hideCommentBox" class="comment_box">
      <div class="planner_comment_block">
        <div v-show="!toggleStatus" class="comment_block_planner basic_form">
          <div class="edit_input border-top-0">
            <div class="profile_picture">
              <div class="text_block">
                <div class="atwho_dropdown mention-feed-dropdown">
                  <AtTa
                    ref="approval_textarea"
                    :members="getActiveWorkspaceMembersDetails"
                    name-key="name"
                  >
                    <template slot="item" slot-scope="member">
                      <div
                        v-on-clickaway="textareaBlur"
                        class="mention-dropdown-li profile_picture"
                      >
                        <div class="picture_block picture_block_background">
                          <div
                            v-if="member.item.image"
                            class="img border-0"
                            :style="{
                              background: 'url(' + member.item.image + ')',
                            }"
                          ></div>
                          <div
                            v-else
                            class="img"
                            style="
                              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                            "
                          ></div>
                        </div>
                        <div class="text_block ml-2 w-auto">
                          <span class="text">{{ member.item.name }}</span>
                        </div>
                      </div>
                    </template>
                    <!--Added CTRL+Enter-->
                    <textarea
                      id="comment-box-top"
                      ref="ed"
                      v-model="item.new_comment"
                      placeholder="Write a comment..."
                      spellcheck="false"
                      @keyup.ctrl.enter="
                        callProcessPlanApproval(item, commentActionStatus, true)
                      "
                    ></textarea>
                    <div
                      class="attachment-icon d-flex justify-content-center align-items-center"
                    >
                      <Emoji type="comment-top" />
                      <label for="list-approval-file-input" class="mb-0"
                        ><i class="far fa-paperclip mb-0"></i
                      ></label>
                      <input
                        id="list-approval-file-input"
                        accept="image/*"
                        type="file"
                        class="d-none mb-0"
                        @change.prevent="uploadCommentsImage"
                      />
                      <i class="far fa-at" @click="triggerAt"></i>
                    </div>
                  </AtTa>
                </div>

                <div class="btn_block">
                  <button
                    class="btn gradient_btn"
                    @click.prevent="
                      callProcessPlanApproval(item, commentActionStatus, true)
                    "
                  >
                    <span v-if="commentActionStatus === 'approve'"
                      >Add & Approve</span
                    >
                    <span v-else>Add & Reject</span>
                  </button>
                  <button
                    class="btn dark_gray ml-2"
                    @click="disallowWritingComment"
                  >
                    <span>Cancel</span>
                  </button>
                </div>

                <div class="attachment-files">
                  <template v-if="file_uploading">
                    <beat-loader
                      :size="'10px'"
                      :color="'#4165ed'"
                    ></beat-loader>
                  </template>
                  <div
                    v-for="(media, key) in item.new_comment_media"
                    :key="key"
                    v-tooltip="media.name"
                    class="img"
                    :style="{ background: 'url(' + media.link + ')' }"
                  >
                    <i
                      class="cs-cross cross_icon"
                      @click="item.new_comment_media.splice(key, 1)"
                    ></i>
                    <div class="image_tool">
                      <i
                        class="cs-eye image_tool_item"
                        @click="previewCommentImage(media.link)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="toggleStatus">
          <div class="comment_block_planner basic_form">
            <div
              class="feed_comment_tabs"
              :class="{ is_comments_tab_hide: !item.approval }"
            >
              <b-tabs content-class="">
                <b-tab title="Comments" active>
                  <div
                    v-if="item.comments && item.comments.length"
                    class="comment_list"
                  >
                    <FeedViewComment
                      :comments="item.comments"
                      :plan_id="item._id"
                    ></FeedViewComment>
                  </div>

                  <div
                    class="edit_input"
                    :class="{ 'note-active': messageType === 'note' }"
                  >
                    <div class="profile_picture d-flex align-items-start">
                      <div class="text_block">
                        <span
                          v-if="!item.write_comment"
                          class="comment-text"
                          @click="allowWritingComment"
                          >Write comment here...</span
                        >
                        <template v-else>
                          <div
                            v-if="getTeamMembership === 'team'"
                            class="change-type"
                          >
                            <div
                              class="type-item"
                              :class="{ active: messageType === 'message' }"
                              @click="messageType = 'message'"
                            >
                              <span>Comment</span>
                              <span
                                class="line_animate_h"
                                :class="{
                                  'active animate': messageType === 'message',
                                }"
                              ></span>
                            </div>
                            <div
                              class="type-item"
                              :class="{ active: messageType === 'note' }"
                              @click="messageType = 'note'"
                            >
                              <span>Internal Note</span>
                              <span
                                class="line_animate_h"
                                :class="{
                                  'active animate': messageType === 'note',
                                }"
                              ></span>
                            </div>
                          </div>
                          <div class="atwho_dropdown mention-feed-dropdown">
                            <AtTa
                              ref="approval_textarea"
                              :members="getActiveWorkspaceMembersDetails"
                              name-key="name"
                            >
                              <template slot="item" slot-scope="member">
                                <div
                                  v-on-clickaway="textareaBlur"
                                  class="mention-dropdown-li profile_picture"
                                >
                                  <div
                                    class="picture_block picture_block_background"
                                  >
                                    <div
                                      v-if="member.item.image"
                                      class="img border-0"
                                      :style="{
                                        background:
                                          'url(' + member.item.image + ')',
                                      }"
                                    ></div>
                                    <div
                                      v-else
                                      class="img"
                                      style="
                                        background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                      "
                                    ></div>
                                  </div>
                                  <div class="text_block ml-2 w-auto">
                                    <span class="text">{{
                                      member.item.name
                                    }}</span>
                                    <span
                                      class="userrole"
                                      :class="getRoleClass(member.item.role)"
                                      >{{
                                        getMutatedUserRole(member.item.role)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </template>
                              <!--Added CTRL+Enter-->
                              <textarea
                                id="comment-box"
                                ref="ed"
                                v-model="item.new_comment"
                                placeholder="Write a comment..."
                                spellcheck="false"
                                @keyup.ctrl.enter="addCommentAction(item)"
                              ></textarea>
                              <div
                                class="attachment-icon d-flex justify-content-center align-items-center"
                              >
                                <Emoji
                                  v-model="item.new_comment"
                                  type="comment"
                                />
                                <label for="list-file-input" class="mb-0"
                                  ><i class="far fa-paperclip mb-0"></i
                                ></label>
                                <input
                                  id="list-file-input"
                                  accept="image/*"
                                  type="file"
                                  class="d-none mb-0"
                                  @change.prevent="uploadCommentsImage"
                                />
                                <i class="far fa-at" @click="triggerAt"></i>
                              </div>
                            </AtTa>
                          </div>
                          <div class="btn_block">
                            <button
                              :disabled="item.loader_store"
                              class="btn gradient_btn btn_loader loader_right"
                              @click.prevent="addCommentAction(item)"
                            >
                              <span v-if="item.loader_store" class=" "
                                >Saving...
                                <clip-loader
                                  :size="'12px'"
                                  :color="'#ffffff'"
                                ></clip-loader
                              ></span>
                              <span v-else class=" ">Add </span>
                            </button>
                            <button
                              class="btn dark_gray ml-2"
                              @click="disallowWritingComment"
                            >
                              <span class=" ">Cancel</span>
                            </button>
                          </div>

                          <!--{{item.new_comment_media}}-->
                          <div class="attachment-files">
                            <template v-if="file_uploading">
                              <beat-loader
                                :size="'10px'"
                                :color="'#4165ed'"
                              ></beat-loader>
                            </template>
                            <div
                              v-for="(media, key) in item.new_comment_media"
                              :key="key"
                              v-tooltip="media.name"
                              class="img"
                              :style="{ background: 'url(' + media.link + ')' }"
                            >
                              <i
                                class="cs-cross cross_icon"
                                @click="item.new_comment_media.splice(key, 1)"
                              ></i>
                              <div class="image_tool">
                                <i
                                  class="cs-eye image_tool_item"
                                  @click="previewCommentImage(media.link)"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab v-if="item.approval" title="Approval Status">
                  <div class="approval_list">
                    <p
                      v-if="item.approval.approvers.length > 1"
                      class="p-2"
                      style="text-align: center"
                    >
                      <span
                        v-html="
                          getApprovalOptionText(item.approval.approve_option)
                        "
                      ></span>
                      <!--Approval Status-->
                      <!--(<span class="approval_status">{{getApprovalOptionText(item.approval.approve_option)}}</span>)-->
                    </p>
                    <FeedViewApprovalStatus
                      :approval="item.approval"
                    ></FeedViewApprovalStatus>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.comment_box {
  background: white;
  z-index: 111;
  position: absolute;
  padding: 20px;
  width: 385px;
  float: right;
  border: 1px solid #f3f7fa;
  box-shadow: 0px 2px 3px 1px #8080801f;
  border-radius: 5px;
  right: 1%;
  text-align: left;
  margin-bottom: 2em;
  h3 {
    font-size: 1rem;
  }
  .comment_item_inner {
    background: #f5f9fc96;
  }
}
.w-100p {
  width: 100px;
}
i.partially_failed {
  transform: rotate(270deg);
  color: #c00000;
}
.list-eye-ico {
  color: #3a485c;
  padding: 5px;
  margin-left: auto;
  float: right;
  position: absolute;
  left: 98%;
  i {
    cursor: pointer;
  }
}
.listitem-btns {
  display: contents;
  pointer-events: auto;
  .dropdown-menu {
    position: absolute !important;
    top: 64% !important;
    left: 88% !important;
    width: 270px;
    box-shadow: 1px 0px 16px 0px #80808061;
  }
}

.popover .popover-header {
  border: 0px !important;
  background: #7478ff !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}
.visibility-popup {
  font-size: 15px;
  width: 250px;
  transform: translate3d(-215px, -75px, 0px) !important;
  .popup-inner {
    padding: 0em 1em !important;
  }
  .buttons {
    float: right !important;
    button {
      cursor: pointer;
      margin: 5px !important;
      padding: 0px 6px !important;
      background: #5e72ff;
      border: 0px;
      color: white;
      border-radius: 3px;
    }
  }
}
.visibility-popup .buttons {
  float: right !important;
}
.is_comments_tab_hide {
  .nav-tabs {
    display: none !important;
  }
}
.comment_more {
  position: absolute;
  width: 0px;
  .listitem-comment-count {
    position: relative;
    background: #6f80ff;
    color: white;
    font-size: 0.625rem;
    padding: 3px 6px;
    border-radius: 100%;
    right: -20px;
    top: -33px;
  }
}

.active_comment {
  background-color: #4165ed !important;
  i {
    color: white !important;
  }
}
.display_eye_img {
  background: #5578ff;
  padding: 2px 5px;
  font-size: 0.75em !important;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 3px;
  border: 0px;
  color: white;
  cursor: pointer;
}
.list_preview_image {
  position: absolute;
  right: 1.125rem;
  top: 8px;
  img {
    border-radius: 5px;
    min-width: 70px;
    height: 70px;
    width: 70px;
    object-fit: cover;
  }

  .planner-file-format {
    padding: 5px 6px;
    border-radius: 4px;
    background-color: #26282c;
    text-transform: uppercase;
    font-size: 0.55rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
</style>
