<template>
  <div>
    <div v-if="loader && posts.length === 0" class="p-3" >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else-if="posts && posts.length > 0">
        <div class="grid grid-cols-2 gap-2 mt-2 w-full">
          <template v-for="(post, index) in posts" >
            <div
                :key="index"
                 :draggable="true"
                class="img w-100 h-32 relative bg-cover bg-no-repeat bg-center rounded-lg bg-white border cursor-pointer"
                :style="{ 'background-image': 'url(' + post._source.image_url + ')' }"
                @click="handleImagePreview(post._source.image_url)"
                 @dragstart="dragstartHandler($event, post)">
              <span class="w-100 h-100 rounded-lg flex items-center justify-center opacity-0"><i class="far fa-eye text-lg text-white"></i></span>
            </div>
          </template>
        </div>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template slot="no-results">
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
    </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>

  </div>
</template>

<script>
/**
 * @param post._source.image_url
 *   Information about the object's members.
 */
import InfiniteLoading from 'vue-infinite-loading'
import NoResult from './NoResult'
export default {
  name: "Quotes",
  components: {
    InfiniteLoading,
    NoResult
  },
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    loader: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'assistantData',
          JSON.stringify({
            id: ev.target.id,
            type: 'image',
            heading: '',
            media: this.backgroundQuoteImageURL(post._source),
            p: post._source.quote || '',
            link: this.backgroundQuoteImageURL(post._source)
          })
      )
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleImagePreview(imageUrl) {
      this.$emit('handle-change','preview', imageUrl);
    }
  }
}
</script>

<style lang="less" scoped>
.img:hover{
  box-shadow: 0 2px 15px 0 #b8bdd1;
span{
  background: #00000037;
  opacity: 1!important;
}
}
</style>