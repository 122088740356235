<template>
  <div class="analytics-summary-block" data-cy="performance-summary">
    <h2>
      {{ heading }}
    </h2>
    <p>{{ subtitle }}</p>

    <!-- Children -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsSummaryBlock',
  components: {},
  props: {
    heading: {
      type: String,
      required: true,
      default: 'All Profile Performance Summary'
    },
    subtitle: {
      type: String,
      required: true,
      default:
        'View your key profile performance metrics from the reporting period.'
    }
  }
}
</script>
