<template>
  <b-tab
    title-item-class="mb-1"
    :active="isActive"
    title-link-class="manage-limits-tab"
  >
    <template slot="title">
      <div class="manage-limits-tab__image">
        <img :src="workspace.workspace.logo" alt="" />
      </div>
      <div class="manage-limits-tab__title">
        {{ workspace.workspace.name }}
      </div>
    </template>

    <b-tabs
      content-class="mt-3"
      nav-class="analytics-tabs analytics-main-tabs my-0 mx-0 manage-limits-tab__nav"
    >
      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab title="Team Members" lazy>
        <slot name="teams"></slot>
      </b-tab>
      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab title="Social Accounts" lazy>
        <slot name="social"></slot>
      </b-tab>
      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab title="Blogs" lazy>
        <slot name="blogs"></slot>
      </b-tab>

      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab title="Automations" lazy>
        <slot name="automations"></slot>
      </b-tab>
    </b-tabs>

    <div
      style="display: flex; position: absolute; top: 0; right: 15px"
      v-b-tooltip="
        workspace.default ? 'You can not remove your default workspace.' : ''
      "
    >
      <button
        @click="$emit('onRemoveWorkspace', workspace)"
        class="btn btn-studio-theme-transparent-red btn-size-small"
        :disabled="workspace.default"
        >Remove Workspace</button
      >
    </div>
  </b-tab>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    workspace: {
      type: Object
    }
  }
}
</script>

<style scoped></style>
