var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articles_list"},[_c('div',{staticClass:"article_inner clear"},[(_vm.getQuoteLoader.search.content.quoteLoader)?_c('beat-loader',{attrs:{"color":'#436aff'}}):[_vm._l((_vm.getQuotes),function(post){return _c('div',{staticClass:"ds_quote_box_grid fb_quote_box_grid article_box_grid"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"image_block"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.backgroundQuoteImageURL(post._source)),expression:"backgroundQuoteImageURL(post._source)",arg:"background-image"}],staticClass:"img"})]),_c('div',{staticClass:"content_block"},[_c('p',{staticClass:"desc",staticStyle:{"min-height":"84px"},domProps:{"innerHTML":_vm._s(_vm.limitTextLength(post._source.quote, 183))}}),_c('p',{staticClass:"author_name",staticStyle:{"min-height":"21px"}},[(post._source.author)?[_vm._v(" - "+_vm._s(post._source.author))]:[_vm._v(" - Anonymous")]],2),_c('div',{staticClass:"btn_block d-flex text-center"},[_c('div',{staticClass:"btn_block_inner"},[_c('div',{staticClass:"article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"},[_c('div',{staticClass:"dropdown_header",attrs:{"data-toggle":"dropdown"},on:{"click":function($event){return _vm.initializeQuotesPostCreation(post._source)}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Share this post'),expression:"'Share this post'",modifiers:{"top-center":true}}],staticClass:"btn light_gray"},[_c('i',{staticClass:"icon-Share_icon d-block"})])])])])])])])])}),_c('div',{staticClass:"clearfix"}),(
          _vm.getQuoteLoader.search.content.infiniteLoader &&
          _vm.getQuotes.length == 0
        )?_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('h2',[_vm._v("No results found")]),_c('p',[_vm._v("No results found for your query. Please try to change your search query.")])])]):_vm._e(),(
          _vm.getQuoteLoader.search.content.infiniteLoader &&
          _vm.getQuotes.length <= 19
        )?_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',[_vm._v("No More results found for your query. Please try to change your search query.")])])]):_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.onInfinite}},[_c('span',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1),_c('div',{staticClass:"not_found_text",attrs:{"slot":"no-more"},slot:"no-more"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('h2',[_vm._v("No more results found")]),_c('p',[_vm._v("No more results found for your query.")])])])]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),(_vm.getQuotes.length == 0)?_c('p',[_vm._v("No results found for your query.")]):_c('p',[_vm._v("No more results found for your query.")]),_c('p',[_vm._v("Please try a different search string or change search filters.")])])])])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }