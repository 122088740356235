<template>
  <div class="bottom_image_selection">
    <p
      class="top_text"
      v-if="
        getUnselectedThumbnails.length &&
        (type === 'Facebook' ||
          type === 'Common' ||
          type === 'Youtube' ||
          type === 'Instagram'
          )
      "
      >Suggested Thumbnails ({{ getUnselectedThumbnails.length }})</p
    >

    <template v-if="details.video && details.video.link">
      <div class="popup_div d-inline-block" v-on-clickaway="closePopup">
        <div
          class="img composer_selected_item"
          v-if="!newSharingVideoLoader"
          :style="{
            'background-image': 'url(' + details.video.thumbnail + ')',
          }"
          v-tooltip="{
            placement: 'top-start',
            content: getFileTooltip(details.video.link),
            delay: { show: 400 },
            template: `<div class='tooltip tooltip-file-detail' role='tooltip'><div class='tooltip-inner'></div></div>`,
          }"
        >
          <i
            class="cs-cross cross_icon"
            @click.prevent="removeSharingVideo()"
          ></i>
          <div
            class="display_layer"
            @click.prevent="displayFile(details.video.link)"
          ></div>
          <!--          <div class="composer-file-format" v-if="getFileExtention(details.video.link)">{{getFileExtention(details.video.link)}}</div>-->
          <div class="image-footer">
            <div class="image-footer__title">
              {{ getFileTitle(details.video.link) }}
            </div>

            <div class="image-footer__buttons text-center" v-if="type === 'Facebook' || type === 'Common'">
              <i
                v-tooltip.top-center="videoTitleTooltip(details.video)"
                @click="triggerVideoTitleModal(details.video)"
                class="icon-edit-cs image_tool_item"
                style="font-size: 12px"
              ></i>
            </div>
          </div>

          <!--                    <div class="error-message-icon" v-if="hasVideoError">-->
          <!--                        <i v-tooltip.bottom-start="{ content:errorsContent, classes: 'tooltip-danger'}" class="far fa-exclamation-circle"></i>-->
          <!--                    </div>-->

          <v-popover
            offset="10"
            placement="bottom-start"
            popoverClass="tooltip-danger tooltip-danger--clickable"
            v-if="hasVideoError"
            trigger="hover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <div class="error-message-icon">
              <i class="far fa-exclamation-circle"></i>
            </div>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div v-html="errorsContent"></div>
            </template>
          </v-popover>
        </div>
        <div class="input_popup" v-if="showPupupStatus">
          <div class="popup_inner">
            <i @click="showPopup('close')" class="cs-cross"></i>
            <label for="">Title</label>
            <input
              type="text"
              v-model="details.video.title"
              placeholder="Add Video title..."
            />
            <button @click="showPopup('close')" class="btn gradient_btn">
              <span>Done</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <div class="img composer_selected_item" v-if="newSharingVideoLoader">
      <div
        class="list_plan_bars uploading_bar d-flex justify-content-center flex-column"
      >
        <div class="bar_item" v-if="getSharingVideoPercentage">
          <div class="item_inner">
            <div
              class="limit_add"
              :style="{ width: getSharingVideoPercentage + '%' }"
            >
              <span
                class="text d-block"
                style="font-size: 0.625rem; line-height: 0.875rem; color: #fff"
              >
              </span>
            </div>
            <div class="limit_exceed"></div>
          </div>
          <p class="text" v-if="getSharingVideoPercentage > 90">
            Finishing...
          </p>
          <p class="text" v-else>{{ getSharingVideoPercentage }}%</p>
        </div>
        <clip-loader v-else color="#4165ed" :size="'16px'"></clip-loader>
      </div>
    </div>

    <div
      class="right_img"
      v-if="
        getUnselectedThumbnails.length &&
        (type === 'Facebook' ||
          type === 'Common' ||
          type === 'Youtube' ||
          type === 'Instagram')
      "
    >
      <label class="upload-thumbnail-btn" v-if="type !== 'Instagram'">
        <template v-if="!isThumbnailUploading">
          <i class="far fa-cloud-upload"></i>
          <span class="text">Custom<br />Thumbnail</span>
        </template>
        <clip-loader v-else color="#4165ed" :size="'16px'"></clip-loader>
        <input
          type="file"
          accept="image/png,image/jpeg,.jpeg,.jpg,.png"
          class="d-none"
          @change="onCustomFileChange"
          :disabled="isThumbnailUploading"
        />
      </label>
      <div class="scroll_area">
        <div
          class="img composer_selected_item thumbnail_item"
          :class="{ active: thumbnail === details.video.thumbnail }"
          v-for="(thumbnail, key) in getUnselectedThumbnails"
          :style="{ 'background-image': 'url(' + thumbnail + ')' }"
          @click.prevent="addSharingVideo(thumbnail)"
          :key="key"
        >
        </div>
      </div>
    </div>

    <!--Composer - Video title modal-->
    <b-modal
      ref="alt-text-modal"
      id="alt-text-modal"
      v-model="videoTitleModal"
      no-close-on-backdrop
      centered
      hide-header
      size="lg"
    >
      <header class="modal-header media-library-modal-header">
        <h3 class="font-weight-bold my-auto text-capitalize">
          Add title for video file
          <!--<a class="beacon ml-2" v-tooltip.top-center="'Learn more about Alt-Texts'"
             href="#">
              <i class="far fa-question-circle"
              ></i>
          </a>-->
        </h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="closeVideoTitleModal"
          >×</button
        >
      </header>

      <b-container fluid class="pt-4 px-4 pb-2">
        <b-row>
          <b-col sm="3" class="my-auto">
            <b-img
              center
              width="150"
              height="150"
              :src="details.video.thumbnail"
              alt="Image 1"
            ></b-img>
          </b-col>
          <b-col sm="9">
            <b-textarea
              id="textarea"
              v-model="videoTitle"
              placeholder="Video Title..."
              rows="6"
              :state="textAreaValidation"
              no-resize
              max-rows="6"
            ></b-textarea>
          </b-col>
          <b-col offset-sm="10" sm="2" class="d-flex justify-content-end">
            <span>{{ videoTitleCharCount }}/{{ videoTitleCharLimit }}</span>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ ok }">
        <div class="px-3">
          <p
            >Available for:
            <!--<span style="color: #1DA1F2">
              <i  size="24" class="fab fa-twitter-square fa-lg px-1">

              </i>
            </span>-->
            <span style="color: #3b5998">
              <i size="24" class="fab fa-facebook-square fa-lg px-1"> </i>
            </span>
          </p>
        </div>
        <b-button
          size="lg"
          variant="primary"
          @click="onClickSaveVideoTitle($event)"
          :disabled="videoTitleSaveButtonStatus"
        >
          Save
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import {
  gmb,
  instagramTypes,
  linkedinSharingTypes, tiktokTypes,
  youtubeTypes
} from '../../../../store/states/mutation-types'
import { directive as onClickaway } from 'vue-clickaway'
import { MediaHelperMixin } from '../../../media-library/utils/MediaHelpers'
import {
  socialIntegrations,
  socialIntegrationsConfigurations
} from '../../../../../integration/config/api-utils'

export default {
  props: {
    type: {},
    details: {},
    threadedTweetIndex: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      showPupupStatus: false,
      isThumbnailUploading: false,
      videoTitleModal: false,
      videoTitleCharLimit: 100,
      videoTitle: ''
    }
  },
  mixins: [MediaHelperMixin],
  computed: {
    ...mapGetters([
      'getCommonSharingProgressBar',
      'getFacebookSharingProgressBar',
      'getTwitterSharingProgressBar',
      'getThreadedTweetsSharingDetails',
      'getTumblrSharingProgressBar',
      'getInstagramSharingProgressBar',
      'getLinkedinSharingProgressBar',
      'getPinterestSharingProgressBar',
      'getGmbSharingLoaders',
      'getGmbSharingProgressBar',
      'getYoutubeSharingProgressBar',
      'getTiktokSharingProgressBar',
      'getInstagramPostingOption'
    ]),
    getUnselectedThumbnails () {
      console.debug(
        'getUnselectedThumbnails',
        this.details.video.thumbnails_suggestions
      )

      const stateObject = this
      const suggestions = []
      if (this.details.video && this.details.video.thumbnails_suggestions) {
        $(this.details.video.thumbnails_suggestions).each(function (index, el) {
          if (stateObject.details.thumbnail !== el) {
            suggestions.push(el)
          }
        })
      }
      return suggestions
    },

    newSharingVideoLoader () {
      switch (this.type) {
        case 'Common':
          return this.getPublishLoaders.upload_common_sharing_video
        case 'Facebook':
          return this.getPublishLoaders.upload_facebook_sharing_video
        case 'Twitter':
          return this.getPublishLoaders.upload_twitter_sharing_video
        case 'ThreadedTweets':
          return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
            .upload_thread_tweets_sharing_video
        case 'Linkedin':
          return this.getPublishLoaders.upload_linkedin_sharing_video
        case 'Pinterest':
          return this.getPublishLoaders.upload_pinterest_sharing_video
        case 'Tumblr':
          return this.getPublishLoaders.upload_tumblr_sharing_video
        case 'Instagram':
          return this.getPublishLoaders.upload_instagram_sharing_video
        case 'Youtube':
          return this.getPublishLoaders.upload_youtube_sharing_video
        case 'Tiktok':
          return this.getPublishLoaders.upload_tiktok_sharing_video
        case 'Gmb':
          return this.getGmbSharingLoaders.upload_sharing_video
        default:
      }

      return false
    },

    getSharingVideoPercentage () {
      switch (this.type) {
        case 'Common':
          return this.getCommonSharingProgressBar.video
        case 'Facebook':
          return this.getFacebookSharingProgressBar.video
        case 'Twitter':
          return this.getTwitterSharingProgressBar.video
        case 'ThreadedTweets':
          return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
            .progressBar.video
        case 'Linkedin':
          return this.getLinkedinSharingProgressBar.video
        case 'Pinterest':
          return this.getPinterestSharingProgressBar.video
        case 'Tumblr':
          return this.getTumblrSharingProgressBar.video
        case 'Instagram':
          return this.getInstagramSharingProgressBar.video
        case 'Youtube':
          return this.getYoutubeSharingProgressBar.video
        case 'Tiktok':
          return this.getTiktokSharingProgressBar.video
        case 'Gmb':
          return this.getGmbSharingProgressBar.video
        default:
      }

      return 0
    },

    hasVideoError () {
      const errors = this.postVideoErrors
      return !!(errors && errors.length > 0)
    },

    errorsContent () {
      let content = ''
      const errors = this.postVideoErrors
      errors.forEach((error) => {
        content += content ? '<span></span>' + error : error
      })
      return content
    },

    postVideoErrors () {
      console.debug('Method::postVideoErrors')
      let errors = []
      const stateObject = this

      if (this.getCommonBoxOptions.status) {
        socialIntegrations.forEach((integration) => {
          errors = errors.concat(stateObject.specificVideoErrors(integration))
        })
        return errors
      }

      errors = stateObject.specificVideoErrors(this.type.toLowerCase()) // todo for threadedTweets

      return errors
    },

    videoTitleCharCount () {
      return this.videoTitle.length
    },

    videoTitleSaveButtonStatus () {
      return this.videoTitleCharCount > this.videoTitleCharLimit
    },
    textAreaValidation () {
      var resp = null
      resp = this.videoTitle.length <= 100 ? null : false
      return resp
    }
  },
  methods: {
    ...mapMutations([
      'SET_COMMON_SHARING_VIDEO',
      'SET_FACEBOOK_SHARING_VIDEO',
      'SET_TWITTER_SHARING_VIDEO',
      'setThreadedTweetsSharingVideo',
      'SET_TUMBLR_SHARING_VIDEO',
      'SET_COMMON_SHARING_VIDEO_THUMBNAIL',
      'SET_FACEBOOK_SHARING_VIDEO_THUMBNAIL',
      'SET_TWITTER_SHARING_VIDEO_THUMBNAIL',
      'setThreadedTweetsSharingVideoThumbnail',
      'SET_TUMBLR_SHARING_VIDEO_THUMBNAIL',
      'SET_PINTEREST_SHARING_VIDEO',
      'SET_PINTEREST_SHARING_VIDEO_THUMBNAIL',
      'SET_COMMON_SHARING_VIDEO_SUGGESTIONS',
      'SET_FACEBOOK_SHARING_VIDEO_SUGGESTIONS',
      'SET_TWITTER_SHARING_VIDEO_SUGGESTIONS',
      'SET_PINTEREST_SHARING_VIDEO_SUGGESTIONS',
      'SET_TUMBLR_SHARING_VIDEO_THUMBNAIL'
    ]),

    getFileTitle (file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let title = ''
      if (mediaDetails[file]) {
        if (mediaDetails[file].name) title = mediaDetails[file].name + ' '
      }
      return title
    },
    showPopup (status) {
      if (status === 'open') {
        this.showPupupStatus = true
      } else {
        this.showPupupStatus = false
      }
    },

    closePopup () {
      this.showPupupStatus = false
    },

    removeSharingVideo () {
      console.debug('Method:removeSharingVideo', this.threadedTweetIndex)

      switch (this.type) {
        case 'Common':
          this.SET_COMMON_SHARING_VIDEO(null)
          break
        case 'Facebook':
          this.SET_FACEBOOK_SHARING_VIDEO(null)
          break
        case 'Twitter':
          this.SET_TWITTER_SHARING_VIDEO(null)
          break
        case 'ThreadedTweets':
          this.setThreadedTweetsSharingVideo({
            video: null,
            index: this.threadedTweetIndex
          })
          break
        case 'Linkedin':
          this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
          break
        case 'Pinterest':
          this.SET_PINTEREST_SHARING_VIDEO(null)
          break
        case 'Tumblr':
          this.SET_TUMBLR_SHARING_VIDEO(null)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
          break
        case 'Youtube':
          this.$store.commit(youtubeTypes.SET_SHARING_VIDEO, null)
          break
        case 'Tiktok':
          this.$store.commit(tiktokTypes.SET_SHARING_VIDEO, null)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_SHARING_VIDEO, null)
          break
        default:
      }
    },

    addSharingVideo (thumbnail) {
      console.debug('Method:addSharingVideo', thumbnail)
      switch (this.type) {
        case 'Common':
          this.SET_COMMON_SHARING_VIDEO_THUMBNAIL(thumbnail)
          break
        case 'Facebook':
          this.SET_FACEBOOK_SHARING_VIDEO_THUMBNAIL(thumbnail)
          break
        case 'Twitter':
          this.SET_TWITTER_SHARING_VIDEO_THUMBNAIL(thumbnail)
          break
        case 'ThreadedTweets':
          this.setThreadedTweetsSharingVideoThumbnail({
            thumbnail,
            index: this.threadedTweetIndex
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_VIDEO_THUMBNAIL,
            thumbnail
          )
          break
        case 'Pinterest':
          this.SET_PINTEREST_SHARING_VIDEO_THUMBNAIL(thumbnail)
          break
        case 'Tumblr':
          this.SET_TUMBLR_SHARING_VIDEO_THUMBNAIL(thumbnail)
          break
        case 'Instagram':
          this.$store.commit(
            instagramTypes.SET_SHARING_VIDEO_THUMBNAIL,
            thumbnail
          )
          break
        case 'Youtube':
          this.$store.commit(
            youtubeTypes.SET_SHARING_VIDEO_THUMBNAIL,
            thumbnail
          )
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_SHARING_VIDEO_THUMBNAIL, thumbnail)
          break
        default:
      }
    },

    displayFile (media) {
      console.debug('Method::displayFile')
      EventBus.$emit('displayFile', { type: 'video', media: media })
      this.$bvModal.show('display-file-modal')
    },

    away: function () {
      this.$bvModal.hide('display-file-modal')
    },

    specificVideoErrors (type) {
      // todo for threadedTweets
      if (type === 'threadedtweets') {
        type = 'twitter'
      }
      console.debug('Method::specificVideoErrors', type)
      const errors = []
      const selectedPlatforms = this.getAccountSelection
      const mediaDetails = this.getSocialSharingMediaDetails

      // checking specific type account is selected
      if (
        selectedPlatforms &&
        selectedPlatforms[type] &&
        selectedPlatforms[type].length
      ) {
        // checking for the video validations
        if (
          this.details.video &&
          this.details.video.link &&
          mediaDetails[this.details.video.link] &&
          socialIntegrationsConfigurations[type] &&
          socialIntegrationsConfigurations[type].validations.video
        ) {
          if (
            mediaDetails[this.details.video.link].size >
            socialIntegrationsConfigurations[type].validations.video.size
          ) {
            errors.push(
              socialIntegrationsConfigurations[type].validations.video
                .size_error
            )
          }
          if (
            mediaDetails[this.details.video.link].duration_seconds >
            socialIntegrationsConfigurations[type].validations.video
              .max_duration
          ) {
            const mediaSize =
              mediaDetails[this.details.video.link].duration_seconds
            const message =
              socialIntegrationsConfigurations[type].validations.video
                .max_duration_error
            const dynamicMessage =
              ' Your video is ' +
              mediaSize +
              ' seconds. Please reduce the length of this video.'
            if (type === 'instagram') {
              if (this.getInstagramPostingOption === 'api') {
                errors.push(message.concat(dynamicMessage))
              }
            } else {
              errors.push(message.concat(dynamicMessage))
            }
          }
          if (
            mediaDetails[this.details.video.link].duration_seconds <
            socialIntegrationsConfigurations[type].validations.video
              .min_duration
          ) {
            errors.push(
              socialIntegrationsConfigurations[type].validations.video
                .min_duration_error
            )
          }

          if (
            type === 'instagram' &&
            this.getInstagramPostingOption === 'api'
          ) {
            const media = this.details.video
            const validations =
              socialIntegrationsConfigurations.instagram.validations.video

            // should be AAC
            if (
              media.audio_codec &&
              media.audio_codec.toLowerCase() !== validations.audio_codec
            ) {
              errors.push(
                'For Instagram API, audio codec for the video needs to be AAC, ' +
                  media.audio_codec +
                  ' provided.'
              )
            }

            // should be less than or equal to 48khz
            if (
              media.audio_hertz &&
              media.audio_hertz > validations.audio_hertz_max
            ) {
              errors.push(
                `For Instagram API, audio codec for the video needs to be max ${validations.audio_hertz_max}khz, ${media.audio_hertz}khz provided.`
              )
            }

            // can either be h264 or hecv
            if (
              media.video_codec &&
              !(
                media.video_codec.toLowerCase() ===
                  validations.video_codec_h264 ||
                media.video_codec.toLowerCase() === validations.video_codec_hecv
              )
            ) {
              errors.push(
                'For Instagram API, video codec needs to either be H264 or HECV, ' +
                  media.video_codec +
                  ' provided.'
              )
            }

            // should be between 23 to 60 fps
            if (
              media.frame_rate &&
              (media.frame_rate < validations.frame_rate_min ||
                media.frame_rate > validations.frame_rate_max)
            ) {
              errors.push(
                'For Instagram API, video framerate needs to be between 23fps and 60fps, ' +
                  media.frame_rate +
                  'fps provided.'
              )
            }
          }
        }
      }
      return errors
    },

    getFileTooltip (file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let tooltip = ''
      if (mediaDetails[file]) {
        if (mediaDetails[file].name) tooltip = mediaDetails[file].name + ' '
        tooltip +=
          "<span class='ml-1'><b>" +
          this.bytesToSize(mediaDetails[file].size) +
          '</b></span>'
      }
      return tooltip
    },

    getFileExtention (file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let extention = ''
      if (mediaDetails[file]) {
        extention = mediaDetails[file].mime_type.replace('video/', '.')
      }
      return extention
    },

    async onCustomFileChange (e) {
      const selectedFiles = e.target.files
      const media = []
      Array.prototype.forEach.call(selectedFiles, function (file) {
        media.push({
          file: file
        })
      })
      // selectedFiles.forEach(file => {
      //   media.push({
      //     file: file
      //   })
      // })
      this.isThumbnailUploading = true

      await this.uploadFilesHelper(
        {
          media: media
        },
        // eslint-disable-next-line camelcase
        (status, message, media_container) => {
          if (status) {
            switch (this.type) {
              case 'Common':
                this.SET_COMMON_SHARING_VIDEO_SUGGESTIONS([
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              case 'Facebook':
                this.SET_FACEBOOK_SHARING_VIDEO_SUGGESTIONS([
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              case 'Pinterest':
                this.SET_PINTEREST_SHARING_VIDEO_SUGGESTIONS([
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              case 'Tumblr':
                this.SET_TUMBLR_SHARING_VIDEO_SUGGESTIONS([
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              case 'Youtube':
                this.$store.commit(youtubeTypes.SET_SHARING_VIDEO_SUGGESTIONS, [
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              case 'Gmb':
                this.$store.commit(gmb.SET_SHARING_VIDEO_SUGGESTIONS, [
                  media_container[0].link,
                  ...this.getUnselectedThumbnails
                ])
                break
              default:
            }

            this.addSharingVideo(media_container[0].link)
          }
          this.isThumbnailUploading = false
        }
      )
    },

    /**
     * enable the video-title modal
     * @param video
     */
    triggerVideoTitleModal (video) {
      console.log('Method:: TriggerVideoTitleModal')
      console.log('Video ----> ', video)
      this.videoTitle = video.title
      this.videoTitleModal = true
    },

    /**
     * close modal method.
     * @param event
     */
    closeVideoTitleModal (event) {
      console.log('Method:: closeVideoTitleModal')
      this.videoTitleModal = false
    },
    onClickSaveVideoTitle (event) {
      this.details.video.title = this.videoTitle
      this.videoTitleModal = false
      this.videoTitle = ''
    },
    videoTitleTooltip (video) {
      if (video.title.length > 1) {
        return video.title
      }

      return 'Add Video Title'
    }
  },

  watch: {}
}
</script>
