import publishMainModule from './states/main'
import publishLoaderModule from './states/loader'
import timeModule from './states/time'
import tasksModule from './states/tasks'
import widgetsModule from './states/widgets'
import rewritingModule from './states/rewriting'
import commentsModule from './states/comments'
import labelsModule from './states/labels'
import platformsSocialModule from './states/platforms/social'
import blogModule from './states/blog'
import socialSharingModule from './states/social/sharing'
import commonSharingModule from './states/social/common'
import facebookSharingModule from './states/social/facebook'
import twitterSharingModule from './states/social/twitter'
import linkedinSharingModule from './states/social/linkedin'
import pinterestSharingModule from './states/social/pinterest'
import tumblrSharingModule from './states/social/tumblr'
import instagramSharingModule from './states/social/instagram'
import gmbSharingModule from './states/social/gmb'
import youtubeSharingModule from './states/social/youtube'

import approvalModule from './states/approval'
export const platformStore = {
  modules: {
    social: platformsSocialModule
  }
}

const socialSharingStore = {
  modules: {
    socialSharing: socialSharingModule,
    common: commonSharingModule,
    facebook: facebookSharingModule,
    twitter: twitterSharingModule,
    linkedin: linkedinSharingModule,
    pinterest: pinterestSharingModule,
    tumblr: tumblrSharingModule,
    instagram: instagramSharingModule,
    gmb: gmbSharingModule,
    youtube: youtubeSharingModule
  }
}

export const publishStore = {
  modules: {
    publishMain: publishMainModule,
    loader: publishLoaderModule,
    rewriting: rewritingModule,
    widgets: widgetsModule,
    blogSharing: blogModule,
    time: timeModule,
    labels: labelsModule,
    comments: commentsModule,
    tasks: tasksModule,
    platform: platformStore,
    socialSharing: socialSharingStore,
    approvalStage: approvalModule
    // caption: AiCaption
  }
}
