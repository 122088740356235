<template>
  <div class="analytics-report-wrapper">
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Linkedin'"
          :current="1"
          :total="total"
        ></report-header>
        <div class="analytics-section-header mb-2">
          <h2>Linkedin {{ metrics.profile }} Performance Summary</h2>
          <p
            >View your key profile performance metrics from the reporting
            period.</p
          >
        </div>
        <div class="row">
          <template
            v-for="(value, key) in metrics.profile === 'Page'
              ? metrics.overview.summary_values
              : metrics.overview.summary_values_profile"
          >
            <div class="col-6 col-sm-3" :key="key">
              <SummaryCard
                :title="value"
                :total="metrics.overview.summary.current[key]"
                :reach="Math.abs(getReach(key, metrics.overview.summary)) + '%'"
                :reachUp="getReachUp(key, metrics.overview.summary)"
              ></SummaryCard>
            </div>
          </template>
        </div>
        <div v-if="metrics.profile === 'Page'">
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Linkedin Audience Growth </h2>
              <p>See how your audience grew during the reporting period.</p>
            </div>
            <SplineBasicChart
              v-if="
                metrics.overview.audience.audience_growth.data.show_data > 0
              "
              :categories="metrics.overview.audience.audience_growth.buckets"
              :series="getAudienceGrowthSeries(metrics.overview.audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>

          <h2>Audience Growth Rollup</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview.audience
                .audience_growth_values"
            >
              <div
                class="col-6 col-sm-3 summary-card-wrapper"
                :class="{ 'mb-4': i < 4 }"
                :key="key"
              >
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    metrics.overview.audience.audience_growth_rollup.current[
                      key
                    ]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.audience.audience_growth_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.audience.audience_growth_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Linkedin'"
          :current="2"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Linkedin Engagement</h2>
              <p
                >See how your engagement grew on the posts during the reporting
                period.</p
              >
            </div>
            <SplineBasicChart
              v-if="metrics.overview.engagement.engagement.data.show_data > 0"
              :categories="metrics.overview.engagement.engagement.buckets"
              :series="getEngagementSeries(metrics.overview.engagement)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>

          <h2>Engagement Rollup</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview.engagement
                .engagement_values"
            >
              <div
                class="col-6 col-sm-3 summary-card-wrapper"
                :class="{ 'mb-4': i < 4 }"
                :key="key"
              >
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    metrics.overview.engagement.engagement_rollup.current[key]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.engagement.engagement_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.engagement.engagement_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Linkedin'"
          :current="3"
          :total="total"
        ></report-header>
        <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
          <div class="analytics-section-header">
            <h2>Linkedin Top Posts</h2>
            <p
              >Review your top posts published during the selected time period,
              based on the post’s lifetime performance.</p
            >
          </div>
          <template v-if="metrics.overview.top_posts.length <= 0">
            <div class="analytics-no-data-found">
              <img
                src="../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p
                >You do not have any posts published in the selected time
                period.</p
              >
            </div>
          </template>
          <div v-else class="row">
            <template v-for="i in 3">
              <div :key="i" class="col-4">
                <template v-if="metrics.overview.top_posts.length >= i">
                  <PostCard
                    :post="metrics.overview.top_posts[i - 1]"
                    platform_type="linkedin"
                  />
                </template>
                <template v-else>
                  <!--  No More Posts   -->
                  <div class="analytics-post-card">
                    <div class="analytics-post-card__header">
                      <div class="analytics-post-card__header-left">
                        <div class="analytics-post-card__header-img">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            height="2.8rem"
                            width="2.8rem"
                            radius="50%"
                          />
                        </div>
                        <div class="analytics-post-card__header-detail">
                          <div
                            class="d-flex"
                            style="flex-direction: column; margin-left: 10px"
                          >
                            <div class="mb-1"> No More Post </div>
                            <SkeletonBox
                              class="skeletonBox--noanimcation"
                              radius=".2rem"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="analytics-post-card__header-right"
                        style="filter: grayscale(100%); opacity: 0.6"
                      >
                        <!-- Dynamic Icon bases on props -->
                        <i class="far fa-external-link-square-alt"></i>
                      </div>
                    </div>

                    <div class="analytics-post-card__content">
                      <div class="mb-1">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          width="100%"
                          radius=".2rem"
                        />
                      </div>
                      <div class="mb-2">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          radius=".2rem"
                        />
                      </div>
                      <div class="mb-2">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          width="100%"
                          height="260px"
                          radius=".2rem"
                        />
                      </div>
                    </div>

                    <ul class="analytics-post-card__content-ele">
                      <li v-for="i in 6" :key="i">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          width="40%"
                          radius=".2rem"
                        />
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          width="1.1rem"
                          radius=".2rem"
                        />
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </section>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Linkedin'"
          :current="4"
          :total="total"
        ></report-header>
        <div>
          <h2>Post Density - Daily</h2>
          <ColumnChart
            v-if="metrics.overview.posts_per_days.data.show_data"
            :series="getPostsDensitySeries(metrics.overview.posts_per_days)"
            :categories="getDaysBuckets(metrics.overview.posts_per_days)"
          ></ColumnChart>
          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
          <h2>Top Hashtags</h2>
          <WordCloud
            v-if="metrics.overview.top_hashtags.length"
            :series="getHashtagsSeries(metrics.overview.top_hashtags)"
          ></WordCloud>
          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import linkedinMixin from '@src/modules/analytics/components/common/series/linkedinMixin'
import PostCard from '@src/modules/analytics/views/common/PostCard'
import ColumnChart from '@src/modules/analytics/views/common/Infographics/ColumnChart'
import WordCloud from '@src/modules/analytics/views/common/Infographics/WordCloud'

export default {
  mixins: [linkedinMixin, analyticsUtilsMixin],
  data () {
    return {
      total: 4,
      legends: []
    }
  },
  props: {
    metrics: {}
  },
  components: {
    ReportHeader,
    AnalyticsSummaryBlock,
    SummaryCard,
    PostCard,
    SkeletonBox,
    SplineBasicChart,
    LineBasicChart,
    ColumnChart,
    WordCloud
  },
  computed: {},
  methods: {
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    }
  }
}
</script>
