<template>
  <div class="billing-upgrade-page">
    <template v-if="is_loading_direct_upgrade">
      <beat-loader :color="'#6173ff'"></beat-loader>
    </template>
    <template v-else>
      <div class="billing-close-button">
        <b-button
          variant="studio-theme-transparent-grey"
          class="mr-4 mt-3"
          @click="$router.push({ name: 'workspaces' })"
        >
          Go to workspaces
        </b-button>
      </div>
      <div class="billing-main-heading">
        <h2>Hey {{ $store.getters.getProfile.full_name }}!</h2>
        <p>Upgrade your subscription plan to get more features.</p>
      </div>
      <div class="billing-plans-section">
        <upgrade-plan-component />
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UpgradePlanComponent from '@src/modules/setting/components/billing/dialogs/UpgradePlanComponent'

export default {
  name: 'DirectUpgradePlan.vue',
  methods: {},
  data () {
    return {
      is_loading_direct_upgrade: true
    }
  },
  components: {
    UpgradePlanComponent
  },
  created () {},
  computed: {},
  async mounted () {
    await this.fetchWorkspaces()
    await this.Plan()
    this.is_loading_direct_upgrade = false
  }
}
</script>
