export const twitterLanguages = [
  {
    code: 'all',
    languageName: 'All Languages'
  },
  {
    code: 'en',
    languageName: 'English'
  },
  {
    code: 'fr',
    languageName: 'Français'
  },
  {
    code: 'nl',
    languageName: 'Netherlands'
  },
  {
    code: 'de',
    languageName: 'Deutsch'
  },
  {
    code: 'es',
    languageName: 'Español'
  },
  {
    code: 'tr',
    languageName: 'Turkish'
  },
  {
    code: 'pl',
    languageName: 'Polski'
  },
  {
    code: 'it',
    languageName: 'Italiano'
  },
  {
    code: 'pt',
    languageName: 'Português'
  },
  {
    code: 'sv',
    languageName: 'Swedish'
  },
  {
    code: 'sq',
    languageName: 'Albanian'
  },
  {
    code: 'da',
    languageName: 'Danish'
  },
  {
    code: 'sr',
    languageName: 'Serbian'
  },
  {
    code: 'hu',
    languageName: 'Hungarian'
  },
  {
    code: 'ru',
    languageName: 'Russian'
  },
  {
    code: 'id',
    languageName: 'Indonesian'
  },
  {
    code: 'th',
    languageName: 'Thai'
  },
  {
    code: 'hi',
    languageName: 'Hindi'
  },
  {
    code: 'he',
    languageName: 'Hebrew'
  },

  {
    code: 'ar',
    languageName: 'Arabic'
  },

  {
    code: 'sl',
    languageName: 'Slovenian'
  },

  {
    code: 'ms',
    languageName: 'Malay'
  },
  {
    code: 'vi',
    languageName: 'Vietnamese'
  },
  {
    code: 'zh',
    languageName: 'Chinese'
  }
]
