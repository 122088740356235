<template>
  <div class="modal fade add_utm_modal  normal_modal" id="createFolder">
    <div class="modal-dialog w-1/3">
      <div class="modal-content">
        <div class="modal_head">
          <h2 v-if="getPublicationFolderEdit.editId">Update Campaign Folder</h2>
          <h2 v-else>Create Campaign Folder</h2>
          <button
            type="button"
            class="modal_head__close"
            id="close"
            @click.prevent="hide()"
            data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div class="field_group">
            <input
              class="{'input-field-error' : validations.name}"
              type="text"
              id="folder_name"
              v-model="publicationNewFolder.name"
              placeholder="Campaign Folder name e.g marketing posts"
              @keyup.enter="CallCreatePublicationFolder()"
              data-cy="campaign-name"
            />
            <label for="folder_name" class="label-animated"
              >Folder Name</label
            >
            <span class="input-error" v-if="validations.name">
              {{ messages.name }}
            </span>
          </div>

          <div class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getPublicationCreateFolderLoader"
              :class="{ disabled: getPublicationCreateFolderLoader }"
              @click.prevent="CallCreatePublicationFolder()"
              data-cy="save-campaign"
            >
              <span v-if="getPublicationFolderEdit.editId">Update</span>
              <span v-else>Create</span>
              <clip-loader
                :color="'#ffffff'"
                :size="'12px'"
                v-if="getPublicationCreateFolderLoader"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED } from '@common/constants/messages'
import {composer} from "@src/modules/composer/store/composer-mutation-type";
import {EventBus} from "@common/lib/event-bus";

export default {
  computed: {
    ...mapGetters([
      'getPublicationCreateFolderLoader',
      'getPublicationNewFolder',
      'getPublicationFolderEdit'
    ])
  },

  data () {
    return {
      publicationNewFolder: {
        name: ''
      },
      validations: {
        name: false
      },
      messages: {
        name: COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED
      }
    }
  },
  mounted () {
    EventBus.$on('createFolder', () => {
      this.publicationNewFolder.name =
        this.getPublicationNewFolder.name.trim() === ''
          ? ''
          : this.getPublicationNewFolder.name
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID,'')
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, '')
      $('#createFolder').modal('show')
    })

    EventBus.$on('setEditFolderName', () => {
      this.publicationNewFolder.name = this.getPublicationFolderEdit.name
    })

  },
  methods: {
    CallCreatePublicationFolder () {
      console.debug('debug', this.publicationNewFolder.name)
      if (this.publicationNewFolder.name) {
        this.getPublicationNewFolder.name = this.publicationNewFolder.name
        this.createPublicationFolder(this.getPublicationFolderEdit.editId)
        this.publicationNewFolder.name = ''
        // this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID,'')
        // this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, '')
      } else {
        this.validations.name = true
      }
    },
    hide () {
      this.validations.name = false
      $('#createFolder').modal('hide')
      // this.$root.$off('createFolder')
    }
  },
  watch: {
    'publicationNewFolder.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
      }
    }
  }
}
</script>
