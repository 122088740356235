<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash.orderby'
import Router from '@src/router'
import proxy, { getProxyCancelToken } from '@common/lib/http-common'
import {
  createPlannerFilterURL,
  deleteCommentsUrl,
  deletePlannerFiltersURL,
  fetchPlannerFiltersURL,
  fetchPlansCountURL,
  fetchPlansURL,
  saveCommentsUrl,
  setDefaultPlannerFiltersURL,
} from '@src/modules/planner/config/api-utils'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'

export default {
  data() {
    return {
      blogIntegrations: blogIntegrationsNames,
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getActiveWorkspace',
      'getLabels',
      'getActiveWorkspaceMembersName',
      'getActiveWorkspaceMembersIds',
      'getPlatformsList',
    ]),
    membersList() {
      const members = []
      if (
        this.getWorkspaces &&
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.members
      ) {
        console.log('memberSearch', this.getWorkspaces.activeWorkspace)
        this.getWorkspaces.activeWorkspace.members.forEach(
          (workspaceMember, index) => {
            if (workspaceMember.status === 'joined' && workspaceMember.user) {
              members.push(workspaceMember)
            }
          }
        )
      }

      return members
    },
    /**
     * @Deprecated
     */
    /* connectedSocialAccountsList() {
      const blogAccount = this.blogIntegrations
        .map((integration) => {
          const blogAccountDetails =
            this.$store.getters.getBlogs[integration].items
          if (!isEmpty(blogAccountDetails)) {
            console.log(blogAccountDetails)
            return this.$store.getters.getBlogs[integration].items.map(
              (blog) => {
                return {
                  ...blog,
                  integration: integration,
                  account_type: 'blog',
                }
              }
            )
          }
          return []
        })
        .flat()

      const socialAccounts = socialChannelsArray
        .map((channel) => {
          var channelItem = this.$store.getters[channel.getter]
          if (typeof channelItem === 'object' && !Array.isArray(channelItem)) {
            if ('items' in channelItem) {
              return channelItem.items.map((item) => {
                item.channel_type = channel.name // is not reactive
                item.channel_identifier = channel.key
                return item
              })
            }
          } else if (typeof channelItem && Array.isArray(channelItem)) {
            return channelItem.map((innerChannel) => {
              innerChannel.channel_type = channel.name // is not reactive
              innerChannel.channel_identifier = channel.key
              return innerChannel
            })
          }
        })
        .flat()
      console.log('connectedSocialAccountsList', blogAccount)
      console.log('connectedSocialAccountsList', socialAccounts)

      return [...blogAccount, ...socialAccounts]
    }, */
    connectedSocialAccountsList() {
      return this.getPlatformsList
    },
    orderedLabels() {
      return this.getLabels.length > 0
        ? orderBy(this.getLabels, function (item) {
            return [item.name.toLowerCase()]
          })
        : []
    },
    /**
     * Fetches the default campaign folder name that the user has either set.
     * @returns {string|*}
     */
    getDefaultCampaignName() {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.default_campaign_name
      ) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign Folder'
    },
    /**
     * checks if the current user profile is a client profile in this active workspace
     * @returns {boolean}
     */
    clientProfile() {
      const userProfile = this.getWorkspaces?.activeWorkspace?.members?.find(
        (item) => item.user_id === this.getProfile._id
      )
      return userProfile?.membership === 'client'
    },
  },
  methods: {
    onError(event, imageUrl) {
      console.log('onError', event, imageUrl)
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`,
      ]

      if (imageUrl) {
        fallbacks.unshift(this.getResizedImageURL(imageUrl, 1080, 0))
      }

      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },

    async fetchPlansCall(payload, loaderCallback = () => {}) {
      const currentRoute =
        Router.history.current.name === 'planner_list_v2'
          ? 'list_plans'
          : Router.history.current.name === 'planner_feed_v2'
          ? 'feed_view'
          : Router.history.current.name === 'planner_calendar_v2'
          ? 'calender_plans'
          : Router.history.current.name

      payload.route_name = currentRoute
      loaderCallback(true)
      const res = await proxy.post(
        fetchPlansURL,
        payload,
        getProxyCancelToken(proxy)
      )

      loaderCallback(false)

      // .then((response) => {
      //   loaderCallback(true)
      //
      //   if (response.data.status) {
      //     if (response.data.plans_count) {
      //       commit(planner.SET_COUNT, response.data.plans_count)
      //     }
      //     let plans = []
      //     if (
      //         options.name === 'calender_plans' ||
      //         options.name === 'calendar_test'
      //     ) {
      //       console.log('calendar name...')
      //       // calling planner calandar
      //       plans = response.data.plans
      //       const workspaceTimezone =
      //           getters.getWorkspaces.activeWorkspace.timezone
      //       plans.map((planItem) => {
      //         planItem.start = getWorkspaceTimeZoneTime(
      //             planItem.execution_time.date,
      //             workspaceTimezone,
      //             'YYYY-MM-DD'
      //         )
      //         if (planItem.post_status === 'published') {
      //           planItem.editable = false
      //         }
      //       })
      //       console.log(plans)
      //       commit(planner.SET_ITEMS, plans)
      //       return false
      //     }
      //
      //     plans = response.data.plans.data
      //         ? response.data.plans.data
      //         : response.data.plans
      //     commit(
      //         planner.SET_TOTAL_ITEMS,
      //         response.data.plans.data ? response.data.plans.total : 1
      //     )
      //
      //     if (getters.getPlans.page === 1) {
      //       commit(planner.SET_SELECTED_PLANS, [])
      //     }
      //
      //     if (plans.length && Router.history.current.query.plan_id) {
      //       plans[0].is_active = true
      //       Router.push({name: 'list_plans'})
      //     }
      //
      //     if (Router.history.current.query.status === 'missed_review') {
      //       Router.push({name: 'list_plans'})
      //     }
      //
      //     if (getters.getPlans.check_all) {
      //       plans.forEach((plan) => {
      //         getters.getPlans.selected_plans.push(plan._id)
      //       })
      //     }
      //     commit(
      //         planner.SET_ITEMS,
      //         getters.getPlans.page > 1
      //             ? getters.getPlans.items.concat(plans)
      //             : plans
      //     )
      //
      //     try {
      //       if (response.data.total_plans === 20) {
      //         commit(planner.SET_PAGINATION_SCROLL, true)
      //         if (options.infinite_loading) options.infinite_loading.loaded()
      //         return false
      //       }
      //       commit(planner.SET_PAGINATION_SCROLL, false)
      //       if (options.infinite_loading) options.infinite_loading.complete()
      //     } catch (trace) {
      //       console.log('Exception in fetchPlans pagination', trace)
      //     }
      //     loaderCallback(false)
      //   }
      //   // commit(planner.SET_FETCH_PLANS_LOADER, false)
      // })
      // .catch((response) => {
      //   console.debug('Exception in fetchPlans', response)
      // })

      return res
    },

    // Planner save Filters

    async createPlannerFilter(payload) {
      console.debug('Action:createPlannerFilter', payload)

      return proxy
        .post(createPlannerFilterURL, payload)
        .then((response) => {
          if (response.data.status) {
            this.alertMessage('Filter successfully saved.', 'success')

            return response.data.filter
          }
          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in createPlannerFilter', response)

          return false
        })
    },

    async fetchPlannerFilters() {
      console.debug('Action:fetchPlannerFilters')

      return proxy
        .post(fetchPlannerFiltersURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status === true) {
            return response.data.filters
          }

          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in fetchPlannerFilters', response)
          return false
        })
    },

    async removePlannerFilter(payload) {
      console.debug('Action:removePlannerFilter')

      return proxy
        .post(deletePlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            this.alertMessage('Filter successfully removed.', 'success')

            return true
          }
          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    async setDefaultPlannerFilter(payload) {
      console.debug('Action:setDefaultPlannerFilter')

      return proxy
        .post(setDefaultPlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response.data.filters
          }

          this.alertMessage(response.data.message, 'error')

          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    /**
     * http request for adding a comment.
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    async addComment(payload) {
      return await proxy.post(saveCommentsUrl, payload).then((response) => {
        if (response.data.status) {
          return response.data.comment
        }
      })
    },

    /**
     * http request for deleting a comment.
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    async deleteComment(payload) {
      console.log('METHOD::deleteComment')
      return proxy.post(deleteCommentsUrl, payload).then((response) => {
        console.log('deleting comment ~ response -> ', response)
      })
    },

    /**
     * Uses Regex to pluck out the mentioned users in an array
     * @param comment
     * @returns {*[]}
     */
    mentionedUserIdsList(comment) {
      var regex = /@[A-Za-z0-9]*/g
      // var regex = /@[a-zA-Z0-9_.]+?(?![a-zA-Z0-9_.])/g // lookaround technique http://www.rexegg.com/regex-lookarounds.html
      let mentionedUsersIds = []
      const mentionedUsers = comment.match(regex)
      if (mentionedUsers) {
        // filter the name and after that get the value by index.
        mentionedUsers.filter((name, index) => {
          const mentionedId = this.getActiveWorkspaceMembersName.indexOf(
            name.substr(1)
          )
          mentionedUsersIds.push(this.getActiveWorkspaceMembersIds[mentionedId])
        })
        // push the keys to the array
        mentionedUsersIds = Array.from(new Set(mentionedUsersIds))
      }
      return mentionedUsersIds
    },

    async fetchPlansCount(payload) {
      try{
        const response = await proxy.post(
            fetchPlansCountURL,
            payload,
            getProxyCancelToken(proxy)
        )
        return response.data.status ? response.data.plans_count : false
      }catch (exception) {
        console.log("FetchPlanCounts_Exception: ", exception)
      }
    },
  },
}
</script>
