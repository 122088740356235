<template>
  <div>
    <chart-render
      :id="'audience_highlight'"
      :options="audience()"
      :ref_value="'audience_chart'"
      :is_content_available="!isAudienceAvailable"
    ></chart-render>
  </div>
</template>

<script>
import ChartRender from '../../common/ChartRender'
import moment from 'moment'
import {
  lineChart,
  lineChartTooltip,
  lineLegend,
  linePlotOptions,
  splineCredits,
  splineXAxis,
  splineYAxis
} from '../../common/helper'
import { INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH } from '@common/constants/messages'

export default {
  props: {
    audience_growth: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH
    }
  },
  computed: {
    isAudienceAvailable () {
      return true
    }
  },
  components: {
    ChartRender
  },
  methods: {
    audience () {
      const lineChartTooltipModified = lineChartTooltip()
      // lineChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.y:f}</span></p>'
      const audienceList = []
      let followerCount
      this.audience_growth.buckets.forEach((item) => {
        if (item.doc_count > 0) {
          audienceList.push([
            Date.parse(item.key_as_string.split(' ')[0]),
            item.followers_count.value
          ])
          followerCount = item.followers_count.value
        } else {
          if (followerCount) {
            audienceList.push([
              Date.parse(item.key_as_string.split(' ')[0]),
              followerCount
            ])
          }
        }
      })
      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        // tooltip: lineChartTooltipModified,
        tooltip: {
          headerFormat: '<div class="chart_tooltip engagement_tooltip">',
          pointFormat:
            '<div class="engagement_point"><h3 class="date">{series.name}</h3><span>{point.y:f}</span></div>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 20,
            color: 'rgba(58, 69, 87, 0.15)'
          }
        },
        chart: lineChart('#audience_highlight'),
        legend: lineLegend(),
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: splineYAxis(),

        //  plotOptions: linePlotOptions(),

        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            lineColor: '#2fe095',
            marker: {
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: '#2fe095'
            },
            connectNulls: true
          }
        },
        series: [
          {
            name: 'Followers',
            data: audienceList,
            color: '#2fe095',
            lineColor: '#2fe095'
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
