<script>
import { mapGetters, mapActions } from 'vuex'
import { getUsersByEmailURL } from '../../../config/api-utils'
import integrations, { getPlatformName } from '@common/lib/integrations'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  components: {
    VueTypeaheadBootstrap
  },

  data () {
    return {
      integrations: integrations,
      gmbView: 'off',
      // selectAll: false
      query: '',
      selecteduser: null
    }
  },
  computed: {
    ...mapGetters([
      'getTeam',
      'getSettingLoaders',
      'getTumblrAccounts',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getInstagramAccounts',
      'getTiktokAccounts',
      'getFolders',
      'getTopics',
      'getTeamSellectAllOptions',
      'getGmbAccounts',
      'getPinterestProfiles',
      'getTeamAccountSelectAll',
      'getUserEmails'
    ]),
    getRoleText () {
      if (this.getTeam.role === 'admin') return 'Administrator'
      if (this.getTeam.role === 'collaborator') return 'Collaborator'
      if (this.getTeam.role === 'approver') return 'Approver'
      return ''
    },
    featureFlagYoutube () {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
    getMembershipText () {
      if (this.getTeam.membership === 'team') return 'Team'
      if (this.getTeam.membership === 'client') return 'Client'
      return ''
    }
  },

  watch: {
    'getTeamSellectAllOptions.wordpress' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.twitter' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.facebook' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.pinterest' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.linkedin' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.tumblr' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.instagram' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.gmb' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.medium' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.shopify' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.youtube' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.tiktok' () {
      this.processAccountSelectAll()
    }
  },

  created () {
    console.debug('Add team Member Initialize')
    this.initializeTeamSection()

    // Instantiate the SDK. CDN will expose splitio globally
    /* let factory = splitio({
        core: {
          authorizationKey: 'anm6pje0e442dmo8nen3roabl0sr1dpijkdb',
          // the customer id can be the logged in
          // user id, or the account id that
          // the logged in user belongs to.
          // The type of customer (user, account, custom)
          // is chosen during Split's sign-up process.
          // key: this.getProfile.email
          // an OPTIONAL traffic type, if provided will be
          // used for event tracking with the SDK client.
        }
      })

      // And get the client instance you'll use
      let client = factory.client()
      let attributes = {
        'first_name': this.getProfile.firstname,
        'last_name': this.getProfile.lastname,
        '_id': this.getProfile.id
      }
      let self = this
      client.on(client.Event.SDK_READY, function () {
        var treatment = client.getTreatment('CS_Gmb', attributes)

        if (treatment == 'on') {
          self.gmbView = 'on'
          console.log('treatment on')
          // insert code here to show on treatment
        } else if (treatment == 'off') {
          console.log('treatment off')
          // insert code here to show off treatment
        } else {
          console.log('treatment else')
          // insert your control treatment code here
        }
      }) */
  },
  mounted () {},
  methods: {
    ...mapActions(['setTeamAccountSelectAll']),

    getBlogItems (type) {
      return this.$store.getters.getBlogs[type].items
    },
    /**
     * this method will check specific type existance in the list.
     */
    checkTypeExist (accounts, type) {
      let exist = false
      accounts.forEach(function (element) {
        if (element.type === type) {
          exist = true
        }
      })

      return exist
    },

    platformText (platform) {
      return getPlatformName(platform)
    },
    accountIsPinterestAndIsManuallyConnected (account) {
      return (
        account &&
        (account.pinterest_id || account.board_id) &&
        account.manually_added
      )
    }
  }
}
</script>
<template>
  <!--<div class="modal fade normal_modal addTeamMember" id="addTeamMember">-->
  <!--</div>-->

  <b-modal
    modal-class="addTeamMember"
    id="addTeamMember"
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="modal_head">
      <h4 v-if="getTeam.id">Update Team Member</h4>
      <h4 v-else>Add Team Member</h4>
      <button
        type="button"
        class="modal_head__close"
        @click="$bvModal.hide('addTeamMember')"
        data-dismiss="modal"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <div class="row">
        <!-- <div class="col-md-6 col-sm-12">
                        <div class="field_group">
                            <input type="text" placeholder="Enter first name here..." v-model="getTeam.first_name"
                                   :readonly="getTeam.id" id="memberfirstName" data-cy='first-name'>
                            <label class="label-animated" for="memberfirstName">First Name</label>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="field_group">
                            <input type="text" placeholder="Enter last name here..." v-model="getTeam.last_name"
                                   :readonly="getTeam.id" id="memberlastname" data-cy='last-name'>
                            <label class="label-animated" for="memberlastname">Last Name</label>
                        </div>
                    </div> -->
        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <VueTypeaheadBootstrap
              v-model="getTeam.email"
              inputClass="autoComplete"
              size="lg"
              :ieCloseFix="false"
              :data="this.getUserEmails"
              :serializer="(item) => item.email"
              @hit="selecteduser = $event"
              :disabledValues="selecteduser ? [selecteduser.email] : []"
              placeholder="Enter email address here.."
              @input="getUsersByEmails()"
              :background-variant-resolver="
                (user) => (user.id % 2 == 0 ? 'light' : 'dark')
              "
            />
            <label class="label-animated" for="memberEmail"
              >Email Address</label
            >
          </div>
        </div>

        <!--<div class="col-md-6 col-sm-12">
                        <div class="field_group">
                            <input type="text" placeholder="Enter email address here.." v-model="getTeam.email"
                                   :readonly="getTeam.id" id="memberEmail" data-cy='email-address'>
                            <label class="label-animated" for="memberEmail">Email Address</label>
                        </div>
                    </div>-->
        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <!--<label for="">User Role</label>-->

            <div class="dropdown dropdown_right">
              <div
                data-boundary="window"
                class="dropdown_header"
                data-toggle="dropdown"
                data-cy="select-permission"
              >
                <span class="text">{{ getRoleText }}</span>
                <span class="ml-auto">
                  <i class="dropdown_arrow icon_last cs-angle-down"></i>
                </span>
              </div>
              <div
                class="dropdown-menu dropdown-menu-right extended-team-dropdown-menu"
              >
                <ul class="inner">
                  <li
                    class="list_item_li"
                    @click="getTeam.role = 'admin'"
                    data-cy="select-admin"
                  >
                    <i v-if="getTeam.role === 'admin'" class="far fa-check"></i>
                    <p>Administrator</p>
                    <p class="role_description"
                      >Has all the privileges except they cannot view/manage
                      billing and plan details.</p
                    >
                  </li>
                  <li
                    class="list_item_li"
                    @click="getTeam.role = 'collaborator'"
                    data-cy="select-collabrator"
                  >
                    <i
                      v-if="getTeam.role === 'collaborator'"
                      class="far fa-check"
                    ></i>
                    <p>Collaborator</p>
                    <p class="role_description"
                      >Can create and edit posts, can comment on posts, other
                      permissions can be set according to the needs.</p
                    >
                  </li>
                  <li
                    class="list_item_li"
                    @click="getTeam.role = 'approver'"
                    data-cy="select-approver"
                  >
                    <i
                      v-if="getTeam.role === 'approver'"
                      class="far fa-check"
                    ></i>
                    <p>Approver</p>
                    <p class="role_description"
                      >Can review posts and comment on them, has access to the
                      planner/calendar only.</p
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <!--<label for="">User Type</label>-->
            <div class="dropdown dropdown_right">
              <div
                data-boundary="window"
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
                ><span class="text">{{ getMembershipText }}</span>
                <span class="ml-auto"
                  ><i class="dropdown_arrow icon_last cs-angle-down"></i
                ></span>
              </div>

              <div
                class="dropdown-menu dropdown-menu-right extended-team-dropdown-menu"
              >
                <ul class="inner">
                  <li class="list_item_li" @click="getTeam.membership = 'team'">
                    <i
                      v-if="getTeam.membership === 'team'"
                      class="far fa-check"
                    ></i>
                    <p>Team</p>
                    <p class="role_description"
                      >Can view internal/private posts and comments.</p
                    >
                  </li>
                  <li
                    class="list_item_li"
                    @click="getTeam.membership = 'client'"
                  >
                    <i
                      v-if="getTeam.membership === 'client'"
                      class="far fa-check"
                    ></i>
                    <p>Client</p>
                    <p class="role_description"
                      >Cannot view internal/private posts and comments.</p
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <template v-if="getTeam.role !== 'admin'">
        <div  class="clear permission_block">
          <h2 class="heading">Permissions</h2>
          <!-- Collaborator Permissions-->
          <div v-if="getTeam.role === 'collaborator'" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="addBlog">
                    Can add blogs
                    <input
                      id="addBlog"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.addBlog"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="addSource">
                    Can add sources
                    <input
                      id="addSource"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.addSource"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="viewTeam">
                    Can view team
                    <input
                      id="viewTeam"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.viewTeam"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="rescheduleQueue">
                    Can reschedule queue
                    <input
                      id="rescheduleQueue"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.rescheduleQueue"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="addSocial">
                    Can add social accounts
                    <input
                      id="addSocial"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.addSocial"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label id="addTopic">
                    Can add topics
                    <input
                      id="addTopic"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.addTopic"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="postsReview">
                    Can approve and publish posts
                    <input
                      id="postsReview"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.postsReview"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="fbPublishAs">
                    Can change Facebook group publishing method
                    <input
                      id="fbPublishAs"
                      type="checkbox"
                      name=""
                      v-model="getTeam.permissions.changeFBGroupPublishAs"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Approver Permissions-->
          <div v-if="getTeam.role === 'approver'" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="editPost">
                    Can edit posts
                    <input
                        id="editPost"
                        type="checkbox"
                        name=""
                        v-model="getTeam.permissions.approverCanEditPost"
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="permissions_option_panel">
          <div class="panel-group" id="accordion" data-collapse-type="manual">
            <div
              class="permission_block d-flex justify_space_between align-items-center"
            >
              <h2 class="heading">Select accounts</h2>
              <div class="field_group my-0">
                <div class="checkbox_container">
                  <label for="select-all-account">
                    Select All
                    <input
                      id="select-all-account"
                      v-model="getTeamAccountSelectAll.status"
                      @change="
                        toggleMemberPermission(getTeamAccountSelectAll.status)
                      "
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>
            <!--..........wordpress............-->

            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_wordpress"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block wordpress_social_icon wordpress_icon_color"
                      >
                        <i class="cs-wordpress wp"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">WordPress</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.wordpress.length }}/{{
                            getBlogItems('wordpress').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getBlogItems('wordpress').length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="wordpress_select_all">
                          <input
                            id="wordpress_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.wordpress"
                            @change="
                              memberPermissionsChanges('wordpress', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_wordpress"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('wordpress').length">
                        <div
                          class="option_row d-flex"
                          v-for="blog in getBlogItems('wordpress')"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    type="checkbox"
                                    v-model="getTeam.permissions.wordpress"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'wordpress',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No WordPress blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Twitter............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_twitter"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block twitter_social_icon twitter_icon_color"
                      >
                        <i class="cs-twitter tw"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Twitter</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.twitter.length }}/{{
                            getTwitterAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getTwitterAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="twitter_select_all">
                          <input
                            id="twitter_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.twitter"
                            @change="memberPermissionsChanges('twitter', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_twitter"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTwitterAccounts.items.length">
                        <div
                          class="option_row d-flex"
                          v-for="account in getTwitterAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img
                                  v-if="twitterImage(account)"
                                  :src="twitterImage(account)"
                                  alt=""
                                />
                                <img
                                  v-else
                                  src="../../../../../assets/img/profile_default.svg"
                                  alt=""
                                />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.screen_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.twitter_id">
                                  <input
                                    :id="account.twitter_id"
                                    type="checkbox"
                                    v-model="getTeam.permissions.twitter"
                                    :value="account.twitter_id"
                                    @change="
                                      memberPermissionsChanges(
                                        'twitter',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Twitter profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Facebook............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_facebook"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block facebook_social_icon facebook_icon_color"
                      >
                        <i class="cs-facebook fb"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Facebook</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.facebook.length }}/{{
                            getFacebookAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getFacebookAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="facebook_select_all">
                          <input
                            id="facebook_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.facebook"
                            @change="
                              memberPermissionsChanges('facebook', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_facebook"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Pages</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="checkTypeExist(getFacebookAccounts.items, 'Page')"
                      >
                        <template v-for="account in getFacebookAccounts.items">
                          <template v-if="account.type === 'Page'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="channelImage(account, 'facebook')"
                                      :src="channelImage(account, 'facebook')"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.facebook_id">
                                      <input
                                        :id="account.facebook_id"
                                        type="checkbox"
                                        v-model="getTeam.permissions.facebook"
                                        :value="account.facebook_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'facebook',
                                            'Single'
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Facebook pages found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Groups</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="
                          checkTypeExist(getFacebookAccounts.items, 'Group')
                        "
                      >
                        <template v-for="account in getFacebookAccounts.items">
                          <template v-if="account.type === 'Group'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="channelImage(account, 'facebook')"
                                      :src="channelImage(account, 'facebook')"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.facebook_id">
                                      <input
                                        :id="account.facebook_id"
                                        type="checkbox"
                                        v-model="getTeam.permissions.facebook"
                                        :value="account.facebook_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'facebook',
                                            'Single'
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Facebook groups found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Pinterest............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_pinterest"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block pinterest_social_icon pinterest_icon_color"
                      >
                        <i class="cs-pinterest pin"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Pinterest</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.pinterest.length }}/{{
                            getPinterestProfiles.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getPinterestAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="pinterest_select_all">
                          <input
                            id="pinterest_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.pinterest"
                            @change="
                              memberPermissionsChanges('pinterest', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_pinterest"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getPinterestAccounts.items.length">
                        <template v-for="account in getPinterestAccounts.items">
                          <template v-if="account.type === 'Profile'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="pinterestImage(account)"
                                      :src="pinterestImage(account)"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p
                                      v-if="
                                        !accountIsPinterestAndIsManuallyConnected(
                                          account
                                        )
                                      "
                                      class="text"
                                      >{{ account.username }}
                                    </p>
                                    <p class="text"
                                      >{{ account.first_name }}
                                      {{ account.last_name }}</p
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.pinterest_id">
                                      <input
                                        :id="account.pinterest_id"
                                        type="checkbox"
                                        v-model="getTeam.permissions.pinterest"
                                        :value="account.pinterest_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'pinterest',
                                            'Single'
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Pinterest Profile found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........LinkedIn............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_linkedin"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block linkedin_social_icon linkedin_icon_color"
                      >
                        <i class="cs-linkedin lin"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">LinkedIn</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.linkedin.length }}/{{
                            getLinkedinAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getLinkedinAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="linkedin_select_all">
                          <input
                            id="linkedin_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.linkedin"
                            @change="
                              memberPermissionsChanges('linkedin', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_linkedin"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="
                          checkTypeExist(getLinkedinAccounts.items, 'Profile')
                        "
                      >
                        <template v-for="account in getLinkedinAccounts.items">
                          <template v-if="account.type === 'Profile'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="linkedinImage(account)"
                                      :src="linkedinImage(account)"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.linkedin_id">
                                      <input
                                        :id="account.linkedin_id"
                                        type="checkbox"
                                        v-model="getTeam.permissions.linkedin"
                                        :value="account.linkedin_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'linkedin',
                                            'Single'
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No LinkedIn profile found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Pages</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="checkTypeExist(getLinkedinAccounts.items, 'Page')"
                      >
                        <template v-for="account in getLinkedinAccounts.items">
                          <template v-if="account.type === 'Page'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.linkedin_id">
                                      <input
                                        :id="account.linkedin_id"
                                        type="checkbox"
                                        v-model="getTeam.permissions.linkedin"
                                        :value="account.linkedin_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'linkedin',
                                            'Single'
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No LinkedIn page found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Tumblr............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_tumblr"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block tumblr_social_icon tumblr_icon_color"
                      >
                        <i class="cs-tumblr tum"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Tumblr</p>
                        <p class="sub_text"
                          >Selected:
                          {{
                            getTeam.permissions.tumblr_blogs.length +
                            getTeam.permissions.tumblr_profiles.length
                          }}/{{
                            getBlogItems('tumblr').length +
                            getTumblrAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="
                      getTumblrAccounts.items.length ||
                      getBlogItems('tumblr').length
                    "
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="tumblr_select_all">
                          <input
                            id="tumblr_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.tumblr"
                            @change="memberPermissionsChanges('tumblr', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="team_tumblr" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTumblrAccounts.items.length">
                        <div
                          class="option_row d-flex"
                          v-for="account in getTumblrAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text"> {{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label
                                  :for="'profile' + account.platform_identifier"
                                >
                                  <input
                                    :id="
                                      'profile' + account.platform_identifier
                                    "
                                    type="checkbox"
                                    v-model="
                                      getTeam.permissions.tumblr_profiles
                                    "
                                    :value="account.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'tumblr',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tumblr profile found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('tumblr').length">
                        <div
                          class="option_row d-flex"
                          v-for="blog in getBlogItems('tumblr')"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="'blog' + blog.platform_identifier">
                                  <input
                                    :id="'blog' + blog.platform_identifier"
                                    type="checkbox"
                                    v-model="getTeam.permissions.tumblr_blogs"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'tumblr',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tumblr blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Instagram............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_instagram"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block instagram_social_icon instagram_icon_color"
                      >
                        <i class="cs-instagram ins"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Instagram</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.instagram.length }}/{{
                            getInstagramAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getInstagramAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="instagram_select_all">
                          <input
                            id="instagram_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.instagram"
                            @change="
                              memberPermissionsChanges('instagram', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_instagram"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getInstagramAccounts.items.length">
                        <div
                          class="option_row d-flex"
                          v-for="account in getInstagramAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img
                                  v-if="instagramImage(account)"
                                  :src="instagramImage(account)"
                                  alt=""
                                />
                                <img
                                  v-else
                                  src="../../../../../assets/img/profile_default.svg"
                                  alt=""
                                />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.instagram_id">
                                  <input
                                    :id="account.instagram_id"
                                    type="checkbox"
                                    v-model="getTeam.permissions.instagram"
                                    :value="account.instagram_id"
                                    @change="
                                      memberPermissionsChanges(
                                        'instagram',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Instagram profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Gmb............-->
            <div class="panel panel-default">
              <!--v-if="gmbView === 'on'"-->

              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_gmb"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div class="picture_block gmb_social_icon gmb_icon_color">
                        <!--<i class="fa fa-google google-b"></i>-->
                        <img
                          style="width: 26px"
                          src="/img/google-business.png"
                          alt=""
                        />
                      </div>
                      <div class="text_block">
                        <p class="text">Google My Business</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.gmb.length }}/{{
                            getGmbAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getGmbAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="gmb_select_all">
                          <input
                            id="gmb_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.gmb"
                            @change="memberPermissionsChanges('gmb', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="team_gmb" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Locations</h3>
                    </div>
                    <div class="items">
                      <template v-if="getGmbAccounts.items.length">
                        <div
                          class="option_row d-flex"
                          v-for="account in getGmbAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <!--<img :src="integrations.platformImage(account)" alt="">-->
                                <img src="/img/google-business.png" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ platformText(account) }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.name">
                                  <input
                                    :id="account.name"
                                    type="checkbox"
                                    v-model="getTeam.permissions.gmb"
                                    :value="account.name"
                                    @change="
                                      memberPermissionsChanges('gmb', 'Single')
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Google My Business location found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Youtube............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_youtube"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block youtube_social_icon youtube_icon_color"
                      >
                        <i class="cs-youtube"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">YouTube</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.youtube.length }}/{{
                            getYoutubeAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getYoutubeAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="youtube_select_all">
                          <input
                            id="youtube_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.youtube"
                            @change="memberPermissionsChanges('youtube', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_youtube"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getYoutubeAccounts.items.length">
                        <div
                          class="option_row d-flex"
                          v-for="account in getYoutubeAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.platform_identifier">
                                  <input
                                    :id="account.platform_identifier"
                                    type="checkbox"
                                    v-model="getTeam.permissions.youtube"
                                    :value="account.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'youtube',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Youtube profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Medium............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_medium"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block medium_social_icon medium_icon_color"
                      >
                        <i class="cs-medium medium"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Medium</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.medium.length }}/{{
                            getBlogItems('medium').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getBlogItems('medium').length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="medium_select_all">
                          <input
                            id="medium_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.medium"
                            @change="memberPermissionsChanges('medium', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="team_medium" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('medium').length">
                        <div
                          class="option_row d-flex"
                          v-for="blog in getBlogItems('medium')"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    type="checkbox"
                                    v-model="getTeam.permissions.medium"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'medium',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Medium blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Shopify............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_shopify"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block shopify_social_icon shopify_icon_color"
                      >
                        <i class="shopify_icon"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Shopify</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.shopify.length }}/{{
                            getBlogItems('shopify').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex_right ml-auto"
                    v-if="getBlogItems('shopify').length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="shopify_select_all">
                          <input
                            id="shopify_select_all"
                            type="checkbox"
                            v-model="getTeamSellectAllOptions.shopify"
                            @change="memberPermissionsChanges('shopify', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_shopify"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('shopify').length">
                        <div
                          class="option_row d-flex"
                          v-for="blog in getBlogItems('shopify')"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    type="checkbox"
                                    v-model="getTeam.permissions.shopify"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'shopify',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Shopify blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........TikTok.............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                        data-toggle="collapse"
                        data-target="#team_tiktok"
                        class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                          class="picture_block tiktok_social_icon tiktok_icon_color"
                      >
                        <i class="cs-tiktok">
                          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" style="width: 20px;">
                            <path fill="#00000" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
                          </svg>
                        </i>
                      </div>
                      <div class="text_block">
                        <p class="text">TikTok</p>
                        <p class="sub_text"
                        >Selected: {{ getTeam.permissions.tiktok.length }}/{{
                            getTiktokAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                      class="flex_right ml-auto"
                      v-if="getTiktokAccounts.items.length"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="tiktok_select_all">
                          <input
                              id="tiktok_select_all"
                              type="checkbox"
                              v-model="getTeamSellectAllOptions.tiktok"
                              @change="memberPermissionsChanges('tiktok', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="team_tiktok"
                  class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTiktokAccounts.items.length">
                        <div
                            class="option_row d-flex"
                            v-for="account in getTiktokAccounts.items"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.platform_identifier">
                                  <input
                                      :id="account.platform_identifier"
                                      type="checkbox"
                                      v-model="getTeam.permissions.tiktok"
                                      :value="account.platform_identifier"
                                      @change="
                                      memberPermissionsChanges(
                                        'tiktok',
                                        'Single'
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tiktok profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row col-md-12 col-sm-12">
        <div class="field_group">
          <button
            class="btn btn-studio-theme-space"
            v-if="getTeam.id"
            @click.prevent="updateTeamMember()"
            data-cy="add-team"
            :disabled="getSettingLoaders.saveTeamMember"
          >
            <span>Save</span>
            <clip-loader
              :color="'#ffffff'"
              :size="'12px'"
              v-if="getSettingLoaders.saveTeamMember"
            ></clip-loader>
          </button>
          <button
            class="btn btn-studio-theme-space"
            v-else
            @click.prevent="saveTeamMember()"
            data-cy="add-team"
            :disabled="getSettingLoaders.saveTeamMember"
          >
            <span>Save</span>
            <clip-loader
              :color="'#ffffff'"
              :size="'12px'"
              v-if="getSettingLoaders.saveTeamMember"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
.dropdown_right {
  border-radius: 5px;
  font-size: 0.875rem;
  color: #3a4557;
  border: solid 1px rgba(152, 158, 181, 0.5);
  .dropdown_header {
    padding-bottom: 11px !important;
    box-shadow: 0 0 0px 1px rgba(152, 158, 181, 0.5);
  }
  .dropdown_header.disable_manage_dropdown {
    position: relative;
    &:after {
      content: '';
    }
  }
  .dropdown-menu:after,
  .dropdown-menu:before {
    display: none !important;
  }
  .dropdown-menu {
    margin-top: 10px;
    border-radius: 10px;
    li {
      position: relative;
      padding-right: 30px;
      padding: 7px 20px;
      i {
        position: absolute;
        right: 10px;
        color: #1ecb7b;
        top: 50%;
        transform: translateY(-50%);
      }
      p {
        font-weight: 500;
      }
      .role_description {
        font-size: 12px;
        color: #989eb5;
        font-weight: 400;
      }
      &:hover {
        background: #f4f7fa;
        cursor: pointer;
      }
    }
  }
}

.autoComplete {
  height: 100% !important;
}
</style>
