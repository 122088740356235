<template>
  <div>
    <template v-if="getFetchSocialStatus">
      <div class="component_inner pt-5">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <template v-else-if="getInstagramAccounts.items.length === 0">
      <ConnectAccountSlot :name="'Instagram'"></ConnectAccountSlot>
    </template>
    <template v-else>
      <template v-if="insta_permission === false">
        <div class="padding-top_50">
          <div class="no_account_connected">
            <img
              class="connect_account"
              src="../../../../assets/img/analytics/connect/instagram.png"
              alt=""
            />

            <p>
              <template
                >You don't have permissions to fetch analytics for any account
                connected</template
              >
            </p>
            <button
              class="large_btn btn gradient_btn"
              @click="connectSocialAccount"
            >
              <span>Authorize Profile(s)</span>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="loader"
          :class="{ 'disable_click reduce_opacity': getAnalyticsServiceStatus }"
        >
          <div class="analytic_top_header pt-2">
            <div class="d-flex">
              <div class="pl-2 flex-row">
                <div
                  class="anaylytic_user_dropdown dropdown default_style_dropdown flex_row"
                >
                  <div
                    class="dropdown_header d-flex align-items-center"
                    data-toggle="dropdown"
                  >
                    <div class="w_img">
                      <img
                        v-if="metrics.insights.profile_picture_url"
                        :src="metrics.insights.profile_picture_url"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <span>
                      <span class="name">{{ metrics.insights.name }}</span>
                      <span class="title"
                        >@{{ metrics.insights.username }}</span
                      >
                    </span>
                    <span class="ml-auto"
                      ><i class="icon-dropdown-cs"></i
                    ></span>
                  </div>
                  <div
                    x-placement="bottom-end"
                    class="dropdown-menu dropdown-menu-center"
                  >
                    <ul class="inner">
                      <template v-if="getInstagramAccounts.items.length">
                        <li
                          v-for="item in getInstagramAccounts.items"
                          v-tooltip.bottom-center="
                            !hasInstaPermission(item)
                              ? 'Insufficient permissions, click to reconnect profile for Analytics.'
                              : ''
                          "
                          class="list_item"
                          :class="{
                            active: item.instagram_id === selected_account,
                          }"
                          @click.prevent="changeAccount(item)"
                        >
                          <a
                            class="d-flex align-items-center"
                            href=""
                            style="
                              border: 0;
                              background: transparent !important;
                            "
                          >
                            <div class="w_img">
                              <img
                                v-if="item.image === ''"
                                src="../../../../assets/img/profile_default.svg"
                                alt=""
                              />
                              <img v-else :src="item.image" alt="" />
                            </div>
                            <span class="name">{{ item.name }}</span>

                            <i
                              v-if="!hasInstaPermission(item)"
                              class="fa fa-exclamation-triangle no_permission"
                            ></i>
                            <i v-else class="cs-checked checked_icon"></i>
                          </a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
              <template v-if="findInstaAccount(selected_account)">
                <div
                  class="ml-auto mr-3 flex-row"
                  :class="{
                    'disable_click reduce_opacity':
                      findInstaAccount(selected_account).state === 'Added',
                  }"
                >
                  <DateRangePicker
                    ref="instagram_analytics_dp"
                    class="datepicker_filter_analytics_main datepicker_input mr-2 instagram_analytics_dp"
                    :opens="analytics.opens"
                    :max-date="getMaxDate"
                    :start-date="analytics.startDate"
                    :end-date="analytics.endDate"
                    :locale-data="analytics.locale"
                    :single-date-picker="analytics.singleDatePicker"
                    :time-picker="analytics.timePicker"
                    :time-picker24hour="analytics.timePicker24Hour"
                    :show-week-numbers="analytics.showWeekNumbers"
                    :show-dropdowns="analytics.showDropdowns"
                    @update="updateInstagramAnalytics"
                    :auto-apply="analytics.autoApply"
                    :ranges="analytics.ranges"
                  >
                    <div
                      slot="input"
                      slot-scope="picker"
                      class="datepicker_content with_bg ml-2 analytics_dp"
                    >
                      <i class="icon_first icon-Calendar"></i>
                      <!--  <span v-if="picker.label">
                     {{picker.label}}
                 </span>-->
                      <span>
                        <template v-if="!analytics.isChanged">
                          Last 30 Days
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              getDatePickerLabel(
                                picker.startDate,
                                picker.endDate,
                              )
                            "
                          >
                            {{
                              getDatePickerLabel(
                                picker.startDate,
                                picker.endDate,
                              )
                            }}
                          </template>
                          <template v-else>
                            {{ picker.startDate | onlyDate }} -
                            {{ picker.endDate | onlyDate }}
                          </template>
                        </template>
                      </span>
                    </div>
                  </DateRangePicker>
                  <PdfButton
                    :accounts="findInstaAccount(selected_account)"
                    :start_date="analytics.startDate"
                    :end_date="analytics.endDate"
                    :type="'instagram'"
                  ></PdfButton>
                </div>
              </template>
            </div>
            <div class="d-flex timezone mr-3">
              <p class="">*Stats are measured as per workspace timezone.</p>
            </div>
          </div>
          <template v-if="findInstaAccount(selected_account)">
            <template
              v-if="findInstaAccount(selected_account).state === 'Added'"
            >
              <AnalyticsLoading
                :name="findInstaAccount(selected_account).name"
              ></AnalyticsLoading>
            </template>
            <template v-else>
              <div class="analyze_tabs d-flex mr-5 ml-2">
                <ul class="links">
                  <li :class="{ active: active_section === 'overview' }">
                    <div
                      class="link-a"
                      @click.prevent="changeActiveSection('overview')"
                      >Overview</div
                    >
                  </li>
                  <li :class="{ active: active_section === 'demographics' }">
                    <div
                      class="link-a"
                      @click.prevent="changeActiveSection('demographics')"
                    >
                      Demographics</div
                    >
                  </li>

                  <li :class="{ active: active_section === 'posts' }">
                    <div
                      class="link-a"
                      @click.prevent="changeActiveSection('posts')"
                    >
                      Posts</div
                    >
                  </li>

                  <!--            <li>-->
                  <!--                <router-link :to="{name:'instagram_profile'}">-->
                  <!--                    <div class="link-a"> Profiles</div>-->
                  <!--                </router-link>-->
                  <!--            </li>-->
                </ul>
                <div
                  v-if="findInstaAccount(selected_account)"
                  class="ml-auto d-flex"
                >
                  <!--                  <div v-if="active_account.last_analytics_updated_at" class="update_box d-flex">-->
                  <!--                    <span>Updated {{ reformatDate(active_account.last_analytics_updated_at) }}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="update_box d-flex ml-2" style="cursor: pointer"-->
                  <!--                       :class="{'disable_click':findInstaAccount(selected_account).state === 'Syncing'}" @click="reFetchData()" >-->
                  <!--                    <template-->
                  <!--                        v-if="getSyncServiceStatus===true || findInstaAccount(selected_account).state === 'Syncing'">-->
                  <!--                      <i class="fas fa-sync"></i> <span>  Syncing...</span>-->
                  <!--                    </template>-->
                  <!--                    <template v-else>-->
                  <!--                      <i class="fas fa-sync"></i> <span>  Sync</span>-->
                  <!--                    </template>-->
                  <!--                  </div>-->
                </div>
              </div>
              <template v-if="active_section === 'overview'">
                <InstagramOverview :data="payload"></InstagramOverview>
              </template>
              <template v-else-if="active_section === 'demographics'">
                <InstagramDemographics
                  :data="payload"
                ></InstagramDemographics>
              </template>
              <template v-else-if="active_section === 'posts'">
                <InstagramPosts :data="payload"></InstagramPosts>
              </template>
            </template>
          </template>
        </div>
      </template>
    </template>
    <!-- navigation tabs ends here -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import InstagramOverview from '@src/modules/analytics/components/instagram/InstagramOverview'
import DateRangePicker from '@common/components/Analytics-date-range-picker/src/components/DateRangePicker'
import InstagramDemographics from '@src/modules/analytics/components/instagram/InstagramDemographics'
import InstagramPosts from '@src/modules/analytics/components/instagram/InstagramPosts'
import * as _ from 'underscore'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { SYNC_ERROR } from '@common/constants/messages'
import PdfButton from '../reports/DownloadPdfButton'
import ConnectAccountSlot from '../common/ConnectAccount'
import { instagram } from '../../../integration/store/states/mutation-types'
import AnalyticsLoading from './common/AnalyticsLoading'

export default {
  name: 'InstagramHeader',
  components: {
    AnalyticsLoading,
    InstagramPosts,
    InstagramDemographics,
    DateRangePicker,
    InstagramOverview,
    PdfButton,
    ConnectAccountSlot
  },
  data () {
    return dataValues().instagram
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getInstagramAccounts',
      'getAnalyticsServiceStatus',
      'getSyncServiceStatus',
      'getInstagramIds',
      'getFetchSocialStatus'
    ]),
    getMaxDate () {
      console.log(moment.utc().format('YYYY-MM-DD '))
      return moment.utc().format('YYYY-MM-DD')
    }
  },
  beforeDestroy () {
    this.removeSocketListners(this.selected_account)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
        'syncing-' + this.getActiveWorkspace._id + '-' + this.selected_account
      )
      this.analytics_channel.unsubscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  mounted: async function () {
    if (this.$route.query.section) {
      this.active_section = this.$route.query.section
    } else {
      this.active_section = 'overview'
    }
    if (this.$route.params.accountId) {
      if (
        this.hasInstaPermission(
          this.findInstaAccount(this.$route.params.accountId)
        )
      ) {
        this.selected_account = this.$route.params.accountId
      } else {
        if (this.getInstagramAccounts.items.length > 0) {
          this.insta_permission = this.selectHasInstaPermission()
        }
      }
    } else {
      if (this.getInstagramAccounts.items.length > 0) {
        this.insta_permission = this.selectHasInstaPermission()
      }
    }
    if (this.insta_permission) {
      if (this.selected_account != null) {
        this.changePath(this.selected_account)
        this.getInsights()

        const pusher = pusherAuthSocketAnalytics()
        this.analytics_channel = pusher.subscribe(
          'analytics-channel-' + this.getActiveWorkspace._id
        )
        this.bindSocketListeners()
      }
    } else {
      console.log('WE DO NOT HAVE PERMISSION FOR ANY')
    }
  },
  methods: {
    ...mapActions(['getAnalyticsService', 'syncUpdateService']),
    changeActiveSection (section) {
      console.log('Method::changeActiveSection')
      console.log(this.active_section)
      this.active_section = section
      this.$router.replace({
        query: {
          ...this.$route.query,
          section: section
        }
      })
    },
    changePath (value) {
      console.log('Method::changePath', value)

      if (value.length) {
        this.$router.push({
          name: 'instagram_analytics',
          params: {
            workspace: this.getWorkspaces.activeWorkspace.slug,
            accountId: value
          },
          query: {
            section: this.active_section
          }
        })
      }
    },
    async changeAccount (value) {
      console.log('Method::changeAccount')
      if (this.hasInstaPermission(value)) {
        this.selected_account = value.instagram_id
        this.changePath(value.instagram_id)
        this.insta_permission = this.hasInstaPermission(
          this.findInstaAccount(value.instagram_id)
        )
        // await this.getInsights()
      } else {
        this.$router.push({
          name: 'social',
          hash: '#instagram',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug }
        })
      }
    },
    async getInsights () {
      this.previous_start_date = this.previousAnalyticsPeriodDate(
        this.analytics.startDate,
        this.analytics.endDate
      )
      this.previous_end_date = this.analytics.startDate
      const payload = {
        workspace_id: this.getActiveWorkspace._id,
        timezone: this.getActiveWorkspace.timezone,
        date: `${this.analytics.startDate} - ${this.analytics.endDate}`,
        accounts: this.selected_account ? this.selected_account : '',
        type: 'instagram',
        section: 'insights'
      }
      const resp = await this.getAnalyticsService(payload)
      this.metrics = Object.assign(this.metrics, resp)
      this.metrics.active_section = 'overview'
      this.loader = true
      this.payload = {
        selected_account: this.selected_account,
        start_date: this.analytics.startDate,
        end_date: this.analytics.endDate,
        previous_start_date: this.previous_start_date,
        previous_end_date: this.analytics.startDate,
        metrics: this.metrics
      }
    },
    async updateInstagramAnalytics (values) {
      this.analytics.isChanged = true
      // date range
      if (values.startDate && values.endDate) {
        this.analytics.startDate = moment(values.startDate)
        this.analytics.endDate = moment(values.endDate)
        await this.getInsights()
      }
    },
    setIGState (state, checkFor, last_analytics_updated_at) {
      var updatedItems = []
      for (const item in this.getInstagramAccounts.items) {
        const update = this.getInstagramAccounts.items[item]
        if (update.instagram_id == checkFor) {
          update.state = state
          update.last_analytics_updated_at = last_analytics_updated_at
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'instagram',
        all_item_setter: instagram.SET_ALL_ACCOUNTS,
        item_setter: instagram.SET_ACCOUNTS
      })
    },
    findInstaAccount (insta_id) {
      return this.getInstagramAccounts.items.find(
        (x) => x.instagram_id === insta_id
      )
    },
    selectHasInstaPermission: function () {
      const index = this.getInstagramAccounts.items.findIndex((x) =>
        this.hasInstaPermission(x)
      )
      this.selected_account = index >= 0 ? this.getInstagramIds[index] : null
      return index >= 0
    },
    hasInstaPermission (channel) {
      if (channel) {
        const analyticPermission = _.findWhere(channel.permissions, {
          permission: 'instagram_manage_insights'
        })
        return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
      }
      return false
    },
    connectSocialAccount () {
      console.log('Method::connectSocialAccount')
      this.$router.push({
        name: 'social',
        hash: '#instagram'
      })
    },
    reformatDate (date) {
      return this.timesAgo(moment(date))
    },
    timesAgo (date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).fromNow()
    },
    async reFetchData () {
      if (this.findInstaAccount(this.selected_account).state === 'Syncing') {
        return
      }
      if (
        this.hourDifference(this.selected_account.last_analytics_updated_at) < 4
      ) {
        this.alertMessage(SYNC_ERROR, 'error')
        return
      }
      const payload = {
        selected_account: this.selected_account,
        channel: 'instagram'
      }
      const resp = await this.syncUpdateService(payload)
      if (resp.status) {
        this.setIGState(
          resp.state,
          this.selected_account,
          this.active_account.last_analytics_updated_at
        )
      }
      console.log(resp)
    },
    removeSocketListners (value) {
      console.log('removing socket for instagram ... ')

      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners () {
      const event =
        'syncing-' + this.getActiveWorkspace._id + '-' + this.selected_account
      this.analytics_channel.bind(event, (data) => {
        console.log('message data --> ', data)
        this.setIGState(
          data.state,
          data.account,
          data.last_analytics_updated_at
        )
        this.active_account = data.account
        this.getInsights()
      })
    }
  },
  watch: {
    'getInstagramAccounts.items': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal.length > 0) {
          this.insta_permission = this.hasInstaPermission(
            this.findInstaAccount(newVal[0].instagram_id)
          )
          if (!this.insta_permission) {
            this.insta_permission = this.selectHasInstaPermission()
          }
          if (this.insta_permission) {
            this.changePath(this.selected_account)
          }
        }
      }
    },
    getFetchSocialStatus (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === false) {
          this.insta_permission = this.hasInstaPermission(
            this.findInstaAccount(this.getInstagramAccounts.items.instagram_id)
          )
          if (!this.insta_permission) {
            this.insta_permission = this.selectHasInstaPermission()
          }
          if (this.insta_permission) {
            this.changePath(this.selected_account)
          }
        } else {
        }
      }
    },
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.active_account = this.findInstaAccount(newVal)
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
        await this.getInsights()
      }
    },
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeSocketListners(oldVal)
        this.analytics_channel.unsubscribe('analytics-channel-' + oldVal)
        this.analytics_channel.subscribe('analytics-channel-' + newVal)
        this.bindSocketListeners()

        // await this.getInsights()
      }
    }
  }
}
</script>

<style scoped>
.padding-top_50 {
  padding: 50px 0px;
}

.timezone {
  justify-content: flex-end;
}

.timezone p {
  font-size: 0.7rem;
  color: #328ae0;
}

.rotating {
  transition: transform 1s ease-in-out;
}

.rotating:hover {
  transform: rotateZ(360deg);
}
</style>
