export default {
  state: {
    filters: {
      search: '',
      page: 0,
      limit: 20,
      flickr: {
        license: {
          code: 0,
          name: 'Any License'
        }
      },
      imgur: {
        sort: 'viral',
        section: 'hot'
      },
      pixabay: {
        sort: 'popular',
        type: 'all'
      }
    },
    images: [],
    scroll: true,
    loader: {
      imageLoader: false,
      infiniteLoader: false
    }
  },
  actions: {
    setImagesPage ({ commit }, page) {
      commit('setImagesPage', page)
    },
    setImagesPosts ({ commit }, posts) {
      commit('setImagesPosts', posts)
    },
    setImagesLoader ({ commit }, loader) {
      commit('setImagesLoader', loader)
    },
    setImagesScroll ({ commit }, scroll) {
      commit('setImagesScroll', scroll)
    },
    setImagesPaginationLoader ({ commit }, loader) {
      commit('setImagesPaginationLoader', loader)
    },
    setFlickrLicense ({ commit }, license) {
      commit('setFlickrLicense', license)
    },
    setImgurSort ({ commit }, sort) {
      commit('setImgurSort', sort)
    },
    setImgurSection ({ commit }, section) {
      commit('setImgurSection', section)
    },
    setPixabayType ({ commit }, type) {
      commit('setPixabayType', type)
    },
    setPixabaySort ({ commit }, sort) {
      commit('setPixabaySort', sort)
    },
    resetImagesStates ({ commit }) {
      commit('resetImagesStates')
    }
  },
  mutations: {
    setImagesPage (state, page) {
      state.filters.page = page
    },
    setImagesPosts (state, posts) {
      state.images = posts
    },
    setImagesLoader (state, loader) {
      state.loader.imageLoader = loader
    },
    setImagesPaginationLoader (state, loader) {
      state.loader.infiniteLoader = loader
    },
    setImagesScroll (state, scroll) {
      state.scroll = scroll
    },
    setFlickrLicense (state, license) {
      state.filters.flickr.license = license
    },
    setImgurSort (state, sort) {
      state.filters.imgur.sort = sort
    },
    setImgurSection (state, section) {
      state.filters.imgur.section = section
    },
    setPixabayType (state, type) {
      state.filters.pixabay.type = type
    },
    setPixabaySort (state, sort) {
      state.filters.pixabay.sort = sort
    },
    resetImagesStates (state) {
      state.filters = {
        search: '',
        page: 0,
        limit: 20,
        flickr: {
          license: {
            code: 0,
            name: 'Any License'
          }
        },
        imgur: {
          sort: 'viral',
          section: 'hot'
        },
        pixabay: {
          sort: 'popular',
          type: 'all'
        }
      }
      state.images = []
      state.scroll = true
      state.loader = {
        imageLoader: false,
        infiniteLoader: false
      }
    }
  },
  getters: {
    getImagesPaginationLoader: (state) => {
      return state.loader.infiniteLoader
    },
    getImagesLoader: (state) => {
      return state.loader.imageLoader
    },
    getImagesPosts: (state) => {
      return state.images
    },
    getImagesPage: (state) => {
      return state.filters.page
    },
    getImagesScroll: (state) => {
      return state.scroll
    },
    getImagesContentFilter: (state) => {
      return state.filters
    },

    getFlickrLicense: (state) => {
      return state.filters.flickr.license
    },
    getImgurSort: (state) => {
      return state.filters.imgur.sort
    },
    getImgurSection: (state) => {
      return state.filters.imgur.section
    },
    getPixabaySort: (state) => {
      return state.filters.pixabay.sort
    },
    getPixabayType: (state) => {
      return state.filters.pixabay.type
    }
  }
}
