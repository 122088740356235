<template>
  <div class="media-library">
    <socialshare></socialshare>
    <UploadMediaModal
      :type="type"
      :folders="folders"
      :limits="mediaLimits"
      :is-fetching-limits="isFetchingLimits"
      @delete="deleteItem"
    />
    <CreateFolderModal :folders="folders" />
    <AssetPreviewModal
      :is-media-deleting="isMediaDeleting"
      @delete="deleteItem"
    />
    <PreviewMediaAssetModal
      :current-media="currentItemInPreview"
      :disable-right="disabledRight"
      :disable-left="disabledLeft"
      @next-image="loadNextImage"
      @previous-image="loadPreviousImage"
      @delete="deleteItem"
      @share="shareItem"
    />

    <SideBar
      :selected-items="selected"
      :is-folder-fetching="isFolderFetching"
      :folders="folders"
      :files-count="filesCount"
      :is-all-selected="isAllSelected"
      @move="handleMoveLoader"
    />
    <div class="media-library-body">
      <Header
        :total="mediaMeta.total"
        :limits="mediaLimits"
        :is-fetching-limits="isFetchingLimits"
      />
      <FiltersBar
        :show-restore-option="selectedType === 'archived'"
        :loading-title="loadingMessage"
        :is-operating="
          isMediaFetching ||
          isFolderFetching ||
          isMediaDeleting ||
          isMediaArchiving ||
          isMediaMoving
        "
        :selected="selected"
        :total="mediaMeta.total"
        :disabled="isFilterDisabled"
        :is-all-selected="isAllSelected"
        @unselect="unSelectAll"
        @remove="removeBulk"
        @restore="restoreItemBulk"
        @select-all-visible="selectAllVisibleItems"
        @select-all="selectAllItems"
        @share="shareItem"
        @export="exportItem"
      />

      <!--    Sub folders list    -->
      <!--        <div class="media-library-folders-block" v-if="activeSubFolders.length > 0">-->
      <!--          <div class="media-library-folder-card" v-for="(folder, key) in activeSubFolders" :key="key" @click="changeRoute(folder._id, false)">-->
      <!--            <i class="far fa-folder"></i>-->
      <!--            {{ folder.folder_name }}-->
      <!--          </div>-->
      <!--        </div>-->

      <div class="media-library-content" @scroll="handleScroll">
        <template v-if="mediaAssets.length === 0 && isMediaFetching === false">
          <div
            class="d-flex w-100 justify-content-center flex-column align-items-center"
          >
            <img
              draggable="false"
              src="../../../../assets/img/no_data_found.svg"
              alt=""
              width="250"
            />
            <i>Media not found</i>
          </div>
        </template>

        <div class="item-row">
          <template v-if="isMediaFetching">
            <div class="item-card">
              <SkeletonBox width="100%" height="180px" radius="12px" />
            </div>
            <div class="item-card">
              <SkeletonBox width="100%" height="180px" radius="12px" />
            </div>
            <div class="item-card">
              <SkeletonBox width="100%" height="180px" radius="12px" />
            </div>
          </template>

          <div
            v-for="(item, index) in mediaAssets"
            v-else
            :key="index"
            class="item-card"
          >
            <Asset
              :id="item._id"
              type="primary"
              :src="isVideo(item['mime_type']) ? item.thumbnail : item.link"
              :alt="item.name"
              :info="item"
              :show-restore="
                $route.query.type && $route.query.type === 'archived'
              "
              :show-share="true"
              :is-media-asset="true"
              @delete="deleteItem"
              @check="selectItem"
              @view="viewItem"
              @restore="restoreItem"
              @share="shareItem"
            />
          </div>

          <template>
            <div v-show="at_bottom" class="item-card">
              <SkeletonBox width="100%" height="180px" radius="12px" />
            </div>
            <div v-show="at_bottom" class="item-card">
              <SkeletonBox width="100%" height="180px" radius="12px" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'is-empty'
import { swalAttributes } from '@common/constants/common-attributes'
import { videoDefaultSharingDetails } from '@src/modules/composer_v2/views/composerInitialState'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import socialshare from '@src/modules/publish/components/dialogs/SocialShare'
import SideBar from '@src/modules/publish/components/media-library/components/SideBar'
import Header from '@src/modules/publish/components/media-library/components/Header'
import FiltersBar from '@src/modules/publish/components/media-library/components/FiltersBar'
import Asset from '@src/modules/publish/components/media-library/components/Asset'
import AssetPreviewModal from '@src/modules/publish/components/media-library/components/AssetPreviewModal'
import CreateFolderModal from '@src/modules/publish/components/media-library/components/CreateFolderModal'
import UploadMediaModal from '@src/modules/publish/components/media-library/components/UploadMediaModal'
import { DragDropMixin } from '@src/modules/publish/components/media-library/utils/helper'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import PreviewMediaAssetModal from '@src/modules/publish/components/media-library/components/PreviewMediaAssetModal'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'MediaLibraryMain',
  components: {
    PreviewMediaAssetModal,
    SideBar,
    Header,
    FiltersBar,
    Asset,
    AssetPreviewModal,
    CreateFolderModal,
    UploadMediaModal,
    SkeletonBox,
    socialshare,
  },
  mixins: [DragDropMixin, MediaHelperMixin],
  data() {
    return {
      mediaAssets: [],
      mediaMeta: {},
      at_bottom: false,
      isFolderFetching: false,
      isMediaFetching: false,
      isMediaDeleting: false,
      isMediaArchiving: false,
      isFetchingLimits: false,
      isMediaMoving: false,

      mediaLimits: null,
      folders: [],
      filesCount: {},
      selectedFolder: null,
      selectedType: 'all',
      removeableAssets: [],

      isAllSelected: {
        visible: false,
        total: false,
      },
      type: 'library',
      currentItemInPreview: {},
      currentImageIndex: -1,
      // disableRight: false,
      // disableLeft: false,
    }
  },
  computed: {
    loadingMessage() {
      if (
        this.isMediaFetching ||
        this.isFolderFetching ||
        this.isMediaDeleting ||
        this.isMediaArchiving ||
        this.isMediaMoving
      ) {
        if (this.isMediaDeleting) {
          return 'Deleting...'
        } else if (this.isMediaMoving) {
          return 'Moving...'
        } else if (this.isMediaFetching) {
          return 'Fetching Media...'
        } else if (this.isFolderFetching) {
          return 'Fetching Folder...'
        } else if (this.isMediaArchiving){
          return 'Archiving...'
        }
      }
      return false
    },
    combinedFilters() {
      return {
        sort: this.$route.query.sort || null,
        type: this.$route.query.filetype || null,
        search: this.$route.query.search || null,
      }
    },
    isFilterDisabled() {
      if (
        !isEmpty(this.combinedFilters.sort) ||
        !isEmpty(this.combinedFilters.type) ||
        !isEmpty(this.combinedFilters.search)
      ) {
        return false
      } else {
        return this.mediaAssets.length === 0
      }
    },
    activeSubFolders() {
      if (this.$route.query.folderId && !isEmpty(this.folders)) {
        const activeFolder = this.folders.filter(
          (folder) => folder._id === this.$route.query.folderId
        )[0]

        if (activeFolder) {
          return activeFolder.sub_folders
        }
      }
      return []
    },
    disabledRight() {
      return this.currentImageIndex === this.mediaAssets.length - 1
    },
    disabledLeft() {
      return this.currentImageIndex === 0
    },
  },
  watch: {
    '$route.query.type'(value) {
      console.debug('watch type', value)
      if (value) {
        this.selectedType = value

        if (value === 'folder' && this.$route.query.folderId) {
          this.selectedFolder = this.$route.query.folderId
        } else {
          if (value !== 'archived') {
            this.selectedFolder = null
          }
        }
        this.selected = []
        this.isAllSelected.visible = false
        this.isAllSelected.total = false
        this.fetchMediaAssets()
      } else {
        this.selectedType = 'all'
      }
    },
    '$route.query.folderId'(value) {
      console.debug('watch folderId', value)
      if (value && this.selectedFolder !== value) {
        if (this.selectedType === 'folder') {
          this.selectedFolder = value
        }
        this.selected = []
        this.isAllSelected.visible = false
        this.isAllSelected.total = false
        this.fetchMediaAssets()
      }
    },

    // filter
    combinedFilters(filters, oldFilters) {
      console.debug('watch filters', filters)

      if (
        !isEmpty(filters.sort) ||
        !isEmpty(filters.type) ||
        filters.search !== oldFilters.search
      ) {
        this.fetchMediaAssets(true, null, filters)
      }
    },
  },
  mounted() {
    this.fetchMediaLimits()

    if (!isEmpty(this.$route.query.type)) {
      this.selectedType = this.$route.query.type

      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        this.selectedFolder = this.$route.query.folderId
      }
    } else {
      this.$router.push({
        name: 'media-library',
        query: {
          type: 'all',
        },
      })
    }

    // Fetch Folders on Mounted
    this.fetchFolders()
    // Fetch Media on Mounted
    this.fetchMediaAssets(true, this.selectedFolder, this.combinedFilters)

    EventBus.$on('refetch-folders', () => {
      this.fetchFolders()
    })

    EventBus.$on('refetch-media', ({folderId = null, isRoot = true}) => {
      if (folderId === null) {
        if (this.$route.query.type !== 'uncategorized') {
          this.$router.push({
            name: 'media-library',
            query: {
              type: 'uncategorized',
            },
          })
        } else {
          this.fetchMediaAssets(true, folderId)
        }
      } else {
        if (
          this.$route.query.type !== 'folder' ||
          (this.$route.query.type === 'folder' &&
            this.$route.query.folderId !== folderId)
        ) {
          this.$router.push({
            name: 'media-library',
            query: {
              type: 'folder',
              folderId: folderId,
              root: isRoot,
            },
          })
        } else {
          this.fetchMediaAssets(true, folderId)
        }
      }
    })

    EventBus.$on('refetch-media-limits', () => {
      this.fetchMediaLimits()
    })

    EventBus.$on('delete-media', async () => {
      if(this.removeableAssets.length === 0) return
      this.isMediaDeleting = true
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
      // when in preview mode, load the previous available image
      this.loadPreviousImage(true)

      const payload = {
        ids: this.removeableAssets,
      }

      if (this.isAllSelected.total) {
        payload.ids = []
        switch (this.selectedType) {
          case 'all':
          case 'uncategorized':
          case 'archived': {
            payload.folder_id = this.selectedType
            break
          }
          case 'folder': {
            payload.folder_id = this.$route.query.folderId
            break
          }
        }
      }

      await this.removeMediaAssetsHelper(
        {
          ...payload,
        },
        false,
        (status, removed) => {
          if (status && removed.length > 0) {
            this.fetchFolders()
            this.fetchMediaLimits()

            if (this.removeableAssets.length === 1) {
              this.mediaAssets = this.mediaAssets.filter(
                (asset) => this.removeableAssets[0] !== asset._id
              )
            } else {
              this.mediaAssets = this.mediaAssets.filter(
                (asset) => !this.removeableAssets.includes(asset._id)
              )
            }
            this.unSelectAll()
            this.selected = []
            this.$bvModal.hide('mediaRemovalConfirmationDialog')
            this.$bvModal.hide('asset-preview-modal')
            // this.$bvModal.hide('preview-media-asset-modal')
          }
        }
      )

      this.isMediaDeleting = false
    })

    EventBus.$on('archive-media', async () => {
      if(this.removeableAssets.length === 0) return
      this.isMediaArchiving = true
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
      // when in preview mode, load the previous available image
      this.loadPreviousImage(true)

      const payload = {
        ids: this.removeableAssets,
      }

      if (this.isAllSelected.total) {
        payload.ids = []
        switch (this.selectedType) {
          case 'all':
          case 'uncategorized':
          case 'archived': {
            payload.folder_id = this.selectedType
            break
          }
          case 'folder': {
            payload.folder_id = this.$route.query.folderId
            break
          }
        }
      }

      await this.removeMediaAssetsHelper(
        {
          ...payload,
        },
        true,
        (status, removed) => {
          if (status && removed.length > 0) {
            this.fetchFolders()
            this.fetchMediaLimits()

            if (this.removeableAssets.length === 1) {
              this.mediaAssets = this.mediaAssets.filter(
                (asset) => this.removeableAssets[0] !== asset._id
              )
            } else {
              this.mediaAssets = this.mediaAssets.filter(
                (asset) => !this.removeableAssets.includes(asset._id)
              )
            }
          }
        }
      )
      this.selected = []
      this.unSelectAll()
      this.$bvModal.hide('asset-preview-modal')
      // this.$bvModal.hide('preview-media-asset-modal')
      this.isMediaArchiving = false
    })
    // changing type of upload media modal
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'social_share_modal') this.type = 'primary'
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'social_share_modal') this.type = 'library'
    })
  },
  beforeDestroy() {
    // Clearing State
    this.mediaAssets = []
    this.mediaMeta = {}
    this.at_bottom = false
    this.isFolderFetching = false
    this.isMediaFetching = false
    this.isMediaDeleting = false
    this.isMediaArchiving = false
    this.folders = []
    this.filesCount = {}
    this.selectedFolder = null
    this.selectedType = 'all'

    EventBus.$off('refetch-folders')
    EventBus.$off('refetch-media')
    EventBus.$off('workspace-changed')

    EventBus.$off('delete-media')
    EventBus.$off('archive-media')
    this.$root.$off('bv::modal::shown')
    this.$root.$off('bv::modal::hidden')
  },
  created() {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      this.fetchFolders()
      this.fetchMediaAssets()
      this.fetchMediaLimits()
    })
  },
  methods: {
    selectAllVisibleItems() {
      // Selecting All Existing Assets
      this.selected = this.mediaAssets.map((asset) => asset._id)

      this.selected.forEach((id) => {
        const element = document.getElementById(id)
        element.setAttribute('data-item-selected', true)
      })

      this.isAllSelected.visible = true
    },

    selectAllItems() {
      this.selectAllVisibleItems()
      this.isAllSelected.total = true
    },

    handleMoveLoader(val) {
      this.isMediaMoving = val
    },

    changeRoute(folderId, isRoot) {
      EventBus.$emit('refetch-folders')
      EventBus.$emit('refetch-media', {folderId, isRoot})
      // this.$router.push({
      //   'name': 'media-library',
      //   'query': {
      //     type: 'folder',
      //     folderId: folderId
      //   }
      // })
    },

    async removeBulk() {
      this.removeableAssets = this.selected

      if (this.$route.query.type && this.$route.query.type === 'archived') {
        const h = this.$createElement
        // Using HTML string
        const messageNode = h('div', {
          domProps: {
            innerHTML:
              "Are you sure, you want to delete these media assets? <i class='gray_dark font-0-87rem mt-3 d-block'>Note: Incase the removed media asset is used in any scheduled post, the post will fail to publish.</i>",
          },
        })
        const confirm = await this.$bvModal.msgBoxConfirm([messageNode], {
          title: 'Remove Folder',
          ...swalAttributes(),
        })
        if (confirm) {
          this.isMediaDeleting = true

          const payload = {
            ids: this.selected,
          }

          if (this.isAllSelected.total) {
            payload.ids = []
            switch (this.selectedType) {
              case 'all':
              case 'uncategorized':
              case 'archived': {
                payload.folder_id = this.selectedType
                break
              }
              case 'folder': {
                payload.folder_id = this.$route.query.folderId
                break
              }
            }
          }

          // Deleting
          await this.removeMediaAssetsHelper(
            {
              ...payload,
            },
            false,
            (status, removed) => {
              if (status && removed.length > 0) {
                this.fetchFolders()
                // this.fetchMediaAssets()
                this.fetchMediaLimits()
                this.mediaAssets = this.mediaAssets.filter(
                  (asset) => !this.selected.includes(asset._id)
                )
                this.selected = []
                this.isAllSelected.visible = false
                this.isAllSelected.total = false
                this.$bvModal.hide('asset-preview-modal')
              }
            }
          )
          this.isMediaDeleting = false
        }
      } else {
        this.$bvModal.show('mediaRemovalConfirmationDialog')
      }
    },

    isVideo(mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },

    viewItem(item) {
      console.log('METHOD::viewItem ~ item -> ', item)
      this.currentItemInPreview = item
      this.currentImageIndex = this.mediaAssets.findIndex(
        (media) => media._id === item._id
      )
      // this.$root.$emit('open-media-preview', item)
      // this.$root.$emit('preview-media-asset-modal', item)
      this.$bvModal.show('preview-media-asset-modal')
    },

    async restoreItemBulk() {
      this.isMediaMoving = true

      const payload = {
        assets: this.selected,
        folderId: 'uncategorized',
      }

      if (this.isAllSelected.total) {
        payload.assets = []
        switch (this.selectedType) {
          case 'all':
          case 'uncategorized':
          case 'archived': {
            payload.current_folder = this.selectedType
            break
          }
          case 'folder': {
            payload.current_folder = this.$route.query.folderId
            break
          }
        }
      }

      payload.restore = true

      await this.moveMediaAssetsHelper(
        {
          ...payload,
        },
        (status, message) => {
          EventBus.$emit('refetch-folders')

          EventBus.$emit('refetch-media')
        },
          true
      )

      this.isMediaMoving = false
    },

    async restoreItem(id, key) {
      this.isMediaMoving = true

      const payload = {
        assets: [id],
        folderId: 'uncategorized',
      }

      if (this.isAllSelected.total) {
        payload.assets = []
        switch (this.selectedType) {
          case 'all':
          case 'uncategorized':
          case 'archived': {
            payload.current_folder = this.selectedType
            break
          }
          case 'folder': {
            payload.current_folder = this.$route.query.folderId
            break
          }
        }
      }

      payload.restore = true

      await this.moveMediaAssetsHelper(
        {
          ...payload,
        },
        (status, message) => {
          EventBus.$emit('refetch-folders')

          EventBus.$emit('refetch-media')
        },
          true
      )

      this.isMediaMoving = false
    },

    async deleteItem(id, key) {
      console.log('METHOD::deleteItem -> ', id, key)
      this.removeableAssets = [id]

      if (this.$route.query.type && this.$route.query.type === 'archived') {
        const h = this.$createElement
        // Using HTML string
        const messageNode = h('div', {
          domProps: {
            innerHTML:
              "Are you sure, you want to delete this media asset? <i class='gray_dark font-0-87rem mt-3 d-block'>Note: Incase the removed media asset is used in any scheduled post, the post will fail to publish.</i>",
          },
        })
        const confirm = await this.$bvModal.msgBoxConfirm([messageNode], {
          title: 'Remove Folder',
          ...swalAttributes(),
        })
        if (confirm) {
          this.isMediaDeleting = true
          this.loadPreviousImage(true)

          const payload = {
            ids: [id],
          }

          if (this.isAllSelected.total) {
            payload.ids = []
            switch (this.selectedType) {
              case 'all':
              case 'uncategorized':
              case 'archived': {
                payload.folder_id = this.selectedType
                break
              }
              case 'folder': {
                payload.folder_id = this.$route.query.folderId
                break
              }
            }
          }

          await this.removeMediaAssetsHelper(
            {
              ...payload,
            },
            false,
            (status, removed) => {
              if (status && removed.length > 0) {
                this.fetchFolders()
                this.fetchMediaLimits()
                // this.fetchMediaAssets(true, this.selectedFolder, this.combinedFilters)
                this.mediaAssets = this.mediaAssets.filter(
                  (asset) => id !== asset._id
                )
                this.$bvModal.hide('asset-preview-modal')
              }
            }
          )
          this.unSelectAll()
          this.isMediaDeleting = false
        }
      } else {
        this.$bvModal.show('mediaRemovalConfirmationDialog')
      }
    },

    async handleScroll(el) {
      const scrollHeight = el.target.scrollHeight
      const totalHeight = el.target.offsetHeight + el.target.scrollTop

      if (
        totalHeight >= scrollHeight &&
        !this.at_bottom &&
        !isEmpty(this.mediaMeta) &&
        !this.mediaMeta.completed
      ) {
        this.at_bottom = true
        console.log('at the bottom')

        await this.fetchMediaAssets(
          false,
          this.selectedFolder,
          this.combinedFilters
        )
        this.at_bottom = false

        if (!this.isAllSelected.total) {
          this.isAllSelected.visible = false
        } else {
          this.selectAllVisibleItems()
        }
      }
    },

    async fetchFolders() {
      this.isFolderFetching = true

      await this.fetchAllFoldersHelper(true, (status, folders) => {
        if (status) {
          this.folders = [...folders]
        } else {
          this.folders = []
        }
      })

      await this.fetchMediaStatsHelper((status, stats) => {
        if (status) {
          this.filesCount = { ...stats }
        } else {
          this.filesCount = {}
        }
      })

      this.isFolderFetching = false
    },

    async fetchMediaAssets(
      clear = true,
      folderId = null,
      filters = {},
      page = 1,
      limit = 40
    ) {
      console.debug('Method:fetchMediaAssets', this.mediaAssets)

      if (clear) {
        this.mediaAssets = []
        this.mediaMeta = {}
        this.isMediaFetching = true
        this.selected = []
        this.isAllSelected.visible = false
        this.isAllSelected.total = false
      }

      let currentPage = page
      let nextPage = page
      let total = null
      let isCompleted = false

      if (!isEmpty(this.mediaMeta)) {
        currentPage = this.mediaMeta.current_page
        nextPage = currentPage + 1
        total = this.mediaMeta.total
        isCompleted = this.mediaMeta.completed
      }

      const payloadFilters = {
        page: nextPage,
        limit: limit,
      }

      // Adding filters in payload
      if (!isEmpty(filters)) {
        for (const filterName in filters) {
          if (!(filterName === 'type' && filters[filterName] === 'all')) {
            payloadFilters[filterName] = filters[filterName]
          }
        }
      }

      // || isEmpty(this.$route.query.filetype) ? null : this.$route.query.filetype

      if (isEmpty(folderId)) {
        if (this.selectedType === 'uncategorized') {
          payloadFilters.folder_id = null
        }

        if (this.selectedType === 'archived') {
          payloadFilters.archived = true
        }

        if (this.selectedType === 'folder') {
          payloadFilters.folder_id = this.selectedFolder
        }
      } else {
        payloadFilters.folder_id = folderId
      }

      if (total === null || !isCompleted) {
        await this.fetchMediaHelper(payloadFilters, (status, media) => {
          if (clear) {
            this.mediaAssets = []
          }

          if (media.data.length) {
            // Saving Media Files
            this.mediaAssets = [...this.mediaAssets, ...media.data]

            // Saving Media Meta
            this.mediaMeta = {
              current_page: media.current_page,
              count: this.mediaAssets.length,
              total: media.total,
              completed: media.total === media.to,
            }
          }
        })
      }

      if (clear) {
        this.isMediaFetching = false
      }
    },

    async fetchMediaLimits() {
      this.isFetchingLimits = true

      await this.getMediaLimitsHelper((used, total) => {
        this.mediaLimits = {
          used,
          total,
        }
      })

      this.isFetchingLimits = false
    },
    async shareItem(item) {
      this.$bvModal.hide('preview-media-asset-modal')
      if (item === true) {
        this.setWidgetSelectedUploadedFilesItems(
          this.mediaAssets.filter((asset) => this.selected.includes(asset._id))
        )
      } else this.setWidgetSelectedUploadedFilesItems([item])
      if (
        (await this.insertFile('share-media', {
          image: [],
          video: videoDefaultSharingDetails,
        })) !== false
      ) {
        this.$bvModal.show('composer-modal')
      }
    },
    exportItem() {
      let payload = {}
      if (this.isAllSelected.total) {
        payload = {
          workspace_id: this.getActiveWorkspace._id,
          folder_id: this.selectedFolder
            ? this.selectedFolder
            : this.selectedType,
          select_all: this.isAllSelected.total,
        }
      } else {
        payload = {
          workspace_id: this.getActiveWorkspace._id,
          folder_id: this.selectedFolder
            ? this.selectedFolder
            : this.selectedType,
          select_all: this.isAllSelected.total,
          id: this.mediaAssets
            .filter((asset) => this.selected.includes(asset._id))
            .map(function (x) {
              return x._id
            }),
        }
      }
      var status = this.createMediaCSV(payload)
      if (status === true) {
        this.unSelectAll()
      }
    },

    /**
     * Loads next available image for preview. Fetches more media /w pagination when available
     * @returns {Promise<void>}
     */
    async loadNextImage() {
      if (this.currentImageIndex === this.mediaAssets.length - 1) return
      this.currentImageIndex = this.mediaAssets.findIndex(
        (item) => item._id === this.currentItemInPreview._id
      )
      // when to fetch more media.
      if (
        this.currentImageIndex < this.mediaAssets.length - 5 &&
        this.mediaAssets.length - 1 - this.currentImageIndex === 5
      ) {
        // fetch more media and enable api loader
        if (this.isMediaFetching) return
        await this.fetchMediaAssets(
          false,
          this.selectedFolder,
          this.combinedFilters
        )
        this.at_bottom = false

        if (!this.isAllSelected.total) {
          this.isAllSelected.visible = false
        } else {
          this.selectAllVisibleItems()
        }
      }
      this.currentItemInPreview = this.mediaAssets[++this.currentImageIndex]
    },

    /**
     * Loads preview image for preview on click and on current media removal
     */
    loadPreviousImage(afterDelete = false) {
      if (afterDelete && this.currentImageIndex === 0) {
        this.$bvModal.hide('preview-media-asset-modal')
        return
      }
      if (!afterDelete && this.currentImageIndex <= 0) return
      this.currentImageIndex = this.mediaAssets.findIndex(
        (item) => item._id === this.currentItemInPreview?._id
      )
      this.currentItemInPreview = this.mediaAssets[--this.currentImageIndex]
    },
  },
}
</script>
