import http from '@common/lib/http-common'
import { saveFolderURL } from '../config/api-utils'
import { folderTypes } from './mutation-types'

export default {
  state: {
    folder: {
      id: null,
      list: [],
      add: false,
      favoriteIds: [],
      status: false,
      text: 'Select Folder',
      archived: false
    }
  },
  actions: {
    storeFolder (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return http
        .post(saveFolderURL, payload)
        .then((res) => {
          if (res.data.status) {
            context.commit(folderTypes.SET_FOLDERS_LIST, res.data.folders)
            return res
          } else {
            return false
          }
        })
        .catch((err) => {
          return null
        })
    },

    setFolders ({ commit }, folder) {
      commit('setFolders', folder)
    },
    setAddFolderBox ({ commit }, folder) {
      commit('setAddFolderBox', folder)
    },

    setAllFavoriteIds ({ commit }, ids) {
      commit('setAllFavoriteIds', ids)
    },

    setFilterFolderId ({ commit }, id) {
      commit('setFilterFolderId', id)
    },

    setFilterArchived ({ commit }, status) {
      commit('setFilterArchived', status)
    },

    setFolderStatus ({ commit }, status) {
      commit('setFolderStatus', status)
    },

    setFolderText ({ commit }, status) {
      commit('setFolderText', status)
    }
  },
  mutations: {
    // Folders and favorite Work
    [folderTypes.SET_FOLDERS_LIST] (state, value) {
      state.folder.list = value
    },
    setFolders (state, folder) {
      state.folder.list = folder
    },
    setAddFolderBox (state, folder) {
      state.folder.add = folder
    },

    setAllFavoriteIds (state, ids) {
      state.folder.favoriteIds = ids
    },

    setFilterFolderId (state, id) {
      state.folder.id = id
    },

    setFilterArchived (state, status) {
      state.folder.archived = status
    },
    setFolderText (state, status) {
      state.folder.text = status
    },
    SET_FOLDERS (state, folders) {
      state.folder.list = folders
    }
  },
  getters: {
    // folder and favorite

    getFolders: (state) => state.folder.list,
    getAddFolderBox: (state) => state.folder.add,
    getAllFavoriteIds: (state) => state.folder.favoriteIds,
    getFolderStatus: (state) => state.folder.status,
    getFilterFolderId: (state) => state.folder.id,
    getFolderText: (state) => state.folder.text
  }
}
