<template>

  <div>
      <div class="grid grid-cols-2 gap-4 bg-gray-50 rounded">
        <div class="p-4">
            <div class="flex">
              <h4 class="text-base flex-grow	font-semibold text-green-500	">Word Count</h4>
              <h3 class="text-lg text-green-500">{{getWordsCount}}</h3>
            </div>
            <p class="text-sm	mt-1.5 text-gray-800">Use More than <span class="font-bold" >2500</span> words</p>
        </div>

        <div class="p-4">
            <div class="flex">
              <h4 class="text-base flex-grow	font-semibold text-orange-600">Read Time</h4>
              <h3 class="text-lg text-orange-600">{{readTime}}</h3>
            </div>
            <p class="text-sm	mt-1.5 text-gray-800">Recomended <span class="font-bold" >7</span> mins.</p>
        </div>

        <div class="p-4">
            <div class="flex">
              <h4 class="text-base flex-grow	font-semibold text-purple-500	">Heading Count</h4>
              <h3 class="text-lg text-purple-500 hidden">{{0}}</h3>
            </div>
            <p class="text-sm	mt-1.5 text-gray-800">Use around <span class="font-bold" >28</span> headings</p>
        </div>

        <div class="p-4">
            <div class="flex">
              <h4 class="text-base flex-grow	font-semibold text-blue-600">Read Time</h4>
              <h3 class="text-lg text-blue-600">{{0}}</h3>
            </div>
            <p class="text-sm	mt-1.5 text-gray-800">Use around <span class="font-bold" >23</span> images or videos.</p>
        </div>

      </div>
    </div>
  </div>
  </div>
    <!-- <div class="panel-group pl-0" id="accordion" data-collapse-type="manual">
          <div class="panel-heading">
            <div class="panel-title pb-3">
              <a
                data-toggle="collapse"
                aria-expanded="true"

                href="#page_title"
              >
                <span><i class="fa fa-angle-right"></i>Title</span>

              </a>
            </div>
          </div>

          <div id="page_title" class="show panel-collapse collapse collapse in">

    <div
      class="d-flex justify-content-around flex-wrap mt-3 progress_bar_content"
    >
      <div class="content-data text-center text-base">
        <h4 >Blog Tone </h4>
        <i v-if="emotions==='anger'" v-tooltip.top-center="'anger'" class="far fa-angry anger text-lg text-anger cursor-pointer	"></i>
        <i v-if="emotions==='fear'" v-tooltip.top-center="'fear'" class="far fa-laugh-squint text-fear text-lg cursor-pointer	"></i>
        <i v-if="emotions==='joy'" v-tooltip.top-center="'joy'" class="far fa-laugh-squint text-joy text-lg cursor-pointer	"></i>
        <i v-if="emotions==='love'" v-tooltip.top-center="'love'" class="far fa-heart text-love text-lg cursor-pointer	"></i>
        <i v-if="emotions==='sadness'" v-tooltip.top-center="'sadness'" class="far fa-sad-cry_icon_emoji text-sadness text-lg cursor-pointer	"></i>
        <i v-if="emotions===''" class="far fa-laugh-squint  text-lg text-default_color cursor-pointer	"></i>

      </div>
      <div class="content-data text-center text-base">
        <h4>Sentiment</h4>
        <i v-if="sentiments==='NEG'"  v-tooltip.top-center="'Negative'"  class="far fa-thumbs-down text-custom_hand text-lg cursor-pointer	"></i>
        <i v-if="sentiments==='NEU'"   v-tooltip.top-center="'Neutral'" class="fal fa-thumbs-up text-custom_hand text-lg cursor-pointer	"></i>
        <i v-if="sentiments==='POS'" v-tooltip.top-center="'Possitive'" class="far fa-thumbs-up text-custom_hand text-lg cursor-pointer	"></i>
        <i v-if="sentiments===''"   class="far fa-thumbs-up text-lg cursor-pointer	"></i>
      </div>
      <div class="content-data text-center text-base text-base">
        <div >
          <h4>Word Count</h4>
          <div class="d-flex align-items-center word_count">
            <i class="fas fa-dot-circle costom-icon"></i>
            <h3 class="text-base">{{this.getWordsCount}}</h3>
          </div>
        </div>
        <p class="text-xs">Use More than 2500</p>
      </div>
      <div class="content-data text-center">
        <div>
          <h4>Read Time</h4>
          <div class="d-flex align-items-center word_count">
            <i class="fas fa-dot-circle ml-0" style="color: red"></i>
            <h3 class="text-base">{{readTime}} Min</h3>
          </div>
        </div>
        <p class="text-xs">Recomended 7 min</p>
      </div>
    </div>
    <div
      class="d-flex justify-content-around flex-wrap mt-4 progress_bar_content heading-section"
    >
      <div class="content-data text-center">
        <h3 class="text-base">Heading Count</h3>
        <div class="d-flex justify-content-center align-items-center word_count">
          <i class="fas fa-dot-circle" style="color: green"></i>
          <h3 class="text-base">{{headingCount}}</h3>

        </div>

        <p>Use around 38</p>
      </div>
      <div class="content-data text-center">
        <h3 class="text-base">Media Count</h3>
        <div class="d-flex align-items-center justify-content-start word_count">
          <i class="fas fa-dot-circle" style="color: green"></i>
          <h3 class="text-base">{{mediaCount}}</h3>
        </div>

        <p>Use around 23</p>
      </div>
    </div>
  </div>

    </div> -->
    <!-- <div class="flex justify-between flex-wrap content-outer">

      <div class="w-47 mb-3 rounded-lg	cursor-pointer m-15 blog_tone_section" v-tooltip.top-center="emotions">
        <div class="m-4">
        <i v-if="emotions==='anger'"  class="far fa-angry anger text-xl	cursor-pointer	anger_emoji"></i>
        <i v-if="emotions==='fear'"class="far fa-laugh-squint  text-xl	 cursor-pointer	fear_emoji"></i>
        <i v-if="emotions==='joy'"  class="far fa-laugh-squint  text-xl	 cursor-pointer	joy_emoji"></i>
        <i v-if="emotions==='love'" class="far fa-heart  text-xl	 cursor-pointer love_emoji	"></i>
        <i v-if="emotions==='sadness'"  class="far fa-sad-cry  text-xl	 cursor-pointer sadness_emoji"></i>
        <i v-if="emotions==='surprise'"  class="far fa-surprise  text-xl	 cursor-pointer surprise_emoji"></i>
        <i v-if="emotions===''" class="far fa-meh  text-xl	 text-default_color cursor-pointer default_emoji"></i>
        <h4 class="mt-2.5 text-sm	">Blog Tone </h4>
        </div>
      </div>
      <div class="w-47  mb-3 rounded-lg	cursor-pointer m-15 sentiment_section"  v-tooltip.top-center="sentiments" >
         <div class="m-4">
        <i v-if="sentiments==='NEG'"  class="far fa-thumbs-down custom_hand_emoji text-xl	 cursor-pointer	"></i>
        <i v-if="sentiments==='NEU'"  class="fal fa-thumbs-up custom_hand_emoji text-xl cursor-pointer	"></i>
        <i v-if="sentiments==='POS'" class="far fa-thumbs-up custom_hand_emoji text-xl cursor-pointer	"></i>
        <i v-if="sentiments===''"   class="far fa-thumbs-up text-xl	 cursor-pointer	"></i>
          <h4 class="mt-2.5 text-sm	">Sentiment</h4>
         </div>
      </div>

       <div class="w-47 mb-3 rounded-lg	m-15 word_cont_section">
             <div class="m-4">
            <h3 class="text-base">{{this.getWordsCount}}</h3>
            <h4 class="mt-1 text-sm	">Word Count</h4>
            <p class="text-xs mt-1">Use More than 2500</p>
             </div>
      </div>

      <div class="w-47 mb-3 rounded-lg	m-15	read_time_section">
         <div class="m-4">
            <h3 class="text-base">{{readTime}}</h3>
            <h4 class="mt-1 text-sm	">Read Time</h4>
            <p class="text-xs mt-1">Recomended 7 min</p>
         </div>
      </div>

     <div class="w-47  rounded-lg	m-15	heading_count_section">
       <div class="m-4">
          <h3 class="text-base ">{{headingCount}}</h3>
          <h3 class="mt-1 text-sm		font-medium	">Heading Count</h3>
          <p class="text-xs mt-1">Use around 38</p>
       </div>
      </div>
      <div class="w-47 rounded-lg	m-15 media_conunt_section">
        <div class="m-4">
          <h3 class="text-base">{{mediaCount}}</h3>
         <h3 class="mt-1 text-sm font-medium">Media Count</h3>
        <p class="text-xs mt-1">Use around 23</p>
        </div>
      </div>
    </div> -->

      <!-- <div class="all_content_outer">
        <div class="flex justify-between flex-wrap"> -->
          <!-- <div class="flex justify-between items-center  p-4	items-baseline	 content_outer_seo">
            <div >
                 <h4 class="text-sm">Blog Tone </h4>
            </div>
            <div class="flex justify-between items-baseline w-1/4 m-2.5">
              <h3 class="text-sm	">{{emotions}}</h3>
               <i v-if="emotions==='anger'"  class="far fa-angry anger text-xl	cursor-pointer	anger_emoji"></i>
              <i v-if="emotions==='fear'"class="far fa-laugh-squint  text-xl	 cursor-pointer	fear_emoji"></i>
             <i v-if="emotions==='joy'"  class="far fa-laugh-squint  text-xl	 cursor-pointer	joy_emoji"></i>
               <i v-if="emotions==='love'" class="far fa-heart  text-xl	 cursor-pointer love_emoji	"></i>
             <i v-if="emotions==='sadness'"  class="far fa-sad-cry  text-xl	 cursor-pointer sadness_emoji"></i>
             <i v-if="emotions==='surprise'"  class="far fa-surprise  text-xl	 cursor-pointer surprise_emoji"></i>
              <i v-if="emotions===''" class="far fa-meh  text-xl	 text-default_color cursor-pointer default_emoji"></i>
            </div>
          </div>
              <div class="flex justify-between items-center w-1/2 p-4	items-baseline	 content_outer_seo">
            <div >
                 <h4 class="text-sm">Sentiment </h4>
            </div>
            <div class="flex justify-between items-baseline w-1/4 ">
              <h3 class="text-sm	">{{sentiments}}</h3>
                <i v-if="sentiments==='NEG'"  v-tooltip.top-center="'Negative'"  class="far fa-thumbs-down text-custom_hand text-lg cursor-pointer	"></i>
                <i v-if="sentiments==='NEU'"   v-tooltip.top-center="'Neutral'" class="fal fa-thumbs-up text-custom_hand text-lg cursor-pointer	"></i>
                <i v-if="sentiments==='POS'" v-tooltip.top-center="'Possitive'" class="far fa-thumbs-up text-custom_hand text-lg cursor-pointer	"></i>
                <i v-if="sentiments===''"   class="far fa-thumbs-up text-lg cursor-pointer	"></i>
            </div>
          </div> -->

             <!-- <div class="p-3 content_outer_seo word_count">
                <div >
                 <h4 class="text-base	font-semibold	">Word Count</h4>
                  <p class="text-xs	mt-1.5 text-gray-800">Use More than</p>
                  <p  class="text-sm font-semibold text-gray-800 mt-1.5">{{getWordsCount}}</p>
            </div>
            <div >
                <h3 class="text-lg">2500</h3>
            </div> -->
            <!-- <div >

            </div> -->
          <!-- </div>
           <div class="p-3 content_outer_seo read_time rounded">
            <div>
              <div class="flex justify-between items-center">
              <h4 class="text-base	font-semibold	">Read Time</h4>
              <p class="text-xs	mt-1.5 text-gray-800">Recomended</p>
              <p  class="text-sm font-semibold text-gray-800 mt-1.5">7 mins</p>
            </div>
            <div class="flex items-end read_time_content flex-col justify-center">
              <h3 class="text-lg mr-1 text-cyan-700 ">{{readTime}}</h3>
              <span class="text-xs">mins</span>
            </div> -->
            <!-- <div class="flex items-end read_time_content flex-col justify-center">

            </div> -->
          <!-- </div>
            <div class=" p-3 content_outer_seo heading_count rounded">
              <div>
                <div class="flex justify-between items-center">
                <h4 class="text-base	font-semibold	">Heading Count</h4>
                <p class="text-xs	mt-1.5 text-gray-800">Use around</p>
                <p  class="text-sm font-semibold text-gray-800 mt-1.5">28</p>
              </div>

              </div> -->

              <!-- <div >
                <h3 class="text-lg">{{headingCount}}</h3>
              </div> -->
          <!-- </div>
            <div class=" p-3 content_outer_seo media_count rounded">
            <div>
               <div class="flex justify-between items-center">
              <h4 class="text-base	font-semibold	">Media Count</h4>
              <p class="text-xs	mt-1.5 text-gray-800">Use around</p>
              <p  class="text-sm font-semibold text-gray-800 mt-1.5">23</p>
            </div>

            </div> -->

              <!-- <div >
                <h3 class="text-lg">{{mediaCount}}</h3>
              </div> -->
<!--
          </div>
        </div> -->

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HelperContent',
  props:{
    blogPost:{type:String,required:true},
    emotions:{type:String,required:true},
    headingCount:{type:Number,required:true},
    mediaCount:{type:Number,required:true},
    sentiments:{type:String,required:true},
    readTime:{type:Number,required:true},
    readTimeText:{type:String,required:true}
  },
  computed: {
    ...mapGetters([
      'getWordsCount'
    ]),
  },
}
</script>

<style lang="scss" scoped >
.content_outer_seo{
  border: 1px solid rgb(242,242,242);
  max-width: 49%;
  background-color: rgb(251,251,251);
  width: 100%;;
  margin-bottom: 10px;

  strong{
    color: #808080;
  }
  &.word_count{
    h4,h3{
      color: #00b050;
    }
}
&.heading_count{
    h4,h3{
     color: #8064a2
    }
}
&.read_time{
 h4,h3{
   color: #e26b0a;
 }


}
&.media_count{
    h4,h3{
         color: #538dd5;
    }
}
}
</style>
