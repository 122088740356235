<template>
  <!--Active Users by Hours table-->

  <div class="active_users_container">
    <chart-render
      :id="'active_users_by_days_highlight'"
      :options="activeUsersByDays()"
      :ref_value="'active_users_by_days'"
      :is_content_available="!isActiveDaysAvailable"
      :is_best_day="true"
      :best_day="active_users_days.highest_day"
    ></chart-render>
  </div>
</template>

<script>
import {
  splineCredits,
  splinePlotOptions,
  splineTooltip
} from '../../common/helper'
import ChartRender from '../../common/ChartRender'

export default {
  components: { ChartRender },
  props: {
    active_users_days: {
      default: []
    }
  },
  data () {
    return {
      active_hour: '00:00'
    }
  },
  computed: {
    isActiveDaysAvailable () {
      return Object.entries(this.active_users_days).length > 0
    }
  },
  methods: {
    activeUsersByDays () {
      let users = []
      const days = []
      Object.entries(this.active_users_days).forEach((item, index) => {
        if (index <= 6) {
          days.push([item[0]])
          users.push([item[0], item[1]])
        }
      })

      if (!this.isActiveDaysAvailable) {
        users = [
          ['Monday', 355],
          ['Tuesday', 788],
          ['Wednesday', 1500],
          ['Thursday', 600],
          ['Friday', 2500],
          ['Saturday', 1600],
          ['Sunday', 3900]
        ]
      }
      console.log(users)

      return {
        credits: splineCredits(),

        tooltip: {
          headerFormat: '<div class="chart_tooltip engagement_tooltip">',
          pointFormat:
            '<div class="engagement_point"><h3 class="date">{series.name}</h3><span>{point.y:f}</span></div>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 20,
            color: 'rgba(58, 69, 87, 0.15)'
          }
        },
        chart: {
          type: 'spline',
          height: 300,
          // type: 'area',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          }
        },
        legend: {
          enabled: false
        },

        colors: ['#989eb5'],
        title: false,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          categories: days
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        // plotOptions: splinePlotOptions(),

        plotOptions: {
          series: {
            lineColor: '#6173ff',
            marker: {
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: '#6173ff'
            }
          }
        },
        series: [
          {
            name: 'Active Users',
            data: users
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
