var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-modal-content"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"top-content"},[_c('h2',[_vm._v("ContentStudio Suite - Special Bundle Offer")]),_c('div',{staticClass:"line"}),_c('h3',{staticClass:"price_tag mb-3"},[_vm._v("Special deal expires in")]),_c('div',{staticClass:"countdown"},[_c('span',{staticClass:"countdown-span"},[_c('Countdown',{attrs:{"deadline":"November 22, 2019 11:59 GMT"}})],1)]),_c('div',{staticClass:"line mb-3 mt-4"}),_c('p',[_vm._v("We are giving our lifetime users a special opportunity to upgrade their accounts massively without the hefty monthly pricing. This deal is the result of Bruce Kraft’s persistent efforts in persuading us to offer such a massive discount. Everyone should be thankful to him. :) ")]),_c('h3',{staticClass:"price_tag mt-3 mb-3"},[_vm._v("Let’s jump right in and see what’s included…")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"price-box-single price-box-single-special"},[_c('div',{staticClass:"box-inner"},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"desc"},[_vm._v("If you have purchased the add-ons in the past, you'll only need to pay the remaining amount. Below you can see your past purchases:")]),_c('div',{staticClass:"billing-history-list",staticStyle:{"margin-bottom":"30px"}},[(_vm.orders.length > 0)?_c('div',{staticClass:"table-prices-wrapper"},[_c('p',{staticClass:"desc mt-0 mb-4"},[_vm._v("Here's your payment detail.")]),_c('div',{staticClass:"table-prices table-prices-special"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.orders),function(order){return (
                          order.product_name &&
                          parseInt(order.fee) + parseInt(order.earnings) > 0
                        )?_c('tr',[_c('td',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(order.product_name))]),_c('td',[(order.created_at)?[_vm._v(" Paid on : "),_c('strong',[_vm._v(_vm._s(order.created_at))])]:_vm._e()],2),_c('td',{staticClass:"text-right",staticStyle:{"width":"150px"}},[_vm._v(" $"+_vm._s(parseInt(order.fee) + parseInt(order.earnings))+" ")])]):_vm._e()}),0)])]),_c('p',{staticClass:"desc"},[_vm._v("You have paid "),_c('strong',[_vm._v("$"+_vm._s(_vm.amount_to_deduct))]),_vm._v(" already.")]),_c('p',{staticClass:"desc"},[_vm._v("You'll have to pay "),_c('strong',[_vm._v("$"+_vm._s(_vm.getExistingLifetimeCustomer))]),_vm._v(" more.")])]):_vm._e()]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"mb-4 mb-lg-0 col-lg-5 box-left d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"left-inner"},[_vm._m(1),_c('h2',[_c('span',{staticClass:"price"},[_vm._v("$"+_vm._s(_vm.getExistingLifetimeCustomer))]),_c('span',{staticClass:"duration"},[_vm._v("/Lifetime")])]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.specialOfferPurchase(
                      'existing_user',
                      _vm.getExistingLifetimeCustomer,
                    )}}},[_c('span',[_vm._v("Buy Now")])])])]),_vm._m(2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"325px"}},[_vm._v("Name")]),_c('th',{staticStyle:{"width":"250px"}},[_vm._v("Date")]),_c('th',[_vm._v("Amount Paid")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"cut_price"},[_c('span',{staticClass:"price"},[_vm._v("$5608")]),_c('span',{staticClass:"duration"},[_vm._v("/Year")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-7 box-right"},[_c('ul',{staticClass:"features"},[_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("ContentStudio Large Agency ( Version Bee) plan with:")])]),_c('li',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-circle"}),_vm._v("50 Workspaces ")]),_c('li',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-circle"}),_c('strong',{staticClass:"mr-1"},[_vm._v("Unlimited")]),_vm._v(" social accounts ")]),_c('li',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-circle"}),_vm._v("50 users (team/client) ")]),_c('li',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-circle"}),_vm._v("50 blog accounts ")]),_c('li',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-circle"}),_vm._v("and everything else according to the large agency plan on our website. ")]),_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("ContentStudio - Analytics (advanced/full)")])]),_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("ContentStudio - Influencers")])]),_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("ContentStudio - Insights")])]),_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("ContentStudio - Social Inbox")])]),_c('li',{staticClass:"d-flex align-items-start"},[_c('i',{staticClass:"fa fa-check"}),_c('span',[_vm._v("Replug (Basic Plan)")])])])])
}]

export { render, staticRenderFns }