var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_field social_content_box social_content_box_multi",class:{
    in_active: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab',
    editor_hover: _vm.isFileHover,
  },attrs:{"id":"youtube_sharing_box"},on:{"dragleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$store.dispatch('leaveFileDragOver', { event: $event, type: 'Youtube' })},"dragover":function($event){return _vm.$store.dispatch('onFileDragOver', { event: $event, type: 'Youtube' })},"drop":function($event){return _vm.$store.dispatch('onFileDrop', { event: $event, type: 'Youtube' })}}},[_vm._m(0),(_vm.isFileHover)?_c('div',{staticClass:"drag_content"},[_c('p',[_vm._v("Drag files here")])]):_vm._e(),_c('div',{staticClass:"text_content text_content_main"},[_c('textarea-autosize',{ref:"youtube_sharing_message",staticClass:"content_textarea",class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab' },attrs:{"id":"youtube_sharing_message","spellcheck":"false","min-height":55},on:{"input":function($event){return _vm.handleRerender()}},nativeOn:{"keydown":[function($event){return _vm.handleKeysOnPopup.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==66)return null;if(!$event.ctrlKey)return null;return _vm.sharingMessageFormatting('Youtube', 'bs')},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==73)return null;if(!$event.ctrlKey)return null;return _vm.sharingMessageFormatting('Youtube', 'is')}],"input":function($event){return _vm.sharingMessageChanges($event, 'Youtube')},"paste":function($event){return _vm.sharingMessageChanges($event, 'Youtube')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.sharingMessageChanges($event, 'Youtube')},"focus":function($event){return _vm.focusSharingBox('Youtube')},"click":function($event){return _vm.focusSharingBox('Youtube')}}}),_c('div',{staticClass:"clone_txt",attrs:{"id":"clone_txt_youtube_tab"}},[_c('span',{staticClass:"desc",class:{
          hide: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab',
        }}),_c('span',{staticClass:"in_active desc",class:{
          hide: _vm.getSocialSharingSelection.activeTab === 'youtube_tab',
        },on:{"click":function($event){_vm.focusSharingBox('Youtube'),
            _vm.textareaFocus(_vm.$refs.youtube_sharing_message)}}}),_c('span',{staticClass:"exceed",class:{
          hide: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab',
        }})]),_c('hashtag-suggestions',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideHashtag),expression:"hideHashtag"}],attrs:{"type":"Youtube"}}),(_vm.shouldLockBasedOnSubAddons('caption_generation'))?[_c('div',{staticClass:"text_content_bottom"},[(_vm.getSocialSharingSelection.activeTab == 'youtube_tab')?_c('button',{staticClass:"btn btn-studio-theme-space caption-btn",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('upgrade-plan-dialog')}}},[_c('i',{staticClass:"fas fa-lock mr-2",staticStyle:{"font-size":"0.8rem"}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.getLockBasedOnSubAddonsMessage('caption_generation'),
                placement: 'top-center',
              }),expression:"{\n                content: getLockBasedOnSubAddonsMessage('caption_generation'),\n                placement: 'top-center',\n              }"}]},[_vm._v(" Generate Caption ")])]):_vm._e()])]:[_c('div',{staticClass:"text_content_bottom"},[(_vm.getSocialSharingSelection.activeTab == 'youtube_tab')?_c('button',{staticClass:"btn btn-studio-theme-space caption-btn",attrs:{"disabled":_vm.getPublishLoaders.fetchYoutubeShortLinks ||
          !_vm.getYoutubeSharingDetails.url},on:{"click":function($event){return _vm.fetchAiCaption(_vm.getYoutubeSharingDetails.url, 'Youtube')}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.getYoutubeSharingDetails.url
              ? null
              : 'Caption generation button is disabled until a valid URL is put in the composer box',
            placement: 'top-center',
          }),expression:"{\n            content: getYoutubeSharingDetails.url\n              ? null\n              : 'Caption generation button is disabled until a valid URL is put in the composer box',\n            placement: 'top-center',\n          }"}]},[_vm._v(" Generate Caption ")])]):_vm._e()])]],2),_c('options',{class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab' },attrs:{"type":"Youtube"}}),(
      (_vm.getYoutubeSharingDetails.video &&
        _vm.getYoutubeSharingDetails.video.link) ||
      _vm.getPublishLoaders.upload_youtube_sharing_video
    )?_c('video-section',{class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'youtube_tab' },attrs:{"type":"Youtube","details":_vm.getYoutubeSharingDetails}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_social_tag youtube_bg"},[_c('i',{staticClass:"fab fa-youtube youtube-b"})])
}]

export { render, staticRenderFns }