<template>
  <div class="profile_picture" :class="[flex_class, type + '-color']">
    <div class="picture_block picture_block_background">
      <template v-if="type === 'shopify'">
        <div class="icon shopify_icon"></div>
      </template>
      <template v-else-if="type === 'webflow'">
        <div class="icon webflow_icon"></div>
      </template>
      <div v-else class="icon">
        <template v-if="type === 'tiktok'">
          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 450 730" style="width: 10px;">
            <path fill="#ffffff" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
          </svg>
        </template>
        <i v-else :class="'cs-' + type"></i>
      </div>
      <div
        class="img"
        :style="{ 'background-image': channelBackgroundImage(account, type) }"
      ></div>
    </div>
    <div class="text_block">
      <p v-b-tooltip.top="account_name.length > 25 ? account_name : ''" class="text">{{ getModifiedChannelName(account_name) }}</p>
      <p class="sub_text">
        <template v-if="integration === 'blog'"> Blog </template>
        <template v-else>
          {{ channelType(account_type) }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {},
    type: {},
    flex_class: {
      default: 'd-flex align-items-start',
    },
    account_name: {},
    account_type: {},
    integration: {},
  },
  methods: {},
}
</script>

<style scoped></style>
