<template>
  <div class="flex w-full mb-2">
    <SavedReplyModal
      :id="`savedReplyModal${state.customId}`"
      :active-inbox-detail="state.activeInboxData"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>
    <div class="mt-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="25"
        height="25"
        :src="
          state.activeInboxData.inbox_details.posted_by.image
            ? state.activeInboxData.inbox_details.posted_by.image
            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
        "
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
    </div>
    <div class="w-full flex flex-column m-1">
      <div
        class="w-full flex flex-column border rounded-lg"
        :class="{ 'select-none pointer-events-none': state.loader }"
      >
        <div class="flex flex-col">
          <b-form-textarea
            :id="`mainComment${state.customId}`"
            ref="comment"
            v-model="state.commentReply"
            class="py-2 !shadow-none !overflow-y-hidden !border-0"
            placeholder="Add a comment..."
            name="addComment"
            max-rows="5"
            autofocus
          >
          </b-form-textarea>
          <div class="flex justify-end items-center mr-2">
            <div
              v-show="state.loader"
              class="w-full flex justify_end items-center mb-2"
            >
              <clip-loader
                class="d-inline-block align-middle mr-1"
                color="rgba(152, 158, 181, 0.8)"
                :size="'12px'"
              ></clip-loader>
            </div>
            <div class="relative self-end mb-2">
              <div
                v-floatingtip.hover.bottom="'Add an emoji'"
                @click="state.emojiHandler = !state.emojiHandler"
              >
                <i
                  class="fal fa-smile-beam attachment_btn !cursor-pointer font-weight-normal font-0-95rem mx-1 mb-1"
                >
                </i>
              </div>
              <CstEmojiPicker
                position="top-start"
                :is-open="state.emojiHandler"
                @on-select="(unicode) => onEmojiSelect(unicode)"
                @handle-click="state.emojiHandler = false"
              >
              </CstEmojiPicker>
            </div>
            <div class="self-end mb-2">
              <i
                v-floatingtip.bottom="'Saved Replies'"
                class="icon-Save attachment_btn !cursor-pointer mx-1"
                @click="$bvModal.show(`savedReplyModal${state.customId}`)"
              />
            </div>
            <div class="self-end">
              <label
                v-floatingtip.bottom="'Add Attachment'"
                class="cursor-pointer"
                :for="state.customId"
              >
                <i class="attachment_btn icon-Image !cursor-pointer ml-1" />
              </label>
              <input
                :id="state.customId"
                ref="inputFile"
                accept="image/png, image/gif, image/jpeg"
                class="d-none"
                type="file"
                @change="attachMedia"
              />
            </div>
          </div>
        </div>
        <div class="w-full flex justify-start bg-[#F9FAFB]">
          <div
            v-if="state.attachment !== null"
            class="flex flex-col group relative w-24 m-2"
          >
            <img
              :id="`output${state.customId}`"
              v-floatingtip.right="state.attachment.name"
              src=""
              class="w-24 h-24 rounded cursor-pointer border"
              alt=""
            />
            <i
              v-floatingtip.right="'Remove Image'"
              class="cs-cross text-xxs absolute hidden group-hover:block top-0.5 right-0.5 text-center w-4 h-4 bg-[#004182] text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer rounded-full"
              @click="removeMedia"
            ></i>
            <i
              v-floatingtip.right="'Preview Image'"
              class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
              @click="previewMediaImage(state.imageLink)"
            ></i>
          </div>
        </div>
      </div>
      <div
        v-if="state.commentReply || state.attachment"
        class="flex justify-center items-center py-1 w-12 !rounded-[14px] mt-1 bg-[#0A66C2] hover:bg-[#004182] cursor-pointer"
        @click="handlePost"
      >
        <p class="font-semibold text-white text-sm">Post</p>
      </div>
    </div>
  </div>
</template>

<script>
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import { ref, computed, nextTick, reactive, watch, onMounted } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import SavedReplyModal from '../../savereplies/SavedReplyModal'

export default {
  name: 'LinkedInReplyBox',
  components: {
    SavedReplyModal,
    CstEmojiPicker,
  },
  props: {
    inboxData: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: 'inputFile',
      required: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputFile = ref(null)
    const state = reactive({
      activeInboxData: computed(() => props.inboxData),
      customId: computed(() => props.id),
      commentReply: '',
      attachment: null,
      showReplyBox: false,
      showComment: false,
      gotNestedComments: true,
      media: null,
      imageLink: null,
      emojiHandler: false,
      loader: computed(() => props.loader),
    })
    const comment = ref(null)

    onMounted(() => {
      EventBus.$on('reset-replyBox', () => {
        if (inputFile?.value) {
          inputFile.value.value = null
        }
        state.commentReply = ''
        state.attachment = null
        state.showReplyBox = false
        state.showComment = false
        state.gotNestedComments = true
        state.media = null
        state.imageLink = null
      })
    })

    watch(
      () => state.activeInboxData.element_details.element_id,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          state.commentReply = ''
          state.attachment = null
          state.showReplyBox = false
          state.showComment = false
          state.gotNestedComments = true
          state.media = null
          state.imageLink = null
        }
      }
    )

    const onEmojiSelect = async (emojiUnicode) => {
      // Get the current cursor position
      await nextTick()
      const cursorPosition = comment.value.selectionStart

      state.commentReply =
        state.commentReply.slice(0, cursorPosition) +
        emojiUnicode +
        state.commentReply.slice(cursorPosition)
    }

    /**
     * @description apply saved replies
     * @param applyText
     */
    const applySavedReply = (applyText) => {
      state.commentReply = applyText
    }

    /**
     * @description attach images(only1)
     */
    const attachMedia = async (event) => {
      if (event.target.files.length > 0) {
        state.attachment = event.target.files[0]
        await nextTick()
        const output = document.getElementById(`output${state.customId}`)
        output.src = state.imageLink = URL.createObjectURL(
          event.target.files[0]
        )
      } else {
        this.removeMedia()
      }
    }

    /**
     * @description remove the attached image
     */
    const removeMedia = () => {
      state.attachment = null
      state.media = null
      state.imageLink = null
      inputFile.value.value = null
    }

    /**
     * @description preview attached image
     * @param image
     */
    const previewMediaImage = (image) => {
      console.log('openImageLightBox', image)
      if (image) {
        const imageData = {
          link: image,
          visible: true,
        }
        emit('preview-image', imageData)
      }
    }

    const handlePost = () => {
      if (!state.loader) {
        emit(
          'post-comment',
          state.commentReply ? state.commentReply : null,
          state.attachment ? state.attachment : null
        )
      }
    }
    return {
      comment,
      inputFile,
      state,
      applySavedReply,
      attachMedia,
      removeMedia,
      previewMediaImage,
      handlePost,
      onEmojiSelect,
    }
  },
}
</script>
