<template>
  <div>
    <b-dropdown
      :disabled="getReportsServiceCalls.export || disabled"
      variant="studio-theme"
      id="dropdown-right"
      class="studio-theme-dropdown studio-theme-dropdown-blue dropdown-size-small pr-0"
      no-caret
    >
      <template v-if="getReportsServiceCalls.export" slot="button-content">
        <div class="opacity-70" style="display: flex; align-items: center">
          <span>Exporting </span>
          <clip-loader
            class="ml-2"
            :color="'#ffffff'"
            :size="'14px'"
          ></clip-loader>
        </div>
      </template>
      <template v-else slot="button-content">
        <i class="icon-download-cs studio-theme-icon"></i>
        <span class="capitalize_text">Export</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item @click.prevent="saveReport()"
        >Export as PDF</b-dropdown-item
      >
      <b-dropdown-item @click="sendReportByEmail()">Send PDF</b-dropdown-item>
      <b-dropdown-item @click="schedulePDF()">Schedule PDF</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import textHelper from '@common/lib/text-helper'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'ExportButton',
  props: {
    accounts: {
      default: []
    },
    start_date: {},
    end_date: {},
    type: {},
    disabled: false
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls', 'getActiveWorkspace'])
  },
  methods: {
    ...mapActions(['renderReportsService']),
    async saveReport () {
      const res = await this.renderReportsService({
        date: `${this.start_date} - ${this.end_date}`,
        accounts: this.accounts,
        type: this.type,
        action: 'render'
      })

      // download the report for the user.

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/pdf' })
      )
      const link = document.createElement('a')
      link.href = url

      // improve the file download name
      const date = textHelper.onlyDateStringIntlTime(
        `${this.start_date} - ${this.end_date}`
      )
      const fileName =
        textHelper.ucFirst(this.type) +
        ` Report (${textHelper.ucFirst(
          this.getActiveWorkspace.slug
        )}) - ${date}.pdf`
      link.setAttribute('download', fileName)
      link.setAttribute('target', '_blank')
      link.download = fileName
      document.body.appendChild(link)
      link.click()
    },
    schedulePDF () {
      EventBus.$emit('schedule-report', {accounts: this.accounts, network: this.type})
      $('#scheduleReport').modal('show')
    },
    sendReportByEmail () {
      $('#sendReportByEmail').modal('show')
      EventBus.$emit(
        'send-report-by-email',
          {
            accounts: this.accounts,
            network: this.type,
            startDate: this.start_date,
            endDate: this.end_date
          }
      )
    }
  }
}
</script>
