export default {
  state: {
    shares: {
      data: []
    },
    loader: {
      sharerLoader: false
    }
  },
  actions: {
    setSharerData ({ commit }, value) {
      commit('setSharerData', value)
    },
    setSharerLoader ({ commit }, status) {
      commit('setSharerLoader', status)
    }
  },
  mutations: {
    setSharerData (state, value) {
      state.shares.data = value
    },
    setSharerLoader (state, status) {
      state.loader.sharerLoader = status
    }
  },
  getters: {
    getSharerData: (state) => {
      return state.shares.data
    },
    getTwitterSharesLoader: (state) => {
      return state.loader
    }
  }
}
