<template>
  <div class="input_field">
    <div
      class="text-center"
      :class="{
        warning_box: getContentCategoryAvailableSlot.available,
        error_box: !getContentCategoryAvailableSlot.available,
      }"
      data-cy="slots-note"
    >
      <template>
        <template>
          <template v-if="getContentCategoryAvailableSlot.available">
            <p
              ><strong>Note:</strong>
              <template v-if="getContentCategoryAvailableSlot.scheduled">
                Your post scheduled for
                {{getContentCategoryAvailableSlot.slot_time.date |
                                convert-to-weekday-with-time }}
                ({{
                  getUtcFromWorkspaceTimeZoneFromNow(
                    getContentCategoryAvailableSlot.slot_time.date
                  )
                }}) from now.
              </template>
              <template v-else>
                <template
                  v-if="type && (type === 'evergreen' || type === 'csv')"
                >
                  Your first post will be published at
                </template>
                <template v-else> Your post will be published at </template>

                {{getContentCategoryAvailableSlot.slot_time.date |
                                convert-to-weekday-with-time }}
                ({{
                  getUtcFromWorkspaceTimeZoneFromNow(
                    getContentCategoryAvailableSlot.slot_time.date
                  )
                }}) from now according to your slot.

              </template>
            </p>
             <template>
                <small class="d-inline-flex font-italic">The delay in starting the campaign is because of the next available slot for the <strong>{{getContentCatgoryName}}</strong>  Content Category, if you want to start immediately either choose custom slots, regular intervals or add a slot in your selected content category.</small>
             </template>
          </template>

          <template v-else>
            You have not created any slots for your category. You need to add
            slots before you can add content to the category.
            <router-link :to="{ name: 'content_categories' }"
              >Click here to create slots.</router-link
            >
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    type: {
      default: ''
    }
  },
  computed: {
    ...mapGetters(['getContentCategoryAvailableSlot','getContentCatgoryName'])
  }
}
</script>
