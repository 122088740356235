<template>
  <span class="clock">
    <!--<div class="date">
            {{ formattedDate }}
        </div>-->
    <span class="time">
      {{ formattedTime }}
    </span>
    <!--<div class="timezone">
            {{ timezone.replace('_', ' ').replace('/', ' / ') }}
        </div>-->
  </span>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'Clock',
  props: {
    timezone: {
      type: String,
      default: 'Europe/Berlin',
    },
    dateFormat: {
      type: String,
      default: 'ddd MM/DD',
    },
    timeFormat: {
      type: String,
      default: 'HH:mm:ss',
    },
  },
  data() {
    return {
      dateTime: moment().tz(this.timezone),
    }
  },
  computed: {
    formattedDate() {
      if (this.dateTime) return this.dateTime.format(this.dateFormat)
    },
    formattedTime() {
      if (this.dateTime) return this.dateTime.format(this.timeFormat)
    },
  },
  created() {
    this.dateTime = moment.tz(this.timezone)
    this.tick()
  },
  methods: {
    tick() {
      setInterval(() => {
        this.dateTime = moment().tz(this.timezone)
      }, 1000)
    },
  },
}
</script>
