var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.itemVisibleStatus)?_c('div',{staticClass:"profile_picture d-flex align-items-start",class:_vm.getPlatformItemClasses(_vm.getPlatformMutatedType(_vm.type, _vm.platform))},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
      _vm.getPlatformName(_vm.getPlatformDetails(_vm.item, _vm.type, _vm.platform))
    ),expression:"\n      getPlatformName(getPlatformDetails(item, type, platform))\n    ",modifiers:{"top-center":true}}],staticClass:"picture_block picture_block_background"},[(_vm.type === 'shopify')?_c('div',{staticClass:"icon shopify_icon"}):(_vm.type === 'webflow')?_c('div',{staticClass:"icon webflow_icon"}):(_vm.type === 'tiktok')?_c('div',{staticClass:"icon tiktok_bg"},[_c('svg',{staticStyle:{"width":"10px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 612"}},[_c('path',{staticClass:"selected",attrs:{"fill":"#ffffff","d":"M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z","id":"svg_1","fill-opacity":"1"}})])]):_c('div',{staticClass:"icon",class:{ youtube_bg: _vm.type === 'youtube' }},[_c('i',{class:_vm.getPlatformIconClasses(_vm.getPlatformMutatedType(_vm.type, _vm.platform))})]),_c('div',{staticClass:"img",style:({
        'background-image': _vm.getBackgroundURLWithDefault(
          _vm.platformImage(_vm.getPlatformDetails(_vm.item, _vm.type, _vm.platform))
        ),
      })})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }