<template>
  <div>
    <!--.........if folder already exist...........-->
    <div
      class="dropdown-menu dropdown-menu-right feeds-no-cursor-pointer follow-feeds-dropdown-menu"
    >
      <template v-if="getFeederGroupList.length > 0 && !is_create_new_group">
        <li class="heading">Select Group</li>
        <ul class="inner follow-feeds-dropdown-menu__ul">
          <template v-for="(group, index) in getSortedFeederGroupList">
            <li
              :key="index"
              class="list_item follow-feeds-dropdown-menu__ul__li"
            >
              <span class="follow-feeds-dropdown-menu__ul__li__name">{{
                limitTextLength(group.name, 18)
              }}</span>
              <template v-if="isAddedToFolderAlready(group)">
                <button
                  class="btn btn-studio-theme-transparent"
                  :class="{
                    'disable_click feeds-disable-click': group.is_remove,
                  }"
                  @click="removeFeedFromGroup(group)"
                >
                  <i class="fa fa-times mr-1"></i>
                  <template
                    v-if="group.is_remove && state_of_loader === 'removing'"
                    >Removing</template
                  ><template v-else>Remove</template>
                  <span
                    v-if="group.is_remove && state_of_loader === 'removing'"
                    class="feeder-group-inline-block ml-2"
                  >
                    <clip-loader
                      :color="'#ea7070'"
                      :size="'14px'"
                    ></clip-loader>
                  </span>
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-studio-theme-transparent"
                  :class="{ 'disable_click feeds-disable-click': group.is_add }"
                  @click="addFeedToGroup(group)"
                >
                  <i class="fa fa-plus mr-1"></i>
                  <template v-if="group.is_add && state_of_loader === 'adding'"
                    >Adding</template
                  ><template v-else>Add</template>
                  <span
                    v-if="group.is_add && state_of_loader === 'adding'"
                    class="feeder-group-inline-block ml-2"
                  >
                    <clip-loader
                      :color="'#1ecb7b'"
                      :size="'14px'"
                    ></clip-loader>
                  </span>
                </button>
              </template>

              <!--<img @click.prevent="removeFolder(folder._id,folder.name, index)"  class="remove close_this" src="../../assets/article/delete_icon.svg" alt="remove topic">-->
            </li>
          </template>
        </ul>
      </template>
      <template v-else>
        <li class="heading new_folder">Create New Group</li>
        <div class="dd_footer close_this test">
          <div class="search-bar-input">
            <div class="search-bar-inner">
              <input
                v-model="getFeederGroupStore.name"
                type="text"
                placeholder="Enter Group Name e.g SEO, Marketing"
                @keyup.enter="validateAndStoreGroupFromDropdown"
              />
              <p
                v-if="state === 'group_exists'"
                class="error_message_about_group pt-1"
                >A group with that name already exists</p
              >
              <button class="clear-search-input">
                <i
                  v-if="
                    getFeederGroupStore.name &&
                    getFeederGroupStore.name.length >= 1
                  "
                  class="cs-cross"
                  @click="clearFeederGroupText"
                ></i>
              </button>
            </div>
          </div>

          <!--<div class="create-input">-->
          <!--<input type="text" v-model="getFeederGroupStore.name"-->
          <!--@keyup.enter="validateAndStoreGroupFromDropdown()"-->
          <!--placeholder="Enter Group Name e.g SEO, Marketing">-->
          <!--<p class="error_message_about_group pt-1" v-if="state ==='group_exists'">A group with that name-->
          <!--already exists</p>-->
          <!--<div class="feeds-dropdown-input-icon">-->

          <!--<i v-if="getFeederGroupStore.name && getFeederGroupStore.name.length >= 1"-->
          <!--class="remove far fa-times" @click="clearFeederGroupText()"></i>-->
          <!--&lt;!&ndash;<img  class="remove" src="../../assets/article/delete_icon.svg" alt="remove topic">&ndash;&gt;-->
          <!--</div>-->

          <!--</div>-->

          <div class="follow-feeds-dropdown-menu__create_group_buttons mt-3">
            <button
              :disabled="!getFeederGroupStore.name || loaders.folder_create"
              class="btn light_gray feeds-add-group-btn mr-2"
              :class="{
                'input-field-error': getFeederGroupValidations.fields.name,
              }"
              @click="validateAndStoreGroupFromDropdown"
            >
              <span>Create</span>
              <clip-loader
                v-if="loaders.folder_create"
                :color="'#ffffff'"
                :size="'12px'"
              ></clip-loader>
            </button>
            <button
              v-if="getFeederGroupList.length > 0"
              class="btn btn_loader loader_right follow-feeds-dropdown-menu__create_group_buttons__cancel"
              @click="is_create_new_group = false"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </template>
      <div v-show="!is_create_new_group" class="dd_footer close_this">
        <button
          class="btn light_gray feeds-add-group-btn"
          @click.prevent="isCreateNewGroup"
        >
          <i class="far fa-plus mr-2"></i>
          Create New Group
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'

export default {
  props: {
    /* eslint-disable */
    feed_id: {},
    removeFromFollowedList: {},
    addToFollowedList: {},
    /* eslint-enable */
  },
  data() {
    return {
      addFolderStatus: true,
      folderName: null,
      state: '',
      is_create_new_group: false,
      state_of_loader: '',
      loaders: {
        folder_create: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getFeederGroupStore',
      'getFeederGroupList',
      'getFeederGroupValidations',
    ]),
    getSortedFeederGroupList() {
      return sortBy(this.getFeederGroupList, ['name'], ['asc'])
    },
  },
  watch: {
    'getFeederGroupStore.name.length'(value) {
      if (this.state === 'group_exists') this.state = ''
    },
  },
  methods: {
    // ...mapActions([''])
    isCreateNewGroup() {
      this.clearFeederGroupText()
      this.is_create_new_group = true
    },
    clearFeederGroupText() {
      this.$set(this.getFeederGroupStore, 'name', '')
    },
    async validateAndStoreGroupFromDropdown() {
      const validationFields = this.getFeederGroupValidations.fields
      this.$set(
        validationFields,
        'name',
        this.requiredCheck(this.getFeederGroupStore.name)
      )
      const result = Object.keys(validationFields).every(
        (k) => validationFields[k] === false
      )
      if (result) {
        this.loaders.folder_create = true
        const res = await this.$store.dispatch('storeFeederGroup')
        this.loaders.folder_create = false
        console.log(res)
        if (res && res.data) {
          if (!res.data.status) {
            this.state = res.data.state
          } else {
            const groupId = res.data.data.uuid
            const groupItem = this.getFeederGroupList.find(
              (item) => item.uuid === res.data.data.uuid
            )
            this.is_create_new_group = false
            this.$set(groupItem, 'is_add', true)
            this.state_of_loader = 'adding'

            const feedResponse = await this.$store.dispatch('storeUserFeed', {
              feedId: this.feed_id,
              groupId: groupId,
            })
            console.log(feedResponse)
            if (feedResponse && feedResponse.data && feedResponse.data.data) {
              this.$store.dispatch('addUserFeedToGroup', feedResponse.data.data)
              this.addToFollowedList(this.feed_id)
              this.clearFeederGroupText()
            }
            this.$set(groupItem, 'is_add', false)
            this.state_of_loader = ''
          }
        }
      }
    },
    isAddedToFolderAlready(group) {
      if (group.user_feed_details) {
        const item = group.user_feed_details.find(
          (item) => item.feed_id === this.feed_id
        )
        return !!item
      }
      return false
    },
    async removeFeedFromGroup(group) {
      this.state_of_loader = 'removing'
      this.$set(group, 'is_remove', true)
      const feedResponse = await this.$store.dispatch('deleteUserFeed', {
        feedId: this.feed_id,
        groupId: group.uuid,
      })

      if (feedResponse && feedResponse.data && feedResponse.data.status) {
        await this.$store.dispatch('removeUserFeedFromGroup', {
          feed_id: this.feed_id,
          group_id: group.uuid,
        })
        this.removeFromFollowedList(this.feed_id)
      }
      console.log(feedResponse)
      this.$set(group, 'is_remove', false)
      this.state_of_loader = ''
    },
    async addFeedToGroup(group) {
      this.state_of_loader = 'adding'
      this.$set(group, 'is_add', true)

      console.log('add feed to group')
      const feedResponse = await this.$store.dispatch('storeUserFeed', {
        feedId: this.feed_id,
        groupId: group.uuid,
      })
      console.log(feedResponse)
      if (feedResponse && feedResponse.data && feedResponse.data.data) {
        await this.$store.dispatch('addUserFeedToGroup', feedResponse.data.data)
        this.addToFollowedList(this.feed_id)
      }
      this.$set(group, 'is_add', false)
      this.state_of_loader = ''
    },
  },
}
</script>
