<template>
  <div
    class="right_filter"
    v-if="getTopicContentType == 'twitter'"
    :class="{ disable_click: getTopicDisabled }"
  >
    <twitter-language></twitter-language>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TwitterLanguage from '../../filters/twitter/FilterTwitterLanguage'

export default {
  components: {
    TwitterLanguage
  },
  computed: {
    ...mapGetters(['getTopicContentType', 'getTopicDisabled'])
  },
  methods: {
    ...mapActions([])
  }
}
</script>
