import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getStatusClass, planHeadAttachment } from '../../lib/planner'

export default {
  computed: {
    ...mapGetters(['getPlans', 'getPlannerLoaders', 'getPublishSelection']),
    planItemsCount() {
      return this.getPlans.items.length
    },
    listingPostRangeText() {
      return `Showing 1 - ${this.planItemsCount} of  ${this.getPlans.total_item}`
    },
    isFetchingPlans() {
      return this.getPlannerLoaders.fetch_plans
    },
    isPlatformsSelected() {
      return this.getPublishSelection.platforms.selection.length
    },
    showInfiniteLoadingSectionForFeedView() {
      return (
        !this.showNullPlansSection &&
        this.getPlannerLoaders.plans_pagination_scroll
      )
    },
    showNullPlansSection() {
      return (
        this.isPlatformsSelected &&
        !this.planItemsCount &&
        this.getPlannerLoaders.plans_pagination_scroll === false
      )
    },
  },
  methods: {
    ...mapActions([]),

    getItemId(item) {
      return item._id
    },
    getEventId(item) {
      return 'week_view_' + item._id
    },
    isPublishedPost(item) {
      return getStatusClass(item) === 'published'
    },
    isFailedPost(item) {
      return getStatusClass(item) === 'failed'
    },
    getPlanAttachment(item) {
      const attachment = planHeadAttachment(item)
      if (attachment.url)
        return attachment.type === 'image'
          ? attachment.url
          : attachment.thumbnail
      return false
    },
    changePlanStatusActionVisibility(item) {
      return (
        getStatusClass(item) === 'reviewed' && item.permissions.change_status
      )
    },
    replacePlanStatusActionVisibility(item) {
      return (
        (item.article_automation_id || item.video_automation_id) &&
        !this.isPublishedPost(item) &&
        !this.isFailedPost(item)
      )
    },
    editPlanStatusActionVisibility(item) {
      return (
        (!this.isPublishedPost(item) || item.blog_reference) &&
        !item.retweet_selection
      )
    },
    clonePlanStatusActionVisibility(item) {
      return this.isPublishedPost(item) && !item.retweet_selection
    },
    removePlanStatusActionVisibility(item) {
      return item.permissions.delete_plan
    },
    async reschedulePlan(event) {
      const status = await this.$store.dispatch('reschedulePlan', event)
      if (!status) this.$refs.calendar.$emit('reload-events')
    },
    // lowerCaseStatuses (statuses) {
    //   let statusesData = []
    //   if (statuses) statuses.forEach(function (status) { statusesData.push(status.replace(' ', '_').toLowerCase()) })
    //   return statusesData
    // }
  },
}
