<template>
  <div class="onboarding flex justify-center items-center">
<!--    <OnboardingNavbar />-->
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'CreateOrganization',
  components: {

  },
  mounted () {
    // this.test();
  },
  methods: {
    // navigateTo (location) {
    //   this.$router.push({ name: location });
    // },
  }
}
</script>
