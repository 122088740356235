<template>
  <div>
    <div class="filter grid grid-cols-1 gap-1 w-100">
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{license.name}}
        </template>
        <template v-slot>
          <CstDropdownItem  @click="handleLicense({ code: 0,name: 'Any License' })" >
            Any License
          </CstDropdownItem>
          <CstDropdownItem  @click="handleLicense({ code: 4,name: 'Creative Commons' })" >
            Creative Commons
          </CstDropdownItem>
        </template>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length === 0" class="p-3">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else-if="posts && posts.length > 0">
        <div class="grid grid-cols-2 gap-2 mt-2">
          <template v-for="(post, index) in posts" >
                <div
                    :key="index"
                    :draggable="true"
                    class="img w-100 h-32 relative bg-cover bg-no-repeat bg-center rounded-lg bg-cs-white border cursor-pointer"
                    :style="{ 'background-image': 'url(' + backgroundFlickrImageURL(post) + ')' }"
                    @click="handleImagePreview(backgroundFlickrPreviewImageURL(post))" @dragstart="dragstartHandler($event, post)">
                  <span class="w-100 h-100 rounded-lg flex items-center justify-center opacity-0"><i class="far fa-eye text-lg text-white"></i></span>
                </div>
          </template>
        </div>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template slot="no-results">
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
      </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
      </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'

export default {
  name: "Flickr",
  components: {
    InfiniteLoading,
    CstDropdown,
    CstDropdownItem,
    NoResult
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    license: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  watch:{

  },
  methods:{
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'assistantData',
          JSON.stringify({
            id: ev.target.id,
            type: 'image',
            heading: '',
            media: this.backgroundFlickrPreviewImageURL(photo),
            p: photo.title || '',
            link: this.backgroundFlickrPreviewImageURL(photo)
          })
      )
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleLicense (value) {
      this.$emit('handle-change','license', value)
    },
    handleImagePreview(photo){
      this.$emit('handle-change','preview',photo)
    },
    backgroundFlickrImageURL (photo) {
      return (
          'https://farm' +
          photo.farm +
          '.staticflickr.com/' +
          photo.server +
          '/' +
          photo.id +
          '_' +
          photo.secret +
          '_m.jpg'
      )
    },
    backgroundFlickrPreviewImageURL (photo) {
      return (
          'https://farm' +
          photo.farm +
          '.staticflickr.com/' +
          photo.server +
          '/' +
          photo.id +
          '_' +
          photo.secret +
          '.jpg'
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.img:hover{
  box-shadow: 0 2px 15px 0 #b8bdd1;
  span{
    background: #00000037;
    opacity: 1!important;
  }
}
</style>