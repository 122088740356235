var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"mt-2 mb-0 mx-0 hr-color"}),_c('div',{staticClass:"flex items-center justify-end mx-2 px-1 py-2 text-base text-gray-800"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.loader),expression:"state.loader"}],staticClass:"w-full flex justify_end items-center"},[_c('clip-loader',{staticClass:"d-inline-block align-middle mr-1 mt-1",attrs:{"color":"rgba(152, 158, 181, 0.8)","size":'12px'}})],1),_c('div',{staticClass:"w-full flex justify-end items-center h-[2rem] rounded-lg"},[_c('i',{staticClass:"far fa-comment-lines"}),_c('p',{staticClass:"font-bold text-sm mx-1"},[_vm._v(_vm._s(_vm.tempCount + _vm.totalComments)+" ")]),_c('span',{staticClass:"font-bold text-sm"},[_vm._v(" Comments ")])])]),_c('div',{staticClass:"w-full px-2",class:[
      _vm.state.postingLoader || _vm.state.deletingLoader
        ? 'opacity-50 select-none'
        : '',
    ]},[_c('LinkedInReplyBox',_vm._g({attrs:{"id":"mainCommentReplyBox","inbox-data":_vm.state.activeInboxData,"loader":_vm.state.postingLoader},on:{"post-comment":_vm.handlePostComment}},_vm.$listeners))],1),(_vm.state.showComment && !_vm.state.loader)?_c('div',{staticClass:"flex flex-column items-center justify-center"},[_vm._l((_vm.state.allComments),function(comment,key){return [_c('LinkedinInboxComment',_vm._g({key:`main_comment_${comment.comment_id}_${key}`,staticClass:"w-full px-2",attrs:{"comment":comment,"inbox-data":_vm.state.activeInboxData},on:{"comment-posted":_vm.addComment,"comment-deleted":_vm.deleteComment}},_vm.$listeners))]}),(
        _vm.tempComments + _vm.totalComments > _vm.state.allComments.length &&
        _vm.totalComments > 7
      )?_c('div',{staticClass:"flex justify-center items-center cursor-pointer hover:bg-gray-100 p-2 !rounded-lg",class:{ 'w-60': _vm.state.isLoading },on:{"click":_vm.loadMoreComments}},[_c('p',{staticClass:"font-bold text-sm text-gray-800"},[_vm._v("Load more comments")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.isLoading),expression:"state.isLoading"}],staticClass:"flex justify-center items-center ml-2 mb-1"},[_c('clip-loader',{staticClass:"d-inline-block align-middle",attrs:{"color":"rgba(152, 158, 181, 0.8)","size":'12px'}})],1)]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }