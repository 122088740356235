<template>
  <div class="topics-cover-stories-container__item">
    <div class="topics-cover-stories-container__item__inner">
      <div
          class="topics-cover-stories-container__item__inner__image__container"
      >
        <div class="top-cover-stories-action-buttons">
          <div class="top-cover-stories-action-buttons__list">
            <!-- share buttons -->
            <b-dropdown
                id="dropdown-share-articles"
                variant="studio-icon-theme"
                dropright
                class="studio-icon-theme-dropdown"
                no-caret
            >
              <template slot="button-content">
                <i
                    v-tooltip="'Share this post'"
                    class="far fa-share-alt icon"
                ></i>
              </template>
              <b-dropdown-item
                  variant="studio-icon-theme"
                  @click.prevent="
                  initializeArticlePostCreation(
                    post._source.id,
                    'social_share_modal',
                  )
                "
              ><i class="far fa-share share-ico-align"></i> Social Media
              </b-dropdown-item>
              <b-dropdown-item
                  variant="studio-icon-theme"
                  @click.prevent="
                  initializeArticlePostCreation(
                    post._source.id,
                    'blog_share_modal',
                  )
                "
              ><i class="far fa-rss"></i> Blog Post
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
                id="dropdown-favorite-articles"
                ref="dropdown_favorite"
                variant="studio-icon-theme"
                dropright
                class="studio-icon-theme-dropdown mt-3"
                :menu-class="{
                  '!hidden': post._source.is_favorite,
                  }"
                no-caret
                block
            >
              <template v-if="post._source.is_favorite" slot="button-content">
                <i
                    v-tooltip="'Unfavorite'"
                    class="far fa-heart icon is-favorite"
                    @click="unfavoritePostItemEventFire(post._source.id)"
                ></i>
                <span
                    :class="{ ' active': post._source.is_active }"
                    class="icon_animate"
                ></span>
              </template>
              <template v-else slot="button-content">
                <i
                    v-tooltip="'Favorite'"
                    class="far fa-heart icon"
                    :class="{ 'animated tada': post._source.is_active }"
                ></i>
              </template>
              <BDropdownFavoriteArticle
                  v-show="!post._source.is_favorite"
                  :post_id="post._source.id"
                  :post-index="index"
              />
            </b-dropdown>

            <!-- more actions button-->
            <b-dropdown
                id="dropdown-actions-articles"
                variant="studio-icon-theme"
                dropleft
                class="studio-icon-theme-dropdown mt-3"
                no-caret
            >
              <template slot="button-content">
                <i class="cs-dots-h icon"></i>
              </template>

              <b-dropdown-item
                  variant="studio-icon-theme"
                  target="_blank"
                  :href="savePocketContent(post._source.url)"
              ><i class="fab fa-get-pocket"></i> Add to Pocket
              </b-dropdown-item>

              <!--              <b-dropdown-item-->
              <!--                variant="studio-icon-theme"-->
              <!--                data-target="#twitter_influencer_modal"-->
              <!--                @click.prevent="influencersWebPreview()"-->
              <!--                ><i class="fa fa-users-crown"></i> View Influencers-->
              <!--              </b-dropdown-item>-->

              <b-dropdown-item
                  v-if="
                  getTopicType === 'archived' &&
                  $route.params &&
                  $route.params.module === 'archived'
                "
                  variant="studio-icon-theme"
                  @click.prevent="
                  unarchive({ post_id: post._source.id, index: index })
                "
              ><i class="far fa-archive"></i> Unarchive
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div
            v-lazy:background-image="backgroundImageURL(post._source)"
            class="topics-cover-stories-container__item__inner__image__container__source"
        ></div>
      </div>
      <div class="topics-cover-stories-container__item__inner__content">
        <h3
            @click="searchWebPreview"
            v-html="limitTextLength(post._source.title, 48)"
        ></h3>
        <p class="topics-cover-stories-container__item__inner__content__date">
          {{ $filters.relative(post._source.post_date) }}</p
        >
        <p class="topics-cover-stories-container__item__inner__content__domain">
          {{ trimURL(post._source.domain_url) }}</p
        >
        <template v-if="post._source.author">
          <p
              class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <span
                v-tooltip="'View articles from this author'"
                @click="
                changeRouteForCoverStoryView(
                  post._source.author,
                  trimURL(post._source.domain_url),
                )
              "
                v-html="post._source.author"
            ></span>
            <a
                v-for="(account, index) in getTwitterAccountsForView(
                post._source.twitter_accounts,
              )"
                v-if="
                post._source.twitter_accounts &&
                post._source.twitter_accounts.length > 0 &&
                index < 1
              "
                :key="index"
                style="cursor: pointer"
                @click.prevent="openTwitterLinkForInfluencer(account)"
            >
              {{ account }}</a
            >

            <a
                v-else-if="post._source.twitter_handler"
                target="_blank"
                :href="getTwitterLink(post._source.twitter_handler)"
            >
              {{ post._source.twitter_handler }}</a
            >
            <clip-loader
                v-if="loader"
                class="d-inline-block ml-1"
                :color="'#436aff'"
                :size="'12px'"
            ></clip-loader>
          </p>
        </template>
        <template
            v-else-if="
            post._source.twitter_accounts &&
            post._source.twitter_accounts.length > 0
          "
        >
          <p
              class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <a
                v-for="(account, index) in getTwitterAccountsForView(
                post._source.twitter_accounts,
              )"
                v-if="index < 1"
                style="cursor: pointer"
                @click.prevent="openTwitterLinkForInfluencer(account)"
            >{{ account }}</a
            >
            <clip-loader
                v-if="loader"
                class="d-inline-block ml-1"
                :color="'#436aff'"
                :size="'12px'"
            ></clip-loader>
          </p>
        </template>

        <template v-if="post._source.twitter_handler">
          <p
              class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <a
                target="_blank"
                :href="getTwitterLink(post._source.twitter_handler)"
            >{{ post._source.twitter_handler }}</a
            >
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'

export default {
  components: {
    BDropdownFavoriteArticle
  },
  props: {
    post: {
      type: Object,
      default: ()=>{}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loader: false
    }
  },

  methods: {
    changeRouteForCoverStoryView(author, domain) {
      this.$router.push({
        name: 'discover_search_web',
        query: {q: `author:${author} AND site:${domain}`}
      })
    },
    openTwitterLinkForInfluencer(account) {
      window.open(`https://twitter.com/${account}`, '_blank')
    },
    searchWebPreview() {
      this.$store.dispatch('articlePreview', {
        id: this.post._source.id,
        index: this.index
      })
    },
    unfavoritePostItemEventFire(postId) {
      this.removeFavorite(postId, this.index)
      this.$refs.dropdown_favorite.toggle(false)
    },
    influencersWebPreview() {
      this.showSharers(this.post._source.id)
    }
  }
}
</script>
