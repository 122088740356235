<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
      type="overview"
      :get-accounts="getAccounts"
      :get-date="getDate"
      :get-previous-date="getPreviousDate"
      :updating="getFetchingData()"
      :style="{
        cursor: getFetchingData() ? 'progress' : '',
        pointerEvents: getFetchingData() ? 'none' : '',
      }"
      class="!top-0"
    ></AnalyticsFilterBar>
    <div
      v-if="selectAllAccounts().length <= 0 && !getFetchSocialStatus"
      class="analytics-main-errors"
    >
      <img
        draggable="false"
        src="../../assets/imgs/no_data_images/no-account-connected.svg"
        alt=""
      />

      <p>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect</router-link
        >
      </p>
    </div>

    <div
      v-else-if="accounts.length <= 0 && !getFetchSocialStatus"
      class="analytics-main-errors"
    >
      <img
        draggable="false"
        src="../../assets/imgs/no_data_images/no-account-selected.svg"
        alt=""
      />

      <p>
        No Account(s) Selected
        <small>Select an account from filters to see respective data</small>
      </p>
    </div>
    <div v-else class="analytics-content-wrapper">
      <AnalyticsSummaryBlock
        :heading="'Profiles Performance Summary'"
        :subtitle="'Key profile performance metrics from the selected time period.'"
      >
        <template v-if="overview_loader">
          <div class="row">
            <div v-for="i in overview_values" :key="i" class="col-6 col-sm-3">
              <SkeletonBox :width="'100%'" height="92.2px" radius="10px" />
            </div>
          </div>
        </template>
        <div v-else class="row">
          <template v-for="(value, key) in overview_values">
            <div :key="key" class="col-6 col-sm-3">
              <SummaryCard
                :title="value"
                :total="overview.current[key]"
                :reach="Math.abs(getReach(key, overview)) + '%'"
                :reach-up="getReachUp(key, overview)"
              ></SummaryCard>
            </div>
          </template>
        </div>
      </AnalyticsSummaryBlock>

      <div class="d-flex">
        <div class="col-9 p-0">
          <section
            class="mt-0 mr-0 analytics-post-engagement"
            data-cy="analytics-post-engagement"
          >
            <div class="analytics-section-header">
              <h2>Engagement and Posts</h2>
              <p>See how your audience interacted with the posts.</p>
            </div>
            <template v-if="posts_loader">
              <div class="h-chart flex-center-center" style="height: 90%">
                <SkeletonBox
                  height="250px"
                  width="300px"
                  type="chart"
                  radius="4px"
                />
              </div>
            </template>
            <template v-else>
              <ColumnCrossHairChart
                v-if="posts_engagements.data.show_data > 0"
                :categories="posts_engagements.buckets"
                :series="getPostEngagementSeries()"
              />

              <div v-else class="flex-center-center" style="height: 90%">
                <div class="analytics-no-data-found">
                  <img
                    src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                    alt=""
                  />
                  <p> No Data Found!</p>
                </div>
              </div>
            </template>
          </section>
        </div>
        <div class="col-3 p-0 m-0 analytics-slide-summary">
          <section class="mt-0">
            <h2>Engagement Rollup</h2>
            <template v-if="engagement_rollup_loader">
              <div
                v-for="i in engagement_rollup_values"
                :key="i"
                style="margin-top: 1.3rem"
              >
                <SkeletonBox :width="'100%'" height="92px" radius="10px" />
              </div>
            </template>
            <template v-else>
              <template v-for="(value, key) in engagement_rollup_values">
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="engagement_rollup.current[key]"
                  :reach="Math.abs(getReach(key, engagement_rollup)) + '%'"
                  :reach-up="getReachUp(key, engagement_rollup)"
                  variant="rollup"
                />
              </template>
            </template>
          </section>
        </div>
      </div>

      <section class="analytics-top-post">
        <h2>Top Posts By Engagement</h2>
        <p
          >Review your top posts published during the selected time period,
          based on the post’s lifetime performance.</p
        >

        <AnalyticsTopPosts
          :top_posts_loader="top_posts_loader"
          :top_posts="top_posts"
          :type="'analyze'"
        ></AnalyticsTopPosts>
      </section>

      <section class="analytics-account-performance">
        <h2>Social Performance</h2>
        <p>Key metrics of the Social Profiles in the selected time period.</p>

        <template v-if="performance_loader">
          <div class="row">
            <div v-for="i in 2" :key="i" class="col-6">
              <div class="analytics-performance-box">
                <div class="analytics-performance-box__header">
                  <div class="d-flex" style="flex-direction: column">
                    <div class="mb-1">
                      <SkeletonBox width="86px" radius=".2rem" />
                    </div>
                    <SkeletonBox height="0.8rem" width="60px" radius=".2rem" />
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <SkeletonBox height="2.8rem" width="2.8rem" radius="50%" />
                  </div>
                </div>

                <div class="analytics-performance-box__body">
                  <div class="row">
                    <div class="col-2">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div class="col-8">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div
                      class="col-2"
                      style="display: flex; justify-content: flex-end"
                    >
                      <SkeletonBox width="44px" radius=".2rem" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div class="col-8">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div
                      class="col-2"
                      style="display: flex; justify-content: flex-end"
                    >
                      <SkeletonBox width="44px" radius=".2rem" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div class="col-8">
                      <SkeletonBox radius=".2rem" />
                    </div>
                    <div
                      class="col-2"
                      style="display: flex; justify-content: flex-end"
                    >
                      <SkeletonBox width="44px" radius=".2rem" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <AnalyticsAccountPerformance
            :account_performance="account_performance.overall"
            :accounts="accounts"
            :type="'analyze'"
          ></AnalyticsAccountPerformance>
        </template>
      </section>

      <section class="analytics-engagement">
        <h2>Overall Platforms Engagement</h2>
        <p
          >View engagement metrics across all platforms in the selected time
          period.</p
        >
        <template v-if="performance_loader">
          <div class="row">
            <template v-for="key in 4">
              <div :key="key" class="col-6">
                <div
                  class="analytics-engagement-box flex-center-center"
                  style="padding: 4rem 0.8rem"
                >
                  <SkeletonBox
                    :innerfill="true"
                    :width="'240px'"
                    :height="'240px'"
                    radius="50%"
                  />
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <AnalyticsEngagement
            :account_performance="account_performance"
          ></AnalyticsEngagement>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'

// Charts
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'

import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'

// Loader
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { mapActions, mapGetters } from 'vuex'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { dataValues } from '@src/modules/analytics/components/common/helper'

export default {
  name: 'AnalyticsOverview',
  components: {
    AnalyticsFilterBar,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    SkeletonBox,
    ColumnCrossHairChart,
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return dataValues().overview
  },
  computed: {
    ...mapGetters(['getFetchSocialStatus']),
  },
  watch: {
    accounts(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal.length > 0) {
          this.initialize()
        }
      }
    },
    date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    async getOverview() {
      if (this.overview_loader === true) {
        return
      }
      this.overview_loader = true
      const response = await this.getAnalyzeService({
        accounts: this.accounts,
        date: this.date,
        section: 'overview',
        type: 'group',
        timezone: this.getActiveWorkspace.timezone,
        previous_date: this.previous_date,
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.status) {
        this.overview = response.overview
      }
      this.overview_loader = false
    },
    async getEngagementRollup() {
      if (this.engagement_rollup_loader === true) {
        return
      }
      this.engagement_rollup_loader = true
      const response = await this.getAnalyzeService({
        accounts: this.accounts,
        date: this.date,
        section: 'engagement_rollup',
        type: 'group',
        timezone: this.getActiveWorkspace.timezone,
        previous_date: this.previous_date,
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.status) {
        this.engagement_rollup = response.engagement_rollup
      }
      this.engagement_rollup_loader = false
    },
    async getPostsEngagements() {
      if (this.posts_loader === true) {
        return
      }
      this.posts_loader = true
      const response = await this.getAnalyzeService({
        accounts: this.accounts,
        date: this.date,
        section: 'posts_engagements',
        type: 'group',
        timezone: this.getActiveWorkspace.timezone,
        previous_date: this.previous_date,
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.status) {
        this.posts_engagements = response.posts_engagements
      }
      this.posts_loader = false
    },
    async getAccountPerformance() {
      if (this.performance_loader === true) {
        return
      }
      this.performance_loader = true
      const response = await this.getAnalyzeService({
        accounts: this.accounts,
        date: this.date,
        section: 'account_performance',
        type: 'group',
        timezone: this.getActiveWorkspace.timezone,
        previous_date: this.previous_date,
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.status) {
        this.account_performance = response.account_performance
      }
      this.performance_loader = false
    },
    async getTopPosts() {
      if (this.top_posts_loader === true) {
        return
      }
      this.top_posts_loader = true
      const response = await this.getAnalyzeService({
        accounts: this.accounts,
        date: this.date,
        section: 'top_posts',
        type: 'group',
        timezone: this.getActiveWorkspace.timezone,
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.status) {
        this.top_posts = response.top_posts
      }
      this.top_posts_loader = false
    },

    getAccounts(accounts) {
      this.accounts = accounts
    },

    getDate(date) {
      this.date = date
    },

    getPreviousDate(date) {
      this.previous_date = date
    },
    getReach(type, section) {
      const previous =
        section.previous[type] === 0 || !section.previous[type]
          ? section.current[type]
          : section.previous[type]
      const value = (
        ((section.current[type] - previous) / previous) *
        100
      ).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReachUp(type, section) {
      return this.getReach(type, section) > 0
    },
    getPostEngagementSeries() {
      return [
        {
          name: 'Comments',
          data: this.posts_engagements.data.comments,
          color: '#ffe202',
          type: 'column',
        },
        {
          name: 'Reposts',
          data: this.posts_engagements.data.reposts,
          color: '#2fe095',
          type: 'column',
        },
        {
          name: 'Reactions',
          data: this.posts_engagements.data.reactions,
          color: '#e02f5f',
          type: 'column',
        },
        {
          name: 'Posts Published',
          data: this.posts_engagements.data.post_count,
          color: '#6173ff',
          type: 'spline',
        },
      ]
    },
    initialize() {
      this.getOverview()
      this.getPostsEngagements()
      this.getEngagementRollup()
      this.getAccountPerformance()
      this.getTopPosts()
    },
    getFetchingData() {
      return (
        this.posts_loader ||
        this.top_posts_loader ||
        this.overview_loader ||
        this.engagement_rollup_loader ||
        this.performance_loader
      )
    },
  },
}
</script>
