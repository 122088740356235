<!--step 1 JS-->
<script>
export default {
  name: 'CalendarSlot',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    limitSlotsAccounts: {
      type: Function,
      default: () => {},
    },
    editSlotModal: {
      type: Function,
      default: () => {},
    },
    removeSlotModal: {
      type: Function,
      default: () => {},
    },
    openEditDeleteModal: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<!--step 2 html-->
<template>
  <div v-if="item" :key="item._id" class="slot_item mx-0" data-cy="item">
    <div class="inner">
      <div class="s_head d-flex align-items-center">
        <span class="text-gray-900">
          {{ $filters.addZeroToStart(item.hour) }}:{{
            $filters.addZeroToStart(item.minute)
          }}
          {{ item.period }}
        </span>
        <span
          v-tooltip="'Edit Slot'"
          class="icon-edit-cs remove_icon ml-auto text-gray-900"
          @click="editSlotModal(item)"
        ></span>
        <span
          v-tooltip="'Remove Slot'"
          class="icon-delete-cs remove_icon ml-2 text-gray-900"
          @click="
            removeSlotModal({
              slotId: item._id,
              weekday: item.day,
              index: item.idx,
              categoryId: item.category._id,
            })
          "
        ></span>
        <span class="icon"> </span>
      </div>
      <div
        class="absolute bottom-0 w-full p-1 pt-0.5 text-left"
        :class="item.category.color"
        @click="openEditDeleteModal(item)"
      >
        <span class="truncate">{{ item.category.name }}</span>
      </div>
    </div>
  </div>
</template>
