<template>
  <div>
    <template v-if="validate_loader">
      <div class="component_inner mt-5">
        <beat-loader :color="'#436aff'"></beat-loader>
        <div class="validating_purchase">Validating your purchase...</div>
      </div>
    </template>
    <template v-else>
      <div class="upgrade_starter_plan mt-5 mb-5">
        <div class="component_inner">
          <template v-if="status">
            <i class="fas fa-check-circle upgraded_tick"> </i>
            <h3 class="pt-3 pb-3"
              >Your plan upgraded to the ContentStudio Starter Plan!</h3
            >
            <p>
              Your lifetime deal purchase has been successfully activated
              against your account. Start enjoying the features!
            </p>
            <a
              href="https://app.contentstudio.io"
              class="btn btn-studio-theme-space"
            >
              <span> Go to account </span>
            </a>
          </template>
          <template v-if="!status">
            <i class="fas fa-times-circle upgrade_already_tick"> </i>
            <h3 class="pt-3 pb-3">
              <template v-if="state === 'upgraded_already'">
                Your plan has been upgraded already!
              </template>
              <template v-else-if="state === 'invalid_coupon_code'">
                Your have provided invalid redeem code.
              </template>
              <template v-else-if="state === 'uuid_missing'">
                Redeem code to upgrade plan is missing.
              </template>
              <template v-else-if="state === 'coupon_used_already'">
                Your redeem code has been used already.
              </template>
            </h3>
            <a
              href="https://app.contentstudio.io"
              class="btn btn-studio-theme-space"
            >
              <span> Go to account </span>
            </a>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import http from '@common/lib/http-common'
import { validateAndUpgradeToStarterPlan } from '../../../config/api-utils'

export default {
  data () {
    return {
      validate_loader: true,
      status: true,
      state: ''
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace'])
  },
  created () {
    this.validateAndUpgrade()
  },
  methods: {
    async validateAndUpgrade () {
      await http
        .post(validateAndUpgradeToStarterPlan, { uuid: this.$route.query.uuid })
        .then((res) => {
          if (res.data) {
            this.status = res.data.status
            this.state = res.data.state
          }
          return res
        })
        .catch((err) => {
          return err
        })
      this.validate_loader = false
    }
  }
}
</script>
<style lang="less" scoped>
.validating_purchase {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
}

.upgrade_starter_plan {
  padding: 25px 20px;
  background: #fff;
  width: 800px;
  margin: 0 auto;
  border: 1px solid #fafafa;
  border-radius: 4px;
  text-align: center;
  h3 {
    background: transparent !important;
  }
  button {
    margin: 15px 0;
  }
}

.upgrade_starter_plan {
  p {
    font-size: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .upgraded_tick {
    font-size: 46px;
    margin: 25px 0;
    color: #1ecb7b;
  }
  .upgrade_already_tick {
    font-size: 46px;
    margin: 25px 0;
    color: #ea7070;
  }
}
</style>
