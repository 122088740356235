<template>
  <div class="inbox-profile">
    <CreateLabelModal
      :active-inbox-detail="activeInboxDetail"
      :type-filters="typeFilters"
      :all-tags="allTags"
      @apply-tag="applyNewTag"
    >
    </CreateLabelModal>
    <BookmarkModal
      v-if="bookmarkedMessage !== null"
      :bookmarked-message="bookmarkedMessage"
      :active-inbox-detail="activeInboxDetail"
    ></BookmarkModal>

    <button class="btn inbox-profile__edit_btn light_gray" @click="editDetails">
      <span>Edit</span>
    </button>
    <div class="inbox-profile__avatar">
      <img
        :src="
          commonMethods.getPostedByImage(
            activeInboxDetail.inbox_details.posted_by
          )
        "
        alt="dp"
        @error="
          $event.target.src = `'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'`
        "
      />
      <i
        class="fab"
        :class="commonMethods.getPlatformIcon(activeInboxDetail.platform)"
      ></i>
    </div>

    <div class="inbox-profile__basic">
      <h2>{{
        commonMethods.getPostedByName(
          activeInboxDetail.inbox_details.posted_by,
          capitalizeFirstLetter(activeInboxDetail.platform)
        )
      }}</h2>
      <p>{{ userDetails !== null ? userDetails.email : null }}</p>
      <p
        v-if="
          commonMethods.getPostedByUserName(
            activeInboxDetail.inbox_details.posted_by
          )
        "
        class="linkified"
      >
        <a
          class="linkified"
          target="_blank"
          :href="
            (activeInboxDetail.platform === 'instagram'
              ? 'https://www.instagram.com/'
              : 'https://twitter.com/') +
            activeInboxDetail.inbox_details.posted_by.user_name
          "
          >@{{ activeInboxDetail.inbox_details.posted_by.user_name }}</a
        >
      </p>
    </div>

    <div class="inbox-profile-collapse">
      <div v-b-toggle.profile-detail class="inbox-profile__toggler">
        <span>Details</span>
        <i class="fal fa-angle-down"></i>
      </div>

      <b-collapse id="profile-detail" visible class="inbox-profile__collapse">
        <transition name="slide-bottom" mode="out-in">
          <div v-if="toggleForm" class="inbox-profile__edit">
            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="email"
                  v-model="tempUserDetails.email"
                  type="email"
                  placeholder="Email Address"
                />
                <label for="email" class="label-animated">Email Address</label>
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-Email"></i>
                </span>
              </div>
              <span
                v-if="!validEmail && tempUserDetails.email"
                class="input-error"
                >Valid email address required</span
              >
            </div>

            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="description"
                  v-model="tempUserDetails.description"
                  type="text"
                  placeholder="Description"
                />
                <label for="description" class="label-animated"
                  >Description</label
                >
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-Info"></i>
                </span>
              </div>
            </div>

            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="phone"
                  v-model="tempUserDetails.phone"
                  type="text"
                  placeholder="Contact"
                  onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                />
                <label for="phone" class="label-animated">Contact</label>
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-Phone"></i>
                </span>
              </div>
            </div>

            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="location"
                  v-model="tempUserDetails.location"
                  type="text"
                  placeholder="Location"
                />
                <label for="location" class="label-animated">Location</label>
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-Location"></i>
                </span>
              </div>
            </div>

            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="job_title"
                  v-model="tempUserDetails.job_title"
                  type="text"
                  placeholder="Job title"
                />
                <label for="job_title" class="label-animated">Job title</label>
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-Company"></i>
                </span>
              </div>
            </div>

            <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  id="company"
                  v-model="tempUserDetails.company"
                  type="text"
                  placeholder="Company"
                />
                <label for="company" class="label-animated">Company</label>
                <span class="field_group__with_icon__input_icon">
                  <i class="far fa-building"></i>
                </span>
              </div>
            </div>

            <div class="btn_block input_field d-flex justify_center">
              <button
                class="btn btn-studio-theme-grey-space btn-size-medium mr-2"
                @click="cancelDetails"
                >Cancel
              </button>

              <button
                class="btn btn-studio-theme-space btn-size-medium flex-grow-1"
                :disabled="!validEmail"
                @click="saveDetails"
                >Update Details
              </button>
            </div>
          </div>
          <template v-else>
            <div>
              <div class="inbox-profile__detail-item">
                <div>
                  <i class="icon-Email"></i>
                  <span class="link_text">Email</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.email : null"
                  >{{ userDetails !== null ? userDetails.email : null }}</span
                >
              </div>

              <div class="inbox-profile__detail-item">
                <div>
                  <i class="icon-Info"></i>
                  <span class="link_text">Description</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.description : null"
                  >{{
                    userDetails !== null ? userDetails.description : null
                  }}</span
                >
              </div>

              <div class="inbox-profile__detail-item">
                <div>
                  <i class="icon-Location"></i>
                  <span class="link_text">Location</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.location : null"
                  >{{
                    userDetails !== null ? userDetails.location : null
                  }}</span
                >
              </div>

              <div class="inbox-profile__detail-item">
                <div>
                  <i class="icon-Phone"></i>
                  <span class="link_text">Phone #</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.phone : null"
                  >{{ userDetails !== null ? userDetails.phone : null }}</span
                >
              </div>

              <div class="inbox-profile__detail-item">
                <div>
                  <i class="icon-Company"></i>
                  <span class="link_text">Job Title</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.job_title : null"
                  >{{
                    userDetails !== null ? userDetails.job_title : null
                  }}</span
                >
              </div>

              <div class="inbox-profile__detail-item">
                <div>
                  <i class="far fa-building"></i>
                  <span class="link_text">Company</span>
                </div>
                <span
                  class="link_value"
                  :title="userDetails !== null ? userDetails.company : null"
                  >{{ userDetails !== null ? userDetails.company : null }}</span
                >
              </div>
            </div>
          </template>
        </transition>
      </b-collapse>
    </div>

    <hr class="inbox_sidebar__divider" />

    <div class="inbox-profile-collapse">
      <div v-b-toggle.profile-bookmarks class="inbox-profile__toggler">
        <span>Bookmarks</span>
        <i class="fal fa-angle-down"></i>
      </div>
      <b-collapse
        id="profile-bookmarks"
        visible
        class="inbox-profile__collapse"
      >
        <div class="bookmarks">
          <!--//loop here-->
          <template v-if="bookmarkedMessages.length !== 0">
            <template v-for="(bookmark, key) in bookmarkedMessages">
              <!--                                "left_side" for left and "right_side" for right view-->
              <div
                :key="key"
                :class="[
                  commonMethods.isIncomingMessage(temp, bookmark)
                    ? 'left_side'
                    : 'right_side',
                ]"
                class="profile_picture d-flex align-items-center"
                @click="setBookmarkedMessage(bookmark)"
              >
                <!--IMAGE SHOULD BE THIS FOR LEFT VIEW-->
                <div
                  v-if="commonMethods.isIncomingMessage(temp, bookmark)"
                  class="picture_block picture_block_background"
                >
                  <div
                    class="img"
                    :style="
                      'background: url(' +
                      commonMethods.getImageForUser(temp, bookmark) +
                      ')'
                    "
                  ></div>
                </div>

                <div class="text_block">
                  <p class="text">
                    <template
                      v-if="
                        bookmark.attachment !== null &&
                        bookmark.attachment.length > 0
                      "
                    >
                      <i
                        v-tooltip.bottom-center="bookmark.attachment[0].name"
                        class="fal fa-file ml-1"
                      />
                      {{
                        commonMethods.limitText(bookmark.attachment[0].name, 20)
                      }}
                    </template>
                    <template v-else-if="bookmark.event_type === 'note'">
                      {{ commonMethods.limitText(bookmark.message, 20) }}
                    </template>
                    <template v-else-if="bookmark.sticker">
                      <i class="fal fa-smile-beam ml-1"> Sticker</i>
                    </template>
                    <template v-else>
                      {{ commonMethods.limitText(bookmark.message, 20) }}
                    </template>
                  </p>
                </div>

                <!--IMAGE SHOULD BE THIS FOR RIGHT VIEW-->
                <div
                  v-if="!commonMethods.isIncomingMessage(temp, bookmark)"
                  class="picture_block picture_block_background"
                >
                  <div
                    class="img"
                    :style="
                      'background: url(' +
                      commonMethods.getImageForUser(temp, bookmark) +
                      ')'
                    "
                  ></div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <p class="link_text">Your bookmarked messages will appear here.</p>
          </template>
          <template v-if="bookmarksAvailable">
            <clip-loader
              class="spinner ml-2"
              :color="'#1e40af'"
              :size="'20px'"
            ></clip-loader>
          </template>
        </div>
      </b-collapse>
    </div>

    <hr class="inbox_sidebar__divider" />

    <div class="inbox-profile-collapse pb-5">
      <div v-b-toggle.profile-tags class="inbox-profile__toggler">
        <span>Tags </span>
        <i class="fal fa-angle-down"></i>
      </div>
      <b-collapse id="profile-tags" visible class="inbox-profile__collapse">
        <div class="inbox-profile__list">
          <template
            v-for="(tag, key) in getSortedTags(
              activeInboxDetail.inbox_details.tags
            )"
          >
            <span
              :key="key"
              :class="['label_tag_round_dark ml-0 mr-1 mb-1 ' + tag.tag_color]"
            >
              <span :title="tag.tag_name">
                {{ tag.tag_name }}
              </span>
              <i
                v-tooltip.bottom-center="{
                  content: 'Remove Tag',
                  trigger: 'hover',
                }"
                class="cross_btn fal fa-times"
                @click="updateTags('delete_tag', tag)"
              >
              </i>
            </span>
          </template>

          <span
            v-if="activeInboxDetail.inbox_details.tags.length > 0"
            v-tooltip.top-center="'Add a new tag'"
            class="inbox-profile__list-add-btn"
            @click="$bvModal.show('createLabelModal')"
            ><i class="fal fa-plus"></i
          ></span>

          <span
            v-else
            class="inbox-profile__list-add-btn"
            @click="$bvModal.show('createLabelModal')"
            ><i class="fal fa-plus mr-2"></i>Add a New Tag</span
          >
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'underscore'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import CreateLabelModal from './CreateLabelModal'
import BookmarkModal from './BookmarkModal'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {
    BookmarkModal,
    CreateLabelModal,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
    typeFilters: { type: Array, default: () => [] },
    allTags: { type: Array, default: () => [] },
  },
  data() {
    return {
      temp: this,
      addTagStatus: false,
      newTags: [],
      toggleForm: false,
      commonMethods: commonMethods,
      bookmarkedMessage: null,
      bookmarkedMessages: [],
      userDetails: {
        email: null,
        phone: null,
        location: null,
        birthday: null,
        gender: null,
      },
      tempUserDetails: {},
      validEmail: true,
      bookmarksAvailable: false,
    }
  },
  watch: {
    'tempUserDetails.email'(value) {
      this.validEmail = this.validateEmail(value)
      if (value === '' || value === null) {
        this.validEmail = true
      }
    },
    'activeInboxDetail.element_details.element_id': async function (
      newVal,
      oldVal
    ) {
      // watch it
      this.bookmarkedMessages = []
      this.userDetails = this.activeInboxDetail.inbox_details.posted_by
      this.cancelDetails()
      this.validEmail = true
      if (this.activeInboxDetail.inbox_type === 'conversation') {
        await this.fetchStarredMessages()
      }
    },
  },
  mounted() {
    // console.log('i am console')
    if (this.activeInboxDetail.inbox_details.posted_by !== null) {
      this.userDetails = this.activeInboxDetail.inbox_details.posted_by
    }
    if (this.activeInboxDetail.inbox_type === 'conversation') {
      this.fetchStarredMessages()
    }
    EventBus.$on('is-bookmarked-event', (message) => {
      console.log('check new event --> ', message)
      const index = this.bookmarkedMessages.findIndex(
        (x) => x.uuid === message.uuid
      )
      if (index < 0) {
        this.bookmarkedMessages.push(message)
      } else {
        this.bookmarkedMessages.splice(index, 1)
      }
      // console.log(this.bookmarkedMessages.findIndex(x => x.uuid === message.uuid))
      // console.log('check new event --> ', message)
    })
  },
  beforeDestroy() {
    EventBus.$off('is-bookmarked-event')
  },
  methods: {
    ...mapActions([
      'updateInboxTags',
      'fetchBookmarkedMessages',
      'updateUserDetails',
    ]),

    setBookmarkedMessage(message) {
      console.log('setBookmarkedMessage', message)
      this.bookmarkedMessage = message
      setTimeout(() => {
        this.$bvModal.show('bookmarkModal')
      }, 500)
    },
    getSortedTags(tags) {
      return _.sortBy(tags, 'tag_name')
    },

    isNumber: function (evt) {
      evt = evt || window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 190 &&
        charCode !== 110
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    createPayload(value, action) {
      return {
        workspace_id: this.activeInboxDetail.workspace_id,
        platform_id: this.activeInboxDetail.platform_id,
        element_id: this.activeInboxDetail.element_details.element_id,
        inbox_type: this.activeInboxDetail.inbox_type,
        tag: value,
        action: action,
      }
    },
    addTag(status) {
      this.addTagStatus = status !== 'close'
    },
    async updateTags(action, value) {
      let response = { isValid: null, message: 'response is null' }
      const tags = action === 'add_new_tag' ? this.newTags : value._id
      response = await this.updateInboxTags(this.createPayload(tags, action))
      if (response.isValid) {
        this.newTags = []
        this.activeInboxDetail = response.inbox_details
      } else {
        this.alertMessage(response.message, 'error')
        // commonMethods.showToast(this, 3, response.message)
      }
    },
    applyNewTag(payload) {
      this.newTags = payload.tags
      this.updateTags('add_new_tag', null)
    },

    async fetchStarredMessages() {
      this.bookmarksAvailable = true
      // console.log(this.activeInboxDetail.workspace_id)
      const response = await this.fetchBookmarkedMessages({
        workspace_id: this.activeInboxDetail.workspace_id,
        element_id: this.activeInboxDetail.element_details.element_id,
      })
      // console.log(response)
      if (response.isValid) {
        this.bookmarksAvailable = false
        this.bookmarkedMessages = response.response
      } else {
        this.bookmarksAvailable = false
        this.bookmarkedMessages = []
        // commonMethods.showToast(this, 3, response.message)
      }
    },
    async saveDetails() {
      // console.log(this.userDetails)
      const response = await this.updateUserDetails({
        posted_by: this.tempUserDetails,
        workspace_id: this.activeInboxDetail.workspace_id,
        platform_id: this.activeInboxDetail.platform_id,
      })
      if (response.isValid) {
        // console.log('updated user details!')
        this.userDetails = this.tempUserDetails
        this.toggleForm = false
        this.$emit('update-conversations', {
          conversation_ids: response.response,
          posted_by: this.tempUserDetails,
        })
      } else {
        // console.log('unable to update user details!')
      }
    },
    editDetails() {
      // this.tempUserDetails = this.userDetails
      this.tempUserDetails = JSON.parse(JSON.stringify(this.userDetails))
      this.toggleForm = !this.toggleForm
    },
    cancelDetails() {
      this.tempUserDetails = {}
      this.toggleForm = false
    },
  },
}
</script>
