<template>
  <div>
    <div class="v-calendar-view">
      <div class="event_inner">
        <!--<div class="custom_loader hide">-->
        <!--<div class="loader_inner"></div>-->
        <!--</div>-->
        <div class="top_tags_category">
          <div
            v-tooltip.top-center="'Content Category'"
            class="top-category"
            v-if="item.content_category"
            :style="{ 'background-color': item.content_category.color_code }"
            >{{ item.content_category.name }}</div
          >
          <div
            v-tooltip.top-center="'Content Type'"
            class="list_tag"
            v-if="getItemTag"
            >{{ getItemTag }}</div
          >
        </div>

        <!--<div title="Content Category" class="top-category">adasdasd</div>-->

        <div class="event_head d-flex align-items-center">
          <div class="head_left">
            <p class="d-flex align-items-center">
              <i
                v-if="item.render_class"
                v-tooltip="
                  item['partially_failed']
                    ? 'Partially Failed'
                    : item.render_class.tooltip
                "
                :class="
                  item['partially_failed']
                    ? 'fas fa-adjust , partially_failed partial'
                    : [item.render_class.icon, item.post_state]
                "
                class="status_color left-icon d-flex align-items-center justify-content-center"
              ></i>
              <span v-if="item.execution_time">
                {{
                  getWorkspaceTimeZoneTime(
                    item['execution_time']['date'],
                    getWorkspaces.activeWorkspace.timezone,
                    'hh:mm a'
                  )
                }}</span
              >
              <!--                                <input type="checkbox" id="calendarItemCheckbox" :name="'plan_actions'+ item._id" :value="item._id" v-model="getPlans.selected_plans">-->
              <!--                                <label :for="'plan_actions'+ item._id" class="no_text checkbox_left"></label>-->
            </p>
          </div>
          <!-- note, this is slowing down  the speed -->
          <div class="head_right">
            <plan-platforms :item="item"></plan-platforms>
          </div>
        </div>

        <div class="event_content">
          <p v-html="parseDescription(planHeadText(item, 'blog'), 100)"></p>

          <div class="img preview-here" v-if="getPlanAttachment(item)">
            <!--data-plan will be use to find plan media to display in the modal-->
            <img
              :src="getResizedImageURL(getPlanAttachment(item), 580, 0)"
              @error="
                $event.target.src = !isStorageLink(getPlanAttachment(item))
                  ? `${getPlanAttachment(item)}`
                  : `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
              "
              id="viewItemAttachment"
              :data-plan="item._id"
              alt=""
            />
          </div>

          <div class="filter_label">
            <template v-for="(label, key) in item.labels">
              <div
                class="label_tag"
                :key="key"
                :class="[getLabelById(label).color]"
                v-if="getLabelById(label)"
                >{{ getLabelById(label).name }}</div
              >
            </template>
          </div>
        </div>

        <!--v-if="item.post_state === 'published'"-->
        <template v-if="item.post_state === 'published'">
          <div class="states">
            <div class="stat_item new-tooltip">
              <div class="icon">
                <i class="fal fa-heart" v-tooltip.top-center="'Total Likes'">
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.likes }}</h3>
              </div>
            </div>

            <div class="stat_item new-tooltip">
              <div class="icon">
                <i class="fal fa-repeat" v-tooltip.top-center="'Total Shares'">
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.shares }}</h3>
              </div>
            </div>

            <div class="stat_item new-tooltip">
              <div class="icon">
                <i
                  class="fal fa-comment-alt-dots"
                  v-tooltip.top-center="'Total Comments'"
                >
                </i>
              </div>
              <div class="value">
                <h3>{{ item.posting_stats.comments }}</h3>
              </div>
            </div>
          </div>
        </template>

        <div class="bottom_action calendar-btn d-flex">
          <!--<template v-if="item.can_perform.approval">-->
          <!--<i id="approveCalendarItem"  title="Approve" class="green_icon calendar_actions check far fa-check"></i>-->
          <!--<i id="rejectCalendarItem" title="Reject" class="red_icon calendar_actions cross far fa-times"></i>-->
          <!--</template>-->

          <template v-if="item.status === 'review' || item.status === 'draft'">
            <template v-if="item.status === 'review' && !item.approval">
              <template v-if="item.can_perform.approval">
                <i
                  data-event="approveCalendarItem"
                  v-tooltip.top-center="'Approve'"
                  class="green_icon calendar_actions check far fa-check"
                ></i>
                <i
                  data-event="rejectCalendarItem"
                  v-tooltip.top-center="'Reject'"
                  class="red_icon calendar_actions cross far fa-times"
                ></i>
              </template>
            </template>

            <template
              v-else-if="
                item.approval &&
                item.approval.status === 'pending_approval' &&
                checkApprovalStatus(item.approval)
              "
            >
              <!--<button  type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'approve')">Approve</button>-->
              <i
                data-event="approvalCalendarItemApprove"
                v-tooltip.top-center="'Approve'"
                class="green_icon calendar_actions check far fa-check"
              ></i>

              <!--<button type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'reject')">Reject</button>-->
              <i
                data-event="approvalCalendarItemReject"
                v-tooltip.top-center="'Reject'"
                class="red_icon calendar_actions cross far fa-times"
              ></i>
            </template>
          </template>

          <template
            v-if="
              getTeamMembership === 'team' &&
              item.status === 'draft' &&
              item.publish_time_options &&
              item.publish_time_options.plan_status === 'Approved' &&
              item.type === 'Composer Social'
            "
          >
            <div class="min_width dropdown_header" data-toggle="dropdown">
              <i
                data-event="hideClientCalendarItem"
                v-tooltip.top-center="hideFromClientMessage(item)"
                v-if="item.publish_time_options.hide_client"
                class="far fa-eye-slash"
              ></i>
              <i
                data-event="hideClientCalendarItem"
                v-tooltip.top-center="hideFromClientMessage(item)"
                v-else
                class="far fa-eye"
              ></i>
            </div>
            <div
              class="dropdown-menu dropdown-menu-right custom-popup eye-visible-pop visibility-popup"
              id="test"
            >
              <div class="popup-inner">
                <template v-if="item.publish_time_options.hide_client">
                  <p>Make this post visible to clients?</p>
                  <div class="buttons">
                    <button data-event="visibleBtn" type="">No</button>
                    <button
                      data-event="visibleBtn"
                      @click.prevent="
                        hideFromClientAction(
                          getPlans.items.filter(
                            (plan) => plan._id === item._id
                          )[0],
                          false
                        )
                      "
                      >Yes</button
                    >
                  </div>
                </template>
                <template v-else>
                  <p>Make this post Invisible to clients?</p>
                  <div class="buttons">
                    <button data-event="visibleBtn" type="">No</button>
                    <button
                      data-event="visibleBtn"
                      @click.prevent="
                        hideFromClientAction(
                          getPlans.items.filter(
                            (plan) => plan._id === item._id
                          )[0],
                          true
                        )
                      "
                      >Yes</button
                    >
                  </div>
                </template>
              </div>
            </div>
          </template>

          <i
            data-event="replaceCalendarItem"
            v-tooltip.top-center="
              'This action will fetch a new post with the same rules you have set in the automation campaign'
            "
            class="calendar_actions refresh far fa-refresh"
            v-if="item.can_perform.replace"
          ></i>
          <i
            data-event="editCalendarItem"
            v-tooltip.top-center="'Edit'"
            class="calendar_actions edit icon-edit-cs"
            v-if="canEditPost(item)"
          ></i>
          <i
            data-event="duplicateCalendarItem"
            v-tooltip.top-center="'Duplicate'"
            class="calendar_actions clone far fa-clone"
            v-if="item.can_perform.duplicate"
          ></i>
          <i
            data-event="deleteCalendarItem"
            v-tooltip.top-center="'Delete'"
            class="delete_icon calendar_actions trash icon-delete-cs"
            v-if="item.can_perform.delete"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlatformName } from '@common/lib/integrations'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import {
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformVisibleStatus
} from '@common/lib/planner'
import { parseDescription } from '@common/lib/helper'
import PlanPlatforms from '../PlanPlatforms'
import { mapGetters } from 'vuex'
import plannerUtilsMixin from '@common/mixins/publish/planner-mixin'

const importedMethods = {
  getPlatformName,
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformVisibleStatus,
  parseDescription,
  getWorkspaceTimeZoneTime
}
export default {
  components: {
    PlanPlatforms
  },
  mixins: [plannerUtilsMixin],
  props: {
    item: {}
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getWorkspaces',
      'getPlannerLoaders',
      'getProfile',
      'getActiveWorkspace',
      'getPublishSelection',
      'getSocialIntegrationsNames'
    ]),
    getItemTag () {
      if (this.item.evergreen_automation_id) return 'Evergreen'
      if (this.item.rss_automation_id) return 'RSS'
      if (this.item.repeat_post) return 'Repeat'
      return ''
    }
  },
  methods: {
    ...importedMethods,
    hideFromClientMessage (item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    getPlanAttachment (item) {
      const attachment = planHeadAttachment(item)
      if (attachment.url) {
        return attachment.type === 'image'
          ? attachment.url
          : attachment.thumbnail
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
i.partially_failed {
  transform: rotate(270deg);
  color: #c00000;
}
</style>
