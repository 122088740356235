<template>
  <div class="p-5 pb-0 pt-0 planner-table">
    <div
      class="flex flex-row items-center justify-between border-0 h-11 my-1.5 relative"
      :class="[
        scroll
          ? scroll && selectedItemsCount > 1
            ? 'mt-0'
            : 'mt-[-48px]'
          : '',
      ]"
      style="transition: all 0.6s"
    >
      <div class="flex flex-row space-x-2.5">
        <!-- Bulk Actions/Dropdown-->
        <CstDropdown
          button-classes="flex justify-between bg-main-gray"
          size="small"
          :disabled="!showBulkActions"
        >
          <template slot="selected"
            ><span
              ><i class="far fa-layer-group mr-2"></i>Bulk Actions</span
            ></template
          >
          <template v-slot>
            <CstDropdownItem
              @click="
                $emit('bulk-action', 'delete', () => (selectedItems = []))
              "
            >
              Delete
            </CstDropdownItem>
            <CstDropdownItem @click="$emit('bulk-action', 'move')">
              Move
            </CstDropdownItem>
            <CstDropdownItem
              v-tooltip="{
                content: disableBulkEdit
                  ? 'Blog posts, and posts with status published, partially failed and failed cannot be bulk edited.'
                  : null,
              }"
              :disabled="disableBulkEdit"
              @click="onClickBulkEdit"
            >
              Edit Social Posts
            </CstDropdownItem>

            <CstDropdownItem @click="$emit('bulk-action', 'share')">
              <div class="flex justify-between items-center">
                Share via Link
                <i
                  v-floatingtip.hover="
                    'Posts on view can be shared via external links only.'
                  "
                  class="far fa-question-circle"
                ></i>
              </div>
            </CstDropdownItem>

            <template v-if="hasPermission('can_schedule_plan')">
              <template v-if="!bulkChangeStatusOptionVisibilityStatus">
                <CstDropdownItem :disabled="true" @click.prevent>
                  <div class="flex justify-between items-center">
                    Approve
                    <i
                      v-floatingtip.hover="
                        'This option can only be applied on \'In Review\' posts.'
                      "
                      class="far fa-question-circle"
                    ></i>
                  </div>
                </CstDropdownItem>
                <CstDropdownItem :disabled="true" @click.prevent>
                  <div class="flex justify-between items-center">
                    Reject
                    <i
                      v-floatingtip.hover="
                        'This option can only be applied on \'In Review\' posts.'
                      "
                      class="far fa-question-circle"
                    ></i>
                  </div>
                </CstDropdownItem>
              </template>

              <template v-else>
                <CstDropdownItem @click="$emit('bulk-action', 'approve')">
                  <div class="flex justify-between items-center"> Approve</div>
                </CstDropdownItem>
                <CstDropdownItem @click="$emit('bulk-action', 'reject')">
                  <div class="flex justify-between items-center"> Reject</div>
                </CstDropdownItem>
              </template>
            </template>
          </template>
        </CstDropdown>

        <!-- Bulk Actions/Select All -->
        <div
          v-if="showBulkActions"
          class="items-center rounded-xl font-normal px-8 py-1.5 text-sm flex justify-between bg-opacity-80 bg- bg-orange-300 text-gray-900 text-opacity-70"
        >
          {{ checkAllItems ? 'All ' + totalItems : selectedItemsCount }} items
          are selected
          <span
            v-if="!checkAllItems && selectedItemsCount !== totalItems"
            class="cursor-pointer text-blue-600"
            @click="$emit('setCheckAllItems', true)"
            >&nbsp; Select All {{ totalItems }}</span
          >
          <span
            v-if="checkAllItems && selectedItemsCount !== totalItems"
            class="cursor-pointer text-blue-600"
            @click="$emit('unSelectAllTableItems', false)"
            >&nbsp;Un-Select All</span
          >
        </div>
      </div>

      <!-- Number of posts and table headers -->
      <div v-if="!loader" class="flex justify-end space-x-3 items-center">
        <span class="font-weight-500 opacity-70"
          >Showing {{ items.length }} of {{ totalItems }} post(s)</span
        >
        <b-dropdown
          class="icon-btn-no-pad"
          no-caret
          right
          size="sm"
          toggle-class="text-decoration-none p-0"
          variant="link"
        >
          <template v-slot:button-content>
            <span
              v-floatingtip.left="'Manage Columns'"
              class="w-8 h-8 flex items-center justify-end cursor-pointer text-xs"
            >
              <img
                height="20"
                src="@assets/img/icons/planner/table-filter.svg"
                width="20"
                alt=""
              />
            </span>
          </template>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox
              v-model="selectedColumns"
              :disabled="true"
              name="post"
            >
              <template slot="label"> Post</template>
            </SimpleCheckbox>
          </b-dropdown-item>

          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="created_by">
              <template slot="label"> Created By</template>
            </SimpleCheckbox>
          </b-dropdown-item>

          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="social_account">
              <template slot="label"> Platforms</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="source">
              <template slot="label"> Post Type</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <!--              <b-dropdown-item @click.native.capture.stop>
                          <SimpleCheckbox v-model="selectedColumns" name="date">
                            <template slot="label">
                              Date
                            </template>
                          </SimpleCheckbox>
                        </b-dropdown-item>-->
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="post_created_at">
              <template slot="label"> Post Created At</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="last_updated_at">
              <template slot="label"> Last Updated At</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="status">
              <template slot="label"> Status</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="created_from">
              <template slot="label"> Created From</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="team_members">
              <template slot="label"> Team Members</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="labels">
              <template slot="label"> Labels</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="content_type">
              <template slot="label"> Content Type</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="content_category">
              <template slot="label"> Content Category</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop>
            <SimpleCheckbox v-model="selectedColumns" name="campaign">
              <template slot="label"> Campaign Folder</template>
            </SimpleCheckbox>
          </b-dropdown-item>
          <!--              <b-dropdown-item @click.native.capture.stop>-->
          <!--                <SimpleCheckbox v-model="selectedColumns" name="campaign">-->
          <!--                  <template slot="label">-->
          <!--                    Campaign-->
          <!--                  </template>-->
          <!--                </SimpleCheckbox>-->
          <!--              </b-dropdown-item>-->
        </b-dropdown>
      </div>
    </div>
    <!-- height is calculated on the basis of top header and planner header -->
    <div
      v-dragscroll
      :style="{
        height: scroll
          ? selectedItemsCount > 1
            ? 'calc(100vh - 125px)'
            : 'calc(100vh - 85px)'
          : 'calc(100vh - 195px)',
        transition: 'all 0.6s',
      }"
      class="bg-white w-full shadow-sm select-none overflow-scroll"
      @dragscrollstart="onDragStart"
    >
      <table
        :class="{ relative: loader }"
        class="w-full text-sm text-left text-gray-900"
      >
        <thead class="text-sm text-gray-900 uppercase">
          <tr>
            <template v-for="({ title, sortable, key }, ind) in columns">
              <th
                v-if="selectedColumns.includes(key)"
                :key="ind"
                scope="col"
                class="px-6 py-3 capitalize font-weight-500"
                :style="{ minWidth: key === 'social_account' ? '200px' : '' }"
              >
                <template v-if="ind === 0">
                  <span class="sr-only">Check</span>
                  <div>
                    <div
                      class="cursor-pointer relative m-0 hover:text-black-900 flex"
                    >
                      <label
                        class="m-0 p-0 d-flex justify-between items-center"
                      >
                        <span class="text-xs"> </span>

                        <input
                          id="select-all-checkbox"
                          ref="select-all-checkbox"
                          class="check-input opacity-0 absolute h-8 w-8"
                          :checked="
                            selectedItems.length !== 0 &&
                            selectedItems.length === items.length
                          "
                          type="checkbox"
                          @change="(event) => handleSelectAll(event)"
                        />

                        <div
                          class="relative cursor-pointer bg-white rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500"
                        >
                          <svg
                            class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
                            version="1.1"
                            viewBox="0 0 17 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <g
                                transform="translate(-9 -11)"
                                fill="#0088cc"
                                fill-rule="nonzero"
                              >
                                <path
                                  d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </label>

                      <span class="ml-6">
                        {{ title }}
                      </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  {{ title }}
                </template>
              </th>
            </template>

            <th
              class="px-6 py-3 text-center capitalize table-auto-width font-weight-500 cursor-pointer"
              scope="col"
            >
              <!-- An Empty Header added to give space to action items -->
            </th>
          </tr>
        </thead>
        <template v-if="loader">
          <div
            :style="{
              width: filterSidebar ? '85%' : '100%',
              marginTop: '50px',
            }"
            class="p-3.5 overflow-hidden fixed"
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </template>
        <template v-else>
          <div
            v-if="items.length === 0"
            :style="{
              width: filterSidebar ? '85%' : '100%',
              marginTop: '50px',
            }"
            class="flex justify-center h-full fixed"
          >
            <ContentAvailableState :type="'no_results'" class="m-0">
              <h2 slot="headline">No posts found</h2>
              <p slot="text"
                >No posts found for the selected filters and date-range.</p
              >
            </ContentAvailableState>
          </div>
        </template>
        <tbody v-show="!loader && items.length" class="cursor-move">
          <template>
            <tr
              v-for="(item, key) in items"
              :key="key"
              :style="`--zIndex: ${items.length}`"
              class="bg-white border-gray-400 list-item-row"
            >
              <th
                v-if="selectedColumns.includes('post')"
                v-floatingtip.top="'Preview Post'"
                class="px-6 py-4 text-sm text-gray-900 cursor-pointer"
                scope="row"
                @click="onClickTableRow(item._id)"
              >
                <div class="flex items-center" style="width: 28rem">
                  <template>
                    <span class="sr-only">Check</span>
                    <SimpleCheckbox
                      v-model="selectedItems"
                      :name="item._id"
                      class="mr-6"
                      @click.native.stop
                    ></SimpleCheckbox>
                  </template>
                  <div class="relative">
                    <template v-if="getPlanMediaType(item) === 'video'">
                      <VideoLightBox
                        :is-compact-view="isCompactView"
                        :item="item"
                      />
                    </template>
                    <div
                      v-else
                      class="cursor-pointer flex-shrink-0"
                      @click.stop="
                        () => openImageLightBox(getAllPlanAttachments(item))
                      "
                    >
                      <img
                        :class="isCompactView ? 'w-20 h-14' : 'w-32 h-24'"
                        :src="
                          getResizedImageURL(getPlanAttachment(item), 150, 0)
                        "
                        alt=""
                        class="rounded pointer-events-none object-cover mr-3.5"
                        @error="onErrorHandle($event)"
                      />
                    </div>
                    <span
                      v-if="getPlanMediaType(item)"
                      class="absolute bottom-1 right-4 p-1 bg-black-100 opacity-70 text-white rounded-md h-7 text-center text-capitalize"
                      >{{ getPlanMediaType(item) }}</span
                    >
                  </div>

                  <div
                    :class="isCompactView ? 'line-clamp-2' : 'line-clamp-4'"
                    class="font-normal select-text w-full"
                  >
                    <div
                      v-if="
                        parseDescriptionV2(
                          planHeadText(item, 'blog'),
                          320
                        ).trim().length === 0
                      "
                    >
                      <img
                        :class="isCompactView ? 'h-9' : ''"
                        :src="
                          require('@src/assets/img/no_data_images/no_caption.svg')
                        "
                        alt="No caption found!"
                        class="object-cover w-full"
                        draggable="false"
                      />
                    </div>
                    <div
                      v-else
                      v-html="
                        parseDescriptionV2(planHeadText(item, 'blog'), 320)
                      "
                    ></div>
                  </div>
                </div>
              </th>
              <td
                v-if="selectedColumns.includes('social_account')"
                class="px-6 py-4"
              >
                <div class="flex justify-start items-center">
                  <template v-if="item.blog_selection">
                    <template v-for="(integration, ind) in integrations">
                      <template
                        v-if="
                          item.blog_selection[integration] &&
                          item.blog_selection[integration].website
                        "
                      >
                        <CstSocialIcon
                          :key="ind"
                          v-tooltip.top-center="
                            getBlogURL(
                              integration,
                              item.blog_selection[integration].website
                            )
                          "
                          :icon="integration"
                          class="block text-4xl"
                        />
                      </template>
                    </template>
                  </template>
                  <template v-if="item.account_selection">
                    <template v-for="(account, ind) in postAccounts(item)">
                      <span
                        v-if="ind < 2"
                        :key="ind"
                        v-floatingtip="
                          'platform_name' in account
                            ? account.platform_name
                            : account.name
                        "
                        class="relative inline-block mr-1.5"
                      >
                        <img
                          :src="channelImage(account, account.platform)"
                          alt=""
                          class="rounded-full object-cover border pointer-events-none w-9 h-9"
                          data-fallback="0"
                          @error="onError($event, account.image)"
                        />

                        <CstSocialIcon
                          :key="ind"
                          :icon="account.platform"
                          class="rounded-full w-4 h-4 text-white block absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
                        />
                      </span>
                      <b-dropdown
                        v-if="ind === 2"
                        :key="ind + 'dropdown'"
                        :menu-class="{
                          'h-96 overflow-y-auto':
                            postAccounts(item).length > 10,
                        }"
                        no-caret
                        toggle-class="bg-white rounded-full border-0 p-0"
                        variant="primary"
                      >
                        <template v-slot:button-content>
                          <span
                            :key="ind"
                            class="inline-flex justify-center items-center w-9 h-9 bg-gray-400 rounded-full text-xs"
                          >
                            +{{ postAccounts(item).length - 2 }}
                          </span>
                        </template>
                        <template
                          v-for="(dropAcc, accKey) in postAccounts(item)"
                        >
                          <b-dropdown-item :key="accKey">
                            <div v-if="accKey > 1" :key="accKey">
                              <span class="relative inline-block mr-1.5">
                                <img
                                  :src="channelImage(dropAcc, dropAcc.platform)"
                                  alt=""
                                  class="rounded-full object-cover border pointer-events-none w-9 h-9"
                                  data-fallback="0"
                                  @error="onError($event, dropAcc.image)"
                                />
                                <CstSocialIcon
                                  :icon="dropAcc.platform"
                                  class="rounded-full w-4 h-4 text-white block absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
                                />
                              </span>
                              <span class="text-sm">
                                {{
                                  truncateString(
                                    'platform_name' in dropAcc
                                      ? dropAcc.platform_name
                                      : dropAcc.name,
                                    30
                                  )
                                }}
                              </span>
                            </div>
                          </b-dropdown-item>
                        </template>
                      </b-dropdown>
                    </template>
                  </template>
                </div>
              </td>
              <td v-if="selectedColumns.includes('status')" class="px-6 py-4">
                <div class="flex flex-col space-y-2">
                  <div class="flex justify-start items-center cursor-pointer">
                    <span
                      :class="
                        item['partially_failed']
                          ? getStatusColorClass('partially_failed')
                          : getStatusColorClass(item['status'])
                      "
                      class="font-semibold capitalize"
                      >{{
                        item['partially_failed']
                          ? 'Partially Failed'
                          : item['render_class']['tooltip']
                      }}</span
                    >
                    <i
                      class="far fa-external-link-alt ml-1.5 cursor-pointer"
                      @click.stop="handleExtra(item, 'status')"
                    ></i>
                  </div>
                  <div
                    v-if="item.execution_time && item.execution_time.date"
                    class="text-xs"
                  >
                    {{
                      getWorkspaceTimeZoneTime(
                        item.execution_time.date,
                        'DD MMM YY, hh:mm A'
                      )
                    }}
                  </div>
                </div>
              </td>
              <td
                v-if="selectedColumns.includes('post_created_at')"
                class="px-6 py-4"
              >
                <p :class="isCompactView ? 'text-xs' : 'text-sm'">
                  {{
                    getWorkspaceTimeZoneTime(
                      item['created_at'],
                      'DD MMM YY, hh:mm A'
                    )
                  }}
                </p>
              </td>
              <td v-if="selectedColumns.includes('source')" class="px-6 py-4">
                <div
                  v-if="isBlogPost(item)"
                  class="bg-blue-100 bg-opacity-20 rounded p-2 px-4 inline-block"
                  >Blog Post
                </div>
                <div
                  v-if="isSocialPost(item)"
                  class="bg-blue-100 bg-opacity-20 rounded p-2 px-4 inline-block"
                  >Social Post
                </div>
              </td>
              <td
                v-if="selectedColumns.includes('content_category')"
                class="px-6 py-4"
              >
                <span
                  v-if="item.content_category"
                  :style="{
                    'background-color': item.content_category.color_code,
                  }"
                  class="inline-flex p-2 text-xs font-semibold leading-5 rounded-full text-white"
                  >{{ item.content_category.name }}
                </span>
                <span v-else>-</span>
                <!--            <span class="inline-flex px-2 text-xs font-semibold leading-5 rounded-full text-white label_green">Silver</span>-->
              </td>
              <td
                v-if="selectedColumns.includes('content_type')"
                class="px-6 py-4"
              >
                <div v-if="getItemTag(item)">{{ getItemTag(item) }}</div>
                <div v-else>-</div>
              </td>
              <td v-if="selectedColumns.includes('labels')" class="px-6 py-4">
                <template v-if="item.labels && item.labels.length > 0">
                  <template v-for="(label, ind) in item.labels">
                    <span
                      v-if="getLabelById(label)"
                      :key="ind"
                      :class="[getLabelById(label).color]"
                      class="tag inline-block px-2 text-xs font-semibold leading-5 rounded-full mr-1.5 text-white truncate"
                      style="max-width: 6rem"
                      >{{ getLabelById(label).name }}</span
                    >
                  </template>
                </template>
                <template v-else> -</template>
                <!--            <span class="inline-flex px-2 text-xs font-semibold leading-5 rounded-full cst-pill label_blue">Label 1</span>-->
              </td>
              <td
                v-if="selectedColumns.includes('team_members')"
                class="px-6 py-4"
              >
                <div v-if="item.members && item.members.length > 0">
                  <div
                    v-for="(member, teamKey) in item.members"
                    :key="teamKey"
                    class="relative inline-block mr-1.5"
                  >
                    <span
                      v-floatingtip="{
                        content: () =>
                          teamMemberTooltipHtmlById(
                            selectedTeamMemberDetailById(member)
                          ),
                        html: true,
                      }"
                    >
                      <img
                        v-if="teamKey < 2"
                        :src="teamMemberImageById(member)"
                        alt=""
                        class="rounded-full object-cover border w-9 h-9"
                        data-fallback="0"
                      />
                    </span>
                    <b-dropdown
                      v-if="teamKey === 2"
                      :menu-class="{
                        'h-96 overflow-y-auto': item.members.length > 10,
                      }"
                      no-caret
                      toggle-class="bg-white p-0 border-0 rounded-full"
                      variant="primary"
                    >
                      <template v-slot:button-content>
                        <span
                          :key="teamKey"
                          class="inline-flex justify-center items-center w-9 h-9 bg-gray-400 rounded-full text-xs"
                        >
                          +{{ item.members.length - 2 }}
                        </span>
                      </template>
                      <template
                        v-for="(teamMember, memberIndex) in item.members"
                      >
                        <b-dropdown-item
                          v-if="memberIndex > 1"
                          :key="`team_${memberIndex}_${teamMember}`"
                          href="#"
                        >
                          <div
                            v-html="
                              teamMemberDropdownItem(
                                selectedTeamMemberDetailById(teamMember)
                              )
                            "
                          ></div>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </div>
                <template v-else> -</template>
              </td>
              <td v-if="selectedColumns.includes('campaign')" class="px-6 py-4">
                <span v-if="item.compose_folder">{{
                  item.compose_folder.name
                }}</span>
                <span v-else>{{ getDefaultCampaignName }}</span>
              </td>
              <td
                v-if="selectedColumns.includes('created_by')"
                class="px-6 py-4"
              >
                <span>{{ item.added_by }}</span>
              </td>
              <td
                v-if="selectedColumns.includes('created_from')"
                class="px-6 py-4"
              >
                <div v-html="item['created_from']"></div>
              </td>
              <td
                v-if="selectedColumns.includes('last_updated_at')"
                class="px-6 py-4"
              >
                <p :class="isCompactView ? 'text-xs' : 'text-sm'">
                  <span>{{
                    getWorkspaceTimeZoneTime(
                      item['updated_at'],
                      'DD MMM YY, hh:mm A'
                    )
                  }}</span>
                </p>
              </td>

              <!--          <td v-if="selectedColumns.includes('campaign')" class="px-6 py-4">-->
              <!--            Laptop-->
              <!--          </td>-->

              <!-- Action Buttons-->
              <th
                class="px-6 py-4 text-right whitespace-nowrap table-auto-width list-item-row__action-items"
              >
                <div class="flex justify-end">
                  <div
                    class="flex space-x-2 justify-center items-center bg-white p-2 border rounded-md"
                  >
                    <!-- Review Buttons -->
                    <template v-if="!isInProgress(item)">
                      <template
                        v-if="item.status === 'review' && !item.approval"
                      >
                        <!-- review case -->
                        <span
                          v-floatingtip.top="'Approve'"
                          :disabled="item.is_processing"
                          class="my-auto cursor-pointer bg-white p-1 border rounded-md w-8 h-8 hover:!border-gray-900"
                          @click.stop="
                            changePlanStatusMethod('scheduled', item, true)
                          "
                        >
                          <div class="flex items-center justify-center">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/approve.svg"
                            />
                          </div>
                        </span>

                        <span
                          v-floatingtip.top="'Reject'"
                          :disabled="item.is_processing"
                          class="my-auto cursor-pointer bg-white p-1 border rounded-md w-8 h-8 hover:!border-gray-900"
                          @click.stop="
                            changePlanStatusMethod('rejected', item, true)
                          "
                        >
                          <div class="flex items-center justify-center">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_reject_post.svg"
                            />
                          </div>
                        </span>
                      </template>
                      <template
                        v-else-if="
                          item.approval &&
                          item.approval.status === 'pending_approval' &&
                          checkApprovalStatus(item.approval)
                        "
                      >
                        <div
                          v-floatingtip.top="'Approve'"
                          class="my-auto cursor-pointer bg-white p-1 border rounded-md w-8 h-8 hover:!border-gray-900"
                          @click.stop="$emit('approve-with-comment', item)"
                        >
                          <div class="flex items-center justify-center">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/approve.svg"
                            />
                          </div>
                          <!--                    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6 9.5L9 12.5L14 7.5M10 19C7.61305 19 5.32387 18.0518 3.63604 16.364C1.94821 14.6761 1 12.3869 1 10V10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1V1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10V10C19 12.3869 18.0518 14.6761 16.364 16.364C14.6761 18.0518 12.3869 19 10 19V19Z"
                                                stroke="#56C288" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                          </svg>-->
                        </div>
                        <div
                          v-floatingtip.top="'Reject'"
                          class="my-auto cursor-pointer bg-white p-1 border rounded-md w-8 h-8 hover:!border-gray-900"
                          @click.stop="$emit('reject-with-comment', item)"
                        >
                          <div class="flex items-center justify-center">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_reject_post.svg"
                            />
                          </div>
                          <!--                    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 13L13 7M19 10C19 11.78 18.4722 13.5201 17.4832 15.0001C16.4943 16.4802 15.0887 17.6337 13.4442 18.3149C11.7996 18.9961 9.99002 19.1743 8.24419 18.8271C6.49836 18.4798 4.89472 17.6226 3.63604 16.364C2.37737 15.1053 1.5202 13.5016 1.17294 11.7558C0.82567 10.01 1.0039 8.20038 1.68509 6.55585C2.36628 4.91131 3.51983 3.50571 4.99987 2.51677C6.47991 1.52784 8.21997 1 10 1C12.387 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10V10ZM13 13L7 7L13 13Z"
                                                stroke="#FF4068" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                          </svg>-->
                        </div>
                      </template>
                    </template>
                    <!-- Edit Button -->
                    <span
                      v-if="
                        showEditButton(item) &&
                        !showReviewButtons(item) &&
                        !isInProgress(item)
                      "
                      v-floatingtip.top="'Edit'"
                      :disabled="isActionProcessing(item)"
                      class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8 hover:!border-gray-900"
                      @click.stop="editPlan(item)"
                    >
                      <div class="flex items-center justify-center">
                        <img
                          alt="approval_publish"
                          src="@assets/img/icons/planner/more_actions_edit.svg"
                        />
                      </div>
                    </span>
                    <!-- Duplicate Button -->
                    <span
                      v-if="
                        showDuplicateButton(item) &&
                        !showReviewButtons(item) &&
                        !showEditDelButtons(item)
                      "
                      v-floatingtip.top="'Duplicate'"
                      :disabled="isActionProcessing(item)"
                      class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8 hover:!border-gray-900"
                      @click.stop="clonePlan(item)"
                    >
                      <div class="flex items-center justify-center">
                        <img
                          alt="approval_publish"
                          src="@assets/img/icons/planner/more_actions_duplicate.svg"
                        />
                      </div>
                    </span>
                    <!-- Replace Post Button -->
                    <span
                      v-if="
                        showReplaceButton(item) &&
                        !isInProgress(item) &&
                        !showReviewButtons(item) &&
                        !showEditDelButtons(item)
                      "
                      v-floatingtip.top="'Replace Post'"
                      :disabled="isActionProcessing(item)"
                      class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8 hover:!border-gray-900"
                      @click.stop="replacePlan(item)"
                    >
                      <div class="flex items-center justify-center">
                        <img
                          alt="approval_publish"
                          src="@assets/img/icons/planner/more_actions_replace.svg"
                        />
                      </div>
                    </span>
                    <!-- Delete Button -->
                    <span
                      v-if="
                        showDelButton(item) &&
                        !showReviewButtons(item) &&
                        !isInProgress(item)
                      "
                      v-floatingtip.top="'Delete'"
                      :disabled="isActionProcessing(item)"
                      class="flex items-center justify-center cursor-pointer p-2 rounded-md bg-white w-8 h-8"
                      @click.stop="removePlan(item._id)"
                    >
                      <div class="flex items-center justify-center">
                        <i
                          class="far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        ></i>
                      </div>
                    </span>
                    <!-- Move Folder Button -->
                    <!--              <span v-if="!showEditDelButtons(item) && !showReviewButtons(item) && !showReplaceButton(item)"
                                    v-floatingtip.left="'Move to Campaign Folder'"
                                    :disabled="isActionProcessing(item)"
                                    class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-10 h-10"
                                    @click.prevent="openMoveCampaignModel(item['folderId'],[item['_id']])">
                                <div class="flex items-center justify-center">
                                    <img alt="approval_publish" src="@assets/img/icons/planner/more_actions_move_camp.svg">
                                </div>
                              </span>-->

                    <!-- More Actions -->
                    <b-dropdown
                      class="icon-btn-no-pad relative z-20"
                      menu-class="planner-more-actions-dropdown"
                      no-caret
                      right
                      size="sm"
                      toggle-class="text-decoration-none p-0"
                      variant="link"
                    >
                      <template v-slot:button-content>
                        <span
                          v-floatingtip.top="'More actions'"
                          class="flex items-center justify-center cursor-pointer"
                        >
                          <div
                            class="relative bg-white p-1 flex items-center justify-center border rounded-md w-8 h-8 hover:!border-gray-900"
                          >
                            <div class="flex items-center justify-center">
                              <img
                                alt="approval_publish"
                                src="@assets/img/icons/planner/planner_more_actions.svg"
                              />
                            </div>
                            <div
                              v-if="
                                commentsLength(item) ||
                                internalNotesLength(item)
                              "
                              class="absolute -top-3 -right-3 w-6 h-6 text-xs text-white bg-red-500 rounded-full flex items-center justify-center p-1"
                            >
                              {{
                                commentsLength(item) + internalNotesLength(item)
                              }}
                            </div>
                          </div>
                        </span>
                      </template>

                      <!-- Duplicate Post -->
                      <b-dropdown-item
                        v-if="
                          showDuplicateButton(item) &&
                          !showReviewButtons(item) &&
                          showEditDelButtons(item)
                        "
                        :disabled="isActionProcessing(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="clonePlan(item)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_duplicate.svg"
                            />
                          </div>
                          <div> Duplicate Post</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Replace Post -->
                      <b-dropdown-item
                        v-if="item.can_perform.replace"
                        :disabled="isActionProcessing(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="replacePlan(item)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_replace.svg"
                            />
                          </div>
                          <div> Replace Post</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Edit Post -->
                      <b-dropdown-item
                        v-if="
                          showEditButton(item) &&
                          showReviewButtons(item) &&
                          !isInProgress(item)
                        "
                        :disabled="isActionProcessing(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="editPlan(item)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_edit.svg"
                            />
                          </div>
                          <div> Edit Post</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Delete Post -->
                      <b-dropdown-item
                        v-if="showDelButton(item) && showReviewButtons(item)"
                        :disabled="isActionProcessing(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="removePlan(item._id)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_delte.svg"
                            />
                          </div>
                          <div> Delete Post</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Move Folder -->
                      <b-dropdown-item
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="
                          openMoveCampaignModel(item['folderId'], [item['_id']])
                        "
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_move_camp.svg"
                            />
                          </div>
                          <div> Move to Campaign Folder</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Comments -->
                      <b-dropdown-item
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="handleExtra(item, 'commentsAccordion')"
                      >
                        <div
                          class="flex items-center justify-between space-x-2"
                        >
                          <div class="flex flex-row items-center space-x-2">
                            <div
                              class="flex items-center justify-center w-6 h-6"
                            >
                              <img
                                alt="approval_publish"
                                src="@assets/img/icons/planner/more_actions_comments.svg"
                              />
                            </div>
                            <div> Comments</div>
                          </div>
                          <div
                            v-if="commentsLength(item) > 0"
                            class="px-1 text-xs text-white bg-red-500 font-semibold rounded-full flex items-center justify-center"
                          >
                            {{ commentsLength(item) }}
                          </div>
                        </div>
                      </b-dropdown-item>

                      <!-- Internal Notes -->
                      <b-dropdown-item
                        v-if="!clientProfile"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="
                          handleExtra(item, 'internalNotesAccordion')
                        "
                      >
                        <div
                          class="flex items-center justify-between space-x-2"
                        >
                          <div class="flex flex-row items-center space-x-2">
                            <div
                              class="flex items-center justify-center w-6 h-6"
                            >
                              <img
                                alt="approval_publish"
                                src="@assets/img/icons/planner/more_actions_internal_note.svg"
                              />
                            </div>
                            <div>
                              <div>Internal Notes</div>
                            </div>
                          </div>
                          <div
                            v-if="internalNotesLength(item) > 0"
                            class="px-1 text-xs text-white bg-red-500 rounded-full font-semibold rounded-full flex items-center justify-center"
                          >
                            {{ internalNotesLength(item) }}
                          </div>
                        </div>
                      </b-dropdown-item>

                      <!-- Approval Status-->
                      <b-dropdown-item
                        v-if="showApprovalStatus(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="handleExtra(item)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_plan_actions_approval_status_1.svg"
                            />
                          </div>
                          <div> Approval Status</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Post Details -->
                      <b-dropdown-item
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="handleExtra(item, 'status')"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_plan_actions_post_status_1.svg"
                            />
                          </div>
                          <div> Post Status</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Hide from client -->
                      <b-dropdown-item
                        v-if="
                          !clientProfile &&
                          item.publish_time_options &&
                          ((item.status === 'draft' &&
                            item.publish_time_options.hide_client) ||
                            (item.status === 'draft' &&
                              !item.publish_time_options.hide_client))
                        "
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                      >
                        <div
                          v-if="
                            item.status === 'draft' &&
                            item.publish_time_options.hide_client
                          "
                          @click.prevent="hideFromClientAction(item, false)"
                        >
                          <div class="flex items-center space-x-2">
                            <div
                              class="flex items-center justify-center w-6 h-6"
                            >
                              <img
                                alt="approval_publish"
                                src="@assets/img/icons/planner/hide_from_client.svg"
                              />
                            </div>
                            <div> {{ hideFromClientMessage(item) }}</div>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            item.status === 'draft' &&
                            !item.publish_time_options.hide_client
                          "
                          @click.prevent="hideFromClientAction(item, true)"
                        >
                          <div class="flex items-center space-x-2">
                            <div
                              class="flex items-center justify-center w-6 h-6"
                            >
                              <img
                                alt="approval_publish"
                                src="@assets/img/icons/planner/unhide_from_client.svg"
                              />
                            </div>
                            <div> {{ hideFromClientMessage(item) }}</div>
                          </div>
                        </div>
                      </b-dropdown-item>

                      <!-- @if isTwitterPosting && item.status === 'published && threads_response && threads_response.length > 0'-->

                      <!-- Threaded Tweets -->
                      <b-dropdown-item
                        v-if="hasThreadedTweetResponse(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="showTweetsResponse(item)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_threaded_tweet.svg"
                            />
                          </div>
                          <div> Threaded Tweet</div>
                        </div>
                      </b-dropdown-item>

                      <!-- First Comments -->
                      <b-dropdown-item
                        v-if="hasFirstCommentResponse(item)"
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="handleExtra(item, 'status')"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <img
                              alt="approval_publish"
                              src="@assets/img/icons/planner/more_actions_first_comment.svg"
                            />
                          </div>
                          <div> First Comment</div>
                        </div>
                      </b-dropdown-item>

                      <!-- Share Plan -->
                      <b-dropdown-item
                        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
                        link-class="px-2"
                        @click.stop="sharePlan(item._id)"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="flex items-center justify-center w-6 h-6">
                            <i
                              class="far fa-link text-gray-700 !bg-transparent !shadow-none"
                            ></i>
                          </div>
                          <div> Share via Link </div>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </th>
            </tr>

            <!--        <SkeletonBox :key="i + '-loader-1'" width="100%" height="45px" radius="12px"/>-->
            <tr v-show="items.length !== totalItems" class="z-0">
              <td class="table-infinite-loading pl-2 pb-4" colspan="8">
                <InfiniteLoading class="mt-4" @infinite="infiniteHandler">
                  <span slot="spinner">
                    <div class="grid grid-cols-9 gap-6 mt-3">
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox
                        class="col-span-2"
                        height="45px"
                        radius="12px"
                        width="100%"
                      />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                      <SkeletonBox height="45px" radius="12px" width="100%" />
                    </div>
                  </span>
                  <div
                    slot="no-results"
                    class="flex my-3 py-3 px-2 rounded-md bg-white"
                  >
                    No more post found!
                  </div>
                  <span
                    slot="no-more"
                    class="flex my-3 py-3 px-2 rounded-md bg-white"
                  >
                    No more results are available.
                  </span>
                </InfiniteLoading>
              </td>
            </tr>

            <tr v-show="items.length === totalItems" class="">
              <td class="py-5" colspan="8">
                <div class="flex items-center justify-center font-medium"
                  >you are up to date!</div
                >
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <VueEasyLightbox
        :visible="visible"
        :imgs="imgs"
        @hide="handleHide"
      ></VueEasyLightbox>

      <b-modal
        id="post-details-modal"
        body-class="p-0 rounded-md shadow-sm overflow-hidden padding-bottom-0"
        size="md"
        centered
        :no-close-on-backdrop="true"
        hide-header
        hide-footer
        dialog-class="max-w-3xl"
        modal-class="full_height first_comment_response right side_slide_right"
      >
        <PlannerAside
          :comments-accordion="commentsAccordion"
          :internal-notes-accordion="internalNotesAccordion"
          :external-action="externalAction"
          :post-status-accordion="postStatusAccordion"
          :item="selectedPost"
          modal-id="post-details-modal"
        />
      </b-modal>

      <b-modal
        id="post-status-modal"
        size="lg"
        centered
        :no-close-on-backdrop="true"
        hide-header
        hide-footer
        dialog-class="max-w-7xl"
      >
        <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
      </b-modal>

      <!-- Approve Post Modal-->
      <!--      <b-modal
        id="approve-post-modal"
        :no-close-on-backdrop="true"
        centered
        dialog-class="max-w-4xl"
        hide-footer
        hide-header
        size="lg"
      >
        <ApprovalConfirmation
          :btn-loading="btnLoading"
          :plan-for-approval="planForApproval"
          @callProcessPlanApproval="callProcessPlanApproval"
          @onClickCancelApprove="onClickCancelApprove"
        />
      </b-modal>-->

      <!-- Reject Post Modal -->
      <!--      <b-modal
        id="reject-post-modal"
        :no-close-on-backdrop="true"
        centered
        dialog-class="max-w-3xl"
        hide-footer
        hide-header
        size="lg"
      >
        <RejectionConfirmation
          :plan-for-reject="planForReject"
          @callProcessPlanApproval="callProcessPlanApproval"
          @onClickCancelReject="onClickCancelReject"
        />
      </b-modal>-->
    </div>
  </div>
</template>

<script>
import PlannerAside from '@src/modules/planner_v2/components/PlannerAside'
import SimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import { dragscroll } from 'vue-dragscroll'
import VueEasyLightbox from 'vue-easy-lightbox'
import {
  getIconClass,
  getStatusColorClass,
  getStatusColorCode,
} from '@src/modules/planner_v2/utils'
import isEmpty from 'is-empty'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import ContentAvailableState from '@src/components/states/ContentAvailableState'
import {
  blogIntegrationsConfigrations,
  blogIntegrationsNames,
} from '@src/modules/integration/config/api-utils'
import CstSocialIcon from '@ui/SocialIcon/CstSocialIcon'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import {
  getPlatformByIdentifier,
  getPlatformIdentifierValue,
  getPlatformName,
  platformImage,
} from '@common/lib/integrations'
import {
  getStatusClass,
  planHeadAttachment,
  planHeadText,
  platformCommentPostingStatus,
  platformPosting,
  platformPostingStatus,
  allPlanHeadAttachments,
} from '@common/lib/planner'
import { swalAttributes } from '@common/constants/common-attributes'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import {
  memoizeCallback,
  parseDescription,
  parseDescriptionHtml,
} from '@common/lib/helper'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
import VideoLightBox from '@ui/Media/VideoLightBox'
import { stringMixin } from '@common/mixins/utils/stringMixin'

import 'linkify-plugin-hashtag'
import 'linkify-plugin-mention'

import { approvalMixin } from '@common/mixins/publish/approvalMixin'
import { mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";

const importedMethods = {
  getStatusClass,
  planHeadText,
  parseDescription,
  platformImage,
  getPlatformName,
  platformPosting,
  getPlatformIdentifierValue,
}

// Memoize the callback functions
const parseDescriptionHtmlMemoized = memoizeCallback(parseDescriptionHtml)

export default {
  name: 'CstDataTable',
  directives: {
    dragscroll,
  },
  components: {
    SimpleCheckbox,
    VueEasyLightbox,
    PlannerAside,
    SkeletonBox,
    ContentAvailableState,
    InfiniteLoading,
    PlannerPostStatus,
    VideoLightBox,
    CstSocialIcon,
    CstDropdown,
    CstDropdownItem,
  },
  mixins: [Helpers, stringMixin, approvalMixin],
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItemsCount: {
      type: Number,
      default: 0,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    checkAllItems: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    filterSidebar: {
      type: Boolean,
      default: false,
    },
    bulkChangeStatusOptionVisibilityStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      at_bottom: false,
      scroll: false,
      selectedPost: null,
      selectedItems: [],
      visible: false,
      index: 0, // default: 0
      imgs: '',
      selectedColumns: [
        'post',
        'created_by',
        'post_created_at',
        'last_updated_at',
        'content_category',
        'source',
        'social_account',
        'created_from',
        'status',
        'team_members',
        'labels',
        'content_type',
        'campaign',
      ],
      columns: [
        {
          title: 'Post',
          key: 'post',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Accounts',
          key: 'social_account',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Status',
          key: 'status',
          sortable: true,
          autoWidth: false,
        },
        {
          title: 'Post Created At',
          key: 'post_created_at',
          sortable: true,
          autoWidth: false,
        },
        {
          title: ' Post Type',
          key: 'source',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Content Category',
          key: 'content_category',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Content Type',
          key: 'content_type',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Labels',
          key: 'labels',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Team Members',
          key: 'team_members',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Campaign Folder',
          key: 'campaign',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Created By',
          key: 'created_by',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Created From',
          key: 'created_from',
          sortable: false,
          autoWidth: false,
        },
        {
          title: 'Last Updated At',
          key: 'last_updated_at',
          sortable: true,
          autoWidth: false,
        },
      ],
      integrations: blogIntegrationsNames,
      configrations: blogIntegrationsConfigrations,
      showComment: false,
      commentsAccordion: false,
      internalNotesAccordion: false,
      externalAction: false,
      postStatusAccordion: false,
      approvePostComment: '',
      rejectPostComment: '',
      planForApproval: null,
      planForReject: null,
      btnLoading: false,
      scrollCount: 0,
      dragged: false,
      dragTimeout: null,
      infiniteHandlerLoader: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getWorkspaces']),
    isCompactView() {
      return this.$route.name === 'planner_list_compact_v2'
    },
    disableBulkEdit() {
      return this.selectedItems.some((selectedItem) => {
        const itemObj = this.items.find((item) => {
          return item._id === selectedItem
        })
        return (
          itemObj.post_state === 'published' ||
          itemObj.post_state === 'failed' ||
          itemObj.partially_failed ||
          'blog_selection' in itemObj
        )
      })
    },
  },
  watch: {
    selectedItems(val) {
      this.$emit('item-select', val)
    },
    loader(val) {
      if (val && !this.infiniteHandlerLoader) {
        this.selectedItems = []
      }
    },
    checkAllItems(val) {
      console.log('WATCH::checkAllItems ~ val -> ', val)
      if (val === false) {
        if (this.$refs['select-all-checkbox']) {
          this.$refs['select-all-checkbox'][0].checked = val
          this.$refs['select-all-checkbox'][0].dispatchEvent(
            new Event('change')
          ) // manually dispatching event on val change
        }
      }
    },
    items() {
      console.log(
        'WATCH::items ~ this.items -> ',
        this.$refs['select-all-checkbox']
      )
      if (this.$refs['select-all-checkbox'] && this.checkAllItems) {
        this.$refs['select-all-checkbox'][0].checked = this.checkAllItems
        this.$refs['select-all-checkbox'][0].dispatchEvent(new Event('change')) // manually dispatching event on val change
      }
    },
  },
  mounted() {
    EventBus.$on('hide-data-table-header', (scroller) => {
      this.scroll = scroller
    })

    EventBus.$on('post-status-modal', (post) => {
      this.selectedPost = post
      this.$bvModal.show('post-status-modal')
    })
  },
  beforeDestroy() {
    EventBus.$off('post-status-modal')
  },
  methods: {
    ...importedMethods,
    onErrorHandle(event) {
      event.target.src = require('@src/assets/img/no_data_images/no_media_found.svg')
    },
    getPlanMediaType(item) {
      if (item.carousel_options?.is_carousel_post) return 'Carousel'
      const attachment = planHeadAttachment(item)

      if (attachment.url) {
        return attachment.type
      }

      return false
    },
    getPlanAttachment(item) {
      const attachment = planHeadAttachment(item)
      if (attachment.url) {
        return attachment.type === 'image'
          ? attachment.url
          : attachment.thumbnail
      }
      return ''
    },

    /**
     * Plucks and returns all the media attached in a post.
     * @param item
     * @returns {*|{type: string, url: *}|boolean|{thumbnail: *, type: string, url: *}|{type: string, url: *}|{thumbnail: *, type: string, url: *}|{type: string, url: *}|{thumbnail: *, type: string, url: *}|{thumbnail: *, type: string, url: *}|[]|boolean}
     */
    getAllPlanAttachments(item) {
      return allPlanHeadAttachments(item)
    },
    handleSelectAll(event) {
      console.log('METHOD::handleSelectAll')
      if (event.target.checked) {
        this.selectedItems = this.items.map((item) => item._id)
        // this.$emit('set-check-all-items', true)
      } else {
        this.selectedItems = []
        this.$emit('set-check-all-items', false)
      }
    },
    hideFromClientMessage(item) {
      if (!item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },

    async addCommentAction(item, notification = false) {
      console.debug('addCommentAction', item)

      if (!item.new_comment || item.new_comment.trim().length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      }
      this.$set(item, 'loader_store', true)

      const payload = {
        workspace_id: item.workspace_id,
        plan_id: item._id,
        comment: item.new_comment,
        mentioned_user: this.mentionedUserIdsList(item.new_comment),
        type: this.$route.name === 'planner_list_v2' ? 'list_view' : '',
        title: item.title || null,
        is_note: true,
        media: item.new_comment_media ? item.new_comment_media : [],
        notification: notification,
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)
      this.$set(item, 'loader_store', false)
      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          if (!item.comments) item.comments = []
          // item.comments.unshift(res.data.comment)
          item.new_comment = ''
          item.write_comment = false
          item.new_comment_media = []
          this.messageType = 'message'
          // this.dispatchCommentSocket(res.data.comment,item._id,'add')
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
        this.toggleStatus = true
      }
    },

    addCommentStatus(type, index) {
      this.commentActionStatus = type
      this.$set(this.item, 'new_comment', '')
      this.toggleStatus = false
      this.getPlans.items[index].is_active = true
      if (
        this.item.status === 'review' &&
        type === 'approve' &&
        this.item.execution_time &&
        this.item.execution_time.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        this.initializeMissedReviewedDatePicker(this.item)
      }
    },

    async infiniteHandler($state) {
      this.$emit('handle-change', (state) => {
        console.log('infiniteHandler', state)
        this.infiniteHandlerLoader = true

        if (state) {
          $state.complete()
          this.infiniteHandlerLoader = false
        } else {
          $state.loaded()
          this.infiniteHandlerLoader = false
        }
      })
    },

    async removePlan(plan) {
      console.debug('Method::removePlan', plan)
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your post?',
        {
          title: 'Remove Post',
          ...swalAttributes(),
        }
      )
      if (res) {
        const response = await this.$store.dispatch('removePlan', { id: plan })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your post, please try again.',
            type: 'error',
          })
        } else {
          this.items.splice(
            this.items.findIndex((item) => item._id === plan),
            1
          )
          this.$emit('fetch-plans-after-delete')
        }
      }
    },

    clonePlan(item) {
      console.debug('Method::clonePlan', item)
      item.stateObject = this
      this.$store.dispatch('clonePlan', item)
    },
    editPlan(item) {
      console.debug('Method::editPlan', item)
      item.stateObject = this
      this.$store.dispatch('editPlan', item)
    },

    /**
     * Calls an api to replace post and update the table item with the newly replaced post.
     * @param plan
     * @returns {Promise<void>}
     */
    async replacePlan(plan) {
      console.debug('Method::replacePlan-List', plan)
      this.$emit('replace-plan', plan)
    },
    isActionProcessing(item) {
      return item.is_processing
    },
    isPublishedPost(item) {
      return getStatusClass(item) === 'published'
    },
    isFailedPost(item) {
      return getStatusClass(item) === 'failed'
    },
    getBlogURL(type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    },
    handleExtra(item, type = 'details') {
      this.selectedPost = item
      if (type === 'details') {
        this.commentsAccordion = false
        this.internalNotesAccordion = false
        this.$bvModal.show('post-details-modal')
      } else if (
        type === 'commentsAccordion' ||
        type === 'internalNotesAccordion' ||
        type === 'externalAction'
      ) {
        this.commentsAccordion = false
        this.internalNotesAccordion = false
        this.externalAction = false
        this[type] = true
        this.$bvModal.show('post-details-modal')
      } else {
        this.$bvModal.show('post-status-modal')
      }
    },

    sharePlan(planId) {
      EventBus.$emit('share-plan::show', [planId])
    },

    getStatusColorCode(status) {
      return getStatusColorCode(status)
    },
    getStatusColorClass(status) {
      return getStatusColorClass(status)
    },
    getPlatforms(platforms) {
      const platformsList = []
      for (const platformsKey in platforms) {
        if (!isEmpty(platforms[platformsKey])) {
          platformsList.push(platformsKey)
        }
      }
      return platformsList
    },

    getIconClass(platform) {
      return getIconClass(platform)
    },
    postAccounts(item) {
      if (item.account_selection) {
        const social = this.getPlatforms(item.account_selection)

        const accounts = []
        social.forEach((platform) => {
          item.account_selection[platform].forEach((account) => {
            accounts.push({
              ...account,
              platform: platform,
            })
          })
        })
        return accounts.flat()
      }

      return []
    },
    postImage(item) {
      let image = []
      if (item.common_box_status) {
        image = item.common_sharing_details.image
      } else {
        if (item.account_selection) {
          const platforms = this.getPlatforms(item.account_selection)
          platforms.forEach((platform) => {
            if (
              item[`${platform}_sharing_details`] &&
              !isEmpty(item[`${platform}_sharing_details`])
            ) {
              image = item[`${platform}_sharing_details`].image
            }
          })
        }
      }

      return image
    },

    openImageLightBox(image) {
      console.log('openImageLightBox', image)
      if (image) {
        this.imgs = image
        this.visible = true
      }
    },
    handleHide() {
      this.visible = false
    },

    getItemTag(item) {
      if (item.evergreen_automation_id) return 'Evergreen'
      if (item.rss_automation_id) return 'RSS'
      if (item.repeat_post) return 'Repeat'
      return ''
    },

    /**
     * fetches account images if it exists. returns a default profile image in case of no-image/error.
     * @param account object
     * @param type string
     */
    channelImage(account, type) {
      const image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      const gmbImage =
        'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      if (!account) return image

      if (account && account.platform_logo) return account.platform_logo

      switch (type) {
        case 'facebook':
          return account.image
            ? account.image
            : account.picture && account.picture.url
            ? account.picture.url
            : image
        case 'twitter':
        case 'linkedin':
        case 'pinterest':
          return account.image && account.image.large && account.image.large.url
            ? account.image.large.url
            : account.image
            ? account.image
            : image
        case 'instagram':
          return account.image
            ? account.image
            : account.profile_picture
            ? account.profile_picture
            : image
        case 'medium':
          return account.imageUrl ? account.imageUrl : image
        case 'gmb':
          return account.location_name ? gmbImage : image
      }
    },

    /**
     * Indicator: returns the length of the comment of the plan post if any.
     * @param item
     * @returns {number}
     */
    commentsLength(item) {
      const comments = item.comments
      let length = 0
      // if (item.type === 'Composer Article') return length
      if (comments) {
        comments.forEach((item) => {
          if (!item.is_note) length += 1
        })
      }
      if (item.external_comments?.length > 0)
        length += item.external_comments.length
      if (item.external_actions?.length > 0) {
        item.external_actions.forEach((item) => {
          if (item.comment) length += 1
        })
      }

      return length
    },

    /**
     * Indicator: returns the length of the internal note of the plan if any.
     * @param item
     * @returns {number}
     */
    internalNotesLength(item) {
      const internalNotes = item.comments
      let length = 0
      if (item.type === 'Composer Article') return length
      if (internalNotes) {
        internalNotes.forEach((item) => {
          if (item.is_note) length += 1
        })
      }
      return length
    },

    /**
     * Checks if the plan has is/includes a twitter posting.
     * @param item
     * @returns {false|*|boolean}
     */
    isTwitterPosting(item) {
      console.log('METHOD::isTwitterPosting ~ item -> ', item)
      return (
        item.type !== 'Composer Article' &&
        item.account_selection?.twitter?.length > 0
      )
    },

    /**
     * Checks if the plan has is/includes a facebok posting.
     * @param item
     * @returns {false|*|boolean}
     */
    isFacebookPosting(item) {
      return (
        item.type !== 'Composer Article' &&
        item.account_selection?.facebook?.length > 0
      )
    },

    /**
     * checks if the plan item has threaded response and is published
     * @param item
     * @returns {boolean|boolean|*}
     */
    hasThreadedTweetResponse(item) {
      if (
        item.status === 'published' &&
        this.isTwitterPosting(item) &&
        item.twitter_options
      ) {
        return item.twitter_options.has_threaded_tweets
      }
      return false
    },

    /**
     * checks if the plan item has first comment response and is published
     * @param item
     * @returns {boolean|boolean|*}
     */
    hasFirstCommentResponse(item) {
      if (item.status === 'published' && this.isFacebookPosting(item)) {
        return item.has_first_comment
      }
      return false
    },

    /**
     * onClick event which enables side bar and shows the threaded tweets response
     * @param item
     */
    showTweetsResponse(item) {
      const account = this.postAccounts(item).find(
        (item) => item.platform === 'twitter'
      )
      EventBus.$emit(
        'tweets_detail_modal',
        item,
        platformPosting(
          item,
          this.getPlatformType,
          getPlatformIdentifierValue(account, 'twitter')
        ).threaded_tweets,
        this.channelImage(account, account.platform),
        'platform_name' in account ? account.platform_name : account.name
      )
    },

    /**
     * onClick event which enables side bar and shows the first comments response
     * @param item
     */
    showCommentResponse(item) {
      console.log('options', item)
      const account = this.postAccounts(item).find(
        (item) => item.platform === 'facebook'
      )
      let detail
      let youtubeOptions
      const response = platformPostingStatus(
        item,
        this.getPlatformType,
        getPlatformIdentifierValue(account, this.type)
      )
      const commentResponse = platformCommentPostingStatus(
        item,
        this.getPlatformType,
        getPlatformIdentifierValue(this.account, this.type)
      )
      if (item.common_box_status) {
        detail = item.common_sharing_details
        youtubeOptions = item.youtube_options
      } else {
        switch (this.type) {
          case 'linkedin':
            detail = this.item.linkedin_sharing_details
            break
          case 'instagram':
            detail = this.item.instagram_sharing_details
            break
          case 'facebook':
            detail = this.item.facebook_sharing_details
            break
          case 'youtube':
            detail = this.item.youtube_sharing_details
            youtubeOptions = this.item.youtube_options
            break
        }
      }

      console.debug(
        'props',
        this.type,
        detail,
        youtubeOptions,
        this.account,
        response,
        this.item.first_comment_message,
        commentResponse
      )
      EventBus.$emit(
        'first_comment_response',
        this.type,
        detail,
        youtubeOptions,
        this.account,
        response,
        commentResponse,
        this.item.first_comment_message
      )
    },

    /**
     * Whether to show the review buttongs based on the  conditions.
     * @param item
     * @returns {boolean}
     */
    showReviewButtons(item) {
      if (item.status === 'review' || item.status === 'draft') {
        if (
          item.approval &&
          item.approval.status === 'pending_approval' &&
          this.checkApprovalStatus(item.approval)
        ) {
          return true
        }
      }
      return false
    },

    /**
     * Shwo the edit button if the user has the permissions
     * @param item
     * @returns {boolean}
     */
    showEditButton(item) {
      return this.canEditPost(item)
    },

    /**
     * show delete button if the user has the permissions
     * @param item
     * @returns {*}
     */
    showDelButton(item) {
      return item.can_perform.delete
    },

    /**
     *
     * @param item
     * @returns {*}
     */
    showDuplicateButton(item) {
      return item.can_perform.duplicate
    },

    /**
     * show replace buttons if the user has the permissions.
     * @param item
     * @returns {*}
     */
    showReplaceButton(item) {
      return item.can_perform.replace
    },

    /**
     * Returns true if edit and delete buttons are active.
     * @param item
     * @returns {false|*}
     */
    showEditDelButtons(item) {
      return this.showEditButton(item) && this.showDelButton(item)
    },

    /**
     * Returns Html element containing the details of the team members.
     * @param member
     * @returns {string|null}
     */
    teamMemberDropdownItem(member) {
      if (member && member.role) {
        const memberRoleClass = this.getRoleClass(member.role)
        if (memberRoleClass) {
          return `<div class='team_detail_tooltip'>
                                    <div class='flex items-center space-x-2'>
                                        <div class='img' style='background: url(${this.teamMemberImageById(
                                          member.user_id
                                        )});'></div>
                                        <div class="flex flex-col">
                                          <div class="flex space-x-2 items-center">
                                            <div class="text-sm">${
                                              member.user.firstname
                                            } ${member.user.lastname}</div>
                                            <div class="${memberRoleClass} role text-sm">
                                              ${this.getMutatedUserRole(
                                                member.role
                                              )}
                                            </div>
                                          </div>
                                          <div class="text-sm text-gray-600 text-xs">${
                                            member.user.email
                                          }</div>
                                        </div>
                                    </div>
                                  </div>`
        }
      }
      return null
    },

    /**
     * Returns a boolean depending if the item passed as an argument is a social post.
     * @param item
     * @returns {boolean}
     */
    isSocialPost(item) {
      return (
        item.type === 'Composer Social' ||
        item.type === 'Social Content' ||
        item.type === 'Evergreen Automation' ||
        ((item.type === 'Automation Article' ||
          item.type === 'Automation Video') &&
          'account_selection' in item)
      )
    },

    /**
     * Returns a boolean depending if the item passed as an argument is a blog post.
     * @param item
     * @returns {boolean}
     */
    isBlogPost(item) {
      return (
        item.type === 'Composer Article' ||
        ((item.type === 'Automation Article' ||
          item.type === 'Automation Video') &&
          'blog_selection' in item)
      )
    },

    isInProgress(item) {
      return item.status === 'queued' || item.status === 'processing'
    },

    parseDescriptionV2(text, limit = 320) {
      return parseDescriptionHtmlMemoized(text, limit)
    },

    /**
     * Returns true/false based on the approval status if exists in this.item.
     * @returns {[]|*|boolean}
     */
    showApprovalStatus(item) {
      return (
        item.approval?.approvers?.length > 0 ||
        item.external_actions?.length > 0
      )
    },

    onClickTableRow(planId) {
      if (this.dragged) {
        this.dragged = false
        return
      }

      this.$emit('preview-plan', planId)
    },

    /**
     * Method to capture if the table row is moved in order to use this.dragged to prevent previewing the modal
     */
    onDragStart() {
      console.log('METHOD::onDragStart ~ ')
      clearTimeout(this.dragTimeout)

      this.dragged = false
      this.dragTimeout = setTimeout(() => {
        this.dragged = true
      }, 100) // Minimal delay to be regarded as drag instead of click
    },

    /**
     * on click handler for bulk edit.
     */
    onClickBulkEdit() {
      if (!this.disableBulkEdit) this.$emit('bulk-action', 'edit')
    },
  },
}
</script>

<style lang="scss">
.planner-list-compact-view {
  .planner-list-image {
    width: 6rem;
    height: 4rem;
  }
}

.planner-more-actions-dropdown {
  width: 14rem;
}
</style>

<style scoped lang="scss">
table {
  thead {
    position: relative;
    z-index: 10;

    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #f3f4f4;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-bottom: 1px solid #ebebeb;
        background: #f3f4f4;
      }

      &:nth-child(1) {
        left: 0;
        z-index: 2;
      }
    }
  }

  tbody {
    th {
      position: sticky;
      left: 0;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        z-index: -1;
      }

      &:last-child {
        right: 0;
        z-index: 1;

        &:hover {
          z-index: var(--zIndex) !important;
        }
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }

    tr {
      border-bottom: 1px solid #eff3f8;
    }

    .table-infinite-loading {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  th:not(.table-auto-width) {
    min-width: 170px;
  }

  .list-item-row {
    &__action-items {
      opacity: 0;
      transition: 0.2s ease all;

      &:before {
        background: transparent !important;
      }

      i {
        background: #ffffff;
        height: 30px;
        width: 30px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        @apply shadow;
      }
    }

    &:hover {
      background: #f1faff !important;
      //box-shadow: 0 -0.5px 0 1px rgb(0 123 255 / 25%);

      .list-item-row__action-items {
        opacity: 1;
      }

      th:not(.list-item-row__action-items) {
        &::before {
          background: #f1faff !important;
        }
      }
    }
  }
}

.planner-table {
  & > div {
    border: 1px solid #eff3f8;
    @apply rounded-md;
  }
}

.icon-btn-no-pad {
  .btn i {
    @apply text-gray-800;
  }
}

input.check-input + div {
  background-color: transparent;
  border: 1.5px solid #99a3a982;
  border-radius: 0.3rem;
  width: 16px;
  height: 16px;
}

input.check-input:checked + div {
  @apply border-blue-300;
}

input.check-input:checked + div svg {
  @apply block;
}
</style>
