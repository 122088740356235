import {
  followBatchTopicsURL,
  followTopicURL,
  getCuratedSuggestionURL,
  getCuratedTopicsURL,
  unfollowTopicURL
} from '../config/api-utils'
import proxy from '@common/lib/http-common'
import axios from 'axios'
import {
  articlesTypes as articleTypes,
  curatedTypes as types
} from './mutation-types'

const getDefaultFollowCuratedState = () => {
  return {
    curated: {
      list_all: [],
      search: '',
      suggested: [],
      selected: [],
      requests: [],
      loaders: {
        save: false,
        suggestion: false,
        curated_view: true,
        auto_complete: false
      }
    }
  }
}

const state = getDefaultFollowCuratedState()
const getters = {
  getFollowCurated: (state) => {
    return state.curated
  },
  getFollowCuratedLoader: (state) => {
    return state.curated.loaders
  },
  getCuratedTopicsList: (state) => {
    return state.curated.list_all
  }
}
const actions = {
  // v1 refacotring for the codebase
  allCuratedTopics ({ commit, getters, dispatch }) {
    console.log('allCuratedTopics', getters.getDiscoverFetchStatus)
    if (getters.getDiscoverFetchStatus === true) {
      commit(types.SET_CURATED_VIEW_LOADER, false)
      return
    }
    commit(types.SET_CURATED_VIEW_LOADER, true)
    proxy
      .post(getCuratedTopicsURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.status) {
          commit(types.SET_CURATED_VIEW_LOADER, false)
          commit(types.SET_CURATED_TOPICS_ALL, resp.data.list)
        }
      })
      .catch((err) => {
        commit(types.SET_CURATED_VIEW_LOADER, false)
      })
  },

  searchCuratedTopic ({ commit, getters, dispatch }) {
    proxy
      .post(getCuratedSuggestionURL, {
        filters: {
          search: getters.getFollowCurated.search,
          match_phrase: true
        },
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        dispatch('removeDuplicates', resp.data.curated_suggestion)
      })
  },

  async autocompleteCuratedTopic ({ commit, getters, dispatch }) {
    // cancel previously sent requests

    const CancelToken = axios.CancelToken
    const searchRequests = getters.getFollowCurated.requests
    for (let i = 0; i < searchRequests.length; i++) {
      const remove = searchRequests[i]
      remove()
    }
    commit(types.SET_SUGGESTED_LIST, [])

    let cancel

    // for search content suggestions
    commit(types.SET_AUTOCOMPLETE_CURATED_TOPICS_LOADER, true)
    const res = await axios
      .post(
        getCuratedSuggestionURL,
        {
          filters: {
            search: getters.getFollowCurated.search,
            match_phrase: true
          },
          workspace_id: getters.getWorkspaces.activeWorkspace._id
        },
        { headers: { Authorization: 'Bearer ' + getters.getJWTToken } },
        {
          cancelToken: new CancelToken(function executor (c) {
            // An executor function receives a cancel function as a parameter
            cancel = c
          })
        }
      )
      .then((resp) => {
        dispatch('removeDuplicates', resp.data.curated_suggestion)
        return resp
      })
      .catch(() => {
        return null
      })
    commit(types.SET_AUTOCOMPLETE_CURATED_TOPICS_LOADER, false)
  },
  saveCuratedTopics ({ commit, getters, dispatch }, vue) {
    commit(types.SET_CURATED_SAVE_LOADER, true)

    return proxy
      .post(followBatchTopicsURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        curated_topics: getters.getFollowCurated.selected
      })
      .then((resp) => {
        commit(types.SET_CURATED_SAVE_LOADER, false)
        vue.$bvModal.hide('curated_topic_modal')

        if (resp.data.status) {
          dispatch('toastNotification', {
            message: 'Your curated topics have been saved!',
            type: 'success'
          })
          // commit(types.SET_SUGGESTED_LIST, [])
          commit(types.SET_SELECTED_LIST, [])
          dispatch('addToCuratedTopicsList', resp.data.curated_topics)

          resp.data.curated_topics.forEach((topic) => {
            getters.getCuratedTopicsList.forEach((item) => {
              item.list.forEach((curated) => {
                if (curated.id === topic.id) curated.followed = true
              })
            })
          })
        }
      })
      .catch()
    vue.$bvModal.hide('curated_topic_modal')
    // $('#curated_topic_modal').modal('hide')
  },
  removeCuratedTopic ({ commit, getters }) {},
  removeDuplicates ({ commit, getters }, suggestedTopics) {
    const cleanedList = []
    if (suggestedTopics) {
      suggestedTopics.forEach((nestedElement) => {
        let exists = false
        getters.getFollowCurated.selected.forEach((element) => {
          if (nestedElement.name === element.name) {
            exists = true
          }
        })
        getters.getMyCuratedTopicsList.forEach((element) => {
          if (nestedElement.name === element.name) {
            exists = true
          }
        })
        if (!exists) {
          cleanedList.push(nestedElement)
        }
      })
    }

    console.log(cleanedList)
    commit(types.SET_SUGGESTED_LIST, cleanedList)
  },
  followCuratedTopic ({ commit, getters, state, dispatch }, payload) {
    console.debug('Action:followCuratedTopic', payload)
    const topic = payload.topic
    topic.followed = true
    topic.workspace_id = getters.getWorkspaces.activeWorkspace._id

    return proxy
      .post(followTopicURL, topic)
      .then((resp) => {
        if (resp.data.status) {
          if (resp.data.curated_topic) {
            topic.id = resp.data.curated_topic.id
            dispatch('addToCuratedTopicsList', [resp.data.curated_topic])
            // dispatch('trackEvent', { event: 'curated_topics_followed' })
            // if there is no payload id

            if (!payload.hasOwnProperty('id')) {
              commit(
                articleTypes.SET_SEARCH_ARTICLES_TOPIC,
                resp.data.curated_topic
              )
            }

            // mark them to the list
            if (payload.id) {
              // verify this section may be not using
              getters.getCuratedTopicsList.forEach((item) => {
                item.list.forEach((curated) => {
                  if (curated.id === payload.id) curated.followed = true
                })
              })
            }

            if (topic.name) {
              getters.getCuratedTopicsList.forEach((item) => {
                item.list.forEach((curated) => {
                  if (curated.name === topic.name) curated.followed = true
                })
              })
            }
          }
          // dispatch('toastNotification', { message: FOLLOWED, type: 'success' })
          return true
        } else {
          return false
        }
      })
      .catch(() => {
        return null
      })
  },
  unfollowCuratedTopic ({ commit, getters, state, dispatch }, payload) {
    console.debug('Action:unfollowCuratedTopic', payload)
    const topic = payload.topic
    const index = payload.index
    topic.followed = false

    commit(
      articleTypes.SET_SEARCH_ARTICLES_TOPIC,
      JSON.parse(JSON.stringify(topic))
    )
    topic.workspace_id = getters.getWorkspaces.activeWorkspace._id

    // remove the item from the list on topics curated topics
    const curatedTopicsList = getters.getMyCuratedTopicsList
    if (index != null) {
      curatedTopicsList.splice(index, 1)
      commit('SET_CURATED_TOPICS_LIST', curatedTopicsList)
    } else {
      let topicIndex = null
      curatedTopicsList.forEach(function (item, index) {
        if (item.name === topic.name) topicIndex = index
      })
      if (topicIndex !== null) curatedTopicsList.splice(topicIndex, 1)
      commit('SET_CURATED_TOPICS_LIST', curatedTopicsList)
    }

    return proxy
      .post(unfollowTopicURL, topic)
      .then((resp) => {
        if (resp.data.status) {
          getters.getCuratedTopicsList.forEach((item) => {
            item.list.forEach((curated) => {
              if (curated.name === topic.name) curated.followed = false
            })
          })

          if (getters.getCoveredStoriesPosts.length > 0) {
            // get the covered stories and remove it.
            getters.getCoveredStoriesPosts.filter((item, indexItem) => {
              if (item.topic._id === topic._id) {
                getters.getCoveredStoriesPosts.splice(indexItem, 1)
              }
            })
            if (getters.myCuratedTopics && getters.myCuratedTopics.length > 0) {
              getters.myCuratedTopics.filter((item, itemIndex) => {
                if (item.topic._id === topic._id) {
                  getters.myCuratedTopics.splice(itemIndex, 1)
                }
              })
            }
          }
          return true
        } else {
          return false
        }
      })
      .catch(() => {
        return null
      })
  }
}
const mutations = {
  [types.SET_CURATED_TOPICS_ALL] (state, list) {
    state.curated.list_all = list
  },

  [types.SET_SELECTED_LIST] (state, list) {
    state.curated.selected = list
  },
  [types.ADD_TO_SELECTED_LIST] (state, payload) {
    state.curated.selected.push(payload.topic)
    state.curated.suggested.splice(payload.index, 1)
  },
  [types.SET_SUGGESTED_LIST] (state, list) {
    state.curated.suggested = list
  },
  [types.ADD_TO_SUGGESTED_LIST] (state, payload) {
    state.curated.suggested.push(payload.topic)
    state.curated.selected.splice(payload.index, 1)
  },
  [types.SET_CURATED_SAVE_LOADER] (state, value) {
    state.curated.loaders.save = value
  },
  [types.SET_CURATED_VIEW_LOADER] (state, value) {
    state.curated.loaders.curated_view = value
  },
  [types.SET_AUTOCOMPLETE_CURATED_TOPICS_LOADER] (state, value) {
    state.curated.loaders.auto_complete = value
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
