var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max_container_1800 automation_main_component"},[_c('div',{staticClass:"component_inner"},[_vm._m(0),_vm._m(1),(_vm.isWorkspaceOnHold)?[_c('div',{staticClass:"input_field"},[_c('div',{staticClass:"warning_box warning-alert"},[(_vm.hasPermission('can_change_hold_status'))?_c('p',[_c('i',{staticClass:"fa fa-warning"}),_vm._v(" Note: You cannot select accounts because you've paused publishing for this workspace, "),_c('router-link',{staticClass:"ml-2",attrs:{"to":{ name: 'workspaces' }}},[_vm._v("Click here to resume publishing ")])],1):_vm._e(),(!_vm.hasPermission('can_change_hold_status'))?_c('p',[_c('i',{staticClass:"fa fa-warning"}),_vm._v(" Note: You cannot select accounts because the admin of this workspace has paused publishing for this workspace.")]):_vm._e()])])]:_vm._e(),_c('div',{staticClass:"automation_boxes"},[_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(2),_vm._m(3),_c('h3',[_vm._v("Article Links to Social Media")]),_c('p',[_vm._v("Share article links on your social media profiles, pages and groups with smart rules and filters.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
              'justify-content-center':
                !_vm.getAutomationCounts.articleSocialAutomation,
            }},[_c('a',{staticClass:"btn btn-studio-theme-transparent btn-size-small",attrs:{"data-cy":"articles-links"},on:{"click":function($event){return _vm.createArticleAutomationProcess('saveArticleSocialAutomation')}}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.articleSocialAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent ml-auto btn-size-small",attrs:{"to":{
                name: 'articleSocialAutomationListing',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.articleSocialAutomation)+")")])]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(4),_vm._m(5),_c('h3',[_vm._v("RSS Feed to Social Media")]),_c('p',[_vm._v("Share RSS feeds content on your social media.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
              'justify-content-center': !_vm.getAutomationCounts.rssAutomation,
            }},[_c('a',{staticClass:"btn btn-studio-theme-transparent btn-size-small",attrs:{"data-cy":"rss-links"},on:{"click":function($event){$event.preventDefault();return _vm.createRssAutomationProcess()}}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.rssAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent btn-size-small ml-auto",attrs:{"to":{
                name: 'rssAutomationListing',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.rssAutomation)+")")])]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(6),_vm._m(7),_c('h3',[_vm._v("Evergreen")]),_c('p',[_vm._v("Recycle your evergreen posts at pre-defined time intervals over and over again for increased engagement.")]),[(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
                'justify-content-center':
                  !_vm.getAutomationCounts.evergreenAutomation,
              }},[_c('a',{staticClass:"btn btn btn-studio-theme-transparent btn-size-small",on:{"click":function($event){$event.preventDefault();return _vm.newEvergreenAutomation()}}},[_c('i',{staticClass:"icon_left add_icon cs-plus",attrs:{"data-cy":"evergreen-links"}}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.evergreenAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent btn-size-small ml-auto",attrs:{"to":{
                  name: 'evergreenAutomationListing',
                  params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
                }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.evergreenAutomation)+")")])]):_vm._e()],1):_vm._e()]],2)]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(8),_c('h3',[_vm._v("Bulk Uploader")]),_c('p',[_vm._v("Schedule multiple messages to your social media channels via CSV data import.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"btn btn btn-studio-theme-transparent btn-size-small",attrs:{"to":{
                name: 'saveBulkCsvAutomation',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Upload")])])],1):_vm._e()])]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(9),_c('h3',[_vm._v("Articles to Blog")]),_c('p',[_vm._v("Share articles on your blog with smart rules and filters.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
              'justify-content-center':
                !_vm.getAutomationCounts.articleBlogAutomation,
            }},[_c('a',{staticClass:"btn btn-studio-theme-transparent btn-size-small",on:{"click":function($event){return _vm.createArticleAutomationProcess('saveArticleBlogAutomation')}}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.articleBlogAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent btn-size-small ml-auto",attrs:{"to":{
                name: 'articleBlogAutomationListing',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.articleBlogAutomation)+")")])]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(10),_c('h3',[_vm._v("Videos to Social Media")]),_c('p',[_vm._v("Share videos on your social media profiles, pages and groups with smart rules and filters.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
              'justify-content-center':
                !_vm.getAutomationCounts.videoSocialAutomation,
            }},[_c('a',{staticClass:"btn btn-studio-theme-transparent btn-size-small",on:{"click":function($event){return _vm.createVideoAutomationProcess('saveVideoSocialAutomation')}}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.videoSocialAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent btn-size-small ml-auto",attrs:{"to":{
                name: 'videoSocialAutomationListing',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.videoSocialAutomation)+")")])]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"a_box"},[_c('div',{staticClass:"box_inner"},[_vm._m(11),_c('h3',[_vm._v("Videos to Blog")]),_c('p',[_vm._v("Share videos on your blog with smart rules and filters.")]),(!_vm.isWorkspaceOnHold)?_c('div',{staticClass:"d-flex align-items-center",class:{
              'justify-content-center':
                !_vm.getAutomationCounts.videoBlogAutomation,
            }},[_c('a',{staticClass:"btn btn-studio-theme-transparent btn-size-small",on:{"click":function($event){return _vm.createVideoAutomationProcess('saveVideoBlogAutomation')}}},[_c('i',{staticClass:"icon_left add_icon cs-plus"}),_c('span',[_vm._v("New Campaign")])]),(_vm.getAutomationCounts.videoBlogAutomation)?_c('router-link',{staticClass:"btn btn-studio-theme-transparent btn-size-small ml-auto",attrs:{"to":{
                name: 'videoBlogAutomationListing',
                params: { workspace: _vm.getWorkspaces.activeWorkspace.slug },
              }}},[_c('span',[_vm._v("View existing ("+_vm._s(_vm.getAutomationCounts.videoBlogAutomation)+")")])]):_vm._e()],1):_vm._e()])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_content_main"},[_c('h2',[_vm._v("Automation Recipes")]),_c('p',[_vm._v("Using the automation recipes given below, you can create highly customized campaigns by setting up a variety of filters and rules according to your needs.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"automation_pinterest_warning"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_c('p',[_vm._v(" Pinterest removal from automation campaigns (except 'Bulk Uploader') to adhere to Pinterest's policies, starting 12th May, 2022. "),_c('a',{staticClass:"beacon ml-2",attrs:{"href":"#","data-beacon-article-modal":"627b500db2de5178f8882990"}},[_vm._v(" Read More. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-most-used float-right"},[_c('span',[_c('i',{staticClass:"far fa-star mr-2"}),_vm._v("Featured")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/article_social.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-most-used float-right"},[_c('span',[_c('i',{staticClass:"far fa-star mr-2"}),_vm._v("Most used")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/rss_feed.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-most-used float-right"},[_c('span',[_c('i',{staticClass:"far fa-star mr-2"}),_vm._v("Most used")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/article_social.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{staticStyle:{"width":"45px"},attrs:{"src":require("../../../assets/img/automation/bulk_upload.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/article_blog.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/video_social.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a_img"},[_c('img',{attrs:{"src":require("../../../assets/img/automation/video_blog.svg"),"alt":""}})])
}]

export { render, staticRenderFns }