<script>
const AddSource = () => import('./AddSource')
export default {
  components: {
    AddSource
  },
  created () {
    if (!this.hasPermission('can_save_source')) {
      this.$router.push({ name: 'profile' })
    }
  },
  mounted () {}
}
</script>
<template>
  <div class="setting_sources_component">
    <add-source></add-source>
    <div class="component_inner">
      <!-- <div class="sources_head">
              <div class="sources_row">
                  <div class="icon_tabs">
                      <ul class="tab_ul">
                          <li class="tab_li"  v-tooltip.top-center="'Web'">
                              <router-link :to="{'name': 'web_sources'}"  class="tab_a d-flex align-items-center justify-content-center ">
                                  <i class="cs-world-o"></i>
                              </router-link>
                          </li>
                          <li class="tab_li"   v-tooltip.top-center="'Twitter'">
                              <router-link :to="{'name': 'twitter'}"
                                           class="tab_a d-flex align-items-center justify-content-center ">
                                  <i class="cs-twitter"></i>
                              </router-link>

                          </li>

                          <li class="tab_li"   v-tooltip.top-center="'Facebook'">
                              <router-link :to="{'name': 'facebook'}"
                                           class="tab_a d-flex align-items-center justify-content-center ">
                                  <i class="cs-facebook"></i>
                              </router-link>

                          </li>

                      </ul>
                  </div>
              </div>
          </div>-->
      <router-view></router-view>
    </div>
  </div>
</template>
