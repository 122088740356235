import { automationBaseUrl } from '../../../config/api-utils'

export const fetchAutomationsCountsUrl =
  automationBaseUrl + 'fetchAutomationsCounts'
export const fetchNextPostingUrl = automationBaseUrl + 'fetchNextPosting'
export const fetchAllAutomationsURL = automationBaseUrl + 'fetchAllAutomations'

// video

export const removeVideoAutomationUrl =
  automationBaseUrl + 'removeVideoAutomation'
export const restartVideoAutomationUrl =
  automationBaseUrl + 'restartVideoAutomation'
export const fetchVideoAutomationUrl =
  automationBaseUrl + 'fetchVideoAutomation'
export const changeVideoAutomationStatusUrl =
  automationBaseUrl + 'changeVideoAutomationStatus'
export const videoAutomationBulkOperationUrl =
  automationBaseUrl + 'videoAutomationBulkOperation'

// blog
export const saveVideoBlogAutomationUrl =
  automationBaseUrl + 'saveVideoBlogAutomation'
export const fetchVideoBlogAutomationsUrl =
  automationBaseUrl + 'fetchVideoBlogAutomations'

// social
export const saveVideoSocialAutomationUrl =
  automationBaseUrl + 'saveVideoSocialAutomation'
export const fetchVideoSocialAutomationsUrl =
  automationBaseUrl + 'fetchVideoSocialAutomations'

// article

export const removeArticleAutomationUrl =
  automationBaseUrl + 'removeArticleAutomation'
export const fetchArticleAutomationUrl =
  automationBaseUrl + 'fetchArticleAutomation'
export const changeArticleAutomationStatusUrl =
  automationBaseUrl + 'changeArticleAutomationStatus'
export const articleAutomationBulkOperationUrl =
  automationBaseUrl + 'articleAutomationBulkOperation'

// blog
export const saveArticleBlogAutomationUrl =
  automationBaseUrl + 'saveArticleBlogAutomation'
export const fetchArticleBlogAutomationsUrl =
  automationBaseUrl + 'fetchArticleBlogAutomations'

// social
export const saveArticleSocialAutomationUrl =
  automationBaseUrl + 'saveArticleSocialAutomation'
export const fetchArticleSocialAutomationsUrl =
  automationBaseUrl + 'fetchArticleSocialAutomations'

// evergreen
export const fetchEvergreenAutomationsUrl =
  automationBaseUrl + 'fetchEvergreenAutomations'
export const saveEvergreenAutomationUrl =
  automationBaseUrl + 'saveEvergreenAutomation'
export const removeEvergreenAutomationUrl =
  automationBaseUrl + 'removeEvergreenAutomation'
export const fetchEvergreenAutomationUrl =
  automationBaseUrl + 'fetchEvergreenAutomation'
export const changeEvergreenAutomationStatusUrl =
  automationBaseUrl + 'changeEvergreenAutomationStatus'
export const evergreenAutomationBulkOperationUrl =
  automationBaseUrl + 'evergreenAutomationBulkOperation'
export const fetchVariationsURL = automationBaseUrl + 'fetchVariations'
export const addPostEvergreenURL = automationBaseUrl + 'addEvergreenPost'

// bulk csv listing
export const fetchCSVAutomationsUrl = automationBaseUrl + 'fetchCSVAutomations'

export const processEvergreenCsvFileURL =
  automationBaseUrl + 'processEvergreenCsvFile'
export const createEvergreenAutomationURL =
  automationBaseUrl + 'saveEvergreenAutomation'

// rss
export const fetchRssAutomationsUrl = automationBaseUrl + 'fetchRssAutomations'
export const saveRssAutomationUrl = automationBaseUrl + 'saveRssAutomation'
export const removeRssAutomationUrl = automationBaseUrl + 'removeRssAutomation'
export const fetchRssAutomationUrl = automationBaseUrl + 'fetchRssAutomation'
export const changeRssAutomationStatusUrl =
  automationBaseUrl + 'changeRssAutomationStatus'
export const rssAutomationBulkOperationUrl =
  automationBaseUrl + 'rssAutomationBulkOperation'
export const pullHistoryUrl = automationBaseUrl + 'rss/pull_history'
