var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getTopicSelection.sources == 'domains_and_sources')?_c('div',{staticClass:"multi_select"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),_c('div',{staticClass:"ml-auto d-flex"},[(
          this.getTopicSelection.include_domains.length > 0 ||
          (this.includeDomainsValue !== '' &&
            this.includeDomainsValue.replace(/^\s+/, '') !== '')
        )?_c('button',{staticClass:"reset_btn mt-2",on:{"click":function($event){return _vm.clearAllKeywords()}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Clear")]):_vm._e(),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Find a domain name and preview content on the right side'),expression:"'Find a domain name and preview content on the right side'"}],staticClass:"mb-0 icon-help-cs float-right help_label"})])]),_c('div',{staticClass:"multi_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.includeDomainsValue),expression:"includeDomainsValue"}],staticClass:"prevent-listener",attrs:{"type":"text","placeholder":"Enter your main domains such as cnn.com, bbc.com"},domProps:{"value":(_vm.includeDomainsValue)},on:{"focus":function($event){return _vm.asyncIncludeDomainResults(_vm.includeDomainsValue)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.asyncIncludeDomainResults(_vm.includeDomainsValue)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.include_domains,
          _vm.includeDomainsValue,
          'include_domains',
          $event
        )},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==188)return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.include_domains,
          _vm.includeDomainsValue,
          'include_domains'
        )}],"input":[function($event){if($event.target.composing)return;_vm.includeDomainsValue=$event.target.value},function($event){return _vm.asyncIncludeDomainResults(_vm.includeDomainsValue)}]}}),_c('p',{staticClass:"placeholder"},[_vm._v("Press ‘ , ’ or ‘Enter’ to add")]),(_vm.isIncludeDomainsLoading && _vm.includeDomainsValue.length >= 3)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner"},[_c('ul',[_vm._l((_vm.include_domains_suggestion_list),function(suggested_domain,index){return [_c('li',{key:index,staticClass:"item d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.addIncludeDomain(suggested_domain, index)}}},[(!suggested_domain.is_parent)?_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-rss"})]):(suggested_domain.favicon_16)?_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":suggested_domain.favicon_16,"alt":""}})]):_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-world-o"})]),_c('span',{staticClass:"text"},[_c('span',{staticClass:"url",domProps:{"innerHTML":_vm._s(
                    _vm.highlightText(suggested_domain.url, _vm.includeDomainsValue)
                  )}})]),_c('span',{staticClass:"view"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View'),expression:"'View'"}],staticClass:"fa fa-eye"})])])]})],2)])]):(_vm.includeDomainsLoader)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner min_height d-flex align-items-center justify-content-center"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]):_vm._e()]),(_vm.getTopicSelection.include_domains)?[_c('div',{staticClass:"multi_tags"},[_c('ul',_vm._l((_vm.getTopicSelection.include_domains),function(include_keyword,index){return _c('li',{key:index,staticClass:"green"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(include_keyword))]),_c('span',{staticClass:"remove",on:{"click":function($event){$event.preventDefault();return _vm.removeKeyword(_vm.getTopicSelection.include_domains, index)}}},[_vm._v("×")])])}),0)])]:_vm._e()],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"sub_label"},[_c('span',{staticClass:"bold"},[_vm._v("INCLUDE")]),_vm._v(" results from these domains ")])
}]

export { render, staticRenderFns }