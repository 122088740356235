import { MediaHelperMixin } from './MediaHelpers'
import { swalAttributes } from '@common/constants/common-attributes'
import {EventBus} from "@common/lib/event-bus";

export const DragDropMixin = {
  mixins: [MediaHelperMixin],
  data () {
    return {
      selected: [],
      isMediaMoving: false
    }
  },

  methods: {
    unSelectAll () {
      this.selected = []
      this.isAllSelected.visible = false
      this.isAllSelected.total = false
      const elements = document.querySelectorAll('[data-item-selected="true"]')

      elements.forEach((el) => {
        el.setAttribute('data-item-selected', false)
      })
    },

    selectItem (element) {
      console.debug('selectItem', element)
      const id = element.getAttribute('id')
      const isExist = this.selected.filter((item) => item === id)

      if (!isExist.length) {
        this.selected.push(id)
        element.setAttribute('data-item-selected', true)
      } else {
        const index = this.selected.indexOf(id)
        this.selected.splice(index, 1)
        element.setAttribute('data-item-selected', false)
        this.isAllSelected.total = false
      }
    },
    onDragStart (event) {
      const folderList = document.querySelectorAll('.stack-folder__wrapper')
      const subFolderList = document.querySelectorAll(
        '.stack-folder__subfolder'
      )

      for (let i = 0; i < folderList.length; ++i) {
        folderList[i].setAttribute('data-disable-pointer', true)
      }
      for (let i = 0; i < subFolderList.length; ++i) {
        subFolderList[i].setAttribute('data-disable-pointer', true)
      }

      // Set ID
      event.dataTransfer.setData('text', event.currentTarget.id)

      // Set Drag Preview
      const img = new Image()
      img.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAB0klEQV\n' +
        'RYhe3XPU4CQRQH8E00cAaPoYFkCyoKClh1SSwsPIOVJWpnYkMsiScgUdmoBzA0xgaURRATwwFkZd\n' +
        '3CysCzccg4OzsfyyBDwj955SS/zLw3mTGMZZYxDLfbe288uSBbbucFPD+QrsHga00YFwc2LRDVTH\n' +
        'EqgJ4fjHUHsndxoYH9agKgntQP+HZrQr+amAChngTv3tYHiHA4MGonl0DVwOLZDqyXUmCWM2CWM3\n' +
        'oBNw7TsF5K/QGKIKWBOJKFw4EIRgPykLGAMvcgiaMBWciZAvFj5QGjkCGQKmAUjgWkIacCZis5yF\n' +
        'ZyYDk27N7sCeF4QBIZG4hwCGg5Nmw6RSZMFIgjJxi3062JAnEcDrQcGwpX20qACBnC8IAkjgRajg\n' +
        '35iy0lQLOckQPScDQgDzkTYBQuCmg5NuQv6UhpIA6hAVk4FtBybCjUwj2pFMjD8YC0wVEGFMGJAM\n' +
        'melAI2W+3xfwBxpBSQnNRmqz356uXO898qgWhwYh8vbRf3rw9OVALR4EhfLyzk3LPQyN++HDVa7e\n' +
        'Oo+vADFXUkBAWAFZl337S/NqGXMyvPvddHrYFktAeS0RoXwgZBWmtgCDwMHrjA4efp3IBkPD8Y4b\n' +
        'g7gFXemh+JU6gi6C6fAQAAAABJRU5ErkJggg=='
      event.dataTransfer.setDragImage(img, 0, 0)
    },

    onDragEnd () {
      const folderList = document.querySelectorAll('.stack-folder__wrapper')
      const subFolderList = document.querySelectorAll(
        '.stack-folder__subfolder'
      )

      for (let i = 0; i < folderList.length; ++i) {
        folderList[i].setAttribute('data-disable-pointer', false)
      }

      for (let i = 0; i < subFolderList.length; ++i) {
        subFolderList[i].setAttribute('data-disable-pointer', false)
      }
    },

    onDragEnter (event, element) {
      event.preventDefault()
      event.stopPropagation()

      const mainElement = element
      if (
        mainElement &&
        mainElement.hasAttribute('data-dragover') &&
        event.target === mainElement
      ) {
        mainElement.setAttribute('data-dragover', true)
      }
    },

    onDragLeave (event, element) {
      event.preventDefault()
      event.stopPropagation()

      const mainElement = element

      if (
        mainElement &&
        mainElement.hasAttribute('data-dragover') &&
        event.target === mainElement
      ) {
        mainElement.setAttribute('data-dragover', false)
      }
    },

    async onDrop (event, element, selectedItems, isAllSelected = {}) {
      console.log('onDrop', selectedItems, isAllSelected)
      event.preventDefault()
      event.stopPropagation()

      this.isMediaMoving = true

      let assetsIds = [event.dataTransfer.getData('text')]

      if (selectedItems.length && selectedItems.includes(assetsIds[0])) {
        assetsIds = selectedItems
      }

      const mainElement = element
      const isRoot = mainElement.getAttribute('data-root')
      if (mainElement && mainElement.hasAttribute('data-dragover')) {
        mainElement.setAttribute('data-dragover', false)
      }

      const folderId = mainElement.getAttribute('id')
      console.log('onDrop folderId', folderId)

      const payload = {
        assets: assetsIds,
        ids: assetsIds
      }

      if (isAllSelected.total) {
        payload.assets = []
        payload.ids = []
        switch (this.$route.query.type) {
          case 'all':
          case 'uncategorized':
          case 'archived': {
            payload.current_folder = this.$route.query.type
            break
          }
          case 'folder': {
            payload.folder_id = this.$route.query.folderId
            break
          }
        }
      }

      if (folderId === 'archived') {
        const confirm = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to move the asset(s) to archive folder',
          {
            title: 'Archived Assets',
            ...swalAttributes()
          }
        )

        if (confirm) {
          delete payload.assets
          if (isAllSelected.total) {
            payload.folder_id = this.$route.query.type
          }

          await this.removeMediaAssetsHelper(
            {
              ...payload
            },
            true,
            (status, removed) => {
              if (status && removed.length > 0) {
                EventBus.$emit('refetch-folders')
                // this.$root.$emit('refetch-media-limits')
                EventBus.$emit('refetch-media')
              }
            }
          )
        }
      } else {
        delete payload.ids
        payload.folderId = folderId
        await this.moveMediaAssetsHelper(
          {
            ...payload
          },
          (status, message) => {
            EventBus.$emit('refetch-folders')

            if (folderId === 'uncategorized') {
              EventBus.$emit('refetch-media')
            } else {
              EventBus.$emit('refetch-media', {folderId, isRoot})
            }
          }
        )
      }

      this.isMediaMoving = false
    }
  }
}

let assetId = 0

export const getAssetId = () => {
  assetId += 1
  return assetId
}
