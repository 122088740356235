<template>
  <div
    class="dropdown default_style_dropdown emoji_dropdown prevent_close_dropdown ml-2"
  >
    <div class="dropdown_header" data-toggle="dropdown" @click="loaded = true">
      <i
        v-tooltip.bottom-center="'Add an emoji'"
        :class="{ 'thread-emoji': type.includes('ThreadedTweets_') }"
        class="fal fa-smile-beam d-block"
      ></i>
    </div>

    <div
      :class="{
        'dropdown-menu-left': dropdownPosition === 'left_side',
        'dropdown-menu-bottom': dropdownPosition === 'bottom_side',
        'dropdown-menu-right': dropdownPosition === 'right_side',
        'dropdown-menu-right': !dropdownPosition,
        'dropdown-menu-top': dropdownPosition === 'top_side',
      }"
      class="dropdown-menu emojis-list-position"
      style="top: -425px !important; transform: unset !important"
    >
      <ul class="inner">
        <ul>
          <div class="emoji_list">
            <template v-if="loaded">
              <Picker
                :data="index"
                :emoji-size="18"
                :emoji-tooltip="true"
                :native="false"
                class="emoji-mart-wrap"
                emoji="point_up"
                title="Pick your emoji..."
                @select="addEmoji"
              ></Picker>
            </template>
          </div>
        </ul>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.thread-emoji {
  font-size: 1.2rem !important;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
const index = new EmojiIndex(data)

// TODO::Remove Extar code when emoji's tested on staging
// const EmojiSmileysPeople = () => import('./EmojiSmileysPeople.vue')
// const EmojiActivity = () => import('./EmojiActivity.vue')
// const EmojiTravelPlaces = () => import('./EmojiTravelPlaces.vue')
// const EmojiAnimalNature = () => import('./EmojiAnimalNature.vue')
// const EmojiFlags = () => import('./EmojiFalgs.vue')
// const EmojiFoodDrink = () => import('./EmojiFoodDrink.vue')
// const EmojiSymbols = () => import('./EmojiSymbols.vue')
// const EmojiObjects = () => import('./EmojiObjects.vue')

export default {
  components: {
    // EmojiSmileysPeople,
    // EmojiActivity,
    // EmojiTravelPlaces,
    // EmojiAnimalNature,
    // EmojiFlags,
    // EmojiFoodDrink,
    // EmojiSymbols,
    // EmojiObjects,
    Picker,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    dropdownPosition: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // emojiSelectionType: 'Smileys',
      loaded: false,
      index: index,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions([]),
    addEmoji(emoji) {
      this.addSharingEmojiFast(emoji, this.type)
    },
  },
}
</script>
