export default {
  state: {
    workspaces: [],
    facebook: {
      accounts: [],
      loader: false
    },
    twitter: {
      accounts: [],
      loader: false
    },
    pinterest: {
      accounts: [],
      loader: false
    },
    linkedin: {
      accounts: [],
      loader: false
    },
    tumblr: {
      accounts: [],
      loader: false
    },

    tumblrBlog: {
      accounts: [],
      loader: false
    },
    medium: {
      accounts: [],
      loader: false
    },
    wordpress: {
      accounts: [],
      loader: false
    },
    team: {
      accounts: [],
      loader: false
    },
    onBoardingLoader: false,
    initializeTeam: false,
    initializeAccounts: false
  },
  actions: {
    setOnboardingWorkspaces ({ commit }, data) {
      commit('setOnboardingWorkspaces', data)
    },
    setFacebookOnboardingAccounts ({ commit }, data) {
      commit('setFacebookOnboardingAccounts', data)
    },
    setTwitterOnboardingAccounts ({ commit }, data) {
      commit('setTwitterOnboardingAccounts', data)
    },
    setLinkedinOnboardingAccounts ({ commit }, data) {
      commit('setLinkedinOnboardingAccounts', data)
    },
    setPinterestOnboardingAccounts ({ commit }, data) {
      commit('setPinterestOnboardingAccounts', data)
    },
    setTumblrOnboardingAccounts ({ commit }, data) {
      commit('setTumblrOnboardingAccounts', data)
    },

    setTumblrBlogOnboardingAccounts ({ commit }, data) {
      commit('setTumblrBlogOnboardingAccounts', data)
    },
    setMediumOnboardingAccounts ({ commit }, data) {
      commit('setMediumOnboardingAccounts', data)
    },
    setWordpressOnboardingAccounts ({ commit }, data) {
      commit('setWordpressOnboardingAccounts', data)
    },
    setOnboardingTeam ({ commit }, data) {
      commit('setOnboardingTeam', data)
    },

    // loaders

    setFacebookOnboardingLoader ({ commit }, status) {
      commit('setFacebookOnboardingLoader', status)
    },
    setTwitterOnboardingLoader ({ commit }, status) {
      commit('setTwitterOnboardingLoader', status)
    },
    setLinkedinOnboardingLoader ({ commit }, status) {
      commit('setLinkedinOnboardingLoader', status)
    },
    setPinterestOnboardingLoader ({ commit }, status) {
      commit('setPinterestOnboardingLoader', status)
    },
    setTumblrOnboardingLoader ({ commit }, status) {
      commit('setTumblrOnboardingLoader', status)
    },

    setTumblrBlogOnboardingLoader ({ commit }, status) {
      commit('setTumblrBlogOnboardingLoader', status)
    },
    setMediumOnboardingLoader ({ commit }, status) {
      commit('setMediumOnboardingLoader', status)
    },
    setWordpressOnboardingLoader ({ commit }, status) {
      commit('setWordpressOnboardingLoader', status)
    },

    setOnboardingTeamLoader ({ commit }, status) {
      commit('setOnboardingTeamLoader', status)
    },
    setOnboardingLoader ({ commit }, status) {
      commit('setOnboardingLoader', status)
    },
    setOnboardingTeamIntilize ({ commit }, status) {
      commit('setOnboardingTeamIntilize', status)
    },
    setOnboardingAccountsIntilize ({ commit }, status) {
      commit('setOnboardingAccountsIntilize', status)
    }
  },
  mutations: {
    setOnboardingWorkspaces (state, data) {
      state.workspaces = data
    },
    setFacebookOnboardingAccounts (state, data) {
      state.facebook.accounts = data
    },
    setTwitterOnboardingAccounts (state, data) {
      state.twitter.accounts = data
    },
    setPinterestOnboardingAccounts (state, data) {
      state.pinterest.accounts = data
    },
    setLinkedinOnboardingAccounts (state, data) {
      state.linkedin.accounts = data
    },
    setTumblrOnboardingAccounts (state, data) {
      state.tumblr.accounts = data
    },

    setWordpressOnboardingAccounts (state, data) {
      state.wordpress.accounts = data
    },
    setMediumOnboardingAccounts (state, data) {
      state.medium.accounts = data
    },
    setTumblrBlogOnboardingAccounts (state, data) {
      state.tumblrBlog.accounts = data
    },

    setOnboardingTeam (state, data) {
      state.team.accounts = data
    },

    // loaders

    setFacebookOnboardingLoader (state, status) {
      state.facebook.loader = status
    },
    setTwitterOnboardingLoader (state, status) {
      state.twitter.loader = status
    },
    setPinterestOnboardingLoader (state, status) {
      state.pinterest.loader = status
    },
    setLinkedinOnboardingLoader (state, status) {
      state.linkedin.loader = status
    },
    setTumblrOnboardingLoader (state, status) {
      state.tumblr.loader = status
    },
    setWordpressOnboardingLoader (state, status) {
      state.wordpress.loader = status
    },
    setMediumOnboardingLoader (state, status) {
      state.medium.loader = status
    },
    setTumblrBlogOnboardingLoader (state, status) {
      state.tumblrBlog.loader = status
    },
    setOnboardingTeamLoader (state, status) {
      state.team.loader = status
    },
    setOnboardingLoader (state, status) {
      state.onBoardingLoader = status
    },
    setOnboardingAccountsIntilize (state, status) {
      state.initializeAccounts = status
    },
    setOnboardingTeamIntilize (state, status) {
      state.initializeTeam = status
    }
  },
  getters: {
    getOnboardingFacebook: (state) => {
      return state.facebook
    },
    getOnboardingTwitter: (state) => {
      return state.twitter
    },
    getOnboardingPinterest: (state) => {
      return state.pinterest
    },
    getOnboardingLinkedin: (state) => {
      return state.linkedin
    },
    getOnboardingTumblr: (state) => {
      return state.tumblr
    },
    getOnboardingTumblrBlog: (state) => {
      return state.tumblrBlog
    },
    getOnboardingMedium: (state) => {
      return state.medium
    },
    getOnboardingWordpress: (state) => {
      return state.wordpress
    },
    getOnboardingTeam: (state) => {
      return state.team
    },
    getOnboardingLoader: (state) => {
      return state.onBoardingLoader
    },
    getOnboardingWorkspaces: (state) => {
      return state.workspaces
    },
    getOnboardingAccountsIntilize: (state) => {
      return state.initializeAccounts
    },
    getOnboardingTeamIntilize: (state) => {
      return state.initializeTeam
    },
    getGetstartedStatus: (state) => {
      return state.getStartedVideo
    },
  }
}
