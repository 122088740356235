<template>
  <div>
        <div class="filter grid grid-cols-2 gap-1 w-full">
               <div class="inline-flex justify-center items-center overflow-hidden text-ellipsis whitespace-pre w-full rounded-lg color-border shadow-xs bg-cs-white font-normal text-gray-800 hover:bg-gray-50 focus:outline-none">
                 <input id="hd" v-model="isHD" type="checkbox" />
                 <label for="hd" class="ml-2 mt-2">HD Videos</label>
               </div>

                <CstDropdown class="w-100" size="small" button-classes="flex justify-between bg-cs-white">
                  <template slot="selected">{{sortType}}</template>
                  <template v-slot>
                    <CstDropdownItem @click="handleSortType('Relevance')">
                      Relevance
                    </CstDropdownItem>
                    <CstDropdownItem @click="handleSortType('Most Viewed')">
                      Most Viewed
                    </CstDropdownItem>
                    <CstDropdownItem @click="handleSortType('Recent')">
                      Recent
                    </CstDropdownItem>
                  </template>
                </CstDropdown>
        </div>
        <div v-if="loader && posts.length ===  0" class="p-3" >
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
        <template  v-else-if="posts && posts.length > 0">
          <div class="grid grid-cols-1 w-full">
           <template v-for="(post, index) in posts" >
               <div :key="index" :draggable="true"
                     class="w-100 rounded-lg bg-cs-white border hover:bg-cs-foggray my-2 mx-1 hover:shadow-sm" @dragstart="dragstartHandler($event, post)">
                     <div class="m-0  image relative w-100">
                       <div
                           class="text-white border rounded-full p-2.5 flex items-center justify-center bg-[#0000007a] cursor-pointer absolute" style="top:45%;left:48%;"
                           @click.prevent="
                          handleVideoPreview(
                            post.snippet.title,
                            post.snippet.description,
                            videoEmbedUrl(post.id, 'youtube'),
                            'Youtube',
                          )">
                         <i class="cs-play"></i>
                       </div>
                       <div
                           v-if="post.snippet.thumbnails.maxres"
                           :style="{ 'background-image': 'url(' + videoImageURL(post.snippet.thumbnails.maxres, 'youtube') + ')' }"
                           class="w-100 h-40 bg-center bg-cover bg-no-repeat rounded-tl-md rounded-tr-md">
                       </div>
                       <div
                           v-else-if="post.snippet.thumbnails.high"
                           :style="{ 'background-image': 'url(' + videoImageURL(post.snippet.thumbnails.high, 'youtube') + ')' }"
                           class="w-100 h-40 bg-center bg-cover bg-no-repeat">
                       </div>
                       <div
                           v-else-if="post.snippet.thumbnails.default"
                           :style="{ 'background-image': 'url(' + videoImageURL(post.snippet.thumbnails.default, 'youtube') + ')' }"
                           class="w-100 h-40 bg-center bg-cover bg-no-repeat">
                       </div>
                     </div>
                     <div class="p-2">
                       <p class="text-sm font-bold">
                         {{ limitTextLength(post.snippet.title, 42) }}
                       </p>
                       <p class="text-xs text-gray-800">
                         <span>{{ videoDate('youtube', post.snippet.publishedAt) }}</span>
                       </p>
                       <hr class="m-2">
                       <div class="flex justify-between">
                         <div v-tooltip.top-center="'View'" class="flex items-center">
                           <i class="cs-eye text-lg"></i><span class="mx-0.5">{{ intToString(post.statistics.viewCount) }}</span>
                         </div>
                         <div v-tooltip.top-center="'Likes'" class="flex items-center">
                           <i class="cs-thumbs-up"></i><span class="mx-0.5">{{ intToString(post.statistics.likeCount) }}</span>
                         </div>
                         <div v-tooltip.top-center="'Comments'" class="flex items-center">
                           <i class="cs-comment"></i><span class="mx-0.5">{{ intToString(post.statistics.commentCount) }}</span>
                         </div>
                       </div>
                     </div>
               </div>
           </template>
          </div>
          <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <template slot="no-results">
              <NoResult msg="No More Result Found"></NoResult>
            </template>
          </InfiniteLoading>
        </template>
        <template v-else-if="posts.length ===  0">
          <NoResult msg="No Result Found"></NoResult>
        </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'

export default {
  name: "Youtube",
  components: {
    InfiniteLoading,
    NoResult,
    CstDropdown,
    CstDropdownItem
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sortType: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isHD:false,
    }
  },
  watch:{
    isHD(val){
      this.$emit('handle-change','HD', val)
    }
  },
  methods:{
    dragstartHandler (ev, video) {
      // Add the target element's id to the data transfer object
      if (video && video.id) {
        ev.dataTransfer.setData(
            'assistantData',
            JSON.stringify({
              id: ev.target.id,
              type: 'embed',
              heading: video.snippet.title || '',
              media: this.videoEmbedUrl(video.id, 'youtube'),
              p: '',
              link: ''
            })
        )
      }
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleSortType(value) {
      this.$emit('handle-change','sort', value)
    },
    handleVideoPreview(title, description, url, source){
      const data = {
        title: title,
        description: description,
        url: url,
        source: source,
      }
      this.$emit('handle-change','preview',data)
    },
  }
}
</script>

<style scoped>
input[type="checkbox"]:checked + label {
  color: #3a4557;
}
</style>