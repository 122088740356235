 const editorInitialValue = {
  initialValue: '',
  widgets: {
    isCarouselPostAdded: false,
    isThreadedTweetsAdded: false,
    isFirstCommentAdded: false,
    hasLinkPreview: true,
    isMediaAdded: false,
  },
  toolbar: {
    carouselPost:false,
    threadedTweets: false,
    firstComment: false,
    media: true,
    replug: true,
    location: false,
    formatting: true,
    emoji: true,
    more: true,
  },
  maxLimit:0,
  twitterTextEnable: false
}

 export default editorInitialValue
