var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-3 py-6 !border-l-0 !border-t-0 !border-b-0 border !border-[#EAF0F6] relative"},[_c('div',[_c('div',{staticClass:"text-base mb-6 px-0.5 font-weight-500"},[_vm._v(" Social Analytics ")]),_c('nav',{},[_c('router-link',{attrs:{"to":{ name: 'analytics_overview_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('analytics_overview_v3')
              ? 'bg-gray-100 !font-medium !text-[#000D21]'
              : 'text-[#69788E]'},[_c('div',{staticClass:"h-8 w-8 bg-[#E6F0FC] rounded-md inline-flex justify-center items-center mr-4"},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("@assets/img/analytics/sidebar/grid.svg"),"alt":"Grid"}})]),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Overview ")])])]),_c('router-link',{attrs:{"to":_vm.facebookPathAndId()}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('facebook_analytics_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('i',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Facebook ")])])]),_c('router-link',{attrs:{"to":_vm.instagramPathAndId()}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('instagram_analytics_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('i',{staticClass:"h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Instagram ")])])]),_c('router-link',{attrs:{"to":_vm.twitterPathAndId()}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('twitter_analytics_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('i',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-twitter inline-flex justify-center items-center text-[#00AAFB] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Twitter ")])])]),_c('router-link',{attrs:{"to":_vm.linkedInPathAndId()}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('linkedin_analytics_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('i',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-linkedin-in inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Linkedin ")])])])],1),_c('div',{staticClass:"text-base mb-6 mt-7 font-weight-500"},[_vm._v(" Competitor Analytics ")]),_c('nav',{},[_c('router-link',{attrs:{"to":{ name: 'facebook_competitor_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('facebook_competitor_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('i',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Facebook ")])])]),(_vm.featureFlagAffiliate())?_c('router-link',{attrs:{"to":{ name: 'instagram_competitor_overview_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 justify-between hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('instagram_competitor')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('span',{staticClass:"flex justify-center items-center"},[_c('i',{staticClass:"h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"}),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v("Instagram")])]),_c('span',{staticClass:"h-5 px-2 bg-[#FFC555] rounded-full text-[#000D21] flex items-center justify-center"},[_vm._v(" NEW ")])])]):_vm._e()],1),_c('div',{staticClass:"text-base mb-6 mt-7 font-weight-500"},[_vm._v(" Manage Reports ")]),_c('nav',{},[_c('router-link',{attrs:{"to":{ name: 'my_report_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('my_report_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('div',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("@assets/img/analytics/sidebar/scheduled_report.svg"),"alt":"Grid"}})]),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Scheduled Reports ")])])]),_c('router-link',{attrs:{"to":{ name: 'download_reports_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('download_reports_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('div',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("@assets/img/analytics/sidebar/download_reports.svg"),"alt":"Grid"}})]),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Download Reports ")])])]),_c('router-link',{attrs:{"to":{ name: 'reports_setting_v3' }}},[_c('div',{staticClass:"flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group",class:_vm.$route.name.includes('reports_setting_v3')
              ? 'bg-gray-100 font-medium text-[#000D21]'
              : 'text-[#69788E]'},[_c('div',{staticClass:"h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("@assets/img/analytics/sidebar/setting_reports.svg"),"alt":"Grid"}})]),_c('span',{staticClass:"group-hover:text-black-900"},[_vm._v(" Reports Settings ")])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }