import {
  accountBaseUrl,
  authenticationBaseUrl,
  settingsBaseUrl
} from '../../../config/api-utils'
export const fetchProfileUrl = accountBaseUrl + 'me'
export const createPromoterUrl = accountBaseUrl + 'createPromoter'
export const updateUserPackageURL = accountBaseUrl + 'updateUserPackage'
// export const fetchSubscriptionUrl = accountBaseUrl + 'fetchSubscription'
export const updateProfileUrl = accountBaseUrl + 'updateProfile'
export const changePasswordUrl = accountBaseUrl + 'changePassword'
export const saveProfileImageUrl = accountBaseUrl + 'saveProfileImage'
export const appStatusUrl = accountBaseUrl + 'app-status'

export const joinTokenStatusURL = settingsBaseUrl + 'joinTokenStatus'
export const reportSettingURL = settingsBaseUrl + 'reportSetting'
export const logoutUrl = authenticationBaseUrl + 'logout'
