<template>
  <b-modal
    modal-class="normal_modal bv-modal-theme-default bv-modal-theme-card-info-dialog"
    id="Card-info-dialog"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <div class="modal-heading-with-text">
        <h2>Card Info</h2>
      </div>
      <b-button
        variant="studio-theme-grey-icon"
        class="mr-4"
        @click="$bvModal.hide('Card-info-dialog')"
      >
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <div class="modal_body basic_form">
     <div class="card-info-section">
        <div class="d-flex justify-content-between">
              <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  type="text"
                  id="description"
                  placeholder="xxxx xxxxx xxxxxxx"
                  :value="text + paymentInfo.last_four_digits"
                  readonly
                />
                <label for="description" class="label-animated"
                  >Card Number</label
                >
                <span class="field_group__with_icon__input_icon">
                  <i class="fas fa-credit-card"></i>
                </span>
              </div>
            </div>

              <div class="field_group mt-0">
              <div class="field_group__with_icon">
                <input
                  type="text"
                  id="description"
                  placeholder="Cardholder Name"
                  :value="paymentInfo.card_type"
                  readonly
                />
                <label for="description" class="label-animated"
                  >Card Type</label
                >
                <span class="field_group__with_icon__input_icon">
                  <i class="icon-User"></i>
                </span>
              </div>
            </div>
            </div>

            <div class="d-flex justify-content-between">
              <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  type="text"
                  id="description"
                  placeholder="MM"
                   :value="paymentInfo.expiry_date && paymentInfo.expiry_date.split('/')[0]"
                   readonly
                />
                <label for="description" class="label-animated"
                  >Month</label
                >
                <span class="field_group__with_icon__input_icon">
                  <i class="fas fa-calendar"></i>
                </span>
              </div>
            </div>
             <div class="field_group mt-0">
              <div class="field_group__with_icon input_validation">
                <input
                  type="text"
                  id="description"
                  placeholder="YYYY"
                   :value="paymentInfo.expiry_date && paymentInfo.expiry_date.split('/')[1]"
                   readonly
                />
                <label for="description" class="label-animated"
                  >Year</label
                >
                <span class="field_group__with_icon__input_icon">
                  <i class="fas fa-calendar"></i>
                </span>
              </div>
            </div>
            </div>


     </div>
      <!-- <increase-limits-component /> -->
    </div>
  </b-modal>
</template>
<style lang="less">
</style>
<script>
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'CardInfoDialog',
  data () {
    return {
      paymentInfo:{},
      text:'xxxx xxxx xxxx '
      // modalId: 'Card-info-dialog'
    }
  },
  mounted() {
    EventBus.$on("Card-info-dialog", (data) => {
      this.paymentInfo = data;
      this.$bvModal.show("Card-info-dialog");
    });
  },

}
</script>
