<template>
  <b-modal
    @hide="fileModalHide"
    ref="insertFileModal"
    class="z-index-9"
    modal-class="fileUploadModel"
    id="insertFileModel"
    hide-header
    hide-footer
  >
    <div class="fileUploadModel__upload_file_wrapper">
      <div class="fileUploadModel__upload_file_wrapper__left_block">
        <div
          class="fileUploadModel__upload_file_wrapper__left_block__left_inner"
        >
          <h3>Add Media</h3>

          <ul>
            <li
              :class="{ active: getFileWidget.activeTab === 'local' }"
              @click="setFileWidgetActiveTab('local')"
            >
              <i class="icon-upload-now_icon"></i> Upload Now
            </li>
            <li
              :class="{ active: getFileWidget.activeTab === 'recent-upload' }"
              @click="setFileWidgetActiveTab('recent-upload')"
            >
              <i class="icon-all-cs"></i> Recent Upload
            </li>

            <li
              :class="{ active: getFileWidget.activeTab === 'directlink' }"
              @click="setFileWidgetActiveTab('directlink')"
            >
              <i class="icon-link_icon"></i> Direct Link
            </li>
          </ul>
          <hr />
          <ul>
            <!--<li :class="{active : getFileWidget.activeTab === 'instagram'}" @click="setFileWidgetActiveTab('instagram')">-->
            <!--<i class="icon-instagram_icon"></i>Instagram-->
            <!--</li>-->
            <li
              :class="{ active: getFileWidget.activeTab === 'pinterest' }"
              @click="setFileWidgetActiveTab('pinterest')"
            >
              <i class="icon-pinterest_icon"></i>Pinterest
            </li>
            <li
              :class="{ active: getFileWidget.activeTab === 'facebook' }"
              @click="setFileWidgetActiveTab('facebook')"
            >
              <i class="icon-facebook_icon"></i>Facebook
            </li>
            <!--<li :class="{active : getFileWidget.activeTab === 'twitter'}" @click="setFileWidgetActiveTab('twitter')">-->
            <!--<i class="icon-linkedin_icon"></i>Twitter-->
            <!--</li>-->
            <li
              :class="{ active: getFileWidget.activeTab === 'flickr' }"
              @click="setFileWidgetActiveTab('flickr')"
            >
              <i class="icon-flicker_icon"></i>Flickr
            </li>

            <li
              :class="{ active: getFileWidget.activeTab === 'pixabay' }"
              @click="setFileWidgetActiveTab('pixabay')"
            >
              <i class="fa fa-link" data-cy="pixabay"></i>Pixabay
            </li>
            <li
              :class="{ active: getFileWidget.activeTab === 'giphy' }"
              @click="setFileWidgetActiveTab('giphy')"
            >
              <i class="icon-giphy_icon"></i>Giphy
            </li>

            <!--<li :class="{active : getFileWidget.activeTab === 'google'}" @click="setInsertFileActiveTab('google')">-->
            <!--<i class="fa fa-google-plus"></i>Google Drive-->
            <!--</li>-->
          </ul>
        </div>
      </div>
      <local-uploader
        :type="type"
        v-show="getFileWidget.activeTab === 'local'"
      ></local-uploader>

      <recent-upload
        :type="type"
        :source="source"
        v-if="getFileWidget.activeTab === 'recent-upload'"
      ></recent-upload>

      <div
        v-if="getFileWidget.activeTab === 'directlink'"
        class="fileUploadModel__upload_file_wrapper__right_block"
      >
        <div class="flex-row text-right">
          <button
            type="button"
            class="close"
            @click="fileModalHide()"
            v-tooltip.top-center="'Close'"
            >&times;</button
          >
        </div>
        <div
          class="fileUploadModel__upload_file_wrapper__right_block__right_inner"
        >
          <div
            class="fileUploadModel__upload_file_wrapper__right_block__right_inner__media_preview_block"
          >
            <div class="input_field mt-0">
              <label for="">Type or Paste media URL</label>
              <div class="url_input cs-input-grey">
                <input
                  type="url"
                  class=""
                  @input="getMediaURL($event)"
                  placeholder="Paste or Type URL..."
                  ref="searchArea"
                />
                <clip-loader
                  :size="'16px'"
                  class="loader_item"
                  v-if="getAddMediaWidgetVideoTypeLoader"
                ></clip-loader>
                <i
                  class="clearsearch"
                  @click="clearSearch()"
                  v-tooltip.top-center="'Remove'"
                  >×</i
                >
              </div>
            </div>
            <div
              class="media_preview"
              v-if="
                getAddMediaWidgetVideoType === 'image' ||
                getAddMediaWidgetVideoType === 'video'
              "
            >
              <!--<iframe :src="getAddMediaWidgetVideoTypeLink" frameborder="0" allowfullscreen></iframe>-->
              <div v-if="getAddMediaWidgetVideoType === 'video'" class="">
                <p class="cs_red"
                  >By using direct link you can only add images to social media
                  post
                </p>
              </div>
              <img
                style="max-width: 100%"
                v-else-if="getAddMediaWidgetVideoType === 'image'"
                :src="getAddMediaWidgetVideoTypeLink"
                alt=""
              />
            </div>
            <div
              class="bottom_btn_section mt-3"
              v-if="getAddMediaWidgetVideoType !== null"
            >
              <button
                class="btn blue loader royal_blue"
                @click="addMediaLink(source)"
              >
                <span>Add Media</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          getFileWidget.activeTab === 'facebook' ||
          getFileWidget.activeTab === 'instagram' ||
          getFileWidget.activeTab === 'pinterest' ||
          getFileWidget.activeTab === 'twitter' ||
          getFileWidget.activeTab === 'flickr'
        "
        class="fileUploadModel__upload_file_wrapper__right_block"
      >
        <div class="flex-row text-right">
          <button
            type="button"
            class="close"
            @click="$bvModal.hide('insertFileModel')"
            data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div
          class="fileUploadModel__upload_file_wrapper__right_block__right_inner"
          style="min-height: 400px"
        >
          <div class="media_preview_block">
            <div class="input_field mt-0">
              <label for="" v-if="getFileWidget.activeTab === 'facebook'"
                >Type or Paste Facebook Image Post URL</label
              >
              <label for="" v-else-if="getFileWidget.activeTab === 'instagram'"
                >Type or Paste Instagram Image Post URL</label
              >
              <label for="" v-else-if="getFileWidget.activeTab === 'pinterest'"
                >Type or Paste Pinterest Image Post URL</label
              >
              <label for="" v-else-if="getFileWidget.activeTab === 'twitter'"
                >Type or Paste Twitter image tweet URL</label
              >
              <label for="" v-else-if="getFileWidget.activeTab === 'flickr'"
                >Type or Paste Flickr photoURL</label
              >

              <div class="search_input with_icon_left">
                <div class="search_inner w-100 cs-input-grey">
                  <input
                    type="url"
                    class=""
                    @input="
                      getMicroLinkMediaURL($event, getFileWidget.activeTab)
                    "
                    v-model="keyword"
                    placeholder="Paste or Type URL..."
                    ref="searchArea"
                  />
                  <div class="bottom_btn_section mt-3">
                    <button
                      class="search_btn"
                      @click.prevent="searchByKeywordUploads(search)"
                    >
                      <i class="icon-Search"></i>
                    </button>
                  </div>
                  <i
                    class="clearsearch"
                    @click="clearSearch()"
                    v-tooltip.top-center="'Clear'"
                    >×</i
                  >
                  <clip-loader
                    :size="'16px'"
                    class="loader_item"
                    v-if="getAddMediaWidgetVideoTypeLoader"
                  ></clip-loader>
                </div>
              </div>
            </div>
            <div
              class="media_preview"
              v-if="getAddMediaWidgetVideoType !== null"
            >
              <iframe
                v-if="getAddMediaWidgetVideoType === 'video'"
                :src="getAddMediaWidgetVideoTypeLink"
                frameborder="0"
                allowfullscreen
              ></iframe>
              <img
                style="max-width: 100%"
                v-else-if="getAddMediaWidgetVideoType === 'image'"
                :src="getAddMediaWidgetVideoTypeLink"
                alt=""
              />
            </div>
            <div
              class="bottom_btn_section mt-3"
              v-if="getAddMediaWidgetVideoType !== null"
            >
              <button
                class="btn blue loader royal_blue"
                @click="addMediaLink(source)"
              >
                <span>Add Media</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <pixabay-media
        :source="source"
        :type="type"
        v-if="getFileWidget.activeTab === 'pixabay'"
      ></pixabay-media>
      <giphy-media
        :source="source"
        :type="type"
        v-if="getFileWidget.activeTab === 'giphy'"
      ></giphy-media>
    </div>
  </b-modal>
</template>

<script>
import LocalUploader from './LocalUploader.vue'
import RecentUpload from './RecentUpload.vue'
import PixabayMedia from '../media/pixabayMedia.vue'
import GiphyMedia from '../media/giphyMedia.vue'
// import pu from '@lumotive/composer/components/assistant/Pixabay'
import NoResultsFound from '@src/modules/discovery/components/topics/NoResultsFound'
import InfiniteLoading from 'vue-infinite-loading'
import { EventBus } from '@common/lib/event-bus'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['type'],
  components: {
    LocalUploader,
    RecentUpload,
    NoResultsFound,
    InfiniteLoading,
    PixabayMedia,
    GiphyMedia
  },

  data () {
    return {
      keyword: '',
      source: null
    }
  },

  created () {
    // console.debug('insertFileModal')
    this.resetFileWidget()
  },
  computed: {
    ...mapGetters([
      'getFileWidget',
      'getAddMediaWidgetVideoType',
      'getAddMediaWidgetVideoTypeLoader',
      'getAddMediaWidgetVideoTypeLink',
      'getImagesLoader',
      'getImagesPosts',
      'getImagesPaginationLoader'
    ])
  },
  mounted () {
    const thiss = this
    $('#insertFileModel').on('hidden.bs.modal', function () {
      thiss.setAddMediaWidgetVideoType(null)
      thiss.resetFileWidget()
      $('.url_input input').val('')
    })

    EventBus.$on('mediaLibrary', (data) => {
      console.debug('insertFile', data)
      this.source = data.source
    })
  },
  beforeDestroy () {
    EventBus.$off('mediaLibrary')
  },
  methods: {
    ...mapActions([
      'setFileWidgetActiveTab',
      'setAddMediaWidgetVideoType',
      'setImagesPage',
      'resetFileWidget',
      'resetAddMediaWidget'
    ]),
    onInfiniteImages ($state) {
      console.log(
        'On infinite images',
        this.getImagesScroll,
        this.getImagesPage
      )
      if (this.getImagesScroll) {
        this.setImagesPage(this.getImagesPage + 1)
        this.getMediaImages(this.getFileWidget.activeTab, this.keyword, $state)
        // this.getImagesContent($state)
      }
    },
    clearSearch () {
      this.$refs.searchArea.value = ''
      this.keyword = ''
      this.resetAddMediaWidget()
    },
    fileModalHide () {
      this.$refs.searchArea.value = ''
      this.keyword = ''
      this.resetAddMediaWidget()
      this.$bvModal.hide('insertFileModel')
    }
  },

  watch: {
    'getFileWidget.activeTab' () {
      this.keyword = ''
      this.resetAddMediaWidget()
    }
  }
}
</script>
