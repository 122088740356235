var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block"},[_c('div',{staticClass:"flex-row text-right"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('insertFileModel')}}},[_vm._v("×")])]),_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner"},[_c('h3',[_vm._v("All Uploads "),(_vm.getFileWidget.localUploads.total)?_c('span',[_vm._v("| "+_vm._s(_vm.getFileWidget.localUploads.total)+" Files")]):_vm._e()]),_c('div',{staticClass:"flex-row d-flex"},[_c('div',{},[_c('b-dropdown',{staticClass:"studio-theme-dropdown mr-2 dropdown-size-medium",attrs:{"variant":"studio-theme","id":"dropdown-right","no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"icon-all-cs"}),_c('span',{staticClass:"capitalize_text"},[_vm._v(_vm._s(_vm.getFileWidget.localUploads.filters.type))]),_c('i',{staticClass:"icon-dropdown-cs"})]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.type === 'All Types',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadType('All Types')}}},[_c('i',{staticClass:"icon-all-cs"}),_vm._v("All Types")]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.type === 'Images',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadType('Images')}}},[_c('i',{staticClass:"icon-Image"}),_vm._v("Images")]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.type === 'Videos',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadType('Videos')}}},[_c('i',{staticClass:"icon-Film"}),_vm._v("Videos")]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.type === 'Gifs',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadType('Gifs')}}},[_c('i',{staticClass:"icon-Image"}),_vm._v("Gifs")])],2),_c('b-dropdown',{staticClass:"studio-theme-dropdown mr-2 dropdown-size-medium",attrs:{"variant":"studio-theme","id":"dropdown-right","no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"icon-schedule-cs"}),_c('span',{staticClass:"capitalize_text"},[_vm._v(_vm._s(_vm.getFileWidget.localUploads.filters.sort))]),_c('i',{staticClass:"icon-dropdown-cs"})]),_c('b-dropdown-item',{class:{
              active:
                _vm.getFileWidget.localUploads.filters.sort ===
                'Most recent uploads',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadSort('Most recent uploads')}}},[_c('i',{staticClass:"icon-schedule-cs"}),_vm._v("Most recent uploads")]),_c('b-dropdown-item',{class:{
              active:
                _vm.getFileWidget.localUploads.filters.sort === 'Oldest uploads',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadSort('Oldest uploads')}}},[_c('i',{staticClass:"icon-Image"}),_vm._v("Oldest uploads")]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.sort === 'A to Z',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadSort('A to Z')}}},[_c('i',{staticClass:"icon-AtoZ"}),_vm._v("A to Z")]),_c('b-dropdown-item',{class:{
              active: _vm.getFileWidget.localUploads.filters.sort === 'Z to A',
            },on:{"click":function($event){$event.preventDefault();return _vm.changeUploadSort('Z to A')}}},[_c('i',{staticClass:"icon-ZtoA"}),_vm._v("Z to A")])],2)],1),_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"search_input with_icon_left"},[_c('div',{staticClass:"search_inner w-100 cs-input-grey"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"searchArea",attrs:{"type":"text","placeholder":"Search by name"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},function($event){return _vm.searchByKeywordDebounce($event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchByKeywordUploads(_vm.search)}}}),_c('button',{staticClass:"search_btn",on:{"click":function($event){$event.preventDefault();return _vm.searchByKeywordUploads(_vm.search)}}},[_c('i',{staticClass:"icon-Search"})]),(_vm.search)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Clear'),expression:"'Clear'",modifiers:{"top-center":true}}],staticClass:"clearsearch",on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("×")]):_vm._e()])])])]),_c('hr'),_c('div',{staticClass:"row d-flex p-only-horizontal"},[(
          _vm.getPublishLoaders.fetchWidgetUploadedFiles &&
          _vm.getFileWidget.localUploads.items.length === 0
        )?_c('clip-loader',{attrs:{"color":"#2f8ae0","size":'30px'}}):[(_vm.getFileWidget.localUploads.items.length)?[_c('div',{staticClass:"row d-flex fileUploadModel__upload_file_wrapper__right_block__right_inner__fix_height"},[_vm._l((_vm.getFileWidget.localUploads.items),function(file){return _c('div',{staticClass:"col-4 col-padding",on:{"click":function($event){return _vm.processFileSelection(file)}}},[_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__pic_block"},[_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__pic_block__content"},[(file.name)?_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(file.name))]):_vm._e(),(file.size)?_c('p',{staticClass:"size"},[_vm._v(_vm._s(_vm.bytesToSize(file.size)))]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.getWorkspaceTimeZoneTime( file.created_at, 'MMMM DD, YYYY, hh:mm A' )))]),_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__content__action_icons"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove Media'),expression:"'Remove Media'",modifiers:{"top-center":true}}],staticClass:"fa fa-remove image_remove_icon",on:{"click":[function($event){$event.stopPropagation();return _vm.processFileSelection()},function($event){return _vm.removeMedia(file)}]}}),(file.canva_design_id)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Canva Edit'),expression:"'Canva Edit'",modifiers:{"top-center":true}}],staticClass:"icon-edit-cs canva_edit_icon",staticStyle:{"z-index":"999999999"},on:{"click":[function($event){$event.stopPropagation();return _vm.processFileSelection()},function($event){return _vm.editCanvaDesign(file)}]}}):_vm._e()])]),_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_img absolute-spinner",class:{
                    active:
                      _vm.getFileWidget.localUploads.selectedItems.includes(file),
                  }},[(file.thumbnail)?_c('img',{attrs:{"src":_vm.getResizedImageURL(file.thumbnail, 0, 450),"alt":""},on:{"error":function($event){$event.target.src = `${file.thumbnail}`}}}):_c('img',{attrs:{"src":_vm.getResizedImageURL(file.link, 0, 450),"alt":""},on:{"error":function($event){$event.target.src = `${file.link}`}}}),(
                      _vm.getFileWidget.localUploads.selectedItems.includes(file)
                    )?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])])}),(_vm.getFileWidget.localUploads.paginationScroll)?_c('div',{staticClass:"col-12"},[_c('infinite-loading',{ref:"infiniteLoading",attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.onInfiniteWidgetUploadedFiles}},[_c('span',{staticClass:"col-12 pt-3 d-block",attrs:{"slot":"spinner"},slot:"spinner"})])],1):_vm._e()],2)]:_vm._e(),(
            _vm.getFileWidget.localUploads.paginationScroll === false &&
            _vm.getFileWidget.localUploads.items.length === 0
          )?[(_vm.search && _vm.search.trim() && _vm.search.length)?[_c('no-results-found',{attrs:{"message":'No files have been found for selected filters.'}})]:[_c('no-results-found',{attrs:{"message":'No files have been uploaded.'}})]]:_vm._e()]],2),(_vm.getFileWidget.localUploads.selectedItems.length)?_c('div',{staticClass:"bottom_btn_section"},[_c('button',{staticClass:"btn blue loader royal_blue mt-3",on:{"click":function($event){$event.preventDefault();return _vm.insertFile(_vm.source)}}},[_c('span',[_vm._v("Insert ")]),_c('span',[_vm._v(_vm._s(_vm.getFileWidget.localUploads.selectedItems.length))]),_c('span',[_vm._v(" file(s)")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }