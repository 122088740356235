<template>
  <div
    class="w-full flex flex-row max-h-[calc(100vh-60px)] h-[calc(100vh-60px)]"
  >
    <aside class="!max-w-[15rem] w-full overflow-auto bg-white">
      <Sidebar />
    </aside>
    <main
      :class="
        $route.name.includes('instagram_competitor')
          ? 'flex-1 overflow-y-auto'
          : 'analytics-wrapper  !overflow-y-auto'
      "
    >
      <Header
        v-if="$route.name.includes('instagram_competitor')"
        :title="
          state.singleReportData
            ? state.singleReportData.name
            : 'Instagram Competitor Analytics'
        "
        @show-manage-competitors-modal="showManageModal"
        @get-date-range="getDateRange"
        @default-date-range="getDateRange"
      />
      <div class="w-full">
        <router-view
          :add-success-data="state.addSuccessData"
          :add-error-data="state.addErrorData"
          :fetch-success-data="state.fetchReportsSuccessData"
          :fetch-error-data="state.fetchReportsErrorData"
          :single-report-data="state.singleReportData"
          :single-error-data="state.singleReportErrorData"
          :all-types-graph-data="state.allTypesGraphData"
          :specific-type-graph-data="state.specificTypeGraphData"
          :followers-growth-graph-data="state.followersGrowthGraphData"
          :posting-activity-table-data="state.postingActivityTableData"
          :graph-media-type="state.mediaType"
          :instagram-analytics-main-info="state.instagramAnalyticsMainInfo"
          @get-analytics-reports="getAnalyticReports"
          @show-manage-competitors-modal="showManageModal"
          @delete-competitors-report="deleteCompetitorReport"
          @get-report-data="getSingleReport"
          @get-graph-data="fetchGraphData"
          @get-table-data="fetchTableData"
          @chart-type="setChartType"
        />
      </div>
    </main>
    <ManageCompetitorsModal
      :report-data="state.singleReportData"
      :success-data="state.searchedSuccessData"
      :error-data="state.searchedErrorData"
      @searched-query="searchCompetitor"
      @save-competitors="saveCompetitorReport"
    />
    <SendReportByEmailModal></SendReportByEmailModal>
    <ScheduleReportModal></ScheduleReportModal>
  </div>
</template>

<script>
// libraries
import { defineComponent, nextTick, reactive, inject, computed } from 'vue'

// components
import Sidebar from '@src/modules/analytics_v3/components/Sidebar'
import Header from '@src/modules/analytics_v3/components/Header'
import ManageCompetitorsModal from '@src/modules/analytics_v3/components/ManageCompetitorsModal.vue'

// constants
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import { useStore } from '@state/base'
import SendReportByEmailModal from '@src/modules/analytics/components/reports/modals/SendReportByEmailModal.vue'
import ScheduleReportModal from '@src/modules/analytics/components/reports/modals/ScheduleReportModal.vue'

export default defineComponent({
  name: 'MainAnalytics',
  components: {
    ScheduleReportModal,
    SendReportByEmailModal,
    ManageCompetitorsModal,
    Sidebar,
    Header,
  },
  setup() {
    const root = inject('root')
    const { $bvModal, $route } = root
    const { dispatch, getters } = useStore()

    const state = reactive({
      searchedSuccessData: [],
      searchedErrorData: [],
      addSuccessData: null,
      addErrorData: null,
      fetchReportsSuccessData: [],
      fetchReportsErrorData: [],
      deleteReportSuccessData: [],
      deleteReportErrorData: [],
      singleReportData: null,
      singleReportErrorData: null,
      dateRange: '',
      defaultDateRange: '',
      allTypesGraphData: [],
      specificTypeGraphData: [],
      followersGrowthGraphData: [],
      postingActivityTableData: [],
      instagramAnalyticsMainInfo: [],
      mediaType: '',
      mediaProductType: '',
      currentReportId: '',
      postingActivityDataCheck: false,
    })
    const payloadGraphs = computed(() => ({
      _id: state.currentReportId,
      date_filter: state.dateRange ? state.dateRange : state.defaultDateRange,
      time_zone: getters.getActiveWorkspace.timezone,
    }))

    /**
     * @description called for event 'searched-query' from ManageCompetitorsModal. Uses a composable to make the api call.
     * @param val
     */
    const searchCompetitor = async (val) => {
      const data = new FormData()
      data.append('search', val)
      const { competitor, error } = await useCompetitorsFactory(
        'getIgAccount',
        data
      )

      state.searchedSuccessData = competitor
      state.searchedErrorData = error
    }

    /**
     * @description called for event 'save-competitors' from ManageCompetitorsModal. Saves the report by making an api call.
     * @param selectedCompetitors
     * @param reportTitle
     * @returns {Promise<void>}
     */
    const saveCompetitorReport = async (selectedCompetitors, reportTitle) => {
      const payload = {
        name:
          reportTitle === 'Untitled' && state.fetchReportsSuccessData?.length
            ? `Untitled-${
                state.fetchReportsSuccessData?.length + 1
              }${Math.floor(Math.random() * 100)}`
            : reportTitle,
        platform_type: 'instagram',
        workspace_id: getters.getActiveWorkspace._id,
        created_by_user_id: getters.getActiveWorkspace.user_id,
        updated_by_user_id: getters.getActiveWorkspace.user_id,
        ...selectedCompetitors,
      }

      const errorText = 'Could not add/edit the report.'

      const { competitor, error } = await useCompetitorsFactory(
        'addUpdateCompetitorReport',
        payload,
        dispatch,
        errorText
      )

      state.addSuccessData = competitor
      state.singleReportData = competitor
      state.addErrorData = error
      state.singleReportErrorData = error

      if (state.addSuccessData?._id) {
        const index = state.fetchReportsSuccessData.findIndex(
          (report) => report?._id === state.addSuccessData._id
        )
        index >= 0
          ? (state.fetchReportsSuccessData[index] = state.addSuccessData)
          : state.fetchReportsSuccessData.unshift(state.addSuccessData)
        if ($route.name === 'instagram_competitor_v3') reFetchAnalyticsData()
      }
    }

    /**
     * @description called for event 'get-analytics-reports' from CompetitorList view. Requires platform name and workspace ID.
     * @param platformName
     */
    const getAnalyticReports = async (platformName) => {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        platform_type: platformName,
      }

      const errorText = 'Could not fetch analytics reports.'
      const { competitor, error } = await useCompetitorsFactory(
        'getCompetitorReportsByWorkspace',
        payload,
        dispatch,
        errorText
      )

      state.fetchReportsSuccessData = competitor
      if (state.fetchReportsSuccessData.length) {
        state.fetchReportsSuccessData.sort((a, b) => {
          if (a?.created_at < b?.created_at) return 1
          return -1
        })
      }
      state.fetchReportsErrorData = error
    }

    /**
     * @description called for event 'get-report-data' from the competitor view. Requires the report ID.
     * @param reportId
     */
    const getSingleReport = async (reportId) => {
      resetReportAnalyticsData()
      state.currentReportId = reportId
      const payload = {
        _id: reportId,
      }

      const errorText = 'Could not fetch report data.'
      const { competitor, error } = await useCompetitorsFactory(
        'getCompetitorReport',
        payload,
        dispatch,
        errorText
      )
      state.singleReportData = competitor
      state.singleReportErrorData = error
    }

    /**
     * @description called for event 'delete-competitors-report' from CompetitorListingTile. Deletes the report with respect to the report ID.
     * @param reportId
     * @param reportName
     * @returns {Promise<void>}
     */
    const deleteCompetitorReport = async (reportId, reportName) => {
      const payload = {
        _id: reportId,
      }

      const errorText = `Could not delete ${reportName}.`

      const { competitor, error } = await useCompetitorsFactory(
        'deleteCompetitorReport',
        payload,
        dispatch,
        errorText
      )

      state.deleteReportSuccessData = competitor
      state.deleteReportErrorData = error

      if (state.deleteReportSuccessData === true) {
        state.fetchReportsSuccessData.splice(
          state.fetchReportsSuccessData.findIndex(
            (competitor) => competitor._id === reportId
          ),
          1
        )
      }
    }

    /**
     * @description opens up the manage competitors modal and sets the report's competitors in the list
     * @param reportData
     * @returns {Promise<void>}
     */
    const showManageModal = async (reportData = {}) => {
      state.singleReportData = reportData
      await nextTick()
      $bvModal.show('manage-competitors-modal')
    }

    /**
     * @description handles all the methods of fetching graph data: (type is the actual end point for the specific graphs )
     * @param type
     * @param id
     * @returns {string}
     */
    const fetchGraphData = (type = '', id = '') => {
      state.currentReportId = id
      switch (type) {
        case 'instagramAnalyticsMainInfo':
          getInstagramAnalyticsMainInfo()
          break
        case 'allTypesGraph':
          getAllTypesGraph()
          break
        case 'postingActivityBySpecificType':
          getPostingActivityBySpecificType()
          break
        case 'followersGrowthComparison':
          getFollowersGrowthComparison()
          break
        default:
          return 'default'
      }
    }

    /**
     * @description handles all the methods for fetching table data for analytics
     * @param type
     */
    const fetchTableData = (type = '') => {
      switch (type) {
        case 'postingActivityTableByType':
          getPostingActivityTableByType()
          break
      }
    }

    /**
     * @description method triggered when user changes the date range, sets a date range by default if none is selected. i.e., last month
     * @param dateRange
     * @param type
     */
    const getDateRange = (dateRange, type = '') => {
      type === 'default'
        ? state.defaultDateRange
          ? (state.dateRange = state.defaultDateRange)
          : (state.defaultDateRange = dateRange)
        : (state.dateRange = dateRange)

      reFetchAnalyticsData()
    }

    /**
     * @description fetches data for posting activity by all types graph
     * @returns {Promise<void>}
     */
    const getAllTypesGraph = async () => {
      if (!state.currentReportId) return
      const payload = payloadGraphs.value
      const errorText = `Could not fetch data for Posting By Type Graph.`
      const { competitor } = await useCompetitorsFactory(
        'postingActivityGraphByTypes',
        payload,
        dispatch,
        errorText
      )

      state.allTypesGraphData = competitor
      if (competitor?.length) {
        state.mediaType = competitor?.length ? competitor[0].mediaType : ''
        state.mediaProductType = competitor?.length
          ? competitor[0].mediaProductType
          : ''

        await getPostingActivityTableByType()
        await getPostingActivityBySpecificType()
        if (competitor) state.postingActivityDataCheck = true
      }
    }

    /**
     * @description fetches data for posting activity by specific type graph
     * @returns {Promise<void>}
     */
    const getPostingActivityBySpecificType = async () => {
      if (
        !state.currentReportId ||
        !state.mediaProductType ||
        (!state.mediaType && state.postingActivityDataCheck)
      )
        return
      const payload = {
        ...payloadGraphs.value,
        media_type: state.mediaType,
        media_product_type: state.mediaProductType,
      }
      const errorText = `Could not fetch data for Specific Type Graph.`
      const { competitor } = await useCompetitorsFactory(
        'postingActivityBySpecificType',
        payload,
        dispatch,
        errorText
      )
      if (competitor?.length) state.specificTypeGraphData = competitor
    }

    /**
     * @description fetches data for posting activity for specific type table
     * @returns {Promise<void>}
     */
    const getPostingActivityTableByType = async () => {
      if (
        !state.currentReportId ||
        !state.mediaProductType ||
        (!state.mediaType && state.postingActivityDataCheck)
      )
        return
      const payload = {
        ...payloadGraphs.value,
        media_type: state.mediaType,
        media_product_type: state.mediaProductType,
        sort_order: 'count',
      }
      const errorText = `Could not fetch data for Specific Type Table.`
      const { competitor } = await useCompetitorsFactory(
        'postingActivityTableByType',
        payload,
        dispatch,
        errorText
      )
      if (competitor?.length) state.postingActivityTableData = competitor
    }

    /**
     * @description fetches data for followers growth comparison graph
     * @returns {Promise<void>}
     */
    const getFollowersGrowthComparison = async () => {
      if (!state.currentReportId) return
      const payload = payloadGraphs.value
      const errorText = `Could not fetch data for Followers Growth Comparison Graph.`
      const { competitor } = await useCompetitorsFactory(
        'followersGrowthComparison',
        payload,
        dispatch,
        errorText
      )
      if (competitor?.length) state.followersGrowthGraphData = competitor
    }

    /**
     * @description fetches the info for competitor tiles, performance comparison graph, performance review graph and table.
     * @returns {Promise<void>}
     */
    const getInstagramAnalyticsMainInfo = async () => {
      if (!state.currentReportId) return
      const payload = payloadGraphs.value
      const errorText = `Could not fetch data for report analytics.`
      const { competitor } = await useCompetitorsFactory(
        'dataTableMetrics',
        payload,
        dispatch,
        errorText
      )
      if (competitor?.length) state.instagramAnalyticsMainInfo = competitor
    }

    /**
     * @description sets the headers for posting activity by specific type table and graph
     * @param mediaType
     * @param mediaProductType
     */
    const setChartType = (mediaType, mediaProductType) => {
      state.mediaType = mediaType
      state.mediaProductType = mediaProductType
      getPostingActivityBySpecificType()
      getPostingActivityTableByType()
    }

    /**
     * @description fetches data for all the graphs and tables when the date range is changed
     */
    const reFetchAnalyticsData = () => {
      getInstagramAnalyticsMainInfo()
      getAllTypesGraph()
      getPostingActivityBySpecificType()
      getFollowersGrowthComparison()
      getPostingActivityTableByType()
    }

    /**
     * @description method to reset the local data for report analytics
     */
    const resetReportAnalyticsData = () => {
      state.dateRange = state.defaultDateRange
      state.allTypesGraphData = []
      state.mediaProductType = ''
      state.mediaType = ''
      state.followersGrowthGraphData = []
      state.specificTypeGraphData = []
      state.postingActivityTableData = []
      state.instagramAnalyticsMainInfo = []
    }

    return {
      state,
      payloadGraphs,

      getAnalyticReports,
      searchCompetitor,
      saveCompetitorReport,
      deleteCompetitorReport,
      getSingleReport,
      showManageModal,
      fetchGraphData,
      fetchTableData,
      getDateRange,
      reFetchAnalyticsData,
      setChartType,
      getFollowersGrowthComparison,
      resetReportAnalyticsData,
      getPostingActivityTableByType,
      getInstagramAnalyticsMainInfo,
      getAllTypesGraph,
      getPostingActivityBySpecificType,
    }
  },
})
</script>

<style scoped></style>
