const state = {
  notifications: {
    items: [],
    loader: false,
    saveLoader: false
  }
}

// getters
const getters = {
  getNotifications: (state) => {
    return state.notifications.items
  },
  getNotificationsSaveLoader: (state) => {
    return state.notifications.saveLoader
  }
}

// actions
const actions = {
  setNotificationsLoader ({ commit }, status) {
    commit('setNotificationsLoader', status)
  },
  setNotificationsSaveLoader ({ commit }, status) {
    commit('setNotificationsSaveLoader', status)
  }
}

// mutations
const mutations = {
  setNotificationsLoader (state, status) {
    state.notifications.loader = status
  },
  setNotificationsSaveLoader (state, status) {
    state.notifications.saveLoader = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
