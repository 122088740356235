<template>
  <div class="article-search-bar-pane__search__input__question">
    <div
      class="dropdown dropdown-hints-suggestions default_style_dropdown prevent_close_dropdown"
    >
      <div
        class="dropdown_header"
        v-tooltip.top-center="'Search Tips'"
        data-toggle="dropdown"
      >
        <i class="icon-help-cs"></i>
      </div>
      <div class="dropdown-menu dropdown-menu-right twitter_assistant_dropdown">
        <div class="search_detail">
          <p>Search by</p>
          <table class="table">
            <tbody>
              <tr>
                <td>Search for a keyword:</td>
                <td>marketing</td>
              </tr>
              <tr>
                <td>Search for two keywords:</td>
                <td>content marketing</td>
              </tr>
              <tr>
                <td>Search for an exact phrase:</td>
                <td>"digital marketing"</td>
              </tr>

              <tr>
                <td>Remove irrelevant results:</td>
                <td>marketing -seo</td>
              </tr>
              <tr>
                <td>Search for keywords in a site:</td>
                <td>content url:contentstudio.io</td>
              </tr>
              <tr>
                <td>Search for tweets sent from an account:</td>
                <td>from:contentstudioio</td>
              </tr>
              <tr>
                <td>Search for multiple keywords:</td>
                <td>search marketing OR SEO OR SEM</td>
              </tr>
              <tr>
                <td>Search for keyword with image or video only:</td>
                <td>content filter:media</td>
              </tr>

              <tr>
                <td>Search for keyword excluding retweets:</td>
                <td>content -filter:retweets</td>
              </tr>

              <tr>
                <td>Search tweets where twitter channel mentioned:</td>
                <td>@jaybaer</td>
              </tr>
              <!--                        <tr>-->
              <!--                            <td>Search by hashtag:</td>-->
              <!--                            <td>#marketing</td>-->
              <!--                        </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.twitter_assistant_dropdown {
  .table {
    tbody {
      tr {
        td:first-child {
          width: 340px !important;
        }
      }
    }
  }
  min-width: 630px;
}
</style>
<script>
export default {
  methods: {}
}
</script>
