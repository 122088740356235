import { loadersTypes, publishLoaders } from './mutation-types'

export default {
  state: {
    editorSelector: '',
    fetchReplugLink: false,
    fetchLongLink: false,
    fetchHashTagsVelocity: false,
    processContentRewriting: false,

    // social image
    uploadCommonSharingImage: { status: false, position: -1 },
    uploadFacebookSharingImage: { status: false, position: -1 },
    uploadTwitterSharingImage: { status: false, position: -1 },
    uploadPinterestSharingImage: false,
    uploadTumblrSharingImage: false,

    // social video
    upload_common_sharing_video: false,
    upload_facebook_sharing_video: false,
    upload_twitter_sharing_video: false,
    upload_linkedin_sharing_video: false,
    upload_pinterest_sharing_video: false,
    upload_tumblr_sharing_video: false,
    upload_instagram_sharing_video: false,
    upload_youtube_sharing_video: false,
    upload_tiktok_sharing_video: false,

    // social previews
    fetchCommonSharingPreview: false,
    fetchFacebookSharingPreview: false,
    fetchTwitterSharingPreview: false,
    fetchLinkedinSharingPreview: false,
    fetchPinterestSharingPreview: false,
    fetchTumblrSharingPreview: false,
    fetchInstagramSharingPreview: false,
    fetchYoutubeSharingPreview: false,
    fetchTiktokSharingPreview: false,

    // social link shortning
    fetchCommonShortLinks: false,
    fetchFacebookShortLinks: false,
    fetchTwitterShortLinks: false,
    fetchLinkedinShortLinks: false,
    fetchPinterestShortLinks: false,
    fetchTumblrShortLinks: false,
    fetchInstagramShortLinks: false,
    fetchYoutubeShortLinks: false,

    fetchFacebookMentionsSuggestions: false,
    fetchTwitterMentionsSuggestions: false,
    fetchLinkedinMentionsSuggestions: false,
    fetchHashTagsSuggestions: false,

    initializeArticlePostCreation: false,
    processBlogPost: false,
    processSocialShare: false,
    processRetweetShare: false,
    processSavePublication: false,
    shrinkCommonSharingSourceLink: false,
    shrinkPinterestSharingSourceLink: false,

    // widget
    fetchWidgetUploadedFiles: false
  },
  actions: {
    setBlogEditorSelector ({ commit }, editor) {
      commit('setBlogEditorSelector', editor)
    },

    setFetchReplugLinkLoader ({ commit }, status) {
      commit('setFetchReplugLinkLoader', status)
    },

    setFetchLongLinkLoader ({ commit }, status) {
      commit('setFetchLongLinkLoader', status)
    },

    setFetchHashTagsVelocityLoader ({ commit }, status) {
      commit('setFetchHashTagsVelocityLoader', status)
    },

    setProcessContentRewritingLoader ({ commit }, status) {
      commit('setProcessContentRewritingLoader', status)
    },

    setUploadFacebookSharingImageLoader ({ commit }, loader) {
      commit('setUploadFacebookSharingImageLoader', loader)
    },

    setUploadTwitterSharingImageLoader ({ commit }, loader) {
      commit('setUploadTwitterSharingImageLoader', loader)
    },

    setUploadPinterestSharingImageLoader ({ commit }, status) {
      commit('setUploadPinterestSharingImageLoader', status)
    },

    setUploadTumblrSharingImageLoader ({ commit }, status) {
      commit('setUploadTumblrSharingImageLoader', status)
    },

    // social image
    setUploadCommonSharingImageLoader ({ commit }, loader) {
      commit('setUploadCommonSharingImageLoader', loader)
    },

    // social previews
    setFetchCommonSharingPreviewLoader ({ commit }, status) {
      commit('setFetchCommonSharingPreviewLoader', status)
    },

    setFetchFacebookSharingPreviewLoader ({ commit }, status) {
      commit('setFetchFacebookSharingPreviewLoader', status)
    },

    setFetchTwitterSharingPreviewLoader ({ commit }, status) {
      commit('setFetchTwitterSharingPreviewLoader', status)
    },

    setFetchLinkedinSharingPreviewLoader ({ commit }, status) {
      commit('setFetchLinkedinSharingPreviewLoader', status)
    },

    setFetchPinterestSharingPreviewLoader ({ commit }, status) {
      commit('setFetchPinterestSharingPreviewLoader', status)
    },

    setFetchTumblrSharingPreviewLoader ({ commit }, status) {
      commit('setFetchTumblrSharingPreviewLoader', status)
    },

    setFetchInstagramSharingPreviewLoader ({ commit }, status) {
      commit('setFetchInstagramSharingPreviewLoader', status)
    },
    setFetchYoutubeSharingPreviewLoader ({ commit }, status) {
      commit('setFetchYoutubeSharingPreviewLoader', status)
    },

    // social link shortning
    setFetchCommonShortLinksLoaders ({ commit }, status) {
      commit('setFetchCommonShortLinksLoaders', status)
    },

    setFetchFacebookShortLinksLoaders ({ commit }, status) {
      commit('setFetchFacebookShortLinksLoaders', status)
    },

    setFetchTwitterShortLinksLoaders ({ commit }, status) {
      commit('setFetchTwitterShortLinksLoaders', status)
    },

    setFetchLinkedinShortLinksLoaders ({ commit }, status) {
      commit('setFetchLinkedinShortLinksLoaders', status)
    },

    setFetchPinterestShortLinksLoaders ({ commit }, status) {
      commit('setFetchPinterestShortLinksLoaders', status)
    },

    setFetchTumblrShortLinksLoaders ({ commit }, status) {
      commit('setFetchTumblrShortLinksLoaders', status)
    },

    setFetchInstagramShortLinksLoaders ({ commit }, status) {
      commit('setFetchInstagramShortLinksLoaders', status)
    },

    setFetchYoutubeShortLinksLoaders ({ commit }, status) {
      commit('setFetchYoutubeShortLinksLoaders', status)
    },

    setInitializeArticlePostCreationLoader ({ commit }, status) {
      commit('setInitializeArticlePostCreationLoader', status)
    },

    setProcessBlogPostLoader ({ commit }, status) {
      commit('setProcessBlogPostLoader', status)
    },

    setProcessSocialShareLoader ({ commit }, status) {
      commit('setProcessSocialShareLoader', status)
    },

    setProcessRetweetShareLoader ({ commit }, status) {
      commit('setProcessRetweetShareLoader', status)
    },

    setProcessSavePublicationLoader ({ commit }, status) {
      commit('setProcessSavePublicationLoader', status)
    },

    setShrinkCommonSharingSourceLinkLoader ({ commit }, status) {
      commit('setShrinkCommonSharingSourceLinkLoader', status)
    },

    setShrinkPinterestSharingSourceLinkLoader ({ commit }, status) {
      commit('setShrinkPinterestSharingSourceLinkLoader', status)
    },

    setFetchWidgetUploadedFilesLoader ({ commit }, status) {
      commit('setFetchWidgetUploadedFilesLoader', status)
    }
  },
  mutations: {
    [loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER] (
      state,
      status
    ) {
      state.fetchFacebookMentionsSuggestions = status
    },

    [loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER] (
      state,
      status
    ) {
      state.fetchTwitterMentionsSuggestions = status
    },
    [loadersTypes.SET_FETCH_LINKEDIN_MENTIONS_SUGGESTIONS_LOADER] (
      state,
      status
    ) {
      state.fetchLinkedinMentionsSuggestions = status
    },
    [loadersTypes.SET_INITIALIZE_ARTICLE_POST_CREATION_LOADER] (state, value) {
      state.initializeArticlePostCreation = value
    },

    [loadersTypes.SET_FETCH_HASHTAGS_SUGGESTIONS_LOADER] (state, status) {
      state.fetchHashTagsSuggestions = status
    },

    setBlogEditorSelector (state, editor) {
      state.editorSelector = editor
    },

    setFetchReplugLinkLoader (state, status) {
      state.fetchReplugLink = status
    },

    setFetchLongLinkLoader (state, status) {
      state.fetchLongLink = status
    },

    setFetchHashTagsVelocityLoader (state, status) {
      state.fetchHashTagsVelocity = status
    },

    setProcessContentRewritingLoader (state, status) {
      state.processContentRewriting = status
    },

    // social image
    SET_UPLOAD_COMMON_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_common_sharing_video = loader
    },

    SET_UPLOAD_FACEBOOK_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_facebook_sharing_video = loader
    },

    SET_UPLOAD_TWITTER_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_twitter_sharing_video = loader
    },

    SET_UPLOAD_LINKEDIN_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_linkedin_sharing_video = loader
    },

    SET_UPLOAD_PINTEREST_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_pinterest_sharing_video = loader
    },

    SET_UPLOAD_TUMBLR_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_tumblr_sharing_video = loader
    },

    SET_UPLOAD_INSTAGRAM_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_instagram_sharing_video = loader
    },
    SET_UPLOAD_YOUTUBE_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_youtube_sharing_video = loader
    },
    SET_UPLOAD_TIKTOK_SHARING_VIDEO_LOADER (state, loader) {
      state.upload_tiktok_sharing_video = loader
    },

    setUploadFacebookSharingImageLoader (state, loader) {
      state.uploadFacebookSharingImage = loader
    },

    setUploadTwitterSharingImageLoader (state, loader) {
      state.uploadTwitterSharingImage = loader
    },

    setUploadPinterestSharingImageLoader (state, status) {
      state.uploadPinterestSharingImage = status
    },

    setUploadTumblrSharingImageLoader (state, status) {
      state.uploadTumblrSharingImage = status
    },

    // social image
    setUploadCommonSharingImageLoader (state, loader) {
      state.uploadCommonSharingImage = loader
    },

    // social previews
    setFetchCommonSharingPreviewLoader (state, status) {
      state.fetchCommonSharingPreview = status
    },

    setFetchFacebookSharingPreviewLoader (state, status) {
      state.fetchFacebookSharingPreview = status
    },

    setFetchTwitterSharingPreviewLoader (state, status) {
      state.fetchTwitterSharingPreview = status
    },

    setFetchLinkedinSharingPreviewLoader (state, status) {
      state.fetchLinkedinSharingPreview = status
    },

    setFetchPinterestSharingPreviewLoader (state, status) {
      state.fetchPinterestSharingPreview = status
    },

    setFetchTumblrSharingPreviewLoader (state, status) {
      state.fetchTumblrSharingPreview = status
    },

    setFetchInstagramSharingPreviewLoader (state, status) {
      state.fetchInstagramSharingPreview = status
    },

    setFetchYoutubeSharingPreviewLoader (state, status) {
      state.fetchYoutubeSharingPreview = status
    },

    // social link shortning
    setFetchCommonShortLinksLoaders (state, status) {
      state.fetchCommonShortLinks = status
    },

    setFetchFacebookShortLinksLoaders (state, status) {
      state.fetchFacebookShortLinks = status
    },

    setFetchTwitterShortLinksLoaders (state, status) {
      state.fetchTwitterShortLinks = status
    },

    setFetchLinkedinShortLinksLoaders (state, status) {
      state.fetchLinkedinShortLinks = status
    },

    setFetchPinterestShortLinksLoaders (state, status) {
      state.fetchPinterestShortLinks = status
    },

    setFetchTumblrShortLinksLoaders (state, status) {
      state.fetchTumblrShortLinks = status
    },

    setFetchInstagramShortLinksLoaders (state, status) {
      state.fetchInstagramShortLinks = status
    },

    setFetchYoutubeShortLinksLoaders (state, status) {
      state.fetchYoutubeShortLinks = status
    },

    setInitializeArticlePostCreationLoader (state, status) {
      state.initializeArticlePostCreation = status
    },

    setProcessBlogPostLoader (state, status) {
      state.processBlogPost = status
    },

    setProcessSocialShareLoader (state, status) {
      state.processSocialShare = status
    },
    [publishLoaders.SET_PROCESS_SOCIAL_SHARE_LOADER] (state, value) {
      state.processSocialShare = value
    },

    setProcessRetweetShareLoader (state, status) {
      state.processRetweetShare = status
    },

    setProcessSavePublicationLoader (state, status) {
      state.processSavePublication = status
    },

    setShrinkCommonSharingSourceLinkLoader (state, status) {
      state.shrinkCommonSharingSourceLink = status
    },

    setShrinkPinterestSharingSourceLinkLoader (state, status) {
      state.shrinkPinterestSharingSourceLink = status
    },

    setFetchWidgetUploadedFilesLoader (state, status) {
      state.fetchWidgetUploadedFiles = status
    }
  },
  getters: {
    getBlogEditorSelector: (state) => {
      return state.editorSelector
    },
    getPublishLoaders: (state) => {
      return state
    }
  }
}
