// import {analyticsFeatureLockCheck} from "@src/router";
import DownloadReports from '@src/modules/analytics/components/reports/DownloadReports'
import ReportsSettings from '@src/modules/setting/components/ReportSettings'

const AnalyticsOverview = () =>
  import(
    /* webpackChunkName: "AnalyticsOverview" */ '@src/modules/analytics/views/overview/AnalyticsOverview'
  )

const AnalyticsMain = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/AnalyticsMain.vue'
  )
// const AnalyticsSelection = () => import('@src/modules/analytics/components/AnalyticsSelection.vue')
/* const FacebookCompetitor = () =>
    import(
        /!* webpackChunkName: "analyze" *!/ '@src/modules/analytics/components/facebook/FacebookCompetitor.vue'
        )
const Facebook = () =>
    import(
        /!* webpackChunkName: "analyze" *!/ '@src/modules/analytics/components/facebook/Facebook.vue'
        ) */
const FacebookOverview = () =>
  import(
    /* webpackChunkName: "FacebookAnalyticsOverview" */ '@src/modules/analytics/views/facebook/FacebookMain'
  )
const InstagramOverview = () =>
  import(
    /* webpackChunkName: "InstagramAnalyticsOverview" */ '@src/modules/analytics/views/instagram/InstagramMain'
  )
const TwitterOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '@src/modules/analytics/views/twitter/TwitterMain'
  )
// const Twitter = () =>
//     import(
//         /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/twitter/Twitter.vue'
//         )
// const InstagramHeader = () =>
//     import('@src/modules/analytics/components/instagram/InstagramHeader.vue')
const InstagramPost = () =>
  import('@src/modules/analytics/components/instagram/InstagramPosts.vue')
const InstagramDemographics = () =>
  import(
    '@src/modules/analytics/components/instagram/InstagramDemographics.vue'
  )
const InstagramProfile = () =>
  import('@src/modules/analytics/components/instagram/InstagramProfile.vue')
const PDFReports = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/PDFReports.vue'
  )

const PinterestOverview = () =>
  import(
    /* webpackChunkName: "PinterestAnalyticsOverview" */ '@src/modules/analytics/views/pinterest/PinterestMain'
  )
const LinkedinOverview = () =>
  import(
    /* webpackChunkName: "LinkedinAnalyticsOverview" */ '@src/modules/analytics/views/linkedin/LinkedInMain'
  )
const MyReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/reports/MyReport.vue'
  )
const TwitterCompetitor = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/twitter/TwitterCompetitor.vue'
  )
const FacebookCompetitorReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/facebook/FacebookCompetitorReport'
  )
const facebookCompetitorOverview = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/facebook/facebookCompetitorOverview'
  )

const InstagramCompetitorReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/instagram/instagramCompetitorReport'
  )
const InstagramCompetitorOverview = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/instagram/instagramCompetitorOverview'
  )

export const analyticsRoutes = {
  path: '/:workspace/analytics',
  component: AnalyticsMain,
  name: 'analytics',
  redirect: '/:workspace/analytics/main',
  meta: {
    title: 'Analytics',
  },
  children: [
    /* {
                  path: 'main/',
                  component: AnalyticsSelection,
                  name: 'analytics_main',
                  meta: {
                    'title': 'Analytics Selection'
                  }
                }, */
    {
      path: 'facebook/:accountId?',
      component: FacebookOverview,
      name: 'facebook_analytics',
      meta: {
        title: 'Facebook | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/',
      component: FacebookCompetitorReport,
      name: 'facebookCompetitor',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/:accountId',
      component: facebookCompetitorOverview,
      name: 'facebookCompetitorOverview',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: '/analytics/twitterCompetitor',
      component: TwitterCompetitor,
      name: 'twitterCompetitor',
      meta: {
        title: 'Twitter Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'twitter/:accountId?',
      component: TwitterOverview,
      name: 'twitter_analytics',
      meta: {
        title: 'Twitter | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports/',
      component: MyReport,
      name: 'my_report',
      meta: {
        title: 'My Report | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'download_reports/',
      component: DownloadReports,
      name: 'download_reports',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports_setting/',
      component: ReportsSettings,
      name: 'reports_setting',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'overview/',
      component: AnalyticsOverview,
      name: 'group_analytics',
      meta: {
        title: 'Overview | Analytics',
      },
    },
    {
      path: 'reports/:reportId?',
      component: PDFReports,
      name: 'analytics_pdf_report',
      meta: {
        title: 'Print Report | Analytics',
        guest: true,
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'pinterest/:accountId?',
      component: PinterestOverview,
      name: 'pinterest_analytics',
      meta: {
        title: 'Pinterest | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'linkedin/:accountId?',
      component: LinkedinOverview,
      name: 'linkedin_analytics',
      meta: {
        title: 'Linkedin | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?',
      component: InstagramOverview,
      name: 'instagram_analytics',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section',
      component: InstagramOverview,
      name: 'instagram_overview',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section=',
      component: InstagramPost,
      name: 'instagram_posts',
      meta: {
        title: 'Instagram Posts | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/InstagramProfile',
      component: InstagramProfile,
      name: 'instagram_profile',
      meta: {
        title: 'Instagram Profile | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section=',
      component: InstagramDemographics,
      name: 'instagram_demographics',
      meta: {
        title: 'Instagram Demographics | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram-competitor/',
      component: InstagramCompetitorReport,
      name: 'instagramCompetitor',
      meta: {
        title: 'Instagram Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram-competitor/:accountId',
      component: InstagramCompetitorOverview,
      name: 'instagramCompetitorOverview',
      meta: {
        title: 'Instagram Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
  ],
}
