<template>
  <div class="modal fade normal_modal addTeamMember addGroup" id="addGroup">
    <!--<div class="modal-dialog">-->
    <!--<div class="modal-content">-->

    <!--<div class="modal_head d-flex align-items-center">-->
    <!--<h2 v-if="getCurrentGroup.name">Edit Group</h2>-->
    <!--<h2 v-else>Create Group</h2>-->
    <!--<button type="button" class="close" data-dismiss="modal">&times;</button>-->
    <!--</div>-->
    <!--<div class="modal_body basic_form m_t_15">-->
    <!--<div class="row">-->
    <!--<div class="col-md-12 col-sm-12 input_field">-->
    <!--<label for="">Name</label>-->
    <!--<input type="text" placeholder="Enter first name here..." v-model="getCurrentGroup.name">-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="clearfix"></div>-->
    <!--<div class=" clear permission_block" >-->
    <!--<h2 class="heading">Select accounts for this group</h2>-->
    <!--</div>-->
    <!--<div class="permissions_option">-->

    <!--<div class="permissions_option_inner d-flex align-items-start">-->
    <!--<div class="tab_links nav" role="tablist" >-->

    <!--<a  class="tab_link active show" id="wordpress_tab"  href="#wordpress_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block wordpress_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">WordPress</span>-->
    <!--</span>-->
    <!--&lt;!&ndash;<span class="count">8/12</span>&ndash;&gt;-->
    <!--</a>-->
    <!--<a class="tab_link"  id="tumblr_tab"  href="#tubmlr_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block tumblr_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Tumblr</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--<a class="tab_link"  id="medium_tab"  href="#medium_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block medium_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Medium</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--<a class="tab_link"  id="facebook_tab"  href="#facebook_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block facebook_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Facebook</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--<a  class="tab_link" id="twitter_tab"  href="#twitter_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block twitter_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Twitter</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--<a  class="tab_link" id="linkedin_tab"  href="#linkedin_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block linkedin_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Linkedin</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--<a  class="tab_link" id="pinterest_tab"  href="#pinterest_container" role="tab" data-toggle="tab" aria-controls="topic_container">-->
    <!--<span class="icon_block pinterest_social_icon">-->
    <!--<span class="icon"></span>-->
    <!--<span class="icon_text">Pinterest</span>-->
    <!--</span>-->
    <!--<span class="count">8/12</span>-->
    <!--</a>-->
    <!--</div>-->
    <!--<div class="tab-content align-self-lg-stretch">-->
    <!--<div class="tab-pane fade" id="wordpress_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div v-if="getWordpressBlogs.items && (getWordpressBlogs.items).length>0" class="section">-->
    <!--<div class="heading">-->
    <!--<h3>Blogs</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........none option.........&ndash;&gt;-->
    <!--<div class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="text_block ml-0">-->
    <!--<p class="text">None</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input id="h221212121" type="radio" name="testr1" value="" v-model="getCurrentGroup.wordpress">-->
    <!--<label  for="h221212121" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--&lt;!&ndash;.........radio.........&ndash;&gt;-->
    <!--<div v-for="account in getWordpressBlogs.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.url}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input :id="account.url" type="radio" name="testr1" :value="account._id" v-model="getCurrentGroup.wordpress">-->
    <!--<label  :for="account.url" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade active show" id="tubmlr_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getTumblrBlogs.items,'Blog')">-->
    <!--<div class="heading">-->
    <!--<h3>Blogs</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........none option.........&ndash;&gt;-->
    <!--<div class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="text_block ml-0">-->
    <!--<p class="text">None</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input id="c221212121" type="radio" name="testr1" value="" v-model="getCurrentGroup.tumblr.blog">-->
    <!--<label  for="c221212121" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--&lt;!&ndash;.........radio.........&ndash;&gt;-->
    <!--<div v-for="blog in getTumblrBlogs.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="blog.image" :src="blog.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{blog.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input :id="blog.url" type="radio" name="testr1" :value="blog._id" v-model="getCurrentGroup.tumblr.blog">-->
    <!--<label  :for="blog.url" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="section">-->
    <!--<div class="heading" v-if="checkAccountTypeExistence(getTumblrBlogs.items,'Profile')">-->
    <!--<h3>Profile</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="profile in getTumblrAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="profile.image" :src="profile.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{profile.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="profile._id" type="checkbox" name="option2" :value="profile._id" v-model="getCurrentGroup.tumblr.profile">-->
    <!--<label :for="profile._id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade" id="medium_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section">-->
    <!--<div class="heading">-->
    <!--<h3>Blogs</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........none option.........&ndash;&gt;-->
    <!--<div class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="text_block ml-0">-->
    <!--<p class="text">None</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input id="d221212121" type="radio" name="testr1" value="" v-model="getCurrentGroup.medium">-->
    <!--<label  for="d221212121" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--&lt;!&ndash;.........radio.........&ndash;&gt;-->
    <!--<div v-for="blog in getMediumBlogs.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="blog.imageUrl" :src="blog.imageUrl" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{blog.url}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input :id="blog.url" type="radio" name="testr1" :value="blog.medium_id" v-model="getCurrentGroup.medium">-->
    <!--<label  :for="blog.url" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade" id="facebook_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getFacebookAccounts.items,'Page')">-->
    <!--<div class="heading">-->
    <!--<h3>Pages</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getFacebookAccounts.items">-->
    <!--<div v-if="account.type==='Page'" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account._id" type="checkbox" name="option2" :value="account.facebook_id" v-model="getCurrentGroup.facebook">-->
    <!--<label  :for="account._id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getFacebookAccounts.items,'Group')">-->
    <!--<div class="heading">-->
    <!--<h3>Groups</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getFacebookAccounts.items">-->
    <!--<div v-if="account.type==='Group'" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg" alt="">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account._id" type="checkbox" name="option2" :value="account.facebook_id" v-model="getCurrentGroup.facebook">-->
    <!--<label  :for="account._id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade" id="twitter_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section">-->
    <!--<div class="heading">-->
    <!--<h3>Profile</h3>-->
    <!--</div>-->
    <!--<div class="items">-->

    <!--&lt;!&ndash;.........none option.........&ndash;&gt;-->
    <!--<div class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="text_block ml-0">-->
    <!--<p class="text">None</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input id="e221212121" type="radio" name="testr1" value="" v-model="getCurrentGroup.twitter">-->
    <!--<label  for="e221212121" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--&lt;!&ndash;.........radio.........&ndash;&gt;-->
    <!--<div v-for="account in getTwitterAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="radio_input_image">-->
    <!--<input :id="account.twitter_id" type="radio" name="testr1" :value="account.twitter_id" v-model="getCurrentGroup.twitter">-->
    <!--<label  :for="account.twitter_id" class="no_text radio_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade" id="linkedin_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getLinkedinAccounts.items,'Page')">-->
    <!--<div class="heading">-->
    <!--<h3>Pages</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getLinkedinAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div v-if="account.type==='Page'" class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account._id" type="checkbox" name="option2" :value="account.linkedin_id" v-model="getCurrentGroup.linkedin">-->
    <!--<label  :for="account._id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getLinkedinAccounts.items,'Profile')">-->
    <!--<div class="heading">-->
    <!--<h3>Profile</h3>-->
    <!--</div>-->
    <!--<div class="items">-->

    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getLinkedinAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image" alt="">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account._id" type="checkbox" name="option2" :value="account.linkedin_id" v-model="getCurrentGroup.linkedin">-->
    <!--<label  :for="account._id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="tab-pane fade" id="pinterest_container" role="tabpanel" aria-labelledby="topic_container">-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getPinterestAccounts.items,'Profile')">-->
    <!--<div class="heading">-->
    <!--<h3>Profile</h3>-->
    <!--</div>-->
    <!--<div class="items">-->
    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getPinterestAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div v-if="account.type==='Profile'">-->
    <!--<div  class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.first_name}} {{account.last_name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account.pinterest_id" type="checkbox" name="option2" :value="account.pinterest_id" v-model="getCurrentGroup.pinterest">-->
    <!--<label  :for="account.pinterest_id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class="section" v-if="checkAccountTypeExistence(getPinterestAccounts.items,'Boards')">-->
    <!--<div class="heading">-->
    <!--<h3>Boards</h3>-->
    <!--</div>-->
    <!--<div class="items">-->

    <!--&lt;!&ndash;.........checkbox.........&ndash;&gt;-->
    <!--<div v-for="account in getPinterestAccounts.items" class="option_row d-flex align-items-center">-->
    <!--<div v-if="account.type==='Boards'">-->
    <!--<div class="row_left">-->
    <!--<div class="profile_picture">-->
    <!--<div class="picture_block">-->
    <!--<img v-if="account.image" :src="account.image">-->
    <!--<img v-else src="./../../../app/src/assets/img/profile_default.svg">-->
    <!--</div>-->
    <!--<div class="text_block">-->
    <!--<p class="text">{{account.first_name}} {{account.last_name}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row_right">-->
    <!--<div  class="checkbox_input_image">-->
    <!--<input :id="account.pinterest_id" type="checkbox" name="option2" :value="account.pinterest_id" v-model="getCurrentGroup.pinterest">-->
    <!--<label  :for="account.pinterest_id" class="no_text checkbox_left"></label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->

    <!--</div>-->

    <!--<div class="row col-md-12 col-sm-12 input_field">-->

    <!--<button class="btn blue_gradient large_btn loader_right btn_loader">-->
    <!--<span>Save</span>-->
    <!--<clip-loader :color="'#ffffff'" :size="'16px'"></clip-loader>-->
    <!--</button>-->
    <!--</div>-->
    <!--</div>-->

    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getTeam',
      'getSettingLoaders',
      'getFacebookAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getTwitterAccounts',
      'getCurrentGroup'
    ])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions([''])
  }
}
</script>
