<template>
  <div class="web_sources">
    <div class="sources_head">
      <div class="sources_row d-flex align-items-center">
        <div class="r_left">
          <button @click.prevent="addSourceModal()" class="btn gradient_btn">
            <span>Add a new Source</span>
          </button>
        </div>
        <div class="r_right ml-auto">
          <div class="search_input with_icon_right d-flex align-items-center">
            <div style="min-width: 345px" class="search_inner">
              <input
                @input="inputDebounce(getGlobalSources.search)"
                @keyup.enter="inputDebounce(getGlobalSources.search)"
                v-model="getGlobalSources.search"
                type="text"
                placeholder="Search in global database of ContentStudio..."
              />
              <button class="search_btn input_icon search_icon gradient_color">
                <i class="icon-Search"></i>
              </button>

              <div class="multi_select">
                <div class="multi_input">
                  <div
                    v-on-clickaway="closeDropdownSources"
                    class="multi_dropdown"
                    v-if="
                      !getSourcesLoader.global &&
                      getGlobalSources.search.length >= 3
                    "
                  >
                    <div
                      class="multi_dropdown_inner"
                      v-if="!getSourcesLoader.global"
                    >
                      <ul>
                        <template
                          v-for="(
                            suggested_domain, index
                          ) in getGlobalSources.suggestions"
                        >
                          <li
                            class="item d-flex align-items-center"
                            @click.prevent="
                              addFromGlobalSources({
                                feed: suggested_domain,
                                index: index,
                              })
                            "
                          >
                            <span
                              class="icon world_icon_size"
                              v-if="!suggested_domain._source.is_parent"
                            >
                              <i class="cs-rss"></i>
                            </span>
                            <span
                              class="icon"
                              v-else-if="suggested_domain._source.favicon_16"
                            >
                              <img
                                :src="suggested_domain._source.favicon_16"
                                alt=""
                              />
                            </span>
                            <span class="icon world_icon_size" v-else>
                              <i class="cs-world-o"></i>
                            </span>
                            <span class="text">
                              <!--<span class="heading">{{suggested_domain.name}}</span>-->
                              <span class="url">{{
                                suggested_domain._source.url
                              }}</span>
                            </span>
                            <span class="view">
                              <i class="cs-plus plus_icon"></i>
                            </span>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="multi_dropdown"
                    v-on-clickaway="closeDropdownSources"
                    v-else-if="
                      getSourcesLoader.global &&
                      getGlobalSources.search.length >= 3
                    "
                  >
                    <div
                      class="multi_dropdown_inner min_heighdasdt d-flex align-items-center justify-content-center"
                    >
                      <beat-loader
                        :color="'#436aff'"
                        :size="'16px'"
                      ></beat-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plan_box">
      <div class="box_heading d-flex align-items-center">
        <h2>Sources you are following in custom topics</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th class="rss_name_width" style="width: 600px">Name</th>
                  <th class="text-center">In Topics</th>
                  <th style="text-align: right; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="getAddedSources && getAddedSources.length > 0">
                  <tr v-for="source in getAddedSources">
                    <td>
                      <!--..///for if text with image -->
                      <div class="profile_picture d-flex align-items-center">
                        <div class="picture_block" v-if="!source.is_parent">
                          <i class="cs-rss"></i>
                        </div>
                        <div
                          class="picture_block"
                          v-else-if="source.favicon_16"
                        >
                          <img :src="source.favicon_16" alt="" />
                        </div>
                        <div class="picture_block" v-else>
                          <i class="cs-world-o"></i>
                        </div>

                        <div class="text_block">
                          <p class="text" v-if="source.name">{{
                            source.name
                          }}</p>
                          <p class="text" v-else>Default Site</p>
                          <p class="sub_text">{{ source.url }}</p>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <div
                        class="d-flex align-items-start justify-content-center"
                      >
                        <div
                          class="tag_list"
                          v-if="
                            source.custom_topics &&
                            source.custom_topics.length > 0
                          "
                        >
                          <template
                            v-for="source_topic in source.custom_topics"
                          >
                            <span
                              v-if="getTopicName(source_topic)"
                              class="tag"
                              >{{ getTopicName(source_topic) }}</span
                            >
                          </template>
                        </div>

                        <div
                          class="source_dd_setting prevent_close_dropdown d-inline-block dropdown default_style_dropdown checkbox_dropdown planner_filter_dd"
                        >
                          <div class="dropdown_header" data-toggle="dropdown">
                            <i class="cs-plus plus_icon"></i>
                          </div>

                          <div
                            class="dropdown-menu dropdown-menu-right"
                            style="min-width: 190px;left: 7px; !important"
                          >
                            <ul class="inner" v-if="getTopics.length > 0">
                              <li
                                class="list_item_li"
                                v-for="topic in getTopics"
                              >
                                <div class="checkbox_input_image">
                                  <input
                                    :id="
                                      'topic_' + topic._id + '_' + source._id
                                    "
                                    @change.prevent="
                                      changeSourcesForCustomTopics({
                                        id: source._id,
                                        custom_topics: source.custom_topics,
                                        url: source.url,
                                      })
                                    "
                                    v-model="source.custom_topics"
                                    :value="topic._id"
                                    type="checkbox"
                                  />
                                  <label
                                    :for="
                                      'topic_' + topic._id + '_' + source._id
                                    "
                                    class="checkbox_right"
                                  >
                                    {{ topic.filters.topics.name }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                            <ul v-else>
                              <li class="no_text_li">
                                You do not have any custom topic created yet!
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <a
                        v-if="source.state == 'enabled'"
                        href="javascript:;"
                        @click.prevent="changeSourceStatus(source)"
                        class="disable"
                        >Disable</a
                      >
                      <a
                        v-else-if="source.state == 'disabled'"
                        href="javascript:;"
                        @click.prevent="changeSourceStatus(source)"
                        class="enable"
                        >Enable</a
                      >
                      <a
                        href="javascript:;"
                        class="disable ml-2"
                        @click.prevent="removeSource(source)"
                        >Remove</a
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      colspan="3"
                      style="
                        text-align: center;
                        padding-top: 15px !important;
                        padding-bottom: 15px !important;
                      "
                    >
                      You do not have any custom sources added in this
                      workspace.
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="plan_box">
            <div class="box_heading d-flex align-items-center">
                <h2 >Curated topics you are following</h2>
            </div>

            <div class="box_content">
                <div class="social_table sources_table">
                    <div class="table_wrapper">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th style="text-align: right;width: 200px;">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <div class="profile_picture">
                                        &lt;!&ndash;..///for if text with image &ndash;&gt;
                                        <div class="picture_block">
                                            <img src="./../../../../app/src/assets/img/icons/replug_icon.svg" alt="">
                                        </div>

                                        <div class="text_block">
                                            <p class="text">Cortana Intelligence and Machine Learning Blog</p>
                                            <p class="sub_text">Cortana Intelligence and Machine Learning Blog</p>
                                        </div>

                                    </div>
                                </td>

                                <td class="text-right">
                                    <a href="" class="enable">Enable</a>
                                    &lt;!&ndash;<a href="" class="disable">Disable</a>&ndash;&gt;
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>-->
    <div class="plan_box">
      <div class="box_heading d-flex align-items-center">
        <h2>Blocked sources</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 600px">Name</th>
                  <th class="text-center">Block Type</th>
                  <th style="text-align: right; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-if="getBlockedSources && getBlockedSources.length > 0"
                >
                  <tr v-for="source in getBlockedSources">
                    <td>
                      <!--..///for if text with image -->
                      <div class="profile_picture d-flex align-items-center">
                        <div class="picture_block" v-if="!source.is_parent">
                          <i class="cs-rss"></i>
                        </div>
                        <div
                          class="picture_block"
                          v-else-if="source.favicon_16"
                        >
                          <img :src="source.favicon_16" alt="" />
                        </div>
                        <div class="picture_block" v-else>
                          <i class="cs-world-o"></i>
                        </div>

                        <div class="text_block">
                          <p class="text" v-if="source.name">{{
                            source.name
                          }}</p>
                          <p class="text" v-else>Default Site</p>
                          <p class="sub_text">{{ source.url }}</p>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <template v-if="source.level == 'topic'">
                        Topic - Level
                      </template>
                      <template v-else> Workspace - Level </template>
                    </td>

                    <td class="text-right">
                      <a
                        v-if="source.state == 'enabled'"
                        href="javascript:;"
                        @click.prevent="changeBlockedSourceStatus(source)"
                        class="disable"
                        >Disable</a
                      >
                      <a
                        v-else-if="source.state == 'disabled'"
                        href="javascript:;"
                        @click.prevent="changeBlockedSourceStatus(source)"
                        class="enable"
                        >Enable</a
                      >
                      <a
                        href="javascript:;"
                        class="disable ml-2"
                        @click.prevent="removeBlockedSource(source)"
                        >Remove</a
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      colspan="3"
                      style="
                        text-align: center;
                        padding-top: 15px !important;
                        padding-bottom: 15px !important;
                      "
                    >
                      You have not blocked any sources yet.
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="plan_box" v-if="getOPMLHistory && getOPMLHistory.length > 0">
      <div class="box_heading d-flex align-items-center">
        <h2>OPML Import History</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 600px">Created at</th>
                  <th
                    class="text-center"
                    style="text-align: right; width: 150px"
                    >Status</th
                  >
                </tr>
              </thead>
              <tbody>
                <template v-if="getOPMLHistory && getOPMLHistory.length > 0">
                  <tr v-for="history in getOPMLHistory">
                    <td>
                      <p>
                        {{ history.created_at }}
                      </p>
                    </td>

                    <td class="text-center">
                      <p
                        class="text-center loader_right"
                        style="text-transform: capitalize !important"
                      >
                        {{ history.state }}
                        <template v-if="history.state == 'processing'">
                          <clip-loader
                            color="#4165ed"
                            :size="'16px'"
                          ></clip-loader>
                        </template>
                      </p>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      colspan="2"
                      style="
                        text-align: center;
                        padding-top: 15px !important;
                        padding-bottom: 15px !important;
                      "
                    >
                      You have not imported any OPML file yet.
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import { getFeedsAutoCompleteURL } from '../../../discovery/config/api-utils'

export default {
  components: {},

  data () {
    return {
      loadDomains: false,
      search: '',
      suggestions: []
    }
  },

  created () {
    this.initializeWebSource()
  },
  mounted () {},
  computed: {
    ...mapGetters([
      'getGlobalSources',
      'getAddedSources',
      'getBlockedSources',
      'getSources',
      'getOPMLHistory',
      'getSourcesLoader'
    ])
  },
  methods: {
    ...mapMutations([
      'RESET_SOURCES_STATE',
      'SET_GLOBAL_SOURCES_LOADER',
      'SET_GLOBAL_SOURCES_SUGGESTIONS'
    ]),
    ...mapActions([
      'changeBlockedSourceStatus',
      'changeSourceStatus',
      'addFromGlobalSources',
      'changeSourcesForCustomTopics',
      'removeSource',
      'removeBlockedSource'
    ]),
    addSourceModal () {
      console.log(this.getSources.topicsSelected)
      this.RESET_SOURCES_STATE()
      $('#add_source_modal').modal('show')
    },
    closeDropdownSources () {
      console.log('HIDE it...')
      this.SET_GLOBAL_SOURCES_LOADER(false)
      this.SET_GLOBAL_SOURCES_SUGGESTIONS([])
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Add Sources:')
    $('#add_source_modal').modal('hide')
    next()
  }
}
</script>
