<script>
import { mapGetters } from 'vuex'
import PlatformItem from './PlatformItem'
import { itemSelectedPlatformsCount } from '@common/lib/planner'
import { blogIntegrationsNames } from '../../../integration/config/api-utils'
const importedMethods = { itemSelectedPlatformsCount }

export default {
  components: { PlatformItem},
  props: ['item'],
  data () {
    return {
      integrations: blogIntegrationsNames
    }
  },
  computed: {
    ...mapGetters(['getSocialIntegrationsNames']),
    hasBlogSelection () {
      return this.item.blog_selection
    },
    hasSocialSelection () {
      return this.item.account_selection
    },
    morePlatformSectionStatus () {
      return itemSelectedPlatformsCount(this.item) > 3
    },
    morePlatformCount () {
      return itemSelectedPlatformsCount(this.item) - 3
    },
    isCalenderView () {
      return this.$route.name === 'calender_plans'
    }
  },
  methods: {
    ...importedMethods,
    hasSpecificBlogSelection (type) {
      return (
        this.item.blog_selection[type] && this.item.blog_selection[type].website
      )
    },
    hasSpecificSocialSelection (type) {
      return (
        this.item.account_selection[type] &&
        this.item.account_selection[type].length
      )
    }
  }
}
</script>
<template>
  <div class="users">
    <template v-if="hasBlogSelection">
      <template v-for="integration in integrations">
        <template v-if="hasSpecificBlogSelection(integration)">
          <platform-item :item="item" :type="integration"></platform-item>
        </template>
      </template>
    </template>

    <template v-if="hasSocialSelection">
      <template v-for="type in getSocialIntegrationsNames">
        <template v-if="hasSpecificSocialSelection(type)">
          <template v-for="platform in item.account_selection[type]">
            <platform-item
              :item="item"
              :type="type"
              :platform="getFreshAccount(type, platform)"
            ></platform-item>
          </template>
        </template>
      </template>
    </template>

    <template v-if="isCalenderView">
      <div
        v-if="morePlatformSectionStatus"
        class="profile_picture d-flex align-items-start"
      >
        <div class="picture_block picture_block_background">
          <div class="img text count-number">+{{ morePlatformCount }}</div>
        </div>
      </div>
    </template>

    <template v-if="!isCalenderView">
      <div
        v-if="morePlatformSectionStatus"
        class="d-inline-block align-middle ml-3 circle_boxes_inline align-middle"
      >
        <div
          class="circle_box d-flex align-items-center justify-content-center"
        >
          <span class="text">+{{ morePlatformCount }}</span>
<!--          <v-popover-->
<!--              trigger="hover"-->
<!--              placement="bottom-center"-->
<!--              class="costum_pop_over"-->
<!--              popover-class="tooltip-full more_acounts_pop_over"-->
<!--          >-->
<!--             <span class="text">+{{ morePlatformCount }}</span>-->
<!--          <template slot="popover">-->
<!--           <template v-if="hasSocialSelection">-->
<!--             <template v-for="type in getSocialIntegrationsNames">-->
<!--                <template v-if="hasSpecificSocialSelection(type)">-->
<!--                 <template v-for="platform in item.account_selection[type]">-->
<!--                <platform-item-pop-over-->
<!--                 :item="item"-->
<!--                 :type="type"-->
<!--                 :platform="getFreshAccount(type, platform)"-->
<!--                ></platform-item-pop-over>-->
<!--           </template>-->
<!--          </template>-->
<!--        </template>-->
<!--       </template>-->
<!--      </template>-->
<!--        </v-popover>-->

        </div>
      </div>
    </template>
    <!--<div  class="profile_picture d-flex align-items-start">-->
    <!--<div class="picture_block picture_block_background ">-->
    <!--<div  class="img text count-number">+34</div>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>
<!--<style>-->

<!--</style>-->
