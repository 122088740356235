<template>
  <div>
    <div class="filter grid grid-cols-1 gap-1 w-full">
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{language.languageName}}
        </template>
        <template v-slot>
          <CstDropdownItem v-for="(languageItem,index) in languageList" :key="index" @click="handleLanguage(languageItem)" >
            {{languageItem.languageName}}
          </CstDropdownItem>
        </template>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length ===  0" class="p-3" >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template  v-else-if="posts && posts.length > 0">
        <div class="grid grid-cols-2 gap-1 mt-2 w-full">
          <template v-for="(post, index) in posts" >
            <div :key="index"
                 :draggable="true"
                  class="w-100 p-1 rounded-lg bg-cs-white border hover:bg-cs-foggray hover:shadow-sm" @dragstart="dragstartHandler($event, post)">
                <div class="flex items-center">
                  <div
                      class="w-8 h-8 !bg-contain bg-no-repeat bg-center rounded-sm"
                      :style="getTweetUserImage(post.user)"
                  ></div>
                  <div class="ml-2">
                    <!--<p class="text"><span class="bold">Waqar</span> Azeem</p>-->
                    <a
                        target="_blank"
                        class="text-sm"
                        :href="getTweetRedirectLink(post.id_str)"
                    >{{ limitTextLength(post.user.name, 11) }}</a
                    >
                    <p class="text-xs text-gray-800">{{ $filters.relative(post.created_at) }}</p>
                  </div>
                </div>
                 <p
                  class="text-xs pt-1 h-32 min-h-32 max-h-32 overflow-hidden text-ellipsis whitespace-pre-wrap"
                  v-html="twitterText(post.text,post.entities.hashtags,post.entities.urls,post.entities.user_mentions)">
                 </p>
                 <hr class="m-1">
                 <div class="grid grid-cols-2">
                   <div class="flex items-center justify-center" v-tooltip.top-center="'Retweet'">
                     <p>
                       <i class="cs-refresh"></i>
                     </p>
                     <h3 class="text-sm ml-1">{{ intToString(post.retweet_count) }}</h3>
                   </div>
                   <div class="flex items-center justify-center" v-tooltip.top-center="'Favorite'">
                     <p>
                       <i class="cs-star"></i>
                     </p>
                     <h3 class="text-sm ml-1">{{ intToString(post.favorite_count) }}</h3>
                   </div>
                 </div>
            </div>
          </template>
        </div>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template slot="no-results">
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
      </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
export default {
  name: "Twitter",
  components: {
    InfiniteLoading,
    CstDropdown,
    CstDropdownItem,
    NoResult
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    languageList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
    }
  },
  watch:{

  },
  methods:{
    dragstartHandler (ev, tweet) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'assistantData',
          JSON.stringify({
            id: ev.target.id,
            type: 'embed',
            heading: tweet.text || '',
            media: `https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`,
            p: '',
            link: ''
          })
      )
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleLanguage (value) {
      this.$emit('handle-change','language', value)
    },
  }
}
</script>

<style scoped>
</style>