<template>
  <div>
    <template v-if="getLanguageList.length > 0 && (isTopic || automation)">
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region"
        no-caret
      >
        <template slot="button-content">
          <i class="flag_icon studio-theme-icon far cs-flag"></i>
          <span class="capitalize_text">{{
            getTopicsLanguage.languageName
          }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <div
          class="topics-filters-pane__container__right_section__dropdown_search topics-filters-pane__container__right_section__dropdown_search_language"
        >
          <input
            type="text"
            v-model="search_language"
            placeholder="Search by language"
            class="search-bar-input search-bar-input-discovery with-icon-left"
          />
          <i class="icon-Search"></i>
        </div>
        <b-dd-divider />

        <template v-if="getLanguageList.length > 0">
          <b-dropdown-item
            v-for="language in getLanguageList"
            :key="language.code"
            :active="getTopicsLanguage.code === language.code"
            variant="studio-theme"
            @click.prevent="changeLanguage('topics', language)"
          >
            {{ language.languageName }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <p
            class="topics-filters-pane__container__right_section__dropdown__no_matches"
          >
            No matches found
          </p>
        </template>
      </b-dropdown>
    </template>
    <template v-else-if="getLanguageList.length > 0">
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region"
        no-caret
      >
        <template slot="button-content">
          <i class="flag_icon studio-theme-icon far cs-flag"></i>
          <span class="capitalize_text">{{
            getArticlesLanguage.languageName
          }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <div
          class="topics-filters-pane__container__right_section__dropdown_search topics-filters-pane__container__right_section__dropdown_search_language"
        >
          <input
            type="text"
            v-model="search_language"
            placeholder="Search by language"
            class="search-bar-input search-bar-input-discovery with-icon-left"
          />
          <i class="icon-Search"></i>
        </div>
        <b-dd-divider />

        <template v-if="getLanguageList.length > 0">
          <b-dropdown-item
            v-for="language in getLanguageList"
            :key="language.code"
            :active="getArticlesLanguage.code === language.code"
            variant="studio-theme"
            @click.prevent="changeLanguage('articles', language)"
          >
            {{ language.languageName }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <p
            class="topics-filters-pane__container__right_section__dropdown__no_matches"
          >
            No matches found
          </p>
        </template>
      </b-dropdown>
    </template>
  </div>
</template>
<script>
import { filterLimitedLanguages } from '@common/constants/languages'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import orderBy from 'lodash/orderBy'
import { topicTypes } from '../../store/mutation-types'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  data () {
    return {
      search_language: '',
      languages: filterLimitedLanguages
    }
  },
  computed: {
    ...mapGetters([
      'getArticlesRegion',
      'getTopicsRegion',
      'getArticlesLanguage',
      'getRelatedStatus',
      'getTopicsLanguage'
    ]),

    getLanguageList () {
      let languageList = []
      this.languages.forEach(function (element) {
        if (this.isCreateTopic || this.isTopic) {
          if (element.code === this.getTopicsRegion.code) {
            languageList = element.languageList
          }
        } else {
          if (element.code === this.getArticlesRegion.code) {
            languageList = element.languageList
          }
        }
      }, this)
      return orderBy(languageList, ['languageName'], ['asc'])
    }
  },
  methods: {
    ...mapActions([
      'articles',
      'topicsContent',
      'getContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations([topicTypes.RESET_TOPICS_FILTER]),
    async changeLanguage (type, value) {
      switch (type) {
        case 'topics':
          this.SET_TOPICS_LANGUAGE(value)
          this[topicTypes.RESET_TOPICS_FILTER]()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        case 'articles':
          await this.getContent({ filter: { key: 'language', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        default:
          break
      }
    }
  }
}
</script>
