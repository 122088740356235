import { analyticsBaseUrl } from '@src/config/api-utils'

export const getTwitterAnalyticsURL = analyticsBaseUrl + 'analytics/twitter'
export const getTwitterAnalyticsV2URL = analyticsBaseUrl + 'v2/twitter'
export const getFacebookAnalyticsURL = analyticsBaseUrl + 'analytics/facebook'
export const getLinkedinAnalyticsURL = analyticsBaseUrl + 'analytics/linkedin'
export const getPinterestAnalyticsURL = analyticsBaseUrl + 'analytics/pinterest'
export const getInstagramAnalyticsURLv1 =
  analyticsBaseUrl + 'analytics/instagram'
export const getInstagramAnalyticsPostsURLv1 =
  analyticsBaseUrl + 'analytics/instagram/posts'
export const getInstagramAnalyticsDemographicsURLv1 =
  analyticsBaseUrl + 'analytics/instagram/demographics'
export const getInstagramAnalyticsInsightsURL =
  analyticsBaseUrl + 'analytics/instagram/insights'
export const addToRefetchQueueURL = analyticsBaseUrl + 'analytics/sync'
export const getGroupAnalyticsURL = analyticsBaseUrl + 'analytics/group'
export const getReportsURL = analyticsBaseUrl + 'analytics/reports/show'
export const saveReportsURL = analyticsBaseUrl + 'analytics/reports/save'
export const renderReportsURL = analyticsBaseUrl + 'analytics/reports/render'
export const downloadReportsURL = analyticsBaseUrl + 'analytics/reports/list'
export const removeDownloadReportsURL =
  analyticsBaseUrl + 'analytics/reports/remove'
export const scheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/save'
export const getScheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/show'
export const removeScheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/remove'

// analytics v2
export const getGroupAnalyticsOverviewURL =
  analyticsBaseUrl + 'analytics/overview/summary'
export const getGroupAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/topPosts'
export const getGroupAnalyticsPageEngagementURL =
  analyticsBaseUrl + 'analytics/overview/postsEngagement'
export const getGroupAnalyticsEngagementRollupURL =
  analyticsBaseUrl + 'analytics/overview/engagementRollup'
export const getGroupAnalyticsAccountPerformanceURL =
  analyticsBaseUrl + 'analytics/overview/accountPerformance'

export const getFacebookAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/facebook/summary'
export const getFacebookAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/getTopPosts'
export const getFacebookAnalyticsDemographicsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/demographics'

export const getInstagramAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview'
export const getInstagramAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/getTopPosts'
export const getInstagramAnalyticsDemographicsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/demographics'

export const getLinkedinAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/summary'
export const getLinkedinAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/getTopPosts'

export const getPinterestAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/pinterest/summary'
export const getPinterestAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/pinterest/getTopPosts'

export const overviewUrl = analyticsBaseUrl + 'fetchOverallStats'
export const facebookAnalyticsUrl = analyticsBaseUrl + 'getFacebookAnalytics'
export const facebookInsightsUrl = analyticsBaseUrl + 'getFacebookInsights'
export const linkedinAnalyticsUrl = analyticsBaseUrl + 'getLinkedinAnalytics'
export const pinterestAnalyticsUrl = analyticsBaseUrl + 'getPinterestAnalytics'
export const twitterAnalyticsUrl = analyticsBaseUrl + 'getTwitterAnalytics'

// dashboard analytics
export const fetchContentPublishingStatsURL =
  analyticsBaseUrl + 'getContentPublishingStats'
export const fetchInboxStatsURL = analyticsBaseUrl + 'getInboxStats'
export const fetchContentApprovalStatsURL =
  analyticsBaseUrl + 'getContentApprovalStats'
export const fetchDashboardAnalyticsURL =
  analyticsBaseUrl + 'fetchDashboardAnalytics'

// facebook competitor analytics
export const fetchAvgEngagement =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/avg_engagement'
export const fetchEngagement =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/engagement'
export const fetchTotalPosts =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/total_posts'
export const fetchEngagementDistribution =
  analyticsBaseUrl +
  'analytics/overview/facebook/competitor/engagement_distribution'
export const fetchEngagementByPostType =
  analyticsBaseUrl +
  'analytics/overview/facebook/competitor/engagement_by_post_type'
export const fetchTopPost =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/top_posts'
export const fetchPromotedPosts =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/promoted_posts'
export const fetchFansGrowth =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/fans_growth'
export const addNewCompetitor =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/add'
export const updateCompetitor =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/update'
export const searchCompetitor =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/search'
export const fetchFbCompetitors =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/get'
export const delFbCompetitors =
  analyticsBaseUrl + 'analytics/overview/facebook/competitor/del'

// composer

export const getTimeRecommendationUrl =
  analyticsBaseUrl + 'analytics/overview/timeRecommendation'

// instagram competitor analytics
export const fetchIgCompetitors =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/getCompetitor'
export const searchIgAccount =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/getIgAccount'
export const addNewIgCompetitor =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/addCompetitor'
export const updateIgCompetitor =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/updateCompetitor'
export const fetchIgRankings =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/rankings'
export const fetchIgTableMetrics =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/tableMetrics'
export const fetchIgMaxFansChange =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/maxFansChange'
export const fetchIgFanCount =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/fanCount'
export const fetchIgFanChange =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/fanChange'
export const fetchIgPostEngagement =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/postEngagement'
export const fetchIgAvgPostEngagement =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/avgPostEngagement'
export const fetchIgPostTypeEngagement =
  analyticsBaseUrl +
  'analytics/overview/instagram/competitor/postTypeEngagement'
export const fetchIgPostCountByType =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/postCountByType'
export const fetchIgEngagementDistribution =
  analyticsBaseUrl +
  'analytics/overview/instagram/competitor/engagementDistribution'
export const fetchIgTopPosts =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/topPosts'
export const fetchIgHashtags =
  analyticsBaseUrl + 'analytics/overview/instagram/competitor/hashtags'
