<script>
import TypeFilter from './filter/TypeFilter'
import StatusFilter from './filter/StatusFilter'
import SavedFilters from './filter/SavedFilters'
import MemberOptions from '../../publish/components/options/MemberOptions'
import LabelOptions from '../../publish/components/options/LabelOptions'
import PlatformFilters from '../../publish/components/PlatformFilters'
import { mapGetters, mapActions } from 'vuex'
import RemovePlanConfirmation from './dialogs/RemovePlanConfirmation'
import ReplacePlanConfirmation from './dialogs/ReplacePlanConfirmation'
import ReopenPlanConfirmation from './dialogs/ReopenPlanConfirmation.vue'
import ProcessBulkConfirmation from './dialogs/ProcessBulkConfirmation'
import AutomationFilter from './filter/AutomationFilter'
import { planner } from '../store/mutation-types'
import { publish } from '../../publish/store/states/mutation-types'
import PlannerBulkActions from './filter/PlannerBulkActions'
import {plannerDefaultView} from "../../publish/config/api-utils"
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import PublishingTimeFeedView from './view/feed-view/PublishingTimeFeedView'
import SocialApprovalModal from '@common/components/dialogs/approval/SocialApprovalModal'
import sortBy from 'lodash.sortby'
import TweetsResponseModal from '@src/modules/planner/components/view/TweetsResponseModal'
import FirstCommentResponseModalVue from '@src/modules/planner/components/FirstCommentResponseModal'
import axios from 'axios'
import {EventBus} from "@common/lib/event-bus";
export default {
  components: {
    AutomationFilter,
    ProcessBulkConfirmation,
    ReplacePlanConfirmation,
    ReopenPlanConfirmation,
    RemovePlanConfirmation,
    PlatformFilters,
    LabelOptions,
    MemberOptions,
    StatusFilter,
    TypeFilter,
    PlannerBulkActions,
    DateRangePicker,
    PublishingTimeFeedView,
    SocialApprovalModal,
    SavedFilters,
    TweetsResponseModal,
    FirstCommentResponseModalVue
  },
  data () {
    return {
      sidebar_status: false,
      operation: '',
      planner: {
        locale: {
          format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
        },

        opens: 'left',
        startDate: '2017-09-19',
        dateRange: {},
        show_ranges: true,
        single_date_picker: false,
        time_picker: false,
        time_picker_24_hour: true,
        show_dropdowns: true,
        auto_apply: true,
        show_week_numbers: true,
        ranges: {
          Today: [moment().startOf('day'), moment().endOf('day')],
          Yesterday: [
            moment().subtract(1, 'days').startOf('day'),
            moment().subtract(1, 'days').endOf('day')
          ],
          'Last week': [
            moment().subtract(1, 'week').startOf('week'),
            moment().subtract(1, 'week').endOf('week')
          ],
          'This month': [moment().startOf('month'), moment().endOf('month')],
          'Last month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ],
          'This year': [moment().startOf('year'), moment().endOf('year')]
        }
      },
      filter: {
        type: '',
        statuses: [],
        members: [],
        created_by: [],
        labels: [],
        campaigns: [],
        content_category: [],
        social_accounts: [],
        no_accounts: false,
      }
    }
  },
  created () {
    this.$store.commit(planner.SET_ITEMS, [])
    this.$store.commit(planner.SET_FETCH_PLANS_LOADER, true)
    this.initializeSection()
  },
  mounted () {
    const thiss = this
    $('.app_container ').mouseup(function (e) {
      console.debug('e', e)
      var container = $('.prevent_click')
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        thiss.sidebar_status = false
      }
    })
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPlans',
      'getPublicationFolderStatus',
      'getActiveWorkspace',
      'getAllAutomations',
      'getPublishSelection',
      'getContentCategoryList',
      'getPlansCount',
      'getActivePlannerFilters',
      'getProfile'
    ]),
    isSidebarActive () {
      return this.sidebar_status
    },
    showAutomationsSelection () {
      const type = this.$route.query.type
      return (
        type === 'article_automation' ||
        type === 'video_automation' ||
        type === 'rss_automation' ||
        type === 'evergreen_automation'
      )
    },
    isListView () {
      return this.$route.name === 'list_plans'
    },
    isFeedView () {
      return this.$route.name === 'feed_view'
    },
    isCalenderView () {
      return this.$route.name === 'calender_plans'
    },
    filtersAppliedWithCount () {
      // calculate count of applied filters
      let count = this.getFiltersType === '' ? 0 : 1
      if (this.getFiltersStatuses.length !== 7) count = count + 1
      if (
        this.getFiltersStatuses.length !==
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length
      ) {
        count = count + this.getFiltersStatuses.length
      }
      if (
        this.getFiltersCreatedMembers.length !==
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length
      ) {
        count = count + this.getFiltersCreatedMembers.length
      }
      if (this.getFiltersLabels.length !== this.getLabelsIds().length) {
        count = count + this.getFiltersLabels.length
      }
      if (
        this.getFiltersCampaigns.length !== this.getComposerFolderIds().length
      ) {
        count = count + this.getFiltersCampaigns.length
      }
      if (
        this.getFiltersCategories.length !==
        this.getPlannerCategoriesIds().length
      ) {
        count = count + this.getFiltersCategories.length
      }
      return count
    },
    hasComposerCampaigns () {
      // check workspace has composer folder or not
      return (
        this.getPlansCount.composer_folders &&
        this.getPlansCount.composer_folders.length
      )
    },

   defaultView (){
      return this.getProfile.planner_default_view
   },
    /**
     * get type filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersType () {
      if (this.$route.query.type) return this.$route.query.type.toLowerCase()
      if (
        this.$route.query.filter_id &&
        this.getActivePlannerFilters &&
        this.getActivePlannerFilters.type
      ) {
        return this.getActivePlannerFilters.type.toLowerCase()
      }
      return ''
    },

    /**
     * get Planner post status filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersStatuses () {
      if (this.$route.query.statuses) {
        return this.$route.query.statuses.split(',')
      }
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.statuses
      }
      return []
    },

    /**
     * get Planner assigned members filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersMembers () {
      if (this.$route.query.members) {
        return this.$route.query.members.split(',')
      }
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.members
      }
      return []
    },

    /**
     * get Planner created members filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersCreatedMembers () {
      if (this.$route.query.created_by) {
        return this.$route.query.created_by.split(',')
      }
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.created_by
      }
      return []
    },

    /**
     * get Planner labels filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersLabels () {
      if (this.$route.query.labels) return this.$route.query.labels.split(',')
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.labels
      }
      return []
    },

    /**
     * get Planner campaigns filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersCampaigns () {
      if (this.$route.query.campaigns) {
        return this.$route.query.campaigns.split(',')
      }
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.campaigns
      }
      return []
    },

    /**
     * get Planner categories filter value
     * first get from query parameter if filter applied than get from active filter
     */
    getFiltersCategories () {
      if (this.$route.query.content_category) {
        return this.$route.query.content_category.split(',')
      }
      if (this.$route.query.filter_id && this.getActivePlannerFilters) {
        return this.getActivePlannerFilters.content_category
      }
      return []
    }
  },
  methods: {
    ...mapActions(['createPlannerFilter','setDefaultView']),
    closeDatePicker () {
      if (this.$refs.topicsdatepicker) {
        this.$refs.topicsdatepicker.clickAway()
      }
    },
    async handleDefaultView(val){
      if(this.defaultView===val){
        return
      }
      try {
       const response = await axios.post(
       plannerDefaultView,
    {
      default_view: val
    },
    {
      headers: { Authorization: 'Bearer ' + this.getJWTToken },
      // cancelToken: source.token
    }
  )

  const { status, data } = response.data
   if(status){
    this.setDefaultView(val)
   }
  console.log(" response.data", response.data);

  // callback(status, data)
} catch (err) {
  console.error(err)
  this.alertMessage('Something went wrong. Please try again.', 'error')
}
    },
    changeSidebarStatus () {
      this.sidebar_status = !this.sidebar_status
      $('#HW_frame_cont').removeClass('HW_visible')
    },

    clearSidebarFilters (ClearActiveFilter = false) {
      console.debug('Method::clearSidebarFilters')

      const query = Object.assign({}, this.$route.query)
      delete query.type
      delete query.labels
      delete query.campaigns
      delete query.content_category
      delete query.created_by
      delete query.members
      delete query.statuses
      if (this.$route.name !== 'calender_plans') delete query.date
      if (ClearActiveFilter) {
        delete query.filter_id
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)
      }
      this.$router.push({ query: query })
      this.$store.commit(planner.SET_CHECK_ALL_STATUSES, true)
      this.$store.commit(planner.SET_CHECK_ALL_CREATED_MEMBERS, true)
      this.$store.commit(planner.SET_CHECK_ALL_ASSIGNED_MEMBERS, true)
      this.$store.commit(planner.SET_CHECK_ALL_LABELS, true)
      this.$store.commit(planner.SET_CHECK_ALL_CAMPAIGNS, true)
      this.$store.commit(planner.SET_CHECK_ALL_CATEGORIES, true)
    },

    async initializeSection () {
      console.debug('Method::initializeSection')
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
      this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)

      // get planner save filers from db
      const plannerFilters = await this.$store.dispatch('fetchPlannerFilters')

      // set default saved filter if no query paremeter in url
      // this check is added due to email notification redirect
      if (Object.keys(this.$route.query).length === 0 && plannerFilters) {
        this.setDefaultFilter(plannerFilters)
      } else if (this.$route.query.filter_id && plannerFilters) {
        this.setActiveFilterById(this.$route.query.filter_id, plannerFilters)
      }

      await this.$store.dispatch('fetchBlogs')
      await this.$store.dispatch('fetchSocialAccounts')

      // set planner filter all filters because if filter is active we get this from states
      this.setCheckAllOptions()

      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, true)
      await this.$store.dispatch('changePlatformsSelection', {
        filter_type: 'blog',
        selection_type: 'all'
      })

      this.initializePlans()
      if (this.getAllAutomations.fetch_status === false) {
        this.$store.dispatch('fetchAllAutomations')
      }
    },

    setCheckAllOptions () {
      if (!this.$route.query.statuses && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_STATUSES, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_STATUSES,
          this.getFiltersStatuses.length === 0 ||
            this.getFiltersStatuses.length === 7
        )
      }

      if (!this.$route.query.members && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_ASSIGNED_MEMBERS, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_ASSIGNED_MEMBERS,
          this.getFiltersMembers.length === 0 ||
            this.getFiltersMembers.length ===
              this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
                .length
        )
      }

      if (!this.$route.query.created_by && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_CREATED_MEMBERS, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_CREATED_MEMBERS,
          this.getFiltersCreatedMembers.length === 0 ||
            this.getFiltersCreatedMembers.length ===
              this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
                .length
        )
      }

      if (!this.$route.query.labels && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_LABELS, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_LABELS,
          this.getFiltersLabels.length === 0 ||
            this.getFiltersLabels.length === this.getLabels.length
        )
      }

      if (!this.$route.query.campaigns && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_CAMPAIGNS, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_CAMPAIGNS,
          this.getFiltersCampaigns.length === 0 ||
            this.getFiltersCampaigns.length ===
              this.getComposerFolderIds().length
        )
      }

      if (!this.$route.query.content_category && !this.$route.query.filter_id) {
        this.$store.commit(planner.SET_CHECK_ALL_CATEGORIES, true)
      } else {
        this.$store.commit(
          planner.SET_CHECK_ALL_CATEGORIES,
          this.getFiltersCategories.length === 0 ||
            this.getFiltersCategories.length ===
              this.getPlannerCategoriesIds().length
        )
      }
    },

    initializePlans () {
      console.debug(
        'Method::initializeSection PlannerMain initializePlans',
        this.$route
      )
      if (this.$route.name === 'calender_plans') {
        const start = moment().startOf('week').format('MMMM DD, YYYY')
        const end = moment().endOf('week').format('MMMM DD, YYYY')
        this.$router.push({
          query: { ...this.$route.query, date: start + ' - ' + end }
        })
        // this.$store.commit(planner.SET_CALENDAR_DATE_RANGE, `${start} - ${end}`)
      }
      this.refetchPlans()
    },

    refetchPlans () {
      console.debug('Method::refetchPlans')
      this.$store.dispatch('refetchPlans', this.$route)
    },

    updatePlannerDate (values) {
      console.debug('Method:updatePlannerDate', values)
      if (values.startDate && values.endDate) {
        const start = moment(values.startDate)
        const end = moment(values.endDate)
        // this.$store.commit(planner.SET_DATE_RANGE, start.format('MMMM DD, YYYY') + ' - ' + end.format('MMMM DD, YYYY'))
        this.$router.push({
          query: {
            ...this.$route.query,
            date:
              start.format('MMMM DD, YYYY') +
              ' - ' +
              end.format('MMMM DD, YYYY')
          }
        })
        this.$store.dispatch('refetchPlans', this.$route)
      }
    },

    refreshPlans () {
      this.$store.dispatch('refetchPlans', this.$route)
    },

    changeSort (sort) {
      this.$router.push({ query: { ...this.$route.query, order_by: sort } })
      this.refreshPlans()
    },

    getComposerFolderIds () {
      const ids = []
      this.getPlansCount.composer_folders.forEach((folder, index) => {
        ids.push(folder._id)
      })
      return ids
    },

    isCampaignSelected (id) {
      return (
        this.getPlans.check_all_campaigns ||
        this.getFiltersCampaigns.indexOf(id) >= 0
      )
    },

    changeCampaignSelection (id) {
      let campaigns = []
      if (this.$route.query.filter_id && !this.$route.query.campaigns) {
        campaigns = this.getFiltersCampaigns.length
          ? this.getFiltersCampaigns
          : this.getPlans.check_all_campaigns
            ? this.getComposerFolderIds()
            : []
        this.getActivePlannerFilters.campaigns = []
      } else {
        campaigns = this.$route.query.campaigns
          ? this.$route.query.campaigns.split(',')
          : this.getPlans.check_all_campaigns
            ? this.getComposerFolderIds()
            : []
      }
      campaigns.indexOf(id) >= 0
        ? campaigns.splice(campaigns.indexOf(id), 1)
        : campaigns.push(id)

      this.$store.commit(
        planner.SET_CHECK_ALL_CAMPAIGNS,
        campaigns.length === this.getComposerFolderIds().length
      )
      if (campaigns.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, campaigns: campaigns.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.campaigns
      this.$router.push({ query: query })
    },

    selectAllCampaigns () {
      console.debug('Method:selectAllCampaigns')
      this.getActivePlannerFilters.campaigns = []
      let ids = []
      if (this.getPlans.check_all_campaigns) ids = this.getComposerFolderIds()
      if (ids.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, campaigns: ids.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.campaigns
      this.$router.push({ query: query })
      this.$store.dispatch('refetchPlans', this.$route)
    },

    campaignOnlySelection (id) {
      this.getActivePlannerFilters.campaigns = []
      if (this.getComposerFolderIds().length > 1) {
        this.$store.commit(planner.SET_CHECK_ALL_CAMPAIGNS, false)
      }
      this.$router.push({ query: { ...this.$route.query, campaigns: id } })
    },

    isCategorySelected (id) {
      return (
        this.getPlans.check_all_categories ||
        this.getFiltersCategories.indexOf(id) >= 0
      )
    },

    getPlannerCategoriesIds () {
      const ids = []
      this.getContentCategoryList.forEach((category, index) => {
        ids.push(category._id)
      })
      return ids
    },

    changeContentCategorySelection (id) {
      let categories = []
      if (this.$route.query.filter_id && !this.$route.query.content_category) {
        categories = this.getFiltersCategories.length
          ? this.getFiltersCategories
          : this.getPlans.check_all_categories
            ? this.getPlannerCategoriesIds()
            : []
        this.getActivePlannerFilters.content_category = []
      } else {
        categories = this.$route.query.content_category
          ? this.$route.query.content_category.split(',')
          : this.getPlans.check_all_categories
            ? this.getPlannerCategoriesIds()
            : []
      }
      categories.indexOf(id) >= 0
        ? categories.splice(categories.indexOf(id), 1)
        : categories.push(id)
      this.$store.commit(
        planner.SET_CHECK_ALL_CATEGORIES,
        categories.length === this.getPlannerCategoriesIds().length
      )
      if (categories.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, content_category: categories.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.content_category
      this.$router.push({ query: query })
    },

    selectAllCategories () {
      console.debug('Method:selectAllCategories')
      this.getActivePlannerFilters.content_category = []
      let ids = []
      if (this.getPlans.check_all_categories) {
        ids = this.getPlannerCategoriesIds()
      }
      if (ids.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, content_category: ids.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.content_category
      this.$router.push({ query: query })
      this.$store.dispatch('refetchPlans', this.$route)
    },

    contentCategoryOnlySelection (id) {
      this.getActivePlannerFilters.content_category = []
      if (this.getPlannerCategoriesIds().length > 1) {
        this.$store.commit(planner.SET_CHECK_ALL_CATEGORIES, false)
      }
      this.$router.push({
        query: { ...this.$route.query, content_category: id }
      })
    },

    refetchPlansCall () {
      const name = this.$route.name
      if (
        name === 'calender_plans' ||
        name === 'list_plans' ||
        name === 'feed_view'
      ) {
        this.$store.dispatch('refetchPlans', this.$route)
      }
    },

    removeSpecificPlans () {
      const query = Object.assign({}, this.$route.query)
      delete query.bulk_id
      delete query.reference_id
      delete query.plan_ids
      this.$router.push({ query: query })
      this.refetchPlans()
    },

    sortContentCategory (data) {
      return sortBy(data, function (item) {
        return [item.name.toLowerCase()]
      })
    },

    setActiveFilterById (id, plannerFilters) {
      console.debug('Method:setActiveFilterById')
      const activeFilter = plannerFilters.find((item) => item._id === id)
      if (activeFilter) {
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, activeFilter)
      }
    },

    setDefaultFilter (plannerFilters) {
      const activeFilter = plannerFilters.find((item) => item.default === true)
      if (activeFilter) {
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, activeFilter)
        this.$router.push({ query: { filter_id: activeFilter._id } })
      }
    }
  },
  beforeDestroy () {
    $('.app_container ').unbind('mouseup')
  },
  watch: {
    'getActiveWorkspace._id' (val) {
      console.debug('Watch::PlannerMain-getActiveWorkspace._id', val.length)
      if (val.length > 0) this.initializeSection()
    },
    '$route.name' () {
      this.initializePlans()
    },
    '$route.query.campaigns' () {
      this.refetchPlansCall()
    },
    '$route.query.content_category' () {
      this.refetchPlansCall()
    },
    '$route.query.type' () {
      this.refetchPlansCall()
    },
    '$route.query.labels' () {
      this.refetchPlansCall()
    },
    '$route.query.created_by' () {
      this.refetchPlansCall()
    },
    '$route.query.members' () {
      this.refetchPlansCall()
    },
    '$route.query.automations' () {
      this.refetchPlansCall()
    },
    '$route.query.statuses' () {
      this.refetchPlansCall()
    },
    '$route.query.plan_ids' () {
      this.refetchPlansCall()
    },
    '$route.query.date' () {
      this.refetchPlansCall()
    }
  }
}
</script>
<style lang="less" scoped>

@c-lightOrange: #ffefcc;
.specific_plan_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  span.specific_selected_plan {
    padding: 10px 15px;
    background: @c-lightOrange;
    border-radius: 4px;

    i {
      padding-left: 0.625rem;
      cursor: pointer;
    }
    i:hover {
      color: #242424;
    }
  }
}

.new-tag-introduced {
  background: #ff9300;
  position: absolute;
  transform: rotate(37deg);
  line-height: 1rem;
  display: inline-block;
  padding: 0.5px 3px;
  font-size: 11px;
  border-radius: 3px;
  color: #ffffff !important;
  font-weight: 700;
  margin-left: -20px;
}
</style>
<template>
  <div
    class="max_container_1800 planner_component"
    :class="{ list_view: isListView, calender_view: isCalenderView }"
  >
    <tweets-response-modal></tweets-response-modal>
    <FirstCommentResponseModalVue></FirstCommentResponseModalVue>
    <remove-plan-confirmation></remove-plan-confirmation>
    <replace-plan-confirmation></replace-plan-confirmation>
    <reopen-plan-confirmation></reopen-plan-confirmation>
    <process-bulk-confirmation
      :operation="operation"
    ></process-bulk-confirmation>
    <publishing-time-feed-view
      type="missed_reviewed"
    ></publishing-time-feed-view>
    <!--    // TODO: remove old Social modal-->
    <social-approval-modal></social-approval-modal>

    <div
      :class="{ open: isSidebarActive }"
      class="prevent_click filter_sidebar"
    >
      <div class="filter_sidebar_inner">
        <div class="main_head d-flex align-items-center">
          <div class="head_left">
            <p>Filter posts</p>
          </div>
          <div class="head_right ml-auto">
            <span class="mr-1 clear-btn" @click="clearSidebarFilters(true)"
              >Clear</span
            >
            <i @click="changeSidebarStatus()" class="cs-cross"></i>
          </div>
        </div>

        <!-- Search Filter Section Start here -->

        <saved-filters></saved-filters>

        <!-- Search Filter Section End here -->

        <div class="filter_box filter_type">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="cs-setting-bar"></i>
                <span>Type</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <type-filter></type-filter>
          </div>
        </div>

        <div class="filter_box filter_status">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="fa fa-toggle-on"></i>
                <span>Status</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <status-filter></status-filter>
          </div>
        </div>

        <div class="filter_box filter_members">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="far fa-user"></i>
                <span>Assigned To</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <member-options location="planner"></member-options>
          </div>
        </div>

        <div class="filter_box filter_members">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="far fa-user"></i>
                <span>Created By</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <member-options location="created_by_filter"></member-options>
          </div>
        </div>

        <div class="filter_box filter_label">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="far fa-bookmark"></i>
                <span>Labels</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <label-options location="planner" type="Filter"></label-options>
          </div>
        </div>

        <div class="filter_box filter_label">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="cs-bookmark"></i>
                <span>Composer Campaigns</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <ul class="inner" v-if="!hasComposerCampaigns">
              <p class="no_label">You have not created any campaigns yet.</p>
            </ul>
            <ul class="inner" v-if="hasComposerCampaigns">
              <li class="list_item_li select_all_li">
                <div class="ui_check_container">
                  <input
                    id="plans_campaigns"
                    v-model="getPlans.check_all_campaigns"
                    type="checkbox"
                    @change="selectAllCampaigns()"
                  />
                  <span class="check"></span>
                  <label for="plans_campaigns" class="checkbox_right">
                    <span class>All</span>
                  </label>
                </div>
              </li>
              <li
                class="ui_check_container showlink"
                v-for="folder in getPlansCount.composer_folders"
              >
                <input
                  type="checkbox"
                  :checked="isCampaignSelected(folder._id)"
                  :id="folder._id"
                  :value="folder._id"
                  @change="changeCampaignSelection(folder._id)"
                />
                <span class="check"></span>
                <label :for="folder._id" class="checkbox_right">
                  <p>
                    {{ folder.name }}
                    <template v-if="folder.counts"
                      >({{ folder.counts }})</template
                    >
                    <a
                      @click.prevent="campaignOnlySelection(folder._id)"
                      class="onlyLink"
                      >Only</a
                    >
                  </p>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="filter_box filter_label">
          <div class="head">
            <div class="box_left">
              <p>
                <i class="cs-user-network"></i>
                <span>Content Categories</span>
              </p>
            </div>
          </div>
          <div class="filter_content">
            <ul class="inner" v-if="getContentCategoryList.length === 0">
              <p class="no_label"
                >You have not created any content categories yet.</p
              >
            </ul>
            <ul class="inner" v-if="getContentCategoryList.length > 0">
              <li class="list_item_li select_all_li">
                <div class="ui_check_container">
                  <input
                    id="plans_categories"
                    v-model="getPlans.check_all_categories"
                    type="checkbox"
                    @change="selectAllCategories()"
                  />
                  <span class="check"></span>
                  <label for="plans_categories" class="checkbox_right">
                    <span class>All</span>
                  </label>
                </div>
              </li>

              <li
                class="ui_check_container showlink"
                v-for="(category, index) in sortContentCategory(
                  getContentCategoryList
                )"
                :key="index"
              >
                <input
                  type="checkbox"
                  :checked="isCategorySelected(category._id)"
                  :id="category._id"
                  :value="category._id"
                  @change="changeContentCategorySelection(category._id)"
                />
                <span class="check"></span>
                <label :for="category._id" class="checkbox_right">
                  <p>
                    {{ category.name }}
                    <a
                      @click.prevent="
                        contentCategoryOnlySelection(category._id)
                      "
                      class="onlyLink"
                      >Only</a
                    >
                  </p>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="planner_inner relative">
      <div class="planner_top_filter">
        <div class="d-flex align-items-center">
          <div class="left_filter relative">
            <div class="link-tabs-buttons">
              <ul>
                <li  @click.prevent="handleDefaultView('calendar')"  >
                  <router-link
                    :to="{ name: 'calender_plans', query: $route.query }"
                    >Calendar View</router-link
                  >

                </li>
                <li @click.prevent="handleDefaultView('list')">
                  <router-link  :to="{ name: 'list_plans', query: $route.query }"
                    >List View</router-link
                  >
                                  </li>
                <li class="new-li-tag" @click.prevent="handleDefaultView('feed')">
                  <router-link  :to="{ name: 'feed_view', query: $route.query }"
                    >Feed View</router-link
                  >
                </li>
                <!--<span class="new-tag-introduced">NEW</span>-->
              </ul>


            </div>


          </div>
          <div class="right_filter">
            <p class="time_zone">
              Timezone: {{ getWorkspaces.activeWorkspace.timezone }} (
              <clock
                timeFormat="hh:mm:ss A"
                :timezone="getWorkspaces.activeWorkspace.timezone"
              ></clock
              >)
            </p>
          </div>
        </div>
      </div>

      <div class="planner_top_filter">
        <div
          class="specific_plan_section"
          v-if="
            $route.query.reference_id ||
            $route.query.bulk_id ||
            $route.query.plan_ids
          "
        >
          <span class="specific_selected_plan">
            You have selected posts already, click on the cross icon to clear
            the filters.
            <i class="far fa-times" @click.prevent="removeSpecificPlans()"></i>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <div class="left_filter">
            <div
              data-cy="platform-selection"
              class="prevent_close_dropdown social_dropdown platform_filters_planner platform_filters_dd dropdown option_dropdown default_style_dropdown"
            >
              <platform-filters
                :dropdownPosition="'dropdown-menu-left'"
                type="Blog"
                source="planner"
              ></platform-filters>
            </div>
          </div>

          <div class="right_filter">
            <template v-if="isListView || isFeedView">
              <div
                class="datepicker_planner m-0"
                :class="{ date_added: $route.query.date }"
              >
                <date-range-picker
                  class="datepicker_input"
                  ref="topicsdatepicker"
                  :opens="planner.opens"
                  @update="updatePlannerDate"
                  :locale-data="planner.locale"
                  :singleDatePicker="planner.single_date_picker"
                  :timePicker="planner.time_picker"
                  :timePicker24Hour="planner.time_picker_24_hour"
                  :showWeekNumbers="planner.show_week_numbers"
                  :showDropdowns="planner.show_dropdowns"
                  :autoApply="planner.auto_apply"
                  :ranges="planner.ranges"
                >
                  <div
                    slot="input"
                    class="datepicker_content discovery_white mr-2"
                    slot-scope="picker"
                  >
                    <template v-if="$route.query.date">
                      <i class="icon_frst cs-calendar-fill"></i>
                      <span>{{ $route.query.date }}</span>
                    </template>

                    <template v-if="!$route.query.date">
                      <i class="icon-Calendar"></i>
                      <span>Select specific date range</span>
                    </template>
                  </div>
                </date-range-picker>
              </div>
              <!--gray_dropdown-->
              <div
                class="dropdown option_dropdown default_style_dropdown checkbox_dropdown planner_filter_dd"
                v-on:click.capture="closeDatePicker"
              >
                <div
                  class="dropdown_header d-flex align-items-center"
                  data-toggle="dropdown"
                >
                  <span class="text">Sort by</span>
                  <span class="ml-auto">
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </span>
                </div>

                <div
                  class="dropdown-menu dropdown-menu-right"
                  style="min-width: 150px"
                >
                  <ul class="inner">
                    <li class="field_group pl-3">
                      <label class="radio_container">
                        <input
                          id="descending_order"
                          :checked="
                            !$route.query.order_by ||
                            $route.query.order_by.toLowerCase() !== 'oldest'
                          "
                          value="descending"
                          type="radio"
                          @change="changeSort('newest')"
                        />
                        <div for="descending_order" class="radio_left">
                          Newest
                          <span class="check"></span>
                        </div>
                      </label>
                    </li>
                    <li class="field_group pl-3">
                      <label class="radio_container">
                        <input
                          id="ascending_order"
                          :checked="
                            $route.query.order_by &&
                            $route.query.order_by.toLowerCase() === 'oldest'
                          "
                          type="radio"
                          @change="changeSort('oldest')"
                        />
                        <div for="ascending_order" class="radio_left">
                          Oldest
                          <span class="check"></span>
                        </div>
                      </label>
                    </li>

                    <!-- <li class="list_item_li">
                                            <div class="radio_input_image">
                                                <input id="descending_order" :checked="!$route.query.order_by || $route.query.order_by.toLowerCase() !== 'oldest'" value="descending" type="radio" @change="changeSort('newest')">
                                                <label for="descending_order" class="radio_left">
                                                    Newest
                                                </label>
                                            </div>
                                        </li>

                                        <li class="list_item_li">
                                            <div class="radio_input_image">
                                                <input id="ascending_order" :checked="$route.query.order_by && $route.query.order_by.toLowerCase() === 'oldest'" type="radio" @change="changeSort('oldest')">
                                                <label for="ascending_order" class="radio_left">
                                                    Oldest
                                                </label>
                                            </div>
                    </li>-->
                  </ul>
                </div>
              </div>
            </template>

            <automation-filter
              v-if="showAutomationsSelection"
            ></automation-filter>
            <div
              class="default_style_dropdown option_dropdown planner-filter-dropdown"
            >
              <div
                :class="{ active: filtersAppliedWithCount > 0 }"
                @click="changeSidebarStatus()"
                class="dropdown_header d-flex align-items-center"
              >
                <i class="icon_first far fa-filter"></i>
                <span class="text">
                  <span
                    class="checkbox_counter"
                    v-if="filtersAppliedWithCount > 0"
                    >{{ filtersAppliedWithCount }}</span
                  >
                  Filter
                </span>
              </div>
            </div>
            <button
              class="btn btn_square has-tooltip bg-white refresh-button"
              @click="refreshPlans()"
              v-tooltip="'Refresh'"
            >
              <i class="far fa-refresh"></i>
            </button>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
