<template>
  <ConfirmBox
    id="upgrade-plan-confirmation-box"
    :call-back-action="getAction"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template
      v-if="getProfile.state === 'paused' || getProfile.state === 'deleted'"
    >
      <template slot="header">
        <h2>Purchase Plan.</h2>
      </template>

      <template slot="footer">
        <p
          >You are about to purchase the {{ subscription.display }} -
          {{ subscription.display_tag }} plan, please confirm if you want to go
          ahead with this?</p
        >
      </template>
    </template>
    <template v-else>
      <template slot="header">
        <h2>Upgrade Plan.</h2>
      </template>

      <template slot="footer">
        <p
          >You are about to upgrade your current plan
          {{ getPlan.subscription.display_name }} to
          {{ subscription.display }} - {{ subscription.display_tag }}, please
          confirm if you want to go ahead with this?</p
        >
      </template>
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils.js'
import { EventBus } from '@common/lib/event-bus'

export default {
  components: {
    ConfirmBox,
  },
  // eslint-disable-next-line vue/require-prop-types,vue/prop-name-casing
  props: ['subscription', 'billing_period'],

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getPlan', 'getProfile']),
  },
  created() {},
  mounted() {},

  methods: {
    getAction(confirmation) {
      console.debug('confirmation', confirmation.type)
      if (confirmation.status) {
        this.upgradeSubscriptionPlanAction()
      }
      // eslint-disable-next-line no-undef
      $('#' + confirmation.type).modal('hide')
    },
    upgradeSubscriptionPlanAction() {
      const routeQueryValue = this.$route.query.special
      console.log(this.isTrialPlan())

      const self = this
      const profile = this.$store.getters.getProfile
      let couponCode = this.billing_period === 'annually' ? 'ANNUAL20OFF' : ''

      if (routeQueryValue && this.isTrialPlan()) {
        couponCode = this.billing_period === 'annually' ? 'BF2020DEAL50OFF' : ''
      }

      // upgrade via Paddle subscription. There would be paddle_subscription_id

      if (profile.paddle_account_id && profile.paddle_subscription_id) {
        this.upgradeBySubscriptionId(this.subscription.paddle_id)
        return
      }

      // if the account does not have any paddle account id.

      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        product: this.subscription.paddle_id,
        email: profile.email,
        title: '',
        coupon: couponCode,
        successCallback: function (data) {
          console.log(data)
          window.fbq('track', 'Purchase', {
            value: parseInt(data.checkout.prices.customer.total),
            currency: data.checkout.prices.customer.currency,
          })
          alert('Thanks for your purchase.')
          // window.location.reload()
          self.upgradeCallback(data)
        },
      })
    },
    async upgradeBySubscriptionId(subscriptionId) {
      const res = await proxy
        .post(`${billingBaseUrl}paddle/upgrade_subscription_by_id`, {
          paddle_subscription_id: subscriptionId,
        })
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
            })
            return true
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            this.$store.dispatch('toastNotification', {
              message: err.response.data.message,
              type: 'error',
            })
          }
          return false
        })

      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
      }
    },

    async upgradeCallback(data) {
      console.log('data ->', JSON.stringify(data))

      const res = await proxy
        .post(`${billingBaseUrl}paddle/update_from_callback`, data)
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
            })
            return true
          }
        })
        .catch((err) => {
          console.error('upgrade callback Error', err)
          return false
        })
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
        if (
          [
            'upgrade_subscription',
            'trial_expired',
            'subscription_expired',
          ].indexOf(this.$route.name) >= 0
        ) {
          this.$router.push({ name: 'workspaces' })
        }
      }
    },
  },
}
</script>

<style>
#upgrade-plan-confirmation-box {
  z-index: 1500;
}
</style>
