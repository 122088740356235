<template>
  <section>
    <div class="billing-upgrade-page">
      <div class="billing-close-button left_nav">
        <b-button
          variant="studio-theme-transparent-grey"
          class="mr-4 mt-3"
          @click="logout()"
        >
          Logout
        </b-button>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-4 margin-auto">
          <div class="subscript-cancel-image">
            <img src="../images/account-close.png" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="subscript-cancel-message">
            <p>Your account is paused.</p>
            <p style="font-size: 17px; margin-top: 12px">
              There can be multiple reasons for your account state being
              "paused" such as payments for your subscription were paused or
              voluntarily pausing your subscription.
            </p>
          </div>

          <div class="change-cancel-mind">
            <p style="margin-bottom: 12px"
              >If you wish to reactivate your account, please use the options
              given below.</p
            >
            <button
              class="btn mybutton"
              @click="resumeSubscriptionAction()"
              :disabled="reopen_account_loader"
            >
              <span>Resume subscription</span>
              <clip-loader
                v-if="reopen_account_loader"
                :color="'#db5353'"
                :size="'16px'"
              ></clip-loader>
            </button>
            <button
              class="btn mybutton"
              style="margin-left: 10px"
              @click="openContactUs()"
            >
              <span>Contact support</span>
            </button>
            <a
              class="btn delete-account-btn"
              style="margin-left: 10px"
              @click="removeAccountData()"
              :disabled="remove_data_loader"
            >
              <span>Permanently delete data</span>
              <clip-loader
                v-if="remove_data_loader"
                :color="'#db5353'"
                :size="'16px'"
              ></clip-loader>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '../../../../config/api-utils'
import { swalAttributes } from '@common/constants/common-attributes'
import { authenticationTypes } from '@src/store/mutation-types'
import { EventBus } from '@common/lib/event-bus'

export default {
  data () {
    return {
      reopen_account_loader: false,
      remove_data_loader: false
    }
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  created () {
    const profile = this.getProfile
    if (profile.state && profile.state !== 'paused') {
      window.location.href = baseUrl
      return
    }
    this.Plan()
    this.fetchWorkspaces()
  },
  mounted () {},
  methods: {
    async resumeSubscriptionAction () {
      console.debug('Method::purchasePlan')
      this.reopen_account_loader = true
      const response = await this.$store.dispatch('resumePaddleSubscription')
      this.reopen_account_loader = false
      if (response) window.location.href = baseUrl
    },
    async removeAccountData () {
      console.debug('Method::removeAccountData')
      const res = await this.$bvModal
        .msgBoxConfirm(
          'Are you sure you want to delete your account permanently?',
          {
            title: 'Remove Account Data',
            ...swalAttributes()
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return false
        })

      if (res) {
        this.remove_data_loader = true
        const response = await this.$store.dispatch('removeAccountData')
        this.remove_data_loader = false
        if (response) {
          this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
          this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
          this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
          setTimeout(() => {
            window.location.href = baseUrl + 'login'
          }, 2000)
        }
      }
    },
    openContactUs () {
      EventBus.$emit('open-help-widget')
    }
  }
}
</script>
