<template>
  <div
    class="analytic_component analytics-report-wrapper"
    style="background: #515558"
  >
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Group'"
          :current="1"
          :total="total"
        ></report-header>
        <div>
          <AnalyticsSummaryBlock
            class="p-0 mx-0"
            :heading="'All Profiles Performance Summary'"
            :subtitle="'Key profile performance metrics from the selected time period.'"
          >
            <div class="row">
              <template v-for="(value, key) in overview_values">
                <div class="col-6 col-sm-3" :key="key">
                  <SummaryCard
                    :title="value"
                    :total="metrics.overview.current[key]"
                    :reach="Math.abs(getReach(key, metrics.overview)) + '%'"
                    :reachUp="getReachUp(key, metrics.overview)"
                  ></SummaryCard>
                </div>
              </template>
            </div>
          </AnalyticsSummaryBlock>

          <div class="analytics-section-header">
            <h2>Engagement / Posts Published </h2>
            <p>See how your audience interacted with your posts.</p>
          </div>
          <ColumnCrossHairChart
            v-if="metrics.posts_engagements.data.show_data > 0"
            :categories="metrics.posts_engagements.buckets"
            :series="getPostEngagementSeries()"
          />
          <div class="row mt-4">
            <template v-for="(value, key) in engagement_rollup_values">
              <div class="col-6 col-sm-3" :key="key">
                <SummaryCard
                  :title="value"
                  :total="metrics.engagement_rollup.current[key]"
                  :reach="
                    Math.abs(getReach(key, metrics.engagement_rollup)) + '%'
                  "
                  :reachUp="getReachUp(key, metrics.engagement_rollup)"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage card_activity_top_post">
        <report-header
          :report_name="'Group'"
          :current="2"
          :total="total"
        ></report-header>
        <h2>Top Posts By Engagement</h2>
        <p
          >Review your top posts published during the selected time period,
          based on the story’s lifetime performance.</p
        >
        <AnalyticsTopPosts
          :top_posts="{ overall: metrics.top_posts.overall }"
          :type="'report'"
        ></AnalyticsTopPosts>
      </div>
    </div>

    <template v-for="(accounts, key) in spliced_accounts">
      <div class="page_view" :key="key">
        <div class="subpage card_activity_top_post">
          <report-header
            :report_name="'Group'"
            :current="key + 3"
            :total="total"
          ></report-header>
          <h2>Social Profiles Performance</h2>
          <p>Key metrics of the Social Profiles in the selected time period.</p>
          <AnalyticsAccountPerformance
            :account_performance="metrics.account_performance.overall"
            :accounts="accounts"
            :type="'report'"
          ></AnalyticsAccountPerformance>
        </div>
      </div>
    </template>
    <div class="page_view">
      <div class="subpage card_activity_top_post analytics-engagement">
        <report-header
          :report_name="'Group'"
          :current="total"
          :total="total"
        ></report-header>
        <h2>Overall Platforms Engagement</h2>
        <p>View engagement metrics across all platforms.</p>
        <AnalyticsEngagement
          :account_performance="metrics.account_performance"
        ></AnalyticsEngagement>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'

export default {
  data () {
    return {
      total: 3,
      account_performance_total_pages: 1,
      total_overall_accounts: 0,
      overview_values: {
        total_posts: 'Total Posts',
        reactions: 'Reactions',
        comments: 'Comments',
        reposts: 'Reposts'
      },
      engagement_rollup_values: {
        total_posts: 'Total Posts',
        posts_per_day: 'Posts Per Day',
        total_engagement: 'Total Engagement',
        engagement_per_day: 'Engagement Per Day'
      },
      spliced_accounts: []
    }
  },
  props: {
    metrics: {}
  },
  mounted () {
    console.log('ooooooooooooooooooooooo',this.metrics)
    this.account_performance_total_pages = Math.ceil(
      this.metrics.accounts.length / 10
    )
    this.total += this.account_performance_total_pages
    console.log('this.account_performance_total_pages')
    console.log(this.account_performance_total_pages)
    console.log(this.total)

    const size = 10

    while (this.metrics.accounts.length > 0) {
      this.spliced_accounts.push(this.metrics.accounts.splice(0, size))
    }

    console.log(this.spliced_accounts)
  },
  methods: {
    getReach (type, section) {
      const previous =
        section.previous[type] === 0 || !section.previous[type]
          ? section.current[type]
          : section.previous[type]
      const value = (
        ((section.current[type] - previous) / previous) *
        100
      ).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReachUp (type, section) {
      return this.getReach(type, section) > 0
    },
    getPostEngagementSeries () {
      return [
        {
          name: 'Comments',
          data: this.metrics.posts_engagements.data.comments,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Reposts',
          data: this.metrics.posts_engagements.data.reposts,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Reactions',
          data: this.metrics.posts_engagements.data.reactions,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Posts Published',
          data: this.metrics.posts_engagements.data.post_count,
          color: '#6173ff',
          type: 'spline'
        }
      ]
    }
  },
  components: {
    ReportHeader,
    AnalyticsFilterBar,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    SkeletonBox,
    ColumnCrossHairChart
  },
  computed: {}
}
</script>
