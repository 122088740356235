<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '@src/modules/discovery/components/topics/NoResultsFound'
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
export default {
  data () {
    return {
      is_curated_quotes_loading: false,
      infiniteId: +new Date(),
      infiniteState: '',
      page: 1,
      limit: 20,
      quotes_list: [],
      total_results: 0
    }
  },
  components: {
    NoResultsFound,
    InfiniteLoading
  },
  computed: {
    ...mapGetters(['getQuotesFilter'])
  },
  methods: {
    quotesViewHandler ($state) {
      console.debug('Method::quotesViewHandler')
      this.actionFetchQuotes($state)
    },
    actionFetchQuotes ($state) {
      console.debug('Method::actionFetchQuotes')
      proxy
        .post(`${discoveryBaseUrl}search/quotes`, {
          page: this.page,
          limit: this.limit,
          search: this.getQuotesFilter.search
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.quotes.hits && res.data.quotes.hits.length > 0) {
              this.quotes_list.push(...res.data.quotes.hits)
              if ($state) $state.loaded()
              this.total_results = res.data.quotes.total
              this.page += 1
            } else {
              if ($state) $state.complete()
            }
            this.checkComposerDragDrop()
          } else {
            if ($state) $state.complete()
          }
        })
        .catch((err) => {})
    },
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', post)

      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'image',
          heading: '',
          media: this.backgroundQuoteImageURL(post._source),
          p: post._source.quote || '',
          link: this.backgroundQuoteImageURL(post._source)
        })
      )
    }
  },
  watch: {
    'getQuotesFilter.search' (value) {
      this.quotes_list = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  }
}
</script>
<template>
  <div>
    <template v-for="(post, key) in quotes_list">
      <div
        class="assistant_box _quote _photo"
        :class="{ dragable_box: !($route.name === 'composerBlog') }"
        v-if="post && post._source"
        :key="key"
      >
        <div
          class="box_inner"
          @dragstart="dragstartHandler($event, post)"
          :draggable="$route.name === 'composerBlog'"
        >
          <div
            class="image_block"
            @click="previewImage(backgroundQuoteImageURL(post._source))"
          >
            <div class="play_icon">
              <i class="cs-eye"></i>
            </div>
            <div
              class="img"
              v-lazy:background-image="backgroundQuoteImageURL(post._source)"
            ></div>
          </div>

          <!--Editor Html DON'T REMOVE-->
          <div
            class="hide d-none editor_quote_block editor_dragable_box editor_image_block"
            contenteditable="false"
          >
            <div class="editor_dragable_box_inner clear">
              <div class="top_option no_flickr">
                <div class="right_block">
                  <i
                    class="handler fa fa-hand-paper-o editor_tooltip"
                    title="Move"
                  ></i>
                  <i
                    class="edit_box fa fa-check-circle editor_tooltip green"
                    title="Merge"
                  ></i>
                  <i
                    class="remove_box fa fa-times-circle editor_tooltip red"
                    title="Remove"
                  ></i>
                </div>
              </div>
              <br />
              <p style="text-align: center">
                <img
                  style="max-width: 100%"
                  class=""
                  :src="backgroundQuoteImageURL(post._source)"
                  alt=""
                />
              </p>
              <div class="clearfix">
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <infinite-loading
      :identifier="infiniteId"
      @infinite="quotesViewHandler"
      ref="infiniteCuratedQuotes"
    >
      <span slot="spinner" class="d-block pt-3 pb-4">
        <beat-loader :color="'#436aff'"></beat-loader>
      </span>

      <div slot="no-more" class="not_found_text">
        <div class="no_data_found_content" v-if="total_results === 0">
          <content-available-state :type="'no_results'" style="width: 100%">
            <h2 slot="headline">No Results Found</h2>
            <p slot="text"
              >No more results found for your query. Please try a different
              search keyword.</p
            >
          </content-available-state>
        </div>
      </div>

      <div slot="no-results">
        <div class="no_data_found_content" v-if="total_results === 0">
          <content-available-state :type="'no_results'" style="width: 100%">
            <h2 slot="headline">No Results Found</h2>
            <p slot="text">No quotes are available for your searched query.</p>
          </content-available-state>
        </div>
      </div>
    </infinite-loading>
  </div>
</template>
