<template>
  <div class="components-uitkit right-side-uitkit-container">
    <h3>Table & Boxes</h3>
    <p
      >For the future, we will be using the following variation for the Tables
      and boxes.</p
    >

    <h4>Table with white background: </h4>

    <div class="row">
      <div class="col-3">
        <div class="uikit-codemirror">
          <codemirror v-model="tableWithWhite" :options="cmOptions" />
        </div>
      </div>

      <div class="col-9">
        <div class="grey_box">
          <div class="table_box">
            <table>
              <thead>
                <tr>
                  <th>heading 1 </th>
                  <th>heading 2 </th>
                  <th>heading 3 </th>
                  <th>heading 4 </th>
                  <th>heading 5 </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
                <tr>
                  <td>content 1</td>
                  <td>content 2</td>
                  <td>content 3</td>
                  <td>content 4</td>
                  <td>content 5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'

export default {
  components: {
    codemirror,
  },
  data() {
    return {
      cmOptions: {
        tabSize: 20,
        mode: 'text/html',
        lineNumbers: true,
        line: true,
      },
      tableWithWhite: `<div class="table_box">
                <table>
                    <thead>
                        <tr>
                            <th>heading 1 </th>
                            <th>heading 2 </th>
                            <th>heading 3 </th>
                            <th>heading 4 </th>
                            <th>heading 5 </th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>content 1</td>
                          <td>content 2</td>
                          <td>content 3</td>
                          <td>content 4</td>
                          <td>content 5</td>
                      </tr>
                      <tr>
                          <td>content 1</td>
                          <td>content 2</td>
                          <td>content 3</td>
                          <td>content 4</td>
                          <td>content 5</td>
                      </tr>
                    </tbody>
                </table>
            </div>`,
      tableWithtrans: ',',
    }
  },
}
</script>

<style scoped>
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/base16-dark.css';
.grey_box {
  background: #f4f7fa;
  padding: 2rem 5rem;
}
</style>
