import { publishTimeTypes } from './mutation-types'

const defaultTimeSelection = {
  time_type: 'now',
  post_date: '',
  repeat: '',
  repeat_type: 'Hour',
  repeat_times: 1,
  repeat_gap: 1,
  evergreen: '',
  plan_status: 'Approved'
}

export default {
  state: {
    selection: {
      time_type: 'now',
      post_date: '',
      repeat: '',
      repeat_type: 'Hour',
      repeat_times: 1,
      repeat_gap: 1,
      evergreen: '',
      plan_status: 'Approved',
      hide_client: false
    }
  },
  actions: {
    setPublishingTimeType ({ commit }, type) {
      commit('setPublishingTimeType', type)
    },

    setPublishingPostDate ({ commit }, postDate) {
      commit('setPublishingPostDate', postDate)
    },

    setPublishingRepeatGap ({ commit }, repeatGap) {
      commit('setPublishingRepeatGap', repeatGap)
    },
    setPublishTimeSelection ({ commit }, selection) {
      commit('setPublishTimeSelection', selection)
    },
    setPublishingTimePlanStatus ({ commit }, status) {
      commit('setPublishingTimePlanStatus', status)
    }
  },
  mutations: {
    [publishTimeTypes.SET_PUBLISHING_TIME_TYPE] (state, value) {
      state.selection.time_type = value
    },
    setPublishingTimeType (state, type) {
      state.selection.time_type = type
    },

    setPublishingPostDate (state, postDate) {
      state.selection.post_date = postDate
    },

    setPublishingRepeatGap (state, repeatGap) {
      state.selection.repeat_gap = repeatGap
    },
    setPublishTimeSelection (state, selection) {
      if (selection == null) {
        state.selection = JSON.parse(JSON.stringify(defaultTimeSelection))
      } else {
        state.selection = selection
      }
    },
    setPublishingTimePlanStatus (state, data) {
      state.selection.plan_status = data
    }
  },
  getters: {
    getPublishTimeOptions: (state) => {
      return state.selection
    }
  }
}
