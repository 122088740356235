var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isWorkspaceOnHold)?_c('div',{class:{
    'post_share_option post_share_option_social basic_form_inline mt-0 mb-5':
      _vm.hasPostShareClass,
    'rss-content-category': _vm.position === 'rss-listing',
  }},[_c('div',{staticClass:"input_field d-flex align-items-center"},[_vm._m(0),_c('div',{staticClass:"right_input"},[_c('div',{staticClass:"input_field mt-0"},[_c('div',{staticClass:"field_group w-auto d-inline-block category_dropdown dropdown default_style_dropdown",class:{
            'input-field-error input_validation': _vm.validations.category,
          },staticStyle:{"min-width":"280px"}},[_c('div',{staticClass:"dropdown_header d-flex align-items-center",attrs:{"data-toggle":"dropdown"}},[_c('span',{staticClass:"text",attrs:{"data-cy":"select-category"}},[(!_vm.getCategoryId)?[_vm._v(" Select category from list ")]:[(
                    _vm.selected_category &&
                    _vm.selected_category.hasOwnProperty('name')
                  )?_c('span',{staticClass:"text"},[_c('span',{staticClass:"ct_color",class:_vm.selected_category.color}),_c('span',{staticClass:"ct_text",attrs:{"data-cy":"existing-categories"}},[_vm._v(_vm._s(_vm.selected_category.name))])]):_vm._e()]],2),_vm._m(1)]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('ul',{staticClass:"inner"},[(_vm.getContentCategoryList.length > 0 && _vm.getCategoryId)?_c('li',{staticClass:"clear-category-action",on:{"click":function($event){return _vm.selectPublishSlotCategory(_vm.selected_category)}}},[_c('span',[_vm._v(" Unselect Category ")])]):_vm._e(),_vm._l((_vm.sortContentCategory(
                  _vm.getContentCategoryList,
                )),function(category){return (_vm.getContentCategoryList.length > 0)?[(
                    (_vm.$route.name === 'saveRssAutomation' ||
                      _vm.$route.name === 'saveEvergreenAutomationAccounts' ||
                      _vm.$route.name === 'saveBulkCsvAutomation') &&
                    category.color
                  )?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
                    content: _vm.getGlobalCategoryText(category.category_state),
                    container: 'body',
                  }),expression:"{\n                    content: getGlobalCategoryText(category.category_state),\n                    container: 'body',\n                  }",modifiers:{"top-center":true}}],staticClass:"list_item_li",class:{
                    disable_content_category:
                      category.category_state === 'global',
                  },attrs:{"value":category._id,"data-cy":"content-catagory"},on:{"click":function($event){return _vm.selectPublishSlotCategory(category)}}},[_c('span',{staticClass:"d-flex"},[_c('span',{staticClass:"ct_text"},[_c('span',{staticClass:"ct_color",class:category.color}),_vm._v(" "+_vm._s(_vm.limitTextLength(category.name, 32))+" "),(category.category_state === 'global')?[_vm._v("(Global)")]:_vm._e()],2),_c('span',{staticClass:"ct_text ml-auto"},[(category.category_state === 'global')?[_c('i',{staticClass:"far fa-crown"})]:_vm._e()],2)])]):_c('li',{staticClass:"list_item_li",class:{
                    'content-category-item-selected':
                      _vm.selected_category &&
                      _vm.selected_category._id === category._id,
                  },attrs:{"value":category._id},on:{"click":function($event){return _vm.selectPublishSlotCategory(category)}}},[_c('span',{staticClass:"d-flex"},[_c('span',{staticClass:"ct_text",attrs:{"data-cy":"category-item"}},[_c('span',{staticClass:"ct_color",class:category.color}),_vm._v(" "+_vm._s(_vm.limitTextLength(category.name, 32))+" "),(category.category_state === 'global')?[_vm._v("(Global)")]:_vm._e(),_c('span',{staticClass:"content-category-selected"},[(
                            _vm.selected_category &&
                            _vm.selected_category._id === category._id
                          )?[_c('i',{staticClass:"fas fa-check"})]:_vm._e()],2)],2),_c('span',{staticClass:"ct_text ml-auto"},[(category.category_state === 'global')?[_c('i',{staticClass:"far fa-crown"})]:_vm._e()],2)])])]:_vm._e()}),(_vm.getContentCategoryList.length === 0)?_c('li',{staticClass:"no_text_li"},[_vm._v(" No category added yet. ")]):_vm._e()],2)]),(_vm.validations.category)?_c('span',{staticClass:"input-error"},[_vm._v(" "+_vm._s(_vm.messages.category)+" ")]):_vm._e()])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_input"},[_c('p',{staticClass:"label"},[_vm._v("Select Content Category")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ml-auto"},[_c('i',{staticClass:"dropdown_arrow icon_last cs-angle-down"})])
}]

export { render, staticRenderFns }