<template>
  <div class="article_box_grid video_box_grid">
    <div class="box_inner">
      <div class="image_block">
        <div
          class="img"
          style="
            background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
          "
        ></div>
      </div>
      <div class="content_block">
        <h2>Boris Johson quits to add to pressu on May over Brexit</h2>
        <div class="detail clear">
          <div class="author_detail d-flex align-items-center">
            <p>7 days ago</p>
            <p class="ml-auto">By <span>Apple</span> <a href="">@appear</a></p>
          </div>
        </div>
        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div class="stat_item">
              <div class="name">
                <p>View Count</p>
              </div>
              <div class="value">
                <h3>343</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_block d-flex">
          <div class="btn_block_inner">
            <div
              class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
            >
              <div class="dropdown_header" data-toggle="dropdown">
                <button class="btn light_gray">
                  <i class="icon-Share_icon d-block"></i>
                  <!--<span class="circle_count">7</span>-->
                </button>
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li class="list_item_li" @click.prevent="test()">
                    <i class="cs-social-share"></i>
                    <span class="icon_text">Social Media</span>
                  </li>
                  <li class="list_item_li" @click.prevent="test()">
                    <i class="cs-rss"></i>
                    <span class="icon_text">Blog Post</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="inline_block dropdown default_style_dropdown">
              <button class="btn light_gray">
                <i class="cs-pocket"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Dialymotion',
  components: {},
  computed: {
    ...mapGetters([])
  }
}
</script>
