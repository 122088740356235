var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade history_version_modal",attrs:{"id":"history_version_modal"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[(_vm.getVersionsHistoryLoader)?_c('div',{staticClass:"modal_body height d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"h-100 pt-5"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'20px'}})],1)]):(_vm.getPlanVersionHistory.length === 0)?_c('div',{staticClass:"modal_body"},[_vm._m(1),_vm._m(2)]):_c('div',{staticClass:"modal_body d-flex height"},[_c('div',{staticClass:"modal_left"},[_c('div',{staticClass:"article_content"},[_c('div',{staticClass:"article_wrapper"},[_c('div',{staticClass:"article_head"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex].title))]),(
                    _vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex][
                      'user'
                    ] &&
                    _vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex][
                      'user'
                    ]['firstname'] &&
                    _vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex][
                      'user'
                    ]['lastname']
                  )?_c('div',{staticClass:"author_detail"},[_c('p',[_vm._v(" By "),_c('span',[_vm._v(_vm._s(_vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex][ 'user' ]['firstname'])+" "+_vm._s(_vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex][ 'user' ]['lastname']))])])]):_vm._e()]),_c('div',{staticClass:"article_content_inner",domProps:{"innerHTML":_vm._s(
                  _vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex]
                    .description
                )}})])])]),_c('div',{staticClass:"modal_right"},[_c('div',{staticClass:"right_head d-flex align-items-center"},[_c('h3',[_vm._v("Version History ("+_vm._s(_vm.getPlanVersionHistory.length)+")")]),_c('i',{staticClass:"cs-cross",attrs:{"data-dismiss":"modal"}})]),_c('div',{staticClass:"history_list"},_vm._l((_vm.getPlanVersionHistory),function(version,index){return _c('div',{staticClass:"h_item d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.changeVersion(index)}}},[_c('div',{staticClass:"profile_picture d-flex align-items-start"},[_c('div',{staticClass:"picture_block picture_block_background"},[(version['user']['image'])?_c('div',{staticClass:"img",style:({
                      background: 'url(' + version['user']['image'] + ')',
                    })}):_c('div',{staticClass:"img",staticStyle:{"background":"url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')"}})]),_c('div',{staticClass:"text_block"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.getWorkspaceTimeZoneTime( version.updated_at, 'MMM DD, hh:mm a' )))]),(
                      version['user']['firstname'] &&
                      version['user']['lastname']
                    )?_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(version['user']['firstname'])+" "+_vm._s(version['user']['lastname']))]):_vm._e()])]),(index === _vm.getVersionHistoryActiveIndex)?_c('div',{staticClass:"version"},[_c('p',[_vm._v("Current Version")])]):_vm._e()])}),0),_c('div',{staticClass:"h_btn"},[_c('button',{staticClass:"btn active",attrs:{"disabled":!_vm.getPlanControl},on:{"click":function($event){$event.preventDefault();return _vm.restoreVersion(
                  _vm.getPlanVersionHistory[_vm.getVersionHistoryActiveIndex]
                )}}},[_vm._v("Restore this version")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close_btn"},[_c('i',{staticClass:"cs-cross",attrs:{"data-dismiss":"modal"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close_btn"},[_c('i',{staticClass:"cs-cross",attrs:{"data-dismiss":"modal"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"not_found_text pt-2"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('h2',[_vm._v("No version history found")]),_c('p',[_vm._v("You don't have any version histroy.")])])])])
}]

export { render, staticRenderFns }