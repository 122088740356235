var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds_card_container ds_domain_table"},[_c('div',{staticClass:"card_v1"},[_c('div',{staticClass:"card_inner"},[_vm._m(0),_c('div',{staticClass:"card_content"},[_c('div',{staticClass:"ds_analytic_table"},[_c('div',{staticClass:"t_head d-flex align-items-center"},[_c('div',{staticClass:"col"},[_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
                  active: _vm.sortByRow == 'key' && _vm.sortByOrder == 'asc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('key', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
                  active: _vm.sortByRow == 'key' && _vm.sortByOrder == 'desc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('key', 'desc')}}}),_c('span',{staticClass:"text"},[_vm._v("Author Name")])]),_c('div',{staticClass:"col"},[_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
                  active: _vm.sortByRow == 'doc_count' && _vm.sortByOrder == 'asc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('doc_count', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
                  active: _vm.sortByRow == 'doc_count' && _vm.sortByOrder == 'desc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('doc_count', 'desc')}}}),_c('span',{staticClass:"text"},[_vm._v("Total Articles")])]),_c('div',{staticClass:"col"},[_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
                  active:
                    _vm.sortByRow == 'total_interactions.value' &&
                    _vm.sortByOrder == 'asc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('total_interactions.value', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
                  active:
                    _vm.sortByRow == 'total_interactions.value' &&
                    _vm.sortByOrder == 'desc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('total_interactions.value', 'desc')}}}),_c('span',{staticClass:"text"},[_vm._v("Total Engagement")])]),_c('div',{staticClass:"col"},[_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
                  active:
                    _vm.sortByRow == 'average_interactions.value' &&
                    _vm.sortByOrder == 'asc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('average_interactions.value', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
                  active:
                    _vm.sortByRow == 'average_interactions.value' &&
                    _vm.sortByOrder == 'desc',
                },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('average_interactions.value', 'desc')}}}),_c('span',{staticClass:"text"},[_vm._v("Avg Engagement")])])]),_c('div',{staticClass:"t_body"},[_vm._l((_vm.orderedList()),function(author){return (
                _vm.getInsightsTopAuthors &&
                _vm.getInsightsTopAuthors.length > 0 &&
                author.total_interactions.value >= 0
              )?_c('div',{staticClass:"t_row d-flex align-items-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"profile_picture"},[_c('div',{staticClass:"picture_block"},[_c('div',{class:_vm.getRandomColor()},[_c('span',[_vm._v(_vm._s(_vm.capitalizeName(author.key)))])])]),_c('div',{staticClass:"text_block"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(author.key))])])])]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(author.doc_count))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$filters.floatToInt(author.total_interactions.value)))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$filters.floatToInt(author.total_interactions.value)))])]):_vm._e()}),(
                _vm.getInsightsTopAuthors && _vm.getInsightsTopAuthors.length == 0
              )?_c('div',{staticClass:"t_row d-flex align-items-center"},[_c('div',{staticClass:"col col-full-width"},[_vm._v(" No data found for the authors. ")])]):_vm._e()],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_head"},[_c('h2',[_vm._v("Top Authors")])])
}]

export { render, staticRenderFns }