<template>
  <div>
    <b-modal
      modal-class="normal_modal bv-modal-upgrade-plan w_900"
      :id="modalId"
      hide-footer
      hide-header
    >
      <div class="modal_head d-flex align-items-center">
        <h2>Upgrade your subscription plan to get more features.</h2>
        <button
          type="button"
          class="modal_head__close"
          data-dismiss="modal"
          @click="$bvModal.hide(modalId)"
          >&times;
        </button>
      </div>
      <div class="modal_body basic_form">
        <upgrade-plan-component
          v-on:showPlanConfirmation="onShowPlanConfirmation"
        />
      </div>
    </b-modal>

    <upgrade-plan-confirmation
      :subscription="subscription"
      :billing_period="billing_period"
    ></upgrade-plan-confirmation>
  </div>
</template>
<script>
import UpgradePlanComponent from './UpgradePlanComponent'
import UpgradePlanConfirmation from './UpgradePlanConfirmation'

export default {
  name: 'upgrade-plan-dialog',
  data () {
    return {
      modalId: 'upgrade-plan-dialog',
      subscription: '',
      billing_period: ''
    }
  },
  components: {
    UpgradePlanComponent,
    UpgradePlanConfirmation
  },
  methods: {
    // eslint-disable-next-line camelcase
    onShowPlanConfirmation (subscription, billing_period) {
      console.debug('Debuged', subscription, billing_period)
      this.subscription = subscription
      // eslint-disable-next-line camelcase
      this.billing_period = billing_period
    }
  }
}
</script>
