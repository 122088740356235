import {mapGetters} from 'vuex'

export const billingMixin = {
    computed: {
        ...mapGetters(['getPlan', 'getActiveWorkspaceAddonsLifetime']),
    },
    methods: {
        shouldLockBasedOnSubAddons(type) {
            const subscription = this.getPlan.subscription

            let shouldLock = false
            // check default addons that are part of the subscription
            let defaultAddons = null
            if (typeof subscription.default_add_ons === 'undefined') {
                return false
            } else {
                defaultAddons = subscription.default_add_ons
            }

            if (typeof defaultAddons[type] === 'undefined') {
                shouldLock = false
            } else {
                shouldLock = !defaultAddons[type]
            }

            // if the subscription has that addon disabled
            // check for paid add ons
            if (shouldLock === true) {
                const paidAddons = this.getActiveWorkspaceAddonsLifetime
                if (type === 'social_inbox') {
                    shouldLock = !paidAddons['social_inbox'] || !paidAddons['social_inbox_beta']
                } else {
                    shouldLock = !paidAddons[type]
                }
            }

            return shouldLock
        },
        getLockBasedOnSubAddonsMessage(type) {
            var message = "Your subscription plan " + this.getPlan.subscription.display_name + " does not include "
            let modType = type.replace('_', ' ').toLowerCase()

            return message + modType + " please update your plan."
        },
        checkIfStarterSubPlan() {
            const subscriptionId = this.getPlan.subscription._id
            return subscriptionId === '62a0310c2d363a60b1e5ff75' // starter trial
                || subscriptionId === '629f4952945c0941d97530a5' // starter monthly
                || subscriptionId === '629f4964945c0941d97530ac' // starter annual
        },
    },
}