export default {
  state: {
    requests: {
      fetchPlansRequests: [],
      fetchPlansCountRequests: [],
      fetchSharingMention: [],
      previewSuggestion: [],
      fetchPublications: [],
      fetchSharingHashTags: [],
      fetchRssSuggestions: [],
      mediaLibraryCreateFolder: [],
      mediaLibraryFetchFolders: [],
      mediaLibraryFetchFoldersStats: [],
      mediaLibraryUpdateFolder: [],
      mediaLibraryUploadMedia: [],
      mediaLibraryFetchAssets: [],
      mediaLibraryRemoveAssets: [],
      mediaLibraryMicroLink: [],
      fetchMediaLinks: [],
      generateCaption: [],
    },
  },
  actions: {},
  mutations: {},
  getters: {
    getAxiosRequests: (state) => {
      return state.requests
    },
  },
}
