<template>
  <div class="straight-line-chart">
    <div
      v-for="(item, key) in values"
      :key="key"
      class="straight-line-chart__item"
    >
      <div class="straight-line-chart__header">
        <span>{{ key }}</span>
        <span>{{ item }}</span>
      </div>
      <div
        class="straight-line-chart__progress"
        :data-progress="(item / max_value) * 100"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StraightLineChart',
  props: ['values', 'max_value'],
  mounted () {
    const chartItems = this.$el.querySelectorAll(
      '.straight-line-chart__progress'
    )
    chartItems.forEach((item) => {
      this.animateProgress(item)
    })
  },
  methods: {
    animateProgress (el) {
      // Get the element that came as parameter and add the class 'animated-progress' on it
      el.classList.add('animate-progress')

      // Set the attribute 'style' of this element with the custom attribute '--animate-progress'
      el.setAttribute(
        'style',
        `--animate-progress:${el.getAttribute('data-progress')}%;`
      )
      // After this the CSS make its magic
    }
  }
}
</script>
