<template>
  <div class="auth">
    <div class="row h-full">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container">
          <form class="auth-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2>Create Your Account</h2>
            </div>

            <CstFloatingLabelInput
              id="fname"
              v-model="account.firstname"
              class="mb-6"
              type="text"
              label="First Name"
              :maxlength="18"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-User"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
              id="lname"
              v-model="account.lastname"
              class="mb-6"
              type="text"
              label="Last Name"
              :maxlength="18"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-User"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
              id="email"
              v-model="account.email"
              class="mb-6"
              type="email"
              label="Email Address"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
              id="password"
              v-model="account.password"
              class="mb-6"
              type="password"
              label="Password"
              :maxlength="18"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>

            <div
              v-if="password_strength_message"
              class="simple-form__text-field-message"
              :class="{ danger: password_state === 'danger' }"
            >
              {{ password_strength_message }}
            </div>

            <div ref="phoneField" class="auth-form__phone-field">
              <VueTelInput
                v-model="account.phone_no"
                data-cy="telephone"
                mode="international"
                placeholder="Phone Number"
                :show-dial-code-in-list="true"
                :show-dial-code-in-selection="false"
                :valid-characters-only="true"
                @input="phoneInput"
                @validate="phoneValidate"
              >
                <template slot="arrow-icon" slot-scope="{ open }">
                  <i v-if="open" class="fas fa-chevron-up"></i>
                  <i v-else class="fas fa-chevron-down"></i>
                </template>
              </VueTelInput>
            </div>

            <div
              class="simple-form__select-field flex items-center h-14 rounded-md border bg-white text-gray-900 text-sm focus-within:outline-blue block w-full py-2.5 px-0 mb-6"
            >
              <select
                v-model="account.business_type"
                class="!px-3"
                required
                data-cy="business_type"
              >
                <option disabled selected value=""
                  >What best describes you?</option
                >
                onkeypress="return
                /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                <option value="blogger">Blogger</option>
                <option value="solopreneur">Solopreneur</option>
                <option value="agency">Agency</option>
                <option value="saas_business">SaaS Business</option>
                <option value="marketing_team">Marketing Team</option>
                <option value="enterprise">Enterprise</option>
              </select>
            </div>

            <VueRecaptcha
              ref="recaptcha"
              size="invisible"
              :sitekey="getGoogleRecaptchaKey"
              @verify="createAccount"
              @expired="onCaptchaExpired"
              @error="onCaptchaError"
            >
            </VueRecaptcha>

            <div class="auth-form__terms">
              <p>
                By signing up, you agree to our
                <a
                  href="https://contentstudio.io/terms-and-conditions"
                  target="_blank"
                  >terms of service</a
                >
                and
                <a
                  href="https://contentstudio.io/privacy-policy"
                  target="_blank"
                  >privacy policy.</a
                >
              </p>
            </div>
            <div class="auth-form__button-field">
              <button
                :disabled="registerLoader"
                data-cy="sign_up_button"
                class="btn btn-studio-theme-space btn-size-large"
                @click.prevent="executeRecaptcha"
              >
                <span>Sign up</span>

                <img
                  v-if="registerLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>

            <div class="auth-form__social-field">
              <p>Or sign in using social media</p>
              <span :disabled="!getSSOAuthorizationLinks.facebook">
                <button
                  data-cy="facebook"
                  class="btn btn-studio-theme-space btn-size-large auth-form__social-field--fb"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.facebook, 'signed_up')
                  "
                >
                  <i class="fab fa-facebook-f"></i>
                </button>
              </span>
              <span :disabled="!getSSOAuthorizationLinks.twitter">
                <button
                  data-cy="twitter"
                  class="btn btn-studio-theme-space btn-size-large auth-form__social-field--tw"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.twitter, 'signed_up')
                  "
                >
                  <i class="fab fa-twitter"></i>
                </button>
              </span>
            </div>
          </form>

          <div class="auth-form-container__footer">
            <p>
              Already have an account?
              <router-link :to="{ name: 'login' }"><b>Sign In</b></router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <FeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueTelInput } from 'vue-tel-input'
import { registerURL } from '@src/config/api-utils'
import { authenticationTypes } from '@src/store/mutation-types'
import VueCookie from 'vue-cookie'
import { VueRecaptcha } from 'vue-recaptcha'
import FeaturesSlider from '@src/modules/account/components/FeaturesSlider'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
export default {
  name: 'SignUp',
  components: {
    FeaturesSlider,
    VueTelInput,
    VueRecaptcha,
    CstFloatingLabelInput,
  },
  data() {
    return {
      account: {
        firstname: '',
        lastname: '',
        email: this.$route.query.email ? this.$route.query.email : '',
        password: '',
        plan: 'trial',
        business_type: '',
        phone_no: '',
      },
      isValidPhone: false,
      registerLoader: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks']),
    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 8) {
          this.password_strength_message =
            'Password must be 8 characters or more'
          this.password_state = 'danger'
          return
        }
        if (value.length >= 6 && value.length <= 12) {
          this.password_strength_message = 'Password could be more secure'
        }
        if (value.length >= 13 && value.length <= 17) {
          this.password_strength_message = 'Password is okay'
        }
        if (value.length >= 17) {
          this.password_strength_message = 'Password is strong'
        }
        this.password_state = ''
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  created() {
    this.fetchSSOLinks()
    if (this.$route.query && typeof this.$route.query.package !== 'undefined') {
      this.account.plan = this.$route.query.package
    }
  },
  mounted() {
    console.debug('Email', this.$route.query.email)
    this.trackUserMaven('pageview')
    const phoneFieldWidth = this.$refs.phoneField.offsetWidth
    const sheet = document.createElement('style')
    sheet.innerHTML = `.vti__responsive-width {width: ${phoneFieldWidth}px`
    document.body.appendChild(sheet)
    this.$refs.phoneField
      .getElementsByClassName('vti__dropdown-list')[0]
      .classList.add('vti__responsive-width')
  },
  methods: {
    ...mapActions(['fetchProfile']),

    executeRecaptcha() {
      if (!this.account.firstname) {
        this.alertMessage('Please enter your first name', 'error')
        return
      }
      if (!this.account.lastname) {
        this.alertMessage('Please enter your last name', 'error')
        return
      }
      if (!this.account.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.account.email.toLowerCase()
        )
      ) {
        this.alertMessage('Please enter a valid email address', 'error')
        return
      }
      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }
      if (this.password_state !== '') {
        this.alertMessage(this.password_strength_message, 'error')
        return
      }
      if (!this.account.business_type) {
        this.alertMessage('Please select a business type', 'error')
        return
      }
      this.$refs.recaptcha.execute()
    },

    phoneInput(str, { number, isValid, country }) {
      // eslint-disable-next-line no-useless-escape
      const regex = /^[\+\d]?(?:[\d.\s()]*)$/
      if (!regex.test(number.input)) {
        this.isValidPhone = false
      }

      this.phoneValidate({ number, isValid, country })
    },
    phoneValidate({ number, isValid, country }) {
      this.isValidPhone = isValid
      // eslint-disable-next-line no-useless-escape
      const regex = /^[\+\d]?(?:[\d.\s()]*)$/
      if (!regex.test(number.input)) {
        this.isValidPhone = false
      }
    },

    onCaptchaExpired: function () {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    onCaptchaError: function (error) {
      console.error('CaptchaError', error)
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    async createAccount(recaptchaToken) {
      // console.debug('here', VueCookie.get('_fprom_tid', { domain: '.contentstudio.io' }))
      if (this.password_state === '') {
        this.registerLoader = true
        // this.account['fp_ref_id'] = VueCookie.get('_fprom_tid')
        this.account.fp_tid = VueCookie.get('_fprom_tid')
        this.account.captcha_code = recaptchaToken

        // if (this.$route.query.fpr) {
        //   this.account['fp_ref_id'] = this.$route.query.fpr
        // }

        this.$refs.recaptcha.reset()

        const response = await this.$http
          .post(registerURL, this.account)
          .then((response) => {
            if (response.data.status) {
              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
            } else {
              this.registerLoader = false
              this.alertMessage(response.data.message, 'error')
            }
            return response
          })
          .catch((response) => {
            this.registerLoader = false
            this.alertMessage(
              'Something went wrong! Please try again.',
              'error'
            )
            return response
          })

        if (response && response.data.status) {
          await this.fetchProfile()

          await this.Plan()
          // await this.identify()

          await this.trackSignUpEvent(
            response.data.user,
            response.data.user.subscription_id
          )

          // this.$store.dispatch('trackEvent', { event: 'signed_up' })
          this.fetchWorkspaces()

          // await this.trackUserMaven('signed_up')

          this.$router.push({ name: 'email_verification' })
        }
        this.registerLoader = false
      } else {
        this.registerLoader = false
        this.alertMessage(this.password_strength_message, 'error')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-form-container .auth-form__social-field {
  margin-top: 2.3rem;
  text-align: center;
}
</style>
