<template>
  <div class="topics-top-pane">
    <div class="topics-top-pane__inner">
      <div class="topics-top-pane__inner__left_section">
        <div class="topics-top-pane__inner__left_section__inner">
          <h3>
            <template v-if="getTopicType === 'curated'">
              Curated Topic
            </template>
            <template v-else-if="getTopicType === 'custom_topic'">
              Topic
            </template>
            <template v-else-if="getTopicType === 'favorite'">
              Favorite
            </template>
            <template v-else-if="getTopicType === 'archived'">
              Archived
            </template>
          </h3>
          <template v-if="getTopicText">
            <h2>{{ getTopicText }}</h2>
          </template>
        </div>
        <div class="topics-top-pane__inner__left_section__follow_container">
          <button
            v-if="displayFollowButton"
            class="btn topics-top-pane__inner__left_section__follow_button"
            @click.prevent="
              followCuratedTopic({ topic: getTopicsFilters.topic })
            "
          >
            <span>FOLLOW</span>
          </button>
        </div>
      </div>
      <template
        v-if="
          (getTopicType === 'curated' || getTopicType === 'custom_topic') &&
          getTopicContentType === 'article' &&
          !getTrendingStories
        "
      >
        <div class="topics-top-pane__inner__right_section">
          <div class="topics-top-pane__inner__right_section__content_types">
            <div
              class="topics-top-pane__inner__right_section__content_types__buttons"
            >
              <a
                href="javascript:;"
                :class="{ active: getTopicModule === 'content' }"
                @click.prevent="changeTopicModule('content')"
              >
                <i class="icon-Content-Feed_icon"></i>
                <span>Content Feed</span>
              </a>
              <a
                href="javascript:;"
                :class="{ active: getTopicModule === 'insights' }"
                @click.prevent="changeTopicModule('insights')"
              >
                <i class="icon-Insights_icon"></i>
                <span>Insights</span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters([
      'getProfile',
      'getTopicType',
      'getTopicText',
      'getTopicModule',
      'getTopicsFilters',
      'getMyCuratedTopicsList'
    ]),
    displayFollowButton () {
      if (this.$route.params.module) {
        if (
          this.$route.params.module === 'curated' &&
          this.$route.params.topicName
        ) {
          if (this.getMyCuratedTopicsList === 0) return false
          const list = this.getMyCuratedTopicsList.filter((item) => {
            console.log(item)
            if (item.name === this.$route.params.topicName) return true
          })
          if (list.length === 0) return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['SET_TOPIC_MODULE', 'SET_TOPICS_DATE_RANGE']),
    ...mapActions(['followCuratedTopic']),
    changeTopicModule (type, view = 'topic_view') {
      this.SET_TOPIC_MODULE(type)
      switch (type) {
        case 'insights':
          this.SET_TOPICS_DATE_RANGE({
            label: 'Last 90 days',
            value:
              moment.utc().subtract(90, 'day').format('YYYY-MM-DDTHH:mm:ss') +
              ' - ' +
              moment.utc().format('YYYY-MM-DDTHH:mm:ss')
          })
          if (!this.isInsightsAllowed()) {
            console.log('Insights module...')
            if (this.$route.name === 'search_web') {
              return
            }
            this.$router.push({
              name: 'topics_insights',
              params: {
                module: this.$route.params.module,
                topicId: this.$route.params.topicId
              },
              meta: { reuse: true }
            })
            return
          }

          if (view === 'topic_view') {
            // this.$store.dispatch('trackEvent', { event: 'insights_unlocked' })
            this.$router.push({
              name: 'topics_insights',
              params: {
                module: this.$route.params.module,
                topicId: this.$route.params.topicId
              },
              meta: { reuse: true }
            })
          }
          break
        case 'content':
          this.SET_TOPICS_DATE_RANGE({
            label: 'Last 30 days',
            value:
              moment.utc().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
              ' - ' +
              moment.utc().format('YYYY-MM-DDTHH:mm:ss')
          })
          if (view === 'topic_view') {
            this.$router.push({
              name: 'topics_web',
              params: {
                module: this.$route.params.module,
                topicId: this.$route.params.topicId
              },
              meta: { reuse: true }
            })
          }
      }
    }
  }
}
</script>
