<template>
  <div id="linkedin_tab">
    <div class="linkedin_post_preview">
      <div class="linkedin_post_preview__head">
        <div class="linkedin_post_preview__head__profile_picture">
          <template v-if="planner">
            <div
              class="linkedin_post_preview__head__profile_picture__picture_block"
            >
              <img
                v-if="linkedinImage(data.account)"
                :src="linkedinImage(data.account)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <img
                v-else
                src="../../../../../../assets/img/profile_default.svg"
                alt=""
              />
            </div>
            <div
              class="linkedin_post_preview__head__profile_picture__text_block"
            >
              <p class="text">{{ data.account.name }}</p>
              <!-- <p class="sub_text">{{getPreviewAccount.linkedin.headline}}</p> -->
            </div>
          </template>
          <template v-else>
            <div
              class="linkedin_post_preview__head__profile_picture__picture_block"
            >
              <img
                v-if="linkedinImage(getPreviewAccount.linkedin)"
                :src="linkedinImage(getPreviewAccount.linkedin)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <img
                v-else
                src="../../../../../../assets/img/profile_default.svg"
                alt=""
              />
            </div>

            <div
              class="linkedin_post_preview__head__profile_picture__text_block"
            >
              <p class="text">{{ getPreviewAccount.linkedin.name }}</p>
              <p class="sub_text">{{ getPreviewAccount.linkedin.headline }}</p>
            </div>
          </template>
        </div>
      </div>

      <div class="linkedin_post_preview__preview_desc">
        <p
          v-html="processSharingPreviewDesciption(details.message, 'Linkedin')"
        ></p>
      </div>

      <div class="preview_link" v-if="details.url">
        <clip-loader
          :color="'#ffffff'"
          :size="'16px'"
          v-if="
            (getCommonBoxOptions.status &&
              getPublishLoaders.fetchCommonSharingPreview) ||
            getPublishLoaders.fetchLinkedinSharingPreview
          "
        ></clip-loader>
        <template v-else>
          <div
            class="img"
            v-if="details.image && details.image.length > 0"
            :style="{
              'background-image':
                'url(' + getResizedImageURL(details.image[0], 500, 0) + ')',
            }"
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
          ></div>

          <div class="link_detail">
            <h3>{{ details.title }}</h3>

            <p class="link">{{ details.website }}</p>
          </div>
        </template>
      </div>

      <template v-else-if="details.video && details.video.link">
        <div
          class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
        >
          <div
            class="img"
            :style="{
              'background-image':
                'url(' + getValidThumbnail(details.video.thumbnail) + ')',
            }"
          >
            <div class="linkedin_vid_icon vid_icon">
              <span class="icon"></span>
            </div>
          </div>
        </div>
      </template>

      <div
        v-else-if="details.image.length > 0"
        class="linkedin_post_preview__preview_images"
      >
        <!--..........for single image...............-->
        <img
          v-if="details.image.length === 1"
          class="single_image"
          :src="details.image[0]"
          alt=""
        />

        <!--..........for two images...............-->

        <div
          v-else-if="details.image.length === 2"
          class="multiple_images two_images"
        >
          <div
            class="img"
            v-for="(image, index) in details.image"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>

        <!--..........for three images...............-->

        <div
          v-else-if="details.image.length === 3"
          class="multiple_images three_images"
        >
          <div
            class="img"
            v-for="(image, index) in details.image"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>

        <!--..........for  more than images...............-->

        <div v-else class="multiple_images more_than_three">
          <div
            :key="`facebook_preview_multiple_${index}`"
            v-for="(image, index) in details.image"
          >
            <div
              class="img"
              v-if="index < 4"
              :style="{ 'background-image': 'url(' + image + ')' }"
            >
              <div
                class="preview_more_images"
                v-if="details.image.length > 4 && index === 3"
              >
                +{{ details.image.length - 4 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rection_icons facebook_r">
        <span><i class="far fa-thumbs-up"></i> Like </span>
        <span><i class="far fa-comment-alt"></i> Comments </span>
        <span><i class="fas fa-share"></i> Share </span>
      </div>

      <template v-if="planner">
        <!-- <div v-if="planner" class="comments-status-box"> <p class="ml-2" v-html="data.response"></p> </div> -->
        <div class="first-comment-preview">
          <div class="first-comment-preview__img">
            <img
              :src="linkedinImage(data.account)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div
            class="first-comment-preview__text"
            v-html="n2br(data.message)"
          ></div
          ><br />
        </div>
        <div v-if="data.commentResponse" class="comments-status-box">
          <p class="ml-2" v-html="data.commentResponse"></p>
        </div>
      </template>
      <template v-else>
        <div
          class="first-comment-preview"
          v-if="
            getSocialSharingFirstComment.has_first_comment &&
            getSocialSharingFirstComment.first_comment_message.trim().length &&
            hasEnableFirstComment(getPreviewAccount.linkedin.linkedin_id) &&
            !planner
          "
        >
          <div class="first-comment-preview__img">
            <img
              :src="linkedinImage(getPreviewAccount.linkedin)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div
            class="first-comment-preview__text"
            v-html="n2br(getSocialSharingFirstComment.first_comment_message)"
          >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'

export default {
  props: ['details', 'planner', 'data'],
  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    hasEnableFirstComment (id) {
      return (
        this.getSocialSharingFirstComment.first_comment_accounts &&
        this.getSocialSharingFirstComment.first_comment_accounts.includes(id)
      )
    },
    getValidThumbnail (selectedThumb) {
      // Getting list of valid thumnbnails for insta
      let thumbnailsList = [...this.details.video.thumbnails_suggestions]
      thumbnailsList = thumbnailsList.filter((thumbnail) => {
        if (!thumbnail.includes('contentstudio-media-library-nearline')) {
          return thumbnail
        }
      })

      if (thumbnailsList.length) {
        // check if is the thumbnail is custom
        if (!selectedThumb.includes('contentstudio-media-library-nearline')) {
          return selectedThumb
        } else {
          return thumbnailsList[0]
        }
      } else {
        return selectedThumb
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getSocialSharingFirstComment'
    ])
  }
}
</script>
