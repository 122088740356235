<template>
  <div>
    <div
        class="color-border shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none rounded-lg bg-cs-light-gray flex flex-col">
      <input
          v-model="input"
          class="border-0 py-2 px-3 rounded-xl bg-cs-light-gray w-full flex-1"
          placeholder="YouTube video tags"
          type="text"
          @keypress.enter.prevent="tagFromInput"
          @keydown.delete="removeLastTag"
      />
      <div v-if="videoTags.length" class="relative flex flex-wrap items-center mt-1">
        <span
            v-for="(badge, index) in videoTags"
            :key="index"
            class="text-xs font-semibold border rounded-full bg-cs-primary text-white p-1 ml-0.5 mt-0.5"
        >
          <span v-html="badge" class="mx-1"></span>
          <i
              v-tooltip="'Remove'"
              class="far fa-close cursor-pointer"
              href="#"
              @click.prevent="removeTag(index)"
          ></i>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import CstInputFields from "@ui/Input/CstInputFields";
import {social} from "@src/modules/publish/store/states/mutation-types";
import {mapGetters} from "vuex";

export default {
  name: "YoutubeTags",
  components: {CstInputFields},
  props: {
    videoTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      existingTags: {},
      tags: [],
      input: ''
    }
  },
  watch: {
    tags() {
      // this.$store.commit(social.SET_YOUTUBE_TAGS, this.tags)
    }
  },
  created() {
    // this.tags = this.getYoutubeOptions.tags
  },
  computed: {
    ...mapGetters(['getYoutubeOptions'])
  },
  methods: {
    tagFromInput(e) {
      let text = this.input.trim()
      // If the new tag is not an empty string
      if (text.length) {
        this.input = ''
        // Determine the tag's slug and text depending on if the tag exists
        // on the site already or not
        let slug = this.makeSlug(text)
        const existingTag = this.existingTags[slug]
        slug = existingTag ? slug : text
        text = existingTag || text
        this.addTag(slug, text)
      }
    },
    makeSlug(value) {
      return value.toLowerCase().replace(/\s/g, '-')
    },
    addTag(slug, text) {
      const searchSlug = this.makeSlug(slug)
      const found = this.videoTags.find((value) => {
        return searchSlug == this.makeSlug(value)
      })
      if (!found) {
        this.videoTags.push(slug)
      }
      // this.$emit('addedTag')
    },
    removeLastTag(e) {
      if (!this.input.length) {
        this.removeTag(this.videoTags.length - 1)
      }
    },
    removeTag(index) {
      this.videoTags.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
