<template>
  <div class="cst-editor-media-box px-3 py-3 flex justify-between items-start">
    <draggable
      v-model="media_list"
      class="cst-editor-media-box__draggable w-full"
    >
      <transition-group>
        <template v-for="(asset, index) in media_list">
          <div
            :key="index"
            class="flex"
            :class="{ 'justify-between w-full': type === 'video' }"
          >
            <v-popover
              placement="top-start"
              trigger="hover"
              open-group="fileInfo"
            >
              <div :class="{ 'w-50': type === 'video' }">
                <div
                  class="cst-editor-media-box__image w-40 h-36 block bg-white rounded overflow-hidden border border-gray-200 mr-2.5 relative z-0"
                >
                  <img
                    v-if="asset"
                    class="w-full h-full object-fit-cover cursor-pointer"
                    :src="
                      asset.thumbnail
                        ? asset.thumbnail
                        : asset.href
                        ? asset.href
                        : asset
                    "
                    width="150"
                    height="140"
                    alt="img"
                    @click="onClickImage(asset)"
                  />
                  <div
                    v-if="type === 'video'"
                    class="text-white border rounded-full p-2 flex items-center justify-center bg-[#0000007a] cursor-pointer absolute top-[33%] left-[40%]"
                    @click="onClickImage(asset)"
                  >
                    <i class="cs-play"></i>
                  </div>
                  <div
                    v-if="
                      (updateImageLoader.status &&
                        updateImageLoader.position === index) ||
                      (scalingLoader.status && scalingLoader.position === index)
                    "
                    class="absolute flex z-99 w-full h-full top-0 items-center justify-center bg-white opacity-60"
                  >
                    <clip-loader
                      class="spinner ml-2"
                      :color="'#1e40af'"
                      :size="'20px'"
                    ></clip-loader>
                  </div>

                  <div
                    class="cst-editor-media-box__header flex justify-between items-center p-2 absolute top-0 w-full"
                  >
                    <div>
                      <v-popover
                        popover-class="tooltip-danger tooltip-danger--clickable"
                        trigger="click"
                        :disabled="hideTooltip"
                        open-group="errors"
                      >
                        <i
                          v-if="
                            getImageVideoError(asset) &&
                            getImageVideoError(asset).length
                          "
                          :ref="`image-video-error-${index}`"
                          class="far fa-exclamation-circle flex items-center justify-center rounded-full p-1 w-5 h-5 bg-red-500 text-white cursor-pointer shadow-md"
                          @click="hideTooltip = false"
                        ></i>

                        <template slot="popover">
                          <div>
                            <ul>
                              <li
                                v-for="(item, ind) in getImageVideoError(asset)"
                                :key="`error_${ind}`"
                              >
                                <span
                                  @click="
                                    onClickError($event, item, asset, index)
                                  "
                                  v-html="item.text"
                                ></span>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </v-popover>
                    </div>
                    <div>
                      <i
                        class="cs-cross cross_icon text-cs-white cursor-pointer text-xs bg-black-400 p-0.5 rounded-full"
                        @click="$emit('remove', asset, type)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="cst-editor-media-box__footer flex justify-between items-center p-2 absolute bottom-0 w-full"
                  >
                    <div
                      class="cst-editor-media-box__footer-title font-bold text-white whitespace-nowrap text-sm"
                    >
                      {{ getFileTitle(asset) }}
                    </div>
                    <div
                      class="cst-editor-media-box__footer-buttons flex items-center"
                    >
                      <v-popover
                        v-if="
                          type === 'image' &&
                          ((accountType === 'common' &&
                            accountsData.accountSelection.twitter.length) ||
                            accountType === 'twitter')
                        "
                        placement="top-start"
                        trigger="hover"
                        open-group="fileOption"
                      >
                        <i
                          class="cursor-pointer p-2 w-7 h-7 flex items-center justify-center bg-white hover:bg-gray-100 rounded-full font-semibold not-italic whitespace-nowrap mr-1"
                          @click.capture="triggerAltTextModal(asset, index)"
                          >ALT</i
                        >

                        <template slot="popover">
                          <div>{{ 'Add Media Description/ALT Text' }}</div>
                        </template>
                      </v-popover>

                      <v-popover
                        v-if="type === 'image'"
                        placement="top-start"
                        trigger="hover"
                        open-group="fileOption"
                      >
                        <i
                          class="icon-edit-cs cursor-pointer p-2 w-7 h-7 flex items-center justify-center rounded-full bg-white hover:bg-gray-100"
                          @click="triggerEditImageModal(asset, index)"
                        ></i>

                        <template slot="popover">
                          <div>Edit Image </div>
                        </template>
                      </v-popover>

                      <v-popover
                        v-if="type === 'video'"
                        placement="top-start"
                        trigger="hover"
                        open-group="fileOption"
                      >
                        <i
                          class="icon-edit-cs cursor-pointer p-2 w-7 h-7 flex items-center justify-center rounded-full bg-white hover:bg-gray-100"
                          @click="handleEditMedia(asset, index)"
                        ></i>

                        <template slot="popover">
                          <div>Edit Video Title </div>
                        </template>
                      </v-popover>

                      <v-popover
                        v-if="
                          type === 'image' &&
                          (accountType === 'common' ||
                            accountType === 'instagram') &&
                          accountsData.accountSelection.instagram.length
                        "
                        placement="top-start"
                        trigger="hover"
                        open-group="fileOption"
                      >
                        <i
                          class="fas fa-at cursor-pointer p-2 w-7 h-7 flex items-center justify-center rounded-full ml-1"
                          :class="{
                            'bg-gray-500': instaPostingMethod !== 'api',
                            'bg-white hover:bg-gray-100':
                              instaPostingMethod === 'api',
                          }"
                          @click.capture="triggerProfileTagingModal(asset)"
                        ></i>

                        <template slot="popover">
                          <div
                            >{{
                              instaPostingMethod === 'api'
                                ? 'Add Instagram Tags'
                                : 'Tagging user in only available with Instagram API'
                            }}
                          </div>
                        </template>
                      </v-popover>
                    </div>
                  </div>
                </div>
              </div>

              <template slot="popover">
                <div v-html="getFileTooltip(asset)"></div>
              </template>
            </v-popover>
            <div v-if="type === 'video' && hasCustomThumbnail">
              <div
                class="h-36 border border-gray-200 border-dashed hover:bg-cs-foggray rounded-lg inline-flex p-1 max-w-md"
              >
                <div
                  class="w-36 flex flex-1 flex-shrink-0 flex-column space-y-2 mr-1 last:mr-0 rounded-lg items-center justify-center relative"
                  :class="
                    uploadingThumbnail ? 'cursor-not-allowed' : 'cursor-pointer'
                  "
                  @click="() => onClickCustomThumbnail(asset)"
                >
                  <div
                    class="w-36 flex flex-column space-y-2 items-center justify-center"
                  >
                    <i class="far fa-cloud-upload text-lg"></i>
                    <span class="text-xs font-semibold">Custom Thumbnail</span>
                  </div>
                </div>
                <!--              <div-->
                <!--                v-for="(suggestion, k) in asset.thumbnails_suggestions"-->
                <!--                :key="k"-->
                <!--                class="flex-1 flex-shrink-0 mr-1 last:mr-0 hover:border-1"-->
                <!--              >-->
                <!--                <img-->
                <!--                  class="w-36 h-full object-fit-cover cursor-pointer"-->
                <!--                  :src="suggestion"-->
                <!--                  alt="img"-->
                <!--                  @click="onChangeThumbnailSuggestion(suggestion)"-->
                <!--                />-->
                <!--              </div>-->
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="
            mediaLoader ||
            isUploadingLinkImage ||
            (uploadingAiImages && activeTabs === selectedTabs)
          "
          :key="'loaders'"
          draggable="false"
          class="flex pointer-events-none"
        >
          <div
            v-if="type === 'image'"
            class="list_plan_bars uploading_bar w-40 h-36 d-flex justify-content-center flex-column rounded overflow-hidden border border-gray-200 mr-2.5 relative z-0"
          >
            <p v-if="progressBar.numberOfImages > 1" class="desc_text">
              Uploading...({{ progressBar.numberOfImages }})</p
            >
            <div v-if="progressBar.image" class="bar_item">
              <div class="item_inner">
                <div
                  class="limit_add"
                  :style="{ width: progressBar.image + '%' }"
                ></div>
                <div class="limit_exceed"></div>
              </div>
            </div>
            <clip-loader
              v-else-if="getSharingImageUploadLoader(accountType)"
              class="spinner ml-2"
              :color="'#1e40af'"
              :size="'20px'"
            />
            <clip-loader v-else color="#4165ed" :size="'16px'" />
          </div>
          <div
            v-if="type === 'video'"
            class="list_plan_bars uploading_bar w-40 h-36 d-flex justify-content-center flex-column rounded overflow-hidden border border-gray-200 mr-2.5 relative z-0"
          >
            <p v-if="progressBar.video > 90" class="desc_text"> Finishing...</p>
            <p v-else class="desc_text">{{ progressBar.video }}%</p>
            <div v-if="progressBar.video" class="bar_item">
              <div class="item_inner">
                <div
                  class="limit_add"
                  :style="{ width: progressBar.video + '%' }"
                >
                  <span
                    class="text d-block"
                    style="
                      font-size: 0.625rem;
                      line-height: 0.875rem;
                      color: #fff;
                    "
                  >
                  </span>
                </div>
                <div class="limit_exceed"></div>
              </div>
            </div>
            <clip-loader v-else color="#4165ed" :size="'16px'"></clip-loader>
          </div>
        </div>
      </transition-group>
    </draggable>
    <!-- Composer - Image Alt Text -->
    <b-modal
      id="alt-text-modal-new"
      ref="alt-text-modal-new"
      v-model="altTextModal"
      no-close-on-backdrop
      centered
      hide-header
      size="lg"
    >
      <header class="modal-header media-library-modal-header">
        <h3 class="font-weight-bold my-auto">
          Add Media Description/ALT Text
          <!--<a class="beacon ml-2" v-tooltip.top-center="'Learn more about Alt-Texts'"
             href="#">
              <i class="far fa-question-circle"
              ></i>
          </a>-->
        </h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="altTextModal = false"
          >×
        </button>
      </header>

      <b-container fluid class="pt-4 px-4 pb-2">
        <b-row>
          <b-col sm="12"></b-col>
          <b-col sm="3" class="my-auto">
            <b-img
              center
              width="150"
              height="150"
              :src="selectedImageAlt.image"
              alt="Image 1"
            ></b-img>
          </b-col>
          <b-col sm="9">
            <b-textarea
              id="textarea"
              v-model="selectedImageAlt.altText"
              placeholder="Enter Description/ALT-Text"
              rows="6"
              :state="textAreaValidation"
              no-resize
              max-rows="6"
            ></b-textarea>
          </b-col>
          <b-col offset-sm="10" sm="2" class="d-flex justify-content-end">
            <span>{{ altTextAreaCounter() }}/{{ alt_text_twitter_limit }}</span>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer>
        <div class="px-3">
          <p
            >Available for:
            <span style="color: #00acee"
              ><i class="fab fa-twitter-square fa-lg px-1" size="24"></i></span
          ></p>
        </div>
        <b-button
          :disabled="altTextButtonStatus"
          size="lg"
          variant="primary"
          @click="closeAltTextModal($event, selectedImageAlt.index)"
        >
          Save
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
      </template>
    </b-modal>
    <!--Composer - Video title modal-->
    <!--    <b-modal
            id="video-title-modal-v2"
            ref="video-title-modal-v2"
            v-model="videoTitleModal"
            centered
            hide-header
            no-close-on-backdrop
            size="lg"
        >
          <header class="modal-header media-library-modal-header">
            <h3 class="font-weight-bold my-auto text-capitalize">
              Add title for video file
              &lt;!&ndash;<a class="beacon ml-2" v-tooltip.top-center="'Learn more about Alt-Texts'"
                 href="#">
                  <i class="far fa-question-circle"
                  ></i>
              </a>&ndash;&gt;
            </h3>
            <button
                aria-label="Close"
                class="close"
                type="button"
                @click="closeVideoTitleModal"
            >×
            </button
            >
          </header>

          <b-container class="pt-4 px-4 pb-2" fluid>
            <b-row>
              <b-col class="my-auto" sm="3">
                <b-img
                    :src="details.video.thumbnail"
                    alt="Image 1"
                    center
                    height="150"
                    width="150"
                ></b-img>
              </b-col>
              <b-col sm="9">
                <b-textarea
                    id="textarea"
                    v-model="videoTitle"
                    :state="textAreaValidation"
                    max-rows="6"
                    no-resize
                    placeholder="Video Title..."
                    rows="6"
                ></b-textarea>
              </b-col>
              <b-col class="d-flex justify-content-end" offset-sm="10" sm="2">
                <span>{{ videoTitleCharCount }}/{{ videoTitleCharLimit }}</span>
              </b-col>
            </b-row>
          </b-container>

          <template v-slot:modal-footer="{ ok }">
            <div class="px-3">
              <p
              >Available for:
                &lt;!&ndash;<span style="color: #1DA1F2">
                  <i  size="24" class="fab fa-twitter-square fa-lg px-1">

                  </i>
                </span>&ndash;&gt;
                <span style="color: #3b5998">
                  <i class="fab fa-facebook-square fa-lg px-1" size="24"> </i>
                </span>
              </p>
            </div>
            <b-button
                :disabled="videoTitleSaveButtonStatus"
                size="lg"
                variant="primary"
                @click="onClickSaveVideoTitle($event)"
            >
              Save
            </b-button>
            &lt;!&ndash; Emulate built in modal footer ok and cancel button actions &ndash;&gt;
          </template>
        </b-modal>-->
    <div
      v-if="type === 'image' && suggestedImages.length"
      class="overflow-x-auto cs-hr-scroll"
    >
      <div class="h-36 border border-gray-200 rounded-lg flex p-1 max-w-sm">
        <div
          v-for="(suggestion, k) in suggestedImages"
          :key="k"
          class="flex-1 flex-shrink-0 mr-1 last:mr-0 hover:border-1"
        >
          <img
            class="w-36 h-full object-fit-cover cursor-pointer"
            :src="suggestion"
            alt="img"
            @error="$event.target.style.display = 'none'"
            @click="addSuggestedImage(suggestion)"
          />
        </div>
      </div>
    </div>

    <VueEasyLightbox
      :imgs="imgs"
      :visible="visible"
      @hide="handleHide"
    ></VueEasyLightbox>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { socialIntegrationsConfigurations } from '@src/modules/integration/config/api-utils'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import VueEasyLightbox from 'vue-easy-lightbox'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { EventBus } from '@common/lib/event-bus'
import isEmpty from 'is-empty'
import moment from 'moment-timezone'
import { swalAttributes } from '@common/constants/common-attributes'
import proxy from '@common/lib/http-common'
import { resizeImgForInstagramUrl } from '@src/modules/publish/config/api-utils'

export default {
  name: 'EditorMediaBox',
  components: {
    draggable,
    VueEasyLightbox,
  },
  mixins: [MediaHelperMixin, ComposerHelper],
  props: {
    type: {
      type: String,
      default: 'image',
      required: true,
    },
    accountType: {
      type: String,
      default: 'common',
      required: true,
    },
    media: {
      type: Array,
      default: () => [],
      required: true,
    },
    mediaLoader: {
      type: Boolean,
      default: false,
    },
    progressBar: {
      type: Object,
      default: () => {
        return {
          image: 0,
          numberOfImages: 0,
          video: 0,
        }
      },
    },
    hasCustomThumbnail: {
      type: Boolean,
      default: false,
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    videoErrors: {
      type: Array,
      default: () => [],
    },
    suggestedImages: {
      type: Array,
      default: () => [],
    },
    sharingDetails: {
      type: Object,
      default: () => {},
    },
    updateImageLoader: {
      type: Object,
      default: () => {
        return {
          status: false,
          position: -1,
        }
      },
    },
    instaPostingMethod: {
      type: String,
      default: '',
    },
    isUploadingLinkImage: {
      type: Boolean,
      default: false,
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    youtubePostType: {
      type: String,
      default: 'video',
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    selectedTabs: {
      type: String,
      default: '',
    },
    activeTabs: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      media_list: this.media,
      imageErrors: [],
      uploadingThumbnail: false,
      alt_text_twitter_limit: 1000,
      altTextModal: false,
      selectedImageAlt: {
        altText: '',
        image: undefined,
        index: -1,
      },
      visible: false,
      imgs: '',
      isUploadingImage: false,
      scaledImage: '',
      scalingLoader: {
        status: false,
        position: -1,
      },
      hideTooltip: false,
    }
  },
  computed: {
    ...mapGetters([
      'getSocialSharingMediaDetails',
      'getPublishLoaders',
      'getActiveWorkspace',
      'getProfile',
    ]),

    altTextButtonStatus() {
      return this.altTextAreaCounter() > this.alt_text_twitter_limit
    },
    /**
     *  Contextual validation for the alt-text text area.
     */
    textAreaValidation() {
      var resp = null
      if (this.selectedImageAlt.altText !== undefined) {
        resp = this.selectedImageAlt.altText.length <= 1000 ? null : false
      }
      return resp
    },
  },
  watch: {
    media_list: {
      handler(newVal, oldVal) {
        console.debug('EditorMediaBox watch media_list')
        // eslint-disable-next-line no-undef
        if (!_.isEqual(newVal, oldVal)) {
          this.$emit('on-sort', newVal, this.type)
        }
      },
      deep: true,
    },
    media: {
      handler(newVal) {
        this.media_list = newVal
      },
      deep: true,
    },
  },
  mounted() {
    console.debug(
      'EditorMediaBox mounted',
      this.type,
      this.media,
      this.accountType
    )
  },
  methods: {
    /**
     *  Method to confirm the user wants to edit the image
     */
    async triggerEditImageModal(asset, index) {
      if (!this.isEditImageEnabled(asset)) {
        const h = this.$createElement
        const titleVNode = h('div', {
          domProps: {
            innerHTML: 'Are you sure you want to edit this image?',
          },
        })
        const noteVNode = h('div', {
          domProps: {
            innerHTML:
              '<i style="font-size: 0.9rem" class="mt-2">Note: User tags assigned to this image will be removed when editing the image.</i>',
          },
        })

        const res = await this.$bvModal.msgBoxConfirm([titleVNode, noteVNode], {
          title: 'Edit Image',
          ...swalAttributes(),
        })
        if (res) {
          this.handleEditMedia(asset, index)
        }
      } else {
        this.handleEditMedia(asset, index)
      }
    },
    handleEditMedia(asset, index) {
      this.$emit('on-edit', asset, index)
    },
    isEditImageEnabled(imgURL = '') {
      if (
        this.type === 'image' &&
        (this.accountType === 'common' || this.accountType === 'instagram') &&
        this.accountsData.accountSelection.instagram.length
      ) {
        if (!isEmpty(this.sharingDetails.image_tagging_details)) {
          if (
            this.sharingDetails.image_tagging_details[imgURL] &&
            this.sharingDetails.image_tagging_details[imgURL].length > 0
          ) {
            return false
          }
        }
      }

      return true
    },
    getImageVideoError(asset) {
      const errors = []
      const accountSelection = this.accountsData.accountSelection

      if (this.accountType === 'common') {
        for (const channelName in accountSelection) {
          if (accountSelection[channelName].length) {
            if (typeof asset === 'string') {
              // validation check for image
              errors.push(...this.getImageErrors(asset, channelName))
            } else if (typeof asset === 'object') {
              // validation check for videos
              errors.push(...this.getVideoErrors(asset, channelName))
            }
          }
        }
      } else {
        const channelName = this.accountType
        if (accountSelection[channelName].length) {
          if (typeof asset === 'string') {
            // validation check for image
            errors.push(...this.getImageErrors(asset, channelName))
          } else if (typeof asset === 'object') {
            // validation check for videos
            errors.push(...this.getVideoErrors(asset, channelName))
          }
        }
      }
      return errors
    },

    /**
     *  Method to get the image errors
     *  @param {String} asset - Image URL
     *  @param {String} channelName - Channel Name
     */
    getImageErrors(asset, channelName) {
      const errors = []
      const mediaDetails = this.getSocialSharingMediaDetails
      // validation check for images
      if (
        mediaDetails[asset] &&
        mediaDetails[asset].mime_type !== 'image/gif'
      ) {
        if (
          mediaDetails[asset].size >
          socialIntegrationsConfigurations[channelName].validations.image
            .max_size
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .image.max_size_single_image_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .image.max_size_single_image_error,
            })
          }
        }
        if (
          mediaDetails[asset].size <
          socialIntegrationsConfigurations[channelName].validations.image
            .min_size
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .image.min_size_single_image_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .image.min_size_single_image_error,
            })
          }
        }

        if (
          mediaDetails[asset].height <
            socialIntegrationsConfigurations[channelName].validations.image
              .min_height ||
          mediaDetails[asset].width <
            socialIntegrationsConfigurations[channelName].validations.image
              .min_width
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .image.min_dimension_single_image_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .image.min_dimension_single_image_error,
            })
          }
        }

        if (
          mediaDetails[asset].height >
            socialIntegrationsConfigurations[channelName].validations.image
              .max_height ||
          mediaDetails[asset].width >
            socialIntegrationsConfigurations[channelName].validations.image
              .max_width
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .image.max_dimension_single_image_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .image.max_dimension_single_image_error,
            })
          }
        }
      }

      // validation check for gifs
      if (
        mediaDetails[asset] &&
        mediaDetails[asset].mime_type === 'image/gif'
      ) {
        if (
          mediaDetails[asset].size >
          socialIntegrationsConfigurations[channelName].validations.gif.size
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .gif.size_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .gif.size_error,
            })
          }
        }
        if (
          mediaDetails[asset].height >
            socialIntegrationsConfigurations[channelName].validations.gif
              .max_height ||
          mediaDetails[asset].width >
            socialIntegrationsConfigurations[channelName].validations.gif
              .max_width
        ) {
          if (channelName === 'instagram') {
            if (this.instaPostingMethod === 'api') {
              errors.push({
                text: socialIntegrationsConfigurations[channelName].validations
                  .gif.max_dimension_error,
              })
            }
          } else {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .gif.max_dimension_error,
            })
          }
        }
      }

      if (
        channelName === 'instagram' &&
        this.instaPostingMethod === 'api' &&
        mediaDetails[asset] &&
        mediaDetails[asset].zapier &&
        !mediaDetails[asset].zapier.status
      ) {
        errors.push({
          text: socialIntegrationsConfigurations[channelName].validations.image
            .min_dimension_single_image_error,
        })
        errors.push({
          text: socialIntegrationsConfigurations[channelName].validations.image
            .min_dimension_single_image_error_auto_scale,
          type: 'auto_scale',
        })
      }
      return errors
    },

    /**
     *  Method to get the video errors
     *  @param {Object} video - Video Object
     *  @param {String} channelName - Channel Name
     */
    getVideoErrors(video, channelName) {
      const errors = []
      if (
        video.link &&
        video.mime_type === 'video/mp4' &&
        channelName !== 'gmb'
      ) {
        if (
          video.size >
          socialIntegrationsConfigurations[channelName].validations.video.size
        ) {
          if (
            !(
              channelName === 'instagram' &&
              this.instaPostingMethod === 'mobile'
            )
          ) {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .video.size_error,
            })
          }
        }
        if (
          video.duration_seconds >=
          socialIntegrationsConfigurations[channelName].validations.video
            .max_duration
        ) {
          if (
            this.instagramPostType === 'feed' &&
            !(
              channelName === 'instagram' &&
              this.instaPostingMethod === 'mobile'
            )
          ) {
            const currentDuration = video.duration_seconds
            const formatMessage = `mm [minutes]${
              moment.utc(currentDuration * 1000).seconds() > 0
                ? ' [and] ss [seconds]'
                : '.'
            }`
            errors.push({
              text: `${
                socialIntegrationsConfigurations[channelName].validations.video
                  .max_duration_error
              } Your video is ${moment
                .utc(currentDuration * 1000)
                .format(formatMessage)}`,
            })
          }
        }
        if (
          video.duration_seconds >
          socialIntegrationsConfigurations[channelName].validations.video
            .reel_max_duration
        ) {
          if (
            channelName === 'instagram' &&
            this.instaPostingMethod === 'api' &&
            this.instagramPostType !== 'feed'
          ) {
            const currentDuration = video.duration_seconds
            errors.push({
              text: `${socialIntegrationsConfigurations[channelName].validations.video.reel_max_duration_error} Your video is ${currentDuration} seconds. Please reduce the length of this video.`,
            })
          }
        }
        if (
          video.duration_seconds <
          socialIntegrationsConfigurations[channelName].validations.video
            .min_duration
        ) {
          errors.push({
            text: `${socialIntegrationsConfigurations[channelName].validations.video.min_duration_error} Your video is ${video.duration_seconds} seconds.`,
          })
        }
        if (
          channelName === 'instagram' &&
          video.width &&
          video.height &&
          this.instaPostingMethod === 'api' &&
          this.instagramPostType === 'feed'
        ) {
          const aspectRatio = (video.width / video.height).toFixed(2)
          console.log('Video aspect ratio: ', aspectRatio)
          if (
            aspectRatio <
              socialIntegrationsConfigurations[channelName].validations.video
                .min_aspect_ratio ||
            aspectRatio >
              socialIntegrationsConfigurations[channelName].validations.video
                .max_aspect_ratio
          ) {
            errors.push({
              text: socialIntegrationsConfigurations[channelName].validations
                .video.aspect_ratio_error,
            })
          }
        }

        if (channelName === 'facebook' && this.facebookPostType === 'reel') {
          const fbValidations =
            socialIntegrationsConfigurations.facebook.validations
          if (video.width && video.height) {
            if (video.width < fbValidations.video.reel_min_width) {
              errors.push({
                text: fbValidations.video.reel_min_width_error,
              })
            }

            if (video.height < fbValidations.video.reel_min_height) {
              errors.push({
                text: fbValidations.video.reel_min_height_error,
              })
            }

            const aspectRatio = (video.width / video.height).toFixed(2)
            console.debug('Reel aspect ratio: ', aspectRatio)
            if (
              aspectRatio !==
              parseFloat(fbValidations.video.reel_aspect_ratio).toFixed(2)
            ) {
              errors.push({
                text: fbValidations.video.reel_aspect_ratio_error,
              })
            }
          }
          if (video.duration_seconds) {
            if (
              video.duration_seconds > fbValidations.video.reel_max_duration
            ) {
              errors.push({
                text: fbValidations.video.reel_max_duration_error,
              })
            }

            if (
              video.duration_seconds < fbValidations.video.reel_min_duration
            ) {
              errors.push({
                text: fbValidations.video.reel_min_duration_error,
              })
            }
          }
        }
        if (channelName === 'youtube' && this.youtubePostType === 'short') {
          if (video.width && video.height && video.width > video.height) {
            errors.push({
              text: 'Video should be in square or vertical orientation for Youtube shorts',
            })
          }
          if (
            video.duration_seconds &&
            video.duration_seconds >
              socialIntegrationsConfigurations.youtube.validations.video
                .shorts_max_duration
          ) {
            errors.push({
              text: 'Video duration should be less than or equal to 60 seconds for Youtube shorts',
            })
          }
        }
      }
      return errors
    },

    /**
     * onClick Event for the thumbnail input image. Click is disabled if the thumbnail image is uploading.
     */
    onClickCustomThumbnail(asset) {
      console.log(
        'METHOD::onClickCustomThumbnail ~ refs -> ',
        this.$refs['thumbnail-select']
      )

      const videoLink = asset.link
      const suggestedImages = asset.thumbnails_suggestions
      const selectedSuggestion = asset.thumbnail
        ? asset.thumbnail
        : asset.href
        ? asset.href
        : asset

      const disabledStates = {
        suggested:
          suggestedImages.length === 0 && this.hasCustomThumbnail === false,
        frame: this.hasCustomThumbnail === false,
        upload:
          this.hasCustomThumbnail === false || this.accountType === 'instagram',
      }

      EventBus.$emit('custom-thumbnail-modal-show', {
        videoSource: videoLink,
        suggestedImages: suggestedImages,
        selectedSuggestion: selectedSuggestion,
        disabledStates: disabledStates,
        emitter: (mediaURL, duration, type) => {
          this.onChangeThumbnailSuggestion(mediaURL, duration, type)

          EventBus.$emit('custom-thumbnail-modal-hide')
        },
      })
      // if (this.uploadingThumbnail) return
      //
      // document.getElementById('thumbnail-select-' + this.accountType).click()
    },

    /**
     * Event invoked when the user selects an image from the prompt box. The selected image is then set as a thumbnail.
     * @param event
     * @returns {Promise<void>}
     */
    async onChangeThumbnail(event) {
      console.log('METHOD::onChangeThumbnail ~ event -> ', event)
      this.uploadingThumbnail = true

      const selectedFiles = event.target.files
      const media = []
      Array.prototype.forEach.call(selectedFiles, function (file) {
        media.push({
          file: file,
        })
      })

      await this.uploadFilesHelper(
        { media: media },
        (status, message, mediaContainer) => {
          console.log(
            'METHOD:: uploadFilesHelper CALLBACK ~ status, message, media_container -> ',
            status,
            message,
            mediaContainer
          )
          if (status) {
            console.log('THIS ________ ', this)
            this.media[0].thumbnails_suggestions.splice(
              0,
              0,
              mediaContainer[0].link
            ) // inserts the element at 0th index
            this.media[0].thumbnail = mediaContainer[0].link
          }
          this.uploadingThumbnail = false
        }
      )
    },

    /**
     * Invoked when the user clicks on one of the suggested thumbnails.
     * @param suggestion, duration, type
     * @param duration
     * @param type
     */
    onChangeThumbnailSuggestion(suggestion, duration, type) {
      const suggestionUrl = new URL(suggestion)
      if (duration) {
        suggestionUrl.searchParams.set('offset', duration)
      }

      this.media[0].thumbnail = suggestionUrl.href
    },

    triggerProfileTagingModal(asset) {
      if (this.instaPostingMethod === 'api') {
        this.$emit('profile-tagging', asset)
      }
    },

    triggerAltTextModal(image, index) {
      console.log(
        'METHOD::triggerAltTextModal ~ image, index -> ',
        image,
        index
      )
      this.selectedImageAlt.image = image
      this.selectedImageAlt.index = index

      if ('alt_texts' in this.sharingDetails) {
        const alt = this.sharingDetails.alt_texts.find(
          (item) => item.image === this.selectedImageAlt.image
        )
        if (alt) {
          this.selectedImageAlt.altText = alt.alt_text
        } else {
          this.selectedImageAlt.altText = ''
        }
      }

      this.altTextModal = true
    },

    /**
     * Counter for alt-texts
     * @returns {number}
     */
    altTextAreaCounter() {
      var counter = 0
      if (this.selectedImageAlt.altText) {
        counter = this.selectedImageAlt.altText.length
      }
      return counter
    },

    closeAltTextModal($event, imageIndex) {
      console.log(
        'METHOD::closeAltTextModal ~ $event, imageIndex -> ',
        $event,
        imageIndex,
        this.altTextAreaCounter()
      )
      // check for necessary validation.
      if (this.altTextAreaCounter() > 1000) {
        console.log('Twitter alt-text char limit exceeded.')
        return
      }
      // if alt_text obj does not exist in the sharing details? create one
      // check if the data already exist in the alt_text obj
      // if not? then push data in alt text obj
      //
      this.sharingDetails.alt_texts =
        'alt_texts' in this.sharingDetails ? this.sharingDetails.alt_texts : []
      const alt = this.sharingDetails.alt_texts.find(
        (item) => item.image === this.selectedImageAlt.image
      )
      if (alt) {
        alt.alt_text = this.selectedImageAlt.altText
      } else {
        this.sharingDetails.alt_texts.push({
          image: this.selectedImageAlt.image,
          alt_text: this.selectedImageAlt.altText,
        })
      }
      // this.$emit('set-alt', this.selectedImageAlt.image, this.selectedImageAlt.altText, this.selectedImageAlt.index)

      this.altTextModal = false
    },

    getFileTooltip(detail) {
      let file = detail
      const mediaDetails = this.getSocialSharingMediaDetails
      let tooltip = ''
      tooltip += '<div class="flex">'
      if (typeof file !== 'string' && detail?.link) {
        file = detail.link
      }
      if (this.type === 'video') {
        tooltip += detail.title + ''
      }
      if (mediaDetails[file]) {
        if (this.type !== 'video') tooltip += mediaDetails[file]?.name + ' '
        if (mediaDetails[file].size)
          tooltip +=
            "<span class='ml-1'><b>" +
            this.bytesToSize(mediaDetails[file].size) +
            '</b></span>'
        tooltip += '</div>'

        const altText = this.altTextToolTip(file, true)

        if (altText)
          tooltip +=
            "<div class='mt-2 w-100 text-left'><b>Alt Text: </b>" +
            altText +
            '</div>'
      }
      return tooltip
    },

    getFileTitle(file) {
      let title = ''
      if (this.type === 'video') title = file.title ? file.title : file.name
      else {
        const mediaDetails = this.getSocialSharingMediaDetails
        if (mediaDetails[file]) {
          if (mediaDetails[file].name) title = mediaDetails[file].name + ' '
        }
      }
      return title
    },

    /**
     * Because we can't use v-model for the tooltip due to the dynamic structure of the alt-text injection in this.details we have to use a method to return a proposed tooltip text.
     * @param image
     * @param flag
     * @returns {string}
     */
    altTextToolTip: function (image, flag = false) {
      // Old Requirement.
      /* var resp = 'Add Media Description/ALT Text';
                var temp_selected = [];
                if(this.details.alt_texts){
                    temp_selected = this.details.alt_texts.filter(alt_text => alt_text.image == image);
                }
                if(temp_selected.length > 0){
                    resp = temp_selected[temp_selected.length - 1].alt_text.length > 0 ? 'Update Media Description/ALT Text' : resp;

                }

                return resp; */

      let tooltip = 'Add Media Description/ALT Text'
      if (this.sharingDetails.alt_texts !== undefined) {
        const tempSelected = this.sharingDetails.alt_texts.filter(
          (altText) => altText.image === image
        )
        if (tempSelected.length > 0) {
          tooltip =
            tempSelected[tempSelected.length - 1].alt_text === ''
              ? tooltip
              : tempSelected[tempSelected.length - 1].alt_text
          tooltip =
            tooltip.length > 100 ? tooltip.substr(0, 100) + ' ...' : tooltip
        }
      }
      if (flag) {
        if (tooltip !== 'Add Media Description/ALT Text') return tooltip
        else return null
      }
      return tooltip
    },

    /**
     * add suggested image to sharing details.
     * @param image
     */
    async addSuggestedImage(image) {
      this.$emit('add-suggested-image', image, true)
    },

    /**
     * Tooltip for facebook video title.
     * @param video
     * @returns {string|*}
     */
    videoTitleTooltip(video) {
      if (video.title.length > 1) {
        return video.title
      }

      return 'Add Video Title'
    },

    /**
     * image preview (vue-easy-lightbox)
     * @param media
     */
    onClickImage(media) {
      console.log('METHOD::onClickImage ~ img -> ', this.type, media)
      if (media && this.type === 'image') {
        this.imgs = media
        this.visible = true
      } else if (media && this.type === 'video') {
        EventBus.$emit('displayFile', {
          type: this.type,
          media: media.link,
          index: 0,
        })

        this.$bvModal.show('display-file-modal')
      }
    },

    /**
     * Close event for image preview (vue-easy-lightbox)
     */
    handleHide() {
      this.visible = false
    },

    async autoScaleImage(asset, index) {
      this.scalingLoader.status = true
      this.scalingLoader.position = index
      const payload = {
        link: asset,
        workspace_id: this.getActiveWorkspace._id,
        user_id: this.getProfile._id,
      }
      try {
        const response = await proxy.post(resizeImgForInstagramUrl, payload)
        this.scaledImage = response.data.image_link
        const h = this.$createElement
        const titleVNode = h('div', {
          domProps: { innerHTML: 'Auto Scaled Image' },
        })
        const messageNode = h('div', [
          h('b-img', {
            props: {
              src: this.scaledImage,
              center: true,
              fluid: true,
            },
          }),
          // h('p', ['Use this auto-scaled image?']),
        ])
        const res = await this.$bvModal.msgBoxConfirm([messageNode], {
          id: 'auto_scale',
          title: [titleVNode],
          okTitle: 'Apply',
          cancelTitle: 'Cancel',
          hideHeader: false,
          hideHeaderClose: true,
          centered: true,
          titleClass: 'w-full text-xl flex justify-center items-center',
        })
        if (res) {
          this.$emit('add-suggested-image', this.scaledImage, false)
          this.$emit('remove', asset, this.type)
        }
        this.scalingLoader.status = false
        this.scalingLoader.position = -1
      } catch (e) {
        this.scalingLoader.status = false
        this.scalingLoader.position = -1
        console.log(e)
      }
    },
    onClickError(event, item, asset, index) {
      this.hideTooltip = true
      if (event?.target?.localName === 'a' && 'type' in item) {
        this.autoScaleImage(asset, index)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-editor-media-box {
  border-top: 1px solid #dee2e6;

  &__draggable > span {
    display: flex;
    flex-wrap: wrap;
  }

  .list_plan_bars .bar_item .item_inner .limit_add {
    @apply bg-blue-300 rounded;
  }

  &__footer {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(2%, #00000005),
      color-stop(60.22%, rgba(0, 0, 0, 0.71)),
      color-stop(91.17%, rgba(0, 0, 0, 0.82))
    );
    background: linear-gradient(
      to bottom,
      #00000005 2%,
      rgba(0, 0, 0, 0.71) 60.22%,
      rgba(0, 0, 0, 0.82) 91.17%
    );
    z-index: 2;
  }

  &__footer-title {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 134px;
  }

  &__footer-buttons {
    font-size: 0.67rem;
  }
}
</style>
