<template>
  <div class="table_box">
    <table>
      <thead>
        <th>Author Name</th>
        <th class="make-text-center">Total Articles</th>
        <th class="make-text-center">Total Engagement</th>
        <th class="make-text-center">Avg. Engagement</th>
      </thead>
      <tbody>
        <template v-for="(item, index) in orderedList">
          <tr :key="index">
            <td>
              {{ item.key }}
            </td>
            <td class="make-text-center">
              {{ item.doc_count }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.total_interactions.value) || 0 }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.average_interactions.value) || 0 }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import orderBy from 'lodash/orderBy'

export default {
  data () {
    return {
      sortByRow: 'doc_count',
      sortByOrder: 'desc'
    }
  },
  props: {
    top_authors: {}
  },
  computed: {
    orderedList () {
      if (this.top_authors) {
        return orderBy(this.top_authors, [this.sortByRow], [this.sortByOrder])
      } else {
        return this.top_authors
      }
    }
  },
  methods: {
    changeSortOrder (row, order) {
      this.sortByRow = row
      this.sortByOrder = order
    }
  }
}
</script>
