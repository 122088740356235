<template>
  <div class="analytic-competitors-report">
    <div class="analytic-competitors-report__header"
    >Competitors' Analytics
    </div
    >

    <div class="analytic-competitors-report__body">
      <div class="analytic-competitors-report__title">
        Track your competitors on Instagram and compare your performance with
        theirs
      </div>

      <div class="grid-flex analytic-competitors-report__content">
        <div class="cell-md-6 pr-5">
          <p> To keep track of your Instagram rivals, this tool projects: </p>
          <div>
            <ul>
              <li class="bullet">
                Total followers and likes of a page as well as its growth rate
                from the day page gets connected.
              </li>
              <li class="bullet">
                Your competitors’ total posts, posting routine and history
              </li>
              <li class="bullet">
                The top 3 posts with their reactions comments & shares
              </li>
              <li class="bullet">
                The engagement rate with an average of its comments and reactions.
              </li>
              <li class="bullet">
                A layout of post types and audience reactions
              </li>
            </ul>
          </div>

          <p class="mt-5">
            These metrics enable you to effectively gauge your performance
            against your competitors by providing:
          </p>
          <div>
            <ul>
              <li class="bullet">
                Comparison graphs & pie charts of reactions with comments, page
                community growth rates, and posting history
              </li>
              <li class="bullet">
                A leaderboard of your competitors based on total posts,
                engagement rates, and page likes/follows.
              </li>
              <li class="bullet">
                A table showcasing content, fans, and engagement statistics.
              </li>
            </ul>
          </div>
        </div>

        <div class="cell-md-6">
          <AddCompetitorFields
              native="true"
              :activeCompetitor="''"
          />
        </div>
      </div>

      <div class="analytic-competitors-report__loader bg-white z-100" v-if="!modalFlag">
        <!--        <clip-loader-->
        <!--            class="ml-2"-->
        <!--            :color="'#5773fa'"-->
        <!--            :size="'60px'"-->
        <!--        ></clip-loader>-->
        <img
            style="width: 50px"
            :src="loaderImage"
            alt="ContentStudio"
        />
      </div>
    </div>
  </div>
</template>

<script>

import analyticsUtilsMixin from '../../../components/common/analyticsUtilsMixin'
import apiOPs from '../../common/competitors/instagram/api_ops'
import AddCompetitorFields from '../../../views/common/competitors/instagram/AddCompetitorFields'
import {mapGetters} from "vuex";
import {EventBus} from "@common/lib/event-bus";

export default {
  name: "instagramCompetitorReport",
  mixins: [analyticsUtilsMixin, apiOPs],
  computed: {
    ...mapGetters(['getProfile','getActiveWorkspace']),
    connectedAccounts() {
      return this.getPlatformAccounts('instagram').sort((a, b) =>
          a.name > b.name ? 1 : -1
      )
    }
  },
  mounted() {
    EventBus.$on('workspace-changed', () => {
      if(this.modalFlag){
      console.log('ppppp in here')
      this.modalFlag = false
      this.cancelPendingRequest('getCompetitor')
      this.competitorExists()}
    })
    this.modalFlag = false
    this.cancelPendingRequest('getCompetitor')
    this.competitorExists()
  },
  components: {
    AddCompetitorFields
  },
  data() {
    return {
      modalFlag: false,
      requestCancelPromise: {},
      windowFlag: false,
      loaderImage: require('@assets/img/logo/logo-animation.gif')
    }
  },
  methods: {
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
    competitorExists() {
      let comp_data = {}
      let promises = []
      this.cancelPendingRequest('getCompetitor')
      this.connectedAccounts.forEach((ig_acc) => {
        comp_data[ig_acc.instagram_id] = []
        console.log(ig_acc.instagram_id, comp_data)
        let promise = this.getIgCompetitors(ig_acc.instagram_id)
        this.requestCancelPromise['getCompetitor'].push(promise[0])
        promises.push(promise[1])
        promise[1].then((res) => {
          comp_data[ig_acc.instagram_id] = res.data.competitors
        })
      })
      let flag = false
      Promise.all(promises).then(() => {
        Object.keys(comp_data).forEach((obj) => {
          if (comp_data[obj].length > 1 && !flag) {
            this.modalFlag = true
            console.log('ppppp',comp_data,obj)
            this.$router.push({
              name: 'instagramCompetitorOverview',
              params: {accountId: obj}
            })
            flag=true
          }
        })
        if (!this.modalFlag) {
          this.modalFlag = true;
          this.windowFlag = true;
        }
      })

    }
  },
  beforeDestroy() {
    EventBus.$off('workspace-changed')
    this.modalFlag = false
  }
}
</script>

<style scoped>

</style>
