<template>
  <ul
    :class="{ disable_click: getTopicDisabled }"
    class="topics-left-pane-items"
    v-if="
      (getCustomSidebar.favorites.status &&
        getTopicContentType === 'article') ||
      getSidebarCustomization.customization
    "
  >
    <li
      class="topics-left-pane-items__heading"
      v-bind:class="[sibarItemStatus('favorites') ? '' : 'opacity']"
    >
      <i class="title_icon icon-stared_icon"></i>
      <span class="topics-left-pane-items__heading__text">Favorites</span>
      <button
        v-tooltip.top-center="'New Folder'"
        class="topics-left-pane-items__heading__button"
        @click.prevent="addFavoriteFolder()"
        v-if="!getSidebarCustomization.customization"
      >
        <i class="far fa-plus d-block"></i>
      </button>
      <div v-else class="topics-left-pane-items__heading__customization">
        <i
          class="view_icon"
          v-bind:class="[
            sibarItemStatus('favorites') ? 'cs-eye ' : 'cs-eye-close ',
          ]"
          @click.prevent="selectionSibarItem('favorites')"
        >
        </i>
      </div>
    </li>
    <!--:class="{selected_disable: !sibarItemStatus('favorites'), disable_box : getSidebarCustomization}"-->
    <div
      class="topics-left-pane-favorite-folder"
      v-if="!getSidebarCustomization.customization"
    >
      <div
        class="topics-left-pane-favorite-folder__create_input"
        v-if="addFolder && getAddFolderBox"
      >
        <input
          @keyup.enter="saveFolder(folderName)"
          type="text"
          v-model="folderName"
          placeholder="Enter Folder Name"
        />
        <div
          class="topics-left-pane-favorite-folder__create_input__action_buttons"
        >
          <img
            @click.prevent="saveFolder(folderName)"
            class="add_folder"
            src="../../assets/article/check_icon.svg"
            alt="Add Folder"
          />
          <img
            @click.prevent="cancelFolder()"
            class="remove"
            src="../../assets/article/delete_icon.svg"
            alt="remove topic"
          />
        </div>
      </div>
    </div>
    <template v-if="getFolders.length > 0">
      <li
        v-for="(folder, index) in getFolders"
        class="topics-left-pane-items__list"
        v-if="
          getSidebarCustomization.customization ||
          !getCustomSidebar.favorites.options.includes(folder._id)
        "
        v-bind:class="[
          sibarItemStatus('favorites', folder._id) ? '' : 'opacity',
          getSidebarCustomization.customization
            ? 'topics-left-pane-items__list__customization'
            : '',
          folder.edit_state ? 'topics-left-pane-items__is_edit_folder' : '',
          $route.params.folder_id === folder._id ? 'active' : '',
        ]"
      >
        <!--:class="{disable_box : getSidebarCustomization,selected_disable: !sibarItemStatus('favorites',folder._id)}"-->
        <!--active:activeFolder(folder._id) -->

        <!--.........Show when click on edit icon........-->
        <template
          v-if="folder.edit_state && !getSidebarCustomization.customization"
        >
          <div
            class="topics-left-pane-items__list__update_favorite_folder topics-left-pane-favorite-folder"
          >
            <div class="topics-left-pane-favorite-folder__create_input">
              <input
                v-on:keydown="updateFolderKeyDown($event, folder)"
                type="text"
                v-model="folder.newName"
                placeholder="Enter Folder Name"
              />
              <div
                class="topics-left-pane-favorite-folder__create_input__action_buttons"
              >
                <img
                  @click.prevent="updateFolder(folder)"
                  class="add_folder"
                  src="../../assets/article/check_icon.svg"
                  alt="Add Folder"
                />
                <img
                  class="remove"
                  src="../../assets/article/delete_icon.svg"
                  alt="remove topic"
                  @click="cancelFolderAction(folder)"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <router-link
            :to="{
              name: 'discover_topics_favorite',
              params: { folder_id: folder._id },
            }"
            >{{ folder.name }}</router-link
          >
          <div class="t_icon topics-left-pane-items__list__action_buttons">
            <template v-if="!getSidebarCustomization.customization">
              <i
                v-tooltip.top-center="'Edit'"
                @click.prevent="editFolderAction(folder)"
                class="topics-left-pane-items__list__action_buttons__edit icon-edit-cs"
              >
              </i>

              <span class="dropdown dropdown-confirmation">
                <i
                  :id="'dropdown_' + folder._id"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  v-tooltip.top-center="'Remove'"
                  class="icon-delete-cs topics-left-pane-items__list__action_buttons__remove"
                ></i>

                <div
                  :aria-labelledby="'dropdown_' + folder._id"
                  class="dropdown-menu dropdown-confirmation__menu"
                >
                  <div class="dropdown-confirmation__menu__header">
                    Confirmation
                  </div>
                  <div class="dropdown-confirmation__menu__actions">
                    Are you sure?
                    <span
                      class="dropdown-confirmation__menu__actions__delete"
                      @click.prevent="
                        removeFolder(folder._id, folder.name, index)
                      "
                      >Yes</span
                    >
                    <span class="dropdown-confirmation__menu__actions__cancel"
                      >No</span
                    >
                  </div>
                </div>
              </span>
            </template>
            <template v-else>
              <i
                class="view_icon"
                v-bind:class="[
                  sibarItemStatus('favorites', folder._id)
                    ? 'cs-eye '
                    : 'cs-eye-close ',
                ]"
                @click.prevent="selectionSibarItem('favorites', folder._id)"
              >
              </i>
            </template>
          </div>

          <!--<div class="line_animate" :class="{'active animate':getActiveTopicTab == folder._id}"></div>-->
        </template>
      </li>
    </template>
    <template v-else>
      <p class="topics-left-pane-items__no_results"
        >You do not have any favorite folder.</p
      >
    </template>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      addFolder: false,
      folderName: null
    }
  },
  created () {
    if (this.getFolders.length === 0) {
      this.getFavoriteFolders()
    }
  },
  computed: {
    ...mapGetters([
      'getFolders',
      'getAddFolderBox',
      'getActiveTopicTab',
      'getTopicDisabled',
      'getSidebarCustomization',
      'getTopicContentType',
      'getWorkspaces'
    ])
  },
  methods: {
    ...mapActions(['setAddFolderbox', 'setTopic']),
    addFavoriteFolder () {
      this.setAddFolderBox(true)
      this.addFolder = true
    },
    cancelFolder () {
      this.addFolder = false
    },
    cancelFolderAction (folder) {
      console.log('CancelFolderAction', folder)
      this.$set(folder, 'edit_state', false)
      folder.edit_state = false
    },
    editFolderAction (folder) {
      console.log('editFolderAction', folder)
      this.$set(folder, 'edit_state', true)
      this.$set(folder, 'newName', folder.name)
    }
  }
}
</script>
