<template>
  <div class="page_404 d-flex align-items-center justify-content-center">
    <div class="page_inner">
      <img src="../assets/img/common/page_404.svg" alt="" />
      <p>there's nothing here!</p>
      <router-link :to="{ name: 'workspaces' }" class="large_btn btn btn_round"
        >Go to Homepage</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {}
  },
  computed: {},

  watch: {},

  mounted() {},
}
</script>
