<template>
  <div class="articles_list articles_grey_bg">
    <div class="article_inner articles_grey_bg">
      <div
        v-if="getTwitterAccounts.items.length === 0"
        class="no_data_found_content"
      >
        <div class="no_data_found_content_inner">
          <div class="img">
            <img
              src="../../../../../assets/img/no_data_images/no_data_found.svg"
              alt=""
            />
          </div>
          <p
            >To get content from Twitter, you need to connect your Twitter
            account first.</p
          >
          <router-link
            class="mt-3 btn blue_gradient"
            :to="{
              name: 'social',
              hash: '#twitter_tab',
              params: { workspace: getWorkspaces.activeWorkspace.slug },
            }"
          >
            <span class="text">Connect a Twitter account</span>
          </router-link>
        </div>
      </div>

      <template v-else>
        <template v-if="getDiscoveryTwitterLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <twitter></twitter>

          <template
            v-if="getTwitterPaginationLoader && getTweetsContent.length == 0"
          >
            <no-results-found></no-results-found>
          </template>
          <infinite-loading
            v-else
            @infinite="onInfiniteTwitter"
            ref="infiniteLoading"
          >
            <span slot="spinner">
              <beat-loader :color="'#436afff'"></beat-loader>
            </span>

            <span slot="no-more" class="not_found_text">
              <no-results-found :more="true"></no-results-found>
            </span>
            <span slot="no-results">
              <span class="not_found_text">
                <i class="fa fa-frown-o" aria-hidden="true"></i>
                <span v-if="getTweetsContent.length == 0"
                  >No results found for your query.</span
                >
                <span v-else>
                  <no-results-found :more="true"></no-results-found>
                </span>
              </span>
            </span>
          </infinite-loading>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../NoResultsFound'
import { topicTypes } from '../../../store/mutation-types'

const Twitter = () => import('../../common/Twitter')
export default {
  components: {
    InfiniteLoading,
    Twitter,
    NoResultsFound
  },
  computed: {
    ...mapGetters([
      'getTwitterAccounts',
      'getWorkspaces',
      'getDiscoveryTwitterLoader',
      'getTwitterPaginationLoader',
      'getTweetsContent'
    ])
  },
  methods: {
    ...mapActions(['setTwitterContentPage']),
    ...mapMutations([topicTypes.SET_CHANGE_TOPIC_FILTERS]),
    onInfiniteTwitter ($state) {
      console.log(
        'On twitter infinite',
        this.getTwitterScroll,
        this.getTwitterContentPage
      )
      if (this.getTwitterScroll) {
        this.setTwitterContentPage(this.getTwitterContentPage + 1)
        this.getTwitterData($state)
      }
    }
  },
  mounted () {
    if (!this.$route.params.module) {
      this.$router.push({
        name: 'cover_stories',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
    let res
    switch (this.$route.params.module) {
      case 'custom':
        res = this.isCustomTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'custom_topic',
            contentType: 'twitter',
            module: this.getTopicModule
          })
        }
        break
      case 'curated':
        res = this.isCuratedTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'curated',
            contentType: 'twitter',
            module: this.getTopicModule
          })
        }
        break
    }
    this.getTwitterContent()
    // this.discoveryMounted()
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Twitter View:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')
    next()
  }
}
</script>
