import shortener from './states/shortener'

import platforms from './states/platforms/platforms'

import blog from './states/platforms/blog'

// social states
import social from './states/platforms/social/social'
import facebook from './states/platforms/social/facebook'
import twitter from './states/platforms/social/twitter'
import pinterest from './states/platforms/social/pinterest'
import linkedin from './states/platforms/social/linkedin'
import tumblr from './states/platforms/social/tumblr'
import instagram from './states/platforms/social/instagram'
import gmb from './states/platforms/social/gmb'
import youtube from './states/platforms/social/youtube'
import tiktok from './states/platforms/social/tiktok'

// rewriter states
import rewriter from './states/rewriter'
import integrations from './states/integration'

const shortenerStore = {
  modules: {
    shortener: shortener
  }
}

export const socialStore = {
  modules: {
    social: social,
    facebook: facebook,
    twitter: twitter,
    pinterest: pinterest,
    linkedin: linkedin,
    tumblr: tumblr,
    instagram: instagram,
    gmb: gmb,
    youtube: youtube,
    tiktok: tiktok
  }
}
export const platformsStore = {
  modules: {
    platforms: platforms,
    blog: blog,
    social: socialStore
  }
}
export const integrationStore = {
  modules: {
    platforms: platformsStore,
    rewriter: rewriter,
    shortener: shortenerStore,
    integrations: integrations
  }
}
