<template>
  <div class="ds_card_container engagement_follower_block">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Popular Content Types</h2>
          <ul class="ds_item_highlight" id="popular_content_item_highlight">
          </ul>
        </div>
        <div
          class="card_content popular_content_types"
          :class="{ card_content_blur: isZero() }"
        >
          <template v-if="isZero()">
            <div class="no_data_for_chart">{{ noDataMessage }}</div>
          </template>
          <div :class="{ blur_div: isZero() }">
            <highcharts
              :options="popularContent"
              ref="popular_content__chart"
            ></highcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,
      popularContent: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} with <span style="text-transform: capitalize;">{point.category}</span>.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_content_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#418aed', '#6f7fb9'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: [
            'Listicles',
            'How to',
            'Articles',
            'Video',
            'Podcast',
            'Ebook'
          ],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 30,
            borderRadiusTopRight: 30,
            pointPadding: 0,
            groupPadding: 0.42
          }
        },
        series: [
          {
            // type: 'column',
            name: 'Number of Content Items',
            data: [11, 23, 24, 23, 43, 20],
            yAxis: 1
          },
          {
            // type: 'column',
            name: 'Avg Engagement Per Content',
            data: [11, 13, 44, 73, 3, 5]
          }
        ]
      }
    }
  },
  created () {
    if (this.isZero() == false) {
      this.popularContent.series[0].data = this.getInsightsContentTypes.docs
      this.popularContent.series[1].data =
        this.getInsightsContentTypes.engagements

      this.popularContent.xAxis.categories =
        this.getInsightsContentTypes.categories
    }
  },
  methods: {
    isZero () {
      if (
        this.getInsightsContentTypes.engagements.length == 0 &&
        this.getInsightsContentTypes.docs.length == 0 &&
        this.getInsightsContentTypes.categories.length == 0
      ) {
        return true
      }
      return false
    }
  },
  computed: {
    ...mapGetters(['getInsightsContentTypes'])
  }
}
</script>
