<template>
  <div class="modal fade normal_modal video_preview" id="imagePreviewModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Image Preview</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div class="video_box_iframe">
            <div class="modal_inner clear text-center">
              <img class="lazy modal_image" src="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions([])
  }
}
</script>
