import { video } from './mutation-types'

const defaultVideoAutomation = {
  id: '',
  name: '',
  status: 0,
  video: {
    source: 'Youtube',
    keyword: [],
    youtube: {
      type: 'videos',
      sort: 'Relevance',
      channel: '',
      category: 1
    },
    dailymotion: {
      type: 'videos',
      sort: 'recent',
      channel: '',
      category: 'animals'
    }
  },
  prefetch_days_limit: 2,
  remove_posts: false,
  review_content: false,
  next_posting: -1
}

const defaultVideoAutomationTabStatus = {
  first: true,
  second: false,
  third: false,
  fourth: false
}

const defaultVideoAutomationListing = {
  items: [],
  search: '',
  page: 1,
  limit: 20,
  nextPage: false,
  previousPage: false,
  fetchStatus: false
}

export default {
  state: {
    selection: {
      id: '',
      name: '',
      status: 0,
      video: {
        source: 'Youtube',
        keyword: [],
        youtube: {
          type: 'videos',
          sort: 'Relevance',
          channel: '',
          category: 1
        },
        dailymotion: {
          type: 'videos',
          sort: 'recent',
          channel: '',
          category: 'animals'
        }
      },
      prefetch_days_limit: 2,
      remove_posts: false,
      review_content: false,
      next_posting: -1
    },

    tabStatus: JSON.parse(JSON.stringify(defaultVideoAutomationTabStatus)),

    listing: {
      select_all: false,
      selected: [],
      blog: {
        items: [],
        search: '',
        page: 1,
        limit: 20,
        nextPage: false,
        previousPage: false,
        fetchStatus: false
      },
      social: {
        items: [],
        search: '',
        page: 1,
        limit: 20,
        nextPage: false,
        previousPage: false,
        fetchStatus: false
      }
    }
  },
  actions: {
    setVideoAutomationFirstTabStatus ({ commit }, status) {
      commit('setVideoAutomationFirstTabStatus', status)
    },

    setVideoAutomationSecondTabStatus ({ commit }, status) {
      commit('setVideoAutomationSecondTabStatus', status)
    },

    setVideoAutomationThirdTabStatus ({ commit }, status) {
      commit('setVideoAutomationThirdTabStatus', status)
    },

    setVideoAutomation ({ commit }, automation) {
      commit('setVideoAutomation', automation)
    },

    // blogListing

    setVideoBlogAutomationListingItem ({ commit }, items) {
      commit('setVideoBlogAutomationListingItem', items)
    },

    setVideoBlogAutomationListingPage ({ commit }, page) {
      commit('setVideoBlogAutomationListingPage', page)
    },

    setVideoBlogAutomationListingPagination ({ commit }, items) {
      commit('setVideoBlogAutomationListingPagination', items)
    },

    // socialListing

    setVideoSocialAutomationListingItem ({ commit }, items) {
      commit('setVideoSocialAutomationListingItem', items)
    },

    setVideoSocialAutomationListingPage ({ commit }, page) {
      commit('setVideoSocialAutomationListingPage', page)
    },

    setVideoSocialAutomationListingPagination ({ commit }, items) {
      commit('setVideoSocialAutomationListingPagination', items)
    },

    setVideoSelectedAutomations ({ commit }, items) {
      commit('setVideoSelectedAutomations', items)
    },

    setVideoAutomationListingSelectedAll ({ commit }, items) {
      commit('setVideoAutomationListingSelectedAll', items)
    },

    setDefaultVideoAutomationStates ({ commit }) {
      commit('setDefaultVideoAutomationStates')
    }
  },
  mutations: {
    setVideoAutomationFirstTabStatus (state, status) {
      state.tabStatus.first = status
    },

    setVideoAutomationSecondTabStatus (state, status) {
      state.tabStatus.second = status
    },

    setVideoAutomationThirdTabStatus (state, status) {
      state.tabStatus.third = status
    },

    [video.SET_AUTOMATION_FOURTH_TAB_STATUS] (state, status) {
      state.tabStatus.fourth = status
    },

    [video.SET_AUTOMATION_NEXT_POSTING] (state, status) {
      state.selection.next_posting = status
    },

    [video.SET_AUTOMATION_KEYWORD] (state, keyword) {
      state.selection.video.keyword = keyword
    },

    setVideoAutomation (state, automation) {
      if (automation) {
        if (automation.copy) {
          let name = automation.name.trim()
          const lastcharater = name.slice(-1)

          if (isNaN(lastcharater)) {
            name = name + ' 1'
          } else {
            name = name.substring(0, name.length - 1).trim()
            name = name + ' ' + (parseInt(lastcharater) + 1)
          }

          state.selection.id = ''
          state.selection.name = name
        } else {
          state.selection.id = automation._id
          state.selection.name = automation.name
        }

        state.selection.status = automation.status

        state.selection.video = automation.video
        state.selection.prefetch_days_limit = automation.prefetch_days_limit
        state.selection.remove_posts = false
        state.selection.review_content = automation.review_content
        state.selection.next_posting = -1
      } else {
        state.selection = JSON.parse(JSON.stringify(defaultVideoAutomation))
      }

      state.tabStatus = JSON.parse(
        JSON.stringify(defaultVideoAutomationTabStatus)
      )
    },

    setVideoBlogAutomationListingItem (state, items) {
      state.listing.blog.items = items
    },

    setVideoBlogAutomationListingPage (state, page) {
      state.listing.blog.page = page
    },

    setVideoBlogAutomationListingPagination (state, pagination) {
      state.listing.blog.nextPage = pagination.nextPage
      state.listing.blog.previousPage = pagination.previousPage
    },

    setVideoSocialAutomationListingItem (state, items) {
      state.listing.social.items = items
    },

    setVideoSocialAutomationListingPage (state, page) {
      state.listing.social.page = page
    },

    setVideoSocialAutomationListingPagination (state, pagination) {
      state.listing.social.nextPage = pagination.nextPage
      state.listing.social.previousPage = pagination.previousPage
    },

    setVideoAutomationListingSelectedAll (state, status) {
      state.listing.select_all = status
    },

    setVideoSelectedAutomations (state, automations) {
      state.listing.selected = automations
    },

    setDefaultVideoAutomationStates (state) {
      state.selection = JSON.parse(JSON.stringify(defaultVideoAutomation))
      state.tabStatus = JSON.parse(
        JSON.stringify(defaultVideoAutomationTabStatus)
      )
      state.listing = {
        selected_all: false,
        selected: [],
        blog: JSON.parse(JSON.stringify(defaultVideoAutomationListing)),
        social: JSON.parse(JSON.stringify(defaultVideoAutomationListing))
      }
    }
  },
  getters: {
    getVideoAutomationDetails: (state) => {
      return state.selection
    },

    getVideoAutomationTabStatus: (state) => {
      return state.tabStatus
    },

    getVideoAutomationListing: (state) => {
      return state.listing
    },

    getVideoBlogAutomationListing: (state) => {
      return state.listing.blog
    },

    getVideoSocialAutomationListing: (state) => {
      return state.listing.social
    }
  }
}
