var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white_box",on:{"click":function($event){$event.preventDefault();return _vm.EventBus.$emit('pinterest-analytics-preview', _vm.post)}}},[[_c('div',{staticClass:"flex-row d-flex table_body align-items-center"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"user_name"},[_vm._m(0),(_vm.getActiveBoardImage(_vm.post.board_id))?_c('img',{attrs:{"src":_vm.getActiveBoardImage(_vm.post.board_id),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}):_c('img',{attrs:{"src":require("@assets/img/profile_default.svg"),"alt":""}}),_c('div',{staticClass:"right right-text col"},[_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.post.author_name))]),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("publishedDate")(_vm.post.created_at)))])])])]),_c('div',{staticClass:"col-2 py-2"},[(_vm.post.image)?_c('div',{staticClass:"post_image dashboard-post-image",style:({
            backgroundImage:
              'url(' + _vm.getResizedImageURL(_vm.post.image, 325, 0) + ')',
          })}):_c('div',{staticClass:"post_image dashboard-post-image",style:({
            backgroundImage:
              'url(' +
              require('../../assets/img/no_data_images/not-found.png') +
              ')',
          })})]),_c('div',{staticClass:"col-4"},[[_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(_vm.isHashtag(_vm.isLink(_vm.limitTextLength(_vm.post.pin_text, 130))))}})]],2),_c('div',{staticClass:"col-2 pl-5 text-center font-weight-500"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(_vm.post.repins_count)))]),(_vm.post.comments_count)?_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(" "+_vm._s(_vm.$filters.numberToCommas(_vm.post.comments_count))+" ")]):_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(" 0 ")]),_c('div',{staticClass:"col-1 text-center"},[_vm._v(" - ")])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon social_image_icon social_image_icon_pinterest"},[_c('i',{staticClass:"cs-pinterest"})])
}]

export { render, staticRenderFns }