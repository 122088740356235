import { mapActions, mapGetters } from 'vuex'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import { parseDescriptionHtml } from '@common/lib/helper'

const inboxListener = {
  data() {
    return {
      broadcastChannels: [],
      inbox_notification_channel: '',
    }
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getProfile']),
  },
  methods: {
    ...mapActions([]),
    setInboxNotificationBroadcast(inboxNotificationChannel = null) {
      this.removeNotificationSocket(inboxNotificationChannel)
      const pusher = pusherAuthSocketInbox(
        this.getProfile._id,
        this.getProfile.full_name,
        this.getProfile.email
      )

      this.inbox_notification_channel = pusher.subscribe(
        'presence-notification-listener-' + this.getActiveWorkspace._id
      )
      inboxNotificationChannel =
        'channel-inbox-notifications-' +
        this.getActiveWorkspace._id +
        '-' +
        this.getProfile._id
      this.inbox_notification_channel.bind(
        inboxNotificationChannel,
        (notification) => {
          console.log('receiving a notification --> ', inboxNotificationChannel)
          console.log(notification)
          this.addToNotifications(notification)
          if (notification.channels.dashboard) {
            this.SET_CURRENT_NOTIFICATIONS(notification)
            this.alertMessage(
              '<p>' +
                notification.toast_text +
                ' <a onclick="notificationActionPerform()" href="#">View</a></p>',
              'info'
            )
          } else if (notification.user_id === this.getProfile._id) {
            this.SET_CURRENT_NOTIFICATIONS(notification)
            this.alertMessage(
              '<p>' +
                notification.toast_text +
                ' <a onclick="notificationActionPerform()" href="#">View</a></p>',
              'info'
            )
          }

          if (notification.channels.browser) {
            console.log(notification.channels.browser)
            notification.description = this.simplifiedNotificationDescription(
              notification.description
            )
            this.displayBrowserNotifications(notification)
          }
        }
      )
    },
    simplifiedNotificationDescription(description) {
      const message = parseDescriptionHtml(description, 320, false)

      return message
    },
    notificationDescription(description) {
      description = description.replace(/{{/g, '<strong>')
      description = description.replace(/}}/g, '</strong>')
      return description
    },
    removeNotificationSocket(inboxNotificationChannel = null) {
      if (inboxNotificationChannel === null) {
        inboxNotificationChannel =
          'channel-inbox-notifications-' +
          this.getActiveWorkspace._id +
          '-' +
          this.getProfile._id
      }
      if (this.inbox_notification_channel) {
        this.inbox_notification_channel.unbind(inboxNotificationChannel)
        this.inbox_notification_channel.unsubscribe(
          'presence-notification-listener-' + this.getActiveWorkspace._id
        )
      }
    },
  },
  watch: {},
}

export { inboxListener }
