<template>
  <div class="feeds-content-pane">
    <!--for topics -- remove d-none class .......-->
    <div class="feeds-content-pane__section">
      <template v-if="load_posts">
        <div class="pt-3">
          <beat-loader :color="'#436aff'"> </beat-loader>
        </div>
      </template>
      <template v-else>
        <template v-if="!load_collection">
          <div class="feeds-content-pane__section__header">
            <div class="feeds-content-pane__section__header__content">
              <content-name
                :title="collection.name"
                :show_add_sources_button="showAddSourcesButton"
              ></content-name>
            </div>
            <div class="feeds-content-pane__section__header__filters">
              <content-total-results
                :total="total_results"
              ></content-total-results>
              <feed-content-filters
                :total="total_results"
                @reloadFeedDateRange="reloadFeedDateRange"
              ></feed-content-filters>
            </div>
          </div>
        </template>
        <feeder-content-view-slot>
          <template v-for="(post, index) in posts">
            <!--                        <transition-group name="slide-down-grid-item" tag="div">-->

            <div
              class="article-grid-view-container"
              :key="`grid_item_${post.id}`"
              v-if="getFeederPreferences.item_style === 'grid'"
            >
              <feeder-grid-view-item
                :post="post"
                :index="index"
                :route_name="'collection_feed_item'"
              ></feeder-grid-view-item>
            </div>
            <!--                        </transition-group>-->
            <div
              :key="'list_' + post.id"
              class="article-list-view-container"
              v-if="getFeederPreferences.item_style === 'list'"
            >
              <feeder-list-view-item
                :post="post"
                :index="index"
                :route_name="'collection_feed_item'"
              ></feeder-list-view-item>
            </div>
          </template>
          <infinite-loading
            :identifier="infiniteId"
            @infinite="feederGroupById"
            ref="infiniteCollection"
          >
            <span slot="spinner" class="d-block pt-3 pb-4">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <div slot="no-more" class="not_found_text">
              <div class="no_data_found_content" v-if="total_results === 0">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <h2>No more results found</h2>
                  <p>No more results found for your query.</p>
                  <p
                    >Please try a different search string or change search
                    filters.</p
                  >
                </div>
              </div>
            </div>

            <div slot="no-results">
              <!-- <div class="no_data_found_content">
                                 <div class="no_data_found_content_inner">
                                     <div class="img">
                                         <img src="../../../../../app/src/assets/img/no_data_images/no_data_found.svg"
                                              alt="">
                                     </div>
                                     <h2>No more results found</h2>
                                     <p>No more results found for your query.</p>
                                     <p>Please try a different search string or change search filters.</p>
                                 </div>
                             </div>-->
            </div>
          </infinite-loading>
        </feeder-content-view-slot>
      </template>
    </div>
    <template v-if="!load_collection && hasCollectionFeedIdsPresent">
      <feeder-follow-sources-empty-view></feeder-follow-sources-empty-view>
    </template>
    <template v-else-if="!load_collection && state === 'empty_posts'">
      <feeder-follow-sources-empty-view></feeder-follow-sources-empty-view>
    </template>
    <template v-else-if="!load_collection && state === 'empty_feeds'">
      <feeder-follow-sources-empty-view></feeder-follow-sources-empty-view>
    </template>
    <!-- for modals like social share, article preview etc. -->
    <router-view></router-view>
  </div>
</template>
<style lang="less">
.slide-down-grid-item-enter-active,
.slide-down-grid-item-leave-active {
  transition: all 0.3s;
}

/*.slide-down-grid-item-enter !* .list-leave-active below version 2.1.8 *! {
        opacity: 0;
        transform: translateY(30px);
    }*/
.slide-down-grid-item-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import FeederFollowSourcesEmptyView from './FeederFollowSourcesEmptyView'
import ContentName from '../components/content-view/ContentName'
import ContentTotalResults from '../components/content-view/ContentTotalResults'
import FeedContentFilters from '../components/content-view/FeedContentFilters'
import FeederGridViewItem from '../components/content-view/type-of-views/FeederGridViewItem'
import FeederContentViewSlot from '../slots/FeederContentViewSlot'
import InfiniteLoading from 'vue-infinite-loading'
import { feederItemMixin } from '../mixins/feeder-item-mixin'
import {EventBus} from "@common/lib/event-bus";

const FeederListViewItem = () =>
  import('../components/content-view/type-of-views/FeederListViewItem')
export default {
  components: {
    FeederFollowSourcesEmptyView,
    ContentName,
    ContentTotalResults,
    FeederGridViewItem,
    FeederContentViewSlot,
    InfiniteLoading,
    FeederListViewItem,
    FeedContentFilters
  },
  mixins: [feederItemMixin],

  created () {},
  mounted () {
    EventBus.$on('feeder_archive_action', async (value) => {
      console.log('feeder_archive_action', value)
      const res = await this.archiveAction(
        value.post_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
      }
    })
    EventBus.$on('feeder_starred_action', async (value) => {
      console.log('feeder_starred_action', value)
      const res = await this.starredAction(
        value.post_id,
        value.index,
        value.state,
        this.posts
      )
    })
  },
  beforeDestroy () {
    EventBus.$off('feeder_archive_action')
    EventBus.$off('feeder_starred_action')
  },
  data () {
    return {
      collection: {},
      load_posts: false,
      posts: [],
      total_results: 0,
      state: '',
      infiniteState: '',
      infiniteId: +new Date(),
      page: 1,
      load_collection: false
    }
  },
  computed: {
    ...mapGetters(['getFeederGroupList', 'getFeederPreferences']),
    hasCollectionFeedIdsPresent () {
      return (
        this.collection.user_feed_ids &&
        this.collection.user_feed_ids.length === 0
      )
    },
    showAddSourcesButton () {
      if (this.load_collection) return false
      return this.hasCollectionFeedIdsPresent || this.state === 'empty_posts'
    }
  },

  methods: {
    async feederGroupById ($state) {
      this.load_collection = true
      const feed = this.$store.dispatch('fetchFeederGroup', {
        groupId: this.$route.params.collection_id,
        page: this.page,
        order: this.$route.query.order || 'most_shared',
        fromDate:
          this.$route.query.from_date ||
          moment.utc().subtract(90, 'days').format('YYYY-MM-DD'),
        toDate: this.$route.query.to_date || moment.utc().format('YYYY-MM-DD')
      })
      feed
        .then((res) => {
          if (res && res.data) {
            if (res.data.status) {
              this.state = res.data.state
              this.collection = res.data.data.group
              document.title = this.limitTextLength(this.collection.name, 30)
              if (res.data.data.hasOwnProperty('posts')) {
                if (
                  res.data.data.posts.hits &&
                  res.data.data.posts.hits.length
                ) {
                  if (this.page === 1) {
                    this.posts = res.data.data.posts.hits
                  } else {
                    this.posts = this.posts.concat(res.data.data.posts.hits)
                  }
                  if ($state) $state.loaded()
                  this.total_results = res.data.data.posts.total
                } else {
                  console.log('no hits, set to complete')
                  if ($state) $state.complete()
                }
                this.page += 1
              } else {
                console.log('no posts, set to complete')
                if ($state) $state.complete()
              }
            } else {
              if ($state) $state.complete()
            }
          }
          this.load_collection = false
        })
        .catch((err) => {
          $state.complete()
          this.load_collection = false
        })
    },
    reloadFeedDateRange () {
      console.log('event emitted for feed date range...')
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  },
  watch: {
    '$route.params.collection_id' (value) {
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    },
    '$route.query.order' (value) {
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  }
}
</script>
