<template>
  <b-modal
    id="socialApprovalModal"
    ref="socialApprovalModal"
    modal-class="socialApprovalModal"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Send to Approval</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('socialApprovalModal')"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <div class="social_approve_container">
<!--        <div class="social_approve_container__post_left_block">-->
<!--          <SocialPreview type="approval"></SocialPreview>-->
<!--        </div>-->

        <div class="social_approve_container__post_selection_center">
          <div>
            <div v-if="false" class="icon_tabs d-none">
              <ul class="tab_ul tab_ul_2">
                <li class="tab_li active">
                  <a
                    class="tab_a gray_dark active_gradient"
                    href="javascript:;"
                  >
                    <span>Workflows</span>
                  </a>
                </li>
                <li class="tab_li">
                  <a
                    class="tab_a gray_dark active_gradient"
                    href="javascript:;"
                  >
                    <span>People</span>
                  </a>
                </li>
              </ul>
            </div>

            <h5>Select Approvers</h5>

            <div
              v-if="
                getWorkspaces.activeWorkspace.members &&
                getWorkspaces.activeWorkspace.members.length > 4
              "
              class="search_input"
            >
              <div class="search_input__search_inner">
                <input
                  v-model="memberSearch"
                  type="text"
                  placeholder='Search by name e.g "John"'
                />
              </div>
            </div>
          </div>

          <div class="selection_list">
            <div
              v-for="member in fetchFilteredMembers"
              v-if="member.status === 'joined' && member.user"
              class="profile_selection"
            >
              <input
                :id="'approval_member_' + member._id"
                v-model="getApprovalStages.members"
                type="checkbox"
                :disabled="isMemberApprovalStatusApprove(member.user._id)"
                :value="member.user._id"
              />
              <label
                :for="'approval_member_' + member._id"
                class="checkbox_right with_check_icon"
                data-cy="post-approver"
              >
                <div class="profile_picture">
                  <div class="picture_block picture_block_background">
                    <div
                      v-if="member.user.image"
                      class="img"
                      :style="{ background: 'url(' + member.user.image + ')' }"
                    ></div>
                    <div
                      v-else
                      class="img"
                      style="
                        background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                      "
                    ></div>
                  </div>
                  <div class="text_block">
                    <p v-if=" member.user.firstname &&  member.user.lastname" class="text">{{ member.user.firstname }} {{ member.user.lastname }}</p>
                    <p v-else class="text">{{ member.user.email }}</p>
                    <p
                      class="sub_text role_text"
                      :class="getRoleClass(member.role)"
                      >{{ getMutatedUserRole(member.role) }}</p
                    >
                  </div>
                </div>
              </label>
            </div>

            <div v-if="fetchFilteredMembers.length < 1"> No member found. </div>

            <div v-if="false" class="post_selection">
              <div class="box_inner">
                <div class="profile_picture">
                  <div class="picture_block picture_block_background">
                    <div
                      class="img"
                      style="
                        background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                      "
                    ></div>
                  </div>
                  <div class="ml-0 text_block">
                    <p class="text">Test Account</p>
                    <p class="sub_text">2 round</p>
                  </div>
                </div>
                <div class="users_list">
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="social_approve_container__post_selected_right">
          <div class="selection_list">
            <template
              v-if="
                getApprovalStages.approvers &&
                getApprovalStages.approvers.length === 0
              "
            >
              <img
                class="workflow-approval-img"
                src="@assets/img/composer/workflow-approval.png"
                alt=""
              />
            </template>
            <template v-for="approver in getApprovalStages.approvers" v-else>
              <approval-member
                :member="getMember(approver.user_id)"
                :approver="approver"
              ></approval-member>
            </template>
            <div v-if="false" class="post_selection">
              <div class="box_inner">
                <div class="profile_picture">
                  <div class="picture_block picture_block_background">
                    <!--<i class="fab fa-facebook-f"></i>-->
                    <div
                      class="img"
                      style="
                        background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                      "
                    ></div>
                  </div>
                  <div class="ml-0 text_block">
                    <p class="text">Test Account</p>
                    <p class="sub_text">2 round</p>
                  </div>
                </div>
                <div class="users_list">
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img"
                        style="
                          background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom_content">
            <template v-if="getApprovalStages.approvers.length > 1">
              <div class="radio_input_image">
                <input
                  id="autoScheduleEveryone"
                  v-model="getApprovalStages.approve_option"
                  type="radio"
                  value="everyone"
                  name="autoScheduleRadio"
                />
                <label for="autoScheduleEveryone" class="radio_left"
                  >Post needs approval from everyone</label
                >
              </div>
              <div class="clearifx"></div>
              <div class="radio_input_image">
                <input
                  id="autoScheduleAnyone"
                  v-model="getApprovalStages.approve_option"
                  type="radio"
                  value="anyone"
                  name="autoScheduleRadio"
                />
                <label for="autoScheduleAnyone" class="radio_left"
                  >Post needs approval from anyone</label
                >
              </div>
            </template>
            <button v-if="false" class="btn light_gray large_btn d-block">
              <span>Save selection as workflow</span>
            </button>
          </div>
        </div>
      </div>
      <div class="bottom_search">
        <div class="search_input">
          <div class="search_input__search_inner">
            <input
              v-model="getApprovalStages.notes"
              class=""
              type="text"
              maxlength="500"
              placeholder="Add a note to the approver(s)"
              data-cy="note-to-approver"
            />
          </div>
        </div>

        <div class="btn_block mt-3 d-flex align-items-center">
          <div>
            <button
              class="btn btn-studio-theme-grey-space"
              @click="$bvModal.hide('socialApprovalModal')"
            >
              <span>Cancel</span>
            </button>
          </div>
          <div class="ml-auto">
            <button
              :disabled="
                !getApprovalStages.approvers ||
                getApprovalStages.approvers.length === 0
              "
              class="btn btn-studio-theme-space"
              data-cy="send-approval"
              @click.prevent="sendForApproval"
            >
              <span>Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less" scoped>
.social_post_side_filter {
  margin: 0 15px;
  min-width: 344px;
  width: 370px;
  .filter_inner {
    border-radius: 0.625rem;
  }
}
.workflow-approval-img {
  opacity: 0.7;
  width: 70%;
  display: block;
  margin: 0 auto;
}
.approver-heading {
  font-size: 1rem;
  padding: 15px 0;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { approvalStages } from '@src/modules/publish/store/states/mutation-types'
import SocialPreview from '@src/modules/publish/components/posting/social/previews/SocialPreview'
import approvalMember from './ApprovalMembers'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {
    approvalMember,
    SocialPreview,
  },
  data() {
    return {
      memberSearch: '',
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getApprovalStages',
      'getPublishTimeOptions',
      'getProfile',
      'getYoutubeSharingDetails',
    ]),
    fetchFilteredMembers() {
      const members = []
      const stateObject = this

      if (this.memberSearch && this.memberSearch.trim().length > 0) {
        if (
          this.getWorkspaces &&
          this.getWorkspaces.activeWorkspace &&
          this.getWorkspaces.activeWorkspace.members
        ) {
          this.getWorkspaces.activeWorkspace.members.forEach(
            (workspaceMember) => {
              if (
                workspaceMember.status === 'joined' &&
                workspaceMember.user &&
                (workspaceMember.role !== 'collaborator' ||
                  (workspaceMember.permissions &&
                    !workspaceMember.permissions.postsReview))
              ) {
                if (
                    (workspaceMember.user.firstname && workspaceMember.user.firstname.toLowerCase().indexOf(stateObject.memberSearch.toLowerCase()) >= 0) ||
                    (workspaceMember.user.lastname && workspaceMember.user.lastname.toLowerCase().indexOf(stateObject.memberSearch.toLowerCase())) >= 0
                ) {
                  members.push(workspaceMember)
                } else if (
                  workspaceMember.user.email
                    .toLowerCase()
                    .indexOf(stateObject.memberSearch.toLowerCase()) >= 0
                ) {
                  members.push(workspaceMember)
                }
              }
            }
          )
        }
      } else if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.members
      ) {
        this.getWorkspaces.activeWorkspace.members.forEach(
          (workspaceMember) => {
            if (
              workspaceMember.status === 'joined' &&
              workspaceMember.user &&
              (workspaceMember.role !== 'collaborator' ||
                (workspaceMember.permissions &&
                  !workspaceMember.permissions.postsReview))
            ) {
              members.push(workspaceMember)
            }
          }
        )
      }

      return members
    },
    isVideo() {
      if (this.getCommonBoxOptions.status) {
        return (
          this.getCommonSharingDetails.video &&
          this.getCommonSharingDetails.video.link
        )
      } else {
        switch (this.getSocialSharingSelection.activeTab) {
          case 'facebook_tab':
            return (
              this.getFacebookSharingDetails.video &&
              this.getFacebookSharingDetails.video.link
            )
          case 'twitter_tab':
            return (
              this.getTwitterSharingDetails.video &&
              this.getTwitterSharingDetails.video.link
            )
          case 'tumblr_tab':
            return (
              this.getTumblrSharingDetails.video &&
              this.getTumblrSharingDetails.video.link
            )
          case 'instagram_tab':
            return (
              this.getInstagramSharingDetails.video &&
              this.getInstagramSharingDetails.video.link
            )
          case 'youtube_tab':
            return (
              this.getYoutubeSharingDetails.video &&
              this.getYoutubeSharingDetails.video.link
            )
        }
      }
      return false
    },
  },

  methods: {
    ...mapActions([]),
    // getMember (id) {
    //   return this.getWorkspaces.activeWorkspace.members.find(member => member.user_id === id)
    // },

    sendForApproval() {
      if (
        !this.getApprovalStages.approvers ||
        this.getApprovalStages.approvers.length === 0
      ) {
        this.alertMessage('Please select member(s).', 'error')
        return false
      }

      if (!this.anyPendingApprover()) {
        this.alertMessage(
          'Please select at least one pending approver.',
          'error'
        )
        return false
      }
      // emiting event for new composer
      EventBus.$emit('approval-selected')
      // ******* to process post for old composer
      // if (this.getPublishTimeOptions.time_type === 'draft') {
      //   this.processSocialShare(true, true, false, '', true)
      // } else {
      //   this.processSocialShare(false, false, false, '', true)
      // }
    },
    isMemberApprovalStatusApprove(memberId) {
      if (memberId === this.getProfile._id) return true
      const exist = this.getApprovalStages.approvers.filter((item) => {
        if (item.user_id === memberId && item.status === 'approve') return item
      })
      if (exist.length > 0) return true
      else return false
    },
    anyPendingApprover() {
      const exist = this.getApprovalStages.approvers.filter((item) => {
        if (item.status === 'pending') return item
      })
      if (exist.length > 0) return true
      else return false
    },

    previewImage() {
      if (this.getCommonBoxOptions.status) {
        return this.approvalPostImage(this.getCommonSharingDetails, true)
      } else {
        switch (this.getSocialSharingSelection.activeTab) {
          case 'facebook_tab':
            return this.approvalPostImage(this.getFacebookSharingDetails, true)
          case 'twitter_tab':
            return this.approvalPostImage(this.getTwitterSharingDetails, true)
          case 'linkedin_tab':
            return this.approvalPostImage(
              this.getLinkedinSharingDetails,
              false,
              'single_image'
            )
          case 'pinterest_tab':
            return this.approvalPostImage(
              this.getPinterestSharingDetails,
              false,
              'single_image'
            )
          case 'tumblr_tab':
            return this.approvalPostImage(
              this.getTumblrSharingDetails,
              true,
              'single_image'
            )
          case 'instagram_tab':
            return this.approvalPostImage(this.getInstagramSharingDetails, true)
          case 'youtube_tab':
            return this.approvalPostImage(
              this.getYoutubeSharingDetails,
              true,
              'single_image'
            )
          case 'gmb_tab':
            return this.approvalPostImage(
              this.getGmbSharingDetails,
              false,
              'single_image'
            )
        }
      }
      return false
    },

    approvalPostImage(details, videoCheck, type = 'multiple_image') {
      if (videoCheck && details.video && details.video.link)
        return details.video.thumbnail

      if (type === 'single_image') {
        if ((details.url && details.image) || details.image)
          return details.image
      }

      if (type === 'multiple_image') {
        if (details.url || details.image.length > 0) {
          if (details.image.constructor === Array) return details.image[0]
          return details.image
        }
      }
      return false
    },
  },
  watch: {
    'getApprovalStages.members'(data) {
      const stateObject = this
      const finalData = []
      data.forEach((approvalMember, index) => {
        const exist = stateObject.getApprovalStages.approvers.filter((item) => {
          if (item.user_id === approvalMember) return item
        })
        if (exist.length > 0) finalData.push(exist[0])
        else {
          finalData.push({
            user_id: approvalMember,
            last_action_note: '',
            last_action_time: '',
            status: 'pending',
            // 'notification': 'pending'
          })
        }
      })
      this.$store.commit(approvalStages.SET_APPROVAL_MEMBERS, finalData)
    },
  },
}
</script>
