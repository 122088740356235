<template>
  <b-modal
    id="group_app_modal"
    size="lg"
    class="modish-modal"
    hide-footer
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="modal_head align-items-center">
      <h4>Add ContentStudio app to your Facebook group(s)</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('group_app_modal')"
        >&times;
      </button>
    </div>

    <div class="mt-0 mb-4">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-start">
          <div class="modish-modal__header">
            <p
              >In order to publish content to your Facebook group(s), you must
              perform this step where you'll add ContentStudio app to your
              Facebook group settings.
              <a
                href="https://docs.contentstudio.io/article/861-how-to-add-the-contentstudio-app-to-your-facebook-group"
                target="_blank"
                >Please read this help doc</a
              >
              to see the exact steps you need to perform.
            </p>

            <p
              >Upon clicking the 'Add ContentStudio App' button, you'll be taken to a
              page on Facebook where you need to search for 'ContentStudio' app
              and add it. </p
            ><p
              >And in order to check if the App is added in the facebook group,
              please click on the 'Verify' button.
            </p>
          </div>
        </div>

        <div class="modal_body">
          <b-list-group class="mt-3">
            <b-list-group-item v-for="(item, index) in list" :key="index">
              <div class="d-flex justify-content-between">
                <div class="profile_picture d-flex align-items-center">
                  <div class="picture_block">
                    <img
                      :src="item.image"
                      alt=""
                      @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                      "
                    />
                  </div>
                  <div class="text_block">
                    <p class="text">{{ item.name }} </p>
                  </div>
                </div>

                <div
                  :id="'fb_' + item.facebook_id"
                  class="flex-grow-1 space_nowrap text-right d-flex justify_end"
                >
                  <button
                    @click="openLink(item.facebook_id)"
                    class="btn btn-studio-theme-space"
                    >Add ContentStudio App</button
                  >

                  <button
                    @click="verifyGroupApp(item.facebook_id)"
                    class="btn btn-studio-theme-grey-space ml-2 verify-btn"
                    >Verify
                  </button>

                  <button
                    :disabled="true"
                    class="btn btn-studio-theme-space verified-btn"
                    >Verified
                  </button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import proxy from '@common/lib/http-common'
import { verifyGroupAppURL } from '../../config/api-utils'
import { mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";
export default {
  name: 'AddGroupModal',
  data () {
    return {
      list: []
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces'])
  },
  methods: {
    // eslint-disable-next-line camelcase
    openLink (facebook_id) {
      // eslint-disable-next-line camelcase
      window.open(`https://www.facebook.com/groups/${facebook_id}/apps/store`)
    },
    // eslint-disable-next-line camelcase
    verifyGroupApp (facebook_id) {
      // eslint-disable-next-line camelcase
      const element = document.getElementById('fb_' + facebook_id)

      element.querySelectorAll('.verify-btn').forEach((button) => {
        button.setAttribute('disabled', true)
      })

      proxy
        .post(verifyGroupAppURL, {
          facebook_id: facebook_id,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        })
        .then((resp) => {
          // element
          if (resp.data.status) {
            element.querySelectorAll('*').forEach((button) => {
              button.setAttribute('disabled', true)
            })
            element.classList.add('verified-app')
            this.alertMessage(
              'ContentStudio app added Successfully.',
              'success'
            )
          } else {
            element.querySelectorAll('.verify-btn').forEach((button) => {
              button.removeAttribute('disabled')
            })
            this.alertMessage(
              'Please add ContentStudio app to your Facebook group first.',
              'error'
            )
          }

          console.log(resp.data)
        })
    }
  },
  mounted () {
    EventBus.$on('showAppGroupModal', (data) => {
      this.list = data.groups
      this.$bvModal.show('group_app_modal')
    })
  }
}
</script>
