<style lang="scss">
@import 'assets/sass/bootstrap-core';
</style>

<style lang="less">
@import './assets/less/main.less';
</style>

<style lang="scss">
@import 'assets/sass/main.scss';

@import 'assets/tailwind.css';

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import GetStarted from '@src/modules/account/views/onboarding/GetStarted'
import { baseUrl, refreshTokenURL } from './config/api-utils'

export default {
  components: { GetStarted },
  data() {
    return {
      showUpdateUI: false,
      image: require('@assets/img/logo/logo-animation.gif'),
    }
  },
  /**
   * @legacy This is a legacy method and will probably be changed in vue3 update
   * @returns {{root: NeverFallback<Instance, Vue>}}
   */
  provide() {
    return {
      root: this.$root,
    }
  },
  computed: {
    ...mapGetters(['getJWTToken']),
    /**
     * checking application is loaded or not
     **/
    checkLoadedOnce() {
      return this.$store.getters.getLoadedOnce
    },
  },
  watch: {
    '$route.name'() {
      this.$bvModal.hide('media-storage-limits-exceeded-modal')
      this.$bvModal.hide('increase-limits-dialog')
    },
    // 'getWorkspaces.onboardingSteps.watch_video'() {
    //   if (!this.getWorkspaces.activeWorkspace.onboarding && this.getActiveWorkspace.onboarding_steps.watch_video === false
    //       && this.$route.name !== 'onboardingWorkspace') {
    //     this.$bvModal.show('get-started-modal')
    //   }
    // }
  },
  created() {
    this.initUserMaven()
  },
  mounted() {
    // calling the endpoint for the refresh token. The refresh token will be updated once we call it.
    setInterval(() => {
      console.debug('In Set Interval method...')
      if (this.getJWTToken && this.getJWTToken !== 'null') {
        this.postRequest(
          refreshTokenURL,
          { token: this.getJWTToken },
          (response) => {
            this.$store.commit(
              authenticationTypes.SET_JWT_TOKEN,
              response.data.token
            )
          },
          (error) => {
            console.debug(error)
            // set the token to expire
            if (error.response && error.response.status === 401) {
              this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
              window.location.href = baseUrl + 'logout'
            }
          }
        )
      }
    }, 5 * 60 * 1000) // 5 minutes interval
  },

  methods: {
    async update() {
      this.showUpdateUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },
  },
}
</script>

<template>
  <div class="app_container">
    <div v-if="showUpdateUI" class="update-dialog">
      <div class="update-dialog__content">
        A new version is found. Refresh to load it?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Update
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="showUpdateUI = false"
        >
          Cancel
        </button>
      </div>
    </div>

    <template v-if="checkLoadedOnce">
      <router-view />
    </template>
    <div
      v-if="!checkLoadedOnce"
      class="loading-logo flex justify-center items-center"
    >
      <img style="width: 50px" :src="image" alt="ContentStudio" />
    </div>

    <b-modal
      id="get-started-modal"
      class="getStartedModal"
      size="xl"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
    >
      <div class="modal-dialog modal-xl mt-0">
        <div class="modal-content">
          <div class="modal_body m_t_15">
            <GetStarted></GetStarted>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
