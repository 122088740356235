function checkUniqueVariation(message, posts) {
  let unique = true
  posts.forEach(function (post) {
    if (message === post.message) {
      unique = false
      return false
    }
  })

  return unique
}

export { checkUniqueVariation }
