<template>
  <div
    v-if="comment !== null"
    class="write_block comment_response d-flex align-items-start"
    :class="isRoot ? '' : 'inbox_reply_comment'"
    :style="isHidden ? 'opacity:0.7;' : ''"
  >
    <div class="image_block" style="margin-top: 3px">
      <div
        v-if="comment.posted_by !== null && comment.posted_by.image !== null"
        class="img"
        :style="'background: url(' + comment.posted_by.image + ')'"
      ></div>
      <div
        v-else
        class="img"
        style="
          background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
        "
      ></div>
    </div>
    <div class="text_area">
      <div class="content messageBox">
        <span
          v-if="comment.posted_by !== null && comment.posted_by.name"
          class="user_name mention"
          >{{ comment.posted_by.name }}</span
        >
        <span v-else class="user_name mention">Facebook User</span
        >{{ comment.comment_message }}
        <template v-if="comment.attachment">
          <video
            v-if="
              comment.attachment !== null &&
              comment.attachment.is_type === 'video' &&
              checkForValidVideo(comment.attachment.url)
            "
            width="320"
            height="240"
            controls
          >
            <source :src="comment.attachment.url" type="video/mp4" />
            <source :src="comment.attachment.url" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
          <img
            v-else-if="
              comment.attachment !== null &&
              comment.attachment.is_type === 'image'
            "
            v-img
            :src="comment.attachment.url"
            width="200"
          />
        </template>
      </div>
      <div class="content_date ml-3">
        <clip-loader
          v-if="isLoading"
          class="d-inline-block align-middle mr-1"
          color="rgba(152, 158, 181, 0.8)"
          :size="'12px'"
        ></clip-loader>
        <span v-if="!isHidden" class="mention" @click="likePostComment">
          <i
            v-tooltip="comment.liked ? 'Unlike' : 'Like'"
            class="fa-heart like"
            :class="comment.liked ? 'fas' : 'far'"
          >
          </i>
        </span>
        <span
          v-if="!isHidden"
          style="color: red"
          class="mention"
          @click="deletePostComment"
        >
          <i v-tooltip="'Delete'" class="far fa-trash-alt"></i>
        </span>
        <span
          v-if="canHide"
          style="color: darkgray"
          class="mention"
          @click="hideUnhidePostComment"
        >
          <i class="fa-minus-hexagon" :class="isHidden ? 'fal' : 'far'"> </i>
          {{ isHidden ? 'Unhide' : 'Hide' }}</span
        >
        <span
          v-if="!isHidden"
          class="mention"
          @click="setCanWrite(rootComment)"
        >
          <i class="far fa-comments"></i>
          Reply</span
        >
        <span
          v-if="getPrivateReplyLabel()"
          v-tooltip="getToolTipPM(comment)"
          class="mention"
          @click="setPrivateReplyTo(comment, rootIndex, subIndex)"
        >
          <i class="far fa-paper-plane"></i>
          {{ getPrivateReplyLabel() }}
        </span>
        <span class="date">{{
          commonMethods.timesAgo(comment.created_time)
        }}</span>
        <span
          v-if="isRoot && repliesCount > 0"
          class="replies-toggle"
          @click="setCanShowReplies(comment)"
          >{{ showReplies ? 'Hide Replies' : 'View Replies' }} ({{
            repliesCount
          }})</span
        >
      </div>
      <div
        v-if="comment.error_message && comment.error_message !== ''"
        class="pl-1"
      >
        <p class="text-xs text-red-500"
          ><i
            v-b-popover.hover.html.top="comment.error_message"
            class="fas fa-exclamation has-tooltip rounded-full text-xs text-white pl-1.5 pr-1.5 mr-1 bg-red-400"
          ></i>
          Failed to perform 'Like action'
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.inbox_reply_comment {
  padding-left: 50px;
}

.like {
  color: rgb(219, 83, 82);
  cursor: pointer;
}

.replies-toggle {
  float: right;
  text-decoration: underline;
  color: #385898;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
}

.messageBox {
  padding: 10px 13px;
  //padding-top: 10px !important;
  //padding-left: 10px !important;
  //padding-right: 10px !important;
  white-space: pre-line !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  components: {},
  props: {
    rootIndex: { type: Number, default: 0 },
    subIndex: { type: Number, default: 0 },
    rootComment: {
      type: Object,
      default: () => {},
    },
    isRoot: { type: Boolean, default: false },
    post: { type: Object, default: null },
    comment: {
      type: Object,
      default: () => {},
    },
    repliesCount: { type: Number, default: 0 },
    showReplies: { type: Boolean, default: false },
    changeStatus: {
      type: Function,
      default: () => {},
    },
    setPrivateReplyTo: {
      type: Function,
      default: () => {},
    },
    setCanWrite: {
      type: Function,
      default: () => {},
    },
    setCanShowReplies: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      indices: { parent: null, child: null },
      commonMethods: commonMethods,
      isHidden: false,
      canHide: false,
    }
  },
  watch: {
    'comment.is_hidden': async function (newVal, oldVal) {
      // watch it
      this.isHidden = newVal
    },
    'comment.can_hide': async function (newVal, oldVal) {
      // watch it
      this.canHide = newVal
    },
  },
  mounted() {
    this.isHidden =
      typeof this.comment.is_hidden !== 'undefined' &&
      this.comment.is_hidden != null &&
      this.comment.is_hidden
    this.canHide =
      typeof this.comment.can_hide !== 'undefined' &&
      this.comment.can_hide != null &&
      this.comment.can_hide
  },
  methods: {
    ...mapActions([
      'likeFacebookComment',
      'deleteComment',
      'hideUnhideComment',
    ]),
    getPrivateReplyLabel() {
      if (this.comment.can_reply_privately) {
        return 'Reply as private'
      } else if (
        this.comment.posted_by != null &&
        this.comment.posted_by.id === this.post.platform_id
      ) {
        return ''
      } else {
        if (this.comment.private_reply_conversation != null) {
          return 'See response'
        } else {
          return 'Message'
        }
      }
    },
    getToolTipPM(comment) {
      if (
        comment.posted_by != null &&
        !comment.can_reply_privately &&
        comment.posted_by.id !== this.post.platform_id
      ) {
        if (commonMethods.daysAgo(comment.created_time) > 7) {
          if (comment.private_reply_conversation === null) {
            return "You can't send a message in response to comments posted more than 7 days ago."
          }
        } else {
          return "Pages can't send private messages to other Pages."
        }
      }
      return ''
    },
    async likePostComment() {
      if (!this.isLoading) {
        this.isLoading = true
        const payload = {
          comment_id: this.comment.comment_id,
          status: !this.comment.liked,
          platform_id: this.comment.platform_id,
          workspace_id: this.comment.workspace_id,
          reply_of: this.isRoot ? null : this.rootComment.comment_id,
        }
        const response = await this.likeFacebookComment(payload)
        if (response.isValid) {
          const subId = this.isRoot ? null : this.comment.comment_id
          this.changeStatus(
            'like',
            this.rootComment.comment_id,
            subId,
            response
          )
          this.isLoading = false
        } else {
          this.comment.error_message = response.response?.error
            ? response.response.error
            : response.message
          this.isLoading = false
        }
      }
    },
    async deletePostComment() {
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your comment?',
        {
          title: 'Remove Comment',
          ...swalAttributes(),
        }
      )
      if (!this.isLoading && res) {
        this.isLoading = true
        const payload = {
          platform_type: 'facebook',
          comment_id: this.comment.comment_id,
          platform_id: this.comment.platform_id,
          workspace_id: this.comment.workspace_id,
          reply_of: this.isRoot ? null : this.rootComment.comment_id,
        }
        const response = await this.deleteComment(payload)
        if (response.isValid) {
          const subId = this.isRoot ? null : this.comment.comment_id
          this.changeStatus(
            'delete',
            this.rootComment.comment_id,
            subId,
            response
          )
          this.isLoading = false
        } else {
          console.log(response)
          this.isLoading = false
        }
      }
    },
    async hideUnhidePostComment() {
      if (!this.isLoading) {
        this.isLoading = true
        const payload = {
          platform_type: 'facebook',
          comment_id: this.comment.comment_id,
          is_hidden: !this.isHidden,
          platform_id: this.comment.platform_id,
          workspace_id: this.comment.workspace_id,
          reply_of: this.isRoot ? null : this.rootComment.comment_id,
        }
        const response = await this.hideUnhideComment(payload)
        if (response.isValid) {
          const subId = this.isRoot ? null : this.comment.comment_id
          this.changeStatus(
            'hide',
            this.rootComment.comment_id,
            subId,
            response
          )
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      }
    },
    checkForValidVideo(url) {
      return url != null && url.includes('.mp4')
    },
  },
}
</script>
