<template>
  <div class="ds_card_container ds_domain_table">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Top Authors</h2>
        </div>
        <div class="card_content">
          <div class="ds_analytic_table">
            <div class="t_head d-flex align-items-center">
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active: sortByRow == 'key' && sortByOrder == 'asc',
                  }"
                  @click.prevent="changeSortOrder('key', 'asc')"
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active: sortByRow == 'key' && sortByOrder == 'desc',
                  }"
                  @click.prevent="changeSortOrder('key', 'desc')"
                >
                </i>
                <span class="text">Author Name</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active: sortByRow == 'doc_count' && sortByOrder == 'asc',
                  }"
                  @click.prevent="changeSortOrder('doc_count', 'asc')"
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active: sortByRow == 'doc_count' && sortByOrder == 'desc',
                  }"
                  @click.prevent="changeSortOrder('doc_count', 'desc')"
                >
                </i>
                <span class="text">Total Articles</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active:
                      sortByRow == 'total_interactions.value' &&
                      sortByOrder == 'asc',
                  }"
                  @click.prevent="
                    changeSortOrder('total_interactions.value', 'asc')
                  "
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active:
                      sortByRow == 'total_interactions.value' &&
                      sortByOrder == 'desc',
                  }"
                  @click.prevent="
                    changeSortOrder('total_interactions.value', 'desc')
                  "
                >
                </i>
                <span class="text">Total Engagement</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active:
                      sortByRow == 'average_interactions.value' &&
                      sortByOrder == 'asc',
                  }"
                  @click.prevent="
                    changeSortOrder('average_interactions.value', 'asc')
                  "
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active:
                      sortByRow == 'average_interactions.value' &&
                      sortByOrder == 'desc',
                  }"
                  @click.prevent="
                    changeSortOrder('average_interactions.value', 'desc')
                  "
                >
                </i>
                <span class="text">Avg Engagement</span>
              </div>
            </div>

            <div class="t_body">
              <div
                v-for="author in orderedList()"
                v-if="
                  getInsightsTopAuthors &&
                  getInsightsTopAuthors.length > 0 &&
                  author.total_interactions.value >= 0
                "
                class="t_row d-flex align-items-center"
              >
                <div class="col">
                  <div class="profile_picture">
                    <div class="picture_block">
                      <div :class="getRandomColor()">
                        <span>{{ capitalizeName(author.key) }}</span>
                      </div>
                    </div>
                    <div class="text_block">
                      <p class="text">{{ author.key }}</p>
                    </div>
                  </div>
                </div>
                <div class="col">{{ author.doc_count }}</div>
                <div class="col">{{
                  $filters.floatToInt(author.total_interactions.value)
                }}</div>
                <div class="col">{{
                    $filters.floatToInt(author.total_interactions.value)
                }}</div>
              </div>

              <div
                v-if="
                  getInsightsTopAuthors && getInsightsTopAuthors.length == 0
                "
                class="t_row d-flex align-items-center"
              >
                <div class="col col-full-width">
                  No data found for the authors.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'
export default {
  data () {
    return {
      sortByRow: 'doc_count',
      sortByOrder: 'desc'
    }
  },
  computed: {
    ...mapGetters(['getInsightsTopAuthors'])
  },
  methods: {
    getRandomColor () {
      const colorsList = [
        'label_green',
        'label_blue',
        'label_purple',
        'label_pink',
        'label_red2',
        'label_light_red',
        'color_11',
        'color_12',
        'color_13',
        'color_14',
        'color_15',
        'color_16',
        'color_17',
        'color_18',
        'color_19',
        'color_20'
      ]
      return (
        'round d-flex align-items-center justify-content-center ' +
        colorsList[Math.floor(Math.random() * colorsList.length)]
      )
    },
    changeSortOrder (row, order) {
      this.sortByRow = row
      this.sortByOrder = order
    },

    orderedList () {
      return orderBy(
        this.getInsightsTopAuthors,
        [this.sortByRow],
        [this.sortByOrder]
      )
    }
  }
}
</script>
