var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_field"},[_c('div',{staticClass:"multi_select"},[_c('div',{staticClass:"d-flex"},[_vm._m(0),(
          this.getTopicSelection.exclude_domains.length > 0 ||
          this.excludeDomainsValue.replace(/^\s+/, '') !== ''
        )?_c('button',{staticClass:"reset_btn",on:{"click":function($event){return _vm.clearAllKeywords()}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Clear")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.excludeDomainsValue),expression:"excludeDomainsValue"}],staticClass:"prevent-listener",attrs:{"type":"text","placeholder":"Enter domains that you think are giving irrelevant results, e.g. careers.com…","data-cy":"exclude-domain"},domProps:{"value":(_vm.excludeDomainsValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.excludeDomainsValue=$event.target.value},function($event){return _vm.asyncExcludeDomainResults(_vm.excludeDomainsValue)}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.exclude_domains,
          _vm.excludeDomainsValue,
          'exclude_domains',
          $event
        )},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==188)return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.exclude_domains,
          _vm.excludeDomainsValue,
          'exclude_domains',
          $event
        )}]}}),_c('div',{staticClass:"multi_input"},[(_vm.isExcludeDomainsLoading && _vm.excludeDomainsValue.length >= 3)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner"},[_c('ul',_vm._l((_vm.exclude_domains_suggestion_list),function(suggested_domain,index){return _c('li',{staticClass:"item d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.addExcludeDomain(suggested_domain, index)}}},[(!suggested_domain.is_parent)?_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-rss"})]):(suggested_domain.favicon_16)?_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":suggested_domain.favicon_16,"alt":""}})]):_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-world-o"})]),_c('span',{staticClass:"text"},[_c('span',{staticClass:"heading"},[_vm._v(_vm._s(suggested_domain.name))]),_c('span',{staticClass:"url",domProps:{"innerHTML":_vm._s(
                    _vm.highlightText(suggested_domain.url, _vm.excludeDomainsValue)
                  )}})]),_vm._m(1,true)])}),0)])]):(_vm.excludeDomainsLoader)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner min_height d-flex align-items-center justify-content-center"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]):_vm._e()]),(_vm.getTopicSelection.exclude_domains)?[_c('div',{staticClass:"multi_tags"},[_c('ul',_vm._l((_vm.getTopicSelection.exclude_domains),function(must_exclude_keyword,index){return _c('li',{staticClass:"red"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(must_exclude_keyword))]),_c('span',{staticClass:"remove",on:{"click":function($event){$event.preventDefault();return _vm.removeKeyword(_vm.getTopicSelection.exclude_domains, index)}}},[_vm._v("×")])])}),0)])]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_c('span',{staticClass:"bold"},[_vm._v("EXCLUDE")]),_vm._v(" results from these domains")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"view"},[_c('i',{staticClass:"fa fa-eye"})])
}]

export { render, staticRenderFns }