<template>
  <div class="authentication_component">
    <AuthenticationLeft></AuthenticationLeft>
    <div class="auth_right login_block">
      <div class="d-flex align-items-center height" style="overflow: auto">
        <div class="_wrapper m-auto">
          <div class="right_logo">
            <img src="../../../assets/img/logo/logo_text_logo.png" alt="" />
          </div>

          <div
            v-if="verifying_information_loader"
            class="auth_right_inner verifying_member"
          >
            <img
              src="../../../assets/img/onboarding/add_team_members.svg"
              alt=""
            />
            <p>Please wait verifying information...</p>
            <clip-loader
              class="align-middle d-inline-block"
              color="#4165ed"
              :size="'16px'"
            ></clip-loader>
          </div>

          <div
            v-else
            class="auth_right_inner"
            :class="{ loader_overlay: getSettingLoaders.joinTeamStatusLoader }"
          >
            <div class="top_block">
              <h2>Join ContentStudio</h2>
              <p>Enter your password and confirm password below.</p>
            </div>

            <div class="line_input clear">
              <div class="row">
                <div
                  class="input_field col-md-12 col-sm-12"
                  :class="{ active: password }"
                >
                  <input
                    id="member_password"
                    v-model="password"
                    type="password"
                  />
                  <div class="label">
                    <label for="">
                      <i class="icon_item cs-key"></i>
                      <span class="lbl_txt">Password</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="input_field col-md-12 col-sm-12"
                  :class="{ active: confirmPassword }"
                >
                  <input
                    id="member_confirm_password"
                    v-model="confirmPassword"
                    type="password"
                    @keyup.enter="validateAndJoinOrganization"
                  />
                  <div class="label">
                    <label for="">
                      <i class="icon_item cs-key"></i>
                      <span class="lbl_txt">Confirm Password</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="btn_block">
                <button
                  class="btn btn_loader loader_right"
                  :disabled="organization_join_loader"
                  :class="{ disabled: organization_join_loader }"
                  @click.prevent="validateAndJoinOrganization"
                >
                  <span>Join Now</span>
                  <img
                    v-if="organization_join_loader"
                    style="width: 20px; margin-left: 8px"
                    src="../../../assets/img/common/gif_loader_white.gif"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import http from '@common/lib/http-common'
import {
  joinOrganizationAndSetPasswordURL,
  validateJoinTokenURL,
} from '@src/modules/setting/config/api-utils'
import AuthenticationLeft from './../AuthenticationLeft.vue'

export default {
  components: {
    AuthenticationLeft,
  },
  computed: {
    ...mapGetters(['getSettingLoaders']),
  },
  created() {
    console.log(this.$route.query.token)
    this.validateJoiningToken()
    // this.getJoinTokenStatus()
  },
  data() {
    return {
      size: '14px',
      loaderColor: '#ffffff',
      confirmPassword: '',
      password: '',
      organization_join_loader: false,
      verifying_information_loader: true,
    }
  },
  methods: {
    ...mapActions([]),
    validateJoiningToken() {
      http
        .post(validateJoinTokenURL, { token: this.$route.query.token })
        .then((res) => {
          if (res.data.status) {
            this.verifying_information_loader = false
          } else {
            this.verifying_information_loader = false
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
            this.$router.push({ name: 'login' })
          }
        })
        .catch()
    },
    validateAndJoinOrganization() {
      if (this.requiredCheck(this.password)) {
        this.$store.dispatch('toastNotification', {
          message: 'Please enter the password.',
          type: 'error',
        })
        return
      }
      if (this.requiredCheck(this.confirmPassword)) {
        this.$store.dispatch('toastNotification', {
          message: 'Please enter the confirm password.',
          type: 'error',
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$store.dispatch('toastNotification', {
          message: 'Your password and confirm password does not match',
          type: 'error',
        })
      }
      this.organization_join_loader = true
      http
        .post(joinOrganizationAndSetPasswordURL, {
          token: this.$route.query.token,
          password: this.password,
          confirm_password: this.confirmPassword,
        })
        .then((res) => {
          if (res.data.status) {
            this.$store.dispatch('toastNotification', {
              message:
                'Your account has been created successfully, you can now login to your account.',
            })
            this.$router.push({ name: 'login' })
          } else {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
          }
          this.organization_join_loader = false
        })
        .catch((err) => {
          this.organization_join_loader = false
        })
    },
  },
}
</script>
