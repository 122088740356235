<template>
  <div>
    <div class="tags-input">
      <div class="tags-area">
        <span
          class="badge badge-pill badge-light"
          v-for="(badge, index) in tags"
          :key="index"
        >
          <span v-html="badge"></span>
          <i
            href="#"
            class="tagsinput-remove"
            v-tooltip="'Remove'"
            @click.prevent="removeTag(index)"
          ></i>
        </span>
      </div>

      <input
        type="text"
        placeholder="YouTube video tags - (optional)"
        v-model="input"
        @keypress.enter.prevent="tagFromInput"
        @keydown.delete="removeLastTag"
      />
    </div>
  </div>
</template>

<script>
import { social } from '../../../../store/states/mutation-types'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      existingTags: {},
      tags: [],
      input: ''
    }
  },
  created () {
    this.tags = this.getYoutubeOptions.tags
  },
  watch: {
    tags () {
      this.$store.commit(social.SET_YOUTUBE_TAGS, this.tags)
    }
  },
  computed: {
    ...mapGetters(['getYoutubeOptions'])
  },
  methods: {
    tagFromInput (e) {
      let text = this.input.trim()
      // If the new tag is not an empty string
      if (text.length) {
        this.input = ''
        // Determine the tag's slug and text depending on if the tag exists
        // on the site already or not
        let slug = this.makeSlug(text)
        const existingTag = this.existingTags[slug]
        slug = existingTag ? slug : text
        text = existingTag || text
        this.addTag(slug, text)
      }
    },
    makeSlug (value) {
      return value.toLowerCase().replace(/\s/g, '-')
    },
    addTag (slug, text) {
      const searchSlug = this.makeSlug(slug)
      const found = this.tags.find((value) => {
        return searchSlug == this.makeSlug(value)
      })
      if (!found) {
        this.tags.push(slug)
      }
      this.$emit('addedTag')
    },
    removeLastTag (e) {
      if (!this.input.length) {
        this.removeTag(this.tags.length - 1)
      }
    },
    removeTag (index) {
      this.tags.splice(index, 1)
    }
  }
}
</script>
