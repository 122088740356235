var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"blog_share_modal","modal-class":"social_share_modal blog_share_modal top_left_design_box","static":"","no-enforce-focus":"","hide-footer":"","hide-header":""},on:{"hide":_vm.onhide}},[_c('model-layout',{attrs:{"type":"Blog"}}),_c('div',{staticClass:"modal_body",staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"modal_inner_content basic_form blog_steps_common",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"d-flex align-items-stretch"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"steps_arrow_links"},[_c('ul',[_c('li',{class:{
                  active: _vm.getBlogPostingDetails.stage === 'content',
                  disabled: _vm.getPublishLoaders.initializeArticlePostCreation,
                },on:{"click":function($event){$event.preventDefault();return _vm.changeBlogPostingStage('content')}}},[_vm._v("Content")]),_c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})]),_c('li',{class:{
                  active: _vm.getBlogPostingDetails.stage === 'settings',
                  disabled: _vm.getPublishLoaders.initializeArticlePostCreation,
                },on:{"click":function($event){$event.preventDefault();return _vm.changeBlogPostingStage('settings')}}},[_vm._v("Settings")]),_c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})]),_c('li',{class:{
                  active: _vm.getBlogPostingDetails.stage === 'distribution',
                  disabled: _vm.getPublishLoaders.initializeArticlePostCreation,
                },on:{"click":function($event){$event.preventDefault();return _vm.changeBlogPostingStage('distribution')}}},[_vm._v("Distribution")]),_c('li',{staticClass:"arrow_right_gray"},[_c('i',{staticClass:"cs-angle-right"})]),_c('li',{class:{
                  active: _vm.getBlogPostingDetails.stage === 'publish',
                  disabled: _vm.getPublishLoaders.initializeArticlePostCreation,
                },on:{"click":function($event){$event.preventDefault();return _vm.changeBlogPostingStage('publish')}}},[_vm._v("Review & Publish")])])]),_c('content-creation-section',{class:{ hide: _vm.getBlogPostingDetails.stage !== 'content' }}),_c('image-section',{class:{ hide: _vm.getBlogPostingDetails.stage !== 'settings' }}),_c('blog-distribute',{class:{ hide: _vm.getBlogPostingDetails.stage !== 'distribution' },attrs:{"variations_options":"true"}}),_c('blog-review-publish',{class:{ hide: _vm.getBlogPostingDetails.stage !== 'publish' },attrs:{"type":"discovery"}})],1),_c('model-sidebar',{staticClass:"social_post_side_filter_model social_share_model_sidebar",attrs:{"type":"Blog"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }