<template>
  <div class="no_post_available">
    <img
      src="../../../../../assets/img/analytics/no_post.svg"
      alt=""
      width="300"
      height="300"
    />

    <p class="text">
      Demographics are only available for the Pages having Followers greater
      than 100.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.no_post_available .text {
  font-size: 1.125rem;
  margin-bottom: 0;
  text-align: center;
}
</style>
