<template>
  <div class="ds_topic_sidebar">
    <div class="sidebar_inner" style="padding-left: 15px">
      <template>
        <content-loader
          :height="700"
          :width="300"
          :speed="1"
          primaryColor="#e3e6ec"
          secondaryColor="#d1d4de"
        >
          <rect x="7" y="9" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="9" rx="3" ry="3" width="222.08" height="16" />
          <rect x="7" y="72" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="40" rx="3" ry="3" width="185.48" height="16" />
          <circle
            cx="15.000609337796924"
            cy="47.460609337796924"
            r="9.190609337796923"
          />
          <circle cx="49" cy="130.27" r="1" />
          <rect x="36" y="72" rx="3" ry="3" width="222.58" height="16" />
          <rect x="7" y="109.56" rx="3" ry="3" width="252" height="2.3" />
          <rect x="7" y="130" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="130" rx="3" ry="3" width="222.08" height="16" />
          <rect x="36" y="170" rx="3" ry="3" width="222.08" height="16" />
          <rect x="36" y="210" rx="3" ry="3" width="222.08" height="16" />
          <rect x="7" y="250" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="250" rx="3" ry="3" width="222.08" height="16" />
          <rect x="7" y="600" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="600" rx="3" ry="3" width="222.08" height="16" />
          <circle
            cx="15.000609337796924"
            cy="648.0006093377968"
            r="9.190609337796923"
          />
          <rect x="36" y="640" rx="3" ry="3" width="222.08" height="16" />
          <rect x="7" y="680" rx="3" ry="3" width="16" height="16" />
          <rect x="36" y="680" rx="3" ry="3" width="222.08" height="16" />
        </content-loader>
      </template>
      <!--<img style="max-width: 100%;" src="./../../assets/sidebar_animation.gif" alt="">-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopicsSidebarLoader'
}
</script>

<style scoped></style>
