<template>
  <div class="h-100 d-flex flex-column">
    <InboxConversationHead :active-inbox-detail="activeInboxDetail">
    </InboxConversationHead>
    <clip-loader
      v-if="postLoader"
      class="d-inline-block align-middle mt-4 mr-2 ml-1"
      color="#989eb5"
      :size="'20px'"
    />
    <div v-else class="conversation_messages">
      <div
        v-if="post !== null"
        class="message m_in social_preview_no_tab _comment_post"
        style="margin-bottom: 14rem"
      >
        <div class="instagram_post_preview">
          <div
            v-if="
              post.element_details.post_attachment[0]['is_type'] === 'photo'
            "
            class="preview_images mt-0"
          >
            <img
              class="single_image"
              :src="post.element_details.post_attachment[0].post_image"
              alt=""
            />
          </div>
          <div
            v-if="
              post.element_details.post_attachment[0]['is_type'] ===
              'carousel_album'
            "
            class="preview_images"
          >
            <b-carousel
              id="carousel-1"
              :interval="4000"
              controls
              indicators
              background="#ababab"
              style="text-shadow: 1px 1px 2px #333"
            >
              <template
                v-for="attachment in post.element_details.post_attachment[0]
                  .post_image_album"
              >
                <b-carousel-slide
                  :key="attachment.post_image"
                  :img-src="attachment.post_image"
                ></b-carousel-slide>
              </template>
            </b-carousel>
          </div>
          <div
            v-else-if="
              post.element_details.post_attachment[0]['is_type'] === 'video'
            "
            class="_video"
          >
            <video
              class="w-100 h-100"
              controls
              :src="post.element_details.post_attachment[0].post_video"
            ></video>
          </div>

          <div class="sec_padding preview_desc">{{
            post.element_details.post_message
          }}</div>
          <div class="_instagram_comment">
            <div class="option_block d-flex align-items-center">
              <span class="span_item">
                <i class="cursor_default fal fa-heart"></i>
                <span class="like_text ml-2">{{
                  post.element_details.likes_count
                }}</span>
              </span>
              <span class="span_item">
                <i class="cursor_default fal fa-comment"></i>
                <span class="like_text ml-2">{{ total }}</span>
              </span>
              <div class="option_right ml-auto">
                <clip-loader
                  v-if="fetchCommentLoader"
                  class="d-inline-block align-middle mr-2"
                  color="#989eb5"
                  :size="'20px'"
                />
              </div>
              <!--//drop down filter-->
              <div
                v-if="postComments.length > 1"
                class="prevent_close_dropdown dropdown default_style_dropdown checkbox_dropdown option_dropdown"
                style="width: 150px"
              >
                <div
                  class="dropdown_header d-flex align-items-center"
                  data-toggle="dropdown"
                >
                  <span class="text"
                    ><b>Sort by:</b> {{ selectedFilter.value }}</span
                  >
                  <span class="ml-auto">
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </span>
                </div>

                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner">
                    <template v-for="type in filterTypes">
                      <li
                        :key="type.key"
                        class="block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200"
                        @click="
                          () => {
                            page = 1
                            postComments = []
                            selectedFilter = type
                            fetchPostAndComments()
                          }
                        "
                      >
                        <div
                          class="checkbox_input_image flex px-3 py-2 justify-between"
                        >
                          <input
                            :id="type.key"
                            :checked="type.key === selectedFilter.key"
                            type="checkbox"
                          />
                          <label
                            :for="type.key"
                            class="checkbox_right square_check_icon flex justify-between w-full"
                          >
                            {{ type.value }}
                          </label>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <span
              style="opacity: 0.5; padding: 10px 15px"
              class="like_text ml-auto font-weight-normal"
              >{{ commonMethods.timesAgo(elementDetails.updated_time) }}</span
            >

            <div
              v-for="(comment, index) in postComments"
              :key="comment.comment_id"
              class="comments_list"
            >
              <InstagramCommentSending
                v-if="comment.sending"
                :temp="temp"
                :is-root="true"
                :root-comment-id="comment.comment_id"
                :post="post"
                :comment="comment"
                :remove-comment="removeComment"
                :append-comment="appendComment"
              >
              </InstagramCommentSending>
              <InstagramCommentBox
                v-else
                :root-index="index"
                :sub-index="null"
                :root-comment-id="comment.comment_id"
                :root-comment="comment"
                :is-root="true"
                :post="post"
                :comment="comment"
                :replies-count="
                  typeof comment.comment_replies !== 'undefined'
                    ? comment.comment_replies.length
                    : 0
                "
                :show-replies="comment.show_replies"
                :set-can-write="setCanWrite"
                :set-can-show-replies="setCanShowReplies"
                :change-status="commentStatusChanged"
              />
              <!--//reply comment-->
              <div
                v-if="comment.comment_replies.length && comment.show_replies"
                class="comment-replies-container"
              >
                <div
                  v-for="(commentReply, replyIndex) in comment.comment_replies"
                  :key="commentReply.comment_id"
                  class="comment-replies-block"
                >
                  <InstagramCommentSending
                    v-if="commentReply.sending"
                    :temp="temp"
                    :is-root="false"
                    :root-comment-id="comment.comment_id"
                    :post="post"
                    :comment="commentReply"
                    :remove-comment="removeComment"
                    :append-comment="appendComment"
                  >
                  </InstagramCommentSending>
                  <InstagramCommentBox
                    v-else
                    :root-index="index"
                    :sub-index="replyIndex"
                    :root-comment-id="comment.comment_id"
                    :root-comment="comment"
                    :is-root="false"
                    :post="post"
                    :comment="commentReply"
                    :replies-count="0"
                    :show-replies="false"
                    :set-can-write="setCanWrite"
                    :set-can-show-replies="setCanShowReplies"
                    :change-status="commentStatusChanged"
                  />
                </div>
              </div>
              <InstagramReplyBox
                v-if="comment.can_write"
                class="comment-replies-container"
                style="border-radius: 5px; background: white"
                :active-inbox-detail="activeInboxDetail"
                :is-root="false"
                :root-comment-index="index"
                :root-comment-id="comment.comment_id"
                :temp="temp"
                :post="post"
                :replying-to="comment.replying_to"
                :remove-comment="removeComment"
                :append-comment="appendComment"
              >
              </InstagramReplyBox>
            </div>
            <p
              v-if="canLoadMore && !fetchCommentLoader"
              class="text-center previouse_conversation pb-2"
            >
              <span
                class="link pt-2 load-more-comments"
                @click="paginateResults"
              >
                <template>Load more comments</template>
              </span>
            </p>
            <InstagramReplyBox
              style="border-radius: 5px; background: white"
              :active-inbox-detail="activeInboxDetail"
              :is-root="true"
              :root-comment-index="null"
              :root-comment-id="null"
              :temp="temp"
              :post="post"
              :replying-to="null"
              :remove-comment="removeComment"
              :append-comment="appendComment"
            >
            </InstagramReplyBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.link-mention {
  color: rgb(66, 192, 247);
  cursor: pointer;
}

textarea:disabled {
  background: white;
}

.comment-replies-container {
  background: rgba(229, 234, 238, 0.59);
  border-radius: 5px;
  margin-left: 30px;
  margin-top: 0.625rem;
}

.comment-replies-block {
  padding: 0.625rem;

  .profile_picture:first-child {
    margin-top: 0 !important;
  }
}

.load-more-comments {
  text-decoration: underline;
  color: #385898;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import InboxConversationHead from './InboxConversationHead'
import InstagramCommentBox from './reusable/instagram-post/InstagramCommentBox'
import InstagramReplyBox from './reusable/instagram-post/InstagramReplyBox'
import InstagramCommentSending from './reusable/instagram-post/InstagramCommentSending'

export default {
  components: {
    InboxConversationHead,
    InstagramCommentBox,
    InstagramReplyBox,
    InstagramCommentSending,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['activeInboxDetail'],

  data() {
    return {
      temp: this,
      postComments: [],
      post: null,
      elementDetails: null,
      commonMethods: commonMethods,
      instagram_post_channel: '',
      postLoader: true,
      fetchCommentLoader: false,
      selectedFilter: {
        key: 'newest',
        value: 'Newest',
      },
      filterTypes: [
        {
          key: 'all',
          value: 'Oldest',
        },
        {
          key: 'newest',
          value: 'Newest',
        },
      ],
      page: 1,
      limit: 7,
      canLoadMore: true,
      total: 0,
    }
  },
  computed: {},
  watch: {
    activeInboxDetail: async function (newVal, oldVal) {
      // watch it
      if (
        oldVal.element_details.element_id !== newVal.element_details.element_id
      ) {
        this.canLoadMore = true
        this.page = 1
        this.post = null
        this.postComments = []
        this.selectedFilter = {
          key: 'newest',
          value: 'Newest',
        }

        this.total = 0

        this.removeSocketListners(oldVal)
        this.bindPusherConversations()

        this.post = newVal
        await this.fetchPostAndComments()
        this.postLoader = false
      }
    },
  },

  created() {},
  mounted() {
    this.postLoader = true
    console.log('active post')
    this.post = this.activeInboxDetail
    this.fetchPostAndComments()
    commonMethods.setActiveUser(this)
    const pusher = pusherAuthSocketInbox(
      this.getProfile._id,
      this.getProfile.full_name,
      this.getProfile.email
    )

    this.instagram_post_channel = pusher.subscribe(
      'presence-instagram-post-' + this.activeInboxDetail.workspace_id
    )
    this.bindPusherConversations()
    this.postLoader = false
  },
  beforeDestroy() {
    this.removeSocketListners(this.activeInboxDetail)
    if (this.instagram_post_channel) {
      const tail =
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForCommentUpdate = 'channel-instagram-comment-' + tail

      this.instagram_post_channel.unbind(channelForCommentUpdate)
      this.instagram_post_channel.unsubscribe(
        'presence-instagram-post-' + this.activeInboxDetail.workspace_id
      )
    }
  },
  methods: {
    ...mapActions(['fetchPostComments']),
    paginateResults() {
      this.page += 1
      this.fetchPostAndComments()
    },
    commentStatusChanged(action, rootId, subId, response) {
      const index = this.findCommentIndex(rootId)
      switch (action) {
        case 'delete':
          if (subId != null) {
            this.$set(this.postComments, index, response.response)
          } else {
            this.$delete(this.postComments, index)
          }
          break
        default:
          this.$set(this.postComments, index, response.response)
          break
      }
    },
    removeComment(rootId, subId) {
      const rootIndex = this.findCommentIndex(rootId)
      if (subId != null) {
        const index = this.findSubCommentIndex(rootIndex, subId)

        this.$delete(this.postComments[rootIndex].comment_replies, index)
      } else {
        this.$delete(this.postComments, rootIndex)
      }
    },
    appendComment(type, comment, index) {
      if (type === 'new') {
        const commentIndex = this.postComments.findIndex(
          (x) => x.comment_id === comment.comment_id
        )
        if (commentIndex < 0) {
          this.$set(comment, 'show_replies', false)
          console.log('the comment to append .. ', comment)
          this.postComments.push(comment)
        }
      } else {
        if (
          typeof this.postComments[index] === 'undefined' ||
          this.postComments[index] === null
        ) {
          this.$set(comment, 'show_replies', false)
          this.postComments.push(comment)
        } else {
          console.log('a')
          if (
            this.postComments[index].comment_replies === null ||
            !this.postComments[index].comment_replies
          ) {
            console.log('2')
            this.$set(this.postComments[index], 'comment_replies', [])
          }
          console.log('b')
          const commentIndex = this.postComments[
            index
          ].comment_replies.findIndex(
            (x) => x.comment_id === comment.comment_id
          )
          if (commentIndex < 0) {
            console.log('1')
            this.postComments[index].comment_replies.push(comment)
          }
          console.log('c')
        }
      }
    },
    setCanShowReplies(comment) {
      this.$set(comment, 'show_replies', !comment.show_replies)
    },
    setCanWrite(comment, replyText) {
      this.$set(comment, 'can_write', true)
      this.$set(comment, 'replying_to', '@' + replyText + ' ')
    },

    async fetchPostAndComments() {
      if (this.fetchCommentLoader === false) {
        this.elementDetails = this.post.element_details
        this.fetchCommentLoader = true
        const resp = await this.fetchPostComments({
          post_id: this.elementDetails.element_id,
          workspace_id: this.post.workspace_id,
          platform: this.post.platform,
          sort_order: this.selectedFilter.key.toLowerCase(),
          page: this.page,
          limit: this.limit,
        })
        if (resp.isValid) {
          if (resp.comments.length > 0) {
            if (resp.comments.length < this.limit) {
              this.canLoadMore = false
            } else {
              this.canLoadMore = true
            }
            for (var comment of resp.comments) {
              this.$set(comment, 'show_replies', false)
              this.postComments.push(comment)
            }
          }
          this.total = resp.total
          this.fetchCommentLoader = false
        } else {
          this.canLoadMore = false
          this.fetchCommentLoader = false
        }
      }
    },
    getPlaceholder() {
      const getter = this.$store.getters.getInstagramAccounts
      // eslint-disable-next-line no-prototype-builtins
      const channelItems = getter.hasOwnProperty('items')
        ? getter.items
        : getter
      const temp = this
      const channelItem = channelItems.filter(function (channel) {
        return channel.instagram_id === temp.post.platform_id
      })
      if (channelItem.length > 0) {
        return 'Write comment as ' + channelItem[0].name + ' ...'
      } else {
        return 'Write a comment ...'
      }
    },
    findSubCommentIndex(rootIndex, commentId) {
      return this.postComments[rootIndex].comment_replies.findIndex(
        (x) => x.comment_id === commentId
      )
    },
    findCommentIndex(commentId) {
      return this.postComments.findIndex((x) => x.comment_id === commentId)
    },
    bindPusherConversations() {
      const tail =
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForCommentUpdate = 'channel-instagram-comment-' + tail
      const temp = this

      this.instagram_post_channel.bind(channelForCommentUpdate, (data) => {
        console.log('receiving on --> ', channelForCommentUpdate)
        console.log(data.comment)
        const comment = data.comment
        if (
          comment.post_id ===
            temp.activeInboxDetail.element_details.element_id &&
          comment.workspace_id === temp.activeInboxDetail.workspace_id
        ) {
          if (comment.is_reply) {
            const index = this.findCommentIndex(comment.reply_of)
            this.appendComment('reply', comment, index)
          } else {
            this.appendComment('new', comment, -1)
          }
        }
      })
    },

    removeSocketListners(value) {
      const tail = value.element_details.element_id + '-' + value.workspace_id
      const channelForCommentUpdate = 'channel-instagram-comment-' + tail

      this.instagram_post_channel.unbind(channelForCommentUpdate)
    },
  },
}
</script>
