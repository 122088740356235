<template>
  <div>
    <template v-if="isTopic || automation">
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown mr-2 ml-2 topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region"
        no-caret
      >
        <template slot="button-content">
          <i class="flag_icon studio-theme-icon far fa-globe"></i>
          <span class="capitalize_text">{{ getTopicsRegion.name }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <div class="topics-filters-pane__container__right_section">
          <div class="search-bar-input p-2">
            <div class="search-bar-inner">
              <input
                type="text"
                v-model="search_region"
                placeholder="Search by country name"
              />
              <button class="search_btn">
                <i class="icon-Search"></i>
              </button>
            </div>
          </div>

          <!--<input type="text"-->
          <!--v-model="search_region"-->
          <!--placeholder="Search by country name"-->
          <!--class="search-bar-input search-bar-input-discovery with-icon-left">-->
          <!--<i-->
          <!--class="icon-Search"></i>-->
        </div>

        <b-dd-divider />

        <template v-if="sortedRegions.length > 0">
          <b-dropdown-item
            v-for="region in sortedRegions"
            :active="getTopicsRegion.code === region.code"
            :key="region.code"
            variant="studio-theme"
            @click.prevent="changeRegion('topics', region)"
          >
            <template v-if="region.flag"
              ><span class="region_flag" v-html="region.flag"></span>
            </template>
            <template v-if="!region.flag && region.code === 'world'"
              ><i class="far fa-globe globe_icon"></i
            ></template>
            {{ region.name }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <p
            class="topics-filters-pane__container__right_section__dropdown__no_matches"
          >
            No matches found
          </p>
        </template>
      </b-dropdown>
    </template>
    <template v-else>
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown mr-2 ml-2 topics-filters-pane__container__right_section__dropdown topics-filters-pane__container__right_section__dropdown__region"
        no-caret
      >
        <template slot="button-content">
          <i class="flag_icon studio-theme-icon far fa-globe"></i>
          <span class="capitalize_text">{{ getArticlesRegion.name }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <div class="topics-filters-pane__container__right_section">
          <div class="search-bar-input p-2">
            <div class="search-bar-inner">
              <input
                type="text"
                v-model="search_region"
                placeholder="Search by country name"
              />
              <button class="search_btn">
                <i class="icon-Search"></i>
              </button>
            </div>
          </div>

          <!--<input type="text"-->
          <!--v-model="search_region"-->
          <!--placeholder="Search by country name"-->
          <!--class="search-bar-input search-bar-input-discovery with-icon-left">-->
          <!--<i-->
          <!--class="icon-Search"></i>-->
        </div>
        <b-dd-divider />

        <template v-if="sortedRegions.length > 0">
          <b-dropdown-item
            v-for="region in sortedRegions"
            :active="getArticlesRegion.code === region.code"
            :key="region.code"
            variant="studio-theme"
            @click.prevent="changeRegion('articles', region)"
          >
            <template v-if="region.flag"
              ><span class="region_flag" v-html="region.flag"></span>
            </template>
            <template v-if="!region.flag && region.code === 'world'"
              ><i class="far fa-globe globe_icon"></i
            ></template>
            {{ region.name }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <p
            class="topics-filters-pane__container__right_section__dropdown__no_matches"
          >
            No matches found
          </p>
        </template>
      </b-dropdown>
    </template>
  </div>
</template>
<script>
import { filterLimitedRegions } from '@common/constants/regions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import orderBy from 'lodash/orderBy'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  data () {
    return {
      search_region: ''
    }
  },
  computed: {
    ...mapGetters(['getArticlesRegion', 'getTopicsRegion']),
    sortedRegions () {
      const regionsList = orderBy(
        filterLimitedRegions.slice(1, filterLimitedRegions.length),
        ['name'],
        ['asc']
      )
      regionsList.unshift(filterLimitedRegions[0])
      return regionsList
    }
  },
  methods: {
    ...mapActions([
      'articles',
      'getContent',
      'topicsContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations(['SET_TOPICS_REGION', 'SET_TOPICS_LANGUAGE']),

    async changeRegion (type, value) {
      switch (type) {
        case 'topics':
          this.SET_TOPICS_REGION(value)
          this.SET_TOPICS_LANGUAGE()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        case 'articles':
          await this.getContent({ filter: { key: 'region', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        default:
          break
      }
    }
  }
}
</script>
