<template>
  <div>
    <ChartRender
      :id="'engagement_rate_highlight'"
      :options="engagementRate()"
      :title="'Engagement'"
      :ref_value="'page_impressions_chart'"
      :is_content_available="isContentNotAvailable"
      :tooltip_message="tooltip_message"
    ></ChartRender>

    <!--Engagement Rate table-->
    <div class="card_container align_70_30">
      <div class="card_v1">
        <div class="card_inner">
          <div class="card_head">
            <h2>Engagement Rate</h2>
          </div>
          <div class="card_content align_chart_items">
            <div class="d-flex align-items-center">
              <ul
                :id="post_type_id"
                class="item_highlight_inline item_highlight horizontal"
              ></ul>
              <div class="chart">
                <highcharts
                  :ref="post_type_id"
                  :options="chartRate()"
                ></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card_v1">
        <div class="box_no_icon_left box_no_icon_left_analytic">
          <div class="box_inner">
            <div class="text">
              <p class="top_text">Total Engagement</p>
              <h2>{{ $filters.numberToCommas(comments + repins) }}</h2>
              <!--green-->
              <!--<p v-if="getLinkedinEngagementRate()<0" class="bottom_text red">
                                <i class="cs-arrow-down "></i>
                                <span>{{getLinkedinEngagementRate()*-1}}%</span>
                            </p>
                            <p v-else class="bottom_text green">
                                <i class="cs-arrow-up "></i>
                                <span>{{getLinkedinEngagementRate()}}%</span>
                            </p>-->
              <!--<p class="bottom_text dark">-->
              <!--since previous date range-->
              <!--</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import {
  NO_CHART_DATA,
  PINTEREST_ANALYTICS_ENGAGEMENT
} from '@common/constants/messages'
import {
  pieChart,
  pieDataLabels,
  piePlotOptions,
  pieTitle,
  pieTooltip,
  splineChart,
  splineCredits,
  splineLegend,
  splinePlotOptions,
  splineTooltip,
  splineXAxis,
  splineYAxis,
  stackedColumnChart,
  stackedPlotOptions
} from '../../common/helper'

import ChartRender from '../../common/ChartRender'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts,
    ChartRender
  },
  props: {
    posts: {},
    repins: {},
    comments: {},
    total_engagement: {}
  },

  data () {
    return {
      noEngagementRateLinkedin: false,
      noData: NO_CHART_DATA,
      tooltip_message: PINTEREST_ANALYTICS_ENGAGEMENT,
      post_type_id: 'engagement_rate_pinterest_highlight'
    }
  },

  computed: {
    totalDocs () {
      let count = 0
      this.posts.buckets.forEach((item) => {
        count += item.doc_count
      })
      return count
    },
    isContentNotAvailable () {
      return this.posts.buckets.length > 0 && this.totalDocs === 0
    }
  },
  mounted () {},
  methods: {
    engagementRate () {
      const comments = []
      const repins = []

      this.posts.buckets.forEach((item) => {
        const parsedDate = moment(item.key_as_string).unix() * 1000
        comments.push([
          parsedDate,
          item.comments.value ? Math.round(item.comments.value) : 0
        ])
        repins.push([
          parsedDate,
          item.repins.value ? Math.round(item.repins.value) : 0
        ])
      })

      if (this.isContentNotAvailable) {
        comments.forEach((item) => {
          item[1] = this.randomNumber()
        })
        repins.forEach((item) => {
          item[1] = this.randomNumber()
        })
      }

      return {
        credits: splineCredits(),
        tooltip: splineTooltip(),
        chart: stackedColumnChart('#engagement_rate_highlight'),
        legend: splineLegend(),

        colors: ['#ffdf46', '#ffcc80', '#27ca7a'],
        title: false,
        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        plotOptions: stackedPlotOptions(),
        series: [
          {
            name: 'Comments',
            data: comments
          },
          {
            name: 'Repins',
            data: repins
          }
        ]
      }
    },
    chartRate () {
      const tooltip = pieTooltip()
      tooltip.pointFormat =
        '<p class="engagement_point"><span><b>{point.y}</b> {point.name}</span></p>'
      const plot = piePlotOptions()
      plot.pie.dataLabels = pieDataLabels()
      return {
        credits: splineCredits(),
        chart: pieChart(`#${this.post_type_id}`, true),
        plotOptions: plot,
        colors: ['#fb8c00', '#ffcc80', '#27ca7a'],
        tooltip: tooltip,
        title: pieTitle(),
        series: [
          {
            id: `#${this.post_type_id}`,
            name: 'Engagement Rate',
            size: '80%',
            innerSize: '55%',
            data: [
              // ['Impressions', this.interaction.facebook.total_impressions.value],
              ['Comments', this.comments],
              ['Repins', this.repins]
            ]
          }
        ]
      }
    }
  }
}
</script>
