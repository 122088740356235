<template>
    <div v-if="isOpen" v-on-clickaway="handleClickAway"
         class="w-max absolute -right-24 rounded-lg dropdown-shadow  bg-white   focus:outline-none z-50" style="min-width:18rem;max-width:18rem;max-height:30rem;overflow:hidden">
      <div class="p-3">
        <span class="text-gray-700 block w-full py-2"> Search & Add Location To Posts</span>

<!--        <CstInputFields type="text"  placeholder="Search Location" class="w-100 my-1 !rounded-md" size="small" @value="handleInput"></CstInputFields>-->
            <div class="mt-1 flex items-center cursor-pointer p-1.5 rounded-md bg-cs-secondary" style="flex-flow: row nowrap;">
              <div v-if="(type === 'common' && accounts.facebook ) || type === 'facebook' " class="p-2.5 h-9 w-full  flex justify-center items-center rounded-md"
                   :class="{ 'bg-social-facebook text-white': activeLocation === 'facebook' || type === 'facebook' }"
                   @click="handleActiveLocation('facebook')">
                <i class="fab fa-facebook"></i>

              </div>
              <div v-if="(type === 'common' && accounts.instagram ) || type === 'instagram' "
                   class="p-2.5 h-9 w-full flex justify-center items-center rounded-md"
                   :class="{ 'bg-social-instagram text-white': activeLocation === 'instagram' || type === 'instagram' }"
                   @click="handleActiveLocation('instagram')">
                <i class="fab fa-instagram"></i>
              </div>
              <div v-if="(type === 'common' && accounts.twitter ) || type === 'twitter' "
                   class="p-2.5 h-9 w-full flex justify-center items-center rounded-md"
                   :class="{ 'bg-social-twitter text-white': activeLocation === 'twitter' || type === 'twitter' }"
                   @click="handleActiveLocation('twitter')">
                <i class="fab fa-twitter"></i>
              </div>
            </div>
        <div v-if="getActiveLocation && getActiveLocation.id"
             class="bg-cs-secondary px-1.5 py-1 -mt-1 flex justify-center items-center w-full text-sm text-black-700">
          {{ getActiveLocation.name }}
          <i class="fal fa-times p-1 cursor-pointer text-red-500 hover:text-red-900 ml-auto"
             @click="removeLocation"></i>
        </div>
        <input v-model="keyword" type="text" placeholder="Search Location"
               class="my-1 w-full px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none bg-cs-light-gray"
               @input="handleInput">
        <div v-if="locationLoader" class="py-2" style="min-height:20px;">
          <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
        </div>
        <template v-else>
          <div v-if="locations.length > 0" class="my-2 overflow-y-scroll" style="max-height:20rem;">
            <template v-for="(item,index) in locations">
              <div :key="index"
                   class="px-3 py-2.5 text-sm text-black-700 block cursor-pointer hover:bg-gray-100"
                   @click="addLocation(item)">{{ item.name }}
              </div>
            </template>
          </div>
        </template>
        <div v-if="activeLocation === 'twitter' && !locationLoader && !locations.length" class="bottom_message">
          <span class="text-sm">
            <i class="fa fa-info-circle"></i>
            Enable Tweet Locations on Twitter.
            <a href="https://twitter.com/settings/safety" target="_blank">
              Learn How
            </a>
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import _debounce from "lodash.debounce";
import proxy from "@common/lib/http-common";
import {searchLocationsURL} from "@src/modules/publish/config/api-utils";
export default {
  name: "LocationDropdown",
  components: {},
  props:{
    type: {
      type: String,
      default: 'common',
      required: true
    },
    accounts:{
      type: Object,
      default:()=>({}),
    },
    location: {
      type: Object,
      default:()=>({}),
      required: false
    },
    isOpen:{
      type: Boolean,
      default:false,
    }
  },
  data () {
    return {
      activeLocation: this.accounts.facebook ? 'facebook' : this.accounts.instagram ? 'instagram' : 'twitter',
      keyword: '',
      locationLoader: false,
      locations: [],
    }
  },
  mounted() {
    console.debug('Location Dropdown mounted')
  },
  computed:{
    getActiveLocation(){
      if(this.type === 'common'){
        if(this.activeLocation === 'facebook' && (this.location && this.location.facebook)){
          return this.location.facebook
        }
        else if(this.activeLocation === 'instagram' && (this.location && this.location.instagram)) {
          return this.location.instagram
        }
        else if(this.activeLocation === 'twitter' && (this.location && this.location.twitter)) {
          return this.location.twitter
        }
      }
      else{
        if(this.location && this.location.id){
          return this.location
        }
      }
      return 0
    },
    locationIndication(){
      if(this.type === 'common'){
        if(this.accounts.facebook && this.location.facebook && this.location.facebook.id){
          return true
        }
        else if(this.accounts.instagram && this.location.instagram && this.location.instagram.id) {
          return true
        }
        else if(this.accounts.twitter && this.location.twitter && this.location.twitter.id) {
          return true
        }
      }
      else{
        if(this.location && this.location.id){
          return true
        }
      }
      return false
    }
  },
  watch:{
    activeLocation(){
      if(this.keyword) this.fetchLocations()
      else this.locations = []
    },
    accounts(val){
      if(!val[this.activeLocation]) {
        this.activeLocation = val['facebook'] ? 'facebook' : val['instagram'] ? 'instagram' : 'twitter'
      }
    }
  },
  methods:{
    handleClickAway(){
      this.$emit('handle-click',this.isOpen,'location')
    },
    handleActiveLocation(location){
      this.activeLocation = location
    },
    addLocation(location){
      this.$emit('add-location',this.activeLocation,location)
    },
    removeLocation(){
      this.$emit('add-location',this.activeLocation,{id:'',name:''})
    },
    handleInput: _debounce(function () {
      this.fetchLocations()
    }, 1000),
    fetchLocations(){
      this.locationLoader = true
      proxy
          .post(searchLocationsURL, {
            keyword: this.keyword,
            type: this.activeLocation.toUpperCase()
          })
          .then((resp) => {
            if(resp.data.status) {
              this.locationLoader = false
              this.locations = resp.data.locations ? resp.data.locations : []
            }
            else {
              this.locations = []
              this.locationLoader = false
            }
          })
          .catch((error) => {
            console.debug('exception in location search',error)
            this.locationLoader = false
            this.locations = []
          })

    }
  },
  beforeDestroy() {
    this.$off('add-location')
  }
}
</script>

<style lang="less" scoped>
.active-tab{
  @apply bg-cs-primary text-white;
}
</style>