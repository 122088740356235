import {
  fetchNotificationsUrl,
  saveNotificationsUrl
} from '../../config/api-utils'
import proxy from '@common/lib/http-common'

const defaultState = {
  notifications_preference: {
    preference_list: [],
    loaders: {
      preference: false,
      save: false
    },
    browser_alert: false
  }
}

const state = defaultState

// getters
const getters = {
  getNotificationsPreference: (state) => {
    return state.notifications_preference.preference_list
  },
  getNotificationsPreferenceLoader: (state) => {
    return state.notifications_preference.loaders.preference
  },
  getNotificationsPreferenceBrowserAlert: (state) => {
    return state.notifications_preference.browser_alert
  }
}

// actions
const actions = {
  fetchNotificationsPreference ({
    commit,
    getters,
    dispatch,
    rootGetters,
    state
  }) {
    commit('SET_PREFERENCE_LOADER', true)
    proxy
      .post(fetchNotificationsUrl, {})
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_PREFERENCE_LIST', resp.data.preference)
          commit('SET_PREFERENCE_LOADER', false)
          dispatch('isNoAccessForBrowserNotification')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  saveNotificationsPreference ({ commit, getters }) {
    proxy
      .post(saveNotificationsUrl, {
        payload: getters.getNotificationsPreference
      })
      .then((resp) => {})
      .catch((error) => {
        console.log(error)
      })
  },

  isNoAccessForBrowserNotification ({ commit }) {
    console.debug('Method:checkBrowserNotificationEnabled')
    if (
      Notification.permission === 'default' ||
      Notification.permission === 'denied'
    ) {
      // no access given, show alert message
      commit('SET_BROWSER_ALERT', true)
      return true
    }

    // access given
    commit('SET_BROWSER_ALERT', false)
    return true
  },

  // enable notifications for the user.
  enableNotifications ({ commit }) {
    if (Notification.permission === 'denied') {
      window.open(
        'https://docs.contentstudio.io/article/493-enabling-disabling-browser-notifications'
      )
    } else {
      Notification.requestPermission()
      commit('SET_BROWSER_ALERT', false)
    }
  },
  resetNotificationsPreference ({ commit }) {
    commit('RESET_NOTIFICATIONS_PREFERENCE')
  }
}

// mutations
const mutations = {
  SET_PREFERENCE_LIST (state, preference) {
    state.notifications_preference.preference_list = preference
  },
  SET_PREFERENCE_LOADER (state, value) {
    state.notifications_preference.loaders.preference = value
  },
  SET_BROWSER_ALERT (state, value) {
    state.notifications_preference.browser_alert = value
  },
  RESET_NOTIFICATIONS_PREFERENCE (state) {
    // this will not work
    // state = JSON.parse(JSON.stringify(defaultState))
    state.notifications_preference.preference_list = []
    state.notifications_preference.loaders = {
      preference: false,
      save: false
    }
    state.notifications_preference.browser_alert = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
