<template>
  <div>
    <transition-group name="listview-items" tag="div" appear>
      <div
        v-for="(post, index) in getPosts"
        :key="'list_' + post._source.id"
        class="article_box_list"
      >
        <div
          v-if="post._source"
          class="box_inner"
          :class="{ shared: post._source.shared }"
        >
          <div class="d-flex">
            <div class="image_block">
              <div v-if="post._source.categories" class="tags_block">
                <span v-for="category in post._source.categories" class="tag capitalize">{{
                  category
                }}</span>
              </div>
              <div class="article_type">
                <span
                  v-if="post._source.read_time && post._source.read_time == 1"
                >
                  Less than 1 Min. Read
                </span>
                <span
                  v-if="post._source.read_time && post._source.read_time > 1"
                >
                  {{ post._source.read_time }} Min. Read
                </span>
                -
                <span v-if="post._source.has_abstract">Abstract Content</span>
                <span v-else>Full Content</span></div
              >
              <div
                v-lazy:background-image="backgroundImageURL(post._source)"
                class="img"
              ></div>
            </div>
            <div class="content_block">
              <div class="d-flex">
                <div class="left_content">
                  <h2
                    :title="limitTextLength(post._source.title, 0)"
                    @click.prevent="
                      articlePreview({ id: post._source.id, index: index })
                    "
                    v-html="limitTextLength(post._source.title, 100)"
                  ></h2>
                  <div class="detail clear">
                    <p class="text_row">
                      <a href="javascript:;">{{
                        trimURL(post._source.domain_url)
                      }}</a>

                      <!--<a class="left_link" href="">Insight</a>
                                            <a class="left_link" href="">All Articles</a>
                                            <a class="left_link" href="">Block Globally</a>-->
                    </p>
                    <p class="text_row">{{
                      $filters.relative(post._source.post_date)
                    }}</p>
                    <p class="author_detail">
                      <template v-if="post._source.author">
                        By
                        <span
                          style="color: #4e92ff"
                          v-html="post._source.author"
                        ></span>
                        <a
                          v-if="post._source.twitter_handler"
                          target="_blank"
                          :href="getTwitterLink(post._source.twitter_handler)"
                        >
                          {{ post._source.twitter_handler }}</a
                        >
                        <a
                          v-for="(account, index) in post._source
                            .twitter_accounts"
                          v-else-if="post._source.twitter_accounts && index < 1"
                          target="_blank"
                          :href="getTwitterLink(account)"
                        >
                          {{ account }}</a
                        >
                      </template>
                      <template v-else-if="post._source.twitter_handler">
                        By
                        <a
                          target="_blank"
                          :href="getTwitterLink(post._source.twitter_handler)"
                          >{{ post._source.twitter_handler }}</a
                        >
                      </template>
                      <template
                        v-else-if="
                          post._source.twitter_accounts &&
                          post._source.twitter_accounts.length > 0
                        "
                      >
                        By
                        <a
                          v-for="(account, index) in post._source
                            .twitter_accounts"
                          v-if="post._source.twitter_accounts && index < 1"
                          target="_blank"
                          :href="getTwitterLink(account)"
                          >{{ account }}</a
                        >
                      </template>
                    </p>
                    <!--
                                        Previous method commented.
                                        <div class="author_detail" v-if="post._source.author">
                                             <p>By <span v-html="post._source.author"></span> <a v-if="post._source.twitter_accounts" v-for="account in post._source.twitter_accounts" href="">{{account}}</a></p>
                                        </div>
                                        -->
                    <div class="tag_section">
                      <template
                        v-for="item in post._source.topics"
                        v-if="isTopicsDisplayAllowed(post)"
                      >
                        <span
                          v-for="finalized_topic in checkIfMultipleTopics(
                            item.label,
                          )"
                          @click.prevent="
                            changeCuratedTopicLabels(item, finalized_topic)
                          "
                        >
                          {{ finalized_topic }}
                        </span>
                      </template>
                    </div>

                    <div
                      v-if="post._source.top_twitter_influencers"
                      class="top_influencers d-inline-block"
                    >
                      <div
                        v-if="post._source.total_influencers_shares"
                        class="d-inline-block influencer_left"
                      >
                        <p
                          >Shared by
                          <strong>{{
                            $filters.numberToCommas(post._source.total_influencers_shares)
                          }}</strong>
                          influencers</p
                        >
                      </div>
                      <!--                                          v-if="influencer.follower_count > 1000"-->
                      <!--                                          v-if="index <= 3"-->
                      <!--                                          @click.prevent="showSharers(post._source.id)"-->
                      <div
                        v-if="post._source.total_influencers_shares > 0"
                        class="d-inline-block influencer_right ml-3"
                      >
                        <template
                          v-for="(influencer, index) in post._source
                            .top_twitter_influencers"
                        >
                          <a
                            v-tooltip="{
                              content: accountTooltipHtml(
                                'influencer',
                                influencer,
                              ),
                              classes: 'team_tooltip',
                            }"
                            target="_blank"
                            :href="
                              'https://twitter.com/' + influencer.screen_name
                            "
                          >
                            <div
                              v-if="influencer.profile_image"
                              class="img"
                              :style="{
                                background:
                                  'url(' + influencer.profile_image + ')',
                              }"
                            ></div>
                            <div
                              v-else
                              class="img"
                              style="
                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                              "
                            ></div>
                          </a>
                        </template>
                        <!--                                                <div @click.prevent="showSharers(post._source.id)" class="rounded_more"-->
                        <!--                                                     v-if="post._source.top_twitter_influencers.length > 3 && post._source.total_influencers_shares - 4 > 0">-->
                        <!--                                                    +{{post._source.total_influencers_shares - 4}}-->
                        <!--                                                </div>-->
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="post._source.related_ids_count > 1"
                    class="similar_content"
                    @click.prevent="
                      retrieveSimilarArticles(post._source.id, post._index)
                    "
                    >{{ post._source.related_ids_count }}
                    similar articles
                  </div>
                </div>
                <div class="right_content">
                  <div class="social_stats">
                    <div
                      class="social_stats_inner d-flex justify-content-center flex-column"
                    >
                      <div class="stat_item custom_tooltip">
                        <div class="name">
                          <p>Engagement</p>
                        </div>
                        <div class="value">
                          <h3>{{
                            intToString(calculateEngagements(post._source))
                          }}</h3>
                        </div>

                        <div class="tool_tip_box top_center">
                          <div class="tool_tip_inner">
                            <div class="d-flex align-items-center">
                              <div
                                v-tooltip.top-center="'Facebook Shares'"
                                class="engagement_stat"
                              >
                                <span class="icon_item">
                                  <i class="fb cs-facebook"></i>
                                </span>

                                <span v-if="post._source.facebook_shares">
                                  {{ post._source.facebook_shares }}
                                </span>
                                <span v-else>0</span>
                              </div>
                              <div
                                v-tooltip.top-center="'Twitter Shares'"
                                class="engagement_stat"
                              >
                                <span class="icon_item">
                                  <i class="tw cs-twitter"></i>
                                </span>
                                <span v-if="post._source.twitter_shares">{{
                                  post._source.twitter_shares
                                }}</span>
                                <span v-else>0</span>
                              </div>
                              <div
                                v-tooltip.top-center="'Pinterest Shares'"
                                class="engagement_stat"
                              >
                                <span class="icon_item">
                                  <i class="pin cs-pinterest"></i>
                                </span>
                                <span v-if="post._source.pins">{{
                                  post._source.pins
                                }}</span>
                                <span v-else>0</span>
                              </div>
                              <div
                                v-tooltip.top-center="'Reddit Shares'"
                                class="engagement_stat"
                              >
                                <span class="icon_item">
                                  <i class="reddit cs-reddit"></i>
                                </span>
                                <span v-if="post._source.reddit">{{
                                  post._source.reddit
                                }}</span>
                                <span v-else>0</span>
                              </div>
                              <!--<div class="engagement_stat">
                                                                <i class="fire_icon cs cs-icon-fire"></i>
                                                                <span>454</span>
                                                            </div>-->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          post._source.velocity && post._source.velocity > 5
                        "
                        class="stat_item"
                      >
                        <div class="name">
                          <p>Trending Score</p>
                        </div>
                        <div class="value">
                          <h3>{{ intToString(post._source.velocity) }}</h3>
                        </div>
                      </div>
                      <SentimentIcon :post="post"></SentimentIcon>
                    </div>
                  </div>
                  <div
                    class="btn_block d-flex justify-content-center flex-column"
                  >
                    <div
                      class="favorite_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                    >
                      <div
                        v-if="post._source.is_favorite"
                        class="dropdown_header"
                        @click.prevent="removeFavorite(post._source.id, index)"
                      >
                        <button v-tooltip.top-center="'Unfavorite'" class="btn">
                          <i
                            class="favorite_icon_orange far fa-heart d-block"
                          ></i>
                        </button>
                      </div>
                      <div
                        v-show="post._source.is_favorite == false"
                        class="dropdown_header"
                        data-toggle="dropdown"
                      >
                        <button
                          v-if="post._source.is_favorite == false"
                          v-tooltip.top-center="'Add to Favorites'"
                          class="btn light_gray"
                        >
                          <i class="favorite_icon far fa-heart d-block"></i>
                        </button>
                      </div>
                      <FavoriteDropdown
                        :post_id="post._source.id"
                        :post-index="index"
                      ></FavoriteDropdown>
                    </div>

                    <div
                      class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                    >
                      <div class="dropdown_header" data-toggle="dropdown">
                        <button
                          v-tooltip.top-center="'Share this post'"
                          class="btn light_gray"
                        >
                          <i
                            class="far fa-share-alt d-block"
                            :class="{ shared_posts: post._source.shared }"
                          ></i>
                          <span
                            v-if="post._source.shared_count"
                            class="circle_count"
                            >{{ post._source.shared_count }}</span
                          >
                        </button>
                      </div>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="inner">
                          <li
                            class="list_item_li"
                            @click.prevent="
                              initializeArticlePostCreation(
                                post._source.id,
                                'social_share_modal',
                              )
                            "
                          >
                            <i class="far fa-share share-ico-align"></i>
                            <span class="icon_text">Social Media</span>
                          </li>
                          <li
                            class="list_item_li"
                            @click.prevent="
                              initializeArticlePostCreation(
                                post._source.id,
                                'blog_share_modal',
                              )
                            "
                          >
                            <i class="far fa-rss"></i>
                            <span class="icon_text">Blog Post</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="article_post_dropdown inline_block dropdown default_style_dropdown"
                    >
                      <div class="dropdown_header">
                        <button
                          v-if="
                            getTopicType === 'archived' &&
                            $route.params &&
                            $route.params.module === 'archived'
                          "
                          v-tooltip.top-center="'Unarchive Post'"
                          class="btn blue"
                          @click.prevent="
                            unarchive({
                              post_id: post._source.id,
                              index: index,
                            })
                          "
                        >
                          <i class="groups2_icon far fa-archive d-block"></i>
                        </button>
                        <button
                          v-else
                          v-tooltip.top-center="'Archive Post'"
                          class="btn blue"
                          @click.prevent="
                            archive({ post_id: post._source.id, index: index })
                          "
                        >
                          <i class="groups2_icon far fa-archive d-block"></i>
                        </button>
                      </div>
                    </div>

                    <div
                      class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                    >
                      <div class="dropdown_header" data-toggle="dropdown">
                        <button
                          v-tooltip.top-center="'More options'"
                          class="btn light_gray"
                        >
                          <i class="cs-dots-h d-block"></i>
                        </button>
                      </div>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="inner">
                          <li class="list_item_li">
                            <a
                              target="_blank"
                              :href="savePocketContent(post._source.url)"
                            >
                              <i class="fab fa-get-pocket"></i>
                              <span class="icon_text">Add to Pocket</span>
                            </a>
                          </li>
                          <li
                            class="list_item_li"
                            @click.prevent="showSharers(post._source.id)"
                          >
                            <i class="far fa-users-crown"></i>
                            <span class="icon_text">Influencers</span>
                          </li>
                          <template
                            v-if="
                              getTopicType != 'archived' &&
                              getTopicType != 'favorite'
                            "
                          >
                            <li
                              class="list_item_li"
                              @click.prevent="blockSource(post._source)"
                            >
                              <i class="far fa-ban"></i>
                              <span class="icon_text">Block Source</span>
                            </li>
                          </template>
                          <!--<template v-if="getTopicType === 'archived' && ($route.params && $route.params.module === 'archived')">-->
                          <!--<li class="list_item_li "-->
                          <!--@click.prevent="unarchive({post_id: post._source.id, index: index})">-->
                          <!--<i class="cs-archive"></i>-->
                          <!--<span class="icon_text">Unarchive Post</span>-->
                          <!--</li>-->
                          <!--</template>-->
                          <!-- <template v-else-if="getTopicType !='favorite'">
                                                        <li class="list_item_li "
                                                            @click.prevent="archive({post_id: post._source.id, index: index})">

                                                            <i class="cs-archive"></i>
                                                            <span class="icon_text">Archive Post</span>
                                                        </li>
                                                    </template>-->
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn_block_footer">
            <div
              class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
            >
              <div class="dropdown_header" data-toggle="dropdown">
                <button
                  v-tooltip.top-center="'Share this post'"
                  class="btn light_gray"
                >
                  <i class="icon-Share_icon d-block"></i>
                  <!--<span class="circle_count">7</span>-->
                </button>
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    class="list_item_li"
                    @click.prevent="
                      initializeArticlePostCreation(
                        post._source.id,
                        'social_share_modal',
                      )
                    "
                  >
                    <i class="cs-social-share"></i>
                    <span class="icon_text">Social Media</span>
                  </li>
                  <li
                    class="list_item_li"
                    @click.prevent="
                      initializeArticlePostCreation(
                        post._source.id,
                        'blog_share_modal',
                      )
                    "
                  >
                    <i class="cs-rss"></i>
                    <span class="icon_text">Blog Post</span>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="favorite_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
            >
              <div
                v-if="post._source.is_favorite"
                class="dropdown_header"
                @click.prevent="removeFavorite(post._source.id, index)"
              >
                <button v-tooltip.top-center="'Unfavorite'" class="btn">
                  <i class="favorite_icon_orange cs-star d-block"> </i>
                </button>
              </div>
              <div
                v-show="post._source.is_favorite == false"
                class="dropdown_header"
                data-toggle="dropdown"
              >
                <button
                  v-if="post._source.is_favorite == false"
                  v-tooltip.top-center="'Add to Favorites'"
                  class="btn light_gray"
                >
                  <i class="favorite_icon cs-star d-block"> </i>
                </button>
              </div>
              <FavoriteDropdown
                :post_id="post._source.id"
                :post-index="index"
              ></FavoriteDropdown>
            </div>

            <div
              class="article_post_dropdown inline_block dropdown default_style_dropdown"
            >
              <div class="dropdown_header">
                <button
                  v-tooltip.top-center="'Twitter Influencers'"
                  class="btn blue"
                  @click.prevent="showSharers(post._source.id)"
                >
                  <i class="groups2_icon far fa-users d-block"> </i>
                </button>
              </div>
              <!--<div class="dropdown-menu dropdown-menu-right">
                                <ul class="inner">
                                    <li class="list_item">
                                        <a class="dropdown-item" href="#">Link 1</a>
                                    </li>
                                </ul>
                            </div>-->
            </div>

            <div
              class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
            >
              <div class="dropdown_header" data-toggle="dropdown">
                <button
                  v-tooltip.top-center="'More options'"
                  class="btn light_gray"
                >
                  <i class="cs-dots-h d-block"> </i>
                </button>
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li class="list_item_li">
                    <a
                      target="_blank"
                      :href="savePocketContent(post._source.url)"
                    >
                      <i class="cs-pocket"></i>
                      <span class="icon_text">Add to Pocket</span>
                    </a>
                  </li>
                  <li
                    class="list_item_li"
                    @click.prevent="blockSource(post._source)"
                  >
                    <i class="cs-network"></i>
                    <span class="icon_text">Block Source</span>
                  </li>
                  <template
                    v-if="$route.params && $route.params.module === 'archived'"
                  >
                    <li
                      class="list_item_li"
                      @click.prevent="
                        unarchive({ post_id: post._source.id, index: index })
                      "
                    >
                      <i class="cs-archive"></i>
                      <span class="icon_text">Unarchive Post</span>
                    </li>
                  </template>
                  <template v-else>
                    <li
                      class="list_item_li"
                      @click.prevent="
                        archive({ post_id: post._source.id, index: index })
                      "
                    >
                      <i class="cs-archive"></i>
                      <span class="icon_text">Archive Post</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FavoriteDropdown from '../folders/FavoriteDropdown'
import SentimentIcon from './snippets/SentimentIcon'

export default {
  components: {
    FavoriteDropdown,
    SentimentIcon
  },
  computed: {
    ...mapGetters(['getPosts', 'getTopicType'])
  },
  methods: {
    ...mapActions(['archive', 'unarchive', 'articlePreview']),
    pocketModal (link) {}
  }
}
</script>

<style>
.listview-items {
  transition: all 600ms ease-in-out 50ms;
}

.listview-items-enter-active,
.listview-items-leave-active {
  transition: all 0.6s;
}

.listview-items-enter, .listview-items-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(45px);
}
</style>
