<template>
  <b-modal id="add_feeder_group" modal-class="add_slot" hide-footer hide-header>
    <div class="modal_head">
      <h4 v-if="getFeederGroupStore._id">Update Feed Group</h4>
      <h4 v-else>Create New Feed Group</h4>
      <button
        type="button"
        class="modal_head__close"
        @click="$bvModal.hide('add_feeder_group')"
      >
        &times;
      </button>
    </div>

    <div class="modal_body">
      <br />
      <p>A collection of sources or keyword alerts you want to read.</p>

      <div class="field_group">
        <input
          type="text"
          v-model="getFeederGroupStore.name"
          class="full_label"
          placeholder="Group name e.g Digital Marketing, News etc."
          :class="{
            'input-field-error': getFeederGroupValidations.fields.name,
          }"
          @keyup.enter="validateAndStoreGroup()"
        />

        <span class="input-error" v-if="getFeederGroupValidations.fields.name">
          {{ getFeederGroupValidations.messages.name }}</span
        >
      </div>

      <div class="field_group">
        <button
          @click="validateAndStoreGroup()"
          :disabled="loaders.store"
          class="btn btn-studio-theme-space"
        >
          <span>
            <template v-if="getFeederGroupStore._id">Update</template>
            <template v-else>Add</template>
          </span>
          <clip-loader
            color="#ffffff"
            :size="'12px'"
            v-if="loaders.store"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loaders: {
        store: false
      }
    }
  },
  computed: {
    ...mapGetters(['getFeederGroupStore', 'getFeederGroupValidations'])
  },
  methods: {
    async validateAndStoreGroup () {
      const validationFields = this.getFeederGroupValidations.fields
      this.$set(
        validationFields,
        'name',
        this.requiredCheck(this.getFeederGroupStore.name)
      )
      const result = Object.keys(validationFields).every(
        (k) => validationFields[k] === false
      )
      if (result) {
        this.loaders.store = true
        const res = await this.$store.dispatch('storeFeederGroup')
        this.loaders.store = false
        if (res && res.data && !res.data.status) {
          if (res.data.state === 'group_exists') {
            this.$set(this.getFeederGroupValidations.fields, 'name', true)
            this.$set(
              this.getFeederGroupValidations.messages,
              'name',
              'A group with that name already exists.'
            )
          }
        } else {
          console.log('[feeder group] -> modal created item')
          if (res.data.status) {
            console.log('[feeder group] -> status is valid', res.data.data)
            // if the user create the topic and he is on the view such as collection or the feed view, redirect the user to that specific group.
            const whitelistRoutes = [
              'feeder_follow_sources',
              'feeder_follow_categories',
              'feeder_follow_actions',
              'feeder_manage_sources',
              'feeder_opml_import'
            ]
            if (whitelistRoutes.indexOf(this.$route.name) < 0) {
              return this.$router.push({
                name: 'feeder_collection',
                params: { collection_id: res.data.data.uuid }
              })
            }
          }
        }
      }
    }
  },
  watch: {
    'getFeederGroupStore.name.length' (value) {
      if (value > 0) {
        this.$set(this.getFeederGroupValidations.fields, 'name', false)
      }
    }
  }
}
</script>
