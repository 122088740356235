<template>
  <div class="auth">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container auth-form-container--block">
          <div
            v-if="getSettingLoaders.joinTeamStatusLoader"
            class="auth-form-container__verifying"
          >
            <img
              src="../../../assets/img/onboarding/add_team_members.svg"
              alt=""
            />
            <p>Please wait verifying information...</p>
            <clip-loader
              class="align-middle d-inline-block"
              color="#4165ed"
              :size="'1.3rem'"
            ></clip-loader>
          </div>
          <form
            v-else
            class="auth-form"
            :class="{ loader_overlay: getSettingLoaders.joinTeamStatusLoader }"
          >
            <div class="auth-form__heading auth-form__heading--center">
              <h2>Join ContentStudio</h2>
              <p>Please enter your basic details.</p>
            </div>
            <CstFloatingLabelInput
                id="memberfirstName"
                v-model="first_name"
                class="mb-6"
                type="text"
                label="First Name"
                :maxlength="18"
                :readonly="getTeam.id"
                show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-User"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
                id="memberlastName"
                v-model="last_name"
                class="mb-6"
                type="text"
                label="Last Name"
                :maxlength="18"
                :readonly="getTeam.id"
                show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-User"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
                id="password"
                v-model="password"
                class="mb-6"
                type="password"
                label="Password"
                show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
                id="confirm_password"
                v-model="confirmPassword"
                class="mb-6"
                type="password"
                label="Confirm Password"
                show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>


            <div class="auth-form__button-field">
              <button
                :class="{ disabled: getSettingLoaders.joinTeamLoader }"
                :disabled="getSettingLoaders.joinTeamLoader"
                class="btn btn-studio-theme-space btn-size-large"
                type="submit"
                @click.prevent="
                  joinTeam(
                    first_name,
                    last_name,
                    password,
                    confirmPassword,
                    $route.params.reff,
                    $route.params.token
                  )
                "
              >
                <span>Join Now</span>
                <img
                  v-if="getSettingLoaders.joinTeamLoader"
                  alt=""
                  src="../assets/img/common/gif_loader_white.gif"
                  style="width: 20px; margin-left: 8px"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <FeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FeaturesSlider from '../components/FeaturesSlider'
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput";

export default {
  name: 'JoinTeamMember',
  components: {
    FeaturesSlider,
    CstFloatingLabelInput
  },
  data() {
    return {
      size: '14px',
      loaderColor: '#ffffff',
      first_name: '',
      last_name: '',
      confirmPassword: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getSettingLoaders']),
  },
  created() {
    this.getJoinTokenStatus()
  },

  methods: {
    ...mapActions([]),
  },
}
</script>

<style lang="scss" scoped>
.setting_profile_component {
  text-align: -webkit-center;
  padding: 0;
}
.setting_profile_component .ps_box:last-child {
  margin-bottom: 0;
}
.setting_profile_component .ps_box .profile_image_upload {
  padding: 8px 0;
  min-width: 150px;
  width: 130px;
  border: 1px solid rgba(58, 69, 87, 0.1);
}
</style>
