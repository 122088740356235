import { mapActions, mapGetters, mapMutations } from 'vuex'

export const commentsMixin = {
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getBlogPostingDetails',
      'getNewComment',
      'getCreateCommentLoader',
    ]),
  },
  methods: {
    scrollToDiv(element, to, duration) {
      const start = element.scrollTop
      const change = to - start
      let currentTime = 0
      const increment = 20
      Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2
        if (t < 1) return (c / 2) * t * t + b
        t--
        return (-c / 2) * (t * (t - 2) - 1) + b
      }
      const animateScroll = function () {
        currentTime += increment
        const val = Math.easeInOutQuad(currentTime, start, change, duration)
        element.scrollTop = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }
      animateScroll()
    },

    /**
     * this method call on saving new comment
     * check if discovery post is not save as draft
     * than save draft first then save task
     * @param null
     */
    async checkPostExistComment() {
      // disable button to save comment on this condition
      // that will prevent from save in case of enter + cntrl event
      if (!this.getNewComment.comment || this.getCreateCommentLoader)
        return false

      if (!this.getPublishSelection.plan_id) {
        if (
          [
            'Composer Article',
            'Discovery Article',
            'Automation Article',
            'Discovery Video',
            'Automation Video',
          ].indexOf(this.getBlogPostingDetails.type) >= 0
        ) {
          this.processBlogPost(true, false, false, 'comment')
        } else {
          this.processSocialShare(true, false, false, 'comment')
        }
        /* if (this.getBlogSharingSelection.type === 'Composer Article' ||
             this.getBlogSharingSelection.type === 'Discovery Article' ||
             this.getBlogSharingSelection.type === 'Automation Article' ||
             this.getBlogSharingSelection.type === 'Discovery Video' ||
             this.getBlogSharingSelection.type === 'Automation Video'
         ) {
           this.processBlogPost(true, false, false, 'comment')
         } else {
           this.processSocialShare(true, false, false, 'comment')
         } */
      } else {
        const commentStatus = await this.saveComment()
        // if (commentStatus) this.emitBlogActivities('comment')
      }
    },

    async updateComment(commentId) {
      // disable button to save comment on this condition
      // that will prevent from save in case of enter + cntrl event
      if (!this.getNewComment.comment) return false

      const commentStatus = await this.saveComment(commentId)
      // if (commentStatus) this.emitBlogActivities('comment')
    },

    async callRemoveComment(payload) {
      const commentStatus = await this.removeComment(payload)
      // if (commentStatus) this.emitBlogActivities('comment')
    },

    previewCommentImage(link) {
      this.SET_COMMENT_PREVIEW_LINK(link)
      this.$bvModal.show('commentImagePreviewModal')
    },
    ...mapActions(['saveComment']),
    ...mapMutations(['SET_COMMENT_PREVIEW_LINK']),
  },
}
