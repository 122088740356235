import proxy from '@src/modules/common/lib/http-common'
import { cookieDomain, isAuthenticatedURL } from '../config/api-utils'
import { authenticationTypes } from './mutation-types'

const vueCookie = require('vue-cookie')

export default {
  state: {
    jwt_token: vueCookie.get('logged_user_jwt', { domain: cookieDomain }),
    logged_user: vueCookie.get('logged_user', { domain: cookieDomain }),
    is_authenticated: false,
    has_loaded_once: false,
  },
  getters: {
    getJWTToken: (state) => state.jwt_token,
    isUserAuthenticated: (state) => state.is_authenticated,
    getLoadedOnce: (state) => state.has_loaded_once,
  },
  actions: {
    async authenticate({ commit, getters, state, dispatch }, route = {}) {
      console.log('Action::authenticate', route.query)

      const payload = {}
      if (
        route.query &&
        route.query.source === 'frill' &&
        route.query.redirect
      ) {
        if (route.query.source) {
          payload.source = route.query.source
        }
        if (route.query.redirect) {
          payload.redirect = route.query.redirect
        }
      }

      try {
        const resp = await proxy.post(isAuthenticatedURL, {
          ...payload,
        })
        commit(authenticationTypes.SET_IS_AUTHENTICATED, !!(resp.data && resp.data.status))
        commit(authenticationTypes.SET_LOADED_ONCE, true)

        if (resp.data.redirect) window.location.href = resp.data.redirect
      } catch (err) {
        commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
        commit(authenticationTypes.SET_LOADED_ONCE, true)
      }
    },
  },
  mutations: {
    [authenticationTypes.SET_JWT_TOKEN](state, token) {
      state.jwt_token = token
      vueCookie.set('logged_user_jwt', token, {
        expires: '1Y',
        domain: cookieDomain,
      })
      localStorage.setItem('logged_user_jwt', token)
    },

    [authenticationTypes.SET_LOGGED_USER](state, token) {
      state.logged_user = token
      vueCookie.set('logged_user', token, {
        expires: '1Y',
        domain: cookieDomain,
      })
    },

    [authenticationTypes.SET_EXPIRE_TOKEN](state) {
      console.debug('Mutation::setExpireToken')
      vueCookie.delete('logged_user_jwt', { domain: cookieDomain })
      vueCookie.set('logged_user_jwt', null, {
        expires: '1Y',
        domain: cookieDomain,
      })
      localStorage.setItem('logged_user_jwt', null)
      vueCookie.delete('admin_logged_user', { domain: cookieDomain })
      vueCookie.delete('has_social_inbox_beta', { domain: cookieDomain })
      vueCookie.set('has_social_inbox_beta', null, {
        expires: '1Y',
        domain: cookieDomain,
      })
      localStorage.setItem('has_social_inbox_beta', null)

      state.jwt_token = null
      console.debug('Removed JWT token')
    },

    [authenticationTypes.SET_LOGGED_USER_EXPIRE](state) {
      console.debug('Mutation::setLoggedUserExpire')
      vueCookie.delete('logged_user_jwt', { domain: cookieDomain })
      vueCookie.set('logged_user_jwt', null, {
        expires: '1Y',
        domain: cookieDomain,
      })
      vueCookie.delete('logged_user', { domain: cookieDomain })
      vueCookie.set('logged_user', null, {
        expires: '1Y',
        domain: cookieDomain,
      })
      vueCookie.delete('admin_logged_user', { domain: cookieDomain })
      vueCookie.delete('has_social_inbox_beta', { domain: cookieDomain })
      vueCookie.set('has_social_inbox_beta', null, {
        expires: '1Y',
        domain: cookieDomain,
      })
      localStorage.setItem('has_social_inbox_beta', null)

      state.logged_user = null
      state.jwt_token = null
    },

    [authenticationTypes.SET_IS_AUTHENTICATED](state, value) {
      state.is_authenticated = value
    },

    [authenticationTypes.SET_LOADED_ONCE](state, value) {
      state.has_loaded_once = value
    },
  },
}
