<template>
  <div class="cs-hr-scroll w-full px-1 overflow-auto absolute h-full pb-20">
    <div class="flex items-center sticky top-0 bg-white">
                   <span class="text-cs-primary cursor-pointer rounded-md text-lg my-2 px-2.5 py-1 mx-px hover:bg-cs-secondary"
                         @click="returnBack"><i class="fas fa-arrow-left "></i>
                   </span>
      <span class="text-black text-base font-semibold ml-auto mx-2">Image Preview</span>
    </div>
    <hr class="m-0 hr-color">
    <template>
      <div v-if="previewPost && previewPost.url" class="body px-2 py-4">

          <img class="w-100" alt="" :src="previewPost.url"/>

      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ImagePreview",
  props:{
    previewPost: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    returnBack(){
      this.$emit('handle-preview','closeImage',false);
    },
  }
}
</script>

<style scoped>

</style>