<script>
import ImagePreview from './feed-view/ImagePreview'
import { mapGetters } from 'vuex'
import FeedViewCard from './feed-view/FeedViewCard'
import plannerUtilsMixin from '@common/mixins/publish/planner-mixin'
import NoResultsFound from '../../../discovery/components/topics/NoResultsFound'
import { planner } from '../../store/mutation-types'
import InfiniteLoading from 'vue-infinite-loading'
import { pusherSocketPublish } from '@common/lib/pusher'

export default {
  mixins: [plannerUtilsMixin],
  components: {
    FeedViewCard,
    NoResultsFound,
    InfiniteLoading,
    ImagePreview
  },

  data () {
    return {
      channel: ''
    }
  },

  created () {},
  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `comments_${this.getActiveWorkspace._id}`
    )
    this.bindPusherComments()
    // this.plannerListDatePickers()
  },
  beforeDestroy () {
    this.channel.unbind('comment')
    this.channel.unsubscribe()
  },

  computed: {
    ...mapGetters(['getPlannerLoaders', 'getActiveWorkspace'])
  },

  watch: {
    'getPublishSelection.platforms.selection' (value) {
      this.$store.dispatch('refetchPlans', this.$route)
    },
    'getActiveWorkspace._id' (value) {
      console.log(
        'unsubscribe the channel and subscribe for the new workspace value'
      )
      if (this.channel) this.channel.unsubscribe()
      this.channel = pusherSocketPublish.subscribe(`comments_${value}`)
      this.bindPusherComments()
    }
  },
  methods: {
    onInfinitePlannerFeedView ($state) {
      console.log('onInfinitePlannerList', $state)
      if (
        this.isPlatformsSelected &&
        this.getPlannerLoaders.plans_pagination_scroll
      ) {
        this.$store.commit(planner.SET_PAGE, this.getPlans.page + 1)
        const route = this.$route
        const options = { name: route.name, query: route.query }
        console.debug('options', options)
        options.infinite_loading = $state
        this.$store.dispatch('fetchPlans', options)
      }
    }
  }
}
</script>
<template>
  <div class="planner_feed_view">
    <!--<image-preview></image-preview>-->

    <div class="p-3" v-if="isFetchingPlans">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>

    <template v-if="!isFetchingPlans">
      <template v-if="!isPlatformsSelected">
        <content-available-state :type="'no_results'">
          <h2 slot="headline">No results are available</h2>
          <p slot="text"></p>
        </content-available-state>
      </template>

      <template v-if="showNullPlansSection">
        <content-available-state :type="'no_results'">
          <h2 slot="headline">No posts found</h2>
          <p slot="text"
            >No posts found for the selected filters and date-range.</p
          >
        </content-available-state>
      </template>
    </template>

    <template v-if="!isFetchingPlans">
      <template v-for="(item, index) in getPlans.items">
        <feed-view-card :index="index" :item="item"></feed-view-card>
      </template>
    </template>

    <infinite-loading
      v-if="showInfiniteLoadingSectionForFeedView"
      @infinite="onInfinitePlannerFeedView"
      ref="infiniteLoading"
    >
      <span slot="spinner" class="pt-3 d-block">
        <beat-loader :color="'#436aff'"></beat-loader>
      </span>
    </infinite-loading>
  </div>
</template>
