<template>
  <div
    :class="size === 'small' ? 'cs-states-section-small' : 'cs-states-section'"
  >
    <img :src="getStateImage" alt="" />
    <slot name="headline"><h2>No results found</h2></slot>
    <slot name="text"><p>No posts are available .</p></slot>
    <slot name="action_btn"></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {},
    size: {},
  },
  computed: {
    getStateImage() {
      switch (this.type) {
        case 'starred':
          return require('../../assets/img/states/no-starred-posts-icon.svg')
        case 'archived':
          return require('../../assets/img/states/no-archived-posts-icon.svg')
        case 'no_results':
          return require('../../assets/img/states/no-results-available-icon.svg')
        case 'no_twitter_channel':
          return require('../../assets/img/states/no-twitter-icon.svg')
        case 'inbox_search_conversations':
          return require('../../assets/img/states/search-for-conversations.svg')
      }
    },
  },
}
</script>

<style lang="less">
.cs-states-section {
  margin: auto;
  margin-top: 2rem;
  padding: 5rem;
  max-width: 700px;
  background: white;
  border-radius: 0.9375rem;
  img {
    margin: 0 auto;
    height: 230px;
    text-align: center;
    display: block;
  }
  h2 {
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
  }
  p {
    padding: 0.625rem 0;
    color: #989eb5;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1rem;
  }
}

.cs-states-section-small {
  margin: 0 auto;
  width: auto;
  padding-top: 2rem;
  img {
    margin: 0 auto;
    height: 150px;
    text-align: center;
    display: block;
  }
  h2 {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
  }
  p {
    padding: 0.625rem 0;
    color: #989eb5;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1rem;
  }
}

.cs-states-section.with-state-bg {
  background: #fff;
  border-radius: 0.25rem;
}
</style>
