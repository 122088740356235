var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.getCustomSidebar.quick_topics.status ||
    _vm.getSidebarCustomization.customization
  )?_c('ul',{staticClass:"topics-left-pane-items",class:{ disable_click: _vm.getTopicDisabled }},[_c('li',{staticClass:"topics-left-pane-items__heading",class:[_vm.sibarItemStatus('quickTopics') ? '' : 'opacity']},[_c('i',{staticClass:"icon-reports_icon"}),_c('span',{staticClass:"topics-left-pane-items__heading__text"},[_vm._v("Curated Topics")]),(
        !_vm.getSidebarCustomization.customization &&
        _vm.hasPermission('can_save_topic')
      )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Add Curated Topic'),expression:"'Add Curated Topic'",modifiers:{"top-center":true}}],staticClass:"topics-left-pane-items__heading__button",attrs:{"data-cy":"add-topic"},on:{"click":function($event){$event.preventDefault();return _vm.followCuratedTopicsModal()}}},[_c('i',{staticClass:"far fa-plus d-block"})]):_vm._e(),(_vm.getSidebarCustomization.customization)?_c('div',{staticClass:"topics-left-pane-items__heading__customization"},[_c('i',{staticClass:"view_icon",class:[
          _vm.sibarItemStatus('quickTopics') ? 'cs-eye ' : 'cs-eye-close ',
        ],on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('quickTopics')}}})]):_vm._e()]),(_vm.getMyCuratedTopicsList.length > 0)?[_c('transition-group',{attrs:{"name":"topics-list","tag":"div"}},_vm._l((_vm.getMyCuratedTopicsList),function(topic,index){return (
          _vm.getSidebarCustomization.customization ||
          !_vm.getCustomSidebar.quick_topics.options.includes(topic.name)
        )?_c('li',{key:topic._id,staticClass:"topics-left-pane-items__list",class:[
          _vm.sibarItemStatus('quickTopics', topic.name) ? '' : 'opacity',
          _vm.getSidebarCustomization.customization
            ? 'topics-left-pane-items__list__customization'
            : '',
          _vm.$route.params.topic_id === topic._id ? 'active' : '',
        ]},[_c('router-link',{class:{ active: _vm.$route.params.topic_id === topic._id },attrs:{"to":{
            name: 'discover_topics_curated_web_content',
            params: { topic_id: topic._id },
          }}},[_vm._v(_vm._s(topic.name))]),_c('div',{staticClass:"topics-left-pane-items__list__action_buttons"},[(_vm.getSidebarCustomization.customization)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Show / Hide'),expression:"'Show / Hide'",modifiers:{"top-center":true}}],staticClass:"view_icon",class:[
              _vm.sibarItemStatus('quickTopics', topic.name)
                ? 'cs-eye '
                : 'cs-eye-close  ',
            ],staticStyle:{"right":"0"},on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('quickTopics', topic.name)}}}):(_vm.hasPermission('can_save_topic'))?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"topics-left-pane-items__list__action_buttons__remove icon-delete-cs",attrs:{"id":topic._id,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}):_vm._e(),_c('div',{staticClass:"dropdown-menu dropdown-confirmation__menu",attrs:{"aria-labelledby":topic._id}},[_c('div',{staticClass:"dropdown-confirmation__menu__header"},[_vm._v(" Confirmation ")]),_c('div',{staticClass:"dropdown-confirmation__menu__actions"},[_vm._v(" Are you sure? "),_c('span',{staticClass:"dropdown-confirmation__menu__actions__delete",on:{"click":function($event){$event.preventDefault();return _vm.unfollowCuratedTopic({ topic: topic, index: index })}}},[_vm._v("Yes")]),_c('span',{staticClass:"dropdown-confirmation__menu__actions__cancel"},[_vm._v("No")])])])])],1):_vm._e()}),0)]:[_c('p',{staticClass:"topics-left-pane-items__no_results"},[_vm._v("You are not following any curated topic.")])]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }