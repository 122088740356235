<template>
  <div>
    <div
      v-tooltip.top-center="'License'"
      class="dropdown option_dropdown default_style_dropdown"
      v-if="getAssistantContentType == 'flickr'"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first cs-flag"></i>
        <span class="text">{{ getFlickrLicense.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-left" style="width: 200px">
        <div class="inner">
          <li
            class="list_item"
            @click.prevent="changeFlickrLicense(0, 'Any License')"
          >
            <a href="javascript:void(0)">Any License</a>
          </li>
          <li
            class="list_item"
            @click.prevent="changeFlickrLicense(4, 'Creative Commons')"
          >
            <a href="javascript:void(0)">Creative Commons</a>
          </li>
        </div>
      </div>
    </div>

    <template v-if="getAssistantContentType == 'imgur'">
      <div
        v-tooltip.top-center="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="flag_icon icon_first cs-flag"></i>
          <span class="text">{{ getImgurSort }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <div class="inner">
            <li class="list_item" @click.prevent="changeImgurSort('viral')">
              <a href="javascript:void(0)">Viral</a>
            </li>
            <li class="list_item" @click.prevent="changeImgurSort('top')">
              <a href="javascript:void(0)">Top</a>
            </li>
            <li class="list_item" @click.prevent="changeImgurSort('time')">
              <a href="javascript:void(0)">Time</a>
            </li>
            <li class="list_item" @click.prevent="changeImgurSort('rising')">
              <a href="javascript:void(0)">Rising</a>
            </li>
          </div>
        </div>
      </div>
      <div
        v-tooltip.top-center="'Section'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="flag_icon icon_first cs-flag"></i>
          <span class="text">{{ getImgurSection }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <div class="inner">
            <li class="list_item" @click.prevent="changeImgurSection('hot')">
              <a href="javascript:void(0)">Hot</a>
            </li>
            <li class="list_item" @click.prevent="changeImgurSection('top')">
              <a href="javascript:void(0)">Top</a>
            </li>
            <li class="list_item" @click.prevent="changeImgurSection('user')">
              <a href="javascript:void(0)">User</a>
            </li>
          </div>
        </div>
      </div>
    </template>

    <template v-if="getAssistantContentType == 'pixabay'">
      <div
        v-tooltip.top-center="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="flag_icon icon_first cs-flag"></i>
          <span class="text">{{ getPixabaySort }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <div class="inner">
            <li class="list_item" @click.prevent="changePixabaySort('popular')">
              <a href="javascript:void(0)">Popular</a>
            </li>
            <li class="list_item" @click.prevent="changePixabaySort('latest')">
              <a href="javascript:void(0)">Latest</a>
            </li>
          </div>
        </div>
      </div>
      <div
        v-tooltip.top-center="'Type'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="flag_icon icon_first cs-flag"></i>
          <span class="text">{{ getPixabayType }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <div class="inner">
            <li class="list_item" @click.prevent="changePixabayType('all')">
              <a href="javascript:void(0)">All</a>
            </li>
            <li class="list_item" @click.prevent="changePixabayType('photo')">
              <a href="javascript:void(0)">Photo</a>
            </li>
            <li
              class="list_item"
              @click.prevent="changePixabayType('illustration')"
            >
              <a href="javascript:void(0)">Illustration</a>
            </li>
            <li class="list_item" @click.prevent="changePixabayType('vector')">
              <a href="javascript:void(0)">Vector</a>
            </li>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getAssistantContentType',
      'getImgurSort',
      'getImgurSection',
      'getPixabaySort',
      'getPixabayType',
      'getTopicDisabled',
      'getFlickrLicense'
    ])
  }
}
</script>
