import { mapGetters } from 'vuex'

export const contentCategoryMixin = {
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getPublishTimeOptions',
      'getContentCategoryList',
    ]),
    isGlobalContentCategory() {
      const contentCategoryId = this.getPublishSelection.content_category_id
      if (
        contentCategoryId &&
        this.getPublishTimeOptions.time_type === 'content_category'
      ) {
        const category = this.getContentCategoryList.find(
          (item) => item._id === contentCategoryId
        )
        return category && category.category_state === 'global'
      }
      return false
    },
    isGlobalContentCategorySelected() {
      const contentCategoryId = this.getPublishSelection.content_category_id
      if (contentCategoryId) {
        const category = this.getContentCategoryList.find(
          (item) => item._id === contentCategoryId
        )
        return category && category.category_state === 'global'
      }
      return false
    },
    isContentCategorySelected() {
      return !!(
        this.getPublishSelection.content_category_id &&
        this.getPublishSelection.content_category_id.length > 3
      )
    },
  },
  methods: {
    getSelectedGlobalContentCategoryId() {
      const contentCategoryId = this.getPublishSelection.content_category_id
      if (contentCategoryId) {
        const category = this.getContentCategoryList.find(
          (item) => item._id === contentCategoryId
        )
        if (category && category.category_state === 'global')
          return category.global_content_category_id
      }
      return false
    },
  },
}
