<template>
  <ul v-if="approval.approvers">
    <li
      v-for="(approver, k) in approval.approvers"
      :key="k"
      class="d-flex align-items-start"
    >
      <template
        v-if="getMember(approver.user_id) && getMember(approver.user_id).user"
      >
        <span class="left">
          <i :class="getApproverClass(approver)"></i>
          <span
            v-if="
              getMember(approver.user_id).user.firstname &&
              getMember(approver.user_id).user.lastname
            "
            >{{ getMember(approver.user_id).user.firstname }}
            {{ getMember(approver.user_id).user.lastname }}</span
          >
          <span v-else>{{ getMember(approver.user_id).user.email }}</span>
        </span>
        <span
          v-if="approver.status === APPROVER_STATUS.APPROVE"
          class="right ml-auto"
        >
          Approved on
          <template v-if="approver.last_action_time.date">
            {{
              getWorkspaceTimeZoneTime(
                approver.last_action_time.date,
                'MMM DD, hh:mm a'
              )
            }}
          </template>
        </span>
        <span
          v-else-if="approver.status === APPROVER_STATUS.REJECT"
          class="right ml-auto"
        >
          Rejected on
          <template v-if="approver.last_action_time.date">
            {{
              getWorkspaceTimeZoneTime(
                approver.last_action_time.date,
                'MMM DD, hh:mm a'
              )
            }}
          </template>
        </span>
        <span
          v-else-if="approver.status === APPROVER_STATUS.PENDING"
          class="right ml-auto"
        >
          <template v-if="approval.status === 'pending_approval'">
            Waiting for approval
            <!--(<span class="link">Send Reminder</span>) (<span class="link">Force Approve</span>)-->
          </template>
          <template v-else> Skipped </template>
        </span>
      </template>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { APPROVER_STATUS } from '@common/constants/composer'

export default {
  props: {
    approval: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      APPROVER_STATUS,
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  mounted() {
    console.debug('approval status mounted')
  },
  methods: {
    getApproverClass(approver) {
      console.log('METHOD::getApproverClass ~ approver -> ', approver)
      console.debug('approver.status', approver.status)
      switch (approver.status) {
        case APPROVER_STATUS.APPROVE:
          return 'approved far fa-check mr-2'
        case APPROVER_STATUS.PENDING:
          return 'pending far fa-minus mr-2'
        case APPROVER_STATUS.REJECT:
          return 'rejected far fa-ban mr-2'
      }
      return ''
    },
  },
}
</script>
