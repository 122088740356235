<template>
  <div class="workspace_component">
    <add-member-to-organization></add-member-to-organization>
    <div class="component_inner">
      <!--<router-link class="add_icon" v-if="getWorkspaces.activeWorkspace._id" :to="{'name': 'discovery', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <button
        type="button"
        class="close_icon float-right"
        @click="$router.push({ name: 'workspaces' })"
        >&times;</button
      >
      <!--</router-link>-->
      <div class="workspace_list_wrapper">
        <div class="w_top_heading">
          <h2>Manage Team &amp; Client Roles</h2>
          <p
            >Here you can manage all of the team members and client roles for
            all of your workspaces in a single place.</p
          >
        </div>
        <template v-if="load_team_members">
          <div class="mt-5">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </template>
        <template v-else>
          <template v-if="getFilteredOrganizationWorkspaces.length === 0">
            <div
              class="d-flex align-items-center m-auto"
              style="padding-top: 20px"
            >
              <div class="warning_box m-auto">
                You are not owner of any workspace. Please create a workspace
                first before you can manage the team members.</div
              >
              <div class="clearfix"></div>
            </div>
            <div class="m-auto" style="text-align: center">
              <button
                class="btn blue_gradient large_btn mt-3"
                @click="$router.push({ name: 'workspaces' })"
              >
                <span>Go to Workspaces</span>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="workspace-top d-flex align-items-center pl-0 pr-0">
              <div class="top-left">
                <div class="d-inline-block workspace-search align-middle">
                  <div class="search_input">
                    <div class="search_inner">
                      <input
                        type="text"
                        v-model="search_workspace"
                        placeholder="Search workspace…"
                      />
                    </div>
                  </div>
                </div>

                <!--                        <div class="dropdown align-middle option_dropdown default_style_dropdown d-inline-block ml-2  sort_by_role">

                                                            <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                                                                <span class="text">Sort by role</span>
                                                                <span class=" ml-auto">
                                                           <i class="dropdown_arrow icon_last cs-angle-down"></i>
                                                          </span>
                                                            </div>
                                                            <div class="dropdown-menu dropdown-menu-right" style="min-width: 170px;">
                                                                <ul class="inner">

                                                                    <li class="list_item_li">
                                                                        <p>
                                                                            All Roles
                                                                        </p>
                                                                    </li>
                                                                    <li class="list_item_li" @click="filterByRole('super_admin')">
                                                                        <i class="far fa-check "
                                                                           v-if="roles_selected.indexOf('super_admin') !== -1 "></i>
                                                                        <p>
                                                                            Super Admin
                                                                        </p>
                                                                        <p class="role_description">Super Admin have all the privileges
                                                                            except they can view the billing and plan details
                                                                        </p>
                                                                    </li>
                                                                    <li class="list_item_li" @click="filterByRole('admin')">
                                                                        <i class="far fa-check "
                                                                           v-if="roles_selected.indexOf('admin')  !== -1"></i>
                                                                        <p>
                                                                            Administrator
                                                                        </p>
                                                                        <p class="role_description">
                                                                            Admin have all the privileges except they cannot view the billing and plan
                                                                            details
                                                                        </p>
                                                                    </li>
                                                                    <li class="list_item_li" @click="filterByRole('collaborator')">
                                                                        <i class="far fa-check "
                                                                           v-if="roles_selected.indexOf('collaborator')  !== -1"></i>
                                                                        <p>
                                                                            Collaborator
                                                                        </p>
                                                                        <p class="role_description">
                                                                            Collaborator can view and manage their data in this workspace.
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>-->
              </div>
              <div class="top-right ml-auto">
                <button
                  @click="addOrganizationMemberModal()"
                  class="btn btn-studio-theme-space"
                >
                  <span>Add Member</span>
                </button>
              </div>
            </div>

            <div class="workspace-permission-manage">
              <div class="box-inner d-flex align-items-start">
                <div class="table-left">
                  <div class="workspace-name">
                    <div class="workspace_col d-flex align-items-center">
                      <p
                        ><span>{{ getOrganizationMembers.members_count }}</span>
                        member(s) in
                        <span>{{
                          getFilteredOrganizationWorkspaces.length
                        }}</span>
                        Workspaces</p
                      >
                    </div>

                    <div
                      class="workspace_col"
                      v-for="(
                        workspace, index
                      ) in getFilteredOrganizationWorkspaces"
                    >
                      <div class="profile_picture d-flex align-items-center">
                        <div class="picture_block picture_block_background">
                          <div
                            class="img rounded_image"
                            v-if="workspace.workspace.logo"
                            :style="{
                              'background-image':
                                'url(' + workspace.workspace.logo + ')',
                            }"
                          ></div>
                          <div
                            v-else
                            class="img"
                            style="
                              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                            "
                          ></div>
                        </div>
                        <div class="text_block">
                          <p class=" "
                            >{{ workspace.workspace.name }}
                            <!-- <span class="far fa-trash"
                                                         v-tooltip="'Remove Workspace'"></span>-->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-right">
                  <div class="d-flex scroll-area">
                    <div
                      class="workspace-username"
                      v-for="(member, key) in getOrganizationMembers.members"
                    >
                      <template v-if="member">
                        <div class="workspace_col d-flex align-items-start">
                          <div
                            class="profile_picture_block d-flex align-items-start"
                          >
                            <div class="picture_block picture_block_background">
                              <div
                                class="img rounded_image"
                                v-if="
                                  member[0].user && member[0].user.full_name
                                "
                              >
                                <img
                                  class="member_profile_image"
                                  v-if="member[0].user.image"
                                  :src="member[0].user.image"
                                  alt=""
                                />
                                <img
                                  class="member_profile_image"
                                  v-else
                                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div class="text_block">
                              <p
                                class="truncate_details"
                                v-if="member[0] && member[0].user"
                                ><span class="capitalize_team_member">{{
                                  member[0].user.full_name
                                }}</span
                                ><br /><span
                                  class="lowercase_team_member_email"
                                  >{{ member[0].user.email }}</span
                                >
                              </p>
                            </div>
                          </div>

                          <span
                            v-if="member[0].user._id !== getProfile._id"
                            @click="confirmAndRemoveOrganizationMember(key)"
                            class="remove_icon far fa-times"
                            v-tooltip="'Remove team member from all workspaces'"
                          ></span>
                        </div>
                        <div
                          class="workspace_col"
                          v-for="(
                            workspace, index
                          ) in getFilteredOrganizationWorkspaces"
                        >
                          <div
                            :id="workspace.workspace_id"
                            class="team-role-dropdown dropdown option_dropdown default_style_dropdown"
                            :class="{
                              disable_click_white:
                                checkRoleOwnershipOfWorkspace(
                                  workspace,
                                  member
                                ) === 'super_admin',
                            }"
                          >
                            <div
                              data-boundary="window"
                              class="dropdown_header d-flex align-items-center"
                              data-toggle="dropdown"
                            >
                              <span
                                class="text"
                                :class="{
                                  no_access:
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'no_access',
                                }"
                              >
                                <template
                                  v-if="
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'super_admin'
                                  "
                                >
                                  Super Admin
                                </template>
                                <template
                                  v-else-if="
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'admin'
                                  "
                                >
                                  Admin
                                </template>
                                <template
                                  v-else-if="
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'collaborator'
                                  "
                                >
                                  Collaborator
                                </template>
                                <template
                                  v-else-if="
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'approver'
                                  "
                                >
                                  Approver
                                </template>
                                <template
                                  v-else-if="
                                    checkRoleOwnershipOfWorkspace(
                                      workspace,
                                      member
                                    ) === 'no_access'
                                  "
                                >
                                  No access
                                </template>
                              </span>
                              <span class="ml-auto">
                                <i
                                  class="dropdown_arrow icon_last cs-angle-down"
                                ></i>
                              </span>
                            </div>

                            <div
                              class="dropdown-menu dropdown-menu-right extended-team-dropdown-menu workspace-auto-hight"
                            >
                              <ul class="inner">
                                <li
                                  class="list_item_li"
                                  :class="{
                                    is_active_role:
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'admin',
                                  }"
                                  @click="
                                    changeRoleOwnershipInWorkspace(
                                      workspace,
                                      member[0],
                                      'admin'
                                    )
                                  "
                                >
                                  <i
                                    class="far fa-check"
                                    v-if="
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'admin'
                                    "
                                  ></i>
                                  <p>Admin</p>
                                  <p class="role_description"
                                    >Has all the privileges except they cannot
                                    view/manage billing and plan details.</p
                                  >
                                </li>
                                <li
                                  class="list_item_li"
                                  :class="{
                                    is_active_role:
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'collaborator',
                                  }"
                                  @click="
                                    changeRoleOwnershipInWorkspace(
                                      workspace,
                                      member[0],
                                      'collaborator'
                                    )
                                  "
                                >
                                  <i
                                    class="far fa-check"
                                    v-if="
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'collaborator'
                                    "
                                  ></i>
                                  <p>Collaborator</p>
                                  <p class="role_description">
                                    Can create and edit posts, can comment on
                                    posts, other permissions can be set
                                    according to the needs.
                                  </p>
                                </li>
                                <li
                                  class="list_item_li"
                                  :class="{
                                    is_active_role:
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'approver',
                                  }"
                                  @click="
                                    changeRoleOwnershipInWorkspace(
                                      workspace,
                                      member[0],
                                      'approver'
                                    )
                                  "
                                >
                                  <i
                                    class="far fa-check"
                                    v-if="
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'approver'
                                    "
                                  ></i>
                                  <p>Approver</p>
                                  <p class="role_description">
                                    Can review posts and comment on them, has
                                    access to the planner/calendar only.
                                  </p>
                                </li>
                                <li
                                  class="list_item_li"
                                  :class="{
                                    is_active_role:
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'no_access',
                                  }"
                                  @click="
                                    removeRoleOwnershipAccess(
                                      workspace,
                                      member[0],
                                      'no_access'
                                    )
                                  "
                                >
                                  <i
                                    class="far fa-check"
                                    v-if="
                                      checkRoleOwnershipOfWorkspace(
                                        workspace,
                                        member
                                      ) === 'no_access'
                                    "
                                  ></i>
                                  <p>No Access</p>
                                  <p class="role_description"
                                    >Has no access to this workspace .</p
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <span
                            class="tag"
                            v-if="
                              checkMemberStatusInWorkspace(
                                workspace,
                                member
                              ) === 'invited'
                            "
                            >Invited</span
                          >
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import removeConfirmation from '../workspace/RemoveConfirmation'
import sortBy from 'lodash.sortby'
import AddMemberToOrganization from './dialogs/AddMemberToOrganization'
import { organizationTypes } from '../../store/states/mutation-types'
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  components: {
    removeConfirmation,
    AddMemberToOrganization
  },

  data () {
    return {
      roles_selection: 'all',
      roles_selected: [],
      search_workspace: null,
      load_team_members: true
    }
  },
  computed: {
    ...mapGetters([
      'getOrganizationLoaders',
      'getOrganizationMembers',
      'getOrganizationMemberAdd',
      'getWorkspaces',
      'getCoveredStories',
      'getCuratedQuotes',
      'getProfile'
    ]),
    getFilteredOrganizationWorkspaces () {
      let workspaces = []
      const searchedValue = this.search_workspace
      if (searchedValue) {
        const searchedLowerCase = searchedValue.toLowerCase()
        workspaces = this.getOrganizationMembers.workspaces.filter(
          (item) =>
            item.workspace.name.toLowerCase().indexOf(searchedLowerCase) >= 0 ||
            item.workspace.slug.toLowerCase().indexOf(searchedLowerCase) >= 0
        )
      } else {
        workspaces = this.getOrganizationMembers.workspaces
      }
      console.log(workspaces)
      return sortBy(workspaces, function (item) {
        return [item.workspace.name.toLowerCase()]
      })
    }
  },
  async created () {
    const res = await this.$store.dispatch('fetchAllMembersOfOrganization')
    this.load_team_members = false
  },
  mounted () {},

  methods: {
    ...mapActions(['setIsAdminLogin']),

    checkRoleOwnershipOfWorkspace (workspace, partOfWorkspaces) {
      console.log(workspace.workspace_id, partOfWorkspaces)
      const isUser = partOfWorkspaces.find(
        (item) => item.workspace_id === workspace.workspace_id
      )
      if (isUser) {
        return isUser.role
      } else {
        return 'no_access'
      }
    },

    checkMemberStatusInWorkspace (workspace, partOfWorkspaces) {
      console.log(workspace.workspace_id, partOfWorkspaces)
      const isUser = partOfWorkspaces.find(
        (item) => item.workspace_id === workspace.workspace_id
      )
      if (isUser) {
        return isUser.status
      } else {
        return ''
      }
    },

    changeRoleOwnershipInWorkspace (workspace, member, role) {
      console.log(workspace.workspace_id, member)
      this.$store.dispatch('changeRoleOfMemberInWorkspace', {
        workspace_id: workspace.workspace_id,
        user_id: member.user._id,
        role: role
      })
    },
    removeRoleOwnershipAccess (workspace, member, role) {
      console.log(workspace.workspace_id, member)
      if (member && member.user) {
        this.$store.dispatch('removeRoleOfMemberInWorkspace', {
          workspace_id: workspace.workspace_id,
          user_id: member.user._id,
          role: role
        })
      }
    },
    confirmAndRemoveOrganizationMember (userId) {
      this.$bvModal
        .msgBoxConfirm(
          'By performing this action, your team member will be removed from all of the workspaces.',
          {
            title: 'Remove Team Member',
            ...swalAttributes()
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch('removeFromAllWorkspaces', userId)
          }
        })
        .catch((err) => {
          // An error occurred
        })
    },
    async addOrganizationMemberModal () {
      await this.$store.commit(organizationTypes.RESET_ORGANIZATION_MEMBER_ADD)
      const listOfWorkspaces = this.getOrganizationMembers.workspaces.map(
        (item) => item.workspace._id
      )
      this.$set(this.getOrganizationMemberAdd, 'workspaces', listOfWorkspaces)
      this.$bvModal.show('add_organization_member')
      // this.$store.commit(organizationTypes.SET_ORGANIZATION_MEMBER_WORKSPACES, listOfWorkspaces)
    },
    redirectForDiscovery () {
      // this.getContentByTopic()
      if (this.getCoveredStories) {
        this.$router.push({
          name: 'cover_stories',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug }
        })
        return
      }
      if (this.getCuratedQuotes) {
        this.$router.push({
          name: 'topics_quotes',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug }
        })
        return
      }
      this.$router.push({
        name: 'discover_search',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    },
    filterByRole (role) {
      if (this.roles_selected.indexOf(role) >= 0) {
        this.roles_selected.splice(this.roles_selected.indexOf(role), 1)
      } else {
        this.roles_selected.push(role)
      }
    }
  }
}
</script>
<style lang="less">
.member_profile_image {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 5px;
}

.lowercase_team_member_email {
  text-transform: lowercase !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.capitalize_team_member {
  text-transform: capitalize !important;
}

.role_description {
  font-size: 12px;
  color: #989eb5; //a1a1b5
}

.is_active_role {
  /*opacity:0.4;*/
  cursor: not-allowed !important;
  position: relative;
}

.sort_by_role {
  .dropdown-menu {
    width: 300px;
    max-width: 320px;
  }
}

.role_check {
  position: absolute;
  right: 12px;
  color: #1ecb7b;
  top: 50%;
  transform: translateY(-50%);
}
</style>
