import { composer } from './composer-mutation-type'
import proxy from '@common/lib/http-common'
import { getPlanVersionHistoryURL } from '../../planner/config/api-utils'
import Router from '@src/router'
import { blogPosting } from '../../publish/store/states/mutation-types'

const state = {
  assistantContentType: 'article',
  favoriteName: 'Select Folder',
  type: '',
  blogAutoSaveInterval: null,

  versionsHistory: [],
  versionsHistoryLoader: false,
  versionHistoryActiveIndex: 0,

  editorTypingInterval: null,
  showTypingMessage: false,
  typingTextInterval: null,

  activePlanMembers: {},
  socketId: '',
  planControl: true,
  planControlRequestData: null,
  planKey: ''
}

const getters = {
  getAssistantContentType: (state) => state.assistantContentType,

  getCurationFolderText: (state) => state.favoriteName,

  getComposerPostType: (state) => state.type,

  getBlogAutoSaveInterval: (state) => state.blogAutoSaveInterval,

  getPlanVersionHistory: (state) => state.versionsHistory,

  getVersionsHistoryLoader: (state) => state.versionsHistoryLoader,

  getVersionHistoryActiveIndex: (state) => state.versionHistoryActiveIndex,

  getEditorTypingInterval: (state) => state.editorTypingInterval,

  getEditorTypingStatus: (state) => state.showTypingMessage,

  getTypingTextInterval: (state) => state.typingTextInterval,

  getComposerActivePlanMember: (state) => state.activePlanMembers,

  getSocketId: (state) => state.socketId,

  getPlanControl: (state) => state.planControl,

  getPlanControlRequestData: (state) => state.planControlRequestData,

  getPlanKey: (state) => state.planKey
}

const actions = {
  /**
   * Reset Composer states
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  resetComposerCommonStates ({ commit, getters, state, dispatch }) {
    commit(blogPosting.RESET_BLOG_POSTING)
    commit(composer.RESET_COMPOSER_BLOG)
    commit(blogPosting.RESET_BLOG_SELECTION)
    commit(composer.SET_COMPOSER_POST_TYPE, '')
    commit(composer.SET_PUBLICATION_LAST_UPDATED, '')

    commit(blogPosting.SET_BLOG_POSTING_TYPE, '')
    commit('setPublishPlanId', '')
    commit('setPublicationStatus', '')
    commit('setPublishPlanSecondaryIds', [])
    commit('setPublicationFolderId', null)
    commit('setPublishPlanExecutionTime', null)
    commit('setSocialPlanDetail', [])
    commit('setEditQueueStatus', false)
    commit('setEditRepeatStatus', false)
  },

  /**
   * Reopen Blog Post
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  reopenBlog ({ commit, getters, state, dispatch }) {
    commit('setPublicationStatus', '')
    commit('setPostReopenStatus', true)
    commit(blogPosting.SET_PRIMARY_BLOG_TIME, null)

    const secondaryBlog = []
    if (getters.getSecondaryBlogSelection) {
      getters.getSecondaryBlogSelection.forEach(function (element, index) {
        element.status = null
        element.time = {
          time_type: 'now',
          postSelectedDate: ''
        }
        secondaryBlog.push(element)
      })
    }
    commit(blogPosting.SET_SECONDARY_BLOG_SELECTION, secondaryBlog)

    const socialPlan = getters.getPublishSelection.socialPlansDetail
    if (socialPlan && socialPlan.length > 0 && socialPlan[0].status) {
      socialPlan[0].status = null
    }
    commit('setSocialPlanDetail', socialPlan)
  },

  /**
   * Reopen Social Post
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   */
  reopenSocialPost ({ commit, getters, state, dispatch }) {
    commit('setPublishTimeSelection', null)
    getters.getPublishSelection.content_category_id &&
    getters.getPublishSelection.content_category_id.length > 3
      ? commit('setPublishingTimeType', 'content_category')
      : commit('setPublishingTimeType', 'queued')
    if (
      getters.getPublishSelection.status === 'review' ||
      getters.getPublishSelection.status === 'rejected'
    ) {
      commit('setPublishingTimePlanStatus', 'Under Review')
    }
    commit('setPublicationStatus', '')
    commit('setPostReopenStatus', true)
    commit('setEditQueueStatus', false)
  },

  planVersionHistory ({ commit, getters, state, dispatch }, planId) {
    commit(composer.SET_VERSION_HISTORY_LOADER, true) // showing loader
    proxy
      .post(getPlanVersionHistoryURL, {
        planId: planId,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.status === true) {
          console.debug(resp.data.versionHistory)
          commit(
            composer.SET_COMPOSER_VERSION_HISTORY,
            resp.data.versionHistory
          )
          commit(composer.SET_VERSION_HISTORY_ACTIVE_INDEX, 0)
        } else {
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'error'
          })
        }
        commit(composer.SET_VERSION_HISTORY_LOADER, false) // hiding loader
      })
      .catch((response) => {
        commit(composer.SET_VERSION_HISTORY_LOADER, false)
      })
  },

  async composerEditingCallBack ({ commit, getters, state, dispatch }, data) {
    console.debug('Method:composerEditingCallBack', data)
    if (
      getters.getPlanKey !== data.key &&
      getters.getSocketId &&
      data.socket_id !== getters.getSocketId &&
      Router.history.current.name === 'composerBlog' &&
      data.plan_id === getters.getPublishSelection.plan_id
    ) {
      window.clearTimeout(getters.getTypingTextInterval)
      commit(composer.SET_TYPING_TEXT_INTERVAL, null)

      let user = null
      getters.getWorkspaces.activeWorkspace.members.filter((member) => {
        if (member.user._id === data.user_id) {
          user = member.user
        }
      })
      if (user) {
        commit(composer.SET_EDITOR_TYPING_STATUS, user)
        const typingTextInterval = setTimeout(function () {
          commit(composer.SET_EDITOR_TYPING_STATUS, false)
        }, 3000)
        commit(composer.SET_TYPING_TEXT_INTERVAL, typingTextInterval)
      }
      return true
    }
    return false
  },

  composerPlanView ({ commit, getters, state, dispatch }, data) {
    console.debug('Method:composerPlanViewCallBack', data)
    commit(
      composer.SET_COMPOSER_ACTIVE_PLAN_MEMBERS,
      JSON.parse(JSON.stringify(data))
    )
  },

  planControlRequest ({ commit, getters, state, dispatch }, data) {
    console.debug('Method:planControlRequest', data)
    if (data) data = JSON.parse(JSON.stringify(data))
    else data = null
    commit(composer.SET_PLAN_CONTROL_REQUEST_DATA, data)
  },

  composerPlanClose ({ commit, getters, state, dispatch }, data) {
    console.debug('Method:composerPlanCloseCallBack', data)
    commit(
      composer.SET_COMPOSER_ACTIVE_PLAN_MEMBERS,
      JSON.parse(JSON.stringify(data))
    )
  }
}

const mutations = {
  [composer.SET_ASSISTANT_CONTENT_TYPE] (state, status) {
    state.assistantContentType = status
  },

  [composer.SET_CURATION_FAVORITE_NAME] (state, name) {
    state.favoriteName = name
  },

  [composer.SET_COMPOSER_POST_TYPE] (state, type) {
    state.type = type
  },

  [composer.SET_BLOG_AUTO_SAVE_INTERVAL] (state, interval) {
    state.blogAutoSaveInterval = interval
  },

  [composer.SET_COMPOSER_VERSION_HISTORY] (state, version) {
    state.versionsHistory = version
  },

  [composer.SET_VERSION_HISTORY_LOADER] (state, loader) {
    state.versionsHistoryLoader = loader
  },

  [composer.SET_VERSION_HISTORY_ACTIVE_INDEX] (state, index) {
    state.versionHistoryActiveIndex = index
  },

  [composer.SET_EDITOR_TYPING_INTERVAL] (state, interval) {
    state.editorTypingInterval = interval
  },

  [composer.SET_EDITOR_TYPING_STATUS] (state, status) {
    state.showTypingMessage = status
  },

  [composer.SET_TYPING_TEXT_INTERVAL] (state, interval) {
    state.typingTextInterval = interval
  },

  [composer.SET_COMPOSER_ACTIVE_PLAN_MEMBERS] (state, data) {
    state.activePlanMembers = data
  },

  [composer.SET_SOCKET_ID] (state, id) {
    state.socketId = id
  },

  [composer.SET_PLAN_CONTROL] (state, control) {
    state.planControl = control
  },

  [composer.SET_PLAN_CONTROL_REQUEST_DATA] (state, data) {
    state.planControlRequestData = data
  },

  [composer.SET_PLAN_KEY] (state, key) {
    state.planKey = key
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
