/**
 * Vue composable for the competitors.
 * Functionality:
 * - Fetches the list of competitors from the API
 * - Provides the competitors to the template
 * - Provides the loading state to the template
 * - Provides the error state to the template
 */

import { reactive } from 'vue'
import { fetchAnalyticsData } from '@src/modules/analytics_v3/helper/services'

/**
 * @param endPoint
 * @param payload
 * @param dispatch
 * @param errorText
 * @returns {{competitor: ComputedRef<{} extends Ref<infer V> ? UnwrapRefSimple<V> : UnwrapRefSimple<{}>>, loading: ComputedRef<boolean>, error: ComputedRef<null extends Ref<infer V> ? UnwrapRefSimple<V> : UnwrapRefSimple<null>>}}
 */
export default function useCompetitorsFactory(
  endPoint = '',
  payload = {},
  dispatch = () => {},
  errorText = ''
) {
  const state = reactive({
    competitor: [],
    loading: false,
    error: null,
  })

  async function fetch(apiEndPoint, apiPayload) {
    state.loading = true
    try {
      const response = await fetchAnalyticsData(apiEndPoint, apiPayload)
      if (apiEndPoint === 'dataTableMetrics') {
        state.competitor =
          apiEndPoint === 'dataTableMetrics'
            ? response.data_table_metrics || response
            : response.data || response.res
      } else {
        state.competitor = response.data ? response.data : response.res
      }
    } catch (err) {
      state.error = err
      if (dispatch) {
        dispatch('toastNotification', {
          message: errorText,
          type: 'error',
        })
      }
    } finally {
      state.loading = false
    }
  }

  return new Promise((resolve) => {
    fetch(endPoint, payload).then(() => {
      if (state.competitor || state.error) {
        resolve({
          competitor: state.competitor,
          error: state.error,
        })
      }
    })
  })
}
