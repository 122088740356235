<template>
  <confirm-box
    id="removePublicationFolder"
    :callBackAction="removePublicationFolder"
    confirmText="Yes"
    cancelText="No"
  >
    <template slot="header">
      <h2>Remove Campaign Folder</h2>
    </template>

    <template slot="footer">
      <p class="text-center"
        >This action will only remove the Campaign Folder, your posts will not be
        removed and will be moved to your default Campaign Folder. <br />Are you sure
        you want to continue?</p
      >
    </template>
  </confirm-box>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
