var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white_box",on:{"click":function($event){$event.preventDefault();return _vm.EventBus.$emit('facebook-analytics-preview', _vm.post)}}},[[_c('div',{staticClass:"flex-row d-flex table_body align-items-center"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"user_name"},[_vm._m(0),(_vm.post.page_id)?_c('img',{attrs:{"src":'https://graph.facebook.com/' +
              _vm.post.page_id +
              '/picture?type=square',"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}):_c('img',{attrs:{"src":require("@assets/img/profile_default.svg"),"alt":""}}),_c('div',{staticClass:"right right-text col"},[(_vm.post.page_name)?_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.post.page_name))]):_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.post.name))]),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("publishedDate")(_vm.post.created_time)))])])])]),_c('div',{staticClass:"col-2 py-1"},[(_vm.post.full_picture)?_c('div',{staticClass:"post_image dashboard-post-image object-cover",style:({
            backgroundImage:
              'url(' + _vm.getResizedImageURL(_vm.post.full_picture, 150, 0) + ')',
          })}):_c('div',{staticClass:"post_image dashboard-post-image",style:({
            backgroundImage:
              'url(' +
              require('../../assets/img/no_data_images/not-found.png') +
              ')',
          })})]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(
            _vm.limitTextLength(
              _vm.isHashtag(_vm.isLink(_vm.nextLineReplacement(_vm.post.message))),
              400,
            )
          )}})]),_c('div',{staticClass:"col-2 pl-5 text-center font-weight-500"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(_vm.post.total_engagement)))]),_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(_vm.post.comments)))]),_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(_vm.post.shares)))])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon social_image_icon social_image_icon_facebook"},[_c('i',{staticClass:"cs-facebook"})])
}]

export { render, staticRenderFns }