<template>
  <div
    class="block w-full cst-drop-shadow bg-white rounded-xl mb-3 scroll-snap-start overflow-y-hidden"
  >
    <div v-if="!togglePreview" class="flex w-full items-center px-3 py-3">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
          src="@assets/img/integration/facebook-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>

    <div class="relative text-white">
      <!--   background   -->
      <div class="ratio ratio-9x16 preview-background">
        <img class="w-full object-cover" :src="video.thumbnail" alt="" />
      </div>

      <!--   header   -->
      <div class="absolute top-px left-0 w-full text-xl text-white p-5">
        <div class="flex justify-end items-center space-x-3 text-base">
          <i
            class="w-5 h-5 flex justify-center items-center fas fa-play text-sm"
          ></i>
          <i
            class="w-5 h-5 flex justify-center items-center fas fa-volume-down"
          ></i>
          <i
            class="w-5 h-5 flex justify-center items-center fas fa-ellipsis-h"
          ></i>
        </div>
      </div>

      <!-- playback icon -->
      <i
        class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid"
        style="transform: translate(-50%, -50%)"
        @click.prevent="displayFile('video', video.link, 0)"
      ></i>

      <!--   footer   -->
      <div class="absolute bottom-px left-0 w-full p-5 text-white">
        <div class="flex space-x-4">
          <div class="min-w-0 flex flex-col justify-end">
            <div class="flex items-center font-bold">
              <img
                :src="accountImage"
                alt=""
                class="rounded-full object-cover border border-solid border-black"
                width="40"
                height="40"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <div class="px-3 font-bold select-none">
                {{ accountName }}
                <div class="font-normal text-sm mt-1"
                  ><i class="fas fa-globe-asia mr-1"></i>Public</div
                >
                <!---->
              </div>
            </div>
            <div class="pt-3 py-0.5 pointer-events-none truncate">
              <span v-if="description" v-html="description"></span>
            </div>
          </div>
          <div class="ml-auto">
            <div class="flex flex-col justify-center items-center">
              <i
                class="fas fa-thumbs-up text-base w-8 h-8 flex justify-center items-center bg-gray-700 bg-opacity-70 rounded-full"
              ></i>
            </div>
            <div class="flex flex-col justify-center items-center mt-4">
              <i
                class="fas fa-comment text-base w-8 h-8 flex justify-center items-center bg-gray-700 bg-opacity-70 rounded-full"
              ></i>
            </div>
            <div class="flex flex-col justify-center items-center mt-4">
              <i
                class="fas fa-share text-base w-8 h-8 flex justify-center items-center bg-gray-700 bg-opacity-70 rounded-full"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'FacebookReelPreview',
  props: {
    accountName: {
      type: String,
      default: '',
      required: true,
    },
    accountImage: {
      type: String,
      required: true,
    },
    video: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    togglePreview: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Previewing/playing video file via event bus.
     * @param type
     * @param media
     * @param index
     */
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type: type,
        media: media,
        index: index,
      })
      this.$bvModal.show('display-file-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-background {
  &::after {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 72%,
      rgba(133, 133, 133, 0.3015406846) 100%
    );
    content: '';
    @apply absolute block w-full h-full top-0 left-0;
  }
}
</style>
