import { composer } from './composer-mutation-type'

const state = {
  editSeoPreview: false,
  selection: {
    keyword: '',
    url:'',
  }
}

const getters = {
  getBlogSubmissionSelection: (state) => {
    return state.selection
  },
  getEditSeoPreview: (state) => {
    return state.editSeoPreview
  }
}

const actions = {}

const mutations = {
  [composer.SET_EDIT_SEO_PREVIEW] (state, status) {
    state.editSeoPreview = status
  },

  [composer.RESET_COMPOSER_BLOG] (state) {
    console.log('TEST')
    state.editSeoPreview = false
    state.selection = {
      keyword: ''
    }
  },

  [composer.SET_SEO_KEYWORD] (state, keyword) {
    console.log('set keyword',keyword)
    state.selection.keyword = keyword
  },

  [composer.SET_SEO_URL] (state, url) {
    console.log('set url',url)
    state.selection.url = url
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
