<template>
  <div>
    <!-- Comment View -->
    <div v-if="!editMode" class="p-3 mb-2 flex flex-row bg-gray-100 rounded-md">
      <div class="w-10 h-10">
        <img
          alt="profile-image"
          class="w-10 h-10 rounded-full"
          :src="userProfileImage(message.user_id)"
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </div>

      <div class="ml-2 flex-1">
        <div>
          <span
            v-floatingtip="message.user.username"
            class="font-semibold mr-1"
            >{{ message.user.full_name }}</span
          >
          <span class="">{{ message.comment }}</span>
        </div>
        <div class="flex justify-between mt-1.5">
          <div class="text-xs text-gray-700 my-auto">{{
            moment(message.created_at).fromNow()
          }}</div>

          <div class="text-xs flex space-x-1.5">
            <i
              v-b-tooltip.top="'Edit Comment'"
              class="far fa-edit cursor-pointer"
              @click="onClickEdit"
            ></i>
            <i
              v-b-tooltip.top="'Delete Comment'"
              class="far fa-trash-alt text-red-700 cursor-pointer"
              @click="onClickDelete"
            ></i>
          </div>
        </div>

        <!--    Attached Media    -->
        <div>
          <div
            v-if="message.media.length > 0"
            class="flex flex-wrap space-x-2 mt-2"
          >
            <div
              v-for="(media, index) in message.media"
              :key="index"
              class="shadow-sm"
            >
              <div class="relative group">
                <img
                  alt="media"
                  class="w-20 h-20 object-cover rounded-md"
                  :src="media.link"
                  @error="
                    $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
                  "
                />

                <!--      Overlay          -->
                <div
                  class="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 rounded-md hidden group-hover:block"
                >
                  <div class="flex flex-col justify-center items-center h-full">
                    <i
                      class="fas fa-eye text-white text-xl cursor-pointer"
                      @click="openImageLightBox(media.link)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Mode -->
    <div v-if="editMode" class="my-2">
      <div class="py-2 border rounded-md border-gray-600 p-2">
        <AtTa
          v-model="comment.text"
          class="mentions-bottom"
          :hover-select="true"
          :members="getActiveWorkspaceMembersName"
        >
          <textarea
            id="comment-box-edit"
            ref="ed"
            v-model="comment.text"
            :disabled="loader.comments || loader.file"
            class="rounded-md w-full py-3 border-0 text-sm"
            maxlength="2200"
            placeholder="Add your comment here"
            rows="2"
          ></textarea>
        </AtTa>
        <div>
          <div
            v-if="comment.media.length"
            class="flex flex-wrap space-x-1.5 mt-1.5 mb-3.5"
          >
            <div
              v-for="(media, key) in comment.media"
              :key="key"
              v-tooltip="media.name"
              class="flex flex-col group relative"
            >
              <img
                :key="key"
                :src="media.link"
                alt=""
                class="w-16 h-16 rounded cursor-pointer"
              />
              <i
                class="cs-cross absolute hidden group-hover:block top-0 right-0 text-center p-1.5 bg-red-800 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
                @click="
                  () => {
                    comment.media.splice(key, 1)
                  }
                "
              ></i>
              <i
                class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
              ></i>
            </div>

            <div v-if="loader.file">
              <div
                class="w-16 h-16 rounded border flex justify-center items-center"
              >
                <clip-loader
                  :color="'#9da6ac'"
                  :size="'13px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <!-- Emoji attachments and hashtags-->
          <div
            class="composer-comment-toolbar flex space-x-1.5 justify-end items-center mt-1.5"
          >
            <div class="relative">
              <div @click="emojiPickerStates = !emojiPickerStates">
                <div
                  v-floatingtip.hover.top="'Add an emoji'"
                  class="w-8 h-8 flex items-center justify-center bg-gray-400 rounded-md cursor-pointer"
                >
                  <i class="fal fa-smile-beam text-blue-700 text-base"> </i>
                </div>
              </div>
              <CstEmojiPicker
                v-if="emojiPickerStates"
                position="top-end"
                :is-open="emojiPickerStates"
                @on-select="onEmojiSelect"
                @handle-click="emojiPickerStates = false"
              >
              </CstEmojiPicker>
            </div>

            <label
              v-floatingtip.hover.top="{
                content: 'Add media file',
              }"
              class="cursor-pointer w-8 h-8 mb-0 flex items-center justify-center bg-gray-400 rounded-md"
              for="update-comment-file-input"
            >
              <img
                width="16px"
                height="16px"
                :src="require('@assets/img/icons/planner/add_image.svg')"
                alt="add_media"
              />
            </label>
            <input
              id="update-comment-file-input"
              accept="image/*"
              class="d-none"
              type="file"
              @change.prevent="uploadCommentImage"
            />
            <i
              v-floatingtip.hover.top="{ content: 'Tag people' }"
              class="far fa-at cursor-pointer flex w-8 h-8 items-center justify-center bg-gray-400 rounded-md text-blue-700 font-1rem"
              @click="triggerAt"
            ></i>
          </div>

          <!-- Add Comment Button -->
          <div class="flex justify-start items-center">
            <CstButton
              :disabled="loader.comments || loader.file || processingComment"
              :loading="processingComment"
              class="ml-1.5"
              size="small"
              text="Update"
              @click="handleComment"
            />
            <CstButton
              :disabled="loader.comments || loader.file || processingComment"
              class="ml-1.5"
              size="small"
              text="Cancel"
              @click="editMode = !editMode"
            />
            <clip-loader
              v-if="loader.comments"
              :color="'#9da6ac'"
              :size="'16px'"
              class="spinner ml-2.5 mr-1"
              variant="info"
            ></clip-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AtTa from 'vue-at/dist/vue-at-textarea'
import CstButton from '@ui/Button/CstButton'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import { swalAttributes } from '@common/constants/common-attributes'
import { teamMixin } from '@common/mixins/setting/teamMixin'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  name: 'CommentCard',
  components: {
    AtTa,
    CstButton,
    CstEmojiPicker,
  },
  mixins: [Helpers, teamMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    planId: {
      type: String,
      default: '',
    },
    isNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment: moment,
      emojiPickerStates: false,
      editMode: false,
      loader: {
        file: false,
        comments: false,
      },
      comment: {
        text: '',
        mentioned_user: [],
        media: [],
      },
      processingComment: false,
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspaceMembersName', 'getActiveWorkspace']),
  },
  methods: {
    onEmojiSelect(emoji) {
      this.comment.text += emoji
    },
    /**
     * Event for updating the comment on a plan. emits the result
     */
    async handleComment() {
      console.log('METHOD::handleComment')
      this.processingComment = true
      const payload = {
        id: this.message._id,
        comment: this.comment.text,
        is_note: this.isNote,
        media: this.comment.media,
        mentioned_user: this.comment.mentioned_user,
        notification: '',
        plan_id: this.planId,
        title: null,
        type: 'list_plans',
        workspace_id: this.getActiveWorkspace._id,
      }

      await this.addComment(payload)
      this.editMode = false
      this.processingComment = false
    },
    /**
     * onClick Event for comment. syncs the plan media and comment with the comment editor
     */
    onClickEdit() {
      console.log('METHOD::onClickEdit')
      this.editMode = true
      this.comment.text = this.message.comment
      this.comment.mentioned_user = this.message.mentioned_user
      this.comment.media = this.message.media
    },
    /**
     * onClick event for deleting a comment.
     */
    async onClickDelete() {
      console.log('METHOD::onClickDelete')
      const response = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your comment?',
        { title: 'Remove Comment', ...swalAttributes() }
      )
      if (!response) return
      const payload = {
        id: this.message._id,
        plan_id: this.planId,
        workspace_id: this.getActiveWorkspace._id,
      }

      this.deleteComment(payload)
    },
    /**
     * Trigger method for tagging people on workspace
     */
    triggerAt() {
      const { ed } = this.$refs
      ed.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },
    /**
     * calls an api for uploading the comment media.
     * @returns {Promise<void>}
     */
    uploadCommentImage: async function uploadCommentImage() {
      this.loader.file = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.loader.file = false
      if (res) {
        if (res.data.status) {
          if (this.comment.media) {
            this.comment.media.push(res.data.media)
          } else {
            this.$set(this.comment, 'media', [res.data.media])
          }
        } else {
          await this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    userProfileImage(userId) {
      return this.teamMemberImageById(userId)
    },
    openImageLightBox(image) {
      console.log('openImageLightBox', image)
      this.$emit('openImageLightBox', image)
    },
  },
}
</script>

<style scoped></style>
