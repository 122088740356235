import { render, staticRenderFns } from "./PinterestPreview.vue?vue&type=template&id=13059a60&scoped=true&"
import script from "./PinterestPreview.vue?vue&type=script&lang=js&"
export * from "./PinterestPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13059a60",
  null
  
)

export default component.exports