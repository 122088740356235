<template>
  <div
    class="icon_tabs facebook_tabs"
    :class="{ disable_click: getTopicDisabled }"
  >
    <ul class="tab_ul">
      <li
        class="tab_li"
        @click.prevent="changeFacebookType('All')"
        :class="{ active: getFacebookContentFilter.type == 'All' }"
      >
        <a class="tab_a" href="javascript:void(0)">All</a>
      </li>
      <li
        class="tab_li"
        @click.prevent="changeFacebookType('Status')"
        :class="{ active: getFacebookContentFilter.type == 'Status' }"
      >
        <a class="tab_a" href="javascript:void(0)">Status</a>
      </li>
      <li
        class="tab_li"
        @click.prevent="changeFacebookType('Link')"
        :class="{ active: getFacebookContentFilter.type == 'Link' }"
      >
        <a class="tab_a" href="javascript:void(0)">Links</a>
      </li>
      <li
        class="tab_li"
        @click.prevent="changeFacebookType('Photo')"
        :class="{ active: getFacebookContentFilter.type == 'Photo' }"
      >
        <a class="tab_a" href="javascript:void(0)">Photos</a>
      </li>
      <li
        class="tab_li"
        @click.prevent="changeFacebookType('Video')"
        :class="{ active: getFacebookContentFilter.type == 'Video' }"
      >
        <a class="tab_a" href="javascript:void(0)">Videos</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getFacebookContentFilter', 'getTopicDisabled'])
  },
  methods: {}
}
</script>
