import Pusher from 'pusher-js'

const appState = 'production'

const pusherContainer = {
  develop: {
    app_id: '932709',
    key: '85b2a1b472cd63cfdf61',
    secret: 'daf08457ed9bbeef2e31',
    cluster: 'eu',
  },
  staging: {
    app_id: '932710',
    key: '1366421a02445c6bf0c8',
    secret: 'c7256337ed09bf7bdcfa',
    cluster: 'eu',
  },
  production: {
    app_id: '932711',
    key: '15535b0c4042e41abc7e',
    secret: 'dab0e84e280b62a0d275',
    cluster: 'eu',
  },
}

Pusher.logToConsole = false

export const pusherSocket = new Pusher('7df577b7a9b7aabcbf52', {
  cluster: 'eu',
  forceTLS: true,
})

export const pusherSocketPublish = new Pusher(
  process.env.VUE_APP_PUSHER_PUBLISH,
  {
    cluster: 'eu',
    forceTLS: true,
  }
)

export const pusherAuthSocketInbox = (userId, fullName, email) => {
  return new Pusher(pusherContainer[appState].key, {
    encrypted: true,
    authTransport: 'ajax',
    cluster: pusherContainer[appState].cluster,
    forceTLS: true,
    authEndpoint: process.env.VUE_APP_EMAILS_BASE_URL + 'pusher/auth',
    auth: {
      params: {
        user_id: userId,
        user_name: fullName,
        email: email,
      },
    },
  })
}

export const pusherAuthSocketAnalytics = () => {
  return new Pusher('70825c772bc75f2ad012', {
    encrypted: true,
    authTransport: 'ajax',
    cluster: pusherContainer[appState].cluster,
    forceTLS: true,
    auth: {
      params: {},
    },
  })
}

export const pusherAuthSocketCaptions = (userId, fullName, email) => {
  return new Pusher(process.env.VUE_APP_PUSHER_CAPTION_KEY, {
    encrypted: true,
    authTransport: 'ajax',
    cluster: pusherContainer[appState].cluster,
    forceTLS: true,
    authEndpoint: process.env.VUE_APP_EMAILS_BASE_URL + 'pusher/auth',
    auth: {
      params: {
        user_id: userId,
        user_name: fullName,
        email: email,
        module: 'caption-generation',
      },
    },
  })
}
