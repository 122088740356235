<template>
  <div
    class="dashboard-engagement-across-networks-chart"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="@assets/img/states/no-analytical-data-available.svg"
            style="height: 100px"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <div :class="{ blur_div: isContentNotAvailable }">
      <highcharts
        :ref="dashboard_engagement_ref"
        :options="engagementAcrossNetworks()"
      ></highcharts>
    </div>
    <div id="engagementAcrossNetworksLegend" class="dashboard-charts__legends">
    </div>
  </div>
</template>

<script>
import {
  splineChart,
  splineCredits,
  splineLegend,
  splinePlotOptions,
  splineTooltip,
  splineXAxis,
  splineYAxis,
} from '@src/modules/analytics/components/common/helper'
import moment from 'moment'
import { NO_CHART_DATA } from '@src/modules/common/constants/messages'
import { getChartColorCode } from '@src/modules/common/constants/common-attributes'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    engagement_with_durations: {
      type: [Object, Array],
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dashboard_engagement_ref: 'engagement_across_networks',
      no_data_available: NO_CHART_DATA,
      isContentNotAvailable: false,
    }
  },
  methods: {
    hexToRgba(hex, alpha) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      const resultValue = result
        ? 'rgba(' +
          parseInt(result[1], 16) +
          ',' +
          parseInt(result[2], 16) +
          ',' +
          parseInt(result[3], 16) +
          ',' +
          (alpha || 0.3) +
          ')'
        : null
      console.log('hextorgba', resultValue)
      return resultValue
    },
    engagementAcrossNetworks() {
      const data = {
        facebook: [],
        twitter: [],
        instagram: [],
        linkedin: [],
        pinterest: [],
      }
      let total = 0
      const selfObject = this
      Object.keys(data).forEach(function (key) {
        if (selfObject.engagement_with_durations.hasOwnProperty(key)) {
          console.debug(key)
          selfObject.engagement_with_durations[key].buckets.forEach((item) => {
            const value =
              item.total_engagement && item.total_engagement.value
                ? Math.round(item.total_engagement.value)
                : 0
            total = total + value
            data[key].push([moment(item.key_as_string).unix() * 1000, value])
          })
        }
      })
      if (total === 0) this.isContentNotAvailable = true
      if (this.isContentNotAvailable) {
        data.facebook.forEach((item) => {
          item[1] = Math.round(this.getRandomInt(20, 200))
        })
        data.twitter.forEach((item) => {
          item[1] = Math.round(this.getRandomInt(10, 150))
        })
        data.instagram.forEach((item) => {
          item[1] = Math.round(this.getRandomInt(10, 200))
        })
        data.linkedin.forEach((item) => {
          item[1] = Math.round(this.getRandomInt(10, 150))
        })
        data.pinterest.forEach((item) => {
          item[1] = Math.round(this.getRandomInt(20, 200))
        })
      }

      const splineChartItem = splineChart('engagementAcrossNetworksLegend')
      splineChartItem.height = 270
      const splinePlotOptionsItem = splinePlotOptions()
      return {
        credits: splineCredits(),
        tooltip: splineTooltip(),
        chart: {
          height: 270,
          type: 'areaspline',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif",
          },
          events: {
            load: function () {
              var chart = this
              document.getElementById(
                'engagementAcrossNetworksLegend'
              ).innerHTML = ''
              $(chart.series).each(function (i, seriesItem) {
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(seriesItem) +
                    ';" class="pink color_box"></span> <span>' +
                    seriesItem.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (seriesItem.visible) {
                      seriesItem.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      seriesItem.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#engagementAcrossNetworksLegend')
              })
            },
          },
        },
        legend: splineLegend(),

        colors: ['#1778f2', '#1cacee', '#1076a8', '#e4405f', '#e02f5f'],
        title: false,
        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        plotOptions: splinePlotOptionsItem,
        series: [
          {
            name: 'Facebook',
            data: data.facebook,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, '#1778f2'],
                [1, this.hexToRgba('#1778f2')],
              ],
            },
          },
          {
            name: 'Twitter',
            data: data.twitter,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, '#1cacee'],
                [1, this.hexToRgba('#1cacee')],
              ],
            },
          },
          {
            name: 'LinkedIn',
            data: data.linkedin,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, '#1076a8'],
                [1, this.hexToRgba('#1076a8')],
              ],
            },
          },
          {
            name: 'Instagram',
            data: data.instagram,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, '#dc4560'],
                [1, this.hexToRgba('#dc4560')],
              ],
            },
          },
          /* {
          name: 'Pinterest',
          data: data['pinterest'],
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#e02f5f'],
              [1, '#e36285']
            ]
          }
        } */
        ],
      }
    },
  },
}
</script>
