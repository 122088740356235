<template>
  <div class="analytics-report-wrapper">
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="1"
          :total="total"
        ></report-header>
        <div class="analytics-section-header mb-2">
          <h2>Facebook Page Performance Summary</h2>
          <p
            >View your key profile performance metrics from the reporting
            period.</p
          >
        </div>
        <div class="row">
          <template v-for="(value, key, i) in summary_values">
            <div class="col-6 col-sm-4" :class="{ 'mb-4': i < 6 }" :key="key">
              <template v-if="key === 'positive_sentiment'">
                <SummaryCard
                  :title="value"
                  :total="
                    metrics.overview.summary.current['positive_sentiment']
                      .value -
                    metrics.overview.summary.current['negative_sentiment'].value
                  "
                  variant="group"
                  :positive="
                    metrics.overview.summary.current['positive_sentiment'].value
                  "
                  :negative="
                    metrics.overview.summary.current['negative_sentiment'].value
                  "
                >
                </SummaryCard>
              </template>
              <template v-else-if="key === 'page_positive_feedback'">
                <SummaryCard
                  :title="value"
                  :total="
                    metrics.overview.summary.current['page_positive_feedback']
                      .value -
                    metrics.overview.summary.current['page_negative_feedback']
                      .value
                  "
                  variant="group"
                  :positive="
                    metrics.overview.summary.current['page_positive_feedback']
                      .value
                  "
                  :negative="
                    metrics.overview.summary.current['page_negative_feedback']
                      .value
                  "
                >
                </SummaryCard>
              </template>
              <template v-else>
                <SummaryCard
                  :title="value"
                  :total="metrics.overview.summary.current[key].value"
                  :reach="
                    Math.abs(getReach(key, metrics.overview.summary)) + '%'
                  "
                  :reachUp="getReachUp(key, metrics.overview.summary)"
                ></SummaryCard>
              </template>
            </div>
          </template>
        </div>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Facebook Audience Growth </h2>
              <p>See how your audience grew during the reporting period.</p>
            </div>
            <SplineBasicChart
              v-if="
                metrics.overview.audience.audience_growth.data.show_data > 0
              "
              :categories="metrics.overview.audience.audience_growth.buckets"
              :series="getAudienceGrowthSeries(metrics.overview.audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>

          <h2>Audience Growth Rollup</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview.audience_rollup_values"
            >
              <div
                class="col-6 col-sm-3 summary-card-wrapper"
                :class="{ 'mb-4': i < 4 }"
                :key="key"
              >
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    metrics.overview.audience.audience_growth_rollup.current[
                      key
                    ].value
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.audience.audience_growth_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.audience.audience_growth_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="2"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Facebook Publishing Behavior </h2>
              <p
                >View the different types of posts you published during the
                selected time period.</p
              >
            </div>
            <ColumnCrossHairChart
              v-if="
                metrics.overview.publishing_behaviour.publishing_behaviour.data
                  .show_data > 0
              "
              :series="
                getPublishingBehaviourSeries(
                  metrics.overview.publishing_behaviour
                )
              "
              :categories="
                metrics.overview.publishing_behaviour.publishing_behaviour
                  .buckets
              "
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Publishing Behavior by Content Type</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview
                .publishing_behaviour_rollup_values"
            >
              <div class="col-6 col-sm-4" :class="{ 'mb-4': i < 3 }" :key="key">
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    metrics.overview.publishing_behaviour
                      .publishing_behaviour_rollup.current[key]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.publishing_behaviour
                          .publishing_behaviour_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.publishing_behaviour
                        .publishing_behaviour_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="3"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Facebook Top Posts</h2>
              <p
                >Review your top posts published during the selected time
                period, based on the post’s lifetime performance.</p
              >
            </div>
            <template v-if="metrics.overview.top_posts.length <= 0">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no_post_found.svg"
                  alt=""
                />
                <p
                  >You do not have any posts published in the selected time
                  period.</p
                >
              </div>
            </template>
            <div v-else class="row">
              <template v-for="i in 3">
                <div :key="i" class="col-4">
                  <template v-if="metrics.overview.top_posts.length >= i">
                    <PostCard
                      :post="metrics.overview.top_posts[i - 1]"
                      platform_type="facebook"
                    />
                  </template>
                  <template v-else>
                    <!--  No More Posts   -->
                    <div class="analytics-post-card">
                      <div class="analytics-post-card__header">
                        <div class="analytics-post-card__header-left">
                          <div class="analytics-post-card__header-img">
                            <SkeletonBox
                              class="skeletonBox--noanimcation"
                              height="2.8rem"
                              width="2.8rem"
                              radius="50%"
                            />
                          </div>
                          <div class="analytics-post-card__header-detail">
                            <div
                              class="d-flex"
                              style="flex-direction: column; margin-left: 10px"
                            >
                              <div class="mb-1"> No More Post </div>
                              <SkeletonBox
                                class="skeletonBox--noanimcation"
                                radius=".2rem"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="analytics-post-card__header-right"
                          style="filter: grayscale(100%); opacity: 0.6"
                        >
                          <!-- Dynamic Icon bases on props -->
                          <i class="far fa-external-link-square-alt"></i>
                        </div>
                      </div>

                      <div class="analytics-post-card__content">
                        <div class="mb-1">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="100%"
                            radius=".2rem"
                          />
                        </div>
                        <div class="mb-2">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            radius=".2rem"
                          />
                        </div>
                        <div class="mb-2">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="100%"
                            height="260px"
                            radius=".2rem"
                          />
                        </div>
                      </div>

                      <ul class="analytics-post-card__content-ele">
                        <li v-for="i in 6" :key="i">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="40%"
                            radius=".2rem"
                          />
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="1.1rem"
                            radius=".2rem"
                          />
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="4"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Facebook Active Users</h2>
              <p
                >Review your active users as of the last day of the reporting
                period.</p
              >
            </div>
            <h3>Active Users by Hours</h3>
            <LineBasicChart
              v-if="
                metrics.overview.active_users.active_users_hours.highest_value >
                0
              "
              :categories="
                metrics.overview.active_users.active_users_hours.buckets
              "
              :series="
                getActiveUsersSeries(metrics.overview.active_users, 'hours')
              "
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  :src="
                    require('../../assets/imgs/no_data_images/no-analytical-data-available.svg')
                  "
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
            <p class="text_center"
              >Best hour to post is
              <b
                >{{
                  metrics.overview.active_users.active_users_hours.highest_hour
                }}:00</b
              >
              with highest value
              <b>{{
                metrics.overview.active_users.active_users_hours.highest_value
              }}</b></p
            >
            <h3>Active Users by Days</h3>
            <LineBasicChart
              v-if="
                metrics.overview.active_users.active_users_days.highest_value >
                0
              "
              :categories="
                metrics.overview.active_users.active_users_days.buckets
              "
              :series="
                getActiveUsersSeries(metrics.overview.active_users, 'days')
              "
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  :src="
                    require('../../assets/imgs/no_data_images/no-analytical-data-available.svg')
                  "
                  alt=""
                />

                <p> No Data Found!</p>
              </div>
            </div>
            <p class="text_center"
              >Best day to post is
              <b>{{
                metrics.overview.active_users.active_users_days.highest_day
              }}</b>
              with highest value
              <b>{{
                metrics.overview.active_users.active_users_days.highest_value
              }}</b></p
            >
          </section>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="5"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Facebook Impressions</h2>
              <p
                >Review how your content was seen by the Facebook community
                during the reporting period.</p
              >
            </div>
            <SplineBasicChart
              v-if="metrics.overview.impressions.impressions.data.show_data > 0"
              :series="getImpressionsSeries(metrics.overview.impressions)"
              :categories="metrics.overview.impressions.impressions.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Impressions Rollup</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview
                .impressions_rollup_values"
            >
              <div
                class="col-6 col-sm-4 summary-card-wrapper"
                :class="{ 'mb-4': i < 3 }"
                :key="key"
              >
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    isNaN(
                      metrics.overview.impressions.impressions_rollup.current[
                        key
                      ]
                    )
                      ? metrics.overview.impressions.impressions_rollup.current[
                          key
                        ].value
                      : metrics.overview.impressions.impressions_rollup.current[
                          key
                        ]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.impressions.impressions_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.impressions.impressions_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="6"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Facebook Video Performance</h2>
              <p
                >View your aggregate video performance during the reporting
                period.</p
              >
            </div>
            <div class="d-flex justify_center align-items-center flex_column">
              <div v-if="getVideoData(metrics.overview.video_analytics, 'engagement')[1][1] <= 0" class="flex-center-center" style="height: 90%">
                <div class="analytics-no-data-found">
                  <img
                      src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                  />
                  <p> No Data Found!</p>
                </div></div>
              <PieInnerChart
                v-on:mounted="getPieRef" v-else
                :chartRef="`video-overall-analytics-pie`"
                :colors="getVideoColors('engagement')"
                :data="
                  getVideoData(metrics.overview.video_analytics, 'engagement')
                "
                :seriesName="'Engagement and Views'"
                :width="270"
                :height="270"
              />
              <div class="d-flex justify_center analytics-pie-legends">
                <template
                  v-for="(obj, i) in legends['video-overall-analytics-pie']"
                >
                  <div
                    class="analytics-pie-legends__detail-item"
                    :key="i"
                    @click="legendClick(obj, obj.name !== 'No Data Found')"
                  >
                    <div class="analytics-pie-legends__detail-label v-metrics">
                      <span
                        :style="{ borderColor: obj.color }"
                        class="analytics-pie-legends__detail-label-icon"
                      ></span>
                      {{
                        capitalizeFirstLetter(obj.name !== null ? obj.name : '')
                      }}
                    </div>
                    <div
                      class="analytics-pie-legends__detail-value"
                      v-if="obj.name !== 'No Data Found'"
                    >
                      {{ obj.y }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="row mt-5">
              <div
                class="col-6 d-flex justify_center align-items-center flex_column"
              >
                <div v-if="getVideoData(metrics.overview.video_analytics, 'organic')[0][1]+getVideoData(metrics.overview.video_analytics, 'organic')[1][1]<=0" class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                        src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                        alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <PieInnerChart v-else
                  v-on:mounted="getPieRef"
                  :chartRef="`video-organic-analytics-pie`"
                  :colors="getVideoColors('organic')"
                  :data="
                    getVideoData(metrics.overview.video_analytics, 'organic')
                  "
                  :seriesName="'Paid and Organic Views'"
                  :width="180"
                  :height="180"
                />
                <div class="d-flex justify_center analytics-pie-legends">
                  <template
                    v-for="(obj, i) in legends['video-organic-analytics-pie']"
                  >
                    <div
                      class="analytics-pie-legends__detail-item"
                      :key="i"
                      @click="legendClick(obj, obj.name !== 'No Data Found')"
                    >
                      <div
                        class="analytics-pie-legends__detail-label v-metrics"
                      >
                        <span
                          :style="{ borderColor: obj.color }"
                          class="analytics-pie-legends__detail-label-icon"
                        >
                        </span>
                        {{
                          capitalizeFirstLetter(
                            obj.name !== null ? obj.name : ''
                          )
                        }}
                      </div>
                      <div
                        class="analytics-pie-legends__detail-value"
                        v-if="obj.name !== 'No Data Found'"
                      >
                        {{ obj.y }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div
                class="col-6 d-flex justify_center align-items-center flex_column"
              >
                <div v-if="getVideoData(metrics.overview.video_analytics, 'play')[0][1]+getVideoData(metrics.overview.video_analytics, 'play')[1][1] <= 0" class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                        src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                        alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <PieInnerChart v-else
                  v-on:mounted="getPieRef"
                  :chartRef="`video-play-analytics-pie`"
                  :colors="getVideoColors('play')"
                  :data="getVideoData(metrics.overview.video_analytics, 'play')"
                  :seriesName="'Video Plays'"
                  :width="180"
                  :height="180"
                />
                <div class="d-flex justify_center analytics-pie-legends">
                  <template
                    v-for="(obj, i) in legends['video-play-analytics-pie']"
                  >
                    <div
                      class="analytics-pie-legends__detail-item"
                      :key="i"
                      @click="legendClick(obj, obj.name !== 'No Data Found')"
                    >
                      <div
                        class="analytics-pie-legends__detail-label v-metrics"
                      >
                        <span
                          :style="{ borderColor: obj.color }"
                          class="analytics-pie-legends__detail-label-icon"
                        >
                        </span>
                        {{
                          capitalizeFirstLetter(
                            obj.name !== null ? obj.name : ''
                          )
                        }}
                      </div>
                      <div
                        class="analytics-pie-legends__detail-value"
                        v-if="obj.name !== 'No Data Found'"
                      >
                        {{ obj.y }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="6"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Facebook Engagement</h2>
              <p
                >See how people are engaging with your posts during the
                reporting period.</p
              >
            </div>
            <SplineBasicChart
              v-if="metrics.overview.engagement.engagement.data.show_data > 0"
              :series="getEngagementSeries(metrics.overview.engagement)"
              :categories="metrics.overview.engagement.engagement.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Engagements Rollup</h2>
          <div class="row">
            <template
              v-for="(value, key, i) in metrics.overview
                .engagement_rollup_values"
            >
              <div
                class="col-6 col-sm-4 summary-card-wrapper"
                :class="{ 'mb-4': i < 3 }"
                :key="key"
              >
                <SummaryCard
                  :key="key"
                  :title="value"
                  :total="
                    isNaN(
                      metrics.overview.engagement.engagement_rollup.current[key]
                    )
                      ? metrics.overview.engagement.engagement_rollup.current[
                          key
                        ].value
                      : metrics.overview.engagement.engagement_rollup.current[
                          key
                        ]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        metrics.overview.engagement.engagement_rollup
                      )
                    ) + '%'
                  "
                  :reachUp="
                    getReachUp(
                      key,
                      metrics.overview.engagement.engagement_rollup
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="7"
          :total="total"
        ></report-header>
        <div>
          <div class="row">
            <div class="col-6">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience by Age</h3>
                </div>
                <StraightLineChart
                  :values="metrics.demographics.fans_age.fans_age"
                  :max_value="metrics.demographics.fans_age.max_age"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="bordered-box bordered-box--contained">
                <div
                  class="bordered-box__header facebook-analytics-demo__gender"
                >
<!--                  <i class="far fa-venus-mars"></i>-->
                  <h3>Audience by Gender</h3>
                </div>
                <PieInnerChart
                  v-on:mounted="getPieRef"
                  :chartRef="`gender-overall-analytics-pie`"
                  :colors="['#6173fc', '#2fe095', '#ffe202']"
                  :data="[
                    ['Male', metrics.demographics.fans_gender['M']],
                    ['Female', metrics.demographics.fans_gender['F']],
                    ['Unspecified', metrics.demographics.fans_gender['U']],
                  ]"
                  class="flex-center-center"
                  style="flex-direction: column"
                  :width="270"
                  :height="270"
                />
                <div class="d-flex justify_center analytics-pie-legends">
                  <template
                    v-for="(obj, i) in legends['gender-overall-analytics-pie']"
                  >
                    <div
                      class="analytics-pie-legends__detail-item"
                      :key="i"
                      @click="legendClick(obj, obj.name !== 'No Data Found')"
                    >
                      <div
                        class="analytics-pie-legends__detail-label v-metrics"
                      >
                        <span
                          :style="{ borderColor: obj.color }"
                          class="analytics-pie-legends__detail-label-icon"
                        ></span>
                        {{ capitalizeFirstLetter(obj.name) }}
                      </div>
                      <div
                        class="analytics-pie-legends__detail-value"
                        v-if="obj.name !== 'No Data Found'"
                      >
                        {{ obj.y }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="bordered-box bordered-box--margined">
                <div class="bordered-box__header justify_center mb-0">
                  <h3 class="normal"
                    ><b>{{
                      metrics.demographics.gender[
                        metrics.demographics.max_gender_age.gender
                      ]
                    }}</b>
                    between the ages of
                    <b>{{ metrics.demographics.max_gender_age.age }}</b> appear
                    to be the leading force among your followers.</h3
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Facebook'"
          :current="8"
          :total="total"
        ></report-header>
        <div>
          <div class="row">
            <div class="col-6">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience Top Countries</h3>
                </div>

                <div class="analytics-audience">
                  <template
                    v-for="(audience_value, audience_key, index) in metrics
                      .demographics.audience_country"
                  >
                    <StatsCard
                      v-if="index < 10"
                      :key="index"
                      :title="audience_key"
                      :value="audience_value"
                      variant="country"
                    />
                  </template>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience Top Cities</h3>
                </div>
                <div class="analytics-audience">
                  <template
                    v-if="index < 10"
                    v-for="(audience_value, audience_key, index) in metrics
                      .demographics.audience_city"
                  >
                    <StatsCard
                      :key="index"
                      :title="audience_key"
                      :value="audience_value"
                      variant="city"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import facebookMixin from '@src/modules/analytics/components/common/series/facebookMixin'
import PostCard from '@src/modules/analytics/views/common/PostCard'
import StraightLineChart from '@src/modules/analytics/views/common/Infographics/StraightLineChart'
import StatsCard from '@src/modules/analytics/views/common/StatsCard'

export default {
  mixins: [facebookMixin, analyticsUtilsMixin],
  data () {
    return {
      total: 10,
      summary_values: {
        fan_count: 'Fans Count',
        total_engagement: 'Total Engagement',
        impressions: 'Impressions',
        posts_clicks: 'Post Clicks',
        reactions: 'Reactions',
        reposts: 'Reposts',
        positive_sentiment: 'Sentiments',
        page_positive_feedback: 'Feedback'
      },
      legends: []
    }
  },
  props: {
    metrics: {}
  },
  components: {
    ReportHeader,
    AnalyticsFilterBar,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    SkeletonBox,
    ColumnCrossHairChart,
    SplineBasicChart,
    LineBasicChart,
    PieInnerChart,
    PostCard,
    StatsCard,
    StraightLineChart
  },
  computed: {},
  methods: {
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    }
  }
}
</script>
