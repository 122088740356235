<template>
  <div class="articles_list">
    <video-preview></video-preview>
    <facebook-preview></facebook-preview>
    <div class="article_inner">
      <div
        class="fb_quote_box_grid article_box_grid"
        v-if="post"
        v-for="(post, index) in getFacebookPosts"
      >
        <!--{{post}}-->
        <div class="box_inner">
          <div class="image_block">
            <div
              v-if="
                post._source.video_preview && postType(post._source) == 'Video'
              "
              class="play_icon facebook_vid_icon"
              @click.prevent="
                previewVideo(
                  getFacebookTitle(post._source),
                  getFacebookDescription(post._source),
                  getFacebookEmbedHtml(post._source),
                  'Facebook',
                  post._source.link
                )
              "
            >
              <i class="cs-play"></i>
            </div>
            <!--getFacebookEmbedHtml(post._source)-->
            <div class="tags_block">
              <span class="tag">{{ postType(post._source) }}</span>
            </div>
            <div
              class="img"
              v-lazy:background-image="backgroundFacebookImageURL(post._source)"
            ></div>
          </div>

          <div class="content_block">
            <div class="content_block_height">
              <div class="users_detail">
                <div class="profile_picture d-flex align-items-start fb">
                  <div
                    class="picture_block"
                    v-tooltip.top-center="getPageName(post._source)"
                  >
                    <div class="icon">
                      <i class="cs-facebook"></i>
                    </div>
                    <img :src="getPageImagePost(post._source)" alt="" />
                  </div>
                  <div class="text_block">
                    <p
                      v-if="postType(post._source) !== 'Video'"
                      @click="showFacebookModal(post)"
                      data-toggle="modal"
                      data-target="#facebook_preview_modal"
                      class="text"
                      ><span class="bold">{{
                        limitTextLength(getPageName(post._source), 30)
                      }}</span></p
                    >
                    <p
                      v-else
                      class="text"
                      @click="showFacebookModal(post)"
                      data-toggle="modal"
                      data-target="#facebook_preview_modal"
                      ><span class="bold">{{
                        limitTextLength(getPageName(post._source), 30)
                      }}</span></p
                    >
                    <p class="sub_text">{{
                      $filters.relative(post._source.created_time)
                    }}</p>
                  </div>
                </div>
              </div>
              <p class="desc" v-html="post._source.message">
                {{ post._source.message }}
              </p>
            </div>

            <!--<div class="sub_title" v-if="postType(post._source)=='Link'">-->
            <!--<a :href="post._source.link" target="_blank" v-html="post._source.name"></a>-->
            <!--</div>-->
            <!--<div class="sub_desc top-title" style="padding-top: 0;" v-if="postType(post._source)=='Link'">-->
            <!--<p>{{limitTextLength(post._source.description,40)}}</p>-->
            <!--</div>-->
            <!--<a v-if="postType(post._source)=='Link'" href="" class="link_area">{{trimURL(post._source.caption)}}</a>-->

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item" v-tooltip.top-center="'Reactions'">
                  <div class="name">
                    <p>
                      <i class="cs-thumbs-up"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post._source.total_engagements) }}</h3>
                  </div>
                </div>
                <div class="stat_item" v-tooltip.top-center="'Comments'">
                  <div class="name">
                    <p>
                      <i class="cs-comment-square"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post._source.comments) }}</h3>
                  </div>
                </div>
                <div class="stat_item" v-tooltip.top-center="'Shares'">
                  <div class="name">
                    <p>
                      <i class="cs-share"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post._source.shares) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_block d-flex text-center">
              <div class="btn_block_inner">
                <div
                  class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <!--v-if="postType(post._source) != 'Video'"-->
                  <div
                    class="dropdown_header"
                    data-toggle="dropdown"
                    @click="
                      initializeFacebookPostCreation(
                        post._source,
                        'social_share_modal'
                      )
                    "
                  >
                    <button
                      class="btn light_gray"
                      v-tooltip.top-center="'Share this post'"
                    >
                      <i class="icon-Share_icon d-block"></i>
                      <!--<span class="circle_count">7</span>-->
                    </button>
                  </div>

                  <!--<template v-else>-->

                  <!--<div class="dropdown_header" data-toggle="dropdown">-->
                  <!--<button v-tooltip.top-center="'Share this post'" class="btn light_gray">-->
                  <!--<i class="cs-share d-block"></i>-->
                  <!--&lt;!&ndash;<span class="circle_count">7</span>&ndash;&gt;-->
                  <!--</button>-->
                  <!--</div>-->

                  <!--<div class="dropdown-menu dropdown-menu-right">-->
                  <!--<ul class="inner">-->
                  <!--<li class="list_item_li  gray_dark gradient_hover" @click="initializeFacebookPostCreation(post._source,'social_share_modal')">-->
                  <!--<i class="cs-social-share"></i>-->
                  <!--<span class="icon_text">Social Media</span>-->
                  <!--</li>-->
                  <!--<li class="list_item_li  gray_dark gradient_hover" @click="initializeFacebookPostCreation(post._source,'blog_share_modal')">-->
                  <!--<i class="cs-rss"></i>-->
                  <!--<span class="icon_text">Blog Post</span>-->
                  <!--</li>-->
                  <!--</ul>-->
                  <!--</div>-->

                  <!--</template>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPreview from './preview/VideoPreview'
import FacebookPreview from './preview/FacebookPreview'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    VideoPreview,
    FacebookPreview
  },

  data () {
    return {}
  },
  created () {},
  computed: {
    ...mapGetters(['getFacebookPosts'])
  },

  methods: {
    ...mapActions([])
  },

  watch: {}
}
</script>
