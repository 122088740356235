import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getAudienceGrowthSeries (audience) {
      return [
        {
          name: 'Paid Followers Count',
          data: audience.audience_growth.data.paid_follower_count,
          color: '#ffe202'
        },
        {
          name: 'Organic Followers Count',
          data: audience.audience_growth.data.organic_follower_count,
          color: '#2fe095'
        },
        {
          name: 'Total Followers Count',
          data: audience.audience_growth.data.total_follower_count,
          color: '#e02f5f'
        }
      ]
    },
    getEngagementSeries (engagement) {
      return [
        {
          name: 'Comments Count',
          data: engagement.engagement.data.comments,
          color: '#ffe202'
        },
        {
          name: 'Favorites Count',
          data: engagement.engagement.data.favorites,
          color: '#2fe095'
        },
        {
          name: 'Total Engagement',
          data: engagement.engagement.data.total_engagement,
          color: '#e02f5f'
        }
      ]
    },
    getDaysBuckets (posts_density) {
      return Object.keys(posts_density.data.days)
    },
    getPostsDensitySeries (posts_density) {
      return [
        {
          name: 'Posts',
          data: Object.values(posts_density.data.days),
          color: '#6173ff'
        }
      ]
    },
    getHashtagsSeries (data) {
      return [
        {
          name: 'Occurrences',
          type: 'wordcloud',
          data: data
        }
      ]
    }
  }
}
