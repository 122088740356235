<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_left_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ noDataMessage }}
        </p>
      </div>
    </template>

    <h2> Total Engagement by Network </h2>
    <div
      class="topics-insights-container__chart_legends"
      id="totalEngagementByNetwork"
    />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        :options="totalEngagement"
        ref="total_engagement_item_highlight"
      ></highcharts>
    </div>
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'

const itemsList = [
  '<i class="cs-facebook "></i>Facebook',
  '<i class=" cs-reddit"></i>Reddit',
  '<i class="cs-pinterest "></i>Pinterest'
]
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_engagement_with_count: {}
  },
  computed: {
    isContentNotAvailable () {
      if (this.isZero()) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      totalEngagement: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} at {point.name}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#total_engagement_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#4e92ff', '#ff4500', '#cb2026', '#0078b5', '#39475d'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          // categories: ['Facebook', 'Reddit', 'Pinterest'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            },
            formatter: function () {
              return itemsList[this.value]
            },
            useHtml: true
          }
        },
        yAxis: {
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          gridLineColor: '#eaf0f4',
          title: {
            text: 'Number of Engagements',
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          min: 0
          // categories: ['0', '5', '10', '15', '20', '25']
        },
        plotOptions: {
          series: {
            pointWidth: 25,
            borderWidth: 0,
            lineWidth: 3
          },
          column: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            colorByPoint: true
          }
        },
        series: [
          {
            name: 'Total Engagement',
            data: [
              ['Facebook', 50],
              ['Reddit', 30],
              ['Pinterest', 70]
            ]
          }
        ]
      }
    }
  },
  created () {
    if (!this.isZero()) {
      this.totalEngagement.series[0].data = [
        [
          'Facebook',
          parseInt(this.articles_engagement_with_count.sum.facebook)
        ],
        ['Reddit', parseInt(this.articles_engagement_with_count.sum.reddit)],
        [
          'Pinterest',
          parseInt(this.articles_engagement_with_count.sum.pinterest)
        ]
      ]
    }
  },
  methods: {
    isZero () {
      if (
        parseInt(this.articles_engagement_with_count.sum.facebook) +
          parseInt(this.articles_engagement_with_count.sum.reddit) +
          parseInt(this.articles_engagement_with_count.sum.pinterest) ==
        0
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
