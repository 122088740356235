<template>
  <div class="padding-top_50">
    <div class="no_account_connected">
      <!--        <img :src="getConnectAccountImage" alt="">-->
      <template v-if="name === 'Twitter'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/twitter.png"
          alt=""
        />
      </template>
      <template v-else-if="name === 'Facebook'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/facebook.png"
          alt=""
        />
      </template>
      <template v-else-if="name === 'Instagram'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/instagram.png"
          alt=""
        />
      </template>
      <template v-else-if="name === 'Linkedin'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/linkedin.png"
          alt=""
        />
      </template>
      <template v-else-if="name === 'Pinterest'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/pinterest.png"
          alt=""
        />
      </template>
      <template v-else-if="name === 'Group'">
        <img
          class="connect_account"
          src="../../../../assets/img/analytics/connect/group-connect-account.svg"
          alt=""
        />
      </template>

      <p>
        <template v-if="name === 'Group'">
          You don't have any social accounts connected.
        </template>
        <template v-else
          >You don't have any {{ name }} account connected</template
        >
      </p>

      <button
        v-if="name === 'Group'"
        @click="connectSocialAccount(name)"
        class="btn btn-studio-theme-space"
      >
        <span>Connect an account</span>
      </button>
      <button
        v-else
        @click="connectSocialAccount(name)"
        class="btn btn-studio-theme-space"
      >
        <span>Connect an account</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {}
  },
  methods: {
    connectSocialAccount (hash = null) {
      if (hash) {
        this.$router.push({
          name: 'social',
          hash: '#' + hash.toLowerCase()
        })
      } else {
        this.$router.push({
          name: 'social'
        })
      }
    }
  }
}
</script>

<style scoped>
.connect_account {
  padding-bottom: 15px;
}
</style>
