import { linkedin } from '@src/modules/integration/store/states/mutation-types'

const getDefaultLinkedinAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultLinkedinAccountsState()
  },
  getters: {
    getLinkedinAccounts: (state) => state.accounts,
    getLinkedinCompanyPages: (state) => {
      let pages = []
      if (state.accounts.items.length > 0) {
        pages = state.accounts.items.filter((item) => {
          if (item.type === 'Page') return true
        })
      }
      return pages
    }
  },
  actions: {},
  mutations: {
    [linkedin.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [linkedin.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [linkedin.SET_DEFAULT] (state) {
      state.accounts = getDefaultLinkedinAccountsState()
    }
  }
}
