<template>
  <div class="flex w-100 justify-center items-center flex-col">
    <img
      src="@assets/img/states/empty-inbox-reply.png"
      class="w-[14.188rem] h-[14.188rem] my-4"
      alt="empty list"
    />
    <h3 class="text-[#3a4557] opacity-80 m-2 text-xl"
      >No Saved Caption Found</h3
    >
    <button
      class="py-3 px-8 border-0 m-2 mr-0 white-space-nowrap text-sm font-medium rounded-lg text-white text-center bg-blue-500 hover:bg-blue-400"
      @click="changeViewMode('create')"
    >
      Create New
    </button>
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CaptionsNotFound',
  props: {
    changeViewMode: {
      type: Function,
      default: () => {},
    },
  },
})
</script>
