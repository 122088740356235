import { mapGetters, mapActions } from 'vuex'

const socketMixin = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
    ...mapActions(['setOnlineUserEmails']),

    saveSocketLogs(data, message = 'load socket broadcast') {
      data.name = 'loadSocket'
      data.user_id = this.$store.getters.getProfile._id
      data.email = this.$store.getters.getProfile.email
      window.DD_LOGS && window.DD_LOGS.logger.info(message, data)
    },
  },
}

export { socketMixin }
