<template>
  <div>
    <template v-if="(isTopic || automation) && !getTrendingStories">
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="trending_icon icon_first cs-trending-chart"></i>
        <span class="text">{{ getTopicsSort }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-left">
        <ul class="inner">
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Recent')"
              >Recent</a
            >
          </li>
          <li class="list_item" v-show="isShowTrendingFilter">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Trending')"
              >Trending</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Relevance')"
              >Relevance</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Most Shared')"
              >Most Shared</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Facebook Shares')"
              >Facebook Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Twitter Shares')"
              >Twitter Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Pinterest Shares')"
              >Pinterest Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('topics', 'Reddit Shares')"
              >Reddit Shares</a
            >
          </li>
        </ul>
      </div>
    </template>

    <template v-else-if="!getTrendingStories">
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="trending_icon icon_first cs-trending-chart"></i>
        <span class="text">{{ getArticlesSort }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-left">
        <ul class="inner">
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Recent')"
              >Recent</a
            >
          </li>
          <!--<li class="list_item">
                        <a class="dropdown-item" href="#" @click.prevent="changeSort('articles', 'Trending')">Trending</a>
                    </li>-->
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Most Shared')"
              >Most Shared</a
            >
          </li>
          <li class="list_item" v-if="!getFilters.topic">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Relevance')"
              >Relevance</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Facebook Shares')"
              >Facebook Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Twitter Shares')"
              >Twitter Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Pinterest Shares')"
              >Pinterest Shares</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="changeSort('articles', 'Reddit Shares')"
              >Reddit Shares</a
            >
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { topicTypes } from '../../store/mutation-types'
import moment from 'moment'
export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  methods: {
    ...mapActions([
      'getContent',
      'articles',
      'topicsContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations([
      topicTypes.RESET_TOPICS_FILTER,
      topicTypes.SET_TOPICS_SORT,
      topicTypes.SET_TOPICS_DATE_RANGE
    ]),
    changeSort: async function (type, value) {
      switch (type) {
        case 'articles':
          await this.getContent({ filter: { key: 'sort', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        case 'topics':
          if (value.toLowerCase() === 'trending') {
            const rangeDefault = {
              label: 'Last 7 days',
              value:
                moment().subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss') +
                ' - ' +
                moment().format('YYYY-MM-DDTHH:mm:ss')
            }
            this[topicTypes.SET_TOPICS_DATE_RANGE](rangeDefault)
          } else {
            const rangeDefault = {
              label: 'Last 30 days',
              value:
                moment().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
                ' - ' +
                moment().format('YYYY-MM-DDTHH:mm:ss')
            }
            this[topicTypes.SET_TOPICS_DATE_RANGE](rangeDefault)
          }
          this[topicTypes.SET_TOPICS_SORT](value)
          this[topicTypes.RESET_TOPICS_FILTER]()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        default:
          break
      }
    }
  },
  computed: {
    ...mapGetters([
      'getArticlesSort',
      'getTopicsSort',
      'getTrendingStories',
      'getFilters'
    ]),
    isShowTrendingFilter () {
      return (
        this.$route.params.module === 'curated' &&
        this.$route.params.topicId === 'N764025'
      )
    }
  }
}
</script>
