var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"relative h-[2rem] box-content border border-2 border-[#2a8bc3] align-top lg:px-2 sm:px-1",attrs:{"data-date-time":`${_vm.day}-${
    _vm.index > 12 ? _vm.index % 12 : _vm.index === 12 ? 0 : _vm.index
  }-${_vm.index >= 12 ? 'PM' : 'AM'}`}},[_c('div',{staticClass:"all-slots"}),_c('div',{staticClass:"group custom-slot-btn cursor-pointer absolute w-full bottom-0 h-full left-1.5",on:{"click":function($event){return _vm.addSlotModal({
        hour: _vm.index > 12 ? _vm.index % 12 : _vm.index,
        minutes: 0,
        period: _vm.index >= 12 ? 'PM' : 'AM',
        day: _vm.day,
      })}}},[_c('button',{staticClass:"h-[2rem] absolute bottom-0 w-full hidden group-hover:block duration-300 bg-[#0068e5] text-white w-11/12 rounded-md border-0"},[_vm._v(" Add a slot ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }