<template>
  <div class="tabs" :class="{ flex: direction === 'vertical' }">
    <div class="tabsList">
      <ul class="flex" :class="{ 'flex-col': direction === 'vertical' }">
        <template v-for="(tab, index) in getTabsTitleContent">
          <li :key="index" :class="{ hidden: isHidden(index) }">
            <div
              :class="[
                'bg-white p-1 border flex w-max items-center cursor-pointer',
                getTabClass(index),
                getActiveTabStyle(index),
              ]"
              @click="setActiveTab(index)"
              v-html="tab.innerHTML"
            ></div>
          </li>
        </template>
      </ul>
    </div>
    <div class="tabsContent" :class="contentClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstTabs',
  provide() {
    return {
      ctx: this,
    }
  },
  props: {
    defaultSelectedTab: { type: Number, default: 1 },
    activeTabClass: { type: String, default: '' },
    contentClass: { type: String, default: '' },
    direction: {
      type: String,
      default: 'horizontal',
      validator: function (value) {
        return ['horizontal', 'vertical'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [],
    }
  },
  computed: {
    getTabsTitleContent() {
      return this.tabs.map((tab) => tab.$refs.title)
    },
    visibleTabs() {
      return JSON.parse(
        JSON.stringify(
          this.tabs
            .map((tab, index) => {
              return {
                active: tab.isActive,
                hidden: tab.hidden,
                index: index,
              }
            })
            .filter((item) => !item.hidden)
        )
      )
    },
    isActiveExist() {
      return this.visibleTabs.filter((tab) => tab.active).length > 0
    },
  },
  watch: {
    activeTabIndex: function (val) {
      this.$emit('active-tab', val)
    },

    visibleTabs: function (val) {
      if (val.length > 0) {
        if (this.isActiveExist) {
          return
        }
        this.setActiveTab(val[0].index)
      }
    },
  },
  mounted() {
    this.activeTabIndex = this.defaultSelectedTab - 1
    this.tabs = this.$slots.default
      .map((tab) => tab.componentInstance)
      .filter((item) => {
        if (item) return item
      })
    this.$emit('active-tab', this.activeTabIndex)
    this.setActiveTab(this.activeTabIndex)
  },
  methods: {
    getTabName(index) {
      return this.tabs[index]?.name
    },
    getTabClass(index) {
      return this.tabs[index]?.titleClass
    },
    isHidden(index) {
      return this.tabs[index]?.hidden
    },
    getActiveTabStyle(index) {
      if (this.tabs[index].isActive) {
        return this.activeTabClass ? this.activeTabClass : 'text-blue-400'
      }
    },
    setActiveTab(index) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === this.getTabName(index)
      })
      this.activeTabIndex = index
    },
  },
}
</script>

<style lang="scss" scoped></style>
