<template>
  <div
    class="bulk_dropdown dropdown option_dropdown default_style_dropdown"
    :disabled="getPlans.selected_plans.length === 0"
  >
    <b-dropdown right :disabled="getPlans.selected_plans.length === 0">
      <template slot="button-content"> Bulk Action </template>

      <b-dropdown-item
        class="list_item_li"
        href="#"
        @click="processBulkOperation('deleted')"
      >
        Remove
      </b-dropdown-item>

      <template v-if="hasPermission('can_schedule_plan')">
        <template v-if="bulkChangeStatusOptionVisibilityStatus">
          <b-dropdown-item
            class="list_item_li"
            @click="processBulkOperation('scheduled')"
          >
            Approve
          </b-dropdown-item>
          <b-dropdown-item
            class="list_item_li"
            @click="processBulkOperation('rejected')"
          >
            Reject
          </b-dropdown-item>
        </template>

        <template v-if="!bulkChangeStatusOptionVisibilityStatus">
          <b-dropdown-item class="list_item_li disabled">
            <span
              >Approve
              <i class="far fa-question-circle custom_tooltip">
                <div class="tool_tip_box">
                  <div class="inner_box" v-html="bulk_disable_message"></div>
                </div>
              </i>
            </span>
          </b-dropdown-item>

          <b-dropdown-item class="list_item_li disabled">
            <span
              >Reject
              <i class="far fa-question-circle custom_tooltip">
                <div class="tool_tip_box">
                  <div class="inner_box" v-html="bulk_disable_message"></div>
                </div>
              </i>
            </span>
          </b-dropdown-item>
        </template>
      </template>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getPlans'])
  },
  methods: {
    processBulkOperation (operation) {
      this.$parent.operation = operation
      this.$bvModal.show('processPlanBulkOperation')
    },
    bulkChangeStatusOptionVisibilityStatus () {
      const plans = this.getPlans

      if (plans.check_all) {
        // if more than one plan statuses are showing than option should not be available
        const statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : [
            'scheduled',
            'published',
            'failed',
            'rejected',
            'under_review',
            'missed_review',
            'draft'
          ]
        if (statuses.length > 1 || statuses.length === 0) return false
        if (statuses[0] !== 'under_review') return false
      }
      let status = true

      // if all selected plans are not of type under review than  option should not be available
      plans.selected_plans.forEach((selectedPlan) => {
        plans.items.forEach((plan) => {
          if (plan._id === selectedPlan) {
            if (this.getStatusClass(plan) !== 'reviewed') {
              status = false
              return false
            }
          }
        })
        if (!status) return false
      })
      return status
    }
  }
}
</script>
