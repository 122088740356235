import { mapGetters, mapActions, mapMutations } from 'vuex'
import { VueRenderer } from '@ui/TextArea/helpers/VueRenderer'
import CstToast from '@ui/Toast/CstToast'

const notificationMixin = {
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  methods: {
    ...mapActions(['setFetchImportantNotificationsLoader']),
    ...mapMutations(['SET_IMPORTANT_NOTIFICATIONS_ITEMS']),
    /**
     * To pop up a message
     * @param message The message string to be displayed to the user
     * @param type The type of the message to be displayed (info, error, success) etc
     */
    alertMessage(message, type) {
      if (this.$route.name === 'analytics_pdf_report') {
        return
      }
      console.debug('notification detail message ==== ', message, type)

      const toast = this.$toasted.show(this.createAlertMessage(type, message), {
        theme: 'primary',
        position: 'top-right',
        duration: 5000,
        className: 'toast-alert',
      })
      const closeIcon = toast.el.firstElementChild.lastElementChild
      closeIcon.addEventListener('click', (e) => {
        toast.goAway(0)
        /* Velocity(toast_item, { 'opacity': 0, marginTop: '-40px' }, {
           duration: 375,
           easing: 'easeOutExpo',
           queue: false,
           complete: function () {
             toast.el.remove()
           }
         }) */
      })

      if (type === 'error') {
        return false
      }
    },
    createAlertMessage(type, message) {
      const component = new VueRenderer(CstToast, {
        // using vue 2:
        parent: this,
        propsData: {
          type,
          message,
        },
      })

      return component.element.outerHTML
    },
  },
}

export { notificationMixin }
