<template>
  <div v-if="is_group" class="card_activity">
    <div class="box_inner d-flex flex-row">
      <div class="profile_picture">
        <div class="picture_block">
          <div class="icon">
            <i class="cs-pinterest"></i>
          </div>
          <img
            v-if="getActiveBoardImage(post.board_id)"
            :src="getActiveBoardImage(post.board_id)"
            alt=""
          />
          <img
            v-else
            src="../../../../../assets/img/profile_default.svg"
            alt=""
          />
        </div>
      </div>

      <div class="description">
        <h2
          ><span class="bold">{{ post.author_name }}</span></h2
        >
        <p class="time">
          {{ post.created_at | publishedDate }}
        </p>
        <p v-html="post.pin_text"></p>
      </div>
      <div class="right_block">
        <div class="d-flex flex-row align-items-center">
          <div class="img" v-if="post.image">
            <img class="img_block" width="100px" :src="post.image" alt="" />
          </div>
          <!--<div class="img">-->
          <!--<img src="http://e4336fa0c208189c0eee-e6761b7f2db36d2d2df00f6c169a70d2.r66.cf1.rackcdn.com/lps/assets/u/who1299ex-178939-W-Union-Square-&#45;&#45;Exterior.jpg" alt="">-->
          <!--</div>-->
          <div class="icons">
            <!--<p class="d-flex align-items-center">-->
            <!--<i class=" cs-heart"></i>-->
            <!--<span class="ml-auto">{{post._source.favorite_count}}</span>-->
            <!--</p>-->
            <p class="d-flex align-items-center">
              <i class="cs-refresh" v-tooltip="'Post Repins'"></i>
              <span class="ml-auto" v-if="post.repins_count">{{
                $filters.numberToCommas(post.repins_count)
              }}</span>
              <span class="ml-auto" v-else>0</span>
            </p>
            <p class="d-flex align-items-center">
              <i class="cs-comment" v-tooltip="'Post Comments'"></i>
              <span class="ml-auto" v-if="post.comments_count">{{
                $filters.numberToCommas(post.comments_count)
              }}</span>
              <span class="ml-auto" v-else>0</span>
            </p>
            <!--<p class="d-flex align-items-center">-->
            <!--<i class=" cs-group"></i>-->
            <!--<span class="ml-auto">{{post._source.followers_count}}</span>-->
            <!--</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="d-flex align-items-start post_block">
    <div class="profile_picture d-flex align-items-start">
      <div class="picture_block">
        <img
          v-if="getActiveBoardImage(post.board_id)"
          :src="getActiveBoardImage(post.board_id)"
          alt=""
        />
        <img
          v-else
          src="../../../../../assets/img/profile_default.svg"
          alt=""
        />
      </div>
      <div class="text_block">
        <p class="text">{{ post.board_name }}</p>
        <p class="sub_text">
          {{ post.created_at | publishedDate }}
        </p>

        <template>
          <p v-html="isHashtag(isLink(post.pin_text))" class="desc"></p>
        </template>
      </div>
    </div>
    <div v-if="post.image">
      <img class="img_block" width="200px" :src="post.image" alt="" />
    </div>
  </div>
</template>
<script>
import analyticsUtilsMixin from '../analyticsUtilsMixin'

export default {
  mixins: [analyticsUtilsMixin],
  props: {
    post: {},
    is_group: {},
    is_report: {}
  }
}
</script>
