var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full_height modal fade twitter_influencer_modal right side_slide_right w_1050 blog_article_preview",attrs:{"id":"blog_article_preview"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal_body height"},[_c('div',{staticClass:"blog_preview_block"},[_c('div',{staticClass:"blog_preview_inner"},[(
                _vm.blogPreviewHTML.length > 35 ||
                _vm.getBlogPostingDetails.image.link ||
                _vm.getBlogTitle(false)
              )?[(_vm.getBlogPostingDetails.image.link || _vm.getBlogTitle(false))?_c('div',{staticClass:"post_head"},[(_vm.getBlogPostingDetails.image.link)?_c('img',{staticClass:"featured_img",attrs:{"src":_vm.getBlogPostingDetails.image.link,"alt":""}}):_vm._e(),(_vm.getBlogTitle(false))?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.getBlogTitle(false))}}):_vm._e(),_vm._m(1)]):_vm._e(),_c('div',{staticClass:"post_description"},[_c('div',{staticClass:"curationCuratedDescription",attrs:{"id":"previewCuration"},domProps:{"innerHTML":_vm._s(_vm.blogPreviewHTML)}})])]:[_vm._m(2)]],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_head d-flex align-items-center",staticStyle:{"background":"#ffffff"}},[_c('h2',[_vm._v("Article Preview")]),_c('button',{staticClass:"close",staticStyle:{"background":"#ffffff"},attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post_head_bottom hide"},[_c('p',{staticClass:"author"},[_vm._v("By "),_c('span',{staticClass:"bold"},[_vm._v("Natasha Bertrand")]),_vm._v(" on "),_c('a',{attrs:{"href":""}},[_vm._v("theatlantic.com")])]),_c('p',{staticClass:"date"},[_vm._v("Oct 30 2018")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no_blog_content"},[_c('img',{attrs:{"src":"/img/no_block_content.png","alt":""}}),_c('p',{staticClass:"bold"},[_vm._v("No content added yet.")]),_c('p',{staticClass:"light"},[_vm._v("Please write some content to get preview.")])])
}]

export { render, staticRenderFns }