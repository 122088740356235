<template>
  <div class="">
    <div
      :class="{
        loader_overlay_with_loader: getFetchIntegrationsAccountsLoader,
      }"
      v-if="getReplug.item._id"
    >
      <clip-loader
        v-if="getFetchIntegrationsAccountsLoader"
        color="#4165ed"
        :size="'16px'"
        class="d-inline-block align-middle"
      ></clip-loader>
      <div
        :class="{ 'mt-3': nomargin, 'm-2': !nomargin }"
        v-if="getReplug.brands.length > 0"
      >
        <div class="field_group">
          <select
            name=""
            id=""
            class="select_sm"
            v-model="getPublishSelection.replug.brand"
            @change="setSharingReplugCampaign('')"
          >
            <option value="">Select Brand</option>
            <option v-for="item in getReplug.brands" :value="item">{{
              item.name
            }}</option>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>

      <p
        class="no_data_p text-center p-2"
        :class="{ 'text-center': textAlign }"
        v-else
      >
        You do not have any Replug brand created.
      </p>

      <template v-if="getPublishSelection.replug.brand">
        <div
          class="m-2"
          v-if="getPublishSelection.replug.brand.campaign.length > 0"
        >
          <div class="field_group">
            <select
              name=""
              id=""
              class="select_sm"
              v-model="getPublishSelection.replug.campaign"
            >
              <option value="">Select Campaign</option>
              <option
                :value="item"
                v-for="item in getPublishSelection.replug.brand.campaign"
              >
                {{ item.name
                }}<template v-if="item.cta_type"
                  >( {{ item.cta_type }} )</template
                >
              </option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>

        <p class="text-center mt-3" v-else>
          You do not have any Replug campaign created.
        </p>
      </template>
    </div>

    <p
      class="no_text_li"
      :class="{ 'text-center': textAlign, 'mt-3': nomargin }"
      v-else
    >
      You have not connected replug yet.
      <button
        class="btn btn-studio-theme-space btn-size-small ml-2 mt-2"
        :disabled="!replugLinkVisibilityStatus"
        @click.prevent="connectAccount('replug', 'connect')"
        >Connect Account</button
      >
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { redirectConnectorRequest } from '@common/lib/integrations'
export default {
  props: ['type', 'nomargin', 'textAlign'],
  components: {},

  data () {
    return {
      bitly_link_visibility: true,
      replug_link_visibility: true
    }
  },

  created () {},

  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getFetchIntegrationsAccountsLoader',
      'getReplug',
      'getIntegrationAuthorization',
      'getWorkspaces'
    ]),
    replugLinkVisibilityStatus () {
      return (
        this.replug_link_visibility && this.getIntegrationAuthorization.replug
      )
    }
  },
  methods: {
    ...mapActions(['setSharingReplugCampaign']),
    connectAccount (type, process, connector_id) {
      let link = ''
      const details = { type: type, process: process }
      switch (type) {
        case 'bitly':
          link = this.getIntegrationAuthorization.bitly
          this.bitly_link_visibility = false
          break
        case 'replug':
          link = this.getIntegrationAuthorization.replug
          this.replug_link_visibility = false
          break
      }
      if (typeof connector_id !== 'undefined') {
        details.connector_id = connector_id
      }
      redirectConnectorRequest(
        details,
        link,
        this.getWorkspaces.activeWorkspace
      )
    }
  },

  watch: {}
}
</script>
