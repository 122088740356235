export default {
  state: {
    file: {
      activeTab: 'local',
      progressBar: 0,
      filesCount: 0,
      localUploads: {
        fetchStatus: false,
        paginationScroll: false,
        items: [],
        selectedItems: [],
        page: 1,
        limit: 20,
        total: 0,
        filters: {
          type: 'All Types',
          sort: 'Most recent uploads',
          search: ''
        }
      }
    },
    addMediaWidget: {
      videoType: null,
      videoTypeLoader: null,
      mediaLink: null
    },
    canvaApi: null,
    crelloApi: 'contentstudiokeyd1d4jf1d6sg'
  },
  actions: {
    setFileWidgetActiveTab ({ commit }, activeTab) {
      commit('setFileWidgetActiveTab', activeTab)
    },

    setWidgetFileProgressBar ({ commit }, percent) {
      commit('setWidgetFileProgressBar', percent)
    },

    setWidgetUploadingFilesCount ({ commit }, count) {
      commit('setWidgetUploadingFilesCount', count)
    },

    setWidgetSelectedUploadedFilesItems ({ commit }, items) {
      commit('setWidgetSelectedUploadedFilesItems', items)
    },

    setWidgetLocalUploadsFetchStatus ({ commit }, status) {
      commit('setWidgetLocalUploadsFetchStatus', status)
    },

    setWidgetLocalUploadsPaginationScroll ({ commit }, status) {
      commit('setWidgetLocalUploadsPaginationScroll', status)
    },

    settWidgetUploadedFilesItems ({ commit }, items) {
      commit('settWidgetUploadedFilesItems', items)
    },

    settWidgetUploadedFilesTotal ({ commit }, count) {
      commit('settWidgetUploadedFilesTotal', count)
    },

    setWidgetUploadedFilesPage ({ commit }, page) {
      commit('setWidgetUploadedFilesPage', page)
    },

    setAddMediaWidgetVideoType ({ commit }, type) {
      commit('setAddMediaWidgetVideoType', type)
    },

    setAddMediaWidgetVideoTypeLoader ({ commit }, type) {
      commit('setAddMediaWidgetVideoTypeLoader', type)
    },

    setAddMediaWidgetVideoTypeLink ({ commit }, link) {
      commit('setAddMediaWidgetVideoTypeLink', link)
    },

    setWidgetUploadedFilesSearch ({ commit }, search) {
      commit('setWidgetUploadedFilesSearch', search)
    },

    setWidgetUploadedFilesTypeFilter ({ commit }, type) {
      commit('setWidgetUploadedFilesTypeFilter', type)
    },

    setWidgetUploadedFilesSortFilter ({ commit }, sort) {
      commit('setWidgetUploadedFilesSortFilter', sort)
    },

    resetFileWidget ({ commit }) {
      commit('resetFileWidget')
    },
    resetLocalUploads ({ commit }) {
      commit('resetLocalUploads')
    },
    resetAddMediaWidget ({ commit }) {
      commit('resetAddMediaWidget')
    }
  },
  mutations: {
    setFileWidgetActiveTab (state, activeTab) {
      state.file.activeTab = activeTab
    },

    setWidgetFileProgressBar (state, percent) {
      state.file.progressBar = percent
    },

    setWidgetUploadingFilesCount (state, count) {
      state.file.filesCount = count
    },

    setWidgetSelectedUploadedFilesItems (state, items) {
      state.file.localUploads.selectedItems = items
    },

    setWidgetLocalUploadsFetchStatus (state, status) {
      state.file.localUploads.fetchStatus = status
    },

    setWidgetLocalUploadsPaginationScroll (state, status) {
      state.file.localUploads.paginationScroll = status
    },

    settWidgetUploadedFilesItems (state, items) {
      state.file.localUploads.items = items
    },

    settWidgetUploadedFilesTotal (state, count) {
      state.file.localUploads.total = count
    },

    setWidgetUploadedFilesPage (state, page) {
      state.file.localUploads.page = page
    },

    setAddMediaWidgetVideoType (state, type) {
      state.addMediaWidget.videoType = type
    },

    setAddMediaWidgetVideoTypeLoader (state, status) {
      state.addMediaWidget.videoTypeLoader = status
    },

    setAddMediaWidgetVideoTypeLink (state, link) {
      state.addMediaWidget.mediaLink = link
    },

    setWidgetUploadedFilesTypeFilter (state, type) {
      state.file.localUploads.filters.type = type
    },
    setWidgetUploadedFilesSortFilter (state, sort) {
      state.file.localUploads.filters.sort = sort
    },
    setWidgetUploadedFilesSearch (state, search) {
      state.file.localUploads.filters.search = search
    },

    resetAddMediaWidget (state) {
      state.addMediaWidget = {
        videoType: null,
        videoTypeLoader: null,
        mediaLink: null
      }
    },

    resetLocalUploads (state) {
      state.file.localUploads = {
        fetchStatus: false,
        paginationScroll: false,
        items: [],
        selectedItems: [],

        page: 1,
        limit: 20,
        total: 0,
        filters: {
          type: 'All Types',
          sort: 'Most recent uploads',
          search: ''
        }
      }
    },

    resetFileWidget (state) {
      state.file = {
        activeTabIndex: 'local',
        progressBar: 0,
        filesCount: 0,
        localUploads: {
          fetchStatus: false,
          paginationScroll: false,
          items: [],
          selectedItems: [],

          page: 1,
          limit: 20,
          total: 0,
          filters: {
            type: 'All Types',
            sort: 'Most recent uploads',
            search: ''
          }
        }
      }
    },

    SET_CANVA_API (state, api) {
      state.canvaApi = api
    }
  },
  getters: {
    getFileWidget: (state) => {
      return state.file
    },

    getAddMediaWidgetVideoType: (state) => {
      return state.addMediaWidget.videoType
    },
    getAddMediaWidgetVideoTypeLoader: (state) => {
      return state.addMediaWidget.videoTypeLoader
    },
    getAddMediaWidgetVideoTypeLink: (state) => {
      return state.addMediaWidget.mediaLink
    },
    getCanvaApi: (state) => state.canvaApi,
    getCrelloApi: (state) => state.crelloApi
  }
}
