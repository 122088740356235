<template>
  <div
      class="input_field mt-0"
      :class="{ 'input-field-light-error': social_accounts_validation }"
  >
    <template v-if="isWorkspaceOnHold">
      <div class="input_field">
        <div class="warning_box warning-alert">
          <p v-if="hasPermission('can_change_hold_status')"
          ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because you've paused publishing for this workspace,
            <router-link :to="{ name: 'workspaces' }" class="ml-2"
            >Click here to resume publishing
            </router-link>
          </p>
          <p v-if="!hasPermission('can_change_hold_status')"
          ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because the admin of this workspace has paused publishing for this
            workspace.
          </p>
        </div>
      </div>
    </template>

    <template v-if="!isWorkspaceOnHold">
      <template v-if="checkAccountDisable(type, false)">
        <template
            v-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'published'
          "
        >
          <label>Social Post is posted on that platform</label>
        </template>
        <template
            v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'failed'
          "
        >
          <label>Social Post is failed on that platform</label>
        </template>
        <template
            v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'rejected'
          "
        >
          <label>Social Post is Rejected on that platform</label>
        </template>
        <template v-else-if="checkSocialMissedReview()">
          <label>Social Post is Missed Reviewed on that platform</label>
        </template>
      </template>
      <template v-else>
        <label v-if="showLabel"
        >Please select account(s) from social networks below</label
        >
      </template>

      <div class="social_accounts_list" v-if="selectedAccountList.length > 0">
        <template v-if="showAllAccounts">
          <template v-for="account in selectedAccountList">
            <div
                :key="account._id"
                class="checkbox_input_image checkbox_social_profile"
                style="position: relative"
            >

              <input
                  :id="'carousel_accounts' + account[account.channel.key]"
                  type="checkbox"
                  :value="account[account.channel.key]"
                  :disabled="checkAccountDisable(type) || getUserRole === 'Approver'"
                  v-model="getCarouselOptions.accounts"
              />

              <label
                  v-tooltip="{
                  content: channelTooltipHTML(account.channel.name, account),
                  classes: 'team_tooltip',
                }"
                  :for="'carousel_accounts' + account[account.channel.key]"
                  class="checkbox_right"
                  data-cy="account-selection"
              >
                <individual-channel-tooltip
                    :account="account"
                    :type="account.channel.name"
                ></individual-channel-tooltip>
              </label>
            </div>
          </template>

          <div
              class="selection_option"
              v-if="!checkAccountDisable(type) && selectedAccountList.length > 1 && getUserRole !== 'Approver'"
          >
            <span
                class="checkAllAccountSelection"
                @click="checkAllCarouselAccountSelection(true)"
            >Select all</span
            >
            |
            <span
                class="unCheckAllAccountSelection"
                @click="checkAllCarouselAccountSelection(false)"
            >None</span
            >
          </div>
        </template>

        <template v-if="!showAllAccounts">
          <div
              data-cy="posting-platform-selection"
              class="prevent_close_dropdown social_dropdown platform_filters_planner platform_filters_dd dropdown option_dropdown default_style_dropdown"
          >
            <div
                data-display="static"
                style="max-height: 37px"
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
            >
              <div class="text d-inline-block align-middle">
                <template v-if="accountSelectionCount < 1">
                  <i class="align-middle far fa-user icon_first"></i>
                  <span class="align-middle" data-cy="social-dropdown"
                  >Select Account(s)</span
                  >
                </template>
                <!-- for the blog channels tooltip -->
                <div class="circle_boxes_inline">
                  <!-- for the social channels tooltip -->
                  <template v-for="(account, i) in selectedAccountList">
                    <span
                        :key="'selected-account-item_' + i"
                        v-tooltip="{
                        content: channelTooltipHTML(
                          account.channel.name,
                          account
                        ),
                        classes: 'team_tooltip',
                      }"
                        v-if="filterPlatformVisibleStatus(account)"
                    >
                      <individual-channel-tooltip
                          :account="account"
                          :type="account.channel.name"
                          :flex_class="'d-flex align-items-start'"
                      ></individual-channel-tooltip>
                    </span>
                  </template>

                  <div
                      v-if="accountSelectionCount > 5"
                      class="profile_picture d-flex align-items-start"
                  >
                    <div class="picture_block picture_block_background">
                      <div class="img text"
                      >+{{ accountSelectionCount - 5 }}</div
                      >
                    </div>
                  </div>
                </div>
              </div>
              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>

            <div
                class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
            >
              <ul class="inner">
                <template v-if="filteredplatformsCount > 0 || search !== ''">
                  <div class="search-bar-input m-3">
                    <div class="search-bar-inner">
                      <input
                          class="full_label"
                          id="carousel_accounts_selection_search"
                          type="text"
                          placeholder="Search by name"
                          v-model="search"
                      />
                      <div class="search_btn">
                        <i class="icon-Search"></i>
                      </div>

                      <button
                          v-if="search !== ''"
                          @click="clearInput()"
                          class="clear-search-input"
                      >
                        <i class="cs-cross"></i>
                      </button>
                    </div>
                  </div>

                  <hr />

                  <div class="select_all" v-if="search === '' && getUserRole !== 'Approver'">
                    <div class="search_inner">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label
                              for="carousel_check_all_accounts"
                              class="checkbox_right"
                          >
                            <span class="checkAllAccountSelection"
                            >Select All</span
                            >
                            <span class="unCheckAllAccountSelection"></span>
                            <input
                                data-cy="carousel_check_all_accounts"
                                id="carousel_check_all_accounts"
                                type="checkbox"
                                v-model="check_all"
                                @change="
                                checkAllCarouselAccountSelection(check_all)
                              "
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(account, key) in selectedAccountList">
                  <li
                      :data-key="key + '_' + account.channel.name"
                      :key="key + '_' + account.channel.name"
                      class="list_item_li"
                      v-if="showPlatformSelection(account)"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <div>
                          <label
                              :for="'account_selection_carousel' +account[account.channel.key]"
                              class="checkbox_right"
                              data-cy="select-account"
                          >
                            <input
                                :data-cy="'account_selection_carousel' +account[account.channel.key]"
                                :value="account[account.channel.key]"
                                :id="'account_selection_carousel' +account[account.channel.key]"
                                type="checkbox"
                                v-model="getCarouselOptions.accounts"
                                :disabled="getUserRole === 'Approver'"
                            />
                            <span class="check"></span>
                            <individual-channel-dropdown
                                :account="account"
                                :type="account.channel.name"
                                :account_name="platformText(account)"
                                :account_type="account.type"
                                :integration="'social'"
                            ></individual-channel-dropdown>
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>

                <li class="p-3 text-center" v-if="filteredplatformsCount === 0">
                  <p v-if="search"
                  >No results found for your search query, please try again</p
                  >
                  <div v-else>
                    <p class="mb-3 mt-2"
                    >You have not connected any account yet.</p
                    >
                    <template v-if="hasPermission('can_save_social')">
                      <router-link
                          :to="{ name: 'social' }"
                          class="btn btn-studio-theme-space"
                      >
                        <span>Connect an account</span>
                      </router-link>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <span> You have not selected any facebook page yet.</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { socialChannelsArray } from '@common/constants/common-attributes'
import { getPlatformName } from '@common/lib/integrations'
import { commonMethods } from '@common/store/common-methods'

export default {
  props: {
    showLabel: {},
    type: {},
    social_accounts_validation: {
      default: false
    },
    blog: {
      default: false
    }
  },

  data () {
    return {
      search: '',
      check_all: false,
      social_channels_list: socialChannelsArray,
      commonMethods: commonMethods
    }
  },

  created () {},

  mounted () {},

  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getBlogPostingDetails',
      'getPublishSelection',
      'getWorkspaces',
      'getCarouselOptions'
    ]),

    selectedAccountIds () {
      const ids = []
      this.selectedAccountList.forEach((account) => {
        ids.push(account[account.channel.key])
      })
      return ids
    },

    selectedAccountList () {
      const accountList = []
      if (this.accountSelection.length) {
        this.social_channels_list.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if ((channel.name === 'facebook' && account.type === 'Page' && this.accountSelection.includes(account[channel.key]))) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key }
              })
            }
          })
        })
      }
      return accountList
    },

    accountSelection () {
      return this.getAccountSelection.facebook
    },

    showAllAccounts () {
      return this.selectedAccountList.length <= 3
    },

    filteredplatformsCount () {
      let platformsCount = 0
      this.social_channels_list.forEach((item) => {
        const getter = this.$store.getters[item.getter]
        const itemsList = getter.hasOwnProperty('items') ? getter.items : getter
        platformsCount += itemsList.filter((account) =>
            this.showPlatformSelection(account)
        ).length
      })
      return platformsCount
    },

    accountSelectionCount () {
      return this.selectedAccountList.length
    },

    isWorkspaceOnHold () {
      return !!(
          this.getWorkspaces.activeWorkspace &&
          this.getWorkspaces.activeWorkspace.on_hold
      )
    }
  },

  methods: {
    ...mapActions([
      'setCarouselOptionsAccounts',
    ]),
    /** Select all button for the social channels.
     *
     * @param status boolean
     */
    checkAllCarouselAccountSelection (status) {
      console.debug('Method:checkAllCarouselAccountSelection')
      if (status) {
        const accountsToSelect = []
        this.selectedAccountList.forEach((account) => {
          accountsToSelect.push(account[account.channel.key])
        })
        this.setCarouselOptionsAccounts(accountsToSelect)
      } else {
        this.setCarouselOptionsAccounts([])
      }
    },

    GoToSocial () {
      this.$router.push({ name: 'social' })
      $('.modal').modal('hide')
    },

    checkAccountDisable (type, message = true) {
      console.debug(type, message)
      console.debug('checkAccountDisable', this.getBlogPostingDetails.type)
      // type && type == 'composer' &&
      if (
          (this.getBlogPostingDetails.type == 'Composer Article' ||
              this.getBlogPostingDetails.type == 'Discovery Article' ||
              this.getBlogPostingDetails.type == 'Automation Article' ||
              this.getBlogPostingDetails.type == 'Discovery Video' ||
              this.getBlogPostingDetails.type == 'Automation Video') &&
          this.getPublishSelection.socialPlansDetail &&
          this.getPublishSelection.socialPlansDetail.length > 0 &&
          (this.getPublishSelection.socialPlansDetail[0].status === 'published' ||
              this.getPublishSelection.socialPlansDetail[0].status === 'failed' ||
              this.getPublishSelection.socialPlansDetail[0].status === 'rejected' ||
              (this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
                  this.getPublishSelection.socialPlansDetail[0].execution_time &&
                  this.getPublishSelection.socialPlansDetail[0].execution_time.date <
                  moment.utc().format('YYYY-MM-DD HH:mm:ss')))
      ) {
        return true
      } else if (
          type === 'socialComposer' &&
          message &&
          this.getPublishSelection.status == 'published'
      ) {
        return true
      } else if (message && this.getPublishSelection.queueStatus) {
        return true
      }
      return false
    },

    checkSocialMissedReview () {
      if (
          this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
          this.getPublishSelection.socialPlansDetail[0].execution_time.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return true
      }
      return false
    },

    platformText (platform) {
      console.debug('Method::platformText', platform)
      return getPlatformName(platform)
    },

    showPlatformSelection (account) {
      return (
          this.platformText(account)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
      )
    },

    clearInput () {
      this.search = ''
    },

    filterPlatformVisibleStatus (platform) {
      let count = 0
      let status = false
      const accounts = this.$store.getters.getAccountSelection

      this.social_channels_list.forEach((channel) => {
        const getter = this.$store.getters[channel.getter]
        const channelItems = getter.hasOwnProperty('items')
            ? getter.items
            : getter

        if (!status) {
          channelItems.forEach((item) => {
            if (accounts[channel.name].includes(item[channel.key])) {
              count += 1
              if (platform[channel.key] === item[channel.key] && count < 6) {
                status = true
                return false
              }
            }
          })
        }
      })
      return status
    },

    showExpiredWarning (account) {
      return (
          account &&
          account.validity &&
          (account.validity === 'invalid' || account.validity === 'expired')
      )
    },
  },

  watch: {
    selectedAccountIds (newArray, oldArray) {
      console.debug('carouselAccountSelection', newArray, oldArray, this.getCarouselOptions.accounts)
      if (newArray.length > oldArray.length) {
        // Get Diff
        const newAccount = newArray.filter((x) => !oldArray.includes(x))
        this.setCarouselOptionsAccounts([
          ...this.getCarouselOptions.accounts,
          ...newAccount
        ])
      } else {
        let carouselAccounts = []
        if (this.getCarouselOptions.accounts && this.getCarouselOptions.accounts.length > 0 ) {
          console.debug('carouselAccountSelection > carouselAccounts', this.getCarouselOptions.accounts)
          carouselAccounts =
              this.getCarouselOptions.accounts.filter((accountId) => {
                    return newArray.includes(accountId)
                  })
        }
        this.setCarouselOptionsAccounts(carouselAccounts)
      }
    }
  }
}
</script>
