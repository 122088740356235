<template>
  <div ref="social-chart-container" class="h-chart social-chart">
    <highcharts :options="dataOptions" ref="pieChart"></highcharts>

    <small
      >Total Engagements from <b>{{ posts }}</b> Posts Sent</small
    >
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: 'SocialEngagement',
  data () {
    return {
      dataOptions: {}
    }
  },
  props: {
    colors: [],
    data: [],
    posts: 0,
    total_engagement: 0
  },

  mounted () {
    this.dataOptions = this.options
    this.initializeDataOptions()

    this.dataOptions.chart.height =
      this.$refs['social-chart-container'].offsetHeight
  },
  methods: {
    initializeDataOptions () {
      this.dataOptions = {
        chart: {
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        title: {
          verticalAlign: 'middle',
          floating: true,
          text: this.total_engagement
        },
        plotOptions: {
          pie: {
            innerSize: '55%',
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: false
          }
        },
        colors: this.colors,
        series: [
          {
            data: this.data
          }
        ]
      }
    }
  }
}
</script>
