<template>
  <div class="compare-box">
    <div class="compare-box__header flex">
      <div class="compare-box__platform">
        <i class="fab fa-facebook-f"></i>
      </div>
      {{ ranking.title }}
      <div class="ml-auto position-relative">
        <i
          class="fa fa-info info_hover"
          v-b-popover.hover.html.topleft="ranking.description"
        ></i>
      </div>
    </div>

    <div class="compare-box__ranking">
      <div
        v-if="competitor.isUser"
        class="compare-box__ranking-cs our-color"
        v-for="(competitor, i) in ranking.competitors"
        :key="i"
      >
        <div class="compare-box__ranking-competitor__ranking-c-content">
          <img
            class="compare-box__ranking-logo"
            :src="competitor.logo"
            alt=""
          />
          <div class="compare-box__ranking-label">
            <div>{{ competitor.name }}</div>
          </div>
        </div>
        <div class="compare-box__ranking-value align-items-center d-flex mr-2">
          <p>{{ competitor.value }}</p>
        </div>
      </div>
      <div
        v-if="ranking.competitors.length <= 0"
        class="opacity-70"
        style="display: flex; justify-content: center"
      >
        <clip-loader
          class="ml-2"
          :color="'#5773fa'"
          :size="'20px'"
        ></clip-loader>
      </div>
      <div
        v-if="!competitor.isUser"
        class="compare-box__ranking-competitor their-color"
        v-for="(competitor, i) in ranking.competitors"
        :key="i"
      >
        <div class="compare-box__ranking-competitor__ranking-c-content">
          <img
            class="compare-box__ranking-logo"
            :src="competitor.logo"
            alt=""
          />
          <div class="compare-box__ranking-label">
            <div>{{ competitor.name }}</div>
          </div>
        </div>
        <div class="compare-box__ranking-value mr-2">
          <p>{{ competitor.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.our-color {
  background: #3797f314;
  padding: 5px;
  border-radius: 8px;
}

.their-color {
  background: #0000000b;
  padding: 5px;
  border-radius: 8px;
}
</style>
<script>
export default {
  name: 'CompareBox',
  props: ['ranking']
}
</script>
