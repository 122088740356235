<template>
  <div
    class="px-3 py-6 !border-l-0 !border-t-0 !border-b-0 border !border-[#EAF0F6] relative"
  >
    <div>
      <div class="text-base mb-6 px-0.5 font-weight-500">
        Social Analytics
      </div>

      <nav class="">
        <router-link :to="{ name: 'analytics_overview_v3' }">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('analytics_overview_v3')
                ? 'bg-gray-100 !font-medium !text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <div
              class="h-8 w-8 bg-[#E6F0FC] rounded-md inline-flex justify-center items-center mr-4"
            >
              <img
                src="@assets/img/analytics/sidebar/grid.svg"
                alt="Grid"
                class="w-6 h-6"
              />
            </div>
            <span class="group-hover:text-black-900"> Overview </span>
          </div>
        </router-link>

        <router-link :to="facebookPathAndId()">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('facebook_analytics_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <i
              class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"
            ></i>
            <span class="group-hover:text-black-900"> Facebook </span>
          </div>
        </router-link>

        <router-link :to="instagramPathAndId()">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('instagram_analytics_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <i
              class="h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"
            ></i>
            <span class="group-hover:text-black-900"> Instagram </span>
          </div>
        </router-link>

        <router-link :to="twitterPathAndId()">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('twitter_analytics_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <i
              class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-twitter inline-flex justify-center items-center text-[#00AAFB] text-xl mr-4"
            ></i>
            <span class="group-hover:text-black-900"> Twitter </span>
          </div>
        </router-link>

        <router-link :to="linkedInPathAndId()">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('linkedin_analytics_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <i
              class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-linkedin-in inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"
            ></i>
            <span class="group-hover:text-black-900"> Linkedin </span>
          </div>
        </router-link>

        <!--        TODO:: Pinterest will be used in the future.-->
        <!--        <router-link :to="pinterestPathAndId()">-->
        <!--          <div-->
        <!--            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"-->
        <!--            :class="-->
        <!--              $route.name.includes('pinterest_analytics_v3')-->
        <!--                ? 'bg-gray-100 font-medium text-[#000D21]'-->
        <!--                : 'text-[#69788E]'-->
        <!--            "-->
        <!--          >-->
        <!--            <i-->
        <!--              class="h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-pinterest inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"-->
        <!--            ></i>-->
        <!--            <span class="group-hover:text-black-900">-->
        <!--              Pinterest-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </router-link>-->
      </nav>

      <div class="text-base mb-6 mt-7 font-weight-500">
        Competitor Analytics
      </div>

      <nav class="">
        <router-link :to="{ name: 'facebook_competitor_v3' }">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('facebook_competitor_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <i
              class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"
            ></i>
            <span class="group-hover:text-black-900"> Facebook </span>
          </div>
        </router-link>

        <router-link v-if="featureFlagAffiliate()" :to="{ name: 'instagram_competitor_overview_v3' }">
          <div
            class="flex items-center mb-1.5 justify-between hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('instagram_competitor')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <span class="flex justify-center items-center">
              <i
                class="h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"
              ></i>
              <span class="group-hover:text-black-900">Instagram</span>
            </span>

            <span
              class="h-5 px-2 bg-[#FFC555] rounded-full text-[#000D21] flex items-center justify-center"
            >
              NEW
            </span>
          </div>
        </router-link>
      </nav>

      <div class="text-base mb-6 mt-7 font-weight-500"> Manage Reports </div>

      <nav class="">
        <router-link :to="{ name: 'my_report_v3' }">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('my_report_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <div
              class="h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"
            >
              <img
                src="@assets/img/analytics/sidebar/scheduled_report.svg"
                alt="Grid"
                class="w-6 h-6"
              />
            </div>
            <span class="group-hover:text-black-900"> Scheduled Reports </span>
          </div>
        </router-link>

        <router-link :to="{ name: 'download_reports_v3' }">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('download_reports_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <div
              class="h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"
            >
              <img
                src="@assets/img/analytics/sidebar/download_reports.svg"
                alt="Grid"
                class="w-6 h-6"
              />
            </div>
            <span class="group-hover:text-black-900"> Download Reports </span>
          </div>
        </router-link>

        <router-link :to="{ name: 'reports_setting_v3' }">
          <div
            class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
            :class="
              $route.name.includes('reports_setting_v3')
                ? 'bg-gray-100 font-medium text-[#000D21]'
                : 'text-[#69788E]'
            "
          >
            <div
              class="h-8 w-8 bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4"
            >
              <img
                src="@assets/img/analytics/sidebar/setting_reports.svg"
                alt="Grid"
                class="w-6 h-6"
              />
            </div>
            <span class="group-hover:text-black-900"> Reports Settings </span>
          </div>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

// constants
import { useStore } from '@state/base'

export default defineComponent({
  name: 'Sidebar',
  setup() {
    const store = useStore()

    /**
     * @description creates the payload for router link of Instagram. finds and passes the id as param if available
     * @returns {{name: string}}
     */
    const instagramPathAndId = () => {
      let payload = { name: 'instagram_analytics_v3' }
      if (store.getters.getInstagramAccounts.items.length) {
        payload = {
          name: 'instagram_analytics_v3',
          params: {
            accountId:
              store.getters.getInstagramAccounts?.items[0]?.instagram_id,
          },
        }
      }
      return payload
    }

    /**
     * @description creates the payload for router link of Facebook. finds and passes the id as param if available
     * @returns {{name: string}}
     */
    const facebookPathAndId = () => {
      let payload = { name: 'facebook_analytics_v3' }
      if (store.getters.getFacebookPagesList.length) {
        payload = {
          name: 'facebook_analytics_v3',
          params: {
            accountId: store.getters.getFacebookPagesList[0]?.facebook_id,
          },
        }
      }
      return payload
    }

    /**
     * @description creates the payload for router link of Twitter. finds and passes the id as param if available
     * @returns {{name: string}}
     */
    const twitterPathAndId = () => {
      let payload = { name: 'twitter_analytics_v3' }
      if (store.getters.getTwitterAccounts.items.length) {
        payload = {
          name: 'twitter_analytics_v3',
          params: {
            accountId: store.getters.getTwitterAccounts?.items[0]?.twitter_id,
          },
        }
      }
      return payload
    }

    /**
     * @description creates the payload for router link of LinkedIn. finds and passes the id as param if available
     * @returns {{name: string}}
     */
    const linkedInPathAndId = () => {
      let payload = { name: 'linkedin_analytics_v3' }
      if (store.getters.getLinkedinCompanyPages.length) {
        payload = {
          name: 'linkedin_analytics_v3',
          params: {
            accountId: store.getters.getLinkedinCompanyPages[0]?.linkedin_id,
          },
        }
      }
      return payload
    }

    /**
     * @description creates the payload for router link of Pinterest. finds and passes the id as param if available
     * @returns {{name: string}}
     */
    const pinterestPathAndId = () => {
      let payload = { name: 'pinterest_analytics_v3' }
      if (store.getters.getPinterestBoards.length) {
        payload = {
          name: 'pinterest_analytics_v3',
          params: {
            accountId: store.getters.getPinterestBoards[0]?.board_id,
          },
        }
      }
      return payload
    }

    return {
      instagramPathAndId,
      facebookPathAndId,
      twitterPathAndId,
      linkedInPathAndId,
      pinterestPathAndId,
    }
  },
})
</script>

<style scoped></style>
