import { mapActions, mapGetters, mapMutations } from 'vuex'
import { composer } from '../../composer/store/composer-mutation-type'

/* import {
  getFavoriteIdsURL,
  updateFolderURL
} from './../../config/urls/feed/article/favorite' */

import {
  getFoldersURL,
  setFavoriteURL,
  unsetFavoriteURL,
  saveFolderURL,
  deleteFolderURL,
  updateFolderURL
} from '../config/api-utils'
import {
  FOLDER_ADDED,
  FOLDER_FAILED,
  FOLDER_UPDATED,
  POST_FAVORITE_ADDED,
  POST_FAVORITE_REMOVED
} from '@common/constants/messages'
import { topicTypes } from '../store/mutation-types'

const favoriteMixin = {
  computed: {
    ...mapGetters([
      'getFilterFolderId',
      'getFolders',
      'getCustomSidebar',
      'getAllFavoriteIds',
      'getProfile',
      'getPosts',
      'getContentPreview',
      'getWorkspaces',
      'getTopicType',
      'getTopicPosts',
      'getTopic',
      'getPostsTotal',
      'getDiscoverFetchStatus'
    ])
  },
  methods: {
    ...mapMutations([topicTypes.SET_POSTS_TOTAL]),
    ...mapActions([
      'setAddFolderBox',
      'setFolders',
      'setAddFolderBox',
      'setAllFavoriteIds',
      'setTrendingStatus',
      'setFilterFolderId',
      'setArticlesSort',
      'setArticlesPage',
      'setFilterArchived',
      'setRelatedStatus',
      'setTopic',
      'setSearch',
      'setQuotesStatus',
      'setDomain',
      'setPosts',
      'setFolderText',
      'setSearchType',
      'setCurationFavorite'
    ]),

    getFavoriteFolders (curation = null) {
      console.debug('Method:getFavoriteFolders')
      if (this.getDiscoverFetchStatus === true) {
        return
      }
      this.postRequest(
        getFoldersURL,
        {
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },
        (response) => {
          const getFolderList = response.data.folders
          if (getFolderList) {
            for (let i = 0; i < response.data.folders.length; i++) {
              getFolderList[i].edit_state = false
              getFolderList[i].newName = getFolderList[i].name
            }
            this.setFolders(getFolderList)
            if (curation) {
              if (this.getFolders.length > 0) {
                // TODO: commented it for now...need to discuss with @umair
                // this.selectFavoriteTopic({ 'id': this.getFolders[0]['_id']._id, 'name': this.getFolders[0]['name'] })
                this.$store.commit(
                  composer.SET_CURATION_FAVORITE_NAME,
                  this.getFolders[0].name
                )
                this.fetchFavoritePosts(this.getFolders[0])
              } else {
                this.$store.commit(
                  composer.SET_CURATION_FAVORITE_NAME,
                  'Select Folder'
                )
              }
            }
          }
          /* if (!this.getCustomSidebar.customized) {
              let currObject = this
              $(getFolderList).each(function (index, el) {
                currObject.getCustomSidebar.favorites.options.push(el._id)
              })
            } */
        },
        (response) => {
          console.debug('Exception in getFavoriteFolders', response)
        }
      )
    },
    async fetchFavoritePosts (folder) {
      this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
        topic: folder,
        type: 'favorite'
      })
      await this.topicsContent()
      this.checkComposerDragDrop()
    },

    setFavorite (folderId, postId, folderName, index) {
      console.debug('Method:setFavorite')

      this.postRequest(
        setFavoriteURL,
        {
          folder_id: folderId,
          post_id: postId,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },
        (response) => {
          if (response.data.status === true) {
            if (this.getPosts[index]) {
              this.getPosts[index]._source.is_favorite = true
            }
            this.getContentPreview._source.is_favorite = true
            setTimeout(() => {
              if (this.getPosts[index]) {
                this.getPosts[index]._source.is_active = true
              }
              this.getContentPreview._source.is_active = true
            }, 50)
            // @umair: change the status of the article.
            // this.getAllFavoriteIds.push(post_id)
            // @umair: set Favorite ID added.
            // this.alertMessage('Post added to ' + folderName + ' successfully!', 'success')
          } else {
            this.alertMessage('Post favorite fail', 'error')
          }
        },
        (response) => {
          console.debug('Exception in setFavorite', response)
        }
      )
    },

    removeFavorite (postId, index) {
      console.debug('Method:removeFavorite')
      this.postRequest(
        unsetFavoriteURL,
        {
          id: postId,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },
        (response) => {
          if (response.data.status == true) {
            // if the user is on the favorite page on the topics page, we are removing the item from the list to
            // make a better user experience.
            if (this.getPosts[index]) {
              this.getPosts[index]._source.is_favorite = false
              this.getPosts[index]._source.is_active = false
            }
            try {
              this.getContentPreview._source.is_favorite = false
              this.getContentPreview._source.is_active = false
              this.changeContentPreviewStatus(postId, false)
            } catch (m) {
              console.log(m)
            }
            if (this.getTopicType == 'favorite') {
              this.getPosts.splice(index, 1)
              this.SET_POSTS_TOTAL(this.getPostsTotal - 1)
            }
            // update the item to is_favorite false.
            console.log(this.getTopicType)
            // this.alertMessage(POST_FAVORITE_REMOVED, 'success')
          } else {
            this.alertMessage(
              'An error has occurred, Please try again',
              'error'
            )
          }
        },
        (response) => {
          console.debug('Exception in removeFavorite', response)
        }
      )
    },
    // folder crud operations

    saveFolder (name) {
      console.debug('Method:saveFolder')
      if (name) name = name.trim()
      if (name && name.length > 0) {
        this.postRequest(
          saveFolderURL,
          {
            name: name,
            workspace_id: this.getWorkspaces.activeWorkspace._id
          },
          (response) => {
            if (response.data.status == true) {
              // @umair: add this one the topics section // this.getCustomSidebar.favorites.options.push(response.data.added_folder)

              const getFolderList = response.data.folders
              for (let i = 0; i < response.data.folders.length; i++) {
                getFolderList[i].edit_state = false
                getFolderList[i].newName = getFolderList[i].name
              }
              // this.$store.dispatch('trackEvent', { event: 'favorite_folders_created' })
              this.setFolders(getFolderList)
              this.setAddFolderBox(false)
              this.folderName = ''
              this.alertMessage(FOLDER_ADDED, 'success')
            } else {
              this.alertMessage(FOLDER_FAILED, 'error')
            }
          },
          (response) => {
            console.debug('Exception in saveFolder', response)
          }
        )
      }
    },

    removeFolder (folderId, folderName, index) {
      console.debug('Method:removeFolder')
      this.getFolders.splice(index, 1)
      const self = this

      // @umair: sweetalert confirmation from arif.
      /* swal({
        title: 'Are you sure ?',
        type: 'warning',
        text: "You won't be able to undo. ",
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel!',
        closeOnConfirm: true,
        customClass: 'sweetAlert_box',
        closeOnCancel: true,
        animation: false
      },
      function (isConfirm) {
        if (isConfirm) { */
      self.postRequest(
        deleteFolderURL,
        {
          folder_id: folderId,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },
        (response) => {
          if (response.data.status === true) {
            self.alertMessage(
              'Your ' + folderName + ' folder has been removed successfully!',
              'success'
            )
            if (response.data.ids.length > 0) {
              this.getPosts.forEach(function (element) {
                if (response.data.ids.indexOf(element._source.id) != -1) {
                  element._source.is_favorite = false
                  element._source.is_active = false
                }
              })
            }
            if (this.getTopic === folderId) {
              this.defaultTopicOnView()
            }

            /* let getFolderList = response.data.folders
            for (let i = 0; i < response.data.folders.length; i++) {
              getFolderList[i].edit_state = false
              getFolderList[i].newName = getFolderList[i].name
            }
            self.setFolders(getFolderList) */
            self.setAllFavoriteIds(response.data.ids)

            if (self.getFilterFolderId === folderId) {
              self.setTrendingStatus(true)
              self.setFilterFolderId(null)
              self.setArticlesSort('Trending')
              self.setArticlesPage(1)
              self.getArticles()
            }
          } else {
            self.alertMessage('An error occurred, Please try again', 'error')
          }
        },
        (response) => {
          console.debug('Exception in removeFolder', response)
        }
      )
      /* } else {
          swal()
        }
      }) */
    },
    /**
     * Change the content preview status to true or false for favorite option in the preview content section.
     * @param postId Post id of the content
     * @param status true or false.
     */
    changeContentPreviewStatus (postId, status) {
      console.log(postId, status)
      if (
        this.getContentPreview._source &&
        this.getContentPreview._source.id == postId
      ) {
        this.getContentPreview._source.is_favorite = status
        this.getContentPreview._source.is_active = status
      }
    },

    editFolder (folder) {
      folder.edit_state = true
    },
    updateFolderKeyDown (event, folder) {
      console.debug('Method:updateFolderKeyDown')

      if (event.key == 'Enter') {
        if (folder.newName.trim().length > 0) {
          this.updateFolder(folder)
        }
      }
    },

    updateFolder (folder) {
      console.debug('Method:updateFolder')
      console.log(folder)
      this.postRequest(
        updateFolderURL,
        {
          folder_id: folder._id,
          name: folder.newName,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },

        (response) => {
          if (response.data.status == true) {
            folder.edit_state = false
            folder.name = folder.newName
            this.alertMessage(FOLDER_UPDATED, 'success')
          } else {
            this.alertMessage('An Error occurred, Please try again', 'error')
          }
        },
        (response) => {
          console.debug('Exception in updateFolder', response)
        }
      )
    }

    /* getCurationFavorite (id, name) {
      this.setFolderText(name)
      this.getFavorite(id)
    } */
  }
}

export { favoriteMixin }
