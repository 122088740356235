<template>
  <div class="info_box">
    <img src="../../../../../assets/img/analytics/data_fetching.gif" alt="" />
    <p>
      We are now fetching and processing data for
      <span>{{ name }}</span> profile. Please come back later, It can take from
      a few minutes to a couple of hours.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {}
  }
}
</script>

<style scoped></style>
