<template>
  <div>
    <template v-if="(isTopic || automation) && !getTrendingStories">
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown topics-filters-pane__container__right_section__dropdown"
        no-caret
      >
        <template slot="button-content">
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{ getTopicsSort }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item
          :active="getTopicsSort === 'Recent'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Recent')"
          >Recent
        </b-dropdown-item>
        <b-dropdown-item
          v-show="isShowTrendingFilter"
          :active="getTopicsSort === 'Trending'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Trending')"
          >Trending
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Most Shared'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Most Shared')"
          >Most Shared
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Facebook Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Facebook Shares')"
          >Facebook Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Twitter Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Twitter Shares')"
          >Twitter Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Pinterest Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Pinterest Shares')"
          >Pinterest Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Reddit Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Reddit Shares')"
          >Reddit Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Relevance'"
          variant="studio-theme"
          @click.prevent="changeSort('topics', 'Relevance')"
          >Relevance
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template v-else-if="!getTrendingStories">
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown topics-filters-pane__container__right_section__dropdown"
        no-caret
      >
        <template slot="button-content">
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{ getArticlesSort }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item
          :active="getTopicsSort === 'Recent'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Recent')"
          >Recent
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Most Shared'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Most Shared')"
          >Most Shared
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Facebook Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Facebook Shares')"
          >Facebook Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Twitter Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Twitter Shares')"
          >Twitter Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Pinterest Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Pinterest Shares')"
          >Pinterest Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Reddit Shares'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Reddit Shares')"
          >Reddit Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="getTopicsSort === 'Relevance'"
          variant="studio-theme"
          @click.prevent="changeSort('articles', 'Relevance')"
          >Relevance
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { topicTypes } from '../../store/mutation-types'
import moment from 'moment'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  methods: {
    ...mapActions([
      'getContent',
      'articles',
      'topicsContent',
      'articlesPreviewSuggestion'
    ]),
    ...mapMutations([
      topicTypes.RESET_TOPICS_FILTER,
      topicTypes.SET_TOPICS_SORT,
      topicTypes.SET_TOPICS_DATE_RANGE
    ]),
    changeSort: async function (type, value) {
      switch (type) {
        case 'articles':
          await this.getContent({ filter: { key: 'sort', value: value } })
          await this.articles()
          this.checkComposerDragDrop()
          break
        case 'topics':
          if (value.toLowerCase() === 'trending') {
            const rangeDefault = {
              label: 'Last 7 days',
              value:
                moment().subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss') +
                ' - ' +
                moment().format('YYYY-MM-DDTHH:mm:ss')
            }
            this[topicTypes.SET_TOPICS_DATE_RANGE](rangeDefault)
          } else {
            const rangeDefault = {
              label: 'Last 30 days',
              value:
                moment().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
                ' - ' +
                moment().format('YYYY-MM-DDTHH:mm:ss')
            }
            this[topicTypes.SET_TOPICS_DATE_RANGE](rangeDefault)
          }
          this[topicTypes.SET_TOPICS_SORT](value)
          this[topicTypes.RESET_TOPICS_FILTER]()
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        default:
          break
      }
    }
  },
  computed: {
    ...mapGetters([
      'getArticlesSort',
      'getTopicsSort',
      'getTrendingStories',
      'getFilters'
    ]),
    isShowTrendingFilter () {
      return (
        this.$route.params.module === 'curated' &&
        this.$route.params.topicId === 'N764025'
      )
    }
  }
}
</script>
