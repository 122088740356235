var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dropdown_header d-flex align-items-center max-h-9",attrs:{"data-display":"static","data-toggle":"dropdown"}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Design image with Canva'),expression:"'Design image with Canva'"}],staticClass:"canva_icon canva_width"},[_c('img',{attrs:{"src":require("../../../../assets/img/canva.svg")},on:{"click":function($event){return _vm.scrolltotop()}}})])]),_c('div',{staticClass:"dropdown-menu show_canva_options"},[_c('ul',{ref:"canvaList",staticClass:"inner"},[_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'SocialMedia')}}},[_vm._m(0)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'FacebookPost')}}},[_vm._m(1)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'TwitterPost')}}},[_vm._m(2)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'InstagramPost')}}},[_vm._m(3)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'InstagramStory')}}},[_vm._m(4)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCanvaDesign(_vm.type, 'YouTubeThumbnail')}}},[_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("SocialMedia")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("FacebookPost")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("TwitterPost")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("InstagramPost")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("InstagramStory")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("YouTubeThumbnail")])])
}]

export { render, staticRenderFns }