export const evergreen = {
  SET_EVERGREEN_POSTS: 'SET_EVERGREEN_POSTS',
  SET_EVERGREEN_BULK_FILENAME: 'SET_EVERGREEN_BULK_FILENAME',
  SET_EVERGREEN_BULK_LOADER: 'SET_EVERGREEN_BULK_LOADER',
  SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS:
    'SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS',
  SET_EVERGREEN_BULK_TOTAL_POSTS: 'SET_EVERGREEN_BULK_TOTAL_POSTS',
  SET_EVERGREEN_ACTIVE_POST: 'SET_EVERGREEN_ACTIVE_POST',
  SET_EVERGREEN_AUTOMATION_LISTING_ITEM:
    'SET_EVERGREEN_AUTOMATION_LISTING_ITEM',
  SET_EVERGREEN_AUTOMATION_LISTING_PAGINATION:
    'SET_EVERGREEN_AUTOMATION_LISTING_PAGINATION',
  SET_EVERGREEN_SELECTED_AUTOMATION: 'SET_EVERGREEN_SELECTED_AUTOMATION',
  SET_EVERGREEN_SELECTION_DEFAULT_STATES:
    'SET_EVERGREEN_SELECTION_DEFAULT_STATES',
  SET_EVERGREEN_DEFAULT_STATES: 'SET_EVERGREEN_DEFAULT_STATES',
  SET_EVERGREEN_STATUS: 'SET_EVERGREEN_STATUS',
  SET_EVERGREEN_NAME: 'SET_EVERGREEN_NAME',
  SET_EVERGREEN_ID: 'SET_EVERGREEN_ID',
  SET_EVERGREEN_CYCLE_GAP: 'SET_EVERGREEN_CYCLE_GAP',
  SET_EVERGREEN_CYCLE_GAP_STATUS: 'SET_EVERGREEN_CYCLE_GAP_STATUS',
  SET_EVERGREEN_LISTING_SELECT_ALL: 'SET_EVERGREEN_LISTING_SELECT_ALL',
  SET_EVERGREEN_CREATE_LOADER: 'SET_EVERGREEN_CREATE_LOADER',
  SET_EVERGREEN_AUTOMATION_LISTING_PAGE:
    'SET_EVERGREEN_AUTOMATION_LISTING_PAGE',
  SET_EVERGREEN_AUTOMATION_TAB_STATUS: 'SET_EVERGREEN_AUTOMATION_TAB_STATUS',
  SET_EVERGREEN_ADD_STATE: 'SET_EVERGREEN_ADD_STATE',
  SET_EVERGREEN_POST_INDEX: 'SET_EVERGREEN_POST_INDEX',
  SET_EVERGREEN_VARIATION_INDEX: 'SET_EVERGREEN_VARIATION_INDEX',
  SET_FETCH_VARIATION_LOADER: 'SET_FETCH_VARIATION_LOADER',
  SET_VARIATION_INDEX: 'SET_VARIATION_INDEX',
  SET_VARIATION_URL: 'SET_VARIATION_URL',
  SET_EVERGREEN_VARIATION_PREVIEW_POSTS:
    'SET_EVERGREEN_VARIATION_PREVIEW_POSTS',
  SET_EVERGREEN_VARIATION_FROM_PREVIEW_LOADER:
    'SET_EVERGREEN_VARIATION_FROM_PREVIEW_LOADER',
  SET_EVERGREEN_AUTOMATION: 'SET_EVERGREEN_AUTOMATION',
  SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE:
    'SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE',
  SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME:
    'SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME',
}

export const bulkCSV = {
  SET_BULK_CSV_SELECTED_AUTOMATION: 'SET_BULK_CSV_SELECTED_AUTOMATION',
  SET_BULK_CSV_LISTING_SELECT_ALL: 'SET_BULK_CSV_LISTING_SELECT_ALL',
  SET_BULK_CSV_AUTOMATION_LISTING_PAGE: 'SET_BULK_CSV_AUTOMATION_LISTING_PAGE',
  SET_BULK_CSV_AUTOMATION_LISTING_PAGINATION:
    'SET_BULK_CSV_AUTOMATION_LISTING_PAGINATION',
  SET_BULK_CSV_AUTOMATION_LISTING_ITEM: 'SET_BULK_CSV_AUTOMATION_LISTING_ITEM',
  SET_APPROVED_LOADER: 'SET_APPROVED_LOADER',
}
