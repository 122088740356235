<template>
  <div class="analytics-posts-section">
    <section class="contains-filter-btn">
      <h2>Instagram Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post's lifetime performance.</p
      >
      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown mr-2 post-filter-btn dropdown-size-small"
        no-caret
        style="right: 12px; top: 25px"
      >
        <template slot="button-content">
          <i class="cs-world-fill studio-theme-icon"></i>
          <span class="capitalize_text">Top {{ limit }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item @click="limit = 5">Top 5</b-dropdown-item>
        <b-dropdown-item @click="limit = 10">Top 10</b-dropdown-item>
        <b-dropdown-item @click="limit = 15">Top 15</b-dropdown-item>
      </b-dropdown>

      <div>
        <div class="analytics-tabs-content">
          <div class="analytics-tab-header grid-flex">
<!--            <div class="cell-2 analytics-tab-header__item">-->
<!--              <span>Accounts</span>-->
<!--            </div>-->
<!--            <div-->
<!--              class="cell-2 analytics-tab-header__item justify_content_start"-->
<!--            >-->
<!--              <span>Posts Image</span>-->
<!--            </div>-->
            <div
              class="cell-8 cell-xl-4 cell-xxl-8 analytics-tab-header__item justify_content_start"
            >
              <span>Posts</span>
            </div>
            <div class="analytics-tab-header__item pr-0 cell-1"></div>
            <div
              class="analytics-tab-header__item pr-0 cell-1 cell-xl-1 cell-xxl-1"
            >
              <span>Engagements</span>
              <span class="analytics-tab-header__item-controls">
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.engagements.up ? 'active' : ''"
                  @click="changeSort('engagements', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.engagements.down ? 'active' : ''"
                  @click="changeSort('engagements', 'down')"
                ></i>
              </span>
            </div>
            <div class="analytics-tab-header__item pr-0 cell-1">
              <span>Likes</span>
              <span class="analytics-tab-header__item-controls">
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.likes.up ? 'active' : ''"
                  @click="changeSort('likes', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.likes.down ? 'active' : ''"
                  @click="changeSort('likes', 'down')"
                ></i>
              </span>
            </div>
            <div
              class="analytics-tab-header__item pr-0 cell-1 cell-xl-1 cell-xxl-1"
            >
              <span>Comments</span>
              <span class="analytics-tab-header__item-controls">
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.comments.up ? 'active' : ''"
                  @click="changeSort('comments', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.comments.down ? 'active' : ''"
                  @click="changeSort('comments', 'down')"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <template v-if="top_post_loader">
          <div class="grid-flex">
            <div class="analytics-tab-body__item cell-2">
              <div class="analytics-tab-body__item-profile-header">
                <div>
                  <SkeletonBox height="2.8rem" width="2.8rem" radius="50%" />
                </div>
                <div>
                  <div class="d-flex" style="flex-direction: column">
                    <div class="mb-1">
                      <SkeletonBox width="100px" radius=".2rem" />
                    </div>
                    <SkeletonBox width="130px" radius=".2rem" />
                  </div>
                </div>
              </div>
            </div>
            <div class="analytics-tab-body__item cell-2 justify_content_start">
              <div class="analytics-tab-body__item-image">
                <SkeletonBox width="100%" height="130px" radius=".2rem" />
              </div>
            </div>
            <div
              class="analytics-tab-body__item cell-4 cell-xl-2 cell-xxl-4 justify_content_start"
            >
              <div
                class="d-flex"
                style="flex-direction: column; margin-left: 1rem; width: 70%"
              >
                <div class="mb-1">
                  <SkeletonBox width="100%" radius=".2rem" />
                </div>
                <div class="mb-1">
                  <SkeletonBox width="90%" radius=".2rem" />
                </div>
                <SkeletonBox radius=".2rem" />
              </div>
            </div>
            <div
              class="analytics-tab-body__item py-0 cell-1 cell-xl-2 cell-xxl-1"
            >
              <SkeletonBox width="1.3rem" radius=".2rem" />
            </div>
            <div class="analytics-tab-body__item py-0 cell-1">
              <SkeletonBox width="1.3rem" radius=".2rem" />
            </div>
            <div
              v-if="isPostCommentsAvailable('instagram')"
              class="analytics-tab-body__item py-0 cell-1 cell-xl-2 cell-xxl-1"
            >
              <SkeletonBox width="1.3rem" radius=".2rem" />
            </div>
          </div>
        </template>
        <div v-else class="analytics-tab-body">
          <template v-if="top_posts.length <= 0">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p
                >You do not have any published posts in the selected time
                period.</p
              >
            </div>
          </template>
          <template v-else>
            <template v-for="(post, index) in top_posts">
              <div v-if="index < limit" :key="index">
                <template>
                  <SinglePostRow :type="'instagram'" :post="post" />
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SinglePostRow from '@src/modules/analytics/views/common/SinglePostRow'
import { mapActions } from 'vuex'
import * as _ from 'underscore'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'PostsTab',
  props: ['account', 'date', 'previous_date', 'getPostsLoader'],
  data () {
    return {
      limit: 5,
      top_post_loader: false,
      top_posts: [],
      existing_sort: 'engagements_up',
      sort_types: {
        engagements: {
          up: true,
          down: false
        },
        likes: {
          up: false,
          down: false
        },
        comments: {
          up: false,
          down: false
        }
      }
    }
  },
  components: {
    SinglePostRow,
    SkeletonBox
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    initialize () {
      this.getTopPosts()
    },
    async getTopPosts () {
      if (this.top_post_loader === true) {
        return
      }
      this.top_post_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id,
          date: this.date,
          tab: 'posts',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
        if (response.status) {
          this.top_posts = response.top_posts
        }
      }
      this.top_post_loader = false
    },
    changeSort (field, type) {
      this.top_post_loader = true
      const res = this.existing_sort.split('_')
      if (field === res[0] && type === res[1]) {
        this.top_post_loader = false
        return
      }
      let sort_field = false

      switch (field) {
        case 'engagements':
          sort_field = this.getEngagementField('instagram')
          break
        case 'comments':
          sort_field = this.getCommentField('instagram')
          break
        case 'likes':
          sort_field = this.getLikeField('instagram')
          break
      }
      if (sort_field === false) {
        this.top_post_loader = false
        return
      }

      if (type === 'up') {
        this.top_posts = _.sortBy(this.top_posts, sort_field).reverse()
      } else {
        this.top_posts = _.sortBy(this.top_posts, sort_field)
      }
      console.log(sort_field)
      console.log(type)

      this.existing_sort = field + '_' + type
      this.sort_types[field][type] = true
      this.sort_types[res[0]][res[1]] = false
      this.top_post_loader = false
    }
  },
  mounted () {
    this.initialize()
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    top_post_loader (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPostsLoader(newVal)
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    }
  }
}
</script>
