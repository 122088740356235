<template>
  <div class="article-filters-pane__left_section">
    <div class="article-filters-pane__left_section__tabs">
      <a
        v-tooltip.top-center="'Web'"
        :disabled="isWebContent"
        :class="{ active: isWebContent }"
        href="javascript:;"
        @click.prevent="redirectSearchWebView"
      >
        <i class="icon-Globe_icon"></i>
        <span>Web</span>
      </a>
      <a
        v-tooltip.top-center="'Youtube'"
        href="javascript:;"
        :disabled="isYoutubeContent"
        :class="{ active: isYoutubeContent }"
        @click.prevent="redirectSearchYoutubeView"
      >
        <i class="icon-Youtube_icon"></i>
        <span>YouTube</span>
      </a>
      <a
        v-tooltip.top-center="'Twitter'"
        :disabled="isTwitterContent"
        href="javascript:;"
        :class="{ active: isTwitterContent }"
        @click.prevent="redirectSearchTwitterView"
      >
        <i class="icon-twitter_icon"></i>
        <span>Twitter</span>
      </a>
    </div>
    <div class="article-filters-pane__left_section__follow_topic">
      <ArticleFollowCuratedTopicBlock />
    </div>
    <!--<div class="icon_tabs icon_tabs_updated">
            <ul class="tab_ul">
                <li class="tab_li" :disabled="isWebContent"
                    @click.prevent="redirectSearchWebView()">
                    <a v-tooltip.top-center="'Web'" class="tab_a globe_gray_icon"
                       :class="{active:isWebContent}" href="">
                        <i class="far fa-globe"></i>
                        <span>Web</span>
                    </a>
                </li>
                <li :disabled="isYoutubeContent" class="tab_li"
                    @click.prevent="redirectSearchYoutubeView()">
                    <a v-tooltip.top-center="'Youtube'" href=""
                       class="tab_a youtube_gray_icon"
                       :class="{active:isYoutubeContent}">
                        <i class="icon-Youtube_icon"></i>
                        <span>Youtube</span>
                    </a>
                </li>
                &lt;!&ndash;<li :disabled="isFacebookContent" class="tab_li"
                    @click.prevent="$router.push({'name': 'discover_search_facebook'})">
                    <a href=""
                       class="tab_a facebook_gray_icon"
                       :class="{active:isFacebookContent}">
                        <i class="fab fa-facebook-f fb important"></i>
                        <span>Facebook</span>
                    </a>
                </li>&ndash;&gt;
                <li :disabled="isTwitterContent" class="tab_li"
                    @click.prevent="redirectSearchTwitterView()">
                    <a v-tooltip.top-center="'Twitter'"  :disabled="isTwitterContent" href=""
                       class="tab_a "
                       :class="{active:isTwitterContent}">
                        <i class="icon-twitter_icon"></i>
                        <span>Twitter</span>
                    </a>
                </li>

            </ul>
        </div>-->
  </div>
</template>

<script>
import ArticleFollowCuratedTopicBlock from '../ArticleFollowCuratedTopicBlock'
export default {
  name: 'ContentChannelFilters',
  components: {
    ArticleFollowCuratedTopicBlock
  },
  props: {},
  computed: {
    isWebContent () {
      const routeName = this.$route.name
      return (
        routeName === 'discover_search_web' ||
        routeName === 'discover_topics_web'
      )
    },
    isFacebookContent () {
      const routeName = this.$route.name
      return (
        routeName === 'discover_search_facebook' ||
        routeName === 'discover_topics_facebook'
      )
    },
    isTwitterContent () {
      const routeName = this.$route.name
      return (
        routeName === 'discover_search_twitter' ||
        routeName === 'discover_topics_twitter'
      )
    },
    isYoutubeContent () {
      const routeName = this.$route.name
      return (
        routeName === 'discover_search_youtube' ||
        routeName === 'discover_topics_youtube'
      )
    }
  },
  methods: {
    redirectSearchWebView () {
      this.trackUserMaven('discover_search_web')
      if (this.$route.query.q) {
        return this.$router.push({
          name: 'discover_search_web',
          query: { q: this.$route.query.q }
        })
      } else {
        return this.$router.push({ name: 'discover_search' })
      }
    },
    redirectSearchYoutubeView () {
      console.log('redirecting to youtube search view', this.$route.query)
      this.trackUserMaven('discover_search_youtube')
      if (this.$route.query.q) {
        return this.$router.push({
          name: 'discover_search_youtube',
          query: { q: this.$route.query.q }
        })
      } else if (this.$route.query.tag) {
        return this.$router.push({
          name: 'discover_search_youtube',
          query: { q: this.$route.query.tag }
        })
      } else {
        return this.$router.push({ name: 'discover_search_youtube' })
      }
    },
    redirectSearchTwitterView () {
      this.trackUserMaven('discover_search_twitter')
      if (this.$route.query.q) {
        return this.$router.push({
          name: 'discover_search_twitter',
          query: { q: this.$route.query.q }
        })
      } else {
        return this.$router.push({ name: 'discover_search_twitter' })
      }
    }
  }
}
</script>
