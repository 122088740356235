// const CalenderView = () => import(/* webpackChunkName: "planner" */'../../components/view/CalenderView.vue')
// import CalenderMain from '../../components/view/calendar-view/CalenderMain'
const PlannerMain = () =>
  import(
    /* webpackChunkName: "planner" */ '@src/modules/planner/components/PlannerMain.vue'
  )

const CalenderMain = () =>
  import(
    /* webpackChunkName: "planner" */ '@src/modules/planner/components/view/calendar-view/CalenderMain.vue'
  )
const ListView = () =>
  import(
    /* webpackChunkName: "planner" */ '@src/modules/planner/components/view/ListView.vue'
  )
const FeedView = () =>
  import(
    /* webpackChunkName: "planner" */ '@src/modules/planner/components/view/FeedView.vue'
  )
// const CalendarTest = () => import('../../components/view/CalendarTest.vue')

export const plannerRoutes = {
  planner: {
    path: '/:workspace/planner',
    name: 'planner',
    redirect: '/:workspace/planner/list',
    component: PlannerMain,
    meta: {
      title: 'Planner',
    },
    children: [
      {
        path: '/:workspace/planner/calendar/',
        name: 'calender_plans',
        component: CalenderMain,
        meta: {
          title: 'Calendar View | Planner',
        },
      },
      {
        path: '/:workspace/planner/list/',
        name: 'list_plans',
        component: ListView,
        redirect: '/:workspace/planner/list-view',
        meta: {
          title: 'List View | Planner',
        },
      },
      {
        path: '/:workspace/planner/feed/',
        name: 'feed_view',
        component: FeedView,
        meta: {
          title: 'Feed View | Planner',
        },
      },
      // {
      //   path: '/:workspace/planner/calendar-test/',
      //   name: 'calendar_test',
      //   component: CalendarTest,
      //   meta: {
      //     'title': 'Calendar View | Planner'
      //   }
      // }
    ],
  },
}
