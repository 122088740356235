<template>
  <div class="analytic_common_component ">
    <div v-if="loader" class="component_inner">
      <div class="page_view d-flex align-items-center justify-content-center ">
        <div class="subpage">
          <div class="logo_block">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loader && !getReportsServiceCalls.retrieve" class="component_inner">
      <CoverPage :type="type" :accounts="metrics.accounts" :report_date="report_date"></CoverPage>
      <template v-if="type === 'twitter'">
        <TwitterReport :metrics="metrics"></TwitterReport>
      </template>
      <template v-else-if="type === 'pinterest'">
        <PinterestReport :metrics="metrics"></PinterestReport>
      </template>
      <template v-else-if="type === 'linkedin'">
        <LinkedinReport :metrics="metrics"></LinkedinReport>
      </template>
      <template v-else-if="type === 'facebook'">
        <FacebookReport :metrics="metrics"></FacebookReport>
      </template>
      <template v-else-if="type === 'instagram'">
        <InstagramReport :metrics="metrics"></InstagramReport>
      </template>
      <template v-else-if="type === 'group'">
        <OverviewReport :metrics="metrics"></OverviewReport>
      </template>

    </div>
  </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
// import TwitterReport from './reports/channel/TwitterReport'
// import LinkedinReport from './reports/channel/LinkedinReport'
// import FacebookReport from './reports/channel/FacebookReport'
// import InstagramReport from './reports/channel/InstagramReport'
import OverviewReport from '@src/modules/analytics/views/reports/OverviewReport'
import LinkedinReport from '@src/modules/analytics/views/reports/LinkedinReport'
import FacebookReport from '@src/modules/analytics/views/reports/FacebookReport'
import TwitterReport from '@src/modules/analytics/views/reports/TwitterReport'
import InstagramReport from '@src/modules/analytics/views/reports/InstagramReport'
import { workspaceTypes } from '../../setting/store/states/mutation-types'
import { dataValues } from './common/helper'
import PinterestReport from './reports/channel/PinterestReport'
import CoverPage from './reports/CoverPage'
import analyticsUtilsMixin from './common/analyticsUtilsMixin'

export default {
  components: {
    CoverPage,
    TwitterReport,
    PinterestReport,
    LinkedinReport,
    FacebookReport,
    InstagramReport,
    OverviewReport
  },
  mixins: [analyticsUtilsMixin],
  data () {
    return {
      metrics: {},
      report_date: '',
      is_rendered: false,
      loader: true,
      type: '',
      workspace: [],
      accounts: []
    }
  },

  async mounted () {
    console.log(this.$route.params)
    if (this.$route.params.reportId) {
      const response = await this.getReportsService({
        '_id': this.$route.params.reportId
      })

      if (response) {
        this.workspace = await this.fetchWorkspace({ 'id': response.workspace_id })
        this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, this.workspace)

        if (response.type === 'twitter') {
          this.loader = true

          const apiType = [
            'aggregations',
            'top_posts',
            'statistics',
            'best_time_for_content',
            'content_distribution_parsing',
            'fans',
            'publish_posts_with_mentions',
            'published_posts_with_engagement',
            'audience'
          ]

          for (const type of apiType) {
            // let payload = {
            //   'workspace_id': '5f9f954888397c55f934dba2',
            //   date: response.date,
            //   'accounts': [
            //     '1095215248884355072'
            //   ],
            //   previous_date: response.previous_date,
            //   'api_type': type,
            //   'type': 'twitter'
            // }

            const payload = {
              'workspace_id': this.workspace._id,
              'timezone': this.workspace.timezone,
              'date': response.date,
              'accounts': [
                response.accounts[0].twitter_id
              ],
              'previous_date': response.previous_date,
              'api_type': type,
              'type': 'twitter'
            }

            const resp = await this.getTwitterAnalyticsService(payload)

            this.metrics = Object.assign({}, this.metrics, {
              accounts: response.accounts[0]
            })

            switch (type) {
              case 'aggregations':
                this.metrics.previous_aggregations = resp.aggregations.previous
                this.metrics.aggregations = resp.aggregations.current

                break
              case 'top_posts':
                if (resp.top_posts.length > 0) {
                  this.metrics.top3Posts = this.setTopPosts(3, resp.top_posts)
                  this.metrics.top5Posts = this.setTopPosts(5, resp.top_posts)
                  this.metrics.top10Posts = this.setTopPosts(10, resp.top_posts)
                  this.metrics.top15Posts = this.setTopPosts(15, resp.top_posts)
                } else {
                  this.metrics.top3Posts = []
                  this.metrics.top5Posts = []
                  this.metrics.top10Posts = []
                  this.metrics.top15Posts = []
                }
                break
              case 'statistics':
                this.metrics.statistics = resp.statistics
                break
              case 'best_time_for_content':
                this.metrics.best_time_for_content = resp.best_time_for_content
                break
              case 'content_distribution_parsing':
                this.metrics.content_distribution_parsing = resp.content_distribution_parsing
                break
              case 'fans':
                this.metrics.fans = resp.fans
                break
              case 'publish_posts_with_mentions':
                this.metrics.publish_posts_with_mentions = resp.publish_posts_with_mentions
                break
              case 'published_posts_with_engagement':
                this.metrics.published_posts_with_engagement = resp.published_posts_with_engagement
                break
              case 'audience':
                this.metrics.audience = resp.audience
                break
            }
          }
        } else {
          await this.getAnalyticsForReports({
            date: response.date,
            previous_date: response.previous_date,
            accounts: [...response.accounts],
            type: response.type,
            workspace_id: response.workspace_id,
            timezone: this.workspace.timezone
          }, response.accounts)
        }

        this.report_date = response.date
        this.type = response.type
        this.accounts = response.accounts

        if (!this.isEmpty(this.metrics)) {
          this.loader = false
        }

        console.debug('Metrics', this.metrics)

        const self = this
        setTimeout(() => {
          self.is_rendered = true
        }, 10000)
      }
    }
  },
  methods: {
    ...mapActions(['getAnalyticsService', 'getReportsService', 'getAnalyzeService', 'getTwitterAnalyticsService']),
    isEmpty (obj) {
      return Object.keys(obj).length === 0
    },
    setTopPosts (count, dataCollection) {
      const payloadToReturn = []
      const iteCount = count > dataCollection.length ? dataCollection.length : count
      for (let x = 0; x < iteCount; x++) {
        payloadToReturn.push(dataCollection[x])
      }
      return payloadToReturn
    },
    GoToSocial () {
      this.$router.push({ 'name': 'social' })
      $('.modal').modal('hide')
    },

    async getAnalyticsForReports (payload = {}, accounts) {
      this.loader = true
      const payloadCopy = payload
      const res = payloadCopy.date.split(' - ')
      payloadCopy.previous_date = `${this.previousAnalyticsPeriodDate(res[0], res[1])} - ${res[0]}`

      if (payloadCopy.type === 'group') {
        const sections = ['overview', 'top_posts', 'engagement_rollup', 'posts_engagements', 'account_performance']

        for (const section in sections) {
          console.log(sections[section])

          payloadCopy.section = sections[section]
          payloadCopy.accounts = accounts
          const resp = await this.getAnalyzeService(payloadCopy)

          this.$set(this.metrics, sections[section], resp[sections[section]])

          // this.$set(this.metrics.accounts, 'accounts', accounts)
        }
        this.$set(this.metrics, 'accounts', payloadCopy.accounts)
        // this.metrics = Object.assign({}, this.metrics, {
        //   s_accounts: payloadCopy.accounts
        // })
      } else if (payloadCopy.type === 'facebook') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].facebook_id
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        let response = await this.getAnalyzeService(payloadCopy)
        if ('current' in response.overview) {
          this.metrics.overview.summary = response.overview
        }

        payloadCopy.section = 'audience_growth'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience.audience_growth = response.audience_growth
        if ('fan_count' in response.audience_growth_rollup.current) {
          this.metrics.overview.audience.audience_growth_rollup = response.audience_growth_rollup
        }

        payloadCopy.section = 'publishing_behaviour'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.publishing_behaviour.publishing_behaviour = response.publishing_behaviour
        this.metrics.overview.publishing_behaviour.publishing_behaviour_rollup = response.publishing_behaviour_rollup

        payloadCopy.section = 'top_posts'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts

        payloadCopy.section = 'active_users'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.active_users = response.active_users

        payloadCopy.section = 'impressions'
        response = await this.getAnalyzeService(payloadCopy)
        if ('impressions_rollup' in response) {
          this.metrics.overview.impressions.impressions_rollup = response.impressions_rollup
        }
        this.metrics.overview.impressions.impressions = response.impressions

        payloadCopy.section = 'engagement'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement.engagement = response.engagement
        if ('engagement_rollup' in response) {
          this.metrics.overview.engagement.engagement_rollup = response.engagement_rollup
        }

        payloadCopy.section = 'video_analytics'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.video_analytics = response.video_insights

        payloadCopy.tab = 'demographics'

        payloadCopy.section = 'audience_age'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.fans_age = response.audience_age
        this.metrics.demographics.fans_gender = response.audience_gender
        this.metrics.demographics.max_gender_age = response.max_gender_age
        this.metrics.demographics.fans = response.fans

        payloadCopy.section = 'country_city'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.audience_country = response.audience_country
        this.metrics.demographics.audience_country = this.sortList(this.metrics.demographics.audience_country)
        this.metrics.demographics.audience_city = response.audience_city
        this.metrics.demographics.audience_city = this.sortList(this.metrics.demographics.audience_city)
      } else if (payloadCopy.type === 'instagram') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].instagram_id
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        let response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.summary = response.overview.summary

        payloadCopy.section = 'audience_growth'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience.audience_growth = response.overview.audience_growth
        this.metrics.overview.audience.audience_growth_rollup = response.overview.audience_growth_rollup

        payloadCopy.section = 'publishing_behaviour'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.publishing_behaviour.publishing_behaviour = response.overview.publishing_behaviour
        this.metrics.overview.publishing_behaviour.publishing_behaviour_rollup = response.overview.publishing_behaviour_rollup

        payloadCopy.section = 'top_posts'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts

        payloadCopy.section = 'active_users'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.active_users = response.overview

        payloadCopy.section = 'impressions'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.impressions = response.overview

        payloadCopy.section = 'engagement'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement = response.overview

        payloadCopy.section = 'hashtags'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.hashtags = response

        payloadCopy.section = 'stories_performance'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.story_performance = response.overview

        payloadCopy.tab = 'demographics'

        payloadCopy.section = 'audience_age'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.fans_age = response.audience_age
        this.metrics.demographics.fans_gender = response.audience_gender
        this.metrics.demographics.max_gender_age = response.max_audience_age
        this.metrics.demographics.fans = response.audience_fans

        payloadCopy.section = 'country_city'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.audience_country = response.audience_country
        this.metrics.demographics.audience_country = this.sortList(this.metrics.demographics.audience_country)
        this.metrics.demographics.audience_city = response.audience_city
        this.metrics.demographics.audience_city = this.sortList(this.metrics.demographics.audience_city)
      }
      else if (payloadCopy.type === 'linkedin') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        this.metrics.profile = payloadCopy.accounts[0].type
        payloadCopy.accounts = payloadCopy.accounts[0].linkedin_id
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        let response = await this.getAnalyzeService(payloadCopy)
        if ('current' in response.overview) {
          this.metrics.overview.summary = response.overview
        }

        payloadCopy.section = 'audience_growth'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience.audience_growth = response.audience_growth
        this.metrics.overview.audience.audience_growth_rollup = response.audience_growth_rollup

        payloadCopy.section = 'top_posts'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts

        payloadCopy.section = 'engagement'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement.engagement = response.engagement
        if ('engagement_rollup' in response) {
          this.metrics.overview.engagement.engagement_rollup = response.engagement_rollup
        }

        payloadCopy.section = 'posts_per_days'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.posts_per_days = response.posts_per_days

        payloadCopy.section = 'hashtags'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_hashtags = response.top_hashtags

      } else {
        const accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].instagram_id
        const resp = await this.getAnalyticsService(payloadCopy)
        this.metrics = Object.assign(dataValues()[payloadCopy.type], resp)
        this.metrics.accounts = accounts
        if (payloadCopy.type === 'instagram') {
          payloadCopy.section = 'demographics'
          this.metrics.demographics = await this.getAnalyticsService(payloadCopy)
          console.log(this.metrics)
        }

      }

      this.loader = false

      setTimeout(() => {
        window.print()
      }, 30000)
    },

    printDoc () {
      window.print()
    }
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls'])
  },
  watch: {},
  created () {
    // setTimeout(function () {
    //     window.print();
    // },5000)

  }

}
</script>

<style>
.analytics_dp {
  /*background: #f5f9fc !important;*/
}

.profile_image {
  border-radius: 4px;
  width: 38px;
}
</style>
