import proxy from '@common/lib/http-common'
import {
  checkConnectorStateURL,
  fetchIntegrationAuthorizationLinksURL,
  fetchIntegrationsAccountsURL,
  removeIntegrationURL
} from '../../config/api-utils'
import {
  REMOVE_INTEGRATION_ACCOUNT_ERROR,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import { integrations, shortener, rewriter, platforms } from './mutation-types'

import { saveFeedlyTopicsURL } from '../../../discovery/config/api-utils'
import { publish } from '../../../publish/store/states/mutation-types'
import {EventBus} from "@common/lib/event-bus";

const getDefaultSocialAuthorizationState = () => {
  return {
    bitly: null,
    pocket: null,
    pocket_redirect_token: null,
    replug: null,
    feedly: null
  }
}

export default {
  state: {
    authorization: getDefaultSocialAuthorizationState(),
    accounts: {
      fetch_status: false,
      fetch_loader: false,
      pocket: [],
      replug: {
        item: {
          domain_options: ['rplg.co', 'replug.link']
        },
        brands: [],
        cta: [],
        selected_brand: '',
        selected_cta: '',
        edit_status: false
      },
      feedly: {
        items: [],
        topics: []
      }
    }
  },
  getters: {
    getIntegrationAuthorization: (state) => state.authorization,
    getFetchIntegrationsAccountsStatus: (state) => state.accounts.fetch_status,
    getFetchIntegrationsAccountsLoader: (state) => state.accounts.fetch_loader,
    getPocket: (state) => state.accounts.pocket,
    getReplug: (state) => state.accounts.replug,
    getFeedly: (state) => state.accounts.feedly
  },
  actions: {
    fetchIntegrationAuthorizationLinks ({
      commit,
      getters,
      rootGetters,
      dispatch
    }) {
      // check for mutliple api calls
      if (
        !Object.values(getters.getIntegrationAuthorization).every(
          (link) => link !== null
        )
      ) {
        console.debug('Method:fetchIntegrationAuthorizationLinks')
        proxy
          .post(fetchIntegrationAuthorizationLinksURL, {})
          .then((response) => {
            if (response.data.status) {
              commit(
                integrations.SET_AUTHORIZATION,
                response.data.authorization
              )
            }
          })
          .catch((error) => {
            console.debug(
              'Exception in fetchIntegrationAuthorizationLinks',
              error
            )
            // dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
          })
      }
    },

    async fetchIntegrationsAccounts ({
      commit,
      getters,
      rootGetters,
      dispatch
    }) {
      console.debug('Method:fetchIntegrationsAccounts')
      if (getters.getFetchIntegrationsAccountsStatus === false && getters.getWorkspaces.activeWorkspace !== false) {
        commit(integrations.SET_FETCH_ACCOUNTS_STATUS, true)
        commit(integrations.SET_FETCH_ACCOUNTS_LOADER, true)
        return proxy
          .post(fetchIntegrationsAccountsURL, {
            workspace_id: getters.getWorkspaces.activeWorkspace._id
          })
          .then((response) => {
            console.log('BITLY=', response)
            if (response.data.status === true) {
              commit(shortener.SET_BITLY_ACCOUNT, response.data.shortener.bitly)
              // commit(
              //     rewriter.SET_CHIMP_REWRITER_ACCOUNT,
              //     response.data.rewriting.spinners.chimp_rewriter
              // )
              // commit(
              //     rewriter.SET_SPIN_REWRITER_ACCOUNT,
              //     response.data.rewriting.spinners.spin_rewriter
              // )
              // commit(
              //     rewriter.SET_QUILLBOT_ACCOUNT,
              //     response.data.rewriting.spinners.quillbot
              // )
              // commit(
              //     rewriter.SET_YANDEX_ACCOUNT,
              //     response.data.rewriting.translator.yandex
              // )
              commit(integrations.SET_POCKET_ACCOUNTS, response.data.pocket)

              if (!response.data.replug.item) {
                response.data.replug.item = {
                  domain_options: ['rplg.co', 'replug.link']
                }
              } else if (!response.data.replug.item.domain_options) {
                response.data.replug.item.domain_options = [
                  'rplg.co',
                  'replug.link'
                ]
              }
              commit(integrations.SET_REPLUG_ACCOUNT, response.data.replug.item)
              commit(integrations.SET_REPLUG_BRANDS, response.data.replug.brands)
              dispatch(
                  'changeReplugShortenerSelection',
                  response.data.replug.item
              )
              dispatch(
                  'changeRelugSelection',
                  getters.getPublishSelection.replug.selectedItem
              )
              commit(integrations.SET_FEEDLY_ACCOUNTS, response.data.feedly)
              commit(integrations.SET_FETCH_ACCOUNTS_LOADER, false)
              return true
            }
          })
          .catch((error) => {
            console.debug('Exception in fetchIntegrationsAccounts', error)
            dispatch('toastNotification', {
              message: UNKNOWN_ERROR,
              type: 'error'
            })
            commit(integrations.SET_FETCH_ACCOUNTS_LOADER, false)
            return false
          })
      }
      return false
    },

    async checkConnectorState (
      { commit, getters, rootGetters, dispatch },
      hash
    ) {
      console.debug('Method:checkConnectorState')
      if (hash) {
        return proxy
          .post(checkConnectorStateURL, {})
          .then(async (response) => {
            if (!response.data.status && response.data.message) {
              dispatch('toastNotification', {
                message: response.data.message,
                type: 'error'
              })
              return false
            }
            return response
          })
          .catch((error) => {
            console.debug('Exception in checkConnectorState', error)
            return false
          })
      }
    },

    changeReplugShortenerSelection (
      { commit, getters, rootGetters, dispatch },
      account
    ) {
      console.debug('Action::changeReplugShortenerSelection', account)
      commit(integrations.SET_REPLUG_SELECTED_BRAND, '')
      commit(integrations.SET_REPLUG_SELECTED_CTA, '')
      commit(integrations.SET_REPLUG_EDIT_STATUS, true)
      if (account.brand) {
        const brand = getters.getReplug.brands.find(
          (brand) => brand._id === account.brand
        )
        if (brand) {
          commit(integrations.SET_REPLUG_SELECTED_BRAND, brand)
          const campaign = brand.campaign.find(
            (campaign) => campaign._id === account.cta
          )
          if (campaign) {
            commit(integrations.SET_REPLUG_SELECTED_CTA, campaign)
            commit(integrations.SET_REPLUG_EDIT_STATUS, false)
          }
        }
      }
    },

    changeRelugSelection ({ commit, getters, rootGetters, dispatch }, replug) {
      console.debug('Action::changeRelugSelection', replug)
      if (replug.brand && replug.campaign) {
        const selection = {
          status: false,
          brand: '',
          campaign: '',
          selectedItem: {
            status: false,
            brand: '',
            campaign: ''
          }
        }

        // finding brand
        const brand = getters.getReplug.brands.find(
          (brand) => brand._id === replug.brand
        )
        if (brand) {
          // finding brand campaigns
          const campaign = brand.campaign.find(
            (item) => item._id === replug.campaign
          )
          if (campaign) {
            selection.status = true
            selection.brand = brand
            selection.campaign = campaign
          }
        }
        commit(publish.SET_PUBLISH_REPLUG_VALUE, selection)
      }
    },

    async saveFeedlyTopics (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Method:saveFeedlyTopics')
      return proxy
        .post(saveFeedlyTopicsURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: response.data.message,
              type: 'success'
            })
            return true
          }
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error'
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in saveFeedlyTopics', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    async removeSocialIntegration (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Action:removeSocialIntegration', payload)

      const context = payload.context

      delete payload.context

      return proxy
        .post(removeIntegrationURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: 'Social Account Removed Successfully.',
              type: 'success'
            })
            EventBus.$emit('refetchSocialAccounts')
          } else {
            dispatch('toastNotification', {
              message: REMOVE_INTEGRATION_ACCOUNT_ERROR,
              type: 'error'
            })
          }
          return response.data
        })
        .catch((error) => {
          console.debug('Exception in removeIntegration', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    async removeIntegration (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Action:removeIntegration', payload)
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
      return proxy
        .post(removeIntegrationURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response
          }
          const message = response.data.message
            ? response.data.message
            : REMOVE_INTEGRATION_ACCOUNT_ERROR
          dispatch('toastNotification', { message: message, type: 'error' })
          return false
        })
        .catch((error) => {
          console.debug('Exception in removeIntegration', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    setDefaultIntegrationStates ({ commit, getters, rootGetters, dispatch }) {
      dispatch('setDefaultBlogStates')
      dispatch('setDefaultSocialStates')
      dispatch('setDefaultShortenerStates')
      dispatch('setDefaultRewritingStates')
      commit(platforms.SET_DEFAULT_STATES, [])
      commit(integrations.SET_FETCH_ACCOUNTS_STATUS, false)
      commit(integrations.SET_FETCH_ACCOUNTS_LOADER, false)
      commit(integrations.SET_POCKET_ACCOUNTS, [])
      commit(integrations.SET_REPLUG_ACCOUNT, {
        domain_options: ['rplg.co', 'replug.link']
      })
      commit(integrations.SET_FEEDLY_ACCOUNTS, [])
      commit(integrations.SET_FEEDLY_TOPICS, [])
    }
  },
  mutations: {
    [integrations.SET_AUTHORIZATION] (state, authorization) {
      if (!authorization) {
        state.authorization = getDefaultSocialAuthorizationState()
        return false
      }
      state.authorization.bitly = authorization.bitly
      if (authorization.pocket && authorization.pocket.link) {
        state.authorization.pocket = authorization.pocket.link
        state.authorization.pocket_redirect_token =
          authorization.pocket.pocket_redirect_token
      } else {
        state.authorization.pocket = ''
        state.authorization.pocket_redirect_token = ''
      }
      state.authorization.feedly = authorization.feedly
      state.authorization.replug = authorization.replug
    },

    [integrations.SET_FETCH_ACCOUNTS_STATUS] (state, status) {
      state.accounts.fetch_status = status
    },

    [integrations.SET_FETCH_ACCOUNTS_LOADER] (state, status) {
      state.accounts.fetch_loader = status
    },

    [integrations.SET_POCKET_ACCOUNTS] (state, accounts) {
      state.accounts.pocket = accounts
    },

    [integrations.SET_REPLUG_ACCOUNT] (state, account) {
      state.accounts.replug = {
        item: { domain_options: ['rplg.co', 'replug.link'] },
        brands: [],
        cta: [],
        selected_brand: '',
        selected_cta: '',
        edit_status: false
      }
      state.accounts.replug.item = account
    },

    [integrations.SET_REPLUG_BRANDS] (state, brands) {
      state.accounts.replug.brands = brands
    },

    [integrations.SET_REPLUG_SELECTED_BRAND] (state, brand) {
      state.accounts.replug.selected_brand = brand
    },

    [integrations.SET_REPLUG_SELECTED_CTA] (state, cta) {
      state.accounts.replug.selected_cta = cta
    },

    [integrations.SET_REPLUG_EDIT_STATUS] (state, status) {
      state.accounts.replug.edit_status = status
    },

    [integrations.SET_FEEDLY_ACCOUNTS] (state, accounts) {
      state.accounts.feedly.items = accounts
    },

    [integrations.SET_FEEDLY_TOPICS] (state, topics) {
      state.accounts.feedly.topics = topics
    }
  }
}
