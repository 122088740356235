<template>
  <div class="competitor-filterer">
    <div class="competitor-filterer__left">
      <div class="competitor-filterer__title">
        Competitors' Analytics
        <a
            class="btn btn-studio-theme-grey-space btn-size-small ml-3"
            v-b-tooltip.right="'Manage Competitors'"
            href="javascript:;"
            v-b-modal.modal-1
        >
          <i class="icon-settings-cs"></i>
        </a>
      </div>
    </div>
    <div class="competitor-filterer__right">
      <p
      ><span class="pr-1">* </span> Stats are measured as per workspace
        timezone.</p
      >

      <div class="analytics-filter-bar__right-btns">
        <date-range-picker
            ref="facebook_analytics_dp"
            class="datepicker_filter_analytics_main datepicker_input mr-2 facebook_analytics_dp"
            :opens="analytics.opens"
            @update="updateFacebookAnalytics"
            :minDate="analytics.minDate"
            :maxDate="analytics.maxDate"
            :startDate="analytics.startDate"
            :endDate="analytics.endDate"
            :locale-data="analytics.locale"
            :singleDatePicker="analytics.singleDatePicker"
            :timePicker="analytics.timePicker"
            :timePicker24Hour="analytics.timePicker24Hour"
            :showWeekNumbers="analytics.showWeekNumbers"
            :showDropdowns="analytics.showDropdowns"
            :autoApply="analytics.autoApply"
            :ranges="analytics.ranges"
        >
          <div
              slot="input"
              style="padding: 7px 12px"
              class="datepicker_content with_bg ml-2 analytics_dp"
              slot-scope="picker"
          >
            <i class="icon-Calendar"></i>
            <!--  <span v-if="picker.label">
           {{picker.label}}
       </span>-->
            <span>
              <template v-if="!analytics.isChanged"> Last 30 Days </template>
              <template v-else>
                <template
                    v-if="getDatePickerLabel(picker.startDate, picker.endDate)"
                >
                  {{ getDatePickerLabel(picker.startDate, picker.endDate) }}
                </template>
                <template v-else>
                  {{ picker.startDate | onlyDate }} -
                  {{ picker.endDate | onlyDate }}
                </template>
              </template>
            </span>
          </div>
        </date-range-picker>
      </div>
    </div>
    <b-modal
        hide-footer
        no-close-on-backdrop
        id="modal-1"
        modal-class="manage_competitor_modal"
        title="Manage Competitors"
    >
      <AddCompetitorFields
          @updatedCompetitor="$emit('updatedCompetitor')"
          native="false"
          :activeCompetitor="selectedCompetitor"
      />
    </b-modal>
  </div>
</template>

<script>
import AddCompetitorFields from './AddCompetitorFields'
import moment from 'moment'
import DateRangePicker from '@common/components/Analytics-date-range-picker/src/components/DateRangePicker'
export default {
  name: 'FilterbarCompetitor',
  props: { selectedCompetitor: String },
  data () {
    return {
      analytics: {
        isChanged: false,
        locale: {
          format: 'MMMM DD, YYYY' // format of the dates displayed
        },
        opens: 'left',
        startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment.utc().format('YYYY-MM-DD'),
        minDate: moment().utc().subtract(3, 'months').format('YYYY-MM-DD'),
        maxDate: moment.utc().format('YYYY-MM-DD'),
        dateRange: {},
        show_ranges: true,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: true,
        showDropdowns: true,
        autoApply: false,
        showWeekNumbers: true,
        ranges: {
          'All Time': [moment.utc().subtract(3, 'months'), moment.utc()],
          '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
          '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
          'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
          'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
          'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
          'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()]
        }
      }
    }
  },
  components: {
    DateRangePicker,
    AddCompetitorFields
  },
  computed: {},
  methods: {
    updateFacebookAnalytics (values) {
      console.log(
          'datelimits',
          this.analytics.minDate,
          this.analytics.maxDate,
          values
      )
      this.analytics.isChanged = true
      const startDate = moment(values.startDate)
      const endDate = moment(values.endDate)
      this.analytics.startDate = startDate.format('YYYY-MM-DD HH:mm:ss')
      this.analytics.endDate = endDate.format('YYYY-MM-DD HH:mm:ss')
      this.$emit(
          'set-date-range',
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD')
      )
    }
  }
}
</script>
