<template>
  <div class="head_section d-flex align-items-center">
    <div class="head_left">
      <img v-if="logo" :src="logo" alt="" height="50px" />
      <img v-else src="../../../../assets/img/logo/logo_text_logo.png" alt="" />
    </div>
    <div class="head_right ml-auto">
      <p
        >{{ report_name }} Report |
        <template v-if="current && total"
          >{{ current }} of {{ total }}</template
        >
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    report_name: {
      type: String,
      default: 'Twitter'
    },
    current: {},
    total: {}
  },
  data () {
    return {
      logo: '',
      name: ''
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getSettingLoaders'])
  },
  mounted: async function () {
    this.getCompanyDetails()
  },
  methods: {
    getCompanyDetails () {
      this.logo = this.getActiveWorkspace.company_logo
      this.name = this.getActiveWorkspace.company_name
    }
  }
}
</script>
