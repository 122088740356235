import DownloadReports from '@src/modules/analytics/components/reports/DownloadReports'
import ReportsSettings from '@src/modules/setting/components/ReportSettings'

const MainAnalytics = () =>
  import(
    /* webpackChunkName: "analytics_v3" */ '@src/modules/analytics_v3/views/MainAnalytics.vue'
  )

const AnalyticsOverview = () =>
  import(
    /* webpackChunkName: "AnalyticsOverview" */ '@src/modules/analytics/views/overview/AnalyticsOverview'
  )

const FacebookOverview = () =>
  import(
    /* webpackChunkName: "FacebookAnalyticsOverview" */ '@src/modules/analytics/views/facebook/FacebookMain'
  )
const InstagramOverview = () =>
  import(
    /* webpackChunkName: "InstagramAnalyticsOverview" */ '@src/modules/analytics/views/instagram/InstagramMain'
  )
const TwitterOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '@src/modules/analytics/views/twitter/TwitterMain'
  )

const InstagramPost = () =>
  import('@src/modules/analytics/components/instagram/InstagramPosts.vue')
const InstagramDemographics = () =>
  import(
    '@src/modules/analytics/components/instagram/InstagramDemographics.vue'
  )
const InstagramProfile = () =>
  import('@src/modules/analytics/components/instagram/InstagramProfile.vue')
const PDFReports = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/PDFReports.vue'
  )

const PinterestOverview = () =>
  import(
    /* webpackChunkName: "PinterestAnalyticsOverview" */ '@src/modules/analytics/views/pinterest/PinterestMain'
  )
const LinkedinOverview = () =>
  import(
    /* webpackChunkName: "LinkedinAnalyticsOverview" */ '@src/modules/analytics/views/linkedin/LinkedInMain'
  )
const MyReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/reports/MyReport.vue'
  )
const TwitterCompetitor = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/twitter/TwitterCompetitor.vue'
  )
const FacebookCompetitorReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/facebook/FacebookCompetitorReport'
  )
const facebookCompetitorOverview = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/views/competitors-report/facebook/facebookCompetitorOverview'
  )

export const analyticsRoutesV3 = {
  path: '/:workspace/analyze',
  name: 'analytics_v3',
  redirect: { name: 'analytics_overview_v3' },
  component: MainAnalytics,
  meta: {
    title: 'Analytics',
  },
  children: [
    // {
    //   path: 'instagram-competitor/:reportId',
    //   name: 'instagram_competitor_v3',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "analytics_v3" */ '@src/modules/analytics_v3/views/instagram/Competitor.vue'
    //     ),
    //   meta: {
    //     title: 'Instagram Competitor',
    //   },
    // },
    // {
    //   path: 'instagram-competitor/',
    //   name: 'instagram_competitor_overview_v3',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "analytics_v3" */ '@src/modules/analytics_v3/views/instagram/CompetitorList.vue'
    //     ),
    //   meta: {
    //     title: 'Instagram Competitor',
    //   },
    // },
    {
      path: 'facebook/:accountId?',
      component: FacebookOverview,
      name: 'facebook_analytics_v3',
      meta: {
        title: 'Facebook | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/',
      component: FacebookCompetitorReport,
      name: 'facebook_competitor_v3',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/:accountId',
      component: facebookCompetitorOverview,
      name: 'facebookCompetitorOverview_v3',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: '/analytics/twitterCompetitor',
      component: TwitterCompetitor,
      name: 'twitterCompetitor',
      meta: {
        title: 'Twitter Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'twitter/:accountId?',
      component: TwitterOverview,
      name: 'twitter_analytics_v3',
      meta: {
        title: 'Twitter | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports/',
      component: MyReport,
      name: 'my_report_v3',
      meta: {
        title: 'My Report | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'download_reports/',
      component: DownloadReports,
      name: 'download_reports_v3',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports_setting/',
      component: ReportsSettings,
      name: 'reports_setting_v3',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'overview/',
      component: AnalyticsOverview,
      name: 'analytics_overview_v3',
      meta: {
        title: 'Overview | Analytics',
      },
    },
    {
      path: 'reports/:reportId?',
      component: PDFReports,
      name: 'analytics_pdf_report',
      meta: {
        title: 'Print Report | Analytics',
        guest: true,
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'pinterest/:accountId?',
      component: PinterestOverview,
      name: 'pinterest_analytics_v3',
      meta: {
        title: 'Pinterest | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'linkedin/:accountId?',
      component: LinkedinOverview,
      name: 'linkedin_analytics_v3',
      meta: {
        title: 'Linkedin | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?',
      component: InstagramOverview,
      name: 'instagram_analytics_v3',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section',
      component: InstagramOverview,
      name: 'instagram_overview',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section=',
      component: InstagramPost,
      name: 'instagram_posts',
      meta: {
        title: 'Instagram Posts | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/InstagramProfile',
      component: InstagramProfile,
      name: 'instagram_profile',
      meta: {
        title: 'Instagram Profile | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section=',
      component: InstagramDemographics,
      name: 'instagram_demographics',
      meta: {
        title: 'Instagram Demographics | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
  ],
}
