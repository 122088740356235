<template>
  <div class="inbox_topbar">
    <div
      v-if="activeInboxDetail"
      class="inbox_header inbox_topbar_inner d-flex align-items-center"
    >
      <div class="top_left">
        <div
          class="inbox_head_dropdown dropdown option_dropdown default_style_dropdown checkbox_dropdown with_background"
        >
          <div
            class="action_icon assign_conversation inbox_head_dropdown prevent_close_dropdown dropdown option_dropdown default_style_dropdown checkbox_dropdown with_background"
            data-toggle="dropdown"
          >
            <i class="icon-Assigned"> </i>
            <span class="ml-2">
              {{
                !activeInboxDetail.inbox_details.assigned.is_assigned
                  ? 'Assign'
                  : 'Assigned to ' +
                    activeInboxDetail.inbox_details.assigned.assigned_to
                      .user_name
              }}
            </span>

            <i class="icon-dropdown-cs ml-2"></i>
          </div>

          <div class="dropdown-menu dropdown-menu-left">
            <ul class="inner">
              <li class="block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200">
                <div class="radio_input_image block">
                  <div
                    class="profile_picture d-flex align-items-center"
                    @click="assignConversation(0, null)"
                  >
                    <div class="picture_block picture_block_background pl-2">
                      <div
                        class="img"
                        v-bind:style="
                          'background: url(' +
                          commonMethods.getDefaultImage() +
                          ');'
                        "
                      ></div>
                    </div>
                    <div class="text_block pl-2">
                      <p class="text">Unassigned</p>
                    </div>
                    <div
                      v-if="
                        !activeInboxDetail.inbox_details.assigned.is_assigned
                      "
                      class="check_assign ml-auto"
                    >
                      <i class="fa fa-check"></i>
                    </div>
                  </div>
                </div>
              </li>
              <template v-for="member in getWorkspaceInboxTeamMembers">
                <template v-if="member.user">
                  <li class="block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200" :key="member.user._id">
                    <div class="radio_input_image block">
                      <div
                        class="profile_picture d-flex align-items-center"
                        @click="
                          assignConversation(member.user._id, member.user)
                        "
                      >
                        <div
                          class="picture_block picture_block_background pl-2"
                        >
                          <div
                            v-if="member.user.image"
                            class="img"
                            v-bind:style="
                              'background: url(' + member.user.image + ');'
                            "
                          ></div>
                          <div
                            v-else
                            class="img"
                            :style="
                              'background: url(' +
                              commonMethods.getDefaultImage() +
                              ');'
                            "
                          ></div>
                        </div>
                        <div class="text_block pl-2">
                          <p class="text">{{ member.user.full_name }}</p>
                        </div>
                        <div
                          v-if="
                            activeInboxDetail.inbox_details.assigned
                              .is_assigned &&
                            activeInboxDetail.inbox_details.assigned.assigned_to
                              .user_id === member.user._id
                          "
                          class="check_assign ml-auto"
                        >
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </div>
        <clip-loader
          v-if="actionForAssign"
          class="d-inline-block align-middle pl-3"
          color="#989eb5"
          :size="'20px'"
        />
      </div>

      <div class="top_right ml-auto">
        <clip-loader
          v-if="actionForToolTips"
          class="d-inline-block align-middle"
          color="#989eb5"
          :size="'20px'"
        />

        <i
          v-if="activeInboxDetail.inbox_details.archived.is_archived === false"
          v-tooltip.bottom-center="
            activeInboxDetail.inbox_details.marked_done.is_marked_done
              ? 'Undone'
              : 'Done'
          "
          @click="
            changeConversationStatus(
              'marked_done',
              !activeInboxDetail.inbox_details.marked_done.is_marked_done
            )
          "
          class="icon-Cheecked action_icon"
        >
          <!--                   :style="activeInboxDetail.inbox_details.marked_done.is_marked_done ? 'background : #1ecb7b; color : #ffffff' : 'background : #ffffff; color : #1ecb7b'">-->
        </i>

        <i
          v-if="
            activeInboxDetail.inbox_details.marked_done.is_marked_done === false
          "
          v-tooltip.bottom-center="
            activeInboxDetail.inbox_details.archived.is_archived
              ? 'Unarchive'
              : 'Archive'
          "
          @click="
            changeConversationStatus(
              'archived',
              !activeInboxDetail.inbox_details.archived.is_archived
            )
          "
          class="icon-Archived-2 action_icon"
        >
          <!--                   :style="activeInboxDetail.inbox_details.archived.is_archived ? 'background : #ff4500; color : #ffffff' : 'background : #ffffff; color : #ff4500'">-->
        </i>
        <a
          v-if="activeInboxDetail.element_details.link !== null"
          v-tooltip.bottom-center="{
            content: activeInboxDetail.platform === 'google_my_business' ? 'View on GMB location' : 'View on ' + capitalizeFirstLetter(activeInboxDetail.platform),
            trigger: 'hover',
          }"
          :href="activeInboxDetail.element_details.link"
          target="_blank"
          class="icon-Link action_icon"
        ></a>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.profile_picture .picture_block_background .img {
  margin-top: 5px;
  height: 25px;
  width: 25px;
  margin-left: 0.625rem;
}

.team_dropdown_inbox .dropdown-menu,
.inbox_head_dropdown .dropdown-menu {
  min-width: 220px;
}

.check_assign {
  color: #1ecb7b;
}
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
import { commonMethods } from '../store/common-methods'

export default {
  props: ['activeInboxDetail'],

  components: {},

  data () {
    return {
      commonMethods: commonMethods,
      actionForAssign: false,
      actionForToolTips: false
    }
  },
  computed: {
    ...mapGetters(['getWorkspaceInboxTeamMembers', 'getProfile'])
  },
  mounted () {
    console.log(this.activeInboxDetail)
  },
  created () {},

  methods: {
    ...mapActions(['inboxStatusChangeAction']),

    async assignConversation (selectedUserId, teamMember) {
      if (this.actionForAssign === false) {
        if (
          selectedUserId === 0 &&
          this.activeInboxDetail.inbox_details.assigned.is_assigned === false
        ) {
          return
        }

        if (
          this.activeInboxDetail.inbox_details.assigned.assigned_to !== null &&
          selectedUserId ===
            this.activeInboxDetail.inbox_details.assigned.assigned_to.user_id
        ) {
          return
        }

        const payload = {
          platform_id: this.activeInboxDetail.platform_id,
          element_id: this.activeInboxDetail.element_details.element_id,
          workspace_id: this.activeInboxDetail.workspace_id,
          status: false,
          assigned_to: null,
          action_performed_by: {
            user_id: this.getProfile._id,
            user_name: this.getProfile.firstname,
            user_image: this.getProfile.image
          },
          action: 'assigned'
        }
        if (teamMember !== null) {
          payload.status = true
          payload.assigned_to = {
            user_id: teamMember._id,
            user_name: teamMember.full_name,
            user_image: teamMember.image
          }
        }

        this.actionForAssign = true
        const resp = await this.inboxStatusChangeAction(payload)
        if (resp.isValid) {
          this.actionForAssign = false
        } else {
          this.actionForAssign = false
        }
      }
    },
    async changeConversationStatus (action, status) {
      if (this.actionForToolTips === false) {
        const payload = {
          platform_id: this.activeInboxDetail.platform_id,
          element_id: this.activeInboxDetail.element_details.element_id,
          workspace_id: this.activeInboxDetail.workspace_id,
          status: status,
          action_performed_by: {
            user_id: this.getProfile._id,
            user_name: this.getProfile.firstname,
            user_image: this.getProfile.image
          },
          action: action
        }

        this.actionForToolTips = true
        const resp = await this.inboxStatusChangeAction(payload)
        if (resp.isValid) {
          this.actionForToolTips = false
        } else {
          this.actionForToolTips = false
        }
      }
    }
  },
  watch: {}
}
</script>
