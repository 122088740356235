<template>
  <div  class="cst-account-checkbox py-1 bg-white rounded-b-lg" style="box-shadow: 0 1px 6px 0 rgb(184 189 209 / 50%);">

    <div class="mx-7 my-2">
      <span class="font-normal text-gray-900">Publish as a <b>Profile</b> or a <b>Page</b> </span>
      <a v-b-tooltip.top-center="'You can update default selection from settings'" class="beacon ml-2" data-beacon-article-modal="622b1d88c1688a6d26a74342"
         href="#">
        <i class="far fa-question-circle"></i>
      </a>
    </div>

    <div v-for="(item, index) in account.posted_as" :key="`account_publish_as_item_${index}`"
         :class="{ 'cursor-not-allowed': !hasPermission('can_change_fb_group_publishing_method') }"
         class="cursor-pointer" @click="changeFBGroupPostedAs(item.id, account.facebook_id)">
     <div class="flex items-center px-3 py-1.5 my-1 pl-6 hover:bg-cs-foggray">
       <input
           :id="'publish_as_selection_' + item.id + account._id"
           :data-cy="'publish_as_selection_' + item.id"
           class="opacity-0 absolute h-5 w-5"
           type="radio"
           :checked="item.id === publishAs[account.facebook_id]"
           :value="item.id"
           :disabled="!hasPermission('can_change_fb_group_publishing_method')"
       />

       <span :id="'tooltip-target-' + item.id" class="flex items-center relative">
      <b-tooltip :target="'tooltip-target-' + item.id" triggers="hover">
           <div v-if="!hasPermission('can_change_fb_group_publishing_method')">
             <span>An admin for this workspace has not allowed you to change the Facebook group posting preference, please ask them to update permissions to make the changes</span>
           </div>
         </b-tooltip>
      <span class="relative">
        <img class="rounded-full object-cover" width="32" height="32" :src="item.image"
             onerror="this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
             alt="">
        <div class="bg-cs-blue absolute -right-0.5 -bottom-0.5  cst-account-checkbox__platform rounded-full w-4 h-4  flex justify-center items-center">
          <i class="fab fa-facebook-f text-white"></i>
        </div>
<!--        <i class="fab fa-facebook-f fb_bg text-white cst-account-checkbox__platform rounded-full w-4 h-4 block absolute -right-0.5 -bottom-0.5 flex justify-center items-center"></i>-->
      </span>
      <span v-if="!collapsed" class="flex flex-col ml-3">
        <span class="block font-normal text-black-100 text-sm select-none">
          {{ item.name }}
        </span>
        <span class="block text-gray-700 cst-account-checkbox__type select-none">
          {{ item.type }}
        </span>
      </span>
    </span>
       <div v-if="item.id === publishAs[account.facebook_id]"
            class="ml-auto">
         <i class="fas fa-check text-cs-green"></i>
       </div>
     </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "CstFbAccountPublishAs",
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: false
    },
    accountPlatform: {
      type: String,
      default: 'facebook',
      required: true
    },
    publishAs: {
      type: Object,
      default: (() => {
        return {}
      })
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    getIconClass() {
      switch (this.accountPlatform) {
        case 'instagram':
          return 'fab fa-instagram ins_bg'
        case 'facebook':
          return 'fab fa-facebook-f fb_bg'
        case 'twitter':
          return 'fab fa-twitter tw_bg'
        case 'linkedin':
          return 'fab fa-linkedin-in lin_bg'
        case 'youtube':
          return 'fab fa-youtube youtube_bg'
      }
    },

    /**
     * updates the "Post As" state for facebook and creates a key:value pair based on posted_id and account_id
     * @param postedId
     * @param accountId
     */
    changeFBGroupPostedAs(postedId, accountId) {
      if (!this.hasPermission('can_change_fb_group_publishing_method')) return
      this.$emit('onChangePostedAs', postedId, accountId)
    },
  }
}
</script>

<style lang="scss" scoped>
.cst-account-checkbox {
  &__type {
    font-size: 0.8rem;
  }

  &__platform {
    font-size: 0.7rem;
    text-align: center;
  }

  input + span {
    opacity: .7;
  }

  input:checked + span {
    opacity: 1;
  }
  //input + .check-icon {
  //  border-width: 1px;
  //  @apply border-solid;
  //  @apply border-gray-300
  //}
  //
}
</style>
