<template>
  <b-dropdown
    v-if="type === 'checkbox'"
    ref="dropdown"
    style="min-width: 230px"
    variant="studio-theme"
    toggle-class="min-width"
    size="lg"
    class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image"
    no-caret
  >
    <template slot="button-content">
      <span class="connected-accounts-images">
        <template>
          <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
        </template>
      </span>
      <span
        class="capitalize_text"
        style="
          font-size: 0.8rem;
          text-align: left;
          display: inline-flex;
          flex-direction: column;
          margin: 0 1rem;
          font-weight: 500;
          justify-content: flex-start;
        "
      >
        2 Accounts
        <small>Selected</small>
      </span>
      <i class="icon-dropdown-cs"></i>
    </template>

    <b-dropdown-item @click.native.capture.stop>
      <div class="field_group">
        <div class="checkbox_container">
          <label>
            Select All
            <input
              v-model="all_selected"
              type="checkbox"
              checked="checked"
              @change="$emit('all-selected')"
            />
            <span class="check"></span>
          </label>
        </div>
      </div>
    </b-dropdown-item>

    <b-dropdown-divider></b-dropdown-divider>

    <b-dropdown-item @click.native.capture.stop>
      <div class="field_group">
        <div class="checkbox_container">
          <label>
            <div class="dropdown-img-wrapper">
              <img class="selected" :src="fallbackImagePreview('T')" alt="" />
              <i class="fab fa-facebook-f fb fb_bg"></i>
            </div>
            <span class="dropdown-item-label">
              <span>Seerat Awan</span>
              <small>Profile</small>
            </span>
            <template>
              <input
                :id="'account-id-123'"
                v-model="selected_accounts"
                type="checkbox"
                :value="123"
                @change="$emit('select', 123)"
              />
              <span class="check"></span>
            </template>
          </label>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
  <b-dropdown
    v-else
    ref="dropdown"
    v-tooltip.right="
      'All scheduled and automated posts will be planned based on the default page or profile selected here.'
    "
    :disabled="
      structuredAccounts.length === 1 ||
      !hasPermission('can_change_fb_group_publishing_method')
    "
    style="min-width: 200px"
    variant="studio-theme"
    toggle-class=""
    size="md"
    class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image"
    no-caret
  >
    <template slot="button-content">
      <div class="flex w-full justify-between items-center">
        <div>
          <template>
            <img
              :src="
                selectedAccount && selectedAccount.length
                  ? fetchImage(selectedAccount[0])
                  : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              "
              alt=""
              class="selected rounded-full w-8 h-8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </template>
          <span
            class="capitalize w-full inline-flex flex-col text-left font-medium text-sm mx-4 my-0"
          >
            {{
              truncateString(
                selectedAccount && selectedAccount.length
                  ? selectedAccount[0]['name']
                  : '',
                20
              )
            }}
            <small>{{ selectedAccount[0]['type'] }}</small>
          </span>
        </div>
        <i class="icon-dropdown-cs"></i>
      </div>
    </template>

    <b-dropdown-item
      v-for="(account, key) in structuredAccounts"
      :key="key"
      @click="selectAccountHandle(account)"
    >
      <div class="field_group">
        <div class="checkbox_container">
          <label>
            <div class="dropdown-img-wrapper">
              <img
                class="selected"
                :src="fetchImage(account)"
                alt=""
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <i class="fab fa-facebook-f fb fb_bg"></i>
            </div>
            <span class="dropdown-item-label">
              <span>{{ account['name'] }}</span>
              <small>{{ account['type'] }}</small>
            </span>
            <div v-if="selectedAccount[0]['id'] === account.id"
              ><svg
                data-v-e040e266=""
                version="1.1"
                viewBox="0 0 17 12"
                xmlns="http://www.w3.org/2000/svg"
                class="fill-current w-4 h-4 text-blue-500 pointer-events-none"
              >
                <g data-v-e040e266="" fill="none" fill-rule="evenodd">
                  <g
                    data-v-e040e266=""
                    transform="translate(-9 -11)"
                    fill="#0088cc"
                    fill-rule="nonzero"
                  >
                    <path
                      data-v-e040e266=""
                      d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                    ></path>
                  </g>
                </g></svg
            ></div>
          </label>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'AccountSelectionDropdown',
  props: {
    accounts: {
      type: [Object, Array],
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected_accounts: [],
      the_selected_account: {},
    }
  },
  computed: {
    structuredAccounts() {
      return this.accounts || []
    },
    selectedAccount() {
      if (Object.keys(this.the_selected_account).length !== 0) {
        return [this.the_selected_account]
      }
      return this.structuredAccounts.filter((account) => {
        return account.default && account.default === true
      })
    },
  },
  methods: {
    fetchImage(imgObj) {
      if (imgObj) {
        if (typeof imgObj.image === 'string') {
          return imgObj.image
        } else if (imgObj.image && imgObj.image.url) {
          return imgObj.image.url
        }
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    selectAccountHandle(account) {
      this.the_selected_account = account
      this.$emit('select', account.id)
    },
  },
}
</script>
