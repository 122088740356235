<template>
  <div class="">
    <clip-loader
      v-if="getWorkspaceHashtags.loader"
      color="#4165ed"
      :size="'16px'"
      class="d-inline-block align-middle"
    ></clip-loader>
    <template v-else>
      <!-- publish case -->
      <div class="field_group" v-if="$route.name !== 'miscellaneous'">
        <label for="">Select from Saved Hashtags</label>

        <div class="field_group">
          <select
            class="sm-for-mobile"
            name=""
            id=""
            v-model="getPublishSelection.hashtag.selected"
            @change="
              changeHashtagSelectionById(getPublishSelection.hashtag.selected)
            "
            data-cy="select-hashtag"
          >
            <option value="" data-cy="create-newhashtag"
              >Select from saved Hashtags</option
            >
            <option
              :value="item._id"
              v-for="item in getWorkspaceHashtags.items"
              data-cy="existing-hashtag"
              >{{ item.name }}</option
            >
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>

      <!-- publish case -->
      <template
        v-if="
          $route.name !== 'miscellaneous' &&
          getPublishSelection.hashtag.selected
        "
      >
        <div
          class="content_preview input_field"
          v-show="getPublishSelection.hashtag.edit_mode === false"
        >
          <div class="p_content_inner">
            <div class="input_field mt-0">
              <div class="multi_select">
                <ul class="multi_tags">
                  <li
                    v-for="(item, index) in getNewWorkspaceHashtag.items"
                    :key="index"
                  >
                    <span class="value">{{ item }}</span>
                  </li>
                </ul>
              </div>
              <ul class="list_desc">
                <li class="mt-2"
                  >{{ getNewWorkspaceHashtag.count }} hashtag(s) from above will
                  be randomly chosen</li
                >
                <li
                  class="mt-2"
                  v-if="getNewWorkspaceHashtag.position === 'Append'"
                  >Hashtag(s) will be appended</li
                >
                <li class="mt-2" v-else>Hashtag(s) will be replaced</li>
              </ul>
            </div>
          </div>
          <div class="input_field text-right">
            <button class="btn" @click="setHashtagEditMode(true)">
              <span>Edit</span>
            </button>
          </div>
        </div>

        <!--for edit mode ----------->
        <div v-show="getPublishSelection.hashtag.edit_mode">
          <!-- miscellaneous case -->
          <div class="field_group field_no_placeholder">
            <template
              v-if="
                $route.name === 'miscellaneous' ||
                !getPublishSelection.hashtag.selected
              "
            >
              <input
                class="prevent-listener"
                type="text"
                placeholder="Name"
                v-model="getNewWorkspaceHashtag.name"
                data-cy="newhashtag-name"
                id="namehashtag"
              />
              <label for="namehashtag" class="label-animated">Name</label>
            </template>
          </div>

          <!-- coming in both publish and miscellenous page -->
          <div class="field_group field_no_placeholder">
            <input
              class="prevent-listener"
              placeholder="Hashtags"
              type="text"
              @keyup.enter="
                addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
              "
              @keyup.space="
                addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
              "
              @paste="pastehashtags()"
              v-model="getWorkspaceHashtags.hashtag_item"
              data-cy="newhashtags"
              id="hastagsList"
            />

            <label for="hastagsList" class="label-animated">Hashtags</label>
          </div>
          <div class="multi_select" v-if="getNewWorkspaceHashtag.items.length">
            <ul class="multi_tags">
              <li v-for="(item, index) in getNewWorkspaceHashtag.items">
                <span class="value">{{ item }}</span>
                <span
                  class="remove"
                  @click.prevent="REMOVE_NEW_WORKSPACE_HASHTAG_ITEM(index)"
                  >&times;</span
                >
              </li>
            </ul>
          </div>

          <div class="field_group d-flex align-items-center">
            <label style="min-width: 118px" class="mb-0" for=""
              >Randomly choose</label
            >
            <input
              class="w-auto prevent-listener hash_count"
              type="number"
              min="1"
              :max="getNewWorkspaceHashtag.items.length"
              v-model="getNewWorkspaceHashtag.count"
              data-cy="hashtag-number"
            />
            <label class="ml-2 mb-0" for="">hashtag(s) for each post.</label>
          </div>

          <div class="field_group">
            <label for="">Placement</label>
          </div>

          <div class="field_group">
            <label class="radio_container" data-cy="hashtag-append">
              <input
                :id="type + 'hashtags_position_append'"
                :name="type + 'hashtags_position'"
                type="radio"
                value="Append"
                v-model="getNewWorkspaceHashtag.position"
              />
              <div :for="type + 'hashtags_position_append'">
                Placement
                <span class="check"></span>
              </div>
            </label>

            <label class="radio_container">
              <input
                :id="type + 'hashtags_position_replace'"
                type="radio"
                :name="type + 'hashtags_position'"
                value="Replace"
                v-model="getNewWorkspaceHashtag.position"
              />
              <div :for="type + 'hashtags_position_replace'">
                Replace (if keyword is matched)
                <span class="check"></span>
              </div>
            </label>
          </div>
        </div>
      </template>

      <template v-else>
        <!-- miscellaneous case -->
        <div class="field_group field_no_placeholder">
          <template
            v-if="
              $route.name === 'miscellaneous' ||
              !getPublishSelection.hashtag.selected
            "
          >
            <input
              class="prevent-listener"
              type="text"
              placeholder="Name"
              v-model="getNewWorkspaceHashtag.name"
              data-cy="newhashtag-name"
              id="namehashtag"
            />
            <label for="namehashtag" class="label-animated">Name</label>
          </template>
        </div>

        <!-- coming in both publish and miscellenous page -->
        <div class="field_group field_no_placeholder">
          <input
            class="prevent-listener"
            placeholder="Hashtags"
            type="text"
            @keyup.enter="
              addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
            "
            @keyup.space="
              addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
            "
            @paste="pastehashtags()"
            v-model="getWorkspaceHashtags.hashtag_item"
            data-cy="newhashtags"
            id="hastagsList"
          />

          <label for="hastagsList" class="label-animated">Hashtags</label>
        </div>
        <div class="multi_select" v-if="getNewWorkspaceHashtag.items.length">
          <ul class="multi_tags">
            <li v-for="(item, index) in getNewWorkspaceHashtag.items">
              <span class="value">{{ item }}</span>
              <span
                class="remove"
                @click.prevent="REMOVE_NEW_WORKSPACE_HASHTAG_ITEM(index)"
                >&times;</span
              >
            </li>
          </ul>
        </div>

        <div class="field_group d-flex align-items-center">
          <label style="min-width: 118px" class="mb-0" for=""
            >Randomly choose</label
          >
          <input
            class="w-auto prevent-listener hash_count"
            type="number"
            min="1"
            :max="getNewWorkspaceHashtag.items.length"
            v-model="getNewWorkspaceHashtag.count"
            data-cy="hashtag-number"
          />
          <label class="ml-2 mb-0" for="">hashtag(s) for each post.</label>
        </div>

        <div class="field_group">
          <label for="">Placement</label>
        </div>

        <div class="field_group">
          <label class="radio_container" data-cy="hashtag-append">
            <input
              :id="type + 'hashtags_position_append'"
              :name="type + 'hashtags_position'"
              type="radio"
              value="Append"
              v-model="getNewWorkspaceHashtag.position"
            />
            <div :for="type + 'hashtags_position_append'">
              Placement
              <span class="check"></span>
            </div>
          </label>

          <label class="radio_container">
            <input
              :id="type + 'hashtags_position_replace'"
              type="radio"
              :name="type + 'hashtags_position'"
              value="Replace"
              v-model="getNewWorkspaceHashtag.position"
            />
            <div :for="type + 'hashtags_position_replace'">
              Replace (if keyword is matched)
              <span class="check"></span>
            </div>
          </label>
        </div>

        <!--<div class="field_group">-->
        <!--<label for="">Placement</label>-->
        <!--<div class="radio_input_image d-block" data-cy="hashtag-append">-->
        <!--<input :id="type+'hashtags_position_append'" :name="type+'hashtags_position'" type="radio" value="Append" v-model="getNewWorkspaceHashtag.position">-->
        <!--<label :for="type+'hashtags_position_append'" class="radio_left">Append</label>-->
        <!--</div>-->
        <!--<div class="radio_input_image d-block" data-cy="hashtag-replace">-->
        <!--<input :id="type+'hashtags_position_replace'" type="radio" :name="type+'hashtags_position'"  value="Replace" v-model="getNewWorkspaceHashtag.position">-->
        <!--<label :for="type+'hashtags_position_replace'" class="radio_left">Replace (if keyword is matched)</label>-->
        <!--</div>-->
        <!--</div>-->
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['type'],
  components: {},

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getWorkspaceHashtags',
      'getNewWorkspaceHashtag'
    ])
  },
  methods: {
    ...mapMutations(['REMOVE_NEW_WORKSPACE_HASHTAG_ITEM']),
    ...mapActions(['setHashtagEditMode', 'addWorkspaceHashtagItems']),

    pastehashtags () {
      const selfObject = this
      setTimeout(function () {
        selfObject.addWorkspaceHashtagItems(
          selfObject.getWorkspaceHashtags.hashtag_item
        )
      }, 200)
    },

    onClickButton () {
      console.log('clicked')
    }
  },

  watch: {
    'getWorkspaceHashtags.hashtag_item' (value) {
      if (value && value.indexOf(',') > -1) {
        const item = value.replace(',', '')
        this.addWorkspaceHashtagItems(item)
      }
    }
  }
}
</script>
