<template>

  <div  class="composer_component" >
    <RemovePublicationConfirmation></RemovePublicationConfirmation>
    <RemovePublicationFolderConfirmation></RemovePublicationFolderConfirmation>
    <CreatePublicationFolder></CreatePublicationFolder>
    <ReopenPlanConfirmation></ReopenPlanConfirmation>
    <RemovePublicationPostsConfirmation></RemovePublicationPostsConfirmation>
    <!--        <composer-on-boarding></composer-on-boarding>-->
    <MovePublicationFolder></MovePublicationFolder>
    <!--<social-approval-modal></social-approval-modal>-->
    <div class="component_inner">
      <!--<button class="btn" @click="$bvModal.show('socialApprovalModal')">test modal</button>-->
      <div class="c_box_wrapper">

        <!--<button data-type="socialMediaSM" data-callback="crelloImageUpload" data-apikey="SOphZOk4VJGFg3Qx9MPgg16TPovP5B5z032cWrnHkYSIPL7Kx5SQTWljFgjImToYX791ZQm8XIhx1RqcszP2dfFKvlcTwaMOZJ0P" class="design-with-crello">Create in Crello</button>-->

        <div v-if="getPlannerLoaders.editPlan">
          <beat-loader :color="'#436aff'" ></beat-loader>
        </div>
        <template v-else>
          <div class="box_center clear divider_line">

            <div class="top_heading">
              <h2>What would you like to <span>Compose?</span></h2>
              <p class="sub_text_heading">Here you can compose posts for your social media & blog and collaborate
                with your team members or clients while creating the content piece.</p>
            </div>
            <div class="d-flex justify-content-center box_align">
              <div class="c_box" >
                <div class="box_inner " data-cy="socialpost-creation"
                     @click.prevent="changePublishType('Composer Social', true)">
                  <div class="img_icon">
                    <img class="selected_img" src="../../../assets/img/composer/social_post_.svg" alt="">
                    <!--<img class="normal_img" src="../../../app/src/assets/img/composer/social_post_deactive.png" alt="">-->
                  </div>
                  <!--<i class="icon cs-file-fill"></i>-->
                  <h3 class="icon_text">Social Media Post</h3>
                </div>
              </div>

              <div class="c_box">
                <div  class="box_inner " data-cy="blogpost-creation" @click.prevent="changePublishType('Composer Article')">
                  <!--<i class="icon cs-rss"></i>-->
                  <div class="img_icon">
                    <img class="selected_img w-40" src="../../../assets/img/composer/blog_post_.svg" alt="">
                    <!--<img class="normal_img" src="../../../app/src/assets/img/composer/blog_post_deactive.png" alt="">-->

                  </div>
                  <h3 class="icon_text">Blog Post</h3>
                </div>
              </div>

              <!--<div class="c_box">-->
              <!--<div class="box_inner ">-->
              <!--<i class="icon cs-envelope-fill"></i>-->
              <!--<h3 class="icon_text">Newsletter</h3>-->
              <!--</div>-->
              <!--</div>-->

            </div>

          </div>

          <div class="publication_list d-flex align-items-start">

            <div class="publication_left">

              <div class="left_heading">
                <h3><i class="icon-Campaign"></i> My Campaign(s) <span> ({{getPublicationFolders.length + 1}})</span> </h3>

                <span class="ml-auto"  data-cy="create-campaign" @click.prevent="openCreateFolderModal">
                                       <i class="action_icon_trans md icon-add-new-member-cs"></i>
                                  </span>
              </div>

              <div class="pub_name_list">

                <div class="pub_item d-flex align-items-center" :class="{ active: !getPublications.folder.id }">

                  <template v-if="defaultCampaignEdit">
                    <div>

                      <div class="m-2 ml-2 field_group field_no_placeholder">
                        <input v-model="defaultCampaignName" type="text"
                               data-cy="campaign-editname" @keyup.enter="updateDefaultFolderName(defaultCampaignName)">
                      </div>

                      <!--<div class="basic_form edit_input test">-->
                      <!--<div class="input_field mt-0">-->
                      <!--<input class="folder_rename" type="text" v-model="defaultCampaignName"-->
                      <!--@keyup.enter="updateDefaultFolderName(defaultCampaignName)" data-cy="campaign-editname">-->
                      <!--</div>-->

                      <!--</div>-->

                    </div>
                  </template>
                  <template v-else >
                    <div class="pub_content" data-cy="campaign-selection" @click.prevent="fetchFolderPublication(null)">
                      <p class="title" data-cy="campaign-name">{{getDefaultCampaignName}}</p>
                      <p class="sub_text">{{getTotalMyPublications}} posts</p>
                    </div>
                  </template>

                  <template v-if="defaultCampaignEdit">
                    <i v-tooltip.top-center="'Save'"
                       class="action_icon sm cs-checked" data-cy="save-edited-campaignname" @click.prevent="updateDefaultFolderName(defaultCampaignName)" >
                    </i>

                  </template>
                  <template v-else>
                    <i v-tooltip.top-center="'Edit'" class="action_icon sm icon-edit-cs icon-edit-cs" data-cy="edit-campaign" @click="editDefaultCampaign">
                    </i>
                  </template>

                </div>

                <div v-if="getPublicationFolderFetchLoader" class="p-3">
                  <beat-loader :color="'#436aff'" :size="'14px'"></beat-loader>
                </div>

                <div v-for="(folder,index) in getPublicationFolders" v-else class="pub_item d-flex align-items-start"
                     :class="{ active: getPublications.folder.id === folder._id }">
                  <template v-if="folder.updateStatus">
                    <div class="pub_content w-100 pub_content_detail">
                      <div class="basic_form edit_input">
                        <div class="input_field mt-0">
                          <input v-model="folder.updateName" class="folder_rename" type="text"
                                 data-cy="campaign-editname" @keyup.enter="updateFolderName(folder)">
                        </div>

                      </div>

                    </div>
                  </template>
                  <template v-else >
                    <div class="pub_content pub_content_detail" data-cy="campaign-selection" @click.prevent="fetchFolderPublication(folder)">
                      <p class="title" data-cy="campaign-name">{{limitTextLength(folder.name,21)}}</p>
                      <p class="sub_text">{{folder.publications}} posts</p>
                    </div>
                  </template>
                  <div class="icons" :class="{ 'editing': folder.updateStatus }">
                    <template v-if="folder.updateStatus">
                      <i v-tooltip.top-center="'Save'"
                         class="pub_icon check_icon_green cs-checked" data-cy="save-edited-campaignname" @click.prevent="updateFolderName(folder)" >
                      </i>

                    </template>
                    <template v-else>
                      <i v-tooltip.top-center="'Edit'" class="action_icon_white sm icon-edit-cs" data-cy="edit-campaign" @click="changeFolderEditStatus(folder._id)">
                      </i>
                      <i v-tooltip.top-center="'Remove'"  class="action_icon_white sm icon-delete-cs"
                         data-cy="delete-campaign" @click.prevent="confirmAction('removePublicationFolder',folder._id)">
                      </i>
                    </template>

                  </div>
                </div>

              </div>

            </div>

            <div class="publication_right">

              <div class="filter_row">

                <div class="left_filter">

                  <div class="search-bar-input">
                    <div class="search-bar-inner">
                      <input v-model="search" class="small_font" type="text" placeholder='Search by title...'
                             @keyup.enter="searchPublication(search)">
                      <button class="search_btn" @click.prevent="searchPublication(search)">
                        <i class="icon-Search"></i>
                      </button>
                    </div>
                  </div>

                  <!--@umair :: what we will do here-->
                  <div v-if="getPublicationBulkSelection.ids.length > 1" class="ml-2 dropdown option_dropdown default_style_dropdown">

                    <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                      <span class="text">Bulk Action</span>
                      <span class=" ml-auto">
                                                         <i class="icon-dropdown-cs icon_last"></i>
                                                      </span>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li class="list_item_li"  @click.prevent="bulkPublicationAction('Delete')">
                          Delete
                        </li>
                        <li class="list_item_li" @click.prevent="bulkPublicationAction('Move')">
                          Move
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>

                <div class="right_filter">

                  <div class="prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown">
                    <div style="max-height: 37px;" class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                      <i class="cs-social-account icon_first" style="font-size:0.875rem;"></i>
                      <span class="text">Content Category</span>
                      <span class="square_number">{{getPublications.category.length}}/{{ getContentCategoryList.length  }}</span>
                      <span class="ml-auto">
                                                  <i class="icon-dropdown-cs icon_last"></i>
                                              </span>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right">
                      <CategoryOptions></CategoryOptions>
                    </div>

                  </div>

                  <div class="prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown">
                    <div style="max-height: 37px;" class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                      <i class="far fa-check-circle icon_first" style="font-size:0.875rem;"></i>
                      <span class="text">Status</span>
                      <span class="square_number">{{getPublications.status.length}}/9</span>
                      <span class="ml-auto">
                                                  <i class="icon-dropdown-cs icon_last"></i>
                                              </span>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right">
                      <StatusOptions></StatusOptions>
                    </div>

                  </div>

                  <div class="prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown">
                    <div style="max-height: 37px;" class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                      <div  v-if="getPublications.members.length > 0" class="circle_boxes_inline mr-2 d-flex align-items-center">
                        <template v-for="(member,index) in getWorkspaces.activeWorkspace.members" >

                          <div v-for="selected_member in getPublications.members.slice(0, 2)"  v-if="member.user && member.user_id == selected_member" v-tooltip="{ content: teamMemberTooltipHtmlById(member) , classes: 'team_tooltip' }"
                               class="circle_box member_circle_box">
                            <div v-if="member.user.image" class="img_circle composer_member_circle" :style="{ 'background': 'url(' + member.user.image + ')' }"></div>
                            <div v-else class="img_circle composer_member_circle" style="background:url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')"></div>
                          </div>

                          <div v-if="getPublications.members.length > 2 && index == getWorkspaces.activeWorkspace.members.length - 1" class="member_selected_count">
                            +{{getPublications.members.length - 2}}
                          </div>
                        </template>

                      </div>
                      <i v-else class="icon-User icon_first" style="font-size:0.875rem;"></i>

                      <span class="text">Member</span>
                      <span class="square_number">{{getPublications.members.length}}/{{getTotalActiveMember()}}</span>
                      <span class="ml-auto">
                                                    <i class="icon-dropdown-cs icon_last"></i>
                                                </span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <MemberOptions location="composer"></MemberOptions>
                    </div>

                  </div>

                  <div class="prevent_close_dropdown dropdown option_dropdown label_dropdown default_style_dropdown ">
                    <div style="max-height: 37px;" class="dropdown_header d-flex align-items-center" data-toggle="dropdown">

                      <div v-if="getPublishSelection.label.length > 0" class="circle_boxes_inline circle_boxes_medium mr-2 d-flex align-items-center">
                        <div v-for="labelId in getPublishSelection.label.slice(0, 2)" :class="getLabelColorById(labelId) + ' circle_box'"></div>

                        <div v-if="getPublishSelection.label.length > 2" class="label_selected_count">
                          +{{getPublishSelection.label.length - 2}}
                        </div>

                      </div>
                      <i v-else class="icon_first icon-Label" style="font-size:0.875rem;"></i>

                      <span class="text">Label</span>
                      <span class=" ml-auto">
                                                     <i class="icon-dropdown-cs icon_last"></i>
                                                 </span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right label_selection">
                      <LabelOptions type="Filter"></LabelOptions>
                    </div>
                  </div>

                  <div class=" dropdown option_dropdown  default_style_dropdown ">
                    <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">

                      <i class="icon_first icon-all-cs" style="font-size:0.875rem;"></i>

                      <span class="text">{{getPublications.type}}</span>
                      <span class=" ml-auto">
                                                     <i class="icon-dropdown-cs icon_last"></i>
                                                 </span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li class="list_item_li" @click.prevent="changeComposerPostType('All Posts')">All Posts</li>
                        <li class="list_item_li" @click.prevent="changeComposerPostType('Social Posts')">Social Posts</li>
                        <li  class="list_item_li" @click.prevent="changeComposerPostType('Blog Posts')">Blog Posts</li>
                      </ul>
                    </div>
                  </div>

                  <!--@umair :: what we will do here-->
                  <div class="dropdown option_dropdown default_style_dropdown hide">

                    <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">

                      <i class="sorting_icon icon_first cs-filter "></i>
                      <span class="text">Filter by label</span>
                      <span class="square_number">2</span>
                      <span class=" ml-auto">
                                                     <i class="icon-dropdown-cs icon_last">
                                                     </i>
                                                  </span>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li class="list_item">
                          <a class="dropdown-item">Abstract Content</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- layout section -->
                  <div class="view_change_btn p-0 flex-shrink-0" >
                    <div class="view_change_btn_inner grey_inner_bg">
                      <p v-tooltip.top-center="'Grid View'" class="grid_icon " :class="{ 'active': toggleViewStatus === 'grid' }"
                         @click="toggleView('grid')" >
                        <i class="icon-groups-cs"></i>
                      </p>
                      <p v-tooltip.top-center="'List View'" class="list_icon" :class="{ 'active': toggleViewStatus === 'list' }" @click="toggleView('list')" >
                        <i class="icon-Listview_icon"></i>
                      </p>
                    </div>
                  </div>

                </div>

              </div>

              <div v-if="toggleViewStatus === 'list'" class=" publication_list_view">
                <div  class="social_table">

                  <div class="table_trans_head">
                    <table>
                      <thead>
                      <tr>
                        <th style="width:40px;">

                          <div class="field_group top_min_25px">
                            <div class="checkbox_container">
                              <label for="selectAllListComposer">
                                <input id="selectAllListComposer" v-model="getPublicationBulkSelection.selectAll" type="checkbox"
                                       @change="composerBulkSelectAll">
                                <span class="check"></span>
                              </label>
                            </div>
                          </div>

                        </th>
                        <th style="width: 500px;">Title</th>
                        <th class="text-center" style=" width: 200px;">Platforms Selected</th>
                        <th class="text-center" style="width: 130px;">Status</th>
                        <th class="text-cente space_nowrap" style="width: 130px;">Members</th>
                        <th class="text-center" style="width: 130px;">Labels</th>
                        <th style="text-align: center;width: 150px;">Actions</th>
                      </tr>
                      </thead>

                      <tbody>

                      <tr v-if="getPublicationFetchLoader">
                        <td colspan="7" style="padding: 50px 20px;">
                          <div class="p-3">
                            <beat-loader :color="'#436aff'" ></beat-loader>
                          </div>
                        </td>
                      </tr>
                      <template v-else>
                        <!--class="is_hide_client"-->
                        <tr v-for="(item,index) in getPublications.publications"  :class="{ 'is_hide_client': item.publish_time_options && item.publish_time_options.hide_client,'p_list_selected': ifItemSelected(item._id) }">
                          <td>
                            <div class="field_group top_min_25px">
                              <div class="checkbox_container">
                                <label :for="item._id">
                                  <input :id="item._id" v-model="getPublicationBulkSelection.ids"
                                         :value="item._id" type="checkbox"  @change="checkSelectedAll">
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>

                          </td>
                          <td>
                            <div v-if="item.type === 'Composer Article'" class="row_tag">Blog Post</div>
                            <div v-if="item.type === 'Composer Social'" class="row_tag">Social Post</div>
                            <div class="post_content">

                              <template  v-if="isEditAble(item)" >
                                <template v-if="item.type === 'Composer Article'">
                                  <p  v-if="item.post && item.post.title && (item.post.title.trim()).length > 0" class="edit desc" @click.prevent="editPlan(item)">
                                    {{item.post.title}}
                                  </p>
                                  <p  v-else-if="item.post && item.post.description && processDescription(item.post.description,50) && (processDescription(item.post.description,50)).length > 0" class="edit desc"
                                      @click.prevent="editPlan(item)">
                                    {{processDescription(item.post.description,50) }}
                                  </p>
                                  <p  v-else class="edit desc" @click.prevent="editPlan(item)">UNTITLED</p>
                                </template>
                                <p  v-if="item.type === 'Composer Social'" class="edit desc" @click.prevent="editPlan(item)"  v-html="processDescription(headingText(item,'social'),50)">
                                  <!--social title-->
                                </p>
                              </template>
                              <template v-else>
                                <template v-if="item.type === 'Composer Article'">
                                  <p v-if="item.post && item.post.title && (item.post.title.trim()).length > 0" class="desc">
                                    {{item.post.title}}
                                  </p>
                                  <p  v-else-if="item.post && item.post.description && processDescription(item.post.description,50) &&
                                                                                   (processDescription(item.post.description,50)).length > 0"
                                      class="edit desc">
                                    {{processDescription(item.post.description,50) }}
                                  </p>
                                  <p v-else class="desc">UNTITLED</p>
                                </template>
                                <p v-if="item.type === 'Composer Social'" class="desc"  v-html="processDescription(headingText(item,'social'),50)"></p>
                              </template>

                              <p v-if="item.type === 'Composer Article' && item.post" class="date">Last edited: {{getUtcTimeAgoFromNow(item['post']['updated_at'])}}</p>
                              <p v-if="item.type === 'Composer Social'" class="date">Last edited: {{getUtcTimeAgoFromNow(item['updated_at'])}}</p>

                            </div>

                          </td>
                          <template v-if="item.blog_selection || item.account_selection">
                            <SelectedPlatforms class-name="composer-social-icon" :blog_selection="item.blog_selection" :account_selection="item.account_selection" :secondary="item.secondary_blogs"></SelectedPlatforms>
                          </template>
                          <td v-else> - </td>

                          <td class="text-center">
                            <div class="status">
                              <p :class="item['partially_failed'] ? 'partial status_color status_text' : item['status'] + ' status_color status_text'">
                                {{
                                  item['partially_failed'] ? 'Partially Failed' : item['render_class']['tooltip']
                                }}</p>
                              <p v-if="item['execution_time']" class="text">
                                {{ getWorkspaceTimeZoneTime(item['execution_time']['date'], 'MMM DD, hh:mm a') }}</p>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="d-inline-block circle_boxes_inline">
                              <template v-if="item.members && item.members.length > 0">
                                <div v-for="member in item.members" v-tooltip="{ content: teamMemberTooltipHtmlById(selectedTeamMemberDetailById(member)) , classes: 'team_tooltip' }"  class="circle_box">
                                  <div v-if="teamMemberImageById(member)"  class="img_circle" :style="{ 'background': 'url(' + teamMemberImageById(member) + ')' }"></div>
                                </div>
                              </template>
                              <template v-else> - </template>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="d-inline-block circle_boxes_inline labels_inline">

                              <template v-if="item.labels && item.labels.length > 0" >
                                <div v-for="label in item.labels" v-if="getLabelById(label)"
                                     v-tooltip="getLabelById(label).name"
                                     class="circle_box"
                                     :class="[getLabelById(label).color]"></div>
                              </template>
                              <template v-else> - </template>
                            </div>
                          </td>

                          <td class="text-right action-table" >
                            <!--<div class="list-eye-ico" v-if="getTeamMembership === 'team' && item.status === 'draft'-->
                            <!--&& item.publish_time_options.plan_status === 'Approved' && item.type === 'Composer Social'">-->
                            <!--<div class="min_width dropdown_header" data-toggle="dropdown">-->
                            <!--<i v-if="item.publish_time_options.hide_client" class="fa fa-eye-slash" title="Hide from clients"></i>-->
                            <!--<i v-else class="fa fa-eye" title="Unhide from clients"></i>-->
                            <!--</div>-->

                            <!--<div class="dropdown-menu dropdown-menu-right custom-popup visibility-popup" id="test">-->
                            <!--<div class="popup-inner">-->

                            <!--<template v-if="item.publish_time_options.hide_client">-->
                            <!--<p >Make this post visible to clients?</p>-->
                            <!--<div class="buttons">-->
                            <!--<button type="">No</button>-->
                            <!--<button @click.prevent="hideFromClientAction(item,false)">Yes</button>-->
                            <!--</div>-->
                            <!--</template>-->
                            <!--<template v-else>-->
                            <!--<p >Make this post Invisible to clients?</p>-->
                            <!--<div class="buttons">-->
                            <!--<button type="">No</button>-->
                            <!--<button @click.prevent="hideFromClientAction(item,true)">Yes</button>-->
                            <!--</div>-->
                            <!--</template>-->

                            <!--</div>-->
                            <!--</div>-->
                            <!--</div>-->

                            <div class="btn_dropdown btn-group default_style_dropdown">

                              <template v-if="getTeamMembership === 'team' && item.status === 'draft'
                                                                             && item.publish_time_options && item.publish_time_options.plan_status === 'Approved' && item.type === 'Composer Social'">
                                <div class="min_width dropdown_header" data-toggle="dropdown">

                                  <i v-if="item.publish_time_options.hide_client" v-tooltip.top-center="hideFromClientMessage(item)" class="action_icon md far fa-eye-slash"></i>
                                  <i v-else v-tooltip.top-center="hideFromClientMessage(item)" class="action_icon md far fa-eye"></i>

                                </div>
                                <div class="dropdown-menu dropdown-menu-right custom-popup visibility-popup" >
                                  <div class="popup-inner">

                                    <template v-if="item.publish_time_options.hide_client">
                                      <p >Make this post visible to clients?</p>
                                      <div class="buttons">
                                        <button type="">No</button>
                                        <button @click.prevent="hideFromClientAction(item,false)">Yes</button>
                                      </div>
                                    </template>
                                    <template v-else>
                                      <p >Make this post Invisible to clients?</p>
                                      <div class="buttons">
                                        <button type="">No</button>
                                        <button @click.prevent="hideFromClientAction(item,true)">Yes</button>
                                      </div>
                                    </template>

                                  </div>
                                </div>
                              </template>

                              <i v-if="isEditAble(item)" v-tooltip.top-center="'Edit'" class="action_icon md icon-edit-cs" @click.prevent="editPlan(item)"></i>

                              <i v-if="statusClass(item) === 'published' || statusClass(item) === 'scheduled'" v-tooltip.top-center="'Duplicate'" class="action_icon md far fa-clone" @click.prevent="clonePlan(item)"></i>

                              <i v-if="item.status !== 'queued'" v-tooltip.top-center="'Delete'" class="action_icon md icon-delete-cs" @click.prevent="$bvModal.show(item._id)"></i>

                              <i v-tooltip.top-center="'Move'" class="action_icon md far fa-arrows-alt" @click.prevent="openMoveCampaignModel(item['folderId'],[item['_id']])"></i>

                              <template>
                                <b-modal :id='item._id' ref="defaultWorkspaceModal"
                                         no-close-on-backdrop modal-class="alertBox defaultWorkspaceModal"
                                         hide-footer hide-header>
                                  <div class="modal_body">
                                    <p>Are you sure you want to delete this post? </p>
                                    <div class="col-sm-12 footer_box">
                                      <button class="btn gray cancel_btn"
                                              @click="$bvModal.hide(item._id)">No
                                      </button>

                                      <button class="btn red confirm_btn btn_loader lsoader_right"
                                              @click.prevent="removePublicationModel(item,index,$bvModal)">
                                        <span>Yes</span>
                                      </button>

                                    </div>
                                  </div>
                                </b-modal>
                              </template>

                              <!--<button v-if="isEditAble(item)"-->
                              <!--class="btn btn_square"-->
                              <!--v-tooltip.top-center="'Edit'"-->
                              <!--@click.prevent="editPlan(item)">-->

                              <!--</button>-->

                              <!--<button v-if="statusClass(item) === 'published' || statusClass(item) === 'scheduled'"-->
                              <!--class="btn btn_square"-->
                              <!--v-tooltip.top-center="'Duplicate'"-->
                              <!--@click.prevent="clonePlan(item)">-->
                              <!--<i class="far fa-clone"></i>-->
                              <!--</button>-->

                              <!--<button-->
                              <!--class="btn btn_square"-->
                              <!--v-tooltip.top-center="'Delete'"-->
                              <!--@click.prevent="removePublicationModel(item,index)">-->
                              <!--<i class="icon-delete-cs"></i>-->
                              <!--</button>-->

                              <!--<button class="btn btn_square"-->
                              <!--v-tooltip.top-center="'Move'"-->
                              <!--@click.prevent="openMoveCampaignModel(item['folderId'],[item['_id']])">-->
                              <!--&lt;!&ndash;<i class="far fa-folders"></i>&ndash;&gt;-->
                              <!--<i class="far fa-arrows-alt"></i>-->
                              <!--</button>-->

                              <!--<button v-if="isEditAble(item)" type="button" class="btn_click btn "-->
                              <!--@click.prevent="editPlan(item)">Edit</button>-->
                              <!--<button type="button" class="btn_arrow btn  dropdown-toggle dropdown-toggle-split"-->
                              <!--data-toggle="dropdown">-->
                              <!--<i class="dropdown_arrow icon_last cs-angle-down ">-->
                              <!--</i>-->
                              <!--</button>-->
                              <!--<div class="dropdown-menu dropdown-menu-right">-->
                              <!--<li class="list_item" v-if="statusClass(item) === 'published' || statusClass(item) === 'scheduled'"-->
                              <!--@click.prevent="clonePlan(item)"><a >Duplicate</a></li>-->
                              <!--<li class="list_item"-->
                              <!--@click.prevent="removePublicationModel(item,index)"><a >Delete</a></li>-->
                              <!--<li class="list_item"><a  @click.prevent="openMoveCampaignModel(item['folderId'],[item['_id']])">Move</a></li>-->
                              <!--</div>-->
                            </div>
                          </td>
                        </tr>
                        <tr  v-if="!getPublicationFetchScrollLoader && getPublications.publications.length === 0">
                          <td colspan="7">
                            <content-available-state :type="'no_results'">
                              <h2 slot="headline">No posts found</h2>
                              <p slot="text">You have not created any posts yet.</p>
                            </content-available-state>
                          </td>
                        </tr>
                        <tr v-else-if="!getPublicationFetchScrollLoader && getPublications.publications.length <= 12">
                          <!--<td colspan="5">-->
                          <!--<no-results-found :message="publicationMessage(true)"></no-results-found>-->
                          <!--</td>-->
                        </tr>
                        <tr v-else >
                          <td colspan="7" >
                            <InfiniteLoading  ref="infiniteLoading" @infinite="onInfinitePublicationList">
                                                                    <span slot="spinner" class="p-3 d-block">
                                                                        <beat-loader :color="'#436aff'"></beat-loader>
                                                                    </span>
                              <NoResultsFound :message="publicationMessage(true)"></NoResultsFound>
                              <span slot="no-results">
                                                                        <content-available-state :type="'no_results'">
                                                                          <h2 slot="headline">

                                                                              No posts found</h2>
                                                                          <p slot="text">
                                                                              <template v-if="hasFiltersOrSearchSelectedForPublication">
                                                                                  No results found for your selected filters.
                                                                              </template>
                                                                              <template v-else>
                                                                                You have not created any posts yet.
                                                                              </template>
                                                                          </p>
                                                                        </content-available-state>
                                                                    </span>
                              <span slot="no-more">
                                                                         <content-available-state :type="'no_results'">
                                                                              <h2 slot="headline">
                                                                                  No more posts found</h2>
                                                                              <p slot="text">
                                                                                  <template v-if="hasFiltersOrSearchSelectedForPublication">
                                                                                      No more results found for your selected filters.
                                                                                  </template>
                                                                                  <template v-else>
                                                                                    No more results are available.
                                                                                  </template>
                                                                              </p>
                                                                        </content-available-state>
                                                                    </span>

                            </InfiniteLoading>
                          </td>
                        </tr>

                      </template>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <template   v-if="toggleViewStatus === 'grid'" >
                <div v-if="getPublicationFetchLoader"  class="p-3">
                  <beat-loader :color="'#436aff'"></beat-loader>
                </div>

                <template v-else>
                  <div class="publication_grid_view row_10">
                    <div v-for="(item,index) in getPublications.publications"  class="pub_grid_box">
                      <div class="inner" :class="{ 'is_hide_client': item.publish_time_options && item.publish_time_options.hide_client,'p_list_selected': ifItemSelected(item._id) }">
                        <div class="top_detail field_group">
                          <div class="grid_checkboxes">

                            <div class="checkbox_container">
                              <label :for="item._id">
                                <input :id="item._id" v-model="getPublicationBulkSelection.ids"
                                       :value="item._id" type="checkbox"  @change="checkSelectedAll">
                                <span class="check"></span>
                              </label>
                            </div>
                          </div>

                          <div class="detail_left">
                            <div v-if="item.type == 'Composer Article'" class="row_tag">Blog Post</div>
                            <div v-if="item.type == 'Composer Social'" class="row_tag">Social Post</div>
                          </div>
                          <div class="detail_right ml-auto">
                            <div class="circle_boxes_inline mr-5">

                              <template v-if="item.members && item.members.length > 0">
                                <div v-for="member in item.members" v-tooltip="{ content: teamMemberTooltipHtmlById(selectedTeamMemberDetailById(member)) , classes: 'team_tooltip' }"  class="circle_box">
                                  <div v-if="teamMemberImageById(member)"  class="img_circle" :style="{ 'background': 'url(' + teamMemberImageById(member) + ')' }"></div>
                                </div>
                              </template>

                              <template v-else> </template>
                            </div>
                          </div>
                        </div>

                        <div class="post_content">

                          <template  v-if="isEditAble(item)" >
                            <template v-if="item.type === 'Composer Article'">
                              <p v-if="item.post.title && (item.post.title.trim()).length > 0"  class="desc" @click.prevent="editPlan(item)">
                                {{item.post.title}}
                              </p>
                              <p  v-else-if="item.post && item.post.description && processDescription(item.post.description,50) &&
                                                                           (processDescription(item.post.description,50)).length > 0"  class="edit desc"
                                  @click.prevent="editPlan(item)">
                                {{processDescription(item.post.description,50) }}
                              </p>
                              <p v-else  class="desc" @click.prevent="editPlan(item)">UNTITLED</p>
                            </template>
                            <p v-if="item.type === 'Composer Social'" class="desc"  @click.prevent="editPlan(item)" v-html="processDescription(headingText(item,'social'),50)"></p>
                          </template>
                          <template v-else>
                            <template v-if="item.type === 'Composer Article'">
                              <p v-if="item.post.title && (item.post.title.trim()).length > 0" class="desc">
                                {{item.post.title}}
                              </p>
                              <p  v-else-if="item.post && item.post.description && processDescription(item.post.description,50) &&
                                                                           (processDescription(item.post.description,50)).length > 0"
                                  class="edit desc">
                                {{processDescription(item.post.description,50) }}
                              </p>
                              <p v-else class="desc">UNTITLED</p>
                            </template>
                            <p v-if="item.type === 'Composer Social'" class="desc"  v-html="processDescription(headingText(item,'social'),50)"></p>
                          </template>

                          <p v-if="item.type === 'Composer Article'" class="date">Last edited: {{getUtcTimeAgoFromNow(item['post']['updated_at'])}}</p>
                          <p v-if="item.type === 'Composer Social'" class="date">Last edited: {{getUtcTimeAgoFromNow(item['updated_at'])}}</p>
                        </div>

                        <div class="top_detail d-flex align-items-start">
                          <div class="detail_left">
                            <div class="status">
                              <p :class="item['status'] + ' status_color status_text'" >{{item['render_class']['tooltip']}}</p>
                              <p class="text" >
                                <template v-if="item.status !== 'draft' && item['execution_time']">
                                  {{getWorkspaceTimeZoneTime(item['execution_time']['date'],'MMM DD, hh:mm a')}}
                                </template>
                                <template v-else> &nbsp; </template>
                              </p>
                            </div>
                          </div>
                          <div class="detail_right ml-auto">

                            <div class="btn_dropdown btn-group default_style_dropdown">

                              <template v-if="getTeamMembership === 'team' && item.status === 'draft'
                                                                     && item.publish_time_options && item.publish_time_options.plan_status === 'Approved' && item.type === 'Composer Social'">

                                <div class="min_width dropdown_header" data-toggle="dropdown">

                                  <i v-if="item.publish_time_options.hide_client" v-tooltip.top-center="hideFromClientMessage(item)" class="action_icon md far fa-eye-slash"></i>
                                  <i v-else v-tooltip.top-center="hideFromClientMessage(item)" class="action_icon md far fa-eye"></i>

                                </div>

                                <div id="test" class="dropdown-menu dropdown-menu-right custom-popup visibility-popup">
                                  <div class="popup-inner">

                                    <template v-if="item.publish_time_options.hide_client">
                                      <p >Make this post visible to clients?</p>
                                      <div class="buttons">
                                        <button type="">No</button>
                                        <button @click.prevent="hideFromClientAction(item,false)">Yes</button>
                                      </div>
                                    </template>
                                    <template v-else>
                                      <p >Make this post Invisible to clients?</p>
                                      <div class="buttons">
                                        <button type="">No</button>
                                        <button @click.prevent="hideFromClientAction(item,true)">Yes</button>
                                      </div>
                                    </template>

                                  </div>
                                </div>
                              </template>

                              <i v-if="isEditAble(item)" v-tooltip.top-center="'Edit'" class="action_icon md icon-edit-cs" @click.prevent="editPlan(item)"></i>

                              <i v-if="statusClass(item) === 'published' || statusClass(item) === 'scheduled'" v-tooltip.top-center="'Duplicate'" class="action_icon md far fa-clone" @click.prevent="clonePlan(item)"></i>

                              <i v-tooltip.top-center="'Delete'" class="action_icon md icon-delete-cs" @click.prevent="$bvModal.show(item._id)"></i>

                              <i v-tooltip.top-center="'Move'" class="action_icon md far fa-arrows-alt" @click.prevent="openMoveCampaignModel(item['folderId'],[item['_id']])"></i>

                              <template>
                                <b-modal :id='item._id' ref="defaultWorkspaceModal"
                                         no-close-on-backdrop modal-class="alertBox defaultWorkspaceModal"
                                         hide-footer hide-header>
                                  <div class="modal_body">
                                    <p>Are you sure you want to delete this post? </p>
                                    <div class="col-sm-12 footer_box">
                                      <button class="btn gray cancel_btn"
                                              @click="$bvModal.hide(item._id)">No
                                      </button>

                                      <button class="btn red confirm_btn btn_loader lsoader_right"
                                              @click.prevent="removePublicationModel(item,index,$bvModal)">
                                        <span>Yes</span>
                                      </button>

                                    </div>
                                  </div>
                                </b-modal>
                              </template>

                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <template v-if="!getPublicationFetchScrollLoader && getPublications.publications.length === 0">
                    <content-available-state :type="'no_results'">
                      <h2 slot="headline">No posts found</h2>
                      <p slot="text">You have not created any posts yet.</p>
                    </content-available-state>
                  </template>
                  <template v-else-if="!getPublicationFetchScrollLoader && getPublications.publications.length <= 12">
                    <!--<no-results-found :message="publicationMessage(true)"></no-results-found>-->
                  </template>
                  <template v-else>
                    <InfiniteLoading ref="infiniteLoading" @infinite="onInfinitePublicationList" >
                                                    <span slot="spinner" class="p-3 d-block">
                                                        <beat-loader :color="'#436aff'"></beat-loader>
                                                    </span>
                      <NoResultsFound :message="publicationMessage(true)"></NoResultsFound>
                      <span slot="no-results">
                                                   <content-available-state :type="'no_results'">
                                                      <h2 slot="headline">

                                                          No posts found</h2>
                                                      <p slot="text">
                                                          <template v-if="hasFiltersOrSearchSelectedForPublication">
                                                              No results found for your selected filters.
                                                          </template>
                                                          <template v-else>
                                                            You have not created any posts yet.
                                                          </template>
                                                      </p>
                                                    </content-available-state>
                                                    </span>
                      <span slot="no-more">
                                                       <content-available-state :type="'no_results'">
                                                          <h2 slot="headline">
                                                              No more posts found</h2>
                                                          <p slot="text">
                                                              <template v-if="hasFiltersOrSearchSelectedForPublication">
                                                                  No more results found for your selected filters.
                                                              </template>
                                                              <template v-else>
                                                                No more results are available.
                                                              </template>
                                                          </p>
                                                        </content-available-state>
                                                    </span>

                    </InfiniteLoading>
                  </template>

                </template>

              </template>

            </div>

          </div>
        </template>

      </div>
    </div>
  </div>

</template>

<style lang="less">
.is_hide_client {
  background: rgba(255, 175, 46, 0.15) !important;
}

.camp_rac_button {

  button {
    background-color: rgba(58, 69, 87, 0.08);
    border: 1px solid transparent;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 12px;
    border-radius: 5px;

  }
  button:hover {
    background: #4165ed;
  }
  button:hover i {
    background: #4165ed;
    color: white;
  }

  i {
    color: rgba(58, 69, 87, 0.5);
    display: block;
  }
  i:hover {
    color: white;
    display: block;
  }

}
.btn_dropdown .dropdown-menu {
  min-width: 365px;
  padding: 12px 0;
}
.eye-ico {
  position: relative;
  left: 1px;

}
.status_color.partial {
  color: #c00000;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { confirmation } from '@common/store/mutation-types'
import { pusherSocketPublish } from '@common/lib/pusher'
import { getStatusClass, planHeadText } from '@common/lib/planner'
import { parseDescription } from '@common/lib/helper'
import InfiniteLoading from 'vue-infinite-loading'
import {
  COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED,
  COMPOSER_PUBLICATION_FOLDER_UPDATE_FAIL,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import { composer } from '../store/composer-mutation-type'

import NoResultsFound from '../../discovery/components/topics/NoResultsFound'
import MemberOptions from '../../publish/components/options/MemberOptions'
import StatusOptions from '../../publish/components/options/StatusOptions'
import CategoryOptions from '../../publish/components/options/CategoryOptions'
import LabelOptions from '../../publish/components/options/LabelOptions'
import ReopenPlanConfirmation from '../../planner/components/dialogs/ReopenPlanConfirmation.vue'
import { updateDefaultPublicationFolderNameURL } from '../config/api-utils'
import RemovePublicationConfirmation from './RemovePublicationConfirmation'
import CreatePublicationFolder from './CreatePublicationFolder'
import ComposerOnBoarding from './ComposerOnBoarding'
import MovePublicationFolder from './MovePublicationFolder'
import RemovePublicationFolderConfirmation from './RemovePublicationFolderConfirmation'
import RemovePublicationPostsConfirmation from './confirmation/RemovePublicationPostsConfirmation'
import SelectedPlatforms from './PublicationSelectedPlatforms.vue'
import {EventBus} from "@common/lib/event-bus";


export default {
  components: {
    NoResultsFound,
    InfiniteLoading,
    MemberOptions,
    LabelOptions,
    ComposerOnBoarding,
    CreatePublicationFolder,
    MovePublicationFolder,
    RemovePublicationConfirmation,
    RemovePublicationFolderConfirmation,
    RemovePublicationPostsConfirmation,
    ReopenPlanConfirmation,
    SelectedPlatforms,
    StatusOptions,
    CategoryOptions,
  },

  data () {
    return {
      toggleViewStatus: 'list',
      search: '',
      labels_channel: '',
      defaultCampaignEdit: false,
      defaultCampaignName: ''
    }
  },
  mounted () {
    this.labels_channel = pusherSocketPublish.subscribe(`labels_${this.getActiveWorkspace._id}`)
    this.bindPusherLabels(this.labels_channel, 'composer')
    this.initializeComposerSection(this.getPublications.folder)
  },
  beforeDestroy () {
    this.labels_channel.unbind('store_label')
    this.labels_channel.unsubscribe()
  },

  created () {
    // this.initializeComposerSection(this.getPublications.folder)
  },
  computed: {
    ...mapGetters(['getPublicationFolderFetchLoader', 'getPublicationFolders', 'getPublicationFetchLoader',
      'getPublications', 'getPublicationFetchScrollLoader', 'getTotalMyPublications', 'getWorkspaces',
      'getPublishSelection', 'getPlannerLoaders', 'getPublicationBulkSelection', 'getContentCategoryList']),
    hasFiltersOrSearchSelectedForPublication () {
      return (this.getPublishSelection.members.length > 0 || this.getPublishSelection.label.length > 0 || (this.getPublications.search && (this.getPublications.search.trim())).length > 0)
    },
    getDefaultCampaignName () {
      if (this.getWorkspaces.activeWorkspace && this.getWorkspaces.activeWorkspace.default_campaign_name) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign'
    }

  },

  methods: {
    // getPublications () {},

    editDefaultCampaign () {
      this.defaultCampaignName = this.getDefaultCampaignName
      this.defaultCampaignEdit = true
    },
    updateDefaultFolderName (folder) {
      console.debug('Method:updateDefaultFolderName', folder)

      if (folder.trim() === '') {
        this.alertMessage(COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED, 'error')
        return false
      }

      const payload = { workspace_id: this.getWorkspaces.activeWorkspace._id, name: folder }

      this.postRequest(updateDefaultPublicationFolderNameURL, payload, response => {
        if (response.data.status === true) {
          // this.getWorkspaces.activeWorkspace.default_campaign_name = folder
          this.$set(this.getWorkspaces.activeWorkspace, 'default_campaign_name', folder)
          this.defaultCampaignEdit = false
        } else {
          this.alertMessage(COMPOSER_PUBLICATION_FOLDER_UPDATE_FAIL, 'error')
        }
      }, error => {
        console.debug('Exception in updateDefaultFolderName ', error)
        this.alertMessage(UNKNOWN_ERROR, 'error')
      })
    },
    hideFromClientMessage (item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    isEditAble (item) {
      return !!(item.status !== 'queued' && item.status !== 'processing' && (this.statusClass(item) !== 'published' || item.blog_reference))
    },
    statusClass (item) {
      return getStatusClass(item)
    },

    headingText (item) {
      return planHeadText(item, 'blog')
    },
    checkBulkSelection (id) {
      return this.getPublicationBulkSelection.ids.some(item => { return item === id })
    },
    processDescription (description, limit) {
      return parseDescription(description, limit)
    },

    toggleView (type) {
      this.refetchPublications()
      this.toggleViewStatus = type
    },
    onInfinitePublicationList ($state) {
      if (this.getPublicationFetchScrollLoader) {
        console.log('onInfinitePublicationList')
        this.$store.commit(composer.SET_PUBLICATIONS_PAGE, this.getPublications.page + 1)
        this.fetchPublications($state)
      }
    },
    publicationMessage (more = false) {
      console.debug('Method:publicationMessage', more)
      if (this.getPublishSelection.members.length > 0 || this.getPublishSelection.label.length > 0 || (this.getPublications.search && (this.getPublications.search.trim())).length > 0) {
        return (more) ? 'No more posts found for the selected filters.' : 'No posts found for the selected filters.'
      }
      return (more) ? 'No more posts found.' : 'No posts found'
    },

    composerBulkSelectAll () {
      let ids = []
      if (this.getPublicationBulkSelection.selectAll) {
        if (this.getPublications.publications) ids = this.getPublications.publications.map(item => item._id)
      }
      this.$store.commit(composer.SET_PUBLICATIONS_BULK_IDS, ids)
    },
    checkSelectedAll () {
      let selectAll = false
      if (this.getPublications.publications.length === this.getPublicationBulkSelection.ids.length) selectAll = true
      this.$store.commit(composer.SET_PUBLICATIONS_SELECT_ALL, selectAll)
    },

    bulkPublicationAction (action) {
      console.debug('METHOD:bulkPublicationAction')

      if (action === 'Delete') {
        this.$store.commit(confirmation.SET_PAYLOAD, action)
        $('#removePublications').modal('show')
      } else if (action === 'Move') {
        this.openMoveCampaignModel(null, this.getPublicationBulkSelection.ids)
      }
    },
    clonePlan (item) {
      console.debug('Method::clonePlan', item)
      item.stateObject = this
      this.$store.dispatch('clonePlan', item)
    },
    editPlan (item) {
      console.debug('Method::editPlan', item)
      item.stateObject = this
      this.$store.dispatch('editPlan', item)
    },
    openCreateFolderModal () {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
      EventBus.$emit('createFolder')
    },
    ifItemSelected (item) {
      return this.getPublicationBulkSelection.ids && this.getPublicationBulkSelection.ids.includes(item)
    }
  },

  watch: {
    'getActiveWorkspace._id' (value) {
      console.log('unsubscribe the channel and subscribe for the new workspace value')
      if (this.labels_channel) this.labels_channel.unsubscribe()
      this.labels_channel = pusherSocketPublish.subscribe(`labels_${value}`)
      this.bindPusherLabels(this.labels_channel)
    }
  }
}
</script>

<style lang="less">
.action-table {
  margin-right: initial;
  text-align: right !important;
}
.list-eye-ico {
  padding: 7px;
  display: inline-grid;
  i {
    cursor: pointer;
  }
}
.visibility-popup {
  position: relative;
  will-change: transform;
  z-index: 333;
  top: -25px;
  box-shadow: 1px 1px 7px #5f5f5f3b;
  left: 238px;
  .popup-inner {
    padding: 0em 1em !important;
  }
  .dropdown_header  i {
    cursor: pointer;
  }
  .buttons {
    float: right !important;
    button {
      cursor: pointer;
      margin: 5px !important;
      padding: 0px 6px !important;
      background: #5e72ff;
      border: 0px;
      color: white;
      border-radius: 3px;
    }
  }
}
.visibility-popup .buttons {
  float: right !important;
}
.member_selected_count {
  display: inline-block;
  margin-left: -7px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  background: #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  width: 25px;
  height: 25px;
}
.label_selected_count {
  display: inline-block;
  margin-left: -7px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  background: #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  width: 25px;
  height: 25px;
}
.composer_member_circle {
  width: 25px !important;
  height: 25px !important;
}
.member_circle_box {
  height: 25px !important;
  width: 25px !important;
}
</style>
