<template>
  <div
    class="modal fade add_utm_modal normal_modal movePublicationModal"
    id="movePublicationModal"
  >
    <div class="modal-dialog w-1/3">
      <div class="modal-content">
        <div class="modal_head">
          <h4>Move to other Campaign Folder</h4>
          <button
            type="button"
            class="modal_head__close"
            id="close"
            data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div class="field_group">
            <label>Select Campaign Folder</label>
            <select name="" id="folder_name" v-model="getMoveCampaign.id">
              <!--<option value="" selected="true" disabled="disabled">Select Campaign</option>-->
              <option value=""
                >{{ truncateString(getDefaultCampaignName, 40) }} ({{
                  getTotalMyPublications
                }})</option
              >
              <option
                v-for="(folder, key) in getPublicationFolders"
                :key="key"
                :value="folder._id"
                >{{ truncateString(folder.name, 40) }} ({{
                  folder.publications
                }})
              </option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>

          <div class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getMoveCampaign.loader"
              :class="{ disabled: getMoveCampaign.loader }"
              @click.prevent="movePostCampaign()"
            >
              Move
              <clip-loader
                :color="'#ffffff'"
                :size="'12px'"
                v-if="getMoveCampaign.loader"
              ></clip-loader>
            </button>

            <a
              class="pull-right create_campaign_link"
              @click.prevent="openCreateModal()"
              href=""
              >Create New Campaign Folder</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { composer } from '../store/composer-mutation-type'
import {EventBus} from "@common/lib/event-bus";

export default {
  computed: {
    ...mapGetters([
      'getPublicationFolders',
      'getMoveCampaign',
      'getTotalMyPublications'
    ]),
    getDefaultCampaignName () {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.default_campaign_name
      ) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign'
    }
  },
  created () {},
  methods: {
    ...mapActions([]),
    openCreateModal () {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, true)
      $('#movePublicationModal').modal('hide')
      EventBus.$emit('createFolder')
    }
  }
}
</script>
