export function getIconClass(accountPlatform) {
  switch (accountPlatform) {
    case 'instagram':
      return 'fab fa-instagram ins_bg'
    case 'facebook':
      return 'fab fa-facebook-f fb_bg'
    case 'twitter':
      return 'fab fa-twitter tw_bg'
    case 'linkedin':
      return 'fab fa-linkedin-in lin_bg'
    case 'youtube':
      return 'fab fa-youtube youtube_bg'
    case 'tumblr':
      return 'fab fa-tumblr bg-tumblr'
    default:
      return ''
  }
}

export function getStatusBgClass(status) {
  switch (status) {
    case 'published':
      return 'bg-status-published'
    case 'draft':
      return 'bg-status-draft'
    case 'scheduled':
      return 'bg-status-scheduled'
    case 'partially_failed':
      return 'bg-status-partially-failed'
    case 'failed':
      return 'bg-status-failed'
    case 'rejected':
      return 'bg-status-rejected'
    case 'under_review':
      return 'bg-status-under-review'
    case 'missed_review':
      return 'bg-status-missed-review'
    default:
      return ''
  }
}

export function getStatusColorClass(status) {
  switch (status) {
    case 'published':
      return 'text-status-published'
    case 'draft':
      return 'text-status-draft'
    case 'scheduled':
      return 'text-status-scheduled'
    case 'partially_failed':
      return 'text-status-partially_failed'
    case 'failed':
      return 'text-status-failed'
    case 'rejected':
      return 'text-status-rejected'
    case 'under_review':
      return 'text-status-under_review'
    case 'missed_review':
      return 'text-status-missed_review'
    case 'processing':
      return 'text-status-in_progress'
    default:
      return ''
  }
}

export function getStatusColorCode(status) {
  switch (status) {
    case 'published':
      return '#35d57b'
    case 'draft':
      return '#00204e'
    case 'scheduled':
      return '#fec107'
    case 'processing':
      return '#C5CAFE'
    case 'partially_failed':
      return '#ff5e5e'
    case 'failed':
      return '#b72f46'
    case 'rejected':
      return '#b68103'
    case 'under_review':
      return '#85f29f'
    case 'missed_review':
      return '#a173f3'
    default:
      return ''
  }
}
