var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade autoVariationGenerate normal_modal",attrs:{"id":"autoVariationGenerate"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal_body basic_form m_t_15"},[_c('div',{staticClass:"input_field mt-0"},[_c('label',[_vm._v("Variations will be generated from this URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getEvergreenVariationData.variationURL),expression:"getEvergreenVariationData.variationURL"}],staticClass:"disable_item",attrs:{"type":"url","placeholder":"Your fetch URL...","readonly":"readonly","disabled":"disabled"},domProps:{"value":(_vm.getEvergreenVariationData.variationURL)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.getEvergreenVariationData, "variationURL", $event.target.value)}}})]),(
            !_vm.getEvergreenVariationData.fetchLoader &&
            _vm.getEvergreenVariationData.variationPreview.length > 0
          )?_c('div',{staticClass:"post_preview_main mt-4"},_vm._l((_vm.getEvergreenVariationData.variationPreview),function(post,index){return _c('div',{key:index,staticClass:"post_preview_box"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(post)}}),_c('div',{staticClass:"btn_block"},[_c('button',{staticClass:"btn red_bg",on:{"click":function($event){$event.preventDefault();return _vm.removeVariationPreview(index)}}},[_c('span',[_vm._v("Remove")])]),_c('span',{staticClass:"char_left"},[_vm._v("Characters: "),_c('span',{},[_vm._v(_vm._s(post.length))])])])])])}),0):_vm._e(),_c('div',{staticClass:"input_field d-flex align-items-center"},[(_vm.getEvergreenVariationData.variationPreview.length === 0)?_c('button',{staticClass:"btn blue_gradient large_btn btn_loader loader_right",class:{ disabled: _vm.getEvergreenVariationData.fetchLoader },attrs:{"disabled":_vm.getEvergreenVariationData.fetchLoader},on:{"click":function($event){$event.preventDefault();return _vm.fetchVariationUrl()}}},[_c('span',[_vm._v("Generate")]),(_vm.getEvergreenVariationData.fetchLoader)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):_vm._e()],1):_c('button',{staticClass:"btn gradient_btn large_btn btn_loader loader_right",class:{ disabled: _vm.getEvergreenVariationData.addLoader },attrs:{"disabled":_vm.getEvergreenVariationData.addLoader},on:{"click":function($event){$event.preventDefault();return _vm.addVariationFromPreview(
                _vm.getEvergreenVariationData.variationPreview
              )}}},[_c('span',[_vm._v("Add")]),(_vm.getEvergreenVariationData.addLoader)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):_vm._e()],1),(_vm.getEvergreenVariationData.variationPreview.length)?_c('p',{staticClass:"ml-auto variation_count"},[_vm._v("Total variation: "),_c('strong',[_vm._v(_vm._s(_vm.getEvergreenVariationData.variationPreview.length))])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_head d-flex align-items-center"},[_c('h2',[_vm._v("Auto Generate Variations")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }