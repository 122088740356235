<template>
  <div v-show="loading" class="v-spinner">
    <div class="v-beat v-beat-odd" :style="spinnerStyle"> </div
    ><div class="v-beat v-beat-even" :style="spinnerStyle"> </div
    ><div class="v-beat v-beat-odd" :style="spinnerStyle"> </div>
  </div>
</template>

<script>
export default {
  name: 'BeatLoader',

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#436aff',
    },
    size: {
      type: String,
      default: '15px',
    },
    margin: {
      type: String,
      default: '2px',
    },
    radius: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      spinnerStyle: {
        backgroundColor: this.color,
        height: this.size,
        width: this.size,
        margin: this.margin,
        borderRadius: this.radius,
      },
    }
  },
}
</script>
