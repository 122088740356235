const state = {
  utms: {
    fetchUtms: false,
    search: '',
    items: [],
    currentUtm: [],
    loader: false,
    saveLoader: false
  }
}

// getters
const getters = {
  getUtmsStatus: (state) => {
    return state.utms.fetchUtms
  },
  getUtms: (state) => {
    return state.utms
  },
  getCurrentUtms: (state) => {
    return state.utms.currentUtm
  },
  getUtmLoader: (state) => {
    return state.utms.loader
  },
  getSaveUtmLoader: (state) => {
    return state.utms.saveLoader
  }
}

// actions
const actions = {
  setUtmsStatus ({ commit }, status) {
    commit('setUtmsStatus', status)
  },
  setUtms ({ commit }, data) {
    commit('setUtms', data)
  },
  setCurrentUtm ({ commit }, data) {
    commit('setCurrentUtm', data)
  },
  setUtmLoader ({ commit }, status) {
    commit('setUtmLoader', status)
  },
  setSaveUtmLoader ({ commit }, status) {
    commit('setSaveUtmLoader', status)
  },
  resetUtm ({ commit }) {
    commit('resetUtm')
  }
}

// mutations
const mutations = {
  setUtmsStatus (state, status) {
    state.utms.fetchUtms = status
  },
  setUtms (state, data) {
    state.utms.items = data
  },
  setCurrentUtm (state, data) {
    state.utms.currentUtm = data
  },
  setUtmLoader (state, status) {
    state.utms.loader = status
  },
  setSaveUtmLoader (state, status) {
    state.utms.saveLoader = status
  },
  resetUtm (state) {
    state.utms.fetchUtms = false
    state.utms.items = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
