<template>
  <b-modal
    id="createTagModalSetting"
    hide-footer
    hide-header
    modal-class="createLabelModal"
  >
    <div class="modal_head">
      <h4 v-if="this.action === 'create'">Create Tag</h4>
      <h4 v-else-if="this.action === 'update'">Edit Tag</h4>
      <h4 v-else>Merge Tags</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('createTagModalSetting')"
      >
        &times;
      </button>
    </div>

    <div v-if="this.action !== 'create'" class="setting_tag_list">
      <div class="tag_list_new filter_label old_label_list ml-4">
        <template v-for="tag in selectedTags">
          <label :for="tag._id + 'create'" class="checkbox_left">
            <span
              class="mb-1 mr-1 label_tag_round_dark"
              :class="[tag.tag_color]"
            >
              {{ tag.tag_name }}
            </span>
          </label>
        </template>
      </div>
    </div>

    <div class="modal_body">
      <div class="create_label_common">
        <div class="field_group">
          <label for="" class="label-animated">Name</label>
          <input
            type="text"
            v-model="tagName"
            placeholder="Enter tag name..."
          />
        </div>
        <div class="field_group">
          <label for="" class="p15">Choose Color</label>
          <div class="create_label_common__label_boxes">
            <template v-for="(value, index) in labelsCount">
              <div class="create_label_common__box_item">
                <input
                  :id="'color_' + index"
                  type="radio"
                  name="color_select"
                  :v-bind="tagColor"
                  v-model="tagColor"
                  @input="tagColor = 'color_' + index"
                  class="icon"
                  :value="'color_' + index"
                />
                <label
                  :for="'color_' + index"
                  :class="'item_inner color_' + index"
                >
                  <i
                    class="check_icon_planner far fa-check d-flex h-100 align-items-center justify-content-center"
                  >
                  </i>
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="label_bottom">
        <button
          v-if="this.action === 'create'"
          @click="createTag"
          class="btn btn-studio-theme-space"
        >
          <span>Create</span>
        </button>
        <button
          v-else-if="this.action === 'update'"
          @click="createTag"
          class="btn btn-studio-theme-space"
        >
          <span>Update</span>
        </button>
        <button v-else @click="createTag" class="btn btn-studio-theme-space">
          <span>Merge</span>
        </button>
        <button
          @click="$bvModal.hide('createTagModalSetting')"
          class="btn btn-studio-theme-grey-space"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['action', 'selectedTags', 'id'],
  components: {},
  data () {
    return {
      labelsCount: 20,
      tagName: null,
      tagColor: null
    }
  },
  computed: {},

  mounted () {
    this.tagName =
      this.action === 'update' ? this.selectedTags[0].tag_name : null
    this.tagColor =
      this.action === 'update' ? this.selectedTags[0].tag_color : null
  },
  created () {},
  methods: {
    ...mapActions(['createTagDetails']),
    async createTag () {
      const workspaceId = this.getActiveWorkspace._id
      if (this.tagName == null || this.tagColor == null) {
        this.alertMessage('Tag name or tag color is missing.', 'error')
        return
      }

      var tagIdList = []

      this.selectedTags.forEach((item) => {
        tagIdList.push(item._id)
      })

      const payload = {
        workspace_id: workspaceId,
        name: this.tagName,
        color: this.tagColor,
        tag_ids:
          this.action === 'update'
            ? tagIdList[0]
            : this.action === 'merge'
              ? tagIdList
              : null
      }

      const createTagResp = await this.createTagDetails(payload)

      if (createTagResp.isValid) {
        this.tagColor = null
        this.tagName = null
        this.selectedTags = null
        this.$emit('create-tag', {
          tag: createTagResp.tagDetails
        })
        this.$bvModal.hide('createTagModalSetting')

        // this.close()
      } else {
        this.alertMessage(createTagResp.message, 'error')
        // commonMethods.showToast(this, 0, createTagResp.message)
      }
    }
  },
  watch: {
    id: async function (newVal, oldVal) {
      // watch it
      this.tagName =
        this.action === 'update' ? this.selectedTags[0].tag_name : null
      this.tagColor =
        this.action === 'update' ? this.selectedTags[0].tag_color : null
    }
  }
}
</script>
