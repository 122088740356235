import { store } from '@state/base'
import proxy from '@src/modules/common/lib/http-common'
import ActivityBasedTimer from './activity-based-timer'
const versioningName = 'contentstudio-app-version'
export const versioningTimer = new ActivityBasedTimer()
let oldSource = localStorage.getItem(versioningName)

async function isNewerVersionAvailable() {
  console.log('isNewVersionAvailable OldSource Value', oldSource)
  let freshSource = null
  await proxy
    .get(`${process.env.VUE_APP_BASE_URL}version.json`)
    .then((res) => {
      if (res.data && res.data.hash) {
        // freshSource = '123321321321'
        freshSource = res.data.hash
        localStorage.setItem(versioningName, res.data.hash)
      }
    })
    .catch(() => {})

  if (oldSource === null) {
    oldSource = freshSource
    return false
  }

  if (oldSource === freshSource) {
    return false
  }

  oldSource = freshSource
  return true
}

versioningTimer.setInterval({
  async callback() {
    const newVersionAvailable = await isNewerVersionAvailable()
    console.log('newVersionAvailable', newVersionAvailable)
    if (!newVersionAvailable) {
      return
    }
    console.log('newVersionAvailable setting state value', newVersionAvailable)
    store.commit('setIsNewVersionAvailable', true)
    console.log(
      'newVersionAvailable after setting state value',
      newVersionAvailable
    )
  },
  // Normal interval is once every 10 minutes
  interval: 1000 * 60 * 5,
  // Forced interval is once per day
  forcedInterval: 1000 * 60 * 60 * 24,
})
