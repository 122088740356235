var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"bookmarkModal","hide-footer":"","hide-header":"","modal-class":"bookmarkModal  normal_modal"},on:{"hidden":_vm.clearTimeout}},[_c('div',{staticClass:"modal_head d-flex align-items-center"},[_c('h2',[_vm._v("Bookmark Conversation")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('bookmarkModal')}}},[_vm._v("× ")])]),_c('div',{staticClass:"modal_body basic_form"},[_c('div',{staticClass:"conversation_messages"},[(_vm.loader)?_c('beat-loader',{attrs:{"color":'#436aff'}}):[_vm._l((_vm.messages),function(message){return [(
              message.event_type === 'assigned' ||
              message.event_type === 'marked_done' ||
              message.event_type === 'archived'
            )?[_c('div',{key:message.uuid,staticClass:"sep_line conversation_notification",attrs:{"id":message.uuid}},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"user_block d-flex align-items-center"},[_c('div',{staticClass:"user_picture"},[(
                        message.event_details.user_image === '' ||
                        message.event_details.user_image == null
                      )?_c('div',{staticClass:"img",style:('background: url(' +
                        _vm.commonMethods.getDefaultImage() +
                        ')')}):_c('div',{staticClass:"img",style:('background: url(' +
                        message.event_details.user_image +
                        ')')})]),_c('div',{staticClass:"user_content"},[_c('p',{staticClass:"name"},[_c('strong',[_vm._v(_vm._s(message.event_details.user_name))]),_vm._v(_vm._s(message.message)),_c('strong',[_vm._v(_vm._s(message.event_type === 'assigned' ? message.assigned_to !== null ? message.assigned_to.user_name + '.' : '' : ''))]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" - "+_vm._s(_vm.commonMethods.timesAgo(message.created_time)))])])])])])])]:_c('div',{key:message.uuid,staticClass:"message",class:[
              _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                ? 'm_in'
                : 'm_out',
              _vm.bookmarkedMessage.uuid === message.uuid
                ? 'highlighted activeMessage animated shake'
                : '',
            ],attrs:{"id":message.uuid}},[_c('div',{staticClass:"profile_picture d-flex align-items-start",class:[
                _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                  ? ''
                  : 'justify-content-end',
              ]},[(_vm.commonMethods.isIncomingMessage(_vm.temp, message))?_c('div',{staticClass:"picture_block picture_block_background"},[_c('div',{staticClass:"img",style:('background: url(' +
                    _vm.commonMethods.getImageForUser(_vm.temp, message) +
                    ')')})]):_vm._e(),_c('div',{staticClass:"text_block"},[(
                    message.attachment != null &&
                    message.attachment.length > 0
                  )?[_vm._l((message.attachment),function(attachment){return _c('div',{key:attachment.id},[(attachment.type === 'text/plain')?[_c('div',{staticClass:"__text",class:[
                          _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                            ? '_user_chat'
                            : '',
                        ]},[_c('div',{staticClass:"_file d-flex align-items-start"},[_c('div',{staticClass:"file_icon"},[_c('i',{staticClass:"fal fa-file-pdf"})]),_c('div',{staticClass:"file_detail"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(attachment.name))])])])]),_c('p',{staticClass:"sub_text"},[_vm._v(" "+_vm._s(_vm.commonMethods.timesAgo(message.created_time)))])]:[(attachment.type.indexOf('image') >= 0)?[_c('div',{staticClass:"__text",class:[
                            _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                              ? '_user_chat'
                              : '',
                          ]},[_c('div',{staticClass:"_images"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],attrs:{"src":attachment.url,"height":"100"}})])])]:(attachment.type.indexOf('video') >= 0)?[_c('div',{staticClass:"__text",class:[
                            _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                              ? '_user_chat'
                              : '',
                          ]},[_c('div',{staticClass:"_video"},[_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":attachment.url,"type":"video/mp4"}}),_c('source',{attrs:{"src":attachment.url,"type":"video/ogg"}}),_vm._v(" Your browser does not support HTML5 video. ")])])])]:(attachment.type.indexOf('audio') >= 0)?[_c('div',{staticClass:"__text",class:[
                            _vm.commonMethods.isIncomingMessage(_vm.temp, message)
                              ? '_user_chat'
                              : '',
                          ]},[_c('div',{},[_c('audio',{attrs:{"id":"myAudio","controls":""}},[_c('source',{attrs:{"src":attachment.url,"type":"audio/ogg"}}),_c('source',{attrs:{"src":attachment.url,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")])])])]:_vm._e(),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(message.created_time)))])]],2)}),_c('span',{staticClass:"bookmarked bottom"},[(message.is_starred)?_c('i',{staticClass:"fa fa-star marked"}):_vm._e()])]:(message.event_type === 'note')?[_c('div',{staticClass:"__text _user_chat",staticStyle:{"background-color":"rgb(251, 252, 225)"}},[_vm._v(_vm._s(message.message))]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(message.created_time)))]),_c('span',{staticClass:"bookmarked bottom"},[(message.is_starred)?_c('i',{staticClass:"fa fa-star marked"}):_vm._e()])]:(message.sticker)?[_c('div',{staticClass:"__text _user_chat"},[_c('div',{staticClass:"sticker"},[_c('img',{attrs:{"src":message.sticker}})])]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(message.created_time)))]),_c('span',{staticClass:"bookmarked bottom"},[(message.is_starred)?_c('i',{staticClass:"fa fa-star marked"}):_vm._e()])]:[_c('div',{staticClass:"__text _user_chat"},[_vm._v(_vm._s(message.message))]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(message.created_time)))]),_c('span',{staticClass:"bookmarked bottom"},[(message.is_starred)?_c('i',{staticClass:"fa fa-star marked"}):_vm._e()])]],2),(
                  !_vm.commonMethods.isIncomingMessage(_vm.temp, message) &&
                  message.event_type !== 'assigned' &&
                  message.event_type !== 'marked_done' &&
                  message.event_type !== 'archived'
                )?_c('div',{staticClass:"picture_block picture_block_background"},[_c('div',{staticClass:"img",style:('background: url(' +
                    _vm.commonMethods.getImageForUser(_vm.temp, message) +
                    ')')})]):_vm._e()])])]})]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }