<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
        :get-accounts="getAccounts"
        :get-date="getDate"
        :get-previous-date="getPreviousDate"
        :style="{
        // eslint-disable-next-line vue/camelcase
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
        :updating="fetching_data"
        type="facebook"
    ></AnalyticsFilterBar>

    <div
        v-if="getPlatformAccounts('facebook').length <= 0 && !fetching_data"
        class="analytics-main-errors"
    >
      <img
          alt=""
          draggable="false"
          src="../../assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect Facebook Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getPlatformAccounts('facebook').length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selected_account.state === 'Added'">
        <AnalyticsLoading :name="selected_account.name"></AnalyticsLoading>
      </template>
      <template v-else>
        <FacebookTabs v-if="!getFetchSocialStatus">
          <!-- overview tab -->
          <template v-if="selected_account" slot="overview">
            <OverviewTab
                :account="selected_account"
                :date="date"
                :get-overview-loader="getOverviewLoader"
                :previous_date="previous_date"
            />
          </template>

          <!-- demographics tab -->
          <template slot="demographics">
            <DemographicsTab
                :account="selected_account"
                :date="date"
                :get-demographics-loader="getDemographicsLoader"
                :previous_date="previous_date"
            />
          </template>

          <!-- posts tab -->
          <template slot="posts">
            <PostsTab
                :account="selected_account"
                :date="date"
                :get-posts-loader="getPostsLoader"
                :previous_date="previous_date"
            />
          </template>
        </FacebookTabs>

        <div
            v-if="findFacebookAccount(selected_account.facebook_id)"
            class="analytics-content-sync-box"
        >
          <!--          <template v-if="selected_account.last_analytics_updated_at">-->
          <!--            <p class="mr-4">Updated {{ timesAgo(selected_account.last_analytics_updated_at) }}</p>-->
          <!--          </template>-->
          <!--          <a class="btn btn-studio-theme-transparent-grey " href="javascript:;" @click="reFetchData()">-->
          <!--            <template v-if="getSyncServiceStatus===true  || selected_account.state === 'Syncing'">-->
          <!--              <i class="fas fa-sync mr-1"></i> <span>  Syncing...</span>-->
          <!--            </template>-->
          <!--            <template v-else >-->
          <!--              <i class="fas fa-sync mr-1"></i> <span>  Sync</span>-->
          <!--            </template>-->
          <!--          </a>-->
        </div>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
          alt=""
          draggable="false"
          src="../../assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import FacebookTabs from '@src/modules/analytics/views/facebook/components/FacebookTabs'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import moment from 'moment'

// Tabs
import OverviewTab from '@src/modules/analytics/views/facebook/tabs/OverviewTab'
import DemographicsTab from '@src/modules/analytics/views/facebook/tabs/DemographicsTab'
import PostsTab from '@src/modules/analytics/views/facebook/tabs/PostsTab'

import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { facebook } from '@src/modules/integration/store/states/mutation-types'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { SYNC_ERROR, UNKNOWN_ERROR } from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'AnalyticsOverview',
  components: {
    AnalyticsLoading,
    AnalyticsFilterBar,
    FacebookTabs,
    OverviewTab,
    DemographicsTab,
    PostsTab
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return {
      facebook_accounts: [],
      selected_account: [],
      date: '',
      previous_date: '',
      fetching_data: false,
      analytics_channel: ''
    }
  },
  computed: {
    ...mapGetters([
      'getSyncServiceStatus',
      'getFetchSocialStatus',
      'getProfile'
    ])
  },
  created () {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.resetState()

      const pusher = pusherAuthSocketAnalytics()
      this.analytics_channel = pusher.subscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
      if (this.selected_account.facebook_id) {
        this.bindSocketListeners()
      }
    })
  },
  mounted () {
    const pusher = pusherAuthSocketAnalytics()
    this.analytics_channel = pusher.subscribe(
      'analytics-channel-' + this.getActiveWorkspace._id
    )
    if (this.selected_account.facebook_id) {
      this.bindSocketListeners()
    }
  },
  beforeDestroy () {
    this.removeSocketListners(this.selected_account.facebook_id)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
        'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account.facebook_id
      )
      this.analytics_channel.unsubscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  methods: {
    ...mapActions(['syncUpdateService']),
    resetState () {
      this.fetching_data = false
      this.facebook_accounts = []
      this.selected_account = []
      this.date = ''
      this.previous_date = ''
      this.analytics_channel = ''
    },
    getAccounts (account) {
      this.selected_account = account
      console.log('acoount changed')
      console.log(this.selected_account)
    },
    getDate (date) {
      this.date = date
    },
    getPreviousDate (date) {
      this.previous_date = date
    },
    getOverviewLoader (value) {
      this.fetching_data = value
    },
    getDemographicsLoader (value) {
      this.fetching_data = value
    },
    getPostsLoader (value) {
      this.fetching_data = value
    },
    async reFetchData () {
      if (this.selected_account.state === 'Syncing') {
        return
      }
      if (
        this.hourDifference(this.selected_account.last_analytics_updated_at) < 4
      ) {
        this.alertMessage(SYNC_ERROR, 'error')
        return
      }
      const payload = {
        selected_account: this.selected_account.facebook_id,
        channel: 'facebook'
      }
      const resp = await this.syncUpdateService(payload)
      if (resp.status) {
        this.setFBState(
          resp.state,
          this.selected_account.facebook_id,
          this.selected_account.last_analytics_updated_at
        )
      }
      console.log(resp)
    },
    removeSocketListners (value) {
      console.log('removing socket for facebook ... ')
      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners () {
      const event =
        'syncing-' +
        this.getActiveWorkspace._id +
        '-' +
        this.selected_account.facebook_id
      console.log(event)
      this.analytics_channel.bind(event, (data) => {
        console.log('message data --> ', data)
        this.setFBState(
          data.state,
          data.account,
          data.last_analytics_updated_at
        )
      })
    },
    // eslint-disable-next-line camelcase
    setFBState (state, checkFor, last_analytics_updated_at) {
      var updatedItems = []
      for (const item in this.getFacebookPagesList) {
        const update = this.getFacebookPagesList[item]
        if (update.facebook_id === checkFor) {
          update.state = state
          // eslint-disable-next-line camelcase
          update.last_analytics_updated_at = last_analytics_updated_at
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'facebook',
        all_item_setter: facebook.SET_ALL_ACCOUNTS,
        item_setter: facebook.SET_ACCOUNTS
      })
    }
  },
  watch: {
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
      }
    }
  },
}
</script>
