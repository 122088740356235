<template>
  <div
    class="topics-filters-pane__container__right_section"
    v-if="getTopicContentType == 'article'"
  >
    <div
      class="view_change_btn d-flex align-items-center mb-3"
      v-if="getTopicModule === 'content' && getTopicContentType === 'article'"
    >
      <div class="view_left">
        <h3
          class="topics-filters-pane__container__right_section__total_results"
          v-if="getPostsTotal > 0"
          >{{ $filters.numberToCommas(getPostsTotal) }}
          <template v-if="getPostsTotal == 1"> Result </template>
          <template v-else> Results </template>
        </h3>
      </div>
      <topics-filter-sort :isTopic="true" />
      <!-- don't show these filters if the user has selected the type favorite or archived -->
      <template
        v-if="getTopicType !== 'favorite' && getTopicType !== 'archived'"
      >
        <!-- region values are not added, we need to add those values and then remove the check getTopictype ~='curated' -->
        <topics-filter-date :isTopic="true" v-if="!getTopicCreationModal" />
        <template v-if="$route.params.module !== 'curated'">
          <topics-filter-region :isTopic="true" />
          <topics-filter-language :isTopic="true" />
          <div
            v-tooltip.top-center="'Length'"
            class="dropdown article_post_dropdown option_dropdown default_style_dropdown hide"
          >
            <filter-type :isTopic="true"></filter-type>
          </div>
        </template>
      </template>

      <div class="topics-filters-pane__container__right_section__view_change">
        <span
          v-tooltip.top-center="'Grid View'"
          class="grid_icon"
          @click.prevent="changeLayout('grid')"
          :class="{ active: getTopicsLayout === 'grid' }"
        >
          <i class="icon-groups-cs"></i>
        </span>
        <span
          class="list_icon"
          v-tooltip.top-center="'List View'"
          @click.prevent="changeLayout('list')"
          :class="{ active: getTopicsLayout === 'list' }"
        >
          <i class="icon-Listview_icon"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FilterType from '../../filters/FilterType'
import FilterSort from '../../filters/FilterSort'
import TopicsFilterSort from '../../filters/TopicsFilterSort'
import TopicsFilterDate from '../../filters/TopicsFilterDate'
import TopicsFilterRegion from '../../filters/TopicsFilterRegion'
import TopicsFilterLanguage from '../../filters/TopicsFilterLanguage'
import FilterRegion from '../../filters/FilterRegion'
import FilterLanguage from '../../filters/FilterLanguage'
import FilterDate from '../../filters/FilterDate'

export default {
  components: {
    FilterType,
    FilterSort,
    FilterRegion,
    FilterLanguage,
    FilterDate,
    TopicsFilterSort,
    TopicsFilterDate,
    TopicsFilterRegion,
    TopicsFilterLanguage
  },
  computed: {
    ...mapGetters([
      'getTopicContentType',
      'getPostsTotal',
      'getTopicType',
      'getTopicCreationModal',
      'getTopicModule'
    ])
  }
}
</script>
