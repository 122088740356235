<script>
import { mapGetters } from 'vuex'
import { planner } from '../../store/mutation-types'

export default {
  data: function () {
    return {
      onlyLink: false
    }
  },
  computed: {
    ...mapGetters(['getPlans', 'getPlansCount', 'getActivePlannerFilters']),
    getScheduledPostCount () {
      return this.getPlansCount.scheduled
    },
    getPublishedPostCount () {
      return this.getPlansCount.published
    },
    getPartiallyPublishedPostCount () {
      return this.getPlansCount.partially_failed
    },
    getFailedPostCount () {
      return this.getPlansCount.failed
    },
    getRejectedPostCount () {
      return this.getPlansCount.rejected
    },
    getUnderReviewPostCount () {
      return this.getPlansCount.review
    },
    getMissedReviewPostCount () {
      return this.getPlansCount.missed
    },
    getDraftPostCount () {
      return this.getPlansCount.draft
    }
  },
  methods: {
    changePlansStatusFilter (type) {
      console.debug('Method:changePlansStatusFilter')
      let statuses = []
      if (this.$route.query.filter_id && !this.$route.query.statuses) {
        statuses = this.$parent.getFiltersStatuses.length
          ? this.$parent.getFiltersStatuses
          : this.getPlans.check_all_statuses
            ? [
              'scheduled',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft'
            ]
            : []
        this.getActivePlannerFilters.statuses = []
      } else {
        statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : this.getPlans.check_all_statuses
            ? [
              'scheduled',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft'
            ]
            : []
      }
      statuses.indexOf(type) >= 0
        ? statuses.splice(statuses.indexOf(type), 1)
        : statuses.push(type)
      this.$store.commit(planner.SET_CHECK_ALL_STATUSES, statuses.length === 7)
      if (statuses.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, statuses: statuses.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.statuses
      this.$router.push({ query: query })
    },
    changePlansStatusOnlyFilter (type) {
      this.getActivePlannerFilters.statuses = []
      this.$store.commit(planner.SET_CHECK_ALL_STATUSES, false)
      this.$router.push({ query: { ...this.$route.query, statuses: type } })
    },

    selectAllStatus () {
      console.debug('Method:selectAllStatus')
      this.getActivePlannerFilters.statuses = []
      let statuses = []
      if (this.getPlans.check_all_statuses) {
        statuses = [
          'scheduled',
          'published',
          'failed',
          'rejected',
          'under_review',
          'missed_review',
          'draft'
        ]
      }
      if (statuses.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, statuses: statuses.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.statuses
      this.$router.push({ query: query })
      this.$store.dispatch('refetchPlans', this.$route)
    },
    isStatusSelected (status) {
      return (
        this.getPlans.check_all_statuses ||
        this.$parent.getFiltersStatuses.indexOf(status) >= 0
      )
      // return (!this.$route.query.statuses && this.getPlans.check_all_statuses) || (this.$route.query.statuses && this.$route.query.statuses.indexOf(status) >= 0) || this.getPlans.check_all_statuses
    }
  }
}
</script>
<template>
  <ul class="inner">
    <li class="list_item_li select_all_li">
      <div class="ui_check_container">
        <input
          id="all_plans"
          v-model="getPlans.check_all_statuses"
          type="checkbox"
          @change="selectAllStatus()"
        />
        <span class="check"></span>
        <label for="all_plans" class="checkbox_right">
          <span class="">Select All</span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="scheduled_plans"
          :checked="isStatusSelected('scheduled')"
          value="Scheduled"
          type="checkbox"
          @change="changePlansStatusFilter('scheduled')"
        />
        <span class="check"></span>
        <label for="scheduled_plans" class="checkbox_right">
          <span
            >Scheduled
            <a
              @click.prevent="changePlansStatusOnlyFilter('scheduled')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getScheduledPostCount">{{
            getScheduledPostCount
          }}</span>
          <span class="status scheduled far fa-clock"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li with_status">
      <div class="ui_check_container showlink">
        <input
          id="published_plans"
          :checked="isStatusSelected('published')"
          value="Published"
          type="checkbox"
          @change="changePlansStatusFilter('published')"
        />
        <span class="check"></span>
        <label for="published_plans" class="checkbox_right">
          <span
            >Published
            <a
              @click.prevent="changePlansStatusOnlyFilter('published')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getPublishedPostCount">{{
            getPublishedPostCount
          }}</span>
          <span class="status published far fa-check-circle"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li with_status">
      <div class="ui_check_container showlink">
        <input
          id="partially_published_plans"
          :checked="isStatusSelected('partial')"
          value="partial"
          type="checkbox"
          @change="changePlansStatusFilter('partial')"
        />
        <span class="check"></span>
        <label for="partially_published_plans" class="checkbox_right">
          <span
            >Partially Failed
            <a
              @click.prevent="changePlansStatusOnlyFilter('partial')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span
            class="checkbox_counter"
            v-if="getPartiallyPublishedPostCount"
            >{{ getPartiallyPublishedPostCount }}</span
          >
          <span
            class="status failed fas fa-adjust"
            style="transform: rotate(270deg)"
          ></span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="failed_plans"
          :checked="isStatusSelected('failed')"
          value="Failed"
          type="checkbox"
          @change="changePlansStatusFilter('failed')"
        />
        <span class="check"></span>
        <label for="failed_plans" class="checkbox_right">
          <span
            >Failed
            <a
              @click.prevent="changePlansStatusOnlyFilter('failed')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getFailedPostCount">{{
            getFailedPostCount
          }}</span>
          <span class="status failed far fa-ban"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="rejected_plans"
          :checked="isStatusSelected('rejected')"
          value="Rejected"
          type="checkbox"
          @change="changePlansStatusFilter('rejected')"
        />
        <span class="check"></span>
        <label for="rejected_plans" class="checkbox_right">
          <span
            >Rejected
            <a
              @click.prevent="changePlansStatusOnlyFilter('rejected')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getRejectedPostCount">{{
            getRejectedPostCount
          }}</span>
          <span class="status reject far fa-times-circle"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="under_review_plans"
          :checked="isStatusSelected('under_review')"
          value="Under Review"
          type="checkbox"
          @change="changePlansStatusFilter('under_review')"
        />
        <span class="check"></span>
        <label for="under_review_plans" class="checkbox_right">
          <span
            >Under Review
            <a
              @click.prevent="changePlansStatusOnlyFilter('under_review')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getUnderReviewPostCount">{{
            getUnderReviewPostCount
          }}</span>
          <span class="status underReview far fa-eye"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="missed_review_plans"
          :checked="isStatusSelected('missed_review')"
          value="Missed Review"
          type="checkbox"
          @change="changePlansStatusFilter('missed_review')"
        />
        <span class="check"></span>
        <label for="missed_review_plans" class="checkbox_right">
          <span
            >Missed Review
            <a
              @click.prevent="changePlansStatusOnlyFilter('missed_review')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getMissedReviewPostCount">{{
            getMissedReviewPostCount
          }}</span>
          <span class="status missedReview far fa-eye-slash"></span>
        </label>
      </div>
    </li>

    <li class="list_item_li">
      <div class="ui_check_container showlink">
        <input
          id="draft_plans"
          :checked="isStatusSelected('draft')"
          value="Draft"
          type="checkbox"
          @change="changePlansStatusFilter('draft')"
        />
        <span class="check"></span>
        <label for="draft_plans" class="checkbox_right">
          <span
            >Draft
            <a
              @click.prevent="changePlansStatusOnlyFilter('draft')"
              class="onlyLink"
              >Only</a
            >
          </span>
          <span class="checkbox_counter" v-if="getDraftPostCount">{{
            getDraftPostCount
          }}</span>
          <span class="status draft far fa-folder-plus"></span>
        </label>
      </div>
    </li>
  </ul>
</template>
<style lang="less">
.onlyLink {
  visibility: hidden;
  text-decoration: underline;
  font-size: 11px !important;
}
.onlyLink:hover {
  font-weight: bold;
}
.showlink:hover .onlyLink {
  visibility: visible;
}
</style>
