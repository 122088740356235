var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.getSocialSharingSelection.mentionsSuggestions.dropdown.status &&
    (_vm.type === 'Common' ||
      (_vm.type === 'Facebook' &&
        (_vm.getPublishLoaders.fetchFacebookMentionsSuggestions ||
          (_vm.getSocialSharingSelection.mentionsSuggestions.facebook &&
            _vm.getSocialSharingSelection.mentionsSuggestions.facebook
              .length))) ||
      (_vm.type === 'Twitter' &&
        (_vm.getPublishLoaders.fetchTwitterMentionsSuggestions ||
          (_vm.getSocialSharingSelection.mentionsSuggestions.twitter &&
            _vm.getSocialSharingSelection.mentionsSuggestions.twitter.length))))
  )?_c('div',{staticClass:"social_mention_dropdown",class:{
    move_left:
      _vm.getSocialSharingSelection.mentionsSuggestions.dropdown.left > 530,
  },style:({
    left: _vm.getSocialSharingSelection.mentionsSuggestions.dropdown.left + 'px',
    top: _vm.getSocialSharingSelection.mentionsSuggestions.dropdown.top + 'px',
  })},[(_vm.type === 'Common')?_c('div',{staticClass:"close_this top_heading d-flex align-items-center"},[_c('div',{staticClass:"head_left",class:{
        active:
          _vm.getSocialSharingSelection.mentionsSuggestions.type === 'Facebook',
      },on:{"click":function($event){return _vm.fetchMentionSuggestions('Facebook')}}},[_c('p',[_vm._v("Facebook")])]),_c('div',{staticClass:"head_right ml-auto",class:{
        active:
          _vm.getSocialSharingSelection.mentionsSuggestions.type === 'Twitter',
      },on:{"click":function($event){return _vm.fetchMentionSuggestions('Twitter')}}},[_c('p',[_vm._v("Twitter")])])]):_vm._e(),(
      _vm.type === 'Common' &&
      ((_vm.getSocialSharingSelection.mentionsSuggestions.type === 'Facebook' &&
        _vm.getPublishLoaders.fetchFacebookMentionsSuggestions) ||
        (_vm.getSocialSharingSelection.mentionsSuggestions.type === 'Twitter' &&
          _vm.getPublishLoaders.fetchTwitterMentionsSuggestions))
    )?_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"no_text"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):(
      _vm.type === 'Facebook' &&
      _vm.getPublishLoaders.fetchFacebookMentionsSuggestions
    )?_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"no_text"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):(
      _vm.type === 'Twitter' && _vm.getPublishLoaders.fetchTwitterMentionsSuggestions
    )?_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"no_text"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):(
      _vm.type === 'Linkedin' &&
      _vm.getPublishLoaders.fetchLinkedinMentionsSuggestions
    )?_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"no_text"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}})],1)]):[(
        (_vm.getSocialSharingSelection.mentionsSuggestions.type === 'Facebook' &&
          _vm.type === 'Common') ||
        _vm.type === 'Facebook'
      )?_c('ul',{staticClass:"inner"},[_vm._l((_vm.getSocialSharingSelection.mentionsSuggestions
          .facebook),function(suggestion){return [(suggestion.name)?_c('li',{on:{"click":function($event){_vm.addSharingMention(_vm.removeUsername(suggestion), _vm.type)}}},[_c('div',{staticClass:"profile_picture d-flex align-items-center"},[_c('div',{staticClass:"picture_block picture_block_background"},[(
                  suggestion.picture &&
                  suggestion.picture.data &&
                  suggestion.picture.data.url
                )?_c('div',{staticClass:"img",style:({
                  background: 'url(' + suggestion.picture.data.url + ')',
                })}):_c('div',{staticClass:"img",staticStyle:{"background":"url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')"}})]),_c('div',{staticClass:"text_block padding-left-ten"},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"text_left"},[_vm._v(_vm._s(suggestion.name)),(suggestion.verification_status === 'blue_verified')?_c('i',{staticClass:"fa fa-check-circle facebook verified_icon",attrs:{"aria-hidden":"true"}}):_vm._e()])]),_c('div',{staticClass:"sub_text"},[(suggestion.username)?_c('p',{},[_vm._v("@"+_vm._s(suggestion.username))]):_c('p',{staticClass:"sub_text"},[_vm._v("@"+_vm._s(_vm.facebookUsernameFromLink(suggestion.link)))]),(suggestion.fan_count)?_c('p',{staticClass:"followers"},[_vm._v("Followers "),_c('span',[_vm._v(_vm._s(_vm.intToString(suggestion.fan_count)))])]):_vm._e()])])])]):_vm._e()]})],2):(
        _vm.getSocialSharingSelection.mentionsSuggestions.type === 'Twitter' ||
        _vm.type === 'Twitter'
      )?_c('ul',{staticClass:"inner"},[_vm._l((_vm.getSocialSharingSelection.mentionsSuggestions
          .twitter),function(suggestion){return [(suggestion.name)?_c('li',{on:{"click":function($event){return _vm.addSharingMention(suggestion, _vm.type)}}},[_c('div',{staticClass:"profile_picture"},[_c('div',{staticClass:"text_block"},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"text_left"},[_vm._v(_vm._s(suggestion.name)),(suggestion.is_verified)?_c('i',{staticClass:"fa fa-check-circle twitter verified_icon",attrs:{"aria-hidden":"true"}}):_vm._e()])]),_c('div',{staticClass:"sub_text"},[_c('p',{},[_vm._v("@"+_vm._s(suggestion.username))]),(suggestion.followers_count)?_c('p',{staticClass:"followers"},[_vm._v("Followers "),_c('span',[_vm._v(_vm._s(_vm.intToString(suggestion.followers_count)))])]):_vm._e()])])])]):_vm._e()]})],2):_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }