import proxy from '@common/lib/http-common'
import { getPocketContentDetailsUrl } from '@src/modules/discovery/config/api-utils'
import { pocketTypes } from '@src/modules/discovery/store/mutation-types'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { appContainer } from '@src/main'

const getPocketDefaultContentPreview = () => {
  return {
    title: '',
    description: '',
    url: '',
    domain_url: '',
  }
}

export default {
  state: {
    filters: {
      search: '',
      page: 0,
      limit: 20,
      account: '',
      sort: {
        code: 'newest',
        name: 'Newest',
      },
      type: {
        code: 'all',
        name: 'All',
      },
    },
    posts: [],
    scroll: true,
    contentPreview: getPocketDefaultContentPreview(),
    loader: {
      pocketLoader: false,
      infiniteLoader: false,
      previewLoader: false,
    },
  },
  actions: {
    pocketContentPreview({ commit, getters, dispatch }, url) {
      console.debug('Action::pocketContentPreview')
      commit(pocketTypes.SET_POCKET_PREVIEW_CONTENT_LOADER, true)
      // $('#pocket_preview_modal').modal('show')
      appContainer.$bvModal.show('pocket_preview_modal')

      proxy
        .post(getPocketContentDetailsUrl, {
          url: url,
        })
        .catch(() => {
          commit(pocketTypes.SET_POCKET_PREVIEW_CONTENT_LOADER, false)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
        })
        .then((resp) => {
          if (!resp.data.status) {
            dispatch('toastNotification', {
              message: resp.data.message,
              type: 'error',
            })
            return false
          }
          commit(pocketTypes.SET_POCKET_PREVIEW_CONTENT, resp.data.data)
          commit(pocketTypes.SET_POCKET_PREVIEW_CONTENT_LOADER, false)
        })
    },

    setPocketContentAccount({ commit }, account) {
      commit('setPocketContentAccount', account)
    },
    setPocketPage({ commit }, page) {
      commit('setPocketPage', page)
    },
    setPocketPosts({ commit }, posts) {
      commit('setPocketPosts', posts)
    },
    setPocketPaginationLoader({ commit }, status) {
      commit('setPocketPaginationLoader', status)
    },
    setPocketScroll({ commit }, scroll) {
      commit('setPocketScroll', scroll)
    },
    setPocketLoader({ commit }, status) {
      commit('setPocketLoader', status)
    },
    setPocketSort({ commit }, sort) {
      commit('setPocketSort', sort)
    },
    setPocketType({ commit }, type) {
      commit('setPocketType', type)
    },
    resetPocket({ commit }) {
      commit('resetPocket')
    },
  },
  mutations: {
    [pocketTypes.SET_POCKET_PREVIEW_CONTENT_LOADER](state, loader) {
      state.loader.previewLoader = loader
    },
    [pocketTypes.SET_POCKET_PREVIEW_CONTENT](state, preview) {
      state.contentPreview = preview
    },
    setPocketContentAccount(state, account) {
      state.filters.account = account
    },
    setPocketPage(state, page) {
      state.filters.page = page
    },
    setPocketPosts(state, posts) {
      state.posts = posts
    },
    setPocketLoader(state, status) {
      state.loader.pocketLoader = status
    },
    setPocketScroll(state, scroll) {
      state.scroll = scroll
    },
    setPocketPaginationLoader(state, status) {
      state.loader.infiniteLoader = status
    },
    setPocketSort(state, sort) {
      state.filters.sort = sort
    },
    setPocketType(state, type) {
      state.filters.type = type
    },
    resetPocket(state) {
      state.filters = {
        search: '',
        page: 0,
        limit: 20,
        account: '',
        sort: {
          code: 'newest',
          name: 'Newest',
        },
        type: {
          code: 'all',
          name: 'All',
        },
      }
      state.posts = []
      state.scroll = true
      state.contentPreview = getPocketDefaultContentPreview()
      state.loader = {
        pocketLoader: false,
        infiniteLoader: false,
        previewLoader: false,
      }
    },
  },
  getters: {
    getPocketFilter: (state) => {
      return state.filters
    },
    getPocketPosts: (state) => {
      return state.posts
    },
    getPocketPaginationLoader: (state) => {
      return state.loader.infiniteLoader
    },
    getPocketLoader: (state) => {
      return state.loader.pocketLoader
    },
    getPocketPage: (state) => {
      return state.filters.page
    },
    getPocketScroll: (state) => {
      return state.scroll
    },
    getPocketContentPreview: (state) => {
      return state.contentPreview
    },
    getPocketLoaders: (state) => {
      return state.loader
    },
  },
}
