export default {
  state: {
    filters: {
      search: '',
      quotes: {
        page: 0,
        limit: 20
      }
    },
    quotes: [],
    scroll: true,
    loader: {
      search: {
        button: {
          search: false
        },
        content: {
          quoteLoader: false,
          infiniteLoader: false
        }
      }
    }
  },
  actions: {
    setQuoteLoader ({ commit }, status) {
      commit('setQuoteLoader', status)
    },
    setQuotes ({ commit }, data) {
      commit('setQuotes', data)
    },
    setQuotesPage ({ commit }, page) {
      commit('setQuotesPage', page)
    },
    setQuotesScroll ({ commit }, status) {
      commit('setQuotesScroll', status)
    },
    setQuotesSearch ({ commit }, search) {
      commit('setQuotesSearch', search)
    },
    setQuoteInfiniteLoader ({ commit }, status) {
      commit('setQuoteInfiniteLoader', status)
    },
    resetQuotes ({ commit }) {
      commit('resetQuotes')
    }
  },
  mutations: {
    setQuoteLoader (state, status) {
      state.loader.search.content.quoteLoader = status
    },
    setQuotes (state, data) {
      state.quotes = data
    },
    setQuotesPage (state, page) {
      state.filters.quotes.page = page
    },
    setQuotesScroll (state, status) {
      state.scroll = status
    },
    setQuotesSearch (state, search) {
      state.filters.search = search
    },
    setQuoteInfiniteLoader (state, status) {
      state.loader.search.content.infiniteLoader = status
    },
    resetQuotes (state) {
      state.filters = {
        search: '',
        quotes: {
          page: 0,
          limit: 20
        }
      }
      state.quotes = []
      state.scroll = true
      state.loader = {
        search: {
          button: {
            search: false
          },
          content: {
            quoteLoader: false,
            infiniteLoader: false
          }
        }
      }
    }
  },
  getters: {
    getQuotesFilter: (state) => {
      return state.filters
    },
    getQuoteLoader: (state) => {
      return state.loader
    },
    getQuotes: (state) => {
      return state.quotes
    },

    getQuoteScroll: (state) => {
      return state.scroll
    },
    getQuotePage: (state) => {
      return state.filters.quotes.page
    }
  }
}
