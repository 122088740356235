<template>
  <confirm-box
    id="removeCsvPlans"
    :callBackAction="removeCsvPlans"
    confirmText="Yes"
    cancelText="No"
  >
    <template slot="header">
      <h2>Remove Plans</h2>
    </template>

    <template slot="footer">
      <p class="text-center">This action cannot be undone.</p>
    </template>
  </confirm-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {
    ...mapActions(['bulkCSVOperation']),
    removeCsvPlans (confirmation, data) {
      console.debug('Method:removeCsvPlans', confirmation, data)
      if (confirmation.status) {
        this.bulkCSVOperation(data)
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    }
  }
}
</script>
