<template>
  <div class="m-2 flex flex-col items-center justify-center">
    <img src="@assets/img/composer/no-preview.png" alt="" class="mt-4 w-52 opacity-70">
    <span class="mt-6 block text-medium text-gray-800">{{msg}}</span>

<!--    <img-->
<!--        src="@assets/img/no_data_images/no_data_found.svg"-->
<!--        alt=""-->
<!--        class="w-100"-->
<!--    />-->
  </div>
</template>

<script>
export default {
  name: "NoResult",
  props:{
    msg:{
      type: String,
      default:"No Result Found"
    }
  }
}
</script>

<style scoped>

</style>