<template>
  <div>
    <template v-if="type === 'twitter'">
      <div class="grid-flex" @click="EventBus.$emit(getPreview(type), post)">
        <!--        <div class="analytics-tab-body__item cell-2">-->
        <!--          <div class="analytics-tab-body__item-profile-header">-->
        <!--            <div>-->
        <!--              <img-->
        <!--                v-if="post.profile_image_url"-->
        <!--                @error="-->
        <!--                  $event.target.src = `https://fakeimg.pl/60x60/98d66d,128/333333,255/?font_size=24&text=${-->
        <!--                    post.name ? post.name[0] : '#'-->
        <!--                  }`-->
        <!--                "-->
        <!--                :src="post.profile_image_url"-->
        <!--                alt=""-->
        <!--              />-->
        <!--              <i class="fab fa-twitter tw tw_bg"></i>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <h3>{{ post.name }}</h3>-->
        <!--              <p>-->
        <!--                <b>@{{ post.screen_name }}</b> •-->
        <!--                {{ this.getTimeFormatted(post.created_at) }}-->
        <!--              </p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="analytics-tab-body__item cell-2 justify_content_start">-->
        <!--          -->
        <!--        </div>-->
        <div class="analytics-tab-body__item cell-8 justify_content_start block pl-3 flex">
          <div class="analytics-tab-body__item-image flex-grow-0">
            <img
                v-if="post.media_url"
                @error="
                $event.target.src = require('../../assets/imgs/no_data_images/not-found.png')
              "
                :src="getResizedImageURL(post.media_url, 200, 0)"
                alt=""
                style="height: 140px; width: 140px;"
            />
            <img
                v-else
                draggable="false"
                src="../../assets/imgs/no_data_images/not-found.png"
                alt="No Picture Found"
                style="height: 140px; width: 140px;"
            />
          </div>
          <div class="pl-3">
            <div class="analytics-tab-body__item-profile-header flex-row">
              <div>

                <img
                    v-if="post.profile_image_url"
                    @error="
                  $event.target.src = fallbackImagePreview(post.name ? post.name[0] : '#')
                "
                    :src="post.profile_image_url"
                    alt=""
                    style="height:2.8rem; width: 2.8rem;"
                />
                <i class="fab fa-twitter tw tw_bg"></i>
              </div>
              <div>
                <h3>{{ post.name }}</h3>
                <p>
                  <b>@{{ post.screen_name }}</b> •
                  {{ this.getTimeFormatted(post.created_at) }}
                </p>
              </div>
            </div>
            <span
                v-if="post.tweet_text"
                v-html="stringToHtmlTwitter(post.tweet_text)"
            ></span>
          </div>
        </div>
        <div class="analytics-tab-body__item py-0 cell-1"></div>
        <div class="analytics-tab-body__item py-0 cell-1">
          <b>{{ post.total_engagement }}</b>
        </div>
        <div class="analytics-tab-body__item py-0 cell-1">
          <b>{{ post.retweet_count }}</b>
        </div>
        <div class="analytics-tab-body__item py-0 cell-1">
          <b>{{ post.favorite_count }}</b>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="grid-flex" @click.prevent="EventBus.$emit(getPreview(type), post)">
        <!--        <div class="analytics-tab-body__item cell-2">-->
        <!--          <div class="analytics-tab-body__item-profile-header">-->
        <!--            <div>-->
        <!--              <img-->
        <!--                :src="getImage(type, post)"-->
        <!--                alt=""-->
        <!--                @error="-->
        <!--                  $event.target.src = `https://fakeimg.pl/60x60/98d66d,128/333333,255/?font_size=24&text=${-->
        <!--                    getPostName(type, post) ? getPostName(type, post)[0] : '#'-->
        <!--                  }`-->
        <!--                "-->
        <!--              />-->
        <!--              <i class="fab" :class="getIconClass(type)"></i>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <h3>{{ getPostName(type, post) }}</h3>-->
        <!--              <p>-->
        <!--                <b> •</b>-->
        <!--                {{ getPostDate(type, post) }}-->
        <!--              </p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="analytics-tab-body__item cell-2 justify_content_start">-->
        <!--          -->
        <!--        </div>-->
        <div
            class="analytics-tab-body__item cell-8 cell-xl-4 cell-xxl-8 justify_content_start pl-3 flex"
        >
          <div class="analytics-tab-body__item-image flex-grow-0">
            <video v-if="type === 'instagram' && post.media_type == 'VIDEO'" class="video_preview_image" controls
                   style="height: 140px; width: 140px;">
              <source
                  :src="post.media_url"
              />
              <source
                  :src="post.media_url"
                  type="video/ogg"
              />
            </video>

            <img
                v-else-if="isPostImageAvailable(type, post)"
                @error="
                $event.target.src = require('../../assets/imgs/no_data_images/not-found.png')
              "
                :src="getPostImage(type, post)"
                alt=""
                style="height: 140px; width: 140px;"
            />

            <img
                v-else
                src="../../assets/imgs/no_data_images/not-found.png"
                alt=""
                style="height: 140px; width: 140px;"
            />
          </div>
          <div class="pl-3">
            <div class="analytics-tab-body__item-profile-header flex-row">
              <div>
                <img
                    :src="getImage(type, post)"
                    alt=""
                    @error="
                  $event.target.src = fallbackImagePreview(getPostName(type, post) ? getPostName(type, post)[0] : '#')
                "
                />
                <i class="fab" :class="getIconClass(type)"></i>
              </div>
              <div>
                <h3>{{ getPostName(type, post) }}</h3>
                <p>
                  <b> •</b>
                  {{ getPostDate(type, post) }}
                </p>
              </div>
            </div>
            <p
                v-if="isPostTextAvailable(type, post)"
                v-html="
              limitTextLength(
                isHashtag(isLink(nextLineReplacement(getPostText(type, post)))),400
              )
            "
            ></p></div>
        </div>
        <div v-if="type !== 'facebook'" class="analytics-tab-body__item py-0 cell-1 cell-xl-1 cell-xxl-1"></div>
        <div class="analytics-tab-body__item py-0 cell-1 cell-xl-1 cell-xxl-1">
          <b>{{ getPostEngagement(type, post) }}</b>
        </div>
        <div class="analytics-tab-body__item py-0 cell-1">
          <b>{{ getPostLikes(type, post) }}</b>
        </div>
        <div
            v-if="isPostCommentsAvailable(type)"
            class="analytics-tab-body__item py-0 cell-1 cell-xl-1 cell-xxl-1"
        >
          <b>{{ getPostComments(type, post) }}</b>
        </div>
        <div
            v-if="isPostSharesAvailable(type)"
            class="analytics-tab-body__item py-0 cell-1"
        >
          <b>{{ getPostShares(type, post) }}</b>
        </div>
        <div
            v-if="isPinClickAvailable(type)"
            class="analytics-tab-body__item py-0 cell-1"
        >
          <b>{{ getPinClick(type, post) }}</b>
        </div>
      </div>
    </template>
    <hr class="analytics-tab-body-divider"/>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import {EventBus} from "@common/lib/event-bus";

const moment = require('moment')

export default {
  computed: {
    EventBus() {
      return EventBus
    }
  },
  mixins: [analyticsUtilsMixin],
  name: 'SinglePostRow',
  props: ['post', 'type'],
  methods: {
    getTimeFormatted(timeString) {
      return moment(timeString).format('MMMM Do, YYYY hh:mm A')
    }
  }
}
</script>

<style scoped></style>
