<template>
  <div class="main_wrapper_sidebar inbox_main_view" ref="inbox-main">
    <inbox-link-sidebar
        v-on:conversation-type-changed="conversationTypeChanged"
    ></inbox-link-sidebar>
    <template v-if="inboxFilters.filter !== null">
      <InboxBody></InboxBody>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.inbox_main_view {
  display: flex;
  height: calc(100vh - 60px);
}

.fixed-sidebar-view {
  .inbox_sidebar {
    position: fixed;
    z-index: 1040;
    height: calc(100vh - 60px);
    overflow: unset;

    .inbox_header__colapse-btn {
      display: none !important;
    }
  }

  .inbox-body {
    padding-left: 58px;
  }
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex'
import InboxLinkSidebar from './InboxLinkSidebar'
import InboxBody from './InboxBody'
import {inboxFilters} from '../store/inbox-filters'

export default {
  components: {
    InboxLinkSidebar,
    InboxBody
  },

  data() {
    return {
      filter: null,
      inboxFilters: inboxFilters,
      windowWidth: window.innerWidth,
      isSidebarHover: false
    }
  },
  computed: {
    ...mapGetters([
      'getPlan',
      'getActiveWorkspaceAddonsLifetime',
      'getActiveWorkspace',
      'getWorkspaces'
    ]),
    hasSocialInboxAccess() {
      if (
          this.getActiveWorkspaceAddonsLifetime.hasOwnProperty(
              'social_inbox_beta'
          ) &&
          this.getActiveWorkspaceAddonsLifetime.social_inbox_beta
      ) {
        return true
      }
      if (
          this.getActiveWorkspaceAddonsLifetime.hasOwnProperty('social_inbox') &&
          this.getActiveWorkspaceAddonsLifetime.social_inbox
      ) {
        return true
      }
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      if (this.getPlan && this.getPlan.subscription.hasOwnProperty('default_add_ons')) {
        return !!this.getPlan.subscription.default_add_ons.social_inbox
      }
      return false
    }
  },

  async created() {
    if (!this.hasSocialInboxAccess) {
      await this.$router.push({
        name: 'upgrade_lifetime_addon',
        params: {workspace: this.getWorkspaces.activeWorkspace.slug},
      })
    }
  },

  async mounted() {

    console.log('getPlans details', this.getPlan)
    this.setUsage(this.getActiveWorkspace._id)

    this.$nextTick(async function () {
      this.toggleSidebarStyle()

      window.addEventListener('resize', () => {
        this.toggleSidebarStyle()
      })

    })
  },

  beforeDestroy() {
    this.removeNotificationSocket()
  },


  methods: {
    ...mapActions(['setInboxUsage']),
    async setUsage(workspaceId) {
      if (this.hasSocialInboxAccess) {
        const resp = await this.setInboxUsage({workspace_id: workspaceId})
        console.log('setUsage', resp)
      }
    },
    toggleSidebarStyle() {
      this.windowWidth = window.innerWidth

      if (typeof this.$refs['inbox-main'] !== 'undefined') {
        if (this.windowWidth <= 1377) {
          this.$refs['inbox-main'].classList.add('fixed-sidebar-view')
        } else {
          this.$refs['inbox-main'].classList.remove('fixed-sidebar-view')
        }
      }
    },
    conversationTypeChanged(payload) {
      this.filter = payload
    },

  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const inboxNotificationChannel =
            'channel-inbox-notifications-' + oldVal + '-' + this.getProfile._id
        this.setInboxNotificationBroadcast(inboxNotificationChannel)

        this.setUsage(newVal)
      }
    },
    'getProfile._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const inboxNotificationChannel =
            'channel-inbox-notifications-' +
            this.getActiveWorkspace._id +
            '-' +
            oldVal
        this.setInboxNotificationBroadcast(inboxNotificationChannel)
      }
    }
  }
}
</script>
