<template>
  <b-modal
    id="external-actions-modal"
    centered
    header-class="cs-modal-header"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title-class="cs-modal-title"
    @hidden="onModalHidden"
  >
    <template v-slot:modal-title>
      You are about to
      {{ data.type === 'comment' ? 'add comment to' : data.type }}
      {{ data.plans.length > 1 ? 'the selected posts' : 'a post' }}.
    </template>

    <div class="px-2 pb-4 pt-7">
      <div class="mb-6">
        Please provide the following information to perform this action.
      </div>

      <CstFloatingLabelInput
        id="full-name-action"
        v-model="data.name"
        class="mb-6"
        type="text"
        label="Full Name *"
      >
      </CstFloatingLabelInput>

      <CstFloatingLabelInput
        id="email-action"
        v-model="data.email"
        class="mb-6"
        type="email"
        label="Email *"
      >
      </CstFloatingLabelInput>

      <div
        class="flex items-center rounded-md border text-gray-900 text-sm focus-within:outline-blue block w-full py-2.5 px-3 mb-6 relative"
      >
        <label
          for="comment-action"
          class="absolute block top-0 left-3 w-full py-2 m-0 leading-normal cursor-text transition-all ease-in-out duration-300 text-gray-900 text-[0.75rem]"
        >
          Comment
        </label>
        <textarea
          id="comment-action"
          v-model="data.comment"
          class="w-full border-0 mt-[1.75rem]"
          rows="4"
          placeholder="Write your comment here..."
        >
        </textarea>
      </div>

      <!--    Footer    -->
      <div class="flex justify-end mt-3.5">
        <CstButton
          variant="secondary"
          size="large"
          text="Cancel"
          class="mr-3 w-28"
          @click="$bvModal.hide('external-actions-modal')"
        />
        <CstButton
          variant="primary"
          size="large"
          text="Confirm"
          class="w-28"
          @click="onSubmit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent, onMounted, reactive, computed, inject } from 'vue'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstButton from '@ui/Button/CstButton'
import CstAlert from '@ui/Alert/CstAlert'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'ExternalActionsModal',

  components: {
    CstFloatingLabelInput,
    CstButton,
    CstAlert,
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch } = useStore()
    const token = computed(() => props.token)

    const data = reactive({
      comment: '',
      type: 'comment',
      plans: [],
      name: '',
      email: '',
      token: '',
    })

    onMounted(() => {
      console.log('mounted')
      EventBus.$on(
        'external-actions::show',
        ({ type = 'comment', plans = [] }) => {
          data.type = type
          data.plans = plans
          $bvModal.show('external-actions-modal')
        }
      )
    })

    const onModalHidden = () => {
      data.name = data.email = data.comment = ''
      data.plans = []
    }

    const onSubmit = async () => {
      console.log('submit')

      // validate
      if (!data.name.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your name',
          type: 'error',
        })
        return
      }

      if (!data.email.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your valid email',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment' && !data.comment.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your comment',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment') {
        await addComment()
      } else {
        await externalAction()
      }
    }

    const externalAction = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.APPROVE_REJECT_PLAN, {
          token: token.value,
          plans: data.plans,
          action: {
            name: data.name,
            email: data.email,
            comment: data.comment,
            type: data.type,
          },
        })
        dispatch('toastNotification', {
          message: 'Post state updated successfully',
          type: 'success',
        })

        $bvModal.hide('external-actions-modal')
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const addComment = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.ADD_COMMENT, {
          token: token.value,
          plans: data.plans,
          comment: {
            name: data.name,
            email: data.email,
            comment: data.comment,
          },
        })
        dispatch('toastNotification', {
          message: 'Comment added successfully',
          type: 'success',
        })

        $bvModal.hide('external-actions-modal')
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    return {
      onModalHidden,
      onSubmit,
      data,
    }
  },
})
</script>

<style scoped></style>
