import proxy from '@common/lib/http-common'
import {
  deleteCommentsUrl,
  saveCommentsUrl,
} from '@src/modules/planner/config/api-utils'

/**
 * API Handler for dispatching comment request.
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addComment = async (payload) => {
  return await proxy.post(saveCommentsUrl, payload).then((response) => {
    if (response.data.status) {
      return response.data.comment
    }
  })
}

/**
 * API Handler for dispatching deletion of comment.
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteComment = async (payload) => {
  console.log('METHOD::deleteComment')
  return proxy.post(deleteCommentsUrl, payload).then((response) => {
    console.log('deleting comment ~ response -> ', response)
  })
}
