<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_left_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Popular Word Count </h2>
    <div
      class="topics-insights-container__chart_legends"
      id="wordCloudTopics"
    />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        :options="wordCountInsights()"
        ref="highcharts_articles_published"
      ></highcharts>
    </div>
  </div>
</template>
<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'

export default {
  props: {
    wordcount: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      return (
        (this.wordcount.categories && this.wordcount.categories.length) +
          (this.wordcount.articles && this.wordcount.articles.length) ===
        0
      )
    }
  },

  methods: {
    wordCountInsights () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{point.key} Word Count</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} articles.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $('wordCloud').html('')
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#wordCloud')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#5e69bc', '#8ca8dd'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories:
            this.wordcount.categories && this.wordcount.categories.length > 0
              ? this.wordcount.categories
              : ['0-1K', '1k-2K', '2K-3K', '3K-4K', '4K-5K'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 40,
            borderRadiusTopRight: 40,
            pointPadding: 0,
            groupPadding: 0.3
          }
        },
        series: [
          {
            type: 'column',
            name: 'Engagement Per Article',
            data:
              this.wordcount.interactions &&
              this.wordcount.interactions.length > 0
                ? this.wordcount.interactions
                : [11, 13, 4, 73, 3],
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#1da1f2'],
                [1, '#9cd9ff']
              ]
            }
          },
          {
            type: 'column',
            name: 'Number of Articles',
            data:
              this.wordcount.articles && this.wordcount.articles.length > 0
                ? this.wordcount.articles
                : [11, 23, 4, 23, 43],
            yAxis: 1,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#6a98cf'],
                [1, '#9ec2ee']
              ]
            }
          }
        ]
      }
      return chartOptions
    }
  }
}
</script>
