<template>
  <div class="center-modal-content">
    <div class="relative">
      <div class="top-content">
        <h2>ContentStudio Suite - Special Bundle Offer</h2>
        <div class="line"></div>
        <h3 class="price_tag mb-3">Special deal expires in</h3>
        <div class="countdown">
          <span class="countdown-span"
            ><Countdown deadline="November 22, 2019 11:59 GMT"></Countdown
          ></span>
        </div>
        <div class="line mb-3 mt-4"></div>
        <p
          >We are giving our lifetime users a special opportunity to upgrade
          their accounts massively without the hefty monthly pricing. This deal
          is the result of Bruce Kraft’s persistent efforts in persuading us to
          offer such a massive discount. Everyone should be thankful to him. :)
        </p>

        <h3 class="price_tag mt-3 mb-3"
          >Let’s jump right in and see what’s included…</h3
        >
      </div>
      <div class="d-flex align-items-center">
        <div class="price-box-single price-box-single-special">
          <div class="box-inner">
            <div class="row"
              ><!---->
              <p class="desc"
                >If you have purchased the add-ons in the past, you'll only need
                to pay the remaining amount. Below you can see your past
                purchases:</p
              >

              <div class="billing-history-list" style="margin-bottom: 30px">
                <div v-if="orders.length > 0" class="table-prices-wrapper">
                  <p class="desc mt-0 mb-4">Here's your payment detail.</p>

                  <div class="table-prices table-prices-special">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="width: 325px">Name</th>
                          <th style="width: 250px">Date</th>
                          <th>Amount Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="order in orders"
                          v-if="
                            order.product_name &&
                            parseInt(order.fee) + parseInt(order.earnings) > 0
                          "
                        >
                          <td style="width: 200px">{{ order.product_name }}</td>
                          <td>
                            <template v-if="order.created_at">
                              Paid on : <strong>{{ order.created_at }}</strong>
                            </template>
                          </td>
                          <td style="width: 150px" class="text-right">
                            ${{
                              parseInt(order.fee) + parseInt(order.earnings)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p class="desc"
                    >You have paid
                    <strong>${{ amount_to_deduct }}</strong> already.</p
                  >
                  <p class="desc"
                    >You'll have to pay
                    <strong>${{ getExistingLifetimeCustomer }}</strong>
                    more.</p
                  >
                  <!--<div class="input_field text-center">
                                        <button class="btn submit-btn"
                                                @click="specialOfferPurchase('existing_user', getExistingLifetimeCustomer)">
                                            <span>Click here to pay now</span>
                                        </button>
                                    </div>-->
                </div>
              </div>
              <div class="clearfix"></div>

              <div
                class="mb-4 mb-lg-0 col-lg-5 box-left d-flex align-items-center justify-content-center"
              >
                <div class="left-inner"
                  ><h2 class="cut_price"
                    ><span class="price">$5608</span
                    ><span class="duration">/Year</span></h2
                  >
                  <h2
                    ><span class="price"
                      >${{ getExistingLifetimeCustomer }}</span
                    ><span class="duration">/Lifetime</span></h2
                  >
                  <button
                    class="btn"
                    @click="
                      specialOfferPurchase(
                        'existing_user',
                        getExistingLifetimeCustomer,
                      )
                    "
                    ><span>Buy Now</span></button
                  >
                </div> </div
              ><!---->
              <div class="col-lg-7 box-right">
                <ul class="features">
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i
                    ><span
                      >ContentStudio Large Agency ( Version Bee) plan
                      with:</span
                    >
                  </li>
                  <li class="d-flex align-items-center"
                    ><i class="fas fa-circle"></i>50 Workspaces
                  </li>
                  <li class="d-flex align-items-center"
                    ><i class="fas fa-circle"></i
                    ><strong class="mr-1">Unlimited</strong> social accounts
                  </li>
                  <li class="d-flex align-items-center"
                    ><i class="fas fa-circle"></i>50 users (team/client)
                  </li>
                  <li class="d-flex align-items-center"
                    ><i class="fas fa-circle"></i>50 blog accounts
                  </li>
                  <li class="d-flex align-items-center"
                    ><i class="fas fa-circle"></i>and everything else according
                    to the large agency plan on our website.
                  </li>
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i
                    ><span>ContentStudio - Analytics (advanced/full)</span>
                  </li>
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i
                    ><span>ContentStudio - Influencers</span>
                  </li>
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i
                    ><span>ContentStudio - Insights</span>
                  </li>
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i
                    ><span>ContentStudio - Social Inbox</span>
                  </li>
                  <li class="d-flex align-items-start"
                    ><i class="fa fa-check"></i><span>Replug (Basic Plan)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPurchasedAddonsURL, overridePriceURL } from '@src/modules/setting/config/api-utils'
import Countdown from 'vuejs-countdown'
import { mapGetters } from 'vuex'
import http from '../../lib/http-common'

export default {
  computed: {
    ...mapGetters(['getProfile']),
    getExistingLifetimeCustomer() {
      const calculateAmount =
        this.existing_lifetime_customer - this.amount_to_deduct
      if (calculateAmount > 0) return calculateAmount
      return 1
    },
  },
  components: {
    Countdown,
  },
  data() {
    return {
      orders: [],
      amount_to_deduct: 0,
      existing_lifetime_customer: 599,
      new_lifetime_customer: 749,
      check_existing_order_loader: false,
      checked_data_for_existing_customer: false,
    }
  },
  mounted() {
    this.checkPurchasedAddons()
  },
  methods: {
    specialOfferPurchase(addonType, addonPrice = '99') {
      const title = 'ContentStudio Large Agency (Version Bee)'
      const productId = 573979
      http
        .post(overridePriceURL, {
          paddle_id: productId,
          price: addonPrice,
          email: this.getProfile.email,
          passthrough: { type: addonType },
          title: title
        })
        .then((resp) => {
          const respPayload = resp.data
          if (respPayload.status) {
            const paddleOptions = {
              override: resp.data.url,
              successCallback: function (data) {
                alert(
                  'Thanks for your purchase. You will be navigated to the ContentStudio application now.'
                )
                window.location.reload()
              },
            }
            Paddle.Checkout.open(paddleOptions)
          } else {
            this.$store.dispatch('toastNotification', {
              message:
                'Unable to upgrade plan, please contact support.'
            })
          }
        })
        .catch()
    },
    checkPurchasedAddons() {
      this.orders = []
      this.amount_to_deduct = 0
      this.check_existing_order_loader = true
      http
        .post(checkPurchasedAddonsURL, {
          email: this.getProfile.email.trim(),
        })
        .then((res) => {
          if (res.data.status) {
            this.amount_to_deduct = res.data.data.amount_to_deduct
            this.orders = res.data.data.orders
            if (this.orders.length === 0) {
              this.checked_data_for_existing_customer = true
            }
            this.check_existing_order_loader = false
          }
        })
        .catch((err) => {
          this.check_existing_order_loader = false
        })
    },
  },
}
</script>

<style lang="less">
@import (reference) '~@assets/less/basic/_colors';
@import (reference) '~@assets/less/basic/_mixins';

.table-prices {
  overflow: hidden;
  border-radius: 0.625rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e7e7e7;

  max-width: 700px;

  /*box-shadow: 2.5px 24.3px 50px 0 rgba(206, 217, 227, 0.5);*/
  padding: 0 30px;
  background: #ffffff;
  padding-bottom: 21px;
  margin: 35px auto;
  .fa-check {
    color: @green;
    margin-right: 5px;
  }
  .table {
    margin-bottom: 0;
  }
  thead {
    tr {
      th {
        visibility: hidden;
        opacity: 0;
        padding: 0;
        border: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 1.125rem 8px;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        font-size: 1rem;
        font-weight: 500;
        &:last-child {
          font-weight: bold;
        }
      }

      &:first-child {
        td {
          border-top: 0;
        }
      }
    }
  }
}

.price-box-single {
  position: relative;
  padding: 20px;
  /*max-width: 950px;*/
  margin: 0 auto;

  .box-inner {
    overflow: hidden;
    .border-radius(50px);
    box-shadow: 2.5px 24.3px 50px 0 rgba(206, 217, 227, 0.5);
    padding: 40px 45px;
    background: @white;

    .box-left {
      background: #f5f7f9;
      padding: 40px;
      border-radius: 12px;
      text-align: center;
      .btn {
        transition: all 0.2s linear;
        display: inline-block;
        margin-top: 25px;
        padding: 10px 30px;
        font-size: 1.125rem;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 0.625rem;
        background: #2dd87e;
        background: linear-gradient(252deg, #2dd87e, #14c4a4);
        span {
          color: @white;
        }
      }
      h2 {
        color: @darkBlack;
        margin-bottom: 1rem;
        .price {
          font-size: 50px;
          margin-left: 7px;
          margin-right: 7px;
          font-weight: bold;
        }
        .duration {
          font-size: 1.125rem;
        }
        .sign {
          font-size: 20px;
          vertical-align: top;
        }
        .text {
          font-size: 20px;
        }
      }

      .cut_price {
        .duration {
          color: #a0a5ba;
        }
        .price {
          font-size: 1.875rem;
          color: #a0a5ba;
          position: relative;
          font-weight: 600;
          &:after {
            position: absolute;
            content: '';
            height: 2px;
            left: -4px;
            background: #f45f54;
            width: 96px;
            top: 1.125rem;
          }
        }
      }
    }
    .box-right {
      padding-left: 62px;
      .features {
        text-align: left;
        li {
          padding: 6px 0;
          font-size: 1rem;
          color: @darkBlack;
          font-weight: 500;
          i {
            margin-right: 15px;
            min-width: 25px;
          }
          .fa-circle {
            font-size: 7px;
          }
          .fa-check {
            color: @green;
          }
          .fa-times {
            color: @pink5;
          }
        }
      }
    }
  }
}

.price-box-single-special {
  .price-box-tab {
    text-align: center;
    margin-bottom: 25px;
    .tag-inner {
      display: inline-block;
      border: 1px solid #e91e63;
      border-radius: 0.625rem;
      overflow: hidden;
    }
    .tab-item {
      cursor: pointer;
      min-width: 205px;
      text-align: center;
      padding: 10px 0.625rem;
      border-radius: 4px;
      display: inline-block;
      vertical-align: middle;

      font-weight: bold;
      text-transform: capitalize;
      letter-spacing: 0.5px;
    }
    .active {
      background: #e91e63;
      color: @white;
    }
  }
  .desc {
    font-size: 1rem;
    margin-top: 25px;
    font-weight: 500;
    text-align: center;
    span,
    .link_a {
      color: @purple2;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .billing-history {
    max-width: 475px;
    margin: 0 auto;
    text-align: center;
    .input_field {
      margin-top: 25px;
      .submit-btn {
        background: #2dd87e;
        background: linear-gradient(252deg, #2dd87e, #14c4a4);
        span {
          color: @white;
        }
      }
      label {
        margin-bottom: 5px;
        padding: 3px 0;
        font-size: 1rem;
        font-weight: 500;
        display: block;
      }
      input {
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        font-size: 0.875rem;
        color: #3a4557;
        border: 1px solid rgba(152, 158, 181, 0.5);
        padding: 12px 0.625rem;
        background: transparent;
      }
    }
  }

  .billing-history-list {
    width: 100%;
    .input_field {
      margin-top: 25px;
      .submit-btn {
        background: #2dd87e;
        background: linear-gradient(252deg, #2dd87e, #14c4a4);
        span {
          color: @white;
        }
      }
      label {
        margin-bottom: 5px;
        padding: 3px 0;
        font-size: 1rem;
        font-weight: 500;
        display: block;
      }
      input {
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        font-size: 0.875rem;
        color: #3a4557;
        border: 1px solid rgba(152, 158, 181, 0.5);
        padding: 12px 0.625rem;
        background: transparent;
      }
    }

    strong {
      color: #212529;
    }
  }
}
</style>
