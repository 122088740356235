export default {
  methods: {
    /** upgrade subscription related routes
     *
     * @returns {boolean}
     */
    isSubscriptionRoute() {
      switch (this.$route.name) {
        case 'trial_expired':
          return true
        case 'subscription_expired':
          return true
        case 'upgrade_subscription':
          return true
        default:
          return false
      }
    },
    /**
     * List of the routes on which we will be showing the authentication container
     * @returns {boolean}
     */
    isNonAuthenticatedRoute() {
      const routesList = [
        'login',
        'forgotpassword',
        'signup',
        'organization_team_join',
        'joinTeam',
        'recover_password',
        'email_verification',
      ]
      if (routesList.indexOf(this.$route.name) > -1) return true
      return false
    },
    /** used for adding the class
     *
     * @returns {boolean}
     */
    isWorkspaceMainPageRoute() {
      switch (this.$route.name) {
        case 'workspaces':
          return true
        case 'createWorkspace':
          return true
        case 'manageTeam':
          return true
        default:
          return false
      }
    },
    showTopNavigationBar() {
      const routesList = [
        'workspaces',
        'createWorkspace',
        'manageTeam',
        'trial_expired',
        'subscription_expired',
        'sso',
        'upgrade_subscription',
        'email_verification',
        'verify_email',
        'page404',
        'upgrade_addon',
        'upgrade_addons_lifetime',
        'upgrade_large',
        'upgrade_starter_plan',
        'upgrade_your_limits',
        'manageLimits',
        'subscription_deleted',
        'subscription_cancelled',
        'subscription_paused',
        'start_trial'
      ]
      // route not found, return true
      if (routesList.indexOf(this.$route.name) === -1) return true
      return false
    },
  },
}
