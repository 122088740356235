var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.getVideos),function(video,index){return _c('div',{key:index,staticClass:"assistant_box _dailymotion _video",class:{ dragable_box: !(_vm.$route.name === 'composerBlog') }},[_c('div',{staticClass:"box_inner",attrs:{"draggable":_vm.$route.name === 'composerBlog'},on:{"dragstart":function($event){return _vm.dragstartHandler($event, video)}}},[_c('div',{staticClass:"image_block"},[_c('div',{staticClass:"play_icon facebook_vid_icon",on:{"click":function($event){$event.preventDefault();_vm.previewVideo(
              video.title,
              '',
              _vm.videoEmbedUrl(video, 'dailymotion'),
              'Dailymotion'
            )}}},[_c('i',{staticClass:"cs-play"})]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.videoImageURL(video, 'dailymotion')),expression:"videoImageURL(video, 'dailymotion')",arg:"background-image"}],staticClass:"img"})]),_c('div',{staticClass:"content_block"},[_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.limitTextLength(video.title, 42))+" ")]),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"date"},[_c('span',[_vm._v(_vm._s(_vm.videoDate('dailymotion', video)))])])])]),_c('div',{staticClass:"social_stats"},[_c('div',{staticClass:"social_stats_inner d-flex"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('View'),expression:"'View'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(0,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(video.views_total)))])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Like'),expression:"'Like'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(1,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(video.likes_total)))])])])])]),_c('div',{staticClass:"hide d-none editor_dragable_box editor_video_block",attrs:{"contenteditable":"false"}},[_c('div',{staticClass:"editor_dragable_box_inner clear"},[_vm._m(2,true),_c('div',{staticClass:"align_box dailymotion_inner_embed",staticStyle:{"text-align":"center","display":"block"},attrs:{"data-link":_vm.videoEmbedUrl(video, 'dailymotion')}},[_vm._m(3,true)]),_c('p',{staticClass:"text_alignment",staticStyle:{"text-align":"center"},attrs:{"data-link":video.url,"data-embed":_vm.videoEmbedUrl(video, 'dailymotion')}},[_vm._v(_vm._s(video.title))]),_vm._m(4,true)])])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"cs-eye"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"cs-thumbs-up"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_option"},[_c('div',{staticClass:"left_block"},[_c('i',{staticClass:"move_left fa fa-align-left editor_tooltip",attrs:{"title":"Align Left"}}),_c('i',{staticClass:"move_center fa fa-align-center editor_tooltip",attrs:{"title":"Align Center "}}),_c('i',{staticClass:"move_right fa fa-align-right editor_tooltip",attrs:{"title":"Align Right"}})]),_c('div',{staticClass:"right_block"},[_c('i',{staticClass:"handler fa fa-hand-paper-o editor_tooltip",attrs:{"title":"Move"}}),_c('i',{staticClass:"edit_box fa fa-check-circle editor_tooltip green",attrs:{"title":"Merge"}}),_c('i',{staticClass:"remove_box fa fa-times-circle editor_tooltip red",attrs:{"title":"Remove"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner_embed"},[_c('div',{staticClass:"custom_loader"},[_c('div',{staticClass:"loader_inner"})]),_c('iframe',{staticStyle:{"width":"470px","height":"330px"},attrs:{"frameborder":"0","src":"","allowfullscreen":""}}),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('br')])
}]

export { render, staticRenderFns }