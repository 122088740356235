<template>
  <div class="article-filters-pane__right_section">
    <div class="checkbox_input_image mr-3">
      <input
        type="checkbox"
        v-model="is_hd_videos"
        @change="redirectYoutubeHDVideosView()"
        class=""
        id="Paginate"
      />
      <label for="Paginate" class="checkbox_left">HD Videos</label>
    </div>

    <div
      v-tooltip.top-center="'Sort By'"
      class="dropdown option_dropdown default_style_dropdown"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="sorting_icon icon_first cs-filter"></i>
        <span class="text">{{ getYoutubeVideoFilterSortTitle }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li
            class="list_item"
            @click.prevent="redirectSortYoutubeView('Relevance')"
          >
            <a class="dropdown-item">Relevance</a>
          </li>
          <li
            class="list_item"
            @click.prevent="redirectSortYoutubeView('ViewCount')"
          >
            <a class="dropdown-item">Most Viewed</a>
          </li>
          <li
            class="list_item"
            @click.prevent="redirectSortYoutubeView('Date')"
          >
            <a class="dropdown-item">Recent</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      is_hd_videos: false
    }
  },
  methods: {
    redirectSortYoutubeView (sort) {
      this.$router.replace({ query: { ...this.$route.query, order: sort } })
    },
    redirectYoutubeHDVideosView () {
      if (this.is_hd_videos) {
        this.$router.replace({
          query: { ...this.$route.query, videoDefinition: 'high' }
        })
      } else {
        const paramQueries = Object.assign({}, this.$route.query)
        delete paramQueries.videoDefinition
        console.log(paramQueries)
        this.$router.replace({ query: paramQueries })
      }
    }
  },
  computed: {
    getYoutubeVideoFilterSortTitle () {
      if (!this.$route.query.order) return 'Relevance'
      switch (this.$route.query.order.toLowerCase()) {
        case 'viewcount':
          return 'Most Viewed'
        case 'relevance':
          return 'Relevance'
        case 'date':
          return 'Recent'
        default:
          return 'Relevance'
      }
    }
  }
}
</script>
