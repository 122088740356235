var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-hr-scroll w-full px-1 overflow-y-auto absolute h-full pb-20"},[_c('div',{staticClass:"flex items-center sticky top-0 bg-white"},[_c('span',{staticClass:"text-cs-primary cursor-pointer rounded-md text-lg my-2 px-2.5 py-1 mx-px hover:bg-cs-secondary",on:{"click":_vm.returnBack}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('span',{staticClass:"text-black text-base font-semibold ml-auto mx-2"},[_vm._v("Article Preview")])]),_c('hr',{staticClass:"m-0 hr-color"}),(_vm.previewLoader)?_c('div',{staticClass:"p-3"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1):[_c('div',{staticClass:"content w-100 py-2 px-2 bg-white"},[_c('div',{staticClass:"flex w-100"},[_c('p',{staticClass:"text-sm text-gray-800"},[_vm._v(" "+_vm._s(_vm.previewPost._source.domain)+" - "+_vm._s(_vm.$filters.relative(_vm.previewPost._source.post_date))+" on "+_vm._s(_vm.$filters.dateWithYear(_vm.previewPost._source.post_date))+" ")])]),_c('div',{staticClass:"flex w-100 justify-end mt-2"},[_vm._l((_vm.previewPost._source.topics),function(topic,index){return [(topic && topic.label)?_c('div',{key:index},[_vm._l((_vm.checkIfMultipleTopics(
                topic.label
              )),function(finalizedTopic,key){return [(topic && topic.label)?_c('span',{key:key,staticClass:"border mx-0.5 p-1 text-gray text-sm rounded-md"},[_vm._v(" "+_vm._s(finalizedTopic)+" ")]):_vm._e()]})],2):_vm._e()]})],2),_c('div',{staticClass:"flex w-100 mt-2"},[_c('h2',{staticClass:"text-lg font-bold text-black-900"},[_vm._v(" "+_vm._s(_vm.previewPost._source.title)+" ")])]),_c('div',{staticClass:"grid grid-cols-2 w-100 mt-2"},[_c('div',{staticClass:"text-gray text-sm text-center flex"},[_c('span',{staticClass:"px-0.5 text-gray-800 text-base"},[_vm._v("Engagement")]),_c('span',{staticClass:"font-bold px-1 text-base"},[_vm._v(_vm._s(_vm.intToString(_vm.getTopicPreviewTotalShares())))])]),_c('div',{staticClass:"text-gray text-sm text-center"},[(_vm.getTopicPreviewSentiment())?_c('div',[_c('span',{staticClass:"px-0.5 text-gray-800 text-base"},[_vm._v("Sentiment")]),_c('span',{staticClass:"font-bold px-0.5 text-base"},[_c('i',{class:_vm.getTopicPreviewSentiment(),attrs:{"aria-hidden":"true"}})])]):_vm._e()])]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"w-100 h-96 bg-contain bg-center bg-no-repeat rounded-md mt-2 mb-3",style:({
            'background-image':
              'url(' +
              _vm.backgroundArticlePreviewImageURL(_vm.previewPost._source, 800) +
              ')',
          })}),(_vm.previewPost._source.description)?_c('div',{staticClass:"text-sm text-justify",domProps:{"innerHTML":_vm._s(_vm.previewPost._source.description)}}):(_vm.previewPost._source.short_description)?_c('div',{staticClass:"text-sm text-justify",domProps:{"innerHTML":_vm._s(_vm.previewPost._source.short_description)}}):_c('div',{},[_c('p',[_vm._v("No description available for this article.")])])])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }