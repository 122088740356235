<template>
  <b-modal
    :id="id"
    ref="refInfluencerVideoPreview"
    :modal-class="'videoTutorialModal normal_modal small_video_box ' + position"
    hide-backdrop
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <!--<h2>Get started with Composer section</h2>-->
      <slot name="heading"></slot>
      <button id="close" type="button" class="close" @click="$bvModal.hide(id)"
        >&times;</button
      >
    </div>

    <div class="modal_body basic_form">
      <slot name="description"></slot>
      <!--<h2>To begin using the publisher, connect a social-->
      <!--network <span class="bold">(Facebook, Twitter, LinkedIn, Pinterest, Tumblr)</span> or-->
      <!--a blog channel <span class="bold">(WordPress, Medium, Tumblr)</span>.</h2>-->
      <div class="video_block embed-responsive embed-responsive-16by9">
        <slot name="iframe"></slot>
        <!--<iframe class="embed-responsive-item"  src="https://www.youtube.com/embed/uB6Mm9DoGoY" frameborder="0"-->
        <!--allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
      </div>
    </div>
  </b-modal>

  <!--<div :class="position" class=" fade modal  videoTutorialModal normal_modal small_video_box" :id="id">-->
  <!--<div class="modal-dialog">-->
  <!--<div class="modal-content">-->
  <!--<div class="modal_head">-->
  <!--&lt;!&ndash;<h2>Get started with Composer section</h2>&ndash;&gt;-->
  <!--<slot name="heading"></slot>-->
  <!--<button type="button" class="close" id="close" data-dismiss="modal">&times;</button>-->
  <!--</div>-->
  <!--<div class="modal_body basic_form ">-->
  <!--<slot name="description"></slot>-->
  <!--&lt;!&ndash;<h2>To begin using the publisher, connect a social&ndash;&gt;-->
  <!--&lt;!&ndash;network <span class="bold">(Facebook, Twitter, LinkedIn, Pinterest, Tumblr)</span> or&ndash;&gt;-->
  <!--&lt;!&ndash;a blog channel <span class="bold">(WordPress, Medium, Tumblr)</span>.</h2>&ndash;&gt;-->
  <!--<div class="video_block embed-responsive embed-responsive-16by9">-->
  <!--<slot name="iframe"></slot>-->
  <!--&lt;!&ndash;<iframe class="embed-responsive-item"  src="https://www.youtube.com/embed/uB6Mm9DoGoY" frameborder="0"&ndash;&gt;-->
  <!--&lt;!&ndash;allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>&ndash;&gt;-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['id', 'position'],
  mounted() {},
  computed: {
    ...mapGetters(['']),
  },
  methods: {
    ...mapActions([]),
  },
}
</script>
