<template>
  <div class="scroll_content">
    <div class="white_box">
      <div class="box_inner">
        <div class="box_heading">
          <h3>Overview</h3>
        </div>
        <div class="box_content clear">
          <template v-if="type === 'youtube'">
            <div class="rating_box col-md-4">
              <div class="inner" e>
                <h3
                  v-tooltip.top-center="
                    stringToInt(preview.average_interaction, true)
                  "
                  data-cy="preview_interaction"
                  >{{ intToString(preview.average_interaction) }}</h3
                >
                <p>Interaction / 1k Subscribers</p>
              </div>
            </div>
            <div class="rating_box col-md-4">
              <div class="inner">
                <h3
                  v-tooltip.top-center="
                    'The average duration of an uploaded video for this channel'
                  "
                  data-cy="preview_runtime"
                  >{{ secondsToString(preview.average_run_time) }}</h3
                >
                <p>Average Length of Video</p>
              </div>
            </div>
            <div class="rating_box col-md-4">
              <div class="inner">
                <h3
                  v-tooltip.top-center="stringToInt(preview.average_view, true)"
                  data-cy="preview_view"
                  >{{ intToString(preview.average_view) }}</h3
                >
                <p>Average View per Video</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="rating_box col-md-6">
              <!--                          :class="{'col-md-6': type !== 'instagram', 'col-md-6': type === 'instagram'}"-->
              <div class="inner" v-if="type === 'twitter'">
                <h3
                  v-tooltip.top-center="
                    stringToInt(preview.average_interaction_per_tweet, true)
                  "
                  data-cy="preview_interaction"
                  >{{ intToString(preview.average_interaction_per_tweet) }}</h3
                >
                <p>Interaction / tweet</p>
              </div>
              <div class="inner" v-else>
                <h3
                  v-tooltip.top-center="
                    stringToInt(preview.average_interaction, true)
                  "
                  data-cy="preview_interaction"
                  >{{ intToString(preview.average_interaction) }}</h3
                >
                <p>Interaction / 1k Followers</p>
              </div>
            </div>
            <div class="rating_box col-md-6" v-if="type === 'twitter'">
              <!--                          :class="{'col-md-6': type !== 'instagram', 'col-md-6': type === 'instagram'}"-->
              <div class="inner">
                <h3
                  v-tooltip.top-center="stringToInt(preview.listed_count)"
                  data-cy="preview_listed_count"
                  >{{ intToString(preview.listed_count) }}</h3
                >
                <p>Listed Count</p>
              </div>
              <!--                            <div class="inner" v-else-if="type === 'instagram'">-->
              <!--                                <h3 v-tooltip.top-center="stringToInt(preview.average_view_per_video)" data-cy="preview_video_view">{{intToString(preview.average_view_per_video)}}</h3>-->
              <!--                                <p>Average number of views per Video</p>-->
              <!--                            </div>-->
            </div>
            <div class="rating_box col-md-6" v-if="type === 'instagram'">
              <div class="inner">
                <h3
                  v-if="preview.lower_bound_cpc && preview.upper_bound_cpc"
                  v-tooltip.top-center="
                    '$' +
                    stringToInt(preview.lower_bound_cpc) +
                    '  -  ' +
                    '$' +
                    stringToInt(preview.upper_bound_cpc)
                  "
                  data-cy="preview_listed_cpc"
                  >${{ intToString(preview.lower_bound_cpc) }} - ${{
                    intToString(preview.upper_bound_cpc)
                  }}</h3
                >
                <h3 v-else data-cy="preview_listed_cpc"> - </h3>
                <p>Estimated Cost Per Post</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div
      class="white_box"
      v-if="preview.top_interests && preview.top_interests.length > 0"
    >
      <div class="box_inner">
        <div class="box_heading">
          <h3>Interests</h3>
        </div>
        <div class="box_content">
          <div class="tag_list">
            <template
              v-for="(interest, index) in preview.top_interests"
              v-if="preview.top_interests && preview.top_interests.length > 0"
            >
              <span
                v-if="type === 'twitter'"
                class="tag"
                :data-cy="'preview_interest_' + index"
                >{{ interest.topic }}</span
              >
              <span
                v-else-if="type === 'instagram'"
                class="tag"
                :data-cy="'preview_interest_' + index"
                >{{ interest.label }}</span
              >
            </template>
            <template v-else>
              <p
                v-if="type === 'twitter'"
                v-html="noContentMessage(preview.display_name, 'interest')"
              ></p>
              <p
                v-else-if="type === 'instagram'"
                v-html="noContentMessage(preview.full_name, 'interest')"
              ></p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="white_box"
      v-if="
        type === 'youtube' && preview.interests && preview.interests.length > 0
      "
    >
      <div class="box_inner">
        <div class="box_heading">
          <h3>Interests</h3>
        </div>
        <div class="box_content">
          <div class="tag_list">
            <template v-for="(interest, index) in preview.interests">
              <span class="tag" :data-cy="'preview_interest_' + index">{{
                interest.label
              }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="white_box" v-if="type !== 'youtube'">
      <div class="box_inner">
        <div class="box_heading">
          <h3>Top Hashtags</h3>
        </div>
        <div class="box_content">
          <div class="tag_list">
            <beat-loader
              v-if="getPublishLoaders.fetchHashTagsVelocity"
              :color="'#436aff'"
            ></beat-loader>
            <div
              v-else-if="
                getSocialSharingSelection.tagsSuggestions &&
                getSocialSharingSelection.tagsSuggestions.items &&
                getSocialSharingSelection.tagsSuggestions.items.length > 0
              "
              v-for="(item, index) in getSocialSharingSelection.tagsSuggestions
                .items"
              class="tag"
              :data-cy="'hashtags_div_' + index"
              >{{ getHashTagAttr(item) }}
              <span
                class="text_detail"
                :data-cy="'hashtags_velocity_' + index"
                v-if="item.retweets && type === 'twitter'"
                ><strong>{{ item.retweets }}</strong> Avg. Tweets/Hour</span
              >
              <span
                class="text_detail"
                :data-cy="'hashtags_velocity_' + index"
                v-if="item.count && type === 'instagram'"
                ><strong>| {{ item.count }}</strong> Posts</span
              >

              <div
                class="twitter_list_dropdown prevent_close_dropdown checkbox_dropdown dropdown option_dropdown default_style_dropdown"
              >
                <div
                  class=""
                  data-toggle="dropdown"
                  @click="openDropdownHashtags(getHashTagAttr(item))"
                >
                  <span
                    v-tooltip="'Remove from hashtags list'"
                    class="add-icon fa fa-minus added"
                    v-if="checkHashtagsExist(getHashTagAttr(item))"
                  ></span>
                  <span
                    v-tooltip="'Add to hashtags list'"
                    class="add-icon cs-plus"
                    v-else
                  ></span>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner">
                    <li
                      class="list_item_li d-block"
                      v-for="folder in getWorkspaceHashtags.items"
                      @click.prevent="
                        hashTagsFolderAction(getHashTagAttr(item), folder)
                      "
                    >
                      <span>{{ folder.name }}</span>
                      <div class="added-text">
                        <i
                          v-if="folder.items.indexOf(getHashTagAttr(item)) > -1"
                          class="fa fa-check-circle"
                        ></i>
                      </div>
                    </li>

                    <li
                      v-if="getWorkspaceHashtags.items.length === 0"
                      class="no_account_li text-left"
                    >
                      <p
                        >You have not create any list. Click below button to
                        create new list.</p
                      >
                    </li>

                    <li
                      class="no_text_li"
                      v-if="!createHashtagsStatus"
                      @click.prevent="createHashtagsStatus = true"
                    >
                      <!--change by arif-->
                      <i class="cs-plus"></i><span>Create a new list</span>
                    </li>

                    <li
                      class="no_text_li border-top-0"
                      v-if="createHashtagsStatus"
                    >
                      <div class="create_input">
                        <input
                          v-model="create.name"
                          type="text"
                          placeholder="Enter hashtag name"
                        />
                        <div class="input_icon">
                          <clip-loader
                            :color="'#ffffff'"
                            :size="'16px'"
                            v-if="getNewWorkspaceHashtagLoader"
                          ></clip-loader>
                          <template v-else>
                            <img
                              @click.prevent="createHashtags()"
                              class="add_folder"
                              src="../../../../assets/article/check_icon.svg"
                              alt="Add Folder"
                            />
                            <img
                              class="remove"
                              @click.prevent="createHashtagsCancel()"
                              src="../../../../assets/article/delete_icon.svg"
                              alt="Cancel"
                            />
                          </template>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <template v-else>
              <p
                v-if="type === 'twitter'"
                v-html="noContentMessage(preview.display_name, 'hashtags')"
              ></p>
              <p
                v-else-if="type === 'instagram'"
                v-html="noContentMessage(preview.full_name, 'hashtags')"
              ></p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template v-if="preview.user_id || preview.channel_id">
      <template v-if="type === 'twitter'">
        <influencer-domain-chart
          class="mb-3"
          title="Domain Shares"
          :data="influencerChartData(preview.domains_shared)"
        ></influencer-domain-chart>
        <influencer-sentiments-chart
          title="Sentiments"
          class="mb-3"
          :data="influencerChartData(preview.top_sentiments)"
        ></influencer-sentiments-chart>
        <influencer-languages-chart
          title="Languages"
          class="mb-3"
          :data="influencerLanguageChartData(preview.languages)"
        ></influencer-languages-chart>
      </template>
      <template v-else-if="type === 'youtube'">
        <influencer-languages-chart
          title="Languages"
          class="mb-3"
          :data="influencerLanguageChartData(preview.languages)"
        ></influencer-languages-chart>
      </template>
      <template v-else>
        <influencer-languages-chart
          title="Languages"
          class="mb-3"
          :data="influencerLanguageChartData(preview.language)"
        ></influencer-languages-chart>
      </template>
    </template>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import InfluencerDomainChart from './charts/InfluencerDomainChart'
import InfluencerSentimentsChart from './charts/InfluencerSentimentsChart'
import InfluencerLanguagesChart from './charts/InfluencerLanguagesChart'
import { isoLanguages } from '@common/constants/iso-languages'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  components: {
    InfluencerDomainChart,
    InfluencerSentimentsChart,
    InfluencerLanguagesChart,
    RadialProgressBar
  },
  props: {
    preview: {
      average_interaction: 123
    },
    score: {},
    type: ''
  },
  data () {
    return {
      createHashtagsStatus: false,
      create: {
        name: '',
        hashtag: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSocialSharingSelection',
      'getPublishLoaders',
      'getWorkspaceHashtags',
      'getNewWorkspaceHashtagLoader'
    ])
  },
  methods: {
    ...mapActions(['addWorkspaceHashtagItems', 'saveWorkspaceHashtag']),
    ...mapMutations([
      'RESET_NEW_WORKSPACE_HASHTAG',
      'SET_NEW_WORKSPACE_HASHTAG_NAME',
      'SET_NEW_WORKSPACE_HASHTAG_ITEMS',
      'SET_NEW_WORKSPACE_HASHTAG'
    ]),
    influencerChartData (data) {
      const finalData = []
      let count = 0
      if (data) {
        data.forEach(function (item) {
          count = count + item.count
        }, this)
        if (count === 0) return finalData
        data.forEach(function (item) {
          finalData.push([
            this.jsUcfirst(item.label),
            Math.floor((item.count / count) * 100)
          ])
        }, this)
      }
      return finalData
    },

    influencerLanguageChartData (data) {
      const finalData = []
      if (data) {
        data.forEach(function (item) {
          let language = item.language
          if (isoLanguages[language]) {
            language = isoLanguages[language].englishName
          }
          finalData.push([
            language,
            parseFloat(parseFloat(item.usage_percentage).toFixed(2))
          ])
        }, this)
      }

      return finalData
    },

    jsUcfirst (string) {
      if (typeof string !== 'string') return ''
      if (!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    createHashtagsCancel () {
      this.createHashtagsStatus = false
      this.create.name = ''
    },

    openDropdownHashtags (tag) {
      this.createHashtagsStatus = false
      this.create = {
        hashtag: tag,
        name: ''
      }
    },

    checkHashtagsExist (tag) {
      let exist = false
      this.getWorkspaceHashtags.items.forEach(function (folder) {
        if (!exist && folder.items.indexOf(tag) > -1) exist = true
      }, this)
      return exist
    },

    getHashTagAttr (item) {
      if (this.type === 'instagram') return item.hashtag
      return item.tag
    },

    noContentMessage (name, type) {
      if (type === 'hashtags') {
        return (
          'Our robots are working on identifying the hashtags for ' + name + '.'
        )
      } else if (type === 'interest') {
        return (
          'Our robots are working on identifying the interests for ' +
          name +
          '.'
        )
      }
      return ''
    },

    async hashTagsFolderAction (tag, folder) {
      folder = JSON.parse(JSON.stringify(folder))
      const index = folder.items.indexOf(tag)
      if (index > -1) folder.items.splice(index, 1)
      else folder.items.push(tag)

      this.RESET_NEW_WORKSPACE_HASHTAG()
      this.SET_NEW_WORKSPACE_HASHTAG(JSON.parse(JSON.stringify(folder)))
      await this.$store.dispatch('saveWorkspaceHashtag', 'influencer', this)
    },

    async createHashtags () {
      this.RESET_NEW_WORKSPACE_HASHTAG()
      this.$store.dispatch('addWorkspaceHashtagItems', this.create.hashtag)
      this.$store.commit('SET_NEW_WORKSPACE_HASHTAG_NAME', this.create.name)
      const res = await this.$store.dispatch(
        'saveWorkspaceHashtag',
        'miscellaneous',
        this
      )
      if (res) {
        this.createHashtagsCancel()
        this.create.hashtag = ''
      }
    },

    secondsToString (seconds) {
      var numyears = Math.floor(seconds / 31536000)
      var numdays = Math.floor((seconds % 31536000) / 86400)
      var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
      var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
      var numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
      if (numyears > 0) {
        return (
          numyears +
          ' years ' +
          numdays +
          ' days ' +
          numhours +
          ' hours ' +
          numminutes +
          ' min ' +
          numseconds +
          ' s'
        )
      } else if (numdays > 0) {
        return (
          numdays +
          ' days ' +
          numhours +
          ' hours ' +
          numminutes +
          ' min ' +
          numseconds +
          ' s'
        )
      } else if (numhours > 0) {
        return numhours + ' hours ' + numminutes + ' min ' + numseconds + ' s'
      } else if (numminutes > 0) return numminutes + ' min ' + numseconds + ' s'
      else if (numseconds > 0) return numseconds + ' s'
      return '-'

      // let hours = Math.floor(seconds / 3600)
      // let minutes = Math.floor((seconds - (hours * 3600)) / 60)
      // seconds = seconds - (hours * 3600) - (minutes * 60)
      //
      // return hours.toString().padStart(2, '0') + ':' +
      //       minutes.toString().padStart(2, '0') + ':' +
      //       seconds.toString().padStart(2, '0')
    }
  }
}
</script>
<style lang="less" scoped>
.influencer_detail_modal
  .modal-dialog
  .modal-content
  .modal_body
  .white_box
  .rating_box {
  width: 100%;
}
</style>
