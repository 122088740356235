<template>
  <div
    class="relative w-full min-w-[30rem] max-w-[30rem] overflow-y-auto flex flex-col px-4 pt-2 pb-4 bg-cs-light-gray"
    :style="{
      'margin-right': isGenerating || aiImages.length > 0 ? '0rem' : '-30rem',
    }"
  >
    <template v-if="aiImages.length > 0 && !isGenerating && completed">
      <div class="flex justify-between w-full items-center mt-2">
        <label for="all" class="m-0 mr-2 text-sm">Results</label>
        <div
          class="flex items-center cursor-pointer mr-[0.875rem]"
          @click="handleSelectAll"
        >
          <label for="all" class="m-0 mr-2 text-sm">{{
            `${selectAll ? 'Unselect' : 'Select'}  all`
          }}</label>
          <div class="w-[1rem] h-[1rem]">
            <Checkbox :checked="selectAll" />
          </div>
        </div>
      </div>
      <div class="max-h-[86%] overflow-y-auto mt-2">
        <ViewImage
          v-for="(image, idx) in aiImages"
          :key="`ai_image_${idx}`"
          :image="image"
        />
      </div>
    </template>
    <template v-else>
      <div class="max-h-[86%] h-full overflow-y-auto mt-4 px-2 mb-8">
        <AiGenerationLoader
          :loading="isGenerating"
          text="images"
          :loading-completed="loadingCompleted"
        />
      </div>
    </template>

    <div class="absolute bottom-1 left-0 flex justify-end px-5 py-5 w-full">
      <!-- Add to Editor -->
      <button
        v-b-tooltip="{
          content: 'Choose an image',
          disabled: selectedImageLength === 0,
        }"
        class="btn btn-studio-theme-space flex w-100 justify-center"
        :disabled="mediaUploading || isGenerating || selectedImageLength === 0"
        @click="addToEditor"
      >
        <i class="icon-add-new-member-cs mr-2"></i>
        <span class="">
          Add to Editor
          {{
            selectedImageLength
              ? `${selectedImageLength} /  ${aiImages.length}`
              : ''
          }}</span
        >

        <clip-loader
          v-if="mediaUploading"
          :color="'#9da6ac'"
          :size="'13px'"
          class="spinner ml-2"
          variant="info"
        ></clip-loader>
      </button>
    </div>
  </div>
</template>

<script>
// libraries
import { computed, defineComponent, ref } from 'vue'

// components
import ViewImage from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/ViewImage.vue'
import Checkbox from '@src/components/common/Checkbox.vue'
import AiGenerationLoader from '@src/components/common/AiGenerationLoader.vue'

export default defineComponent({
  name: 'ImagesListing',
  components: { ViewImage, Checkbox, AiGenerationLoader },
  props: {
    aiImages: {
      type: Array,
      default: () => [],
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
    uploadImagesFromLink: {
      type: Function,
      default: () => {},
    },
    loadingCompleted: {
      type: Function,
      default: () => {},
    },
    completed: {
      type: Boolean,
      default: false,
    },
    mediaUploading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const selectAll = ref(false)

    // computed
    const selectedImageLength = computed(
      () => props.aiImages.filter((item) => item.select).length
    )

    // methods
    /**
     * Select or unselect all images
     */
    const handleSelectAll = () => {
      props.aiImages.length > 0 &&
        props.aiImages.forEach((item) => {
          item.select = !selectAll.value
        })
      selectAll.value = !selectAll.value
    }

    /**
     * add all images to editor
     */
    const addToEditor = () => {
      // return images url which are selected
      const selectedUrls = props.aiImages
        .filter((item) => item.select)
        .map((item) => item.url)
      props.uploadImagesFromLink(selectedUrls)
    }
    return { handleSelectAll, selectAll, addToEditor, selectedImageLength }
  },
})
</script>
