<template>
  <div class="profile_picture" :class="[flex_class, type + '-color']">
    <div
      class="picture_block picture_block_background"
      :class="{ icons_height_md: channels_size === '28px' }"
    >
      <!--<i v-if="showExpiredWarning(account)" class="fas fa-exclamation warning_icon_sm_3"></i>-->
      <div
        v-show="showIcon"
        class="icon flex justify-center items-center"
        :class="{ shopify_icon: type === 'shopify' }"
      >
        <template v-if="type === 'gmb'">
          <i class="fab fa-google"></i>
        </template>
        <template v-else-if="type === 'tiktok'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style="width: 10px"
          >
            <path
              id="svg_1"
              fill="#ffffff"
              d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
              class="selected"
              fill-opacity="1"
            />
          </svg>
        </template>
        <template v-else>
          <i :class="'cs-' + type"></i>
        </template>
      </div>
      <div
        class="img"
        :style="{ 'background-image': channelBackgroundImage(account, type) }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/prop-name-casing
    flex_class: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    channels_size: {
      type: String,
      default: '35px',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    // showExpiredWarning (account) {
    //   return account && account.validity && (account.validity === 'invalid' || account.validity === 'expired')
    // }
  },
}
</script>

<style lang="less" scoped>
.icons_height_md {
  width: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
}
</style>
