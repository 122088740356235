<template>
  <div class="topics-pane">
    <TopicsSidebar />
    <div v-if="allTopicsData && allTopicsData.length">
      <div class="topics-cover-stories">
        <h2 class="topics-cover-stories__heading">
          <span>Top Stories from your topics</span>
        </h2>

        <template v-for="(topic, key) in allTopicsData">
          <h3 :key="key" class="topics-cover-stories__subheading">
            <span v-if="topic.topic.name">{{ topic.topic.name }}</span>
            <span v-else>{{ topic.topic.filters.topics.name }}</span>
          </h3>
          <div :key="key" class="topics-cover-stories-container">
            <template v-if="topic.posts.length > 0">
              <template v-for="(post, index) in topic.posts">
                <CoverStoryItem :key="post._id" :post="post" :index="index" />
              </template>
            </template>
            <template v-if="topic.posts && topic.posts.length === 0">
              <div class="topics-cover-stories-container__no_posts">
                <div
                  class="topics-cover-stories-container__no_posts__container"
                >
                  <img
                    src="../../../../../../assets/img/states/no-results-available-icon.svg"
                    alt=""
                  />
                  <h3>No results available</h3>
                  <p>There are no cover stories from your curated topic.</p>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <!-- Design level work to be implemented here -->
    </div>

    <InfiniteLoading class="mt-4" @infinite="loadMoreStories">
      <span slot="spinner" class="d-block pt-3 pb-4 mb-10">
        <beat-loader></beat-loader>
      </span>

      <div slot="no-more" class="not_found_text">
        <div class="no_data_found_content">
          <NoResult msg="No More Result Found"></NoResult>
        </div>
      </div>

      <div slot="no-results">
        <div class="no_data_found_content">
          <NoResult msg="No Result Found"></NoResult>
        </div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import TopicsSidebar from '@src/modules/discovery/components/topics/sub-components/TopicsSidebar'
import http from '@common/lib/http-common'
import axios from 'axios'
import { discoveryBaseUrl } from '@src/config/api-utils'
import CoverStoryItem from '@src/modules/discovery/components/common/components/CoverStoryItem'
import { EventBus } from '@common/lib/event-bus'
import NoResult from '@src/modules/composer_v2/components/SidebarAssistant/NoResult'

export default {
  components: {
    TopicsSidebar,
    CoverStoryItem,
    InfiniteLoading,
    NoResult,
  },
  data() {
    return {
      limit: 6,
      page: 1,
      allTopics: [],
      allTopicsData: [],
      is_cover_stories_loading: true,
    }
  },
  created() {
    this.actionGetCoverStories()
  },
  mounted() {
    EventBus.$on('favorite-post-item', async (payload) => {
      console.log('EventBus:favorite-post-item ', payload)
      const resFavorite = this.$store.dispatch('favoritePostItem', {
        folder_id: payload.folderId,
        post_id: payload.postId,
      })
      resFavorite.then((res) => {
        if (res.data.status) {
          this.allTopicsData.forEach((cItem) => {
            const cItems = cItem.posts.filter(
              (pItem) => pItem._source.id === payload.postId
            )
            if (cItems.length > 0) {
              const firstItem = cItems[0]
              firstItem._source.is_favorite = true
              firstItem._source.is_active = true
            }
          })
          EventBus.$emit('favorite-post-item-preview')
        }
      })
    })
  },
  beforeDestroy() {
    EventBus.$off('favorite-post-item')
  },
  methods: {
    async actionGetCoverStories() {
      console.log('ActionGetCoverStories.')
      // getMyCuratedTopicsList
      let myCuratedTopics = this.$store.getters.getMyCuratedTopicsList
      myCuratedTopics = myCuratedTopics.map((item) => {
        item.isCurated = true
        return item
      })
      this.allTopics = myCuratedTopics.concat(this.$store.getters.getTopics)
    },

    async loadMoreStories($state) {
      await this.loadCoverStories()
      if (
        this.allTopicsData.length <= 0 ||
        this.allTopics.length <= this.allTopicsData.length
      ) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },

    async loadCoverStories() {
      this.is_cover_stories_loading = true
      const end = this.page * this.limit
      const start = end - this.limit
      const topics = []
      for (let i = start; i < end; i++) {
        if (this.allTopics[i]) {
          topics.push(this.allTopics[i])
        }
      }
      if (topics.length === 0) {
        this.is_cover_stories_loading = false
        return
      }
      await this.retrieveCoverStories(topics)
      this.is_cover_stories_loading = false
    },

    async retrieveCoverStories(topics) {
      console.debug('retrieveCoverStories', topics)
      const listOfRequests = []
      let endPoint = 'retrieve_cover_story_custom_topic'
      topics.forEach((item) => {
        if (item.isCurated) {
          endPoint = 'retrieve_cover_story_curated_topic'
        } else {
          endPoint = 'retrieve_cover_story_custom_topic'
        }
        const request = http.post(`${discoveryBaseUrl}v2/topics/${endPoint}`, {
          workspace_id: this.getActiveWorkspace._id,
          topic_id: item._id,
        })
        listOfRequests.push(request)
      })
      await axios.all(listOfRequests).then((responses) => {
        console.log('list of batch requests responses =>', responses)
        this.page += 1
        responses.forEach((response) => {
          if (response.data.status) {
            this.allTopicsData.push(response.data)
          }
        })
      })
    },
  },
}
</script>
