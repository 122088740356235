<template>
  <div
    class="setting_profile_component"
    :class="{ 'pl-4': $route.name === 'reports_setting' }"
  >
    <div class="component_inner">
      <div class="ps_box">
        <h2 class="box_heading">Reports Settings</h2>
        <div class="padding-1em">
          <div class="flex-row d-flex m-0">
            <div class="col-7">
              <div class="field_group">
                <p
                  >Add your company name and logo and make them automatically
                  appear in the analytics reports.</p
                >
              </div>

              <div class="field_group">
                <input
                  type="text"
                  id="companyName"
                  placeholder="Type here..."
                  v-model="name"
                />
                <label for="companyName" class="label-animated"
                  >Company Name</label
                >
              </div>

              <div class="field_group">
                <button
                  @click="updateCompanyName"
                  class="btn btn-studio-theme-space"
                  ><span>Update</span></button
                >
                &nbsp;<clip-loader
                  v-if="getSettingLoaders.saveCompanyName"
                  class="spinner"
                  :color="'#6d76ff'"
                  :size="'12px'"
                ></clip-loader>
              </div>
            </div>

            <div class="col-3">
              <div class="field_group">
                <p class="company-logo"> Company Logo </p>
              </div>
              <div class="logo-dotted-box">
                <div class="upload_section">
                  <div class="img">
                    <img v-if="logo" :src="logo" />
                    <img
                      v-else
                      src="../../../assets/img/logo/logo_text_logo.png"
                    />
                  </div>

                  <clip-loader
                    v-if="getSettingLoaders.saveCompanyImage"
                    class="center_loader"
                    :color="'#ffffff'"
                    :size="'16px'"
                  ></clip-loader>
                  <template v-else>
                    <div
                      class="upload_btn_block"
                      @click="triggerImageFileChange"
                    >
                      <!--                  <label for="upload">-->
                      <input
                        id="upload"
                        @change.prevent="updateCompanyImage"
                        type="file"
                        autocomplete="off"
                        accept="image/*"
                      />
                      <i class="fal fa-upload"><p>Upload</p></i>
                      <!--                  </label>-->
                    </div>
                  </template>
                </div>
              </div>
              <div class="file-info"
                >*Recommended: JPG, GIF or PNG(100x100). Max size 100KB.</div
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { reportSettingURL } from '../../account/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'

export default {
  components: {},
  data () {
    return {
      loader: false,
      name: 'ContentStudio',
      logo: ''
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getSettingLoaders'])
  },
  mounted: async function () {
    this.getCompanyDetails()
    this.name = this.name ? this.name : 'ContentStudio'
    this.loader = false
  },
  methods: {
    ...mapActions(['setCompanyImageLoader', 'setCompanyNameLoader']),
    triggerImageFileChange () {
      console.debug('Method:triggerImageFile')
      document.getElementById('upload').click()
    },
    updateCompanyName: function () {
      const formData = new FormData()
      formData.append('company_name', this.name)
      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('type', 'name')
      this.setCompanyNameLoader(true)

      this.postRequest(
        reportSettingURL,
        formData,
        (response) => {
          if (response.data.status === true) {
            this.alertMessage(
              'Company name has been successfully updated.',
              'success'
            )
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change the company name.', 'error')
          }
          this.setCompanyNameLoader(false)
        },
        (error) => {
          this.setCompanyNameLoader(false)

          console.debug('Exception in updateCompanyName', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
    updateCompanyImage (event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      console.log(files)
      formData.append('file', files[0])
      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('type', 'image')
      this.setCompanyImageLoader(true)
      this.postRequest(
        reportSettingURL,
        formData,
        (response) => {
          if (response.data.status === true) {
            this.logo = response.data.media.link
            this.alertMessage(
              'Company image has been successfully updated.',
              'success'
            )
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change the profile image.', 'error')
          }
          this.setCompanyImageLoader(false)
        },
        (error) => {
          this.setCompanyImageLoader(false)
          console.debug('Exception in updateCompanyImage', error)
          event.target.value = ''
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
    getCompanyDetails () {
      this.name = this.getActiveWorkspace.company_name
      this.logo = this.getActiveWorkspace.company_logo
    }
  },
  watch: {}
}
</script>
<style scoped>
.spinner {
  bottom: 0.75rem;
  position: absolute;
  left: 6rem;
}
</style>
