/* eslint-disable */
export const AppAnalyticsMixin = {
  methods: {
    analyticsTrackPage() {
      if (window.IndicativeHandler) {
        window.IndicativeHandler.buildEvent(
          'app page viewed: ' + document.title,
          {
            page_name: document.title,
            url: window.location.pathname,
          }
        )
      }
    },

    analyticsRegisterUser(userId, userProperties) {
      if (window.IndicativeHandler) {
        window.IndicativeHandler.setUniqueID(userId)
        window.IndicativeHandler.addProperties(userProperties)
      }
    },

    analyticsUnsubscribeUser() {
      if (window.IndicativeHandler) {
        window.IndicativeHandler.clearUniqueID()
      }
    },

    analyticsTrackEvent(eventName, eventProperties) {
      if (window.IndicativeHandler) {
        window.IndicativeHandler.buildEvent(eventName, eventProperties)
      }
    },

    analyticsTrackEventWithIdentifier(
      eventName,
      eventIdentifier,
      eventProperties
    ) {
      if (window.IndicativeHandler) {
        window.IndicativeHandler.buildEvent(
          eventName,
          eventIdentifier,
          eventProperties
        )
      }
    },
  },
}
