var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade normal_modal retweet_model",attrs:{"id":"influencer_follow_modal"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),(_vm.getTwitterAccounts.items.length === 0)?_c('div',{staticClass:"modal_body"},[_c('influencer-twitter-no-account-exist')],1):[_c('div',{staticClass:"modal_body"},[(_vm.getInfluencerFollow.fetchInfluencerFollowersLoader)?_c('div',{staticClass:"p-4"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1):_c('div',{staticClass:"social_accounts follow-accounts"},_vm._l((_vm.getTwitterAccounts.items),function(account,index){return _c('div',{staticClass:"follow-item d-flex align-items-center"},[_c('div',{staticClass:"checkbox_input_image checkbox_social_profile"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.channelTooltipHTML('twitter', account),
                    classes: 'team_tooltip',
                  }),expression:"{\n                    content: channelTooltipHTML('twitter', account),\n                    classes: 'team_tooltip',\n                  }"}],staticClass:"checkbox_right",attrs:{"for":'selection_' + account.twitter_id}},[_c('individual-channel-tooltip',{attrs:{"account":account,"type":'twitter'}})],1),_c('span',{staticClass:"ml-3 align-content-center"},[(_vm.isAccountFollow(account._id))?[_vm._v(" You twitter account "+_vm._s(account.name)+" already following this influencer. ")]:[_vm._v(" Follow influencer from your twitter account ("+_vm._s(account.name)+"). ")]],2)]),_c('button',{staticClass:"btn gradient_btn ml-auto",class:{ dark_gray: _vm.isAccountFollow(account._id) },attrs:{"data-cy":'twitter_influencer_follow_button_' + account._id,"disabled":account.is_processing},on:{"click":function($event){$event.preventDefault();_vm.processInfluencerFollow({
                    account: account._id,
                    follow: !_vm.isAccountFollow(account._id),
                    index: index,
                  })}}},[(_vm.isAccountFollow(account._id))?_c('span',[_vm._v("Unfollow")]):_c('span',[_vm._v("Follow")])])])}),0)])]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_head d-flex align-items-center"},[_c('h2',[_vm._v("Follow/Unfollow")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }