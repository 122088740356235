<template>
  <span :class="`integration-icon-${icon}`">
    <span v-for="path in numberOfPaths()" :class="`path${path}`" :key="path"></span>
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'facebook',
      required: true
    }
  },
  methods: {
    numberOfPaths() {
      if (this.icon === 'facebook' || this.icon === 'instagram' || this.icon === 'twitter' || this.icon === 'youtube' || this.icon === 'linkedin' || this.icon === 'tumblr' || this.icon === 'pinterest' || this.icon === 'webflow') {
        return 2
      } else if (this.icon === 'shopify' || this.icon === 'wordpress') {
        return 3
      } else if (this.icon === 'medium') {
        return 4
      } else if (this.icon === 'tiktok') {
        return 8
      } else if (this.icon === 'gmb') {
        return 11
      } else {
        return 0
      }
    }
  },

}
</script>