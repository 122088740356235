<template>
  <div class="stats-card">
    <div class="stats-card__left flex items-center">
      <img
        v-if="variant === 'city'"
        class="stats-card__variant-city"
        src="../../../../assets/img/analytics/map-pin-tag.svg"
      />
      <img
        v-else
        class="stats-card__variant-country mr-2"
        :src="
          require('@src/assets/img/analytics/flags/' + title.toLowerCase() + '.svg')
        "
      />
      <h3 class="stats-card__title">
        {{ getOriginalName() }}
      </h3>
    </div>
    <div class="stats-card__right">
      {{ value }}
    </div>
  </div>
</template>

<script>
import { isoCountries } from '@common/constants/iso-countries'
export default {
  name: 'StatsCard',
  props: {
    title: '',
    variant: '',
    value: 0
  },
  methods: {
    getOriginalName () {
      return this.variant === 'country' ? isoCountries[this.title] : this.title
    }
  }
}
</script>
