<template>
  <div class="cst-switch" :class="{ 'focus:outline-none cursor-pointer pointer-events-none filter-none opacity-50': disabled }">
    <div class="inline-flex justify-between items-center cursor-pointer" @click="toggleActive = !toggleActive">
      <div class=" flex items-center bg-gray-300 rounded-full px-1 py-2.5 duration-300 ease-in-out" :class="[{ 'cst-switch-active-bg': toggleActive }, sizeLineClasses]">
        <div class="rounded-full rounded-circle shadow-md border-solid border-white transform duration-300 ease-in-out" :class="[sizeDotActiveClasses, sizeDotClasses]"></div>
      </div>

      <label v-if="label" class="mb-0 ml-2 font-semibold" :class="labelClasses">{{label}}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstSwitch',
  props: {
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      }
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      toggleActive: this.value,
    };
  },
  computed: {
    labelClasses: function () {
      return {
        'text-sm':  this.size === 'small',
        'text-base':  this.size === 'default' || this.size === 'large',
      }
    },
    sizeLineClasses: function () {
      return {
        'w-8 h-3.5':  this.size === 'small',
        'w-14 h-8':  this.size === 'default',
        'w-16 h-10':  this.size === 'large',
      }
    },
    sizeDotClasses: function () {
      return {
        'border-6 w-2 h-2':  this.size === 'small',
        'border-4 w-6 h-6':  this.size === 'default',
        'border-8 w-8 h-8':  this.size === 'large',
      }
    },
    sizeDotActiveClasses: function () {
      return {
        'cst-switch-active-bg translate-x-3':  this.size === 'small' && this.toggleActive,
        'cst-switch-active-bg translate-x-7':  (this.size === 'default' || this.size === 'large') && this.toggleActive,
      }
    }
  },

  watch: {
    value: function (value) {
      this.toggleActive = value
    },
    toggleActive: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.cst-switch {
  .border-6{
    border-width: 6px;
  }
  .cst-switch-active-bg {
    background:#549aee;
  }

  .translate-x-4 {
    transform: translateX(1rem);
  }

  .translate-x-6 {
    transform: translateX(0.5rem);
  }
}

</style>
