var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"optimize_block with_opt_box_shadow canva_image"},[_c('div',{staticClass:"opt_box"},[_vm._m(0),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dropdown default_style_dropdown",attrs:{"data-cy":"platform-selection"}},[_c('canva-types',{attrs:{"dropdownPosition":'dropdown-menu-left',"type":"Blog"}})],1),(
          _vm.getBlogPostingDetails.image.link &&
          _vm.getBlogPostingDetails.image.source === 'Dropzone'
        )?_c('div',{staticClass:"info_sec"},[_c('a',{staticClass:"btn red remove_btn enhance_image",attrs:{"id":"ImageEnhance"},on:{"click":function($event){$event.preventDefault();return _vm.triggerSharingImageEnhance('Blog')}}},[_c('i',{staticClass:"fas fa-magic"}),_vm._v("  Enhance Image")])]):_vm._e()]),_c('div',{staticClass:"image_upload_block d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"drop_img",on:{"click":function($event){$event.preventDefault();return _vm.displayFile('Dropzone')}}},[_c('vue2-dropzone',{ref:"blogImageDropzone",attrs:{"id":"blogImageDropzone","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingEvent,"vdropzone-success":_vm.vDropzoneCallback,"vdropzone-error":_vm.vDropzoneCallback,"vdropzone-removed-file":function($event){return _vm.changeBlogPostingImage(null)}}})],1),(_vm.separatorSectionVisibilityStatus)?_c('div',{staticClass:"or_sep"},[_c('span',{staticClass:"sep_text"},[_vm._v("OR")])]):_vm._e(),(_vm.getBlogPostingLoaders.upload_blog_image)?[_c('div',{staticClass:"new_image loader_overlay_with_loader"},[_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}}),_vm._m(1),_c('p',[_vm._v("Uploading...")])],1)]:(
          _vm.getBlogPostingDetails.image.link &&
          _vm.getBlogPostingDetails.image.source !== 'Dropzone'
        )?[_c('div',{staticClass:"new_image"},[_c('img',{attrs:{"src":_vm.getBlogPostingDetails.image.link,"alt":""},on:{"click":function($event){$event.preventDefault();return _vm.displayFile(_vm.getBlogPostingDetails.image.source)}}}),_c('div',{staticClass:"info_sec"},[_c('a',{staticClass:"btn gradient_btn enhance_btn",on:{"click":function($event){$event.preventDefault();return _vm.triggerSharingImageEnhance('Blog')}}},[_vm._v("Enhance")]),_c('a',{staticClass:"btn red remove_btn",on:{"click":function($event){$event.preventDefault();return _vm.changeBlogPostingImage(null)}}},[_vm._v("Remove")])])])]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head_text"},[_c('h3',[_vm._v("Featured Image")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image_icon_gray"},[_c('i',{staticClass:"cs-gallery"})])
}]

export { render, staticRenderFns }