<template>
  <div class="card_container">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Top Posts by Engagement</h2>
        </div>
        <div class="card_content">
          <div class="social_table top_post_table">
            <div class="table_wrapper">
              <table class="table">
                <thead :class="{ report_head: report }">
                  <tr>
                    <th>Post</th>
                    <th style="width: 140px" class="text-center">
                      <span
                        :class="{
                          active: checkAscendingOrder(
                            '_source.total_engagement',
                          ),
                        }"
                      >
                        Engagement
                      </span>

                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder(
                              '_source.total_engagement',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.total_engagement',
                              'asc',
                            )
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder(
                              '_source.total_engagement',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.total_engagement',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th style="width: 90px" class="text-center">
                      <span> Reach </span>
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder(
                              '_source.total_impressions',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.total_impressions',
                              'asc',
                            )
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder(
                              '_source.total_impressions',
                            ),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.total_impressions',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th style="width: 120px" class="text-center">
                      <span> Post Clicks </span>
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder('_source.post_clicks'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.post_clicks',
                              'asc',
                            )
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder('_source.post_clicks'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook(
                              '_source.post_clicks',
                              'desc',
                            )
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th
                      v-if="!report"
                      style="width: 10.625rem"
                      class="text-center"
                    >
                      <span> Reactions </span>
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder('_source.total'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook('_source.total', 'asc')
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder('_source.total'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook('_source.total', 'desc')
                          "
                        >
                        </i>
                      </template>
                    </th>
                    <th v-if="!report" style="width: 120px" class="text-center">
                      <span> Comments </span>
                      <template v-if="!report">
                        <i
                          v-tooltip="v_ascending"
                          class="sorting_icon cs-arrow-up"
                          :class="{
                            active: checkAscendingOrder('_source.comments'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook('_source.comments', 'asc')
                          "
                        >
                        </i>
                        <i
                          v-tooltip="v_descending"
                          class="sorting_icon cs-arrow-down"
                          :class="{
                            active: checkDescendingOrder('_source.comments'),
                          }"
                          @click.prevent="
                            changeSortOrderFacebook('_source.comments', 'desc')
                          "
                        >
                        </i>
                      </template>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-if="getOrderedPosts.length > 0">
                    <tr v-for="post in getOrderedPosts">
                      <td
                        @click.prevent="
                          EventBus.$emit(
                            'facebook-analytics-preview',
                            post._source,
                          )
                        "
                      >
                        <FacebookPostView
                          :post="post._source"
                        ></FacebookPostView>
                      </td>
                      <td class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.total_engagement)
                        }}
                      </td>
                      <td class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.post_impressions)
                        }}
                      </td>
                      <td class="text-center statistics"
                        >{{ $filters.numberToCommas(post._source.post_clicks) }}
                      </td>
                      <td v-if="!report" class="text-center statistics"
                        >{{
                          $filters.numberToCommas(post._source.total + post._source.shares)
                        }}
                      </td>
                      <td v-if="!report" class="text-center statistics"
                        >{{ $filters.numberToCommas(post._source.comments) }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="6" class="make-text-center">
                      You do not have any published posts/messages.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'
import analyticsUtilsMixin from '../../common/analyticsUtilsMixin'
import FacebookPostView from '../../common/posts/FacebookPostView'
import { EventBus } from "@common/lib/event-bus";

export default {
  components: {
    FacebookPostView
  },
  mixins: [analyticsUtilsMixin],
  props: {
    posts: {
      type: Array
    },
    selected_account: {},
    report: {
      default: false
    }
  },

  data () {
    return {
      sortByRow: '_source.total_engagement',
      sortByOrder: 'desc',
      v_ascending: 'Ascending Order',
      v_descending: 'Descending Order',
      EventBus
    }
  },
  mounted () {},

  computed: {
    ...mapGetters(['getFacebookAccounts']),
    getOrderedPosts () {
      const totalPosts = orderBy(this.posts, this.sortByRow, this.sortByOrder)
      if (this.report) {
        return totalPosts.slice(0, 7)
      }
      return totalPosts
    }
  },
  methods: {
    ...mapActions([]),
    changeSortOrderFacebook (key, order) {
      this.sortByOrder = order
      this.sortByRow = key
    },
    checkAscendingOrder (row) {
      return this.sortByRow === row && this.sortByOrder === 'asc'
    },
    checkDescendingOrder (row) {
      return this.sortByRow === row && this.sortByOrder === 'desc'
    }
  }
}
</script>

<style lang="less">
.statistics {
  font-weight: bold;
  font-size: 16px !important;
}

.report_head {
  th {
    font-size: 12px !important;
  }
}
</style>
