<template>
  <ConfirmBox
    id="removeTeam"
    :call-back-action="removeTeamMember"
    confirm-text="Yes"
    cancel-text="No"
    :loader="getSettingLoaders.removeTeamMember"
  >
    <template slot="header">
      <h2>Remove Team Member</h2>
    </template>

    <template slot="footer">
      <p class="text-center">This action cannot be undone.</p>
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox,
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  created() {},
  mounted() {},

  methods: {},
}
</script>
