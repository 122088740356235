var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message",class:[_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message) ? 'm_in' : 'm_out']},[_c('div',{staticClass:"profile_picture d-flex align-items-start",class:[
      _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
        ? ''
        : 'justify-content-end',
    ]},[(_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message))?_c('div',{staticClass:"picture_block picture_block_background"},[_c('div',{staticClass:"img",style:('background: url(' +
          _vm.commonMethods.getImageForUser(_vm.temp, _vm.message) +
          ')')})]):_vm._e(),(_vm.isLoading && !_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message))?_c('clip-loader',{staticStyle:{"padding-right":"10px"},attrs:{"color":"rgba(152, 158, 181, 0.8)","size":'20px'}}):_vm._e(),_c('div',{staticClass:"text_block",staticStyle:{"display":"flex","flex-direction":"column","max-width":"80%"},style:(!_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
          ? { alignItems: 'end' }
          : { alignItems: 'flex-start' })},[(
          _vm.message.event_type === 'message' || _vm.message.event_type === 'note'
        )?[(!_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message))?_c('span',{staticClass:"bookmarked top",on:{"click":function($event){return _vm.updateBookmark()}}},[(_vm.message.is_starred)?[_c('i',{staticClass:"fas fa-star marked"})]:[_c('i',{staticClass:"far fa-star"})]],2):_vm._e()]:_vm._e(),(_vm.message.event_type === 'note')?[_c('div',{staticClass:"__text",class:[
            _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
              ? '_user_chat'
              : '',
          ],staticStyle:{"background-color":"rgb(251, 252, 225)","white-space":"pre-line","width":"100%"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(_vm.message.created_time)))])]:(_vm.message.event_type === 'message')?[(
            typeof _vm.message.attachment != 'undefined' &&
            _vm.message.attachment != null &&
            _vm.message.attachment.length > 0
          )?_vm._l((_vm.message.attachment),function(attachment){return _c('div',{key:attachment.id},[(attachment.type === 'text/plain')?[_c('div',{staticClass:"__text",class:[
                  _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                    ? '_user_chat'
                    : '',
                ],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"_file d-flex align-items-start"},[_vm._m(0,true),_c('div',{staticClass:"file_detail"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(attachment.name))])])])]),_c('p',{staticClass:"sub_text"},[_vm._v(" "+_vm._s(_vm.commonMethods.timesAgo(_vm.message.created_time)))])]:[(attachment.type.indexOf('image') >= 0)?[_c('div',{staticClass:"__text",class:[
                    _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                      ? '_user_chat'
                      : '',
                  ],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"_images"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],attrs:{"src":attachment.url,"height":"200"}})])])]:(attachment.type.indexOf('video') >= 0)?[_c('div',{staticClass:"__text",class:[
                    _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                      ? '_user_chat'
                      : '',
                  ],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"_video"},[_c('video',{attrs:{"controls":"","height":"100"}},[_c('source',{attrs:{"height":"100","src":attachment.url,"type":"video/mp4"}}),_c('source',{attrs:{"height":"100","src":attachment.url,"type":"video/ogg"}}),_vm._v(" Your browser does not support HTML5 video. ")])])])]:(attachment.type.indexOf('audio') >= 0)?[_c('div',{staticClass:"__text",class:[
                    _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                      ? '_user_chat'
                      : '',
                  ],staticStyle:{"width":"100%"}},[_c('div',{},[_c('audio',{attrs:{"id":"myAudio","controls":""}},[_c('source',{attrs:{"src":attachment.url,"type":"audio/ogg"}}),_c('source',{attrs:{"src":attachment.url,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")])])])]:_vm._e(),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(_vm.message.created_time)))])]],2)}):(
            typeof _vm.message.sticker != 'undefined' && _vm.message.sticker != null
          )?[_c('div',{staticClass:"__text",class:[
              _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                ? '_user_chat'
                : '',
            ]},[_c('div',{staticClass:"sticker"},[_c('img',{attrs:{"src":_vm.message.sticker}})])]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(_vm.message.created_time)))])]:[(
              typeof _vm.message.link != 'undefined' &&
              _vm.message.link != null &&
              _vm.message.link.title
            )?[_c('div',{staticClass:"__text",class:[
                _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                  ? '_user_chat'
                  : '',
              ],staticStyle:{"width":"100%"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLink(_vm.message.message))}}),(
                  !_vm.checkFor('email', _vm.message.message) &&
                  _vm.checkFor('url', _vm.message.message)
                )?_c('div',{staticClass:"_link d-flex align-items-start"},[_c('div',{staticClass:"content"},[_c('a',{attrs:{"href":_vm.message.link.url}},[_vm._v(_vm._s(_vm.message.link.title))]),_c('p',[_vm._v(_vm._s(_vm.message.link.description))])]),(_vm.message.link.image !== null)?_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],attrs:{"src":_vm.message.link.image,"alt":_vm.message.link.website}})]):_vm._e()]):_vm._e()])]:(
              typeof _vm.message.private_message != 'undefined' &&
              _vm.message.private_message != null &&
              _vm.message.private_message.name
            )?[_c('div',{staticClass:"__text",class:[
                _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                  ? '_user_chat'
                  : '',
              ],staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.message.message)+" "),_c('div',{staticClass:"_link d-flex align-items-start"},[_c('div',{staticClass:"content"},[_c('a',{on:{"click":function($event){return _vm.goToPost(_vm.message.private_message.post_id)}}},[_vm._v(_vm._s(_vm.message.private_message.name))])])])])]:[_c('div',{staticClass:"__text",class:[
                _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                  ? '_user_chat'
                  : '',
              ],staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(_vm.getMentionedUsersNames(_vm.message.message))}})],_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.commonMethods.timesAgo(_vm.message.created_time)))])]]:_vm._e(),(_vm.message.event_type === 'message')?[(_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message))?_c('span',{staticClass:"bookmarked bottom",on:{"click":function($event){return _vm.updateBookmark()}}},[(_vm.message.is_starred)?[_c('i',{staticClass:"fas fa-star marked"})]:[_c('i',{staticClass:"far fa-star"})]],2):_vm._e()]:_vm._e()],2),(_vm.isLoading && _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message))?_c('clip-loader',{staticStyle:{"padding-left":"10px"},attrs:{"color":"rgba(152, 158, 181, 0.8)","size":'20px'}}):_vm._e(),(
        !_vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message) &&
        _vm.message.event_type !== 'assigned' &&
        _vm.message.event_type !== 'marked_done' &&
        _vm.message.event_type !== 'archived'
      )?_c('div',{staticClass:"picture_block picture_block_background"},[(_vm.message.event_type === 'note')?_c('div',{staticClass:"img",style:('background: url(' +
          _vm.commonMethods.getUserImageForNote(_vm.temp, _vm.message) +
          ')')}):_c('div',{staticClass:"img",style:('background: url(' +
          _vm.commonMethods.getImageForUser(_vm.temp, _vm.message) +
          ')')})]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file_icon"},[_c('i',{staticClass:"fal fa-file-pdf"})])
}]

export { render, staticRenderFns }