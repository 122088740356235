<template>
  <b-modal
    id="manage-links-modal"
    body-class="p-0 rounded-md shadow-sm overflow-hidden padding-bottom-0 bg-white"
    modal-class="full_height right side_slide_right"
    header-class="cs-modal-header bg-white"
    title-class="cs-modal-title"
    dialog-class="max-w-3xl"
    size="md"
    centered
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="onModalHidden"
    @shown="onModalOpen"
  >
    <template slot="modal-title">
      <div class="flex items-center">
        {{ getModalLink }}

        <clip-loader
          v-if="data.updating.status"
          variant="info"
          class="spinner ml-3 mr-1"
          :color="'#9da6ac'"
          :size="'16px'"
        ></clip-loader>
      </div>
    </template>

    <div v-if="data.loader" class="px-4 py-5">
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
    </div>

    <div v-else class="px-4 py-6 h-full overflow-y-auto">
      <template v-if="data.selectedLink && data.selectedLink.link_id">
        <CstFloatingLabelInput
          id="edit-share-link"
          :value="data.baseUrl + 'share/planner/' + data.selectedLink.link_id"
          class="mb-6"
          type="text"
          label="Shared Link"
          readonly
          disabled
        >
        </CstFloatingLabelInput>

        <div>
          <CstFloatingLabelInput
            id="edit-link-name"
            v-model="data.selectedLink.name"
            class="mb-6"
            type="text"
            label="Share Link Name"
            maxlength="30"
          />

          <div class="flex justify-between mb-2">
            <div>
              <span class="text-gray-800 font-bold"
                >Enable Password Protection</span
              >
            </div>

            <div>
              <CstSwitch
                v-model="data.selectedLink.is_password_protected"
                size="small"
              />
            </div>
          </div>

          <CstFloatingLabelInput
            id="edit-link-password"
            v-model="data.selectedLink.password"
            class="mb-6"
            :type="!data.passwordVisible ? 'password' : 'text'"
            label="Password"
            autocomplete="off"
            :disabled="!data.selectedLink.is_password_protected"
            show-icon-right
          >
            <template slot="icon-right">
              <div @click="switchPasswordVisibility">
                <i
                  v-if="!data.passwordVisible"
                  class="cs-eye cursor-pointer text-lg"
                ></i>
                <i v-else class="cs-eye-close cursor-pointer text-lg"></i>
              </div>
            </template>
          </CstFloatingLabelInput>
        </div>
        <div class="flex justify-between mt-3.5">
          <CstButton
            size="large"
            variant="primary"
            class="bg-[#e02f5f]"
            :disabled="data.updating.status"
            @click="removeLink(data.selectedLink._id)"
          >
            Delete
            <!--            <i class="fas fa-trash"></i>-->
          </CstButton>

          <div class="flex justify-end">
            <CstButton
              size="large"
              variant="secondary"
              class="mr-3 w-28"
              text="Cancel"
              :disabled="data.updating.status"
              @click="data.selectedLink = null"
            />
            <CstButton
              variant="primary"
              size="large"
              text="Update"
              :loading="data.updating.status"
              :disabled="data.updating.status"
              @click="updateLinkDetails"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="!data.links.length" class="text-gray-800">
          No shared links found. You can create a new shared link by selecting
          the posts you want to share and clicking on the share button from the
          bulk actions.
        </div>
        <div
          v-for="(item, i) in data.links"
          v-else
          :key="i"
          class="flex flex-row justify-between items-center bg-white rounded-md shadow-sm border !border-[#eef1f4] rounded-md py-3 px-4 mb-4"
        >
          <div :class="{ grayscale: item.is_disabled }">
            <h3 class="text-base font-bold mb-1.5"
              >{{ item.name
              }}<i
                v-if="item.is_password_protected"
                v-floatingtip.hover.right="{
                  content: 'Links is password protected',
                }"
                class="fas fa-lock-alt ml-2 cursor-pointer"
              ></i>
            </h3>
            <p class="text-sm text-blue-400"
              >{{ data.baseUrl }}share/planner/{{ item.link_id }}</p
            >
          </div>
          <div class="ml-2 flex items-center text-base">
            <CstSwitch
              v-floatingtip.hover.top-center="{
                content: item.is_disabled ? 'Enable Link' : 'Disable Link',
              }"
              :value="!item.is_disabled"
              class="mr-2 flex items-center"
              size="small"
              :disabled="
                data.updating.status && data.updating.link === item._id
              "
              @input="(value) => toggleLinkStatus(item._id, value)"
            />

            <span
              v-if="!item.is_disabled"
              v-floatingtip.hover.top-center="{
                content: 'Copy Link',
              }"
              class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
              @click="
                copyLinkToClipboard(
                  `${data.baseUrl}share/planner/${item.link_id}`
                )
              "
            >
              <span class="flex items-center justify-center">
                <i class="far fa-link text-gray-700 cursor-pointer"></i>
              </span>
            </span>
            <span
              v-if="!item.is_disabled"
              v-floatingtip.hover.top-center="'Edit Link'"
              class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
              @click="editLink(item._id)"
            >
              <span class="flex items-center justify-center">
                <img
                  alt="approval_publish"
                  src="@assets/img/icons/planner/more_actions_edit.svg"
                  class="w-4"
                />
              </span>
            </span>
            <span
              v-floatingtip.hover.top-center="{
                content: 'Remove Link',
              }"
              class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
              @click="removeLink(item._id)"
            >
              <span class="flex items-center justify-center">
                <i class="far fa-trash-alt text-red-500 !shadow-none w-4"></i>
              </span>
            </span>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import CstSwitch from '@ui/Switch/CstSwitch'
import { computed, defineComponent, inject, reactive } from 'vue'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstButton from '@ui/Button/CstButton'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { swalAttributes } from '@common/constants/common-attributes'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'ManageLinksModal',
  components: {
    CstSwitch,
    CstButton,
    CstFloatingLabelInput,
    SkeletonBox,
  },

  setup() {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch, getters } = useStore()

    const data = reactive({
      links: [],
      selectedLink: null,
      passwordVisible: false,
      loader: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
      updating: {
        status: false,
        link: null,
      },
    })

    /**
     * Copy link to clipboard
     * @param link
     */
    const copyLinkToClipboard = (link) => {
      navigator.clipboard.writeText(link)
    }

    /**
     * Edit link
     * @param linkId
     */
    const editLink = (linkId) => {
      data.selectedLink = data.links.find((link) => link._id === linkId)
      data.selectedLink.password = data.selectedLink.password || ''
    }

    /**
     * Remove link
     * @param linkId
     */
    const removeLink = async (linkId) => {
      const confirm = await $bvModal.msgBoxConfirm(
        'Are you sure you want to delete the selected link?',
        {
          title: 'Delete Shared Link',
          ...swalAttributes(),
          cancelTitle: 'Cancel',
          okTitle: 'Delete',
        }
      )

      if (!confirm) {
        return
      }

      // service call to remove link
      // ...
      data.updating = {
        status: true,
        link: linkId,
      }

      try {
        await serviceShareLink(ACTION_TYPES.DELETE_LINK, {
          id: linkId,
          workspace_id: getters.getActiveWorkspace._id,
        })

        dispatch('toastNotification', {
          message: 'Link details successfully deleted' || UNKNOWN_ERROR,
          type: 'success',
        })

        // update component state
        data.links = data.links.filter((link) => link._id !== linkId)
        data.selectedLink = null
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        data.updating = {
          status: false,
          link: null,
        }
      }
    }

    /**
     * Update link details
     */
    const updateLinkDetails = async () => {
      data.updating = {
        status: true,
        link: data.selectedLink._id,
      }

      const details = { ...data.selectedLink }
      details.id = details._id
      details.workspace_id = getters.getActiveWorkspace._id

      // Remove unwanted properties
      delete details._id
      delete details.link_id
      delete details.updated_at
      delete details.created_at

      try {
        await serviceShareLink(ACTION_TYPES.UPDATE_LINK, {
          ...details,
        })

        dispatch('toastNotification', {
          message: 'Link details successfully updated' || UNKNOWN_ERROR,
          type: 'success',
        })

        data.selectedLink = null
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        data.updating = {
          status: false,
          link: null,
        }
      }
    }

    /**
     * Toggle link active/inactive state
     * @param linkId
     * @param activeState
     */
    const toggleLinkStatus = async (linkId, activeState) => {
      data.updating = {
        status: true,
        link: linkId,
      }
      try {
        await serviceShareLink(ACTION_TYPES.UPDATE_LINK, {
          ...getLinkDetails(linkId),
          is_disabled: !activeState,
        })

        // update component state
        data.links = data.links.map((link) => {
          if (link._id === linkId) {
            link.is_disabled = !activeState
          }
          return link
        })
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        data.updating = {
          status: false,
          link: null,
        }
      }
    }

    /**
     * Switch password visibility
     */
    const switchPasswordVisibility = () => {
      data.passwordVisible = !data.passwordVisible
    }

    // Helper method to get link details
    const getLinkDetails = (linkId) => {
      const link = data.links.find((link) => link._id === linkId)
      return {
        id: link._id,
        name: link.name,
        is_password_protected: link.is_password_protected,
        is_disabled: link.is_disabled,
        password: link.password,
        workspace_id: getters.getActiveWorkspace._id,
      }
    }

    const getModalLink = computed(() => {
      return data.selectedLink ? `Edit Link` : 'Manage Shared Links'
    })

    /**
     * On modal open
     */
    const onModalOpen = async () => {
      // service call to fetch links
      try {
        const response = await serviceShareLink(ACTION_TYPES.FETCH_LINKS, {
          workspace_id: getters.getActiveWorkspace._id,
        })

        data.links = response.share_links
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        data.loader = false
      }
    }
    /**
     * On modal close
     */
    const onModalHidden = () => {
      data.selectedLink = null
      data.links = []
      data.loader = true
    }
    return {
      data,
      toggleLinkStatus,
      updateLinkDetails,
      removeLink,
      copyLinkToClipboard,
      editLink,
      switchPasswordVisibility,
      onModalHidden,
      onModalOpen,
      getModalLink,
    }
  },
})
</script>
