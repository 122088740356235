<template>
  <div>
    <chart-render
      :id="'engagement_rate_highlight'"
      :options="pageEngagments()"
      :ref_value="'engagement_rate_chart'"
    ></chart-render>
    <!--Likes Breakdown by Day table-->
  </div>
</template>
<script>
import moment from 'moment'
import ChartRender from '../../common/ChartRender'
import {
  lineChart,
  lineChartTooltip,
  lineLegend,
  linePlotOptions,
  splineCredits,
  splineXAxis,
  splineYAxis,
  stackedColumnChart
} from '../../common/helper'

import { INSTAGRAM_ANALYTICS_ENGAGEMENT } from '@common/constants/messages'

export default {
  components: {
    ChartRender
  },

  props: {
    engagements: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_ENGAGEMENT
    }
  },
  computed: {},
  methods: {
    pageEngagments () {
      const lineChartTooltipModified = lineChartTooltip()
      // lineChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.y:f}</span></p>'
      const engagementsList = []
      let engagementCount
      this.engagements.buckets.forEach((item) => {
        if (item.doc_count > 0) {
          engagementsList.push([
            Date.parse(item.key_as_string.split(' ')[0]),
            item.engagement.value
          ])
          engagementCount = item.engagement.value
        } else {
          if (engagementCount) {
            engagementsList.push([
              Date.parse(item.key_as_string.split(' ')[0]),
              engagementCount
            ])
          }
        }
      })
      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: lineChartTooltipModified,
        chart: lineChart('#engagement_rate_highlight'),
        legend: lineLegend(),
        title: false,

        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        // plotOptions: linePlotOptions(),
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            lineColor: '#5e73d9',
            marker: {
              symbol: 'diamond',
              lineWidth: 2,
              fillColor: '#5e73d9',
              lineColor: '#5e73d9'
            },
            connectNulls: true
          }
        },

        series: [
          {
            name: 'Engagements',
            data: engagementsList,
            color: '#5e73d9',
            lineColor: '#5e73d9'
            // borderRadiusTopLeft: 50,
            // borderRadiusTopRight: 50
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
