<template>
  <div
    id="pinterest_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'pinterest_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Pinterest' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Pinterest' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Pinterest' })"
  >
    <div class="box_social_tag pin_bg">
      <i class="cs-pinterest"></i>
    </div>

    <div class="drag_content" v-if="isFileHover">
      <p>Drag files here</p>
    </div>

    <div class="d-flex align-items-center">
      <b-form-input
        :state="
          getPinterestSharingDetails.pinterest_title.length <= 100
            ? null
            : false
        "
        class="pinterest_title px-0 mx-2"
        @click.native="focusSharingBox('Pinterest')"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'pinterest_tab',
        }"
        placeholder="Post Title..."
        v-model="getPinterestSharingDetails.pinterest_title"
      ></b-form-input>
    </div>

    <!--<textarea-autosize :class="{hide : getSocialSharingSelection.activeTab !== 'pinterest_tab' }" class="content_textarea"
                           v-model="getPinterestOptions.postDescription"></textarea-autosize>-->

    <div class="text_content text_content_main">
      <textarea-autosize
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'pinterest_tab',
        }"
        class="content_textarea"
        @input="handleRerender()"
        id="pinterest_sharing_message"
        spellcheck="false"
        ref="pinterest_sharing_message"
        :min-height="55"
        @keydown.native="handleKeysOnPopup"
        @input.native="sharingMessageChanges($event, 'Pinterest')"
        @paste.native="sharingMessageChanges($event, 'Pinterest')"
        @keyup.space.native="sharingMessageChanges($event, 'Pinterest')"
        @focus.native="focusSharingBox('Pinterest')"
        @click.native="focusSharingBox('Pinterest')"
        @keydown.ctrl.66.native="sharingMessageFormatting('Pinterest', 'bs')"
        @keydown.ctrl.73.native="sharingMessageFormatting('Pinterest', 'is')"
      ></textarea-autosize>
      <div class="clone_txt" id="clone_txt_pinterest_tab">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'pinterest_tab',
          }"
        ></span>
        <span
          @click="
            focusSharingBox('Pinterest'),
              textareaFocus($refs.pinterest_sharing_message)
          "
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'pinterest_tab',
          }"
        ></span>
        <span
          class="exceed"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'pinterest_tab',
          }"
        ></span>
      </div>

      <hashtag-suggestions
        v-on-clickaway="hideHashtag"
        type="Pinterest"
      ></hashtag-suggestions>

      <!--          <div class="text_content_bottom">-->
      <!--            <button v-if="getPinterestSharingDetails.url && getSocialSharingSelection.activeTab == 'pinterest_tab'" class="btn btn-studio-theme-space caption-btn" @click="fetchAiCaption(getPinterestSharingDetails.url, 'Pinterest')">Generate Caption</button>-->
      <!--          </div>-->
    </div>

    <options
      type="Pinterest"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'pinterest_tab' }"
    ></options>
    <!--<video-section :class="{hide : getSocialSharingSelection.activeTab !== 'pinterest_tab' }" v-if="(getPinterestSharingDetails.video && getPinterestSharingDetails.video.link) || getPublishLoaders.upload_pinterest_sharing_video" type="Pinterest" :details="getPinterestSharingDetails"></video-section>-->
    <image-section
      :class="{ hide: getSocialSharingSelection.activeTab !== 'pinterest_tab' }"
      v-if="
        getPublishLoaders.uploadPinterestSharingImage ||
        getPinterestSharingDetails.image
      "
      type="Pinterest"
      :details="getPinterestSharingDetails"
    ></image-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Options from '../options/Options'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'
import PinterestOptions from '@src/modules/publish/components/posting/social/PinterestOptions'

export default {
  components: {
    PinterestOptions,
    Options,
    ImageSection,
    VideoSection,
    HashtagSuggestions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {
    this.setPinterestboxRef(this.$refs.pinterest_sharing_message)
  },

  computed: {
    ...mapGetters([
      'getPublishLoaders',
      'getPinterestSharingDetails',
      'getSocialSharingSelection',
      'getPinterestOptions'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.pinterest &&
        this.getSocialSharingSelection.activeTab === 'pinterest_tab'
      )
    }
  },
  methods: {
    ...mapActions(['setPinterestboxRef']),
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      console.debug(
        'Method::handleRerender ~ getPinterestSharingDetails message ->',
        this.getPinterestSharingDetails.message
      )
      console.debug(
        'Method::handleRerender ~ getPinterestSharingDetails title ->',
        this.getPinterestSharingDetails.pinterest_title
      )
      const text = document.getElementById('pinterest_sharing_message').value
      if (!text) {
        const message = this.getPinterestSharingDetails.message
        console.log('Method::handleRerender ~ message -> ', message)
        document.getElementById('pinterest_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Pinterest'),
          'Pinterest'
        )
      }
    }
  },

  watch: {
    'getPinterestSharingDetails.message' (value) {
      this.setSharingBoxHtml(
        this.processSharingBoxHtml(value, 'Pinterest'),
        'Pinterest'
      )
    }
  }
}
</script>
