<template>
<!--  <div class="onboarding-welcome">-->
    <div class="onboarding-welcome__wrapper text-center mt-4">
      <h2>Welcome to ContentStudio</h2>
      <p
        >Watch this short video to see what ContentStudio can do for your
        business and how you can get started. We have created a step-by-step
        process to help you easily setup your first workspace.</p
      >
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/6mrp_SRynW0?showinfo=0&rel=0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <!--      <router-link :to="{name: 'onboardingWorkspace'}" data-cy ="start" ,class="btn btn-studio-theme-transparent btn-size-large">Let's Start</router-link>-->
      <button
        class="btn btn-studio-theme-transparent btn-size-large mr-3"
        data-cy="get-strated"
        @click.prevent="routeDiscovery"
        >I've watched the video, let's get started!</button
      >

      <button
          class="btn btn-studio-theme-transparent-grey btn-size-large"
          @click.prevent="bookADemo"
      >Book a demo</button>

    </div>
<!--  </div>-->
</template>

<script>
export default {
  components: {},

  data () {
    return {}
  },
  computed: {},
  created () {

  },
  mounted () {

  },
  methods: {
    // ...mapActions(['setOnboardingAccountsIntilize', 'setOnboardingTeamIntilize'])
    async routeDiscovery () {
      this.trackUserMaven('onboarding_get_started')

      if (!this.getActiveWorkspace.onboarding_steps.watch_video.status) {
        await this.onboardingStepsCompleted('watch_video')
        this.$bvModal.hide('get-started-modal')
      }

      // await this.fetchWorkspaces(true)
    },
    bookADemo() {
      this.trackUserMaven('onboarding_click_book_a_demo')
      window.open('https://contentstudio.io/book-a-demo', '_blank')
    }
  }
}
</script>
