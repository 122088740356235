<template>
  <div
    class="datepicker_style datepicker_facebook_filter"
    :class="{ disable_click: getTopicDisabled }"
  >
    <!--<i class="icon-Search"></i>-->
    <!--<input readonly v-model="getFacebookContentFilter.date.label" type="text" name="daterange" />-->
    <DateRangePicker
      ref="topicsdatepicker"
      class="datepicker_input mr-2"
      :opens="opens"
      :start-date="getDateRange.start"
      :end-date="getDateRange.end"
      :max-date="getMaxDate"
      :locale-data="locale"
      :single-date-picker="singleDatePicker"
      :time-picker="timePicker"
      :time-picker24hour="timePicker24Hour"
      :show-week-numbers="showWeekNumbers"
      :show-dropdowns="showDropdowns"
      @update="updateDateRange"
      :auto-apply="autoApply"
      :ranges="ranges"
    >
      <div
        slot="input"
        slot-scope="picker"
        class="datepicker_content discovery_white ml-2 mr-2"
      >
        <i class="icon-Calendar"></i>
        <span v-if="picker.label">{{ picker.label }}</span>
        <span v-else>Last 30 days</span>
        <!--<span v-else>{{picker.startDate | date}} - {{picker.endDate | date}}</span>-->
      </div>
    </DateRangePicker>
    <!--<i class="dropdown_arrow icon_last cs-angle-down  "></i>-->
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DateRangePicker
  },
  data () {
    return {
      locale: {
        format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
      },

      opens: 'left',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: this.getMaxDate,
      show_ranges: true,
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: true,
      showDropdowns: true,
      autoApply: true,
      showWeekNumbers: true,
      ranges: {
        'All Time': [moment.utc().subtract(5, 'years'), moment.utc()],
        '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
        '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
        'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
        'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
        'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
        'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
        'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
        'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
        'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
      }
    }
  },
  methods: {
    getDateRange () {
      const splitDates = this.getFacebookContentFilter.date.value.split(' - ')
      if (splitDates.length > 0) {
        return {
          start: moment(splitDates[0]).format('YYYY-MM-DD HH:mm'),
          end: moment(splitDates[1]).format('YYYY-MM-DD HH:mm')
        }
      } else {
        return {}
      }
    },

    updateDateRange (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)

      let range = null

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)

        range = {
          label: '',
          value:
            startDate.format('YYYY-MM-DDTHH:mm:ss') +
            ' - ' +
            endDate.format('YYYY-MM-DDTHH:mm:ss')
        }
      }
      this.changeFacebookDate(range)
    }
  },
  computed: {
    ...mapGetters(['getTopicDisabled', 'getFacebookContentFilter']),
    getMaxDate () {
      return moment.utc().format('YYYY-MM-DD')
    }
  },
  mounted () {
    // let self = this
    // $('.datepicker_facebook_filter input[name="daterange"]').daterangepicker({
    //   autoUpdateInput: false,
    //   opens: 'left',
    //   maxDate: new Date(),
    //   ranges: {
    //     'All Time': '',
    //     '12 hours': [moment.utc().subtract(12, 'hours'), moment.utc()],
    //     '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
    //     '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
    //     'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
    //     'Last 7 days': [moment().utc().subtract(7, 'days'), moment().utc()],
    //     'Last 30 days': [moment().utc().subtract(30, 'days'), moment().utc()],
    //     'This Month': [moment.utc().startOf('month'), moment.utc().endOf('month')],
    //     'Last Month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month')]
    //   },
    //   locale: {
    //     format: 'YYYY-MM-DD HH:mm:ss'
    //   },
    //   alwaysShowCalendars: true
    // }, function (start, end, label) {
    //   console.log('A new date selection was made: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'))
    // })
    // $('.datepicker_facebook_filter input[name="daterange"]').on('show.daterangepicker', function (ev, picker) {
    //   $('.datepicker_facebook_filter').addClass('open')
    //   console.debug('open')
    // })
    // $('.datepicker_facebook_filter input[name="daterange"]').on('hide.daterangepicker', function (ev, picker) {
    //   $('.datepicker_facebook_filter').removeClass('open')
    //   console.debug('close')
    // })
    // setTimeout(function () {
    //   $('.datepicker_facebook_filter').on('apply.daterangepicker', function (ev, picker) {
    //     let range = null
    //     if (picker.chosenLabel != 'All Time') {
    //       range = {
    //         label: ' ' + picker.chosenLabel,
    //         value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' - ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
    //       }
    //     }
    //
    //     // calling the content mixing method to reflect the range filter.
    //
    //     self.changeFacebookDate(range)
    //   })
    // }, 1000)
  }
}
</script>
