import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getTwitterContentURL } from '../../config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'

const twitterContentMixin = {
  methods: {
    ...mapActions([
      'setTwitterPage',
      'setTweetsContent',
      'setTwitterContentPage',
      'setTwitterPageToken',
      'setTwitterScroll',
      'setTwitterPaginationLoader',
      'setDiscoveryTwitterLoader',
      'setVideoPageToken',
      'setTwitterContentLanguage'
    ]),
    getTwitterContent (language = null) {
      console.debug('Method:getTwitterContent')
      this.setTwitterContentLanguage(language)
      this.setTwitterContentPage(0)
      this.setTwitterPageToken(null)
      this.setTwitterScroll(true)
      this.setTweetsContent([])
      this.setTwitterPaginationLoader(false)
      this.setDiscoveryTwitterLoader(true)
      this.getTwitterData()
    },
    changeTwitterLanguage (language) {
      this.getTwitterContent(language)
    },
    getTwitterData ($state = null) {
      console.debug('Method:getTwitterData')

      if (this.getTwitterAccounts.items.length > 0) {
        this.SET_TOPIC_DISABLED(true)
        let payload = null
        if (this.getTwitterContentType == 'search') {
          payload = {
            filters: this.getTwitterContentFilter,
            type: 'search',
            search: this.getFilters.search,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
            token: this.getTwitterAccounts.items[0].oauth_token,
            secret: this.getTwitterAccounts.items[0].oauth_token_secret
          }
        } else {
          payload = {
            selection: this.getTopicSelection,
            filters: this.getTwitterContentFilter,
            type: this.getTopicType,
            topic: this.getTopic,
            curated: this.getCuratedTopic,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
            token: this.getTwitterAccounts.items[0].oauth_token,
            secret: this.getTwitterAccounts.items[0].oauth_token_secret
          }
        }
        if (payload) {
          this.postRequest(
            getTwitterContentURL,
            payload,
            (response) => {
              if (response.data.status) {
                this.SET_TOPIC_DISABLED(false)

                if (this.getTwitterContentPage == 0) {
                  this.setTweetsContent([])
                }

                if (!response.data.tweets || response.data.tweets.length == 0) {
                  response.data.tweets = []
                }

                if (this.getTwitterContentPage >= 1) {
                  this.setTweetsContent(
                    this.getTweetsContent.concat(response.data.tweets)
                  )
                } else {
                  this.setTweetsContent(response.data.tweets)
                }
                this.setTwitterPaginationLoader(true)

                if (
                  response.data.tweets.length == 0 ||
                  response.data.tweets.length == 1
                ) {
                  this.setTwitterScroll(false)
                  try {
                    if ($state) {
                      $state.complete()
                    }
                  } catch (ex) {
                    console.log(ex)
                  }
                } else {
                  this.setTwitterPageToken(
                    this.decStrNum(response.data.lowest_id)
                  )
                  this.setTwitterScroll(true)
                  if ($state) {
                    $state.loaded()
                  }
                }
                this.setDiscoveryTwitterLoader(false)
                this.checkComposerDragDrop()
              } else {
                this.SET_TOPIC_DISABLED(false)
                this.setTwitterPaginationLoader(true)
                this.setDiscoveryTwitterLoader(false)
                this.alertMessage(response.data.error, 'error')
              }
            },
            (response) => {
              this.alertMessage(UNKNOWN_ERROR, 'error')
            }
          )
        }
      } else {
        this.alertMessage(
          'No Twitter accounts connected.To get content from Twitter, you need to connect your Twitter account first.',
          'error'
        )
        this.SET_TOPIC_DISABLED(false)
        this.setTwitterPaginationLoader(true)
        this.setDiscoveryTwitterLoader(false)
      }
    },
    twitterText (text, hashtags, urls, user_mentions) {
      if (
        hashtags.length == 0 &&
        urls.length == 0 &&
        user_mentions.length == 0
      ) {
        return text
      }

      if (hashtags.length >= 1) {
        // step 1 save all hashes in array

        const hashArray = []

        hashtags.forEach(function (element) {
          hashArray.push(element.text)
        }, this)

        // step 2 sort all hashes with resprect to length

        hashArray.sort(function (a, b) {
          return b.length - a.length
        })

        const hasingRandomArray = []

        // step 3 replace hases with random string to overcome the duplicate problem

        hashArray.forEach(function (element) {
          const randomId = this.generateRandomId()
          text = text.replace('#' + element, randomId)
          hasingRandomArray.push({
            text: element,
            id: randomId
          })
        }, this)

        // step 4 replace random number with clickable href

        hasingRandomArray.forEach(function (element) {
          text = text.replace(
            element.id,
            '<a  data-text="' +
              '#' +
              element.text +
              '" class="hash_tags searchHash">' +
              '#' +
              element.text +
              '</a>'
          )
        }, this)
      }

      if (urls.length >= 1) {
        urls.forEach(function (element) {
          text = text.replace(
            element.url,
            '<a class="hash_tags" target="_blank" href="' +
              element.url +
              '">' +
              element.url +
              '</a>'
          )
        }, this)
      }

      if (user_mentions.length >= 1) {
        // step 1 save all mentions in array

        const mentionsArray = []

        user_mentions.forEach(function (element) {
          mentionsArray.push(element.screen_name)
        }, this)

        // step 2 sort all mentions with resprect to length

        mentionsArray.sort(function (a, b) {
          return b.length - a.length
        })

        const mentionsRandomArray = []

        // step 3 replace mentions with random string to overcome the duplicate problem

        mentionsArray.forEach(function (element) {
          const randomId = this.generateRandomId()
          text = text.replace('@' + element, randomId)
          mentionsRandomArray.push({
            text: element,
            id: randomId
          })
        }, this)

        // step 4 replace random number with clickable href

        mentionsRandomArray.forEach(function (element) {
          text = text.replace(
            element.id,
            '<a class="hash_tags" target="_blank" href="https://twitter.com/' +
              element.text +
              '" >' +
              '@' +
              element.text +
              '</a>'
          )
        }, this)
      }

      return text
    },

    getTweetUserImage (user) {
      if (user.profile_image_url) {
        return "background: url('" + user.profile_image_url + "');"
      }
      return "background: url('http://image.prntscr.com/image/947dd2c08ba744cc92793266d5cf6655.png');"
    },
    getTweetRedirectLink (tweet) {
      return tweet.user
        ? `https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`
        : ''
    }
  },
  computed: {
    ...mapGetters([
      'getTwitterContentType',
      'getTwitterContentFilter',
      'getTweetsContent',
      'getTwitterContentPage',
      'getTwitterAccounts'
    ])
  }
}
export { twitterContentMixin }
