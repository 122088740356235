<template>
  <div class="net_follower_box">
    <div class="box_inner d-flex align-items-center">
      <div class="left col-8">
        <div class="text">
          <h4 class="top_text">{{ title }} </h4>
          <h2>
            {{ newValue != null ? parseInt(newValue) : 0 }}
          </h2>
        </div>
      </div>
      <div class="right ml-auto" v-if="oldValue">
        <div>
          <h2
            ><span :class="{ 'brown-text': getGrowth() < 0 }"
              >{{ Math.abs(getGrowth()) }}%</span
            >
            <img
              v-if="getGrowth() >= 0"
              src="../../../../../assets/img/analytics/up_arrow.svg"
            />
            <img v-else src="../../../../../assets/img/analytics/up_down.svg" />
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: '',
    newValue: '',
    oldValue: ''
  },
  data: function () {
    return {
      growth: 0
    }
  },
  methods: {
    getGrowth () {
      const previous =
        this.oldValue === 0 || !this.oldValue ? this.newValue : this.oldValue
      return (((this.newValue - previous) / previous) * 100).toFixed(2)
    },
    isGrowthPostivegrowth () {
      return '../../../../../app/src/assets/img/analytics/up_down.svg'
    }
  }
}
</script>

<style scoped></style>
