<template>
  <div>
    <div
      class="topic_content_box _article"
      v-for="(post, index) in getPosts"
      :key="index"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
    >
      <div
        class="box_inner"
        v-if="post._source"
        @dragstart="dragstartHandler($event, post)"
        :draggable="$route.name === 'composerBlog'"
      >
        <div class="d-flex">
          <div class="image_block">
            <div
              class="img"
              v-lazy:background-image="backgroundImageURL(post._source)"
            ></div>
            <!--<div class="img" style="background:url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');"></div>-->
          </div>
          <div class="content_block">
            <div class="content_block_inner">
              <h3
                :title="post._source.title"
                @click.prevent="
                  articlePreview({ id: post._source.id, index: index })
                "
                >{{ limitTextLength(post._source.title, 65) }}</h3
              >
              <div class="detail_box d-flex align-items-center">
                <a class="domain_name" href="javascript:;">{{
                  trimURL(post._source.domain_url)
                }}</a>
                <!--<p class="more">More from this domain</p>-->
              </div>
              <div class="detail_box d-flex align-items-center">
                <p class="date">{{ $filters.relative(post._source.post_date) }}</p>
                <!--<p class="author">By <span>Franz</span></p>-->
                <p class="author_name">
                  <template v-if="post._source.author">
                    By <span v-html="post._source.author"></span>
                    <a
                      v-if="post._source.twitter_accounts && index < 1"
                      v-for="(account, index) in post._source.twitter_accounts"
                      target="_blank"
                      :href="getTwitterLink(account)"
                      >{{ account }}</a
                    >
                  </template>
                  <template
                    v-else-if="
                      post._source.twitter_accounts &&
                      post._source.twitter_accounts.length > 0
                    "
                  >
                    By
                    <a
                      v-if="post._source.twitter_accounts && index < 1"
                      v-for="(account, index) in post._source.twitter_accounts"
                      target="_blank"
                      :href="getTwitterLink(account)"
                      >{{ account }}</a
                    >
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer clear">
          <div class="social_detail" v-tooltip.top-center="'Engagement'">
            <i class="icon-engagement-ico"></i>
            <span class="state_txt" v-if="post._source">{{
              intToString(calculateEngagements(post._source))
            }}</span>
            <span class="state_txt" v-else>0</span>
          </div>
          <div class="social_detail" v-tooltip.top-center="'Sentiment'">
            <template
              v-if="post._source.sentiment && post._source.sentiment.length > 0"
            >
              <i
                class="cs-sad-face sad"
                v-if="post._source.sentiment[0].label == 'negative'"
              ></i>
              <i
                class="cs-happy-face smile"
                v-if="
                  post._source.sentiment[0].label == 'positive' &&
                  post._source.sentiment[0].probability >= 0.65
                "
              ></i>
              <i
                class="cs-normal-face normal"
                v-else-if="
                  post._source.sentiment[0].label == 'positive' &&
                  post._source.sentiment[0].probability < 0.65
                "
              ></i>
              <i
                class="cs-normal-face normal"
                v-if="post._source.sentiment[0].label == 'neutral'"
              ></i>
            </template>
          </div>

          <!---->
          <!--<div class="social_detail">-->
          <!--<i class="fb cs-facebook" v-tooltip.top-center="'Facebook share'"></i>-->
          <!--<span class="state_txt" v-if="post._source.facebook_shares">{{intToString(post._source.facebook_shares)}}</span>-->
          <!--<span class="state_txt" v-else>0</span>-->
          <!--</div>-->
          <!--<div class="social_detail">-->
          <!--<i class="pin  cs-pinterest" v-tooltip.top-center="'Pinterest Share'"></i>-->
          <!--<span class="state_txt" v-if="post._source.pins">{{intToString(post._source.pins)}}</span>-->
          <!--<span class="state_txt" v-else>0</span>-->
          <!--</div>-->
          <!--<div class="social_detail">-->
          <!--<i class="reddit  cs-reddit" v-tooltip.top-center="'Reddit Share'"></i>-->
          <!--<span class="state_txt" v-if="post._source.reddit">{intToString(post._source.reddit)}}</span>-->
          <!--<span class="state_txt" v-else>0</span>-->
          <!--</div>-->
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_article_block"
          contenteditable="false"
        >
          <div class="top_option no_flickr">
            <div class="right_block">
              <i
                class="handler fa fa-hand-paper-o editor_tooltip"
                title="Move"
              ></i>
              <i
                class="edit_box fa fa-check-circle editor_tooltip green"
                title="Merge"
              ></i>
              <i
                class="remove_box fa fa-times-circle editor_tooltip red"
                title="Remove"
              ></i>
            </div>
          </div>
          <div class="editor_dragable_box_inner clear">
            <!--<br>-->
            <h2>{{ limitTextLength(post._source.title, 0) }}</h2>
            <p
              ><img
                style="
                  width: 225px;
                  float: left;
                  margin-left: 0.625rem;
                  margin-right: 0.625rem;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                :src="backgroundImageURL(post._source)"
                alt=""
            /></p>
            <p v-if="post._source.description">{{
              limitTextLength(removeHtmlTags(post._source.description), 200)
            }}</p>
            <a
              style="text-decoration: underline"
              target="_blank"
              :href="post._source.url"
              >Read more</a
            >
            <br />
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { removeHtmlTags } from '@common/lib/helper'

export default {
  computed: {
    ...mapGetters(['getPosts'])
  },
  created () {},
  methods: {
    ...mapActions(['articlePreview']),
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      console.log('dragstartHandler', post)
      if (post && post._source.title) {
        ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'article',
            heading: post._source.title,
            media: this.backgroundImageURL(post._source),
            p: this.truncateString(
              removeHtmlTags(post._source.description),
              400
            ),
            link: post._source.url
          })
        )
      }
    }
  }
}
</script>
