<template>
  <div class="discovery_analytic_component">
    <!--.....unlock insight feature.......-->
    <div class="component_inner">
      <div v-if="!isInsightsAllowed()" class="analytic_verification">
        <!--show if review submitted-->
        <div
          v-if="review_submitted"
          class="analytic_verification_inner unlock_block"
        >
          <img
            style="width: 200px"
            src="../../../../assets/img/common/unblock_post.svg"
          />
          <h2>Thank you for submitting the review</h2>
          <p
            >After we verify your review, this page will be automatically
            unlocked within the next 24-48 hours.</p
          >
        </div>

        <!--if no review added yet -->
        <div v-if="!review_submitted" class="analytic_verification_inner">
          <div class="head_text">
            <h2
              >Insights is a new tool that we have added recently and we are
              giving it away for FREE to our lifetime users.</h2
            >
            <p
              >But we need a small favor from you to UNLOCK this tool for
              you.</p
            >
          </div>
          <div class="step_box">
            <h2>Step 1</h2>
            <p
              >Please write a few words about ContentStudio on any of the below
              mentioned website.</p
            >
            <div class="web_list">
              <li :class="{ completed: review.service == 'capterra' }">
                <div
                  class="img"
                  @click.prevent="selectReviewWebsite('capterra')"
                >
                  <img
                    style="width: 135px"
                    src="../../../../assets/img/common/capterra_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://rplg.co/cs-app-review-capterra"
                  >Write on Capterra</a
                >
              </li>
              <li :class="{ completed: review.service == 'g2crowd' }">
                <div
                  class="img"
                  @click.prevent="selectReviewWebsite('g2crowd')"
                >
                  <img
                    style="width: 10.625rem"
                    src="../../../../assets/img/common/crowd_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://rplg.co/cs-app-g2crowd-review"
                  >Write on G2Crowd</a
                >
              </li>
              <!-- <li :class="{'completed': review.service=='getapp'}">
                                <div class="img" @click.prevent="selectReviewWebsite('getapp')">
                                    <img style="width: 100px;"
                                         src="../../../../app/src/assets/img/common/getapp_logo.png"/>
                                </div>
                            </li>-->
            </div>
          </div>
          <div class="step_box basic_form">
            <h2>Step 2</h2>
            <p
              >Once you have submitted your review, please send us a message
              below with a proof to verify that you’ve actually submitted the
              review.</p
            >
            <p class="sub_text"
              >Add a link to the review post that you’ve submitted and/or send
              us a screenshot or a video proof using useloom.com/screencast
              etc.</p
            >

            <div class="text_block input_field">
              <textarea
                id=""
                v-model="review.message"
                name=""
                cols="30"
                rows="5"
                placeholder="Type here.."
              ></textarea>
              <form method="post" enctype="multipart/form-data" class="d-none">
                <div class="upload_file_block">
                  <input
                    id="prof_ss"
                    type="file"
                    name="reviewFiles"
                    accept="image/*,video/*"
                    @change.prevent="uploadReviewFiles($event)"
                  />
                  <label for="prof_ss">
                    <span class="btn gradient_btn">
                      <span>Upload a file</span>
                    </span>
                    <!--<span class="text">No file selected.</span>-->
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div class="btn_block text-center">
            <button
              class="btn btn_round large_btn"
              @click.prevent="submitReview"
              >Submit</button
            >
          </div>
        </div>
      </div>
    </div>

    <!--..... insight feature charts.......-->
    <div v-if="isInsightsAllowed()" class="component_inner">
      <div class="inner_component_filter">
        <FilterDate
          v-if="!getTopicCreationModal"
          :is-topic="isTopicView"
        ></FilterDate>
      </div>
      <div class="d-flex flex-row justify-content-between insight_boxes">
        <div class="ds_box_no_icon_left">
          <div class="box_inner">
            <p class="top_text">Articles Analyzed</p>
            <h2>{{ intToString(getInsightsCount.articlesAnalyzed) }}</h2>
          </div>
        </div>
        <div class="ds_box_no_icon_left">
          <div class="box_inner">
            <p class="top_text">Total Engagements</p>
            <h2>{{ intToString(getInsightsCount.totalEngagements) }}</h2>
          </div>
        </div>
        <div class="ds_box_no_icon_left">
          <div class="box_inner">
            <p class="top_text">Avg. Engagements</p>
            <h2>{{
              $filters.floatToInt(getInsightsCount.averageEngagements)
            }}</h2>
          </div>
        </div>
        <div class="ds_box_no_icon_left">
          <div class="box_inner">
            <p class="top_text">Avg. Engagement By Channel</p>
            <div class="d-flex align-items-center justify-content-between">
              <div
                v-tooltip.top-center="'Facebook Share'"
                class="engagement_stat two_box_social"
              >
                <span class="icon_item">
                  <i class="fb cs-facebook"></i>
                </span>
                <span class="text">{{
                  $filters.floatToInt(getInsightsCount.averageFacebookEngagement)
                }}</span>
              </div>
              <div
                v-tooltip.top-center="'Pinterest Share'"
                class="engagement_stat two_box_social"
              >
                <span class="icon_item">
                  <i class="pin cs-pinterest"></i>
                </span>
                <span class="text">{{
                    $filters.floatToInt(getInsightsCount.averagePinterestEngagement)
                }}</span>
              </div>
              <div
                v-tooltip.top-center="'Reddit Share'"
                class="engagement_stat two_box_social"
              >
                <span class="icon_item">
                  <i class="reddit cs-reddit"></i>
                </span>
                <span class="text">{{
                  $filters.floatToInt(getInsightsCount.averageRedditEngagement)
                }}</span>
              </div>

              <!--<div class="engagement_stat ml-auto two_box_social">
                                 <span class="icon_item pinterest_white">
                                 <span class="icon"></span>
                             </span>
                                <span class="text">{{getInsightsCount.averagePinterestEngagement | float-to-int}}</span>
                            </div>-->
            </div>
          </div>
        </div>
      </div>

      <template v-if="getShowChartsLoader">
        <ArticlesPublishedOverTime
          ref="parent_articles_published"
        ></ArticlesPublishedOverTime>
      </template>

      <template v-if="getShowChartsLoader">
        <div
          class="ds_card_container engagement_rate_block engagement_rate_block_3_1"
        >
          <div class="d-flex flex-row">
            <ArticlesPublishedAndSentiment></ArticlesPublishedAndSentiment>
            <ShareOfSentiment></ShareOfSentiment>
          </div>
        </div>
      </template>

      <template v-if="getShowChartsLoader">
        <div
          class="ds_card_container engagement_rate_block engagement_rate_block_2_2"
        >
          <div class="d-flex flex-row">
            <AverageEngagementByNetwork></AverageEngagementByNetwork>
            <TotalEngagementByNetwork></TotalEngagementByNetwork>
          </div>
        </div>
      </template>

      <template v-if="getShowChartsLoader">
        <PopularContentTypes></PopularContentTypes>
      </template>
      <div
        class="ds_card_container engagement_rate_block engagement_rate_block_2_2"
      >
        <div class="d-flex flex-row">
          <WordCloud></WordCloud>
          <PopularWordCount></PopularWordCount>
        </div>
      </div>
      <template v-if="getShowChartsLoader">
        <div
          class="ds_card_container engagement_rate_block engagement_rate_block_2_2"
        >
          <div class="d-flex flex-row">
            <PopularSentiments></PopularSentiments>
            <PopularReadingLevels></PopularReadingLevels>
          </div>
        </div>
      </template>
      <template v-if="getShowChartsLoader">
        <PopularDays></PopularDays>
      </template>
      <template v-if="getShowChartsLoader">
        <TopDomainsOnNetworks></TopDomainsOnNetworks>
      </template>
      <WebsitesTable></WebsitesTable>
      <AuthorsTable></AuthorsTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilterDate from '../filters/FilterDate'
import { unlockInsightsURL } from '../../config/api-utils'
import WebsitesTable from './charts/WebsitesTable'
import AuthorsTable from './charts/AuthorsTable'
import TopDomainsOnNetworks from './charts/TopDomainsOnNetworks'
import PopularDays from './charts/PopularDays'
import PopularReadingLevels from './charts/PopularReadingLevels'
import PopularSentiments from './charts/PopularSentiments'
import PopularWordCount from './charts/PopularWordCount'
import PopularFormats from './charts/PopularFormats'
import WordCloud from './charts/WordCloud'
import PopularContentTypes from './charts/PopularContentTypes'
import AverageEngagementByNetwork from './charts/AverageEngagementByNetwork'
import TotalEngagementByNetwork from './charts/TotalEngagementByNetwork'
import ShareOfSentiment from './charts/ShareOfSentiment'
import ArticlesPublishedAndSentiment from './charts/ArticlesPublishedAndSentiment'
import ArticlesPublishedOverTime from './charts/ArticlesPublishedOverTime'

export default {
  components: {
    WebsitesTable,
    AuthorsTable,
    TopDomainsOnNetworks,
    PopularDays,
    PopularReadingLevels,
    PopularSentiments,
    PopularWordCount,
    PopularFormats,
    WordCloud,
    PopularContentTypes,
    TotalEngagementByNetwork,
    AverageEngagementByNetwork,
    ShareOfSentiment,
    ArticlesPublishedAndSentiment,
    ArticlesPublishedOverTime,
    FilterDate
  },
  props: ['module'],
  data () {
    return {
      review_submitted: false,
      review: {
        service: '',
        message: ''
      }
    }
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters([
      'getTopicCreationModal',
      'getInsightsCount',
      'getInsightsLoader',
      'getShowChartsLoader',
      'getProfile',
      'getTopicsFilters'
    ]),
    isTopicView () {
      return this.module === 'topics'
    }
  },
  methods: {
    ...mapActions(['setInsightsLoader']),
    selectReviewWebsite (service) {
      if (service === 'g2crowd') {
        window.open('https://rplg.co/cs-app-g2crowd-review', '_blank')
      } else if (service === 'capterra') {
        window.open('https://rplg.co/cs-app-review-capterra', '_blank')
      }
      this.review.service = service
    },
    submitReview () {
      this.postRequest(
        unlockInsightsURL,
        { review: this.review },
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message)
            this.review_submitted = true
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (response) => {
          this.alertMessage(response.data.message, 'error')
        }
      )
    },
    uploadReviewFiles (event) {
      // NOTE: currently, we are asking user to create the video using useloom or screencast.
      console.debug('Method:uploadReviewFiles')

      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()

      const fileType = []
      for (let i = 0; i < files.length; i++) {
        if (fileType.includes(files[i].type) === false) {
          fileType.push(files[i].type)
        }

        formData.append('files[]', files[i])
      }
    }
  },

  watch: {}
}
</script>
