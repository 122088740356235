<template>
  <div class="feeds-manage-sources">
    <div class="feeds-manage-sources__left_section">
      <h3 class="feeds-manage-sources__left_section__heading"
        >Manage Sources</h3
      >
      <p
        class="feeds-manage-sources__left_section__heading_text"
        v-if="getSourcesListArray.length"
      >
        Following {{ getSourcesListArray.length }} sources
        <!--Following 55 sources with 12 inactive and 15 unreachable.-->
      </p>
    </div>
    <div
      class="feeds-manage-sources__right_section"
      v-if="getSourcesListArray.length > 0"
    >
      <div class="float-right">
        <button
          @click="$router.push({ name: 'feeder_opml_import' })"
          class="btn btn-studio-theme-grey-space ml-2"
        >
          <span>OPML Import</span>
        </button>

        <button
          @click="$router.push({ name: 'feeder_follow_categories' })"
          class="btn btn-studio-theme-space"
        >
          <span>Add Sources</span>
        </button>
      </div>
    </div>

    <div class="table_filter mt-3 mb-3 d-flex align-items-center">
      <!--<div class="f_left">
                <div class=" dropdown option_dropdown default_style_dropdown">

                    <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                        <span class="align-middle text">All your feeds</span>
                        <i class="align-middle cs-angle-down icon_last"></i>
                    </div>

                    <div class="dropdown-menu dropdown-menu-left">
                        <ul class="inner">

                            <li class="list_item">
                                <a class="dropdown-item">Delete</a>
                            </li>
                            <li class="list_item">
                                <a class="dropdown-item">Delete</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>-->
      <div class="f_right ml-auto">
        <div
          class="search-bar-input-discovery search-bar-input with-icon-right d-flex align-items-center"
        >
          <div class="search-bar-inner">
            <input
              type="text"
              v-model="search_query"
              placeholder="Search for your feed"
            />
            <button class="search_btn input_icon search_icon gradient_color">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="table_box feeds-manage-sources-table">
      <table class="">
        <thead>
          <tr>
            <th style="width: 30px">
              <div class="field_group" v-if="getSourcesListArray.length > 0">
                <div class="checkbox_container">
                  <label for="checkAllSources">
                    <input
                      v-model="checkAllSources"
                      type="checkbox"
                      id="checkAllSources"
                      @change="changeCheckAllSources()"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>

              <!--<div class="checkbox_input_image"-->
              <!--v-if="getSourcesListArray.length > 0">-->

              <!--<input v-model="checkAllSources" type="checkbox" id="checkAllSources"-->
              <!--@change="changeCheckAllSources()">-->
              <!--<label for="checkAllSources" class="no_text"></label>-->

              <!--</div>-->
            </th>

            <template
              v-if="checkAllSources || selected_sources_list.length > 0"
            >
              <th style="width: 400px"
                >Selected {{ selected_sources_list.length }} Of
                {{ getSourcesListArray.length }}
              </th>
              <th style="width: 160px" class="">
                <!--<button class="btn btn_head light_gray">
                                <i class="far fa-recycle mr-1"></i>
                                <span>Reorganize</span>
                            </button>-->
              </th>

              <th style="width: 160px" class="">
                <button
                  class="btn btn-studio-theme-space btn-size-small float-right"
                  @click="unfollowBatchItemsAction()"
                >
                  <i class="icon-delete-cs mr-1"></i>
                  <span>Unfollow Selected</span>
                  <clip-loader
                    :color="'#9cbbff'"
                    :size="'12px'"
                    v-if="loaders.batch_unfollow"
                  ></clip-loader>
                </button>
              </th>
            </template>

            <template v-else>
              <th style="width: 400px">Source Name</th>
              <th style="width: 160px" class="text-center">Topics</th>
              <th style="width: 160px" class="text-right">Actions</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="loaders.retrieve">
            <tr>
              <td class="pt-3 pb-3" colspan="4">
                <clip-loader :color="'#436aff'" :size="'14px'"></clip-loader>
              </td>
            </tr>
          </template>
          <template v-else>
            <template
              v-if="getSourcesListArray.length > 0"
              v-for="(source, index) in getSourcesListArray"
            >
              <tr
                :class="{
                  row_bg:
                    checkAllSources ||
                    selected_sources_list.indexOf(source[0]) >= 0,
                }"
                :key="`${source[0]}_${index}`"
              >
                <td>
                  <div class="field_group">
                    <div class="checkbox_container">
                      <label :for="`input_checkbox_${source[0]}`">
                        <input
                          type="checkbox"
                          :id="`input_checkbox_${source[0]}`"
                          v-model="selected_sources_list"
                          :value="source[0]"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="profile_picture">
                    <div class="block_of_picture">
                      <div class="picture_block picture_block_background">
                        <div
                          class="img"
                          v-if="source[1].logo"
                          :style="`background:url('${source[1].logo}')`"
                        ></div>
                        <div class="img" v-else>
                          <i class="far fa-rss no_rss_icon"></i>
                        </div>
                      </div>
                    </div>
                    <div class="block_of_text">
                      <div class="text_block">
                        <p class="text">{{
                          limitTextLength(source[1].title, 35)
                        }}</p>
                        <div>
                          <template
                            v-for="source_group in source[1].group_details"
                          >
                            <span class="sub_text"
                              ><i class="far fa-rss mr-2"></i
                              >{{ source_group.name }}</span
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    class="source_category_item"
                    v-for="category in source[1].categories"
                  >
                    {{ category.interest }}
                  </span>
                </td>
                <td>
                  <button
                    class="btn btn-studio-theme-space btn-size-small float-right"
                    :disabled="source[1].hasOwnProperty('loader') && source[1]"
                    @click="unfollowIndividualItem(source, index)"
                  >
                    <i class="icon-delete-cs mr-1"></i>
                    <span>Unfollow</span>
                    <clip-loader
                      :color="'#9cbbff'"
                      :size="'12px'"
                      v-if="source[1].hasOwnProperty('loader') && source[1]"
                    ></clip-loader>
                  </button>
                </td>
              </tr>
            </template>
            <template v-if="getSourcesListArray.length === 0">
              <tr>
                <td colspan="4" class="text-center pt-3 pb-3">
                  <img
                    src="../../../../../assets/img/states/follow-sources-icon-style-2.svg"
                    height="120px"
                    alt=""
                    width="200px"
                    style="margin: 0 auto; display: block"
                  />
                  <span class="mt-3 mb-3">
                    You have not added any sources yet.
                  </span>

                  <p>&nbsp;</p>

                  <button
                    @click="$router.push({ name: 'feeder_follow_categories' })"
                    class="btn btn-studio-theme-space display-block m-auto"
                  >
                    <span>Add Sources</span>
                  </button>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <!--<div class="social_table feeds-manage-sources-table">-->

    <!--</div>-->
  </div>
</template>
<script>
import sortBy from 'lodash.sortby'

export default {
  data () {
    return {
      checkAllSources: false,
      sources_list: {},
      selected_sources_list: [],
      search_query: '',
      loaders: {
        retrieve: false,
        batch_unfollow: false
      }
    }
  },
  created () {
    this.allSourcesList()
  },
  computed: {
    getSourcesListArray () {
      const sourcesList = Object.entries(this.sources_list)
      if (this.search_query.trim().length > 0) {
        console.log(
          'search query for the sources',
          this.search_query.toLowerCase()
        )
        return sourcesList.filter((item) =>
          item[1].title.toLowerCase().includes(this.search_query.toLowerCase())
        )
      }
      return sortBy(sourcesList, function (item) {
        console.log(item)
        return [item[1].title.toLowerCase()]
      })
    }
  },
  methods: {
    async allSourcesList () {
      this.loaders.retrieve = true
      this.sources_list = {}
      const res = await this.$store.dispatch('feederManageSources')
      if (res.data.status && res.data.data) {
        this.sources_list = res.data.data
      }
      this.loaders.retrieve = false
    },
    async unfollowIndividualItem (source, index) {
      console.log(source)
      this.$set(source[1], 'loader', true)
      const res = await this.$store.dispatch('feederUnfollowIndividualFeed', {
        group_ids: source[1].group_ids,
        feed_id: source[0]
      })
      this.$set(source[1], 'loader', false)
      if (res.data.status) {
        this.$delete(this.sources_list, source[0])
        console.log(this.sources_list)
        this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
      }
    },
    async unfollowBatchItemsAction () {
      const feedsList = []
      this.loaders.batch_unfollow = true
      Object.entries(this.sources_list).forEach((item) => {
        if (this.selected_sources_list.indexOf(item[0]) >= 0) {
          feedsList.push(item[1])
        }
      })
      const res = await this.$store.dispatch('feederUnfollowBatchFeeds', {
        feeds: feedsList
      })
      this.loaders.batch_unfollow = false
      if (res.data && res.data.status) {
        this.selected_sources_list.forEach((sourceSelected) => {
          this.$delete(this.sources_list, sourceSelected)
        })
        this.selected_sources_list = []
        this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
      }
    },
    changeCheckAllSources () {
      if (this.checkAllSources) {
        this.selected_sources_list = this.getSourcesListArray.map(
          (item) => item[0]
        )
      } else {
        this.selected_sources_list = []
      }
    }
  },
  watch: {
    'getActiveWorkspace._id' () {
      this.allSourcesList()
    },
    'selected_sources_list.length' (value) {
      this.checkAllSources = value === this.getSourcesListArray.length
    }
  }
}
</script>
