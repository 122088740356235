<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {},
  data () {
    return {}
  },
  methods: {
    ...mapActions([
      'fetchScheduleReportsService',
      'removeScheduledReportService'
    ]),
    ...mapMutations([
      'SET_SCHEDULED_REPORT_ITEM',
      'SET_SCHEDULED_REPORT_ITEM_ACCOUNTS'
    ]),
    editScheduledReport (item) {
      console.log(this.getAccounts(item))
      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(this.getAccounts(item))
      this.SET_SCHEDULED_REPORT_ITEM(JSON.parse(JSON.stringify(item)))
      $('#scheduleReport').modal('show')
    },
    getIconClass (type) {
      switch (type) {
        case 'group':
          return 'fa-pie-chart'
        default:
          return `fa-${type}`
      }
    },
    getIconBg (type) {
      switch (type) {
        case 'instagram':
          return 'ins_bg'
        case 'facebook':
          return 'fb_bg'
        case 'twitter':
          return 'tw_bg'
        case 'linkedin':
          return 'lin_bg'
      }
    },
    getPlatform (type) {
      return 'cs-' + type + ' ' + this.getIconBg(type)
    },
    getAccounts (item) {
      switch (item.type) {
        case 'group':
          return item.accounts
        case 'facebook':
          return this.getFacebookPagesList.filter(
            (i) => i.facebook_id === item.accounts
          )
        case 'instagram':
          return this.getInstagramAccounts.items.filter(
            (i) => i.instagram_id === item.accounts
          )
        case 'twitter':
          return this.getTwitterAccounts.items.filter(
            (i) => i.twitter_id === item.accounts
          )
        case 'linkedin':
          return this.getLinkedinCompanyPages.filter(
            (i) => i.linkedin_id === item.accounts
          )
      }
    }
  },
  async mounted () {
    const res = await this.fetchScheduleReportsService()
  },
  computed: {
    ...mapGetters([
      'getScheduledReports',
      'getScheduledReportItem',
      'getFacebookPagesList',
      'getFacebookAccounts',
      'getInstagramAccounts',
      'getTwitterAccounts',
      'getLinkedinCompanyPages'
    ]),
    reportsList () {
      if (
        this.getScheduledReports.query &&
        this.getScheduledReports.query.length > 0
      ) {
        return this.getScheduledReports.list.filter((item) => {
          if (
            item.name.toLowerCase().includes(this.getScheduledReports.query)
          ) {
            return item
          }
        })
      } else {
        return this.getScheduledReports.list
      }
    }
  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const res = await this.fetchScheduleReportsService()
      }
    }
  }
}
</script>

<style lang="less">
.report-created-at {
  padding-bottom: 10px;
}

.report-interval p {
  margin-top: 30px !important;
}

.report-interval span {
  padding: 2px 14px;
  /*left: 15px;*/
  /*top: 15px;*/
  font-size: 13px;
  text-transform: capitalize;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: rgba(65, 101, 237, 0.2);
  border: solid 1px #4165ed;
  color: #4165ed;
  letter-spacing: 0.7px;
  -webkit-transition: all 0.2s ease-in !important;
  -moz-transition: all 0.2s ease-in !important;
  -o-transition: all 0.2s ease-in !important;
  transition: all 0.2s ease-in !important;
}
</style>

<template>
  <div class="analytic_common_component custom_report_main">
    <div class="component_inner">
      <div class="section_top_bar">
        <div
          class="section_top_bar_inner max_container_1800 d-flex height align-items-center"
        >
          <div class="left basic_form">
            <div class="input_field mt-0">
              <input
                v-model="getScheduledReports.query"
                type="text"
                placeholder="Search report..."
              />
            </div>
          </div>
          <!--<div class="right ml-auto">-->
          <!--</div>-->
        </div>
      </div>

      <div class="report_list clear">
        <h2 class="heading">My Reports</h2>
        <div class="clearfix"></div>
        <!--<div class="report_box create_report"
            v-if="!getScheduledReports.query || getScheduledReports.query.length === 0">
           <div class="box_inside">
               <span class="plus_icon">&times;</span>
               <h3>Create New Report</h3>
           </div>
       </div>-->
        <template v-if="reportsList.length > 0">
          <div class="row">
            <div class="report_box" v-for="(item, key) in reportsList">
              <div class="box_inside">
                <i
                  class="icon d-flex align-items-center justify-content-center fa"
                  :class="getIconClass(item.type)"
                ></i>
                <h3>{{ item.name }}</h3>
                <div>
                  <div class="d-flex bg_main pb-2">
                    <!-- for the social channels tooltip -->
                    <template v-for="(channel, index) in getAccounts(item)">
                      <div class="flex-row" v-if="index < 3" :key="index">
                        <div style="margin-left: -10px">
                          <img class="bg" :src="channel.image" />
                          <i
                            class="bg_icon"
                            :class="getPlatform(channel.account_type)"
                          ></i>
                        </div>
                      </div>
                    </template>

                    <div
                      v-if="
                        item.accounts != null && getAccounts(item).length > 3
                      "
                      class="flex-row"
                    >
                      <div class="bg_count" style="margin-left: -10px">
                        <p>+ {{ getAccounts(item).length - 3 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="report-created-at"
                  >Created:
                  {{ item.created_at | publishedOnlyDateWithShortWeekday }}</p
                >
                <p class="report-interval">
                  <span>
                    {{ item.interval }}
                  </span>
                </p>

                <div class="btn_block">
                  <i class="fa fa-edit" @click="editScheduledReport(item)"></i>
                  <i
                    class="icon-delete-cs"
                    @click="
                      removeScheduledReportService({
                        reportId: item._id,
                        index: key,
                      })
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="reportsList.length === 0">
          <div class="not_found_text">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p>No reports found for your searched query.</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
