<template>
  <div class="suggestion-item-list">
    <div v-for="(item, index) in items" :key="index" class="suggestion-item">
      <div class="flex items-center">
        <div
          class="picture"
          :style="`background: rgba(0, 0, 0, 0) url(${item.picture}) repeat scroll 0% 0%;`"
        ></div>
        <div class="info pl-2">
          <div class="text"
            >{{ item.name }}
            <template v-if="item.verification_status">
              <i
                aria-hidden="true"
                class="fa fa-check-circle verified_icon"
              ></i>
            </template>
          </div>
          <div class="sub_text">
            <p>{{ item.username }}</p>
            <p v-if="item.fan_count"
              >Followers: <b>{{ intToString(item.fan_count) }}</b></p
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MentionsList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.suggestion-item-list {
  max-height: 300px;
  overflow-y: auto;
  width: 300px;

  .suggestion-item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #f5f9fc;
    background-color: #ffffff;
    &:hover {
      background: #f4f9fd;
    }

    .picture {
      height: 40px;
      width: 40px;
      min-width: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      background: #ebf3f7;
      background-size: cover !important;
    }

    .text {
      font-weight: 500;
      color: #3a4557;
      font-size: 0.875rem;
      word-break: break-word;
      word-wrap: break-word;

      .verified_icon {
        color: #2f8ae0;
      }
    }

    .sub_text {
      font-size: 12px;
      color: #989eb5;
      word-break: break-word;
      word-wrap: break-word;

      p {
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        border-right: 1px solid rgba(152, 158, 181, 0.5);
        margin-right: 0.625rem;
        padding-right: 0.625rem;

        &:last-child {
          border-right: 0;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
