import { mapGetters } from 'vuex'
import { storageBaseUrl } from '@src/config/api-utils'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import { UNKNOWN_ERROR } from '../../constants/messages'

const dropzoneMixin = {
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['getJWTToken']),
    dropzoneOptions() {
      return {
        headers: {
          Authorization: 'Bearer ' + this.getJWTToken,
        },
        acceptedFiles: 'image/*',
        url: storageBaseUrl + 'uploadDropzoneImage/Blog',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        maxFiles: 1,
        dictDefaultMessage:
          '  <div class="upload_icon_gray">\n' +
          '                    <i class="cs-upload-arrow"></i>\n' +
          '                    </div>\n' +
          '                    <p>Drag & Drop files here or </p>\n' +
          '                    <p>select a file to upload</p> ',
      }
    },
  },

  methods: {
    vDropzoneCallback: function (file, response) {
      console.debug('Method::vDropzoneCallback', response)

      try {
        if (response.status === true) {
          if (response.process === 'Blog') {
            this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, {
              source: 'Dropzone',
              link: response.link,
            })
          }
        } else {
          this.$refs.blogImageDropzone.removeAllFiles()
          if (response.message) {
            this.alertMessage(response.message, 'error')
          } else if (response === 'You can not upload any more files.') {
            this.alertMessage("You can't upload before remove image.", 'error')
          } else if (response === 'Upload canceled.') {
            this.alertMessage('Upload canceled.', 'error')
          } else if (response.storageFull) {
            this.$store.commit('setMediaStorageLimit', response.usedStorage)
            this.$bvModal.show('media-storage-limits-exceeded-modal')
          } else {
            if (response.indexOf('File is too big') > -1) {
              this.alertMessage('File is too big.', 'error')
            } else {
              this.alertMessage(UNKNOWN_ERROR, 'error')
            }
          }

          $('#blogImageDropzone').removeClass('dz-started')
          $('#blogImageDropzone .dz-image-preview').remove()
        }
      } catch (e) {
        this.$refs.blogImageDropzone.removeAllFiles()
        console.debug('Exception in vDropzoneCallback', e)
        $('#blogImageDropzone').removeClass('dz-started')
        $('#blogImageDropzone .dz-image-preview').remove()
      }
    },
  },
}
export { dropzoneMixin }
