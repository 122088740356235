<template>
  <div
    v-if="isOpen"
    v-on-clickaway="handleClickAway"
    class="w-max absolute rounded-lg dropdown-shadow bg-white focus:outline-none z-50"
    :class="positionClasses"
    style="min-width: 18rem; max-width: 18rem; max-height: 32rem"
  >
    <Picker
      class="emoji-mart-wrap"
      :data="index"
      :emoji-size="18"
      :native="false"
      :emoji-tooltip="true"
      title="Pick your emoji..."
      emoji="point_up"
      @select="emojiUnicodeAdded"
    ></Picker>
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
const index = new EmojiIndex(data)

export default {
  name: 'CstEmojiPicker',
  components: {
    Picker,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom-end',
    },
  },
  data() {
    return {
      loaded: false,
      index: index,
    }
  },
  computed: {
    positionClasses() {
      switch (this.position) {
        case 'bottom-end':
          return 'bottom-0 right-0'
        case 'bottom-start':
          return 'bottom-0 left-0'
        case 'top-end':
          return 'top-0 right-0'
        case 'top-start':
          return 'top-0 left-0'
        default:
          return 'bottom-0 right-0'
      }
    },
    // emojiDataAll() {
    //   return [EmojiDatSmileys, EmojiDataComponent,EmojiDataAnimalsNature, EmojiDataFoodDrink, EmojiDataFlags, EmojiDataSymbols];
    // },
    // emojiGroups() {
    //   return [
    //     { "group": 0, "description": "😀" },
    //     { "group": 1, "description": "👍️" },
    //     { "group": 2, "description": "🏿" },
    //     { "group": 3, "description": "🐶" },
    //     { "group": 4, "description": "🍉" },
    //     { "group": 6, "description": "🎁" },
    //     { "group": 7, "description": "🎶" },
    //     { "group": 8, "description": "⏺️" },
    //     { "group": 9, "description": "🏁" }
    //   ];
    // }
  },
  mounted() {
    console.debug('Emoji Dropdown mounted')
  },
  beforeDestroy() {
    this.$off('on-select')
  },
  methods: {
    handleClickAway() {
      this.$emit('handle-click', this.isOpen, 'emoji')
    },
    emojiUnicodeAdded(emojiUnicode) {
      const Unicode = emojiUnicode.native
      this.$emit('on-select', Unicode)
    },
  },
}
</script>

<style></style>
