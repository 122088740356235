export const powerWords=['A Cut Above',
'Absolute',
'Absolutely Lowest',
'Absolutely',
'Absurd',
'Abuse',
'Accurate',
'Accuse',
'Achieve',
'Actionable',
'Adaptable',
'Adequate',
'Admit',
'Adorable',
'Advantage',
'Advice',
'Affordable',
'Aggravate',
'Aggressive',
'Agitated',
'Agonizing',
'Agony',
'Alarmed',
'Alarming',
'Alienated',
'Aligned',
'All-inclusive',
'Alluring',
'Always',
'Amazing',
'Amp',
'Animated',
'Annihilate',
'Announcing',
'Anonymous',
'Antagonistic',
'Anxious',
'Apocalypse',
'Appalled',
'Approved',
'Approving',
'Argumentative',
'Armageddon',
'Arrogant',
'Ass Kicking',
'Assault',
'Assured',
'Astonishing',
'Astounded',
'Astounding',
'At Ease',
'Atrocious',
'Attractive',
'Audacity',
'Authentic',
'Authoritative',
'Authority',
'Aware',
'Awe-inspiring',
'Awesome',
'Awkward',
'Backbone',
'Backdoor',
'Backed',
'Backlash',
'Backstabbing',
'Badass',
'Balanced',
'Banned',
'Bargain',
'Barrage',
'Basic',
'Battle',
'Beaming',
'Beat Down',
'Beating',
'Beautiful',
'Beauty',
'Begging',
'Behind The Scenes',
'Belief',
'Best-selling',
'Best',
'Better',
'Beware',
'Big',
'Billion',
'Black Market',
'Blacklisted',
'Blast',
'Blessed',
'Blinded',
'Blissful',
'Blood',
'Bloodbath',
'Bloodcurdling',
'Bloody',
'Blunder',
'Blushing',
'Bold',
'Bomb',
'Bona Fide',
'Bonanza',
'Bonus',
'Bootleg',
'Bottom Line',
'Bountiful',
'Brave',
'Bravery',
'Brazen',
'Break',
'Breaking',
'Breakthrough',
'Breathtaking',
'Bright',
'Brilliant',
'Broke',
'Brutal',
'Budget',
'Buffoon',
'Bullshit',
'Bully',
'Bumbling',
'Buy',
'Cadaver',
'Calm',
'Cancel Anytime',
'Capable',
'Captivate',
'Captivating',
'Carefree',
'Case Study',
'Cash',
'Cataclysmic',
'Catapult',
'Catastrophe',
'Caution',
'Censored',
'Centered',
'Certain',
'Certainly',
'Certified',
'Challenge',
'Charming',
'Cheap',
'Cheat-sheet',
'Cheat',
'Cheer',
'Cheerful',
'Child-like',
'Clarity',
'Classified',
'Clear',
'Clueless',
'Collapse',
'Colorful',
'Colossal',
'Comfortable',
'Compare',
'Competitive',
'Complete',
'Completely',
'Completeness',
'Comprehensive',
'Compromise',
'Compulsive',
'Concealed',
'Conclusive',
'Condemning',
'Condescending',
'Confess',
'Confession',
'Confessions',
'Confident',
'Confidential',
'Conquer',
'Conscientious',
'Constructive',
'Content',
'Contrary',
'Controlling',
'Controversial',
'Convenient',
'Convert',
'Cooperative',
'Copy',
'Corpse',
'Corrupt',
'Corrupting',
'Courage',
'Courageous',
'Cover-up',
'Covert',
'Coward',
'Cowardly',
'Crammed',
'Crave',
'Crazy',
'Creative',
'Cringeworthy',
'Cripple',
'Crisis',
'Critical',
'Crooked',
'Crush',
'Crushing',
'Damaging',
'Danger',
'Dangerous',
'Daring',
'Dazzling',
'Dead',
'Deadline',
'Deadly',
'Death',
'Decadent',
'Deceived',
'Deceptive',
'Defiance',
'Definitely',
'Definitive',
'Defying',
'Dejected',
'Delicious',
'Delight',
'Delighted',
'Delightful',
'Delirious',
'Delivered',
'Deplorable',
'Depraved',
'Desire',
'Desperate',
'Despicable',
'Destiny',
'Destroy',
'Detailed',
'Devastating',
'Devoted',
'Diagnosed',
'Direct',
'Dirty',
'Disadvantages',
'Disastrous',
'Discount',
'Discover',
'Disdainful',
'Disempowered',
'Disgusted',
'Disgusting',
'Dishonest',
'Disillusioned',
'Disoriented',
'Distracted',
'Distraught',
'Distressed',
'Distrustful',
'Divulge',
'Document',
'Dollar',
'Doomed',
'Double',
'Doubtful',
'Download',
'Dreadful',
'Dreamy',
'Drive',
'Drowning',
'Dumb',
'Dynamic',
'Eager',
'Earnest',
'Easily',
'Easy',
'Economical',
'Ecstatic',
'Edge',
'Effective',
'Efficient',
'Effortless',
'Elated',
'Eliminate',
'Elite',
'Embarrass',
'Embarrassed',
'Emergency',
'Emerging',
'Emphasize',
'Empowered',
'Enchant',
'Encouraged',
'Endorsed',
'Energetic',
'Energy',
'Enormous',
'Enraged',
'Enthusiastic',
'Epic',
'Epidemic',
'Essential',
'Ethical',
'Euphoric',
'Evil',
'Exactly',
'Exasperated',
'Excellent',
'Excited',
'Excitement',
'Exciting',
'Exclusive',
'Exclusivity',
'Excruciating',
'Exhilarated',
'Expensive',
'Expert',
'Explode',
'Exploit',
'Explosive',
'Exposed',
'Exquisite',
'Extra',
'Extraordinary',
'Extremely',
'Exuberant',
'Eye-opening',
'Fail-proof',
'Fail',
'Failure',
'Faith',
'Famous',
'Fantasy',
'Fascinating',
'Fatigued',
'Faux Pas',
'Fearless',
'Feast',
'Feeble',
'Festive',
'Fierce',
'Fight',
'Final',
'Fine',
'Fired',
'First Ever',
'First',
'Flirt',
'Fluid',
'Focus',
'Focused',
'Fool',
'Fooled',
'Foolish',
'Forbidden',
'Force-fed',
'Forever',
'Forgiving',
'Forgotten',
'Formula',
'Fortune',
'Foul',
'Frantic',
'Free',
'Freebie',
'Freedom',
'Frenzied',
'Frenzy',
'Frightening',
'Frisky',
'Frugal',
'Frustrated',
'Fulfill',
'Fulfilled',
'Full',
'Fully',
'Fun-loving',
'Fundamentals',
'Funniest',
'Funny',
'Furious',
'Gambling',
'Gargantuan',
'Genius',
'Genuine',
'Gift',
'Gigantic',
'Giveaway',
'Glamorous',
'Gleeful',
'Glorious',
'Glowing',
'Gorgeous',
'Graceful',
'Grateful',
'Gratified',
'Gravity',
'Greatest',
'Greatness',
'Greed',
'Greedy',
'Grit',
'Grounded',
'Growth',
'Guaranteed',
'Guilt-free',
'Guilt',
'Gullible',
'Guts',
'Hack',
'Happiness',
'Happy',
'Harmful',
'Harsh',
'Hate',
'Have You Heard',
'Havoc',
'Hazardous',
'Healthy',
'Heart',
'Heartbreaking',
'Heartwarming',
'Heavenly',
'Helpful',
'Helplessness',
'Hero',
'Hesitant',
'Hidden',
'High Tech',
'Highest',
'Highly Effective',
'Hilarious',
'Hoak',
'Hoax',
'Holocaust',
'Honest',
'Honored',
'Hope',
'Hopeful',
'Horrific',
'Horror',
'Hostile',
'How To',
'Huge',
'Humility',
'Humor',
'Hurricane',
'Hurry',
'Hypnotic',
'Idiot',
'Ignite',
'Illegal',
'Illusive',
'Imagination',
'Immediately',
'Imminently',
'Impatience',
'Impatient',
'Impenetrable',
'Important',
'Improved',
'In The Zone',
'Incapable',
'Incapacitated',
'Incompetent',
'Inconsiderate',
'Increase',
'Incredible',
'Indecisive',
'Indulgence',
'Indulgent',
'Inexpensive',
'Inferior',
'Informative',
'Infuriated',
'Ingredients',
'Innocent',
'Innovative',
'Insane',
'Insecure',
'Insider',
'Insider',
'Insidious',
'Inspired',
'Inspiring',
'Instant Savings',
'Instantly',
'Instructive',
'Intel',
'Intelligent',
'Intense',
'Interesting',
'Intriguing',
'Introducing',
'Invasion',
'Investment',
'Iron-clad',
'Ironclad',
'Irresistible',
'Irs',
'Jackpot',
'Jail',
'Jaw-dropping',
'Jealous',
'Jeopardy',
'Jittery',
'Jovial',
'Joyous',
'Jubilant',
'Judgmental',
'Jumpstart',
'Just Arrived',
'Keen',
'Kickass',
'Kickstart',
'Know It All',
'Lame',
'Largest',
'Lascivious',
'Last Chance',
'Last Minute',
'Last',
'Latest',
'Laugh',
'Laughing',
'Launch',
'Launching',
'Lavishly',
'Lawsuit',
'Left Behind',
'Legendary',
'Legitimate',
'Liberal',
'Liberated',
'Lick',
'Lies',
'Life-changing',
'Lifetime',
'Light',
'Lighthearted',
'Likely',
'Limited',
'Literally',
'Little-known',
'Loathsome',
'Lonely',
'Looming',
'Loser',
'Lost',
'Love',
'Lunatic',
'Lurking',
'Lust',
'Luxurious',
'Luxury',
'Lying',
'Magic',
'Magical',
'Magnificent',
'Mainstream',
'Malicious',
'Mammoth',
'Manipulative',
'Marked Down',
'Massive',
'Maul',
'Mediocre',
'Meditative',
'Meltdown',
'Memorability',
'Memorable',
'Menacing',
'Mesmerizing',
'Meticulous',
'Mind-blowing',
'Minimalist',
'Miracle',
'Mired',
'Mischievous',
'Misgiving',
'Missing Out',
'Mistake',
'Monetize',
'Money',
'Moneyback',
'Moneygrubbing',
'Monumental',
'Most Important',
'Motivated',
'Mouth-watering',
'Murder',
'Mystery',
'Nail',
'Naked',
'Natural',
'Naughty',
'Nazi',
'Nest Egg',
'Never',
'New',
'Nightmare',
'No Good',
'No Obligation',
'No One Talks About',
'No Questions Asked',
'No Risk',
'No Strings Attached',
'Non-controlling',
'Noted',
'Novelty',
'Now',
'Obnoxious',
'Obsessed',
'Obsession',
'Obvious',
'Odd',
'Off-kilter',
'Off-limits',
'Off-the Record',
'Offensive',
'Official',
'Okay',
'On-demand',
'Open-minded',
'Opportunities',
'Optimistic',
'Ordeal',
'Outlawed',
'Outrageousness',
'Outstanding',
'Overcome',
'Overjoyed',
'Overnight',
'Overwhelmed',
'Painful',
'Painless',
'Painstaking',
'Pale',
'Panic',
'Panicked',
'Paralyzed',
'Passionate',
'Pathetic',
'Pay Zero',
'Payback',
'Peril',
'Perplexed',
'Perspective',
'Pessimistic',
'Pioneering',
'Piranha',
'Pitfall',
'Pitiful',
'Placid',
'Plague',
'Played',
'Playful',
'Pleased',
'Pluck',
'Plummet',
'Plunge',
'Poison',
'Poisonous',
'Poor',
'Popular',
'Portfolio',
'Pound',
'Powerful',
'Powerless',
'Practical',
'Preposterous',
'Prestige',
'Price',
'Priceless',
'Pride',
'Prison',
'Privacy',
'Private',
'Privileged',
'Prize',
'Productive',
'Professional',
'Profit',
'Profitable',
'Profound',
'Promiscuous',
'Promising',
'Promote',
'Protect',
'Protected',
'Proven',
'Provocative',
'Provoke',
'Psychological',
'Pummel',
'Punch',
'Punish',
'Pus',
'Quadruple',
'Quality',
'Quarrelsome',
'Quick-start',
'Quick',
'Quickly',
'Quiet',
'Radiant',
'Rare',
'Ravenous',
'Rebellious',
'Recession-proof',
'Reckoning',
'Recognized',
'Recommend',
'Recreate',
'Reduced',
'Reflective',
'Refugee',
'Refund',
'Refundable',
'Reject',
'Relaxed',
'Release',
'Reliable',
'Remarkable',
'Replicate',
'Report',
'Reprimanding',
'Repulsed',
'Repulsive',
'Research',
'Resentful',
'Resourceful',
'Responsible',
'Responsive',
'Rested',
'Restricted',
'Results',
'Retaliating',
'Reveal',
'Revealing',
'Revenge',
'Revengeful',
'Revisited',
'Revolting',
'Revolutionary',
'Reward',
'Rich',
'Ridiculous',
'Risky',
'Riveting',
'Rookie',
'Rowdy',
'Ruin',
'Rules',
'Ruthless',
'Sabotaging',
'Sacred',
'Sadistic',
'Sadly',
'Sadness',
'Safe',
'Safety',
'Sale',
'Sampler',
'Sarcastic',
'Satisfied',
'Savage',
'Savagery',
'Save',
'Savings',
'Savvy',
'Scam',
'Scandal',
'Scandalous',
'Scarce',
'Scared',
'Scary',
'Scornful',
'Scream',
'Searing',
'Secret Agenda',
'Secret Plot',
'Secret',
'Secrets',
'Secure',
'Security',
'Seductive',
'Seething',
'Seize',
'Selected',
'Self-hating',
'Self-sufficient',
'Sensational',
'Senseless',
'Sensual',
'Serene',
'Severe',
'Sex',
'Sexy',
'Shaking',
'Shameful',
'Shameless',
'Shaming',
'Shatter',
'Shellacking',
'Shocking',
'Should',
'Shrewd',
'Sick And Tired',
'Signs',
'Silly',
'Simple',
'Simplicity',
'Simplified',
'Simplistic',
'Sincere',
'Sinful',
'Sins',
'Six-figure',
'Sizable',
'Skill',
'Skyrocket',
'Slaughter',
'Slave',
'Sleazy',
'Sleeping',
'Sly',
'Smash',
'Smiling',
'Smug',
'Smuggled',
'Sneak-peek',
'Sneaky',
'Sniveling',
'Snob',
'Snooty',
'Snotty',
'Soar',
'Soaring',
'Solid',
'Solution',
'Spank',
'Special',
'Spectacular',
'Speedy',
'Spell-binding',
'Spine',
'Spirit',
'Spirited',
'Spiteful',
'Spoiler',
'Spontaneous',
'Spotlight',
'Spunky',
'Squirming',
'Stable',
'Staggering',
'Startling',
'Steady',
'Stealthy',
'Steamy',
'Step-by-step',
'Still',
'Stoic',
'Strange',
'Strangle',
'Strategy',
'Stressed',
'Strong',
'Strongly Suggest',
'Struggle',
'Stuck Up',
'Studies',
'Stunning',
'Stupid-simple',
'Stupid',
'Sturdy',
'Sublime',
'Succeed',
'Success',
'Successful',
'Suck',
'Suddenly',
'Suffer',
'Sunny',
'Super-human',
'Superb',
'Supercharge',
'Superior',
'Supported',
'Supportive',
'Sure Fire',
'Sure',
'Surefire',
'Surge',
'Surging',
'Surprise',
'Surprised',
'Surprising',
'Survival',
'Survive',
'Suspicious',
'Sweaty',
'Swoon-worthy',
'Swoon',
'Tailspin',
'Tank',
'Tantalizing',
'Targeted',
'Tawdry',
'Tease',
'Technology',
'Teetering',
'Tempting',
'Tenacious',
'Tense',
'Terrible',
'Terrific',
'Terrified',
'Terrifying',
'Terror',
'Terrorist',
'Tested',
'Thankful',
'The Truth',
'Threaten',
'Threatened',
'Thrilled',
'Thrilling',
'Thug',
'Ticked Off',
'Tickled',
'Timely',
'Today',
'Torture',
'Toxic',
'Track Record',
'Trade Secret',
'Tragedy',
'Tragic',
'Transform',
'Transparency',
'Trap',
'Trapped',
'Trauma',
'Treacherous',
'Treasure',
'Tremendous',
'Trend',
'Tricks',
'Triggers',
'Triple',
'Triumph',
'Truly',
'Trusting',
'Trustworthy',
'Truth',
'Truthful',
'Turbo-charge',
'Tweaks',
'Twitching',
'Ultimate',
'Unadulterated',
'Unassuming',
'Unauthorized',
'Unbelievable',
'Unburdened',
'Uncaring',
'Uncensored',
'Uncertain',
'Uncomfortable',
'Unconditional',
'Uncontrollable',
'Unconventional',
'Uncovered',
'Undeniable',
'Under Priced',
'Undercover',
'Underground',
'Underhanded',
'Underused',
'Unexpected',
'Unforgettable',
'Unheard Of',
'Unhurried',
'Uninterested',
'Unique',
'Unjustified',
'Unknowingly',
'Unleashed',
'Unlimited',
'Unlock',
'Unparalleled',
'Unreliable',
'Unresponsive',
'Unseen',
'Unstable',
'Unstoppable',
'Unsure',
'Unsurpassed',
'Untapped',
'Unusual',
'Up-sell',
'Upbeat',
'Uplifted',
'Uplifting',
'Urge',
'Urgent',
'Useful',
'Useless',
'Validate',
'Valor',
'Valuable',
'Value',
'Vanquish',
'Vaporize',
'Venomous',
'Verify',
'Vibrant',
'Vicious',
'Victim',
'Victory',
'Vigorous',
'Vilified',
'Vindictive',
'Violated',
'Violent',
'Volatile',
'Vulnerable',
'Waiting',
'Wanted',
'Wanton',
'Warning',
'Waste',
'Weak',
'Wealth',
'Weird',
'What No One Tells You',
'Whip',
'Whopping',
'Wicked',
'Wild',
'Willpower',
'Withheld',
'Wonderful',
'Wondrous',
'Woozy',
'World',
'Worry',
'Worst',
'Worthwhile',
'Wounded',
'Wreaking',
'Youthful',
'Zen',
'Zinger',
'Kill',
'Kills',
'Killed',
'Killing',
'Sizzle',
'Sizzles',
'Sizzled',
'Sizzling',
'Sizzlingly',
'Turbocharges',
'Turbo-charge',
'Challenge',
'Discover',
'Crave',
'Unpopular',
'Impressive',
'Embarrassing',
'Sensational',
'Sinful',
'Zen',
'Surprising',
'Alive',
'Odd',
'Ravenous',
'Lazy',
'Avoid',
'Demoralizing',
'Problem',
'Horrifying',
'Attack',
'Traumatized',
'Insult',
'Horribly',
'Hell',
'Relentless',
'Master',
'Perfect',
'Super',
'Create',
'Packed',
'Deep',
'Gripping',
'Cool',
'Seriously',
'Envy',
'Lucrative',
'Steal',
'Bona',
'Fide',
'Masterclass',
'Faux',
'Pas',
'Belong',
'Stop',
'Polarizing',
'Dominate',
'Is Here',
'Smuggle',
'Great',
'Fun',
]
