<template>
  <div :class="class_name">
    <div class="name">
      <p>Sentiment</p>
    </div>
    <div
      class="value"
      v-if="post._source.sentiment && post._source.sentiment.length > 0"
    >
      <i
        class="cs-sad-face sad"
        v-tooltip="'Negative'"
        v-if="post._source.sentiment[0].label == 'negative'"
      >
      </i>
      <i
        class="cs-happy-face smile"
        v-tooltip="'Positive'"
        v-if="
          post._source.sentiment[0].label == 'positive' &&
          post._source.sentiment[0].probability >= 0.65
        "
      >
      </i>
      <i
        class="cs-normal-face normal"
        v-tooltip="'Neutral'"
        v-else-if="
          post._source.sentiment[0].label == 'positive' &&
          post._source.sentiment[0].probability < 0.65
        "
      >
      </i>
      <i
        class="cs-normal-face normal"
        v-tooltip="'Neutral'"
        v-if="post._source.sentiment[0].label == 'neutral'"
      >
      </i>
    </div>
    <div class="value" v-else>
      <i class="cs-normal-face normal" v-tooltip="'Neutral'"> </i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {},
    class_name: {
      type: String
    }
  }
}
</script>
