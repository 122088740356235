<template>
  <div class="compare-box height-100">
    <div class="compare-box__header flex">
      <div class="compare-box__platform">
        <i class="fab fa-facebook-f"></i>
      </div>
      {{ title }}
      <div class="ml-auto">
        <i
          class="fa fa-info info_hover"
          v-b-popover.hover.html.topleft="desc"
        ></i>
      </div>
    </div>

    <div
      v-if="series.length <= 0"
      class="opacity-70"
      style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'20px'"></clip-loader>
    </div>
    <div class="h-chart" v-if="series.length > 0">
      <!--      isDataAvailabale(series)-->
      <p class="text-center" v-if="!isDataAvailabale(series)">
        Data not found for the selected pages and filters
      </p>
      <highcharts
        ref="growth-chart-container"
        :options="dataOptions"
        v-if="isDataAvailabale(series)"
      >
      </highcharts>
    </div>
  </div>
</template>

<script>
import {themeColors} from '@src/theme'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: 'ColumnChart',
  data () {
    return {
      dataOptions: {
        title: {
          text: ' '
        },
        chart: {
          marginTop: 50
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },

        xAxis: {
          type: this.type
        },

        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          y: -30,
          itemMarginBottom: 5,
          itemStyle: {
            fontSize: '12px',
            fontWeight: 'bold',
            textOverflow: 'ellipsis'
          }
        },

        tooltip: {
          headerFormat:
            '<span style="font-size:11px; margin-bottom: 0.4rem">{point.key}</span><table>',
          pointFormat:
            '<tr class="my-1"><td style="color:{series.color};padding:0">{series.name}:   </td>' +
            '<td style="padding:0"><b>  {point.y}</b></td></tr>',
          footerFormat: '</table>',
          backgroundColor: '#FFFFFF',
          borderColor: '#000000',
          shared: true,
          useHTML: true,
          crosshairs: [true]
        },
        colors: [
          themeColors.purple[500],
          themeColors.green[500],
          themeColors.pink[500],
          themeColors.orange[600],
          themeColors.red[500],
          themeColors.blue[500],
        ],
        plotOptions: {
          series: {
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            },
            shadow: true,
            lineWidth: 4
          }
        },
        series: this.series
      }
    }
  },
  props: { title: String, series: Array, type: String, desc: String },

  watch: {
    series (newValue, oldValue) {
      if (newValue.length > 0 && newValue !== oldValue) {
        this.redraw(newValue)
      }
    }
  },

  mounted () {
    // this.$nextTick(() => {
    //   console.debug('MOUNTED', this.$refs['growth-chart-container'])
    //   // this.initializeDataOptions()
    //   // this.dataOptions.chart.height = this.$refs['growth-chart-container'].offsetHeight - 32
    // })
  },
  methods: {
    redraw (newValue) {
      this.$set(this.dataOptions, 'series', newValue)
    },
    isEmpty (obj) {
      return Object.keys(obj).length === 0
    },
    initializeDataOptions () {
      this.$nextTick(() => {
        Object.assign(this.dataOptions.series, this.series)
      })
    },
    isDataAvailabale (series) {
      const validData = series.filter((obj) => obj.data.length > 0)
      return validData.length > 0
    }
  }
}
</script>
