var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{staticClass:"modish-modal",attrs:{"id":"accounts_not_allowed_modal","size":"lg","hide-footer":"","hide-header":"","centered":"","no-close-on-esc":"","no-close-on-backdrop":""}},[_c('div',{staticClass:"modal_head align-items-center"},[_c('h4',[_vm._v("Account(s) are already connected")]),_c('button',{staticClass:"modal_head__close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('accounts_not_allowed_modal')}}},[_vm._v("× ")])]),_c('div',{staticClass:"mt-0 mb-4"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal_head d-flex align-items-start"},[_c('div',{staticClass:"modish-modal__header"},[_c('p',[_vm._v(" The following social account(s) are already connected under a different super admin. Therefore, they would need to remove this connection to be added under your workspace. ")]),_c('p',[_vm._v(" If you need assistance, don't hesitate to contact us at support@contentstudio.io so we can further assist. ")])])]),(_vm.data.notAllowedIds.length)?_c('div',{staticClass:"mx-2 my-4"},[(_vm.checkAccountInclusion('firstSectionItems'))?_c('section',{staticClass:"my-4"},[_c('div',{staticClass:"flex justify-start items-center mb-3"},[_c('h2',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.data.firstSectionHeader))])]),(_vm.data.firstSectionItems.length)?_c('div',{staticClass:"grid gap-x-3.5 gap-y-2",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                ? 'grid-cols-2'
                : 'grid-cols-3'},[_vm._l((_vm.data.firstSectionItems),function(item,key){return [(_vm.isNotAddedAccount(item))?_c('div',{key:key,staticClass:"flex items-center shadow-sm justify-start p-3 rounded-lg border-2 border-solid min-w-fit"},[_c('img',{staticClass:"rounded-full w-10 h-10",attrs:{"src":_vm.getImageLink(item),"alt":"profile_image"}}),_c('div',{staticClass:"ml-4 inline-block truncate select-none",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                      ? 'w-72'
                      : 'w-44'},[_vm._v(" "+_vm._s(_vm.getAccountName(item))+" ")])]):_vm._e()]})],2):_vm._e()]):_vm._e(),(
            (_vm.platformName === 'facebook' ||
              _vm.platformName === 'linkedin' ||
              _vm.platformName === 'tumblr') &&
            _vm.checkAccountInclusion('secondSectionItems')
          )?_c('section',{staticClass:"my-4"},[_c('div',{staticClass:"flex justify-start items-center mb-3"},[_c('h2',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.data.secondSectionHeader))])]),(_vm.data.secondSectionItems.length)?_c('div',{staticClass:"grid gap-x-3.5 gap-y-2 pt-1.5",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                ? 'grid-cols-2'
                : 'grid-cols-3'},[_vm._l((_vm.data.secondSectionItems),function(item,key){return [(_vm.isNotAddedAccount(item))?_c('div',{key:key,staticClass:"flex items-center shadow-sm justify-start p-3 rounded-lg border-2 border-solid min-w-fit"},[_c('img',{staticClass:"rounded-full w-10 h-10",attrs:{"src":_vm.getImageLink(item),"alt":"profile_image"}}),_c('div',{staticClass:"ml-4 inline-block truncate select-none",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                      ? 'w-72'
                      : 'w-44'},[_vm._v(" "+_vm._s(_vm.getAccountName(item))+" ")])]):_vm._e()]})],2):_vm._e()]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }