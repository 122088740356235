<template>
  <div class="profile_picture d-flex align-items-start mt-3">
    <div class="picture_block picture_block_background">
      <div
        class="img"
        :style="
          'background:url(' +
          commonMethods.getSocialDetail(temp, post).image +
          ')'
        "
      ></div>
    </div>
    <div class="text_block">
      <span style="float: right">
        <clip-loader
          v-if="!comment.has_failed"
          style="padding-left: 10px"
          class="d-inline-block"
          color="rgba(152, 158, 181, 0.8)"
          :size="'20px'"
        ></clip-loader>
      </span>
      <div class="text">
        <span class="username mr-2">
          <strong
            v-html="commonMethods.getSocialDetail(temp, post).name"
          ></strong>
        </span>
        <span class="comment_text" v-html="comment.comment_message"></span>
      </div>
      <div class="sub_text">
        <div class="option_block d-flex align-items-center p-0">
          <div v-if="comment.has_failed" class="on-failed-comment pl-2">
            <p
              ><i
                v-b-popover.hover.html.top="comment.error_message"
                class="fas fa-exclamation has-tooltip rounded-full text-xs text-white pl-1.5 pr-1.5 mr-1 bg-red-400"
              ></i
              >Comment wasn't sent.
              <span class="link" @click="sendComment">Retry </span> or
              <span
                class="link"
                @click="
                  removeComment(
                    rootCommentId,
                    isRoot ? null : comment.comment_id
                  )
                "
                >Delete
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.on-failed-comment {
  .link {
    color: #00aced;
    font-weight: 500;
    cursor: pointer;
  }

  p {
    color: fade(#ff3366, 80%);
    font-size: 12px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'

export default {
  components: {},
  props: {
    temp: { type: Object, default: () => {} },
    isRoot: { type: Boolean, default: false },
    rootCommentId: { type: String, default: '' },
    post: { type: Object, default: () => {} },
    comment: { type: Object, default: () => {} },
    removeComment: { type: Function, default: () => {} },
    appendComment: { type: Function, default: () => {} },
  },
  data() {
    return {
      commonMethods: commonMethods,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    ...mapActions(['reply']),
    async sendComment() {
      this.comment.has_failed = false
      const response = await this.reply(this.comment.payload)
      if (response.isValid) {
        this.removeComment(
          this.rootCommentId,
          this.isRoot ? null : this.comment.comment_id
        )
        this.appendComment(
          this.comment.type,
          response.response,
          this.comment.index
        )
      } else {
        this.comment.error_message = response.response?.error
          ? response.response.error
          : response.message
        this.comment.has_failed = true
      }
    },
  },
}
</script>
