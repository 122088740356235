<template>
  <div class="fileUploadModel__upload_file_wrapper__right_block">
    <div class="flex-row text-right">
      <button
        type="button"
        class="close"
        @click="$bvModal.hide('insertFileModel')"
        data-dismiss="modal"
        >&times;</button
      >
    </div>

    <div class="fileUploadModel__upload_file_wrapper__right_block__right_inner">
      <h3
        >All Uploads
        <span v-if="getFileWidget.localUploads.total"
          >| {{ getFileWidget.localUploads.total }} Files</span
        ></h3
      >

      <div class="flex-row d-flex">
        <div class="">
          <b-dropdown
            variant="studio-theme"
            id="dropdown-right"
            class="studio-theme-dropdown mr-2 dropdown-size-medium"
            no-caret
          >
            <template slot="button-content">
              <i class="icon-all-cs"></i>
              <span class="capitalize_text">{{
                getFileWidget.localUploads.filters.type
              }}</span>
              <i class="icon-dropdown-cs"></i>
            </template>

            <b-dropdown-item
              @click.prevent="changeUploadType('All Types')"
              :class="{
                active: getFileWidget.localUploads.filters.type === 'All Types',
              }"
              ><i class="icon-all-cs"></i>All Types</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadType('Images')"
              :class="{
                active: getFileWidget.localUploads.filters.type === 'Images',
              }"
              ><i class="icon-Image"></i>Images</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadType('Videos')"
              :class="{
                active: getFileWidget.localUploads.filters.type === 'Videos',
              }"
              ><i class="icon-Film"></i>Videos</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadType('Gifs')"
              :class="{
                active: getFileWidget.localUploads.filters.type === 'Gifs',
              }"
              ><i class="icon-Image"></i>Gifs</b-dropdown-item
            >
          </b-dropdown>

          <b-dropdown
            variant="studio-theme"
            id="dropdown-right"
            class="studio-theme-dropdown mr-2 dropdown-size-medium"
            no-caret
          >
            <template slot="button-content">
              <i class="icon-schedule-cs"></i>
              <span class="capitalize_text">{{
                getFileWidget.localUploads.filters.sort
              }}</span>
              <i class="icon-dropdown-cs"></i>
            </template>

            <b-dropdown-item
              @click.prevent="changeUploadSort('Most recent uploads')"
              :class="{
                active:
                  getFileWidget.localUploads.filters.sort ===
                  'Most recent uploads',
              }"
              ><i class="icon-schedule-cs"></i>Most recent
              uploads</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadSort('Oldest uploads')"
              :class="{
                active:
                  getFileWidget.localUploads.filters.sort === 'Oldest uploads',
              }"
              ><i class="icon-Image"></i>Oldest uploads</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadSort('A to Z')"
              :class="{
                active: getFileWidget.localUploads.filters.sort === 'A to Z',
              }"
              ><i class="icon-AtoZ"></i>A to Z</b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="changeUploadSort('Z to A')"
              :class="{
                active: getFileWidget.localUploads.filters.sort === 'Z to A',
              }"
              ><i class="icon-ZtoA"></i>Z to A</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <div class="ml-auto">
          <div class="search_input with_icon_left">
            <div class="search_inner w-100 cs-input-grey">
              <input
                type="text"
                placeholder="Search by name"
                v-model="search"
                @input="searchByKeywordDebounce($event)"
                @keyup.enter="searchByKeywordUploads(search)"
                ref="searchArea"
              />
              <button
                class="search_btn"
                @click.prevent="searchByKeywordUploads(search)"
              >
                <i class="icon-Search"></i>
              </button>
              <i
                v-if="search"
                class="clearsearch"
                @click="clearSearch()"
                v-tooltip.top-center="'Clear'"
                >×</i
              >
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="row d-flex p-only-horizontal">
        <clip-loader
          color="#2f8ae0"
          :size="'30px'"
          v-if="
            getPublishLoaders.fetchWidgetUploadedFiles &&
            getFileWidget.localUploads.items.length === 0
          "
        ></clip-loader>

        <template v-else>
          <template v-if="getFileWidget.localUploads.items.length">
            <div
              class="row d-flex fileUploadModel__upload_file_wrapper__right_block__right_inner__fix_height"
            >
              <div
                class="col-4 col-padding"
                v-for="file in getFileWidget.localUploads.items"
                @click="processFileSelection(file)"
              >
                <div
                  class="fileUploadModel__upload_file_wrapper__right_block__right_inner__pic_block"
                >
                  <div
                    class="fileUploadModel__upload_file_wrapper__right_block__right_inner__pic_block__content"
                  >
                    <p v-if="file.name" class="truncate">{{ file.name }}</p>
                    <p class="size" v-if="file.size">{{
                      bytesToSize(file.size)
                    }}</p>
                    <p>{{
                      getWorkspaceTimeZoneTime(
                        file.created_at,
                        'MMMM DD, YYYY, hh:mm A'
                      )
                    }}</p>
                    <div
                      class="fileUploadModel__upload_file_wrapper__right_block__right_inner__content__action_icons"
                    >
                      <i
                        class="fa fa-remove image_remove_icon"
                        v-tooltip.top-center="'Remove Media'"
                        v-on:click.stop="processFileSelection()"
                        @click="removeMedia(file)"
                      ></i>
                      <i
                        class="icon-edit-cs canva_edit_icon"
                        v-tooltip.top-center="'Canva Edit'"
                        style="z-index: 999999999"
                        v-if="file.canva_design_id"
                        v-on:click.stop="processFileSelection()"
                        @click="editCanvaDesign(file)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_img absolute-spinner"
                    :class="{
                      active:
                        getFileWidget.localUploads.selectedItems.includes(file),
                    }"
                  >
                    <img
                      v-if="file.thumbnail"
                      :src="getResizedImageURL(file.thumbnail, 0, 450)"
                      @error="$event.target.src = `${file.thumbnail}`"
                      alt=""
                    />
                    <img
                      v-else
                      :src="getResizedImageURL(file.link, 0, 450)"
                      @error="$event.target.src = `${file.link}`"
                      alt=""
                    />
                    <i
                      class="fa fa-check"
                      v-if="
                        getFileWidget.localUploads.selectedItems.includes(file)
                      "
                    ></i>
                  </div>
                </div>
                <!--<beat-loader :color="'#436aff'" v-if="file.is_processing"></beat-loader>-->
              </div>

              <div
                class="col-12"
                v-if="getFileWidget.localUploads.paginationScroll"
              >
                <infinite-loading
                  :identifier="infiniteId"
                  @infinite="onInfiniteWidgetUploadedFiles"
                  ref="infiniteLoading"
                >
                  <span slot="spinner" class="col-12 pt-3 d-block">
                    <!--<beat-loader :color="'#436aff'"></beat-loader>-->
                  </span>
                </infinite-loading>
              </div>
            </div>
          </template>

          <template
            v-if="
              getFileWidget.localUploads.paginationScroll === false &&
              getFileWidget.localUploads.items.length === 0
            "
          >
            <template v-if="search && search.trim() && search.length">
              <no-results-found
                :message="'No files have been found for selected filters.'"
              ></no-results-found>
            </template>
            <template v-else>
              <no-results-found
                :message="'No files have been uploaded.'"
              ></no-results-found>
            </template>
          </template>
        </template>
      </div>

      <div
        class="bottom_btn_section"
        v-if="getFileWidget.localUploads.selectedItems.length"
      >
        <button
          class="btn blue loader royal_blue mt-3"
          @click.prevent="insertFile(source)"
        >
          <span>Insert </span>
          <span>{{ getFileWidget.localUploads.selectedItems.length }}</span>
          <span> file(s)</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'lodash/debounce'
import NoResultsFound from '../../../../discovery/components/topics/NoResultsFound'
import { swalAttributes } from '@common/constants/common-attributes'
import { EventBus } from '@common/lib/event-bus'
export default {
  props: ['source'],
  name: 'RecentUpload',
  components: {
    InfiniteLoading,
    NoResultsFound
  },
  data () {
    return {
      search: '',
      infiniteId: +new Date()
    }
  },
  created () {
    this.search = this.getFileWidget.localUploads.filters.search
    if (this.getFileWidget.localUploads.fetchStatus === false) {
      this.infiniteId += 1
      this.fetchWidgetUploadedFiles()
      this.setWidgetLocalUploadsFetchStatus(true)
    }
  },
  computed: {
    ...mapGetters(['getFileWidget', 'getPublishLoaders']),

    countUploadedFiles () {
      if (this.files.length) { return this.files.length - this.removed_files.length }
      return 0
    }
  },
  beforeDestroy () {
    this.resetLocalUploads()
  },
  methods: {
    ...mapActions([
      'setWidgetLocalUploadsFetchStatus',
      'setWidgetUploadedFilesPage',
      'resetFileWidget',
      'resetLocalUploads',
      'setWidgetUploadedFilesTypeFilter',
      'settWidgetUploadedFilesItems',
      'setWidgetUploadedFilesSortFilter',
      'setWidgetUploadedFilesSearch',
      'setWidgetSelectedUploadedFilesItems'
    ]),

    onInfiniteWidgetUploadedFiles ($state) {
      // this.infiniteId += 1;
      this.setWidgetUploadedFilesPage(this.getFileWidget.localUploads.page + 1)
      this.fetchWidgetUploadedFiles($state)
    },
    changeUploadType (type) {
      this.setWidgetUploadedFilesTypeFilter(type)
      this.refetchUploads()
    },
    changeUploadSort (sort) {
      this.setWidgetUploadedFilesSortFilter(sort)
      this.refetchUploads()
    },
    searchByKeywordUploads (search) {
      this.setWidgetUploadedFilesSearch(search.trim())
      this.refetchUploads()
    },
    searchByKeywordDebounce: debounce(function (event) {
      console.debug('Method::searchByKeywordDebounce', event, this.search)
      this.searchByKeywordUploads(this.search)
    }, 500),
    refetchUploads () {
      this.infiniteId += 1
      this.$store.commit('setFetchWidgetUploadedFilesLoader', true)
      this.setWidgetSelectedUploadedFilesItems([])
      this.setWidgetUploadedFilesPage(1)
      this.settWidgetUploadedFilesItems([])
      this.fetchWidgetUploadedFiles()
    },
    clearSearch () {
      this.search = ''
      this.searchByKeywordUploads('')
    },
    removeTempImage (name) {
      this.removed_files.push(name)
      if (this.countUploadedFiles === 0) this.resetUploads()
    },
    resetUploads () {
      this.removed_files = []
      this.files = []
      this.event = null
      this.uploadFile = true
      this.$refs.myFileInput.value = ''
    },
    async removeMedia (media) {
      console.debug('Method::removeMedia', media)
      const confirm = await this.$bvModal.msgBoxConfirm(
        'It will also effects the posting plan in which you used this media, are you sure you want to delete this media?',
        {
          title: 'Remove Media',
          ...swalAttributes()
        }
      )
      if (confirm) {
        const response = await this.$store.dispatch(
          'removeGCSMedia',
          media.link
        )
        if (response) {
          const items = this.getFileWidget.localUploads.items
          items.forEach((item, index) => {
            if (item.link === media.link) {
              // removing media item from states
              items.splice(index, 1)

              // removing item from selected files in case the file is selected
              const selectedItems =
                this.getFileWidget.localUploads.selectedItems
              if (selectedItems.includes(media)) {
                const index = selectedItems.indexOf(media)
                if (index > -1) selectedItems.splice(index, 1)
              }
            }
          })
        }
        console.debug('Method::removeMedia', response)
      }
    }
  }
}
</script>

<style scoped></style>
