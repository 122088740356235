import { mapGetters } from 'vuex'
import textHelper from '@common/lib/text-helper'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters([
      'getPinterestBoards',
      'getLinkedinCompanyPages',
      'getLinkedinAccounts',
      'getInstagramAccounts',
      'getFacebookPagesList',
      'getTwitterAccounts',
    ]),
  },
  methods: {
    /**
     * Reseting the date time to the default 30 days.
     *
     * @param analytics
     */
    reloadDatePicker(analytics) {
      analytics.startDate = analytics.ranges['Last 30 Days'][0]
      analytics.endDate = analytics.ranges['Last 30 Days'][1]
      this.analytics.isChanged = false
    },
    getActiveBoardImage(boardId) {
      if (this.getPinterestBoards.length > 0) {
        const boards = this.getPinterestBoards.filter((item) => {
          if (item.board_id === boardId) return true
        })
        if (boards.length > 0) {
          return boards[0].image
        }
      }
      return null
    },
    getActiveCompanyPage(pageId) {
      if (this.getLinkedinAccounts.items.length > 0) {
        const boards = this.getLinkedinAccounts.items.filter((item) => {
          if (item.linkedin_id.toString() === pageId.toString()) return true
        })
        if (boards.length > 0) {
          return boards[0].image
        }
      }
      return null
    },
    isLink(text) {
      if (!text) return text
      return textHelper.convertToLinkClass(text)
      // return text
    },
    isHashtag(text) {
      if (!text) return text
      return textHelper.convertToHashtagsClass(text)
      // return text
    },
    nextLineReplacement(text) {
      if (!text) return text
      return textHelper.replaceByHtmlNextLine(text)
    },
    findInstaAccount(instaId) {
      return this.getInstagramAccounts.items.find(
        (x) => x.instagram_id === instaId
      )
    },
    findFacebookAccount(facebookId) {
      return this.getFacebookPagesList.find((x) => x.facebook_id === facebookId)
    },
    findLinkedinAccount(linkedinId) {
      return this.getLinkedinAccounts.items.find(
        (x) => x.linkedin_id.toString() === linkedinId.toString()
      )
    },
    findTwitterAccount(twitterId) {
      return this.getTwitterAccounts.items.find(
          (x) => x.twitter_id === twitterId
      )
    },
    findPinteresrBoard(boardId) {
      return this.getPinterestBoards.find((x) => x.board_id === boardId)
    },
    getProfileType(accountType, account = null) {
      switch (accountType) {
        case 'instagram':
          return 'Profile'
        case 'facebook':
          return 'Page'
        case 'pinterest':
          return 'Board'
        case 'linkedin':
          return account ? account.type : 'Page'
        default:
          return 'Page'
      }
    },
    getImage(network, post) {
      switch (network) {
        case 'instagram':
          return post.profile_picture_url
        case 'facebook':
          return (
            'https://graph.facebook.com/' +
            post.page_id +
            '/picture?type=square'
          )
        case 'linkedin':
          if (this.findLinkedinAccount(post.linkedin_id)) {
            return this.findLinkedinAccount(post.linkedin_id).image
          }
          return ''
        case 'twitter':
          return post.profile_image_url
        case 'pinterest':
          if (this.findPinteresrBoard(post.board_id)) {
            return this.findPinteresrBoard(post.board_id).image
          }
          return ''
      }
    },

    /**
     *
     * @param account object
     * @param type string
     */
    channelImage(account, type) {
      const image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      const gmbImage =
        'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      if (!account) return image

      if (account && account.platform_logo) return account.platform_logo

      switch (type) {
        case 'facebook':
          return account.image
            ? account.image
            : account.picture && account.picture.url
            ? account.picture.url
            : image
        case 'twitter':
        case 'linkedin':
        case 'pinterest':
          return account.image && account.image.large && account.image.large.url
            ? account.image.large.url
            : account.image
            ? account.image
            : image
        case 'instagram':
          return account.image
            ? account.image
            : account.profile_picture
            ? account.profile_picture
            : image
        case 'medium':
          return account.imageUrl ? account.imageUrl : image
        case 'gmb':
          return account.location_name ? gmbImage : image
      }
    },
    isPostImageAvailable(network, post) {
      switch (network) {
        case 'instagram':
          return 'media_url' in post
        case 'facebook':
          return 'full_picture' in post
        case 'linkedin':
          return 'image' in post
        case 'twitter':
          return 'media_url' in post
        case 'pinterest':
          return 'image' in post
      }
    },
    getPostImage(network, post) {
      switch (network) {
        case 'instagram':
          return post.media_url
        case 'facebook':
          return post.full_picture
        case 'linkedin':
          return post.image
        case 'twitter':
          return post.media_url
        case 'pinterest':
          return post.image
      }
    },
    getPostName(network, post) {
      switch (network) {
        case 'instagram':
          return post.name
        case 'facebook':
          return post.page_name
        case 'linkedin':
          if (this.findLinkedinAccount(post.linkedin_id)) {
            return this.findLinkedinAccount(post.linkedin_id).name
          }
          return ''
        case 'twitter':
          return post.name
        case 'pinterest':
          if (this.findPinteresrBoard(post.board_id)) {
            return this.findPinteresrBoard(post.board_id).name
          }
      }
    },
    getPostUsername(network, post) {
      switch (network) {
        case 'instagram':
          return '@' + post.username
        case 'facebook':
          return post.name
        case 'linkedin':
          return post.user_name
        case 'twitter':
          return '@' + post.screen_name
        case 'pinterest':
          return '@' + post.screen_name
      }
    },
    isPostLikesAvailable(network) {
      switch (network) {
        case 'twitter':
        case 'instagram':
        case 'facebook':
        case 'linkedin':
          return true
        case 'pinterest':
          return false
      }
    },
    getPostLikes(network, post) {
      switch (network) {
        case 'instagram':
          return post.like_count ? post.like_count : 0
        case 'facebook':
          return post.total ? post.total : 0
        case 'linkedin':
          return post.favorites ? post.favorites : 0
        case 'twitter':
          return post.favorite_count ? post.favorite_count : 0
      }
    },
    getPostLikesText(network) {
      switch (network) {
        case 'instagram':
          return 'Likes'
        case 'facebook':
          return 'Likes'
        case 'linkedin':
          return 'Favorites'
        case 'twitter':
          return 'Favorites'
      }
    },
    getPostEngagement(network, post) {
      switch (network) {
        case 'instagram':
        case 'pinterest':
          return post.engagement ? post.engagement : 0
        case 'facebook':
          return post.total_engagement ? post.total_engagement : 0
        case 'linkedin':
          return post.total_engagement ? post.total_engagement : 0
        case 'twitter':
          return post.total_engagement ? post.total_engagement : 0
      }
    },
    isPostCommentsAvailable(network) {
      switch (network) {
        case 'twitter':
        case 'pinterest':
          return false
        case 'instagram':
        case 'facebook':
        case 'linkedin':
          return true
      }
      return network !== 'twitter'
    },
    getPostComments(network, post) {
      switch (network) {
        case 'instagram':
          return post.comments_count ? post.comments_count : 0
        case 'facebook':
          return post.comments ? post.comments : 0
        case 'linkedin':
          return post.comments ? post.comments : 0
        case 'twitter':
          return 0
      }
    },
    isPostSharesAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'twitter':
        case 'pinterest':
          return true
        case 'instagram':
        case 'linkedin':
          return false
      }
    },
    getPostShares(network, post) {
      switch (network) {
        case 'instagram':
          return 0
        case 'facebook':
          return post.shares ? post.shares : 0
        case 'linkedin':
          return post.shares ? post.shares : 0
        case 'twitter':
          return post.retweet_count ? post.retweet_count : 0
        case 'pinterest':
          return post.repins_count ? post.repins_count : 0
      }
    },
    getPostSharesText(network) {
      switch (network) {
        case 'facebook':
          return 'Shares'
        case 'linkedin':
          return 'Shares'
        case 'twitter':
          return 'Retweet Count'
        case 'pinterest':
          return 'Repins Count'
      }
    },
    isPostTextAvailable(network, post) {
      switch (network) {
        case 'instagram':
          return 'caption' in post
        case 'facebook':
          return 'message' in post
        case 'linkedin':
          return 'title' in post
        case 'twitter':
          return 'tweet_text' in post
        case 'pinterest':
          return 'pin_text' in post
      }
    },
    getPostText(network, post) {
      switch (network) {
        case 'instagram':
          return post.caption
        case 'facebook':
          return post.message
        case 'linkedin':
          return post.title
        case 'pinterest':
          return post.pin_text
        case 'twitter':
          return post.tweet_text
      }
    },
    isPostImpressionsAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPostImpressions(network, post) {
      switch (network) {
        case 'facebook':
          return 'post_impressions' in post ? post.post_impressions : 0
        case 'pinterest':
          return 'impressions' in post ? post.impressions : 0
      }
    },
    isPinClickAvailable(network) {
      switch (network) {
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPinClick(network, post) {
      switch (network) {
        case 'pinterest':
          return 'pin_click' in post ? post.pin_click : 0
      }
    },
    isPostClicksAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPostClicks(network, post) {
      switch (network) {
        case 'facebook':
          return 'post_clicks' in post ? post.post_clicks : 0
        case 'pinterest':
          return 'pin_click' in post ? post.pin_click : 0
      }
    },
    getPostDate(network, post) {
      switch (network) {
        case 'instagram':
          return moment(post.post_created_at).format('llll')
        case 'facebook':
          return moment(post.created_time).format('llll')
        case 'linkedin':
          return moment(post.created_at).format('llll')
        case 'twitter':
          return moment(post.created_at).format('llll')
        case 'pinterest':
          return moment(post.created_at).format('llll')
      }
    },
    formatDate(date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).format('llll')
    },
    getIconClass(accountType) {
      switch (accountType) {
        case 'instagram':
          return 'fab fa-instagram ins ins_bg'
        case 'facebook':
          return 'fab fa-facebook-f fb fb_bg'
        case 'twitter':
          return 'fab fa-twitter tw tw_bg'
        case 'linkedin':
          return 'fab fa-linkedin-in lin lin_bg'
        case 'pinterest':
          return 'fab fa-pinterest-in pin pin_bg'
      }
    },
    getBorderClass(accountType) {
      switch (accountType) {
        case 'instagram':
          return 'ins-border'
        case 'facebook':
          return 'fb-border'
        case 'twitter':
          return 'tw-border'
        case 'linkedin':
          return 'lin-border'
        case 'pinterest':
          return 'pin-border'
      }
    },
    getEngagementField(network) {
      switch (network) {
        case 'instagram':
          return 'engagement'
        case 'facebook':
          return 'total_engagement'
        case 'linkedin':
          return 'total_engagement'
        case 'twitter':
          return 'total_engagement'
        case 'pinterest':
          return 'engagement'
      }
    },
    getLikeField(network) {
      switch (network) {
        case 'instagram':
          return 'like_count'
        case 'facebook':
          return 'total'
        case 'linkedin':
          return 'favorites'
        case 'twitter':
          return 'favorite_count'
      }
    },
    isVideo(network, post) {
      switch (network) {
        case 'instagram':
          return post.media_type === 'VIDEO'
        case 'facebook':
          return false
        case 'linkedin':
          return false
        case 'twitter':
          return false
      }
    },
    getCommentField(network) {
      switch (network) {
        case 'instagram':
          return 'comments_count'
        case 'facebook':
          return 'comments'
        case 'linkedin':
          return 'comments'
        case 'twitter':
          return false
      }
    },
    getSharesField(network) {
      switch (network) {
        case 'instagram':
          return false
        case 'facebook':
          return 'shares'
        case 'linkedin':
          return 'shares'
        case 'twitter':
          return 'shares'
        case 'pinterest':
          return 'repins_count'
      }
    },

    selectAllAccounts() {
      const platforms = ['facebook', 'instagram', 'twitter', 'linkedin']
      let accountsList = []
      for (var platform in platforms) {
        accountsList = accountsList.concat(
          this.getPlatformAccounts(platforms[platform])
        )
      }
      return accountsList
    },
    getPlatformAccounts(platform) {
      const accountsList = []
      switch (platform) {
        case 'facebook':
          this.getFacebookPagesList.forEach((item) => {
            item.account_type = 'facebook'
            accountsList.push(item)
          })
          break
        case 'twitter':
          this.getTwitterAccounts.items.forEach((item) => {
            item.account_type = 'twitter'
            accountsList.push(item)
          })
          break
        case 'linkedin':
          this.getLinkedinAccounts.items.forEach((item) => {
            item.account_type = 'linkedin'
            accountsList.push(item)
          })
          break
        case 'instagram':
          this.getInstagramAccounts.items.forEach((item) => {
            item.account_type = 'instagram'
            accountsList.push(item)
          })
          break
        case 'pinterest':
          this.getPinterestBoards.forEach((item) => {
            item.account_type = 'pinterest'
            accountsList.push(item)
          })
          break
      }

      return accountsList
    },
    getPreview(network) {
      switch (network) {
        case 'instagram':
          return 'instagram-posts-analytics-preview'
        case 'facebook':
          return 'facebook-analytics-preview'
        case 'linkedin':
          return 'linkedin-analytics-preview'
        case 'twitter':
          return 'twitter-analytics-preview'
        case 'pinterest':
          return 'pinterest-analytics-preview'
      }
    },
    getPreviewLink(network, post) {
      switch (network) {
        case 'instagram':
          return post.permalink
        case 'facebook':
          return post.link
        case 'linkedin':
          return 'https://www.linkedin.com/feed/update/' + post.activity
        case 'pinterest':
          return 'https://www.pinterest.com/pin/' + post.pin_id
        case 'twitter':
          return (
            'https://twitter.com/' +
            post.screen_name +
            '/status/' +
            post.tweet_id
          )
      }
    },
    timesAgo(date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).fromNow()
    },
    hourDifference(date) {
      return Math.round(moment().diff(date) / 36e5)
    },
    getReachCurrentPrevious(currentVal, prevVal) {
      let current = currentVal
      if (currentVal) {
        current = currentVal
      }

      let previousValue = 0
      if (prevVal) {
        previousValue = prevVal
      }

      const previous =
        previousValue === 0 || !previousValue ? current : previousValue
      const value = (((current - previous) / previous) * 100).toFixed(2)
      return value === 'NaN' || value === '0.00' ? 0 : value
    },
    getReachUpCurrentPrevious(currentVal, prevVal) {
      return this.getReachCurrentPrevious(currentVal, prevVal) > 0
    },
    getReach(type, section) {
      let current = 0
      if (section.current && section.current[type]) {
        current = isNaN(section.current[type])
          ? section.current[type].value
          : section.current[type]
      }

      let previousValue = 0
      if (section.previous && section.previous[type]) {
        previousValue = isNaN(section.previous[type])
          ? section.previous[type].value
          : section.previous[type]
      }

      const previous =
        previousValue === 0 || !previousValue ? current : previousValue
      const value = (((current - previous) / previous) * 100).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReachUp(type, section) {
      return this.getReach(type, section) > 0
    },
    sortList(targetObject) {
      if (targetObject) {
        return Object.entries(targetObject)
          .sort(([, v1], [, v2]) => +v2 - +v1)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      }
    },
  },
}
