<template>
  <div>
    <div
      class="comment_item"
      v-for="(item, index) in getFilteredCommentsList"
      v-bind:key="index"
    >
      <div
        class="comment_item_inner"
        :class="{ is_note_comment: item.is_note }"
      >
        <div class="top_tool">
          <i
            v-tooltip.top-center="'Edit'"
            class="edit_icon icon-edit-cs"
            @click="allowToUpdateComment(item)"
          ></i>
          <i
            v-tooltip.top-center="'Remove'"
            class="d-inline-block icon-delete-cs remove_icon"
            @click="deleteCommentAction(item, index)"
          ></i>
        </div>

        <div class="profile_picture d-flex align-items-start">
          <div class="picture_block mt-1 picture_block_background">
            <div
              v-if="item.user && item.user.image"
              class="img"
              :style="{ background: 'url(' + item.user.image + ')' }"
            ></div>
            <div
              v-else
              class="img"
              style="
                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
              "
            ></div>
          </div>
          <div class="text_block w-100">
            <p class="text">
              <template v-if="item.user">
                <div class="comment_truncate" v-tooltip="item.user.full_name">
                  {{ item.user.full_name }}
                </div>
              </template>
              <template v-else>
                <template v-if="item.user_id === getProfile._id">
                  <div
                    class="comment_truncate"
                    v-tooltip="getProfile.full_name"
                  >
                    {{ getProfile.full_name }}
                  </div>
                </template>
                <template v-else> Team Member </template>
              </template>
              <span class="time" v-tooltip="item.created_at">{{
                $filters.relative(item.created_at)
              }}</span></p
            >
            <p class="sub_text" v-if="!item.can_update_comment">
              {{ item.comment }}</p
            >

            <!-- update existing comment block -->

            <div
              class="comment_block basic_form p-0 updating-comment"
              v-if="item.can_update_comment"
            >
              <div class="edit_input">
                <div class="profile_picture">
                  <div class="text_block ml-0">
                    <div class="atwho_dropdown mention-feed-dropdown">
                      <at-ta
                        :members="getActiveWorkspaceMembersDetails"
                        name-key="name"
                      >
                        <template slot="item" slot-scope="member">
                          <div class="mention-dropdown-li profile_picture">
                            <div class="picture_block picture_block_background">
                              <div
                                v-if="member.item.image"
                                class="img border-0"
                                :style="{
                                  background: 'url(' + member.item.image + ')',
                                }"
                              ></div>
                              <div
                                v-else
                                class="img"
                                style="
                                  background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                "
                              ></div>
                            </div>
                            <div class="text_block ml-2 w-auto">
                              <span class="text">{{ member.item.name }}</span>
                              <span
                                class="userrole"
                                :class="getRoleClass(member.item.role)"
                                >{{
                                  getMutatedUserRole(member.item.role)
                                }}</span
                              >
                            </div>
                          </div>
                        </template>
                        <!--Added CTRL+Enter-->
                        <textarea
                          ref="editAt"
                          placeholder="Write a comment..."
                          id="comment-box-edit"
                          v-model="item.new_comment"
                          @keyup.ctrl.enter="updateCommentAction(item)"
                          spellcheck="false"
                        ></textarea>
                        <div
                          class="attachment-icon d-flex justify-content-center align-items-center"
                        >
                          <Emoji type="comment-edit" />
                          <label
                            :for="'feed-file-input' + item._id"
                            class="mb-0"
                            ><i class="far fa-paperclip mb-0"></i
                          ></label>
                          <input
                            accept="image/*"
                            @change.prevent="
                              uploadExistingCommentImage($event, item)
                            "
                            type="file"
                            :id="'feed-file-input' + item._id"
                            class="d-none"
                          />
                          <i
                            class="far fa-at"
                            @click="triggerAtExistingComment"
                          ></i>
                        </div>
                      </at-ta>
                    </div>
                    <!--</div>-->
                    <div class="btn_block">
                      <button
                        @click="updateCommentAction(item)"
                        class="btn gradient_btn"
                      >
                        <span class=" ">Update</span>
                      </button>
                      <button
                        class="btn dark_gray ml-2"
                        @click="disallowToUpdateComment(item)"
                      >
                        <span class=" ">Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- attached media files -->

            <div class="attachment-files">
              <template v-if="file_uploading">
                <beat-loader :size="'10px'" :color="'#4165ed'"></beat-loader>
              </template>
              <div
                v-tooltip="media.name"
                class="img"
                v-for="(media, index) in item.media"
                :style="{ background: 'url(' + media.link + ')' }"
              >
                <i
                  class="fal fa-times cross_icon"
                  @click="item.media.splice(index, 1)"
                  v-if="item.can_update_comment"
                ></i>
                <div class="image_tool">
                  <i
                    @click="previewCommentImage(media.link)"
                    class="cs-eye image_tool_item"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="more-comment"
      v-if="comments && comments.length > 1 && !show_all_comments"
    >
      <p @click="show_all_comments = true"
        >({{ comments.length - 1 }}) Show More
        <template v-if="comments.length - 1 === 1">comment</template>
        <template v-else>comments</template>
      </p>
    </div>

    <div
      class="more-comment hide-comment"
      v-if="comments && comments.length > 1 && show_all_comments"
    >
      <p @click="show_all_comments = false"> Show Less Comments </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import AtTa from 'vue-at/dist/vue-at-textarea'
import At from 'vue-at'
import Emoji from '../../../../publish/components/posting/social/emoji/Emoji'

export default {
  components: {
    AtTa,
    At,
    Emoji
  },
  data () {
    return {
      show_all_comments: false,
      file_uploading: false
    }
  },
  props: {
    comments: {},
    plan_id: {}
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getActiveWorkspace',
      'getActiveWorkspaceMembersDetails'
    ]),
    getFilteredCommentsList () {
      if (this.comments && this.comments.length > 0) {
        return this.show_all_comments ? this.comments : [this.comments[0]]
      }
    }
  },
  methods: {
    allowToUpdateComment (item) {
      this.$set(item, 'new_comment', item.comment)
      this.$set(item, 'can_update_comment', true)
    },
    disallowToUpdateComment (item) {
      this.$set(item, 'can_update_comment', false)
    },

    triggerAtExistingComment () {
      const { editAt } = this.$refs
      if (editAt && editAt.focus) {
        editAt.focus()
        document.execCommand('insertText', 0, ' @')
        const e = document.createEvent('HTMLEvents')
        e.initEvent('input', true, true)
        editAt.dispatchEvent(e)
      }
    },
    async uploadExistingCommentImage (event, item) {
      this.file_uploading = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.file_uploading = false
      if (res) {
        if (res.data.status) {
          item.media.push(res.data.media)
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    },

    async updateCommentAction (item) {
      const payload = {
        workspace_id: item.workspace_id,
        plan_id: this.plan_id,
        comment: item.new_comment,
        mentioned_user: this.$parent.mentionedUserIdsList(item.new_comment),
        type: this.$route.name,
        title: item.title || null,
        is_note: item.is_note ? item.is_note : false,
        media: item.media ? item.media : [],
        id: item._id
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)

      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          item.new_comment = ''
          item.can_update_comment = false
          item.comment = res.data.comment.comment
          // this.dispatchCommentSocket({ 'text': res.data.comment.comment, 'id': item._id }, this.plan_id, 'update')
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    },
    /**
     *
     * @param comment {Object} - Comment object.
     * @param index {Integer} - Index no used for removing the item from the list.
     * @returns {Promise<void>}
     */
    async deleteCommentAction (comment, index) {
      const res = await this.$store.dispatch('deleteFeedViewComment', {
        id: comment._id,
        workspace_id: comment.workspace_id,
        plan_id: comment.plan_id
      })
      // once removed, we get the response
      if (res && res.data.status) {
        const findCommentIndex = this.comments.findIndex(
          (commentItem) => commentItem._id === comment._id
        )
        if (findCommentIndex >= 0) {
          this.comments.splice(findCommentIndex, 1)
        }

        // this.dispatchCommentSocket({ 'id': comment._id }, comment.plan_id, 'delete')
        return
      }
      // show the toast message or notification

      const message = res && !res.data.status ? res.data.message : UNKNOWN_ERROR
      this.$store.dispatch('toastNotification', {
        message: message,
        type: 'error'
      })
    }
  }
}
</script>
<style lang="less">
.is_note_comment {
  background: rgba(255, 175, 46, 0.15) !important;
}
.comment_truncate {
  max-width: 10.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
</style>
