import moment from 'moment'
import { blogPlatformNames, socialPlatformNames } from './integrations'
import { decodeTextURI } from './helper'

function getStatusClass(item) {
  if (item.status === 'review') {
    if (item.execution_time.date < moment.utc().format('YYYY-MM-DD HH:mm:ss')) {
      return 'missedReview'
    }
    return 'reviewed'
  }

  return item.status
}

function planTypeText(item, type) {
  const text = ``
  if (item.post && !item.account_selection)
    return decodeTextURI(item.post.title)
  if (item.common_box_status) {
    if (item.common_sharing_details) {
      if (item.common_sharing_details.message)
        return decodeTextURI(item.common_sharing_details.message)
      if (item.common_sharing_details.description)
        return decodeTextURI(item.common_sharing_details.description)
      // if (
      //   item.common_sharing_details.video &&
      //   item.common_sharing_details.video.link
      // )
      //   return decodeTextURI(item.common_sharing_details.video.link)
      // if (
      //   item.common_sharing_details.image &&
      //   item.common_sharing_details.image.length
      // )
      //   return decodeTextURI(item.common_sharing_details.image[0])
    }
    return text
  }

  if (item[type + '_sharing_details']) {
    if (item[type + '_sharing_details'].message)
      return decodeTextURI(item[type + '_sharing_details'].message)
    if (item[type + '_sharing_details'].description)
      return decodeTextURI(item[type + '_sharing_details'].description)
    else return text
  }
  if (item.retweet_selection) return item.retweet_selection.message
  return text
}

function planHeadText(item, type) {
  console.debug('planHeadText', item, type)
  let text = ''
  if (type === 'blog' && item.blog_selection) {
    text = planTypeText(item)
    return text || ``
  }
  if (item.account_selection) {
    // eslint-disable-next-line camelcase
    const socialPlatforms = socialPlatformNames()
    socialPlatforms.forEach(function (platform) {
      if (
        !text &&
        item.account_selection[platform] &&
        item.account_selection[platform].length > 0
      ) {
        text = planTypeText(item, platform)
        return false
      }
    })

    if (!text) {
      socialPlatforms.forEach(function (platform) {
        if (!text) text = planTypeText(item, platform)
      })
    }
  }

  return text || ``
}

function planTypeAttachment(item, type) {
  const attachment = false
  if (item.post && !item.account_selection) {
    return item.post.image && item.post.image.link
      ? { type: 'image', url: item.post.image.link }
      : attachment
  }

  if (
    type === 'facebook' &&
    item.carousel_options?.is_carousel_post &&
    item.carousel_options?.cards?.length
  ) {
    return {
      type: 'image',
      url: item.carousel_options?.cards[0]?.picture,
    }
  }

  if (item.common_box_status) {
    if (item.common_sharing_details) {
      if (
        item.common_sharing_details.video &&
        item.common_sharing_details.video.link
      ) {
        return {
          type: 'video',
          url: item.common_sharing_details.video.link,
          thumbnail: item.common_sharing_details.video.thumbnail,
        }
      }
      if (
        item.common_sharing_details.image &&
        item.common_sharing_details.image.length
      ) {
        return {
          type: 'image',
          url: item.common_sharing_details.image[0],
        }
      }
    }
    return attachment
  }
  if (item[type + '_sharing_details']) {
    if (
      item[type + '_sharing_details'].video &&
      item[type + '_sharing_details'].video.link
    ) {
      return {
        type: 'video',
        url: item[type + '_sharing_details'].video.link,
        thumbnail: item[type + '_sharing_details'].video.thumbnail,
      }
    }
    if (
      item[type + '_sharing_details'].image &&
      item[type + '_sharing_details'].image.length
    ) {
      // using first image if item contains multiple images if not then using the simple string value
      return {
        type: 'image',
        url:
          item[type + '_sharing_details'].image.constructor === Array
            ? item[type + '_sharing_details'].image[0]
            : item[type + '_sharing_details'].image,
      }
    }
  }
  return attachment
}

function planHeadAttachment(item) {
  let attachment = false
  if (item.blog_selection) return planTypeAttachment(item)
  if (item.account_selection) {
    const socialPlatformsList = socialPlatformNames()
    socialPlatformsList.forEach(function (platform) {
      if (
        !attachment &&
        item.account_selection[platform] &&
        item.account_selection[platform].length > 0
      )
        attachment = planTypeAttachment(item, platform)
    })

    if (!attachment) {
      socialPlatformsList.forEach(function (platform) {
        if (!attachment) attachment = planTypeAttachment(item, platform)
      })
    }
  }
  return attachment
}

/**
 * Checks the item type and plucks out the all the media files from a social post(item)
 * @param item
 * @returns {boolean|{type: string, url: *}|boolean|{thumbnail: *, type: string, url: *}|{type: string, url: *}|{thumbnail: *, type: string, url: *}|{type: string, url: *}|*[]|*|{thumbnail, type: string, url}|{thumbnail: string, type: string, url: string}|[]}
 */
function allPlanHeadAttachments(item) {
  // if item is a carousel post.
  if (item.carousel_options?.is_carousel_post) {
    return item.carousel_options.cards.map((item) => item.picture)
  }

  // if item is a blog post.
  if (item.blog_selection) return planTypeAttachment(item).url

  // if item is a social post with common box
  if (item.account_selection && item.common_box_status) {
    if (item.common_sharing_details?.video?.link) {
      return {
        type: 'video',
        url: item.common_sharing_details.video.link,
        thumbnail: item.common_sharing_details.video.thumbnail,
      }
    }
    if (item.common_sharing_details?.image?.length) {
      return item.common_sharing_details.image
    }
  } else if (item.account_selection && !item.common_box_status) {
    // if item is a social post without common box
    // in case of non common box post type we are showing the media type just for facebook (Questionable!).
    if (item.facebook_sharing_details?.video?.link) {
      return {
        type: 'video',
        url: item.facebook_sharing_details.video.link,
        thumbnail: item.facebook_sharing_details.video.thumbnail,
      }
    }
    if (item.facebook_sharing_details?.image?.length) {
      return item.facebook_sharing_details.image
    }
  }

  return false
}

function planTypeMedia(item, type) {
  const attachment = false
  // checking for the blog media
  if (item.post && !item.account_selection)
    return item.post.image && item.post.image.link
      ? { type: 'image', media: [item.post.image.link] }
      : attachment
  if (item.common_box_status) {
    if (item.common_sharing_details) {
      if (
        item.common_sharing_details.video &&
        item.common_sharing_details.video.link
      )
        return { type: 'video', media: item.common_sharing_details.video.link }
      if (
        item.common_sharing_details.image &&
        item.common_sharing_details.image.length
      )
        return {
          type: 'image',
          media:
            item.common_sharing_details.image.constructor === Array
              ? item.common_sharing_details.image
              : [item.common_sharing_details.image],
        }
    }
    return attachment
  }
  if (item[`${type}_sharing_details`]) {
    if (
      item[`${type}_sharing_details`].video &&
      item[`${type}_sharing_details`].video.link
    )
      return {
        type: 'video',
        media: item[`${type}_sharing_details`].video.link,
      }
    if (
      item[`${type}_sharing_details`].image &&
      item[`${type}_sharing_details`].image.length
    )
      return {
        type: 'image',
        media:
          item[`${type}_sharing_details`].image.constructor === Array
            ? item[`${type}_sharing_details`].image
            : [item[`${type}_sharing_details`].image],
      }
  }
  return attachment
}

// use for displaying files
function getPlanMedia(item) {
  let attachment = false

  // checking for the blog media
  if (item.blog_selection) return planTypeMedia(item)
  if (item.account_selection) {
    // checking base on account selection
    const socialPlatformsList = socialPlatformNames()
    socialPlatformsList.forEach(function (platform) {
      if (
        !attachment &&
        item.account_selection[platform] &&
        item.account_selection[platform].length > 0
      )
        attachment = planTypeMedia(item, platform)
    })

    // checking without account selection
    if (!attachment) {
      socialPlatformsList.forEach(function (platform) {
        if (!attachment) attachment = planTypeMedia(item, platform)
      })
    }
  }
  return attachment
}

/**
 * checking the posted document for a specific account
 * @param item
 * @param type
 * @param platform
 * @returns {boolean}
 */
function getExternalPosting(item, platform) {
  let posting = false
  if (item.external_integrations) {
    item.external_integrations.forEach(function (element) {
      if (element.platform_id === platform) {
        posting = element
        return false
      }
    })
  }
  return posting
}

function zapierPostingError(item, type, platform) {
  const posting = platformPosting(item, type, platform)
  if (posting) {
    if (posting.zapier_error_message)
      return (
        '<span class="status failed"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Failed</span><span> [' +
        posting.zapier_error_message +
        ']</span><span> | <a class="resend-notification" href="https://docs.contentstudio.io/article/693-instagram-errors-while-publishing" target="_blank">Learn More Here</a></span>'
      )
  }
  return ''
}

function platformPosting(item, type, platform) {
  let posting = false
  if (item.posting) {
    switch (type) {
      case 'wordpress':
      case 'tumblr_blog':
      case 'medium':
        item.posting.forEach(function (element) {
          if (
            element.platform_type === 'Tumblr' ||
            element.platform_type ===
              type.charAt(0).toUpperCase() + type.slice(1)
          ) {
            posting = element
            return false
          }
        })
        break
      default:
        item.posting.forEach(function (element) {
          if (element.platform_id === platform) {
            posting = element
            return false
          }
        })
        break
    }

    if (!posting) posting = item.posting[0]
  }

  return posting
}
function platformCommentPostingStatus(item, type, platform) {
  const posting = platformPosting(item, type, platform)
  if (posting.first_comment) {
    if (posting.first_comment.status) {
      return (
        '<span class="status published"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Published</span><span> | [' +
        posting.first_comment.message +
        ']</span>'
      )
    }
    const firstCommentMessage = posting.first_comment.message
      ? posting.first_comment.message
      : 'Something went wrong while processing post.'
    return (
      '<span class="status failed"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">Failed</span><span> [' +
      firstCommentMessage +
      ']</span>'
    )
  }
}
function platformPostingStatus(item, type, platform) {
  let response = ''

  if (item.status === 'draft')
    return (
      '<span class="status bg-status-draft"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">Draft</span>'
    )
  if (item.status === 'processing')
    return (
      '<span class="status bg-status-in_progress"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">In Progress</span>'
    )
  if (item.status === 'scheduled')
    return (
      '<span class="status bg-status-scheduled"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">Scheduled</span>'
    )
  if (item.status === 'rejected')
    return (
      '<span class="status bg-status-rejected"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">Rejected</span>'
    )
  if (
    item.status === 'review' &&
    item.execution_time.date < moment.utc().format('YYYY-MM-DD HH:mm:ss')
  )
    return (
      '<span class="status bg-status-missed_review"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">Missed Review</span>'
    )
  if (item.status === 'review')
    return (
      '<span class="status bg-status-under_review"></span><span class="text" data-cy="plan_id_' +
      platform +
      '_status">In Review</span>'
    )
  const posting = platformPosting(item, type, platform)
  if (posting) {
    if (posting.link)
      return (
        '<span class="status bg-status-published"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Published</span><span> | <a class="resend-notification" href="' +
        posting.link +
        '" target="_blank">Live Link</a></span>'
      )
    if (posting.retrying) {
      return (
        '<span class="status bg-status-in_progress"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">In Progress</span>'
      )
    }
    if (posting.error) {
      let message = 'Something went wrong while processing post.'
      if (posting.error_message) message = posting.error_message
      response =
        '<span class="status bg-status-failed"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Failed</span><span> [' +
        message +
        ']</span>'
      let link = ''
      switch (type) {
        case 'facebook':
          link = 'https://docs.contentstudio.io/article/688-facebook-errors'
          break
        case 'twitter':
          link =
            'https://docs.contentstudio.io/article/689-twitter-errors-while-publishing'
          break
        case 'linkedin':
          link =
            'https://docs.contentstudio.io/article/499-linkedin-post-failed-to-publish'
          break
        case 'pinterest':
          link =
            'https://docs.contentstudio.io/article/497-pinterest-post-failed-to-publish'
          break
        case 'instagram':
          link =
            'https://docs.contentstudio.io/article/693-instagram-errors-while-publishing'
          break
        case 'gmb':
          link =
            'https://docs.contentstudio.io/article/575-troubleshooting-google-my-business'
          break
        case 'tiktok':
          link =
            'https://docs.contentstudio.io/article/923-how-to-post-to-tiktok-using-content-studio'
          break
      }
      if (link)
        response +=
          '<span> | <a class="resend-notification" href="' +
          link +
          '" target="_blank">Learn More Here</a></span>'
      return response
    }

    // checking posting status for Zapier
    const zapier = getExternalPosting(item, platform)
    if (zapier) {
      // if published
      if (zapier.can_post && zapier.posted)
        return (
          '<span class="status bg-status-published"></span><span class="text" data-cy="plan_id_' +
          platform +
          '_status">Published (Zapier)</span>'
        )

      // if pending from Zapier
      if (zapier.can_post && !zapier.posted)
        return (
          '<span class="status bg-status-scheduled"></span><span class="text" data-cy="plan_id_' +
          platform +
          '_status">Scheduled</span><span> [Pending execution from Zapier]</span>'
        )
    }

    if (posting.notification_processed)
      return (
        '<span class="status bg-status-published"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Published</span>'
      )
    if (typeof posting.device_notification !== 'undefined') {
      if (posting.device_notification) {
        return (
          '<span class="status bg-status-published"></span><span class="text" data-cy="plan_id_' +
          platform +
          '_status">Published</span><span> [Push notification/reminder sent to your mobile device.]</span>'
        )
      }
      return (
        '<span class="status bg-status-failed"></span><span class="text" data-cy="plan_id_' +
        platform +
        '_status">Failed</span><span> [Failed to send push notification/reminder to your mobile device.]</span><span> | <a class="resend-notification" href="https://docs.contentstudio.io/article/693-instagram-errors-while-publishing" target="_blank">Learn More</a></span>'
      )
    }
  }
  return response
}

function platformPostingStats(item, type, platform) {
  const stats = {
    status: false,
    likes: 0,
    comments: 0,
    shares: 0,
    retweets: 0,
    repins: 0,
  }
  if (type === 'all') {
    stats.status = true
    if (item.likes_count) stats.likes += item.likes_count
    if (item.favorite_count) stats.likes += item.favorite_count
    if (item.shares_count) stats.shares += item.shares_count
    if (item.retweet_count) stats.shares += item.retweet_count
    if (item.comments_count) stats.comments += item.comments_count
    return stats
  }
  const posting = platformPosting(item, type, platform)
  if (posting && (posting.link || posting.notification_processed)) {
    stats.status = true
    if (posting.likes_count) stats.likes += posting.likes_count
    if (posting.favorite_count) stats.likes += posting.favorite_count
    if (posting.shares_count) stats.shares += posting.shares_count
    if (posting.retweet_count) stats.retweets += posting.retweet_count
    if (posting.repins_count) stats.repins += posting.repins_count
    if (posting.comments_count) stats.comments += posting.comments_count
  }

  return stats
}

function itemSelectedPlatformsCount(item) {
  let count = 0
  // eslint-disable-next-line camelcase
  if (item.blog_selection) {
    const blogPlatforms = blogPlatformNames()
    blogPlatforms.forEach(function (platform) {
      if (
        item.blog_selection[platform] &&
        item.blog_selection[platform].website
      ) {
        count += 1
      }
    })
  }

  if (item.account_selection) {
    const socialPlatforms = socialPlatformNames()
    socialPlatforms.forEach(function (platform) {
      if (item.account_selection[platform]) {
        count += item.account_selection[platform].length
      }
    })
  }
  return count
}

function itemSelectedPlatformVisibleStatus(item, account) {
  let count = 0
  let status = false

  if (item.blog_selection) {
    const blogPlatforms = blogPlatformNames()
    blogPlatforms.forEach(function (platform) {
      if (
        item.blog_selection[platform] &&
        item.blog_selection[platform].website
      ) {
        count += 1
      }
    })
  }

  if (item.account_selection) {
    const socialPlatforms = socialPlatformNames()
    socialPlatforms.forEach(function (platform) {
      if (!status) {
        if (item.account_selection[platform]) {
          item.account_selection[platform].forEach(function (element) {
            count += 1
            if (
              (element.platform_identifier === account ||
                element[platform + '_id'] === account ||
                element.name === account ||
                element.board_id === account) &&
              count < 4
            ) {
              status = true
              return false
            }
          })
        }
      }
      return false
    })
  }

  return status
}
// function itemSelectedPlatformVisibleStatusPopOver(item, account) {
//   let count = 0
//   let status = false
//
//   if (item.blog_selection) {
//     const blog_platforms = blogPlatformNames()
//     blog_platforms.forEach(function (platform) {
//       if (
//         item.blog_selection[platform] &&
//         item.blog_selection[platform].website
//       ) {
//         count += 1
//       }
//     })
//   }
//
//   if (item.account_selection) {
//     const social_platforms = socialPlatformNames()
//     social_platforms.forEach(function (platform) {
//       if (!status) {
//         if (item.account_selection[platform]) {
//           item.account_selection[platform].forEach(function (element) {
//             count += 1
//             if (
//               (element.platform_identifier === account ||
//                 element[platform + '_id'] === account ||
//                 element.name === account ||
//                 element.board_id === account) &&
//               count > 10
//             ) {
//               status = true
//               return false
//             }
//           })
//         }
//       }
//       return false
//     })
//   }
//
//   return status
// }
export {
  getStatusClass,
  planTypeText,
  planHeadText,
  planTypeAttachment,
  planHeadAttachment,
  getPlanMedia,
  platformPosting,
  zapierPostingError,
  platformPostingStatus,
  platformCommentPostingStatus,
  platformPostingStats,
  itemSelectedPlatformsCount,
  itemSelectedPlatformVisibleStatus,
  allPlanHeadAttachments,
}
