<template>
  <div class="sep_line conversation_notification">
    <div class="text">
      <div class="user_block d-flex align-items-center">
        <div class="user_picture">
          <div
            v-if="
              message.event_details.user_image == '' ||
              message.event_details.user_image == null
            "
            class="img"
            :style="'background: url(' + commonMethods.getDefaultImage() + ')'"
          ></div>
          <div
            v-else
            class="img"
            v-bind:style="
              'background: url(' + message.event_details.user_image + ')'
            "
          ></div>
        </div>
        <div class="user_content">
          <p class="name"
            ><strong>{{ message.event_details.user_name }}</strong
            >{{ message.message
            }}<strong>{{
              message.event_type === 'assigned'
                ? message.assigned_to !== null
                  ? message.assigned_to.user_name + '.'
                  : ''
                : ''
            }}</strong>
            <span style="font-size: 12px">
              - {{ commonMethods.timesAgo(message.created_time) }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
export default {
  props: ['message'],
  components: {},
  data () {
    return {
      commonMethods: commonMethods
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  methods: {
    ...mapActions([])
  },
  watch: {}
}
</script>

<style scoped></style>
