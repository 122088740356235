import moment from 'moment'

function timePassed(time) {
  return moment(time).isBefore(moment())
}

function getWorkspaceTimeZoneTime(date, timezone, format) {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').tz(timezone).format(format)
}

function getDateTimeFormat(dateTime, format) {
  return moment(dateTime).format(format)
}

export { timePassed, getWorkspaceTimeZoneTime, getDateTimeFormat }
