<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      change_notification_devices_loader: false
    }
  },
  computed: {
    ...mapGetters(['getMobileDevices', 'getInstagramNotificationDevices']),
    changeNotificationDevicesLoaderStatus () {
      return this.change_notification_devices_loader
    },
    showAccountNotificationDevices () {
      return this.getInstagramNotificationDevices._id
    },

    mobileDevicesExists () {
      return this.getMobileDevices.length
    },

    showActionButton () {
      return this.showAccountNotificationDevices && this.mobileDevicesExists
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('notification_devices')
    },
    deviceModel (device) {
      return device.device_model
    },

    deviceStatusCode (device) {
      return device.hasOwnProperty('status_code')
    },

    isDeviceDisabled (device) {
      return !!(
        this.getInstagramNotificationDevices.disabled_devices.includes(
          device.device_id
        ) === true
      )
    },

    deviceIdentifier (key, device) {
      return key + device
    },

    changeDeviceNotificationStatus (device, type) {
      console.debug('Method::changeDeviceNotificationStatus', device, type)

      const stateObject = this
      if (type === 'Enable') {
        this.getInstagramNotificationDevices.disabled_devices.forEach(function (
          element,
          index
        ) {
          if (element === device) {
            stateObject.getInstagramNotificationDevices.disabled_devices.splice(
              index,
              1
            )
            return false
          }
        })
      } else {
        if (
          this.getInstagramNotificationDevices.disabled_devices.includes(
            device
          ) === false
        ) {
          this.getInstagramNotificationDevices.disabled_devices.push(device)
        }
      }
    },

    async changeInstagramNotificationDevices () {
      this.change_notification_devices_loader = true
      const response = await this.$store.dispatch(
        'changeInstagramNotificationDevices'
      )
      if (response) {
        this.$bvModal.hide('notification_devices')
      }
      this.change_notification_devices_loader = false
    },
    removeNotificationDevice (device_id) {
      console.debug('Method::removeNotificationDevice', device_id)
      this.$parent.device_id = device_id
      this.$bvModal.show('removeNotificationDevice')
    }
  }
}
</script>

<template>
  <b-modal
    modal-class="normal_modal add_devices_instagram"
    class=""
    id="notification_devices"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Mobile Notifications/Reminders</h2>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="hideModal()"
        >&times;</button
      >
    </div>

    <div class="modal_body basic_form">
      <div class="top_heading_text">
        <p
          >Following devices are connected to the selected Instagram account,
          you can enable or disable notification for any device from here.
        </p>
      </div>

      <div class="social_table" v-if="showAccountNotificationDevices">
        <div class="table_wrapper">
          <table class="table">
            <thead>
              <tr>
                <th>Device Name</th>
                <th style="text-align: center; width: 120px">Status</th>
                <th style="text-align: right; width: 100px">Actions</th>
              </tr>
            </thead>

            <tbody>
              <template v-if="mobileDevicesExists">
                <tr v-for="device in getMobileDevices">
                  <td>
                    <div class="profile_picture">
                      <div class="picture_block">
                        <i class="fa-mobile fa"></i>
                      </div>
                      <div class="text_block">
                        <p class="text"
                          >{{ device.device_name }}
                          <span v-if="deviceModel(device)"
                            >({{ deviceModel(device) }})</span
                          ></p
                        >
                        <P class="sub_text"
                          >Added
                          {{
                            getDateTimeFormat(device.created_at, 'Do MMMM YYYY')
                          }}</P
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="devices_dropdown dropdown default_style_dropdown checkbox_dropdown"
                    >
                      <div
                        class="dropdown_header d-flex align-items-center"
                        data-toggle="dropdown"
                      >
                        <span class="disable" v-if="deviceStatusCode(device)"
                          >Expired</span
                        >
                        <span
                          class="action_text disable"
                          v-else-if="isDeviceDisabled(device)"
                          >Disabled</span
                        >
                        <span class="action_text enable" v-else>Enabled</span>
                      </div>

                      <div
                        class="dropdown-menu dropdown-menu-right"
                        style="min-width: 170px"
                        v-if="!deviceStatusCode(device)"
                      >
                        <ul class="inner">
                          <li
                            class="list_item_li"
                            @click="
                              changeDeviceNotificationStatus(
                                device.device_id,
                                'Enable'
                              )
                            "
                          >
                            <div class="radio_input_image with_check_icon">
                              <input
                                :id="
                                  deviceIdentifier('enable_status_', device._id)
                                "
                                type="radio"
                                :name="
                                  deviceIdentifier(
                                    'status_selection_',
                                    device._id
                                  )
                                "
                                :checked="!isDeviceDisabled(device)"
                              />
                              <label
                                :for="
                                  deviceIdentifier('enable_status_', device._id)
                                "
                                class="radio_right"
                              >
                                Enable
                              </label>
                            </div>
                          </li>

                          <li
                            class="list_item_li"
                            @click="
                              changeDeviceNotificationStatus(
                                device.device_id,
                                'Disable'
                              )
                            "
                          >
                            <div class="radio_input_image with_check_icon">
                              <input
                                :id="
                                  deviceIdentifier(
                                    'disable_status_',
                                    device._id
                                  )
                                "
                                type="radio"
                                :name="
                                  deviceIdentifier(
                                    'status_selection_',
                                    device._id
                                  )
                                "
                                :checked="isDeviceDisabled(device)"
                              />
                              <label
                                :for="
                                  deviceIdentifier(
                                    'disable_status_',
                                    device._id
                                  )
                                "
                                class="radio_right"
                              >
                                Disable
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <i
                      v-tooltip.top-center="'Remove'"
                      class="icon_table remove_icon icon-delete-cs c-pointer"
                      @click.prevent="
                        removeNotificationDevice(device.device_id)
                      "
                    ></i>
                  </td>
                </tr>
              </template>

              <tr v-else>
                <td colspan="5" class="text-center">
                  <p class="no_data_padding"
                    >You have not connected any device yet.</p
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row col-md-12 col-sm-12 input_field" v-if="showActionButton">
        <button
          class="btn blue_gradient large_btn btn_loader loader_right"
          @click.prevent="changeInstagramNotificationDevices()"
          :disabled="changeNotificationDevicesLoaderStatus"
        >
          <span>Save</span>
          <clip-loader
            :color="'#ffffff'"
            :size="'16px'"
            v-if="changeNotificationDevicesLoaderStatus"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>
