// import {automationFeatureLockCheck} from "@src/router";
import { plannerRoutes as plannerRoutesTemp } from '@src/modules/planner_v2/config/routes/planner'
import { plannerRoutes } from '@src/modules/planner/config/routes/planner'

const PublishMain = () =>
  import(
    /* webpackChunkName: "publish" */ '@src/modules/publish/components/PublishMain.vue'
  )
const ComposerMain = () =>
  import(
    /* webpackChunkName: "publish" */ '@src/modules/composer/components/ComposerMain.vue'
  )
const AutomationMain = () =>
  import(
    /* webpackChunkName: "publish" */ '@src/modules/automation/components/AutomationMain.vue'
  )
const MediaLibraryMain = () =>
  import(
    /* webpackChunkName: "publish" */ '@src/modules/publish/components/media-library/MediaLibraryMain'
  )
export const publishRoutes = {
  publish: {
    path: '/:workspace/publish',
    name: 'publish',
    redirect: '/composer',
    component: PublishMain,
    meta: {
      title: 'Publish',
    },
    children: [
      {
        path: '/:workspace/publish/media/',
        name: 'media-library',
        component: MediaLibraryMain,
        meta: {
          title: 'Media Library | Manage',
        },
      },
      {
        path: '/:workspace/composer-old/',
        name: 'composer',
        component: ComposerMain,
        redirect: '/:workspace/composer/',
        meta: {
          title: 'Composer | Publish',
        },
      },
      {
        path: '/:workspace/automation/',
        name: 'automation',
        component: AutomationMain,
        meta: {
          title: 'Automation Recipes | Publish',
        },
        // beforeEnter: (to, from, next) => automationFeatureLockCheck(to, from, next)
      },
    ],
  },
  plannerTemp: plannerRoutesTemp.planner,
  planner: plannerRoutes.planner,
}
