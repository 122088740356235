import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@state/base'
/***************************
 *  MODULES ROUTES BY NAME
 *****************************/
import { settingRoutes } from './modules/setting/config/routes/setting'
import { discoveryRoutes } from './modules/discovery/config/routes/discovery'
import { analyticsRoutes } from './modules/analytics/config/routes/analytics'
import { composerRoutes } from './modules/composer/config/routes/composer'
import { automationRoutes } from './modules/automation/config/routes/automation'
import { publishRoutes } from './modules/publish/config/routes/publish'
import { inboxRoutes } from './modules/inbox/config/routes/inbox'
import { analyticsRoutesV3 } from './modules/analytics_v3/config/routes'

import { onboardingRoutes } from './modules/onboarding/config/routes/onboarding'

// Analytics New Routes
import { analyticsRoutesV2 } from './modules/analytics/config/routes/analyticsV2'

import Home from './Home.vue'
import { userMaven } from './tracking/userMavenMixin'

// Overriding Router Replace Function
if (Router.prototype.replace) {
  const originalReplace = Router.prototype.replace
  Router.prototype.replace = function replace(location, onComplete, onAbort) {
    return originalReplace
      .call(this, location, onComplete, onAbort)
      .catch((err) => {
        console.log(
          `%c Navigation Error on Replace: ${err}`,
          'color:red;background pink'
        )
        return err
      })
  }
}

// Overriding Router Push Function
if (Router.prototype.push) {
  const originalPush = Router.prototype.push
  Router.prototype.push = function push(location) {
    return new Promise((resolve, reject) => {
      originalPush.call(
        this,
        location,
        () => {
          // on complete
          resolve(this.currentRoute)
        },
        (error) => {
          // on abort
          // only ignore NavigationDuplicated error
          if (error?.name === 'NavigationDuplicated') {
            console.log(
              `%c Navigation Error on Push: ${error}`,
              'color:red;background pink'
            )
            resolve(this.currentRoute)
          } else {
            reject(error)
          }
        }
      )
    })
  }
}

const Dashboard = () => import('./views/Dashboard')

/***************************
 * Modules - AUTHENTICATION
 * !!!!!!!!!!!!!!!!!!!!!! CHANGING NAME OF ANY ROUTE CAN EFFECT "App.vue" PAGE CONDITIONS  !!!!!!!!!!!!!!!!!!!!!!!
 *****************************/
const Login = () => import('./modules/account/views/SignIn')
const SharePlans = () => import('./modules/planner_v2/views/SharePlans')
const Signup = () => import('./modules/account/views/SignUp')
const TwoFactorVerification = () =>
  import('./modules/account/views/TwoFactorVerification')
const ForgotPassword = () => import('./modules/account/views/ForgotPassword')
const magicLoginRequest = () => import('./modules/account/views/MagicSignIn')
const EmailVerificationScreen = () =>
  import('./modules/account/views/VerifyEmail')

const joinTeam = () => import('./modules/account/views/JoinTeamMember')

const ComingSoon = () => import('@common/components/ComingSoon')
const UpgradeToLargeAgencyPlanModal = () =>
  import('@common/components/dialogs/UpgradeToLargeAgencyPlanModal')
const SSO = () => import('./components/authentication/SSO.vue')
const setPassword = () => import('./components/authentication/SetPassword.vue')
const Logout = () => import('./components/authentication/Logout.vue')
const TeamMemberJoin = () =>
  import('./components/authentication/organization/TeamMemberJoin.vue')
const VerifyEmail = () => import('./components/authentication/VerifyEmail.vue')
const ResetPassword = () =>
  import('./components/authentication/ResetPassword.vue')
const ApproverLogin = () =>
  import('./components/authentication/ApproverLogin.vue')

const TrialExpired = () =>
  import('./modules/setting/components/billing/TrialExpired.vue')
const CancelledPlan = () =>
  import('./modules/setting/components/billing/CancelledPlan.vue')
const DeletedPlan = () =>
  import('./modules/setting/components/billing/DeletedPlan.vue')
const PausedPlan = () =>
  import('./modules/setting/components/billing/PausedPlan.vue')
const DirectUpgradePlan = () =>
  import('./modules/setting/components/billing/DirectUpgradePlan.vue')
const DirectUpgradeAddon = () =>
  import('./modules/setting/components/billing/DirectUpgradeAddon.vue')
const UpgradeStarterPlan = () =>
  import('./modules/setting/components/billing/lifetime/UpgradeStarterPlan.vue')
const UpdateFeaturePlan = () =>
  import('@common/components/dialogs/UpdateFeaturePlan')
const SocialShareModal = () =>
  import('./modules/publish/components/dialogs/SocialShareDialog.vue')

// Upsell Add-ons for lifetime user routes.
const LimitsUpsellView = () =>
  import(
    './modules/setting/components/billing/upsell-lifetime/limits-upsell/LimitsUpsellView'
  )

// 404 page
const Page404 = () => import('./components/Page404.vue')
const Maintenance = () => import('./components/Maintenance.vue')

// UI KIT
const UIKIT = () => import('./views/UIKit.vue')
const UIKitButton = () => import('./views/sub-views/UIKitButton.vue')
const UIKitDropdown = () => import('./views/sub-views/UIKitDropdown.vue')
const UIKitForms = () => import('./views/sub-views/UIkitForms')
const UIKitTablesBoxes = () => import('./views/sub-views/UIKitTables&Boxes')
const UIKitUsefulIcons = () => import('./views/sub-views/uikit-useful-Icons')

const GoogleDriveAuth = () => import('./components/GoogleDriveAuth')

const ifNotAuthenticated = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.
  if (!store.getters.getLoadedOnce) await store.dispatch('authenticate', to)
  if (!store.getters.isUserAuthenticated) {
    next()
    return false
  }
  // if the user is authenticated and his email is verified, prevent them from landing pages.
  if (
    store.getters.isUserAuthenticated &&
    store.getters.getProfile?.email_verify
  ) {
    const workspaceSlug = store.getters.getWorkspaces.items.find(
      (item) => item.default === true
    ).workspace.slug
    if (workspaceSlug) {
      next({ name: 'dashboard', params: { workspace: workspaceSlug } })
    } else {
      next('/workspaces')
    }
    return false
  }

  next('/')
}

const ifAuthenticated = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.

  if (!store.getters.getLoadedOnce) await store.dispatch('authenticate', to)
  if (store.getters.isUserAuthenticated) {
    next()
    return
  }
  if (to.name && to.name === 'home') next('/login')
  else next('/login?redirected_url=' + to.fullPath)
}

const ignoreAuthenticationProcess = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.
  if (!store.getters.getLoadedOnce) await store.dispatch('authenticate', to)
  next()
}

Vue.use(Router)

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    // console.log('to route', to)
    // console.log('from route', from)
    // console.log('savedPosition', savedPosition)
    /*
        NOTE: don't change the position...
        if (from && from.meta && from.meta.preserve_position) {
          console.log('returning scroll position...')
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(savedPosition)
            }, 500)
          })
        } */
    // do nothing
    if (
      (to && to.meta && to.meta.preserve_position) ||
      (from && from.meta && from.meta.preserve_position)
    ) {
      return
    }
    return { x: 0, y: 0 }
  },
  routes: [
    // unauthenticated routes
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Sign In | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/2factor-verification/:token',
      name: 'twoFactor',
      component: TwoFactorVerification,
      meta: {
        title: 'Two Factor Verification | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        title: 'Sign Up | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: ForgotPassword,
      meta: {
        title: 'Forgot Password | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/share/planner/:id',
      name: 'share-plans',
      component: SharePlans,
      meta: {
        title: 'Shared Plans | ContentStudio',
        guest: true,
      },
      beforeEnter: ignoreAuthenticationProcess,
    },
    {
      path: '/setPassword',
      name: 'setPassword',
      component: setPassword,
      meta: {
        title: 'Set Password | ContentStudio',
        guest: true,
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/uikit-contentstudio',
      name: 'uikit-contentstudio',
      component: UIKIT,
      meta: {
        title: 'UI Kit  | ContentStudio',
        top_header: false,
      },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'button',
          name: 'uikit-button',
          component: UIKitButton,
          meta: {
            title: 'Button - UIKit',
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'dropdown',
          name: 'uikit-dropdown',
          component: UIKitDropdown,
          meta: {
            title: 'Dropdown | UIKit',
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'forms',
          name: 'uikit-forms',
          component: UIKitForms,
          meta: {
            title: 'Forms | UIKit',
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'tables-&-boxes',
          name: 'uikit-tables-&-boxes',
          component: UIKitTablesBoxes,
          meta: {
            title: 'Tables & Boxes | UIKit',
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'useful-icons',
          name: 'uikit-useful-icons',
          component: UIKitUsefulIcons,
          meta: {
            title: 'Useful Icons | UIKit',
          },
          beforeEnter: ifAuthenticated,
        },
      ],
    },
    {
      path: '/register',
      name: 'register',
      component: Signup,
      meta: {
        title: 'Sign up • ContentStudio',
        guest: true,
      },
    },
    {
      path: '/magic_login',
      name: 'magicLogin',
      component: magicLoginRequest,
      meta: {
        title: 'Approver Sign In | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/sso',
      name: 'sso',
      component: SSO,
      meta: {
        title: 'Single-Sign On | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/joinTeam/:reff/:token',
      name: 'joinTeam',
      component: joinTeam,
      meta: {
        title: 'Join ContentStudio | ContentStudio',
        guest: true,
      },
      beforeEnter: ignoreAuthenticationProcess,
    },
    {
      path: '/team/join',
      name: 'organization_team_join',
      component: TeamMemberJoin,
      meta: {
        title: 'Join ContentStudio | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/approverLogin',
      name: 'approver_login',
      component: ApproverLogin,
      meta: {
        title: 'Approver Log In | ContentStudio',
        guest: true,
      },
      beforeEnter: ignoreAuthenticationProcess,
    },
    {
      path: '/recoverPassword',

      name: 'recover_password',
      component: ResetPassword,
      meta: {
        title: 'Forgot Password | ContentStudio',
        guest: true,
      },
      beforeEnter: ifNotAuthenticated,
    },

    // ignoreAuthenticationProcess
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        title: 'Under Maintenance | ContentStudio',
      },
      beforeEnter: ignoreAuthenticationProcess,
    },

    // authenticated routes
    {
      path: '/setPassword',
      name: 'setPassword',
      component: setPassword,
      meta: {
        title: 'Set Password | ContentStudio',
        guest: true,
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/verifyEmail',
      name: 'verify_email',
      component: VerifyEmail,
      meta: {
        title: 'Email Verification | ContentStudio',
        guest: true,
      },
      beforeEnter: ignoreAuthenticationProcess,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'ContentStudio',
        default: false,
        // no_authentication: true
      },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '/social-share',
          name: 'socialShareModal',
          component: SocialShareModal,
          meta: {
            title: 'Social Share | ContentStudio',
          },
        },

        {
          path: '/verification',
          name: 'email_verification',
          component: EmailVerificationScreen,
          meta: {
            title: 'Verify Email Address | ContentStudio',
          },
        },
        {
          path: '/trial_expired',
          name: 'trial_expired',
          component: TrialExpired,
          meta: {
            title: 'Trial Expired | ContentStudio',
          },
        },
        {
          path: '/subscription_expired',
          name: 'subscription_expired',
          component: TrialExpired,
          meta: {
            title: 'Subscription Expired | ContentStudio',
          },
        },
        {
          path: '/subscription_cancelled',
          name: 'subscription_cancelled',
          component: CancelledPlan,
          meta: {
            title: 'Subscription Cancelled | ContentStudio',
          },
        },
        {
          path: '/subscription_deleted',
          name: 'subscription_deleted',
          component: DeletedPlan,
          meta: {
            title: 'Subscription Deleted | ContentStudio',
          },
        },
        {
          path: '/subscription_paused',
          name: 'subscription_paused',
          component: PausedPlan,
          meta: {
            title: 'Subscription Paused | ContentStudio',
          },
        },
        {
          path: '/upgrade_subscription',
          name: 'upgrade_subscription',
          component: DirectUpgradePlan,
          meta: {
            title: 'Upgrade Plan | ContentStudio',
          },
        },
        {
          path: '/upgrade_addon',
          name: 'upgrade_addon',
          component: DirectUpgradeAddon,
          meta: {
            title: 'Upgrade Plan | ContentStudio',
            top_header: false,
          },
        },
        {
          path: '/upgrade_addons_lifetime',
          name: 'upgrade_addons_lifetime',
          component: UpdateFeaturePlan,
          meta: {
            title: 'Upgrade Lifetime Addons | ContentStudio',
            top_header: false,
          },
        },
        {
          path: '/upgrade_starter_plan',
          name: 'upgrade_starter_plan',
          component: UpgradeStarterPlan,
          meta: {
            title: 'Upgrade To Starter Plan | ContentStudio',
            top_header: false,
          },
        },
        {
          path: '/upgrade_your_limits',
          name: 'upgrade_your_limits',
          component: LimitsUpsellView,
          meta: {
            title: 'Upgrade Limits | ContentStudio',
            top_header: false,
          },
        },
        {
          path: '/:workspace/dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            title: 'Dashboard | ContentStudio',
          },
        },
        {
          path: '/google-drive-auth',
          name: 'googleDrive',
          component: GoogleDriveAuth,
          meta: {
            title: 'Google Drive Auth | ContentStudio',
          },
        },
        {
          path: '/:workspace/upgrade_lifetime_addon',
          component: ComingSoon,
          name: 'upgrade_lifetime_addon',
          meta: {
            title: 'Upgrade Lifetime Addon | ContentStudio',
          },
        },
        {
          path: '/upgrade_large',
          component: UpgradeToLargeAgencyPlanModal,
          name: 'upgrade_large',
          meta: {
            title: 'Upgrade to Large Agency | ContentStudio',
          },
        },
        {
          path: '/logout',
          name: 'logout',
          component: Logout,
          meta: {
            title: 'Sign Out | ContentStudio',
            guest: true,
          },
        },
        settingRoutes.settings,
        settingRoutes.workspace.workspaces,
        settingRoutes.workspace.manageTeam,
        settingRoutes.workspace.createWorkspace,
        settingRoutes.workspace.StartTrial,
        settingRoutes.workspace.manageLimits,
        discoveryRoutes,
        analyticsRoutes,
        analyticsRoutesV2,
        analyticsRoutesV3,

        publishRoutes.publish,
        publishRoutes.plannerTemp,
        publishRoutes.planner,
        inboxRoutes.inbox,
        composerRoutes.composerBlog,
        composerRoutes.composerSocial,
        automationRoutes.articleSocialAutomationListing,
        automationRoutes.saveArticleSocialAutomation,
        automationRoutes.articleBlogAutomationListing,
        automationRoutes.saveArticleBlogAutomation,
        automationRoutes.videoSocialAutomationListing,
        automationRoutes.saveVideoSocialAutomation,
        automationRoutes.videoBlogAutomationListing,
        automationRoutes.saveVideoBlogAutomation,
        automationRoutes.saveBulkCsvAutomation,
        automationRoutes.evergreenAutomationListing,
        automationRoutes.saveEvergreenAutomation,
        automationRoutes.rssAutomationListing,
        automationRoutes.saveRssAutomation,
        automationRoutes.bulkSocialAutomationListing,
        onboardingRoutes,
      ],
    },
    {
      path: '/*',
      name: 'page404',
      component: Page404,
      meta: {
        title: 'Page Not Found | ContentStudio',
        no_authentication: true,
      },
      beforeEnter: ignoreAuthenticationProcess,
    },
  ],
  mode: 'history',
  linkActiveClass: 'active',
})

router.afterEach(async (to, from) => {
  console.debug('afterEach')
  // react to route changes...
  // don't forget to call next()
  if (store.getters.getProfile && store.getters.getProfile._id) {
    // if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    console.debug('userMaven Pageview')
    await userMaven.track('pageview')
    // }
  }

  if (
    to.name === 'email_verification' &&
    from.name === 'onboardingWorkspace' &&
    !router.app.$store.getters.getProfile.email_verify
  ) {
    router.push({ path: to.path })
  }

  // if the user clicks back button from dashboard and goes home route
  // if(
  //     (to.name === 'home' && from.name === 'dashboard') ||
  //     (to.name === 'home' && from.name === 'email_verification') ||
  //     (to.name === 'email_verification' && from.name === 'onboardingWorkspace')){
  //   router.push({path: from.path})
  // }
})

export default router

/**
 * beforeEnter route event handler for automation feature lock. @publish.js
 * @param to
 * @param from
 * @param next
 */
export function automationFeatureLockCheck(to, from, next) {
  if (!shouldLockBasedOnSubAddons('automation_campaigns')) {
    next()
  } else {
    const workspaceSlug = to.params.workspace
    next(`${workspaceSlug}/dashboard`)
  }
}

/**
 * beforeEnter route event handler for analytics feature lock. @analytics.js
 * @param to
 * @param from
 * @param next
 */
export function analyticsFeatureLockCheck(to, from, next) {
  if (isAnalyticsEnabledForUser()) {
    next()
  } else {
    const activeWorkspaceSlug = to.params.workspace
    next(`${activeWorkspaceSlug}/analytics/overview`)
  }
}

/**
 * beforeEnter route event handler for Discover Influencers feature lock. @discovery.js
 * @param to
 * @param from
 * @param next
 */
export function influencerFeatureLockCheck(to, from, next) {
  if (!shouldLockBasedOnSubAddons('influencers')) {
    next()
  } else {
    const workspaceSlug = to.params.workspace
    next(`${workspaceSlug}/dashboard`)
  }
}

/**
 * Checks for a feature access based on subaddons. common usecase for feature lock check on routes
 * @param type
 * @returns {boolean}
 */
function shouldLockBasedOnSubAddons(type) {
  console.log(
    'METHOD::shouldLockBasedOnSubAddons -> ',
    store.getters.getProfile
  )
  const subscription = store.getters.getProfile.subscription_details

  let shouldLock = false
  // check default addons that are part of the subscription
  let defaultAddons = null
  if (subscription?.default_add_ons) {
    defaultAddons = subscription.default_add_ons
  } else {
    return false
  }

  shouldLock = defaultAddons.type ? !defaultAddons.type : false

  // if the subscription has that addon disabled
  // check for paid add ons
  if (shouldLock) {
    const paidAddons = store.getters.getActiveWorkspaceAddonsLifetime
    shouldLock =
      type === 'social_inbox'
        ? !paidAddons.social_inbox || !paidAddons.social_inbox_beta
        : !paidAddons.type
  }

  return shouldLock
}

/**
 * Checks if the users have access to the analytics feature
 * @returns {boolean}
 */
function isAnalyticsEnabledForUser() {
  if (store.getters.isAnalyticsPlanAllowed) {
    return true
  }
  return store.getters.getActiveWorkspaceAddonsLifetime.analytics
}
