export default {
  methods: {
    getMentionedUsersNames(text) {
      var regex = /@[A-Za-z]*/g
      const mentionedUsers = text.match(regex)
      if (mentionedUsers) {
        mentionedUsers.filter((name) => {
          const replaceText = '<span class="mention">' + name + '</span>'

          text = text.replace(new RegExp(name, 'g'), replaceText)
        })
      }
      return text
    },
  },
  computed: {},
}
