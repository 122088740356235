<template>
  <div class="overflow-y-auto h-full">
    <div
      class="planner-calender-main"
      :class="{
        loader_overlay_with_loader: isFetchingPlans,
      }"
    >
      <beat-loader v-if="isFetchingPlans" :color="'005fd0'"></beat-loader>

      <transition name="fade-list-item">
        <template v-if="showNullPlansSection">
          <div class="warning_box text-center mx-0">
            <p>No posts found for the selected filters and date-range.</p>
          </div>
        </template>
      </transition>

      <FullCalendar class="planner-app-calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <CalendarEvent
            :item="arg.event.extendedProps"
            :plans="plans"
          ></CalendarEvent>
        </template>
      </FullCalendar>
    </div>

    <b-modal
      id="post-status-modal"
      size="lg"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      dialog-class="max-w-7xl"
    >
      <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
    </b-modal>
  </div>
</template>

<script>
// Core Imports
import { mapGetters } from 'vuex'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
// Full Calendar Imports
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import {
  CC_POST_RESCHEDULE_PLAN_ERROR,
  DRAFT_POST_RESCHEDULE_PLAN_ERROR,
} from '@common/constants/messages'
import { getPlanMedia } from '@common/lib/planner'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import { pusherSocketPublish } from '@common/lib/pusher'
import debounce from 'lodash/debounce'
import CalendarEvent from '../components/CalendarEvent'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEvent,
    PlannerPostStatus,
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    isPlatformSelected: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      selectedPost: null,
      calendarOptions: {
        // Plugins
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],

        // Components Options
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },

        // Custom
        customButtons: {
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('clicked the custom button!')
            },
          },
        },

        // Views Options
        editable: true,
        // selectable: true,
        // selectMirror: true,
        dayMaxEvents: 15,
        initialView: 'dayGridWeek',
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: { weekday: 'long' },
        views: {
          dayGrid: {
            dayCellContent: this.handleDayCellContent,
          },
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        timeZone: 'UTC',
        datesSet: this.handleDatesSet,
        viewDidMount: this.handleViewDidMount,
        // dayCellContent: this.handleDayCellContent,
        // dayCellClassNames: 'h-100 fc-options-hover',
        dayCellClassNames: 'fc-cell',
        dayHeaders: true,

        // Events Options
        initialEvents: [],
        events: [],
        eventColor: 'transparent',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDisplay: 'block',
        eventDrop: this.reschedulePlan,
        firstDay: null,

        // eventContent: this.handleEventContent
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
      labels_channel: '',
      view: 'dayGridWeek',
      previousTopOffset: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getPlannerLoaders',
      'getPublishSelection',
      'getWorkspaceDetails',
    ]),
    isFetchingPlans() {
      return this.loader
    },
    planItemsCount() {
      return this.plans.length
    },
    /* isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    }, */
    showNullPlansSection() {
      return !this.isFetchingPlans && !this.planItemsCount
    },
  },
  watch: {
    showNullPlansSection(newValue) {
      if (newValue) {
        this.$set(this.calendarOptions, 'events', this.plans)
      }
    },
    plans(newValue) {
      console.debug('Watch Plan', newValue)
      if (newValue.length) {
        this.$set(this.calendarOptions, 'events', newValue)
      }
    },
    scroll(val) {
      console.log('WATCH::val -> ', val)
      debounce(() => {
        this.handleTableHeight()
      }, 650)()
    },
  },
  created() {
    const query = Object.assign({}, this.$route.query)
    delete query.order_by
    this.$router.push({ query: query })
  },
  mounted() {
    this.calendarOptions.firstDay = this.getWorkspaces.activeWorkspace.first_day
      ? this.getWorkspaces.activeWorkspace.first_day.key
      : 1
    EventBus.$on('workspace-changed', () => {
      this.calendarOptions.firstDay = this.getWorkspaces.activeWorkspace
        .first_day?.key
        ? this.getWorkspaces.activeWorkspace.first_day.key
        : 1
    })
    EventBus.$on('post-status-modal', (post) => {
      this.selectedPost = post
      this.$bvModal.show('post-status-modal')
    })

    this.$nextTick(() => {
      this.labels_channel = pusherSocketPublish.subscribe(
        `labels_${this.getActiveWorkspace._id}`
      )
      this.bindPusherLabels(this.labels_channel)
    })

    this.$nextTick(() => {
      this.handleTableHeight()
    })
    window.addEventListener('resize', () => {
      this.handleTableHeight()
    })
  },
  beforeDestroy() {
    console.log('METHOD::beforeDestroy')
    delete this.$route.query.date
    window.removeEventListener('resize', () => {
      this.handleTableHeight()
    })

    EventBus.$off('post-status-modal')
  },
  methods: {
    handleTableHeight() {
      if (this.$el) {
        const offset = this.$el.getBoundingClientRect()
        this.previousTopOffset = offset
        this.$el.style.height = `calc(100vh - ${
          scroll ? this.previousTopOffset : offset.top
        }px)`
      }
    },
    // https://preactjs.com/guide/v8/api-reference/ - createElement Guide
    handleDayCellContent(arg, createElement) {
      const day = moment(arg.date).utc().format('D')
      let dateEle = null

      if (arg.isToday) {
        dateEle = createElement(
          'span',
          {
            class: 'fc-daygrid-day-no active',
          },
          day
        )
      } else {
        dateEle = createElement(
          'span',
          {
            class: 'fc-daygrid-day-no',
          },
          day
        )
      }

      if (
        (arg.isFuture || arg.isToday) &&
        !arg.isOther &&
        this.hasPermission('can_access_top_header')
      ) {
        const post = createElement('i', {
          class: 'fas fa-plus',
          title: 'New Social Post',
          onclick: (e) => this.createContent(e, 'post', arg.isToday, arg.date),
        })

        const blog = createElement('i', {
          class: 'fas fa-file',
          title: 'New Blog Post',
          onclick: (e) => this.createContent(e, 'blog', arg.isToday, arg.date),
        })

        const icons = createElement(
          'span',
          {
            class: 'fc-daygrid-top-icon',
          },
          [post, blog]
        )

        return createElement('div', {}, [icons, dateEle])
      } else {
        return createElement('div', {}, dateEle)
      }
    },
    createContent(e, type, isToday, date) {
      console.debug('createContent', type)
      this.changePublishTypeFromCalender(type, isToday, date)
    },
    handleDayClassNames(arg) {
      console.log('handleDayClassNames', arg)
      if (arg.event) {
        console.log('handleDayClassNames event', arg.event.extendedProps)
      }
    },
    handleDateClick(arg) {
      alert('date click! ' + arg.dateStr)
    },
    handleViewDidMount(event) {
      console.log('handleViewDidMount', event)
      this.fetchPlansWithCalendarDate(event)
    },

    async handleDatesSet(event) {
      console.debug('handleDatesSet', this.fetchPlansWithCalendarDate)
      await this.fetchPlansWithCalendarDate(event)
    },
    async fetchPlansWithCalendarDate(event) {
      console.log('fetchPlansWithCalendarDate', event)
      const start = moment(event.view.activeStart).format('MMMM DD, YYYY')
      const end = moment(event.view.activeEnd).format('MMMM DD, YYYY')

      // this.$store.commit(planner.SET_CALENDAR_DATE_RANGE, `${start} - ${end}`)

      this.$router.push({
        query: { ...this.$route.query, date: start + ' - ' + end },
      })

      // if (
      //   this.$route.query.date &&
      //   (this.$route.query.date.split('-')[0].trim() !== start.trim() ||
      //     this.$route.query.date.split('-')[1].trim() !== end.trim())
      // ) {
      //   this.$router.push({
      //     query: { ...this.$route.query, date: start + ' - ' + end }
      //   })
      //   // this.$store.commit(planner.SET_FETCH_PLANS_LOADER, true)
      //   // await this.$store.dispatch('fetchPlans', this.$route)
      //
      // } else {
      //
      // }

      // this.addItemIconToFullCalendar()
      // this.countTotalRenderedWeekEvents()
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      // let title = prompt('Please enter a new title for your event')
      // let calendarApi = selectInfo.view.calendar
      // calendarApi.unselect() // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },
    async handleEventClick(info) {
      console.log('event click', info.jsEvent.target.getAttribute('data-event'))
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      if (event) {
        item.stateObject = this
        switch (event) {
          case 'viewItemAttachment':
            // eslint-disable-next-line no-case-declarations
            let plan = target.getAttribute('data-plan')
            if (plan) {
              // finding plan from states
              plan = item.stateObject.plans.find((item) => item._id === plan)
              if (plan) {
                // checking for plan media
                const attachment = getPlanMedia(plan)
                if (attachment) {
                  EventBus.$emit('displayFile', {
                    type: attachment.type,
                    media: attachment.media,
                    index: 0,
                  })
                  item.stateObject.$bvModal.show('display-file-modal')
                }
              }
            }
            break
          case 'editCalendarItem':
            if (item.can_perform && item.can_perform.edit) {
              this.$store.dispatch('editPlan', item)
            }
            break
          case 'deleteCalendarItem':
            // eslint-disable-next-line no-case-declarations
            const res = await this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this post?', {
                title: 'Remove Post',
                ...swalAttributes(),
              })
              .then((res) => res)
              .catch(() => null)

            if (res) {
              const response = await this.$store.dispatch('removePlan', {
                id: item._id,
              })
              if (response)
                this.plans.splice(
                  this.plans.findIndex((x) => x._id === item._id),
                  1
                )
            }
            break
          case 'replaceCalendarItem':
            console.debug('Method::replacePlan-Calendar', item)
            this.$emit('replace-plan', item)
            break
          case 'approveCalendarItem':
            this.changePlanStatusMethod('scheduled', item, true)
            // this.$store.dispatch('changePlanStatus', { id: item._id, status: 'scheduled' })
            break
          case 'rejectCalendarItem':
            this.changePlanStatusMethod('rejected', item, true)
            // await this.$store.dispatch('changePlanStatus', { id: item._id, status: 'rejected' })
            break
          case 'duplicateCalendarItem':
            this.$store.dispatch('clonePlan', item)
            break
          case 'calendarItemCheckbox':
            // eslint-disable-next-line no-case-declarations
            const planId = target.getAttribute('value')
            if (target.checked) {
              this.getPlans.selected_plans.push(planId)
            } else {
              this.getPlans.selected_plans.splice(
                this.getPlans.selected_plans.indexOf(planId),
                1
              )
            }
            console.log(target)
            console.log()
            console.log(target.checked)
            break
          case 'approvalCalendarItemReject':
            this.$emit('reject-with-comment', item)
            // resp = this.processPlanApproval(item, 'reject')
            // if (resp) {
            //   this.$emit('refresh-table')
            // }
            break
          case 'approvalCalendarItemApprove':
            this.$emit('approve-with-comment', item)
            // resp = this.processPlanApproval(item, 'approve')
            // if (resp) {
            //   this.$emit('refresh-table')
            // }
            break
        }
      } else {
        this.$emit('preview-plan', item._id)
        // let item = JSON.parse(JSON.stringify(info.event.extendedProps))
        // item.stateObject = this
        // if (item.can_perform && item.can_perform.edit) this.$store.dispatch('editPlan', item)
      }
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    async reschedulePlan(info) {
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      item.stateObject = this
      if (!item.can_perform || !item.can_perform.edit) {
        info.revert()
        return false
      }
      console.log(item, info.event.start)
      item.start = moment(info.event.start)
      item.startStr = info.event.startStr // startStr YYYY-MM-DD
      if (item.content_category_id && item.content_category_id.length > 0) {
        this.$store.dispatch('toastNotification', {
          message: CC_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        info.revert()
        return false
      }
      if (item.status === 'draft') {
        this.$store.dispatch('toastNotification', {
          message: DRAFT_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        info.revert()
        return false
      }

      const status = await this.$store.dispatch('reschedulePlan', item)
      if (!status) info.revert()
    },
  },
}
</script>

<style lang="scss">
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>
