<template>
  <div class="no_data_found_content">
    <div class="no_data_found_content_inner">
      <div class="img">
        <img
          src="../../../../assets/img/no_data_images/no_data_found.svg"
          alt=""
        />
      </div>
      <!--<h2>No more results found</h2>-->
      <template v-if="message">
        <p>{{ message }}</p>
      </template>
      <template v-else>
        <p>No <span v-if="more">more </span>results found for your query.</p>
        <p>Please try a different search string or change search filters.</p>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    more: {
      type: Boolean,
      Default: false
    },
    message: {
      type: String,
      Default: null
    }
  }
}
</script>
