<template>
  <div class="facebook_analytic_boxes v5 row_20">
    <div class="box_with_icon">
      <div class="box_inner d-flex align-items-center">
        <div class="text">
          <div class="text_left">
            <p class="top_text"
              >Pins
              <i
                class="far fa-question-circle cursor_pointer"
                v-tooltip="messages.pins"
                aria-hidden="true"
              ></i>
            </p>
            <h2>{{ pins }}</h2>
            <!--<p class="bottom_text">-->
            <!--<i class="cs-arrow-down red"></i>-->
            <!--<span>13.4%</span>-->
            <!--</p>-->
          </div>
        </div>
        <div class="icon">
          <!--<i class="cs-eye"></i>-->
          <img
            class="img_icon"
            src="../../../../../assets/img/analytics/icons/pin.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="box_with_icon">
      <div class="box_inner d-flex align-items-center">
        <div class="text">
          <p class="top_text"
            >Engagement
            <i
              class="far fa-question-circle cursor_pointer"
              v-tooltip="messages.engagement"
              aria-hidden="true"
            ></i>
          </p>
          <h2>{{ engagement }}</h2>
          <!--<p class="bottom_text green">-->
          <!--<i class="cs-arrow-up green"></i>-->
          <!--<span>13.4%</span>-->
          <!--</p>-->
        </div>
        <div class="icon">
          <!--<i class="cs-comment"></i>-->
          <img
            class="img_icon"
            src="../../../../../assets/img/analytics/icons/chat.svg"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="box_with_icon">
      <div class="box_inner d-flex align-items-center">
        <div class="text">
          <p class="top_text"
            >Followers
            <i
              class="far fa-question-circle cursor_pointer"
              v-tooltip="messages.followers"
              aria-hidden="true"
            ></i>
          </p>
          <h2>{{ followers }}</h2>
          <!--<p class="bottom_text">-->
          <!--<i class="cs-arrow-down red"></i>-->
          <!--<span>13.4%</span>-->
          <!--</p>-->
        </div>
        <div class="icon">
          <!--<i class="cs-group"></i>-->
          <img
            class="img_icon"
            src="../../../../../assets/img/analytics/icons/users.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="box_with_icon">
      <div class="box_inner d-flex align-items-center">
        <div class="text">
          <p class="top_text"
            >Following
            <i
              class="far fa-question-circle cursor_pointer"
              v-tooltip="messages.following"
              aria-hidden="true"
            ></i>
          </p>

          <h2>{{ following }}</h2>
          <!--  <p class="bottom_text green">
                          <i class="cs-arrow-up green"></i>
                          <span>13.4%</span>
                      </p>-->
        </div>
        <div class="icon">
          <!--<i class="cs-star"></i>-->
          <img
            class="img_icon"
            src="../../../../../assets/img/analytics/icons/group.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="box_with_icon">
      <div class="box_inner d-flex align-items-center">
        <div class="text">
          <p class="top_text"
            >Boards
            <i
              class="far fa-question-circle cursor_pointer"
              v-tooltip="messages.boards"
              aria-hidden="true"
            ></i>
          </p>
          <h2>{{ boards }}</h2>
          <!--<p class="bottom_text">-->
          <!--<i class="cs-arrow-down red"></i>-->
          <!--<span>13.4%</span>-->
          <!--</p>-->
        </div>
        <div class="icon">
          <!--<i class="cs-group"></i>-->

          <img
            class="img_icon"
            src="../../../../../assets/img/analytics/icons/window.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  ANALYTICS_PINS_TOOLTIP,
  ANALYTICS_PINTEREST_BOARDS_TOOLTIP,
  ANALYTICS_PINTEREST_ENGAGEMENT_TOOLTIP,
  ANALYTICS_PINTEREST_FOLLOWERS_TOOLTIP,
  ANALYTICS_PINTEREST_FOLLOWING_TOOLTIP
} from '@common/constants/messages'

export default {
  props: {
    pins: {},
    engagement: {},
    followers: {},
    boards: {},
    following: {}
  },
  components: {},
  mounted () {},

  data () {
    return {
      messages: {
        pins: ANALYTICS_PINS_TOOLTIP,
        engagement: ANALYTICS_PINTEREST_ENGAGEMENT_TOOLTIP,
        followers: ANALYTICS_PINTEREST_FOLLOWERS_TOOLTIP,
        following: ANALYTICS_PINTEREST_FOLLOWING_TOOLTIP,
        boards: ANALYTICS_PINTEREST_BOARDS_TOOLTIP
      }
    }
  },

  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([])
  }
}
</script>
