<template>
  <div
    class="search-field-standard"
    :style="{ opacity: disabled ? '0.55' : '' }"
  >
    <div class="search-field-standard__wrapper">
      <div class="search-field-standard__icon">
        <i class="far fa-search"></i>
      </div>

      <input
        type="text"
        :value="value"
        :placeholder="placeholder"
        maxlength="40"
        :disabled="disabled"
        @input="handleInput($event)"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'SearchField',
  props: {
    placeholder: {
      default: 'Search',
    },
    value: {
      default: '',
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    handleInput: debounce(function (event) {
      this.$emit('input', event.target.value)
    }, 550),

    // handleInput (event) {
    //   this.$emit('input', event.target.value)
    // }
  },
}
</script>
