import { mapGetters, mapActions } from 'vuex'
import { fetchReplugLinksUrl } from '@src/modules/publish/config/api-utils'
import {
  gmb,
  commonTypes,
  instagramTypes,
  linkedinSharingTypes,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { UNKNOWN_ERROR } from '../../constants/messages'

export const replugMixin = {
  computed: {
    ...mapGetters([
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getSocialSharingSelection',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getPublishSelection',
      'getWorkspaces',
      'getInstagramSharingDetails',
      'getGmbSharingDetails',
      'getYoutubeSharingDetails',
    ]),
  },
  methods: {
    ...mapActions([
      'setSharingReplugBrand',
      'setSharingReplugCampaign',
      'setSharingReplugLink',
      'setFetchReplugLinkLoader',
      'setFetchCommonSharingPreviewLoader',
      'setFetchFacebookSharingPreviewLoader',
      'setFetchTwitterSharingPreviewLoader',
      'setFetchLinkedinSharingPreviewLoader',
      'setFetchPinterestSharingPreviewLoader',
      'setFetchTumblrSharingPreviewLoader',
      'setCommonSharingLink',
      'setFacebookSharingLink',
      'setTwitterSharingLink',
      'setPinterestSharingLink',
      'setTumblrSharingLink',
      'setCommonFacebookSharingLink',
      'setCommonTwitterSharingLink',
      'setCommonLinkedinSharingLink',
      'setCommonPinterestSharingLink',
      'setCommonTumblrSharingLink',
      'setFetchInstagramSharingPreviewLoader',
      'setCommonInstagramSharingLink',
      'setCommonCancelledLink',
      'setFacebookCancelledLink',
      'setTwitterCancelledLink',
      'setPinterestCancelledLink',
      'setTumblrCancelledLink',
      'setCommonYoutubeSharingLink',
      'setFetchYoutubeSharingPreviewLoader',
    ]),

    triggerReplugOption() {
      console.debug('Method:triggerReplugOption')
      this.setSharingReplugBrand('')
      this.setSharingReplugCampaign('')
      this.setSharingReplugLink(this.getSharingLink())
    },

    getSharingLink() {
      console.debug('Method:getSharingLink')

      let link = ''
      let description = ''

      if (this.getCommonBoxOptions.status) {
        if (this.getCommonSharingDetails.url) {
          link = this.getCommonSharingDetails.url
        } else {
          description = this.getCommonSharingDetails.message
        }
      } else {
        switch (this.getSocialSharingSelection.activeTab) {
          case 'facebook_tab':
            if (this.getFacebookSharingDetails.url) {
              link = this.getFacebookSharingDetails.url
            } else {
              description = this.getFacebookSharingDetails.message
            }
            break
          case 'twitter_tab':
            if (this.getTwitterSharingDetails.url) {
              link = this.getTwitterSharingDetails.url
            } else {
              description = this.getTwitterSharingDetails.message
            }
            break
          case 'linkedin_tab':
            if (this.getLinkedinSharingDetails.url) {
              link = this.getLinkedinSharingDetails.url
            } else {
              description = this.getLinkedinSharingDetails.message
            }
            break
          case 'pinterest_tab':
            if (this.getPinterestSharingDetails.url) {
              link = this.getPinterestSharingDetails.url
            } else {
              description = this.getPinterestSharingDetails.message
            }
            break
          case 'tumblr_tab':
            if (this.getTumblrSharingDetails.url) {
              link = this.getTumblrSharingDetails.url
            } else {
              description = this.getTumblrSharingDetails.message
            }
            break
          case 'instagram_tab':
            if (this.getInstagramSharingDetails.url) {
              link = this.getInstagramSharingDetails.url
            } else {
              description = this.getInstagramSharingDetails.message
            }
            break
          case 'youtube_tab':
            if (this.getYoutubeSharingDetails.url) {
              link = this.getYoutubeSharingDetails.url
            } else {
              description = this.getYoutubeSharingDetails.message
            }
            break
          case 'gmb_tab':
            if (this.getGmbSharingDetails.url) {
              link = this.getGmbSharingDetails.url
            } else {
              description = this.getGmbSharingDetails.message
            }
            break
        }
      }

      if (link === '') {
        const urls = this.getLinksFromText(description)
        if (urls) {
          link = urls[0]
        }
      }

      return link
    },

    fetchReplugLink(type) {
      console.debug('Method:fetchReplugLink', type)

      if (
        !this.getPublishSelection.replug.brand ||
        !this.getPublishSelection.replug.brand._id
      ) {
        this.alertMessage('Please select replug brand.', 'error')
        return false
      } else if (
        !this.getPublishSelection.replug.campaign ||
        !this.getPublishSelection.replug.campaign._id
      ) {
        this.alertMessage('Please select replug campaign', 'error')
        return false
      } else if (
        !this.getPublishSelection.replug.link ||
        this.getPublishSelection.replug.link.trim().length === 0
      ) {
        this.alertMessage(
          'Please enter a URL to the message box in order to apply Replug.',
          'info'
        )
        return false
      } else if (!this.isValidUrl(this.getPublishSelection.replug.link)) {
        this.alertMessage('Link is not valid.', 'error')
        return false
      }

      this.setFetchReplugLinkLoader(true)

      const links = [this.getPublishSelection.replug.link]
      if (type === 'Common' && this.getCommonSharingDetails.url) {
        links.push(this.getCommonSharingDetails.facebook_url)
        links.push(this.getCommonSharingDetails.twitter_url)
        links.push(this.getCommonSharingDetails.linkedin_url)
        links.push(this.getCommonSharingDetails.pinterest_url)
        links.push(this.getCommonSharingDetails.tumblr_url)
        links.push(this.getCommonSharingDetails.instagram_url)
        links.push(this.getCommonSharingDetails.youtube_url)
        links.push(this.getCommonSharingDetails.gmb_url)
      }

      this.postRequest(
        fetchReplugLinksUrl,
        {
          links: links,
          campaign: this.getPublishSelection.replug.campaign._id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          if (response.data.status) {
            this.applyReplug(response.data.links, type)
            $('.sharing_replug_dropdown .dropdown-menu').removeClass('show')
          } else {
            this.alertMessage(response.data.message, 'error')
          }

          this.setFetchReplugLinkLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchReplugLink', error)
          this.setFetchReplugLinkLoader(false)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    applyReplug(links, type) {
      console.debug('Method:applyReplug', type)
      const stateObject = this
      stateObject.setSharingReplugLink('')

      if (links && links.length) {
        if (type === 'Common') {
          let message = stateObject.getCommonSharingDetails.message
          $(links).each(function (index, el) {
            if (message.indexOf(el.original_link) > -1) {
              message = message.replace(el.original_link, el.replug_link)
            }
            if (el.original_link === stateObject.getCommonSharingDetails.url) {
              stateObject.setCommonSharingLink(el.replug_link)
              stateObject.setFetchCommonSharingPreviewLoader(true)
              stateObject.setCommonCancelledLink(el.replug_link)
              stateObject.fetchLinkPreview(el.replug_link, 'Common')
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.facebook_url
            ) {
              stateObject.setCommonFacebookSharingLink(el.replug_link)
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.twitter_url
            ) {
              stateObject.setCommonTwitterSharingLink(el.replug_link)
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.linkedin_url
            ) {
              stateObject.setCommonLinkedinSharingLink(el.replug_link)
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.pinterest_url
            ) {
              stateObject.setCommonPinterestSharingLink(el.replug_link)
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.tumblr_url
            ) {
              stateObject.setCommonTumblrSharingLink(el.replug_link)
            }
            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.youtube_url
            ) {
              stateObject.setCommonYoutubeSharingLink(el.replug_link)
            }

            if (
              el.original_link ===
              stateObject.getCommonSharingDetails.instagram_url
            ) {
              stateObject.setCommonInstagramSharingLink(el.replug_link)
            } else if (
              el.original_link === stateObject.getCommonSharingDetails.gmb_url
            ) {
              stateObject.$store.commit(
                commonTypes.SET_GMB_SHARING_LINK,
                el.replug_link
              )
            }
          })
          stateObject.setSharingMessage('Common', message)
        } else {
          const message = ''
          switch (type) {
            case 'Facebook':
              message = stateObject.getFacebookSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (
                  el.original_link === stateObject.getFacebookSharingDetails.url
                ) {
                  stateObject.setFacebookSharingLink(el.replug_link)
                  stateObject.setFetchFacebookSharingPreviewLoader(true)
                  stateObject.setFacebookCancelledLink(el.replug_link)
                  stateObject.fetchLinkPreview(el.replug_link, 'Facebook')
                }
              })
              stateObject.setSharingMessage('Facebook', message)
              break
            case 'Twitter':
              message = stateObject.getTwitterSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (
                  el.original_link === stateObject.getTwitterSharingDetails.url
                ) {
                  stateObject.setTwitterSharingLink(el.replug_link)
                  stateObject.setFetchTwitterSharingPreviewLoader(true)
                  stateObject.setTwitterCancelledLink(el.replug_link)
                  stateObject.fetchLinkPreview(el.replug_link, 'Twitter')
                }
              })
              stateObject.setSharingMessage('Twitter', message)
              break
            case 'Linkedin':
              message = stateObject.getLinkedinSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (
                  el.original_link === stateObject.getLinkedinSharingDetails.url
                ) {
                  stateObject.$store.commit(
                    linkedinSharingTypes.SET_SHARING_URL,
                    el.replug_link
                  )
                  stateObject.setFetchLinkedinSharingPreviewLoader(true)
                  stateObject.$store.commit(
                    linkedinSharingTypes.SET_SHARING_CANCELLED_PREVIEW,
                    el.replug_link
                  )
                  stateObject.fetchLinkPreview(el.replug_link, 'Linkedin')
                }
              })
              stateObject.setSharingMessage('Linkedin', message)
              break
            case 'Pinterest':
              message = stateObject.getPinterestSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (
                  el.original_link ===
                  stateObject.getPinterestSharingDetails.url
                ) {
                  stateObject.setPinterestSharingLink(el.replug_link)
                  stateObject.setFetchPinterestSharingPreviewLoader(true)
                  stateObject.setPinterestCancelledLink(el.replug_link)
                  stateObject.fetchLinkPreview(el.replug_link, 'Pinterest')
                }
              })
              stateObject.setSharingMessage('Pinterest', message)
              break
            case 'Tumblr':
              message = stateObject.getTumblrSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (
                  el.original_link === stateObject.getTumblrSharingDetails.url
                ) {
                  stateObject.setTumblrSharingLink(el.replug_link)
                  stateObject.setFetchTumblrSharingPreviewLoader(true)
                  stateObject.setTumblrCancelledLink(el.replug_link)
                  stateObject.fetchLinkPreview(el.replug_link, 'Tumblr')
                }
              })
              stateObject.setSharingMessage('Tumblr', message)
              break
            case 'Instagram':
              message = stateObject.getInstagramSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                // replacing the instagram url if it match with the original_link of the response
                if (
                  el.original_link ===
                  stateObject.getInstagramSharingDetails.url
                ) {
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_URL,
                    el.replug_link
                  )
                  stateObject.setFetchInstagramSharingPreviewLoader(true)
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_CANCELLED_PREVIEW,
                    el.replug_link
                  )
                  stateObject.fetchLinkPreview(el.replug_link, 'Instagram')
                }
              })
              stateObject.setSharingMessage('Instagram', message)
              break

            case 'Youtube':
              message = stateObject.getYoutubeSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                // replacing the youtube url if it match with the original_link of the response
                if (
                  el.original_link === stateObject.getYoutubeSharingDetails.url
                ) {
                  stateObject.$store.commit(
                    youtubeTypes.SET_SHARING_URL,
                    el.replug_link
                  )
                  stateObject.setFetchYoutubeSharingPreviewLoader(true)
                  stateObject.$store.commit(
                    youtubeTypes.SET_SHARING_CANCELLED_PREVIEW,
                    el.replug_link
                  )
                  // this.fetchLinkPreview(el.replug_link, 'Youtube')
                }
              })
              stateObject.setSharingMessage('Youtube', message)
              break
            case 'Gmb':
              let message = stateObject.getGmbSharingDetails.message
              $(links).each(function (index, el) {
                if (message.indexOf(el.original_link) > -1) {
                  message = message.replace(el.original_link, el.replug_link)
                }
                if (el.original_link === stateObject.getGmbSharingDetails.url) {
                  stateObject.$store.commit(gmb.SET_SHARING_URL, el.replug_link)
                  stateObject.$store.commit(
                    gmb.SET_FETCH_SHARING_PREVIEW_LOADER,
                    true
                  )
                  stateObject.$store.commit(
                    gmb.SET_SHARING_CANCELLED_PREVIEW,
                    el.replug_link
                  )
                  stateObject.fetchLinkPreview(el.replug_link, 'Gmb')
                }
              })
              stateObject.setSharingMessage('Gmb', message)
              break
          }
        }
      } else {
        stateObject.alertMessage('Unable to add replug link.', 'error')
      }
    },
  },
}
