<template>
  <div class="right_filter" v-if="getSearchContentType === 'youtube'">
    <video-sort-filter></video-sort-filter>
  </div>
</template>
<script>
import VideoSortFilter from '../../../filters/video/VideoSort'
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getSearchContentType'])
  },
  components: {
    VideoSortFilter
  }
}
</script>
