export default {
  state: {
    instagram_posting_option: null
  },
  actions: {},
  mutations: {
    SET_INSTAGRAM_POSTING_OPTION (state, value) {
      state.instagram_posting_option = value
    }
  },
  getters: {
    getInstagramPostingOption: (state) => {
      return state.instagram_posting_option
    }
  }
}
