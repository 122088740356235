import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  getFacebookContentURL,
  getFacebookPostLinkURL
} from '../../config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { fetchBlogAuthorizationLinksUrl } from '../../../integration/config/api-utils'
const facebookContentMixin = {
  methods: {
    ...mapMutations(['SET_TOPIC_DISABLED']),
    ...mapActions([
      'setFacebookPostID',
      'setFacebookPage',
      'setFacebookScroll',
      'setFacebookPosts',
      'setFacebookPaginationLoader',
      'setDiscoveryFacebookLoader',
      'setFacebookType',
      'setFacebookSort',
      'setFacebookDate',
      'setFacebookPreview'
    ]),
    getFacebookData ($state) {
      console.debug('Method:getFacebookData')
      this.SET_TOPIC_DISABLED(true)
      let payload = null
      if (this.getFacebookContentType === 'search') {
        payload = {
          filters: this.getFacebookContentFilter,
          type: 'search',
          search: this.getFilters.search,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        }
      } else {
        payload = {
          selection: this.getTopicSelection,
          filters: this.getFacebookContentFilter,
          type: this.getTopicType,
          topic: this.getTopic,
          curated: this.getCuratedTopic,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        }
      }
      if (payload) {
        this.postRequest(
          getFacebookContentURL,
          payload,
          (response) => {
            if (response.data.status) {
              this.SET_TOPIC_DISABLED(false)

              if (this.getFacebookPage === 0) {
                this.setFacebookPosts([])
              }

              if (!response.data.posts || response.data.posts.length === 0) {
                response.data.posts = []
              }

              if (this.getFacebookPage >= 1) {
                this.setFacebookPosts(
                  this.getFacebookPosts.concat(response.data.posts)
                )
              } else {
                this.setFacebookPosts(response.data.posts)
              }

              this.setFacebookPaginationLoader(true)

              if (response.data.posts.length === 20) {
                this.setFacebookScroll(true)
                if ($state) {
                  $state.loaded()
                }
              } else {
                this.setFacebookScroll(false)
                try {
                  if ($state) {
                    $state.complete()
                  }
                } catch (ex) {
                  console.log(ex)
                }
              }

              this.setDiscoveryFacebookLoader(false)
              this.checkComposerDragDrop()
              // let splitDates = this.getFacebookContentFilter.date.value.split(' - ')
              // try {
              //   setTimeout(function () {
              //     $('.datepicker_facebook_filter input[name="daterange"]').data('daterangepicker').setStartDate(splitDates[0].replace('T', ' '))
              //     $('.datepicker_facebook_filter input[name="daterange"]').data('daterangepicker').setEndDate(splitDates[1].replace('T', ' '))
              //   }, 500)
              // } catch (e) {
              //   console.log(e)
              // }
            } else {
              this.SET_TOPIC_DISABLED(false)
              this.setFacebookPaginationLoader(true)
              this.setDiscoveryFacebookLoader(false)
              this.alertMessage(UNKNOWN_ERROR, 'error')
            }
          },
          (response) => {
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },
    getFacebookContent () {
      console.debug('Method:getFacebookContent')
      this.setFacebookType('All')
      this.setFacebookSort('Engagement')
      this.setFacebookDate(null)
      this.resetFacebookStates()
    },
    resetFacebookStates () {
      this.setFacebookPage(0)
      this.setFacebookScroll(true)
      this.setFacebookPosts([])
      this.setFacebookPaginationLoader(false)
      this.setDiscoveryFacebookLoader(true)
      this.getFacebookData()
    },
    changeFacebookType (type) {
      this.setFacebookType(type)
      this.resetFacebookStates()
    },
    changeFacebookPostsSort (sort) {
      this.setFacebookSort(sort)
      this.resetFacebookStates()
    },
    changeFacebookDate (date) {
      if (!date) {
        date = {
          label: ' All Time',
          value: ''
        }
      }
      this.setFacebookDate(date)
      this.resetFacebookStates()
    },

    getPageImagePost (post) {
      if (post.post_id) {
        return (
          'https://graph.facebook.com/' +
          post.post_id.split('_')[0] +
          '/picture'
        )
        // return "background: url('" + postUrl + "');"
      } else if (post.page_picture) {
        return post.page_picture
        // return "background: url('" + post.page_picture + "');"
      }
      return 'http://image.prntscr.com/image/947dd2c08ba744cc92793266d5cf6655.png'
      // return "background: url('http://image.prntscr.com/image/947dd2c08ba744cc92793266d5cf6655.png');"
    },
    backgroundFacebookImageURL (post) {
      if (post && post.full_picture) {
        console.log(
          'backgroundFacebookImageURL full picture',
          post.full_picture
        )
        return post.full_picture
      } else {
        return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
      }
    },
    getFacebookEmbedHtml (source) {
      if (source.embed_html) {
        return source.embed_html
      }
      return ''
    },
    getFacebookDescription (source) {
      if (source.description) {
        return source.description
      }
      return ''
    },
    getFacebookTitle (source) {
      if (source.name) {
        return source.name
      }
      return ''
    },

    getPageName (post) {
      if (post.page_name) {
        return post.page_name
      }
      return ''
    },
    postType (post) {
      if (
        post.link &&
        post.link.includes('https://www.facebook.com/') &&
        post.link.includes('/videos/')
      ) {
        return 'Video'
      } else if (
        post.name == 'Timeline Photos' ||
        (post.link &&
          post.link.includes('https://www.facebook.com/') &&
          post.link.includes('/photos/'))
      ) {
        return 'Photo'
      } else if (post.caption != undefined && post.caption.length != 0) {
        return 'Link'
      } else if (post.name === undefined) {
        if (post.link === undefined || post.link.length <= 0) {
          return 'Status'
        } else {
          if (
            post.link &&
            post.link.includes('https://www.facebook.com/') &&
            post.link.includes('/photos/')
          ) {
            return 'Photo'
          } else if (post.caption != undefined && post.caption.length != 0) {
            return 'Link'
          }
        }
      }
    },
    showFacebookModal (post) {
      this.setFacebookPostID(post)
      this.setFacebookPreview(post)
      $('#facebook_preview_modal').modal('show')
    },

    getFacebookPostLinkURL (url, id) {
      console.debug('Method:getFacebookPostLinkURL', url, id)
      if (url.includes('facebook.com')) {
        // this.loadFacebookPreview(id)
        return url
      } else {
        console.debug('else -- Method:', url, id)
        this.postRequest(
          getFacebookPostLinkURL,
          { post_id: id },
          (response) => {
            if (response.data) {
              console.debug('else -- response', response.data)
              return response.data
            } else {
              console.debug('Exception in getFacebookPostLinkURL')
            }
          },
          (error) => {
            console.debug('Exception in fetchBlogAuthorizationLinks', error)
          }
        )
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFacebookContentType',
      'getFacebookContentFilter',
      'getTopicSelection',
      'getCuratedTopic',
      'getTopic',
      'getTopicType',
      'getFacebookPage',
      'getFacebookPosts'
    ])
  }
}
export { facebookContentMixin }
