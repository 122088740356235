import moment from 'moment'
import {
  getArticleContentURL,
  getArticlesURL,
  getKeywordsSuggestionURL,
  relatedTopicsURL,
} from '@src/modules/discovery/config/api-utils'
import proxy from '@common/lib/http-common'
import Router from '@src/router'
import axios from 'axios'
import { appContainer } from '@src/main'
import { articlesTypes, topicTypes } from './mutation-types'

const getDefaultDateRange = () => {
  return {
    label: ' Last 30 days',
    value:
      moment.utc().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
      ' - ' +
      moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
  }
}

const getDefaultRegion = () => {
  return {
    code: 'world',
    native_language: {
      code: 'en',
      languageName: 'English',
    },
    name: 'Worldwide',
    flag_position: '0 0',
  }
}

const getDefaultLanguage = () => {
  return {
    languageName: 'English',
    code: 'en',
  }
}

const getDefaultContentPreview = () => {
  return {
    title: '',
    image: '',
    url: '',
    description: '',
    author: '',
    post_date: '',
  }
}

const getDefaultArticleState = () => {
  return {
    module_type: 'search',
    discoveryType: null,
    didYouMeanText: null,
    contentTabStatus: true,
    searchText: '',
    searchRequest: [],
    suggestedKeywords: [],
    suggestedTopics: [],
    showSuggestion: true,
    similarArticles: [],
    searchContentType: 'article',
    related_topics: [],
    filters: {
      displayTopics: true,
      trending: true,
      topic: null,
      category: null,
      search: '',
      search_type: 'title',
      page_id: null,
      related: false,
      articles: {
        type: 'Any',
        sort: 'Most Shared',
        date: getDefaultDateRange(),
        region: getDefaultRegion(),
        language: getDefaultLanguage(),
        related_id: null,
        page: 1,
        limit: 20,
        domain: null,
      },
    },
    contentPreview: getDefaultContentPreview(),
    posts: [],
    scroll: true,
    loader: {
      search: {
        button: {
          search: false,
        },
        content: {
          articleLoader: false,
          discoverySearch: false,
          previewArticle: false,
          getArticle: false,
        },
      },
    },
  }
}
export default {
  state: getDefaultArticleState(),
  actions: {
    // actions workflow
    getContent({ commit, getters, actions, dispatch }, payload) {
      // set the filters depending on the type user may have selected

      switch (payload.type) {
        case 'curated':
          const topic = payload.topic //eslint-disable-line

          console.debug('topic', topic)

          if (!topic.hasOwn('id') || !topic.hasOwn('followed')) {
            let curatedTopic = null
            getters.getMyCuratedTopicsList.forEach(function (item) {
              if (item.name === topic.name) curatedTopic = item
            })
            console.debug('curatedTopic', curatedTopic)
            if (curatedTopic !== null) {
              topic.id = curatedTopic.id
              topic.followed = curatedTopic.followed
            }
          }

          // set the followed and id if there is no property to null and false.
          topic.followed = Object.hasOwn(topic, 'followed')
            ? topic.followed
            : false
          topic.id = Object.hasOwn(topic, 'id') ? topic.id : null

          commit(articlesTypes.SET_SEARCH_ARTICLES_TOPIC, topic)
          commit(articlesTypes.SET_ARTICLES_MODULE_TYPE, 'curated')
          break
        case 'search':
          commit(articlesTypes.SET_SEARCH_ARTICLES_TOPIC, null)
          commit('SET_TOPIC_MODULE', 'content')
          payload.keyword = Object.hasOwn(payload, 'keyword')
            ? payload.keyword
            : null
          commit(articlesTypes.SET_SEARCH_TEXT, payload.keyword)
          commit(articlesTypes.SET_SEARCH_KEYWORD, payload.keyword)
          commit(articlesTypes.SET_ARTICLES_MODULE_TYPE, 'search')
          break
      }

      // default states to be set

      commit(articlesTypes.SET_SEARCH_DISPLAY_TOPICS, false)
      // commit(articlesTypes.SET_SEARCH_CONTENT_TYPE, 'content')
      commit(articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS, false)
      commit(articlesTypes.SET_SEARCH_BUTTON_LOADER, true)
      commit(articlesTypes.SET_CONTENT_LOADER, true)
      commit(articlesTypes.SET_ARTICLES_PAGE, 1)
      commit(articlesTypes.SET_DOMAIN, null)

      // applying the filter if the user have supplied any one of those.

      payload.filter = Object.hasOwn(payload, 'filter')
        ? payload.filter
        : { key: 'default', value: 'default' }

      switch (payload.filter.key) {
        case 'sort':
          commit(articlesTypes.SET_ARTICLES_SORT, payload.filter.value)

          if (payload.filter.value.toLowerCase() === 'trending') {
            commit(articlesTypes.SET_ARTICLES_DATE_RANGE, {
              label: 'Last 7 days',
              value:
                moment.utc().subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss') +
                ' - ' +
                moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
            })
            commit(articlesTypes.SET_TRENDING_STATUS, true)
          } else {
            commit(articlesTypes.SET_ARTICLES_DATE_RANGE, getDefaultDateRange())
            commit(articlesTypes.SET_TRENDING_STATUS, false)
          }

          break
        case 'date':
          commit(articlesTypes.SET_ARTICLES_DATE_RANGE, payload.filter.value)
          break
        case 'region':
          commit(articlesTypes.SET_ARTICLES_REGION, payload.filter.value)
          commit(articlesTypes.SET_ARTICLES_LANGUAGE)
          break
        case 'language':
          commit(articlesTypes.SET_ARTICLES_LANGUAGE, payload.filter.value)
          break
        case 'type':
          commit(articlesTypes.SET_ARTICLES_TYPE, payload.filter.value)
          break
        default:
          commit(articlesTypes.SET_ARTICLES_TYPE)
          commit(articlesTypes.SET_ARTICLES_SORT)
          commit(articlesTypes.SET_ARTICLES_DATE_RANGE)
          commit(articlesTypes.SET_ARTICLES_LANGUAGE)
          commit(articlesTypes.SET_ARTICLES_REGION)
          break
      }

      return true
    },

    articles({ commit, getters, actions, dispatch }, $state = null) {
      commit(articlesTypes.SET_RELATED_TOPICS, [])
      commit(topicTypes.SET_POSTS_TOTAL, 0)
      commit(articlesTypes.SET_DID_YOU_MEAN_TEXT, null)
      commit(articlesTypes.SET_CONTENT_TAB_STATUS, false)
      const filters = getters.getFilters
      let payload = {
        articles: {
          sort: filters.articles.sort,
          page: filters.articles.page,
          limit: filters.articles.limit,
        },
        folder_id: null,
        archived: null,
      }

      if (!payload.archived && !payload.folder_id) {
        payload = {
          topic: filters.topic,
          category: filters.category,
          search: filters.search,
          search_type: filters.search_type,
          related: filters.related,
          articles: filters.articles,
        }
      }

      // set the posts to null if the articles page is 1

      if (filters.articles.page === 1) {
        commit(articlesTypes.SET_POSTS, [])
      }

      return proxy
        .post(getArticlesURL, {
          filters: payload,
          route: Router.history.current.name,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          module_type: getters.getArticlesModuleType,
        })
        .catch(() => {
          commit(articlesTypes.SET_CONTENT_TAB_STATUS, true)
          commit(articlesTypes.SET_SEARCH_BUTTON_LOADER, false)
          commit(articlesTypes.SET_CONTENT_LOADER, false)
        })
        .then((resp) => {
          // set the default states

          commit(articlesTypes.SET_CONTENT_TAB_STATUS, true)
          commit(articlesTypes.SET_SEARCH_BUTTON_LOADER, false)
          commit(articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS, false)
          commit(articlesTypes.SET_SEARCH_DISPLAY_TOPICS, false)

          // if the response of data is false, show the relevant suggestion if any,
          if (
            resp.data.status === false &&
            Object.hasOwn(resp.data, 'relevant_suggestion')
          ) {
            commit(
              articlesTypes.SET_DID_YOU_MEAN_TEXT,
              resp.data.relevant_suggestion
            )
          }

          // add show full property
          resp.data.posts.forEach((item, index) => {
            item._source.showfull = false
          })

          // set the posts

          if (filters.articles.page > 1) {
            console.debug('SET_POSTS', getters.getPosts.concat(resp.data.posts))
            commit(
              articlesTypes.SET_POSTS,
              getters.getPosts.concat(resp.data.posts)
            )
          } else {
            // related article stories
            if (getters.getRelatedStatus) {
              const parentPost = getters.getPosts[0]
              commit(articlesTypes.SET_POSTS, [parentPost])
              commit(
                articlesTypes.SET_POSTS,
                getters.getPosts.concat(resp.data.posts)
              )
            } else {
              console.debug('SET_POSTS', resp.data.posts)
              commit(articlesTypes.SET_POSTS, resp.data.posts)
            }
          }

          // loaders and other things to be set.
          commit(topicTypes.SET_POSTS_TOTAL, resp.data.total)
          commit(articlesTypes.SET_CONTENT_LOADER, false)
          commit(articlesTypes.SET_ARTICLES_LOADER, true)

          // if the posts are equal to 20.

          if (resp.data.posts.length === 20) {
            commit(articlesTypes.SET_SCROLL, true)
            try {
              if ($state) {
                $state.loaded()
              }
            } catch (ex) {
              console.debug(ex)
            }
          } else {
            commit(articlesTypes.SET_SCROLL, false)
            if (getters.getDiscoveryType === 'Curation') {
              commit(articlesTypes.SET_ARTICLES_LOADER, false)
            } else {
              if ($state) {
                $state.complete()
              }
            }
          }

          dispatch('relatedTopicSuggestions')
          return true
        })
    },

    searchSuggestions({ commit, getters, dispatch }) {
      console.log(
        'Autocomplete characters are greater than 3 and perfomring search...'
      )
      commit(articlesTypes.SET_SEARCH_SUGGESTED_KEYWORDS, [])
      commit(articlesTypes.SET_SEARCH_SUGGESTED_TOPICS, [])

      // cancel previously sent requests

      const CancelToken = axios.CancelToken
      const searchRequests = getters.getSearchRequests
      for (let i = 0; i < searchRequests.length; i++) {
        const remove = searchRequests[i]
        remove()
      }

      // set the suggestion section
      commit(articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS, true)
      commit(articlesTypes.SET_SEARCH_ARTICLES_TOPIC, null)
      let cancel

      // for search content suggestions

      axios
        .post(
          getKeywordsSuggestionURL,
          {
            keyword: getters.getFilters.search,
          },
          { headers: { Authorization: 'Bearer ' + getters.getJWTToken } },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          }
        )
        .then((resp) => {
          let results = []
          // if (resp.data.keywords && resp.data.keywords.CompleteSuggestion) {
          //   resp.data.keywords.CompleteSuggestion.forEach(function (element) {
          //     // for only 5 keywords
          //     if (results.length < 7) {
          //       results.push(element.suggestion.attributes.data)
          //     }
          //   }, this)
          // }
          if (resp.data.keywords) results = resp.data.keywords

          if (resp.data.topics.length > 0) {
            const topicsList = resp.data.topics.filter((item, index) => {
              if (index < 5) return true
            })
            commit(articlesTypes.SET_SEARCH_SUGGESTED_TOPICS, topicsList)
          }

          commit(articlesTypes.SET_SEARCH_SUGGESTED_KEYWORDS, results)
        })
        .catch(() => {
          commit(articlesTypes.SET_SEARCH_SUGGESTED_KEYWORDS, [])
          commit(articlesTypes.SET_SEARCH_SUGGESTED_TOPICS, [])
          commit(articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS, false)
        })

      commit(
        articlesTypes.SET_SEARCH_REQUESTS,
        getters.getSearchRequests.concat(cancel)
      )
    },

    relatedTopicSuggestions({ commit, getters }) {
      return proxy
        .post(relatedTopicsURL, {
          keyword: getters.getFilters.search,
        })
        .catch(() => {
          commit(articlesTypes.SET_RELATED_TOPICS, [])
        })
        .then((resp) => {
          resp && resp.data.status
            ? commit(articlesTypes.SET_RELATED_TOPICS, resp.data.topics)
            : commit(articlesTypes.SET_RELATED_TOPICS, [])
        })
    },
    articlePreview({ commit, getters, dispatch }, payload) {
      console.debug('articlePreview', payload)
      commit(articlesTypes.SET_ARTICLE_PREVIEW_CONTENT_LOADER, true)
      // $('#article_preview_modal').modal('show')
      appContainer.$bvModal.show('article_preview_modal')
      proxy
        .post(getArticleContentURL, {
          id: payload.id,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch(() => {
          commit(articlesTypes.SET_ARTICLE_PREVIEW_CONTENT_LOADER, false)
        })
        .then((resp) => {
          if (resp && resp.data && resp.data.status) {
            resp.data.post.index_id = payload.index
            commit(articlesTypes.SET_ARTICLE_PREVIEW_CONTENT, resp.data.post)
          }
          commit(articlesTypes.SET_ARTICLE_PREVIEW_CONTENT_LOADER, false)
        })
    },

    setSearch({ commit }, search) {
      commit('setSearch', search)
    },
    setSearchType({ commit }, searchType) {
      commit('setSearchType', searchType)
    },
    setSearchText({ commit }, search) {
      commit('setSearchText', search)
    },

    // showing the dropdown menu for the related keywords and domains suggestion
    setArticleSuggestion({ commit }, value) {
      commit('setArticleSuggestion', value)
    },
    setTrendingStatus({ commit }, trending) {
      commit('setTrendingStatus', trending)
    },

    // search content type
    setSearchContentType({ commit }, type) {
      commit('setSearchContentType', type)
    },

    // Articles start

    setArticlesSort({ commit }, sort) {
      commit('setArticlesSort', sort)
    },

    setArticlesPage({ commit }, page) {
      commit('setArticlesPage', page)
    },

    setSearchArticlesTopic({ commit }, topic) {
      commit('setSearchArticlesTopic', topic)
    },
    setScroll({ commit }, scroll) {
      commit('setScroll', scroll)
    },
    setPosts({ commit }, posts) {
      commit('setPosts', posts)
    },
    setDomain({ commit }, id) {
      commit('setDomain', id)
    },
    setRelatedStatus({ commit }, status) {
      commit('setRelatedStatus', status)
    },
    setContentPreview({ commit }, content) {
      commit('setContentPreview', content)
    },
    setSearchDisplayTopics({ commit }, status) {
      commit('setSearchDisplayTopics', status)
    },

    setSimilarArticles({ commit }, stories) {
      commit('setSimilarArticles', stories)
    },
    // Articles end

    // loaders
    setPreviewArticleContentLoader({ commit }, loader) {
      commit('setPreviewArticleContentLoader', loader)
    },
    setNoMoreResultArticle({ commit }, tab) {
      commit('setNoMoreResultArticle', tab)
    },

    setSearchButtonLoader({ commit }, status) {
      commit('setSearchButtonLoader', status)
    },
    setContentLoader({ commit }, status) {
      commit('setContentLoader', status)
    },

    resetArticleSearch({ commit }) {
      commit('resetArticleSearch')
    },
  },
  mutations: {
    setSearch(state, search) {
      state.filters.search = search
    },

    setSearchType(state, searchType) {
      state.filters.search_type = searchType
    },

    setSearchText(state, search) {
      state.searchText = search
    },

    setArticleSuggestion(state, value) {
      state.showSuggestion = value
    },
    setTrendingStatus(state, trending) {
      state.filters.trending = trending
    },

    setContentPreview(state, content) {
      state.contentPreview = content
    },

    setSearchContentType(state, type) {
      state.searchContentType = type
    },
    // Articles start

    setArticlesSort(state, sort) {
      state.filters.articles.sort = sort
    },

    setArticlesPage(state, page) {
      state.filters.articles.page = page
    },

    setSearchArticlesTopic(state, topic) {
      state.filters.topic = topic
    },
    setScroll(state, scroll) {
      state.scroll = scroll
    },
    setPosts(state, posts) {
      state.posts = posts
    },
    setDomain(state, id) {
      state.filters.articles.domain = id
    },
    setSearchDisplayTopics(state, status) {
      state.filters.displayTopics = status
    },
    // Articles end

    // loaders

    setPreviewArticleContentLoader(state, status) {
      state.loader.search.content.previewArticle = status
    },
    setSearchButtonLoader(state, status) {
      state.loader.search.button.search = status
    },
    setContentLoader(state, status) {
      state.loader.search.content.discoverySearch = status
    },

    setSimilarArticles(state, articles) {
      state.similarArticles = articles
    },
    resetArticleSearch(state) {
      state = getDefaultArticleState()
    },

    // new states

    [articlesTypes.SET_SEARCH_DISPLAY_TOPICS](state, value) {
      state.filters.displayTopics = value
    },
    [articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS](state, value) {
      state.showSuggestion = value
    },
    [articlesTypes.SET_SEARCH_BUTTON_LOADER](state, value) {
      state.loader.search.button.search = value
    },
    [articlesTypes.SET_ARTICLES_TYPE](state, value) {
      state.filters.articles.type = value || 'Any'
    },
    [articlesTypes.SET_ARTICLES_SORT](state, value) {
      state.filters.articles.sort = value || 'Most Shared'
    },
    [articlesTypes.SET_ARTICLES_DATE_RANGE](state, value) {
      state.filters.articles.date = value || getDefaultDateRange()
    },
    [articlesTypes.SET_ARTICLES_LANGUAGE](state, value) {
      state.filters.articles.language = value || {
        code: 'en',
        languageName: 'English',
      }
    },
    [articlesTypes.SET_ARTICLES_REGION](state, value) {
      state.filters.articles.region = value || getDefaultRegion()
    },
    [articlesTypes.SET_SEARCH_ARTICLES_TOPIC](state, value) {
      state.filters.topic = value
    },
    [articlesTypes.SET_SEARCH_TEXT](state, value) {
      state.searchText = value
    },
    [articlesTypes.SET_SEARCH_KEYWORD](state, value) {
      state.filters.search = value
    },
    [articlesTypes.SET_CONTENT_LOADER](state, value) {
      state.loader.search.content.discoverySearch = value
    },
    [articlesTypes.SET_ARTICLES_PAGE](state, value) {
      state.filters.articles.page = value
    },
    [articlesTypes.SET_DOMAIN](state, value) {
      state.filters.domain = value
    },
    [articlesTypes.SET_RELATED_TOPICS](state, value) {
      state.related_topics = value
    },
    [articlesTypes.SET_DID_YOU_MEAN_TEXT](state, value) {
      state.didYouMeanText = value
    },
    RESET_ARTICLE_SEARCH(state) {
      state = getDefaultArticleState()
    },

    [articlesTypes.SET_CONTENT_TAB_STATUS](state, status) {
      state.contentTabStatus = status
    },

    [articlesTypes.SET_ARTICLES_LOADER](state, status) {
      state.loader.search.content.articleLoader = status
    },
    [articlesTypes.SET_SCROLL](state, status) {
      state.scroll = status
    },
    [articlesTypes.SET_SEARCH_CONTENT_TYPE](state, type) {
      state.searchContentType = type
    },
    [articlesTypes.SET_SEARCH_REQUESTS](state, request) {
      state.searchRequest = request
    },
    [articlesTypes.SET_TRENDING_STATUS](state, value) {
      state.filters.trending = value
    },
    [articlesTypes.SET_ARTICLES_MODULE_TYPE](state, value) {
      state.module_type = value
    },

    [articlesTypes.SET_SEARCH_SUGGESTED_KEYWORDS](state, keywords) {
      state.suggestedKeywords = keywords
    },
    [articlesTypes.SET_SEARCH_SUGGESTED_TOPICS](state, topics) {
      state.suggestedTopics = topics
    },
    [articlesTypes.SET_ARTICLE_PREVIEW_CONTENT_LOADER](state, loader) {
      state.loader.search.content.previewArticle = loader
    },
    [articlesTypes.SET_ARTICLE_PREVIEW_CONTENT](state, value) {
      state.contentPreview = value
    },

    SET_POSTS(state, value) {
      state.posts = value
    },
  },
  getters: {
    getContentTabStatus: (state) => {
      return state.contentTabStatus
    },
    getDidYouMeanText: (state) => {
      return state.didYouMeanText
    },
    getSearchSuggestedkeywords: (state) => {
      return state.suggestedKeywords
    },
    getSearchSuggestedTopics: (state) => {
      return state.suggestedTopics
    },
    getSearchRequests: (state) => {
      return state.searchRequest
    },
    getArticleSuggestion: (state) => {
      return state.showSuggestion
    },
    getTrendingStatus: (state) => {
      return state.filters.trending
    },
    getFilters: (state) => {
      return state.filters
    },
    getDiscoveryType: (state) => {
      return state.discoveryType
    },

    getSearchContentType: (state) => {
      return state.searchContentType
    },
    // Articles start

    getArticlesType: (state) => {
      return state.filters.articles.type
    },
    getArticlesSort: (state) => {
      return state.filters.articles.sort
    },
    getArticlesPage: (state) => {
      return state.filters.articles.page
    },
    getArticlesRegion: (state) => {
      return state.filters.articles.region
    },
    getArticlesLanguage: (state) => {
      return state.filters.articles.language
    },
    getScroll: (state) => {
      return state.scroll
    },
    getPosts: (state) => {
      return state.posts
    },
    getContentPreview: (state) => {
      return state.contentPreview
    },
    getArticlesModuleType: (state) => {
      return state.module_type
    },

    // related posts getters

    getRelatedId: (state) => {
      return state.filters.articles.related_id
    },

    getRelatedStatus: (state) => {
      return state.filters.articles.related_id
    },

    getRelatedTopics: (state) => {
      return state.related_topics
    },
    getSimilarArticles: (state) => {
      return state.similarArticles
    },
    // Articles End

    getSearchLoader: (state) => {
      return state.loader.search
    },
    getArticleLoader: (state) => {
      return state.loader.search.content.articleLoader
    },
  },
}
