import loaderModule from './loader'
import articleRecipeModule from './recipes/article'
import scheduleRecipeModule from './recipes/schedule'
import videoRecipeModule from './recipes/video'
import rssRecipeModule from './recipes/rss'
import evergreenRecipeModule from './recipes/evergreen'
import csvRecipeModule from './recipes/csv'
import proxy from '@common/lib/http-common'
import { fetchNextPostingUrl } from '../config/api-utils'
import { article, video, loaders } from './recipes/mutation-types'
import integrations from '@common/lib/integrations'
import { scheduleOptionsValidation } from '@common/lib/automation'

const automation = {
  state: {
    automationCounts: {
      articleSocialAutomation: 0,
      articleBlogAutomation: 0,
      videoSocialAutomation: 0,
      videoBlogAutomation: 0,
      evergreenAutomation: 0,
      bulkAutomation: 0,
      rssAutomation: 0,
      total: 0
    },
    fetch_next_posting_loader: false
  },
  actions: {
    fetchAutomationNextPosting (
      { commit, getters, rootGetters, dispatch },
      route
    ) {
      console.debug('Action::fetchAutomationNextPosting')
      if (route === 'saveEvergreenAutomationOptimization') return false

      let automation = null
      let automation_type = null
      if (
        route === 'saveArticleBlogAutomation' ||
        route === 'saveArticleSocialAutomation'
      ) {
        automation_type = 'ArticleAutomation'
        automation = getters.getArticleAutomationDetails
      }
      if (
        route === 'saveVideoBlogAutomation' ||
        route === 'saveVideoSocialAutomation'
      ) {
        automation_type = 'VideoAutomation'
        automation = getters.getVideoAutomationDetails
      }

      if (automation) {
        const response = scheduleOptionsValidation(
          getters.getAutomationScheduleOptions,
          automation
        )
        if (!response.status) {
          console.debug('response', response)
          automation_type === 'ArticleAutomation'
            ? commit(article.SET_AUTOMATION_NEXT_POSTING, -1)
            : commit(video.SET_AUTOMATION_NEXT_POSTING, -1)
          return false
        }

        const payload = {
          id: automation.id,
          type: automation_type,
          prefetch_days_limit: automation.prefetch_days_limit,
          last_execution: automation.last_execution,
          schedule_options: getters.getAutomationScheduleOptions,
          workspace_id: getters.getWorkspaces.activeWorkspace._id
        }
        if (automation.id) {
          payload.remove_posts = automation.remove_posts
        }
        if (getters.getAutomationScheduleOptions.time_type === 'Queue') {
          payload.account_selection = getters.getAccountSelection
        }
        commit(loaders.SET_FETCH_NEXT_POSTING_LOADER, true)
        return proxy
          .post(fetchNextPostingUrl, payload)
          .then((response) => {
            let count = 0
            if (response.data.status) {
              if (getters.getAutomationScheduleOptions.time_type !== 'Queue') {
                count = response.data.next_posting.length
              }
              if (getters.getAutomationScheduleOptions.time_type === 'Queue') {
                const names = integrations.socialPlatformNames()
                names.forEach(function (integration) {
                  if (
                    Object.keys(response.data.next_posting).includes(
                      integration
                    )
                  ) {
                    response.data.next_posting[integration].forEach(function (
                      element,
                      index
                    ) {
                      count += element.length
                    })
                  }
                })
              }
            }
            automation_type === 'ArticleAutomation'
              ? commit(article.SET_AUTOMATION_NEXT_POSTING, count)
              : commit(video.SET_AUTOMATION_NEXT_POSTING, count)
            commit(loaders.SET_FETCH_NEXT_POSTING_LOADER, false)
          })
          .catch((error) => {
            console.debug('Exception in fetchAutomationNextPosting', error)
            automation_type === 'ArticleAutomation'
              ? commit(article.SET_AUTOMATION_NEXT_POSTING, 0)
              : commit(video.SET_AUTOMATION_NEXT_POSTING, 0)
            commit(loaders.SET_FETCH_NEXT_POSTING_LOADER, false)
          })
      }
    },

    setAutomationCounts ({ commit }, automationCounts) {
      commit('setAutomationCounts', automationCounts)
    }
  },
  mutations: {
    [loaders.SET_FETCH_NEXT_POSTING_LOADER] (state, loader) {
      state.fetch_next_posting_loader = loader
    },

    setAutomationCounts (state, automationCounts) {
      if (automationCounts) {
        state.automationCounts = automationCounts
      } else {
        state.automationCounts = {
          articleSocialAutomation: 0,
          articleBlogAutomation: 0,
          videoSocialAutomation: 0,
          videoBlogAutomation: 0,
          evergreenAutomation: 0,
          bulkAutomation: 0,
          rssAutomation: 0,
          total: 0
        }
      }
    }
  },
  getters: {
    getAutomationCounts: (state) => {
      return state.automationCounts
    },
    getFetchNextPostingLoader: (state) => {
      return state.fetch_next_posting_loader
    }
  }
}

export const automationStore = {
  modules: {
    automation: automation,
    loader: loaderModule,
    schedule: scheduleRecipeModule,
    article: articleRecipeModule,
    video: videoRecipeModule,
    rss: rssRecipeModule,
    evergreen: evergreenRecipeModule,
    csv: csvRecipeModule
  }
}
