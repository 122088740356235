<template>
  <chart-render
    :id="'published_posts_with_engagement_highlight'"
    :options="published()"
    :title="'Published Posts With Engagement'"
    :ref_value="'published_posts_with_engagement'"
    :is_content_available="isContentNotAvailable"
    :tooltip_message="tooltip_message"
  ></chart-render>
</template>

<script>
import { NO_CHART_DATA } from '@common/constants/messages'
import ChartRender from '../ChartRender'
import {
  darkBlackColor,
  darkBlueColor,
  splineChart,
  splineLegend,
  splinePlotOptions,
  splineTooltip,
  splineXAxis
} from '../helper'
import moment from 'moment'
export default {
  components: {
    ChartRender
  },
  props: {
    posts: {},
    engagement_message: {
      default: 'Avg Engagements (Likes + RTs/Post)'
    },
    width: {
      default: null
    },
    tooltip_message: {
      default: null
    }
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    countTotalPosts () {
      let count = 0
      this.posts.buckets.forEach((element) => {
        count += element.doc_count ? element.doc_count : 0
      })
      return count
    },
    isContentNotAvailable () {
      return this.posts.buckets.length > 0 && this.countTotalPosts === 0
    }
  },
  methods: {
    published () {
      const posts = []
      const engagement = []
      this.posts.buckets.forEach((element) => {
        const parsedDate = moment(element.key_as_string).unix() * 1000
        const totalEngagement = element.total_engagement.value
          ? Math.round(element.total_engagement.value)
          : 0
        const docCount = element.doc_count ? element.doc_count : 0
        engagement.push([parsedDate, totalEngagement])
        posts.push([parsedDate, docCount])
      })
      // let docCount = element.doc_count || 0
      // let averageStats = element.avg_stats.value || 0
      // articlesPublishedArrayPostsCount.push([parsedDate, docCount])

      if (this.isContentNotAvailable) {
        engagement.forEach((item) => {
          item[1] = this.randomNumber()
        })
        posts.forEach((item) => {
          item[1] = this.randomNumber()
        })
      }
      const plot = splinePlotOptions()
      plot.series.marker.lineColor = '#3a4557'
      plot.series.marker.lineWidth = 3
      plot.series.marker.enabled = true

      return {
        credits: {
          enabled: false
        },
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: splineTooltip(),
        chart: {
          // type: 'areaspline',
          type: 'spline',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: splineChart('#published_posts_with_engagement_highlight')
            .events,
          width: this.width
        },
        legend: splineLegend(),

        colors: ['#6c8aff', '#3a4557'],
        title: false,
        xAxis: splineXAxis(),
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Posts',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: this.engagement_message,
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
          }
        ],
        plotOptions: plot,
        series: [
          {
            pointWidth: 15,
            type: 'column',
            name: 'Number of Posts',
            data: posts,
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            color: darkBlueColor()
          },
          {
            name: this.engagement_message,
            data: engagement,
            marker: {
              // enabled: false,
              lineWidth: 2,
              lineColor: '#3a4557',
              fillColor: '#ffffff'
            },
            yAxis: 1,
            color: darkBlackColor()
          }
        ]
      }

      /** {
          series: {
            pointWidth: 25,
            borderWidth:
                0,
            lineWidth:
                3,
            lineColor:
                '#00C4FF'
          }
        }

         marker: {
              // enabled: false,
              lineWidth: 2,

              lineColor: '#3eb8ff',
              fillColor: '#ffffff'
            }
         **/
    }
  }
}
</script>

<style scoped></style>
