<template>
  <!--  Modal Wrapper-->
  <ModalWrapper
    modal_heading="AI Images"
    :modal-close="
      () => {
        toggleHelpDropdown(false)
      }
    "
  >
    <!--    Modal Body-->
    <template v-slot>
      <!--      left side -->
      <SearchPrompt
        :prompt-details="promptDetails"
        :generate-images="generateImages"
        :prompt-errors="promptErrors"
        :limits="limits"
        :is-generating="isGenerating"
        :media-uploading="mediaUploading"
      />
      <!-- right side -->
      <ImagesListing
        :ai-images="aiImages"
        :is-generating="isGenerating"
        :upload-images-from-link="uploadImagesFromLink"
        :loading-completed="loadingCompleted"
        :completed="completed"
        :media-uploading="mediaUploading"
      />
    </template>
  </ModalWrapper>
</template>

<script>
// libraries
import { defineComponent, ref, onMounted, computed, inject } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'

// components
import ModalWrapper from '@common/components/modal-wrapper/ModalWrapper.vue'
import {
  ImagesListing,
  SearchPrompt,
} from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/index'

// constants
import { EventBus } from '@common/lib/event-bus'
import { aiImageGenerationApiUrl } from '@src/config/api-utils'

export default defineComponent({
  name: 'ImageGeneratorModal',
  components: { ModalWrapper, ImagesListing, SearchPrompt },
  props: {
    uploadImagesFromLink: {
      type: Function,
      default: () => {},
    },
  },
  setup: function () {
    const store = useStore()
    const root = inject('root')
    const limits = {
      quantity: {
        min: 1,
        max: 3,
      },
      promptLength: 1000,
    }
    const aiImages = ref([])
    const isGenerating = ref(false)
    const completed = ref(false)
    const mediaUploading = ref(false)
    const oldComposer = ref(false)

    const promptDetails = ref({
      prompt: '',
      resolution: '512x512',
      quantity: '3',
    })

    // mounted
    onMounted(() => {
      EventBus.$on('imageGeneratorModal', ({ isOldComposer }) => {
        root.$bvModal.show('image_generator_modal')
        oldComposer.value = isOldComposer
        toggleHelpDropdown(true)
      })
      EventBus.$on('clear-modal-state', () => {
        clearState()
      })
    })

    // computed properties
    /**
     *  When value of prompt details changes it will validate input
     * @type {ComputedRef<{quantity, prompt: boolean}>}
     */
    const promptErrors = computed(() => ({
      prompt: promptDetails.value.prompt === '',
      quantity: !(
        promptDetails.value.quantity > 0 && promptDetails.value.quantity < 4
      ),
    }))

    // methods
    /**
     * generate images on the basis of prompt
     * @returns {Promise<void>}
     */
    const generateImages = async () => {
      // if prompt is invalid then it will return
      if (promptErrors.value.quantity || promptErrors.value.prompt) {
        return
      }
      const payload = {
        workspace_id: store.getters.getActiveWorkspace._id,
        prompt: promptDetails.value.prompt,
        images_count: parseInt(promptDetails.value.quantity),
        size: promptDetails.value.resolution,
      }
      aiImages.value = []
      isGenerating.value = true
      try {
        const response = await proxy.post(aiImageGenerationApiUrl, payload)
        isGenerating.value = false
        completed.value = false
        if (!response.data.status) {
          await store.dispatch('toastNotification', {
            message: response?.data?.message || 'Unable to generate image',
            type: 'error',
          })
          return
        }
        store.getters.getPlan.used_limits.image_generation_credit =
          response?.data?.limits?.used
        if (response?.data?.images?.length > 0) {
          aiImages.value = response.data?.images?.map((item) => ({
            ...item,
            select: false,
          }))
        }
      } catch (error) {
        completed.value = false
        isGenerating.value = false
        await root.$store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }
    /**
     * reinitialize states
     */
    const clearState = () => {
      Object.assign(promptDetails.value, {
        prompt: '',
        resolution: '512x512',
        quantity: '3',
      })
      isGenerating.value = false
      aiImages.value = []
      completed.value = false
    }
    const loadingCompleted = () => {
      completed.value = true
    }
    /**
     * toggle help dropdown and GoSquared Chat plugin on Opening
     */
    const toggleHelpDropdown = (toggle = false) => {
      const ele = document.getElementById('help_dropdown_fixed')
      if (ele) {
        toggle ? ele.classList.add('hidden') : ele.classList.remove('hidden')
      }
      const element = document.getElementById('gs')
      if (element)
        toggle
          ? element.classList.add('hidden')
          : element.classList.remove('hidden')
    }
    return {
      promptDetails,
      generateImages,
      promptErrors,
      limits,
      isGenerating,
      aiImages,
      loadingCompleted,
      completed,
      mediaUploading,
      clearState,
      toggleHelpDropdown,
      oldComposer,
    }
  },
})
</script>
