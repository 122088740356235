import { mapGetters, mapActions } from 'vuex'
import {
  loadersTypes,
  publish,
  sharingTypes,
  social,
} from '@src/modules/publish/store/states/mutation-types'
import { rssTypes } from '@src/modules/automation/store/recipes/mutation-types'
import { getRssFeedAutoCompleteURL } from '@src/modules/discovery/config/api-utils'
import debounce from 'lodash/debounce'
import axios from 'axios'
import { fetchSharingHashTagsSuggestionsUrl } from '@src/modules/publish/config/api-utils'
// import { UNKNOWN_ERROR } from '@src/modules/common/constants/messages'

export const rssAutomationMixin = {
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['setEditQueueStatus']),
    createRssAutomationProcess() {
      const result = this.checkSubscriptionLimits('automations')
      if (result) {
        return
      }
      this.$store.commit(social.RESET_ACCOUNT_SELECTION)
      this.setEditQueueStatus(false)
      this.$store.commit(rssTypes.RESET_RSS_AUTOMATION)
      this.$store.commit(publish.RESET_PUBLISH_HASHTAG_VALUE)
      this.$store.commit(publish.RESET_PUBLISH_REPLUG_VALUE)
      this.$router.push({
        name: 'saveRssAutomation',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    },
    asyncIncludeRssResults: debounce(function (query) {
      console.debug('Method:asyncLimitDomainResults:_.debounce')

      if (this.isValidUrl(query)) {
        const stateObject = this
        const CancelToken = axios.CancelToken
        for (
          let i = 0;
          i < stateObject.getAxiosRequests.fetchRssSuggestions.length;
          i++
        ) {
          const remove = stateObject.getAxiosRequests.fetchRssSuggestions[i]
          remove()
        }

        axios.defaults.headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getJWTToken,
        }
        let cancel
        this.include_domains_suggestion_list = []
        axios
          .post(
            getRssFeedAutoCompleteURL,
            { keyword: query },
            {
              cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c
              }),
            }
          )
          .then(
            (response) => {
              if (response.data.status && response.data.rss_links)
                this.include_domains_suggestion_list = response.data.rss_links
            },
            (response) => {
              console.debug('Exception in asyncIncludeGetFeeds ', response)
            }
          )

        stateObject.getAxiosRequests.fetchRssSuggestions.push(cancel)

        // // this.isIncludeDomainsLoading = false
        // // this.includeDomainsLoader = true
        // this.include_domains_suggestion_list = []
        // this.postRequest(getRssFeedAutoCompleteURL, { 'keyword': query },
        //   response => {
        //     if (response.data.status && response.data.rss_links) this.include_domains_suggestion_list = response.data.rss_links
        //     // this.isIncludeDomainsLoading = true
        //     // this.includeDomainsLoader = false
        //   },
        //   response => {
        //     console.debug('Error in asyncIncludeGetFeeds:_.debounce', response)
        //     this.alertMessage('Unknown error occurred', 'error')
        //   }
        // )
        //
        // console.debug(query)
      }
    }, 250),
  },
}
