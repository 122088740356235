<template>
  <div>
    <div class="head clear">
      <div class="picture_block picture_block_background">
        <div
          v-if="post.posted_by.image"
          class="img"
          v-bind:style="
            'background: url(' +
            post.posted_by.image +
            ');height:40px !important;width:40px !important;'
          "
        ></div>
        <div
          v-else
          class="img"
          style="
            background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
            height: 40px !important;
            width: 40px !important;
          "
        ></div>
      </div>
      <div class="text_block">
        <p class="text head_text">{{ post.posted_by.name }}</p>
        <p class="sub_text"
          >@{{ post.posted_by.user_name }}
          <span class="tag">
            . {{ commonMethods.timesAgo(post.created_time) }}</span
          ></p
        >
      </div>
    </div>
    <div class="content">
      <div class="w-100 float-none text_block">
        <div class="preview_desc">
          <p class="desc" v-html="stringToHtmlTwitter(post.tweet_message)"></p>
        </div>

        <div
          v-if="
            post.tweet_attachment != null && post.tweet_attachment.length > 0
          "
        >
          <div
            v-if="post.tweet_attachment[0].type === 'photo'"
            class="preview_images"
          >
            <div :class="[getImagesDist(post.tweet_attachment.length)]">
              <template v-for="attachment in post.tweet_attachment">
                <div
                  class="img"
                  :key="attachment.media"
                  v-bind:style="'background: url(' + attachment.media + ')'"
                >
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped></style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
export default {
  props: ['post'],
  components: {},
  data () {
    return {
      commonMethods: commonMethods
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  methods: {
    ...mapActions([]),
    getImagesDist (attachmentSize) {
      switch (attachmentSize) {
        case 1:
          return 'single_image'
        case 2:
          return 'multiple_images two_images'
        case 3:
          return 'multiple_images three_images'
        case 4:
          return 'multiple_images four_images'
        default:
          return 'messed yp --> ' + attachmentSize
      }
    }
  },
  watch: {}
}
</script>
