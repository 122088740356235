<style lang="less">
@import '../../../../../assets/less/third-party/slick.less';
</style>
<script>
import 'slick-carousel/slick/slick.css'
import draggable from 'vuedraggable'
import EvergreenBulk from './EvergreenBulk'
import { mapGetters, mapActions } from 'vuex'
import { evergreen } from '../../../store/automation-mutation-type'
import { parseDescription } from '@common/lib/helper'
import { gmb, social } from '../../../../publish/store/states/mutation-types'

export default {
  components: {
    Slick: () => import('vue-slick'),
    EvergreenBulk,
    draggable
  },

  data () {
    return {
      slickOptions: {
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        asNavFor: this.$refs.slick,
        speed: 700,
        autoplay: false,
        dots: false,
        nextArrow: false,
        prevArrow: false
      }
    }
  },

  created () {
    this.$store.commit(evergreen.SET_EVERGREEN_AUTOMATION_TAB_STATUS, 'second')
    /*
     * if url is hit directly we check social accounts selected and name exist
     * if not we redirect back to step 1 social accounts selection
     */
    if (this.$parent.isValidStep) {
      this.$parent.redirectEvergreen('saveEvergreenAutomationAccounts')
      return false
    }
  },

  computed: {
    ...mapGetters([
      'getEvergreenSelection',
      'getEvergreenVariationData',
      'getAccountSelection',
      'getEvergreenAutomationBulk'
    ]),

    /*
     * check file is uploading , to show progress bar
     */
    isProcessing () {
      return (
        this.getEvergreenAutomationBulk.csvFileUpload &&
        this.getEvergreenAutomationBulk.total > 0
      )
    }
  },

  watch: {
    /*
     * reload slick on change post (delete, edit, add)
     */
    'getEvergreenSelection.posts' (value) {
      const self = this
      value.forEach(function (value, index) {
        self.slickReload(index)
      })
    }
  },

  methods: {
    ...mapActions([
      'setCommonSharingDetails',
      'saveEvergreenAutomation',
      'setSocialSharingTagsSuggestionsItems'
    ]),

    processDescription (description, limit) {
      return parseDescription(description, limit)
    },

    // slick slider methods
    slickReload (index) {
      if (this.$refs.slick && this.$refs.slick[index]) {
        const currIndex = this.$refs.slick[index].currentSlide()
        this.$refs.slick[index].destroy()
        this.$nextTick(() => {
          this.$refs.slick[index].create()
          this.$refs.slick[index].goTo(currIndex, true)
        })
      }
    },

    // event trigger when any changes in slider
    handleAfterChange (index) {
      console.log('handleAfterChange', index)

      if (
        parseInt(this.$refs.currentSliderNumber[index].innerHTML) ===
          this.getEvergreenSelection.posts[index].length &&
        this.getEvergreenSelection.posts[index].length > 1
      ) {
        this.$refs.variationArrowNext[index].classList.add('lastIndex')
        this.$refs.variationArrowPrev[index].classList.remove('lastIndex')
      } else if (
        parseInt(this.$refs.currentSliderNumber[index].innerHTML) === 1
      ) {
        this.$refs.variationArrowNext[index].classList.remove('lastIndex')
        this.$refs.variationArrowPrev[index].classList.add('lastIndex')
      } else {
        this.$refs.variationArrowNext[index].classList.remove('lastIndex')
        this.$refs.variationArrowPrev[index].classList.remove('lastIndex')
      }
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    // next slide method
    next (index) {
      this.$refs.slick[index].next()
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    // previous slide method
    prev (index) {
      this.$refs.slick[index].prev()
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    /*
     * get post video thumbnail or post image
     */
    getEvergreenPostImage (post) {
      if (post.video && post.video.link) {
        return post.video.thumbnail
      } else if (post.image && post.image.length) {
        return post.image[0]
      }
      return false
    },

    /*
     * get post video thumbnail or post image count
     */
    getEvergreenPostTotalImages (post) {
      if (post.video && post.video.link) {
        return 1
      } else if (post.image && post.image.length) {
        return post.image.length
      }
      return 0
    },

    /*
     * edit post variation
     * set single box data
     * set evergreen states for edit variation
     * show edit modal
     */
    editEvergreenVariation (variation, variationIndex, postIndex) {
      console.debug('Method:editEvergreenVariation', variation)
      variation = JSON.parse(JSON.stringify(variation))
      this.setCommonSharingDetails(variation)
      if (variation.gmb_options) {
        this.$store.commit(social.SET_GMB_OPTIONS, variation.gmb_options)
      } else this.$store.commit(social.SET_GMB_OPTIONS, null)

      this.setSharingMessage('Common', variation.message)
      this.setSocialSharingTagsSuggestionsItems([])
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, variation.id)
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'variation')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, postIndex)
      this.$store.commit(
        evergreen.SET_EVERGREEN_VARIATION_INDEX,
        variationIndex
      )
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * reset states for adding new Posts
     * show Add Post Modal
     */
    openAddPostModal () {
      // reset social box
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      document.getElementById('common_sharing_message').style.height = '63px'
      this.setSocialSharingTagsSuggestionsItems([])
      // reset add post stats
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'post')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, 0)
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')

      // show add post modal
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * add new Variation in post
     * set states and show add post modal
     */
    addVariation (index) {
      // set 1st variation in social box
      this.setCommonSharingDetails(
        JSON.parse(JSON.stringify(this.getEvergreenSelection.posts[index][0]))
      )
      this.setSharingMessage(
        'Common',
        this.getEvergreenSelection.posts[index][0].message
      )
      this.setSocialSharingTagsSuggestionsItems([])
      if (this.getAccountSelection.gmb.length) {
        if (this.getEvergreenSelection.posts[index][0].gmb_options) {
          this.$store.commit(
            social.SET_GMB_OPTIONS,
            JSON.parse(
              JSON.stringify(
                this.getEvergreenSelection.posts[index][0].gmb_options
              )
            )
          )
        } else this.$store.commit(social.SET_GMB_OPTIONS, null)
      }
      // set stats for add variations
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'variation')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, index)
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')
      // show add modal
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * fetch post url or url from text
     * and show generate variation modal
     */
    addURLVariation (index) {
      let link = this.getEvergreenSelection.posts[index][0].url
      if (link === '') {
        const urls = this.getLinksFromText(
          this.getEvergreenSelection.posts[index][0].message
        )
        if (urls) link = urls[0]
      }

      if (link === '') {
        this.alertMessage(
          'Auto-generate variation feature works only with link posts.',
          'error'
        )
        return false
      }

      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, index)
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      this.$store.commit(evergreen.SET_VARIATION_URL, link)
      this.$store.commit(evergreen.SET_EVERGREEN_VARIATION_PREVIEW_POSTS, [])
      // eslint-disable-next-line no-undef
      $('#autoVariationGenerate').modal('show')
    },

    /*
     * generate post all warning messages
     */
    singleBoxWarningMessagesEvergreen (posts) {
      const messages = []
      const socialPlatforms = this.getAccountSelection

      posts.forEach(function (details, index) {
        let images = []
        if (
          Object.prototype.toString.call(details.image) === '[object Array]'
        ) {
          images = details.image
        } else {
          images = [details.image]
        }

        if (socialPlatforms.twitter.length) {
          if (images.length > 4) {
            messages.push(
              'Twitter doesn’t allow publishing more than 4 images. So, we’ll use the first 4 images for the Twitter post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (socialPlatforms.pinterest.length && images.length > 1) {
          messages.push(
            'Pinterest doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Pinterest post. [Variation ' +
              (index + 1) +
              ']'
          )
        }

        if (socialPlatforms.linkedin.length) {
          if (images.length > 9) {
            messages.push(
              'Linkedin doesn’t allow publishing more than 9 images. So, we’ll use the first 9 images for the Linkedin post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (socialPlatforms.tumblr.length && images.length > 1) {
          messages.push(
            'Tumblr doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Tumblr post. [Variation ' +
              (index + 1) +
              ']'
          )
        }

        // showing warning message if images are more than 10
        if (socialPlatforms.instagram.length) {
          if (images.length > 10) {
            messages.push(
              'Instagram doesn’t allow publishing more than 10 images. So, we’ll use the first 10 images for the Instagram post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (details.mentions.length > 0) {
          messages.push(
            'You can only tag Facebook pages and Twitter handles. [Variation ' +
              (index + 1) +
              ']'
          )
        }
      })

      return messages
    },

    /*
     * calculate bulk add posts progress bar
     */
    EvergreenProcessBar (percentage = false) {
      if (percentage) {
        return {
          width:
            (this.getEvergreenAutomationBulk.totalProcess /
              this.getEvergreenAutomationBulk.total) *
              100 +
            '%'
        }
      }
      return (
        Math.round(
          (this.getEvergreenAutomationBulk.totalProcess /
            this.getEvergreenAutomationBulk.total) *
            100
        ) + '%'
      )
    },

    /*
     * calculate total expected post can b generated from one cycle of evergreen
     */
    getTotalExpectedPosts () {
      let maxVariation = 0
      this.getEvergreenSelection.posts.forEach(function (value, index) {
        if (value && value.length > maxVariation) maxVariation = value.length
      })
      return maxVariation * this.getEvergreenSelection.posts.length
    }
  }
}
</script>
<template>
  <div class="page_3 schedule_campaign">
    <div class="automation_page width_medium">
      <div class="page_inner basic_form">
        <div class="step_heading">
          <h3>Step 2 - Content</h3>
        </div>

        <div class="evergreen_option">
          <div class="box_align">
            <div class="c_box">
              <div class="box_inner" @click.prevent="openAddPostModal()">
                <div class="img_icon">
                  <img
                    class="selected_img"
                    src="../../../../../assets/img/evergreen/social_active.png"
                    alt=""
                  />
                  <img
                    class="normal_img"
                    src="../../../../../assets/img/evergreen/social_inactive.png"
                    alt=""
                  />
                </div>
                <h3 class="icon_text" data-cy="c-post">Add Post</h3>
              </div>
            </div>
            <div class="or_sep">
              <span class="sep_text">OR</span>
            </div>
            <evergreen-bulk></evergreen-bulk>
          </div>
        </div>

        <template v-if="isProcessing">
          <p
            style="font-size: 1.125rem"
            class="mb-3 mt-3"
            v-if="EvergreenProcessBar() === '0%'"
            >Transforming file (it may take few seconds depending on the size of
            file)</p
          >
          <p style="font-size: 1.125rem" class="mb-3 mt-3" v-else
            >Processing {{ EvergreenProcessBar() }}</p
          >
          <div class="process_bar_automation">
            <div class="limit_add" :style="EvergreenProcessBar(true)"></div>
            <div class="limit_exceed"></div>
          </div>
        </template>

        <p
          class="expected_count mt-2 mb-3"
          v-if="
            getEvergreenSelection.posts &&
            getEvergreenSelection.posts.length > 0
          "
        >
          Total expected posts in 1 cycle :
          <strong>{{ getTotalExpectedPosts() }}</strong>
        </p>

        <draggable
          ghost-class="ghost"
          handle=".drag_tool_item"
          class="evergreen_list"
          v-model="getEvergreenSelection.posts"
          group="variations"
        >
          <div
            class="evergreen_list_item input_field"
            v-for="(post, index) in getEvergreenSelection.posts"
            :key="index"
          >
            <div class="list_inner">
              <button
                class="drag_tool_item btn border_btn drag_btn"
                v-tooltip.top-center="'Drag'"
              >
                <i class="icon_left fa fa-arrows-alt mr-0"></i>
              </button>
              <div class="top_area d-flex align-items-center">
                <div class="top_left">
                  <p
                    >Post <span>{{ index + 1 }}</span></p
                  >
                </div>
                <div class="top_right ml-auto">
                  <button
                    class="btn border_btn"
                    @click.prevent="
                      confirmAction('removeEvergreenPost', { index: index })
                    "
                  >
                    <i class="icon_left icon-delete-cs"></i>
                    <span>Remove post</span>
                  </button>
                </div>
              </div>
              <slick
                @afterChange="handleAfterChange(index)"
                ref="slick"
                :options="slickOptions"
              >
                <div
                  :key="variationIndex"
                  v-for="(variation, variationIndex) in post"
                  class="center_content d-flex align-items-start"
                >
                  <div class="content">
                    <div
                      v-html="processDescription(variation.message, 320)"
                      class="content_inner"
                    ></div>

                    <!--........Don't remove this 'display:none' button as because it's using indirectly...........-->
                    <div class="btn_content_block">
                      <button
                        :ref="'insideEdit' + index"
                        class="btn border_btn"
                        @click.prevent="
                          editEvergreenVariation(
                            variation,
                            variationIndex,
                            index
                          )
                        "
                      >
                        <i class="icon_left fa fa-edit"></i> <span>Edit</span>
                      </button>
                      <!--........Don't remove this 'display:none' button as because it's using indirectly...........-->
                      <button
                        :ref="'insideRemove' + index"
                        class="ml-2 btn border_btn"
                        @click.prevent="
                          confirmAction('removeEvergreenVariation', {
                            variationIndex: variationIndex,
                            postIndex: index,
                          })
                        "
                      >
                        <i class="icon_left icon-delete-cs"></i>
                        <span>Remove</span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="img_block d-flex align-items-center justify-content-center"
                    v-if="getEvergreenPostImage(variation)"
                  >
                    <img
                      :src="getEvergreenPostImage(variation)"
                      class="img"
                      alt=""
                    />

                    <div
                      class="count_number"
                      v-if="getEvergreenPostTotalImages(variation) > 1"
                    >
                      <span
                        >+{{
                          getEvergreenPostTotalImages(variation) - 1
                        }}
                        more</span
                      >
                    </div>

                    <div
                      @click.prevent="
                        previewVideo(
                          '',
                          '',
                          variation.video.converted_video,
                          'External'
                        )
                      "
                      class="play_icon"
                      v-if="variation.video && variation.video.link"
                    >
                      <i class="fa fa-play" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </slick>
              <div class="variation_area d-flex align-items-center">
                <div class="v_left">
                  <i
                    ref="variationArrowPrev"
                    @click="prev(index)"
                    class="fa fa-caret-left"
                  ></i>
                  <i
                    ref="variationArrowNext"
                    @click="next(index)"
                    class="fa fa-caret-right"
                  ></i>
                  <span
                    >Variation <span ref="currentSliderNumber">1</span> of
                    {{ post.length }}</span
                  >
                </div>
                <div class="v_right ml-auto">
                  <button
                    class="btn border_btn"
                    @click.prevent="addVariation(index)"
                  >
                    <span>Add variations</span>
                  </button>
                  <button
                    class="btn border_btn ml-2"
                    @click.prevent="addURLVariation(index)"
                    :disabled="getEvergreenVariationData.fetchLoader"
                    :class="{ disabled: getEvergreenVariationData.fetchLoader }"
                  >
                    <span>Auto-generate variations</span>
                  </button>
                </div>
              </div>

              <div
                class="error_block"
                v-if="$parent.singleBoxErrorMessageEvergreen(post).length > 0"
              >
                <div class="warning_block_content">
                  <p
                    >Error ({{
                      $parent.singleBoxErrorMessageEvergreen(post).length
                    }})</p
                  >
                  <ul>
                    <li
                      v-for="(
                        message, index
                      ) in $parent.singleBoxErrorMessageEvergreen(post)"
                      :key="index"
                      >{{ message }}</li
                    >
                  </ul>
                </div>
              </div>
              <div
                class="warning_block"
                v-else-if="singleBoxWarningMessagesEvergreen(post).length > 0"
              >
                <div class="warning_block_content">
                  <p
                    >Warning ({{
                      singleBoxWarningMessagesEvergreen(post).length
                    }})</p
                  >
                  <ul>
                    <li
                      v-for="(
                        message, index
                      ) in singleBoxWarningMessagesEvergreen(post)"
                      :key="index"
                      >{{ message }}</li
                    >
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <div
          v-if="getEvergreenSelection.posts.length > 0"
          class="post_create_link"
        >
          <p @click.prevent="openAddPostModal()" data-cy="add-another"
            ><i class="cs-plus"></i> Add another post</p
          >
        </div>

        <div class="field_group text_center">
          <button
            class="btn btn-studio-theme-transparent-grey mr-1"
            :disabled="getEvergreenSelection.saveEvergreenContent"
            @click.prevent="
              $parent.redirectEvergreen('saveEvergreenAutomationAccounts')
            "
          >
            <i class="icon_left cs-angle-left"></i>
            <span>Prev</span>
          </button>

          <button
            class="btn btn-studio-theme-space"
            @click.prevent="$parent.processStepSecond(true)"
            data-cy="step2-button"
          >
            <span>Next</span>
            <i class="icon_right arrow_right_active cs-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
