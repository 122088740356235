<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
        type="pinterest"
        :style="{
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
        :updating="fetching_data"
        :getAccounts="getAccounts"
        :getDate="getDate"
        :getPreviousDate="getPreviousDate"
    ></AnalyticsFilterBar>
    <div
        class="analytics-main-errors"
        v-if="getPlatformAccounts('pinterest').length <= 0 && !fetching_data"
    >
      <img
          draggable="false"
          src="../../assets/imgs/no_data_images/no-account-connected.svg"
          alt=""
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
            class="btn btn-studio-theme-space btn-size-small"
            :to="{ name: 'social' }"
        >Connect Pinterest Board
        </router-link>
      </p>
    </div>

    <div
        v-else-if="getPlatformAccounts('pinterest').length > 0"
        class="analytics-content-wrapper"
    >
      <template v-if="selected_account.state === 'Added'">
        <analytics-loading :name="selected_account.name"></analytics-loading>
      </template>
      <template v-else>
        <PinterestTabs v-if="!getFetchSocialStatus">
          <!-- overview tab -->
          <template slot="overview" v-if="selected_account">
            <OverviewTab
                :account="selected_account"
                :date="date"
                :previous_date="previous_date"
                :getOverviewLoader="getOverviewLoader"
                :profile="selected_account.type"
            ></OverviewTab>
          </template>

          <!-- posts tab -->
          <template slot="posts">
            <PostsTab
                :account="selected_account"
                :date="date"
                :previous_date="previous_date"
                :getPostsLoader="getPostsLoader"
            ></PostsTab>
          </template>
        </PinterestTabs>
      </template>

      <div class="analytics-content-sync-box">
        <!--        <template v-if="selected_account.last_analytics_updated_at">-->
        <!--          <p class="mr-4">Updated {{ timesAgo(selected_account.last_analytics_updated_at) }}</p>-->
        <!--        </template>-->
        <!--        <div class="update_box d-flex ml-2" style="cursor: pointer"-->
        <!--             :class="{'disable_click':selected_account.state === 'Syncing'}" @click="reFetchData()">-->
        <!--          <template-->
        <!--              v-if="getSyncServiceStatus===true || selected_account.state === 'Syncing'">-->
        <!--            <i class="fas fa-sync"></i> <span>  Syncing...</span>-->
        <!--          </template>-->
        <!--          <template v-else>-->
        <!--              <i class="fas fa-sync"></i> <span>  Sync</span>-->
        <!--          </template>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import PinterestTabs from '@src/modules/analytics/views/pinterest/components/PinterestTabs'

// Tabs
import OverviewTab from '@src/modules/analytics/views/pinterest/tabs/OverviewTab'
import PostsTab from '@src/modules/analytics/views/pinterest/tabs/PostsTab'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { mapActions, mapGetters } from 'vuex'
import AnalyticsLoading from '../../components/common/AnalyticsLoading'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { pinterest } from '@src/modules/integration/store/states/mutation-types'
import { SYNC_ERROR } from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsOverview',

  components: {
    AnalyticsFilterBar,
    PinterestTabs,
    OverviewTab,
    PostsTab,
    AnalyticsLoading
  },
  data () {
    return {
      selected_account: [],
      date: '',
      previous_date: '',
      fetching_data: false
    }
  },
  computed: {
    ...mapGetters(['getFetchSocialStatus', 'getSyncServiceStatus'])
  },
  created () {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.resetState()

      const pusher = pusherAuthSocketAnalytics()
      this.analytics_channel = pusher.subscribe(
          'analytics-channel-' + this.getActiveWorkspace._id
      )
      if (this.selected_account.board_id) {
        this.bindSocketListeners()
      }
    })
  },
  mounted () {
    const pusher = pusherAuthSocketAnalytics()
    this.analytics_channel = pusher.subscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
    )
    if (this.selected_account.board_id) {
      this.bindSocketListeners()
    }
  },
  beforeDestroy () {
    this.removeSocketListners(this.selected_account.board_id)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
          'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account.board_id
      )
      this.analytics_channel.unsubscribe(
          'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  methods: {
    ...mapActions(['syncUpdateService']),
    getAccounts (account) {
      this.selected_account = account
      console.log('account changed')
      console.log(this.selected_account)
    },
    getDate (date) {
      this.date = date
    },
    getPreviousDate (date) {
      this.previous_date = date
    },
    getOverviewLoader (value) {
      this.fetching_data = value
    },
    getPostsLoader (value) {
      this.fetching_data = value
    },
    async reFetchData () {
      if (this.selected_account.state === 'Syncing') {
        return
      }
      if (
          this.hourDifference(this.selected_account.last_analytics_updated_at) < 4
      ) {
        this.alertMessage(SYNC_ERROR, 'error')
        return
      }
      const payload = {
        selected_account: this.selected_account.board_id,
        channel: 'pinterest'
      }
      const resp = await this.syncUpdateService(payload)
      if (resp.status) {
        this.setPinState(
            resp.state,
            this.selected_account.board_id,
            this.selected_account.last_analytics_updated_at
        )
      }
      console.log(resp)
    },

    setPinState (state, checkFor, last_analytics_updated_at) {
      var updatedItems = []
      for (const item in this.getPinterestAccounts) {
        const update = this.getPinterestAccounts[item]
        if (update.board_id === checkFor) {
          update.state = state
          // eslint-disable-next-line camelcase
          update.last_analytics_updated_at = last_analytics_updated_at
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'pinterest',
        all_item_setter: pinterest.SET_ALL_ACCOUNTS,
        item_setter: pinterest.SET_ACCOUNTS
      })
    },
    removeSocketListners (value) {
      console.log('removing socket for pinterest ... ')
      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners () {
      const event =
          'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account.board_id
      this.analytics_channel.bind(event, (data) => {
        console.log('message data --> ', data)
        this.setPinState(
            data.state,
            data.account,
            data.last_analytics_updated_at
        )
      })
    }
  },
  watch: {
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
      }
    }
  }
}
</script>
