import { gmb, linkedinSharingTypes } from '../mutation-types'

const getDefaultGmbSharingVideo = () => {
  return {
    name: '',
    title: '',
    mime_type: '',
    link: '',
    duration: '00:00:00.00',
    size: 0,
    thumbnail: '',
    thumbnails_suggestions: [],
    converted_video: '',
    converted_link: '',
    converted_mime_type: '',
    converted_size: 0
  }
}

const defaultGmbSharingDetails = {
  message: '',
  image: '',
  video: getDefaultGmbSharingVideo(),
  url: '',
  cta_link: ''
}

export default {
  state: {
    selection: {
      message: '',
      image: '',
      video: getDefaultGmbSharingVideo(),
      url: '',
      cta_link: ''
    },
    cancelled_link: '',
    progress_bar: {
      image: 0,
      video: 0
    },
    loaders: {
      fetch_sharing_preview: false,
      fetch_short_links: false,
      upload_sharing_image: false,
      upload_sharing_video: false
    }
  },
  actions: {},
  mutations: {
    [gmb.SET_SHARING_IMAGE] (state, image) {
      state.selection.image = image
    },

    [gmb.SET_SHARING_MESSAGE] (state, message) {
      state.selection.message = message
    },

    [gmb.SET_SHARING_URL] (state, url) {
      state.selection.url = url
      state.selection.cta_link = url
    },

    [gmb.SET_SHARING_PREVIEW] (state, preview) {
      if (preview) {
        state.selection.image = preview.image
      } else {
        state.selection.image = ''
        state.selection.url = ''
        state.selection.cta_link = ''
      }
    },

    [gmb.SET_SHARING_DETAILS] (state, data) {
      if (data == null) {
        state.selection = JSON.parse(JSON.stringify(defaultGmbSharingDetails))
      } else {
        state.selection = data
      }
      state.cancelled_link = ''
    },

    [gmb.SET_DEFAULT_SHARING] (state) {
      state.selection = JSON.parse(JSON.stringify(defaultGmbSharingDetails))
      state.cancelled_link = ''
      state.progress_bar = {
        image: 0,
        video: 0
      }
    },

    [gmb.SET_SHARING_IMAGE_PERCENTAGE] (state, percent) {
      state.progress_bar.image = percent
    },

    [gmb.SET_SHARING_CANCELLED_PREVIEW] (state, link) {
      state.cancelled_link = link
    },

    [gmb.SET_SHARING_VIDEO] (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultGmbSharingVideo()
      }
    },

    [gmb.SET_SHARING_VIDEO_INITIALIZATION] (state) {
      state.selection.image = ''
      state.selection.url = ''
      state.selection.cta_link = ''
      state.selection.video = getDefaultGmbSharingVideo()
      state.progress_bar.video = 0
    },

    [gmb.SET_SHARING_VIDEO_PERCENTAGE] (state, percent) {
      state.progress_bar.video = percent
    },

    [gmb.SET_SHARING_VIDEO_THUMBNAIL] (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },
    [gmb.SET_SHARING_VIDEO_SUGGESTIONS] (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },
    [gmb.SET_FETCH_SHARING_PREVIEW_LOADER] (state, status) {
      state.loaders.fetch_sharing_preview = status
    },

    [gmb.SET_FETCH_SHORT_LINKS_LOADER] (state, status) {
      state.loaders.fetch_short_links = status
    },

    [gmb.SET_UPLOAD_SHARING_IMAGE_LOADER] (state, status) {
      state.loaders.upload_sharing_image = status
    },

    [gmb.SET_UPLOAD_SHARING_VIDEO_LOADER] (state, status) {
      state.loaders.upload_sharing_video = status
    }
  },
  getters: {
    getGmbSharingDetails: (state) => {
      return state.selection
    },

    getGmbSharingCancelledLink: (state) => {
      return state.cancelled_link
    },

    getGmbSharingProgressBar: (state) => {
      return state.progress_bar
    },

    getGmbSharingLoaders: (state) => {
      return state.loaders
    }
  }
}
