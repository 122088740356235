var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.getTweetsContent),function(tweet,index){return _c('div',{key:index,staticClass:"assistant_box _twitter",class:{ dragable_box: !(_vm.$route.name === 'composerBlog') }},[_c('div',{staticClass:"box_inner",attrs:{"draggable":_vm.$route.name === 'composerBlog'},on:{"dragstart":function($event){return _vm.dragstartHandler($event, tweet)}}},[_c('div',{staticClass:"content_block"},[_c('div',{staticClass:"users_detail"},[_c('div',{staticClass:"profile_picture d-flex align-items-start tw"},[_c('div',{staticClass:"picture_block rounded_picture",style:(_vm.getTweetUserImage(tweet.user))}),_c('div',{staticClass:"text_block"},[_c('a',{staticClass:"text",attrs:{"target":"_blank","href":_vm.getTweetRedirectLink(tweet.id_str)}},[_vm._v(_vm._s(_vm.limitTextLength(tweet.user.name, 11)))]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.$filters.relative(tweet.created_at)))])])])]),_c('p',{staticClass:"desc",staticStyle:{"min-height":"90px"},domProps:{"innerHTML":_vm._s(
            _vm.twitterText(
              tweet.text,
              tweet.entities.hashtags,
              tweet.entities.urls,
              tweet.entities.user_mentions
            )
          )}})]),_c('div',{staticClass:"social_stats"},[_c('div',{staticClass:"social_stats_inner d-flex"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Retweet'),expression:"'Retweet'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(0,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(tweet.retweet_count)))])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Favorite'),expression:"'Favorite'",modifiers:{"top-center":true}}],staticClass:"stat_item"},[_vm._m(1,true),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(tweet.favorite_count)))])])])])]),_c('div',{staticClass:"hide d-none editor_dragable_box editor_twitter_block",attrs:{"contenteditable":"false"}},[_c('div',{staticClass:"editor_dragable_box_inner clear"},[_vm._m(2,true),_c('div',{staticClass:"align_box twitter_inner_embed",staticStyle:{"text-align":"center","display":"block"},attrs:{"data-link":'https://twitter.com/' +
              tweet.user.screen_name +
              '/status/' +
              tweet.id_str,"data-tweet":tweet.text}},[_c('div',{staticStyle:{"display":"none !important"}},[_c('p',{staticClass:"tweet_id"},[_vm._v(_vm._s(tweet.id_str))]),_c('p',{staticClass:"tweet_description"},[_vm._v(_vm._s(_vm.twitterText( tweet.text, tweet.entities.hashtags, tweet.entities.urls, tweet.entities.user_mentions )))]),_c('p',{staticClass:"tweet_screenname"},[_vm._v(_vm._s(tweet.user.screen_name))]),_c('p',{staticClass:"tweet_title"},[_vm._v(_vm._s(tweet.user.name))]),_c('p',{staticClass:"tweet_link"},[_vm._v(_vm._s('https://twitter.com/' + tweet.user.screen_name + '/status/' + tweet.id_str))])]),_vm._m(3,true),_c('div',{staticClass:"inner_embed",attrs:{"id":""}})]),_vm._m(4,true)])])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"cs-refresh"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_c('i',{staticClass:"cs-star"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_option"},[_c('div',{staticClass:"left_block"},[_c('i',{staticClass:"move_left fa fa-align-left editor_tooltip",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Align Left"}}),_c('i',{staticClass:"move_center fa fa-align-center editor_tooltip",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Align Center "}}),_c('i',{staticClass:"move_right fa fa-align-right editor_tooltip",attrs:{"data-toggle":"tooltip","data-placement":"left","title":"Align Right"}})]),_c('div',{staticClass:"right_block"},[_c('i',{staticClass:"handler fa fa-hand-paper-o editor_tooltip",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Move"}}),_c('i',{staticClass:"edit_box fa fa-check-circle editor_tooltip green",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Merge"}}),_c('i',{staticClass:"remove_box fa fa-times-circle editor_tooltip red",attrs:{"data-toggle":"tooltip","data-placement":"left","title":"Remove"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom_loader"},[_c('div',{staticClass:"loader_inner"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('br')])
}]

export { render, staticRenderFns }