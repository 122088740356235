<template>
  <div class="">
    <re-tweet></re-tweet>
    <div class="article_inner">
      <div
        class="twitter_post_box_grid fb_quote_box_grid article_box_grid"
        v-for="(tweet, index) in getTweetsContent"
      >
        <div class="box_inner">
          <div class="content_block">
            <div class="content_block_height">
              <div class="users_detail">
                <div class="profile_picture d-flex align-items-start">
                  <div
                    class="picture_block rounded_picture"
                    :style="getTweetUserImage(tweet.user)"
                  ></div>
                  <div class="text_block">
                    <a
                      target="_blank"
                      class="text"
                      :href="getTweetRedirectLink(tweet)"
                      >{{ limitTextLength(tweet.user.name, 15) }}</a
                    >
                    <p class="sub_text" v-if="tweet.created_at">{{
                      $filters.relative(tweet.created_at)
                    }}</p>
                  </div>
                </div>
              </div>

              <!--<div class="image_block" v-if="tweet.entities.media && tweet.entities.media[0]">-->
              <!--<div class="img" v-lazy:background-image="tweet.entities.media[0].media_url"></div>-->
              <!--</div>-->
              <p
                class="desc"
                v-html="
                  twitterText(
                    tweet.text,
                    tweet.entities.hashtags,
                    tweet.entities.urls,
                    tweet.entities.user_mentions
                  )
                "
              ></p>
            </div>

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item" v-tooltip.top-center="'Retweets'">
                  <div class="name">
                    <p> Retweet </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(tweet.retweet_count) }}</h3>
                  </div>
                </div>
                <div class="stat_item" v-tooltip.top-center="'Favorite'">
                  <div class="name">
                    <p> Favorite </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(tweet.favorite_count) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_block d-flex text-center">
              <div class="btn_block_inner">
                <div
                  class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div
                    class="dropdown_header"
                    data-toggle="dropdown"
                    @click="initializeTwitterPostCreation(tweet)"
                  >
                    <button
                      class="btn light_gray"
                      v-tooltip.top-center="'Share this post'"
                    >
                      <i class="icon-Share_icon d-block"></i>
                      <!--<span class="circle_count">7</span>-->
                    </button>
                  </div>

                  <!--<div class="dropdown-menu dropdown-menu-right">-->
                  <!--<ul class="inner">-->
                  <!--<li class="list_item_li " >-->
                  <!--<span class="icon "></span>-->
                  <!--<span class="icon_text">Social Media</span>-->
                  <!--</li>-->
                  <!--<li class="list_item_li ">-->
                  <!--<span class="icon"></span>-->
                  <!--<span class="icon_text">Blog Post</span>-->
                  <!--</li>-->
                  <!--</ul>-->

                  <!--</div>-->
                </div>

                <div
                  class="article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div
                    class="dropdown_header"
                    @click.prevent="initializeTwitterRetweet(tweet)"
                  >
                    <button
                      @click="$bvModal.show('retweet_share_modal')"
                      v-tooltip.top-center="'Retweet'"
                      class="btn blue"
                    >
                      <i class="cs-refresh d-block"></i>
                    </button>
                  </div>
                </div>
                <!-- <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                    <div class="dropdown_header" data-toggle="dropdown">
                                        <button class="btn light_gray"  v-tooltip.top-center="'More options'">
                                            <i class=" cs-dots-h d-block">
                                            </i>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul class="inner">
                                            <li class="list_item_li ">
                                                <i class="cs-pocket"></i>
                                                <span class="icon_text">Add to Pocket</span>
                                            </li>
                                            <li class="list_item_li ">
                                                <i class="cs-archive"></i>
                                                <span class="icon_text">Archive Post</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReTweet from './ReTweet'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ReTweet
  },

  data () {
    return {}
  },
  created () {},
  computed: {
    ...mapGetters(['getTweetsContent'])
  },

  methods: {
    ...mapActions([])
  },

  watch: {}
}
</script>
