<template>
    <div class="articles_list">
        <div class="article_inner grey_inner">
            <template v-if="getCoveredStoriesLoader">

                <div class="article_cover_story_list pt-5">
                    <div style="background: #fff;" class="mb-5 pb-3 pt-3">
                        <template>
                            <content-loader
                                    :height="430"
                                    :width="1000"
                                    :speed="2"
                                    primaryColor="#e3e6ec"
                                    secondaryColor="#d1d4de"
                            >
                                <rect x="25" y="20" rx="4" ry="4" width="117" height="20" />
                                <rect x="800" y="20" rx="4" ry="4" width="160" height="20" />
                                <rect x="0" y="65.27" rx="0" ry="0" width="1000" height="1" />
                                <rect x="25" y="90" rx="4" ry="4" width="550" height="20" />
                                <rect x="820" y="90" rx="4" ry="4" width="140" height="75" />
                                <rect x="25" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="125" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="230" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="25" y="170" rx="4" ry="4" width="675" height="17" />
                                <rect x="25" y="200" rx="4" ry="4" width="600" height="17" />
                                <rect x="25" y="230" rx="4" ry="4" width="550" height="17" />
                                <rect x="0" y="280" rx="0" ry="0" width="1000" height="1" />
                                <rect x="25" y="310" rx="4" ry="4" width="550" height="20" />
                                <rect x="820" y="310" rx="4" ry="4" width="140" height="75" />
                                <rect x="25" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="125" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="230" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="25" y="370" rx="4" ry="4" width="675" height="17" />
                                <rect x="25" y="400" rx="4" ry="4" width="600" height="17" />
                                <rect x="25" y="430" rx="4" ry="4" width="550" height="17" />
                            </content-loader>
                        </template>
                    </div>
                    <div style="background: #fff;" class="mt-5 pb-3 pt-3">
                        <template>
                            <content-loader
                                    :height="430"
                                    :width="1000"
                                    :speed="2"
                                    primaryColor="#e3e6ec"
                                    secondaryColor="#d1d4de"
                            >
                                <rect x="25" y="20" rx="4" ry="4" width="117" height="20" />
                                <rect x="800" y="20" rx="4" ry="4" width="160" height="20" />
                                <rect x="0" y="65.27" rx="0" ry="0" width="1000" height="1" />
                                <rect x="25" y="90" rx="4" ry="4" width="550" height="20" />
                                <rect x="820" y="90" rx="4" ry="4" width="140" height="75" />
                                <rect x="25" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="125" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="230" y="130" rx="4" ry="4" width="80" height="15" />
                                <rect x="25" y="170" rx="4" ry="4" width="675" height="17" />
                                <rect x="25" y="200" rx="4" ry="4" width="600" height="17" />
                                <rect x="25" y="230" rx="4" ry="4" width="550" height="17" />
                                <rect x="0" y="280" rx="0" ry="0" width="1000" height="1" />
                                <rect x="25" y="310" rx="4" ry="4" width="550" height="20" />
                                <rect x="820" y="310" rx="4" ry="4" width="140" height="75" />
                                <rect x="25" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="125" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="230" y="340" rx="4" ry="4" width="80" height="15" />
                                <rect x="25" y="370" rx="4" ry="4" width="675" height="17" />
                                <rect x="25" y="400" rx="4" ry="4" width="600" height="17" />
                                <rect x="25" y="430" rx="4" ry="4" width="550" height="17" />
                            </content-loader>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else-if="(getTopics.length + getMyCuratedTopicsList.length) === (getCustomSidebar.my_topics.options.length + getCustomSidebar.quick_topics.options.length)"">
                <div class="article_cover_story_list pt-5" >
                     <span slot="no-more" class="not_found_text">
                        <div class="no_data_found_content">
                            <div class="no_data_found_content_inner">
                                <div class="img">
                                    <img src="../../../../assets/img/no_data_images/no_data_found.svg"
                                         alt="">
                                </div>
                                <p>
                                    Your Curated Topics and Custom Topics are not showing in covered stories because you have disabled them from custom sidebar.
                                </p>
                            </div>
                         </div>
                     </span>
                </div>

            </template>
            <div class="article_cover_story_list pt-1" v-if="getCoveredStoriesPosts && getCoveredStoriesPosts.length > 0 && (!getCustomSidebar.my_topics.options || getCoveredStoriesPosts.length !== getCustomSidebar.my_topics.options.length) ">
                <template>
                    <h2 class="story_heading pink_block">Cover Stories From Your <span>Curated Topics</span></h2>
                    <div class="story_item"  v-if="getCoveredStoriesPosts.length > 0 && (getSidebarCustomization.customization || !getCustomSidebar.quick_topics.options.includes(item.topic.name))"  v-for="item,index in getCoveredStoriesPosts" >
                        <div class="story_item_head d-flex align-items-center">
                            <div class="story_left">
                                <!--{{item}}-->
                                <h2>{{item.topic.name}}</h2>
                            </div>
                            <div class="story_right ml-auto">
                                <p @click.prevent="selectTopicFromCoverStory('curated', item.topic)">View more from this topic</p>
                            </div>
                        </div>

                        <template v-if="!item.posts || item.posts.length == 0 ">
                            <div class="article_cover_story_box">
                                <div class="box_inner  ">

                                    <div class="no_data_found_content">
                                        <div class="no_data_found_content_inner">
                                            <div class="img">
                                                <img src="../../../../assets/img/no_data_images/no_story_found.svg" alt="">
                                            </div>
                                            <p>No cover stories found.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="article_cover_story_box" v-for="post in item.posts">
                            <div class="box_inner  " v-if="post._source">
                                <div class="d-flex">

                                    <div class="content_block">
                                        <div class="d-flex ">
                                            <div class="left_content">
                                                <h2 @click.prevent="articlePreview({id:post._source.id, index:index})"
                                                    :title="limitTextLength(post._source.title,0)"
                                                    v-html="limitTextLength(post._source.title,120)"></h2>
                                                <div class="detail clear">
                                                    <p>{{$filters.relative(post._source.post_date)}}</p>
                                                    <p><a class="gray_text" href="javascript:;">{{trimURL(post._source.domain_url)}}</a>
                                                    </p>
                                                    <p class="author_detail">
                                                        <template v-if="post._source.author">
                                                            By <span v-html="post._source.author"></span>
                                                            <a v-if="post._source.twitter_handler" target="_blank"
                                                               :href="getTwitterLink(post._source.twitter_handler)">{{post._source.twitter_handler}}</a>
                                                            <a v-else-if="index <1"
                                                               v-for="account,index in getTwitterAccountsForView(post._source.twitter_accounts)"
                                                               target="_blank"
                                                               :href="getTwitterLink(account)">{{account}}</a>
                                                        </template>
                                                        <template v-else-if="post._source.twitter_handler">
                                                            By <a target="_blank"
                                                                  :href="getTwitterLink(post._source.twitter_handler)">{{post._source.twitter_handler}}</a>
                                                        </template>
                                                        <template
                                                                v-else-if="post._source.twitter_accounts && post._source.twitter_accounts.length > 0">
                                                            By <a v-if="index <1"
                                                                  v-for="account,index in getTwitterAccountsForView(post._source.twitter_accounts)"
                                                                  target="_blank" :href="getTwitterLink(account)">{{account}}</a>
                                                        </template>
                                                    </p>
                                                </div>
                                                <p class="desc" v-html="limitTextLength(post._source.description, 500)"></p>
                                                <!--<div class="similar_content">16 similar articles</div>-->
                                            </div>

                                        </div>

                                    </div>
                                    <div class="image_block">
                                        <div class="img" v-lazy:background-image="backgroundImageURL(post._source)"></div>
                                    </div>
                                </div>

                                <div class="btn_block_footer">
                                    <div class=" favorite_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">

                                        <div class="dropdown_header" v-tooltip.top-center="'Unfavorite'" v-if="post._source.is_favorite"
                                             @click.prevent="removeFavorite(post._source.id, index)">
                                            <button class="btn " >
                                                <i class="favorite_icon_orange d-block cs-star"></i>
                                            </button>
                                        </div>
                                        <div class="dropdown_header" data-toggle="dropdown"
                                             v-show="post._source.is_favorite == false">
                                            <button v-if="post._source.is_favorite == false" v-tooltip.top-center="'Add to Favorite'" class="btn light_gray">
                                                <i class="d-block cs-star"></i>
                                            </button>
                                        </div>
                                        <favorite-dropdown :post_id="post._source.id"
                                                           :postIndex="index"></favorite-dropdown>
                                    </div>

                                    <div class="article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header" data-toggle="dropdown">
                                            <button class="btn light_gray" v-tooltip.top-center="'Share this post'">
                                                <i class="far fa-share-alt"></i>
                                               <span class="text">Share</span>

                                                <!--<span class="circle_count">7</span>-->
                                            </button>
                                        </div>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item_li " data-toggle="modal"

                                                    @click.prevent="initializeArticlePostCreation(post._source.id,'social_share_modal')">
                                                    <i class="far fa-share share-ico-align"></i>
                                                    <span class="icon_text">Social Media</span>
                                                </li>
                                                <li class="list_item_li "
                                                    @click.prevent="initializeArticlePostCreation(post._source.id,'blog_share_modal')">
                                                    <i class="far fa-rss"></i>
                                                    <span class="icon_text">Blog Post</span>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header">
                                            <button class="btn blue" data-toggle="modal"
                                                    data-target="#twitter_influencer_modal">
                                                <i class="far fa-users "></i>
                                                <span class="text">Twitter Influencers</span>

                                            </button>
                                        </div>
                                        <!--<div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item">
                                                    <a class="dropdown-item" href="#">Link 1</a>
                                                </li>
                                            </ul>
                                        </div>-->
                                    </div>

                                    <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header" data-toggle="dropdown">
                                            <button class="btn light_gray" v-tooltip.top-center="'More options'" >
                                                <i class="cs-dots-h"></i>
                                                <span class="text">More</span>

                                            </button>
                                        </div>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item_li ">
                                                    <a target="_blank" :href="savePocketContent(post._source.url)">
                                                        <i class="cs-pocket"></i>
                                                        <span class="icon_text">Add to Pocket</span>
                                                    </a>
                                                </li>
                                                <!-- <li class="list_item_li " @click.prevent="archivedPost(post._source.id,$event, index)">
                                                     <span class="icon"></span>
                                                     <span class="icon_text">Archive Post</span>
                                                 </li>-->

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="article_cover_story_list pt-1" v-if="getCustomStoriesPosts.length > 0 && getCustomStoriesPosts.length > getCustomSidebar.my_topics.options.length">
                <h2 class="story_heading orange_block">Cover Stories From Your <span>Custom Topics</span></h2>

                <div class="story_item" v-if="getSidebarCustomization.customization || !getCustomSidebar.my_topics.options.includes(item.topic._id)" v-for="item,index in getCustomStoriesPosts">
                    <div class="story_item_head d-flex align-items-center">
                        <div class="story_left">
                            <!--{{item}}-->
                            <h2>{{item.topic.name}}</h2>
                        </div>
                        <div class="story_right ml-auto">
                            <p @click.prevent="selectTopicFromCoverStory('custom', item.topic)">View more from this topic</p>
                        </div>
                    </div>
                    <template v-if="item.posts.length ==0 ">
                        <div class="article_cover_story_box">
                            <div class="box_inner  ">

                                <div class="no_data_found_content">
                                    <div class="no_data_found_content_inner">
                                        <div class="img">
                                            <img src="../../../../assets/img/no_data_images/no_story_found.svg" alt="">
                                        </div>
                                            <p>No cover stories found.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template>
                        <div class="article_cover_story_box" v-for="post in item.posts">
                            <div class="box_inner  ">
                                <div class="d-flex">

                                    <div class="content_block">
                                        <div class="d-flex ">
                                            <div class="left_content">
                                                <h2 @click.prevent="articlePreview({id:post._source.id, index:index})"
                                                    :title="limitTextLength(post._source.title,0)"
                                                    v-html="limitTextLength(post._source.title,120)"></h2>
                                                <div class="detail clear">
                                                    <p>{{$filters.relative(post._source.post_date)}}</p>
                                                    <p><a class="gray_text" href="javascript:;">{{trimURL(post._source.domain_url)}}</a>
                                                    </p>
                                                    <p class="author_detail">
                                                        <template v-if="post._source.author">
                                                            By <span v-html="post._source.author"></span>
                                                            <a v-if="post._source.twitter_handler" target="_blank"
                                                               :href="getTwitterLink(post._source.twitter_handler)">{{post._source.twitter_handler}}</a>
                                                            <a v-else-if="index <1"
                                                               v-for="account,index in getTwitterAccountsForView(post._source.twitter_accounts)"
                                                               target="_blank" :href="getTwitterLink(account)">{{account}}</a>
                                                        </template>
                                                        <template v-else-if="post._source.twitter_handler">
                                                            By <a target="_blank"
                                                                  :href="getTwitterLink(post._source.twitter_handler)">{{post._source.twitter_handler}}</a>
                                                        </template>
                                                        <template
                                                                v-else-if="post._source.twitter_accounts && post._source.twitter_accounts.length > 0">
                                                            By <a v-if="index <1"
                                                                  v-for="account,index in getTwitterAccountsForView(post._source.twitter_accounts)"
                                                                  target="_blank"
                                                                  :href="getTwitterLink(account)">{{account}}</a>
                                                        </template>
                                                    </p>
                                                </div>
                                                <p class="desc" v-html="limitTextLength(post._source.description, 500)"></p>
                                                <!--<div class="similar_content">16 similar articles</div>-->
                                            </div>

                                        </div>

                                    </div>
                                    <div class="image_block">
                                        <div class="img" v-lazy:background-image="backgroundImageURL(post._source)"></div>
                                    </div>
                                </div>

                                <div class="btn_block_footer">

                                    <div class=" favorite_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">

                                        <div class="dropdown_header" v-if="post._source.is_favorite"
                                             @click.prevent="removeFavorite(post._source.id, index)">
                                            <button class="btn " v-tooltip.top-center="'Unfavorite'" >
                                                <i class="d-block favorite_icon_orange cs-star"></i>
                                            </button>
                                        </div>
                                        <div class="dropdown_header" data-toggle="dropdown"
                                             v-show="post._source.is_favorite == false">
                                            <button v-if="post._source.is_favorite == false" v-tooltip.top-center="'Add to Favorite'"  class="btn light_gray">
                                                <i class="d-block cs-star"></i>
                                            </button>
                                        </div>
                                        <favorite-dropdown :post_id="post._source.id" :postIndex="index"></favorite-dropdown>
                                    </div>

                                    <div class="article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header" data-toggle="dropdown">
                                            <button class="btn light_gray" v-tooltip.top-center="'Share this post'" >
                                                <i class="far fa-share-alt"></i>
                                                <span class="text">Share</span>

                                                <!--<span class="circle_count">7</span>-->
                                            </button>
                                        </div>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item_li " data-toggle="modal"

                                                    @click.prevent="initializeArticlePostCreation(post._source.id,'social_share_modal')">
                                                    <i class="far fa-share share-ico-align"></i>
                                                    <span class="icon_text">Social Media</span>
                                                </li>
                                                <li class="list_item_li "
                                                    @click.prevent="initializeArticlePostCreation(post._source.id,'blog_share_modal')">
                                                    <i class="far fa-rss"></i>
                                                    <span class="icon_text">Blog Post</span>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header">
                                            <button class="btn blue" v-tooltip.top-center="'Twitter Influencers'"  data-toggle="modal"
                                                    data-target="#twitter_influencer_modal">
                                                <i class="far fa-users "></i>
                                                <span class="text">Twitter Influencers</span>

                                            </button>
                                        </div>
                                        <!--<div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item">
                                                    <a class="dropdown-item" href="#">Link 1</a>
                                                </li>
                                            </ul>
                                        </div>-->
                                    </div>

                                    <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                        <div class="dropdown_header" data-toggle="dropdown">
                                            <button class="btn light_gray" v-tooltip.top-center="'More options'" >
                                                     <span class="">
                                                       <i class="cs-dots-h"></i>
                                                             <span class="text">More</span>
                                                   </span>

                                            </button>
                                        </div>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul class="inner">
                                                <li class="list_item_li ">
                                                    <a target="_blank" :href="savePocketContent(post._source.url)">
                                                        <i class="cs-pocket"></i>
                                                        <span class="icon_text">Add to Pocket</span>
                                                    </a>
                                                </li>
                                                <!-- <li class="list_item_li " @click.prevent="archivedPost(post._source.id,$event, index)">
                                                     <span class="icon"></span>
                                                     <span class="icon_text">Archive Post</span>
                                                 </li>-->

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </template>

                </div>

            </div>
        </div>
</div>

</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FavoriteDropdown from '../folders/FavoriteDropdown'
import { topicTypes } from '../../store/mutation-types'

export default {
  components: {
    FavoriteDropdown
  },
  computed: {
    ...mapGetters(['getPosts', 'getTopicType', 'getCoveredStoriesPosts', 'getCoveredStoriesLoader', 'getCustomStoriesPosts',
      'getSidebarCustomization', 'getCustomSidebar', 'getTopics', 'getMyCuratedTopicsList'])
  },
  created () {

  },
  mounted () {
    this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({ topic: null, type: 'covered_stories' })
    this.topicsContent()
  },
  methods:
  {
    ...mapMutations([topicTypes.SET_CHANGE_TOPIC_FILTERS]),
    ...mapActions(['articlePreview', 'topicsContent']),
    selectTopicFromCoverStory (type, topic) {
      console.log('selectTopicFromCoverStory')
      if (type === 'custom') {
        this.$router.push({ name: 'topics_web', params: { module: 'custom', topicId: topic._id } })
      } else {
        console.log(topic, this.getMyCuratedTopicsList)
        const item = this.getMyCuratedTopicsList.filter(item => {
          if (item.id === topic.id) return true
        })
        this.$router.push({ name: 'topics_web', params: { module: 'curated', topicId: item[0]._id } })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Cover Stories:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    next()
  }

}
</script>

<style>
    .listview-items {
        transition: all 600ms ease-in-out 50ms;
    }

    .listview-items-enter-active, .listview-items-leave-active {
        transition: all 0.6s;
    }

    .listview-items-enter, .listview-items-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(45px);
    }
</style>
