import axios from 'axios'
/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
import VueCookie from 'vue-cookie'
import { cookieDomain } from '@src/config/api-utils'

const proxy = axios.create({})

proxy.CancelToken = axios.CancelToken
proxy.source = axios.CancelToken.source()
proxy.isCancel = axios.isCancel
proxy.interceptors.request.use(
  (config) => {
    let token = VueCookie.get('logged_user_jwt', { domain: cookieDomain })
    if (!token) {
      token = localStorage.getItem('logged_user_jwt')
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    config.headers.Accept = 'application/json'

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)
export default proxy

const requests = []

function getProxyCancelToken(proxy) {
  console.debug('removePendingRequest', proxy)
  for (let i = 0; i < requests.length; i++) {
    const remove = requests[i]
    remove()
  }
  let cancel
  const token = new proxy.CancelToken(function executor(c) {
    cancel = c
  })
  requests.push(cancel)
  return { cancelToken: token }
}

export { getProxyCancelToken }
