<template>
  <DynamicScroller
    ref="scroller"
    :items="arrItems"
    :min-item-size="10"
    class="scroller"
    :page-mode="true"
    key-field="id"
  >
    <DynamicScrollerItem
      slot-scope="{ item, index, active }"
      :item="item"
      :active="active"
      :data-index="index"
      :size-dependencies="[item.name]"
    >
      <div class="item-row my-0">
        <div v-for="(item, chunk_idx) in item.chunk" :key="chunk_idx" class="item-card">
          <Asset
            v-on:check="(el) => $emit('check', el)"
            v-on:delete="(id, link) => $emit('delete', id, link)"
            v-on:view="(item) => $emit('view', item)"
            :hideEye="false"
            :id="item._id"
            :src="isVideo(item['mime_type']) ? item.thumbnail : item.link"
            :alt="item.name"
            :info="item"
            :isMediaAsset="true"
          />
        </div>
      </div>
    </DynamicScrollerItem>

    <template v-slot:after>
      <beat-loader v-show="atBottom" :color="'#436aff'"></beat-loader>
    </template>
  </DynamicScroller>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import Asset from './Asset'

export default {
  name: 'VirtualAssets',
  components: {
    Asset,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    atBottom: {
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      count: 0,
      arrItems: []
    }
  },

  watch: {
    assets (newVal, oldVal) {
      this.getData(newVal, 6)
    }
  },
  mounted () {
    this.getData(this.assets, 6)
  },
  methods: {
    isVideo (mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },
    spliteArray (arr, size) {
      if (this.count !== arr.length) {
        const arrLength = arr.length
        arr = arr.slice(this.count, arrLength)
        this.count += arrLength - this.count
      }
      //
      // console.debug('ADSASD a', arr, this.count, arr.length)
      while (arr.length > 0) {
        let chunk = []

        if (this.arrItems.length !== 0) {
          const lastObj = { ...this.arrItems[this.arrItems.length - 1] }.chunk

          if (lastObj.length < 3) {
            console.log(3 - lastObj.length, 'small')
            chunk = arr.splice(0, 3 - lastObj.length)

            this.arrItems[this.arrItems.length - 1] = {
              id: this.generateId(10),
              chunk: [...lastObj, ...chunk]
            }

            console.log(lastObj)
            continue
          }
        }

        chunk = arr.splice(0, size)
        // chunk = arr.splice(0, size)
        this.arrItems.push({
          id: this.generateId(10),
          chunk
        })
      }
    },
    getData (assets, chunk) {
      console.debug('ADSASD', this.count)

      if (this.count === 0 || this.count === undefined) {
        this.count = assets.length
      }

      return this.spliteArray([...assets], chunk)
    },
    // generateId :: Integer -> String
    generateId (len) {
      // dec2hex :: Integer -> String
      // i.e. 0-255 -> '00'-'ff'
      function dec2hex (dec) {
        return dec.toString(16).padStart(2, '0')
      }
      const arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, dec2hex).join('')
    }
  }
}
</script>

<style scoped></style>
