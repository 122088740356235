<template>
  <div id="tiktok_tab">

<!--    Mobile Preview-->
    <div v-if="previewMode === 'Mobile'" class="rounded-3xl w-full overflow-scroll relative app__videos">
      <div class="w-full relative video">
        <video autoplay loop muted class="object-cover w-full h-full video__player" :poster="details.video.thumbnail">
          <source :src="details.video.link" type="video/mp4" />
          <source :src="details.video.link" type="video/ogg" />
          Your browser does not support the video tag.
        </video>

        <!-- sidebar -->
        <div class="absolute videoSidebar">
             <div class="text-center mb-15px videoSidebar__button">
          <img class="h-8 w-8 rounded-full" :src="account.platform_logo" onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'" alt="Logo">
          </div>
          <div class="text-center mb-15px videoSidebar__button">
          <i class="text-2xl fas fa-heart"></i>
            <p>12</p>
          </div>

          <div class="text-center mb-15px	videoSidebar__button">
            <i class="text-2xl fas fa-comment"></i>
            <p>23</p>
          </div>

          <div class="text-center mb-15px videoSidebar__button">
           <i class="text-2xl fas fa-share"></i>
            <p>75</p>
          </div>
        </div>

        <!-- footer -->
        <!-- <div class="relative flex videoFooter">
          <div class="flex-1 videoFooter__text">
            <p class="videoFooter__description">Best Video Ever</p>

            <div class="videoFooter__ticker">
                <i class="fas fa-music"></i>
              <marquee>Song name</marquee>
            </div>
          </div>
          <img
            src="https://static.thenounproject.com/png/934821-200.png"
            alt=""
            class="videoFooter__record"
          />
        </div> -->

      </div>
      <!-- video ends -->
          <!-- </div> -->
    </div>
<!-- Desktop Preview -->
    <div v-else-if="previewMode === 'Desktop'" class="p-3">
      <div class="flex flex-column space-y-4">
        <div class="flex flex-row items-center space-x-4">
          <img class="h-10 w-10 rounded-full" :src="account.platform_logo" onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'" alt="ChitChat Logo">
          <div class="text-xl font-medium text-black">{{account.platform_name}}</div>
        </div>
        <div class="flex flex-row space-x-2">
          <video
              width="93%"
              height="240px"
              loop
              muted
              controls
              :poster="details.video.thumbnail"
          >
            <source :src="details.video.link" type="video/mp4" />
            <source :src="details.video.link" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
          <div class="flex flex-column-reverse w-1/5">
            <div class="flex flex-column w-9 h-9 shrink-0 text-center justify-center my-2 bg-gray-300 rounded-full">
              <i class="fas fa-share text-lg"></i>
<!--              <strong>365</strong>-->
            </div>
            <div class="flex flex-column w-9 h-9 shrink-0 text-center justify-center my-2 bg-gray-300 rounded-full">
              <i class="fas fa-comment-dots text-lg"></i>
<!--              <strong>1159</strong>-->
            </div>
            <div class="flex flex-column w-9 h-9 shrink-0 text-center justify-center my-2 bg-gray-300 rounded-full">
              <div>
                <i class="fas fa-heart text-lg"></i>
              </div>
<!--              <strong >285</strong>-->
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="flex flex-row">
        <div class="shrink-0 mr-2.5">
          <img class="h-10 w-10 rounded-full" :src="account.platform_logo" onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'" alt="ChitChat Logo">
        </div>
        <div>
          <div class="text-xl font-medium text-black mb-2.5">{{ account.platform_name }} &lt;!&ndash;<span class="text-xs ml-2">Hassan Ali</span>&ndash;&gt;</div>
          &lt;!&ndash; <p class="text-cs-slate">Your tiktok description and captions!</p> &ndash;&gt;

          <div class="flex">
            <video
                width="85%"
                height="240px"
                controls
                :poster="details.video.thumbnail"
            >
              <source :src="details.video.link" type="video/mp4" />
              <source :src="details.video.link" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
            <div class="flex flex-column-reverse w-1/5">
              <div class="flex flex-column w-11 shrink-0 text-center my-2">
                <i class="fas fa-share text-xl"></i>
                <strong>365</strong>
              </div>
              <div class="flex flex-column w-11 shrink-0 text-center my-2">
                <i class="fas fa-comment-dots text-xl"></i>
                <strong>1159</strong>
              </div>
              <div class="flex flex-column w-11 shrink-0 text-center my-2">
                <div>
                  <i class="fas fa-heart text-xl"></i>
                </div>
                <strong >285</strong>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import {encode} from "html-entities";
import {mapGetters} from "vuex";

export default {
  name: "TiktokPreview",
  data () {
    return {
      PreviewMode: 'Desktop'
    }
  },
  props: ['details', 'options', 'account', 'planner', 'data', 'previewMode'],

  methods: {
  },
  computed: {
    ...mapGetters(['getPreviewAccount', 'getSocialSharingFirstComment'])
  }
}
</script>

<style scoped>
.app__videos {
  height: 495px;
  background-color: rgb(146, 42, 42);
  scroll-snap-type: y mandatory;
}

.app__videos::-webkit-scrollbar {
  display: none;
}
.app__videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.video {
  height: 100%;
  background-color:#fff;
  scroll-snap-align: start;
}

.videoSidebar {
  top: 44%;
  right: 8px;
}
.videoSidebar_desktop_view{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.videoSidebar i {
  color: white;
}
.videoSidebar__button {
  margin-bottom: 15px
}
.videoSidebar__button i{
  color: white;
}
.desktop_view i{
  color: black !important;
}

.videoSidebar__button p{
    font-size: 10px;
    color: #fff;
}
.videoFooter {
  bottom: 100px;
  margin-left: 20px;
  color: white;
  margin-top: 25px;
}

@keyframes spinTheRecord {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.videoFooter__record {
  animation: spinTheRecord infinite 5s linear;
  height: 50px;
  filter: invert(1);
  position: absolute;
  bottom: -9px;
  right: 3px;
}
.videoFooter__text h3 {
  padding-bottom: 10px;
  font-size: 15px;
}
.videoFooter__ticker {
  display: flex;
  align-items: center;
}

.videoFooter__ticker marquee {
  height: fit-content;
  margin-left: 30px;
  width: 60%;
}

.videoFooter__description {
  padding-bottom: 5px;
}
@media (max-width: 1400px) {
  .app__videos {
    width:100%;
    height: 360px !important;
    max-width: 100%;
    border-radius: 20px !important;
  }
  .videoSidebar {
    top: 34% !important;
    right: 8px;
}
.videoFooter{
  bottom: 118px;
}
}

</style>
