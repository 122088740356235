<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-2">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-violet"
      >
        <img
          src="@assets/img/integration/tumblr-icon.svg"
          alt=" "
          class="w-5"
        />
      </div>
    </div>
    <div class="flex items-center px-3 py-2">
      <img
        class="object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.platform_logo"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-medium text-black">
        {{ account.platform_name }}
      </div>
      <span class="px-1 font-medium text-blue-500">Follow</span>
      <span class="ml-auto block font-bold"
        ><i class="fas fa-ellipsis-h"></i
      ></span>
    </div>
    <div class="w-full h-full">
      <div v-if="detail.image && detail.image.length > 0" class="w-100 h-100">
        <div class="flex flex-wrap relative overflow-hidden">
          <div
            class="flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
            :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
            @click="handleImagePreview"
          >
            <img
              class="max-w-full max-h-full w-full opacity-0"
              :src="detail.image[0]"
              alt=""
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white"
      >
        <img
          class="w-full min-h-[190px] max-h-[500px]"
          :src="detail.video.thumbnail"
          alt=""
        />
        <i
          class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid"
          style="transform: translate(-50%, -50%)"
          @click.prevent="displayFile('video', detail.video.link, 0)"
        ></i>
      </div>
    </div>
    <div v-if="detail.message" class="items-center py-3 px-3">
      <span class="whitespace-pre-wrap" v-html="getDescription"></span>
    </div>
    <template v-if="type === 'composer'">
      <div class="flex items-center px-3 py-1">
        <div class="bg-blue-600 text-white rounded-full mx-1"
          ><i class="fas fa-comment m-1"> </i
        ></div>
        <div class="bg-green-600 text-white rounded-full mx-1"
          ><i class="fas fa-retweet m-1"> </i
        ></div>
        <div class="bg-red-600 text-white rounded-full mx-1"
          ><i class="fas fa-heart m-1"> </i
        ></div>
        <span class="mx-1 text-gray-800 font-bold">513 notes</span>
      </div>
      <div
        class="flex items-center mt-2 mx-4 px-1 py-3 reaction-icons justify-between text-base"
        style="border-top: 1px solid #dee2e6"
      >
        <i class="far fa-share"></i>
        <i class="far fa-comment"></i>
        <i class="far fa-retweet"></i>
        <i class="far fa-heart"></i>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'TumblrPreview',
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      charLimit: 600,
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
  },
  methods: {
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type: type,
        media: media,
        index: index,
      })
      this.$bvModal.show('display-file-modal')
    },
    handleImagePreview(index) {
      if (this.type === 'feed_view')
        this.$emit('preview-images', { images: this.detail.image[0], index: 0 })
    },
  },
}
</script>

<style scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
</style>
