<template>
  <b-modal
    id="create_topic_modal"
    ref="create_topic_modal"
    content-class="height-auto"
    modal-class="create_topic_modal full_screen"
    hide-footer
    hide-header
    @show="saveBtnDisabled = false"
  >
    <div class="modal_head justify_end position-absolute-right">
      <!--<h2>Video Preview</h2>-->
      <button
        type="button"
        class="close_icon float-right"
        data-dismiss="modal"
        @click="$bvModal.hide('create_topic_modal')"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <div class="top_content">
        <h2>{{ topicTitle }} Your Custom Topic Feed</h2>
        <p
          >Add relevant keywords and domains along with excluding irrelevant
          terms and sources to build a highly customized topic feed.</p
        >
      </div>
      <div class="d-flex topic_content_main align-items-start">
        <div
          :class="{ open: !showPreviewSnippet() }"
          class="topic_left basic_form"
        >
          <div class="topic_inner topic_common_style">
            <div class="input_field no_margin">
              <!--{{getTopicSelection}}
                            {{getTopicsFilters}}-->

              <label class="heading_label no_margin" for="">Topic Name</label>
              <input
                v-model="getTopicLabel.name"
                type="text"
                placeholder="Give a name to your topic such as, ‘Digital Marketing’"
                data-cy="topic-name"
              />
            </div>
            <div class="input_field">
              <SourceFrom></SourceFrom>
              <AnyKeywords></AnyKeywords>
              <IncludeDomains></IncludeDomains>
            </div>

            <MustAlsoContainsKeywords></MustAlsoContainsKeywords>
            <MustNotContainsKeywords></MustNotContainsKeywords>

            <!--<div class="input_field">
                            <label for="">Each result must <span class="bold">NOT</span> contain any of these keywords</label>
                            <input type="text" placeholder="Enter keywords that you think are giving irrelevant results, e.g. job, course…">
                        </div>-->

            <ExcludeDomains></ExcludeDomains>
            <LimitDomainsResults></LimitDomainsResults>

            <div class="input_field">
              <label class="heading_label no_margin" for=""
                >Set Default Filters</label
              >
              <div class="filter_block">
                <div
                  v-tooltip.top-center="'Sort By'"
                  style="min-width: 160px"
                  class="dropdown with_background_open_blue option_dropdown default_style_dropdown"
                >
                  <FilterSort
                    :is-topic="true"
                    :is-create-topic="true"
                  ></FilterSort>
                </div>
                <div
                  v-tooltip.top-center="'Region'"
                  class="dropdown with_background_open_blue option_dropdown default_style_dropdown"
                >
                  <FilterRegion
                    :is-topic="true"
                    :is-create-topic="true"
                  ></FilterRegion>
                </div>
                <div
                  v-tooltip.top-center="'Language'"
                  class="dropdown option_dropdown with_background_open_blue default_style_dropdown"
                >
                  <FilterLanguage
                    :is-topic="true"
                    :is-create-topic="true"
                  ></FilterLanguage>
                </div>
                <div
                  v-tooltip.top-center="'Length'"
                  class="dropdown option_dropdown with_background_open_blue default_style_dropdown hide"
                >
                  <FilterType
                    :is-topic="true"
                    :is-create-topic="true"
                  ></FilterType>
                </div>

                <div class="create_topic_calendar d-inline-block align-middle">
                  <template v-if="getTopicCreationModal">
                    <FilterDate
                      :is-topic="true"
                      :is-create-topic="true"
                    ></FilterDate>
                  </template>
                </div>
              </div>
            </div>

            <div class="input_field">
              <label class="heading_label no_margin" for=""
                >Matching Criteria</label
              >
              <div class="radio_block">
                <div class="radio_input_image block">
                  <input
                    id="t1"
                    v-model="getTopicSelection.match_type"
                    value="title"
                    checked
                    type="radio"
                    name="m1"
                    @change.prevent="changeSelectionType"
                  />
                  <label class="radio_left" for="t1"
                    >Match query in titles only</label
                  >
                </div>
                <div class="radio_input_image block">
                  <input
                    id="t2"
                    v-model="getTopicSelection.match_type"
                    value="title_body"
                    type="radio"
                    name="m1"
                    @change.prevent="changeSelectionType"
                  />
                  <label class="radio_left" for="t2"
                    >Match query in titles and body content.</label
                  >
                </div>
              </div>
            </div>

            <!--<div class="input_field">-->
            <!--<div class="query_builder_result">-->
            <!--<label class="heading_label no_margin" for="">Query</label>-->
            <!--<p>(recipes) <span class="bold">AND</span> (health <span class="bold">OR</span>-->
            <!--healthy <span class="bold">OR</span> nutritions <span class="bold">OR</span> heart)</p>-->
            <!--</div>-->
            <!--</div>-->

            <div class="input_field">
              <button
                :disabled="saveBtnDisabled"
                class="btn btn-studio-theme-space"
                data-cy="save-topic"
                @click.prevent="updateTopic"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
        <!--v-show="showPreviewSnippet()"-->
        <div class="topic_right" :class="{ open: showPreviewSnippet() }">
          <PreviewSuggestion></PreviewSuggestion>
        </div>
      </div>
    </div>
  </b-modal>

  <!--<div class="modal fade create_topic_modal full_screen" id="create_topic_modal">-->
  <!--<div class="modal-dialog">-->
  <!--<div class="modal-content">-->
  <!--<div class="modal_head">-->
  <!--<button @click.prevent="dismissModal()" type="button" class="close">&times;</button>-->
  <!--</div>-->
  <!--<div class="modal_body">-->
  <!--<div class="top_content">-->
  <!--<h2>{{topicTitle}} Your Custom Topic Feed</h2>-->
  <!--<p>Add relevant keywords and domains along with excluding irrelevant terms and sources to-->
  <!--build a highly customized topic feed.</p>-->
  <!--</div>-->
  <!--<div class="d-flex topic_content_main align-items-start">-->
  <!--<div :class="{'open' : !showPreviewSnippet()}" class="topic_left basic_form">-->
  <!--<div class="topic_inner topic_common_style">-->
  <!--<div class="input_field no_margin">-->
  <!--&lt;!&ndash;{{getTopicSelection}}-->
  <!--{{getTopicsFilters}}&ndash;&gt;-->

  <!--<label class="heading_label no_margin" for="">Topic Name</label>-->
  <!--<input type="text" v-model="getTopicLabel.name"-->
  <!--placeholder="Give a name to your topic such as, ‘Digital Marketing’">-->
  <!--</div>-->
  <!--<div class="input_field">-->
  <!--<source-from></source-from>-->
  <!--<any-keywords></any-keywords>-->
  <!--<include-domains></include-domains>-->

  <!--</div>-->

  <!--<must-also-contains-keywords></must-also-contains-keywords>-->
  <!--<must-not-contains-keywords></must-not-contains-keywords>-->

  <!--&lt;!&ndash;<div class="input_field">-->
  <!--<label for="">Each result must <span class="bold">NOT</span> contain any of these keywords</label>-->
  <!--<input type="text" placeholder="Enter keywords that you think are giving irrelevant results, e.g. job, course…">-->
  <!--</div>&ndash;&gt;-->

  <!--<exclude-domains></exclude-domains>-->
  <!--<limit-domains-results></limit-domains-results>-->

  <!--<div class="input_field">-->
  <!--<label class="heading_label no_margin" for="">Set Default Filters</label>-->
  <!--<div class="filter_block">-->

  <!--<div style="min-width: 160px;" v-tooltip.top-center="'Sort By'"-->
  <!--class="dropdown with_background_open_blue option_dropdown default_style_dropdown">-->
  <!--<filter-sort :isTopic="true" :isCreateTopic="true"></filter-sort>-->
  <!--</div>-->
  <!--<div v-tooltip.top-center="'Region'"-->
  <!--class="dropdown with_background_open_blue option_dropdown default_style_dropdown">-->
  <!--<filter-region :isTopic="true" :isCreateTopic="true"></filter-region>-->
  <!--</div>-->
  <!--<div v-tooltip.top-center="'Language'"-->
  <!--class="dropdown option_dropdown with_background_open_blue default_style_dropdown ">-->
  <!--<filter-language :isTopic="true" :isCreateTopic="true"></filter-language>-->
  <!--</div>-->
  <!--<div v-tooltip.top-center="'Length'"-->
  <!--class="dropdown option_dropdown with_background_open_blue default_style_dropdown hide ">-->
  <!--<filter-type :isTopic="true" :isCreateTopic="true"></filter-type>-->
  <!--</div>-->

  <!--<div class="create_topic_calendar d-inline-block align-middle">-->
  <!--<template v-if="getTopicCreationModal">-->
  <!--<filter-date :isTopic="true" :isCreateTopic="true"></filter-date>-->
  <!--</template>-->
  <!--</div>-->

  <!--</div>-->
  <!--</div>-->

  <!--<div class="input_field">-->
  <!--<label class="heading_label no_margin" for="">Matching Criteria</label>-->
  <!--<div class="radio_block">-->
  <!--<div class="radio_input_image block">-->
  <!--<input @change.prevent="changeSelectionType()" value="title" checked-->
  <!--type="radio" id="t1" name="m1"-->
  <!--v-model="getTopicSelection.match_type">-->
  <!--<label class="radio_left" for="t1">Match query in titles only</label>-->
  <!--</div>-->
  <!--<div class="radio_input_image block">-->
  <!--<input @change.prevent="changeSelectionType()" value="title_body"-->
  <!--type="radio" id="t2" name="m1"-->
  <!--v-model="getTopicSelection.match_type">-->
  <!--<label class="radio_left" for="t2">Match query in titles and body-->
  <!--content.</label>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <!--&lt;!&ndash;<div class="input_field">&ndash;&gt;-->
  <!--&lt;!&ndash;<div class="query_builder_result">&ndash;&gt;-->
  <!--&lt;!&ndash;<label class="heading_label no_margin" for="">Query</label>&ndash;&gt;-->
  <!--&lt;!&ndash;<p>(recipes) <span class="bold">AND</span> (health <span class="bold">OR</span>&ndash;&gt;-->
  <!--&lt;!&ndash;healthy <span class="bold">OR</span> nutritions <span class="bold">OR</span> heart)</p>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->

  <!--<div class="input_field">-->
  <!--<button @click.prevent="updateTopic()" class="btn blue_gradient large_btn">-->
  <!--<span>Save</span>-->
  <!--</button>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--&lt;!&ndash;v-show="showPreviewSnippet()"&ndash;&gt;-->
  <!--<div class="topic_right" :class="{'open' : showPreviewSnippet()}">-->
  <!--<preview-suggestion></preview-suggestion>-->
  <!--</div>-->

  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { saveCustomTopicURL } from '@src/modules/discovery/config/api-utils'
import { TOPIC_VALIDATION, TOPIC_SAVED } from '@common/constants/messages'
import { topicTypes } from '@src/modules/discovery/store/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import FilterType from '../filters/FilterType'
import FilterSort from '../filters/FilterSort'
import FilterRegion from '../filters/FilterRegion'
import FilterLanguage from '../filters/FilterLanguage'
import FilterDate from '../filters/FilterDate'
import SourceFrom from './selection/SourceFrom'
import AnyKeywords from './selection/AnyKeywords'
import IncludeDomains from './selection/IncludeDomains'
import MustAlsoContainsKeywords from './selection/MustAlsoContainsKeywords'
import MustNotContainsKeywords from './selection/MustNotContainsKeywords'
import ExcludeDomains from './selection/ExcludeDomains'
import LimitDomainsResults from './selection/LimitDomainsResults'
import PreviewSuggestion from './PreviewSuggestion'
export default {
  components: {
    FilterType,
    FilterSort,
    FilterRegion,
    FilterLanguage,
    SourceFrom,
    AnyKeywords,
    IncludeDomains,
    MustAlsoContainsKeywords,
    MustNotContainsKeywords,
    ExcludeDomains,
    LimitDomainsResults,
    PreviewSuggestion,
    FilterDate,
  },
  data() {
    return {
      topicTitle: 'Create',
      mustNotContainsKeywordValue: null,
      mustExcludeKeywordsValue: null,
      limitDomainsResultsValue: null,
      isLoading: false,
      include_suggetion_list: [],
      exclude_suggestion_list: [],
      saveBtnDisabled: false,
    }
  },
  computed: {
    ...mapGetters([
      'getTopicLabel',
      'getTopicSelection',
      'getTopicsFilters',
      'getPreviewSuggestion',
      'getTopic',
      'getTopics',
      'getWorkspaces',
      'getBeforeRegion',
      'getBeforeLanguage',
      'getBeforeSort',
      'getBeforeDate',
      'getTopicCreationModal',
      'getBeforeDate',
      'getTopics',
      'getBeforeTopicName',
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_TOPICS_SORT',
      'SET_TOPICS_LANGUAGE',
      'SET_TOPICS_REGION',
      'SET_TOPICS_DATE_RANGE',
      topicTypes.SET_TOPIC_NAME,
    ]),
    ...mapActions([
      'setTopicCreationModal',
      'articlesPreviewSuggestion',
      'topicsContent',
    ]),

    changeSelectionType() {
      console.log('ChangeSelectionType')
      this.articlesPreviewSuggestion()
    },
    updateTopic() {
      this.saveBtnDisabled = true
      // topic label name
      if (!this.getTopicLabel.name) {
        this.alertMessage(TOPIC_VALIDATION.TOPIC_NAME, 'error')
        this.saveBtnDisabled = false
        return
      }

      // if no keywords or domains, show the error message.

      if (!this.showPreviewSnippet()) {
        this.alertMessage(TOPIC_VALIDATION.TOPIC_KEYWORDS, 'error')
        this.saveBtnDisabled = false
        return
      }

      // perform a server request

      this.postRequest(
        saveCustomTopicURL,
        {
          selection: this.getTopicSelection,
          filters: this.getTopicsFilters,
          id: this.getTopic,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        async (response) => {
          if (response.data.status) {
            if (
              !this.getActiveWorkspace.onboarding_steps.discover_content.status
            ) {
              await this.onboardingStepsCompleted('discover_content')
            }
            this.setTopicCreationModal(false)
            this.alertMessage(TOPIC_SAVED, 'success')
            EventBus.$emit('refresh-topics-post')
            if (!this.getTopic) {
              this.getTopics.unshift(response.data.topic)
            } else {
              const topicItem = this.getTopics.findIndex(
                (obj) => obj._id === this.getTopic
              )
              if (topicItem !== -1) {
                this.getTopics[topicItem] = response.data.topic
                this.getTopics.splice(topicItem, 1)
                this.getTopics.splice(topicItem, 0, response.data.topic)
              }
            }
            //   this.$store.dispatch('trackEvent', { event: 'custom_topics_created' })
            // $('#create_topic_modal').modal('hide')
            this.$bvModal.hide('create_topic_modal')

            if (this.getBeforeTopicName) {
              this[topicTypes.SET_TOPIC_NAME](this.getBeforeTopicName)
            }
            this.topicsContent()
          }
        },
        (response) => {}
      )
    },
    dismissModal() {
      this.setTopicCreationModal(false)
      if (this.getBeforeRegion.code) {
        this.SET_TOPICS_REGION(this.getBeforeRegion)
      }

      if (this.getBeforeLanguage.code) {
        this.SET_TOPICS_LANGUAGE(this.getBeforeLanguage)
      }
      if (this.getBeforeSort) {
        this.SET_TOPICS_SORT(this.getBeforeSort)
      }
      if (this.getBeforeDate.label) {
        this.SET_TOPICS_DATE_RANGE(this.getBeforeDate)
      }
      if (this.getBeforeTopicName) {
        this[topicTypes.SET_TOPIC_NAME](this.getBeforeTopicName)
      }

      // $('#create_topic_modal').modal('hide')
      this.$bvModal.hide('create_topic_modal')
    },
  },
}
</script>
