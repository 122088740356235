<template>
  <div>
    <!--    Caption form-->
    <label class="mt-4 mb-2 text-sm text-[#3a4557] font-bold"
      >Caption Name</label
    >
    <input
      v-model="captionTitle"
      type="text"
      placeholder="Name this caption..."
      class="border-[1px] border-solid border-[#7070704d] w-100 px-2 text-[#3a4557] rounded py-3 bg-transparent hover:border-[#2f8ae0] focus-within:border-[#2f8ae0]"
      maxlength="50"
    />
    <div
      v-if="formErrors.captionTitle"
      class="text-[#ff0000] text-xs mt-2 pl-2.5"
      >* Caption name is compulsory!
    </div>
    <label class="mt-8 mb-4 text-sm text-[#3a4557] font-bold"
      >Description</label
    >
    <textarea
      v-model="captionDescription"
      rows="10"
      placeholder="The text for the caption..."
      class="border-[1px] border-solid border-[#7070704d] w-100 px-2 text-[#3a4557] rounded py-3 bg-transparent hover:border-[#2f8ae0] focus-within:border-[#2f8ae0]"
    />
    <div
      v-if="formErrors.captionDescription"
      class="text-[#ff0000] text-xs mt-2 pl-2.5"
      >* Caption description is compulsory!
    </div>

    <!--    FOOTER-->
    <div class="flex w-100">
      <div v-if="viewMode === 'edit'" class="text-left flex w-100">
        <div
          class="py-0 px-4 my-[0.7rem] mx-0 border-[1px] border-solid border-[#9ca1aa] border-l-0 border-t-0 border-b-0"
        >
          <p class="font-medium text-base"
            ><b class="uppercase text-[#9ca1aa] text-[0.8rem]">Used</b> <br />{{
              captionToUpdate.value?.times_used || 0
            }}
            times
          </p>
        </div>
        <div class="py-0 px-4 my-[0.7rem] mx-0">
          <p class="font-medium text-base"
            ><b class="uppercase text-[#9ca1aa] text-[0.8rem]">Last Used</b>
            <br />{{ lastUsed }}
          </p>
        </div>
      </div>
      <div class="flex pr-1 w-100 justify-end items-center">
        <button
          class="flex justify-center items-center py-3 px-12 border-0 m-2 mr-0 white-space-nowrap text-sm font-medium rounded-lg text-white text-center bg-blue-500 hover:bg-blue-400 max-h-[2.6rem]"
          :disabled="captionProcessing"
          @click="submitCaption"
        >
          <clip-loader
            v-if="captionProcessing"
            class="mr-2"
            color="#989eb5"
            :size="'20px'"
          />
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { computed, defineComponent, reactive, ref } from 'vue'
import { useStore } from '@state/base'

// constants
import proxy from '@common/lib/http-common'
import { saveCaptionApiUrl, updateCaptionApiUrl } from '@src/config/api-utils'
import { commonMethods } from '@src/modules/inbox/store/common-methods'

export default defineComponent({
  name: 'AddEditCaption',
  props: {
    viewMode: {
      type: String,
      default: 'list',
    },
    captionTitle: {
      type: String,
      default: '',
    },
    captionText: {
      type: String,
      default: '',
    },
    workSpaceId: {
      type: String,
      default: '',
    },
    changeViewMode: {
      type: Function,
      default: () => {},
    },
    captionToUpdate: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore()
    const captionDescription = ref(props.captionText)
    const captionTitle = ref(props.captionTitle)
    const captionProcessing = ref(false)
    const formErrors = reactive({
      captionTitle: false,
      workSpaceId: false,
      captionDescription: false,
    })

    // computed properties
    /**
     * * Computes the time elapsed since the last time the current caption was used.
     *  * If the caption has never been used before, returns the string "Never".
     * @type {ComputedRef<string|string>}
     */
    const lastUsed = computed(() =>
      props.captionToUpdate.value?.last_used
        ? commonMethods.timesAgo(props.captionToUpdate.value?.last_used)
        : 'Never'
    )

    /**
     * * Computes the text to display on the button in the caption editor form.
     *  * If the view mode is "edit", returns the string "Update".
     *  * If the view mode is "create", returns the string "Create".
     * @type {ComputedRef<string>}
     */
    const buttonText = computed(() =>
      props.viewMode === 'edit' ? 'Update' : 'Create'
    )

    // methods
    /**
     * save or update caption
     * @returns {Promise<void>}
     */
    const submitCaption = async () => {
      // caption title, description or workspace id is empty or contains white spaces only then return
      if (
        !captionTitle.value?.trim() ||
        !props.workSpaceId ||
        !captionDescription.value?.trim()
      ) {
        formErrors.captionTitle = !captionTitle.value?.trim()
        formErrors.workSpaceId = !props.workSpaceId
        formErrors.captionDescription = !captionDescription.value?.trim()
        return
      }
      // process caption
      try {
        const payload = {
          workspace_id: props.workSpaceId,
          title: captionTitle.value,
          description: captionDescription.value,
        }
        if (props.viewMode === 'edit') {
          payload.caption_id = props.captionToUpdate?.value?._id
        }
        captionProcessing.value = true
        const response =
          props.viewMode === 'edit'
            ? await proxy.put(updateCaptionApiUrl, payload)
            : await proxy.post(saveCaptionApiUrl, payload)
        await store.dispatch('toastNotification', {
          message: response?.data?.message,
          type: 'success',
        })
        captionProcessing.value = false
        // reset state of whole component
        resetState()
        // change view back to listing
        props.changeViewMode('list')
      } catch (error) {
        captionProcessing.value = false
        await store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }
    /**
     * reset title,description and form error state
     */
    const resetState = () => {
      captionDescription.value = props.captionText
      captionTitle.value = ''
      captionProcessing.value = false
      formErrors.captionTitle = false
      formErrors.workSpaceId = false
      formErrors.captionDescription = false
    }

    return {
      captionDescription,
      captionTitle,
      captionProcessing,
      submitCaption,
      resetState,
      formErrors,
      lastUsed,
      buttonText,
    }
  },
})
</script>
