<template>
  <div class="article-search-bar-pane__search__input__question">
    <div
      class="dropdown dropdown-hints-suggestions default_style_dropdown prevent_close_dropdown"
    >
      <div
        class="dropdown_header"
        v-tooltip.top-center="'Search Tips'"
        data-toggle="dropdown"
      >
        <i class="icon-help-cs"></i>
      </div>
      <div class="dropdown-menu dropdown-menu-right z-99">
        <div class="search_detail">
          <p>Search by</p>
          <table class="table">
            <tbody>
              <tr>
                <td>Search for a keyword:</td>
                <td>marketing</td>
              </tr>
              <tr>
                <td>Search for two keywords:</td>
                <td>content marketing</td>
              </tr>
              <tr>
                <td>Search for an exact phrase:</td>
                <td>"digital marketing"</td>
              </tr>

              <tr>
                <td>Remove irrelevant results:</td>
                <td>marketing -seo -stitcher.com</td>
              </tr>
              <tr>
                <td>Search for multiple keywords:</td>
                <td>search marketing OR SEO OR SEM</td>
              </tr>
              <tr>
                <td>Search for multiple keywords:</td>
                <td>content AND marketing</td>
              </tr>
              <tr>
                <td>Search by site:</td>
                <td>nytimes.com</td>
              </tr>
              <tr>
                <td>Search by site and multiple keywords:</td>
                <td>site:foxnews.com trump AND putin</td>
              </tr>
              <tr>
                <td>Search for keywords in a site:</td>
                <td>forbes.com marketing</td>
              </tr>
              <tr>
                <td>Search by author:</td>
                <td>author:'Joe Pulizzi'</td>
              </tr>
              <tr>
                <td>Search by sharer:</td>
                <td>@jaybaer</td>
              </tr>
              <!--                        <tr>-->
              <!--                            <td>Search by hashtag:</td>-->
              <!--                            <td>#marketing</td>-->
              <!--                        </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
}
</script>
