<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-2">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
      >
        <img
          src="@assets/img/integration/pinterest-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="detail.image && detail.image.length > 0" class="w-100 h-100">
      <div class="flex flex-wrap relative rounded-t-3xl overflow-hidden">
        <div
          class="bg-cover bg-no-repeat bg-center w-full max-h-[500px] min-h-[300px]"
          :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
          @click="handleImagePreview"
        >
          <img
            class="max-w-full max-h-full w-full opacity-0"
            :src="detail.image[0]"
            alt=""
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
      </div>
    </div>
    <div v-else-if="detail.video.link">
      <video
        ref="pinterestPreview"
        controls
        class="w-100 h-100"
        :poster="getThumbnail"
      >
        <source :src="detail.video.link" :type="detail.video['mime_type']" />
        Your browser does not support the video tag.
      </video>
    </div>
    <img
      v-else
      class="w-100 min-h-48 max-h-[33rem]"
      src="@src/assets/img/no_data_images/no_media_found.svg"
      alt=""
    />
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        {{ account.name }}
        <span
          v-if="followersCount"
          class="block font-normal text-xs text-gray-800"
          >{{ followersCount }} followers</span
        >
      </div>
      <span class="ml-auto block font-bold bg-gray-200 py-2 px-3 rounded-full"
        >Follow</span
      >
    </div>
    <div
      v-if="detail.pinterest_title || detail.message"
      class="flex items-center px-4 py-2"
    >
      <div class="text-center text-black w-100">
        <span
          v-if="detail.pinterest_title"
          class="block font-bold text-lg py-1"
          >{{ detail.pinterest_title }}</span
        >
        <span
          class="block font-normal text-sm whitespace-pre-wrap"
          v-html="getDescription"
        ></span>
      </div>
    </div>
    <div
      v-if="type === 'composer'"
      class="flex items-center px-3 py-3 justify-between reaction-icons"
    >
      <div class="text-2xl block">
        <i class="fas fa-comment mx-2"></i>
        <i class="far fa-heart mx-2"></i>
      </div>
      <div class="block">
        <span class="font-bold bg-gray-200 py-2 px-3 rounded-full mx-1"
          >View</span
        >
        <span class="font-bold pin_bg py-2 px-3 rounded-full text-white mx-1"
          >Save</span
        >
      </div>
      <div class="block">
        <i class="fas fa-ellipsis-h"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'PinterestPreview',
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    followersCount: {
      type: Number,
      default: 0,
      required: false,
    },
    sourceUrl: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      charLimit: 500,
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    getThumbnail() {
      return this.detail?.video?.thumbnail
    },
  },
  watch: {
    detail: {
      handler(oldVal, newVal) {
        if (
          newVal.video?.link !== oldVal.video?.link &&
          this.$refs.pinterestPreview
        ) {
          this.$refs.pinterestPreview.load()
        }
      },
      deep: true,
    },
  },
  methods: {
    handleImagePreview(index) {
      if (this.type === 'feed_view')
        this.$emit('preview-images', { images: this.detail.image[0], index: 0 })
    },
  },
}
</script>

<style scoped></style>
