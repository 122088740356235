<template>
  <div
    id="replug_dropdown_id"
    class="dropdown data_boundary_dd prevent_close_dropdown ml-1 dropdown default_style_dropdown form_dropdown replug_dropdown sharing_replug_dropdown"
  >
    <div
      data-display="static"
      class="min_width dropdown_header"
      data-toggle="dropdown"
      @click.prevent="triggerReplugOption()"
    >
      <i
        style="font-size: 20px"
        class="cs-replug d-block"
        v-tooltip.top-center="'Add Replug CTA'"
      ></i>
    </div>

    <div class="dropdown-menu dropdown-menu-left">
      <ul class="inner">
        <replug-options></replug-options>

        <template v-if="getReplug.item._id">
          <template
            v-if="
              getReplug.brands.length > 0 &&
              getPublishSelection.replug.brand &&
              getPublishSelection.replug.brand.campaign &&
              getPublishSelection.replug.brand.campaign.length > 0
            "
          >
            <div class="field_group m-2">
              <label for="" class="label-animated">URL</label>
              <input
                type="url"
                readonly="readonly"
                placeholder="Add Replug link..."
                v-model="getPublishSelection.replug.link"
              />
            </div>

            <div class="text-center mb-2 mt-3">
              <button
                class="btn btn-studio-theme-space"
                @click.prevent="fetchReplugLink(type)"
                :disabled="getPublishLoaders.fetchReplugLink"
                :class="{ disabled: getPublishLoaders.applyReplug }"
              >
                <span>Apply</span>
                <clip-loader
                  :color="'#ffffff'"
                  :size="'12px'"
                  v-if="getPublishLoaders.fetchReplugLink"
                ></clip-loader>
              </button>
            </div>
          </template>
        </template>
        <!--<div class="text-center mb-2" v-else>-->
        <!--<button class="btn btn-studio-theme-space" @click.prevent="moveToReplugConnection()">-->
        <!--<span>Connect</span>-->
        <!--</button>-->
        <!--</div>-->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReplugOptions from '../../options/ReplugOptions'

export default {
  props: ['type'],
  components: {
    ReplugOptions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getReplug', 'getPublishLoaders'])
  },
  methods: {},

  watch: {}
}
</script>
