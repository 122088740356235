<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <div class="my-4 flex items-center">
      <div class="w-2/12"> Post Type</div>
      <div class="w-10/12 flex items-center">
        <CstRadio
          id="fb-feed"
          class="w-max mr-4"
          name="facebook_post_type"
          :model-value="facebookPostType"
          value="feed"
          @change="setFacebookPostType"
        >
          Feed
        </CstRadio>
        <CstRadio
          id="fb-reel"
          class="w-max mr-4"
          name="facebook_post_type"
          :model-value="facebookPostType"
          value="reel"
          :disabled="isFacebookGroupsSelected && !isFacebookPageSelected"
          @change="setFacebookPostType"
        >
          <div class="flex justify-center">
            Reel

            <v-popover
              trigger="hover"
              placement="bottom-end"
              popover-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <i
                class="far fa-question-circle text-base text-blue-900 ml-1.5"
              ></i>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div>Reel's option is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                </ul>
              </template>
            </v-popover>
          </div>
        </CstRadio>
      </div>
    </div>

    <div class="my-4 flex flex-row items-center">
      <div class="w-2/12">Video Title</div>
      <div class="w-10/12">
        <CstInputFields
          :value="facebookVideoTitle"
          maxlength="100"
          class="w-full"
          type="text"
          size="small"
          @value="setFacebookVideoTitle"
        />
      </div>
    </div>

    <div class="my-2 flex items-center">
      <div class="w-2/12"></div>
      <div class="w-10/12 flex items-center">
        <div class="bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
            <span class="font-semibold">Reels Tab Eligibility:</span>
            <template>
              Reels option is currently only available for Pages. When Group is
              also selected, the system will publish there as a feed post.
              <a
                v-if="facebookPostType === 'feed'"
                href="#"
                data-beacon-article-modal="632bf6d1172d934fa05937d1"
                target="_blank"
                >Learn more.</a
              >
            </template>
            <span
              v-if="facebookPostType !== 'feed'"
              class="block mt-1 leading-4"
            >
              Only video file with a duration between 4 and 60 seconds and
              aspect ratios of 9:16 are eligible to be posted as a Reel post.
              <a
                href="#"
                data-beacon-article-modal="632bf6d1172d934fa05937d1"
                target="_blank"
                >Learn more.</a
              >
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstInputFields from '@ui/Input/CstInputFields'

export default {
  name: 'FacebookOptions',
  components: {
    CstRadio,
    CstInputFields,
  },
  props: {
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    facebookVideoTitle: {
      type: String,
      default: '',
    },
    isFacebookGroupsSelected: {
      type: Boolean,
      default: false,
    },
    isFacebookPageSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isGroupAndPageSelected() {
      return this.isFacebookGroupsSelected && this.isFacebookPageSelected
    },
  },
  watch: {
    isFacebookGroupsSelected(val) {
      if (val) {
        if (!this.isFacebookPageSelected) {
          this.setFacebookPostType('feed')
        }
      }
    },
    isFacebookPageSelected(val) {
      if (!val) {
        this.setFacebookPostType('feed')
      }
    },
  },
  methods: {
    setFacebookPostType(value) {
      this.$emit('setFacebookPostType', value)
    },
    setFacebookVideoTitle(value) {
      this.$emit('setFacebookVideoTitle', value)
    },
  },
}
</script>
