<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_right_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Popular Sentiments </h2>
    <div
      class="topics-insights-container__chart_legends"
      id="popular_sentiments_item_highlight"
    />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        :options="popularSentimentInsights()"
        ref="highcharts_articles_published"
      ></highcharts>
    </div>
  </div>
</template>
<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'

export default {
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  props: {
    sentiments: {}
  },
  computed: {
    isContentNotAvailable () {
      if (this.sentiments.docs) {
        return (
          this.sentiments.docs.positive +
            this.sentiments.docs.negative +
            this.sentiments.docs.neutral ===
          0
        )
      }
      return false
    }
  },
  methods: {
    popularSentimentInsights () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f}</span> with <b>{point.category}</b> sentiment.</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_sentiments_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#745ebc', '#a88cdd'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['-ve', 'Neutral', '+ve'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            pointPadding: 0,
            groupPadding: 0.4
          }
        },
        series: [
          {
            type: 'column',
            name: 'Engagement Per Article',
            data: this.isContentNotAvailable
              ? [121, 143, 42]
              : [
                this.sentiments.engagement.negative,
                this.sentiments.engagement.neutral,
                this.sentiments.engagement.positive
              ],
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#745ebc'],
                [1, '#9e8ae2']
              ]
            }
          },
          {
            type: 'column',
            name: 'Number of Articles',
            data: this.isContentNotAvailable
              ? [44, 43, 43]
              : [
                this.sentiments.docs.negative,
                this.sentiments.docs.neutral,
                this.sentiments.docs.positive
              ],
            yAxis: 1,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#b672e2'],
                [1, '#d1a5ee']
              ]
            }
          }
        ]
      }
      return chartOptions
    }
  }
}
</script>
