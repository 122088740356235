var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discovery_article_component"},[_c('article-preview'),_c('twitter-influencer'),_c('similar-articles'),(_vm.getFollowCuratedLoader.curated_view)?_c('div',{staticClass:"component_inner pt-5"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1):_c('div',{staticClass:"component_inner"},[_c('div',{staticClass:"top_filter_section bottom_border"},[(
          !_vm.getFilters.displayTopics &&
          !_vm.getSearchLoader.content.discoverySearch
        )?[_c('button',{staticClass:"btn btn_topic_back",on:{"click":function($event){$event.preventDefault();return _vm.viewDisplayTopics(true)}}},[_vm._v("Back")])]:_vm._e(),(_vm.getFilters.topic && _vm.$route.name !== 'articles')?[_c('follow-topics'),_c('div',{},[_c('content-type',{attrs:{"padding":false}})],1)]:[_c('div',{staticClass:"filter_section_inner width_800"},[_c('h3',{staticClass:"filter_section_heading"},[_vm._v("Find the top performing content?")]),_c('div',{staticClass:"search_input search_input_discovery with_icon_left d-flex align-items-center"},[_c('div',{staticClass:"search_inner"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getFilters.search),expression:"getFilters.search"}],staticClass:"discoverySearchInput",staticStyle:{"padding-top":"12px","padding-bottom":"12px"},attrs:{"id":"","type":"text","placeholder":"Search for any topic or domain, e.g ‘marketing’ or ‘cnn.com’","data-cy":"discovery_search_bar"},domProps:{"value":(_vm.getFilters.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.getFilters, "search", $event.target.value)},function($event){return _vm.changeSearchKeyDown($event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchByKeyword(_vm.getFilters.search)}}}),_c('button',{staticClass:"search_btn input_icon search_icon gradient_color",attrs:{"data-cy":"discovery_search_button"},on:{"click":function($event){$event.preventDefault();return _vm.searchByKeyword(_vm.getFilters.search)}}},[_c('i',{staticClass:"icon-Search"})]),_c('search-suggestions')],1),(_vm.getSearchContentType === 'article')?[_c('search-assistant-table')]:_vm._e()],2),_c('content-type')],1),_c('transition',[(_vm.getTopicModule !== 'influencers')?[(
                _vm.getRelatedTopics.length > 0 &&
                !_vm.getDidYouMeanText &&
                _vm.getPosts.length > 0
              )?_c('div',{staticClass:"related_topic_list justify-content-center d-flex align-items-start"},[_c('p',[_vm._v("Related Topics:")]),_c('ul',{staticClass:"related_topic_list_inner"},_vm._l((_vm.getRelatedTopics.slice(0, 20)),function(related_topic){return _c('li',{on:{"click":function($event){$event.preventDefault();return _vm.searchByKeyword(related_topic)}}},[_vm._v(_vm._s(related_topic)+" ")])}),0)]):_vm._e()]:_vm._e()],2)]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getFilters.displayTopics && _vm.getTopicModule === 'content'),expression:"!getFilters.displayTopics && getTopicModule === 'content'"}],staticClass:"ds_inner_pages"},[_c('div',{staticClass:"ds_article_filter"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('channel-filters'),_c('search-article-filters'),_c('search-youtube-filters'),_c('search-twitter-filters'),_c('search-facebook-filters')],1)]),(
          _vm.getSearchContentType === 'article' ||
          _vm.getSearchContentType === 'youtube' ||
          _vm.getSearchContentType === 'facebook' ||
          _vm.getSearchContentType === 'twitter'
        )?[_c('router-view')]:_vm._e()],2),_c('div',{staticClass:"ds_inner_pages"},[_c('overview',{attrs:{"module":'search'}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }