<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'
export default {
  props: ['details'],
  computed: {
    ...mapGetters([
      'getThreadedTweetsSharingDetails',
      'getPreviewAccount',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getTwitterOptions'
    ])
  },
  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    showPreview (index) {
      return (
        this.getThreadedTweetsSharingDetails[index].url &&
        this.getThreadedTweetsSharingDetails[index].url.length
      )
    },
    getValidThumbnail (selectedThumb, tweet) {
      // Getting list of valid thumnbnails for insta
      let thumbnailsList = [...tweet.video.thumbnails_suggestions]
      thumbnailsList = thumbnailsList.filter((thumbnail) => {
        if (!thumbnail.includes('contentstudio-media-library-nearline')) {
          return thumbnail
        }
      })

      if (thumbnailsList.length) {
        // check if is the thumbnail is custom
        if (!selectedThumb.includes('contentstudio-media-library-nearline')) {
          return selectedThumb
        } else {
          return thumbnailsList[0]
        }
      } else {
        return selectedThumb
      }
    }
  }
}
</script>
<template>
  <div v-if="getTwitterOptions.has_threaded_tweets">
    <div
      v-for="(threaded_tweet, key) in getThreadedTweetsSharingDetails"
      :key="key"
    >
      <div
        class="twitter_post_preview multiple-threads-box"
        v-if="
          threaded_tweet.message ||
          threaded_tweet.video.link ||
          threaded_tweet.image.length > 0
        "
      >
        <div class="single-thread">
          <div class="thread-line"></div>
          <div class="twitter_post_preview__head">
            <div class="twitter_post_preview__head__profile_picture">
              <div
                class="twitter_post_preview__head__profile_picture__picture_block thread-profile"
              >
                <img
                  v-if="twitterImage(getPreviewAccount.twitter)"
                  :src="twitterImage(getPreviewAccount.twitter)"
                  alt=""
                />
                <img
                  v-else
                  src="../../../../../../assets/img/profile_default.svg"
                  alt=""
                />
              </div>

              <div
                class="twitter_post_preview__head__profile_picture__text_block"
              >
                <p class="head_text"
                  >{{ getPreviewAccount.twitter.name }}<br /><span
                    class="account_name"
                  >
                    @{{ getPreviewAccount.twitter.screen_name }}</span
                  ></p
                >
              </div>
            </div>
          </div>
          <div class="thread-content">
            <div
              class="twitter_post_preview__head__profile_picture__preview_desc thread-desc"
            >
              <p
                class="desc"
                v-html="
                  processSharingPreviewDesciption(
                    threaded_tweet.message,
                    'ThreadedTweets_' + key
                  )
                "
              ></p>
            </div>
            <!-- <div class="preview_link" v-if="showPreview(key)">
                    <clip-loader :color="'#ffffff'" :size="'16px'" v-if="(getCommonBoxOptions.status && getPublishLoaders.fetchCommonSharingPreview) || getPublishLoaders.fetchTwitterSharingPreview"></clip-loader>
                    <template v-else>

                        <div class="img" v-if="getPreviewImage.length > 0"  :style="{ 'background-image': 'url(' + getResizedImageURL(getPreviewImage[0], 500 , 0)  + ')' }"
                             style="border: 1px solid #eff4f8;"
                             @error="$event.target.src=`https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`"></div>
                        <div class="link_detail">

                            <h3>{{getPreviewTitle}}</h3>
                            <p class="desc">{{getPreviewDescription}}</p>
                            <p class="link">{{getPreviewWebsite}}</p>
                        </div>

                    </template>

                </div> -->
            <template v-if="threaded_tweet.video && threaded_tweet.video.link">
              <div
                class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
              >
                <div
                  class="img"
                  :style="{
                    'background-image':
                      'url(' +
                      getValidThumbnail(
                        threaded_tweet.video.thumbnail,
                        threaded_tweet
                      ) +
                      ')',
                  }"
                >
                  <div class="twitter_vid_icon vid_icon">
                    <span class="icon"></span>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else-if="!threaded_tweet.url && threaded_tweet.image && threaded_tweet.image.length > 0"
              class="twitter_post_preview__preview_images"
            >
              <div
                v-if="threaded_tweet.image.length === 1"
                class="ratio ratio-16x9"
              >
                <!--..........for single image...............-->
                <img
                  class="single_image"
                  :src="threaded_tweet.image[0]"
                  alt=""
                  @error="
                    $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  "
                />
              </div>
              <!--..........for two images...............-->

              <div
                class="multiple_images two_images"
                v-else-if="threaded_tweet.image.length === 2"
              >
                <div
                  class="img"
                  :key="`twitter_preview_${index}`"
                  v-for="(image, index) in threaded_tweet.image"
                  :style="{ 'background-image': 'url(' + image + ')' }"
                ></div>
              </div>

              <!--..........for three images...............-->

              <div
                class="multiple_images three_images"
                v-else-if="threaded_tweet.image.length === 3"
              >
                <div class="img_left">
                  <div
                    class="img"
                    :style="{
                      'background-image':
                        'url(' + threaded_tweet.image[0] + ')',
                    }"
                  ></div>
                </div>
                <div class="img_right">
                  <template
                    v-for="(image, index) in threaded_tweet.image"
                    v-if="index > 0"
                  >
                    <div
                      class="img"
                      :style="{ 'background-image': 'url(' + image + ')' }"
                    ></div>
                  </template>
                </div>
              </div>

              <!--..........for four images images...............-->

              <div class="multiple_images four_images" v-else>
                <div
                  :key="`twitter_preview_${index}`"
                  v-for="(image, index) in threaded_tweet.image"
                  v-if="index < 4"
                  class="img"
                  :style="{ 'background-image': 'url(' + image + ')' }"
                ></div>
              </div>
            </div>
            <div class="rection_icons multiple-threads-box-icons">
              <span>
                <svg viewBox="0 0 24 24" aria-hidden="true" width="20">
                  <g>
                    <path
                      d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                    ></path>
                  </g></svg
              ></span>
              <span>
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="20"
                  class="tweet-icons"
                >
                  <g>
                    <path
                      d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"
                    ></path>
                  </g>
                </svg>
              </span>
              <span>
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="20"
                  class="tweet-icons"
                >
                  <g>
                    <path
                      d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                    ></path>
                  </g></svg
              ></span>
            </div>

            <!-- <div class="location_preview" v-if="getLocationName">
                    <h3>
                        <img src="../../../../../../assets/img/locate.png" />
                        {{getLocationName}}
                    </h3>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.multiple-threads-box {
  /*margin-top:10px; */
  background: white;
  padding: 10px;
}
.single-thread {
  margin: 0px;
  position: relative;
  padding-top: 10px;
}
.single-thread:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.multiple-threads-box-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.thread-line {
  position: absolute;
  background-color: rgb(207, 217, 222);
  width: 2px;
  height: 100%;
  left: 33px;
  top: 0px;
}
.thread-profile {
  z-index: 999;
}
.thread-content {
  padding-left: 48px;
}
</style>
