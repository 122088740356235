var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_field social_content_box social_content_box_multi",class:{
    in_active: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
    editor_hover: _vm.isFileHover,
  },attrs:{"id":"linkedin_sharing_box"},on:{"@dragleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$store.dispatch('leaveFileDragOver', { event: $event, type: 'Linkedin' })},"dragover":function($event){return _vm.$store.dispatch('onFileDragOver', { event: $event, type: 'Linkedin' })},"drop":function($event){return _vm.$store.dispatch('onFileDrop', { event: $event, type: 'Linkedin' })}}},[_vm._m(0),(_vm.isFileHover)?_c('div',{staticClass:"drag_content"},[_c('p',[_vm._v("Drag files here")])]):_vm._e(),_c('div',{staticClass:"text_content text_content_main"},[_c('textarea-autosize',{ref:"linkedin_sharing_message",staticClass:"content_textarea",class:{
        hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
      },attrs:{"id":"linkedin_sharing_message","spellcheck":"false","min-height":55},on:{"input":function($event){return _vm.handleRerender()}},nativeOn:{"keydown":[function($event){return _vm.handleKeysOnPopup.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==66)return null;if(!$event.ctrlKey)return null;return _vm.sharingMessageFormatting('Linkedin', 'bs')},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==73)return null;if(!$event.ctrlKey)return null;return _vm.sharingMessageFormatting('Linkedin', 'is')}],"input":function($event){return _vm.sharingMessageChanges($event, 'Linkedin')},"paste":function($event){return _vm.sharingMessageChanges($event, 'Linkedin')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.sharingMessageChanges($event, 'Linkedin')},"focus":function($event){return _vm.focusSharingBox('Linkedin')},"click":function($event){return _vm.focusSharingBox('Linkedin')}}}),_c('div',{staticClass:"clone_txt",attrs:{"id":"clone_txt_linkedin_tab"}},[_c('span',{staticClass:"desc",class:{
          hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }}),_c('span',{staticClass:"in_active desc",class:{
          hide: _vm.getSocialSharingSelection.activeTab === 'linkedin_tab',
        },on:{"click":function($event){_vm.focusSharingBox('Linkedin'),
            _vm.textareaFocus(_vm.$refs.linkedin_sharing_message)}}}),_c('span',{staticClass:"exceed",class:{
          hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }})]),_c('hashtag-suggestions',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideHashtag),expression:"hideHashtag"}],attrs:{"type":"Linkedin"}}),_c('div',{staticClass:"text_content_bottom"},[(_vm.shouldLockBasedOnSubAddons('caption_generation'))?[(_vm.getSocialSharingSelection.activeTab == 'linkedin_tab')?_c('button',{staticClass:"btn btn-studio-theme-space caption-btn",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('upgrade-plan-dialog')}}},[_c('i',{staticClass:"fas fa-lock mr-2",staticStyle:{"font-size":"0.8rem"}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.getLockBasedOnSubAddonsMessage('caption_generation'),
                placement: 'top-center',
              }),expression:"{\n                content: getLockBasedOnSubAddonsMessage('caption_generation'),\n                placement: 'top-center',\n              }"}]},[_vm._v(" Generate Caption ")])]):_vm._e()]:[(_vm.getSocialSharingSelection.activeTab == 'linkedin_tab')?_c('button',{staticClass:"btn btn-studio-theme-space caption-btn",attrs:{"disabled":_vm.getPublishLoaders.fetchLinkedinShortLinks ||
          !_vm.getLinkedinSharingDetails.url},on:{"click":function($event){return _vm.fetchAiCaption(_vm.getLinkedinSharingDetails.url, 'Linkedin')}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.getLinkedinSharingDetails.url
              ? null
              : 'Caption generation button is disabled until a valid URL is put in the composer box',
            placement: 'top-center',
          }),expression:"{\n            content: getLinkedinSharingDetails.url\n              ? null\n              : 'Caption generation button is disabled until a valid URL is put in the composer box',\n            placement: 'top-center',\n          }"}]},[_vm._v(" Generate Caption ")])]):_vm._e()]],2)],1),_c('options',{class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab' },attrs:{"type":"Linkedin"}}),(
      _vm.getPublishLoaders.fetchLinkedinShortLinks ||
      _vm.getPublishLoaders.fetchLinkedinSharingPreview
    )?_c('clip-loader',{staticClass:"link_loader",class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab' },attrs:{"color":"#4165ed","size":'16px'}}):[(_vm.getLinkedinSharingDetails.url)?_c('link-section',{class:{
        hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
      },attrs:{"type":"Linkedin","details":_vm.getLinkedinSharingDetails}}):(
        (_vm.getLinkedinSharingDetails.video &&
          _vm.getLinkedinSharingDetails.video.link) ||
        _vm.getPublishLoaders.upload_linkedin_sharing_video
      )?_c('video-section',{class:{
        hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
      },attrs:{"type":"Linkedin","details":_vm.getLinkedinSharingDetails}}):(
        _vm.getInstagramSharingLoaders.upload_sharing_image.status ||
        _vm.getLinkedinSharingDetails.image && _vm.getLinkedinSharingDetails.image.length > 0
      )?_c('image-section',{class:{
        hide: _vm.getSocialSharingSelection.activeTab !== 'linkedin_tab',
      },attrs:{"type":"Linkedin","details":_vm.getLinkedinSharingDetails}}):_vm._e()]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_social_tag lin_bg"},[_c('i',{staticClass:"cs-linkedin"})])
}]

export { render, staticRenderFns }