import { mapGetters } from 'vuex'
import moment from 'moment'
import vueCookie from 'vue-cookie'
import { cookieDomain } from '@src/config/api-utils'

export const identifyMixin = {
  computed: {
    ...mapGetters(['getPlan', 'getProfile']),
  },
  methods: {
    async identify(timeout = 0) {
      console.debug('Method::Identify')

      // if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      //   return;
      // }

      const self = this
      const profile = this.getProfile
      const plan = this.getPlan
      const workspace = this.getActiveWorkspace
      /* if (!window.location.href.includes('app.contentstudio.io')) {
        console.debug('url is not app.contentstudio.io, not firing an event.')
        return
      } */

      setTimeout(async function () {
        if (!profile._id) return
        const payload = {
          event: 'user',
          logged_in: true, // either true or false (boolean)
        }

        const state = profile.state ? profile.state : 'active'
        let loggedUser = {}

        if (workspace.members) {
          loggedUser = workspace.members.find((item) => {
            return item.user_id === profile._id
          })
          console.log('Firing event...', loggedUser)
        }

        payload.user = {
          id: profile._id,
          email: profile.email,
          first_name: profile.firstname,
          last_name: profile.lastname,
          created_at: profile.created_at,
          created_at_timestamp: moment
            .utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss')
            .unix(),
          state: state,
          role: 'super_admin',
          phone_no: profile.phone_no || null,
        }

        if (loggedUser && loggedUser.user_id) {
          payload.user = {
            id: loggedUser.user_id,
            email: profile.email,
            first_name: profile.firstname,
            last_name: profile.lastname,
            created_at: profile.created_at,
            created_at_timestamp: moment
              .utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss')
              .unix(),
            role: loggedUser.role,
            state: state,
            phone_no: profile.phone_no || null,
          }
        }
        // subscription instance is available.

        if (plan.subscription && plan.subscription.display_name) {
          payload.account = {
            plan_fs: plan.subscription.fastspring_name,
            plan_display: plan.subscription.display_name,
            count_social_accounts: plan.used_limits.profiles,
            count_blogs_accounts: plan.used_limits.blogs,
            count_team_members: plan.used_limits.members,
            count_workspaces: plan.used_limits.workspaces,
            count_custom_topics: plan.used_limits.topics,
            /* 'count_blog_posts': 0,
              'count_social_posts': 0, */
            // 'count_fav_folders': 0,
            // 'count_custom_sources': 0
          }
        }

        if (workspace) {
          payload.workspace = workspace
        }

        if (Object.hasOwn(profile, 'addons')) {
          payload.addons = profile.addons
        }

        console.log('Method::Identify payload', payload)
        if (!vueCookie.get('admin_logged_user', { domain: cookieDomain })) {
          console.log('Method::Identify triggered')
          self.userMavenIdentity(payload)

          self.goSquaredIdentify(payload)
          self.helpscoutIdentify(payload)
          self.customerIOIdentify(payload)
          self.hubspotIdentify(payload)
          self.profitWellScriptLoad(
            payload.workspace?.members
              ? payload.workspace.members.find(
                  (item) => item.role === 'super_admin'
                )?.user?.email
              : payload.user.email
          )
        }
        return true
      }, 0)
    },

    goSquaredIdentify(payload) {
      const gsPayload = {
        id: payload.user.id,
        name: payload.user.first_name + ' ' + payload.user.last_name,
        first_name: payload.user.first_name,
        last_name: payload.user.last_name,
        email: payload.user.email,
        // other special properties...
        custom: {
          team_role: payload.user.role,
        },
      }

      if (Object.hasOwn(payload, 'account') && payload.account.plan_fs) {
        gsPayload.custom.current_plan = payload.account.plan_fs
        gsPayload.custom.total_blogs = payload.account.count_blogs_accounts
        gsPayload.custom.total_social_accounts =
          payload.account.count_social_accounts
        gsPayload.custom.total_workspaces = payload.account.count_workspaces
        gsPayload.custom.total_team = payload.account.count_team_members
        gsPayload.custom.total_custom_topics =
          payload.account.count_custom_topics
      }

      try {
        // eslint-disable-next-line no-undef
        _gs('identify', gsPayload)
        console.log('goquared fired...', gsPayload)
      } catch (m) {
        console.log('goquared idenitfy: error ', m)
      }
    },

    helpscoutIdentify(payload) {
      let name = payload.user.first_name + ' ' + payload.user.last_name
      if (!name.trim()) {
        name = payload.user.email.substring(
          0,
          payload.user.email.lastIndexOf('@')
        )
      }
      const hsPayload = {
        id: payload.user.id,
        name: name,
        email: payload.user.email,
        'team-role': payload.user.role,
      }

      if (Object.hasOwn(payload, 'account') && payload.account.plan_display) {
        console.log('helpscout identify -> ', payload)
        if (payload.account.plan_fs.includes('trial')) {
          hsPayload['billing-type'] = 'Trial'
        } else if (payload.account.plan_fs.includes('lifetime')) {
          hsPayload['billing-type'] = 'Lifetime'
        }
        //  Checking in the plan_display if it monthly or annual for the billing type.
        else if (
          payload.account.plan_display.toLowerCase().includes('monthly')
        ) {
          hsPayload['billing-type'] = 'Monthly'
        } else if (
          payload.account.plan_display.toLowerCase().includes('annually')
        ) {
          hsPayload['billing-type'] = 'Annual'
        }
        if (payload?.workspace?.members) {
          hsPayload['super-admin-email'] =
            payload.workspace.members.find(
              (item) => item.role === 'super_admin'
            )?.user?.email || ''
        }

        hsPayload['internal-plan-name'] = payload.account.plan_fs
        hsPayload['current-plan'] = payload.account.plan_display
        hsPayload['total-workspaces'] = payload.account.count_workspaces
        hsPayload['total-social-accounts'] =
          payload.account.count_social_accounts
        hsPayload['total-blog-accounts'] = payload.account.count_blogs_accounts
        hsPayload['total-team-members'] = payload.account.count_team_members
        // hsPayload.CustomTopics = payload.account.count_custom_topics
      }
      try {
        // eslint-disable-next-line no-undef
        Beacon('identify', hsPayload)
        console.log(payload)
        console.log('event: helpscout fired')
      } catch (e) {}
    },

    customerIOIdentify(payload) {
      const cioPayload = {
        id: payload.user.id,
        name: payload.user.first_name + ' ' + payload.user.last_name,
        first_name: payload.user.first_name,
        last_name: payload.user.last_name,
        email: payload.user.email,
        created_at: payload.user.created_at_timestamp,
        state: payload.user.state,
        team_role: payload.user.role,
      }

      if (Object.hasOwn(payload, 'account') && payload.account.plan_fs) {
        cioPayload.current_plan = payload.account.plan_fs
        cioPayload.count_blogs = payload.account.count_blogs_accounts
        cioPayload.count_social_accounts = payload.account.count_social_accounts
        cioPayload.count_workspaces = payload.account.count_workspaces
        cioPayload.count_team = payload.account.count_team_members
        cioPayload.count_custom_topics = payload.account.count_custom_topics
      }
      try {
        // eslint-disable-next-line no-undef
        _cio.identify(cioPayload)
        console.log('event: customerio fired')
      } catch (e) {}
    },

    hubspotIdentify(payload) {
      try {
        var _hsq = (window._hsq = window._hsq || [])
        const leadProperties = {
          id: payload.user.id,
          email: payload.user.email,
          firstname: payload.user.first_name,
          lastname: payload.user.last_name,
          signed_up_at: payload.user.created_at_timestamp * 1000, // hubspot needs to be passed timestamp by multiplying with 1000
          team_role: payload.user.role,
          phone: payload.user.phone_no || null,
        }
        if (Object.hasOwn(payload, 'account') && payload.account.plan_fs) {
          leadProperties.subscription_plan = payload.account.plan_fs
          leadProperties.total_blogs = payload.account.count_blogs_accounts
          leadProperties.total_social_accounts =
            payload.account.count_social_accounts
          leadProperties.total_workspaces = payload.account.count_workspaces
          leadProperties.total_team_members = payload.account.count_team_members
          leadProperties.total_custom_topics =
            payload.account.count_custom_topics
        }

        _hsq.push(['identify', leadProperties])

        console.log('goquared fired...', leadProperties)
      } catch (e) {}
    },

    profitWellScriptLoad(email) {
      const profitwellScript = document.createElement('script')
      profitwellScript.id = 'profitwell-js'
      profitwellScript.setAttribute(
        'data-pw-auth',
        '0e427e1143d32f52ed1925243ac46217'
      )
      profitwellScript.innerHTML = ` (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
        a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
        s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
        profitwell('start', { 'user_email': '${email}' }); `
      document.head.appendChild(profitwellScript)
    },
  },
}
