<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'
import TwitterThreadedTweetsPreview from '../../../../../publish/components/posting/social/previews/TwitterThreadedTweetsPreview.vue'
export default {
  props: ['details'],
  components: {
    TwitterThreadedTweetsPreview
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getTwitterOptions'
    ]),
    getPreviewTitle () {
      return this.getCommonBoxOptions.status
        ? this.details.twitter_title
        : this.details.title
    },
    getPreviewDescription () {
      return this.getCommonBoxOptions.status
        ? this.details.twitter_description
        : this.details.description
    },
    getPreviewWebsite () {
      return this.getCommonBoxOptions.status
        ? this.details.twitter_website
        : this.details.website
    },
    getPreviewImage () {
      return this.getCommonBoxOptions.status
        ? this.details.image
        : this.details.twitter_image
    },
    showPreview () {
      return (
        this.details.url &&
        ((this.getPreviewTitle && this.getPreviewTitle.length) ||
          (this.getPreviewDescription && this.getPreviewDescription.length) ||
          (this.getPreviewImage && this.getPreviewImage.length > 0))
      )
    },
    getBoxType () {
      return this.getCommonBoxOptions.status ? 'Common' : 'Twitter'
    },
    getLocationName () {
      if (this.details.location) {
        return this.getBoxType === 'Common'
          ? this.details.location.twitter.name
          : this.details.location.name
      }
      return ''
    }
  },
  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    getValidThumbnail (selectedThumb) {
      // Getting list of valid thumnbnails for insta
      let thumbnailsList = [...this.details.video.thumbnails_suggestions]
      thumbnailsList = thumbnailsList.filter((thumbnail) => {
        if (!thumbnail.includes('contentstudio-media-library-nearline')) {
          return thumbnail
        }
      })

      if (thumbnailsList.length) {
        // check if is the thumbnail is custom
        if (!selectedThumb.includes('contentstudio-media-library-nearline')) {
          return selectedThumb
        } else {
          return thumbnailsList[0]
        }
      } else {
        return selectedThumb
      }
    }
  }
}
</script>
<template>
  <div id="twitter_tab">
    <div class="twitter_post_preview">
      <div class="twitter_post_preview__head">
        <div class="twitter_post_preview__head__profile_picture">
          <div
            class="twitter_post_preview__head__profile_picture__picture_block"
          >
            <img
              v-if="twitterImage(getPreviewAccount.twitter)"
              :src="twitterImage(getPreviewAccount.twitter)"
              alt=""
            />
            <img
              v-else
              src="../../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>

          <div class="twitter_post_preview__head__profile_picture__text_block">
            <p class="head_text"
              >{{ getPreviewAccount.twitter.name
              }}<span class="account_name">
                @{{ getPreviewAccount.twitter.screen_name }}</span
              ></p
            >
          </div>
        </div>

        <div class="twitter_post_preview__head__profile_picture__preview_desc">
          <p
            class="desc"
            v-html="processSharingPreviewDesciption(details.message, 'Twitter')"
          ></p>
        </div>
      </div>
      <div class="preview_link" v-if="showPreview">
        <clip-loader
          :color="'#ffffff'"
          :size="'16px'"
          v-if="
            (getCommonBoxOptions.status &&
              getPublishLoaders.fetchCommonSharingPreview) ||
            getPublishLoaders.fetchTwitterSharingPreview
          "
        ></clip-loader>

        <template v-else>
          <img
            draggable="false"
            class="img"
            v-if="getPreviewImage.length > 0"
            :src="getResizedImageURL(getPreviewImage[0], 500, 0)"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
          />
          <div class="link_detail">
            <h3>{{ getPreviewTitle }}</h3>
            <p class="desc">{{ getPreviewDescription }}</p>
            <p class="link">{{ getPreviewWebsite }}</p>
          </div>
        </template>
      </div>

      <template v-else-if="details.video && details.video.link">
        <div
          class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
        >
          <div
            class="img"
            :style="{
              'background-image':
                'url(' + getValidThumbnail(details.video.thumbnail) + ')',
            }"
          >
            <div class="twitter_vid_icon vid_icon">
              <span class="icon"></span>
            </div>
          </div>
        </div>
      </template>

      <div
        v-else-if="!details.url && details.image.length > 0"
        class="twitter_post_preview__preview_images"
      >
        <div v-if="details.image.length === 1" class="ratio ratio-16x9">
          <!--..........for single image...............-->
          <img
            class="single_image"
            :src="details.image[0]"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
          />
        </div>
        <!--..........for two images...............-->

        <div
          class="multiple_images two_images"
          v-else-if="details.image.length === 2"
        >
          <div
            class="img"
            :key="`twitter_preview_${index}`"
            v-for="(image, index) in details.image"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>

        <!--..........for three images...............-->

        <div
          class="multiple_images three_images"
          v-else-if="details.image.length === 3"
        >
          <div class="img_left">
            <div
              class="img"
              :style="{ 'background-image': 'url(' + details.image[0] + ')' }"
            ></div>
          </div>
          <div class="img_right">
            <template v-for="(image, index) in details.image" v-if="index > 0">
              <div
                class="img"
                :style="{ 'background-image': 'url(' + image + ')' }"
              ></div>
            </template>
          </div>
        </div>

        <!--..........for four images images...............-->

        <div class="multiple_images four_images" v-else>
          <div
            :key="`twitter_preview_${index}`"
            v-for="(image, index) in details.image"
            v-if="index < 4"
            class="img"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>
      </div>
      <!-- <div v-else-if="details.image.length==0" class="twitter_post_preview__preview_images ratio ratio-16x9" style="background: url('https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png');"></div> -->

      <div class="rection_icons facebook_r">
        <span><i class="fas fa-retweet"></i> </span>
        <span> <i class="far fa-heart"></i> </span>
        <span><i class="far fa-comment-alt"></i> </span>
      </div>

      <div class="location_preview" v-if="getLocationName">
        <h3>
          <img src="../../../../../../assets/img/locate.png" />
          {{ getLocationName }}
        </h3>
      </div>
    </div>
    <TwitterThreadedTweetsPreview
      v-show="getTwitterOptions.has_threaded_tweets"
    />
  </div>
</template>
