import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import {
  blogPosting,
  commonTypes,
  instagramTypes,
  linkedinSharingTypes,
  publish,
} from '@src/modules/publish/store/states/mutation-types'
import {EventBus} from "@common/lib/event-bus";

const he = require('he')

export const csvAutomationMixin = {
  computed: {
    ...mapGetters([
      'getCsvAutomationSelection',
      'getCsvListing',
      'getWorkspaces',
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_CSV_FILE',
      'SET_CSV_FILE_NAME',
      'SET_CSV_FIRST_TAB_STATUS',
      'SET_CSV_SECOND_TAB_STATUS',
      'SET_CSV_THIRD_TAB_STATUS',
      'SET_CSV_Fourth_TAB_STATUS',
      'SET_CSV_PAGE',
      'SET_CSV_CHECK_ALL',
      'SET_CSV_SELECTED_IDS',
    ]),

    ...mapActions([
      'fetchCSVPosts',
      'setPublicationStatus',
      'setPublishLabels',
      'setPublishMembers',
      'setPublishPlanExecutionTime',
      'setPublishTimeSelection',
      'setCommonSharingDetails',
      'setFacebookSharingDetails',
      'setTwitterSharingDetails',
      'setPinterestSharingDetails',
      'setTumblrSharingDetails',
      'setAccountSelection',
      'setEditQueueStatus',
      'setCSVPlanId',
      'setSidebarStatus',
    ]),

    uploadCsvFile(event) {
      event.preventDefault()
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        this.SET_CSV_FILE(null)
        this.SET_CSV_FILE_NAME(null)
        files = files[0]
        if (files.size > 15728640) {
          this.alertMessage('File size too big.', 'error')
        } else {
          this.SET_CSV_FILE(files)
          this.SET_CSV_FILE_NAME(files.name)
        }
      } else {
        this.alertMessage('No file selected', 'error')
      }
    },

    processCsvAutomationValidation(tab) {
      const automation = this.getCsvAutomationSelection
      if (tab < 4 && this.getCsvAutomationTabStatus.fourth === true)
        return false
      if (tab > 0 || tab === 'All') {
        if (automation.file === null) {
          this.alertMessage('Upload CSV file first', 'error')
          return false
        }

        this.changeActiveCSVAutomationTab(2)
      }
      if (tab > 1 || tab === 'All') {
        if (!this.socialAccountSelectionValidation('CsvAutomation')) {
          return false
        }

        this.changeActiveCSVAutomationTab(3)
      }

      return true
    },

    changeActiveCSVAutomationTab(tab) {
      if (tab < 4 && this.getCsvAutomationTabStatus.fourth === true)
       return false
      if (tab === 1) {
        this.SET_CSV_FIRST_TAB_STATUS(true)
        this.SET_CSV_SECOND_TAB_STATUS(false)
        this.SET_CSV_THIRD_TAB_STATUS(false)
        this.SET_CSV_Fourth_TAB_STATUS(false)
      } else if (tab === 2) {
        this.SET_CSV_FIRST_TAB_STATUS(false)
        this.SET_CSV_SECOND_TAB_STATUS(true)
        this.SET_CSV_THIRD_TAB_STATUS(false)
        this.SET_CSV_Fourth_TAB_STATUS(false)
      } else if (tab === 3) {
        this.SET_CSV_FIRST_TAB_STATUS(false)
        this.SET_CSV_SECOND_TAB_STATUS(false)
        this.SET_CSV_THIRD_TAB_STATUS(true)
        this.SET_CSV_Fourth_TAB_STATUS(false)
      } else if (tab === 4) {
        this.SET_CSV_FIRST_TAB_STATUS(false)
        this.SET_CSV_SECOND_TAB_STATUS(false)
        this.SET_CSV_THIRD_TAB_STATUS(false)
        this.SET_CSV_Fourth_TAB_STATUS(true)
      }
    },

    onInfiniteCSVList($state) {
      if (this.getCsvListing.fetchCSVPaginationScroll) {
        console.log('onInfiniteCSVList')
        this.SET_CSV_PAGE(this.getCsvListing.page + 1)
        this.fetchCSVPosts($state)
      }
    },

    checkValidForApprove(item) {
      if (
        item.error === 'error_date' ||
        item.error === 'error_message' ||
        item.error === 'error_date_empty' ||
        item.error === 'twitter_length_error' ||
        (item.execution_time &&
          this.timePassingCheckCSV(item.execution_time.date) &&
          !(
            item.content_category_id &&
            item.dateSettingType &&
            item.dateSettingType === 'content_category'
          ))
      ) {
        return false
      }
      return true
    },

    changeSelectedCSV(type) {
      const selfObject = this
      if (type === 'ALL') {
        const selectedCSVIds = []
        if (this.getCsvListing.checkAll) {
          $(this.getCsvListing.items).each(function (index, el) {
            if (
              !(
                el.error === 'error_date' ||
                el.error === 'error_message' ||
                el.error === 'error_date_empty' ||
                el.error === 'twitter_length_error' ||
                (el.execution_time &&
                  selfObject.timePassingCheckCSV(el.execution_time.date))
              )
            )
              selectedCSVIds.push(el._id)
          })
        }
        this.SET_CSV_SELECTED_IDS(selectedCSVIds)
      } else {
        if (
          this.getCsvListing.selectedItems.length ===
          this.getCsvListing.items.length
        ) {
          this.SET_CSV_CHECK_ALL(true)
        } else {
          this.SET_CSV_CHECK_ALL(false)
        }
      }
    },

    editCSVPost(plan, index) {
      console.debug('Method:editCSVPost')
      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')
      const self = this
      setTimeout(function () {
        EventBus.$emit('publication-composer-post', {
          mode: 'edit-csv-post',
          plan
        })
      }, 500)


      return
      // old code to edit CSV Post using old composer TODO: Need to remove after some time.
      {
        this.$bvModal.show('social_share_modal')
        this.setSidebarStatus('preview')

        console.debug(plan)
        const stateObject = this
        this.resetBlogSocialDetail()
        this.setPublicationStatus(plan.status)

        this.setCSVPlanId(plan._id)

        this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, '')
        this.setPublishLabels(plan.labels)
        this.setPublishMembers(plan.members)

        const defaultTimeSelection = {
          time_type: 'schedule',
          post_date: '',
          repeat: '',
          repeat_type: 'Hour',
          repeat_times: 1,
          repeat_gap: 1,
          evergreen: '',
          plan_status: 'Approved',
        }
        if (
            plan.execution_time &&
            !this.timePassingCheckCSV(plan.execution_time.date)
        ) {
          this.setPublishPlanExecutionTime(plan.execution_time)
          defaultTimeSelection.post_date = moment
              .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
              .tz(this.getWorkspaces.activeWorkspace.timezone)
              .format('MMMM DD, YYYY HH:mm')
        } else {
          // defaultTimeSelection['time_type'] = 'queued'
          const currentDate = moment()
              .tz(this.getWorkspaces.activeWorkspace.timezone)
              .add(10, 'm')
          defaultTimeSelection.post_date = currentDate.format(
              'MMMM DD, YYYY HH:mm'
          )
        }
        if (plan.status === 'review') {
          defaultTimeSelection.plan_status = 'Under Review'
        }

        if (
            plan.content_category_id &&
            plan.dateSettingType &&
            plan.dateSettingType === 'content_category'
        )
          defaultTimeSelection.time_type = 'content_category'
        if (plan.content_category_id)
          stateObject.$store.commit(
              publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE,
              plan.content_category_id
          )

        this.setPublishTimeSelection(defaultTimeSelection)
        this.$store.commit(
            commonTypes.SET_COMMON_BOX_STATUS,
            plan.common_box_status
        )

        setTimeout(function () {
          plan.common_sharing_details.message = he.decode(
              plan.common_sharing_details.message
          )
          plan.facebook_sharing_details.message = he.decode(
              plan.facebook_sharing_details.message
          )
          plan.twitter_sharing_details.message = he.decode(
              plan.twitter_sharing_details.message
          )
          plan.linkedin_sharing_details.message = he.decode(
              plan.linkedin_sharing_details.message
          )
          plan.pinterest_sharing_details.message = he.decode(
              plan.pinterest_sharing_details.message
          )
          plan.tumblr_sharing_details.message = he.decode(
              plan.tumblr_sharing_details.message
          )

          stateObject.setCommonSharingDetails(plan.common_sharing_details)
          stateObject.setFacebookSharingDetails(plan.facebook_sharing_details)
          stateObject.setTwitterSharingDetails(plan.twitter_sharing_details)
          stateObject.$store.commit(
              linkedinSharingTypes.SET_SHARING_DETAILS,
              plan.linkedin_sharing_details
          )
          stateObject.setPinterestSharingDetails(plan.pinterest_sharing_details)
          stateObject.setTumblrSharingDetails(plan.tumblr_sharing_details)

          // setting Instagram box details in the states
          if (plan.instagram_sharing_details) {
            plan.instagram_sharing_details.message = he.decode(
                plan.instagram_sharing_details.message
            )
            stateObject.$store.commit(
                instagramTypes.SET_SHARING_DETAILS,
                plan.instagram_sharing_details
            )
          } else {
            stateObject.$store.commit(instagramTypes.SET_SHARING_DETAILS, null)
          }
        }, 1000)

        this.setAccountSelection(plan.account_selection)
        this.setEditQueueStatus(plan.queued)

        setTimeout(function () {
          stateObject.setSharingMessage(
              'Common',
              plan.common_sharing_details.message
          )
          stateObject.setSharingMessage(
              'Facebook',
              plan.facebook_sharing_details.message
          )
          stateObject.setSharingMessage(
              'Twitter',
              plan.twitter_sharing_details.message
          )
          stateObject.setSharingMessage(
              'Linkedin',
              plan.linkedin_sharing_details.message
          )
          stateObject.setSharingMessage(
              'Pinterest',
              plan.pinterest_sharing_details.message
          )
          stateObject.setSharingMessage(
              'Tumblr',
              plan.tumblr_sharing_details.message
          )

          if (
              plan.instagram_sharing_details &&
              plan.instagram_sharing_details.message
          ) {
            stateObject.setSharingMessage(
                'Instagram',
                plan.instagram_sharing_details.message
            )
          } else {
            stateObject.setSharingMessage('Instagram', '')
          }
          stateObject.allTextareaFocus()
        }, 1000)
      }
    },

    refetchCSVPlans() {
      this.SET_CSV_PAGE(1)
      this.fetchCSVPosts()
    },
  },
}
