var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"twitter_tab"}},[_c('div',{staticClass:"twitter_post_preview"},[_c('div',{staticClass:"twitter_post_preview__head"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture"},[_c('div',{staticClass:"twitter_post_preview__head__profile_picture__picture_block"},[(_vm.twitterImage(_vm.getPreviewAccount.twitter))?_c('img',{attrs:{"src":_vm.twitterImage(_vm.getPreviewAccount.twitter),"alt":""}}):_c('img',{attrs:{"src":require("../../../../../../assets/img/profile_default.svg"),"alt":""}})]),_c('div',{staticClass:"twitter_post_preview__head__profile_picture__text_block"},[_c('p',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.getPreviewAccount.twitter.name)),_c('span',{staticClass:"account_name"},[_vm._v(" @"+_vm._s(_vm.getPreviewAccount.twitter.screen_name))])])])]),_c('div',{staticClass:"twitter_post_preview__head__profile_picture__preview_desc"},[_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.processSharingPreviewDesciption(_vm.details.message, 'Twitter'))}})])]),(_vm.showPreview)?_c('div',{staticClass:"preview_link"},[(
          (_vm.getCommonBoxOptions.status &&
            _vm.getPublishLoaders.fetchCommonSharingPreview) ||
          _vm.getPublishLoaders.fetchTwitterSharingPreview
        )?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):[(_vm.getPreviewImage.length > 0)?_c('img',{staticClass:"img",attrs:{"draggable":"false","src":_vm.getResizedImageURL(_vm.getPreviewImage[0], 500, 0),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}}):_vm._e(),_c('div',{staticClass:"link_detail"},[_c('h3',[_vm._v(_vm._s(_vm.getPreviewTitle))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.getPreviewDescription))]),_c('p',{staticClass:"link"},[_vm._v(_vm._s(_vm.getPreviewWebsite))])])]],2):(_vm.details.video && _vm.details.video.link)?[_c('div',{staticClass:"social_preview_block__social_preview_inner__social_post_preview__preview_video"},[_c('div',{staticClass:"img",style:({
            'background-image':
              'url(' + _vm.getValidThumbnail(_vm.details.video.thumbnail) + ')',
          })},[_vm._m(0)])])]:(!_vm.details.url && _vm.details.image.length > 0)?_c('div',{staticClass:"twitter_post_preview__preview_images"},[(_vm.details.image.length === 1)?_c('div',{staticClass:"ratio ratio-16x9"},[_c('img',{staticClass:"single_image",attrs:{"src":_vm.details.image[0],"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}})]):(_vm.details.image.length === 2)?_c('div',{staticClass:"multiple_images two_images"},_vm._l((_vm.details.image),function(image,index){return _c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})}),0):(_vm.details.image.length === 3)?_c('div',{staticClass:"multiple_images three_images"},[_c('div',{staticClass:"img_left"},[_c('div',{staticClass:"img",style:({ 'background-image': 'url(' + _vm.details.image[0] + ')' })})]),_c('div',{staticClass:"img_right"},[_vm._l((_vm.details.image),function(image,index){return (index > 0)?[_c('div',{staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})]:_vm._e()})],2)]):_c('div',{staticClass:"multiple_images four_images"},_vm._l((_vm.details.image),function(image,index){return (index < 4)?_c('div',{key:`twitter_preview_${index}`,staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })}):_vm._e()}),0)]):_vm._e(),_vm._m(1),(_vm.getLocationName)?_c('div',{staticClass:"location_preview"},[_c('h3',[_c('img',{attrs:{"src":require("../../../../../../assets/img/locate.png")}}),_vm._v(" "+_vm._s(_vm.getLocationName)+" ")])]):_vm._e()],2),_c('TwitterThreadedTweetsPreview',{directives:[{name:"show",rawName:"v-show",value:(_vm.getTwitterOptions.has_threaded_tweets),expression:"getTwitterOptions.has_threaded_tweets"}]})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"twitter_vid_icon vid_icon"},[_c('span',{staticClass:"icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rection_icons facebook_r"},[_c('span',[_c('i',{staticClass:"fas fa-retweet"})]),_c('span',[_c('i',{staticClass:"far fa-heart"})]),_c('span',[_c('i',{staticClass:"far fa-comment-alt"})])])
}]

export { render, staticRenderFns }