<template>
  <div>
    <!--Page Impressions by Day chart-->

    <chart-render
      :id="'page_impressions_highlight'"
      :options="pageImpressions()"
      :ref_value="'page_impressions_chart'"
    ></chart-render>
    <!--Likes Breakdown by Day table-->
  </div>
</template>
<script>
import moment from 'moment'
import ChartRender from '../../common/ChartRender'
import {
  lineChart,
  lineChartTooltip,
  lineLegend,
  linePlotOptions,
  splineCredits,
  splineXAxis,
  splineYAxis
} from '../../common/helper'
import { INSTAGRAM_ANALYTICS_IMPRESSIONS } from '@common/constants/messages'

export default {
  components: {
    ChartRender
  },

  props: {
    impressions: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_IMPRESSIONS
    }
  },
  computed: {},
  methods: {
    pageImpressions () {
      const lineChartTooltipModified = lineChartTooltip()
      // lineChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.y:f}</span></p>'
      const impressionsList = []
      let impressionCount
      this.impressions.buckets.forEach((item) => {
        if (item.doc_count > 0) {
          impressionsList.push([
            Date.parse(item.key_as_string.split(' ')[0]),
            item.impressions.value
          ])
          impressionCount = item.impressions.value
        } else {
          if (impressionCount) {
            impressionsList.push([
              Date.parse(item.key_as_string.split(' ')[0]),
              impressionCount
            ])
          }
        }
      })
      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: lineChartTooltipModified,
        chart: lineChart('#page_impressions_highlight'),
        legend: lineLegend(),
        title: {
          text: ''
        },

        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        // plotOptions: linePlotOptions(),
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            lineColor: '#2fe095',
            marker: {
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: '#2fe095'
            },
            connectNulls: true
          }
        },
        series: [
          {
            name: 'Impressions',
            data: impressionsList,
            color: '#2fe095',
            lineColor: '#2fe095'
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
