<template>
  <!--Active Users by Hours table-->

  <div class="active_users_container">
    <chart-render
      :id="'active_users_by_hours_highlight'"
      :options="activeUsersByHours()"
      :ref_value="'active_users_by_hours'"
      :is_content_available="!isActiveUsersAvailable"
      :is_active_hour="true"
      :active_hour="active_hour"
      :container_class="''"
    ></chart-render>
  </div>
</template>

<script>
import ChartRender from '../../common/ChartRender'

export default {
  components: { ChartRender },
  props: {
    active_users: {
      default: []
    }
  },
  data () {
    return {
      active_hour: '00:00'
    }
  },
  computed: {
    isActiveUsersAvailable () {
      return Object.entries(this.active_users).length > 0
    }
  },
  methods: {
    activeUsersByHours () {
      this.active_hour = this.active_users.highest_hour
      let fans = []
      const hours = []
      Object.entries(this.active_users).forEach((item, index) => {
        if (index <= 23) {
          hours.push([item[0]])
          fans.push([item[0], item[1]])
        }
      })

      if (!this.isActiveUsersAvailable) {
        fans = [
          [0, 255],
          [1, 355],
          [2, 600],
          [3, 455],
          [4, 700],
          [5, 880],
          [6, 1000],
          [7, 955],
          [8, 455],
          [9, 1200],
          [10, 1800],
          [11, 1600],
          [12, 1500],
          [13, 1225],
          [14, 1455],
          [15, 1785],
          [16, 1985],
          [17, 2550],
          [18, 2220],
          [19, 2152],
          [20, 2285],
          [21, 3555],
          [22, 3200],
          [23, 3100]
        ]
      }
      console.log(fans)
      return {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat: '<div class="chart_tooltip engagement_tooltip">',
          pointFormat:
            '<div class="engagement_point"><h3 class="date">{series.name}</h3><span>Users: {point.y:f}</span></div>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 20,
            color: 'rgba(58, 69, 87, 0.15)'
          }
        },
        chart: {
          type: 'spline',
          height: 300,
          // type: 'area',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          }
        },
        legend: {
          enabled: false
        },

        colors: ['red'],
        title: false,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          categories: hours
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        plotOptions: {
          series: {
            lineColor: '#2fe095',
            marker: {
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: '#2fe095'
            }
          }
        },
        series: [
          {
            name: 'Active Users',
            data: fans
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
