var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full cst-drop-shadow bg-white rounded-xl px-2"},[_c('div',{staticClass:"flex items-center py-2"},[_c('img',{staticClass:"rounded-full object-cover border border-solid border-black",attrs:{"width":"40","height":"40","src":_vm.account.account_image,"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}),_c('div',{staticClass:"px-2 font-bold"},[_c('span',{staticClass:"leading-4"},[_vm._v(_vm._s(_vm.account.platform_name))]),_c('span',{staticClass:"block font-normal text-sm text-gray-800"},[(_vm.executionTime.date)?_c('span',[_vm._v(_vm._s(_vm._f("publishedOnlyDateWithWeekdayAndMonth")(_vm.getWorkspaceTimeZoneTime(_vm.executionTime.date, 'YYYY-MM-DD HH:mm:ss'))))]):_c('span',[_vm._v(_vm._s(_vm.getWorkspaceTimezonCurrentTime('D MMM YYYY')))])])]),_vm._m(0)]),_c('div',{staticClass:"w-100 h-100"},[(
        (Array.isArray(_vm.detail.image) && _vm.detail.image.length > 0) ||
        (typeof _vm.detail.image === 'string' && _vm.detail.image !== '')
      )?_c('div',{staticClass:"flex flex-wrap relative rounded-lg overflow-hidden",staticStyle:{"max-height":"500px","min-height":"150px"}},[_c('div',{staticClass:"flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]",style:({ 'background-image': 'url(' + _vm.getImage + ')' }),on:{"click":_vm.handleImagePreview}},[_c('img',{staticClass:"max-w-full max-h-full w-full opacity-0",attrs:{"src":_vm.getImage,"alt":""},on:{"error":(event) => {
              event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
              event.target.classList.remove('opacity-0')
            }}})])]):(_vm.detail.video && _vm.detail.video.thumbnail)?_c('div',{staticClass:"relative h-100 text-white"},[_c('img',{staticClass:"w-full min-h-48",staticStyle:{"max-height":"500px"},attrs:{"src":_vm.detail.video.thumbnail,"alt":""}}),_c('i',{staticClass:"fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid",staticStyle:{"transform":"translate(-50%, -50%)"},on:{"click":function($event){$event.preventDefault();return _vm.displayFile('video', _vm.detail.video.link, 0)}}})]):_vm._e()]),(_vm.eventTitle)?_c('div',{staticClass:"items-center p-2"},[_c('span',{staticClass:"text-xl font-bold pointer-events-none"},[_vm._v(_vm._s(_vm.eventTitle))])]):_vm._e(),(_vm.eventDate && _vm.eventTitle)?_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.eventDate)+" ")]):_vm._e(),(_vm.detail.message)?_c('div',{staticClass:"items-center p-2"},[_c('span',{staticClass:"whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.getDescription)}})]):_vm._e(),(_vm.selectedActionType)?_c('div',{staticClass:"flex items-center p-2 mb-1"},[_c('span',{staticClass:"text-blue-300 font-semibold pointer-events-none"},[_vm._v(_vm._s(_vm.selectedActionType))]),_vm._m(1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-blue"},[_c('img',{staticClass:"w-5",attrs:{"src":require("@assets/img/integration/gmb-icon.svg"),"alt":" "}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ml-auto"},[_c('i',{staticClass:"fas fa-share-alt text-gray-700"})])
}]

export { render, staticRenderFns }