var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps_box",attrs:{"id":_vm.type + '-section'}},[_c('div',{staticClass:"box_heading"},[_c('h2',[_vm._v(_vm._s(_vm.getServiceName))])]),_c('div',{staticClass:"box_content"},[_c('div',{staticClass:"integration_box_list"},[(_vm.showConnectionBox)?_c('div',{staticClass:"integration_box connect_integration_box"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"int_img",style:([_vm.isReplug ? { width: '80px', height: '80px' } : {}]),attrs:{"src":_vm.getServiceImage,"alt":""}}),_c('div',{staticClass:"text d-flex justify-content-center align-items-center"},[_c('p',[_vm._v(_vm._s(_vm.getServiceConnectText))])]),(_vm.linkVisibilityStatus)?_c('a',{staticClass:"btn btn-studio-theme-transparent",on:{"click":function($event){$event.preventDefault();return _vm.connectAccount(_vm.type, 'connect')}}},[_c('span',[_vm._v("Connect")])]):_vm._e()])])]):_vm._e(),(_vm.hasConnectedAccounts)?_vm._l((_vm.getConnectedAccounts),function(account,index){return _c('div',{key:index,staticClass:"integration_box"},[_c('div',{staticClass:"box_inner"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"int_img",style:([_vm.isReplug ? { background: '#3988fc14' } : {}]),attrs:{"src":_vm.getServiceImage,"alt":""}}),_c('div',{staticClass:"connected"},[_vm._v(" Connected ")]),_c('div',{staticClass:"text d-flex justify-content-center align-items-center"},[_c('p',[_vm._v(_vm._s(_vm.getAccountDisplayText(account)))])]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Reconnect'),expression:"'Reconnect'",modifiers:{"top-center":true}}],staticClass:"action_icon cs-refresh",on:{"click":function($event){$event.preventDefault();_vm.connectAccount(
                    _vm.type,
                    'reconnect',
                    _vm.isReplug
                      ? account.replug_id
                      : _vm.getAccountDisplayText(account)
                  )}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"action_icon icon-delete-cs",on:{"click":function($event){$event.preventDefault();return _vm.removeAccount(_vm.type, account._id)}}})])])])}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }