<template>
  <b-modal
    id="bulk-edit-plans"
    body-class="p-0"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="lg"
    @shown="onModalShow"
    @hidden="onModalHide"
  >
    <div class="flex flex-col">
      <!--Header::start-->
      <div class="flex flex-row items-center justify-between px-6 py-5">
        <div class="flex flex-col justify-start">
          <span class="font-medium text-base">Bulk Edit Posts</span>
        </div>
        <!--ButtonClose::start-->
        <div>
          <span
            class="h-8 w-8 cursor-pointer flex justify-center items-center bg-gray-300 hover:bg-gray-500 text-black-400 rounded-lg p-3.5 text-lg border-0"
            @click="closeBulkEdit"
          >
            <i class="fal fa-times"></i>
          </span>
        </div>
        <!--ButtonClose::end-->
      </div>
      <!--Header::end-->

      <hr class="m-0 hr-color" />

      <!--Body::start-->
      <div
        class="px-6 py-4 flex flex-col space-y-3 max-h-[40rem]"
        :class="{ 'overflow-y-auto': bulkEditResponse }"
      >
        <!--EditInputs::start-->
        <template v-if="!bulkEditResponse">
          <!--Info::start-->
          <div
            class="flex flex-row space-x-2 bg-[#0068e51a] opacity-1 p-3 rounded-sm text-cs-primary"
          >
            <img
              class="w-5 h-5"
              alt="info_icon"
              :src="require('@assets/img/icons/info_icon.svg')"
            />
            <span
              >Changes will be applied to selected {{ plansCount }} posts</span
            >
          </div>
          <!--Info::end-->

          <!--Social::start-->
          <div class="flex flex-col space-y-2">
            <span class="w-25 font-medium text-base">Social Accounts</span>
            <CstDropdown class="w-full">
              <template v-slot:selected>
                <div
                  v-if="bulkSocialAccounts.length > 0"
                  class="flex flex-row space-x-1"
                >
                  <template v-for="(account, index) in bulkSocialAccounts">
                    <div
                      v-if="index < 4"
                      :key="index"
                      class="bg-white border flex items-center p-1 rounded-full space-x-1"
                    >
                      <span class="relative">
                        <img
                          class="w-6 h-6 rounded-full"
                          :src="channelImage(account, account.channel_type)"
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                        <img
                          :src="
                            require(`@assets/img/integration/${account.channel_type}-rounded.svg`)
                          "
                          alt=""
                          class="absolute -right-0.5 -bottom-0.5 w-3.5 h-3.5"
                        />
                      </span>
                      <span class="text-black-400 pr-2">{{
                        truncateString(
                          'platform_name' in account
                            ? account.platform_name
                            : account.name,
                          17
                        )
                      }}</span>
                    </div>
                  </template>
                  <template v-if="bulkSocialAccounts.length > 4">
                    <div
                      class="border flex flex-row items-center p-1 rounded-full bg-gray-300"
                    >
                      <span class="relative">
                        <img
                          class="w-6 h-6 border rounded-full"
                          :src="'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                        <img
                          class="absolute border h-6 left-4 rounded-full w-6"
                          :src="'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                      </span>
                      <span>
                        <span class="ml-6 mr-1 text-black-50"
                          >+{{ bulkSocialAccounts.length - 4 }} more</span
                        >
                      </span>
                    </div>
                  </template>
                </div>
              </template>
              <CstDropdownItem :close-on-click="false">
                <div
                  class="flex flex-row space-x-2 items-center justify-between"
                >
                  <CstInputFields
                    v-model="searchQuery"
                    class="w-full"
                    placeholder="Search Social Account"
                  ></CstInputFields>
                  <CstSimpleCheckbox
                    v-model="selectAllSocialAccounts"
                    class="border-0"
                  ></CstSimpleCheckbox>
                </div>
              </CstDropdownItem>
              <CstDropdownItem
                v-for="(socialAccount, index) in socialAccountsList"
                :key="`bulk_edit_social_accounts_${socialAccount._id}_${index}`"
                :close-on-click="false"
              >
                <CstSimpleCheckbox
                  v-model="selectedSocialAccounts"
                  class="w-full"
                  :name="socialAccount"
                  :disabled="
                    socialAccount.validity &&
                    socialAccount.validity.toLowerCase() !== 'valid' &&
                    socialAccount.validity.toLowerCase() !== 'expiring_soon'
                  "
                  :indeterminate="socialAccount.indeterminate"
                  @input="onClickSocialAccount(socialAccount)"
                >
                  <template slot="label">
                    <SocialAccount :account="socialAccount"></SocialAccount>
                  </template>
                  <template
                    v-if="
                      socialAccount.validity &&
                      socialAccount.validity.toLowerCase() !== 'valid' &&
                      socialAccount.validity.toLowerCase() !== 'expiring_soon'
                    "
                    v-slot:input
                  >
                    <i
                      v-b-tooltip
                      title="The access token for this social account has been invalidated, please reconnect to continue posting."
                      class="fas fa-exclamation-square text-red-500 text-xl"
                    ></i>
                  </template>
                </CstSimpleCheckbox>
              </CstDropdownItem>
            </CstDropdown>
            <span class="text-sm"
              >The addition of social accounts will only be performed on the
              post that full-fill the API requirements for social
              platforms</span
            >
          </div>
          <!--Social::end-->

          <!--Label::start-->
          <div class="flex flex-col space-y-2">
            <span class="w-25 font-medium text-base">Label</span>
            <CstDropdown class="w-full">
              <template v-slot:selected>
                <div
                  v-if="bulkLabels.length > 0"
                  class="flex flex-row space-x-1"
                >
                  <template v-for="(label, index) in bulkLabels">
                    <div
                      v-if="index < 4"
                      :key="index"
                      class="bg-white border flex items-center p-1 rounded-full space-x-1"
                    >
                      <span
                        class="w-6 h-6 rounded-full"
                        :class="label.color"
                      ></span>
                      <span class="text-black-400 pr-2">{{ label.name }}</span>
                    </div>
                  </template>
                  <template v-if="bulkLabels.length > 4">
                    <div
                      class="border flex flex-row items-center p-1 rounded-full bg-gray-300"
                    >
                      <span class="mx-2 text-black-50"
                        >+{{ bulkLabels.length - 4 }} more</span
                      >
                    </div>
                  </template>
                </div>
              </template>
              <CstDropdownItem :close-on-click="false">
                <div
                  class="flex flex-row space-x-2 items-center justify-between"
                >
                  <CstSimpleCheckbox
                    :value="labels.length === selectedLabels.length"
                    class="border-0 w-full"
                    @input="selectAllLabels"
                  >
                    <template v-slot:label> Select All </template>
                  </CstSimpleCheckbox>
                </div>
              </CstDropdownItem>
              <CstDropdownItem
                v-for="(label, index) in labels"
                :key="`labels_list_${index}`"
                :close-on-click="false"
              >
                <CstSimpleCheckbox
                  v-model="selectedLabels"
                  :name="label"
                  class="w-full"
                  :indeterminate="label.indeterminate"
                  @input="onClickLabel(label)"
                >
                  <template slot="label">
                    <div
                      class="flex flex-row items-center justify-center space-x-2"
                    >
                      <div
                        class="w-6 h-6 rounded-lg"
                        :class="label.color"
                      ></div>
                      <div>{{ label.name }}</div>
                    </div>
                  </template>
                </CstSimpleCheckbox>
              </CstDropdownItem>
            </CstDropdown>
          </div>
          <!--Label::end-->

          <!--Campaigns::start-->
          <div class="flex flex-col space-y-2">
            <span class="w-25 font-medium text-base">Campaigns</span>
            <CstDropdown class="w-full">
              <template v-slot:selected>
                <div
                  v-if="selectedFolderObj"
                  class="bg-white border flex items-center p-2 rounded-full space-x-1 text-black-400"
                >
                  {{ selectedFolderObj.name }}
                </div>
              </template>
              <CstDropdownItem
                v-for="(folder, index) in publicationFolders"
                :key="`publication_folders_key_${index}`"
                :close-on-click="false"
              >
                <CstRadio
                  :id="`publication_folders_${index}`"
                  v-model="selectedFolder.folderId"
                  name="publication_folders"
                  :value="folder._id"
                  reverse
                  @change="onClickFolder(folder)"
                >
                  {{ folder.name }}
                </CstRadio>
              </CstDropdownItem>
            </CstDropdown>
          </div>
          <!--Campaigns::end-->

          <!--Members::start-->
          <div class="flex flex-col space-y-2">
            <span class="w-25 font-medium text-base">Members</span>
            <CstDropdown class="w-full">
              <template v-slot:selected>
                <div
                  v-if="bulkMembers.length > 0"
                  class="flex flex-row space-x-1"
                >
                  <template v-for="(member, index) in bulkMembers">
                    <div
                      v-if="index < 4"
                      :key="index"
                      class="bg-white border flex items-center p-1 rounded-full space-x-1"
                    >
                      <span class="relative">
                        <img
                          class="w-6 h-6 rounded-full"
                          :src="
                            member.user && member.user.image
                              ? member.user.image
                              : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                          "
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                      </span>
                      <span class="text-black-400 pr-2">{{
                        member.user.full_name
                      }}</span>
                    </div>
                  </template>
                  <template v-if="bulkMembers.length > 4">
                    <div
                      class="border flex flex-row items-center p-1 rounded-full bg-gray-300"
                    >
                      <span class="relative">
                        <img
                          class="w-6 h-6 border rounded-full"
                          :src="'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                        <img
                          class="absolute border h-6 left-4 rounded-full w-6"
                          :src="'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                          alt="profile_pic"
                          @error="socialAccountImgFallback"
                        />
                      </span>
                      <span>
                        <span class="ml-6 mr-1 text-black-50"
                          >+{{ bulkMembers.length - 4 }} more</span
                        >
                      </span>
                    </div>
                  </template>
                </div>
              </template>
              <CstDropdownItem :close-on-click="false">
                <div
                  class="flex flex-row space-x-2 items-center justify-between"
                >
                  <CstSimpleCheckbox
                    :value="members.length === selectedMembers.length"
                    class="border-0 w-full"
                    @input="selectAllMembers"
                  >
                    <template v-slot:label>Select All</template>
                  </CstSimpleCheckbox>
                </div>
              </CstDropdownItem>
              <CstDropdownItem
                v-for="(member, index) in members"
                :key="`members_list_${index}`"
                :close-on-click="false"
              >
                <CstSimpleCheckbox
                  v-model="selectedMembers"
                  :name="member"
                  class="w-full"
                  :indeterminate="member.indeterminate"
                  @input="onClickMember(member)"
                >
                  <template slot="label">
                    <div
                      class="flex flex-row items-center justify-center space-x-2"
                    >
                      <img
                        :src="member.user.image || ''"
                        class="w-10 h-10 rounded-full object-cover border"
                        alt="profile pic"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                        "
                      />
                      <div class="flex flex-col items-start justify-start">
                        <div>{{ member.user.full_name }}</div>
                        <div class="text-xs text-blue-300 capitalize">{{
                          member.role.replace('_', ' ')
                        }}</div>
                      </div>
                    </div>
                  </template>
                </CstSimpleCheckbox>
              </CstDropdownItem>
            </CstDropdown>
          </div>
          <!--Members::end-->

          <!--Posting Schedule::start-->
          <div class="flex flex-col space-y-3">
            <span class="w-25 font-medium text-base">Posting Schedule</span>
            <!--OverrideCheckbox::start-->
            <CstSimpleCheckbox
              v-model="overrideExecutionTime"
              reverse
              class="flex justify-start"
            >
              <template slot="label"
                >Override the selected posts execution time.
              </template>
            </CstSimpleCheckbox>
            <!--OverrideCheckbox::end-->

            <!--PostingScheduleOptions::start-->
            <template v-if="overrideExecutionTime">
              <div
                class="grid lg:grid-flow-row desktop:grid-flow-col auto-cols-auto"
              >
                <!--PostNow::start-->
                <div class="flex items-center mr-2 border rounded-sm p-2">
                  <CstRadio
                    id="post_now"
                    v-model="publishTimeOptions.time_type"
                    name="post_options"
                    value="now"
                  >
                    Post Now
                  </CstRadio>
                </div>
                <!--PostNow::end-->

                <!--Schedule::start-->
                <div class="flex items-center mr-2 border rounded-sm p-2">
                  <CstRadio
                    id="schedule"
                    v-model="publishTimeOptions.time_type"
                    name="post_options"
                    value="schedule"
                    @change="onClickSchedule"
                  >
                    Schedule
                  </CstRadio>
                </div>
                <!--Schedule::end-->

                <!--Draft::start-->
                <div class="flex items-center border rounded-sm p-2">
                  <CstRadio
                    id="draft"
                    v-model="publishTimeOptions.time_type"
                    name="post_options"
                    value="draft"
                    @change="onClickDraft"
                  >
                    Draft
                  </CstRadio>
                </div>
                <!--Draft::end-->
              </div>
            </template>
            <!--PostingScheduleOptions::end-->

            <!--DateTime::start-->
            <template v-if="showDatePicker">
              <transition name="slide-bottom">
                <div class="flex flex-row items-center">
                  <DatePicker
                    ref="postingScheduleDatePicker"
                    class="w-60"
                    :value="
                      moment(publishTimeOptions.post_date).format(
                        'MMMM DD, YYYY hh:mm A'
                      )
                    "
                    :default-value="
                      moment(publishTimeOptions.post_date).format(
                        'MMMM DD, YYYY hh:mm A'
                      )
                    "
                    :disabled-date="disabledDate"
                    :clearable="false"
                    type="datetime"
                    :show-time-panel="showTimePanel"
                    format="MMMM DD, YYYY hh:mm A"
                    title-format="MMMM DD, YYYY  hh:mm A"
                    value-type="format"
                    :append-to-body="false"
                    @change="onChangePostDate"
                    @close="onClosePostDate"
                  >
                    <template v-slot:footer="{ emit, value }">
                      <SelectTime
                        :emit="emit"
                        :selected-date="value"
                        :timezone="
                          getters.getWorkspaces.activeWorkspace.timezone
                        "
                      />
                    </template>
                  </DatePicker>
                  <p class="text-xs text-gray-900 ml-2">
                    {{ getters.getWorkspaces.activeWorkspace.timezone }} (
                    <clock
                      time-format="MMMM DD, YYYY hh:mm A"
                      :timezone="getters.getWorkspaces.activeWorkspace.timezone"
                    ></clock>
                    )
                  </p>
                </div>
              </transition>
            </template>
            <!--DateTime::end-->
          </div>
          <!--Posting Schedule::end-->
        </template>
        <!--EditInputs::end-->

        <!--BulkEditResponse::start-->
        <template v-else>
          <!--ErrorInfo::start-->
          <div
            v-if="bulkEditResponse && !bulkEditResponse.status"
            class="flex flex-row space-x-2 bg-cs-light-red opacity-1 p-3 rounded-sm text-cs-red"
          >
            <img
              class="w-5 h-5"
              alt="info_icon"
              :src="require('@assets/img/icons/error_info_icon.svg')"
            />
            <span
              >The following posts failed to modify because of API requirements
              of the selected social platforms</span
            >
          </div>
          <!--ErrorInfo::end-->
          <PlanIssuesDropdown
            v-for="(value, key) in plansWithErrors"
            :key="`plan_with_issue_${key}`"
            :dropdown-id="`plan_issues_${key}`"
            class="border p-1 rounded-md shadow-md"
            :plan="findPlan(key)"
            :errors="value"
          ></PlanIssuesDropdown>
        </template>
        <!--BulkEditResponse::end-->
      </div>
      <!--Body::end-->

      <!--Footer::start-->
      <div class="flex flex-row justify-end space-x-3 p-4">
        <div
          v-if="!bulkEditResponse"
          v-tooltip="{
            content: disableProcessButton
              ? 'At-least one social account needs to be selected'
              : null,
          }"
        >
          <CstButton
            :disabled="disableProcessButton"
            :loading="processChangesLoader"
            @click="processBulkEditChanges"
            >Process Changes
          </CstButton>
        </div>
        <div v-else>
          <CstButton @click="closeBulkEdit">Close </CstButton>
        </div>
      </div>
      <!--Footer::end-->
    </div>
  </b-modal>
</template>

<script>
import { defineComponent, ref, computed, nextTick, watch, inject } from 'vue'
import DatePicker from 'vue2-datepicker'
import CstInputFields from '@ui/Input/CstInputFields'
import CstButton from '@ui/Button/CstButton'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem'
import CstRadio from '@ui/Radio/CstRadio'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime'
import { defaultPublishTimeOptions } from '@src/modules/composer_v2/views/composerInitialState'
import SocialAccount from '@common/components/PublicAccounts/SocialAccount'
import proxy from '@common/lib/http-common'
import { processPlannerBulkEditUrl } from '@src/modules/publish/config/api-utils'
import PlanIssuesDropdown from '@src/modules/planner_v2/components/PlanIssuesDropdown'
import CstTagsDropdown from '@ui/Dropdown/CstTagsDropdown'
import { useStore } from '@state/base'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
const clone = require('rfdc/default')

export default defineComponent({
  name: 'BulkEditPlans',
  components: {
    CstTagsDropdown,
    PlanIssuesDropdown,
    SocialAccount,
    CstSimpleCheckbox,
    CstRadio,
    CstDropdownItem,
    CstDropdown,
    CstButton,
    CstInputFields,
    SelectTime,
    DatePicker,
  },
  props: {
    selectedPlans: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const root = inject('root')
    const { getters } = useStore()

    const searchQuery = ref('')
    const selectAllSocialAccounts = ref(false)
    const postingScheduleState = ref('now')
    const postingScheduleDatePicker = ref(null)
    const processChangesLoader = ref(false)
    const plansEditConfig = ref({})
    const overrideExecutionTime = ref(false)
    const showTimePanel = ref(false)
    const publishTimeOptions = ref(clone(defaultPublishTimeOptions))
    const selectedLabels = ref([])
    const indeterminateLabels = ref([])
    const selectedMembers = ref([])
    const indeterminateMembers = ref([])
    const selectedFolder = ref({
      common: false,
      folderId: '',
    })
    const selectedSocialAccounts = ref([])
    const indeterminateSocialAccounts = ref([])
    const socialAccounts = ref(clone(getters.getSocialAccountsList))
    const labels = ref(clone(getters.getLabels))
    const members = ref(clone(getters.getWorkspaces.activeWorkspace.members))
    const publicationFolders = ref(clone(getters.getPublicationFolders))
    const bulkEditResponse = ref(null)

    // Computed Properties
    const plansCount = computed(() => props.selectedPlans.length)
    const showDatePicker = computed(
      () =>
        (publishTimeOptions.value.time_type === 'schedule' ||
          publishTimeOptions.value.time_type === 'draft') &&
        overrideExecutionTime.value
    )
    const getDefaultPublicationFolderName = computed(
      () =>
        getters.getWorkspaces?.activeWorkspace?.default_campaign_name ||
        'Untitled Campaign'
    )

    /**
     * Rendering social accounts list based on the search query if available.
     * @type {ComputedRef<*>}
     */
    const socialAccountsList = computed(() => {
      return searchQuery.value.length
        ? socialAccounts.value.filter((account) => {
            const accountName =
              'platform_name' in account ? account.platform_name : account.name
            return accountName
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
          })
        : socialAccounts.value
    })

    // @DEPRECATED (but don't remove yet) Method for sorting the social accounts based on the checked and indeterminate value.
    /* const sortSocialAccounts = () => {
      socialAccounts.value
        .sort((a, b) => {
          return Number(b.checked) - Number(a.checked)
        })
        .sort((a, b) => {
          return Number(b.checked) - Number(a.indeterminate || false)
        })
    } */

    /**
     * Shows the plans with errors when the user clicks on process plans.
     * @type {ComputedRef<unknown>}
     */
    const plansWithErrors = computed(() => {
      return bulkEditResponse.value?.errors || {}
    })

    /**
     * Disabling process button if a certain condition is not fulfilled.
     * @type {ComputedRef<boolean>}
     */
    const disableProcessButton = computed(() => {
      return (
        selectedSocialAccounts.value.length +
          indeterminateSocialAccounts.value.length ===
        0
      )
    })

    /**
     * indeterminate and selected social accounts objects
     * @type {ComputedRef<*[]>}
     */
    const bulkSocialAccounts = computed(() => {
      return [
        ...selectedSocialAccounts.value,
        ...indeterminateSocialAccounts.value,
      ]
    })

    /**
     * indeterminate and selected bulk labels
     * @type {ComputedRef<*[]>}
     */
    const bulkLabels = computed(() => {
      return [...selectedLabels.value, ...indeterminateLabels.value]
    })

    /**
     * indeterminate and selected bulk members
     * @type {ComputedRef<*[]>}
     */
    const bulkMembers = computed(() => {
      return [...selectedMembers.value, ...indeterminateMembers.value]
    })

    // Default Campaign Folder.
    publicationFolders.value.unshift({
      _id: '',
      name: getDefaultPublicationFolderName.value,
    })

    /**
     * selected publication folder object
     * @type {ComputedRef<*>}
     */
    const selectedFolderObj = computed(() => {
      return publicationFolders.value.find(
        (folder) => folder._id === selectedFolder.value.folderId
      )
    })

    /**
     * Watches if the select all value is triggered. Selects all social accounts form the list.
     */
    watch(selectAllSocialAccounts, async (value) => {
      socialAccountsList.value.forEach((account) => {
        if (
          account.validity &&
          account.validity.toLowerCase() !== 'valid' &&
          account.validity.toLowerCase() !== 'expiring_soon'
        )
          return
        account.indeterminate = false
        account.checked = value

        // remove all social accounts from indeterminate list.
        indeterminateSocialAccounts.value = []

        const selectedSocialAccountIndex =
          selectedSocialAccounts.value.findIndex(
            (acc) =>
              String(acc[acc.channel_identifier]) ===
              String(account[account.channel_identifier])
          )

        if (value && selectedSocialAccountIndex === -1) {
          selectedSocialAccounts.value.push(account)
        }
        if (!value && selectedSocialAccountIndex > -1) {
          selectedSocialAccounts.value.splice(selectedSocialAccountIndex, 1)
        }

        checkYouTubeTwitterSelection(account)
      })
    })

    /**
     * Selecting all labels
     * @param value
     */
    const selectAllLabels = (value) => {
      indeterminateLabels.value = []
      selectedLabels.value = value ? labels.value : []
    }

    /**
     * Selecting all members
     * @param value
     */
    const selectAllMembers = (value) => {
      indeterminateMembers.value = []
      selectedMembers.value = value ? members.value : []
    }

    /**
     * onClose event for when the user apply or click outside the picker
     */
    const onClosePostDate = () => {
      showTimePanel.value = false
    }

    /**
     * Range calculator for disabled dates.
     * @param date
     * @returns {boolean}
     */
    const disabledDate = (date) => {
      // Converting current time to given timezone.
      const timeStart = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: getters.getActiveWorkspace.timezone,
        })
      )
      const timeEnd = new Date(date)

      // Removing 1 day from current time.
      const now = timeStart.setHours(
        timeStart.getHours() - 24,
        timeStart.getMinutes(),
        timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
        timeEnd.getHours(),
        timeEnd.getMinutes(),
        timeEnd.getSeconds()
      )

      return value < now
    }

    /**
     * on Change event for when the user modifies the date time to the customized time.
     * @param dateTime
     */
    const onChangePostDate = (dateTime) => {
      console.log(
        'METHOD::onChangePostDate ~ event -> ',
        dateTime,
        publishTimeOptions.value
      )
      publishTimeOptions.value.post_date = clone(
        moment(dateTime).format('MMM DD, YYYY HH:mm')
      )
    }

    /**
     * Handle Selections on modal preview.
     */
    const onModalShow = () => {
      handleSocialAccountSelection()
      handleLabelSelection()
      handleMembersSelection()
      handleFoldersSelection()
    }

    /**
     * Clear state data on modal hide/close
     */
    const onModalHide = () => {
      selectAllSocialAccounts.value = false
      bulkEditResponse.value = null
      selectedSocialAccounts.value = []
      selectedLabels.value = []
      selectedMembers.value = []
      indeterminateSocialAccounts.value = []
      indeterminateLabels.value = []
      indeterminateMembers.value = []
      publishTimeOptions.value = clone(defaultPublishTimeOptions)
    }

    /**
     * Handle indeterminate and common accounts selections.
     */
    const handleSocialAccountSelection = () => {
      selectedSocialAccounts.value = []

      socialAccounts.value.forEach((account) => {
        const commonAccount = props.selectedPlans.every((plan) => {
          if (plan.account_selection[account.channel_type]) {
            const channel = plan.account_selection[account.channel_type].find(
              (channel) =>
                String(channel[account.channel_identifier]) ===
                String(account[account.channel_identifier])
            )
            return !!channel
          }
          return false
        })
        if (commonAccount) {
          account.indeterminate = false
          account.checked = true
          selectedSocialAccounts.value.push(account)
          return
        }
        const indeterminateAccount = props.selectedPlans.some((plan) => {
          if (plan.account_selection[account.channel_type]) {
            const channel = plan.account_selection[account.channel_type].find(
              (channel) =>
                String(channel[account.channel_identifier]) ===
                String(account[account.channel_identifier])
            )
            return !!channel
          }
          return false
        })
        if (indeterminateAccount) {
          account.indeterminate = true
          indeterminateSocialAccounts.value.push(account)
        }
      })
    }

    /**
     * Handle indeterminate and common labels selections
     */
    const handleLabelSelection = () => {
      selectedLabels.value = []
      labels.value.forEach((label) => {
        const commonLabel = props.selectedPlans.every((plan) => {
          if ('labels' in plan) {
            const planLabel = plan.labels.find((lab) => lab === label._id)
            return !!planLabel
          }
          return false
        })
        if (commonLabel) {
          selectedLabels.value.push(label)
          return
        }
        const indeterminateLabel = props.selectedPlans.some((plan) => {
          if ('labels' in plan) {
            const planLabel = plan.labels.find((lab) => lab === label._id)
            return !!planLabel
          }
          return false
        })
        if (indeterminateLabel) {
          label.indeterminate = true
          indeterminateLabels.value.push(label)
        }
      })
    }

    /**
     * Handle indeterminate and common Members selections
     */
    const handleMembersSelection = () => {
      selectedMembers.value = []
      members.value.forEach((member) => {
        const commonMember = props.selectedPlans.every((plan) => {
          if ('members' in plan) {
            const planMember = plan.members.find(
              (mem) => mem === member?.user_id
            )
            return !!planMember
          }
          return false
        })
        if (commonMember) {
          selectedMembers.value.push(member)
          return
        }
        const indeterminateMember = props.selectedPlans.some((plan) => {
          if ('members' in plan) {
            const planMember = plan.members.find(
              (mem) => mem === member?.user_id
            )
            return !!planMember
          }
          return false
        })
        if (indeterminateMember) {
          member.indeterminate = true
          indeterminateMembers.value.push(member)
        }
      })
    }

    /**
     * Handle indeterminate and common Folders selections
     */
    const handleFoldersSelection = () => {
      publicationFolders.value.forEach((folder) => {
        const commonFolder = props.selectedPlans.every((plan) => {
          return plan.compose_folder?._id === folder._id
        })
        if (commonFolder) {
          selectedFolder.value.common = true
          selectedFolder.value.folderId = folder._id
        }
      })
    }

    /**
     * OnClick handler for folder selection. Since we can only select one folder so it has to be common for all plans.
     */
    const onClickFolder = () => {
      selectedFolder.value.common = true
    }

    /**
     *  preparing payload and calling bulk api processing request.
     * @param event
     * @returns {Promise<void>}
     */
    const processBulkEditChanges = async (event) => {
      console.log('METHOD::processBulkEditChanges ~ event -> ', event)

      const dataPayload = {
        workspace_id: getters.getActiveWorkspace._id,
        selected_plans: props.selectedPlans.map((plan) => plan._id),
        selected_labels: selectedLabels.value.map((label) => label._id),
        selected_members: selectedMembers.value.map((member) => member.user_id),
        selected_folder: selectedFolder.value,
        selected_social_accounts: categorizeSocialAccounts(
          selectedSocialAccounts
        ),
        indeterminate_labels: indeterminateLabels.value.map(
          (label) => label._id
        ),
        indeterminate_members: indeterminateMembers.value,
        indeterminate_social_accounts: categorizeSocialAccounts(
          indeterminateSocialAccounts
        ),
        override_execution_time: overrideExecutionTime.value,
        publish_time_options: publishTimeOptions.value,
      }
      processChangesLoader.value = true
      await proxy
        .post(processPlannerBulkEditUrl, dataPayload)
        .then((response) => {
          console.log('PROXY::Response -> ', response)
          bulkEditResponse.value = response.data
          processChangesLoader.value = false

          root.alertMessage(
            bulkEditResponse.value?.message ?? '',
            bulkEditResponse.value?.status ? 'success' : 'error'
          )

          if (bulkEditResponse.value?.status) {
            // hide bulk edit modal
            root.$bvModal.hide('bulk-edit-plans')
            // refresh planner table
            emit('refresh-table')
          } else if (
            !bulkEditResponse.value?.status &&
            bulkEditResponse.value?.errors.length === 0
          ) {
            root.$bvModal.hide('bulk-edit-plans')
          }
        })
        .catch((error) => {
          console.log('PROXY::Error -> ', error)
          processChangesLoader.value = false
        })
    }

    /**
     * Categorizes the social accounts based on the channel type
     * @param accounts
     * @returns {{}}
     */
    const categorizeSocialAccounts = (accounts) => {
      const categorizedSocialAccounts = {}
      accounts.value.forEach((account) => {
        const socialAccount = socialAccounts.value.find(
          (socialAccountObj) =>
            String(socialAccountObj[socialAccountObj.channel_identifier]) ===
            String(account[account.channel_identifier])
        )
        if (socialAccount) {
          if (categorizedSocialAccounts[socialAccount.channel_type]) {
            categorizedSocialAccounts[socialAccount.channel_type].push(
              String(account[account.channel_identifier])
            )
          } else {
            categorizedSocialAccounts[socialAccount.channel_type] = []
            categorizedSocialAccounts[socialAccount.channel_type].push(
              String(account[account.channel_identifier])
            )
          }
        }
      })
      return categorizedSocialAccounts
    }

    /**
     * finds a plan from the selected plans based on the id
     * @param id
     * @returns {*}
     */
    const findPlan = (id) => {
      console.log('METHOD::getPlan ~ id -> ', id)
      return props.selectedPlans.find((item) => item._id === id)
    }

    /**
     * click handler on social account click.
     * handles the removal of indeterminate accounts
     * @param value
     */
    const onClickSocialAccount = async (value) => {
      value.indeterminate = false
      value.checked = !value.checked
      const accountIndex = indeterminateSocialAccounts.value.findIndex(
        (indeterminateAccount) =>
          indeterminateAccount[indeterminateAccount.channel_identifier] ===
          value[value.channel_identifier]
      )
      if (accountIndex > -1) {
        indeterminateSocialAccounts.value.splice(accountIndex, 1)
      }

      checkYouTubeTwitterSelection(value)
    }

    /**
     * check for youtube and tiktok (only one youtube/tiktok account is selected)
     * @param value
     */
    const checkYouTubeTwitterSelection = (value) => {
      if (
        value.channel_type === 'youtube' ||
        value.channel_type === 'twitter'
      ) {
        const accIndex = selectedSocialAccounts.value.findIndex((account) => {
          return (
            account.channel_type === value.channel_type &&
            account[account.channel_identifier] !==
              value[value.channel_identifier]
          )
        })
        if (accIndex > -1) {
          selectedSocialAccounts.value[accIndex].checked = false
          selectedSocialAccounts.value[accIndex].indeterminate = false
          selectedSocialAccounts.value.splice(accIndex, 1)
        }
      }
    }

    /**
     * click handler for labels
     * handles removal of indeterminate labels
     * @param value
     */
    const onClickLabel = (value) => {
      console.log('METHOD::onClickLabel ~ value -> ', value)
      value.indeterminate = false
      const labelIndex = indeterminateLabels.value.findIndex(
        (indeterminateLabel) => indeterminateLabel._id === value._id
      )
      if (labelIndex > -1) {
        indeterminateLabels.value.splice(labelIndex, 1)
      }
    }

    /**
     * click handler for members
     * handles removal of indeterminate members
     * @param value
     */
    const onClickMember = (value) => {
      console.log('METHOD::onClickMember ~ value -> ', value)
      value.indeterminate = false
      const memberIndex = indeterminateMembers.value.findIndex(
        (indeterminateMember) => indeterminateMember._id === value._id
      )
      if (memberIndex > -1) {
        indeterminateLabels.value.splice(memberIndex, 1)
      }
    }

    /**
     * Onclick handler for schedule time. Setting the date/time picker 1 hr ahead.
     * @returns {Promise<void>}
     */
    const onClickSchedule = async () => {
      await nextTick()
      postingScheduleDatePicker.value.openPopup()

      if (
        postingScheduleState.value === 'draft' ||
        postingScheduleState.value === 'schedule'
      )
        return

      postingScheduleState.value = 'schedule'
      publishTimeOptions.value.post_date = moment()
        .tz(getters.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
        .format('MMM DD, YYYY HH:mm')
    }

    /**
     * Onclick handler for Draft time. Setting the date/time picker 1 hr ahead.
     */
    const onClickDraft = () => {
      if (
        postingScheduleState.value === 'draft' ||
        postingScheduleState.value === 'schedule'
      )
        return

      postingScheduleState.value = 'draft'
      publishTimeOptions.value.post_date = moment()
        .tz(getters.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
        .format('MMM DD, YYYY HH:mm')
    }

    /**
     * Social account image fallback if there's no image.
     * @param event
     */
    const socialAccountImgFallback = (event) => {
      event.target.src =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    }

    const closeBulkEdit = () => {
      // refresh planner table
      if (
        !bulkEditResponse.value?.status &&
        bulkEditResponse.value?.errors &&
        Object.keys(bulkEditResponse.value?.errors).length
      )
        emit('refresh-table')
      // hide bulk edit modal
      root.$bvModal.hide('bulk-edit-plans')
    }

    return {
      getters,
      searchQuery,
      selectAllSocialAccounts,
      overrideExecutionTime,
      showTimePanel,
      plansCount,
      plansEditConfig,
      moment,
      publishTimeOptions,
      showDatePicker,
      labels,
      selectedLabels,
      members,
      selectedMembers,
      publicationFolders,
      selectedFolder,
      getDefaultPublicationFolderName,
      socialAccounts,
      socialAccountsList,
      selectedSocialAccounts,
      indeterminateLabels,
      indeterminateMembers,
      indeterminateSocialAccounts,
      processChangesLoader,
      bulkEditResponse,
      plansWithErrors,
      bulkSocialAccounts,
      bulkLabels,
      bulkMembers,
      selectedFolderObj,
      postingScheduleDatePicker,
      disableProcessButton,
      selectAllLabels,
      selectAllMembers,
      disabledDate,
      onClosePostDate,
      onChangePostDate,
      onModalShow,
      onModalHide,
      handleSocialAccountSelection,
      handleLabelSelection,
      handleMembersSelection,
      handleFoldersSelection,
      processBulkEditChanges,
      categorizeSocialAccounts,
      findPlan,
      onClickSocialAccount,
      checkYouTubeTwitterSelection,
      socialAccountImgFallback,
      onClickLabel,
      onClickMember,
      onClickSchedule,
      onClickDraft,
      onClickFolder,
      closeBulkEdit,
    }
  },
})
</script>

<style scoped></style>
