var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"profile-tagging-modal","centered":"","header-class":"cs-modal-header","hide-footer":"","no-close-on-backdrop":"","size":"lg","title-class":"cs-modal-title"},on:{"hide":_vm.onCloseConfirmation}},[_c('template',{slot:"modal-title"},[_vm._v(" Tag Instagram Public Profiles")]),_c('div',{staticClass:"mx-2 my-4"},[_c('div',{staticClass:"relative",on:{"click":_vm.handleClick}},[_c('div',{on:{"!mousemove":function($event){return _vm.handleMouseover.apply(null, arguments)}}},[_c('img',{staticClass:"w-full select-none cursor-crosshair",attrs:{"draggable":"false","src":_vm.imageUrl,"alt":""},on:{"load":_vm.onImgLoad}})]),(_vm.isImageLoaded)?_vm._l((_vm.mentions),function(mention,key){return _c('div',{key:key,staticClass:"absolute z-20",style:(`top: calc(${
              mention.y * _vm.height
            }px - 0.5rem); left: calc(${mention.x * _vm.width}px - 0.5rem)`)},[_c('div',{staticClass:"h-5 w-5 flex justify-center items-center rounded-full bg-green-50 shadow bg-opacity-50 cursor-pointer hover:bg-opacity-75",on:{"click":function($event){return _vm.deleteMention(mention.id)}}},[_c('i',{staticClass:"far fa-close"})]),_c('div',{staticClass:"absolute shadow bottom-full left-1/2 mb-2.5 transform -translate-x-1/2 rounded px-4 right-0 bottom-full text-base w-max h-max",class:{
                'bg-black-100 text-white py-1 ': mention.username,
                'bg-gray-50 text-black-100 py-3 w-[22rem]': !mention.username,
              }},[(mention.username)?[_vm._v(" "+_vm._s(mention.username)+" ")]:[_c('div',{staticClass:"flex items-center"},[_c('CstInputFields',{ref:"mentionInput",refInFor:true,staticClass:"w-100 mr-2",attrs:{"maxlength":30,"value":_vm.tagName,"placeholder":"Enter Instagram Tag","size":"small","type":"text"},on:{"value":(val) => (_vm.tagName = val)}}),_c('CstButton',{staticClass:"flex-shrink-0",attrs:{"disabled":_vm.tagName.trim() === ''},on:{"click":() => _vm.addNewMention(mention.id)}},[_vm._v("Add")])],1),(_vm.tagName.trim())?_c('div',{staticClass:"mt-2.5"},[_c('p',{staticClass:"text-sm mb-1"},[_vm._v(" Due to Instagram API limitations, we cannot show matching profiles to choose from. Please enter valid public profile. ")]),_c('a',{staticClass:"text-xs text-blue-400 hover:text-blue-500 cursor-pointer inline-flex",attrs:{"href":`https://www.instagram.com/${_vm.tagName}/`,"target":"_blank"}},[_vm._v(" Verify"),_c('span',{staticClass:"truncate inline-block max-w-xxs mx-1"},[_vm._v("@"+_vm._s(_vm.tagName.trim()))]),_vm._v("account exists on Instagram "),_c('i',{staticClass:"far fa-external-link ml-1.5"})])]):_vm._e()],_c('svg',{staticClass:"absolute text-black-100 h-2 w-full left-0 top-full",class:{
                  'text-black-100': mention.username,
                  'text-gray-50': !mention.username,
                },attrs:{"x":"0px","y":"0px","viewBox":"0 0 255 255","xml:space":"preserve"}},[_c('polygon',{staticClass:"fill-current",attrs:{"points":"0,0 127.5,127.5 255,0"}})])],2)])}):_vm._e()],2)]),_c('div',{staticClass:"flex justify-between mt-3.5 mb-4"},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-5 h-5 mx-2",attrs:{"src":require("@assets/img/composer/warning-icon.svg"),"alt":" "}}),_c('span',[_vm._v("Instagram will not publish your post if private accounts are tagged.")])]),_c('div',[_c('CstButton',{staticClass:"mr-3 w-28",attrs:{"size":"large","variant":"secondary","text":"Cancel"},on:{"click":function($event){return _vm.EventBus.$emit('profile-tagging-modal-hide')}}}),_c('CstButton',{staticClass:"w-28",attrs:{"variant":"primary","size":"large","text":"Apply"},on:{"click":_vm.setMentions}})],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }