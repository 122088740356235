<template>
  <b-modal
    id="upgrade-to-large-agency-plan"
    modal-class="update-feature-plan"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <button
      type="button"
      class="close"
      @click="$router.push({ name: 'workspaces' })"
      >&times;</button
    >
    <UpgradeToLargeAgencyPlan></UpgradeToLargeAgencyPlan>
  </b-modal>
</template>

<script>
import UpgradeToLargeAgencyPlan from './UpgradeToLargeAgencyPlan'

export default {
  components: {
    UpgradeToLargeAgencyPlan,
  },
  computed: {},
  mounted() {
    if (this.$route.name === 'upgrade_large') {
      if (this.isOnLifetimePlan()) {
        this.$bvModal.show('upgrade-to-large-agency-plan')
      } else {
        this.$router.push({ name: 'workspaces' })
      }
    }
  },
  methods: {
    hideUpgradeLargeAgencyPlanModal() {
      if (this.$route.name === 'upgrade_large') {
        this.$bvModal.hide('upgrade-to-large-agency-plan')
        this.$router.push({ name: 'workspaces' })
        return
      }
      this.$store.dispatch('triggerAction', 'influencers_and_analytics')
      this.$bvModal.hide('upgrade-to-large-agency-plan')
    },
  },
}
</script>

<style lang="less">
#upgrade-to-large-agency-plan {
  .modal-dialog {
    max-width: 1400px !important;
    /*max-width: 1940px;*/
    padding: 0 100px 0;
  }
}
</style>
