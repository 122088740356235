<script>
import { mapGetters } from 'vuex'
import DaysListing from './list/DaysListing'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../../../discovery/components/topics/NoResultsFound'
import { planner } from '../../store/mutation-types'
import { getStatusClass } from '@common/lib/planner'
import { pusherSocketPublish } from '@common/lib/pusher'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
const importedMethods = { getStatusClass }

export default {
  components: {
    NoResultsFound,
    DaysListing,
    InfiniteLoading
  },

  data () {
    return {
      bulk_disable_message:
        "This option can only be applied on 'Under Review' posts",
      labels_channel: ''
    }
  },
  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `comments_${this.getActiveWorkspace._id}`
    )
    this.bindPusherComments()

    this.labels_channel = pusherSocketPublish.subscribe(
      `labels_${this.getActiveWorkspace._id}`
    )
    this.bindPusherLabels(this.labels_channel)
  },
  beforeDestroy () {
    this.channel.unbind('comment')
    this.channel.unsubscribe()

    this.labels_channel.unbind('store_label')
    this.labels_channel.unsubscribe()
  },
  watch: {
    'getActiveWorkspace._id' (value) {
      console.log(
        'unsubscribe the channel and subscribe for the new workspace value'
      )
      if (this.channel) this.channel.unsubscribe()
      this.channel = pusherSocketPublish.subscribe(`comments_${value}`)
      this.bindPusherComments()

      if (this.labels_channel) this.labels_channel.unsubscribe()
      this.labels_channel = pusherSocketPublish.subscribe(`labels_${value}`)
      this.bindPusherLabels(this.labels_channel)
    }
  },
  created () {},
  computed: {
    ...mapGetters([
      'getPlans',
      'getPlannerLoaders',
      'getWorkspaces',
      'getPublishSelection',
      'getActiveWorkspace'
    ]),
    bulkChangeStatusOptionVisibilityStatus () {
      const plans = this.getPlans

      if (plans.check_all && !this.$route.query.filter_id) {
        const statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : [
            'scheduled',
            'published',
            'failed',
            'rejected',
            'under_review',
            'missed_review',
            'draft'
          ]
        // if more than one plan statuses are showing than option should not be available
        if (statuses.length > 1 || statuses.length === 0) return false
        if (statuses[0] !== 'under_review') return false
      }
      let status = true

      // if all selected plans are not of type under review than  option should not be available
      plans.selected_plans.forEach((selectedPlan) => {
        plans.items.forEach((plan) => {
          if (plan._id === selectedPlan) {
            if (this.getStatusClass(plan) !== 'reviewed') {
              if (
                !(
                  plan.status === 'draft' &&
                  plan.approval &&
                  plan.approval.status === 'pending_approval' &&
                  this.checkApprovalStatus(plan.approval)
                )
              ) {
                status = false
                return false
              }
            } else if (
              plan.approval &&
              plan.approval.status === 'pending_approval' &&
              !this.checkApprovalStatus(plan.approval)
            ) {
              status = false
              return false
            } else if (
              (plan.article_automation_id ||
                plan.video_automation_id ||
                plan.evergreen_automation_id ||
                plan.rss_automation_id ||
                plan.csv_id) &&
              !this.hasPermission('can_schedule_automation_plan')
            ) {
              status = false
              return false
            }
          }
        })
        if (!status) return false
      })
      return status
    },
    getTodaysDate () {
      const today = moment
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .format('YYYY-MM-DD')
      const start = moment(today + ' 00:00:00').format('MMMM DD, YYYY')
      const end = moment(today + ' 23:59:59').format('MMMM DD, YYYY')
      return start + ' - ' + end
    },
    isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    },
    planItemsCount () {
      return this.getPlans.items.length
    },
    selectedItemsCount () {
      return this.getPlans.selected_plans.length
    },
    isFetchingPlans () {
      return this.getPlannerLoaders.fetch_plans
    },
    showNullPlansSection () {
      return (
        this.isPlatformsSelected &&
        !this.planItemsCount &&
        this.getPlannerLoaders.plans_pagination_scroll === false
      )
    },
    showInfiniteLoadingSection () {
      return (
        !this.showNullPlansSection &&
        this.getPlannerLoaders.plans_pagination_scroll
      )
    },
    isPostDisplayed () {
      return this.planItemsCount && this.isPlatformsSelected
    },
    listingPostRangeText () {
      return (
        'Showing 1 - ' +
        this.planItemsCount +
        ' of ' +
        this.getPlans.total_items
      )
    },
    showAllItemsSelection () {
      const plans = this.getPlans
      return (
        !this.showTotalItemsSelection &&
        plans.selected_plans.length &&
        plans.selected_plans.length === this.planItemsCount
      )
    },
    allItemsSelectionText () {
      return 'All ' + this.selectedItemsCount + ' items are selected'
    },
    showTotalItemsSelectionOption () {
      const plans = this.getPlans
      return (
        plans.selected_plans.length === this.planItemsCount &&
        plans.total_items &&
        plans.selected_plans.length < plans.total_items
      )
    },
    totalItemsSelectionOptionText () {
      return 'Select all ' + this.getPlans.total_items + ' items'
    },
    showTotalItemsSelection () {
      const plans = this.getPlans
      return plans.check_all_items && plans.total_items
    },
    totalItemsSelectionText () {
      return 'All ' + this.getPlans.total_items + ' items are selected'
    }
  },
  methods: {
    ...importedMethods,
    onInfinitePlannerList ($state) {
      console.log('onInfinitePlannerList', $state)
      if (
        this.isPlatformsSelected &&
        this.getPlannerLoaders.plans_pagination_scroll
      ) {
        this.$store.commit(planner.SET_PAGE, this.getPlans.page + 1)
        const route = this.$route
        const options = { name: route.name, query: route.query }
        console.debug('options', options)
        options.infinite_loading = $state
        this.$store.dispatch('fetchPlans', options)
      }
    },

    clearSelectedPlans () {
      console.debug('Method:clearSelectedPlans')
      this.$store.commit(planner.SET_SELECTED_PLANS, [])
      this.$store.commit(planner.SET_CHECK_ALL, false)
      this.$store.commit(planner.SET_CHECK_ALL_ITEMS, false)
    },

    refetchPlans () {
      this.$store.dispatch('refetchPlans', this.$route)
    },

    planSelectionChange (type) {
      console.debug('Method:planSelectionChange', type)

      if (type === 'all') {
        if (!this.getPlans.check_all) {
          this.$store.commit(planner.SET_CHECK_ALL, false)
          this.$store.commit(planner.SET_CHECK_ALL_ITEMS, false)
          this.$store.commit(planner.SET_SELECTED_PLANS, [])
          return false
        }

        const plans = []
        this.getPlans.items.forEach((plan) => {
          plans.push(plan._id)
        })
        this.$store.commit(planner.SET_CHECK_ALL, true)
        this.$store.commit(planner.SET_SELECTED_PLANS, plans)
        return false
      }

      if (this.selectedItemsCount === this.planItemsCount) {
        this.$store.commit(planner.SET_CHECK_ALL, true)
        return false
      }
      this.$store.commit(planner.SET_CHECK_ALL, false)
      this.$store.commit(planner.SET_CHECK_ALL_ITEMS, false)
    },

    processBulkOperation (operation) {
      this.$parent.operation = operation
      this.$bvModal.show('processPlanBulkOperation')
    }
  }
}
</script>
<template>
  <div class="planner_list">
    <div
      class="warning_box text-center"
      v-if="!isPlatformsSelected && !isFetchingPlans"
    >
      <p
        >Please select at least one profile from the menu on the left side to
        view posts.</p
      >
    </div>

    <div class="list_inner">
      <div class="l_body">
        <div
          style="min-height: 70px"
          class="l_body_head d-flex align-items-center justify-content-between"
          v-if="isPostDisplayed"
        >
          <div class="left_block">
            <div class="checkbox_input_image">
              <input
                id="a24"
                type="checkbox"
                name="option2"
                v-model="getPlans.check_all"
                @change="planSelectionChange('all')"
              />
              <label for="a24" class="checkbox_left">Select All</label>
            </div>
            <!--gray_dropdown-->
            <div
              class="bulk_dropdown dropdown option_dropdown default_style_dropdown"
              v-if="getPlans.selected_plans.length > 1"
            >
              <div
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
              >
                <span class="text">Bulk Action</span>
                <span class="ml-auto">
                  <i class="dropdown_arrow icon_last cs-angle-down"></i>
                </span>
              </div>

              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    v-if="hasPermission('can_use_bulk_operations')"
                    class="list_item_li"
                    @click="processBulkOperation('deleted')"
                  >
                    Remove
                  </li>

                  <template v-if="hasPermission('can_schedule_plan')">
                    <template v-if="bulkChangeStatusOptionVisibilityStatus">
                      <li
                        class="list_item_li"
                        @click="processBulkOperation('scheduled')"
                      >
                        Approve
                      </li>
                      <li
                        class="list_item_li"
                        @click="processBulkOperation('rejected')"
                      >
                        Reject
                      </li>
                    </template>

                    <template v-if="!bulkChangeStatusOptionVisibilityStatus">
                      <li class="list_item_li disabled">
                        <span
                          >Approve
                          <i class="far fa-question-circle custom_tooltip">
                            <div class="tool_tip_box">
                              <div
                                class="inner_box"
                                v-html="bulk_disable_message"
                              ></div>
                            </div>
                          </i>
                        </span>
                      </li>

                      <li class="list_item_li disabled">
                        <span
                          >Reject
                          <i class="far fa-question-circle custom_tooltip">
                            <div class="tool_tip_box">
                              <div
                                class="inner_box"
                                v-html="bulk_disable_message"
                              ></div>
                            </div>
                          </i>
                        </span>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </div>
          </div>

          <div class="center_block" v-if="planItemsCount">
            <div class="warning_bar text-center" v-if="showTotalItemsSelection">
              <div class="warning_bar_inner">
                <span>{{ totalItemsSelectionText }}</span>
                <a class="clear_all_link" @click.prevent="clearSelectedPlans()"
                  >Clear selection</a
                >
              </div>
            </div>

            <div class="warning_bar text-center" v-if="showAllItemsSelection">
              <div class="warning_bar_inner">
                <span>{{ allItemsSelectionText }}</span>
                <div
                  class="select_all_post checkbox_input_image"
                  v-if="showTotalItemsSelectionOption"
                >
                  <input
                    type="checkbox"
                    id="checkAllStatus"
                    v-model="getPlans.check_all_items"
                  />
                  <label for="checkAllStatus">{{
                    totalItemsSelectionOptionText
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="right_block">
            <p>{{ listingPostRangeText }}</p>
          </div>
        </div>

        <div
          class="list_items"
          :class="{ loader_overlay_with_loader: isFetchingPlans }"
        >
          <div class="p-3" v-if="isFetchingPlans">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>

          <template v-if="!isFetchingPlans">
            <days-listing></days-listing>

            <template v-if="!isPlatformsSelected">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No results are available</h2>
                <p slot="text"></p>
              </content-available-state>
            </template>

            <template v-if="showNullPlansSection">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No posts found</h2>
                <p slot="text"
                  >No posts found for the selected filters and date-range.</p
                >
              </content-available-state>
            </template>
            <infinite-loading
              v-if="showInfiniteLoadingSection"
              @infinite="onInfinitePlannerList"
              ref="infiniteLoading"
            >
              <span slot="spinner" class="pt-3 d-block">
                <beat-loader :color="'#436aff'"></beat-loader>
              </span>
            </infinite-loading>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
