import proxy from '@common/lib/http-common'
import {
  archivedPostURL,
  deleteAllSearchHistoryURL,
  deleteIndividualSearchHistoryURL,
  discoverShowURL,
  fetchCuratedTopicByTagURL,
  fetchDiscoverInsightsURL,
  fetchDiscoverSearchHistoryURL,
  fetchDiscoverSearchURL,
  fetchDiscoverSearchURLUsingKnowledgeGraph,
  fetchDiscoverInsightsURLUsingKnowledgeGraph,
  getArticleContentURL,
  relatedTopicsURL,
  setFavoriteURL,
  unArchivedPostURL,
  unsetFavoriteURL
} from '../config/api-utils'
import { curatedTypes, discoverTypes } from './mutation-types'
import moment from 'moment'
import {
  POST_ARCHIVED_ADDED,
  POST_ARCHIVED_REMOVED,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import { discoveryBaseUrl } from '@src/config/api-utils'
import { addPostEvergreenURL } from '../../automation/config/api-utils'

const getDiscoverDateRange = () => {
  return {
    label: ' Last 30 days',
    value:
      moment.utc().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
      ' - ' +
      moment.utc().format('YYYY-MM-DDTHH:mm:ss')
  }
}

const getDiscoverRegion = () => {
  return {
    code: 'world',
    native_language: {
      code: 'en',
      languageName: 'English'
    },
    name: 'World',
    flag_position: '0 0'
  }
}

const getDiscoverLanguage = () => {
  return {
    languageName: 'English',
    code: 'en'
  }
}
export default {
  state: {
    search_total_results: 0,
    fetch: false,
    is_request_completed: false,
    curated_topic: {},
    filters: {
      topic: null,
      category: null,
      search: '',
      search_type: 'title',
      sort: 'most_shared',
      from_date: moment.utc().subtract(30, 'day').format('YYYY-MM-DD'),
      to_date: moment.utc().format('YYYY-MM-DD'),
      region: 'world',
      language: 'en',
      page: 1,
      limit: 20
    }
  },
  getters: {
    getDiscoverFetchStatus: (state) => {
      return state.fetch
    },
    getDiscoverRequestCompleted: (state) => {
      return state.is_request_completed
    },
    getDiscoverCuratedTopic: (state) => state.curated_topic,
    getDiscoverFilters: (state) => state.filters,
    getDiscoverSearchTotalResults: (state) => state.search_total_results
  },

  actions: {
    // new methods being created to improve the codebase.
    fetchCuratedTopicByTag (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      const res = proxy
        .post(fetchCuratedTopicByTagURL, payload)
        .then((res) => {
          if (res.data.status) {
            context.commit(
              discoverTypes.SET_DISCOVER_CURATED_TOPIC,
              res.data.data
            )
          }
          return res
        })
        .catch(() => null)
        .finally()
      return res
    },
    fetchDiscoverCuratedArticles (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      // let email = context.getters.getProfile.email
      // let url = fetchDiscoverSearchURL
      // if (email.includes('@contentstudio.io') || email.includes('@d4interactive.io')){
      // let url = fetchDiscoverSearchURLUsingKnowledgeGraph
      // }
      return proxy.post(fetchDiscoverSearchURLUsingKnowledgeGraph, payload)
    },
    fetchDiscoverSearchArticles (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      // let email = context.getters.getProfile.email
      // let url = fetchDiscoverSearchURL
      // if (email.includes('@contentstudio.io') || email.includes('@d4interactive.io')){
      // let url = fetchDiscoverSearchURLUsingKnowledgeGraph
      // }
      return proxy.post(fetchDiscoverSearchURLUsingKnowledgeGraph, payload)
    },
    fetchDiscoverSearchInsights (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      // let email = context.getters.getProfile.email
      // let url = fetchDiscoverInsightsURL
      // if (email.includes('@contentstudio.io') || email.includes('@d4interactive.io')){
      // let url = fetchDiscoverInsightsURLUsingKnowledgeGraph
      // }
      return proxy.post(fetchDiscoverInsightsURLUsingKnowledgeGraph, payload)
    },
    fetchDiscoverSearchYoutubeVideos (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return proxy.post(fetchDiscoverSearchURL, payload)
    },
    fetchDiscoverSearchTwitterTweets (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return proxy.post(fetchDiscoverSearchURL, payload)
    },
    fetchArticlePreviewById (context, postId) {
      return proxy.post(getArticleContentURL, {
        id: postId,
        workspace_id: context.getters.getActiveWorkspace._id
      })
    },
    fetchSearchHistory (context) {
      return proxy.post(fetchDiscoverSearchHistoryURL, {
        workspace_id: context.getters.getActiveWorkspace._id
      })
    },
    fetchRelatedTopicsSuggestions ({ commit, getters }, keyword) {
      return proxy.post(relatedTopicsURL, {
        keyword: keyword
      })
    },
    archivePostItem (context, postId) {
      return proxy
        .post(archivedPostURL, {
          post_id: postId,
          workspace_id: context.getters.getWorkspaces.activeWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            context.dispatch('toastNotification', {
              message: POST_ARCHIVED_ADDED,
              type: 'success'
            })
          }
          return res.data.status
        })
        .catch(() => {
          context.dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return null
        })
    },
    addEvergreenPost (context, payload) {
      return proxy
        .post(addPostEvergreenURL, payload)
        .then((res) => {
          if (res.data.status) {
            context.dispatch('toastNotification', {
              message: 'Post has been added in evergreen automation',
              type: 'success'
            })
          } else if (res.data.message) {
            context.dispatch('toastNotification', {
              message: res.data.message,
              type: 'error'
            })
          } else {
            context.dispatch('toastNotification', {
              message: UNKNOWN_ERROR,
              type: 'error'
            })
          }
          return res.data.status
        })
        .catch(() => {
          context.dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return null
        })
    },
    unarchivePostItem (context, postId) {
      return proxy
        .post(unArchivedPostURL, {
          post_id: postId,
          workspace_id: context.getters.getWorkspaces.activeWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            context.dispatch('toastNotification', {
              message: POST_ARCHIVED_REMOVED,
              type: 'success'
            })
          }
          return res.data.status
        })
        .catch(() => {
          context.dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return null
        })
    },
    favoritePostItem (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return proxy.post(setFavoriteURL, payload)
    },
    unfavoritePostItem (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return proxy.post(unsetFavoriteURL, payload)
    },
    deleteIndividualSearchHistory (context, payload) {
      payload.workspace_id = context.getters.getActiveWorkspace._id
      return proxy.post(deleteIndividualSearchHistoryURL, payload)
    },
    deleteAllSearchHistory (context, type = null) {
      return proxy.post(deleteAllSearchHistoryURL, {
        workspace_id: context.getters.getActiveWorkspace._id,
        type: type
      })
    },
    fetchDiscover ({ commit, getters, rootGetters, dispatch }) {
      commit('SET_IS_DISCOVER_REQUEST_COMPLETED', false)
      commit('SET_DISCOVER_FETCH_STATUS', true)
      return proxy
        .post(discoverShowURL, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id
        })
        .then((resp) => {
          commit('SET_CUSTOM_SIDEBAR_OPTIONS', resp.data.customized_sidebar)
          commit(
            curatedTypes.SET_CURATED_TOPICS_ALL,
            resp.data.top_curated_list
          )
          commit('SET_FOLDERS', resp.data.favorites)
          commit('ADD_TO_CURATED_LIST', resp.data.curated)
          commit('SET_CUSTOM_TOPICS', resp.data.custom)
          dispatch('removeDuplicates', resp.data.curated_suggestion)
          commit(curatedTypes.SET_CURATED_VIEW_LOADER, false)
          commit('SET_IS_DISCOVER_REQUEST_COMPLETED', true)
          commit('SET_TOPICS_PAGE_LOADED', true)
        })
        .catch()
    },
    fetchDiscoverTopCuratedTopics ({ commit, getters, rootGetters, dispatch }) {
      return proxy
        .post(`${discoveryBaseUrl}v2/topics/retrieve_top_curated_topics_list`, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id
        })
        .then((res) => {
          if (res.data.data) {
            commit(curatedTypes.SET_CURATED_TOPICS_ALL, res.data.data)
          }
        })
        .catch((err) => {})
    },
    fetchDiscoverCuratedTopicSuggestions ({
      commit,
      getters,
      rootGetters,
      dispatch
    }) {
      return proxy
        .post(
          `${discoveryBaseUrl}v2/topics/retrieve_curated_topics_suggestions`,
          {}
        )
        .then((res) => {
          if (res.data.data) {
            dispatch('removeDuplicates', res.data.data)
          }
        })
        .catch((err) => {})
    }
  },
  mutations: {
    [discoverTypes.SET_DISCOVER_CURATED_TOPIC] (state, value) {
      state.curated_topic = value
    },
    [discoverTypes.RESET_DISCOVER_CURATED_TOPIC] (state) {
      state.curated_topic = {}
    },
    SET_DISCOVER_FETCH_STATUS (state, value) {
      state.fetch = value
    },
    SET_IS_DISCOVER_REQUEST_COMPLETED (state, value) {
      state.is_request_completed = value
    },
    [discoverTypes.SET_DISCOVER_SEARCH_TOTAL_RESULTS] (state, value) {
      state.search_total_results = value
    }
  }
}
