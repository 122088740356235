<template>
  <div class="first-comment">
    <div class="grid-flex">
      <div class="cell-12">
        <div class="flex justify-between">
          <div class="flex align-items-center py-1 px-2">
            <div class="left_input mr-3">
              <div class="label font-bold">Create a Facebook Carousel Post</div>
            </div>
            <div class="right_input d-flex align_center">
              <label class="switch-radio">
                <input
                    v-model="getCarouselOptions.is_carousel_post"
                    type="checkbox"/>
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <AccountSelectionCarouselPost class="py-1 px-2" :show-label="false" />
        </div>

        <div v-if="!has_url" class="flex w-100 py-3 px-3 justify-center">

              <div class="w-128">
                <input
                    id="carousel-url" ref="carousel-url"
                     v-model="url.link"
                    style=""
                    class="w-100 rounded-lg bg-cs-slate"
                    type="url"
                    placeholder="Insert your link..."
                    @input="validations.invalid_url = false"
                    @keyup.enter="validateURl"
                />
                <span v-if="validations.invalid_url" class="input-error">{{
                    validations.message
                  }}</span>
              </div>


            <button  class="btn btn-studio-theme-space rounded-md mx-2 h-10 max-h-10 px-3 text-xs" :disabled="fetchingCard" @click.prevent="validateURl">
              Submit URL
              <img
                  v-if="fetchingCard"
                  style="width: 20px; margin-left: 8px"
                  src="@src/assets/img/common/gif_loader_white.gif"
                  alt=""
              />
            </button>


        </div>
        <template v-else>
          <div v-if="getCarouselOptions.cards.length > 0" class="px-3 py-3 bg-cs-slate-200" style="border-bottom:1px solid rgba(0,0,0,8%)">
            <div class="flex w-100 py-1"><span class="ml-auto text-blue-500 underline cursor-pointer" @click="clearCardsContent">Clear Content
              <i
                v-tooltip="'Clear carousel cards'"
                class="far fa-question-circle"
            ></i>
            </span>
            </div>
            <Carousel :navigation-next-label="navigationNext" :navigation-prev-label="navigationPrev" :navigation-enabled="true" :pagination-enabled="false" :scroll-per-page="false"  :per-page-custom="[[1080, 2], [1440,2]]" class="facebook-carousel">
              <template v-for="(card, index) in getCarouselOptions.cards">
                <Slide :key="index" class="slide bg-white  w-100 p-px">
                  <div class="relative block w-100 bg-white" >
                    <div class="relative  w-100 h-64 max-h-64 bg-cover bg-no-repeat bg-center border rounded-tl-md rounded-tr-md"
                         :style="{ 'background-image': 'url(' + card.picture + ')' }"
                    >

                      <span class="flex text-white absolute py-1 px-2 justify-center font-bold rounded-sm" style="background-color:#6173ffa1;">
                        {{index + 1}}
                      </span>
                      <div v-show="!(isURLShow === index)" class="flex  absolute bottom-0 w-100 justify-end py-1 px-1">
                        <i  class="fa fa-link py-2 px-2  btn btn-studio-theme-space " @click="isURLShow = index"></i>
                      </div>
                      <div v-show="isURLShow === index" class="flex justify-center items-center w-100 h-100 py-1 px-1"
                           style="background-color:rgba(0,0,0,0.5)">
                        <div class="field_group w-100">
                          <input
                              id="target-url"
                              v-model="card.url"
                              type="text"
                              placeholder="Target URL"
                              class="py-2 px-1 bg-white"
                              style="font-weight:bolder;border-radius:0"
                              onclick="this.select()"
                          />
                          <div class="block mr-auto ml-auto w-24 mt-2 py-2 px-2 text-center btn btn-studio-theme-space text-white font-bold cursor-pointer" @click="isURLShow = -1">Save</div>
                        </div>
                      </div>
                    </div>
                    <div  class="footer w-100 py-0.5 px-0.5 overflow-hidden cursor-pointer border rounded-br-md rounded-bl-md">
                      <div v-show="getCarouselOptions.call_to_action !== 'NO_BUTTON' && !(cta_btn === index)" class="absolute right-0 bottom-5 mx-2 z-40">
                         <span class="flex py-1.5 px-2 border items-center justify-center font-medium cursor-default rounded-md" style="color:#4b4f56;background-color:#e4e6eb;">
                           {{facebook_call_to_action.find(action => action.type === getCarouselOptions.call_to_action) .name}}
                         </span>
                      </div>
                      <div class="w-full mt-1 input-box">

                          <textarea
                              v-if="cta_btn === index || card.name.trim().length === 0"
                              :id="'card-name-' + index"
                              v-model="card.name"
                              type="text"
                              placeholder="Title"
                              class="card-input w-100 overflow-y-hidden"
                              style="font-weight:500;resize: none;"
                              :rows=" card.name.length > 37 ? 2 : 1 "
                              @focusout="cta_btn = -1"
                          />
<!--                        :rows=" card.name.length > 40 ? 2 : 1 "-->
                        <div v-else
                             class="mirror-card-name"
                             :class="{ 'mirror-input-default':getCarouselOptions.call_to_action === 'NO_BUTTON' }"
                             @click="showInput('name',index)"
                        >{{card.name}}</div>
                        <input
                            v-if="cta_btn === index || card.description.trim().length === 0"
                            :id="'card-description-' + index"
                            v-model="card.description"
                            type="text"
                            placeholder="Description"
                            class="card-input w-100 text-gray-800 text-sm"
                            style="font-weight: normal"
                            @focusout="cta_btn = -1"
                        >
                        <div v-else
                             class="mirror-card-description"
                             :class="{ 'mirror-input-default':getCarouselOptions.call_to_action === 'NO_BUTTON' }"
                             @click="showInput('description',index)"
                        >{{card.description}}</div>
                      </div>
                    </div>
                  </div>

                </Slide>
              </template>
              <Slide  v-if="getCarouselOptions.end_card && getCarouselOptions.end_card_url" class="slide bg-white  w-100 p-px">
                <div class="w-100 block bg-white border rounded-md">
                  <div class="flex w-100 h-64 min-h-64 max-h-64 justify-center items-center">
                        <div class="w-60 h-48  bg-cover bg-no-repeat bg-center rounded-md"
                             :style="{ 'background-image': getAccountImage  }"
                        >
                        </div>
                  </div>
                  <div  class="footer w-100 py-0.5 px-0.5 overflow-hidden cursor-pointer" style="border-top:1px solid #dee2e6;">
                    <div class="w-full mt-1 input-box text-center">
                      <div class="w-100 font-bold ">See more at</div>
                      <div class="w-100 overflow-hidden  font-normal text-gray-800 uppercase">{{getHostName}}</div>
                    </div>
                  </div>
                </div>
              </Slide>
            </Carousel>
          </div>
          <div class="flex justify-between items-center">
            <div class="w-50 my-2 py-2 mx-3">
              <p class="font-bold my-1">Call To Action</p>
              <div class="cta-dropdown">
                <select  v-model="getCarouselOptions.call_to_action">
                <template v-for="(action,index) in facebook_call_to_action">
                  <option :key="index" :value="action.type">{{action.name }}</option>
                </template>
                </select>
                <i class="icon-dropdown-cs"></i>
              </div>

            </div>
            <div class="w-50 my-2 py-2 mx-3">

              <div class="field_group">

                <div class="checkbox_container">
                  <label>
                   Include Automatic Carousel End Card
                    <input v-model="getCarouselOptions.end_card" type="checkbox" checked="checked">
                    <span class="check"></span>
                  </label>
                </div>
                <div class="field_group mt-0">
                  <input id="last-card" v-model="getCarouselOptions.end_card_url" type="text" style="padding:0.5em 1em;font-weight:normal;" class="rounded-md"  placeholder="End Card Link">

                </div>
              </div>
            </div>

          </div>
          <ErrorSection
              :errors="getCarouselErrors.errors"
              :warnings="getCarouselErrors.warnings"
          ></ErrorSection>
        </template>


        <div class="first-comment__info-box">
          <div class="first-comment__info-box-icon ml-3">
            <v-popover
                trigger="hover"
                placement="bottom-end"
                popover-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"><i class="far fa-question-circle"></i></a>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div> Carousel Post is available for: </div>
                <ul>
                  <li> <i class="fab fa-facebook-f"></i> Pages </li>

                </ul>

                <p class="first-comment__message-box"
                >How to add carousel post?
                  <a
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6221f69bab585b230a89d34b"
                  >Click here</a
                  ></p
                >
              </template>
            </v-popover>
          </div>
          <div class="first-comment__info-box-tray ml-2">
            <i class="fab fa-facebook-f"></i>
          </div>
          Available for
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import { FacebookCallToAction } from '@common/constants/common-attributes'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import {fetchLinkPreviewUrl} from '@src/modules/publish/config/api-utils'
import AccountSelectionCarouselPost from '../AccountSelectionCarouselPost'

export default {
  name: 'CarouselPostSection',

  components: {
    AccountSelectionCarouselPost,
    ErrorSection,
    Carousel,
    Slide
  },
  props: {
    isCarouselPostEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      facebook_call_to_action: FacebookCallToAction,
      has_url:false,
      url:{
        link:'',
        image:'',
        title:'',
        description:''
      },
      validations: {
        invalid_url: false,
        message:''
      },
      isURLShow:-1,
      cta_btn:-1,
      fetchingCard:false
    }
  },
  computed: {
    ...mapGetters([
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getFacebookAccounts',
      'getCarouselOptions',
      'getSocialSharingMediaDetails'
    ]),
    navigationNext: function() { return `<i class="fas fa-chevron-right"></i>` },
    navigationPrev: function() { return `<i class="fas fa-chevron-left"></i>` },
    addCard () {
      const cards = []
        if (this.getFacebookURL.url && this.getFacebookURL.image && !this.getCarouselOptions.cards[0]) {
          cards.push({
            picture: this.getFacebookURL.image,
            name: this.getFacebookURL.title,
            description: this.getFacebookURL.description,
            url: this.getFacebookURL.url
          })
          this.url.image = this.getFacebookURL.image
          this.url.title = this.getFacebookURL.title
          this.url.description = this.getFacebookURL.description
          this.url.link = this.getFacebookURL.url
          this.has_url = true
        } else if(this.getCarouselOptions.cards[0]) {
          cards.push(this.getCarouselOptions.cards[0])
          this.has_url = true
        } else if(this.url.link && this.url.image ) {
          cards.push({
            picture: this.url.image,
            name: this.url.title,
            description: this.url.description,
            url: this.url.link
          })
          this.has_url = true
        }
      if(this.getFacebookImages.length && this.has_url) {

        if(!this.getFacebookURL.url) {
          this.getFacebookImages.forEach((image, index) => {
              // carousel can have max of 10 images
              if(index > 8) return
              if (cards[0] && image == cards[0].picture) return
              if(this.isGif(image)) return
              const card = this.getCarouselOptions.cards.find(singleCard => singleCard.picture === image)
                if(card && card.picture){
                cards.push(card)
                 }
              else{
                cards.push({
                  picture: image,
                  name:  this.url.title ? this.url.title: '',
                  description: this.url.description ? this.url.description : '',
                  url: this.url.link ? this.url.link : ''
                })
              }


          })
        }
      }
      if(cards.length === 0) this.has_url = false
      this.getCarouselOptions.cards = JSON.parse(JSON.stringify(cards))
    },
    getFacebookImages () {
      if(this.getCommonBoxOptions.status)  return this.getCommonSharingDetails.image
      return this.getFacebookSharingDetails.image
    },
    getFacebookURL () {
      if(this.getCommonBoxOptions.status) return {url:this.getCommonSharingDetails.url,description:this.getCommonSharingDetails.description,title:this.getCommonSharingDetails.title,image:this.getCommonSharingDetails.image[0]}
      return {url:this.getFacebookSharingDetails.url,description:this.getFacebookSharingDetails.description,title:this.getFacebookSharingDetails.title,image:this.getFacebookSharingDetails.image[0]}
    },
    getCarouselErrors () {
      return this.carouselErrors()
    },
    getAccountImage(){
     const account =  this.getFacebookAccounts.items.find(
          (item) =>
              item.facebook_id === this.getCarouselOptions.accounts[0])
        return this.channelBackgroundImage(account, 'facebook')

    },
    getHostName(){
           const urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(this.getCarouselOptions.end_card_url)
           return urlParts[1] ? urlParts[1] : ''
    }
  },
  watch: {
    addCard (newArray, oldArray){
    },
    },
  mounted () {

  },
  methods: {
    showInput (type,index) {
      this.cta_btn = index
      if(type === 'name'){
        const id= 'card-name-'+index
        setTimeout(() => {
          document.getElementById(id).focus()
        }, 5)
      }else {
        const id= 'card-description-'+index
        setTimeout(() => {
          document.getElementById(id).focus()
        }, 5)
      }
    },
    isGif (url) {
      if (this.getSocialSharingMediaDetails[url] && this.getSocialSharingMediaDetails[url].mime_type) {
        return this.getSocialSharingMediaDetails[url].mime_type === 'image/gif'
      } else {
        return false
      }
    },
    clearCardsContent() {
        this.has_url = false
        this.url = {
          link:'',
          image:'',
          title:'',
          description:''
        }
        this.getCarouselOptions.cards = JSON.parse(JSON.stringify([]))
    },

    validateURl () {
      if(!this.url.link.trim()){
         this.validations.invalid_url = true
        this.validations.message = 'URL is required'
        return false
      }
       if(this.isValidURL(this.url.link)){

         this.fetchCardPreview(this.url.link)

       }
       else{
         this.validations.invalid_url = true
         this.validations.message = 'Invalid URl'
         return false
       }
    },
    fetchCardPreview (url){
      this.fetchingCard = true
      this.postRequest(
          fetchLinkPreviewUrl,
          { url: url },
          (response) => {

            if(response.data.status){
              this.fetchingCard = false
                if(response.data.facebook && (response.data.facebook.image || response.data.images.length > 0)){
                  this.url.link = response.data.facebook.url ? response.data.facebook.url : url
                  this.url.image = response.data.facebook.image ? response.data.facebook.image : response.data.images[0] ? response.data.images[0] : ''
                  this.url.title = response.data.facebook.title ? response.data.facebook.title : response.data.meta.title ? response.data.meta.title : ''
                  this.url.description = response.data.facebook.description ? response.data.facebook.description : response.data.meta.description ? response.data.meta.description : ''
                }
                else{
                  this.fetchingCard = false
                  this.validations.invalid_url = true
                  this.validations.message = 'Unable to generate preview for this url'
                }
            }
            else {
              this.fetchingCard = false
              this.validations.invalid_url = true
              this.validations.message = 'Unable to generate preview for this url'
            }
          },
          (error) => {
            this.fetchingCard = false
            console.debug('Exception in fetchShortLinks', error)
            this.validations.invalid_url = true
            this.validations.message = 'Unable to generate preview for this url'
          }
      )
    }
  }
  }
</script>

<style lang="scss" scoped>
    .card-input{
      border:0!important;
      width:100%!important;
      padding: 0.1rem 0.8rem !important;
      border-radius:5px!important;
      background-color:#fff!important;
      &:hover{
        cursor:pointer;
      }
      &:focus{
        width:100%!important;
        border:1px solid rgba(152, 158, 181, 0.3)!important;
        background-color:#3a455708!important;
        color:#1d2129!important;
        cursor:default;
      }
    }
    .mirror-card-name{
      border:0!important;
      padding: 0.1rem 0.8rem !important;
      border-radius:0!important;
      background-color:#fff!important;
      font-weight:500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width:235px!important;
      max-height: 50px!important;
      //min-height:30px!important;
      overflow: hidden !important;
      text-overflow: ellipsis;
      @media (max-width: 1440px) {
        width:60%!important;
      }
      &:hover{

      }
    }
    .input-box{
      max-height:75px;
      min-height:75px;
    }
    .mirror-card-description{
      //margin-top:5px;
      border:0!important;
      padding: 0.1rem 0.8rem !important;
      border-radius:0!important;
      background-color:#fff!important;
      font-weight:400;
      font-size:14px;
      color: #747474;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width:235px!important;
      max-height: 25px;
      white-space: pre-wrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      @media (max-width: 1440px) {
        width:60%!important;
      }
    }
    .mirror-input-default{
      width:100%!important;
    }
    .cta-dropdown {
      position: relative;
      margin: 0.9rem 0;
      select {
        width: 100%;
        padding: 0.5rem 1.5rem 0.5rem 1rem !important;
        overflow: hidden;
        font-size: 0.9rem;
        color: #3a4557;
        text-overflow: ellipsis;
        cursor: pointer;
        background-color: #3a455708;
        background-position-x: 97%;
        border: solid 1px rgba(152, 158, 181, 0.3);
        -webkit-transition: 1s;
        transition: 1s;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      i{
        float: right;
        margin-top: -25px;
        margin-right: 14px;
        font-size: 0.8rem;
        pointer-events: none;
      }
    }
    #carousel-url{
      padding: 0.6rem 1.3rem !important;
      border:1px solid #ffffff!important;
      font-weight:400!important;
      &:focus{
        border:1px solid #e5e5e5!important;
      }
    }
</style>
