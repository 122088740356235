<template>
  <div
    class="dropdown default_style_dropdown option_dropdown ml-2 analytic_export_dd"
  >
    <template v-if="getReportsServiceCalls.export">
      <button
        class="dropdown_header d-flex align-items-center opacity-70"
        :disabled="getReportsServiceCalls.export"
      >
        <span>Exporting...</span>
        <clip-loader :color="'#ffffff'" :size="'14px'"></clip-loader>
      </button>
    </template>
    <template v-else>
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="icon-download-cs"></i>
        <span>Export</span>
        <span class="ml-auto">
          <i class="icon-dropdown-cs"></i>
        </span>
      </div>
      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li class="list_item_li" @click.prevent="saveReport()">
            Export as PDF
          </li>
          <!--
                 <li class="list_item_li">
                      Export as CSV
                  </li>-->
          <li class="list_item_li" @click="sendReportByEmail()"> Send PDF </li>

          <li class="list_item_li" @click="schedulePDF()"> Schedule PDF </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { baseUrl } from '../../../../config/api-utils'
import proxy from '@common/lib/http-common'
import textHelper from '@common/lib/text-helper'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'download-pdf-button',
  props: {
    accounts: {
      default: []
    },
    start_date: {},
    end_date: {},
    type: {}
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls', 'getActiveWorkspace'])
  },
  methods: {
    ...mapActions(['renderReportsService']),
    async saveReport () {
      const res = await this.renderReportsService({
        date: `${this.start_date} - ${this.end_date}`,
        accounts: this.accounts,
        type: this.type,
        action: 'render'
      })

      // download the report for the user.

      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url

      // improve the file download name
      const date = textHelper.onlyDateStringIntlTime(
        `${this.start_date} - ${this.end_date}`
      )
      const fileName =
        textHelper.ucFirst(this.type) +
        ` Report (${textHelper.ucFirst(
          this.getActiveWorkspace.slug
        )}) - ${date}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
    schedulePDF () {
      $('#scheduleReport').modal('show')
      EventBus.$emit('schedule-report', {accounts: this.accounts, network: this.type})
    },
    sendReportByEmail () {
      $('#sendReportByEmail').modal('show')
      EventBus.$emit(
        'send-report-by-email',
          {
            accounts: this.accounts,
            network: this.type,
            startDate: this.start_date,
            endDate: this.end_date
          }
      )
    }
  }
}
</script>
