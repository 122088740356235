<template>
  <div class="auth">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container auth-form-container--block">
          <form class="auth-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2>Approver Login</h2>
            </div>
            <CstFloatingLabelInput
              id="email"
              v-model="email"
              class="mb-6"
              type="email"
              label="Email Address"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="auth-form__button-field">
              <button
                class="btn btn-studio-theme-space btn-size-large"
                type="submit"
                :disabled="loginLoader"
                @click.prevent="sendMagicLink"
              >
                <span>Send Login Email</span>
                <img
                  v-if="loginLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
              /></button>
            </div>
          </form>

          <div class="auth-form-container__footer mt-5">
            <p
              >Not an approver?
              <router-link :to="{ name: 'login' }"
                >Use the regular Sign In</router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <FeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import { loginWithMagicLinkURL } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import FeaturesSlider from '../components/FeaturesSlider'

export default {
  name: 'MagicSignIn',
  components: {
    FeaturesSlider,
    CstFloatingLabelInput,
  },
  data() {
    return {
      email: '',
      loginLoader: false,
    }
  },
  methods: {
    async sendMagicLink() {
      console.debug(this.email)
      const parameters = { email: this.email }
      this.loginLoader = true
      const resp = await this.$http
        .post(loginWithMagicLinkURL, parameters)
        .catch((err) => {
          if (err.response.status === 500) {
            this.alertMessage(UNKNOWN_ERROR, 'error')
            return false
          }
        })
        .finally(() => {
          this.loginLoader = false
        })
      // this.loginLoader = false

      if (resp.data.status === false) {
        this.alertMessage(resp.data.message, 'error')
      } else {
        this.alertMessage(
          'We sent you an email. Click the link in the email to log in instantly.',
          'success'
        )
      }
    },
  },
}
</script>

<style scoped></style>
