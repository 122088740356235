export const explore = [
  {
    show_all: true,
    heading: 'Explore Curated Topics',
    sub_heading: 'Featured',
    list: [
      {
        id: '10001',
        name: 'Startups &  VC',
        label: '/Business & Industrial/Business Services/',
        label_list: ['/Business & Industrial', 'Finance/Investing'],
        picture: 'https://winds.getstream.io/static/media/vc.0bc5b570.jpg',
        hashtags: [
          '#startups',
          '#venturecapitalist',
          '#venturecapital',
          '#funding',
          '#investment',
          '#investing',
          '#leanstartup',
          '#techstartup'
        ]
      },
      {
        id: '10002',
        name: 'Programming',
        label: '/Science/Computer Science',
        label_list: [
          '/Science/Computer Science',
          '/Computers & Electronics/Programming'
        ],
        hashtags: [
          '#php',
          '#java',
          '#linux',
          '#erlang',
          '#kotlin',
          '#javascript',
          '#swift',
          '#android'
        ],
        picture:
          'https://winds.getstream.io/static/media/programming.0c1d947b.jpg'
      },
      {
        id: '10003',
        name: 'Machine Learning & AI',
        label: '/News/Business News',
        picture:
          'https://winds.getstream.io/static/media/machinelearning.461c076f.jpg'
      },
      {
        id: '10004',
        name: 'News',
        label: '/News/Business News/Financial Markets News',
        picture: 'https://winds.getstream.io/static/media/news.c6b1c342.jpg'
      },
      {
        id: '10005',
        name: 'VR',
        label: '/News/Weather',
        picture: 'https://winds.getstream.io/static/media/vr.48a6f3e6.jpg'
      },
      {
        id: '10006',
        name: 'Lifehacks',
        label: '/News/Health News',
        picture:
          'https://winds.getstream.io/static/media/lifehacks.f6163bc4.jpg'
      },
      {
        id: '10007',
        name: 'Marketing',
        label: '/News/Health News',
        picture:
          'https://winds.getstream.io/static/media/marketing.6e97843a.jpg'
      },
      {
        id: '10008',
        name: 'Gaming',
        label: '/News/Health News',
        picture: 'https://winds.getstream.io/static/media/gaming.42ea344b.jpg'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'News',
    list: [
      {
        name: 'Politics',
        label: '/News/Politics',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Sports News',
        label: '/News/Sports News',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Business News',
        label: '/News/Business News',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Financial News',
        label: '/News/Business News/Financial Markets News',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Weather',
        label: '/News/Weather',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Health News',
        label: '/News/Health News',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'Arts & Entertainment',
    list: [
      {
        name: 'Music & Audio',
        label: '/Arts & Entertainment/Music & Audio',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Celebrities',
        label: '/Arts & Entertainment/Celebrities',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Movies',
        label: '/Arts & Entertainment/Movies',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Tv & Video',
        label: '/Arts & Entertainment/TV & Video',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Comics & Animation',
        label: '/Arts & Entertainment/Comics & Animation',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Humor',
        label: '/Arts & Entertainment/Humor',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Performing Arts',
        label: '/Arts & Entertainment/Performing Arts',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Visual Art & Design',
        label: '/Arts & Entertainment/Visual Art & Design',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Online Media',
        label: '/Arts & Entertainment/Online Media',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Events & Listings',
        label: '/Arts & Entertainment/Events & Listings',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Entertainment Industry',
        label: '/Arts & Entertainment/Entertainment Industry',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Offbeat',
        label: '/Arts & Entertainment/Offbeat',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },

  {
    show_all: false,
    heading: null,
    sub_heading: 'Beauty & Fitness',
    list: [
      {
        name: 'Fashion & Style',
        label: '/Beauty & Fitness/Fashion & Style',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Face & Body Care',
        label: '/Beauty & Fitness/Face & Body Care',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Fitness',
        label: '/Beauty & Fitness/Fitness',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Weight loss',
        label: '/Beauty & Fitness/Weight loss',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Hair care',
        label: '/Beauty & Fitness/Hair care',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Beauty Pageants',
        label: '/Beauty & Fitness/Beauty Pageants',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Body Art',
        label: '/Beauty & Fitness/Body Art',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Cosmetic Procedures',
        label: '/Beauty & Fitness/Cosmetic Procedures',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'Health Conditions',
    list: [
      {
        name: 'Health Conditions',
        label: '/Health/Health Conditions',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Medical Facilities & Services',
        label: '/Health/Medical Facilities & Services',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Reproductive Health',
        label: '/Health/Reproductive Health',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Women’s Health',
        label: '/Health/Women’s Health',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Men’s Health',
        label: '/Health/Men’s Health',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Nutrition',
        label: '/Health/Nutrition',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Pharmacy',
        label: '/Health/Pharmacy',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Mental Health',
        label: '/Health/Mental Health',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Substance Abuse',
        label: '/Health/Substance Abuse',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },

      {
        name: 'Public Health',
        label: '/Health/Public Health',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Health Foundations & Medical Research',
        label: '/Health/Health Foundations & Medical Research',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Aging & Geriatrics',
        label: '/Health/Aging & Geriatrics',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Health Education & Medical Training',
        label: '/Health/Health Education & Medical Training',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Oral & Dental Care',
        label: '/Health/Oral & Dental Care',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Vision Care',
        label: '/Health/Health Conditions//Vision Care',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'Travel',
    list: [
      {
        name: 'Air Travel',
        label: '/Travel/Air Travel',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Tourist Destinations',
        label: '/Travel/Tourist Destinations',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Hotels & Accommodations',
        label: '/Travel/Hotels & Accommodations',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Bus & Rail',
        label: '/Travel/Bus & Rail',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Car Rental & Taxi Services',
        label: '/Travel/Car Rental & Taxi Services',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Cruises & Charters',
        label: '/Travel/Cruises & Charters',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'Finance',
    list: [
      {
        name: 'Investing',
        label: '/Finance/Investing',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Accounting & Audit',
        label: '/Finance/Accounting & Audit',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Insurance',
        label: '/Finance/Insurance',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Banking',
        label: '/Finance/Banking',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Credit & Lending',
        label: '/Finance/Credit & Lending',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Financial Planning & Management',
        label: '/Finance/Financial Planning & Management',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Grants, Scholarships and Aids',
        label: '/Finance/Grants, Scholarships & Financial Aid',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  },
  {
    show_all: false,
    heading: null,
    sub_heading: 'Science',
    list: [
      {
        name: 'Biological Sciences',
        label: '/Science/Biological Sciences',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Engineering & Technology',
        label: '/Science/Engineering & Technology',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Astronomy',
        label: '/Science/Astronomy',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Earth Sciences',
        label: '/Science/Earth Sciences',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Computer Science',
        label: '/Science/Computer Science',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Physics',
        label: '/Science/Physics',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Ecology & Environment',
        label: '/Science/Ecology & Environment',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Chemistry',
        label: '/Science/Chemistry',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      },
      {
        name: 'Mathematics',
        label: '/Science/Mathematics',
        picture: 'https://image.prntscr.com/image/-p6XQAWMTsiQuyTUE4m_Lw.png'
      }
    ]
  }
]
