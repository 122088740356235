<template>
  <div
    v-on-clickaway="handleClickAway"
    v-floatingtip.right="
      disabled ? 'Select two or more posts to perform bulk operations' : false
    "
    class="inline-block text-left"
    :class="{
      'relative z-100': isOpen,
      'grayscale filter cursor-not-allowed opacity-70': disabled,
    }"
  >
    <div>
      <button
        type="button"
        class="inline-flex overflow-hidden text-ellipsis whitespace-pre justify-between items-center w-full rounded-lg color-border shadow-xs bg-cs-light-gray font-normal text-gray-800 hover:bg-gray-50 focus:outline-none"
        :class="classObject"
        aria-expanded="true"
        aria-haspopup="true"
        @click="handleOpen"
      >
        <slot name="selected"> Select </slot>

        <div class="transition ml-2" :class="{ 'fa-rotate-180': isOpen }">
          <slot name="arrow">
            <i class="fas fa-caret-down text-lg"></i>
          </slot>
        </div>
      </button>
    </div>
    <div
      v-show="isOpen"
      class="max-h-72 overflow-y-auto origin-top-right absolute right-0 w-full rounded-xl shadow-lg bg-white focus:outline-none z-50"
      :class="[{ ...placementClassObject }, containerClasses]"
    >
      <div
        ref="dropdownList"
        class="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        :data-active="selected"
        tabindex="-1"
        @keydown="keyHandler"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstDropdown',
  provide() {
    return {
      ctx: this,
    }
  },
  props: {
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    dropdownPlacement: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: this.open,
      selected: 0,
    }
  },
  computed: {
    classObject: function () {
      return {
        'px-3 py-1.5 text-sm': this.size === 'small',
        'px-3 py-2 text-sm': this.size === 'default',
        'px-3 py-3 text-base': this.size === 'large',
        [this.buttonClasses]: true,
        'pointer-none': this.disabled,
      }
    },
    placementClassObject: function () {
      return {
        'bottom-full mb-2': this.dropdownPlacement === 'top',
        'mt-2': this.dropdownPlacement === 'bottom',
      }
    },
  },
  methods: {
    handleClickAway() {
      this.isOpen = false
      this.$emit('onClose')
    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
        this.$emit('onClose')
      } else {
        this.isOpen = true
        setTimeout(() => {
          this.$refs.dropdownList.focus()
        }, 100)
      }
    },
    keyHandler(e) {
      console.log(e)
      if (e.keyCode === 38 && this.selected > 0) {
        e.preventDefault()
        this.selected--
      } else if (
        e.keyCode === 40 &&
        this.selected < this.$refs.dropdownList.children.length - 1
      ) {
        e.preventDefault()
        this.selected++
      }
    },
  },
}
</script>
