<script>
import { mapGetters, mapActions } from 'vuex'
import VTooltip from 'v-tooltip'
import { planner } from '../../store/mutation-types'

// Vue.use(VTooltip)
export default {
  data () {
    return {
      saveFilterLoader: false,
      save_filter_option: 'new',
      planner_filters: [],
      filterName: ''
    }
  },
  created () {
    this.initializePlanner()
  },
  computed: {
    ...mapGetters(['getWorkspaces', 'getLabels', 'getActivePlannerFilters'])
  },
  watch: {
    save_filter_option (value) {
      if (value === 'update') {
        this.filterName = this.getActivePlannerFilters.name
      }
    },
    'getActiveWorkspace._id' () {
      this.planner_filters = []
      this.filterName = ''
      this.save_filter_option = 'new'
      this.saveFilterLoader = false
      this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)
      this.initializePlanner()
    }
  },
  methods: {
    ...mapActions([
      'createPlannerFilter',
      'setDefaultPlannerFilter',
      'removePlannerFilter',
      'fetchPlannerFilters'
    ]),
    readyToCreateNewFilter () {
      if (this.$route.query.type && this.$route.query.type !== '') return true
      if (
        this.$route.query.statuses &&
        this.$route.query.statuses.split(',').length !== 7
      ) {
        return true
      }
      if (
        this.$route.query.members &&
        this.$route.query.members.split(',').length !==
          this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            .length
      ) {
        return true
      }
      if (
        this.$route.query.created_by &&
        this.$route.query.created_by.split(',').length !==
          this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            .length
      ) {
        return true
      }
      if (
        this.$route.query.labels &&
        this.$route.query.labels.split(',').length !== this.getLabels.length
      ) {
        return true
      }
      if (
        this.$route.query.campaigns &&
        this.$route.query.campaigns.split(',').length !==
          this.$parent.getComposerFolderIds().length
      ) {
        return true
      }
      if (
        this.$route.query.content_category &&
        this.$route.query.content_category.split(',').length !==
          this.$parent.getPlannerCategoriesIds().length
      ) {
        return true
      }
      return false
    },

    async savePlannerFilterRequest (filterName, id = null) {
      console.debug('Method:savePlannerFilterRequest')
      if (filterName && filterName.trim() === '') {
        this.alertMessage('Please enter name for Filter.', 'error')
        return false
      }
      this.saveFilterLoader = true
      const payload = {
        name: filterName,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        type: this.$parent.getFiltersType,
        statuses: this.$parent.getFiltersStatuses,
        members: this.$parent.getFiltersMembers,
        created_by: this.$parent.getFiltersCreatedMembers,
        labels: this.$parent.getFiltersLabels,
        campaigns: this.$parent.getFiltersCampaigns,
        content_category: this.$parent.getFiltersCategories,
        default: false
      }
      if (id) payload.id = id
      const response = await this.$store.dispatch(
        'createPlannerFilter',
        payload
      )
      if (response) {
        if (id) {
          const filterIndex = this.planner_filters.findIndex(
            (item) => item._id === id
          )
          if (filterIndex >= 0) this.planner_filters[filterIndex] = response
        } else this.planner_filters = this.planner_filters.concat(response)
        this.refetchFilterPosts(response)
      }
      this.saveFilterLoader = false
    },

    refetchFilterPosts (response) {
      this.$parent.clearSidebarFilters()
      this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, response)
      this.$router.push({ query: { filter_id: response._id } })
      this.$parent.setCheckAllOptions()
      this.$parent.refetchPlans()
    },

    selectFilter (filter) {
      this.$parent.clearSidebarFilters()
      if (
        this.$route.query.filter_id &&
        this.$route.query.filter_id === filter._id
      ) {
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)
        this.$router.push({})
      } else {
        this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, filter)
        this.$router.push({ query: { filter_id: filter._id } })
      }
      this.$parent.setCheckAllOptions()
      this.$parent.refetchPlans()
      $('.dropdown-menu').removeClass('show')
    },

    async setDefaultFilterStatus (filterId, status, index) {
      console.debug('Method:setDefaultFilterStatus')
      const payload = {
        filter_id: filterId,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        status: status
      }
      const response = await this.$store.dispatch(
        'setDefaultPlannerFilter',
        payload
      )
      if (response) this.planner_filters = response
    },

    async removeFilter (filterId, index) {
      console.debug('Method:removeFilter')
      const payload = {
        filter_id: filterId,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }
      const response = await this.$store.dispatch(
        'removePlannerFilter',
        payload
      )
      if (response) {
        this.planner_filters.splice(index, 1)
        if (
          this.getActivePlannerFilters &&
          this.getActivePlannerFilters._id &&
          this.getActivePlannerFilters._id === filterId
        ) {
          this.$parent.clearSidebarFilters()
          this.$store.commit(planner.SET_PLANNER_ACTIVE_FILTER, null)
          this.$router.push({})
          this.$parent.setCheckAllOptions()
          this.$parent.refetchPlans()
        }
      }
    },

    readyToFetchFilterChanges () {
      const query = this.$route.query
      if (query.type && query.type !== '') return true
      if (
        query.statuses ||
        query.members ||
        query.created_by ||
        query.labels ||
        query.campaigns ||
        query.content_category
      ) {
        return true
      }
      return false
    },

    savePlannerFilter (name) {
      if (
        this.save_filter_option === 'update' &&
        this.getActivePlannerFilters &&
        this.getActivePlannerFilters._id
      ) {
        this.savePlannerFilterRequest(name, this.getActivePlannerFilters._id)
        $('.dropdown-menu').removeClass('show')
        return true
      }
      this.savePlannerFilterRequest(name)
      $('.dropdown-menu').removeClass('show')
    },

    resetFilternameText () {
      this.save_filter_option = 'new'
      this.filterName = ''
      // if (!this.getActivePlannerFilters._id && this.save_filter_option === 'new') this.filterName = ''
    },

    async initializePlanner () {
      this.planner_filters = await this.$store.dispatch('fetchPlannerFilters')
    }
  }
}
</script>

<template>
  <div class="save_filter">
    <div v-if="planner_filters.length === 0" class="dropdown">
      <button
        class="btn_click btn full_width"
        data-toggle="dropdown"
        :class="{ save_button: this.readyToCreateNewFilter() }"
        :disabled="!readyToCreateNewFilter()"
        @click="filterName = ''"
      >
        <img src="../../../../assets/img/common/save-icon.svg" /> Save Filter
      </button>

      <hr />

      <div class="save_filter_pop dropdown-menu dropdown-menu-right">
        <p>Save New Filter</p>

        <input
          v-model="filterName"
          type="text"
          class="input_field"
          placeholder="Name this Filter"
          @keyup.enter="savePlannerFilterRequest(filterName)"
        />

        <div class="main_head d-flex align-items-center">
          <div class="head_left">
            <button class="btn_click btn"> Cancel </button>
          </div>

          <div class="head_right ml-auto">
            <button
              class="btn_click btn save_button"
              :disabled="filterName.trim() === '' || saveFilterLoader"
              @click.prevent="savePlannerFilterRequest(filterName)"
            >
              <img src="../../../../assets/img/common/save-icon.svg" /> Save
              Filter
              <clip-loader
                v-if="saveFilterLoader"
                class="d-inline-block align-middle"
                style="margin-right: 10px"
                color="#989eb5"
                :size="'25px'"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="">
      <div class="main_head d-flex align-items-center">
        <div class="head_left">
          <div
            class="dropdown prevent_close_dropdown option_dropdown save_filter_dropdown default_style_dropdown"
          >
            <div
              class="dropdown_header align-items-center"
              data-toggle="dropdown"
            >
              <span
                v-if="
                  this.getActivePlannerFilters &&
                  this.getActivePlannerFilters.name
                "
                v-tooltip.top="this.getActivePlannerFilters.name"
                class="text filter-name-head"
                >{{ this.getActivePlannerFilters.name }}</span
              >
              <span v-else class="text">Saved Filters</span>

              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>

            <div class="dropdown-menu dropdown-menu-right save_filters_list">
              <p>Saved Filters</p>
              <ul>
                <li
                  v-for="(filter, index) in planner_filters"
                  :class="{
                    active:
                      $route.query.filter_id &&
                      $route.query.filter_id === filter._id,
                  }"
                >
                  <div class="filtername" @click.prevent="selectFilter(filter)">
                    <span v-tooltip.top-center="filter.name">
                      {{ filter.name }}
                    </span>
                  </div>
                  <div class="filter_list_links">
                    <a
                      v-if="filter.default"
                      href=""
                      @click.prevent="
                        setDefaultFilterStatus(filter._id, 'remove', index)
                      "
                    >
                      Remove as default</a
                    >
                    <a
                      v-else
                      href=""
                      @click.prevent="
                        setDefaultFilterStatus(filter._id, 'add', index)
                      "
                    >
                      Set as default</a
                    >
                    <a
                      v-tooltip.top-center="'Delete'"
                      href=""
                      @click.prevent="removeFilter(filter._id, index)"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="head_right ml-auto">
          <div class="dropdown prevent_close_dropdown">
            <div
                :class="{ save_button: this.readyToFetchFilterChanges() }"
                :disabled="!readyToFetchFilterChanges()"
                class="btn_click btn appearance-none"
                data-toggle="dropdown"
                @click="resetFilternameText"
            >
              <i class="fa fa-save"></i> Save
            </div>

            <div class="dropdown-menu dropdown-menu-right update_filter_list">
              <p>Saved Filters</p>

              <ul class="inner">
                <li
                  v-if="
                    this.getActivePlannerFilters &&
                    this.getActivePlannerFilters._id
                  "
                  class="list_item_li"
                >
                  <div class="radio_input_image">
                    <input
                      id="update_filter"
                      v-model="save_filter_option"
                      value="update"
                      type="radio"
                    />
                    <label for="update_filter" class="radio_left">
                      Update '{{ getActivePlannerFilters.name }}'
                    </label>
                  </div>
                </li>

                <li class="list_item_li">
                  <div class="radio_input_image">
                    <input
                      id="save_bew_filter"
                      v-model="save_filter_option"
                      value="new"
                      type="radio"
                    />
                    <label for="save_bew_filter" class="radio_left">
                      Save as a new Filter
                    </label>
                  </div>
                </li>
              </ul>
              <div class="fields_group">
                <!--v-if="save_filter_option==='new'"-->
                <input
                  v-model="filterName"
                  type="text"
                  placeholder="Name this filter"
                  @keyup.enter="savePlannerFilter(filterName)"
                />
                <button
                  class="btn_click btn save_button full_width"
                  :disabled="
                    save_filter_option === '' ||
                    filterName.trim() === '' ||
                    saveFilterLoader
                  "
                  @click.prevent="savePlannerFilter(filterName)"
                >
                  Save Filter
                  <clip-loader
                    v-if="saveFilterLoader"
                    class="d-inline-block align-middle"
                    style="margin-right: 10px"
                    color="white"
                    :size="'15px'"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  </div>
</template>
