<template>
  <div
    class="topics-insights-container__chart_full_width"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ noDataMessage }}
        </p>
      </div>
    </template>

    <h2>Articles Published Over Time & Engagement</h2>

    <div
      class="topics-insights-container__chart_legends"
      id="articlesPublishedOverTime"
    />

    <div class="topics-insights-container__chart_full_width__chart">
      <highcharts
        :options="articlesPublishedOverTime"
        ref="highcharts_articles_published"
      ></highcharts>
    </div>

    <!--        <div class="card_v1">-->
    <!--            <div class="card_inner">-->
    <!--                <div class="card_head">-->
    <!--                    <h2>Articles Published Over Time & Engagement</h2>-->
    <!--                    <ul class="ds_item_highlight " id="engagement_follower_item_highlight">-->
    <!--                        &lt;!&ndash;<li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span class="pink color_box"></span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span>Likes</span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;</li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span class="red color_box"></span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span>Reports</span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;</li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span class="blue color_box"></span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span>Comments</span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;</li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<li>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span class="green color_box"></span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;<span>Followers</span>&ndash;&gt;-->
    <!--                        &lt;!&ndash;</li>&ndash;&gt;-->
    <!--                    </ul>-->

    <!--                </div>-->

    <!--                <div class="card_content" :class="{'card_content_blur': isZero()}">-->

    <!--                    <template-->
    <!--                            v-if="articles_published_posts_count.length === 0 && articles_published_engagement.length === 0">-->
    <!--                        <div class="no_data_for_chart">{{noDataMessage}}</div>-->
    <!--                    </template>-->
    <!--                    <div :class="{'blur_div': articles_published_posts_count.length === 0 && articles_published_engagement.length === 0}">-->
    <!--                        <highcharts :options="articlesPublishedOverTime"-->
    <!--                                    ref="highcharts_articles_published"></highcharts>-->
    <!--                    </div>-->

    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { getChartColorCode } from '@common/constants/common-attributes'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_published_posts_count: {
      default: []
    },
    articles_published_engagement: {
      default: []
    }
  },
  computed: {
    isContentNotAvailable () {
      if (
        this.articles_published_posts_count.length === 0 &&
        this.articles_published_engagement.length === 0
      ) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      articlesPublishedOverTime: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,

          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#articlesPublishedOverTime')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#436aff', '#324380'],

        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%l %P',
            month: '%e. %b',
            year: '%b'
          },
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          labels: {
            style: {
              color: 'rgba(67, 66, 93, 0.5)',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Average Number of Engagements',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Articles Published',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
          }
        ],
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            baseSeries: 1,
            pointWidth: 10,
            borderWidth: 0,
            // borderRadiusTopLeft: 15,
            // borderRadiusTopRight: 15,
            lineWidth: 2,
            lineColor: '#5467aa',
            groupPadding: 0.1
          }
        },
        series: [
          {
            baseSeries: 1,
            name: 'Number of Articles Published',
            data: [
              11, 13, 14, 11, 12, 11, 13, 14, 17, 21, 51, 3, 4, 1, 2, 3, 3, 7,
              2, 7, 1
            ],
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            yAxis: 1,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#4e92ff'],
                [1, '#73a3ef']
              ]
            }
          },
          {
            type: 'spline',
            name: 'Average Shares',
            data: [
              0, 12, 20, 20, 30, 31, 30, 33, 37, 32, 35, 33, 34, 37, 30, 20, 23,
              7, 2, 7, 2
            ],
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: '#324380',
              fillColor: '#324380',
              yAxis: 1
            }
          }
        ]
      }
    }
  },
  created () {
    if (this.articles_published_posts_count.length > 0) {
      this.articlesPublishedOverTime.series[0].data =
        this.articles_published_posts_count
    }

    if (this.articles_published_engagement.length > 0) {
      this.articlesPublishedOverTime.series[1].data =
        this.articles_published_engagement
    }

    // this.articlesPublishedOverTime.xAxis.categories = this.getInsightsArticlesPublishedDate
    /*
     */
  },
  methods: {
    isZero () {
      return (
        this.articles_published_engagement.length === 0 &&
        this.articles_published_posts_count.length === 0
      )
    }
  },
  watch: {}
}
</script>
