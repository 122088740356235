<template>
  <!-- this is an old one, we no longer use i think-->
  <div>
    <!-- layout section -->
    <div
      class="view_change_btn d-flex align-items-center"
      v-if="getSearchContentType === 'article'"
    >
      <!--<div class="view_left">-->
      <!--<h3 class="results">12,085 Results</h3>-->
      <!--</div>-->
      <div class="view_left">
        <h3 class="results" v-if="getPostsTotal > 0"
          >{{ $filters.numberToCommas(getPostsTotal) }}
          <template v-if="getPostsTotal === 1"> Result </template>
          <template v-else> Results </template>
        </h3>
      </div>
      <div class="view_change_btn_inner ml-auto">
        <p
          v-tooltip.top-center="'Grid View'"
          class="grid_icon"
          @click.prevent="changeLayout('grid')"
          :class="{ active: getTopicsLayout === 'grid' }"
        >
          <i class="icon-groups-cs"></i>
        </p>
        <p
          class="list_icon"
          v-tooltip.top-center="'List View'"
          @click.prevent="changeLayout('list')"
          :class="{ active: getTopicsLayout === 'list' }"
        >
          <i class="icon-Listview_icon"></i>
        </p>
      </div>
    </div>

    <div class="articles_list" v-if="getSearchContentType === 'article'">
      <div class="article_inner">
        <!-- if the filter is of the gridview, show the posts in gridview -->
        <!--{{getLoader}}
                {{getArticleLoader}}-->
        <beat-loader
          :color="'#436aff'"
          v-if="getSearchLoader.content.discoverySearch"
        ></beat-loader>
        <template v-else>
          <template v-if="getPosts && getTopicsLayout === 'grid'">
            <grid-view></grid-view>
            <!--<dailymotion></dailymotion>-->
          </template>
          <template v-if="getPosts && getTopicsLayout === 'list'">
            <list-view></list-view>
          </template>

          <!-- if the search query does not return any results, in that case we will be showing the below message-->
          <template v-if="getArticleLoader && getPosts.length === 0">
            <div
              class="article-search-results-did-you-mean"
              v-if="getDidYouMeanText"
            >
              <p
                >No result found for
                <span class="bold">{{ getDidYouMeanText }}</span></p
              >
              <p
                class="red_text"
                @click.prevent="searchByKeyword(getDidYouMeanText)"
              >
                Did you mean:
                <span class="did_you_mean_text">{{ getDidYouMeanText }}</span
                >?
              </p>
              <div class="tips_list">
                <p>Search tips:</p>
                <ul class="">
                  <li>Check the spelling of your keywords.</li>
                  <li>Try using fewer, different or more general keywords.</li>
                </ul>
              </div>
            </div>
            <template v-if="!getDidYouMeanText">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <h2>No results found</h2>
                  <p
                    >No results found for your query. Please try changing your
                    search query.</p
                  >
                </div>
              </div>
            </template>
          </template>
          <template
            v-else-if="
              getArticleLoader && getPosts.length <= 19 && getPostsTotal <= 19
            "
          >
            <!--<span >No results found for your query.</span>-->
          </template>

          <!-- lazy load for the content to be displayed here -->
          <infinite-loading
            v-else-if="getArticleLoader"
            @infinite="onInfinite"
            ref="infiniteLoading"
          >
            <span slot="spinner" class="d-block pt-3 pb-4">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <div slot="no-more" class="not_found_text">
              <!--<span class="not_found_text">-->
              <!--<i class="fa fa-frown-o" aria-hidden="true"></i>-->
              <!--<span>No more results found for your query.</span>-->
              <!--<span>Please try a different search string or change search filters.</span>-->
              <!--</span>-->

              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <h2>No more results found</h2>
                  <p>No more results found for your query.</p>
                  <p
                    >Please try a different search string or change search
                    filters.</p
                  >
                </div>
              </div>
            </div>

            <div slot="no-results">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../../assets/img/no_data_images/no_data_folder.svg"
                      alt=""
                    />
                  </div>
                  <!--<h2>No posts have been archived</h2>-->
                  <p v-if="getFilters.archived && getPosts.length == 0"
                    >No posts have been archived yet.</p
                  >
                  <template
                    v-else-if="
                      !getFilterFolderId &&
                      (getPosts.length === 0 || getFilters.articles.page > 1)
                    "
                  >
                    <p v-if="getPosts.length === 0"
                      >No results found for your query.</p
                    >
                    <p v-else>No more results found for your query.</p>
                    <!--<p>Please try a different search string or change search filters.</p>-->
                  </template>

                  <template
                    v-else-if="getFilterFolderId && getPosts.length === 0"
                    class="not_found_text"
                  >
                    <p
                      >Your folder is empty, please favorite some posts first to
                      see in here.</p
                    >
                  </template>
                </div>
              </div>
            </div>
          </infinite-loading>
        </template>

        <!-- if the filter is of listview, show list view section.-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ListView from '../../../common/ListView'
import GridView from '../../../common/GridView'
import InfiniteLoading from 'vue-infinite-loading'
import { articlesTypes } from '../../../../store/mutation-types'
import {EventBus} from "@common/lib/event-bus";

export default {
  data () {
    return {
      layout: 'grid'
    }
  },
  components: {
    GridView,
    ListView,
    InfiniteLoading
  },
  computed: {
    ...mapGetters([
      'getFilters',
      'getFilterFolderId',
      'getPosts',
      'getArticleLoader',
      'getSearchContentType',
      'getScroll',
      'getArticlesPage',
      'getSearchLoader',
      'getPostsTotal',
      'getTopicsLayout',
      'getDidYouMeanText'
    ])
  },
  methods: {
    ...mapActions(['setArticlesPage', 'articles']),
    ...mapMutations([articlesTypes.SET_ARTICLES_PAGE]),
    onInfinite ($state) {
      console.log('On infinite baby', this.getScroll, this.getArticlesPage)
      if (this.getScroll) {
        // if (this.getCurationTab == 'Article') {
        this.SET_ARTICLES_PAGE(this.getArticlesPage + 1)
        console.log('Page no is ', this.getArticlesPage)
        this.articles($state)
        // }
      }
    }
  },
  created () {
    EventBus.$on('publish-source', (value) => {
      console.log('publish-source-value', value)
      this.getPosts.forEach((item) => {
        if (item._source.id === value) item._source.shared = true
      })
    })
  },
  destroyed () {
    EventBus.$off('publish-source')
  }
}
</script>
<style lang="less">
.did_you_mean_text {
  cursor: pointer !important;
}
</style>
