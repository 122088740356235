<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      processing_loader: false,
      stage: ''
    }
  },
  computed: {
    ...mapGetters(['getConfirmationPlan']),
    isNormalStage () {
      return this.stage === ''
    },
    isSuccessStage () {
      return this.stage === 'success'
    },
    isFailStage () {
      return this.stage === 'fail'
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('replacePlan')
    },

    async replacePlan () {
      console.debug('Method::replacePlan')
      this.processing_loader = true
      this.$bvModal.hide('replacePlan')
      const response = await this.$store.dispatch(
        'replacePlan',
        this.getConfirmationPlan
      )
      if (!response) this.stage = 'fail'
      if (response) this.stage = 'success'
      this.processing_loader = false
      setTimeout(() => {
        setTimeout(() => {
          this.stage = ''
        }, 500)
        // this.$bvModal.hide('replacePlan')
      }, 1000)
    }
  }
}
</script>

<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox"
    id="replacePlan"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div v-if="isSuccessStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-
            miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>

      <div v-if="isFailStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>

      <div v-if="isNormalStage" class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Replace Post</h2>
          <p class="text-center">You won't be able to undo.</p>
        </div>
        <div class="col-sm-12 footer_box">
          <button class="btn gray cancel_btn" @click="hideModal()">No</button>
          <button
            class="btn red confirm_btn btn_loader loader_right"
            @click.prevent="replacePlan()"
          >
            <span>Yes</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
