<template>
  <div>
    <img :src="getPricingImagePath(pricingPlan.image_name)" alt="" />
    <div class="upgrade-pricing-tag-box">
      <span
        v-if="pricingPlan.display_tag"
        class="upgrade-plan-box-pricing-span"
        >{{ pricingPlan.display_tag }}</span
      >

      <span v-else class="upgrade-plan-box-pricing-span"> &nbsp; </span>

      <h3 :class="{ 'active-tag-box': !pricingPlan.display_tag }"
        >{{ pricingPlan.display }}
      </h3>
    </div>
    <div v-if="billing_period !== 'trial'" class="upgrade-pricing-amount">
      <h4
        ><span class="sign-dollar">$</span
        ><span class="plan-amount">{{ pricingPlan.price_int }}</span
        >/<span class="plan-period">month</span></h4
      >
    </div>
    <div
      v-if="billing_period === 'annually'"
      class="upgrade-billed-annually-amount"
    >
      <h4
        ><span class="sign-dollar">$</span
        ><span class="plan-amount">{{
          pricingPlan.price_billed_annually_int
        }}</span>
        <span class="billed-annually-text">billed annually</span></h4
      >
    </div>
    <ul>
      <li
        ><span class="pricing-limit-text">{{ pricingPlan.workspaces }}</span>
        <template v-if="pricingPlan.workspaces > 1"> Workspaces</template>
        <template v-else> Workspace</template>
      </li>
      <li>
        <template v-if="pricingPlan.members === 0"
          ><span class="pricing-limit-text">No</span>
        </template>
        <template v-else>
          <span class="pricing-limit-text">{{ pricingPlan.members }}</span>
        </template>
        <template v-if="pricingPlan.members > 1 || pricingPlan.members === 0">
          Team Members
        </template>
        <template v-else> Team Member</template>
      </li>
      <li>
        <span class="pricing-limit-text">{{ pricingPlan.profiles }}</span>
        Social Accounts
      </li>
      <li>
        <span v-if="pricingPlan.blogs > 1" class="pricing-limit-text"
          >{{ pricingPlan.blogs }}
        </span>
        <span v-else class="pricing-limit-text">No </span>
        <template>Blogs</template>
      </li>
      <li>
        <span v-if="pricingPlan.automations === 0" class="pricing-limit-text"
          >No
        </span>
        <span v-else-if="pricingPlan.automations > 1" class="pricing-limit-text"
          >{{ pricingPlan.automations }}
        </span>
        <span v-else class="pricing-limit-text">Unlimited </span>
        <template>Automations</template>
      </li>

      <li>
        <span v-if="pricingPlan.media_storage" class="pricing-limit-text"
          >{{ pricingPlan.media_storage }}
        </span>
        <template v-if="pricingPlan.media_storage > 1"
          >GB Media Storage
        </template>
      </li>

      <li>
        <template v-if="pricingPlan.caption_generation_credits === 0"
          ><span class="pricing-limit-text">No</span>
        </template>
        <template v-else>
          <span class="pricing-limit-text">{{
            pricingPlan.caption_generation_credits
          }}</span>
        </template>
        <template
          v-if="
            pricingPlan.caption_generation_credits > 1 ||
            pricingPlan.caption_generation_credits === 0
          "
        >
          AI Text Credits(words)
        </template>
        <template v-else> AI Text Credits(words)</template>
      </li>

      <li>
        <template v-if="pricingPlan.image_generation_credits === 0">
          <span class="pricing-limit-text">No</span>
        </template>
        <template v-else>
          <span class="pricing-limit-text">{{
            pricingPlan.image_generation_credits
          }}</span>
        </template>
        <template
          v-if="
            pricingPlan.image_generation_credits > 1 ||
            pricingPlan.image_generation_credits === 0
          "
        >
          AI Image Credits
        </template>
        <template v-else> AI Image Credits</template>
      </li>
    </ul>
  </div>
</template>

<style lang="less" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  // eslint-disable-next-line
  props: ['billing_period', 'pricingPlan'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions([]),
  },
}
</script>
