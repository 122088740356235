import isEmpty from 'is-empty'
import axios from 'axios'

import { mapGetters, mapActions } from 'vuex'
import {
  createMediaFolderUrl,
  deleteMediaFolderUrl,
  fetchMediaFolderUrl,
  UpdateMediaFolderUrl,
  uploadMediaAssetUrl,
  uploadMediaAssetByLinkUrl,
  fetchMediaAssetsUrl,
  deleteMediaAssetsUrl,
  archiveMediaAssetsUrl,
  moveMediaAssetsUrl,
  fetchMediaStatsUrl,
  getMediaFromLinkUrl,
  moveMediaLimitsUrl,
  createMediaCSV
} from '../../../config/api-utils'
import { getImagesContentURL } from '../../../../discovery/config/api-utils'

export const MediaHelperMixin = {
  computed: {
    ...mapGetters(['getAxiosRequests'])
  },
  methods: {
    ...mapActions(['setMediaStorageLimit']),
    async createNewFolderHelper (details, callback = () => {}) {
      console.debug('Method::createNewFolder')

      // Destructuring folder details
      const { name, isRootFolder } = details

      if (isEmpty(name)) {
        this.alertMessage('Folder name is required!', 'error')
        return false
      }

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      // eslint-disable-next-line no-unused-vars
      let cancel

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryCreateFolder.length;
        i++
      ) {
        const remove = this.getAxiosRequests.mediaLibraryCreateFolder[i]
        remove()
      }

      if (isRootFolder) {
        // Creating Root Folder

        try {
          const response = await axios.post(
            createMediaFolderUrl,
            {
              is_root: true,
              root_folder_id: null,
              folder_name: name.trim(),
              workspace_id: this.getActiveWorkspace._id
            },
            {
              headers: { Authorization: 'Bearer ' + this.getJWTToken }
            },
            {
              cancelToken: new CancelToken(function executor (c) {
                // An executor function receives a cancel function as a parameter
                cancel = c
              })
            }
          )

          const { status, message } = response.data

          // Showing Alert Message on resp
          this.alertMessage(message, status ? 'success' : 'error')

          // Firing callback
          callback(status)
        } catch (err) {
          console.error(err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
        }
      } else {
        // Creating Sub Folder

        // Getting Root Folder Id to add subfolder
        const { rootFolderId } = details
        if (isEmpty(rootFolderId)) {
          this.alertMessage('Please Select Root Folder!', 'error')
          return false
        }

        try {
          const response = await axios.post(
            createMediaFolderUrl,
            {
              is_root: false,
              root_folder_id: rootFolderId,
              folder_name: name.trim(),
              workspace_id: this.getActiveWorkspace._id
            },
            {
              headers: { Authorization: 'Bearer ' + this.getJWTToken }
            },
            {
              cancelToken: new CancelToken(function executor (c) {
                // An executor function receives a cancel function as a parameter
                cancel = c
              })
            }
          )

          const { status, message } = response.data
          // Showing Alert Message on resp
          this.alertMessage(message, status ? 'success' : 'error')

          // Firing callback
          callback(status)
        } catch (err) {
          console.error(err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
        }
      }

      this.getAxiosRequests.mediaLibraryCreateFolder.push(cancel)
    },

    async fetchAllFoldersHelper (isStructured, callback = () => {}) {
      console.debug('Method::fetchAllFoldersHelper')

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      // eslint-disable-next-line no-unused-vars
      let cancel

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryFetchFolders.length;
        i++
      ) {
        const remove = this.getAxiosRequests.mediaLibraryFetchFolders[i]
        remove()
      }

      try {
        const response = await axios.post(
          fetchMediaFolderUrl,
          {
            structured: isStructured,
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken }
          },
          {
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            })
          }
        )

        const { status, folders } = response.data

        // Firing callback
        callback(status, folders)
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }

      this.getAxiosRequests.mediaLibraryCreateFolder.push(cancel)
    },

    async renameFolderHelper (details, callback = () => {}) {
      console.debug('Method::renameFolderHelper')
      // Destructuring folder details
      const { id, name } = details

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      // eslint-disable-next-line no-unused-vars
      let cancel

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryUpdateFolder.length;
        i++
      ) {
        const remove = this.getAxiosRequests.mediaLibraryUpdateFolder[i]
        remove()
      }

      try {
        const response = await axios.post(
          UpdateMediaFolderUrl,
          {
            folder_id: id,
            folder_name: name,
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken }
          },
          {
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            })
          }
        )

        const { status, message } = response.data

        if (!status) {
          this.alertMessage(message, 'error')
        } else {
          // Firing callback
          callback(status, message)
        }
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }

      this.getAxiosRequests.mediaLibraryUpdateFolder.push(cancel)
    },

    async deleteFolderHelper (details, callback = () => {}) {
      console.debug('Method::deleteFolderHelper')
      // Destructuring folder details
      const { id } = details

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      // eslint-disable-next-line no-unused-vars
      let cancel

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryUpdateFolder.length;
        i++
      ) {
        const remove = this.getAxiosRequests.mediaLibraryUpdateFolder[i]
        remove()
      }

      try {
        const response = await axios.post(
          deleteMediaFolderUrl,
          {
            folder_id: id,
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            })
          }
        )

        const { status, message } = response.data

        if (!status) {
          this.alertMessage(message, 'error')
        } else {
          // Firing callback
          callback(status, message)
        }
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }

      this.getAxiosRequests.mediaLibraryUpdateFolder.push(cancel)
    },

    async uploadFilesHelper (
      details,
      callback = () => {},
      onProgress = () => {}
    ) {
      console.debug('Method::uploadFilesHelper', details)

      // Destructuring folder details
      // eslint-disable-next-line camelcase
      const { media, folder_id, is_root } = details

      console.debug(is_root)

      if (isEmpty(media)) {
        this.alertMessage('Media file is required!', 'error')
        return false
      }

      const formData = new FormData()
      Array.prototype.forEach.call(media, function (img) {
        formData.append('media_assets[]', img.file)
      })
      // media.forEach((img, i) => {
      //   formData.append('media_assets[]', img.file)
      // })

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryUploadMedia.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryUploadMedia[i].cancel()
      }

      this.getAxiosRequests.mediaLibraryUploadMedia.push(source)

      // eslint-disable-next-line camelcase
      formData.append('folder_id', folder_id || null)

      // eslint-disable-next-line camelcase
      if (!isEmpty(is_root)) {
        // eslint-disable-next-line camelcase
        formData.append('is_root', is_root || null)
      }

      // eslint-disable-next-line camelcase
      formData.append('workspace_id', this.getActiveWorkspace._id)

      try {
        const response = await axios.post(uploadMediaAssetUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.getJWTToken
          },
          cancelToken: source.token,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            onProgress(percentCompleted)
          }
        })

        // eslint-disable-next-line camelcase
        const { status, message, storageFull, usedStorage, media_container } =
          response.data

        if (storageFull) {
          this.setMediaStorageLimit(usedStorage)
          this.$bvModal.show('media-storage-limits-exceeded-modal')
        } else this.alertMessage(message, status ? 'success' : 'error')
        callback(status, message, media_container)
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(err.message)
          if (!isEmpty(err.message)) {
            this.alertMessage(err.message, 'info')
          }
        } else {
          console.error(err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
        }
      }
    },

    async uploadLinksFilesHelper (details, callback = () => {}) {
      console.debug('Method::uploadLinksFilesHelper')

      // Destructuring folder details
      // eslint-disable-next-line camelcase
      const { link, folder_id } = details

      if (isEmpty(link)) {
        this.alertMessage('Media link is required!', 'error')
        return false
      }

      // let formData = new FormData()

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryUploadMedia.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryUploadMedia[i].cancel()
      }

      this.getAxiosRequests.mediaLibraryUploadMedia.push(source)

      try {
        const response = await axios.post(
          uploadMediaAssetByLinkUrl,
          {
            link: [...link],
            // eslint-disable-next-line camelcase
            folder_id: folder_id || 'null',
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: source.token
          }
        )

        const {status, message, storageFull, usedStorage, media_container} = response.data

        if (storageFull) {
          this.setMediaStorageLimit(usedStorage)
          this.$bvModal.show('media-storage-limits-exceeded-modal')
        } else this.alertMessage(message, status ? 'success' : 'error')
        callback(status, message, media_container)
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(err.message)
          if (!isEmpty(err.message)) {
            this.alertMessage(err.message, 'info')
          }
        } else {
          console.error(err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
        }
      }
    },

    async fetchMediaHelper (filters, callback = () => {}) {
      console.debug('Method::fetchMediaHelper')

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryFetchAssets.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryFetchAssets[i].cancel()
      }

      try {
        const response = await axios.post(
          fetchMediaAssetsUrl,
          {
            page: filters.page,
            limit: filters.limit,
            folder_id: filters.folder_id,
            type: filters.type,
            search: filters.search,
            sort: filters.sort,
            workspace_id: this.getActiveWorkspace._id,
            archived: filters.archived
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: source.token
          }
        )

        const { status, media, message } = response.data

        if (!status) {
          this.alertMessage(message, 'error')
        } else {
          // Firing callback
          callback(status, media)
        }
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }

      this.getAxiosRequests.mediaLibraryFetchAssets.push(source)
    },

    async removeMediaAssetsHelper (
      filters,
      isArchive = false,
      callback = () => {}
    ) {
      console.debug('Method::removeMediaAssetsHelper')

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryRemoveAssets.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryRemoveAssets[i].cancel()
      }

      this.getAxiosRequests.mediaLibraryRemoveAssets.push(source)

      const payload = {
        ids: filters.ids,
        workspace_id: this.getActiveWorkspace._id
      }

      if (filters.folder_id) {
        payload.folder_id = filters.folder_id
      }

      try {
        const response = await axios.post(
          isArchive ? archiveMediaAssetsUrl : deleteMediaAssetsUrl,
          {
            ...payload
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: source.token
          }
        )

        const { status, removed } = response.data

        if (!status) {
          this.alertMessage('Unable to delete asset(s)', 'error')
        } else {
          // Firing callback
          callback(status, removed)
        }
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }
    },

    async moveMediaAssetsHelper (filters, callback = () => {}, restoreItems = false) {
      console.debug('Method::moveMediaAssetsHelper')

      // destructing data
      const { assets, folderId } = filters

      if (isEmpty(folderId)) {
        this.alertMessage('Folder is required!', 'error')
        return false
      }

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryUploadMedia.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryUploadMedia[i].cancel()
      }

      this.getAxiosRequests.mediaLibraryUploadMedia.push(source)

      const payload = {
        assets: assets,
        folder_id: folderId,
        workspace_id: this.getActiveWorkspace._id
      }

      if (filters.current_folder) {
        payload.current_folder = filters.current_folder
      }

      if (filters.restore) {
        payload.restore = filters.restore
      }

      try {
        const response = await axios.post(
          moveMediaAssetsUrl,
          {
            ...payload
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: source.token
          }
        )

        const { status, message } = response.data

        if (!status) {
          this.alertMessage(message, 'error')
        } else {
          if(restoreItems) {
            this.alertMessage('File(s) restored successfully.', 'success')
          }else{
            this.alertMessage(message, 'success')
          }
          // Firing callback
          callback(status, message)
        }
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }
    },

    async fetchMediaStatsHelper (callback = () => {}) {
      console.debug('Method::fetchMediaStatsHelper')

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      // eslint-disable-next-line no-unused-vars
      let cancel

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryFetchFoldersStats.length;
        i++
      ) {
        const remove = this.getAxiosRequests.mediaLibraryFetchFoldersStats[i]
        remove()
      }

      try {
        const response = await axios.post(
          fetchMediaStatsUrl,
          {
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken }
          },
          {
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            })
          }
        )

        const { status, stats } = response.data

        callback(status, stats)
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }

      this.getAxiosRequests.mediaLibraryFetchFoldersStats.push(cancel)
    },

    async fetchMediaMicroLinkHelper (details, callback = () => {}) {
      console.debug('Method::fetchMediaMicroLinkHelper')

      const { link, type } = details

      if (isEmpty(link) || isEmpty(type)) {
        this.alertMessage(`${type} link is required!`, 'error')
        return false
      }

      if (!this.isValidTypeUrl(link, type)) {
        this.alertMessage('URL seems to be invalid', 'error')
        return false
      }

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryMicroLink.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryMicroLink[i].cancel()
      }

      this.getAxiosRequests.mediaLibraryMicroLink.push(source)

      try {
        const response = await axios.post(
          getMediaFromLinkUrl,
          {
            link: link
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: source.token
          }
        )

        const { status, data } = response.data

        callback(status, data)
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }
    },

    async searchMediaHelper (details, callback = () => {}) {
      console.debug('Method::fetchMediaMicroLinkHelper')

      const { keyword, source, page } = details

      if (isEmpty(keyword) || isEmpty(source)) {
        this.alertMessage('Search keyword is required!', 'error')
        return false
      }

      const payload = {
        filters: {
          search: '',
          page: page,
          limit: 42,
          flickr: {
            license: {
              code: 0,
              name: 'Any License'
            }
          },
          imgur: {
            sort: 'viral',
            section: 'hot'
          },
          pixabay: {
            sort: 'popular',
            type: 'all'
          }
        },
        source: source,
        search: keyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const sourceToken = CancelToken.source()

      // removing all existing axios requests
      for (
        let i = 0;
        i < this.getAxiosRequests.mediaLibraryMicroLink.length;
        i++
      ) {
        this.getAxiosRequests.mediaLibraryMicroLink[i].cancel()
      }

      try {
        const response = await axios.post(getImagesContentURL, payload, {
          headers: { Authorization: 'Bearer ' + this.getJWTToken },
          cancelToken: sourceToken.token
        })

        console.debug(response.data)

        const { status, photos } = response.data

        callback(status, photos)
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }
      this.getAxiosRequests.mediaLibraryMicroLink.push(sourceToken)
    },

    async getMediaLimitsHelper (callback = () => {}) {
      console.debug('Method::getMediaLimitsHelper')

      // Create Cancel Token
      const CancelToken = axios.CancelToken
      const sourceToken = CancelToken.source()

      // removing all existing axios requests
      for (let i = 0; i < this.getAxiosRequests.fetchMediaLinks.length; i++) {
        this.getAxiosRequests.fetchMediaLinks[i].cancel()
      }

      try {
        const response = await axios.post(
          moveMediaLimitsUrl,
          {
            workspace_id: this.getActiveWorkspace._id
          },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
            cancelToken: sourceToken.token
          }
        )

        console.debug(response.data)

        const { used, total } = response.data

        callback(used, total)
      } catch (err) {
        console.error(err)
        this.alertMessage('Something went wrong. Please try again.', 'error')
      }
      this.getAxiosRequests.fetchMediaLinks.push(sourceToken)
    },

    cancelMediaRequestHelper (type) {
      console.debug(
        'Method::cancelMediaRequestHelper',
        this.getAxiosRequests[type],
        type
      )
      for (let i = 0; i < this.getAxiosRequests[type].length; i++) {
        this.getAxiosRequests[type][i].cancel('Operation canceled by the user.')
      }
    },

    /**
     * Creates payload and calls an api that computes and creates CSV file and emails to user.
     * @param payload
     * @returns {Promise<void>}
     */
    async createMediaCSV (payload) {
      this.alertMessage('An email will be sent to you shortly', 'success')

      const response = await axios.post(createMediaCSV, payload, {
        headers: { Authorization: 'Bearer ' + this.getJWTToken }
        // cancelToken: sourceToken.token
      })

      if (response.data.status === true) {
        this.unSelectAll()
        this.alertMessage('We sent you an email with media details.', 'success')
      } else {
        this.alertMessage('Something got wrong.', 'error')
      }
    }
  }
}
