<template>
  <div class="topics-content-container">
    <div class="topics-content-container__box">
      <template v-if="getTopicLoader">
        <div class="pt-5">
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
      </template>
      <template v-else>
        <div class="topics-content-container__box__inner" v-if="getPosts">
          <template v-if="getPosts.length" v-for="(post, index) in getPosts">
            <div
              class="article-grid-view-container"
              :key="'grid_' + post._source.id"
              v-if="getTopicsLayout === 'grid'"
            >
              <topics-grid-view-item
                :post="post"
                :index="index"
              ></topics-grid-view-item>
            </div>
            <div
              class="article-list-view-container"
              v-if="getTopicsLayout === 'list'"
            >
              <topics-list-view-item
                :post="post"
                :index="index"
              ></topics-list-view-item>
            </div>
          </template>
        </div>

        <!--<template v-if="getPosts && getTopicsLayout === 'grid'">
                    <grid-view></grid-view>

                </template>
                <template v-if="getPosts && getTopicsLayout === 'list'">
                    <list-view></list-view>
                </template>-->

        <!-- if the search query does not return any results, in that case we will be showing the below message-->
        <template
          v-if="getTopicPaginationLoader && getPosts && getPosts.length === 0"
        >
          <!-- added conditional checks for showing the relevant message related to the posts -->

          <div class="no_data_found_content">
            <div class="no_data_found_content_inner">
              <div class="img">
                <img
                  v-if="getTopicType === 'favorite'"
                  src="../../../../../assets/img/no_data_images/no_data_folder.svg"
                  alt=""
                />
                <template v-else-if="getTopicType === 'archived'">
                  <img
                    src="../../../../../assets/img/no_data_images/no_archived.svg"
                    alt=""
                  />
                </template>
                <template v-else>
                  <img
                    src="../../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </template>
              </div>
              <!--<h2>No more results found</h2>-->
              <template v-if="getTopicType === 'favorite'">
                <p
                  >Your folder is empty, please favorite some posts first to see
                  in here.</p
                >
              </template>
              <template v-else-if="getTopicType === 'archived'">
                <p>No posts have been archived yet.</p>
              </template>
              <template v-else>
                <h2>No results found</h2>
                <p>No data found for your selected topic.</p>
              </template>
            </div>
          </div>
        </template>
        <template
          v-else-if="
            getTopicPaginationLoader &&
            getPosts &&
            getPosts.length <= 19 &&
            getPostsTotal <= 19
          "
        >
          <!-- FIXME: add a better image for this case -->
          <!--<div class="no_data_found_content">-->
          <!--<div class="no_data_found_content_inner">-->
          <!--<div class="img">-->
          <!--<img src="../../../../app/src/assets/img/no_data_images/no_data_found.svg" alt="">-->
          <!--</div>-->
          <!--<p>No results found for your query</p>-->
          <!--</div>-->
          <!--</div>-->
        </template>

        <!-- lazy load for the content to be displayed here -->
        <infinite-loading v-else @infinite="onInfinite" ref="infiniteLoading">
          <span slot="spinner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
          <span slot="no-more" class="not_found_text">
            <no-results-found :more="true"></no-results-found>
          </span>
          <no-results-found :more="true"></no-results-found>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../NoResultsFound'
import GridView from '../../common/GridView'
import ListView from '../../common/ListView'
import TopicsGridViewItem from '../../common/components/TopicsGridViewItem'
import TopicsListViewItem from '../../common/components/TopicsListViewItem'
import { topicTypes } from '../../../store/mutation-types'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {
    InfiniteLoading,
    NoResultsFound,
    GridView,
    ListView,
    TopicsGridViewItem,
    TopicsListViewItem
  },
  computed: {
    ...mapGetters([
      'getTopicsLayout',
      'getTopicLoader',
      'getTopicPaginationLoader',
      'getPosts',
      'getTopicType',
      'getTopicType',
      'getTopicsScroll',
      'getTopicsPage',
      'getTopicContentType',
      'getFolders'
    ])
  },
  watch: {},
  async mounted () {
    this.SET_TOPIC_MODULE('content')
    if (!this.$route.params.module) {
      this.$router.push({
        name: 'cover_stories',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
    let res
    switch (this.$route.params.module) {
      case 'custom':
        res = this.isCustomTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'custom_topic',
            contentType: 'article',
            module: this.getTopicModule
          })
          this.topicsContent()
          return
        }
        break
      case 'curated':
        // if the curated topic is trending news
        if (this.$route.params.topicId === 'N764025') {
          this.isTrendingNews()
          this.topicsContent()
          return true
        }
        res = this.isCuratedTopicAvailable()
        if (res) {
          this.SET_CHANGE_TOPIC_FILTERS({
            topic: res.topic,
            type: 'curated',
            contentType: 'article',
            module: this.getTopicModule
          })

          this.topicsContent()
          return
        }
        break

      case 'favorite':
        const folders = this.getFolders.filter((value) => {
          if (value._id === this.$route.params.topicId) return true
        })
        if (folders.length > 0) {
          this.SET_CHANGE_TOPIC_FILTERS({ topic: folders[0], type: 'favorite' })
          this.topicsContent()
          return
        }
        break
      case 'archived':
        this.SET_CHANGE_TOPIC_FILTERS({ topic: null, type: 'archived' })
        this.topicsContent()
        return
      case 'pocket':
        this.SET_CHANGE_TOPIC_FILTERS({ topic: null, type: 'pocket' })
        this.topicsContent()
        return
    }
    this.$router.push({ name: 'cover_stories' })
    // this.discoveryMounted()
  },
  methods: {
    ...mapMutations(['SET_TOPICS_PAGE']),
    ...mapActions(['topicsContent', 'curatedTopic']),
    onInfinite ($state) {
      console.log(
        'On article infinite',
        this.getTopicsScroll,
        this.getTopicsPage
      )
      if (this.getTopicsScroll) {
        console.log('Calling...')
        this.SET_TOPICS_PAGE(this.getTopicsPage + 1)
        this.topicsContent($state)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.debug('beforeRouteLeave Web Articles View:')
    console.debug('beforeRouteLeave', to, from)
    console.debug('beforeRouteLeave', to.name)
    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')

    if (to && to.name === 'cover_stories') {
      this.selectCoverStoriesFilters()
    }
    /* if(to && to.name == 'topics_web')
            {
                this.getContentByTopic()
            }
            else{

            } */

    next()
  },
  updated () {
    console.log('updated route...')
  },
  created () {
    EventBus.$on('publish-source', (value) => {
      console.log('publish-source-value', value)
    })
  },
  destroyed () {
    EventBus.$off('publish-source')
  }
}
</script>
