<template>
  <!-- The Modal -->
  <div
    class="full_height modal fade similar_article_modal twitter_influencer_modal right side_slide_right w_1050"
    id="similar_article_modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Similar Stories</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>

        <div class="modal_body flex_column">
          <!-- Show the loader meanwhile we are fetching the posts -->

          <!--<div class=" p-5 d-flex height align-items-center justify-content-center">-->
          <!--<beat-loader></beat-loader>-->
          <!--</div>-->

          <div class="similar_article_list">
            <div
              :key="'list_' + post._source.id"
              class="article_box_list"
              v-for="(post, index) in getSimilarArticles"
            >
              <div class="box_inner" v-if="post._source">
                <div class="d-flex">
                  <div class="image_block">
                    <div class="tags_block" v-if="post._source.categories">
                      <span
                        class="tag capitalize"
                        v-for="category in post._source.categories"
                        >{{ category }}</span
                      >
                    </div>
                    <div class="article_type">
                      <span
                        v-if="
                          post._source.read_time && post._source.read_time == 1
                        "
                      >
                        Less than 1 Min. Read
                      </span>
                      <span
                        v-if="
                          post._source.read_time && post._source.read_time > 1
                        "
                      >
                        {{ post._source.read_time }} Min. Read
                      </span>
                      -
                      <span v-if="post._source.has_abstract"
                        >Abstract Content</span
                      >
                      <span v-else>Full Content</span></div
                    >
                    <div
                      class="img"
                      v-lazy:background-image="backgroundImageURL(post._source)"
                    ></div>
                  </div>
                  <div class="content_block">
                    <div class="d-flex">
                      <div class="left_content">
                        <h2
                          @click.prevent="
                            articlePreview({
                              id: post._source.id,
                              index: index,
                            })
                          "
                          :title="limitTextLength(post._source.title, 0)"
                          v-html="limitTextLength(post._source.title, 100)"
                        ></h2>
                        <div class="detail clear">
                          <p class="text_row">
                            <a href="">{{
                              trimURL(post._source.domain_url)
                            }}</a>

                            <a class="left_link" href="">Insight</a>
                            <a class="left_link" href="">All Articles</a>
                            <a class="left_link" href="">Block Globally</a>
                          </p>
                          <p class="text_row">{{
                            $filters.relative(post._source.post_date)
                          }}</p>
                          <p class="author_detail">
                            <template v-if="post._source.author">
                              By <span v-html="post._source.author"></span>
                              <a
                                v-if="post._source.twitter_handler"
                                target="_blank"
                                :href="
                                  getTwitterLink(post._source.twitter_handler)
                                "
                                >{{ post._source.twitter_handler }}</a
                              >
                              <a
                                v-else-if="
                                  post._source.twitter_accounts && index < 1
                                "
                                v-for="(account, index) in post._source
                                  .twitter_accounts"
                                target="_blank"
                                :href="getTwitterLink(account)"
                                >{{ account }}</a
                              >
                            </template>
                            <template v-else-if="post._source.twitter_handler">
                              By
                              <a
                                target="_blank"
                                :href="
                                  getTwitterLink(post._source.twitter_handler)
                                "
                                >{{ post._source.twitter_handler }}</a
                              >
                            </template>
                            <template
                              v-else-if="
                                post._source.twitter_accounts &&
                                post._source.twitter_accounts.length > 0
                              "
                            >
                              By
                              <a
                                v-if="
                                  post._source.twitter_accounts && index < 1
                                "
                                v-for="(account, index) in post._source
                                  .twitter_accounts"
                                target="_blank"
                                :href="getTwitterLink(account)"
                                >{{ account }}</a
                              >
                            </template>
                          </p>
                          <!--
                                                     Previous method commented.
                                                     <div class="author_detail" v-if="post._source.author">
                                                          <p>By <span v-html="post._source.author"></span> <a v-if="post._source.twitter_accounts" v-for="account in post._source.twitter_accounts" href="">{{account}}</a></p>
                                                     </div>
                                                     -->
                          <div class="tag_section">
                            <template
                              v-for="item in post._source.topics"
                              v-if="
                                post._source.topics && item.probability > 0.2
                              "
                            >
                              <span
                                v-for="finalized_topic in checkIfMultipleTopics(
                                  item.label
                                )"
                                @click.prevent="
                                  changeCuratedTopicLabels(
                                    item,
                                    finalized_topic
                                  )
                                "
                              >
                                {{ finalized_topic }}
                              </span>
                            </template>
                          </div>
                        </div>
                        <div
                          class="similar_content"
                          @click.prevent="
                            retrieveSimilarArticles(
                              post._source.id,
                              post._index
                            )
                          "
                          v-if="post._source.related_ids_count > 1"
                          >{{ post._source.related_ids_count }} similar
                          articles</div
                        >
                      </div>
                      <div class="right_content">
                        <div class="social_stats">
                          <div
                            class="social_stats_inner d-flex justify-content-center flex-column"
                          >
                            <div class="stat_item custom_tooltip">
                              <div class="name">
                                <p>Engagement</p>
                              </div>
                              <div class="value">
                                <h3>{{
                                  intToString(
                                    calculateEngagements(post._source)
                                  )
                                }}</h3>
                              </div>

                              <div class="tool_tip_box top_center">
                                <div class="tool_tip_inner">
                                  <div class="d-flex align-items-center">
                                    <div
                                      class="engagement_stat"
                                      v-tooltip.top-center="'Facebook Share'"
                                    >
                                      <span class="icon_item">
                                        <i class="fb cs-facebook"></i>
                                      </span>
                                      <span
                                        v-if="post._source.facebook_shares"
                                        >{{
                                          post._source.facebook_shares
                                        }}</span
                                      >
                                      <span v-else>0</span>
                                    </div>
                                    <div
                                      class="engagement_stat"
                                      v-tooltip.top-center="'Pinterest Share'"
                                    >
                                      <span class="icon_item">
                                        <i class="pin cs-pinterest"></i>
                                      </span>
                                      <span v-if="post._source.pins">{{
                                        post._source.pins
                                      }}</span>
                                      <span v-else>0</span>
                                    </div>
                                    <div
                                      class="engagement_stat"
                                      v-tooltip.top-center="'Reddit Share'"
                                    >
                                      <span class="icon_item">
                                        <i class="reddit cs-reddit"></i>
                                      </span>
                                      <span v-if="post._source.reddit">{{
                                        post._source.reddit
                                      }}</span>
                                      <span v-else>0</span>
                                    </div>
                                    <!--<div class="engagement_stat">
                                                                             <i class="fire_icon cs cs-icon-fire"></i>
                                                                             <span>454</span>
                                                                         </div>-->
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="stat_item"
                              v-if="
                                post._source.velocity &&
                                post._source.velocity > 5
                              "
                            >
                              <div class="name">
                                <p>Trending Score</p>
                              </div>
                              <div class="value">
                                <h3>{{
                                  intToString(post._source.velocity)
                                }}</h3>
                              </div>
                            </div>
                            <div class="stat_item">
                              <div class="name">
                                <p>Sentiment</p>
                              </div>
                              <div
                                class="value"
                                v-if="
                                  post._source.sentiment &&
                                  post._source.sentiment.length > 0
                                "
                              >
                                <i
                                  class="cs-sad-face sad"
                                  v-if="
                                    post._source.sentiment[0].label ==
                                    'negative'
                                  "
                                >
                                </i>
                                <i
                                  class="cs-happy-face smile"
                                  v-if="
                                    post._source.sentiment[0].label ==
                                      'positive' &&
                                    post._source.sentiment[0].probability >=
                                      0.65
                                  "
                                >
                                </i>
                                <i
                                  class="cs-normal-face normal"
                                  v-else-if="
                                    post._source.sentiment[0].label ==
                                      'positive' &&
                                    post._source.sentiment[0].probability < 0.65
                                  "
                                >
                                </i>
                                <i
                                  class="cs-normal-face normal"
                                  v-if="
                                    post._source.sentiment[0].label == 'neutral'
                                  "
                                >
                                </i>
                              </div>
                              <div class="value" v-else>
                                <i class="cs-normal-face normal"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="btn_block d-flex justify-content-center flex-column"
                        >
                          <div
                            class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                          >
                            <div class="dropdown_header" data-toggle="dropdown">
                              <button
                                class="btn light_gray"
                                v-tooltip.top-center="'Share this post'"
                              >
                                <i class="icon-Share_icon d-block"></i>
                                <span class="circle_count">7</span>
                              </button>
                            </div>
                            <div class="dropdown-menu dropdown-menu-right">
                              <ul class="inner">
                                <li class="list_item_li" data-toggle="modal">
                                  <i class="cs-social-share"></i>
                                  <span class="icon_text">Social Media</span>
                                </li>
                                <li class="list_item_li">
                                  <i class="cs-rss"></i>
                                  <span class="icon_text">Blog Post</span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div
                            class="favorite_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                          >
                            <div
                              class="dropdown_header"
                              v-if="post._source.is_favorite"
                              @click.prevent="
                                removeFavorite(post._source.id, index)
                              "
                            >
                              <button
                                class="btn"
                                v-tooltip.top-center="'Unfavorite'"
                              >
                                <i
                                  class="favorite_icon_orange cs-star d-block"
                                ></i>
                              </button>
                            </div>
                            <div
                              class="dropdown_header"
                              data-toggle="dropdown"
                              v-show="post._source.is_favorite == false"
                            >
                              <button
                                v-if="post._source.is_favorite == false"
                                v-tooltip.top-center="'Add to Favorites'"
                                class="btn light_gray"
                              >
                                <i class="favorite_icon cs-star d-block"></i>
                              </button>
                            </div>
                            <favorite-dropdown
                              :post_id="post._source.id"
                              :postIndex="index"
                            ></favorite-dropdown>
                          </div>

                          <div
                            class="article_post_dropdown inline_block dropdown default_style_dropdown"
                          >
                            <div class="dropdown_header">
                              <button
                                class="btn blue"
                                v-tooltip.top-center="'Twitter Influencers'"
                                @click.prevent="showSharers(post._source.id)"
                              >
                                <i class="groups2_icon far fa-users d-block">
                                </i>
                              </button>
                            </div>
                            <!--<div class="dropdown-menu dropdown-menu-right">
                                                             <ul class="inner">
                                                                 <li class="list_item">
                                                                     <a class="dropdown-item" href="#">Link 1</a>
                                                                 </li>
                                                             </ul>
                                                         </div>-->
                          </div>

                          <div
                            class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                          >
                            <div class="dropdown_header" data-toggle="dropdown">
                              <button
                                class="btn light_gray"
                                v-tooltip.top-center="'More options'"
                              >
                                <i class="cs-dots-h d-block"></i>
                              </button>
                            </div>
                            <div class="dropdown-menu dropdown-menu-right">
                              <ul class="inner">
                                <li class="list_item_li">
                                  <a
                                    target="_blank"
                                    :href="savePocketContent(post._source.url)"
                                  >
                                    <i class="cs-pocket"></i>
                                    <span class="icon_text">Add to Pocket</span>
                                  </a>
                                </li>
                                <li
                                  class="list_item_li"
                                  @click.prevent="blockSource(post._source)"
                                >
                                  <i class="cs-network"></i>
                                  <span class="icon_text">Block Source</span>
                                </li>
                                <template
                                  v-if="
                                    getTopicType == 'archived' &&
                                    $route.name == 'topics'
                                  "
                                >
                                  <li
                                    class="list_item_li"
                                    @click.prevent="
                                      unarchive({
                                        post_id: post._source.id,
                                        index: index,
                                      })
                                    "
                                  >
                                    <i class="cs-archive"></i>
                                    <span class="icon_text"
                                      >Unarchive Post</span
                                    >
                                  </li>
                                </template>
                                <template
                                  v-else-if="getTopicType != 'favorite'"
                                >
                                  <li
                                    class="list_item_li"
                                    @click.prevent="
                                      archive({
                                        post_id: post._source.id,
                                        index: index,
                                      })
                                    "
                                  >
                                    <i class="cs-archive"></i>
                                    <span class="icon_text">Archive Post</span>
                                  </li>
                                </template>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn_block_footer">
                  <div
                    class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                  >
                    <div class="dropdown_header" data-toggle="dropdown">
                      <button
                        class="btn light_gray"
                        v-tooltip.top-center="'Share this post'"
                      >
                        <i class="icon-Share_icon d-block"></i>
                        <span class="circle_count">7</span>
                      </button>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li class="list_item_li" data-toggle="modal">
                          <i class="cs-social-share"></i>
                          <span class="icon_text">Social Media</span>
                        </li>
                        <li class="list_item_li">
                          <i class="cs-rss"></i>
                          <span class="icon_text">Blog Post</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="favorite_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                  >
                    <div
                      class="dropdown_header"
                      v-if="post._source.is_favorite"
                      @click.prevent="removeFavorite(post._source.id, index)"
                    >
                      <button class="btn" v-tooltip.top-center="'Unfavorite'">
                        <i class="favorite_icon_orange cs-star d-block"> </i>
                      </button>
                    </div>
                    <div
                      class="dropdown_header"
                      data-toggle="dropdown"
                      v-show="post._source.is_favorite == false"
                    >
                      <button
                        v-if="post._source.is_favorite == false"
                        class="btn light_gray"
                        v-tooltip.top-center="'Add to Favorites'"
                      >
                        <i class="favorite_icon cs-star d-block"> </i>
                      </button>
                    </div>
                    <favorite-dropdown
                      :post_id="post._source.id"
                      :postIndex="index"
                    ></favorite-dropdown>
                  </div>

                  <div
                    class="article_post_dropdown inline_block dropdown default_style_dropdown"
                  >
                    <div class="dropdown_header">
                      <button
                        class="btn blue"
                        v-tooltip.top-center="'Twitter Influencers'"
                        @click.prevent="showSharers(post._source.id)"
                      >
                        <i class="groups2_icon far fa-users d-block"> </i>
                      </button>
                    </div>
                    <!--<div class="dropdown-menu dropdown-menu-right">
                                             <ul class="inner">
                                                 <li class="list_item">
                                                     <a class="dropdown-item" href="#">Link 1</a>
                                                 </li>
                                             </ul>
                                         </div>-->
                  </div>

                  <div
                    class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                  >
                    <div class="dropdown_header" data-toggle="dropdown">
                      <button
                        v-tooltip.top-center="'More options'"
                        class="btn light_gray"
                      >
                        <i class="cs-dots-h d-block"> </i>
                      </button>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li class="list_item_li">
                          <a
                            target="_blank"
                            :href="savePocketContent(post._source.url)"
                          >
                            <i class="cs-pocket"></i>
                            <span class="icon_text">Add to Pocket</span>
                          </a>
                        </li>
                        <li
                          class="list_item_li"
                          @click.prevent="blockSource(post._source)"
                        >
                          <i class="cs-network"></i>
                          <span class="icon_text">Block Source</span>
                        </li>
                        <template
                          v-if="
                            getTopicType == 'archived' &&
                            $route.name == 'topics'
                          "
                        >
                          <li
                            class="list_item_li"
                            @click.prevent="
                              unarchive({
                                post_id: post._source.id,
                                index: index,
                              })
                            "
                          >
                            <i class="cs-archive"></i>
                            <span class="icon_text">Unarchive Post</span>
                          </li>
                        </template>
                        <template v-else>
                          <li
                            class="list_item_li"
                            @click.prevent="
                              archive({
                                post_id: post._source.id,
                                index: index,
                              })
                            "
                          >
                            <i class="cs-archive"></i>
                            <span class="icon_text">Archive Post</span>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteDropdown from '../folders/FavoriteDropdown'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getSimilarArticles'])
  },
  components: {
    FavoriteDropdown
  },
  methods: {
    ...mapActions(['archive', 'unarchive', 'articlePreview'])
  }
}
</script>
