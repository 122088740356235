import { commonTypes, getDefaultVideoSharingDetails } from '../mutation-types'

const getDefaultCommonSharingDetails = () => {
  return {
    message: '',
    title: '',
    twitter_title: '',
    pinterest_title: '',
    description: '',
    twitter_description: '',
    image: [],
    twitter_image: [],
    video: getDefaultVideoSharingDetails(),
    url: '',
    facebook_url: '',
    twitter_url: '',
    linkedin_url: '',
    pinterest_url: '',
    tumblr_url: '',
    instagram_url: '',
    gmb_url: '',
    youtube_url: '',
    source_url: '',
    cta_link: '',
    website: '',
    twitter_website: '',
    image_suggestions: [],
    mentions: [],
    location: {
      facebook: {
        id: '',
        name: ''
      },
      twitter: {
        id: '',
        name: ''
      },
      instagram: {
        id: '',
        name: ''
      }
    }
  }
}

const defaultCommonSharingDetails = {
  message: '',
  title: '',
  twitter_title: '',
  pinterest_title: '',
  description: '',
  twitter_description: '',
  image: [],
  twitter_image: [],
  video: getDefaultVideoSharingDetails(),
  url: '',
  facebook_url: '',
  twitter_url: '',
  linkedin_url: '',
  pinterest_url: '',
  tumblr_url: '',
  instagram_url: '',
  gmb_url: '',
  youtube_url: '',
  source_url: '',
  cta_link: '',
  website: '',
  twitter_website: '',
  image_suggestions: [],
  mentions: [],
  location: {
    facebook: {
      id: '',
      name: ''
    },
    twitter: {
      id: '',
      name: ''
    },
    instagram: {
      id: '',
      name: ''
    }
  }
}

export default {
  state: {
    socialTextareaHeightAdjust: {
      commonboxRef: null,
      facebookboxRef: null,
      twitterboxRef: null,
      pinterestboxRef: null,
      linkedinboxRef: null,
      tumblrboxRef: null,
      instagramboxRef: null,
      gmbboxRef: null,
      youtubeboxRef: null,
      tiktokboxRef: null
    },
    commonBox: {
      status: true,
      messagesStatus: false,
      globalCategoryStatus: false,
      globalCategoryChannels: {
        facebook: true,
        twitter: true,
        linkedin: true,
        pinterest: true,
        tumblr: true,
        instagram: true,
        gmb: true,
        tiktok: true
      }
    },
    selection: JSON.parse(JSON.stringify(defaultCommonSharingDetails)),
    cancelledLink: '',
    progressBar: {
      image: 0,
      numberOfImages: 0,
      video: 0
    },
    twitterVariations: [],
    activeVariationIndex: 0
  },
  actions: {
    setCommonSharingImage ({ commit }, image) {
      commit('setCommonSharingImage', image)
    },

    setCommonSharingMessage ({ commit }, message) {
      commit('setCommonSharingMessage', message)
    },

    setCommonSharingLink ({ commit }, url) {
      commit('setCommonSharingLink', url)
    },

    setCommonFacebookSharingLink ({ commit }, url) {
      commit('setCommonFacebookSharingLink', url)
    },

    setCommonTwitterSharingLink ({ commit }, url) {
      commit('setCommonTwitterSharingLink', url)
    },

    setCommonLinkedinSharingLink ({ commit }, url) {
      commit('setCommonLinkedinSharingLink', url)
    },

    setCommonPinterestSharingLink ({ commit }, url) {
      commit('setCommonPinterestSharingLink', url)
    },

    setCommonTumblrSharingLink ({ commit }, url) {
      commit('setCommonTumblrSharingLink', url)
    },

    setCommonInstagramSharingLink ({ commit }, url) {
      commit('setCommonInstagramSharingLink', url)
    },
    setCommonYoutubeSharingLink ({ commit }, url) {
      commit('setCommonYoutubeSharingLink', url)
    },

    setCommonSharingSourceLink ({ commit }, url) {
      commit('setCommonSharingSourceLink', url)
    },

    setCommonSharingPreview ({ commit }, preview) {
      commit('setCommonSharingPreview', preview)
    },

    setCommonSharingDetails ({ commit }, data) {
      commit('setCommonSharingDetails', data)
    },

    setDefaultCommonSharing ({ commit }) {
      commit('setDefaultCommonSharing')
    },

    setCommonCancelledLink ({ commit }, link) {
      commit('setCommonCancelledLink', link)
    },

    setCommonImagePercentage ({ commit }, percent) {
      commit('setCommonImagePercentage', percent)
    },

    setCommonSharingNumberOfImages ({ commit }, images) {
      commit('setCommonSharingNumberOfImages', images)
    },

    setCommonboxRef ({ commit }, status) {
      commit('setCommonboxRef', status)
    },
    setFacebookboxRef ({ commit }, status) {
      commit('setFacebookboxRef', status)
    },
    setTwitterboxRef ({ commit }, status) {
      commit('setTwitterboxRef', status)
    },
    setPinterestboxRef ({ commit }, status) {
      commit('setPinterestboxRef', status)
    },
    setLinkedinboxRef ({ commit }, status) {
      commit('setLinkedinboxRef', status)
    },
    setTumblrboxRef ({ commit }, status) {
      commit('setTumblrboxRef', status)
    },
    setInstagramboxRef ({ commit }, status) {
      commit('setInstagramboxRef', status)
    }
  },
  mutations: {
    [commonTypes.SET_GMB_BOX_REF] (state, status) {
      state.socialTextareaHeightAdjust.gmbboxRef = status
    },

    [commonTypes.SET_YOUTUBE_BOX_REF] (state, status) {
      state.socialTextareaHeightAdjust.youtubeboxRef = status
    },

    [commonTypes.SET_TIKTOK_BOX_REF] (state, status) {
      state.socialTextareaHeightAdjust.tiktokboxRef = status
    },

    [commonTypes.SET_GMB_SHARING_LINK] (state, url) {
      state.selection.gmb_url = url
      state.selection.cta_link = url
    },

    [commonTypes.SET_TWITTER_VARIATION] (state, variation) {
      state.twitterVariations = variation
    },

    [commonTypes.SET_ACTIVE_TWITTER_VARIATION_INDEX] (state, index) {
      state.activeVariationIndex = index
    },

    [commonTypes.RESET_TWITTER_VARIATIONS] (state) {
      state.activeVariationIndex = 0
      state.twitterVariations = []
    },

    [commonTypes.SET_COMMON_BOX_STATUS] (state, status) {
      state.commonBox.status = status
    },

    [commonTypes.SET_GLOBAL_CATEGORIES_STATUS_LOADER] (state, status) {
      state.commonBox.globalCategoryStatus = status
    },

    [commonTypes.SET_GLOBAL_CATEGORIES_CHANNELS] (state, channels) {
      state.commonBox.globalCategoryChannels = channels
    },

    [commonTypes.SET_COMMON_BOX_MESSAGE_STATUS] (state, value) {
      state.commonBox.messagesStatus = value
    },

    [commonTypes.SET_COMMON_SHARING_IMAGE_SUGGESTIONS] (state, images) {
      state.selection.image_suggestions = images
    },

    [commonTypes.SET_COMMON_FACEBOOK_LOCATION] (state, location) {
      state.selection.location.facebook = location
    },

    [commonTypes.SET_COMMON_TWITTER_LOCATION] (state, location) {
      state.selection.location.twitter = location
    },

    [commonTypes.SET_COMMON_INSTAGRAM_LOCATION] (state, location) {
      state.selection.location.instagram = location
    },

    [commonTypes.RESET_COMMON_SHARING_DETAILS] (state) {
      state.selection = getDefaultCommonSharingDetails()
    },

    setCommonboxRef (state, status) {
      state.socialTextareaHeightAdjust.commonboxRef = status
    },

    setFacebookboxRef (state, status) {
      state.socialTextareaHeightAdjust.facebookboxRef = status
    },

    setTwitterboxRef (state, status) {
      state.socialTextareaHeightAdjust.twitterboxRef = status
    },

    setPinterestboxRef (state, status) {
      state.socialTextareaHeightAdjust.pinterestboxRef = status
    },

    setLinkedinboxRef (state, status) {
      state.socialTextareaHeightAdjust.linkedinboxRef = status
    },

    setTumblrboxRef (state, status) {
      state.socialTextareaHeightAdjust.tumblrboxRef = status
    },

    setInstagramboxRef (state, status) {
      state.socialTextareaHeightAdjust.instagramboxRef = status
    },

    setCommonSharingImage (state, image) {
      state.selection.image = image
    },

    setCommonSharingMessage (state, message) {
      state.selection.message = message
    },

    setCommonSharingLink (state, url) {
      state.selection.url = url
    },

    setCommonFacebookSharingLink (state, url) {
      state.selection.facebook_url = url
    },

    setCommonTwitterSharingLink (state, url) {
      state.selection.twitter_url = url
    },

    setCommonLinkedinSharingLink (state, url) {
      state.selection.linkedin_url = url
    },

    setCommonPinterestSharingLink (state, url) {
      state.selection.pinterest_url = url
      state.selection.source_url = url
    },

    setCommonTumblrSharingLink (state, url) {
      state.selection.tumblr_url = url
    },

    setCommonInstagramSharingLink (state, url) {
      state.selection.instagram_url = url
    },

    setCommonYoutubeSharingLink (state, url) {
      state.selection.youtube_url = url
    },

    setCommonSharingSourceLink (state, url) {
      state.selection.source_url = url
    },

    setCommonSharingPreview (state, preview) {
      if (preview) {
        state.selection.title = preview.title
        state.selection.twitter_title = preview.twitter_title
        state.selection.description = preview.description
        state.selection.twitter_description = preview.twitter_description
        state.selection.website = preview.website
        state.selection.twitter_website = preview.twitter_website
        state.selection.image = preview.image
        state.selection.twitter_image = preview.twitter_image
        state.selection.image_suggestions = preview.image_suggestions
      } else {
        state.selection.title = ''
        state.selection.twitter_title = ''
        state.selection.description = ''
        state.selection.twitter_description = ''
        state.selection.url = ''
        state.selection.facebook_url = ''
        state.selection.twitter_url = ''
        state.selection.linkedin_url = ''
        state.selection.pinterest_url = ''
        state.selection.tumblr_url = ''
        state.selection.instagram_url = ''
        state.selection.youtube_url = ''
        state.selection.gmb_url = ''
        state.selection.source_url = ''
        state.selection.cta_link = ''
        state.selection.website = ''
        state.selection.twitter_website = ''
      }
    },

    setCommonSharingDetails (state, data) {
      console.debug('setCommonSharingDetails', data)
      state.cancelledLink = ''
      if (data == null) {
        state.selection = JSON.parse(
          JSON.stringify(defaultCommonSharingDetails)
        )
        return true
      }
      if (typeof data.twitter_title === 'undefined') {
        data.twitter_title = ''
        data.twitter_description = ''
        data.twitter_website = ''
        data.twitter_image = []
      }
      if (typeof data.location === 'undefined') {
        data.location = {
          facebook: {
            id: '',
            name: ''
          },
          twitter: {
            id: '',
            name: ''
          }
        }
      }
      state.selection = JSON.parse(JSON.stringify(data))
    },

    setDefaultCommonSharing (state) {
      state.commonBox = {
        status: true,
        messagesStatus: false,
        globalCategoryStatus: false,
        globalCategoryChannels: {
          facebook: true,
          twitter: true,
          linkedin: true,
          pinterest: true,
          tumblr: true,
          instagram: true,
          gmb: true
        }
      }
      state.selection = JSON.parse(JSON.stringify(defaultCommonSharingDetails))
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        numberOfImages: 0,
        video: 0
      }
    },

    setCommonCancelledLink (state, link) {
      state.cancelledLink = link
    },

    setCommonImagePercentage (state, percent) {
      state.progressBar.image = percent
    },

    setCommonSharingNumberOfImages (state, images) {
      state.progressBar.numberOfImages = images
    },

    SET_COMMON_SHARING_VIDEO (state, video) {
      state.selection.video = video || getDefaultVideoSharingDetails()
    },

    SET_COMMON_SHARING_VIDEO_INITIALIZATION (state) {
      state.selection.title = ''
      state.selection.twitter_title = ''
      state.selection.description = ''
      state.selection.twitter_description = ''
      state.selection.url = ''
      state.selection.facebook_url = ''
      state.selection.twitter_url = ''
      state.selection.linkedin_url = ''
      state.selection.pinterest_url = ''
      state.selection.tumblr_url = ''
      state.selection.instagram_url = ''
      state.selection.youtube_url = ''
      state.selection.gmb_url = ''
      state.selection.source_url = ''
      state.selection.cta_link = ''
      state.selection.website = ''
      state.selection.twitter_website = ''
      state.selection.image = []
      state.selection.twitter_image = []
      state.selection.image_suggestions = []
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    SET_COMMON_SHARING_VIDEO_PERCENTAGE (state, percent) {
      state.progressBar.video = percent
    },

    SET_COMMON_SHARING_VIDEO_THUMBNAIL (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    SET_COMMON_SHARING_VIDEO_SUGGESTIONS (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    }
  },
  getters: {
    getTwitterVariations: (state) => {
      return state.twitterVariations
    },

    getTwitterVariationsIndex: (state) => {
      return state.activeVariationIndex
    },

    getCommonBoxOptions: (state) => {
      return state.commonBox
    },

    getCommonSharingDetails: (state) => {
      return state.selection
    },

    getCommonSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getCommonSharingProgressBar: (state) => {
      return state.progressBar
    },

    getCommonboxRef: (state) => {
      return state.socialTextareaHeightAdjust.commonboxRef
    },

    getFacebookboxRef: (state) => {
      return state.socialTextareaHeightAdjust.facebookboxRef
    },

    getTwitterboxRef: (state) => {
      return state.socialTextareaHeightAdjust.twitterboxRef
    },

    getLinkedinboxRef: (state) => {
      return state.socialTextareaHeightAdjust.linkedinboxRef
    },

    getPinterestboxRef: (state) => {
      return state.socialTextareaHeightAdjust.pinterestboxRef
    },

    getTumblrboxRef: (state) => {
      return state.socialTextareaHeightAdjust.tumblrboxRef
    },

    getInstagramboxRef: (state) => {
      return state.socialTextareaHeightAdjust.instagramboxRef
    },

    getGmbboxRef: (state) => {
      return state.socialTextareaHeightAdjust.gmbboxRef
    },
    getYoutubeboxRef: (state) => {
      return state.socialTextareaHeightAdjust.youtubeboxRef
    }
  }
}
