<template>
  <div v-if="state.replyMessage && !state.replyBox">
    <div class="bg-main-gray h-10 p-2 h-auto">
      <div class="flex flex-row justify-between">
        <div class="flex flex-row justify_center items-center">
          <p class="font-medium mr-2">Response from the owner:</p>
          <p class="text-gray-800 text-sm"
            >{{ commonMethod.timesAgo(state.replyMessageTime) }}
          </p>
        </div>
        <div class="w-40 flex flex-row justify-end">
          <clip-loader
            v-if="state.isLoading"
            class="d-inline-block align-middle mr-1 mt-1"
            color="rgba(152, 158, 181, 0.8)"
            :size="'12px'"
          ></clip-loader>
          <span
            class="mention cursor-pointer"
            style="color: gray"
            @click="editReply"
          >
            <i
              v-b-tooltip.hover.bottom="'Edit'"
              class="fa fa-pencil-square-o"
            ></i>
          </span>
          <span
            style="color: red"
            class="mention cursor-pointer"
            @click="deleteReply"
          >
            <i v-b-tooltip.hover.right="'Delete'" class="far fa-trash-alt"></i>
          </span>
        </div>
      </div>
      <p class="font-normal whitespace-pre-line text-gray-800 mt-2">
        {{ state.replyMessage }}</p
      >
    </div>
    <p v-if="state.error" class="text-red-600 mt-3 text-sm">
      <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
      {{ state.errorMessage }}
    </p>
  </div>

  <div v-else>
    <SavedReplyModal
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>
    <div
      class="flex flex-col align-items-start"
      :class="{ 'pointer-events-none': state.isDisabled }"
    >
      <div class="flex flex-row w-full ml-[2px]">
        <textarea
          id="mainComment"
          v-model="state.typedComment"
          :disabled="state.isDisabled"
          class="block shadow-none overflow-hidden p-2 w-full h-16 bg-gray-100 rounded-lg border-2 border-gray-300 focus:ring-blue-300 focus:border-blue-300"
          placeholder="Write a response..."
          @keydown.enter.exact.prevent="replyToReview"
        >
        </textarea>
        <div v-if="state.editAllow">
          <i
            v-b-tooltip.hover.right="'Cancel'"
            class="fa fa-times fa-lg cursor-pointer relative right-[6px] top-[-7px] font-thin"
            aria-hidden="true"
            @click="handleCancel"
          ></i>
        </div>
      </div>
      <div class="flex flex-row justify-end items-center w-[98%] pt-2">
        <div
          class="comment_tool mr-3 flex flex-row justify_center items-center"
        >
          <clip-loader
            v-if="state.isLoading"
            class="d-inline-block align-middle mr-2"
            color="rgba(152, 158, 181, 0.8)"
            :size="'12px'"
          ></clip-loader>
          <i
            v-b-tooltip.hover.left="'Saved Replies'"
            class="icon-Save attachment_btn cursor-pointer"
            @click="$bvModal.show('savedReplyModal')"
          />
        </div>
        <div
          v-if="state.showButtons || !state.replyMessage"
          class="mr-1 flex flex-row justify-center items-center"
        >
          <i
            v-b-tooltip.hover.right="
              state.typedComment
                ? 'Send Response'
                : 'Please add a response first.'
            "
            class="cursor-pointer text-[#2f8ae0] far fa-paper-plane font-bold"
            :class="{
              'cursor-not-allowed text-blue-300 opacity-50':
                !state.typedComment || state.isDisabled,
            }"
            @click="replyToReview"
          >
          </i>
        </div>
      </div>
      <p v-if="state.error" class="text-red-600 mt-3 text-sm">
        <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
        {{ state.errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import { reactive, computed, watch, onMounted, inject } from 'vue'
import proxy from '@common/lib/http-common'
import { LUMOTIVE_INBOX } from '@src/modules/inbox/store/constants'
import { swalAttributes } from '@common/constants/common-attributes'
import { EventBus } from '@common/lib/event-bus'
import SavedReplyModal from '../../savereplies/SavedReplyModal'

export default {
  components: {
    SavedReplyModal,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const root = inject('root')
    const commonMethod = commonMethods
    const { $store } = root

    /**
     * @description method to return and set the default values of the state
     */
    const getDefaultValuesForGMBReplyBox = () => {
      return {
        typedComment: '',
        isLoading: false,
        replyBox: false,
        isDisabled: false,
        error: false,
        errorMessage: '',
        replyMessage: '',
        replyMessageTime: '',
      }
    }

    const activeInbox = computed(() => props.activeInboxDetail)

    const state = reactive({
      getProfile: $store.getters.getProfile,
      activeInboxData: activeInbox,
      ...getDefaultValuesForGMBReplyBox(),
      showButtons: false,
      editAllow: false,
    })

    const applySavedReply = (applyText) => {
      state.typedComment = applyText
    }

    /**
     * @description method that updates the state by maintaining its reactivity
     */
    const setState = (newState) => {
      console.log('changing state b4::', state)
      Object.assign(state, newState)
      console.log('changing state::', state)
    }

    onMounted(() => {
      // Setting inbox data on mounted
      setState({
        ...getDefaultValuesForGMBReplyBox(),
        replyMessage: state.activeInboxData.review_reply.review_message,
        replyMessageTime: state.activeInboxData.review_reply.updated_time,
      })
      if (!state.replyMessage) {
        state.showButtons = true
      }
      console.log('onMounted::', state)
    })

    watch(
      () => state.activeInboxData.element_details.element_id,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          console.log('watcher')
          setState({
            ...getDefaultValuesForGMBReplyBox(),
            replyMessage: state.activeInboxData.review_reply.review_message,
            replyMessageTime: state.activeInboxData.review_reply.updated_time,
          })
          console.log('state::', state)
        }
      }
    )

    /**
     * @description method that calls the api to send the response for a review
     */
    const replyToReview = async () => {
      setState({
        isLoading: true,
        isDisabled: true,
      })
      console.log('state responding b4:', state)
      const payload = {
        element_id: state.activeInboxData.element_details.element_id,
        workspace_id: state.activeInboxData.workspace_id,
        platform_id: state.activeInboxData.platform_id,
        review_reply: state.typedComment,
      }
      console.log('payloadData:', payload)
      try {
        const response = await proxy.post(
          LUMOTIVE_INBOX + '/gmb/review/reply/add',
          payload
        )
        console.log('response:', response.data.response.review_reply)
        const time = commonMethod.timesAgo(
          response.data.response.review_reply.updated_time
        )
        console.log('response:', time)
        setState({
          isLoading: false,
          replyBox: false,
          isDisabled: false,
          error: false,
          errorMessage: '',
          replyMessage: state.typedComment,
          replyMessageTime: response.data.response.review_reply.updated_time,
          showButtons: false,
          editAllow: false,
        })
        EventBus.$emit('refresh-inbox-detail')
        console.log('state responding after:', state)
      } catch (error) {
        let errorText = ''
        if (error.response.status === 422) {
          for (const value of Object.values(error.response.data)) {
            if (value.length > 0) {
              errorText = value[0]
              break
            }
          }
        } else {
          errorText = error.response?.data?.error
            ? error.response.data.error
            : error.message
        }
        setState({
          ...getDefaultValuesForGMBReplyBox(),
          isLoading: false,
          isDisabled: false,
          error: true,
          replyBox: true,
          errorMessage: errorText,
        })
      }
    }

    /**
     * @description method that calls the api to delete the response for a review
     */
    const deleteReply = async () => {
      setState({
        isLoading: true,
        isDisabled: true,
      })
      const res = await root.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your response?',
        {
          title: 'Delete Response',
          ...swalAttributes(),
        }
      )
      if (res) {
        const payload = {
          element_id: state.activeInboxData.element_details.element_id,
          workspace_id: state.activeInboxData.workspace_id,
          platform_id: state.activeInboxData.platform_id,
        }
        try {
          const response = await proxy.post(
            LUMOTIVE_INBOX + '/gmb/review/reply/delete',
            payload
          )
          console.log('data deleted', response)
          setState({
            ...getDefaultValuesForGMBReplyBox(),
            replyBox: true,
            showButtons: true,
            isDisabled: false,
            editAllow: false,
          })
          EventBus.$emit('refresh-inbox-detail')
        } catch (error) {
          console.log('error::', error)
          let errorText = ''
          if (error.response.status === 422) {
            for (const value of Object.values(error.response.data)) {
              if (value.length > 0) {
                errorText = value[0]
                break
              }
            }
          } else {
            errorText = error.response?.data?.error
              ? error.response.data.error
              : error.message
          }
          setState({
            isLoading: false,
            error: true,
            errorMessage: errorText,
            isDisabled: false,
          })
        }
      } else {
        setState({
          isLoading: false,
          isDisabled: false,
        })
      }
    }

    const editReply = () => {
      console.log('emitting')
      setState({
        replyBox: true,
        typedComment: state.replyMessage,
        showButtons: true,
        editAllow: true,
      })
    }

    const handleCancel = () => {
      if (state.replyMessage) {
        setState({
          typedComment: '',
          replyBox: false,
          editAllow: false,
        })
      } else {
        setState({
          typedComment: '',
          error: false,
          errorMessage: '',
          editAllow: false,
        })
      }
    }

    return {
      commonMethod,
      state,
      applySavedReply,
      replyToReview,
      deleteReply,
      editReply,
      handleCancel,
    }
  },
}
</script>
