<template>
  <div class="analytic_component">
    <template v-if="loader">
      <div class="component_inner">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <div v-else class="analyze_container">
      <!-- Top Posts & Stories start here -->
      <div class="section">
        <div class="d-flex white_box">
          <div class="flex-row">
            <h1>Instagram Top Performance Posts</h1>
            <p
              >Top posts published during the selected time period, based on the
              post lifetime performance.</p
            >
          </div>
          <div class="right ml-auto">
            <div class="dropdown with_background default_style_dropdown">
              <div
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
              >
                <i class="icon_first cs-world-fill"></i>
                <span class="text">Top {{ post_count.posts }}</span>
                <span class="ml-auto">
                  <i class="cs-angle-down dropdown_arrow icon_last"></i>
                </span>
              </div>

              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    @click="changePostCount(15, 'posts')"
                    class="list_item_li"
                  >
                    <!--<i class="fb cs-facebook mr-2"></i>-->
                    <span class="text">Top 15</span>
                  </li>
                  <li
                    @click="changePostCount(10, 'posts')"
                    class="list_item_li"
                  >
                    <!--<i class="pin cs-pinterest mr-2"></i>-->
                    <span class="text">Top 10</span>
                  </li>
                  <li @click="changePostCount(5, 'posts')" class="list_item_li">
                    <!--<i class="tum cs-tumblr mr-2"></i>-->
                    <span class="text">Top 5</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Top Posts start here -->
      <div class="section table_container">
        <template v-if="metrics.top_posts.length > 0">
          <div class="d-flex flex-row table_head">
            <div class="col-2">Accounts</div>

            <div class="col-2">Posts Image</div>

            <div class="col-3">Posts</div>

            <div class="col-2 pl-5 text-center"
              >Engagements
              <i
                class="sorting_icon far"
                :class="
                  sort_types.posts.engagement === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('engagement', 'posts')"
              ></i>
            </div>

            <div class="col-1 text-center"
              >Likes
              <i
                class="sorting_icon far"
                :class="
                  sort_types.posts.like_count === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('like_count', 'posts')"
              ></i>
            </div>

            <div class="col-1 text-center"
              >Comments
              <i
                class="sorting_icon far"
                :class="
                  sort_types.posts.comments_count === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('comments_count', 'posts')"
              ></i>
            </div>

            <div class="col-1 text-center"
              >Saves
              <i
                class="sorting_icon far"
                :class="
                  sort_types.posts.saved === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('saved', 'posts')"
              ></i>
            </div>
          </div>

          <div class="white_box">
            <template
              v-if="index < post_count.posts"
              v-for="(post, index) in metrics.top_posts"
            >
              <div
                class="flex-row d-flex table_body align-items-center"
                :key="post.id"
                @click.prevent="
                  EventBus.$emit('instagram-posts-analytics-preview', post)
                "
              >
                <div class="col-2">
                  <div class="user_name">
                    <img
                      v-if="post.profile_picture_url"
                      :src="post.profile_picture_url"
                    />
                    <img
                      v-else
                      src="../../../../assets/img/profile_default.svg"
                    />
                    <div class="right right-text col">
                      <h3 class="name">{{ post.name }}</h3>
                      <p class="date"
                        ><b>@{{ post.username }}</b> <br />
                        {{ formatDate(post.post_created_at) }}</p
                      >
                    </div>
                  </div>
                </div>

                <div class="col-2">
                  <template v-if="post.media_type === 'VIDEO'">
                    <video class="post_image" :src="post.media_url"> </video>
                    <i class="fa fa-play video_icon"></i>
                  </template>
                  <div
                    v-else
                    class="post_image"
                    :style="{ backgroundImage: 'url(' + post.media_url + ')' }"
                  ></div>
                </div>

                <div class="col-3">
                  <p class="paragraph">{{ limitText(post.caption, 250) }}</p>
                </div>

                <div class="col-2 pl-5 text-center">
                  {{ post.engagement }}
                </div>

                <div class="col-1 text-center">
                  {{ post.like_count }}
                </div>

                <div class="col-1 text-center">
                  {{ post.comments_count }}
                </div>

                <div class="col-1 text-center">
                  {{ post.saved ? post.saved : 0 }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="section table_container">
            <div class="white_box">
              <no-posts></no-posts>
            </div>
          </div>
        </template>
      </div>
      <!-- Top Posts end here -->

      <!-- Top Stories start here -->

      <div class="section">
        <div class="d-flex white_box">
          <div class="flex-row">
            <h1>Instagram Top Performance Stories</h1>
            <p
              >Top stories published during the selected time period, based on
              the story’s lifetime performance.</p
            >
          </div>
          <div class="right ml-auto">
            <div class="dropdown with_background default_style_dropdown">
              <div
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
              >
                <i class="icon_first cs-world-fill"></i>
                <span class="text">Top {{ post_count.stories }}</span>
                <span class="ml-auto">
                  <i class="cs-angle-down dropdown_arrow icon_last"></i>
                </span>
              </div>

              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    @click="changePostCount(15, 'stories')"
                    class="list_item_li"
                  >
                    <!--<i class="fb cs-facebook mr-2"></i>-->
                    <span class="text">Top 15</span>
                  </li>
                  <li
                    @click="changePostCount(10, 'stories')"
                    class="list_item_li"
                  >
                    <!--<i class="pin cs-pinterest mr-2"></i>-->
                    <span class="text">Top 10</span>
                  </li>
                  <li
                    @click="changePostCount(5, 'stories')"
                    class="list_item_li"
                  >
                    <!--<i class="tum cs-tumblr mr-2"></i>-->
                    <span class="text">Top 5</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section table_container">
        <template v-if="metrics.top_stories.length > 0">
          <div class="d-flex flex-row table_head">
            <div class="col-2">Accounts</div>

            <div class="col-2">Stories</div>

            <div class="col-2 text-center"
              >Impressions
              <i
                class="sorting_icon far"
                :class="
                  sort_types.stories.impressions === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('impressions', 'stories')"
              ></i>
            </div>

            <div class="col-2 text-center"
              >Reach
              <i
                class="sorting_icon far"
                :class="
                  sort_types.stories.reach === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('reach', 'stories')"
              ></i>
            </div>

            <div class="col-2 text-center"
              >Replies
              <i
                class="sorting_icon far"
                :class="
                  sort_types.stories.replies === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('replies', 'stories')"
              ></i>
            </div>

            <div class="col-2 text-center"
              >Exits
              <i
                class="sorting_icon far"
                :class="
                  sort_types.stories.exits === 'asc'
                    ? 'fa-sort-amount-up-alt'
                    : 'fa-sort-amount-down-alt'
                "
                @click="changeSort('exits', 'stories')"
              ></i>
            </div>
          </div>

          <div class="white_box">
            <template
              v-if="index < post_count.stories"
              v-for="(story, index) in metrics.top_stories"
            >
              <div
                class="flex-row d-flex table_body align-items-center"
                :key="story.id"
              >
                <div class="col-2">
                  <div class="user_name">
                    <img
                      v-if="story.profile_picture_url"
                      :src="story.profile_picture_url"
                    />
                    <img
                      v-else
                      src="../../../../assets/img/profile_default.svg"
                    />
                    <div class="right right-text col">
                      <h3 class="name">{{ story.name }}</h3>
                      <span>@{{ story.username }}</span
                      ><br />
                      <p class="date">{{
                        formatDate(story.post_created_at)
                      }}</p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-2 col align-items-center"
                  @click.prevent="
                    EventBus.$emit('instagram-stories-analytics-preview', story)
                  "
                >
                  <!--                    <img class="cover_img" :src=""/>-->
                  <template v-if="story.media_type === 'VIDEO'">
                    <video class="post_image" :src="story.media_url"> </video>
                    <i class="fa fa-play video_icon"></i>
                  </template>
                  <div
                    v-else
                    class="post_image"
                    :style="{
                      'background-image': 'url(' + story.media_url + ')',
                    }"
                  ></div>
                </div>

                <div class="col-2 text-center">
                  {{ story.impressions }}
                </div>

                <div class="col-2 text-center">
                  {{ story.reach }}
                </div>

                <div class="col-2 text-center">
                  {{ story.replies }}
                </div>

                <div class="col-2 text-center">
                  {{ story.exits }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="section table_container">
            <div class="white_box">
              <no-posts :name="'stories'"></no-posts>
            </div>
          </div>
        </template>
      </div>
      <!-- Top stories end here -->
    </div>
  </div>
</template>

<script>
import InstagramHeader from './InstagramHeader'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import * as _ from 'underscore'
import NoPosts from './common/NoPosts'
import { EventBus } from "@common/lib/event-bus";

export default {
  name: 'instagram_posts',
  props: {
    data: null
  },
  components: {
    InstagramHeader,
    NoPosts
  },
  data () {
    return {
      ...dataValues().instagram,
    }
  },
  computed: {
    ...mapGetters([
      'getInstagramAccounts',
      'getAnalyticsServiceStatus',
      'getInstagramIds'
    ]),
    EventBus() {
      return EventBus
    }
  },
  async mounted () {
    // if (this.$route.params.accountId) {
    if (this.data != null) {
      this.initialize()
    }
  },
  methods: {
    ...mapActions(['getAnalyticsService']),
    initialize () {
      this.metrics = {}
      this.selected_account = this.data.selected_account
      this.previous_start_date = this.data.previous_start_date
      this.previous_end_date = this.data.previous_end_date
      this.getAnalyticsForInstagram({
        workspace_id: this.getActiveWorkspace._id,
        timezone: this.getActiveWorkspace.timezone,
        date: `${this.data.start_date} - ${this.data.end_date}`,
        accounts: this.selected_account,
        // accounts: ["36971002400"],
        previous_date: `${this.previous_start_date} - ${this.previous_end_date}`
      })
    },
    formatDate (date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).format('llll')
    },
    changePostCount (value, type) {
      this.post_count[type] = value
    },
    changeSort (field, type) {
      if (this.sort_types[type][field] === 'desc') {
        this.metrics['top_' + type] = _.sortBy(
          this.metrics['top_' + type],
          field
        )
        this.sort_types[type][field] = 'asc'
      } else {
        this.metrics['top_' + type] = _.sortBy(
          this.metrics['top_' + type],
          field
        ).reverse()
        this.sort_types[type][field] = 'desc'
      }
    },

    showActiveAccount (account) {
      return account.instagram_id === this.selected_account
    },
    // update the date-range for the instagram
    updateInstagramAnalytics (values) {
      const payload = {
        accounts: this.selected_account
      }
      this.analytics.isChanged = true

      // date range

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.analytics.startDate = startDate.format('YYYY-MM-DD HH:mm:ss')
        this.analytics.endDate = endDate.format('YYYY-MM-DD HH:mm:ss')
        payload.date =
          startDate.format('YYYY-MM-DD HH:mm:ss') +
          ' - ' +
          endDate.format('YYYY-MM-DD HH:mm:ss')
      }

      this.getAnalyticsForInstagram(payload)
    },
    async getAnalyticsForInstagram (payload = {}) {
      this.loader = true
      payload.type = 'instagram'
      payload.section = 'posts'
      payload.timezone = this.getActiveWorkspace.timezone
      const resp = await this.getAnalyticsService(payload)
      this.metrics = Object.assign(this.metrics, resp)
      console.log(this.metrics.top_posts)
      this.loader = false
    },

    changeInstagramAnalyticsAccount () {
      this.reloadDatePicker(this.analytics)
      console.log(this.$refs.instagram_analytics_dp)
      this.$refs.instagram_analytics_dp.open = true
      this.$refs.instagram_analytics_dp.clickAway()
      this.$router.push({
        name: 'instagram_analytics',
        params: { accountId: this.selected_account }
      })
      this.getAnalyticsForInstagram({
        date: `${this.analytics.startDate} - ${this.analytics.endDate}`,
        accounts: this.selected_account
      })
      // this.$refs.instagram_analytics_dp.$emit('update', { startDate: this.startDate, endDate: this.endDate })
    },

    limitText (value, limit) {
      if (value && value.length > limit) {
        // console.log(value.substring(0, 15)
        return value.substring(0, limit) + '...'
      }
      return value
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.initialize()
      }
    }
  }
}
</script>

<style scoped>
.video_icon {
  bottom: 4rem;
  align-content: center;
  font-size: 1.5rem;
  left: 6.5rem;
  color: rgb(244, 247, 250);
  background-color: #45484db3;
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem 0.3rem 0.6rem;
  position: absolute;
}

.video_icon:hover {
  background-color: #6573ffcc;
}
</style>
