<template>
  <!-- this is an old one, we no longer use i think-->
  <div class="articles_list">
    <div class="article_inner">
      <div
        v-if="getTwitterAccounts.items.length === 0"
        class="no_data_found_content"
      >
        <div class="no_data_found_content_inner">
          <div class="img">
            <img
              src="../../../../../../assets/img/no_data_images/no_data_found.svg"
              alt=""
            />
          </div>
          <p
            >To get content from Twitter, you need to connect your Twitter
            account first.</p
          >
          <router-link
            class="mt-3 btn blue_gradient"
            :to="{
              name: 'social',
              hash: '#twitter_tab',
              params: { workspace: getWorkspaces.activeWorkspace.slug },
            }"
          >
            <span class="text">Connect a Twitter account</span>
          </router-link>
        </div>
      </div>

      <template v-else>
        <template v-if="getDiscoveryTwitterLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <twitter></twitter>

          <template
            v-if="getTwitterPaginationLoader && getTweetsContent.length == 0"
          >
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p>No more results found for your query.</p>
              </div>
            </div>
          </template>
          <infinite-loading
            v-else
            @infinite="onInfiniteTwitter"
            ref="infiniteLoading"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>

            <span slot="no-more" class="not_found_text">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <p>No more results found for your query.</p>
                </div>
              </div>
            </span>
            <span slot="no-results">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <p v-if="getTweetsContent.length == 0"
                    >No results found for your query.</p
                  >
                  <p v-else>No more results found for your query.</p>
                </div>
              </div>
            </span>
          </infinite-loading>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

const Twitter = () => import('../../../common/Twitter')
export default {
  components: {
    Twitter,
    InfiniteLoading
  },
  computed: {
    ...mapGetters([
      'getTwitterAccounts',
      'getDiscoveryTwitterLoader',
      'getTwitterPaginationLoader',
      'getTweetsContent',
      'getTwitterContentPage',
      'getTwitterScroll'
    ])
  },
  created () {
    // this.changeSearchContentType('twitter')
  },
  methods: {
    ...mapActions(['setTwitterContentPage']),
    onInfiniteTwitter ($state) {
      console.log(
        'On twitter infinite',
        this.getTwitterScroll,
        this.getTwitterContentPage
      )
      if (this.getTwitterScroll) {
        this.setTwitterContentPage(this.getTwitterContentPage + 1)
        this.getTwitterData($state)
      }
    }
  }
}
</script>
