<template>
  <div class="ds_card_container ds_domain_table">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>Top Domains</h2>
        </div>
        <div class="card_content">
          <div class="ds_analytic_table">
            <div class="t_head d-flex align-items-center">
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active: sortByRow == 'key' && sortByOrder == 'asc',
                  }"
                  @click.prevent="changeSortOrder('key', 'asc')"
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active: sortByRow == 'key' && sortByOrder == 'desc',
                  }"
                  @click.prevent="changeSortOrder('key', 'desc')"
                >
                </i>
                <span class="text">Website</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active: sortByRow == 'doc_count' && sortByOrder == 'asc',
                  }"
                  @click.prevent="changeSortOrder('doc_count', 'asc')"
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active: sortByRow == 'doc_count' && sortByOrder == 'desc',
                  }"
                  @click.prevent="changeSortOrder('doc_count', 'desc')"
                >
                </i>
                <span class="text">Total Articles</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active:
                      sortByRow == 'total_interactions.value' &&
                      sortByOrder == 'asc',
                  }"
                  @click.prevent="
                    changeSortOrder('total_interactions.value', 'asc')
                  "
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active:
                      sortByRow == 'total_interactions.value' &&
                      sortByOrder == 'desc',
                  }"
                  @click.prevent="
                    changeSortOrder('total_interactions.value', 'desc')
                  "
                >
                </i>
                <span class="text">Total Engagement</span>
              </div>
              <div class="col">
                <i
                  class="sorting_icon cs-arrow-up"
                  :class="{
                    active:
                      sortByRow == 'average_interactions.value' &&
                      sortByOrder == 'asc',
                  }"
                  @click.prevent="
                    changeSortOrder('average_interactions.value', 'asc')
                  "
                >
                </i>
                <i
                  class="sorting_icon cs-arrow-down"
                  :class="{
                    active:
                      sortByRow == 'average_interactions.value' &&
                      sortByOrder == 'desc',
                  }"
                  @click.prevent="
                    changeSortOrder('average_interactions.value', 'desc')
                  "
                >
                </i>
                <span class="text">Avg Engagement</span>
              </div>
            </div>

            <div class="t_body">
              <div
                v-for="domain in orderedList()"
                v-if="
                  getInsightsTopDomains &&
                  getInsightsTopDomains.length > 0 &&
                  domain.total_interactions.value >= 0
                "
                class="t_row d-flex align-items-center"
              >
                <div class="col">
                  <div class="profile_picture d-flex align-items-start">
                    <!--<div class="picture_block ">
                                            <img class="rounded-0" src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg" alt="">
                                        </div>-->
                    <div class="text_block">
                      <p class="text">{{ domain.key }}</p>
                    </div>
                  </div>
                </div>
                <div class="col">{{ domain.doc_count }}</div>
                <div class="col">{{
                  $filters.floatToInt(domain.total_interactions.value)
                }}</div>
                <div class="col">{{
                  $filters.floatToInt(domain.average_interactions.value)
                }}</div>
              </div>
              <div
                v-if="
                  getInsightsTopDomains && getInsightsTopDomains.length == 0
                "
                class="t_row d-flex align-items-center"
              >
                <div class="col col-full-width">
                  No data found for the top domains.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'

export default {
  data () {
    return {
      sortByRow: 'doc_count',
      sortByOrder: 'desc'
    }
  },
  computed: {
    ...mapGetters(['getInsightsTopDomains'])
  },
  methods: {
    changeSortOrder (row, order) {
      this.sortByRow = row
      this.sortByOrder = order
    },

    orderedList () {
      return orderBy(
        this.getInsightsTopDomains,
        [this.sortByRow],
        [this.sortByOrder]
      )
    }
  }
}
</script>
