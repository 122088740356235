<template>
  <div class="fileUploadModel__upload_file_wrapper__right_block">
    <div class="flex-row text-right">
      <button
        type="button"
        class="close"
        @click="$bvModal.hide('insertFileModel')"
        data-dismiss="modal"
        v-tooltip.top-center="'Close'"
        >&times;</button
      >
    </div>

    <div class="fileUploadModel__upload_file_wrapper__right_block__right_inner">
      <h3>Upload To Media Library</h3>

      <div class="form-group file-area">
        <input
          type="file"
          name="images"
          id="images"
          required="required"
          multiple="multiple"
          accept="image/*,.avi,.mov,.m4v,.mp4"
          @change="uploadTempFiles($event)"
          ref="myFileInput"
        />

        <div class="file-dummy">
          <div class="loader_overlay" v-if="getFileWidget.progressBar">
            <span class="number_counter" v-if="getFileWidget.filesCount > 1">{{
              getFileWidget.filesCount
            }}</span>
            <div class="loader_bar">
              <span
                class="loader_value"
                :class="{ white: getFileWidget.progressBar > 50 }"
                >{{ getFileWidget.progressBar }}%</span
              >
              <div
                class="loader_value_bar"
                :style="{ width: getFileWidget.progressBar + '%' }"
              ></div>
            </div>
          </div>

          <div class="success" v-else-if="countUploadedFiles > 0">
            <div class="row head_actions">
              <div class="col-4 text-left">
                <a href="#" @click.prevent="resetUploads()"
                  ><i class="fa fa-close"></i>&nbsp; Remove All
                </a>
              </div>
              <div class="col-4"> {{ countUploadedFiles }} file Selected </div>
              <div class="col-4 text-right">
                <!--<i class="icon-add-new-member-cs"></i> Add More-->
              </div>
            </div>

            <div
              class="row d-flex fileUploadModel__upload_file_wrapper__right_block__right_inner__fix_height"
            >
              <div class="col-4 add-more-files">
                <div class="more_default">
                  <i class="icon-upload_now_light"></i>
                  <h2><b>Add More Files</b></h2>
                  <input
                    type="file"
                    name="images"
                    id="images"
                    required="required"
                    multiple="multiple"
                    accept="image/*,.avi,.mov,.m4v,.mp4"
                    @change="uploadMoreFiles($event)"
                    ref="myFileInput"
                    style="
                      opacity: 0;
                      position: absolute;
                      background: rgb(246, 246, 246);
                      padding: 8px;
                      border: 1px solid rgb(229, 229, 229);
                    "
                  />
                </div>
              </div>

              <div
                class="col-4 col-padding"
                v-for="(file, index) in files"
                v-if="!removed_files.includes(file.name)"
              >
                <div class="">
                  <!--pic_block-->
                  <div
                    class="fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_img active"
                  >
                    <img
                      v-if="file.type.includes('image/')"
                      :src="readURL(file, index)"
                      :id="'file_' + index"
                    />

                    <!-- <img v-else src="../../../../../app/src/assets/img/test.jpg"/>-->

                    <i v-else class="fa fa-video"></i>

                    <i
                      class="fa fa-times"
                      v-tooltip.top-center="'Remove'"
                      :id="'rem_' + index"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></i>

                    <div
                      aria-labelledby="'rem_'+index"
                      class="dropdown-menu dropdown-confirmation__menu"
                    >
                      <div class="dropdown-confirmation__menu__header">
                        Confirmation
                      </div>
                      <div class="dropdown-confirmation__menu__actions">
                        Are you sure?
                        <span
                          class="dropdown-confirmation__menu__actions__delete"
                          @click.prevent="removeTempImage(file.name)"
                          >Yes</span
                        >
                        <span
                          class="dropdown-confirmation__menu__actions__cancel"
                          >No</span
                        >
                      </div>
                    </div>

                    <!--<div class="dropdown dropdown-confirmation remove_prompt" aria-labelledby="'rem_'+index">-->
                    <!--<p>Are you sure you want to remove this item?</p>-->
                    <!--<button class="btn" @click.prevent="removeTempImage(file.name)">Yes</button>-->
                    <!--<button class="btn" @click="removePrompt = false">Cancel</button>-->
                    <!--</div>-->
                  </div>
                  <div
                    class="fileUploadModel__upload_file_wrapper__right_block__right_inner__content"
                  >
                    <p v-if="file.name">{{ file.name }}</p>
                    <p class="size" v-if="file.size">{{
                      bytesToSize(file.size)
                    }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="uploadFile" class="default">
            <i class="icon-upload_now_light"></i>
            <h2><b>Drag & drop to upload</b></h2>
            <p>OR</p>
            <button class="btn btn-studio-theme-space">Upload From PC</button>
          </div>
        </div>
      </div>

      <button
        class="btn blue royal_blue"
        v-if="countUploadedFiles > 0"
        @click.prevent="uploadNowWidgetFiles()"
      >
        <span>
          Upload {{ countUploadedFiles }}
          <span v-if="countUploadedFiles > 1">Files</span>
          <span v-else> File</span></span
        >
      </button>

      <!--<div class="recent_upload">-->

      <!--<h2 v-if="getFileWidget.localUploads.items.length">Recent Uploads</h2>-->

      <!--<ul>-->

      <!--<li v-if="getFileWidget.progressBar">-->

      <!--<div class="loader_overlay">-->
      <!--<span class="number_counter" v-if="getFileWidget.filesCount > 1">{{getFileWidget.filesCount}}</span>-->

      <!--<div class="loader_bar">-->
      <!--<span class="loader_value" :class="{white:getFileWidget.progressBar > 50 }">{{getFileWidget.progressBar}}%</span>-->
      <!--<div class="loader_value_bar" :style="{ width: getFileWidget.progressBar + '%' }"></div>-->
      <!--</div>-->
      <!--</div>-->

      <!--</li>-->

      <!--<clip-loader color="#4165ed" :size="'16px'" v-if="getPublishLoaders.fetchWidgetUploadedFiles && getFileWidget.localUploads.items.length === 0"></clip-loader>-->
      <!--<template v-else>-->

      <!--<template v-if="getFileWidget.localUploads.items.length">-->

      <!--<li v-for="file in getFileWidget.localUploads.items" @click="processFileSelection(file)">-->

      <!--<div class="content">-->
      <!--<p v-if="file.name">{{file.name}}</p>-->
      <!--<p class="size" v-if="file.size">{{bytesToSize(file.size)}}</p>-->
      <!--</div>-->

      <!--<div v-if="file.thumbnail" class="image" :class="{'active':getFileWidget.localUploads.selectedItems.includes(file)}" >-->
      <!--<img  :src="file.thumbnail" alt="">-->
      <!--<i class="fa fa-check" v-if="getFileWidget.localUploads.selectedItems.includes(file)"></i>-->
      <!--</div>-->
      <!--<div v-else class="image" :class="{'active':getFileWidget.localUploads.selectedItems.includes(file)}" >-->
      <!--<img  :src="file.link" alt="">-->
      <!--<i class="fa fa-check" v-if="getFileWidget.localUploads.selectedItems.includes(file)"></i>-->
      <!--</div>-->

      <!--</li>-->

      <!--</template>-->

      <!--<template v-if="getFileWidget.localUploads.paginationScroll === false && getFileWidget.localUploads.items.length === 0">-->
      <!--&lt;!&ndash;<p>1</p>&ndash;&gt;-->
      <!--<no-results-found :message="'No files have been uploaded.'"></no-results-found>-->
      <!--</template>-->

      <!--<infinite-loading v-else-if="getFileWidget.localUploads.paginationScroll" @infinite="onInfiniteWidgetUploadedFiles" ref="infiniteLoading"  >-->
      <!--&lt;!&ndash;<p>2</p>&ndash;&gt;-->
      <!--<span slot="spinner"  class="pt-3 d-block" >-->
      <!--<beat-loader :color="'#436aff'"></beat-loader>-->
      <!--</span>-->
      <!--</infinite-loading>-->

      <!--</template>-->

      <!--</ul>-->

      <!--<div class="bottom_btn_section" v-if="getFileWidget.localUploads.selectedItems.length" >-->
      <!--<button class="btn blue loader royal_blue" @click.prevent="insertFile(type)">-->
      <!--<span>Insert </span>-->
      <!--<span>{{getFileWidget.localUploads.selectedItems.length}}</span>-->
      <!--<span> files</span>-->
      <!--</button>-->
      <!--</div>-->

      <!--</div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../../../../discovery/components/topics/NoResultsFound'
import { EventBus } from '@common/lib/event-bus'
import {
  allowedImageMimeTypes,
  allowedVideoMimeTypes
} from '../../../config/api-utils'

export default {
  props: ['type'],
  components: {
    InfiniteLoading,
    NoResultsFound
  },
  data () {
    return {
      size: '28px',
      color: '#0095f3',
      files: [],
      removed_files: [],
      event: null,
      uploadFile: true,
      removePrompt: false
    }
  },
  mounted () {
    EventBus.$on('reset-upload-states', async (res) => {
      this.resetUploads()
      // this.resetFileWidget()
    })
  },
  beforeDestroy () {
    EventBus.$off('reset-upload-states')
  },
  created () {
    // if (this.getFileWidget.localUploads.fetchStatus === false) {
    //   this.fetchWidgetUploadedFiles()
    //   this.setWidgetLocalUploadsFetchStatus(true)
    // }
  },

  computed: {
    ...mapGetters(['getFileWidget', 'getPublishLoaders']),

    countUploadedFiles () {
      if (this.files.length) { return this.files.length - this.removed_files.length }
      return 0
    }
  },
  methods: {
    ...mapActions([
      'setWidgetLocalUploadsFetchStatus',
      'setWidgetUploadedFilesPage',
      'setWidgetUploadingFilesCount',
      'resetFileWidget'
    ]),

    uploadTempFiles (event) {
      console.debug('Method::uploadTempFiles', event)
      this.files = event.target.files || event.dataTransfer.files
      this.event = event
      this.uploadFile = true
    },
    uploadMoreFiles (event) {
      console.debug('Method::uploadMoreFiles', event)
      const files = event.target.files || event.dataTransfer.files
      this.files = Array.prototype.slice
        .call(files)
        .concat(Array.prototype.slice.call(this.files))
      this.event = event
      this.uploadFile = true
    },
    readURL (input, index) {
      const reader = new FileReader()
      reader.onload = function (e) {
        $('#file_' + index).attr('src', e.target.result)
      }
      return reader.readAsDataURL(input) // convert to base64 string
    },

    removeTempImage (name) {
      this.removed_files.push(name)
      if (this.countUploadedFiles === 0) this.resetUploads()
    },
    resetUploads () {
      this.removed_files = []
      this.files = []
      this.event = null
      this.uploadFile = true
      this.$refs.myFileInput.value = ''
    },
    uploadNowWidgetFiles () {
      console.debug('Method::uploadNowWidgetFiles')
      const formData = new FormData()
      const invalidFiles = []
      for (let i = 0; i < this.files.length; i++) {
        if (!this.removed_files.includes(this.files[i].name)) {
          if (allowedImageMimeTypes.includes(this.files[i].type)) {
            formData.append('files[]', this.files[i])
            continue
          }
          if (allowedVideoMimeTypes.includes(this.files[i].type)) {
            formData.append('files[]', this.files[i])
            continue
          }
          if (!this.files[i].type) {
            if (this.files.length > 1) {
              this.alertMessage(
                'One of your attached file types is not allowed to upload from Media Library.',
                'error'
              )
              return false
            }
            this.alertMessage(
              'File type is not allowed to upload from Media Library.',
              'error'
            )
            return false
          }
          if (invalidFiles.includes(this.files[i].type) === false) { invalidFiles.push(this.files[i].type) }
        }
      }

      if (invalidFiles.length) {
        this.alertMessage(
          'File types (' +
            invalidFiles.toString() +
            ') are not allowed to upload from Media Library.',
          'error'
        )
        return false
      }
      this.setWidgetUploadingFilesCount(this.countUploadedFiles)
      this.processUploadFiles(this.event, formData)
      this.resetUploads()
    }
  }
}
</script>
