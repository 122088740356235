<template>
  <div v-if="platform_type === 'twitter'" class="analytics-post-card">
    <div class="analytics-post-card__header">
      <div class="analytics-post-card__header-left">
        <div class="analytics-post-card__header-img">
          <img
              v-if="post.profile_image_url"
              :src="post.profile_image_url"
              alt=""
              @error="
              $event.target.src = fallbackImagePreview( post.name ? post.name[0] : '#')
              "
          />
        </div>
        <div class="analytics-post-card__header-detail">
          <h3>{{ post.name }}</h3>
          <p class="mb-0"
          ><small
          ><b>@{{ post.screen_name }} </b> •
            {{ this.getTimeFormatted(post.created_at) }}</small
          >
          </p>
        </div>
      </div>
      <div class="analytics-post-card__header-right">
        <a
            class="btn"
            :href="getPreviewLink(platform_type, post)"
            target="_blank"
        >
          <i class="far fa-external-link-square-alt"></i>
        </a>
        <!--        <i class="icon-twitter_icon"></i>-->
      </div>
    </div>

    <div class="analytics-post-card__content">
      <p
          v-if="post.tweet_text"
          v-html="stringToHtmlTwitter(trimString(post.tweet_text, 80))"
      ></p>

      <img
          v-if="post.media_url"
          @error="
          $event.target.src = require('../../assets/imgs/no_data_images/not-found.png')
        "
          :src="post.media_url"
          alt=""
      />
      <img
          v-else
          draggable="false"
          src="../../assets/imgs/no_data_images/not-found.png"
          alt="No Picture Found"
      />
    </div>

    <ul class="analytics-post-card__content-ele">
      <li>
        <span>Total Engagements</span>
        <span
        ><b>{{ post.total_engagement }}</b></span
        >
      </li>
      <li>
        <span>Favorites</span>
        <span
        ><b>{{ post.favorite_count }}</b></span
        >
      </li>
      <li>
        <span>Retweet Count</span>
        <span
        ><b>{{ post.retweet_count }}</b></span
        >
      </li>
    </ul>
  </div>
  <div v-else class="analytics-post-card">
    <div class="analytics-post-card__header">
      <div class="analytics-post-card__header-left">
        <div class="analytics-post-card__header-img">
          <img
              :src="getImage(platform_type, post)"
              alt=""
              @error="
              $event.target.src = fallbackImagePreview( getPostName(platform_type, post)
                  ? getPostName(platform_type, post)[0]
                  : '#')
            "
          />
        </div>
        <div class="analytics-post-card__header-detail">
          <h3>{{ getPostName(platform_type, post) }}</h3>
          <p class="mb-0"
          ><small
          ><b v-tooltip.top-center="getPostUsername(platform_type, post)"
          >{{ trimString(getPostUsername(platform_type, post), 20) }}
          </b>
            • {{ getPostDate(platform_type, post) }}</small
          ></p
          >
        </div>
      </div>
      <div class="analytics-post-card__header-right">
        <!-- Dynamic Icon bases on props -->
        <a
            class="btn"
            :href="getPreviewLink(platform_type, post)"
            target="_blank"
        >
          <i class="far fa-external-link-square-alt"></i>
        </a>
        <!--        <i :class="getIconClass(platform_type)"></i>-->
      </div>
    </div>

    <div class="analytics-post-card__content">
      <p
          v-if="isPostTextAvailable(platform_type, post)"
          v-html="
          trimString(
            isHashtag(
              isLink(nextLineReplacement(getPostText(platform_type, post)))
            ),
            80
          )
        "
      ></p>

      <img
          v-if="isPostImageAvailable(platform_type, post)"
          @error="
          $event.target.src = require('../../assets/imgs/no_data_images/not-found.png')
        "
          :src="getPostImage(platform_type, post)"
          alt=""
      />
      <img
          v-else
          draggable="false"
          src="../../assets/imgs/no_data_images/not-found.png"
          alt="No Picture Found"
      />
    </div>

    <ul class="analytics-post-card__content-ele">
      <li>
        <span>Total Engagements</span>
        <span
        ><b>{{ getPostEngagement(platform_type, post) }}</b></span
        >
      </li>
      <li v-if="isPostLikesAvailable(platform_type)">
        <span>{{ getPostLikesText(platform_type) }}</span>
        <span
        ><b>{{ getPostLikes(platform_type, post) }}</b></span
        >
      </li>
      <li v-if="isPostCommentsAvailable(platform_type)">
        <span>Comments</span>
        <span
        ><b>{{ getPostComments(platform_type, post) }}</b></span
        >
      </li>
      <li v-if="isPostSharesAvailable(platform_type)">
        <span>{{ getPostSharesText(platform_type) }}</span>
        <span
        ><b>{{ getPostShares(platform_type, post) }}</b></span
        >
      </li>
      <li v-if="isPostImpressionsAvailable(platform_type)">
        <span>Post Impressions</span>
        <span
        ><b>{{ getPostImpressions(platform_type, post) }}</b></span
        >
      </li>
      <li v-if="isPostClicksAvailable(platform_type)">
        <span>Post Clicks</span>
        <span
        ><b>{{ getPostClicks(platform_type, post) }}</b></span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

const moment = require('moment')

export default {
  mixins: [analyticsUtilsMixin],
  name: 'PostCard',
  props: ['platform_type', 'post'],
  methods: {
    getTimeFormatted(timeString) {
      return moment(timeString).format('MMMM Do, YYYY hh:mm A')
    },
    trimString(string, maxLength = 100, start = 0) {
      // Note - `start` is if I want to start after some point of the string
      if (string && string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return (
            trimmedString.substr(
                start,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            ) + ' ...'
        )
      }
      return string
    }
  }
}
</script>

<style scoped></style>
