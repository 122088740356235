<template>
  <!-- The Modal -->

  <b-modal
    id="article_preview_modal"
    ref="article_preview_modal"
    modal-class="article_preview_modal right side_slide_right w_1150 full_height article-preview-dialog"
    hide-footer
    hide-header
  >
    <!--<div class="modal_head  ">-->
    <!--&lt;!&ndash;<h2>Video Preview</h2>&ndash;&gt;-->
    <!--<button type="button" class="close" data-dismiss="modal" @click="$bvModal.hide('article_preview_modal')">&times;</button>-->
    <!--</div>-->
    <div class="modal-content article-preview-dialog__content">
      <div class="modal-body article-preview-dialog__content__body">
        <div class="article-preview-dialog__content__body__inner">
          <template v-if="!isShowArticlePreview">
            <div class="article-preview-dialog__content__body__inner__loading">
              <beat-loader />
            </div>
          </template>
          <template v-else>
            <div class="article-preview-dialog__content__body__inner__header">
              <div
                class="article-preview-dialog__content__body__inner__header__icon_actions"
              >
                <template v-if="sectionName !== 'composerSection'">
                  <div
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="showTwitterPreview(getContentPreview._source.id)"
                  >
                    <i class="icon-User"></i>
                  </div>

                  <!-- share buttons -->
                  <b-dropdown
                    id="dropdown-share-articles"
                    variant="studio-icon-theme"
                    class="studio-icon-theme-dropdown mr-2"
                    dropright
                    no-caret
                  >
                    <template slot="button-content">
                      <i :title="'Share this post'" class="icon-Share_icon"></i>
                    </template>
                    <b-dropdown-item
                      variant="studio-icon-theme"
                      @click.prevent="
                        shareSearchFromDialog('social_share_modal')
                      "
                      ><i class="far fa-share share-ico-align"></i> Social Media
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-icon-theme"
                      @click.prevent="shareSearchFromDialog('blog_share_modal')"
                      ><i class="far fa-rss"></i> Blog Post
                    </b-dropdown-item>
                  </b-dropdown>

                  <template>
                    <template v-if="getTopicPreviewHasFavorited">
                      <b-dropdown
                        id="dropdown-favorite-articles"
                        ref="dropdown_favorite"
                        variant="studio-icon-theme"
                        class="studio-icon-theme-dropdown mr-2"
                        no-caret
                        block
                      >
                        <template
                          v-if="getContentPreview._source.is_favorite"
                          slot="button-content"
                        >
                          <i
                            v-tooltip="'Unfavorite'"
                            class="icon-Favorite_icon is-favorite"
                            @click.prevent="
                              unfavoriteArticlePreviewPostItemEventFire(
                                getContentPreview._source.id,
                              )
                            "
                          ></i>
                          <span
                            :class="{
                              ' active': getContentPreview._source.is_active,
                            }"
                            class="icon_animate"
                          ></span>
                        </template>
                        <template v-else slot="button-content">
                          <i
                            v-tooltip="'Favorite'"
                            class="icon-Favorite_icon"
                            :class="{
                              'animated tada':
                                getContentPreview._source.is_active,
                            }"
                          ></i>
                        </template>
                        <BDropdownFavoriteArticle
                          v-show="!getContentPreview._source.is_favorite"
                          :post_id="getContentPreview._source.id"
                          :post-index="getContentPreview.index_id"
                        />
                      </b-dropdown>
                    </template>
                  </template>

                  <div
                    class="btn-studio-theme-transparent-icon mr-2"
                    title="Add to Pocket"
                    @click="pocketPreviewAction"
                  >
                    <i class="icon-pocket"></i>
                  </div>
                  <template v-if="$route.name === 'discover_topics_archived'">
                    <div
                      class="btn-studio-theme-transparent-icon mr-2"
                      title="Unarchive post"
                      @click="
                        unarchivePostItemEventFire(getContentPreview._source.id)
                      "
                    >
                      <i class="icon-Archive_icon"></i>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="btn-studio-theme-transparent-icon mr-2"
                      title="Archive post"
                      @click="
                        archivePostItemEventFire(getContentPreview._source.id)
                      "
                    >
                      <i class="icon-Archive_icon"></i>
                    </div>
                  </template>

                  <div class="btn-separator-line"> </div>
                </template>

                <div
                  class="btn-studio-theme-transparent-icon mr-2"
                  title="Open this link"
                  @click="openExternalPageLink"
                >
                  <i class="icon-link_icon"></i>
                </div>
              </div>
              <div
                v-if="sectionName === 'composerSection'"
                class="article-preview-dialog__content__body__inner__header__add_full_article_to_editor"
              >
                <button
                  class="btn btn-studio-theme-space mr-2"
                  @click="addTextToEditor('full')"
                >
                  <span>Add full article to editor</span>
                </button>
              </div>
              <div
                class="article-preview-dialog__content__body__inner__header__close_action"
              >
                <div
                  class="btn-studio-theme-grey-icon"
                  @click="$bvModal.hide('article_preview_modal')"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>

            <div class="article-preview-dialog__content__body__inner__main">
              <div
                class="article-preview-dialog__content__body__inner__main__about_domain"
              >
                <div
                  class="article-preview-dialog__content__body__inner__main__about_domain__left_section"
                >
                  <p
                    >{{ getContentPreview._source.domain }} -
                    {{ $filters.relative(getContentPreview._source.post_date) }} on
                    {{
                      $filters.dateWithYear(getContentPreview._source.post_date)
                    }}</p
                  >
                </div>
                <div
                  class="article-preview-dialog__content__body__inner__main__about_domain__right_section"
                >
                  <div
                    v-if="getContentPreview._source.topics"
                    class="article-preview-dialog__content__body__inner__main__about_domain__right_section__tags"
                  >
                    <template
                      v-for="topic in getContentPreview._source.topics"
                      v-if="topic.label"
                    >
                      <span
                        v-for="finalized_topic in checkIfMultipleTopics(
                          topic.label,
                        )"
                        v-if="topic && topic.label"
                        class="tag"
                      >
                        {{ finalized_topic }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>
              <div
                class="article-preview-dialog__content__body__inner__main__title"
              >
                <h2 v-html="getContentPreview._source.title" />
              </div>

              <div
                class="article-preview-dialog__content__body__inner__main__engagement"
              >
                <div class="article-preview-engagement-block">
                  <div
                    class="article-preview-engagement-block-social popover-social-statistics"
                  >
                    <SharedCountTooltip
                      :post="{ _source: getContentPreview._source }"
                    />
                    <span class="value-block">{{
                      intToString(getTopicPreviewTotalShares)
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="
                    getContentPreview._source.velocity &&
                    getContentPreview._source.velocity > 5
                  "
                  class="article-preview-engagement-block"
                >
                  <span class="text-block">Trending Score</span>
                  <span class="value-block">{{
                    $filters.numberToCommas(getContentPreview._source.velocity)
                  }}</span>
                </div>
                <div
                  v-if="getTopicPreviewSentiment"
                  class="article-preview-engagement-block"
                >
                  <span class="text-block">Sentiment</span>
                  <span class="value-block"
                    ><i
                      v-tooltip.top-center="getTopicSentimentTooltip"
                      aria-hidden="true"
                      :class="getTopicPreviewSentiment"
                    ></i
                  ></span>
                </div>
              </div>

              <div
                class="article-preview-dialog__content__body__inner__main__image"
              >
                <div
                  class="article-preview-image-block"
                  :style="{
                    'background-image':
                      'url(' +
                      backgroundArticlePreviewImageURL(
                        getContentPreview._source,
                        800,
                      ) +
                      '), url(' +
                      backgroundArticlePreviewImageDefaultURL() +
                      ')',
                  }"
                >
                </div>
              </div>

              <template v-if="sectionName === 'composerSection'">
                <div
                  id="article_wrapper"
                  class="article-preview-dialog__content__body__inner__main__content"
                >
                  <!--                         @click.left="closeMenu"-->
                  <!--                         v-on-clickaway="closeMenu"-->
                  <!--                         @contextmenu.prevent="openMenu($event)"-->
                  <div
                    v-if="getContentPreview._source.description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.description"
                  >
                  </div>
                  <div
                    v-else-if="getContentPreview._source.short_description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.short_description"
                  >
                  </div>
                  <div
                    v-else
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                  >
                    <p>No description available for this article.</p>
                  </div>
                  <!--<div v-if="showDropdown" class="right_click_dropdown"
                         :style="{ left: origin.left + 'px', top: origin.top + 'px' }">
                      <ul>
                        <li @click="addTextToEditor('selected')">Add to editor</li>
                      </ul>
                    </div>-->
                </div>
              </template>
              <template v-else>
                <div
                  class="article-preview-dialog__content__body__inner__main__content"
                >
                  <div
                    v-if="getContentPreview._source.description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.description"
                  >
                  </div>
                  <div
                    v-else
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                  >
                    <p>No description available for this article.</p>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--        <div class="modal_body d-flex height" v-if="isShowArticlePreview">
            <div class="modal_left">
                <div class="brand_preview">

                    <div class="img"
                         :style="{ 'background-image': 'url(' + backgroundImageURL(getContentPreview._source) + ')' }">

                        <div class="tags_block" v-if="getContentPreview._source.categories">
                            <span class="tag" v-for="category in getContentPreview._source.categories">{{category | capitalize}}</span>
                        </div>
                    </div>
                </div>

                <div class="article_detail_left">
                    <div class="article_detail_left_inner">
                        <div class="similar_content"
                             v-if="getContentPreview._source.related_ids_count && getContentPreview._source.related_ids_count > 0">
                            {{getContentPreview._source.related_ids_count}} similar articles
                        </div>
                        <div class="tags_block"
                             v-if="isTopicsDisplayAllowed(getContentPreview) && getContentPreview._source.topics && getContentPreview._source.language && getContentPreview._source.language === 'en'">
                            <template v-for="item in getContentPreview._source.topics">
                                            <span class="tag" v-if="item"
                                                  v-for="finalized_topic in checkIfMultipleTopics(item.label)">
                                                {{finalized_topic}}
                                            </span>
                            </template>
                        </div>

                        <div class="social_stats">
                            <div class="social_stats_inner d-flex ">
                                <div class="stat_item custom_tooltip ">
                                    <div class="name ">
                                        <p>Engagement</p>
                                        <div class="tool_tip_box top_right">
                                            <div class="tool_tip_inner">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="engagement_stat"
                                                         v-tooltip.top-center="'Facebook Share'">
                                                                    <span class="icon_item ">
                                                                        <i class=" fb cs-facebook"></i>
                                                                     </span>
                                                        <span>{{intToString(getContentPreview._source.facebook_shares)}}</span>
                                                    </div>
                                                    <div class="engagement_stat"
                                                         v-tooltip.top-center="'Pinterest Share'">
                                                                    <span class="icon_item">
                                                                       <i class=" pin cs-pinterest"></i>
                                                                     </span>
                                                        <span>{{intToString(getContentPreview._source.pins)}}</span>
                                                    </div>
                                                    <div class="engagement_stat"
                                                         v-tooltip.top-center="'Reddit Share'">
                                                                    <span class="icon_item">
                                                                       <i class=" reddit cs-reddit"></i>
                                                                         </span>
                                                        <span v-if="getContentPreview._source.reddit">{{getContentPreview._source.reddit}}</span>
                                                        <span v-else>0</span>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="value">
                                        <h3>
                                            {{intToString(calculateEngagements(getContentPreview._source))}}</h3>
                                    </div>
                                </div>
                                <div class="stat_item">
                                    <div class="name">
                                        <p>Trending Score</p>
                                    </div>
                                    <div class="value">
                                        <h3>{{getContentPreview._source.velocity}}</h3>
                                    </div>
                                </div>
                                <div class="stat_item">
                                    <div class="name">
                                        <p >Sentiment</p>
                                    </div>
                                    <div class="value"
                                         v-if="getContentPreview._source.sentiment && getContentPreview._source.sentiment.length > 0">
                                        <i v-tooltip="'Negative'" v-if="getContentPreview._source.sentiment[0].label === 'negative'"
                                           class="sad fa fa-frown-o" aria-hidden="true"></i>
                                        <i v-tooltip="'Positive'" v-if="getContentPreview._source.sentiment[0].label === 'positive' && getContentPreview._source.sentiment[0].probability >=0.65"
                                           class="smile fa fa-smile-o" aria-hidden="true"></i>
                                        <i v-tooltip="'Positive'" v-else-if="getContentPreview._source.sentiment[0].label === 'positive' && getContentPreview._source.sentiment[0].probability < 0.65"
                                           class="normal fa fa-meh-o" aria-hidden="true"></i>
                                        <i v-tooltip="'Neutral'" v-if="getContentPreview._source.sentiment[0].label === 'neutral'"
                                           class="normal fa fa-meh-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="value" v-else>
                                        <i v-tooltip="'Normal'" class="normal fa fa-meh-o" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn_block ">
                            <div class="btn_block_inner">
                                <div class="twitter_influencer_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                    <div class="dropdown_header"
                                         @click.prevent="showTwitterPreview(getContentPreview._source.id)">
                                        <button class="btn blue">
                                            <i class="groups2_icon far fa-users align-middle">
                                            </i>
                                            <span class="text">Twitter Influencers</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="d-none article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                    <div class="dropdown_header" data-toggle="dropdown">
                                        <button class="btn light_gray">
                                            <i class="cs-share align-middle"></i>
                                            <span class="text">Share</span>
                                            <span class="circle_count">7</span>

                                        </button>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul class="inner">
                                            <li class="list_item_li " data-toggle="modal"
                                            >
                                                <i class="cs-social-share"></i>
                                                <span class="icon_text">Social Media</span>
                                            </li>
                                            <li class="list_item_li ">
                                                <i class="cs-rss"></i>
                                                <span class="icon_text">Blog Post</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class=" favorite_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                    <div class="dropdown_header" v-if="getContentPreview._source.is_favorite"
                                         @click.prevent="removeFavorite(getContentPreview._source.id, getContentPreview.index_id)">
                                        <button class="btn ">

                                            <i :class="{'animated tada' : getContentPreview._source.is_active}"
                                               class="favorite_icon_orange far fa-heart  ">
                                                           <span style="    top: -43px;right: -38px;" :class="{' active' : getContentPreview._source.is_active}"
                                                                 class="icon_animate"></span>
                                            </i>

                                            <span class="text">favourite</span>
                                        </button>
                                    </div>
                                    <div class="dropdown_header" data-toggle="dropdown"
                                         v-show="getContentPreview._source.is_favorite == false">
                                        <button v-if="getContentPreview._source.is_favorite == false"
                                                class="btn light_gray">
                                            <i class="favorite_icon far fa-heart align-middle"></i>
                                            <span class="text">favourite</span>
                                        </button>
                                    </div>
                                    <favorite-dropdown :post_id="getContentPreview._source.id"
                                                       :postIndex="getContentPreview.index_id"></favorite-dropdown>

                                    &lt;!&ndash;<div class="dropdown_header" data-toggle="dropdown">&ndash;&gt;
                                    &lt;!&ndash;&lt;!&ndash;<button v-if="post._source.is_favorite" class="btn orange" @click.prevent="removeFavorite(post._source.id, index)">&ndash;&gt;&ndash;&gt;
                                    &lt;!&ndash;&lt;!&ndash;<i class="cs cs-icon-star orange"></i>&ndash;&gt;&ndash;&gt;
                                    &lt;!&ndash;&lt;!&ndash;</button>&ndash;&gt;&ndash;&gt;
                                    &lt;!&ndash;<button class="btn light_gray" >&ndash;&gt;
                                    &lt;!&ndash;<i class="cs cs-icon-star"></i>&ndash;&gt;
                                    &lt;!&ndash;<span class="text">Favorite</span>&ndash;&gt;
                                    &lt;!&ndash;</button>&ndash;&gt;
                                    &lt;!&ndash;</div>&ndash;&gt;
                                    &lt;!&ndash;<favorite-dropdown :post_id="post._source.id" :postIndex="index"></favorite-dropdown>&ndash;&gt;
                                </div>

                                <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                                    <div class="dropdown_header" data-toggle="dropdown">
                                        <button class="btn light_gray">
                                            <i class=" cs-dots-h align-middle">
                                            </i>
                                            <span class="text">More</span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul class="inner">
                                            <li class="list_item_li"
                                                @click.prevent="archive({post_id: getContentPreview._source.id, index: null})">
                                                <i class="cs-archive"></i>
                                                <span class="icon_text">Archive Post</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="modal_right">
                <div class="modal_head">
                    <div class="d-flex align-items-center">
                        <div class="left_content">
                            <span class="title">Article Preview</span>
                        </div>
                        <div class="right_content">

                            <button @click="addTextToEditor('full')" v-if="sectionName ==='composerSection'"
                                    class="btn border_btn gradient_btn">
                                <span>Add Full Article to Editor</span>
                            </button>
                            <button class="btn border_btn red"
                                    @click.prevent="reportPost(getContentPreview._id,getContentPreview._id,getContentPreview._source.domain_url,getContentPreview._source.url)">
                                <span>Report Broken</span>
                            </button>
                            <a v-if="getContentPreview._source" class="btn border_btn"
                               :href="fetchUtmBasedLinks(getContentPreview._source.url,fetchActiveUtm).common"
                               target="_blank">
                                <span>View Original Article</span>
                            </a>
                            <button @click="$bvModal.hide('article_preview_modal')"  class="btn_close btn border_btn royal_blue">
                                <span>&times;</span>
                            </button>

                        </div>
                    </div>
                </div>
                <div class="article_content">
                    <div class="article_wrapper" id="article_wrapper">
                        <h2 class="title" v-html="getContentPreview._source.title"></h2>
                        <div class="author_detail">
                            <p>
                                {{getContentPreview._source && getContentPreview._source.domain_url}} -
                                {{getContentPreview._source.post_date | relative}} on
                                {{getContentPreview._source.post_date | date_with_year}}
                            </p>
                            <p v-if="getContentPreview._source.author">
                                By <span v-html="getContentPreview._source.author"></span> <a
                                    v-if="getContentPreview._source.twitter_accounts"
                                    v-for="account in getContentPreview._source.twitter_accounts"
                                    :href="handleLink(account)" target="_blank">{{account}}</a>
                            </p>
                        </div>
                        <template v-if="sectionName ==='composerSection'">
                            <template v-if="getContentPreview._source.description">
                                <div @click.left="closeMenu" v-on-clickaway="closeMenu"
                                     @contextmenu.prevent="openMenu($event)"
                                     class="article_content_inner"
                                     v-html="getContentPreview._source.description"></div>
                            </template>
                            <template v-else-if="getContentPreview._source.short_description">
                                <div @click.left="closeMenu" v-on-clickaway="closeMenu"
                                     @contextmenu.prevent="openMenu($event)"
                                     class="article_content_inner"
                                     v-html="getContentPreview._source.short_description"></div>
                            </template>
                            <div v-if="showDropdown" class="right_click_dropdown"
                                 :style="{ left: origin.left + 'px', top: origin.top + 'px' }">
                                <ul>
                                    <li @click="addTextToEditor('selected')">Add to editor</li>
                                </ul>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="getContentPreview._source.description">
                                <div class="article_content_inner"
                                     v-html="getContentPreview._source.description"></div>
                            </template>
                            <template v-else-if="getContentPreview._source.short_description">
                                <div class="article_content_inner"
                                     v-html="getContentPreview._source.short_description"></div>
                            </template>

                        </template>

                    </div>

                </div>
            </div>

        </div>-->
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { POSTS_REPORT_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import FavoriteDropdown from '../../folders/FavoriteDropdown'
import { reportPostURL } from '../../../config/api-utils'
import SharedCountTooltip from '../snippets/SharedCountTooltip'
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'

export default {
  components: {
    FavoriteDropdown,
    SharedCountTooltip,
    BDropdownFavoriteArticle
  },
  props: ['sectionName'],
  data () {
    return {
      index: null,
      showDropdown: false,
      text: '',
      origin: {}
    }
  },
  computed: {
    ...mapGetters([
      'getContentPreview',
      'getSearchLoader',
      'getBlogEditorSelector'
    ]),
    isShowArticlePreview () {
      return !!(
        !this.getSearchLoader.content.previewArticle &&
        this.getContentPreview._source
      )
    },
    getTopicPreviewTotalShares () {
      const post = this.getContentPreview._source
      return (
        (post.facebook_shares || 0) +
        (post.pins || 0) +
        (post.twitter_shares || 0) +
        (post.reddit || 0)
      )
    },
    getTopicPreviewSentiment () {
      if (
        this.getContentPreview._source.sentiment &&
        this.getContentPreview._source.sentiment.length > 0
      ) {
        const sentimentLabel = this.getContentPreview._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'smile far fa-smile-o'
          case 'negative':
            return 'sad far fa-meh-o'
          case 'neutral':
            return 'far fa-meh normal'
          default:
            return 'normal far fa-frown-o'
        }
      }
      return 'normal far fa-frown-o'
    },

    getTopicSentimentTooltip () {
      if (
        this.getContentPreview._source.sentiment &&
        this.getContentPreview._source.sentiment.length > 0
      ) {
        const sentimentLabel = this.getContentPreview._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'Positive'
          case 'negative':
            return 'Negative'
          case 'neutral':
            return 'Neutral'
          default:
            return 'Normal'
        }
      }
      return 'Normal'
    },
    getTopicPreviewHasFavorited () {
      return this.getContentPreview._source.hasOwnProperty('is_favorite')
    }
  },
  mounted () {
    console.log('load preview item')
    EventBus.$on('favorite-post-item-preview', () => {
      this.$set(this.getContentPreview._source, 'is_favorite', true)
      this.$set(this.getContentPreview._source, 'is_active', true)
    })
  },
  beforeDestroy () {
    EventBus.$off('favorite-post-item-preview')
  },

  methods: {
    ...mapActions(['archive']),
    pocketPreviewAction () {
      const url = this.savePocketContent(this.getContentPreview._source.url)
      window.open(url, '_blank')
    },
    openExternalPageLink () {
      window.open(
        this.fetchUtmBasedLinks(
          this.getContentPreview._source.url,
          this.fetchActiveUtm
        ).common,
        '_blank'
      )
    },

    archivePostItemEventFire (postId) {
      this.$bvModal.hide('article_preview_modal')
      EventBus.$emit('archive-post-item', { postId: postId })
    },
    unarchivePostItemEventFire (postId) {
      this.$bvModal.hide('article_preview_modal')
      EventBus.$emit('unarchive-post-item', { postId: postId })
    },

    shareSearchFromDialog (type = 'social_share_modal') {
      this.$bvModal.hide('article_preview_modal')
      this.initializeArticlePostCreation(
        this.getContentPreview._source.id,
        type
      )
    },
    unfavoriteArticlePreviewPostItemEventFire (postId) {
      EventBus.$emit('unfavorite-post-item', { postId: postId })
      this.$set(this.getContentPreview._source, 'is_favorite', false)
      this.$set(this.getContentPreview._source, 'is_active', false)
    },

    // old methods.

    reportPost (postId, elasticId, websiteURL, link) {
      console.debug('Method:reportPostMixin')

      const data = {
        elastic_id: elasticId,
        post_id: postId,
        website_url: websiteURL,
        link: link
      }

      this.postRequest(
        reportPostURL,
        data,
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(POSTS_REPORT_ERROR, 'error')
          }
        },
        (response) => {
          console.debug('Exception in reportPostMixin ', response)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
    showTwitterPreview (id) {
      const thiss = this
      // $('#article_preview_modal').modal('hide')
      this.$bvModal.hide('article_preview_modal')
      setTimeout(function () {
        thiss.showSharers(id, true)
      }, 500)
    },
    closeMenu () {
      this.showDropdown = false
    },
    openMenu (e) {
      this.text = ''
      // get selected text
      this.showDropdown = false
      if (window.getSelection) {
        this.text = window.getSelection().toString()
        if (this.text.trim() !== '') {
          this.showDropdown = true
        }
      }
      // set dropdown position
      const parent = $('#article_wrapper')
      this.origin = {
        left: e.pageX - parent.offset().left,
        top: e.pageY - parent.offset().top
      }
    },
    addTextToEditor (type) {
      // this.checkEditorRange()
      // add text by "selected" or "full" content type
      // selected by mouse cursor
      if (type === 'selected') {
        if (this.text.trim() !== '') {
          this.setCKEditorHtml(this.text, true)

          // this.getBlogEditorSelector.insertHtml(this.text)
        }
        // full by click on "add to editor" button
      } else if (type === 'full') {
        if (this.getContentPreview._source.description.length) {
          this.setCKEditorHtml(this.getContentPreview._source.description, true)
          // this.getBlogEditorSelector.insertHtml(this.getContentPreview._source.description)
        } else if (this.getContentPreview._source.short_description.length) {
          // this.getBlogEditorSelector.insertHtml(this.getContentPreview._source.description)
          this.setCKEditorHtml(
            this.getContentPreview._source.short_description,
            true
          )
        }
      }
    }
  }
}
</script>

<style>
.article_preview_modal
  .modal-dialog
  .modal-content
  .modal_left
  .article_detail_left
  .btn_block
  .btn
  .favorite_icon_orange {
  color: #ff9300;
}
</style>
