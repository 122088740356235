var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_field"},[_c('div',{staticClass:"multi_select"},[_c('div',{staticClass:"d-flex"},[_vm._m(0),(
          this.getTopicSelection.must_not_contains_keywords.length > 0 ||
          (this.mustNotContainsKeywordValue !== null &&
            this.mustNotContainsKeywordValue.replace(/^\s+/, '') !== '')
        )?_c('button',{staticClass:"reset_btn",on:{"click":function($event){return _vm.clearAllKeywords()}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Clear")]):_vm._e()]),_c('div',{staticClass:"multi_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mustNotContainsKeywordValue),expression:"mustNotContainsKeywordValue"}],staticClass:"prevent-listener",attrs:{"type":"text","placeholder":"Enter keywords that you think are giving irrelevant results, e.g. job, course…","data-cy":"exclude-keyword"},domProps:{"value":(_vm.mustNotContainsKeywordValue)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addAnyKeyword(
            _vm.getTopicSelection.must_not_contains_keywords,
            _vm.mustNotContainsKeywordValue,
            'must_not_contains_keyword'
          )},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==188)return null;return _vm.addAnyKeyword(
            _vm.getTopicSelection.must_not_contains_keywords,
            _vm.mustNotContainsKeywordValue,
            'must_not_contains_keyword'
          )}],"input":function($event){if($event.target.composing)return;_vm.mustNotContainsKeywordValue=$event.target.value}}}),_c('p',{staticClass:"placeholder"},[_vm._v("Press ‘ , ’ or ‘Enter’ to add")])]),(_vm.getTopicSelection.must_not_contains_keywords)?[_c('div',{staticClass:"multi_tags"},[_c('ul',_vm._l((_vm.getTopicSelection.must_not_contains_keywords),function(must_not_contains_keyword,index){return _c('li',{staticClass:"red"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(must_not_contains_keyword))]),_c('span',{staticClass:"remove",on:{"click":function($event){$event.preventDefault();return _vm.removeKeyword(
                  _vm.getTopicSelection.must_not_contains_keywords,
                  index
                )}}},[_vm._v("×")])])}),0)])]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_vm._v("Each result must "),_c('span',{staticClass:"bold"},[_vm._v("NOT")]),_vm._v(" contain any of these keywords")])
}]

export { render, staticRenderFns }