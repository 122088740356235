<template>
  <div class="article-grid-view-container__inner">
    <!-- @mouseover="mouseOver(post." @mouseleave="mouseLeave(post."-->
    <div class="article-grid-view-container__inner__image_block">
      <div class="article-grid-view-container__inner__image_block__tags">
        <template v-for="(item, i) in post.topics" v-if="item.label">
          <span :key="i">
            <span
              v-for="(finalized_topic, k) in checkIfMultipleTopics(item.label)"
              v-if="item && item.label"
              :key="k"
              class="article-grid-view-container__inner__image_block__tags__item"
            >
              {{ finalized_topic }}
            </span>
          </span>
        </template>
      </div>
      <div
        v-lazy:background-image="backgroundImageURL(post)"
        class="article-grid-view-container__inner__image"
      ></div>
    </div>
    <div class="article-grid-view-container__inner__content_block">
      <div class="article-grid-view-container__inner__content_block__height">
        <h2
          :title="limitTextLength(post.title, 0)"
          @click.prevent="routeToPreview"
          v-html="limitTextLength(post.title, 94)"
        ></h2>
        <template v-if="post.author">
          <p class="article-grid-view-container__inner__content_block__author">
            By <span style="color: #4e92ff" v-html="post.author"></span>

            <a
              v-for="(account, index) in getTwitterAccountsForView(
                post.twitter_accounts,
              )"
              v-if="index < 1"
              :key="index"
              style="cursor: pointer"
              target="_blank"
              :href="getTwitterLink(account)"
            >
              {{ account }}</a
            >
            <a
              v-else-if="post.twitter_handler"
              target="_blank"
              :href="getTwitterLink(post.twitter_handler)"
            >
              {{ post.twitter_handler }}</a
            >

            <clip-loader
              v-if="loader"
              class="d-inline-block ml-1"
              :color="'#436aff'"
              :size="'12px'"
            ></clip-loader>
          </p>
        </template>

        <div
          class="clear article-grid-view-container__inner__content_block__information"
        >
          <div class="d-flex flex-row align-items-center">
            <div
              class="article-grid-view-container__inner__content_block__information__author mr-2"
            >
              <a
                v-if="post.url_netloc"
                v-tooltip="trimURL(post.url_netloc)"
                class="domain_url_detail"
                href="javascript:;"
                >{{ trimURL(post.url_netloc) }}</a
              >
            </div>

            <div
              class="article-grid-view-container__inner__content_block__information__author"
            >
              <p class="full_width_author"
                >{{ $filters.relative(post.post_date) }}
                <span
                  v-if="post.related_ids_count > 1"
                  style="float: right"
                  class="similar_stories"
                  @click.prevent="retrieveSimilarArticles(post.id, post._index)"
                  >{{ post.related_ids_count }} similar articles</span
                ></p
              >
              <!--v-if="post._source.related_ids_count"-->
            </div>
          </div>
          <div
            v-if="getGridViewTopInfluencers"
            class="article-grid-view-top-influencers"
          >
            <div
              v-if="getGridViewTotalInfluencers"
              class="article-grid-view-top-influencers__left"
            >
              <p
                ><i class="icon-profile-cs pr-1"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(getGridViewTotalInfluencers)
                }}</strong>
                influencers</p
              >
            </div>
            <!-- showSharers(post._source.id)-->
            <!--                  @click.prevent="routeToTwitterInfluencer(post.id)"-->
            <div
              v-if="getGridViewTotalInfluencers > 0"
              class="article-grid-view-top-influencers__right ml-auto"
            >
              <template
                v-for="(influencer, index) in getGridViewTopInfluencers"
              >
                <!--                      v-if="index <= 3"-->
                <a
                  :key="index"
                  v-tooltip="{
                    content: channelTooltipHTML('influencer', influencer),
                    classes: 'team_tooltip',
                  }"
                  target="_blank"
                  :href="'https://twitter.com/' + influencer.screen_name"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      background: 'url(' + influencer.profile_image + ')',
                    }"
                  ></div>

                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
              <!--                    <div class="rounded_more"-->
              <!--                         v-if="(getGridViewTopInfluencers && getGridViewTopInfluencers.length) && (getGridViewTotalInfluencers > 3  && getGridViewTotalInfluencers - 4 > 0)">-->
              <!--                      +{{getGridViewTotalInfluencers - 4}}-->
              <!--                    </div>-->
            </div>
          </div>
        </div>

        <!--              <div class="article-grid-view-container__inner__content_block__author">-->
        <!--                    <div class="article-grid-view-container__inner__content_block__author">-->
        <!--                        <a v-if="post.url_netloc" v-tooltip="trimURL(post.url_netloc)" href="javascript:;">{{trimURL(post.url_netloc)}}</a>-->
        <!--                        <template v-if="post.author">-->
        <!--                            <p class="author_name block">-->
        <!--                                By <span style="color: #4e92ff;" v-html="post.author"></span>-->

        <!--                                <a v-if="index <1"-->
        <!--                                   v-for="account,index in getTwitterAccountsForView(post.twitter_accounts)"-->
        <!--                                   target="_blank" :href="getTwitterLink(account)"> {{account}}</a>-->
        <!--                                <a v-else-if="post.twitter_handler" target="_blank"-->
        <!--                                   :href="getTwitterLink(post.twitter_handler)">-->
        <!--                                    {{post.twitter_handler}}</a>-->

        <!--                            </p>-->

        <!--                        </template>-->
        <!--                        <template v-else-if="post.twitter_handler">-->
        <!--                            <p class="author_name block">-->
        <!--                                By <a target="_blank" :href="getTwitterLink(post.twitter_handler)">{{post.twitter_handler}}</a>-->
        <!--                            </p>-->
        <!--                        </template>-->
        <!--                        <template-->
        <!--                                v-else-if="post.twitter_accounts && post.twitter_accounts.length > 0">-->
        <!--                            <p class="author_name block">-->
        <!--                                By <a v-if="index <1"-->
        <!--                                      v-for="account,index in getTwitterAccountsForView(post.twitter_accounts)"-->
        <!--                                      target="_blank" :href="getTwitterLink(account)">{{account}}</a>-->

        <!--                            </p>-->
        <!--                        </template>-->

        <!--                    </div>-->
        <!--                    <div class="author_detail">-->
        <!--                        <p class="full_width_author">{{post.post_date | relative}}-->
        <!--                            <span @click.prevent="retrieveSimilarArticles(post.id, post._index)"-->
        <!--                                  style="float:right;"-->
        <!--                                  class="similar_stories" v-if="post.related_ids_count > 1"-->
        <!--                            >{{post.related_ids_count}} similar articles</span></p>-->
        <!--                        &lt;!&ndash;v-if="post.related_ids_count"&ndash;&gt;-->

        <!--                    </div>-->
        <!--                    <div class="more_detail">-->
        <!--                        &lt;!&ndash; <template v-if="post.top_twitter_influencers && post.top_twitter_influencers.length > 0">-->
        <!--                             <div class="similar_content">{{post.top_twitter_influencers.length}} influencers-->
        <!--                                 shared-->
        <!--                             </div>-->
        <!--                         </template>&ndash;&gt;-->

        <!--                        &lt;!&ndash;<div class="article_type">&ndash;&gt;-->
        <!--                        &lt;!&ndash;<span v-if="post.read_time && post.read_time ==1 ">&ndash;&gt;-->
        <!--                        &lt;!&ndash;Less than 1 Min. Read - &ndash;&gt;-->
        <!--                        &lt;!&ndash;</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;<span v-if="post.read_time && post.read_time >1 ">{{post.read_time}} Min. Read -</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;<span v-if="post.has_abstract">Abstract Content</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;<span v-else>Full Content</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;</div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;<div class="similar_content">16 similar articles</div>&ndash;&gt;-->
        <!--                    </div>-->

        <!--                    <div class="top_influencers d-flex align-items-center" v-if="getGridViewTopInfluencers">-->
        <!--                        &lt;!&ndash;  (getGridViewTopInfluencersgetGridViewTopInfluencers.length )&ndash;&gt;-->
        <!--                        <div class="influencer_left" v-if="getGridViewTotalInfluencers">-->
        <!--                            <p>Shared by <strong>{{getGridViewTotalInfluencers |-->
        <!--                                number-to-commas}}</strong> influencers</p>-->
        <!--                        </div>-->
        <!--                        <div @click.prevent="routeToTwitterInfluencer(post.id)"-->
        <!--                             class="influencer_right ml-auto"-->
        <!--                             v-if="getGridViewTotalInfluencers > 0">-->
        <!--                            <template v-for="(influencer, index) in getGridViewTopInfluencers">-->
        <!--                                <a v-tooltip="{ content:channelTooltipHTML('influencer',influencer) , classes: 'team_tooltip' }"-->
        <!--                                   v-if="index <= 3" target="_blank"-->
        <!--                                   :href="'https://twitter.com/' + influencer.screen_name">-->

        <!--                                    <div v-if="influencer.profile_image" class="img"-->
        <!--                                         :style="{'background':'url('+ influencer.profile_image +')'}"></div>-->
        <!--                                    <div v-else class="img"-->
        <!--                                         style="background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');"></div>-->
        <!--                                    &lt;!&ndash;<img v-tooltip="influencer.name + ' (<b>' + influencer.follower_count+  ' followers</b>)'"&ndash;&gt;-->
        <!--                                    &lt;!&ndash;class="influencer_image" :src="influencer.profile_image" alt="">&ndash;&gt;-->
        <!--                                </a>-->
        <!--                            </template>-->
        <!--                            <div class="rounded_more"-->
        <!--                                 v-if="(getGridViewTopInfluencers && getGridViewTopInfluencers.length) && (getGridViewTotalInfluencers > 3  && getGridViewTotalInfluencers - 4 > 0)">-->
        <!--                                +{{getGridViewTotalInfluencers - 4}}-->
        <!--                            </div>-->

        <!--                        </div>-->

        <!--                    </div>-->
        <!--                </div>-->
      </div>

      <div
        class="article-grid-view-container__inner__content_block__social_statistics"
      >
        <div
          class="article-grid-view-container__inner__content_block__social_statistics__inner d-flex"
        >
          <div
            class="popover-social-statistics article-grid-view-container__inner__content_block__social_statistics__inner__item"
          >
            <FeederSharedCountTooltip
              :facebook_shares="post.facebook_shares"
              :twitter_shares="post.twitter_shares"
              :pins_shares="post.pins_shares"
              :reddit_shares="post.reddit_shares"
            ></FeederSharedCountTooltip>
            <div class="value">
              <h3>{{ intToString(signalsCount(post)) }}</h3>
            </div>
          </div>
          <div
            v-if="post.velocity && post.velocity > 5"
            class="article-grid-view-container__inner__content_block__social_statistics__inner__item"
          >
            <div class="name">
              <p>Trending Score</p>
            </div>
            <div class="value">
              <h3>{{ intToString(post.velocity) }}</h3>
            </div>
          </div>
          <FeederSentimentIcon
            :sentiment="post.sentiment"
          ></FeederSentimentIcon>
        </div>
      </div>
      <div class="article-grid-view-container__inner__action_buttons">
        <!-- share buttons -->
        <b-dropdown
          id="dropdown-share-articles"
          variant="studio-icon-theme"
          class="studio-icon-theme-dropdown mr-3"
          no-caret
        >
          <template slot="button-content">
            <i v-tooltip="'Share this post'" class="far fa-share-alt icon"></i>
            <b-badge
              v-if="post.shared_count"
              pill
              variant="light"
              class="ml-2"
              >{{ post.shared_count }}</b-badge
            >
          </template>
          <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="shareToSocial(post.id)"
            ><i class="far fa-share"></i> Social Media
          </b-dropdown-item>
          <b-dropdown-item
            v-show="false"
            variant="studio-icon-theme"
            @click.prevent="routeToBlogPost(post.id)"
            ><i class="far fa-rss"></i> Blog Post
          </b-dropdown-item>
        </b-dropdown>

        <!-- favorite article button -->
        <div
          class="dropdown b-dropdown studio-icon-theme-dropdown mr-3 btn-group"
        >
          <template v-if="post.is_starred">
            <button
              class="btn btn-studio-icon-theme"
              @click.prevent="
                EventBus.$emit('feeder_starred_action', {
                  post_id: post.id,
                  index: index,
                  state: 'unstarred',
                })
              "
            >
              <i
                v-tooltip="'Unfavorite'"
                class="far fa-heart icon is-favorite"
              ></i>
              <span
                :class="{ ' active': post.is_starred }"
                class="icon_animate"
              ></span>
            </button>
          </template>
          <template v-else>
            <button
              class="btn btn-studio-icon-theme"
              @click.prevent="
                EventBus.$emit('feeder_starred_action', {
                  post_id: post.id,
                  index: index,
                  state: 'starred',
                })
              "
            >
              <i v-tooltip="'Favorite'" class="far fa-heart icon"></i>
            </button>
          </template>
        </div>

        <!-- archive article button -->
        <button
          id="dropdown-archive-articles"
          class="btn btn-studio-icon-theme post_archi_btn"
          style=""
        >
          <template v-if="isArchive">
            <i
              v-tooltip.top-center="'Unarchive Post'"
              class="far fa-archive icon is-archived"
              @click="
                EventBus.$emit('feeder_unarchive_action', {
                  post_id: post.id,
                  index: index,
                  domain_uuid: post.domain_uuid,
                  state: 'unarchive',
                })
              "
            ></i>
          </template>
          <template v-else>
            <i
              v-tooltip.top-center="'Archive Post'"
              class="far fa-archive icon"
              @click="
                EventBus.$emit('feeder_archive_action', {
                  post_id: post.id,
                  index: index,
                  domain_uuid: post.domain_uuid,
                  state: 'archive',
                })
              "
            ></i>
          </template>
        </button>

        <!-- more actions button-->
        <b-dropdown
          id="dropdown-actions-articles"
          variant="studio-icon-theme"
          dropleft
          class="studio-icon-theme-dropdown"
          no-caret
        >
          <template slot="button-content">
            <i v-tooltip.top-center="'More options'" class="cs-dots-h icon"></i>
          </template>

          <b-dropdown-item
            variant="studio-icon-theme"
            target="_blank"
            :href="savePocketContent(post.url)"
            ><i class="fab fa-get-pocket"></i> Add to Pocket
          </b-dropdown-item>

          <b-dropdown-item
            v-show="false"
            v-if="getGridViewTotalInfluencers"
            variant="studio-icon-theme"
            @click.prevent="showSharers(post.id)"
            ><i class="fa fa-users-crown"></i> View Influencers
          </b-dropdown-item>
        </b-dropdown>

        <!--                <div class="btn_block_inner">-->
        <!--                    <div class="article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">-->
        <!--                        <div class="dropdown_header" data-toggle="dropdown">-->
        <!--                            <button v-tooltip.top-center="'Share this post'" class="btn light_gray">-->
        <!--                                <i class="far fa-share-alt d-block"-->
        <!--                                   :class="{'shared_posts': post.shared}"></i>-->
        <!--                                <span class="circle_count"-->
        <!--                                      v-if="post.shared_count">{{post.shared_count}}</span>-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-menu dropdown-menu-right">-->
        <!--                            <ul class="inner">-->
        <!--                                <li class="list_item_li  gray_dark gradient_hover"-->
        <!--                                    @click.prevent="routeToShareSocial()">-->
        <!--                                    <i class="far fa-share"></i>-->
        <!--                                    <span class="icon_text">Social Media</span>-->
        <!--                                </li>-->
        <!--                                <li class="list_item_li d-none " @click.prevent="routeToBlogPost(post.id)">-->
        <!--                                    <i class="far fa-rss"></i>-->
        <!--                                    <span class="icon_text">Blog Post</span>-->
        <!--                                </li>-->
        <!--                            </ul>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                  <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">-->
        <!--                        <div class="dropdown_header">-->
        <!--                            <template v-if="post.is_starred">-->

        <!--                                <button v-tooltip.top-center="'Unstarred this post'" class="btn blue "-->
        <!--                                        :class="{'animated tada' : post.is_starred}"-->
        <!--                                        @click.prevent="EventBus.$emit('feeder_starred_action', {post_id: post.id, index: index, state: 'unstarred'})"-->
        <!--                                >-->
        <!--                                    <i class="groups2_icon favorite_icon_orange far fa-star d-block">-->
        <!--                                    </i>-->
        <!--                                    <span :class="{' active' : post.is_starred}"-->
        <!--                                          class="icon_animate"></span>-->
        <!--                                </button>-->
        <!--                            </template>-->
        <!--                            <template v-else>-->
        <!--                                <button v-tooltip.top-center="'Starred this post'" class="btn blue"-->
        <!--                                        @click.prevent="EventBus.$emit('feeder_starred_action', {post_id: post.id, index: index, state: 'starred'})">-->
        <!--                                    <i class="groups2_icon far fa-heart icon d-block">-->
        <!--                                    </i>-->
        <!--                                </button>-->

        <!--                            </template>-->

        <!--                        </div>-->

        <!--                    </div>-->

        <!--                  <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">-->
        <!--                        <div class="dropdown_header">-->
        <!--                            <template v-if="!isArchive">-->
        <!--                                <button v-tooltip.top-center="'Archive Post'" class="btn blue"-->
        <!--                                        @click="EventBus.$emit('feeder_archive_action', {post_id: post.id, index: index, domain_uuid: post.domain_uuid, state: 'archive'})">-->
        <!--                                    <i class="groups2_icon far fa-archive d-block">-->
        <!--                                    </i>-->
        <!--                                </button>-->
        <!--                            </template>-->
        <!--                            <template v-else>-->
        <!--                                <button v-tooltip.top-center="'Unarchive Post'" class="btn blue"-->
        <!--                                        @click="EventBus.$emit('feeder_unarchive_action', {post_id: post.id, index: index, domain_uuid: post.domain_uuid, state: 'unarchive'})">-->
        <!--                                    <i class="groups2_icon far fa-archive d-block">-->
        <!--                                    </i>-->
        <!--                                </button>-->
        <!--                            </template>-->

        <!--                        </div>-->
        <!--                    </div>-->

        <!--                  <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">-->
        <!--                        <div class="dropdown_header" data-toggle="dropdown">-->
        <!--                            <button v-tooltip.top-center="'More options'" class="btn light_gray">-->
        <!--                                <i class=" cs-dots-h d-block">-->
        <!--                                </i>-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div class="dropdown-menu dropdown-menu-right">-->
        <!--                            <ul class="inner">-->
        <!--                                <li class="list_item_li   ">-->
        <!--                                    <a target="_blank" :href="savePocketContent(post.url)">-->
        <!--                                        <i class="fab fa-get-pocket"></i>-->
        <!--                                        <span class="icon_text">Add to Pocket</span>-->
        <!--                                    </a>-->

        <!--                                </li>-->
        <!--                                <template>-->
        <!--                                    <li class="list_item_li d-none"-->
        <!--                                        v-if="getGridViewTotalInfluencers"-->
        <!--                                        @click.prevent="showSharers(post. id)">-->
        <!--                                        <i class="far fa-users-crown"></i>-->
        <!--                                        <span class="icon_text">Influencers</span>-->
        <!--                                    </li>-->
        <!--                                </template>-->
        <!--                            </ul>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {UNKNOWN_ERROR} from "@common/constants/messages";
import {feederItemMixin} from '../../../mixins/feeder-item-mixin'
import FeederSentimentIcon from '../feed-item/FeederSentimentIcon'
import FeederSharedCountTooltip from '../feed-item/FeederSharedCountTooltip'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {
    FeederSharedCountTooltip,
    FeederSentimentIcon
  },
  mixins: [feederItemMixin],
  props: {
    post: {},
    index: {},
    isArchive: { default: false },
    route_name: {}
  },
  computed: {
    EventBus() {
      return EventBus
    },
    getGridViewTopInfluencers () {
      if (this.post.hasOwnProperty('top_influencers')) {
        return this.post.top_influencers
      }
      if (this.post.hasOwnProperty('top_twitter_influencers')) {
        return this.post.top_twitter_influencers
      }
      return []
    },
    getGridViewTotalInfluencers () {
      if (this.post.hasOwnProperty('influencers_shares')) {
        return this.post.influencers_shares
      }
      if (this.post.hasOwnProperty('total_influencers_shares')) {
        return this.post.total_influencers_shares
      }
      return 0
    }
  },
  methods: {
    testPosts () {
      console.log(this.$parent)
    },
    routeToTwitterInfluencer (id) {
      this.showSharers(id)
      const payload = {
        name: 'aggregate_feed_twitter_influencer',
        params: { post_id: id },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },

    routeToPreview() {
      const payload = {
        name: `${this.route_name}_preview`,
        params: {post_id: this.post.id},
        query: {...this.$route.query, module: 'feeds'}
      }
      console.log(payload)
      this.$router.push(payload)
    },
    async routeToShareSocial() {
      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')

      const payload = {
        post_id: this.post.id,
        type_of: 'social'
      }

      const res = await this.$store.dispatch('shareFeederPost', payload)
      if (res.data.data.post) {
        const options = {
          _id: res.data.data.post.id,
          post_id: res.data.data.post.id,
          url: res.data.data.post.url,
          title: res.data.data.post.title,
          image: res.data.data.post.image
        }

        EventBus.$emit('publication-composer-post', {
          mode: 'discovery-post',
          options,
        })

      } else {
        console.debug("share feed post error")
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.$bvModal.hide('composer-modal')
        EventBus.$emit('publication-composer-post-loader', false)
      }
    },
    routeToBlogPost (postId) {
      const payload = {
        name: 'aggregate_feed_item_blog_post',
        params: { post_id: postId },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    }
  }
}
</script>
