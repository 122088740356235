<template>
  <div class="input_field d-flex align-items-start">
    <div
      class="input_field warning_box m-auto"
      style="background: rgba(255, 252, 245, 0.5)"
    >
      <div class="content_category_note">
        <span class="note_text">
          <ul>
            <li>
              You can use Spintax format to create different caption for each
              post. <a href="#" data-beacon-article-modal="593">Read more</a>
            </li>
            <li>
              If Instagram and Pinterest are included in your channels for this
              post, you MUST add at least one image.
            </li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less">
.content_category_note {
  span.note_icon {
    padding-right: 15px;
  }
  span.note_text {
    ul {
      margin-left: 30px;
    }
    li {
      list-style: disc;
    }
  }
}
</style>
