<template>
  <div class="multi_select">
    <div
      class="input_field"
      v-if="getTopicSelection.sources == 'topics_and_keywords'"
    >
      <div class="d-flex align-items-center">
        <label for=""
          ><span class="bold">LIMIT</span> results to these domains only</label
        >
        <button
          class="reset_btn"
          @click="clearAllKeywords()"
          v-if="
            this.getTopicSelection.limit_domains_results.length > 0 ||
            (this.limitDomainsResultsValue !== null &&
              this.limitDomainsResultsValue.replace(/^\s+/, '') !== '')
          "
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <input
        class="prevent-listener"
        @input="asyncLimitDomainResults(limitDomainsResultsValue)"
        v-on:keyup.enter="
          addAnyKeyword(
            getTopicSelection.limit_domains_results,
            limitDomainsResultsValue,
            'limit_domains_results',
            $event
          )
        "
        v-on:keyup.188="
          addAnyKeyword(
            getTopicSelection.limit_domains_results,
            limitDomainsResultsValue,
            'limit_domains_results',
            $event
          )
        "
        v-model="limitDomainsResultsValue"
        type="text"
        placeholder="Enter domains to see results from only these sites… e.g cnn.com, bbc.com"
        data-cy="include-domain"
      />
      <div class="multi_input">
        <div
          v-on-clickaway="closeDropdown"
          class="multi_dropdown"
          v-if="isLimitDomainsLoading && limitDomainsResultsValue.length >= 3"
        >
          <div class="multi_dropdown_inner">
            <ul>
              <!--<transition-group name="slide-bottom">-->
              <!--:key="suggested_domain+index+'content'"-->
              <li
                v-for="(
                  suggested_domain, index
                ) in limit_domains_suggestion_list"
                class="item d-flex align-items-center"
                @click.prevent="addLimitDomain(suggested_domain, index)"
              >
                <span
                  class="icon world_icon_size"
                  v-if="!suggested_domain.is_parent"
                >
                  <i class="cs-rss"></i>
                </span>
                <span class="icon" v-else-if="suggested_domain.favicon_16">
                  <img :src="suggested_domain.favicon_16" alt="" />
                </span>
                <span class="icon world_icon_size" v-else>
                  <i class="cs-world-o"></i>
                </span>
                <span class="text">
                  <!--<span class="heading">{{suggested_domain.name}}</span>-->
                  <span
                    class="url"
                    v-html="
                      highlightText(
                        suggested_domain.url,
                        limitDomainsResultsValue
                      )
                    "
                  ></span>
                </span>
                <span class="view">
                  <i class="fa fa-eye"></i>
                </span>
              </li>
              <!--</transition-group>-->
            </ul>
          </div>
        </div>
        <div
          class="multi_dropdown"
          v-on-clickaway="closeDropdown"
          v-else-if="limitDomainsLoader"
        >
          <div
            class="multi_dropdown_inner min_height d-flex align-items-center justify-content-center"
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
      <template v-if="getTopicSelection.limit_domains_results">
        <div class="multi_tags">
          <ul>
            <li
              class="green"
              v-for="(
                limit_domain_keyword, index
              ) in getTopicSelection.limit_domains_results"
            >
              <span class="value">{{ limit_domain_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(getTopicSelection.limit_domains_results, index)
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isLimitDomainsLoading: false,
      limit_domains_suggestion_list: [],
      limitDomainsLoader: false,
      limitDomainsResultsValue: null
    }
  },
  methods: {
    closeDropdown () {
      this.isLimitDomainsLoading = false
    },
    clearAllKeywords () {
      this.limitDomainsResultsValue = ''
      this.getTopicSelection.limit_domains_results = []
      this.limitDomainsLoader = false
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection'])
  },
  watch: {
    limitDomainsResultsValue (value) {
      if (value && value.length === 0) {
        this.limit_domains_suggestion_list = []
      }
    }
  }
}
</script>
