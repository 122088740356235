<template>
  <div
    class="white_box"
    @click.prevent="EventBus.$emit('facebook-analytics-preview', post)"
  >
    <template>
      <div class="flex-row d-flex table_body align-items-center">
        <div class="col-2">
          <div class="user_name">
            <div class="icon social_image_icon social_image_icon_facebook">
              <i class="cs-facebook"></i>
            </div>
            <img
              v-if="post.page_id"
              :src="
                'https://graph.facebook.com/' +
                post.page_id +
                '/picture?type=square'
              "
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img v-else src="@assets/img/profile_default.svg" alt="" />
            <div class="right right-text col">
              <h3 v-if="post.page_name" class="name">{{ post.page_name }}</h3>
              <h3 v-else class="name">{{ post.name }}</h3>
              <p class="date"> {{ post.created_time | publishedDate }}</p>
            </div>
          </div>
        </div>

        <div class="col-2 py-1">
          <div
            v-if="post.full_picture"
            class="post_image dashboard-post-image object-cover"
            :style="{
              backgroundImage:
                'url(' + getResizedImageURL(post.full_picture, 150, 0) + ')',
            }"
          ></div>

          <div
            v-else
            class="post_image dashboard-post-image"
            :style="{
              backgroundImage:
                'url(' +
                require('../../assets/img/no_data_images/not-found.png') +
                ')',
            }"
          ></div>
        </div>

        <div class="col-4">
          <p
            class="paragraph"
            v-html="
              limitTextLength(
                isHashtag(isLink(nextLineReplacement(post.message))),
                400,
              )
            "
          ></p>
        </div>

        <div class="col-2 pl-5 text-center font-weight-500">{{
          $filters.numberToCommas(post.total_engagement)
        }}</div>
        <div class="col-1 text-center font-weight-500">{{
          $filters.numberToCommas(post.comments)
        }}</div>
        <div class="col-1 text-center font-weight-500">{{
          $filters.numberToCommas(post.shares)
        }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { EventBus } from "@common/lib/event-bus";

export default {
  mixins: [analyticsUtilsMixin],
  props: {
    post: {},
  },
  computed: {
    EventBus() {
      return EventBus
    }
  }
}
</script>
