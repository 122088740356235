var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_body basic_form"},[_c('div',{staticClass:"instta_steps_block"},[_c('div',{staticClass:"insta_steps_inner"},[_c('div',{staticClass:"top_section"},[_c('div',{staticClass:"steps_count"},[_c('div',{staticClass:"count_inner"},[_c('a',{staticClass:"d-flex align-items-center justify-content-center",class:{
                active: _vm.isTabActive('first') === true,
                past: _vm.isTabActionCompleted('first'),
              }},[_c('span',[_vm._v("1")])]),_c('a',{staticClass:"d-flex align-items-center justify-content-center",class:{
                active: _vm.isTabActive('second') === true,
                past: _vm.isTabActionCompleted('second'),
              }},[_c('span',[_vm._v("2")])]),_c('a',{staticClass:"d-flex align-items-center justify-content-center",class:{ active: _vm.isTabActive('third') === true }},[_c('span',[_vm._v("3")])])])])]),_c('div',{staticClass:"step_one step_block",class:{ 'd-none': !_vm.isTabActive('first') }},[_c('div',{staticClass:"text_section"},[_c('h2',[_vm._v("Download Our App")]),_vm._m(0),_c('p',[_vm._v("Your Instagram account "),(
                _vm.getInstagramConnectorAccount &&
                _vm.getInstagramConnectorAccount.username
              )?[_vm._v(" @"),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.getInstagramConnectorAccount.username))])]:_vm._e(),_vm._v(" is now connected. ")],2)]),(
            _vm.getInstagramConnectorAccount &&
            _vm.getInstagramConnectorAccount.username
          )?_c('div',{staticClass:"profile_picture_section"},[_c('div',{staticClass:"profile_picture d-flex align-items-center"},[_c('div',{staticClass:"picture_block picture_block_background"},[_c('div',{staticClass:"img",style:({
                  background:
                    'url(' +
                    _vm.instagramImage(_vm.getInstagramConnectorAccount) +
                    ')',
                })})]),_c('div',{staticClass:"text_block"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.getInstagramConnectorAccount.full_name))]),_c('p',{staticClass:"sub_text"},[_vm._v("Profile")])])])]):_vm._e(),_vm._m(1),_vm._m(2),_c('div',{staticClass:"text-center mt-5"},[_c('button',{staticClass:"mr-3 btn btn-studio-theme-grey-space btn-size-large",attrs:{"disabled":"disabled"}},[_vm._v("Back")]),_c('button',{staticClass:"btn btn-studio-theme-space btn-size-large",on:{"click":function($event){return _vm.changeActiveInstagramConnectionTab(2)}}},[_vm._v(" Next Step ")])])]),_c('div',{staticClass:"steps_two step_block",class:{ 'd-none': !_vm.isTabActive('second') }},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"text-center mt-5"},[_c('button',{staticClass:"mr-3 btn btn-studio-theme-grey-space btn-size-large",on:{"click":function($event){return _vm.changeActiveInstagramConnectionTab(1)}}},[_vm._v("Back ")]),_c('button',{staticClass:"btn btn-studio-theme-space btn-size-large",class:{ active: _vm.getFetchDevicesLoader },attrs:{"disabled":_vm.getFetchDevicesLoader},on:{"click":function($event){return _vm.changeActiveInstagramConnectionTab(3)}}},[_vm._v(" Next Step "),(_vm.getFetchDevicesLoader)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):_vm._e()],1)])]),_c('div',{staticClass:"steps_three step_block",class:{ 'd-none': !_vm.isTabActive('third') }},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"mt-5 text-center"},[_c('button',{staticClass:"mr-3 btn btn-studio-theme-grey-space btn-size-large",on:{"click":function($event){return _vm.changeActiveInstagramConnectionTab(2)}}},[_vm._v("Back ")]),_c('button',{staticClass:"btn btn-studio-theme-space btn-size-large",on:{"click":function($event){return _vm.changeActiveInstagramConnectionTab()}}},[_vm._v(" Done ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To schedule posts on Instagram, you need to install our "),_c('a',{attrs:{"target":"_blank","href":"https://trk.contentstudio.io/cs-ios-app"}},[_vm._v(" iOS ")]),_vm._v(" or "),_c('a',{attrs:{"target":"_blank","href":"http://trk.contentstudio.io/cs-android-app"}},[_vm._v("Android App")]),_vm._v(" on your device. This app will be sending posts to Instagram when the time comes.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step_img"},[_c('img',{attrs:{"src":require("@src/assets/img/settings/insta_steps/step1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app_btn"},[_c('a',{attrs:{"target":"_blank","href":"https://trk.contentstudio.io/cs-ios-app"}},[_c('img',{attrs:{"src":require("@src/assets/img/settings/apple_store.jpg"),"alt":""}})]),_c('a',{staticClass:"ml-2",attrs:{"target":"_blank","href":"http://trk.contentstudio.io/cs-android-app"}},[_c('img',{attrs:{"src":require("@src/assets/img/settings/google_play.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text_section"},[_c('h2',[_vm._v("SignIn to Connect Web and Mobile App")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-2"},[_c('div',{staticClass:"step_img"},[_c('img',{attrs:{"src":require("@src/assets/img/settings/insta_steps/step2.png"),"alt":""}})]),_c('div',{staticClass:"bottom_text flex justify-start flex-col"},[_c('p',{staticClass:"bold text-left"},[_vm._v("Before posting to Instagram, sign in to your instagram app. To do it, follow these quick steps:")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Open the ContentStudio app on your mobile device.")]),_c('li',[_vm._v("Enter your Email & Password.")]),_c('li',[_vm._v("Tap on SignIn button.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text_section"},[_c('h2',[_vm._v("Now you can Schedule posts!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps_img_col"},[_c('div',{staticClass:"d-flex align-items-center img_only"},[_c('div',{staticClass:"step_img"},[_c('img',{staticStyle:{"max-width":"90%"},attrs:{"src":require("@src/assets/img/settings/insta_steps/step3_1.png"),"alt":""}})]),_c('div',{staticClass:"step_img"},[_c('img',{attrs:{"src":require("@src/assets/img/settings/insta_steps/step3_2.png"),"alt":""}})]),_c('div',{staticClass:"step_img"},[_c('img',{attrs:{"src":require("@src/assets/img/settings/insta_steps/step3_3.png"),"alt":""}})])]),_c('div',{staticClass:"d-flex align-items-start text_only"},[_c('div',{staticClass:"step_img"},[_c('p',[_vm._v("Compose and schedule your post from the web-version of ContentStudio.")])]),_c('div',{staticClass:"step_img"},[_c('p',[_vm._v("You’ll receive a notification/reminder on your mobile device at the scheduled time.")])]),_c('div',{staticClass:"step_img"},[_c('p',[_vm._v("Follow the steps on the ContentStudio mobile app to share your post on Instagram.")])])])])
}]

export { render, staticRenderFns }