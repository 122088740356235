<template>
  <div
    v-on-clickaway="closeSearchDropdown"
    class="search_dropdown"
    v-if="
      getArticleSuggestion &&
      getFilters.search.length > 3 &&
      !getSearchLoader.content.discoverySearch &&
      getSearchContentType === 'article'
    "
  >
    <div class="search_dropdown_inner">
      <ul v-if="getSearchSuggestedkeywords.length > 0">
        <li class="head">Related Keywords</li>
        <template
          v-if="index <= 4"
          v-for="(value, index) in getSearchSuggestedkeywords"
        >
          <li @click.prevent="searchByKeyword(value)" class="item">{{
            value
          }}</li>
        </template>
      </ul>
      <ul v-if="getSearchSuggestedTopics.length > 0">
        <li class="head">Related Topics</li>
        <template v-for="(value, index) in getSearchSuggestedTopics">
          <li @click.prevent="searchByCuratedTopic(value)" class="item">
            {{ value.name }}
          </li>
          <!--                                                <li @click.prevent="searchByKeyword(value)" class="item">{{value}}</li>-->
        </template>
      </ul>
      <!--<li class="head">Related Sites</li>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getArticleSuggestion',
      'getFilters',
      'getSearchLoader',
      'getSearchContentType',
      'getSearchSuggestedkeywords',
      'getSearchSuggestedTopics'
    ])
  },
  methods: {
    closeSearchDropdown () {
      this.setArticleSuggestion(false)
    }
  }
}
</script>

<style scoped></style>
