<template>
  <div>
    <video-preview></video-preview>
    <div
      class="assistant_box dragable_box _facebook"
      v-for="(post, index) in getFacebookPosts"
    >
      <div class="box_inner">
        <div class="image_block">
          <!--.......For video icon..............-->
          <div
            class="play_icon facebook_vid_icon"
            v-if="
              post._source.video_preview && postType(post._source) == 'Video'
            "
            @click.prevent="
              previewVideo(
                getFacebookTitle(post._source),
                getFacebookDescription(post._source),
                getFacebookEmbedHtml(post._source),
                'Facebook',
                post._source.link
              )
            "
          >
            <i class="cs-play"></i>
          </div>
          <div
            class="img"
            v-lazy:background-image="backgroundFacebookImageURL(post._source)"
          ></div>
        </div>

        <div class="content_block">
          <div class="users_detail">
            <div class="profile_picture d-flex align-items-start fb">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-facebook"></i>
                </div>
                <img :src="getPageImagePost(post._source)" alt="" />
              </div>
              <div class="text_block">
                <p class="text"
                  ><span class="bold">{{
                    limitTextLength(getPageName(post._source), 13)
                  }}</span></p
                >
                <p class="sub_text">{{
                  $filters.relative(post._source.created_time)
                }}</p>
              </div>
            </div>
          </div>
          <p class="desc" style="min-height: 54px">
            {{ limitTextLength(post._source.message, 65) }}
          </p>
        </div>

        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div class="stat_item" v-tooltip.top-center="'Reaction'">
              <div class="name">
                <p>
                  <i class="cs-normal-face"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(post._source.reactions_count) }}</h3>
              </div>
            </div>
            <div class="stat_item" v-tooltip.top-center="'Comment'">
              <div class="name">
                <p>
                  <i class="cs-comment"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(post._source.comments) }}</h3>
              </div>
            </div>
            <div class="stat_item" v-tooltip.top-center="'Share'">
              <div class="name">
                <p>
                  <i class="cs-share"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(post._source.shares) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div class="hide d-none editor_dragable_box editor_facebook_block">
          <div class="editor_dragable_box_inner clear">
            <div class="top_option">
              <div class="left_block">
                <i
                  class="move_left fa fa-align-left editor_tooltip"
                  title="Align Left"
                ></i>
                <i
                  class="move_center fa fa-align-center editor_tooltip"
                  title="Align Center "
                ></i>
                <i
                  class="move_right fa fa-align-right editor_tooltip"
                  title="Align Right"
                ></i>
              </div>
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div
              class="align_box facebook_inner_embed"
              :data-type="postType(post._source)"
              :data-postid="post._source.post_id"
              :data-link="post._source.link"
              :data-message="post._source.message"
              :data-image="post._source.full_picture"
              style="text-align: center; display: block"
            >
              <div class="inner_embed">
                <div class="custom_loader">
                  <div class="loader_inner"></div>
                </div>

                <div class="fb-post" data-href="" data-width="350"></div>
                <br />
              </div>
            </div>

            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPreview from '../../../discovery/components/common/preview/VideoPreview'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Youtube',
  components: {
    VideoPreview
  },
  computed: {
    ...mapGetters(['getFacebookPosts'])
  },
  created () {},
  methods: {
    ...mapActions([])
  }
}
</script>
