<template>
  <div
    class="white_box"
    @click.prevent="EventBus.$emit('linkedin-analytics-preview', post)"
  >
    <template>
      <div class="flex-row d-flex table_body align-items-center">
        <div class="col-2">
          <div class="user_name">
            <div class="icon social_image_icon social_image_icon_linkedin">
              <i class="cs-linkedin"></i>
            </div>
            <img
              v-if="getImage('linkedin', post)"
              :src="getImage('linkedin', post)"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img
              v-else
              src="@assets/img/profile_default.svg"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <div class="right right-text col">
              <h3 class="name">{{ getPostName('linkedin', post) }}</h3>
              <p class="date"> {{ post.created_at | publishedDate }}</p>
            </div>
          </div>
        </div>

        <div class="col-2 py-2">
          <div
            v-if="post.image"
            class="post_image dashboard-post-image"
            :style="{
              backgroundImage:
                'url(' + getResizedImageURL(post.image, 325, 0) + ')',
            }"
          ></div>

          <div
            v-else
            class="post_image dashboard-post-image"
            :style="{
              backgroundImage:
                'url(' +
                require('../../assets/img/no_data_images/not-found.png') +
                ')',
            }"
          ></div>
        </div>

        <div class="col-4">
          <template>
            <p
              class="paragraph"
              v-html="
                limitTextLength(
                  isHashtag(isLink(nextLineReplacement(post.title))),
                  400,
                )
              "
            ></p>
          </template>
        </div>

        <div
          v-if="post.favorites"
          class="col-2 pl-5 text-center font-weight-500"
          >{{ $filters.numberToCommas(post.favorites) }}
        </div>
        <div v-else class="col-2 pl-5 text-center font-weight-500">0</div>
        <div v-if="post.comments" class="col-1 text-center font-weight-500">
          {{ $filters.numberToCommas(post.comments) }}
        </div>
        <div v-else class="col-1 text-center font-weight-500"> 0</div>
        <div class="col-1 text-center"> -</div>
      </div>
    </template>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { EventBus } from "@common/lib/event-bus";

export default {
  mixins: [analyticsUtilsMixin],
  props: {
    post: {},
  },
  computed: {
    EventBus() {
      return EventBus
    }
  }
}
</script>
