<template>
  <div
    class="topics-insights-container__chart_full_width"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Popular Days </h2>
    <div
      class="topics-insights-container__chart_legends"
      id="popular_days_item_highlight"
    />

    <div
      class="topics-insights-container__chart_full_width__chart charts-legends-capitalize"
    >
      <highcharts
        :options="popularDaysInsights()"
        ref="highcharts_articles_published"
      ></highcharts>

      <p>
        <span class="charts-description">
          <span class="bold">{{
            popular_days.biggest_published_articles_day.day
          }}</span>
          is the biggest day in terms of published articles with a total of
          <span class="bold">{{
            intToString(popular_days.biggest_published_articles_day.value)
          }}</span
          >.
        </span>
      </p>
      <p>
        <span class="charts-description">
          <span class="bold">{{
            popular_days.biggest_interactions_day.day
          }}</span>
          is the most interactive day with a total of
          <span class="bold">{{
            intToString(popular_days.biggest_interactions_day.value)
          }}</span>
          average interactions.
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'

export default {
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  props: {
    popular_days: {}
  },
  computed: {
    isContentNotAvailable () {
      return (
        (this.popular_days.articles && this.popular_days.articles.length) +
        (this.popular_days.interactions &&
          this.popular_days.interactions.length)
      )
    }
  },
  methods: {
    popularDaysInsights () {
      let averageInteractions = []
      let numberOfArticles = []
      let numberOfArticlesSum = 0
      let averageInteractionsSum = 0
      if (this.popular_days.hasOwnProperty('articles')) {
        if (this.popular_days.articles.length > 0) {
          numberOfArticles = this.popular_days.articles
          numberOfArticlesSum = numberOfArticles.reduce((a, b) => a + b, 0)
        }
      }
      if (numberOfArticlesSum === 0) {
        numberOfArticles = [3, 10, 5, 7, 9, 20, 35]
      }

      if (this.popular_days.hasOwnProperty('interactions')) {
        if (this.popular_days.interactions.length > 0) {
          averageInteractions = this.popular_days.interactions
          averageInteractionsSum = averageInteractions.reduce(
            (a, b) => a + b,
            0
          )
        }
      }
      if (averageInteractionsSum === 0) {
        averageInteractions = [6, 20, 10, 14, 18, 40, 70]
      }

      console.log('numberOfArticles Popular Days', numberOfArticles)

      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} on {point.category}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_days_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#5eaabc', '#8cddca'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories:
            this.popular_days.days && this.popular_days.days.length > 0
              ? this.popular_days.days
              : [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
              ],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            pointPadding: 0,
            groupPadding: 0.665,
            pointWidth: 30,
            borderWidth: 0
          }
        },
        series: [
          {
            type: 'column',
            name: 'Articles Per Day',
            data: numberOfArticles,
            yAxis: 1,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#1da1f2'],
                [1, '#9cd9ff']
              ]
            }
          },
          {
            type: 'column',
            name: 'Average Interactions Per Day',
            data: averageInteractions,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#6a98cf'],
                [1, '#9ec2ee']
              ]
            }
          }
        ]
      }
      return chartOptions
    }
  }
}
</script>
