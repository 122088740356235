<template>
  <div
    class="right_filter"
    v-if="getTopicContentType == 'facebook'"
    :class="{ disable_click: getTopicDisabled }"
  >
    <facebook-type></facebook-type>
    <facebook-date></facebook-date>
    <facebook-sort></facebook-sort>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FacebookType from '../../filters/facebook/FacebookType'
import FacebookSort from '../../filters/facebook/FacebookSort'
import FacebookDate from '../../filters/facebook/FacebookDate'
export default {
  components: {
    FacebookType,
    FacebookSort,
    FacebookDate
  },
  computed: {
    ...mapGetters(['getTopicContentType', 'getTopicDisabled'])
  },
  methods: {
    ...mapActions([])
  }
}
</script>
