<template>
  <div class="cst-editor color-border rounded-xl relative">
    <!-- Pinterest Post Title -->
    <div
      v-if="
        accountsData.accountSelection.pinterest.length &&
        (type === 'common' || type === 'pinterest')
      "
      class="px-3 py-2"
    >
      <div>
        <input
          id="pin-title"
          v-model="sharingDetails.pinterest_title"
          class="border-0 bg-white mb-1 font-normal text-sm w-full text-black-200"
          placeholder="Title for Pinterest"
          maxlength="100"
        />
        <!--        <input v-model="sharingDetails.pinterest_title" placeholder="Pinterest Title" class="border-0 bg-white mb-1 font-semibold" />-->
      </div>
      <hr class="m-0 p-0 hr-color" />
    </div>

    <div
      v-if="dragEntered"
      class="absolute w-full rounded-lg h-full bg-gray-800 text-white flex items-center justify-center opacity-70 z-99"
      @dragover="handleDragEnter"
      @drop.self="handleDrop"
      @dragleave="handleDragLeave"
    >
      Drag & Drop
    </div>
    <CstTextArea
      v-if="type !== 'tiktok'"
      :key="getEditorKey()"
      ref="editorElement"
      :placeholder="type === 'common' ? commonPlaceHolder : singlePlaceHolder"
      :get-suggestions="getSuggestions"
      :initial-value="sharingDetails.message"
      :max-limit="maxLimit"
      :twitter-text-enable="twitterTextEnable"
      :get-suggestions-loader="getSuggestionsLoader"
      :saved-links="savedLinks"
      :type="type"
      @change="handleChange"
      @paste="handlePaste"
      @dragEnter="handleDragEnter"
    />

    <!-- Pinterest Source URL-->
    <div
      v-if="type !== 'tiktok'"
      class="cst-editor__inner text-base bg-white px-2 pl-3 py-2"
      :class="{
        'hr-color':
          accountsData.accountSelection.pinterest.length &&
          (type === 'common' || type === 'pinterest'),
      }"
    >
      <div
        v-if="
          accountsData.accountSelection.pinterest.length &&
          (type === 'common' || type === 'pinterest')
        "
        class="flex flex-row space-x-2 justify-between items-center"
      >
        <div class="flex items-center">
          <div class="text-sm text-gray-700 font-normal">
            Source URL for Pinterest
          </div>
          <div>
            <CstInputFields
              :value="sharingDetails.source_url"
              class="ml-4 w-80"
              type="text"
              placeholder="e.g. https://www.example.com/xyz"
              @value="
                (val) => {
                  // eslint-disable-next-line vue/camelcase
                  sharingDetails.source_url = val
                }
              "
            />
          </div>
          <div
            v-if="
              sharingDetails.source_url &&
              !isShortLink(sharingDetails.source_url)
            "
            class="pl-2"
          >
            <button
              class="flex flex-row items-center space-x-2 text-xs text-white border-0 rounded-md px-3 py-2 font-semibold focus:outline-none bg-cs-primary"
              @click="
                shrinkSharingSourceLinkNew(type, sharingDetails.source_url)
              "
            >
              <span>Shrink</span>
              <clip-loader
                v-if="pinterestUrlLinkLoader"
                :color="'#ffffff'"
                :size="'12px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor toolbar-->
    <div
      v-if="type !== 'tiktok'"
      class="cst-editor__toolbar text-base flex justify-between bg-white px-1.5 py-2"
      @dragenter="handleDragEnter"
    >
      <div class="flex">
        <!-- media Options --->
        <div
          v-if="toolbar.media"
          v-floatingtip="'Add Media'"
          class="cst-editor__toolbar-btn cursor-pointer mx-1 px-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        >
          <img src="@assets/img/composer/media-upload.svg" alt="" class="w-6" />
          <span class="ml-2 text-sm font-normal text-black-200 hidden xl:block"
            >Upload</span
          >
          <div
            class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-44 z-50"
          >
            <div
              v-if="type !== 'youtube'"
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click.prevent="triggerComposerImageUpload"
            >
              <div class="w-5 h-5 mr-2">
                <img
                  src="@assets/img/composer/upload-image.svg"
                  alt="upload image"
                />
              </div>
              <span class="pt-0.5"> Upload Image </span>
            </div>
            <div
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click.prevent="triggerComposerVideoUpload"
            >
              <div class="w-5 h-5 mr-2">
                <img
                  src="@assets/img/composer/upload-video.svg"
                  alt="upload image"
                />
              </div>
              <span class="pt-0.5"> Upload Video </span>
            </div>
            <div
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click.prevent="openMediaLibrary"
            >
              <div class="w-5 h-5 mr-2">
                <img
                  src="@assets/img/composer/open-media-library.svg"
                  alt="upload image"
                />
              </div>
              <span class="pt-0.5"> Open Media Library </span>
            </div>
            <form method="post" enctype="multipart/form-data" class="d-none">
              <input
                :id="getImageUploadInputId()"
                type="file"
                name="socialFile"
                accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
                :multiple="
                  [
                    'common',
                    'facebook',
                    'twitter',
                    'threadedTweets',
                    'linkedin',
                    'instagram',
                  ].indexOf(type) >= 0
                "
                data-index=""
                @change="composerUploadImage($event)"
              />
            </form>
            <form method="post" enctype="multipart/form-data" class="d-none">
              <input
                :id="getImageUpdateId()"
                type="file"
                name="socialFileUpdate"
                accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
                data-index=""
                @change="composerUpdateImage($event)"
              />
            </form>

            <form method="post" enctype="multipart/form-data" class="d-none">
              <input
                :id="getVideoUploadInputId()"
                type="file"
                name="socialFileVideo"
                accept=".avi,.mov,.m4v,.mp4"
                data-index=""
                @change="composerUploadVideo($event)"
              />
            </form>
          </div>
        </div>
        <!-- Canva --->
        <div
          v-if="type !== 'youtube'"
          v-floatingtip="'Design image with Canva'"
          class="canva-dropdown mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-green hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        >
          <img src="@assets/img/composer/canva-logo.svg" alt="" class="w-5" />
          <span class="ml-2 text-sm font-normal text-black-200 hidden xl:block"
            >Canva</span
          >
          <div
            class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-44 z-50"
          >
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'SocialMedia', sharingDetails)
              "
            >
              Social Media
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'FacebookPost', sharingDetails)
              "
            >
              Facebook Post
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'TwitterPost', sharingDetails)
              "
            >
              Twitter Post
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'InstagramPost', sharingDetails)
              "
            >
              Instagram Post
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'InstagramStory', sharingDetails)
              "
            >
              Instagram Story
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCanvaDesign(type, 'YouTubeThumbnail', sharingDetails)
              "
            >
              YouTube Thumbnail
            </div>
          </div>
        </div>
        <!-- Crello --->
        <div
          v-if="type !== 'youtube'"
          v-floatingtip="'Design image with Vista'"
          class="crello-dropdown mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-blue hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        >
          <img src="@assets/img/composer/vista-logo.svg" alt="" class="w-5" />
          <span class="ml-2 text-sm font-normal text-black-200 hidden xl:block"
            >Vista Create</span
          >
          <div
            class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-48 z-50"
          >
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(type, 'facebookSM', sharingDetails)
              "
            >
              Facebook SM
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(type, 'facebookVideoStoryAN', sharingDetails)
              "
            >
              Facebook Video Story AN
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(type, 'instagramSM', sharingDetails)
              "
            >
              Instagram SM
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(
                  type,
                  'instagramVideoStoryAN',
                  sharingDetails
                )
              "
            >
              Instagram Video Story AN
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(type, 'twitterSM', sharingDetails)
              "
            >
              Twitter SM
            </div>
            <div
              class="p-2 hover:bg-cs-secondary"
              @click.prevent="
                createCrelloDesign(type, 'tikTokVideoAN', sharingDetails)
              "
            >
              TikTok Video AN
            </div>
          </div>
        </div>
        <!-- Artificial Intelligence  -->
        <div
          v-if="toolbar.media"
          v-floatingtip="'Generate AI Content'"
          class="cst-editor__toolbar-btn cursor-pointer mx-1 px-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        >
          <img
            src="@assets/img/common/cyrus-bot.svg"
            alt=""
            class="w-6 mb-[0.25rem]"
          />
          <span
            class="ml-2 bg-[#FFC555] text-xs px-1 py-0.5 text-white font-bold rounded uppercase"
          >
            New
          </span>
          <div
            class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-48 z-50"
          >
            <!-- AI Captions -->
            <div
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click="fetchAICaption"
            >
              <span class="pt-0.5"> AI Generated Captions </span>
            </div>
            <!-- AI Images -->
            <div
              v-if="type !== 'youtube'"
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click.prevent="openImageGeneratorModal"
            >
              <span class="pt-0.5"> AI Generated Images </span>
            </div>
            <!-- AI Hashtags -->
            <div
              class="hover:bg-cs-secondary flex items-center justify-between"
            >
              <div
                class="p-2"
                :class="{
                  'text-gray-700 pointer-events-none': getCharacterCount < 100,
                }"
                @click.prevent="handleFetchAIHashtags(false)"
              >
                <span class="pt-0.5"> AI Generated Hashtags </span>
              </div>
              <v-popover
                v-if="getCharacterCount < 100"
                trigger="hover"
                placement="right"
                popover-class="first-comment__info-popover"
                class="inline-block mx-1"
              >
                <a href="#"
                  ><img
                    src="@assets/img/composer/help-icon.svg"
                    alt=""
                    class="w-4 h-4"
                /></a>
                <template slot="popover">
                  To generate hashtags, the content needs to be at least 100
                  characters long.
                </template>
              </v-popover>
            </div>
          </div>
        </div>
        <!-- Split Tweet Content -->
        <transition
          enter-active-class="transition-opacity ease-linear duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-300"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="toolbar.splitContent && getCharacterCount > maxLimit"
            v-floatingtip="
              'Automatically split long content into multiple threaded tweets'
            "
            class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-blue hover:bg-opacity-75 rounded-md relative"
            @click="$emit('split-tweet-content')"
          >
            <img
              src="@assets/img/composer/threaded-tweet-icon.svg"
              alt=""
              class="w-5"
            />
            <span
              class="ml-2 mt-0.5 text-sm font-normal text-black-200 hidden xl:block"
              >Auto-Thread</span
            >
          </div>
        </transition>
      </div>

      <div class="flex items-center">
        <div
          v-tooltip="'Saved Captions'"
          class="cst-editor__toolbar-btn text-[#808080] relative mr-2 cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
          @click="openSaveCaptionModal"
        >
          <i class="icon-Save pointer" />
        </div>
        <!-- location dropdown btn is placed here to have same style -->
        <div v-if="toolbar.location" class="location-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="locationToggle = !locationToggle"
          >
            <div
              v-if="toolbar.location"
              v-floatingtip.hover.top="'Add Location'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                src="@assets/img/composer/location-icon.svg"
                alt=" "
                class="w-4 h-4"
              />
            </div>
          </div>
          <LocationDropdown
            v-if="locationToggle"
            :is-open="locationToggle"
            :type="type"
            :accounts="isLocationAccount"
            :location="sharingDetails.location"
            @add-location="addLocation"
            @handle-click="handleClickAwayEvents"
          >
          </LocationDropdown>
        </div>
        <!-- Hashtag dropdown -->
        <div class="hashtag-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="hashtagToggle = !hashtagToggle"
          >
            <div
              v-floatingtip.hover.top="'Add Hashtags'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                src="@assets/img/composer/hashtag-icon.svg"
                alt=" "
                class="w-4 h-4"
              />
            </div>
          </div>
          <HashtagDropdown
            v-if="hashtagToggle"
            :is-open="hashtagToggle"
            :type="type"
            :apply-hashtag="applyHashtag"
            :external-hashtags="externalTags"
            @handle-hashtags="handleHashtagsChanges"
            @handle-click="handleClickAwayEvents"
          >
          </HashtagDropdown>
        </div>
        <!-- UTM dropdown -->
        <div class="utm-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="utmToggle = !utmToggle"
          >
            <div
              v-floatingtip.hover.top="'Add UTM'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                src="@assets/img/composer/utm-icon.svg"
                alt=" "
                class="w-5 h-5"
              />
            </div>
          </div>
          <UtmDropdown
            v-if="utmToggle"
            :is-open="utmToggle"
            :detail="sharingDetails"
            :utm="utm"
            @handle-utm="handleUtmChanges"
            @handle-click="handleClickAwayEvents"
          >
          </UtmDropdown>
        </div>
        <!-- Replug dropdown -->
        <div class="replug-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="replugToggle = !replugToggle"
          >
            <div
              v-floatingtip.hover.top="'Add Replug CTA'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                src="@assets/img/composer/replug-icon.svg"
                alt=" "
                class="w-5 h-5"
              />
            </div>
          </div>
          <ReplugDropdown
            v-if="replugToggle"
            :is-open="replugToggle"
            :detail="sharingDetails"
            :loader="replugLoader"
            @handle-replug="handleReplugChanges"
            @handle-click="handleClickAwayEvents"
          >
          </ReplugDropdown>
        </div>
        <!-- Formatting option -->
        <div
          v-floatingtip="'Format Text'"
          class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md hover-trigger relative mx-2 transition-all ease-in-out"
        >
          <i class="fas fa-text w-4 h-4 text-cs-smoke"></i>
          <!--          <img src="@assets/img/composer/format-icon.svg" alt=" " class="w-4 h-4">-->
          <div
            class="hover-target group absolute top-2 w-40 top-full bg-white rounded p-0.5 shadow text-sm z-50"
          >
            <div
              unselectable="on"
              class="p-2 hover:bg-cs-secondary"
              @click="handleUnicodeVariant('bs')"
              ><b>Bold</b>
              Selected Text
            </div>
            <div
              unselectable="on"
              class="p-2 hover:bg-cs-secondary"
              @click="handleUnicodeVariant('is')"
              ><i>Italic</i>
              Selected Text
            </div>
          </div>
        </div>
        <!-- Emoji dropdown -->
        <div v-if="toolbar.emoji" class="relative">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="emojiToggle = !emojiToggle"
          >
            <div
              v-floatingtip.hover.top="'Add an emoji'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                src="@assets/img/composer/emoji-icon.svg"
                alt=" "
                class="w-4 h-4"
              />
            </div>
          </div>
          <CstEmojiPicker
            v-if="emojiToggle"
            class="right-12"
            position="top-end"
            :is-open="emojiToggle"
            @on-select="onEmojiSelect"
            @handle-click="handleClickAwayEvents"
          >
          </CstEmojiPicker>
        </div>

        <!-- Character limit -->
        <template>
          <div
            v-if="maxLimit"
            class="ml-2 pl-2"
            style="border-left: 1px solid #eef1f4"
          >
            <svg
              v-tooltip.bottom-end="
                maxLimit >= getCharacterCount
                  ? `Remaining Characters: ${maxLimit - getCharacterCount}`
                  : `Extra Characters: ${getCharacterCount - maxLimit}`
              "
              height="20"
              width="20"
              viewBox="0 0 20 20"
              class="cursor-pointer"
              :class="
                getCharacterCount > maxLimit ? 'text-red-400' : 'text-blue-400'
              "
            >
              <circle r="10" cx="10" cy="10" fill="#e9ecef"></circle>
              <circle
                id="svg-circle"
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke="currentColor"
                stroke-width="10"
                transform="rotate(-90) translate(-20)"
                :stroke-dasharray="`calc(${percentage} * 31.4px / 100) 31.4`"
              ></circle>
              <circle r="6" cx="10" cy="10" fill="white"></circle>
            </svg>
          </div>
        </template>
      </div>
    </div>

    <!-- Toolbar UI For Tiktok Box  -->
    <div v-else class="text-base flex bg-white px-2 py-2 rounded-lg">
      <div
        class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-green rounded-md hover-trigger relative"
        @click.prevent="triggerComposerVideoUpload"
      >
        <i class="fas fa-film text-base text-gray-800 mr-1"></i>
        <span class="ml-2 text-sm font-normal text-black-200"
          >Upload Video</span
        >
      </div>

      <div
        class="mx-1 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-blue rounded-md hover-trigger relative"
        @click.prevent="openMediaLibrary"
      >
        <i class="fas fa-folder text-base text-gray-800 mr-1"></i>
        <span class="ml-2 text-sm font-normal text-black-200"
          >Open Media Library</span
        >
      </div>

      <form method="post" enctype="multipart/form-data" class="d-none">
        <input
          :id="getVideoUploadInputId()"
          type="file"
          name="socialFileVideo"
          accept=".avi,.mov,.m4v,.mp4"
          data-index=""
          @change="composerUploadVideo($event)"
        />
      </form>
    </div>

    <!-- Link Preview Box -->
    <transition name="slidedown">
      <template v-if="canHaveLinkPreview()">
        <div
          v-if="(linkPreviewLoader || linkShortenerLoader) && !isMediaEnabled"
          class="p-3"
        >
          <div class="flex w-full">
            <SkeletonBox width="12rem" height="9rem" radius="12px" />

            <div class="p-3 w-full">
              <div class="mb-2">
                <SkeletonBox height="1.2rem" radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <SkeletonBox radius=".2rem" />
            </div>
          </div>
        </div>
        <div v-else-if="isLinkPreviewEnabled">
          <EditorLinkPreview
            :title="sharingDetails.title ? sharingDetails.title : ''"
            :url="sharingDetails.website ? sharingDetails.website : ''"
            :description="
              sharingDetails.description ? sharingDetails.description : ''
            "
            :image="
              sharingDetails.image[0]
                ? sharingDetails.image[0]
                : sharingDetails.image
                ? sharingDetails.image
                : ''
            "
            @on-remove="() => removeLinkPreview(true, true)"
          />
        </div>
      </template>
    </transition>

    <!-- Media Type should be either image | video -->
    <transition name="slidedown">
      <template
        v-if="
          (!isLinkPreviewEnabled &&
            !isLoadersEnabled &&
            (isMediaEnabled || getSuggestedImages.length)) ||
          mediaUploadLoader ||
          isUploadingLinkImage ||
          getSharingImageUploadLoader(type).status ||
          uploadingAiImages
        "
      >
        <EditorMediaBox
          :type="getMediaType"
          :media="getMedia"
          :account-type="type"
          :accounts-data="accountsData"
          :media-loader="
            mediaUploadLoader || getSharingImageUploadLoader(type).status
          "
          :progress-bar="progressBar"
          :video-errors="videoErrors"
          :has-custom-thumbnail="hasCustomThumbnail"
          :suggested-images="getSuggestedImages"
          :sharing-details="sharingDetails"
          :update-image-loader="updateImageLoader"
          :insta-posting-method="instaPostingMethod"
          :is-uploading-link-image="isUploadingLinkImage"
          :instagram-post-type="instagramPostType"
          :facebook-post-type="facebookPostType"
          :youtube-post-type="youtubePostType"
          :uploading-ai-images="uploadingAiImages"
          :selected-tabs="selectedTabs"
          :active-tabs="activeTabs"
          @on-sort="sortMedia"
          @on-edit="onEdit"
          @set-alt="setAlt"
          @remove="removeMediaAsset"
          @add-suggested-image="uploadImageFromLink"
          @profile-tagging="profileTagging"
        />
      </template>
    </transition>

    <!-- AI Hashtag Suggestions -->
    <EditorAIHashtags
      ref="editor_AI_hashtags"
      @addHashtag="addSocialHashtag"
      @saveHashtag="loadHashtagDropdown"
      @reGenerate="handleFetchAIHashtags(true)"
    >
    </EditorAIHashtags>
    <!--Composer - Video title modal-->
    <b-modal
      id="video-title-modal-v2"
      ref="video-title-modal-v2"
      v-model="videoTitleModal"
      centered
      hide-header
      no-close-on-backdrop
      size="lg"
    >
      <header class="modal-header media-library-modal-header">
        <h3 class="font-weight-bold my-auto text-capitalize">
          Add title for video file
          <!--<a class="beacon ml-2" v-tooltip.top-center="'Learn more about Alt-Texts'"
               href="#">
                <i class="far fa-question-circle"
                ></i>
            </a>-->
        </h3>
        <button
          aria-label="Close"
          class="close"
          type="button"
          @click="closeVideoTitleModal"
          >×
        </button>
      </header>

      <b-container class="pt-4 px-4 pb-2" fluid>
        <b-row>
          <b-col class="my-auto" sm="3">
            <b-img
              :src="
                sharingDetails.video && sharingDetails.video.thumbnail
                  ? sharingDetails.video.thumbnail
                  : ''
              "
              alt="Image 1"
              center
              height="150"
              width="150"
            ></b-img>
          </b-col>
          <b-col sm="9">
            <b-textarea
              id="textarea"
              v-model="videoTitle"
              :state="textAreaValidation"
              max-rows="6"
              no-resize
              placeholder="Video Title..."
              rows="6"
            ></b-textarea>
          </b-col>
          <b-col class="d-flex justify-content-end" offset-sm="10" sm="2">
            <span>{{ videoTitleCharCount }}/{{ videoTitleCharLimit }}</span>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer>
        <div class="px-3">
          <p
            >Available for:
            <span style="color: #3b5998">
              <i class="fab fa-facebook-square fa-lg mx-1" size="24"> </i>
            </span>
            <span style="color: #1da1f2">
              <i class="fab fa-linkedin fa-lg mx-1" size="24"></i>
            </span>
          </p>
        </div>
        <b-button
          :disabled="videoTitleSaveButtonStatus || videoTitleCharCount === 0"
          size="lg"
          variant="primary"
          @click="onClickSaveVideoTitle($event)"
        >
          Save
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import CstTextArea from '@ui/TextArea/CstTextArea'
import EditorMediaBox from '@src/modules/composer_v2/components/EditorBox/EditorMediaBox'
import EditorLinkPreview from '@src/modules/composer_v2/components/EditorBox/EditorLinkPreview'
import debounce from 'lodash/debounce'
import LocationDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/LocationDropdown'
import UtmDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/UtmDropdown'
import EditorAIHashtags from '@src/modules/composer_v2/components/EditorBox/EditorAIHashtags.vue'
import isEmpty from 'is-empty'
import HashtagDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/HashtagDropdown'
import ReplugDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/ReplugDropdown'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import {
  getHashtagsFromText,
  getLinksFromText,
  memoizeCallback,
  parseFilesMimeTypes,
} from '@common/lib/helper'
import { commonTypes } from '@src/modules/publish/store/states/mutation-types'
import { mapGetters } from 'vuex'
import {
  fetchAIHashTags,
  fetchShortLinksUrl,
} from '@src/modules/publish/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import editorInitialValue from '@src/modules/composer_v2/components/EditorBox/editorInitialValue'
import CstInputFields from '@ui/Input/CstInputFields'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import proxy from '@common/lib/http-common'
const memoizedGetLinksFromText = memoizeCallback(getLinksFromText, true)

export default {
  name: 'EditorBox',
  components: {
    EditorAIHashtags,
    HashtagDropdown,
    LocationDropdown,
    UtmDropdown,
    ReplugDropdown,
    CstTextArea,
    CstEmojiPicker: () => import('@ui/EmojiPicker/CstEmojiPicker'),
    EditorMediaBox,
    EditorLinkPreview,
    SkeletonBox,
    CstInputFields,
  },
  mixins: [ComposerHelper, MediaHelperMixin],
  props: {
    planId: {
      type: String,
      default: '',
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
    tweetIndex: {
      type: Number,
      default: 0,
    },
    username: {
      type: String,
      default: '',
      required: true,
    },
    toolbar: {
      type: Object,
      default: () => editorInitialValue.toolbar,
    },
    maxLimit: {
      type: Number,
      default: 5000,
    },
    twitterTextEnable: {
      type: Boolean,
      default: false,
    },
    sharingDetails: {
      type: Object,
      default: () => {},
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'common',
      required: true,
    },
    utm: {
      type: Object,
      default: () => {},
    },
    replugLoader: {
      type: Boolean,
      default: false,
    },
    videoErrors: {
      type: Array,
      default: () => [],
    },
    instaPostingMethod: {
      type: String,
      default: '',
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    youtubePostType: {
      type: String,
      default: 'video',
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    selectedTabs: {
      type: String,
      default: '',
    },
    activeTabs: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debounceTextUpdate: () => null,
      commonPlaceHolder:
        'Add your content here e.g. caption, images, videos, URL, mention, emoji etc.',
      singlePlaceHolder: 'Add your content here ...',
      templateText: '',
      characterCount: 0,
      hashtags: [],
      externalTags: [],
      mediaType: 'image',
      linkPreviewLoader: false,
      linkShortenerLoader: false,
      cancelledLink: '',
      progressBar: {
        image: 0,
        numberOfImages: 0,
        video: 0,
      },
      mediaUploadLoader: false,
      updateImageLoader: { status: false, position: -1 },
      isUploadingLinkImage: false,
      usedSuggestedImages: [],
      mentionsLoader: false,
      links: {},
      dragEntered: false,
      pinterestUrlLinkLoader: false,
      videoTitleModal: false,
      videoTitle: '',
      videoTitleCharLimit: 100,
      locationToggle: false,
      utmToggle: false,
      replugToggle: false,
      hashtagToggle: false,
      emojiToggle: false,
      crelloToggle: false,
      canvaToggle: false,
      savedLinks: [],
    }
  },
  computed: {
    ...mapGetters([
      'getTwitterVariationsIndex',
      'getTwitterVariations',
      'getPublishLoaders',
    ]),
    /**
     * Calculates the length of the accounts selected
     * @returns {number}
     */
    selectedAccountsLength() {
      let length = 0
      for (const channel in this.accountSelection) {
        length += this.accountSelection[channel].length
      }
      return length
    },
    getMedia() {
      if (this.sharingDetails.video && this.sharingDetails.video.link)
        return [this.sharingDetails.video]
      else return [].concat(this.sharingDetails.image)
    },

    getMediaType() {
      console.debug('getMediaType', this.progressBar)
      if (
        (this.sharingDetails.video && this.sharingDetails.video.link) ||
        (this.mediaUploadLoader && this.progressBar.video)
      ) {
        return 'video'
      }
      return 'image'
    },
    percentage() {
      return Math.round((100 / this.maxLimit) * this.getCharacterCount)
    },

    isLinkPreviewEnabled() {
      // link preview is not enabled for instagram, pinterest, tumblr, gmb, youtube, tiktok (only show link preview image)
      if (
        this.type === 'instagram' ||
        this.type === 'pinterest' ||
        this.type === 'tumblr' ||
        this.type === 'gmb' ||
        this.type === 'youtube' ||
        this.type === 'tiktok'
      ) {
        return false
      }
      if (this.sharingDetails.url && this.sharingDetails.url !== '') {
        if (this.sharingDetails.image && this.sharingDetails.image.length)
          return true
        if (this.sharingDetails.title) return true
        if (this.sharingDetails.website) return true
        return !!this.sharingDetails.description
      }
      return false
    },
    isLoadersEnabled() {
      if (this.getMedia && this.getMedia.length > 0) return false
      return this.linkPreviewLoader || this.linkShortenerLoader
    },
    isMediaEnabled() {
      return (
        (this.sharingDetails.image && this.sharingDetails.image.length > 0) ||
        (this.sharingDetails.video &&
          this.sharingDetails.video.link &&
          this.sharingDetails.video.link !== '')
      )
    },

    hasCustomThumbnail() {
      return (
        this.type === 'common' ||
        this.type === 'facebook' ||
        this.type === 'youtube' ||
        this.type === 'instagram' ||
        this.type === 'linkedin' ||
        this.type === 'pinterest'
      )
    },

    linksFromText() {
      if (this.sharingDetails.message) {
        const links = memoizedGetLinksFromText(this.sharingDetails.message)
        console.log(' linksFromTextlinks::', links)
        return links ? links[0] : ''
      }
      return ''
    },
    isLocationAccount() {
      return {
        facebook: this.accountsData.accountSelection.facebook.length > 0,
        instagram: this.accountsData.accountSelection.instagram.length > 0,
        twitter: this.accountsData.accountSelection.twitter.length > 0,
      }
    },
    textAreaValidation() {
      var resp = null
      resp = this.videoTitle.length <= 100 ? null : false
      return resp
    },
    videoTitleCharCount() {
      return this.videoTitle.length
    },

    videoTitleSaveButtonStatus() {
      return this.videoTitleCharCount > this.videoTitleCharLimit
    },

    getSuggestedImages() {
      const images = []
      if (
        this.sharingDetails.image_suggestions &&
        this.sharingDetails.image_suggestions.length
      ) {
        this.sharingDetails.image_suggestions.forEach((image) => {
          if (this.usedSuggestedImages.includes(image) === false) {
            images.push(image)
          }
        })
      }
      return images
    },
    getCharacterCount() {
      if (
        this.twitterTextEnable &&
        this.sharingDetails.url &&
        !this.sharingDetails.message.includes(this.sharingDetails.url)
      ) {
        return this.characterCount + 24
      }
      return this.characterCount
    },
  },

  watch: {
    maxLimit: function (val) {
      if (this.type === 'common') {
        this.$refs.editorElement.editor.setMaxCharacterLimit(val)
      }
    },
    twitterTextEnable: function (val) {
      if (this.type === 'common') {
        this.$refs.editorElement.editor.setTwitterTextStatus(val)
      }
    },
    'sharingDetails.message': function (val) {
      if (this.sharingDetails.message !== this.templateText) {
        console.debug('Update Editor Content', this.type)
        this.templateText = this.sharingDetails.message
        this.$refs.editorElement.editor.setContent(this.sharingDetails.message)
      }
    },
  },
  created() {
    const self = this
    // hack: fix for the issue related to the editor boxes content update - https://stackoverflow.com/a/60030038/15265413
    this.debounceTextUpdate = debounce(function (count) {
      self.$emit('onEditorBoxTextChange', self.type, self.templateText, count)
    }, 500)
  },

  mounted() {
    console.debug('EditorBox mounted', this.type, this.sharingDetails)
    if (this.$el) {
      // this.$refs.editorElement.editor.setContent(this.sharingDetails.message)
    }
    this.savedLinks = this.sharingDetails.saved_urls
      ? this.sharingDetails.saved_urls
      : []
  },

  beforeDestroy() {
    console.debug('editor before destroy', this.type)
    this.$off([
      'toggleFirstComment',
      'toggleThreadedTweets',
      'toggleCarouselPost',
      'onEditorBoxTextChange',
      'removeLinkPreview',
      'handleSharingLink',
      'setLinkPreview',
      'initializeVideoUpload',
      'addMedia',
      'removeMedia',
      'add-location',
      'handle-utm',
      'handle-replug',
      'handle-hashtags',
      'handle-change',
      'add-single-image',
    ])
  },

  methods: {
    profileTagging(link) {
      this.$emit('profile-tagging', link, this.type)
    },
    handleClickAwayEvents(toggle, type) {
      console.log('handleClickAwayEvents', toggle, type)
      if (toggle) {
        switch (type) {
          case 'location':
            this.locationToggle = false
            break
          case 'utm':
            this.utmToggle = false
            break
          case 'replug':
            this.replugToggle = false
            break
          case 'hashtag':
            this.hashtagToggle = false
            this.externalTags = []
            break
          case 'emoji':
            this.emojiToggle = false
            break
          case 'canva':
            this.canvaToggle = false
            break
          case 'crello':
            this.crelloToggle = false
            break
        }
      }
    },
    closeOther() {
      this.locationToggle = false
      this.utmToggle = false
      this.replugToggle = false
      this.hashtagToggle = false
      this.emojiToggle = false
      this.crelloToggle = false
    },
    addSuggestedImage(image) {
      this.$emit('add-single-image', this.type, image)
    },

    canHaveLinkPreview() {
      return !(this.type === 'youtube' || this.type === 'tiktok')
    },

    getEditorKey() {
      return `editor_${this.type}`
    },
    getImageUploadInputId() {
      return `image-upload-input-${this.type}`
    },
    getVideoUploadInputId() {
      return `video-upload-input-${this.type}`
    },
    getImageUpdateId() {
      return `image-update-${this.type}`
    },

    getSharingDetail() {
      return this.sharingDetails
    },

    sortMedia(media, type) {
      if (type === 'image') {
        const details = { ...this.sharingDetails }
        details.image = [...media]
        this.$emit('handle-change', details, this.type)
      }
    },

    triggerComposerImageUpload() {
      console.debug('Method:triggerComposerImageUpload-v2')
      document.getElementById(this.getImageUploadInputId()).click()
    },

    triggerComposerVideoUpload() {
      console.debug('Method:triggerComposerVideoUpload-v2')
      document.getElementById(this.getVideoUploadInputId()).click()
    },

    openMediaLibrary() {
      console.debug('openMediaLibrary-v2', this.type)
      EventBus.$emit('show-media-library-modal', {
        source: this.type,
        details: {
          image:
            !this.sharingDetails.url || this.sharingDetails.url === ''
              ? this.sharingDetails.image
              : [],
          video: this.sharingDetails.video,
          threadedTweetIndex: this.tweetIndex,
        },
      })
    },
    openImageGeneratorModal() {
      EventBus.$emit('imageGeneratorModal', { isOldComposer: false })
    },
    /**
     * open saved caption modal
     */
    openSaveCaptionModal() {
      EventBus.$emit('save-caption-modal', {
        caption: this.sharingDetails.message,
        isOldComposer: false,
        source: this.type,
      })
    },
    handleUnicodeVariant(variant) {
      this.$refs.editorElement.editor.replaceSelected((selectedText) => {
        return this.toUnicodeVariant(selectedText, variant)
      })

      this.$refs.editorElement.editor.view.focus()
    },
    toggleFirstComment: function () {
      this.$emit('toggleFirstComment')
    },
    toggleThreadedTweets: function () {
      this.$emit('toggleThreadedTweets')
    },
    toggleCarouselPost() {
      this.$emit('toggleCarouselPost')
    },

    getTemplateText() {
      return this.templateText
    },
    handlePaste(event, view, data) {
      if (
        (event.clipboardData || event.originalEvent.clipboardData).items.length
      ) {
        this.processMediaPasteDropEvent(event)
      }
    },
    async handleDrop(event, view = null) {
      console.log('METHOD::handleDrop ~ event -> ', event)
      let data = ''
      data = event.dataTransfer.getData('assistantData')

      if (data) {
        data = JSON.parse(data)
        // to remove previous link preview if any
        await this.$emit(
          'removeLinkPreview',
          this.type,
          data.type !== 'image',
          true
        )
        let msg = this.templateText
        switch (data.type) {
          case 'article':
            msg = data.heading + '\n' + data.link + ' '
            this.templateText = ''
            this.$refs.editorElement.editor.setContent(msg)
            break
          case 'embed':
            msg = data.heading + '\n' + data.media + ' '
            this.templateText = ''
            this.$refs.editorElement.editor.setContent(msg)
            break
          case 'image':
            this.uploadImageFromLink(data.media)
            break
        }
        this.dragEntered = false
      } else {
        this.dragEntered = false
        this.processMediaPasteDropEvent(event)
      }
    },
    handleDragEnter(event, view = null) {
      console.log('METHOD::handleDragEnter ~ event -> ', event)
      event.preventDefault()
      this.dragEntered = true
    },
    handleDragLeave(event, view = null) {
      console.log('METHOD::handleDragLeave ~ event -> ', event)
      this.dragEntered = false
    },
    processMediaPasteDropEvent(event) {
      console.log('METHOD::processPasteDropEvent ~ event -> ', event)
      event.preventDefault()
      event.stopPropagation()

      const types = parseFilesMimeTypes(event)
      // checking for the the files other than image or video
      if (types.others) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only image or video file.',
          type: 'error',
        })
        return false
      }
      // checking for the the multiple gifs
      if (types.gifs > 1) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 GIF image.',
          type: 'error',
        })
        return false
      }
      // checking for the multiple videos
      if (types.videos > 1) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 video.',
          type: 'error',
        })
        return false
      }
      // checking for the multiple files types
      if (
        (types.gifs && types.images) ||
        (types.gifs && types.videos) ||
        (types.images && types.videos)
      ) {
        this.$store.dispatch('toastNotification', {
          message: 'Please upload one file type at a time.',
          type: 'error',
        })
        return false
      }
      // calling method for uploading image
      if (types.gifs || types.images) {
        this.CSUploadImage(
          event,
          this.type,
          this.getSharingDetail,
          this.cancelledLink,
          this.handleCallback
        )
      }

      // calling method for uploading video
      if (types.videos) {
        this.CSUploadVideo(
          event,
          this.type,
          this.getSharingDetail,
          this.handleCallback
        )
      }
    },

    handleChange: function ({
      templateText,
      text,
      links,
      count,
      lastChar,
      hashtags,
    }) {
      console.log('templateText:: ', this.templateText)
      this.characterCount = count
      this.hashtags = hashtags
      if (
        lastChar === ' ' ||
        (this.templateText === '' && this.socialPostType !== 'edit')
      ) {
        this.templateText = templateText
        this.sharingDetails.message = templateText
        this.$emit('onEditorBoxTextChange', this.type, templateText, count)
        if (links.length > 0) this.processEditorLinks(links, templateText)
        if (this.templateText === '') {
          this.removeLinkPreview()
        }
      } else {
        this.templateText = templateText
        this.debounceTextUpdate(count)
      }
    },

    handleEditorTextUpdate: debounce(function (templateText, text, count) {
      console.debug(
        'handleEditorTextUpdate',
        this.type,
        templateText,
        text,
        count
      )
      this.$emit('onEditorBoxTextChange', this.type, this.templateText, count)
    }, 500),

    getSuggestions: async function (type, text, done) {
      console.log(
        'METHOD::getSuggestions ~ type, text, done -> ',
        type,
        text,
        done
      )
      console.log(
        'getSuggestions',
        this.type,
        this.accountsData.accountSelection
      )
      if (type === 'mention') {
        const suggestions = []
        if (
          this.type === 'common' ||
          this.type === 'facebook' ||
          this.type === 'twitter' ||
          this.type === 'instagram' ||
          this.type === 'linkedin'
        ) {
          const platforms = []
          if (this.type === 'common') {
            // if facebook account selected
            if (!isEmpty(this.accountsData.accountSelection.facebook)) {
              platforms.push('facebook')
            }
            // if twitter account selected
            if (!isEmpty(this.accountsData.accountSelection.twitter)) {
              platforms.push('twitter')
            }
            if (!isEmpty(this.accountsData.accountSelection.linkedin)) {
              platforms.push('linkedin')
            }
          } else if (this.type === 'facebook') {
            // if facebook account selected
            if (!isEmpty(this.accountsData.accountSelection.facebook)) {
              platforms.push('facebook')
            }
          } else if (this.type === 'twitter') {
            // if Twitter account selected
            if (!isEmpty(this.accountsData.accountSelection.twitter)) {
              platforms.push('twitter')
            }
          } else if (this.type === 'linkedin') {
            // if LinkedIn account selected
            if (!isEmpty(this.accountsData.accountSelection.linkedin)) {
              platforms.push('linkedin')
            }
          }
          this.mentionsLoader = true
          const tempSuggestions = await this.fetchComposerMentionSuggestions(
            text,
            platforms
          )
          this.mentionsLoader = false

          if (tempSuggestions) {
            console.log('suggestions', tempSuggestions)
            if (tempSuggestions.facebook && tempSuggestions.facebook.length) {
              const facebookMentions = tempSuggestions.facebook.map((item) => ({
                name: item.name,
                username: item.username,
                id: item.id,
                tab: 'facebook',
                verification_status:
                  item.verification_status === 'blue_verified',
                fan_count: item.fan_count,
                picture: item.picture?.data?.url,
                selectable_field: 'name',
              }))

              suggestions.push(...facebookMentions)
            }

            if (tempSuggestions.twitter && tempSuggestions.twitter.length) {
              const twitterMentions = tempSuggestions.twitter.map((item) => ({
                name: item.name,
                username: `@${item.username}`,
                id: `@${item.username}`,
                tab: 'twitter',
                verification_status: item.is_verified,
                fan_count: item.followers_count,
                picture: item?.picture,
                selectable_field: 'username',
              }))

              suggestions.push(...twitterMentions)
            }

            if (tempSuggestions?.linkedin?.length) {
              const linkedInMentions = tempSuggestions.linkedin.map((item) => ({
                name: item.name,
                username: `${item.name}`,
                id: `${item.id}`,
                tab: 'linkedin',
                verification_status: item.is_verified,
                picture: item?.picture,
                selectable_field: 'username',
              }))

              suggestions.push(...linkedInMentions)
            }
          }

          if (suggestions.length) {
            done(suggestions)
          } else {
            done([])
          }
        } else {
          done([])
        }

        // setTimeout(() => {
        //   done([
        //     {name: 'Seerat Awan', username: '@seeratawan', id: '999999', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: false, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //
        //   ])
        // }, 500)
        // pass dummy mention suggestions
      } else if (type === 'tag') {
        // pass dummy tag suggestions
        // done([{tag: 'WikiLeaks'}, {tag: 'NetNeutrality'}])
      }
    },

    getSuggestionsLoader() {
      if (
        (this.type === 'common' ||
          this.type === 'facebook' ||
          this.type === 'twitter' ||
          this.type === 'linkedin') &&
        ((this.accountsData.accountSelection.facebook &&
          this.accountsData.accountSelection.facebook.length) ||
          (this.accountsData.accountSelection.twitter &&
            this.accountsData.accountSelection.twitter.length) ||
          (this.accountsData.accountSelection.linkedin &&
            this.accountsData.accountSelection.linkedin.length))
      ) {
        return "<div class='mention-loader mx-auto my-3'></div>"
      } else {
        return ''
      }
    },
    onEmojiSelect: function (emojiUnicode) {
      this.$refs.editorElement.editor.insertText(emojiUnicode)
      this.$refs.editorElement.editor.view.focus()
    },
    addLocation(locationType, location) {
      this.$emit('add-location', this.type, locationType, location)
    },
    handleUtmChanges(flag, data) {
      this.$emit('handle-utm', flag, this.type, data)
    },
    handleReplugChanges(data) {
      this.$emit('handle-replug', this.type, data)
    },
    handleHashtagsChanges(flag, data) {
      this.$emit('handle-hashtags', this.type, flag, data)
    },
    /**
     * Remove the link preview from the editor
     */
    removeLinkPreview: function (removeImage = false, trigger = false) {
      this.$emit('removeLinkPreview', this.type, removeImage, trigger)
    },

    /**
     * This method will be called when user clicks on the cross button on media asset
     */
    removeMediaAsset: function (asset, mediaType) {
      console.debug('Method:removeMediaAsset-v2', this.type, mediaType, asset)
      this.$emit('removeMedia', this.type, mediaType, asset)
    },

    processEditorLinks(links, text) {
      console.debug('Method:processEditorLinks', links, this.type)
      if (
        !this.linkPreviewLoader &&
        !this.linkShortenerLoader &&
        !this.isMediaEnabled
      )
        this.CSProcessLinks(
          this.type === 'threaded-tweet' ? 'twitter' : this.type,
          links,
          this.getTemplateText,
          this.getSharingDetail,
          this.cancelledLink,
          this.handleCallback,
          this.savedLinks
        )
    },

    /**
     * @description: This method is used upload image
     * @param event
     */
    composerUploadImage(event) {
      this.CSUploadImage(
        event,
        this.type,
        this.getSharingDetail,
        this.cancelledLink,
        this.handleCallback
      )
    },

    /**
     * upload Image From Link
     * @param image
     * @param isSuggested is link preview suggested image (default false)
     * @param index
     */
    async uploadImageFromLink(image, isSuggested = false) {
      if (this.isUploadingLinkImage) return
      try {
        this.isUploadingLinkImage = true
        const filters = {
          link: [image],
          folder_id: null,
        }
        const self = this
        await this.uploadLinksFilesHelper(
          filters,
          (status, message, mediaContainer) => {
            if (
              status &&
              mediaContainer &&
              mediaContainer[0] &&
              mediaContainer[0].link
            ) {
              self.$store.dispatch('updateSocialSharingMedia', mediaContainer)
              self.$emit('add-single-image', this.type, mediaContainer[0].link)
              if (isSuggested) self.usedSuggestedImages.push(image)
            }
            self.isUploadingLinkImage = false
          }
        )
      } catch (exception) {
        console.error('Exception occur while adding suggested image', exception)
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.isUploadingLinkImage = false
      }
    },

    /**
     * @description: This method is used upload video
     * @param event
     */
    composerUploadVideo(event) {
      this.CSUploadVideo(
        event,
        this.type,
        this.getSharingDetail,
        this.handleCallback
      )
    },

    /**
     * @description: This method is used handle callback from mixin
     * @param {string} actionType - action type
     * @param {object} payload - action payload
     */
    handleCallback(actionType, payload) {
      console.debug(
        'Method:processEditorLinks handleCallback ',
        actionType,
        payload
      )
      switch (actionType) {
        case 'details':
          this.sharingDetails[payload.propertyName] = payload.value
          break
        case 'local-property':
          if (payload.objectName)
            this[payload.objectName][payload.propertyName] = payload.value
          else this[payload.propertyName] = payload.value
          break
        case 'setLinkPreview':
          this.$emit('setLinkPreview', this.type, payload.value)
          break
        case 'handle-change':
          this.$emit('handle-change', this.sharingDetails, this.type)
          break
        case 'removeLinkPreview':
          this.removeLinkPreview(true, true)
          break
        case 'removeMedia':
          this.$emit(
            'removeMedia',
            this.type,
            payload.mediaType,
            payload.media,
            payload.removeAll
          )
          break
        case 'addMedia':
          this.$emit('addMedia', this.type, payload.media, payload.mediaType)
          break
        case 'initializeVideoUpload':
          this.$emit('initializeVideoUpload', this.type)
          break
        case 'handle-url':
          this.saveUrl(payload)
          break
      }
    },
    saveUrl(payload) {
      const { original, short } = payload
      const value = this.savedLinks.find(
        (item) => item.short === short || item.original === original
      )
      if (value) return
      this.savedLinks.push(payload)
      this.$emit('save-editor-url', this.type, this.savedLinks)
    },
    /**
     * Captions generation logic event
     */
    fetchAICaption(event) {
      EventBus.$emit('AiCaptionModal_show', {
        isOldComposer: false,
        source: this.type,
      })
    },

    async handleFetchAIHashtags(reGenerate = false) {
      if (this.getCharacterCount < 100) {
        this.alertMessage(
          'To generate hashtags, the content needs to be at least 100 characters long.',
          'info'
        )
        return
      }
      const hashtags = this.$refs.editor_AI_hashtags
      hashtags.setLoader(true)
      hashtags.setHashtags([])
      const payload = {
        workspace_id: this.$store.getters.getActiveWorkspace._id,
        prompt: this.sharingDetails.message,
        regenerate: reGenerate,
      }
      await proxy
        .post(fetchAIHashTags, payload)
        .then((res) => {
          if (!res.data.status) {
            if (res.data.creditFull)
              this.$store.getters.getPlan.used_limits.caption_generation_credit =
                res.data.usedCredits
            this.alertMessage(res.data.message, 'error')
            hashtags.setLoader(false)
            return
          }
          this.$store.getters.getPlan.used_limits.caption_generation_credit =
            res.data.limits.used
          hashtags.setHashtags(res.data.hashtags)
          hashtags.setLoader(false)
        })
        .catch((err) => {
          console.log('FetchAiHashtagsCall', err)
          this.alertMessage('Something went wrong. Please try again.', 'error')
          hashtags.setLoader(false)
        })
    },

    loadHashtagDropdown(value = []) {
      this.hashtagToggle = true
      this.externalTags = value
    },
    /**
     * Changes the variations of the caption generated
     */
    changeVariation() {
      const message = this.sharingDetails.message
      const tags = getHashtagsFromText(message)
      const links = memoizedGetLinksFromText(message)
      const mentions = this.getTextMentions(message)
      // this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      let index = 0
      if (
        this.getTwitterVariationsIndex !==
        this.getTwitterVariations.length - 1
      ) {
        index = this.getTwitterVariationsIndex + 1
      }
      this.$store.commit(commonTypes.SET_ACTIVE_TWITTER_VARIATION_INDEX, index)

      let newMessage = this.getTwitterVariations[index]
      if (mentions) {
        for (const prop in mentions) {
          newMessage = newMessage + ' ' + mentions[prop]
        }
      } // apply mentions
      if (tags) {
        for (const prop in tags) newMessage = newMessage + ' ' + tags[prop]
      } // apply hashtags
      if (links) {
        for (const prop in links) newMessage = newMessage + ' ' + links[prop]
      } // apply links

      this.$refs.editorElement.editor.setContent(newMessage)
      // this.setSharingMessage('Common', newMessage)
    },
    addSocialHashtag(tag, index, isBulk = false) {
      console.log('METHOD::addSocialHashtag', tag, index, isBulk)
      // this.sharingDetails.message = this.sharingDetails.message + ' ' + tag
      if (isBulk && tag.length > 1) {
        tag.forEach((item) =>
          this.$refs.editorElement.editor.insertText(' ' + item)
        )
        return
      }
      this.$refs.editorElement.editor.insertText(' ' + tag)
      // this.$refs.editorElement.editor.insertText(tag)
    },

    shrinkSharingSourceLinkNew(type, link) {
      console.debug(
        'Method:shrinkSharingSourceLinkNew ~ type, link -> ',
        type,
        link
      )

      if (this.isValidUrl(link)) {
        this.pinterestUrlLinkLoader = true
        /* if (type === 'Common') {
          this.setShrinkCommonSharingSourceLinkLoader(true)
        } else if (type === 'Pinterest') {
          this.setShrinkPinterestSharingSourceLinkLoader(true)
        } */

        this.postRequest(
          fetchShortLinksUrl,
          {
            urls: [link],
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.links && response.data.links.length) {
              /* if (type === 'Common') {
                  this.setCommonSharingSourceLink(response.data.links[0].short)
                } else if (type === 'Pinterest') {
                  this.setPinterestSharingSourceLink(response.data.links[0].short)
                } */
              this.sharingDetails.source_url = response.data.links[0].short
              this.pinterestUrlLinkLoader = false
            } else if (
              response.data.status === false &&
              response.data.message
            ) {
              this.alertMessage(response.data.message, 'error')
            }
            this.pinterestUrlLinkLoader = false
          },
          (error) => {
            console.debug('Exception in shrinkSharingSourceLink', error)
            this.pinterestUrlLinkLoader = false
            /* if (type === 'Common') {
                this.setShrinkCommonSharingSourceLinkLoader(false)
              } else if (type === 'Pinterest') {
                this.setShrinkPinterestSharingSourceLinkLoader(false)
              } */
          }
        )
      } else {
        this.alertMessage('Please enter a valid URL to shorten.', 'error')
      }
    },

    /**
     * Event for editing image.
     * @param asset
     * @param index
     */
    onEdit(asset, index) {
      console.log('METHOD::onEdit ~ asset -> ', asset, index)
      if (typeof asset === 'string') {
        // New Version Implementation.
        EventBus.$emit('enhanceImagePintura', {
          stateObject: this,
          type: this.type,
          image: asset,
          index: index,
          threadedTweetIndex: this.tweetIndex,
          modalVisible: true,
        })
      } else if (typeof asset === 'object') {
        if (asset?.converted_mime_type === 'video/mp4') {
          this.triggerVideoTitleModal(asset)
        }
      }
    },

    /**
     * event for adding alt text to the image.
     * @param image
     * @param altText
     * @param index
     */
    setAlt(image, altText, index) {
      console.log('METHOD::setAltText ~ image, index -> ')
      if (!('alt_texts' in this.sharingDetails)) {
        this.sharingDetails.alt_texts = []
      }

      this.sharingDetails.alt_texts.push({ image, alt_text: altText })
    },

    /**
     * enable the video-title modal
     * @param video
     */
    triggerVideoTitleModal(video) {
      console.log('Method:: TriggerVideoTitleModal')
      this.videoTitle = video.title
      this.videoTitleModal = true
    },

    /**
     * close modal method.
     * @param event
     */
    closeVideoTitleModal(event) {
      console.log('Method:: closeVideoTitleModal ~ event -> ', event)
      this.videoTitleModal = false
    },
    onClickSaveVideoTitle(event) {
      console.log('METHOD::onClickSaveVideoTitle ~ event -> ', event)
      this.sharingDetails.video.title = this.videoTitle
      this.videoTitleModal = false
      this.videoTitle = ''
    },
    videoTitleTooltip(video) {
      if (video.title.length > 1) {
        return video.title
      }

      return 'Add Video Title'
    },
  },
}
</script>

<style lang="scss">
.cst-editor {
  @apply p-px;
  transition: all 0.2s ease;
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  input::placeholder {
    color: #a4a8ac;
    font-size: 0.875rem;
  }
  input::-webkit-input-placeholder {
    color: #a4a8ac;
  }
  .cst-textarea {
    min-height: 120px;
    @apply text-black-200;
    //padding:0 4px;
    @apply text-base;
    @apply rounded-t-lg;
  }
  &__toolbar {
    border-top: 1px solid #eff0f1;
    @apply rounded-b-lg;
  }
  &__media,
  &__link-preview {
    overflow: hidden;
    border-top: 1px solid #eff0f1;
    @apply rounded-b-lg;
  }
}
</style>
