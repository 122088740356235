var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feeds-left-pane__fixed-block"},[_c('div',{staticClass:"feeds-left-pane__fixed-block__content"},[_c('ul',{staticClass:"mb-0 feeds-left-pane__fixed-block__content__ul_header"},[_c('li',[_c('router-link',{attrs:{"to":{
            name: 'feeder_aggregate_view',
            params: { content_type: 'today' },
          }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-today_icon"}),_c('span',[_vm._v("Today")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'feeder_aggregate_view',
            params: { content_type: 'trending' },
          }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-trending_icon"}),_c('span',[_vm._v("Trending")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'feeder_aggregate_view',
            params: { content_type: 'starred' },
          }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-stared_icon"}),_c('span',[_vm._v("Starred")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'feeder_aggregate_view',
            params: { content_type: 'archived' },
          }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-Archive_icon"}),_c('span',[_vm._v("Archived")])])],1)]),_c('hr'),_c('feeder-groups'),_c('div',{staticClass:"hr_line"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }