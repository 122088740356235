<template>
  <div class="discovery_article_component">
    <!--<social-share></social-share>-->
    <!--<blog-share></blog-share>-->
    <article-preview></article-preview>
    <twitter-influencer></twitter-influencer>
    <similar-articles></similar-articles>
    <div
      class="component_inner pt-5"
      v-if="getFollowCuratedLoader.curated_view"
    >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div class="component_inner" v-else>
      <div class="top_filter_section bottom_border">
        <!-- showing the topic name and follow button. If the user follow it, we will storing it in the curated topics.

                Display topic name will be visible only if user clicks on it-->
        <template
          v-if="
            !getFilters.displayTopics &&
            !getSearchLoader.content.discoverySearch
          "
        >
          <button
            class="btn btn_topic_back"
            @click.prevent="viewDisplayTopics(true)"
            >Back</button
          >
        </template>
        <template v-if="getFilters.topic && $route.name !== 'articles'">
          <follow-topics></follow-topics>
          <div class="">
            <content-type :padding="false"></content-type>
          </div>
        </template>

        <!-- showing the search bar-->
        <template v-else>
          <div class="filter_section_inner width_800">
            <h3 class="filter_section_heading"
              >Find the top performing content?</h3
            >

            <!-- search bar -->
            <div
              class="search_input search_input_discovery with_icon_left d-flex align-items-center"
            >
              <div class="search_inner">
                <input
                  class="discoverySearchInput"
                  style="padding-top: 12px; padding-bottom: 12px"
                  id
                  @input="changeSearchKeyDown($event)"
                  v-model="getFilters.search"
                  type="text"
                  placeholder="Search for any topic or domain, e.g ‘marketing’ or ‘cnn.com’"
                  @keyup.enter="searchByKeyword(getFilters.search)"
                  data-cy="discovery_search_bar"
                />
                <button
                  class="search_btn input_icon search_icon gradient_color"
                  data-cy="discovery_search_button"
                  @click.prevent="searchByKeyword(getFilters.search)"
                >
                  <i class="icon-Search"></i>
                  <!--<beat-loader :color="'#ffffff'" :size="'1.125rem'" v-if="getSearchLoader.button.search"></beat-loader>-->
                </button>
                <!-- search option component added -->
                <!-- search suggestion dropdown -->
                <search-suggestions></search-suggestions>
              </div>
              <template v-if="getSearchContentType === 'article'">
                <search-assistant-table></search-assistant-table>
              </template>
            </div>

            <!-- default is content feed which is enabled and showing the content based on it-->
            <!--<template v-if="!getFilters.displayTopics && getSearchContentType === 'article'">-->
            <content-type></content-type>
            <!--</template>-->
          </div>
          <transition>
            <!--v-if="getRelatedTopics.length > 0 && !getDidYouMeanText && getPosts.length > 0"-->

            <template v-if="getTopicModule !== 'influencers'">
              <div
                v-if="
                  getRelatedTopics.length > 0 &&
                  !getDidYouMeanText &&
                  getPosts.length > 0
                "
                class="related_topic_list justify-content-center d-flex align-items-start"
              >
                <p>Related Topics:</p>
                <ul class="related_topic_list_inner">
                  <li
                    v-for="related_topic in getRelatedTopics.slice(0, 20)"
                    @click.prevent="searchByKeyword(related_topic)"
                    >{{ related_topic }}
                  </li>
                </ul>
              </div>
            </template>
          </transition>
        </template>
      </div>

      <!--display of the content-->
      <div
        class="ds_inner_pages"
        v-show="!getFilters.displayTopics && getTopicModule === 'content'"
      >
        <div class="ds_article_filter">
          <div class="d-flex align-items-center">
            <!-- accounts channel left filter -->
            <channel-filters></channel-filters>
            <search-article-filters></search-article-filters>
            <search-youtube-filters></search-youtube-filters>
            <search-twitter-filters></search-twitter-filters>
            <search-facebook-filters></search-facebook-filters>
          </div>
        </div>
        <!-- commenting for the router-view-->
        <template
          v-if="
            getSearchContentType === 'article' ||
            getSearchContentType === 'youtube' ||
            getSearchContentType === 'facebook' ||
            getSearchContentType === 'twitter'
          "
        >
          <router-view></router-view>
        </template>
      </div>

      <!-- insights section -->
      <div class="ds_inner_pages">
        <overview :module="'search'"></overview>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

import ListView from '@src/modules/discovery/components/common/ListView'
import GridView from '@src/modules/discovery/components/common/GridView'
import ContentType from '@src/modules/discovery/components/search/sub-components/ContentType'
import { explore } from '@src/modules/discovery/mixins/topics/explore'
import SearchAssistantTable from '@src/modules/discovery/components/search/SearchAssistantTable'
import SearchSuggestions from '@src/modules/discovery/components/search/sub-components/SearchSuggestions'

import FollowTopics from '@src/modules/discovery/components/search/sub-components/FollowTopics'
import CuratedTopicsView from '@src/modules/discovery/components/search/sub-components/CuratedTopicsView'
import { articlesTypes as articleTypes } from '@src/modules/discovery/store/mutation-types'

const ArticlePreview = () =>
  import('@src/modules/discovery/components/common/preview/ArticlePreview')
// const SocialShare = () => import('../../../../publish/components/posting/social/SocialShare')
// const BlogShare = () => import('../../../../publish/components/posting/blog/BlogPost')
const TwitterInfluencer = () =>
  import('@src/modules/discovery/components/common/TwitterInfluencer')
const InfluencerDetail = () =>
  import(
    '@src/modules/discovery/components/influencers/dialogs/InfluencerDetail'
  )
const SimilarArticles = () =>
  import('@src/modules/discovery/components/common/SimilarArticles')
const Dailymotion = () =>
  import('@src/modules/discovery/components/common/Dailymotion')
const Overview = () =>
  import('@src/modules/discovery/components/insights/Overview.vue')
const Influencers = () =>
  import('@src/modules/discovery/components/influencers/Influencers.vue')

// as they are loaded on the next go.

const ChannelFilters = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/ChannelFilters.vue'
  )
const SearchArticleFilters = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/filters/SearchArticleFilters.vue'
  )
const SearchYoutubeFilters = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/filters/SearchYoutubeFilters.vue'
  )
const SearchTwitterFilters = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/filters/SearchTwitterFilters.vue'
  )
const SearchFacebookFilters = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/filters/SearchFacebookFilters.vue'
  )

export default {
  components: {
    GridView,
    ListView,
    InfiniteLoading,
    ArticlePreview,
    SimilarArticles,
    TwitterInfluencer,
    InfluencerDetail,
    // SocialShare,
    // BlogShare,
    SearchAssistantTable,
    SearchSuggestions,
    Dailymotion,
    Overview,
    Influencers,
    FollowTopics,
    CuratedTopicsView,
    ChannelFilters,
    SearchArticleFilters,
    SearchYoutubeFilters,
    SearchTwitterFilters,
    SearchFacebookFilters,
    ContentType
  },

  data () {
    return {
      showSearchLoader: true,
      layout: 'grid',
      options: {
        inputPlaceholder: 'test keyword here...',
        value: ''
      },
      explore: explore
    }
  },
  created () {},
  computed: {
    ...mapGetters([
      'getSearchLoader',
      'getArticleLoader',
      'getScroll',
      'getArticlesPage',
      'getFilters',
      'getFilterFolderId',
      'getArticleSuggestion',
      'getSearchSuggestedkeywords',
      'getDidYouMeanText',
      'getCuratedTopicsList',
      'getTopicModule',
      'getInsightsLoader',
      'getSearchContentType',
      'getDiscoveryVideoLoader',
      'getVideoPaginationLoader',
      'getVideos',
      'getVideoScroll',
      'getVideoPage',
      'getDiscoveryTwitterLoader',
      'getTwitterPaginationLoader',
      'getTweetsContent',
      'getTwitterScroll',
      'getTwitterContentPage',
      'getDiscoveryFacebookLoader',
      'getFacebookPaginationLoader',
      'getFacebookPosts',
      'getFacebookScroll',
      'getFacebookPage',
      'getWorkspaces',
      'getRelatedTopics',
      'getTwitterAccounts',
      'getFollowCuratedLoader',
      'getSearchSuggestedTopics',
      'getActiveWorkspaceAddonsLifetime'
    ])
  },
  mounted () {},

  methods: {
    ...mapMutations(['SET_TOPIC_MODULE', 'SET_TOPIC_TYPE']),
    ...mapActions(['setArticleSuggestion', 'getContent', 'articles']),

    /*
     * Closing the search dropdown if the user click outside of the dropdown.
     * */
    closeSearchDropdown () {
      this.setArticleSuggestion(false)
    },
    /*
     * Change layout from Grid to Listview and Listview to Gridview.
     * */
    changeLayout (value) {
      console.log('method:changeLayout')
      console.log(value, this.layout)
      if (value !== this.layout) {
        this.layout = value
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Article:')

    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    next()
  },

  watch: {
    'getSearchSuggestedkeywords.length' (value) {
      if (value > 0) {
        this.dropdownArrowSelection()
      }
    },
    getInsightsLoader (value) {
      console.log('method: getShowChartsLoader', value)

      /* if (value === false) {
                                console.log('value has been changed, let set it out')
                                if (this.getTopicsFilters.topics.date.value) {
                                  let splitDates = this.getTopicsFilters.topics.date.value.split(' - ')

                                }
                              } */
    },
    'getFollowCuratedLoader.curated_view' (value) {
      /*      console.log('method: getFollowCuratedLoader.curated_view', value)

                                    let self = this
                                    let splitDates = this.getFilters.articles.date.value.split(' - ')

                                    if (value === false) {

                                    } */
    }
  }
}
</script>
