import { approvalStages } from './mutation-types'

const defaultApprovalStage = {
  approval_stage: {
    approvers: [],
    approve_option: 'anyone',
    notes: '',
    status: 'pending_approval',
    members: []
  },
  plan: null,
  type: ''
}

export default {
  state: {
    approval: {
      approval_stage: {
        approvers: [],
        approve_option: 'anyone',
        notes: '',
        status: 'pending_approval',
        members: []
      },
      plan: null,
      type: ''
    }
  },
  actions: {},
  mutations: {
    [approvalStages.RESET_APPROVAL_DATA] (state) {
      state.approval = JSON.parse(JSON.stringify(defaultApprovalStage))
    },
    [approvalStages.SET_APPROVAL_DATA] (state, data) {
      state.approval.approval_stage = data
    },
    [approvalStages.SET_APPROVAL_MEMBERS] (state, data) {
      state.approval.approval_stage.approvers = data
    },
    [approvalStages.SET_APPROVAL_PLAN] (state, plan) {
      state.approval.plan = plan
    },

    [approvalStages.SET_APPROVAL_TYPE] (state, type) {
      state.approval.type = type
    }
  },
  getters: {
    getApprovalStages: (state) => {
      return state.approval.approval_stage
    },
    getApprovalData: (state) => {
      return state.approval
    }
  }
}
