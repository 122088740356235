import moment from 'moment'

export default {
  state: {
    filters: {
      page: 0,
      limit: 20,
      sort: 'Engagement',
      type: 'All',
      date: {
        label: ' Last 30 days',
        value:
          moment.utc().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
          ' - ' +
          moment.utc().format('YYYY-MM-DDTHH:mm:ss')
      },
      region: {
        name: 'Worldwide',
        code: 'world'
      }
    },
    posts: [],
    scroll: true,

    content: 'topic',

    loader: {
      search: {
        button: {
          search: false
        },
        content: {
          facebookLoader: false,
          infiniteLoader: false
        }
      }
    }
  },
  actions: {
    setFacebookPosts ({ commit }, posts) {
      commit('setFacebookPosts', posts)
    },
    setFacebookScroll ({ commit }, status) {
      commit('setFacebookScroll', status)
    },
    setFacebookPaginationLoader ({ commit }, status) {
      commit('setFacebookPaginationLoader', status)
    },
    setDiscoveryFacebookLoader ({ commit }, status) {
      commit('setDiscoveryFacebookLoader', status)
    },
    setFacebookType ({ commit }, status) {
      commit('setFacebookType', status)
    },
    setFacebookSort ({ commit }, sort) {
      commit('setFacebookSort', sort)
    },
    setFacebookDate ({ commit }, date) {
      commit('setFacebookDate', date)
    },
    setFacebookPage ({ commit }, page) {
      commit('setFacebookPage', page)
    },
    resetFacebook ({ commit }) {
      commit('resetFacebook')
    },
    setFacebookContentType ({ commit }, status) {
      commit('setFacebookContentType', status)
    }
  },
  mutations: {
    setFacebookPosts (state, posts) {
      state.posts = posts
    },
    setFacebookScroll (state, status) {
      state.scroll = status
    },
    setFacebookPaginationLoader (state, status) {
      state.loader.search.content.infiniteLoader = status
    },
    setDiscoveryFacebookLoader (state, status) {
      state.loader.search.content.facebookLoader = status
    },
    setFacebookPage (state, page) {
      state.filters.page = page
    },
    setFacebookType (state, type) {
      state.filters.type = type
    },
    setFacebookSort (state, sort) {
      state.filters.sort = sort
    },
    setFacebookDate (state, date) {
      if (date == null) {
        // state.filters.date = {
        //   label: ' All Time',
        //   value: ''
        // }
        state.filters.date = {
          label: ' Last 30 days',
          value:
            moment().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
            ' - ' +
            moment().format('YYYY-MM-DDTHH:mm:ss')
        }
      } else {
        state.filters.date = date
      }
    },
    setFacebookContentType (state, type) {
      state.content = type
    },
    resetFacebook (state) {
      state.filters = {
        page: 0,
        limit: 20,
        sort: 'Engagement',
        type: 'All',
        date: {
          label: ' Last 30 days',
          value:
            moment.utc().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
            ' - ' +
            moment.utc().format('YYYY-MM-DDTHH:mm:ss')
        },
        region: {
          name: 'World',
          code: 'world'
        }
      }
      ;(state.posts = []), (state.scroll = true)

      state.content = 'topic'

      state.loader = {
        search: {
          button: {
            search: false
          },
          content: {
            facebookLoader: false,
            infiniteLoader: false
          }
        }
      }
    }
  },
  getters: {
    getFacebookContentFilter: (state) => {
      return state.filters
    },
    getFacebookPage: (state) => {
      return state.filters.page
    },

    getFacebookPosts: (state) => {
      return state.posts
    },
    getFacebookPaginationLoader: (state) => {
      return state.loader.search.content.infiniteLoader
    },
    getDiscoveryFacebookLoader: (state) => {
      return state.loader.search.content.facebookLoader
    },
    getFacebookScroll: (state) => {
      return state.scroll
    },

    getFacebookContentType: (state) => {
      return state.content
    }
  }
}
