var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dropdown-menu dropdown-menu-right feeds-no-cursor-pointer follow-feeds-dropdown-menu"},[(_vm.getFeederGroupList.length > 0 && !_vm.is_create_new_group)?[_c('li',{staticClass:"heading"},[_vm._v("Select Group")]),_c('ul',{staticClass:"inner follow-feeds-dropdown-menu__ul"},[_vm._l((_vm.getSortedFeederGroupList),function(group,index){return [_c('li',{key:index,staticClass:"list_item follow-feeds-dropdown-menu__ul__li"},[_c('span',{staticClass:"follow-feeds-dropdown-menu__ul__li__name"},[_vm._v(_vm._s(_vm.limitTextLength(group.name, 18)))]),(_vm.isAddedToFolderAlready(group))?[_c('button',{staticClass:"btn btn-studio-theme-transparent",class:{
                  'disable_click feeds-disable-click': group.is_remove,
                },on:{"click":function($event){return _vm.removeFeedFromGroup(group)}}},[_c('i',{staticClass:"fa fa-times mr-1"}),(group.is_remove && _vm.state_of_loader === 'removing')?[_vm._v("Removing")]:[_vm._v("Remove")],(group.is_remove && _vm.state_of_loader === 'removing')?_c('span',{staticClass:"feeder-group-inline-block ml-2"},[_c('clip-loader',{attrs:{"color":'#ea7070',"size":'14px'}})],1):_vm._e()],2)]:[_c('button',{staticClass:"btn btn-studio-theme-transparent",class:{ 'disable_click feeds-disable-click': group.is_add },on:{"click":function($event){return _vm.addFeedToGroup(group)}}},[_c('i',{staticClass:"fa fa-plus mr-1"}),(group.is_add && _vm.state_of_loader === 'adding')?[_vm._v("Adding")]:[_vm._v("Add")],(group.is_add && _vm.state_of_loader === 'adding')?_c('span',{staticClass:"feeder-group-inline-block ml-2"},[_c('clip-loader',{attrs:{"color":'#1ecb7b',"size":'14px'}})],1):_vm._e()],2)]],2)]})],2)]:[_c('li',{staticClass:"heading new_folder"},[_vm._v("Create New Group")]),_c('div',{staticClass:"dd_footer close_this test"},[_c('div',{staticClass:"search-bar-input"},[_c('div',{staticClass:"search-bar-inner"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getFeederGroupStore.name),expression:"getFeederGroupStore.name"}],attrs:{"type":"text","placeholder":"Enter Group Name e.g SEO, Marketing"},domProps:{"value":(_vm.getFeederGroupStore.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndStoreGroupFromDropdown.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.getFeederGroupStore, "name", $event.target.value)}}}),(_vm.state === 'group_exists')?_c('p',{staticClass:"error_message_about_group pt-1"},[_vm._v("A group with that name already exists")]):_vm._e(),_c('button',{staticClass:"clear-search-input"},[(
                  _vm.getFeederGroupStore.name &&
                  _vm.getFeederGroupStore.name.length >= 1
                )?_c('i',{staticClass:"cs-cross",on:{"click":_vm.clearFeederGroupText}}):_vm._e()])])]),_c('div',{staticClass:"follow-feeds-dropdown-menu__create_group_buttons mt-3"},[_c('button',{staticClass:"btn light_gray feeds-add-group-btn mr-2",class:{
              'input-field-error': _vm.getFeederGroupValidations.fields.name,
            },attrs:{"disabled":!_vm.getFeederGroupStore.name || _vm.loaders.folder_create},on:{"click":_vm.validateAndStoreGroupFromDropdown}},[_c('span',[_vm._v("Create")]),(_vm.loaders.folder_create)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'12px'}}):_vm._e()],1),(_vm.getFeederGroupList.length > 0)?_c('button',{staticClass:"btn btn_loader loader_right follow-feeds-dropdown-menu__create_group_buttons__cancel",on:{"click":function($event){_vm.is_create_new_group = false}}},[_c('span',[_vm._v("Cancel")])]):_vm._e()])])],_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_create_new_group),expression:"!is_create_new_group"}],staticClass:"dd_footer close_this"},[_c('button',{staticClass:"btn light_gray feeds-add-group-btn",on:{"click":function($event){$event.preventDefault();return _vm.isCreateNewGroup.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v(" Create New Group ")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }