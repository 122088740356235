<template>
  <div>
    <AnalyticPreview />

    <div class="top-navigation-section">
      <div class="top-navigation-left-section">
        <div v-if="getProfile.image">
          <img
            :src="getProfile.image"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
          />
        </div>
        <span class="welcome-text">
          <span class="welcome-text-name">
            Good {{ getDayText }}, {{ getProfile.firstname }}!
          </span>
          <!--          Welcome, <span class="welcome-text-name">{{ getProfile.full_name }}</span>-->
        </span>
      </div>
      <!-- need to add checks for the upgrade -->
      <div class="top-navigation-right-section">
        <span
          v-show="isUserTrailPlan() && hasPermission('can_see_subscription')"
          class="trial-remaining-days"
        >
          <template v-if="getProfile.trial_overs_in > 0">
            Trial ending in <strong>{{ getProfile.trial_overs_in }}</strong>
            <template v-if="getProfile.trial_overs_in > 1"> days </template>
            <template v-else> day </template>
          </template>
          <template v-else-if="getProfile.trial_overs_in === 0">
            Trial ending today!
          </template>
        </span>

        <div
          v-if="isUserTrailPlan() && hasPermission('can_see_subscription')"
          class="trial-upgrade-button"
        >
          <a
            href="javascript:;"
            class="btn btn-studio-theme-space ml-2"
            @click="
              $router.push({ query: { ...$route.query, special: true } }),
                $bvModal.show('upgrade-plan-dialog')
            "
            >Start Subscription</a
          >

          <a
            v-if="!isUserTrialFinished"
            href="javascript:;"
            class="btn btn-studio-theme-grey-space ml-2"
            @click="$bvModal.show('change-trial-plan-dialog')"
            >Change Trial Plan</a
          >
        </div>

        <template v-else-if="hasPermission('can_see_subscription')">
          <div
            v-if="
              getPlan.subscription.fastspring_name &&
              getPlan.subscription.fastspring_name.includes('no_package')
            "
            class="trial-upgrade-button"
          >
            <a
              href="javascript:;"
              class="btn btn-studio-theme"
              @click="$bvModal.show('upgrade-plan-dialog')"
              >Upgrade Plan</a
            >
            <a
              href="javascript:;"
              class="btn btn-studio-theme ml-2"
              @click="$bvModal.show('change-trial-plan-dialog')"
              >ChangeTrial Plan</a
            >
          </div>
          <div v-else-if="isPlanUpgradeable" class="trial-upgrade-button">
            <a
              href="javascript:;"
              class="btn btn-studio-theme"
              @click="$bvModal.show('upgrade-plan-dialog')"
              >Upgrade Plan</a
            >
            <a
              href="javascript:;"
              class="btn btn-studio-theme ml-2"
              @click="$bvModal.show('increase-limits-dialog')"
              >Increase Limits</a
            >
          </div>
          <div v-else class="trial-upgrade-button">
            <a
              href="javascript:;"
              class="btn btn-studio-theme"
              @click="$bvModal.show('increase-limits-dialog')"
              >Increase Limits</a
            >
          </div>
        </template>
      </div>
    </div>
    <div class="dashboard-actions-container">
      <div class="dashboard-actions-box">
        <div class="left-panel-dashboard-actions">
          <!--          <h3>Good {{getDayText}}, {{ getProfile.firstname }}</h3>-->
          <p>
            Welcome back, from below, you can select to create a new post, view
            your planned content or you can measure how your content is
            performing on social media networks.
          </p>
          <div class="dashboard-action-items-list">
            <div
              class="dashboard-action-item"
              data-cy="create-newpost"
              @click="changePublishType('Composer Social')"
            >
              <img
                src="../assets/img/dashboard/icons/create-a-new-post.svg"
                alt=""
              />
              <h4>Create a new post</h4>
              <p
                >Publish, schedule or queue to reach your audience at the
                perfect time.</p
              >
              <div class="divider-active-item"></div>
            </div>

            <div
              class="dashboard-action-item"
              data-cy="content-planner"
              @click.prevent="
                redirectLinks(
                  defaultView === 'list'
                    ? 'planner_list_v2'
                    : defaultView === 'feed'
                    ? 'planner_feed_v2'
                    : defaultView === 'calendar'
                    ? 'planner_calendar_v2'
                    : 'planner_v2'
                )
              "
            >
              <img
                src="../assets/img/dashboard/icons/view-content-planner.svg"
                alt=""
              />
              <h4>View Content Planner</h4>
              <p
                >Plan and collaborate with your teams on an intuitive editorial
                calendar.</p
              >
              <div class="divider-active-item"></div>
            </div>

            <div
              class="dashboard-action-item"
              @click.prevent="redirectLinks('analytics_overview_v3')"
            >
              <img
                src="../assets/img/dashboard/icons/view-analytics.svg"
                alt=""
              />
              <h4>View Analytics</h4>
              <p>Track KPIs and measure performance across all the channels.</p>
              <div class="divider-active-item"></div>
            </div>
          </div>
        </div>
        <div class="right-panel-dashboard-actions"> </div>
      </div>
    </div>

    <div class="dashboard-actions-container">
      <div class="dashboard-module-statistics">
        <div class="dashboard-module-statistics-item">
          <div
            class="dashboard-module-statistics-item-content first-child-item"
          >
            <div class="stats-content-box">
              <div class="top-row-content">
                <div class="top-content-row-left-section">
                  <img
                    src="../assets/img/dashboard/icons/content-publishing-stats.svg"
                    alt=""
                  />
                  <h5>Content Publishing</h5>
                </div>
                <div class="top-content-row-right-section">
                  <b-dropdown
                    id="dropdown-right"
                    variant="studio-theme"
                    class="studio-theme-dropdown mr-2"
                    no-caret
                  >
                    <template slot="button-content">
                      <span class="capitalize_text">{{
                        contentPublishingDateFilterText
                      }}</span>
                      <i class="icon-dropdown-cs"></i>
                    </template>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeContentPublishingDate('Today')"
                      >Today
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeContentPublishingDate('Yesterday')"
                    >
                      Yesterday
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="
                        changeContentPublishingDate('Last 7 Days')
                      "
                    >
                      Last 7 Days
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeContentPublishingDate('This month')"
                      >This month
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeContentPublishingDate('Last month')"
                      >Last month
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeContentPublishingDate('This year')"
                      >This year
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="stats-main-content">
                <div
                  v-if="contentPublishingLoader"
                  class="dashboard-statistics-loader mt-4 mb-1"
                >
                  <clip-loader :color="'#436aff'" :size="'1rem'" />
                </div>
                <template v-else>
                  <div class="stats-box stats-scheduled">
                    <h3>{{ contentPublishingData['scheduled'] }}</h3>
                    <p>Scheduled</p>
                    <a
                      style="cursor: pointer"
                      @click.prevent="
                        redirectToPlanner(
                          'scheduled',
                          contentPublishingDateFilterText
                        )
                      "
                      >View ></a
                    >
                  </div>
                  <div class="stats-box stats-published">
                    <h3>{{ contentPublishingData['published'] }}</h3>
                    <p>Published</p>
                    <a
                      style="cursor: pointer"
                      @click.prevent="
                        redirectToPlanner(
                          'published',
                          contentPublishingDateFilterText
                        )
                      "
                      >View ></a
                    >
                  </div>
                  <div class="stats-box stats-failed partial">
                    <h3>{{ contentPublishingData['partial'] }}</h3>
                    <p>Partially Failed</p>
                    <a
                      style="cursor: pointer"
                      @click.prevent="
                        redirectToPlanner(
                          'partial',
                          contentPublishingDateFilterText
                        )
                      "
                      >View ></a
                    >
                  </div>
                  <div class="stats-box stats-failed">
                    <h3>{{ contentPublishingData['failed'] }}</h3>
                    <p>Failed</p>
                    <a
                      style="cursor: pointer"
                      @click.prevent="
                        redirectToPlanner(
                          'failed',
                          contentPublishingDateFilterText
                        )
                      "
                      >View ></a
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-module-statistics-item">
          <div class="dashboard-module-statistics-item-content">
            <div class="stats-content-box">
              <div class="top-row-content">
                <div class="top-content-row-left-section">
                  <img
                    src="../assets/img/dashboard/icons/inbox-stats.svg"
                    alt=""
                  />
                  <h5>Social Inbox</h5>
                </div>
              </div>
              <div v-if="!hasSocialInboxAccess" class="stats-main-unlocked">
                <img
                  class="mt-0"
                  src="../assets/img/states/no-inbox-feature-access.svg"
                  alt=""
                />
                <p>You don't have access to Inbox feature.</p>
                <a
                  v-if="
                    isUserTrailPlan() && hasPermission('can_see_subscription')
                  "
                  href="javascript:;"
                  class="btn btn-studio-theme-space ml-2"
                  @click="
                    $router.push({ query: { ...$route.query, special: true } }),
                      $bvModal.show('upgrade-plan-dialog')
                  "
                  >Start Subscription</a
                >
                <router-link
                  v-else
                  :to="{
                    name: 'upgrade_lifetime_addon',
                    params: {
                      workspace: getWorkspaces.activeWorkspace.slug,
                    },
                  }"
                  class="btn btn-studio-theme"
                  >Purchase Inbox Add-on
                </router-link>
              </div>
              <div v-else class="stats-main-content flex-column">
                <div class="stats-single-row single-row-inbox">
                  <div class="stats-items-list">
                    <div
                      v-if="inboxAnalyticsLoader"
                      class="dashboard-statistics-loader"
                    >
                      <clip-loader :color="'#436aff'" :size="'1rem'" />
                    </div>
                    <template v-else>
                      <div class="stats-items-box">
                        <div class="stats-single-row-items">
                          <h3>{{ inboxData['UNASSIGNED'] }}</h3>
                          <p>Unassigned</p>
                        </div>
                        <div class="stats-single-row-items">
                          <h3>{{ inboxData['ASSIGNED'] }}</h3>
                          <p>Assigned</p>
                        </div>
                        <div class="stats-single-row-items">
                          <h3>{{ inboxData['MARKED_AS_DONE'] }}</h3>
                          <p>Marked as done</p>
                        </div>
                      </div>
                      <div class="stats-link stats-go-to-inbox">
                        <a href="javascript:;" @click.prevent="redirectToInbox"
                          >Go to Inbox ></a
                        >
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-module-statistics-item">
          <div class="dashboard-module-statistics-item-content last-child-item">
            <div class="stats-content-box">
              <div class="top-row-content">
                <div class="top-content-row-left-section">
                  <img
                    src="../assets/img/dashboard/icons/content-approvals-stats.svg"
                    alt=""
                  />
                  <h5>Content Approvals</h5>
                </div>
                <div class="top-content-row-right-section">
                  <b-dropdown
                    id="dropdown-left"
                    variant="studio-theme"
                    class="studio-theme-dropdown mr-2 negate-dropdown-left-2rem"
                    no-caret
                  >
                    <template slot="button-content">
                      <span class="capitalize_text">{{
                        approvalPublishingDateFilterText
                      }}</span>
                      <i class="icon-dropdown-cs"></i>
                    </template>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeApprovalPublishingDate('Today')"
                      >Today
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeApprovalPublishingDate('Yesterday')"
                    >
                      Yesterday
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="
                        changeApprovalPublishingDate('Last 7 Days')
                      "
                    >
                      Last 7 Days
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="
                        changeApprovalPublishingDate('This month')
                      "
                    >
                      This month
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="
                        changeApprovalPublishingDate('Last month')
                      "
                    >
                      Last month
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-theme"
                      @click.prevent="changeApprovalPublishingDate('This year')"
                      >This year
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="stats-main-content flex-column">
                <div class="stats-single-row single-row-content-approvals">
                  <div class="stats-items-list">
                    <div
                      v-if="contentAnalyticsLoader"
                      class="dashboard-statistics-loader"
                    >
                      <clip-loader :color="'#26cb85'" :size="'1rem'" />
                    </div>
                    <template v-else>
                      <div class="stats-items-box">
                        <div class="stats-single-row-items">
                          <h3>{{ approvalPublishingData['review'] }}</h3>
                          <p>Under Review</p>
                        </div>
                        <div class="stats-single-row-items">
                          <h3>{{ approvalPublishingData['missed'] }}</h3>
                          <p>Missed Review</p>
                        </div>
                        <div class="stats-single-row-items">
                          <h3>{{ approvalPublishingData['rejected'] }}</h3>
                          <p>Rejected</p>
                        </div>
                      </div>
                      <div class="stats-link stats-review-posts">
                        <a
                          style="cursor: pointer"
                          @click.prevent="
                            redirectToPlanner(
                              'approval',
                              approvalPublishingDateFilterText
                            )
                          "
                          >Review Posts ></a
                        >
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-actions-container">
      <div class="dashboard-analytics-section">
        <div class="dashboard-analytics-left-section">
          <div class="dashboard-analytics-chart-section">
            <h4>Engagement Across Networks</h4>
            <p class="pb-3">
              Determine how your content is performing across different social
              networks during last 30 days.
            </p>
            <div
              v-if="graphLoader"
              class="dashboard-analytics-chart-section-loader"
            >
              <clip-loader :color="'#436aff'" :size="'1rem'"></clip-loader>
            </div>
            <template v-else>
              <EngagementAcrossNetworks
                :engagement_with_durations="performanceData"
              ></EngagementAcrossNetworks>
            </template>
          </div>
        </div>
        <div class="dashboard-analytics-right-section">
          <div class="dashboard-analytics-piechart-section">
            <h4>Comparison Across Networks</h4>
            <div
              class="dashboard-comparison-networks dashboard-piechart-margin"
              :class="{
                'no-data-available-blur relative':
                  isComparisonChartDataAvailable && !graphLoader,
              }"
            >
              <div
                v-if="graphLoader"
                class="dashboard-comparison-networks-loader"
              >
                <clip-loader :color="'#436aff'" :size="'1rem'"></clip-loader>
              </div>
              <template v-else>
                <ComparisonAcrossNetworks
                  :comparison-data="comparisonData"
                ></ComparisonAcrossNetworks>
                <div class="comparison-network-statistics">
                  <ul>
                    <li>
                      <div class="social-comparison-text">
                        <span
                          class="comparison-metrics facebook-metrics"
                        />Facebook
                      </div>
                      <div class="comparison-metrics-percentage"
                        >{{ comparisonData['facebook'] }}%
                      </div>
                    </li>
                    <li>
                      <div class="social-comparison-text">
                        <span
                          class="comparison-metrics instagram-metrics"
                        />Instagram
                      </div>
                      <div class="comparison-metrics-percentage"
                        >{{ comparisonData['instagram'] }}%
                      </div>
                    </li>
                    <li>
                      <div class="social-comparison-text">
                        <span
                          class="comparison-metrics twitter-metrics"
                        />Twitter
                      </div>
                      <div class="comparison-metrics-percentage"
                        >{{ comparisonData['twitter'] }}%
                      </div>
                    </li>
                    <li>
                      <div class="social-comparison-text">
                        <span
                          class="comparison-metrics linkedin-metrics"
                        />LinkedIn
                      </div>
                      <div class="comparison-metrics-percentage">
                        {{ comparisonData['linkedin'] }}%
                      </div>
                    </li>

                    <!--  <li >
                                            <div class="social-comparison-text">
                                              <span class="comparison-metrics pinterest-metrics"/>Pinterest
                                            </div>
                                            <div class="comparison-metrics-percentage">{{ comparisonData['pinterest'] }}%</div>
                                          </li>-->
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="dashboard-actions-container">
      <div class="dashboard-top-posts">
        <div class="dashboard-top-row">
          <div class="dashboard-top-posts-left-section">
            <h4> Top Posts </h4>
            <p>
              View your top performing posts across different social media
              networks during last 30 days.
            </p>
          </div>
          <div class="dashboard-top-posts-right-section">
            <b-dropdown
              id="dropdown-right"
              variant="studio-theme"
              class="studio-theme-dropdown mr-2"
              no-caret
            >
              <template slot="button-content">
                <span class="capitalize_text">Top {{ post_count }} Posts</span>
                <i class="icon-dropdown-cs"></i>
              </template>
              <b-dropdown-item variant="studio-theme" @click="post_count = 5"
                >Top 5 Posts
              </b-dropdown-item>
              <b-dropdown-item variant="studio-theme" @click="post_count = 10"
                >Top 10 Posts
              </b-dropdown-item>
              <b-dropdown-item variant="studio-theme" @click="post_count = 15"
                >Top 15 Posts
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div class="section table_container">
          <div class="d-flex flex-row table_head">
            <div class="col-2">Accounts</div>

            <div class="col-2">Posts Image</div>

            <div class="col-4">Posts</div>

            <div class="col-2 pl-5 text-center">Engagements</div>

            <div class="col-1 text-center">Comments </div>
            <div class="col-1 text-center">Shares</div>
          </div>

          <template v-for="(post, index) in topPosts">
            <template v-if="index < post_count">
              <template v-if="post.network_type === 'facebook'">
                <DashboardFacebookPostView
                  :key="index"
                  class="cursor-pointer"
                  :post="post"
                ></DashboardFacebookPostView>
              </template>
              <template v-else-if="post.network_type === 'twitter'">
                <DashboardTwitterPostView
                  :key="index"
                  class="cursor-pointer"
                  :post="post"
                ></DashboardTwitterPostView>
              </template>
              <template v-else-if="post.network_type === 'pinterest'">
                <DashboardPinterestPostView
                  :key="index"
                  class="cursor-pointer"
                  :post="post"
                ></DashboardPinterestPostView>
              </template>
              <template v-else-if="post.network_type === 'linkedin'">
                <DashboardLinkedinPostView
                  :key="index"
                  class="cursor-pointer"
                  :post="post"
                ></DashboardLinkedinPostView>
              </template>
              <template v-else-if="post.network_type === 'instagram'">
                <DashboardInstagramPostView
                  :key="index"
                  class="cursor-pointer"
                  :post="post"
                ></DashboardInstagramPostView>
              </template>
            </template>
          </template>
          <template v-if="graphLoader">
            <div class="not_found_text">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <clip-loader :color="'#436aff'" :size="'1rem'" />
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="topPosts.length === 0">
            <div class="not_found_text">
              <div class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="@assets/img/no_data_images/no_post_found.svg"
                      alt=""
                    />
                  </div>
                  <p>You have not published any posts yet.</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>

    <div class="dashboard-actions-container">
      <div class="useful-links-container">
        <div class="useful-links-left-section">
          <h4 class="useful-content-heading">Useful Links</h4>
          <div class="useful-links-block-items">
            <div class="useful-links-block">
              <h4
                ><span class="useful-links-block-image"
                  ><img
                    src="../assets/img/dashboard/icons/your-account.svg"
                    alt="" /></span
                >Your Account</h4
              >
              <ul class="useful-links-items">
                <router-link :to="{ name: 'profile' }"
                  ><span><i class="icon-profile-cs"></i>Profile Settings</span>
                </router-link>
                <router-link
                  v-if="hasPermission('can_see_subscription')"
                  :to="{ name: 'plan' }"
                  ><span><i class="icon-plan-cs"></i>Billing & Plan</span>
                </router-link>
                <router-link
                  v-if="hasPermission('can_view_team')"
                  :to="{ name: 'team' }"
                  ><span><i class="icon-team-member-cs"></i>Team Members</span>
                </router-link>
                <router-link
                  v-if="hasPermission('can_access_workspace_setting')"
                  :to="{ name: 'basicSetting' }"
                  ><span
                    ><i class="icon-settings-cs"></i>Workspace Settings</span
                  >
                </router-link>
                <router-link
                  v-if="isAnalyticsEnabledForUser"
                  :to="{ name: 'reportsettings' }"
                  ><span><i class="icon-reports_icon"></i>Report Settings</span>
                </router-link>
              </ul>
            </div>
            <div class="useful-links-block">
              <h4
                ><span class="useful-links-block-image"
                  ><img
                    src="../assets/img/dashboard/icons/connect-profile.svg"
                    alt="" /></span
                >Connect Profile</h4
              >
              <ul class="useful-links-items">
                <router-link :to="{ name: 'social', hash: '#facebook' }"
                  ><span><i class="icon-facebook_icon"></i>Facebook</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#instagram' }"
                  ><span><i class="icon-instagram_icon"></i>Instagram</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#twitter' }"
                  ><span><i class="icon-twitter_icon"></i>Twitter</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#linkedin' }"
                  ><span><i class="icon-linkedin_icon"></i>LinkedIn</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#pinterest' }"
                  ><span><i class="icon-pinterest_icon"></i>Pinterest</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#gmb' }"
                  ><span><i class="icon-GMB"></i>GMB</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#tumblr' }"
                  ><span><i class="cs-tumblr"></i>Tumblr</span>
                </router-link>
                <router-link :to="{ name: 'social', hash: '#tiktok' }"
                  ><span>
                    <i class="cs-tiktok">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style="width: 11px"
                      >
                        <path
                          id="svg_1"
                          fill="#00000"
                          d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                          class="selected"
                          fill-opacity="1"
                        />
                      </svg> </i
                    >Tiktok</span
                  >
                </router-link>
                <router-link :to="{ name: 'social', hash: '#youtube' }"
                  ><span><i class="icon-Youtube_icon"></i>Youtube</span>
                </router-link>
              </ul>
            </div>
            <div class="useful-links-block">
              <h4
                ><span class="useful-links-block-image"
                  ><img
                    src="../assets/img/dashboard/icons/integrations.svg"
                    alt="" /></span
                >Integrations</h4
              >
              <ul class="useful-links-items">
                <router-link :to="{ name: 'integrations', hash: '#bitly' }"
                  ><span><i class="icon-bitly"></i>Bit.ly</span></router-link
                >
                <router-link :to="{ name: 'integrations', hash: '#replug' }"
                  ><span><i class="cs-replug"></i>Replug</span></router-link
                >
                <router-link :to="{ name: 'integrations', hash: '#feedly' }"
                  ><span><i class="icon-feedly"></i>Feedly</span></router-link
                >
                <router-link :to="{ name: 'integrations', hash: '#pocket' }"
                  ><span><i class="icon-pocket"></i>Pocket</span>
                </router-link>
              </ul>
            </div>
            <div class="useful-links-block">
              <h4
                ><span class="useful-links-block-image"
                  ><img
                    src="../assets/img/dashboard/icons/power-users.svg"
                    alt="" /></span
                >Power Users</h4
              >
              <ul class="useful-links-items">
                <a
                  target="_blank"
                  href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj8mL7frOjsAhWKVRUIHX6BACIQFjAAegQIBBAC&url=https%3A%2F%2Fchrome.google.com%2Fwebstore%2Fdetail%2Fcontentstudio-social-medi%2Fdmcddloohffhmjngiieikfifpfneadcc%3Fhl%3Den&usg=AOvVaw0DJcGGKiP90lkY5rVylVIF"
                  ><span><i class="icon-Chrome"></i>Chrome Extension</span>
                </a>
                <!--<router-link :to="{name: 'dashboard'}"><span><i class="icon-settings-cs"></i>iOS/Android Apps</span>
                                </router-link>-->
                <router-link :to="{ name: 'miscellaneous' }"
                  ><span><i class="icon-utm"></i>UTM Parameters</span>
                </router-link>
                <router-link :to="{ name: 'miscellaneous' }"
                  ><span><i class="icon-Hashtag"></i>Hashtags</span>
                </router-link>
                <router-link
                  v-if="hasSocialInboxAccess"
                  :to="{ name: 'miscellaneous' }"
                  ><span><i class="icon-Msg-Tagging"></i>Messaging Tags</span>
                </router-link>
              </ul>
            </div>
          </div>
        </div>
        <div class="useful-links-right-section">
          <img src="../assets/img/dashboard/icons/help-support.svg" alt="" />
          <h4>Help & Support</h4>
          <p>Need help? Get in touch with us.</p>
          <div class="useful-links-buttons">
            <a
              href="https://docs.contentstudio.io"
              target="_blank"
              class="btn btn-studio-theme-transparent-grey"
              >Knowledgebase</a
            >
            <span
              class="btn btn-studio-theme-transparent-grey"
              @click.prevent="fireOpenHelpWidget"
              >Contact Support</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticPreview from '@src/modules/analytics/components/common/AnalyticPreview'

import { mapGetters } from 'vuex'
import http from '@src/modules/common/lib/http-common'
import {
  fetchContentApprovalStatsURL,
  fetchContentPublishingStatsURL,
  fetchInboxStatsURL,
  fetchDashboardAnalyticsURL,
} from '@src/modules/analytics/config/api-utils'
import moment from 'moment'
import { EventBus } from '@src/modules/common/lib/event-bus'
import ComparisonAcrossNetworks from './dashboard-charts/ComparisonAcrossNetworks'
import EngagementAcrossNetworks from './dashboard-charts/EngagementAcrossNetworks'
import DashboardFacebookPostView from './posts/DashboardFacebookPostView'
import DashboardTwitterPostView from './posts/DashboardTwitterPostView'
import DashboardLinkedinPostView from './posts/DashboardLinkedinPostView'
import DashboardPinterestPostView from './posts/DashboardPinterestPostView'
import DashboardInstagramPostView from './posts/DashboardInstagramPostView'

export default {
  components: {
    ComparisonAcrossNetworks,
    EngagementAcrossNetworks,
    DashboardFacebookPostView,
    DashboardTwitterPostView,
    DashboardInstagramPostView,
    DashboardPinterestPostView,
    DashboardLinkedinPostView,
    AnalyticPreview,
  },
  data() {
    return {
      // loaders
      contentPublishingLoader: false,
      inboxAnalyticsLoader: true,
      contentAnalyticsLoader: true,

      // data
      contentPublishingData: {
        scheduled: 0,
        published: 0,
        partial: 0,
        failed: 0,
      },
      contentPublishingDateFilterText: 'Today',

      approvalPublishingData: {
        review: 0,
        rejected: 0,
        missed: 0,
      },
      approvalPublishingDateFilterText: 'Today',

      inboxData: {
        UNASSIGNED: 0,
        ASSIGNED: 0,
        MARKED_AS_DONE: 0,
      },

      // graphs
      graphLoader: true,
      comparisonData: {
        facebook: 0,
        twitter: 0,
        instagram: 0,
        pinterest: 0,
        linkedin: 0,
      },
      performanceData: {},
      topPosts: [],
      post_count: 5,
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'getWorkspaces',
      'isAnalyticsPlanAllowed',
      'getGetstartedStatus',
    ]),
    getDayText() {
      const hr = moment.tz(this.getWorkspaces.activeWorkspace.timezone).hour()
      if (hr >= 5 && hr < 12) {
        return 'Morning'
      } else if (hr >= 12 && hr <= 1) {
        return 'Noon'
      } else if (hr >= 2 && hr <= 16) {
        return 'Afternoon'
      }
      return 'Evening'
    },
    hasSocialInboxAccess() {
      /* eslint-disable no-prototype-builtins */
      if (
        this.getActiveWorkspaceAddonsLifetime.hasOwnProperty(
          'social_inbox_beta'
        ) &&
        this.getActiveWorkspaceAddonsLifetime.social_inbox_beta
      ) {
        return true
      }
      if (
        this.getActiveWorkspaceAddonsLifetime.hasOwnProperty('social_inbox') &&
        this.getActiveWorkspaceAddonsLifetime.social_inbox
      ) {
        return true
      }
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      return false
    },
    isUserTrialFinished() {
      return this.getProfile.state === 'trial_finished'
    },
    defaultView() {
      return this.getProfile.planner_default_view
    },
    isPlanUpgradeable() {
      const listOfPlans = [
        'business-large',
        'lifetime',
        'contentstudio-business-small-lifetime',
        'contentstudio-business-medium-lifetime',
        'contentstudio-bundle-lifetime',
        'business-agency-large-annual-custom',
        'business-agency-small-bf',
        'business-large-annual',
        'jvzoo_agency_upgrade',
        'contentstudio-enterprise-yearly-plan',
        'bruce-contentstudio-large-agency-bee-lifetime',
        'bruce-contentstudio-pro-lifetime',
        'bruce-contentstudio-business-agency-small-lifetime',
        'bruce-contentstudio-business-agency-medium-lifetime',
        'bruce-contentstudio-business-agency-large-lifetime',
        'contentstudio-covid-professional-lifetime',
        'contentstudio-covid-business-agency-small-lifetime',
        'contentstudio-covid-business-agency-medium-lifetime',
        'contentstudio-covid-business-agency-large-lifetime',
        'contentstudio-covid-professional-with-addons-lifetime',
        'contentstudio-starter-lifetime-unlimited-automations',
      ]
      if (this.$store.getters.getPlan.subscription.fastspring_name) {
        if (
          listOfPlans.indexOf(
            this.$store.getters.getPlan.subscription.fastspring_name
          ) >= 0
        ) {
          return false
        }
      }
      return true
    },

    isAnalyticsEnabledForUser() {
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      return this.getActiveWorkspaceAddonsLifetime.analytics
    },
    isComparisonChartDataAvailable() {
      return (
        this.comparisonData.facebook +
          this.comparisonData.twitter +
          this.comparisonData.instagram +
          this.comparisonData.linkedin ===
        0
      )
    },
  },
  watch: {
    'getActiveWorkspace._id'(val) {
      if (val.length > 0) {
        this.getContentPublishingStats()
        this.getApprovalPublishingStats()
        this.getInboxStats()
        this.fetchAnalytics()
      }
    },
  },
  created() {
    const googleGTag = document.createElement('script')
    googleGTag.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=AW-701937701'
    )
    document.head.appendChild(googleGTag)

    this.getContentPublishingStats()
    this.getApprovalPublishingStats()
    this.getInboxStats()
    this.fetchAnalytics()
    const conversionScriptTag = document.createElement('script')
    conversionScriptTag.type = 'text/javascript'
    conversionScriptTag.innerHTML =
      'window.dataLayer = window.dataLayer || [];\n' +
      '  function gtag(){dataLayer.push(arguments);}\n' +
      "  gtag('js', new Date());\n" +
      "  gtag('config', 'AW-701937701');  gtag('event', 'conversion', {'send_to': 'AW-701937701/7Xy0CMrgpYkCEKXw2s4C'});"

    document.head.appendChild(conversionScriptTag)
  },
  mounted() {
    console.log('LLL', this.getWorkspaces)
    // this.getWorkspaces.activeWorkspace.members[0].user_id
  },
  methods: {
    isActiveUserSuperAdmin() {
      const teamMembers = this.getWorkspaces.activeWorkspace.members
      for (var i = 0; i < teamMembers.length; i++) {
        const member = teamMembers[i]
        if (member.user && this.getProfile.email === member.user.email) {
          return member.role === 'super_admin'
        }
      }
      return false
    },
    getContentPublishingStats() {
      console.debug('Method:getContentPublishingStats')
      http
        .post(fetchContentPublishingStatsURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          date_range: this.getFilterDate(this.contentPublishingDateFilterText),
        })
        .then((res) => {
          if (res.data.status) this.contentPublishingData = res.data.stats
          this.contentPublishingLoader = false
        })
        .catch(() => {
          console.debug('exception in getContentPublishingStats')
          this.contentPublishingLoader = false
        })
    },
    changeContentPublishingDate(date) {
      this.contentPublishingDateFilterText = date
      this.contentPublishingLoader = true
      this.getContentPublishingStats()
    },

    getApprovalPublishingStats() {
      console.debug('Method:getApprovalPublishingStats')
      http
        .post(fetchContentApprovalStatsURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          date_range: this.getFilterDate(this.approvalPublishingDateFilterText),
        })
        .then((res) => {
          if (res.data.status) this.approvalPublishingData = res.data.stats
          this.contentAnalyticsLoader = false
        })
        .catch(() => {
          console.debug('exception in getApprovalPublishingStats')
          this.contentAnalyticsLoader = false
        })
    },
    changeApprovalPublishingDate(date) {
      this.approvalPublishingDateFilterText = date
      this.contentAnalyticsLoader = true
      this.getApprovalPublishingStats()
    },

    getInboxStats() {
      this.inboxAnalyticsLoader = true
      console.debug('Method:getInboxStats')
      http
        .post(fetchInboxStatsURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) this.inboxData = res.data.stats
          this.inboxAnalyticsLoader = false
        })
        .catch(() => {
          console.debug('exception in getInboxStats')
          this.inboxAnalyticsLoader = false
        })
    },

    getFilterDate(text) {
      switch (text) {
        case 'Today':
          return (
            moment().format('MMM DD, YYYY') +
            ' - ' +
            moment().format('MMM DD, YYYY')
          )
        case 'Yesterday':
          return (
            moment().subtract(1, 'days').format('MMM DD, YYYY') +
            ' - ' +
            moment().subtract(1, 'days').format('MMM DD, YYYY')
          )
        case 'Last 7 Days':
          return (
            moment().subtract(6, 'days').format('MMM DD, YYYY') +
            ' - ' +
            moment().format('MMMM DD, YYYY')
          )
        case 'This month':
          return (
            moment().startOf('month').format('MMM DD, YYYY') +
            ' - ' +
            moment().endOf('month').format('MMM DD, YYYY')
          )
        case 'Last month':
          return (
            moment()
              .subtract(1, 'month')
              .startOf('month')
              .format('MMM DD, YYYY') +
            ' - ' +
            moment().subtract(1, 'month').endOf('month').format('MMM DD, YYYY')
          )
        case 'This year':
          return (
            moment().startOf('year').format('MMM DD, YYYY') +
            ' - ' +
            moment().endOf('year').format('MMM DD, YYYY')
          )
      }
      return null
    },

    // graphs

    fetchAnalytics() {
      this.graphLoader = true
      console.debug('Method:fetchAnalytics')
      http
        .post(fetchDashboardAnalyticsURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            this.comparisonData = res.data.comparison
            this.performanceData = res.data.performance
            this.topPosts = res.data.top_posts
          }
          this.graphLoader = false
        })
        .catch(() => {
          console.debug('exception in fetchDashboardAnalyticsURL')
          this.graphLoader = false
        })
    },

    // redirects
    redirectLinks(name) {
      this.$router.push({ name: name })
    },
    redirectToPlanner(name, date) {
      switch (name) {
        case 'scheduled':
        case 'published':
        case 'partial':
        case 'failed':
          this.$router.push({
            name: 'planner_list_v2',
            query: { statuses: name, date: this.getFilterDate(date) },
          })
          break
        case 'approval':
          this.$router.push({
            name: 'planner_list_v2',
            query: {
              statuses: 'rejected,under_review,missed_review',
              date: this.getFilterDate(date),
            },
          })
          break
      }
    },
    redirectToInbox() {
      if (!this.hasSocialInboxAccess) {
        this.$router.push({
          name: 'upgrade_lifetime_addon',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
        })
      } else this.$router.push({ name: 'inbox' })
    },
    fireOpenHelpWidget() {
      EventBus.$emit('open-help-widget')
    },
  },
}
</script>

<style lang="less">
@import (reference) '../assets/less/basic/transitions';
.stats-main-content .partial {
  background: #db818170 !important;
}
</style>
