<template>
  <div class="seo_main_block">
    <div class="d-flex align-items-center clear">
      <h2 class="title">SEO Toolbox</h2>
    </div>

    <div class="inner">
      <div class="header">
        <div class="progress_bar_outer">
          <HelperProgress :main_bar="main_bar" />
        </div>
        <div class="mt-3">
          <HelperContent
            :blog-post="blogPost"
            :emotions="emotions"
            :heading-count="headingCount"
            :media-count="mediaCount"
            :sentiments="sentiments"
            :read-time="readTime"
            :read-time-text="readTimeText"
          />
        </div>
      </div>
      <div class="tabs_outer mt-3">
        <b-tabs pills nav-class="rounded mb-3 px-1.5 mx-3">
          <b-tab title="SEO Toolkit" active title-item-class="mytabs-costum">
            <div class="mt-2 panel panel-default target_keywords mx-3">
              <div
                id="accordion"
                class="panel-group pl-0"
                data-collapse-type="manual"
              >
                <div class="title_sec_keyword input_field mt-0 pt-2">
                  <label class="text-base">Target Keyword</label>
                  <input
                    v-model="getBlogSubmissionSelection.keyword"
                    type="text"
                    placeholder="Add your main keyword here..."
                  />
                </div>

                <!-- // target Url /// -->
                <div class="title_sec_keyword input_field mt-0">
                  <label>Target URL</label>
                  <input
                    v-model="getBlogSubmissionSelection.url"
                    type="text"
                    placeholder="Add your target URL here"
                  />
                </div>
              </div>
            </div>
            <div id="accordion" class="panel-group" data-collapse-type="manual">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_title"
                    >
                      <span
                        ><i class="fa fa-angle-right"></i> Page Title and
                        Description</span
                      >
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <!-- Total  {{Math.round((title_bar + desc_bar + title_Nub) * 100)}}
                    title_bar  {{title_bar}}
                    desc_bar  {{desc_bar}}
                    title_Nub  {{title_Nub}} -->
                          <div
                            class="limit_add"
                            :style="{
                              width:
                                Math.round(
                                  (title_bar + desc_bar + title_Nub) * 100
                                ) + '%',
                            }"
                          ></div>
                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_title"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) < 1
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword in the <strong>Page Title</strong>.</p
                        >
                      </li>
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) >=
                            1 &&
                          !checkStringExist(
                            getBlogSubmissionSelection.keyword.toLowerCase(),
                            getBlogTitle()
                          ) > 0
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword more often in your Page Title.</p
                        >
                      </li>
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) >=
                            1 &&
                          checkStringExist(
                            getBlogSubmissionSelection.keyword.toLowerCase(),
                            getBlogTitle()
                          ) > 0
                        "
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i> Add
                          target keyword in the page title
                          {{
                            checkStringExist(
                              getBlogSubmissionSelection.keyword.toLowerCase(),
                              getBlogTitle()
                            )
                          }}.</p
                        >
                      </li>

                      <!--1-->
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) < 1
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword in <strong>Page Description</strong>.
                        </p>
                      </li>
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) >=
                            1 &&
                          !checkStringExist(
                            getBlogSubmissionSelection.keyword.toLowerCase(),
                            getBlogDescription()
                          ) > 0
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          your keyword more often in your Page Description.</p
                        >
                      </li>
                      <li
                        v-if="
                          getTextLength(getBlogSubmissionSelection.keyword) >=
                            1 &&
                          checkStringExist(
                            getBlogSubmissionSelection.keyword.toLowerCase(),
                            getBlogDescription()
                          ) > 0
                        "
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of keywords
                          {{
                            checkStringExist(
                              getBlogSubmissionSelection.keyword.toLowerCase(),
                              getBlogDescription()
                            )
                          }}.
                        </p>
                      </li>
                      <li
                        v-if="
                          getTextLength(getBlogTitle()) >= 50 &&
                          getTextLength(getBlogTitle()) <= 60
                        "
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of characters
                          {{ getTextLength(getBlogTitle()) }}.</p
                        >
                      </li>
                      <li v-if="getTextLength(getBlogTitle()) < 50">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Keep
                          your title length between 50-60 characters
                        </p>
                      </li>
                      <li v-if="getTextLength(getBlogTitle()) > 60">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Remove
                          {{ getTextLength(getBlogTitle()) - 60 }} characters in
                          your Page Title. Use max 60 characters.</p
                        >
                      </li>
                      <li
                        v-if="
                          getTextLength(
                            getBlogPostingDetails.optionalDescription
                          ) >= 120 &&
                          getTextLength(
                            getBlogPostingDetails.optionalDescription
                          ) <= 155
                        "
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of characters in page description
                          {{
                            getTextLength(
                              getBlogPostingDetails.optionalDescription
                            )
                          }}.</p
                        >
                      </li>
                      <li
                        v-if="
                          getTextLength(
                            getBlogPostingDetails.optionalDescription
                          ) < 120
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Keep
                          your description length between 120-155 characters
                        </p>
                      </li>
                      <li
                        v-if="
                          getTextLength(
                            getBlogPostingDetails.optionalDescription
                          ) > 155
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Remove
                          {{
                            getTextLength(
                              getBlogPostingDetails.optionalDescription
                            ) - 155
                          }}
                          characters from your Page Description. Use max 155
                          characters.
                        </p>
                      </li>
                      <!--                <li-->
                      <!--                   v-if="title_bar_checks.number"-->
                      <!--                   class="completed"-->
                      <!--                >-->
                      <!--                  <p-->
                      <!--                    ><i class="fas fa-circle" aria-hidden="true"></i> Numbers in your page title</p-->
                      <!--                  >-->
                      <!--                </li>-->
                      <!--                <li v-if="!title_bar_checks.number">-->
                      <!--                  <p-->
                      <!--                    ><i class="far fa-circle" aria-hidden="true"></i> Consider using a number in your page title</p-->
                      <!--                  >-->
                      <!--                </li>-->
                      <li v-if="getBlogTitle().match(/\d+/g)" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Numbers in your page title</p
                        >
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Consider using a number in your page title</p
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_heading"
                    >
                      <span><i class="fa fa-angle-right"></i> Heading</span>
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{
                              width:
                                Math.round(
                                  (heading_bar + h3_bar + h2_bar) * 100
                                ) + '%',
                            }"
                          ></div>
                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_heading"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->
                      <li v-if="h2_tags" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of H2 tags {{ h2_tags }}
                        </p>
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          at least one <strong>H2 / Header 2</strong> tag(s)</p
                        >
                      </li>
                      <li v-if="h3_tags" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of H3 tags {{ h3_tags }}
                        </p>
                      </li>

                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          at least one <strong>H3 / Header 3</strong> tag(s)</p
                        >
                      </li>
                      <!--2-->
                      <li v-if="h2_keywords || h3_keywords" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of Keyword(s) in H2 or H3 tags
                          {{ h2_keywords + h3_keywords }}
                        </p>
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword 1 time(s) within a
                          <strong>H2 or H3</strong> tag.</p
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_content"
                    >
                      <span><i class="fa fa-angle-right"></i>Body</span>
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{
                              width: Math.round(content_bar * 100) + '%',
                            }"
                          ></div>

                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_content"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->
                      <li v-if="total_content >= 1500" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Number of words in the page {{ total_content }}</p
                        >
                      </li>

                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Number of words on the Page should be at least 1500
                          words.
                          {{
                            total_content < 1500
                              ? `Remaining words is ${1500 - total_content}`
                              : ''
                          }} </p
                        >`
                      </li>
                      <li v-if="in_first_10_content === 0">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword in first 10% of the content
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Target keyword in first 10% of the content
                        </p>
                      </li>

                      <!--3-->
                      <li v-if="in_all_content === 0">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword in content
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Target Keyword at your page
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Content Readability  -->
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_content"
                    >
                      <span
                        ><i class="fa fa-angle-right"></i>Content
                        Readability</span
                      >
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{
                              width:
                                Math.round(
                                  (readability_bar + power_bar) * 100
                                ) + '%',
                            }"
                          ></div>

                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_content"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->
                      <li
                        v-if="content_bar_checks.target_keyword"
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>Use
                          the target keyword at the beginning of the title</p
                        >
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Use
                          the target keyword at the beginning of the title
                        </p>
                      </li>
                      <li v-if="!content_bar_checks.power_words">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Add
                          some power words in your title
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Add some power words in your title
                        </p>
                      </li>
                      <li v-if="!content_bar_checks.short_paragraph">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Use
                          short paragraphs (less than or equal to 120 words)
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Use short paragraphs (less than or equal to 120 words)
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Media -->
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_content"
                    >
                      <span><i class="fa fa-angle-right"></i>Media</span>
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{
                              width: Math.round(media_bar * 100) + '%',
                            }"
                          ></div>

                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_content"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->

                      <li v-if="more_images !== 0" class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Consider adding more images in your content</p
                        >
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Consider adding more images in your content
                        </p>
                      </li>
                      <li v-if="keyword_in_alt === 0">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Add
                          target keyword in the alt text of one or more images
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Add target keyword in the alt text of one or more
                          images
                        </p>
                      </li>
                      <li v-if="!content_bar_checks.featured_image">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Add a
                          featured image
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Add a featured image
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- url -->
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_content"
                    >
                      <span><i class="fa fa-angle-right"></i>URL</span>
                    </a>
                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{ width: Math.round(Url_bar * 100) + '%' }"
                          ></div>

                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_content"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <!--1-->

                      <li
                        v-if="content_bar_checks.target_url"
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i> Add
                          target keyword in the URL</p
                        >
                      </li>
                      <li v-else>
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Add
                          target keyword in the URL
                        </p>
                      </li>
                      <li v-if="!content_bar_checks.url_char">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i> Keep
                          your URL less than or equal to 75 characters
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Keep your URL less than or equal to 75 characters
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a
                      data-toggle="collapse"
                      aria-expanded="true"
                      class=""
                      href="#page_miscellaneous"
                    >
                      <span
                        ><i class="fa fa-angle-right"></i> Miscellaneous</span
                      >
                    </a>

                    <div class="list_plan_bars">
                      <div class="bar_item">
                        <div class="item_inner">
                          <div
                            class="limit_add"
                            :style="{
                              width:
                                Math.round(
                                  (miscellaneous_bar + external_resources_bar) *
                                    100
                                ) + '%',
                            }"
                          ></div>
                          <div class="limit_exceed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="page_miscellaneous"
                  class="show panel-collapse collapse collapse in"
                >
                  <div class="panel-body">
                    <ul>
                      <li v-if="total_content_density === 0">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i
                          >Keyword density.</p
                        >
                      </li>
                      <li
                        v-else-if="
                          content_keyword < min_range ||
                          content_keyword > max_range
                        "
                      >
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>
                          Based on current number of words on your page, add
                          your keyword
                          <strong v-if="min_range === max_range">{{
                            max_range
                          }}</strong>
                          <strong v-else
                            >{{ min_range }} - {{ max_range }}</strong
                          >
                          times in the page.
                        </p>
                      </li>
                      <li
                        v-else-if="
                          content_keyword >= min_range &&
                          content_keyword <= max_range
                        "
                        class="completed"
                      >
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Keyword density.
                        </p>
                      </li>
                      <li v-if="!miscellaneous_bar_checks.internal_link">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Add
                          internal links in your content
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Add internal links in your content
                        </p>
                      </li>
                      <li v-if="!miscellaneous_bar_checks.external_resources">
                        <p
                          ><i class="far fa-circle" aria-hidden="true"></i>Link
                          to external resources
                        </p>
                      </li>
                      <li v-else class="completed">
                        <p
                          ><i class="fas fa-circle" aria-hidden="true"></i>
                          Link to external resources
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Keywords" title-item-class="mytabs-costum">
            <div
              class="d-flex justify-content-between flex-wrap tabs-outer mx-3"
            >
              <template v-if="keywords && keywords.length === 0">
                <div class="no_data_found_content w-full">
                  <div class="no_data_found_content_inner seo_content">
                    <div class="img">
                      <img
                        src="../../../../assets/img/no_data_images/no_data_found.svg"
                        alt=""
                      />
                    </div>
                    <template>
                      <h3 class="text-base font-normal">No results found</h3>
                    </template>
                  </div>
                </div>
              </template>
              <div
                v-for="(data, index) in keywords"
                :key="index"
                class="tabs-content mt-2 align-items-center cursor-pointer"
              >
                <div
                  class="flex justify-between items-center"
                  @click.prevent="handleKeyWords(data)"
                >
                  <p class="text-left text-base"> {{ data[0] }}</p>
                  <div class="flex items-center keywords_content">
                    <span class="text-base font-medium mr-4">{{
                      Math.floor(data[1] * 100)
                    }}</span>
                    <CircularBar :bar_data="Math.floor(data[1] * 100)" />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title-item-class="mytabs-costum" title="Question">
            <div
              class="d-flex justify-content-between flex-wrap tabs-outer mx-3"
            >
              <template v-if="questions && questions.length === 0">
                <div class="no_data_found_content w-full">
                  <div class="no_data_found_content_inner seo_content">
                    <div class="img">
                      <img
                        src="../../../../assets/img/no_data_images/no_data_found.svg"
                        alt=""
                      />
                    </div>
                    <template>
                      <h3 class="text-base font-normal">No results found</h3>
                    </template>
                  </div>
                </div>
              </template>
              <div
                v-for="(data, key) in questions"
                :key="key"
                class="tabs-content questions mt-2 align-items-center"
              >
                <p
                  class="text-left cursor-pointer"
                  @click.prevent="handleTopics(data)"
                >
                  {{ data }}</p
                >
              </div>
            </div>
          </b-tab>
          <b-tab title-item-class="mytabs-costum" title="Topics">
            <div
              class="d-flex justify-content-between flex-wrap tabs-outer mx-3"
            >
              <template v-if="!topics || topics.length === 0">
                <div class="no_data_found_content w-full">
                  <div class="no_data_found_content_inner seo_content">
                    <div class="img">
                      <img
                        src="../../../../assets/img/no_data_images/no_data_found.svg"
                        alt=""
                      />
                    </div>
                    <template>
                      <h3 class="text-base font-normal">No results found</h3>
                    </template>
                  </div>
                </div>
              </template>
              <div
                v-for="(data, key) in topics"
                :key="key"
                class="tabs-content d-flex justify-content-between mt-4 align-items-center cursor-pointer"
              >
                <div
                  class="flex justify-between items-center w-full"
                  @click.prevent="handleTopics(data.text)"
                >
                  <p class="text-left text-base">{{ data.text }}</p>
                  <div class="flex items-center keywords_content">
                    <span class="text-base font-medium mr-4">{{
                      Math.floor(data.relevance * 100)
                    }}</span>
                    <CircularBar :bar_data="Math.floor(data.relevance * 100)" />
                  </div>
                </div>
                <!-- <p class="text-left	"> {{data.text}}</p> -->

                <!-- <span>{{Math.floor(data.relevance * 100)}}%</span> -->
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { seoPredictionUrl } from '@src/modules/publish/config/api-utils'
import HelperProgress from './HelperProgress.vue'
import HelperContent from './HelperContent.vue'
import CircularBar from './CircularProgress.vue'
import {EventBus} from "@common/lib/event-bus";
export default {
  components: {
    HelperProgress,
    HelperContent,
    CircularBar,
  },

  data() {
    return {
      circleSateColor: '#4165ed',
      lines: [],
      Line: [],
      Circle: [],

      main_bar: 0,
      title_bar: 0,
      title_Nub: 0,
      desc_bar: 0,
      heading_bar: 0,
      h2_bar: 0,
      h3_bar: 0,
      content_bar: 0,
      Url_bar: 0,
      readability_bar: 0,
      power_bar: 0,
      media_bar: 0,
      miscellaneous_bar: 0,
      external_resources_bar: 0,

      title_bar_checks: {
        character: false,
        keyword: false,
        word: false,
        number: false,
      },
      desc_bar_checks: {
        character: false,
        keyword: false,
        word: false,
      },
      heading_bar_checks: {
        h2_tags: false,
        h3_tags: false,
        h2_keywords: false,
        h3_keywords: false,
        h2_words: false,
        h3Toh6: false,
        h3Toh6_keywords: false,
      },
      content_bar_checks: {
        total_content: false,
        content_keyword: false,
        in_all_content: false,
        target_url: false,
        total_content_density: false,
        url_char: false,
        target_more_images: false,
        featured_image: false,
        image_size: false,
        target_image_name: false,
        target_keyword: false,
        more_images: false,
        short_paragraph: false,
        power_words: false,
        in_first_10_keyword: false,
        keyword_in_alt: false,
        total_images: false,
        bold_tag: false,
        bold_keyword: false,
      },
      miscellaneous_bar_checks: {
        // italic_tag: false,
        // italic_keyword: false
        internal_link: false,
        external_resources: false,
      },
      // heading section
      h2_tags: 0,
      h3_tags: 0,
      h2_keywords: 0,
      h3_keywords: 0,
      h2_words: 'minimum',
      h2_total: 0,
      h3Toh6: 0,
      h3Toh6_keywords: 0,
      // main content
      total_content: 0,
      total_content_density: 0,
      min_range: 0,
      max_range: 0,
      content_keyword: 0,
      in_all_content: 0,
      target_url: 0,
      target_more_images: 0,
      target_image_name: 0,
      target_keyword: 0,
      more_images: 0,
      short_paragraph: 0,
      featured_image: 0,
      url_char: 0,
      image_size: 0,
      power_words: 0,
      in_first_10_content: 0,
      keyword_in_alt: 0,
      total_images: 0,
      bold_tag: 0,
      bold_keyword: 0,
      italic_tag: 0,
      internal_link: 0,
      external_resources: 0,
      italic_keyword: 0,
      /// seo ///////
      emotions: '',
      keywords: [],
      sentiments: '',
      headingCount: 0,
      mediaCount: 0,
      questions: [],
      readTime: 0,
      topics: [],
      readTimeText: 'sec',
    }
  },
  computed: {
    ...mapGetters([
      'getBlogSubmissionSelection',
      'getBlogPostingTitle',
      'getBlogPostingMetaTitle',
      'getBlogPostingMetaDescription',
      'getWordsCount',
      'getBlogPostingHTML',
      'getBlogPostingDetails',
    ]),

    barProperty() {
      return [
        this.title_bar,
        this.desc_bar,
        this.heading_bar,
        this.h2_bar,
        this.h3_bar,
        this.content_bar,
        this.Url_bar,
        this.readability_bar,
        this.power_bar,
        this.media_bar,
        this.miscellaneous_bar,
        this.external_resources_bar,
        this.title_Nub,
      ].join()
    },
    blogPost() {
      this.getHeadings(this.getBlogPostingHTML)
      return this.stripHtml(this.getBlogPostingHTML)
    },
    contentProperty() {
      return [
        this.total_content,
        this.content_keyword,
        this.in_all_content,
        this.in_first_10_content,
        this.keyword_in_alt,
        this.total_images,
        this.bold_keyword,
        this.bold_tag,
      ].join()
    },

    urlProperty() {
      return [this.target_url, this.url_char]
    },
    contentReadability() {
      return [this.target_keyword, this.power_words, this.short_paragraph]
    },
    mediaContent() {
      return [
        this.more_images,
        this.target_more_images,
        this.target_image_name,
        this.featured_image,
        this.image_size,
      ]
    },

    headingProperty() {
      return [
        this.h2_tags,
        this.h3_tags,
        this.h2_keywords,
        this.h3_keywords,
        this.h2_words,
        this.h3Toh6,
        this.h3Toh6_keywords,
      ].join()
    },

    miscellaneousProperty() {
      return [
        this.total_content_density,
        this.internal_link,
        this.external_resources,
      ]
    },
  },
  watch: {
    blogPost(n, o) {
      if (n) {
        this.getPredictions(n)
      } else {
        this.emotions = ''
        this.keywords = []
        this.sentiments = ''
        this.questions = []
        this.readTime = 0
        this.topics = []
      }
    },
    getBlogPostingHTML() {
      this.dropzone_change_html(this)
    },
    'getBlogSubmissionSelection.url'() {
      this.url_process()
    },
    'getBlogSubmissionSelection.keyword'() {
      this.dropzone_change_html(this)
      this.content_ReadabilityProcess()
      this.title_progress()
      this.description_progress()
      this.heading_progress()
      this.content_process()
      this.url_process()
      this.miscellaneous_process()
      this.featured_image_process()
    },
    'getBlogPostingDetails.image.link'() {
      this.featured_image_process()
    },
    'getBlogPostingDetails.optionalDescription'() {
      this.optionalDescription_process()
    },
    main_bar(value) {
      if (value < 0.1) {
        this.circleSateColor = '#ea7070'
      } else if (value < 0.5 && value > 0.1) {
        this.circleSateColor = '#f59203'
      } else if (value >= 0.5 && value < 0.9) {
        this.circleSateColor = '#41b2ff'
      } else if (value >= 0.9 && value < 1) {
        this.circleSateColor = '#41b2ff'
      } else {
        this.circleSateColor = '#4bc489'
      }
    },
    getBlogPostingTitle() {
      this.title_progress()
      this.content_ReadabilityProcess()
    },
    getBlogPostingMetaTitle() {
      this.title_progress()
    },

    getBlogPostingMetaDescription() {
      this.description_progress()
    },

    headingProperty() {
      this.heading_progress()
    },

    contentProperty() {
      this.content_process()
    },

    urlProperty() {
      this.url_process()
    },
    contentReadability() {
      this.content_ReadabilityProcess()
    },
    mediaContent() {
      this.media_contentProcess()
    },
    miscellaneousProperty() {
      this.miscellaneous_process()
    },

    barProperty() {
      this.main_process()
    },
  },
  created() {
    const title = this.getTitle()
    if (title) this.getPredictions(title)
  },
  mounted() {
    this.dropzone_change_html(this)
    this.content_ReadabilityProcess()
    this.title_progress()
    this.optionalDescription_process()
    this.description_progress()
    this.heading_progress()
    this.content_process()
    this.url_process()
    this.miscellaneous_process()
    this.featured_image_process()
  },
  methods: {
    getTitle() {
      this.getHeadings(this.getBlogPostingHTML)
      return this.stripHtml(this.getBlogPostingHTML)
    },
    handleKeyWords(data) {
      EventBus.$emit('blog-keyword', data[0])
    },
    handleTopics(text) {
      EventBus.$emit('blog-Questions', text)
    },
    getMinutes(value) {
      const sec = parseInt(value, 10) // convert value to number if it's string
      const hours = Math.floor(sec / 3600) // get hours
      const minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
      if (minutes < 60) {
        this.readTimeText = 'mins'
        return minutes
      } else {
        this.readTimeText = 'hours'
        return hours
      }
    },
    // eslint-disable-next-line no-undef
    getPredictions: _.debounce(async function (title) {
      console.log('METHOD::getPredictions SEO.vue ~ title -> ', title)
      await this.$http
        .post(
          seoPredictionUrl,
          { text: title },
          {
            headers: { Authorization: 'Bearer ' + this.getJWTToken },
          }
        )
        .then((resp) => {
          const { keywords, readtime } = resp.data.seo_prediction?.prediction
          this.questions = resp.data.seo_prediction.questions.questions
          this.readTime = readtime >= 60 ? this.getMinutes(readtime) : readtime
          this.keywords = keywords
          this.topics = resp.data.seo_prediction.topics.concepts
        })
    }, 500),
    /// / fetch headings and media tagg from html /////
    getHeadings(innerHtml) {
      var parsed = new DOMParser().parseFromString(innerHtml, 'text/html')
      const headings = parsed.querySelectorAll('h1,h2,h3,h4,h5,h6')
      const mediaTag = parsed.querySelectorAll('img,video,iframe')
      this.headingCount = headings.length
      this.mediaCount = mediaTag.length
    },
    stripHtml(html) {
      // Create a new div element
      var temporalDivElement = document.createElement('div')
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html
      // Retrieve the text property of the element (cross-browser support)
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ''
      )
    },
  },
}
</script>

<style lang="less">
.nav.nav-pills {
  //border: 1px solid rgb(241,241,241);
}
.nav-pills .mytabs-costum .nav-link {
  color: #26282c !important;
  margin: 10px 0px;
  font-size: 0.87rem;
  font-weight: 600;
  @media only screen and (max-width: 1600px) {
    font-size: 0.7rem;
  }
}
.nav-pills .mytabs-costum .nav-link.active {
  background-color: #ebf7fe;
  border-radius: 50px;
  color: #0a97f3 !important;
  font-weight: 500;
  animation: nav-pills 0.9s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.tabs-outer {
  padding: 9px 16px;
}
.tabs-content {
  background-color: rgba(251 251 251);
  width: 100%;
  padding: 4px 10px;
  border: 1px solid rgba(242 242 242);
}
.keywords_content span {
  color: #0a9ff7;
}
.questions {
  padding: 10px;
}
.questions p {
  max-width: 100% !important;
}
</style>
