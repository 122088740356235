<script>
import { mapGetters } from 'vuex'

import TwoFactorAuth from '@src/modules/setting/components/TwoFactorAuth'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstButton from '@ui/Button/CstButton'

export default {
  components: {
    TwoFactorAuth,
    CstFloatingLabelInput,
    CstButton,
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      old_password: '',
      new_password: '',
      confirm_password: '',
      join_team: {
        join_status: false,
      },
    }
  },

  computed: {
    ...mapGetters(['getProfile', 'getSettingLoaders']),
  },

  mounted() {
    this.firstName = this.getProfile.firstname || ''
    this.lastName = this.getProfile.lastname || ''
    this.email = this.getProfile.email || ''
    this.phone = this.getProfile.phone_no || ''
  },

  methods: {
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`,
      ]
      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },
    /*
     * Method to handle code input validation
     */
    phoneNumberValidation(e) {
      console.log('e.keyCode', e.keyCode)
      const isModifierkeyPressed = e.metaKey || e.ctrlKey || e.shiftKey
      const isCursorMoveOrDeleteAction =
        [46, 8, 37, 38, 39, 40, 32].indexOf(e.keyCode) !== -1

      const isSpecialKeys = [107, 173].indexOf(e.keyCode) !== -1

      const isNumKeyPressed =
        (e.keyCode >= 48 && e.keyCode <= 58) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      const vKey = 86
      const cKey = 67
      const aKey = 65
      const plusKey = 61
      const leftBracket = 57
      const rightBracket = 48
      switch (true) {
        case isCursorMoveOrDeleteAction:
        case isSpecialKeys && !e.shiftKey:
        case isModifierkeyPressed === false && isNumKeyPressed:
        case (e.keyCode === plusKey ||
          e.keyCode === leftBracket ||
          e.keyCode === rightBracket) &&
          e.shiftKey:
        case (e.metaKey || e.ctrlKey) &&
          [vKey, cKey, aKey].indexOf(e.keyCode) !== -1:
          break
        default:
          e.preventDefault()
      }
    },
  },
}
</script>

<template>
  <div class="setting_profile_component">
    <div class="component_inner">
      <div class="flex space-x-5 space-x-3">
        <div class="ps_box !mb-5 flex-1">
          <h2 class="box_heading">Basic Details</h2>

          <div class="box_content">
            <div class="d-flex align-items-start">
              <div class="flex-1">
                <div class="grid grid-cols-2 gap-5 mt-6 mb-5">
                  <div>
                    <CstFloatingLabelInput
                      id="first-name-field"
                      v-model="firstName"
                      data-cy="first_name"
                      label="First Name"
                      type="text"
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-User"></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>
                  <div>
                    <CstFloatingLabelInput
                      id="last-name-field"
                      v-model="lastName"
                      data-cy="last_name"
                      label="Last Name"
                      type="text"
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-User"></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>
                  <div>
                    <CstFloatingLabelInput
                      id="email-field"
                      v-model="email"
                      data-cy="email_address"
                      type="email"
                      label="Email Address"
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-Email"></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>
                  <div>
                    <CstFloatingLabelInput
                      id="phone-field"
                      v-model="phone"
                      data-cy="phone_no"
                      type="text"
                      label="Phone Number"
                      show-icon-left
                      @keydown="phoneNumberValidation"
                    >
                      <template v-slot:icon>
                        <i class="icon-Phone"></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>
                </div>

                <div>
                  <CstButton
                    class="ml-auto block"
                    @click.prevent="updateProfile"
                  >
                    <span>Update Profile</span>
                  </CstButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ps_box !mb-5">
          <h2 class="box_heading">Display Picture</h2>
          <div class="flex flex-col justify-center !p-7">
            <div
              class="w-48 h-48 overflow-hidden relative shadow-sm text-base ease-soft-in-out relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200 group bg-gray-400"
            >
              <img
                class="h-full shadow-soft-sm object-cover"
                :src="getProfile.image"
                alt="display picture"
                data-fallback="0"
                @error="onError($event)"
              />

              <div
                class="w-full h-full absolute bg-black-100 bg-opacity-50 flex justify-center items-center cursor-pointer opacity-0 group-hover:!opacity-100 transition-all duration-200"
                :class="{ '!opacity-100': getSettingLoaders.saveProfileImage }"
                @click="triggerProfileImageFileChange"
              >
                <clip-loader
                  v-if="getSettingLoaders.saveProfileImage"
                  class="center_loader"
                  :color="'#ffffff'"
                  :size="'24px'"
                ></clip-loader>
                <i v-else class="fas fa-camera text-4xl"></i>
                <input
                  id="profile_image"
                  class="hidden"
                  type="file"
                  autocomplete="off"
                  accept="image/*"
                  :disabled="getSettingLoaders.saveProfileImage"
                  @change.prevent="saveProfileImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ps_box">
        <h2 class="box_heading">Security</h2>

        <div class="box_content">
          <b-tabs
            class="px-0 flex-grow-1 flex-wrap-initial"
            active-nav-item-class="!text-primary-variant-1 font-weight-500 !border-b-2 border-b-solid border-b-red-500"
            nav-wrapper-class="my-4"
            nav-class="mb-3 border-px pb-3"
          >
            <b-tab
              title-link-class="border-0 text-black-100"
              title="Password"
              active
            >
              <div class="max-w-3xl">
                <div class="grid grid-cols-1 gap-5 mt-6 mb-5">
                  <div class="w-1/2">
                    <CstFloatingLabelInput
                      id="old_password"
                      v-model="old_password"
                      type="password"
                      label="Old Password"
                      show-icon-right
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-Password"></i>
                      </template>
                      <template v-slot:icon-right>
                        <i
                          class="cs-eye cursor-pointer text-lg"
                          @click.prevent="
                            switchVisibility($event, 'old_password')
                          "
                        ></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>

                  <div class="w-1/2">
                    <CstFloatingLabelInput
                      id="new_password"
                      v-model="new_password"
                      type="password"
                      label="New Password"
                      show-icon-right
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-Password"></i>
                      </template>
                      <template v-slot:icon-right>
                        <i
                          class="cs-eye cursor-pointer text-lg"
                          @click.prevent="
                            switchVisibility($event, 'new_password')
                          "
                        ></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>

                  <div class="w-1/2">
                    <CstFloatingLabelInput
                      id="confirm_password"
                      v-model="confirm_password"
                      type="password"
                      label="Confirm New Password"
                      show-icon-right
                      show-icon-left
                    >
                      <template v-slot:icon>
                        <i class="icon-Password"></i>
                      </template>
                      <template v-slot:icon-right>
                        <i
                          class="cs-eye cursor-pointer text-lg"
                          @click.prevent="
                            switchVisibility($event, 'confirm_password')
                          "
                        ></i>
                      </template>
                    </CstFloatingLabelInput>
                  </div>
                </div>

                <div class="w-[24rem]">
                  <CstButton
                    class="ml-auto block"
                    :loading="getSettingLoaders.changePassword"
                    @click.prevent="changePassword"
                  >
                    <span>Change Password</span>
                  </CstButton>
                </div>
              </div>
            </b-tab>
            <b-tab
              title-link-class="border-0 text-black-100"
              title="Two-factor Authentication"
            >
              <div>
                <TwoFactorAuth />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
