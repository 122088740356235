import { TEXT_IMAGE_PREVIEW_URL } from '@src/config/api-utils'

const stringMixin = {
  created() {},

  methods: {
    /**
     * @description used throughout the app in case of fallback images, takes the letter and returns a thumbnail image for that.
     */
    fallbackImagePreview(
      text,
      bgColor = '97d56c',
      textColor = 'green',
      fontSize = 30
    ) {
      return `${TEXT_IMAGE_PREVIEW_URL}/text_preview/?bg_color=%23${bgColor}&text_color=${textColor}&font_size=${fontSize}&text=${text}`
    },

    intToString(value) {
      if (value) {
        // console.debug(value)
        // Nine Zeroes for Billions
        return Math.abs(Number(value)) >= 1.0e9
          ? Number((Math.abs(Number(value)) / 1.0e9).toFixed(1)) + 'B'
          : // Six Zeroes for Millions
          Math.abs(Number(value)) >= 1.0e6
          ? Number((Math.abs(Number(value)) / 1.0e6).toFixed(1)) + 'M'
          : // Three Zeroes for Thousands
          Math.abs(Number(value)) >= 1.0e3
          ? Number((Math.abs(Number(value)) / 1.0e3).toFixed(1)) + 'K'
          : Number(value) === value && value % 1 !== 0
          ? Math.abs(Number(value)).toFixed(2)
          : Math.abs(Number(value))
      } else {
        return 0
      }
    },

    getTextLength(str) {
      if (str) {
        str = str.trim()
        return str.length
      }
      return 0
    },

    getTextWordsLength(str) {
      str = str.trim()
      return this.cleanArray(str.split(' ')).length
    },

    checkStringExist(keyword, str) {
      if (keyword && str) {
        str = str.trim()
        keyword = keyword.trim()
        if (keyword.length === 0) {
          return 0
        }
        const re = new RegExp('\\b' + keyword + '\\b', 'g')
        return (str.match(re) || []).length
      }

      return 0
    },

    removeEmptyTagsFromString(text) {
      // use this pattern to remove any empty tag
      const pattern = /<([^\s>]+)[^>]*>\s*<\/\1>/g
      return text.replace(pattern, '')
    },

    capitalizeFirstLetter(string) {
      if (string) {
        return string.length
          ? string.charAt(0).toUpperCase() + string.slice(1)
          : ''
      } else {
        return ''
      }
    },
    stringToInt(value, float = false) {
      if (!value) return 0
      if (float) return value.toFixed(2).toString()
      return value.toLocaleString()
    },
    capitalizeName(string) {
      const split = string.split(' ')
      if (split.length >= 2) {
        return (
          split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase()
        )
      } else {
        return split[0].substring(0, 2).toUpperCase()
      }
    },
    getEncodedHtml(html) {
      console.debug('Method:getEncodedHtm', html)

      const characters = []

      for (let i = html.length - 1; i >= 0; i--) {
        characters.unshift(['&#', html[i].charCodeAt(), ';'].join(''))
      }

      html = characters.join('')

      console.debug('encodedHtml', html)

      return html
    },

    getDecodedHtml(html) {
      console.debug('Method:getDecodedHtml', html)

      html = html.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec)
      })

      console.debug('decodedHtml', html)

      return html
    },
    decStrNum(n) {
      if (n) {
        n = n.toString()
        let result = n
        let i = n.length - 1
        while (i > -1) {
          if (n[i] === '0') {
            result = result.substring(0, i) + '9' + result.substring(i + 1)
            i--
          } else {
            result =
              result.substring(0, i) +
              (parseInt(n[i], 10) - 1).toString() +
              result.substring(i + 1)
            return result
          }
        }

        return result
      }

      return ''
    },
    truncateString(str, num) {
      if (str) {
        if (str.length > num) {
          return str.slice(0, num) + '...'
        } else {
          return str
        }
      }
    },
  },
}
export { stringMixin }
