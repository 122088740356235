<template>
  <chart-render
    :id="'heatmap_highlight'"
    :options="heatmapPerformance()"
    :title="'Engagement by day/time'"
    :ref_value="'heatmap_chart'"
    :is_content_available="isContentNotAvailable"
    :tooltip_message="tooltip_message"
  ></chart-render>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  heatMapChart,
  heatMapLegend,
  heatMapColorAxis,
  heatMapXAxis,
  splineCredits
} from '../helper'
import { FACEBOOK_ANALYTICS_BEST_TIME_TO_POST } from '@common/constants/messages'
import ChartRender from '../ChartRender'

export default {
  props: {
    best_time: {},
    engagement: {
      default: 1
    }
  },
  mounted () {},

  data () {
    return {
      tooltip_message: FACEBOOK_ANALYTICS_BEST_TIME_TO_POST
    }
  },

  computed: {
    ...mapGetters([]),
    isContentNotAvailable () {
      return this.engagement === 0
    }
  },
  components: {
    ChartRender
  },
  methods: {
    ...mapActions([]),
    heatmapPerformance () {
      console.log(this.best_time)
      const daysArray = []
      console.log()
      Object.entries(this.best_time).forEach((item, index) => {
        console.log(item, index)
        item[1].hours.forEach((hour, hourIndex) => {
          daysArray.push([hourIndex, index, hour])
        })
      })
      // let heatMapChartTooltipModified = {
      //   headerFormat: '<div class="chart_tooltip engagement_tooltip"><h4>Engagement</h4>',
      //   pointFormat: '<p class="engagement_point">{point.x}, {point.y}: {point.value}</p>',
      //   footerFormat: '</div>',
      //   backgroundColor: null,
      //   shadow: false,
      //   useHTML: true,
      //   borderWidth: 0,
      //   crosshairs: {
      //     // width: 50,
      //     color: '#fafafa'
      //   }
      // }
      // heatMapChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.x}, {point.y}: {point.value}</span></p>'

      return {
        credits: splineCredits(),
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>Engagement</h4>',
          pointFormat:
            '<p class="engagement_point"><strong>{point.value} total engagement</strong> at {point.x}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            // width: 50,
            color: '#fafafa'
          }
        },
        chart: heatMapChart(),
        title: {
          text: ''
        },

        xAxis: heatMapXAxis(),
        yAxis: {
          categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          title: null
        },
        colorAxis: heatMapColorAxis(),
        legend: heatMapLegend(),
        series: [
          {
            borderWidth: 8,
            borderColor: '#ffffff',
            data: daysArray,
            dataLabels: {
              enabled: false
            }
          }
        ]
      }
    }
  }
}
</script>
