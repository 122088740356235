import { gmb } from '@src/modules/integration/store/states/mutation-types'

const getDefaultGMBAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultGMBAccountsState()
  },
  getters: {
    getGmbAccounts: (state) => state.accounts
  },
  actions: {},
  mutations: {
    [gmb.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.location_name.toLowerCase().localeCompare(b.location_name.toLowerCase()))
    },

    [gmb.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.location_name.toLowerCase().localeCompare(b.location_name.toLowerCase()))
    },

    [gmb.SET_DEFAULT] (state) {
      state.accounts = getDefaultGMBAccountsState()
    }
  }
}
