<template>
  <div class="analytic-competitors-report">
    <div class="analytic-competitors-report__header !top-0"
      >Competitors' Analytics</div
    >

    <div class="analytic-competitors-report__body">
      <div class="analytic-competitors-report__title">
        Track your competitors on Facebook and compare your performance with
        theirs
      </div>

      <div class="grid-flex analytic-competitors-report__content">
        <div class="cell-md-6 pr-5">
          <p> To keep track of your Facebook rivals, this tool projects: </p>
          <div>
            <ul>
              <li class="bullet">
                Total followers and likes of a page as well as its growth rate
                from the day page gets connected.
              </li>
              <li class="bullet">
                Your competitors’ total posts, posting routine and history
              </li>
              <li class="bullet">
                The top 3 posts with their reactions comments & shares
              </li>
              <li class="bullet">
                The engagement rate with an average of its comments, reactions
                and shares.
              </li>
              <li class="bullet">
                A layout of post types and audience reactions
              </li>
            </ul>
          </div>

          <p class="mt-5">
            These metrics enable you to effectively gauge your performance
            against your competitors by providing:
          </p>
          <div>
            <ul>
              <li class="bullet">
                Comparison graphs & pie charts of reactions with comments, page
                community growth rates, and posting history
              </li>
              <li class="bullet">
                A leaderboard of your competitors based on total posts,
                engagement rates, and page likes/follows.
              </li>
              <li class="bullet">
                A table showcasing content, fans, and engagement statistics.
              </li>
            </ul>
          </div>
        </div>

        <div class="cell-md-6">
          <AddCompetitorFields
            native="true"
            :selected-competitor="
              connectedAccounts.length > 0
                ? connectedAccounts[0].facebook_id
                : ''
            "
          />
        </div>
      </div>

      <div v-if="!modalFlag" class="analytic-competitors-report__loader">
        <clip-loader
          class="ml-2"
          :color="'#5773fa'"
          :size="'60px'"
        ></clip-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {EventBus} from "@common/lib/event-bus";
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { fetchFbCompetitors } from '@src/modules/analytics/config/api-utils'
import AddCompetitorFields from '../../common/AddCompetitorFields'

export default {
  name: 'FacebookCompetitorReport',
  components: {
    AddCompetitorFields
  },
  mixins: [analyticsUtilsMixin],
  data () {
    return {
      modalFlag: false,
      CancelToken: axios.CancelToken,
      proxy: axios,
      requestCancelPromise: {}
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    connectedAccounts () {
      return this.getPlatformAccounts('facebook').sort((a, b) =>
          a.name > b.name ? 1 : -1
      )
    }
  },
  mounted () {
    EventBus.$on('workspace-changed', () => {
      console.log('connected accounts wsevent 2')
      this.modalFlag = false
      this.cancelPendingRequest('get_comp')
      this.competitorExists()
    })
    console.log('connected accounts wsevent 3')
    this.cancelPendingRequest('get_comp')
    this.competitorExists()
  },
  beforeDestroy () {
    EventBus.$off('workspace-changed')
  },
  methods: {
    cancelPendingRequest (key) {
      if (!this.requestCancelPromise[key]) {
        this.requestCancelPromise[key] = []
      }
      this.requestCancelPromise[key].forEach((cancel) => {
        cancel()
      })
      this.proxy.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getJWTToken
      }
    },
    competitorExists () {
      console.log('connected accounts', this.connectedAccounts)
      const promises = []
      let compId = []
      let cancel
      this.connectedAccounts.forEach((dataObj) => {
        promises.push(
          this.proxy
            .get(
              fetchFbCompetitors +
                `?facebook_id=${dataObj.facebook_id}&workspace_id=${dataObj.workspace_id}`,
              {
                cancelToken: new this.CancelToken(function executor (c) {
                  cancel = c
                })
              }
            )
            .then((res) => {
              if (res.data.competitors.length > 0) {
                if (
                  res.data.competitors.length === 1 &&
                  res.data.competitors[0].competitor_id === dataObj.facebook_id
                ) {
                } else {
                  compId.push({ id: dataObj._id, name: dataObj.name })
                }
              }
            })
            .catch((err) => {
              console.log(err)
            })
        )
        this.requestCancelPromise.get_comp.push(cancel)
      })
      Promise.all(promises).then(() => {
        console.log('routing', compId)
        compId = compId.sort((a, b) => (a.name > b.name ? 1 : -1))
        console.log('routingggggggggggggg', compId)
        if (compId.length <= 0) {
          this.modalFlag = true
        } else {
          console.log('routing', compId[0].id)
          this.$router.push({
            name: 'facebookCompetitorOverview_v3',
            params: { accountId: compId[0].id }
          })
        }
      })
    }
  }
}
</script>
