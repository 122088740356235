<template>
  <div class="analytics-posts-section">
    <section class="contains-filter-btn">
      <h2>Twitter Top Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post's lifetime performance.</p
      >
      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown mr-2 post-filter-btn dropdown-size-small"
        no-caret
        style="right: 12px; top: 25px"
      >
        <template slot="button-content">
          <i class="cs-world-fill studio-theme-icon"></i>
          <span class="capitalize_text">Top {{ postLimitCount }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item @click="setTopPosts(5)">Top 5</b-dropdown-item>
        <b-dropdown-item @click="setTopPosts(10)">Top 10</b-dropdown-item>
        <b-dropdown-item @click="setTopPosts(15)">Top 15</b-dropdown-item>
      </b-dropdown>
      <div>
        <div class="analytics-tabs-content">
          <div class="analytics-tab-header grid-flex">
<!--            <div class="cell-2 analytics-tab-header__item">-->
<!--              <span>Accounts</span>-->
<!--            </div>-->
<!--            <div-->
<!--              class="cell-2 analytics-tab-header__item justify_content_start"-->
<!--            >-->
<!--              <span>Tweet Image</span>-->
<!--            </div>-->
            <div
              class="cell-8 analytics-tab-header__item justify_content_start"
            >
              <span>Tweets</span>
            </div>
            <div class="analytics-tab-header__item pr-0 cell-1"></div>
            <div class="analytics-tab-header__item pr-0 cell-1">
              <span>Engagements</span>
              <span class="analytics-tab-header__item-controls">
                <!--                <i class="fas fa-caret-up active"></i>-->
                <!--                <i class="fas fa-caret-down "></i>-->
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.engagements.up ? 'active' : ''"
                  @click="changeSort('engagements', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.engagements.down ? 'active' : ''"
                  @click="changeSort('engagements', 'down')"
                ></i>
              </span>
            </div>
            <div class="analytics-tab-header__item pr-0 cell-1">
              <span>Retweets</span>
              <span class="analytics-tab-header__item-controls">
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.retweets.up ? 'active' : ''"
                  @click="changeSort('retweets', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.retweets.down ? 'active' : ''"
                  @click="changeSort('retweets', 'down')"
                ></i>
              </span>
            </div>
            <div class="analytics-tab-header__item pr-0 cell-1">
              <span>Favorites</span>
              <span class="analytics-tab-header__item-controls">
                <i
                  class="fas fa-caret-up"
                  :class="sort_types.favorites.up ? 'active' : ''"
                  @click="changeSort('favorites', 'up')"
                ></i>
                <i
                  class="fas fa-caret-down"
                  :class="sort_types.favorites.down ? 'active' : ''"
                  @click="changeSort('favorites', 'down')"
                ></i>
              </span>
            </div>
          </div>
        </div>

        <div class="analytics-tab-body">
          <!--  Loader  -->
          <template v-if="fetchingTopPosts">
            <div class="grid-flex">
              <div class="analytics-tab-body__item cell-2">
                <div class="analytics-tab-body__item-profile-header">
                  <div>
                    <SkeletonBox height="2.8rem" width="2.8rem" radius="50%" />
                  </div>
                  <div>
                    <div class="d-flex" style="flex-direction: column">
                      <div class="mb-1">
                        <SkeletonBox width="100px" radius=".2rem" />
                      </div>
                      <SkeletonBox width="130px" radius=".2rem" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="analytics-tab-body__item cell-2 justify_content_start"
              >
                <div class="analytics-tab-body__item-image">
                  <SkeletonBox width="100%" height="130px" radius=".2rem" />
                </div>
              </div>
              <div
                class="analytics-tab-body__item cell-4 cell-xl-2 cell-xxl-4 justify_content_start"
              >
                <div
                  class="d-flex"
                  style="flex-direction: column; margin-left: 1rem; width: 70%"
                >
                  <div class="mb-1">
                    <SkeletonBox width="100%" radius=".2rem" />
                  </div>
                  <div class="mb-1">
                    <SkeletonBox width="90%" radius=".2rem" />
                  </div>
                  <SkeletonBox radius=".2rem" />
                </div>
              </div>
              <div class="analytics-tab-body__item py-0 cell-2">
                <SkeletonBox width="1.3rem" radius=".2rem" />
              </div>
              <div class="analytics-tab-body__item py-0 cell-1">
                <SkeletonBox width="1.3rem" radius=".2rem" />
              </div>
              <div class="analytics-tab-body__item py-0 cell-1">
                <SkeletonBox width="1.3rem" radius=".2rem" />
              </div>
            </div>
          </template>

          <SinglePostRow
            v-else-if="postItems && postItems.length"
            v-for="post in postItems"
            :type="'twitter'"
            :post="post"
            :key="post.tweet_id"
          />

          <div v-else class="analytics-no-data-found">
            <img
              src="../../../assets/imgs/no_data_images/no_post_found.svg"
              alt=""
            />
            <p>You have not published any posts yet.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SinglePostRow from '@src/modules/analytics/views/common/SinglePostRow'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import * as _ from 'underscore'

export default {
  name: 'PostsTab',
  mixins: [analyticsUtilsMixin],
  props: ['fetchingTopPosts', 'top5Posts', 'top10Posts', 'top15Posts'],
  data: function () {
    return {
      existing_sort: 'engagements_up',
      postLimitCount: 15,
      postItems: [],
      sort_types: {
        engagements: {
          up: true,
          down: false
        },
        retweets: {
          up: false,
          down: false
        },
        favorites: {
          up: false,
          down: false
        }
      }
    }
  },
  components: {
    SinglePostRow,
    SkeletonBox
  },
  mounted () {
    this.postItems = this.top15Posts
  },
  methods: {
    postClicked (data) {
      alert('Clicked ' + JSON.stringify(data))
    },
    setTopPosts (count) {
      this.postLimitCount = count
      if (count === 5) {
        this.postItems = this.top5Posts
      } else if (count === 10) {
        this.postItems = this.top10Posts
      } else {
        this.postItems = this.top15Posts
      }
    },
    changeSort (field, type) {
      const res = this.existing_sort.split('_')
      if (field === res[0] && type === res[1]) {
        return
      }
      let sort_field = false
      switch (field) {
        case 'engagements':
          sort_field = this.getEngagementField('twitter')
          break
        case 'retweets':
          sort_field = 'retweet_count'
          break
        case 'favorites':
          sort_field = this.getLikeField('twitter')
          break
      }
      if (sort_field === false) {
        return
      }

      if (type === 'up') {
        this.postItems = _.sortBy(this.postItems, sort_field).reverse()
      } else {
        this.postItems = _.sortBy(this.postItems, sort_field)
      }
      console.log(sort_field)
      console.log(this.postItems)

      this.existing_sort = field + '_' + type
      this.sort_types[field][type] = true
      this.sort_types[res[0]][res[1]] = false
    }
  },
  watch: {
    top15Posts (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setTopPosts(this.postLimitCount)
      }
    }
  }
}
</script>
