<style>
.bg_main {
  justify-content: center;
}

.bg_count {
  margin: auto;
  background-color: rgba(47, 138, 224, 0.1);
  border-radius: 0.9rem;
  /* justify-content: center; */
  height: 2rem;
  width: 2rem;
}

.bg_p {
  font-size: 1rem;
  color: #357be2;
  position: relative;
  top: 0.3rem;
}

.bg {
  font-size: 13px;
  height: 30px;
  margin: auto;
  width: 30px;
  border-radius: 0.9rem;
}

.bg_i {
  border-radius: 20px;
  color: #ffffff;
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  position: relative;
  bottom: 0.7rem;
  left: 1rem;
}
</style>

<template>
  <div class="page_view d-flex align-items-center justify-content-center">
    <div class="subpage">
      <div v-if="company_logo" class="flex-column company-logo-cover m-0">
        <img :src="company_logo" alt="" />
        <h3>{{ company_name }}</h3>
      </div>
      <div class="logo_block flex-column">
        <template v-if="type === 'group'">
          <i
            :class="getIcon()"
            class="d-flex align-items-center justify-content-center"
          ></i>
        </template>
        <template v-else>
          <img class="icon w-25" :src="getAccounts().image" />
        </template>
        <h2 v-if="type !== 'group'">{{ getAccounts().name }}</h2>
        <h3>{{ getReportName }} Report</h3>

        <div v-if="type === 'group'" class="d-flex bg_main pb-2">
          <!-- for the social channels tooltip -->
          <template v-for="(channel, index) in getAccounts()">
            <div class="flex-row pr-1" v-if="index < 10" :key="index">
              <div>
                <img class="bg" :src="channel.image" />
                <div
                  :class="getClassName(channel.account_type)"
                  class="bg_i"
                ></div>
              </div>
            </div>
          </template>

          <div v-if="getAccounts().length > 10" class="flex-row">
            <div class="bg_count">
              <div class="bg_p">+ {{ getAccounts().length - 10 }}</div>
            </div>
          </div>
        </div>

        <p>{{ getReportDate }}</p>
      </div>
      <div class="generated-date flex-column">
        <p>Generated on {{ getGeneratedDate() }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    report_date: {
      default: ''
    },
    accounts: {
      default () {
        return []
      }
    },
    type: {
      default: 'Twitter'
    }
  },
  data () {
    return {
      company_name: '',
      company_logo: ''
    }
  },
  mounted () {
    this.getCompanyDetails()
  },
  computed: {
    getReportDate () {
      if (this.report_date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }

        const splitDates = this.report_date.split(' - ')
        return (
          Intl.DateTimeFormat('en-US', options).format(
            Date.parse(splitDates[0])
          ) +
          ' - ' +
          Intl.DateTimeFormat('en-US', options).format(
            Date.parse(splitDates[1])
          )
        )
      }
    },

    getReportName () {
      if (this.type) {
        return this.type.charAt(0).toUpperCase() + this.type.slice(1)
      }
    },
    getName () {
      return this.report_name
    }
  },
  methods: {
    getCompanyDetails () {
      this.company_name = this.getActiveWorkspace.company_name
      this.company_logo = this.getActiveWorkspace.company_logo
    },
    getGeneratedDate () {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }

      return Intl.DateTimeFormat('en-US', options).format(Date.now())
    },
    getIcon () {
      return this.getClassName(this.type)
    },
    getClassName (type) {
      switch (type) {
        case 'twitter':
          return 'tw_bg cs-twitter'
        case 'facebook':
          return 'fb_bg cs-facebook'
        case 'linkedin':
          return 'lin_bg cs-linkedin'
        case 'pinterest':
          return 'pin_bg cs-pinterest'
        case 'instagram':
          return 'ins_bg cs-instagram'
        case 'group':
          return 'tum_bg cs-group'
      }
    },
    getAccounts () {
      console.log('this.accounts')
      console.log(this.accounts)
      return this.accounts
    }
  }
}
</script>
