<template>
  <div class="compare-box height-100">
    <div class="compare-box__header flex align-items-center">
      <div class="compare-box__platform">
        <i class="fab fa-instagram ins "></i>
      </div>
      Word CLoud (Hashtags)
      <div class="ml-auto">
        <i
            class="fa fa-info info_hover"
            v-b-popover.hover.html.topleft="description "
        ></i>
      </div>
    </div>

    <div
        v-if="!state"
        class="opacity-70"
        style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'20px'"></clip-loader>
    </div>
    <div class="h-chart" v-else>
      <p class="text-center" v-if="competitors_data.length<=0">
        Data not found for the selected pages and filters
      </p>
      <div class="grid-flex">

        <div
            :class="Object.keys(competitors_data).length == 1?'cell-lg-12 mb-6 ':([2,4].includes(Object.keys(competitors_data).length)?'cell-lg-6 mb-3 ':'cell-lg-4 mb-2 ')"
            v-for="(val,key) in active_competitors" :key="key" v-if="competitors_data.hasOwnProperty(key)">
          <div class="p-1 cloud_borders" v-if="!(competitors_data[key].hasOwnProperty('status'))">
            <highcharts
                ref="growth-chart-container"
                :options="competitors_data[key]"
            >
            </highcharts>
          </div>
          <div class="p-1 cloud_borders flex flex-col justify_center items-center" v-else>
            <span :style="competitors_data[key]['title']['style']">{{competitors_data[key]['title']['text']}}</span>
            <div class="analytics-no-data-found">
              <img class="pl-1 pr-1" style="height:260px;width:100%;"
                  src="../../../../assets/imgs/no_data_images/no_post_found.svg"
                  alt=""
              />
              <p>No data found within applied filters.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import api_ops from "@src/modules/analytics/views/common/competitors/instagram/api_ops";
import {themeColors} from "@src/theme";

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  name: "WordCLoud",
  mixins: [analyticsUtilsMixin, api_ops],
  data() {
    return {
      dataOptions: {
        title: {
          text: ' '
        },
        chart: {
          marginTop: 50,
          type: 'line'
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },

        xAxis: {},

        colors: [
          themeColors.blue[200],
          themeColors.green[400],
          themeColors.purple[400],
          themeColors.pink[500],
          themeColors.orange[500],
          themeColors.red[500],
        ],
        plotOptions: {
          series: {
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            },
            shadow: true,
            lineWidth: 4
          }
        },
        series: [{
          type: 'wordcloud',
          data: [{
            "name": 'a',
            "weight": 1
          },
            {
              "name": 'b',
              "weight": 5
            },
            {
              "name": 'c',
              "weight": 4
            },
            {
              "name": 'd',
              "weight": 10
            }],
          name: 'Occurrences'
        }]
      },
      description: "<span class=\"font-0-75rem\">The WordCloud presents most used/dominant Hashtags in the posts of relevant pages, within the applied timeframe.</span>",
      requestCancelPromise: {},
      active_competitors: {},
      competitors_data: {},
      state: false

    }
  },
  props: ['type', 'reload', 'selected_competitors', 'time_period', 'selected_account_id'],

  watch: {
    reload(newValue, oldValue) {
      this.active_competitors = {}
      this.competitors_data = {}
      this.state = false
      this.fetchChartData()
    }
  },

  mounted() {
    this.state = false
    this.fetchChartData()
  },
  methods: {
    fetchChartData() {

      let our = {}
      let theirs = this.selected_competitors.filter((obj) => {
        if (obj.competitor_id == this.selected_account_id) {
          our = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.display_name > b.display_name ? 1 : -1)
      let competitors = [our, ...theirs]
      competitors.forEach((obj) => {
        if (obj['enabled'] && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj['competitor_id']] = obj
        }
      })

      this.cancelPendingRequest('Hashtags')
      let ids = Object.keys(this.active_competitors);
      let promises = []
      ids.forEach((id) => {
        let promise = this.getIgHashtags([id], this.time_period)
        this.requestCancelPromise['Hashtags'].push(promise[0])
        promises.push(promise[1])
        promise[1].then((res) => {
          if (res.data.data.length > 0) {
            let counts = {};
            let tags = res.data.data[0]['hashtags'].split(',').filter(v => v.startsWith('#'))
            if (tags.length > 0) {

              tags.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
              });
              counts = Object.keys(counts).map(function (key, index) {
                return {
                  "name": key.replace('#', ''),
                  "weight": counts[key]
                }

              });
              let series = [{
                type: 'wordcloud',
                data: [...counts],
                // rotation: {
                //   from: -60,
                //   to: 60,
                //   orientations: 5
                // },
                name: 'Occurrences'
              }]
              let title = {
                text: this.active_competitors[id].display_name,
                style: {
                  color: 'rgb(64 69 87)',
                  fontWeight: 'bold'
                }
              }
              this.competitors_data[id] = {...this.dataOptions}
              this.competitors_data[id]['title'] = {...title}
              this.competitors_data[id]['series'] = [...series]
              console.log("hashtags", this.competitors_data, series)
            }
            else{
              this.addPlaceHolder(id)
            }
          } else {
            this.addPlaceHolder(id)
          }
        })

      })
      Promise.all(promises).then(() => {

        this.state = true
      })

    },
    addPlaceHolder(id) {
      this.competitors_data[id] = {...this.dataOptions}
      let title = {
        text: this.active_competitors[id].display_name,
        style: "color:rgb(64 69 87);font-size:18px;font-weight:bold;fill:rgb(64 69 87);"
      }
      let series = [{
        type: 'wordcloud',
        data: [['Data Not Available', 1]],
        name: 'Occurrences'
      }]
      this.competitors_data[id]['title'] = {...title}
      this.competitors_data[id]['status'] = false

    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
  }
}
</script>

<style scoped>
.cloud_borders {
  border-style: solid;
  border-color: #f0f0f0;
  border-width: thin;
  border-radius: 1rem;
}
</style>
