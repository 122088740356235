<template>
  <div id="instagram_tab">
    <div class="instagram_post_preview">
      <div class="instagram_post_preview__head">
        <div class="instagram_post_preview__head__profile_picture">
          <template v-if="planner">
            <div
              class="instagram_post_preview__head__profile_picture__picture_block"
            >
              <img
                :src="instagramImage(data.account)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
            </div>
            <div
              class="instagram_post_preview__head__profile_picture__text_block"
            >
              <p class="text">{{ data.account.name }}</p>
            </div>
          </template>
          <template v-else>
            <div
              class="instagram_post_preview__head__profile_picture__picture_block"
            >
              <img
                :src="instagramImage(getPreviewAccount.instagram)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
            </div>
            <div
              class="instagram_post_preview__head__profile_picture__text_block"
            >
              <p class="text">{{ getPreviewAccount.instagram.name }}</p>
            </div>
          </template>
        </div>
      </div>

      <div class="instagram_post_preview__preview_images">
        <div class="" v-if="details.image.length > 1">
            <Carousel
                :per-page="1"
                :autoplay="true"
                :autoplay-timeout="3000"
                :loop="true"
                paginationActiveColor="#6173ff"
                paginationColor="rgba(58, 69, 87, 0.2)"
                :navigation-enabled="true"
                :pagination-enabled="true"
                class="instagram-preview-carousel"
                 >
                <Slide v-for="(image, index) in details.image" :key="index" class="w-100">
                  <div class="w-100 h-80 insta-image"  :style="{ 'background': 'url(' + image + ')' }">
                    <img :src="image" class="hidden" alt="" />
                  </div>
                </Slide>
            </Carousel>
<!--          <b-carousel id="carousel-instagram" :interval="0" controls indicators>-->
<!--            <b-carousel-slide-->
<!--              :key="index"-->
<!--              v-for="(image, index) in details.image"-->
<!--            >-->
<!--              <template v-slot:img>-->
<!--                <div class="img w-100" slot="img" :style="{ 'background': 'url(' + image + ')' }">-->
<!--                  <img :src="image" alt="" class="hidden"/>-->
<!--                </div>-->

<!--                &lt;!&ndash;<div slot="img" class="img" :style="{ 'background-image': 'url(' + image + ')' }"></div>&ndash;&gt;-->
<!--              </template>-->
<!--            </b-carousel-slide>-->
<!--          </b-carousel>-->
        </div>

        <div v-if="details.image.length === 1">
          <img class="single_image" :src="details.image[0]" alt="" />
        </div>
      </div>
      <div class="flex items-center px-3 py-1 reaction-icons space-x-5 text-xl">
        <i class="far fa-heart"></i>
        <i class="far fa-comment"></i>
        <i class="far fa-paper-plane"></i>
        <div class="ml-auto"><i class=" far fa-bookmark"></i></div>
      </div>
      <div
        class="instagram_post_preview__preview_desc"
        v-html="processSharingPreviewDesciption(details.message, 'Instagram')"
      ></div>

      <div class="location_preview" v-if="getLocationName">
        <h3>
          <img src="../../../../../../assets/img/locate.png" />
          {{ getLocationName }}
        </h3>
      </div>

      <template v-if="details.video && details.video.link">
        <div
          class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
        >
          <div
            class="img"
            :style="{
              'background-image':
                'url(' + getValidThumbnail(details.video.thumbnail) + ')',
            }"
          >
            <div class="facebook_vid_icon vid_icon">
              <span class="icon"></span>
            </div>
          </div>
        </div>
      </template>

<!--      <div class="rection_icons">-->
<!--        <i class="far fa-heart"></i> <i class="far fa-comment"></i>-->
<!--        <i class="far fa-paper-plane"></i>-->
<!--      </div>-->
      <!--  First Comment Preview  -->
      <template v-if="planner">
        <div class="first-comment-preview">
          <div class="first-comment-preview__img">
            <img
              :src="instagramImage(data.account)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div class="first-comment-preview__text" v-html="n2br(data.message)">
          </div>
        </div>
        <div v-if="data.commentResponse" class="comments-status-box">
          <p class="ml-2" v-html="data.commentResponse"></p>
        </div>
      </template>
      <template v-else>
        <div
          class="first-comment-preview"
          v-if="
            getSocialSharingFirstComment.has_first_comment &&
            getSocialSharingFirstComment.first_comment_message.trim().length &&
            hasEnableFirstComment(getPreviewAccount.instagram.instagram_id)
          "
        >
          <div class="first-comment-preview__img">
            <img
              :src="instagramImage(getPreviewAccount.instagram)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div
            class="first-comment-preview__text"
            v-html="n2br(getSocialSharingFirstComment.first_comment_message)"
          >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'
import { Carousel, Slide } from 'vue-carousel'
export default {
  props: ['details', 'planner', 'data'],
  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getSocialSharingFirstComment'
    ]),
    getBoxType () {
      return this.getCommonBoxOptions.status ? 'Common' : 'Instagram'
    },
    getLocationName () {
      if (this.details.location) {
        if (this.getBoxType === 'Common' && this.details.location.instagram) {
          return this.details.location.instagram.name
        } else if (this.details.location.name) {
          return this.details.location.name
        }

        // return this.getBoxType === 'Common' ? this.details.location.instagram.name : this.details.location.name
      }
      return ''
    }
  },
  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    hasEnableFirstComment (id) {
      return (
        this.getSocialSharingFirstComment.first_comment_accounts &&
        this.getSocialSharingFirstComment.first_comment_accounts.includes(id)
      )
    },
    getValidThumbnail (selectedThumb) {
      // Getting list of valid thumnbnails for insta
      let thumbnailsList = [...this.details.video.thumbnails_suggestions]
      thumbnailsList = thumbnailsList.filter((thumbnail) => {
        if (!thumbnail.includes('contentstudio-media-library-nearline')) {
          return thumbnail
        }
      })

      if (thumbnailsList.length) {
        // check if is the thumbnail is custom
        if (!selectedThumb.includes('contentstudio-media-library-nearline')) {
          return selectedThumb
        } else {
          return thumbnailsList[0]
        }
      } else {
        return selectedThumb
      }
    }
  }
}
</script>

<style scoped lang="scss">
.insta-image{
  background-size: cover!important;
  background-position: center center!important;
  background-repeat: no-repeat!important;
}
</style>
