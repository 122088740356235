<template>
  <div class="pt-5" v-if="!getDiscoverRequestCompleted">
    <!-- NOTE: hide a loader for the time being -->
    <beat-loader :color="'#436aff'"></beat-loader>
  </div>
  <div v-else class="discovery_article_component">
    <curated-topic></curated-topic>
    <article-preview></article-preview>
    <create-topic></create-topic>
    <twitter-influencer></twitter-influencer>
    <div class="component_inner">
      <template v-if="isTopicsAvailable === false">
        <!-- we can check status for the both quick topics and curated topics and after that show if both are 0. -->
        <div class="no_topic">
          <div class="no_topic_inner">
            <h2>Welcome to Topics</h2>
            <p
              >Create customized feeds to bring fresh and engaging content
              relevant to the keywords, topics and source of your choice.</p
            >

            <div class="d-flex align-items-center justify-content-center mt-5">
              <div class="img_box" @click.prevent="followCuratedTopicsModal()">
                <i class="cs-heart-file"></i>

                <span class="text">Follow Curated Topics</span>
              </div>

              <template v-if="hasPermission('can_save_topic')">
                <div class="or_sep">
                  <span class="sep_text">OR</span>
                </div>
                <div class="img_box" @click.prevent="createNewTopic()">
                  <i class="fas fa-file-edit"></i>
                  <span class="text">Create a Custom topic</span>
                </div>
              </template>
            </div>

            <!--<button data-toggle="modal" data-target="#create_topic_modal" class="btn">Create Your Topic Feed</button>-->
          </div>
        </div>
      </template>
      <template v-else>
        <div class="topics-pane">
          <template v-if="getShowPageLoaded === false">
            <topics-sidebar-loader></topics-sidebar-loader>
          </template>
          <template v-else>
            <topics-sidebar></topics-sidebar>
          </template>

          <div class="right_content">
            <!--for topics -- remove d-none class .......-->
            <div class="ds_inner_pages" style="padding: 0; border-top: 0">
              <!-- NOTE: not using the routes as they are slower and does not show the loader-->

              <template v-if="!getCoveredStories && !getCuratedQuotes">
                <topics-filter-results></topics-filter-results>
                <div
                  class="topics-filters-pane"
                  v-if="
                    getTopicModule === 'content' &&
                    getTopicType !== 'favorite' &&
                    getTopicType !== 'archived'
                  "
                >
                  <div class="topics-filters-pane__container">
                    <topics-channel></topics-channel>
                    <combined-article-filters></combined-article-filters>
                    <combined-video-filters></combined-video-filters>
                    <combined-facebook-filters></combined-facebook-filters>
                    <combined-twitter-filters></combined-twitter-filters>
                  </div>
                </div>
                <!-- change the pattern to show the posts -->
                <topics-view-results></topics-view-results>
              </template>
              <!--<transition name="discovery-route">-->
              <router-view :key="$route.fullPath"></router-view>

              <!--</transition>-->
            </div>

            <!--// for analytics and insights.-->
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'

// import sub-components.

import TopicsSidebar from './sub-components/TopicsSidebar'
import TopicsSidebarLoader from './sub-components/TopicsSidebarLoader'

// rendering the view based on grid/list view.

import GridView from '../common/GridView'
import ListView from '../common/ListView'

import InfiniteLoading from 'vue-infinite-loading'

import NoResultsFound from './NoResultsFound'

const TopicsFilterResults = () => import('./sub-components/TopicsFilterResults')
const TopicsChannel = () => import('./sub-components/TopicsChannel')
const TopicsViewResults = () => import('./sub-components/TopicsViewResults')

// channel filters

const CombinedArticleFilters = () =>
  import('./channel-filters/CombinedArticleFilters')
const CombinedVideoFilters = () =>
  import('./channel-filters/CombinedVideoFilters')
const CombinedTwitterFilters = () =>
  import('./channel-filters/CombinedTwitterFilters')
const CombinedFacebookFilters = () =>
  import('./channel-filters/CombinedFacebookFilters')

const ArticlePreview = () => import('../common/preview/ArticlePreview')
const CuratedTopic = () => import('./CuratedTopic')
const TwitterInfluencer = () => import('../common/TwitterInfluencer')
const CreateTopic = () => import('./CreateTopic.vue')
export default {
  components: {
    CreateTopic,
    GridView,
    ListView,
    InfiniteLoading,
    CuratedTopic,
    ArticlePreview,
    TwitterInfluencer,
    NoResultsFound,
    TopicsSidebar,
    TopicsSidebarLoader,
    TopicsFilterResults,
    TopicsViewResults,
    TopicsChannel,
    CombinedArticleFilters,
    CombinedVideoFilters,
    CombinedTwitterFilters,
    CombinedFacebookFilters
  },

  data () {
    return {
      layout: 'grid',
      runOnce: false,
      showLoaderRequest: true, // used for showing the create the topic page
      showPageLoaded: true // used for showing the page loader
    }
  },

  created () {
    // first parameter is for setting the method to know it is from created section.
    // this.initializeTopics(true)
  },
  mounted () {},

  computed: {
    ...mapGetters([
      'getTopics',
      'getTopic',
      'getTopicText',
      'getTopicType',
      'getPosts',
      'getTopicLoader',
      'getTopicsScroll',
      'getTopicsPage',
      'getTopicPaginationLoader',
      'getTopicModule',
      'getInsightsLoader',
      'getTopicDisabled',
      'getSidebarCustomizationLoader',
      'getSidebarCustomization',
      'getTopicContentType',
      'getVideoPaginationLoader',
      'getVideos',
      'getDiscoveryVideoLoader',
      'getVideoPage',
      'getVideoScroll',
      'getDiscoveryTwitterLoader',
      'getTwitterPaginationLoader',
      'getTweetsContent',
      'getTwitterContentPage',
      'getTwitterScroll',
      'getDiscoveryFacebookLoader',
      'getFacebookPaginationLoader',
      'getFacebookPosts',
      'getFacebookScroll',
      'getFacebookPage',
      'getMyCuratedTopicsList',
      'getTopicCreationModal',
      'getPostsTotal',
      'getShowPageLoaded',
      'getCoveredStories',
      'getCoveredStoriesRunOnce',
      'getTrendingStories',
      'getCuratedQuotes',
      'getCuratedTopicsLoader',
      'getCustomTopicsLoader',
      'getTwitterAccounts',
      'getWorkspaces',
      'getCustomSidebar',
      'getTopicsLayout',
      'getFolders',
      'getDiscoverRequestCompleted'
    ]),

    isTopicsAvailable () {
      if (
        (this.getTopics && this.getTopics.length > 0) ||
        (this.getMyCuratedTopicsList && this.getMyCuratedTopicsList.length > 0)
      ) {
        if (this.getTopicModule == 'insights') {
          return true
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations(['SET_TOPIC_CONTENT_TYPE', 'SET_TOPIC_MODULE']),
    ...mapActions([
      'setTopic',
      'setPreviewArticleContentLoader',
      'setSidebarCustomization',
      'setVideoPage',
      'resetVideo',
      'setTwitterContentPage',
      'resetTwitter',
      'setTrendingStatus',
      'setFacebookPage',
      'resetFacebook',
      'setPosts',
      'setCoveredStoriesRunOnce',
      'fetchCustomSidebar'
    ])
  },

  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Topics:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')
    next()
  },
  watch: {
    'getWorkspaces.activeWorkspace.slug' (slug) {
      this.setTrendingStatus(false)
      this.SET_TOPIC_MODULE('content')
      // this.SET_TOPIC_CONTENT_TYPE('article')
      // this.getCustomTopics()
      // this.myCuratedTopics()

      this.resetVideo()
      this.resetTwitter()
      this.resetFacebook()
      this.setSidebarCustomization(false)
      // if (!this.getCustomSidebarFetchStatus) {
      // this.fetchCustomSidebar()
      // this.setSidebarFetchStatus(true)
      // }
    }
  }
}
</script>

<style>
.discovery-route {
  transition: all 300ms ease-in-out 0ms;
}
.discovery-route-enter-active {
  transition: all 0.3s;
}
.discovery-route-leave-active {
  transition: all 0s;
}
.discovery-route-enter {
  opacity: 0;
  transform: translateX(-45px);
}
.discovery-route-leave-to {
  opacity: 0;
  transform: translateX(-45px);
}
</style>
