import { hashtagFromText, linksExtractor } from './extractor'

const textHelper = {
  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  convertToLinkClass(value) {
    const links = linksExtractor(value)
    if (links) {
      links.forEach((item) => {
        value = value.replace(
          item,
          "<a href='" +
            item +
            "' target='_blank' class='tab_link'>" +
            item +
            '</a>'
        )
      })
    }

    return value
  },
  convertToHashtagsClass(value) {
    const links = hashtagFromText(value)
    if (links) {
      links.forEach((item) => {
        value = value.replace(
          item,
          '<span class="is_hashtag">' + item + '</span>'
        )
      })
    }

    return value
  },

  onlyDateString(value) {
    return value.slice(0, 10)
  },

  onlyDateStringBetweenDates(string) {
    const explode = string.split(' - ')
    console.log(explode)
    return (
      textHelper.onlyDateString(replaceAllexplode[0]) +
      ' - ' +
      textHelper.onlyDateString(explode[1])
    )
  },
  onlyDateStringIntlTime(string) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }

    const splitDates = string.split(' - ')
    return (
      Intl.DateTimeFormat('en-US', options).format(Date.parse(splitDates[0])) +
      ' - ' +
      Intl.DateTimeFormat('en-US', options).format(Date.parse(splitDates[1]))
    )
  },

  /**
   * This method is use to find and replace substr in the description.
   * @param text
   * @param find
   * @param replace
   * @returns {*}
   */

  replaceInAllText(text, find, replace) {
    String.prototype.replaceAllPrototype = function (search, replacement) {
      return this.split(search).join(replacement)
    }
    return find ? text.replaceAllPrototype(find, replace) : text
  },

  replaceByHtmlNextLine(text) {
    if (!text) return text
    return this.replaceInAllText(text, '\n\n', '<br>')
  },
}
export default textHelper
