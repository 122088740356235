import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getFacebookPostLinkURL } from '@src/modules/discovery/config/api-utils'
import { fetchStorageLinkUrl } from '@src/modules/publish/config/api-utils'
import {
  gmb,
  commonTypes,
  blogPosting,
  instagramTypes,
  linkedinSharingTypes,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { UNKNOWN_ERROR } from '../../constants/messages'

require('jquery-ui-bundle')
const he = require('he')
// let socketIo = io('http://localhost:3000')
export const ckEditorConfig = {
  // Define changes to default configuration here.
  // For complete reference see:
  // http://docs.ckeditor.com/#!/api/CKEDITOR.config

  // adding extra plugin required
  // autogrow
  extraPlugins: 'divarea,autogrow,dragresize,wordcount', // simage
  // extraPlugins :'divarea',//simage

  // testFunction: blogPostMixin.methods.blogPostAutoSave,
  wordcount: {
    // Whether or not you want to show the Word Count
    showWordCount: true,

    // Whether or not you want to show the Char Count
    showCharCount: false,

    // Maximum allowed Word Count
    maxWordCount: -1,

    // Maximum allowed Char Count
    maxCharCount: -1,
  },

  // extra options
  allowedContent: true,
  resize_enabled: false,
  // Remove tags on paste text
  forcePasteAsPlainText: true,

  // The toolbar groups arrangement, optimized for two toolbar rows.
  toolbar: [
    // first line items
    { name: 'format', items: ['Format'] },
    {
      name: 'align',
      items: ['JustifyCenter', 'JustifyLeft', 'JustifyRight', 'JustifyBlock'],
    },
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Blockquote'],
    },
    { name: 'liststyle', items: ['NumberedList', 'BulletedList'] },
    { name: 'link', items: ['Link', 'Unlink'] },
    {
      name: 'spinnerbutton',
      items: ['SpinnerButton'],
      class: 'spinner_btn_editor',
    },
    {
      name: 'pasteFromWord',
      items: ['pasteFromWord'],
      class: 'paste_word_btn',
    },
    { name: 'insertFile', items: ['InsertFile'], class: 'upload_file_btn' },
    {
      name: 'toggleToolbar',
      items: ['toggleToolbar'],
      class: 'toggle_btn_editor',
    },

    '/',
    // second line items
    { name: 'fontsize', items: ['FontSize'], class: 'hidethis' },
    { name: 'image', items: ['Image'], class: 'hidethis' },
    { name: 'strike', items: ['Strike', 'HorizontalRule'], class: 'hidethis' },
    { name: 'colorstyle', items: ['BGColor', 'TextColor'], class: 'hidethis' },
    {
      name: 'styleformat',
      items: ['CopyFormatting', 'RemoveFormat'],
      class: 'hidethis',
    },
    { name: 'indent', items: ['Outdent', 'Indent'], class: 'hidethis' },
    { name: 'state', items: ['Undo', 'Redo'], class: 'hidethis' },
    { name: 'search', items: ['Find', 'Replace'], class: 'hidethis' },
    { name: 'fullscreen', items: ['Maximize'], class: 'hidethis' },
    { name: 'Source', items: ['Source'], class: 'hidethis' },
  ],

  toolbarGroups: [
    // first line group

    { name: 'format', groups: ['format'] },
    { name: 'align', groups: ['align'] },
    { name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'liststyle', groups: ['liststyle'] },
    { name: 'link', groups: ['link'] },
    { name: 'spinnerbutton', groups: ['spinnerbutton'] },
    { name: 'pasteFromWord', groups: ['pasteFromWord'] },
    { name: 'insertFile', groups: ['insertFile'] },
    { name: 'toggleToolbar', groups: ['toggleToolbar'] },
    '/',

    // second line group
    { name: 'fontsize', groups: ['fontsize'] },
    { name: 'image', groups: ['image'] },
    { name: 'strike', groups: ['strike'] },
    { name: 'colorstyle', groups: ['colorstyle'] },
    { name: 'styleformat', groups: ['styleformat'] },
    { name: 'indent', groups: ['indent'] },
    { name: 'state', groups: ['state'] },
    { name: 'search', groups: ['search'] },
    { name: 'fullscreen', groups: ['fullscreen'] },
    { name: 'Source', groups: ['Source'] },
    // '/',
    //
    // { name: 'styles', groups: [ 'format' ] },
    // // second  line group
    // { name: 'format', groups: [ 'format' ] },
    // { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    // { name: 'forms', groups: [ 'forms' ] },
    // { name: 'links', groups: [ 'links' ] },
    // { name: 'colors', groups: [ 'colors' ] },
    // { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    // { name: 'paragraph', groups: [ 'insertfile', 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    // // { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    //
    // { name: 'insert', groups: [ 'insert' ] },
    //
    // { name: 'tools', groups: [ 'tools' ] },
    // { name: 'others', groups: [ 'others' ] },
    // { name: 'about', groups: [ 'about' ] },
    // { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    // { name: 'document', groups: [ 'mode', 'document', 'doctools' ] }
  ],

  // extraAllowedContent : 'sup;em(*)[data-link,data-type];h1 h2 h3 h4 h5 h6[hidechildren];div(as-editor-card,as-editor-card-handle,as-editor-card-controls)[contenteditable,ref,card-title,data-as-indent];a(merge,remove,citation-ref)[data-id,data-ref-id,data-link];*(wysiwyg-text-align-left,wysiwyg-text-align-center,wysiwyg-text-align-right);span(caption);',

  // Remove some buttons provided by the standard plugins, which are
  // not needed in the Standard(s) toolbar.
  // config.removeButtons : 'Underline,Subscript,Superscript',
  removeButtons:
    'Save,NewPage,Preview,Print,Templates,Copy,Cut,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Radio,Form,Checkbox,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Language,Anchor,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,Font,ShowBlocks,About',

  // Set the most common block elements.
  format_tags: 'p;h1;h2;h3;h4;h5;h6;pre',

  // Simplify the dialog windows.
  // config.removeDialogTabs : 'image:advanced;link:advanced',
  // removeDialogTabs : 'image:advanced;link:advanced;image:Link',
  imageResize: { maxWidth: 800, maxHeight: 800 },
  removePlugins: 'magicline,lite,image2,placeholder,elementspath,iframe',
}
export const editorMixin = {
  computed: {
    ...mapGetters([
      'getBlogEditorSelector',
      'getCommonBoxOptions',
      'getSocialSharingSelection',
      'getPinterestboxRef',
      'getFacebookboxRef',
      'getInstagramboxRef',
      'getTwitterboxRef',
      'getGmbboxRef',
      'getLinkedinboxRef',
      'getTumblrboxRef',
      'getCommonboxRef',
      'getBlogPostingDetails',
      'getWorkspaces',
      'getBlogAutoSaveInterval',
      'getEditorTypingInterval',
      'getPublishSelection',
      'getProfile',
      'getSocketId',
      'getPlanControl',
      'getPlanKey',
      'getYoutubeboxRef',
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_COMMON_SHARING_VIDEO',
      'SET_FACEBOOK_SHARING_VIDEO',
      'SET_TWITTER_SHARING_VIDEO',
      'SET_TUMBLR_SHARING_VIDEO',
      'SET_PINTEREST_SHARING_VIDEO',
    ]),
    ...mapActions([
      'setBlogEditorSelector',
      'setCommonSharingDetails',
      'setFacebookSharingDetails',
      'setTwitterSharingDetails',
      'setPinterestSharingDetails',
      'setTumblrSharingDetails',
      'setUploadCommonSharingImageLoader',
      'setCommonSharingImage',
      'setFetchCommonSharingPreviewLoader',
      'setUploadFacebookSharingImageLoader',
      'setFacebookSharingImage',
      'setFetchFacebookSharingPreviewLoader',
      'setUploadTwitterSharingImageLoader',
      'setTwitterSharingImage',
      'setFetchTwitterSharingPreviewLoader',
      'setFetchLinkedinSharingPreviewLoader',
      'setUploadPinterestSharingImageLoader',
      'setPinterestSharingImage',
      'setFetchPinterestSharingPreviewLoader',
      'setUploadTumblrSharingImageLoader',
      'setTumblrSharingImage',
      'setFetchTumblrSharingPreviewLoader',
      'setFetchInstagramSharingPreviewLoader',
      'setFetchYoutubeSharingPreviewLoader',
      'setCommonSharingPreview',
      'setFacebookSharingPreview',
      'setFacebookSharingImageSuggestions',
      'setTwitterSharingPreview',
      'setTwitterSharingImageSuggestions',
      'setPinterestSharingPreview',
      'setTumblrSharingPreview',
      'setRewritingType',
      'setCommonCancelledLink',
      'setFacebookCancelledLink',
      'setTwitterCancelledLink',
      'setPinterestCancelledLink',
      'setTumblrCancelledLink',
      'setInstagramCancelledLink',
      'setBlogContentEmpty',
    ]),

    checkComposerDragDrop() {
      console.debug('Method::checkComposerDragDrop', this.$route.name)
      if (this.$route.name === 'composerBlog') {
        this.callSortableFunctionBlog()
      } else if (this.$route.name === 'composerSocial') {
        this.callSortableFunctionSocial()
      }
    },

    processPixabayImage(element, type) {
      console.debug('Method::processPixabayImage', element, type)

      let image = ''
      if (type === 'Compose Post') {
        image = element.image
      } else {
        image = element
      }

      this.postRequest(
        fetchStorageLinkUrl,
        { link: image, workspace_id: this.getActiveWorkspace._id },
        (response) => {
          if (response.data.status === true) {
            if (response.data.media) {
              this.$store.dispatch(
                'updateSocialSharingMedia',
                response.data.media
              )
            }

            switch (type) {
              case 'Compose Post':
                $('#' + element.loaderID).fadeOut(400, function () {
                  $('#' + element.loaderID)
                    .siblings('img')
                    .attr('src', response.data.link)
                    .removeClass('hide')
                  $(this).remove()
                })
                break
              case 'Common':
                this.setCommonSharingImage([response.data.link])
                break
              case 'Facebook':
                this.setFacebookSharingImage([response.data.link])
                break
              case 'Twitter':
                this.setTwitterSharingImage([response.data.link])
                break
              case 'Linkedin':
                this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [
                  response.data.link,
                ])
                break
              case 'Pinterest':
                this.setPinterestSharingImage(response.data.link)
                break
              case 'Tumblr':
                this.setTumblrSharingImage(response.data.link)
                break
              case 'Instagram':
                this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [
                  response.data.link,
                ])
                break
              case 'Gmb':
                this.$store.commit(gmb.SET_SHARING_IMAGE, response.data.link)
                break
              // case 'Youtube':
              //   this.$store.commit(youtubeTypes.SET_SHARING_IMAGE, response.data.link)
              //   break
            }
          } else {
            this.alertMessage(response.data.message, 'error')
            if (type === 'Compose Post') {
              $('#' + element.loaderID)
                .parents('.editor_dragable_box')
                .remove()
            }
          }

          switch (type) {
            case 'Common':
              this.setUploadCommonSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Facebook':
              this.setUploadFacebookSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Twitter':
              this.setUploadTwitterSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Linkedin':
              this.$store.commit(
                linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
                { status: false, position: -1 }
              )
              break
            case 'Pinterest':
              this.setUploadPinterestSharingImageLoader(false)
              break
            case 'Tumblr':
              this.setUploadTumblrSharingImageLoader(false)
              break
            case 'Instagram':
              this.$store.commit(
                instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
                { status: false, position: -1 }
              )
              break
            case 'Gmb':
              this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
              break
          }
        },
        (response) => {
          console.debug('Exception in processPixabayImage', response)
          switch (type) {
            case 'Compose Post':
              $('#' + element.loaderID)
                .parents('.editor_dragable_box')
                .remove()
              break
            case 'Common':
              this.setUploadCommonSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Facebook':
              this.setUploadFacebookSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Twitter':
              this.setUploadTwitterSharingImageLoader({
                status: false,
                position: -1,
              })
              break
            case 'Linkedin':
              this.$store.commit(
                linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
                { status: false, position: -1 }
              )
              break
            case 'Pinterest':
              this.setUploadPinterestSharingImageLoader(false)
              break
            case 'Tumblr':
              this.setUploadTumblrSharingImageLoader(false)
              break
            case 'Instagram':
              this.$store.commit(
                instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
                { status: false, position: -1 }
              )
              break
            case 'Gmb':
              this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
              break
          }
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    // ....................FOR BLOG ONLY..................
    editorToolbarFixed() {
      console.debug('Method::editorToolbarFixed')
      const stateObject = this
      setTimeout(function () {
        $('.composer_step_page .c_body .content').on('scroll', function () {
          if (
            stateObject.getBlogPostingDetails.stage &&
            stateObject.getBlogPostingDetails.stage === 'content'
          ) {
            var offsetTop = $('.editor_block').offset()
              ? $('.editor_block').offset().top
              : 0
            // console.debug(offsetTop, 'testttttttttttt', stateObject.getBlogPostingDetails.stage)
            if (offsetTop < 70) {
              $('.cke_top').addClass('positionFixed')
            } else {
              $('.cke_top').removeClass('positionFixed')
            }
          }
        })
      }, 500)
    },

    emitBlogChanges() {
      const stateObject = this
      if (
        stateObject.$route.name === 'composerBlog' &&
        stateObject.getPublishSelection.plan_id &&
        !stateObject.getEditorTypingInterval
      ) {
        console.debug(
          'call Editor typing interval',
          stateObject.getEditorTypingInterval
        )
        const editorInterval = setTimeout(function () {
          // here we do about emit on channel
          console.debug('send request to save editor interval')
          console.debug('socket Id', stateObject.getSocketId)
          console.debug('Plan Control', stateObject.getPlanControl)

          if (stateObject.getPlanControl) {
            console.debug('sending request to save editor interval')

            // let key = stateObject.generateRandomId()
            // stateObject.$store.commit(composer.SET_PLAN_KEY, key)
            const payload = {
              plan_id: stateObject.getPublishSelection.plan_id,
              workspace_id: stateObject.getWorkspaces.activeWorkspace._id,
              user_id: stateObject.getProfile._id,
              editor_data: stateObject.fetchCKEditorHtml(false).html(),
              title: stateObject.getBlogPostingDetails.title,
              optionalDescription:
                stateObject.getBlogPostingDetails.optionalDescription,
              channel: 'composer_editing',
              key: stateObject.getPlanKey,
            }
            window.webSockets.emit('composer_editing', payload)
            // stateObject.emitSocket('composer_editing', payload)
          }

          stateObject.$store.commit(composer.SET_EDITOR_TYPING_INTERVAL, null)
        }, 1000)
        stateObject.$store.commit(
          composer.SET_EDITOR_TYPING_INTERVAL,
          editorInterval
        )
      }
    },

    emitBlogActivities(type, data = null) {
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          route: this.$route.name,
          socket_id: this.getSocketId,
          data: data,
        },
        'Tyring Emit blog_activities'
      )

      if (
        this.$route.name === 'composerBlog' &&
        this.getPublishSelection.plan_id &&
        this.getSocketId
      ) {
        const payload = {
          plan_id: this.getPublishSelection.plan_id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          user_id: this.getProfile._id,
          data: data,
          type: type,
          channel: 'blog_activities',
        }

        this.saveSocketLogs(
          {
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
            route: this.$route.name,
            plan_id: this.getPublishSelection.plan_id,
            socket_id: this.getSocketId,
            data: payload,
          },
          'Success Emit blog_activities'
        )

        window.webSockets.emit('blog_activities', payload)
        // this.emitSocket('blog_activities', payload)
      }
    },

    loadBlogEditor() {
      console.debug('Method::loadBlogEditor')
      const stateObject = this
      setTimeout(function () {
        try {
          try {
            const myInstanceName = stateObject.getBlogEditorSelector
            if (myInstanceName) {
              myInstanceName.destroy()
            }
          } catch (e) {
            console.debug('ck editor catch ', e)
          }

          const editorSelector = CKEDITOR.replace(
            'editor_selector',
            ckEditorConfig
          )
          console.debug('Method::editorSelector', editorSelector)

          stateObject.setBlogEditorSelector(editorSelector)

          console.debug(
            stateObject.getBlogEditorSelector,
            'editorSelector111111'
          )
          editorSelector.on('change', function (e) {
            console.debug('Method::editorSelector change')
            stateObject.updateBlogPreview(0)

            if (!stateObject.getBlogAutoSaveInterval) {
              const blogInterval = setTimeout(function () {
                stateObject.blogPostAutoSave()
              }, 15000)
              stateObject.$store.commit(
                composer.SET_BLOG_AUTO_SAVE_INTERVAL,
                blogInterval
              )
            }
            stateObject.emitBlogChanges()
          })

          // CKFinder.setupCKEditor( editorSelector, '/ckfinder/' );
          //   editorSelector.on('paste', function (evt) {
          //       alert("Test")
          //       var widgetHTML = editorSelector.getData()
          //       console.debug(widgetHTML,"test-----------------",editorSelector.getData())
          //        editorSelector.setData(widgetHTML)
          //
          //
          //
          //   });

          editorSelector.on(
            'afterCommandExec',
            stateObject.handleAfterCommandExec
          )
          // prevent of removing these tags
          CKEDITOR.dtd.$removeEmpty.i = false
          CKEDITOR.dtd.$removeEmpty.span = false
          CKEDITOR.dtd.$removeEmpty.a = false

          // .................Spinner button............
          editorSelector.addCommand('SpinnerButton', {
            // create named command
            exec: function (edt) {
              $('#contentRewritingModal').modal('show')
              stateObject.setRewritingType('spin')
              // edt.getData() // get editor all data
            },
          })
          // editorSelector.ui.addButton('SpinnerButton', { // add new button and bind our command
          //   label: 'Content Rewriting',
          //   command: 'SpinnerButton',
          //   toolbar: 'SpinnerButton',
          //   icon: CKEDITOR.basePath + 'assets/img/file.png'
          // })

          // .................End Spinner button............

          // .................Inser File button............

          editorSelector.addCommand('insertFile', {
            exec: function (edt) {
              stateObject.showInsertFileModel('BlogEditorFile')
              // edt.getData() // get editor all data
            },
          })

          editorSelector.addCommand('pasteFromWord', {
            exec: function (edt) {
              $('#wordContentID').html('')
              // $('.cke_wysiwyg_div').focus()
              $('#pasteFromWord').modal('show')
            },
          })

          editorSelector.ui.addButton('InsertFile', {
            label: 'Add Media',
            command: 'insertFile',
            toolbar: 'insert',
            icon: CKEDITOR.basePath + 'assets/img/insertfile.png',
          })
          editorSelector.ui.addButton('pasteFromWord', {
            label: 'Paste from Doc ',
            command: 'pasteFromWord',
            toolbar: 'pasteFromWord',
            icon: CKEDITOR.basePath + 'assets/img/word.png',
          })
          editorSelector.addCommand('toggleToolbar', {
            exec: function (edt) {
              $('.toggle_btn_editor').toggleClass('animate_btn')
              $('.hidethis').toggleClass('d-inline-block')
              if ($('.toggle_btn_editor').is('.animate_btn')) {
                $('.cke_button__toggletoolbar_label').text('Less')
              } else {
                $('.cke_button__toggletoolbar_label').text('More')
              }

              // edt.getData() // get editor all data
            },
          })

          // editorSelector.on('doubleclick', function (e) {
          //     setTimeout(function () {
          //         if (e.data.dialog && e.data.dialog === 'image') {
          //             console.debug(e, e.data.dialog , 'dialog----')
          //             setTimeout(function () {
          //                 $(".cke_btn_reset span").trigger("click")
          //             },100)
          //         }
          //     },100)
          // })

          editorSelector.ui.addButton('toggleToolbar', {
            label: 'More',
            command: 'toggleToolbar',
            toolbar: 'toggleToolbar',
            icon: CKEDITOR.basePath + 'assets/img/arrow-down.png',
          })

          // .................End Spinner button............
        } catch (e) {
          console.debug('Exception in loadBlogEditor', e)
        }
      }, 500)
      this.editorToolbarFixed()
      $(document).on('click', '.cke_contents', function () {
        $('.cke_wysiwyg_div').focus()
      })
    },
    loadBlogEditorFeeder() {
      console.debug('Method::loadBlogEditor')
      const stateObject = this
      setTimeout(function () {
        try {
          try {
            const myInstanceName = stateObject.getBlogEditorSelector
            if (myInstanceName) {
              myInstanceName.destroy()
            }
          } catch (e) {
            console.debug('ck editor catch ', e)
          }

          const editorSelector = CKEDITOR.replace(
            'editor_selector_feeder',
            ckEditorConfig
          )

          stateObject.setBlogEditorSelector(editorSelector)

          console.debug(
            stateObject.getBlogEditorSelector,
            'editorSelector111111'
          )
          editorSelector.on('change', function (e) {
            console.debug('Method::editorSelector change')
            stateObject.updateBlogPreview(0)

            if (!stateObject.getBlogAutoSaveInterval) {
              const blogInterval = setTimeout(function () {
                stateObject.blogPostAutoSave()
              }, 15000)
              stateObject.$store.commit(
                composer.SET_BLOG_AUTO_SAVE_INTERVAL,
                blogInterval
              )
            }
            stateObject.emitBlogChanges()
          })

          // CKFinder.setupCKEditor( editorSelector, '/ckfinder/' );
          //   editorSelector.on('paste', function (evt) {
          //       alert("Test")
          //       var widgetHTML = editorSelector.getData()
          //       console.debug(widgetHTML,"test-----------------",editorSelector.getData())
          //        editorSelector.setData(widgetHTML)
          //
          //
          //
          //   });

          editorSelector.on(
            'afterCommandExec',
            stateObject.handleAfterCommandExec
          )
          // prevent of removing these tags
          CKEDITOR.dtd.$removeEmpty.i = false
          CKEDITOR.dtd.$removeEmpty.span = false
          CKEDITOR.dtd.$removeEmpty.a = false

          // .................Spinner button............
          editorSelector.addCommand('SpinnerButton', {
            // create named command
            exec: function (edt) {
              $('#contentRewritingModal').modal('show')
              stateObject.setRewritingType('spin')
              // edt.getData() // get editor all data
            },
          })
          // editorSelector.ui.addButton('SpinnerButton', { // add new button and bind our command
          //   label: 'Content Rewriting',
          //   command: 'SpinnerButton',
          //   toolbar: 'SpinnerButton',
          //   icon: CKEDITOR.basePath + 'assets/img/file.png'
          // })

          // .................End Spinner button............

          // .................Inser File button............

          editorSelector.addCommand('insertFile', {
            exec: function (edt) {
              stateObject.showInsertFileModel('BlogEditorFile')
              // $('#insertFileModel').modal('show')
              // edt.getData() // get editor all data
            },
          })

          editorSelector.addCommand('pasteFromWord', {
            exec: function (edt) {
              $('#wordContentID').html('')
              // $('.cke_wysiwyg_div').focus()
              $('#pasteFromWord').modal('show')
            },
          })

          editorSelector.ui.addButton('InsertFile', {
            label: 'Add Media',
            command: 'insertFile',
            toolbar: 'insert',
            icon: CKEDITOR.basePath + 'assets/img/insertfile.png',
          })
          editorSelector.ui.addButton('pasteFromWord', {
            label: 'Paste from Doc ',
            command: 'pasteFromWord',
            toolbar: 'pasteFromWord',
            icon: CKEDITOR.basePath + 'assets/img/word.png',
          })
          editorSelector.addCommand('toggleToolbar', {
            exec: function (edt) {
              $('.toggle_btn_editor').toggleClass('animate_btn')
              $('.hidethis').toggleClass('d-inline-block')
              if ($('.toggle_btn_editor').is('.animate_btn')) {
                $('.cke_button__toggletoolbar_label').text('Less')
              } else {
                $('.cke_button__toggletoolbar_label').text('More')
              }

              // edt.getData() // get editor all data
            },
          })

          // editorSelector.on('doubleclick', function (e) {
          //     setTimeout(function () {
          //         if (e.data.dialog && e.data.dialog === 'image') {
          //             console.debug(e, e.data.dialog , 'dialog----')
          //             setTimeout(function () {
          //                 $(".cke_btn_reset span").trigger("click")
          //             },100)
          //         }
          //     },100)
          // })

          editorSelector.ui.addButton('toggleToolbar', {
            label: 'More',
            command: 'toggleToolbar',
            toolbar: 'toggleToolbar',
            icon: CKEDITOR.basePath + 'assets/img/arrow-down.png',
          })

          // .................End Spinner button............
        } catch (e) {
          console.debug('Exception in loadBlogEditor', e)
        }
      }, 500)
      this.editorToolbarFixed()
      $(document).on('click', '.cke_contents', function () {
        $('.cke_wysiwyg_div').focus()
      })
    },

    handleAfterCommandExec(event) {
      console.debug('Method::handleAfterCommandExec')
      const stateObject = this
      const commandName = event.data.name

      setTimeout(function () {
        const commandState = event.data.command.state
        if (commandName == 'source' && commandState == 2) {
          if (stateObject.$route.name === 'composerBlog') {
            stateObject.callSortableFunctionBlog()
          }
          const $descriptionHtml = stateObject.fetchCKEditorHtml(true)
          console.debug('$descriptionHtml', $descriptionHtml.html())
          stateObject.setCKEditorHtml($descriptionHtml.html(), true)
        }
      }, 100)
    },

    fetchCKEditorHtml(loaders) {
      console.debug('Method::fetchCKEditorHtml -- ', this.getBlogEditorSelector)

      const url = ''
      const description = ''
      const stateobject = this
      const descriptionHtml = this.$store.getters.getBlogPostingHTML
      const $descriptionHtml = $('<div />', { html: descriptionHtml })

      // // for youtube
      // $descriptionHtml.find('.youtube_inner_embed').each(function (i, el) {
      //   url = $(el).attr('data-link')
      //   description = `<div class="inner_embed"><br>`
      //
      //   if (loaders) {
      //     let loaderID = stateobject.generateRandomId()
      //     description += '<div class="custom_loader" id="' + loaderID + '"><div class="loader_inner"></div></div>'
      //   }
      //
      //   description += `<iframe style="width: 470px;height: 330px;" src="${url}" frameborder="0" allowfullscreen></iframe><br></div>`
      //
      //   $descriptionHtml.find(".youtube_inner_embed[data-link='" + url + "']").html(description)
      // })
      //
      // // for dailymotion
      // $descriptionHtml.find('.dailymotion_inner_embed').each(function (i, el) {
      //   url = $(el).attr('data-link')
      //   description = `<div class="inner_embed"><br>`
      //
      //   if (loaders) {
      //     let loaderID = stateobject.generateRandomId()
      //     description += '<div class="custom_loader" id="' + loaderID + '"><div class="loader_inner"></div></div>'
      //   }
      //
      //   description += `<iframe frameborder="0" style="width: 470px;height: 330px;"  src="${url}"  allowfullscreen></iframe><br></div>`
      //
      //   $descriptionHtml.find(".dailymotion_inner_embed[data-link='" + url + "']").html(description)
      // })
      //
      // // for flickr
      // $descriptionHtml.find('.flickr_inner_embed').each(function (i, el) {
      //   url = $(el).attr('data-link')
      //   description = `<div class="inner_embed"><br>`
      //
      //   if (loaders) {
      //     let loaderID = stateobject.generateRandomId()
      //     description += '<div class="custom_loader" id="' + loaderID + '"><div class="loader_inner"></div></div>'
      //   }
      //
      //   description += `<a data-flickr-embed="true"><img src="${url}"></a><br></div>`
      //
      //   $descriptionHtml.find(".flickr_inner_embed[data-link='" + url + "']").html(description)
      // })
      //
      // // for facebook
      // $descriptionHtml.find('.facebook_inner_embed').each(function (i, el) {
      //   url = $(el).attr('data-link')
      //   // let facebookID = stateobject.generateRandomId()
      //   // id="${facebookID}"
      //   description = `<div class="inner_embed"><br>`
      //
      //   if (loaders) {
      //     let loaderID = stateobject.generateRandomId()
      //     description += '<div class="custom_loader" id="' + loaderID + '"><div class="loader_inner"></div></div>'
      //   }
      //
      //   // description += ` <div class="fb-post"  data-href="${url}"  data-width="350"></div><br>`
      //   description += `<iframe src="https://www.facebook.com/plugins/post.php?href=${url}&width=500&show_text=true&height=290&appId" width="500" height="290" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>`
      //   description += `<br></div>`
      //
      //   $descriptionHtml.find(".facebook_inner_embed[data-link='" + url + "']").html(description)
      // })
      //
      // // for twitter
      // $descriptionHtml.find('.twitter_inner_embed').each(function (i, el) {
      //   let tweetDescription = $(el).attr('data-description')
      //   let tweetScreenname = $(el).attr('data-screenname')
      //   let tweetTitle = $(el).attr('data-title')
      //   let tweetLink = $(el).attr('data-link')
      //
      //   url = $(el).attr('data-link')
      //   description = ``
      //
      //   if (loaders) {
      //     let loaderID = stateobject.generateRandomId()
      //     description += '<div class="custom_loader" id="' + loaderID + '"><div class="loader_inner"></div></div>'
      //   }
      //
      //   description += `<div class="inner_embed" id=""><blockquote class=" twitter-tweet"  data-width="350">
      //                   <p lang="en" dir="ltr">${tweetDescription}</p>
      //                   <p>— ${tweetTitle} (${tweetScreenname})
      //                   <a href="${tweetLink}"><span class="hide">November 24, 2016</span></a></p>
      //                 </blockquote></div>`
      //
      //   $descriptionHtml.find(".twitter_inner_embed[data-link='" + url + "']").html(description)
      // })

      return $descriptionHtml
    },

    fetchCKEditorPreviewHtml() {
      console.debug('Method::fetchCKEditorPreviewHtml')

      const $descriptionHtml = this.fetchCKEditorHtml()
      $descriptionHtml.find('.editor_dragable_box').each(function (i, el) {
        $(el).remove() // for removing boxes that are not included
      })
      return $descriptionHtml.html()
    },

    updateBlogPreview(timeout) {
      console.debug(
        'method : updateBlogPreview',
        this.fetchCKEditorPreviewHtml()
      )

      const stateObject = this
      if (
        stateObject.removeHtmlTags(
          stateObject.$store.getters.getBlogPostingHTML
        ) !== ''
      ) {
        const regex = /<oembed.+?url="(.*?)"><\/oembed>/g

        const newHTML =
          stateObject.$store.getters.getBlogPostingHTML.replaceAll(
            regex,
            '<a data-iframely-url href="$1"></a>'
          )

        console.log(newHTML)

        $('#previewCuration').html(newHTML)

        stateObject.$store.commit(
          blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
          false
        )
      } else {
        stateObject.$store.commit(
          blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
          true
        )
      }

      window.iframely && window.iframely.load()
      console.debug(
        'method : updateBlogPreview window.iframely',
        window.iframely
      )

      // setTimeout(function () {
      //   if (stateObject.removeHtmlTags(stateObject.fetchCKEditorPreviewHtml()) !== '') {
      //     $('#previewCuration').html(stateObject.getBlogPostingHTML())
      //
      //     stateObject.loadTwitterPreview(0)
      //     stateObject.loadFlickrPreview(0)
      //     stateObject.$store.commit(blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS, false)
      //   } else {
      //     stateObject.$store.commit(blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS, true)
      //   }
      // }, timeout)
    },

    setCKEditorHtml(description, status) {
      console.debug('Method::setCKEditorHtml', description)
      if (status) {
        try {
          this.$store.commit(
            blogPosting.SET_BLOG_POSTING_HTML,
            `${description}`
          )
        } catch (e) {
          console.debug('this.getBlogEditorSelector', e)
        }
      }
      if (description) {
        const stateObject = this
        // setTimeout(function () {
        //   $('.cke_wysiwyg_div .youtube_inner_embed').each(function (i, el) {
        //     stateObject.processLoaderPreview($(this).find('.custom_loader').attr('id'))
        //   })
        //
        //   $('.cke_wysiwyg_div .dailymotion_inner_embed').each(function (i, el) {
        //     stateObject.processLoaderPreview($(this).find('.custom_loader').attr('id'))
        //   })
        //
        //   $('.cke_wysiwyg_div .facebook_inner_embed').each(function (i, el) {
        //     $(this).find('.fb-post').html('')
        //     // here
        //     // let idd = $(this).attr('id')
        //     // stateObject.loadFacebookPreview(idd, 1500)
        //     stateObject.processLoaderPreview($(this).find('.custom_loader').attr('id'))
        //   })
        //
        //   $('.cke_wysiwyg_div .twitter_inner_embed').each(function (i, el) {
        //     let twitterID = $(this).find('.inner_embed').attr('id')
        //     let twitterPostID = $(this).find('.inner_embed').attr('data-tweet')
        //     stateObject.loadTwitterPreview(0)
        //     stateObject.processLoaderPreview($(this).find('.custom_loader').attr('id'))
        //   })
        //
        //   $('.cke_wysiwyg_div .flickr_inner_embed').each(function (i, el) {
        //     stateObject.loadFlickrPreview()
        //     stateObject.processLoaderPreview($(this).find('.custom_loader').attr('id'))
        //   })
        //   $('.cke_wysiwyg_div .align_box').each(function (i, el) {
        //     stateObject.processLoaderPreviewWithClass($(this).find('.custom_loader'))
        //   })
        // }, 800)
      }
    },

    callSortableFunctionBlog() {
      const self = this
      setTimeout(function () {
        console.debug('method:callSortableFunctionBlog')
        self.curationDragBlog()
        self.sortableBlog()
      }, 500)
    },

    curationDragBlog() {
      console.debug('method:curationDragBlog blog')
      const self = this
      $(document).ready(function () {
        $('.assistant_main_block .dragable_box').draggable({
          helper: 'clone',
          appendTo: '.composer_step_page .editor_block .cke_wysiwyg_div',
          connectToSortable:
            '.composer_step_page .editor_block .cke_wysiwyg_div',
          drag: function (e, ui) {
            if (!self.getPlanControl) {
              $('.assistant_main_block .dragable_box').draggable('disable')
              return false
            } else {
              $('.assistant_main_block .dragable_box').draggable('enable')
            }
            console.debug('curation Draging event')
            $('.composer_step_page .editor_block').addClass('editor_hover')
          },
          // start: function (event, ui) {
          //   console.debug('curationDrag start')
          //
          // },
          stop: function (event, ui) {
            console.debug('curationDrag stop')
            $('.composer_step_page .editor_block').removeClass('editor_hover')
          },
        })
      })
    },

    sortableBlog() {
      console.debug('method:sortableBlog blog')

      const self = this

      // ckeditor
      setTimeout(function () {
        $(document).ready(function () {
          var triggerReceive = false
          $('.composer_step_page .editor_block .cke_wysiwyg_div').sortable({
            // items: '.editor_dragable_box',
            handle: '.handler',
            cursorAt: { left: 125, top: 20 },
            // sort: function (e, ui) {
            // console.debug("sorting..................................")
            // },

            receive: function (event, ui) {
              console.debug(event, ui, 'sortable receive')
              triggerReceive = true
              const loaderID = self.generateRandomId() + '_loader'
              const contentHtml = $('<div />', {
                html: ui.helper.clone().find('.editor_dragable_box').html(),
              })
              console.debug($(this), '----------------------------')
              // .....................ARTICLE
              if (ui.helper.is('._article')) {
                ui.helper
                  .removeAttr('style')
                  .removeClass(
                    'topic_content_box  dragable_box _article ui-draggable-dragging ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_article_block highlighted_box'
                  )
                  .html(contentHtml)
              }
              // ..................... VIDEO
              else if (ui.helper.is('._video')) {
                $(contentHtml).find('.custom_loader').attr('id', loaderID)
                const link = $(contentHtml)
                  .find('.editor_dragable_box_inner .text_alignment')
                  .attr('data-embed')
                $(contentHtml).find('.inner_embed iframe').attr('src', link)
                ui.helper
                  .removeAttr('style')
                  .removeClass(
                    'assistant_box dragable_box _dailymotion _youtube ui-draggable-dragging ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_video_block highlighted_box'
                  )
                  .html(contentHtml)

                self.processLoaderPreview(loaderID)
              }
              // .....................PHOTO
              else if (ui.helper.is('._photo')) {
                $(contentHtml).find('.custom_loader').attr('id', loaderID)
                const image = $(contentHtml)
                  .find('.editor_dragable_box_inner img')
                  .attr('src')
                // more condition inside  '_flickr, _imgur ...etc'
                if ($(ui.helper).is('._flickr')) {
                  const flickrID = self.generateRandomId()
                  $(contentHtml)
                    .find('.flickr_inner_embed')
                    .attr('id', flickrID)
                  ui.helper.addClass('editor_flickr_block')
                }

                ui.helper
                  .removeAttr('style')
                  .removeClass(
                    'assistant_box dragable_box _flickr _photo _imgur _pixabay _quote _giphy ui-draggable-dragging ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_image_block highlighted_box'
                  )
                  .html(contentHtml)

                if (image && image.indexOf('pixabay.com') !== -1) {
                  self.processPixabayImage(
                    { loaderID: loaderID, image: image },
                    'Compose Post'
                  )
                } else {
                  $(contentHtml)
                    .find('.flickr_inner_embed a')
                    .attr('data-flickr-embed', true)
                  self.loadFlickrPreview(500)
                  self.processLoaderPreview(loaderID)
                }
              }
              // .....................TWITTER
              else if (ui.helper.is('._twitter')) {
                $(contentHtml).find('.custom_loader').attr('id', loaderID)
                const tweetDescription = $(contentHtml)
                  .find('.twitter_inner_embed')
                  .find('.tweet_description')
                  .html()
                const tweetScreenname = $(contentHtml)
                  .find('.twitter_inner_embed')
                  .find('.tweet_screenname')
                  .html()
                const tweetTitle = $(contentHtml)
                  .find('.twitter_inner_embed')
                  .find('.tweet_title')
                  .html()
                const tweetLink = $(contentHtml)
                  .find('.twitter_inner_embed')
                  .find('.tweet_link')
                  .html()
                $(contentHtml)
                  .find('.twitter_inner_embed')
                  .attr('data-description', tweetDescription)
                $(contentHtml)
                  .find('.twitter_inner_embed')
                  .attr('data-screenname', tweetScreenname)
                $(contentHtml)
                  .find('.twitter_inner_embed')
                  .attr('data-title', tweetTitle)
                $(contentHtml)
                  .find('.twitter_inner_embed')
                  .attr('data-link', tweetLink)

                $(contentHtml).find('.twitter_inner_embed .inner_embed')
                  .html(`<blockquote class=" twitter-tweet"  data-width="350">
                        <p lang="en" dir="ltr">${tweetDescription}</p>
                        <p>— ${tweetTitle} (${tweetScreenname})
                        <a href="${tweetLink}"><span class="hide">November 24, 2016</span></a></p>
                      </blockquote>`)
                ui.helper
                  .removeAttr('style')
                  .removeClass(
                    'assistant_box dragable_box _twitter ui-draggable-dragging ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_twitter_block highlighted_box'
                  )
                  .html(contentHtml)

                // self.loadTwitterPreview(twitterPostID, twitterID, 0)
                self.loadTwitterPreview(0)
                self.processLoaderPreview(loaderID)
              }
              // .....................FACEBOOK
              else if (ui.helper.is('._facebook')) {
                $(contentHtml).find('.custom_loader').attr('id', loaderID)
                // Get facebook ID and  Link for preview with Random ID
                const faceookID = self.generateRandomId()
                $(contentHtml)
                  .find('.facebook_inner_embed')
                  .attr('id', faceookID)
                const postLink = $(contentHtml)
                  .find('.facebook_inner_embed')
                  .attr('data-link')
                const postID = $(contentHtml)
                  .find('.facebook_inner_embed')
                  .attr('data-postid')
                // Set content in Editor
                ui.helper
                  .removeAttr('style')
                  .removeClass(
                    'assistant_box ui-draggable-dragging dragable_box _facebook ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_facebook_block  highlighted_box'
                  )
                  .html(contentHtml)
                // Check if it have valid facebook link then call preview

                console.debug('postLink', postLink)
                if (postLink && postLink.includes('facebook.com')) {
                  $(contentHtml)
                    .find('.facebook_inner_embed .fb-post')
                    .attr('data-href', postLink)
                  self.loadFacebookPreview(faceookID, 1500)
                  self.processLoaderPreview(loaderID)
                } else {
                  // If not a valid link then curl request and assign new link
                  self.postRequest(
                    getFacebookPostLinkURL,
                    { post_id: postID },
                    (response) => {
                      if (response.data) {
                        $(contentHtml)
                          .find('.facebook_inner_embed .fb-post')
                          .attr('data-href', response.data)
                        $(contentHtml)
                          .find('.facebook_inner_embed')
                          .attr('data-link', response.data)
                        self.loadFacebookPreview(faceookID, 1500)
                        self.processLoaderPreview(loaderID)
                      } else {
                        console.debug('Exception in getFacebookPostLinkURL')
                      }
                    },
                    (error) => {
                      console.debug(
                        'Exception in getFacebookPostLinkURL',
                        error
                      )
                    }
                  )
                }
              }
              setTimeout(function () {
                $('.editor_dragable_box').removeClass('highlighted_box')
              }, 1000)
              // self.getBlogEditorSelector.setData(contentHtml)
            },

            // out: function (event, ui) {
            //   console.debug('sortable out')
            //  $('.composer_step_page .editor_block').removeClass('editor_hover')
            // },

            // over: function (event, ui) {
            //   console.debug('sortable over')
            // },
            //
            stop: function (event, ui) {
              if (
                $(ui.item).is('.editor_flickr_block') &&
                triggerReceive === false
              ) {
                console.debug(event, 'sortable stop', ui)
                const contentHtml = $('<div />', { html: ui.item.html() })
                const imgUrl = $(contentHtml)
                  .find('.flickr_inner_embed')
                  .attr('data-link')
                $(contentHtml)
                  .find('.flickr_inner_embed')
                  .html(
                    `     <div class="inner_embed"><br>

                                   <a data-flickr-embed="true">
                                     <img src="${imgUrl}">
                                   </a>
                                   <br>

                                </div>`
                  )
                console.debug(imgUrl, 'imgUrl', contentHtml)
                ui.item
                  .removeAttr('style')
                  .removeClass(
                    'assistant_box dragable_box _flickr _photo _imgur _pixabay _quote _giphy ui-draggable-dragging ui-draggable ui-draggable-handle'
                  )
                  .attr('contenteditable', false)
                  .addClass(
                    'editor_dragable_box editor_image_block editor_flickr_block highlighted_box'
                  )
                  .html(contentHtml)

                // self.loadFlickrPreview(500)
              }
              triggerReceive = false
            },
          })
        })
      }, 200)
    },

    loadFacebookPreview(id, timeout) {
      console.debug('Method:loadFacebookPreview', id, timeout)
      setTimeout(function () {
        FB.XFBML.parse(document.getElementById(id), function () {})
      }, timeout)
    },

    loadTwitterPreview(timeout) {
      console.debug('Method:loadTwitterPreviews')

      setTimeout(function () {
        twttr.ready(function (twttr) {
          twttr.widgets.load()
        })
      }, timeout)
    },

    loadFlickrPreview(timeout) {
      console.debug('Method:loadFlickrPreview')
      setTimeout(function () {
        !(function (e, t, n, r, c) {
          var i = function (e, t) {
            var n
            var r = 'IE'
            var c = document.createElement('B')
            var i = document.documentElement
            return (
              e && ((r += ' ' + e), t && (r = t + ' ' + r)),
              (c.innerHTML =
                '<!--[if ' + r + ']><b id="iecctest"></b><![endif]-->'),
              i.appendChild(c),
              (n = !!document.getElementById('iecctest')),
              i.removeChild(c),
              n
            )
          }
          if (
            !e[n] &&
            ((e[n] = {
              process: function (t) {
                ;(e[n].q = e[n].q || []).push(t)
              },
              baseURL: 'https://embedr.flickr.com',
            }),
            !i(8, 'lte'))
          ) {
            var d = t.createElement(r)
            var m = t.getElementsByTagName(r)[0]
            ;(d.async = 1), (d.src = c), m.parentNode.insertBefore(d, m)
          }
        })(
          window,
          document,
          'FlickrEmbedr',
          'script',
          'https://embedr.flickr.com/assets/embedr-loader.js'
        ),
          window.FlickrEmbedr.process('inline')
      }, timeout)
    },

    generateRandomId() {
      console.debug('Method:generateRandomId')
      let text = ''
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < 30; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    },

    processLoaderPreview(id) {
      console.debug('Method:processLoaderPreview')
      setTimeout(function () {
        $('#' + id).fadeOut(400, function () {
          $(this).remove()
        })
      }, 1700)
    },

    processLoaderPreviewWithClass(selector) {
      console.debug('Method:processLoaderPreviewWithClass')
      setTimeout(function () {
        $(selector).fadeOut(400, function () {
          $(this).remove()
        })
      }, 1700)
    },
    // ....................FOR SOCIAL ONLY..................

    callSortableFunctionSocial() {
      const self = this
      setTimeout(function () {
        console.debug('method:callSortableFunctionSocial')
        self.curationDragSocial()
        self.curationDropSocial()
      }, 500)
    },

    curationDragSocial() {
      console.debug('Method:curationDragSocial social')
      const self = this
      $(document).ready(function () {
        $('.assistant_main_block .dragable_box').draggable({
          helper: 'clone',
          appendTo: '.composer_step_page .content_container_drop',
          connectToSortable: '.composer_step_page .content_container_drop',
          drag: function (e, ui) {
            console.debug('curation Draging event')
            $(
              '.composer_step_page .content_container_drop .text_content_main'
            ).addClass('editor_hover')
          },
          // start: function (event, ui) {
          //   console.debug('curationDrag start')
          //
          // },
          stop: function (event, ui) {
            console.debug('curationDrag stop')
            $(
              '.composer_step_page .content_container_drop .text_content_main'
            ).removeClass('editor_hover')
          },
        })
      })
    },

    curationDropSocial() {
      console.debug('Method:curationDropSocial social')
      const stateObject = this
      $(document).ready(function () {
        $(
          '.composer_step_page .social_content_box .text_content_main'
        ).droppable({
          accept: '.assistant_main_block .dragable_box',
          drop: function (event, ui) {
            let title = ''
            let link = ''
            let image = ''
            const contentHtml = $('<div />', {
              html: ui.helper.clone().find('.editor_dragable_box').html(),
            })

            if (ui.helper.is('._article')) {
              console.debug('_article')
              title = $(contentHtml)
                .find('.editor_dragable_box_inner h2')
                .text()
              link = $(contentHtml)
                .find('.editor_dragable_box_inner a')
                .attr('href')
            } else if (ui.helper.is('._video')) {
              title = $(contentHtml)
                .find('.editor_dragable_box_inner .text_alignment')
                .text()
              link = $(contentHtml)
                .find('.editor_dragable_box_inner .text_alignment')
                .attr('data-link')
            } else if (ui.helper.is('._photo')) {
              image = $(contentHtml)
                .find('.editor_dragable_box_inner img')
                .attr('src')
            } else if (ui.helper.is('._twitter')) {
              title = $(contentHtml)
                .find('.editor_dragable_box_inner .twitter_inner_embed')
                .attr('data-tweet')
              link = $(contentHtml)
                .find('.editor_dragable_box_inner .twitter_inner_embed')
                .attr('data-link')
            } else if (ui.helper.is('._facebook')) {
              title = $(contentHtml)
                .find('.editor_dragable_box_inner .facebook_inner_embed')
                .attr('data-message')
              const type = $(contentHtml)
                .find('.editor_dragable_box_inner .facebook_inner_embed')
                .attr('data-type')
              if (type === 'Video' || type === 'Link') {
                link = $(contentHtml)
                  .find('.editor_dragable_box_inner .facebook_inner_embed')
                  .attr('data-link')
              } else if (type === 'Photo') {
                image = $(contentHtml)
                  .find('.editor_dragable_box_inner .facebook_inner_embed')
                  .attr('data-image')
              }
            }

            title = he.decode(title)
            let activeTab = 'common_tab'
            if (!stateObject.getCommonBoxOptions.status) {
              activeTab = stateObject.getSocialSharingSelection.activeTab
            }
            const utmLinks = stateObject.fetchUtmBasedLinks(
              link,
              stateObject.fetchActiveUtm
            )

            switch (activeTab) {
              case 'common_tab':
                stateObject.generateCommonBoxPost(title, link, image, utmLinks)
                break
              case 'facebook_tab':
                stateObject.generateFacebookBoxPost(
                  title,
                  link,
                  image,
                  utmLinks
                )
                break
              case 'twitter_tab':
                stateObject.generateTwitterBoxPost(title, link, image, utmLinks)
                break
              case 'linkedin_tab':
                stateObject.generateLinkedinBoxPost(
                  title,
                  link,
                  image,
                  utmLinks
                )
                break
              case 'pinterest_tab':
                stateObject.generatePinterestBoxPost(
                  title,
                  link,
                  image,
                  utmLinks
                )
                break
              case 'tumblr_tab':
                stateObject.generateTumblrBoxPost(title, link, image, utmLinks)
                break
              case 'instagram_tab':
                stateObject.generateInstagramBoxPost(
                  title,
                  link,
                  image,
                  utmLinks
                )
                break
              case 'youtube_tab':
                stateObject.generateYoutubeBoxPost(title, link, image, utmLinks)
                break
              case 'gmb_tab':
                stateObject.generateGmbBoxPost(title, link, image, utmLinks)
                break
            }
          },
        })
      })
    },

    generateCommonBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateCommonBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.setCommonCancelledLink('')
      this.setCommonSharingPreview(null)
      this.setCommonSharingImage([])
      this.$store.commit(commonTypes.SET_COMMON_SHARING_IMAGE_SUGGESTIONS, [])
      this.SET_COMMON_SHARING_VIDEO(null)
      if (image) {
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.setUploadCommonSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processPixabayImage(image, 'Common')
        } else {
          this.setCommonSharingImage([image])
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.common
          const urls = [utmLinks.common]
          if (utmLinks.auto_add_social_channel) {
            urls.push(utmLinks.facebook)
            urls.push(utmLinks.twitter)
            urls.push(utmLinks.linkedin)
            urls.push(utmLinks.pinterest)
            urls.push(utmLinks.tumblr)
            urls.push(utmLinks.instagram)
            urls.push(utmLinks.gmb)
            this.setCommonSharingLink(utmLinks.common)
            this.setCommonFacebookSharingLink(utmLinks.facebook)
            this.setCommonTwitterSharingLink(utmLinks.twitter)
            this.setCommonLinkedinSharingLink(utmLinks.linkedin)
            this.setCommonPinterestSharingLink(utmLinks.pinterest)
            this.setCommonTumblrSharingLink(utmLinks.tumblr)
            this.setCommonInstagramSharingLink(utmLinks.instagram)
            this.$store.commit(commonTypes.SET_GMB_SHARING_LINK, utmLinks.gmb)
          }
          this.setFetchCommonSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks(urls, 'Common')
          } else {
            this.parseLinkPreview(urls, 'Common')
          }
        }
        this.setSharingMessage('Common', description)
      }
      this.getCommonboxRef.$el.focus()
    },

    generateFacebookBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateFacebookBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.setFacebookCancelledLink('')
      this.setFacebookSharingPreview(null)
      this.setFacebookSharingImage([])
      this.setFacebookSharingImageSuggestions([])
      this.SET_FACEBOOK_SHARING_VIDEO(null)
      if (image) {
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.setUploadFacebookSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processPixabayImage(image, 'Facebook')
        } else {
          this.setFacebookSharingImage([image])
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.facebook
          const urls = [utmLinks.common]
          this.setFetchFacebookSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.facebook], 'Facebook')
          } else {
            this.parseLinkPreview([utmLinks.facebook], 'Facebook')
          }
        }
        this.setSharingMessage('Facebook', description)
      }
      this.getFacebookboxRef.$el.focus()
    },

    generateTwitterBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateFTwitterBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.setTwitterCancelledLink('')
      this.setTwitterSharingPreview(null)
      this.setTwitterSharingImage([])
      this.setTwitterSharingImageSuggestions([])
      this.SET_TWITTER_SHARING_VIDEO(null)
      if (image) {
        this.setSharingMessage('Twitter', description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.setUploadTwitterSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processPixabayImage(image, 'Twitter')
        } else {
          this.setTwitterSharingImage([image])
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.twitter
          const urls = [utmLinks.common]
          this.setFetchTwitterSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.twitter], 'Twitter')
          } else {
            this.parseLinkPreview([utmLinks.twitter], 'Twitter')
          }
        }
        this.setSharingMessage('Twitter', description)
      }
      this.getTwitterboxRef.$el.focus()
    },

    generateLinkedinBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateLinkedinBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.$store.commit(linkedinSharingTypes.SET_SHARING_CANCELLED_PREVIEW, '')
      this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
      this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
      this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
      if (image) {
        this.setSharingMessage('Linkedin', description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.$store.commit(link.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: -1,
          })
          this.processPixabayImage(image, 'Linkedin')
        } else {
          this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [image])
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.linkedin
          const urls = [utmLinks.common]
          this.setFetchLinkedinSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.linkedin], 'Linkedin')
          } else {
            this.parseLinkPreview([utmLinks.linkedin], 'Linkedin')
          }
        }
        this.setSharingMessage('Linkedin', description)
      }
      this.getLinkedinboxRef.$el.focus()
    },

    generatePinterestBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generatePinterestBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.setPinterestCancelledLink('')
      this.setPinterestSharingPreview(null)
      this.setPinterestSharingImage('')
      this.SET_PINTEREST_SHARING_VIDEO(null)
      if (image) {
        this.setSharingMessage('Pinterest', description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.setUploadPinterestSharingImageLoader(true)
          this.processPixabayImage(image, 'Pinterest')
        } else {
          this.setPinterestSharingImage(image)
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.pinterest
          const urls = [utmLinks.common]
          this.setFetchPinterestSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.pinterest], 'Pinterest')
          } else {
            this.parseLinkPreview([utmLinks.pinterest], 'Pinterest')
          }
        }
        this.setSharingMessage('Pinterest', description)
      }
      this.getPinterestboxRef.$el.focus()
    },

    generateTumblrBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateTumblrBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.setTumblrCancelledLink('')
      this.setTumblrSharingPreview(null)
      this.setTumblrSharingImage('')
      this.SET_TUMBLR_SHARING_VIDEO(null)
      if (image) {
        this.setSharingMessage('Tumblr', description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.setUploadTumblrSharingImageLoader(true)
          this.processPixabayImage(image, 'Tumblr')
        } else {
          this.setTumblrSharingImage(image)
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.tumblr
          const urls = [utmLinks.common]
          this.setFetchTumblrSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.tumblr], 'Tumblr')
          } else {
            this.parseLinkPreview([utmLinks.tumblr], 'Tumblr')
          }
        }
        this.setSharingMessage('Tumblr', description)
      }
      this.getTumblrboxRef.$el.focus()
    },

    generateInstagramBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateInstagramBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.$store.commit(instagramTypes.SET_SHARING_CANCELLED_PREVIEW, '')
      this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, null)
      this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
      this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
      if (image) {
        this.setSharingMessage('Instagram', description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: -1,
          })
          this.processPixabayImage(image, 'Instagram')
        } else {
          this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [image])
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.instagram
          const urls = [utmLinks.common]
          this.setFetchInstagramSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.instagram], 'Instagram')
          } else {
            this.parseLinkPreview([utmLinks.instagram], 'Instagram')
          }
        }
        this.setSharingMessage('Instagram', description)
      }
      this.getInstagramboxRef.$el.focus()
    },

    generateYoutubeBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateYoutubeBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.$store.commit(youtubeTypes.SET_SHARING_CANCELLED_PREVIEW, '')
      this.$store.commit(youtubeTypes.SET_SHARING_PREVIEW, null)
      this.$store.commit(youtubeTypes.SET_SHARING_IMAGE, '')
      this.$store.commit(youtubeTypes.SET_SHARING_VIDEO, null)
      if (image) {
        this.setSharingMessage('Youtube', description)
        // this.$store.commit(youtubeTypes.SET_SHARING_IMAGE, image)
      } else {
        if (link) {
          description += ' ' + utmLinks.youtube
          const urls = [utmLinks.common]
          this.setFetchYoutubeSharingPreviewLoader(true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.youtube], 'Youtube')
          } else {
            this.parseLinkPreview([utmLinks.youtube], 'Youtube')
          }
        }
        this.setSharingMessage('Youtube', description)
      }
      this.getYoutubeboxRef.$el.focus()
    },

    checkEditorRange() {
      const sel = this.getBlogEditorSelector.getSelection()
      var range = sel.getRanges()[0]
      // no range, means the editor is empty. Select the range.
      if (!range) {
        range = this.getBlogEditorSelector.createRange()
        range.selectNodeContents(this.getBlogEditorSelector.editable())
        sel.selectRanges([range])
      }
    },

    generateGmbBoxPost(description, link, image, utmLinks) {
      console.debug(
        'Method::generateGmbBoxPost',
        description,
        link,
        image,
        utmLinks
      )
      this.$store.commit(gmb.SET_SHARING_CANCELLED_PREVIEW, '')
      this.$store.commit(gmb.SET_SHARING_PREVIEW, null)
      this.$store.commit(gmb.SET_SHARING_IMAGE, '')
      if (image) {
        this.$store.commit(gmb.SET_SHARING_MESSAGE, description)
        if (image && image.indexOf('pixabay.com') !== -1) {
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, true)
          this.processPixabayImage(image, 'Gmb')
        } else {
          this.$store.commit(gmb.SET_SHARING_IMAGE, image)
        }
      } else {
        if (link) {
          description += ' ' + utmLinks.gmb
          const urls = [utmLinks.common]
          this.$store.commit(gmb.SET_FETCH_SHARING_PREVIEW_LOADER, true)
          if (
            this.getWorkspaces.activeWorkspace.shortener &&
            this.checkLongLinkExistence(urls)
          ) {
            this.fetchShortLinks([utmLinks.gmb], 'Gmb')
          } else {
            this.parseLinkPreview([utmLinks.gmb], 'Gmb')
          }
        }
        this.$store.commit(gmb.SET_SHARING_MESSAGE, description)
      }
      this.getGmbboxRef.$el.focus()
    },
  },
}
