<template>
  <div class="max_container_1800 analytic_component_main">
    <template>
      <div class="component_inner mt-5 update-feature-plan-outter">
        <UpgradeLifetimeAddons></UpgradeLifetimeAddons>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UpgradeLifetimeAddons from './dialogs/UpgradeLifetimeAddons'

export default {
  components: {
    UpgradeLifetimeAddons,
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
  },
  watch: {},
}
</script>
