<template>
  <div>
    <!-- Summary Section -->
    <AnalyticsSummaryBlock
      heading="All Profile Performance Summary"
      subtitle="View your key profile performance metrics from the reporting period."
    >
      <template v-if="fetchingStatistics">
        <div class="flex-5c-row">
          <div
            v-for="i in 5"
            class="flex-fc-col"
            :class="{ 'pl-0': i === 1, 'pr-0': i === 5 }"
            :key="i"
          >
            <SkeletonBox :width="'100%'" height="92px" radius="10px" />
          </div>
        </div>
      </template>

      <div v-else class="flex-5c-row">
        <div class="flex-fc-col pl-0">
          <SummaryCard
            v-if="
              aggregations &&
              typeof aggregations.total_posts != 'undefined' &&
              aggregations.total_posts.value != null
            "
            title="Posts"
            :total="aggregations.total_posts.value"
            :reach="
              getReachCurrentPrevious(
                aggregations.total_posts.value,
                previous_aggregations.total_posts.value
              ) + '%'
            "
            :reachUp="
              getReachUpCurrentPrevious(
                aggregations.total_posts.value,
                previous_aggregations.total_posts.value
              )
            "
            variant="rollup"
          />
          <SummaryCard
            v-else
            title="Posts"
            total="0"
            reach="0%"
            variant="rollup"
          />
        </div>
        <div class="flex-fc-col">
          <SummaryCard
            v-if="
              aggregations &&
              typeof aggregations.total_retweets != 'undefined' &&
              aggregations.total_retweets.value != null
            "
            title="Retweets"
            :total="aggregations.total_retweets.value"
            :reach="
              getReachCurrentPrevious(
                aggregations.total_retweets.value,
                previous_aggregations.total_retweets.value
              ) + '%'
            "
            :reachUp="
              getReachUpCurrentPrevious(
                aggregations.total_retweets.value,
                previous_aggregations.total_retweets.value
              )
            "
            variant="rollup"
          />
          <SummaryCard
            v-else
            title="Retweets"
            total="0"
            reach="0%"
            variant="rollup"
          />
        </div>

        <div class="flex-fc-col">
          <SummaryCard
            v-if="
              aggregations &&
              typeof aggregations.total_engagement != 'undefined' &&
              aggregations.total_engagement.value != null
            "
            title="Engagements"
            :total="aggregations.total_engagement.value"
            :reach="
              getReachCurrentPrevious(
                aggregations.total_engagement.value,
                previous_aggregations.total_engagement.value
              ) + '%'
            "
            :reachUp="
              getReachUpCurrentPrevious(
                aggregations.total_engagement.value,
                previous_aggregations.total_engagement.value
              )
            "
            variant="rollup"
          />
          <SummaryCard
            v-else
            title="Engagements"
            total="0"
            reach="0%"
            variant="rollup"
          />
        </div>

        <div class="flex-fc-col">
          <SummaryCard
            v-if="
              aggregations &&
              typeof aggregations.total_following != 'undefined' &&
              aggregations.total_following.value != null
            "
            title="Following"
            :total="aggregations.total_following.value"
            :reach="
              getReachCurrentPrevious(
                aggregations.total_following.value,
                previous_aggregations.total_following.value
              ) + '%'
            "
            :reachUp="
              getReachUpCurrentPrevious(
                aggregations.total_following.value,
                previous_aggregations.total_following.value
              )
            "
            variant="rollup"
          />
          <SummaryCard
            v-else
            title="Following"
            total="0"
            reach="0%"
            variant="rollup"
          />
        </div>

        <div class="flex-fc-col pr-0">
          <SummaryCard
            v-if="
              aggregations &&
              typeof aggregations.total_followers_count != 'undefined' &&
              aggregations.total_followers_count.value != null
            "
            title="Followers"
            :total="aggregations.total_followers_count.value"
            :reach="
              getReachCurrentPrevious(
                aggregations.total_followers_count.value,
                previous_aggregations.total_followers_count.value
              ) + '%'
            "
            :reachUp="
              getReachUpCurrentPrevious(
                aggregations.total_followers_count.value,
                previous_aggregations.total_followers_count.value
              )
            "
            variant="rollup"
          />
          <SummaryCard
            v-else
            title="Followers"
            total="0"
            reach="0%"
            variant="rollup"
          />
        </div>
      </div>
    </AnalyticsSummaryBlock>

    <div class="d-flex">
      <div class="col-9 p-0">
        <section
          class="mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Published Posts With Engagement</h2>
            <p>See how your audience grew during the reporting period.</p>
          </div>
          <!--    LOADER      -->
          <template v-if="fetchingPublishedPostsWithEngagement">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>

          <SplineBasicChart
            v-else-if="
              published_posts_with_engagement &&
              published_posts_with_engagement.time_line &&
              !(
                isEmptyBucket(published_posts_with_engagement.posts) &&
                isEmptyBucket(published_posts_with_engagement.engagements)
              )
            "
            :categories="published_posts_with_engagement.time_line"
            :series="
              setSeriesForPublishedPostsEngagements(
                published_posts_with_engagement
              )
            "
          />

          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Net Publish Post with Engagement</h2>
          <template v-if="fetchingAggregations">
            <div v-for="i in 3" style="margin-top: 1.3rem" :key="i">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>

          <template v-else>
            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.total_posts != 'undefined' &&
                aggregations.total_posts.value != null
              "
              title="Number of Posts"
              :total="aggregations.total_posts.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.total_posts.value,
                  previous_aggregations.total_posts.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.total_posts.value,
                  previous_aggregations.total_posts.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Number of Posts"
              total="0"
              reach="0%"
              variant="rollup"
            />

            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.avg_engagements != 'undefined' &&
                aggregations.avg_engagements.value != null
              "
              title="Avg Engagements (Likes + RTs/Post)"
              :total="aggregations.avg_engagements.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.avg_engagements.value,
                  previous_aggregations.avg_engagements.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.avg_engagements.value,
                  previous_aggregations.avg_engagements.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Avg Engagements (Likes + RTs/Post)"
              total="0"
              reach="0%"
              variant="rollup"
            />
          </template>
        </section>
      </div>
    </div>

    <!-- Publishing Behavior Section -->
    <div class="d-flex">
      <div class="col-9 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Publishing Behavior</h2>
            <p
              >View the different types of posts you published during the
              selected time period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="fetchingFans">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <ColumnCrossChart
            v-else-if="
              fans &&
              !(isEmptyBucket(fans.favourites) && isEmptyBucket(fans.retweets))
            "
            :categories="fans.timeLine"
            :series="setSeriesForEngagements(fans)"
          />

          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section>
          <h2>Engagement Rollup</h2>

          <template v-if="fetchingFans">
            <div v-for="i in 4" style="margin-top: 1.3rem" :key="i">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>

          <template v-else>
            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.total_engagement != 'undefined' &&
                aggregations.total_engagement.value != null
              "
              title="Total Engagement"
              :total="aggregations.total_engagement.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.total_engagement.value,
                  previous_aggregations.total_engagement.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.total_engagement.value,
                  previous_aggregations.total_engagement.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Total Engagement"
              :total="0"
              reach="0%"
              variant="rollup"
            />

            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.total_retweets != 'undefined' &&
                aggregations.total_retweets.value != null
              "
              title="Retweet"
              :total="aggregations.total_retweets.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.total_retweets.value,
                  previous_aggregations.total_retweets.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.total_retweets.value,
                  previous_aggregations.total_retweets.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Retweet"
              :total="0"
              reach="0%"
              variant="rollup"
            />

            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.favorites != 'undefined' &&
                aggregations.favorites.value != null
              "
              title="Likes"
              :total="aggregations.favorites.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.favorites.value,
                  previous_aggregations.favorites.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.favorites.value,
                  previous_aggregations.favorites.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Likes"
              :total="0"
              reach="0%"
              variant="rollup"
            />
          </template>
        </section>
      </div>
    </div>

    <div class="d-flex" style="margin-top: 1.6rem">
      <div class="col-6 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Post Density - Daily</h2>
          </div>
          <!--    LOADER      -->
          <template v-if="fetchingContentDistributionParsing">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>

          <ColumnChart
            v-else-if="
              content_distribution_daily &&
              !isEmptyBucket(content_distribution_daily.distribution)
            "
            :categories="content_distribution_daily.time_line"
            :series="setSeriesForDensity(content_distribution_daily)"
          />

          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
      <div class="col-6 p-0 m-0 analytics-slide-summary">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Engagement by day/time</h2>
          </div>
          <!--    LOADER      -->
          <template v-if="fetchingBestTimeForContent">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>

          <HeatmapChart
            v-else-if="
              best_time_for_content && !isLastEmptyBucket(best_time_for_content)
            "
            :series="setSeriesForHeatMap(best_time_for_content)"
          />

          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <section
      class="analytics-fb-audience h-chart-section-wrapper"
      style="margin-top: 3.2rem"
    >
      <div class="analytics-section-header">
        <h2>Awareness through Mentions</h2>
        <p
          >View the different types of posts you published during the selected
          time period.</p
        >
      </div>

      <!--    LOADER      -->
      <template v-if="fetchingPublishPostsWithMentions">
        <div class="h-chart flex-center-center" style="height: 90%">
          <SkeletonBox height="250px" width="300px" type="chart" radius="4px" />
        </div>
      </template>

      <SplineBasicChart
        v-else-if="
          publish_posts_with_mentions &&
          publish_posts_with_mentions.time_line &&
          !(
            isEmptyBucket(publish_posts_with_mentions.posts) &&
            isEmptyBucket(publish_posts_with_mentions.engagements)
          )
        "
        :categories="publish_posts_with_mentions.time_line"
        :series="
          setSeriesForAwarenessThroughMentions(publish_posts_with_mentions)
        "
      />

      <div v-else class="flex-center-center" style="height: 90%">
        <div class="analytics-no-data-found">
          <img
            src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p> No Data Found!</p>
        </div>
      </div>
    </section>

    <!-- Audience Growth Section -->
    <div class="d-flex" style="margin-top: 1.6rem">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Audience Growth</h2>
            <p
              >View how your audience has grown over the selected time
              period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="fetchingAudience">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>

          <SplineBasicChart
            v-else-if="
              audience &&
              !(
                isEmptyBucket(audience.followers) &&
                isEmptyBucket(audience.following)
              )
            "
            :categories="audience.time_line"
            :series="setSeriesForAudienceGrowth(audience)"
          />

          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Audience Growth Rollup</h2>

          <template v-if="fetchingAggregations">
            <div v-for="i in 4" style="margin-top: 1.3rem" :key="i">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>

          <template v-else>
            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.total_followers_count != 'undefined' &&
                aggregations.total_followers_count.value != null
              "
              title="Total Followers"
              :total="aggregations.total_followers_count.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.total_followers_count.value,
                  previous_aggregations.total_followers_count.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.total_followers_count.value,
                  previous_aggregations.total_followers_count.value
                )
              "
              variant="rollup"
            />
            <SummaryCard
              v-else
              title="Total Followers"
              total="0"
              reach="0%"
              variant="rollup"
            />
            <SummaryCard
              v-if="
                aggregations &&
                typeof aggregations.total_following != 'undefined' &&
                aggregations.total_following.value != null
              "
              title="People that you are following"
              variant="rollup"
              :total="aggregations.total_following.value"
              :reach="
                getReachCurrentPrevious(
                  aggregations.total_following.value,
                  previous_aggregations.total_following.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  aggregations.total_following.value,
                  previous_aggregations.total_following.value
                )
              "
            />
            <SummaryCard
              v-else
              title="People that you are following"
              total="0"
              reach="0%"
              variant="rollup"
            />
          </template>
        </section>
      </div>
    </div>

    <section>
      <h2>Twitters Top Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post’s lifetime performance.</p
      >

      <div class="row">
        <template v-if="fetchingTopPosts">
          <template v-for="i in 3">
            <div :key="i" class="col-4">
              <div class="analytics-post-card">
                <div class="analytics-post-card__header">
                  <div class="analytics-post-card__header-left">
                    <div class="analytics-post-card__header-img">
                      <SkeletonBox
                        height="2.8rem"
                        width="2.8rem"
                        radius="50%"
                      />
                    </div>
                    <div class="analytics-post-card__header-detail">
                      <div
                        class="d-flex"
                        style="flex-direction: column; margin-left: 10px"
                      >
                        <div class="mb-1">
                          <SkeletonBox width="100px" radius=".2rem" />
                        </div>
                        <SkeletonBox radius=".2rem" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="analytics-post-card__header-right"
                    style="filter: grayscale(100%); opacity: 0.6"
                  >
                    <!-- Dynamic Icon bases on props -->
                    <i class="icon-twitter_icon"></i>
                  </div>
                </div>

                <div class="analytics-post-card__content">
                  <div class="mb-1">
                    <SkeletonBox width="100%" radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox width="100%" height="150px" radius=".2rem" />
                  </div>
                </div>

                <ul class="analytics-post-card__content-ele">
                  <li v-for="i in 4" :key="i">
                    <SkeletonBox width="40%" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </template>

        <div
          v-else-if="top3Posts && top3Posts.length"
          class="col-4"
          v-for="(post, index) in top3Posts"
          :key="index"
        >
          <PostCard :platform_type="'twitter'" :post="post" />
        </div>

        <!--        <div v-else class="flex-center-center" style="height: 90%;">-->

        <!--          <div class="analytics-no-data-found ">-->
        <!--            <img src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">-->
        <!--            <p>   No Data Found!</p>-->
        <!--          </div>-->
        <!--        </div>-->
        <template v-else>
          <div class="d-flex justify_center analytics-post-card-row w-100">
            <template>
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no_post_found.svg"
                  alt=""
                />
                <p
                  >You do not have any posts published in the selected time
                  period.</p
                >
              </div>
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import PostCard from '@src/modules/analytics/views/common/PostCard'

import analyticsUtilsMixin from '../../../components/common/analyticsUtilsMixin'

// Charts
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnChart from '@src/modules/analytics/views/common/Infographics/ColumnChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'
import HeatmapChart from '@src/modules/analytics/views/common/Infographics/HeatmapChart'
import { mapActions } from 'vuex'

// SkeletonBox
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import ColumnCrossChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'OverviewTab',
  props: [
    'fetchingAggregations',
    'fetchingTopPosts',
    'fetchingStatistics',
    'fetchingBestTimeForContent',
    'fetchingContentDistributionParsing',
    'fetchingFans',
    'fetchingPublishPostsWithMentions',
    'fetchingPublishedPostsWithEngagement',
    'fetchingAudience',
    'top3Posts',
    'aggregations',
    'previous_aggregations',
    'best_time_for_content',
    'content_distribution_daily',
    'fans',
    'publish_posts_with_mentions',
    'published_posts_with_engagement',
    'audience'
  ],
  components: {
    ColumnCrossChart,
    AnalyticsSummaryBlock,
    SummaryCard,
    PostCard,
    SkeletonBox,
    SplineBasicChart,
    LineBasicChart,
    ColumnChart,
    PieInnerChart,
    HeatmapChart
  },
  data () {
    return {}
  },
  mounted () {
    console.debug('Done ::::', this.published_posts_with_engagement)
  },
  methods: {
    ...mapActions(['getTwitterAnalyticsService']),
    isEmptyBucket (arr) {
      if (Array.isArray(arr)) {
        return arr.reduce((a, b) => a + b, 0) === 0
      } else {
        return true
      }
    },
    isLastEmptyBucket (arr) {
      if (Array.isArray(arr)) {
        return arr.reduce((a, b) => a + b[2], 0) === 0
      } else {
        return true
      }
    },
    setSeriesForPublishedPostsEngagements (dataContainer) {
      return [
        {
          name: 'Posts',
          data: dataContainer.posts,
          color: '#ffe202'
        },
        {
          name: 'Engagements',
          data: dataContainer.engagements,
          color: '#2fe095'
        }
      ]
    },
    setSeriesForAwarenessThroughMentions (dataContainer) {
      return [
        {
          name: 'Total No. of Posts',
          data: dataContainer.posts,
          color: '#6173fc'
        },
        {
          name: 'Total No. of Mentions',
          data: dataContainer.engagements,
          color: '#26282c'
        }
      ]
    },
    setSeriesForAudienceGrowth (dataContainer) {
      return [
        {
          name: 'Total Followers',
          data: dataContainer.followers,
          color: '#ffe202'
        },
        {
          name: 'Total Following',
          data: dataContainer.following,
          color: '#2fe095'
        }
      ]
    },
    setSeriesForEngagements (dataContainer) {
      return [
        {
          name: 'Retweets',
          data: dataContainer.retweets,
          color: '#26282c'
        },
        {
          name: 'Likes',
          data: dataContainer.favourites,
          color: '#6173fc',
          type: 'spline'
        }
      ]
    },
    setSeriesForDensity (dataContainer) {
      return [
        {
          name: 'Posts',
          data: dataContainer.distribution,
          color: '#2fe095'
        }
      ]
    },
    setSeriesForHeatMap (dataContainer) {
      return [
        {
          name: '',
          borderWidth: 1.3,
          borderColor: '#FFFFFF',
          data: dataContainer,
          dataLabels: {
            enabled: false
          }
        }
      ]
    }
  },
  watch: {}
}
</script>
