export const getDefaultVideoSharingDetails = () => {
  return {
    name: '',
    title: '',
    mime_type: '',
    link: '',
    duration: '00:00:00.00',
    size: 0,
    thumbnail: '',
    thumbnails_suggestions: [],
    converted_video: '',
    converted_link: '',
    converted_mime_type: '',
    converted_size: 0,
    facebook_video: '',
    facebook_converted_size: 0,
    facebook_converted_mimetype: ''
  }
}

export const getDefaultSharingProgressBar = () => {
  return {
    image: 0,
    numberOfImages: 0,
    video: 0
  }
}

export const instagramPostingOption = {
  SET_INSTAGRAM_POSTING_OPTION: 'SET_INSTAGRAM_POSTING_OPTION'
}

export const loadersTypes = {
  SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER:
    'SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER',
  SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER:
    'SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER',
  SET_INITIALIZE_ARTICLE_POST_CREATION_LOADER:
    'SET_INITIALIZE_ARTICLE_POST_CREATION_LOADER',
  SET_FETCH_LINKEDIN_MENTIONS_SUGGESTIONS_LOADER:
    'SET_FETCH_LINKEDIN_MENTIONS_SUGGESTIONS_LOADER',
  SET_FETCH_HASHTAGS_SUGGESTIONS_LOADER:
    'SET_FETCH_HASHTAGS_SUGGESTIONS_LOADER'
}

export const sharingTypes = {
  SET_SHARING_ACTIVE_TAB: 'SET_SHARING_ACTIVE_TAB',
  SET_MENTIONS_DROPDOWN_STATUS: 'SET_SHARING_MENTIONS_DROPDOWN_STATUS',
  SET_MENTIONS_DROPDOWN_LEFT_POSITION:
    'SET_SHARING_MENTIONS_DROPDOWN_LEFT_POSITION',
  SET_MENTIONS_DROPDOWN_TOP_POSITION:
    'SET_SHARING_MENTIONS_DROPDOWN_TOP_POSITION',
  SET_FACEBOOK_MENTIONS_SUGGESTIONS:
    'SET_SHARING_FACEBOOK_MENTIONS_SUGGESTIONS',
  SET_TWITTER_MENTIONS_SUGGESTIONS: 'SET_SHARING_TWITTER_MENTIONS_SUGGESTIONS',
  SET_DRAG_OVER_STATUS: 'SET_DRAG_OVER_STATUS',
  RESET_SHARING_TAGS_SUGGESTION_ITEMS: 'RESET_SHARING_TAGS_SUGGESTION_ITEMS',

  SET_HASHTAGS_DROPDOWN_STATUS: 'SET_HASHTAGS_DROPDOWN_STATUS',
  SET_HASHTAGS_DROPDOWN_LEFT_POSITION: 'SET_HASHTAGS_DROPDOWN_LEFT_POSITION',
  SET_HASHTAGS_DROPDOWN_TOP_POSITION: 'SET_HASHTAGS_DROPDOWN_TOP_POSITION',
  SET_HASHTAGS_SUGGESTIONS: 'SET_HASHTAGS_SUGGESTIONS',
  SET_HASHTAGS_SEARCH_KEYWORD: 'SET_HASHTAGS_SEARCH_KEYWORD',
  SET_HASHTAGS_ACTIVE_BOX: 'SET_HASHTAGS_ACTIVE_BOX',
  SET_SOCIAL_SHARING_MEDIA_DETAILS: 'SET_SOCIAL_SHARING_MEDIA_DETAILS',

  SET_HAS_FIRST_COMMENT: 'setHasFirstComment',
  SET_FIRST_COMMENT_MESSAGE: 'setFirstCommentMessage',
  SET_FIRST_COMMENT_LOCATION: 'setFirstCommentLocation',
  SET_FIRST_COMMENT: 'setFirstComment',
  SET_FIRST_COMMENT_ACCOUNTS: 'setFirstCommentAccount'
}

export const publish = {
  RESET_SOCIAL_SHARE_SELECTION: 'RESET_SOCIAL_SHARE_SELECTION',
  SET_PUBLISH_PLAN_ID: 'SET_PUBLISH_PLAN_ID',
  SET_PUBLISH_REPLUG_VALUE: 'SET_PUBLISH_REPLUG_VALUE',
  SET_PUBLISH_REPLUG_SELECTED_ITEM_VALUE:
    'SET_PUBLISH_REPLUG_SELECTED_ITEM_VALUE',
  SET_PUBLISH_CONTENT_CATEGORY_VALUE: 'SET_PUBLISH_CONTENT_CATEGORY_VALUE',
  SET_PUBLISH_HASHTAG_VALUE: 'SET_PUBLISH_HASHTAG_VALUE',
  SET_PUBLISH_HASHTAG_EDIT_MODE: 'SET_PUBLISH_HASHTAG_EDIT_MODE',
  SET_PUBLISH_SOURCE: 'SET_PUBLISH_SOURCE',
  ADD_PUBLISH_LABELS_ITEM: 'ADD_PUBLISH_LABELS_ITEM',
  SET_PUBLISH_CAMPAIGN: 'SET_PUBLISH_CAMPAIGN',
  RESET_PUBLISH_REPLUG_VALUE: 'RESET_PUBLISH_REPLUG_VALUE',
  RESET_PUBLISH_HASHTAG_VALUE: 'RESET_PUBLISH_HASHTAG_VALUE',
  SET_PLATFORMS_CHECK_ALL_STATUS: 'SET_PUBLISH_PLATFORMS_CHECK_ALL_STATUS',
  SET_PLATFORMS_SELECTION: 'SET_PUBLISH_PLATFORMS_SELECTION',
  SET_PLAN_APPROVAL_DATA: 'SET_PLAN_APPROVAL_DATA'
}

export const publishLoaders = {
  SET_PROCESS_SOCIAL_SHARE_LOADER: 'SET_PROCESS_SOCIAL_SHARE_LOADER'
}

export const blogPostingTypes = {
  SET_UPLOAD_POSTING_IMAGE_LOADER: 'SET_UPLOAD_BLOG_POSTING_IMAGE_LOADER'
}

export const social = {
  SET_ACCOUNT_SELECTION: 'SET_ACCOUNT_SELECTION',
  RESET_ACCOUNT_SELECTION: 'RESET_ACCOUNT_SELECTION',
  SET_GMB_ACCOUNT_SELECTION: 'SET_GMB_ACCOUNT_SELECTION',
  SET_GMB_PREVIEW_ACCOUNT_SELECTION: 'SET_GMB_PREVIEW_ACCOUNT_SELECTION',
  SET_GMB_OPTIONS_START_DATE: 'SET_GMB_OPTIONS_START_DATE',
  SET_GMB_OPTIONS_END_DATE: 'SET_GMB_OPTIONS_END_DATE',
  SET_GMB_OPTIONS: 'SET_GMB_OPTIONS',
  SET_FACEBOOK_ACCOUNT_SELECTION: 'SET_FACEBOOK_ACCOUNT_SELECTION',
  SET_TWITTER_ACCOUNT_SELECTION: 'SET_TWITTER_ACCOUNT_SELECTION',
  SET_PINTEREST_ACCOUNT_SELECTION: 'SET_PINTEREST_ACCOUNT_SELECTION',
  SET_INSTAGRAM_ACCOUNT_SELECTION: 'SET_INSTAGRAM_ACCOUNT_SELECTION',
  SET_LINKEDIN_ACCOUNT_SELECTION: 'SET_LINKEDIN_ACCOUNT_SELECTION',
  SET_YOUTUBE_ACCOUNT_SELECTION: 'SET_YOUTUBE_ACCOUNT_SELECTION',
  SET_CONTENT_CATEGORY_ACCOUNT_SELECTION:
    'SET_CONTENT_CATEGORY_ACCOUNT_SELECTION',
  SET_YOUTUBE_OPTIONS: 'SET_YOUTUBE_OPTIONS',
  SET_YOUTUBE_PLAYLISTS: 'SET_YOUTUBE_PLAYLISTS',
  SET_YOUTUBE_TAGS: 'SET_YOUTUBE_TAGS',
  SET_TWITTER_OPTIONS: 'SET_TWITTER_OPTIONS',
  SET_PINTEREST_OPTIONS: 'SET_PINTEREST_OPTIONS',
  RESET_CAROUSEL_OPTIONS: 'RESET_CAROUSEL_OPTIONS',

}

export const commonTypes = {
  SET_COMMON_FACEBOOK_LOCATION: 'SET_COMMON_FACEBOOK_LOCATION',
  SET_COMMON_TWITTER_LOCATION: 'SET_COMMON_TWITTER_LOCATION',
  SET_COMMON_INSTAGRAM_LOCATION: 'SET_COMMON_INSTAGRAM_LOCATION',
  SET_GMB_BOX_REF: 'SET_GMB_BOX_REF',
  SET_YOUTUBE_BOX_REF: 'SET_YOUTUBE_BOX_REF',
  SET_TIKTOK_BOX_REF: 'SET_TIKTOK_BOX_REF',
  SET_GMB_SHARING_LINK: 'SET_COMMON_GMB_SHARING_LINK',
  SET_TWITTER_VARIATION: 'SET_TWITTER_VARIATION',
  SET_ACTIVE_TWITTER_VARIATION_INDEX: 'SET_ACTIVE_TWITTER_VARIATION_INDEX',
  RESET_TWITTER_VARIATIONS: 'RESET_TWITTER_VARIATIONS',
  SET_COMMON_BOX_STATUS: 'SET_COMMON_BOX_STATUS',
  SET_COMMON_BOX_MESSAGE_STATUS: 'SET_COMMON_BOX_MESSAGE_STATUS',
  SET_COMMON_SHARING_IMAGE_SUGGESTIONS: 'SET_COMMON_SHARING_IMAGE_SUGGESTIONS',
  RESET_COMMON_SHARING_DETAILS: 'RESET_COMMON_SHARING_DETAILS',
  SET_GLOBAL_CATEGORIES_STATUS_LOADER: 'SET_GLOBAL_CATEGORIES_STATUS_LOADER',
  SET_GLOBAL_CATEGORIES_CHANNELS: 'SET_GLOBAL_CATEGORIES_CHANNELS'
}

export const facebookSharingTypes = {
  SET_MENTIONS: 'SET_FACEBOOK_MENTIONS',
  SET_LOCATION: 'SET_FACEBOOK_LOCATION',
  RESET_FACEBOOK_SHARING_DETAILS: 'RESET_FACEBOOK_SHARING_DETAILS'
}
export const twitterSharingTypes = {
  SET_LOCATION: 'SET_TWITTER_LOCATION',
  RESET_TWITTER_SHARING_DETAILS: 'RESET_TWITTER_SHARING_DETAILS'
}
export const pinterestSharingTypes = {
  RESET_PINTEREST_SHARING_DETAILS: 'RESET_PINTEREST_SHARING_DETAILS'
}
export const linkedinSharingTypes = {
  SET_SHARING_IMAGE: 'SET_LINKEDIN_SHARING_IMAGE',
  SET_SHARING_NUMBER_OF_IMAGES: 'SET_LINKEDIN_SHARING_NUMBER_OF_IMAGES',
  SET_SHARING_PREVIEW: 'SET_LINKEDIN_SHARING_PREVIEW',
  SET_UPLOAD_SHARING_IMAGE_LOADER: 'SET_UPLOAD_LINKEDIN_SHARING_IMAGE_LOADER',
  SET_SHARING_VIDEO: 'SET_LINKEDIN_SHARING_VIDEO',
  SET_SHARING_VIDEO_INITIALIZATION: 'SET_LINKEDIN_SHARING_VIDEO_INITIALIZATION',
  SET_SHARING_VIDEO_PERCENTAGE: 'SET_LINKEDIN_SHARING_VIDEO_PERCENTAGE',
  SET_SHARING_VIDEO_THUMBNAIL: 'SET_LINKEDIN_SHARING_VIDEO_THUMBNAIL',
  SET_SHARING_VIDEO_SUGGESTIONS: 'SET_LINKEDIN_SHARING_VIDEO_SUGGESTIONS',
  SET_DEFAULT_SHARING: 'SET_LINKEDIN_DEFAULT_SHARING',
  SET_SHARING_MESSAGE: 'SET_LINKEDIN_SHARING_MESSAGE',
  SET_SHARING_CANCELLED_PREVIEW: 'SET_LINKEDIN_SHARING_CANCELLED_PREVIEW',
  SET_SHARING_IMAGE_PERCENTAGE: 'SET_LINKEDIN_SHARING_IMAGE_PERCENTAGE',
  SET_SHARING_URL: 'SET_LINKEDIN_SHARING_URL',
  SET_SHARING_DETAILS: 'SET_LINKEDIN_SHARING_DETAILS'
}
export const instagramSharingTypes = {
  RESET_INSTAGRAM_SHARING_DETAILS: 'RESET_INSTAGRAM_SHARING_DETAILS'
}
export const tumblrSharingTypes = {
  RESET_TUMBLR_SHARING_DETAILS: 'RESET_TUMBLR_SHARING_DETAILS'
}
export const youtubeSharingTypes = {
  RESET_YOUTUBE_SHARING_DETAILS: 'RESET_YOUTUBE_SHARING_DETAILS'
}
export const tiktokSharingTypes = {
  RESET_TIKTOK_SHARING_DETAILS: 'RESET_TIKTOK_SHARING_DETAILS'
}

export const instagramTypes = {
  SET_LOCATION: 'SET_INSTAGRAM_LOCATION',
  SET_SHARING_IMAGE: 'SET_INSTAGRAM_SHARING_IMAGE',
  SET_SHARING_NUMBER_OF_IMAGES: 'SET_INSTAGRAM_SHARING_NUMBER_OF_IMAGES',
  SET_SHARING_PREVIEW: 'SET_INSTAGRAM_SHARING_PREVIEW',
  SET_UPLOAD_SHARING_IMAGE_LOADER: 'SET_UPLOAD_INSTAGRAM_SHARING_IMAGE_LOADER',
  SET_SHARING_VIDEO: 'SET_INSTAGRAM_SHARING_VIDEO',
  SET_SHARING_VIDEO_INITIALIZATION:
    'SET_INSTAGRAM_SHARING_VIDEO_INITIALIZATION',
  SET_SHARING_VIDEO_PERCENTAGE: 'SET_INSTAGRAM_SHARING_VIDEO_PERCENTAGE',
  SET_SHARING_VIDEO_THUMBNAIL: 'SET_INSTAGRAM_SHARING_VIDEO_THUMBNAIL',
  SET_DEFAULT_SHARING: 'SET_INSTAGRAM_DEFAULT_SHARING',
  SET_SHARING_MESSAGE: 'SET_INSTAGRAM_SHARING_MESSAGE',
  SET_SHARING_CANCELLED_PREVIEW: 'SET_INSTAGRAM_SHARING_CANCELLED_PREVIEW',
  SET_SHARING_IMAGE_PERCENTAGE: 'SET_INSTAGRAM_SHARING_IMAGE_PERCENTAGE',
  SET_SHARING_URL: 'SET_INSTAGRAM_SHARING_URL',
  SET_SHARING_DETAILS: 'SET_INSTAGRAM_SHARING_DETAILS'
}

export const gmb = {
  SET_SHARING_IMAGE: 'SET_GMB_SHARING_IMAGE',
  SET_SHARING_VIDEO: 'SET_GMB_SHARING_VIDEO',
  SET_SHARING_MESSAGE: 'SET_GMB_SHARING_MESSAGE',
  SET_SHARING_URL: 'SET_GMB_SHARING_URL',
  SET_SHARING_PREVIEW: 'SET_GMB_SHARING_PREVIEW',
  SET_SHARING_DETAILS: 'SET_GMB_SHARING_DETAILS',
  SET_DEFAULT_SHARING: 'SET_GMB_DEFAULT_SHARING',
  SET_SHARING_VIDEO_INITIALIZATION: 'SET_GMB_SHARING_VIDEO_INITIALIZATION',
  SET_SHARING_VIDEO_PERCENTAGE: 'SET_GMB_SHARING_VIDEO_PERCENTAGE',
  SET_SHARING_VIDEO_THUMBNAIL: 'SET_GMB_SHARING_VIDEO_THUMBNAIL',
  SET_SHARING_VIDEO_SUGGESTIONS: 'SET_GMB_SHARING_VIDEO_SUGGESTIONS',
  SET_SHARING_IMAGE_PERCENTAGE: 'SET_GMB_SHARING_IMAGE_PERCENTAGE',
  SET_SHARING_CANCELLED_PREVIEW: 'SET_GMB_SHARING_CANCELLED_PREVIEW',
  SET_FETCH_SHARING_PREVIEW_LOADER: 'SET_FETCH_GMB_SHARING_PREVIEW_LOADER',
  SET_FETCH_SHORT_LINKS_LOADER: 'SET_FETCH_GMB_SHORT_LINKS_LOADER',
  SET_UPLOAD_SHARING_IMAGE_LOADER: 'SET_UPLOAD_GMB_SHARING_IMAGE_LOADER',
  SET_UPLOAD_SHARING_VIDEO_LOADER: 'SET_UPLOAD_GMB_SHARING_VIDEO_LOADER',
  RESET_GMB_SHARING_DETAILS: 'RESET_GMB_SHARING_DETAILS'
}
export const youtubeTypes = {
  SET_SHARING_IMAGE: 'SET_YOUTUBE_SHARING_IMAGE',
  SET_SHARING_VIDEO: 'SET_YOUTUBE_SHARING_VIDEO',
  SET_SHARING_MESSAGE: 'SET_YOUTUBE_SHARING_MESSAGE',
  SET_SHARING_URL: 'SET_YOUTUBE_SHARING_URL',
  SET_SHARING_PREVIEW: 'SET_YOUTUBE_SHARING_PREVIEW',
  SET_SHARING_DETAILS: 'SET_YOUTUBE_SHARING_DETAILS',
  SET_DEFAULT_SHARING: 'SET_YOUTUBE_DEFAULT_SHARING',
  SET_SHARING_CANCELLED_PREVIEW: 'SET_YOUTUBE_SHARING_CANCELLED_PREVIEW',
  SET_SHARING_IMAGE_PERCENTAGE: 'SET_YOUTUBE_SHARING_IMAGE_PERCENTAGE',
  SET_SHARING_VIDEO_INITIALIZATION: 'SET_YOUTUBE_SHARING_VIDEO_INITIALIZATION',
  SET_SHARING_VIDEO_PERCENTAGE: 'SET_YOUTUBE_SHARING_VIDEO_PERCENTAGE',
  SET_SHARING_VIDEO_THUMBNAIL: 'SET_YOUTUBE_SHARING_VIDEO_THUMBNAIL',
  SET_SHARING_VIDEO_SUGGESTIONS: 'SET_YOUTUBE_SHARING_VIDEO_SUGGESTIONS'
}

export const tiktokTypes = {
  SET_SHARING_VIDEO_INITIALIZATION: 'SET_TIKTOK_SHARING_VIDEO_INITIALIZATION',
  SET_SHARING_VIDEO_PERCENTAGE: 'SET_TIKTOK_SHARING_VIDEO_PERCENTAGE',
  SET_SHARING_VIDEO: 'SET_TIKTOK_SHARING_VIDEO',
  SET_SHARING_DETAILS: 'SET_TIKTOK_SHARING_DETAILS',
}

export const rewritingTypes = {
  RESET_REWRITING_SELECTION: 'RESET_REWRITING_SELECTION',
  SET_REWRITING_SELECTION: 'SET_REWRITING_SELECTION'
}

export const activityTypes = {
  SET_ACTIVITIES: 'SET_ACTIVITIES',
  SET_TOTAL_ACTIVITIES: 'SET_TOTAL_ACTIVITIES'
}

export const commentsTypes = {
  RESET_COMMENTS_LIST: 'RESET_COMMENTS_LIST',
  SET_COMMENTS_LOADER: 'SET_COMMENTS_LOADER',
  ADD_COMMENT: 'ADD_COMMENT',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  RESET_NEW_COMMENT: 'RESET_NEW_COMMENT',
  UPDATE_NEW_COMMENT: 'UPDATE_NEW_COMMENT',
  SET_COMMENT_PREVIEW_LINK: 'SET_COMMENT_PREVIEW_LINK',
  SET_CREATE_COMMENT_LOADER: 'SET_CREATE_COMMENT_LOADER',
  SET_COMMENTS: 'SET_COMMENTS'
}

export const tasksTypes = {}

export const publishTimeTypes = {
  SET_PUBLISHING_TIME_TYPE: 'SET_PUBLISHING_TIME_TYPE'
}

export const approvalStages = {
  RESET_APPROVAL_DATA: 'RESET_APPROVAL_DATA',
  SET_APPROVAL_MEMBERS: 'SET_APPROVAL_MEMBERS',
  SET_APPROVAL_PLAN: 'SET_APPROVAL_PLAN',
  SET_APPROVAL_TYPE: 'SET_APPROVAL_TYPE',
  SET_APPROVAL_DATA: 'SET_APPROVAL_DATA'
}

export const blogPosting = {
  SET_BLOG_OPTIONS: 'SET_BLOG_OPTIONS',
  SET_BLOG_SELECTION: 'SET_BLOG_SELECTION',
  SET_WORDPRESS_AUTHORS: 'SET_WORDPRESS_AUTHORS',
  SET_WORDPRESS_CATEGORIES: 'SET_WORDPRESS_CATEGORIES',
  SET_WORDPRESS_SITE_STATUS: 'SET_WORDPRESS_SITE_STATUS',
  SET_WORDPRESS_SELECTED_AUTHOR: 'SET_WORDPRESS_SELECTED_AUTHOR',
  SET_WORDPRESS_SELECTED_CATEGORIES: 'SET_WORDPRESS_SELECTED_CATEGORIES',
  SET_SHOPIFY_AUTHORS: 'SET_SHOPIFY_AUTHORS',
  SET_SHOPIFY_TAGS: 'SET_SHOPIFY_TAGS',
  SET_SHOPIFY_SITE_STATUS: 'SET_SHOPIFY_SITE_STATUS',
  SET_SHOPIFY_SELECTED_AUTHOR: 'SET_SHOPIFY_SELECTED_AUTHOR',
  SET_SHOPIFY_SELECTED_TAGS: 'SET_SHOPIFY_SELECTED_TAGS',
  RESET_BLOG_SELECTION: 'RESET_BLOG_SELECTION',
  SET_BLOG_POSTING_STAGE: 'SET_BLOG_POSTING_STAGE',
  SET_BLOG_POSTING_TYPE: 'SET_BLOG_POSTING_TYPE',
  SET_BLOG_POSTING_TITLE: 'SET_BLOG_POSTING_TITLE',
  SET_BLOG_POSTING_HTML: 'SET_BLOG_POSTING_HTML',
  SET_BLOG_POSTING_LINK: 'SET_BLOG_POSTING_LINK',
  SET_BLOG_POSTING_IMAGE: 'SET_BLOG_POSTING_IMAGE',
  SET_BLOG_POSTING_OPTIONAL_DESCRIPTION:
    'SET_BLOG_POSTING_OPTIONAL_DESCRIPTION',
  SET_BLOG_POSTING_CUSTOMIZE_SEO_PREVIEW_STATUS:
    'SET_BLOG_POSTING_CUSTOMIZE_SEO_PREVIEW_STATUS',
  SET_BLOG_POSTING_META_TITLE: 'SET_BLOG_POSTING_META_TITLE',
  SET_BLOG_POSTING_META_URL: 'SET_BLOG_POSTING_META_URL',
  SET_BLOG_POSTING_META_DESCRIPTION: 'SET_BLOG_POSTING_META_DESCRIPTION',
  SET_BLOG_POSTING_ID: 'SET_BLOG_POSTING_ID',
  SET_BLOG_POSTING_DROPZONE_STATUS: 'SET_BLOG_POSTING_DROPZONE_STATUS',
  SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS:
    'SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS',
  RESET_BLOG_SELECTION_EDIT_STATES: 'RESET_BLOG_SELECTION_EDIT_STATES',
  SET_PRIMARY_BLOG_SELECTION: 'SET_PRIMARY_BLOG_SELECTION',
  SET_PRIMARY_BLOG_PLATFORM_NAME: 'SET_PRIMARY_BLOG_PLATFORM_NAME',
  SET_PRIMARY_BLOG_TIME: 'SET_PRIMARY_BLOG_TIME',
  SET_PRIMARY_BLOG_DATE: 'SET_PRIMARY_BLOG_DATE',
  SET_PRIMARY_BLOG_OPTION: 'SET_PRIMARY_BLOG_OPTION',
  RESET_PRIMARY_BLOG_SELECTION: 'RESET_PRIMARY_BLOG_SELECTION',
  SET_SECONDARY_BLOG_SELECTION: 'SET_SECONDARY_BLOG_SELECTION',
  SET_SECONDARY_BLOG_IDS: 'SET_SECONDARY_BLOG_IDS',
  RESET_BLOG_POSTING: 'RESET_BLOG_POSTING',
  RESET_BLOG_EDIT_VALUES: 'RESET_BLOG_EDIT_VALUES',
  SET_WEBFLOW_COLLECTIONS: 'SET_WEBFLOW_COLLECTIONS',
  SET_WEBFLOW_LOADING: 'SET_WEBFLOW_LOADING',
  SET_WORDS_COUNT:"SET_WORDS_COUNT"
}
