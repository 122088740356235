<script>
// libraries
import { defineComponent, watch, ref, inject } from 'vue'

// components
import TableData from '@src/modules/setting/components/content-categories/calendar/TableData.vue'
import TableHeader from '@src/modules/setting/components/content-categories/calendar/TableHeader.vue'

export default defineComponent({
  name: 'TimeGridCalendar',
  components: { TableData, TableHeader },
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    editSlotModal: {
      type: Function,
      default: () => {},
    },
    removeSlotModal: {
      type: Function,
      default: () => {},
    },
    addSlotModal: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = inject('root')
    const days = ref([
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ])
    /**
     * detect change in slots
     */
    watch(
      () => props.slots,
      (current, old) => {
        if (current !== old) {
          assignEvents(props.slots)
        }
      }
    )

    /**
     * create div element
     * @param htmlString
     * @returns {ChildNode}
     */
    const createElementFromHTML = (htmlString) => {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      // Change this to div.childNodes to support multiple top-level nodes.
      return div.firstChild
    }
    /**
     * Map events on calendar w.r.t to time
     */
    const assignEvents = () => {
      const slotsArr = document.querySelectorAll('.custom-slots')
      slotsArr.forEach((slot) => {
        slot.parentNode.nextSibling.style.height = '100%'
        slot.remove()
      })
      props.slots.length > 0 &&
        props.slots.forEach((item) => {
          const element = document.querySelector(
            '[data-date-time=' +
              item.day.toString() +
              '-' +
              item.hour +
              '-' +
              item.period +
              ']'
          )
          if (element) {
            const truncatedName =
              item?.category?.name?.length >= 35
                ? item?.category?.name.substring(0, 35) + '...'
                : item?.category?.name
            const node =
              createElementFromHTML(`<div class="group custom-slots w-full rounded-t-lg rounded-b-lg border border-solid border-[#70707045] mt-2 last:mb-[38px]">
              <div class="edit-delete-slot lg:flex lg:justify-between lg:flex-row sm:flex-col sm:justify-center sm:align-center lg:px-2 lg:py-2.5 sm:px-1 sm:py-1.5  lg:h-[1.875rem] sm:h-[3.5rem] border border-solid border-top-0 border-left-0 border-right-0 border-bottom-2" >
              <span class="text-[#3a4557ba] text-[0.7rem]">   ${root.$filters.addZeroToStart(
                item.hour
              )}:${root.$filters.addZeroToStart(item.minute)}
          ${item.period}</span>
              <div class="hidden group-hover:block">
                 <span
                 title="Edit Slot"
          class="edit-slot-button cursor-pointer icon-edit-cs text-xs remove_icon ml-auto text-[#3a4557ba] hover:text-[#ff4500]"
        ></span>
        <span
        title="Remove Slot"
          class="remove-slot-button cursor-pointer icon-delete-cs text-xs remove_icon ml-1 text-[#3a4557ba] hover:text-[#ff4500]"
        ></span>
</div>
              </div>
         <div class=" w-full px-2 py-2.5 text-center cursor-pointer edit-slot-bar rounded-b-lg ${
           item.category.color
         }">
              <span class="text-white font-medium text-[0.688rem] capitalize overflow-ellipsis" title="${
                item?.category?.name
              }">${truncatedName || '--'}</span>

</div>
              </div>`)
            // onclick event listener on edit button of specific slot
            node
              .querySelector('.edit-slot-button')
              .addEventListener('click', () => {
                if (item.hour === 0) {
                  item.hour = 12
                }
                props.editSlotModal(item)
              })
            node
              .querySelector('.edit-slot-bar')
              .addEventListener('click', () => {
                if (item.hour === 0) {
                  item.hour = 12
                }
                props.editSlotModal(item)
              })

            // onclick event listener on remove button of specific slot
            node
              .querySelector(`.remove-slot-button`)
              .addEventListener('click', () => {
                props.removeSlotModal({
                  slotId: item._id,
                  weekday: item.day,
                  index: item.idx,
                  categoryId: item.category._id,
                })
              })
            element.querySelector('.all-slots').appendChild(node)

            // Get the all-slots element
            const allSlots = element.querySelector('.all-slots')

            // Get the height of the all-slots element
            const allSlotsHeight = allSlots.offsetHeight + 'px'

            // Set the height of the other element
            element.querySelector(
              '.custom-slot-btn'
            ).style.height = `calc(100% - ${allSlotsHeight})`
          }
        })
    }
    // return time e.g. 12a, e.t.c
    const showHour = (index) => {
      let time = ''
      if (index > 12) {
        time = (index % 12) + 'pm'
      } else if (index === 0) {
        time = 12 + 'am'
      } else if (index === 12) {
        time = 12 + 'pm'
      } else {
        time = index + 'am'
      }
      return time
    }
    return {
      assignEvents,
      createElementFromHTML,
      days,
      showHour,
    }
  },
})
</script>

<template>
  <table class="table-fixed w-full">
    <thead>
      <tr>
        <th
          class="sticky top-0 z-10 h-[2rem] box-content border border-2 border-[#2a8bc3] w-1/5"
        ></th>
        <TableHeader
          v-for="(day, i) in days"
          :key="`table_header_${i}_${Math.random()}`"
          :day="day"
        />
      </tr>
    </thead>
    <tbody>
      <tr v-for="(_, index) in 24" :key="`time_calendar_${index}`">
        <td
          class="custom-table-time-td lg:text-[0.85rem] sm:text-[0.688rem] group relative h-[2rem] box-content border border-2 border-[#2a8bc3] text-center"
        >
          {{ showHour(index) }}
        </td>
        <TableData
          v-for="(day, keyIdx) in days"
          :key="`table_data_${keyIdx}_${Math.random()}`"
          :index="index"
          :day="day"
          :add-slot-modal="addSlotModal"
        />
      </tr>
    </tbody>
  </table>
</template>

<style lang="less">
/*writing custom media queries to handle responsiveness at laptop screen  */
@media screen and (min-width: 1270px) and (max-width: 1400px) {
  .edit-delete-slot {
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
    font-size: 0.7rem !important;
  }
}
@media screen and (min-width: 1270px) and (max-width: 1600px) {
  .custom-table-time-td {
    font-size: 0.7rem !important;
  }
}
</style>
