<template>
  <b-modal
    id="add_organization_member"
    modal-class="add_slot default-scroll normal_modal add_organization_member"
    size="xl"
    hide-footer
    hide-header
    centered
  >
    <div class="basic_form">
      <div class="modal_head d-flex align-items-center">
        <h4>Add Team Member</h4>
        <button
          type="button"
          class="modal_head__close"
          data-dismiss="modal"
          @click="$bvModal.hide('add_organization_member')"
        >
          &times;
        </button>
      </div>

      <div class="modal_body">
        <!--        <div class="row">
          <div class="col-md-6">
            <div class="field_group mt-0 input_validation">
              <label for="" class="label-animated">First Name</label>
              <input
                type="text"
                placeholder="First Name"
                v-model="getOrganizationMemberAdd.first_name"
                :class="{
                  'input-field-error':
                    getOrganizationMemberAddValidations.first_name,
                }"
              />
            </div>
            <span
              class="input-error"
              v-if="getOrganizationMemberAddValidations.first_name"
            >
              First name cannot be empty.
            </span>
          </div>
          <div class="col-md-6">
            <div class="field_group mt-0 input_validation">
              <label for="" class="label-animated">Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                v-model="getOrganizationMemberAdd.last_name"
                :class="{
                  'input-field-error':
                    getOrganizationMemberAddValidations.last_name,
                }"
              />
            </div>
            <span
              class="input-error"
              v-if="getOrganizationMemberAddValidations.last_name"
            >
              Last name cannot be empty.
            </span>
          </div>
        </div>-->
        <div class="row">
          <div class="col-md-6">
            <div class="field_group input_validation">
              <label for="" class="label-animated">Email</label>
              <input
                v-model="getOrganizationMemberAdd.email"
                type="text"
                :class="{
                  'input-field-error':
                    getOrganizationMemberAddValidations.email,
                }"
                placeholder="Email Address"
              />
            </div>
            <span
              v-if="getOrganizationMemberAddValidations.email"
              class="input-error"
            >
              Email Address cannot be empty.
            </span>
          </div>
          <div class="col-md-6">
            <div class="field_group input_validation">
              <!--<label for="" class="label-animated">User Role</label>-->
              <select id="" v-model="getOrganizationMemberAdd.role" name="">
                <option value="admin">Administrator</option>
                <option value="collaborator">Collaborator</option>
                <option value="approver">Approver</option>
              </select>
              <i class="icon-dropdown-cs"></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="field_group input_validation">
              <!--<label for="">User Type</label>-->
              <select
                id=""
                v-model="getOrganizationMemberAdd.membership"
                class="with_bg"
                name=""
              >
                <option value="team">Team</option>
                <option value="client">Client</option>
              </select>
              <i class="icon-dropdown-cs"></i>
            </div>
          </div>
        </div>

        <transition name="slide-bottom">
          <div
            v-if="getOrganizationMemberAdd.role === 'collaborator'"
            class="clear permission_block"
          >
            <h2 class="heading">Permissions</h2>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="addBlog">
                      Can add blogs
                      <input
                        id="addBlog"
                        v-model="getOrganizationMemberAdd.permissions.addBlog"
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>

                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="addSource">
                      Can add sources
                      <input
                        id="addSource"
                        v-model="getOrganizationMemberAdd.permissions.addSource"
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>

                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="viewTeam">
                      Can view team
                      <input
                        id="viewTeam"
                        v-model="getOrganizationMemberAdd.permissions.viewTeam"
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>

                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="rescheduleQueue">
                      Can reschedule queue
                      <input
                        id="rescheduleQueue"
                        v-model="
                          getOrganizationMemberAdd.permissions.rescheduleQueue
                        "
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="addSocial">
                      Can add social accounts
                      <input
                        id="addSocial"
                        v-model="getOrganizationMemberAdd.permissions.addSocial"
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>

                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="addTopic">
                      Can add topics
                      <input
                        id="addTopic"
                        v-model="getOrganizationMemberAdd.permissions.addTopic"
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>

                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="postsReview">
                      Can approve and publish posts
                      <input
                        id="postsReview"
                        v-model="
                          getOrganizationMemberAdd.permissions.postsReview
                        "
                        type="checkbox"
                        name=""
                      />
                      <span class="check check-sm"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div
          class="text_block"
          :class="{
            'input-field-error workspace_selection_missing':
              getOrganizationMemberAddValidations.workspaces,
          }"
        >
          <label for="">Select Workspaces(s)</label>
          <p>Select the workspaces to add your team member into.</p>
          <div class="workspace_checkbox_list">
            <div class="field_group">
              <div class="checkbox_container">
                <label for="select_all_workspaces">
                  Select All
                  <input
                    id="select_all_workspaces"
                    v-model="select_all_workspaces"
                    type="checkbox"
                    name=""
                    @change="selectAllWorkspacesForOrganization"
                  />
                  <span class="check"></span>
                </label>
              </div>
            </div>

            <div class="row">
              <div
                v-for="(workspace, index) in getOrganizationMembers.workspaces"
                :key="index"
                class="col-md-4"
              >
                <div class="field_group">
                  <div class="checkbox_container">
                    <label :for="'workspace_' + workspace.workspace._id">
                      <input
                        :id="'workspace_' + workspace.workspace._id"
                        v-model="getOrganizationMemberAdd.workspaces"
                        type="checkbox"
                        name=""
                        :value="workspace.workspace_id"
                      />
                      <span class="check"></span>
                      <div class="profile_picture">
                        <div class="picture_block picture_block_background">
                          <div
                            v-if="workspace.workspace.logo"
                            class="img rounded_image"
                            :style="{
                              'background-image':
                                'url(' + workspace.workspace.logo + ')',
                            }"
                          ></div>
                          <div
                            v-else
                            class="img"
                            style="
                              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                            "
                          ></div>
                        </div>
                        <div class="text_block">
                          <p class=" ">{{ workspace.workspace.name }}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          v-if="getOrganizationMemberAddValidations.workspaces"
          class="input-error"
        >
          Please select atleast one workspace.
        </span>

        <div class="input_field mt-4">
          <button
            class="btn btn-studio-theme-space"
            @click="validateAndStoreOrganizationMember"
          >
            <span> Add </span>
            <clip-loader
              v-if="getOrganizationLoaders.store_member"
              color="#ffffff"
              :size="'12px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.workspace_selection_missing {
  padding: 0.625rem;
  border-radius: 4px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import { organizationTypes } from '@src/modules/setting/store/states/mutation-types'

export default {
  components: {},
  data() {
    return {
      select_all_workspaces: true,
    }
  },
  computed: {
    ...mapGetters([
      'getOrganizationLoaders',
      'getOrganizationMemberAddValidations',
      'getOrganizationMemberAdd',
      'getOrganizationMembers',
    ]),
  },
  watch: {
    'getOrganizationMemberAdd.first_name.length'(value) {
      if (value && value > 0) {
        this.$set(this.getOrganizationMemberAddValidations, 'first_name', false)
      }
    },
    'getOrganizationMemberAdd.last_name.length'(value) {
      if (value && value > 0) {
        this.$set(this.getOrganizationMemberAddValidations, 'last_name', false)
      }
    },
    'getOrganizationMemberAdd.email.length'(value) {
      if (value && value > 0) {
        this.$set(this.getOrganizationMemberAddValidations, 'email', false)
      }
    },
    'getOrganizationMemberAdd.workspaces.length'(value) {
      if (value === 0) {
        this.select_all_workspaces = false
      }
      if (value && value > 0) {
        this.select_all_workspaces =
          this.getOrganizationMembers.workspaces_count === value
        this.$set(this.getOrganizationMemberAddValidations, 'workspaces', false)
      }
    },
  },
  methods: {
    ...mapActions([]),
    selectAllWorkspacesForOrganization() {
      console.log(this.select_all_workspaces)
      const workspacesList = this.getOrganizationMembers.workspaces.map(
        (item) => item.workspace_id
      )
      if (this.select_all_workspaces) {
        this.$set(this.getOrganizationMemberAdd, 'workspaces', workspacesList)
      } else {
        this.$set(this.getOrganizationMemberAdd, 'workspaces', [])
      }
    },
    async validateAndStoreOrganizationMember() {
      const memberAdd = this.getOrganizationMemberAdd
      /* this.$store.commit(
        organizationTypes.SET_ORGANIZATION_MEMBERS_VALIDATIONS,
        {
          type: 'first_name',
          value: this.requiredCheck(memberAdd.first_name)
        }
      )
      this.$store.commit(
        organizationTypes.SET_ORGANIZATION_MEMBERS_VALIDATIONS,
        {
          type: 'last_name',
          value: this.requiredCheck(memberAdd.last_name)
        }
      ) */
      this.$store.commit(
        organizationTypes.SET_ORGANIZATION_MEMBERS_VALIDATIONS,
        {
          type: 'email',
          value: this.requiredCheck(memberAdd.email),
        }
      )
      this.$store.commit(
        organizationTypes.SET_ORGANIZATION_MEMBERS_VALIDATIONS,
        {
          type: 'workspaces',
          value: this.getOrganizationMemberAdd.workspaces.length === 0,
        }
      )

      const result = Object.keys(
        this.getOrganizationMemberAddValidations
      ).every((k) => this.getOrganizationMemberAddValidations[k] === false)
      if (result) {
        await this.$store.dispatch('bulkAddTeamMember')
        this.$bvModal.hide('add_organization_member')
      } else {
        console.log('validation failed...')
      }
    },
  },
}
</script>
