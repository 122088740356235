<template>
  <div class="flex items-center pt-5 w-100 relative">
    <div class="relative w-100">
      <input
        type="text"
        placeholder="Find a saved caption ..."
        class="border-[1px] border-solid border-[#7070704d] w-100 py-2 px-4 text-[#3a4557] rounded-xl py-3 pl-[2.5rem] bg-[#bcc2c629] hover:border-[#2f8ae0]"
        @input="filterCaptions"
      />
      <button
        class="absolute top-[0.5rem] pointer border-0 outline-none left-[1rem] inline-block"
      >
        <i class="icon-Search block text-lg text-[#3a4557]"></i>
      </button>
    </div>
    <button
      class="text-xs capitalize bg-transparent py-[0.8rem] px-[2rem] rounded-xl border-[1px] border-solid border-[#7070704d] pointer white-space-nowrap m-2 hover:border-[#2f8ae0] font-bold"
      @click="changeViewMode('create')"
    >
      <i class="fa fa-plus font-light text-sm mr-[0.7rem] text-[#a39f9f]"></i
      ><span class="text-[#4a4a4a] text-sm font-normal">Create New </span>
    </button>
    <clip-loader
      v-if="loadingCaptions"
      class="d-inline-block align-middle ml-1"
      style="margin-right: 0.625rem"
      color="#989eb5"
      :size="'30px'"
    />
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CaptionsListing',
  props: {
    loadingCaptions: {
      type: Boolean,
      default: false,
    },
    changeViewMode: {
      type: Function,
      default: () => {},
    },
    filterCaptions: {
      type: Function,
      default: () => {},
    },
  },
})
</script>
