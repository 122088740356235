<template>
  <div class="auth">
    <div class="row h-full">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container">
          <form class="auth-form simple-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2 class="flex justify-content-center">Welcome Back...</h2>
            </div>

            <CstFloatingLabelInput
              id="email"
              v-model="email"
              class="mb-6"
              type="email"
              label="Email Address"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
              id="password"
              v-model="password"
              class="mb-6"
              type="password"
              label="Password"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="simple-form__check-field">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="rememberCheck">
                    Remember me
                    <input
                      id="rememberCheck"
                      v-model="remember_me"
                      type="checkbox"
                      checked="checked"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>

              <router-link :to="{ name: 'forgotpassword' }"
                >Forgot Password?
              </router-link>
            </div>
            <div class="auth-form__button-field">
              <button
                data-cy="sign-in"
                :disabled="loginLoader"
                class="btn btn-studio-theme-space btn-size-large"
                @click.prevent="loginAccount"
              >
                <span>
                  <template v-if="loginLoader">Processing</template>
                  <template v-else>Sign in</template>
                </span>
                <img
                  v-if="loginLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>

            <div class="auth-form__social-field">
              <p>Or sign in using social media</p>

              <span :disabled="!getSSOAuthorizationLinks.facebook">
                <button
                  data-cy="facebook"
                  class="btn btn-studio-theme-space btn-size-large auth-form__social-field--fb"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.facebook, 'logged_in')
                  "
                >
                  <i class="fab fa-facebook-f"></i>
                </button>
              </span>
              <span :disabled="!getSSOAuthorizationLinks.twitter">
                <button
                  data-cy="facebook"
                  class="btn btn-studio-theme-space btn-size-large auth-form__social-field--tw"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.twitter, 'logged_in')
                  "
                >
                  <i class="fab fa-twitter"></i>
                </button>
              </span>
            </div>
          </form>

          <div class="auth-form-container__footer">
            <p
              >Just approving content?
              <router-link :to="{ name: 'magicLogin' }"
                >Sign in without a password
              </router-link>
            </p>
            <p
              >Don’t have an account?
              <router-link :to="{ name: 'signup' }"
                ><b data-cy="sign-up">Sign Up</b></router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <NewFeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { authenticationTypes } from '@src/store/mutation-types'
import { mapActions, mapGetters } from 'vuex'
import { login } from '@src/modules/account/services'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import NewFeaturesSlider from '@src/modules/account/components/NewFeaturesSlider.vue'
export default {
  name: 'SignIn',
  components: {
    CstFloatingLabelInput,
    NewFeaturesSlider,
  },
  data() {
    return {
      email: '',
      password: '',
      loginLoader: false,
      remember_me: false,
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks', 'getWorkspaces']),
  },
  created() {
    this.fetchSSOLinks()
  },
  methods: {
    ...mapActions(['fetchDiscover', 'fetchSocialAccounts']),

    async loginAccount() {
      console.debug('Method::loginAccount')

      /**
       * If query params contain Frill.co SSO
       * Authenticate user and redirect to frill redirect URI
       */
      const postData = this.getFrillParams()

      if (!this.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (!this.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }

      this.loginLoader = true

      try {
        const response = await login({
          username: this.email,
          password: this.password,
          remember_me: this.remember_me,
          ...postData,
        })

        // check if user has enabled 2FA
        if (response['2fa_enabled']) {
          await this.$router.push({
            name: 'twoFactor',
            params: {
              token: response.user_info,
            },
            query: {
              ...this.$route.query,
            },
          })
          return
        }

        // set the cookies
        this.$store.commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        this.$store.commit(
          authenticationTypes.SET_LOGGED_USER,
          response.logged_user
        )
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        // custom event for user maven
        this.trackUserMaven('logged_in')

        // If login is request from SSO - Frill.co, redirect user
        if (response.redirect) {
          window.location.href = response.redirect
        }

        // check for redirected url
        if (
          this.$route.query.redirected_url &&
          this.$route.query.redirected_url !== '/logout' &&
          this.$route.query.redirected_url !== '/login'
        ) {
          window.location.href = this.$route.query.redirected_url
          return
        }

        if (response.slug) {
          await this.$router.push({
            name: 'dashboard',
            params: { workspace: response.slug },
          })
          return
        }

        await this.fetchProfile()
        this.isEmailVerified()
      } catch (error) {
        this.alertMessage(error.message || UNKNOWN_ERROR, 'error')
      }

      this.loginLoader = false
    },
  },
}
</script>
