var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white_box",on:{"click":function($event){$event.preventDefault();return _vm.EventBus.$emit('linkedin-analytics-preview', _vm.post)}}},[[_c('div',{staticClass:"flex-row d-flex table_body align-items-center"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"user_name"},[_vm._m(0),(_vm.getImage('linkedin', _vm.post))?_c('img',{attrs:{"src":_vm.getImage('linkedin', _vm.post),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}):_c('img',{attrs:{"src":require("@assets/img/profile_default.svg"),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}),_c('div',{staticClass:"right right-text col"},[_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.getPostName('linkedin', _vm.post)))]),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("publishedDate")(_vm.post.created_at)))])])])]),_c('div',{staticClass:"col-2 py-2"},[(_vm.post.image)?_c('div',{staticClass:"post_image dashboard-post-image",style:({
            backgroundImage:
              'url(' + _vm.getResizedImageURL(_vm.post.image, 325, 0) + ')',
          })}):_c('div',{staticClass:"post_image dashboard-post-image",style:({
            backgroundImage:
              'url(' +
              require('../../assets/img/no_data_images/not-found.png') +
              ')',
          })})]),_c('div',{staticClass:"col-4"},[[_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(
              _vm.limitTextLength(
                _vm.isHashtag(_vm.isLink(_vm.nextLineReplacement(_vm.post.title))),
                400,
              )
            )}})]],2),(_vm.post.favorites)?_c('div',{staticClass:"col-2 pl-5 text-center font-weight-500"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(_vm.post.favorites))+" ")]):_c('div',{staticClass:"col-2 pl-5 text-center font-weight-500"},[_vm._v("0")]),(_vm.post.comments)?_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(" "+_vm._s(_vm.$filters.numberToCommas(_vm.post.comments))+" ")]):_c('div',{staticClass:"col-1 text-center font-weight-500"},[_vm._v(" 0")]),_c('div',{staticClass:"col-1 text-center"},[_vm._v(" -")])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon social_image_icon social_image_icon_linkedin"},[_c('i',{staticClass:"cs-linkedin"})])
}]

export { render, staticRenderFns }