<template>
  <div>

        <div class="filter grid grid-cols-2 gap-1 w-100">
          <CstInputFields  :date-options="dateOptions" type="date" placeholder="Select date" size="small" class="bg-cs-white" @value="handleDateChange"/>
          <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-cs-white">
            <template slot="selected">{{sortType}}</template>
            <template v-slot>
              <CstDropdownItem @click="handleSortType('Most Shared')">
                Most Shared
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Recent')">
                Recent
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Relevance')">
                Relevance
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Facebook Shares')">
                Facebook Shares
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Twitter Shares')">
                Twitter Shares
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Pinterest Shares')">
                Pinterest Shares
              </CstDropdownItem>
              <CstDropdownItem @click="handleSortType('Reddit Shares')">
                Reddit Shares
              </CstDropdownItem>
            </template>
          </CstDropdown>
          <CstDropdown  class="" size="small" button-classes="w-100 flex justify-between bg-cs-white">
            <template v-slot:selected>
              {{region.name}}
            </template>
            <template>
              <CstDropdownItem v-for="(regionItem,index) in regionList" :key="index" @click="handleRegion(regionItem)">
                {{regionItem.name}}
              </CstDropdownItem>
            </template>
          </CstDropdown>
          <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-cs-white">
            <template v-slot:selected>
              {{language.languageName}}
            </template>
            <template v-slot>
              <CstDropdownItem v-for="(languageItem,index) in languageList" :key="index" @click="handleLanguage(languageItem)" >
                {{languageItem.languageName}}
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
        <div v-if="loader && posts.length ===  0" class="p-3">
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
        <template v-else-if="posts.length ===  0">
          <NoResult msg="No Result Found"></NoResult>
        </template>
        <template v-else-if="posts && posts.length > 0">
              <template v-for="(post, index) in posts">
                <div :key="index"  :draggable="true"  class="flex my-2 w-100 p-2 rounded-lg bg-cs-white border hover:bg-cs-foggray hover:shadow-sm" @dragstart="dragstartHandler($event, post,'article')">
                  <div class="image my-2 w-28 min-h-full">
                    <div class="w-full h-full bg-cover bg-no-repeat bg-center rounded-md"
                         :style="{ 'background-image': 'url(' + backgroundImageURL(post._source) + ')' }">
                      <img  class="max-w-full max-h-full opacity-0"  :src="backgroundImageURL(post._source)" alt="">
                    </div>
                  </div>
                  <div class="content w-full px-2">
                    <div class="font-bold text-sm">{{ limitTextLength(post._source.title, 65) }}</div>
                    <div class="my-1 font-normal text-xs text-gray-800">{{trimURL(post._source.domain_url) }}</div>
                    <div class="my-1 font-normal text-xs text-gray-800">{{ $filters.relative(post._source.post_date) }}</div>
                    <hr>
                    <div class="flex justify-between">
                      <span v-floatingtip.hover.top="{ content: 'Engagement',distance: 10 }" class="font-bold flex items-center px-1 text-sm"><i class="icon-engagement-ico mx-1"></i>{{intToString(getTopicPreviewTotalShares(post._source))}}</span>
                      <span v-floatingtip.hover.top="{ content: 'Sentiment',distance: 10 }" class="font-bold flex items-center px-1 text-base"><i aria-hidden="true" :class="getTopicPreviewSentiment(post)"></i></span>

                      <span class="text-cs-primary cursor-pointer" @click="handleArticlePreview({ id: post._source.id, index: index })">Preview <i class="fas fa-arrow-right"></i></span>
                    </div>
                  </div>
                </div>
              </template>
              <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
                <template slot="no-results">
                  <NoResult msg="No More Result Found"></NoResult>
                </template>
          </InfiniteLoading>
        </template>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstInputFields from '@ui/Input/CstInputFields'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
import {removeHtmlTags} from "@common/lib/helper";
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
// import FilterDate from '@src/modules/discovery/components/filters/FilterDate.vue'
export default {
  name: "Article",
  components: {
    NoResult,
    InfiniteLoading,
    CstInputFields,
    CstDropdown,
    CstDropdownItem,
    DateRangePicker,
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sortType: {
      type: String,
      default: '',
    },
    region: {
      type: Object,
      default() {
        return {}
      }
    },
    regionList: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    languageList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data(){
    return {
      dateOptions: {
        defaultValue: null,
        format: 'YYYY-MM-DD',
        disabledDate:(date)=>{
            // return date > new Date(new Date().setHours(0, 0, 0, 0));
            return date > moment().toDate()
          },
        shortcuts: [
          {
            text: 'Last 24 hours',
            onClick() {
               return [moment.utc().subtract(24, 'hours').toDate(), moment.utc().toDate()]
            },
          },
          {
            text: 'Last 48 hours',
            onClick() {
              return [moment.utc().subtract(48, 'hours').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'Last 7 Days',
            onClick() {
             return [moment.utc().subtract(7, 'days').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'Last 30 Days',
            onClick() {
              return [moment.utc().subtract(30, 'days').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'Last 3 Months',
            onClick() {
              return [moment.utc().subtract(90,'days').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'Last 6 Months',
            onClick() {
              return [moment.utc().subtract(180,'days').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'Last 1 Year',
            onClick() {
              return [moment.utc().subtract(360,'days').toDate(), moment.utc().toDate()];
            },
          },
          {
            text: 'All Time',
            onClick() {
              return [moment.utc().subtract(5, 'years').toDate(), moment.utc().toDate()];
            },
          },
        ],
      }
    }
  },
  methods:{
    dragstartHandler(e, post){
      e.dataTransfer.setData('assistantData', JSON.stringify({
        id: e.target.id,
        type: 'article',
        heading: post._source.title,
        media: this.backgroundImageURL(post._source),
        p: this.truncateString(
            removeHtmlTags(post._source.description),
            400
        ),
        link: post._source.url
      }))
    },
    handleDateChange(date){
      console.debug('date', date);
      this.$emit('handle-change','date', date)
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleSortType(value) {
      this.$emit('handle-change','sort', value)
    },
    handleRegion (value) {
      this.$emit('handle-change','region', value)
    },
    handleLanguage (value) {
      this.$emit('handle-change','language', value)
    },
    handleArticlePreview(data){
      this.$emit('handle-change','preview',data)
    },
    getTopicPreviewTotalShares (post) {
      return (
          (post.facebook_shares || 0) +
          (post.pins || 0) +
          (post.twitter_shares || 0) +
          (post.reddit || 0)
      )
    },
    getTopicPreviewSentiment (post) {
      if (
          post._source.sentiment &&
          post._source.sentiment.length > 0
      ) {
        const sentimentLabel = post._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'text-green-400 far fa-smile-o'
          case 'negative':
            return 'text-red-400 far fa-meh-o'
          case 'neutral':
            return 'text-orange-400 far fa-meh'
          default:
            return 'text-orange-400 far fa-frown-o'
        }
      }
      return 'text-orange-400 far fa-frown-o'
    },
  }
}
</script>

<style lang="scss">
.datepicker_input {
  width: 100%;

  .datepicker_content {
    font-size: 13px;
    padding: 3px 4px;
    border-radius: 2px;
  }
  .calendars{
    z-index: 9999;
    position: relative;
  }

}
</style>