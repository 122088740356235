<template>
  <div class="topics-filters-pane__container__left_section">
    <div class="topics-filters-pane__container__left_section__tabs">
      <!--<a v-tooltip.top-center="'Web'"
         @click.prevent="changeToWebChannel()"
         :class="{active:$route.name === 'discover_topics_curated_web_content' || $route.name === 'discover_topics_custom_web'}"
         href="javascript:;"
      >
        <i class="icon-Globe_icon"></i>
        <span>Web</span>
      </a>
      <a v-tooltip.top-center="'Youtube'"
         href="javascript:;"
         @click.prevent="changeContentType('youtube')"
         :class="{'disable_click': getTopicDisabled, active:getTopicContentType === 'youtube'}"
         v-if="!getTrendingStories">
        <i class="icon-Youtube_icon"></i>
        <span>Youtube</span>
      </a>
      <a v-tooltip.top-center="'Twitter'"
         href="javascript:;"
         @click.prevent="changeContentType('twitter')"
         v-if="!getTrendingStories"
         :class="{'disable_click': getTopicDisabled, active:getTopicContentType === 'twitter'}">
        <i class="icon-twitter_icon"></i>
        <span>Twitter</span>
      </a>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    module: {}
  },
  computed: {
    ...mapGetters([
      'getTopicDisabled',
      'getTopicContentType',
      'getTopicType',
      'getTrendingStories'
    ])
  },
  methods: {
    changeToWebChannel () {
      if (this.module === 'curated') {
        if (this.$route.query.topic) {
          this.$router.push({
            name: 'discover_topics_curated_web_content',
            query: { topic: this.$route.query.topic }
          })
        } else {
          this.$router.push({
            name: 'discover_topics_curated_web_content',
            params: { topic_id: this.$route.params.topic_id }
          })
        }
      }
    }
  }
}
</script>
