<template>
  <div class="">
    <!--Fan By Gender chart-->

    <div class="chart_area padding-margin d-flex">
      <div class="col-7">
        <highcharts
          :options="fanByGender()"
          ref="fan_by_gender_chart"
        ></highcharts>
      </div>
      <div class="col-5 content_area align-items-center">
        <ul
          class="solidgauge_chart_value_by_gender highlight_age align-items-center justify-content-center"
        >
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {
  INSTAGRAM_ANALYTICS_FANS,
  INSTAGRAM_ANALYTICS_GENDER
} from '@common/constants/messages'
import * as _ from 'underscore'
import {
  pieChart,
  piePlotOptions,
  pieTitle,
  pieTooltip,
  splineCredits
} from '../../common/helper'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    fans: {
      default: {}
    }
  },
  data () {
    return {
      messages: {
        fans: INSTAGRAM_ANALYTICS_FANS,
        gender: INSTAGRAM_ANALYTICS_GENDER
      },
      percentage: []
    }
  },

  methods: {
    getValue (value) {
      return _.find(this.fans, function (o) {
        return o.key === value
      })
    },
    fanByGender () {
      console.log(this.fans)
      let total = 0
      for (const fan in this.fans) {
        console.log(fan)
        console.log(fan)
        total += this.fans[fan].event_value.value
      }
      const data = []
      this.percentage.male = parseInt(
        (this.getValue('m').event_value.value / total) * 100
      )
      this.percentage.female = parseInt(
        (this.getValue('f').event_value.value / total) * 100
      )
      this.percentage.non = parseInt(
        (this.getValue('u').event_value.value / total) * 100
      )
      data.push(['Male', this.percentage.male])
      data.push(['Female', this.percentage.female])
      data.push(['Non-binary/Unspecified', this.percentage.non])

      const tooltip = pieTooltip()
      tooltip.pointFormat =
        '<p class="engagement_point"><span><b>{point.name}:</b> {point.y} %</span></p>'
      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        chart: pieChart('.solidgauge_chart_value_by_gender', true),
        plotOptions: piePlotOptions(),
        colors: ['#64b4f6', '#f48fb1', '#ffcc80'],
        tooltip: {
          headerFormat: '',
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            'Percentage: <b>{point.y} %</b><br/>'
        },
        title: pieTitle(),
        series: [
          {
            id: '.solidgauge_chart_value_by_gender',
            name: 'Gender Percentage',
            size: '100%',
            innerSize: '60%',
            data: data
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.padding-margin {
  margin: 6.7rem 0rem;
}
.highlight_age .value {
  font-weight: 500;
  margin: auto 0 auto auto;
}
</style>
