import { mapGetters, mapActions } from 'vuex'
import router from '@src/router'
import { authenticationTypes } from '@state/mutation-types'
const commonNetworkMixin = {
  created() {},
  computed: {
    ...mapGetters(['getJWTToken']),
  },
  methods: {
    ...mapActions([]),
    getRequest(URL, successFunc, errorFunc) {
      const newToken = this.getJWTToken
      // let newToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODE5MFwvYXV0aFwvbG9naW5JZFwvNWI2ZTBiNTVlYmFlMDY3OGMzNjExM2QzIiwiaWF0IjoxNTM4MDUyNTE5LCJleHAiOjE1MzgwNTYxMTksIm5iZiI6MTUzODA1MjUxOSwianRpIjoiTDE4R3JVRUVrSkxwRDF0aSIsInN1YiI6IjViNmUwYjU1ZWJhZTA2NzhjMzYxMTNkMyIsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.MFuY8snubt3Q34TLcMem0ynwmexHbanzDhmf0zmxsfs"
      this.$http
        .get(URL, { headers: { Authorization: 'Bearer ' + newToken } })
        .then((response) => {
          successFunc(response)
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status === 401) {
            this.$store.commit(authenticationTypes.SET_JWT_TOKEN, null)
            console.log(window.location.href)
            if (!window.location.href.includes('login')) {
              router.push('/login')
            }
          }
          errorFunc(error)
        })
    },

    postRequest(URL, parameters, successFunc, errorFunc) {
      console.debug('Method::postRequest', this.getJWTToken)
      this.$http
        .post(URL, parameters, {
          headers: { Authorization: 'Bearer ' + this.getJWTToken },
        })
        .then((response) => {
          successFunc(response)
        })
        .catch((error) => {
          console.log(error)
          if (URL.includes('isAuthenticated')) {
            if (!error.status) {
              if (!window.location.href.includes('login')) {
                router.push('/login')
              }
            }
          }
          console.log('error message', error)
          if (error && error.response && error.response.status === 401) {
            this.$store.commit(authenticationTypes.SET_JWT_TOKEN, null)
            console.log(window.location.href)
            if (!window.location.href.includes('login')) {
              router.push('/login')
            }
          }
          errorFunc(error)
        })
    },
  },
}

export { commonNetworkMixin }
