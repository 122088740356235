<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import analyticsUtilsMixin from '../common/analyticsUtilsMixin'

export default {
  components: {},
  mixins: {
    analyticsUtilsMixin
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions([
      'fetchDownloadReportsService',
      'removeDownloadReportService'
    ]),
    ...mapMutations(['SET_SCHEDULED_REPORT_ITEM'])
  },
  async mounted () {
    const res = await this.fetchDownloadReportsService()
  },
  computed: {
    ...mapGetters([
      'getDownloadReports',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinCompanyPages',
      'getPinterestBoards'
    ])
  },
  watch: {}
}
</script>

<template>
  <div class="analytic_common_component custom_report_main">
    <div class="component_inner">
      <!-- <div class="section_top_bar">
                 <div class="section_top_bar_inner  max_container_1800 d-flex height  align-items-center">
                     <div class="left basic_form">
                         <div class="input_field mt-0">
                             <input v-model="getScheduledReports.query" type="text" placeholder="Search report...">
                         </div>
                     </div>
                     &lt;!&ndash;<div class="right ml-auto">&ndash;&gt;
                     &lt;!&ndash;</div>&ndash;&gt;
                 </div>
             </div>-->

      <div class="report_list clear">
        <h2 class="heading">Download Reports</h2>
        <div class="clearfix"></div>

        <div class="card_content mt-3">
          <!--top_post_table-->
          <div class="social_table">
            <div class="table_wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 35%">Accounts</th>
                    <th style="width: 200px" class="text-center">Type</th>
                    <th style="width: 124px" class="text-center">Format</th>
                    <th style="width: 300px" class="text-center">Period</th>
                    <th style="width: 180px" class="text-center"
                      >Creation date</th
                    >
                    <th style="width: 120px" class="text-center">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <template v-if="getDownloadReports.list.length > 0">
                    <tr v-for="(item, key) in getDownloadReports.list">
                      <td>
                        <div class="">
                          <template v-if="item.type === 'group'">
                            <template v-for="account in item.accounts">
                              <div
                                class="d-inline-block mr-3"
                                v-tooltip="{
                                  content: channelTooltipHTML(
                                    account.account_type,
                                    account
                                  ),
                                  classes: 'team_tooltip',
                                }"
                              >
                                <individual-channel-tooltip
                                  :account="account"
                                  :type="account.account_type"
                                ></individual-channel-tooltip>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <template
                              v-if="channelItem(account, item.type)"
                              v-for="account in item.accounts"
                            >
                              <div
                                class="d-inline-block mr-3"
                                v-tooltip="{
                                  content: channelTooltipHTML(
                                    item.type,
                                    channelItem(account, item.type)
                                  ),
                                  classes: 'team_tooltip',
                                }"
                              >
                                <individual-channel-tooltip
                                  :account="channelItem(account, item.type)"
                                  :type="item.type"
                                ></individual-channel-tooltip>
                              </div>
                            </template>
                          </template>
                        </div>
                      </td>
                      <td class="make-text-center uppercase">
                        {{ item.type }} Report
                      </td>
                      <td class="make-text-center"> PDF </td>
                      <td class="make-text-center">
                        {{ $filters.convertToWeekdaySplit(item.date) }}
                      </td>

                      <td class="make-text-center">
                        {{ $filters.convertToWeekday(item.created_at) }}
                      </td>
                      <td class="">
                        <div class="action_icons text-right">
                          <a :href="item.export_url" target="_blank">
                            <i
                              class="icon_table fa fa-download"
                              v-tooltip="'Download Report'"
                            ></i>
                            <!-- @click="exportDownloadReportService({reportId: item._id, index: key})"-->
                          </a>

                          <i
                            class="icon_table icon-delete-cs"
                            v-tooltip="'Remove Report'"
                            @click="
                              removeDownloadReportService({
                                reportId: item._id,
                                index: key,
                              })
                            "
                          ></i>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr v-for="post in getOrderedPosts">
                                         <td @click.prevent="$root.$emit('linkedin-analytics-preview', post._source)">
                                             <linkedin-post-view :post="post._source"></linkedin-post-view>
                                         </td>

                                         <td class="text-center statistics">{{post._source.total_engagement |
                                             number-to-commas}}
                                         </td>
                                         <td class="text-center statistics">{{post._source.favourites |
                                             number-to-commas}}

                                         </td>
                                         <td class="text-center statistics">{{post._source.comments|
                                             number-to-commas}}
                                         </td>

                                     </tr>-->
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6" class="make-text-center">
                        You have not exported any report yet.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
