var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"social_icons d-flex align-items-center flex-wrap justify-content-center",class:[_vm.className]},[(_vm.blog_selection)?[_vm._l((_vm.integrations),function(integration){return [(
            _vm.blog_selection[integration] && _vm.blog_selection[integration].website
          )?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
              _vm.getBlogURL(integration, _vm.blog_selection[integration].website)
            ),expression:"\n              getBlogURL(integration, blog_selection[integration].website)\n            ",modifiers:{"top-center":true}}],staticClass:"icon_block d-flex align-items-center justify-content-center",class:_vm.configrations[integration].background_classes})]:_vm._e()]})]:_vm._e(),(_vm.secondary)?[_vm._l((_vm.secondary),function(account){return [(account.blog_selection)?[_vm._l((_vm.integrations),function(integration){return [(
                account.blog_selection[integration] &&
                account.blog_selection[integration].website
              )?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
                  _vm.getBlogURL(
                    integration,
                    account.blog_selection[integration].website
                  )
                ),expression:"\n                  getBlogURL(\n                    integration,\n                    account.blog_selection[integration].website\n                  )\n                ",modifiers:{"top-center":true}}],staticClass:"icon_block d-flex align-items-center justify-content-center",class:_vm.configrations[integration].background_classes})]:_vm._e()]})]:_vm._e()]}),_vm._l((_vm.secondary),function(account){return [(account.account_selection)?[_c('channels-tooltip',{attrs:{"accounts":account.account_selection,"channels_class":'d-flex align-items-center justify-content-center',"channels_size":'28px'}})]:_vm._e()]})]:_vm._e(),(_vm.account_selection)?[_c('channels-tooltip',{attrs:{"accounts":_vm.account_selection,"channels_class":'d-flex align-items-center justify-content-center',"channels_size":'28px'}})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }