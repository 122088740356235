var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warning_box_zapier"},[_c('div',{staticClass:"text_container"},[(_vm.no_access_accounts.length > 0)?_c('div',[_c('div',{staticClass:"header"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.channelTooltipMultipleHTML(
                'instagram',
                _vm.no_access_accounts,
              ),
              classes: 'team_tooltip',
            }),expression:"{\n              content: channelTooltipMultipleHTML(\n                'instagram',\n                no_access_accounts,\n              ),\n              classes: 'team_tooltip',\n            }"}],domProps:{"innerHTML":_vm._s(_vm.getDescriptionForPermissionLinkedAccounts())}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }