import { mapActions, mapGetters } from 'vuex'
import { ssoLinksURL } from '@src/config/api-utils'
import {userMaven} from "@src/tracking/userMavenMixin";

export const ssoMixin = {
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['setSSOAuthorizationLinks']),
    fetchSSOLinks() {
      this.$http.get(ssoLinksURL).then(
        (response) => {
          if (response.data.status) {
            this.setSSOAuthorizationLinks(response.data.authorization)
          } else {
            this.setSSOAuthorizationLinks({
              facebook: '',
              twitter: '',
            })
          }
        },
        (response) => {}
      )
    },
    async redirectSSO (link, typeName = 'signed_up') {
      await this.trackUserMaven(typeName)
      window.location.href = link
    },
  },
}
