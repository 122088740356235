export const loaders = {
  SET_FETCH_NEXT_POSTING_LOADER: 'SET_FETCH_AUTOMATION_NEXT_POSTING_LOADER'
}

export const article = {
  SET_AUTOMATION_NEXT_POSTING: 'SET_ARTICLE_AUTOMATION_NEXT_POSTING',
  RESET_ARTICLE_AUTOMATION: 'RESET_ARTICLE_AUTOMATION',
  SET_ARTICLE_AUTOMATION: 'SET_ARTICLE_AUTOMATION',
  SET_SOCIAL_AUTOMATION_ITEMS: 'SET_SOCIAL_AUTOMATION_ITEMS',
  SET_SOCIAL_AUTOMATION_LISTING_PAGINATION:
    'SET_SOCIAL_AUTOMATION_LISTING_PAGINATION',
  SET_BLOG_AUTOMATION_ITEMS: 'SET_BLOG_AUTOMATION_ITEMS',
  SET_BLOG_AUTOMATION_LISTING_PAGINATION:
    'SET_BLOG_AUTOMATION_LISTING_PAGINATION',
  SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER:
    'SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER',
  SET_SELECTED_ARTICLE_AUTOMATIONS: 'SET_SELECTED_ARTICLE_AUTOMATIONS',
  SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS:
    'SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS',
  SET_SOCIAL_AUTOMATION_RETRIEVE_LOADER:
    'SET_SOCIAL_AUTOMATION_RETRIEVE_LOADER',
  SET_BLOG_AUTOMATION_RETRIEVE_LOADER: 'SET_BLOG_AUTOMATION_RETRIEVE_LOADER',
  SET_ARTICLE_AUTOMATION_STORE_LOADER: 'SET_ARTICLE_AUTOMATION_STORE_LOADER',
  SET_ARTICLE_AUTOMATION_VALIDATION_VALUE:
    'SET_ARTICLE_AUTOMATION_VALIDATION_VALUE',
  SET_ARTICLE_AUTOMATION_POST_AGE: 'SET_ARTICLE_AUTOMATION_POST_AGE',
  SET_ARTICLE_AUTOMATION_POST_DATE: 'SET_ARTICLE_AUTOMATION_POST_DATE',
  SET_ARTICLE_AUTOMATION_ENGAGEMENT_TYPE:
    'SET_ARTICLE_AUTOMATION_ENGAGEMENT_TYPE'
}

export const video = {
  SET_AUTOMATION_FOURTH_TAB_STATUS: 'SET_VIDEO_AUTOMATION_FOURTH_TAB_STATUS',
  SET_AUTOMATION_NEXT_POSTING: 'SET_VIDEO_AUTOMATION_NEXT_POSTING',
  SET_AUTOMATION_KEYWORD: 'SET_VIDEO_AUTOMATION_KEYWORD'
}
export const rssTypes = {
  SET_RSS_AUTOMATION_CONTENT_CATEGORY_VALUE:
    'SET_RSS_AUTOMATION_CONTENT_CATEGORY_VALUE',
  SET_RSS_AUTOMATION: 'SET_RSS_AUTOMATION',
  RESET_RSS_AUTOMATION: 'RESET_RSS_AUTOMATION',
  RESET_ALL_RSS_AUTOMATION_STATES: 'RESET_ALL_RSS_AUTOMATION_STATES',
  SET_RSS_AUTOMATION_RETRIEVE_LOADER: 'SET_RSS_AUTOMATION_RETRIEVE_LOADER',
  SET_RSS_AUTOMATION_STORE_LOADER: 'SET_RSS_AUTOMATION_STORE_LOADER',
  SET_RSS_AUTOMATION_LISTING_PAGINATION:
    'SET_RSS_AUTOMATION_LISTING_PAGINATION',
  SET_RSS_AUTOMATION_ITEMS: 'SET_RSS_AUTOMATION_ITEMS',
  SET_SELECTED_RSS_AUTOMATION_ITEMS: 'SET_SELECTED_RSS_AUTOMATION_ITEMS',
  SET_RSS_AUTOMATION_LISTING_SELECTED_ALL:
    'SET_RSS_AUTOMATION_LISTING_SELECTED_ALL',
  SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS: 'SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS',
  ADD_RSS_PULL_HISTORY_ITEMS: 'ADD_RSS_PULL_HISTORY_ITEMS',
  SET_RSS_PULL_HISTORY_ITEMS: 'SET_RSS_PULL_HISTORY_ITEMS',
  SET_RSS_FEED_URL_AUTOMATION: 'SET_RSS_FEED_URL_AUTOMATION'
}

export const recipeScheduleTypes = {
  RESET_AUTOMATION_SCHEDULE_OPTIONS: 'RESET_AUTOMATION_SCHEDULE_OPTIONS'
}
