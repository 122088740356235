export default {
  state: {
    filters: {
      search: '',
      page: 0,
      limit: 20,
      youtube: {
        pagetoken: null,
        sort: {
          name: 'Relevance',
          code: 'Relevance'
        },
        region: {
          name: 'United States',
          code: 'US'
        },
        hd: false
      },
      dailymotion: {
        sort: {
          name: 'Relevance',
          code: 'relevance'
        }
        // region: {
        //   name: 'United States',
        //   code: 'US'
        // }
      }
    },
    videos: [],
    scroll: true,

    content: 'topic',

    loader: {
      search: {
        button: {
          search: false
        },
        content: {
          videoLoader: false,
          infiniteLoader: false
        }
      }
    },

    videoPreview: {
      title: '',
      description: '',
      url: '',
      source: '',
      height: null
    },
    facebookPostID: '',
    facebookPreview: {}
  },
  actions: {
    setVideos ({ commit }, videos) {
      commit('setVideos', videos)
    },
    setVideoScroll ({ commit }, status) {
      commit('setVideoScroll', status)
    },
    setVideoPaginationLoader ({ commit }, status) {
      commit('setVideoPaginationLoader', status)
    },
    setDiscoveryVideoLoader ({ commit }, status) {
      commit('setDiscoveryVideoLoader', status)
    },
    setVideoPageToken ({ commit }, status) {
      commit('setVideoPageToken', status)
    },
    setVideoPage ({ commit }, page) {
      commit('setVideoPage', page)
    },
    setYoutubeSort ({ commit }, sort) {
      commit('setYoutubeSort', sort)
    },
    setDailymotionSort ({ commit }, sort) {
      commit('setDailymotionSort', sort)
    },
    // setDailymotionRegion ({ commit }, region) {
    //     commit('setDailymotionRegion', region)
    // },
    resetVideo ({ commit }) {
      commit('resetVideo')
    },

    setVideoContentType ({ commit }, status) {
      commit('setVideoContentType', status)
    },
    setVideoPreview ({ commit }, preview) {
      commit('setVideoPreview', preview)
    },
    setFacebookPostID ({ commit }, preview) {
      commit('setFacebookPostID', preview)
    },
    setFacebookPreview ({ commit }, preview) {
      commit('setFacebookPreview', preview)
    }
  },
  mutations: {
    RESET_VIDEO (state) {
      state.loader.search.content.videoLoader = true
      state.filters.page = 0
      state.filters.youtube.pagetoken = null
    },
    setVideos (state, videos) {
      state.videos = videos
    },
    setVideoScroll (state, status) {
      state.scroll = status
    },

    setVideoPaginationLoader (state, status) {
      state.loader.search.content.infiniteLoader = status
    },
    setDiscoveryVideoLoader (state, status) {
      state.loader.search.content.videoLoader = status
    },
    setVideoPageToken (state, token) {
      state.filters.youtube.pagetoken = token
    },
    setVideoPage (state, page) {
      state.filters.page = page
    },
    setYoutubeSort (state, sort) {
      state.filters.youtube.sort = sort
    },
    setDailymotionSort (state, sort) {
      state.filters.dailymotion.sort = sort
    },
    // setDailymotionRegion (state, region) {
    //   state.filters.dailymotion.region = region
    // },
    setVideoContentType (state, type) {
      state.content = type
    },
    resetVideo (state) {
      state.filters = {
        search: '',
        page: 0,
        limit: 20,
        youtube: {
          pagetoken: null,
          sort: {
            name: 'Relevance',
            code: 'Relevance'
          },
          region: {
            name: 'United States',
            code: 'US'
          },
          hd: false
        },
        dailymotion: {
          sort: {
            name: 'Relevance',
            code: 'relevance'
          }
          // region: {
          //   name: 'United States',
          //   code: 'US'
          // }
        }
      }
      state.videos = []
      state.scroll = true

      state.content = 'topic'

      state.loader = {
        search: {
          button: {
            search: false
          },
          content: {
            videoLoader: false,
            infiniteLoader: false
          }
        }
      }

      state.videoPreview = {
        title: '',
        description: '',
        url: '',
        source: '',
        height: null
      }
    },
    setVideoPreview (state, preview) {
      state.videoPreview = preview
    },
    setFacebookPostID (state, preview) {
      state.facebookPostID = preview
    },
    setFacebookPreview (state, preview) {
      state.facebookPreview = preview
    }
  },
  getters: {
    getVideoFilter: (state) => {
      return state.filters
    },
    getVideoPage: (state) => {
      return state.filters.page
    },
    getVideos: (state) => {
      return state.videos
    },
    getVideoPaginationLoader: (state) => {
      return state.loader.search.content.infiniteLoader
    },
    getDiscoveryVideoLoader: (state) => {
      return state.loader.search.content.videoLoader
    },
    getVideoScroll: (state) => {
      return state.scroll
    },
    getYoutubeSort: (state) => {
      return state.filters.youtube.sort
    },
    getDailymotionSort: (state) => {
      return state.filters.dailymotion.sort
    },

    // getDailymotionRegion: state => {
    //     return state.filters.dailymotion.region
    // },

    getVideoContentType: (state) => {
      return state.content
    },
    getVideoPreview: (state) => {
      return state.videoPreview
    },
    getFacebookPostID: (state) => {
      return state.facebookPostID
    },
    getFacebookPreview: (state) => {
      return state.facebookPreview
    }
  }
}
