import { mapGetters, mapActions } from 'vuex'

const actionConfirmationMixin = {
  computed: {
    ...mapGetters([]),
  },

  methods: {
    ...mapActions(['setConfirmActionData', 'setConfirmActionStage']),

    tagDelete() {
      this.$bvModal.show('tagDeleteConfirm')
    },
    confirmAction(model_id, item) {
      console.debug('Method::confirmAction', model_id)
      $('#' + model_id).modal('show')
      if (item) {
        this.setConfirmActionData(item)
      }
    },

    closeConfirmAction(model_id) {
      const stateObject = this
      setTimeout(function () {
        stateObject.setConfirmActionData('')

        setTimeout(function () {
          stateObject.setConfirmActionStage('')
        }, 1000)

        $('#' + model_id).modal('hide')
      }, 1000)
    },
  },
}
export { actionConfirmationMixin }
