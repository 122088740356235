var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeSearchHistoryDropdown),expression:"closeSearchHistoryDropdown"}],staticClass:"dropdown-search-suggestions article-search-bar-pane__search__input__history"},[(_vm.getSearchKeywords.length > 0)?[_c('div',{staticClass:"search_dropdown_inner",class:{ 'pb-1': !_vm.getShowOperatorsSuggestions }},[_c('div',{staticClass:"article-search-bar-pane__search__input__history__recent_searches"},[_c('div',{staticClass:"text"},[_vm._v(" Recent Searches ")]),_c('div',{staticClass:"clear_all_action",on:{"click":function($event){return _vm.clearAllSearchHistoryAction()}}},[_vm._v(" Clear All ")])]),(_vm.getSearchKeywords.length > 0)?_c('ul',{staticClass:"search_history_item_element article-search-bar-pane__search__input__history__item"},[_vm._l((_vm.getSearchKeywords),function(value,index){return (index <= 4)?[_c('li',{staticClass:"item",class:{
              active:
                _vm.search_key_input.selector === 'search_history' &&
                value === _vm.search_key_input.key,
            }},[_c('span',{staticClass:"li_text",on:{"click":function($event){_vm.routeSearchQueryFromSuggestion(value.toLowerCase())}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(_vm._s(value)+" ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove'),expression:"'Remove'"}],staticClass:"li_action",on:{"click":function($event){return _vm.clearIndividualSearchHistoryAction(value, index)}}},[_c('i',{staticClass:"far fa-times"})])])]:_vm._e()})],2):_vm._e(),(_vm.getShowOperatorsSuggestions)?_c('div',{staticClass:"article-search-bar-pane__search__input__history__operators"},[_c('h5',{on:{"click":function($event){_vm.show_search_operators = !_vm.show_search_operators}}},[_c('i',{staticClass:"fas fa-caret-right",class:{ 'carret-rotate-animation': _vm.show_search_operators }}),_vm._v("Search Operators")]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show_search_operators)?_c('article-search-operators-list'):_vm._e()],1)],1):_vm._e(),(
          _vm.$route.meta.is_active && _vm.$route.meta.is_active === 'twitter_tweets'
        )?_c('div',{staticClass:"article-search-bar-pane__search__input__history__operators"},[_c('h5',{on:{"click":function($event){_vm.show_twitter_search_operators = !_vm.show_twitter_search_operators}}},[_c('i',{staticClass:"fas fa-caret-right",class:{
              'carret-rotate-animation': _vm.show_twitter_search_operators,
            }}),_vm._v("Search Operators")]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show_twitter_search_operators)?_c('twitter-search-operators-list'):_vm._e()],1)],1):_vm._e()])]:_vm._e(),(_vm.getSearchKeywords.length === 0 && _vm.getShowOperatorsSuggestions)?[_c('article-search-operators-list')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }