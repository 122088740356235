<template>
  <div class="cs-hr-scroll w-full px-1 overflow-y-auto absolute h-full pb-20">
    <div class="flex items-center sticky top-0 bg-white">
      <span
        class="text-cs-primary cursor-pointer rounded-md text-lg my-2 px-2.5 py-1 mx-px hover:bg-cs-secondary"
        @click="returnBack"
        ><i class="fas fa-arrow-left"></i>
      </span>
      <span class="text-black text-base font-semibold ml-auto mx-2"
        >Article Preview</span
      >
    </div>
    <hr class="m-0 hr-color" />
    <!--         <ul class="flex w-100 border rounded-md py-1 px-1 justify-end bg-white">-->
    <!--           <template v-if="sectionName !== 'composerSection'">-->
    <!--             <li class="h-8 w-8 flex justify-center items-center cursor-pointer text-black-700 rounded-md text-lg mx-px hover:bg-cs-secondary">-->
    <!--               <i class="icon-Favorite_icon "></i>-->
    <!--             </li>-->
    <!--             <li class="h-8 w-8 flex justify-center items-center cursor-pointer text-black-700 rounded-md text-lg mx-px hover:bg-cs-secondary">-->
    <!--               <i class="icon-pocket "></i>-->
    <!--             </li>-->
    <!--             <li class="h-8 w-8 flex justify-center items-center cursor-pointer text-black-700 rounded-md text-lg mx-px hover:bg-cs-secondary">-->
    <!--               <i class="icon-Archive_icon "></i>-->
    <!--             </li>-->
    <!--             <div class="w-px m-1 bg-gray-200"> </div>-->
    <!--           </template>-->
    <!--           <li class="h-8 w-8 flex justify-center items-center cursor-pointer text-black-700 rounded-md text-lg mx-px hover:bg-cs-secondary">-->
    <!--             <i class="icon-link_icon"></i>-->
    <!--           </li>-->
    <!--           <template v-if="sectionName === 'composerSection'">-->
    <!--             <li class="h-8 w-8 flex justify-center items-center cursor-pointer text-black-700 rounded-md text-lg mx-px bg-cs-primary hover:bg-cs-secondary">-->
    <!--               Add full-->
    <!--             </li>-->
    <!--           </template>-->
    <!--         </ul>-->

    <div v-if="previewLoader" class="p-3">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else>
      <div class="content w-100 py-2 px-2 bg-white">
        <div class="flex w-100">
          <p class="text-sm text-gray-800">
            {{ previewPost._source.domain }} -
            {{ $filters.relative(previewPost._source.post_date) }} on
            {{ $filters.dateWithYear(previewPost._source.post_date) }}
          </p>
        </div>
        <div class="flex w-100 justify-end mt-2">
          <template v-for="(topic, index) in previewPost._source.topics">
            <div v-if="topic && topic.label" :key="index">
              <template
                v-for="(finalizedTopic, key) in checkIfMultipleTopics(
                  topic.label
                )"
              >
                <span
                  v-if="topic && topic.label"
                  :key="key"
                  class="border mx-0.5 p-1 text-gray text-sm rounded-md"
                >
                  {{ finalizedTopic }}
                </span>
              </template>
            </div>
          </template>
        </div>
        <div class="flex w-100 mt-2">
          <h2 class="text-lg font-bold text-black-900">
            {{ previewPost._source.title }}
          </h2>
        </div>
        <div class="grid grid-cols-2 w-100 mt-2">
          <!--TODO: need to redesign this Shared Count component also -->

          <div class="text-gray text-sm text-center flex">
            <!--                     <SharedCountTooltip-->
            <!--                         :post="{ _source: previewPost._source }" class="text-gray-800 text-base"-->
            <!--                     />-->
            <span class="px-0.5 text-gray-800 text-base">Engagement</span>
            <span class="font-bold px-1 text-base">{{
              intToString(getTopicPreviewTotalShares())
            }}</span>
          </div>
          <div class="text-gray text-sm text-center">
            <div v-if="getTopicPreviewSentiment()">
              <span class="px-0.5 text-gray-800 text-base">Sentiment</span>
              <span class="font-bold px-0.5 text-base"
                ><i aria-hidden="true" :class="getTopicPreviewSentiment()"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="w-100">
          <div
            class="w-100 h-96 bg-contain bg-center bg-no-repeat rounded-md mt-2 mb-3"
            :style="{
              'background-image':
                'url(' +
                backgroundArticlePreviewImageURL(previewPost._source, 800) +
                ')',
            }"
          >
          </div>
          <div
            v-if="previewPost._source.description"
            class="text-sm text-justify"
            v-html="previewPost._source.description"
          ></div>
          <div
            v-else-if="previewPost._source.short_description"
            class="text-sm text-justify"
            v-html="previewPost._source.short_description"
          ></div>
          <div v-else class=""
            ><p>No description available for this article.</p></div
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  components: {},
  props: {
    previewPost: {
      type: Object,
      required: true,
    },
    previewLoader: {
      type: Boolean,
      required: true,
    },
    sectionName: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    returnBack() {
      this.$emit('handle-preview', 'close', false)
    },
    getTopicPreviewTotalShares() {
      const post = this.previewPost._source
      return (
        (post.facebook_shares || 0) +
        (post.pins || 0) +
        (post.twitter_shares || 0) +
        (post.reddit || 0)
      )
    },
    getTopicPreviewSentiment() {
      if (
        this.previewPost._source.sentiment &&
        this.previewPost._source.sentiment.length > 0
      ) {
        const sentimentLabel = this.previewPost._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'text-green-400 far fa-smile-o'
          case 'negative':
            return 'text-red-400 far fa-meh-o'
          case 'neutral':
            return 'text-orange-400 far fa-meh'
          default:
            return 'text-orange-400 far fa-frown-o'
        }
      }
      return 'text-orange-400 far fa-frown-o'
    },
  },
}
</script>

<style scoped></style>
