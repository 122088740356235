<script>
import { mapGetters, mapActions } from 'vuex'
import ListingFilters from './ListingFilters'
import SelectedPlatforms from '../../SelectedPlatforms'
import RemoveCampaign from '../dialogs/RemoveCampaign'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'

export default {
  components: {
    ConfirmBox,
    ListingFilters,
    SelectedPlatforms,
    RemoveCampaign
  },

  data () {
    return {
      youtubeKey: ''
    }
  },

  created () {
    this.fetchYoutubeApiKey()
    this.initializeVideoSocialAutomationListingSection()
  },
  mounted () {
    document.addEventListener('keyup', this.keyPressListener)
  },
  destroyed () {
    document.removeEventListener('keyup', this.keyPressListener)
  },

  computed: {
    ...mapGetters([
      'getVideoSocialAutomationListing',
      'getAutomationLoaders',
      'getWorkspaces',
      'getVideoAutomationListing',
      'getProfile',
      'getActiveWorkspace'
    ])
  },
  methods: {
    ...mapActions(['fetchWorkspaceYoutubeKey']),
    removeVideoAutomationPayload (automation, index) {
      automation.removalIndex = index
      return automation
    },
    keyPressListener (event) {
      console.debug('Method::keyPressListener', event.keyCode)
      if (this.$route.name === 'videoSocialAutomationListing') {
        if (event.keyCode === 27) {
          this.$router.push({
            name: 'automation',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug }
          })
          return false
        }
      }
    },
    async fetchYoutubeApiKey () {
      const response = await this.fetchWorkspaceYoutubeKey({
        workspace_id: this.getActiveWorkspace._id
      })
      if (response.data && response.data.key) {
        this.youtubeKey = response.data.key
      } else this.youtubeKey = ''
    }
  },

  watch: {}
}
</script>
<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <remove-campaign></remove-campaign>
      <confirm-box
        id="videoAutomationBulkOperation"
        confirmText="Yes"
        cancelText="No"
        :callBackAction="videoAutomationBulkOperation"
      >
        <template slot="header">
          <h2>Perform bulk operation</h2>
        </template>
        <template slot="footer">
          <p class="text-center">This action cannot be undone.</p>
        </template>
      </confirm-box>

      <div class="top_content_listing">
        <div class="d-flex align-items-center">
          <div class="col-md-4 text-left nopad-l">
            <router-link
              class="btn btn-studio-theme-grey-space"
              :to="{
                name: 'automation',
                params: { workspace: getWorkspaces.activeWorkspace.slug },
              }"
            >
              <i
                style="color: #ffffff"
                class="cs-angle-left icon_left arrow_right_active"
              ></i>
              <span>Back</span>
            </router-link>
          </div>

          <h2 class="col-md-4 text-center">View Existing Campaigns</h2>
          <p class="col-md-4 text-right nopad-r"
            >Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            (
            <clock
              timeFormat="hh:mm:ss A"
              :timezone="getWorkspaces.activeWorkspace.timezone"
            ></clock>
            )
          </p>
        </div>

        <img
          src="../../../../../assets/img/automation/video_social.svg"
          alt=""
        />
        <h3>Videos to Social Media</h3>
        <p
          >Share videos on your social media profiles, pages and groups with
          smart rules and filters.</p
        >
      </div>
      <div class="automation_listing">
        <div class="page_inner">
          <listing-filters type="Social"></listing-filters>

          <div class="table_box">
            <table class="table-sm">
              <thead>
                <tr>
                  <th class="text-center">
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label
                          for="select_all"
                          :class="{
                            disabled:
                              getVideoSocialAutomationListing.items.length < 1,
                          }"
                        >
                          <input
                            id="select_all"
                            type="checkbox"
                            :disabled="
                              getVideoSocialAutomationListing.items.length < 1
                            "
                            v-model="getVideoAutomationListing.select_all"
                            @change="changeVideoAutomationSelectAllStatus()"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </th>
                  <th class="text-center">Status</th>
                  <th>Name</th>
                  <th class="text-center" style="width: 200px"
                    >Platforms Selected</th
                  >
                  <th class="text-center"
                    >Next Refill Time
                    <i
                      class="fas fa-info-circle"
                      style="margin-left: 5px"
                      v-tooltip.top-center="
                        'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'
                      "
                    ></i
                  ></th>

                  <th class="text-center">Published Posts</th>
                  <th style="text-align: center; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    getAutomationLoaders.fetchVideoSocialAutomations ||
                    getAutomationLoaders.fetchVideoAutomation
                  "
                >
                  <td colspan="7" class="text-center">
                    <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
                  </td>
                </tr>

                <template
                  v-else-if="getVideoSocialAutomationListing.items.length"
                >
                  <tr
                    :class="{ pause_automation: !automation.status }"
                    v-for="(
                      automation, index
                    ) in getVideoSocialAutomationListing.items"
                    :key="index"
                  >
                    <td class="text-center">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label :for="index">
                            <input
                              :id="index"
                              type="checkbox"
                              :checked="
                                getVideoAutomationListing.selected.includes(
                                  automation._id
                                )
                              "
                              :disabled="automation.lock !== 0"
                              @change="
                                changeAutomationSelection(
                                  getVideoAutomationListing.selected,
                                  automation._id,
                                  'Video'
                                )
                              "
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td class="text-center d-flex justify-content">
                      <label
                        class="switch-radio"
                        v-tooltip.top-center="'Toggle ON/OFF'"
                      >
                        <input
                          type="checkbox"
                          :disabled="automation.lock !== 0"
                          id="automation_toggle"
                          v-model="automation.status"
                          @change="
                            changeVideoAutomationStatus(automation, youtubeKey)
                          "
                        />
                        <div
                          class="slider round"
                          :class="{ disabled: automation.lock !== 0 }"
                        ></div>
                      </label>

                      <v-popover
                        placement="top-center"
                        :container="'#errortooltip-' + automation._id"
                        trigger="hover"
                        popoverClass="tooltip-danger"
                      >
                        <i
                          :id="'errortooltip-' + automation._id"
                          v-if="
                            !automation.lock &&
                            (automation.state === 'limit_exceeded' ||
                              automation.state === 'partial_content_planned' ||
                              automation.state === 'empty_token' ||
                              automation.state === 'invalid_token')
                          "
                          class="fas fa-exclamation-triangle ml-2"
                          style="
                            color: #f43838;
                            font-size: 0.84rem;
                            cursor: pointer;
                          "
                        ></i>

                        <template
                          slot="popover"
                          v-if="automation.state === 'empty_token'"
                        >
                          You do not have any YouTube Data API Key, please add
                          one by going to Settings > Other Integrations. You can
                          <router-link
                            :to="{
                              name: 'integrations',
                              params: {
                                workspace: getWorkspaces.activeWorkspace.slug,
                              },
                              hash: '#youtube',
                            }"
                            >Click here</router-link
                          >
                          to add your API key.
                        </template>
                        <template
                          slot="popover"
                          v-else-if="automation.state === 'limit_exceeded'"
                        >
                          You have reached YouTube API quota limit for today,
                          your campaign will be running again at your next
                          refill time. Alternatively, you can restart your
                          campaign if you want to schedule new posts.
                        </template>
                        <template
                          slot="popover"
                          v-else-if="
                            automation.state === 'partial_content_planned'
                          "
                        >
                          Your posts have been scheduled but we have reached
                          YouTube Quota for your campaign. You may see less no.
                          of scheduled posts for your campaign due to this.
                        </template>
                        <template
                          slot="popover"
                          v-else-if="automation.state === 'invalid_token'"
                        >
                          Your YouTube Data API Key is invalid. Please make sure
                          to add a correct YouTube API key.
                        </template>
                      </v-popover>
                    </td>
                    <td>{{ automation.name }}</td>
                    <selected-platforms
                      :account_selection="automation.account_selection"
                    ></selected-platforms>
                    <td class="text-center"
                      >{{ automationNextRefill(automation, 'VideoAutomation') }}
                    </td>
                    <td class="text-center" v-if="automation.posts">{{
                      automation.posts
                    }}</td>
                    <td class="text-center" v-else>0</td>
                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <clip-loader
                          class="inline_loader"
                          v-if="automation.lock"
                          color="#4165ed"
                          :size="'16px'"
                        ></clip-loader>
                        <template>
                          <i
                            v-tooltip.top-center="'Restart Automation'"
                            v-if="
                              automation.status &&
                              !automation.lock &&
                              (automation.state === 'limit_exceeded' ||
                                automation.state ===
                                  'partial_content_planned' ||
                                automation.state === 'invalid_token')
                            "
                            class="action_icon sm cs-refresh"
                            @click="
                              restartVideoAutomation(
                                automation._id,
                                automation.name
                              )
                            "
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'View Posts'"
                            class="action_icon sm icon-Listview_icon"
                            @click="
                              viewAutomationPosts(
                                automation._id,
                                'video_automation'
                              )
                            "
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Edit'"
                            v-if="
                              automation.policy && automation.policy.can_edit
                            "
                            class="action_icon sm icon-edit-cs"
                            @click="fetchVideoAutomation(automation._id)"
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Duplicate'"
                            v-if="
                              automation.policy && automation.policy.can_edit
                            "
                            class="action_icon sm fa fa-files-o"
                            @click="fetchVideoAutomation(automation._id, true)"
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Remove'"
                            v-if="
                              automation.policy && automation.policy.can_remove
                            "
                            class="action_icon sm icon-delete-cs"
                            @click.prevent="
                              confirmAction(
                                'removeVideoAutomation',
                                removeVideoAutomationPayload(automation, index)
                              )
                            "
                          >
                          </i>
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>

                <tr v-else>
                  <td
                    colspan="12"
                    class="text-center"
                    v-if="getVideoSocialAutomationListing.search"
                  >
                    No results found for your search query, please try again.
                  </td>
                  <td colspan="12" class="text-center" v-else
                    >You have not created any automation yet.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="field_group text-center"
            v-if="
              getVideoSocialAutomationListing.nextPage ||
              getVideoSocialAutomationListing.previousPage
            "
          >
            <button
              class="btn btn-studio-theme-transparent-grey mr-1"
              :disabled="!getVideoSocialAutomationListing.previousPage"
              @click="processVideoSocialAutomationPagination('previous')"
            >
              <i class="icon_left cs-angle-left"></i>
              <span>Prev</span>
            </button>

            <button
              class="btn btn-studio-theme-space"
              :disabled="!getVideoSocialAutomationListing.nextPage"
              @click="processVideoSocialAutomationPagination('next')"
            >
              <span>Next</span>
              <i class="icon_right arrow_right_active cs-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
