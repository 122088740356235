import {
  fetchUTMUrl,
  saveUTMUrl,
  removeUtmUrl,
  editUtmStatusUrl,
  changeAttachUtmStatusUrl,
} from '@src/modules/setting/config/api-utils'
import {
  UTM_SAVE_SUCCESS,
  UTM_INVALID_NAME,
  UTM_INVALID_SOURCE,
  UNKNOWN_ERROR,
} from '@common/constants/messages'
import { mapGetters, mapActions } from 'vuex'
import { fetchLongLinkUrl } from '@src/modules/publish/config/api-utils'
import {
  commonTypes,
  gmb,
  instagramTypes,
  linkedinSharingTypes,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import urlJoin from 'proper-url-join'

export const utmMixin = {
  computed: {
    ...mapGetters([
      'getUtmsStatus',
      'getCurrentUtms',
      'getUtms',
      'getWorkspaces',
      'getPublishSelection',
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getPublishUtmSelection',
      'getInstagramSharingDetails',
      'getGmbSharingDetails',
      'getYoutubeSharingDetails',
    ]),

    filteredUtms() {
      const utms = []
      const stateObject = this

      if (this.getUtms.search) {
        this.getUtms.items.forEach(function (element, index) {
          if (
            element.name
              .toLowerCase()
              .indexOf(stateObject.getUtms.search.toLowerCase()) >= 0
          ) {
            utms.push(element)
          }
        })
      } else {
        return []
      }

      return utms
    },

    fetchActiveUtm() {
      console.debug('Method:fetchActiveUtm')

      let selectedUtm = false

      if (this.getWorkspaces.activeWorkspace.utmStatus) {
        this.getUtms.items.forEach(function (item) {
          if (item.active === 1 || item.active === true) {
            selectedUtm = item
            return false
          }
        })

        if (!selectedUtm) {
          selectedUtm = 'ContentStudio'
        }
      }

      return selectedUtm
    },
  },
  methods: {
    ...mapActions([
      'setUtmsStatus',
      'setUtms',
      'setUtmLoader',
      'getUtmLoader',
      'resetUtm',
      'setSaveUtmLoader',
      'setFetchLongLinkLoader',
      'setSharingUtmLink',
      'setCommonSharingLink',
      'setCommonFacebookSharingLink',
      'setCommonTwitterSharingLink',
      'setCommonLinkedinSharingLink',
      'setCommonPinterestSharingLink',
      'setCommonTumblrSharingLink',
      'setFacebookSharingLink',
      'setTwitterSharingLink',
      'setPinterestSharingLink',
      'setTumblrSharingLink',
      'setFetchCommonSharingPreviewLoader',
      'setFetchFacebookSharingPreviewLoader',
      'setFetchTwitterSharingPreviewLoader',
      'setFetchLinkedinSharingPreviewLoader',
      'setFetchPinterestSharingPreviewLoader',
      'setFetchTumblrSharingPreviewLoader',
      'setCommonInstagramSharingLink',
      'setFetchInstagramSharingPreviewLoader',
      'setSelectedUtm',
      'setCurrentUtm',
      'setCommonYoutubeSharingLink',
      'setFetchYoutubeSharingPreviewLoader',
    ]),

    /**
     * This method will fetch the UTMs in an account
     */
    fetchAllUtms(noLoad, applyType) {
      if (!this.getUtmsStatus) {
        if (!noLoad) {
          this.setUtmLoader(true)
        }
        this.setUtmsStatus(true)
        this.postRequest(
          fetchUTMUrl,
          {
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.status) {
              this.setUtms(response.data.utm)
              this.setUtmLoader(false)
              if (applyType) {
                this.applyUtmDetails(applyType)
              }
            } else {
              this.setUtmLoader(false)
            }
          },
          (response) => {
            this.setUtmLoader(false)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },

    saveUtms(process, type) {
      console.debug('Method::saveUtms', process, type)
      if (!this.getCurrentUtms.name) {
        this.alertMessage(UTM_INVALID_NAME, 'error')
        return
      }
      if (
        !this.getCurrentUtms.auto_add_social_channel &&
        !this.getCurrentUtms.source
      ) {
        this.alertMessage(UTM_INVALID_SOURCE, 'error')
        return
      }
      const payload = {
        id: this.getCurrentUtms._id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        name: this.getCurrentUtms.name,
        source: this.getCurrentUtms.source,
        medium: this.getCurrentUtms.medium,
        content: this.getCurrentUtms.content,
        auto_add_social_channel: this.getCurrentUtms.auto_add_social_channel,
      }

      this.setSaveUtmLoader(true)
      this.postRequest(
        saveUTMUrl,
        payload,
        (response) => {
          if (response.data.status) {
            this.alertMessage(UTM_SAVE_SUCCESS, 'success')
            // this.$store.dispatch('trackEvent', { event: 'utm_tracking_created' })

            this.setUtmsStatus(false)

            if (process === 'Sharing') {
              this.setSelectedUtm(response.data.id)
            } else {
              this.$bvModal.hide('add_utm_modal')
            }

            this.fetchAllUtms(true, type)
          } else {
            this.alertMessage(response.data.message, 'error')
          }
          this.setSaveUtmLoader(false)
        },
        (response) => {
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setSaveUtmLoader(false)
        }
      )
    },

    deleteUtm(confirmation, data) {
      if (confirmation.status) {
        this.postRequest(
          removeUtmUrl,
          {
            utm: data.utm,
            workspace_id: data.workspace_id,
          },
          (response) => {
            if (response.data.status) {
              for (let i = 0; i < this.getUtms.items.length; i++) {
                if (this.getUtms.items[i]._id === data.utm) {
                  this.getUtms.items.splice(i, 1)
                }
              }
              this.alertMessage(response.data.message, 'success')
            } else {
              this.setConfirmActionStage('error')
              this.alertMessage(response.data.message, 'error')
            }
            // eslint-disable-next-line no-undef
            $('#' + confirmation.type).modal('hide')
            this.setConfirmActionStage('')
          },
          (response) => {
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      } else {
        // eslint-disable-next-line no-undef
        $('#' + confirmation.type).modal('hide')
      }
    },

    editUtmStatus(utm) {
      console.debug('editUtmStatus', utm)
      this.postRequest(
        editUtmStatusUrl,
        {
          utm: utm._id,
          workspace_id: utm.workspace_id,
        },
        (response) => {
          if (response.data.status) {
            for (let i = 0; i < this.getUtms.items.length; i++) {
              if (this.getUtms.items[i]._id === utm._id) {
                this.getUtms.items[i].active = true
              } else {
                this.getUtms.items[i].active = false
              }
            }
          } else {
            utm.active = false
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        },
        (response) => {
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    resetUtmData() {
      this.resetUtm()
    },

    autoAttachUtm() {
      this.postRequest(
        changeAttachUtmStatusUrl,
        {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          utmStatus: !this.getWorkspaces.activeWorkspace.utmStatus,
        },
        (response) => {
          if (response.data.status) {
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (response) => {
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    fetchUtmBasedLinks(url, selectedUtm) {
      console.debug('Method:fetchUtmBasedLinks', url, selectedUtm)

      /* eslint-disable */
      const regexp =
        /^http[^\?]*\.(html|php|png|pdf|js|htm|zip|png|jpg|gif|ico|asp|jpeg|tiff|bmp)(?:[?/])?/gim
      /* eslint-enable */
      const isContainExt = regexp.test(url)
      let options = {
        leadingSlash: false,
        trailingSlash: true,
      }
      // if has ext then remove tailing slash
      if (isContainExt) {
        options = {
          leadingSlash: false,
          trailingSlash: false,
        }
      }

      const response = {
        common: url,
        facebook: url,
        twitter: url,
        linkedin: url,
        pinterest: url,
        tumblr: url,
        instagram: url,
        youtube: url,
        gmb: url,
        auto_add_social_channel: false,
      }
      if (url) {
        if (selectedUtm) {
          let querystring = ''
          url = this.removeExistingUtmParameters(url)
          console.debug('HELPPPPPP', url, selectedUtm, url.match(/\?/))
          if (selectedUtm === 'ContentStudio') {
            let queryParameter = null
            if (url.indexOf('/?') >= 0) {
              const splitUrl = url.split('?')
              url = splitUrl[0]
              queryParameter = splitUrl[1]
            }
            url = urlJoin(
              url,
              (url.match(/\?/) ? '&' : '?') +
                'utm_source=contentstudio.io&utm_medium=referral',
              options
            )
            if (queryParameter !== null) url = url + '&' + queryParameter
            response.common = url
            response.facebook = url
            response.twitter = url
            response.linkedin = url
            response.pinterest = url
            response.tumblr = url
            response.instagram = url
            response.youtube = url
            response.gmb = url
          } else {
            if (!selectedUtm.auto_add_social_channel) {
              if (selectedUtm.source)
                querystring =
                  'utm_source=' + selectedUtm.source.replaceAll(' ', '%20')
              if (selectedUtm.medium) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_medium=' + selectedUtm.medium.replaceAll(' ', '%20')
              }

              if (selectedUtm.name) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_campaign=' + selectedUtm.name.replaceAll(' ', '%20')
              }

              if (selectedUtm.content) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_content=' + selectedUtm.content.replaceAll(' ', '%20')
              }
              console.debug('HELPPPPPP', querystring)
              if (querystring) {
                let queryParameter = null
                if (url.indexOf('/?') >= 0) {
                  const splitUrl = url.split('?')
                  url = splitUrl[0]
                  queryParameter = splitUrl[1]
                }
                // url = urlJoin(url, (url.match(/\?/) ? '&' : '?') + querystring, options)
                url = url + (url.match(/\?/) ? '&' : '?') + querystring
                console.debug('HELPPPPPP', url)
                if (queryParameter !== null) url = url + '&' + queryParameter
                console.debug('HELPPPPPP', url)
                response.common = url
                response.facebook = url
                response.twitter = url
                response.linkedin = url
                response.pinterest = url
                response.tumblr = url
                response.instagram = url
                response.gmb = url
                response.youtube = url
              }
            } else {
              querystring = 'utm_source=socialChangeIntoChannel'
              response.auto_add_social_channel = true

              if (selectedUtm.medium) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_medium=' + selectedUtm.medium.replaceAll(' ', '%20')
              }

              if (selectedUtm.name) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_campaign=' + selectedUtm.name.replaceAll(' ', '%20')
              }

              if (selectedUtm.content) {
                if (querystring) {
                  querystring += '&'
                }
                querystring +=
                  'utm_content=' + selectedUtm.content.replaceAll(' ', '%20')
              }
              response.common =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'social')
              response.facebook =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'facebook')
              response.twitter =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'twitter')
              response.linkedin =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'linkedin')
              response.pinterest =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'pinterest')
              response.tumblr =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'tumblr')
              response.instagram =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'instagram')
              response.gmb =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'gmb')
              response.youtube =
                url +
                (url.match(/\?/) ? '&' : '?') +
                querystring.replace('socialChangeIntoChannel', 'youtube')
            }
          }
        }
      }

      console.debug('123123123', response)
      return response
    },

    triggerUtmOption(type) {
      console.debug('Method::triggerUtmOption', type)

      let link = ''
      let description = ''

      switch (type) {
        case 'Common':
          if (this.getCommonSharingDetails.url) {
            link = this.getCommonSharingDetails.url
          } else {
            description = this.getCommonSharingDetails.message
          }
          break
        case 'Facebook':
          if (this.getFacebookSharingDetails.url) {
            link = this.getFacebookSharingDetails.url
          } else {
            description = this.getFacebookSharingDetails.message
          }
          break
        case 'Twitter':
          if (this.getTwitterSharingDetails.url) {
            link = this.getTwitterSharingDetails.url
          } else {
            description = this.getTwitterSharingDetails.message
          }
          break
        case 'Linkedin':
          if (this.getLinkedinSharingDetails.url) {
            link = this.getLinkedinSharingDetails.url
          } else {
            description = this.getLinkedinSharingDetails.message
          }
          break
        case 'Pinterest':
          if (this.getPinterestSharingDetails.url) {
            link = this.getPinterestSharingDetails.url
          } else {
            description = this.getPinterestSharingDetails.message
          }
          break
        case 'Tumblr':
          if (this.getTumblrSharingDetails.url) {
            link = this.getTumblrSharingDetails.url
          } else {
            description = this.getTumblrSharingDetails.message
          }
          break
        case 'Instagram':
          if (this.getInstagramSharingDetails.url) {
            link = this.getInstagramSharingDetails.url
          } else {
            description = this.getInstagramSharingDetails.message
          }
          break
        case 'Youtube':
          if (this.getYoutubeSharingDetails.url) {
            link = this.getYoutubeSharingDetails.url
          } else {
            description = this.getYoutubeSharingDetails.message
          }
          break
        case 'Gmb':
          if (this.getGmbSharingDetails.url) {
            link = this.getGmbSharingDetails.url
          } else {
            description = this.getGmbSharingDetails.message
          }
          break
      }

      if (link === '') {
        const urls = this.getLinksFromText(description)
        if (urls) {
          link = urls[0]
        }
      }

      const activeUtm = this.fetchActiveUtm

      if (activeUtm && activeUtm._id) {
        this.setSelectedUtm(activeUtm._id)
        this.setCurrentUtm(activeUtm)
      }

      if (link) {
        if (this.isShortLink(link)) {
          this.setFetchLongLinkLoader(true)
          this.postRequest(
            fetchLongLinkUrl,
            {
              link: link,
              workspace_id: this.getWorkspaces.activeWorkspace._id,
            },
            (response) => {
              if (response.data.status) {
                this.setSharingUtmLink(
                  this.removeExistingUtmParameters(response.data.link)
                )
              } else {
                this.setSharingUtmLink(link)
              }
              this.setFetchLongLinkLoader(false)
            },
            (response) => {
              this.setSharingUtmLink('')
              console.debug('Exception in triggerUtmOption', response)
              this.alertMessage(UNKNOWN_ERROR, 'error')
              this.setFetchLongLinkLoader(false)
            }
          )
        } else {
          this.setSharingUtmLink(this.removeExistingUtmParameters(link))
        }
      }
    },

    applyUtmDetails(type) {
      console.debug(
        'Method:applyUtmDetails',
        type,
        this.getPublishSelection.utm.selected,
        this.getUtms.items
      )

      const stateObject = this
      let selectedUtm = false
      this.getUtms.items.forEach(function (item) {
        if (item._id === stateObject.getPublishSelection.utm.selected) {
          selectedUtm = item
          return false
        }
      })

      if (
        !this.getPublishUtmSelection.link ||
        this.getPublishUtmSelection.link.trim().length === 0
      ) {
        this.alertMessage('Link is required', 'error')
        return false
      }
      if (!this.isValidUrl(this.getPublishUtmSelection.link)) {
        this.alertMessage('Link is not valid.', 'error')
        return false
      }
      let message = ''
      const response = this.fetchUtmBasedLinks(
        this.getPublishUtmSelection.link,
        selectedUtm
      )

      switch (type) {
        case 'Common':
          message = this.getCommonSharingDetails.message
          if (
            this.getCommonSharingDetails.url &&
            this.getCommonSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getCommonSharingDetails.url,
              response.common
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.common)
            } else {
              message = message + ' ' + response.common
            }
          }
          this.setSharingMessage(type, message)
          // if (this.getCommonSharingDetails.image.length === 0 && (!this.getCommonSharingDetails.video || this.getCommonSharingDetails.video.link === '')) {

          this.setCommonSharingLink(response.common)
          this.setCommonFacebookSharingLink(response.facebook)
          this.setCommonTwitterSharingLink(response.twitter)
          this.setCommonLinkedinSharingLink(response.linkedin)
          this.setCommonPinterestSharingLink(response.pinterest)
          this.setCommonTumblrSharingLink(response.tumblr)
          this.setCommonInstagramSharingLink(response.instagram)
          this.setCommonYoutubeSharingLink(response.youtube)
          this.$store.commit(commonTypes.SET_GMB_SHARING_LINK, response.gmb)
          this.setFetchCommonSharingPreviewLoader(true)
          // }

          // eslint-disable-next-line no-case-declarations
          const urls = [response.common]
          if (response.auto_add_social_channel) {
            urls.push(response.facebook)
            urls.push(response.twitter)
            urls.push(response.linkedin)
            urls.push(response.pinterest)
            urls.push(response.tumblr)
            urls.push(response.instagram)
            urls.push(response.youtube)
          }

          this.fetchShortLinks(urls, type)
          break
        case 'Facebook':
          message = this.getFacebookSharingDetails.message

          if (
            this.getFacebookSharingDetails.url &&
            this.getFacebookSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getFacebookSharingDetails.url,
              response.facebook
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.facebook)
            } else {
              message = message + ' ' + response.facebook
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getFacebookSharingDetails.image.length === 0 && (!this.getFacebookSharingDetails.video || this.getFacebookSharingDetails.video.link === '')) {
          this.setFetchFacebookSharingPreviewLoader(true)
          this.setFacebookSharingLink(response.facebook)

          // }

          this.fetchShortLinks([response.facebook], type)
          break
        case 'Twitter':
          message = this.getTwitterSharingDetails.message

          if (
            this.getTwitterSharingDetails.url &&
            this.getTwitterSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getTwitterSharingDetails.url,
              response.twitter
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.twitter)
            } else {
              message = message + ' ' + response.twitter
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getTwitterSharingDetails.image.length === 0 && (!this.getTwitterSharingDetails.video || this.getTwitterSharingDetails.video.link === '')) {
          this.setTwitterSharingLink(response.twitter)
          this.setFetchTwitterSharingPreviewLoader(true)
          // }

          this.fetchShortLinks([response.twitter], type)
          break
        case 'Linkedin':
          message = this.getLinkedinSharingDetails.message
          if (
            this.getLinkedinSharingDetails.url &&
            this.getLinkedinSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getLinkedinSharingDetails.url,
              response.linkedin
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.linkedin)
            } else {
              message = message + ' ' + response.linkedin
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getLinkedinSharingDetails.image.length === 0 && (!this.getLinkedinSharingDetails.video || this.getLinkedinSharingDetails.video.link === '')) {
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_URL,
            response.linkedin
          )
          this.setFetchLinkedinSharingPreviewLoader(true)
          // }

          this.fetchShortLinks([response.linkedin], type)
          break
        case 'Pinterest':
          message = this.getPinterestSharingDetails.message
          if (
            this.getPinterestSharingDetails.url &&
            this.getPinterestSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getPinterestSharingDetails.url,
              response.pinterest
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.pinterest)
            } else {
              message = message + ' ' + response.pinterest
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getPinterestSharingDetails.image.length === 0 && (!this.getPinterestSharingDetails.video || this.getPinterestSharingDetails.video.link === '')) {
          this.setPinterestSharingLink(response.pinterest)
          this.setFetchPinterestSharingPreviewLoader(true)
          // }

          this.fetchShortLinks([response.pinterest], type)
          break
        case 'Tumblr':
          message = this.getTumblrSharingDetails.message
          if (
            this.getTumblrSharingDetails.url &&
            this.getTumblrSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getTumblrSharingDetails.url,
              response.tumblr
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.tumblr)
            } else {
              message = message + ' ' + response.tumblr
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getTumblrSharingDetails.image.length === 0 && (!this.getTumblrSharingDetails.video || this.getTumblrSharingDetails.video.link === '')) {
          this.setTumblrSharingLink(response.tumblr)
          this.setFetchTumblrSharingPreviewLoader(true)
          // }

          this.fetchShortLinks([response.tumblr], type)
          break
        case 'Instagram':
          message = this.getInstagramSharingDetails.message
          if (
            this.getInstagramSharingDetails.url &&
            this.getInstagramSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getInstagramSharingDetails.url,
              response.instagram
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.instagram)
            } else {
              message = message + ' ' + response.instagram
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getInstagramSharingDetails.image.length === 0 && (!this.getInstagramSharingDetails.video || this.getInstagramSharingDetails.video.link === '')) {
          this.$store.commit(instagramTypes.SET_SHARING_URL, response.instagram)
          this.setFetchInstagramSharingPreviewLoader(true)
          // }

          this.fetchShortLinks([response.instagram], type)
          break
        case 'Youtube':
          message = this.getYoutubeSharingDetails.message
          if (
            this.getYoutubeSharingDetails.url &&
            this.getYoutubeSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getYoutubeSharingDetails.url,
              response.youtube
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.youtube)
            } else {
              message = message + ' ' + response.youtube
            }
          }

          this.setSharingMessage(type, message)

          this.$store.commit(youtubeTypes.SET_SHARING_URL, response.youtube)
          this.setFetchYoutubeSharingPreviewLoader(true)

          this.fetchShortLinks([response.youtube], type)
          break
        case 'Gmb':
          message = this.getGmbSharingDetails.message
          if (
            this.getGmbSharingDetails.url &&
            this.getGmbSharingDetails.url.trim().length > 0
          ) {
            message = message.replace(
              this.getGmbSharingDetails.url,
              response.gmb
            )
          } else {
            const urls = this.getLinksFromText(message)
            if (urls) {
              message = message.replace(urls[0], response.gmb)
            } else {
              message = message + ' ' + response.gmb
            }
          }

          this.setSharingMessage(type, message)

          // if (this.getGmbSharingDetails.image.length === 0 && (!this.getGmbSharingDetails.video || this.getGmbSharingDetails.video.link === '')) {
          this.$store.commit(gmb.SET_SHARING_URL, response.gmb)
          this.$store.commit(gmb.SET_FETCH_SHARING_PREVIEW_LOADER, true)
          // }

          this.fetchShortLinks([response.gmb], type)
          break
      }

      // eslint-disable-next-line no-undef
      $('.sharing_utm_dropdown .dropdown-menu').removeClass('show')
    },

    removeExistingUtmParameters(url) {
      let querystring = ''
      const splitUrl = url.split('?')

      if (splitUrl[1]) {
        url = splitUrl[0]

        const parameters = splitUrl[1].split('&')
        if (parameters) {
          parameters.forEach(function (parameter) {
            if (
              parameter.indexOf('utm_source') < 0 &&
              parameter.indexOf('utm_medium') < 0 &&
              parameter.indexOf('utm_content') < 0 &&
              parameter.indexOf('utm_campaign') < 0
            ) {
              if (querystring) {
                querystring += '&'
              }
              querystring += parameter
            }
          })
        }
      }

      // if (url.slice(-1) !== '/') url += '/'  // https://app.shortcut.com/contentstudio-team/story/41644/utm-link-issue
      if (querystring) url += '?' + querystring
      return url
    },
  },
}
