<script>
// libraries
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'TableHeader',
  props: {
    day: {
      type: String,
      default: '',
    },
  },
  setup() {
    const showBackground = ref(false)

    /**
     * Add background color white when table scrolls
     */
    onMounted(() => {
      const tableBody = document.getElementById('time-grid-calendar')
      tableBody.addEventListener('scroll', function () {
        showBackground.value = tableBody.scrollTop > 0
      })
    })

    return {
      showBackground,
    }
  },
})
</script>

<template>
  <th
    class="sticky top-0 z-10 h-[2rem] box-content border font-bold w-2/3 text-center lg:text-[0.85rem] md:text-[0.7rem]"
    :class="{ 'bg-white': showBackground }"
  >
    {{ day.toUpperCase() }}
  </th>
</template>
