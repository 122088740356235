<template>
  <div
    class="mt-3 bg-white rounded-md shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      v-b-toggle.accordion-3
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <svg
            fill="none"
            height="25"
            viewBox="0 0 24 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21ZM11.2637 14.2363L11 14.5L16 9.5"
              fill="#EEF9F3"
            />
            <path
              d="M7.99999 12H9.99999L11 9L12 12L13 15L14 12H16M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z"
              stroke="#56C288"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </svg>
        </div>
        <h3 class="font-normal text-base">Approval Status</h3>
      </div>
      <i class="fas fa-chevron-down"></i>
    </div>
    <!--          <hr class="m-0">-->
    <b-collapse
      id="accordion-3"
      accordion="my-accordion"
      role="tabpanel"
      visible
    >
      <div v-if="plan.approval" class="overflow-y-auto flex-1 px-6 mx-3">
        <p
          class="p-2"
          style="text-align: center"
          v-html="getApprovalOptionText(plan.approval.approve_option)"
        >
        </p>
        <FeedViewApprovalStatus
          class="mb-3"
          :approval="plan.approval"
        ></FeedViewApprovalStatus>
      </div>
      <template v-if="externalActions.length > 0">
        <div class="flex items-center justify-center">
          <span class="font-bold text-sm">External Review</span>
          <v-popover
            trigger="hover"
            placement="bottom-end"
            popover-class="threaded-tweets__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <img
              width="20px"
              height="20px"
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5 mx-2"
            />
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <p class="">Added by Shareable link</p>
            </template>
          </v-popover>
        </div>
        <ul class="overflow-y-auto flex-1 items-center px-6 py-2 mx-3">
          <template v-for="(action, index) in externalActions">
            <li :key="index">
              <div
                v-if="action.type === 'approve'"
                class="flex items-center justify-between"
              >
                <span>
                  <i class="approved far fa-check mr-2" />
                  <span
                    v-floatingtip="action.email"
                    class="font-medium cursor-pointer"
                    >{{ action.name }}</span
                  >
                </span>

                Approved on
                {{
                  getWorkspaceTimeZoneTime(action.created_at, 'MMM DD, hh:mm a')
                }}
              </div>
              <div
                v-if="action.type === 'reject'"
                class="flex items-center justify-between"
              >
                <span>
                  <i class="rejected far fa-ban mr-2" />
                  <span
                    v-floatingtip="action.email"
                    class="font-medium cursor-pointer"
                    >{{ action.name }}</span
                  >
                </span>

                Rejected on
                {{
                  getWorkspaceTimeZoneTime(action.created_at, 'MMM DD, hh:mm a')
                }}
              </div>
            </li>
          </template>
        </ul>
      </template>
    </b-collapse>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FeedViewApprovalStatus from '@src/modules/planner/components/view/feed-view/FeedViewApprovalStatus'

export default defineComponent({
  name: 'PlanApprovalStatus',
  components: {
    FeedViewApprovalStatus,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    externalActions: {
      type: Array,
      default: () => [],
    },
  },
})
</script>

<style scoped></style>
