<script>
export default {
  props: ['type'],
  methods: {
    scrolltotop () {
      this.$refs.canvaList.scrollTop = 0
    }
  }
}
</script>
<template>
  <div>
    <div
        data-display="static"
        class="dropdown_header d-flex align-items-center max-h-9"
        data-toggle="dropdown"
    >
      <span class="canva_icon canva_width" v-tooltip="'Design image with Canva'">
        <img
            src="../../../../assets/img/canva.svg"
            v-on:click="scrolltotop()"
        />
      </span>
    </div>

    <div class="dropdown-menu show_canva_options">
<!--      <h2 class="heading">Design type</h2>-->

      <ul class="inner" ref="canvaList">
        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'SocialMedia')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">SocialMedia</span></a
          >
        </li>
        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'FacebookPost')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">FacebookPost</span></a
          >
        </li>
        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'TwitterPost')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">TwitterPost</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'InstagramPost')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">InstagramPost</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'InstagramStory')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">InstagramStory</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCanvaDesign(type, 'YouTubeThumbnail')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">YouTubeThumbnail</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
