var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dropdown_header d-flex align-items-center max-h-9",attrs:{"data-display":"static","data-toggle":"dropdown"}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Design image with VistaCreate'),expression:"'Design image with VistaCreate'"}],staticClass:"canva_icon vista_width"},[_c('img',{staticClass:"vista-icon",attrs:{"src":require("@assets/img/vista-black.svg"),"alt":""},on:{"click":function($event){return _vm.scrolltotop()}}}),_vm._v("VistaCreate ")])]),_c('div',{staticClass:"dropdown-menu show_canva_options"},[_c('ul',{ref:"crelloList",staticClass:"inner"},[_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'facebookSM')}}},[_vm._m(0)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'facebookVideoStoryAN')}}},[_vm._m(1)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'instagramSM')}}},[_vm._m(2)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'instagramVideoStoryAN')}}},[_vm._m(3)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'twitterSM')}}},[_vm._m(4)]),_c('li',{staticClass:"list_item",on:{"click":function($event){$event.preventDefault();return _vm.createCrelloDesign(_vm.type, 'tikTokVideoAN')}}},[_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("Facebook SM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("Facebook Video Story AN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("Instagram SM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("Instagram Video Story AN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("Twitter SM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center",attrs:{"href":""}},[_c('span',{staticClass:"name"},[_vm._v("TikTok Video AN")])])
}]

export { render, staticRenderFns }