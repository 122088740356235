function linksExtractor(text) {
  return text.match(
    /((ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._\/\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.,~#?&\/=]*)/g
  )
}

function hashtagFromText(searchText) {
  const regexp = /\B\#\w\w+\b/g
  return searchText.match(regexp)
}

function replaceAll(search, replace, target) {
  // for the utm parameters
  if (target.includes('?')) {
    return target.replace(search, replace)
  }
  // for default cases.
  return target.replace(new RegExp(search, 'g'), replace)
}

function removeUTMParameters(url) {
  let querystring = ''
  const splitUrl = url.split('?')

  if (splitUrl[1]) {
    url = splitUrl[0]

    const parameters = splitUrl[1].split('&')
    if (parameters) {
      parameters.forEach(function (parameter) {
        if (
          parameter.indexOf('utm_source') < 0 &&
          parameter.indexOf('utm_medium') < 0 &&
          parameter.indexOf('utm_content') < 0 &&
          parameter.indexOf('utm_campaign') < 0
        ) {
          if (querystring) {
            querystring += '&'
          }
          querystring += parameter
        }
      })
    }

    if (querystring) {
      url += '?' + querystring
    }
  }

  return url
}

function getFirstLinkFromMessage(message, link = '') {
  if (link === '') {
    const urls = linksExtractor(message)
    if (urls) {
      link = urls[0]
    }
  }
  return link
}

function isValidUrl(url) {
  // let regexp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig //not validating http://j.mp/2gAYmSN
  const regexp =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  return regexp.test(url)
}
export {
  linksExtractor,
  hashtagFromText,
  replaceAll,
  removeUTMParameters,
  isValidUrl,
  getFirstLinkFromMessage,
}
