<template>
  <!-- Render views for Trending News, Favorites, Archived and Pocket Posts-->
  <div class="topics-pane">
    <topics-sidebar />
    <div class="topics-top-pane force-white-bg">
      <div class="topics-top-pane__inner">
        <div class="topics-top-pane__inner__left_section">
          <div class="topics-top-pane__inner__left_section__inner">
            <h3>Favorite</h3>
            <h2>{{ folder.name }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <div class="topics-filters-pane__container__left_section"> </div>
        <div class="topics-filters-pane__container__right_section">
          <div class="view_change_btn d-flex align-items-center mb-3">
            <div
              class="topics-filters-pane__container__right_section__view_change"
            >
              <span
                v-tooltip.top-center="'Grid View'"
                class="grid_icon"
                :class="{ active: getDiscoveryView === 'grid' }"
                @click.prevent="changeViewLayout('grid')"
              >
                <i class="icon-groups-cs"></i>
              </span>
              <span
                class="list_icon"
                v-tooltip.top-center="'List View'"
                :class="{ active: getDiscoveryView === 'list' }"
                @click.prevent="changeViewLayout('list')"
              >
                <i class="icon-Listview_icon"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-content-container">
      <div class="topics-content-container__box">
        <div class="topics-content-container__box__inner">
          <template
            v-if="favorite_posts_list.length"
            v-for="(post, index) in favorite_posts_list"
          >
            <div
              class="article-list-view-container"
              v-if="$route.query.view === 'list'"
            >
              <topics-list-view-item :post="post" :index="index" />
            </div>
            <div
              class="article-grid-view-container"
              :key="'grid_' + post._source.id"
              v-else
            >
              <topics-grid-view-item :post="post" :index="index" />
            </div>
          </template>

          <infinite-loading
            :identifier="infiniteId"
            @infinite="favoritePostsHandler"
            ref="infiniteFavoritePosts"
          >
            <span slot="spinner" class="d-block pt-3 pb-4">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>

            <span slot="no-more" class="not_found_text">
              <no-results-found v-if="total_results > 20" :more="true" />
            </span>
            <span slot="no-results" class="not_found_text">
              <!-- once the request completes, we show it based on the slot content. -->
              <no-results-found
                v-if="is_request_completed_for_favorite_posts"
              />
            </span>
            <no-results-found :nore="true" />
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@common/lib/event-bus'
import TopicsGridViewItem from '../../../common/components/TopicsGridViewItem'
import TopicsSidebar from '../../sub-components/TopicsSidebar'
import InfiniteLoading from 'vue-infinite-loading'
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import NoResultsFound from '../../NoResultsFound'
import {discoveryDefaultView} from "@src/modules/publish/config/api-utils";
import {mapActions, mapGetters} from "vuex";

const TopicsListViewItem = () =>
  import('../../../common/components/TopicsListViewItem')

export default {
  components: {
    TopicsSidebar,
    TopicsGridViewItem,
    TopicsListViewItem,
    InfiniteLoading,
    NoResultsFound
  },
  data () {
    return {
      limit: 20,
      page: 1,
      infiniteId: +new Date(),
      infiniteState: '',
      total_results: 0,
      favorite_posts_list: [],
      folder: { name: '' },
      is_request_completed_for_favorite_posts: false
    }
  },
  computed: {
    ...mapGetters(['getDiscoveryView']),
  },
  mounted () {
    EventBus.$on('archive-post-item', async (res) => {
      console.log('archive-post-item', res)
      const resArchive = await this.$store.dispatch(
        'archivePostItem',
        res.postId
      )
      if (resArchive) {
        this.favorite_posts_list.splice(
          this.favorite_posts_list.findIndex(
            (item) => item._source.id === res.postId
          ),
          1
        )
      }
    })

    EventBus.$on('unfavorite-post-item', async (payload) => {
      console.log('EventBus:unfavorite-post-item ', payload)
      const unfavorite = this.$store.dispatch('unfavoritePostItem', {
        id: payload.postId
      })
      unfavorite.then((res) => {
        if (res.data.status) {
          const postIndex = this.favorite_posts_list.findIndex(
            (item) => item._source.id === payload.postId
          )
          this.favorite_posts_list.splice(postIndex, 1)
          this.total_results -= 1
          if (this.total_results === 0) {
            this.page = 1
            this.favorite_posts_list = []
            this.folder = { name: '' }
            this.total_results = 0
            this.state = ''
            this.infiniteState = ''
            this.infiniteId += 1
          }
          // post._source.is_favorite = false
          // post._source.is_active = false
        }
      })
    })
  },
  beforeDestroy () {
    EventBus.$off('archive-post-item')
    EventBus.$off('unfavorite-post-item')
  },
  methods: {
    ...mapActions(['setDiscoveryDefaultView']),
    favoritePostsHandler ($state) {
      this.actionFetchFavoritePostsByFolderId($state)
    },
    async actionFetchFavoritePostsByFolderId ($state) {
      this.is_request_completed_for_favorite_posts = false
      await proxy
        .post(`${discoveryBaseUrl}v2/topics/retrieve_favorite_posts`, {
          page: this.page,
          limit: this.limit,
          folder_id: this.$route.params.folder_id,
          workspace_id: this.$store.getters.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.posts && res.data.posts.length > 0) {
              this.favorite_posts_list.push(...res.data.posts)
              this.folder = res.data.folder
              if ($state) $state.loaded()
              this.total_results = res.data.total
              this.page += 1
            } else {
              if ($state) $state.complete()
            }
          } else {
            if ($state) $state.complete()
          }
        })
        .catch((err) => {})
      this.is_request_completed_for_favorite_posts = true
    },
    /**
     * change view to grid or list
     * @param view
     * @returns {Promise<void>}
     */
    async changeViewLayout(view) {
      if (this.getDiscoveryView === view) {
        return
      }
      try {
        await this.setDiscoveryDefaultView(view)
        await this.$router.replace({query: {...this.$route.query, view: view}})
      }catch(e){
        console.log(e)
      }
    },
  },
  watch: {
    '$route.params.folder_id' (value) {
      this.page = 1
      this.favorite_posts_list = []
      this.folder = { name: '' }
      this.total_results = 0
      this.state = ''
      this.infiniteState = ''
      this.infiniteId += 1
    }
  }
}
</script>
