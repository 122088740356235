var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"saveSocialAccounts",attrs:{"id":"save-social-accounts","scrollable":"","centered":"","no-close-on-backdrop":"","header-class":"cs-modal-header","title-class":"cs-modal-title","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.modalHeader))]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"flex justify-end"},[_c('CstButton',{staticClass:"mr-3 w-28",attrs:{"size":"large","variant":"secondary","text":"Cancel"},on:{"click":_vm.hideModal}}),_c('CstButton',{staticClass:"w-28",attrs:{"variant":"primary","size":"large","loading":_vm.getSavePlatformsLoader,"disabled":_vm.getSavePlatformsLoader ||
          (_vm.isEmpty(_vm.secondSectionItems) && _vm.isEmpty(_vm.firstSectionItems))},on:{"click":_vm.saveSelectedPlatforms}},[_vm._v(" Save ")])],1)]},proxy:true}])},[(_vm.platformName)?_c('div',{staticClass:"mx-2 my-4"},[_c('section',{staticClass:"my-4"},[(_vm.platformName === 'facebook')?_c('CstAlert',{staticClass:"mb-5",attrs:{"type":"warn"}},[_vm._v("Unable to view all of your pages and groups? "),_c('a',{attrs:{"href":"https://docs.contentstudio.io/article/377-how-to-connect-facebook-pages","target":"_blank"}},[_vm._v("Follow this guide to allow permissions to access them.")])]):_vm._e(),(_vm.platformName === 'instagram')?_c('CstAlert',{staticClass:"mb-5",attrs:{"type":"warn"}},[_vm._v("Unable to view all of your business accounts? "),_c('a',{attrs:{"href":"https://docs.contentstudio.io/article/729-getting-started-with-instagram-publishing","target":"_blank"}},[_vm._v("Follow this guide on how to get started with Instagram.")])]):_vm._e(),_c('div',{staticClass:"flex justify-between items-center mb-3"},[_c('h2',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.firstSectionHeader))]),_c('span',{staticClass:"cursor-pointer text-blue-900 select-none",class:{
            'opacity-50 cursor-not-allowed': _vm.isEmpty(_vm.firstSectionItems),
          },on:{"click":function($event){return _vm.handleSelectAll('first')}}},[_vm._v("Select "+_vm._s(_vm.isFirstItemsAllSelected && !_vm.isEmpty(_vm.firstSectionItems) ? 'None' : 'All'))])]),(_vm.firstSectionItems && _vm.firstSectionItems.length)?_c('div',{staticClass:"grid gap-x-3.5 gap-y-2",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
            ? 'grid-cols-2'
            : 'grid-cols-3'},[_vm._l((_vm.firstSectionItems),function(item,key){return [_c('CstCardCheckbox',{key:key,staticClass:"w-full overflow-hidden",attrs:{"value":_vm.getPlanId(item),"title":_vm.getAccountName(item)},model:{value:(_vm.data.selectedPlatforms.firstItems),callback:function ($$v) {_vm.$set(_vm.data.selectedPlatforms, "firstItems", $$v)},expression:"data.selectedPlatforms.firstItems"}},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"rounded-full w-10 h-10",attrs:{"src":_vm.getImageLink(item),"alt":"profile_image"}}),_c('div',{staticClass:"ml-4 inline-block truncate select-none",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                    ? 'w-72'
                    : 'w-44'},[_vm._v(" "+_vm._s(_vm.getAccountName(item))+" ")])])])]})],2):_c('div',[_c('p',{staticClass:"text-center text-gray-700 py-4"},[_vm._v(_vm._s(_vm.firstSectionMessage))])])],1),(
        _vm.platformName === 'facebook' ||
        _vm.platformName === 'linkedin' ||
        _vm.platformName === 'tumblr'
      )?_c('section',{staticClass:"my-4"},[_c('div',{staticClass:"flex justify-between items-center mb-3"},[_c('h2',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.secondSectionHeader))]),_c('span',{staticClass:"cursor-pointer text-blue-900 select-none",class:{
            'opacity-50 cursor-not-allowed': _vm.isEmpty(_vm.secondSectionItems),
          },on:{"click":function($event){return _vm.handleSelectAll('second')}}},[_vm._v("Select "+_vm._s(_vm.isSecondItemsAllSelected && !_vm.isEmpty(_vm.secondSectionItems) ? 'None' : 'All'))])]),(_vm.platformName === 'facebook')?_c('CstAlert',{staticClass:"mb-5",attrs:{"type":"warn"}},[_vm._v("You need to be an admin and have ContentStudio added as an app to your Group in order to connect and post. "),_c('a',{attrs:{"href":"https://docs.contentstudio.io/article/378-how-to-connect-facebook-groups","target":"_blank"}},[_vm._v("Follow this guide to add ContentStudio as an app to the group. ")])]):_vm._e(),(_vm.secondSectionItems && _vm.secondSectionItems.length)?_c('div',{staticClass:"grid gap-x-3.5 gap-y-2 pt-1.5",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
            ? 'grid-cols-2'
            : 'grid-cols-3'},[_vm._l((_vm.secondSectionItems),function(item,key){return [_c('CstCardCheckbox',{key:key,staticClass:"w-full relative",attrs:{"value":_vm.getPlanId(item)},model:{value:(_vm.data.selectedPlatforms.secondItems),callback:function ($$v) {_vm.$set(_vm.data.selectedPlatforms, "secondItems", $$v)},expression:"data.selectedPlatforms.secondItems"}},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"rounded-full w-10 h-10",attrs:{"src":_vm.getImageLink(item),"alt":"profile_image"}}),_c('div',{staticClass:"ml-4 inline-block truncate select-none",class:_vm.platformName === 'gmb' || _vm.platformName === 'youtube'
                    ? 'w-72'
                    : 'w-44'},[_vm._v(" "+_vm._s(_vm.getAccountName(item))+" ")]),(_vm.isAppNotInstalled(item))?_c('div',{staticClass:"absolute -right-1.5 -top-2",on:{"click":(e) => _vm.handleAppNotInstalledClick(e, item.id)}},[_c('div',{directives:[{name:"floatingtip",rawName:"v-floatingtip.hover.top",value:({
                    content: `<div class='text-center w-52'>
                      In order to publish content to your group <strong>${_vm.getAccountName(
                        item
                      )}</strong> you must authorize ContentStudio application. Click on the icon to authorize permission.
                  </div>`,
                    html: true,
                  }),expression:"{\n                    content: `<div class='text-center w-52'>\n                      In order to publish content to your group <strong>${getAccountName(\n                        item\n                      )}</strong> you must authorize ContentStudio application. Click on the icon to authorize permission.\n                  </div>`,\n                    html: true,\n                  }",modifiers:{"hover":true,"top":true}}],staticClass:"w-5 h-5 rounded-full bg-red-600 text-xxs flex justify-center items-center text-white cursor-pointer"},[_c('i',{staticClass:"fas fa-exclamation"})])]):_vm._e()])])]})],2):_c('div',[_c('p',{staticClass:"text-center text-gray-700 py-4"},[_vm._v(_vm._s(_vm.secondSectionMessage))])])],1):_vm._e()]):_c('div',{staticClass:"py-12"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }