<template>
  <div class="bg-white w-full px-5 pt-2 flex items-center justify-between">
    <div class="flex space-x-2 justify-center items-center">
      <button
        v-if="hideFilterButton === false"
        class="border-0 bg-primary-variant-1 text-white text-xs font-semibold w-content px-2 rounded-md bg-opacity-90 hover:bg-opacity-100 focus:outline-none h-9"
        @click="$emit('toggle-filters')"
        >Filters
      </button>
      <template v-if="this.$route.name !== 'planner_calendar_v2'">
        <div class="planner-date-picker !w-[16rem]">
          <CstInputFields
            :date-options="dateOptions"
            :value="
              $route.query.date
                ? [
                    new Date($route.query.date.split('-')[0].trim()),
                    new Date($route.query.date.split('-')[1].trim()),
                  ]
                : [null, null]
            "
            class="planner-date-picker cursor-pointer"
            placeholder="Select specific date range"
            size="small"
            type="date"
            :date-picker-popup-classes="['planner-date-picker-popup']"
            @change="handleDateFilterChange"
          />
        </div>
        <div>
          <CstDropdown
            button-classes="bg-gray-200 w-[13rem]"
            size="small"
            container-classes="overflow-hidden z-20 !mt-0"
          >
            <template slot="selected">
              <span>
                <i
                  v-if="sortEvent === 'Last'"
                  class="fa fa-sort-amount-asc mr-1"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="sortEvent === 'First'"
                  class="fa fa-sort-amount-desc mr-1"
                  aria-hidden="true"
                ></i>
                Sort
                <span v-if="sortName && sortEvent">({{ sortName }})</span>
              </span>
            </template>
            <template v-slot>
              <div>
                <div class="mt-1">
                  <CstRadio
                    id="post-created-oldest"
                    v-floatingtip.right="
                      'It shows the oldest created post on top.'
                    "
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'post_created_at'
                    "
                    value="post_created_at-oldest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'oldest')"
                  >
                    <div class="w-[12rem]">First Created</div>
                  </CstRadio>
                  <CstRadio
                    id="post-created-newest"
                    v-floatingtip.right="
                      'It shows the recently created post on top.'
                    "
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'post_created_at'
                    "
                    value="post_created_at-newest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'newest')"
                  >
                    <div class="w-[12rem]">Last Created</div>
                  </CstRadio>
                </div>
                <div class="mt-1">
                  <CstRadio
                    id="post-updated-oldest"
                    v-floatingtip.right="
                      'It shows the oldest updated post on top.'
                    "
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'last_updated_at'
                    "
                    value="last_updated_at-oldest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'oldest')"
                  >
                    <div class="w-[12rem]">First Updated</div>
                  </CstRadio>
                  <CstRadio
                    id="post-updated-newest"
                    v-floatingtip.right="
                      'It shows the recently updated post on top.'
                    "
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'last_updated_at'
                    "
                    value="last_updated_at-newest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'newest')"
                  >
                    <div class="w-[12rem]">Last Updated</div>
                  </CstRadio>
                </div>
                <div class="mt-1 pb-1">
                  <CstRadio
                    id="post-scheduled-upcoming"
                    v-floatingtip.right="'It shows the earliest post on top.'"
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'status'
                    "
                    value="status-oldest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'oldest')"
                  >
                    <div class="w-[12rem]">First Scheduled</div>
                  </CstRadio>
                  <CstRadio
                    id="post-scheduled-last"
                    v-floatingtip.right="'It shows the endmost post on top.'"
                    name="sort_datatable"
                    :model-value="
                      getSortPreference
                        ? getSortPreference.column +
                          '-' +
                          getSortPreference.order
                        : 'status'
                    "
                    value="status-newest"
                    class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                    @change="radioChangeHandler($event, 'newest')"
                  >
                    <div class="w-[12rem]">Last Scheduled</div>
                  </CstRadio>
                </div>
              </div>
            </template>
          </CstDropdown>
        </div>
        <!-- Search Field-->
        <i
          v-if="hideSearchBar"
          v-floatingtip.right="'Search'"
          aria-hidden="true"
          class="fa fa-search fa-lg text-gray-700 cursor-pointer hover:text-blue-500 w-10 h-10 flex justify-center items-center bg-gray-200 rounded-lg"
          @click="openSearchBar"
        ></i>
        <div v-else class="relative w-[15.5rem]" style="transition: all 0.6s">
          <CstInputFields
            ref="searchBar"
            :value="search"
            class="pr-16 plannerSearchBar"
            placeholder="Search with title..."
            size="small"
            type="text"
            @change="(val) => (search = val)"
            @onPressEnter="$emit('setSearchQuery', search)"
            @blur="closeSearchBar"
          />
          <span v-if="!searchLoader && search.length">
            <i
              aria-hidden="true"
              class="fa fa-times absolute right-11 top-1/3 bg-white text-gray-700 cursor-pointer hover:text-blue-500"
              @click="clearSearch"
            ></i>
          </span>
          <span v-if="!searchLoader">
            <i
              aria-hidden="true"
              class="fa fa-search absolute right-4 top-1/3 bg-white text-gray-700 cursor-pointer hover:text-blue-500"
              @click="$emit('setSearchQuery', search)"
            ></i>
          </span>
          <span v-else>
            <clip-loader
              :color="'#4d4d4d'"
              :size="'13px'"
              class="spinner absolute right-4 top-1/3 opacity-70"
              variant="info"
            ></clip-loader>
          </span>
        </div>
      </template>
    </div>
    <div class="flex space-x-2 py-2.5 text-base items-center">
      <!-- Workspace Timezone -->
      <div>
        <v-popover placement="left" trigger="hover">
          <i
            class="fa fa-globe fa-lg w-10 h-10 flex justify-center items-center bg-gray-200 rounded-xl"
          ></i>

          <template slot="popover">
            <div>
              <p class="text-sm">
                {{ getWorkspaces.activeWorkspace.timezone }} (
                <clock
                  :timezone="getWorkspaces.activeWorkspace.timezone"
                  time-format="MMMM DD, hh:mm A"
                ></clock>
                )
              </p>
            </div>
          </template>
        </v-popover>
      </div>
      <div>
        <button
          class="inline-flex justify-between items-center cursor-pointer p-1 rounded-xl bg-gray-200 hover:bg-gray-300 border-0"
        >
          <span
            class="h-9 text-[#595c5f] px-2 inline-flex justify-center items-center"
            @click="$bvModal.show('manage-links-modal')"
          >
            <i class="far fa-link mr-2"></i>
            Shared Links
          </span>
        </button>
      </div>
      <div class="cst-switch">
        <div
          class="inline-flex justify-between items-center cursor-pointer p-1 rounded-xl bg-gray-200"
        >
          <a
            v-tooltip="'Calendar View'"
            @click="onClickPlannerView('planner_calendar_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_calendar_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="left-icon">
                <img
                  v-if="$route.name === 'planner_calendar_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'Feed View'"
            @click="onClickPlannerView('planner_feed_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_feed_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_feed_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-feed-view-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-feed-view.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'List View'"
            @click="onClickPlannerView('planner_list_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_list_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_list_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-list-view-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-list-view.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'Compact List View'"
            @click="onClickPlannerView('planner_list_compact_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_list_compact_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_list_compact_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-list-view-compact-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-list-view-compact.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
        </div>
      </div>

      <!-- Refresh Button -->
      <div v-tooltip="'Refresh Results'">
        <i
          :class="{ 'fa-spin': loader }"
          class="far fa-refresh cursor-pointer text-gray-900 bg-gray-100 bg-opacity-50 inline-flex items-center justify-center hover:text-black-900 h-7 w-7 rounded-md"
          @click="$emit('refreshTable')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import CstInputFields from '@src/components/UI/Input/CstInputFields.vue'
import { datePickerShortcuts } from '@common/constants/common-attributes'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstRadio from '@ui/Radio/CstRadio'
import moment from 'moment'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'PlannerHeader',
  components: {
    CstInputFields,
    CstDropdown,
    CstRadio,
  },
  props: {
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    selectedItemsCount: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    checkAllItems: {
      type: Boolean,
      default: false,
    },
    bulkChangeStatusOptionVisibilityStatus: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    searchLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideSearchBar: true,
      sortName: '',
      sortEvent: '',
      dateOptions: {
        defaultValue: null,
        shortcuts: [...datePickerShortcuts],
        format: 'DD MMM YY',
      },
      search: '',
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces', 'getSortPreference']),
  },
  watch: {
    /* search(val) {
      this.onChangeSearch(val)
    }, */
    /* '$route.name'(val) {
      console.log("WATCH::PlannerHeader router -> ", val)
      if (val !== 'planner_calendar_v2') {
        delete this.$route.query.date
      }
    } */
  },
  mounted() {
    EventBus.$on('set-sort-data', () => {
      if (this.getSortPreference) {
        this.radioChangeHandler(
          this.getSortPreference.column,
          this.getSortPreference.order
        )
      }
    })
  },
  methods: {
    async openSearchBar() {
      this.hideSearchBar = false
      await this.$nextTick()
      this.$refs.searchBar.$el.focus()
    },
    closeSearchBar() {
      if (this.search === '') {
        this.hideSearchBar = true
      }
    },
    radioChangeHandler(event, order) {
      if (event.includes('-')) {
        event = event.split('-')[0]
      }
      this.setSortNames(event, order)
      this.$emit('sort-handler', event, order)
    },
    setSortNames(event, order) {
      this.sortEvent = order
      if (event === 'post_created_at') {
        if (order === 'newest') {
          this.sortName = 'Last Created'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Created'
          this.sortEvent = 'First'
        }
      } else if (event === 'last_updated_at') {
        if (order === 'newest') {
          this.sortName = 'Last Updated'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Updated'
          this.sortEvent = 'First'
        }
      } else if (event === 'status') {
        if (order === 'newest') {
          this.sortName = 'Last Scheduled'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Scheduled'
          this.sortEvent = 'First'
        }
      }
    },
    handleDateFilterChange(date) {
      const query = Object.assign({}, this.$route.query)

      if (date[0] && date[1]) {
        const startDate = moment(date[0]).format('MMM DD, YYYY')
        const endDate = moment(date[1]).format('MMM DD, YYYY')

        query.date = startDate + ' - ' + endDate
      } else {
        delete query.date
      }

      this.$router.push({ query: query })
    },
    /**
     * debounced search query run when the user stops typing and is fired after 500ms of not typing.
     */
    onChangeSearch: _.debounce(function (val) {
      console.log('METHOD::onChangeSearch ~ val -> ', val)
      this.$emit('setSearchQuery', val)
    }, 500),

    onClickPlannerView(routeName) {
      console.log('METHOD::onClickPlannerView')
      if (
        this.$route.name === 'planner_calendar_v2' &&
        routeName !== this.$route.name
      )
        delete this.$route.query.date
      this.$router.push({ name: routeName, query: this.$route.query })
    },

    clearSearch() {
      this.search = ''
      this.$emit('setSearchQuery', this.search)
    },
  },
}
</script>

<style scoped lang="scss">
.planner-date-picker {
  width: 24rem;
}
</style>

<style lang="scss">
.planner-date-picker-popup {
  th,
  td {
    height: 40px !important;
    //font-size: 16px !important;
  }
  .mx-calendar {
    height: 325px;
  }
}
</style>
