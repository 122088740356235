<script>
import { mapGetters } from 'vuex'
import { redirectConnectorRequest } from '@common/lib/integrations'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],
  data() {
    return {
      link_visibility: true,
    }
  },
  computed: {
    ...mapGetters([
      'getIntegrationAuthorization',
      'getReplug',
      'getPocket',
      'getFeedly',
    ]),
    getServiceName() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1)
    },
    getServiceConnectText() {
      switch (this.type) {
        case 'pocket':
          return 'Connect New Pocket Account'
        case 'replug':
          return 'Connect New Replug Account'
        case 'feedly':
          return 'Connect New Feedly Account'
      }
      return ''
    },
    isReplug() {
      return this.type === 'replug'
    },
    getServiceImage() {
      switch (this.type) {
        case 'pocket':
          return require('../../../../assets/img/integration/pocket_red.svg')
        case 'replug':
          return require('../../../../assets/img/integration/replug.svg')
        case 'feedly':
          return require('../../../../assets/img/integration/feedly.svg')
      }
      return ''
    },
    isReplugConnected() {
      return this.getReplug.item && this.getReplug.item._id
    },
    showConnectionBox() {
      return !!(this.type !== 'replug' || !this.isReplugConnected)
    },
    linkVisibilityStatus() {
      return this.link_visibility && this.getIntegrationAuthorization[this.type]
    },
    hasConnectedAccounts() {
      const accounts = this.getConnectedAccounts
      return accounts && accounts.length
    },
    getConnectedAccounts() {
      switch (this.type) {
        case 'pocket':
          return this.getPocket
        case 'replug':
          if (this.isReplugConnected) {
            return [this.getReplug.item]
          }
          break
        case 'feedly':
          return this.getFeedly.items
      }
      return []
    },
  },
  methods: {
    getAccountDisplayText(account) {
      switch (this.type) {
        case 'pocket':
          return account.username
        case 'replug':
          return account.replug_email
        case 'feedly':
          return account.email
      }
    },
    /* eslint-disable camelcase */
    connectAccount(type, process, connector_id) {
      this.link_visibility = false
      const link = this.getIntegrationAuthorization[this.type]
      const details = { type: type, process: process }
      if (typeof connector_id !== 'undefined') {
        details.connector_id = connector_id
      }
      if (type === 'pocket') {
        details.pocketRedirectToken =
          this.getIntegrationAuthorization.pocket_redirect_token
      }
      redirectConnectorRequest(
        details,
        link,
        this.getWorkspaces.activeWorkspace
      )
    },
    removeAccount(type, platform) {
      console.debug('Method::removeAccount', type, platform)
      this.$parent.type = type
      this.$parent.platform = platform
      this.$bvModal.show('removeIntegration')
    },
  },
}
</script>

<template>
  <div :id="type + '-section'" class="ps_box">
    <div class="box_heading">
      <h2>{{ getServiceName }}</h2>
    </div>
    <div class="box_content">
      <div class="integration_box_list">
        <div
          v-if="showConnectionBox"
          class="integration_box connect_integration_box"
        >
          <div class="box_inner">
            <div class="content">
              <img
                :style="[isReplug ? { width: '80px', height: '80px' } : {}]"
                class="int_img"
                :src="getServiceImage"
                alt=""
              />

              <div
                class="text d-flex justify-content-center align-items-center"
              >
                <p>{{ getServiceConnectText }}</p>
              </div>

              <a
                v-if="linkVisibilityStatus"
                class="btn btn-studio-theme-transparent"
                @click.prevent="connectAccount(type, 'connect')"
              >
                <span>Connect</span>
              </a>
            </div>
          </div>
        </div>

        <template v-if="hasConnectedAccounts">
          <div
            v-for="(account, index) in getConnectedAccounts"
            :key="index"
            class="integration_box"
          >
            <div class="box_inner">
              <div class="content">
                <img
                  class="int_img"
                  :style="[isReplug ? { background: '#3988fc14' } : {}]"
                  :src="getServiceImage"
                  alt=""
                />

                <div class="connected"> Connected </div>

                <div
                  class="text d-flex justify-content-center align-items-center"
                >
                  <p>{{ getAccountDisplayText(account) }}</p>
                </div>

                <i
                  v-tooltip.top-center="'Reconnect'"
                  class="action_icon cs-refresh"
                  @click.prevent="
                    connectAccount(
                      type,
                      'reconnect',
                      isReplug
                        ? account.replug_id
                        : getAccountDisplayText(account)
                    )
                  "
                ></i>
                <i
                  v-tooltip.top-center="'Remove'"
                  class="action_icon icon-delete-cs"
                  @click.prevent="removeAccount(type, account._id)"
                ></i>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
