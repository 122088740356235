<template>
  <div class="table-responsive-sm mb-0" ref="datatable">

    <div
        v-if="!state"
        class="opacity-70 flex justify-center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'20px'"></clip-loader>
    </div>
    <table class="table" v-else>
      <thead>
      <tr>
        <th class="b-0"></th>
        <th
            class="b-0"
            :width="colWidth"
            v-for="(field, i) in active_competitors"
            :key="i"
        >
          {{ field.display_name }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(val,key,i) in fields">
        <!--            <template >-->
        <th class="text-left text-normal bg-light">
          <div class="d-flex  mr-auto align-items-center" style="max-width: 13.1rem;">
            {{ key }}
            <div class="ml-auto">
              <i
                  class="fa fa-info info_hover_sm"
                  v-b-popover.hover.html.topright="val['desc']"
              ></i>
            </div>
          </div>
        </th>
        <td v-for="(obj, ig_id, j) in active_competitors" :key="'col-' + j">
          <p
              class="max_width_content"
              v-tooltip="getToolTip(ig_id,val)"
              style="font-size: 0.875rem"
          >
            <template v-if="val['key'] != 'change_in_followers' && getMetricValue(ig_id, val) != '-'">{{getMetricValue(ig_id,val)}} ({{ getToolTip(ig_id, val) }})</template>
            <template v-else>{{ getMetricValue(ig_id, val) }}</template>
            </p>
        </td>
        <!--            </template>-->
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style lang="less">
.width-margin {
  max-width: 170px;
}

.column-width {
  width: 14%;
}
</style>
<script>
import api_ops from "./api_ops";
import analyticsUtilsMixin from "@src/modules/analytics/components/common/analyticsUtilsMixin";
import moment from "moment";
export default {
  name: 'ComparativeTable',
  mixins: [analyticsUtilsMixin, api_ops],
  props: ['reload', 'selected_competitors', 'time_period', 'selected_account_id'],
  watch: {
    reload(newVal, oldVal) {
      this.tableData = {}
      this.state = false
      this.active_competitors = {}
      this.fetchRankingData()
    },

  },
  mounted() {
    this.fetchRankingData()
  },
  created() {
    window.addEventListener('resize', this.getColWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getColWidth)
  },
  methods: {
    fetchRankingData() {
      let our = {}
      let theirs = this.selected_competitors.filter((obj) => {
        if (obj.competitor_id == this.selected_account_id) {
          our = obj
        } else {
          return obj
        }
      })
      theirs = theirs.sort((a, b) => a.display_name > b.display_name ? 1 : -1)
      let competitors = [our, ...theirs]
      competitors.forEach((obj) => {
        if (obj['enabled'] && obj.hasOwnProperty('last_analytics_updated_at')) {
          this.active_competitors[obj['competitor_id']] = obj
        }
      })
      this.getColWidth()
      let promises = []
      this.cancelPendingRequest('getTableMetrics')
      let ids = Object.keys(this.active_competitors);
      let promise = this.getIgTableMetrics(ids, this.time_period)
      this.requestCancelPromise['getTableMetrics'].push(promise[0])
      promises.push(promise[1])
      promise[1].then((res) => {
        console.log('tableres', res)
        res.data.data.forEach((obj) => {
          this.tableData[obj.ig_id] = obj
        })
      })
      Promise.all(promises).then(() => {
        ids.forEach((id) => {
          let promise = this.getIgMaxFansChange([id], this.time_period)
          this.requestCancelPromise['getTableMetrics'].push(promise[0])
          promises.push(promise[1])
          promise[1].then((res) => {
            console.log('fanres', res)
            if (res.data.data.length > 0) {
              if (!this.tableData.hasOwnProperty(res.data.data[0].ig_id)) {
                console.log('vrooom')
                this.tableData[res.data.data[0].ig_id] = {}
              }
              this.tableData[res.data.data[0].ig_id]['change_in_followers'] = res.data.data[0]['change_in_followers']
              this.tableData[res.data.data[0].ig_id]['followers_timestamp'] = res.data.data[0]['timestamp']
              this.tableData = {...this.tableData}

            }
          })
        })
      })
      Promise.all(promises).then(() => {

        this.state = true
      })
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
    getColWidth(e) {
      if (Object.keys(this.active_competitors).length) {
        this.colWidth =
            Math.floor(
                this.$refs.datatable.clientWidth / (Object.keys(this.active_competitors).length + 1)
            ) - 5
      } else {
        this.colWidth = Math.floor(this.$refs.datatable.clientWidth / 7) - 5
      }
    },
    getToolTip(ig_id, val) {
      let value = this.tableData.hasOwnProperty(ig_id) ? (this.tableData[ig_id].hasOwnProperty(val['tooltip']) ? this.tableData[ig_id][val['tooltip']] : '-') : '-'
      if (value != '-') {
        if (val['type'] == 'day') {
          let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", 'Sunday']
          value = days[parseInt(value) - 1]

        } else if (val['type'] == 'hour') {
          let hours = ['12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM']
          value = hours[parseInt(value)]
        } else if (val['type'] == 'date') {
          value = moment(value).format("MMM DD,YYYY")
        }
      }
      let ac_val = this.getMetricValue(ig_id, val)
      value = ac_val == "-" ? '-' : value
      return value
    },
    getMetricValue(ig_id, val) {
      let value = (this.tableData.hasOwnProperty(ig_id) ? (this.tableData[ig_id].hasOwnProperty(val['key']) ? this.intToString(this.tableData[ig_id][val['key']]) : '-') : '-').toString()
      value = value == "0" ? '-' : value
      return value
    }
  },
  data() {
    return {
      tableData: {},
      requestCancelPromise: {},
      active_competitors: {},
      state: false,
      colWidth: 10,
      fields: {
        'Posts the most on (Day) ': {
          'desc': '<span class="font-0-75rem">The field represents the day of week on which a certain page posts the most on, along with the post count on that specific day.</span>',
          'key': 'd_posts',
          'tooltip': 'max_post_day',
          'type': 'day'
        },
        'Posts the most on (Hour) ': {
          'desc': '<span class="font-0-75rem">The field represents the hour of day on which a certain page posts the most on, along with the post count on that specific hour.</span>',
          'key': 'h_posts',
          'tooltip': 'max_post_hour',
          'type': 'hour'
        },
        'Max Post Engagement (Day) ': {
          'desc': '<span class="font-0-75rem">The field represents Max post Engagement on posts posted with in a specific day of week (within applied filter). It helps identify some post going viral and on which day.</span>',
          'key': 'd_engagements',
          'tooltip': 'max_eng_day',
          'type': 'day'
        },
        'Max Post Engagement (Hour) ': {
          'desc': '<span class="font-0-75rem">The field represents Max post Engagement on posts posted with in a specific hour of day window (within applied filter). It helps identify some post going viral and on which Hour of Day.</span>',
          'key': 'h_engagements',
          'tooltip': 'max_eng_hour',
          'type': 'hour'
        },
        'Max Change in Fans (Date) ': {
          'desc': '<span class="font-0-75rem">The field represents Max change in fans(increment or decrement) on a certain day. The field represents the occurrence of some event that might have caused increment or decrement in fans. <span class="text-red-700"> The data will be visible after 2 days of adding competitor. </span> </br>PS: Hover on values to see date</span>',
          'key': 'change_in_followers',
          'tooltip': 'followers_timestamp',
          'type': 'date'
        }
      }
    }
  }
}
</script>
