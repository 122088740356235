<template>
  <div class="analytics-report-wrapper">
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Twitter'"
          :current="1"
          :total="total"
        ></report-header>
        <div class="analytics-section-header mb-2">
          <h2>Twitter Page Performance Summary</h2>
          <p
            >View your key profile performance metrics from the reporting
            period.</p
          >
        </div>
        <!-- Summary Section -->

        <div class="row">
          <div class="col-sm-4 mb-4">
            <SummaryCard
              v-if="
                metrics.aggregations &&
                typeof metrics.aggregations.total_posts != 'undefined' &&
                metrics.aggregations.total_posts.value != null
              "
              title="Posts"
              variant="rollup"
              :total="metrics.aggregations.total_posts.value"
              :reach="
                getReachCurrentPrevious(
                  metrics.aggregations.total_posts.value,
                  metrics.previous_aggregations.total_posts.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  metrics.aggregations.total_posts.value,
                  metrics.previous_aggregations.total_posts.value
                )
              "
            />
          </div>
          <div class="col-sm-4 mb-4">
            <SummaryCard
              v-if="
                metrics.aggregations &&
                typeof metrics.aggregations.total_engagement != 'undefined' &&
                metrics.aggregations.total_engagement.value != null
              "
              title="Engagements"
              :total="metrics.aggregations.total_engagement.value"
              :reach="
                getReachCurrentPrevious(
                  metrics.aggregations.total_engagement.value,
                  metrics.previous_aggregations.total_engagement.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  metrics.aggregations.total_engagement.value,
                  metrics.previous_aggregations.total_engagement.value
                )
              "
              variant="rollup"
            />
          </div>
          <div class="col-sm-4 mb-4">
            <SummaryCard
              v-if="
                metrics.aggregations &&
                typeof metrics.aggregations.total_following != 'undefined' &&
                metrics.aggregations.total_following.value != null
              "
              title="Following"
              :total="metrics.aggregations.total_following.value"
              :reach="
                getReachCurrentPrevious(
                  metrics.aggregations.total_following.value,
                  metrics.previous_aggregations.total_following.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  metrics.aggregations.total_following.value,
                  metrics.previous_aggregations.total_following.value
                )
              "
              variant="rollup"
            />
          </div>
          <div class="col-sm-4">
            <SummaryCard
              v-if="
                metrics.aggregations &&
                typeof metrics.aggregations.total_followers_count !=
                  'undefined' &&
                metrics.aggregations.total_followers_count.value != null
              "
              title="Followers"
              :total="metrics.aggregations.total_followers_count.value"
              :reach="
                getReachCurrentPrevious(
                  metrics.aggregations.total_followers_count.value,
                  metrics.previous_aggregations.total_followers_count.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  metrics.aggregations.total_followers_count.value,
                  metrics.previous_aggregations.total_followers_count.value
                )
              "
              variant="rollup"
            />
          </div>
          <div class="col-sm-4">
            <SummaryCard
              v-if="
                metrics.aggregations &&
                typeof metrics.aggregations.total_retweets != 'undefined' &&
                metrics.aggregations.total_retweets.value != null
              "
              title="Retweets"
              :total="metrics.aggregations.total_retweets.value"
              :reach="
                getReachCurrentPrevious(
                  metrics.aggregations.total_retweets.value,
                  metrics.previous_aggregations.total_retweets.value
                ) + '%'
              "
              :reachUp="
                getReachUpCurrentPrevious(
                  metrics.aggregations.total_retweets.value,
                  metrics.previous_aggregations.total_retweets.value
                )
              "
              variant="rollup"
            />
          </div>
        </div>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Twitter Published Posts With Engagement </h2>
              <p>See how your audience grew during the reporting period.</p>
            </div>
            <SplineBasicChart
              v-if="
                metrics.published_posts_with_engagement &&
                metrics.published_posts_with_engagement.time_line &&
                !(
                  isEmptyBucket(
                    metrics.published_posts_with_engagement.posts
                  ) &&
                  isEmptyBucket(
                    metrics.published_posts_with_engagement.engagements
                  )
                )
              "
              :categories="metrics.published_posts_with_engagement.buckets"
              :series="
                setSeriesForPublishedPostsEngagements(
                  metrics.published_posts_with_engagement
                )
              "
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>

          <h2>Net Publish Post with Engagement</h2>
          <div class="row">
            <template
              v-if="
                (metrics.aggregations.total_engagement &&
                  metrics.aggregations.total_engagement.value) ||
                (metrics.aggregations.avg_engagements &&
                  metrics.aggregations.avg_engagements.value)
              "
            >
              <div class="col-6 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.total_posts != 'undefined' &&
                    metrics.aggregations.total_posts.value != null
                  "
                  title="Number of Posts"
                  :total="metrics.aggregations.total_posts.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.total_posts.value,
                      metrics.previous_aggregations.total_posts.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.total_posts.value,
                      metrics.previous_aggregations.total_posts.value
                    )
                  "
                  variant="rollup"
                />
              </div>
              <div class="col-6 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.avg_engagements !=
                      'undefined' &&
                    metrics.aggregations.avg_engagements.value != null
                  "
                  title="Avg Engagements (Likes + RTs/Post)"
                  :total="metrics.aggregations.avg_engagements.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.avg_engagements.value,
                      metrics.previous_aggregations.avg_engagements.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.avg_engagements.value,
                      metrics.previous_aggregations.avg_engagements.value
                    )
                  "
                  variant="rollup"
                />
              </div>
            </template>

            <template v-else>
              <div class="col-6 summary-card-wrapper">
                <SummaryCard
                  title="Number of Posts"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
              <div class="col-6 summary-card-wrapper">
                <SummaryCard
                  title="Avg Engagements (Likes + RTs/Post)"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Twitter'"
          :current="2"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Publishing Behavior</h2>
              <p
                >View the different types of posts you published during the
                selected time period.</p
              >
            </div>
            <ColumnChart
              v-if="
                metrics.fans &&
                metrics.fans.timeLine &&
                !(
                  isEmptyBucket(metrics.fans.favourites) &&
                  isEmptyBucket(metrics.fans.retweets)
                )
              "
              :categories="metrics.fans.timeLine"
              :series="setSeriesForEngagements(metrics.fans)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Engagement Rollup</h2>

          <div class="row">
            <template
              v-if="
                metrics.aggregations &&
                (metrics.aggregations.total_engagement.value ||
                  metrics.aggregations.total_retweets.value ||
                  metrics.aggregations.favorites.value)
              "
            >
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.total_engagement !=
                      'undefined' &&
                    metrics.aggregations.total_engagement.value != null
                  "
                  title="Total Engagement"
                  :total="metrics.aggregations.total_engagement.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.total_engagement.value,
                      metrics.previous_aggregations.total_engagement.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.total_engagement.value,
                      metrics.previous_aggregations.total_engagement.value
                    )
                  "
                  variant="rollup"
                />
              </div>
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.total_retweets != 'undefined' &&
                    metrics.aggregations.total_retweets.value != null
                  "
                  title="Retweet"
                  :total="metrics.aggregations.total_retweets.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.total_retweets.value,
                      metrics.previous_aggregations.total_retweets.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.total_retweets.value,
                      metrics.previous_aggregations.total_retweets.value
                    )
                  "
                  variant="rollup"
                />
              </div>
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.favorites != 'undefined' &&
                    metrics.aggregations.favorites.value != null
                  "
                  title="Likes"
                  :total="metrics.aggregations.favorites.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.favorites.value,
                      metrics.previous_aggregations.favorites.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.favorites.value,
                      metrics.previous_aggregations.favorites.value
                    )
                  "
                  variant="rollup"
                />
              </div>
            </template>
            <template v-else>
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="Total Engagement"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="Retweet"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
              <div class="col-6 col-sm-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="Likes"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Twitter'"
          :current="3"
          :total="total"
        ></report-header>
        <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
          <div class="analytics-section-header">
            <h2>Post Density - Daily</h2>
          </div>
          <ColumnChart
            v-if="
              metrics.content_distribution_parsing &&
              metrics.content_distribution_parsing.time_line &&
              !isEmptyBucket(metrics.content_distribution_parsing.distribution)
            "
            :categories="metrics.content_distribution_parsing.time_line"
            :series="setSeriesForDensity(metrics.content_distribution_parsing)"
          />
          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>

        <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
          <div class="analytics-section-header">
            <h2>Engagement by day/time</h2>
          </div>
          <HeatmapChart
            v-if="
              metrics.best_time_for_content &&
              !isLastEmptyBucket(metrics.best_time_for_content)
            "
            :series="setSeriesForHeatMap(metrics.best_time_for_content)"
          />
          <div v-else class="flex-center-center" style="height: 90%">
            <div class="analytics-no-data-found">
              <img
                src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                alt=""
              />
              <p> No Data Found!</p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Twitter'"
          :current="4"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Audience Growth </h2>
              <p
                >View the different types of posts you published during the
                selected time period.</p
              >
            </div>
            <SplineBasicChart
              v-if="
                metrics.audience &&
                metrics.audience.time_line &&
                !(
                  isEmptyBucket(metrics.audience.followers) &&
                  isEmptyBucket(metrics.audience.following)
                )
              "
              :categories="metrics.audience.time_line"
              :series="setSeriesForAudienceGrowth(metrics.audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Audience Growth Rollup</h2>
          <div class="row">
            <template
              v-if="
                metrics.aggregations &&
                (metrics.aggregations.total_followers_count.value ||
                  metrics.aggregations.total_engagement.value ||
                  (metrics.statistics.length &&
                    metrics.statistics.friends_count.value))
              "
            >
              <div class="col-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.total_followers_count !=
                      'undefined' &&
                    metrics.aggregations.total_followers_count.value != null
                  "
                  title="Total Followers"
                  :total="metrics.aggregations.total_followers_count.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.total_followers_count.value,
                      metrics.previous_aggregations.total_followers_count.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.total_followers_count.value,
                      metrics.previous_aggregations.total_followers_count.value
                    )
                  "
                  variant="rollup"
                />
              </div>
              <div class="col-6 mb-4 summary-card-wrapper">
                <SummaryCard
                  v-if="
                    metrics.aggregations &&
                    typeof metrics.aggregations.total_following !=
                      'undefined' &&
                    metrics.aggregations.total_following.value != null
                  "
                  title="People that you are following"
                  variant="rollup"
                  :total="metrics.aggregations.total_following.value"
                  :reach="
                    getReachCurrentPrevious(
                      metrics.aggregations.total_following.value,
                      metrics.previous_aggregations.total_following.value
                    ) + '%'
                  "
                  :reachUp="
                    getReachUpCurrentPrevious(
                      metrics.aggregations.total_following.value,
                      metrics.previous_aggregations.total_following.value
                    )
                  "
                />
              </div>
            </template>

            <template v-else>
              <div class="col-4 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="Total Followers"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
              <div class="col-4 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="Followers"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
              <div class="col-4 mb-4 summary-card-wrapper">
                <SummaryCard
                  title="People that you are following"
                  :total="0"
                  reach="0%"
                  variant="rollup"
                />
              </div>
            </template>
          </div>
        </div>

        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Awareness through Mentions</h2>
              <p
                >View the different types of posts you published during the
                selected time period.</p
              >
            </div>
            <SplineBasicChart
              v-if="
                metrics.publish_posts_with_mentions &&
                metrics.publish_posts_with_mentions.time_line &&
                !(
                  isEmptyBucket(metrics.publish_posts_with_mentions.posts) &&
                  isEmptyBucket(metrics.publish_posts_with_mentions.engagements)
                )
              "
              :categories="metrics.publish_posts_with_mentions.time_line"
              :series="
                setSeriesForAwarenessThroughMentions(
                  metrics.publish_posts_with_mentions
                )
              "
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Twitter'"
          :current="5"
          :total="total"
        ></report-header>
        <div>
          <section class="analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Twitter Top Posts</h2>
              <p
                >Review your top posts published during the selected time
                period, based on the post’s lifetime performance.</p
              >
            </div>
            <template v-if="metrics.top3Posts.length <= 0">
              <div class="analytics-no-data-found">
                <img
                  src="../../assets/imgs/no_data_images/no_post_found.svg"
                  alt=""
                />
                <p
                  >You do not have any posts published in the selected time
                  period.</p
                >
              </div>
            </template>
            <div v-else class="row">
              <template v-for="i in 3">
                <div :key="i" class="col-4">
                  <template v-if="metrics.top3Posts.length >= i">
                    <PostCard
                      :post="metrics.top3Posts[i - 1]"
                      platform_type="twitter"
                    />
                  </template>
                  <template v-else>
                    <!--  No More Posts   -->
                    <div class="analytics-post-card">
                      <div class="analytics-post-card__header">
                        <div class="analytics-post-card__header-left">
                          <div class="analytics-post-card__header-img">
                            <SkeletonBox
                              class="skeletonBox--noanimcation"
                              height="2.8rem"
                              width="2.8rem"
                              radius="50%"
                            />
                          </div>
                          <div class="analytics-post-card__header-detail">
                            <div
                              class="d-flex"
                              style="flex-direction: column; margin-left: 10px"
                            >
                              <div class="mb-1"> No More Post </div>
                              <SkeletonBox
                                class="skeletonBox--noanimcation"
                                radius=".2rem"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="analytics-post-card__header-right"
                          style="filter: grayscale(100%); opacity: 0.6"
                        >
                          <!-- Dynamic Icon bases on props -->
                          <i class="far fa-external-link-square-alt"></i>
                        </div>
                      </div>

                      <div class="analytics-post-card__content">
                        <div class="mb-1">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="100%"
                            radius=".2rem"
                          />
                        </div>
                        <div class="mb-2">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            radius=".2rem"
                          />
                        </div>
                        <div class="mb-2">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="100%"
                            height="260px"
                            radius=".2rem"
                          />
                        </div>
                      </div>

                      <ul class="analytics-post-card__content-ele">
                        <li v-for="i in 3" :key="i">
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="40%"
                            radius=".2rem"
                          />
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            width="1.1rem"
                            radius=".2rem"
                          />
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'
import ColumnChart from '@src/modules/analytics/views/common/Infographics/ColumnChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'
import HeatmapChart from '@src/modules/analytics/views/common/Infographics/HeatmapChart'
import PostCard from '@src/modules/analytics/views/common/PostCard'
import StraightLineChart from '@src/modules/analytics/views/common/Infographics/StraightLineChart'
import StatsCard from '@src/modules/analytics/views/common/StatsCard'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  mixins: [analyticsUtilsMixin],
  data () {
    return {
      total: 5,
      legends: []
    }
  },
  props: ['metrics'],
  components: {
    ReportHeader,
    AnalyticsFilterBar,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    SkeletonBox,
    ColumnCrossHairChart,
    SplineBasicChart,
    LineBasicChart,
    PieInnerChart,
    PostCard,
    StatsCard,
    StraightLineChart,
    ColumnChart,
    HeatmapChart
  },
  computed: {},
  methods: {
    isEmptyBucket (arr) {
      if (Array.isArray(arr)) {
        return arr.reduce((a, b) => a + b, 0) === 0
      } else {
        return true
      }
    },
    isLastEmptyBucket (arr) {
      if (Array.isArray(arr)) {
        return arr.reduce((a, b) => a + b[2], 0) === 0
      } else {
        return true
      }
    },
    setSeriesForPublishedPostsEngagements (dataContainer) {
      return [
        {
          name: 'Posts',
          data: dataContainer.posts,
          color: '#ffe202'
        },
        {
          name: 'Engagements',
          data: dataContainer.engagements,
          color: '#2fe095'
        }
      ]
    },
    setSeriesForAwarenessThroughMentions (dataContainer) {
      return [
        {
          name: 'Total No. of Posts',
          data: dataContainer.posts,
          color: '#6173fc'
        },
        {
          name: 'Total No. of Mentions',
          data: dataContainer.engagements,
          color: '#26282c'
        }
      ]
    },
    setSeriesForAudienceGrowth (dataContainer) {
      return [
        {
          name: 'Total Followers',
          data: dataContainer.followers,
          color: '#ffe202'
        },
        {
          name: 'Total Following',
          data: dataContainer.following,
          color: '#2fe095'
        }
      ]
    },
    setSeriesForEngagements (dataContainer) {
      return [
        {
          name: 'Retweets',
          data: dataContainer.retweets,
          color: '#26282c'
        },
        {
          name: 'Likes',
          data: dataContainer.favourites,
          color: '#6173fc',
          type: 'spline'
        }
      ]
    },
    setSeriesForDensity (dataContainer) {
      return [
        {
          name: 'Posts',
          data: dataContainer.distribution,
          color: '#ffe202'
        }
      ]
    },
    setSeriesForHeatMap (dataContainer) {
      return [
        {
          name: '',
          borderWidth: 1.3,
          borderColor: '#FFFFFF',
          data: dataContainer,
          dataLabels: {
            enabled: false
          }
        }
      ]
    },
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    }
  }
}
</script>
