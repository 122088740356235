<template>
  <div :class="{ open: toggle }" class="prevent_click filter_sidebar">
    <div
      class="filter_sidebar_inner"
      :class="{ loader_overlay_with_loader: getInfluencerLoaders.filter_count }"
    >
      <beat-loader
        :color="'#436aff'"
        v-if="getInfluencerLoaders.filter_count"
      ></beat-loader>
      <div class="main_head d-flex align-items-center">
        <div class="head_left">
          <p>Filter Influencers</p>
        </div>

        <div class="head_right ml-auto">
          <span
            class="mr-1 clear-btn"
            data-cy="instagram_influencer_sidebar_filter_clear"
            @click.prevent="clearInstagramFilters()"
            >Clear</span
          >
          <i @click="changeSidebarStatus()" class="cs-cross"></i>
        </div>
      </div>

      <div class="filter_box filter_discovery_type filter_status">
        <div class="head">
          <div class="box_left">
            <p>
              <i class="cs-setting-bar"></i>
              <span>Type</span>
            </p>
          </div>
        </div>
        <div class="filter_content">
          <ul class="inner" data-cy="instagram_influencer_category">
            <li class="checkbox_input_image">
              <input
                :checked="$parent.isInfluencerTypeSelected('Blogger')"
                @change="$parent.changeInfluencerCategoryType('Blogger')"
                data-cy="instagram_influencer_category_blogger"
                value="Blogger"
                type="checkbox"
                id="blogger"
              />
              <label for="blogger" class="checkbox_right">
                <p
                  >Bloggers
                  <span
                    class="checkbox_counter"
                    data-cy="instagram_influencer_category_blogger_count"
                    v-if="
                      influencerFilters.influencers_category_stats &&
                      influencerFilters.influencers_category_stats.Blogger
                    "
                    >{{
                      influencerFilters.influencers_category_stats.Blogger
                    }}</span
                  >
                </p>
              </label>
            </li>
            <li class="checkbox_input_image">
              <input
                :checked="$parent.isInfluencerTypeSelected('Influencer')"
                @change="$parent.changeInfluencerCategoryType('Influencer')"
                type="checkbox"
                id="influencer"
                data-cy="instagram_influencer_category_influencers"
                value="Influencer"
              />
              <label for="influencer" class="checkbox_right">
                <p
                  >Influencers
                  <span
                    class="checkbox_counter"
                    data-cy="instagram_influencer_category_influencers_count"
                    v-if="
                      influencerFilters.influencers_category_stats &&
                      influencerFilters.influencers_category_stats.Influencer
                    "
                  >
                    {{
                      influencerFilters.influencers_category_stats.Influencer
                    }}</span
                  >
                </p>
              </label>
            </li>
            <li class="checkbox_input_image">
              <input
                :checked="$parent.isInfluencerTypeSelected('Company')"
                @change="$parent.changeInfluencerCategoryType('Company')"
                type="checkbox"
                id="company"
                data-cy="instagram_influencer_category_company"
                value="Company"
              />
              <label for="company" class="checkbox_right">
                <p
                  >Companies
                  <span
                    class="checkbox_counter"
                    data-cy="instagram_influencer_category_company_count"
                    v-if="
                      influencerFilters.influencers_category_stats &&
                      influencerFilters.influencers_category_stats.Company
                    "
                  >
                    {{
                      influencerFilters.influencers_category_stats.Company
                    }}</span
                  >
                </p>
              </label>
            </li>
            <li class="checkbox_input_image">
              <input
                :checked="$parent.isInfluencerTypeSelected('Journalist')"
                @change="$parent.changeInfluencerCategoryType('Journalist')"
                type="checkbox"
                data-cy="instagram_influencer_category_journalist"
                value="Journalist"
                id="journalist"
              />
              <label for="journalist" class="checkbox_right">
                <p
                  >Journalists
                  <span
                    class="checkbox_counter"
                    data-cy="instagram_influencer_category_journalist_count"
                    v-if="
                      influencerFilters.influencers_category_stats &&
                      influencerFilters.influencers_category_stats.Journalist
                    "
                  >
                    {{
                      influencerFilters.influencers_category_stats.Journalist
                    }}</span
                  >
                </p>
              </label>
            </li>
            <li class="checkbox_input_image">
              <input
                :checked="$parent.isInfluencerTypeSelected('Regular Person')"
                @change="$parent.changeInfluencerCategoryType('Regular Person')"
                type="checkbox"
                data-cy="instagram_influencer_category_person"
                value="Regular Person"
                id="regular_person"
              />
              <label for="regular_person" class="checkbox_right">
                <p
                  >Regular People
                  <span
                    class="checkbox_counter"
                    data-cy="instagram_influencer_category_person_count"
                    v-if="
                      influencerFilters.influencers_category_stats &&
                      influencerFilters.influencers_category_stats[
                        'Regular Person'
                      ]
                    "
                  >
                    {{
                      influencerFilters.influencers_category_stats[
                        'Regular Person'
                      ]
                    }}</span
                  >
                </p>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter_box filter_discovery_type filter_status">
        <div class="head">
          <div class="box_left">
            <p>
              <i class="cs-world-fill"></i>
              <span>Countries</span>
            </p>
          </div>
        </div>
        <div class="filter_content">
          <ul class="inner" data-cy="instagram_influencer_locations">
            <template v-for="country in countries">
              <li
                class="checkbox_input_image"
                v-if="
                  (influencerFilters.location_stats &&
                    influencerFilters.location_stats[
                      country.code.toUpperCase()
                    ]) ||
                  getInstagramInfluencerFilters.location.indexOf(
                    country.code
                  ) !== -1
                "
              >
                <input
                  @change="$parent.getInfluencers('instagram')"
                  :data-cy="'instagram_influencer_location_' + country.code"
                  type="checkbox"
                  v-model="getInstagramInfluencerFilters.location"
                  :value="country.code"
                  :id="'country_' + country.code"
                />
                <label :for="'country_' + country.code" class="checkbox_right">
                  <p
                    >{{ country.display }}
                    <span
                      class="checkbox_counter"
                      :data-cy="
                        'instagram_influencer_location_count_' + country.code
                      "
                      v-if="
                        influencerFilters.location_stats &&
                        influencerFilters.location_stats[
                          country.code.toUpperCase()
                        ]
                      "
                    >
                      {{
                        influencerFilters.location_stats[
                          country.code.toUpperCase()
                        ]
                      }}</span
                    >
                  </p>
                </label>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div class="filter_box filter_discovery_type filter_status">
        <div class="head">
          <div class="box_left">
            <p>
              <i class="cs-flag"></i>
              <span>Languages</span>
            </p>
          </div>
        </div>
        <div class="filter_content">
          <ul class="inner" data-cy="instagram_influencer_languages">
            <template v-for="language in languages">
              <li
                class="checkbox_input_image"
                v-if="
                  (influencerFilters.language_stats &&
                    influencerFilters.language_stats[language.code]) ||
                  getInstagramInfluencerFilters.languages.indexOf(
                    language.code
                  ) !== -1
                "
              >
                <input
                  @change="$parent.getInfluencers('instagram')"
                  :data-cy="'instagram_influencer_language_' + language.code"
                  type="checkbox"
                  v-model="getInstagramInfluencerFilters.languages"
                  :value="language.code"
                  :id="'language' + language.code"
                />

                <label :for="'language' + language.code" class="checkbox_right">
                  <p
                    >{{ language.display }}
                    <span
                      class="checkbox_counter"
                      :data-cy="
                        'instagram_influencer_language_count_' + language.code
                      "
                      v-if="influencerFilters.language_stats[language.code]"
                    >
                      {{ influencerFilters.language_stats[language.code] }}
                    </span>
                  </p>
                </label>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { influencerTypes } from '../../../store/mutation-types'

export default {
  data () {
    return {
      languages: [
        {
          display: 'English',
          code: 'en'
        },
        {
          display: 'Spanish',
          code: 'es'
        },
        {
          display: 'German',
          code: 'de'
        },
        {
          display: 'Japanesse',
          code: 'ja'
        },
        {
          display: 'Swedish',
          code: 'sv'
        },
        {
          display: 'Dutch',
          code: 'nl'
        },
        {
          display: 'Turkish',
          code: 'tr'
        },
        {
          display: 'French',
          code: 'fr'
        }
      ],
      countries: [
        {
          display: 'Argentina',
          code: 'ar'
        },
        {
          display: 'Australia',
          code: 'au'
        },
        {
          display: 'Canada',
          code: 'ca'
        },
        {
          display: 'Chile',
          code: 'cl'
        },
        {
          display: 'Colombia',
          code: 'co'
        },
        {
          display: 'France',
          code: 'fr'
        },
        {
          display: 'Germany',
          code: 'de'
        },
        {
          display: 'Italy',
          code: 'it'
        },
        {
          display: 'India',
          code: 'in'
        },
        {
          display: 'indonesia',
          code: 'id'
        },
        {
          display: 'Japan',
          code: 'jp'
        },
        {
          display: 'Nigeria',
          code: 'ng'
        },
        {
          display: 'Pakistan',
          code: 'pk'
        },
        {
          display: 'Spain',
          code: 'es'
        },
        {
          display: 'Sweden',
          code: 'se'
        },
        {
          display: 'United Kingdom',
          code: 'gb'
        },
        {
          display: 'United States',
          code: 'us'
        }
      ]
    }
  },
  props: {
    toggle: {},
    influencerFilters: {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders', 'getInstagramInfluencerFilters'])
  },
  methods: {
    changeSidebarStatus () {
      this.$parent.toggle = false
    },
    clearInstagramFilters () {
      this.$store.commit(
        influencerTypes.CLEAR_INSTAGRAM_INFLUENCERS_SIDEBAR_FILTER
      )
      const query = Object.assign({}, this.$route.query)
      delete query.type
      this.$router.push({ query: query })
      // this.$parent.getInfluencers('instagram')
    }
  }
}
</script>

<style scoped></style>
