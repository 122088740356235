<template>
  <div class="table_filter d-flex align-items-center">
    <div class="f_left">
      <a
        class="btn btn-studio-theme-space btn-size-small mr-1"
        @click.prevent="
          createArticleAutomationProcess(
            type === 'Blog'
              ? 'saveArticleBlogAutomation'
              : 'saveArticleSocialAutomation'
          )
        "
      >
        <i class="icon_left add_icon far fa-plus"> </i>
        <span>New Campaign</span>
      </a>
      <template v-if="getArticleAutomationListing.selected.length > 0">
        <div class="dropdown option_dropdown default_style_dropdown">
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="align-middle text">Select Operation</span>
            <i class="align-middle cs-angle-down icon_last"></i>
          </div>

          <div class="dropdown-menu dropdown-menu-left">
            <ul class="inner">
              <li
                class="list_item"
                @click.prevent="
                  bulkOperationDialog({ state: 'Active', type: type })
                "
              >
                <a class="dropdown-item">Active</a>
              </li>
              <li
                class="list_item"
                @click.prevent="
                  bulkOperationDialog({ state: 'Inactive', type: type })
                "
              >
                <a class="dropdown-item">Inactive</a>
              </li>
              <li
                class="list_item"
                @click.prevent="
                  bulkOperationDialog({ state: 'Delete', type: type })
                "
              >
                <a class="dropdown-item">Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>

    <div class="f_right ml-auto d-flex">
      <div
        class="platform_filters_planner prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown"
      >
        <PlatformFilters
          :dropdown-position="'dropdown-menu-right'"
          :type="type"
          source="article"
        ></PlatformFilters>
      </div>

      <div class="ml-1 search-bar-input">
        <div v-if="type === 'Blog'" class="search-bar-inner">
          <input
            v-model="getArticleBlogAutomationListing.search"
            type="text"
            placeholder="Search for campaign..."
            @keyup.enter="$store.dispatch('fetchArticleToBlogAutomations')"
          />
          <button
            class="search_btn"
            @click.prevent="$store.dispatch('fetchArticleToBlogAutomations')"
          >
            <i class="icon-Search"></i>
          </button>
        </div>

        <div v-else class="search-bar-inner">
          <input
            v-model="getArticleSocialAutomationListing.search"
            type="text"
            placeholder="Search for campaign..."
            @keyup.enter="$store.dispatch('fetchArticleToSocialAutomations')"
          />
          <button
            class="search_btn"
            @click.prevent="$store.dispatch('fetchArticleToSocialAutomations')"
          >
            <i class="icon-Search"></i>
          </button>
        </div>
      </div>

      <!--<div class="search_input with_icon_right d-inline-block ml-2">-->

      <!--<div class="search_inner" v-if="type === 'Blog'">-->
      <!--<input type="text" placeholder="Search for campaign..."-->
      <!--v-model="getArticleBlogAutomationListing.search"-->
      <!--v-on:keyup.enter="$store.dispatch('fetchArticleToBlogAutomations')">-->
      <!--<button class="search_btn input_icon search_icon"-->
      <!--@click.prevent="$store.dispatch('fetchArticleToBlogAutomations')">-->
      <!--<i class="icon-Search"></i>-->
      <!--</button>-->
      <!--</div>-->

      <!--<div class="search_inner" v-else>-->
      <!--<input type="text" placeholder="Search for campaign..."-->
      <!--v-model="getArticleSocialAutomationListing.search"-->
      <!--v-on:keyup.enter="$store.dispatch('fetchArticleToSocialAutomations')">-->
      <!--<button class="search_btn input_icon search_icon"-->
      <!--@click.prevent="$store.dispatch('fetchArticleToSocialAutomations')">-->
      <!--<span class="icon"></span>-->
      <!--</button>-->
      <!--</div>-->

      <!--</div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
import PlatformFilters from '../../../../publish/components/PlatformFilters'

export default {
  components: {
    PlatformFilters,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],

  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getArticleBlogAutomationListing',
      'getArticleSocialAutomationListing',
      'getArticleAutomationListing',
    ]),
  },
  methods: {
    bulkOperationDialog(payload) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to ${payload.state.toLowerCase()} your campaigns?`,
          {
            title: 'Perform Bulk Operation',
            ...swalAttributes(),
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch('bulkOperationArticleAutomation', payload)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    },
  },
}
</script>
