<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl py-2">
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        {{ account.name }}
        <span
          v-if="account.headline"
          class="block font-normal text-xs text-gray-800"
        >
          {{ account.headline }}
        </span>
        <span class="block font-normal text-xs text-gray-800">
          <span v-if="executionTime.date">{{
            getWorkspaceTimeZoneTime(executionTime.date, 'YYYY-MM-DD HH:mm:ss')
              | publishedOnlyDateWithWeekdayAndMonth
          }}</span>
          <span v-else
            >{{ getWorkspaceTimezonCurrentTime('D MMM YYYY') }}.</span
          >
        </span>
      </div>
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
          src="@assets/img/integration/linkedin-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="getDescription" class="items-center px-3 py-2">
      <span class="whitespace-pre-wrap" v-html="getDescription"></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        Load More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <!-- used inline style where tailwind base classes are missing -->
    <div class="w-100 h-100">
      <div v-if="detail.url && detail.image.length > 0" class="">
        <div
          class="flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
          :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
        >
          <img
            class="max-w-full max-h-full opacity-0 w-full"
            :src="detail.image[0]"
            alt=""
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
        <div
          v-if="detail.website || detail.title"
          class="bg-[#EEF3F8] w-full h-max py-2 px-3 overflow-hidden"
        >
          <div
            v-if="detail.title"
            class="font-medium text-[0.9rem] leading-5 truncate"
            >{{ detail.title }}</div
          >
          <div
            v-if="detail.website"
            class="block text-gray-700 text-sm mt-0.5"
            >{{ getHostOnly(detail.website) }}</div
          >
        </div>
      </div>
      <div
        v-else-if="detail.image && detail.image.length > 0"
        class="flex flex-wrap relative overflow-hidden"
      >
        <template v-for="(image, index) in detail.image">
          <div
            v-if="index <= 4"
            :key="index"
            class="flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
            :class="{ 'img-five': detail.image.length >= 5 && index > 1 }"
            :style="{ 'background-image': 'url(' + image + ')' }"
            @click="handleImagePreview(index)"
          >
            <span
              v-if="detail.image.length > 5 && index === 4"
              class="block w-full h-full bg-[##00000066]"
            ></span>
            <img
              class="max-w-full max-h-full w-full opacity-0"
              :src="image"
              alt=""
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
          <div
            v-else
            :key="index"
            class="absolute p-2 text-2xl text-bold text-white"
            style="transform: translate(-50%, -50%); left: 83%; top: 75%"
            >+{{ detail.image.length - 5 }}</div
          >
        </template>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white"
      >
        <img
          class="w-full min-h-48 max-h-[500px]"
          :src="detail.video.thumbnail"
          alt=""
        />
        <i
          class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid"
          style="transform: translate(-50%, -50%)"
          @click.prevent="displayFile('video', detail.video.link, 0)"
        ></i>
      </div>
    </div>
    <div
      v-if="type === 'composer'"
      class="flex items-center mx-2 px-1 py-2 reaction-icons justify-between text-base text-gray-800"
      style="border-top: 1px solid #dee2e6"
    >
      <span
        ><i class="far fa-thumbs-up fa-flip-horizontal"></i
        ><span class="font-bold text-sm"> Like</span></span
      >
      <i class="far fa-comment-lines"
        ><span class="font-bold text-sm"> Comment</span>
      </i>
      <i class="far fa-share"><span class="font-bold text-sm"> Share</span> </i>
      <i class="far fa-paper-plane"
        ><span class="font-bold text-sm"> Send</span>
      </i>
    </div>
    <div
      v-if="comment"
      class="flex items-center justify-center px-2 py-3"
      style="border-top: 1px solid #dee2e6"
    >
      <div>
        <img
          class="rounded-full object-cover border border-solid border-black"
          width="30"
          height="30"
          :src="account.image"
          alt=""
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </div>
      <div class="ml-2 w-full rounded-lg bg-cs-slate py-2 px-2">
        {{ comment }}
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'LinkedinPreview',
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    executionTime: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      loadMore: true,
      charLimit: 210,
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
  },
  methods: {
    getHostOnly(url) {
      const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
      return urlParts[1] ? urlParts[1] : ''
    },
    changeLoadMore(val) {
      this.loadMore = val
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type: type,
        media: media,
        index: index,
      })
      this.$bvModal.show('display-file-modal')
    },
    handleImagePreview(index) {
      if (this.type === 'feed_view')
        this.$emit('preview-images', {
          images: this.detail.image,
          index: index,
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
.img-five {
  flex: 1 0 calc(18.33% - 10px);
  border: 1px solid white;
}
</style>
