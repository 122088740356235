<template>
  <b-modal
    id="save-caption-modal"
    size="lg"
    title="Saved Captions"
    title-class="text-xl font-bold"
    body-class="px-7 py-4 min-h-[31rem]"
    hide-header
    hide-footer
    @hidden="handleModalClose"
  >
    <!--    Modal Header -->
    <SavedCaptionHeader
      :loading-captions="loadingCaptions"
      :view-mode="viewMode"
      :change-view-mode="changeViewMode"
      :caption-to-update="captionToUpdate"
      :handle-modal-close="handleModalClose"
    />
    <!--    Captions Listing -->
    <template v-if="viewMode === 'list'">
      <SearchAddCaption
        :loading-captions="loadingCaptions"
        :change-view-mode="changeViewMode"
        :filter-captions="filterCaptions"
      />
      <CaptionsNotFound
        v-if="displayCaptionNotFound"
        :change-view-mode="changeViewMode"
      />
      <div v-else class="h-[28.313rem] overflow-y-auto">
        <div v-for="(caption, caption_idx) in captions" :key="caption_idx">
          <CaptionItem
            :caption="caption"
            :remove-caption-from-list="removeCaptionFromList"
            :set-caption-to-update="setCaptionToUpdate"
            :update-and-add-text-to-editor="updateAndAddTextToEditor"
          />
        </div>
      </div>
    </template>

    <!--    Add or Update Caption -->
    <template v-if="viewMode === 'create' || viewMode === 'edit'">
      <AddEditCaption
        :view-mode="viewMode"
        :caption-title="initialCaptionTitle"
        :caption-text="initialCaptionDescription"
        :work-space-id="store.getters.getActiveWorkspace._id"
        :change-view-mode="changeViewMode"
        :caption-to-update="captionToUpdate"
      />
    </template>
  </b-modal>
</template>

<script>
// libraries
import {
  defineComponent,
  computed,
  inject,
  onMounted,
  ref,
  reactive,
} from 'vue'
import { useStore } from '@state/base'

// components
import {
  AddEditCaption,
  CaptionItem,
  SearchAddCaption,
  SavedCaptionHeader,
  CaptionsNotFound,
} from '@src/modules/composer_v2/components/EditorBox/SaveCaptions/index'

// constants
import { EventBus } from '@common/lib/event-bus'
import proxy from '@common/lib/http-common'
import {
  fetchAllCaptionsApiUrl,
  usedCaptionApiUrl,
} from '@src/config/api-utils'

export default defineComponent({
  name: 'SaveCaptionModal',
  components: {
    CaptionsNotFound,
    SavedCaptionHeader,
    SearchAddCaption,
    CaptionItem,
    AddEditCaption,
  },
  props: {
    handleAiCaptionOutput: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = inject('root')
    const store = useStore()
    const editorText = ref('')
    const captionArray = ref([])
    const captions = ref([])
    const loadingCaptions = ref(false)
    const viewMode = ref('list')
    const editorType = ref('')
    const oldComposer = ref(false)
    const captionToUpdate = reactive({})

    // mounted
    onMounted(() => {
      EventBus.$on(
        'save-caption-modal',
        ({ caption, isOldComposer = false, source = 'Common' }) => {
          root.$bvModal.show('save-caption-modal')
          editorType.value = source
          editorText.value = caption
          oldComposer.value = isOldComposer
          fetchAllCaptions()
        }
      )
    })

    // computed properties
    /**
     *  if captions array is empty show caption not found message
     * @type {ComputedRef<unknown>}
     */
    const displayCaptionNotFound = computed(
      () =>
        (!captions.value || captions.value?.length === 0) &&
        !loadingCaptions.value
    )
    /**
     * Pass the existing caption title if updating an existing caption, otherwise pass the empty text
     * @type {ComputedRef<*|string>}
     */
    const initialCaptionTitle = computed(() =>
      viewMode.value === 'edit' ? captionToUpdate?.value.title : ''
    )

    /**
     * Pass the existing caption description if updating an existing caption, otherwise pass the text written in the editor.
     * @type {ComputedRef<*|Ref<UnwrapRef<string>>>}
     */
    const initialCaptionDescription = computed(() =>
      viewMode.value === 'edit'
        ? captionToUpdate?.value.description
        : editorText.value
    )

    // methods

    /**
     * change view type (list,create,edit)
     * @param viewType
     */
    const changeViewMode = (viewType) => {
      viewMode.value = viewType
      if (viewType === 'list') {
        fetchAllCaptions()
      }
    }

    /**
     * delete caption from list
     * @param captionId
     */
    const removeCaptionFromList = (captionId) => {
      captions.value = captions.value.filter((item) => item._id !== captionId)
    }
    /**
     * Fetch all saved captions
     * @returns {Promise<void>}
     */
    const fetchAllCaptions = async () => {
      try {
        const payload = {
          params: {
            workspace_id: store.getters.getActiveWorkspace._id,
          },
        }
        captionArray.value = []
        captions.value = []
        loadingCaptions.value = true
        const response = await proxy.get(fetchAllCaptionsApiUrl, payload)
        captions.value = response?.data?.captions
        captionArray.value = response?.data?.captions
        loadingCaptions.value = false
      } catch (error) {
        loadingCaptions.value = false
        await store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }

    /**
     * hide modal
     */
    const handleModalClose = () => {
      root.$bvModal.hide('save-caption-modal')
      captions.value = []
      loadingCaptions.value = false
      viewMode.value = 'list'
    }
    /**
     * set caption object which user select to update
     * @param caption
     */
    const setCaptionToUpdate = (caption) => {
      captionToUpdate.value = caption
      changeViewMode('edit')
    }
    /**
     * Clicking on a caption updates the caption count and adds the caption text to the editor.
     * @param caption
     * @returns {Promise<void>}
     */
    const updateAndAddTextToEditor = async (caption) => {
      try {
        await proxy.put(`${usedCaptionApiUrl}${caption?._id}`)
        props.handleAiCaptionOutput(
          caption?.description,
          oldComposer.value,
          editorType.value
        )
        handleModalClose()
      } catch (error) {
        await store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }
    /**
     * show filtered captions
     * @param e
     */
    const filterCaptions = (e) => {
      if (!e.target.value) {
        captions.value = captionArray.value
        return
      }
      captions.value = captionArray.value?.filter((caption) =>
        caption?.title?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      )
    }

    return {
      handleModalClose,
      captions,
      fetchAllCaptions,
      loadingCaptions,
      changeViewMode,
      viewMode,
      editorText,
      store,
      displayCaptionNotFound,
      removeCaptionFromList,
      captionToUpdate,
      setCaptionToUpdate,
      initialCaptionDescription,
      initialCaptionTitle,
      updateAndAddTextToEditor,
      filterCaptions,
      captionArray,
      oldComposer,
      editorType,
    }
  },
})
</script>
