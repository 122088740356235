import { instagramSharingTypes, instagramTypes } from '../mutation-types'

const getDefaultInstagramSharingVideo = () => {
  return {
    name: '',
    title: '',
    mime_type: '',
    link: '',
    duration: '00:00:00.00',
    size: 0,
    thumbnail: '',
    thumbnails_suggestions: [],
    converted_video: '',
    converted_link: '',
    converted_mime_type: '',
    converted_size: 0
  }
}

const getDefaultInstagramSharingDetails = () => {
  return {
    message: '',
    image: [],
    video: getDefaultInstagramSharingVideo(),
    url: '',
    location: {
      id: '',
      name: ''
    }
  }
}

export default {
  state: {
    selection: {
      message: '',
      image: [],
      video: getDefaultInstagramSharingVideo(),
      url: '',
      location: {
        id: '',
        name: ''
      }
    },
    cancelledLink: '',
    progressBar: {
      image: 0,
      numberOfImages: 0,
      video: 0
    },
    loaders: {
      upload_sharing_image: { status: false, position: -1 }
    }
  },
  getters: {
    getInstagramSharingDetails: (state) => {
      return state.selection
    },

    getInstagramSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getInstagramSharingProgressBar: (state) => {
      return state.progressBar
    },

    getInstagramSharingLoaders: (state) => {
      return state.loaders
    }
  },
  actions: {},
  mutations: {
    [instagramTypes.SET_LOCATION] (state, location) {
      state.selection.location = location
    },

    [instagramTypes.SET_SHARING_MESSAGE] (state, message) {
      state.selection.message = message
    },

    [instagramTypes.SET_SHARING_URL] (state, url) {
      state.selection.url = url
    },

    [instagramTypes.SET_SHARING_CANCELLED_PREVIEW] (state, link) {
      state.cancelledLink = link
    },

    [instagramTypes.SET_SHARING_DETAILS] (state, details) {
      state.cancelledLink = ''
      if (details == null) {
        state.selection = getDefaultInstagramSharingDetails()
        return false
      }
      if (details.image.constructor !== Array) { details.image = details.image.length ? [details.image] : [] }
      state.selection = details
    },

    [instagramTypes.SET_SHARING_IMAGE_PERCENTAGE] (state, percent) {
      state.progressBar.image = percent
    },

    [instagramTypes.SET_SHARING_IMAGE] (state, image) {
      state.selection.image = image
    },

    [instagramTypes.SET_SHARING_NUMBER_OF_IMAGES] (state, images) {
      state.progressBar.numberOfImages = images
    },

    [instagramTypes.SET_SHARING_PREVIEW] (state, preview) {
      if (preview) {
        state.selection.image = preview.image
        return false
      }
      state.selection.image = []
      state.selection.url = ''
    },

    [instagramTypes.SET_SHARING_VIDEO] (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultInstagramSharingVideo()
      }
    },

    [instagramTypes.SET_SHARING_VIDEO_INITIALIZATION] (state) {
      state.selection.image = []
      state.selection.url = ''
      state.selection.video = getDefaultInstagramSharingVideo()
      state.progressBar.video = 0
    },

    [instagramTypes.SET_SHARING_VIDEO_PERCENTAGE] (state, percent) {
      state.progressBar.video = percent
    },

    [instagramTypes.SET_SHARING_VIDEO_THUMBNAIL] (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    [instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER] (state, loader) {
      state.loaders.upload_sharing_image = loader
    },

    [instagramTypes.SET_DEFAULT_SHARING] (state) {
      state.selection = getDefaultInstagramSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        numberOfImages: 0,
        video: 0
      }
    },
    [instagramSharingTypes.RESET_INSTAGRAM_SHARING_DETAILS] (state) {
      state.selection = getDefaultInstagramSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        numberOfImages: 0,
        video: 0
      }
    }
  }
}
