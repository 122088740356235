<template>
  <!-- The Modal -->

  <div class="modal fade history_version_modal" id="history_version_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          v-if="getVersionsHistoryLoader"
          class="modal_body height d-flex flex-column"
        >
          <div class="close_btn">
            <i class="cs-cross" data-dismiss="modal"></i>
          </div>
          <div class="h-100 pt-5">
            <clip-loader color="#4165ed" :size="'20px'"></clip-loader>
          </div>
        </div>
        <div class="modal_body" v-else-if="getPlanVersionHistory.length === 0">
          <div class="close_btn">
            <i class="cs-cross" data-dismiss="modal"></i>
          </div>

          <div class="not_found_text pt-2">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <h2>No version history found</h2>
                <p>You don't have any version histroy.</p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="modal_body d-flex height">
          <div class="modal_left">
            <div class="article_content">
              <div class="article_wrapper">
                <div class="article_head">
                  <h2 class="title">{{
                    getPlanVersionHistory[getVersionHistoryActiveIndex].title
                  }}</h2>
                  <div
                    class="author_detail"
                    v-if="
                      getPlanVersionHistory[getVersionHistoryActiveIndex][
                        'user'
                      ] &&
                      getPlanVersionHistory[getVersionHistoryActiveIndex][
                        'user'
                      ]['firstname'] &&
                      getPlanVersionHistory[getVersionHistoryActiveIndex][
                        'user'
                      ]['lastname']
                    "
                  >
                    <p>
                      By
                      <span
                        >{{
                          getPlanVersionHistory[getVersionHistoryActiveIndex][
                            'user'
                          ]['firstname']
                        }}
                        {{
                          getPlanVersionHistory[getVersionHistoryActiveIndex][
                            'user'
                          ]['lastname']
                        }}</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="article_content_inner"
                  v-html="
                    getPlanVersionHistory[getVersionHistoryActiveIndex]
                      .description
                  "
                ></div>
              </div>
            </div>
          </div>

          <div class="modal_right">
            <div class="right_head d-flex align-items-center">
              <h3>Version History ({{ getPlanVersionHistory.length }})</h3>
              <i class="cs-cross" data-dismiss="modal"></i>
            </div>
            <div class="history_list">
              <div
                class="h_item d-flex align-items-center"
                v-for="(version, index) in getPlanVersionHistory"
                @click.prevent="changeVersion(index)"
              >
                <div class="profile_picture d-flex align-items-start">
                  <div class="picture_block picture_block_background">
                    <div
                      v-if="version['user']['image']"
                      class="img"
                      :style="{
                        background: 'url(' + version['user']['image'] + ')',
                      }"
                    ></div>
                    <div
                      v-else
                      class="img"
                      style="
                        background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                      "
                    ></div>

                    <!--<img v-if="version['user']['image']" :src="version['user']['image']" alt="">-->
                    <!--<img v-else src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg" alt="">-->
                  </div>
                  <div class="text_block">
                    <p class="text">{{
                      getWorkspaceTimeZoneTime(
                        version.updated_at,
                        'MMM DD, hh:mm a'
                      )
                    }}</p>
                    <p
                      v-if="
                        version['user']['firstname'] &&
                        version['user']['lastname']
                      "
                      class="sub_text"
                      >{{ version['user']['firstname'] }}
                      {{ version['user']['lastname'] }}</p
                    >
                  </div>
                </div>
                <div
                  class="version"
                  v-if="index === getVersionHistoryActiveIndex"
                >
                  <p>Current Version</p>
                </div>
              </div>
            </div>
            <div class="h_btn">
              <button
                class="btn active"
                :disabled="!getPlanControl"
                @click.prevent="
                  restoreVersion(
                    getPlanVersionHistory[getVersionHistoryActiveIndex]
                  )
                "
                >Restore this version</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { composer } from '../../../composer/store/composer-mutation-type'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'

export default {
  computed: {
    ...mapGetters([
      'getPlanVersionHistory',
      'getVersionsHistoryLoader',
      'getVersionHistoryActiveIndex',
      'getPlanControl'
    ])
  },

  created () {},
  methods: {
    ...mapActions([]),
    changeVersion (index) {
      this.$store.commit(composer.SET_VERSION_HISTORY_ACTIVE_INDEX, index)
    },

    restoreVersion (version) {
      this.$store.commit(blogPosting.SET_BLOG_POSTING_TITLE, version.title)
      this.setCKEditorHtml(version.description, true)
      $('#history_version_modal').modal('hide')
    }
  }
}
</script>
