<template id="managE_competitor">
  <div
      class="analytic-competitors-addform"
      :class="overviewFlag ? 'pointer-none' : ''"
  >

    <div class="analytic-competitors-addform__title mb-2">Your Pages</div>
    <div v-if="connectedAccounts.length === 0" class="input_field mb-2"><!----><!---->
      <div><span>You have not connected any account yet.</span><a href="" class="ml-2" style="text-decoration: underline;"
                                                                  @click.prevent="GoToSocial">Connect an
        account</a></div>
    </div>

    <div class="analytic-competitors-addform__search-wrapper">
      <b-dropdown
          ref="dropdown"
          variant="studio-theme"
          toggle-class="w-100"
          size="lg"
          class="
          studio-theme-dropdown
          studio-theme-dropdown--checkbox
          studio-theme-dropdown--contain-image
          w-100
        "
          :disabled="connectedAccounts.length === 0"
          no-caret
      >
        <template v-slot:button-content>
          <template v-if="connectedAccounts.length === 0">
            <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
            <span
                class="
                capitalize_text
                d-inline-flex
                justify_content_start
                text-left
                ml-3
              "
            >
              <small>No Account Connected</small>
            </span>
          </template>
          <template v-else>
            <div v-if="!selected_account" class="d-flex align-items-center">
              <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
              <span
                  class="
                  capitalize_text
                  d-inline-flex
                  justify_content_start
                  text-left
                  ml-3
                "
              >
                <small>Please Select An Account</small>
              </span>
            </div>
            <div
                v-else
                class="d-flex align-items-center justify-content-between"
                @click="disableSuggestion"
            >
              <div class="d-flex align-items-center">
                <span class="connected-accounts-images">
                  <div>
                    <img
                        class="selected"
                        :src="channelImage(selected_account, 'facebook')"
                        alt=""
                        @error="
                          $event.target.src =
                            'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                        "
                    />
                  </div>
                </span>
                <span
                    class="
                    capitalize_text
                    d-flex
                    flex-column
                    justify_content_start
                    text-left
                    ml-3
                  "
                >
                  {{ selected_account.name }}
                  <small>{{ selected_account.type }}</small>
                </span>
              </div>

              <i class="icon-dropdown-cs"></i>
            </div>
          </template>
        </template>

        <div v-for="(account, i) in connectedAccounts" :key="i">
          <b-dropdown-item @click="changeSelectedAccount(account)">
            <div class="field_group">
              <div class="checkbox_container">
                <label>
                  <div class="dropdown-img-wrapper">
                    <img
                        class="selected"
                        :src="channelImage(account, 'facebook')"
                        alt=""
                        @error="
                        $event.target.src = fallbackImagePreview(account.name[0])
                      "
                    />
                    <i :class="getIconClass('facebook')"></i>
                  </div>
                  <span class="dropdown-item-label">
                    <span>{{ account.name }}</span>
                    <small>{{ getProfileType("facebook") }}</small>
                  </span>
                </label>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <div class="analytic-competitors-addform__title mb-0 mt-4">Add Competitor(s)</div>
      <b-input-group class="mt-2">
        <template v-slot:prepend>
          <i class="far fa-search"></i>
        </template>

        <b-form-input
            v-model="value"
            :disabled="selected_account === null"
            type="text"
            debounce="500"
            placeholder="Enter competitor’s name here..."
            @click="searchKeyword(value)"
        ></b-form-input>
        <template v-if="enableSuggestion" v-slot:append>
          <clip-loader
              class="append"
              :color="'#5773fa'"
              :size="'22px'"
          ></clip-loader>
        </template>
      </b-input-group>

      <div
          v-on-clickaway="clearResults"
          class="analytic-competitors-addform__dropdown-wrapper"
      >
        <transition name="slide-bottom">
          <div
              v-if="results.length"
              class="analytic-competitors-addform__dropdown"
          >
            <div
                v-for="(result, i) in results"
                :key="i"
                :class="{ disable_suggestion: enableSuggestion }"
                @click="selectCompetitor(result)"
            >
              <div class="analytic-competitors-addform__dropdown-img">
                <img
                    :src="
                    'https://graph.facebook.com/' + result.id + '/picture'
                  "
                    :alt="result.name"
                />
              </div>
              <div class="analytic-competitors-addform__dropdown-name">
                {{ result.name }}<br>
                <small v-if="result.location && (result.location.city || result.location.country)">{{
                    (result.location.city ? result.location.city + ', ' : '') + result.location.country || ''
                  }}</small>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="analytic-competitors-addform__selected-wrapper">
      <div v-if="optionsFlag" class="analytic-competitors-addform__selected">
        <div
            v-for="(result, i) in getSelectedCompetitors()"
            v-if="getSelectedCompetitors().length > 0"
            :key="i"
        >
          <div class="d-flex align-items-center">
            <div class="analytic-competitors-addform__selected-img">
              <img :src="result.image" :alt="result.name"/>
            </div>
            <div class="analytic-competitors-addform__selected-name">
              {{ result.name }}
            </div>
          </div>
          <div
              class="analytic-competitors-addform__selected-remove"
              @click="removeSelected(i, result)"
          >
            <i class="far fa-close"></i>
          </div>
        </div>
      </div>
    </div>
    <div
        v-if="selected_account && !optionsFlag"
        class="opacity-70"
        style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'50px'"></clip-loader>
    </div>
    <div class="analytic-competitors-addform__button d-flex align-items-center">
      <div class="compose_warning_box manage_c_warning pr-4"><i class="far fa-exclamation-circle test"></i>
        <p>Only 5 competitors per page are allowed.</p></div>

      <button
          class="btn btn-studio-theme-space ml-auto"
          :disabled="
          selected_account === null ||
          !selectedCompetitors[selected_account.facebook_id] ||
          !selectedCompetitors[selected_account.facebook_id].length ||
          overviewFlag
        "
          @click="resolveRoute"
      >
        <span v-if="native !== 'true'">Save Changes</span>
        <span v-else>Fetch Analytics</span>
      </button>
      <div
          v-if="overviewFlag"
          class="opacity-70"
          style="display: inline-flex; vertical-align: middle"
      >
        <clip-loader
            class="ml-2"
            :color="'#5773fa'"
            :size="'22px'"
        ></clip-loader>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.disable_suggestion {
  pointer-events: none;
  opacity: 0.7;
}

.manage_c_warning {
  p {
    font-size: 15px;
    font-weight: 400;
    color: #b57e00;
  }

  i {
    margin: 0 0 0 10px;
    padding: 2px 8px 1px 5px;
  }
}
</style>

<script>
import {mapGetters} from 'vuex'
import proxy from '@common/lib/http-common'
import axios from 'axios'
import {
  addNewCompetitor,
  updateCompetitor,
  searchCompetitor,
  fetchFbCompetitors,
  delFbCompetitors
} from '@src/modules/analytics/config/api-utils'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  name: 'AddCompetitorFields',
  mixins: [analyticsUtilsMixin],
  props: {
    native: {
      type: String,
      default: '',
    },
    selectedCompetitor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      optionsFlag: null,
      value: '',
      results: [],
      existingCompetitors: {},
      selectedCompetitors: {},
      selected_account: null,
      enableSuggestion: false,
      overviewFlag: false,
      CancelToken: axios.CancelToken,
      proxy: axios,
      requestCancelPromise: {}
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    selectedCompetitorsIds() {
      return this.selectedCompetitors[this.selected_account.facebook_id].map(
          (selected) => selected.id
      )
    },
    connectedAccounts() {
      return this.getPlatformAccounts('facebook').sort((a, b) => (a.name > b.name) ? 1 : -1)
    }
  },
  watch: {
    value(newValue) {
      this.searchKeyword(newValue.trim())
    }
  },
  created() {
    // selecting available competitor by default in the drop down
    if (this.selectedCompetitor !== '') {
      this.selected_account = this.connectedAccounts.filter((obj) => obj.facebook_id === this.selectedCompetitor).sort((a, b) => (a.name > b.name) ? 1 : -1)[0]
      this.fetchCompetitors(this.selected_account.facebook_id, this.selected_account.workspace_id)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.results = []
    this.value = ''
  },
  methods: {
    resolveRoute() {
      // The function is responsible to commit user changes regarding CURD operations for competitors.
      const promises = []
      console.debug('selected_account', this.selected_account)
      this.overviewFlag = true
      console.log(this.existingCompetitors)
      const deletedCompetitors = []
      // following loop is generating a list of objects that already exists as competitors and are to be updated, upon saving.
      const updatedCompetitors = this.existingCompetitors[
          this.selected_account.facebook_id
          ].filter((dataObj) => {
        if (
            this.selectedCompetitors[this.selected_account.facebook_id].some(
                (sObj) => sObj.competitor_id === dataObj.competitor_id
            )
        ) {
          return dataObj
        } else {
          // following code is generating a list of competitors objects that are to be removed from user's competitors configurations.
          deletedCompetitors.push(dataObj)
        }
      })
      // defining competitors to be added/configured against a given facebook id.
      const addedCompetitors = this.selectedCompetitors[
          this.selected_account.facebook_id
          ].filter((dataObj) => {
        if (updatedCompetitors.some((sobj) => sobj.competitor_id === dataObj.competitor_id)) {
        } else {
          return dataObj
        }
      })
      console.log('f12deletedCompetitors', deletedCompetitors)
      console.log('f12deletedCompetitors', addedCompetitors)
      console.log('f12deletedCompetitors', updatedCompetitors)
      // following loop is responsible to make API calls for the objects that are to be deleted
      deletedCompetitors.forEach((dataObj) => {
        promises.push(
            proxy
                .put(delFbCompetitors, dataObj)
                .catch(() => {
                })
                .then((res) => {
                  console.log(res)
                })
        )
      })
      // following loop is responsible to make API calls for the objects that are to be updated
      updatedCompetitors.forEach((dataObj) => {
        promises.push(
            proxy
                .put(updateCompetitor, dataObj)
                .catch((res) => {
                  console.log(res)
                })
                .then((res) => {
                })
        )
      })
      // waiting for update and delete calls to be completed, once completed executing calls to add new competitors.
      Promise.all(promises).then(() => {
        promises.push(
            proxy
                .post(addNewCompetitor, {data: addedCompetitors})
                .catch((err) => {
                  console.log('compet', err.response, err.response.status)
                  // status code 426 represents maximum competitors add limit reached.
                  if (err.response.status === 426 && this.overviewFlag) {
                    this.alertMessage(err.response.data.message, 'error')
                    this.overviewFlag = false
                  }
                })
                .then((res) => {
                })
        )

        // closing current modal and emitting signal to reload existing overview page.
        Promise.all(promises).then(() => {
          if (this.overviewFlag) {
            this.alertMessage('Competitors updated successfully. Data for competitors will be updated/available within half an hour.', 'success')
            this.$router.push({
              name: 'facebookCompetitorOverview_v3',
              params: {accountId: this.selected_account._id}
            })
            if (this.native !== 'true') {
              this.$emit('updatedCompetitor')
            }
          }
        })
      })
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancle pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundency and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
        // The following chunk update request header with a valid auth token
        this.proxy.defaults.headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.getJWTToken
        }
      }
    },
    changeSelectedAccount(account) {
      this.optionsFlag = null
      this.selected_account = account
      this.fetchCompetitors(account.facebook_id, account.workspace_id)
    },
    searchKeyword(keyword) {
      this.enableSuggestion = true
      let cancel
      if (keyword.length > 1) {
        // call to cancel any pending request of the same category.
        this.cancelPendingRequest('search_keyword')
        this.proxy
            .get(searchCompetitor + `?page_name=${keyword}`, {
              cancelToken: new this.CancelToken(function executor(c) {
                cancel = c
              })
            })
            .catch((err) => {
              if (err.message === undefined) {
              } else {
                console.log('cancelled', err)
                this.results = []
                this.enableSuggestion = false
              }
            })
            .then((res) => {
              console.log(res)
              this.results = res.data.data.filter(function (val) {
                return val
              })
              this.enableSuggestion = false
            })
        this.requestCancelPromise.search_keyword.push(cancel)
        console.log(this.results)
      } else {
        this.results = []
        this.enableSuggestion = false
      }
    },
    clearResults() {
      this.results = []
    },
    selectCompetitor(competitor) {
      // The function is responsible to add competitor in view list searched by user.
      // ...
      // following condition is checking if the user has searched or added the selected pages as competitor.
      if (competitor.id === this.selected_account.facebook_id) {
        // in case user selects source page as competitors, showing error message.
        this.alertMessage('Adding Source Page as Competitor is Not Allowed!', 'error')
      } else {
        // creating object to be added as fb competitor
        competitor = {
          workspace_id: this.selected_account.workspace_id,
          type: 'Page',
          facebook_id: this.selected_account.facebook_id,
          competitor_id: competitor.id,
          name: competitor.name,
          added_by: this.selected_account.name,
          user_id: this.selected_account.user_id,
          image: 'https://graph.facebook.com/' + competitor.id + '/picture'
        }
        // checking if the object already exists in the list, if yes then dont add id again. else adding it in the list.
        const fbId = this.selected_account.facebook_id
        console.log(this.selectedCompetitors)
        if (this.selectedCompetitors[fbId]) {
          this.selectedCompetitors[fbId] = this.selectedCompetitors[fbId].filter(
              (compet) => {
                return compet.competitor_id !== competitor.competitor_id
              }
          )
          this.selectedCompetitors[fbId].push(competitor)
        } else {
          this.selectedCompetitors[fbId] = [competitor]
        }
        console.log(this.selectedCompetitors)
      }

      setTimeout(() => {
        this.value = ''
        this.clearResults()
      }, 200)
    },
    removeSelected(i, selected) {
      // the function is responsible to remove any competitors from list selected by user.
      // API Call to delete selected competitor
      this.selectedCompetitors[this.selected_account.facebook_id].splice(i, 1)
    },
    fetchCompetitors(fbId, wsId) {
      // The function is responsible to fetch configured competitors for a given/selected facebook page.
      // API call to fetch competitors for a given fb page.
      proxy
          .get(fetchFbCompetitors + `?facebook_id=${fbId}&workspace_id=${wsId}`)
          .then((res) => {
            const alias = []
            this.$nextTick(() => {
              res.data.competitors.forEach((obj) => {
                if (obj.competitor_id !== fbId) {
                  alias.push(obj)
                }
              })
              this.selectedCompetitors[fbId] = alias
              this.existingCompetitors[fbId] = [...alias]
              this.optionsFlag = true
              console.log(this.selectedCompetitors)
            })
          })
          .catch((err) => {
            console.log(err)
          })
    },
    getSelectedCompetitors() {
      console.log('this is a debug message', this.selectedCompetitor)
      // the function is responsible to return a list of competitor objects(fb) for the selected account
      if (
          this.selected_account &&
          this.selectedCompetitors[this.selected_account.facebook_id] &&
          this.selectedCompetitors[this.selected_account.facebook_id].length > 0
      ) {
        return this.selectedCompetitors[this.selected_account.facebook_id]
      }
      return []
    },
    GoToSocial() {
      // this function is responsible to redirect application to page where user add/update/del social accounts
      this.$router.push({name: 'social'})
    },
    disableSuggestion() {
      this.results = []
    }
  }
}
</script>
