<template>
  <div
    v-if="!isWorkspaceOnHold"
    :class="{
      'post_share_option post_share_option_social basic_form_inline mt-0 mb-5':
        hasPostShareClass,
      'rss-content-category': position === 'rss-listing',
    }"
  >
    <div class="input_field d-flex align-items-center">
      <div class="left_input"><p class="label">Select Content Category</p></div>
      <div class="right_input">
        <div class="input_field mt-0">
          <div
            style="min-width: 280px"
            class="field_group w-auto d-inline-block category_dropdown dropdown default_style_dropdown"
            :class="{
              'input-field-error input_validation': validations.category,
            }"
          >
            <div
              class="dropdown_header d-flex align-items-center"
              data-toggle="dropdown"
            >
              <span class="text" data-cy="select-category">
                <template v-if="!getCategoryId">
                  Select category from list
                </template>
                <template v-else>
                  <span
                    v-if="
                      selected_category &&
                      selected_category.hasOwnProperty('name')
                    "
                    class="text"
                  >
                    <span
                      class="ct_color"
                      :class="selected_category.color"
                    ></span>
                    <span class="ct_text" data-cy="existing-categories">{{
                      selected_category.name
                    }}</span>
                  </span>
                </template>
              </span>
              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="inner">
                <li
                  v-if="getContentCategoryList.length > 0 && getCategoryId"
                  class="clear-category-action"
                  @click="selectPublishSlotCategory(selected_category)"
                >
                  <span> Unselect Category </span>
                </li>
                <template
                  v-for="category in sortContentCategory(
                    getContentCategoryList,
                  )"
                  v-if="getContentCategoryList.length > 0"
                >
                  <li
                    v-if="
                      ($route.name === 'saveRssAutomation' ||
                        $route.name === 'saveEvergreenAutomationAccounts' ||
                        $route.name === 'saveBulkCsvAutomation') &&
                      category.color
                    "
                    v-tooltip.top-center="{
                      content: getGlobalCategoryText(category.category_state),
                      container: 'body',
                    }"
                    :value="category._id"
                    class="list_item_li"
                    :class="{
                      disable_content_category:
                        category.category_state === 'global',
                    }"
                    data-cy="content-catagory"
                    @click="selectPublishSlotCategory(category)"
                  >
                    <span class="d-flex">
                      <span class="ct_text">
                        <span class="ct_color" :class="category.color"></span>
                        {{ limitTextLength(category.name, 32) }}
                        <template v-if="category.category_state === 'global'"
                          >(Global)</template
                        >
                      </span>
                      <span class="ct_text ml-auto">
                        <template v-if="category.category_state === 'global'">
                          <i class="far fa-crown"></i>
                        </template>
                      </span>
                    </span>
                  </li>
                  <li
                    v-else
                    :value="category._id"
                    class="list_item_li"
                    :class="{
                      'content-category-item-selected':
                        selected_category &&
                        selected_category._id === category._id,
                    }"
                    @click="selectPublishSlotCategory(category)"
                  >
                    <span class="d-flex">
                      <span class="ct_text" data-cy="category-item">
                        <span class="ct_color" :class="category.color"></span>
                        {{ limitTextLength(category.name, 32) }}
                        <template v-if="category.category_state === 'global'"
                          >(Global)</template
                        >
                        <span class="content-category-selected">
                          <template
                            v-if="
                              selected_category &&
                              selected_category._id === category._id
                            "
                            ><i class="fas fa-check"></i
                          ></template>
                        </span>
                      </span>
                      <span class="ct_text ml-auto">
                        <template v-if="category.category_state === 'global'">
                          <i class="far fa-crown"></i>
                        </template>
                      </span>
                    </span>
                  </li>
                </template>

                <li
                  v-if="getContentCategoryList.length === 0"
                  class="no_text_li"
                >
                  No category added yet.
                </li>
              </ul>
            </div>
            <span v-if="validations.category" class="input-error">
              {{ messages.category }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import sortBy from 'lodash.sortby'
import { publish, social } from '../../../store/states/mutation-types'
import { rssTypes } from '../../../../automation/store/recipes/mutation-types'
import { evergreen } from '../../../../automation/store/automation-mutation-type'
import {EventBus} from "@common/lib/event-bus";

export default {
  props: {
    module: {
      default: 'publish'
    },
    position: ''
  },

  data () {
    return {
      validations: {
        category: false,
        weekdays: false
      },
      messages: {
        category: 'Please choose a category.',
        weekdays: 'Please choose a day for the slot.'
      },
      selected_category: {},
      selected_category_Name:{}
    }
  },
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getContentCategoryList',
      'getRssAutomationDetails',
      'getPublishTimeOptions',
      'getWorkspaces',
      'getCommonBoxOptions',
      'getEvergreenSelection',
      'getCsvAutomationSelection',
      'getAutomationScheduleOptions'
    ]),
    isWorkspaceOnHold () {
      return !!(
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.on_hold
      )
    },

    getCategoryIdValueOnly () {
      switch (this.module) {
        case 'publish':
          return this.getPublishSelection.content_category_id
        case 'rss_automation':
          return this.getRssAutomationDetails.content_category_id
        case 'evergreen_automation':
          return this.getEvergreenSelection.content_category_id
        case 'bulk_automation':
          return this.getCsvAutomationSelection.content_category_id
      }
    },
    // get the selection value depending on the module that we are using..

    getCategoryId () {
      // select the category

      if (!this.selected_category.hasOwnProperty('name')) {
        switch (this.module) {
          case 'rss_automation':
            if (this.getRssAutomationDetails.content_category_id) {
              const category = this.getContentCategoryList.find(
                (item) =>
                  item._id === this.getRssAutomationDetails.content_category_id
              )
              this.selected_category = category
            }
            break
          case 'evergreen_automation':
            console.debug('asd', this.getEvergreenSelection.content_category_id)
            if (this.getEvergreenSelection.content_category_id) {
              const category = this.getContentCategoryList.find(
                (item) =>
                  item._id === this.getEvergreenSelection.content_category_id
              )
              if (!category) {
                this.$store.commit(
                  evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE,
                  ''
                )
                this.getAutomationScheduleOptions.time_type = 'Custom'
                return ''
              }
              this.selected_category = category
            }
            break
          case 'bulk_automation':
            if (this.getCsvAutomationSelection.content_category_id) {
              const category = this.getContentCategoryList.find(
                (item) =>
                  item._id ===
                  this.getCsvAutomationSelection.content_category_id
              )
              this.selected_category = category
            }
            break
          case 'publish':
            if (this.getPublishSelection.content_category_id) {
              const category = this.getContentCategoryList.find(
                (item) =>
                  item._id === this.getPublishSelection.content_category_id
              )
              this.selected_category = category
            }
            break
        }
      }
      // get the value from the store.
      return this.getCategoryIdValueOnly
    },
    // do we need to add the class for the post share, depending on the module.
    hasPostShareClass () {
      switch (this.module) {
        case 'publish':
        case 'rss_automation':
        case 'evergreen_automation':
        case 'bulk_automation':
          return true
        default:
          return false
      }
    }
  },
  methods: {
    ...mapMutations(['SET_CSV_CONTENT_CATEGORY_ID']),
    getGlobalCategoryText (categoryState) {
      if (categoryState === 'global') {
        return "Global category can't be used to avoid duplicate content issue."
        // if (this.$route.name === 'saveRssAutomation') {
        //   return "Global category can't be used to avoid duplicate content issue."
        // } else if (this.$route.name === 'saveEvergreenAutomationAccounts') {
        //   return "Global category can't be used to avoid duplicate content issue."
        // }
        // return 'You cannot use global category in  Automation'
      }
      return ''
    },
    selectPublishSlotCategory (category) {
      if(category.category_state=== 'global' && this.module.includes('automation')){
        return
      }
      if(this.getUserRole === 'Approver') {
        this.alertMessage('Approver cannot change selected category','info')
        return
      }
      // console.debug(
      //   'select publish category isUnselect',
      //   category,
      //   this.getCategoryIdValueOnly
      // )
      console.log("category...............................?????????????",category.name);
      this.selected_category = category

      // value being mutated directly, that's why we are storing in the variable first.

      let categoryIdValue = category._id
      let categoryName=category.name
      // select or unselect the category
      if (this.getCategoryIdValueOnly) {
        if (this.getCategoryIdValueOnly === categoryIdValue) {
          categoryIdValue = null
          categoryName=null
          this.selected_category = {}
        }
      }

      // depending on the module, select the value in the store.

      console.debug(this.module)
      switch (this.module) {
        case 'publish':
          this.$store.commit(
            publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE,
            categoryIdValue,
          )
          this.$store.commit(
            evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME,
            categoryName
          )
          this.$set(this.getPublishTimeOptions, 'time_type', 'content_category')
          if (!categoryIdValue) {
            this.$set(this.getPublishTimeOptions, 'time_type', 'queued')
          }
          break
        case 'rss_automation':
          this.$store.commit(
            rssTypes.SET_RSS_AUTOMATION_CONTENT_CATEGORY_VALUE,
            categoryIdValue
          )
          this.$store.commit(
            evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME,
            categoryName
          )
          break
        case 'evergreen_automation':
          this.$store.commit(
            evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE,
            categoryIdValue,
            // categoryName
          )
           this.$store.commit(
            evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME,
            categoryName
          )
          break
        case 'bulk_automation':
          this.$store.commit('SET_CSV_CONTENT_CATEGORY_ID', categoryIdValue),
           this.$store.commit(
            evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME,
            categoryName
          )
          break
      }
    },
    sortContentCategory (data) {
      return sortBy(data, function (item) {
        return [item.name.toLowerCase()]
      })
    }
  },
  watch: {
    getCategoryId (value) {
      if (value && value.length > 3) {
        const category = this.getContentCategoryList.find(
          (item) => item._id === value
        )
        console.log('category', category, value)
        this.$store.commit(
          social.SET_CONTENT_CATEGORY_ACCOUNT_SELECTION,
          category
        )

        if(this.module === 'rss_automation' || this.module === 'evergreen_automation') this.$store.commit(social.SET_PINTEREST_ACCOUNT_SELECTION,[])

        if (
          !this.getCommonBoxOptions.status &&
          this.isGlobalContentCategorySelected
        ) {
          this.fetchGlobalCategoryChannels()
        }
      }
    }
  },
  mounted () {
    EventBus.$on('reset_content_category_selection', () => {
      this.selectPublishSlotCategory(this.selected_category)
    })
  }
}
</script>

<style lang="less">
.disable_content_category {
  opacity: 0.6;
  position: relative;
  cursor: not-allowed !important;
  &:hover {
    background: transparent !important;
  }
  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
  }
}

.clear-category-action {
  margin-bottom: 5px !important;
  span {
    -webkit-transition: all 0.2s ease-in !important;
    -moz-transition: all 0.2s ease-in !important;
    -o-transition: all 0.2s ease-in !important;
    transition: all 0.2s ease-in !important;
    display: flex;
    margin-bottom: 4px;
    padding: 7px 20px;
    font-size: 13px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    &:hover {
      background: #f5f9fc;
    }
  }
}
.content-category-selected {
  color: #26cb85;
  padding-left: 0.625rem;
}
.content-category-item-selected {
  -webkit-transition: all 0.2s ease-in !important;
  -moz-transition: all 0.2s ease-in !important;
  -o-transition: all 0.2s ease-in !important;
  transition: all 0.2s ease-in !important;
  background: #26cb8521;
  &:hover {
    background: #26cb8512 !important;
  }
}
</style>
