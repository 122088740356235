<template>
  <div class="article-list-view-container__inner">
    <div class="article-list-view-container__inner__image_section">
      <div
        v-if="post.categories"
        class="article-list-view-container__inner__categories"
      >
        <span
          v-for="(category, index) in post.categories"
          :key="index"
          class="tag capitalize"
          >{{ category }}</span
        >
        <!--<div class="article_type">
                <span v-if="post.read_time && post.read_time ==1 ">
                    Less than 1 Min. Read
                </span>
                        <span v-if="post.read_time && post.read_time >1 ">
                     {{post.read_time}} Min. Read
                </span> - <span v-if="post.has_abstract">Abstract Content</span>
                        <span v-else>Full Content</span></div>-->
      </div>
      <div
        v-lazy:background-image="backgroundImageURL(post)"
        class="article-list-view-container__inner__image"
      ></div>

      <div
        v-if="post.shared && post.shared_count"
        class="article-list-view-container__inner__shared"
      >
        <span>
          Shared to {{ post.shared_count }}
          <template v-if="post.shared_count === 1">channel</template>
          <template v-else>channels</template>
        </span>
      </div>
    </div>

    <div class="article-list-view-container__inner__content_section">
      <div
        class="article-list-view-container__inner__content_section__left_section"
      >
        <h2
          :title="limitTextLength(post.title, 0)"
          @click.prevent="routeToPreview"
          v-html="limitTextLength(post.title, 100)"
        ></h2>
        <div
          class="clear article-list-view-container__inner__content_section__left_section__details"
        >
          <p class="text_row">
            <a href="javascript:;">{{ trimURL(post.url_netloc) }}</a>

            <!--<a class="left_link" href="">Insight</a>
                                <a class="left_link" href="">All Articles</a>
                                <a class="left_link" href="">Block Globally</a>-->
          </p>
          <p class="text_row">{{ $filters.relative(post.post_date) }}</p>
          <p
            class="article-list-view-container__inner__content_section__left_section__author_information"
          >
            <template v-if="post.author">
              By <span style="color: #4e92ff" v-html="post.author"></span>
              <a
                v-if="post.twitter_handler"
                target="_blank"
                :href="getTwitterLink(post.twitter_handler)"
              >
                {{ post.twitter_handler }}</a
              >
              <a
                v-for="(account, index) in post.twitter_accounts"
                v-else-if="post.twitter_accounts && index < 1"
                target="_blank"
                :href="getTwitterLink(account)"
              >
                {{ account }}</a
              >
            </template>
            <template v-else-if="post.twitter_handler">
              By
              <a target="_blank" :href="getTwitterLink(post.twitter_handler)">{{
                post.twitter_handler
              }}</a>
            </template>
            <template
              v-else-if="
                post.twitter_accounts && post.twitter_accounts.length > 0
              "
            >
              By
              <a
                v-for="(account, index) in post.twitter_accounts"
                v-if="post.twitter_accounts && index < 1"
                target="_blank"
                :href="getTwitterLink(account)"
                >{{ account }}</a
              >
            </template>
          </p>
          <!--
                            Previous method commented.
                            <div class="author_detail" v-if="post.author">
                                 <p>By <span v-html="post.author"></span> <a v-if="post.twitter_accounts" v-for="account in post.twitter_accounts" href="">{{account}}</a></p>
                            </div>
                            -->
          <div
            class="article-list-view-container__inner__content_section__left_section__topics"
          >
            <template v-for="item in post.topics">
              <span
                v-for="(finalized_topic, index) in checkIfMultipleTopics(
                  item.label,
                )"
                :key="index"
                class="topic_tag"
              >
                {{ finalized_topic }}
              </span>
              <!--@click.prevent="changeCuratedTopicLabels(item, finalized_topic)"-->
            </template>
          </div>

          <div
            v-if="post.top_twitter_influencers"
            class="article-list-view-top-influencers"
          >
            <div
              v-if="post.total_influencers_shares"
              class="article-list-view-top-influencers__left"
            >
              <p
                ><i class="icon-profile-cs mr-2"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(post.total_influencers_shares)
                }}</strong>
                influencers</p
              >
            </div>
            <!--                              @click.prevent="showSharers(post.id)"-->
            <!--                              v-if="influencer.follower_count > 1000"-->
            <!--                              v-if="index <= 3"-->
            <div v-if="post.total_influencers_shares > 0" class="ml-3">
              <template
                v-for="(influencer, index) in post.top_twitter_influencers"
              >
                <a
                  v-tooltip="{
                    content: accountTooltipHtml('influencer', influencer),
                    classes: 'team_tooltip',
                  }"
                  target="_blank"
                  :href="'https://twitter.com/' + influencer.screen_name"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      background: 'url(' + influencer.profile_image + ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
              <!--                                    <div @click.prevent="showSharers(post.id)" class="rounded_more"-->
              <!--                                         v-if="post.top_twitter_influencers.length > 3 && post.total_influencers_shares - 4 > 0">-->
              <!--                                        +{{post.total_influencers_shares - 4}}-->
              <!--                                    </div>-->
            </div>
          </div>
        </div>
        <!-- <div class="similar_content"
                              @click.prevent="retrieveSimilarArticles(post.id, post._index)"
                              v-if="post.related_ids_count > 1">{{post.related_ids_count}}
                             similar articles
                         </div>-->
      </div>
      <div
        class="article-list-view-container__inner__content_section__right_section"
      >
        <div
          class="article-list-view-container__inner__content_section__right_section__signals"
        >
          <div
            class="article-list-view-container__inner__content_section__right_section__signals__inner"
          >
            <div
              class="popover-list-view-social-statistics article-list-view-container__inner__content_section__right_section__signals__inner__item"
            >
              <div class="name">
                <p>Engagement</p>
              </div>
              <div class="value">
                <h3>{{ intToString(calculateEngagements(post)) }}</h3>
              </div>

              <div
                class="popover-list-view-social-statistics__container top_center"
              >
                <div
                  class="popover-list-view-social-statistics__container__box"
                >
                  <div
                    class="popover-list-view-social-statistics__container__box__inner"
                  >
                    <div class="d-flex align-items-center">
                      <div
                        v-tooltip.top-center="'Facebook Shares'"
                        class="popover-list-view-social-statistics__container__box__inner__item"
                      >
                        <span class="icon_item">
                          <i class="fb cs-facebook"></i>
                        </span>
                        <span v-if="post.facebook_shares">{{
                          post.facebook_shares
                        }}</span>
                        <span v-else>0</span>
                      </div>
                      <div
                        v-tooltip.top-center="'Twitter Shares'"
                        class="popover-list-view-social-statistics__container__box__inner__item"
                      >
                        <span class="icon_item">
                          <i class="tw cs-twitter"></i>
                        </span>
                        <span v-if="post.twitter_shares">{{
                          post.twitter_shares
                        }}</span>
                        <span v-else>0</span>
                      </div>
                      <div
                        v-tooltip.top-center="'Pinterest Shares'"
                        class="popover-list-view-social-statistics__container__box__inner__item"
                      >
                        <span class="icon_item">
                          <i class="pin cs-pinterest"></i>
                        </span>
                        <span v-if="post.pins_shares">{{
                          post.pins_shares
                        }}</span>
                        <span v-else>0</span>
                      </div>
                      <div
                        v-tooltip.top-center="'Reddit Shares'"
                        class="popover-list-view-social-statistics__container__box__inner__item"
                      >
                        <span class="icon_item">
                          <i class="reddit cs-reddit"></i>
                        </span>
                        <span v-if="post.reddit_shares">{{
                          post.reddit_shares
                        }}</span>
                        <span v-else>0</span>
                      </div>
                      <!--<div class="engagement_stat">
                                                    <i class="fire_icon cs cs-icon-fire"></i>
                                                    <span>454</span>
                                                </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="post.velocity && post.velocity > 5"
              class="article-list-view-container__inner__content_section__right_section__signals__inner__item"
            >
              <div class="name">
                <p>Trending Score</p>
              </div>
              <div class="value">
                <h3>{{ intToString(post.velocity) }}</h3>
              </div>
            </div>
            <FeederSentimentIcon
              :sentiment="post.sentiment"
              :class="'article-list-view-container__inner__content_section__right_section__signals__inner__item'"
            ></FeederSentimentIcon>
          </div>
        </div>
        <div
          class="article-list-view-container__inner__content_section__right_section__action_buttons"
        >
          <!-- share buttons -->
          <b-dropdown
            id="dropdown-share-articles"
            variant="studio-icon-theme"
            class="studio-icon-theme-dropdown mr-3"
            dropleft
            no-caret
          >
            <template slot="button-content">
              <i
                v-tooltip="'Share this post'"
                class="far fa-share-alt icon"
              ></i>
              <b-badge
                v-if="post.shared_count"
                pill
                variant="light"
                class="ml-2"
                >{{ post.shared_count }}</b-badge
              >
            </template>
            <b-dropdown-item
                variant="studio-icon-theme"
                @click.prevent="shareToSocial(post.id)"
              ><i class="far fa-share"></i> Social Media
            </b-dropdown-item>
            <b-dropdown-item
              v-show="false"
              variant="studio-icon-theme"
              @click.prevent="routeToBlogPost(post.id)"
              ><i class="far fa-rss"></i> Blog Post
            </b-dropdown-item>
          </b-dropdown>

          <!-- favorite article button -->
          <div
            class="dropdown b-dropdown studio-icon-theme-dropdown mr-3 btn-group"
          >
            <template v-if="post.is_starred">
              <button
                class="btn btn-studio-icon-theme"
                style="margin-bottom: 0.5rem"
                @click.prevent="
                  EventBus.$emit('feeder_starred_action', {
                    post_id: post.id,
                    index: index,
                    state: 'unstarred',
                  })
                "
              >
                <i
                  v-tooltip="'Unfavorite'"
                  class="far fa-heart icon is-favorite"
                ></i>
                <span
                  :class="{ ' active': post.is_starred }"
                  class="icon_animate"
                ></span>
              </button>
            </template>
            <template v-else>
              <button
                class="btn btn-studio-icon-theme"
                style="margin-bottom: 0.5rem"
                @click.prevent="
                  EventBus.$emit('feeder_starred_action', {
                    post_id: post.id,
                    index: index,
                    state: 'starred',
                  })
                "
              >
                <i v-tooltip="'Favorite'" class="far fa-heart icon"></i>
              </button>
            </template>
          </div>

          <!-- archive article button -->
          <button
            id="dropdown-archive-articles"
            class="btn btn-studio-icon-theme post_archi_btn"
            style=""
          >
            <template v-if="isArchive">
              <i
                v-tooltip.top-center="'Unarchive Post'"
                class="far fa-archive icon is-archived"
                @click="
                  EventBus.$emit('feeder_unarchive_action', {
                    post_id: post.id,
                    index: index,
                    domain_uuid: post.domain_uuid,
                    state: 'unarchive',
                  })
                "
              ></i>
            </template>
            <template v-else>
              <i
                v-tooltip.top-center="'Archive Post'"
                class="far fa-archive icon"
                @click="
                  EventBus.$emit('feeder_archive_action', {
                    post_id: post.id,
                    index: index,
                    domain_uuid: post.domain_uuid,
                    state: 'archive',
                  })
                "
              ></i>
            </template>
          </button>

          <!-- more actions button-->
          <b-dropdown
            id="dropdown-actions-articles"
            variant="studio-icon-theme"
            dropleft
            class="studio-icon-theme-dropdown"
            no-caret
          >
            <template slot="button-content">
              <i
                v-tooltip.top-center="'More options'"
                class="cs-dots-h icon"
              ></i>
            </template>

            <b-dropdown-item
              variant="studio-icon-theme"
              target="_blank"
              :href="savePocketContent(post.url)"
              ><i class="fab fa-get-pocket"></i> Add to Pocket
            </b-dropdown-item>

            <b-dropdown-item
              v-show="false"
              v-if="getGridViewTotalInfluencers"
              variant="studio-icon-theme"
              @click.prevent="showSharers(post.id)"
              ><i class="fa fa-users-crown"></i> View Influencers
            </b-dropdown-item>
          </b-dropdown>

          <!--                            <div class="favorite_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">-->

          <!--                                <div class="dropdown_header" v-if="post.is_starred"-->
          <!--                                     @click.prevent="EventBus.$emit('feeder_starred_action', {post_id: post.id, index: index, state: 'unstarred'})">-->
          <!--                                    <button class="btn " v-tooltip.top-center="'Unstarred this post'">-->
          <!--                                        <i class="favorite_icon_orange far fa-heart d-block"></i>-->
          <!--                                    </button>-->
          <!--                                </div>-->
          <!--                                <div class="dropdown_header" data-toggle="dropdown"-->
          <!--                                     v-show="!post.is_starred ">-->
          <!--                                    <button v-if="!post.is_starred"-->
          <!--                                            v-tooltip.top-center="'Starred this post'"-->
          <!--                                            class="btn light_gray"-->
          <!--                                            @click.prevent="EventBus.$emit('feeder_starred_action', {post_id: post.id, index: index, state: 'starred'})"-->
          <!--                                    >-->
          <!--                                        <i class="favorite_icon far fa-heart d-block"></i>-->
          <!--                                    </button>-->
          <!--                                </div>-->

          <!--                            </div>-->

          <!--                            <div class="article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">-->
          <!--                                <div class="dropdown_header" data-toggle="dropdown">-->
          <!--                                    <button class="btn light_gray" v-tooltip.top-center="'Share this post'">-->
          <!--                                        <i class="far fa-share-alt d-block"-->
          <!--                                           :class="{'shared_posts': post.shared}"></i>-->
          <!--                                        <span class="circle_count"-->
          <!--                                              v-if="post.shared_count">{{post.shared_count}}</span>-->
          <!--                                    </button>-->
          <!--                                </div>-->
          <!--                                <div class="dropdown-menu dropdown-menu-right">-->

          <!--                                    <ul class="inner">-->
          <!--                                        <li class="list_item_li "-->
          <!--                                            @click.prevent="routeToShareSocial()">-->
          <!--                                            <i class="far fa-share"></i>-->
          <!--                                            <span class="icon_text">Social Media</span>-->
          <!--                                        </li>-->
          <!--                                        <li class="list_item_li d-none " @click.prevent="routeToBlogPost(post.id)">-->
          <!--                                            <i class="far fa-rss"></i>-->
          <!--                                            <span class="icon_text">Blog Post</span>-->
          <!--                                        </li>-->
          <!--                                    </ul>-->

          <!--                                </div>-->
          <!--                            </div>-->

          <!--                            <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">-->
          <!--                                <div class="dropdown_header">-->
          <!--                                    <template v-if="!isArchive">-->
          <!--                                        <button class="btn blue" v-tooltip.top-center="'Archive Post'"-->
          <!--                                                @click.prevent="EventBus.$emit('feeder_archive_action', {post_id: post.id, index: index, domain_uuid: post.domain_uuid, state: 'archive'})">-->
          <!--                                            <i class="groups2_icon far fa-archive d-block">-->
          <!--                                            </i>-->

          <!--                                        </button>-->
          <!--                                    </template>-->
          <!--                                    <template v-else>-->
          <!--                                        <button class="btn blue" v-tooltip.top-center="'UnArchive Post'"-->
          <!--                                                @click.prevent="EventBus.$emit('feeder_unarchive_action', {post_id: post.id, index: index, domain_uuid: post.domain_uuid, state: 'unarchive'})">-->
          <!--                                            <i class="groups2_icon far fa-archive d-block">-->
          <!--                                            </i>-->

          <!--                                        </button>-->
          <!--                                    </template>-->

          <!--                                </div>-->
          <!--                                &lt;!&ndash;<div class="dropdown-menu dropdown-menu-right">-->
          <!--                                    <ul class="inner">-->
          <!--                                        <li class="list_item">-->
          <!--                                            <a class="dropdown-item" href="#">Link 1</a>-->
          <!--                                        </li>-->
          <!--                                    </ul>-->
          <!--                                </div>&ndash;&gt;-->
          <!--                            </div>-->

          <!--                            <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">-->
          <!--                                <div class="dropdown_header" data-toggle="dropdown">-->
          <!--                                    <button class="btn light_gray" v-tooltip.top-center="'More options'">-->
          <!--                                        <i class=" cs-dots-h d-block"></i>-->
          <!--                                    </button>-->
          <!--                                </div>-->
          <!--                                <div class="dropdown-menu dropdown-menu-right">-->
          <!--                                    <ul class="inner">-->
          <!--                                        <li class="list_item_li ">-->
          <!--                                            <a target="_blank" :href="savePocketContent(post.url)">-->
          <!--                                                <i class="fab fa-get-pocket"></i>-->
          <!--                                                <span class="icon_text">Add to Pocket</span>-->
          <!--                                            </a>-->
          <!--                                        </li>-->
          <!--                                        <li class="list_item_li d-none"-->
          <!--                                            @click.prevent="showSharers(post.id)">-->
          <!--                                            <i class="far fa-users-crown"></i>-->
          <!--                                            <span class="icon_text">Influencers</span>-->
          <!--                                        </li>-->
          <!--                                        <template v-if="getTopicType =='archived' && $route.name=='topics'">-->
          <!--                                            <li class="list_item_li "-->
          <!--                                                @click.prevent="unarchive({post_id: post.id, index: index})">-->
          <!--                                                <i class="cs-archive"></i>-->
          <!--                                                <span class="icon_text">Unarchive Post</span>-->
          <!--                                            </li>-->
          <!--                                        </template>-->
          <!--                                        &lt;!&ndash; <template v-else-if="getTopicType !='favorite'">-->
          <!--                                             <li class="list_item_li "-->
          <!--                                                 @click.prevent="archive({post_id: post.id, index: index})">-->

          <!--                                                 <i class="cs-archive"></i>-->
          <!--                                                 <span class="icon_text">Archive Post</span>-->
          <!--                                             </li>-->
          <!--                                         </template>&ndash;&gt;-->

          <!--                                    </ul>-->
          <!--                                </div>-->
          <!--                            </div>-->
        </div>
      </div>
    </div>

    <!--<div class="btn_block_footer">

                <div class="article_share_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                    <div class="dropdown_header" data-toggle="dropdown">
                        <button class="btn light_gray" v-tooltip.top-center="'Share this post'">
                            <i class="cs-share d-block"></i>
                            &lt;!&ndash;<span class="circle_count">7</span>&ndash;&gt;
                        </button>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">

                        <ul class="inner">
                            <li class="list_item_li "
                                @click.prevent="initializeArticlePostCreation(post.id,'social_share_modal')">
                                <i class="cs-social-share"></i>
                                <span class="icon_text">Social Media</span>
                            </li>
                            <li class="list_item_li "
                                @click.prevent="initializeArticlePostCreation(post.id,'blog_share_modal')">
                                <i class="cs-rss"></i>
                                <span class="icon_text">Blog Post</span>
                            </li>
                        </ul>

                    </div>
                </div>

                <div class=" favorite_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">

                    <div class="dropdown_header" v-if="post.is_starred"
                         @click.prevent="removeFavorite(post.id, index)">
                        <button class="btn " v-tooltip.top-center="'Unfavorite'">
                            <i class="favorite_icon_orange cs-star d-block">
                            </i>
                        </button>
                    </div>
                    <div class="dropdown_header" data-toggle="dropdown"
                         v-show="post.is_starred == false">
                        <button v-if="post.is_starred == false" class="btn light_gray"
                                v-tooltip.top-center="'Add to Favorites'">
                            <i class="favorite_icon cs-star d-block">
                            </i>
                        </button>
                    </div>
                    <favorite-dropdown :post_id="post.id" :postIndex="index"></favorite-dropdown>
                </div>

                <div class="article_post_dropdown inline_block dropdown  default_style_dropdown">
                    <div class="dropdown_header">
                        <button class="btn blue" v-tooltip.top-center="'Twitter Influencers'"
                                @click.prevent="showSharers(post.id)">
                            <i class="groups2_icon far fa-users d-block">
                            </i>
                        </button>
                    </div>
                    &lt;!&ndash;<div class="dropdown-menu dropdown-menu-right">
                        <ul class="inner">
                            <li class="list_item">
                                <a class="dropdown-item" href="#">Link 1</a>
                            </li>
                        </ul>
                    </div>&ndash;&gt;
                </div>

                <div class="more_dropdown article_post_dropdown inline_block dropdown  default_style_dropdown">
                    <div class="dropdown_header" data-toggle="dropdown">
                        <button v-tooltip.top-center="'More options'" class="btn light_gray">
                            <i class=" cs-dots-h d-block">
                            </i>
                        </button>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                        <ul class="inner">
                            <li class="list_item_li ">
                                <a target="_blank" :href="savePocketContent(post.url)">
                                    <i class="cs-pocket"></i>
                                    <span class="icon_text">Add to Pocket</span>
                                </a>
                            </li>
                            <li class="list_item_li  " @click.prevent="blockSource(post)">
                                <i class="cs-network"></i>
                                <span class="icon_text">Block Source</span>
                            </li>
                            <template v-if="$route.params && $route.params.module === 'archived'">
                                <li class="list_item_li "
                                    @click.prevent="unarchive({post_id: post.id, index: index})">
                                    <i class="cs-archive"></i>
                                    <span class="icon_text">Unarchive Post</span>
                                </li>
                            </template>
                            <template v-else>
                                <li class="list_item_li "
                                    @click.prevent="archive({post_id: post.id, index: index})">

                                    <i class="cs-archive"></i>
                                    <span class="icon_text">Archive Post</span>
                                </li>
                            </template>

                        </ul>
                    </div>
                </div>

            </div>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { feederItemMixin } from '../../../mixins/feeder-item-mixin'
import FeederSentimentIcon from '../feed-item/FeederSentimentIcon'
import FeederSharedCountTooltip from '../feed-item/FeederSharedCountTooltip'
import { EventBus } from "@common/lib/event-bus";

export default {
  components: {
    FeederSentimentIcon,
    FeederSharedCountTooltip
  },
  mixins: [feederItemMixin],
  props: {
    post: {},
    index: {},
    isArchive: { default: false },
    route_name: {}
  },
  computed: {
    ...mapGetters([]),
    EventBus() {
      return EventBus
    }
  },
  methods: {
    ...mapActions([]),
    routeToShareSocial () {
      const payload = {
        name: `${this.route_name}_social_share`,
        params: { post_id: this.post.id },
        query: { ...this.$route.query, module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },
    routeToBlogPost (postId) {
      const payload = {
        name: 'aggregate_feed_item_blog_post',
        params: { post_id: postId },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },
    routeToPreview () {
      const payload = {
        name: `${this.route_name}_preview`,
        params: { post_id: this.post.id },
        query: { ...this.$route.query, module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    }
  }
}
</script>

<style>
.listview-items {
  transition: all 600ms ease-in-out 50ms;
}

.listview-items-enter-active,
.listview-items-leave-active {
  transition: all 0.6s;
}

.listview-items-enter, .listview-items-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(45px);
}
</style>
