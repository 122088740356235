<template>
  <div class="authentication_component">
    <div class="auth_right login_block setPassword_block">
      <template v-if="accountExistLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <router-link :to="{ name: 'login' }">
          <button type="button" class="close">×</button>
        </router-link>
        <div class="d-flex align-items-center height" style="overflow: auto">
          <div class="_wrapper m-auto">
            <div class="right_logo d-block">
              <img src="../../assets/img/logo/logo_text_logo.png" alt="" />
            </div>
            <div class="auth_right_inner">
              <div class="top_block">
                <h2>Welcome! You are just one step away!</h2>
                <p>To keep your account secured and fully accessible,</p>
                <p
                  >we'd love if you'd be up for adding your email address and
                  password.</p
                >
              </div>

              <div class="line_input clear">
                <form>
                  <VueRecaptcha
                    ref="recaptcha"
                    size="invisible"
                    :sitekey="getGoogleRecaptchaKey"
                    @verify="createAccount"
                    @expired="onCaptchaExpired"
                  >
                  </VueRecaptcha>
                  <div class="row">
                    <div
                      class="input_field col-md-12"
                      :class="{ active: account.email }"
                    >
                      <input
                        v-model="account.email"
                        :disabled="emailDisabled"
                        type="text"
                      />
                      <div class="label">
                        <label for="">
                          <i class="icon_item far fa-user"></i>
                          <span class="lbl_txt">Your email address</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="input_field col-md-12"
                      :class="{ active: account.password }"
                    >
                      <input v-model="account.password" type="password" />
                      <div class="label">
                        <label for="">
                          <i class="icon_item cs-key"></i>
                          <span class="lbl_txt">Your password</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 basic_form"
                    style="
                      margin-bottom: 30px;
                      padding-top: 0px;
                      margin-left: 0;
                      padding-left: 0;
                    "
                  >
                    <div class="input_field">
                      <div class="with_right_icon">
                        <select
                          id=""
                          v-model="account.business_type"
                          name=""
                          class="with_bg"
                        >
                          <option disabled value=""
                            >What best describes you?</option
                          >
                          <option value="blogger">Blogger</option>
                          <option value="solopreneur">Solopreneur</option>
                          <option value="agency">Agency</option>
                          <option value="saas_business">SaaS Business</option>
                          <option value="marketing_team">Marketing Team</option>
                          <option value="enterprise">Enterprise</option>
                        </select>
                        <i class="right_icon fa fa-caret-down"></i>
                      </div>
                    </div>
                  </div>

                  <div class="btn_block">
                    <button
                      class="btn btn_loader loader_right"
                      @click.prevent="executeRecaptcha"
                    >
                      <span>Continue </span>
                      <img
                        v-if="registerLoader"
                        style="width: 20px; margin-left: 8px"
                        src="../../assets/img/common/gif_loader_white.gif"
                        alt=""
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import { authenticationTypes } from '@state/mutation-types'
import { accountExistURL, registerURL } from '@src/config/api-utils.js'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },

  data() {
    return {
      emailDisabled: false,
      account: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        plan: 'trial',
        business_type: '',
        picture: '',
        id: '',
        type: '',
      },
      size: '14px',
      loaderColor: '#fff',
      registerLoader: false,

      email: '',
      password: '',
      cpassword: '',
      accountExistLoader: false,
    }
  },
  computed: {
    ...mapGetters([]),

    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },

  mounted() {},
  created() {
    this.accountExists()
  },

  methods: {
    ...mapMutations(['SET_PROFILE']),
    ...mapActions(['fetchProfile']),

    executeRecaptcha() {
      this.registerLoader = true
      this.$refs.recaptcha.execute()
    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },

    async accountExists() {
      this.accountExistLoader = true
      const res = await this.$http
        .post(accountExistURL, {
          id: this.$route.query.id,
          type: this.$route.query.type,
          email: this.$route.query.email,
        })
        .then(
          async (response) => {
            if (response.data.status) {
              // login to the account and redirect
              if (
                response.data.user &&
                response.data.user.status &&
                response.data.user.status === 'invited'
              ) {
                this.alertMessage(response.data.message, 'error')
                this.$router.push({ name: 'login' })
                return false
              }

              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                    redirected_url: this.$route.fullPath,
                  },
                })
                return
              }

              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              if (this.getProfile.onBoarding) {
                this.isUserOnboarded()
              } else {
                if (response.data.user) {
                  this.SET_PROFILE(response.data.user)
                }
                this.loginLoader = false
              }
              return response
            } else {
              if (this.$route.query.email) {
                this.emailDisabled = true
              }
              this.account.type = this.$route.query.type
              this.account.id = this.$route.query.id
              this.account.email = this.$route.query.email
              this.account.firstname = this.$route.query.first_name
              this.account.lastname = this.$route.query.last_name
              this.account.picture = this.$route.query.picture
              this.accountExistLoader = false
            }
            return response
          },
          (response) => {
            return null
            // this.accountExistLoader = false
          }
        )
      console.log('accountExists res > ', res)
      if (res && res.data.status) {
        if (
          res.data.user.state &&
          (res.data.user.state === 'canceled' ||
            res.data.user.state === 'cancelled')
        ) {
          this.$router.push({ name: 'subscription_cancelled' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'deleted') {
          this.$router.push({ name: 'subscription_deleted' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'paused') {
          this.$router.push({ name: 'subscription_paused' })
          return
        }

        if (res.data.user.trial_finished) {
          this.$router.push({ name: 'trial_expired' })
          return
        }

        await this.fetchWorkspaces()
        this.Plan()
        if (res.data.activeWorkspace) {
          this.$store.commit(
            workspaceTypes.SET_ACTIVE_WORKSPACE,
            res.data.activeWorkspace.workspace
          )
          this.resetDefaultStates()
          this.initializeSection()
          await this.fetchDiscover()
          this.fetchSocialAccounts()

          console.debug('activate workspace set')
          this.$router.push({
            name: 'discover_search',
            params: { workspace: res.data.activeWorkspace.workspace?.slug },
          })
        } else {
          console.debug('Login: Redirecting to workspaces')
          this.$router.push({ name: 'workspaces' })
        }
      }
    },
    async createAccount(recaptchaToken) {
      this.registerLoader = true
      this.account.captcha_code = recaptchaToken
      this.$refs.recaptcha.reset()
      const response = await this.$http
        .post(registerURL, this.account)
        .then((response) => {
          this.registerLoader = false
          if (response.data.status) {
            this.$store.commit(
              authenticationTypes.SET_JWT_TOKEN,
              response.data.token
            )
            this.$store.commit(
              authenticationTypes.SET_LOGGED_USER,
              response.data.logged_user
            )
            this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
          } else {
            this.alertMessage(response.data.message, 'error')
          }
          return response
        })
        .catch((response) => {})
      if (response.data.status) {
        await this.fetchProfile()
        // await this.identify()
        await this.Plan()
        // this.$store.dispatch('trackEvent', { event: 'signed_up' })

        if (response.data.user && response.data.user.email_verify === false) {
          this.$router.push({ name: 'email_verification' })
        } else {
          // this.fetchWorkspaces()
          this.$router.push({ name: 'onboardingWorkspace' })
        }
      }
    },
  },
}
</script>

<style scoped>
.account_verification {
  text-align: center;
}
</style>
