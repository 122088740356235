<template>
  <div class="uikit-container">
    <div class="left-side-uitkit">
      <h4> Components </h4>
      <ul class="uikit_nav">
        <li>
          <router-link :to="{ name: 'uikit-contentstudio' }"
            >Introduction</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'uikit-button' }">Button</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'uikit-dropdown' }">Dropdown</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'uikit-forms' }">Forms</router-link>
        </li>

        <li>
          <router-link :to="{ name: 'uikit-tables-&-boxes' }"
            >Tables & boxes</router-link
          >
        </li>

        <li>
          <router-link :to="{ name: 'uikit-useful-icons' }"
            >Usefull Icons</router-link
          >
        </li>
      </ul>
    </div>
    <div class="right-side-uitkit">
      <div class="introduction-section right-side-uitkit-container">
        <h3>Introduction</h3>
        <p>
          Components are the reusable building blocks of our design system. Each
          component meets a specific interaction or UI need, and has been
          specifically created to work together to create patterns and intuitive
          user experiences.
        </p>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="less">
.uikit_nav {
  padding: 1rem 0rem;
  li {
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    a {
      padding: 0.5rem 0rem;
    }
    &:hover {
      background: white;
    }
  }
}
.uikit-container {
  .uikit-codemirror {
    //max-width:800px;
    //max-height:400px;
    display: flex;
    padding: 1rem 0;
    .vue-codemirror {
      width: 100%;
    }
    .CodeMirror {
      border: 1px solid #eee;
      height: auto !important;
    }
  }

  display: flex;
  code {
    margin: 0.5rem 0;
    padding: 1rem 0.25rem;
    display: flex;
    background: rgb(244, 245, 247) none repeat scroll 0% 0% !important;
  }

  .left-side-uitkit {
    width: 250px;
    padding: 1rem 1rem;
  }

  .right-side-uitkit {
    margin-top: 1rem;
    margin-right: 4rem;
    background: #fff;
    border-radius: 12px;
    width: 100%;
    flex-grow: 1;
    padding: 1.5rem 1.5rem;
    .right-side-uitkit-container {
      p {
        font-size: 1.1em;
        padding: 0.5rem 0;
        max-width: 1200px;
      }
      h3 {
        padding: 0.5rem 0;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
  .components-uitkit {
    h4 {
      padding: 0.25rem 0;
    }
  }
  .buttons-inline-components {
    margin: 1rem 0;
    a {
      margin-right: 15px;
    }
  }
}
</style>

<script>
export default {}
</script>
