<template>
  <div>
    <div
        data-display="static"
        class="dropdown_header d-flex align-items-center max-h-9"
        data-toggle="dropdown"
    >
      <span class="canva_icon vista_width" v-tooltip="'Design image with VistaCreate'">
        <img
            src="@assets/img/vista-black.svg"
            class="vista-icon"
            v-on:click="scrolltotop()"
            alt=""
        />VistaCreate
      </span>
    </div>

    <div class="dropdown-menu show_canva_options">
<!--      <h2 class="heading">Design type</h2>-->
      <ul class="inner" ref="crelloList">
        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'facebookSM')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">Facebook SM</span></a
          >
        </li>
        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'facebookVideoStoryAN')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">Facebook Video Story AN</span></a
          >
        </li>
        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'instagramSM')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">Instagram SM</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'instagramVideoStoryAN')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">Instagram Video Story AN</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'twitterSM')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">Twitter SM</span></a
          >
        </li>

        <li
            class="list_item"
            @click.prevent="createCrelloDesign(type, 'tikTokVideoAN')"
        >
          <a class="d-flex align-items-center" href=""
          ><span class="name">TikTok Video AN</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrelloTypes',
  props: ['type'],
  methods: {
    scrolltotop () {
      this.$refs.crelloList.scrollTop = 0
    }
  },
  mounted () {
    if (document.getElementById('crelloScript')) {
      return
    }
    const script = document.createElement('script')
    script.setAttribute('src', 'https://static.create.vista.com/js/frame_v2.min.js')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'crelloScript')
    script.async = false // optionally
    document.head.appendChild(script)
  },
  beforeDestroy () {
    const el = document.getElementById('crelloScript')
    if (el) {
      el.remove()
    }
  }
}
</script>

<style lang="scss" scoped>
.vista_width {
  width: auto;
  padding: 8px 4px;
  font-size: 0.8rem;
  font-weight:500;
  color:#595C5F;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  .vista-icon{
    width:18px;
  }
&:hover {
   background: #ebf3f7;
 }
}

</style>
