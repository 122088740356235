var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.getCustomSidebar.favorites.status &&
      _vm.getTopicContentType === 'article') ||
    _vm.getSidebarCustomization.customization
  )?_c('ul',{staticClass:"topics-left-pane-items",class:{ disable_click: _vm.getTopicDisabled }},[_c('li',{staticClass:"topics-left-pane-items__heading",class:[_vm.sibarItemStatus('favorites') ? '' : 'opacity']},[_c('i',{staticClass:"title_icon icon-stared_icon"}),_c('span',{staticClass:"topics-left-pane-items__heading__text"},[_vm._v("Favorites")]),(!_vm.getSidebarCustomization.customization)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('New Folder'),expression:"'New Folder'",modifiers:{"top-center":true}}],staticClass:"topics-left-pane-items__heading__button",on:{"click":function($event){$event.preventDefault();return _vm.addFavoriteFolder()}}},[_c('i',{staticClass:"far fa-plus d-block"})]):_c('div',{staticClass:"topics-left-pane-items__heading__customization"},[_c('i',{staticClass:"view_icon",class:[
          _vm.sibarItemStatus('favorites') ? 'cs-eye ' : 'cs-eye-close ',
        ],on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('favorites')}}})])]),(!_vm.getSidebarCustomization.customization)?_c('div',{staticClass:"topics-left-pane-favorite-folder"},[(_vm.addFolder && _vm.getAddFolderBox)?_c('div',{staticClass:"topics-left-pane-favorite-folder__create_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.folderName),expression:"folderName"}],attrs:{"type":"text","placeholder":"Enter Folder Name"},domProps:{"value":(_vm.folderName)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveFolder(_vm.folderName)},"input":function($event){if($event.target.composing)return;_vm.folderName=$event.target.value}}}),_c('div',{staticClass:"topics-left-pane-favorite-folder__create_input__action_buttons"},[_c('img',{staticClass:"add_folder",attrs:{"src":require("../../assets/article/check_icon.svg"),"alt":"Add Folder"},on:{"click":function($event){$event.preventDefault();return _vm.saveFolder(_vm.folderName)}}}),_c('img',{staticClass:"remove",attrs:{"src":require("../../assets/article/delete_icon.svg"),"alt":"remove topic"},on:{"click":function($event){$event.preventDefault();return _vm.cancelFolder()}}})])]):_vm._e()]):_vm._e(),(_vm.getFolders.length > 0)?_vm._l((_vm.getFolders),function(folder,index){return (
        _vm.getSidebarCustomization.customization ||
        !_vm.getCustomSidebar.favorites.options.includes(folder._id)
      )?_c('li',{staticClass:"topics-left-pane-items__list",class:[
        _vm.sibarItemStatus('favorites', folder._id) ? '' : 'opacity',
        _vm.getSidebarCustomization.customization
          ? 'topics-left-pane-items__list__customization'
          : '',
        folder.edit_state ? 'topics-left-pane-items__is_edit_folder' : '',
        _vm.$route.params.folder_id === folder._id ? 'active' : '',
      ]},[(folder.edit_state && !_vm.getSidebarCustomization.customization)?[_c('div',{staticClass:"topics-left-pane-items__list__update_favorite_folder topics-left-pane-favorite-folder"},[_c('div',{staticClass:"topics-left-pane-favorite-folder__create_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(folder.newName),expression:"folder.newName"}],attrs:{"type":"text","placeholder":"Enter Folder Name"},domProps:{"value":(folder.newName)},on:{"keydown":function($event){return _vm.updateFolderKeyDown($event, folder)},"input":function($event){if($event.target.composing)return;_vm.$set(folder, "newName", $event.target.value)}}}),_c('div',{staticClass:"topics-left-pane-favorite-folder__create_input__action_buttons"},[_c('img',{staticClass:"add_folder",attrs:{"src":require("../../assets/article/check_icon.svg"),"alt":"Add Folder"},on:{"click":function($event){$event.preventDefault();return _vm.updateFolder(folder)}}}),_c('img',{staticClass:"remove",attrs:{"src":require("../../assets/article/delete_icon.svg"),"alt":"remove topic"},on:{"click":function($event){return _vm.cancelFolderAction(folder)}}})])])])]:[_c('router-link',{attrs:{"to":{
            name: 'discover_topics_favorite',
            params: { folder_id: folder._id },
          }}},[_vm._v(_vm._s(folder.name))]),_c('div',{staticClass:"t_icon topics-left-pane-items__list__action_buttons"},[(!_vm.getSidebarCustomization.customization)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Edit'),expression:"'Edit'",modifiers:{"top-center":true}}],staticClass:"topics-left-pane-items__list__action_buttons__edit icon-edit-cs",on:{"click":function($event){$event.preventDefault();return _vm.editFolderAction(folder)}}}),_c('span',{staticClass:"dropdown dropdown-confirmation"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"icon-delete-cs topics-left-pane-items__list__action_buttons__remove",attrs:{"id":'dropdown_' + folder._id,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),_c('div',{staticClass:"dropdown-menu dropdown-confirmation__menu",attrs:{"aria-labelledby":'dropdown_' + folder._id}},[_c('div',{staticClass:"dropdown-confirmation__menu__header"},[_vm._v(" Confirmation ")]),_c('div',{staticClass:"dropdown-confirmation__menu__actions"},[_vm._v(" Are you sure? "),_c('span',{staticClass:"dropdown-confirmation__menu__actions__delete",on:{"click":function($event){$event.preventDefault();return _vm.removeFolder(folder._id, folder.name, index)}}},[_vm._v("Yes")]),_c('span',{staticClass:"dropdown-confirmation__menu__actions__cancel"},[_vm._v("No")])])])])]:[_c('i',{staticClass:"view_icon",class:[
                _vm.sibarItemStatus('favorites', folder._id)
                  ? 'cs-eye '
                  : 'cs-eye-close ',
              ],on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('favorites', folder._id)}}})]],2)]],2):_vm._e()}):[_c('p',{staticClass:"topics-left-pane-items__no_results"},[_vm._v("You do not have any favorite folder.")])]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }