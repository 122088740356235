<template>
  <b-modal
    @hidden="onHideVideoModal()"
    id="videoPreview"
    modal-class="video_preview normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Video Preview</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('videoPreview')"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <p class="desc" v-html="getVideoPreview.title"></p>
      <!--<div class="video_box_iframe" v-if="getVideoPreview.source=='Facebook'">-->
      <!--&lt;!&ndash;<div v-html="getVideoPreview.url">   </div>&ndash;&gt;-->
      <!--<iframe :src="getVideoPreview.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>-->
      <!--</div>-->
      <!--<div v-else class="video_box_iframe">-->

      <!--</div>-->

      <div class="video_box_iframe">
        <iframe
          v-if="getVideoPreview.heightRatio"
          :height="getVideoPreview.heightRatio"
          :src="getVideoPreview.url"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
        <iframe
          class="youtube_iframe"
          v-else
          :src="getVideoPreview.url"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
      <div class="content">
        <p>{{ getVideoPreview.description }}</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getVideoPreview'])
  },

  created () {
    // let statObject = this
    // let videoPreview = {
    //   title: '',
    //   url: '',
    //   description: '',
    //   source: '',
    //   height: null
    // }
    // setTimeout(function () {
    //   $(document).ready(function () {
    //     $('#videoPreview').on('hide.bs.modal', function () {
    //       statObject.setVideoPreview(videoPreview)
    //     })
    //   })
    // }, 200)
  },
  mounted () {},

  methods: {
    ...mapActions(['setVideoPreview']),
    onHideVideoModal () {
      const videoPreview = {
        title: '',
        url: '',
        description: '',
        source: '',
        height: null
      }
      setTimeout(() => {
        this.setVideoPreview(videoPreview)
      }, 200)
    }
  }
}
</script>
