<template>
  <div class="rounded-xl overflow-hidden">
    <div ref="editor" class="cst-textarea text-base"></div>
  </div>
</template>

<script>
import SocialEditor from 'social-editor'

import { VueRenderer } from '@ui/TextArea/helpers/VueRenderer'
import MentionsList from '@ui/TextArea/components/MentionsList'

export default {
  name: 'CstTextArea',

  props: {
    type: {
      type: String,
      default: 'composer',
    },
    placeholder: {
      type: String,
      default: 'Write something amazing...',
    },
    initialValue: {
      type: String,
      default: '',
    },
    maxLimit: {
      type: Number,
      default: 0,
    },
    twitterTextEnable: {
      type: Boolean,
      default: false,
    },
    getSuggestions: {
      type: Function,
      default: () => {},
    },
    getSuggestionsLoader: {
      type: Function,
      default: () => {},
    },
    savedLinks: {
      type: Array,
      default: () => [],
    },
  },
  /**
   * Data
   */
  data: () => ({
    editor: null,
  }),

  /**
   * Before Destroy
   */
  beforeDestroy() {
    console.log('beforeDestroy editor')

    this.editor.destroy()
    this.editor = null
  },

  /**
   * Mounted
   */
  mounted() {
    this.init()
  },

  /**
   * Methods
   */
  methods: {
    init() {
      if (this.$el) {
        const elem = this.$refs.editor

        this.editor = new SocialEditor(elem, {
          placeholder: this.placeholder,

          initialValue: this.initialValue,

          max: this.maxLimit,

          twitterText: this.twitterTextEnable,

          onUpdate: (store) => {
            this.$emit('change', store)
          },
          onPaste: (event, view, data) => {
            this.$emit('paste', event, view, data)
          },
          onDrop: (event, view) => {
            event.preventDefault()
            this.$emit('drop', event, view)
          },
          onDragEnter: (event, view) => {
            this.$emit('dragEnter', event, view)
          },
          onDragLeave: (event, view) => {
            this.$emit('dragLeave', event, view)
          },

          getMentionSuggestionsHTML: (items) => {
            const component = new VueRenderer(MentionsList, {
              // using vue 2:
              parent: this,
              propsData: {
                items: items,
              },
            })

            return component.element.outerHTML
          },

          getTagSuggestionsHTML: (items) =>
            '<div class="suggestion-item-list suggestion-list">' +
            items
              .map(
                (i) => '<div class="suggestion-item item">' + i.tag + '</div>'
              )
              .join('') +
            '</div>',
          getSuggestions: this.getSuggestions,
          getMentionSuggestionsLoader: this.getSuggestionsLoader,
          getTagSuggestionsLoader: () => null,
          linkPreview: this.type !== 'threaded-tweet',
          onLinkClick: (link) => {
            const short = this.savedLinks.find((item) => item.short === link)
            if (short) {
              return {
                text: 'Unshort to:',
                link: short.original,
              }
            }
            const original = this.savedLinks.find(
              (item) => item.original === link
            )
            if (original) {
              return {
                text: 'Short to:',
                link: original.short,
              }
            }
            return {
              text: '',
              link: link,
            }
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.cst-textarea {
  max-height: 31rem;
  overflow-y: auto;
  .ProseMirror {
    min-height: 8rem;
  }
}
.social-editor-box {
  border: 0 !important;
}
</style>
