<template>
  <div class="card_v1">
    <div class="card_inner">
      <div class="card_head">
        <h2>Popular Formats</h2>
        <ul class="ds_item_highlight" id="popular_format_item_highlight"></ul>
      </div>
      <div class="card_content">
        <!--<template v-if="isZero()">
                    <div class="no_data_for_chart">{{noDataMessage}}</div>
                </template>-->
        <!--<div :class="{'blur_div': isZero()}">-->
        <highcharts
          :options="popularFormats"
          ref="popular_format_chart"
        ></highcharts>
        <!--</div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      popularFormats: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_format_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#327d80', '#41edc5'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: {
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          gridLineColor: '#eaf0f4',
          title: {
            text: 'Avg Engagement Per Content',
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          min: 0
          // categories: ['0', '5', '10', '15', '20', '25']
        },
        plotOptions: {
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            pointPadding: 0,
            groupPadding: 0.3
          }
        },
        series: [
          {
            type: 'column',
            name: 'Avg Engagement Per Content',
            data: [11, 13, 4, 73, 3]
          },
          {
            type: 'column',
            name: 'Number of Content Items',
            data: [11, 23, 4, 23, 43]
          }
        ]
      }
    }
  }
}
</script>
