<template>
  <div>
    <transition-group name="grid-items" tag="div" class="clear" appear>
      <div
        v-for="(post, index) in getPosts"
        :key="'grid_' + post._source.id"
        class="article_box_grid only_article_box_grid"
      >
        <!-- @mouseover="mouseOver(post._source)" @mouseleave="mouseLeave(post._source)"-->
        <div
          v-if="post._source"
          class="box_inner"
          :class="{ shared: post._source.shared }"
        >
          <div class="image_block">
            <!--<transition name="fade-list-item">
                            <div class="tags_block top_right action_top_right_gridview" v-show="post._source.actions">
                                <i class="far fa-archive gridview_icon_action" v-tooltip="'Archive'"></i>
                            </div>
                        </transition>-->

            <!--<div class="tags_block top_right"  v-if="post._source.categories" >-->
            <!--<span class="tag" v-for="category in post._source.categories">{{category | capitalize}}</span>-->
            <!--</div>-->
            <div
              v-if="isTopicsDisplayAllowed(post)"
              class="tags_block bottom_left"
            >
              <template v-for="item in post._source.topics" v-if="item.label">
                <span
                  v-for="finalized_topic in checkIfMultipleTopics(item.label)"
                  v-if="item && item.label"
                  class="tag"
                  @click.prevent="
                    changeCuratedTopicLabels(item, finalized_topic)
                  "
                >
                  {{ finalized_topic }}
                </span>
              </template>
            </div>
            <div
              v-lazy:background-image="backgroundImageURL(post._source)"
              class="img"
            ></div>
            <!--<img class="lazy _img" :src="backgroundImageURL(post._source)" alt="">-->
          </div>
          <div class="content_block">
            <div class="content_block_height">
              <h2
                :title="limitTextLength(post._source.title, 0)"
                @click.prevent="
                  articlePreview({ id: post._source.id, index: index })
                "
                v-html="post._source.title"
              ></h2>

              <div class="detail clear">
                <div class="author_detail">
                  <a v-if="post._source.domain_url" href="javascript:;">{{
                    trimURL(post._source.domain_url)
                  }}</a>
                  <template v-if="post._source.author">
                    <p class="author_name block">
                      By
                      <span
                        style="color: #4e92ff"
                        v-html="post._source.author"
                      ></span>
                      <a
                        v-if="post._source.twitter_handler"
                        target="_blank"
                        :href="getTwitterLink(post._source.twitter_handler)"
                      >
                        {{ post._source.twitter_handler }}</a
                      >
                      <a
                        v-for="(account, index) in getTwitterAccountsForView(
                          post._source.twitter_accounts,
                        )"
                        v-else-if="index < 1"
                        target="_blank"
                        :href="getTwitterLink(account)"
                      >
                        {{ account }}</a
                      >
                    </p>
                  </template>
                  <template v-else-if="post._source.twitter_handler">
                    <p class="author_name block">
                      By
                      <a
                        target="_blank"
                        :href="getTwitterLink(post._source.twitter_handler)"
                        >{{ post._source.twitter_handler }}</a
                      >
                    </p>
                  </template>
                  <template
                    v-else-if="
                      post._source.twitter_accounts &&
                      post._source.twitter_accounts.length > 0
                    "
                  >
                    <p class="author_name block">
                      By
                      <a
                        v-for="(account, index) in getTwitterAccountsForView(
                          post._source.twitter_accounts,
                        )"
                        v-if="index < 1"
                        target="_blank"
                        :href="getTwitterLink(account)"
                        >{{ account }}</a
                      >
                    </p>
                  </template>
                </div>
                <div class="author_detail">
                  <p class="full_width_author"
                    >{{ $filters.relative(post._source.post_date) }}
                    <span
                      v-if="post._source.related_ids_count > 1"
                      style="float: right"
                      class="similar_stories"
                      @click.prevent="
                        retrieveSimilarArticles(post._source.id, post._index)
                      "
                      >{{ post._source.related_ids_count }} similar
                      articles</span
                    ></p
                  >
                  <!--v-if="post._source.related_ids_count"-->
                </div>
                <div class="more_detail">
                  <!-- <template v-if="post._source.top_twitter_influencers && post._source.top_twitter_influencers.length > 0">
                                         <div class="similar_content">{{post._source.top_twitter_influencers.length}} influencers
                                             shared
                                         </div>
                                     </template>-->

                  <!--<div class="article_type">-->
                  <!--<span v-if="post._source.read_time && post._source.read_time ==1 ">-->
                  <!--Less than 1 Min. Read - -->
                  <!--</span>-->
                  <!--<span v-if="post._source.read_time && post._source.read_time >1 ">{{post._source.read_time}} Min. Read -</span>-->
                  <!--<span v-if="post._source.has_abstract">Abstract Content</span>-->
                  <!--<span v-else>Full Content</span>-->
                  <!--</div>-->
                  <!--<div class="similar_content">16 similar articles</div>-->
                </div>
                <div
                  v-if="post._source.top_twitter_influencers"
                  class="top_influencers d-flex align-items-center"
                >
                  <div
                    v-if="post._source.total_influencers_shares"
                    class="influencer_left"
                  >
                    <p
                      >Shared by
                      <strong>{{
                        $filters.numberToCommas(post._source.total_influencers_shares)
                      }}</strong>
                      influencers</p
                    >
                  </div>
                  <!--                                  v-if="influencer.follower_count > 1000"-->
                  <!--                                  @click.prevent="showSharers(post._source.id)"-->
                  <!--                                  v-if="index <= 3"-->
                  <div
                    v-if="post._source.total_influencers_shares > 0"
                    class="influencer_right ml-auto"
                  >
                    <template
                      v-for="(influencer, index) in post._source
                        .top_twitter_influencers"
                    >
                      <a
                        v-tooltip="{
                          content: channelTooltipHTML('influencer', influencer),
                          classes: 'team_tooltip',
                        }"
                        target="_blank"
                        :href="'https://twitter.com/' + influencer.screen_name"
                      >
                        <div
                          v-if="influencer.profile_image"
                          class="img"
                          :style="{
                            background: 'url(' + influencer.profile_image + ')',
                          }"
                        ></div>
                        <div
                          v-else
                          class="img"
                          style="
                            background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                          "
                        ></div>
                      </a>
                    </template>
                    <!--                                        <div class="rounded_more"-->
                    <!--                                             v-if="post._source.top_twitter_influencers.length > 3  && post._source.total_influencers_shares - 4 > 0">-->
                    <!--                                            +{{post._source.total_influencers_shares - 4}}-->
                    <!--                                        </div>-->
                  </div>
                </div>
              </div>
              <!--<div class="tag_section">-->

              <!--<template v-for="item in post._source.topics" v-if="item.probability > 0.20">-->
              <!--<span v-for="finalized_topic in checkIfMultipleTopics(item.label)">-->
              <!--{{finalized_topic}}-->
              <!--</span>-->
              <!--</template>-->
              <!--</div>-->
            </div>

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item custom_tooltip">
                  <SharedCountTooltip :post="post"></SharedCountTooltip>
                  <div class="value">
                    <h3>{{
                      intToString(calculateEngagements(post._source))
                    }}</h3>
                  </div>
                </div>
                <div
                  v-if="post._source.velocity && post._source.velocity > 5"
                  class="stat_item"
                >
                  <div class="name">
                    <p>Trending Score</p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post._source.velocity) }}</h3>
                  </div>
                </div>
                <SentimentIcon :post="post"></SentimentIcon>
              </div>
            </div>
            <div class="btn_block d-flex">
              <div class="btn_block_inner">
                <div
                  class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header" data-toggle="dropdown">
                    <button
                      v-tooltip.top-center="'Share this post'"
                      class="btn light_gray"
                    >
                      <i
                        class="far fa-share-alt d-block"
                        :class="{ shared_posts: post._source.shared }"
                      ></i>
                      <span
                        v-if="post._source.shared_count"
                        class="circle_count"
                        >{{ post._source.shared_count }}</span
                      >
                    </button>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <li
                        class="list_item_li gray_dark gradient_hover"
                        @click.prevent="
                          initializeArticlePostCreation(
                            post._source.id,
                            'social_share_modal',
                          )
                        "
                      >
                        <i class="far fa-share share-ico-align"></i>
                        <span class="icon_text">Social Media</span>
                      </li>
                      <li
                        class="list_item_li gray_dark gradient_hover"
                        @click.prevent="
                          initializeArticlePostCreation(
                            post._source.id,
                            'blog_share_modal',
                          )
                        "
                      >
                        <i class="far fa-rss"></i>
                        <span class="icon_text">Blog Post</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="favorite_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div
                    v-if="post._source.is_favorite"
                    class="dropdown_header"
                    @click.prevent="removeFavorite(post._source.id, index)"
                  >
                    <button
                      v-tooltip.top-center="'Unfavorite'"
                      class="btn relative"
                    >
                      <i
                        :class="{ 'animated tada': post._source.is_active }"
                        class="favorite_icon_orange far fa-heart d-block"
                      ></i>
                      <span
                        :class="{ ' active': post._source.is_active }"
                        class="icon_animate"
                      ></span>
                    </button>
                  </div>
                  <div
                    v-show="post._source.is_favorite == false"
                    class="dropdown_header"
                    data-toggle="dropdown"
                  >
                    <button
                      v-if="post._source.is_favorite == false"
                      v-tooltip.top-center="'Add to Favorites'"
                      class="btn light_gray"
                    >
                      <i class="favorite_icon far fa-heart d-block"></i>
                    </button>
                  </div>
                  <FavoriteDropdown
                    :post_id="post._source.id"
                    :post-index="index"
                  ></FavoriteDropdown>
                </div>

                <div
                  class="article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header">
                    <button
                      v-if="
                        getTopicType === 'archived' &&
                        $route.params &&
                        $route.params.module === 'archived'
                      "
                      v-tooltip.top-center="'Unarchive Post'"
                      class="btn blue"
                      @click.prevent="
                        unarchive({ post_id: post._source.id, index: index })
                      "
                    >
                      <i class="groups2_icon far fa-archive d-block"> </i>
                    </button>

                    <button
                      v-else
                      v-tooltip.top-center="'Archive Post'"
                      class="btn blue"
                      @click.prevent="
                        archive({ post_id: post._source.id, index: index })
                      "
                    >
                      <i class="groups2_icon far fa-archive d-block"> </i>
                    </button>
                  </div>
                </div>

                <div
                  class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header" data-toggle="dropdown">
                    <button
                      v-tooltip.top-center="'More options'"
                      class="btn light_gray"
                    >
                      <i class="cs-dots-h d-block"> </i>
                    </button>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <li class="list_item_li">
                        <a
                          target="_blank"
                          :href="savePocketContent(post._source.url)"
                        >
                          <i class="fab fa-get-pocket"></i>
                          <span class="icon_text">Add to Pocket</span>
                        </a>
                      </li>
                      <template>
                        <li
                          class="list_item_li"
                          @click.prevent="showSharers(post._source.id)"
                        >
                          <i class="far fa-users-crown"></i>
                          <span class="icon_text">Influencers</span>
                        </li>
                      </template>
                      <template
                        v-if="
                          getTopicType !== 'archived' &&
                          getTopicType !== 'favorite'
                        "
                      >
                        <li
                          class="list_item_li"
                          @click.prevent="blockSource(post._source)"
                        >
                          <i class="far fa-ban"></i>
                          <span class="icon_text">Block Source</span>
                        </li>
                      </template>
                      <!--<template-->
                      <!--v-if="getTopicType === 'archived' && ($route.params && $route.params.module === 'archived')">-->
                      <!--<li class="list_item_li   "-->
                      <!--@click.prevent="unarchive({post_id: post._source.id, index: index})">-->
                      <!--<i class="far fa-archive"></i>-->
                      <!--<span class="icon_text">Unarchive Post</span>-->
                      <!--</li>-->
                      <!--</template>-->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FavoriteDropdown from '../folders/FavoriteDropdown'
import SharedCountTooltip from './snippets/SharedCountTooltip'
import SentimentIcon from './snippets/SentimentIcon'

export default {
  name: 'GridView',
  components: {
    FavoriteDropdown,
    SharedCountTooltip,
    SentimentIcon
  },
  computed: {
    ...mapGetters(['getPosts', 'getTopicType'])
  },
  methods: {
    ...mapActions(['archive', 'unarchive', 'articlePreview']),
    activeClass (e) {
      setTimeout(() => {}, 4200)
    },
    mouseOver (item) {
      this.$set(item, 'actions', true)
    },
    mouseLeave (item) {
      this.$set(item, 'actions', false)
    }
  }
}
</script>

<style>
.grid-items {
  transition: all 600ms ease-in-out 50ms;
}

.grid-items-enter-active,
.grid-items-leave-active {
  transition: all 0.6s;
}

.grid-items-enter, .grid-items-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-45px);
}
</style>
