<template>
  <div class="search_dropdown" v-if="!searchedTerm && is_visible">
    <div class="search_dropdown_inner">
      <ul v-if="searchedTermsList">
        <template v-for="(value, index) in searchedTermsList">
          <li class="item" @click.prevent="searchQueryFromHistory(value.query)">
            {{ value.query }}
          </li>
          <!--                                                <li @click.prevent="searchByKeyword(value)" class="item">{{value}}</li>-->
        </template>
      </ul>
      <!--<li class="head">Related Sites</li>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    terms: {},
    query: {},
    is_visible: {},
    isVisibleHistory: {}
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([]),
    searchedTerm () {
      let term = this.query
      if (term) {
        term = term.trim()
        if (term.length > 0) return true
      }
      return false
    },
    searchedTermsList () {
      const terms = this.terms
      if (terms && terms.length > 0) {
        return terms
      } else {
        return []
      }
    }
  },
  methods: {
    searchQueryFromHistory (value) {
      console.log('search query selected from history -> ', value)
      this.$router.push({ name: 'feeder_follow_actions', query: { q: value } })
    }
  }
}
</script>

<style scoped></style>
