<template>
  <div class="topics-filters-pane__container__right_section">
    <!--        <div class="feeds-content-pane__section__header__filters__total">-->
    <!--          <h3 class="results" v-if="total > 0">{{total | number-to-commas}}-->
    <!--            <template v-if="total === 1">-->
    <!--              Result-->
    <!--            </template>-->
    <!--            <template v-else>-->
    <!--              Results-->
    <!--            </template>-->
    <!--          </h3>-->
    <!--        </div>-->

    <div class="view_change_btn d-flex align-items-center mb-3">
      <div class="view_left">
        <h3
          class="topics-filters-pane__container__right_section__total_results"
          v-if="total > 0"
        >
          {{ total }}
          <template v-if="total === 1"> Result </template>
          <template v-else> Results </template>
        </h3>
      </div>

      <!--v-if="total > 0"-->
      <b-dropdown
        variant="studio-theme"
        id="dropdown-right"
        class="studio-theme-dropdown article-filters-pane__right_section__dropdown"
        no-caret
      >
        <template slot="button-content">
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{ getFeedSortText }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <b-dropdown-item
          :active="$route.query.order === 'most_shared' || !$route.query.order"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'most_shared' },
            })
          "
          >Most Shared
        </b-dropdown-item>

        <b-dropdown-item
          :active="$route.query.order === 'recent'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({ query: { ...$route.query, order: 'recent' } })
          "
          >Recent
        </b-dropdown-item>

        <b-dropdown-item
          :active="$route.query.order === 'facebook_shares'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'facebook_shares' },
            })
          "
          >Facebook Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="$route.query.order === 'twitter_shares'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'twitter_shares' },
            })
          "
        >
          Twitter Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="$route.query.order === 'pinterest_shares'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'pinterest_shares' },
            })
          "
        >
          Pinterest Shares
        </b-dropdown-item>
        <b-dropdown-item
          :active="$route.query.order === 'reddit_shares'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'reddit_shares' },
            })
          "
          >Reddit Shares
        </b-dropdown-item>
      </b-dropdown>

      <div
        class="topic_date_picker d-inline-block align-middle topics-filters-pane__container__right_section__datepicker"
        data-cy="date-picker"
      >
        <span class="d-inline-block mr-2 topics-datepicker">
          <date-range-picker
            ref="feedDatePicker"
            class="datepicker_input"
            :class="{ show: getDatePickerFeedFilter }"
            :opens="dateRangePickerFeed.normal.opens"
            @update="changeFeedDateRange"
            :startDate="dateRangePickerFeed.startData"
            :endDate="dateRangePickerFeed.endDate"
            :maxDate="dateRangePickerFeed.maxDate"
            :locale-data="dateRangePickerFeed.normal.locale"
            :singleDatePicker="dateRangePickerFeed.normal.singleDatePicker"
            :timePicker="dateRangePickerFeed.normal.timePicker"
            :timePicker24Hour="dateRangePickerFeed.normal.timePicker24Hour"
            :showWeekNumbers="dateRangePickerFeed.normal.showWeekNumbers"
            :showDropdowns="dateRangePickerFeed.normal.showDropdowns"
            :autoApply="dateRangePickerFeed.normal.autoApply"
            :ranges="dateRangePickerFeed.normal.ranges"
          >
            <div
              slot="input"
              class="datepicker_content discovery_white ml-2"
              slot-scope="picker"
            >
              <i class="icon-Calendar"></i>

              <span
                v-if="getSearchDatePicker(picker.startDate, picker.endDate)"
              >
                {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
              </span>
              <span
                v-else-if="
                  $route.query.from_date &&
                  getSearchDatePicker(
                    $route.query.from_date,
                    $route.query.to_date
                  )
                "
              >
                {{
                  getSearchDatePicker(
                    $route.query.from_date,
                    $route.query.to_date
                  )
                }}
              </span>
              <span v-else-if="picker.label">
                {{ picker.label }}
              </span>
              <span v-else> Last 3 months </span>
              <!--                <i class="icon-dropdown-cs"></i>-->
            </div>
          </date-range-picker>
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.feeder-content-filters {
  display: flex;
  justify-content: flex-end;
}
</style>
<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import ContentTotalResults from './ContentTotalResults'
import {EventBus} from "@common/lib/event-bus";

export default {
  props: {
    total: 0
  },
  components: {
    DateRangePicker,
    ContentTotalResults
  },
  data () {
    return {
      is_date_picker_loaded: false,
      total_results: 0,
      dateRangePickerFeed: {
        normal: {
          locale: {
            format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
          },

          opens: 'left',
          startDate:
            this.$route.query.from_date ||
            moment.utc().subtract('days', 90).format('YYYY-MM-DD'),
          endDate:
            this.$route.query.from_date || moment.utc().format('YYYY-MM-DD'),
          minDate: '2015-01-01',
          maxDate: moment.utc().format('YYYY-MM-DD HH:mm'),
          dateRange: {
            endDate: moment().utc()
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          ranges: {
            'All Time': [
              moment.utc().subtract(5, 'years').startOf('year'),
              moment.utc()
            ],
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
            'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
            'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
            'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
            'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
            'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
          }
        }
      }
    }
  },
  methods: {
    closeDatePicker () {
      if (this.$refs.feedDatePicker) {
        this.$refs.feedDatePicker.clickAway()
      }
    },
    changeFeedDateRange (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD')
          }
        })
        this.$emit('reloadFeedDateRange')
      }
    },
    checkOpen (event) {
      console.log('date range picker event ', event)
    }
  },
  mounted () {
    this.is_date_picker_loaded = true
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
  },

  computed: {
    getFeedSortText () {
      const feedSort = this.$route.query.order || 'Most Shared'
      return feedSort.replace('_', ' ')
    },
    getDatePickerFeedFilter () {
      if (this.is_date_picker_loaded) {
        return this.$refs.feedDatePicker.open
      } else {
        return false
      }
    }
  },
  watch: {}
}
</script>
