var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_container"},[_c('div',{staticClass:"card_v1"},[_c('div',{staticClass:"card_inner"},[_vm._m(0),_c('div',{staticClass:"card_content"},[_c('div',{staticClass:"social_table top_post_table"},[_c('div',{staticClass:"table_wrapper"},[_c('table',{staticClass:"table"},[_c('thead',{class:{ report_head: _vm.report }},[_c('tr',[_c('th',[_vm._v("Post")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"140px"}},[_c('span',{class:{
                        active: _vm.checkAscendingOrder(
                          '_source.total_engagement',
                        ),
                      }},[_vm._v(" Engagement ")]),(!_vm.report)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_ascending),expression:"v_ascending"}],staticClass:"sorting_icon cs-arrow-up",class:{
                          active: _vm.checkAscendingOrder(
                            '_source.total_engagement',
                          ),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.total_engagement',
                            'asc',
                          )}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_descending),expression:"v_descending"}],staticClass:"sorting_icon cs-arrow-down",class:{
                          active: _vm.checkDescendingOrder(
                            '_source.total_engagement',
                          ),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.total_engagement',
                            'desc',
                          )}}})]:_vm._e()],2),_c('th',{staticClass:"text-center",staticStyle:{"width":"90px"}},[_c('span',[_vm._v(" Reach ")]),(!_vm.report)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_ascending),expression:"v_ascending"}],staticClass:"sorting_icon cs-arrow-up",class:{
                          active: _vm.checkAscendingOrder(
                            '_source.total_impressions',
                          ),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.total_impressions',
                            'asc',
                          )}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_descending),expression:"v_descending"}],staticClass:"sorting_icon cs-arrow-down",class:{
                          active: _vm.checkDescendingOrder(
                            '_source.total_impressions',
                          ),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.total_impressions',
                            'desc',
                          )}}})]:_vm._e()],2),_c('th',{staticClass:"text-center",staticStyle:{"width":"120px"}},[_c('span',[_vm._v(" Post Clicks ")]),(!_vm.report)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_ascending),expression:"v_ascending"}],staticClass:"sorting_icon cs-arrow-up",class:{
                          active: _vm.checkAscendingOrder('_source.post_clicks'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.post_clicks',
                            'asc',
                          )}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_descending),expression:"v_descending"}],staticClass:"sorting_icon cs-arrow-down",class:{
                          active: _vm.checkDescendingOrder('_source.post_clicks'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook(
                            '_source.post_clicks',
                            'desc',
                          )}}})]:_vm._e()],2),(!_vm.report)?_c('th',{staticClass:"text-center",staticStyle:{"width":"10.625rem"}},[_c('span',[_vm._v(" Reactions ")]),(!_vm.report)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_ascending),expression:"v_ascending"}],staticClass:"sorting_icon cs-arrow-up",class:{
                          active: _vm.checkAscendingOrder('_source.total'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook('_source.total', 'asc')}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_descending),expression:"v_descending"}],staticClass:"sorting_icon cs-arrow-down",class:{
                          active: _vm.checkDescendingOrder('_source.total'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook('_source.total', 'desc')}}})]:_vm._e()],2):_vm._e(),(!_vm.report)?_c('th',{staticClass:"text-center",staticStyle:{"width":"120px"}},[_c('span',[_vm._v(" Comments ")]),(!_vm.report)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_ascending),expression:"v_ascending"}],staticClass:"sorting_icon cs-arrow-up",class:{
                          active: _vm.checkAscendingOrder('_source.comments'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook('_source.comments', 'asc')}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.v_descending),expression:"v_descending"}],staticClass:"sorting_icon cs-arrow-down",class:{
                          active: _vm.checkDescendingOrder('_source.comments'),
                        },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrderFacebook('_source.comments', 'desc')}}})]:_vm._e()],2):_vm._e()])]),_c('tbody',[(_vm.getOrderedPosts.length > 0)?_vm._l((_vm.getOrderedPosts),function(post){return _c('tr',[_c('td',{on:{"click":function($event){$event.preventDefault();return _vm.EventBus.$emit(
                          'facebook-analytics-preview',
                          post._source,
                        )}}},[_c('FacebookPostView',{attrs:{"post":post._source}})],1),_c('td',{staticClass:"text-center statistics"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(post._source.total_engagement))+" ")]),_c('td',{staticClass:"text-center statistics"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(post._source.post_impressions))+" ")]),_c('td',{staticClass:"text-center statistics"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(post._source.post_clicks))+" ")]),(!_vm.report)?_c('td',{staticClass:"text-center statistics"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(post._source.total + post._source.shares))+" ")]):_vm._e(),(!_vm.report)?_c('td',{staticClass:"text-center statistics"},[_vm._v(_vm._s(_vm.$filters.numberToCommas(post._source.comments))+" ")]):_vm._e()])}):_c('tr',[_c('td',{staticClass:"make-text-center",attrs:{"colspan":"6"}},[_vm._v(" You do not have any published posts/messages. ")])])],2)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_head"},[_c('h2',[_vm._v("Top Posts by Engagement")])])
}]

export { render, staticRenderFns }