<template>
  <div
    class="topics-left-pane"
    :class="{ customization_active: getSidebarCustomization.customization }"
  >
    <div class="topics-left-pane__fixed_block">
      <div class="topics-left-pane__fixed_block__inner">
        <div class="topics-left-pane__fixed_block__inner__header_links">
          <ul>
            <li>
              <router-link
                :to="{ name: 'discover_topics_cover_stories' }"
                href="javascript:;"
                ><i class="title_icon gray_dark_2 icon-today_icon"></i>
                <span>Cover Stories</span></router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'discover_topics_curated_trending',
                  query: { topic: 'News' },
                }"
                ><i class="title_icon gray_dark_2 icon-trending_icon"></i>
                <span>Trending News</span></router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'discover_topics_curated_quotes' }"
                ><i class="title_icon gray_dark_2 icon-Curated-Quotes"></i>
                <span>Curated Quotes</span></router-link
              >
            </li>
          </ul>
        </div>
        <hr />
        <quick-topics-list></quick-topics-list>
        <topics-list v-if="hasPermission('can_save_topic')"></topics-list>
        <favorite-folders-list></favorite-folders-list>
        <hr />
        <div
          class="topics-left-pane__fixed_block__inner__header_links topics-left-pane__fixed_block__inner__footer_links"
          :class="{
            'topics-left-pane__fixed_block__inner__footer_links_customization':
              getSidebarCustomization.customization,
          }"
        >
          <ul>
            <li>
              <router-link :to="{ name: 'discover_topics_archived' }"
                ><i class="title_icon gray_dark_2 fab icon-Archive_icon"></i>
                <span>View Archived Posts</span></router-link
              >
            </li>
            <!-- we are not showing the active bar if archived option is selected that's why we have conditoned it with  $route.name !== 'discover_topics_archived' -->
            <li>
              <a
                @click.prevent="sidebarCustomization()"
                href="javascript:;"
                :class="{
                  active:
                    getSidebarCustomization.customization &&
                    $route.name !== 'discover_topics_archived',
                }"
                ><i class="title_icon gray_dark_2 fas fa-sliders-h"></i>
                <span class="space_nowrap"
                  >Customize Sidebar ({{
                    getCustomSidebar.my_topics.options.length +
                    getCustomSidebar.quick_topics.options.length +
                    getCustomSidebar.favorites.options.length
                  }}/{{
                    getTopics.length +
                    this.getMyCuratedTopicsList.length +
                    this.getFolders.length
                  }})</span
                ></a
              >
            </li>
          </ul>
          <div
            v-if="getSidebarCustomization.customization"
            :class="{
              disable_click: getTopicDisabled,
              'topics-left-pane-with-customization':
                getSidebarCustomization.customization,
            }"
          >
            <div class="topics-left-pane-customization">
              <p>Click on 'Done' when selection is complete</p>
              <button
                @click="saveCustomSidebar()"
                class="btn btn-studio-theme-space btn-size-medium"
                :disabled="getSidebarCustomizationLoader.save"
                :class="{ disabled: getSidebarCustomizationLoader.save }"
              >
                <span>Done</span>
                <clip-loader
                  :color="'#ffffff'"
                  :size="'12px'"
                  v-if="getSidebarCustomizationLoader.save"
                ></clip-loader>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TopicsList from '../TopicsList'
import FavoriteFoldersList from '../FavoriteFoldersList'
import QuickTopicsList from '../QuickTopicsList'
import { topicTypes } from '../../../store/mutation-types'

export default {
  components: {
    TopicsList,
    FavoriteFoldersList,
    QuickTopicsList
  },

  computed: {
    ...mapGetters([
      'getSidebarCustomization',
      'getCoveredStories',
      'getTrendingStories',
      'getCuratedQuotes',
      'getCustomSidebar',
      'getSidebarCustomizationLoader',
      'getTopics',
      'getMyCuratedTopicsList',
      'getTopicDisabled',
      'getWorkspaces',
      'getActiveChannelRoute',
      'getFolders'
    ])
  },
  methods: {
    ...mapActions(['saveCustomSidebar', 'topicsContent']),
    ...mapMutations([topicTypes.SET_CHANGE_TOPIC_FILTERS]),

    // Topics Sidebar
    goToSources () {
      this.$router.push({
        name: 'feeder_manage_sources',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    },
    redirectTrendingNews () {
      this.$router.push({
        name: 'topics_web',
        params: {
          module: 'curated',
          topicId: 'N764025',
          workspace: this.getWorkspaces.activeWorkspace.slug
        }
      })
    },
    redirectArchive () {
      this.$router.push({
        name: 'topics_web',
        params: { module: 'archived' },
        workspace: this.getWorkspaces.activeWorkspace.slug
      })
    },
    redirectPocket () {
      this.$router.push({
        name: 'topics_web',
        params: { module: 'pocket' },
        workspace: this.getWorkspaces.activeWorkspace.slug
      })
    }

    // updated () {
    //     if (getSidebarCustomization.customization) {
    //         document.querySelector('.topics-left-pane__fixed_block__inner').scrollTo({ top: 10000000000000, behavior: 'smooth' })
    //     }
    //  },
  }
}
</script>
<style lang="less">
.cover-story-index {
  z-index: auto !important;
}
.side_bar_block .ds_topic_sidebar .sidebar_inner .head_links li.heading:hover {
  z-index: 1;
}
.margin-bottom-twenty {
  margin-bottom: 50px !important;
}
</style>
