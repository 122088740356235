<template>
  <div class="flex flex-col h-full py-6">
    <div class="px-9 mb-4">
      <img
        src="../assets/img/logo/contentstudio-logo-original.png"
        width="210"
        alt="ContentStudio"
      />
    </div>

    <Carousel
      class="featureSlider__carousel"
      :per-page="1"
      :autoplay="true"
      :autoplay-timeout="8000"
      :loop="true"
      pagination-active-color="#005fd0"
      pagination-color="rgba(58, 69, 87, 0.2)"
      :pagination-padding="4"
    >
      <Slide>
        <div class="flex flex-col text-left justify-between h-full p-9">
          <div class="">
            <h2 class="text-4xl font-bold mb-4">Discover.</h2>
            <p class="max-w-lg text-xl mb-4"
              >Stay on top of relevant and trending content in your industry
              with our powerful discovery engine.</p
            >
          </div>
          <div class="ml-auto auth-feature-img w-full">
            <img
              class="w-full"
              draggable="false"
              src="../assets/img/features/Creativity-pana.png"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
      <Slide>
        <div class="flex flex-col text-left justify-between h-full p-9">
          <div class="">
            <h2 class="text-4xl font-bold mb-4">Plan.</h2>
            <p class="max-w-md text-xl mb-4"
              >Streamline your content workflow by collaborating with your team
              members and planning month-long campaigns from an interactive
              calendar.</p
            >
          </div>
          <div class="ml-auto auth-feature-img w-full">
            <img
              class="w-full"
              draggable="false"
              src="../assets/img/features/Calendar-pana.svg"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
      <Slide>
        <div class="flex flex-col text-left justify-between h-full p-9">
          <div class="">
            <h2 class="text-4xl font-bold mb-4">Compose.</h2>
            <p class="max-w-md text-xl mb-4"
              >Annotate, publish or schedule content to multiple social and
              blogging channels at once from one single platform.</p
            >
          </div>
          <div class="ml-auto auth-feature-img w-full">
            <img
              class="w-full"
              draggable="false"
              src="../assets/img/features/Get-in-touch-pana.svg"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
      <Slide>
        <div class="flex flex-col text-left justify-between h-full p-9">
          <div class="">
            <h2 class="text-4xl font-bold mb-4">Publish.</h2>
            <p class="max-w-md text-xl mb-4"
              >Schedule content to all of your channels and networks from a
              single place.</p
            >
          </div>
          <div class="ml-auto auth-feature-img w-full">
            <img
              class="w-full"
              draggable="false"
              src="../assets/img/features/Social-networking-pana.svg"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'FeaturesSlider',
  components: {
    Carousel,
    Slide,
  },
  // created() {
  //   VueCarousel();
  // }
}
</script>

<style scoped lang="scss">
.auth-feature-img {
  @apply max-w-lg;
  @media (max-height: 848px) {
    @apply max-w-sm;
  }
}
</style>
