<template>
  <div>
    <div  class="publish_component "
          :class="{'list_view': this.$route.name === 'list_plans','calender_view': this.$route.name === 'calender_plans','fixed_section_top_bar':hasPermission('can_access_top_header')}">
      <div class="publish_inner">
        <!--v-if="!this.$route.name ==='automation'"-->
<!--        <div v-if="hasPermission('can_access_top_header')" class="section_top_bar">-->
<!--          <div class="d-flex  align-items-center">-->
<!--            <div class="left">-->
<!--              <ul class="links">-->
<!--                <li v-if="hasPermission('can_access_top_header')">-->
<!--                  <router-link-->
<!--                      class="route-btn"-->
<!--                      tag="button"-->
<!--                      data-cy="publish-composer" :to="{'name': 'composer'}">Composer</router-link>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <router-link-->
<!--                      class="route-btn"-->
<!--                      tag="button"-->
<!--                      data-cy="publish-planner" :to="{name: 'planner'}">Planner</router-link>-->

<!--                </li>-->
<!--                <li v-if="hasPermission('can_access_top_header')">-->
<!--                  <router-link-->
<!--                      class="route-btn"-->
<!--                      tag="button"-->
<!--                      data-cy="publish-automation" :to="{'name': 'automation'}">Automation</router-link>-->
<!--                </li>-->

<!--                <li v-if="hasPermission('can_access_top_header')">-->
<!--                  <router-link-->
<!--                      class="route-btn"-->
<!--                      tag="button"-->
<!--                      data-cy="publish-media" :to="{'name': 'media-library'}">Media Library</router-link>-->
<!--                </li>-->
<!--              </ul>-->

<!--            </div>-->

<!--            <div class="right ml-auto" v-if="hasPermission('can_access_top_header')">-->
<!--              <composer-dropdown v-if="this.$route.name !=='composer'"></composer-dropdown>-->
<!--              &lt;!&ndash;<trial-ending class=" d-inline-block align-middle"></trial-ending>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <router-view></router-view>

      </div>
    </div>
  </div>

</template>

<script>
// import TrialEnding from '../../../common/components/notifications/TrialEnding'
import ComposerDropdown from '@common/components/dropdowns/ComposerDropdown'
import { mapGetters } from 'vuex'
export default {
  components: {
    // TrialEnding,
    ComposerDropdown
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
     ...mapGetters([
      'getProfile',
    ]),
  defaultView (){
      return this.getProfile.planner_default_view
   },
  },
  methods: {},

  watch: {}
}
</script>
