<template>
  <div>
    <chart-render
      :id="'insta_stories_highlight'"
      :options="storiesPerformance()"
      :ref_value="'instagram_stories_chart'"
      :is_content_available="!isAudienceAvailable"
    ></chart-render>
  </div>
</template>

<script>
import ChartRender from '../../common/ChartRender'
import moment from 'moment'
import {
  darkBlueShadeColor,
  darkGreenColor,
  darkLightGreenColor,
  darkRedColor,
  darkYellowColor,
  lineChart,
  lineChartTooltip,
  lineLegend,
  linePlotOptions,
  splineCredits,
  splineXAxis,
  splineYAxis,
  stackedColumnChart,
  stackedPlotOptions
} from '../../common/helper'
import { INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH } from '@common/constants/messages'

export default {
  props: {
    stories_performance: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH
    }
  },
  computed: {
    isAudienceAvailable () {
      return true
    }
  },
  components: {
    ChartRender
  },
  methods: {
    storiesPerformance () {
      const storyPerformance = {
        story_exits: [],
        story_taps_forward: [],
        story_taps_back: [],
        story_reply: []
      }

      const keys = [
        'story_exits',
        'story_taps_forward',
        'story_taps_back',
        'story_reply'
      ]
      const lineChartTooltipModified = lineChartTooltip()
      // lineChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.y:f}</span></p>'
      this.stories_performance.buckets.forEach((item) => {
        if (item.doc_count > 0) {
          keys.forEach((key) => {
            storyPerformance[key].push([
              Date.parse(item.key_as_string.split(' ')[0]),
              item[key].value
            ])
          })
        } else {
          keys.forEach((key) => {
            storyPerformance[key].push([
              Date.parse(item.key_as_string.split(' ')[0]),
              item.doc_count
            ])
          })
        }
      })
      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: lineChartTooltipModified,
        chart: stackedColumnChart('#insta_stories_highlight'),
        legend: lineLegend(),
        title: {
          text: ''
        },

        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        plotOptions: stackedPlotOptions(),
        series: [
          {
            name: 'Story Exits',
            data: storyPerformance.story_exits,
            color: darkRedColor(),
            lineColor: '#e02f5f',
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50
          },
          {
            name: 'Story Taps Forward',
            data: storyPerformance.story_taps_forward,
            lineColor: '#2fe095',
            color: darkLightGreenColor()
          },
          {
            name: 'Story Taps Back',
            data: storyPerformance.story_taps_back,
            color: darkBlueShadeColor(),
            lineColor: '#2d3f8e'
          },
          {
            name: 'Story Replies',
            data: storyPerformance.story_reply,
            lineColor: '#ffe202',
            color: darkYellowColor()
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
