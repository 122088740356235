import axios from 'axios'
import { previewSuggestionURL } from '../../config/api-utils'
import { mapGetters, mapActions } from 'vuex'

const topicsSelectionMixin = {
  computed: {
    ...mapGetters([
      'getTopicSelection',
      'getTopicsFilters',
      'getPreviewSuggestion',
      'getPreviewSuggestionPosts',
      'getAxiosRequests',
      'getArticleAutomationDetails'
    ])
  },

  methods: {
    ...mapActions([
      'setPreviewSuggestionPosts',
      'setPreviewSuggestionTotal',
      'setPreviewSuggestionInfiniteLoader',
      'setPreviewSuggestionLoader',
      'setPreviewSuggestionPage',
      'setPreviewSuggestionScroll',
      'setPreviewSuggestionTotal',
      'setPreviewSuggestionNoMoreResult',
      'articlesPreviewSuggestion',
      'articlesPreviewSuggestion',
      'articlesPreviewSuggestion'
    ]),
    addAnyKeyword (arrayList, keyword, keywordType, event = null) {
      // for adding domain by entering the keywords etc.

      if (event && event.key === 'Enter') {
        // checking that the type for which user can add the results from these types only.

        if (
          [
            'include_domains',
            'exclude_domains',
            'limit_domains_results'
          ].includes(keywordType)
        ) {
          if (this.isValidURL(keyword)) {
            if (!arrayList.includes(keyword)) {
              arrayList.push(keyword.toLowerCase())

              switch (keywordType) {
                case 'exclude_domains':
                  this.excludeDomainsValue = ''
                  break
                case 'include_domains':
                  this.includeDomainsValue = ''
                  break
                case 'limit_domains_results':
                  this.limitDomainsResultsValue = ''
                  break
                default:
                  break
              }
              this.articlesPreviewSuggestion()
            }
          }
        }
        return
      }

      // for adding keywords data.

      console.debug('method:addAnyKeyword', arrayList, keyword, keywordType)
      if (keyword) {
        if (keyword.toString().includes(',')) {
          keyword = keyword.toString().replace(',', '')
        }
        keyword = keyword.trim(' ')
        if (!arrayList.includes(keyword)) {
          arrayList.push(keyword)
        }
        keyword = null
        switch (keywordType) {
          case 'any_keyword':
            this.anyKeywordValue = ''
            break
          case 'must_also_keyword':
            this.mustAlsoKeywordValue = ''
            break
          case 'must_contains_keyword':
            this.mustContainsKeywordValue = ''
            break
          case 'must_not_contains_keyword':
            this.mustNotContainsKeywordValue = ''
            break
          case 'must_exclude_keyword':
            this.mustExcludeKeywordsValue = ''
            break
          case 'exclude_domains':
            this.excludeDomainsValue = ''
            break
          case 'include_domains':
            this.includeDomainsValue = ''
            break
          case 'limit_domains_results':
            this.limitDomainsResultsValue = ''
            break
          default:
            break
        }
        console.log(keyword)
        console.log('Type content added' + keywordType)
        this.articlesPreviewSuggestion()
      }
    },
    removeKeyword (arrayList, index) {
      arrayList.splice(index, 1)
      this.articlesPreviewSuggestion()
    },
    showPreviewSnippet () {
      if (
        this.getTopicSelection.any_keywords.length > 0 ||
        this.getTopicSelection.must_also_keywords.length ||
        this.getTopicSelection.must_contains_keywords.length ||
        this.getTopicSelection.include_domains.length ||
        this.getTopicSelection.limit_domains_results.length ||
        this.getArticleAutomationDetails.article.topics.length > 0
      ) {
        return true
      }
      return false
    },
    /**
     *
     * @param pagination is true in case of the suggestion preview, and in other cases we have set it to false.
     */
    previewSuggestion (pagination = false, $state = null, automation = false) {
      // if there are no keywords or domains, don't send a request.
      if (this.showPreviewSnippet() === false) {
        return
      }

      // resetting all the states if the user has entered a new keyword and pagination is set to false.
      this.setPreviewSuggestionInfiniteLoader(false)
      this.setPreviewSuggestionLoader(true)
      if (pagination === false) {
        this.setPreviewSuggestionPosts([])
        this.setPreviewSuggestionPage(1)
        this.setPreviewSuggestionLoader(true)
      }

      // set the default to false.

      this.setPreviewSuggestionScroll(false)

      const CancelToken = axios.CancelToken

      // removing all existing axios requests

      for (let i = 0; i < this.getAxiosRequests.previewSuggestion.length; i++) {
        const remove = this.getAxiosRequests.previewSuggestion[i]
        remove()
      }

      let cancel, url

      axios
        .post(
          previewSuggestionURL,
          {
            selection: this.getTopicSelection,
            filters: this.getTopicsFilters,
            preview: this.getPreviewSuggestion
            // 'topics': this.getArticleAutomationDetails.article.topics
          },
          { headers: { Authorization: 'Bearer ' + this.getJWTToken } },
          {
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            })
          }
        )
        .then(
          (response) => {
            if (response.data.status) {
              // hide the loaders

              this.setPreviewSuggestionLoader(false)

              // add the posts to the array if they are called through the pagination
              if (pagination) {
                this.setPreviewSuggestionPosts(
                  this.getPreviewSuggestionPosts.concat(response.data.posts)
                )
              } else {
                this.setPreviewSuggestionPosts(response.data.posts)
                this.setPreviewSuggestionTotal(response.data.total)
              }
              // if the posts are 20, we need to set the infinite loading to scrollable behavior
              if (response.data.posts.length === 20) {
                console.log('Set Preview Suggestion Scroll')
                this.setPreviewSuggestionScroll(true)
                this.setPreviewSuggestionInfiniteLoader(true)
                console.log('Value set for the infinite loader')

                if ($state) {
                  console.log('State loaded for the preview suggestion')
                  $state.loaded()
                }
              } else if (
                response.data.posts.length > 0 &&
                response.data.posts.length < 20
              ) {
                console.log(
                  'Posts length is less, setting all values to false.'
                )
                this.setPreviewSuggestionScroll(false)
                this.setPreviewSuggestionNoMoreResult(true)
                this.setPreviewSuggestionInfiniteLoader(false)
                if ($state) {
                  $state.complete()
                }
              } else {
                console.log('Posts length is zero...')

                this.setPreviewSuggestionScroll(false)
                this.setPreviewSuggestionNoMoreResult(true)
                this.setPreviewSuggestionInfiniteLoader(false)
                if ($state) {
                  $state.complete()
                }
              }
            }
          },
          (response) => {}
        )
      this.getAxiosRequests.previewSuggestion.push(cancel)
    },
    highlightText (words, query) {
      return words.replace(
        query,
        '<span class="highlight_domain">' + query + '</span>'
      )
    }
  }
}
export { topicsSelectionMixin }
