var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SavedReplyModal',{attrs:{"active-inbox-detail":_vm.activeInboxDetail,"apply-saved-reply":_vm.applySavedReply}}),_c('div',{staticClass:"write_block d-flex"},[_c('div',{staticClass:"text_area contentEditableTextArea"},[_c('div',{ref:"commentArea",attrs:{"placeholder":_vm.getPlaceholder(),"contenteditable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.replyToComments(
            _vm.isRoot ? null : _vm.rootCommentIndex,
            $event,
            _vm.isRoot ? 'new' : ''
          )},"input":_vm.typeComment}})]),_c('div',{staticStyle:{"flex":"1","display":"flex","justify-content":"center","align-items":"center"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Saved Replies'),expression:"'Saved Replies'",modifiers:{"bottom-center":true}}],staticClass:"icon-Save attachment_btn m-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$bvModal.show('savedReplyModal')}}}),_c('span',{staticClass:"post_btn",class:{ disabled: !_vm.typedComment },on:{"click":function($event){return _vm.replyToComments(
            _vm.isRoot ? null : _vm.rootCommentIndex,
            null,
            _vm.isRoot ? 'new' : ''
          )}}},[_vm._v("Post")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }