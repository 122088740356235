export const PUBLISH_OPTIONS_TIME = {
  DRAFT: 'draft',
  SCHEDULE: 'schedule',
  CONTENT_CATEGORY: 'content_category',
  NOW: 'now',
}

export const PLAN_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  UNDER_REVIEW: 'Under Review',
  APPROVED: 'Approved',
  REJECTED: 'rejected',
  QUEUED: 'queued',
}

export const WORKSPACE_ROLES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  COLLABORATOR: 'collaborator',
  APPROVER: 'approver',
}

export const WORKSPACE_MEMBER_STATUS = {
  JOINED: 'joined',
  INVITED: 'invited',
}

export const WORKSPACE_MEMBER_TYPES = {
  TEAM: 'team',
  CLIENT: 'client',
}
export const APPROVER_STATUS = {
  APPROVE: 'approve',
  REJECT: 'reject',
  PENDING: 'pending',
}
