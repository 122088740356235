import { mapGetters, mapActions } from 'vuex'
import {
  fetchAutomationsCountsUrl,
  removeVideoAutomationUrl,
  removeArticleAutomationUrl,
  removeEvergreenAutomationUrl,
  removeRssAutomationUrl,
} from '@src/modules/automation/config/api-utils'
import moment from 'moment'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type'
import { planner } from '@src/modules/planner/store/mutation-types'
import { hashtagsTypes } from '@src/modules/setting/store/states/mutation-types'
import { getDefaultCurrentHashtag } from '@src/modules/setting/store/states/hashtags'
import { publish } from '@src/modules/publish/store/states/mutation-types'
import { rssTypes } from '@src/modules/automation/store/recipes/mutation-types'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import {
  AUTOMATION_REMOVAL_ERROR,
  AUTOMATION_REMOVAL_SUCCESS,
  UNKNOWN_ERROR,
} from '../../constants/messages'

export const automationMixin = {
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getVideoBlogAutomationListing',
      'getVideoSocialAutomationListing',
      'getRssAutomationListing',
      'getArticleBlogAutomationListing',
      'getArticleSocialAutomationListing',
      'getArticleAutomationListing',
      'getEvergreenAutomationListing',
    ]),
  },
  methods: {
    ...mapActions([
      'setAutomationStartTime',
      'setAutomationEndTime',
      'setFetchAutomationsCountsLoader',
      'setAutomationCounts',
      'setHashtagStatus',
      'setSelectedHashtag',
      'setHashtagEditMode',
      'setAutomationScheduleOptions',
      'setArticleAutomationListingSelectedAll',
      'setVideoAutomationListingSelectedAll',
    ]),

    /**
     * This method will get count for all type of saved automation.
     */
    fetchAutomationsCounts() {
      console.debug('Method:fetchAutomationsCounts')

      this.setFetchAutomationsCountsLoader(true)
      this.postRequest(
        fetchAutomationsCountsUrl,
        { workspace_id: this.getWorkspaces.activeWorkspace._id },
        (response) => {
          if (response.data.status === true) {
            this.setAutomationCounts(response.data.automationCounts)
          }
          this.setFetchAutomationsCountsLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchAutomationsCounts', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFetchAutomationsCountsLoader(false)
        }
      )
    },

    processHashtagSelection(hashtag) {
      if (!hashtag || (hashtag && !hashtag.status)) {
        // if no hashtag is present, then create the default hashtag.
        hashtag = {
          ...getDefaultCurrentHashtag(),
          id: '',
          status: false,
        }
      }
      // set the hashtags selection values to the publish and on the module level for the name, items, count, position etc.
      this.$store.commit(publish.SET_PUBLISH_HASHTAG_VALUE, hashtag)
      this.$store.commit(publish.SET_PUBLISH_HASHTAG_EDIT_MODE, false)
      this.$store.commit(hashtagsTypes.SET_CURRENT_HASHTAG_VALUE, hashtag)
    },

    automationNextRefill(automation, type, relative = false) {
      if (automation.lock || automation.status === 0) {
        return '-'
      } else {
        if (type === 'RssAutomation') {
          if (automation.last_execution) {
            let time = moment
              .utc(automation.last_execution.date, 'YYYY-MM-DD HH:mm:ss')
              .tz(this.getWorkspaces.activeWorkspace.timezone)

            const frequency = automation.pull_frequency
            time = frequency
              ? time.add(
                  parseInt(frequency.slice(0, -1)),
                  frequency.substr(frequency.length - 1)
                )
              : time.add(30, m)
            return relative
              ? time.local().fromNow()
              : time.format('DD/MM/YY - hh:mm a')
          }
        } else if (type === 'EvergreenAutomation') {
          if (automation.next_refill_time) {
            return moment
              .utc(automation.next_refill_time.date, 'YYYY-MM-DD HH:mm:ss')
              .tz(this.getWorkspaces.activeWorkspace.timezone)
              .format('DD/MM/YY - hh:mm a')
          }
        } else {
          if (
            automation.schedule_options.schedule_type === 'fix' &&
            automation.schedule_options.end_time
          ) {
            if (
              this.timePassingCheck(automation.schedule_options.end_time.date)
            ) {
              return 'Automation period ended.'
            }
          }

          const lastExecution = this.getWorkspaceTimeZoneTime(
            automation.last_execution.date,
            'YYYY-MM-DD HH:mm:ss'
          )
          return moment(lastExecution, 'YYYY-MM-DD HH:mm:ss')
            .subtract(1, 'day')
            .format('DD/MM/YY - hh:mm a')
        }
      }

      return '-'
    },

    processAutomationScheduleOptions(options) {
      console.debug('Method::processAutomationScheduleOptions', options)

      const stateObject = this

      if (options.start_time) {
        options.start_time = moment
          .utc(options.start_time.date, 'YYYY-MM-DD HH:mm')
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .format('YYYY-MM-DD HH:mm')
      }

      if (options.end_time) {
        options.end_time = moment
          .utc(options.end_time.date, 'YYYY-MM-DD HH:mm')
          .tz(this.getWorkspaces.activeWorkspace.timezone)
          .format('YYYY-MM-DD HH:mm')
      }

      $(options.custom.days).each(function (dayIndex, day) {
        $(day.times).each(function (timeIndex, time) {
          options.custom.days[dayIndex].times[timeIndex] = moment
            .utc(time, 'HH:mm:ss')
            .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
            .format('HH:mm')
        })
      })

      this.setAutomationScheduleOptions(options)
      this.showAutomationDatePickers()
    },

    automationBlogArticleContentPlanned(data) {
      console.debug('Method: automationBlogArticleContentPlanned')
      for (
        let i = 0;
        i < this.getArticleBlogAutomationListing.items.length;
        i++
      ) {
        if (
          this.getArticleBlogAutomationListing.items[i]._id ===
          data.automation._id
        ) {
          this.getArticleBlogAutomationListing.items[i].lock = 0
          this.getArticleBlogAutomationListing.items[i].last_execution =
            data.automation.last_execution
          break
        }
      }
    },

    automationSocialArticleContentPlanned(data) {
      console.debug('Method: automationSocialArticleContentPlanned')
      for (
        let i = 0;
        i < this.getArticleSocialAutomationListing.items.length;
        i++
      ) {
        if (
          this.getArticleSocialAutomationListing.items[i]._id ===
          data.automation._id
        ) {
          this.getArticleSocialAutomationListing.items[i].lock = 0
          this.getArticleSocialAutomationListing.items[i].last_execution =
            data.automation.last_execution
          break
        }
      }
    },

    automationBlogVideoContentPlanned(data) {
      console.debug('Method: automationBlogVideoContentPlanned')
      for (
        let i = 0;
        i < this.getVideoBlogAutomationListing.items.length;
        i++
      ) {
        if (
          this.getVideoBlogAutomationListing.items[i]._id ===
          data.automation._id
        ) {
          this.getVideoBlogAutomationListing.items[i].lock = 0
          this.getVideoBlogAutomationListing.items[i].last_execution =
            data.automation.last_execution
          if (data.automation.state)
            this.getVideoBlogAutomationListing.items[i].state =
              data.automation.state
          break
        }
      }
    },

    automationSocialVideoContentPlanned(data) {
      console.debug('Method: automationSocialVideoContentPlanned')
      for (
        let i = 0;
        i < this.getVideoSocialAutomationListing.items.length;
        i++
      ) {
        if (
          this.getVideoSocialAutomationListing.items[i]._id ===
          data.automation._id
        ) {
          this.getVideoSocialAutomationListing.items[i].lock = 0
          this.getVideoSocialAutomationListing.items[i].last_execution =
            data.automation.last_execution
          if (data.automation.state)
            this.getVideoBlogAutomationListing.items[i].state =
              data.automation.state
          break
        }
      }
    },

    changeAutomationSelection(selectedAutomations, automation, type) {
      const position = $.inArray(automation, selectedAutomations)

      if (position !== -1) {
        selectedAutomations.splice(position, 1)
      } else {
        selectedAutomations.push(automation)
      }

      let automations = []
      if (type === 'Article') {
        if (this.$route.name === 'articleBlogAutomationListing') {
          automations = this.getArticleBlogAutomationListing.items
        } else {
          automations = this.getArticleSocialAutomationListing.items
        }

        if (selectedAutomations.length === automations.length) {
          this.setArticleAutomationListingSelectedAll(true)
        } else {
          this.setArticleAutomationListingSelectedAll(false)
        }
      } else if (type === 'Video') {
        if (this.$route.name === 'videoBlogAutomationListing') {
          automations = this.getVideoBlogAutomationListing.items
        } else {
          automations = this.getVideoSocialAutomationListing.items
        }

        if (selectedAutomations.length === automations.length) {
          this.setVideoAutomationListingSelectedAll(true)
        } else {
          this.setVideoAutomationListingSelectedAll(false)
        }
      } else if (type === 'Evergreen') {
        if (
          selectedAutomations.length ===
          this.getEvergreenAutomationListing.items.length
        ) {
          this.$store.commit(evergreen.SET_EVERGREEN_LISTING_SELECT_ALL, true)
          // this.setEvergreenAutomationListingSelectedAll(true)
        } else {
          this.$store.commit(evergreen.SET_EVERGREEN_LISTING_SELECT_ALL, false)
          // this.setEvergreenAutomationListingSelectedAll(false)
        }
      } else if (type === 'Rss') {
        const rssSelected =
          selectedAutomations.length ===
          this.getRssAutomationListing.items.length
        this.$store.commit(
          rssTypes.SET_RSS_AUTOMATION_LISTING_SELECTED_ALL,
          rssSelected
        )
      }
    },

    viewAutomationPosts(automation, type) {
      console.debug('Method:viewAutomationPosts', automation, type)
      this.$store.dispatch('resetPlansRequestFilters')
      this.$router.push({
        name: 'planner_list_v2',
        params: {workspace: this.getWorkspaces.activeWorkspace.slug},
        query: {type: type, automations: automation},
      })
    },

    async removeAutomationFromManageLimits(payload) {
      let url = null
      if (payload.type.indexOf('Article') > -1) url = removeArticleAutomationUrl
      else if (payload.type.indexOf('Video') > -1)
        url = removeVideoAutomationUrl
      else if (payload.type === 'Evergreen') url = removeEvergreenAutomationUrl
      else if (payload.type === 'RSS') url = removeRssAutomationUrl

      if (url) {
        return proxy
          .post(url, {
            automation_id: payload.id,
            workspace_id: payload.workspace_id,
          })
          .then((resp) => {
            return resp
          })
          .catch((err) => {
            console.error(err)
            return false
          })
        // return this.postRequest(url, {
        //   automation_id: payload.id,
        //   workspace_id: payload.workspace_id
        // }, response => {
        //   return response
        // }, error => {
        //   return false
        // })
      }
      return false
    },
  },
}
