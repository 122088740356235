<template>
  <div class="social_accounts_list">
    <!-- less than 6 account -->
    <template v-if="isCollapsed">
      <template v-for="(account, index) in accountsList">
        <div
          :key="index"
          class="checkbox_input_image checkbox_social_profile"
          style="position: relative"
        >
          <i
            v-if="isDisabledAccount(account)"
            v-tooltip.top-center="getDisabledAccountWarning(account)"
            class="fas fa-exclamation warning_icon_sm"
          />
          <!--          for first comment accounts selection-->
          <input
            v-if="type === 'firstComment'"
            :id="
              'accounts_selection_v2_' +
              type +
              '_' +
              account[account.channel_identifier]
            "
            v-model="firstComment.first_comment_accounts"
            type="checkbox"
            :value="account[account.channel_identifier]"
            :disabled="isDisabledAccount(account)"
          />
          <!--          for carousel accounts selection-->
          <input
            v-else
            :id="
              'accounts_selection_v2_' +
              type +
              '_' +
              account[account.channel_identifier]
            "
            v-model="carousel.accounts"
            type="checkbox"
            :value="account[account.channel_identifier]"
            :disabled="isDisabledAccount(account)"
          />

          <label
            v-tooltip="{
              content: channelTooltipHTML(account.channel_type, account),
              classes: 'team_tooltip',
            }"
            :for="
              'accounts_selection_v2_' +
              type +
              '_' +
              account[account.channel_identifier]
            "
            class="checkbox_right"
            data-cy="account-selection"
          >
            <individual-channel-tooltip
              :account="account"
              :type="account.channel_type"
            />
          </label>
        </div>
      </template>
      <div v-if="accountsList.length > 1" class="selection_option">
        <span
          class="font-bold checkAllAccountSelection"
          @click="selectAllAccounts(true)"
          >Select all</span
        >
        |
        <span
          class="font-bold unCheckAllAccountSelection"
          @click="selectAllAccounts(false)"
          >None</span
        >
      </div>
    </template>
    <!-- more than 6 account dropdown here -->
    <template v-else>
      <div
        data-cy="posting-platform-selection"
        class="prevent_close_dropdown social_dropdown platform_filters_planner platform_filters_dd dropdown option_dropdown default_style_dropdown"
      >
        <div
          data-display="static"
          style="max-height: 37px"
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <div class="text d-inline-block align-middle">
            <div class="circle_boxes_inline">
              <template v-for="(account, i) in accountsList">
                <span
                  v-if="i < 6"
                  :key="'selected-account-item_' + i"
                  v-tooltip="{
                    content: channelTooltipHTML(account.channel_type, account),
                    classes: 'team_tooltip',
                  }"
                >
                  <individual-channel-tooltip
                    :account="account"
                    :type="account.channel_type"
                    :flex_class="'d-flex align-items-start'"
                  />
                </span>
              </template>

              <div
                v-if="accountSelectionCount > 6"
                class="profile_picture d-flex align-items-start"
              >
                <div class="picture_block picture_block_background">
                  <div class="img text">+{{ accountSelectionCount - 6 }} </div>
                </div>
              </div>
            </div>
          </div>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
        >
          <ul class="inner">
            <template>
              <div class="search-bar-input m-3">
                <div class="search-bar-inner">
                  <input
                    :id="'account_selection_v2_search_input_' + type"
                    v-model="search"
                    class="full_label"
                    type="text"
                    placeholder="Search by name"
                  />
                  <div class="search_btn">
                    <i class="icon-Search"></i>
                  </div>

                  <button
                    v-if="search !== ''"
                    class="clear-search-input"
                    @click="clearInput"
                  >
                    <i class="cs-cross"></i>
                  </button>
                </div>
              </div>

              <hr />

              <div
                v-if="search === '' && getUserRole !== 'Approver'"
                class="select_all test"
              >
                <div class="search_inner">
                  <div class="field_group">
                    <div class="checkbox_container">
                      <label
                        :for="'account_selection_check_all_v2' + type"
                        class="checkbox_right"
                      >
                        <span class="checkAllAccountSelection">Select All</span>
                        <span class="unCheckAllAccountSelection"></span>
                        <input
                          :id="'account_selection_check_all_v2' + type"
                          v-model="checkAll"
                          data-cy="platform_selection_check_all"
                          type="checkbox"
                          @change="selectAllAccounts(checkAll)"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-for="(account, key) in accountsList">
              <li
                v-if="isShowAccount(account)"
                :key="key + '_' + account.channel_type"
                :data-key="key + '_' + account.channel_type"
                class="list_item_li"
              >
                <div class="field_group">
                  <div class="checkbox_container">
                    <div>
                      <label
                        :for="
                          'account_selection_v2_' +
                          type +
                          '_' +
                          account[account.channel_identifier]
                        "
                        class="checkbox_right"
                        data-cy="select-account"
                      >
                        <input
                          v-if="type === 'firstComment'"
                          :id="
                            'account_selection_v2_' +
                            type +
                            '_' +
                            account[account.channel_identifier]
                          "
                          v-model="firstComment.first_comment_accounts"
                          :data-cy="
                            'account_selection_v2_' +
                            type +
                            '_' +
                            account[account.channel_identifier]
                          "
                          :value="account[account.channel_identifier]"
                          type="checkbox"
                          :disabled="
                            isDisabledAccount(account) ||
                            getUserRole === 'Approver'
                          "
                        />
                        <input
                          v-else
                          :id="
                            'account_selection_v2_' +
                            type +
                            '_' +
                            account[account.channel_identifier]
                          "
                          v-model="carousel.accounts"
                          :data-cy="
                            'account_selection_v2_' +
                            type +
                            '_' +
                            account[account.channel_identifier]
                          "
                          :value="account[account.channel_identifier]"
                          type="checkbox"
                          :disabled="
                            isDisabledAccount(account) ||
                            getUserRole === 'Approver'
                          "
                        />
                        <span
                          v-if="
                            !isDisabledAccount(account) &&
                            getUserRole !== 'Approver'
                          "
                          class="check"
                        ></span>
                        <i
                          v-else
                          v-tooltip.top-center="
                            getDisabledAccountWarning(account)
                          "
                          class="fas fa-exclamation icon_right"
                        />
                        <individual-channel-dropdown
                          :account="account"
                          :type="account.channel_type"
                          :account_name="getAccountName(account)"
                          :account_type="account.type"
                          :integration="'social'"
                        ></individual-channel-dropdown>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const clone = require('rfdc/default')

export default {
  name: 'AccountSelectionFirstCommentV2',
  components: {},
  props: {
    type: {
      type: String,
      required: true,
    },
    firstComment: {
      type: Object,
      default: () => {},
    },
    carousel: {
      type: Object,
      default: () => {},
    },
    accountsList: {
      type: Array,
      default: () => {
        return []
      },
    },
    isAllowFirstComment: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      search: '',
      checkAll: false,
    }
  },

  computed: {
    isCollapsed() {
      return this.accountsList.length <= 5
    },
    accountSelectionCount() {
      return this.accountsList.length
    },
  },

  created() {},

  mounted() {
    console.debug('Account Selection mounted', this.type)
  },

  methods: {
    isShowAccount(account) {
      return (
        this.getAccountName(account)
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
      )
    },

    selectAllAccounts(status) {
      if (this.type === 'firstComment') {
        // first comment case
        const comment = clone(this.firstComment)
        comment.first_comment_accounts = []
        if (status) {
          this.accountsList.forEach((account) => {
            if (this.isAllowFirstComment(account))
              comment.first_comment_accounts.push(
                account[account.channel_identifier]
              )
          })
        }
        this.$emit('changeFirstComment', comment)
      } else if (this.type === 'carousel') {
        // carousel case
        const carouselAccounts = []
        if (status) {
          this.accountsList.forEach((account) => {
            if (account.validity === 'valid')
              carouselAccounts.push(account[account.channel_identifier])
          })
        }
        this.carousel.accounts = carouselAccounts
      }
    },

    getDisabledAccountWarning(account) {
      if (this.getUserRole === 'Approver')
        return 'You are not allowed to change account selection'
      switch (this.type) {
        case 'firstComment':
          if (account.validity === 'invalid')
            return 'The access token for this social account has been invalidated, please reconnect to continue posting.'
          switch (account.channel_type) {
            case 'facebook':
              return 'First comment can only be published on a Facebook Group when a Facebook Page is Selected as a Publishing Method'
            case 'instagram':
              return 'Instagram first comment is only supported with Direct Publishing (API)'
            case 'youtube':
              return "First comment is not available for 'Private' and 'Made for Kids' videos"
          }
          break
        case 'carousel':
          if (account.validity === 'invalid')
            return 'The access token for this social account has been invalidated, please reconnect to continue posting.'
          return ''
      }
    },

    getAccountName(account) {
      if (account.name) return account.name
      else if (account.platform_name) return account.platform_name
      else if (account.location) return account.location
      else return ''
    },

    clearInput() {
      this.search = ''
    },

    isDisabledAccount(account) {
      switch (this.type) {
        case 'firstComment':
          return !this.isAllowFirstComment(account)
        case 'carousel':
          return account.validity === 'invalid'
      }
    },
  },
}
</script>
