import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getImagesContentURL } from '../../config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
const imagesMixin = {
  methods: {
    ...mapMutations(['SET_TOPIC_DISABLED']),
    ...mapActions([
      'setImagesPosts',
      'setImagesPaginationLoader',
      'setImagesScroll',
      'setImagesLoader',
      'setImagesPage',
      'setFlickrLicense',
      'setImgurSort',
      'setImgurSection',
      'setPixabaySort',
      'setPixabayType'
    ]),
    getImagesContent ($state = null) {
      this.SET_TOPIC_DISABLED(true)
      const payload = {
        filters: this.getImagesContentFilter,
        source: this.getAssistantContentType,
        search: this.getFilters.search,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }
      if (payload) {
        this.postRequest(
          getImagesContentURL,
          payload,
          (response) => {
            if (response.data.status) {
              this.SET_TOPIC_DISABLED(false)

              if (this.getImagesPage === 0) {
                this.setImagesPosts([])
              }

              if (!response.data.photos || response.data.photos.length === 0) {
                response.data.photos = []
              }

              if (this.getImagesPage >= 1) {
                this.setImagesPosts(
                  this.getImagesPosts.concat(response.data.photos)
                )
              } else {
                this.setImagesPosts(response.data.photos)
              }

              this.setImagesPaginationLoader(true)
              if (response.data.photos.length > 0) {
                this.setImagesScroll(true)
                if ($state) {
                  $state.loaded()
                }
              } else {
                this.setImagesScroll(false)
                try {
                  if ($state) {
                    $state.complete()
                  }
                } catch (ex) {
                  console.log(ex)
                }
              }

              this.setImagesLoader(false)
              this.checkComposerDragDrop()
            } else {
              this.SET_TOPIC_DISABLED(false)
              this.setImagesPaginationLoader(true)
              this.setImagesLoader(false)
              this.setImagesScroll(false)
            }
          },
          (response) => {
            this.SET_TOPIC_DISABLED(false)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },
    resetImagesFilter () {
      this.setImagesPage(0)
      this.setImagesScroll(true)
      this.setImagesPosts([])
      this.setImagesPaginationLoader(false)
      this.setImagesLoader(true)
      this.getImagesContent()
    },
    changeFlickrLicense (code, name) {
      console.debug('Method:changeFlickrLicense')
      this.setFlickrLicense({ code: code, name: name })
      this.resetImagesFilter()
    },
    changeImgurSort (sort) {
      console.debug('Method:changeImgurSort')
      this.setImgurSort(sort)
      this.resetImagesFilter()
    },
    changeImgurSection (section) {
      console.debug('Method:changeImgurSort')
      this.setImgurSection(section)
      this.resetImagesFilter()
    },
    changePixabayType (type) {
      console.debug('Method:changePixabayType')
      this.setPixabayType(type)
      this.resetImagesFilter()
    },
    changePixabaySort (sort) {
      console.debug('Method:changePixabaySort')
      this.setPixabaySort(sort)
      this.resetImagesFilter()
    },
    previewImage (photo) {
      $('#imagePreviewModal').find('.modal_inner img').attr('src', '')
      $('#imagePreviewModal')
        .modal('show')
        .find('.modal_inner img')
        .attr('src', photo)
    }
  },
  computed: {
    ...mapGetters([
      'getAssistantContentType',
      'getImagesContentFilter',
      'getImagesPage',
      'getImagesPosts'
    ])
  }
}
export { imagesMixin }
