var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isRoot ? '' : 'sub_comment moveLeft clear'},[_c('div',{staticClass:"picture_block picture_block_background"},[(_vm.tweet.posted_by.image)?_c('div',{staticClass:"img",style:('background: url(' + _vm.tweet.posted_by.image + ')')}):_c('div',{staticClass:"img",staticStyle:{"background":"url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')"}})]),_c('div',{staticClass:"text_block"},[(_vm.isParent || !_vm.isRoot)?_c('div',[_c('p',{staticClass:"text head_text_custom"},[_vm._v(_vm._s(_vm.tweet.posted_by.name)),_c('br'),_c('span',{staticClass:"tag",staticStyle:{"margin-left":"0px"}},[_vm._v("@"+_vm._s(_vm.tweet.posted_by.user_name))]),_c('span',{staticClass:"tag"},[_vm._v(" . "+_vm._s(_vm.commonMethods.timesAgo(_vm.tweet.created_time)))])]),_c('div',{staticClass:"preview_desc"},[_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.stringToHtmlTwitter(_vm.tweet.tweet_message))}})]),_c('div',{staticClass:"option_block option_block_bottom d-flex align-items-left"})]):_c('div',[_c('p',{staticClass:"text head_text_custom"},[_vm._v(_vm._s(_vm.tweet.posted_by.name)),_c('span',{staticClass:"tag"},[_vm._v("@"+_vm._s(_vm.tweet.posted_by.user_name))]),_c('span',{staticClass:"tag"},[_vm._v(" . "+_vm._s(_vm.commonMethods.timesAgo(_vm.tweet.created_time)))]),(!_vm.isParent && _vm.isRoot && _vm.repliesCount > 0)?[_c('span',{staticClass:"linkable",on:{"click":_vm.shouldShowReplies}},[_vm._v("("+_vm._s(_vm.repliesCount)+") "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.showReplies ? 'View Replies' : 'Hide Replies')}})])]:_vm._e()],2),_c('div',{staticClass:"preview_desc"},[_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.stringToHtmlTwitter(_vm.tweet.tweet_message))}})]),_c('div',{staticClass:"option_block option_block_bottom"},[_c('span',{staticClass:"span_item",on:{"click":function($event){return _vm.replyCommentTwitter(
              _vm.tweet.posted_by.user_name,
              _vm.tweet.mentioned_user,
              _vm.tweet.tweet_id,
              false,
              _vm.rootIndex
            )}}},[_c('i',{staticClass:"fas fa-reply"}),_vm._v(" "),_c('span',[_vm._v("Reply")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }