<template>
  <div
    class="planner-aside flex flex-col flex-grow-1 flex-shrink-0 bg-transparent"
  >
    <button
      class="modal_head__close mr-4 mt-4"
      data-dismiss="modal"
      type="button"
      @click="$bvModal.hide(modalId)"
    >
      &times;
    </button>

    <div class="overflow-auto bg-white" style="height: calc(100vh - 5.7rem)">
      <div class="p-6">
        <!-- Comments -->
        <div>
          <CommentsAndNotes
            :plan="item"
            :comments-accordion="commentsAccordion"
          />
        </div>

        <!-- Internal Notes -->
        <div v-if="!clientProfile">
          <CommentsAndNotes
            :plan="item"
            comment-type="notes"
            :internal-notes-accordion="internalNotesAccordion"
          />
        </div>

        <!-- Approval Status -->
        <div v-if="showApprovalStatus() || showExternalActions">
          <PlanApprovalStatus
            :plan="item"
            :external-actions="item.external_actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusClass } from '@common/lib/planner'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import { mapGetters } from 'vuex'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import CommentsAndNotes from '@src/modules/planner_v2/components/CommentsAndNotes'
import PlanApprovalStatus from '@src/modules/planner_v2/components/PlanApprovalStatus'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export default {
  name: 'PlannerAside',
  components: {
    PlanApprovalStatus,
    CommentsAndNotes,
  },
  mixins: [Helpers],
  props: {
    modalId: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => null,
      required: false,
    },
    commentsAccordion: {
      type: Boolean,
      default: false,
    },
    internalNotesAccordion: {
      type: Boolean,
      default: false,
    },
    postStatusAccordion: {
      type: Boolean,
      default: false,
    },
    externalAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      integrations: blogIntegrationsNames,
      moment: moment,
      expansionComments: this.commentsAccordion,
      expansionInternalNotes: this.internalNotesAccordion,
    }
  },
  computed: {
    ...mapGetters([
      'getSocialIntegrationsNames',
      'getActiveWorkspaceMembersName',
      'getActiveWorkspace',
    ]),
    showExternalActions() {
      return this.item?.external_actions?.length
    },
    isItemActive() {
      return this.item.is_active
    },
    isPublishedPost() {
      return getStatusClass(this.item) === 'published'
    },
    hasBlogSelection() {
      return this.item.blog_selection
    },
    getWorkspaceClientsName() {
      const members = []
      this.getActiveWorkspace.members.filter(function (member) {
        if (
          member.user &&
          (!member.membership || member.membership !== 'client')
        )
          members.push(member.user.full_name.replace(/ /g, ''))
      })
      return members
    },
  },
  mounted() {},
  methods: {
    hasSpecificBlogSelection(type) {
      return (
        this.item.blog_selection[type] && this.item.blog_selection[type].website
      )
    },
    hasSpecificSocialSelection(type) {
      return (
        this.item.account_selection[type] &&
        this.item.account_selection[type].length
      )
    },

    /**
     * Returns true/false based on the approval status if exists in this.item.
     * @returns {[]|*|boolean}
     */
    showApprovalStatus() {
      return this.item.approval?.approvers?.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.planner-aside {
  background: #f4f7fa;
}
</style>
