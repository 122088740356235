<template>
  <b-modal
    id="accounts_not_allowed_modal"
    size="lg"
    class="modish-modal"
    hide-footer
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="modal_head align-items-center">
      <h4>Account(s) are already connected</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('accounts_not_allowed_modal')"
        >&times;
      </button>
    </div>

    <div class="mt-0 mb-4">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-start">
          <div class="modish-modal__header">
            <p>
              The following social account(s) are already connected under a
              different super admin. Therefore, they would need to remove this
              connection to be added under your workspace.
            </p>
            <p>
              If you need assistance, don't hesitate to contact us at
              support@contentstudio.io so we can further assist.
            </p>
          </div>
        </div>
        <div v-if="data.notAllowedIds.length" class="mx-2 my-4">
          <!-- First Section -->
          <section
            v-if="checkAccountInclusion('firstSectionItems')"
            class="my-4"
          >
            <div class="flex justify-start items-center mb-3">
              <h2 class="text-base font-semibold">{{
                data.firstSectionHeader
              }}</h2>
            </div>

            <div
              v-if="data.firstSectionItems.length"
              class="grid gap-x-3.5 gap-y-2"
              :class="
                platformName === 'gmb' || platformName === 'youtube'
                  ? 'grid-cols-2'
                  : 'grid-cols-3'
              "
            >
              <template v-for="(item, key) in data.firstSectionItems">
                <div
                  v-if="isNotAddedAccount(item)"
                  :key="key"
                  class="flex items-center shadow-sm justify-start p-3 rounded-lg border-2 border-solid min-w-fit"
                >
                  <img
                    :src="getImageLink(item)"
                    alt="profile_image"
                    class="rounded-full w-10 h-10"
                  />
                  <div
                    class="ml-4 inline-block truncate select-none"
                    :class="
                      platformName === 'gmb' || platformName === 'youtube'
                        ? 'w-72'
                        : 'w-44'
                    "
                  >
                    {{ getAccountName(item) }}
                  </div>
                </div>
              </template>
            </div>
          </section>

          <!-- Second Section -->
          <section
            v-if="
              (platformName === 'facebook' ||
                platformName === 'linkedin' ||
                platformName === 'tumblr') &&
              checkAccountInclusion('secondSectionItems')
            "
            class="my-4"
          >
            <div class="flex justify-start items-center mb-3">
              <h2 class="text-base font-semibold">{{
                data.secondSectionHeader
              }}</h2>
            </div>

            <div
              v-if="data.secondSectionItems.length"
              class="grid gap-x-3.5 gap-y-2 pt-1.5"
              :class="
                platformName === 'gmb' || platformName === 'youtube'
                  ? 'grid-cols-2'
                  : 'grid-cols-3'
              "
            >
              <template v-for="(item, key) in data.secondSectionItems">
                <div
                  v-if="isNotAddedAccount(item)"
                  :key="key"
                  class="flex items-center shadow-sm justify-start p-3 rounded-lg border-2 border-solid min-w-fit"
                >
                  <img
                    :src="getImageLink(item)"
                    alt="profile_image"
                    class="rounded-full w-10 h-10"
                  />
                  <div
                    class="ml-4 inline-block truncate select-none"
                    :class="
                      platformName === 'gmb' || platformName === 'youtube'
                        ? 'w-72'
                        : 'w-44'
                    "
                  >
                    {{ getAccountName(item) }}
                  </div>
                </div>
              </template>
            </div>
          </section>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { computed, defineComponent, inject, onMounted, reactive } from 'vue'
import { platformImage } from '@common/lib/integrations'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'AccountsNotAllowedModal',
  setup() {
    const root = inject('root')
    const { $bvModal } = root
    const { getters } = useStore()
    const data = reactive({
      notAllowedIds: [],
      firstSectionItems: [],
      firstSectionHeader: '',
      firstSectionMessage: '',
      secondSectionItems: [],
      secondSectionHeader: '',
      secondSectionMessage: '',
      notAllowedAccounts: [],
    })

    /**
     * Computed property for modal header based on platform name
     */
    const platformName = computed(() => {
      return (
        getters.getPlatformsConnectionState?.process
          ? getters.getPlatformsConnectionState.process
          : ''
      ).toLowerCase()
    })

    onMounted(() => {
      EventBus.$on('showNotAllowedModal', (emittedData) => {
        data.notAllowedIds = emittedData?.notAllowedIds
        data.firstSectionItems = emittedData?.firstSectionItems
        data.secondSectionItems = emittedData?.secondSectionItems

        data.firstSectionHeader = emittedData?.firstSectionHeader
        data.firstSectionMessage = emittedData?.firstSectionMessage

        data.secondSectionHeader = emittedData?.secondSectionHeader
        data.secondSectionMessage = emittedData?.secondSectionMessage

        $bvModal.show('accounts_not_allowed_modal')
      })
    })

    /**
     *  Method to get plan Id
     */
    const getPlanId = (item) => {
      return item.platform_identifier
        ? item.platform_identifier
        : item.id
        ? item.id
        : item.instagram_id
        ? item.instagram_id
        : item.name
        ? item.name
        : ''
    }

    /**
     *  Method to get account name
     */
    const getAccountName = (item) => {
      if (Object.hasOwn(item, 'platform_name')) return item.platform_name

      if (
        Object.hasOwn(item, 'locationName') &&
        Object.hasOwn(item, 'address')
      ) {
        const name = item.locationName + ' - ' + item.address.locality
        return item.address.postalCode
          ? name + ' - ' + item.address.postalCode
          : name
      }

      if (Object.hasOwn(item, 'name')) {
        let name = item.name
        if (item.location && item.location.city) {
          name += ' (' + item.location.city
          if (item.location.country) name += ', ' + item.location.country
          return name + ')'
        } else if (item.location_name) {
          name += ' (' + item.location_name + ' )'
        }
        return name
      }
      if (Object.hasOwn(item, 'firstName')) {
        return item.firstName + ' ' + item.lastName
      }
      if (Object.hasOwn(item, 'first_name')) {
        return item.first_name + ' ' + item.last_name
      }
      if (Object.hasOwn(item, 'url')) return item.url
      if (Object.hasOwn(item, 'title')) return item.title
      return ''
    }

    /**
     *  Method to get account image
     */
    const getImageLink = (item) => {
      if (platformName.value === 'gmb') {
        return 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      }
      return platformImage(item)
    }

    /**
     * @description checks if the account is eligible to be displayed under not allowed section.
     * @param item
     * @returns {*}
     */
    const isNotAddedAccount = (item) => {
      return data.notAllowedIds.includes(getPlanId(item))
    }

    /**
     * @description checks if the ids of any sectionItems exist in notAllowedIds array.
     * @param itemType
     * @returns {boolean}
     */
    const checkAccountInclusion = (itemType) => {
      return data.notAllowedIds.some((element) =>
        data[itemType].find((item) => getPlanId(item) === element)
      )
    }

    return {
      data,
      platformName,
      getPlanId,
      getAccountName,
      getImageLink,
      isNotAddedAccount,
      checkAccountInclusion,
    }
  },
})
</script>

<style scoped></style>
