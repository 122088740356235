var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.getCustomSidebar.my_topics.status || _vm.getSidebarCustomization.customization
  )?_c('ul',{staticClass:"topics-left-pane-items",class:{ disable_click: _vm.getTopicDisabled }},[_c('li',{staticClass:"topics-left-pane-items__heading",class:[_vm.sibarItemStatus('myTopics') ? '' : 'opacity']},[_c('i',{staticClass:"icon-reports_icon title_icon"}),_c('span',{staticClass:"topics-left-pane-items__heading__text"},[_vm._v("Custom Topics")]),(!_vm.getSidebarCustomization.customization)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('New Topic'),expression:"'New Topic'",modifiers:{"top-center":true}}],staticClass:"topics-left-pane-items__heading__button",attrs:{"data-cy":"add-custom-topic"},on:{"click":function($event){$event.preventDefault();return _vm.createNewTopic()}}},[_c('i',{staticClass:"far fa-plus d-block"})]):_c('div',{staticClass:"topics-left-pane-items__heading__customization"},[_c('i',{staticClass:"view_icon",class:[
          _vm.sibarItemStatus('myTopics') ? ' cs-eye' : '  cs-eye-close',
        ],on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('myTopics')}}})])]),(_vm.getTopics.length > 0)?[_c('transition-group',{attrs:{"name":"topics-list","tag":"div"}},_vm._l((_vm.getTopics),function(topic,index){return (
          _vm.getSidebarCustomization.customization ||
          !_vm.getCustomSidebar.my_topics.options.includes(topic._id)
        )?_c('li',{key:topic._id,staticClass:"topics-left-pane-items__list",class:[
          _vm.sibarItemStatus('myTopics', topic._id) ? '' : 'opacity',
          _vm.getSidebarCustomization.customization
            ? 'topics-left-pane-items__list__customization'
            : '',
          _vm.$route.params.topic_id === topic._id ? 'active' : '',
        ],attrs:{"disabled":_vm.getTopicDisabled === true || _vm.getSidebarCustomization.customization}},[(topic.filters)?_c('router-link',{class:{ active: _vm.$route.params.topic_id === topic._id },attrs:{"href":"javascript:;","to":{
            name: 'discover_topics_custom_web_content',
            params: { topic_id: topic._id },
          }}},[_vm._v(" "+_vm._s(topic.filters.topics.name)+" ")]):_vm._e(),_c('div',{staticClass:"t_icon topics-left-pane-items__list__action_buttons"},[(!_vm.getSidebarCustomization.customization)?[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Edit'),expression:"'Edit'",modifiers:{"top-center":true}}],staticClass:"icon-edit-cs topics-left-pane-items__list__action_buttons__edit",staticStyle:{"font-size":"13px"},on:{"click":function($event){$event.preventDefault();return _vm.editTopic(topic)}}}),_c('span',{staticClass:"dropdown dropdown-confirmation"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"icon-delete-cs topics-left-pane-items__list__action_buttons__remove",attrs:{"id":'dropdown_' + topic._id,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),_c('div',{staticClass:"dropdown-menu dropdown-confirmation__menu",attrs:{"aria-labelledby":'dropdown_' + topic._id}},[_c('div',{staticClass:"dropdown-confirmation__menu__header"},[_vm._v(" Confirmation ")]),_c('div',{staticClass:"dropdown-confirmation__menu__actions"},[_vm._v(" Are you sure? "),_c('span',{staticClass:"dropdown-confirmation__menu__actions__delete",on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('deleteCustomTopic', {
                        id: topic._id,
                        index: index,
                      })}}},[_vm._v("Yes")]),_c('span',{staticClass:"dropdown-confirmation__menu__actions__cancel"},[_vm._v("No")])])])])]:[_c('i',{staticClass:"view_icon",class:[
                _vm.sibarItemStatus('myTopics', topic._id)
                  ? 'cs-eye '
                  : 'cs-eye-close ',
              ],on:{"click":function($event){$event.preventDefault();return _vm.selectionSibarItem('myTopics', topic._id)}}})]],2)],1):_vm._e()}),0)]:[_c('p',{staticClass:"topics-left-pane-items__no_results"},[_vm._v("You do not have any topic created.")])]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }