<script>
import { mapGetters } from 'vuex'
import { planner } from '../../store/mutation-types'

export default {
  computed: {
    ...mapGetters(['getAllAutomations']),
    getPlannerAutomations () {
      const automations = []
      const items =
        this.getAllAutomations[
          this.$route.query.type.replace('_automation', '')
        ]
      items.forEach((item) => {
        if (
          item.name
            .toLowerCase()
            .indexOf(this.getAllAutomations.search.toLowerCase()) >= 0
        ) {
          automations.push(item)
        }
      })
      return automations
    },
    hasAddedAutomations () {
      return this.getPlannerAutomations.length
    },
    selectAllOptionVisibilityStatus () {
      return this.getAllAutomations.search === ''
    }
  },
  methods: {
    changePlannerAutomationsAllSelection () {
      console.debug('Method:changePlannerAutomationsAllSelection')

      const automations = []
      if (this.getAllAutomations.select_all) {
        this.getPlannerAutomations.forEach((item) => {
          automations.push(item._id)
        })
      }
      if (automations.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, automations: automations.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.automations
      this.$router.push({ query: query })
      return false
    },
    getId (automation) {
      return automation._id
    },
    getName (automation) {
      return automation.name
    },
    getType (automation) {
      return automation.type
    },
    isAutomationSelected (id) {
      return (
        this.$route.query.automations &&
        this.$route.query.automations.indexOf(id) >= 0
      )
    },
    changePlannerAutomationsSelection (id) {
      const automations = this.$route.query.automations
        ? this.$route.query.automations.split(',')
        : []
      automations.indexOf(id) >= 0
        ? automations.splice(automations.indexOf(id), 1)
        : automations.push(id)

      this.$store.commit(
        planner.SET_AUTOMATIONS_SELECT_ALL_STATUS,
        !!(
          this.getAllAutomations.search === '' &&
          automations.length === this.getPlannerAutomations.length
        )
      )
      if (automations.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, automations: automations.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.automations
      this.$router.push({ query: query })
    }
  }
}
</script>
<template>
  <div
    class="prevent_close_dropdown dropdown option_dropdown default_style_dropdown social_dropdown"
  >
    <div
      style="max-height: 37px"
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <i class="align-middle far fa-user icon_first"></i>
      <span class="text align-middle">Automations</span>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>

    <div class="dropdown-menu dropdown-menu-right">
      <ul class="inner">
        <div class="search_input with_icon_left">
          <div class="search_inner w-100">
            <input
              v-model="getAllAutomations.search"
              type="text"
              class=""
              placeholder="Search by name"
            />
            <button class="search_btn input_icon search_icon">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>

        <template v-if="hasAddedAutomations">
          <li
            class="list_item_li select_all_li"
            v-if="selectAllOptionVisibilityStatus"
          >
            <div class="checkbox_input_image">
              <input
                id="planner_automations_select_all"
                v-model="getAllAutomations.select_all"
                type="checkbox"
                @change="changePlannerAutomationsAllSelection()"
              />
              <label
                for="planner_automations_select_all"
                class="checkbox_right"
              >
                <span class="">Select All</span>
              </label>
            </div>
          </li>

          <li class="list_item_li" v-for="automation in getPlannerAutomations">
            <div class="checkbox_input_image">
              <input
                :id="getId(automation)"
                :checked="isAutomationSelected(getId(automation))"
                :value="getId(automation)"
                type="checkbox"
                @change="changePlannerAutomationsSelection(getId(automation))"
              />
              <label :for="getId(automation)" class="checkbox_right">
                <div class="profile_picture d-flex align-items-start fb">
                  <div class="text_block">
                    <p class="text">{{ getName(automation) }}</p>
                    <p class="sub_text" v-if="getType(automation)">{{
                      getType(automation)
                    }}</p>
                  </div>
                </div>
              </label>
            </div>
          </li>
        </template>
        <li class="no_text_li" v-if="!hasAddedAutomations"
          >No automation found.</li
        >
      </ul>
    </div>
  </div>
</template>
