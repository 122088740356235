import { youtube } from '@src/modules/integration/store/states/mutation-types'

const getDefaultYoutubeAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultYoutubeAccountsState()
  },
  getters: {
    getYoutubeAccounts: (state) => state.accounts
  },
  actions: {},
  mutations: {
    [youtube.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.platform_name.toLowerCase() < b.platform_name.toLowerCase() ? -1 : a.platform_name.toLowerCase() > b.platform_name.toLowerCase() ? 1 : 0 )
    },

    [youtube.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.platform_name.toLowerCase() < b.platform_name.toLowerCase() ? -1 : a.platform_name.toLowerCase() > b.platform_name.toLowerCase() ? 1 : 0 )
    },

    [youtube.SET_DEFAULT] (state) {
      state.accounts = getDefaultYoutubeAccountsState()
    }
  }
}
