<template>
  <div class="planner_calender">
    <div
      class="planner-calender-main"
      :class="{
        loader_overlay_with_loader:
          isFetchingPlans || getPlannerLoaders.reschedule_plan,
      }"
    >
      <beat-loader :color="'#436aff'" v-if="isFetchingPlans"></beat-loader>

      <transition name="fade-list-item">
        <template v-if="showNullPlansSection">
          <div class="warning_box text-center mx-0" v-if="isPlatformsSelected">
            <p>No posts found for the selected filters and date-range.</p>
          </div>
          <div class="warning_box text-center mx-0" v-if="!isPlatformsSelected">
            <p
              >Please select at least one profile from the menu on the left side
              to view posts.</p
            >
          </div>
        </template>
      </transition>

      <FullCalendar class="planner-app-calendar" :options="calendarOptions">
        <template slot="eventContent" slot-scope="arg">
          <CalendarEvent :item="arg.event.extendedProps"></CalendarEvent>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
// Core Imports
import Vue from 'vue'
import router from '../../../../../router'
import { store } from '../../../../../store/base'
import { mapGetters } from 'vuex'

// Full Calendar Imports
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { createEventId } from './event-utils'
import CalendarEvent from './CalendarEvent'
import moment from 'moment'
import { getDateTimeFormat, timePassed } from '@common/lib/date-time'
import {
  CC_POST_RESCHEDULE_PLAN_ERROR,
  DRAFT_POST_RESCHEDULE_PLAN_ERROR,
  TIME_PASSED_RESCHEDULE_PLAN_ERROR
} from '@common/constants/messages'
import { getPlanMedia } from '@common/lib/planner'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import { planner } from '../../../store/mutation-types'
import { pusherSocketPublish } from '@common/lib/pusher'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEvent
  },
  computed: {
    ...mapGetters(['getPlans', 'getPlannerLoaders', 'getPublishSelection']),
    isFetchingPlans () {
      return this.getPlannerLoaders.fetch_plans
    },
    planItemsCount () {
      return this.getPlans.items.length
    },
    isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    },
    showNullPlansSection () {
      return !this.isFetchingPlans && !this.planItemsCount
    }
  },
  data: function () {
    return {
      calendarOptions: {
        // Plugins
        plugins: [
          dayGridPlugin,
          interactionPlugin // needed for dateClick
        ],

        // Components Options
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek'
        },

        // Custom
        customButtons: {
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('clicked the custom button!')
            }
          }
        },

        // Views Options
        editable: true,
        // selectable: true,
        // selectMirror: true,
        dayMaxEvents: 15,
        initialView: 'dayGridWeek',
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: { weekday: 'long' },
        views: {
          dayGrid: {
            dayCellContent: this.handleDayCellContent
          },
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric'
            }
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric'
            }
          }
        },
        timeZone: 'UTC',
        datesSet: this.handleDatesSet,
        viewDidMount: this.handleViewDidMount,
        // dayCellContent: this.handleDayCellContent,
        // dayCellClassNames: 'h-100 fc-options-hover',
        dayCellClassNames: 'fc-cell',
        dayHeaders: true,

        // Events Options
        initialEvents: [],
        events: [],
        eventColor: 'transparent',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDisplay: 'block',
        eventDrop: this.reschedulePlan

        // eventContent: this.handleEventContent
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
      labels_channel: '',
      view: 'dayGridWeek'
    }
  },
  created () {
    const query = Object.assign({}, this.$route.query)
    delete query.order_by
    this.$router.push({ query: query })
  },
  watch: {
    showNullPlansSection (newValue) {
      if (newValue) {
        this.$set(this.calendarOptions, 'events', this.getPlans.items)
      }
    },
    'getPlans.items' (newValue) {
      console.debug('Watch Plan', JSON.stringify(newValue))
      if (newValue.length) {
        this.$set(this.calendarOptions, 'events', newValue)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.labels_channel = pusherSocketPublish.subscribe(
        `labels_${this.getActiveWorkspace._id}`
      )
      this.bindPusherLabels(this.labels_channel)
    })
  },
  methods: {
    // https://preactjs.com/guide/v8/api-reference/ - createElement Guide
    handleDayCellContent (arg, createElement) {
      const day = moment(arg.date).utc().format('D')
      let dateEle = null

      if (arg.isToday) {
        dateEle = createElement(
          'span',
          {
            class: 'fc-daygrid-day-no active'
          },
          day
        )
      } else {
        dateEle = createElement(
          'span',
          {
            class: 'fc-daygrid-day-no'
          },
          day
        )
      }

      if (
        (arg.isFuture || arg.isToday) &&
        !arg.isOther &&
        this.hasPermission('can_access_top_header')
      ) {
        const post = createElement('i', {
          class: 'fas fa-plus',
          title: 'Create New Post',
          onclick: (e) => this.createContent(e, 'post', arg.isToday, arg.date)
        })

        const blog = createElement('i', {
          class: 'fas fa-file',
          title: 'Create New Blog',
          onclick: (e) => this.createContent(e, 'blog', arg.isToday, arg.date)
        })

        const icons = createElement(
          'span',
          {
            class: 'fc-daygrid-top-icon'
          },
          [post, blog]
        )

        return createElement('div', {}, [icons, dateEle])
      } else {
        return createElement('div', {}, dateEle)
      }
    },
    createContent (e, type, isToday, date) {
      console.debug('createContent', type)
      this.changePublishTypeFromCalender(type, isToday, date)
    },
    handleDayClassNames (arg) {
      console.log('handleDayClassNames', arg)
      if (arg.event) {
        console.log('handleDayClassNames event', arg.event.extendedProps)
      }
    },
    handleDateClick (arg) {
      alert('date click! ' + arg.dateStr)
    },
    handleViewDidMount (event) {
      console.log('handleViewDidMount', event)
      this.fetchPlansWithCalendarDate(event)
    },

    async handleDatesSet (event) {
      console.debug('handleDatesSet', this.fetchPlansWithCalendarDate)
      await this.fetchPlansWithCalendarDate(event)
    },
    async fetchPlansWithCalendarDate (event) {
      console.log('fetchPlansWithCalendarDate', event)
      const start = moment(event.view.activeStart).format('MMMM DD, YYYY')
      const end = moment(event.view.activeEnd).format('MMMM DD, YYYY')

      // this.$store.commit(planner.SET_CALENDAR_DATE_RANGE, `${start} - ${end}`)

      if (
        this.$route.query.date &&
        (this.$route.query.date.split('-')[0].trim() !== start.trim() ||
          this.$route.query.date.split('-')[1].trim() !== end.trim())
      ) {
        this.$router.push({
          query: { ...this.$route.query, date: start + ' - ' + end }
        })
        this.$store.commit(planner.SET_FETCH_PLANS_LOADER, true)
        await this.$store.dispatch('fetchPlans', this.$route)
      }

      // this.addItemIconToFullCalendar()
      // this.countTotalRenderedWeekEvents()
    },
    handleWeekendsToggle () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect (selectInfo) {
      // let title = prompt('Please enter a new title for your event')
      // let calendarApi = selectInfo.view.calendar
      // calendarApi.unselect() // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },
    async handleEventClick (info) {
      console.log('event click', info.jsEvent.target.getAttribute('data-event'))
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')
      if (event) {
        const item = JSON.parse(JSON.stringify(info.event.extendedProps))
        item.stateObject = this
        switch (event) {
          case 'viewItemAttachment':
            let plan = target.getAttribute('data-plan')
            if (plan) {
              // finding plan from states
              plan = item.stateObject.getPlans.items.find(
                (item) => item._id === plan
              )
              if (plan) {
                // checking for plan media
                const attachment = getPlanMedia(plan)
                if (attachment) {
                  EventBus.$emit('displayFile', {
                    type: attachment.type,
                    media: attachment.media,
                    index: 0
                  })
                  item.stateObject.$bvModal.show('display-file-modal')
                }
              }
            }
            break
          case 'editCalendarItem':
            if (item.can_perform && item.can_perform.edit) {
              this.$store.dispatch('editPlan', item)
            }
            break
          case 'deleteCalendarItem':
            const res = await this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this post?', {
                title: 'Remove Post',
                ...swalAttributes()
              })
              .then((res) => res)
              .catch(() => null)

            if (res) {
              const response = await this.$store.dispatch('removePlan', {
                id: item._id
              })
              // if (response) this.getPlans.items.splice(this.getPlans.items.findIndex(x => x._id === item._id), 1)
            }
            break
          case 'replaceCalendarItem':
            this.$store.commit(planner.SET_CONFIRMATION_PLAN, item)
            this.$bvModal.show('replacePlan')
            break
          case 'approveCalendarItem':
            this.changePlanStatusMethod('scheduled', item)
            // this.$store.dispatch('changePlanStatus', { id: item._id, status: 'scheduled' })
            break
          case 'rejectCalendarItem':
            this.changePlanStatusMethod('rejected', item)
            // await this.$store.dispatch('changePlanStatus', { id: item._id, status: 'rejected' })
            break
          case 'duplicateCalendarItem':
            this.$store.dispatch('clonePlan', item)
            break
          case 'calendarItemCheckbox':
            const planId = target.getAttribute('value')
            if (target.checked) {
              this.getPlans.selected_plans.push(planId)
            } else {
              this.getPlans.selected_plans.splice(
                this.getPlans.selected_plans.indexOf(planId),
                1
              )
            }
            console.log(target)
            console.log()
            console.log(target.checked)
            break
          case 'approvalCalendarItemReject':
            this.processPlanApproval(item, 'reject')
            break
          case 'approvalCalendarItemApprove':
            this.processPlanApproval(item, 'approve')
            break
        }
      } else {
        // let item = JSON.parse(JSON.stringify(info.event.extendedProps))
        // item.stateObject = this
        // if (item.can_perform && item.can_perform.edit) this.$store.dispatch('editPlan', item)
      }
    },
    handleEvents (events) {
      this.currentEvents = events
    },
    async reschedulePlan (info) {
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      item.stateObject = this
      if (!item.can_perform || !item.can_perform.edit) {
        info.revert()
        return false
      }
      console.log(item, info.event.start)
      item.start = moment(info.event.start)
      const executionTime =
        item.start.format('YYYY-MM-DD') +
        ' ' +
        getDateTimeFormat(item.execution_time.date, 'HH:mm:ss')
      console.debug('execution_time', executionTime)
      if (item.content_category_id && item.content_category_id.length > 0) {
        this.$store.dispatch('toastNotification', {
          message: CC_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error'
        })
        info.revert()
        return false
      }
      if (timePassed(executionTime)) {
        this.$store.dispatch('toastNotification', {
          message: TIME_PASSED_RESCHEDULE_PLAN_ERROR,
          type: 'error'
        })
        info.revert()
        return false
      }
      if (item.status === 'draft') {
        this.$store.dispatch('toastNotification', {
          message: DRAFT_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error'
        })
        info.revert()
        return false
      }

      const status = await this.$store.dispatch('reschedulePlan', item)
      if (!status) info.revert()
    }
  }
}
</script>

<style lang="scss">
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>
