import { ref, computed } from 'vue'
import {
  WORKSPACE_MEMBER_STATUS,
  WORKSPACE_ROLES,
} from '@common/constants/composer'

export default function (storeData, searchedMember) {
  console.log('composable useFetchMembers')
  const store = storeData
  const memberSearch = ref(searchedMember)
  const membersWorkspace = store.getters.getWorkspaces.activeWorkspace.members
  const profile = computed(() => store.getters.getProfile._id)

  function isWorkspaceMemberAllowedForApproval(workspaceMember) {
    return (
      workspaceMember?.user &&
      workspaceMember?.status === WORKSPACE_MEMBER_STATUS.JOINED &&
      (workspaceMember?.role !== WORKSPACE_ROLES.COLLABORATOR ||
        !workspaceMember?.permissions?.postsReview)
    )
  }

  /**
   * @description - This function is used to fetch the members of the specific workspace and also filter by search.
   */
  function fetchFilteredMembers() {
    let members
    const memberSearchQuery = memberSearch.value.toLowerCase()

    /**
     * @description - if there is any string to search, it will filter the members w.r.t the string else will return all the eligible members for approval
     */

    if (memberSearch.value && memberSearch.value.trim().length > 0) {
      members = membersWorkspace.filter(
        (workspaceMember) =>
          isWorkspaceMemberAllowedForApproval(workspaceMember) &&
          (`${workspaceMember?.user?.firstname?.toLowerCase()} ${workspaceMember?.user?.lastname?.toLowerCase()}`.includes(
            memberSearchQuery
          ) ||
            workspaceMember?.user?.email
              ?.toLowerCase()
              .includes(memberSearchQuery))
      )
    } else {
      members = membersWorkspace.filter((workspaceMember) =>
        isWorkspaceMemberAllowedForApproval(workspaceMember)
      )
    }
    /**
     * @description - active/current user is also a member but does not need to approve the post hence removing the active user from the approval members list
     */

    if (members.some((member) => member.user_id === profile.value)) {
      members = members.filter((member) => member.user_id !== profile.value)
    }

    console.log('members are:', members)
    return members
  }

  return {
    fetchFilteredMembers,
  }
}
