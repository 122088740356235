<template>
  <div class="no_post_available">
    <img
      src="../../../../../assets/img/analytics/no_post.svg"
      alt=""
      width="300"
      height="300"
    />

    <p class="text">
      You don't have any {{ name ? name : 'posts' }} in this time period
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {}
  }
}
</script>

<style scoped>
.no_post_available .text {
  font-size: 1.125rem;
  margin-bottom: 0;
  text-align: center;
}
</style>
