var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tweet-grid-item"},[_c('div',{staticClass:"box_inner tweet-grid-item__box_inner"},[_c('div',{staticClass:"content_block tweet-grid-item__box_inner__content_block"},[_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__height"},[_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__user"},[_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__user__profile"},[_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__user__profile__picture",style:(_vm.getTweetUserImage(_vm.tweet.user))}),_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__user__profile__text"},[_c('a',{staticClass:"text",attrs:{"target":"_blank","href":_vm.getTweetRedirectLink(_vm.tweet)}},[_vm._v(_vm._s(_vm.limitTextLength(_vm.tweet.user.name, 15)))]),(_vm.tweet.created_at)?_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.$filters.relative(_vm.tweet.created_at)))]):_vm._e()])])]),_c('p',{staticClass:"tweet-grid-item__box_inner__content_block__description",domProps:{"innerHTML":_vm._s(
            _vm.twitterText(
              _vm.tweet.text,
              _vm.tweet.entities.hashtags,
              _vm.tweet.entities.urls,
              _vm.tweet.entities.user_mentions
            )
          )}})]),_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__social_stats"},[_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__social_stats__inner"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Retweets'),expression:"'Retweets'",modifiers:{"top-center":true}}],staticClass:"tweet-grid-item__box_inner__content_block__social_stats__inner__item"},[_vm._m(0),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(_vm.tweet.retweet_count)))])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Favorite'),expression:"'Favorite'",modifiers:{"top-center":true}}],staticClass:"tweet-grid-item__box_inner__content_block__social_stats__inner__item"},[_vm._m(1),_c('div',{staticClass:"value"},[_c('h3',[_vm._v(_vm._s(_vm.intToString(_vm.tweet.favorite_count)))])])])])]),_c('div',{staticClass:"tweet-grid-item__box_inner__content_block__action_buttons"},[_c('b-dropdown',{ref:"dropdown_favorite",staticClass:"studio-icon-theme-dropdown mr-3",attrs:{"variant":"studio-icon-theme","id":"dropdown-favorite-articles","no-caret":"","block":""}},[_c('template',{slot:"button-content"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Share to social media'),expression:"'Share to social media'"}],staticClass:"icon-Share_icon",on:{"click":function($event){return _vm.initializeTwitterPostCreation(_vm.tweet)}}})])],2),_c('b-dropdown',{ref:"dropdown_favorite",staticClass:"studio-icon-theme-dropdown mr-3",attrs:{"variant":"studio-icon-theme","id":"dropdown-favorite-articles","no-caret":"","block":""}},[_c('template',{slot:"button-content"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Retweet'),expression:"'Retweet'"}],staticClass:"cs-refresh",on:{"click":function($event){$event.preventDefault();return _vm.twitterRetweetModal(_vm.tweet)}}})])],2)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_vm._v(" Retweet ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('p',[_vm._v(" Favorite ")])])
}]

export { render, staticRenderFns }