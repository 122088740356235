var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytic_common_component custom_report_main"},[_c('div',{staticClass:"component_inner"},[_c('div',{staticClass:"section_top_bar"},[_c('div',{staticClass:"section_top_bar_inner max_container_1800 d-flex height align-items-center"},[_c('div',{staticClass:"left basic_form"},[_c('div',{staticClass:"input_field mt-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getScheduledReports.query),expression:"getScheduledReports.query"}],attrs:{"type":"text","placeholder":"Search report..."},domProps:{"value":(_vm.getScheduledReports.query)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.getScheduledReports, "query", $event.target.value)}}})])])])]),_c('div',{staticClass:"report_list clear"},[_c('h2',{staticClass:"heading"},[_vm._v("My Reports")]),_c('div',{staticClass:"clearfix"}),(_vm.reportsList.length > 0)?[_c('div',{staticClass:"row"},_vm._l((_vm.reportsList),function(item,key){return _c('div',{staticClass:"report_box"},[_c('div',{staticClass:"box_inside"},[_c('i',{staticClass:"icon d-flex align-items-center justify-content-center fa",class:_vm.getIconClass(item.type)}),_c('h3',[_vm._v(_vm._s(item.name))]),_c('div',[_c('div',{staticClass:"d-flex bg_main pb-2"},[_vm._l((_vm.getAccounts(item)),function(channel,index){return [(index < 3)?_c('div',{key:index,staticClass:"flex-row"},[_c('div',{staticStyle:{"margin-left":"-10px"}},[_c('img',{staticClass:"bg",attrs:{"src":channel.image}}),_c('i',{staticClass:"bg_icon",class:_vm.getPlatform(channel.account_type)})])]):_vm._e()]}),(
                      item.accounts != null && _vm.getAccounts(item).length > 3
                    )?_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"bg_count",staticStyle:{"margin-left":"-10px"}},[_c('p',[_vm._v("+ "+_vm._s(_vm.getAccounts(item).length - 3))])])]):_vm._e()],2)]),_c('p',{staticClass:"report-created-at"},[_vm._v("Created: "+_vm._s(_vm._f("publishedOnlyDateWithShortWeekday")(item.created_at)))]),_c('p',{staticClass:"report-interval"},[_c('span',[_vm._v(" "+_vm._s(item.interval)+" ")])]),_c('div',{staticClass:"btn_block"},[_c('i',{staticClass:"fa fa-edit",on:{"click":function($event){return _vm.editScheduledReport(item)}}}),_c('i',{staticClass:"icon-delete-cs",on:{"click":function($event){return _vm.removeScheduledReportService({
                      reportId: item._id,
                      index: key,
                    })}}})])])])}),0)]:(_vm.reportsList.length === 0)?[_vm._m(0)]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"not_found_text"},[_c('div',{staticClass:"no_data_found_content"},[_c('div',{staticClass:"no_data_found_content_inner"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../../assets/img/no_data_images/no_data_found.svg"),"alt":""}})]),_c('p',[_vm._v("No reports found for your searched query.")])])])])
}]

export { render, staticRenderFns }