<template>
  <div class="basic_form">
    <div class="field_group field_no_label">
      <input
        id="ws-name"
        ref="workspace_name_modal"
        v-model="getWorkspaceDetails.name"
        class="relative z-10"
        data-cy="workspace_name"
        type="text"
        placeholder="Workspace Name"
        maxlength="35"
        @keydown="fetchWorkspaceLogoSuggessions($event)"
      />
      <label for="ws-name" class="label-animated z-0">Workspace Name - e.g. Nike</label>
    </div>

<!--    <div-->
<!--      class="field_group field_no_label"-->
<!--      :class="{-->
<!--        input_loader: getSlugAvailableLoader,-->
<!--        input_error:-->
<!--          (getWorkspaceSlugValidation &&-->
<!--            getWorkspaceSlugValidation == 'invalid' &&-->
<!--            getWorkspaceDetails.slug.length > 0) ||-->
<!--          (getWorkspaceSlugValidation &&-->
<!--            getWorkspaceSlugValidation == 'already_used' &&-->
<!--            getWorkspaceDetails.slug.length > 0),-->
<!--      }"-->
<!--    >-->
<!--      <input-->
<!--        id="ws-slug"-->
<!--        ref="workspace_slug_modal"-->
<!--        data-cy="workspace_slug"-->
<!--        type="text"-->
<!--        @keyup="slugUpdated()"-->
<!--        placeholder="Workspace Slug - e.g. mybrand"-->
<!--        v-model="getWorkspaceDetails.slug"-->
<!--      />-->
<!--      <label for="ws-slug" class="label-animated">Workspace Slug</label>-->

<!--      <template v-if="getSlugAvailableLoader">-->
<!--        <clip-loader size="15px"></clip-loader>-->
<!--      </template>-->

<!--      <template-->
<!--        v-if="-->
<!--          getWorkspaceSlugValidation &&-->
<!--          getWorkspaceSlugValidation == 'invalid' &&-->
<!--          getWorkspaceDetails.slug.length > 0-->
<!--        "-->
<!--      >-->
<!--        <span class="error error_field">-->
<!--          URL must be letters, numbers and dashes only.-->
<!--        </span>-->
<!--      </template>-->
<!--      <template-->
<!--        v-else-if="-->
<!--          getWorkspaceSlugValidation &&-->
<!--          getWorkspaceSlugValidation == 'already_used' &&-->
<!--          getWorkspaceDetails.slug.length > 0-->
<!--        "-->
<!--      >-->
<!--        <span class="error error_field">-->
<!--          Your workspace slug has been already used. Please use a different-->
<!--          slug.-->
<!--        </span>-->
<!--      </template>-->
<!--    </div>-->

    <!--<div class="input_field "-->
    <!--:class="{'input_loader':getSlugAvailableLoader,'input_error' : getWorkspaceSlugValidation && getWorkspaceSlugValidation == 'invalid' && getWorkspaceDetails.slug.length > 0 || getWorkspaceSlugValidation && getWorkspaceSlugValidation == 'already_used' && getWorkspaceDetails.slug.length > 0}">-->

    <!--&lt;!&ndash;<div class="input_block">&ndash;&gt;-->
    <!--&lt;!&ndash;<input data-cy="workspace_slug" type="text" @keyup="slugUpdated()" placeholder="Workspace Slug - e.g. mybrand"&ndash;&gt;-->
    <!--&lt;!&ndash;v-model="getWorkspaceDetails.slug">&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash; @arif adjust its position so that it comes under the input  but on the right side section &ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;<template v-if="getSlugAvailableLoader">&ndash;&gt;-->
    <!--&lt;!&ndash;<clip-loader size="15px"></clip-loader>&ndash;&gt;-->
    <!--&lt;!&ndash;</template>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->

    <!--&lt;!&ndash;<template&ndash;&gt;-->
    <!--&lt;!&ndash;v-if="getWorkspaceSlugValidation && getWorkspaceSlugValidation == 'invalid' && getWorkspaceDetails.slug.length > 0">&ndash;&gt;-->
    <!--&lt;!&ndash;<span class="error error_field">&ndash;&gt;-->
    <!--&lt;!&ndash;URL must be letters, numbers and dashes only.&ndash;&gt;-->
    <!--&lt;!&ndash;</span>&ndash;&gt;-->
    <!--&lt;!&ndash;</template>&ndash;&gt;-->
    <!--&lt;!&ndash;<template&ndash;&gt;-->
    <!--&lt;!&ndash;v-else-if="getWorkspaceSlugValidation && getWorkspaceSlugValidation == 'already_used' && getWorkspaceDetails.slug.length > 0">&ndash;&gt;-->
    <!--&lt;!&ndash;<span class="error error_field">&ndash;&gt;-->
    <!--&lt;!&ndash;Your workspace slug has been already used. Please use a different slug.&ndash;&gt;-->
    <!--&lt;!&ndash;</span>&ndash;&gt;-->
    <!--&lt;!&ndash;</template>&ndash;&gt;-->

    <!--</div>-->

    <div class="field_group">
      <label for="super-admin">Set Workspace Time Zone</label>
      <div
        class="timezone_search_dropdown social_dropdown dropdown default_style_dropdown"
      >
        <div
          data-toggle="dropdown"
          class="dropdown_header d-flex align-items-center"
          data-cy="create_workspace_timezone_clickable"
        >
          <div class="text d-inline-block align-middle">{{
            getWorkspaceDetails.timezone
          }}</div>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
          data-cy="create_workspace_timezone"
        >
          <ul class="inner">
            <div class="search_input with_icon_right">
              <div class="search_inner w-100">
                <input
                  id="search_timezone"
                  v-model="search_timezone"
                  type="text"
                  placeholder="Search by name"
                  data-cy="search-timezone"
                />
              </div>
            </div>

            <template v-if="getFilteredTimezones.length">
              <template v-for="(timezone, key) in getFilteredTimezones">
                <li
                  :key="key"
                  class="list_item_li"
                  data-cy="timezone-selection"
                  @click.prevent="changeTimezone(timezone.value)"
                >
                  {{ timezone.name }}
                </li>
              </template>
            </template>

            <li v-if="!getFilteredTimezones.length" class="no_text_li">
              No Results found
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Set Super Admin Field -->
    <div class="field_group">
      <label for="super-admin">Set Super Admin</label>
      <div
        class="timezone_search_dropdown social_dropdown dropdown default_style_dropdown"
      >
        <div
          data-toggle="dropdown"
          :class="{
            disabled: getOtherWorkspacesSuperAdmins().length === 0,
          }"
          class="dropdown_header d-flex align-items-center"
          data-cy="create_workspace_super_admin_clickable"
        >
          <div class="text d-inline-block align-middle">{{
            selectedSuperAdmin.full_name +
            ' - ' +
            selectedSuperAdmin.email
          }}</div>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
          data-cy="create_workspace_timezone"
        >
          <ul class="inner">
            <template>
              <li
                v-if="!isNoSubscription() && isProfileUserTrialFinished()"
                class="list_item_li id disabled_super_admin"
              >
                {{ getProfile.full_name + ' - ' + getProfile.email }}
                <span>Trial Finished</span>
              </li>
              <li
                v-if="!isNoSubscription() && !isProfileUserTrialFinished()"
                class="list_item_li id"
                @click.prevent="changeSuperAdmin(null, true)"
              >
                {{ getProfile.full_name + ' - ' + getProfile.email }}
              </li>
              <template
                v-for="(
                  superAdmin, key
                ) in getOtherWorkspacesSuperAdmins()"
              >
                <li
                  v-if="isActiveUser(superAdmin.user)"
                  :key="key"
                  class="list_item_li"
                  @click.prevent="changeSuperAdmin(superAdmin.user, false)"
                >
                  {{
                    superAdmin.user.full_name + ' - ' + superAdmin.user.email
                  }}
                </li>
                <li v-else :key="key" class="disabled_super_admin list_item_li">
                  {{
                    superAdmin.user.full_name + ' - ' + superAdmin.user.email
                  }}
                  <span>{{ getUserState(superAdmin.user.state) }}</span>
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="">Workspace Logo</label>
        <div class="upload_logo">
          <div class="image_upload">
            <div
              class="upload_section"
              :class="{
                loader_overlay_with_loader:
                  getSettingLoaders.uploadWorkspaceLogo,
              }"
            >
              <!--// for real dummy image -->
              <div v-if="!getWorkspaceDetails.logo" class="img">
                <img
                  class="picture"
                  src="../../../../assets/img/settings/leaf_upload_image.svg"
                  alt=""
                />
              </div>
              <!--// for real image -->
              <div v-else class="img">
                <img class="picture" :src="getWorkspaceDetails.logo" alt="" />
              </div>

              <clip-loader
                v-if="getSettingLoaders.uploadWorkspaceLogo"
                class="center_loader"
                color="#436aff"
                :size="'16px'"
              ></clip-loader>
              <div
                v-else
                data-cy="workspace_logo"
                :class="{ image_default: !getWorkspaceDetails.logo }"
                class="upload_btn_block"
                @click="triggerWorkspaceChangeLogo('w_logo_image_new')"
              >
                <input
                  id="w_logo_image_new"
                  type="file"
                  autocomplete="off"
                  accept="image/*"
                  @change.prevent="uploadWorkspaceLogo($event, true)"
                />
                <i class="icon-Camera"></i>
                <!--<img class="profile_img_btn"-->
                <!--src="../../../../app/src/assets/img/settings/upload_profile_icon.svg" alt="">-->
                <p v-if="!getWorkspaceDetails.logo">Upload</p>
                <p v-else>Change</p>
              </div>
            </div>
            <p>Recommended size: Less than 2MB (130px x 130px)</p>
          </div>
        </div>
      </div>
      <div
        v-if="
          getWorkspaceDetails.logoSuggestions.length ||
          getWorkspaceLogoSuggestionsLoader
        "
        class="col-md-8"
      >
        <label for="">Logo Suggestions</label>
        <div class="clearfix"></div>
        <div
          class="workspace_logo_suggestions"
          data-cy="workspace_logo_suggestions"
        >
          <div class="logo_suggestions_inner">
            <ul>
              <li
                v-if="getWorkspaceLogoSuggestionsLoader"
                class="loader_overlay_with_loader"
              >
                <clip-loader :color="'#5c77f8'" :size="'16px'"></clip-loader>
              </li>
              <li
                v-for="(
                  suggestion, index
                ) in getWorkspaceDetails.logoSuggestions"
                v-else
                :key="index"
                class=""
                data-cy="workspace_logo_suggestion"
                @click="SET_WORKSPACE_LOGO(suggestion.logo)"
              >
                <img :src="suggestion.logo" alt="" />
              </li>
            </ul>
          </div>

          <p v-if="getWorkspaceLogoSuggestionsLoader === false"
            >Click on one of the logos above to set as the Workspace logo.</p
          >
        </div>
      </div>
    </div>

    <div class="text-center">
      <button
        data-cy="submit_workspace"
        class="btn btn-studio-theme-space"
        :disabled="getSettingLoaders.saveWorkspace"
        @click.prevent="saveWorkspace(true, true)"
      >
        <span>Save</span>
        <clip-loader
          v-if="getSettingLoaders.saveWorkspace"
          :color="'#ffffff'"
          :size="'16px'"
        ></clip-loader>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { timezones } from '@src/modules/setting/config/timezone.js'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'

export default {
  components: {},

  data () {
    return {
      timezones: timezones,
      slugError: false,
      manuallySlugUpdated: false,
      search_timezone: '',
      selectedSuperAdmin: {
        full_name: '',
        email: '',
        id: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaceDetails',
      'getSettingLoaders',
      'getWorkspaceLogoSuggestionsLoader',
      'getSlugAvailableLoader',
      'getWorkspaceSlugValidation',
      'getProfile'
    ]),
    getFilteredTimezones () {
      return this.timezones.filter((s) =>
        s.value.toLowerCase().includes(this.search_timezone.toLowerCase())
      )
    }
  },

  created () {},
  mounted () {
    this.fetchWorkspaceLogoSuggessions('social media')
    if (!this.getWorkspaceDetails.name && !this.getWorkspaceDetails.slug) {
      this.$store.commit(
        workspaceTypes.SET_WORKSPACE_TIMEZONE,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    }

    // set Selected Super Admin
    this.$nextTick(() => {
      if (!this.isNoSubscription() && !this.isProfileUserTrialFinished()) {
        this.selectedSuperAdmin.full_name = this.getProfile.full_name
        this.selectedSuperAdmin.email = this.getProfile.email
        this.selectedSuperAdmin.id = this.getProfile._id
        this.getWorkspaceDetails.superAdmin = this.getProfile._id
      } else if (this.getOtherWorkspacesSuperAdmins().length > 0) {
        for (const superAdmin of this.getOtherWorkspacesSuperAdmins()) {
          if (superAdmin.user.state === 'active') {
            this.selectedSuperAdmin.full_name = superAdmin.user.full_name
            this.selectedSuperAdmin.email = superAdmin.user.email
            this.selectedSuperAdmin.id = superAdmin.user._id
            this.getWorkspaceDetails.superAdmin = superAdmin.user._id
            break
          }
        }
      } else if (this.isProfileUserTrialFinished()) {
        this.$router.replace({ name: 'trial_expired' })
      }
    })
  },

  methods: {
    ...mapMutations([workspaceTypes.SET_WORKSPACE_LOGO]),
    slugUpdated () {
      this.manuallySlugUpdated = true
    },
    isActiveUser (user) {
      return user.state === 'active'
    },
    isProfileUserTrialFinished () {
      return this.getProfile.state === 'trial_finished'
    },
    isNoSubscription () {
      return this.getProfile.subscription_name === 'No Package'
    },
    getUserState (state) {
      switch (state) {
        case 'trial_finished':
          return 'Trial Finished'
        default:
          return state.replace('_', ' ')
      }
    },

    getOtherWorkspacesSuperAdmins () {
      var wsSuperAdmins = []
      this.filteredWorkspacesOwnedBy(false).forEach((workspace) => {
        if (workspace.role === 'admin') {
          workspace.workspace.members.forEach((member) => {
            if (
              member.role === 'super_admin' &&
              wsSuperAdmins.filter((admin) => admin.user_id === member.user_id)
                .length === 0
            ) {
              wsSuperAdmins.push(member)
            }
          })
        }
      })
      return wsSuperAdmins
    },
    changeSuperAdmin (superAdmin, isProfileUser) {
      console.debug('Method::changeSuperAdmin', superAdmin)
      if (!superAdmin && isProfileUser) {
        this.selectedSuperAdmin.full_name = this.getProfile.full_name
        this.selectedSuperAdmin.email = this.getProfile.email
        this.selectedSuperAdmin.id = this.getProfile._id
        this.getWorkspaceDetails.superAdmin = this.getProfile._id
      } else {
        this.selectedSuperAdmin.full_name = superAdmin.full_name
        this.selectedSuperAdmin.email = superAdmin.email
        this.selectedSuperAdmin.id = superAdmin._id
        this.getWorkspaceDetails.superAdmin = superAdmin._id
      }
    },
    changeTimezone (timezone) {
      console.debug('Method::changeTimezone', timezone)
      this.getWorkspaceDetails.timezone = timezone
      this.search_timezone = ''
    }
  },
}
</script>

<style lang="scss" scoped>
  .field_no_label input:not(:placeholder-shown) + label {
    z-index: 0;
  }
</style>
