import { intToString } from '@common/lib/helper'

export default {
  methods: {
    renderInsightsResponse (resp) {
      const response = {
        ...this.topPublishedEngagementAndSentiments(resp),
        // articles_sentiments_with_count: this.sentimentsMetrics(resp.data.insights.aggregations.average_sentiment_statistics),
        articles_engagement_with_count: this.insightsEngagementMetrics(
          resp.data.insights.aggregations
        ),
        articles_categories_list: this.popularContentTypesList(
          resp.data.insights.aggregations.categories_list
        ),
        articles_wordcloud_list: this.wordCloudListItems(
          resp.data.insights.aggregations.top_popular_hashtags.buckets
        ),
        articles_popular_reading_wordcount_list: this.wordCountLevelList(
          resp.data.insights.aggregations.temporarily_popular_word_count.buckets
        ),
        articles_popular_reading_levels_list: this.popularReadingLevels(
          resp.data.insights.aggregations.popular_reading_levels.buckets
        ),
        articles_popular_days: resp.data.insights.aggregations.popular_days,
        articles_popular_domains: this.topPopularDomains(
          resp.data.insights.aggregations.top_domains.buckets
        ),
        articles_popular_authors:
          resp.data.insights.aggregations.top_authors.buckets
      }
      console.log(response)
      return response
    },
    topPublishedEngagementAndSentiments (resp) {
      const articlesPublishedArrayPostsCount = []
      const articlesPublishedarrayEngagement = []
      const sentiments = {
        positive: [],
        negative: [],
        neutral: []
      }
      // sentiment analysis
      // published articles over time with their average shares.

      resp.data.insights.aggregations.articles_published_per_day.buckets.forEach(
        (element) => {
          const parsedDate = Date.parse(element.key_as_string)
          const docCount = element.doc_count || 0
          const averageStats = element.avg_stats.value || 0
          articlesPublishedArrayPostsCount.push([parsedDate, docCount])
          articlesPublishedarrayEngagement.push([
            parsedDate,
            Math.ceil(averageStats)
          ])
          // element.sentiment.sentiment_labels.buckets.forEach(function (item) {
          //   let itemDocCount = 0
          //   console.debug(parsedDate, item.doc_count)
          //
          //   if (item.doc_count) {
          //     itemDocCount = item.doc_count
          //   }
          //   switch (item.key) {
          //     case 'neutral':
          //     case 'positive':
          //     case 'negative':
          //       sentiments[item.key].push([parsedDate, itemDocCount])
          //       break
          //     default:
          //       break
          //   }
          // }, this)
        },
        this
      )
      return {
        articles_published_posts_count: articlesPublishedArrayPostsCount,
        articles_published_engagement: articlesPublishedarrayEngagement,
        articles_sentiments: sentiments
      }
    },
    sentimentsMetrics (sentiments) {
      const totalDocs = sentiments.doc_count
      const sentimentsCount = {
        percentage: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        engagement: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        docs: {
          positive: 0,
          negative: 0,
          neutral: 0
        }
      }
      sentiments.sentiment_labels.buckets.forEach((item) => {
        const itemDocCount = item.doc_count || 0
        switch (item.key) {
          case 'neutral':
          case 'positive':
          case 'negative':
            console.log('sentiment metrics', item)
            sentimentsCount.percentage[item.key] = (
              (item.doc_count / totalDocs) *
              100
            ).toFixed(0)
            sentimentsCount.docs[item.key] = itemDocCount
            sentimentsCount.engagement[item.key] = parseInt(
              (item.social_shares.average_engagements.value || 0).toFixed(0)
            )
            break
          default:
            break
        }
      })
      return sentimentsCount
    },
    insightsEngagementMetrics (aggregation) {
      const engagement = {
        average: {
          facebook: parseInt(aggregation.average_facebook.value) || 0,
          pinterest: parseInt(aggregation.average_pins.value) || 0,
          reddit: parseInt(aggregation.average_reddit.value) || 0
        },
        sum: {
          facebook: parseInt(aggregation.total_facebook.value) || 0,
          pinterest: parseInt(aggregation.total_pins.value) || 0,
          reddit: parseInt(aggregation.total_reddit.value) || 0
        }
      }

      return engagement
    },
    popularContentTypesList (contentList) {
      const contentTypes = {
        categories: [],
        engagements: [],
        docs: []
      }
      contentList.buckets.forEach((item) => {
        let average = 0
        if (item.average.value) {
          average = Math.ceil(item.average.value)
        }
        contentTypes.categories.push(item.key)
        contentTypes.engagements.push(average)
        contentTypes.docs.push(item.doc_count)
      }, this)
      return contentTypes
    },
    wordCloudListItems (bucket) {
      const wordcloudList = []
      bucket.forEach(function (item) {
        const removeHashtag = item.key.replace('#', '')
        const splitWord = removeHashtag.replace(/([a-z])([A-Z])/g, '$1 $2')
        wordcloudList.push({
          name: splitWord,
          weight: item.doc_count
        })
      })
      return wordcloudList
    },
    wordCountLevelList (range) {
      let wordCountFirstRange = range
      const wordCountSecondRange = range

      if (wordCountFirstRange.length > 4) {
        // pick only first two elements

        if (wordCountFirstRange.length > 2) {
          wordCountFirstRange = wordCountFirstRange.slice(0, 2)
        }
        // remove first element which is 0 index.
        if (wordCountSecondRange.length > 2) {
          wordCountSecondRange.splice(0, 1)
        }

        // merge two dicts and pass the value to set for the chart.
        console.debug(wordCountFirstRange, wordCountSecondRange)
        return this.popularReadingWordCountList(
          wordCountFirstRange.concat(wordCountSecondRange)
        )
      } else {
        return this.popularReadingWordCountList(wordCountFirstRange)
      }
    },
    popularReadingWordCountList (bucket) {
      const wordCountList = {
        categories: [],
        articles: [],
        interactions: []
      }
      let count = 0
      bucket.forEach(function (item) {
        count += 1
        let docCount = 0
        let avgStats = 0
        if (count <= bucket.length - 1) {
          console.debug(item)
          let countValue = parseInt(item.key)
          const nextItem = bucket[count]

          countValue =
            intToString(countValue).toString() +
            ' - ' +
            intToString(nextItem.key).toString()
          docCount = item.doc_count
          avgStats = item.avg_stats.value

          wordCountList.categories.push(countValue)
          wordCountList.articles.push(docCount)
          wordCountList.interactions.push(parseInt(avgStats))
        }
      }, this)
      return wordCountList
    },

    popularReadingLevels (bucket) {
      const readingLevel = {
        categories: [],
        articles: [],
        interactions: []
      }
      bucket.forEach(function (item) {
        console.debug(item)
        readingLevel.categories.push(item.key)
        readingLevel.articles.push(item.doc_count)
        readingLevel.interactions.push(parseInt(item.avg_stats.value))
      }, this)

      console.log('readingLevel', readingLevel)
      return readingLevel
    },
    topPopularDomains (bucket) {
      const domainsList = []
      bucket.forEach((item) => {
        console.debug(item)
        domainsList.push([item.key, item.doc_count])
      }, this)
      return {
        domains_list_for_chart: domainsList,
        domains_list: bucket
      }
    }
  }
}
