<template>
  <div class="topic_inner">
    <!--        <article-preview></article-preview>-->
    <div class="heading">
      <h3>Preview</h3>
      <p>
        <template v-if="$route.path.includes('/discovery/content/topics')">
          This is the type of content you will get in your custom topics, if the
          content presented to you is not matching your criteria, please try
          excluding the irrelevant domains and keywords to refine your results.
        </template>
        <template v-else>
          This is the type of content you will get through this automation
          campaign. You should mainly look if content presented to you is
          matching your criteria, if not, you can exclude the irrelevant results
          by using exclude feature.
        </template>
      </p>
      <div class="warning_box text-center" style="margin-top: 19px"
        ><p style="font-size: 0.9rem">
          Note: While we plan your posts, broken image posts will be excluded
          from your results, and therefore the number of posts might differ from
          the preview you see below</p
        >
      </div>
      <h4 v-if="!getPreviewSuggestionLoader"
        ><span>{{ getPreviewSuggestion.total }}</span> results matched for your
        query.</h4
      >
    </div>
    <template v-if="getPreviewSuggestionLoader">
      <div class="pt-3 pb-3">
        <beat-loader :color="'#436aff'"></beat-loader
      ></div>
    </template>

    <div class="topic_listed_items">
      <template v-if="getPreviewSuggestion.posts.length > 0">
        <div
          class="topic_content_box"
          v-for="(post, index) in getPreviewSuggestion.posts"
          :key="index + '_box'"
        >
          <div class="box_inner">
            <div class="d-flex">
              <div class="image_block">
                <div
                  class="img"
                  v-lazy:background-image="backgroundImageURL(post._source)"
                ></div>
              </div>
              <div class="content_block">
                <div class="content_block_inner">
                  <h3
                    @click.prevent="
                      articlePreview({ id: post._source.id, index: index })
                    "
                    v-if="post._source && post._source.title"
                    v-html="post._source.title"
                  ></h3>
                  <div
                    v-if="post._source && post._source.domain_url"
                    class="detail_box d-flex align-items-center"
                  >
                    <a href="javascript:;" class="domain_name">{{
                      trimURL(post._source.domain_url)
                    }}</a>
                    <!--<p class="more">More from this domain</p>-->
                  </div>
                  <div class="detail_box d-flex align-items-center">
                    <p
                      class="date"
                      v-if="post._source && post._source.post_date"
                      >{{ $filters.relative(post._source.post_date) }}</p
                    >
                    <p class="author" v-if="post._source && post._source.author"
                      >By <span>{{ post._source.author }}</span></p
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="footer clear">
              <div class="social_detail">
                <div
                  class="m-auto-fit popover-social-statistics topics_preview_suggestions"
                >
                  <shared-count-tooltip :post="post" />
                  <i class="icon-engagement-ico"></i>
                  <span class="state_txt" v-if="post._source">{{
                    intToString(calculateEngagements(post._source))
                  }}</span>
                  <span class="state_txt" v-else>0</span>
                </div>
              </div>
              <div class="social_detail">
                <template
                  v-if="
                    post &&
                    post._source &&
                    post._source.sentiment &&
                    post._source.sentiment.length > 0
                  "
                >
                  <i
                    class="cs-sad-face sad"
                    v-if="post._source.sentiment[0].label == 'negative'"
                    v-tooltip.top-center="'Negative'"
                  ></i>
                  <i
                    class="cs-happy-face smile"
                    v-if="
                      post._source.sentiment[0].label == 'positive' &&
                      post._source.sentiment[0].probability >= 0.65
                    "
                    v-tooltip.top-center="'Positive'"
                  ></i>
                  <i
                    class="cs-normal-face normal"
                    v-else-if="
                      post._source.sentiment[0].label == 'positive' &&
                      post._source.sentiment[0].probability < 0.65
                    "
                    v-tooltip.top-center="'Positive'"
                  ></i>
                  <i
                    class="cs-normal-face normal"
                    v-if="post._source.sentiment[0].label == 'neutral'"
                    v-tooltip.top-center="'Neutral'"
                  ></i>
                </template>
              </div>

              <!--<div class="social_detail">-->
              <!--<i class="fb cs-facebook" v-tooltip.top-center="'Facebook Share'"></i>-->
              <!--<span class="state_txt" v-if="post._source.facebook_shares">{{intToString(post._source.facebook_shares)}}</span>-->
              <!--<span class="state_txt" v-else>0</span>-->
              <!--</div>-->
              <!--<div class="social_detail">-->
              <!--<i class="pin  cs-pinterest" v-tooltip.top-center="'Pinterest Share'"></i>-->
              <!--<span class="state_txt" v-if="post._source.pins">{{intToString(post._source.pins)}}</span>-->
              <!--<span class="state_txt" v-else>0</span>-->

              <!--</div>-->
              <!--<div class="social_detail">-->
              <!--<i class="reddit cs-reddit" v-tooltip.top-center="'Reddit Share'"></i>-->
              <!--<span class="state_txt"v-if="post._source.reddit">{{intToString(post._source.reddit)}}</span>-->
              <!--<span class="state_txt"v-else>0</span>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no_data_found_content" v-if="!getPreviewSuggestionLoader">
          <no-results-found></no-results-found>
        </div>
      </template>
      <!-- show the loader in case of the paginated posts-->
      <template
        v-if="
          getPreviewSuggestion.posts.length >= 20 &&
          getPreviewSuggestionInfiniteLoader == false &&
          getPreviewSuggestionNoMoreResult == false
        "
      >
        <span class="pt-5 d-block">
          <beat-loader :color="'#436aff'"></beat-loader>
        </span>
      </template>
      <template
        v-if="
          getPreviewSuggestionNoMoreResult &&
          getPreviewSuggestion.posts.length > 20
        "
      >
        <no-results-found
          :more="true"
          :message="'No more results found. Please try adding more keywords or domains'"
        ></no-results-found>
      </template>
      <infinite-loading
        v-if="
          getPreviewSuggestionInfiniteLoader &&
          getPreviewSuggestionNoMoreResult == false
        "
        @infinite="onInfinite"
        ref="infiniteLoading"
      >
        <span slot="spinner" class="pt-5 d-block">
          <beat-loader :color="'#436aff'"></beat-loader>
        </span>
        <span slot="no-more" class="not_found_text">
          <no-results-found :more="true"></no-results-found>
        </span>
      </infinite-loading>

      <!--<template v-else>

            </template>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from './NoResultsFound'
import ArticlePreview from '../common/preview/ArticlePreview'
import SharedCountTooltip from '../common/snippets/SharedCountTooltip'
export default {
  components: {
    InfiniteLoading,
    NoResultsFound,
    ArticlePreview,
    SharedCountTooltip
  },
  methods: {
    ...mapActions(['articlePreview', 'articlesPreviewSuggestion']),
    onInfinite ($state) {
      console.log('PreviewSuggestion infinite Loading...')
      if (this.getPreviewSuggestionScroll) {
        console.log('Inside infinite loading ...')

        this.setPreviewSuggestionPage(this.getPreviewSuggestionPage + 1)
        this.articlesPreviewSuggestion({ pagination: true, $state: $state })
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getPreviewSuggestion',
      'getPreviewSuggestionScroll',
      'getPreviewSuggestionPage',
      'getPreviewSuggestionInfiniteLoader',
      'getPreviewSuggestionLoader',
      'getPreviewSuggestionNoMoreResult'
    ])
  }
}
</script>
