<template>
  <confirm-box
    id="removeHashtag"
    class="small_width"
    :callBackAction="removeTag"
    confirmText="Yes"
    cancelText="No"
  >
    <template slot="header">
      <h2>Remove Hashtag</h2>
    </template>

    <template slot="footer">
      <p class="text-center">This action cannot be undone.</p>
    </template>
  </confirm-box>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
