<script>
import AutomationScheduleOptions from '../../ScheduleOptions'
import { evergreen } from '../../../store/automation-mutation-type'
import { mapGetters } from 'vuex'

export default {
  components: {
    AutomationScheduleOptions
  },
  computed: {
    ...mapGetters(['getEvergreenSelection'])
  },
  created () {
    this.$store.commit(evergreen.SET_EVERGREEN_AUTOMATION_TAB_STATUS, 'third')
    // check automation name and account selection exist in case of direct load of page
    if (this.$parent.isValidStep) {
      this.$parent.redirectEvergreen('saveEvergreenAutomationAccounts')
      return false
    }
  },
  mounted () {
    // adding event listener for enter key
    document.addEventListener('keyup', this.keyPressListener)
  },
  destroyed () {
    // removing event listener when component destroy
    document.removeEventListener('keyup', this.keyPressListener)
  },
  methods: {
    // if enter is press than verify route and process third step
    keyPressListener (event) {
      if (
        event.keyCode === 13 &&
        this.$route.name === 'saveEvergreenAutomationOptimization'
      ) {
        this.$parent.processStepThird()
      }
    }
  }
}
</script>

<template>
  <div class="page_2 post_optimization">
    <div class="automation_page schedule_campaign width_small">
      <div class="page_inner basic_form">
        <div class="step_heading">
          <h3>Step 3 - Schedule</h3>
        </div>
        <automation-schedule-options
          :tooltipValue="'Configure the time-slots for this campaign'"
          type="evergreen"
        ></automation-schedule-options>

        <div class="cycle_selction" v-if="getEvergreenSelection.id">
          <div class="input_field">
            <label>When should the changes applied?</label>

            <div class="radio_list">
              <div class="radio_input_image">
                <input
                  id="replan_next_cycle"
                  name="replanType"
                  type="radio"
                  value="Later"
                  v-model="getEvergreenSelection.replanType"
                />
                <label for="replan_next_cycle" class="radio_left"
                  >Apply changes from next cycle</label
                >
              </div>
              <div class="radio_input_image">
                <input
                  id="replan_now"
                  type="radio"
                  name="replanType"
                  value="Now"
                  v-model="getEvergreenSelection.replanType"
                />
                <label for="replan_now" class="radio_left"
                  >Apply changes now and restart cycle</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="field_group text_center">
          <a
            href=""
            class="btn btn-studio-theme-transparent-grey mr-1"
            @click.prevent="
              $parent.redirectEvergreen('saveEvergreenAutomationFinalize')
            "
          >
            <i class="icon_left cs-angle-left"></i>
            <span>Prev</span>
          </a>
          <button
            class="btn btn-studio-theme-space"
            :disabled="getEvergreenSelection.saveEvergreenContent"
            @click.prevent="$parent.processStepThird()"
            data-cy="done-button"
          >
            <clip-loader
              v-if="getEvergreenSelection.saveEvergreenContent"
              color="#ffffff"
              :size="'12px'"
            ></clip-loader>
            <span>Done</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
