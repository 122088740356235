<script>
import {
  getPlatformByIdentifier,
  getPlatformName,
} from '@common/lib/integrations'
import {
  blogIntegrationsNames,
  blogIntegrationsConfigrations,
} from '@src/modules/integration/config/api-utils.js'

export default {
  components: {},
  props: {
    blog_selection: {
      // eslint-disable-line vue/prop-name-casing
      type: Object,
      default: () => {},
    },
    account_selection: {
      // eslint-disable-line vue/prop-name-casing
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      integrations: blogIntegrationsNames,
      configrations: blogIntegrationsConfigrations,
    }
  },
  methods: {
    platformText(platform) {
      return getPlatformName(platform)
    },

    getBlogURL(type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    },
  },
}
</script>

<template>
  <td class="text-center">
    <div
      class="social_icons d-flex align-items-center flex-wrap justify-content-center"
    >
      <template v-if="blog_selection">
        <template
          v-if="
            blog_selection.primary_blog && blog_selection.primary_blog.selection
          "
        >
          <template v-for="(integration, key) in integrations">
            <template
              v-if="
                blog_selection.primary_blog.selection[integration] &&
                blog_selection.primary_blog.selection[integration].website
              "
            >
              <i
                :key="`primary_blog_${key}`"
                v-tooltip.top-center="
                  getBlogURL(
                    integration,
                    blog_selection.primary_blog.selection[integration].website
                  )
                "
                class="icon_block d-flex align-items-center justify-content-center"
                :class="configrations[integration].background_classes"
              >
              </i>
            </template>
          </template>
        </template>

        <template v-if="blog_selection.secondary_blog">
          <template
            v-for="(secondary_selection, key) in blog_selection.secondary_blog"
          >
            <template v-for="(integration, index) in integrations">
              <template
                v-if="
                  secondary_selection.selection[integration] &&
                  secondary_selection.selection[integration].website
                "
              >
                <i
                  :key="`secondary_blog_${key}_${index}`"
                  v-tooltip.top-center="
                    getBlogURL(
                      integration,
                      secondary_selection.selection[integration].website
                    )
                  "
                  class="icon_block d-flex align-items-center justify-content-center"
                  :class="configrations[integration].background_classes"
                >
                </i>
              </template>
            </template>
          </template>
        </template>
      </template>

      <i
        v-for="(account, key) in account_selection.facebook"
        :key="`facebook_${key}`"
        v-tooltip.top-center="platformText(account)"
        class="fb_bg cs-facebook icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <i
        v-for="(account, key) in account_selection.twitter"
        :key="`twitter_${key}`"
        v-tooltip.top-center="platformText(account)"
        class="tw_bg cs-twitter icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <i
        v-for="(account, key) in account_selection.pinterest"
        :key="`pinterest_${key}`"
        v-tooltip.top-center="platformText(account)"
        class="pin_bg cs-pinterest icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <i
        v-for="(account, key) in account_selection.linkedin"
        :key="`linkedin_${key}`"
        v-tooltip.top-center="platformText(account)"
        class="lin_bg cs-linkedin icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <i
        v-for="(account, key) in account_selection.tumblr"
        :key="`tumblr_${key}`"
        v-tooltip.top-center="platformText(account).replace('profile_', '')"
        class="tum_bg cs-tumblr icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <i
        v-for="(account, key) in account_selection.instagram"
        :key="`instagram_${key}`"
        v-tooltip.top-center="platformText(account)"
        class="ins_bg cs-instagram icon_block d-flex align-items-center justify-content-center"
      >
      </i>
      <span v-for="(account, key) in account_selection.gmb"
            :key="`gmb_${key}`"
            v-tooltip.top-center="platformText(account)"
            class="icon_block d-flex align-items-center justify-content-center">
        <img class="w-[30px] h-[30px]" src="@assets/img/integration/gmb-rounded.svg"  alt="gmb_icon"/>
      </span>
    </div>
  </td>
</template>
