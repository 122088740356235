<template>
  <b-modal
    id="add_slot"
    modal-class="add_slot"
    dialog-class="h-full"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>
        <template v-if="!getContentCategorySlotAdd._id">
          Add New Slot
        </template>
        <template v-else> Edit Slot </template>
      </h4>
      <button type="button" class="modal_head__close" @click="closeSlot"
        >&times;
      </button>
    </div>

    <div class="modal_body">
      <div class="field_group">
        <label for="">Category Name</label>
        <div class="category_dropdown dropdown default_style_dropdown">
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="text">
              <template v-if="!getContentCategorySlotAdd.category_id">
                Select category from list
              </template>
              <template v-else>
                <span class="text">
                  <template v-for="(category, index) in getContentCategoryList">
                    <template
                      v-if="
                        category._id === getContentCategorySlotAdd.category_id
                      "
                    >
                      <span
                        :key="`add_slot_${index}`"
                        class="ct_color"
                        :class="category.color"
                      ></span>
                      <span
                        :key="`slot_${index}`"
                        class="ct_text"
                        data-cy="existing-categories"
                        >{{ category.name }}</span
                      >
                    </template>
                  </template>
                </span>
              </template>
            </span>

            <span class="ml-auto">
              <i class="icon-dropdown-cs"></i>
            </span>
          </div>
          <div
            class="dropdown-menu dropdown-menu-right"
            data-cy="select-category"
          >
            <ul class="inner">
              <template v-if="getContentCategoryList.length > 0">
                <template v-for="(category, index) in getContentCategoryList">
                  <li
                    :key="`select_category_${index}`"
                    :value="category._id"
                    class="list_item_li"
                    data-cy="existing-slots"
                    @click="selectSlotCategory(category)"
                  >
                    <span class="ct_color" :class="category.color"></span>
                    <span class="ct_text"
                      ><template v-if="category.category_state === 'global'"
                        ><i
                          v-tooltip="'Global Category'"
                          style="font-size: 13px; padding-right: 5px"
                          class="far fa-crown"
                        ></i
                      ></template>
                      {{ category.name }}
                      <template v-if="category.category_state === 'global'"
                        >(Global)</template
                      ></span
                    >
                  </li>
                </template>
              </template>

              <li v-else class="no_text_li"> No category added yet. </li>
            </ul>
          </div>
          <span v-if="validations.category" class="input-error">
            {{ messages.category }}
          </span>
        </div>
      </div>

      <div class="field_group">
        <label for="">Choose a time</label>
      </div>

      <div class="d-flex">
        <div class="field_group w-100 m-1">
          <select
            v-model="getContentCategorySlotAdd.hour"
            name=""
            class="ml-0"
            data-cy="hours-in-slots"
          >
            <template v-for="hours in 12">
              <option :key="hours" :value="hours" data-cy="hours">
                <template v-if="hours <= 9"> 0{{ hours }} </template>
                <template v-else>
                  {{ hours }}
                </template>
              </option>
            </template>
          </select>

          <i class="icon-dropdown-cs"></i>
        </div>

        <div class="field_group w-100 m-1">
          <select v-model="getContentCategorySlotAdd.minute" name="">
            <option value="0"> 00 </option>
            <template v-for="minutes in 59">
              <option :key="minutes" :value="minutes">
                <template v-if="minutes <= 9"> 0{{ minutes }} </template>
                <template v-else>
                  {{ minutes }}
                </template>
              </option>
            </template>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>

        <div class="field_group w-100 m-1">
          <select v-model="getContentCategorySlotAdd.period" name="">
            <option value="AM"> AM </option>
            <option value="PM"> PM </option>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>
      <div
        v-if="!getContentCategorySlotAdd._id"
        class="field_group input_validation"
      >
        <div class="time_table_inline d-flex align-items-start">
          <template v-for="day in getWeekdays">
            <div :key="day" class="time_box_main">
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="day"
                    v-model="getContentCategorySlotAdd.weekdays"
                    :value="day"
                    type="checkbox"
                  />
                  <label :for="day" data-cy="days-slot">{{
                    day.substr(0, 3)
                  }}</label>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span v-if="validations.weekdays" class="input-error">
          {{ messages.weekdays }}
        </span>
      </div>

      <!-- edit case where we give the option to update the day. -->

      <div
        v-if="getContentCategorySlotAdd._id"
        class="field_group input_validation"
      >
        <div class="time_table_inline d-flex align-items-start">
          <template v-for="day in getWeekdays">
            <div :key="day" class="time_box_main">
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="day"
                    v-model="getContentCategorySlotAdd.day"
                    :value="day"
                    type="radio"
                  />
                  <label :for="day" data-cy="days-slot">{{
                    day.substr(0, 3)
                  }}</label>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span v-if="validations.weekdays" class="input-error">
          {{ messages.weekdays }}
        </span>
      </div>

      <!--<div class="field_group " v-if="getContentCategorySlotAdd._id">-->
      <!--<label class="d-block" for="">Select a slot day</label>-->
      <!--<select v-model="getContentCategorySlotAdd.day" name="" id="" class="field_group__select_bg">-->
      <!--<option value="sunday">Sunday</option>-->
      <!--<option value="monday">Monday</option>-->
      <!--<option value="tuesday">Tuesday</option>-->
      <!--<option value="wednesday">Wednesday</option>-->
      <!--<option value="thursday">Thursday</option>-->
      <!--<option value="friday">Friday</option>-->
      <!--<option value="saturday">Saturday</option>-->
      <!--</select>-->
      <!--<i class="icon-dropdown-cs"></i>-->
      <!--</div>-->

      <div
        v-if="
          !getContentCategorySlotAdd._id &&
          getContentCategorySlotAdd.category_id &&
          selected_category &&
          selected_category.category_state === 'global'
        "
        class="field_group"
      >
        <div class="radio_input_image">
          <input
            id="local"
            v-model="getContentCategorySlotAdd.slots_state"
            value="local"
            type="radio"
            name="category_type"
          />
          <label for="local" class="radio_left"
            >Add slots within this workspace
            <i
              v-tooltip="
                'Your category slots will be created only within this workspace.'
              "
              class="far fa-question-circle ml-1"
            ></i
          ></label>
        </div>
        <div class="radio_input_image ml-4">
          <input
            id="global"
            v-model="getContentCategorySlotAdd.slots_state"
            value="global"
            type="radio"
            name="category_type"
          />
          <label for="global" class="radio_left"
            >Add slots in all workspaces
            <i
              v-tooltip="
                'Your category slots will be created in all workspaces where your global category is present.'
              "
              class="far fa-question-circle ml-1"
            ></i
          ></label>
        </div>
      </div>

      <p
        v-if="selected_category.posts_count > 0"
        class="compose_warning_box pl-3 m-0 mb-3 mt-4"
      >
        Already scheduled posts will not be moved to the new slots. However,
        posts created in the future will use the new slots.
      </p>

      <span v-else></span>

      <!-- create the new slots -->
      <div v-if="!getContentCategorySlotAdd._id" class="field_group mt-3">
        <button
          id="createSlotButtn"
          class="btn btn-studio-theme-space"
          data-cy="create-slot"
          @click="validateAndStoreSlot"
        >
          <span>Create</span>
          <clip-loader
            v-if="getContentCategoryLoaders.store_slots"
            color="#ffffff"
            :size="'12px'"
          ></clip-loader>
        </button>
      </div>

      <!-- update scenario for the slot case -->
      <div v-else class="field_group mt-4">
        <button
          class="btn btn-studio-theme-space"
          @click="validateAndUpdateSlot"
        >
          <span>Update</span>
          <clip-loader
            v-if="getContentCategoryLoaders.store_slots"
            color="#ffffff"
            :size="'12px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'

export default {
  data() {
    return {
      validations: {
        category: false,
        weekdays: false,
      },
      messages: {
        category: 'Please choose a category.',
        weekdays: 'Please choose a day for the slot.',
      },
      alreadySchueduled: false,
      selected_category: {},
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategorySlotAdd',
      'getContentCategoryList',
      'getContentCategoryLoaders',
      'getFacebookAccounts',
      'getPinterestBoards',
      'getTwitterAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
    ]),
    getWeekdays() {
      return [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ]
    },
  },
  watch: {
    'getContentCategorySlotAdd.weekdays.length'(value) {
      if (value > 0) this.validations.weekdays = false
    },
    'getContentCategorySlotAdd.category_id'(value) {
      if (value && value.length > 2) this.validations.category = false

      if (!('name' in this.selected_category) || !this.selected_category.name) {
        this.selected_category =
          this.getContentCategoryList.find((item) => item._id === value) || {}
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    async validateAndStoreSlot() {
      document.getElementById('createSlotButtn').disabled = true
      const slot = this.getContentCategorySlotAdd
      this.validations.category = !slot.category_id
      this.validations.weekdays = !(slot.weekdays.length > 0)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        let res
        if (this.getContentCategorySlotAdd.slots_state === 'global') {
          res = await this.$store.dispatch('storeGlobalCategorySlot')
        } else {
          res = await this.$store.dispatch('storeCategorySlot')
        }

        if (res) {
          this.$bvModal.hide('add_slot')
        }
      }
      setTimeout(function () {
        if (document.getElementById('createSlotButtn'))
          document.getElementById('createSlotButtn').disabled = false
      }, 2000)
    },
    async validateAndUpdateSlot() {
      const slot = this.getContentCategorySlotAdd
      this.validations.category = !slot.category_id
      if (!this.validations.category) {
        const res = await this.$store.dispatch('updateCategorySlot')
        if (res) {
          this.$bvModal.hide('add_slot')
        }
      }
    },
    closeSlot() {
      this.$bvModal.hide('add_slot')
      this.selected_category = {}
      this.$refs.alreadySchueduled = false
    },
    selectSlotCategory(category) {
      this.selected_category = category
      this.$store.commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE,
        category._id
      )
    },
  },
}
</script>
