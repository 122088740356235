<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_right_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ noDataMessage }}
        </p>
      </div>
    </template>
    <h2>Tag cloud of top keywords extracted from the articles</h2>

    <div class="topics-insights-container__chart_legends" id="wordCloud" />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts :options="wordcloud" ref="wordcloud_chart"></highcharts>
    </div>

    <!--        <div class="card_inner height-100" >-->
    <!--            <div class="card_head">-->
    <!--                <h2>Tag cloud of top keywords extracted from the articles</h2>-->
    <!--            </div>-->
    <!--            <div class="card_content" :class="{'card_content_blur': isZero()}">-->
    <!--                <template v-if="isZero()">-->
    <!--                    <div class="no_data_for_chart">{{noDataMessage}}</div>-->
    <!--                </template>-->
    <!--                <div :class="{'blur_div': isZero()}">-->
    <!--                    <highcharts :options="wordcloud" ref="wordcloud_chart"></highcharts>-->
    <!--                    <ul class="ds_item_highlight ds_item_highlight_value " id="share_sentiment_item_highlight">-->
    <!--                    </ul>-->
    <!--                </div>-->

    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_wordcloud_list: {
      default: []
    }
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      wordcloud: {
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },

        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point">{point.name} appeared <b>{point.weight}</b> times.</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        series: [
          {
            minFontSize: 10,
            colors: [
              '#69c366',
              '#5cc6ff',
              '#ff6462',
              '#ff5f31',
              '#864de9',
              '#e7af4d',
              '#fea28b',
              '#fa6ab6',
              '#0095f3',
              '#dc70ea'
            ],
            type: 'wordcloud',
            data: [
              { name: 'Content Marketing', weight: 100 },
              { name: 'Digital Marketing', weight: 30 },
              { name: 'Business', weight: 20 },
              { name: 'SEO', weight: 33 },
              { name: 'Search Engine Optimization', weight: 15 },
              { name: 'Mobile Optimization', weight: 35 },
              { name: 'Digital World', weight: 25 }
            ],
            name: 'Occurrences'
          }
        ],
        title: {
          text: ''
        }
      }
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.isZero()) {
        return true
      }
      return false
    }
  },
  created () {
    if (!this.isZero()) {
      this.wordcloud.series[0].data = this.articles_wordcloud_list
    }
  },
  methods: {
    isZero () {
      return this.articles_wordcloud_list.length === 0
    }
  }
}
</script>
