module.exports = {
  themeColors: {
    transparent: 'transparent',
    current: 'currentColor',
    black: {
      50: '#e6e9ed',
      100: '#26303E',
      200: '#595C5F',
      300: '#546a88',
      400: '#334d71',
      500: '#00204e',
      600: '#001d47',
      700: '#001737',
      800: '#00122b',
      900: '#000d21',
    },
    pink: {
      100: '#FEDFE4',
      200: '#FEBFCF',
      300: '#FE9FC1',
      400: '#FD87BD',
      500: '#FD60B7',
      600: '#D946A6',
      700: '#B63095',
      800: '#921E81',
      900: '#791274',
    },
    gray: {
      50: '#fbfcfc',
      100: '#f2f4f6',
      200: '#F2F4F6',
      300: '#e3e8eb',
      400: '#F3F7FD',
      450: '#f2f4f6',
      500: '#d5dce1',
      600: '#c2c8cd',
      700: '#979ca0',
      800: '#75797c',
      900: '#595c5f',
    },
    purple: {
      100: '#EBDAFE',
      200: '#D4B6FD',
      300: '#BA90F9',
      400: '#A173F3',
      500: '#7D47EB',
      600: '#6033CA',
      700: '#4623A9',
      800: '#301688',
      900: '#200D70',
    },
    green: {
      100: '#D6FCD8',
      200: '#AFFAB9',
      300: '#85F29F',
      400: '#64E590',
      500: '#35d57b',
      600: '#26B774',
      700: '#1A996B',
      800: '#107B5F',
      900: '#0A6657',
    },
    blue: {
      50: '#e6f0fc',
      100: '#8abaf3',
      200: '#549aee',
      300: '#549aee',
      400: '#3386ea',
      500: '#005fd0',
      600: '#004aa3',
      700: '#3386EA',
      800: '#00397e',
      900: '#19B4FF',
    },
    orange: {
      50: '#faf2dc',
      100: '#FEF7CD',
      200: '#FEEE9B',
      300: '#FEE16A',
      400: '#FED544',
      500: '#fec107',
      600: '#DAA005',
      700: '#B68103',
      800: '#936402',
      900: '#794F01',
    },
    red: {
      100: '#FFE9DE',
      200: '#FFCFBE',
      300: '#FFAF9E',
      400: '#FF9086',
      500: '#ff5e5e',
      600: '#DB4451',
      700: '#B72F46',
      800: '#931D3C',
      900: '#7A1236',
    },

    // Others
    primary: {
      'variant-1': '#0068e5',
      'variant-2': '#00204e',
      'variant-3': '#d5dce1',
    },
    secondary: {
      'variant-1': '#9fa6ff',
      'variant-2': '#8aabf1',
      'variant-3': '#56c288',
      'variant-4': '#ffc555',
      'variant-5': '#ffb3c3',
      'variant-6': '#ff4068',
    },
    // old one cs:'#6173ff',
    cs: {
      primary: '#0068e5',
      secondary: '#e6f0fc',
      foggray: '#f5f8f9',
      metalgray: '#6c6c6c0d',
      slate: '#f1f5f9',
      blue: '#3FB2FF',
      red: '#F1416C',
      yellow: '#F9C704',
      violet: '#572CB8',
      green: '#3FD488',
      smoke: '#979CA0',
      desertStorm: 'F7F7F7',
      white: '#fff',
      light: {
        gray: '#FBFCFC',
        blue: '#F1FAFF',
        red: '#FEEFF3',
        yellow: '#FEF8DD',
        violet: '#F6F1FD',
        green: '#E4FFF4',
      },
      ultra: {
        blue: '#F1F9FF',
        violet: '#F5F6FF',
        green: '#E8F8FF',
      },
    },
    // Socials
    social: {
      facebook: '#1776f1',
      twitter: '#1da1f2',
      instagram: '#ef0072',
      pinterest: '#da0018',
      linkedin: '#2867b2',
      youtube: '#ff0000',
      google: '#FBBC05',
      gmb: '#FBBC05',
      tumblr: '#324558',
      tiktok: '#1b1b1d',
    },

    status: {
      published: '#35d57b',
      scheduled: '#fec107',
      draft: '#00204e',
      failed: '#b72f46',
      partially_failed: '#ff5e5e',
      rejected: '#b68103',
      under_review: '#19b4ff',
      missed_review: '#a173f3',
      in_progress: '#9ca0c1',
    },
  },
}
