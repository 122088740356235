<template>
  <div>
    <div
      class="assistant_box _twitter"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
      v-for="(tweet, index) in getTweetsContent"
      :key="index"
    >
      <div
        class="box_inner"
        @dragstart="dragstartHandler($event, tweet)"
        :draggable="$route.name === 'composerBlog'"
      >
        <div class="content_block">
          <div class="users_detail">
            <div class="profile_picture d-flex align-items-start tw">
              <!--<div class="picture_block">-->
              <!--<div class="icon">-->
              <!--<i class="cs-twitter"></i>-->
              <!--</div>-->
              <!--<img src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg" alt="">-->
              <!--</div>-->
              <div
                class="picture_block rounded_picture"
                :style="getTweetUserImage(tweet.user)"
              ></div>
              <div class="text_block">
                <!--<p class="text"><span class="bold">Waqar</span> Azeem</p>-->
                <a
                  target="_blank"
                  class="text"
                  :href="getTweetRedirectLink(tweet.id_str)"
                  >{{ limitTextLength(tweet.user.name, 11) }}</a
                >
                <p class="sub_text">{{ $filters.relative(tweet.created_at) }}</p>
              </div>
            </div>
          </div>

          <p
            style="min-height: 90px"
            class="desc"
            v-html="
              twitterText(
                tweet.text,
                tweet.entities.hashtags,
                tweet.entities.urls,
                tweet.entities.user_mentions
              )
            "
          ></p>
        </div>
        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div class="stat_item" v-tooltip.top-center="'Retweet'">
              <div class="name">
                <p>
                  <i class="cs-refresh"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(tweet.retweet_count) }}</h3>
              </div>
            </div>
            <div class="stat_item" v-tooltip.top-center="'Favorite'">
              <div class="name">
                <p>
                  <i class="cs-star"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(tweet.favorite_count) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_twitter_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option">
              <div class="left_block">
                <i
                  class="move_left fa fa-align-left editor_tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Align Left"
                ></i>
                <i
                  class="move_center fa fa-align-center editor_tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Align Center "
                ></i>
                <i
                  class="move_right fa fa-align-right editor_tooltip"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Align Right"
                ></i>
              </div>
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div
              class="align_box twitter_inner_embed"
              style="text-align: center; display: block"
              :data-link="
                'https://twitter.com/' +
                tweet.user.screen_name +
                '/status/' +
                tweet.id_str
              "
              :data-tweet="tweet.text"
            >
              <div style="display: none !important">
                <p class="tweet_id">{{ tweet.id_str }}</p>
                <p class="tweet_description">{{
                  twitterText(
                    tweet.text,
                    tweet.entities.hashtags,
                    tweet.entities.urls,
                    tweet.entities.user_mentions
                  )
                }}</p>
                <p class="tweet_screenname">{{ tweet.user.screen_name }}</p>
                <p class="tweet_title">{{ tweet.user.name }}</p>
                <p class="tweet_link">{{
                  'https://twitter.com/' +
                  tweet.user.screen_name +
                  '/status/' +
                  tweet.id_str
                }}</p>
              </div>

              <div class="custom_loader">
                <div class="loader_inner"></div>
              </div>
              <div class="inner_embed" id=""></div>
            </div>

            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},

  data () {
    return {}
  },
  created () {},
  computed: {
    ...mapGetters(['getTweetsContent'])
  },

  methods: {
    ...mapActions([]),
    dragstartHandler (ev, tweet) {
      // Add the target element's id to the data transfer object
      console.log('dragstartHandler', tweet)
      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'embed',
          heading: '',
          media: `https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`,
          p: '',
          link: ''
        })
      )
    }
  },

  watch: {}
}
</script>
