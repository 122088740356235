var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message m_out"},[_c('div',{staticClass:"profile_picture d-flex align-items-start justify-content-end"},[(!_vm.message.has_failed && _vm.messageLoader)?_c('clip-loader',{staticStyle:{"padding-right":"10px"},attrs:{"color":"rgba(152, 158, 181, 0.8)","size":'20px'}}):_vm._e(),_c('div',{staticClass:"text_block"},[[(_vm.message.attachment != null)?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.message.attachment.name),expression:"message.attachment.name",modifiers:{"top-center":true}}],staticClass:"attachment_btn_custom"},[_c('i',{staticClass:"icon_custom fal fa-file"}),_vm._v(" "+_vm._s(_vm.message.attachment.name)+" ")])]:(_vm.message.event_type === 'note')?[_c('div',{staticClass:"__text",class:[
              _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                ? '_user_chat'
                : '',
            ],staticStyle:{"background-color":"rgb(251, 252, 225)","white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")])]:[_c('div',{staticClass:"__text",class:[
              _vm.commonMethods.isIncomingMessage(_vm.temp, _vm.message)
                ? '_user_chat'
                : '',
            ],domProps:{"innerHTML":_vm._s(_vm.getMentionedUsersNames(_vm.message.message))}})],(_vm.message.has_failed)?_c('div',{staticClass:"failed_send"},[_c('p',[_vm._v("Message wasn't sent. "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.html.bottom.leftbottom",value:(_vm.message.error_message),expression:"message.error_message",modifiers:{"hover":true,"html":true,"bottom":true,"leftbottom":true}}],staticClass:"fas fa-exclamation warning_icon_sm has-tooltip"})])]):_vm._e()]],2),_c('div',{staticClass:"picture_block picture_block_background"},[(_vm.message.event_type === 'note')?_c('div',{staticClass:"img",style:('background: url(' + _vm.temp.getProfile.image + ')')}):_c('div',{staticClass:"img",style:('background: url(' +
          _vm.commonMethods.getSocialDetail(_vm.temp, _vm.temp.activeInboxDetail).image +
          ')')})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }