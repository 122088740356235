<template>
  <div id="component-button" class="components-uitkit right-side-uitkit-container">
    <h3>Buttons</h3>
    <p>
      At ContentStudio, we are a fan of Bootstrap as it makes our life easier by using pre-made components instead
      of
      reinventing the wheel.
    </p>
    <p>
      We will be using Bootstrap classes and modify them by using the variant option that it offers. Variants are
      used for creating our own design scheme which can be used throughout the project.
    </p>

    <div class="buttons-section">
      <p>
        We are using the name <strong>"studio-theme"</strong> taken from the ContentStudio name. So, let's dive in.
      </p>
      <h4>Primary/Default button</h4>
      <div class="uikit-codemirror">
        <codemirror v-model="button_code" :options="cmOptions" />
      </div>

      <a class="btn btn-studio-theme" href="javascript:;">Default button</a>

      <hr>
      <!-- Normal/Default button-->

      <h4>Normal Button for Primary/Default button</h4>

      <div class="uikit-codemirror">
        <codemirror v-model="normal_button_code" :options="cmOptions" />
      </div>
      <a class="btn btn-studio-theme-space" href="javascript:;">Normal button</a>
      <hr>
      <!-- Normal/Default button with loader-->

      <h4>Normal Button with Loader</h4>
      <div class="uikit-codemirror">
        <codemirror v-model="normal_button_with_loader_code" :options="cmOptions" />
      </div>
      <a class="btn btn-studio-theme-space" href="javascript:;">Normal button <clip-loader :size="'12px'" :color="'#fff'"></clip-loader></a>
      <hr>
      <!-- grey normal button-->

      <h4>Grey Normal Button</h4>

      <div class="uikit-codemirror">
        <codemirror v-model="grey_button" :options="cmOptions" />
      </div>
      <a class="btn btn-studio-theme-grey-space" href="javascript:;">Grey button</a>

      <hr>
      <!-- grey normal button-->
      <h4>Transparent Default button</h4>
      <div class="uikit-codemirror">
        <codemirror v-model='transparent_button' :options="cmOptions" />
      </div>
      <a class="btn btn-studio-theme-transparent" href="javascript:;">Transparent button</a>

      <hr>
      <!-- grey normal button-->
      <h4>Transparent Grey button</h4>
      <div class="uikit-codemirror">
        <codemirror v-model='transparent_grey_button' :options="cmOptions" />
      </div>
      <a class="btn btn-studio-theme-transparent-grey" href="javascript:;">Transparent button</a>
      <hr>
      <!-- Danger/Red normal button-->
      <h4>Red Button for Cancel Actions</h4>
      <div class="uikit-codemirror">
        <codemirror v-model='red_button' :options="cmOptions" />
      </div>

      <a class="btn btn-studio-theme-transparent-red" href="javascript:;">Red button</a>

      <h3 class="mt-3">What if we want to change the button size?</h3>
      <p>
        We have three more variants available such as:

      </p>
      <ul>
        <li>Large e.g btn-size-large</li>
        <li>Medium e.g btn-size-medium</li>
        <li>Small e.g btn-size-small</li>
      </ul>

      <div class="buttons-inline-components">
        <a class="btn btn-studio-theme-space btn-size-small" href="javascript:;">Small button</a>
        <a class="btn btn-studio-theme-space btn-size-medium" href="javascript:;">Medium button</a>
        <a class="btn btn-studio-theme-space btn-size-large" href="javascript:;">Large button</a>
      </div>
      <div class="uikit-codemirror">
        <codemirror v-model='multiple_buttons_with_size' :options="cmOptions" />
      </div>

    </div>

    <h3 class="mt-2">What if want to change the button size to custom?</h3>
    <p>
      In order to change the button size, it is best practice that you define your own class to the button and change
      the following two properties:

      <span class="d-block uikit-codemirror">
          <codemirror v-model='class_padding' :options="cmOptions" />
      </span>

      You can play around with properties to improve it.
    </p>

  </div>
</template>

<style lang="less">
  @import '~codemirror/lib/codemirror.css';
  @import '~codemirror/theme/base16-dark.css';
</style>

<script>
import { codemirror } from 'vue-codemirror'
export default ({
  components: {
    codemirror
  },
  data () {
    return {
      cmOptions: {
        tabSize: 4,
        mode: 'text/html',
        // theme: 'base16-dark',
        lineNumbers: true,
        line: true
      },
      button_code: ' <a class="btn btn-studio-theme" href="javascript:;">Default button</a>',
      normal_button_code: '<a class="btn btn-studio-theme-space" href="javascript:;">Normal button</a>',
      normal_button_with_loader_code: ' <a class="btn btn-studio-theme-space" href="javascript:;">Normal button <clip-loader :size="\'12px\'" :color="\'#fff\'"></clip-loader></a>',
      grey_button: '<a class="btn btn-studio-theme-grey-space" href="javascript:;">Grey button</a>',
      transparent_button: ' <a class="btn btn-studio-theme-transparent" href="javascript:;">Transparent button</a>',
      transparent_grey_button: '<a class="btn btn-studio-theme-transparent-grey" href="javascript:;">Transparent button</a>',
      red_button: '<a class="btn btn-studio-theme-transparent-red" href="javascript:;">Red button</a>',
      multiple_buttons_with_size: `
        <a class="btn btn-studio-theme-space btn-size-small" href="javascript:;">Small button</a>
        <a class="btn btn-studio-theme-space btn-size-medium" href="javascript:;">Medium button</a>
        <a class="btn btn-studio-theme-space btn-size-large" href="javascript:;">Large button</a>`,
      class_padding: `.class{
        font-size: 1em;
        padding:0.75rem 1rem
        }`
    }
  }
})
</script>
