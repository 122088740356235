import { twitter } from '@src/modules/integration/store/states/mutation-types'

const defaultTwitterAccountsState = {
  items: [],
  all_items: []
}
const getDefaultTwitterAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultTwitterAccountsState()
  },
  getters: {
    getTwitterAccounts: (state) => state.accounts
  },
  actions: {},
  mutations: {
    [twitter.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [twitter.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [twitter.SET_DEFAULT] (state) {
      state.accounts = getDefaultTwitterAccountsState()
    }
  }
}
