// import "core-js/stable/array/virtual/";
// import "regenerator-runtime/runtime";

import Vue from 'vue'
// import VueCompositionApi from '@vue/composition-api'
// import 'core-js'
import IdleVue from 'idle-vue'
import TreeView from 'vue-json-tree-view'
// import { publicPath } from './config/APIUtils'

import 'bootstrap'
import BootstrapVue, { BPopover, BTooltip } from 'bootstrap-vue'
// import caret from 'jquery.caret'
import VueTextareaAutosize from 'vue-textarea-autosize'
import io from 'socket.io-client'

import axios from 'axios'
import moment from 'moment'
import VueLazyload from 'vue-lazyload'

// global load mixins that are common such as helper, string etc that will be used throughout the application.
import { mixin as clickaway } from 'vue-clickaway'
import Toasted from 'vue-toasted'
import VueClipboard from 'vue-clipboard2'
import { ContentLoader } from 'vue-content-loader'
import ChannelsTooltip from '@src/components/common/ChannelsTooltip'
import IndividualChannelTooltip from '@src/components/common/IndividualChannelTooltip'
import IndividualChannelDropdown from '@src/components/common/IndividualChannelDropdown'
import ContentAvailableState from '@src/components/states/ContentAvailableState'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { renderUtils } from '@src/modules/common/mixins/discovery/renderUtils'
import { editorMixin } from '@src/modules/common/mixins/utils/editorMixin'
import { dropzoneMixin } from '@src/modules/common/mixins/utils/dropzoneMixin'
import { htmlMixin } from '@src/modules/common/mixins/utils/htmlMixin'
import { dateTimeMixin } from '@src/modules/common/mixins/utils/dateTimeMixin'
import { helperMixin } from '@src/modules/common/mixins/utils/helperMixin'
import { notificationMixin } from '@src/modules/common/mixins/utils/notificationMixin'
import { stringMixin } from '@src/modules/common/mixins/utils/stringMixin'
import { accountMixin } from '@src/modules/common/mixins/account/accountMixin'
import { connectAccountCheck } from '@src/modules/common/mixins/account/connectAccountCheck'
import { profileMixin } from '@src/modules/common/mixins/account/profileMixin'
import { actionConfirmationMixin } from '@src/modules/common/mixins/utils/actionConfirmationMixin'
import { workspaceMixin } from '@src/modules/common/mixins/setting/workspaceMixin'
import { teamMixin } from '@src/modules/common/mixins/setting/teamMixin'
import { applicationMixin } from '@src/modules/common/mixins/applicationMixin'
import { initializeMixin } from '@src/modules/common/mixins/initializeMixin'
import { searchMixin } from '@src/modules/common/mixins/discovery/searchMixin'

import { fileMixin } from '@src/modules/common/mixins/publish/widget/FileMixin'
import { billingMixin } from '@src/modules/common/mixins/billing/billingMixin'

// composer
// discovery mixins
// integrations mixins
import { platformMixin } from '@src/modules/common/mixins/integrations/platformMixin'
// shortener Mixins
import { utmMixin } from '@src/modules/common/mixins/setting/utmMixin'
import { hashtagMixin } from '@src/modules/common/mixins/setting/hashtagMixin'

import { replugMixin } from '@src/modules/common/mixins/integrations/replugMixin'
import { publishMixin } from '@src/modules/common/mixins/publish/publishMixin'
import { automationMixin } from '@src/modules/common/mixins/automation/automationMixin'
import { rssAutomationMixin } from '@src/modules/common/mixins/automation/rssAutomationMixin'
import { csvAutomationMixin } from '@src/modules/common/mixins/automation/csvAutomationMixin'
import { videoAutomationMixin } from '@src/modules/common/mixins/automation/videoAutomationMixin'
import { articleAutomationMixin } from '@src/modules/common/mixins/automation/article-automation-mixin'
import { labelsMixin } from '@src/modules/common/mixins/publish/labelsMixin'
import { contentCategoryMixin } from '@src/modules/common/mixins/publish/content-category-mixin'
import { commentsMixin } from '@src/modules/common/mixins/publish/commentsMixin'
import { subscriptionPlanMixin } from '@src/modules/common/mixins/setting/subscriptionPlanMixin'

// publish
import { blogPostMixin } from '@src/modules/common/mixins/publish/blogPostMixin'
import { approvalMixin } from '@src/modules/common/mixins/publish/approvalMixin'
import { blogPostRequest } from '@src/modules/common/mixins/publish/blogPostRequest'
import { sharingMixin } from '@src/modules/common/mixins/publish/social/sharingMixin.js'
import { sharingLinkMixin } from '@src/modules/common/mixins/publish/social/sharingLinkMixin'
import { sharingMessageMixin } from '@src/modules/common/mixins/publish/social/sharingMessageMixin'
import { sharingImageMixin } from '@src/modules/common/mixins/publish/social/sharingImageMixin'
import channelMixin from '@src/modules/common/mixins/publish/channel-mixin'

import CustomPopover from '@src/modules/common/components/dropdowns/CustomPopover'
import { tasksMixin } from '@src/modules/common/mixins/publish/tasksMixin'
import { onboardingMixin } from '@src/modules/common/mixins/onboarding/onboardingMixin'
import { sourcesMixin } from '@src/modules/common/mixins/setting/sourcesMixin'
import { blockedSourcesMixin } from '@src/modules/common/mixins/setting/blockedSourcesMixin'
import { ssoMixin } from '@src/modules/common/mixins/account/ssoMixin'
import { identifyMixin } from '@src/modules/common/mixins/utils/identifyMixin'
// watchers sockets and listener
// import { broadcastMixin } from '@src/modules/common/mixins/notifications/broadcastMixin'
import { notification } from '@src/modules/common/mixins/watchers/notification'
import { listener } from '@src/modules/common/mixins/watchers/listener'
import { socketMixin } from '@src/modules/common/mixins/watchers/socketMixin'

import { browserNotificationMixin } from '@src/modules/common/mixins/notifications/browserNotificationMixin'
import mentionsMixin from '@src/modules/common/mixins/publish/mentionsMixin'
import authMixin from '@src/modules/common/mixins/authMixin'
import guardMixin from '@src/modules/common/mixins/guardMixin'

import Clock from '@src/modules/common/components/Clock'
// vue js slider
import { versioningTimer } from '@src/modules/common/mixins/versioning'

// FPS Indicator
// import createFps from 'fps-indicator'
// Submenus
import { permissionMixin } from '@src/modules/common/mixins/permissionMixin'
import SvgIcons from '@src/modules/common/components/icons/SvgIcons'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import BeatLoader from '@src/modules/common/components/spinner/BeatLoader'
import { dateRangePickerMixin } from '@src/modules/common/mixins/utils/dateRangePickerMixin'
import { commonNetworkMixin } from '@src/modules/common/mixins/utils/httpMixin'
import validationsMixin from '@src/modules/common/mixins/validations'
import { store } from '@state/base'

import VTooltip from 'v-tooltip'
import { VTooltip as VTooltipDirective } from 'floating-vue'
import EditorBox from '@src/modules/composer_v2/components/EditorBox/EditorBox'
import { inboxListener } from './modules/inbox/watchers/notificationListener'
import { APP_ENVIRONMENT, APP_RELEASE_VERSION } from './config/api-utils'
import WorkspaceLimitsDialog from './modules/setting/components/billing/dialogs/WorkspaceLimitsDialog'
import UpgradePlanDialog from './modules/setting/components/billing/dialogs/UpgradePlanDialog'
import { userMavenMixin } from './tracking/userMavenMixin'
import { AppAnalyticsMixin } from './app_analytics'
import { twitterContentMixin } from './modules/discovery/mixins/twitter/twitterContentMixin'
import { chartsMixin } from './modules/discovery/mixins/insights/chartsMixin'
import { favoriteMixin } from './modules/discovery/mixins/favoriteMixin'
import { topicsSelectionMixin } from './modules/discovery/mixins/topics/topicsSelectionMixin'
import { topicsMixin } from './modules/discovery/mixins/topics/topicsMixin'
import { autocompleteMixin } from './modules/discovery/mixins/autocompleteMixin'
import { PublicationsMixin } from './modules/composer/mixins/PublicationsMixin'
import { PublicationFolderMixin } from './modules/composer/mixins/PublicationFolderMixin'
import { SeoScoreMixin } from './modules/composer/mixins/SeoScoreMixin'
import { composerMixin } from './modules/composer/mixins/composerMixin'
import { blogComposerMixin } from './modules/composer/mixins/blogComposerMixin'
import { pocketMixin } from './modules/discovery/mixins/pocket/pocketMixin'
import { imagesMixin } from './modules/discovery/mixins/images/ImagesMixin'
import { twitterSharesMixin } from './modules/discovery/mixins/twitterSharesMixin'
import { facebookContentMixin } from './modules/discovery/mixins/facebook/facebookMixin'
import { videoMixin } from './modules/discovery/mixins/video/videoMixin'
import { quotesMixin } from './modules/discovery/mixins/quotes/quotesMixin'

import router from './router'
import App from './App.vue'
import './assets/tailwind.css'

import 'floating-vue/dist/style.css'
// composition api registration
// Vue.use(VueCompositionApi)

Vue.use(VTooltip, {
  defaultHideOnTargetClick: false,
})

Vue.directive('floatingtip', VTooltipDirective)

// TODO: Remove In Production
// createFps({
//   position: 'bottom-right',
//   style: `
//     font-size: 24px;
//     z-index: 999999999999;
//     right: 100px;
//   `,
// })

Vue.component('b-popover', BPopover)
Vue.use(BootstrapVue)

Vue.use(VueTextareaAutosize)
Vue.use(Toasted)
Vue.use(VueClipboard)
Vue.component('custom-tooltip', CustomPopover)
Vue.component('ContentLoader', ContentLoader)
Vue.component('b-tooltip', BTooltip)
Vue.component('EditorBox', EditorBox)

const VueResource = require('vue-resource')
const jQuery = require('jquery')
window.$ = jQuery
window.JQuery = jQuery

Vue.config.productionTip = false

// Removed and created new component for this (@ui/Highcharts)
// import Highcharts from 'highcharts'
// import loadHighchartsMore from 'highcharts/highcharts-more'
// import loadSolidGauge from 'highcharts/modules/solid-gauge'
// import heatMap from 'highcharts/modules/heatmap'
// import wordcloud from 'highcharts/modules/wordcloud'
// import VueHighcharts from '@src/modules/common/components/highcharts/index'
// Vue.use(VueHighcharts)
// Highcharts.setOptions({
//   lang: {
//     thousandsSep: ',',
//   },
// })
// heatMap(Highcharts)
// wordcloud(Highcharts)
// loadHighchartsMore(Highcharts)
// loadSolidGauge(Highcharts)

window.webSockets = io.connect(process.env.VUE_APP_WEBSOCKET_URL, {
  transports: ['websocket'],
  secure: true,
})

window.socket = io.connect(process.env.VUE_APP_WEBSOCKET_URN, {
  transports: ['websocket'],
  secure: true,
})

const VueCookie = require('vue-cookie')
// Tell Vue to use the plugin
Vue.use(VueCookie)

// idle check monitor

// idleTime is the value in milli seconds to set time of inactivity of user to term him idle
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000,
})

Vue.mixin(commonNetworkMixin)
Vue.use(VueResource)
Vue.use(TreeView)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading:
    'https://storage.googleapis.com/lumotive-web-storage/logo_loading.jpg',
  error:
    'https://storage.googleapis.com/omniflip-discovery/assets/no_image_found.jpg',
  attempt: 1,
})

Vue.component('BeatLoader', BeatLoader)
Vue.component('ClipLoader', ClipLoader)
Vue.component('SvgIcons', SvgIcons)
Vue.component('UpgradePlanDialog', UpgradePlanDialog)
Vue.component('WorkspaceLimitsDialog', WorkspaceLimitsDialog)
Vue.component('content-available-state', ContentAvailableState)
// tooltips components as they are imported in several places.

Vue.component('channels-tooltip', ChannelsTooltip)
Vue.component('individual-channel-tooltip', IndividualChannelTooltip)
Vue.component('individual-channel-dropdown', IndividualChannelDropdown)
Vue.component('Clock', Clock)

/**
 * @NOTE: for vue2.7+: instead of using Vue.prototype.$filters, use app.config.globalProperties.$filters... app being the Vue app Object.
 * https://v3-migration.vuejs.org/breaking-changes/filters.html
 * @type {{convertToWeekdaySplit(*): string, addZeroToStart(*): string|*, convertToWeekday(*): *, dateWithYear(*): (string|undefined), convertToWeekdayWithTime(*): *, floatToInt(*): (number|string), numberToCommas(*): (number|*), relative(*): string}}
 */
Vue.prototype.$filters = {
  relative(dateValue) {
    const date = moment.utc(dateValue)
    return moment(date).local().fromNow()
  },
  dateWithYear(dateValue) {
    if (dateValue) {
      return moment(String(dateValue)).format('MMMM Do YYYY')
    }
  },
  floatToInt(value) {
    if (!value) return 0
    return Number(value).toFixed(0)
  },
  numberToCommas(value) {
    if (!value) return 0
    if (value === 0) return 0
    return value.toLocaleString()
  },
  addZeroToStart(value) {
    return value <= 9 ? `0${value}` : value
  },
  convertToWeekdaySplit(value) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
    }
    const dateRanges = value.split(' - ')
    const startDate = Intl.DateTimeFormat('en-US', options).format(
      Date.parse(dateRanges[0])
    )
    const endDate = Intl.DateTimeFormat('en-US', options).format(
      Date.parse(dateRanges[1])
    )
    return `${startDate} - ${endDate}`
  },
  convertToWeekday(value) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
    }
    return Intl.DateTimeFormat('en-US', options).format(Date.parse(value))
  },
  convertToWeekdayWithTime(value) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    }
    return Intl.DateTimeFormat('en-US', options).format(moment(String(value)))
  },
}

Vue.mixin(validationsMixin)
Vue.prototype.$http = axios

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  versioningTimer.runTimersCheck()
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  // console.log('Before route', VueCookie.get('logged_user_jwt'))
  // if (to.name == 'logout') {
  //   console.log(VueCookie.get('logged_user_jwt'))
  // }
  // hide the modals
  // console.log('hiding from global');
  // $('#curated_topic_modal').modal('hide')
  // $('#article_preview_modal').modal('hide')

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

Vue.config.devtools =
  process.env.VUE_APP_ENVIRONMENT === 'develop' ||
  process.env.VUE_APP_ENVIRONMENT === 'qa-features'
Vue.mixin(userMavenMixin)
Vue.mixin(clickaway)
Vue.mixin(channelMixin)
Vue.mixin(authMixin)
Vue.mixin(guardMixin)
Vue.mixin(topicsMixin)
Vue.mixin(mentionsMixin)
Vue.mixin(autocompleteMixin)
Vue.mixin(topicsSelectionMixin)
Vue.mixin(favoriteMixin)
Vue.mixin(chartsMixin)
Vue.mixin(renderUtils)
Vue.mixin(permissionMixin)
Vue.mixin(editorMixin)
Vue.mixin(htmlMixin)
Vue.mixin(helperMixin)
Vue.mixin(notificationMixin)
Vue.mixin(browserNotificationMixin)
Vue.mixin(stringMixin)
Vue.mixin(accountMixin)
Vue.mixin(profileMixin)
Vue.mixin(actionConfirmationMixin)
Vue.mixin(workspaceMixin)
Vue.mixin(teamMixin)
Vue.mixin(dateTimeMixin)
Vue.mixin(applicationMixin)
Vue.mixin(initializeMixin)
Vue.mixin(searchMixin)
Vue.mixin(hashtagMixin)
Vue.mixin(utmMixin)
Vue.mixin(platformMixin)
Vue.mixin(replugMixin)
Vue.mixin(quotesMixin)
Vue.mixin(videoMixin)
Vue.mixin(imagesMixin)
Vue.mixin(twitterContentMixin)
Vue.mixin(twitterSharesMixin)
Vue.mixin(facebookContentMixin)
Vue.mixin(connectAccountCheck)
Vue.mixin(publishMixin)
Vue.mixin(automationMixin)

Vue.mixin(rssAutomationMixin)
Vue.mixin(csvAutomationMixin)
Vue.mixin(videoAutomationMixin)
Vue.mixin(articleAutomationMixin)
Vue.mixin(dateRangePickerMixin)
Vue.mixin(dropzoneMixin)
Vue.mixin(pocketMixin)
Vue.mixin(SeoScoreMixin)
Vue.mixin(fileMixin)
Vue.mixin(blogPostMixin)
Vue.mixin(blogPostRequest)
Vue.mixin(approvalMixin)
Vue.mixin(sharingMixin)
Vue.mixin(sharingLinkMixin)
Vue.mixin(sharingMessageMixin)
Vue.mixin(sharingImageMixin)
Vue.mixin(labelsMixin)
Vue.mixin(contentCategoryMixin)
Vue.mixin(PublicationFolderMixin)
Vue.mixin(commentsMixin)
Vue.mixin(tasksMixin)
Vue.mixin(PublicationsMixin)
Vue.mixin(onboardingMixin)
Vue.mixin(AppAnalyticsMixin)
Vue.mixin(subscriptionPlanMixin)
Vue.mixin(sourcesMixin)
Vue.mixin(blockedSourcesMixin)
Vue.mixin(ssoMixin)
Vue.mixin(identifyMixin)

// composer
Vue.mixin(blogComposerMixin)
Vue.mixin(composerMixin)

// watchers sockets and listener
Vue.mixin(socketMixin)
Vue.mixin(listener)
Vue.mixin(notification)
Vue.mixin(inboxListener)

Vue.mixin(billingMixin)

Sentry.init({
  Vue,
  dsn: 'https://4a6c414725b24ba5a62a49a0a608dddb@sentry-onpremise.contentstudio.io/3',
  autoSessionTracking: true,
  environment: APP_ENVIRONMENT,
  release: APP_RELEASE_VERSION,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'staging.contentstudio.io',
        'app.contentstudio.io',
      ],
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
})

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 */
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr)
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}

if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  // eslint-disable-next-line
  Paddle.Environment.set('sandbox')
}
// eslint-disable-next-line
Paddle.Setup({ vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID) })

// TODO: When upgraded to vue 3, Remove 'root' instance provided inside App.vue and do 'app.provide('root')' when using the createApp hook
export const appContainer = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
// TODO: When upgraded to vue 3, Remove 'root' instance provided inside App.vue and do 'app.provide('root')' when using the createApp hook

// window.appContainer = appContainer
// Vue.config.devtools = true
// Vue.config.performance = true
