import http from '@common/lib/http-common'
import Vue from 'vue'
import { ROLE_CHANGED_MESSAGE } from '@common/constants/messages'
import {
  bulkAddTeamMemberURL,
  changeOrganizationTeamMemberStatusURL,
  manageAllMembersURL,
  removeOrganizationTeamMemberAccessURL,
  removeOrganizationTeamMemberFromAllWorkspacesURL
} from '../../config/api-utils'
import { organizationTypes as organzationTypes } from './mutation-types'

const getDefaultOrganizationMemberAdd = () => {
  return {
    member: {
      first_name: null,
      last_name: null,
      email: null,
      role: 'admin',
      membership: 'team',
      workspaces: [],
      permissions: {
        addBlog: true,
        addSocial: true,
        addSource: true,
        addTopic: true,
        viewTeam: false,
        postsReview: true,
        rescheduleQueue: false
      }
    },
    validations: {
      first_name: false,
      last_name: false,
      email: false,
      workspaces: false
    }
  }
}
export default {
  state: {
    loaders: {
      retrieve: false,
      store_member: false
    },
    ...getDefaultOrganizationMemberAdd(),
    organization_members: {
      members: [],
      workspaces: [],
      workspaces_count: 0,
      members_count: 0
    }
  },
  getters: {
    getOrganizationLoaders: (state) => state.loaders,
    getOrganizationMembers: (state) => state.organization_members,
    getOrganizationMemberAdd: (state) => state.member,
    getOrganizationMemberAddValidations: (state) => state.validations
  },
  actions: {
    async fetchAllMembersOfOrganization ({ commit, getters, dispatch }) {
      commit(organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_RETRIEVE, true)
      const res = await http
        .post(manageAllMembersURL)
        .then((res) => {
          if (res.data.status) {
            commit(organzationTypes.SET_ORGANIZATION_MEMBERS, res.data.data)
          }
        })
        .catch((err) => err)

      commit(organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_RETRIEVE, false)
      return res
    },
    async bulkAddTeamMember ({ commit, getters, dispatch }) {
      const payload = getters.getOrganizationMemberAdd
      commit(
        organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_STORE_MEMBER,
        true
      )
      const res = await http
        .post(bulkAddTeamMemberURL, payload)
        .then(async (res) => {
          if (res.data.status) {
            dispatch('fetchAllMembersOfOrganization')

            if (!this.getActiveWorkspace.onboarding_steps.invite_team.status) {
              await this.onboardingStepsCompleted('invite_team')
            }
            this.trackUserMaven('team_member_invited')

          } else {
            if (res.data.message) dispatch('toastNotification', { message: res.data.message, type: 'error' })
          }
          return res
        })
        .catch((err) => err)
      commit(
        organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_STORE_MEMBER,
        false
      )

      return res
    },
    async changeRoleOfMemberInWorkspace (
      { commit, getters, dispatch },
      payload
    ) {
      const res = await http
        .post(changeOrganizationTeamMemberStatusURL, payload)
        .then((res) => {
          if (res.data.status) {
            // add to the members list
            const data = res.data.data
            const organization = getters.getOrganizationMembers
            console.log('updating organization role member ==>')

            if (organization.members.hasOwnProperty(data.user._id)) {
              console.log('User already part of the organization ==>')

              const findMemberIndex = organization.members[
                data.user._id
              ].findIndex(
                (item) => item.workspace_id === data.workspace_team.workspace_id
              )
              console.log(
                'User part of the organization, check their member index level ==>',
                findMemberIndex
              )
              if (findMemberIndex >= 0) {
                console.log(
                  'User part of the organization, slicing the member ',
                  findMemberIndex
                )
                organization.members[data.user._id].splice(
                  findMemberIndex,
                  1,
                  data.workspace_team
                )
              } else {
                console.log(
                  'User part of the organization, pushing data to the queue'
                )
                organization.members[data.user._id].push(data.workspace_team)
              }
            } else {
              console.log('Fresh user and he is not part of the workspaces ==>')
              Vue.$set(organization.members, data.user._id, [
                data.workspace_team
              ])
            }

            // workspaces
            console.log('Finding organization workspace by their id ==>')
            const workspace = organization.workspaces.find(
              (item) => item.workspace_id === payload.workspace_id
            )
            console.log('workspace found for organization by id ==>', workspace)
            const findIndex = workspace.workspace.members.findIndex(
              (user) => user.user_id === data.user._id
            )
            console.log('finding index of team member==>', findIndex)
            if (findIndex >= 0) {
              console.log(
                'item before slicing and updating',
                workspace.workspace.members[findIndex]
              )
              workspace.workspace.members.splice(
                findIndex,
                1,
                data.workspace_team
              )
              console.log(
                'item spliced and updated.',
                data.workspace_team,
                workspace.workspace.members[findIndex]
              )
            } else {
              workspace.workspace.members.push(data.workspace_team)
              console.log('item pushed to the array')
            }

            dispatch('toastNotification', { message: ROLE_CHANGED_MESSAGE })
          }
        })
        .catch()
      return res
    },
    removeRoleOfMemberInWorkspace ({ commit, getters, dispatch }, payload) {
      http.post(removeOrganizationTeamMemberAccessURL, payload).then((res) => {
        if (res.data.status) {
          // add to the members list
          const data = res.data.data
          const organization = getters.getOrganizationMembers
          const findMemberIndex = organization.members[data.user._id].findIndex(
            (item) => item.workspace_id === data.workspace_team.workspace_id
          )
          console.log(
            'User part of the organization, check their member index level ==>',
            findMemberIndex
          )
          if (findMemberIndex >= 0) {
            console.log(
              'User part of the organization, slicing the member ',
              findMemberIndex
            )

            organization.members[data.user._id].splice(findMemberIndex, 1)
            if (organization.members[data.user._id].length === 0) {
              delete organization.members[data.user._id]
            }
          }

          // workspaces
          console.log('Finding organization workspace by their id ==>')
          const workspace = organization.workspaces.find(
            (item) => item.workspace_id === payload.workspace_id
          )
          console.log('workspace found for organization by id ==>', workspace)
          const findIndex = workspace.workspace.members.findIndex(
            (user) => user.user_id === data.user._id
          )
          workspace.workspace.members.splice(findIndex, 1)
          dispatch('toastNotification', { message: ROLE_CHANGED_MESSAGE })
        }
      })
    },
    removeFromAllWorkspaces ({ commit, getters, dispatch }, userId) {
      const workspaceIds = getters.getOrganizationMembers.members[userId].map(
        (item) => {
          return item.workspace_id
        }
      )
      http
        .post(removeOrganizationTeamMemberFromAllWorkspacesURL, {
          user_id: userId,
          workspace_ids: workspaceIds
        })
        .then((res) => {
          if (res.data.status) {
            delete getters.getOrganizationMembers.members[userId]
            Vue.set(
              getters.getOrganizationMembers,
              'members',
              JSON.parse(JSON.stringify(getters.getOrganizationMembers.members))
            )
          }
        })
        .catch()
    }
  },
  mutations: {
    [organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_RETRIEVE] (state, value) {
      state.loaders.retrieve = value
    },
    [organzationTypes.SET_ORGANIZATION_MEMBERS_LOADER_STORE_MEMBER] (
      state,
      value
    ) {
      state.loaders.store_member = value
    },
    [organzationTypes.RESET_ORGANIZATION_MEMBER_ADD] (state, value) {
      state.member = getDefaultOrganizationMemberAdd().member
      state.validations = getDefaultOrganizationMemberAdd().validations
    },
    [organzationTypes.SET_ORGANIZATION_MEMBERS] (state, value) {
      state.organization_members = value
    },
    [organzationTypes.SET_ORGANIZATION_MEMBERS_VALIDATIONS] (
      state,
      { type, value }
    ) {
      state.validations[type] = value
    }
  }
}
