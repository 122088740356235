var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_box"},[_c('table',[_c('thead',[_c('th',[_vm._v(" Website "),_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{ active: _vm.sortByRow === 'key' && _vm.sortByOrder === 'asc' },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('key', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{ active: _vm.sortByRow === 'key' && _vm.sortByOrder === 'desc' },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('key', 'desc')}}})]),_c('th',{staticClass:"make-text-center"},[_vm._v(" Total Articles "),_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
            active: _vm.sortByRow === 'doc_count' && _vm.sortByOrder === 'asc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('doc_count', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
            active: _vm.sortByRow === 'doc_count' && _vm.sortByOrder === 'desc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('doc_count', 'desc')}}})]),_c('th',{staticClass:"make-text-center"},[_vm._v(" Total Engagement "),_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
            active:
              _vm.sortByRow === 'total_interactions.value' &&
              _vm.sortByOrder === 'asc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('total_interactions.value', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
            active:
              _vm.sortByRow === 'total_interactions.value' &&
              _vm.sortByOrder === 'desc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('total_interactions.value', 'desc')}}})]),_c('th',{staticClass:"make-text-center"},[_vm._v(" Avg. Engagement "),_c('i',{staticClass:"sorting_icon cs-arrow-up",class:{
            active:
              _vm.sortByRow === 'average_interactions.value' &&
              _vm.sortByOrder === 'asc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('average_interactions.value', 'asc')}}}),_c('i',{staticClass:"sorting_icon cs-arrow-down",class:{
            active:
              _vm.sortByRow === 'average_interactions.value' &&
              _vm.sortByOrder === 'desc',
          },on:{"click":function($event){$event.preventDefault();return _vm.changeSortOrder('average_interactions.value', 'desc')}}})])]),_c('tbody',[_vm._l((_vm.orderedList()),function(item,index){return (
          _vm.articles_popular_domains.domains_list &&
          _vm.articles_popular_domains.domains_list.length > 0 &&
          item.total_interactions.value >= 0
        )?[_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.key)+" ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" "+_vm._s(item.doc_count)+" ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" "+_vm._s(_vm.intToString(item.total_interactions.value) || 0)+" ")]),_c('td',{staticClass:"make-text-center"},[_vm._v(" "+_vm._s(_vm.intToString(item.average_interactions.value) || 0)+" ")])])]:_vm._e()}),(
          _vm.articles_popular_domains.domains_list &&
          _vm.articles_popular_domains.domains_list.length === 0
        )?_c('tr',[_c('td',[_vm._v(" No data found for the top domains. ")])]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }