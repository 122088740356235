<template>
  <div class="analytics-filter-bar !top-0">
    <!-- Loader (When Workspace Loaded) -->
    <div v-if="getFetchSocialStatus" class="analytics-filter-bar__left">
      <b-dropdown
        ref="dropdown"
        variant="studio-theme"
        size="lg"
        disabled
        class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image analytic-accounts-dropdown"
        no-caret
      >
        <template slot="button-content">
          <span class="connected-accounts-images">
            <template>
              <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
            </template>
          </span>
          <span
            class="capitalize_text"
            style="
              text-align: left;
              display: inline-flex;
              flex-direction: column;
              margin: 0 1rem;
              font-weight: 500;
              justify-content: flex-start;
            "
          >
            Accounts
            <small>Are Loading...</small>
          </span>
          <i class="icon-dropdown-cs"></i>
        </template>
      </b-dropdown>
      <clip-loader
        v-if="updating"
        class="spinner ml-3"
        :color="'#9da6ac'"
        :size="'16px'"
      ></clip-loader>
    </div>
    <div v-else class="analytics-filter-bar__left">
      <CstDropdown
        v-if="type === 'overview'"
        class="w-full min-w-[25rem]"
        size="small"
        button-classes="flex !rounded-xl w-full"
        container-classes="max-h-[30rem]"
        @onClose="searchQuery = ''"
      >
        <template v-slot:selected>
          <span class="flex flex-row w-full items-center">
            <span class="connected-accounts-images">
              <template v-if="selected_accounts.length === 0">
                <i class="fad fa-user-circle text-3xl mr-0"></i>
              </template>
              <div
                v-for="(account, index) in selected_accounts"
                :key="index + '_overview_select'"
              >
                <template v-if="index < 5">
                  <img
                    class="selected"
                    :src="channelImage(account, account.account_type)"
                    alt=""
                    @error="
                      $event.target.src = fallbackImagePreview(account.name[0])
                    "
                  />
                </template>
              </div>
              <!-- Last -->
              <template v-if="getAllAccountsLength() > 5">
                <span class="selected">+{{ getAllAccountsLength() - 5 }}</span>
              </template>
            </span>
            <span
              class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
            >
              {{ getAllAccountsLength() }} Account{{
                getAllAccountsLength() > 1 ? 's' : ''
              }}
              <small>Connected</small>
            </span>
          </span>
        </template>
        <template v-slot>
          <div class="py-2 px-3 sticky top-0 bg-white z-50 shadow-sm">
            <label class="w-full flex items-center mt-1">
              <input
                v-model="searchQuery"
                class="rounded-xl border py-2 pl-1 w-full text-grey-darkest"
                placeholder="Search Account By Name"
                type="text"
              />
              <i
                v-if="searchQuery.length"
                class="fas fa-times-circle absolute cursor-pointer opacity-80 right-4 hover:text-red-400"
                @click="searchQuery = ''"
              ></i>
            </label>
            <SimpleCheckbox
              v-if="!searchQuery && selectAllAccounts().length > 1"
              v-model="all_selected"
              @change="selectUnselectAll"
            >
              <template slot="label">
                <span class="font-medium">Select All</span></template
              >
            </SimpleCheckbox>
          </div>
          <div>
            <template v-if="searchQuery">
              <template v-if="searchedAccounts && searchedAccounts.length > 0">
                <div v-for="(account, i) in searchedAccounts" :key="i">
                  <CstDropdownItem :close-on-click="false">
                    <SimpleCheckbox
                      v-model="selected_accounts"
                      :name="account"
                      :disabled="!hasInstaPermission(account)"
                      class="group"
                    >
                      <template slot="label">
                        <span class="flex items-center">
                          <span class="relative">
                            <img
                              :src="channelImage(account, account.account_type)"
                              alt=""
                              class="rounded-full object-cover border"
                              data-fallback="0"
                              height="32"
                              width="32"
                              @error="
                                (event) => {
                                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                }
                              "
                            />

                            <img
                              :src="
                                require('@assets/img/integration/' +
                                  getSocialImage(account.account_type))
                              "
                              alt=""
                              class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                            />
                          </span>
                          <span class="flex flex-col ml-3">
                            <span class="flex flex-row">
                              <span
                                v-tooltip="{
                                  content: hasInstaPermission(account)
                                    ? account.name
                                    : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                                }"
                                class="text-sm text-ellipsis font-normal text-black-100 select-none"
                              >
                                {{ account.name }}
                              </span>
                            </span>
                            <span
                                class="block text-xs select-none text-gray-800">
                              {{ getProfileType(account.account_type, account) }}
                            </span>
                          </span>
                        </span>
                      </template>
                    </SimpleCheckbox>
                  </CstDropdownItem>
                </div>
              </template>
              <template v-else>
                <CstDropdownItem
                  class="active:bg-transparent hover:bg-transparent"
                  :close-on-click="false"
                  :disabled="true"
                >
                  <span>No Account Found</span>
                </CstDropdownItem>
              </template>
            </template>
            <template v-else>
              <div v-for="(account, i) in selectAllAccounts()" :key="i">
                <CstDropdownItem :close-on-click="false">
                  <SimpleCheckbox
                    v-model="selected_accounts"
                    :name="account"
                    :disabled="!hasInstaPermission(account)"
                    class="group"
                  >
                    <template slot="label">
                      <span class="flex items-center">
                        <span class="relative">
                          <img
                            :src="channelImage(account, account.account_type)"
                            alt=""
                            class="rounded-full object-cover border"
                            data-fallback="0"
                            height="32"
                            width="32"
                            @error="
                              (event) => {
                                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                              }
                            "
                          />

                          <img
                            :src="
                              require('@assets/img/integration/' +
                                getSocialImage(account.account_type))
                            "
                            alt=""
                            class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                          />
                        </span>
                        <span class="flex flex-col ml-3">
                          <span class="flex flex-row">
                            <span
                              v-tooltip="{
                                content: hasInstaPermission(account)
                                  ? account.name
                                  : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                              }"
                              class="text-sm text-ellipsis font-normal text-black-100 select-none"
                            >
                              {{ account.name }}
                            </span>
                          </span>
                          <span class="block text-xs select-none text-gray-800">
                            {{ getProfileType(account.account_type, account) }}
                          </span>
                        </span>
                      </span>
                    </template>
                  </SimpleCheckbox>
                </CstDropdownItem>
              </div>
            </template>
          </div>

          <div class="p-1 sticky bottom-0 border-top bg-white z-50">
            <CstDropdownItem
              class="active:bg-transparent hover:bg-transparent !p-0.5 text-right"
              size="small"
              :close-on-click="true"
            >
              <CstButton
                size="small"
                text="Apply"
                @click="handleApplyAccountSelection"
              />
            </CstDropdownItem>
          </div>
        </template>
      </CstDropdown>

      <b-dropdown
        v-else
        ref="dropdown"
        variant="studio-theme"
        toggle-class="analytic-accounts-dropdown__btn"
        size="lg"
        :disabled="updating || selected_accounts.length === 0"
        class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image analytic-accounts-dropdown"
        no-caret
      >
        <template slot="button-content">
          <template v-if="selected_accounts.length === 0">
            <i class="fad fa-user-circle text-3xl mr-0"></i>
            <span
              class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
            >
              <small>No Account Connected</small>
            </span>
          </template>
          <template v-else>
            <span class="flex flex-row w-full justify-center items-center">
              <span class="connected-accounts-images">
                <div>
                  <img
                    class="selected"
                    :src="channelImage(the_selected_account, type)"
                    alt=""
                    @error="
                      $event.target.src = fallbackImagePreview(
                        the_selected_account.name[0],
                      )
                    "
                  />
                </div>
              </span>
              <span
                class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
              >
                {{ the_selected_account.name }}
                <small>Connected</small>
              </span>
              <i class="icon-dropdown-cs ml-auto"></i>
            </span>
          </template>
        </template>

        <div v-for="(account, i) in selected_accounts" :key="i">
          <b-dropdown-item
            v-b-tooltip="
              type === 'instagram'
                ? !hasInstaPermission(account)
                  ? 'Insufficient permissions, please reconnect profile to enable Analytics.'
                  : ''
                : ''
            "
            :disabled="
              type === 'instagram' ? !hasInstaPermission(account) : false
            "
            @click="changeSelectedAccount(account)"
          >
            <div class="field_group">
              <div class="checkbox_container">
                <label>
                  <div class="dropdown-img-wrapper">
                    <img
                      class="selected"
                      :src="channelImage(account, type)"
                      alt=""
                      @error="
                        $event.target.src = fallbackImagePreview(
                          account.name[0],
                        )
                      "
                    />
                    <i :class="getIconClass(type)"></i>
                  </div>
                  <span class="dropdown-item-label">
                    <span v-b-tooltip.top="account.name">{{
                      account.name
                    }}</span>
                    <small>{{ getProfileType(type, account) }}</small>
                  </span>
                </label>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <!-- Loader/ Spinner when toggle between accounts -->
      <clip-loader
        v-if="updating"
        class="spinner ml-3"
        :color="'#9da6ac'"
        :size="'16px'"
      ></clip-loader>
    </div>
    <div class="analytics-filter-bar__right">
      <p
        ><span class="pr-1">* </span> Stats are measured as per workspace
        timezone.</p
      >
      <div class="analytics-filter-bar__right-btns">
        <DateRangePicker
          ref="instagram_analytics_dp"
          class="datepicker_filter_analytics_main datepicker_input mr-2 instagram_analytics_dp"
          :opens="analytics.opens"
          :max-date="getMaxDate"
          :start-date="analytics.startDate"
          :end-date="analytics.endDate"
          :locale-data="analytics.locale"
          :single-date-picker="analytics.singleDatePicker"
          :time-picker="analytics.timePicker"
          :time-picker24hour="analytics.timePicker24Hour"
          :show-week-numbers="analytics.showWeekNumbers"
          :show-dropdowns="analytics.showDropdowns"
          :auto-apply="analytics.autoApply"
          :ranges="analytics.ranges"
          :class="{
            disabled:
              getFetchSocialStatus ||
              updating ||
              selected_accounts.length === 0,
          }"
          @update="changeDateRange"
        >
          <div
            slot="input"
            slot-scope="picker"
            class="datepicker_content with_bg ml-2 analytics_dp"
          >
            <i class="icon_first icon-Calendar"></i>
            <span>
              <template v-if="!analytics.isChanged"> Last 30 Days </template>
              <template v-else>
                <template
                  v-if="getDatePickerLabel(picker.startDate, picker.endDate)"
                >
                  {{ getDatePickerLabel(picker.startDate, picker.endDate) }}
                </template>
                <template v-else>
                  {{ picker.startDate | onlyDate }} -
                  {{ picker.endDate | onlyDate }}
                </template>
              </template>
            </span>
          </div>
        </DateRangePicker>
        <ExportButton
          :accounts="
            type === 'overview' ? selected_accounts : the_selected_account
          "
          :start_date="analytics.startDate"
          :end_date="analytics.endDate"
          :type="type === 'overview' ? 'group' : type"
          :disabled="
            getFetchSocialStatus || updating || selected_accounts.length === 0
          "
        ></ExportButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { analyticsDatePickerValues } from '@src/modules/analytics/components/common/helper'
import moment from 'moment'
import ExportButton from '@src/modules/analytics/views/common/ExportButton'
import DateRangePicker from '@common/components/Analytics-date-range-picker/src/components/DateRangePicker'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import CstButton from '@ui/Button/CstButton.vue'
import * as _ from 'underscore'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'AnalyticsFilterBar',
  components: {
    DateRangePicker,
    ExportButton,
    CstDropdown,
    CstDropdownItem,
    SimpleCheckbox,
    CstButton,
  },
  mixins: [analyticsUtilsMixin, ComposerHelper],
  // eslint-disable-next-line vue/require-prop-types
  props: ['getAccounts', 'getDate', 'getPreviousDate', 'updating', 'type'],
  data() {
    return {
      all_selected: true,
      selected_accounts: [],
      the_selected_account: [],
      all_accounts: [],
      analytics: analyticsDatePickerValues().analytics,
      searchQuery: '',
      searchedAccounts: [],
    }
  },

  computed: {
    ...mapGetters(['getActiveWorkspace', 'getFetchSocialStatus']),
    getMaxDate() {
      return moment.utc().format('YYYY-MM-DD')
    },
  },
  watch: {
    the_selected_account(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAccounts(newVal)
        this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
        this.getPreviousDate(
          `${this.previousAnalyticsPeriodDate(
            this.analytics.startDate,
            this.analytics.endDate
          )} - ${this.analytics.startDate}`
        )
        const hash = location.hash
        this.$router.push({
          name: this.type + '_analytics_v3',
          params: { accountId: newVal[this.type + '_id'] },
        })
        location.hash = hash
      }
    },
    selected_accounts(newVal, oldVal) {
      this.all_selected = newVal.length === this.selectAllAccounts().length
    },
    async getFetchSocialStatus(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === false) {
          this.getSelectedAccounts()
          // this.selected_accounts = await this.selectAllAccounts()
        } else {
        }
      }
    },
    updating(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setAnalyzeService({ value: newVal })
      }
    },
    searchQuery: function (newVal, oldVal) {
      const list = this.selectAllAccounts()
      if (newVal) {
        this.searchedAccounts = list.filter((account) =>
          account.name.toLowerCase().includes(newVal.toLowerCase())
        )
        return
      }
      this.searchedAccounts = []
    },
  },
  created() {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      this.getSelectedAccounts()
      this.handleApplyAccountSelection()
    })
  },
  mounted() {
    this.getSelectedAccounts()
    this.handleApplyAccountSelection()
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
  },
  methods: {
    ...mapActions(['fetchSocialService', 'setAnalyzeService']),
    closeDatePicker() {
      if (this.$refs.instagram_analytics_dp) {
        this.$refs.instagram_analytics_dp.clickAway()
      }
    },
    changeSelectedAccount(account) {
      this.the_selected_account = account
      const hash = location.hash
      this.$router.push({
        name: this.type + '_analytics_v3',
        params: { accountId: this.the_selected_account[this.type + '_id'] },
      })
      location.hash = hash
    },
    getSelectedAccounts() {
      this.fetchSocialService({ value: true })
      if (this.type === 'overview') {
        this.all_selected = true
        const list = this.selectAllAccounts()
        this.selected_accounts = this.checkInstagramPermission(list)
      } else {
        const list = this.getPlatformAccounts(this.type)
        this.selected_accounts =
          this.type === 'instagram' ? this.checkInstagramPermission(list) : list
        if (!this.$route.params.accountId) {
          this.the_selected_account = this.selected_accounts[0]
        } else {
          switch (this.type) {
            case 'facebook': {
              const account = this.findFacebookAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'instagram': {
              const account = this.findInstaAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'twitter': {
              const account = this.findTwitterAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'linkedin': {
              const account = this.findLinkedinAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'pinterest': {
              const account = this.findPinteresrBoard(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
          }
        }
      }
      this.fetchSocialService({ value: false })
    },
    changeDateRange(values) {
      this.analytics.isChanged = true
      // date range
      if (values.startDate && values.endDate) {
        this.analytics.startDate = moment(values.startDate)
        this.analytics.endDate = moment(values.endDate)
      }
      this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
      this.getPreviousDate(
        `${this.previousAnalyticsPeriodDate(
          this.analytics.startDate,
          this.analytics.endDate
        )} - ${this.analytics.startDate}`
      )
    },
    selectUnselectAll() {
      if (this.all_selected) {
        const list = this.selectAllAccounts()
        this.selected_accounts = this.checkInstagramPermission(list)
      } else {
        this.selected_accounts = []
      }
    },
    getAllAccountsLength() {
      return this.selected_accounts.length
    },
    checkInstagramPermission(list) {
      list.forEach((item) => {
        if (item.account_type === 'instagram') {
          if (!this.hasInstaPermission(item)) {
            list = _.without(
              list,
              _.findWhere(list, {
                instagram_id: item.instagram_id,
              })
            )
          }
        }
      })
      return list
    },
    hasInstaPermission(channel) {
      if (channel.account_type === 'instagram') {
        const analyticPermission = _.findWhere(channel.permissions, {
          permission: 'instagram_manage_insights',
        })
        return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
      } else return true
    },
    handleApplyAccountSelection() {
      if (this.type === 'overview') {
        this.all_selected =
          this.selected_accounts.length === this.selectAllAccounts().length
        this.getAccounts(this.selected_accounts)
        this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
        this.getPreviousDate(
          `${this.previousAnalyticsPeriodDate(
            this.analytics.startDate,
            this.analytics.endDate
          )} - ${this.analytics.startDate}`
        )
      }
    },
  },
}
</script>

<style lang="scss">
.analytic-accounts-dropdown {
  min-width: 24rem;

  &__btn {
    flex: 0 1 auto !important;
    width: 100%;
  }

  .dropdown-menu {
    width: 100% !important;
    max-height: 28rem !important;
  }

  .dropdown-item-label {
    span {
      max-width: 16rem !important;
    }
  }
}
</style>
