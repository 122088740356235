<template>
  <div class="flex flex-col h-full items-center py-6">
    <div class="mx-auto mb-4">
      <img
        src="../assets/img/logo/contentstudio-logo-original.png"
        width="210"
        alt="ContentStudio"
      />
    </div>

    <Carousel
      class="featureSlider__carousel"
      :per-page="1"
      :autoplay="true"
      :autoplay-timeout="8000"
      :loop="true"
      pagination-active-color="#005fd0"
      pagination-color="rgba(58, 69, 87, 0.2)"
      :pagination-padding="4"
    >
      <Slide>
        <div
          class="flex flex-col items-center justify-center text-center h-full p-9"
        >
          <div class="max-w-3xl">
            <h2 class="text-2xl font-bold mb-4">AI Caption Generation - v2</h2>
            <p class="text-base mb-4"
              >Use our new AI-powered technology to develop high-quality, unique
              content for your social media platforms. Get more done and engage
              better with your audience by saving time and effort and focusing
              on the essentials!</p
            >
          </div>
          <div class="feature-img-container w-full">
            <img
              class="w-full"
              draggable="false"
              src="@assets/img/new-features/feature-image-ai-captions.png"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
      <Slide>
        <div
          class="flex flex-col items-center justify-center text-center h-full p-9"
        >
          <div class="max-w-3xl">
            <h2 class="text-2xl font-bold mb-4">AI Image Generation</h2>
            <p class="text-base mb-4"
              >Transform your social media presence with our cutting-edge
              AI-powered image generation technology. Generate high-quality,
              stunning visuals in seconds and captivate your audience with
              unique, eye-catching content.</p
            >
          </div>
          <div class="feature-img-container w-full">
            <img
              class="w-full"
              draggable="false"
              src="@assets/img/new-features/feature-image-ai-images.png"
              alt="Feature Image"
            />
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'FeaturesSlider',
  components: {
    Carousel,
    Slide,
  },
  // created() {
  //   VueCarousel();
  // }
}
</script>

<style scoped lang="scss">
.feature-img-container {
  @apply max-w-5xl;
  @media (max-height: 848px) {
    @apply max-w-screen-md;
  }
  @media (max-height: 768px) {
    @apply max-w-screen-sm;
  }
}
</style>
