<template>
  <div
    class="follow_tag_block"
    v-if="
      $route.query.tag &&
      getDiscoverCuratedTopic &&
      getDiscoverCuratedTopic.name
    "
  >
    <div class="follow_tag">
      <div class="tag_inner">
        <div class="profile_picture d-flex align-items-center">
          <div class="text_block ml-0">
            <p class="sub_text">Curated Topic</p>
            <p class="text">{{ getDiscoverCuratedTopic.name }}</p>
          </div>
          <template v-if="!getDiscoverCuratedTopic.followed">
            <div class="follow_btn ml-auto">
              <button
                class="btn border_btn"
                @click="followCuratedTopicAction()"
              >
                <span>Follow</span>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="follow_btn ml-auto">
              <button
                class="btn border_btn"
                @click="unfollowCuratedTopicAction()"
              >
                <span><i class="far fa-check mr-2"></i> Following</span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@c-darkBlack: #3a4557;
@green: #1ecb7b;
@cs-background-light-grey: #f4f7fa;
.follow_tag_block {
  .follow_tag {
    min-width: fit-content;
    width: fit-content;

    .tag_inner {
      background: #ffffff;
      // box-shadow: 0 4px 1.125rem -8px rgba(0, 0, 0, 0.15);
      border-radius: 0.625rem;
      padding: 10px 15px;
      .profile_picture {
        border-left: 1px solid @cs-background-light-grey;
        padding-left: 20px;
        .text_block {
          margin: 0em 2em;
          .text {
            font-weight: 500;
            line-height: 28px;
            color: @c-darkBlack;
          }
        }
        .follow_btn {
          .btn {
            border-radius: 7px;
            padding: 4px 0.875rem;
            text-transform: uppercase;
            font-size: 0.9em;
            border: 0px;
            background: rgba(38, 203, 133, 0.1);
            span {
              color: @green;
              i {
                color: #2fe095;
              }
            }
            &:hover {
              background: #6173ff;
              span {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getDiscoverCuratedTopic'])
  },
  methods: {
    async followCuratedTopicAction () {
      const res = await this.$store.dispatch('followCuratedTopic', {
        topic: this.getDiscoverCuratedTopic
      })
      if (res) {
        this.$set(this.getDiscoverCuratedTopic, 'followed', true)
      }
    },
    async unfollowCuratedTopicAction () {
      const res = await this.$store.dispatch('unfollowCuratedTopic', {
        topic: this.getDiscoverCuratedTopic
      })
      if (res) {
        this.$set(this.getDiscoverCuratedTopic, 'followed', false)
      }
    }
  }
}
</script>
