import { mapActions, mapGetters } from 'vuex'
import integrations, { getPlatformName } from '../../lib/integrations'

export const platformMixin = {
  computed: {
    ...mapGetters([
      'getPlatformsConnectionState',
      'getWorkspaces',
      'getFacebookAccounts',
      'getInstagramAccounts',
      'getLinkedinAccounts',
      'getTwitterAccounts',
      'getPinterestAccounts',
      'getYoutubeAccounts',
      'getTiktokAccounts',
    ]),
  },
  methods: {
    ...mapActions([]),
    errorImageReplacement(e) {
      e.target.src =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    getPinterestPlatformByID(id) {
      let platform = ''
      this.getPinterestAccounts.all_items.forEach((index, el) => {
        if (el.profile_id == id || el.board_id == id) {
          platform = el
          return false
        }
      })

      return platform
    },

    pinterestImage(account) {
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      if (account && account.image) {
        if (account.image.large) {
          if (account.image.large.url) {
            image = account.image.large.url
          }
        } else {
          image = account.image
        }
      }

      return image
    },

    getTwitterPlatformByID(twitter_id) {
      let platform = ''

      this.getTwitterAccounts.all_items.forEach((el, index) => {
        if (
            Object.prototype.toString.call(twitter_id) === '[object Array]' &&
            twitter_id
        ) {
          twitter_id = twitter_id[0]
        }

        if (el.twitter_id == twitter_id) {
          platform = el
          return false
        }
      })

      return platform
    },

    twitterImage(twitter) {
      if (twitter && twitter.image) {
        return twitter.image
      }

      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },

    getLinkedinPlatformByID(linkedin_id) {
      let platform = ''

      this.getLinkedinAccounts.all_items.forEach((el, index) => {
        if (el.linkedin_id == linkedin_id) {
          platform = el
          return false
        }
      })

      return platform
    },

    linkedinImage(account) {
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      if (account && account.image) {
        image = account.image
      }
      return image
    },

    instagramImage(account) {
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      if (account) {
        if (account.image) {
          image = account.image
        } else if (account.profile_picture) {
          image = account.profile_picture
        }
      }
      return image
    },

    getInstagramPlatformByID(instagramId) {
      let platform = ''

      this.getInstagramAccounts.all_items.forEach((el, index) => {
        if (el.instagram_id === instagramId) {
          platform = el
          return false
        }
      })

      return platform
    },

    getYoutubePlatformByID(youtubeId) {
      let platform = ''
      this.getYoutubeAccounts.all_items.forEach((el, index) => {
        if (el.platform_identifier === youtubeId) {
          platform = el
          return false
        }
      })

      return platform
    },
    getTiktokPlatformByID(tiktokId) {
      let platform = ''
      this.getTiktokAccounts.items.forEach((el, index) => {
        if (el.platform_identifier === tiktokId) {
          platform = el
          return false
        }
      })
      return platform
    },

    facebookImage(account) {
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      if (account) {
        if (account.image) {
          image = account.image
        } else if (account.picture && account.picture.url) {
          image = account.picture.url
        }
      }
      return image
    },

    getFacebookPlatformByID(facebookId) {
      let platform = ''

      this.getFacebookAccounts.all_items.forEach((el, index) => {
        if (el.facebook_id === facebookId) {
          platform = el
          return false
        }
      })

      return platform
    },

    accountTooltipHtml(platformType, platform, onlyName = false) {
      let name = ''
      let handler = ''
      let type = ''
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      if (platformType === 'wordpress') {
        name = platform.url
      } else if (platformType === 'tumblrBlog') {
        name = platform.url
        image = this.tumblrImage(platform)
      } else if (platformType === 'medium') {
        name = platform.url
        image = this.mediumImage(platform)
      } else if (platformType === 'gmb') {
        image = integrations.platformImage(platform)
        type = 'Location'
        name = getPlatformName(platform)
      } else if (platformType === 'influencer') {
        image = platform.profile_image
        type = 'Followers'
        name =
          platform.name +
          '<span class="screen_name">@' +
          platform.screen_name +
          '</span>'
        handler = '  <strong>' + platform.follower_count + '</strong>'
      } else {
        if (!platform) return
        name = platform.name
        if (platformType === 'facebook') {
          image = this.facebookImage(platform)
          type = platform.type
        } else if (platformType === 'twitter') {
          image = this.twitterImage(platform)
          handler = `<span class='handler'>@${platform.screen_name}</span>`
          type = 'Profile'
        } else if (platformType === 'linkedin') {
          image = this.linkedinImage(platform)
          type = platform.type
        } else if (platformType === 'pinterest') {
          image = this.pinterestImage(platform)
          type = platform.type
        } else if (platformType === 'tumblr') {
          name = platform.platform_name
          image = this.tumblrImage(platform)
          type = platform.type
        } else if (platformType === 'instagram') {
          image = this.instagramImage(platform)
          type = 'Profile'
        }
      }

      if (onlyName) return name
      return `<div class='team_detail_tooltip'>
                  <div class='t_inner d-flex align-items-center'>
                      <div class='img' style='background: url(${image});'></div>
                      <div class='t_content'>
                          <h3>${name}</h3>
                          <p>${type}${handler}</p>
                      </div>
                  </div>
              </div>`
    },
    mediumImage(account) {
      if (account && account.imageUrl) {
        return account.imageUrl
      }
      return ''
    },

    getWordpressAuthorName(authors, id) {
      let author = ''

      $(authors).each(function (index, el) {
        if (el.user_id == id) {
          author = el.display_name
          return false
        }
      })

      return author
    },
    getWordpressCategoryName(categories, id) {
      let category = ''

      $(categories).each(function (index, el) {
        if (el.term_id == id) {
          category = el.name
          return false
        }
      })

      return category
    },
    tumblrImage(account) {
      if (account && account.image) {
        return account.image
      }

      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    youtubeImage(account) {
      if (account && account.platform_logo) {
        return account.platform_logo
      }

      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    getTumblrPlatformByName(name, type) {
      let platform = ''
      this.getTumblrAccounts.all_items.forEach((index, el) => {
        if (el.name === name) {
          platform = el
          return false
        }
      })

      return platform
    },
  },
}
