import io from 'socket.io-client'
import { composerBaseUrl, plannerBaseUrl } from '../../../config/api-utils'

export const getPublicationFoldersURL =
  composerBaseUrl + 'getPublicationFolders'
export const createPublicationFolderURL =
  composerBaseUrl + 'createPublicationFolder'
export const updatePublicationFolderNameURL =
  composerBaseUrl + 'updatePublicationFolderName'
export const updateDefaultPublicationFolderNameURL =
  composerBaseUrl + 'updateDefaultPublicationFolderName'
export const removePublicationFolderURL =
  composerBaseUrl + 'removePublicationFolder'
export const updatePublicationFolderIdURL =
  plannerBaseUrl + 'updatePlanFolderId'
