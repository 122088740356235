import {
  facebookSharingTypes,
  getDefaultSharingProgressBar,
  getDefaultVideoSharingDetails
} from '../mutation-types'

const getDefaultFacebookSharingDetails = () => {
  return {
    message: '',
    title: '',
    description: '',
    image: [],
    video: getDefaultVideoSharingDetails(),
    url: '',
    website: '',
    image_suggestions: [],
    mentions: [],
    location: {
      id: '',
      name: ''
    }
  }
}

export default {
  state: {
    selection: getDefaultFacebookSharingDetails(),
    cancelledLink: '',
    progressBar: getDefaultSharingProgressBar()
  },
  actions: {
    resetFacebookSharingDetails ({ commit }) {
      commit('resetFacebookSharingDetails')
    },
    setFacebookSharingImage ({ commit }, image) {
      commit('setFacebookSharingImage', image)
    },

    setFacebookSharingImageSuggestions ({ commit }, images) {
      commit('setFacebookSharingImageSuggestions', images)
    },

    setFacebookSharingMessage ({ commit }, message) {
      commit('setFacebookSharingMessage', message)
    },

    setFacebookSharingLink ({ commit }, url) {
      commit('setFacebookSharingLink', url)
    },

    setFacebookSharingPreview ({ commit }, preview) {
      commit('setFacebookSharingPreview', preview)
    },

    setFacebookSharingDetails ({ commit }, data) {
      commit('setFacebookSharingDetails', data)
    },

    setDefaultFacebookSharing ({ commit }) {
      commit('setDefaultFacebookSharing')
    },

    setFacebookCancelledLink ({ commit }, link) {
      commit('setFacebookCancelledLink', link)
    },

    setFacebookImagePercentage ({ commit }, percent) {
      commit('setFacebookImagePercentage', percent)
    },

    setFacebookSharingNumberOfImages ({ commit }, images) {
      commit('setFacebookSharingNumberOfImages', images)
    }
  },
  mutations: {
    resetFacebookSharingDetails: function (state) {
      Object.assign(state.selection, getDefaultFacebookSharingDetails())
    },

    setFacebookSharingImage (state, image) {
      state.selection.image = image
    },

    setFacebookSharingImageSuggestions (state, images) {
      state.selection.image_suggestions = images
    },

    setFacebookSharingMessage (state, message) {
      state.selection.message = message
    },

    setFacebookSharingLink (state, url) {
      state.selection.url = url
    },

    setFacebookSharingPreview (state, preview) {
      if (preview) {
        state.selection.title = preview.title
        state.selection.description = preview.description
        state.selection.website = preview.website
        state.selection.image = preview.image
        state.selection.image_suggestions = preview.image_suggestions
      } else {
        state.selection.title = ''
        state.selection.description = ''
        state.selection.url = ''
        state.selection.website = ''
      }
    },

    setFacebookSharingDetails (state, data) {
      state.cancelledLink = ''
      if (data == null) {
        state.selection = getDefaultFacebookSharingDetails()
        return true
      }
      if (typeof data.location === 'undefined') { data.location = { id: '', name: '' } }
      state.selection = data
    },

    setDefaultFacebookSharing (state) {
      state.selection = getDefaultFacebookSharingDetails()
      state.cancelledLink = ''
      state.progressBar = getDefaultSharingProgressBar()
    },

    setFacebookCancelledLink (state, link) {
      state.cancelledLink = link
    },

    setFacebookImagePercentage (state, percent) {
      state.progressBar.image = percent
    },

    setFacebookSharingNumberOfImages (state, images) {
      state.progressBar.numberOfImages = images
    },

    SET_FACEBOOK_SHARING_VIDEO (state, video) {
      state.selection.video = video || getDefaultVideoSharingDetails()
    },

    SET_FACEBOOK_SHARING_VIDEO_INITIALIZATION (state) {
      state.selection.title = ''
      state.selection.description = ''
      state.selection.url = ''
      state.selection.website = ''
      state.selection.image = []
      state.selection.image_suggestions = []
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    SET_FACEBOOK_SHARING_VIDEO_PERCENTAGE (state, percent) {
      state.progressBar.video = percent
    },

    SET_FACEBOOK_SHARING_VIDEO_THUMBNAIL (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },

    SET_FACEBOOK_SHARING_VIDEO_SUGGESTIONS (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },

    [facebookSharingTypes.SET_MENTIONS] (state, mentions) {
      state.selection.mentions = mentions
    },

    [facebookSharingTypes.SET_LOCATION] (state, location) {
      state.selection.location = location
    },

    [facebookSharingTypes.RESET_FACEBOOK_SHARING_DETAILS] (state) {
      state.selection = getDefaultFacebookSharingDetails()
      state.progressBar = getDefaultSharingProgressBar()
    }
  },
  getters: {
    getFacebookSharingDetails: (state) => {
      return state.selection
    },

    getFacebookSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getFacebookSharingProgressBar: (state) => {
      return state.progressBar
    }
  }
}
