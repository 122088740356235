<script>
import { mapGetters } from 'vuex'
import AddPinterestModal from '@src/modules/integration/components/modals/AddPinterestModal'
import { platforms } from '@src/modules/integration/store/states/mutation-types'
import SaveSocialAccounts from '@src/modules/integration/components/dialogs/SaveSocialAccounts'
import AccountsNotAllowedModal from '@src/modules/integration/components/modals/AccountsNotAllowedModal.vue'
import {EventBus} from "@common/lib/event-bus";
import BulkReconnectedAccounts from "@src/modules/integration/components/dialogs/BulkReconnectedAccounts.vue";
import TimeQueue from '../../dialogs/TimeQueue'
import SavePlatform from '../../dialogs/SavePlatform'
import RemoveIntegration from '../../dialogs/remove/RemoveIntegration'
import AddGroupModal from '../../modals/AddGroupModal'
import AccountListing from './AccountListing'
export default {
  components: {
    BulkReconnectedAccounts,
    AccountsNotAllowedModal,
    SaveSocialAccounts,
    RemoveIntegration,
    AccountListing,
    SavePlatform,
    TimeQueue,
    AddPinterestModal,
    AddGroupModal,
  },

  data() {
    return {
      gmbView: 'on',
      type: '',
      platform: {},
      social_authorization_interval: '',
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getFetchAccountsStatus', 'getWorkspaces']),
    featureFlagYoutube() {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
  },
  watch: {
    'getActiveWorkspace._id'(val) {
      console.debug('Watch::Social-getActiveWorkspace._id', val.length)
      if (val.length > 0) {
        this.initializeSection()
      }
    },
  },
  created() {
    this.initializeSection()
  },
  mounted() {
    setTimeout(() => {
      const ele = this.$route.hash ? this.$route.hash : '#social_component'
      const topPos =
        document.querySelector(ele).getBoundingClientRect().top - 100
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      })
    }, 500)
  },
  beforeDestroy() {
    console.debug('beforeDestroy social')
    window.clearInterval(this.social_authorization_interval)
  },
  methods: {
    async initializeSection() {
      console.debug('Social::initializeSection')
      this.$store.dispatch('fetchSocialAccounts')
      this.$store.dispatch('fetchSocialAuthorizationLinks')
      this.social_authorization_interval = window.setInterval(
        function () {
          this.$store.dispatch('fetchSocialAuthorizationLinks')
        }.bind(this),
        1200000
      )
      const response = await this.$store.dispatch(
        'checkConnectorState',
        this.$route.hash
      )

      if(response?.data?.processType === 'bulk_reconnect') {
        await this.processBulkReconnectResponse(response)
      } else {
        await this.processReconnectResponse(response)
      }
    },
    getPlatforms() {
      return [
        'facebook',
        'instagram',
        'twitter',
        'linkedin',
        'pinterest',
        'gmb',
        'youtube',
        'tiktok',
        'tumblr',
      ]
    },
    /**
     * Normal connection & reconnection process.
     * Shows a modal containing accounts to be added in case of normal connection.
     * Shows a notification of success or failure in case of individual reconnection.
     * @param response
     * @returns {Promise<void>}
     */
    async processReconnectResponse(response) {
      if (response?.data) {
        if (response.data.limitExceed) {
          this.showUpgradeModal()
          return
        }
        if (response.data.status === true) {
          this.trackUserMaven('connected_social_accounts')
          switch (response.data.process) {
            case 'tiktok':
            case 'Twitter':
              if (
                  !this.getActiveWorkspace.onboarding_steps.connect_social_account
                      .status
              ) {
                await this.onboardingStepsCompleted('connect_social_account')
              }
              break
            case 'Facebook':
            case 'Instagram':
            case 'Linkedin':
            case 'Pinterest':
            case 'tumblr':
            case 'Gmb':
            case 'Youtube':
              if (
                  response.data.pages ||
                  response.data.groups ||
                  response.data.profile ||
                  response.data.boards ||
                  response.data.profiles ||
                  response.data.blogs ||
                  response.data.locations ||
                  response.data.channels
              ) {
                this.$store.commit(
                    platforms.SET_CONNECTION_STATE,
                    response.data
                )
                this.$bvModal.show('save-social-accounts')
                return
              }
              break
          }
          if (response.data?.not_allowed.length) {
            EventBus.$emit('showNotAllowedModal', {
              notAllowedIds: response.data.not_allowed,
              firstSectionItems: [response.data.profile || []],
              firstSectionHeader: 'Social Account',
            })
            return
          }
          this.$store.dispatch('toastNotification', {
            message: response.data.message,
            type: 'success',
          })
        }
      }
    },
    /**
     * Bulk reconnection process.
     * Shows a modal of success and failed accounts connection.
     * @param response
     */
    processBulkReconnectResponse(response) {
      const {data: {processType, connectionData}} = response
      if(connectionData) {
        this.trackUserMaven('connected_social_accounts')
        EventBus.$emit('toggleBulkReconnectedAccounts', {toggleValue: true, connectionData})
        this.$bvModal.show('bulkReconnectedAccounts')
      }
    }
  },
}
</script>

<template>
  <div
    id="social_component"
    class="setting_groups_component setting_social_component"
  >
    <AddPinterestModal />
    <div class="component_inner">
      <SaveSocialAccounts />
      <AddGroupModal></AddGroupModal>
      <AccountsNotAllowedModal></AccountsNotAllowedModal>
      <SavePlatform></SavePlatform>
      <TimeQueue></TimeQueue>
      <RemoveIntegration :type="type" :platform="platform"></RemoveIntegration>
      <!--BulkReconnectAccountsModal::start-->
      <BulkReconnectedAccounts></BulkReconnectedAccounts>
      <!--BulkReconnectAccountsModal::end-->

      <div class="social_block">
        <template v-for="account in getPlatforms()" :id="account">
          <AccountListing :key="account" :type="account"></AccountListing>
        </template>
      </div>
    </div>
  </div>
</template>
