<template>
  <div class="left_filter">
    <div class="icon_tabs" v-show="!getFilters.topic">
      <ul class="tab_ul">
        <li
          :disabled="isChannelActive('article')"
          v-tooltip.top-center="'Articles'"
          class="tab_li"
          @click.prevent="changeSearchContentType('article')"
        >
          <a
            class="tab_a d-flex align-items-center justify-content-center globe_gray_icon"
            :class="{ active: isChannelActive('article') }"
            href=""
          >
            <i class="far fa-globe"></i>
          </a>
        </li>
        <li
          :disabled="isChannelActive('youtube')"
          v-tooltip.top-center="'Youtube'"
          class="tab_li"
          @click.prevent="changeSearchContentType('youtube')"
        >
          <a
            href=""
            class="tab_a d-flex align-items-center justify-content-center youtube_gray_icon"
            :class="{ active: isChannelActive('youtube') }"
          >
            <i class="icon-Youtube_icon"></i>
          </a>
        </li>
        <!--<li v-if="false" :disabled="isChannelActive('facebook')"  v-tooltip.top-center="'Facebook'" class="tab_li"-->
        <!--@click.prevent="changeSearchContentType('facebook')">-->
        <!--<a href=""-->
        <!--class="tab_a d-flex align-items-center justify-content-center  facebook_gray_icon"-->
        <!--:class="{active:isChannelActive('facebook')}">-->
        <!--<i class="fab fa-facebook-f"></i>-->
        <!--</a>-->
        <!--</li>-->
        <li
          :disabled="isChannelActive('twitter')"
          v-tooltip.top-center="'Twitter'"
          class="tab_li"
          @click.prevent="changeSearchContentType('twitter')"
        >
          <a
            :disabled="isChannelActive('twitter')"
            href=""
            class="tab_a d-flex align-items-center justify-content-center twitter_gray_icon"
            :class="{ active: isChannelActive('twitter') }"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { articlesTypes as articleTypes } from '../../../store/mutation-types'

export default {
  computed: {
    ...mapGetters(['getFilters'])
  },
  methods: {
    ...mapActions(['getContent', 'articles']),
    ...mapMutations([articleTypes.SET_SEARCH_CONTENT_TYPE]),
    /**
     * Checking the type of the active selected channel like web content, facebook, twitter etc.
     * @param type
     * @returns {boolean}
     */
    isChannelActive (type) {
      return this.getSearchContentType === type
    },
    async changeSearchContentType (type) {
      switch (type) {
        case 'article':
          if (this.isChannelActive(type)) return
          await this.getContent({
            type: 'search',
            keyword: this.$route.query.q
          })
          this.articles()
          this.$router.push({
            name: 'search_web',
            query: {
              q: this.$route.query.q
            }
          })
          break

        case 'youtube':
          this.setVideoContentType('search')
          this.getYoutubeVideo()
          this.$router.push({
            name: 'search_youtube',
            query: {
              q: this.$route.query.q
            }
          })
          break

        case 'twitter':
          this.setTwitterContentType('search')
          this.getTwitterContent()
          this.$router.push({
            name: 'search_twitter',
            query: {
              q: this.$route.query.q
            }
          })
          break

        case 'facebook':
          this.setFacebookContentType('search')
          this.getFacebookContent()
          this.$router.push({
            name: 'search_facebook',
            query: {
              q: this.$route.query.q
            }
          })
          break
      }
      console.debug('Method:changeSearchContentType')
      this[articleTypes.SET_SEARCH_CONTENT_TYPE](type)
    }
  }
}
</script>

<style scoped></style>
