var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-12 px-2.5 w-full",on:{"click":function($event){$event.preventDefault();_vm.EventBus.$emit(_vm.getPreview(_vm.isOverview() ? _vm.posts.network : _vm.index), _vm.posts)}}},[_c('div',{staticClass:"col-span-4 xl:col-span-6 2xl:col-span-8 analytics-tab-body__item"},[_c('div',{staticClass:"analytics-tab-body__item-image"},[(
          _vm.isPostImageAvailable(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)
        )?[(_vm.isVideo(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts))?_c('video',{staticClass:"video_preview_image",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.getPostImage(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)}}),_c('source',{attrs:{"src":_vm.getPostImage(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts),"type":"video/ogg"}}),_vm._v(" Your browser does not support the video tag. ")]):_c('img',{attrs:{"src":_vm.getResizedImageURL(
              _vm.getPostImage(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts),
              200,
              0
            ),"alt":""},on:{"error":function($event){return _vm.setPostFallback($event)}}})]:[_c('img',{attrs:{"src":require("../../assets/imgs/no_data_images/not-found.png"),"alt":""}})]],2),_c('div',{staticClass:"analytics-tab-body__item-profile"},[_c('div',{staticClass:"analytics-tab-body__item-profile-header"},[_c('div',[(_vm.getImage(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts))?_c('img',{attrs:{"src":_vm.getImage(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts),"alt":""},on:{"error":function($event){$event.target.src = _vm.fallbackImagePreview(_vm.getPostName(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)[0])}}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg","alt":""}}),_c('i',{class:_vm.getIconClass(_vm.isOverview() ? _vm.posts.network : _vm.index)})]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.getPostName(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.getPostUsername(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts))+" •")]),_vm._v(" "+_vm._s(_vm.getPostDate(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))])])]),_c('div',{staticClass:"analytics-tab-body__item-profile-body"},[(
            _vm.isPostTextAvailable(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)
          )?[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.limitTextLength(
                _vm.isHashtag(
                  _vm.isLink(
                    _vm.nextLineReplacement(
                      _vm.getPostText(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)
                    )
                  )
                ),
                300
              )
            )}})]:_vm._e()],2)])]),_c('div',{staticClass:"col-span-2 2xl:col-span-1 analytics-tab-body__item"},[_c('b',[_vm._v(_vm._s(_vm.getPostEngagement(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))])]),_c('div',{staticClass:"col-span-2 lg:col-span-1 analytics-tab-body__item"},[_c('b',[_vm._v(_vm._s(_vm.getPostLikes(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))])]),_c('div',{staticClass:"col-span-2 2xl:col-span-1 analytics-tab-body__item"},[_c('b',[_vm._v(_vm._s(_vm.getPostComments(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))])]),_c('div',{staticClass:"col-span-2 lg:col-span-1 analytics-tab-body__item"},[_c('b',[_vm._v(_vm._s(_vm.getPostShares(_vm.isOverview() ? _vm.posts.network : _vm.index, _vm.posts)))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }