<template>
  <td class="text-center">
    <div
      class="social_icons d-flex align-items-center flex-wrap justify-content-center"
      :class="[className]"
    >
      <template v-if="blog_selection">
        <template v-for="integration in integrations">
          <template
            v-if="
              blog_selection[integration] && blog_selection[integration].website
            "
          >
            <i
              v-tooltip.top-center="
                getBlogURL(integration, blog_selection[integration].website)
              "
              class="icon_block d-flex align-items-center justify-content-center"
              :class="configrations[integration].background_classes"
            >
            </i>
          </template>
        </template>
      </template>

      <template v-if="secondary">
        <template v-for="account in secondary">
          <template v-if="account.blog_selection">
            <template v-for="integration in integrations">
              <template
                v-if="
                  account.blog_selection[integration] &&
                  account.blog_selection[integration].website
                "
              >
                <i
                  v-tooltip.top-center="
                    getBlogURL(
                      integration,
                      account.blog_selection[integration].website
                    )
                  "
                  class="icon_block d-flex align-items-center justify-content-center"
                  :class="configrations[integration].background_classes"
                >
                </i>
              </template>
            </template>
          </template>
        </template>

        <template v-for="account in secondary">
          <template v-if="account.account_selection">
            <channels-tooltip
              :accounts="account.account_selection"
              :channels_class="'d-flex align-items-center justify-content-center'"
              :channels_size="'28px'"
            ></channels-tooltip>
          </template>
        </template>
      </template>

      <template v-if="account_selection">
        <channels-tooltip
          :accounts="account_selection"
          :channels_class="'d-flex align-items-center justify-content-center'"
          :channels_size="'28px'"
        ></channels-tooltip>
      </template>
    </div>
  </td>
</template>

<script>
import {
  getPlatformByIdentifier,
  getPlatformName
} from '@common/lib/integrations'
import {
  blogIntegrationsNames,
  blogIntegrationsConfigrations
} from '../../integration/config/api-utils'

export default {
  props: ['blog_selection', 'account_selection', 'secondary', 'className'],
  data () {
    return {
      integrations: blogIntegrationsNames,
      configrations: blogIntegrationsConfigrations
    }
  },
  methods: {
    platformText (platform) {
      return getPlatformName(platform)
    },

    getBlogURL (type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    }
  }
}
</script>
