<template>
  <b-modal
    id="planner-post-preview"
    dialog-class="flex fixed mw-100 m-0 top-0 bottom-0 left-0 right-0"
    content-class="bg-transparent"
    body-class="relative h-5/6"
    hide-footer
    hide-header
    @shown="onModalShow"
    @hidden="onModalHide"
  >
    <!-- Modal Close Button -->
    <div
      v-tooltip="'Close'"
      class="absolute top-5 right-5 cursor-pointer text-4xl text-gray-600 hover:text-white"
      @click="$bvModal.hide('planner-post-preview')"
    >
      <i class="fa fa-times"></i>
    </div>

    <div class="h-100 flex items-center justify-between space-x-7">
      <!-- Left button -->
      <div class="w-[94px] flex-shrink-0">
        <div
          v-if="!disableLeft"
          class="flex items-center justify-start space-x-2 text-center text-white cursor-pointer bg-gray-900 bg-opacity-95 hover:bg-opacity-100 px-2.5 py-2 rounded-xl"
          @click="previousPlan"
        >
          <template>
            <i class="fa fa-chevron-left text-2xl"></i>
            <span class="font-medium text-sm">Previous Post</span>
          </template>
        </div>
      </div>

      <!-- Content -->
      <div
        class="post-preview-content bg-white flex justify-between rounded-xl"
      >
        <!-- Accounts -->
        <div class="bg-cs-light-gray w-[27rem] h-100 accounts-list">
          <div class="flex flex-col h-100">
            <div class="p-3">
              <div class="flex items-center relative py-2">
                <span class="ml-1 font-medium text-base"> Accounts </span>
              </div>
            </div>
            <hr class="m-0 hr-color" />

            <!-- Selected Accounts List -->
            <div
              class="flex flex-col space-y-4 text-gray-600 mt-3 px-3 py-2 overflow-y-auto"
            >
              <template v-for="(channel, key) in getSocialAccounts">
                <template v-for="(account, index) in channel">
                  <span
                    :key="key + index"
                    class="flex items-center cursor-pointer"
                    :class="{ 'opacity-60': !activeSocialAccount(account) }"
                    @click="selectSocialAccount(account, key)"
                  >
                    <span class="relative">
                      <img
                        :src="channelImage(account, key)"
                        alt=""
                        class="rounded-full object-cover border"
                        data-fallback="0"
                        height="30"
                        width="30"
                        @error="
                          externalPostPreview
                            ? ($event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`)
                            : onError($event, account.platform_logo)
                        "
                      />
                      <img
                        :src="getSocialImage(key)"
                        alt=""
                        class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                      />
                    </span>
                    <span class="flex flex-col ml-3 flex-1">
                      <span class="flex flex-row">
                        <span
                          v-tooltip="{
                            content: sanitizedSocialTooltip(
                              'platform_name' in account
                                ? account.platform_name
                                : account.name
                            ),
                          }"
                          class="text-sm text-ellipsis font-normal text-black-100 select-none"
                        >
                          {{
                            truncateString(
                              'platform_name' in account
                                ? account.platform_name
                                : account.name,
                              20
                            )
                          }}
                        </span>
                      </span>

                      <span class="block text-xs select-none text-gray-800">
                        {{ 'type' in account ? account.type : 'Profile' }}
                      </span>
                    </span>
                  </span>
                </template>
              </template>
              <template v-for="(account, key) in getBlogAccounts">
                <template v-if="account.website">
                  <span
                    :key="`blog_${key}`"
                    class="flex items-center cursor-pointer"
                  >
                    <span class="relative">
                      <img
                        :src="getFullBlogAccount(account.website).platform_logo"
                        alt=""
                        class="rounded-full object-cover border"
                        data-fallback="0"
                        height="30"
                        width="30"
                        @error="
                          onError(
                            $event,
                            getFullBlogAccount(account.website).platform_logo
                          )
                        "
                      />

                      <img
                        v-if="
                          getFullBlogAccount(account.website).integration ===
                          'wordpress'
                        "
                        src="https://storage.googleapis.com/lumotive-web-storage/default/wordpress.png"
                        alt=""
                        class="absolute -right-0.5 -bottom-0.5 w-4 h-4"
                      />

                      <img
                        v-else
                        :src="
                          require('@assets/img/integration/' +
                            getBlogImage(
                              getFullBlogAccount(account.website).integration
                            ))
                        "
                        alt=""
                        class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                      />
                    </span>
                    <span class="flex flex-col ml-3 flex-1">
                      <span
                        v-tooltip="{
                          content:
                            getFullBlogAccount(account.website).platform_name
                              .length > 12
                              ? getFullBlogAccount(account.website)
                                  .platform_name
                              : '',
                        }"
                        class="text-sm font-normal text-black-100 select-none"
                      >
                        {{
                          truncateString(
                            getFullBlogAccount(account.website).platform_name,
                            12
                          )
                        }}
                      </span>

                      <span class="block text-xs select-none text-gray-700">
                        Blog
                      </span>
                    </span>
                  </span>
                </template>
                <!--                <code>{{getFullBlogAccount(account.website)}}</code>-->
              </template>
              <template v-if="!hasAccountSelection">
                <div class="py-10 flex items-center justify-center w-full">
                  <span class="font-medium text-sm"> No Account Selected </span>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- Post Details -->
        <div class="flex flex-col w-full h-full max-w-lg xl:max-w-[42rem]">
          <div class="flex justify-between bg-cs-light-gray">
            <h2 class="font-medium text-base h-16 flex items-center p-3">
              Post Details

              <span
                class="text-sm font-medium mr-2 px-2.5 py-0.5 rounded-lg ml-2 capitalize flex items-center justify-center"
                :class="postStatusBadgeClasses"
              >
                <span
                  class="w-1.5 h-1.5 rounded-full mr-1.5 bg-cs-white bg-opacity-95 block"
                ></span>
                {{ planStatus }}
              </span>
            </h2>
            <div
              v-if="hasPlan && (isSocialPost || isBlogPost)"
              class="flex justify-between space-x-3 mr-4 items-center"
            >
              <template v-if="automationApproval">
                <img
                  v-tooltip="'Approve'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="require('@assets/img/icons/planner/approve.svg')"
                  @click="automationApprovalAction('scheduled')"
                />
                <img
                  v-tooltip="'Reject'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="
                    require('@assets/img/icons/planner/more_actions_reject_post.svg')
                  "
                  @click="automationApprovalAction('rejected')"
                />
              </template>
              <template
                v-else-if="
                  canApprove ||
                  (externalPostPreview &&
                    (plan.post_state === 'Scheduled' ||
                      plan.post_state === 'Draft' ||
                      plan.post_state === 'In Review' ||
                      plan.post_state === 'Missed Review'))
                "
              >
                <img
                  v-tooltip="'Approve'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="require('@assets/img/icons/planner/approve.svg')"
                  @click="approveWithComment"
                />
                <img
                  v-tooltip="'Reject'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="
                    require('@assets/img/icons/planner/more_actions_reject_post.svg')
                  "
                  @click="rejectWithComment"
                />
              </template>
              <template v-if="canEdit">
                <img
                  v-tooltip="'Edit'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="
                    require('@assets/img/icons/planner/more_actions_edit.svg')
                  "
                  @click="editPlan"
                />
              </template>
              <template v-if="canDuplicate">
                <img
                  v-tooltip="'Duplicate'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="
                    require('@assets/img/icons/planner/more_actions_duplicate.svg')
                  "
                  @click="duplicatePlan"
                />
              </template>
              <template v-if="canDelete">
                <div
                  v-tooltip="'Delete'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white flex justify-center items-center hover:!border-gray-900"
                  @click="deletePlan"
                >
                  <i class="far fa-trash-alt text-red-500 !shadow-none"></i>
                </div>
              </template>
              <template>
                <img
                  v-tooltip="'Post Status'"
                  class="w-7 h-7 border rounded-md hover:bg-gray-100 p-1 cursor-pointer bg-white hover:!border-gray-900"
                  :src="
                    require('@assets/img/icons/planner/more_plan_actions_post_status_1.svg')
                  "
                  @click="showPostStats"
                />
              </template>
            </div>
          </div>
          <hr class="m-0 hr-color" />

          <!-- Post Preview -->
          <div
            v-if="hasPlan && isSocialPost"
            class="p-3 overflow-y-auto h-full"
          >
            <template v-if="!hasAccountSelection">
              <div class="flex flex-col items-center justify-center">
                <img
                  src="@assets/img/composer/no-preview.png"
                  alt=""
                  class="mt-4 w-52 opacity-70"
                />
                <span class="mt-6 block text-md text-gray-800 leading-5">
                  You have not selected any social accounts yet.
                </span>
              </div>
            </template>
            <template v-else>
              <div
                class="my-2 mx-1 text-center text-sm text-gray-700 leading-5"
              >
                <img
                  src="@assets/img/composer/info-icon-gray.svg"
                  class="w-5 h-5"
                />
                <span>
                  The generated previews are not an exact depiction of what the
                  post will look like on the platform, there will be some minor
                  difference.
                </span>
              </div>
              <template v-if="showTwitterPreview">
                <TwitterPreview
                  :execution-time="plan.execution_time"
                  :common-box-status="plan.common_box_status"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                  :thread="fetchTwitterThreads"
                />
              </template>
              <template v-else-if="showLinkedinPreview">
                <LinkedinPreview
                  :execution-time="plan.execution_time"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                />
              </template>
              <template v-else-if="showPinterestPreview">
                <PinterestPreview
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :followers-count="180"
                  :detail="getFeedSharingDetails"
                  source-url=""
                />
              </template>
              <template v-else-if="showTumblrPreview">
                <TumblrPreview
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                />
              </template>
              <template v-else-if="showInstagramPreview">
                <InstagramPreview
                  type="planner"
                  :common-box-status="plan.common_box_status"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                  :instagram-post-type="plan.instagram_post_type"
                  :toggle-preview="true"
                  :hide-footer="true"
                  :comment="''"
                  :user-tags="getFeedSharingDetails.image_tagging_details"
                />
              </template>
              <template v-else-if="showYoutubePreview">
                <YoutubePreview
                  type="planner"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                  :video-title="plan.youtube_options.title"
                  :youtube-post-type="plan.youtube_options.post_type"
                  :comment="''"
                />
              </template>
              <template v-else-if="showTiktokPreview">
                <TikTokPreview
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                />
              </template>
              <template v-else-if="showFacebookPreview">
                <FacebookPreview
                  type="planner"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :facebook-post-type="findFacebookPostType"
                  :common-box-status="plan.common_box_status"
                  :detail="getFeedSharingDetails"
                  :publish-as="getFacebookPublishAs"
                  :carousel="
                    plan.carousel_options ? plan.carousel_options : null
                  "
                  :carousel-account="findFacebookCarouselAccount(plan)"
                  :execution-time="plan.execution_time"
                />
              </template>
              <template v-else-if="showGmbPreview">
                <GmbPreview
                  :execution-time="plan.execution_time"
                  :account="
                    getAccount(selectedChannelType, accountInPreview, store)
                  "
                  :detail="getFeedSharingDetails"
                  :cta="getGMBOptions('cta')"
                  :event-title="getGMBOptions('title')"
                  :event-date="
                    getDateTimeFormat(getGMBOptions('start_date'), 'MMM DD') +
                    ' - ' +
                    getDateTimeFormat(getGMBOptions('end_date'), 'MMM DD')
                  "
                />
              </template>
            </template>
          </div>
          <div v-if="hasPlan && isBlogPost" class="p-3 overflow-y-auto">
            <div
              class="flex flex-col space-y-4 bg-white cst-drop-shadow overflow-auto p-3 rounded-xl w-full"
            >
              <div class="flex flex-col space-y-3">
                <!--                <img
                  v-if="plan.post.image.link"
                  class="max-w-full opacity-0"
                  :src="plan.post.image.link"
                  alt=""
                  @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                    }
                  "
                />-->
                <span
                  v-if="plan.post.title"
                  class="text-xl px-3"
                  v-html="plan.post.title"
                ></span>
                <span
                  v-if="plan.post.description"
                  class="px-3"
                  v-html="plan.post.description"
                ></span>
              </div>
              <div class="flex px-3 space-x-3 font-bold">
                <span class="text-gray-800"
                  >Likes: <span class="ml-1 text-black-900">0</span></span
                >
                <span class="text-gray-800"
                  >Shares: <span class="ml-1 text-black-900">0</span></span
                >
                <span class="text-gray-800"
                  >Comments: <span class="ml-1 text-black-900">0</span></span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Comments/Internal Notes -->
        <div class="comments-list flex flex-col w-1/2 bg-cs-light-gray">
          <h2 class="flex font-medium text-base h-16 items-center p-5">{{
            externalPostPreview ? 'Add a comment' : 'Comments'
          }}</h2>
          <hr class="m-0 hr-color" />
          <div
            v-if="externalPostPreview"
            class="overflow-y-auto py-3 px-2 h-full"
          >
            <template>
              <CommentsAndNotes
                :plan="plan"
                comment-type="external"
                :token="token"
              />
            </template>
          </div>
          <div v-else class="overflow-y-auto py-3 px-2 h-full">
            <!-- Comments -->
            <template>
              <CommentsAndNotes
                :plan="plan"
                :external-comments="plan.external_comments"
              />
            </template>

            <!-- Internal Notes -->
            <template v-if="!clientProfile">
              <CommentsAndNotes :plan="plan" comment-type="notes" />
            </template>

            <!-- Approval Status -->
            <template v-if="showApprovalStatus || showExternalActions">
              <PlanApprovalStatus
                :plan="plan"
                :external-actions="plan.external_actions"
              />
            </template>
          </div>
        </div>
      </div>

      <!-- Right button -->
      <div class="w-[94px] flex-shrink-0">
        <div
          v-if="!disableRight"
          class="flex items-center justify-end space-x-2 text-center text-white cursor-pointer bg-gray-900 bg-opacity-95 hover:bg-opacity-100 px-2.5 py-2 rounded-xl"
          @click="nextPlan"
        >
          <template>
            <span class="font-medium text-sm">Next Post</span>
            <i class="fa fa-chevron-right text-2xl"></i>
          </template>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent, ref, computed, nextTick, inject } from 'vue'
import { useStore } from '@state/base'
import CommentCard from '@src/modules/planner_v2/components/CommentCard'
import AtTa from 'vue-at/dist/vue-at-textarea'
import Emoji from '@src/modules/publish/components/posting/social/emoji/Emoji'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import FacebookPreview from '@src/modules/composer_v2/components/SocialPreviews/FacebookPreview'
import TwitterPreview from '@src/modules/composer_v2/components/SocialPreviews/TwitterPreview'
import InstagramPreview from '@src/modules/composer_v2/components/SocialPreviews/InstagramPreview'
import YoutubePreview from '@src/modules/composer_v2/components/SocialPreviews/YoutubePreview'
import LinkedinPreview from '@src/modules/composer_v2/components/SocialPreviews/LinkedinPreview'
import PinterestPreview from '@src/modules/composer_v2/components/SocialPreviews/PinterestPreview'
import TumblrPreview from '@src/modules/composer_v2/components/SocialPreviews/TumblrPreview'
import GmbPreview from '@src/modules/composer_v2/components/SocialPreviews/GmbPreview'
import TikTokPreview from '@src/modules/composer_v2/components/SocialPreviews/TikTokPreview'
import { commonMethods } from '@common/store/common-methods'
import CommentsAndNotes from '@src/modules/planner_v2/components/CommentsAndNotes'
import {
  getAccount,
  platformAccountsList,
} from '@common/composables/useSocialChannels'
import { checkApprovalStatus } from '@common/composables/useApproval'
import isEmpty from 'lodash/isEmpty'
import PlanApprovalStatus from '@src/modules/planner_v2/components/PlanApprovalStatus'
import { EventBus } from '@common/lib/event-bus'

export default defineComponent({
  components: {
    PlanApprovalStatus,
    CommentsAndNotes,
    FacebookPreview,
    TwitterPreview,
    InstagramPreview,
    YoutubePreview,
    LinkedinPreview,
    PinterestPreview,
    TumblrPreview,
    GmbPreview,
    TikTokPreview,
    CommentCard,
    AtTa,
    Emoji,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    disableLeft: {
      type: Boolean,
      default: false,
    },
    disableRight: {
      type: Boolean,
      default: false,
    },
    externalPostPreview: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
  },
  mixins: [Helpers], // can't be used in setup() but it can be used in vuejs templates
  setup(props, { emit }) {
    const store = {
      getters: useStore().getters,
    }
    const root = inject('root')
    const selectedChannelType = ref('') // channel name e.g. facebook, twitter etc
    const accountInPreview = ref(null)
    const processingComment = ref(false)
    const imgs = ref('https://via.placeholder.com/300.png/')
    const visible = ref(false)
    const comment = ref({
      text: '',
      mentioned_user: [],
      media: [],
    })
    const internalNote = ref({
      text: '',
      mentioned_user: [],
      media: [],
    })
    const loader = ref({
      file: false,
      comments: false,
    })
    const commented = ref(null)
    const noted = ref(null)

    /**
     * Computes and get the post status classes - in progress, scheduled, published, partially failed, failed,
     * rejected, in review, draft, missed review
     * @type {ComputedRef<string>}
     */
    const postStatusBadgeClasses = computed(() => {
      // get the post status
      const status = props.plan.status

      if (props.plan.partially_failed) {
        return 'bg-status-partially_failed text-white'
      }

      // create the classes
      switch (status) {
        case 'processing':
          return 'bg-status-in_progress text-white'
        case 'scheduled':
          return 'bg-status-scheduled text-white'
        case 'published':
          return 'bg-status-published text-white'
        case 'partially_failed':
          return 'bg-status-partially_failed text-white'
        case 'failed':
          return 'bg-status-failed text-white'
        case 'rejected':
          return 'bg-status-rejected text-white'
        case 'review':
          if (props.plan.render_class.tooltip === 'In review') {
            return 'bg-status-under_review text-white'
          } else {
            return 'bg-status-missed_review text-white'
          }
        case 'draft':
          return 'bg-status-draft text-white'
        case 'missed_review':
          return 'bg-status-missed_review text-white'
        default:
          return 'bg-status-draft text-white'
      }
    })

    /**
     * Computes and checks if facebook preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showFacebookPreview = computed(() => {
      return selectedChannelType.value === 'facebook'
    })

    /**
     * Computes and checks if linkedin preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showLinkedinPreview = computed(() => {
      return selectedChannelType.value === 'linkedin'
    })

    /**
     * Computes and checks if twitter preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTwitterPreview = computed(() => {
      return selectedChannelType.value === 'twitter'
    })

    /**
     * Computes and checks if pinterest preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showPinterestPreview = computed(() => {
      return selectedChannelType.value === 'pinterest'
    })

    /**
     * Computes and checks if tumblr preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTumblrPreview = computed(() => {
      return selectedChannelType.value === 'tumblr'
    })

    /**
     * Computes and checks if instagram preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showInstagramPreview = computed(() => {
      return selectedChannelType.value === 'instagram'
    })

    /**
     * Computes and checks if youtube preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showYoutubePreview = computed(() => {
      return selectedChannelType.value === 'youtube'
    })

    /**
     * Computes and checks if tiktok preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTiktokPreview = computed(() => {
      return selectedChannelType.value === 'tiktok'
    })

    /**
     * Computes and checks if gmb preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showGmbPreview = computed(() => {
      return selectedChannelType.value === 'gmb'
    })

    /**
     * Fetches the sharing details of active social channel feed
     * @type {ComputedRef<*>}
     */
    const getFeedSharingDetails = computed(() => {
      return props.plan?.common_box_status
        ? props.plan?.common_sharing_details
        : props.plan[`${selectedChannelType.value}_sharing_details`]
    })

    /**
     * If facebook preview is active and group channel is selected, returns the profile/account used to publish as
     * @type {ComputedRef<unknown>}
     */
    const getFacebookPublishAs = computed(() => {
      if (props.plan?.feed_preview?.account?.type === 'Group') {
        return commonMethods.getFBGroupPostedAsAccount(
          findFacebookAccount(
            getAccount(
              props.plan?.feed_preview?.channel,
              props.plan?.feed_preview?.account,
              store
            )?.facebook_id
          ),
          props.plan?.facebook_options?.posted_as
            ? props.plan?.facebook_options?.posted_as
            : false
        )
      }
      return null
    })

    /**
     * Checks if the user has the permission/access to approve plan
     * @type {ComputedRef<unknown>}
     */
    const canApprove = computed(() => {
      return (
        props.plan.approval?.status === 'pending_approval' &&
        checkApprovalStatus(props.plan?.approval, false, store)
      )
    })

    /**
     * Checks if the user has the permission/access to delete plan
     * @type {ComputedRef<unknown>}
     */
    const canDelete = computed(() => {
      return props.plan.can_perform?.delete && !planInProgress.value
    })

    /**
     * Checks if the user has the permission/access to duplicate plan
     * @type {ComputedRef<*>}
     */
    const canDuplicate = computed(() => {
      return props.plan.can_perform?.duplicate
    })

    /**
     * Checks if the user has the permission/access to edit plan
     * @type {ComputedRef<unknown>}
     */
    const canEdit = computed(() => {
      return root.canEditPost(props.plan) && !planInProgress.value
    })

    /**
     * Checks if the user has the permission/access to replace plan
     * @type {ComputedRef<*>}
     */
    const canReplace = computed(() => {
      return props.plan.can_perform?.replace
    })

    /**
     * Verifies if the props has an active plan
     * @type {ComputedRef<boolean>}
     */
    const hasPlan = computed(() => {
      return !isEmpty(props.plan)
    })

    /**
     * Checks if the plan status is currently in progress.
     * @type {ComputedRef<unknown>}
     */
    const planInProgress = computed(() => {
      return (
        props.plan.status === 'queued' || props.plan.status === 'processing'
      )
    })

    /**
     * Checks if the plan has any active account selection
     * @type {ComputedRef<unknown>}
     */
    const hasAccountSelection = computed(() => {
      let hasAccSelection = false
      if (!hasPlan.value) return false
      if (isSocialPost.value) {
        const accountsArr = Object.values(props.plan?.account_selection)
        for (let i = 0; i < accountsArr.length; i++) {
          if (accountsArr[i].length > 0) {
            hasAccSelection = true
            return hasAccSelection
          }
        }
      } else if (isBlogPost.value) {
        const accountsArr = Object.values(props.plan?.blog_selection)
        for (let i = 0; i < accountsArr.length; i++) {
          if (accountsArr[i].website) {
            hasAccSelection = true
            return hasAccSelection
          }
        }
      }

      return hasAccSelection
    })

    /**
     * Returns true/false based on the approval status if exists in this.item.
     * @returns {[]|*|boolean}
     */
    const showApprovalStatus = computed(() => {
      return props.plan?.approval?.approvers?.length > 0
    })

    /**
     * Returns the account selection object from Plan prop
     * @type {ComputedRef<*>}
     */
    const getSocialAccounts = computed(() => {
      return props.plan?.account_selection
    })

    /**
     * Return the selected blog accounts from the plan object
     * @type {ComputedRef<*>}
     */
    const getBlogAccounts = computed(() => {
      return props.plan?.blog_selection
    })

    /**
     * Returns boolean value true if the plan is of social type
     * @type {ComputedRef<boolean>}
     */
    const isSocialPost = computed(() => {
      return 'account_selection' in props.plan
    })

    /**
     * eturns boolean value false if the plan is of blog type.
     * @type {ComputedRef<boolean>}
     */
    const isBlogPost = computed(() => {
      return 'blog_selection' in props.plan
    })

    /**
     * Permission checker for automation approval buttons.
     * @type {ComputedRef<unknown>}
     */
    const automationApproval = computed(() => {
      return props.plan.status === 'review' && !props.plan.approval
    })

    /**
     * Fetches threaded tweets for twitter previews if any.
     * @type {ComputedRef<[{image: [], image_suggestions: [], website: string, progressBar: {image: number, numberOfImages: number, video: number}, upload_thread_tweets_sharing_video: boolean, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, fetchThreadedTweetsShortLinks: boolean, cancelledLink: string, url: string, uploadThreadedTweetsSharingImage: {position: number, status: boolean}}]|[]|[{image: [], image_suggestions: [], website: string, mentions: [], description: string, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, title: string, url: string}]|*>}
     */
    const fetchTwitterThreads = computed(() => {
      return props.plan.twitter_options?.threaded_tweets
    })

    const findFacebookPostType = computed(() => {
      return props.plan.facebook_options?.post_type
    })

    /**
     * Fetches planner plan status
     * @type {ComputedRef<string|*>}
     */
    const planStatus = computed(() => {
      if (props.externalPostPreview) {
        return props.plan.post_state
      }
      return props.plan.partially_failed
        ? 'Partially Failed'
        : props.plan.render_class?.tooltip
    })

    /**
     * Fetches social channel image/logo
     * @param channelType
     * @returns {unknown}
     */
    const getSocialImage = (channelType) => {
      return channelType
        ? require(`@assets/img/integration/${channelType}-rounded.svg`)
        : ''
    }

    /**
     * Fetches blog account image/logo
     * @param platform
     * @returns {string}
     */
    const getBlogImage = (platform) => {
      switch (platform) {
        case 'wordpress':
          return 'wordpress.svg'
        case 'medium':
          return 'medium.svg'
        case 'webflow':
          return 'webflow.svg'
        case 'tumblr':
          return 'tumblr-rounded.svg'
        case 'shopify':
          return 'shopify.svg'
      }
    }

    /**
     * Find a specific facebook account in facebook accounts list.
     * @param facebookId
     * @returns {*}
     */
    const findFacebookAccount = (facebookId) => {
      return store.getters.getFacebookAccounts.items.find(
        (x) => x.facebook_id === facebookId
      )
    }

    /**
     * Finds specific carousel account if it's a carousel post.
     * @param plan
     * @returns {*|null}
     */
    const findFacebookCarouselAccount = (plan) => {
      return plan.carousel_options?.accounts?.includes(
        plan.feed_preview?.account?.facebook_id
      )
        ? getAccount(selectedChannelType, accountInPreview, store).value
        : null
    }

    /**
     * Fetches full blog account details for previews
     * @param id
     * @returns {*}
     */
    const getFullBlogAccount = (id) => {
      const accountsList = platformAccountsList(store)
      if (!isEmpty(accountsList)) {
        return accountsList.find((item) => item?.platform_identifier === id)
      }
    }

    /**
     * Returns an active social account
     * @param account
     * @returns {boolean}
     */
    const activeSocialAccount = (account) => {
      return accountInPreview.value?._id === account?._id
    }

    /**
     * Setter for social account
     * @param account
     * @param channelKey
     */
    const selectSocialAccount = (account, channelKey) => {
      selectedChannelType.value = channelKey
      accountInPreview.value = account
    }

    /**
     *  When the modal is first shown, checks for available account selection.
     */
    const handleAccountSelection = () => {
      if (isSocialPost.value) {
        const accountSelection = Object.entries(props.plan.account_selection)
        for (let i = 0; i < accountSelection.length; i++) {
          if (accountSelection[i][1].length) {
            selectSocialAccount(
              accountSelection[i][1][0],
              accountSelection[i][0]
            )
            break
          } else {
            selectedChannelType.value = 'facebook'
          }
        }
      }
    }

    /**
     * Event fired when the modal is shown
     */
    const onModalShow = () => {
      console.log('METHOD::onModalShow ~ props.plan -> ', props.plan)
      handleAccountSelection()
      window.addEventListener('keydown', handleKeyDown, null)
    }

    /**
     * Event fired when the modal is hidden
     */
    const onModalHide = () => {
      window.removeEventListener('keydown', handleKeyDown)
      selectedChannelType.value = ''
      accountInPreview.value = null
    }

    /**
     * Handles arrow keys left/right operation to navigated
     * @param e
     */
    const handleKeyDown = (e) => {
      root.$bvModal.hide('post-status-modal')
      if (
        e.target?.id === 'comment-box-top' ||
        e.target?.id === 'comment-box-external' ||
        e.target?.id === 'internal-note-box-top' ||
        e.target?.id === 'full-name-preview' ||
        e.target?.id === 'email-preview'
      )
        return
      switch (e.keyCode) {
        case 37:
          previousPlan()
          break
        case 39:
          nextPlan()
          break
      }
    }

    /**
     * onClick event for fetching the next available plan
     * @returns {Promise<void>}
     */
    const nextPlan = async () => {
      emit('next-plan')
      await nextTick()
      handleAccountSelection()
    }

    /**
     * onClick event for fetching the previous available plan
     * @returns {Promise<void>}
     */
    const previousPlan = async () => {
      emit('previous-plan')
      await nextTick()
      handleAccountSelection()
    }

    /**
     * onClick event for approving a plan with comment.
     */
    const approveWithComment = () => {
      console.log('METHOD::approveWithComment ~ plan -> ', props.plan)
      emit('approve-with-comment', props.plan)
    }

    /**
     * onClick event for rejecting a plan with comment.
     */
    const rejectWithComment = () => {
      console.log('METHOD::rejectWithComment ~ plan -> ', props.plan)
      emit('reject-with-comment', props.plan)
    }

    /**
     * onClick event for editing a plan
     */
    const editPlan = () => {
      emit('edit-plan', props.plan)
      root.$bvModal.hide('planner-post-preview')
    }

    /**
     * onClick event for deleting a plan.
     */
    const deletePlan = () => {
      emit('delete-plan', props.plan?._id)
    }

    /**
     * onClick event for duplicating a plan
     */
    const duplicatePlan = () => {
      emit('duplicate-plan', props.plan)
      root.$bvModal.hide('planner-post-preview')
    }

    /**
     * Method for sanitizing the social account names
     * @param name
     * @returns {*|string}
     */
    const sanitizedSocialTooltip = (name) => {
      return name.length > 15 ? name : ''
    }

    /**
     * Pops up the post status modal which contains all the details of post stats
     */
    const showPostStats = () => {
      EventBus.$emit('post-status-modal', props.plan)
    }

    /**
     * Returns relevant data required to show the gmb post preview
     * @param type
     * @returns {string|*|string}
     */
    const getGMBOptions = (type) => {
      switch (type) {
        case 'cta':
          return props.plan.gmb_options?.action_type
            ? props.plan.gmb_options.action_type
            : ''

        case 'title':
          return props.plan.gmb_options?.title
            ? props.plan.gmb_options.title
            : ''

        case 'start_date':
          return props.plan.gmb_options?.start_date
            ? props.plan.gmb_options.start_date
            : ''

        case 'end_date':
          return props.plan.gmb_options?.end_date
            ? props.plan.gmb_options.end_date
            : ''
      }
      return ''
    }

    const automationApprovalAction = (action) => {
      root.changePlanStatusMethod(action, props.plan, true)
      // nextPlan()
      // root.$bvModal.hide('planner-post-preview')
    }

    /**
     * Chhecks if the plan has external actions
     * @type {ComputedRef<unknown>}
     */
    const showExternalActions = computed(() => {
      return props.plan?.external_actions?.length
    })

    return {
      showExternalActions,
      store,
      selectedChannelType,
      loader,
      processingComment,
      imgs,
      visible,
      comment,
      accountInPreview,
      commented,
      noted,
      internalNote,
      showFacebookPreview,
      showLinkedinPreview,
      showTwitterPreview,
      showPinterestPreview,
      showTumblrPreview,
      showInstagramPreview,
      showYoutubePreview,
      showTiktokPreview,
      showGmbPreview,
      getFeedSharingDetails,
      getFacebookPublishAs,
      canApprove,
      canDelete,
      canDuplicate,
      canEdit,
      canReplace,
      hasPlan,
      planInProgress,
      hasAccountSelection,
      showApprovalStatus,
      getSocialAccounts,
      getBlogAccounts,
      isSocialPost,
      isBlogPost,
      automationApproval,
      fetchTwitterThreads,
      postStatusBadgeClasses,
      planStatus,

      getSocialImage,
      getBlogImage,
      getFullBlogAccount,
      activeSocialAccount,
      selectSocialAccount,
      onModalShow,
      onModalHide,
      nextPlan,
      previousPlan,
      handleAccountSelection,
      findFacebookAccount,
      findFacebookCarouselAccount,
      approveWithComment,
      rejectWithComment,
      editPlan,
      deletePlan,
      getAccount,
      duplicatePlan,
      sanitizedSocialTooltip,
      showPostStats,
      getGMBOptions,
      automationApprovalAction,
      findFacebookPostType,
    }
  },
})
</script>

<style scoped lang="scss">
.accounts-list {
  border-right: 1px solid #eff0f1;
  border-radius: 20px 0 0 20px;
}

.comments-list {
  border-left: 1px solid #eff0f1;
  border-radius: 0 20px 20px 0;
}

.post-preview-content {
  height: 90vh;
  width: 100%;
  max-width: 81rem;
}
</style>
