<template>
  <div class="discovery_article_component">
    <influencers></influencers>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

const Influencers = () =>
  import('@src/modules/discovery/components/influencers/Influencers.vue')

export default {
  components: {
    Influencers
  },

  created () {},
  methods: {
    ...mapMutations(['SET_TOPIC_MODULE'])
  }
}
</script>
