<template>
  <div class="right_filter" v-if="getSearchContentType === 'twitter'">
    <twitter-language></twitter-language>
  </div>
</template>

<script>
import TwitterLanguage from '../../../filters/twitter/FilterTwitterLanguage'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    TwitterLanguage
  },
  computed: {
    ...mapGetters(['getSearchContentType'])
  }
}
</script>
