<template>
  <div>
    <ul class="inner">
      <template v-if="location === 'planner'">
        <template v-if="!createLabelVisibility">
          <template v-if="getLabels.length > 0">
            <li class="list_item_li select_all_li">
              <div class="ui_check_container">
                <input
                  id="plans_labels"
                  v-model="getPlans.check_all_labels"
                  type="checkbox"
                  @change="selectAllLabels()"
                />
                <span class="check"></span>
                <label for="plans_labels" class="checkbox_right">
                  <span class="">All</span>
                </label>
              </div>
            </li>
            <li
              class="ui_check_container"
              v-for="(label, index) in orderedLabels"
              :key="index"
            >
              <input
                type="checkbox"
                :checked="isLabelSelected(label._id)"
                :id="'planner' + label._id"
                :value="label._id"
                @change="changeLabelSelection(label._id)"
              />
              <span class="check"></span>
              <label
                :for="'planner' + label._id"
                class="checkbox_right showlink"
              >
                <span
                  class="label_tag showlink"
                  :class="fetchColor(label.color) + ' label'"
                  >{{ label.name }}
                </span>
                <a
                  @click.prevent="labelOnlySelection(label._id)"
                  class="onlyLink"
                  >Only</a
                >
              </label>
            </li>
          </template>
          <p class="no_label" v-else-if="type === 'Filter'">
            You have not created any label yet.
          </p>
        </template>
      </template>
      <template v-else>
        <!--for label listing.......-->
        <!--d-flex-->
        <div v-show="!createLabelVisibility">
          <div v-if="type !== 'Filter'" class="label_head align-items-center">
            <p class="text_right"> Assign Label </p>
            <p class="add_icon ml-auto">
              <span
                class="text"
                @click.prevent="
                  hideDiv(true)
                  SET_NEW_LABEL()
                "
                data-cy="new-label"
                ><i class="fas fa-bookmark"></i> Add New</span
              >
            </p>
          </div>
          <template v-show="!createLabelVisibility">
            <div class="label_list" v-if="getLabels.length > 0">
              <template v-if="getLabels.length > 7">
                <div class="search_input with_icon_left mb-3">
                  <div class="search_inner w-100">
                    <input
                      type="text"
                      v-model="label_search"
                      placeholder="Search for label e.g marketing"
                    />
                    <button class="search_btn input_icon search_icon">
                      <i class="icon-Search"></i>
                    </button>
                  </div>
                </div>
              </template>
              <template v-for="(label, index) in orderedWithSearchedLabels">
                <div class="list_item" :key="index">
                  <input
                    type="checkbox"
                    v-model="getPublishSelection.label"
                    :id="label._id"
                    :value="label._id"
                  />
                  <label
                    :for="label._id"
                    :class="fetchColor(label.color) + ' label'"
                    data-cy="label-name-click"
                  >
                    <span data-cy="label-name">{{
                      truncateString(label.name, 30)
                    }}</span>
                    <i class="check_icon_planner cs-checked"></i>
                  </label>
                  <div class="label_icon" v-if="type !== 'Filter'">
                    <i
                      v-tooltip.top-center="'Edit'"
                      @click.prevent="
                        editLabel(label)
                        hideDiv(true)
                      "
                      class="edit_icon icon-edit-cs"
                    >
                    </i>

                    <i
                      v-tooltip.top-center="'Remove'"
                      data-cy="remove-label"
                      @click.prevent="deleteLabel(label)"
                      class="remove_icon icon-delete-cs"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </div>

            <p class="no_label" v-else-if="type === 'Filter'">
              You have not created any label yet.
            </p>
          </template>
        </div>

        <div v-show="createLabelVisibility">
          <!--for Create label.......-->

          <div
            v-if="type !== 'Filter'"
            class="field_group label_head labelclass"
          >
            <p v-if="getNewLabel._id" class="text_right">Edit Label</p>
            <p v-else class="text_right">Create Label</p>
          </div>

          <template v-if="type !== 'Filter'">
            <div v-show="createLabelVisibility" class="create_label mt-2">
              <div class="field_group m-3">
                <input
                  type="text"
                  v-model="getNewLabel.name"
                  required
                  placeholder="Enter label name..."
                  data-cy="newlabel-name"
                  class="label_input"
                />
              </div>
              <div class="field_group">
                <label class="p15" for="">Choose Color</label>
                <div class="label_boxes">
                  <template v-for="(value, key) in fetchColor(null, null)">
                    <div class="box_item">
                      <input
                        :id="key"
                        :value="key"
                        v-model="getNewLabel.color"
                        type="radio"
                        class="icon"
                        name="color_select"
                      />
                      <label
                        :for="key"
                        :class="value + ' item_inner'"
                        data-cy="newlabel-color"
                      >
                        <i
                          class="check_icon_planner cs-checked d-flex height align-items-center justify-content-center"
                        ></i>
                      </label>
                    </div>
                  </template>
                </div>
              </div>

              <!-- save label or cancel -->
              <div class="label_btn">
                <button
                  :disabled="getSaveLabelLoader"
                  @click.prevent="saveLabel()"
                  class="btn btn-studio-theme-space btn-size-small"
                  data-cy="newlabel-create"
                >
                  <span v-if="getNewLabel._id">Save</span>
                  <span v-else>Create</span>
                  <clip-loader
                    :color="'#ffffff'"
                    :size="'16px'"
                    v-if="getSaveLabelLoader"
                  ></clip-loader>
                </button>
                &nbsp;
                <button
                  @click="
                    hideDiv(false)
                    SET_NEW_LABEL()
                  "
                  id="labelcancel"
                  class="btn btn-studio-theme-grey-space btn-size-small"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
            <p
              class="no_label"
              v-show="!createLabelVisibility && getLabels.length <= 0"
            >
              Create a label and it will be displayed here
            </p>
          </template>
        </div>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { composer } from '../../../composer/store/composer-mutation-type'
import orderBy from 'lodash.orderby'
import { pusherSocketPublish } from '@common/lib/pusher'
import { planner } from '@src/modules/planner/store/mutation-types'

export default {
  name: 'LabelOptions',
  props: ['type', 'location'],
  components: {},

  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `labels_${this.getActiveWorkspace._id}`
    )
    this.bindPusherPostLabels()
  },
  data () {
    return {
      createLabelVisibility: false,
      label_search: '',
      channel: ''
    }
  },
  beforeDestroy () {
    if (this.channel) this.channel.unsubscribe()
    this.channel.unbind('store_label')
  },

  computed: {
    ...mapGetters([
      'getLabels',
      'getNewLabel',
      'getSaveLabelLoader',
      'getPlans',
      'getBlogPostingDetails',
      'getActivePlannerFilters'
    ]),
    orderedLabels () {
      return this.getLabels.length > 0
        ? orderBy(this.getLabels, function (item) {
          return [item.name.toLowerCase()]
        })
        : []
    },
    orderedWithSearchedLabels () {
      if (this.label_search && this.label_search.trim().length > 1) {
        const labelsList = this.getLabels.filter((item) =>
          item.name.toLowerCase().includes(this.label_search)
        )
        return labelsList.length > 0
          ? orderBy(labelsList, function (item) {
            return [item.name.toLowerCase()]
          })
          : []
      }
      return this.getLabels.length > 0
        ? orderBy(this.getLabels, function (item) {
          return [item.name.toLowerCase()]
        })
        : []
    }
  },
  methods: {
    ...mapActions([
      'fetchLabels',
      'saveLabel',
      'deleteLabel',
      'setPublishLabels'
    ]),
    ...mapMutations(['SET_NEW_LABEL']),

    bindPusherPostLabels () {
      this.channel.bind('store_label', (data) => {
        console.log('Post label store payload => ', data)
        if (
          data &&
          data.plan_id &&
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          if (data.labels) {
            this.setPublishLabels(JSON.parse(JSON.stringify(data.labels)))
          }
        }
      })
    },

    hideDiv (boolVal) {
      this.createLabelVisibility = boolVal
    },
    editLabel (label) {
      this.SET_NEW_LABEL(JSON.parse(JSON.stringify(label)))
    },
    isLabelSelected (labelId) {
      return (
        this.getPlans.check_all_labels ||
        this.$parent.getFiltersLabels.indexOf(labelId) >= 0
      )
      // return (!this.$route.query.labels && this.getPlans.check_all_labels) || (this.$route.query.labels && this.$route.query.labels.indexOf(labelId) >= 0) || this.getPlans.check_all_labels
    },

    selectAllLabels () {
      console.debug('Method:selectAllLabels')
      this.getActivePlannerFilters.labels = []
      let labels = []
      if (this.getPlans.check_all_labels) labels = this.getLabelsIds()
      if (labels.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, labels: labels.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.labels
      this.$router.push({ query: query })
      this.$store.dispatch('refetchPlans', this.$route)
    },
    changeLabelSelection (labelId) {
      let labels = []
      if (this.$route.query.filter_id && !this.$route.query.labels) {
        labels = this.$parent.getFiltersLabels.length
          ? this.$parent.getFiltersLabels
          : this.getPlans.check_all_labels
            ? this.getLabelsIds()
            : []
        this.getActivePlannerFilters.labels = []
      } else {
        labels = this.$route.query.labels
          ? this.$route.query.labels.split(',')
          : this.getPlans.check_all_labels
            ? this.getLabelsIds()
            : []
      }
      labels.indexOf(labelId) >= 0
        ? labels.splice(labels.indexOf(labelId), 1)
        : labels.push(labelId)
      this.$store.commit(
        planner.SET_CHECK_ALL_LABELS,
        this.getLabels.length === labels.length
      )

      if (labels.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, labels: labels.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.labels
      this.$router.push({ query: query })
    },
    labelOnlySelection (labelId) {
      this.getActivePlannerFilters.labels = []
      if (this.getLabels.length > 1) {
        this.$store.commit(planner.SET_CHECK_ALL_LABELS, false)
      }
      this.$router.push({ query: { ...this.$route.query, labels: labelId } })
    }
  },

  watch: {
    'getPublishSelection.label' () {
      if (this.$route.name === 'composer') {
        this.$store.commit(
          composer.SET_PUBLICATIONS_LABELS,
          JSON.parse(JSON.stringify(this.getPublishSelection.label))
        )
        this.refetchPublications()
      }
    }
  }
}
</script>
<style>
.onlyLink {
  display: none;
  text-decoration: underline;
  font-size: 11px !important;
}
.onlyLink:hover {
  font-weight: bold;
}
.showlink:hover .onlyLink {
  display: initial;
}
.labelclass {
  position: absolute;
  left: 39px;
  top: 16px;
  font-weight: 300;
  padding: 0 !important;
  p {
    font-weight: 500;
    font-size: 0.9rem;
    opacity: 0.8;
    color: #3a4557;
  }
}
.label_input{
  padding:15px!important;
}
</style>
