import { render, staticRenderFns } from "./InstagramPreview.vue?vue&type=template&id=81ea9562&scoped=true&"
import script from "./InstagramPreview.vue?vue&type=script&lang=js&"
export * from "./InstagramPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81ea9562",
  null
  
)

export default component.exports