<script>
export default {
  props: ['type', 'details'],
  computed: {
    showLinkPreview () {
      if (this.details.image && this.details.image.length) return true
      if (this.details.title) return true
      if (this.details.website) return true
      return !!this.details.description
    }
  }
}
</script>
<template>
  <div
    class="link_preview d-flex align-items-start"
    data-cy="link-preview"
    v-if="showLinkPreview"
  >
    <i class="cross_icon cs-cross" @click.prevent="cancelSocialPreview(type)">
    </i>
    <div class="img_section">
      <img
        draggable="false"
        class="img-cover img 1"
        v-if="
          details.image &&
          details.image.constructor === Array &&
          details.image.length
        "
        :src="getResizedImageURL(details.image[0], 250, 0)"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
        "
      />
      <img
        draggable="false"
        class="img-cover img 2"
        v-else-if="
          details.image && details.image.constructor !== Array && details.image
        "
        :src="getResizedImageURL(details.image, 250, 0)"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
        "
      />
      <!--            <div class="img 1" v-if="details.image && details.image.constructor === Array && details.image.length" :style="{ 'background-image': 'url(' + details.image[0] + ')' }"></div>-->
      <!--            <div class="img 2" v-else-if="details.image && details.image.constructor !== Array && details.image" :style="{ 'background-image': 'url(' + details.image + ')' }"></div>-->
      <div
        v-else
        class="img"
        style="
          background: url('https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png');
        "
      ></div>
    </div>
    <div class="link_preview_content">
      <h3>{{ details.title }}</h3>
      <p class="link">{{ details.website }}</p>
      <p>{{ details.description }}</p>
    </div>
  </div>
</template>
