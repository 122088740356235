<script>
import { mapGetters } from 'vuex'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import GmbOptions from '@src/modules/publish/components/posting/social/GmbOptions'
import { social } from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type'
import SingleBox from '../../../../publish/components/posting/social/boxes/SingleBox'
export default {
  components: {
    ErrorSection,
    SingleBox,
    GmbOptions,
  },
  data() {
    return {
      imageUploading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getEvergreenSelection',
      'getEvergreenVariationData',
      'getAccountSelection',
    ]),
  },
  mounted() {
    EventBus.$on('image-uploading', (value) => {
      this.imageUploading = value
    })
  },
  methods: {
    /*
     * method will add new post and add/update variation
     */
    addEvergreenPost() {
      // check social account validation before adding variation
      if (!this.socialShareDetailsValidation('Evergreen')) return false
      if (this.getAccountSelection.gmb.length) {
        const validationStatus = this.checkEvergreenGmbValidation()
        if (!validationStatus) return false
      }

      const post = this.getCommonSharingDetails
      if (this.getAccountSelection.gmb.length) {
        post.gmb_options = JSON.parse(JSON.stringify(this.getGmbOptions))
      }

      console.debug('addEvergreenPost', this.getCommonSharingDetails)
      const posts = this.getEvergreenSelection.posts
      if (this.getEvergreenVariationData.addState === 'variation') {
        // section called in case of add/update variation
        // check variation is new or edit case
        if (this.getEvergreenSelection.active_post) {
          // in case of edit update specific variation through index
          posts[this.getEvergreenVariationData.postIndex][
            this.getEvergreenVariationData.variationIndex
          ] = post
        } else {
          // in case of new variation generate unique id and append in posts
          post.id = this.generateRandomId()
          posts[this.getEvergreenVariationData.postIndex].push(post)
        }
      } else {
        // section called in case of add new post
        post.id = this.generateRandomId()
        posts.push([post])
      }

      // reset evergreen stats
      this.$store.commit(
        evergreen.SET_EVERGREEN_POSTS,
        JSON.parse(JSON.stringify(posts))
      )
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'post')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, 0)
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      EventBus.$emit('AiCaptionModal_reset')
      this.clearModalState()
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('hide')
    },
    /**
     * this method will clear image generator modal state
     */
    clearModalState() {
      EventBus.$emit('clear-modal-state')
    },
  },
}
</script>

<template>
  <div id="addEvergreenPost" class="modal fade addEvergreenPost normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-start">
          <div class="left_block">
            <h2 v-if="getEvergreenVariationData.addState === 'variation'">
              <template v-if="getEvergreenSelection.active_post"
                >Edit Evergreen Variation</template
              >
              <template v-else>Add Evergreen Variation</template>
            </h2>
            <h2 v-else>Add Evergreen Post</h2>
          </div>
          <button
            type="button"
            class="modal_head__close"
            data-dismiss="modal"
            @click="clearModalState"
            >&times;</button
          >
        </div>
        <div class="modal_body modal-body basic_form m_t_15">
          <SingleBox
            type="Common"
            evergreen-box="true"
            :image-uploading="imageUploading"
          ></SingleBox>
          <ErrorSection
            :errors="socialPostContentErrors('list', 'evergreen')"
            :warnings="socialPostContentWarnings('list', 'evergreen')"
          ></ErrorSection>
          <GmbOptions v-if="getAccountSelection.gmb.length"></GmbOptions>
          <div class="post_footer text-right">
            <button
              :disabled="
                sharingPendingProcessStatus ||
                socialPostContentErrors('status') ||
                imageUploading
              "
              class="btn btn-studio-theme-space"
              data-cy="add-post"
              @click.prevent="addEvergreenPost"
            >
              <span
                v-if="
                  getEvergreenSelection.active_post &&
                  getEvergreenSelection.active_post.length
                "
              >
                Update</span
              >
              <span v-else>Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
