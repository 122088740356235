<script>
import { mapGetters } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },

  created () {
    console.debug('before create:affiliate')
    if (
      !this.getProfile.first_promoter ||
      !this.getProfile.first_promoter.auth_token
    ) {
      this.$store.dispatch('createPromoter')
    }
  },
  mounted () {},
  beforeCreate () {},

  computed: {
    ...mapGetters(['getProfile'])
  },

  methods: {}
}
</script>
<template>
  <div class="setting_profile_component">
    <div class="component_inner">
      <div class="ps_box">
        <h2 class="box_heading">Affiliate Program</h2>

        <div class="box_content">
          <div
            class="d-flex align-items-start"
            v-if="
              getProfile.first_promoter && getProfile.first_promoter.auth_token
            "
          >
            <iframe
              height="850px"
              width="100%"
              frameborder="0"
              :src="
                'https://contentstudio.firstpromoter.com//iframe?at=' +
                getProfile.first_promoter.auth_token
              "
            ></iframe>
          </div>
          <template v-else>
            <beat-loader class="mt-5" :color="'#436aff'"></beat-loader>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
