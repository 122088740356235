import {UNKNOWN_ERROR} from "@common/constants/messages";
import {EventBus} from "@common/lib/event-bus";

export const feederItemMixin = {
  methods: {
    signalsCount(post) {
      const facebookShares = post.facebook_shares ? post.facebook_shares : 0
      const twitterShares = post.twitter_shares ? post.twitter_shares : 0
      const pinsShares = post.pins_shares ? post.pins_shares : 0
      const redditShares = post.reddit_shares ? post.reddit_shares : 0
      return facebookShares + twitterShares + pinsShares + redditShares
    },
    archiveAction (postId, index, state, posts) {
      console.log(postId, index, state, posts)
      const p = this.$store.dispatch('feederArchivePost', {
        postId: postId,
        state: state
      })
      return p
          .then((res) => {
            if (res.data.status) {
              console.log('splice the item based on index')
              posts.splice(
                  posts.findIndex((item) => item.id === postId),
                  1
              )
            }
            return true
          })
          .catch((err) => {
            return false
          })
    },
    starredAction(postId, index, state, posts) {
      console.log(postId, index, state, posts)
      const p = this.$store.dispatch('feederStarredPost', {
        postId: postId,
        state: state
      })
      return p
          .then((res) => {
            if (res.data.status) {
              console.log('splice the item based on index')
              const post = posts.find((item) => item.id === postId)
              post.is_starred = state === 'starred'
            }
            return true
          })
          .catch((err) => {
            return false
          })
    },

    async shareToSocial(postId) {

      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')

      const payload = {
        post_id: postId,
        type_of: 'social'
      }

      const res = await this.$store.dispatch('shareFeederPost', payload)
      if (res.data && res.data.data && res.data.data.post) {
        const options = {
          _id: res.data.data.post.id,
          post_id: res.data.data.post.id,
          url: res.data.data.post.url,
          title: res.data.data.post.title,
          image: res.data.data.post.image
        }

        EventBus.$emit('publication-composer-post', {
          mode: 'discovery-post',
          options,
        })

      } else {
        console.debug("share feed post error")
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.$bvModal.hide('composer-modal')
        EventBus.$emit('publication-composer-post-loader', false)
      }
    },

  }
}
