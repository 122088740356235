var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"filter_section_heading article-search-bar-pane__search__heading"},[(_vm.$route.meta.is_active && _vm.$route.meta.is_active === 'content')?[_vm._v(" Find the top performing content ")]:(
        _vm.$route.meta.is_active && _vm.$route.meta.is_active === 'insights'
      )?[_vm._v(" Find key insights from top performing content ")]:(_vm.$route.name === 'discover_search_youtube')?[_vm._v(" Find the top performing videos ")]:(_vm.$route.name === 'discover_search_twitter')?[_vm._v(" Find the top performing tweets ")]:_vm._e()],2),_c('div',{staticClass:"search-bar-input search-bar-input-discovery with-icon-left article-search-bar-pane__search__input"},[_c('div',{staticClass:"search-bar-inner"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_query),expression:"search_query"}],staticClass:"discoverySearchInput",staticStyle:{"padding-top":"12px","padding-bottom":"12px"},attrs:{"type":"text","placeholder":"Search for any topic or domain, e.g ‘marketing’ or ‘cnn.com’","data-cy":"discovery_search_bar"},domProps:{"value":(_vm.search_query)},on:{"input":[function($event){if($event.target.composing)return;_vm.search_query=$event.target.value},function($event){return _vm.inputArticleSearchKeyword($event)}],"focus":function($event){return _vm.searchHistory()},"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==38)return null;return _vm.inputSelectItemFromList('up')},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40)return null;return _vm.inputSelectItemFromList('down')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.articleSearchKeyword(_vm.search_query)}]}}),_c('button',{staticClass:"search_btn input_icon search_icon gradient_color",attrs:{"data-cy":"discovery_search_button"},on:{"click":function($event){$event.preventDefault();return _vm.articleSearchKeyword(_vm.search_query)}}},[_c('i',{staticClass:"icon-Search"})]),_c('transition',{attrs:{"name":"slide-bottom"}},[(
            _vm.suggestions.show && _vm.search_query && _vm.search_query.trim().length > 3
          )?_c('article-search-suggestions',{attrs:{"topics":_vm.suggestions.topics,"keywords":_vm.suggestions.keywords,"search_key_input":_vm.suggestions.active_key_type,"query":_vm.search_query}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-bottom"}},[(
            _vm.suggestions.search_history &&
            (!_vm.search_query ||
              (_vm.search_query && _vm.search_query.trim().length === 0))
          )?_c('article-search-history',{attrs:{"keywords":_vm.suggestions.search_history_keywords,"search_key_input":_vm.suggestions.active_key_type}}):_vm._e()],1)],1),(_vm.getShowHintsRoute)?[_c('article-search-assistant-table')]:_vm._e(),(
        _vm.$route.meta.is_active && _vm.$route.meta.is_active === 'twitter_tweets'
      )?[_c('twitter-search-assistant-table')]:_vm._e()],2),(_vm.getShowHintsRoute)?_c('article-search-suggestions-hints'):_vm._e(),(_vm.$route.meta.is_active && _vm.$route.meta.is_active === 'twitter_tweets')?_c('twitter-search-suggestions-hints'):_vm._e(),(_vm.$route.meta.is_active && _vm.$route.meta.is_active === 'youtube_videos')?_c('youtube-search-suggestions-hints'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }