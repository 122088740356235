<template>
  <b-modal
    id="manage-competitors-modal"
    centered
    header-class="cs-modal-header"
    title-class="cs-modal-title"
    no-close-on-backdrop
    dialog-class="!w-[28rem]"
    @show="onShowManageModal"
    @hide="onHideManageModal"
  >
    <!--    header-->
    <template v-slot:modal-title>Add Competitors</template>

    <!--    body-->
    <div class="flex flex-col p-2 !h-[31rem]">
      <div class="mb-1.5 analytic-competitors-addform__search-wrapper">
        <b-form-input
          v-model="state.title"
          class="!h-11 !pl-3"
          type="text"
          debounce="1000"
          placeholder="Enter title here..."
        ></b-form-input>
      </div>
      <div
        v-tooltip.top="{
          content:
            state.selectedCompetitors.length === 5
              ? `You've already added five competitors. Please remove one of them to add another.`
              : '',
          classes: 'w-[14rem]',
        }"
        class="analytic-competitors-addform__search-wrapper"
        :class="
          state.selectedCompetitors.length === 5 ? '!cursor-not-allowed' : ''
        "
      >
        <b-input-group class="mt-2">
          <template v-slot:prepend>
            <i class="far fa-search"></i>
          </template>
          <b-form-input
            v-model="state.searchedText"
            class="!h-11"
            :class="
              state.selectedCompetitors.length === 5
                ? '!cursor-not-allowed'
                : ''
            "
            type="text"
            debounce="500"
            placeholder="Enter competitor’s slug/url here..."
            :disabled="state.selectedCompetitors.length === 5"
            @update="searchQuery"
          ></b-form-input>
          <template v-slot:append>
            <clip-loader
              v-if="state.loadingData && state.searchedText"
              class="append mr-2"
              :color="'#5773fa'"
              :size="'22px'"
            ></clip-loader>
          </template>
        </b-input-group>
        <div
          v-if="state.showDropDown && state.searchedText"
          v-on-clickaway="onClickAway"
          class="relative z-20"
        >
          <transition name="slide-bottom">
            <div
              class="w-full p-2 border border-[#E9EFF6] shadow-lg !bg-white rounded-lg absolute top-2"
            >
              <div
                v-if="showData('success')"
                class="py-1 flex items-center rounded-lg px-4 hover:bg-gray-50 cursor-pointer"
                @click="addCompetitors(state.successResult)"
              >
                <img
                  :src="state.successResult.image"
                  :alt="state.successResult.name"
                  class="w-10 h-10 object-cover border rounded-full border-[#E9EFF6]"
                  @error="
                    $event.target.src =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <div class="ml-3 font-medium">
                  {{
                    state.successResult.name
                      ? state.successResult.name
                      : state.successResult.slug
                  }}
                </div>
              </div>
              <div
                v-if="showData('error')"
                class="p-3 !bg-white !cursor-default"
              >
                <p class="capitalize">{{ state.errorResult.error }}</p>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="state.selectedCompetitors.length"
        class="w-full flex flex-col items-center justify-center mt-4"
      >
        <div
          v-for="(competitor, index) in state.selectedCompetitors"
          :key="`competitors_list_${index}`"
          class="flex items-center justify-between w-full px-4 m-4"
        >
          <div
            v-tooltip.top="{
              content: `${competitor.slug ? competitor.slug : competitor.name}`,
            }"
            class="flex justify-start items-center select-none"
          >
            <img
              :src="competitor.image"
              class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover border border-[#E9EFF6]"
              alt=""
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              "
            />
            <div class="w-64">
              <p class="font-bold truncate">{{
                competitor.name ? competitor.name : competitor.slug
              }}</p>
            </div>
          </div>
          <span
            v-tooltip="'Remove Competitor'"
            class="w-7 h-7 rounded-full flex justify-center items-center bg-cs-white drop-shadow-lg z-10 cursor-pointer"
            @click="removeCompetitor(competitor.competitor_id)"
          >
            <i class="fa fa-times text-red-500" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div v-else class="w-full h-full flex justify-center items-center">
        <p>No competitors selected.</p>
      </div>
    </div>

    <!--    Footer    -->
    <template v-slot:modal-footer>
      <div class="flex w-full justify-between items-center gap-2">
        <div
          class="bg-[#faf2dc] flex justify-center items-center rounded-lg gap-2 px-2 py-1"
        >
          <i class="far fa-exclamation-circle text-[#2f8ae0]"></i>
          <p class="text-xs text-[#b57e00]"
            >Upto 5 competitors can be added.</p
          ></div
        >
        <div class="flex justify-center items-center">
          <CstButton
            variant="secondary"
            class="mr-3 w-28"
            text="Cancel"
            @click="$bvModal.hide('manage-competitors-modal')"
          />
          <CstButton
            class="w-28"
            text="Save"
            :disabled="!state.selectedCompetitors.length || state.loadingData"
            @click="saveCompetitors"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
// libraries
import { reactive, defineComponent, computed, watch, inject } from 'vue'

// components
import CstButton from '@ui/Button/CstButton.vue'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'ManageCompetitorsModal',
  components: { CstButton },
  props: {
    successData: {
      type: Array | Object,
      default: () => {},
    },
    errorData: {
      type: Array | Object,
      default: () => {},
    },
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch } = useStore()

    const state = reactive({
      searchedText: '',
      loadingData: false,
      successResult: computed(() => props.successData),
      errorResult: computed(() => props.errorData),
      showDropDown: false,
      selectedCompetitors: [],
      title: 'Untitled',
    })

    watch(
      () => [props.successData, props.errorData],
      (current, old) => {
        if (current !== old) {
          state.loadingData = false
          state.showDropDown = !!(
            props.successData?.competitor_id || props.errorData?.error
          )
        }
      }
    )

    /**
     * @description on modal show, sets the selectedCompetitors property on basis of props.reportData
     */
    const onShowManageModal = () => {
      state.selectedCompetitors = props.reportData?.competitors
        ? [...props.reportData.competitors]
        : []
      state.title = props.reportData?.name ? props.reportData.name : 'Untitled'
    }

    /**
     * @description method to reset the local properties when the modal is hides.
     */
    const onHideManageModal = () => {
      state.searchedText = ''
      state.showDropDown = false
      state.selectedCompetitors = []
    }

    /**
     * @description method to check if the data received from props is for error case or success.
     */
    const showData = (type = '') => {
      if (type === 'success') {
        return !!state.successResult?.competitor_id
      } else if (type === 'error') {
        return !!state.errorResult?.error
      }
      return false
    }

    /**
     * @description method to add a competitor in the selected competitors list. Max 5 competitors can be added.
     * @param competitor
     */
    const addCompetitors = (competitor = '') => {
      if (
        !state.selectedCompetitors.find(
          (item) => item.competitor_id === competitor.competitor_id
        )
      ) {
        state.selectedCompetitors.push(competitor)
      } else {
        dispatch('toastNotification', {
          message: 'Competitor already added.',
          type: 'info',
        })
      }
      state.showDropDown = false
      state.searchedText = ''
    }

    /**
     * @description method to find and remove the specific competitor from the selected competitors list.
     * @param competitorsId
     */
    const removeCompetitor = (competitorsId = '') => {
      state.selectedCompetitors.splice(
        state.selectedCompetitors.findIndex(
          (item) => item.competitor_id === competitorsId
        ),
        1
      )
    }

    /**
     * @description on call, emits the event to save the selected competitors to the report with the title.
     */
    const saveCompetitors = () => {
      const competitorsData = { competitors: [] }

      state.selectedCompetitors.forEach((competitor) => {
        competitorsData.competitors.push({
          platform_type: 'instagram',
          type: 'Page',
          competitor_id: competitor.competitor_id,
          name: competitor.name ? competitor.name : '',
          slug: competitor.slug,
          image: competitor.image,
          state: competitor.state ? competitor.state : 'Added',
          is_active: true,
        })
      })
      if (props.reportData?._id) competitorsData._id = props.reportData._id
      emit('save-competitors', competitorsData, state.title)
      $bvModal.hide('manage-competitors-modal')
    }

    const searchQuery = () => {
      if (state.searchedText) {
        state.loadingData = true
        emit('searched-query', state.searchedText)
      }
    }

    const onClickAway = () => {
      state.showDropDown = false
    }

    return {
      state,

      searchQuery,
      onShowManageModal,
      onHideManageModal,
      showData,
      addCompetitors,
      removeCompetitor,
      saveCompetitors,
      onClickAway,
    }
  },
})
</script>

<style scoped></style>
