<template>
  <div v-on-clickaway="handleClickAway" class="inline-block text-left " :class="{ 'relative z-100': isOpen }">
    <div>
      <span  :class="classObject" class="flex overflow-hidden text-ellipsis whitespace-pre justify-center items-center" aria-expanded="true" aria-haspopup="true" @click="handleOpen">
        <slot name="selected">
          <i class="fas fa-gear"></i>
        </slot>

      </span>
    </div>

    <div v-show="isOpen"
         :class="menuClass"
         class="w-max max-w-96 absolute  rounded-xl shadow-lg bg-white   focus:outline-none z-50">
      <slot name="options"></slot>
      <div  ref="dropdownList" class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
            :data-active="selected"
            tabindex="-1"
            @keydown="keyHandler">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'IconDropdown',
  provide() {
    return {
      ctx: this
    }
  },
  props: {
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      }
    },
    alignMenu: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['left', 'default', 'right'].indexOf(value) !== -1
      }
    },
    open: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      isOpen: this.open,
      selected: 0,
    }
  },
  computed: {
    classObject: function () {
      return {
        'px-2 py-2 text-sm':  this.size === 'small',
        'px-2.5 py-2.5 text-sm':  this.size === 'default',
        'px-3 py-3 text-base':  this.size === 'large',
        [this.buttonClasses]: true
      }
    },
    menuClass: function () {
      return {
        'right-0':  this.alignMenu === 'left',
        'left-1/2 -translate-x-2/4':  this.alignMenu === 'default',
        'left-0':  this.alignMenu === 'right',
      }
    }
  },
  methods:{
    handleClickAway () {
      this.isOpen = false
    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
        setTimeout(()=> {
          this.$refs.dropdownList.focus()
        },100)
      }
    },
    keyHandler(e){
      console.log(e)
      if(e.keyCode === 38 && this.selected > 0){
        e.preventDefault();
        this.selected--;
      }
      else if(e.keyCode === 40 && this.selected < this.$refs.dropdownList.children.length - 1){
        e.preventDefault();
        this.selected++;
      }
    }
  }
}
</script>
