<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getConfirmationPlan'])
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('reopenPlan')
    },

    async processReopenPlan () {
      console.debug('Method::processReopenPlan')
      const plan = this.getConfirmationPlan
      let type = 'social'
      if (plan.blog_reference) type = 'blog'
      if (plan.status === 'draft' && plan.blog_reference) type = 'blog_draft'
      else if (plan.status === 'draft') type = 'social_draft'
      const plan_id = plan.parent ? plan.parent_reference : plan._id
      this.editPublication(plan_id, false, true, false, type)
      this.$bvModal.hide('reopenPlan')
    }
  }
}
</script>

<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox"
    id="reopenPlan"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Reopen Post</h2>
          <p class="text-center"
            >This post is either rejected, failed or missed review. Would you
            like to re-open it?</p
          >
        </div>
        <div class="col-sm-12 footer_box">
          <button class="btn gray cancel_btn" @click="hideModal()">No</button>
          <button
            class="btn red confirm_btn btn_loader loader_right"
            @click.prevent="processReopenPlan()"
          >
            <span>Yes</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
