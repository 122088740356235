<template>
  <div class="facebook_sources">
    <div class="sources_head">
      <div class="sources_row d-flex align-items-center">
        <div class="r_left">
          <button class="btn gradient_btn">
            <span>Add a new Source</span>
          </button>
        </div>
        <div class="r_right ml-auto">
          <div class="search_input with_icon_right d-flex align-items-center">
            <div style="min-width: 345px" class="search_inner">
              <input
                type="text"
                placeholder="Search in global database of contentstudio..."
              />
              <button class="search_btn input_icon search_icon gradient_color">
                <i class="icon-Search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plan_box">
      <div class="box_heading d-flex align-items-center">
        <h2>Sources you are following in custom topics</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 600px">Name</th>
                  <th class="text-center">In Topics</th>
                  <th style="text-align: right; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="profile_picture">
                      <!--..///for if text with image -->
                      <div class="picture_block">
                        <img
                          src="../../../../assets/img/icons/replug_icon.svg"
                          alt=""
                        />
                      </div>

                      <div class="text_block">
                        <p class="text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                        <p class="sub_text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="d-flex align-items-start justify-content-center"
                    >
                      <div class="tag_list">
                        <span class="tag">asd asda</span>
                        <span class="tag">asd asda</span>
                        <span class="tag">asd asda</span>
                        <span class="tag">asd asda</span>
                      </div>
                      <div
                        class="prevent_close_dropdown d-inline-block dropdown default_style_dropdown checkbox_dropdown planner_filter_dd"
                      >
                        <div
                          class="dropdown_header d-flex"
                          data-toggle="dropdown"
                        >
                          <i class="cs-plus plus_icon"></i>
                        </div>

                        <div
                          class="dropdown-menu dropdown-menu-right"
                          style="min-width: 190px;left: 7px; !important"
                        >
                          <ul class="inner">
                            <li class="list_item_li">
                              <div class="checkbox_input_image">
                                <input
                                  id="descending_order"
                                  value="descending"
                                  type="checkbox"
                                />
                                <label
                                  for="descending_order"
                                  class="checkbox_right"
                                >
                                  Descending
                                </label>
                              </div>
                            </li>

                            <li class="list_item_li">
                              <div class="checkbox_input_image">
                                <input
                                  id="ascending_order"
                                  value="ascending"
                                  type="checkbox"
                                />
                                <label
                                  for="ascending_order"
                                  class="checkbox_right"
                                >
                                  Ascending
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <a href="" class="enable">Enable</a>
                    <!--<a href="" class="disable">Disable</a>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="plan_box">
      <div class="box_heading d-flex align-items-center">
        <h2>Curated topics you are following</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th style="text-align: right; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="profile_picture">
                      <!--..///for if text with image -->
                      <div class="picture_block">
                        <img
                          src="../../../../assets/img/icons/replug_icon.svg"
                          alt=""
                        />
                      </div>

                      <div class="text_block">
                        <p class="text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                        <p class="sub_text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                      </div>
                    </div>
                  </td>

                  <td class="text-right">
                    <a href="" class="enable">Enable</a>
                    <!--<a href="" class="disable">Disable</a>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="plan_box">
      <div class="box_heading d-flex align-items-center">
        <h2>Blocked sources</h2>
      </div>

      <div class="box_content">
        <div class="social_table sources_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 600px">Name</th>
                  <th class="text-center">Block Type</th>
                  <th style="text-align: right; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="profile_picture">
                      <!--..///for if text with image -->
                      <div class="picture_block">
                        <img
                          src="../../../../assets/img/icons/replug_icon.svg"
                          alt=""
                        />
                      </div>

                      <div class="text_block">
                        <p class="text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                        <p class="sub_text"
                          >Cortana Intelligence and Machine Learning Blog</p
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-center"> Topic - Level </td>
                  <td class="text-right">
                    <a href="" class="enable">Enable</a>
                    <!--<a href="" class="disable">Disable</a>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },

  created () {
    // this.initializeGroupSection()
    // this.fetchGroup()
  },
  mounted () {}
}
</script>
