<template>
  <div id="pinterest_tab">
    <div class="pinterest_post_preview">
      <div class="text-center" v-if="getPreviewImage">
        <img
          v-if="getPreviewImage"
          :src="getPreviewImage"
          alt=""
          style="border: 1px solid #eff4f8"
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
        <img
          v-else
          src="../../../../../../assets/img/profile_default.svg"
          alt=""
        />
      </div>

      <h2
        class="font-weight-bold"
        v-html="
          processSharingPreviewDesciption(details.pinterest_title, 'Pinterest')
        "
      ></h2>
      <!--                <h3 class="pb-5" v-html="processSharingPreviewDesciption(getPinterestOptions.postDescription,'Pinterest')"></h3>-->
      <h3
        class="pb-5"
        v-html="processSharingPreviewDesciption(details.message, 'Pinterest')"
      ></h3>

      <!-- <template v-if="details.video && details.video.link">

                    <div class="social_preview_block__social_preview_inner__social_post_preview__preview_video">
                        <div class="img" :style="{ 'background-image': 'url(' + details.video.thumbnail + ')' }">
                            <div class="pinterest_vid_icon vid_icon">
                                <span class="icon"></span>
                            </div>
                        </div>
                    </div>

                </template> -->

      <div class="pinterest_post_preview__head">
        <div class="pinterest_post_preview__head__profile_picture">
          <div
            class="pinterest_post_preview__head__profile_picture__picture_block picture_block_background"
          >
            <div
              class="img rounded_image"
              v-if="pinterestImage(getPreviewAccount.pinterest)"
              :style="{
                'background-image':
                  'url(' + pinterestImage(getPreviewAccount.pinterest) + ')',
              }"
            ></div>
            <div
              v-else
              class="img"
              style="
                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
              "
            ></div>
          </div>
          <div
            class="pinterest_post_preview__head__profile_picture__text_block"
          >
            <p class="text">{{ getPreviewAccount.pinterest.name }}</p>
            <p class="sub_text">{{ getPreviewAccount.pinterest.username }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['details'],
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getPinterestOptions'
    ]),
    getPreviewImage () {
      if (
        this.details.image &&
        Array.isArray(this.details.image) &&
        this.details.image.length > 0
      ) {
        return this.details.image[0]
      } else if (this.details.image && !Array.isArray(this.details.image)) {
        return this.details.image
      } else return false
    }
  },
  created () {
    console.log('DETAILS ------> ', this.details)
  }
}
</script>
