export const confirmation = {
  SET_PAYLOAD: 'SET_CONFIRMATION_PAYLOAD',
  SET_STAGE: 'SET_CONFIRMATION_STAGE',
  SET_CONFIRMATION_STAGE: 'setConfirmActionStage',
}

export const draftConfirmationTypes = {
  SET_DRAFT_CANCEL_ACTION: 'SET_DRAFT_CANCEL_ACTION',
  SET_DRAFT_CLICKAWAY: 'SET_DRAFT_CLICKAWAY',
}
