<template>
  <!-- 'loader_overlay_with_loader' class when showing loader....-->
  <div class="editor_block_main">
    <div
      class="editor_block"
      style="background: #ffffff"
      :class="{ hide: getBlogSharingSelection.stage !== 'content' }"
    >
      <!--loader here....-->
      <div class="editor_loader_area" v-show="editorLoaderStatus">
        <clip-loader :color="'#4165ed'" :size="'16px'"></clip-loader>
      </div>
      <div v-show="!editorLoaderStatus">
        <textarea
          style="border: none; resize: none"
          name=""
          id="editor_selector_feeder"
          cols="30"
          rows="10"
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      editorLoaderStatus: true
    }
  },
  created () {
    this.loadBlogEditorFeeder()
    this.editorLoader()
  },
  computed: {
    ...mapGetters(['getBlogSharingSelection'])
  },
  methods: {
    ...mapActions([]),
    editorLoader () {
      setTimeout(() => {
        this.editorLoaderStatus = false
      }, 2000)
    }
  }
}
</script>
