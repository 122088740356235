var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 d-flex flex-column"},[_c('InboxConversationHead',{attrs:{"active-inbox-detail":_vm.state.activeInboxData}}),_c('div',{staticClass:"conversation_messages"},[(_vm.state.activeInboxData !== null)?_c('div',{staticClass:"message m_in _comment_post"},[_c('div',{staticClass:"profile_picture d-flex align-items-start"},[_c('div',{staticClass:"text_block w-100"},[_c('div',{staticClass:"__text"},[_c('div',{staticClass:"_comment"},[_c('div',{staticClass:"inline w-full"},[_c('p',{staticClass:"text-xs text-gray-900"},[_c('strong',[_vm._v(_vm._s(_vm.state.gmbAccountData.location_name)+":")]),_vm._v(" "+_vm._s(_vm.state.gmbAccountData.platform_name))])]),_c('hr',{staticClass:"m-0 hr-color"}),_c('div',{staticClass:"flex flex-row justify-start items-center mt-3",class:_vm.getPlatformNameColor(_vm.state.activeInboxData.platform)},[_c('b-avatar',{attrs:{"variant":"light","size":"lg","src":_vm.state.activeInboxData.inbox_details.posted_by.image,"alt":""},on:{"error":function($event){$event.target.src = _vm.fallbackImagePreview(
                      _vm.state.activeInboxData.inbox_details.posted_by
                        ? _vm.state.activeInboxData.inbox_details.posted_by[0].toUpperCase()
                        : ''
                    )}}}),_c('p',{staticClass:"ml-2 font-bold"},[_vm._v(_vm._s(_vm.state.activeInboxData.inbox_details.posted_by.name))])],1),_c('div',{staticClass:"flex flex-row mt-3"},[_c('div',{staticClass:"flex items-center"},_vm._l((_vm.setStars(
                      _vm.state.activeInboxData.element_details.rating
                    )),function(star,index){return _c('span',{key:index,staticClass:"flex items-center"},[(star)?_c('svg',{staticClass:"w-5 h-5 text-yellow-400",attrs:{"aria-hidden":"true","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('title',[_vm._v("Rating "+_vm._s(index + 1))]),_vm._v(" > "),_c('path',{attrs:{"d":"M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"}})]):_c('svg',{staticClass:"w-5 h-5 text-gray-300 dark:text-gray-500",attrs:{"aria-hidden":"true","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('title',[_vm._v("Rating "+_vm._s(index + 1))]),_c('path',{attrs:{"d":"M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"}})])])}),0),_c('p',{staticClass:"ml-3 text-gray-700"},[_vm._v(" "+_vm._s(_vm.commonMethod.timesAgo( _vm.state.activeInboxData.element_details.updated_time ))+" ")])]),_c('div',{staticClass:"desc whitespace-pre-line"},[(_vm.state.activeInboxData.element_details.post_message)?_c('h2',{domProps:{"innerHTML":_vm._s(
                    _vm.stringToHtml(
                      _vm.state.activeInboxData.element_details.post_message
                    )
                  )}}):_vm._e()]),_c('div',{staticClass:"pt-2.5"},[_c('GmbReplyBox',{attrs:{"active-inbox-detail":_vm.state.activeInboxData}})],1)])])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }