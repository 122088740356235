<template>
  <div class="card_container">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2
            >{{ title }}
            <template v-if="tooltip_message">
              <i
                class="far fa-question-circle cursor_pointer"
                v-tooltip="tooltip_message"
                aria-hidden="true"
              ></i>
            </template>
          </h2>
          <ul class="item_highlight" :id="id"></ul>
        </div>
        <div
          class="card_content"
          :class="{ 'card_content_blur relative': is_content_available }"
        >
          <template>
            <div v-if="is_content_available" class="no_data_for_chart">
              <div>
                <img
                  src="../../../../assets/img/analytics/connect/analytics_data_availability.png"
                  alt=""
                />
              </div>
              <p class="no_data_available">
                {{ no_data_available }}
              </p>
            </div>
          </template>
          <div :class="{ blur_div: is_content_available }">
            <highcharts :options="options" :ref="ref_value"></highcharts>
          </div>
        </div>
      </div>
      <div class="text_box" v-if="is_active_hour && !is_content_available">
        <div class="text_inner">
          <p
            >Best time to post is <span>{{ active_hour }}:00</span></p
          >
        </div>
      </div>

      <div
        class="text_box"
        v-if="is_best_day && !is_content_available && best_day"
      >
        <div class="text_inner">
          <p
            >Best day to post is <span>{{ best_day }}</span></p
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_CHART_DATA } from '@common/constants/messages'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    options: {},
    title: {},
    id: {},
    ref_value: {},
    is_content_available: {
      default: false
    },
    is_active_hour: {
      default: false
    },
    active_hour: {
      default: '00:00'
    },
    is_best_day: {
      default: false
    },
    best_day: {
      default: 'Monday'
    },
    tooltip_message: {
      default: null
    }
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  mounted () {}
}
</script>

<style scoped>
@media print {
  .card_container {
    width: 920px !important;
  }
}
</style>
