<template>
  <div class="input_field">
    <div class="multi_select">
      <div class="d-flex">
        <label for=""
          ><span class="bold">EXCLUDE</span> results from these domains</label
        >
        <button
          class="reset_btn"
          @click="clearAllKeywords()"
          v-if="
            this.getTopicSelection.exclude_domains.length > 0 ||
            this.excludeDomainsValue.replace(/^\s+/, '') !== ''
          "
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <input
        class="prevent-listener"
        @input="asyncExcludeDomainResults(excludeDomainsValue)"
        v-on:keyup.enter="
          addAnyKeyword(
            getTopicSelection.exclude_domains,
            excludeDomainsValue,
            'exclude_domains',
            $event
          )
        "
        v-on:keyup.188="
          addAnyKeyword(
            getTopicSelection.exclude_domains,
            excludeDomainsValue,
            'exclude_domains',
            $event
          )
        "
        v-model="excludeDomainsValue"
        type="text"
        placeholder="Enter domains that you think are giving irrelevant results, e.g. careers.com…"
        data-cy="exclude-domain"
      />
      <div class="multi_input">
        <div
          v-on-clickaway="closeDropdown"
          class="multi_dropdown"
          v-if="isExcludeDomainsLoading && excludeDomainsValue.length >= 3"
        >
          <div class="multi_dropdown_inner">
            <ul>
              <!--<transition-group name="slide-bottom">-->
              <!--:key="suggested_domain"-->
              <li
                v-for="(
                  suggested_domain, index
                ) in exclude_domains_suggestion_list"
                class="item d-flex align-items-center"
                @click.prevent="addExcludeDomain(suggested_domain, index)"
              >
                <span
                  class="icon world_icon_size"
                  v-if="!suggested_domain.is_parent"
                >
                  <i class="cs-rss"></i>
                </span>
                <span class="icon" v-else-if="suggested_domain.favicon_16">
                  <img :src="suggested_domain.favicon_16" alt="" />
                </span>
                <span class="icon world_icon_size" v-else>
                  <i class="cs-world-o"></i>
                </span>
                <span class="text">
                  <span class="heading">{{ suggested_domain.name }}</span>
                  <span
                    class="url"
                    v-html="
                      highlightText(suggested_domain.url, excludeDomainsValue)
                    "
                  ></span>
                </span>
                <span class="view">
                  <i class="fa fa-eye"></i>
                </span>
              </li>
              <!--</transition-group>-->
            </ul>
          </div>
        </div>
        <div
          v-on-clickaway="closeDropdown"
          class="multi_dropdown"
          v-else-if="excludeDomainsLoader"
        >
          <div
            class="multi_dropdown_inner min_height d-flex align-items-center justify-content-center"
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
      <template v-if="getTopicSelection.exclude_domains">
        <div class="multi_tags">
          <ul>
            <li
              class="red"
              v-for="(
                must_exclude_keyword, index
              ) in getTopicSelection.exclude_domains"
            >
              <span class="value">{{ must_exclude_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(getTopicSelection.exclude_domains, index)
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isExcludeDomainsLoading: false,
      excludeDomainsValue: '',
      exclude_domains_suggestion_list: [],
      excludeDomainsLoader: false
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection'])
  },
  methods: {
    closeDropdown () {
      this.isExcludeDomainsLoading = false
    },
    clearAllKeywords () {
      this.excludeDomainsValue = ''
      this.getTopicSelection.exclude_domains = []
      this.excludeDomainsLoader = false
    }
  },
  watch: {
    excludeDomainsValue (value) {
      if (value.length == 0) {
        this.exclude_domains_suggestion_list = []
      }
    }
  }
}
</script>
