<template>
  <div class="table-responsive-sm mb-0" ref="datatable">
    <div
      v-if="!getState() > 0"
      class="opacity-70"
      style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'20px'"></clip-loader>
    </div>
    <table class="table" v-if="getState() > 0">
      <thead>
        <tr>
          <th class="b-0"></th>
          <th
            class="b-0"
            :width="colWidth"
            v-for="(field, i) in fields"
            :key="i"
          >
            {{ field.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(val, key, j) in tableData" :key="'row-' + j">
          <template v-if="Object.keys(tableData[key]).length > 0">
            <th class="text-left text-normal bg-light">
              <div class="d-flex mr-auto width-margin align-items-center">
                {{ tableData[key]['title'] }}
                <div class="ml-auto">
                  <i
                    class="fa fa-info info_hover_sm"
                    v-b-popover.hover.html.topright="tableData[key]['desc']"
                  ></i>
                </div>
              </div>
            </th>
            <td v-for="(value, k) in fields" :key="'val-' + k + j">
              <template v-if="typeof tableData[key][value.id] !== 'object'"
                >{{ tableData[key][value.id] }}
              </template>
              <template v-else>
                <p
                  class="max_width_content"
                  v-tooltip="tableData[key][value.id]['date']"
                  style="font-size: 0.875rem"
                >
                  {{ tableData[key][value.id]['val'] }}
                </p>
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style lang="less">
.width-margin {
  max-width: 170px;
}

.column-width {
  width: 14%;
}
</style>
<script>
export default {
  name: 'ComparativeTable',
  props: {
    talkingAbout: Object,
    fields: Array,
    maxPost: Object,
    maxFanChange: Object,
    maxFanEngagement: Object
  },
  // props: ['items', 'fields'],
  computed: {
    count () {
      return this.fields.length
    }
  },
  created () {
    window.addEventListener('resize', this.getColWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.getColWidth)
  },
  watch: {
    talkingAbout (newVal, oldVal) {
      if (Object.keys(newVal).length > 0 || newVal !== oldVal) {
        const alias = { ...this.tableData }
        alias.talking_about = newVal
        alias.talking_about.desc =
          '<span class="font-0-75rem">The field represents current value of people talking about this page. The field is normally used to define the liveliness and the potential to acquire traffic of certain page.</span>'
        this.tableData = { ...alias }
      }
    },
    maxPost (newVal, oldVal) {
      if (Object.keys(newVal).length > 0 || newVal !== oldVal) {
        const alias = { ...this.tableData }
        alias.max_post = newVal
        alias.max_post.desc =
          '<span class="font-0-75rem">The field represents the day on which a certain page have posted most posts on, along with the post count on that specific day.</br>PS: Hover on values to see complete date</span>'
        this.tableData = { ...alias }
      }
    },
    maxFanChange (newVal, oldVal) {
      if (Object.keys(newVal).length > 0 || newVal !== oldVal) {
        const alias = { ...this.tableData }
        alias.maxFanChange = newVal
        alias.maxFanChange.desc =
          '<span class="font-0-75rem">The field represents Max change in fans(increment or decrement) on a certain day. The field represents the occurrence of some event that might have caused increment or decrement in fans. The data will be visible after 2 days of adding competitor. </br>PS: Hover on values to see date</span>'
        this.tableData = { ...alias }
      }
    },
    maxFanEngagement (newVal, oldVal) {
      if (Object.keys(newVal).length > 0 || newVal !== oldVal) {
        const alias = { ...this.tableData }
        alias.maxFanEngagement = newVal
        alias.maxFanEngagement.desc =
          '<span class="font-0-75rem">The field represents Max post Engagement on any the day of occurance (within applied filter). It helps identify some post going viral and on which day.</br>PS: Hover on values to see date</span>'
        this.tableData = { ...alias }
      }
    }
  },
  methods: {
    getTableData () {
      return this.tableData
    },
    getState () {
      if (Object.keys(this.tableData).length > 0) {
        this.getColWidth('')
        return (
          Object.keys(this.tableData.talking_about).length +
          Object.keys(this.tableData.max_post).length +
          Object.keys(this.tableData.maxFanChange).length +
          Object.keys(this.tableData.maxFanEngagement).length
        )
      } else {
        return 0
      }
    },
    getColWidth (e) {
      if (this.fields.length) {
        this.colWidth =
          Math.floor(
            this.$refs.datatable.clientWidth / (this.fields.length + 1)
          ) - 5
      } else {
        this.colWidth = Math.floor(this.$refs.datatable.clientWidth / 7) - 5
      }
    }
  },
  data () {
    return {
      tableData: {},
      colWidth: 10
    }
  }
}
</script>
