<template>
  <!--<div class="view_change_btn d-flex align-items-center"-->
  <!--v-if="getTopicModule =='content' && getTopicContentType == 'article'">-->

  <!--&lt;!&ndash;<div class="view_left">&ndash;&gt;-->
  <!--&lt;!&ndash;<h3 class="results" v-if="getPostsTotal > 0">{{getPostsTotal | number-to-commas}}&ndash;&gt;-->
  <!--&lt;!&ndash;<template v-if="getPostsTotal == 1">&ndash;&gt;-->
  <!--&lt;!&ndash;Result&ndash;&gt;-->
  <!--&lt;!&ndash;</template>&ndash;&gt;-->
  <!--&lt;!&ndash;<template v-else>&ndash;&gt;-->
  <!--&lt;!&ndash;Results&ndash;&gt;-->
  <!--&lt;!&ndash;</template>&ndash;&gt;-->
  <!--&lt;!&ndash;</h3>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->

  <!--&lt;!&ndash;<div class="view_change_btn_inner ml-auto">&ndash;&gt;-->

  <!--&lt;!&ndash;<p class="grid_icon " v-tooltip.top-center="'Grid View'"  @click.prevent="changeLayout('grid')"&ndash;&gt;-->
  <!--&lt;!&ndash;:class="{active: getTopicsLayout=='grid'}">&ndash;&gt;-->
  <!--&lt;!&ndash;<i class="cs-dots-menu"></i>&ndash;&gt;-->
  <!--&lt;!&ndash;</p>&ndash;&gt;-->
  <!--&lt;!&ndash;<p v-tooltip.top-center="'List View'" class="list_icon" @click.prevent="changeLayout('list')"&ndash;&gt;-->
  <!--&lt;!&ndash;:class="{active: getTopicsLayout=='list'}">&ndash;&gt;-->
  <!--&lt;!&ndash;<i class="cs-list"></i>&ndash;&gt;-->
  <!--&lt;!&ndash;</p>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->

  <!--</div>-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'getTopicModule',
      'getPostsTotal',
      'getTopicContentType',
      'getTopicsLayout'
    ])
  },
  methods: {
    ...mapActions([])
  }
}
</script>
