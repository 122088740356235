<template>
  <div>
    <label class="heading_label" for="">Build Your Query</label>
    <p class="sub_label">Let's start by</p>
    <div class="radio_block">
      <div class="radio_input_image block">
        <input
          value="topics_and_keywords"
          checked
          type="radio"
          id="t3"
          name="m2"
          v-model="getTopicSelection.sources"
        />
        <label for="t3" class="radio_left">Adding topics and keywords.</label>
      </div>
      <div class="radio_input_image block">
        <input
          value="domains_and_sources"
          type="radio"
          id="t4"
          name="m2"
          v-model="getTopicSelection.sources"
        />
        <label for="t4" class="radio_left">Adding domains as sources.</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getTopicSelection'])
  }
}
</script>
