<template>
  <div class="auth">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container flex justify-center items-center">
          <form class="auth-form simple-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2 class="flex justify-content-center"
                >Two-Factor Authentication</h2
              >
              <CstAlert type="info" class="mt-6 text-left">
                You have 2FA enabled for enhanced security, please complete the
                verification.
              </CstAlert>
            </div>
            <CstFloatingLabelInput
              id="code"
              v-model="state.code"
              class="mb-6"
              type="text"
              :label="state.backup ? 'Backup Code' : 'Authenticator Code'"
              :maxlength="6"
              show-icon-left
              @keydown="filterKey"
              @enter="handleSubmit"
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="text-sm text-center">
              {{
                state.backup
                  ? 'Enter a 6 digit code from the backup codes generated.'
                  : 'Enter a 6 digit code from the Google Authenticator app.'
              }}
            </div>

            <div class="auth-form__button-field">
              <button
                data-cy="sign-in"
                :disabled="state.loader"
                class="btn btn-studio-theme-space btn-size-large"
                @click.prevent="handleSubmit"
              >
                <span>
                  <template v-if="state.loader">Processing</template>
                  <template v-else>Submit</template>
                </span>
                <img
                  v-if="state.loader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>

            <div v-if="state.backup" class="text-sm text-center">
              If you are having trouble logging in please contact support.
              <span
                class="text-blue-400 cursor-pointer hover:underline block"
                @click="toggleBackup"
                >Authenticate with Google Authenticator</span
              >
            </div>
            <div v-else class="text-sm text-center">
              Don’t have your phone?
              <span
                class="text-blue-400 cursor-pointer hover:underline"
                @click="toggleBackup"
                >Verify with backup code</span
              >
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <NewFeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import NewFeaturesSlider from '@src/modules/account/components/NewFeaturesSlider'
import CstAlert from '@ui/Alert/CstAlert'
import { defineComponent, inject, onMounted, reactive, watch } from 'vue'
import { verify2FACode } from '@src/modules/account/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { authenticationTypes } from '@state/mutation-types'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'SignIn',
  components: {
    NewFeaturesSlider,
    CstFloatingLabelInput,
    CstAlert,
  },
  setup() {
    const root = inject('root')
    const { dispatch, commit } = useStore()
    const {
      $route,
      $router,
      trackUserMaven,
      fetchProfile,
      isEmailVerified,
      getFrillParams,
    } = root

    const state = reactive({
      code: '',
      backup: false,
      loader: false,
    })
    const toggleBackup = () => {
      state.backup = !state.backup
    }
    const handleSubmit = async () => {
      state.loader = true

      /**
       * If query params contain Frill.co SSO
       * Authenticate user and redirect to frill redirect URI
       */
      const postData = getFrillParams()

      try {
        const response = await verify2FACode(
          {
            user_info: $route.params.token,
            code: state.code,
            ...postData,
          },
          state.backup
        )

        // set the cookies
        commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        commit(authenticationTypes.SET_LOGGED_USER, response.logged_user)
        commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        // custom event for user maven
        trackUserMaven('logged_in')

        // If login is request from SSO - Frill.co, redirect user
        if (response.redirect) {
          window.location.href = response.redirect
        }

        // check for redirected url
        if (
          $route.query.redirected_url &&
          $route.query.redirected_url !== '/logout' &&
          $route.query.redirected_url !== '/login'
        ) {
          window.location.href = $route.query.redirected_url
          return
        }

        if (response.slug) {
          await $router.push({
            name: 'dashboard',
            params: { workspace: response.slug },
          })
          return
        }

        await fetchProfile()
        isEmailVerified()
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
      state.loader = false
    }

    // make api call when state code length is 6
    watch(
      () => state.code.length,
      (newValue) => {
        if (newValue === 6) {
          handleSubmit()
        }
      }
    )

    onMounted(() => {
      console.log('mounted')
      // check if the token is in the route params
      const token = $route.params.token
      if (!token) {
        $router.push({ name: 'login' })
      }
    })

    /*
     * Method to handle code input validation
     */
    const filterKey = (e) => {
      const isModifierkeyPressed = e.metaKey || e.ctrlKey || e.shiftKey
      const isCursorMoveOrDeleteAction =
        [46, 8, 37, 38, 39, 40].indexOf(e.keyCode) !== -1
      const isNumKeyPressed =
        (e.keyCode >= 48 && e.keyCode <= 58) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      const vKey = 86
      const cKey = 67
      const aKey = 65
      switch (true) {
        case isCursorMoveOrDeleteAction:
        case isModifierkeyPressed === false && isNumKeyPressed:
        case (e.metaKey || e.ctrlKey) &&
          [vKey, cKey, aKey].indexOf(e.keyCode) !== -1:
          break
        default:
          e.preventDefault()
      }
    }

    return {
      state,
      filterKey,
      toggleBackup,
      handleSubmit,
    }
  },
})
</script>
