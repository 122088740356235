<template>
  <div class="dropdown-favorite-articles">
    <!-- if there are no folders, give an option to create one-->
    <template v-if="getFolders.length === 0">
      <h4 class="dropdown-favorite-articles__heading">Create a new folder</h4>
      <b-dd-divider />
      <div v-show="addFolderStatus" class="dropdown-favorite-articles__create">
        <a
          class="btn btn-studio-theme-grey-space create-folder"
          href="javascript:"
          @click.prevent="addFolder"
        >
          <i class="icon-add-new-member-cs mr-2"></i>Create Folder</a
        >
      </div>
      <template v-show="!addFolderStatus">
        <div class="dropdown-favorite-articles__create_input">
          <input
            v-model="folderName"
            placeholder="Enter a folder name"
            type="text"
            @keyup.enter="saveFolderAction(folderName)"
          />
          <div class="dropdown-favorite-articles__create_input__action_buttons">
            <img
              src="../../assets/article/check_icon.svg"
              alt="Add Folder"
              class="add_folder"
              @click.prevent="saveFolderAction(folderName)"
            />
            <img
              class="remove"
              src="../../assets/article/delete_icon.svg"
              alt="remove topic"
              @click="cancelFolder"
            />
          </div>
        </div>
        <div v-if="saveFolderError" class="p-1 pl-3 font-0-87rem"
          >Enter name of folder..</div
        >
      </template>
    </template>
    <!-- if there are no folders, give an option to create one-->

    <template v-else>
      <h4 class="dropdown-favorite-articles__heading">Select folder</h4>
      <b-dd-divider />
      <div
        v-if="getFolders.length > 4"
        class="dropdown-favorite-articles__search"
      >
        <input
          v-model="search_folder"
          type="text"
          saveFolderError
          placeholder="Search folder name"
          class="search-bar-input search-bar-input-discovery with-icon-left"
        />
        <i class="icon-Search"></i>
      </div>
      <ul class="dropdown-favorite-articles__items">
        <template v-for="(folder, index) in getSortedFolders">
          <li :key="index" @click.prevent="savePostAsFavoriteAction(folder._id, post_id)">
            <span>{{ folder.name }}</span>
            <!--<img @click.prevent="removeFolder(folder._id,folder.name, index)"  class="remove close_this" src="../../assets/article/delete_icon.svg" alt="remove topic">-->
          </li>
        </template>
      </ul>
      <b-dd-divider />
      <div v-show="addFolderStatus" class="dropdown-favorite-articles__create">
        <a
          class="btn btn-studio-theme-grey-space create-folder"
          href="javascript:"
          @click.prevent="addFolder"
        >
          <i class="icon-add-new-member-cs mr-2"></i>Create Folder</a
        >
      </div>
      <div v-show="!addFolderStatus" class="dropdown-favorite-articles__create_input">
        <input
          v-model="folderName"
          type="text"
          placeholder="Enter a folder name"
          @keyup.enter="saveFolderAction(folderName)"
        />
        <div class="dropdown-favorite-articles__create_input__action_buttons">
          <img
            class="add_folder"
            src="../../assets/article/check_icon.svg"
            alt="Add Folder"
            @click.prevent="saveFolderAction(folderName)"
          />
          <img
            class="remove"
            src="../../assets/article/delete_icon.svg"
            alt="remove topic"
            @click="cancelFolder"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import { EventBus } from '@common/lib/event-bus'

export default {
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['post_id', 'postIndex'],
  data () {
    return {
      addFolderStatus: true,
      folderName: null,
      search_folder: '',
      saveFolderError: false
    }
  },
  computed: {
    ...mapGetters(['getFolderText', 'getFolders']),
    isDropdownFavoriteTopicsModule () {
      if (['cover_stories', 'topics_web'].indexOf(this.$route.name) >= 0) {
        return true
      }
      return false
    },
    getSortedFolders () {
      if (this.search_folder.trim().length > 0) {
        const folders = this.getFolders.filter((item) =>
          item.name.toLowerCase().includes(this.search_folder.toLowerCase())
        )
        return sortBy(folders, ['name'], ['asc'])
      }
      return sortBy(this.getFolders, ['name'], ['asc'])
    }
  },
  methods: {
    // ...mapActions([''])
    addFolder () {
      this.addFolderStatus = false
    },
    cancelFolder () {
      this.addFolderStatus = true
    },
    savePostAsFavoriteAction (folderId, postId) {
      // for the discover topics

      /*  if(this.isDropdownFavoriteTopicsModule){
        this.setFavorite(folderId, postId, '', this.postIndex)
      }
      else{ */
      // for the discover search
      console.log('savePostAsFavoriteAction: ', folderId, postId)
      EventBus.$emit('favorite-post-item', {
        folderId: folderId,
        postId: postId
      })
      EventBus.$emit('hide-favorite-dropdown')
      this.search_folder = ''
      // }
    },
    async saveFolderAction (folderName) {
      // for the discover topics

      if (
        this.folderName === '' ||
        this.folderName === null ||
        this.folderName === 0
      ) {
        this.saveFolderError = true
      } else {
        this.saveFolderError = false
      }

      if (this.isDropdownFavoriteTopicsModule) {
        this.saveFolder(folderName)
      } else {
        // for the discover search
        console.log('saveFolderAction: ', folderName)
        const res = await this.$store.dispatch('storeFolder', {
          name: folderName
        })
        if (res) {
          EventBus.$emit('favorite-post-item', {
            folderId: res.data.added_folder,
            postId: this.post_id
          })
          this.addFolderStatus = true
          this.folderName = ''
        }
      }
    }
  }
}
</script>
