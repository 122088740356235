<template>
  <div class="blog_share_editor">
    <clip-loader
      color="#4165ed"
      :size="'16px'"
      v-if="getPublishLoaders.initializeArticlePostCreation"
    ></clip-loader>

    <div
      class="input_field editor_title"
      :class="{ hide: getPublishLoaders.initializeArticlePostCreation }"
    >
      <textarea
        name=""
        id=""
        cols="30"
        rows="2"
        v-model="getBlogSharingSelection.title"
        placeholder="Add your title here..."
      ></textarea>
    </div>
    <blog-editor-feeder
      :class="{ hide: getPublishLoaders.initializeArticlePostCreation }"
    ></blog-editor-feeder>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BlogEditorFeeder from '../../../../composer/components/blog/BlogEditorFeeder'

export default {
  components: {
    BlogEditorFeeder
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getBlogSharingSelection', 'getPublishLoaders'])
  },
  methods: {},

  watch: {}
}
</script>
