<template>
  <div>
    <FilterbarCompetitor
        @updatedCompetitor="initializePageWithData()"
        @set-date-range="setDateRange"
        :selectedCompetitor="selected_account.instagram_id"
    />
    <div class="competitor-overview__wrapper">
      <!-- tool tip for pending processes -->
      <div
          class="tp_text"
          v-if="
                inactiveCompetitor.length > 0 &&
                selectedCompetitors.length >= 2 &&
                isCompetitorValid(selected_account.instagram_id)
              "
      >
        We are fetching data and crunching numbers for
        <b>{{ inactiveCompetitor[0].name }}</b>
        <template v-if="inactiveCompetitor.length > 1">
          and
          <span class="ml-auto position-relative">
                  <span
                      class="cursor-pointer"
                      v-b-popover.hover.html.leftbottom="compToolTip()"
                  ><b>{{ inactiveCompetitor.length - 1 }} other.</b></span
                  >
                </span></template
        >
      </div>
      <!-- header section start here -->
      <div class="competitor-overview__header">
        <div class="competitor-overview__header-left top-comp-filter">
          <b-dropdown
              ref="dropdown"
              variant="studio-theme"
              toggle-class="min-width"
              size="lg"
              class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image"
              :disabled="connectedAccounts.length === 0"
              no-caret
          >
            <template slot="button-content">
              <template v-if="connectedAccounts.length === 0">
                <i
                    class="fad fa-user-circle mr-0"
                    style="font-size: 1.8rem"
                ></i>
                <span
                    class="capitalize_text d-inline-flex justify_content_start text-left ml-3 text-bold-500"
                >
                  <small>No Account Connected</small>
                </span>
              </template>
              <template v-else :disabled="selectedCompetitors.length <= 0">
                <div v-if="!selected_account" class="d-flex align-items-center">
                  <i
                      class="fad fa-user-circle mr-0"
                      style="font-size: 1.8rem"
                  ></i>
                  <span
                      class="capitalize_text d-inline-flex justify_content_start text-left ml-3 text-bold-500"
                  >
                    <small>Please Select An Account</small>
                  </span>
                </div>
                <div
                    v-else
                    class="d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <span class="connected-accounts-images">
                      <div>
                        <img
                            class="selected"
                            @error="
                            $event.target.src = fallbackImagePreview(selected_account.name[0])
                          "
                            :src="selected_account['image']"
                            alt=""
                        />
                      </div>
                    </span>
                    <span
                        class="capitalize_text d-flex flex-column justify_content_start text-left ml-3 text-bold-500"
                    >
                      {{ selected_account.name }}
                      <small>{{ selected_account.type }}</small>
                    </span>
                  </div>

                  <i class="icon-dropdown-cs"></i>
                </div>
              </template>
            </template>

            <div v-for="(account, i) in connectedAccounts" :key="i">
              <b-dropdown-item @click="changeSelectedAccount(account)">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label>
                      <div class="dropdown-img-wrapper">
                        <img
                            class="selected"
                            @error="
                            $event.target.src = fallbackImagePreview(account.name[0])
                          "
                            :src="channelImage(account, 'facebook')"
                            alt=""
                        />
                        <i :class="getIconClass('instagram')"></i>
                      </div>
                      <span class="dropdown-item-label">
                        <span>{{ account.name }}</span>
                        <small>Page</small>
                      </span>
                    </label>
                  </div>
                </div>
              </b-dropdown-item>
            </div>
          </b-dropdown>

          <div class="d-inline-flex flex-wrap">
            <div
                v-if="selectedCompetitors.length > 0"
                v-for="(competitor, i) in selectedCompetitors.filter(
                (obj) => obj.competitor_id !== selected_account.instagram_id
              )"
                :key="i"
                class="competitor-overview__comp-box"
            >
                            <span
                                class="mx-4"
                                v-if="isCompetitorValid(competitor.competitor_id)"
                            ><i>vs</i></span>
              <div
                  v-if="isCompetitorValid(competitor.competitor_id)"
                  class=""
                  :class="
                  competitor.enabled ? 'rounded_pill_selected' : 'rounded_pill'
                "
                  @click="updateCompState(competitor)"
                  debounce="500"
              >
                <span class="competitor-overview__comp-box-image">
                  <img :src="competitor.image" alt=""
                       @error="$event.target.src = fallbackImagePreview(competitor.display_name[0]) "/>
                </span>
                <span
                    class="capitalize_text flex-column justify_content_start text-left ml-2 text-bold-500 truncate"
                >
                  {{ competitor.name }}
                </span>
              </div>

            </div>
          </div>
        </div>

        <!--        <div class="competitor-overview__header-right"></div>-->
      </div>

      <template
          v-if="selectedCompetitors.length >= 2 && isCompetitorValid(selected_account.instagram_id) && (selectedCompetitors.length-inactiveCompetitor.length)>1"
      >
        <!-- Ranking section start here -->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header">Rankings</div>
          <div class="competitor-overview__card-body">
            <!--                  <div class="grid-flex">-->
            <!--                    <div-->
            <!--                        class="cell-lg-4 pr-0 mb-2"-->
            <!--                    >-->
            <CompareBox :reload="reload" :selected_competitors="selectedCompetitors"
                        :time_period="startDate + ' - ' + endDate"
                        :selected_account_id="selected_account.instagram_id"/>
            <!--                    </div>-->
            <!--                  </div>-->
          </div>
        </div>
        <!--              &lt;!&ndash; Data Table  start here&ndash;&gt;-->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header d-flex align-items-center">
            <div class="compare-box__platform">
              <i class="fab fa-instagram ins"></i>
            </div>
            Comparative Table
          </div>
          <div class="competitor-overview__card-body">
            <ComparativeTable
                :reload="reload" :selected_competitors="selectedCompetitors"
                :time_period="startDate + ' - ' + endDate"
                :selected_account_id="selected_account.instagram_id"
            />
          </div>
        </div>
        <!-- Data Graphs  start here-->
        <div class="competitor-overview__card">
          <div class="competitor-overview__card-header">
            Growth and history charts
          </div>
          <div class="competitor-overview__card-body">
            <div class="grid-flex">
              <div class="cell-lg-6 mb-3 pr-0 pl-0">
                <GrowthChart
                    :type="'FanCount'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <!-- Bar Graph -->
              <div class="cell-lg-6 mb-3 pr-0">
                <GrowthChart
                    :type="'FanGrowth'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <div class="cell-lg-6 mb-3 pr-0 pl-0">
                <GrowthChart
                    :type="'AvgPostEng'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>

              <!-- Bar Graph -->
              <div class="cell-lg-6 mb-3 pr-0 pr-0">
                <GrowthChart
                    :type="'EngPostType'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <!-- Post Engagement Graph -->
              <div class="cell-lg-12 mb-6 pl-0 pr-0">
                <GrowthChart
                    :type="'PostEng'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <div class="cell-lg-12 mb-6 pl-0 pr-0">
                <WordCLoud
                    :type="'WordCloud'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <!-- Pir Chart -->
              <div class="cell-lg-12 mb-6 pr-0 pl-0">
                <competitorPieChart
                    :type="'postType'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
              <!-- Pie Chart -->
              <div class="cell-lg-12 mb-6 pl-0 pr-0">
                <competitorPieChart
                    :type="'userEngagement'"
                    :reload="reload" :selected_competitors="selectedCompetitors"
                    :time_period="startDate + ' - ' + endDate"
                    :selected_account_id="selected_account.instagram_id"
                />
              </div>
            </div>
          </div>

        </div>
        <!-- Top Posts -->
        <div class="competitor-overview__card">
          <PostCards
              :reload="reload" :selected_competitors="selectedCompetitors"
              :time_period="startDate + ' - ' + endDate"
              :selected_account_id="selected_account.instagram_id"></PostCards>
        </div>

      </template>
      <div v-else>
        <CompetitorAnalyticsLoading
            :name_list="inactiveCompetitor"
            :isPageValid="isCompetitorValid(selected_account.instagram_id)"
        ></CompetitorAnalyticsLoading>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.top-comp-filter {
  background: #fff;
  padding: 0.8rem;
  border: 1px;
  border-radius: 13px;
}
</style>
<script>
import FilterbarCompetitor from '../../common/competitors/instagram/FilterBarCompetitor'
import analyticsUtilsMixin from '../../../components/common/analyticsUtilsMixin'
import CompareBox from '../../common/competitors/instagram/CompareBox'
import competitorPieChart from '../../common/competitors/instagram/CompetitorPieChart'
import ComparativeTable from '../../common/competitors/instagram/ComparativeTable'
import GrowthChart from '../../common/competitors/instagram/GrowthChart'
import WordCLoud from '../../common/competitors/instagram/WordCLoud'
import PostCards from '../../common/competitors/instagram/postCards'
import CompetitorAnalyticsLoading from '../../common/CompetitorAnalyticsLoading'
import moment from 'moment'
import api_ops from "../../common/competitors/instagram/api_ops";

import {mapGetters} from 'vuex'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'instagramCompetitorOverview',
  mixins: [analyticsUtilsMixin, api_ops],
  components: {
    FilterbarCompetitor,
    CompareBox,
    ComparativeTable,
    GrowthChart,
    competitorPieChart,
    CompetitorAnalyticsLoading,
    WordCLoud,
    PostCards
  },
  computed: {
    ...mapGetters(['getJWTToken', 'getActiveWorkspace']),
    connectedAccounts() {
      // function returns connected accounts for user->workspace
      return this.getPlatformAccounts('instagram').sort((a, b) =>
          a.name > b.name ? 1 : -1
      )
    }
  },

  methods: {
    updateCompState(competitor) {
      // The function is responsible to manages competitors states (enabled/disabled)
      competitor.enabled = !competitor.enabled
      if (!competitor.enabled) {
        this.disabledCompetitors.push(competitor.competitor_id)
      } else {
        this.disabledCompetitors = this.disabledCompetitors.filter(
            (val) => val !== competitor.competitor_id
        )
      }
      // calls to reload all data of the view.
      this.loadComps()
    },
    changeSelectedAccount(account) {
      // the function is responsible make report and view changes whenever user selects a different fb account.
      this.selectedCompetitors = []
      // this.emptyExistingViews()
      this.$router.push({
        name: 'instagramCompetitorOverview',
        params: {accountId: account.instagram_id}
      })
      this.initializePageWithData()

    },

    setDateRange(startDate, endDate) {
      // this function is responsible to update date range every time user update the date filter.
      console.log('newDateRange', startDate, endDate)
      this.startDate = startDate
      this.endDate = endDate
      this.reload = this.reload ? false : true
      // this.emptyExistingViews(false)
      // this.loadReport()
    },
    initializePageWithData() {
      // the function is reponsible to initialize whole page with data send set selected accounts base on the id recieved in url.
      this.$root.$emit('bv::hide::modal', 'modal-1')
      this.inactiveCompetitor = []
      this.selected_account = {}
      this.disabledCompetitors = []
      this.selectedCompetitors = []
      if (this.$route.params.accountId) {
        this.selected_account = this.connectedAccounts.filter((account) => {
          return account.instagram_id === this.$route.params.accountId
        })[0]
        this.loadComps('all')

      }
    },
    loadComps(comp = '') {
      if (comp == 'all') {
        this.cancelPendingRequest('getCompetitors')
        let promise = this.getIgCompetitors(this.selected_account.instagram_id)
        this.requestCancelPromise['getCompetitors'].push(promise[0])
        promise[1].then((res) => {
          this.selectedCompetitors = res.data.competitors
          this.selectedCompetitors.map((obj) => {
            obj['enabled'] = true
            return obj
          })
          this.inactiveCompetitor = this.selectedCompetitors.filter((obj) => {
            return !obj.hasOwnProperty('last_analytics_updated_at')
          })
          this.reload = this.reload ? false : true
        })
      } else {
        this.selectedCompetitors = this.selectedCompetitors.map((obj) => {
          if (this.disabledCompetitors.includes(obj['competitor_id'])) {
            obj['enabled'] = false
          } else {
            obj['enabled'] = true
          }
          return obj
        })
        this.reload = this.reload ? false : true
      }


    },

    compToolTip() {
      let template = ''
      this.inactiveCompetitor.forEach((obj, idx) => {
        if (idx === 0) {
        } else {
          template =
              template +
              '<div class="tooltip_compair_box">' +
              '<img src="' +
              obj.image +
              '" alt="" class="compare-box__ranking-logo">' +
              '<div class="compare-box__ranking-label label">' +
              obj.name +
              '</div></div>'
        }
      })
      return template + ''
    },
    isCompetitorValid(comp_id) {
      let flag = false
      this.selectedCompetitors.forEach((obj) => {
        if (
            obj.competitor_id === comp_id &&
            obj.hasOwnProperty('last_analytics_updated_at')
        ) {
          flag = true
        }
      })
      return flag

    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },

  },
  data() {
    return {
      reload: false,
      requestCancelPromise: {},
      selected_account: {},
      selectedCompetitors: [],
      startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
      inactiveCompetitor: [],
      disabledCompetitors: [],
    }
  },
  mounted() {
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.cancelPendingRequest('all')
      console.log('ws changing itself' +
          '')
      this.$router.push({
        name: 'instagramCompetitor'
      })
    })

    this.initializePageWithData()
  },
  beforeDestroy() {
    EventBus.$off('workspace-changed')
    this.selected_account = null
    this.selectedCompetitors = []
    this.inactiveCompetitor = []
    this.rankings = []

  }
}
</script>
