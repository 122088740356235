var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block"},[_c('div',{staticClass:"flex-row text-right"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('insertFileModel')}}},[_vm._v("×")])]),_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner"},[_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__media_preview_block"},[_c('div',{staticClass:"input_field mt-0"},[_c('label',{attrs:{"for":""}},[_vm._v("Find an image on Giphy")]),_c('div',{staticClass:"search_input with_icon_left"},[_c('div',{staticClass:"search_inner w-100 cs-input-grey"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"searchArea",attrs:{"type":"text","placeholder":"Search for something"},domProps:{"value":(_vm.keyword)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchGiphyImage()},"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_c('div',{staticClass:"bottom_btn_section mt-3"},[_vm._m(0),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Clear'),expression:"'Clear'",modifiers:{"top-center":true}}],staticClass:"clearsearch",on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("×")])])])])])]),_c('div',{staticClass:"fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_upload"},[_c('ul',[(_vm.getImagesLoader)?_c('clip-loader',{attrs:{"color":"#4165ed","size":'16px'}}):[(_vm.getImagesPosts.length)?_vm._l((_vm.getImagesPosts),function(photo){return (
                photo.images &&
                photo.images.original &&
                photo.images.original.url
              )?_c('li',{staticClass:"col-3",on:{"click":function($event){return _vm.processFileSelectionGiphy(photo.images.original.url)}}},[_c('div',{staticClass:"image",class:{
                  active: _vm.selectedItems.includes(photo.images.original.url),
                }},[_c('img',{attrs:{"src":photo.images.original.url,"alt":""}}),(_vm.selectedItems.includes(photo.images.original.url))?_c('i',{staticClass:"fa fa-check"}):_vm._e()])]):_vm._e()}):_vm._e(),(!_vm.getImagesScroll && _vm.getImagesPosts.length === 0)?[_c('no-results-found')]:(_vm.getImagesPosts.length > 0 && _vm.getImagesScroll)?_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.onInfiniteImagesGiphy}},[_c('span',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]):_vm._e()]],2),(_vm.selectedItems.length)?_c('div',{staticClass:"bottom_btn_section"},[_c('button',{staticClass:"btn blue loader royal_blue mt-3",on:{"click":function($event){$event.preventDefault();return _vm.insertGiphyImages(_vm.selectedItems)}}},[_c('span',[_vm._v("Insert ")]),_c('span',[_vm._v(_vm._s(_vm.selectedItems.length))]),_c('span',[_vm._v(" image(s)")])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"search_btn"},[_c('i',{staticClass:"icon-Search"})])
}]

export { render, staticRenderFns }