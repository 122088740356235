import { plannerBaseUrl } from '@src/config/api-utils'
import proxy from '@common/lib/http-common'

export const ACTION_TYPES = {
  CREATE_LINK: 'CREATE_LINK',
  GET_LINK: 'GET_LINK',
  VERIFY_PASSWORD: 'VERIFY_PASSWORD',
  FETCH_PLANS: 'FETCH_PLANS',
  FETCH_LINKS: 'FETCH_LINKS',
  UPDATE_LINK: 'UPDATE_LINK',
  DELETE_LINK: 'DELETE_LINK',
  ADD_COMMENT: 'ADD_COMMENT',
  APPROVE_REJECT_PLAN: 'APPROVE_REJECT_PLAN',
}
/**
 * Service to create a share link
 * @param action  - action to be performed
 * @param {object} payload - payload to be sent
 */
export const serviceShareLink = (action = '', payload = {}) => {
  let apiUrl = ``
  if (action === ACTION_TYPES.CREATE_LINK) {
    apiUrl = `${plannerBaseUrl}shareLink/create`
  } else if (action === ACTION_TYPES.GET_LINK) {
    apiUrl = `${plannerBaseUrl}shareLink/get`
  } else if (action === ACTION_TYPES.VERIFY_PASSWORD) {
    apiUrl = `${plannerBaseUrl}shareLink/verifyPassword`
  } else if (action === ACTION_TYPES.FETCH_PLANS) {
    apiUrl = `${plannerBaseUrl}shareLink/fetchPlans`
  } else if (action === ACTION_TYPES.FETCH_LINKS) {
    apiUrl = `${plannerBaseUrl}shareLink/fetch`
  } else if (action === ACTION_TYPES.UPDATE_LINK) {
    apiUrl = `${plannerBaseUrl}shareLink/update`
  } else if (action === ACTION_TYPES.DELETE_LINK) {
    apiUrl = `${plannerBaseUrl}shareLink/delete`
  } else if (action === ACTION_TYPES.ADD_COMMENT) {
    apiUrl = `${plannerBaseUrl}shareLink/comment`
  } else if (action === ACTION_TYPES.APPROVE_REJECT_PLAN) {
    apiUrl = `${plannerBaseUrl}shareLink/action`
  }

  return new Promise((resolve, reject) => {
    proxy
      .post(apiUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response?.data?.status) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) reject(error.response.data)
        else reject(error)
      })
  })
}
