<template>
  <div class="analytics-report-wrapper">
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="1" :total="total"></report-header>
        <div class="analytics-section-header mb-2">
          <h2>Instagram Page Performance Summary</h2>
          <p>View your key profile performance metrics from the reporting period.</p>
        </div>
        <div class="row">
          <template v-for="(value, key, i) in summary_values">
            <div class="col-6 col-sm-4 " :class="{ 'mb-4': i<6 }" :key="key">
              <template>
                <SummaryCard :title="value"
                             :total="metrics.overview.summary.current[key] ? metrics.overview.summary.current[key] : 0"
                             :reach="Math.abs(getReach(key,metrics.overview.summary))+'%'"
                             :reachUp="getReachUp(key,metrics.overview.summary)"></SummaryCard>
              </template>
            </div>
          </template>
        </div>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Instagram Audience Growth </h2>
              <p>See how your audience grew during the reporting period.</p>
            </div>
            <SplineBasicChart v-if="metrics.overview.audience.audience_growth.data.show_data > 0"
                              :categories="metrics.overview.audience.audience_growth.buckets"
                              :series="getAudienceGrowthSeries(metrics.overview.audience)"/>
            <div v-else class="flex-center-center" style="height: 90%;">

              <div class="analytics-no-data-found ">
                <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                <p> No Data Found!</p>
              </div>
            </div>
          </section>

          <h2>Audience Growth Rollup</h2>
          <div class="row">
            <template v-for="(value, key,i) in metrics.overview.audience_rollup_values">
              <div class="col-6 col-sm-3 summary-card-wrapper" :class="{ 'mb-4': i<4 }" :key="key">
                <SummaryCard  :key="key" :title="value"
                              :total="metrics.overview.audience.audience_growth_rollup.current[key]"
                              :reach="Math.abs(getReach(key,metrics.overview.audience.audience_growth_rollup))+'%'"
                              :reachUp="getReachUp(key,metrics.overview.audience.audience_growth_rollup)"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="2" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Instagram Publishing Behavior </h2>
              <p>View the different types of posts you published during the
                selected time period.</p>
            </div>
            <ColumnCrossHairChart v-if="metrics.overview.publishing_behaviour.publishing_behaviour.data.show_data>0"
                                  :series="getPublishingBehaviourSeries(metrics.overview.publishing_behaviour)"
                                  :categories="metrics.overview.publishing_behaviour.publishing_behaviour.buckets"/>
            <div v-else class="flex-center-center" style="height: 90%;">

              <div class="analytics-no-data-found ">
                <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Publishing Behavior by Content Type</h2>
          <div class="row">
            <template v-for="(value, key,i) in metrics.overview.publishing_behaviour_rollup_values">
              <div class="col-6 col-sm-4 " :class="{ 'mb-4': i<3 }" :key="key">
                <SummaryCard :key="key" :title="value"
                             :total="metrics.overview.publishing_behaviour.publishing_behaviour_rollup.current[key]"
                             :reach="Math.abs(getReach(key,metrics.overview.publishing_behaviour.publishing_behaviour_rollup))+'%'"
                             :reachUp="getReachUp(key,metrics.overview.publishing_behaviour.publishing_behaviour_rollup)"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="3" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper mx-0">
            <div class="analytics-section-header">
              <h2>Instagram Top Posts</h2>
              <p>Review your top posts published during the selected time period, based on the post’s lifetime
                performance.</p>
            </div>
            <template v-if="metrics.overview.top_posts.length <=0">
              <div class="analytics-no-data-found">
                <img src="../../assets/imgs/no_data_images/no_post_found.svg"
                     alt="">
                <p>You do not have any posts published in the selected time period.</p>
              </div>
            </template>
            <div v-else class="row">
              <template  v-for="i in 3">
                <div :key="i" class="col-4">
                  <template v-if="metrics.overview.top_posts.length>=i">
                    <PostCard :post="metrics.overview.top_posts[i-1]" platform_type="instagram"/>
                  </template>
                  <template v-else>
                    <!--  No More Posts   -->
                    <div class="analytics-post-card">
                      <div class="analytics-post-card__header">
                        <div class="analytics-post-card__header-left">
                          <div class="analytics-post-card__header-img">
                            <SkeletonBox class="skeletonBox--noanimcation" height="2.8rem" width="2.8rem" radius="50%"/>
                          </div>
                          <div class="analytics-post-card__header-detail">
                            <div class="d-flex" style="flex-direction: column; margin-left: 10px">
                              <div class="mb-1">
                                No More Post
                              </div>
                              <SkeletonBox class="skeletonBox--noanimcation" radius=".2rem"/>
                            </div>
                          </div>
                        </div>
                        <div class="analytics-post-card__header-right" style="filter: grayscale(100%);opacity: 0.6;">
                          <!-- Dynamic Icon bases on props -->
                          <i class="far fa-external-link-square-alt"></i>
                        </div>
                      </div>

                      <div class="analytics-post-card__content">
                        <div class="mb-1">
                          <SkeletonBox class="skeletonBox--noanimcation" width="100%" radius=".2rem"/>
                        </div>
                        <div class="mb-2">
                          <SkeletonBox class="skeletonBox--noanimcation" radius=".2rem"/>
                        </div>
                        <div class="mb-2">
                          <SkeletonBox class="skeletonBox--noanimcation" width="100%" height="260px" radius=".2rem"/>
                        </div>
                      </div>

                      <ul class="analytics-post-card__content-ele">
                        <li v-for="i in 6" :key="i">
                          <SkeletonBox class="skeletonBox--noanimcation" width="40%" radius=".2rem"/>
                          <SkeletonBox class="skeletonBox--noanimcation" width="1.1rem" radius=".2rem"/>
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </section>

        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="4" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Instagram Active Users</h2>
              <p>Review your active users as of the last day of the reporting period.</p>
            </div>
            <h3>Active Users by Hours</h3>
            <LineBasicChart v-if="metrics.overview.active_users.active_users_hours.highest_value>0"
                            :categories="metrics.overview.active_users.active_users_hours.buckets"
                            :series="getActiveUsersSeries( metrics.overview.active_users,'hours')"/>
            <div v-else class="flex-center-center" style="height: 90%;">
              <div class="analytics-no-data-found ">
                <img :src="require('../../assets/imgs/no_data_images/no-analytical-data-available.svg')" alt="">
                <p> No Data Found!</p>
              </div>
            </div>
            <p class="text_center">Best hour to post is <b>{{ metrics.overview.active_users.active_users_hours.highest_hour }}:00</b> with highest
              value
              <b>{{ metrics.overview.active_users.active_users_hours.highest_value }}</b></p>
            <h3>Active Users by Days</h3>
            <LineBasicChart v-if="metrics.overview.active_users.active_users_days.highest_value>0"
                            :categories="metrics.overview.active_users.active_users_days.buckets"
                            :series="getActiveUsersSeries(metrics.overview.active_users,'days')"/>
            <div v-else class="flex-center-center" style="height: 90%;">
              <div class="analytics-no-data-found ">
                <img :src="require('../../assets/imgs/no_data_images/no-analytical-data-available.svg')" alt="">

                <p> No Data Found!</p>
              </div>
            </div>
            <p  class="text_center">Best day to post is <b>{{ metrics.overview.active_users.active_users_days.highest_day }}</b> with highest value
              <b>{{ metrics.overview.active_users.active_users_days.highest_value }}</b></p>

          </section>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="5" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Instagram Impressions</h2>
              <p>Review how your content was seen by the Facebook community during the reporting period.</p>
            </div>
            <SplineBasicChart v-if="metrics.overview.impressions.impressions.data.show_data>0"
                              :series="getImpressionsSeries(metrics.overview.impressions)"
                              :categories="metrics.overview.impressions.impressions.buckets"/>
            <div v-else class="flex-center-center" style="height: 90%;">

              <div class="analytics-no-data-found ">
                <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Impressions  Rollup</h2>
          <div class="row">
            <template v-for="(value, key,i) in metrics.overview.impressions_rollup_values">
              <div class="col-6 col-sm-4 summary-card-wrapper" :class="{ 'mb-4': i<3 }" :key="key">
                <SummaryCard :key="key" :title="value"
                             :total="(metrics.overview.impressions.impressions_rollup.current[key])"
                             :reach="Math.abs(getReach(key,metrics.overview.impressions.impressions_rollup))+'%'"
                             :reachUp="getReachUp(key,metrics.overview.impressions.impressions_rollup)"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="6" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Facebook Engagement</h2>
              <p>See how people are engaging with your posts during the reporting period.</p>
            </div>
            <SplineBasicChart v-if="metrics.overview.engagement.engagements.data.show_data >0"
                              :series="getEngagementSeries(metrics.overview.engagement)"
                              :categories="metrics.overview.engagement.engagements.buckets"/>
            <div v-else class="flex-center-center" style="height: 90%;">
              <div class="analytics-no-data-found ">
                <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                <p> No Data Found!</p>
              </div>
            </div>
          </section>
          <h2>Engagements Rollup</h2>
          <div class="row">
            <template v-for="(value,key,i) in metrics.overview.engagement_rollup_values">
              <div class="col-6 col-sm-4 summary-card-wrapper" :class="{ 'mb-4': i<3 }" :key="key">
                <SummaryCard :key="key" :title="value"
                             :total="(metrics.overview.engagement.engagements_rollup.current[key])"
                             :reach="Math.abs(getReach(key,metrics.overview.engagement.engagements_rollup))+'%'"
                             :reachUp="getReachUp(key,metrics.overview.engagement.engagements_rollup)"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="7" :total="total"></report-header>
        <div>
          <div class="row">
            <div class="col-6 ">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience by Age</h3>
                </div>
                <StraightLineChart :values="metrics.demographics.fans_age" :max_value="metrics.demographics.max_gender_age.value"/>
              </div>
            </div>

            <div class="col-6">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header facebook-analytics-demo__gender">
<!--                  <i class="far fa-venus-mars"></i>-->
                  <h3>Audience by Gender</h3>
                </div>
                <PieInnerChart
                    v-on:mounted="getPieRef"
                    :chartRef="`gender-overall-analytics-pie`"
                    :colors="['#6173fc', '#2fe095', '#ffe202']"
                    :data="[['Male',metrics.demographics.fans_gender['M']],['Female',metrics.demographics.fans_gender['F']],['Unspecified',metrics.demographics.fans_gender['U']]]"

                    class="flex-center-center" style="flex-direction: column;" :width="270" :height="270"/>
                <div class="d-flex justify_center analytics-pie-legends">
                  <template v-for="(obj,i) in legends['gender-overall-analytics-pie']">
                    <div class="analytics-pie-legends__detail-item" :key="i"
                         @click="legendClick(obj, obj.name !== 'No Data Found')">
                      <div class="analytics-pie-legends__detail-label v-metrics">
                     <span
                         :style="{borderColor: obj.color}"
                         class="analytics-pie-legends__detail-label-icon"
                     ></span> {{ capitalizeFirstLetter(obj.name) }}
                      </div>
                      <div class="analytics-pie-legends__detail-value" v-if="obj.name !== 'No Data Found'">
                        {{ obj.y }}
                      </div>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 ">
              <div class="bordered-box bordered-box--margined">
                <div class="bordered-box__header justify_center mb-0">
                  <h3 class="normal"><b>{{ metrics.demographics.gender[metrics.demographics.max_gender_age.gender] }}</b> between the ages of
                    <b>{{ metrics.demographics.max_gender_age.age }}</b> appear to be the leading force among your
                    followers.</h3>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="8" :total="total"></report-header>
        <div>
          <div class="row">
            <div class="col-6 ">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience Top Countries</h3>
                </div>

                <div class="analytics-audience">

                  <template v-for="(audience_value,audience_key, index) in metrics.demographics.audience_country">
                    <StatsCard v-if="index < 10" :key="index" :title="audience_key" :value="audience_value"
                               variant="country"/>
                  </template>

                </div>
              </div>
            </div>

            <div class="col-6 ">
              <div class="bordered-box bordered-box--contained">
                <div class="bordered-box__header">
                  <h3>Audience Top Cities</h3>
                </div>
                <div class="analytics-audience">
                  <template v-if="index < 10" v-for="(audience_value,audience_key, index) in metrics.demographics.audience_city">
                    <StatsCard  :key="index" :title="audience_key" :value="audience_value" variant="city"/>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="9" :total="total"></report-header>
        <div>
          <h2>Instagram Stories Performance</h2>
          <p>Review how people are interacting with stories that you published during selected date range.</p>
          <div class="row">
            <template v-for="(value,key,i) in metrics.overview.story_rollup_values">
              <div class="col-6 col-sm-4 summary-card-wrapper" :class="{ 'mb-4': i<3 }" :key="key">
                <SummaryCard :key="key" :title="value"
                             :total="metrics.overview.story_performance.stories_rollup.current[key]"
                             :reach="Math.abs(getReach(key, metrics.overview.story_performance.stories_rollup))+'%'"
                             :reachUp="getReachUp(key, metrics.overview.story_performance.stories_rollup)"
                             variant="rollup"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="10" :total="total"></report-header>
        <div>
          <section class=" analytics-fb-audience h-chart-section-wrapper">
            <div class="analytics-section-header">
              <h2>Instagram Stories Performance</h2>
              <p>Review how people are interacting with stories that you published during selected date range.</p>
            </div>
            <div>
              <SplineBasicChart v-if="metrics.overview.story_performance.stories_performance.data.show_data>0"
                              :series="getStoriesPerformanceSeries(metrics.overview.story_performance)"
                              :categories="metrics.overview.story_performance.stories_performance.buckets"/>
              <div v-else class="flex-center-center" style="height: 90%;">
                <div class="analytics-no-data-found ">
                  <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                  <p> No Data Found!</p>
                </div>
              </div>
            </div>
            <div>
              <SplineBasicChart v-if="metrics.overview.story_performance.stories_published.data.show_data>0"
                                :series="getStoriesPublishedSeries(metrics.overview.story_performance)"
                                :categories="metrics.overview.story_performance.stories_published.buckets"/>
              <div v-else class="flex-center-center" style="height: 90%;">
                <div class="analytics-no-data-found ">
                  <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
                  <p> No Data Found!</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header :report_name="'Instagram'" :current="11" :total="total"></report-header>
        <div>
          <h2>Most used hashtags</h2>
          <WordCloud v-if="metrics.overview.hashtags.top_hashtags.length" :series="getHashtagsSeries(metrics.overview.hashtags.top_hashtags)"></WordCloud>
          <div v-else class="flex-center-center" style="height: 90%;">

            <div class="analytics-no-data-found ">
              <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
              <p> No Data Found!</p>
            </div>
          </div>
          <h2>Top Hashtags by Lifetime Engagements</h2>
          <WordCloud v-if="metrics.overview.hashtags.top_engagement_hashtags.length" :series="getHashtagsSeries(metrics.overview.hashtags.top_engagement_hashtags)"></WordCloud>
          <div v-else class="flex-center-center" style="height: 90%;">

            <div class="analytics-no-data-found ">
              <img src="../../assets/imgs/no_data_images/no-analytical-data-available.svg" alt="">
              <p> No Data Found!</p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import instagramMixin from '@src/modules/analytics/components/common/series/instagramMixin'
import PostCard from '@src/modules/analytics/views/common/PostCard'
import StraightLineChart from '@src/modules/analytics/views/common/Infographics/StraightLineChart'
import StatsCard from '@src/modules/analytics/views/common/StatsCard'
import WordCloud from '@src/modules/analytics/views/common/Infographics/WordCloud'

export default {
  mixins: [instagramMixin, analyticsUtilsMixin],
  data () {
    return {
      total: 10,
      summary_values: {
        'followers_count': 'Followers',
        'profile_views': 'Profile Views',
        'engagement': 'Total Engagement',
        'impressions': 'Total Impressions',
        'comments': 'Comments',
        'saved': 'Saved Posts',
        'reactions': 'Total Reactions',
        'reach': 'Total Reach'
      },
      legends: []
    }
  },
  props: {
    metrics: {}

  },
  components: {
    ReportHeader,
    AnalyticsFilterBar,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    SkeletonBox,
    ColumnCrossHairChart,
    SplineBasicChart,
    LineBasicChart,
    PieInnerChart,
    PostCard,
    StatsCard,
    StraightLineChart,
    WordCloud

  },
  computed: {},
  methods: {
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      let tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    }
  }
}
</script>
