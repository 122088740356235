export const htmlMixin = {
  methods: {
    removeHtmlTags(text) {
      // for decoding html entities
      const txt = document.createElement('textarea')
      txt.innerHTML = text
      text = txt.value

      text = text.replace(/(<([^>]+)>)/gi, '')
      text = text.trim()

      return text
    },
  },
}
