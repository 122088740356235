<template>
  <div class="cst-editor__link-preview flex relative z-0">
    <div>
      <img
        class="w-36 h-36 max-w-36 max-h-36 object-cover"
        data-fallback="0"
        :src="getResizedImageURL(image, 250, 0)"
        alt="og:image"
        @error="onError($event)"
      />
    </div>
    <div class="p-3">
      <p class="text-sm font-semibold mb-2">{{ title }}</p>
      <p class="text-sm text-gray-800 mb-1">{{ url }}</p>
      <p class="text-sm text-gray-800">{{ description }}</p>
    </div>

    <i
      class="m-2 w-5 h-5 cs-cross absolute right-0 top-0 rounded-full text-white text-xs flex justify-center items-center cursor-pointer bg-gray-600 hover:bg-gray-700"
      @click="$emit('on-remove', url)"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'EditorLinkPreview',
  props: {
    image: {
      type: [URL, String, Array],
      default: '',
    },
    url: {
      type: String,
      default: '',
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
  },
  mounted() {
    console.debug(
      'EditorLinkPreview mounted',
      this.image,
      this.url,
      this.title,
      this.description
    )
  },
  methods: {
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        `https://storage.googleapis.com/lumotive-web-storage/some-fallback-0.png`,
        `https://storage.googleapis.com/lumotive-web-storage/some-fallback-1.png`,
        `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`,
      ]
      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-editor__link-preview {
  border-top: 1px solid #dee2e6;
}
</style>
