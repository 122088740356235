<template>
  <b-modal
    id="bookmarkModal"
    hide-footer
    hide-header
    modal-class="bookmarkModal  normal_modal"
    @hidden="clearTimeout"
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Bookmark Conversation</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('bookmarkModal')"
        >&times;
      </button>
    </div>

    <div class="modal_body basic_form">
      <div class="conversation_messages">
        <beat-loader v-if="loader" :color="'#436aff'"></beat-loader>
        <template v-else>
          <template v-for="message in messages">
            <template
              v-if="
                message.event_type === 'assigned' ||
                message.event_type === 'marked_done' ||
                message.event_type === 'archived'
              "
            >
              <div
                :id="message.uuid"
                :key="message.uuid"
                class="sep_line conversation_notification"
              >
                <div class="text">
                  <div class="user_block d-flex align-items-center">
                    <div class="user_picture">
                      <div
                        v-if="
                          message.event_details.user_image === '' ||
                          message.event_details.user_image == null
                        "
                        class="img"
                        :style="
                          'background: url(' +
                          commonMethods.getDefaultImage() +
                          ')'
                        "
                      ></div>
                      <div
                        v-else
                        class="img"
                        :style="
                          'background: url(' +
                          message.event_details.user_image +
                          ')'
                        "
                      ></div>
                    </div>
                    <div class="user_content">
                      <p class="name"
                        ><strong>{{ message.event_details.user_name }}</strong
                        >{{ message.message
                        }}<strong>{{
                          message.event_type === 'assigned'
                            ? message.assigned_to !== null
                              ? message.assigned_to.user_name + '.'
                              : ''
                            : ''
                        }}</strong>
                        <span style="font-size: 12px">
                          -
                          {{
                            commonMethods.timesAgo(message.created_time)
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else
              :id="message.uuid"
              :key="message.uuid"
              class="message"
              :class="[
                commonMethods.isIncomingMessage(temp, message)
                  ? 'm_in'
                  : 'm_out',
                bookmarkedMessage.uuid === message.uuid
                  ? 'highlighted activeMessage animated shake'
                  : '',
              ]"
            >
              <div
                class="profile_picture d-flex align-items-start"
                :class="[
                  commonMethods.isIncomingMessage(temp, message)
                    ? ''
                    : 'justify-content-end',
                ]"
              >
                <div
                  v-if="commonMethods.isIncomingMessage(temp, message)"
                  class="picture_block picture_block_background"
                >
                  <div
                    class="img"
                    :style="
                      'background: url(' +
                      commonMethods.getImageForUser(temp, message) +
                      ')'
                    "
                  >
                  </div>
                </div>
                <div class="text_block">
                  <!---->
                  <template
                    v-if="
                      message.attachment != null &&
                      message.attachment.length > 0
                    "
                  >
                    <div
                      v-for="attachment in message.attachment"
                      :key="attachment.id"
                    >
                      <template v-if="attachment.type === 'text/plain'">
                        <div
                          class="__text"
                          :class="[
                            commonMethods.isIncomingMessage(temp, message)
                              ? '_user_chat'
                              : '',
                          ]"
                        >
                          <div class="_file d-flex align-items-start">
                            <div class="file_icon">
                              <i class="fal fa-file-pdf"></i>
                            </div>
                            <div class="file_detail">
                              <p class="name">{{ attachment.name }}</p>
                            </div>
                          </div>
                        </div>
                        <p class="sub_text">
                          {{ commonMethods.timesAgo(message.created_time) }}</p
                        >
                      </template>

                      <template v-else>
                        <template v-if="attachment.type.indexOf('image') >= 0">
                          <div
                            class="__text"
                            :class="[
                              commonMethods.isIncomingMessage(temp, message)
                                ? '_user_chat'
                                : '',
                            ]"
                          >
                            <div class="_images">
                              <img
                                v-img
                                :src="attachment.url"
                                height="100"
                              />
                            </div>
                          </div>
                        </template>
                        <template
                          v-else-if="attachment.type.indexOf('video') >= 0"
                        >
                          <div
                            class="__text"
                            :class="[
                              commonMethods.isIncomingMessage(temp, message)
                                ? '_user_chat'
                                : '',
                            ]"
                          >
                            <div class="_video">
                              <video controls>
                                <source
                                  :src="attachment.url"
                                  type="video/mp4"
                                />
                                <source
                                  :src="attachment.url"
                                  type="video/ogg"
                                />
                                Your browser does not support HTML5 video.
                              </video>
                            </div>
                          </div>
                        </template>
                        <template
                          v-else-if="attachment.type.indexOf('audio') >= 0"
                        >
                          <div
                            class="__text"
                            :class="[
                              commonMethods.isIncomingMessage(temp, message)
                                ? '_user_chat'
                                : '',
                            ]"
                          >
                            <div class="">
                              <audio id="myAudio" controls>
                                <source
                                  :src="attachment.url"
                                  type="audio/ogg"
                                />
                                <source
                                  :src="attachment.url"
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          </div>
                        </template>
                        <p class="sub_text">{{
                          commonMethods.timesAgo(message.created_time)
                        }}</p>
                      </template>
                    </div>
                    <span class="bookmarked bottom"
                      ><i
                        v-if="message.is_starred"
                        class="fa fa-star marked"
                      ></i
                    ></span>
                  </template>
                  <template v-else-if="message.event_type === 'note'">
                    <div
                      class="__text _user_chat"
                      style="background-color: rgb(251, 252, 225)"
                      >{{ message.message }}</div
                    >
                    <p class="sub_text">{{
                      commonMethods.timesAgo(message.created_time)
                    }}</p
                    ><span class="bookmarked bottom"
                      ><i
                        v-if="message.is_starred"
                        class="fa fa-star marked"
                      ></i
                    ></span>
                  </template>
                  <template v-else-if="message.sticker">
                    <div class="__text _user_chat">
                      <div class="sticker">
                        <img :src="message.sticker" />
                      </div>
                    </div>
                    <p class="sub_text">{{
                      commonMethods.timesAgo(message.created_time)
                    }}</p
                    ><span class="bookmarked bottom"
                      ><i
                        v-if="message.is_starred"
                        class="fa fa-star marked"
                      ></i
                    ></span>
                  </template>
                  <template v-else>
                    <div class="__text _user_chat">{{ message.message }}</div>
                    <p class="sub_text">{{
                      commonMethods.timesAgo(message.created_time)
                    }}</p
                    ><span class="bookmarked bottom"
                      ><i
                        v-if="message.is_starred"
                        class="fa fa-star marked"
                      ></i
                    ></span>
                  </template>
                </div>

                <div
                  v-if="
                    !commonMethods.isIncomingMessage(temp, message) &&
                    message.event_type !== 'assigned' &&
                    message.event_type !== 'marked_done' &&
                    message.event_type !== 'archived'
                  "
                  class="picture_block picture_block_background"
                >
                  <div
                    class="img"
                    :style="
                      'background: url(' +
                      commonMethods.getImageForUser(temp, message) +
                      ')'
                    "
                  >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <!--<div class="input_field">-->
      <!--<button class="btn gradient_btn large_btn  ">-->
      <!--<span>Send</span>-->
      <!--</button>-->
      <!--</div>-->
    </div>
  </b-modal>
</template>

<style lang="less" scoped>
.bookmarkModal {
  .basic_form {
    height: 77vh;
    overflow: scroll;
  }
}

.sticker {
  width: 100px;
  height: 100px;
  text-align: center;
}
.sticker img {
  max-width: 100%;
  margin: 0 auto;
  max-height: 100%;
}

.conversation_notification {
  border-bottom: 0;
  margin: 30px auto;

  .text {
    top: 0;
  }

  .user_block {
    align-content: center;
    justify-content: center;

    .user_picture .img {
      height: 22px;
      width: 22px;
      min-height: 22px;
      min-width: 22px;
      border-radius: 50%;
      -webkit-background-size: cover !important;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .user_content {
      margin-left: 6px;

      .name {
        color: #989eb5;
        font-weight: normal;
        font-size: 13px;

        strong {
          font-weight: 500;
          color: #3a4557;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import { commonMethods } from '../store/common-methods'

export default {

  components: {},
  props: ['bookmarkedMessage', 'activeInboxDetail'],
  data () {
    return {
      temp: this,
      messages: [],
      commonMethods: commonMethods,
      loader: true,
      cleartTimeout: ''
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace'])
  },

  mounted () {
    // console.log(this.bookmarkedMessage)
    this.filteredBookmarkMessages()
  },
  created () {},

  methods: {
    ...mapActions(['fetchFilteredBookmarkMessages']),

    clearTimeout () {
      clearTimeout(this.cleartTimeout)
    },
    async filteredBookmarkMessages () {
      const response = await this.fetchFilteredBookmarkMessages({
        workspace_id: this.bookmarkedMessage.workspace_id,
        platform_id: this.bookmarkedMessage.platform_id,
        created_time: this.bookmarkedMessage.created_time,
        element_id: this.bookmarkedMessage.element_id
      })
      // console.log(response)

      if (response.isValid) {
        this.messages = response.response
        this.cleartTimeout = setTimeout(() => {
          const activeDomElement = document.querySelector('.activeMessage')
          if (activeDomElement)
            activeDomElement.classList.remove('highlighted')
        }, 3000)
      } else {
        this.messages = []

        // commonMethods.showToast(this, 3, response.message)
      }
      this.loader = false
    }
  },

  watch: {
    bookmarkedMessage: async function (newVal, oldVal) {
      // watch it
      // console.log(newVal)
      this.filteredBookmarkMessages()
    }
  }
}
</script>
