<template>
  <div class="topics-insights-container__row">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    block_size: {
      default: 'engagement_rate_block_3_1'
    }
  }
}
</script>
