<style lang="less">
@import '../../../../assets/less/third-party/slick.less';
</style>

<script>
import VideoModal from '@common/components/dialogs/onboarding/VideoModal'
import { mapGetters, mapActions } from 'vuex'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import {
  publish,
  blogPosting,
} from '@src/modules/publish/store/states/mutation-types'
import LabelOptions from '../../../publish/components/options/LabelOptions'
import MemberOptions from '../../../publish/components/options/MemberOptions'

import VideoPreview from '../../../discovery/components/common/preview/VideoPreview'
import ImagePreview from '../../../discovery/components/common/preview/ImagePreview'
import ArticlePreview from '../../../discovery/components/common/preview/ArticlePreview'
import PocketPreview from '../../../discovery/components/common/preview/PocketPreview'

import BlogReviewPublish from '../../../publish/components/posting/blog/BlogReviewPublish'
import BlogDistribute from '../../../publish/components/posting/blog/BlogDistribute'

import CreatePublicationFolder from '../CreatePublicationFolder'

import UploadMediaModal from '../../../publish/components/media-library/components/UploadMediaModal'
import BlogSocialCampaign from './BlogSocialCampaign'
import BlogOptimize from './BlogOptimize'

import BlogPostEditor from './BlogPostEditor'
import Helper from '@/src/modules/discovery/components/share-sidebar/Helper.vue'
import {EventBus} from "@common/lib/event-bus";

const Comments = () =>
  import('../../../publish/components/posting/collaboration/Comments')

const Assistant = () =>
  import('../../../discovery/components/share-sidebar/Assistant')
const BlogArticlePreview = () =>
  import('../../../discovery/components/share-sidebar/BlogArticlePreview')
const HistoryVersion = () =>
  import('../../../discovery/components/share-sidebar/HistoryVersion')

const Seo = () => import('../../../discovery/components/share-sidebar/Seo')
const Tasks = () =>
  import('../../../publish/components/posting/collaboration/Tasks')
const Activities = () =>
  import('../../../publish/components/posting/collaboration/Activities')
// const ContentRewritingSectionModal = () => import('../../../../publish/components/posting/blog/ContentRewritingSectionModal')
const InsertFileModal = () =>
  import('../../../publish/components/widget/file/InsertFileModal')
const PasteFromWord = () =>
  import('../../../publish/components/widget/file/PasteFromWord')
export default {
  components: {
    UploadMediaModal,
    PasteFromWord,
    InsertFileModal,
    // ContentRewritingSectionModal,
    BlogArticlePreview,
    HistoryVersion,
    LabelOptions,
    VideoPreview,
    ArticlePreview,
    PocketPreview,
    VideoModal,
    ImagePreview,
    BlogDistribute,
    BlogOptimize,
    BlogReviewPublish,
    BlogSocialCampaign,
    MemberOptions,
    Helper,
    Assistant,
    Seo,
    Comments,
    Tasks,
    Activities,
    CreatePublicationFolder,
    BlogPostEditor,
  },

  data() {
    return {
      closeTooltipStatus: false,
      utm_presets: 'create',
      hashtag_select: 'saved',
      schedule_radio: 'post_now_r',
      repeat_post: 'no_repeat',
      blogShareTabStatus: '',
      toggleModalSidebarStatus: false,
      // blogPublicationInterval: '',

      validations: {
        postTitle: false,
      },
      messages: {
        postTitle: 'Please enter post title',
      },
      previousRoute: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => (vm.previousRoute = from))
  },

  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getPublishSelection',
      'getPublicationLastUpdated',
      'getPublicationFolders',
      'getWorkspaces',
      'getPublishLoaders',
      'getPlannerLoaders',
      'getBlogAutoSaveInterval',
      'getEditorTypingInterval',
      'getEditorTypingStatus',
      'getComposerActivePlanMember',
      'getSocketId',
      'getProfile',
      'getPlanControlRequestData',
      'getPlanControl',
      'getComments',
      'getCommentsLoader',
      'getTasks',
      'getTaskscCompletedCount',
      'getPublications',
      'getBlogPostingHTML',
    ]),
    checkPostStatus() {
      return (
        this.getPublishSelection.status !== '' ||
        this.getPublishSelection.repost ||
        this.getPublishSelection.reopen
      )
    },
    checkPlanRequestReceived() {
      const status =
        this.getPlanControlRequestData &&
        this.getPlanControlRequestData.receiver_user_id &&
        this.getPlanControlRequestData.receiver_user_id === this.getProfile._id
      if (status) this.$bvModal.show('lockStatusModal')
      return status
    },
    checkRequestAlreadySent() {
      return (
        this.getPlanControlRequestData &&
        this.getPlanControlRequestData.requested_user_id === this.getProfile._id
      )
    },
    getDefaultCampaignName() {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.default_campaign_name
      ) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign'
    },
  },

  watch: {
    'getPublishSelection.folderId'() {
      this.blogPostAutoSave()
    },

    'getBlogPostingDetails.title'(value) {
      if (value && value.length > 0) {
        this.validations.postTitle = false
      }
      this.emitBlogChanges()
    },

    'getBlogPostingDetails.optionalDescription'() {
      this.emitBlogChanges()
    },

    getPlanControl(value) {
      if (value === false) {
        this.closeTooltipStatus = true
        setTimeout(
          function () {
            this.closeTooltipStatus = false
          }.bind(this),
          5000
        )

        // eslint-disable-next-line no-undef
        $('.assistant_main_block .dragable_box').draggable('disable')
      } else {
        this.closeTooltipStatus = false
        // eslint-disable-next-line no-undef
        $('.assistant_main_block .dragable_box').draggable('enable')
      }
    },

    // 'getPublishSelection.label' (value) {
    //   this.emitBlogActivities('label', value)
    // }

    // 'getComments' (value) {
    //     console.debug('changeComment')
    //       this.emitBlogActivities('comment')
    // }
  },

  created() {
    // this.$store.commit(composer.SET_PLAN_CONTROL, true)
    // if (this.checkPostStatus) this.setSocialLoadPlanStatus(false)
    if (this.$route.params.type) {
      let type = null
      if (this.$route.params.typeId) {
        if (this.$route.params.type === 'comment') type = 'comment'
        if (this.$route.params.type === 'task') type = 'task'
      } else {
        if (this.$route.params.type === 'comments') type = 'comment'
        if (this.$route.params.type === 'tasks') type = 'task'
      }
      if (type) this.socialShareTab(type, true)
    }
    this.initializeComposerBlogSection()
    this.broadcastPlanEditStatus()

    if (this.getPublications.folder.id && !this.getPublishSelection.folderId) {
      this.setPublicationFolderId(this.getPublications.folder.id)
    }
  },

  mounted() {
    const thiss = this
    setTimeout(function () {
      // eslint-disable-next-line no-undef
      $('.blog_article_preview,.history_version_modal').on(
        'hidden.bs.modal',
        function () {
          thiss.blogShareTabStatus = ''
        }
      )
    }, 1000)
    window.webSockets.on('disconnect', () => {
      console.log('ComposerMain: Broadcasting all events again.')
      this.loadUserBroadCasters()
    })
  },
  beforeDestroy() {
    window.webSockets.removeListener('disconnect')

    // to destroy interval using for saving publication
    if (this.getBlogAutoSaveInterval) {
      window.clearTimeout(this.getBlogAutoSaveInterval)
      this.$store.commit(composer.SET_BLOG_AUTO_SAVE_INTERVAL, null)
    }

    if (this.getEditorTypingInterval) {
      window.clearTimeout(this.getEditorTypingInterval)
      this.$store.commit(composer.SET_EDITOR_TYPING_INTERVAL, null)
    }
    this.$store.commit(composer.SET_EDITOR_TYPING_STATUS, false)
    this.closePlanEmit(this.getPublishSelection.plan_id)
  },
  methods: {
    ...mapActions(['setPublicationFolderId']),
    openCreateFolderModal() {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
      EventBus.$emit('createFolder')
    },
    closeTooltip() {
      this.closeTooltipStatus = false
    },
    toggleModalSidebar() {
      if (!this.toggleModalSidebarStatus && this.blogShareTabStatus === '') {
        this.blogShareTabStatus = 'assistant'
      }
      this.toggleModalSidebarStatus = !this.toggleModalSidebarStatus
    },

    socialShareTab(status, initialize = false) {
      console.log('status', status)
      console.log('initialize', initialize)
      this.toggleModalSidebarStatus = true
      this.blogShareTabStatus = status
      if (!initialize) {
        switch (status) {
          case 'comment':
            this.$router.push({
              name: 'composerBlog',
              params: { id: this.$route.params.id, type: 'comments' },
            })
            break
          case 'task':
            this.$router.push({
              name: 'composerBlog',
              params: { id: this.$route.params.id, type: 'tasks' },
            })
            break
          default:
            this.$router.push({
              name: 'composerBlog',
              params: { id: this.$route.params.id },
            })
        }
      }
      this.checkComposerDragDrop()
    },

    previewBlogCuration(status) {
      console.debug('Method:previewBlogCuration')
      this.blogShareTabStatus = status
      this.toggleModalSidebarStatus = false
      // this.updateBlogPreview(200)
      // this.checkComposerDragDrop()

      if (this.getBlogPostingHTML.length) {
        this.$store.commit(
          blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
          false
        )
      } else {
        this.$store.commit(
          blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
          true
        )
      }
      window.iframely && window.iframely.load()
      // eslint-disable-next-line no-undef
      $('#blog_article_preview').modal('show')
    },

    historyVersionModal(status) {
      console.debug('Method:historyVersionModal')
      this.blogShareTabStatus = status
      this.toggleModalSidebarStatus = false
      if (this.getPublishSelection.plan_id) {
        this.$store.dispatch(
          'planVersionHistory',
          this.getPublishSelection.plan_id
        )
        // eslint-disable-next-line no-undef
        $('#history_version_modal').modal('show')
      }
    },

    changeBlogStage(stage) {
      this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, stage)
    },
    proceedBlogStage() {
      const currentStage = this.getBlogPostingDetails.stage
      switch (currentStage) {
        case 'content':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'settings')
          break
        case 'settings':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'distribution')
          break
        case 'distribution':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'publish')
          break
      }
    },
    validateProcessBlogPost(draft = false) {
      console.debug('Method:validateProcessBlogPost')
      if (this.getBlogPostingDetails.title.trim() === '') {
        this.validations.postTitle = true
        return false
      }
      if (draft) this.processBlogPost(true, true)
      else this.processBlogPost()
    },

    getPlanViewers() {
      const stateObject = this
      const userIds = []
      const usersData = []
      this.getComposerActivePlanMember.forEach(function (element) {
        if (userIds.indexOf(element.user_id) === -1) {
          userIds.push(element.user_id)
          stateObject.getWorkspaces.activeWorkspace.members.filter((member) => {
            if (member.user) {
              if (member.user._id === element.user_id) {
                usersData.push(member.user)
              }
            }
          })
        }
      })
      return usersData
    },

    checkPlanControl() {
      console.debug('Method:checkPlanControl')
      const socketId = this.getSocketId
      const composerActivePlanMember = this.getComposerActivePlanMember
      if (
        socketId &&
        composerActivePlanMember &&
        composerActivePlanMember.length > 0
      ) {
        const planControl = composerActivePlanMember.find((item) => {
          return item.control === true
        })
        if (planControl) {
          if (planControl.socket_id === socketId) {
            this.$store.commit(composer.SET_PLAN_CONTROL, true)
            return true
          } else if (planControl.user_id === this.getProfile._id) {
            this.$store.commit(composer.SET_PLAN_CONTROL, false)
            return 'otherTab'
          }
          this.$store.commit(composer.SET_PLAN_CONTROL, false)
          return 'otherUser'
        }
        this.$store.commit(composer.SET_PLAN_CONTROL, false)
        return 'none'
      }
      this.$store.commit(composer.SET_PLAN_CONTROL, true)
      return true
    },

    takePlanControl() {
      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
      }
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          data: data,
        },
        'takePlanControl'
      )

      // this.emitSocket('take_plan_control', data)
      window.webSockets.emit('take_plan_control', data)
      this.$bvModal.hide('lockStatusModal')
    },

    requestPlanControl() {
      this.$store.commit(composer.SET_PLAN_CONTROL_REQUEST_DATA, null)
      let userId = ''
      const planControl = this.getComposerActivePlanMember.find((item) => {
        return item.control === true
      })
      if (planControl) userId = planControl.user_id

      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
        requested_user_id: this.getProfile._id,
        receiver_user_id: userId,
      }
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          data: data,
        },
        'request_plan_control'
      )

      window.webSockets.emit('request_plan_control', data)
      // this.emitSocket('request_plan_control', data)
    },

    planControlUsername() {
      const planControl = this.getComposerActivePlanMember.find((item) => {
        return item.control === true
      })
      if (planControl) {
        let user = null
        this.getWorkspaces.activeWorkspace.members.filter((member) => {
          if (member.user && member.user._id === planControl.user_id) {
            user = member.user
          }
        })
        // if (user && user.firstname) return user.firstname + ' ' + user.lastname
        if (user && user.firstname) return user
      }
      return 'user'
    },

    getRequestedUsername() {
      const getPlanControlRequestData = this.getPlanControlRequestData
      if (getPlanControlRequestData) {
        let user = null
        this.getWorkspaces.activeWorkspace.members.filter((member) => {
          if (
            member.user &&
            member.user._id === getPlanControlRequestData.requested_user_id
          ) {
            user = member.user
          }
        })
        if (user) return user
      }
      return 'user'
    },

    acceptRequestPlanControl() {
      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
        requested_socket_id: this.getPlanControlRequestData.socket_id,
      }
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          data: data,
        },
        'accept_request_plan_control'
      )
      window.webSockets.emit('accept_request_plan_control', data)
      // this.emitSocket('accept_request_plan_control', data)
    },

    cancelRequestPlanControl() {
      const data = {
        plan_id: this.getPublishSelection.plan_id,
      }
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          data: data,
        },
        'cancel_request_plan_control'
      )
      window.webSockets.emit('cancel_request_plan_control', data)
      // this.emitSocket('cancel_request_plan_control', data)
    },

    checkSelected(folderId) {
      return !!(this.getPublishSelection.folderId === folderId)
    },
    selectCampaign(folderId) {
      if (
        this.getPublishSelection.folderId &&
        this.getPublishSelection.folderId === folderId
      ) {
        this.setPublicationFolderId(null)
      } else this.setPublicationFolderId(folderId)
    },
    lockTooltipMessage() {
      const control = this.checkPlanControl()
      if (control === 'otherTab') {
        return 'You are editing this story in another tab, window or device.'
      } else if (control === 'none') {
        return 'No one is editing the story.'
      } else if (control === 'otherUser') {
        return (
          '<strong>' +
          this.planControlUsername().firstname +
          ' ' +
          this.planControlUsername().lastname +
          '</strong> is editing the story.'
        )
      }
      this.closeTooltipStatus = false
      return ''
    },
    selectCampaignForPost(folderId) {
      console.debug('Method:selectCampaignForPost', folderId)
      const folderValue =
        this.getPublishSelection.folderId &&
        this.getPublishSelection.folderId === folderId
          ? null
          : folderId
      console.debug('folder value is ', folderValue)
      this.$store.commit(publish.SET_PUBLISH_CAMPAIGN, folderValue)
    },
  },
}
</script>

<template>
  <div>
    <b-modal
      id="lockStatusModal"
      no-close-on-backdrop
      modal-class="alertBox lockStatusModal"
      hide-footer
      hide-header
    >
      <button
        v-if="checkPlanRequestReceived"
        type="button"
        class="close"
        data-dismiss="modal"
        @click.prevent="cancelRequestPlanControl"
        >&times;
      </button>
      <button
        v-else
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('lockStatusModal')"
        >&times;</button
      >
      <div class="modal_body">
        <template v-if="checkPlanRequestReceived">
          <div class="col-sm-12 alert_content">
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>

              <div class="profile_picture d-flex align-items-start">
                <div class="picture_block picture_block_background">
                  <div
                    v-if="teamMemberImageById(getRequestedUsername()._id)"
                    class="img"
                    :style="{
                      background:
                        'url(' +
                        teamMemberImageById(getRequestedUsername()._id) +
                        ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </div>
                <div class="text_block">
                  <p class="text"
                    ><strong
                      >{{ getRequestedUsername().firstname }}
                      {{ getRequestedUsername().lastname }}</strong
                    >
                    would like to start editing now. If you're ready to take a
                    break, you can grant access now. If not, cancel this request
                    and they'll get access when you're done.</p
                  >
                </div>
              </div>

              <!---->
              <!--<p>{{getRequestedUsername()}} would like to start editing now. If you're ready to take a break, you can grant access now.-->
              <!--If not, cancel this request and they'll get access when you're done.</p>-->
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click.prevent="cancelRequestPlanControl"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="acceptRequestPlanControl"
              >
                <span>Accept</span>
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="checkPlanControl() === 'otherTab'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <p
                >You are editing this story in another tab, window or device.</p
              >
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Take Over</span>
              </button>
            </div>
          </div>

          <div
            v-else-if="checkPlanControl() === 'none'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <p
                >No one is editing the story. Take control by pressing the
                button below.</p
              >
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Take Over</span>
              </button>
            </div>
          </div>

          <div
            v-else-if="checkPlanControl() === 'otherUser'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <div class="profile_picture d-flex align-items-start">
                <div class="picture_block picture_block_background">
                  <div
                    v-if="teamMemberImageById(planControlUsername()._id)"
                    class="img"
                    :style="{
                      background:
                        'url(' +
                        teamMemberImageById(planControlUsername()._id) +
                        ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </div>
                <div class="text_block">
                  <p class="text"
                    ><strong
                      >{{ planControlUsername().firstname }}
                      {{ planControlUsername().lastname }}</strong
                    >
                    is already editing this post. Do you want to take over?</p
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                :disabled="checkRequestAlreadySent"
                @click.prevent="requestPlanControl"
              >
                <span>Request Access</span>
              </button>
              <button
                v-if="checkRequestAlreadySent"
                class="btn force_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Force Take Over</span>
              </button>
            </div>
          </div>

          <!--<div class="col-sm-12 alert_content" v-else>-->
          <!--<div class=" col-sm-12 alert_content">-->
          <!--<i class="fa fa-lock"></i>-->
          <!--<p>Something went wrong.</p>-->
          <!--</div>-->
          <!--<div class="col-sm-12 footer_box">-->
          <!--<button  @click="$bvModal.hide('lockStatusModal')" class="btn btn_cancel" >-->
          <!--<span>Close</span>-->
          <!--</button>-->
          <!--</div>-->
          <!--</div>-->
        </template>
      </div>
    </b-modal>
    <!--<comment-image-preview></comment-image-preview>-->

    <template v-if="blogShareTabStatus === 'assistant'">
      <VideoPreview></VideoPreview>
      <ImagePreview></ImagePreview>

      <ArticlePreview :section-name="'composerSection'"></ArticlePreview>
      <PocketPreview></PocketPreview>
    </template>

    <VideoModal id="create-blog-post" position="bottom_left">
      <template slot="heading">
        <h2>Publish Blog Post</h2>
      </template>
      <template slot="description">
        <!--<h2>To begin using the discovery, you can use either a search section or create your tailored topics according to your needs.</h2>-->
        <!-- , connect a social
                    network <span class="bold">(Facebook, Twitter, LinkedIn, Pinterest, Tumblr)</span> or
                    a blog channel <span class="bold">(WordPress, Medium, Tumblr)</span>.-->
      </template>
      <template slot="iframe">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/s4Sn9393eOI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>

    <BlogArticlePreview></BlogArticlePreview>
    <HistoryVersion></HistoryVersion>
    <UploadMediaModal type="BlogEditorFile" />
    <InsertFileModal type="BlogEditorFile"></InsertFileModal>
    <PasteFromWord></PasteFromWord>
    <!--<content-rewriting-section-modal></content-rewriting-section-modal>-->
    <CreatePublicationFolder></CreatePublicationFolder>

    <div class="composer_component composer_step_page top_left_design_box">
      <div class="component_inner">
        <template v-if="getPlannerLoaders.edit_plan">
          <div class="c_header d-flex align-items-center">
            <div class="head_left">
              <h2>Blog Post Composer</h2>
            </div>
          </div>
          <div class="c_body basic_form">
            <div class="height page_content">
              <div class="height d-flex align-items-stretch">
                <div class="content">
                  <div
                    class="content_container content_container_drop"
                    style="max-width: 900px"
                  >
                    <beat-loader :color="'#436aff'"></beat-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="z-index: 3" class="c_header d-flex align-items-center">
            <div class="head_left">
              <div class="c_header_detail">
                <div class="input_validation">
                  <input
                    id="c_title"
                    v-model="getBlogPostingDetails.title"
                    class="c_title"
                    type="text"
                    placeholder="Post Name"
                    name="title"
                    :disabled="!getPlanControl"
                    data-cy="blog-title"
                  />
                  <span v-if="validations.postTitle" class="input-error">
                    {{ messages.postTitle }}
                  </span>
                </div>

                <input
                  v-model="getBlogPostingDetails.optionalDescription"
                  class="c_desc"
                  type="text"
                  placeholder="A brief description about the post (Optional)"
                  name="description"
                  :disabled="!getPlanControl"
                  data-cy="blog-desc"
                />
              </div>
            </div>

            <div class="head_right ml-auto d-flex align-items-center">
              <div
                :class="{ locked_status: !getPlanControl }"
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown label_dropdown profiles_list_header"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <div
                      v-if="
                        getPublishSelection.label &&
                        getPublishSelection.label.length > 0
                      "
                      class="circle_boxes_inline circle_boxes"
                    >
                      <div
                        v-for="(labelId, key) in getPublishSelection.label"
                        :key="key"
                        :class="getLabelColorById(labelId) + ' c_box'"
                      ></div>
                    </div>

                    <span v-else class="icon_first">
                      <i class="icon_first icon-Label"></i>
                    </span>

                    <span class="text">Labels</span>

                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <LabelOptions></LabelOptions>
                </div>
              </div>

              <div
                :class="{ locked_status: !getPlanControl }"
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown social_dropdown profiles_list_header"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <div
                      v-if="
                        getPublishSelection.members &&
                        getPublishSelection.members.length > 0
                      "
                      class="circle_boxes_inline circle_boxes"
                    >
                      <template
                        v-for="(member, pKey) in getWorkspaces.activeWorkspace
                          .members"
                      >
                        <template
                          v-for="(
                            selected_member, key
                          ) in getPublishSelection.members"
                        >
                          <div
                            v-if="member.user_id === selected_member"
                            :key="key + pKey"
                            v-tooltip="{
                              content: teamMemberTooltipHtmlById(member),
                              classes: 'team_tooltip',
                            }"
                            class="c_box"
                          >
                            <div
                              v-if="member.user.image"
                              class="c_img_box"
                              :style="{
                                background: 'url(' + member.user.image + ')',
                              }"
                            ></div>
                            <div
                              v-else
                              class="c_img_box"
                              style="
                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                              "
                            ></div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <span v-else class="icon_first">
                      <i class="icon-profile-cs"></i>
                    </span>

                    <span class="text">Members</span>
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <MemberOptions></MemberOptions>
                </div>
              </div>

              <div
                v-if="getBlogPostingDetails.type === 'Composer Article'"
                :class="{ locked_status: !getPlanControl }"
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown checkbox_dropdown profiles_list_header"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <span class="icon_first">
                      <i class="icon-Campaign"></i>
                    </span>
                    <span class="text">Campaigns</span>
                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div
                  style="min-width: 250px"
                  class="dropdown-menu dropdown-menu-right"
                >
                  <ul class="inner">
                    <li class="list_item_li">
                      <div class="radio_input_image">
                        <input
                          id="defaultFolder"
                          :checked="checkSelected(null)"
                          type="radio"
                          @click.prevent="selectCampaign(null)"
                        />
                        <label for="defaultFolder" class="radio_left">
                          {{ getDefaultCampaignName }}
                        </label>
                      </div>
                    </li>
                    <template v-if="getPublicationFolders.length">
                      <li
                        v-for="(folder, key) in getPublicationFolders"
                        :key="key"
                        class="list_item_li"
                      >
                        <div class="radio_input_image">
                          <input
                            :id="folder._id"
                            :checked="checkSelected(folder._id)"
                            :value="folder._id"
                            type="radio"
                            @click.prevent="selectCampaignForPost(folder._id)"
                          />
                          <label :for="folder._id" class="radio_left">
                            {{ folder.name }}
                          </label>
                        </div>
                      </li>
                    </template>
                    <!--<li class="no_text_li" v-if="getPublicationFolders.length < 1">-->
                    <!--No campaign found.-->
                    <!--</li>-->
                    <li
                      class="add-new-camp"
                      data-cy="create-campaign"
                      @click.prevent="openCreateFolderModal"
                    >
                      + Add New Campaign
                    </li>
                  </ul>
                </div>
              </div>

              <!--                            <router-link class="d-flex align-items-center justify-content-center close ml-4"-->
              <!--                                         :to="{'name': 'composer'}">&times;-->
              <!--                            </router-link>-->
              <a
                href="javascript:;"
                class="close_icon float_right"
                @click.prevent="composerClose(previousRoute)"
              >
                &times;
              </a>
            </div>
          </div>
          <div class="c_sidebar">
            <ul>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'assistant' }"
                @click="socialShareTab('assistant')"
              >
                <a href="javascript:;">
                  <i class="far fa-th"></i>
                  <span class="text">Assistant</span>
                </a>
              </li>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'seo' }"
                @click="socialShareTab('seo')"
              >
                <a href="javascript:;">
                  <i class="icon-Search"></i>
                  <span class="text">Seo</span>
                </a>
              </li>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'task' }"
                @click="socialShareTab('task')"
              >
                <a href="javascript:;">
                  <i class="far fa-tasks"></i>
                  <span class="text">Tasks</span>
                  <!--TODO::ARIF-->
                  <span
                    v-if="
                      getTasks && getTasks.length - getTaskscCompletedCount > 0
                    "
                    class="count"
                    >{{ getTasks.length - getTaskscCompletedCount }}</span
                  >
                </a>
              </li>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'comment' }"
                @click="socialShareTab('comment')"
              >
                <a href="javascript:;">
                  <i class="far fa-comment"></i>
                  <span class="text">Comments</span>
                  <!--TODO::ARIF-->
                  <span
                    v-if="
                      !getCommentsLoader &&
                      getComments &&
                      getComments.length > 0
                    "
                    class="count"
                    >{{ getComments.length }}</span
                  >
                </a>
              </li>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'preview' }"
                @click.prevent="previewBlogCuration('preview')"
              >
                <a href="javascript:;">
                  <i class="far fa-eye"></i>
                  <span class="text">Preview</span>
                </a>
              </li>
              <li
                class=""
                :class="{ active: blogShareTabStatus === 'activity' }"
                @click.prevent="socialShareTab('activity')"
              >
                <a href="javascript:;">
                  <i class="far fa-list"></i>
                  <span class="text">Activities</span>
                </a>
              </li>

              <li
                class=""
                :class="{ active: blogShareTabStatus === 'history' }"
                @click.prevent="historyVersionModal('history')"
              >
                <a href="javascript:;">
                  <i class="far fa-history"></i>
                  <span class="text">Version History</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="c_body basic_form blog_steps_common">
            <div
              class="height page_content"
              :class="{ open: toggleModalSidebarStatus }"
            >
              <div class="height d-flex align-items-stretch">
                <div class="content">
                  <div
                    class="content_container"
                    :class="{ locked_status: !getPlanControl }"
                  >
                    <div class="steps_arrow_links composer_steps_arrow">
                      <ul>
                        <li
                          :class="{
                            active: getBlogPostingDetails.stage === 'content',
                          }"
                          data-cy="write"
                          @click.prevent="changeBlogStage('content')"
                          >Write
                        </li>
                        <li class="arrow_right_gray cs-angle-right"> </li>
                        <li
                          :class="{
                            active: getBlogPostingDetails.stage === 'settings',
                          }"
                          data-cy="optimize"
                          @click.prevent="changeBlogStage('settings')"
                          >Optimize
                        </li>
                        <li class="arrow_right_gray cs-angle-right"> </li>
                        <li
                          :class="{
                            active:
                              getBlogPostingDetails.stage === 'distribution',
                          }"
                          data-cy="distribute"
                          @click.prevent="changeBlogStage('distribution')"
                          >Distribute
                        </li>
                        <li class="arrow_right_gray cs-angle-right"> </li>
                        <li class="arrow_right_gray hide cs-angle-right"> </li>
                        <li
                          :class="{
                            active: getBlogPostingDetails.stage === 'publish',
                          }"
                          data-cy="review-publish"
                          @click.prevent="changeBlogStage('publish')"
                          >Review & Publish
                        </li>
                      </ul>
                      <!--v-tooltip="{ content:accountTooltipHtml('influencer',influencer) , classes: 'team_tooltip' }"-->
                      <div class="time_text d-flex align-items-center">
                        <!--"show" class use to toggle hide/show .......-->
                        <div
                          v-if="!getPlanControl"
                          :class="{ show: closeTooltipStatus }"
                          class="custom_tooltip_click isHover"
                        >
                          <div
                            v-b-modal.lockStatusModal
                            class="mr-0 btn btn_locked"
                          >
                            <i class="fa fa-lock"></i>
                          </div>

                          <div class="tool_tip_box bottom_center">
                            <div class="tool_tip_inner">
                              <span class="close_tooltip" @click="closeTooltip"
                                >&times;</span
                              >
                              <p v-html="lockTooltipMessage()"></p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="mr-1 ml-2 d-flex align-items-center active_profile_list"
                        >
                          <div
                            v-for="(user, index) in getPlanViewers()"
                            :key="index"
                            class="profile_picture d-flex align-items-start"
                            :class="{
                              first:
                                getEditorTypingStatus &&
                                getEditorTypingStatus._id === user._id,
                            }"
                          >
                            <!--<template v-if="index < 3">-->
                            <div
                              v-if="
                                getEditorTypingStatus &&
                                getEditorTypingStatus._id === user._id
                              "
                              class="text_block ml-0 mr-2"
                            >
                              <p class="text"
                                >{{ getEditorTypingStatus.firstname }}
                                {{ getEditorTypingStatus.lastname }}</p
                              >
                              <!--<p class="sub_text">Writing...</p>-->
                              <div class="sub_text">
                                <div class="bubble_loader_typing">
                                  <div class="bubble_loader_inner">
                                    <div id="circle1" class="circle"></div>
                                    <div id="circle2" class="circle"></div>
                                    <div id="circle3" class="circle"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--v-tooltip="{ content:teamMemberTooltipHtmlComposer(user) , classes: 'team_tooltip' }"-->

                            <div
                              v-tooltip="{
                                content: teamMemberTooltipHtmlComposer(user),
                                classes: 'team_tooltip',
                              }"
                              class="picture_block picture_block_background"
                            >
                              <div class="status"></div>
                              <div
                                v-if="user.image"
                                class="img"
                                :style="{
                                  background: 'url(' + user.image + ')',
                                }"
                              ></div>
                              <div
                                v-else
                                class="img"
                                style="
                                  background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                "
                              ></div>
                              <!--<img v-if="user.image" :src="user.image" alt="">-->
                              <!--<img v-else src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg" alt="">-->
                            </div>
                            <!--</template>-->
                          </div>

                          <!--//show more profile in dropdown..-->
                          <div
                            v-if="false"
                            class="dropdown default_style_dropdown"
                          >
                            <div
                              class="dropdown_header d-flex align-items-center"
                              data-toggle="dropdown"
                            >
                              <div
                                class="profile_picture d-flex align-items-start"
                              >
                                <div
                                  class="picture_block rounded_image d-flex align-items-center justify-content-center"
                                >
                                  <span>+54</span>
                                </div>
                              </div>
                            </div>

                            <div class="dropdown-menu dropdown-menu-right">
                              <div class="inner">
                                <div class="list_item_li">
                                  <div
                                    class="profile_picture d-flex align-items-start"
                                  >
                                    <div class="picture_block">
                                      <div class="status"></div>
                                      <img
                                        src="http://pbs.twimg.com/profile_images/1024983595784515584/ze3phsOV_normal.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div class="text_block">
                                      <p class="text">Umair Zahid</p>
                                      <p class="sub_text">Profile</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="list_item_li">
                                  <div
                                    class="profile_picture d-flex align-items-start"
                                  >
                                    <div class="picture_block">
                                      <div class="status"></div>
                                      <img
                                        src="http://pbs.twimg.com/profile_images/1024983595784515584/ze3phsOV_normal.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div class="text_block">
                                      <p class="text">Umair Zahid</p>
                                      <p class="sub_text">Profile</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="list_item_li">
                                  <div
                                    class="profile_picture d-flex align-items-start"
                                  >
                                    <div class="picture_block">
                                      <div class="status"></div>
                                      <img
                                        src="http://pbs.twimg.com/profile_images/1024983595784515584/ze3phsOV_normal.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div class="text_block">
                                      <p class="text">Umair Zahid</p>
                                      <p class="sub_text">Profile</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="list_item_li">
                                  <div
                                    class="profile_picture d-flex align-items-start"
                                  >
                                    <div class="picture_block">
                                      <div class="status"></div>
                                      <img
                                        src="http://pbs.twimg.com/profile_images/1024983595784515584/ze3phsOV_normal.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div class="text_block">
                                      <p class="text">Umair Zahid</p>
                                      <p class="sub_text">Profile</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <template
                          v-if="getPublishSelection.status !== 'published'"
                        >
                          <button
                            class="ml-2 btn btn-studio-theme-transparent-grey loader_right btn_loader"
                            :disabled="
                              getPublishLoaders.processSavePublication ||
                              getPublishLoaders.processBlogPost
                            "
                            data-cy="save-draft"
                            @click.prevent="validateProcessBlogPost(true)"
                          >
                            <span>Save as draft</span>
                            <clip-loader
                              v-if="getPublishLoaders.processSavePublication"
                              :color="'#ffffff'"
                              :size="'16px'"
                            ></clip-loader>
                          </button>
                        </template>
                        <button
                          v-if="getBlogPostingDetails.stage !== 'publish'"
                          class="ml-2 btn btn-studio-theme-space loader_right btn_loader"
                          @click.prevent="proceedBlogStage"
                        >
                          <span>Next</span>
                          <!-- <clip-loader :color="'#ffffff'" :size="'16px'" v-if="getPublishLoaders.processSavePublication && getBlogPostingDetails.stage === 'publish'"></clip-loader> -->
                        </button>
                      </div>
                    </div>

                    <BlogPostEditor
                      :class="{
                        hide: getBlogPostingDetails.stage !== 'content',
                      }"
                    />

                    <BlogDistribute
                      :nomodel="false"
                      type="composer"
                      variations_options="true"
                      :class="{
                        hide: getBlogPostingDetails.stage !== 'distribution',
                      }"
                    ></BlogDistribute>
                    <BlogOptimize
                      :class="{
                        hide: getBlogPostingDetails.stage !== 'settings',
                      }"
                      class="with_opt_box_shadow optimize_block"
                    ></BlogOptimize>
                    <BlogSocialCampaign
                      :class="{
                        hide: getBlogPostingDetails.stage !== 'social',
                      }"
                    ></BlogSocialCampaign>
                    <BlogReviewPublish
                      type="composer"
                      :class="{
                        hide: getBlogPostingDetails.stage !== 'publish',
                      }"
                    ></BlogReviewPublish>
                  </div>
                </div>
                <div
                  class="social_post_side_filter"
                  :class="{ open: toggleModalSidebarStatus }"
                >
                  <div class="filter_inner">
                    <div
                      class="c_side_menu radiused_icon"
                      @click="toggleModalSidebar"
                    >
                      <i class="cs-angle-right"></i>
                    </div>
                    <Helper v-if="blogShareTabStatus === 'helper'" />
                    <Assistant
                      v-if="blogShareTabStatus === 'assistant'"
                    ></Assistant>
                    <Seo v-if="blogShareTabStatus === 'seo'"></Seo>
                    <Comments
                      v-if="blogShareTabStatus === 'comment'"
                      :comment-id="$route.params.typeId"
                    ></Comments>
                    <Tasks
                      v-if="blogShareTabStatus === 'task'"
                      :task-id="$route.params.typeId"
                    ></Tasks>
                    <Activities
                      v-if="blogShareTabStatus === 'activity'"
                    ></Activities>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.add-new-camp {
  text-align: center;
  font-size: 0.875rem;
  border-top: 1px solid #cecece;
  padding: 10px 0px 1px 0px;
  color: #484b52;
  cursor: pointer;
}
</style>
