<template>
  <button
    type="button"
    :class="`border-0 focus:outline-none rounded-md hover:shadow-lg font-semibold ${sizeClasses()} ${disabledClasses()} ${variantClasses()}`"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <span class="flex justify-center items-center">
      <span v-if="text">{{ text }}</span>
      <span v-else>
        <slot></slot>
      </span>
      <clip-loader
        v-if="loading"
        :color="'#9da6ac'"
        :size="'13px'"
        class="spinner ml-2"
        variant="info"
      ></clip-loader>
    </span>
  </button>
</template>

<script>
export default {
  name: 'CstButton',
  props: {
    text: {
      type: String,
      default: '',
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['primary', 'primary-light', 'secondary', 'outlined'].indexOf(
            value
          ) !== -1
        )
      },
    },
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sizeClasses() {
      if (this.size === 'small') return 'text-xs pb-2 pt-2.5 px-4'
      else if (this.size === 'large') return 'text-md pb-3 pt-4 px-6'
      else return 'text-sm pb-2.5 pt-3 px-5'
    },
    variantClasses() {
      const disabledClasses = this.disabledClasses()
      if (this.variant === 'primary') {
        const classes = ' border-0 '
        if (this.disabled) {
          return `${disabledClasses} ${classes}`
        } else {
          return `bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer ${classes}`
        }
      }
      if (this.variant === 'primary-light') {
        const classes = 'border-0 '
        if (this.disabled) {
          return `${disabledClasses} ${classes}`
        } else {
          return `bg-blue-100 bg-opacity-60 hover:bg-opacity-80 cursor-pointer text-primary-variant-1 ${classes}`
        }
      } else if (this.variant === 'secondary') {
        if (this.disabled) {
          return `${disabledClasses}`
        } else {
          return 'border-0 text-gray-900 bg-cs-white hover:bg-gray-50'
        }
      }

      return 'border border-gray-100 text-gray-900  bg-gray-100 hover:bg-gray-200'
    },
    disabledClasses() {
      if (this.disabled) {
        if (this.variant === 'primary') {
          return 'cursor-not-allowed bg-cs-primary opacity-50 hover:shadow-none text-white'
        }
        if (this.variant === 'primary-light') {
          return 'cursor-not-allowed bg-blue-100 opacity-50 hover:shadow-none text-primary-variant-1'
        }
        if (this.variant === 'secondary') {
          return 'cursor-not-allowed bg-cs-white opacity-50 hover:shadow-none text-gray-900 '
        }
      } else return 'cursor-pointer'
    },
  },
}
</script>
