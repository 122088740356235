<template>
  <div class="summary-card">
    <div v-if="variant === 'rollup'" class="w-100">
      <div class="summary-card__title">
        {{ title }}
      </div>
      <div
        class="summary-card__body d-flex"
        style="justify-content: space-between"
      >
        <span class="summary-card__total">{{
          total % 1 !== 0
            ? $filters.numberToCommas(total.toFixed(2))
            : $filters.numberToCommas(total)
        }}</span>
        <span v-if="reach === '0%'" class="ml-auto summary-card__reach normal">
          <i class="fas fa-horizontal-rule"></i>
        </span>
        <span
          v-else
          class="ml-auto summary-card__reach"
          :class="{ reachup: reachUp }"
        >
          {{ reach }}
          <i
            v-show="reachUp"
            class="fas fa-arrow-up"
            style="transform: rotate(45deg)"
          ></i>
          <i
            v-show="!reachUp"
            class="fas fa-arrow-down"
            style="transform: rotate(-45deg)"
          ></i>
        </span>
      </div>
    </div>

    <div v-else-if="variant === 'group'" class="summary-card__variant-grp">
      <div>
        <div class="summary-card__title">
          {{ title }}
        </div>
        <div class="summary-card__body">
          <span class="summary-card__total">{{
            total % 1 !== 0 ? total.toFixed(2) : total
          }}</span>
        </div>
      </div>
      <div class="summary-card__group">
        <span
          >Positive <b>{{ positive }}</b></span
        >
        <span
          >Negative <b>{{ negative }}</b></span
        >
      </div>
    </div>
    <div v-else>
      <div class="summary-card__title">
        {{ title }}
      </div>
      <div class="summary-card__body">
        <span class="summary-card__total">{{
          total % 1 !== 0 ? total.toFixed(2) : total
        }}</span>
        <span v-if="reach === '0%'" class="ml-auto summary-card__reach normal">
          <i class="fas fa-horizontal-rule"></i>
        </span>
        <span
          v-else
          class="ml-auto summary-card__reach"
          :class="{ reachup: reachUp }"
        >
          {{ reach }}
          <template v-if="reachUp">
            <i
              v-show="reachUp"
              class="fas fa-arrow-up"
              style="transform: rotate(45deg)"
            ></i>
            <i
              v-show="!reachUp"
              class="fas fa-arrow-down"
              style="transform: rotate(-45deg)"
            ></i>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryCard',
  props: {
    variant: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    reach: {
      type: String,
      default: '',
    },
    reachUp: {
      type: Boolean,
      default: false,
    },
    positive: {
      type: String,
      default: '',
    },
    negative: {
      type: String,
      default: '',
    },
  },
}
</script>
