<template>
  <div>
    <template>
      <div class="upgrade-plan-billing-period">
        <div class="toggle-btn-single">
          <div class="discount_wrapper">
            <div class="toggle-btn-inner">
              <div
                class="action-move"
                :class="{
                  active_left: billing_period === 'monthly',
                  active_right: billing_period === 'annually',
                }"
              ></div>
              <button
                :class="{ active: billing_period === 'monthly' }"
                class="btn btn_round border_btn large_btn"
                @click="billing_period = 'monthly'"
              >
                <span>Monthly</span>
              </button>
              <button
                :class="{ active: billing_period === 'annually' }"
                class="btn btn_round border_btn large_btn"
                @click="billing_period = 'annually'"
              >
                <span>Yearly</span>
              </button>
            </div>
            <span class="discount">save<br />20%</span>
          </div>
        </div>
      </div>
      <div v-if="!loaders.retrieve_subscription" class="upgrade-plan-box mt-5">
        <div
          v-for="(pricingPlan, index) in getSubscriptionPlansList"
          v-if="billing_period === pricingPlan.type"
          :key="index"
          class="upgrade-box-column"
          :class="{
            active: pricingPlan.level === level,
            downgrade: pricingPlan.level < level,
          }"
        >
          <BillingPlanTile
              :billing_period="billing_period"
              :pricing-plan="pricingPlan"
          ></BillingPlanTile>
          <template
            v-if="
              level === pricingPlan.level &&
              (getProfile.state === 'deleted' || getProfile.state === 'paused')
            "
          >
            <b-button
              :disabled="pricingPlan.loader"
              variant="studio-theme-transparent-grey"
              data-cy="choose-plan2"
              @click="upgradeSubscriptionPlanAction(pricingPlan)"
            >
              Choose Plan
              <span v-if="pricingPlan.loader" class="ml-2">
                <clip-loader :size="'14px'" :color="'#3a4557'"></clip-loader>
              </span>
            </b-button>
          </template>
          <template v-else-if="level === pricingPlan.level">
            <b-button variant="studio-theme-active">Current Plan</b-button>
          </template>
          <template v-else-if="pricingPlan.level > level">
            <b-button
              :disabled="pricingPlan.loader"
              variant="studio-theme-transparent-grey"
              data-cy="choose-plan3"
              @click="upgradeSubscriptionPlanAction(pricingPlan)"
            >
              Choose Plan
              <span v-if="pricingPlan.loader" class="ml-2">
                <clip-loader :size="'14px'" :color="'#3a4557'"></clip-loader>
              </span>
            </b-button>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="less">
.upgrade-pricing-span-component {
}

.billed-annually-text {
  color: #9298a2;
  font-weight: 500;
  font-weight: 500;
  font-size: 0.9em;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { pricingList } from '@common/constants/pricing'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import BillingPlanTile from "@src/modules/setting/components/billing/reusable/BillingPlanTile";
import UpgradePlanConfirmation from './UpgradePlanConfirmation'

export default {
  components: {
    UpgradePlanConfirmation,
    BillingPlanTile
  },
  data () {
    return {
      billing_period: 'monthly',
      level: 0,
      subscription_plans_list: pricingList,
      checked: false,
      loaders: {
        retrieve_subscription: true
      },
      new_subscription: null
    }
  },
  async created () {
    this.new_subscription = this.getPlan.subscription
    if (
      !this.$store.getters.getPlan.subscription &&
      this.$store.getters.getPlan.subscription.fastspring_name
    ) {
      await this.fetchPlan()
    }
    this.loaders.retrieve_subscription = false
  },
  computed: {
    ...mapGetters(['getPlan', 'getProfile']),
    getSubscriptionPlansList () {
      const subscriptionName =
        this.$store.getters.getPlan.subscription.fastspring_name
      // check if the subscription matches in paddle list names

      this.subscription_plans_list.plans.forEach((plan) => {
        if (plan.paddle_name === subscriptionName) {
          this.level = plan.level
        }
        // check if the subscription matches in fastspring names

        if (plan.fs_name === subscriptionName) {
          this.level = plan.level
        }
      })

      return this.subscription_plans_list.plans
    }
  },
  methods: {
    upgradeSubscriptionPlanAction (subscription) {
      console.log(
        'upgradeSubscriptionPlanAction',
        subscription,
        this.isTrialPlan()
      )
      this.new_subscription = subscription

      // If user is trial
      if (this.isTrialPlan()) {
        setTimeout(() => {
          this.upgradeSubscriptionPlanPaddle()
        }, 100)
      } else {
        this.$emit('showPlanConfirmation', subscription, this.billing_period)
        setTimeout(() => {
          $('#upgrade-plan-confirmation-box').modal('show')
          $('#upgrade-plan-confirmation-box').focus()
        }, 200)
      }
    },

    upgradeSubscriptionPlanPaddle () {
      const routeQueryValue = this.$route.query.special
      console.log(this.isTrialPlan())

      const self = this
      const profile = this.$store.getters.getProfile
      const couponCode = this.billing_period === 'annually' ? 'ANNUAL20OFF' : ''

      // if (routeQueryValue && this.isTrialPlan()) {
      //   couponCode = this.billing_period === 'annually' ? 'BF2020DEAL50OFF' : ''
      // }

      // upgrade via Paddle subscription. There would be paddle_subscription_id

      if (profile.paddle_account_id && profile.paddle_subscription_id) {
        this.upgradeBySubscriptionId(this.new_subscription.paddle_id)
        return
      }

      // if the account does not have any paddle account id.
      const passthrough = {
        user_id: profile._id,
        user_email: profile.email,
        paddle_account_id: profile.paddle_account_id || '',
        paddle_subscription_id: profile.paddle_subscription_id || '',
      }

      Paddle.Checkout.open({
        product: this.new_subscription.paddle_id,
        email: profile.email,
        title: '',
        coupon: couponCode,
        passthrough: JSON.stringify(passthrough),
        successCallback: function (data) {
          console.log(data)
          window.fbq('track', 'Purchase', {
            value: parseInt(data.checkout.prices.customer.total),
            currency: data.checkout.prices.customer.currency
          })
          alert('Thanks for your purchase.')
          // window.location.reload()
          self.upgradeCallback(data)
        }
      })
    },

    async upgradeBySubscriptionId (subscriptionId) {
      const res = await proxy
        .post(`${billingBaseUrl}paddle/upgrade_subscription_by_id`, {
          paddle_subscription_id: subscriptionId
        })
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message
            })
            return true
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            this.$store.dispatch('toastNotification', {
              message: err.response.data.message,
              type: 'error'
            })
          }
          return false
        })

      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
      }
    },

    async upgradeCallback (data) {
      console.log('data ->', JSON.stringify(data))

      const res = await proxy
        .post(`${billingBaseUrl}paddle/update_from_callback`, data)
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message
            })
            return true
          }
        })
        .catch((err) => {
          return false
        })
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
        if (
          [
            'upgrade_subscription',
            'trial_expired',
            'subscription_expired'
          ].indexOf(this.$route.name) >= 0
        ) {
          this.$router.push({ name: 'workspaces' })
        }
      }
    }
  }
}
</script>
