import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getHashtagsSeries (data) {
      return [
        {
          name: 'Occurrences',
          type: 'wordcloud',
          data: data
        }
      ]
    },
    getAudienceGrowthSeries (audience) {
      audience.audience_growth.data.followers.forEach(function (val, index) {
            if (index > 0) {
              if (val === 0) {
                audience.audience_growth.data.followers[index] = audience.audience_growth.data.followers[index - 1]
              }
            }
          }
      )
      return [
        {
          name: 'Followers',
          data: audience.audience_growth.data.followers,
          color: '#ffe202'
        }
      ]
    },
    getPublishingBehaviourSeries (publishing_behaviour) {
      return [
        {
          name: 'Image Posts',
          data: publishing_behaviour.publishing_behaviour.data.image,
          color: '#ffe202'
        },
        {
          name: 'Video Posts',
          data: publishing_behaviour.publishing_behaviour.data.video,
          color: '#2fe095'
        },
        {
          name: 'Carousel Album',
          data: publishing_behaviour.publishing_behaviour.data.carousel_album,
          color: '#e02f5f'
        }
      ]
    },
    getActiveUsersSeries (active_users, period) {
      if (period === 'days') {
        return [
          {
            name: 'Active Users By Days',
            data: active_users.active_users_days.values,
            color: '#2fe095'
          }
        ]
      }
      if (period === 'hours') {
        return [
          {
            name: 'Active Users By Hours',
            data: active_users.active_users_hours.values,
            color: '#6173ff'
          }
        ]
      }
    },
    getImpressionsSeries (impressions) {
      return [
        {
          name: 'Impressions',
          data: impressions.impressions.data.impressions,
          color: '#2fe095'
        }
      ]
    },
    getEngagementSeries (engagement) {
      return [
        {
          name: 'Total Engagement',
          data: engagement.engagements.data.engagement,
          color: '#ffe202',
          type: 'column'
        }
      ]
    },
    getStoriesPerformanceSeries (stories_performance) {
      return [
        {
          name: 'Story Replies',
          data: stories_performance.stories_performance.data.story_reply,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Story Taps Back',
          data: stories_performance.stories_performance.data.story_taps_back,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Story Taps Forward',
          data: stories_performance.stories_performance.data.story_taps_forward,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Story Exits',
          data: stories_performance.stories_performance.data.story_exits,
          color: '#e02f5f',
          type: 'column'
        }
      ]
    },
    getStoriesPublishedSeries (stories_performance) {
      return [
        {
          name: 'Stories Published',
          data: stories_performance.stories_published.data.published_stories,
          color: '#e02f5f',
          type: 'column'
        }
      ]
    }
  }
}
