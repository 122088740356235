<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
      type="twitter"
      :style="{
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
      :updating="fetching_data"
      :getAccounts="getAccounts"
      :getDate="getDate"
      :getPreviousDate="getPreviousDate"
    />

    <div
      class="analytics-main-errors"
      v-if="getPlatformAccounts('twitter').length <= 0 && !fetching_data"
    >
      <img
        draggable="false"
        src="../../assets/imgs/no_data_images/no-account-connected.svg"
        alt=""
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect Twitter Account
        </router-link>
      </p>
    </div>

    <div v-else class="analytics-content-wrapper">
      <template v-if="selected_accounts.state === 'Added'">
        <analytics-loading :name="selected_accounts.name"></analytics-loading>
      </template>
      <TwitterTabs v-else-if="!getFetchSocialStatus">
        <!-- overview tab -->
        <template slot="overview">
          <OverviewTab
            :fetchingAggregations="fetchingAggregations"
            :fetchingTopPosts="fetchingTopPosts"
            :fetchingStatistics="fetchingStatistics"
            :fetchingBestTimeForContent="fetchingBestTimeForContent"
            :fetchingContentDistributionParsing="
              fetchingContentDistributionParsing
            "
            :fetchingFans="fetchingFans"
            :fetchingPublishPostsWithMentions="fetchingPublishPostsWithMentions"
            :fetchingPublishedPostsWithEngagement="
              fetchingPublishedPostsWithEngagement
            "
            :fetchingAudience="fetchingAudience"
            :top3Posts="top3Posts"
            :aggregations="aggregations"
            :previous_aggregations="previous_aggregations"
            :best_time_for_content="best_time_for_content"
            :content_distribution_daily="content_distribution_parsing"
            :fans="fans"
            :publish_posts_with_mentions="publish_posts_with_mentions"
            :published_posts_with_engagement="published_posts_with_engagement"
            :audience="audience"
          ></OverviewTab>
        </template>

        <!-- posts tab -->
        <template slot="posts">
          <PostsTab
            :fetchingTopPosts="fetchingTopPosts"
            :top5Posts="top5Posts"
            :top10Posts="top10Posts"
            :top15Posts="top15Posts"
          />
        </template>
      </TwitterTabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import TwitterTabs from '@src/modules/analytics/views/twitter/components/TwitterTabs'

// Tabs
import OverviewTab from '@src/modules/analytics/views/twitter/tabs/OverviewTab'
import PostsTab from '@src/modules/analytics/views/twitter/tabs/PostsTab'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  name: 'AnalyticsOverview',
  mixins: [analyticsUtilsMixin],
  components: {
    AnalyticsFilterBar,
    TwitterTabs,
    OverviewTab,
    PostsTab,
    AnalyticsLoading
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getFetchSocialStatus', 'getProfile']),
    fetching_data () {
      return (
        this.fetchingAggregations ||
        this.fetchingTopPosts ||
        this.fetchingStatistics ||
        this.fetchingBestTimeForContent ||
        this.fetchingContentDistributionParsing ||
        this.fetchingFans ||
        this.fetchingPublishPostsWithMentions ||
        this.fetchingPublishedPostsWithEngagement ||
        this.fetchingAudience
      )
    }
  },
  data () {
    return {
      selected_accounts: [],
      date: '',
      previous_date: '',
      top3Posts: [],
      top5Posts: [],
      top10Posts: [],
      top15Posts: [],
      aggregations: {},
      previous_aggregations: {},
      best_time_for_content: [],
      content_distribution_parsing: {},
      fans: {},
      publish_posts_with_mentions: {},
      published_posts_with_engagement: {},
      audience: {},
      fetchingAggregations: false,
      fetchingTopPosts: false,
      fetchingStatistics: false,
      fetchingBestTimeForContent: false,
      fetchingContentDistributionParsing: false,
      fetchingFans: false,
      fetchingPublishPostsWithMentions: false,
      fetchingPublishedPostsWithEngagement: false,
      fetchingAudience: false
    }
  },
  mounted () {
    this.fetchAnalytics()
  },
  methods: {
    ...mapActions(['getTwitterAnalyticsService']),
    fetchAnalytics () {
      console.debug('Method: fetchAnalytics')
      if (
        this.selected_accounts == null ||
        (Array.isArray(this.selected_accounts) &&
          this.selected_accounts.length === 0)
      ) {
        return
      }
      if (this.date == null || this.date === '') return
      if (this.previous_date == null || this.previous_date === '') return

      const apiType = [
        'aggregations',
        'top_posts',
        'best_time_for_content',
        'content_distribution_parsing',
        'fans',
        'publish_posts_with_mentions',
        'published_posts_with_engagement',
        'audience'
      ]

      for (const type of apiType) {
        switch (type) {
          case 'aggregations': {
            if (!this.fetchingAggregations) {
              this.fetchingAggregations = true
            } else {
              return
            }
            break
          }
          case 'top_posts': {
            if (!this.fetchingTopPosts) {
              this.fetchingTopPosts = true
            } else {
              return
            }
            break
          }
          case 'best_time_for_content': {
            if (!this.fetchingBestTimeForContent) {
              this.fetchingBestTimeForContent = true
            } else {
              return
            }
            break
          }
          case 'content_distribution_parsing': {
            if (!this.fetchingContentDistributionParsing) {
              this.fetchingContentDistributionParsing = true
            } else {
              return
            }
            break
          }
          case 'fans': {
            if (!this.fetchingFans) {
              this.fetchingFans = true
            } else {
              return
            }
            break
          }
          case 'publish_posts_with_mentions': {
            if (!this.fetchingPublishPostsWithMentions) {
              this.fetchingPublishPostsWithMentions = true
            } else {
              return
            }
            break
          }
          case 'published_posts_with_engagement': {
            if (!this.fetchingPublishedPostsWithEngagement) {
              this.fetchingPublishedPostsWithEngagement = true
            } else {
              return
            }
            break
          }
          case 'audience': {
            if (!this.fetchingAudience) {
              this.fetchingAudience = true
            } else {
              return
            }
            break
          }
        }

        const payload = {
          workspace_id: this.getActiveWorkspace._id,
          date: this.date,
          accounts: [this.selected_accounts.twitter_id],
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          api_type: type,
          type: 'twitter'
        }
        this.makeAPICall(type, payload)
      }
    },
    async makeAPICall (type, payload) {
      const resp = await this.getTwitterAnalyticsService(payload)
      console.debug('Resp', resp)
      switch (type) {
        case 'aggregations':
          this.aggregations = resp.aggregations.current
          this.previous_aggregations = resp.aggregations.previous
          this.fetchingAggregations = false
          break
        case 'top_posts':
          if (resp.top_posts.length > 0) {
            this.top3Posts = this.setTopPosts(3, resp.top_posts)
            this.top5Posts = this.setTopPosts(5, resp.top_posts)
            this.top10Posts = this.setTopPosts(10, resp.top_posts)
            this.top15Posts = this.setTopPosts(15, resp.top_posts)
          } else {
            this.top3Posts = []
            this.top5Posts = []
            this.top10Posts = []
            this.top15Posts = []
          }
          this.fetchingTopPosts = false
          break
        case 'best_time_for_content':
          this.best_time_for_content = resp.best_time_for_content
          this.fetchingBestTimeForContent = false
          break
        case 'content_distribution_parsing':
          this.content_distribution_parsing = resp.content_distribution_parsing
          this.fetchingContentDistributionParsing = false
          break
        case 'fans':
          this.fans = resp.fans
          this.fetchingFans = false
          break
        case 'publish_posts_with_mentions':
          this.publish_posts_with_mentions = resp.publish_posts_with_mentions
          this.fetchingPublishPostsWithMentions = false
          break
        case 'published_posts_with_engagement':
          this.published_posts_with_engagement =
            resp.published_posts_with_engagement
          this.fetchingPublishedPostsWithEngagement = false
          break
        case 'audience':
          this.audience = resp.audience
          this.fetchingAudience = false
          break
      }
    },
    getAccounts (account) {
      this.selected_accounts = account
    },
    getDate (date) {
      this.date = date
    },
    getPreviousDate (date) {
      this.previous_date = date
    },
    setTopPosts (count, dataCollection) {
      const payloadToReturn = []
      const iteCount =
        count > dataCollection.length ? dataCollection.length : count
      for (var x = 0; x < iteCount; x++) {
        payloadToReturn.push(dataCollection[x])
      }
      return payloadToReturn
    }
  },
  watch: {
    selected_accounts (newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log('updated >>> selected_accounts', newVal, oldVal)
        this.fetchAnalytics()
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log('updated >>> date')
        this.fetchAnalytics()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log('updated >>> previous_date')
        this.fetchAnalytics()
      }
    }
  }
}
</script>
