<template>
  <div class="articles_list">
    <div class="article_inner">
      <div class="fb_quote_box_grid article_box_grid">
        <div class="box_inner">
          <div class="image_block">
            <div class="tags_block">
              <span class="tag">Photo</span>
            </div>
            <div
              class="img"
              style="
                background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
              "
            ></div>
          </div>

          <div class="content_block">
            <div class="users_detail">
              <div class="profile_picture d-flex align-items-start fb">
                <div class="picture_block">
                  <div class="icon">
                    <i class="cs-facebook"></i>
                  </div>
                  <img
                    src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                    alt=""
                  />
                </div>
                <div class="text_block">
                  <p class="text"><span class="bold">Waqar</span> Azeem</p>
                  <p class="sub_text">17 days ago 10:30 PM</p>
                </div>
              </div>
            </div>
            <p class="desc">
              The talk you hear about adapting to change is not only stupid,
              it’s dangerous. The only way you can manage change is to create i
            </p>

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item">
                  <div class="name">
                    <p>
                      <i class="cs-thumbs-up"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>34343</h3>
                  </div>
                </div>
                <div class="stat_item">
                  <div class="name">
                    <p>
                      <i class="cs-comment-square"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>34343</h3>
                  </div>
                </div>
                <div class="stat_item">
                  <div class="name">
                    <p>
                      <i class="cs-share"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>34343</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_block d-flex text-center">
              <div class="btn_block_inner">
                <div
                  class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header" data-toggle="dropdown">
                    <button class="btn light_gray">
                      <i class="icon-Share_icon d-block"></i>
                      <span class="circle_count">7</span>
                    </button>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <li class="list_item_li">
                        <i class="cs-social-share"></i>
                        <span class="icon_text">Social Media</span>
                      </li>
                      <li class="list_item_li">
                        <i class="cs-rss"></i>
                        <span class="icon_text">Blog Post</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="more_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                >
                  <div class="dropdown_header" data-toggle="dropdown">
                    <button class="btn light_gray">
                      <i class="cs-dots-h d-block"> </i>
                    </button>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <li class="list_item_li">
                        <i class="cs-pocket"></i>
                        <span class="icon_text">Add to Pocket</span>
                      </li>
                      <li class="list_item_li">
                        <i class="cs-archive"></i>
                        <span class="icon_text">Archive Post</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},

  data () {
    return {}
  },
  created () {},
  computed: {
    ...mapGetters([])
  },

  methods: {
    ...mapActions([])
  },

  watch: {}
}
</script>
