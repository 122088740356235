const hashtag = {
  appendHashTag(message, details) {
    for (let i = 0; i < details.count; i++) {
      const index = Math.floor(Math.random() * details.items.length)
      const item = details.items[index]
      message = message + ' ' + item
      details.items.splice(index, 1)
    }
    return message
  },
  replaceHashTag(message, details) {
    for (let i = 0; i < details.count; i++) {
      const index = Math.floor(Math.random() * details.items.length)
      let tag = details.items[index]
      tag = tag.replace('#', '')

      if (
        message
          .toLowerCase()
          .match(new RegExp('(^|\\s)(' + tag.toLowerCase() + ')(?=\\s|$)'))
      ) {
        message = this.replaceIgnoringCase(message, tag, ' #' + tag)
      } else {
        message = message + ' #' + tag
      }

      details.items.splice(index, 1)
    }
    return message
  },
  replaceIgnoringCase(text, search, replace) {
    const regEx = new RegExp('(^|\\s)(' + search + ')(?=\\s|$)', 'ig')
    return text.replace(regEx, replace)
  },
}
export default hashtag
