var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_field social_content_box social_content_box_multi",class:{
    in_active: _vm.getSocialSharingSelection.activeTab !== 'tiktok_tab',
    editor_hover: _vm.isFileHover,
  },attrs:{"id":"tiktok_sharing_box"},on:{"dragleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$store.dispatch('leaveFileDragOver', { event: $event, type: 'Tiktok' })},"dragover":function($event){return _vm.$store.dispatch('onFileDragOver', { event: $event, type: 'Tiktok' })},"drop":function($event){return _vm.$store.dispatch('onFileDrop', { event: $event, type: 'Tiktok' })},"click":function($event){return _vm.focusSharingBox('Tiktok')}}},[_c('div',{staticClass:"box_social_tag tiktok_bg"},[_c('svg',{staticStyle:{"width":"15px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{staticClass:"selected",attrs:{"fill":"#ffffff","d":"M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z","id":"svg_1","fill-opacity":"1"}})])]),(_vm.isFileHover)?_c('div',{staticClass:"drag_content"},[_c('p',[_vm._v("Drag files here")])]):_vm._e(),_c('options',{class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'tiktok_tab' },attrs:{"type":"Tiktok"}}),(
        (_vm.getTiktokSharingDetails.video &&
        _vm.getTiktokSharingDetails.video.link) ||
      _vm.getPublishLoaders.upload_tiktok_sharing_video
    )?_c('video-section',{class:{ hide: _vm.getSocialSharingSelection.activeTab !== 'tiktok_tab' },attrs:{"type":"Tiktok","details":_vm.getTiktokSharingDetails}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }