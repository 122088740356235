<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getProfile',
      'isAnalyticsPlanAllowed',
      'getActiveWorkspaceAddonsLifetime',
    ]),
    isAnalyticsEnabledForUser() {
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      return this.getActiveWorkspaceAddonsLifetime.analytics
    },
  },

  watch: {},
  methods: {},
}
</script>

<template>
  <div class="ps_sidebar">
    <div class="sidebar_inner">
      <!--<div class="avator">-->
      <!--<div class="img">-->
      <!--<img v-if="getProfile.image" :src="getProfile.image" alt="">-->
      <!--<img v-else src="../../../app/src/assets/img/profile_default.svg" alt="">-->
      <!--</div>-->
      <!--<h3><span>{{getProfile.firstname}}</span> {{getProfile.lastname}}</h3>-->
      <!--<p>{{getProfile.email}}</p>-->
      <!--</div>-->
      <ul class="links">
        <!--<div class="hr_line"></div>-->
        <li>
          <div class="setting_profile_img">
            <img
              :src="getWorkspaces.activeWorkspace.logo"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
              "
            />
            <h4 data-cy="workspace_name">{{
              getWorkspaces.activeWorkspace.name
            }}</h4>
          </div>
        </li>
        <li class="heading">
          <span>Account Settings</span>
        </li>
        <li>
          <router-link class="" :to="{ name: 'profile' }">
            <i class="icon-profile-cs"></i>
            <span class="text">Your Profile</span>

            <div
              class="line_animate"
              :class="{ animate: this.$route.name === 'profile' }"
            ></div>
          </router-link>
        </li>
        <li v-if="hasPermission('can_see_subscription')">
          <router-link class=" " :to="{ name: 'plan' }">
            <i
              :class="
                checkIfActiveWorkspaceHasLimitExceeded()
                  ? 'fas fa-exclamation-triangle limit_issue_billing'
                  : 'icon-plan-cs'
              "
            ></i>
            <span class="text">Billing & Plan</span>
            <div
              class="line_animate"
              :class="{ animate: this.$route.name === 'plan' }"
            ></div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'notifications' }">
            <i class="icon-notificaion-cs"></i>
            <span class="text">Notifications</span>
            <div
              class="line_animate"
              :class="{ animate: this.$route.name === 'notifications' }"
            ></div>
          </router-link>
        </li>
        <li>
          <router-link class=" " :to="{ name: 'emailNotificationStatus' }">
            <i class="far fa-envelope -mr-1.5"></i>
            <span class="text">Email Notification Status</span>
            <div
              class="line_animate"
              :class="{
                animate: this.$route.name === 'emailNotificationStatus',
              }"
            ></div>
          </router-link>
        </li>
        <li v-if="getTeamMembership === 'team'">
          <router-link :to="{ name: 'refer&earn' }">
            <i class="fa fa-gift"></i>
            <span class="text" style="margin-left: 15px"
              >Refer & Earn
              <span class="nav-beta new-feature-available">NEW</span></span
            >
            <div
              class="line_animate"
              :class="{ animate: this.$route.name === 'refer&earn' }"
            ></div>
          </router-link>
        </li>

        <!--
        hiding it before we secure our API server and add it behind some rate limit mechanism.
        <li v-if="hasPermission('can_see_development')">

            <router-link class=" " :to="{name: 'developers'}">
                <i class="cs-key"></i>
                <span class="text">Developers</span>
                <div class="line_animate" :class="{'animate' : this.$route.name == 'developers' }"></div>
            </router-link>

        </li>-->

        <template v-if="hasPermission('can_access_workspace_setting')">
          <div class="hr_line"></div>
          <li class="heading">
            <span>Workspace Settings</span>
          </li>

          <li>
            <router-link class="setting_icon" :to="{ name: 'basicSetting' }">
              <i class="icon-settings-cs"></i>

              <span class="text">Basic Settings</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'basicSetting' }"
              ></div>
            </router-link>
          </li>

          <li v-if="hasPermission('can_view_team')">
            <router-link class="   " :to="{ name: 'team' }">
              <i class="icon-team-member-cs"></i>
              <!--<i class="icon-team-member-icon"></i>-->
              <span class="text">Team Members</span>

              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'team' }"
              ></div>
            </router-link>
          </li>

          <li>
            <router-link class="" :to="{ name: 'content_categories' }">
              <i class="icon-engagement-ico"></i>
              <span class="text">Content Categories</span>
            </router-link>
          </li>

          <li>
            <router-link class="" :to="{ name: 'social' }">
              <i class="icon-social-accounts-cs"></i>
              <span class="text">Social Accounts</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'social' }"
              ></div>
            </router-link>
          </li>

          <li>
            <router-link class=" " :to="{ name: 'blogs' }">
              <i class="icon-blogs-websites-cs"></i>
              <span class="text">Blogs &amp; Websites</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'blogs' }"
              ></div>
            </router-link>
          </li>

          <li>
            <router-link class="   " :to="{ name: 'integrations' }">
              <i class="icon-other-integration-cs"></i>
              <span class="text">Other Integrations</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'integrations' }"
              ></div>
            </router-link>
          </li>
          <!-- <li v-if="hasPermission('can_save_source')">

               <router-link :to="{name: 'sources'}">
                   <i class="cs-network"></i>
                   <span class="text">Sources</span>
                   <div class="line_animate" :class="{'animate' : this.$route.name == 'web_sources' }"></div>

               </router-link>

           </li>-->

          <li>
            <router-link class=" " :to="{ name: 'miscellaneous' }">
              <i class="icon-miscellaneous-cs"></i>
              <span class="text">Miscellaneous</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'miscellaneous' }"
              ></div>
            </router-link>
          </li>

          <li v-if="isAnalyticsEnabledForUser">
            <router-link class=" " :to="{ name: 'reportsettings' }">
              <i class="icon-reports_icon"></i>
              <span class="text">Reports Settings</span>
              <div
                class="line_animate"
                :class="{ animate: this.$route.name === 'reportsettings' }"
              ></div>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
