<template>
  <div class="scroll_content influencer-tweets social_preview_no_tab">
    <template v-if="getInfluencerLoaders.posts">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="posts && posts.length > 0">
      <div class="influencer-instagram-posts clear">
        <div class="instagram_post_preview_main" v-for="(post, index) in posts">
          <div class="instagram_post_preview">
            <div class="sec_padding head">
              <div class="profile_picture d-flex align-items-center">
                <div class="picture_block">
                  <img :src="preview.influencer_image" alt="" />
                </div>
                <div class="text_block">
                  <p class="text" v-html="preview.channel_title"></p>
                </div>
              </div>
            </div>

            <div class="preview_images">
              <iframe
                width="100%"
                height="500px"
                :src="'https://www.youtube.com/embed/' + post.id"
              >
              </iframe>

              <!--<div @click.prevent="previewVideo(post.title,post.video_description,videoEmbedUrl(post.id,'youtube'),'Youtube')"-->
              <!--class="img play_icon_youtube" :style="{ 'background': 'url(' + post.video_thumbnail + ')' }">-->

              <!--<i  class="fal fa-play-circle"></i>-->
              <!--</div>-->
            </div>

            <div class="sec_padding preview_desc" v-html="post.title"></div>
            <div
              class="video_description"
              v-html="post.video_description.substring(0, 428) + '...'"
            >
            </div>

            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div class="stat_item" v-tooltip.top-center="'Likes'">
                  <div class="name">
                    <p>
                      <i class="fal fa-heart"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.dislike_count) }}</h3>
                  </div>
                </div>
                <div class="stat_item" v-tooltip.top-center="'Dislikes'">
                  <div class="name">
                    <p>
                      <i class="fal fa-thumbs-down"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.like_count) }}</h3>
                  </div>
                </div>
                <div class="stat_item" v-tooltip.top-center="'Video views'">
                  <div class="name">
                    <p>
                      <i class="fal fa-eye"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.view_count) }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <p>No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    posts: {},
    preview: {}
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders'])
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less">
.video_description {
  padding: 0.625rem;
  text-align: justify;
}
</style>
