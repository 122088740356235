<template>
  <b-modal
    id="remove_global_category_confirmation"
    modal-class=" add_category sweet-alert-confirmation"
    hide-header
  >
    <div class="basic_form">
      <div class="modal_body">
        <h4 class="warning_question"
          >Are you sure you want to delete this global content category?</h4
        >
        <div class="input_field">
          <div class="checkbox_input_image block">
            <input
              v-model="removePermissionForCategory"
              type="checkbox"
              id="removePermissionForCategory"
            />
            <label
              class="checkbox_left top_side"
              for="removePermissionForCategory"
              >Also, I want to delete this global category from all
              workspaces.</label
            >
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary btn-md"
        @click="$bvModal.hide('remove_global_category_confirmation')"
        >Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger btn-md"
        @click="removeGlobalCategoryOption()"
        >Continue</button
      >
    </div>
  </b-modal>
</template>
<style lang="less" scoped>
.warning_question {
  font-size: 15px;
}
</style>
<script>
import { EventBus } from '@common/lib/event-bus'

export default {
  components: {},
  computed: {},
  data () {
    return {
      removePermissionForCategory: true,
      categoryId: null,
      globalContentCategoryId: null
    }
  },

  created () {},
  mounted () {
    EventBus.$on('remove-global-content-category', (data) => {
      this.removePermissionForCategory = true
      this.categoryId = data.categoryId
      this.globalContentCategoryId = data.globalContentCategoryId
    })
  },
  beforeDestroy () {
    EventBus.$off('remove-global-content-category')
  },
  watch: {},
  methods: {
    async removeGlobalCategoryOption () {
      if (this.removePermissionForCategory) {
        console.log('removing global category')
        await this.$store.dispatch('deleteGlobalCategory', {
          content_category_id: this.categoryId,
          global_content_category_id: this.globalContentCategoryId
        })
      } else {
        await this.$store.dispatch('deleteCategory', this.categoryId)
        console.log('removing individual category')
      }

      this.$bvModal.hide('remove_global_category_confirmation')
    }
  }
}
</script>

<style scoped lang="less"></style>
