<template>
  <div class="feeds-content-pane">
    <!--for topics -- remove d-none class .......-->
    <div class="feeds-content-pane__section" style="padding: 0; border-top: 0">
      <template v-if="load_posts">
        <div class="pt-3">
          <beat-loader :color="'#436aff'"> </beat-loader>
        </div>
      </template>
      <template v-else>
        <!-- for today and trending stories, we do not show the header. -->
        <div
          class="feeds-content-pane__section__header"
          v-if="!isCollectiveCollectionType"
        >
          <div class="feeds-content-pane__section__header__content">
            <content-name :title="feed_type.title"></content-name>
          </div>
          <div class="feeds-content-pane__section__header__filters">
            <content-total-results
              :total="total_results"
            ></content-total-results>
          </div>
        </div>
        <template v-if="isCollectiveCollectionType">
          <feeder-content-view-slot type="main">
            <template v-if="collection_results.loader">
              <div class="pt-3">
                <beat-loader :color="'#436aff'"> </beat-loader>
              </div>
            </template>
            <template v-else>
              <div class="topics-cover-stories">
                <template
                  v-if="
                    $route.params.content_type === 'today' &&
                    hasCollectionItemsWithPosts > 0
                  "
                >
                  <h2 class="topics-cover-stories__heading"
                    ><span>Cover Stories For Today</span></h2
                  >
                </template>
                <template
                  v-if="
                    $route.params.content_type === 'trending' &&
                    hasCollectionItemsWithPosts > 0
                  "
                >
                  <h2 class="topics-cover-stories__heading"
                    ><span>Trending Stories</span></h2
                  >
                </template>

                <template v-if="hasCollectionItemsWithPosts === 0">
                  <div class="no_data_found_content">
                    <content-available-state :type="'no_results'">
                      <h2 slot="headline">No results are available</h2>
                      <p slot="text"
                        >There are no stories available for your followed feeds
                        from {{ $route.params.content_type }}!</p
                      >
                    </content-available-state>
                  </div>
                </template>
                <!-- collective results , top 6 posts from the view -->
                <template
                  v-for="(collection, index) in getSortedCollectionItemsList"
                  v-if="
                    collection.posts &&
                    collection.posts.hits &&
                    collection.posts.hits.length
                  "
                >
                  <div :key="index">
                    <div
                      class="d-flex justify_space_between align-items-center"
                    >
                      <h3 class="topics-cover-stories__subheading"
                        ><span>{{ collection.group.name }}</span></h3
                      >
                      <router-link
                        style="position: relative"
                        class="mr-4"
                        :to="{
                          name: 'feeder_collection',
                          params: { collection_id: collection.group.uuid },
                        }"
                      >
                        View more from this group
                      </router-link>
                    </div>
                    <div class="topics-cover-stories-container">
                      <template
                        v-for="(post, index) in collection.posts.hits"
                        v-if="collection.posts.hits.length > 0"
                      >
                        <feeder-cover-item
                          :key="post._id"
                          :index="index"
                          :collection_uuid="collection.group.uuid"
                          :post="post"
                        ></feeder-cover-item>
                      </template>

                      <!--                                      <feeder-cover-item :collection_name="collection.group.name"-->
                      <!--                                                           :collection_uuid="collection.group.uuid"-->
                      <!--                                                           :collection_posts="collection.posts.hits"-->
                      <!--                                        ></feeder-cover-item>-->
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </feeder-content-view-slot>
        </template>

        <template v-else>
          <feeder-content-view-slot>
            <!--<transition-group :name="slide_item_animation" tag="div" >-->
            <template v-for="(post, index) in posts">
              <div
                class="article-grid-view-container"
                :key="index"
                v-if="getFeederPreferences.item_style === 'grid'"
              >
                <feeder-grid-view-item
                  :post="post"
                  :index="index"
                  :isArchive="isArchiveView"
                  :route_name="'aggregate_feed_item'"
                >
                </feeder-grid-view-item>
              </div>
              <div
                :key="'list_' + post.id"
                class="article_box_list"
                v-if="getFeederPreferences.item_style === 'list'"
              >
                <feeder-list-view-item
                  :post="post"
                  :index="index"
                  :isArchive="isArchiveView"
                  :route_name="'aggregate_feed_item'"
                ></feeder-list-view-item>
              </div>
            </template>
            <!--</transition-group>-->
            <infinite-loading
              :identifier="infiniteId"
              @infinite="feederAggregateViewHandler"
              ref="infiniteAggregateView"
            >
              <span slot="spinner" class="d-block pt-3 pb-4">
                <beat-loader :color="'#436aff'"></beat-loader>
              </span>
              <div slot="no-more" class="not_found_text">
                <div class="no_data_found_content" v-if="total_results === 0">
                  <content-available-state :type="'no_results'">
                    <h2 slot="headline">No Results Found</h2>
                    <p slot="text"
                      >No more results found for your query. Please try a
                      different search string or change search filters.</p
                    >
                  </content-available-state>
                </div>
              </div>

              <div slot="no-results">
                <div class="no_data_found_content" v-if="total_results === 0">
                  <template v-if="isFeederStarredType">
                    <content-available-state :type="'starred'">
                      <h2 slot="headline">No Starred Posts Found</h2>
                      <p slot="text">You have not starred any posts yet.</p>
                    </content-available-state>
                  </template>
                  <template v-else-if="isFeederArchivedType">
                    <content-available-state :type="'archived'">
                      <h2 slot="headline">No Archived Posts Found</h2>
                      <p slot="text">You have not archived any posts yet.</p>
                    </content-available-state>
                  </template>
                  <template v-else>
                    <content-available-state :type="'no_results'">
                      <h2 slot="headline">No Results Found</h2>
                      <p slot="text"
                        >No posts are available for your selected feed.</p
                      >
                    </content-available-state>
                  </template>
                </div>
              </div>
            </infinite-loading>
          </feeder-content-view-slot>
        </template>
      </template>
    </div>
    <!-- for modals like social share, article preview etc. -->
    <router-view></router-view>
  </div>
</template>
<script>
import ContentName from '../components/content-view/ContentName'
import ContentTotalResults from '../components/content-view/ContentTotalResults'
import FeedContentFilters from '../components/content-view/FeedContentFilters'
import FeederGridViewItem from '../components/content-view/type-of-views/FeederGridViewItem'
import FeederCoverItem from '../components/content-view/type-of-views/FeederCoverItem'
import FeederContentViewSlot from '../slots/FeederContentViewSlot'
import InfiniteLoading from 'vue-infinite-loading'
import { feederItemMixin } from '../mixins/feeder-item-mixin'
import axios from 'axios'
import http from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import { feederGroupURI } from '../../../config/api-utils'
import sortBy from 'lodash.sortby'
import {EventBus} from "@common/lib/event-bus";

const FeederListViewItem = () =>
  import('../components/content-view/type-of-views/FeederListViewItem')

export default {
  components: {
    ContentName,
    ContentTotalResults,
    FeederGridViewItem,
    FeederContentViewSlot,
    FeederCoverItem,
    InfiniteLoading,
    FeederListViewItem,
    FeedContentFilters
  },
  mixins: [feederItemMixin],
  data () {
    return {
      slide_item_animation: '',
      load_posts: false,
      feed_type: {},
      posts: [],
      total_results: 0,
      state: '',
      infiniteState: '',
      infiniteId: +new Date(),
      page: 1,
      collection_results: {
        items: [],
        loader: false
      }
    }
  },
  created () {
    this.feedAggregateContent()
    if (this.isCollectiveCollectionType) {
      this.feederAggregateCollectionViewHandler()
    }
  },
  mounted () {
    EventBus.$on('feeder_archive_action', async (value) => {
      console.log('feeder_archive_action', value)
      const res = await this.archiveAction(
        value.post_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
        if (this.isCollectiveCollectionType) {
          this.collection_results.items.forEach((item) => {
            if (item.posts && item.posts.hits && item.posts.hits.length) {
              const findPostIndex = item.posts.hits.findIndex(
                (post) => post.id === value.post_id
              )
              item.posts.hits.splice(findPostIndex, 1)
            }
            console.log(item)
          })
        }
      }
    })

    EventBus.$on('feeder_unarchive_action', async (value) => {
      console.log('feeder_unarchive_action', value)
      const res = await this.archiveAction(
        value.post_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
      }
    })

    EventBus.$on('feeder_starred_action', async (value) => {
      console.log('feeder_starred_action', value)
      const res = await this.starredAction(
        value.post_id,
        value.index,
        value.state,
        this.posts
      )
      console.log('feeder aggregate view starred action', res)

      if (res) {
        // edge case, only removes if the user is on the starred view.
        if (this.isFeederStarredType) {
          this.posts.splice(
            this.posts.findIndex((item) => value.post_id === item.id),
            1
          )
          this.total_results -= 1
        } else {
        }
      }

      // edge case for the cover stories for today and trending
      console.log(
        'feeder aggregate view starred action',
        value,
        this.isCollectiveCollectionType
      )

      if (this.isCollectiveCollectionType) {
        this.collection_results.items.forEach((item) => {
          if (item.posts && item.posts.hits && item.posts.hits.length) {
            item.posts.hits.forEach((post) => {
              console.log(
                'feeder aggregate view starred action',
                post.id,
                value.post_id
              )
              if (post.id === value.post_id) {
                post.is_starred = value.state === 'starred'
              }
            })
          }
          console.log(item)
        })
      }
    })

    EventBus.$on('feeder_starred_is_collection', async (value) => {
      console.log('feeder_starred_is_collection', value)
      this.$store
        .dispatch('feederStarredPost', {
          postId: value.post_id,
          state: value.state
        })
        .then((res) => {
          if (res.data.status) {
            console.log('feeder_starred searching for group')
            const group = this.collection_results.items.find(
              (item) => item.group.uuid === value.group_id
            )
            console.log('feeder_starred found the group', group)
            if (group) {
              group.posts.hits.forEach((postItem) => {
                console.log('feeder_starred group post item', postItem)
                if (postItem.id === value.post_id) {
                  postItem.is_starred = value.state === 'starred'
                }
              })
            }
          }
        })
        .catch((err) => {})
    })
  },
  beforeDestroy () {
    EventBus.$off('feeder_unarchive_action')
    EventBus.$off('feeder_archive_action')
    EventBus.$off('feeder_starred_action')
    EventBus.$off('feeder_starred_is_collection')
  },
  methods: {
    feederAggregateViewHandler ($state) {
      // send an individual request in case if the content type is of starred, archived etc.
      const p = this.$store.dispatch(
        'fetchFeederAggregate',
        this.$route.params.content_type
      )
      p.then((res) => {
        if (res.data.status) {
          this.total_results = res.data.data.posts.total
          this.posts = res.data.data.posts.hits
          $state.complete()
        }
      })
        .catch()
        .finally(() => {})
    },

    async feederAggregateCollectionViewHandler () {
      this.collection_results.loader = true
      this.collection_results.items = []
      const listOfRequests = []
      this.getFeederGroupList.forEach((item) => {
        const request = http.post(feederGroupURI + 'retrieve_by_id', {
          workspace_id: this.getActiveWorkspace._id,
          group_id: item.uuid,
          page: 1,
          limit: 4,
          state: this.$route.params.content_type
        })
        listOfRequests.push(request)
      })
      await axios
        .all(listOfRequests)
        .then((responses) => {
          console.log('list of batch requests responses =>', responses)
          responses.forEach((response) => {
            if (response.data.status) {
              this.collection_results.items.push(response.data.data)
            }
          })
          // use/access the results
        })
        .catch((errors) => {
          console.log('list of batch requests responses =>', errors)

          // react on errors.
        })
      this.collection_results.loader = false
    },

    feedAggregateContent () {
      this.load_posts = true
      switch (this.$route.params.content_type) {
        case 'today':
          this.feed_type.title = 'Today'
          break
        case 'starred':
          this.feed_type.title = 'Starred'
          break
        case 'unread':
          this.feed_type.title = 'Unread'
          break
        case 'saved':
          this.feed_type.title = 'Read Later'
          break
        case 'trending':
          this.feed_type.title = 'Trending'
          break
        case 'archived':
          this.feed_type.title = 'Archived'
          break
      }
      document.title = this.feed_type.title
      this.load_posts = false
    }
  },
  computed: {
    ...mapGetters([
      'getFeederGroupList',
      'getActiveWorkspace',
      'getFeederPreferences'
    ]),
    isArchiveView () {
      return (
        this.$route.params.content_type &&
        this.$route.params.content_type === 'archived'
      )
    },
    isFeederArchivedType () {
      return this.$route.params.content_type === 'archived'
    },
    isFeederStarredType () {
      return this.$route.params.content_type === 'starred'
    },
    isCollectiveCollectionType () {
      return (
        this.$route.params.content_type === 'trending' ||
        this.$route.params.content_type === 'today'
      )
    },
    getSortedCollectionItemsList () {
      return sortBy(this.collection_results.items, function (item) {
        console.log(item)
        return [item.group.name.toLowerCase()]
      })
    },
    hasCollectionItemsWithPosts () {
      let count = 0
      this.collection_results.items.forEach((item) => {
        if (item.posts && item.posts.total) {
          count += item.posts.total
        }
      })
      return count
    }
  },
  watch: {
    '$route.params.content_type' (value) {
      this.feedAggregateContent()
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
      // only for the trending and curated topics

      if (this.isCollectiveCollectionType) {
        this.feederAggregateCollectionViewHandler()
      }
    },
    async 'getActiveWorkspace._id' (value) {
      // if the user changes the workspace and he is on the today route. Get the data.
      if (this.$route.params.content_type === 'today') {
        // edge case, the data has not loaded yet for the feeder groups, therefore showing the loader and getting the groups.
        this.collection_results.loader = true
        const res = await this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
        this.feederAggregateCollectionViewHandler()
      }
    }
  }
}
</script>
