var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topics-insights-container"},[(!_vm.is_insights_retrieved)?[_c('beat-loader',{staticClass:"mt-5"})]:[_c('div',{staticClass:"topics-insights-container__tabs"},[_c('a',{class:{
          active:
            !_vm.$route.query.section || _vm.$route.query.section === 'overview',
        },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.$router.replace({ query: { ..._vm.$route.query, section: 'overview' } })}}},[_vm._v(" Overview ")]),_c('a',{class:{ active: _vm.$route.query.section === 'top_domains' },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.$router.replace({
            query: { ..._vm.$route.query, section: 'top_domains' },
          })}}},[_vm._v(" Top Domains ")]),_c('a',{class:{ active: _vm.$route.query.section === 'top_authors' },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.$router.replace({
            query: { ..._vm.$route.query, section: 'top_authors' },
          })}}},[_vm._v(" Top Authors ")])]),(!_vm.$route.query.section || _vm.$route.query.section === 'overview')?[_c('content-insights-summary',{attrs:{"total_articles":_vm.insights.hits.total,"total_engagements":_vm.insights.aggregations.total_stats.value || 0,"avg_engagements":_vm.insights.aggregations.average_stats.value || 0,"average_facebook_engagement":_vm.insights.aggregations.average_facebook.value || 0,"average_pinterest_engagement":_vm.insights.aggregations.average_pins.value || 0,"average_reddit_engagement":_vm.insights.aggregations.average_reddit.value || 0}}),_c('content-insights-articles-published-over-time',{attrs:{"articles_published_per_day":_vm.insights.aggregations.articles_published_per_day}}),_c('div',{staticClass:"topics-insights-container__row"},[_c('content-insights-average-engagement-by-network',{attrs:{"average_channel_engagements":_vm.insightsEngagementMetrics(_vm.insights.aggregations)}}),_c('content-insights-total-engagement-by-network',{attrs:{"total_channel_engagements":_vm.insightsEngagementMetrics(_vm.insights.aggregations)}})],1),_c('content-insights-popular-content-types',{attrs:{"popular_content_types":_vm.popularContentTypesList(_vm.insights.aggregations.categories_list)}}),_c('content-insights-popular-days',{attrs:{"popular_days":_vm.insights.aggregations.popular_days}}),_c('div',{staticClass:"topics-insights-container__row"},[_c('content-insights-word-cloud',{attrs:{"wordcloud_list":_vm.wordCloudListItems(
              _vm.insights.aggregations.top_popular_hashtags.buckets
            )}}),_c('content-insights-word-count',{attrs:{"wordcount":_vm.wordCountLevelList(
              _vm.insights.aggregations.temporarily_popular_word_count.buckets
            )}})],1),_c('div',{staticClass:"topics-insights-container__row"},[_c('content-insights-popular-sentiments',{attrs:{"sentiments":_vm.sentimentsMetrics(
              _vm.insights.aggregations.average_sentiment_statistics
            )}}),_c('content-insights-popular-reading-levels',{attrs:{"reading_levels":_vm.popularReadingLevels(
              _vm.insights.aggregations.popular_reading_levels.buckets
            )}})],1),_c('content-insights-top-domains',{attrs:{"top_domains":_vm.topPopularDomains(_vm.insights.aggregations.top_domains.buckets)}})]:(_vm.$route.query.section === 'top_domains')?[_c('content-insights-top-domains-table',{attrs:{"top_domains":_vm.topPopularDomains(_vm.insights.aggregations.top_domains.buckets)}})]:(_vm.$route.query.section === 'top_authors')?[_c('content-insights-top-authors-table',{attrs:{"top_authors":_vm.insights.aggregations.top_authors.buckets}})]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }