<template>
  <div class="analytic_common_component analytic_facebook_competitor_component">
    <div class="component_inner">
      <!--Competitor Activity overview-->
      <div class="card_container" style="margin-top: 25px">
        <div class="card_v1">
          <div class="card_inner">
            <div class="card_head">
              <h2>Competitor Activity Overview</h2>
            </div>
            <div class="card_content">
              <div class="social_table facebook_competitor_table">
                <div class="table_wrapper">
                  <table class="table">
                    <thead>
                      <tr>
                        <th style="width: 350px">Facebook Page</th>
                        <th class="text-center">Talking About This</th>
                        <th class="text-center">Fan Posts</th>
                        <th class="text-center">Page Posts</th>
                        <th class="text-center">Interactions</th>
                        <th class="text-center" style="width: 200px"
                          >Post Breakdown</th
                        >
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div class="profile_picture">
                            <div class="picture_block">
                              <img
                                src="../../../../assets/img/profile_default.svg"
                                alt=""
                              />
                            </div>
                            <div class="text_block">
                              <p class="text">asdasd asd </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center red_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center red_block icon_block">
                          <div class="d-flex align-items-center">
                            <p>
                              <i class="cs-link"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-gallery"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-video"></i>
                              <span>5</span>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="profile_picture">
                            <div class="picture_block">
                              <img
                                src="../../../../assets/img/profile_default.svg"
                                alt=""
                              />
                            </div>
                            <div class="text_block">
                              <p class="text">asdasd asd </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center icon_block">
                          <div class="d-flex align-items-center">
                            <p>
                              <i class="cs-link"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-gallery"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-video"></i>
                              <span>5</span>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="profile_picture">
                            <div class="picture_block">
                              <img
                                src="../../../../assets/img/profile_default.svg"
                                alt=""
                              />
                            </div>
                            <div class="text_block">
                              <p class="text">asdasd asd </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center red_block icon_block">
                          <div class="d-flex align-items-center">
                            <p>
                              <i class="cs-link"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-gallery"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-video"></i>
                              <span>5</span>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="profile_picture">
                            <div class="picture_block">
                              <img
                                src="../../../../assets/img/profile_default.svg"
                                alt=""
                              />
                            </div>
                            <div class="text_block">
                              <p class="text">asdasd asd </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center red_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td class="text-center green_block text_block">
                          <p class="title_text">156</p>
                          <p class="sub_text">-0.45/day</p>
                        </td>
                        <td
                          class="green_block text-center red_block icon_block"
                        >
                          <div class="d-flex align-items-center">
                            <p>
                              <i class="cs-link"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-gallery"></i>
                              <span>5</span>
                            </p>
                            <p>
                              <i class="cs-video"></i>
                              <span>5</span>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Fans Gained by day-->
      <div class="card_container">
        <div class="card_v1">
          <div class="card_inner">
            <div class="card_head">
              <h2>Engagement</h2>
              <ul class="item_highlight" id="tw_engagement_highlight"></ul>
            </div>
            <div class="card_content">
              <highcharts
                :options="twitterEngagement"
                ref="tw_engagement_chart"
              ></highcharts>
            </div>
          </div>
        </div>
      </div>

      <!--Engagement Rate table-->
      <div class="card_container align_70_30">
        <div class="card_v1">
          <div class="card_inner">
            <div class="card_head">
              <h2>Engagement Rate</h2>
            </div>
            <div class="card_content">
              <div class="white_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Total Engagement</th>
                      <th style="width: 100px; text-align: center">2323k</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Retweets</td>
                      <td class="text-center">235</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card_v1">
          <div class="box_no_icon_left box_no_icon_left_analytic">
            <div class="box_inner">
              <div class="text">
                <p class="top_text">Total Engagement</p>
                <h2>256k</h2>
                <!--green-->
                <p class="bottom_text red">
                  <i class="cs-arrow-down"></i>
                  13.4%
                </p>
                <p class="bottom_text dark"> since previous date range </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--audience Growth-->
      <div class="card_container">
        <div class="card_v1">
          <div class="card_inner">
            <div class="card_head">
              <h2>Audience Growth</h2>
              <ul class="item_highlight" id="audience_growth_highlight"></ul>
            </div>
            <div class="card_content">
              <highcharts
                :options="audienceGrowth"
                ref="audienceGrowth_chart"
              ></highcharts>
            </div>
          </div>
        </div>
      </div>

      <!--Engagement Rate table-->
      <div class="card_container align_70_30">
        <div class="card_v1">
          <div class="card_inner">
            <div class="card_head">
              <h2>Followers</h2>
            </div>
            <div class="card_content">
              <div class="white_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Total Followers</th>
                      <th style="width: 100px; text-align: center">2323k</th>
                    </tr>
                  </thead>
                  <!--<tbody>-->
                  <!--<tr>-->
                  <!--<td>Total Retweets</td>-->
                  <!--<td class="text-center">235</td>-->
                  <!--</tr>-->

                  <!--</tbody>-->
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card_v1">
          <div class="box_no_icon_left box_no_icon_left_analytic">
            <div class="box_inner">
              <div class="text">
                <p class="top_text">Total Followers</p>
                <h2>256k</h2>
                <!--green-->
                <p class="bottom_text red">
                  <i class="cs-arrow-down"></i>
                  13.4%
                </p>
                <p class="bottom_text dark"> since previous date range </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },

  data () {
    return {
      twitterEngagement: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          // type: 'areaspline',
          type: 'spline',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#tw_engagement_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        colors: ['#8900ff', '#00c4ff'],
        title: false,
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        plotOptions: {
          series: {
            marker: {
              enable: false,
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: null
            }
          }
        },
        series: [
          {
            name: 'Total Engagement',
            data: [235, 90, 70, 0, 0, 232, 0, 90, 0, 0, 0, 22]
          },
          {
            name: 'Total Retweets Count',
            data: [35, 0, 60, 0, 0, 232, 0, 80, 0, 0, 0, 32]
          }
        ]
      },
      audienceGrowth: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          // type: 'areaspline',
          type: 'spline',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              var chart = this
              $(chart.series).each(function (i, serie) {
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#audience_growth_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        colors: ['#ff00ce'],
        title: false,
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          }
        },
        plotOptions: {
          series: {
            marker: {
              enable: false,
              symbol: 'circle',
              lineWidth: 2,
              fillColor: 'white',
              lineColor: null
            }
          }
        },
        series: [
          {
            name: 'Total Followers',
            data: [235, 90, 70, 0, 0, 232, 0, 90, 0, 0, 0, 22]
          }
        ]
      }
    }
  },

  created () {},
  mounted () {},

  computed: {},
  methods: {},

  watch: {}
}
</script>
