import { render, staticRenderFns } from "./CustomPopover.vue?vue&type=template&id=3519e2a0&scoped=true&"
import script from "./CustomPopover.vue?vue&type=script&lang=js&"
export * from "./CustomPopover.vue?vue&type=script&lang=js&"
import style0 from "./CustomPopover.vue?vue&type=style&index=0&id=3519e2a0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3519e2a0",
  null
  
)

export default component.exports