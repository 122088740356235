<template>
  <div>
    <div class="flex justify-end items-center mb-2">

    <p class="mr-2 text-xs font-medium opacity-50 text-black">Page Score </p>
     <span class="text-cyan-300 text-xs"> {{Math.round(main_bar * 100)}}%</span>
    </div>
    <div class="helper_progres_outer w-full m-auto relative ">
      <b-progress :max="100" height="0.5rem">
      <b-progress-bar  :value="Math.round(main_bar * 100)">
      </b-progress-bar>
    </b-progress>

    <!-- <b-progress height="2rem" :value="Math.round(main_bar * 100)" :max="100"show-progress animated></b-progress> -->
           </div>
  </div>
</template>

<script>
export default {
  name: 'HelperProgress',
   props:{

    main_bar:{type:Number,required:true},

  },
  data() {
    // return {
    //   value: main_bar,
    //   max: 100,
    // }
  },
}
</script>

<style lang="less">
.helper_progres_outer {
  // width: 100%;
  // margin: 0 auto;
}
.helper_progres_outer .progress {
  border-radius: 6px !important;
}
.helper_progres_outer .progress .progress-bar {
  background-color: #0a97f3 !important;
}
.progress .progress-bar span {
  position: absolute;
  top: 0px;
  color: black;
  left: 76px;
  font-size: 14px;
}
</style>
