<template>
  <div class="min-h-full flex flex-col bg-cs-light-gray">
    <div class="flex items-center  px-6 h-16 max-h-16 min-h-16">
      <span class="font-bold text-base">Campaign Folder</span>
      <div v-if="!isOpen" v-floatingtip.hover.right="{ content: 'Create New Campaign Folder',distance: 14 }" class="mx-4 cursor-pointer w-7 h-7 bg-cs-primary text-white rounded-lg font-bold flex items-center justify-center" @click="isOpen = !isOpen"><i class="fal fa-plus"></i></div>
    </div>
    <hr class="m-0 hr-color">
    <div class="py-3.5 px-3.5 flex-grow flex-shrink flex-basis-auto overflow-y-auto h-0 mb-24">

      <template>
        <div v-if="isOpen" class="my-4">
          <input
              v-model="newFolderName"
              type="text"
              placeholder="Campaign Folder Name e.g Marketing Posts"
              class="w-full my-1 mx-0 px-3 py-2.5 text-sm rounded-lg border border-gray-300 shadow-xs font-normal text-gray-800 hover:bg-gray-50 focus:outline-none"
              @input="validations.name = false"
              @keyup.enter="createCampaign"/>
          <span v-if="validations.name" class="input-error">{{
              validations.message
            }}</span>
          <div class="flex items-center justify-end">
            <button class="block mt-3 text-sm  focus:outline-none rounded-md text-black  border-0 px-3 py-2 mr-2"  @click="isOpen = !isOpen">Cancel</button>
            <button class="block mt-3 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2" @click="createCampaign">Create Folder</button>
          </div>

        </div>
        <!-- ***************** campaign radio group boxes **************** -->
        <template v-if="!isOpen">
          <div  class="flex items-center cursor-pointer flex flex-row w-full items-center rounded-md px-2 py-2  hover:bg-cs-foggray">
            <input
                id="default_folder"
                v-model="planFolder"
                class=" h-4 w-4 cursor-pointer"
                type="radio"
                name="campaign_select"
            />
            <label for="default_folder" class="w-full">
                     <span  class="block font-medium text-sm text-gray-900 py-2">
                         {{getDefaultName}}
                     </span>
            </label>
          </div>
          <template v-for="(folder,index) in campaigns">
            <div :key="index" class="flex items-center cursor-pointer flex flex-row w-full items-center rounded-md px-2 py-2  hover:bg-cs-foggray">
              <input
                  :id="folder._id"
                  v-model="planFolder"
                  class=" h-4 w-4 cursor-pointer"
                  :value="folder._id"
                  type="radio"
                  name="campaign_select"
              />
              <label :for="folder._id" class="w-full">
                     <span :id="folder._id" class="block font-medium text-sm text-gray-900 py-2">
                         {{folder.name}}
                     </span>
              </label>
            </div>
          </template>
        </template>
      </template>

    </div>
  </div>
</template>

<script>
import {COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED} from "@common/constants/messages";
export default {
  name: "Campaigns",
  components: {

  },
  props:{
    campaigns: {
      type: Array,
      default:()=>[],
      required: false
    },
    folderId:{
      type: String,
      default:'',
      required: false
    },
  },
  data() {
    return {
      isOpen:false,
      newFolderName:'',
      planFolder:this.folderId,
      validations: {
        name: false,
        message:COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED
      },
    }
  },
  computed:{
    getDefaultName(){
        if (this.getWorkspaces.activeWorkspace && this.getWorkspaces.activeWorkspace.default_campaign_name) {
          return this.getWorkspaces.activeWorkspace.default_campaign_name
        }
        return 'Untitled Campaign'
    },
    // getSelectedCampaign(){
    //   if(this.folderId && this.campaigns.find(campaign => campaign._id === this.folderId)){
    //       return this.campaigns.find(campaign => campaign._id === this.folderId).name
    //   }
    //  else if(this.campaigns.find(campaign => campaign._id === this.getPublishSelection.folderId)){
    //     return this.campaigns.find(campaign => campaign._id === this.getPublishSelection.folderId).name
    //   }
    //   else if(this.getWorkspaces.activeWorkspace.default_campaign_name){
    //     return this.getWorkspaces.activeWorkspace.default_campaign_name
    //   }
    //   else{
    //     return 'Untitled Campaign'
    //   }
    // }
  },
  watch:{
    planFolder(val) {
      this.$emit('handle-campaigns-changes','set', val)
    },
  },
  methods:{
    // setCampaign(folderId){
    //   this.$emit('handle-campaigns-changes','set', folderId)
    // },
    createCampaign(){
      if (this.newFolderName) {
        this.$emit('handle-campaigns-changes', 'create', this.newFolderName)
        this.newFolderName = ''
        this.isOpen = false
      } else {
        this.validations.name = true
      }
    }
  }

}
</script>

<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  @apply  text-gray-800
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(58,69,87,.3);
  border-radius: 100%;
  background: #fbfcfc;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 18px;
  height: 18px;
  border: 3px solid #549aee;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:checked + label {
  @apply text-gray-900
}
</style>