<template>
  <div class="view_change_btn d-flex align-items-center">
    <div class="view_change_btn_inner ml-auto" v-if="can_change_view">
      <p
        v-tooltip.top-center="'Grid View'"
        class="grid_icon"
        @click.prevent="changeViewLayout('grid')"
        :class="{ active: getViewLayoutValue === 'grid' }"
      >
        <i class="icon-groups-cs"></i>
      </p>
      <p
        class="list_icon"
        v-tooltip.top-center="'List View'"
        @click.prevent="changeViewLayout('list')"
        :class="{ active: getViewLayoutValue === 'list' }"
      >
        <i class="icon-Listview_icon"></i>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    can_change_view: {},
    total_results: 0
  },
  computed: {
    getViewLayoutValue () {
      if (this.$route.query.view) {
        return this.$route.query.view === 'grid' ? 'grid' : 'list'
      }
      return 'grid'
    }
  },
  methods: {
    changeViewLayout (view) {
      this.$router.replace({ query: { ...this.$route.query, view: view } })
    }
  }
}
</script>
