<template>
  <div class="article-grid-view-container">
    <div class="article-grid-view-container__inner">
      <div
        class="article-grid-view-container__inner__image_block article-grid-view-container__inner__image_block--youtube-thumbnail"
      >
        <div
          v-if="video.snippet.thumbnails.maxres"
          class="article-grid-view-container__inner__image"
          v-lazy:background-image="
            videoImageURL(video.snippet.thumbnails.maxres, 'youtube')
          "
        ></div>
        <div
          v-else-if="video.snippet.thumbnails.high"
          class="article-grid-view-container__inner__image"
          v-lazy:background-image="
            videoImageURL(video.snippet.thumbnails.high, 'youtube')
          "
        ></div>
        <div
          v-else-if="video.snippet.thumbnails.default"
          class="article-grid-view-container__inner__image"
          v-lazy:background-image="
            videoImageURL(video.snippet.thumbnails.default, 'youtube')
          "
        ></div>
        <img
          @click.prevent="previewVideoDialog()"
          class="article-grid-view-container__inner__image--youtube-icon"
          src="../../../assets/youtube_icon.png"
          alt=""
          draggable="false"
        />
      </div>
      <div class="article-grid-view-container__inner__content_block">
        <div class="article-grid-view-container__inner__content_block__height">
          <h2
            @click.prevent="previewVideoDialog()"
            v-html="video.snippet.title"
            >{{ video.snippet.title }}</h2
          >

          <div
            class="clear article-grid-view-container__inner__content_block__information"
          >
            <div class="d-flex flex-row align-items-center">
              <p
                class="article-grid-view-container__inner__content_block__information__author"
                >By
                <span
                  ><b>{{ video.snippet.channelTitle }}</b></span
                ></p
              >

              <div
                class="article-grid-view-container__inner__content_block__information__author ml-2"
              >
                <p class="full_width_author">{{
                  videoDate('youtube', video.snippet.publishedAt)
                }}</p>
              </div>
            </div>
          </div>

          <!-- previewVideo(video.snippet.title,video.snippet.description,videoEmbedUrl(video.id,'youtube'),'Youtube')-->
        </div>
        <div
          class="article-grid-view-container__inner__content_block__social_statistics"
        >
          <div
            class="article-grid-view-container__inner__content_block__social_statistics__inner d-flex"
          >
            <div
              class="popover-social-statistics article-grid-view-container__inner__content_block__social_statistics__inner__item"
            >
              <div class="name popover-social-statistics__container">
                <p>View Count</p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.statistics.viewCount) }}</h3>
              </div>
            </div>
            <div
              class="popover-social-statistics article-grid-view-container__inner__content_block__social_statistics__inner__item"
            >
              <div class="name popover-social-statistics__container">
                <p>Like Count</p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.statistics.likeCount) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="article-grid-view-container__inner__action_buttons">
          <b-dropdown
            variant="studio-icon-theme"
            id="dropdown-share-articles"
            class="studio-icon-theme-dropdown mr-3"
            no-caret
          >
            <template slot="button-content">
              <i
                v-tooltip="'Share this post'"
                class="far fa-share-alt icon"
              ></i>
            </template>
            <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="
                initializeVideoPostCreation(video, 'social_share_modal')
              "
              ><i class="far fa-share"></i> Social Media
            </b-dropdown-item>
            <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="
                initializeVideoPostCreation(video, 'blog_share_modal')
              "
            >
              <i class="far fa-rss"></i> Blog Post
            </b-dropdown-item>
          </b-dropdown>

          <div class="dropdown b-dropdown studio-icon-theme-dropdown btn-group">
            <button
              class="btn btn-studio-icon-theme"
              target="_blank"
              @click="
                openWindow(
                  savePocketContent(
                    'https://www.youtube.com/watch?v=' + video.id
                  )
                )
              "
              style=""
            >
              <i v-tooltip="'Save to Pocket'" class="cs-pocket icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@common/lib/event-bus'

export default {
  props: {
    video: {}
  },
  methods: {
    previewVideoDialog () {
      const payload = {
        title: this.video.snippet.title,
        url: this.videoEmbedUrl(this.video.id, 'youtube'),
        description: this.video.snippet.description,
        source: 'youtube',
        height: 350
      }
      EventBus.$emit('video-preview-dialog', payload)
      this.$bvModal.show('videoPreviewDialog')
    },
    openWindow (link) {
      window.open(link)
    }
  }
}
</script>
