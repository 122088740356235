import { mapGetters } from 'vuex'
const blogComposerMixin = {
  methods: {
    getBlogTitle (lowerCase = true) {
      // if (this.getBlogPostingCustomizeSeoPreviewStatus) {
      //   if (lowerCase) {
      //     return this.getBlogPostingDetails.metaTitle.toLowerCase()
      //   } else {
      //     return this.getBlogPostingDetails.metaTitle
      //   }
      // }
      if (lowerCase) {
        return this.getBlogPostingDetails.title.toLowerCase()
      } else {
        return this.getBlogPostingDetails.title
      }
    },

    getBlogDescription () {
      // if (this.getBlogPostingCustomizeSeoPreviewStatus) {
      //   return this.getBlogPostingDetails.metaDescription.toLowerCase()
      // }
      return this.limitTextLength(
        this.getEditorMetaDescription().toLowerCase(),
        153
      )
    },

    getEditorMetaDescription () {
      try {
        return $(this.getBlogPostingHTML).text()
      } catch (e) {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getBlogPostingCustomizeSeoPreviewStatus',
      'getBlogPostingDetails',
      'getBlogEditorSelector'
    ])
  }
}
export { blogComposerMixin }
