<template>
  <div class="setting_profile_component refer_earn_section">
    <div class="component_inner">
      <div
        class="ps_box"
        v-if="getProfile.first_promoter && getProfile.first_promoter.auth_token"
      >
        <h2 class="box_heading">Affiliate Program</h2>

        <div class="box_content">
          <div class="d-flex align-items-start">
            <iframe
              height="850px"
              width="100%"
              frameborder="0"
              :src="
                'https://contentstudio.firstpromoter.com//iframe?at=' +
                getProfile.first_promoter.auth_token
              "
            ></iframe>
          </div>
        </div>
      </div>
      <div class="ps_box p-4" v-else>
        <h1 class="box_heading text_center">Earn 30% Recurring Commissions!</h1>

        <div class="box_content">
          <p class="text_center p-3">Here’s how our affiliate program works:</p>

          <div class="row ico_contain">
            <div class="col-md-3">
              <div>
                <img
                  width="100%"
                  src="../../../assets/img/settings/rounded_shape_refer.png"
                />
                <img
                  class="round_ico"
                  src="../../../assets/img/settings/refer_join.png"
                />
              </div>
              <h2>1. Join</h2>
              <p>Sign up for the ContentStudio affiliate program.</p>
            </div>

            <div class="col-md-3">
              <div>
                <img
                  width="100%"
                  src="../../../assets/img/settings/rounded_shape_refer.png"
                />
                <img
                  class="round_ico"
                  src="../../../assets/img/settings/refer_share.png"
                />
              </div>
              <h2>2. Refer</h2>
              <p
                >Share your unique affiliate link with your friends and
                followers.</p
              >
            </div>

            <div class="col-md-3">
              <div>
                <img
                  width="100%"
                  src="../../../assets/img/settings/rounded_shape_refer.png"
                />
                <img
                  class="round_ico"
                  src="../../../assets/img/settings/refer_earn.png"
                />
              </div>
              <h2>3. Earn</h2>
              <p
                >Receive 30% commission on every sale we get from your affiliate
                link.</p
              >
            </div>
          </div>

          <div class="row content_contain">
            <p
              ><b>Note:</b> It’s not just that one payment when they sign up.
              That 30% commission will be paid to you every month for as long as
              their ContentStudio account is active.</p
            >
            <p
              >Through our affiliate program you’re not only earning extra money
              for you and your family, you’re also helping others streamline
              their social media and content marketing with ContentStudio.</p
            >
            <button
              class="btn btn-studio-theme-space m-4"
              @click.prevent="becomeAffiliate()"
              >Become an affiliate
              <!--                          <clip-loader color="#ffffff" :size="'12px'" v-if="loader"></clip-loader>-->
            </button>
          </div>
        </div>
      </div>

      <!--          <template>-->
      <!--            <beat-loader class="mt-5" :color="'#436aff'"></beat-loader>-->
      <!--          </template>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'refer&earn.vue',
  data () {
    return {
      loader: false
    }
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    async becomeAffiliate () {
      // this.loader = true
      const res = await this.$store.dispatch('createPromoter')
      // console.debug('here', res)
      // if (res.status) this.loader = false
    }
  }
}
</script>

<style scoped></style>
