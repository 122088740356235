/**
 * For all the services calls to the backend for analytics v2
 */

import proxy from '@common/lib/http-common'
import { analyticsBaseUrl } from '@src/config/api-utils'

export const fetchAnalyticsData = (endpoint = '', payload = {}) => {
  return new Promise((resolve, reject) => {
    proxy
      .post(
        `${analyticsBaseUrl}analytics/overview/instagram/competitor/${endpoint}`,
        payload,
        {}
      )
      .then((response) => {
        if (response?.data?.data || response?.data?.res) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) reject(error.response.data)
        else reject(error)
      })
  })
}
