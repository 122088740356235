<template>
  <div class="analytics-wrapper">
    <!--  Side Menu  -->
    <analytic-preview></analytic-preview>
    <send-report-by-email-modal></send-report-by-email-modal>
    <schedule-report-modal></schedule-report-modal>
    <div class="analytics-sidebar">
      <nav class="analytics-sidebar__nav">
        <!-- Side Menu Title -->
        <div class="analytics-sidebar__nav-header"> Reports Hub </div>

        <!-- Side Menu Item -->
        <router-link
          :to="{ name: 'analytics_overview' }"
          class="analytics-sidebar__nav-item"
        >
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-groups-cs"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label">Overview</span>
        </router-link>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-twitter_icon"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label">Twitter</span>
        </router-link>

        <!-- Side Menu Item -->
        <router-link
          :to="{ name: 'analytics_facebook' }"
          class="analytics-sidebar__nav-item"
        >
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-facebook_icon"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label">Facebook</span>
        </router-link>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-instagram_icon"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label">Instagram</span>
        </router-link>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-linkedin_icon"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label">Linkedin</span>
        </router-link>
      </nav>

      <nav class="analytics-sidebar__nav">
        <!-- Side Menu Title -->
        <div class="analytics-sidebar__nav-header"> Manage Reports </div>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-reports_icon"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label"
            >Scheduled Reports</span
          >
        </router-link>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-dropdown-cs"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label"
            >Download Reports</span
          >
        </router-link>

        <!-- Side Menu Item -->
        <router-link to="#" class="analytics-sidebar__nav-item">
          <span class="analytics-sidebar__nav-item-icon"
            ><i class="icon-settings-cs"></i
          ></span>
          <span class="analytics-sidebar__nav-item-label"
            >Reports Settings</span
          >
        </router-link>
      </nav>
    </div>
    <!--  Main Content  -->
    <div class="analytics-content">
      <!-- INFO: keep-alive makes rendering component faster -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import SendReportByEmailModal from '@src/modules/analytics/components/reports/modals/SendReportByEmailModal'
import ScheduleReportModal from '@src/modules/analytics/components/reports/modals/ScheduleReportModal'
import AnalyticPreview from '@src/modules/analytics/components/common/AnalyticPreview'

import { mapActions } from 'vuex'
export default {
  name: 'AnalyticsMain',
  components: {
    AnalyticPreview,
    SendReportByEmailModal,
    ScheduleReportModal
  },
  async mounted () {
    this.fetchSocialService({ value: true })
    await this.fetchSocialAccounts()
    this.fetchSocialService({ value: false })
  },
  methods: {
    ...mapActions(['fetchSocialService', 'fetchSocialAccounts'])
  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchSocialService({ value: true })
        await this.fetchSocialAccounts()
        this.fetchSocialService({ value: false })
      }
    }
  }
}
</script>
