<template>
  <div :class="classObject" class="text-black-700 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500" @click="handleVisibility">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CstDropdownItem',
  inject: ['ctx'],
  props: {
    size: {
      type: String,
      default: 'small'
    },
    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classObject: function () {
      return {
        'px-3 py-2.5 text-sm':  this.size === 'small',
        'px-3 py-2.5 text-base':  this.size === 'medium',
        'px-3 py-3 text-lg':  this.size === 'large',
      }
    }
  },
  methods: {
    handleVisibility: function () {
       // console.log('Method::handleVisibility ~ selected -> ',this.ctx.$slots.selected[0].text,this.$slots.default[0].text);

      this.ctx.isOpen = !this.closeOnClick
      this.$emit('click')
    }
  }
}
</script>

