<template>
  <div class="article-search-results-did-you-mean" v-if="did_you_mean_text">
    <p
      >No result found for <span class="bold">{{ did_you_mean_text }}</span></p
    >
    <p
      class="red_text"
      @click.prevent="
        $router.push({ name: $route.name, query: { q: did_you_mean_text } })
      "
    >
      Did you mean:
      <span class="did_you_mean_text">{{ did_you_mean_text }}</span
      >?
    </p>
    <div class="tips_list">
      <p>Search tips:</p>
      <ul class="">
        <li>Check the spelling of your keywords.</li>
        <li>Try using fewer, different or more general keywords.</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    did_you_mean_text: {}
  }
}
</script>
