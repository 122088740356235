<template>
  <div class="dashboard-pie-chart-container">
    <div class="dashboard-comparison-across-networks-chart">
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-one-third-width-padding"
      >
        <div>
          <img
            src="@assets/img/states/no-analytical-data-available.svg"
            style="height: 100px"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
      <!--<div class="center_chart_text">
          <i class="cs-facebook fb"></i>
          <p>{{ comparisonData['facebook'] + comparisonData['twitter'] + comparisonData['pinterest'] +
          comparisonData['instagram'] + comparisonData['linkedin'] }}%</p>
        </div>-->
      <highcharts
        :ref="comparison_ref"
        :options="comparisonInteractions()"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { NO_CHART_DATA } from '@src/modules/common/constants/messages'
import {
  pieChart,
  piePlotOptions,
  pieTitle,
  pieTooltip,
  splineCredits,
} from '@src/modules/analytics/components/common/helper'
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    comparisonData: {
      facebook: 0,
      twitter: 0,
      instagram: 0,
      pinterest: 0,
      linkedin: 0,
    },
  },
  data() {
    return {
      comparison_ref: 'comparison_across_networks',
      no_data_available: NO_CHART_DATA,
    }
  },
  computed: {
    isContentNotAvailable() {
      return (
        this.comparisonData.facebook +
          this.comparisonData.twitter +
          this.comparisonData.instagram +
          this.comparisonData.linkedin ===
        0
      )
    },
  },
  methods: {
    comparisonInteractions() {
      const tooltip = pieTooltip()
      tooltip.pointFormat =
        '<p class="engagement_point"><span>Engagement for <strong>{point.name}</strong> is <strong>{point.y}%</strong> </span></p>'
      return {
        credits: splineCredits(),
        chart: pieChart(`#${this.comparison_ref}`),
        plotOptions: piePlotOptions(),
        colors: ['#1778f2', '#1cacee', '#1076a8', '#e4405f', '#e02f5f'],
        tooltip: tooltip,
        title: pieTitle(),
        series: [
          {
            id: `#${this.comparison_ref}`,
            name: 'Comparison By Network',
            size: '80%',
            innerSize: '55%',
            data: [
              // ['Impressions', this.interaction.facebook.total_impressions.value],
              ['Facebook', this.comparisonData.facebook],
              ['Twitter', this.comparisonData.twitter],
              ['LinkedIn', this.comparisonData.linkedin],
              ['Instagram', this.comparisonData.instagram],
              // ['Pinterest', this.comparisonData['pinterest']]
            ],
          },
        ],
      }
    },
  },
}
</script>
