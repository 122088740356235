var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytic_component"},[_c('div',{staticClass:"analyze_container"},[_c('div',{staticClass:"section"},[(_vm.loader)?[_c('div',{staticClass:"component_inner"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]:_c('div',{staticClass:"summary_row white_box"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"left"},[_c('section-titles',{attrs:{"name":'Instagram Audience Demographics'}})],1)]),(_vm.metrics.hasOwnProperty('status'))?[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner align-items-center"},[_vm._m(0),_c('div',{staticClass:"text flex-row"},[_c('div',{staticClass:"line_bars"},[_vm._l((_vm.metrics.audience_age.value),function(age){return [_c('div',{staticClass:"bar_item"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(age['key'].replace('_', '-'))+" "),_c('span',[_vm._v(_vm._s(parseInt(age['event_value']['value'])))])]),_c('div',{staticClass:"item_inner"},[_c('div',{staticClass:"limit_add",style:('width: ' +
                              (age['event_value']['value'] /
                                _vm.metrics.audience_age.max) *
                                100 +
                              '%;')}),_c('div',{staticClass:"limit_exceed"})])])]})],2)])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner align-items-center"},[_vm._m(1),_c('div',{staticClass:"text flex-row"},[_c('fans-by-gender',{attrs:{"fans":_vm.metrics.audience_gender}})],1)])])]),_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner text-center"},[_c('div',{staticClass:"text flex-row"},[_c('h4',{staticClass:"normal-text"},[_c('b',[_vm._v(_vm._s(_vm.getGender(_vm.metrics.audience_gender_age.event_key[0])))]),_vm._v(" between the ages of "),_c('b',[_vm._v(_vm._s(_vm.metrics.audience_gender_age.event_key .substring(2) .replace('_', '-')))]),_vm._v(" appear to be the leading force among your followers. ")])])])])]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner align-items-center"},[_vm._m(2),_c('div',{staticClass:"flex-row"},[_vm._l((_vm.metrics.audience_country),function(audience_value,audience_key,index){return (index < 10)?[_c('top-country',{attrs:{"type":'country',"name":audience_key,"value":audience_value}})]:_vm._e()})],2)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner text-center"},[_vm._m(3),_c('div',{staticClass:"flex-row"},[_vm._l((_vm.metrics.audience_city),function(audience_value,audience_key,index){return (index < 10)?[_c('top-country',{attrs:{"name":audience_key,"value":audience_value,"type":'city'}})]:_vm._e()})],2)])])])]:[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_inner align-items-center"},[_c('no-demographics')],1)])])]],2)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row"},[_c('h4',{staticClass:"top_text"},[_vm._v("Audience by Age ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row"},[_c('h4',{staticClass:"top_text"},[_vm._v("Audience by Gender ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row"},[_c('h4',{staticClass:"top_text"},[_vm._v("Audience Top Countries ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row"},[_c('h4',{staticClass:"top_text"},[_vm._v("Audience Top Cities ")])])
}]

export { render, staticRenderFns }