import Vue from 'vue'

export class VueRenderer {
  constructor(component, props) {
    const Component = Vue.extend(component)

    this.ref = new Component(props).$mount()
  }

  get element() {
    return this.ref.$el
  }

  updateProps(props = {}) {
    if (!this.ref.$props) {
      return
    }

    // prevents `Avoid mutating a prop directly` error message
    const originalSilent = Vue.config.silent

    Vue.config.silent = true

    Object
        .entries(props)
        .forEach(([key, value]) => {
          this.ref.$props[key] = value
        })

    Vue.config.silent = originalSilent
  }

  destroy() {
    this.ref.$destroy()
  }
}
