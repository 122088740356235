<template>
  <div>
    <div
      class="topic_content_box _pocket _article"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
      v-for="(post, k) in getPocketPosts"
      :key="k"
    >
      <div
        class="box_inner d-flex"
        @dragstart="dragstartHandler($event, post)"
        :draggable="$route.name === 'composerBlog'"
      >
        <div class="image_block">
          <div class="img" v-lazy:background-image="post.image">
            <p class="top_tag content_type_article content_type_bottom_right">
              <span>{{ pocketContentType(post) }}</span>
            </p>
          </div>
        </div>
        <div class="content_block">
          <div class="content_block_inner">
            <h3
              v-if="pocketContentType(post) !== 'Image'"
              :title="post.title"
              @click.prevent="
                $store.dispatch('pocketContentPreview', post.given_url)
              "
              >{{ limitTextLength(post.title, 61) }}</h3
            >
            <h3
              style="text-decoration: none !important; cursor: default"
              v-else
              :title="post.title"
              >{{ limitTextLength(post.title, 61) }}</h3
            >
            <div class="detail_box d-flex align-items-center">
              <a class="domain_name" href="javascript:;">{{
                trimURL(post.domain)
              }}</a>
            </div>
            <div class="detail_box d-flex align-items-center">
              <p class="date">{{ unixFromNow(post.time_added) }}</p>
              <p class="author" v-if="post.authors"
                >By <span>{{ getAuthorNames(post.authors) }}</span></p
              >
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_article_block"
          contenteditable="false"
        >
          <div class="top_option no_flickr">
            <div class="right_block">
              <i
                class="handler fa fa-hand-paper-o editor_tooltip"
                title="Move"
              ></i>
              <i
                class="edit_box fa fa-check-circle editor_tooltip green"
                title="Merge"
              ></i>
              <i
                class="remove_box fa fa-times-circle editor_tooltip red"
                title="Remove"
              ></i>
            </div>
          </div>
          <div class="editor_dragable_box_inner clear">
            <!--<br>-->
            <h2>{{ post.title }}</h2>
            <p
              ><img
                style="
                  width: 225px;
                  float: left;
                  margin-left: 0.625rem;
                  margin-right: 0.625rem;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                :src="backgroundImageURL(post)"
                alt=""
            /></p>
            <p>{{ limitTextLength(post.description, 200) }}</p>
            <a
              style="text-decoration: underline"
              target="_blank"
              :href="post.given_url"
              >Read more</a
            >
            <br />
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getPocketPosts'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      console.log('dragstartHandler', post)
      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'article',
          heading: post.title,
          media: post.image,
          p: post.title || '',
          link: post.given_url || ''
        })
      )
    }
  }
}
</script>
