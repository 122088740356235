var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi_select"},[(_vm.getTopicSelection.sources == 'topics_and_keywords')?_c('div',{staticClass:"input_field"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(0),(
          this.getTopicSelection.limit_domains_results.length > 0 ||
          (this.limitDomainsResultsValue !== null &&
            this.limitDomainsResultsValue.replace(/^\s+/, '') !== '')
        )?_c('button',{staticClass:"reset_btn",on:{"click":function($event){return _vm.clearAllKeywords()}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Clear")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.limitDomainsResultsValue),expression:"limitDomainsResultsValue"}],staticClass:"prevent-listener",attrs:{"type":"text","placeholder":"Enter domains to see results from only these sites… e.g cnn.com, bbc.com","data-cy":"include-domain"},domProps:{"value":(_vm.limitDomainsResultsValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.limitDomainsResultsValue=$event.target.value},function($event){return _vm.asyncLimitDomainResults(_vm.limitDomainsResultsValue)}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.limit_domains_results,
          _vm.limitDomainsResultsValue,
          'limit_domains_results',
          $event
        )},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==188)return null;return _vm.addAnyKeyword(
          _vm.getTopicSelection.limit_domains_results,
          _vm.limitDomainsResultsValue,
          'limit_domains_results',
          $event
        )}]}}),_c('div',{staticClass:"multi_input"},[(_vm.isLimitDomainsLoading && _vm.limitDomainsResultsValue.length >= 3)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner"},[_c('ul',_vm._l((_vm.limit_domains_suggestion_list),function(suggested_domain,index){return _c('li',{staticClass:"item d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.addLimitDomain(suggested_domain, index)}}},[(!suggested_domain.is_parent)?_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-rss"})]):(suggested_domain.favicon_16)?_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":suggested_domain.favicon_16,"alt":""}})]):_c('span',{staticClass:"icon world_icon_size"},[_c('i',{staticClass:"cs-world-o"})]),_c('span',{staticClass:"text"},[_c('span',{staticClass:"url",domProps:{"innerHTML":_vm._s(
                    _vm.highlightText(
                      suggested_domain.url,
                      _vm.limitDomainsResultsValue
                    )
                  )}})]),_vm._m(1,true)])}),0)])]):(_vm.limitDomainsLoader)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"multi_dropdown"},[_c('div',{staticClass:"multi_dropdown_inner min_height d-flex align-items-center justify-content-center"},[_c('beat-loader',{attrs:{"color":'#436aff'}})],1)]):_vm._e()]),(_vm.getTopicSelection.limit_domains_results)?[_c('div',{staticClass:"multi_tags"},[_c('ul',_vm._l((_vm.getTopicSelection.limit_domains_results),function(limit_domain_keyword,index){return _c('li',{staticClass:"green"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(limit_domain_keyword))]),_c('span',{staticClass:"remove",on:{"click":function($event){$event.preventDefault();return _vm.removeKeyword(_vm.getTopicSelection.limit_domains_results, index)}}},[_vm._v("×")])])}),0)])]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_c('span',{staticClass:"bold"},[_vm._v("LIMIT")]),_vm._v(" results to these domains only")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"view"},[_c('i',{staticClass:"fa fa-eye"})])
}]

export { render, staticRenderFns }