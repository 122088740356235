var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"write_block",class:_vm.isRoot ? '' : 'inbox_reply_comment'},[_c('SavedReplyModal',{attrs:{"active-inbox-detail":_vm.activeInboxDetail,"apply-saved-reply":_vm.applySavedReply}}),_c('div',{staticClass:"d-flex align-items-start"},[_c('div',{staticClass:"image_block",staticStyle:{"margin":"auto"}},[_c('div',{staticClass:"img",style:('background: url(' +
          _vm.commonMethods.getSocialDetail(_vm.temp, _vm.post).image +
          ')')})]),_c('div',{staticClass:"text_area relative"},[_c('div',{staticClass:"comment_tool",staticStyle:{"padding-top":"0.45rem","padding-right":"0.45rem"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Saved Replies'),expression:"'Saved Replies'",modifiers:{"bottom-center":true}}],staticClass:"icon-Save attachment_btn m-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$bvModal.show('savedReplyModal')}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Add Attachment'),expression:"'Add Attachment'",modifiers:{"bottom-center":true}}],staticClass:"attachment_btn pr-1"},[_vm._m(0),_c('input',{ref:"inputFile",attrs:{"id":"attachment_file","type":"file","accept":"*"},on:{"change":function($event){return _vm.attachMedia($event)}}})])]),_c('b-form-textarea',{staticStyle:{"box-shadow":"none","overflow-y":"hidden","padding":"15px","min-height":"60px"},attrs:{"id":"mainComment","placeholder":"Write a comment...","name":"","max-rows":"6"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.replyToComments(
            _vm.isRoot ? null : _vm.rootCommentIndex,
            $event,
            _vm.isRoot ? 'new' : ''
          )}},model:{value:(_vm.typedComment),callback:function ($$v) {_vm.typedComment=$$v},expression:"typedComment"}})],1)]),(_vm.attachment !== null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.attachment.name),expression:"attachment.name",modifiers:{"top-center":true}}],staticClass:"attachment_file attachment_file_post"},[_c('i',{staticClass:"d-flex align-items-center justify-content-center attachment-type fal fa-file"}),_vm._v(" "+_vm._s(_vm.commonMethods.limitText(_vm.attachment.name, 15))+" "),_c('i',{staticClass:"d-flex align-items-center justify-content-center cross_btn fal fa-times",on:{"click":_vm.removeMedia}})]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"attachment_file"}},[_c('i',{staticClass:"option_icon icon-Image"})])
}]

export { render, staticRenderFns }