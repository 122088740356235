var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inbox_topbar"},[(_vm.activeInboxDetail)?_c('div',{staticClass:"inbox_header inbox_topbar_inner d-flex align-items-center"},[_c('div',{staticClass:"top_left"},[_c('div',{staticClass:"inbox_head_dropdown dropdown option_dropdown default_style_dropdown checkbox_dropdown with_background"},[_c('div',{staticClass:"action_icon assign_conversation inbox_head_dropdown prevent_close_dropdown dropdown option_dropdown default_style_dropdown checkbox_dropdown with_background",attrs:{"data-toggle":"dropdown"}},[_c('i',{staticClass:"icon-Assigned"}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(!_vm.activeInboxDetail.inbox_details.assigned.is_assigned ? 'Assign' : 'Assigned to ' + _vm.activeInboxDetail.inbox_details.assigned.assigned_to .user_name)+" ")]),_c('i',{staticClass:"icon-dropdown-cs ml-2"})]),_c('div',{staticClass:"dropdown-menu dropdown-menu-left"},[_c('ul',{staticClass:"inner"},[_c('li',{staticClass:"block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200"},[_c('div',{staticClass:"radio_input_image block"},[_c('div',{staticClass:"profile_picture d-flex align-items-center",on:{"click":function($event){return _vm.assignConversation(0, null)}}},[_c('div',{staticClass:"picture_block picture_block_background pl-2"},[_c('div',{staticClass:"img",style:('background: url(' +
                        _vm.commonMethods.getDefaultImage() +
                        ');')})]),_vm._m(0),(
                      !_vm.activeInboxDetail.inbox_details.assigned.is_assigned
                    )?_c('div',{staticClass:"check_assign ml-auto"},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()])])]),_vm._l((_vm.getWorkspaceInboxTeamMembers),function(member){return [(member.user)?[_c('li',{key:member.user._id,staticClass:"block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200"},[_c('div',{staticClass:"radio_input_image block"},[_c('div',{staticClass:"profile_picture d-flex align-items-center",on:{"click":function($event){return _vm.assignConversation(member.user._id, member.user)}}},[_c('div',{staticClass:"picture_block picture_block_background pl-2"},[(member.user.image)?_c('div',{staticClass:"img",style:('background: url(' + member.user.image + ');')}):_c('div',{staticClass:"img",style:('background: url(' +
                            _vm.commonMethods.getDefaultImage() +
                            ');')})]),_c('div',{staticClass:"text_block pl-2"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(member.user.full_name))])]),(
                          _vm.activeInboxDetail.inbox_details.assigned
                            .is_assigned &&
                          _vm.activeInboxDetail.inbox_details.assigned.assigned_to
                            .user_id === member.user._id
                        )?_c('div',{staticClass:"check_assign ml-auto"},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()])])])]:_vm._e()]})],2)])]),(_vm.actionForAssign)?_c('clip-loader',{staticClass:"d-inline-block align-middle pl-3",attrs:{"color":"#989eb5","size":'20px'}}):_vm._e()],1),_c('div',{staticClass:"top_right ml-auto"},[(_vm.actionForToolTips)?_c('clip-loader',{staticClass:"d-inline-block align-middle",attrs:{"color":"#989eb5","size":'20px'}}):_vm._e(),(_vm.activeInboxDetail.inbox_details.archived.is_archived === false)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:(
          _vm.activeInboxDetail.inbox_details.marked_done.is_marked_done
            ? 'Undone'
            : 'Done'
        ),expression:"\n          activeInboxDetail.inbox_details.marked_done.is_marked_done\n            ? 'Undone'\n            : 'Done'\n        ",modifiers:{"bottom-center":true}}],staticClass:"icon-Cheecked action_icon",on:{"click":function($event){return _vm.changeConversationStatus(
            'marked_done',
            !_vm.activeInboxDetail.inbox_details.marked_done.is_marked_done
          )}}}):_vm._e(),(
          _vm.activeInboxDetail.inbox_details.marked_done.is_marked_done === false
        )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:(
          _vm.activeInboxDetail.inbox_details.archived.is_archived
            ? 'Unarchive'
            : 'Archive'
        ),expression:"\n          activeInboxDetail.inbox_details.archived.is_archived\n            ? 'Unarchive'\n            : 'Archive'\n        ",modifiers:{"bottom-center":true}}],staticClass:"icon-Archived-2 action_icon",on:{"click":function($event){return _vm.changeConversationStatus(
            'archived',
            !_vm.activeInboxDetail.inbox_details.archived.is_archived
          )}}}):_vm._e(),(_vm.activeInboxDetail.element_details.link !== null)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:({
          content: _vm.activeInboxDetail.platform === 'google_my_business' ? 'View on GMB location' : 'View on ' + _vm.capitalizeFirstLetter(_vm.activeInboxDetail.platform),
          trigger: 'hover',
        }),expression:"{\n          content: activeInboxDetail.platform === 'google_my_business' ? 'View on GMB location' : 'View on ' + capitalizeFirstLetter(activeInboxDetail.platform),\n          trigger: 'hover',\n        }",modifiers:{"bottom-center":true}}],staticClass:"icon-Link action_icon",attrs:{"href":_vm.activeInboxDetail.element_details.link,"target":"_blank"}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text_block pl-2"},[_c('p',{staticClass:"text"},[_vm._v("Unassigned")])])
}]

export { render, staticRenderFns }