<template>
  <div class="main w-full min-w-full overflow-hidden">
    <transition name="fade-sidebar">
      <FilterSidebar
        v-show="showFilterSidebar"
        ref="filter-sidebar"
        :content-types="contentTypes"
        :plans-count="plansCount"
        @close="showFilterSidebar = false"
        @fetch-plans="reFetchPlans"
        @social-account="handleSocialAccountSelection"
      />
    </transition>
    <div
      :class="{ 'bg-white': isListView }"
      class="w-full max-w-full h-full max-h-full overflow-hidden"
      @wheel="onScroll($event)"
    >
      <!--      if transition time is changed, then scroll watch property in calenderView & FeedView shall also be updated -->
      <div id="mainHeader" style="transition: all 0.6s">
        <div class="sticky top-0 z-[40] bg-white p-0.5">
          <PlannerHeader
            :bulk-change-status-option-visibility-status="
              bulkChangeStatusOptionVisibilityStatus
            "
            :check-all-items="check_all_items"
            :hide-filter-button="showFilterSidebar"
            :loader="loader"
            :search-loader="searchLoader"
            :search-query="searchQuery"
            :selected-items-count="selectedPlans.length"
            :show-bulk-actions="showBulkActions"
            :total-items="total_items"
            @refreshTable="refreshTable"
            @setCheckAllItems="setCheckAllItems"
            @setSearchQuery="setSearchQuery"
            @unSelectAllTableItems="unSelectAllTableItems"
            @bulk-action="handleBulkAction"
            @toggle-filters="showFilterSidebar = !showFilterSidebar"
            @sort-handler="sortHandler"
          />
        </div>
      </div>
      <div id="mainView" style="transition: all 0.6s" class="h-full">
        <router-view
          :check-all-items="check_all_items"
          :filter-sidebar="showFilterSidebar"
          :is-platform-selected="platformSelection.length > 0"
          :loader="loader"
          :plans="items"
          :search-loader="searchLoader"
          :search-query="searchQuery"
          :bulk-change-status-option-visibility-status="
            bulkChangeStatusOptionVisibilityStatus
          "
          :selected-items-count="selectedPlans.length"
          :show-bulk-actions="showBulkActions"
          :total-items="total_items"
          @setCheckAllItems="setCheckAllItems"
          @setSearchQuery="setSearchQuery"
          @unSelectAllTableItems="unSelectAllTableItems"
          @bulk-action="handleBulkAction"
          @refresh-table="refreshTable"
          @set-check-all-items="setCheckAllItems"
          @item-select="handleItemSelection"
          @handle-change="handleMoreFetch"
          @fetch-plans-after-delete="fetchPlansAfterDelete"
          @update-plans-count="updatePlansCount"
          @update-plan-status="updatePlanStatus"
          @preview-plan="previewPlan"
          @approve-with-comment="approveWithComment"
          @reject-with-comment="rejectWithComment"
          @replace-plan="replacePlan"
        ></router-view>
      </div>
    </div>
    <MovePublicationFolder />
    <CreatePublicationFolder />
    <TweetsResponseModal></TweetsResponseModal>
    <FirstCommentResponseModalVue></FirstCommentResponseModalVue>
    <SocialApprovalModal></SocialApprovalModal>
    <ReplacePlanConfirmation></ReplacePlanConfirmation>
    <ReopenPlanConfirmation></ReopenPlanConfirmation>
    <PublishingTimeFeedView type="missed_reviewed"></PublishingTimeFeedView>
    <!-- Approve Post Modal-->
    <b-modal
      id="approve-post-modal"
      :no-close-on-backdrop="true"
      centered
      dialog-class="max-w-4xl"
      hide-footer
      hide-header
      size="lg"
    >
      <ApprovalConfirmation
        :btn-loading="btnLoading"
        :plan-for-approval="planForApproval"
        @callProcessPlanApproval="callProcessPlanApproval"
        @onClickCancelApprove="onClickCancelApprove"
      />
    </b-modal>

    <!-- Reject Post Modal -->
    <b-modal
      id="reject-post-modal"
      :no-close-on-backdrop="true"
      centered
      dialog-class="max-w-3xl"
      hide-footer
      hide-header
      size="lg"
    >
      <RejectionConfirmation
        :plan-for-reject="planForReject"
        @callProcessPlanApproval="callProcessPlanApproval"
        @onClickCancelReject="onClickCancelReject"
      />
    </b-modal>
    <PlannerPostPreview
      :plan="currentPlanInPreview"
      :disable-left="planPreviewDisableLeft"
      :disable-right="planPreviewDisableRight"
      @next-plan="previewNextPlan"
      @previous-plan="previewPreviousPlan"
      @approve-with-comment="approveWithComment"
      @reject-with-comment="rejectWithComment"
      @edit-plan="editPlan"
      @delete-plan="deletePlan"
      @duplicate-plan="duplicatePlan"
    />
    <template v-if="showBulkActions">
      <BulkEditPlans
        :selected-plans="selectedPlansObjects"
        @refresh-table="refreshTable"
      ></BulkEditPlans>
    </template>
    <SharePlanModal />
    <ManageLinksModal />
  </div>
</template>

<script>
import FilterSidebar from '@src/modules/planner_v2/components/FilterSidebar'
import PlannerHeader from '@src/modules/planner_v2/components/PlannerHeader'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import MovePublicationFolder from '@src/modules/composer/components/MovePublicationFolder'
import CreatePublicationFolder from '@src/modules/composer/components/CreatePublicationFolder'
import { swalAttributes } from '@common/constants/common-attributes'
import { getStatusClass } from '@common/lib/planner'
import TweetsResponseModal from '@src/modules/planner/components/view/TweetsResponseModal'
import FirstCommentResponseModalVue from '@src/modules/planner/components/FirstCommentResponseModal'
import SocialApprovalModal from '@common/components/dialogs/approval/SocialApprovalModal'
import Router from '@src/router'
import proxy from '@common/lib/http-common'
import {
  processPlanBulkOperationURL,
  replacePlanURL,
} from '@src/modules/planner/config/api-utils'
import {
  BULK_OPERATION_ERROR,
  BULK_OPERATION_SUCCESS,
  UNKNOWN_ERROR,
} from '@common/constants/messages'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import ReplacePlanConfirmation from '@src/modules/planner/components/dialogs/ReplacePlanConfirmation'
import {
  plannerDefaultSort,
  plannerDefaultView,
} from '@src/modules/publish/config/api-utils'
import { mapGetters } from 'vuex'
import PublishingTimeFeedView from '@src/modules/planner/components/view/feed-view/PublishingTimeFeedView'
import ReopenPlanConfirmation from '@src/modules/planner/components/dialogs/ReopenPlanConfirmation'
import moment from 'moment'
import PlannerPostPreview from '@src/modules/planner_v2/components/PlannerPostPreview'
import ApprovalConfirmation from '@src/modules/planner_v2/components/ApprovalConfirmation'
import RejectionConfirmation from '@src/modules/planner_v2/components/RejectionConfirmation'
import { pusherSocketPublish } from '@common/lib/pusher'
import BulkEditPlans from '@src/modules/planner_v2/components/BulkEditPlans'
import SharePlanModal from '@src/modules/planner_v2/components/SharePlanModal'
import ManageLinksModal from '@src/modules/planner_v2/components/ManageLinksModal'
import {EventBus} from "@common/lib/event-bus";

const importedMethods = {
  getStatusClass,
}

export default {
  name: 'MainPlanner',
  components: {
    BulkEditPlans,
    PlannerPostPreview,
    ReopenPlanConfirmation,
    FilterSidebar,
    PlannerHeader,
    MovePublicationFolder,
    CreatePublicationFolder,
    TweetsResponseModal,
    FirstCommentResponseModalVue,
    SocialApprovalModal,
    ReplacePlanConfirmation,
    PublishingTimeFeedView,
    ApprovalConfirmation,
    RejectionConfirmation,
    SharePlanModal,
    ManageLinksModal,
  },
  mixins: [Helpers],
  data() {
    return {
      scroller: false,
      lastScrollPosition: 0,
      scrollOffset: 40,
      showFilterSidebar: true,
      plansCount: {
        scheduled: 0,
        published: 0,
        partially_failed: 0,
        review: 0,
        rejected: 0,
        missed: 0,
        failed: 0,
        draft: 0,
        repeat: 0,
        article_automations: 0,
        video_automations: 0,
        evergreen_automations: 0,
        rss_automations: 0,
        csv_automations: 0,
        queued: 0,
        composer_folders: [],
      },
      items: [],
      page: 1,
      limit: 20,
      check_all_statuses: true,
      date_range: '',
      total_items: 0,
      check_all: false,
      check_all_items: false,
      selectedPlans: [],
      calendar_date_range: '',
      check_all_assigned_members: true,
      check_all_created_members: true,
      check_all_labels: true,
      check_all_campaigns: true,
      check_all_categories: true,
      active_planner_filter: {
        type: '',
        statuses: [],
        members: [],
        created_by: [],
        labels: [],
        campaigns: [],
        content_category: [],
        social_accounts: [],
      },
      loader: true,
      platformSelection: [],
      noAccountFilter: false,
      searchQuery: '',
      contentTypes: [
        { name: 'Repeat Posts ', value: 'repeat', countKey: 'repeat' },
        {
          name: 'Article Automations Posts',
          value: 'article_automation',
          countKey: 'article_automations',
        },
        {
          name: 'Video Automations Posts',
          value: 'video_automation',
          countKey: 'video_automations',
        },
        {
          name: 'Evergreen Automations Posts',
          value: 'evergreen_automation',
          countKey: 'evergreen_automations',
        },
        {
          name: 'Rss Automations Posts',
          value: 'rss_automation',
          countKey: 'rss_automations',
        },
        {
          name: 'CSV Automations Posts',
          value: 'csv_automation',
          countKey: 'csv_automations',
        },
        { name: 'Queued Posts', value: 'queued', countKey: 'queued' },
      ],
      searchLoader: false,
      plannerDefaultView: '',
      plannerViews: [
        { view: 'list', routeName: 'planner_list_v2' },
        { view: 'compact_list', routeName: 'planner_list_compact_v2' },
        { view: 'calendar', routeName: 'planner_calendar_v2' },
        { view: 'feed', routeName: 'planner_feed_v2' },
      ],
      currentPlanIndex: -1,
      currentPlanInPreview: {},
      planForApproval: null,
      planForReject: null,
      btnLoading: false,
      scrollCount: 0,
      channel: null,
      sortData: {},
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getActiveWorkspace']),
    isListView() {
      return (
        this.$route.name === 'planner_list_v2' ||
        this.$route.name === 'planner_list_compact_v2'
      )
    },
    bulkChangeStatusOptionVisibilityStatus() {
      const plans = this.items

      if (
        this.items.length === this.selectedPlans.length &&
        this.items.length !== this.total_items &&
        !this.$route.query.filter_id
      ) {
        const statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : [
              'scheduled',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft',
            ]
        // if more than one plan statuses are showing than option should not be available
        if (statuses.length > 1 || statuses.length === 0) return false
        if (statuses[0] !== 'under_review') return false
      }
      let status = true

      // if all selected plans are not of type under review than  option should not be available
      this.selectedPlans.forEach((selectedPlan) => {
        plans.forEach((plan) => {
          if (plan._id === selectedPlan) {
            if (this.getStatusClass(plan) !== 'reviewed') {
              if (
                !(
                  plan.status === 'draft' &&
                  plan.approval &&
                  plan.approval.status === 'pending_approval' &&
                  this.checkApprovalStatus(plan.approval)
                )
              ) {
                status = false
                return false
              }
            } else if (
              plan.approval &&
              plan.approval.status === 'pending_approval' &&
              !this.checkApprovalStatus(plan.approval)
            ) {
              status = false
              return false
            } else if (
              (plan.article_automation_id ||
                plan.video_automation_id ||
                plan.evergreen_automation_id ||
                plan.rss_automation_id ||
                plan.csv_id) &&
              !this.hasPermission('can_schedule_automation_plan')
            ) {
              status = false
              return false
            }
          }
        })
        if (!status) return false
      })
      return status
    },

    /**
     * Returns true/false if the previous button should be disabled.
     * @returns {boolean}
     */
    planPreviewDisableLeft() {
      return this.currentPlanIndex === 0
    },

    /**
     * Returns true/false if the next button should be disabled.
     * @returns {boolean}
     */
    planPreviewDisableRight() {
      return this.currentPlanIndex === this.items.length - 1
    },

    /**
     * More than one account selected will enable bulk actions
     * @returns {boolean}
     */
    showBulkActions() {
      return this.selectedPlans.length > 1
    },

    selectedPlansObjects() {
      const selectedPlanObjs = []
      this.selectedPlans.forEach((id) => {
        const tempItem = this.items.find((item) => item._id === id)
        if (tempItem) {
          selectedPlanObjs.push(tempItem)
        }
      })
      return selectedPlanObjs
    },
  },
  watch: {
    '$route.query': {
      handler() {
        console.log('WATCH::route query', this.$route.query)
        this.page = 1
        this.reFetchPlans()
      },
      // immediate: true,
    },
    '$route.name'(val) {
      console.log('WATCH::route.name ~ val -> ', val)
      const viewName = this.plannerViews.find((item) => item.routeName === val)
      if (viewName) {
        this.setPlannerDefaultView(viewName.view)
      }
    },
    sortData(val) {
      this.setPlannerDefaultSort(val)
    },
    scroll(val) {
      if (!val) {
        const nodeEl = document.getElementsByClassName(
          'fc-scrollgrid-section-header'
        )
        if (nodeEl.length) {
          nodeEl[0].style.top = '3.1rem'
        }
      }
    },
  },
  /**
   * 1. Checks and redirects the user preferred view.
   * 2. The <FilterDropdown /> component inside <FilterSidebar /> checks for the default filter and applies it.
   *    If there are no default filters it emits an event to fetch plans.
   * 3. Event listener ('refreshPlannerTableV2') for refreshing the planner table.
   */
  mounted() {
    // subscribing to pusher comments channel
    this.channel = pusherSocketPublish.subscribe(
      `comments_${this.getActiveWorkspace._id}`
    )
    this.bindPusherComments()
    this.handlePlannerDefaultView()
    EventBus.$on('refreshPlannerTableV2', async () => {
      this.refreshTable()
    })
    EventBus.$on('update-plan-status', ({id, res}) => {
      this.updatePlanStatus(id, res)
    })
    EventBus.$on('update-plans-count', () => {
      this.updatePlansCount()
    })
    EventBus.$emit('set-sort-data')
  },
  beforeDestroy() {
    if (this.channel) {
      this.channel.unbind('store_comment')
      this.channel.unbind('delete_comment')
      this.channel.unsubscribe(`comments_${this.getActiveWorkspace._id}`)
    }
    EventBus.$off('update-plan-status')
    EventBus.$off('update-plans-count')
  },
  methods: {
    ...importedMethods,
    hideHeader() {
      document.getElementById('mainHeader').style.marginTop = '-65px'
    },
    showHeader() {
      document.getElementById('mainHeader').style.marginTop = '0'
    },
    onScroll(event) {
      // to calculate mouse wheel up/ down
      this.scrollCount++
      let scale = 1
      scale += event.deltaY * -0.01
      scale = Math.min(Math.max(0.125, scale), 4)
      if (this.scrollCount >= 4) {
        if (scale < 1) {
          this.scroller = true
          const value = this.scroller
          EventBus.$emit('hide-data-table-header', value)
          this.hideHeader()
          this.scrollCount = 0
        } else {
          this.scroller = false
          const value = this.scroller
          EventBus.$emit('hide-data-table-header', value)
          this.showHeader()
          this.scrollCount = 0
        }
      }
    },
    handleBulkAction(type, callback) {
      console.log('METHOD::handleBulkAction ~ type -> ', type, callback)
      switch (type) {
        case 'delete':
          this.removeBulkPlans(this.selectedPlans, callback)
          break
        case 'move':
          this.openMoveCampaignModel(null, this.selectedPlans)
          break
        case 'share':
          this.openSharePlanModel(this.selectedPlans)
          break
        case 'approve':
          this.processPlanBulkOperation('scheduled')
          break
        case 'reject':
          this.processPlanBulkOperation('rejected')
          break
        case 'edit':
          this.processPlanBulkEdit()
          break
      }
    },
    async processPlanBulkOperation(operation = 'scheduled') {
      console.log('METHOD::processPlanBulkOperation ~ operation -> ', operation)
      const res = await this.$bvModal.msgBoxConfirm(
        "You won't be able to undo.",
        {
          title: 'Process bulk operation',
          ...swalAttributes(),
        }
      )
      if (res) {
        this.processing_loader = true
        console.debug('Method:processPlanBulkOperation', operation)

        let data = {}
        // const plans = this.items
        if (this.check_all_items) {
          data = await this.$store.dispatch(
            'getPlansRequestFilters',
            this.$route
          )
          delete data.order
          delete data.page
          delete data.limit
          data.platformSelection = [...this.platformSelection]
          data.operation = operation
          delete data.specific_plans
        }

        if (!this.check_all_items) {
          data = {
            operation: operation,
            selected_plans: this.selectedPlans,
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          }
        }
        return proxy
          .post(processPlanBulkOperationURL, data)
          .then((response) => {
            if (response.data.status === true) {
              this.$store.dispatch('toastNotification', {
                message: BULK_OPERATION_SUCCESS,
                type: 'success',
              })
              this.check_all_items = false
              if (response) {
                this.reFetchPlans()
              }
              this.processing_loader = false
              this.refreshTable()
              return response
            }
            this.$store.dispatch('toastNotification', {
              message: BULK_OPERATION_ERROR,
              type: 'error',
            })
            return false
          })
          .catch((error) => {
            console.debug('Exception in processPlanBulkOperation', error)
            return false
          })
      }
    },
    async removeBulkPlans(plans, flushSelectedPlansCB) {
      console.debug('Method::removePlan', this.check_all_items)
      if (this.check_all_items) {
        await this.processPlanBulkOperation('deleted')
        return
      }
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your posts?',
        {
          title: 'Remove Posts',
          ...swalAttributes(),
        }
      )

      if (res) {
        const response = await this.$store.dispatch('removePlan', {
          id: plans,
          type: 'publication',
        })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your posts, please try again.',
            type: 'error',
          })
        } else {
          plans.forEach((plan) => {
            this.items = this.items.filter((item) => item._id !== plan)
          })
          // cleaning selectedItems array in CstDatatable after removing bulk plans.
          flushSelectedPlansCB()
          this.fetchPlansAfterDelete()
        }
      }
    },
    handleItemSelection(items) {
      this.selectedPlans = items
    },
    handleMoreFetch(cb) {
      this.page++
      this.reFetchPlans(cb)
    },
    handleSocialAccountSelection(accounts, noAccount = false) {
      console.log(
        'METHOD::handleSocialAccountSelection ~ accounts, noACcount -> ',
        accounts,
        noAccount
      )
      this.platformSelection = [...accounts]
      this.noAccountFilter = noAccount
      this.page = 1
      this.reFetchPlans()
    },

    async getFetchPlansPayload() {
      const payload = await this.$store.dispatch(
        'getPlansRequestFilters',
        this.$route
      )
      if (payload.type && payload.type !== '') {
        payload.type =
          payload.type.split(',')?.length === this.contentTypes.length
            ? []
            : payload.type?.split(',')
            ? payload.type?.split(',')
            : []
      } else {
        payload.type = []
      }
      // Social Account case
      payload.platformSelection = [...this.platformSelection]
      this.$route.name === 'planner_calendar_v2'
        ? delete payload.page
        : (payload.page = this.page)

      payload.no_social_account = this.noAccountFilter
      if (
        this.searchQuery !== '' &&
        this.$route.name !== 'planner_calendar_v2'
      ) {
        payload.search = this.searchQuery
      }

      if (payload.page && !payload.limit) {
        payload.limit = this.limit
      }
      return payload
    },

    async reFetchPlans(cb = null) {
      // refetch plans after filter change
      console.log('METHOD::reFetchPlans')

      const payload = await this.getFetchPlansPayload()

      this.fetchPlansCall(payload, (state) => {
        if (!cb) {
          this.loader = state
        }
      })
        .then((response) => {
          if (response.data.status) {
            if (response.data.plans_count) {
              this.plansCount = response.data.plans_count
            }

            let plans = []

            plans = response.data.plans.data
              ? response.data.plans.data
              : response.data.plans

            if (plans.length && Router.history.current.query.plan_id) {
              plans.is_active = true
              Router.push({ name: 'planner_list_v2' })
            }

            if (this.$route.name === 'planner_calendar_v2') {
              console.log('calendar name...')

              const workspaceTimezone =
                this.getWorkspaces.activeWorkspace.timezone
              plans.map((planItem) => {
                planItem.start = getWorkspaceTimeZoneTime(
                  planItem.execution_time.date,
                  workspaceTimezone,
                  'YYYY-MM-DD'
                )
                if (planItem.post_status === 'published') {
                  planItem.editable = false
                }
              })
            }

            this.total_items = response.data.plans.data
              ? response.data.plans.total
              : 1

            this.items = this.page > 1 ? this.items.concat(plans) : plans

            if (cb) {
              if (plans.length) {
                console.log('plans loaded', plans)
                cb(false) // eslint-disable-line standard/no-callback-literal
              } else {
                cb(true) // eslint-disable-line standard/no-callback-literal
              }
            }

            this.searchLoader = false
          }
          console.log('ListView response', response)
        })
        .catch((error) => {
          console.log('Fetch plans Error', error)
        })
    },

    async fetchPlansAfterDelete() {
      console.log('METHOD::fetchPlansAfterDelete')
      if (this.$route.name !== 'planner_calendar_v2') {
        console.log('METHOD::fetchPlansAfterDelete')

        const payload = await this.getFetchPlansPayload()

        this.fetchPlansCall(payload)
          .then((response) => {
            if (response.data.status) {
              if (response.data.plans_count) {
                this.plansCount = response.data.plans_count
              }

              let plans = []
              const newPlans = []

              plans = response.data.plans.data
                ? response.data.plans.data
                : response.data.plans

              if (plans.length) {
                const workspaceTimezone =
                  this.getWorkspaces.activeWorkspace.timezone

                plans.map((planItem) => {
                  const index = this.items.findIndex(
                    (item) => item._id === planItem._id
                  )
                  if (index === -1) {
                    planItem.start = getWorkspaceTimeZoneTime(
                      planItem.execution_time.date,
                      workspaceTimezone,
                      'YYYY-MM-DD'
                    )
                    if (planItem.post_status === 'published') {
                      planItem.editable = false
                    }
                    newPlans.push(planItem)
                  }
                })
              }

              if (newPlans.length) {
                this.items = this.items.concat(newPlans)
              }

              this.total_items = response.data.plans.data
                ? response.data.plans.total
                : 1
            }
          })
          .catch((error) => {
            console.log('Fetch plans Error', error)
          })
      }
    },

    /**
     * onClick event for refreshing table data.
     * this will not reset the filters, instead it will apply the currently selected filters.
     */
    refreshTable() {
      if (
        this.$el &&
        this.$refs['filter-sidebar'] &&
        this.$refs['filter-sidebar'].handleApply
      )
        this.$refs['filter-sidebar'].handleApply()
    },

    /**
     * sets all items in the table checked.
     * @param val
     */
    setCheckAllItems(val) {
      console.log('METHOD::setCheckAllItems ~ val -> ', val)
      this.check_all_items = val
    },

    /**
     * unselects all tale items on a single click.
     * @param val
     */
    unSelectAllTableItems(val) {
      console.log('METHOD::unSelectAllTableItems ~ val ->  ', val)
      this.check_all_items = val
    },

    /**
     * sets the search query value and runs a search. Re-fetches all plans if the search query is empty
     * @param val
     */
    setSearchQuery(val) {
      console.log('METHOD::setSearchQuery ~ val ->', val)
      this.searchQuery = val
      if (val !== '') {
        this.searchLoader = true
        this.reFetchPlans()
      } else {
        this.searchLoader = false
        this.page = 1
        this.reFetchPlans()
      }
    },

    /**
     * fetches the user prefered default view from store and navigates to that view.
     */
    handlePlannerDefaultView() {
      if (!location.pathname.includes('/composer')) {
        // if in composer view (for socialModal), then don't navigate to default view
        this.plannerDefaultView = this.getProfile.planner_default_view
        const defaultRouteName = this.plannerViews.find(
          (item) => item.view === this.plannerDefaultView
        )
        this.$router.push({
          name: defaultRouteName.routeName,
          query: this.$route.query,
        })
      }
    },

    /**
     * Sets the user default view based on the current user route
     * @param view
     */
    setPlannerDefaultView(view) {
      const self = this
      proxy
        .post(plannerDefaultView, { default_view: view })
        .then(() => {
          self.$store.dispatch('setDefaultView', view)
        })
        .catch((err) => {
          console.log('PROXY::plannerDefaultView ~ err -> ', err)
        })
    },

    /**
     * Sets user default sort based on current selected sort option
     * @param sort
     */
    setPlannerDefaultSort(sort) {
      const self = this
      proxy
        .post(plannerDefaultSort, { default_sort: sort })
        .then(() => {
          self.$store.dispatch('setDefaultSort', sort)
        })
        .catch((err) => {
          console.log('PROXY::plannerDefaultView ~ err -> ', err)
        })
    },

    async updatePlansCount() {
      const payload = await this.$store.dispatch(
        'getPlansRequestFilters',
        this.$route
      )
      if (payload.type && payload.type !== '') {
        payload.type =
          payload.type.split(',')?.length === this.contentTypes.length
            ? []
            : payload.type?.split(',')
            ? payload.type?.split(',')
            : []
      } else {
        payload.type = []
      }
      // Social Account case
      payload.platformSelection = [...this.platformSelection]
      this.$route.name === 'planner_calendar_v2'
        ? delete payload.page
        : (payload.page = this.page)

      payload.no_social_account = this.noAccountFilter
      if (
        this.searchQuery !== '' &&
        this.$route.name !== 'planner_calendar_v2'
      ) {
        payload.search = this.searchQuery
      }

      if (payload.page && !payload.limit) {
        payload.limit = this.limit
      }

      const counts = await this.fetchPlansCount(payload)
      if (counts) {
        this.plansCount = counts
      }
    },

    async updatePlanStatus(id, res) {
      let nbr = 0
      this.items.forEach((element, index) => {
        if (element._id === id) {
          nbr = index
          if (res.data?.data) {
            element.approval = res.data?.data
          }
          element.status = res.data.plan_status
          element.execution_time = res.data.execution_time
          if (res.data.plan_status === 'rejected') {
            element.post_state = 'rejected'
            element.render_class.tooltip = 'Rejected'
            element.render_class.icon = 'far fa-times-circle'
          }
          if (res.data.plan_status === 'draft') {
            element.post_state = 'draft'
            element.render_class.tooltip = 'Draft'
            element.render_class.icon = 'far fa-folder-plus'
          }
          if (res.data.plan_status === 'scheduled') {
            element.render_class.tooltip = 'Scheduled'
            element.render_class.icon = 'far fa-clock'
            if (element.approval?.status)
              element.approval.status = 'completed_approval'
            element.post_state = 'scheduled'
          }
          if (res.data.plan_status === 'review') {
            if (
              element.execution_time.date <
              moment.utc().format('YYYY-MM-DD HH:mm:ss')
            ) {
              element.post_state = 'missedReview'
              element.render_class.icon = 'far fa-eye-slash'
              element.render_class.tooltip = 'Missed review'
            } else {
              element.post_state = 'reviewed'
              element.render_class.icon = 'far fa-eye'
              element.render_class.tooltip = 'In review'
            }
          }
        }
      })
      const statuses = this.$route.query.statuses
        ? this.$route.query.statuses.split(',')
        : ''
      if (statuses && !statuses.includes(res.data.plan_status)) {
        this.items.splice(nbr, 1)
      }
    },

    /**
     * Event fired from PlannerPostPreview to show a modal and preview the selected plan
     * @param planId
     */
    previewPlan(planId) {
      console.log('METHOD::previewPlan ~ planId -> ', planId)
      const planIndex = this.items.findIndex((item) => item._id === planId)
      if (planIndex > -1) {
        this.currentPlanIndex = planIndex
        this.currentPlanInPreview = this.items[planIndex]
        this.$bvModal.show('planner-post-preview')
      }
    },

    /**
     * onClick event for previewing next plan
     */
    previewNextPlan(removed = false) {
      if (
        this.total_items - this.items.length > 0 &&
        this.items.length - this.currentPlanIndex <= 5 &&
        !this.loader
      ) {
        this.handleMoreFetch(null)
      }
      if (this.currentPlanIndex === this.items.length - 1 && removed) {
        this.$bvModal.hide('planner-post-preview')
        this.currentPlanIndex = -1
        this.currentPlanInPreview = null
        return
      }
      this.currentPlanInPreview = this.items[++this.currentPlanIndex]
    },

    /**
     * onClick event for previewing previous plan
     */
    previewPreviousPlan() {
      console.log('METHOD::previewPreviousPlan')
      if (this.currentPlanIndex === 0) return
      this.currentPlanInPreview = this.items[--this.currentPlanIndex]
    },

    /**
     * Event for displaying the approve modal
     * @param item
     */
    approveWithComment(item) {
      console.log('METHOD::approveWithComment ~ item -> ', item)
      this.planForApproval = item
      this.$bvModal.show('approve-post-modal')
    },

    /**
     * Event for displaying the reject modal
     * @param item
     */
    rejectWithComment(item) {
      console.log('METHOD::rejectWithComment ~ item -> ', item)
      this.planForReject = item
      this.$bvModal.show('reject-post-modal')
    },

    /**
     * Handling the approval/rejection of a plan with comment.
     * @param plan
     * @param status
     * @param comment
     * @param postComment
     * @returns {Promise<void>}
     */
    async callProcessPlanApproval(plan, status, comment = false, postComment) {
      const self = this
      self.btnLoading = true

      if (status === 'approve' && postComment === '') {
        comment = false
      } else if (status === 'reject' && postComment === '') {
        comment = false
      }
      const res = this.processPlanApproval(plan, status, comment)
      if (res) {
        plan.new_comment = postComment.approvePostComment
          ? postComment.approvePostComment
          : postComment.rejectPostComment
        plan.new_comment_media = postComment.media ? postComment.media : []
        this.$emit('update-plans-count')
        if (res && comment) await this.addCommentAction(plan, true)
      }
      if (status === 'approve') {
        this.$bvModal.hide('approve-post-modal')
        this.planForApproval = null
        this.approvePostComment = ''
      } else if (status === 'reject') {
        this.$bvModal.hide('reject-post-modal')
        this.planForReject = null
        this.rejectPostComment = ''
      }
      this.btnLoading = false
    },

    /**
     * Adds comment to the plan while approving/rejecting a plan with comment if the comment existed
     * @param item
     * @param notification
     * @returns {Promise<boolean>}
     */
    async addCommentAction(item, notification = false) {
      console.debug('addCommentAction', item)

      if (!item.new_comment || item.new_comment.trim().length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      }
      this.$set(item, 'loader_store', true)

      const payload = {
        workspace_id: item.workspace_id,
        plan_id: item._id,
        comment: item.new_comment,
        mentioned_user: this.mentionedUserIdsList(item.new_comment),
        type: this.$route.name === 'planner_list_v2' ? 'list_view' : '',
        title: item.title || null,
        is_note: false,
        media: item.new_comment_media ? item.new_comment_media : [],
        notification: notification,
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)
      this.$set(item, 'loader_store', false)
      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          if (!item.comments) item.comments = []
          item.new_comment = ''
          item.write_comment = false
          item.new_comment_media = []
          this.messageType = 'message'
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
        this.toggleStatus = true
      }
    },

    /**
     * onclick event for closing the approval modal dialog box
     */
    onClickCancelApprove() {
      this.planForApproval = null
      this.approvePostComment = ''
      this.$bvModal.hide('approve-post-modal')
    },

    /**
     * onclick event for closing the approval modal dialog box
     */
    onClickCancelReject() {
      this.planForReject = null
      this.rejectPostComment = ''
      this.$bvModal.hide('reject-post-modal')
    },

    /**
     * click capture event for editing a plan
     * @param item
     */
    editPlan(item) {
      console.debug('Method::editPlan', item)
      item.stateObject = this
      this.$store.dispatch('editPlan', item)
    },

    /**
     * click capture event for deleting a plan.
     * @param plan
     * @returns {Promise<void>}
     */
    async deletePlan(plan) {
      console.debug('Method::removePlan', plan)
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your post?',
        {
          title: 'Remove Post',
          ...swalAttributes(),
        }
      )
      if (res) {
        const response = await this.$store.dispatch('removePlan', { id: plan })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your post, please try again.',
            type: 'error',
          })
        } else {
          this.previewNextPlan(true)
          this.items.splice(
            this.items.findIndex((item) => item._id === plan),
            1
          )
          this.$emit('fetch-plans-after-delete')
        }
      }
    },

    /**
     * Calls an api to replace post and update the item with the newly replaced post.
     * @param plan
     * @returns {Promise<void>}
     */
    async replacePlan(plan) {
      console.log('METHOD::replacePlan ~ plan -> ', plan)
      const response = await this.$bvModal.msgBoxConfirm(
        "You won't be able to undo",
        { title: 'Replace Post?', ...swalAttributes() }
      )
      if (!response) return

      this.$store.dispatch('toastNotification', {
        message: 'Replacing post...',
        type: 'info',
      })

      const payload = {
        id: plan._id,
        workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
      }

      if (plan.article_automation_id) {
        payload.type = 'Article Automation'
        payload.automation_id = plan.article_automation_id
      }
      if (plan.video_automation_id) {
        payload.type = 'Video Automation'
        payload.automation_id = plan.video_automation_id
      }

      proxy
        .post(replacePlanURL, payload)
        .then((response) => {
          if (response.data.status) {
            this.items.forEach((element, index) => {
              if (element._id === plan._id) {
                if (response.data.item.post) {
                  this.items[index].publish_source =
                    response.data.item.publish_source
                  this.items[index].post = response.data.item.post
                  return false
                }
                for (var key in response.data.item) {
                  if (typeof response.data.item[key] !== 'function') {
                    const plan = this.items[index]
                    plan[key] = response.data.item[key]
                    this.items[index] = plan
                  }
                }
                return false
              }
            })
          } else {
            this.$store.dispatch('toastNotification', {
              message: response.data.message,
              type: 'error',
            })
          }
        })
        .catch((error) => {
          console.debug('Exception in replacePlan', error)
          this.$store.dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
        })
    },

    /**
     * OnClick capture event for duplicating a plan
     * @param item
     */
    duplicatePlan(item) {
      console.debug('Method::duplicatePlan', item)
      item.stateObject = this
      this.$store.dispatch('clonePlan', item)
    },

    /**
     * binding pusher events so that upon trigger, comments can be updated.
     * NOTE: Events are being triggered from backend
     * listens for store_comment and delete_comment event for comments and updates the local comments state of plans.
     */
    bindPusherComments() {
      console.log('METHOD::bindPusherComments')
      this.channel.bind('store_comment', (data) => {
        const plan = this.items.find((item) => item._id === data.plan_id)
        if (plan && plan.comments) {
          if (plan.comments?.length > 0) {
            const comment = plan.comments.find(
              (commentItem) => commentItem._id === data._id
            )
            if (!comment) {
              plan.comments.unshift(data)
            } else {
              const commentItemIndex = plan.comments.findIndex(
                (commentItem) => commentItem._id === data._id
              )
              if (commentItemIndex >= 0) {
                plan.comments.splice(commentItemIndex, 1, data)
              }
            }
          } else {
            plan.comments.unshift(data)
          }
        }
      })
      this.channel.bind('delete_comment', (data) => {
        const plan = this.items.find((item) => item._id === data.plan_id)
        if (plan) {
          const getCommentIndex = plan.comments?.findIndex(
            (commentItem) => commentItem._id === data._id
          )
          if (getCommentIndex >= 0) plan.comments.splice(getCommentIndex, 1)
        }
      })
    },

    /**
     * @description sorts the plans w.r.t the column name and order received from planner header. (schedule) sort if for (scheduled+in_review)
     * @param column to be sorted
     * @param order of sorting
     */
    sortHandler(column, order) {
      this.sortData = {
        column,
        order,
      }
      const query = Object.assign({}, this.$route.query)

      query.order_by = order
      query.sort_column = column
      this.$refs['filter-sidebar'].sort = query.order_by
      this.$refs['filter-sidebar'].handleApply(query)
    },

    processPlanBulkEdit() {
      console.log(
        'METHOD::processPlanBulkEdit ~ this.selectedPlans -> ',
        this.selectedPlans
      )
      // showBulkActions
      // bulk-edit-plans
      this.$bvModal.show('bulk-edit-plans')
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
}
.header {
  transform: translateY(0);
  transition: transform 300ms linear;
}

.header.is-hidden {
  transform: translateY(-100%);
}
#reject-post-modal {
  &___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
}
#approve-post-modal {
  &___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
}
</style>
