<template id="managE_competitor">
  <div
      class="analytic-competitors-addform"
      :class="overviewFlag ? 'pointer-none' : ''"
  >
    <div class="analytic-competitors-addform__title mb-2">Your Pages</div>
    <div v-if="connectedAccounts.length === 0" class="input_field mb-2"><!----><!---->
      <div><span>You have not connected any account yet.</span><a href="" @click.prevent="GoToSocial()" class="ml-2"
                                                                  style="text-decoration: underline;">Connect an
        account</a></div>
    </div>

    <div class="analytic-competitors-addform__search-wrapper">
      <b-dropdown
          ref="dropdown"
          variant="studio-theme"
          toggle-class="w-100"
          size="lg"
          class="
          studio-theme-dropdown
          studio-theme-dropdown--checkbox
          studio-theme-dropdown--contain-image
          w-100
        "
          :disabled="connectedAccounts.length === 0"
          no-caret
      >
        <template slot="button-content">
          <template v-if="connectedAccounts.length === 0">
            <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
            <span
                class="
                capitalize_text
                d-inline-flex
                justify_content_start
                text-left
                ml-3
              "
            >
              <small>No Account Connected</small>
            </span>
          </template>
          <template v-else>
            <div v-if="!selected_account" class="d-flex align-items-center">
              <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
              <span
                  class="
                  capitalize_text
                  d-inline-flex
                  justify_content_start
                  text-left
                  ml-3
                "
              >
                <small>Please Select An Account</small>
              </span>
            </div>
            <div
                v-else
                class="d-flex align-items-center justify-content-between"
                @click="disableSuggestion()"
            >
              <div class="d-flex align-items-center">
                <span class="connected-accounts-images">
                  <div>
                    <img
                        class="selected"
                        @error="
                        $event.target.src = fallbackImagePreview(selected_account.name[0])
                      "
                        :src="selected_account.image"
                        alt=""
                    />
                  </div>
                </span>
                <span
                    class="
                    capitalize_text
                    d-flex
                    flex-column
                    justify_content_start
                    text-left
                    ml-3
                  "
                >
                  {{ selected_account.name }}
                </span>
              </div>

              <i class="icon-dropdown-cs"></i>
            </div>
          </template>
        </template>

        <div v-for="(account, i) in connectedAccounts" :key="i">
          <b-dropdown-item @click="changeSelectedAccount(account)">
            <div class="field_group">
              <div class="checkbox_container">
                <label>
                  <div class="dropdown-img-wrapper">
                    <img
                        class="selected"
                        @error="
                        $event.target.src = fallbackImagePreview(account.name[0])
                      "
                        :src="channelImage(account, 'facebook')"
                        alt=""
                    />
                    <i :class="getIconClass('instagram')"></i>
                  </div>
                  <span class="dropdown-item-label">
                    <span>{{ account.name }}</span>
                    <small>Page</small>
                  </span>
                </label>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <div class="analytic-competitors-addform__title mb-0 mt-4">Add Competitor(s)</div>
      <b-input-group class="mt-2">
        <template v-slot:prepend>
          <i class="far fa-search"></i>
        </template>

        <b-form-input
            class="mr-2"
            v-model="value"
            :disabled="selected_account === null"
            type="text"
            debounce="500"
            @click="searchKeyword(value)"
            placeholder="Enter competitor’s slug/url here..."
        ></b-form-input>
        <template v-if="enableSuggestion" v-slot:append>
          <clip-loader
              class="append mr-24"
              :color="'#5773fa'"
              :size="'22px'"
          ></clip-loader>
        </template>
        <button class="btn btn-studio-theme-space ml-auto m-1 justify-center" @click="searchButtonResults()">
          <span>search</span>
        </button>
      </b-input-group>


      <div
          class="analytic-competitors-addform__dropdown-wrapper"
          v-on-clickaway="clearResults"
      >
        <transition name="slide-bottom">
          <div
              class="analytic-competitors-addform__dropdown"
              v-if="results.length"
          >
            <div
                v-for="(result, i) in results"
                :key="i"
                :class="{ disable_suggestion: enableSuggestion }"
                @click="selectCompetitor(result)"
            >
              <div class="analytic-competitors-addform__dropdown-img">
                <img
                    :src="
                    result['profile_picture_url']
                  "
                    @error="$event.target.src = fallbackImagePreview(result.name[0])"
                />
              </div>
              <div class="analytic-competitors-addform__dropdown-name">
                {{ result.name == null ? result.username : result.name }}</br>
                <small v-if="result['biography']">{{ result['biography'] }}</small>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="analytic-competitors-addform__selected-wrapper">
      <div class="analytic-competitors-addform__selected" v-if="optionsFlag">
        <div
            v-for="(result, i) in getSelectedCompetitors()"
            :key="i"
        >
          <div class="d-flex align-items-center">
            <div class="analytic-competitors-addform__selected-img">
              <img :src="result.image"
                   @error="$event.target.src = fallbackImagePreview(result.display_name[0]) "/>
            </div>
            <div class="analytic-competitors-addform__selected-name">
              {{ result.display_name }}
            </div>
          </div>
          <div
              class="analytic-competitors-addform__selected-remove"
              @click="removeSelected(i, result)"
          >
            <i class="far fa-close"></i>
          </div>
        </div>
        <div v-if="getSelectedCompetitors().length <= 0">
          No Competitors Added, Search and Add new Competitors to view Report
        </div>
      </div>
    </div>
    <div
        v-if="selected_account && !optionsFlag"
        class="opacity-70"
        style="display: flex; justify-content: center"
    >
      <clip-loader class="ml-2" :color="'#5773fa'" :size="'50px'"></clip-loader>
    </div>
    <div class="analytic-competitors-addform__button d-flex align-items-center">
      <div class="compose_warning_box manage_c_warning pr-4"><i class="far fa-exclamation-circle test"></i>
        <p>Only 5 competitors per page are allowed.</p></div>

      <button
          class="btn btn-studio-theme-space ml-auto"
          @click="resolveRoute"
          :disabled="
          selected_account === null ||
          getSelectedCompetitors() <= 0 ||
          overviewFlag
        "
      >
        <span v-if="native !== 'true'">Save Changes</span>
        <span v-else>Fetch Analytics</span>
      </button>
      <div
          v-if="overviewFlag"
          class="opacity-70"
          style="display: inline-flex; vertical-align: middle"
      >
        <clip-loader
            class="ml-2"
            :color="'#5773fa'"
            :size="'22px'"
        ></clip-loader>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.disable_suggestion {
  pointer-events: none;
  opacity: 0.7;
}

.manage_c_warning {
  p {
    font-size: 15px;
    font-weight: 400;
    color: #b57e00;
  }

  i {
    margin: 0px 0px 0px 10px;
    padding: 2px 8px 1px 5px;
  }
}
</style>
<script>
import {mapGetters} from 'vuex'
import analyticsUtilsMixin from '../../../../components/common/analyticsUtilsMixin'
import api_ops from "../../competitors/instagram/api_ops";
import axios from 'axios'
import {EventBus} from "@common/lib/event-bus";


export default {
  name: 'AddCompetitorFields',
  mixins: [analyticsUtilsMixin, api_ops],
  props: {
    native: String,
    activeCompetitor: String
  },
  data() {
    return {
      optionsFlag: false,
      value: '',
      results: [],
      existingCompetitors: {},
      selectedCompetitors: {},
      selected_account: null,
      enableSuggestion: false,
      overviewFlag: false,
      CancelToken: axios.CancelToken,
      proxy: axios,
      requestCancelPromise: {}
    }
  },
  watch: {
    value(newValue) {
      this.searchKeyword(newValue.trim())
    }
  },
  created() {
    this.intializePage()
  },
  mounted() {
    EventBus.$on('workspace-changed', () => {
      this.cancelPendingRequest('all')
      this.intializePage()
    })
  },
  computed: {
    ...mapGetters(['getProfile']),
    connectedAccounts() {
      return this.getPlatformAccounts('instagram').sort((a, b) => (a.name > b.name) ? 1 : -1)
    }
  },
  methods: {
    intializePage() {
      this.results = []
      this.value = ''
      this.selectedCompetitors = {}
      if (this.connectedAccounts.length > 0) {
        this.selected_account = this.connectedAccounts[0]
        if (this.activeCompetitor != '') {
          this.selected_account = this.connectedAccounts.filter((obj) => {
            return obj.instagram_id == this.activeCompetitor
          })[0]
        }
        this.cancelPendingRequest('fetchCompetitors')
        let promise = this.getIgCompetitors(this.selected_account.instagram_id)
        this.requestCancelPromise['fetchCompetitors'].push(promise[0])
        promise[1].then(data => {
          this.selectedCompetitors[this.selected_account.instagram_id] = data.data.competitors
          this.existingCompetitors[this.selected_account.instagram_id] = [...data.data.competitors]
          this.optionsFlag = true
        })
      }
    },
    resolveRoute() {
      // The function is responsible to commit user changes regarding CURD operations for competitors.
      const promises = []
      let existingAccounts = this.existingCompetitors[this.selected_account.instagram_id]
      let selectedAccountIds = this.selectedCompetitors[this.selected_account.instagram_id].map((x) => {
        return x.competitor_id
      })
      console.log('existing competitors', existingAccounts, selectedAccountIds)
      let deletedCompetitors = existingAccounts.filter((obj) => {
        return !selectedAccountIds.includes(obj.competitor_id)
      })
      this.cancelPendingRequest('delCompetitor')
      deletedCompetitors.forEach((obj) => {
        let promise = this.delIgCompetitors(obj)
        this.requestCancelPromise['delCompetitor'].push(promise[0])
        promises.push(promise[1])
      })
      console.log(this.selectedCompetitors[this.selected_account.instagram_id])
      Promise.all(promises).then(() => {
        this.cancelPendingRequest('addCompetitor')
        let promise = this.addIgCompetitors(this.selected_account.instagram_id, this.selected_account.name, this.selectedCompetitors[this.selected_account.instagram_id])
        this.requestCancelPromise['addCompetitor'].push(promise[0])
        promises.push(promise[1])
      })
      this.overviewFlag = true

      //   // closing current modal and emitting signal to reload existing overview page.
      Promise.all(promises).then(() => {
        if (this.overviewFlag) {
          this.alertMessage('Competitors updated successfully. Data for competitors will be updated/available within half an hour.', 'success')
          this.$router.push({
            name: 'instagramCompetitorOverview',
            params: {accountId: this.selected_account.instagram_id}
          })
          if (this.native !== 'true') {
            setTimeout(() => this.$emit('updatedCompetitor'), 200);
          }
        }
      })
      // })
    },
    cancelPendingRequest(key) {
      // The function is responsible to cancel pending api calls.
      // Eg: If a user filter a view and while its data is loading if the user applied another filter current function
      // will be called and the function will kill any pending api calls, to avoid data redundancy and server load.
      if (key === 'all') {
        Object.keys(this.requestCancelPromise).forEach((k) => {
          this.requestCancelPromise[k].forEach((cancel) => {
            cancel()
          })
        })
      } else {
        if (!this.requestCancelPromise[key]) {
          this.requestCancelPromise[key] = []
        }
        this.requestCancelPromise[key].forEach((cancel) => {
          cancel()
        })
      }
    },
    changeSelectedAccount(account) {
      this.optionsFlag = false
      this.selected_account = account
      this.cancelPendingRequest('fetchCompetitors')
      let promise = this.getIgCompetitors(this.selected_account.instagram_id)
      this.requestCancelPromise['fetchCompetitors'].push(promise[0])
      promise[1].then(data => {
        this.selectedCompetitors[this.selected_account.instagram_id] = data.data.competitors
        this.existingCompetitors[this.selected_account.instagram_id] = [...data.data.competitors]
        this.optionsFlag = true
      })
    },
    async searchKeyword(keyword) {
      this.enableSuggestion = true
      let promises = []
      if (keyword.length > 1) {
        // call to cancel any pending request of the same category.
        this.cancelPendingRequest('search_keyword')
        let promise = this.searchIgAccount(keyword)
        this.requestCancelPromise['search_keyword'].push(promise[0])
        promises.push(promise[1])
        await promise[1].then((res) => {
          if (res.data.data) {
            this.results = [res.data.data]
          } else {
            this.results = []
          }
          this.enableSuggestion = false
        })
      } else {
        this.results = []
        this.enableSuggestion = false
      }
    },
    async searchButtonResults() {
      await this.searchKeyword(this.value)
      if (this.results.length <= 0) {
        this.alertMessage('Account not found! Kindly enter a valid business account URL or SLUG', 'error')
      }
    },
    clearResults() {
      this.results = []
    },
    selectCompetitor(competitor) {
      // The function is responsible to add competitor in view list searched by user.
      // ...
      // following condition is checking if the user has searched or added the selected pages as competitor.
      if (competitor.id === this.selected_account.instagram_id) {
        // in case user selects source page as competitors, showing error message.
        this.alertMessage('Adding Source Page as Competitor is Not Allowed!', 'error')
      } else if (this.selectedCompetitors[this.selected_account.instagram_id].length > 5) {
        this.alertMessage('Only 5 Competitors Per Page Are Allowed!', 'error')
      } else {
        // creating object to be added as fb competitor
        let comp_obj = {
          competitor_id: competitor['id'],
          name: competitor['username'],
          display_name: competitor['name'],
          image: competitor['profile_picture_url']
        }
        // checking if the object already exists in the list, if yes then don't add id again. else adding it in the list.
        if (this.selectedCompetitors[this.selected_account.instagram_id]) {
          if ((this.selectedCompetitors[this.selected_account.instagram_id].map((x) => {
            return x['competitor_id']
          }).includes(comp_obj['competitor_id']))) {
          } else {
            this.selectedCompetitors[this.selected_account.instagram_id].push(comp_obj)
          }
        } else {
          this.selectedCompetitors[this.selected_account.instagram_id] = [comp_obj]
        }
        console.log(this.selectedCompetitors)
      }

      setTimeout(() => {
        this.value = ''
        this.clearResults()
      }, 200)
    },
    removeSelected(i, selected) {
      // the function is responsible to remove any competitors from list selected by user.
      // API Call to delete selected competitor
      this.selectedCompetitors[this.selected_account.instagram_id].splice(i + 1, 1)
    },
    getSelectedCompetitors() {
      // the function is responsible to return a list of competitor objects(fb) for the selected account
      if (
          this.selected_account &&
          this.selectedCompetitors[this.selected_account.instagram_id] &&
          this.selectedCompetitors[this.selected_account.instagram_id].length > 0
      ) {
        return this.selectedCompetitors[this.selected_account.instagram_id].filter((obj) => {
          return obj.competitor_id != this.selected_account.instagram_id
        })
      }
      return []
    },
    GoToSocial() {
      // this function is responsible to redirect application to page where user add/update/del social accounts
      this.$router.push({name: 'social'})
    },
    disableSuggestion() {
      this.results = []
    }
  },

  beforeDestroy() {
    this.results = []
    this.value = ''
    this.selectedCompetitors = {}
  }
}
</script>
