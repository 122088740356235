import {
  fetchOnboardingFacebookAccountsUrl,
  fetchOnboardingTwitterAccountsUrl,
  fetchOnboardingPinterestAccountsUrl,
  fetchOnboardingLinkedinAccountsUrl,
  fetchOnboardingTumblrAccountsUrl,
  fetchOnboardingWordpressAccountsUrl,
  fetchOnboardingMediumAccountsUrl,
  onboardingProcessUrl,
} from '@src/modules/integration/config/api-utils'
import { fetchOnboardingTeamUrl } from '@src/modules/setting/config/api-utils'
import { mapActions, mapGetters } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import {
  folderTypes,
  topicCustomizedSidebarTypes,
  topicTypes
} from '../../../discovery/store/mutation-types'
export const onboardingMixin = {
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getOnboardingFacebook',
      'getOnboardingTwitter',
      'getOnboardingTumblr',
      'getOnboardingLinkedin',
      'getOnboardingPinterest',
      'getOnboardingMedium',
      'getOnboardingTumblrBlog',
      'getOnboardingWordpress',
      'getOnboardingTeam',
      'getOnboardingWorkspaces',
    ]),
  },
  methods: {
    ...mapActions([
      'setFacebookOnboardingAccounts',
      'setTwitterOnboardingAccounts',
      'setPinterestOnboardingAccounts',
      'setLinkedinOnboardingAccounts',
      'setTumblrOnboardingAccounts',
      'setTumblrOnboardingLoader',
      'setPinterestOnboardingLoader',
      'setLinkedinOnboardingLoader',
      'setTwitterOnboardingLoader',
      'setFacebookOnboardingLoader',
      'setTumblrBlogOnboardingAccounts',
      'setTumblrBlogOnboardingLoader',
      'setWordpressOnboardingLoader',
      'setWordpressOnboardingAccounts',
      'setMediumOnboardingAccounts',
      'setMediumOnboardingLoader',
      'setOnboardingTeam',
      'setOnboardingTeamLoader',
      'setOnboardingLoader',
      'fetchProfile'
    ]),
    initializeOnboardingSocialAccounts() {
      this.getOnboardingFacebookAccounts()
      this.getOnboardingTwitterAccounts()
      this.getOnboardingPinterestAccounts()
      this.getOnboardingLinkedinAccounts()
      this.getOnboardingTumblrAccounts()
      this.getOnboardingTumblrBlogAccounts()
      this.getOnboardingMediumAccounts()
      this.getOnboardingWordpressAccounts()
    },

    initializeOnboardingTeam() {
      this.getOnboardingTeamMembers()
    },

    getOnboardingFacebookAccounts() {
      this.setFacebookOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingFacebookAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setFacebookOnboardingAccounts(response.data.accounts)
          }
          this.setFacebookOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in getOnboardingFacebookAccounts', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFacebookOnboardingLoader(false)
        }
      )
    },

    getOnboardingTwitterAccounts() {
      this.setTwitterOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingTwitterAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setTwitterOnboardingAccounts(response.data.accounts)
          }
          this.setTwitterOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchOnboardingTwitterAccountsUrl', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setTwitterOnboardingLoader(false)
        }
      )
    },

    getOnboardingPinterestAccounts() {
      this.setPinterestOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingPinterestAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setPinterestOnboardingAccounts(response.data.accounts)
          }
          this.setPinterestOnboardingLoader(false)
        },
        (error) => {
          console.debug(
            'Exception in fetchOnboardingPinterestAccountsUrl',
            error
          )
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setPinterestOnboardingLoader(false)
        }
      )
    },

    getOnboardingLinkedinAccounts() {
      this.setLinkedinOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingLinkedinAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setLinkedinOnboardingAccounts(response.data.accounts)
          }
          this.setLinkedinOnboardingLoader(false)
        },
        (error) => {
          console.debug(
            'Exception in fetchOnboardingPinterestAccountsUrl',
            error
          )
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setLinkedinOnboardingLoader(false)
        }
      )
    },

    getOnboardingTumblrAccounts() {
      this.setTumblrOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingTumblrAccountsUrl,
        { type: 'Profile' },
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setTumblrOnboardingAccounts(response.data.accounts)
          }
          this.setTumblrOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchOnboardingTumblrAccountsUrl', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setTumblrOnboardingLoader(false)
        }
      )
    },

    getOnboardingMediumAccounts() {
      this.setMediumOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingMediumAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setMediumOnboardingAccounts(response.data.accounts)
          }
          this.setMediumOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in getOnboardingMediumAccounts', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setMediumOnboardingLoader(false)
        }
      )
    },

    getOnboardingTumblrBlogAccounts() {
      this.setTumblrBlogOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingTumblrAccountsUrl,
        { type: 'Blog' },
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setTumblrBlogOnboardingAccounts(response.data.accounts)
          }
          this.setTumblrBlogOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in getOnboardingTumblrBlogAccounts', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setTumblrBlogOnboardingLoader(false)
        }
      )
    },

    getOnboardingWordpressAccounts() {
      this.setWordpressOnboardingLoader(true)
      this.postRequest(
        fetchOnboardingWordpressAccountsUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
            })
            this.setWordpressOnboardingAccounts(response.data.accounts)
          }
          this.setWordpressOnboardingLoader(false)
        },
        (error) => {
          console.debug('Exception in getOnboardingWordpressAccounts', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setWordpressOnboardingLoader(false)
        }
      )
    },


    getOnboardingTeamMembers() {
      this.setOnboardingTeamLoader(true)
      const selfObject = this
      this.postRequest(
        fetchOnboardingTeamUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            $(response.data.accounts).each(function (index, el) {
              el.workspaces = []
              if (selfObject.getMemberRole(el) === 'Super Admin') {
                $(selfObject.getOnboardingWorkspaces).each(function (
                  index,
                  el2
                ) {
                  el.workspaces.push(el2.workspace_id)
                })
              }
            })
            this.setOnboardingTeam(response.data.accounts)
          }
          this.setOnboardingTeamLoader(false)
        },
        (error) => {
          console.debug('Exception in getOnboardingTeam', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setOnboardingTeamLoader(false)
        }
      )
    },

    getMemberRole(member) {
      if (member.role === '1') {
        return 'Admin'
      } else if (member.role === '0') {
        return 'Collaborator'
      } else if (member.role === '2') {
        return 'Client'
      } else {
        return 'Super Admin'
      }
    },

    onboardingProcess() {
      this.setOnboardingLoader(true)
      const payload = {
        facebook: this.getOnboardingFacebook.accounts,
        twitter: this.getOnboardingTwitter.accounts,
        tumblr: this.getOnboardingTumblr.accounts,
        linkedin: this.getOnboardingLinkedin.accounts,
        pinterest: this.getOnboardingPinterest.accounts,
        medium: this.getOnboardingMedium.accounts,
        tumblrBlog: this.getOnboardingTumblrBlog.accounts,
        wordpress: this.getOnboardingWordpress.accounts,
        team: this.getOnboardingTeam.accounts,
      }
      this.postRequest(
        onboardingProcessUrl,
        payload,
        (response) => {
          this.setOnboardingLoader(false)
          if (response.data.status === true) {
            this.fetchWorkspaces(true)
            this.fetchProfile()
            this.alertMessage(
              'Your social and blog posts will be migrated, it can take few minutes depending on the no. of social and blog posts.',
              'success'
            )
            this.$router.push({ name: 'workspaces' })
          } else {
            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage(UNKNOWN_ERROR, 'error')
            }
          }
        },
        (error) => {
          console.debug('Exception in onboardingProcess', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setOnboardingLoader(false)
        }
      )
    },

    redirectToTeamConfirmation() {
      let hasEmpty = false
      $(this.getOnboardingMedium.accounts).each(function (index, el) {
        if (el.workspaces.length === 0) {
          hasEmpty = true
          return false
        }
      })

      if (!hasEmpty) {
        $(this.getOnboardingTumblrBlog.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingWordpress.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingTumblr.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingPinterest.accounts).each(function (index, el) {
          if (el.type === 'Profile' && el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingTwitter.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingFacebook.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }

      if (!hasEmpty) {
        $(this.getOnboardingLinkedin.accounts).each(function (index, el) {
          if (el.workspaces.length === 0) {
            hasEmpty = true
            return false
          }
        })
      }


      if (hasEmpty) {
        this.confirmAction('removeOnboardingAccounts', [])
      } else {
        this.$router.push({ name: 'teammembers' })
      }
    },

    removeOnboardingAccounts(confirmation) {
      console.debug('Method:removeOnboardingAccounts', confirmation)
      $('#' + confirmation.type).modal('hide')
      if (confirmation.status) {
        this.$router.push({ name: 'teammembers' })
      }
    },

    onboardingTeamConfirmation() {
      let hasEmpty = false
      $(this.getOnboardingTeam.accounts).each(function (index, el) {
        if (el.workspaces.length === 0) {
          hasEmpty = true
          return false
        }
      })

      if (hasEmpty) {
        this.confirmAction('removeOnboardingTeam', [])
      } else {
        this.onboardingProcess()
      }
    },
    removeOnboardingTeam(confirmation) {
      console.debug('Method:removeOnboardingTeam', confirmation)
      $('#' + confirmation.type).modal('hide')
      if (confirmation.status) {
        this.onboardingProcess()
      }
    },
    async actionFetchTopicsAndFavorites () {
      const res = await this.$store
          .dispatch('fetchTopicsAndFavorites')
          .then((res) => {
            if (res.data.status) {
              console.log('Discover new content', res.data)
              this.$store.commit(folderTypes.SET_FOLDERS_LIST, res.data.favorites)
              this.$store.commit(
                  topicTypes.SET_CURATED_TOPICS_LIST,
                  res.data.curated
              )
              this.$store.commit(topicTypes.SET_CUSTOM_TOPICS, res.data.custom)
              this.$store.commit(
                  topicCustomizedSidebarTypes.SET_CUSTOM_SIDEBAR_OPTIONS,
                  res.data.customized_sidebar
              )
            }
            return res
          })
          .catch((err) => {
            console.log(err)
          })
      return res
    }
  },
}
