<template>
  <main class="bg-white flex flex-1 flex-col relative">
    <div
      v-if="accountSelection"
      class="flex items-center justify-between px-6 h-16 max-h-16 min-h-16 bg-white"
    >
      <span class="font-medium text-base">Post Composer</span>
      <div class="flex items-center mr-2.5">
        <p class="font-normal text-sm text-gray-800 mx-4">
          <template v-if="lastUpdated && lastUpdated.date">
            Saved {{ $filters.relative(lastUpdated.date) }}
          </template>
        </p>
        <span class="font-normal text-sm text-gray-900 mr-2"
          >Customize Content</span
        >
        <div id="separate-box-switch">
          <CstSwitch
            v-model="isSeparateBoxes"
            :disabled="isEmptyAccountSelection"
            size="small"
          />
        </div>
        <div
          class="pr-4 h-4 border-[#efeeee] border-solid border-t-0 border-r-[1px] border-l-0 border-b-0"
        >
        </div>
        <button
          v-tooltip="{
            content: tooltipText,
            trigger: 'hover',
            placement: 'top',
          }"
          class="flex items-center justify-center w-7 h-7 border rounded-md hover:bg-gray-100 cursor-pointer bg-white hover:!border-gray-900 ml-4"
          @click="copyUrlToClipBoard"
        >
          <i
            class="fa fa-files-o text-gray-700 font-thin"
            aria-hidden="true"
          ></i>
        </button>

        <b-tooltip
          v-if="isEmptyAccountSelection"
          target="separate-box-switch"
          triggers="hover"
          right
          >Please select an account first
        </b-tooltip>
      </div>
    </div>
    <div
      id="post_composer"
      class="px-6 pt-px flex-1 overflow-y-auto pb-12 cs-hr-scroll"
    >
      <!--      common Box -->
      <div :class="{ hidden: !commonBoxStatus }">
        <EditorBox
          ref="common-editor-box-ref"
          :username="username"
          :toolbar="getEditorSettings.singleBox.toolbar"
          :max-limit="getEditorSettings.singleBox.maxLimit"
          :twitter-text-enable="getEditorSettings.singleBox.twitterTextEnable"
          :sharing-details="sharingDetails.common_sharing_details"
          :accounts-data="getAccountsData"
          :video-errors="videoErrors"
          type="common"
          :utm="utm"
          :plan-id="planId"
          :replug-loader="replugLoader"
          :insta-posting-method="instaPostingMethod"
          :instagram-post-type="instagramPostType"
          :facebook-post-type="facebookPostType"
          :youtube-post-type="youtubePostType"
          :social-post-type="socialPostType"
          :apply-hashtag="handleHashtagsChanges"
          :account-selection="accountSelection"
          :uploading-ai-images="getUploadingState"
          :selected-tabs="selectedTabs"
          :active-tabs="activeTabs"
          @profile-tagging="handleProfileTagging"
          @toggleFirstComment="toggleFirstComment"
          @toggleThreadedTweets="toggleThreadedTweets"
          @toggleCarouselPost="toggleCarouselPost"
          @onEditorBoxTextChange="onEditorBoxTextChange"
          @removeLinkPreview="removeLinkPreview"
          @handleSharingLink="handleSharingLink"
          @setLinkPreview="setLinkPreview"
          @initializeVideoUpload="initializeVideoUpload"
          @addMedia="addMedia"
          @removeMedia="handleRemoveMedia"
          @add-location="handleLocationChanges"
          @handle-utm="handleUtmChanges"
          @handle-replug="handleReplugChanges"
          @handle-hashtags="handleHashtagsChanges"
          @handle-change="onChangeSharingDetails"
          @add-single-image="addSingleImage"
          @split-tweet-content="handleSplitTweetContent"
          v-on="$listeners"
        />
      </div>

      <!--      separate boxes-->
      <div :class="{ hidden: commonBoxStatus }" class="relative">
        <div
          class="absolute flex-row text-right right-0 flex items-center justify-center my-auto h-14 p-2"
        >
          <a class="clear_content_link" @click="composerClearContent"
            >Clear all content
            <i
              v-tooltip="{
                content:
                  'By clearing content, your content from all the social media message box will be removed.',
                trigger: 'hover',
                placement: 'top',
              }"
              class="far fa-question-circle"
            ></i>
          </a>
        </div>
        <!-- Customize Boxes -->

        <CstTabs
          ref="custom_box_tabs"
          active-tab-class="border-0 composer-tab-active"
          content-class="mt-2.5"
          @active-tab="
            (val) => {
              activeTabIndex = val
            }
          "
        >
          <template v-for="(value, propertyName, index) in getEditorSettings">
            <CstTab
              v-if="propertyName !== 'singleBox'"
              :key="index"
              :hidden="!isAccountSelected(propertyName)"
              :title-class="`px-1 mr-1 py-2 border-0 bg-transparent rounded-lg composer-tab-${propertyName}`"
              :name="propertyName"
            >
              <template v-slot:title>
                <div
                  :class="
                    'inline-flex justify-center items-center w-8 h-8 rounded-lg ' +
                    getSocialBackground(propertyName)
                  "
                >
                  <img
                    :src="
                      require('@assets/img/integration/' +
                        getSocialIcon(propertyName))
                    "
                    alt=""
                    class="w-5"
                  />
                </div>
              </template>
              <EditorBox
                :ref="propertyName + '-editor-box-ref'"
                :username="username"
                :toolbar="value.toolbar"
                :max-limit="value.maxLimit"
                :twitter-text-enable="value.twitterTextEnable"
                :sharing-details="
                  sharingDetails[propertyName + '_sharing_details']
                "
                :accounts-data="getAccountsData"
                :type="propertyName"
                :utm="utm"
                :plan-id="planId"
                :replug-loader="replugLoader"
                :social-post-type="socialPostType"
                :insta-posting-method="instaPostingMethod"
                :instagram-post-type="instagramPostType"
                :facebook-post-type="facebookPostType"
                :youtube-post-type="youtubePostType"
                :apply-hashtag="handleHashtagsChanges"
                :account-selection="accountSelection"
                :uploading-ai-images="getUploadingState"
                :selected-tabs="selectedTabs"
                :active-tabs="activeTabs"
                @toggleFirstComment="toggleFirstComment"
                @toggleThreadedTweets="toggleThreadedTweets"
                @toggleCarouselPost="toggleCarouselPost"
                @onEditorBoxTextChange="onEditorBoxTextChange"
                @removeLinkPreview="removeLinkPreview"
                @handleSharingLink="handleSharingLink"
                @setLinkPreview="setLinkPreview"
                @initializeVideoUpload="initializeVideoUpload"
                @addMedia="addMedia"
                @removeMedia="handleRemoveMedia"
                @add-location="handleLocationChanges"
                @handle-utm="handleUtmChanges"
                @handle-replug="handleReplugChanges"
                @handle-hashtags="handleHashtagsChanges"
                @handle-change="onChangeSharingDetails"
                @add-single-image="addSingleImage"
                @profile-tagging="handleProfileTagging"
                @split-tweet-content="handleSplitTweetContent"
                v-on="$listeners"
              />
            </CstTab>
          </template>
        </CstTabs>
      </div>

      <!-- Threaded Tweets Box -->
      <div :class="{ hidden: !showTwitterThreads }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-3.5"
          :class="{ hidden: isThreadedTweetsAdded }"
        >
          <div class="w-48 flex items-center">
            <div
              class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-gray-400"
            >
              <img
                src="@assets/img/composer/threaded-tweet-icon.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >Threaded Tweet</span
            >
            <v-popover
              trigger="hover"
              placement="bottom-end"
              popover-class="threaded-tweets__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div> Threaded Tweets are available for:</div>
                <ul>
                  <li><i class="fab fa-twitter"></i> Profiles</li>
                </ul>
                <p class="threaded-tweets__message-box"
                  >How to add threaded tweets?
                  <a
                    v-close-popover="true"
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="61826fd112c07c18afde3ed5"
                    >Click here</a
                  ></p
                >
              </template>
            </v-popover>
          </div>
          <div>
            <CstSwitch
              v-model="twitterOptions.has_threaded_tweets"
              class="mx-2"
              :disabled="twitterAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="isThreadedTweetsAdded" class="mt-4">
          <EditorThreadedTweetsBox
            :video-errors="videoErrors"
            :twitter-options="twitterOptions"
            :accounts="twitterAccounts"
            :accounts-data="getAccountsData"
            :apply-hashtag="handleHashtagsChanges"
            @onChangeThreadDetails="onChangeThreadDetails"
            @addThreadedTweet="addThreadedTweet"
            @removeThreadedTweet="removeThreadedTweet"
            @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>
      <!-- Carousel Box -->
      <div :class="{ hidden: !showCarousel }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-4"
          :class="{ hidden: isCarouselPostAdded }"
        >
          <div class="w-48 flex items-center">
            <div
              class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary"
            >
              <img
                src="@assets/img/composer/facebook-carousel-icon.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >Facebook Carousel</span
            >
            <v-popover
              trigger="hover"
              placement="bottom-end"
              popover-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div> Carousel Post is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add carousel post?
                  <a
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="6221f69bab585b230a89d34b"
                    >Click here</a
                  ></p
                >
              </template>
            </v-popover>
          </div>
          <div>
            <CstSwitch
              v-model="carouselOptions.is_carousel_post"
              class="mx-2"
              :disabled="carouselAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="isCarouselPostAdded" class="mt-4">
          <EditorCarouselBox
            :account-selection="accountSelection"
            :carousel-accounts="carouselAccounts"
            :carousel-options="carouselOptions"
            :errors="fbCarouselErrors"
            :carousel-images="getSharingDetailsForCarousel.image"
            :url="carouselUrl"
            v-on="$listeners"
          />
        </div>
      </div>

      <!-- First Comment Box -->
      <div :class="{ hidden: !showFirstComment }">
        <div
          class="grid grid-flow-col auto-cols-max items-center my-5 ml-4"
          :class="{ hidden: firstComment.has_first_comment }"
        >
          <div class="w-48 flex items-center">
            <div
              class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary"
            >
              <img
                src="@assets/img/composer/comment-icon.svg"
                alt=""
                class="w-5 h-5"
              />
            </div>
            <span class="mx-2 font-normal text-sm text-black-300"
              >First Comment</span
            >
            <v-popover
              class=""
              trigger="hover"
              placement="bottom-end"
              popover-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
                ><img
                  src="@assets/img/composer/help-icon.svg"
                  alt=""
                  class="w-5 h-5"
              /></a>
              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div> First comment is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages and Groups</li>
                  <li><i class="fab fa-instagram"></i> API Posting Method</li>
                  <li>
                    <i class="fab fa-linkedin-in"></i> Profiles and Pages
                  </li>
                  <li>
                    <i class="fab fa-youtube"></i> All videos except 'Private'
                    and 'Made for Kids'
                  </li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add first comment?
                  <a
                    v-close-popover="true"
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="60407b770a2dae5b58fb5bef"
                    >Click here</a
                  ></p
                >
              </template>
            </v-popover>
          </div>
          <div>
            <CstSwitch
              v-model="firstComment.has_first_comment"
              class="mx-2"
              :disabled="firstCommentAccounts.length < 1"
              size="small"
            />
          </div>
        </div>
        <div v-if="firstComment.has_first_comment" class="mt-4">
          <EditorFirstCommentBox
            :first-comment="firstComment"
            :accounts-list="firstCommentAccounts"
            :apply-hashtag="handleHashtagsChanges"
            :is-allow-first-comment="isAllowFirstComment"
            @changeFirstComment="handleFirstCommentChange"
            @handle-hashtags="handleHashtagsChanges"
          />
        </div>
      </div>

      <!-- gmb Settings-->
      <CstCollapsible
        id="gmb-settings"
        :class="{ hidden: !showGmbSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="font-medium text-sm text-black-300 flex justify-center items-center"
          >
            <div
              class="'inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg font-medium bg-cs-light-blue mx-1.5"
            >
              <img
                src="@assets/img/integration/gmb-icon.svg"
                alt=" "
                class="w-6 h-6"
              />
            </div>
            Google My Business
            <v-popover
              class="mx-1 mr-0.5"
              offset="10"
              placement="bottom-start"
              popover-class="tooltip-danger tooltip-danger--clickable"
              trigger="hover"
            >
              <!--              <i v-if="gmbErrors.length" id="gmb-options-tooltip"-->
              <!--                 class="fas fa-exclamation-square flex justify-center items-center w-6 h-6 rounded-lg bg-red-500 text-white mr-1 text-xs"></i>-->
              <img
                v-if="gmbErrors.length"
                id="gmb-options-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template slot="popover">
                <div>
                  <ul>
                    <li
                      v-for="(item, index) in gmbErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}</li
                    >
                  </ul>
                </div>
              </template>
            </v-popover>
          </span>
        </template>
        <template v-slot>
          <GMBOptions
            :gmb-options="gmbOptions"
            :gmb-sharing-data="activeGmbSharingDetail"
            @setButtonLink="setButtonLink"
            @setGmbOptionsEdited="(val) => $emit('setGmbOptionsEdited', val)"
          ></GMBOptions>
        </template>
      </CstCollapsible>

      <!-- Facebook Settings -->
      <CstCollapsible
        id="facebook-settings"
        class="box-hover mt-4"
        :class="{ hidden: !showFacebookSettings }"
        open
      >
        <template v-slot:header>
          <span
            class="font-medium text-sm text-black-300 flex justify-center items-center"
          >
            <span
              class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-light-blue text-[#02b2ff] mx-1.5"
            >
              <img
                src="@assets/img/integration/facebook-icon.svg"
                alt=""
                class="w-5"
              />
            </span>
            Facebook Settings
          </span>
        </template>
        <template v-slot>
          <FacebookOptions
            :facebook-post-type="facebookPostType"
            :facebook-video-title="activeFacebookSharingDetail.video.title"
            :is-facebook-groups-selected="isFacebookGroupsSelected"
            :is-facebook-page-selected="isFacebookPageSelected"
            v-on="$listeners"
          ></FacebookOptions>
        </template>
      </CstCollapsible>

      <!-- Instagram Settings -->
      <CstCollapsible
        id="instagram-settings"
        :class="{ hidden: !showInstagramSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="font-medium text-sm text-black-300 flex justify-center items-center"
          >
            <i
              class="fab fa-instagram inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-light-red text-cs-red mx-1.5"
            ></i>
            Instagram Settings
          </span>
        </template>
        <template v-slot>
          <InstagramOptions
            :instagram-post-type="instagramPostType"
            :is-video-post="isInstagramVideoPost"
            :instagram-selection="instagramSelection"
            :insta-posting-method="instaPostingMethod"
            v-on="$listeners"
          />
        </template>
      </CstCollapsible>
      <!-- youtube Settings-->
      <CstCollapsible
        id="youtube-settings"
        :class="{ hidden: !showYoutubeSettings }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="font-medium text-sm text-black-300 flex justify-center items-center"
          >
            <i
              class="fab fa-youtube inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-light-red text-cs-red mx-1.5"
            ></i>
            Youtube Settings
            <v-popover
              class="mx-1 mr-0.5"
              offset="10"
              popover-class="tooltip-danger tooltip-danger--clickable"
              placement="bottom-start"
              trigger="hover"
            >
              <!--              <i-->
              <!--                 class="fas fa-exclamation-square flex justify-center items-center w-6 h-6 rounded-lg bg-red-500 text-white mr-1 text-xs"></i>-->
              <img
                v-if="youtubeErrors.length"
                id="youtube-options-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template slot="popover">
                <div>
                  <ul>
                    <li
                      v-for="(item, index) in youtubeErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}</li
                    >
                  </ul>
                </div>
              </template>
            </v-popover>
          </span>
        </template>
        <template v-slot>
          <YoutubeOptions
            :account-selection="accountSelection"
            :accounts-list="accountsList"
            :youtube-options="youtubeOptions"
            v-on="$listeners"
          />
        </template>
      </CstCollapsible>
      <!-- Posting Options-->
      <CstCollapsible
        id="posting-schedule"
        :class="{ hidden: isApprover }"
        class="box-hover mt-4"
        open
      >
        <template v-slot:header>
          <span
            class="font-medium text-sm text-black-300 flex justify-center items-center"
          >
            <div
              class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg font-medium bg-gray-400 mx-1.5"
            >
              <img
                src="@assets/img/composer/posting-schedule.svg"
                alt=" "
                class="w-6 h-6"
              />
            </div>
            Posting Schedule
            <v-popover
              class="mx-1 mr-0.5"
              offset="10"
              popover-class="tooltip-danger tooltip-danger--clickable"
              placement="bottom-start"
              trigger="hover"
            >
              <!--              <i v-if="postingScheduleErrors.length" id="posting-schedule-tooltip"-->
              <!--                 class="fas fa-exclamation-square flex justify-center items-center w-6 h-6 rounded-lg bg-red-500 text-white mr-1 text-xs"></i>-->
              <img
                v-if="postingScheduleErrors.length"
                id="posting-schedule-tooltip"
                src="@assets/img/composer/error-icon.svg"
                alt=""
                class="w-6 h-6"
              />
              <template slot="popover">
                <div>
                  <ul>
                    <li
                      v-for="(item, index) in postingScheduleErrors"
                      :key="`gmb_options_${index}`"
                      >{{ item.text }}
                    </li>
                  </ul>
                </div>
              </template>
            </v-popover>
          </span>
        </template>

        <template v-slot>
          <PostingSchedule
            :account-selection="accountSelection"
            :is-facebok-selected="false"
            :is-instagram-selected="
              (instagramSelection &&
                instagramSelection.no_access_accounts.length > 0) ||
              instagramSelection.all_access_accounts.length > 0
            "
            :instagram-selection="instagramSelection"
            :selected-content-category="selectedContentCategory"
            :publish-time-options="publishTimeOptions"
            :global-content-category="globalContentCategory"
            :next-content-category-slot="nextContentCategorySlot"
            :plan-id="planId"
            :queue-status="queueStatus"
            :post-status="postStatus"
            :social-post-type="socialPostType"
            v-on="$listeners"
          ></PostingSchedule>
        </template>
      </CstCollapsible>

      <div
        v-if="
          publishTimeOptions.time_type !== 'now' &&
          approval.approvers &&
          approval.approvers.length > 0 &&
          getUserRole !== 'Approver'
        "
        class="post_share_option"
      >
        <div class="schedule_detail_box mt-3">
          <div class="bottom_content mt-0">
            <h3
              >Approval Status
              <!--(<span class="approval_status">{{getApprovalOptionText(getPublishSelection.planApprovalData.approve_option)}}</span>)-->
            </h3>
            <p
              v-if="approval.approvers.length > 1"
              class="p-2 text-center"
              v-html="getApprovalOptionText(approval.approve_option)"
            />

            <div class="approval_list">
              <FeedViewApprovalStatus
                :approval="approval"
              ></FeedViewApprovalStatus>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainComposerFooter
      :warnings="footerData.warnings"
      :errors="footerData.errors"
      :publish-time-options="footerData.publishTimeOptions"
      :approval-data="footerData.approvalData"
      :global-content-category="footerData.globalContentCategory"
      :post-has-errors="footerData.postHasErrors"
      :is-approver="footerData.isApprover"
      :next-content-category-slot="footerData.nextContentCategorySlot"
      :queue-status="queueStatus"
      :post-status="postStatus"
      :social-post-type="socialPostType"
      :process-plan-loader="processPlanLoader"
      :approver-modal-validation="approverModalValidation"
      :clear-state="clearState"
      :uploading-ai-images="getUploadingState"
      v-on="$listeners"
    />
  </main>
</template>

<script>
import CstSwitch from '@ui/Switch/CstSwitch'
import CstTabs from '@ui/CstTabs'
import CstTab from '@ui/CstTab'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import editorInitialValue from '@src/modules/composer_v2/components/EditorBox/editorInitialValue'
import PostingSchedule from '@src/modules/composer_v2/components/PostingSchedule/PostingSchedule'
import { commonMethods } from '@common/store/common-methods'
import {
  defaultCommonLocation,
  defaultFirstComment,
  defaultSharingDetails,
  defaultTwitterOptions,
  defaultTwitterSharingDetails,
  firstCommentChannels,
  videoDefaultSharingDetails,
} from '@src/modules/composer_v2/views/composerInitialState'
import EditorFirstCommentBox from '@src/modules/composer_v2/components/EditorBox/EditorFirstCommentBox'
import EditorThreadedTweetsBox from '@src/modules/composer_v2/components/EditorBox/EditorThreadedTweetsBox'
import EditorCarouselBox from '@src/modules/composer_v2/components/EditorBox/EditorCarouselBox'

import {
  facebookPostLimit,
  fetchReplugLinksUrl,
  gmbPostLimit,
  instagramPostLimit,
  linkedinPostLimit,
  pinterestPostLimit,
  twitterPostLimit,
  youtubePostLimit,
} from '@src/modules/publish/config/api-utils'
import GMBOptions from '@src/modules/composer_v2/components/ChannelOptions/GmbOptions'
import YoutubeOptions from '@src/modules/composer_v2/components/ChannelOptions/YoutubeOptions'
import proxy from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { swalAttributes } from '@common/constants/common-attributes'
import FeedViewApprovalStatus from '@src/modules/planner/components/view/feed-view/FeedViewApprovalStatus'
import {
  HASHTAG_SAVE_SUCCESS,
  INVALID_COUNT,
  INVALID_NAME_HASHTAG,
  NO_HASHTAG,
} from '@common/constants/messages'
import hashtag from '@common/lib/hashtag'
import { saveHashTagUrl } from '@src/modules/setting/config/api-utils'
import InstagramOptions from '@src/modules/composer_v2/components/ChannelOptions/InstagramOptions'
import FacebookOptions from '@src/modules/composer_v2/components/ChannelOptions/FacebookOptions'
import MainComposerFooter from '@src/modules/composer_v2/components/MainComposerFooter'
import { EventBus } from '@common/lib/event-bus'

const clone = require('rfdc/default')
export default {
  name: 'MainComposer',
  components: {
    InstagramOptions,
    FacebookOptions,
    FeedViewApprovalStatus,
    YoutubeOptions,
    GMBOptions,
    PostingSchedule,
    CstSwitch,
    CstCollapsible,
    EditorFirstCommentBox,
    EditorThreadedTweetsBox,
    EditorCarouselBox,
    MainComposerFooter,
    CstTabs,
    CstTab,
  },
  mixins: [ComposerHelper],
  props: {
    username: {
      type: String,
      default: '',
      required: true,
    },
    commonBoxStatus: {
      type: Boolean,
      default: true,
      required: true,
    },
    sharingDetails: {
      type: Object,
      default: () => defaultSharingDetails,
      required: true,
    },
    platforms: {
      type: Array,
      default: () => [],
    },
    accountsList: {
      type: Array,
      default: () => [],
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
    firstCommentAccountsList: {
      type: Array,
      default: () => [],
    },
    firstComment: {
      type: Object,
      default: () => defaultFirstComment,
    },
    selectedContentCategory: {
      type: Object,
      default: () => {},
    },
    nextContentCategorySlot: {
      type: Object,
      default: () => {},
    },
    publishTimeOptions: {
      type: Object,
      default: () => {},
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    planId: {
      type: String,
      default: null,
    },
    utm: {
      type: Object,
      default: () => {},
    },
    gmbOptions: {
      type: Object,
      default: () => {},
    },
    youtubeOptions: {
      type: Object,
      default: () => {},
    },
    twitterOptions: {
      type: Object,
      default: () => {
        return { ...defaultTwitterOptions }
      },
    },
    carouselOptions: {
      type: Object,
      default: () => {},
    },
    carouselUrl: {
      type: Object,
      default: () => {},
    },
    carouselAccounts: {
      type: Array,
      default: () => [],
    },
    isAllowFirstComment: {
      type: Function,
      default: () => {},
    },
    gmbErrors: {
      type: Array,
      default: () => [],
    },
    youtubeErrors: {
      type: Array,
      default: () => [],
    },
    fbCarouselErrors: {
      type: Array,
      default: () => [],
    },
    postingScheduleErrors: {
      type: Array,
      default: () => [],
    },
    videoErrors: {
      type: Array,
      default: () => [],
    },
    socialErrors: {
      type: Array,
      default: () => [],
    },
    approval: {
      type: Object,
      default: () => {},
    },
    isApprover: {
      type: Boolean,
    },
    instaPostingMethod: {
      type: String,
      default: '',
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    lastUpdated: {
      type: [Object, String],
      default: () => {},
    },
    queueStatus: {
      type: [Boolean, Number],
      default: false,
    },
    footerData: {
      type: Object,
      default: () => {},
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    processPlanLoader: {
      type: Boolean,
      default: false,
    },
    approverModalValidation: {
      type: Function,
      default: () => {},
    },
    postStatus: {
      type: String,
      default: 'draft',
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    clearState: {
      type: Function,
      default: () => {},
    },
    selectedTabs: {
      type: String,
      default: '',
    },
    activeTabs: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSeparateBoxes: false,
      instagramSelection: {
        no_access_accounts: [],
        all_access_accounts: [],
      },
      characterCount: 0,
      editorsCharacterCount: {
        common: 0,
        facebook: 0,
        twitter: 0,
        instagram: 0,
        linkedin: 0,
        youtube: 0,
        pinterest: 0,
        gmb: 0,
        tumblr: 0,
        tiktok: 0,
      },
      activeTabIndex: 0,
      replugLoader: false,
      tooltipText: 'Copy composer url',
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces']),
    /**
     * get state of image uploading
     * @returns {boolean}
     */
    getUploadingState() {
      return this.uploadingAiImages
    },
    getEditorSettings() {
      return {
        singleBox: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: this.getLocationStatus(),
            splitContent: this.accountSelection.twitter.length > 0,
          },
          twitterTextEnable: this.accountSelection.twitter.length > 0,
          maxLimit: this.getCharacterLimit('common'),
        },
        facebook: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: true,
          },
          maxLimit: facebookPostLimit,
        },
        instagram: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: true,
          },
          maxLimit: instagramPostLimit,
        },
        twitter: {
          toolbar: {
            ...editorInitialValue.toolbar,
            location: true,
            splitContent: true,
          },
          twitterTextEnable: true,
          maxLimit: twitterPostLimit,
        },
        linkedin: {
          toolbar: {
            ...editorInitialValue.toolbar,
          },
          maxLimit: linkedinPostLimit,
        },
        pinterest: {
          maxLimit: pinterestPostLimit,
        },
        gmb: {
          maxLimit: gmbPostLimit,
        },
        youtube: {
          toolbar: {
            ...editorInitialValue.toolbar,
          },
          maxLimit: youtubePostLimit,
        },
        tiktok: {
          ...editorInitialValue,
        },
        tumblr: {
          maxLimit: 0,
        },
      }
    },
    getAccountsData() {
      return {
        accountSelection: this.accountSelection,
        accountsList: this.accountsList,
        firstCommentAccounts: this.firstCommentAccountsList,
      }
    },
    isEmptyAccountSelection() {
      return (
        Object.entries(this.accountSelection).find(
          ([key, value]) => value.length > 0
        ) === undefined
      )
    },
    isThreadedTweetsAdded() {
      return (
        this.twitterOptions.has_threaded_tweets &&
        this.accountSelection.twitter.length > 0
      )
    },
    getSharingDetailsForCarousel() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.facebook_sharing_details
    },
    isCarouselPostAdded() {
      return (
        this.carouselAccounts.length > 0 &&
        this.carouselOptions.is_carousel_post
      )
    },
    isInstagramVideoPost: function () {
      const details = this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.instagram_sharing_details
      return !!(
        details.video &&
        details.video.link &&
        details.video.link !== ''
      )
    },
    firstCommentAccounts() {
      return this.accountsList.filter(
        (account) =>
          account.checked && firstCommentChannels.includes(account.channel_type)
      )
    },
    twitterAccounts() {
      return this.accountsList.filter(
        (account) => account.channel_type === 'twitter' && account.checked
      )
    },
    isFacebookPageSelected() {
      let isFbGroupSelected = false
      this.getFacebookAccounts.items.forEach((account) => {
        if (
          account.type === 'Page' &&
          this.accountSelection.facebook.includes(account.facebook_id)
        ) {
          isFbGroupSelected = true
        }
      })
      return isFbGroupSelected
    },
    isFacebookGroupsSelected() {
      let isFbGroupSelected = false
      this.getFacebookAccounts.items.forEach((account) => {
        if (
          account.type === 'Group' &&
          this.accountSelection.facebook.includes(account.facebook_id)
        ) {
          isFbGroupSelected = true
        }
      })
      return isFbGroupSelected
    },
    showGmbSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.gmb.length
      }
      return (
        this.accountSelection.gmb.length &&
        this.activeTabIndex === this.getTabIndexByName('gmb')
      )
    },
    showYoutubeSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.youtube.length
      }
      return (
        this.accountSelection.youtube.length &&
        this.activeTabIndex === this.getTabIndexByName('youtube')
      )
    },
    showFacebookSettings() {
      if (this.commonBoxStatus) {
        return (
          this.accountSelection.facebook.length &&
          this.activeFacebookSharingDetail?.video?.link
        )
      }
      return (
        this.accountSelection.facebook.length &&
        this.activeFacebookSharingDetail?.video?.link &&
        this.activeTabIndex === this.getTabIndexByName('facebook')
      )
    },
    showInstagramSettings() {
      if (this.commonBoxStatus) {
        return this.accountSelection.instagram.length
      }
      return (
        this.accountSelection.instagram.length &&
        this.activeTabIndex === this.getTabIndexByName('instagram')
      )
    },
    showTwitterThreads() {
      return (
        this.commonBoxStatus ||
        this.activeTabIndex === this.getTabIndexByName('twitter')
      )
    },
    showCarousel() {
      return (
        this.commonBoxStatus ||
        this.activeTabIndex === this.getTabIndexByName('facebook')
      )
    },
    showFirstComment() {
      return (
        this.commonBoxStatus ||
        firstCommentChannels.includes(
          this.getTabNameByIndex(this.activeTabIndex)
        )
      )
    },
    activeGmbSharingDetail() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.gmb_sharing_details
    },
    activeFacebookSharingDetail() {
      return this.commonBoxStatus
        ? this.sharingDetails.common_sharing_details
        : this.sharingDetails.facebook_sharing_details
    },
    youtubePostType() {
      return this.youtubeOptions.post_type || 'video'
    },
  },
  watch: {
    isSeparateBoxes: function (val) {
      if (val === this.commonBoxStatus) {
        this.$emit('isSeparateBoxes', val)
      }
    },
    isEmptyAccountSelection: function (val) {
      console.debug('isEmptyAccountSelection', val)
      if (val) {
        this.isSeparateBoxes = false
        this.$emit('isSeparateBoxes', false)
      }
    },
    firstCommentAccounts: function (val) {
      if (val.length === 0 && this.firstComment.has_first_comment)
        this.toggleFirstComment()
    },
    twitterAccounts: function (val) {
      if (val.length === 0 && this.twitterOptions.has_threaded_tweets)
        this.toggleThreadedTweets()
    },
    'twitterOptions.has_threaded_tweets': function (val) {
      if (!val) {
        const twitterOptions = clone(defaultTwitterOptions)
        this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
      }
    },
    carouselAccounts: function (val) {
      if (val.length === 0 && this.carouselOptions.is_carousel_post)
        this.toggleCarouselPost()
    },
    activeTabIndex: function (val) {
      this.$emit('active-tab', this.getTabNameByIndex(val))
    },
  },
  mounted() {
    console.debug('MainComposer mounted ->  ', this.accountsList)
    this.initializeCrello()
    this.isSeparateBoxes = !this.commonBoxStatus
    this.instagramSelection = commonMethods.linkedInstagramAccountExist(
      this.accountSelection,
      this.accountsList
    )
    EventBus.$on(
      'add-media-from-media-library',
      ({ type, media, mediaType }) => {
        console.debug('add-media-from-media-library', type, media, mediaType)
        this.addMedia(type, media, mediaType)
      }
    )
  },
  beforeDestroy() {
    EventBus.$off('add-media-from-media-library')
  },
  methods: {
    handleProfileTagging(link, type) {
      this.$emit('profile-tagging', link, type)
    },

    getTabIndexByName(name) {
      switch (name) {
        case 'facebook':
          return 0
        case 'instagram':
          return 1
        case 'twitter':
          return 2
        case 'linkedin':
          return 3
        case 'pinterest':
          return 4
        case 'gmb':
          return 5
        case 'youtube':
          return 6
        case 'tiktok':
          return 7
        case 'tumblr':
          return 8
        default:
          return 0
      }
    },

    getTabNameByIndex(index) {
      switch (index) {
        case 0:
          return 'facebook'
        case 1:
          return 'instagram'
        case 2:
          return 'twitter'
        case 3:
          return 'linkedin'
        case 4:
          return 'pinterest'
        case 5:
          return 'gmb'
        case 6:
          return 'youtube'
        case 7:
          return 'tiktok'
        case 8:
          return 'tumblr'
        default:
          return 'facebook'
      }
    },

    setActiveTab(name = 'twitter') {
      this.$refs.custom_box_tabs.setActiveTab(this.getTabIndexByName(name))
    },

    addSingleImage(type, imageUrl) {
      console.debug('addSingleImage', type, imageUrl)
      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      if (this.isImagesHasGif(sharingDetails.image)) {
        this.alertMessage('You can only add one type of file.', 'error')
        return
      }
      if (sharingDetails.image.includes(imageUrl) === false) {
        switch (type) {
          case 'common':
          case 'facebook':
            sharingDetails.image.push(imageUrl)
            break
          case 'twitter':
            if (sharingDetails.image.length > 3) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            break
          case 'instagram':
            if (sharingDetails.image.length > 9) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            break
          case 'linkedin':
            if (sharingDetails.image.length > 8) sharingDetails.image.pop()
            sharingDetails.image.push(imageUrl)
            break
        }
        this.onChangeSharingDetails(sharingDetails, type)
      }
    },

    async composerClearContent() {
      // asking for confirmation
      const res = await this.$bvModal
        .msgBoxConfirm('Are you sure you want to clear all content?', {
          title: 'Clear all content',
          ...swalAttributes(),
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.error('clearContent', err)
          return false
        })

      if (res) {
        // resting box states
        this.$emit('composerResetSharingDetails')
      }
    },

    getCharacterLimit(type) {
      switch (type) {
        case 'common':
          if (this.accountSelection.twitter.length > 0) return twitterPostLimit
          else if (this.accountSelection.pinterest.length > 0)
            return pinterestPostLimit
          else if (this.accountSelection.gmb.length > 0) return gmbPostLimit
          else if (this.accountSelection.instagram.length > 0)
            return instagramPostLimit
          else if (this.accountSelection.linkedin.length > 0)
            return linkedinPostLimit
          else if (this.accountSelection.youtube.length > 0)
            return youtubePostLimit
          else if (this.accountSelection.facebook.length > 0)
            return facebookPostLimit
          else return 0 // default
        case 'facebook':
          return facebookPostLimit
        case 'twitter':
          return twitterPostLimit
        case 'linkedin':
          return linkedinPostLimit
        case 'instagram':
          return instagramPostLimit
        case 'youtube':
          return youtubePostLimit
        case 'pinterest':
          return pinterestPostLimit
        case 'gmb':
          return gmbPostLimit
        default:
          return 5000 // default
      }
    },

    getLocationStatus() {
      return (
        this.accountSelection.facebook.length > 0 ||
        this.accountSelection.instagram.length > 0 ||
        this.accountSelection.twitter.length > 0
      )
    },
    handleLocationChanges(type, locationType, value) {
      console.debug('addLocationMAINCOMPOSER', type, locationType, value)
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      if (type === 'common') {
        if (!('location' in sharingDetails))
          sharingDetails.location = defaultCommonLocation
        sharingDetails.location[locationType] = value
      } else {
        sharingDetails.location = value
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },
    async handleUtmChanges(flag, type, data) {
      console.debug('handleUtmChanges', flag, type, data)
      if (flag === 'fetch') {
        this.$emit('fetch-utms')
      }
      if (flag === 'apply') {
        const sharingDetails = {
          ...this.sharingDetails[type + '_sharing_details'],
        }

        if (sharingDetails.url && sharingDetails.url.trim().length > 0) {
          sharingDetails.message = sharingDetails.message.replace(
            sharingDetails.url,
            data[type]
          )
          sharingDetails.url = data[type]
        } else {
          const urls = this.getLinksFromText(sharingDetails.message)
          if (urls && urls.length > 0) {
            sharingDetails.message = sharingDetails.message.replace(
              urls[0],
              data[type]
            )
          } else {
            sharingDetails.message = sharingDetails.message + ' ' + data[type]
          }
          sharingDetails.url = data[type]
        }
        const urls = [data[type]]
        // for common
        if (type === 'common') {
          Object.entries(data).forEach((value) => {
            if (
              value[0] !== 'auto_add_social_channel' &&
              value[0] !== 'common'
            ) {
              sharingDetails[value[0] + '_url'] = value[1]
              if (data.auto_add_social_channel) urls.push(value[1])
            }
          })
        }
        await this.$emit('onChangeSharingDetails', type, sharingDetails)
        if (this.$el) {
          // When using refs with v-for, the component / DOM nodes are stored as an array, so we need to use the first element of the array.
          // https://github.com/vuejs/vue/issues/4952
          const editor =
            type === 'common'
              ? this.$refs[type + '-editor-box-ref']
              : this.$refs[type + '-editor-box-ref'] &&
                this.$refs[type + '-editor-box-ref'][0]
              ? this.$refs[type + '-editor-box-ref'][0]
              : null
          if (editor && editor.processEditorLinks) {
            editor.processEditorLinks(urls)
          }
        }
      }
    },

    async handleHashtagsChanges(type, flag, data, threadIndex = 0) {
      console.debug('handleHashtagsChanges', data)
      const sharingDetails = clone(
        this.sharingDetails[type + '_sharing_details']
      )
      if (this.validateHashtag(data)) {
        // to save new hashtag
        if (flag === 'save') {
          data.workspace_id = this.getWorkspaces.activeWorkspace._id
          proxy
            .post(saveHashTagUrl, data)

            .then((res) => {
              if (res.data.status) {
                this.$store.commit('ADD_WORKSPACE_HASHTAG', res.data.hashtag)
                this.$store.dispatch('toastNotification', {
                  message: HASHTAG_SAVE_SUCCESS,
                  type: 'success',
                })
              } else {
                this.$store.dispatch('toastNotification', {
                  message: res.data.message,
                  type: 'error',
                })
              }
            })
            .catch((e) => {
              console.error('Save Hashtags =>', e)
              return false
            })
        }
        // to apply hashtag
        // check where to apply

        if (type === 'threaded-tweet') {
          if (
            this.twitterOptions.threaded_tweets &&
            this.twitterOptions.threaded_tweets[threadIndex]
          ) {
            const thread = clone(
              this.twitterOptions.threaded_tweets[threadIndex]
            )
            if (data.position === 'Append')
              thread.message = hashtag.appendHashTag(thread.message, data)
            else thread.message = hashtag.replaceHashTag(thread.message, data)
            this.onChangeThreadDetails(thread, threadIndex)
            return true
          }
        } else if (type === 'first-comment') {
          const firstComment = clone(this.firstComment)
          if (data.position === 'Append')
            firstComment.first_comment_message = hashtag.appendHashTag(
              firstComment.first_comment_message,
              data
            )
          else
            firstComment.first_comment_message = hashtag.replaceHashTag(
              firstComment.first_comment_message,
              data
            )
          this.handleFirstCommentChange(firstComment)
          return true
        } else {
          if (data.position === 'Append') {
            sharingDetails.message = hashtag.appendHashTag(
              sharingDetails.message,
              data
            )
          } else {
            sharingDetails.message = hashtag.replaceHashTag(
              sharingDetails.message,
              data
            )
          }
          this.onChangeSharingDetails(sharingDetails, type)
          return true
        }
      } else {
        return false
      }
    },

    validateHashtag(data) {
      if (data.name.trim() === '') {
        this.$store.dispatch('toastNotification', {
          message: INVALID_NAME_HASHTAG,
          type: 'error',
        })
        return false
      } else if (data.items.length < 1) {
        this.$store.dispatch('toastNotification', {
          message: NO_HASHTAG,
          type: 'error',
        })
        return false
      } else if (data.count < 1) {
        this.$store.dispatch('toastNotification', {
          message: INVALID_COUNT + ' ' + data.items.length,
          type: 'error',
        })
        return false
      } else if (data.items.length > 0 && data.count > data.items.length) {
        this.$store.dispatch('toastNotification', {
          message: INVALID_COUNT + ' ' + data.items.length,
          type: 'error',
        })
        return false
      }
      return true
    },

    async handleReplugChanges(type, data) {
      // accountSelection keys used just to iterate over platform names
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      const links = [data.link]
      let replugLinks = []
      if (type === 'common') {
        Object.keys(this.accountSelection).forEach((key) => {
          if (key !== 'tiktok') links.push(sharingDetails[key + '_url'])
        })
        // ! tiktok_url is not in common details
      }
      this.replugLoader = true
      await proxy
        .post(fetchReplugLinksUrl, {
          links: links,
          campaign: data.campaign,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status) {
            replugLinks = response.data.links
            this.replugLoader = false
          } else {
            this.alertMessage(response.data.message, 'error')
            this.replugLoader = false
          }
        })
        .catch((error) => {
          console.error(error)
          this.replugLoader = false
        })

      // this  block will find and replace the link in message and url both for common and individual platforms
      if (replugLinks && replugLinks.length > 0) {
        replugLinks.forEach((link) => {
          if (
            link.original_link &&
            sharingDetails.message.indexOf(link.original_link) > -1
          ) {
            sharingDetails.message = sharingDetails.message.replace(
              link.original_link,
              link.replug_link
            )
          }
          if (link.original_link === sharingDetails.url) {
            sharingDetails.url = link.replug_link
          }

          if (type === 'common') {
            Object.keys(this.accountSelection).forEach((key) => {
              if (key !== 'tiktok') {
                if (link.original_link === sharingDetails[key + '_url']) {
                  sharingDetails[key + '_url'] = link.replug_link
                }
              }
            })
            // ! tiktok_url is not in common details
          }
        })
      }

      this.replugLoader = false

      this.onChangeSharingDetails(sharingDetails, type)
    },

    disabledBeforeTodayAndAfterAWeek: function (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return (
        date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)
      )
    },

    /** Handle First Comment Change
     * @param {object} firstComment - first comment object
     */
    handleFirstCommentChange: function (firstComment) {
      console.debug('firstCommentChange', firstComment)
      this.$emit('onChangeFirstComment', clone(firstComment))
    },

    toggleFirstComment: function () {
      const firstComment = { ...this.firstComment }
      firstComment.has_first_comment = !this.firstComment.has_first_comment
      this.$emit('onChangeFirstComment', firstComment)
    },
    toggleThreadedTweets: function () {
      const twitterOptions = clone(this.twitterOptions)
      twitterOptions.has_threaded_tweets =
        !this.twitterOptions.has_threaded_tweets
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },
    async handleSplitTweetContent() {
      const boxType = this.isSeparateBoxes ? 'twitter' : 'common'
      const content = this.sharingDetails[boxType + '_sharing_details'].message
      let res = false
      const tweets = []
      let thread = ''
      // eslint-disable-next-line no-useless-escape
      const urlPattern =
        /(http(s)?:\/\/)?(([a-zA-Z0-9])([-\w]*\.)+([^\s\.]+[^\s]*)+[^,.\s])/u
      let splitPattern = ' '
      const urlIncluded =
        this.sharingDetails[boxType + '_sharing_details'].url &&
        !content.includes(this.sharingDetails[boxType + '_sharing_details'].url)
      const twitterOptions = clone(defaultTwitterOptions)
      if (boxType === 'common') {
        res = await this.$root.$bvModal.msgBoxConfirm(
          'In order to automatically split long content into multiple threaded tweets, we would need to enable “customize content” for each social network. Would you like to continue?',
          {
            title: 'Close Composer',
            ...swalAttributes(),
            cancelTitle: 'Cancel',
            okTitle: 'Yes Split',
          }
        )
        if (!res) return
      }
      // if . includes anywhere after 180 char
      if (
        content.includes('. ', 180) &&
        content.lastIndexOf('. ', 280) !== -1
      ) {
        splitPattern = '. '
      }
      // split content by pattern either by . or by space
      content.split(splitPattern).forEach((word) => {
        // if url is added to box add 23 char for URl
        const urlLength = tweets.length === 0 && urlIncluded ? 23 : 0
        if (
          thread.replace(urlPattern, 'http://8901234567890123').length +
            (word + splitPattern).length +
            urlLength >
          280
        ) {
          tweets.push(thread)
          thread = ''
        }
        if (word.length > 280) {
          const pattern = word.lastIndexOf('. ', 280) !== -1 ? '. ' : ' '
          tweets.push(word.slice(0, word.lastIndexOf(pattern, 280)))
          thread +=
            word.slice(word.lastIndexOf(pattern, 280) + 1) + splitPattern
        } else thread += word + splitPattern
      })
      tweets.push(thread)
      // now add splited tweets to twitterOptions
      tweets.forEach((tweet, index) => {
        if (index === 0)
          this.sharingDetails.twitter_sharing_details.message = tweet
        else {
          if (!twitterOptions.threaded_tweets[index - 1])
            twitterOptions.threaded_tweets[index - 1] = clone(
              defaultTwitterSharingDetails
            )
          twitterOptions.threaded_tweets[index - 1].message = tweet
        }
      })
      // add previous tweet
      if (
        this.twitterOptions.threaded_tweets.length > 0 &&
        this.twitterOptions.threaded_tweets[0].message !== ''
      ) {
        this.twitterOptions.threaded_tweets.forEach((item) =>
          twitterOptions.threaded_tweets.push(item)
        )
      }
      twitterOptions.has_threaded_tweets = true
      this.isSeparateBoxes = true
      this.setActiveTab('twitter')
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
      // this.onChangeSharingDetails(sharingDetails, type)
    },

    splitString(str) {
      const maxLength = 280
      const pattern = ' '
      const parts = []
      while (str.length > maxLength) {
        const index = str.lastIndexOf(pattern, maxLength)
        if (index === -1) {
          // If there are no spaces within the maxLength limit, split at the limit
          parts.push(str.slice(0, maxLength))
          str = str.slice(maxLength)
        } else {
          // Otherwise, split at the last space before the limit
          parts.push(str.slice(0, index))
          str = str.slice(index + 1)
        }
      }
      parts.push(str)
      return parts
    },

    toggleCarouselPost: function () {
      const carouselOptions = clone(this.carouselOptions)
      carouselOptions.is_carousel_post = !this.carouselOptions.is_carousel_post
      this.$emit('onChangeSharingOptions', 'carousel', carouselOptions)
    },

    /**
     * Invoked when an instagram account is selected. calculated the instagram warnings and api errors.
     */
    updateInstagramSelection() {
      console.log('METHOD::updateInstagramSelection')
      this.instagramSelection = commonMethods.linkedInstagramAccountExist(
        this.accountSelection,
        this.accountsList
      )
    },

    isAccountSelected(type) {
      if (this.accountSelection[type]) {
        return this.accountSelection[type].length > 0
      }
      return false
    },

    /** Event handlers on Editor Box Text Change
     * @param type {string} type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param message {string} message
     * @param characterCount
     */
    onEditorBoxTextChange: function (type, message, characterCount) {
      console.debug('onEditorBoxTextChange', type, message)
      this.characterCount = characterCount
      this.editorsCharacterCount[type] = characterCount
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      sharingDetails.message = message
      console.log('sharing Details :: ', this.sharingDetails)
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Event handlers on change sharing details
     * @param value details
     * @param type platform type
     */
    onChangeSharingDetails: function (value, type) {
      console.debug('Method::onChangeSharingDetails MainComposer ', value, type)
      this.$emit('onChangeSharingDetails', type, value)
      // this.$emit('editorErrors', this.editorBoxErrors())
    },

    /** Add media to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param media media
     * @param mediaType media type (image, video, etc)
     */
    addMedia(type, media, mediaType) {
      console.debug('addMedia', type, media, mediaType)
      let sharingDetails = { ...this.sharingDetails[type + '_sharing_details'] }
      // remove link preview if it is added
      sharingDetails = this.removeLinkPreview(
        type,
        false,
        false,
        sharingDetails
      )
      if (mediaType === 'image') {
        this.addImages(type, sharingDetails, media)
      } else if (mediaType === 'video') {
        this.addVideo(type, sharingDetails, media)
      }
    },

    /** Add images to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param sharingDetails sharing details
     * @param images array
     */
    addImages(type, sharingDetails, images) {
      console.debug('addImages', type, sharingDetails, images)
      // remove video
      sharingDetails.video = videoDefaultSharingDetails
      switch (type) {
        case 'common':
        case 'facebook':
          sharingDetails.image = images
          break
        case 'twitter':
        case 'threaded-tweet':
          sharingDetails.image = images.length > 3 ? images.slice(0, 4) : images
          break
        case 'linkedin':
          sharingDetails.image = images.length > 9 ? images.slice(0, 9) : images
          break
        case 'instagram':
          sharingDetails.image =
            images.length > 10 ? images.slice(0, 10) : images
          break
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
          sharingDetails.image = images.length > 1 ? images.slice(0, 1) : images
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Add video to editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param sharingDetails sharing details
     * @param video video object
     */
    addVideo(type, sharingDetails, video) {
      console.debug('addVideo', video)
      sharingDetails.image = []
      // commit(social.SET_GMB_OPTIONS, null) TODO: SET_GMB_OPTIONS TO NULL
      sharingDetails.video = video[0]
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** handle Sharing Link event
     * @param type
     * @param link
     */
    handleSharingLink(type, link) {
      console.debug('handleSharingLink', type, link)
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      switch (type) {
        case 'common':
          sharingDetails.url =
            sharingDetails.facebook_url =
            sharingDetails.twitter_url =
            sharingDetails.linkedin_url =
              link
          sharingDetails.instagram_url =
            sharingDetails.pinterest_url =
            sharingDetails.tumblr_url =
            sharingDetails.gmb_url =
              link
          break
        case 'facebook':
        case 'twitter':
        case 'threadedTweets':
        case 'linkedin':
        case 'instagram':
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
          sharingDetails.url = link
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Set link preview
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param preview link preview object
     */
    setLinkPreview(type, preview) {
      console.debug('setLinkPreview', type, preview)
      const sharingDetails = {
        ...this.sharingDetails[type + '_sharing_details'],
      }
      switch (type) {
        case 'common':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.image = sharingDetails.image
          }
          sharingDetails.title = preview.title
          sharingDetails.description = preview.description
          sharingDetails.image = preview.image
          sharingDetails.website = preview.website
          sharingDetails.image_suggestions = preview.image_suggestions
          sharingDetails.twitter_title = preview.twitter_title
          sharingDetails.twitter_description = preview.twitter_description
          sharingDetails.twitter_image = preview.twitter_image
          sharingDetails.twitter_website = preview.twitter_website
          break
        case 'twitter':
        case 'threadedTweets':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.twitter_image = sharingDetails.image
          }
          sharingDetails.title = preview.twitter_title
          sharingDetails.description = preview.twitter_description
          sharingDetails.image = preview.twitter_image
          sharingDetails.website = preview.twitter_website
          sharingDetails.image_suggestions = preview.image_suggestions
          break
        case 'facebook':
        case 'linkedin':
          // using same images if images are already added
          if (sharingDetails.image.length) {
            preview.image = sharingDetails.image
          }
          sharingDetails.title = preview.title
          sharingDetails.description = preview.description
          sharingDetails.image = preview.image
          sharingDetails.website = preview.website
          sharingDetails.image_suggestions = preview.image_suggestions
          break
        case 'instagram':
          if (sharingDetails.image.length === 0) {
            sharingDetails.image = preview.image
          }
          break
        case 'pinterest':
        case 'tumblr':
        case 'gmb':
          if (sharingDetails.image.length === 0) {
            sharingDetails.image = [].concat(preview.singleImage)
          }
          break
      }
      this.onChangeSharingDetails(sharingDetails, type)
    },

    /** Remove link preview
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param removeImage remove link preview image from details (true/false) default false
     * @param triggerEvent trigger event to parent component (true/false) default true
     * @param details sharing details object default null
     */
    removeLinkPreview: function (
      type,
      removeImage = false,
      triggerEvent = true,
      details = null
    ) {
      details = details || { ...this.sharingDetails[type + '_sharing_details'] }
      switch (type) {
        case 'common':
          details.website =
            details.title =
            details.description =
            details.twitter_title =
            details.twitter_description =
            details.twitter_website =
              ''
          details.url =
            details.facebook_url =
            details.twitter_url =
            details.instagram_url =
            details.linkedin_url =
            details.youtube_url =
            details.pinterest_url =
            details.gmb_url =
            details.tumblr_url =
              ''
          details.source_url = details.cta_link = ''
          details.twitter_image = []
          break
        case 'twitter':
        case 'threaded-tweet':
        case 'facebook':
        case 'linkedin':
        case 'instagram':
        case 'tumblr':
        case 'gmb':
        case 'pinterest':
        case 'youtube':
        case 'tiktok':
          details.title =
            details.description =
            details.website =
            details.url =
              ''
          break
      }
      if (removeImage) {
        details.image = []
      }
      if (triggerEvent) {
        this.onChangeSharingDetails(details, type)
      }
      return details
    },

    /**
     * handle remove media
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param mediaType mediaType string (image, video)
     * @param media media to remove object
     * @param removeAll remove all images (true/false) default false
     */
    handleRemoveMedia: function (type, mediaType, media, removeAll = false) {
      console.debug('Method:removeMediaAsset-v2', type, mediaType, media)
      if (mediaType === 'image') {
        this.removeImage(type, media, removeAll)
      } else if (mediaType === 'video') {
        this.removeVideo(type, media)
      }
    },

    /** Remove video from editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     */
    removeVideo: function (type) {
      const details = { ...this.sharingDetails[type + '_sharing_details'] }
      details.video = videoDefaultSharingDetails
      this.onChangeSharingDetails(details, type)
    },
    /** Remove image from editor media box
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param image image to remove
     * @param removeAll remove all images (true/false) default false
     */
    removeImage: function (type, image, removeAll = false) {
      const details = { ...this.sharingDetails[type + '_sharing_details'] }
      let index = -1
      const self = this
      if (details && details.image && details.image.length > 0 && !removeAll) {
        setTimeout(function () {
          switch (type) {
            case 'common':
            case 'facebook':
            case 'instagram':
            case 'twitter':
            case 'threadedTweets':
            case 'linkedin':
              index = details.image.indexOf(image)
              if (index > -1) {
                details.image.splice(index, 1)
                if (
                  details.image_tagging_details &&
                  typeof details.image_tagging_details === 'object' &&
                  details.image_tagging_details[image]
                ) {
                  delete details.image_tagging_details[image]
                }
              }
              break
            case 'pinterest':
            case 'tumblr':
            case 'gmb':
            case 'youtube':
            case 'tiktok':
              details.image = []
              details.url = ''
              break
          }
          self.onChangeSharingDetails(details, type)
        }, 200)
      } else if (removeAll) {
        details.image = []
        details.image_tagging_details = {}
        this.onChangeSharingDetails(details, type)
      }
    },

    /** Initialize video upload from editor
     * @param type type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     */
    initializeVideoUpload: function (type) {
      let sharingDetails = { ...this.sharingDetails[type + '_sharing_details'] }
      // remove link preview if it is added
      sharingDetails = this.removeLinkPreview(type, true, false, sharingDetails)
      sharingDetails.video = videoDefaultSharingDetails
      sharingDetails.image_suggestions = []
      this.onChangeSharingDetails(sharingDetails, type)
    },

    onChangeThreadDetails(threadDetails, index) {
      console.debug('Method:onChangeThreadDetails MainComposer')
      const twitterOptions = { ...this.twitterOptions }
      twitterOptions.threaded_tweets[index] = threadDetails
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    addThreadedTweet() {
      console.debug(
        'Method:addThreadedTweet MainComposer',
        JSON.stringify(defaultTwitterSharingDetails)
      )
      const twitterOptions = { ...this.twitterOptions }
      twitterOptions.threaded_tweets.push({ ...defaultTwitterSharingDetails })
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    removeThreadedTweet(index) {
      console.debug('Method:removeThreadedTweet MainComposer', index)
      const twitterOptions = { ...this.twitterOptions }
      if (twitterOptions.threaded_tweets.length === 1) {
        twitterOptions.has_threaded_tweets = false
        this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
        return
      }
      twitterOptions.threaded_tweets.splice(index, 1)
      this.$emit('onChangeSharingOptions', 'twitter', twitterOptions)
    },

    /**
     * editor box validations calculations.
     * @param type
     * @returns {*[]}
     */
    editorBoxErrors() {
      console.log('EditorBoxErrors:', this.accountSelection)
      const errors = []

      if (this.commonBoxStatus) {
        if (
          this.getEditorSettings.singleBox.maxLimit !== 0 &&
          this.editorsCharacterCount.common >
            this.getEditorSettings.singleBox.maxLimit
        ) {
          errors.push({
            text: `Note: You have exceeded the characters limit, please reduce your content.`,
          })
        }
      } else {
        for (const channelName in this.accountSelection) {
          if (this.accountSelection[channelName].length) {
            console.debug(
              'EditorBoxErrors:',
              channelName,
              this.editorsCharacterCount[channelName]
            )
            if (
              this.editorsCharacterCount[channelName] >
                this.getEditorSettings[channelName].maxLimit &&
              channelName !== 'tumblr'
            ) {
              errors.push({
                text: `Note: You have exceeded the characters limit for ${channelName}, please reduce your content.`,
              })
            }
          }
        }
      }
      return errors
    },

    setButtonLink(link) {
      this.sharingDetails.common_sharing_details.cta_link =
        this.sharingDetails.gmb_sharing_details.cta_link = link
    },

    /**
     * Copy url from address bar to clipboard
     */
    copyUrlToClipBoard() {
      navigator.clipboard.writeText(window.location.href)
      const copied = () => {
        this.tooltipText = 'Copy composer url'
      }
      setTimeout(copied, 1000)
      clearTimeout(copied)
      this.tooltipText = 'Copied'
    },
  },
}
</script>

<style lang="scss">
main {
  //margin-bottom: 90px;
  //margin-bottom:12rem;
}

.fa-gmb {
  position: relative;
  font-family: 'Font Awesome 5 Brands';
  font-weight: normal;
  line-height: 1;
  font-variant: normal;
  text-rendering: auto;

  &:after {
    content: '\f1a0';
  }
}

.box-hover {
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }

  //&:focus-within {
  //  background-color: #fff;
  //  //border-color: #80bdff;
  //  border-color: #80bdff30;
  //  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
  //  outline: 0;
  //}
}
</style>
