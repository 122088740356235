import { mapGetters, mapActions, mapMutations } from 'vuex'
import { addBlockSourceURL } from '@src/modules/setting/config/api-utils'

export const blockedSourcesMixin = {
  computed: {
    ...mapGetters(['getTopicType', 'getTopic']),
  },
  created() {},
  methods: {
    ...mapMutations(['SET_TOPICS_LOADER']),
    ...mapActions(['topicsContent']),
    // TODO: refactor when the topic work changed.
    blockSource(post) {
      const payload = {}
      if (post.domain_id) {
        payload.domain_id = post.domain_id
      }
      if (post.rss_id) {
        payload.rss_id = post.rss_id
      } else {
        payload.rss_id = null
      }
      if (post.rss_url) {
        payload.url = post.rss_url
      } else {
        payload.url = post.domain_url
      }
      if (
        this.getTopicType === 'custom_topic' &&
        this.getTopic &&
        this.$route.name === 'topics'
      ) {
        payload.topic_id = this.getTopic
      }

      console.log(this.$route.name)
      if (this.$route.path.includes('discovery/content/topics')) {
        payload.level = 'topic'
      } else {
        payload.level = 'topic'
      }
      payload.state = 'enabled'
      payload.workspace_id = this.getWorkspaces.activeWorkspace._id

      this.postRequest(
        addBlockSourceURL,
        payload,
        (response) => {
          if (response.data.status) {
            // if user is on the topic page, reload the content.
            if (this.$route.path.includes('discovery/content/topics')) {
              this.SET_TOPICS_LOADER(true)
              this.topicsContent()
              // TOOD: reload work to be checked.
              // this.getContentByTopic()
            } else {
              // article content reload.
              // TODO: reload work to be checked.
              // this.resetFilters()
            }
            this.alertMessage(response.data.message)
          } else {
            this.alertMessage(response.data.message, 'error')
          }
          // retrieve content again.
        },
        (response) => {}
      )
    },
  },
  watch: {},
}
