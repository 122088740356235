<template>
  <div
    class="relative mt-4 cursor-pointer first:mt-0"
    @click="handleImageSelect"
  >
    <div
      class="absolute m-4 z-10 top-0 right-0 w-[1.5rem] h-[1.5rem] p-[0.25rem] border rounded-sm z-20 bg-gray-100"
    >
      <Checkbox :checked="image.select" />
    </div>
    <img
      :src="image.url"
      class="w-full h-full min-h-[16rem] max-h-[63.75rem] rounded-lg"
      alt="Image Listing"
    />
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

// components
import Checkbox from '@src/components/common/Checkbox.vue'

export default defineComponent({
  name: 'ViewImage',
  components: {
    Checkbox,
  },
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    // methods
    const handleImageSelect = () => {
      props.image.select = !props.image.select
    }

    return { handleImageSelect }
  },
})
</script>
