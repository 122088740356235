<template>
  <div class="filter_section_inner width_750">
    <h3 class="filter_section_heading" data-cy="discovery_section_heading">{{
      getFilters.topic.name
    }}</h3>
    <div class="btn_section text-center">
      <button
        data-cy="discovery_topic_follow"
        v-if="getFilters.topic.followed"
        @click.prevent="
          unfollowCuratedTopic({ topic: getFilters.topic, index: null })
        "
        class="btn btn_round border_btn btn_follow"
      >
        <span>UNFOLLOW</span>
      </button>
      <button
        data-cy="discovery_topic_follow"
        v-else
        @click.prevent="followCuratedTopic({ topic: getFilters.topic })"
        class="btn btn_round border_btn btn_follow"
      >
        <span>FOLLOW</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getFilters'])
  },
  methods: {
    ...mapActions(['followCuratedTopic', 'unfollowCuratedTopic'])
  }
}
</script>

<style scoped></style>
