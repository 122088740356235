import { setting } from '@src/modules/setting/store/states/mutation-types'
import proxy from '@common/lib/http-common'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { fetchDevicesUrl } from '@src/modules/planner/config/api-utils.js'

export default {
  state: {
    mobileDevices: [],
    fetchDevicesLoader: false,
  },
  getters: {
    getMobileDevices: (state) => {
      return state.mobileDevices
    },
    getFetchDevicesLoader: (state) => {
      return state.fetchDevicesLoader
    },
  },
  actions: {
    async fetchMobileDevices({ commit, getters, rootGetters, dispatch }) {
      commit(setting.SET_FETCH_DEVICES_LOADER, true)
      return proxy
        .post(fetchDevicesUrl, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id || '',
        })
        .then((response) => {
          if (response.data.status === true) {
            commit(setting.SET_MOBILE_DEVICES, response.data.devices)
          }
          commit(setting.SET_FETCH_DEVICES_LOADER, false)
          return true
        })
        .catch((error) => {
          console.debug(
            'Exception in changeActiveInstagramConnectionTab',
            error
          )
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          commit(setting.SET_FETCH_DEVICES_LOADER, false)
          return false
        })
    },
  },
  mutations: {
    [setting.SET_MOBILE_DEVICES](state, devices) {
      state.mobileDevices = devices
    },

    [setting.SET_FETCH_DEVICES_LOADER](state, status) {
      state.fetchDevicesLoader = status
    },
  },
}
