import { mapGetters, mapActions, mapMutations } from 'vuex'
import { planner } from '@src/modules/planner/store/mutation-types'
import { publish } from '@src/modules/publish/store/states/mutation-types'
import { parseDescriptionHtml } from '@common/lib/helper'

const notification = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  methods: {
    ...mapMutations(['SET_CURRENT_NOTIFICATIONS']),
    ...mapActions(['addToNotifications', 'markNotificationRead']),

    /**
     * check notification workspace and active workspace
     *  if they are different then change workspace according to notification workspace
     * @param notification
     * @returns {null}
     */
    async verifySameWorkspace(notification) {
      if (
        notification.workspace_id !== this.getWorkspaces.activeWorkspace._id
      ) {
        await this.fetchWorkspaces(true)
        this.getWorkspaces.items.filter((workspace) => {
          if (workspace.workspace_id === notification.workspace_id) {
            this.changeWorkspace(workspace.workspace, this.$route.name)
            return null
          }
        })
        // console.debug('not found')
      }
    },

    /**
     * apply comment notification check post type and comment id and redirect to specific post in composer
     * @param notification
     * @returns {null}
     */
    applyCommentNotification(notification) {
      let redirect = false
      if (
        notification.post_type &&
        notification.plan_id &&
        notification.comment_id
      ) {
        if (notification.post_type === 'composerBlog') {
          this.$router.push({
            name: 'composerBlog',
            params: {
              id: notification.plan_id,
              type: 'comment',
              typeId: notification.comment_id,
            },
          })
          redirect = true
          return redirect
        } else if (notification.post_type === 'composerSocial') {
          // for new social composer
          this.editPublication(
            notification.plan_id,
            false,
            false,
            false,
            'Social'
          )
          redirect = true
          return redirect
        }
      }
      return redirect
    },

    /**
     * apply task notification check post type and task id and redirect to specific post in composer
     * @param notification
     * @returns {null}
     */
    applyTaskNotification(notification) {
      let redirect = false
      if (
        notification.post_type &&
        notification.plan_id &&
        notification.task_id
      ) {
        if (notification.post_type === 'composerBlog') {
          this.$router.push({
            name: 'composerBlog',
            params: {
              id: notification.plan_id,
              type: 'task',
              typeId: notification.task_id,
            },
          })
          redirect = true
          return redirect
        } else if (notification.post_type === 'composerSocial') {
          // for new social composer
          this.editPublication(
            notification.plan_id,
            false,
            false,
            false,
            'Social'
          )
          redirect = true
          return redirect
        }
      }
      return redirect
    },

    /**
     * apply automation notification check automation type redirect
     * @param notification
     * @returns {null}
     */
    applyAutomationNotification(notification) {
      this.$store.dispatch('resetPlansRequestFilters')
      let type = notification.automation.type
      if (notification.automation.article) type = 'article_automation'
      else if (notification.automation.video) type = 'video_automation'
      else if (notification.automation.rss) type = 'rss_automation'

      if (this.$route.name === 'list_plans') {
        const stateObject = this
        this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, true)
        this.$router.push({
          query: {
            ...this.$route.query,
            type: type,
            automations: notification.automation._id,
          },
        })
        setTimeout(function () {
          stateObject.platformFilterChange('Blog', 'All')
          setTimeout(function () {
            stateObject.$store.dispatch('refetchPlans', stateObject.$route)
          }, 200)
        }, 200)
      } else {
        this.$router.push({
          name: 'planner_list_v2',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: { type: type, automations: notification.automation._id },
        })
      }
      return null
    },

    /**
     * apply plan notification
     * if notification contain plan_id than redirect on planner with plan_id query parameter
     * else in case of bulk ids redirect without query parameters
     * @param notification
     * @returns {null}
     */
    applyPlanNotification(notification) {
      console.debug('method:applyPlanNotification', notification)
      this.$store.dispatch('resetPlansRequestFilters')

      // approval notification
      if (notification.source === 'plan_approval') {
        if (this.$route.name === 'feed_view') {
          const stateObject = this
          this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, true)
          setTimeout(function () {
            stateObject.platformFilterChange('Blog', 'All')
          }, 200)

          // if single plan approve / reject notification
          if (notification.plan_id) {
            this.$router.push({ query: { plan_ids: notification.plan_id } })
            return false
          }

          // if queue or repeat posts approve / reject notification
          if (notification.reference_id) {
            this.$router.push({
              query: { reference_id: notification.reference_id },
            })
            return false
          }

          // if bulk plan posts approve / reject notification
          if (notification.bulk_id) {
            this.$router.push({ query: { bulk_id: notification.bulk_id } })
            return false
          }

          setTimeout(function () {
            stateObject.$store.dispatch('refetchPlans', stateObject.$route)
          }, 200)

          return false
        }

        // if single plan approve / reject notification
        if (notification.plan_id) {
          return this.$router.push({
            name: 'planner_feed_v2',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            query: { plan_ids: notification.plan_id },
          })
        }

        // if queue or repeat posts approve / reject notification
        if (notification.reference_id) {
          return this.$router.push({
            name: 'planner_feed_v2',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            query: { reference_id: notification.reference_id },
          })
        }

        // if bulk plan posts approve / reject notification
        if (notification.bulk_id) {
          return this.$router.push({
            name: 'planner_feed_v2',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            query: { bulk_id: notification.bulk_id },
          })
        }

        this.$router.push({
          name: 'planner_feed_v2',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
        })
        return false
      }
      let specificPlan = null
      if (notification.plan_id) specificPlan = notification.plan_id
      if (notification.bulk_plan_ids)
        specificPlan = notification.bulk_plan_ids.join()
      if (notification.type === 'missed_plans')
        this.$store.commit(planner.SET_CHECK_ALL_STATUSES, false)
      if (this.$route.name === 'planner_list_v2') {
        const stateObject = this
        this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, true)
        setTimeout(function () {
          stateObject.platformFilterChange('Blog', 'All')
        }, 200)

        if (notification.type === 'missed_plans') {
          if (
            notification.plan_ids &&
            notification.plan_ids.length > 1 &&
            notification.plan_ids.length < 21
          ) {
            this.$router.push({
              query: { plan_ids: notification.plan_ids.join(',') },
            })
            return false
          }
          this.$router.push({ query: { statuses: 'missed_review' } })
          return false
        }

        if (specificPlan) {
          this.$router.push({ query: { plan_ids: specificPlan } })
          return false
        }

        setTimeout(function () {
          stateObject.$store.dispatch('refetchPlans', stateObject.$route)
        }, 200)

        return false
      }
      if (specificPlan) {
        this.$router.push({
          name: 'planner_list_v2',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: { plan_ids: specificPlan },
        })
        return false
      }
      if (notification.type === 'missed_plans') {
        if (
          notification.plan_ids &&
          notification.plan_ids.length > 1 &&
          notification.plan_ids.length < 21
        ) {
          this.$router.push({
            query: { plan_ids: notification.plan_ids.join(',') },
          })
          return false
        }
        this.$router.push({
          name: 'planner_list_v2',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          query: { statuses: 'missed_review' },
        })
        return false
      }
      this.$router.push({
        name: 'planner_list_v2',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
      return false
    },

    /**
     * relevant action to be performed after a user clicks on the notification
     * @param notification
     * @returns {null}
     */
    onClickNotification(notification) {
      console.debug('Method::onClickNotification', notification)
      // eslint-disable-next-line no-undef
      $('.modal').modal('hide')
      this.$parent.toggleNotificationDropdown = false

      if (!notification.workspace_id)
        notification.workspace_id = this.getWorkspaces.activeWorkspace._id
      this.markNotificationRead(notification)
      // active workspace is different, select the active workspace.
      this.verifySameWorkspace(notification)

      if (notification.source && notification.source === 'workspace') {
        this.$router.push({ name: 'team' })
        return null
      }

      switch (notification.type) {
        case 'mention_in_comment':
        case 'new_comment':
          if (this.applyCommentNotification(notification)) return null
          break
        case 'new_task_assigned':
        case 'task_assigned_completed':
          if (this.applyTaskNotification(notification)) return null
          break
        case 'automation_queued':
          this.applyAutomationNotification(notification)
          return null
        case 'integration_expire':
          this.$router.push({ name: 'integrations' })
          return null
        case 'account_expired':
          this.$router.push({ name: notification.route })
          break
        case 'social_expire':
          this.$router.push({ name: 'social' })
          return null
        case 'blog_expire':
          this.$router.push({ name: 'blogs' })
          return null
        case 'opml_import':
          this.$router.push({ name: 'feeder_manage_sources' })
          return null
        case 'feedly_import':
          this.$router.push({ name: 'feeder_manage_sources' })
          return null
        case 'inbox':
          this.$router.push({
            name: 'inboxConversation',
            params: {
              workspace: this.getWorkspaces.activeWorkspace.slug,
              conversation: notification.element_id,
              filter: 'all',
            },
            query: {
              notification: true,
            },
          })
          return null
      }

      if (
        notification.plan_id ||
        notification.bulk_plan_ids ||
        notification.bulk_id ||
        notification.reference_id ||
        notification.type === 'missed_plans'
      ) {
        this.applyPlanNotification(notification)
        return null
      }

      this.SET_CURRENT_NOTIFICATIONS({})
    },

    /**
     * To display notification in browser or on dashboard
     * @param data The notification detail received from broadcast
     */
    dashboardNotification(data) {
      if (data.dashboard) {
        this.SET_CURRENT_NOTIFICATIONS(JSON.parse(JSON.stringify(data)))
        if (data.url) {
          // this.alertMessage(`<p>` + data.description + `. <a href="` + data.url + `">Accept Invitation</a></p>`, 'info')
          this.alertMessage(
            '<p>' + parseDescriptionHtml(data.description, 320, false) + '</p>',
            'info'
          )
        } else {
          this.alertMessage(
            '<p>' +
              parseDescriptionHtml(data.description, 320, false) +
              '. <a onclick="notificationActionPerform()" href="#">View</a></p>',
            'info'
          )
        }
      }
      if (data.browser) this.displayBrowserNotifications(data)
      this.addToNotifications(data)
    },

    onlyIncrementOrBrowserNotification(data) {
      if (data.dashboard)
        this.SET_CURRENT_NOTIFICATIONS(JSON.parse(JSON.stringify(data)))
      if (data.browser) this.displayBrowserNotifications(data)
      this.addToNotifications(data)
    },
  },
}

export { notification }
