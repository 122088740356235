var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.id,"hide-header":"","no-close-on-backdrop":"","modal-class":"saved_replies_modal","no-close-on-esc":"","hide-footer":""},on:{"show":_vm.shouldShowReplyListing}},[_c('div',{staticClass:"modal_head d-flex align-items-center top-bar"},[(!_vm.showListing)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Back'),expression:"'Back'",modifiers:{"bottom-center":true}}],staticClass:"close ml-0 button-custom w-8",staticStyle:{"padding":"0"},attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.shouldShowReplyListing}},[_c('i',{staticClass:"far fa-angle-left"})]):_vm._e(),_c('p',{staticClass:"header-custom"},[_vm._v(" "+_vm._s(!_vm.showListing ? _vm.editReply ? 'Update Reply' : 'Create New Reply' : 'Saved Replies'))]),(_vm.showListing)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-center",value:('Close'),expression:"'Close'",modifiers:{"bottom-center":true}}],staticClass:"close button-custom",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v(" × ")]):_vm._e(),(
        !_vm.showListing &&
        _vm.editPayload !== null &&
        _vm.editPayload.user_details !== null
      )?_c('div',{staticClass:"d-flex user-details user-details-custom"},[_c('div',{staticClass:"text"},[_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v("Updated "+_vm._s(_vm.commonMethods.timesAgo(_vm.editPayload.updated_time)))]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("by "),_c('b',[_vm._v(_vm._s(_vm.editPayload.user_details.full_name))])])]),_c('div',{staticClass:"img",staticStyle:{"display":"inline-block","margin-left":"0.625rem"}},[(
            typeof _vm.editPayload.user_details.image !== 'undefined' &&
            _vm.editPayload.user_details.image !== null &&
            _vm.editPayload.user_details.image
          )?_c('img',{attrs:{"src":_vm.editPayload.user_details.image,"alt":""}}):_c('img',{attrs:{"src":'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',"alt":""}})])]):_vm._e()]),(_vm.showListing)?_c('SavedReplyListing',{attrs:{"id":_vm.id,"active-inbox-detail":_vm.activeInboxDetail,"apply-saved-reply":_vm.applySavedReply,"should-show-create-new":_vm.shouldShowCreateNew}}):_c('SavedReplyCreateNew',{attrs:{"active-inbox-detail":_vm.activeInboxDetail,"edit-reply":_vm.editReply,"edit-payload":_vm.editPayload,"should-show-reply-listing":_vm.shouldShowReplyListing}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }