<template>
  <div
    id="analyticSocialPreview"
    class="modal fade normal_modal analyticSocialPreview analytic-post-preview"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Post Preview</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form clear grid-flex" style="padding: 1rem 1.4rem;">
          <div class="preview_left col-9">
            <div class="post-preview-header">
              <h2 class="title">Post</h2>
              <a
                class="btn"
                :href="getPreviewLink(social_network, post)"
                target="_blank"
              >
                <i class="far fa-external-link-square-alt"></i>
              </a>
            </div>

            <div class="social_preview_no_tab analytic_social_preview">
              <!--<facebook-preview></facebook-preview>-->
              <div
                v-if="social_network === 'facebook'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img
                        v-if="post.page_id"
                        :src="
                          'http://graph.facebook.com/' +
                          post.page_id +
                          '/picture?type=square'
                        "
                        alt=""
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <div class="text_block">
                      <p class="text head_text"
                        >{{ getPostName('facebook', post)
                        }}<span class="ml-1"
                          >{{ getPostUsername('facebook', post) }} <br />{{
                            getPostDate('facebook', post)
                          }}</span
                        ></p
                      >
                    </div>
                  </div>
                  <div class="text_block">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('facebook', post)">
                        <p class="desc">
                          <span
                            v-if="!readMoreActivated"
                            v-html="
                              isHashtag(isLink(post.message).slice(0, 250)) +
                              '...'
                            "
                          ></span>
                          <span
                            v-else
                            v-html="isHashtag(isLink(post.message))"
                          ></span>
                          <a
                            v-if="isHashtag(isLink(post.message)).length >= 250"
                            class="ml-2"
                            href="javascript:;"
                            @click.prevent="toggleReadMore"
                            >{{
                              readMoreActivated ? 'Read Less' : 'Read More'
                            }}</a
                          >
                        </p>
                      </template>
                    </div>
                    <div v-if="post.full_picture" class="preview_images"
                      >
                      <!-- Single image case -->
                      <div class="h-[500px] object-cover">
                        <img
                          :src="getResizedImageURL(post.full_picture, 570, 0)"
                          alt=""
                          class=""
                          @error="
                            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                          "
                        />
                      </div>
                      </div>
                  </div>
                </div>
              </div>
              <div
                v-if="social_network === 'instagram'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img
                        v-if="post.profile_picture_url"
                        :src="post.profile_picture_url"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <div class="text_block">
                      <p class="text head_text"
                        >{{ post.name }}
                        <span class="ml-1"
                          >@{{ post.username }} <br />{{
                            formatDate(post.post_created_at)
                          }}</span
                        ></p
                      >
                    </div>
                  </div>

                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('instagram', post)">
                        <p class="desc">
                          <span
                            v-if="!readMoreActivated"
                            v-html="
                              isHashtag(isLink(post.caption).slice(0, 250)) +
                              '...'
                            "
                          ></span>
                          <span
                            v-else
                            v-html="isHashtag(isLink(post.caption))"
                          ></span>
                          <a
                            v-if="isHashtag(isLink(post.caption)).length >= 250"
                            class="ml-2"
                            href="javascript:;"
                            @click.prevent="toggleReadMore"
                            >{{
                              readMoreActivated ? 'Read Less' : 'Read More'
                            }}</a
                          >
                        </p>
                      </template>
                    </div>
                    <div v-if="post.media_url" class="preview_images">
                      <video
                        v-if="post.media_type === 'VIDEO'"
                        class="video_preview_image"
                        controls
                      >
                        <source :src="post.media_url" />
                        <source :src="post.media_url" type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                      <img
                        v-else
                        :src="getResizedImageURL(post.media_url, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- preview for the twitter -->
              <div
                v-if="social_network === 'twitter'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img :src="post.profile_image_url" alt="" />
                    </div>
                    <div class="text_block">
                      <p class="text head_text"
                        >{{ post.name
                        }}<span class="tag">@{{ post.screen_name }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('twitter', post)">
                        <p
                          class="desc"
                          v-html="isHashtag(isLink(post.tweet_text))"
                        ></p>
                      </template>
                    </div>
                    <div v-if="post.media_url" class="preview_images"
                      ><img
                        :src="getResizedImageURL(post.media_url, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>

              <!-- preview for the linkedin -->
              <div
                v-if="social_network === 'linkedin'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img
                        v-if="post.linkedin_id"
                        :src="getActiveCompanyPage(post.linkedin_id)"
                        alt=""
                        @error="
                          $event.target.src = fallbackImagePreview(getPostName('linkedin', post)
                              ? getPostName('linkedin', post)[0]
                              : '#')
                        "
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <div class="text_block"
                      ><p class="text head_text"
                        >{{ getPostName('linkedin', post) }}
                      </p>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('linkedin', post)"
                        ><p
                          class="desc"
                          v-html="isHashtag(isLink(post.title))"
                        ></p>
                      </template>
                    </div>
                    <div v-if="post.image" class="preview_images">
                      <img
                        :src="getResizedImageURL(post.image, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>

              <!-- preview for the pinterest -->
              <div
                v-if="social_network === 'pinterest'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="picture_block">
                    <img
                      v-if="post.board_id"
                      :src="getActiveBoardImage(post.board_id)"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../../../assets/img/profile_default.svg"
                      alt=""
                    />
                  </div>
                  <div class="text_block"
                    ><p class="text head_text">{{ post.board_name }} </p>
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('pinterest', post)">
                        <p
                          class="desc"
                          v-html="isHashtag(isLink(post.pin_text))"
                        ></p>
                      </template>
                    </div>
                    <div v-if="post.image" class="preview_images"
                      ><img
                        :src="getResizedImageURL(post.image, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="preview_right col-3">
            <div class="post-preview-header">
              <h2 class="title">Statistics</h2>
            </div>
            <div class="right_content clear grid-flex">
              <div
                class="analytic_p_box col-12"
                v-for="(stats, key) in engagement_list"
                :key="key"
              >
                <div class="box_inner d-flex align-items-center">
                  <div
                    class="icon d-flex align-items-center justify-content-center"
                  >
                    <!--                                        <i :class="stats[2]"></i>-->
                    <img :src="require('../../assets/imgs/post/' + stats[2])" />
                  </div>
                  <div class="content">
                    <p>{{ stats[0] }}</p>
                    <h3>{{ $filters.numberToCommas(stats[1]) }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import analyticsUtilsMixin from './analyticsUtilsMixin'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {},
  mixins: [analyticsUtilsMixin],

  data () {
    return {
      post: {},
      engagement_list: [],
      social_network: 'facebook',
      readMoreActivated: false
    }
  },

  created () {
    EventBus.$on('facebook-analytics-preview', (text) => {
      this.social_network = 'facebook'
      this.post = text

      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        [
          'Impressions',
          this.post.post_impressions || 0,
          'controlling-card.svg'
        ],
        ['Post Clicks', this.post.post_clicks || 0, 'click-like.svg'],
        [
          'Reactions',
          (this.post.total || 0) + (this.post.shares || 0),
          'smiling.svg'
        ],
        ['Comments', this.post.comments || 0, 'comment.svg']
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('instagram-posts-analytics-preview', (text) => {
      this.social_network = 'instagram'
      this.post = text

      this.engagement_list = [
        ['Total Engagement', this.post.engagement || 0, 'statistics.svg'],
        ['Saves', this.post.saved || 0, 'books.svg'],
        ['Reactions', this.post.like_count || 0, 'smiling.svg'],
        ['Comments', this.post.comments_count || 0, 'comment.svg']
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('instagram-stories-analytics-preview', (text) => {
      this.social_network = 'instagram'
      this.post = text

      this.engagement_list = [
        ['Impressions', this.post.impressions || 0, 'statistics.svg'],
        ['Reach', this.post.reach || 0, 'smiling.svg'],
        ['Exits', this.post.exits || 0, 'books.svg'],
        ['Replies', this.post.replies || 0, 'comment.svg']
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('twitter-analytics-preview', (text) => {
      this.social_network = 'twitter'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Retweets', this.post.retweet_count || 0, 'comment.svg'],
        ['Favorites', this.post.favorite_count || 0, 'click-like.svg']
      ]
      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })
    EventBus.$on('linkedin-analytics-preview', (text) => {
      this.social_network = 'linkedin'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Reactions', this.post.favorites || 0, 'smiling.svg'],
        ['Comments', this.post.comments || 0, 'comment.svg']
      ]
      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })
    EventBus.$on('pinterest-analytics-preview', (text) => {
      console.log(text)
      this.social_network = 'pinterest'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Repins Count', this.post.repins_count || 0, 'books.svg'],
        ['Pins Click', this.post.pin_click || 0, 'click-like.svg']
      ]
      $('#analyticSocialPreview').modal('show')
    })
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  beforeDestroy () {
    EventBus.$off('twitter-analytics-preview', () => {})
    EventBus.$off('facebook-analytics-preview', () => {})
    EventBus.$off('linkedin-analytics-preview', () => {})
    EventBus.$off('pinterest-analytics-preview', () => {})
    EventBus.$off('pinterest-analytics-preview', () => {})
  },

  methods: {
    toggleReadMore () {
      this.readMoreActivated = !this.readMoreActivated
    },
    helloWorld () {
      alert('HellowWorld')
    },
    formatDate (date) {
      return moment(date).format('llll')
    }
  }
}
</script>
