<template>
  <div>
    <template v-if="loader">
      <clip-loader
        class="spinner mt-4 mb-3"
        :color="'#6d76ff'"
        :size="'20px'"
      ></clip-loader>
    </template>
    <template v-else>
      <div v-if="!(blogs.length > 0)" class="text-center px-3 py-3">
        You have not connected any any blog yet.
      </div>
      <b-table
        v-else
        class="onboarding-team__table mt-1"
        thead-tr-class="onboarding-team__table-header"
        tbody-tr-class="onboarding-team__table-body"
        table-class="text-left"
        responsive
        borderless
        :items="blogs"
        :fields="blogFields"
      >
        <template slot="cell(actions)" slot-scope="data">
          <button
            @click="removeWSAccount(data.item)"
            class="btn onboarding-team__trash"
            ><i class="fas fa-trash"></i
          ></button>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
export default {
  name: 'BlogTab',
  props: ['workspace_id'],
  data () {
    return {
      blogFields: [
        { key: 'blog' },
        { key: 'platform' },
        { key: 'added_by' },
        { key: 'actions', label: 'Action' }
      ],

      blogs: [],
      loader: false
    }
  },
  mounted () {
    this.fetchCurrentWSBlogs()
  },
  methods: {
    ...mapActions(['fetchWSBlogs', 'removeSocialIntegration']),
    async fetchCurrentWSBlogs () {
      this.loader = true
      const blogs = await this.fetchWSBlogs({ workspace_id: this.workspace_id })
      const dataBlogContainer = []
      for (let x = 0; x < blogs.length; x++) {
        const container = blogs[x]
        const items = container.items
        for (let y = 0; y < items.length; y++) {
          const item = items[y]
          const payload = {
            blog: item.platform_url,
            platform: container.type,
            added_by: item.added_by,
            platform_id: item.platform_identifier
          }
          dataBlogContainer.push(payload)
        }
      }
      this.blogs = dataBlogContainer

      this.loader = false
    },
    async removeWSAccount (account) {
      console.debug('Method::removeAccount - Blog', account)
      const payload = {
        platform: account.platform_id,
        type: account.platform.toLowerCase(),
        workspace_id: this.workspace_id,
        context: this
      }
      const context = this
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to remove a blog account.',
          {
            title: 'Remove Blog Account',
            ...swalAttributes()
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await context.removeSocialIntegration(payload)
            console.log('I AM A FUCKING RESP .. ', resp)
            if (resp.status) {
              context.fetchCurrentWSBlogs()
            }
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    }
  }
}
</script>
