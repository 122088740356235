<template>
  <div class="bg-white w-full flex items-center justify-between px-7 py-5">
    <div>
      <p class="text-base font-weight-500">
        {{
          $route.name === 'instagram_competitor_overview_v3'
            ? 'Instagram Competitor Analytics'
            : title
        }}
      </p>
    </div>
    <div
      v-if="$route.name !== 'instagram_competitor_overview_v3'"
      class="flex gap-3"
    >
      <div class="w-56">
        <CstInputFields
          :date-options="dateOptions"
          class="planner-date-picker cursor-pointer"
          placeholder="Select specific date range"
          size="small"
          type="date"
          @change="handleDateFilterChange"
        />
      </div>
      <div
        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 rounded-lg py-2.5 px-3 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"
        @click="$bvModal.show('manage-competitors-modal')"
        ><p>Manage Competitors</p>
      </div>
      <div
        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 rounded-lg pl-4 pr-1.5 py-2.5 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center gap-2"
      >
        <p>Save</p>
        <i class="fa fa-angle-down px-2.5 rounded-lg text-lg !bg-[#549AEE]"></i>
      </div>
      <div
        class="border text-gray-900 bg-cs-white hover:bg-gray-50 bg-opacity-90 hover:bg-opacity-100 cursor-pointer h-10 rounded-lg py-2.5 px-3 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"
      >
        <img
          src="@src/assets/img/icons/analytic/graphs_filter.svg"
          alt=""
          class="text-[#9099FF]"
        />
      </div>
    </div>

    <div v-if="this.$route.name === 'instagram_competitor_overview_v3'">
      <div
        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 rounded-lg py-2.5 px-3 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"
        @click="$emit('show-manage-competitors-modal')"
        ><p> + Create New</p>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent, reactive, computed, onMounted } from 'vue'
import moment from 'moment'

// components
import CstInputFields from '@ui/Input/CstInputFields'
import CstButton from '@ui/Button/CstButton.vue'

export default defineComponent({
  name: 'Header',
  components: {
    CstButton,
    CstInputFields,
  },
  props: {
    title: {
      type: String,
      default: 'Instagram Competitor Analytics',
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      emit('default-date-range', state.dateRange, 'default')
    })

    const dateOptions = reactive({
      defaultValue: null,
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const date = moment().toDate()
            // return a Date
            return [date, date]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const date = moment().subtract(1, 'days').toDate()
            return [date, date]
          },
        },
        {
          text: 'Last Week',
          onClick() {
            const start = moment().subtract(1, 'week').startOf('week').toDate()
            const end = moment().subtract(1, 'week').endOf('week').toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const start = moment()
              .subtract(1, 'month')
              .startOf('month')
              .toDate()
            const end = moment().subtract(1, 'month').endOf('month').toDate()

            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const start = moment().startOf('month').toDate()
            const end = moment().endOf('month').toDate()

            return [start, end]
          },
        },
        {
          text: 'This Year',
          onClick() {
            const start = moment().startOf('year').toDate()
            const end = moment().endOf('year').toDate()

            return [start, end]
          },
        },
      ],
      disabledDate: (date) => date > new Date(),
      format: 'DD MMM YY',
    })

    const state = reactive({
      title: computed(() => props.title),
      showCompetitorsModal: false,
      dateRange: `${moment().startOf('month').format('YYYY-MM-DD')} - ${moment()
        .endOf('month')
        .format('YYYY-MM-DD')}`,
    })

    const handleDateFilterChange = (date) => {
      if (date[0] && date[1]) {
        const startDate = moment(date[0]).format('YYYY-MM-DD')
        const endDate = moment(date[1]).format('YYYY-MM-DD')
        state.dateRange = `${startDate} - ${endDate}`
        emit('get-date-range', state.dateRange)
      } else {
        emit('default-date-range', state.dateRange, 'default')
      }
    }

    return {
      dateOptions,
      state,

      handleDateFilterChange,
    }
  },
})
</script>

<style scoped></style>
