<script>
import { mapGetters } from 'vuex'
import { planner } from '../../store/mutation-types'
export default {
  computed: {
    ...mapGetters(['getPlans', 'getPlansCount', 'getActivePlannerFilters']),
    getRepeatPostCount () {
      return this.getPlansCount.repeat
    },
    getArticleAutomationPostCount () {
      return this.getPlansCount.article_automations
    },
    getVideoAutomationPostCount () {
      return this.getPlansCount.video_automations
    },
    getEvergreenAutomationPostCount () {
      return this.getPlansCount.evergreen_automations
    },
    getRssAutomationPostCount () {
      return this.getPlansCount.rss_automations
    },
    getCsvAutomationPostCount () {
      return this.getPlansCount.csv_automations
    },
    getQueuedPostCount () {
      return this.getPlansCount.queued
    }
  },
  methods: {
    changePlanType (type = '') {
      console.debug('Method::changePlanType')
      if (this.$route.query.filter_id) {
        this.getActivePlannerFilters.type = ''
      }

      const query = Object.assign({}, this.$route.query)
      delete query.automations
      if (type === '') {
        delete query.type
        this.$router.push({ query: query })
      } else this.$router.push({ query: { ...query, type: type } })

      this.$store.commit(planner.SET_AUTOMATIONS_SELECT_ALL_STATUS, false)
      this.$store.dispatch('refetchPlans', this.$route)
    }
  }
}
</script>

<template>
  <ul class="inner">
    <!-- <li class="list_item_li">
            <div class="radio_input_image">
                <input id="plans_all_posts" :checked="$parent.getFiltersType === ''" value="" type="radio" @change="changePlanType()">
                <label for="plans_all_posts" class="radio_right">
                    All Posts
                </label>
            </div>
        </li> -->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="plans_all_posts"
          :checked="$parent.getFiltersType === ''"
          value=""
          type="radio"
          @change="changePlanType()"
        />
        <div for="plans_all_posts">
          All Posts
          <span class="check_n"></span>
        </div>
      </label>
    </li>
    <!--<li class="list_item_li">
            <div class="radio_input_image">
                <input id="plans_repeat_posts" :checked="$parent.getFiltersType === 'repeat'" value="repeat" type="radio" @change="changePlanType('repeat')">
                <label for="plans_repeat_posts" class="radio_right">
                    Repeat posts
                    <span class="checkbox_counter" v-if="getRepeatPostCount">{{getRepeatPostCount}}</span>
                </label>
            </div>
        </li>-->

    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="plans_repeat_posts"
          :checked="$parent.getFiltersType === 'repeat'"
          value="repeat"
          type="radio"
          @change="changePlanType('repeat')"
        />
        <div for="plans_repeat_posts">
          Repeat posts
          <span class="checkbox_counter" v-if="getRepeatPostCount">{{
            getRepeatPostCount
          }}</span>
          <span class="check_n"></span>
        </div>
      </label>
    </li>

    <!-- <li class="list_item_li">
            <div class="radio_input_image">
                <input id="article_automations_posts"  :checked="$parent.getFiltersType === 'article_automation'" value="article_automation" type="radio" @change="changePlanType('article_automation')">
                <label for="article_automations_posts" class="radio_right">
                    Article Automations Posts<span class="checkbox_counter" v-if="getArticleAutomationPostCount">{{getArticleAutomationPostCount}}</span>
                </label>
            </div>
        </li> -->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="article_automations_posts"
          :checked="$parent.getFiltersType === 'article_automation'"
          value="article_automation"
          type="radio"
          @change="changePlanType('article_automation')"
        />
        <div for="article_automations_posts">
          Article Automations Posts
          <span class="checkbox_counter" v-if="getArticleAutomationPostCount">{{
            getArticleAutomationPostCount
          }}</span>
          <span class="check_n"></span>
        </div>
      </label>
    </li>

    <!--<li class="list_item_li">
            <div class="radio_input_image">
                <input id="video_automations_posts"  :checked="$parent.getFiltersType === 'video_automation'" value="video_automation" type="radio" @change="changePlanType('video_automation')">
                <label for="video_automations_posts" class="radio_right">
                    Video Automations Posts<span class="checkbox_counter" v-if="getVideoAutomationPostCount">{{getVideoAutomationPostCount}}</span>
                </label>
            </div>
        </li>-->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="video_automations_posts"
          :checked="$parent.getFiltersType === 'video_automation'"
          value="video_automation"
          type="radio"
          @change="changePlanType('video_automation')"
        />
        <div for="video_automations_posts">
          Video Automations Posts<span
            class="checkbox_counter"
            v-if="getVideoAutomationPostCount"
            >{{ getVideoAutomationPostCount }}</span
          >
          <span class="check_n"></span>
        </div>
      </label>
    </li>
    <!--<li class="list_item_li">
            <div class="radio_input_image">
                <input id="evergreen_automations_posts"   :checked="$parent.getFiltersType === 'evergreen_automation'" value="evergreen_automation" type="radio" @change="changePlanType('evergreen_automation')">
                <label for="evergreen_automations_posts" class="radio_right">
                    Evergreen Automations Posts<span class="checkbox_counter" v-if="getEvergreenAutomationPostCount">{{getEvergreenAutomationPostCount}}</span>
                </label>
            </div>
        </li>-->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="evergreen_automations_posts"
          :checked="$parent.getFiltersType === 'evergreen_automation'"
          value="evergreen_automation"
          type="radio"
          @change="changePlanType('evergreen_automation')"
        />
        <div for="evergreen_automations_posts">
          Evergreen Automations Posts<span
            class="checkbox_counter"
            v-if="getEvergreenAutomationPostCount"
            >{{ getEvergreenAutomationPostCount }}</span
          >
          <span class="check_n"></span>
        </div>
      </label>
    </li>
    <!-- <li class="list_item_li">
            <div class="radio_input_image">
                <input id="rss_automations_posts"  :checked="$parent.getFiltersType === 'rss_automation'" value="rss_automation" type="radio" @change="changePlanType('rss_automation')">
                <label for="rss_automations_posts" class="radio_right">
                    RSS Automations Posts<span class="checkbox_counter" v-if="getRssAutomationPostCount">{{getRssAutomationPostCount}}</span>
                </label>
            </div>
        </li> -->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="rss_automations_posts"
          :checked="$parent.getFiltersType === 'rss_automation'"
          value="rss_automation"
          type="radio"
          @change="changePlanType('rss_automation')"
        />
        <div for="rss_automations_posts">
          RSS Automations Posts<span
            class="checkbox_counter"
            v-if="getRssAutomationPostCount"
            >{{ getRssAutomationPostCount }}</span
          >
          <span class="check_n"></span>
        </div>
      </label>
    </li>
    <!-- <li class="list_item_li">
            <div class="radio_input_image">
                <input id="csv_automations_posts"  :checked="$parent.getFiltersType === 'csv_automation'" value="csv_automation" type="radio" @change="changePlanType('csv_automation')">
                <label for="csv_automations_posts" class="radio_right">
                    CSV Automations Posts<span class="checkbox_counter" v-if="getCsvAutomationPostCount">{{getCsvAutomationPostCount}}</span>
                </label>
            </div>
        </li> -->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="csv_automations_posts"
          :checked="$parent.getFiltersType === 'csv_automation'"
          value="csv_automation"
          type="radio"
          @change="changePlanType('csv_automation')"
        />
        <div for="csv_automations_posts">
          CSV Automations Posts<span
            class="checkbox_counter"
            v-if="getCsvAutomationPostCount"
            >{{ getCsvAutomationPostCount }}</span
          >
          <span class="check_n"></span>
        </div>
      </label>
    </li>
    <!--  <li class="list_item_li">
            <div class="radio_input_image">
                <input id="queued_posts"  :checked="$parent.getFiltersType === 'queued'" value="queued" type="radio" @change="changePlanType('queued')">
                <label for="queued_posts" class="radio_right">
                    Queued Posts<span class="checkbox_counter" v-if="getQueuedPostCount">{{getQueuedPostCount}}</span>
                </label>
            </div>
        </li> -->
    <li class="list_item_li">
      <label class="radio_container_n">
        <input
          id="queued_posts"
          :checked="$parent.getFiltersType === 'queued'"
          value="queued"
          type="radio"
          @change="changePlanType('queued')"
        />
        <div for="queued_posts">
          Queued Posts<span
            class="checkbox_counter"
            v-if="getQueuedPostCount"
            >{{ getQueuedPostCount }}</span
          >
          <span class="check_n"></span>
        </div>
      </label>
    </li>
  </ul>
</template>
<style lang="less" scoped>
.list_item_li {
  margin-bottom: 0px;
}
</style>
