<template>
  <div>
    <InstagramReelPreview
      v-if="instagramPostType !== 'feed' && detail.video && detail.video.link"
      :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
      :account-name="account.name"
      :account-image="account.image"
      :video="detail.video"
      :description="getDescription"
      :toggle-preview="togglePreview"
      :thumbnail="getVideoThumbnail"
    />
    <template v-else>
      <div class="w-full cst-drop-shadow bg-white rounded-xl">
        <div class="flex items-center px-3 py-3 font-bold">
          <img
            class="rounded-full object-cover border border-solid border-black"
            width="40"
            height="40"
            :src="account.image"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
          />
          <div class="px-2 font-bold">
            {{ account.name }}
            <span
              v-if="isLocationAdded"
              class="block text-gray-800 font-normal text-xs"
            >
              {{ isLocationAdded }}
            </span>
          </div>
          <div
            class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
          >
            <img
              src="@assets/img/integration/instagram-icon.svg"
              alt=""
              class="w-5"
            />
          </div>
        </div>
        <div class="w-full h-full">
          <b-carousel
            v-if="detail.image && detail.image.length > 0"
            ref="carousel"
            class="instagram-preview-carousel"
            :interval="0"
            :controls="detail.image.length > 1"
            :indicators="detail.image.length > 1"
            @sliding-end="onSlideChange"
          >
            <b-carousel-slide
              v-for="(image, index) in detail.image"
              :key="index"
            >
              <template v-slot:img>
                <div class="max-h-[470px] w-full block relative">
                  <div class="block overflow-hidden relative pt-[100%]">
                    <img
                      slot="img"
                      class="w-full h-full absolute top-0 left-0 object-cover"
                      :src="image"
                      alt=""
                      @click="handleImagePreview(index)"
                      @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        }
                      "
                    />
                  </div>
                </div>
                <template v-if="userTags[image] && userTags[image].length > 0">
                  <div
                    v-for="(mention, key) in userTags[image]"
                    :key="key"
                    class="absolute z-20"
                    :style="`top: calc((${mention.y} * var(--image-height))); left: calc((${mention.x} * var(--image-width)))`"
                  >
                    <div
                      class="absolute shadow left-1/2 mt-2.5 transform -translate-x-1/2 rounded px-4 right-0 text-base w-max h-max"
                      :class="{
                        'bg-black-100 text-white py-1 ': mention.username,
                        'bg-gray-50 text-black-100 py-3': !mention.username,
                      }"
                    >
                      <div
                        class="absolute left-0 w-full bottom-full flex justify-center items-center pt-1 h-1"
                      >
                        <div
                          class="h-2 w-2 bg-black-100 transform rotate-45"
                        ></div>
                      </div>
                      <template v-if="mention.username">
                        {{ mention.username }}
                      </template>
                    </div>
                  </div>
                </template>
              </template>

              <div
                v-if="userTags[image] && userTags[image].length > 0"
                class="h-8 w-8 rounded-full bg-black-800 hover:bg-black-600 flex justify-center items-center absolute bottom-0 -left-3/20 shadow cursor-pointer"
                @click="handleUserTags"
              >
                <svg
                  aria-label="Tags"
                  class="_ab6-"
                  color="#ffffff"
                  fill="#ffffff"
                  height="12"
                  role="img"
                  viewBox="0 0 24 24"
                  width="12"
                >
                  <path
                    d="M21.334 23H2.666a1 1 0 01-1-1v-1.354a6.279 6.279 0 016.272-6.272h8.124a6.279 6.279 0 016.271 6.271V22a1 1 0 01-1 1zM12 13.269a6 6 0 116-6 6.007 6.007 0 01-6 6z"
                  ></path>
                </svg>
              </div>
            </b-carousel-slide>
          </b-carousel>
          <div
            v-else-if="detail.video && detail.video.thumbnail"
            class="relative h-full text-white"
          >
            <img
              class="w-full"
              style="max-height: 500px"
              :src="getVideoThumbnail"
              alt="thumbnail"
            />
            <i
              class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid"
              style="transform: translate(-50%, -50%)"
              @click.prevent="displayFile('video', detail.video.link, 0)"
            ></i>
          </div>
          <img
            v-else
            class="w-full"
            src="@src/assets/img/no_data_images/no_media_found.svg"
            alt=""
          />
        </div>
        <div
          v-if="!hideFooter"
          class="flex items-center px-3 py-2 reaction-icons space-x-5 text-xl"
        >
          <i class="far fa-heart"></i>
          <i class="far fa-comment"></i>
          <i class="far fa-paper-plane"></i>
          <div class="ml-auto"><i class="far fa-bookmark"></i></div>
        </div>
        <div
          v-if="getDescription"
          class="items-center px-3 py-1 pb-3"
          :class="{ 'pt-3': hideFooter }"
        >
          <span class="font-bold">{{ account.name }}</span>
          <span
            class="px-2 whitespace-pre-wrap leading-4"
            v-html="getDescription"
          ></span>
          <span
            v-if="getDescription.length > charLimit && loadMore"
            class="text-blue-300 cursor-pointer"
            @click="changeLoadMore(false)"
          >
            Load More</span
          >
          <span
            v-if="getDescription.length > charLimit && !loadMore"
            class="text-blue-300 cursor-pointer"
            @click="changeLoadMore(true)"
          >
            Show Less</span
          >
        </div>
        <div
          v-if="comment"
          class="flex justify-center px-2 py-3"
          style="border-top: 1px solid #dee2e6"
        >
          <div>
            <img
              class="rounded-full object-cover border border-solid border-black"
              width="30"
              height="30"
              :src="account.image"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div class="ml-1 w-full rounded-lg px-2">
            <span class="font-bold">{{ account.name }} </span> {{ comment }}
          </div>
          <div class="my-auto">
            <i class="far fa-heart"></i>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InstagramReelPreview from '@src/modules/composer_v2/components/SocialPreviews/InstagramReelPreview'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'InstagramPreview',
  components: {
    InstagramReelPreview,
  },
  props: {
    commonBoxStatus: {
      type: Boolean,
      default: false,
      required: true,
    },
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    togglePreview: {
      type: Boolean,
      default: false,
    },
    userTags: {
      type: [Object, Array],
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      loadMore: true,
      charLimit: 500,
    }
  },
  computed: {
    isLocationAdded() {
      if (this.commonBoxStatus) return this.detail.location?.instagram?.name
      return this.detail.location?.name
    },
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg && msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },

    /**
     * Get the video thumbnail.
     * @returns {string}
     */
    getVideoThumbnail() {
      let thumbnail = this.detail.video.thumbnail
      // If the thumbnail url includes the /media_library/, then it is a custom thumbnail.
      // If the thumbnail url includes the ?offset= then it is 'choose from video' thumbnail.
      // If its custom thumbnail and not 'choose from video' then use the first thumbnail
      // from the suggestions as custom thumbnail is not supported in instagram.
      if (
        thumbnail.indexOf('/media_library/') > -1 &&
        thumbnail.indexOf('?offset=') === -1
      ) {
        thumbnail = this.detail.video.thumbnails_suggestions[0]
      }
      return thumbnail
    },
  },
  methods: {
    /**
     *  Method to toggle the user tags on the image
     * @param strategy {string} Can be: auto, show, hide
     */
    handleUserTags(strategy = 'auto') {
      const carousel = this.$refs.carousel.$el

      if (carousel) {
        let canShowtags = true

        switch (strategy) {
          case 'show':
            carousel.setAttribute('data-show-tags', 'true')
            break
          case 'hide':
            carousel.setAttribute('data-show-tags', 'false')
            canShowtags = false
            break
          default:
            if (
              carousel.getAttribute('data-show-tags') &&
              carousel.getAttribute('data-show-tags') === 'true'
            ) {
              carousel.setAttribute('data-show-tags', 'false')
              canShowtags = false
            } else {
              carousel.setAttribute('data-show-tags', 'true')
            }
            break
        }

        if (canShowtags) {
          carousel.style.setProperty(
            '--image-height',
            `${carousel.clientHeight}px`
          )
          carousel.style.setProperty(
            '--image-width',
            `${carousel.clientWidth}px`
          )
        } else {
          carousel.style.setProperty('--image-height', `0`)
          carousel.style.setProperty('--image-width', `0`)
        }
      }
    },
    onSlideChange(slideIndex) {
      console.log(slideIndex)
      this.handleUserTags('hide')
    },
    changeLoadMore(val) {
      this.loadMore = val
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type: type,
        media: media,
        index: index,
      })
      this.$bvModal.show('display-file-modal')
    },
    handleImagePreview(index) {
      if (this.type === 'feed_view')
        this.$emit('preview-images', {
          images: this.detail.image,
          index: index,
        })
    },
  },
}
</script>

<style scoped></style>
