<template>
  <b-modal
    id="mediaRemovalConfirmationDialog"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox socialConfirmationDialog"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div class="col-sm-12 alert_content">
        <h2>Are you sure?</h2>
        <p class="text-center"
          >In case of permanently deleting the media asset which is used in any
          of the scheduled posts, the post will fail to publish.</p
        >

        <div class="compose_warning_box text-center">
          <p style="color: #444343"
            >Note: Archived assets are shown in the Archive folder and will not
            affect the scheduled posts.</p
          >
        </div>
      </div>

      <div class="col-sm-12 footer_box">
        <button class="btn gray cancel_btn" @click="closeThisModal"
          >Cancel</button
        >
        <button
          class="btn gray_color_btn gray cancel_btn"
          @click="EventBus.$emit('archive-media')"
          >Archive</button
        >
        <button class="btn confirm_btn" @click="EventBus.$emit('delete-media')"
          >Delete Permanently
          <!--                    <clip-loader v-if="getPublishLoaders.processSavePublication"-->
          <!--                                 class="d-inline-block align-middle ml-1" :color="'#ffffff'"-->
          <!--                                 :size="'14px'"></clip-loader>-->
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";

export default {
  computed: {
    EventBus() {
      return EventBus
    }
  },
  methods: {
    closeThisModal() {
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
      // setTimeout(function () {
      //   $('body').addClass('modal-open')
      // }, 400)
    },
  },
}
</script>

<style lang="scss"></style>
