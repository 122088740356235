var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topics-left-pane",class:{ customization_active: _vm.getSidebarCustomization.customization }},[_c('div',{staticClass:"topics-left-pane__fixed_block"},[_c('div',{staticClass:"topics-left-pane__fixed_block__inner"},[_c('div',{staticClass:"topics-left-pane__fixed_block__inner__header_links"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'discover_topics_cover_stories' },"href":"javascript:;"}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-today_icon"}),_c('span',[_vm._v("Cover Stories")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'discover_topics_curated_trending',
                query: { topic: 'News' },
              }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-trending_icon"}),_c('span',[_vm._v("Trending News")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'discover_topics_curated_quotes' }}},[_c('i',{staticClass:"title_icon gray_dark_2 icon-Curated-Quotes"}),_c('span',[_vm._v("Curated Quotes")])])],1)])]),_c('hr'),_c('quick-topics-list'),(_vm.hasPermission('can_save_topic'))?_c('topics-list'):_vm._e(),_c('favorite-folders-list'),_c('hr'),_c('div',{staticClass:"topics-left-pane__fixed_block__inner__header_links topics-left-pane__fixed_block__inner__footer_links",class:{
          'topics-left-pane__fixed_block__inner__footer_links_customization':
            _vm.getSidebarCustomization.customization,
        }},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'discover_topics_archived' }}},[_c('i',{staticClass:"title_icon gray_dark_2 fab icon-Archive_icon"}),_c('span',[_vm._v("View Archived Posts")])])],1),_c('li',[_c('a',{class:{
                active:
                  _vm.getSidebarCustomization.customization &&
                  _vm.$route.name !== 'discover_topics_archived',
              },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.sidebarCustomization()}}},[_c('i',{staticClass:"title_icon gray_dark_2 fas fa-sliders-h"}),_c('span',{staticClass:"space_nowrap"},[_vm._v("Customize Sidebar ("+_vm._s(_vm.getCustomSidebar.my_topics.options.length + _vm.getCustomSidebar.quick_topics.options.length + _vm.getCustomSidebar.favorites.options.length)+"/"+_vm._s(_vm.getTopics.length + this.getMyCuratedTopicsList.length + this.getFolders.length)+")")])])])]),(_vm.getSidebarCustomization.customization)?_c('div',{class:{
            disable_click: _vm.getTopicDisabled,
            'topics-left-pane-with-customization':
              _vm.getSidebarCustomization.customization,
          }},[_c('div',{staticClass:"topics-left-pane-customization"},[_c('p',[_vm._v("Click on 'Done' when selection is complete")]),_c('button',{staticClass:"btn btn-studio-theme-space btn-size-medium",class:{ disabled: _vm.getSidebarCustomizationLoader.save },attrs:{"disabled":_vm.getSidebarCustomizationLoader.save},on:{"click":function($event){return _vm.saveCustomSidebar()}}},[_c('span',[_vm._v("Done")]),(_vm.getSidebarCustomizationLoader.save)?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'12px'}}):_vm._e()],1)])]):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }