<template>
  <div
    class="social_mention_dropdown social_hashtag_dropdown"
    :class="{
      move_left:
        getSocialSharingSelection.hashTagsSuggestions.dropdown.left > 530,
    }"
    :style="{
      left: getSocialSharingSelection.hashTagsSuggestions.dropdown.left + 'px',
      top: getSocialSharingSelection.hashTagsSuggestions.dropdown.top + 'px',
    }"
    v-if="
      getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
      (getPublishLoaders.fetchHashTagsSuggestions ||
        (getSocialSharingSelection.hashTagsSuggestions.data &&
          getSocialSharingSelection.hashTagsSuggestions.data.length)) &&
      type === getSocialSharingSelection.hashTagsSuggestions.box
    "
  >
    <ul class="inner" v-if="getPublishLoaders.fetchHashTagsSuggestions">
      <li class="no_text">
        <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
      </li>
    </ul>
    <template v-else>
      <h5 style="padding: 1em">
        <b>Suggested Hashtags</b>
      </h5>
      <ul class="inner" @keyup="nextItem" @keydown="selectItem">
        <template
          v-for="(suggestion, idx) in getSocialSharingSelection
            .hashTagsSuggestions.data"
        >
          <li
            class="hashtag-suggestions-dropdown-list-item"
            :key="idx"
            v-if="suggestion.name"
            @click="addSharingHashtags(suggestion, type)"
            :class="{ 'active-item': currentItem === idx }"
          >
            <div class="profile_picture d-flex align-items-center">
              <div class="text_block">
                <div class="text">
                  <p class="text_left"
                    >#{{ suggestion.name }}
                    <span
                      style="background: rgb(243 243 243)"
                      v-if="suggestion.popularity"
                      v-tooltip.top-center="'Popularity for this hashtag'"
                      class="hashtagnumber"
                      :class="getTweetColors(suggestion.popularity)"
                      >{{ suggestion.popularity }}</span
                    ></p
                  >

                  <!-- number_blue, number_red, number_orange -->
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sharingTypes } from '../../../../store/states/mutation-types'

export default {
  props: ['type'],
  data () {
    return {
      currentItem: 0
    }
  },
  computed: {
    ...mapGetters(['getSocialSharingSelection', 'getPublishLoaders'])
  },
  mounted () {
    document.addEventListener('keyup', this.nextItem)
    document.addEventListener('keydown', this.selectItem)
  },
  destroyed () {
    document.removeEventListener('keyup', this.nextItem)
    document.removeEventListener('keydown', this.selectItem)
  },
  methods: {
    getTweetColors (popularity) {
      if (popularity >= 80) return 'number_green'
      if (popularity >= 50) return 'number_blue'
      return 'number_orange'
    },
    selectItem (event) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        event.keyCode === 13
      ) {
        event.preventDefault()
        const selected = document.querySelector(
          '.hashtag-suggestions-dropdown-list-item.active-item'
        )
        if (selected !== null) {
          selected.click()
        }
      }
    },
    nextItem (event) {
      if (event.keyCode === 38 && this.currentItem > 0) {
        this.currentItem--
      } else if (
        event.keyCode === 40 &&
        this.currentItem <
          this.getSocialSharingSelection.hashTagsSuggestions.data.length - 1
      ) {
        this.currentItem++
      }

      const selected = document.querySelector(
        '.hashtag-suggestions-dropdown-list-item.active-item'
      )
      if (selected !== null) {
        selected.focus()
        const elHeight = selected.clientHeight

        const scrollTop = document.querySelector(
          '.social_mention_dropdown .inner'
        ).scrollTop
        const viewport =
          scrollTop +
          document.querySelector('.social_mention_dropdown .inner').clientHeight
        const elOffset = elHeight * this.currentItem

        if (elOffset < scrollTop || elOffset + elHeight > viewport) {
          document.querySelector('.social_mention_dropdown .inner').scrollTop =
            elOffset
        }
      }
    }
  },
  watch: {
    'getSocialSharingSelection.hashTagsSuggestions.dropdown.left' (value) {
      if (value > 530) {
        value = value - 150
        this.$store.commit(
          sharingTypes.SET_HASHTAGS_DROPDOWN_LEFT_POSITION,
          value
        )
      }
    }
  }
}
</script>
