var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topics-filters-pane__container__left_section"},[_c('div',{staticClass:"topics-filters-pane__container__left_section__tabs"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Web'),expression:"'Web'",modifiers:{"top-center":true}}],class:{
        disable_click: _vm.getTopicDisabled,
        active: _vm.getTopicContentType === 'article',
      },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.changeContentType('article')}}},[_c('i',{staticClass:"icon-Globe_icon"}),_c('span',[_vm._v("Web")])]),(!_vm.getTrendingStories)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Youtube'),expression:"'Youtube'",modifiers:{"top-center":true}}],class:{
        disable_click: _vm.getTopicDisabled,
        active: _vm.getTopicContentType === 'youtube',
      },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.changeContentType('youtube')}}},[_c('i',{staticClass:"icon-Youtube_icon"}),_c('span',[_vm._v("YouTube")])]):_vm._e(),(!_vm.getTrendingStories)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Twitter'),expression:"'Twitter'",modifiers:{"top-center":true}}],class:{
        disable_click: _vm.getTopicDisabled,
        active: _vm.getTopicContentType === 'twitter',
      },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.changeContentType('twitter')}}},[_c('i',{staticClass:"icon-twitter_icon"}),_c('span',[_vm._v("Twitter")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }