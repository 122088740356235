<template>
  <div class="articles_list">
    <div class="article_inner">
      <template v-if="getDiscoveryFacebookLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <facebook></facebook>
        <template
          v-if="getFacebookPaginationLoader && getFacebookPosts.length == 0"
        >
          <no-results-found></no-results-found>
        </template>
        <template
          v-else-if="
            getFacebookPaginationLoader && getFacebookPosts.length <= 19
          "
        >
          <!-- FIXME: add a better image for this case -->
          <!-- will be creating some sort of image for this purpose.-->
          <!--<span >No more results found for your query.</span>-->
        </template>
        <infinite-loading
          v-else
          @infinite="onInfiniteFacebook"
          ref="infiniteLoading"
        >
          <span slot="spinner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>

          <span slot="no-more" class="not_found_text">
            <no-results-found :more="true"></no-results-found>
          </span>
          <span slot="no-results">
            <no-results-found
              v-if="getFacebookPosts.length == 0"
            ></no-results-found>
            <!--
                                                                        <span v-if="getFacebookPosts.length == 0">No results found for your query.</span>
                                                                        <span v-else>No more results found for your query.</span>
                                                                        <span>Please try a different search string or change search filters.</span>
                        -->
          </span>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../NoResultsFound'
import { topicTypes } from '../../../store/mutation-types'

const Facebook = () => import('../../common/Facebook')
export default {
  components: {
    InfiniteLoading,
    NoResultsFound,
    Facebook
  },
  computed: {
    ...mapGetters([
      'getDiscoveryFacebookLoader',
      'getFacebookPaginationLoader',
      'getFacebookPosts',
      'getVideoPaginationLoader',
      'getFacebookScroll'
    ])
  },
  async mounted () {
    if (!this.$route.params.module) {
      this.$router.push({
        name: 'cover_stories',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
    let res
    switch (this.$route.params.module) {
      case 'custom':
        res = this.isCustomTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'custom_topic',
            contentType: 'facebook',
            module: this.getTopicModule
          })
        }
        break
      case 'curated':
        res = this.isCuratedTopicAvailable()
        if (res) {
          this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
            topic: res.topic,
            type: 'curated',
            contentType: 'facebook',
            module: this.getTopicModule
          })
        }
        break
    }
    this.getFacebookContent()
    // this.discoveryMounted()
  },
  methods: {
    ...mapMutations([
      'SET_TOPIC_CONTENT_TYPE',
      topicTypes.SET_CHANGE_TOPIC_FILTERS
    ]),
    ...mapActions(['setFacebookPage']),
    onInfiniteFacebook ($state) {
      console.log(
        'On facebook infinite',
        this.getFacebookScroll,
        this.getFacebookPage
      )
      if (this.getFacebookScroll) {
        this.setFacebookPage(this.getFacebookPage + 1)
        this.getFacebookData($state)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave Facebook View:')

    // $('#curated_topic_modal').modal('hide')
    this.$bvModal.hide('curated_topic_modal')
    // $('#article_preview_modal').modal('hide')
    this.$bvModal.hide('article_preview_modal')
    // $('#create_topic_modal').modal('hide')
    this.$bvModal.hide('create_topic_modal')
    next()
  }
}
</script>
