import { mapGetters } from 'vuex'

export const connectAccountCheck = {
  computed: {
    ...mapGetters([
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getMobileDevices',
      'getWorkspaces',
      'getYoutubeAccounts',
      'getBlogs',
    ]),
  },
  methods: {
    checkSocialAccountConnection() {
      if (
        this.getFacebookAccounts.items.length > 0 ||
        this.getTwitterAccounts.items.length > 0 ||
        this.getLinkedinAccounts.items.length > 0 ||
        this.getPinterestAccounts.items.length > 0 ||
        this.getTumblrAccounts.items.length > 0 ||
        this.getInstagramAccounts.items.length > 0 ||
        this.getGmbAccounts.items.length > 0 ||
        this.getMobileDevices.items.length > 0 ||
        this.getWorkspaces.items.length > 0 ||
        this.getYoutubeAccounts.items.length > 0
      ) {
        return true
      } else {
        return false
      }
    },
    checkBlogAccountConnection() {
      if (
        this.getBlogs.wordpress.items.length > 0 ||
        this.getBlogs.tumblr.items.length > 0 ||
        this.getBlogs.medium.items.length > 0 ||
        this.getBlogs.shopify.items.length > 0
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
