const ComposerValidationMixin = {
    data(){
        return {
            errors: [
                {
                    id: 123,
                    text: 'Some Error'
                }
            ],
            warnings: [
                {
                    id: 123,
                    text: 'Some Warning'
                }
            ]
        }
    },
    computed: {

    },
    created() {

    },
    methods: {

    }
}

export default ComposerValidationMixin
