// import {permissionMixin} from '@common/mixins/permissionMixin'

// import {influencerFeatureLockCheck} from "@src/router";
import { store } from '@src/store/base'

const DiscoveryMain = () =>
  import('@src/modules/discovery/components/DiscoveryNavigationLayout.vue')
const Article = () =>
  import('@src/modules/discovery/components/search/Article.vue')
const ArticleSearchView = () =>
  import('@src/modules/discovery/components/search/ArticleSearchView.vue')
const ArticleInsightsView = () =>
  import('@src/modules/discovery/components/search/ArticleInsightsView.vue')
const ArticleWebInsightsReviewRequiredView = () =>
  import(
    '@src/modules/discovery/components/search/views/ArticleWebInsightsReviewRequiredView.vue'
  )
const ArticleWebInsightsView = () =>
  import(
    '@src/modules/discovery/components/search/views/ArticleWebInsightsView.vue'
  )
const ArticleWebSearchView = () =>
  import(
    '@src/modules/discovery/components/search/views/ArticleWebSearchView.vue'
  )
const ArticlesYoutubeSearchView = () =>
  import(
    '@src/modules/discovery/components/search/views/ArticlesYoutubeSearchView.vue'
  )
const ArticlesTwitterSearchView = () =>
  import(
    '@src/modules/discovery/components/search/views/ArticlesTwitterSearchView.vue'
  )
const WebSearch = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/main/WebSearch.vue'
  )
const YoutubeSearch = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/main/YoutubeSearch.vue'
  )
const FacebookSearch = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/main/FacebookSearch.vue'
  )
const TwitterSearch = () =>
  import(
    '@src/modules/discovery/components/search/sub-components/main/TwitterSearch.vue'
  )

const Topics = () =>
  import('@src/modules/discovery/components/topics/SelectedTopics.vue')
const TopicsMainView = () =>
  import('@src/modules/discovery/components/topics/views/TopicsMainView')
const TopicsFollowView = () =>
  import('@src/modules/discovery/components/topics/views/TopicsFollowView')
const TopicsCoverStoriesView = () =>
  import(
    '@src/modules/discovery/components/topics/views/aggregated/TopicsCoverStoriesView'
  )
const TopicsCuratedQuotesView = () =>
  import(
    '@src/modules/discovery/components/topics/views/aggregated/TopicsCuratedQuotesView'
  )
const TopicsArchivedView = () =>
  import(
    '@src/modules/discovery/components/topics/views/aggregated/TopicsArchivedView'
  )
const TopicsFavoriteView = () =>
  import(
    '@src/modules/discovery/components/topics/views/aggregated/TopicsFavoriteView'
  )
const TopicsCuratedMainView = () =>
  import(
    '@src/modules/discovery/components/topics/views/curated-topic-content/TopicsCuratedMainView'
  )
const TopicsCuratedWebView = () =>
  import(
    '@src/modules/discovery/components/topics/views/curated-topic-content/TopicsCuratedWebView'
  )
const TopicsCustomMainView = () =>
  import(
    '@src/modules/discovery/components/topics/views/custom-topic-content/TopicsCustomMainView'
  )
const TopicsCustomWebView = () =>
  import(
    '@src/modules/discovery/components/topics/views/custom-topic-content/TopicsCustomWebView'
  )
const TopicsCustomInsightsMainView = () =>
  import(
    '@src/modules/discovery/components/topics/views/custom-topic-insights/TopicsCustomInsightsMainView'
  )
const TopicsCustomInsightsWebView = () =>
  import(
    '@src/modules/discovery/components/topics/views/custom-topic-insights/TopicsCustomInsightsWebView'
  )
const TopicsCustomInsightsReviewView = () =>
  import(
    '@src/modules/discovery/components/topics/views/TopicsInsightsReviewView'
  )

const TopicsCuratedInsightsMainView = () =>
  import(
    '@src/modules/discovery/components/topics/views/curated-topic-insights/TopicsCuratedInsightsMainView'
  )
const TopicsCuratedInsightsWebView = () =>
  import(
    '@src/modules/discovery/components/topics/views/curated-topic-insights/TopicsCuratedInsightsWebView'
  )

const CoverStories = () =>
  import('@src/modules/discovery/components/common/CoverStories.vue')

const DiscoveryQuotes = () =>
  import('@src/modules/discovery/components/quotes/WebQuotes.vue')
const FacebookQuotes = () =>
  import('@src/modules/discovery/components/quotes/FacebookQuotes.vue')
const CuratedQuotesNavigation = () =>
  import('@src/modules/discovery/components/quotes/CuratedQuotesNavigation.vue')
const QuoteNavigationLayout = () =>
  import('@src/modules/discovery/components/quotes/QuoteNavigationLayout.vue')
const YoutubeView = () =>
  import(
    '@src/modules/discovery/components/topics/channel-views/YoutubeView.vue'
  )
const FacebookView = () =>
  import(
    '@src/modules/discovery/components/topics/channel-views/FacebookView.vue'
  )
const TwitterView = () =>
  import(
    '@src/modules/discovery/components/topics/channel-views/TwitterView.vue'
  )
const WebArticlesView = () =>
  import(
    '@src/modules/discovery/components/topics/channel-views/WebArticlesView.vue'
  )
const FeederView = () =>
  import('@src/modules/discovery/components/feeder/views/FeederView.vue')
const FeederContentSection = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederContentSection.vue'
  )
const FeederFeedSectionView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederFeedSectionView.vue'
  )
const FeederAggregateView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederAggregateView.vue'
  )
const FeederFollowSourcesEmptyView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederFollowSourcesEmptyView.vue'
  )
const FeederFollowCategoriesView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederFollowCategoriesView.vue'
  )
const FeederFollowActionsView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederFollowActionsView.vue'
  )
const FeederManageSourcesView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederManageSourcesView.vue'
  )
const FeederImportOPMLView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederImportOPMLView.vue'
  )
const FeederOPMLImportHistoryView = () =>
  import(
    '@src/modules/discovery/components/feeder/views/FeederOPMLImportHistoryView.vue'
  )
const InsightsView = () =>
  import(
    '@src/modules/discovery/components/topics/channel-views/InsightsView.vue'
  )
const ArticlePreviewDialog = () =>
  import(
    '@src/modules/discovery/components/common/preview/ArticlePreviewDialog.vue'
  )
const SocialShareDialog = () =>
  import('@src/modules/publish/components/dialogs/SocialShareDialog')
const BlogPostDialog = () =>
  import('@src/modules/publish/components/dialogs/BlogPostDialog')

// discovery new routes

const Content = () =>
  import('@src/modules/discovery/components/discovery/content/Content.vue')
const InfluencersMain = () =>
  import('@src/modules/discovery/components/influencers/InfluencersMain.vue')
const Insights = () =>
  import('@src/modules/discovery/components/discovery/insights/Insights.vue')
const InfluencersInstagram = () =>
  import(
    '@src/modules/discovery/components/influencers/instagram/InfluencersListing.vue'
  )
const InfluencersTwitter = () =>
  import(
    '@src/modules/discovery/components/influencers/twitter/InfluencersListing.vue'
  )
const TwitterInfluencerDialog = () =>
  import(
    '@src/modules/discovery/components/common/preview/TwitterInfluencerDialog.vue'
  )

const InfluencersYoutube = () =>
  import(
    '@src/modules/discovery/components/influencers/youtube/InfluencersListing.vue'
  )
const InfluencerDetails = () =>
  import(
    '@src/modules/discovery/components/influencers/dialogs/InfluencerDetail.vue'
  )

const isOnLifetimePlan = () => {
  if (
    store.getters.getPlan.subscription &&
    store.getters.getPlan.subscription.fastspring_name
  ) {
    const name = store.getters.getPlan.subscription.fastspring_name
    if (
      name.includes('lifetime') ||
      name.includes('jvzoo') ||
      name.includes('appsumo')
    ) {
      if (name.includes('bruce') || name.includes('starter')) {
        return false
      }
      return true
    }
  }
  return false
}

const isAnalyticsUnlocked = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.
  if (isOnLifetimePlan()) {
    if (!store.getters.getProfile.insights_unlocked) {
      if (to.name.includes('custom')) {
        next({
          name: 'discover_topics_custom_web_insights_review',
          params: { topic_id: to.params.topic_id },
        })
      } else {
        next({
          name: 'discover_topics_curated_web_insights_review',
          params: { topic_id: to.params.topic_id },
          query: { topic: to.query.topic },
        })
      }

      return
    }
    next()
    return
  }
  next()
}

export const discoveryRoutes = {
  path: '/:workspace/discovery',
  redirect: '/:workspace/discovery/topics',
  name: 'discovery',
  component: DiscoveryMain,
  meta: {
    title: 'Discover | ContentStudio',
  },
  children: [
    {
      path: 'content/discover_search/',
      name: 'discover_search',
      component: ArticleSearchView,
      meta: {
        title: 'Content Feed | Discover Search',
        is_active: 'content', // used for showing the options such as search history, their examples, along with the search operators. Only valid for content  and insights use case.
        show_default_view: true,
        is_route: 'discover_search',
      },
      children: [
        {
          path: 'web',
          name: 'discover_search_web',
          component: ArticleWebSearchView,
          meta: {
            title: 'Web Search | Discover Search',
            is_active: 'content',
            is_route: 'discover_search',
          },
          children: [
            {
              path: 'preview/:post_id/',
              name: 'discover_search_web_item_preview',
              component: ArticlePreviewDialog,
              meta: {
                preserve_position: true,
                is_active: 'content',
                is_route: 'discover_search',
              },
            },
            {
              path: 'influencers/:post_id/',
              name: 'discover_search_web_item_influencers',
              component: TwitterInfluencerDialog,
              meta: {
                preserve_position: true,
                is_active: 'content',
                is_route: 'discover_search',
              },
            },
            {
              path: 'preview/influencers/:influencer_id/',
              name: 'twitter_discovery_influencer_preview',
              component: InfluencerDetails,
              meta: {
                preserve_position: true,
                title: 'Twitter Influencer Preview',
              },
            },
          ],
        },
        {
          path: 'youtube',
          name: 'discover_search_youtube',
          component: ArticlesYoutubeSearchView,
          meta: {
            preserve_position: true,
            is_route: 'discover_search',
            is_active: 'youtube_videos',
          },
        },
        {
          path: 'twitter',
          name: 'discover_search_twitter',
          component: ArticlesTwitterSearchView,
          meta: {
            preserve_position: true,
            is_route: 'discover_search',
            is_active: 'twitter_tweets',
          },
        },
      ],
    },
    {
      path: 'content/discover_insights/',
      name: 'discover_insights',
      component: ArticleInsightsView,
      meta: {
        title: 'Insights | Discover Search',
        is_active: 'insights',
        show_default_view: true,
        is_route: 'discover_search',
      },
      children: [
        {
          path: 'web',
          name: 'discover_insights_web',
          component: ArticleWebInsightsView,
          meta: {
            title: 'Web Insights | Discover Search',
            is_active: 'insights',
            is_route: 'discover_search',
          },
        },
        {
          path: 'review_required',
          name: 'discover_insights_web_review_required',
          component: ArticleWebInsightsReviewRequiredView,
          meta: {
            title: 'Web Insights | Discover Search',
            is_active: 'insights',
            is_route: 'discover_search',
          },
        },
      ],
    },
    {
      path: 'content/search/',
      name: 'articles',
      component: Article,
      meta: {
        title: 'Discovery Articles | Discover',
      },
      children: [
        {
          path: 'web/:module?/:topicId?/:topicName?/',
          name: 'search_web',
          component: WebSearch,
        },
        {
          path: 'youtube/:module?/:topicId?/:topicName?/',
          name: 'search_youtube',
          component: YoutubeSearch,
        },
        {
          path: 'facebook/:module?/:topicId?/:topicName?/',
          name: 'search_facebook',
          component: FacebookSearch,
        },
        {
          path: 'twitter/:module?/:topicId?/:topicName?/',
          name: 'search_twitter',
          component: TwitterSearch,
        },
      ],
    },
    // new routes for the Topics.
    {
      path: 'topics',
      name: 'discover_topics',
      component: TopicsMainView,
      meta: {
        title: 'Topics | Discover',
      },
      children: [
        {
          path: 'follow',
          name: 'discover_topics_follow',
          component: TopicsFollowView,
          meta: {
            title: 'Follow Topics | Discover Topics',
          },
        },
        {
          path: 'cover_stories',
          name: 'discover_topics_cover_stories',
          component: TopicsCoverStoriesView,
          meta: {
            title: 'Cover Stories | Discover Topics',
          },
        },
        {
          path: 'curated_quotes',
          name: 'discover_topics_curated_quotes',
          component: TopicsCuratedQuotesView,
          meta: {
            title: 'Curated Quotes | Discover Topics',
          },
        },
        {
          path: 'archived',
          name: 'discover_topics_archived',
          component: TopicsArchivedView,
          meta: {
            title: 'Archived Posts | Discover Topics',
          },
        },
        {
          path: 'favorite/:folder_id?',
          name: 'discover_topics_favorite',
          component: TopicsFavoriteView,
          meta: {
            title: 'Favorite Posts | Discover Topics',
          },
        },
        {
          path: 'curated/content/:topic_id?',
          name: 'discover_topics_curated',
          component: TopicsCuratedMainView,
          redirect: { name: 'discover_topics_curated_web' },
          meta: {
            title: 'Curated Topic | Discover Topics',
          },
          children: [
            {
              path: 'web',
              name: 'discover_topics_curated_web_content',
              component: TopicsCuratedWebView,
              meta: {
                title: 'Web Articles | Discover Topics',
              },
            },
            {
              path: 'trending',
              name: 'discover_topics_curated_trending',
              component: TopicsCuratedWebView,
              meta: {
                title: 'Trending News | Discover Topics',
              },
            },
          ],
        },

        {
          path: 'curated/insights/:topic_id?',
          name: 'discover_topics_curated_insights',
          component: TopicsCuratedInsightsMainView,
          // redirect:{name: 'discover_topics_custom_web'},
          meta: {
            title: 'Curated Topic Insights | Discover Topics',
          },
          children: [
            {
              path: 'web',
              name: 'discover_topics_curated_web_insights',
              component: TopicsCuratedInsightsWebView,
              beforeEnter: isAnalyticsUnlocked,
              meta: {
                title: 'Web Articles | Discover Topics',
              },
            },
            {
              path: 'review',
              name: 'discover_topics_curated_web_insights_review',
              component: TopicsCustomInsightsReviewView,
              meta: {
                title: 'Insights | Discover Topics',
              },
            },
          ],
        },
        {
          path: 'custom/:topic_id?',
          name: 'discover_topics_custom',
          component: TopicsCustomMainView,
          // redirect:{name: 'discover_topics_custom_web'},
          meta: {
            title: 'Custom Topic | Discover Topics',
          },
          children: [
            {
              path: 'web',
              name: 'discover_topics_custom_web_content',
              component: TopicsCustomWebView,
              meta: {
                title: 'Web Articles | Discover Topics',
              },
            },
          ],
        },
        {
          path: 'custom/insights/:topic_id?',
          name: 'discover_topics_custom_insights',
          component: TopicsCustomInsightsMainView,
          // redirect:{name: 'discover_topics_custom_web'},
          meta: {
            title: 'Custom Topic Insights | Discover Topics',
          },
          children: [
            {
              path: 'web',
              name: 'discover_topics_custom_web_insights',
              component: TopicsCustomInsightsWebView,
              beforeEnter: isAnalyticsUnlocked,
              meta: {
                title: 'Web Articles | Discover Topics',
              },
            },
            {
              path: 'review',
              name: 'discover_topics_custom_web_insights_review',
              component: TopicsCustomInsightsReviewView,
              meta: {
                title: 'Insights | Discover Topics',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'content/topics/',
      name: 'topics',
      component: Topics,
      meta: {
        title: 'Topics | Discover Topics',
      },
      children: [
        {
          path: 'cover_stories',
          name: 'cover_stories',
          component: CoverStories,
        },
        {
          path: 'quotes',
          name: 'topics_quotes',
          component: CuratedQuotesNavigation,
        },
        {
          path: ':module?/youtube/:topicId?/:topicName?/',
          name: 'topics_youtube',
          component: YoutubeView,
        },
        {
          path: ':module?/facebook/:topicId?/:topicName?/',
          name: 'topics_facebook',
          component: FacebookView,
        },
        {
          path: ':module?/twitter/:topicId?/:topicName?/',
          name: 'topics_twitter',
          component: TwitterView,
        },
        {
          path: ':module?/web/:topicId?/:topicName?/',
          name: 'topics_web',
          component: WebArticlesView,
        },
        {
          path: ':module?/insights/:topicId?/:topicName?/',
          name: 'topics_insights',
          component: InsightsView,
        },
      ],
    },
    {
      path: 'quotes/',
      name: 'quotes',
      redirect: '/content/discovery/quotes/web',
      component: QuoteNavigationLayout,
      // redirect: '/:workspace/discovery/quotes/web',
      meta: {
        title: 'Visual Web Quotes | Discover Topics',
      },
      children: [
        {
          path: 'web/',
          name: 'web',
          component: DiscoveryQuotes,
          meta: {
            title: 'Visual Web Quotes',
          },
        },
        {
          path: 'facebook/',
          name: 'facebook',
          component: FacebookQuotes,
          meta: {
            title: 'Facebook Quotes',
          },
        },
      ],
    },

    {
      path: 'influencers/',
      name: 'web_influencers',
      redirect: '/content/discovery/influencers/twitter',
      component: InfluencersMain,
      meta: {
        title: 'Influencers | Discover Influencers',
      },
      children: [
        {
          path: 'twitter/',
          name: 'twitter_influencers',
          component: InfluencersTwitter,
          meta: {
            title: 'Twitter Influencers | Discover Influencers',
          },
          // beforeEnter: (to, from, next) => influencerFeatureLockCheck(to, from, next),
          children: [
            {
              path: 'preview/:influencer_id/',
              name: 'twitter_influencer_preview',
              component: InfluencerDetails,
              meta: {
                title: 'Twitter Influencer Preview | Discover Influencers',
              },
            },
          ],
        },
        {
          path: 'instagram/',
          name: 'instagram_influencers',
          component: InfluencersInstagram,
          meta: {
            title: 'Instagram Influencers | Discover Influencers',
          },
          // beforeEnter: (to, from, next) => influencerFeatureLockCheck(to, from, next),
          children: [
            {
              path: 'preview/:influencer_id/',
              name: 'instagram_influencer_preview',
              component: InfluencerDetails,
              meta: {
                title: 'Instagram Influencer Preview | Discover Influencers',
              },
            },
          ],
        },
        {
          path: 'youtube/',
          name: 'youtube_influencers',
          component: InfluencersYoutube,
          meta: {
            title: 'YouTube Influencers',
          },
          children: [
            {
              path: 'preview/:influencer_id/',
              name: 'youtube_influencer_preview',
              component: InfluencerDetails,
              meta: {
                title: 'YouTube Influencer Preview | Discover Influencers',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'feeds/',
      name: 'feeder',
      component: FeederView,
      meta: {
        title: 'Feeds | Discover Feeds',
      },
      children: [
        {
          path: 'collection/:collection_id/:feed_id?',
          name: 'feeder_collection',
          component: FeederContentSection,
          meta: {
            title: 'Collection | Discover Feeds',
          },
          children: [
            {
              path: 'preview/:post_id/',
              name: 'collection_feed_item_preview',
              component: ArticlePreviewDialog,
              meta: {
                preserve_position: true,
              },
            },
            {
              path: 'share/:post_id/',
              name: 'collection_feed_item_social_share',
              component: SocialShareDialog,
              meta: {
                preserve_position: true,
              },
            },
          ],
        },
        {
          path: 'feed/:feed_id/group/:collection_id',
          name: 'feeder_feed_view',
          component: FeederFeedSectionView,
          meta: {
            title: 'Feed View | Discover Feeds',
          },
          children: [
            {
              path: 'preview/:post_id/',
              name: 'individual_feed_item_preview',
              component: ArticlePreviewDialog,
              meta: {
                preserve_position: true,
              },
            },
            {
              path: 'share/:post_id/',
              name: 'individual_feed_item_social_share',
              component: SocialShareDialog,
              meta: {
                preserve_position: true,
              },
            },
          ],
        },
        {
          path: 'me/:content_type',
          name: 'feeder_aggregate_view',
          component: FeederAggregateView,
          meta: {
            title: 'View | Discover Feeds',
          },
          children: [
            {
              path: 'preview/:post_id/',
              name: 'aggregate_feed_item_preview',
              component: ArticlePreviewDialog,
              meta: {
                preserve_position: true,
              },
            },
            {
              path: 'share/:post_id/',
              name: 'aggregate_feed_item_social_share',
              component: SocialShareDialog,
              meta: {
                preserve_position: true,
              },
            },
            {
              path: 'share/:post_id/',
              name: 'aggregate_feed_item_blog_post',
              component: BlogPostDialog,
              meta: {
                preserve_position: true,
              },
            },
            {
              path: 'twitter-influencer/:post_id/',
              name: 'aggregate_feed_twitter_influencer',
              component: TwitterInfluencerDialog,
              meta: {
                preserve_position: true,
              },
            },
          ],
        },
        {
          path: 'follow',
          name: 'feeder_follow_sources',
          component: FeederFollowSourcesEmptyView,
          meta: {
            title: 'Follow Sources | Discover Feeds',
          },
        },
        {
          path: 'follow/categories',
          name: 'feeder_follow_categories',
          component: FeederFollowCategoriesView,
          meta: {
            title: 'Search Feeds | Discover Feeds',
          },
        },
        {
          path: 'follow/actions',
          name: 'feeder_follow_actions',
          component: FeederFollowActionsView,
          meta: {
            title: 'Follow Feeds | Discover Feeds',
          },
        },
        {
          path: 'manage_sources',
          name: 'feeder_manage_sources',
          component: FeederManageSourcesView,
          meta: {
            title: 'Manage Sources | Discover Feeds',
          },
        },
        {
          path: 'opml_import',
          name: 'feeder_opml_import',
          component: FeederImportOPMLView,
          meta: {
            title: 'OPML Import | Discover Feeds',
          },
        },
        {
          path: 'opml_import_history',
          name: 'feeder_opml_import_history',
          component: FeederOPMLImportHistoryView,
          meta: {
            title: 'OPML Import History | Discover Feeds',
          },
        },
      ],
    },

    // new routes for Discovery

    {
      path: '/:workspace/new_articles',
      name: 'new_articles',
      component: Content,
    },
    {
      path: '/:workspace/new_insights',
      name: 'new_insights',
      component: Insights,
    },
  ],
}
