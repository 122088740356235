var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full cst-drop-shadow bg-white rounded-xl"},[_vm._m(0),_c('div',{staticClass:"flex items-center px-3 py-2"},[_c('img',{staticClass:"object-cover border border-solid border-black",attrs:{"width":"40","height":"40","src":_vm.account.platform_logo,"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}),_c('div',{staticClass:"px-2 font-medium text-black"},[_vm._v(" "+_vm._s(_vm.account.platform_name)+" ")]),_c('span',{staticClass:"px-1 font-medium text-blue-500"},[_vm._v("Follow")]),_vm._m(1)]),_c('div',{staticClass:"w-full h-full"},[(_vm.detail.image && _vm.detail.image.length > 0)?_c('div',{staticClass:"w-100 h-100"},[_c('div',{staticClass:"flex flex-wrap relative overflow-hidden"},[_c('div',{staticClass:"flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]",style:({ 'background-image': 'url(' + _vm.detail.image[0] + ')' }),on:{"click":_vm.handleImagePreview}},[_c('img',{staticClass:"max-w-full max-h-full w-full opacity-0",attrs:{"src":_vm.detail.image[0],"alt":""},on:{"error":(event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }}})])])]):(_vm.detail.video && _vm.detail.video.thumbnail)?_c('div',{staticClass:"relative h-100 text-white"},[_c('img',{staticClass:"w-full min-h-[190px] max-h-[500px]",attrs:{"src":_vm.detail.video.thumbnail,"alt":""}}),_c('i',{staticClass:"fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid",staticStyle:{"transform":"translate(-50%, -50%)"},on:{"click":function($event){$event.preventDefault();return _vm.displayFile('video', _vm.detail.video.link, 0)}}})]):_vm._e()]),(_vm.detail.message)?_c('div',{staticClass:"items-center py-3 px-3"},[_c('span',{staticClass:"whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.getDescription)}})]):_vm._e(),(_vm.type === 'composer')?[_vm._m(2),_vm._m(3)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center px-3 py-2"},[_c('div',{staticClass:"ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-violet"},[_c('img',{staticClass:"w-5",attrs:{"src":require("@assets/img/integration/tumblr-icon.svg"),"alt":" "}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ml-auto block font-bold"},[_c('i',{staticClass:"fas fa-ellipsis-h"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center px-3 py-1"},[_c('div',{staticClass:"bg-blue-600 text-white rounded-full mx-1"},[_c('i',{staticClass:"fas fa-comment m-1"})]),_c('div',{staticClass:"bg-green-600 text-white rounded-full mx-1"},[_c('i',{staticClass:"fas fa-retweet m-1"})]),_c('div',{staticClass:"bg-red-600 text-white rounded-full mx-1"},[_c('i',{staticClass:"fas fa-heart m-1"})]),_c('span',{staticClass:"mx-1 text-gray-800 font-bold"},[_vm._v("513 notes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center mt-2 mx-4 px-1 py-3 reaction-icons justify-between text-base",staticStyle:{"border-top":"1px solid #dee2e6"}},[_c('i',{staticClass:"far fa-share"}),_c('i',{staticClass:"far fa-comment"}),_c('i',{staticClass:"far fa-retweet"}),_c('i',{staticClass:"far fa-heart"})])
}]

export { render, staticRenderFns }