<script>
import { mapGetters } from 'vuex'
import UpgradeLifetimeAddons from './UpgradeLifetimeAddons'

export default {
  components: { UpgradeLifetimeAddons },
  props: [],

  data() {
    return {}
  },

  created() {},

  mounted() {
    if (this.$route.name === 'upgrade_addons_lifetime')
      this.$bvModal.show('update-feature-plan')
  },
  methods: {
    hideUpdateFeaturePlanModal() {
      if (this.$route.name === 'upgrade_addons_lifetime') {
        this.$bvModal.hide('update-feature-plan')
        this.$router.push({ name: 'workspaces' })
        return
      }

      this.$store.dispatch('triggerAction', 'influencers_and_analytics')
      this.$bvModal.hide('update-feature-plan')
    },
  },
}
</script>

<template>
  <b-modal
    id="update-feature-plan"
    modal-class="update-feature-plan"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <button
      type="button"
      class="close"
      style="z-index: 1000"
      @click="hideUpdateFeaturePlanModal"
      >&times;</button
    >
    <UpgradeLifetimeAddons></UpgradeLifetimeAddons>
  </b-modal>
</template>
