<template>
  <div>
    <div class="hidden">
      <div ref="title">
        <slot name="title"></slot>
      </div>
    </div>
    <div v-show="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstTab',
  inject: ['ctx'],
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  watch: {
    hidden: function (val) {
      if (val) this.isActive = false
    },
  },
}
</script>
