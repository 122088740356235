<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {},

  data() {
    return {
      accounts: [],
      selected_accounts: [],
      network: 'group',
      all_selected: true
    }
  },
  computed: {
    ...mapGetters([
      'getReportsServiceCalls',
      'getScheduledReportItem',
      'getFacebookPagesList',
      'getInstagramAccounts',
      'getTwitterAccounts',
      'getLinkedinCompanyPages'
    ])
  },
  created() {
  },
  beforeDestroy() {
    EventBus.$off('schedule-report')
  },
  mounted() {
    EventBus.$on('schedule-report', async ({accounts, network}) => {
      console.log('schedule-report', accounts, network)
      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(accounts)
      this.SET_SCHEDULED_REPORT_ITEM_TYPE(network)
      this.accounts = this.populateAccounts()

      if (network === 'instagram') {
        this.selected_accounts = await this.getAccounts(
            this.getScheduledReportItem
        )
      } else {
        if (Array.isArray(accounts)) {
          this.selected_accounts = accounts
        } else {
          this.selected_accounts = [accounts]
        }
      }

      this.network = network
      if (this.selected_accounts) {
        this.all_selected =
          this.selected_accounts.length === this.accounts.length &&
          this.selected_accounts.length > 0
      }
    })
  },

  methods: {
    ...mapActions(['scheduleReportsService']),
    ...mapMutations([
      'SET_SCHEDULED_REPORT_ITEM_ACCOUNTS',
      'SET_SCHEDULED_REPORT_ITEM_TYPE',
      'RESET_SCHEDULED_REPORT_ITEM'
    ]),
    reset () {
      this.RESET_SCHEDULED_REPORT_ITEM()
      this.selected_accounts = []
    },
    populateAccounts () {
      const accountsList = []
      this.getFacebookPagesList.forEach((item) => {
        item.account_type = 'facebook'
        accountsList.push(item)
      })
      this.getTwitterAccounts.items.forEach((item) => {
        item.account_type = 'twitter'
        accountsList.push(item)
      })
      // this.getPinterestBoards.forEach(item => {
      //   item.account_type = 'pinterest'
      //   accountsList.push(item)
      // })
      this.getLinkedinAccounts.items.forEach((item) => {
        item.account_type = 'linkedin'
        accountsList.push(item)
      })
      this.getInstagramAccounts.items.forEach((item) => {
        item.account_type = 'instagram'
        accountsList.push(item)
      })

      return accountsList
    },
    getAccounts (item) {
      switch (item.type) {
        case 'group':
          return item.accounts
        case 'facebook':
          return this.getFacebookPagesList.filter(
            (i) => i.facebook_id === item.accounts
          )
        case 'instagram':
          return this.getInstagramAccounts.items.filter(
            (i) => i.instagram_id === item.accounts.instagram_id
          )
        case 'twitter':
          return this.getTwitterAccounts.items.filter(
            (i) => i.twitter_id === item.accounts
          )
        case 'linkedin':
          return this.getLinkedinCompanyPages.filter(
            (i) => i.linkedin_id === item.accounts
          )
      }
    },
    getIconBg (type) {
      switch (type) {
        case 'instagram':
          return 'ins_bg'
        case 'facebook':
          return 'fb_bg'
        case 'twitter':
          return 'tw_bg'
        case 'linkedin':
          return 'lin_bg'
      }
    },
    getPlatform (type) {
      return 'cs-' + type + ' ' + this.getIconBg(type)
    },
    async scheduleReport () {
      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(this.selected_accounts)
      await this.scheduleReportsService(this.getScheduledReportItem)
    },
    addEmailToList (event) {
      console.log(event)
      let email = this.getScheduledReportItem.email
      // check if it is a comma, then substring last element which will be a comma
      if (event.key === ',') {
        email = email.substring(0, email.length - 1)
      }

      // validate emaill address

      if (this.validateEmail(email)) {
        // if it is a valid one push to the list and empty the v-model value
        if (this.getScheduledReportItem.email_list.includes(email)) {
          // not a duplicate email
          this.$store.dispatch('toastNotification', {
            message: 'Email address already added.',
            type: 'error'
          })
        } else {
          this.getScheduledReportItem.email_list.push(email)
          this.getScheduledReportItem.email = null
          console.log('Valid email address pushed to the reports list.')
        }
      }
    },
    removeEmailFromList (index) {
      this.getScheduledReportItem.email_list.splice(index, 1)
    },
    copyEmailMyself () {
      if (this.getScheduledReportItem.copy_email_to_myself) {
        if (
          this.getScheduledReportItem.email_list.indexOf(
            this.getProfile.email
          ) === -1
        ) {
          this.getScheduledReportItem.email_list.unshift(this.getProfile.email)
        }
      } else {
        if (
          this.getScheduledReportItem.email_list.indexOf(
            this.getProfile.email
          ) !== -1
        ) {
          this.getScheduledReportItem.email_list.splice(
            this.getScheduledReportItem.email_list.indexOf(
              this.getProfile.email
            ),
            1
          )
        }
      }
    }
  },
  watch: {
    async getScheduledReportItem (val) {
      console.debug('Watch::getScheduledReportItem', val)
      this.accounts = this.populateAccounts()
      this.selected_accounts = await this.getAccounts(val)

      if (this.selected_accounts) {
        this.all_selected =
          this.selected_accounts.length === this.accounts.length &&
          this.selected_accounts.length > 0
      }
    },
    // eslint-disable camelcase
    all_selected (new_val, val) {
      if (new_val) {
        this.selected_accounts = this.accounts
      } else {
        if (
          !(
            this.selected_accounts &&
            this.selected_accounts.length < this.accounts.length &&
            this.selected_accounts.length > 0
          )
        ) {
          this.selected_accounts = []
        }
      }
    }
  }
}
</script>

<template>
  <div id="scheduleReport" class="modal fade scheduleEmail normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Schedule Email</h2>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="reset"
          >&times;
          </button
          >
        </div>
        <div class="modal_body basic_form m_t_15">
          <div class="input_field mt-0">
            <label for="">Export Name</label>
            <input
                v-model="getScheduledReportItem.name"
                type="text"
                placeholder="Enter a name for export..."
            />
          </div>
          <div v-if="getScheduledReportItem.type" class="input_field mt-0">
            <label for="">Export Type</label>
            <p class="accounts">
              {{
                getScheduledReportItem.type[0].toUpperCase() +
                getScheduledReportItem.type.slice(1)
              }}</p
            >
          </div>

          <div class="input_field mt-3">
            <div class="d-flex">
              <label for="">Social Accounts</label>
              <div
                v-if="getScheduledReportItem.type === 'group'"
                class="checkbox_input_image checkboxx"
              >
                <input
                    id="select_all_accounts"
                    v-model="all_selected"
                    type="checkbox"
                />
                <label for="select_all_accounts" class="checkbox_right">
                </label>
              </div>
            </div>

            <div class="accounts container">
              <ul class="account_ul ks-cboxtags">
                <template
                  v-for="(item, key) in getScheduledReportItem.type !== 'group'
                    ? selected_accounts
                    : accounts"
                >
                  <li v-if="item" :key="key" class="">
                    <input
                        :id="item._id"
                        v-model="selected_accounts"
                        type="checkbox"
                        :value="item"
                        :disabled="getScheduledReportItem.type !== 'group'"
                    />
                    <label
                        :for="item._id"
                        :class="{
                        disable_click: getScheduledReportItem.type !== 'group',
                      }"
                    >
                      <img
                          class="account_img"
                          :src="item.image"
                          :alt="item.name[0]"
                          @error="
                          $event.target.src = fallbackImagePreview(   item.name ? item.name[0] : '#')
                        "
                      />
                      <i
                        class="account_i"
                        :class="getPlatform(item.account_type)"
                      ></i>
                      <p class="account_p">{{ item.name }}</p>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="input_field mt-3 repeat_option">
            <label for="">Interval</label>
            <div class="d-flex align-items-start">
              <div class="radio_input_image">
                <input
                    id="week_interval"
                    v-model="getScheduledReportItem.interval"
                    checked
                    name="repeat_radio"
                    value="weekly"
                    type="radio"
                />
                <label for="week_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-starkot fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Weekly</span></p>
                      <p class="sub_text"
                        >Every Monday you'll be sent a report of the previous
                        week.</p
                      >
                    </div>
                  </div>
                </label>
              </div>

              <div class="radio_input_image">
                <input
                    id="month_interval"
                    v-model="getScheduledReportItem.interval"
                    name="repeat_radio"
                    type="radio"
                    value="monthly"
                />
                <label for="month_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-start fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Monthly</span></p>
                      <p class="sub_text"
                        >On 2nd of every month you'll be sent a report of the
                        previous month.</p
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="input_field">
            <div class="multi_select">
              <div class="d-flex mb-2 align-items-center">
                <label for="" class="mb-0"
                  >Email To (Separate emails with a comma)</label
                >
                <div class="checkbox_input_image ml-auto">
                  <input
                      id="copy_email_to_myself"
                      v-model="getScheduledReportItem.copy_email_to_myself"
                      type="checkbox"
                      @change="copyEmailMyself"
                  />
                  <label for="copy_email_to_myself" class="checkbox_left">
                    Send a copy to myself
                  </label>
                </div>
              </div>

              <div class="multi_input">
                <input
                    v-model="getScheduledReportItem.email"
                    type="text"
                    placeholder="Enter up to 25 email address..."
                    @keyup.enter="addEmailToList($event)"
                    @keyup.188="addEmailToList($event)"
                />
              </div>
              <div class="multi_tags">
                <ul>
                  <li
                      v-for="(item, key) in getScheduledReportItem.email_list"
                      :key="key"
                      class="green"
                  >
                    <span class="value">{{ item }}</span>
                    <span class="remove" @click="removeEmailFromList(key)"
                    >&times;</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!--<div class="input_field  ">-->
          <!--<label for="">Summary</label>-->
          <!--<p class="summary_text">Scheduled to repeat every Monday.</p>-->
          <!--</div>-->

          <div class="input_field mt-4">
            <button
                class="btn gradient_btn large_btn btn_loader loader_right"
                :disabled="getReportsServiceCalls.save"
                @click="scheduleReport"
            >
              <span>Schedule</span>
              <clip-loader
                  v-if="getReportsServiceCalls.save"
                  :color="'#ffffff'"
                  :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accounts {
  max-height: 150px;
  overflow: auto;
  border: 1px solid #cbceda;
  padding: 0.5rem;
  border-radius: 5px;
}

.account_ul {
  display: flex;
  flex-wrap: wrap;
  cursor: default;
}

.account_img {
  font-size: 13px;
  height: 25px;
  width: 25px;
  border-radius: 2rem;
}

.account_p {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.account_i {
  color: #ffffff;
  border-radius: 0.6rem;
  position: relative;
  right: 0.6rem;
  top: 0.6rem;
  height: 0.8rem;
  width: 0.8rem;
}

.container {
}

ul.ks-cboxtags {
  list-style: none;
}

ul.ks-cboxtags li {
  display: flex;
}

ul.ks-cboxtags li label {
  display: flex;
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  align-items: center;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  margin-right: 0.2rem;
}

ul.ks-cboxtags li label::before {
  display: flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 0.8rem;
  padding: 2px 6px 2px 2px;
  content: '✚';
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
  content: '✔';
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label {
  border: 2px solid #4cdd99;
  color: #3a4557;
  transition: all 0.2s;
}

.select_all label {
  position: absolute;
  right: 2rem;
  top: 16rem;
}

.select_all label span {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
  transition: all linear 0.3s;
}

.select_all label span :after {
  content: 's';
  position: absolute;
  top: -1px;
  left: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 9px;
  width: 4px;
  transform: rotate(45deg);
  visibility: hidden;
}

.select_all input {
  display: none;
}

.select_all input :checked {
  background: #ccc;
}

.select_all input :after {
  visibility: visible;
}

ul.ks-cboxtags li input[type='checkbox'] {
}

ul.ks-cboxtags li input[type='checkbox'] {
  opacity: 0;
}

.checkboxx {
  position: absolute;
  right: 2.5rem;
}
</style>
