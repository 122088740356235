<script>
import {
  getStatusClass,
  platformCommentPostingStatus,
  platformPostingStats,
  platformPostingStatus,
  zapierPostingError,
  platformPosting,
} from '@common/lib/planner'
import {
  platformImage,
  getPlatformByIdentifier,
  getPlatformName,
  getPlatformIdentifierValue,
} from '@common/lib/integrations'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import { retryPostPublishURL } from '@src/modules/planner/config/api-utils'
import proxy from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";

const importedMethods = {
  getStatusClass,
  platformCommentPostingStatus,
  platformPostingStats,
  platformPostingStatus,
  zapierPostingError,
  platformPosting,
  platformImage,
  getPlatformByIdentifier,
  getPlatformName,
  getPlatformIdentifierValue,
}

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    type: {
      type: String,
      default: 'facebook',
      required: true,
    },
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      blog_integrations: blogIntegrationsNames,
      post_notification_loader: false,
      threads_response: false,
      retryLoader: false,
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces']),
    getPlatformType() {
      return this.type === 'tumblr' && this.account
        ? 'tumblr_account'
        : this.type
    },
    isPublishedPost() {
      return getStatusClass(this.item) === 'published'
    },
    statisticsVisibilityStatus() {
      return (
        this.type !== 'gmb' &&
        this.type !== 'instagram' &&
        this.type !== 'youtube' &&
        this.type !== 'pinterest' &&
        this.isPublishedPost &&
        platformPostingStats(
          this.item,
          this.getPlatformType,
          getPlatformIdentifierValue(this.account, this.type)
        ).status
      )
    },
    getItemClasses() {
      switch (this.type) {
        case 'tumblr':
          return ['tum']
        case 'medium':
          return ['med']
        case 'shopify':
          return ['shopify']
        case 'facebook':
          return ['fb']
        case 'twitter':
          return ['tw']
        case 'linkedin':
          return ['lin']
        case 'pinterest':
          return ['pin']
        case 'instagram':
          return ['ins']
        case 'youtube':
          return ['you']
        case 'gmb':
          return ['google_b']
        default:
          return []
      }
    },
    getIconClasses() {
      switch (this.type) {
        case 'wordpress':
          return ['cs-wordpress']
        case 'tumblr':
          return ['cs-tumblr']
        case 'medium':
          return ['cs-medium']
        case 'shopify':
          return ['cs-shopify']
        case 'facebook':
          return ['cs-facebook']
        case 'twitter':
          return ['cs-twitter']
        case 'linkedin':
          return ['cs-linkedin']
        case 'pinterest':
          return ['cs-pinterest']
        case 'instagram':
          return ['cs-instagram']
        case 'youtube':
          return ['cs-youtube']
        case 'gmb':
          return ['fab fa-google']
        default:
          return []
      }
    },
    getPlatform() {
      const type = this.getPlatformType
      return this.blog_integrations.includes(type)
        ? getPlatformByIdentifier(
            this.$store.getters.getBlogs[type].items,
            'platform_identifier',
            this.item.blog_selection[type].website
          )
        : this.account
    },
    isTwitterPosting() {
      return this.type === 'twitter'
    },
    isLinkedinPosting() {
      return this.type === 'linkedin'
    },
    isPinterestPosting() {
      return this.type === 'pinterest'
    },
    isInstagramPosting() {
      return this.type === 'instagram'
    },
    isShowInstagramReelsWarning() {
      if (this.isInstagramPosting) {
        const details = this.item.common_box_status
          ? this.item.common_sharing_details
          : this.item.instagram_sharing_details
        if (
          details &&
          details.video &&
          details.video.link &&
          this.item.instagram_post_type &&
          this.item.instagram_post_type !== 'feed'
        ) {
          const posting = platformPosting(
            this.item,
            this.getPlatformType,
            getPlatformIdentifierValue(this.account, this.type)
          )
          return (
            posting &&
            posting.link &&
            posting.published_post_type &&
            posting.published_post_type === 'FEED'
          )
        }
      }
      return false
    },
    showShareStatistics() {
      return !this.isLinkedinPosting && !this.isInstagramPosting
    },
    isSendingPostNotification() {
      return this.post_notification_loader
    },
    resendNotificationAvailability() {
      const status = getStatusClass(this.item)
      const posting = platformPosting(
        this.item,
        this.type,
        getPlatformIdentifierValue(this.account, this.type)
      )
      if (posting && posting.zapier_published) return false
      if (posting && posting.api_published) return false
      return (
        this.isInstagramPosting &&
        (status === 'published' || status === 'failed')
      )
    },
    platformPostingObj() {
      return platformPosting(
        this.item,
        this.getPlatformType,
        getPlatformIdentifierValue(this.account, this.type)
      )
    },
    /**
     * If a posting can be re-tried due to failure.
     * @returns {*|boolean}
     */
    canRetryPosting() {
      return (
        this.platformPostingObj?.error &&
        !this.platformPostingObj?.job_failed &&
        !this.platformPostingObj?.limit_reached &&
        !this.platformPostingObj?.retrying
      )
    },
  },
  mounted() {
    if (this.item && this.item.posting && this.platformPostingObj) {
      this.threads_response = this.platformPostingObj.threaded_tweets
    }
  },
  methods: {
    ...importedMethods,
    async resendPostNotification(item, account) {
      console.debug('Method::resendPostNotification', item, account)
      this.post_notification_loader = true
      await this.$store.dispatch('sendPostNotification', {
        platform_identifier: account.instagram_id,
        planner_id: item._id,
        workspace_id: item.workspace_id,
      })
      this.post_notification_loader = false
    },
    showTweetsResponse() {
      EventBus.$emit(
        'tweets_detail_modal',
        this.item,
        this.threads_response,
        this.platformImage(this.getPlatform),
        this.getPlatformName(this.getPlatform)
      )
    },
    showCommentResponse() {
      console.log('options', this.item)
      let detail
      let youtubeOptions
      const response = platformPostingStatus(
        this.item,
        this.getPlatformType,
        getPlatformIdentifierValue(this.account, this.type)
      )
      const commentResponse = platformCommentPostingStatus(
        this.item,
        this.getPlatformType,
        getPlatformIdentifierValue(this.account, this.type)
      )
      if (this.item.common_box_status) {
        detail = this.item.common_sharing_details
        youtubeOptions = this.item.youtube_options
      } else {
        switch (this.type) {
          case 'linkedin':
            detail = this.item.linkedin_sharing_details
            break
          case 'instagram':
            detail = this.item.instagram_sharing_details
            break
          case 'facebook':
            detail = this.item.facebook_sharing_details
            break
          case 'youtube':
            detail = this.item.youtube_sharing_details
            youtubeOptions = this.item.youtube_options
            break
        }
      }

      console.debug(
        'props',
        this.type,
        detail,
        youtubeOptions,
        this.account,
        response,
        this.item.first_comment_message,
        commentResponse
      )
      EventBus.$emit(
        'first_comment_response',
        this.type,
        detail,
        youtubeOptions,
        this.account,
        response,
        commentResponse,
        this.item.first_comment_message
      )
    },
    /**
     * Click event for retrying posting
     * @returns {Promise<void>}
     */
    async retryPosting() {
      const payload = {
        posting_id: this.platformPostingObj._id,
        workspace_id: this.getWorkspaces?.activeWorkspace?._id,
      }
      this.retryLoader = true
      const postingIndex = this.item.posting.findIndex(
        (posting) => posting._id === payload.posting_id
      )
      if (postingIndex > -1)
        this.$set(this.item.posting[postingIndex], 'retrying', this.retryLoader)
      await proxy.post(retryPostPublishURL, payload).then(({ data }) => {
        const { status, message } = data
        if (status) {
          this.alertMessage(message, 'success')
        }
      })
      this.retryLoader = false
    },
  },
}
</script>

<template>
  <tr>
    <td>
      <div
        class="profile_picture d-flex align-items-center"
        :class="getItemClasses"
      >
        <div class="picture_block">
          <div v-if="type === 'shopify'" class="icon shopify_icon"></div>
          <div
            v-else
            :class="{
              youtube_bg: type === 'youtube',
              tiktok_bg: type === 'tiktok',
            }"
            class="icon"
          >
            <template v-if="type === 'tiktok'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 450 700"
                style="width: 10px"
              >
                <path
                  id="svg_1"
                  fill="#ffffff"
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                  class="selected"
                  fill-opacity="1"
                />
              </svg>
            </template>
            <i v-else :class="getIconClasses"></i>
          </div>
          <img
            :src="platformImage(getPlatform)"
            alt
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
          />
        </div>
        <div v-if="getPlatform" class="text_block blog_link">
          <p class="text">{{ getPlatformName(getPlatform) }}</p>
        </div>
      </div>
    </td>
    <td class>
      <p
        v-if="isInstagramPosting"
        v-html="
          zapierPostingError(
            item,
            getPlatformType,
            getPlatformIdentifierValue(account, type)
          )
        "
      ></p>
      <div style="display: flex">
        <p
          v-if="
            platformPostingObj &&
            platformPostingObj.error &&
            platformPostingObj.job_failed
          "
          class="flex"
        >
          <span class="status failed"></span
          ><span
            class="text"
            data-cy="plan_id_' +
        platform +
        '_status"
            >Failed [{{ platformPostingObj.error_message }}
          </span>
          <v-popover
            trigger="hover"
            placement="bottom-end"
            popover-class="first-comment__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#"
              ><img
                src="@assets/img/composer/help-icon.svg"
                alt=""
                class="w-5 h-5"
            /></a>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              The posts may have gone through to some social channels; please
              make sure to remove them before rescheduling to avoid duplicating
              posts.
            </template>
          </v-popover>
          ]
        </p>
        <p
          v-else-if="
            platformPostingObj &&
            platformPostingObj.error &&
            platformPostingObj.limit_reached
          "
        >
          <span class="status failed"></span
          ><span
            class="text"
            data-cy="plan_id_' +
        platform +
        '_status"
            >Failed [ You've reached the publishing limit on your trial account.
          </span>
          <a
            class="text-base"
            href="javascript:;"
            @click.prevent="showUpgradeModal"
            >Upgrade now! </a
          >]</p
        >
        <p
          v-else
          v-html="
            platformPostingStatus(
              item,
              getPlatformType,
              getPlatformIdentifierValue(account, type)
            )
          "
        ></p>
        <p
          v-if="
            platformPostingObj &&
            platformPostingObj.link &&
            platformPostingObj.first_comment
          "
          @click.prevent="showCommentResponse"
        >
          |
          <span class="btn-status">First Comment Status</span>
        </p>
        <p
          v-if="
            isTwitterPosting &&
            item.status === 'published' &&
            threads_response &&
            threads_response.length > 0
          "
          @click.prevent="showTweetsResponse"
        >
          |
          <span class="btn-status">Threaded Tweet Status</span>
        </p>
      </div>
      <span v-if="isShowInstagramReelsWarning" class="text-xs">
        The post was selected as a Reel but will not appear in the Reels tab due
        to Instagram's algorithm.
        <a
          href="#"
          data-beacon-article-modal="62e10979b828a850e9e61a35"
          target="_blank"
          >Learn more.</a
        >
      </span>

      <a
        v-if="
          (getPlatformType === 'tiktok' &&
            item.device_notification &&
            !item.notification_processed) !== false
        "
        class="resend-notification"
        target="_blank"
        href="https://docs.contentstudio.io/article/923-how-to-post-to-tiktok-using-content-studio"
        >learn more here</a
      >
      <a
        v-if="resendNotificationAvailability"
        class="resend-notification"
        href
        @click.prevent="resendPostNotification(item, account)"
        >Resend via mobile notification/reminder</a
      >
      <template v-if="resendNotificationAvailability">|</template>
      <!-- begin::retry posting-->
      <a
        v-if="canRetryPosting"
        class="resend-notification"
        href
        @click.prevent="retryPosting"
        >Retry Posting</a
      >
      <!--end::retry posting-->
      <clip-loader
        v-if="isSendingPostNotification"
        class="align-middle d-inline-block"
        color="#4165ed"
        :size="'16px'"
      ></clip-loader>
    </td>
    <template v-if="statisticsVisibilityStatus">
      <td class>
        <div
          class="statistics_item d-flex align-items-center justify-content-center"
        >
          <div class="s_row">
            <i v-tooltip.top-center="'Likes'" class="t_icon cs-thumbs-up"></i>
            <span class="text">{{
              platformPostingStats(
                item,
                getPlatformType,
                getPlatformIdentifierValue(account, type)
              ).likes
            }}</span>
          </div>
          <div v-if="isTwitterPosting" class="s_row">
            <i v-tooltip.top-center="'Retweet'" class="t_icon cs-refresh"></i>
            <span class="text">{{
              platformPostingStats(
                item,
                getPlatformType,
                getPlatformIdentifierValue(account, type)
              ).retweets
            }}</span>
          </div>
          <div v-if="isPinterestPosting" class="s_row">
            <i v-tooltip.top-center="'Repins'" class="t_icon cs-refresh"></i>
            <span class="text">{{
              platformPostingStats(
                item,
                getPlatformType,
                getPlatformIdentifierValue(account, type)
              ).repins
            }}</span>
          </div>
          <template v-if="!isTwitterPosting">
            <div v-if="showShareStatistics" class="s_row">
              <i v-tooltip.top-center="'Shares'" class="t_icon cs-share"></i>
              <span class="text">{{
                platformPostingStats(
                  item,
                  getPlatformType,
                  getPlatformIdentifierValue(account, type)
                ).shares
              }}</span>
            </div>
            <div class="s_row">
              <i
                v-tooltip.top-center="'Comments'"
                class="t_icon cs-comment"
              ></i>
              <span class="text">{{
                platformPostingStats(
                  item,
                  getPlatformType,
                  getPlatformIdentifierValue(account, type)
                ).comments
              }}</span>
            </div>
          </template>
        </div>
      </td>
    </template>
  </tr>
</template>

<style lang="less">
.resend-notification {
  padding: 3px 3px;
  font-size: 12px;
}
.btn-status {
  padding: 3px 3px;
  font-size: 12px;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #0056b3;
  }
}
</style>
