var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social_accounts_tooltip",class:_vm.channels_class},[(_vm.has_ids)?void 0:[_vm._l((_vm.accounts),function(items,channel_name){return [_vm._l((items),function(account){return [_c('div',{staticClass:"radio_input_image radio_social_profile"},[_c('input',{attrs:{"id":'select' + account._id,"type":"radio","name":"testprofile"}}),_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.channelTooltipHTML(channel_name, account),
              classes: 'team_tooltip',
            }),expression:"{\n              content: channelTooltipHTML(channel_name, account),\n              classes: 'team_tooltip',\n            }"}],staticClass:"radio_right",attrs:{"for":'select' + account._id}},[_c('div',{class:['profile_picture ' + channel_name + '-color']},[_c('div',{staticClass:"picture_block picture_block_background",class:{
                  icons_height_md: _vm.channels_size === '36px',
                  icons_height_lg: _vm.channels_size === '42px',
                }},[_c('div',{staticClass:"icon"},[(channel_name === 'tiktok')?[_c('svg',{staticStyle:{"width":"10px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 450 700"}},[_c('path',{staticClass:"selected",attrs:{"fill":"#ffffff","d":"M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z","id":"svg_1","fill-opacity":"1"}})])]:_c('i',{class:'cs-' + channel_name})],2),_c('div',{staticClass:"img",style:({
                    'background-image': _vm.channelBackgroundImage(
                      account,
                      channel_name,
                    ),
                  })})])])])])]})]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }