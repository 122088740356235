<template>
  <div class="analytics-post-card">
    <div class="analytics-post-card__header">
      <div class="analytics-post-card__header-left">
        <div class="analytics-post-card__header-img">
          <img :src="getImage(platform_type, post)" alt=""
               @error="$event.target.src = fallbackImagePreview(post.name[0]) "/>
        </div>
        <div class="analytics-post-card__header-detail">
          <h3>{{ getPostName(platform_type, post) }}</h3>
          <p class="mb-0"
          ><small
          ><b v-tooltip.top-center="getPostUsername(platform_type, post)"
          >{{ trimString(getPostUsername(platform_type, post), 20) }}
          </b>
            • {{ getDate(post) }}</small
          ></p
          >
        </div>
      </div>
      <div class="analytics-post-card__header-right">
        <!-- Dynamic Icon bases on props -->
        <a class="btn" :href="getPostLink(post)" target="_blank">
          <i class="far fa-external-link-square-alt"></i>
        </a>
        <!--        <i :class="getIconClass(platform_type)"></i>-->
      </div>
    </div>

    <div class="analytics-post-card__content">
      <p
          v-if="isPostTextAvailable(platform_type, post)"
          class="line-clamp-2"
          v-html="
            isHashtag(
              isLink(nextLineReplacement(getPostText(platform_type, post)))
            )
        "
          style="height: 3rem;"
      ></p>
      <div v-if="post.media_type == 'VIDEO'">
        <video
            class=""
            controls
        >
          <source
              :src="getPostImage(post)"
          />
          <source
              :src="getPostImage(post)"
              type="video/ogg"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div v-else>
        <img
            v-if="isPostImageAvailable(platform_type, post)"
            :src="getPostImage(post)"
            alt=""
            @error="
          $event.target.src =
            'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        "
        />
        <img
            v-else
            draggable="false"
            src="../../../../assets/imgs/no_data_images/not-found.png"
            alt="No Picture Found"
        />
      </div>
    </div>

    <ul class="analytics-post-card__content-ele">
      <li>
        <span>Total Engagements</span>
        <span
        ><b>{{ roudValues(post.engagement) }}</b></span
        >
      </li>
      <li>
        <span>{{ getPostLikesText(platform_type) }}</span>
        <span
        ><b>{{ roudValues(post.like_count) }}</b></span
        >
      </li>
      <li v-if="isPostCommentsAvailable(platform_type)">
        <span>Comments</span>
        <span
        ><b>{{ roudValues(getPostComments(platform_type, post)) }}</b></span
        >
      </li>
      <li v-if="isPostSharesAvailable(platform_type)">
        <span>{{ getPostSharesText(platform_type) }}</span>
        <span
        ><b>{{ roudValues(getPostShares(platform_type, post)) }}</b></span
        >
      </li>
      <li v-if="isPostImpressionsAvailable(platform_type)">
        <span>Total Post Reactions</span>
        <span
        ><b>{{ roudValues(post.total_post_reactions) }}</b></span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import {intToString} from '@common/lib/helper'
import moment from 'moment'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'PostCard',
  props: ['platform_type', 'post'],
  methods: {
    getTimeFormatted(timeString) {
      return moment(timeString).format('MMMM Do, YYYY hh:mm A')
    },
    getPostLink(obj) {
      return obj.permalink
    },
    getPostImage(obj) {
      if (obj.media_url) {
        return obj.media_url
      } else {
        const url =
            'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        return url
      }
    },
    trimString(string, maxLength = 100, start = 0) {
      // Note - `start` is if I want to start after some point of the string
      if (string && string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return (
            trimmedString.substr(
                start,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            ) + ' ...'
        )
      }
      return string
    },
    roudValues(value) {
      return intToString(value)
    },
    getDate(post) {
      return moment(post.created_at).format('llll')
    }
  }
}
</script>

<style scoped>
video {
  width: 100%;
  height: 16.25rem;
  overflow: hidden;
  border-radius: 0.625rem;
  object-fit: cover;
  margin-top: 1rem;

  /*@media (max-width: 1400px) {*/
  /*  width: 100px;*/
  /*  height: 100px;*/
  /*}*/

  /*@media (max-width: $media_1200) {*/
  /*  width: 140px;*/
  /*  height: 140px;*/
  /*}*/
}
</style>
