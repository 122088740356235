<script>
import DateRangePicker from 'vue2-daterange-picker'

import { mapGetters } from 'vuex'
import { social } from '../../../store/states/mutation-types'

import YoutubeVideoTags from './options/YoutubeVideoTags'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export default {
  components: {
    DateRangePicker,
    YoutubeVideoTags
  },

  data () {
    return {
      locale: {
        format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
      },
      opens: 'center',
      startDate: '',
      endDate: '',
      minDate: '',
      maxDate: '',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      hiddenInput: []
    }
  },
  computed: {
    ...mapGetters([
      'getGmbOptions',
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getGmbSharingDetails',
      'getYoutubeOptions',
      'getAccountSelection'
    ]),

    isCtaTopicSelection () {
      return this.getGmbOptions.topic_type === 'STANDARD'
    },

    isOfferTopicSelection () {
      return this.getGmbOptions.topic_type === 'OFFER'
    },

    showActionType () {
      return (
        this.getGmbOptions.action_type &&
        this.getGmbOptions.action_type !== 'CALL'
      )
    },

    isCommonBox () {
      return this.getCommonBoxOptions.status
    },
    getSelectedYoutubePlaylist () {
      const id = this.getAccountSelection.youtube[0]
      const selectedItem = this.getYoutubeAccounts.all_items.filter((item) => {
        // loop and check for IG details for selected ids
        return item.platform_identifier === id
      })
      return selectedItem[0].playlists
    }
  },
  methods: {
    updateStartDate (values) {
      console.debug('Method::updateStartDate', values)
      const myDate = moment(values.startDate)
      this.$store.commit(
        social.SET_GMB_OPTIONS_START_DATE,
        myDate.format('MMMM DD, YYYY HH:mm')
      )
      const endDate = moment(myDate, 'MMMM DD, YYYY HH:mm').add(15, 'days')
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        endDate.format('MMMM DD, YYYY HH:mm')
      )
    },

    updateEndDate (values) {
      console.debug('Method::updateEndDate', values)
      const myDate = moment(values.startDate)
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        myDate.format('MMMM DD, YYYY HH:mm')
      )
    }

    // addedTag:function(){
    //     console.log("!");
    // }
  },
  watch: {
    // hiddenInput: function () {
    //     console.log(this.hiddenInput);
    // }
  }
}
</script>
<template>
  <transition name="slide-bottom">
    <div class="youtube_options">
      <p class="p-3">Select YouTube Options</p>
      <div class="flex-row d-flex">
        <div class="col">
          <div class="field_group">
            <label class="label-animated">Video title</label>
            <input
              type="text"
              placeholder="Video title here..."
              v-model="getYoutubeOptions.title"
            />
          </div>
        </div>
      </div>

      <div class="flex-row d-flex">
        <div class="col">
          <div class="radio_tabs_buttons">
            <input
              type="radio"
              name="youtube_options_privacy_status"
              value="public"
              v-model="getYoutubeOptions.privacy_status"
              class="radio-tab"
              id="tab1"
              checked
            />
            <label for="tab1">Public</label>

            <input
              type="radio"
              name="youtube_options_privacy_status"
              value="private"
              v-model="getYoutubeOptions.privacy_status"
              class="radio-tab"
              id="tab2"
            />
            <label for="tab2">Private</label>
          </div>
        </div>
      </div>

      <div class="flex-row d-flex">
        <div class="col">
          <div class="field_group">
            <select v-model="getYoutubeOptions.category">
              <option disabled value="">Select Category - (optional)</option>
              <option value="2">Cars & Vehicles</option>
              <option value="23">Comedy</option>
              <option value="27">Education</option>
              <option value="24">Entertainment</option>
              <option value="1">Film & Animation</option>
              <option value="20">Gaming</option>
              <option value="26">How-to & Style</option>
              <option value="10">Music</option>
              <option value="25">News & Politics</option>
              <option value="29">Non-profits & Activism</option>
              <option value="22">People & Blogs</option>
              <option value="15">Pets & Animals</option>
              <option value="28">Science & Technology</option>
              <option value="17">Sport</option>
              <option value="19">Travel & Events</option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>

        <div class="col">
          <div class="field_group">
            <select v-model="getYoutubeOptions.playlist">
              <option disabled value="">Select Playlist - (Optional)</option>
              <option
                v-for="playlist in getSelectedYoutubePlaylist"
                :value="playlist.id"
                >{{ limitTextLength(playlist.name, 21) }}
              </option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>
      </div>

      <div class="flex-row d-flex">
        <div class="col">
          <youtube-video-tags></youtube-video-tags>
        </div>

        <div class="col">
          <div class="field_group">
            <select v-model="getYoutubeOptions.license">
              <option disabled value="">License</option>
              <option value="youtube">Standard YouTube Licence</option>
              <option value="creativeCommon"
                >Creative Commons - Attribution</option
              >
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>
      </div>

      <div class="flex-row d-flex">
        <div class="col">
          <div class="field_group">
            <div class="checkbox_container">
              <label for="youtube-options-embeddable" class="checkbox_left"
                >Embeddable
                <input
                  type="checkbox"
                  id="youtube-options-embeddable"
                  v-model="getYoutubeOptions.embeddable"
                />
                <span class="check check-sm"></span>
              </label>
            </div>

            <div class="checkbox_container">
              <label
                for="youtube-options-notify-subscribers"
                class="checkbox_left"
                >Notify subscribers
                <input
                  type="checkbox"
                  id="youtube-options-notify-subscribers"
                  v-model="getYoutubeOptions.notify_subscribers"
                />
                <span class="check check-sm"></span>
              </label>
            </div>

            <div class="checkbox_container">
              <label for="youtube-options-made-for-kids" class="checkbox_left"
                >Made for kids
                <input
                  type="checkbox"
                  id="youtube-options-made-for-kids"
                  v-model="getYoutubeOptions.made_for_kids"
                />
                <span class="check check-sm"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
