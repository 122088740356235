<script>
import {
  getPlatformByIdentifier,
  getPlatformName,
  platformImage,
  getPlatformIdentifierValue,
  getPlatformItemClasses,
  getPlatformIconClasses,
  getPlatformMutatedType,
  getPlatformDetails
} from '@common/lib/integrations'
import { itemSelectedPlatformVisibleStatus } from '@common/lib/planner'

const importedMethods = {
  getPlatformByIdentifier,
  getPlatformName,
  platformImage,
  getPlatformIdentifierValue,
  itemSelectedPlatformVisibleStatus,
  getPlatformItemClasses,
  getPlatformIconClasses,
  getPlatformMutatedType,
  getPlatformDetails
}
export default {
  props: ['item', 'type', 'platform'],
  created () {},
  computed: {
    itemVisibleStatus () {
      return !!(
        !this.platform ||
        itemSelectedPlatformVisibleStatus(
          this.item,
          getPlatformIdentifierValue(this.platform, this.type)
        )
      )
    }
  },
  methods: {
    ...importedMethods
  }
}
</script>
<template>
  <div
    class="profile_picture d-flex align-items-start"
    :class="getPlatformItemClasses(getPlatformMutatedType(type, platform))"
    v-if="itemVisibleStatus"
  >
    <div
      class="picture_block picture_block_background"
      v-tooltip.top-center="
        getPlatformName(getPlatformDetails(item, type, platform))
      "
    >
      <div class="icon shopify_icon" v-if="type === 'shopify'"></div>
      <div class="icon webflow_icon" v-else-if="type === 'webflow'"></div>
      <div class="icon tiktok_bg" v-else-if="type === 'tiktok'">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 612" style="width: 10px;">
          <path fill="#ffffff" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
        </svg>
      </div>
      <div v-else class="icon" :class="{ youtube_bg: type === 'youtube' }">
        <i
          :class="
            getPlatformIconClasses(getPlatformMutatedType(type, platform))
          "
        ></i>
      </div>
      <div
        class="img"
        :style="{
          'background-image': getBackgroundURLWithDefault(
            platformImage(getPlatformDetails(item, type, platform))
          ),
        }"
      ></div>
    </div>
  </div>
</template>
