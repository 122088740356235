import {
  getDefaultVideoSharingDetails,
  tumblrSharingTypes
} from '../mutation-types'

export const getDefaultTumblrSharingDetails = () => {
  return {
    message: '',
    image: '',
    video: getDefaultVideoSharingDetails(),
    url: ''
  }
}

export default {
  state: {
    selection: getDefaultTumblrSharingDetails(),
    cancelledLink: '',
    progressBar: {
      image: 0,
      video: 0
    }
  },
  actions: {
    setTumblrSharingImage ({ commit }, image) {
      commit('setTumblrSharingImage', image)
    },

    setTumblrSharingMessage ({ commit }, message) {
      commit('setTumblrSharingMessage', message)
    },

    setTumblrSharingLink ({ commit }, url) {
      commit('setTumblrSharingLink', url)
    },

    setTumblrSharingPreview ({ commit }, preview) {
      commit('setTumblrSharingPreview', preview)
    },

    setTumblrSharingDetails ({ commit }, data) {
      commit('setTumblrSharingDetails', data)
    },

    setDefaultTumblrSharing ({ commit }) {
      commit('setDefaultTumblrSharing')
    },

    setTumblrImagePercentage ({ commit }, percent) {
      commit('setTumblrImagePercentage', percent)
    },

    setTumblrCancelledLink ({ commit }, link) {
      commit('setTumblrCancelledLink', link)
    }
  },
  mutations: {
    setTumblrSharingImage (state, image) {
      state.selection.image = image
    },

    setTumblrSharingMessage (state, message) {
      state.selection.message = message
    },

    setTumblrSharingLink (state, url) {
      state.selection.url = url
    },

    setTumblrSharingPreview (state, preview) {
      if (preview) {
        state.selection.image = preview.image
      } else {
        state.selection.image = ''
      }
    },

    setTumblrSharingDetails (state, data) {
      if (data == null) {
        state.selection = getDefaultTumblrSharingDetails()
      } else {
        state.selection = data
      }
      state.cancelledLink = ''
    },

    setDefaultTumblrSharing (state) {
      state.selection = getDefaultTumblrSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        video: 0
      }
    },

    setTumblrImagePercentage (state, percent) {
      state.progressBar.image = percent
    },

    setTumblrCancelledLink (state, link) {
      state.cancelledLink = link
    },

    SET_TUMBLR_SHARING_VIDEO (state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultVideoSharingDetails()
      }
    },

    SET_TUMBLR_SHARING_VIDEO_INITIALIZATION (state) {
      state.selection.image = ''
      state.selection.url = ''
      state.selection.video = getDefaultVideoSharingDetails()
      state.progressBar.video = 0
    },

    SET_TUMBLR_SHARING_VIDEO_PERCENTAGE (state, percent) {
      state.progressBar.video = percent
    },

    SET_TUMBLR_SHARING_VIDEO_THUMBNAIL (state, thumbnail) {
      state.selection.video.thumbnail = thumbnail
    },
    SET_TUMBLR_SHARING_VIDEO_SUGGESTIONS (state, thumbnailsSuggestions) {
      state.selection.video.thumbnails_suggestions = thumbnailsSuggestions
    },

    [tumblrSharingTypes.RESET_TUMBLR_SHARING_DETAILS] (state) {
      state.selection = getDefaultTumblrSharingDetails()
      state.progressBar = {
        image: 0,
        video: 0
      }
    }
  },
  getters: {
    getTumblrSharingDetails: (state) => {
      return state.selection
    },

    getTumblrSharingCancelledLink: (state) => {
      return state.cancelledLink
    },

    getTumblrSharingProgressBar: (state) => {
      return state.progressBar
    }
  }
}
