var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"linkedin_tab"}},[_c('div',{staticClass:"linkedin_post_preview"},[_c('div',{staticClass:"linkedin_post_preview__head"},[_c('div',{staticClass:"linkedin_post_preview__head__profile_picture"},[(_vm.planner)?[_c('div',{staticClass:"linkedin_post_preview__head__profile_picture__picture_block"},[(_vm.linkedinImage(_vm.data.account))?_c('img',{staticStyle:{"border":"1px solid #eff4f8"},attrs:{"src":_vm.linkedinImage(_vm.data.account),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}):_c('img',{attrs:{"src":require("../../../../../../assets/img/profile_default.svg"),"alt":""}})]),_c('div',{staticClass:"linkedin_post_preview__head__profile_picture__text_block"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.account.name))])])]:[_c('div',{staticClass:"linkedin_post_preview__head__profile_picture__picture_block"},[(_vm.linkedinImage(_vm.getPreviewAccount.linkedin))?_c('img',{staticStyle:{"border":"1px solid #eff4f8"},attrs:{"src":_vm.linkedinImage(_vm.getPreviewAccount.linkedin),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}}):_c('img',{attrs:{"src":require("../../../../../../assets/img/profile_default.svg"),"alt":""}})]),_c('div',{staticClass:"linkedin_post_preview__head__profile_picture__text_block"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.getPreviewAccount.linkedin.name))]),_c('p',{staticClass:"sub_text"},[_vm._v(_vm._s(_vm.getPreviewAccount.linkedin.headline))])])]],2)]),_c('div',{staticClass:"linkedin_post_preview__preview_desc"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.processSharingPreviewDesciption(_vm.details.message, 'Linkedin'))}})]),(_vm.details.url)?_c('div',{staticClass:"preview_link"},[(
          (_vm.getCommonBoxOptions.status &&
            _vm.getPublishLoaders.fetchCommonSharingPreview) ||
          _vm.getPublishLoaders.fetchLinkedinSharingPreview
        )?_c('clip-loader',{attrs:{"color":'#ffffff',"size":'16px'}}):[(_vm.details.image && _vm.details.image.length > 0)?_c('div',{staticClass:"img",style:({
            'background-image':
              'url(' + _vm.getResizedImageURL(_vm.details.image[0], 500, 0) + ')',
          }),on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`}}}):_vm._e(),_c('div',{staticClass:"link_detail"},[_c('h3',[_vm._v(_vm._s(_vm.details.title))]),_c('p',{staticClass:"link"},[_vm._v(_vm._s(_vm.details.website))])])]],2):(_vm.details.video && _vm.details.video.link)?[_c('div',{staticClass:"social_preview_block__social_preview_inner__social_post_preview__preview_video"},[_c('div',{staticClass:"img",style:({
            'background-image':
              'url(' + _vm.getValidThumbnail(_vm.details.video.thumbnail) + ')',
          })},[_vm._m(0)])])]:(_vm.details.image.length > 0)?_c('div',{staticClass:"linkedin_post_preview__preview_images"},[(_vm.details.image.length === 1)?_c('img',{staticClass:"single_image",attrs:{"src":_vm.details.image[0],"alt":""}}):(_vm.details.image.length === 2)?_c('div',{staticClass:"multiple_images two_images"},_vm._l((_vm.details.image),function(image,index){return _c('div',{staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})}),0):(_vm.details.image.length === 3)?_c('div',{staticClass:"multiple_images three_images"},_vm._l((_vm.details.image),function(image,index){return _c('div',{staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })})}),0):_c('div',{staticClass:"multiple_images more_than_three"},_vm._l((_vm.details.image),function(image,index){return _c('div',{key:`facebook_preview_multiple_${index}`},[(index < 4)?_c('div',{staticClass:"img",style:({ 'background-image': 'url(' + image + ')' })},[(_vm.details.image.length > 4 && index === 3)?_c('div',{staticClass:"preview_more_images"},[_vm._v(" +"+_vm._s(_vm.details.image.length - 4)+" ")]):_vm._e()]):_vm._e()])}),0)]):_vm._e(),_vm._m(1),(_vm.planner)?[_c('div',{staticClass:"first-comment-preview"},[_c('div',{staticClass:"first-comment-preview__img"},[_c('img',{staticStyle:{"border":"1px solid #eff4f8"},attrs:{"src":_vm.linkedinImage(_vm.data.account),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}})]),_c('div',{staticClass:"first-comment-preview__text",domProps:{"innerHTML":_vm._s(_vm.n2br(_vm.data.message))}}),_c('br')]),(_vm.data.commentResponse)?_c('div',{staticClass:"comments-status-box"},[_c('p',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.data.commentResponse)}})]):_vm._e()]:[(
          _vm.getSocialSharingFirstComment.has_first_comment &&
          _vm.getSocialSharingFirstComment.first_comment_message.trim().length &&
          _vm.hasEnableFirstComment(_vm.getPreviewAccount.linkedin.linkedin_id) &&
          !_vm.planner
        )?_c('div',{staticClass:"first-comment-preview"},[_c('div',{staticClass:"first-comment-preview__img"},[_c('img',{staticStyle:{"border":"1px solid #eff4f8"},attrs:{"src":_vm.linkedinImage(_vm.getPreviewAccount.linkedin),"alt":""},on:{"error":function($event){$event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`}}})]),_c('div',{staticClass:"first-comment-preview__text",domProps:{"innerHTML":_vm._s(_vm.n2br(_vm.getSocialSharingFirstComment.first_comment_message))}})]):_vm._e()]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkedin_vid_icon vid_icon"},[_c('span',{staticClass:"icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rection_icons facebook_r"},[_c('span',[_c('i',{staticClass:"far fa-thumbs-up"}),_vm._v(" Like ")]),_c('span',[_c('i',{staticClass:"far fa-comment-alt"}),_vm._v(" Comments ")]),_c('span',[_c('i',{staticClass:"fas fa-share"}),_vm._v(" Share ")])])
}]

export { render, staticRenderFns }