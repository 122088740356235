<template>
  <div
    class="show modal composerOnboaring normal_modal"
    id="composerOnboaring"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!--<div class="modal_head d-flex align-items-center">-->
        <!--<h2>Create Campaign</h2>-->
        <!--<button type="button" class="close" id="close" data-dismiss="modal">&times;</button>-->
        <!--</div>-->
        <div class="modal_body basic_form">
          <h2
            >To begin using the publisher, connect a social network
            <span class="bold"
              >(Facebook, Twitter, LinkedIn, Pinterest, Tumblr)</span
            >
            or a blog channel
            <span class="bold">(WordPress, Medium, Tumblr)</span>.</h2
          >
          <div class="btn_block">
            <button class="btn btn_round border_btn large_btn"
              ><span>Connect a Social Network</span></button
            >
            <div class="or_separator">
              <span class="sep_text">OR</span>
            </div>
            <button class="btn btn_round border_btn large_btn">
              <span>Connect a Blog</span>
            </button>
          </div>
          <p class="sub_text"
            >Watch the video blow to see what you can do with Publisher</p
          >
          <div class="video_block embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/uB6Mm9DoGoY"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([''])
  },
  methods: {
    ...mapActions([])
  }
}
</script>
