var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discovery_main_component fixed_section_top_bar"},[_c('SocialConfirmBox',{staticClass:"discovery_socialConfirm_modal"}),_c('CreatePublicationFolder'),(
      !_vm.$route.name.includes('feeder') &&
      !_vm.$route.name.includes('feed_item_social_share')
    )?[_c('SocialShare'),_c('BlogShare'),_c('UploadMediaModal',{attrs:{"type":"BlogEditorFile"}}),_c('InsertFileModal',{staticClass:"discovery_insertFile_modal",attrs:{"type":"BlogEditorFile"}}),_c('PasteFromWord',{staticClass:"discovery_pasteWord_modal"}),_c('ReplaceGifConfirmation'),_c('ReplaceCurrentTypeConfirmation'),_c('ReplaceImageConfirmation'),_c('SocialApprovalModal')]:(_vm.$route.name.includes('feed_item_social_share'))?[_c('SocialApprovalModal')]:_vm._e(),_c('VideoModal',{attrs:{"id":"create-custom-topic","position":"bottom_right"}},[_c('template',{slot:"heading"},[_c('h2',[_vm._v("Get Started With Custom Topic Creation")])]),_c('template',{slot:"description"}),_c('template',{slot:"iframe"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":"https://www.youtube.com/embed/NwQR4LrmAY4","frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])],2),_c('div',{staticClass:"component_inner"},[(!_vm.$route.path.includes('/discovery/influencers/'))?_c('div',{staticClass:"section_top_bar"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"links"},[_c('li',[_c('router-link',{staticClass:"route-btn",attrs:{"tag":"button","disabled":_vm.$route.matched.some(
                    ({ name }) => name === 'discover_topics',
                  ),"to":{ name: 'discover_topics' },"data-cy":"discover-topics"}},[_vm._v("Topics ")])],1),_c('li',[_c('router-link',{staticClass:"route-btn",class:{
                  'active animate':
                    _vm.$route.meta.is_route &&
                    _vm.$route.meta.is_route === 'discover_search',
                },attrs:{"tag":"button","to":{ name: 'discover_search' },"data-cy":"discover-search"},nativeOn:{"click":function($event){return _vm.navigateToSearch.apply(null, arguments)}}},[_vm._v(" Search ")])],1),_c('li',{staticClass:"beta-li-tag"},[_c('router-link',{staticClass:"route-btn",attrs:{"tag":"button","disabled":_vm.$route.matched.some(({ name }) => name === 'feeder'),"to":{ name: 'feeder' },"data-cy":"discover-feeds"}},[_vm._v("Feeds ")])],1)])]),_c('div',{staticClass:"right ml-auto d-flex"},[(
              !_vm.$route.path.includes('/discovery/content/discover_search') &&
              !_vm.$route.path.includes('/discovery/content/discover_insights')
            )?_c('div',{staticClass:"manage-sources-btn"},[_c('div',{staticClass:"manage-btn",on:{"click":function($event){$event.preventDefault();return _vm.goToSources.apply(null, arguments)}}},[_c('i',{staticClass:"title_icon title_icon_gray icon-settings-cs"}),_c('span',{staticClass:"text"},[_vm._v("Manage Sources ")])])]):_vm._e(),_c('ComposerDropdown')],1)])]):_vm._e(),_c('router-view')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }