<template>
  <LimitExceeds v-if="isLimitExceeded" modal_heading="AI Images" />
  <div
    v-else
    class="search-prompt relative w-full min-w-[30rem] max-w-[30rem] overflow-y-auto flex flex-col px-4 pt-2 pb-4"
  >
    <div class="max-h-[86%] h-full overflow-y-auto mt-2 px-2">
      <span class="font-normal text-black-300 block mb-2 text-[0.75rem]"
        >Here's a detailed guide on
        <a
          href="https://dallery.gallery/wp-content/uploads/2022/07/The-DALL%C2%B7E-2-prompt-book-v1.02.pdf"
          target="_blank"
          >prompt generation for AI images</a
        ></span
      >
      <div class="mt-2">
        <textarea
          ref=""
          v-model="promptDetails.prompt"
          :placeholder="placeHolderText"
          rows="15"
          class="resize-none rounded-xl w-full p-3 border-0 color-border cst-caption-editor"
          :maxlength="limits.promptLength"
        >
        </textarea>
        <span class="text-xs block w-100 text-right text-gray-700"
          >Maximum 1000 characters</span
        >
      </div>
      <span class="font-normal text-sm text-black-300 block mt-2"
        >Image dimensions</span
      >
      <div class="flex justify-between w-[75%]">
        <div class="mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.resolution"
              type="radio"
              value="256x256"
              name="image_resolution"
            />
            <span class="check_btn"> 256 x 256 </span>
          </label>
        </div>
        <div class="mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.resolution"
              type="radio"
              value="512x512"
              name="image_resolution"
            />
            <span class="check_btn"> 512 x 512 </span>
          </label>
        </div>
        <div class="mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.resolution"
              type="radio"
              value="1024x1024"
              name="image_resolution"
            />
            <span class="check_btn"> 1024 x 1024 </span>
          </label>
        </div>
      </div>

      <div class="mt-6 mb-8">
        <span class="font-normal text-sm text-black-300 block"
          >Number of Images</span
        >
        <div class="inline-block mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.quantity"
              type="radio"
              value="1"
              name="variations"
            />
            <span class="check_btn"> 1 </span>
          </label>
        </div>
        <div class="inline-block mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.quantity"
              type="radio"
              value="2"
              name="variations"
            />
            <span class="check_btn"> 2 </span>
          </label>
        </div>
        <div class="inline-block mr-16 mb-5">
          <label class="variation_input">
            <input
              v-model="promptDetails.quantity"
              type="radio"
              value="3"
              name="variations"
            />
            <span class="check_btn"> 3 </span>
          </label>
        </div>
      </div>
      <span v-if="promptErrors.quantity" class="text-red-700"
        >Invalid quantity (1-3)</span
      >
    </div>
    <div class="absolute bottom-1 left-0 px-4 py-5 w-full">
      <CstButton
        class="w-full"
        size="default"
        :disabled="
          promptErrors.quantity ||
          promptErrors.prompt ||
          isGenerating ||
          mediaUploading
        "
        :loading="isGenerating"
        @click="generateImages"
      >
        <i class="fa fa-magic mx-2"></i>
        <span class="">Generate</span>
      </CstButton>
    </div>
  </div>
</template>

<script>
// libraries
import { computed, defineComponent } from 'vue'
import { useStore } from '@state/base'

// components
import CstRadio from '@ui/Radio/CstRadio.vue'
import CstButton from '@ui/Button/CstButton.vue'
import LimitExceeds from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/LimitExceeds.vue'

// constants

export default defineComponent({
  name: 'SearchPrompt',
  components: { CstRadio, CstButton, LimitExceeds },
  props: {
    promptDetails: {
      type: Object,
      default: () => ({
        prompt: '',
        resolution: '512 x 512',
        quantity: '3',
      }),
    },
    generateImages: {
      type: Function,
      default: () => {},
    },
    promptErrors: {
      type: Object,
      default: () => ({
        prompt: true,
        quantity: true,
      }),
    },
    limits: {
      type: Object,
      default: () => {},
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
    mediaUploading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()
    const placeHolderText =
      'Write description of the image you would like to generate.\n\nThe more detailed description you provide, the more accurate the generated images will be. Mention Style (abstract, cartoon etc.), Medium (canvas, charcoal etc.), Artist style (Pablo Picasso etc.), Mood (ambient light, realistic etc.)\n\nFor example:\n“Image of a mountain bike, realistic, morning light, epic landscape, art, perfect composition, photorealistic concept art, soft natural volumetric cinematic perfect light, with an abstract art style, painted on canvas with a Pablo Picasso-inspired artist style, and a tranquil mood with ambient lighting.'
    // computed properties
    /**
     * check if user credits limits exceeds
     * @type {ComputedRef<unknown>}
     */
    const isLimitExceeded = computed(() => {
      if (store.getters.getPlan.subscription.limits?.image_generation_credit)
        return (
          store.getters.getPlan.used_limits.image_generation_credit >=
          store.getters.getPlan.subscription.limits.image_generation_credit
        )
      return true
    })
    // methods
    /**
     * change image resolution
     * @param value
     */
    const changeImageResolution = (value) => {
      props.promptDetails.resolution = value
    }

    return {
      changeImageResolution,
      isLimitExceeded,
      placeHolderText,
    }
  },
})
</script>

<style lang="scss" scoped>
.search-prompt {
  .variation_input {
    position: relative;

    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      opacity: 0;
    }

    .check_btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 6px 20px;
      border-radius: 6px;
      font-weight: 400;
      font-size: 0.9rem;
      width: max-content;
      border: 1px solid #eee;
    }

    &:hover .check_btn {
      background-color: #f2f4f6;
      cursor: pointer;
    }

    input[type='radio']:checked ~ .check_btn {
      border: 1px solid #2196f3;
      background-color: #2196f30a;
      color: #2196f3;
    }
  }
  .cst-caption-editor {
    &:hover,
    &:focus {
      border-color: #409aff;
      box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
    }

    textarea::placeholder {
      color: #a4a8ac;
      font-size: 0.875rem;
    }
  }
}
</style>
