<template>
  <div id="facebook_tab">
    <div class="facebook_post_preview">
      <div class="facebook_post_preview__head">
        <div class="facebook_post_preview__head__profile_picture">
          <template v-if="planner">
            <div
              class="facebook_post_preview__head__profile_picture__picture_block"
            >
              <img
                v-if="facebookImage(data.account)"
                :src="facebookImage(data.account)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <img
                v-else
                src="../../../../../../assets/img/profile_default.svg"
                alt=""
              />
            </div>
            <div
              class="facebook_post_preview__head__profile_picture__text_block"
            >
              <p class="text">{{ data.account.name }}</p>
              <p class="sub_text">{{
                getWorkspaceTimezonCurrentTime('D MMM YYYY')
              }}</p>
            </div>
          </template>

          <template v-else>
            <div
              class="facebook_post_preview__head__profile_picture__picture_block"
            >

              <img v-if="commonMethods.getFBGroupPostedAsAccount(getPreviewAccount.facebook, getFacebookPostedAs) !== ''"
                   :src="commonMethods.getFBGroupPostedAsAccount(getPreviewAccount.facebook, getFacebookPostedAs).image" alt=""
                   style="border: 1px solid #eff4f8;"
                   @error="$event.target.src=`https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
              >

              <img v-else-if="facebookImage(getPreviewAccount.facebook)"
                :src="facebookImage(getPreviewAccount.facebook)"
                alt=""
                style="border: 1px solid #eff4f8"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <img
                v-else
                src="../../../../../../assets/img/profile_default.svg"
                alt=""
              />
            </div>
            <div
              class="facebook_post_preview__head__profile_picture__text_block"
            >
              <p class="text">
                <span v-if="commonMethods.getFBGroupPostedAsAccount(getPreviewAccount.facebook, getFacebookPostedAs) !== ''" class="p_text">
                  {{ commonMethods.getFBGroupPostedAsAccount(getPreviewAccount.facebook, getFacebookPostedAs).name }}
                  <i class="fa fa-caret-right"></i>
                </span> {{getPreviewAccount.facebook.name}}</p>
              <p class="sub_text">{{
                getWorkspaceTimezonCurrentTime('D MMM YYYY')
              }}</p>
            </div>
          </template>
        </div>
      </div>

      <div
        class="facebook_post_preview__preview_desc"
        v-html="processSharingPreviewDesciption(details.message, getBoxType)"
      >
      </div>

      <div class="location_preview" v-if="getLocationName">
        <h3>
          <img src="../../../../../../assets/img/locate.png" />
          {{ getLocationName }}
        </h3>
      </div>

      <div class="preview_link" v-if="details.url">
        <clip-loader
          :color="'#ffffff'"
          :size="'16px'"
          v-if="
            (getCommonBoxOptions.status &&
              getPublishLoaders.fetchCommonSharingPreview) ||
            getPublishLoaders.fetchFacebookSharingPreview
          "
        ></clip-loader>
        <template v-else>
          <!-- <div class="img" v-if="details.image.length > 0"
                             :style="{ 'background-image': 'url(' + getResizedImageURL(details.image[0], 500 , 0) + ')' }"></div> -->
          <img
            draggable="false"
            class="img"
            v-if="details.image && details.image.length > 0"
            :src="getResizedImageURL(details.image[0], 500, 0)"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
          />

          <div class="link_detail">
            <p class="link" v-html="details.website"></p>
            <h3 v-html="details.title"></h3>
            <p class="desc" v-html="details.description"></p>
          </div>
        </template>
      </div>

      <template v-else-if="details.video && details.video.link">
        <div
          class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
        >
          <div
            class="img"
            :style="{
              'background-image':
                'url(' +
                getResizedImageURL(details.video.thumbnail, 500, 0) +
                ')',
            }"
          >
            <div class="facebook_vid_icon vid_icon">
              <span class="icon"></span>
            </div>
          </div>
        </div>
      </template>

      <div
        v-else-if="details.image.length > 0"
        class="facebook_post_preview__preview_images"
      >
        <!--..........for single image...............-->
        <img
          v-if="details.image.length === 1"
          class="single_image"
          :src="details.image[0]"
          alt=""
        />

        <!--..........for two images...............-->

        <div
          v-else-if="details.image.length === 2"
          class="multiple_images two_images"
        >
          <div
            class="img"
            :key="`facebook_preview_${index}`"
            v-for="(image, index) in details.image"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>

        <!--..........for three images...............-->

        <div
          v-else-if="details.image.length === 3"
          class="multiple_images three_images"
        >
          <div
            class="img"
            :key="`facebook_preview_multiple_${index}`"
            v-for="(image, index) in details.image"
            :style="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>

        <!--..........for  more than images...............-->
        <div v-else class="fr_images">
          <div
            :key="`facebook_preview_multiple_${index}`"
            v-for="(image, index) in details.image"
          >
            <div
              class="img"
              v-if="index < 4"
              :style="{ 'background-image': 'url(' + image + ')' }"
            >
              <div
                class="preview_more_images"
                v-if="details.image.length > 4 && index === 3"
              >
                +{{ details.image.length - 4 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rection_icons facebook_r">
        <span><i class="far fa-thumbs-up"></i> Like </span>
        <span><i class="far fa-comment-alt"></i> Comments </span>
        <span><i class="fas fa-share"></i> Share </span>
      </div>
      <!--  First Comment Preview  -->
      <template v-if="planner">
        <div class="first-comment-preview">
          <div class="first-comment-preview__img">
            <img
              :src="facebookImage(data.account)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div class="first-comment-preview__text" v-html="n2br(data.message)">
          </div>
        </div>
        <div v-if="data.commentResponse" class="comments-status-box">
          <p class="ml-2" v-html="data.commentResponse"></p>
        </div>
      </template>
      <template v-else>
        <div
          class="first-comment-preview"
          v-if="
            getSocialSharingFirstComment.has_first_comment &&
            getSocialSharingFirstComment.first_comment_message.trim().length &&
            hasEnableFirstComment(getPreviewAccount.facebook.facebook_id)
          "
        >
          <div class="first-comment-preview__img">
            <img
              :src="facebookImage(getPreviewAccount.facebook)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div
            class="first-comment-preview__text"
            v-html="n2br(getSocialSharingFirstComment.first_comment_message)"
          >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.fr_images {
  .img {
    cursor: pointer;
    float: left;
    width: 50%;
    height: 200px;
    display: block;
    margin-bottom: 1px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }
}
</style>
<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'
import { commonMethods } from '@common/store/common-methods'

export default {
  props: ['details', 'planner', 'data'],
  data () {
    return {
      commonMethods: commonMethods
    }
  },
  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    hasEnableFirstComment (id) {
      return (
        this.getSocialSharingFirstComment.first_comment_accounts &&
        this.getSocialSharingFirstComment.first_comment_accounts.includes(id)
      )
    }
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getPreviewAccount',
      'getPublishLoaders',
      'getSocialSharingFirstComment',
      'getFacebookPostedAs'
    ]),
    getBoxType () {
      return this.getCommonBoxOptions.status ? 'Common' : 'Facebook'
    },
    getLocationName () {
      if (this.details.location && this.details.location.id) {
        return this.getBoxType === 'Common'
          ? this.details.location.facebook.name
          : this.details.location.name
      }
      return ''
    }
  }
}
</script>
