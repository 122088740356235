<template>
  <div class="h-100 d-flex flex-column">
    <InboxConversationHead
      :active-inbox-detail="state.activeInboxData"
    ></InboxConversationHead>

    <div class="conversation_messages">
      <div
        v-if="state.activeInboxData !== null"
        class="message m_in _comment_post"
      >
        <div class="profile_picture d-flex align-items-start">
          <div class="text_block w-100">
            <div class="__text">
              <div class="_comment">
                <div class="inline w-full">
                  <p class="text-xs text-gray-900">
                    <strong>{{ state.gmbAccountData.location_name }}:</strong>
                    {{ state.gmbAccountData.platform_name }}</p
                  >
                </div>
                <hr class="m-0 hr-color" />
                <div
                  class="flex flex-row justify-start items-center mt-3"
                  :class="getPlatformNameColor(state.activeInboxData.platform)"
                >
                  <b-avatar
                    variant="light"
                    size="lg"
                    :src="state.activeInboxData.inbox_details.posted_by.image"
                    alt=""
                    @error="
                      $event.target.src = fallbackImagePreview(
                        state.activeInboxData.inbox_details.posted_by
                          ? state.activeInboxData.inbox_details.posted_by[0].toUpperCase()
                          : ''
                      )
                    "
                  ></b-avatar>
                  <p class="ml-2 font-bold">{{
                    state.activeInboxData.inbox_details.posted_by.name
                  }}</p>
                </div>

                <div class="flex flex-row mt-3">
                  <div class="flex items-center">
                    <span
                      v-for="(star, index) in setStars(
                        state.activeInboxData.element_details.rating
                      )"
                      :key="index"
                      class="flex items-center"
                    >
                      <svg
                        v-if="star"
                        aria-hidden="true"
                        class="w-5 h-5 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Rating {{ index + 1 }}</title>
                        >
                        <path
                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        ></path>
                      </svg>
                      <svg
                        v-else
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-300 dark:text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Rating {{ index + 1 }}</title>
                        <path
                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <p class="ml-3 text-gray-700">
                    {{
                      commonMethod.timesAgo(
                        state.activeInboxData.element_details.updated_time
                      )
                    }}
                  </p>
                </div>

                <div class="desc whitespace-pre-line">
                  <h2
                    v-if="state.activeInboxData.element_details.post_message"
                    v-html="
                      stringToHtml(
                        state.activeInboxData.element_details.post_message
                      )
                    "
                  ></h2>
                </div>

                <div class="pt-2.5">
                  <GmbReplyBox :active-inbox-detail="state.activeInboxData">
                  </GmbReplyBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InboxConversationHead from '@src/modules/inbox/components/InboxConversationHead'
import GmbReplyBox from '@src/modules/inbox/components/reusable/gmb-review/GmbReplyBox'
import { computed, reactive, watch } from 'vue'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import { GMB_RATINGS } from '@src/modules/inbox/config/constants'
import { socialChannelsArray } from '@src/modules/inbox/store/inbox-filters.js'
import { useStore } from '@state/base'

export default {
  components: {
    InboxConversationHead,
    GmbReplyBox,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const { getters } = useStore()
    const state = reactive({
      activeInboxData: computed(() => props.activeInboxDetail),
      allGmbAccounts: null,
      gmbAccountData: null,
      gmbGetter: null,
    })
    const commonMethod = commonMethods

    state.gmbGetter = socialChannelsArray.find((item) => item.name === 'gmb')
    state.allGmbAccounts = getters[state.gmbGetter.getter].items
    state.gmbAccountData = state.allGmbAccounts.find(
      (inbox) => inbox.name === state.activeInboxData.platform_id
    )
    console.log('GMB::ACC', state.gmbAccountData)

    watch(
      () => state.activeInboxData.element_details.element_id,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          console.log('GMB:: WATCHER: ')
          state.gmbAccountData = state.allGmbAccounts.find(
            (inbox) => inbox.name === state.activeInboxData.platform_id
          )
          console.log('GMB::ACC', state.gmbAccountData)
        }
      }
    )

    const getPlatformNameColor = (platform) => {
      switch (platform) {
        case 'facebook':
          return 'fb'
        case 'twitter':
          return 'tw'
        case 'instagram':
          return 'ins'
        case 'gmb':
          return 'gmb'
      }
    }
    const classes = 'd-flex align-items-center  justify-content-center __icon'
    const getPlatformName = (platform) => {
      switch (platform) {
        case 'facebook':
          return `${classes} fab fa-facebook-f`
        case 'twitter':
          return `${classes} fab fa-twitter`
        case 'instagram':
          return `${classes} fab fa-instagram`
        case 'gmb':
          return `${classes} icon-GMB`
      }
    }

    const ratingValue = (rating) => {
      switch (rating) {
        case GMB_RATINGS.ONE:
          return 1
        case GMB_RATINGS.TWO:
          return 2
        case GMB_RATINGS.THREE:
          return 3
        case GMB_RATINGS.FOUR:
          return 4
        case GMB_RATINGS.FIVE:
          return 5
      }
    }
    const setStars = (stars) => {
      const ratingList = new Array(5).fill(false)
      for (let i = 0; i < ratingValue(stars); i++) {
        ratingList[i] = true
      }
      return ratingList
    }

    return {
      state,
      getPlatformNameColor,
      getPlatformName,
      commonMethod,
      ratingValue,
      setStars,
    }
  },
}
</script>
