import { recipeScheduleTypes } from '@src/modules/automation/store/recipes/mutation-types'

const getDefaultScheduleSelection = () => {
  return {
    time_type: 'Recurring',
    recurring: {
      type: 'Hour',
      value: 1
    },
    custom: {
      days: [
        {
          name: 'Sun',
          status: true,
          times: ['18:00']
        },
        {
          name: 'Mon',
          status: true,
          times: ['09:50', '16:15']
        },
        {
          name: 'Tue',
          status: true,
          times: ['16:05', '18:40']
        },
        {
          name: 'Wed',
          status: true,
          times: ['13:50', '15:40', '19:20']
        },
        {
          name: 'Thu',
          status: true,
          times: ['14:20', '16:10', '18:40']
        },
        {
          name: 'Fri',
          status: true,
          times: ['09:40', '16:05']
        },
        {
          name: 'Sat',
          status: true,
          times: ['16:10']
        }
      ]
    },
    schedule_type: 'continuously',
    start_time: '',
    end_time: ''
  }
}

export default {
  state: {
    selection: getDefaultScheduleSelection()
  },
  actions: {
    setRecurringValue ({ commit }, value) {
      commit('setRecurringValue', value)
    },

    setAutomationScheduleSlots ({ commit }, slots) {
      commit('setAutomationScheduleSlots', slots)
    },
    setAutomationStartTime ({ commit }, startTime) {
      commit('setAutomationStartTime', startTime)
    },

    setAutomationEndTime ({ commit }, endTime) {
      commit('setAutomationEndTime', endTime)
    },

    setAutomationScheduleOptions ({ commit }, selection) {
      commit('setAutomationScheduleOptions', selection)
    }
  },
  mutations: {
    setRecurringValue (state, value) {
      state.selection.recurring.value = value
    },

    setAutomationScheduleSlots (state, slots) {
      state.selection.custom.days = slots
    },

    setAutomationStartTime (state, startTime) {
      state.selection.start_time = startTime
    },

    setAutomationEndTime (state, endTime) {
      state.selection.end_time = endTime
    },

    setAutomationScheduleOptions (state, selection) {
      if (selection) {
        state.selection = selection
      } else {
        state.selection = {
          time_type: 'Recurring',
          recurring: {
            type: 'Hour',
            value: 1
          },
          custom: {
            days: [
              {
                name: 'Sun',
                status: true,
                times: ['18:00']
              },
              {
                name: 'Mon',
                status: true,
                times: ['09:50', '16:15']
              },
              {
                name: 'Tue',
                status: true,
                times: ['16:05', '18:40']
              },
              {
                name: 'Wed',
                status: true,
                times: ['13:50', '15:40', '19:20']
              },
              {
                name: 'Thu',
                status: true,
                times: ['14:20', '16:10', '18:40']
              },
              {
                name: 'Fri',
                status: true,
                times: ['09:40', '16:05']
              },
              {
                name: 'Sat',
                status: true,
                times: ['16:10']
              }
            ]
          },
          schedule_type: 'continuously',
          start_time: '',
          end_time: ''
        }
      }
    },

    [recipeScheduleTypes.RESET_AUTOMATION_SCHEDULE_OPTIONS] (state) {
      state.selection = getDefaultScheduleSelection()
    }
  },
  getters: {
    getAutomationScheduleOptions: (state) => {
      return state.selection
    }
  }
}
