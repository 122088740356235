<template>
  <div>
    <div class="modal_head d-flex align-items-center p-0">
      <div class="head_left">
        <h2 v-if="type === 'Blog'">Post on Blog</h2>
        <h2 v-else>Share on social media</h2>
      </div>
      <div class="head_right ml-auto d-flex align-items-center">
        <template v-if="getUserRole !== 'Approver'">
          <div
            class="prevent_close_dropdown ml-2 dropdown default_style_dropdown label_dropdown profiles_list_header"
          >
            <div class="min_width dropdown_header" data-toggle="dropdown">
              <div class="with_circle_box">
                <!--v label length-->
                <div
                  v-if="getPublishSelection.label.length > 0"
                  class="circle_boxes_inline"
                >
                  <div
                    v-for="labelId in getPublishSelection.label"
                    :key="labelId"
                    :class="getLabelColorById(labelId) + ' circle_box'"
                  ></div>
                </div>
                <!--v else -->
                <span v-else class="icon_first">
                  <i
                    class="d-flex align-items-center justify-content-center far fa-bookmark"
                  ></i>
                </span>

                <span class="text">Labels</span>
              </div>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <LabelOptions></LabelOptions>
            </div>
          </div>

          <div
            class="prevent_close_dropdown ml-2 dropdown default_style_dropdown social_dropdown profiles_list_header"
          >
            <div class="min_width dropdown_header" data-toggle="dropdown">
              <div class="with_circle_box">
                <div
                  v-if="getPublishSelection.members.length > 0"
                  class="circle_boxes_inline"
                >
                  <template
                    v-for="(member, index) in getWorkspaces.activeWorkspace
                      .members"
                  >
                    <template
                      v-for="selected_member in getPublishSelection.members"
                    >
                      <div
                        v-if="member.user_id === selected_member"
                        :key="index + selected_member"
                        v-tooltip="{
                          content: teamMemberTooltipHtmlById(member),
                          classes: 'team_tooltip',
                        }"
                        class="circle_box"
                      >
                        <div
                          v-if="member.user.image"
                          class="img_circle"
                          :style="{
                            background: 'url(' + member.user.image + ')',
                          }"
                        ></div>
                        <div
                          v-else
                          class="img_circle"
                          style="
                            background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                          "
                        ></div>
                      </div>
                    </template>
                  </template>
                </div>
                <span v-else class="icon_first">
                  <i
                    class="d-flex align-items-center justify-content-center far fa-user"
                  ></i>
                </span>
                <span class="text">Members</span>
              </div>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <MemberOptions></MemberOptions>
            </div>
          </div>

          <div
            class="prevent_close_dropdown ml-2 dropdown default_style_dropdown checkbox_dropdown profiles_list_header"
          >
            <div class="min_width dropdown_header" data-toggle="dropdown">
              <div class="with_circle_box">
                <span class="icon_first">
                  <i
                    class="d-flex align-items-center justify-content-center far fa-user"
                  ></i>
                </span>
                <span class="text">Campaigns</span>
              </div>
            </div>
            <div
              style="min-width: 250px"
              class="dropdown-menu dropdown-menu-right"
            >
              <ul class="inner">
                <li class="list_item_li">
                  <div class="radio_input_image">
                    <input
                      id="defaultFolder"
                      :checked="checkSelected('default')"
                      type="radio"
                      @change="selectCampaignForPost('default')"
                    />
                    <label for="defaultFolder" class="radio_left">
                      {{ getDefaultCampaignName }}
                    </label>
                  </div>
                </li>
                <template v-if="getPublicationFolders.length">
                  <li
                    v-for="folder in getPublicationFolders"
                    :key="folder._id"
                    class="list_item_li"
                  >
                    <div class="radio_input_image">
                      <input
                        :id="'campaign_' + folder._id"
                        :checked="checkSelected(folder._id)"
                        :value="folder._id"
                        type="radio"
                        @change="selectCampaignForPost(folder._id)"
                      />
                      <label :for="'campaign_' + folder._id" class="radio_left">
                        {{ folder.name }}
                      </label>
                    </div>
                  </li>
                </template>

                <!--<li class="no_text_li" v-if="getPublicationFolders.length < 1">-->
                <!--No campaign found.-->
                <!--</li>-->
                <li
                  class="add-new-camp"
                  data-cy="create-campaign"
                  @click.prevent="openCreateFolderModal"
                >
                  + Add New Campaign
                </li>
              </ul>
            </div>
          </div>
        </template>
        <button type="button" class="close ml-4 mr-0" @click="hideModal"
          >&times;</button
        >
      </div>
    </div>

    <div class="modal_side">
      <ul>
        <li
          v-if="!isCSV && getUserRole !== 'Approver'"
          class=""
          :class="{ active: getSidebarStatus === 'task' }"
          @click="setSidebarStatus('task')"
        >
          <a href="javascript:;">
            <i class="far fa-tasks"></i>
            <span class="text">Tasks</span>
            <!--TODO::ARIF-->
            <span
              v-if="getTasks && getTasks.length - getTaskscCompletedCount > 0"
              class="count"
              >{{ getTasks.length - getTaskscCompletedCount }}</span
            >
          </a>
        </li>
        <li
          v-if="!isCSV"
          class=""
          :class="{ active: getSidebarStatus === 'comment' }"
          @click="setSidebarStatus('comment')"
        >
          <a href="javascript:;">
            <i class="far fa-comments"></i>
            <span class="text">Comments</span>
            <!--TODO::ARIF-->
            <span
              v-if="!getCommentsLoader && getComments && getComments.length > 0"
              class="count"
              >{{ getComments.length }}</span
            >
          </a>
        </li>
        <li
          v-if="getPublishSelection.plan_id && getUserRole !== 'Approver'"
          class=""
          :class="{ active: getSidebarStatus === 'activity' }"
          @click.prevent="setSidebarStatus('activity')"
        >
          <a href="javascript:;">
            <i class="cs-heart-beat"></i>
            <span class="text">Activities</span>
          </a>
        </li>
        <li
          v-if="type === 'Social'"
          :class="{ active: getSidebarStatus === 'preview' }"
          @click="setSidebarStatus('preview')"
        >
          <a href="javascript:;">
            <i class="far fa-eye"></i>
            <span class="text">Preview</span>
          </a>
        </li>
        <li
          v-if="type === 'Blog'"
          :class="{ active: getSidebarStatus === 'seo' }"
          @click="setSidebarStatus('seo')"
        >
          <a href="javascript:;">
            <i class="icon-Search"></i>
            <span class="text">Seo</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { publish } from '@src/modules/publish/store/states/mutation-types'
import { draftConfirmationTypes } from '@common/store/mutation-types'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import LabelOptions from '../options/LabelOptions'
import MemberOptions from '../options/MemberOptions'
import {EventBus} from "@common/lib/event-bus";

export default {
  /* eslint-disable vue/require-prop-types */
  components: {
    MemberOptions,
    LabelOptions,
  },
  props: ['type', 'isCSV', 'status'],
  data() {
    return {
      socialShareTabStatus: 'preview',
    }
  },
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getSidebarStatus',
      'getPublicationFolders',
      'getCommentsLoader',
      'getComments',
      'getTasks',
      'getTaskscCompletedCount',
    ]),
    getDefaultCampaignName() {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.default_campaign_name
      ) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign'
    },
  },
  created() {},
  mounted() {
    // fetch Integration Accounts and Authorization links
    this.$store.dispatch('fetchIntegrationsAccounts')
    this.$store.dispatch('fetchIntegrationAuthorizationLinks')
    this.setSidebarStatus(this.socialShareTabStatus)
  },

  methods: {
    ...mapActions(['setSidebarStatus']),
    selectCampaignForPost(folderId) {
      const folderValue =
        this.getPublishSelection.folderId &&
        this.getPublishSelection.folderId === folderId
          ? null
          : folderId
      this.$store.commit(publish.SET_PUBLISH_CAMPAIGN, folderValue)
    },
    checkSelected(folderId) {
      return !!(this.getPublishSelection.folderId === folderId)
    },
    hideModal() {
      if (
        this.$route.name.includes('item_social_share') ||
        this.$route.name.includes('item_blog_post')
      ) {
        // edge case, if we dont set the true, it will call the redirection twice.
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CLICKAWAY, true)
        console.log('closeAllModal --> click away go back to previous route...')
        setTimeout(() => {
          document.querySelector('body').classList.remove('overflow_hidden')
          this.$router.go(-1)
        }, 300)

        // this.$router.go(-1)
        // document.querySelector('body').classList.remove('overflow_hidden')
      }
      this.$bvModal.hide(
        this.type === 'Blog' ? 'blog_share_modal' : 'social_share_modal'
      )
    },
    openCreateFolderModal() {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
      EventBus.$emit('createFolder')
    },
  },
}
</script>

<style lang="less">
.add-new-camp {
  text-align: center;
  font-size: 0.875rem;
  border-top: 1px solid #cecece;
  padding: 10px 0px 1px 0px;
  color: #484b52;
  cursor: pointer;
}
</style>
