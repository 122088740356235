var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"feeds-left-pane--feed-groups",class:{ disable_click: _vm.getTopicDisabled },attrs:{"id":"accordion"}},[_c('li',{staticClass:"feeds-left-pane--feed-groups__heading"},[_c('i',{staticClass:"title_icon icon-feeds_icon"}),_c('span',{staticClass:"feeds-left-pane--feed-groups__heading__text"},[_vm._v("Feeds")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Create New Group'),expression:"'Create New Group'",modifiers:{"top-center":true}}],staticClass:"btn add_icon feeds-left-pane--feed-groups__heading__button",on:{"click":function($event){return _vm.feedGroupModal()}}},[_c('i',{staticClass:"far fa-plus d-block"})])]),(_vm.getFeederGroupList.length > 0)?[_vm._l((_vm.getSortedFeederGroupList),function(feeder,index){return _c('li',{key:feeder.uuid,staticClass:"feeds-left-pane--feed-groups__list-items",class:{
        feed_removal:
          feeder.hasOwnProperty('state') && feeder.state === 'removing',
      }},[_c('span',{staticClass:"d-block feeds-left-pane--feed-groups__parent",class:{
          'active animate': _vm.$route.params.collection_id === feeder.uuid,
        },staticStyle:{"position":"relative"}},[_c('span',{staticClass:"feeds-left-pane--feed-groups__collapse_icon",attrs:{"data-toggle":"collapse","href":'#_' + feeder.uuid}},[(_vm.getSortedFeederGroupList)?_c('i',{staticClass:"edit_icon far fa-angle-right",staticStyle:{"font-size":"13px"}}):_vm._e()]),_c('router-link',{staticClass:"feeds-left-pane--feed-groups__group_name d-block",attrs:{"to":{
            name: 'feeder_collection',
            params: { collection_id: feeder.uuid },
          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.limitTextLength(feeder.name, 22))+" ")])]),(feeder.total_posts > 0)?_c('span',{staticClass:"feeds-left-pane--feed-groups__total_statistics"},[_c('span',{staticClass:"total_no_of_posts"},[_vm._v(" "+_vm._s(_vm.intToString(feeder.total_posts))+" ")])]):_vm._e(),_c('span',{staticClass:"feeds-left-pane--feed-groups__action_buttons d-flex align-items-center"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Edit'),expression:"'Edit'",modifiers:{"top-center":true}}],staticClass:"icon-edit-cs feeds-left-pane--feed-groups__action_buttons__edit",on:{"click":function($event){$event.preventDefault();return _vm.editFeedGroupModal(feeder)}}}),_c('span',{staticClass:"dropdown dropdown-confirmation"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"icon-delete-cs feeds-left-pane--feed-groups__action_buttons__remove",attrs:{"id":'dropdown_' + feeder._id,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),_c('div',{staticClass:"dropdown-menu dropdown-confirmation__menu",attrs:{"aria-labelledby":"'dropdown_'+feeder._id"}},[_c('div',{staticClass:"dropdown-confirmation__menu__header"},[_vm._v(" Confirmation ")]),_c('div',{staticClass:"dropdown-confirmation__menu__actions"},[_vm._v(" Are you sure? "),_c('span',{staticClass:"dropdown-confirmation__menu__actions__delete",on:{"click":function($event){$event.preventDefault();return _vm.deleteFeederGroupAction(feeder)}}},[_vm._v("Yes")]),_c('span',{staticClass:"dropdown-confirmation__menu__actions__cancel"},[_vm._v("No")])])])])])],1),_c('ul',{staticClass:"feeds-sublist__items collapse",attrs:{"id":'_' + feeder.uuid,"data-parent":"#accordion"}},[_vm._l((_vm.sortUserFeedDetails(
            feeder.user_feed_details,
            feeder
          )),function(user_followed_feed){return _c('li',{class:{
            feed_removal:
              user_followed_feed.hasOwnProperty('state') &&
              user_followed_feed.state === 'removing',
          }},[_c('router-link',{attrs:{"to":{
              name: 'feeder_feed_view',
              params: {
                collection_id: feeder.uuid,
                feed_id: user_followed_feed.uuid,
              },
            }}},[(
                user_followed_feed.hasOwnProperty('edit_feed_input') &&
                user_followed_feed.edit_feed_input
              )?[_c('span',{staticClass:"d-inline-block feeder-input-edit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(user_followed_feed.title),expression:"user_followed_feed.title"}],attrs:{"type":"text"},domProps:{"value":(user_followed_feed.title)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.feederEditIndividualFeedStore(user_followed_feed)},"input":function($event){if($event.target.composing)return;_vm.$set(user_followed_feed, "title", $event.target.value)}}}),(
                    user_followed_feed.hasOwnProperty(
                      'edit_feed_name_store_loader'
                    ) && user_followed_feed.edit_feed_name_store_loader
                  )?[_c('clip-loader',{attrs:{"color":"#436aff","size":'13px'}})]:_vm._e()],2),_c('div',{staticClass:"t_icon d-flex align-items-center",staticStyle:{"width":"20px","height":"20px"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Cancel'),expression:"'Cancel'",modifiers:{"top-center":true}}],staticClass:"edit_icon far fa-times",staticStyle:{"font-size":"13px"},on:{"click":function($event){return _vm.feederEditIndividualFeed(user_followed_feed, false)}}})])]:[_c('div',{staticClass:"feeds-sublist__items__image_logo d-inline-block"},[(user_followed_feed.logo)?_c('img',{staticClass:"d-inline-block mr-2",attrs:{"src":user_followed_feed.logo,"alt":""}}):[_c('i',{staticClass:"far fa-rss"})]],2),_c('span',{staticClass:"feeds-sublist__items__text inner_link_text d-inline-block"},[_vm._v(_vm._s(_vm.limitTextLength(user_followed_feed.title, 16)))]),(user_followed_feed.total_posts > 0)?_c('div',{staticClass:"feeds-sublist__items__statistics"},[_c('span',{staticClass:"total_no_of_posts"},[_vm._v(" "+_vm._s(_vm.intToString(user_followed_feed.total_posts))+" ")])]):_vm._e(),_c('div',{staticClass:"feeds-sublist__items__action_buttons t_icon d-flex align-items-center"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Remove'),expression:"'Remove'",modifiers:{"top-center":true}}],staticClass:"far icon-delete-cs feeds-sublist__items__action_buttons__remove",staticStyle:{"font-size":"13px"},on:{"click":function($event){$event.preventDefault();return _vm.feederRemoveIndividualFeed(feeder, user_followed_feed)}}})])]],2)],1)}),(
            feeder.user_feed_details && feeder.user_feed_details.length === 0
          )?_c('li',{staticClass:"feeds-sublist-extended-items feeds-sublist-extended-items-without-underline"},[_vm._v(" You have not followed any feed yet. ")]):_vm._e(),(
            feeder.show_more &&
            feeder.user_feed_details &&
            feeder.user_feed_details.length > 6
          )?_c('li',{staticClass:"feeds-sublist-extended-items",on:{"click":function($event){return _vm.feederToggleMore(feeder, false)}}},[_vm._v(" Show less ")]):(feeder.user_feed_details.length > 6)?_c('li',{staticClass:"feeds-sublist-extended-items",on:{"click":function($event){return _vm.feederToggleMore(feeder, true)}}},[_vm._v(" Show "+_vm._s(feeder.user_feed_details.length - 6)+" more sources ")]):_vm._e()],2)])}),(_vm.import_state === 'started')?_c('hr'):_vm._e(),(_vm.import_state === 'started')?_c('div',{staticClass:"import_opml_started"},[_c('span',[_vm._v(" Importing new sources "),_c('span',[_c('clip-loader',{attrs:{"size":'11px',"color":'#989eb5'}})],1)])]):_vm._e()]:[_c('div',{staticClass:"feeds-create-group"},[_c('img',{attrs:{"src":_vm.create_feed_group_image,"alt":""}}),_c('div',{staticClass:"feeds-create-group__content"},[_vm._m(0),_c('div',{staticClass:"feeds-create-group__content__right"},[_c('b-button',{attrs:{"variant":"studio-theme"},on:{"click":function($event){return _vm.feedGroupModal()}}},[_vm._v("+")])],1)])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feeds-create-group__content__left"},[_c('p',[_vm._v(" Keep up-to date with your favorite sources. ")])])
}]

export { render, staticRenderFns }