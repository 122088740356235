var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-library-sidebar"},[_c('div',{staticClass:"media-library-sidebar__header"},[_c('div',{staticClass:"media-library-sidebar__header__label"},[_vm._v("Folders")]),_c('div',{staticClass:"media-library-sidebar__header__button"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],staticClass:"btn btn-studio-theme-transparent-grey btn-size-small",attrs:{"title":"Create New Folder"},on:{"click":function($event){return _vm.$bvModal.show('create-media-folder')}}},[_c('i',{staticClass:"icon-add-new-member-cs"})])])]),_c('div',{staticClass:"media-library-sidebar__options"},[_c('div',{staticClass:"media-library-sidebar__option",class:{ active: _vm.$route.query.type === 'all' },on:{"click":function($event){return _vm.handleRoute('all')}}},[_vm._m(0),_c('div',{staticClass:"media-library-sidebar__option__item-count"},[_vm._v(" "+_vm._s(_vm.filesCount.all)+" ")])]),_c('div',{staticClass:"media-library-sidebar__option",class:{ active: _vm.$route.query.type === 'archived' },attrs:{"id":"archived","data-dragover":"false"},on:{"drop":function($event){_vm.onDrop($event, _vm.getElement('archived'), _vm.selectedItems, _vm.isAllSelected)},"dragenter":function($event){$event.preventDefault();_vm.onDragEnter($event, _vm.getElement('archived'))},"dragleave":function($event){$event.preventDefault();_vm.onDragLeave($event, _vm.getElement('archived'))},"dragover":function($event){$event.preventDefault();},"click":function($event){return _vm.handleRoute('archived')}}},[_vm._m(1),_c('div',{staticClass:"media-library-sidebar__option__item-count"},[_vm._v(" "+_vm._s(_vm.filesCount.archived)+" ")])]),_c('div',{staticClass:"media-library-sidebar__option",class:{ active: _vm.$route.query.type === 'uncategorized' },attrs:{"id":"uncategorized","data-dragover":"false"},on:{"drop":function($event){_vm.onDrop(
          $event,
          _vm.getElement('uncategorized'),
          _vm.selectedItems,
          _vm.isAllSelected
        )},"dragenter":function($event){$event.preventDefault();_vm.onDragEnter($event, _vm.getElement('uncategorized'))},"dragleave":function($event){$event.preventDefault();_vm.onDragLeave($event, _vm.getElement('uncategorized'))},"dragover":function($event){$event.preventDefault();},"click":function($event){return _vm.handleRoute('uncategorized')}}},[_vm._m(2),_c('div',{staticClass:"media-library-sidebar__option__item-count"},[_vm._v(" "+_vm._s(_vm.filesCount.uncategorized)+" ")])])]),_c('div',{staticClass:"sidebar-divider"}),_c('div',{staticClass:"media-library-sidebar__folders"},[(_vm.isFolderFetching)?[_c('div',{staticClass:"stack-folder__wrapper"},[_c('SkeletonBox',{attrs:{"width":"100%","height":"40px","radius":"12px"}})],1),_c('div',{staticClass:"stack-folder__wrapper"},[_c('SkeletonBox',{attrs:{"width":"100%","height":"40px","radius":"12px"}})],1)]:[(_vm.folders.length === 0)?_c('div',{staticClass:"media-library-sidebar__note"},[_c('i',[_vm._v("You don't have any folder yet, please "),_c('span',{on:{"click":function($event){return _vm.$bvModal.show('create-media-folder')}}},[_vm._v("create a new folder")]),_vm._v(".")])]):_vm._l((_vm.folders),function(folder,i){return _c('Folder',{key:i,attrs:{"type":"primary","folder":folder},on:{"drop":(e, ele) => _vm.onDrop(e, ele, _vm.selectedItems, _vm.isAllSelected),"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"folderclick":_vm.handleClick}})})]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-library-sidebar__option__item"},[_c('div',{staticClass:"media-library-sidebar__option__item-icon"},[_c('i',{staticClass:"far fa-layer-group"})]),_c('div',{staticClass:"media-library-sidebar__option__item-text"},[_vm._v(" All Uploads ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-library-sidebar__option__item"},[_c('div',{staticClass:"media-library-sidebar__option__item-icon"},[_c('i',{staticClass:"far fa-trash"})]),_c('div',{staticClass:"media-library-sidebar__option__item-text"},[_vm._v(" Archived ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-library-sidebar__option__item"},[_c('div',{staticClass:"media-library-sidebar__option__item-icon"},[_c('i',{staticClass:"far fa-folder"})]),_c('div',{staticClass:"media-library-sidebar__option__item-text"},[_vm._v(" Uncategorized ")])])
}]

export { render, staticRenderFns }