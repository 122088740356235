import proxy from '@common/lib/http-common'
import { getCustomSidebarURL, setCustomSidebarURL } from '../config/api-utils'
import { UPDATE_CUSTOMIZE_SIDEBAR_FAIL } from '@common/constants/messages'
import { topicCustomizedSidebarTypes } from './mutation-types'
const getDefaultCustomizeSidebarState = () => {
  return {
    sidebar: {
      customization: false,
      custom_sidebar: {
        quick_topics: {
          status: true,
          options: []
        },
        my_topics: {
          status: true,
          options: []
        },
        favorites: {
          status: true,
          options: []
        }
      },
      loaders: {
        fetch: false,
        save: false
      }
    }
  }
}

const state = getDefaultCustomizeSidebarState()
const getters = {
  getSidebarCustomization: (state) => {
    return state.sidebar
  },
  getCustomSidebar: (state) => {
    return state.sidebar.custom_sidebar
  },
  getSidebarCustomizationLoader: (state) => {
    return state.sidebar.loaders
  }
}
const mutations = {
  [topicCustomizedSidebarTypes.SET_SIDEBAR_CUSTOMIZATION] (state, value) {
    console.log('sidebar_customization', value)
    state.sidebar.customization = value
    console.log('sidebar_customization after', value)
  },
  [topicCustomizedSidebarTypes.SET_CUSTOM_SIDEBAR_OPTIONS] (state, value) {
    if (value != null) {
      state.sidebar.custom_sidebar._id = value._id
      state.sidebar.custom_sidebar.my_topics = value.myTopics
      state.sidebar.custom_sidebar.quick_topics = value.quickTopics
      state.sidebar.custom_sidebar.favorites = value.favorites
    } else {
      // default values
      state.sidebar.custom_sidebar =
        getDefaultCustomizeSidebarState().sidebar.custom_sidebar
    }
  },
  [topicCustomizedSidebarTypes.SET_SIDEBAR_CUSTOMIZATION_FETCH_LOADER] (
    state,
    value
  ) {
    state.sidebar.loaders.fetch = value
  },
  [topicCustomizedSidebarTypes.SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER] (
    state,
    value
  ) {
    state.sidebar.loaders.save = value
  }
}
const actions = {
  fetchCustomSidebar ({ commit, getters, state, dispatch }) {
    if (getters.getDiscoverFetchStatus === true) {
      return
    }
    proxy
      .post(getCustomSidebarURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        if (resp.data.custom) {
          commit('SET_CUSTOM_SIDEBAR_OPTIONS', resp.data.custom)
        }
      })
      .catch()
  },

  setSidebarCustomization ({ commit }, value) {
    commit('SET_SIDEBAR_CUSTOMIZATION', value)
  },
  saveCustomSidebar ({ commit, getters, state, dispatch }) {
    const sidebar = getters.getCustomSidebar

    commit('SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER', true)
    proxy
      .post(setCustomSidebarURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        quickTopics: sidebar.quick_topics,
        myTopics: sidebar.my_topics,
        favorites: sidebar.favorites,
        id: sidebar._id
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_SIDEBAR_CUSTOMIZATION', false)
        } else {
          dispatch('toastNotification', {
            message: UPDATE_CUSTOMIZE_SIDEBAR_FAIL,
            type: 'error'
          })
        }
        commit('SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER', false)
      })
      .catch((err) => {
        commit('SET_SIDEBAR_CUSTOMIZATION_SAVE_LOADER', false)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
