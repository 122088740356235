import proxy from '@common/lib/http-common'
import {
  addToRefetchQueueURL,
  downloadReportsURL,
  getFacebookAnalyticsSummaryURL,
  getFacebookAnalyticsURL,
  getGroupAnalyticsAccountPerformanceURL,
  getGroupAnalyticsEngagementRollupURL,
  getGroupAnalyticsOverviewURL,
  getGroupAnalyticsPageEngagementURL,
  getGroupAnalyticsTopPostsURL,
  getGroupAnalyticsURL,
  getInstagramAnalyticsDemographicsURLv1,
  getInstagramAnalyticsDemographicsURL,
  getInstagramAnalyticsInsightsURL,
  getInstagramAnalyticsPostsURLv1,
  getInstagramAnalyticsURLv1,
  getLinkedinAnalyticsURL,
  getPinterestAnalyticsURL,
  getReportsURL,
  getScheduleReportsURL,
  getTwitterAnalyticsURL,
  removeDownloadReportsURL,
  removeScheduleReportsURL,
  saveReportsURL,
  scheduleReportsURL,
  getTwitterAnalyticsV2URL,
  getFacebookAnalyticsTopPostsURL,
  getFacebookAnalyticsDemographicsURL,
  getLinkedinAnalyticsSummaryURL,
  getLinkedinAnalyticsTopPostsURL,
  getInstagramAnalyticsSummaryURL,
  getInstagramAnalyticsTopPostsURL,
  getPinterestAnalyticsSummaryURL,
  getPinterestAnalyticsTopPostsURL,
} from '@src/modules/analytics/config/api-utils'

export const defaultReportItem = () => {
  return {
    _id: null,
    accounts: [],
    selected_accounts: [],
    type: null,
    name: null,
    email: null,
    email_list: [],
    copy_email_to_myself: false,
    interval: 'weekly',
  }
}

export default {
  state: {
    api_call_status: false,
    sync_call_status: false,
    analyze_service_status: false,
    fetch_social_status: false,
    reports_call: {
      save: false,
      retrieve: false,
      export: false,
    },
    reports: {
      query: null,
      list: [],
      item: defaultReportItem(),
    },
    download_reports: {
      list: [],
    },
  },
  getters: {
    getAnalyticsServiceStatus: (state) => {
      return state.api_call_status
    },
    getSyncServiceStatus: (state) => {
      return state.sync_call_status
    },
    getAnalyzeServiceStatus: (state) => {
      return state.analyze_service_status
    },
    getFetchSocialStatus: (state) => {
      return state.fetch_social_status
    },
    getReportsServiceCalls: (state) => {
      return state.reports_call
    },
    getScheduledReportItem: (state) => {
      return state.reports.item
    },

    getScheduledReports: (state) => {
      return state.reports
    },
    getDownloadReports: (state) => {
      return state.download_reports
    },
  },
  actions: {
    getAnalyzeService: function ({ commit, getters, actions }, payload) {
      console.log(payload)
      const date = Object.hasOwn(payload, 'date') ? payload.date : null
      const type = Object.hasOwn(payload, 'type') ? payload.type : 'facebook'
      const tab = Object.hasOwn(payload, 'tab') ? payload.tab : 'overview'
      const timezone = Object.hasOwn(payload, 'timezone')
        ? payload.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone
      const section = Object.hasOwn(payload, 'section')
        ? payload.section
        : 'overview'
      let accounts = Object.hasOwn(payload, 'accounts') ? payload.accounts : []
      if (!Array.isArray(accounts) || typeof accounts !== 'object') {
        accounts = [accounts]
      }
      let url
      switch (type) {
        case 'group':
          if (section === 'overview') {
            url = getGroupAnalyticsOverviewURL
          } else if (section === 'top_posts') {
            url = getGroupAnalyticsTopPostsURL
          } else if (section === 'posts_engagements') {
            url = getGroupAnalyticsPageEngagementURL
          } else if (section === 'engagement_rollup') {
            url = getGroupAnalyticsEngagementRollupURL
          } else if (section === 'account_performance') {
            url = getGroupAnalyticsAccountPerformanceURL
          }
          break
        case 'facebook':
          if (tab === 'overview') {
            url = getFacebookAnalyticsSummaryURL
          } else if (tab === 'posts') {
            url = getFacebookAnalyticsTopPostsURL
          } else if (tab === 'demographics') {
            url = getFacebookAnalyticsDemographicsURL
          }
          break
        case 'linkedin':
          if (tab === 'overview') {
            url = getLinkedinAnalyticsSummaryURL
          } else if (tab === 'posts') {
            url = getLinkedinAnalyticsTopPostsURL
          }
          break
        case 'pinterest':
          if (tab === 'overview') {
            url = getPinterestAnalyticsSummaryURL
          } else if (tab === 'posts') {
            url = getPinterestAnalyticsTopPostsURL
          }
          break
        case 'instagram':
          if (tab === 'overview') {
            url = getInstagramAnalyticsSummaryURL
          } else if (tab === 'posts') {
            url = getInstagramAnalyticsTopPostsURL
          } else if (tab === 'demographics') {
            url = getInstagramAnalyticsDemographicsURL
          }
          break
      }
      const requestPayload = {
        workspace_id: payload.workspace_id
          ? payload.workspace_id
          : getters.getActiveWorkspace._id,
        date: date,
        facebook_accounts: [],
        twitter_accounts: [],
        instagram_accounts: [],
        pinterest_accounts: [],
        linkedin_accounts: [],
        type: section,
        timezone: timezone,
        previous_date: Object.hasOwn(payload, 'previous_date')
          ? payload.previous_date
          : null,
      }
      if (type === 'group') {
        accounts.forEach((account) => {
          switch (account.account_type) {
            case 'facebook':
              requestPayload.facebook_accounts.push(account.facebook_id)
              break
            case 'twitter':
              requestPayload.twitter_accounts.push(account.twitter_id)
              break
            case 'instagram':
              requestPayload.instagram_accounts.push(account.instagram_id)
              break
            case 'pinterest':
              requestPayload.pinterest_accounts.push(account.board_id)
              break
            case 'linkedin':
              requestPayload.linkedin_accounts.push(account.linkedin_id)
              break
          }
        })
      } else {
        requestPayload.accounts = accounts
      }
      return proxy
        .post(url, requestPayload)
        .catch((e) => {
          console.error(e)
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
        })
        .then((resp) => {
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
          if (typeof resp.data !== 'undefined') {
            console.log(resp.data)
            if (resp.data.status) return resp.data
          }
        })
    },
    getAnalyticsService({ commit, getters, actions }, payload) {
      console.debug('Action:getAnalyticsService')
      const date = Object.hasOwn(payload, 'date') ? payload.date : null
      const type = Object.hasOwn(payload, 'type') ? payload.type : 'facebook'
      const timezone = Object.hasOwn(payload, 'timezone')
        ? payload.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone
      let accounts = Object.hasOwn(payload, 'accounts') ? payload.accounts : []
      if (!Array.isArray(accounts) || typeof accounts !== 'object') {
        accounts = [accounts]
      }
      let url
      switch (type) {
        case 'facebook':
          url = getFacebookAnalyticsURL
          break
        case 'twitter':
          url = getTwitterAnalyticsURL
          break
        case 'linkedin':
          url = getLinkedinAnalyticsURL
          break
        case 'pinterest':
          url = getPinterestAnalyticsURL
          break
        case 'instagram':
          if (
            Object.hasOwn(payload, 'section') &&
            payload.section === 'posts'
          ) {
            url = getInstagramAnalyticsPostsURLv1
          } else if (
            Object.hasOwn(payload, 'section') &&
            payload.section === 'demographics'
          ) {
            url = getInstagramAnalyticsDemographicsURLv1
          } else if (
            Object.hasOwn(payload, 'section') &&
            payload.section === 'insights'
          ) {
            url = getInstagramAnalyticsInsightsURL
          } else {
            url = getInstagramAnalyticsURLv1
          }
          break
        case 'group':
          url = getGroupAnalyticsURL
          break
      }
      if (getters.getAnalyticsServiceStatus) return
      commit('SET_ANALYTICS_SERVICE_STATUS', true)

      return proxy
        .post(url, {
          workspace_id: payload.workspace_id
            ? payload.workspace_id
            : getters.getActiveWorkspace._id,
          timezone: timezone,
          date: date,
          accounts: accounts,
          previous_date: Object.hasOwn(payload, 'previous_date')
            ? payload.previous_date
            : null,
        })
        .catch(() => {
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
        })
        .then((resp) => {
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
          if (resp.data.status) return resp.data
        })
    },
    getTwitterAnalyticsService({ commit, getters, actions }, payload) {
      return proxy
        .post(getTwitterAnalyticsV2URL, payload)
        .catch(() => {
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
        })
        .then((resp) => {
          commit('SET_ANALYTICS_SERVICE_STATUS', false)
          if (resp.data.status) return resp.data
        })
    },
    getReportsService({ commit, getters, actions }, payload) {
      commit('SET_REPORTS_SERVICE_RETRIEVE_STATUS', true)
      return proxy
        .post(getReportsURL, payload)
        .catch(() => {
          commit('SET_REPORTS_SERVICE_RETRIEVE_STATUS', false)
          return false
        })
        .catch(() => {
          commit('SET_REPORTS_SERVICE_RETRIEVE_STATUS', false)
        })
        .then((res) => {
          commit('SET_REPORTS_SERVICE_RETRIEVE_STATUS', false)
          if (res.data.status) {
            return res.data.report
          }
          return false
        })
    },
    renderReportsService({ commit, getters, actions }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      if (
        !Array.isArray(payload.accounts) ||
        typeof payload.accounts !== 'object'
      ) {
        payload.accounts = [payload.accounts]
      }
      commit('SET_REPORTS_SERVICE_SAVE_STATUS', true)
      commit('SET_REPORTS_SERVICE_EXPORT_STATUS', true)

      return proxy
        .post(saveReportsURL, payload, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        })
        .catch(() => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          commit('SET_REPORTS_SERVICE_EXPORT_STATUS', false)
          return false
        })
        .then((res) => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          commit('SET_REPORTS_SERVICE_EXPORT_STATUS', false)

          return res
        })
    },

    saveReportsService({ commit, getters, actions, dispatch }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      if (
        !Array.isArray(payload.accounts) ||
        typeof payload.accounts !== 'object'
      ) {
        payload.accounts = [payload.accounts]
      }
      commit('SET_REPORTS_SERVICE_SAVE_STATUS', true)
      return proxy
        .post(saveReportsURL, payload)
        .catch(() => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          return false
        })
        .then((res) => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          if (payload.action === 'email') {
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'success',
            })

            // eslint-disable-next-line no-undef
            $('#sendReportByEmail').modal('hide')
          }
          return res
        })
    },

    scheduleReportsService({ commit, getters, dispatch, state }, payload) {
      console.log(payload)
      // validating the payload
      if (!payload.name) {
        dispatch('toastNotification', {
          message: 'Please enter a report name.',
          type: 'error',
        })
        return false
      }

      if (payload.accounts.length === 0) {
        dispatch('toastNotification', {
          message:
            'You have not selected any social accounts, please select your social accounts to receive a report.',
          type: 'error',
        })
        return false
      }

      if (payload.email_list.length === 0 && !payload.copy_email_to_myself) {
        dispatch('toastNotification', {
          message:
            'Please add an email address where we should send the report.',
          type: 'error',
        })
        return false
      }

      commit('SET_REPORTS_SERVICE_SAVE_STATUS', true)
      payload.workspace_id = getters.getActiveWorkspace._id
      return proxy
        .post(scheduleReportsURL, payload)
        .catch(() => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          return false
        })
        .then((res) => {
          commit('SET_REPORTS_SERVICE_SAVE_STATUS', false)
          if (res.data.status) {
            // eslint-disable-next-line no-undef
            $('#scheduleReport').modal('hide')
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'success',
            })
            commit('RESET_SCHEDULED_REPORT_ITEM')

            // updating the array element

            if (payload._id) {
              getters.getScheduledReports.list.forEach((item, index) => {
                console.log(res.data.report._id, item._id)
                if (res.data.report._id === item._id) {
                  state.reports.list.splice(index, 1, res.data.report)
                  console.log('item updated...')
                }
              })
            }
            return res.data.report
          }
          return false
        })
    },
    fetchScheduleReportsService({ commit, getters, dispatch }) {
      return proxy
        .post(getScheduleReportsURL, {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch(() => {})
        .then((res) => {
          commit('SET_SCHEDULED_REPORTS_LIST', res.data.data)
          return res
        })
    },

    removeScheduledReportService(
      { commit, getters, dispatch, state },
      payload
    ) {
      return proxy
        .post(removeScheduleReportsURL, {
          workspace_id: getters.getActiveWorkspace._id,
          report_id: payload.reportId,
        })
        .catch(() => {})
        .then((res) => {
          state.reports.list.splice(payload.index, 1)
          return res
        })
    },

    // downloaded reports
    fetchDownloadReportsService({ commit, getters, dispatch }) {
      return proxy
        .post(downloadReportsURL, {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch(() => {})
        .then((res) => {
          commit('SET_DOWNLOADED_REPORTS_LIST', res.data.data)
          return res
        })
    },
    removeDownloadReportService({ commit, getters, dispatch, state }, payload) {
      return proxy
        .post(removeDownloadReportsURL, {
          workspace_id: getters.getActiveWorkspace._id,
          report_id: payload.reportId,
        })
        .catch(() => {})
        .then((res) => {
          state.download_reports.list.splice(payload.index, 1)
          return res
        })
    },
    exportDownloadReportService({ commit, getters, dispatch, state }, payload) {
      return proxy
        .post(removeDownloadReportsURL, {
          workspace_id: getters.getActiveWorkspace._id,
          report_id: payload.reportId,
        })
        .catch(() => {})
        .then((res) => {
          return res
        })
    },

    syncUpdateService({ commit, getters, dispatch, state }, payload) {
      commit('SET_SYNC_SERVICE_STATUS', true)
      return proxy
        .post(addToRefetchQueueURL, {
          workspace_id: getters.getActiveWorkspace._id,
          selected_account: payload.selected_account,
          channel: payload.channel,
        })
        .catch(() => {
          console.log('faillllllllll')
          commit('SET_SYNC_SERVICE_STATUS', false)
        })
        .then((resp) => {
          commit('SET_SYNC_SERVICE_STATUS', false)
          if (resp.data.status) return resp.data
        })
    },

    fetchSocialService({ commit, getters, dispatch, state }, payload) {
      console.log("payload['value']")
      console.log(payload.value)
      commit('SET_FETCH_SOCIAL_STATUS', payload.value)
    },

    setAnalyzeService({ commit, getters, dispatch, state }, payload) {
      console.log('SET_ANALYZE_SERVICE_STATUS')
      console.log(payload)
      commit('SET_ANALYZE_SERVICE_STATUS', payload.value)
    },
  },
  mutations: {
    SET_ANALYTICS_SERVICE_STATUS(state, value) {
      state.api_call_status = value
    },
    SET_SYNC_SERVICE_STATUS(state, value) {
      state.sync_call_status = value
    },
    SET_ANALYZE_SERVICE_STATUS(state, value) {
      state.analyze_service_status = value
    },
    SET_FETCH_SOCIAL_STATUS(state, value) {
      state.fetch_social_status = value
    },
    SET_REPORTS_SERVICE_SAVE_STATUS(state, value) {
      state.reports_call.save = value
    },
    SET_REPORTS_SERVICE_EXPORT_STATUS(state, value) {
      state.reports_call.export = value
    },
    SET_REPORTS_SERVICE_RETRIEVE_STATUS(state, value) {
      state.reports_call.retrieve = value
    },
    SET_SCHEDULED_REPORTS_LIST(state, value) {
      state.reports.list = value
    },
    SET_DOWNLOADED_REPORTS_LIST(state, value) {
      state.download_reports.list = value
    },
    SET_SCHEDULED_REPORT_ITEM(state, value) {
      state.reports.item = value
    },
    RESET_SCHEDULED_REPORT_ITEM(state) {
      state.reports.item = defaultReportItem()
    },
    SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(state, value) {
      state.reports.item.accounts = value
    },
    SET_SCHEDULED_REPORT_ITEM_TYPE(state, value) {
      state.reports.item.type = value
    },
    UPDATE_SCHEDULED_REPORT_LIST_ITEM(state, value, index) {
      // state.reports.list.splice(index, 1)
    },
  },
}
