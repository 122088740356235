import { mapGetters, mapActions } from 'vuex'
export const tasksMixin = {
  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getPublishSelection',
      'getCreateTaskLoader',
    ]),
  },
  methods: {
    ...mapActions(['saveTask']),

    getTaskImage(text) {
      if (!text) {
        return
      }

      try {
        const taskImages = []
        const splitText = text.split('![')
        if (splitText.length > 1) {
          for (let i = 1; i < splitText.length; i++) {
            const newSplitText = splitText[i].split('](')
            if (newSplitText.length > 1) {
              const taskImage = newSplitText[1].split(')')
              taskImages.push(taskImage[0])
            }
          }
        }
        return taskImages
      } catch (e) {
        return null
      }
    },

    /**
     * this method call on saving new task
     * check if discovery post is not save as draft
     * than save draft first then save task
     * @param null
     */
    async checkPostExistTask() {
      console.debug(this.getBlogPostingDetails.type)

      if (this.getCreateTaskLoader) return false

      if (!this.getPublishSelection.plan_id) {
        if (
          this.getBlogPostingDetails.type === 'Composer Article' ||
          this.getBlogPostingDetails.type === 'Discovery Article' ||
          this.getBlogPostingDetails.type === 'Automation Article' ||
          this.getBlogPostingDetails.type === 'Discovery Video' ||
          this.getBlogPostingDetails.type === 'Automation Video'
        ) {
          this.processBlogPost(true, false, false, 'task')
        } else {
          this.processSocialShare(true, false, false, 'task')
        }
      } else {
        const taskStatus = await this.saveTask(null)
        // if (taskStatus) this.emitBlogActivities('task')
        // this.saveTask(null)
      }
    },
  },
}
