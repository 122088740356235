import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getContentInsightsURL } from '../../config/api-utils'

const chartsMixin = {
  computed: {
    ...mapGetters([
      'getTopicSelection',
      'getTopicsFilters',
      'getTopicType',
      'getTopic',
      'getCuratedTopic',
      'getWorkspaces'
    ])
  },
  methods: {
    ...mapMutations(['SET_TOPIC_DISABLED']),
    ...mapActions([
      'setArticlesPublishedOverTimePostsCount',
      'setArticlesPublishedOverTimeEngagement',
      'setArticlesPublishedOverTimeDate',
      'setShowChartsLoader',
      'setInsightsSentimentCount',
      'setInsightsLoader',
      'setInsightsCount',
      'setArticlesPublishedOverTimeSentiments',
      'setInsightsContentTypes',
      'setInsightsEngagement',
      'setInsightsPopularDays',
      'setInsightsTopDomains',
      'setInsightsTopDomainsForChart',
      'setInsightsTopAuthors',
      'setInsightsReadingLevels',
      'setInsightsPopularWordCount',
      'setWordCloud'
    ]),
    getContentInsights () {
      this.SET_TOPIC_DISABLED(true)
      console.debug('Method:getContentInsights')
      this.postRequest(
        getContentInsightsURL,
        {
          selection: this.getTopicSelection,
          filters: this.getTopicsFilters,
          type: this.getTopicType,
          topic: this.getTopic,
          curated: this.getCuratedTopic,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          search_term: this.getFilters.search
        },
        (response) => {
          this.SET_TOPIC_DISABLED(false)

          if (response.data.status) {
            const articlesPublishedArrayPostsCount = []
            const articlesPublishedarrayEngagement = []
            const sentiments = {
              positive: [],
              negative: [],
              neutral: []
            }

            // percentage of shared data

            console.debug(response.data.insights.aggregations.total_stats.value)
            this.setInsightsCount({
              articlesAnalyzed: response.data.insights.hits.total,
              totalEngagements:
                response.data.insights.aggregations.total_stats.value,
              averageEngagements:
                response.data.insights.aggregations.average_stats.value,
              averageFacebookEngagement:
                response.data.insights.aggregations.average_facebook.value,
              averagePinterestEngagement:
                response.data.insights.aggregations.average_pins.value,
              averageRedditEngagement:
                response.data.insights.aggregations.average_reddit.value
            })

            // published articles over time with their average shares.

            response.data.insights.aggregations.articles_published_per_day.buckets.forEach(
              function (element) {
                const parsedDate = Date.parse(element.key_as_string)
                const docCount = element.doc_count || 0
                const averageStats = element.avg_stats.value || 0
                articlesPublishedArrayPostsCount.push([parsedDate, docCount])
                articlesPublishedarrayEngagement.push([
                  parsedDate,
                  Math.ceil(averageStats)
                ])
                element.sentiment.sentiment_labels.buckets.forEach(function (
                  item
                ) {
                  let itemDocCount = 0
                  console.debug(parsedDate, item.doc_count)

                  if (item.doc_count) {
                    itemDocCount = item.doc_count
                  }
                  if (item.key == 'neutral') {
                    sentiments.neutral.push([parsedDate, itemDocCount])
                    // sentiments.positive.push([parsedDate, 0])
                    // sentiments.negative.push([parsedDate, 0])
                  } else if (item.key == 'positive') {
                    sentiments.positive.push([parsedDate, itemDocCount])
                    // sentiments.neutral.push([parsedDate, 0])
                    // sentiments.negative.push([parsedDate, 0])
                  } else if (item.key == 'negative') {
                    sentiments.negative.push([parsedDate, itemDocCount])
                    // sentiments.neutral.push([parsedDate, 0])
                    // sentiments.positive.push([parsedDate, 0])
                  }
                },
                this)
              },
              this
            )

            // set the values to the setters

            this.setArticlesPublishedOverTimePostsCount(
              articlesPublishedArrayPostsCount
            )
            this.setArticlesPublishedOverTimeEngagement(
              articlesPublishedarrayEngagement
            )
            this.setArticlesPublishedOverTimeSentiments(sentiments)
            this.setInsightsPopularDays(
              response.data.insights.aggregations.popular_days
            )
            this.setInsightsTopAuthors(
              response.data.insights.aggregations.top_authors.buckets
            )
            this.sentimentEngagementAndPercentageCalculator(
              response.data.insights.aggregations.average_sentiment_statistics
            )
            this.popularContentTypes(
              response.data.insights.aggregations.categories_list
            )
            this.insightsEngagement(response.data.insights.aggregations)
            this.popularDomainsChart(
              response.data.insights.aggregations.top_domains.buckets
            )
            this.wordCloud(
              response.data.insights.aggregations.top_popular_hashtags.buckets
            )
            this.popularReadingLevelsChart(
              response.data.insights.aggregations.popular_reading_levels.buckets
            )

            let wordCountFirstRange =
              response.data.insights.aggregations.popular_word_count.buckets
            const wordCountSecondRange =
              response.data.insights.aggregations.temporarily_popular_word_count
                .buckets

            if (wordCountFirstRange.length > 4) {
              // pick only first two elements

              if (wordCountFirstRange.length > 2) {
                wordCountFirstRange = wordCountFirstRange.slice(0, 2)
              }
              // remove first element which is 0 index.
              if (wordCountSecondRange.length > 2) {
                wordCountSecondRange.splice(0, 1)
              }

              // merge two dicts and pass the value to set for the chart.
              console.debug(wordCountFirstRange, wordCountSecondRange)
              this.popularReadingWordCountChart(
                wordCountFirstRange.concat(wordCountSecondRange)
              )
            } else {
              this.popularReadingWordCountChart(wordCountFirstRange)
            }

            // display the charts now.

            this.setInsightsLoader(false)
            const self = this
            setTimeout(function () {
              self.setShowChartsLoader(true)
            }, 500)

            if (this.getTopicsFilters.topics.date.value) {
              console.debug('Set Topic Filters')
              const splitDates =
                this.getTopicsFilters.topics.date.value.split(' - ')
              setTimeout(function () {
                $('.datepicker_filter input[name="daterange"]')
                  .data('daterangepicker')
                  .setStartDate(splitDates[0].replace('T', ' '))
                $('.datepicker_filter input[name="daterange"]')
                  .data('daterangepicker')
                  .setEndDate(splitDates[1].replace('T', ' '))
              }, 2500)
            }
          }
        },
        (response) => {}
      )
    },
    /**
     * Sentiments bucket list through which we can get the percentage values.
     * @param sentiments
     */
    sentimentEngagementAndPercentageCalculator (sentiments) {
      const totalDocs = sentiments.doc_count
      const sentimentsCount = {
        percentage: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        engagement: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        docs: {
          positive: 0,
          negative: 0,
          neutral: 0
        }
      }
      sentiments.sentiment_labels.buckets.forEach(function (item) {
        const itemDocCount = item.doc_count || 0
        if (item.key == 'neutral') {
          sentimentsCount.percentage.neutral = (
            (item.doc_count / totalDocs) *
            100
          ).toFixed(0)
          sentimentsCount.docs.neutral = itemDocCount
          sentimentsCount.engagement.neutral = parseInt(
            item.social_shares.average_engagements.value.toFixed(0)
          )
        } else if (item.key == 'positive') {
          sentimentsCount.percentage.positive = (
            (item.doc_count / totalDocs) *
            100
          ).toFixed(0)
          sentimentsCount.docs.positive = itemDocCount
          sentimentsCount.engagement.positive = parseInt(
            item.social_shares.average_engagements.value.toFixed(0)
          )
        } else if (item.key == 'negative') {
          sentimentsCount.percentage.negative = (
            (item.doc_count / totalDocs) *
            100
          ).toFixed(0)
          sentimentsCount.docs.negative = itemDocCount
          sentimentsCount.engagement.negative = parseInt(
            item.social_shares.average_engagements.value.toFixed(0)
          )
        }
      })

      this.setInsightsSentimentCount(sentimentsCount)
    },
    /**
     * bucket list of categories with the docs and engagement value
     * @param contentList
     */
    popularContentTypes (contentList) {
      const contentTypes = {
        categories: [],
        engagements: [],
        docs: []
      }
      contentList.buckets.forEach(function (item) {
        let average = 0
        if (item.average.value) {
          average = Math.ceil(item.average.value)
        }
        contentTypes.categories.push(item.key)
        contentTypes.engagements.push(average)
        contentTypes.docs.push(item.doc_count)
      }, this)
      this.setInsightsContentTypes(contentTypes)
    },
    insightsEngagement (aggregation) {
      const engagement = {
        average: {
          facebook: parseInt(aggregation.average_facebook.value) || 0,
          pinterest: parseInt(aggregation.average_pins.value) || 0,
          reddit: parseInt(aggregation.average_reddit.value) || 0
        },
        sum: {
          facebook: parseInt(aggregation.total_facebook.value) || 0,
          pinterest: parseInt(aggregation.total_pins.value) || 0,
          reddit: parseInt(aggregation.total_reddit.value) || 0
        }
      }
      console.debug('Setter', engagement)
      this.setInsightsEngagement(engagement)
    },
    popularDomainsChart (bucket) {
      const domainsList = []
      bucket.forEach(function (item) {
        console.debug(item)
        domainsList.push([item.key, item.doc_count])
      }, this)
      this.setInsightsTopDomainsForChart(domainsList)
      this.setInsightsTopDomains(bucket)
    },
    popularReadingLevelsChart (bucket) {
      const readingLevel = {
        categories: [],
        articles: [],
        interactions: []
      }
      bucket.forEach(function (item) {
        console.debug(item)
        readingLevel.categories.push(item.key)
        readingLevel.articles.push(item.doc_count)
        readingLevel.interactions.push(parseInt(item.avg_stats.value))
      }, this)
      this.setInsightsReadingLevels(readingLevel)
    },
    popularReadingWordCountChart (bucket) {
      const wordCountList = {
        categories: [],
        articles: [],
        interactions: []
      }
      let count = 0
      bucket.forEach(function (item) {
        count += 1
        let docCount = 0
        let avgStats = 0
        if (count <= bucket.length - 1) {
          console.debug(item)
          let countValue = parseInt(item.key)
          const nextItem = bucket[count]

          countValue =
            this.intToString(countValue).toString() +
            ' - ' +
            this.intToString(nextItem.key).toString()
          docCount = item.doc_count
          avgStats = item.avg_stats.value

          wordCountList.categories.push(countValue)
          wordCountList.articles.push(docCount)
          wordCountList.interactions.push(parseInt(avgStats))
        }
      }, this)
      this.setInsightsPopularWordCount(wordCountList)
    },
    wordCloud (bucket) {
      const wordcloudList = []
      bucket.forEach(function (item) {
        const removeHashtag = item.key.replace('#', '')
        const splitWord = removeHashtag.replace(/([a-z])([A-Z])/g, '$1 $2')
        wordcloudList.push({
          name: splitWord,
          weight: item.doc_count
        })
      })

      this.setWordCloud(wordcloudList)
    }
  },
  created () {}
}
export { chartsMixin }
