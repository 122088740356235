var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topics-cover-stories-container__item"},[_c('div',{staticClass:"topics-cover-stories-container__item__inner"},[_c('div',{staticClass:"topics-cover-stories-container__item__inner__image__container"},[_c('div',{staticClass:"top-cover-stories-action-buttons"},[_c('div',{staticClass:"top-cover-stories-action-buttons__list"},[_c('b-dropdown',{staticClass:"studio-icon-theme-dropdown",attrs:{"id":"dropdown-share-articles","variant":"studio-icon-theme","dropright":"","no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Share this post'),expression:"'Share this post'"}],staticClass:"far fa-share-alt icon"})]),_c('b-dropdown-item',{attrs:{"variant":"studio-icon-theme"},on:{"click":function($event){$event.preventDefault();return _vm.initializeArticlePostCreation(
                  _vm.post._source.id,
                  'social_share_modal',
                )}}},[_c('i',{staticClass:"far fa-share share-ico-align"}),_vm._v(" Social Media ")]),_c('b-dropdown-item',{attrs:{"variant":"studio-icon-theme"},on:{"click":function($event){$event.preventDefault();return _vm.initializeArticlePostCreation(
                  _vm.post._source.id,
                  'blog_share_modal',
                )}}},[_c('i',{staticClass:"far fa-rss"}),_vm._v(" Blog Post ")])],2),_c('b-dropdown',{ref:"dropdown_favorite",staticClass:"studio-icon-theme-dropdown mt-3",attrs:{"id":"dropdown-favorite-articles","variant":"studio-icon-theme","dropright":"","menu-class":{
                '!hidden': _vm.post._source.is_favorite,
                },"no-caret":"","block":""}},[(_vm.post._source.is_favorite)?_c('template',{slot:"button-content"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Unfavorite'),expression:"'Unfavorite'"}],staticClass:"far fa-heart icon is-favorite",on:{"click":function($event){return _vm.unfavoritePostItemEventFire(_vm.post._source.id)}}}),_c('span',{staticClass:"icon_animate",class:{ ' active': _vm.post._source.is_active }})]):_c('template',{slot:"button-content"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Favorite'),expression:"'Favorite'"}],staticClass:"far fa-heart icon",class:{ 'animated tada': _vm.post._source.is_active }})]),_c('BDropdownFavoriteArticle',{directives:[{name:"show",rawName:"v-show",value:(!_vm.post._source.is_favorite),expression:"!post._source.is_favorite"}],attrs:{"post_id":_vm.post._source.id,"post-index":_vm.index}})],2),_c('b-dropdown',{staticClass:"studio-icon-theme-dropdown mt-3",attrs:{"id":"dropdown-actions-articles","variant":"studio-icon-theme","dropleft":"","no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"cs-dots-h icon"})]),_c('b-dropdown-item',{attrs:{"variant":"studio-icon-theme","target":"_blank","href":_vm.savePocketContent(_vm.post._source.url)}},[_c('i',{staticClass:"fab fa-get-pocket"}),_vm._v(" Add to Pocket ")]),(
                _vm.getTopicType === 'archived' &&
                _vm.$route.params &&
                _vm.$route.params.module === 'archived'
              )?_c('b-dropdown-item',{attrs:{"variant":"studio-icon-theme"},on:{"click":function($event){$event.preventDefault();return _vm.unarchive({ post_id: _vm.post._source.id, index: _vm.index })}}},[_c('i',{staticClass:"far fa-archive"}),_vm._v(" Unarchive ")]):_vm._e()],2)],1)]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.backgroundImageURL(_vm.post._source)),expression:"backgroundImageURL(post._source)",arg:"background-image"}],staticClass:"topics-cover-stories-container__item__inner__image__container__source"})]),_c('div',{staticClass:"topics-cover-stories-container__item__inner__content"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.limitTextLength(_vm.post._source.title, 48))},on:{"click":_vm.searchWebPreview}}),_c('p',{staticClass:"topics-cover-stories-container__item__inner__content__date"},[_vm._v(" "+_vm._s(_vm.$filters.relative(_vm.post._source.post_date)))]),_c('p',{staticClass:"topics-cover-stories-container__item__inner__content__domain"},[_vm._v(" "+_vm._s(_vm.trimURL(_vm.post._source.domain_url)))]),(_vm.post._source.author)?[_c('p',{staticClass:"topics-cover-stories-container__item__inner__content__author"},[_vm._v(" By "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View articles from this author'),expression:"'View articles from this author'"}],domProps:{"innerHTML":_vm._s(_vm.post._source.author)},on:{"click":function($event){_vm.changeRouteForCoverStoryView(
                _vm.post._source.author,
                _vm.trimURL(_vm.post._source.domain_url),
              )}}}),_vm._l((_vm.getTwitterAccountsForView(
              _vm.post._source.twitter_accounts,
            )),function(account,index){return (
              _vm.post._source.twitter_accounts &&
              _vm.post._source.twitter_accounts.length > 0 &&
              index < 1
            )?_c('a',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.openTwitterLinkForInfluencer(account)}}},[_vm._v(" "+_vm._s(account))]):(_vm.post._source.twitter_handler)?_c('a',{attrs:{"target":"_blank","href":_vm.getTwitterLink(_vm.post._source.twitter_handler)}},[_vm._v(" "+_vm._s(_vm.post._source.twitter_handler))]):_vm._e()}),(_vm.loader)?_c('clip-loader',{staticClass:"d-inline-block ml-1",attrs:{"color":'#436aff',"size":'12px'}}):_vm._e()],2)]:(
          _vm.post._source.twitter_accounts &&
          _vm.post._source.twitter_accounts.length > 0
        )?[_c('p',{staticClass:"topics-cover-stories-container__item__inner__content__author"},[_vm._v(" By "),_vm._l((_vm.getTwitterAccountsForView(
              _vm.post._source.twitter_accounts,
            )),function(account,index){return (index < 1)?_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.openTwitterLinkForInfluencer(account)}}},[_vm._v(_vm._s(account))]):_vm._e()}),(_vm.loader)?_c('clip-loader',{staticClass:"d-inline-block ml-1",attrs:{"color":'#436aff',"size":'12px'}}):_vm._e()],2)]:_vm._e(),(_vm.post._source.twitter_handler)?[_c('p',{staticClass:"topics-cover-stories-container__item__inner__content__author"},[_vm._v(" By "),_c('a',{attrs:{"target":"_blank","href":_vm.getTwitterLink(_vm.post._source.twitter_handler)}},[_vm._v(_vm._s(_vm.post._source.twitter_handler))])])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }