<template>
  <div
    class="dropdown option_dropdown default_style_dropdown"
    :class="{ disable_click: getTopicDisabled }"
  >
    <div
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <i class="trending_icon icon_first cs-trending-chart"></i>
      <span class="text">{{ getFacebookContentFilter.sort }}</span>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>

    <div class="dropdown-menu dropdown-menu-left">
      <ul class="inner">
        <li class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="changeFacebookPostsSort('Recent')"
            >Recent</a
          >
        </li>
        <li class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="changeFacebookPostsSort('Engagement')"
            >Total Engagements</a
          >
        </li>
        <li class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="changeFacebookPostsSort('Like')"
            >Likes</a
          >
        </li>
        <li class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="changeFacebookPostsSort('Comment')"
            >Comments</a
          >
        </li>
        <li class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="changeFacebookPostsSort('Shares')"
            >Shares</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {},
  computed: {
    ...mapGetters(['getTopicDisabled', 'getFacebookContentFilter'])
  }
}
</script>
