<template>
  <div class="message m_out">
    <div class="profile_picture d-flex align-items-start justify-content-end">
      <clip-loader
        v-if="!message.has_failed && messageLoader"
        style="padding-right: 10px"
        color="rgba(152, 158, 181, 0.8)"
        :size="'20px'"
      ></clip-loader>

      <div class="text_block">
        <template>
          <template v-if="message.attachment != null">
            <span
              class="attachment_btn_custom"
              v-tooltip.top-center="message.attachment.name"
            >
              <i class="icon_custom fal fa-file" />
              {{ message.attachment.name }}
            </span>
          </template>
          <template v-else-if="message.event_type === 'note'">
            <div
              class="__text"
              style="
                background-color: rgb(251, 252, 225);
                white-space: pre-line;
              "
              :class="[
                commonMethods.isIncomingMessage(temp, message)
                  ? '_user_chat'
                  : '',
              ]"
            >
              {{ message.message }}
            </div>
          </template>
          <template v-else>
            <div
              class="__text"
              :class="[
                commonMethods.isIncomingMessage(temp, message)
                  ? '_user_chat'
                  : '',
              ]"
              v-html="getMentionedUsersNames(message.message)"
            ></div>
          </template>
          <div v-if="message.has_failed" class="failed_send">
            <p
              >Message wasn't sent.
              <i
                class="fas fa-exclamation warning_icon_sm has-tooltip"
                v-b-popover.hover.html.bottom.leftbottom="message.error_message"
              ></i>
              <!--                            <span-->
              <!--                                @click="sendMessage"-->
              <!--                                class="link">Retry-->
              <!--                            </span> or-->
              <!--                            <span-->
              <!--                                @click="$emit('removeMessage',index, message.uuid)" class="link">Delete-->
              <!--                            </span>-->
            </p>
          </div>
        </template>
      </div>
      <div class="picture_block picture_block_background">
        <div
          v-if="message.event_type === 'note'"
          class="img"
          v-bind:style="'background: url(' + temp.getProfile.image + ')'"
        >
        </div>
        <div
          v-else
          class="img"
          v-bind:style="
            'background: url(' +
            commonMethods.getSocialDetail(temp, temp.activeInboxDetail).image +
            ')'
          "
        >
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.warning_icon_sm {
  border-radius: 50%;
  font-size: 0.65rem;
  z-index: 3;
  background: fade(#ff3366, 80%);
  padding: 3px 6px;
  color: #fff;
}
.icon_custom {
  border-radius: 2px;
  float: left;
  margin-right: 0.625rem;
  background: #e1e1e1;
  padding: 4px;
}

.attachment_btn_custom {
  border-radius: 5px;
  padding: 8px;
  display: inline-block;
  vertical-align: middle;
  background: white;
}

.failed_send {
  padding-top: 5px;
  padding-left: 5px;

  .link {
    color: #00aced;
    font-weight: 500;
    cursor: pointer;
  }

  p {
    color: fade(#ff3366, 80%);
    font-size: 12px;
  }
}

.time_block {
  float: right;
  color: fade(#989eb5, 80%);
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'

export default {
  props: [
    'message',
    'temp',
    'index',
    'messageIndex',
    'messageLoader',
    'appendInActiveMessages',
    'removeMessage',
    'emitEventForNote'
  ],
  components: {},
  data () {
    return {
      commonMethods: commonMethods,
      hasFailed: null
    }
  },
  computed: {
    ...mapGetters([]),
    hasFail: {
      // getter
      get: function () {
        return this.message.has_failed ? this.message.has_failed : null
      },
      // setter
      set: function (newValue) {
        this.hasFailed = newValue
      }
    }
  },
  mounted () {
    this.hasFailed = this.hasFail

    this.$nextTick(function () {
      this.hasFailed = this.hasFail
    })
  },
  methods: {
    ...mapActions(['reply', 'addNoteToInboxDetails']),
    async sendMessage () {
      if (this.message.has_failed) {
        if (this.message.event_type === 'note') {
          await this.sendForNote()
        } else if (this.message.event_type === 'message') {
          await this.sendForMessage()
        }
      }
    },
    async sendForNote () {
      this.message.has_failed = false
      const response = await this.addNoteToInboxDetails(this.message.payload)
      if (response.isValid) {
        this.appendInActiveMessages(this.index, response.note)
        this.emitEventForNote(response.note)
        this.removeMessage(this.index, this.message.uuid)
      } else {
        this.message.has_failed = true
      }
    },
    async sendForMessage () {
      this.message.has_failed = false
      const response = await this.reply(this.message.payload)
      if (response.isValid) {
        this.appendInActiveMessages(this.index, response.response)
        this.removeMessage(this.index, this.message.uuid)
      } else {
        this.message.has_failed = true
      }
    }
  },
  watch: {}
}
</script>
