<template>
  <div class="media-library-tab">
    <PreviewMediaAssetModal
      modal-id="preview-media-asset-modal-composer"
      :current-media="currentItemInPreview"
      :disable-right="disabledRight"
      :disable-left="disabledLeft"
      @next-image="loadNextImage"
      @previous-image="loadPreviousImage"
      @delete="deleteItem"
      @share="shareItem"
    />
    <div class="d-flex">
      <div ref="foldersList" class="media-library-tab__folders">
        <div class="media-tab__header !pl-0"> Folders </div>

        <div
          id="media-tab-all-upload"
          class="media-library-sidebar__option mt-3 active"
          @click="handleClick('all', null, 'All Uploads')"
        >
          <div class="media-library-sidebar__option__item">
            <div class="media-library-sidebar__option__item-icon">
              <i class="far fa-layer-group"></i>
            </div>
            <div class="media-library-sidebar__option__item-text mr-2">
              All Uploads
            </div>
          </div>
          <div class="media-library-sidebar__option__item-count">
            {{ filesCount.all }}
          </div>
        </div>

        <div
          id="media-tab-uncategorized-upload"
          class="media-library-sidebar__option"
          @click="handleClick('uncategorized', null, 'Uncategorized')"
        >
          <div class="media-library-sidebar__option__item">
            <div class="media-library-sidebar__option__item-icon">
              <i class="far fa-folder"></i>
            </div>
            <div class="media-library-sidebar__option__item-text mr-2">
              Uncategorized
            </div>
          </div>
          <div class="media-library-sidebar__option__item-count">
            {{ filesCount.uncategorized }}
          </div>
        </div>

        <div class="sidebar-divider"></div>

        <template v-if="isFolderFetching">
          <div class="stack-folder__wrapper px-0">
            <SkeletonBox width="100%" height="36px" radius="12px" />
          </div>
          <div class="stack-folder__wrapper px-0">
            <SkeletonBox width="100%" height="36px" radius="12px" />
          </div>
        </template>
        <template v-else>
          <div class="media-library-tab__folders-list">
            <div
              v-if="folders.length === 0"
              class="media-library-sidebar__note"
            >
              <i>You don't have any folder yet.</i>
            </div>
            <Folder
              v-for="(folder, i) in folders"
              v-else
              :key="i"
              type="secondary"
              :folder="folder"
              @folderclick="handleClick"
            />
          </div>
        </template>
      </div>

      <div class="media-library-tab__content">
        <div class="media-tab__header">
          <span :title="selectedFolderName">{{ selectedFolderName }}</span>
          <div v-if="mediaAssets.length > 0" class="media-tab__header-count">
            {{ mediaAssets.length }} File{{ mediaAssets.length > 1 ? 's' : '' }}
          </div>
        </div>

        <div class="media-tab__body media-tab__padding-left">
          <div class="media-tab__filters">
            <FiltersBarState
              :show-search="true"
              :show-bulk="selectedAssets.length > 1"
              :is-operating="isMediaDeleting"
              :disabled="false"
              :folders="null"
              @type="changeType"
              @sort="changeSort"
              @search="changeSearch"
              @unselect="unSelectAll"
              @remove="removeBulk"
            />
          </div>

          <div
            class="media-tab__items media-tab__items--min"
            @scroll="handleScroll"
          >
            <template
              v-if="mediaAssets.length === 0 && isMediaFetching === false"
            >
              <div
                class="d-flex w-100 justify-content-center flex-column align-items-center mt-4"
              >
                <img
                  draggable="false"
                  src="../../../../../../assets/img/no_data_found.svg"
                  alt=""
                  width="250"
                />
                <i>Media not found</i>
              </div>
            </template>

            <div class="item-row mx-auto mt-2">
              <template v-if="isMediaFetching">
                <div class="item-card">
                  <SkeletonBox width="100%" height="166px" radius="12px" />
                </div>
                <div class="item-card">
                  <SkeletonBox width="100%" height="166px" radius="12px" />
                </div>
                <div class="item-card">
                  <SkeletonBox width="100%" height="166px" radius="12px" />
                </div>
              </template>

              <template v-else>
                <VirtualAssets
                  :assets="mediaAssets"
                  :at-bottom="at_bottom"
                  @check="selectItem"
                  @delete="deleteItem"
                  @view="viewItem"
                />
              </template>
              <!--            <template>-->
              <!--              <div  v-show="at_bottom" class="item-card">-->
              <!--                <SkeletonBox width="100%" height="166px" radius="12px" />-->
              <!--              </div>-->
              <!--              <div  v-show="at_bottom" class="item-card">-->
              <!--                <SkeletonBox width="100%" height="166px" radius="12px" />-->
              <!--              </div>-->
              <!--            </template>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="type !== 'library'"
      class="media-tab__footer d-flex flex-row-reverse"
    >
      <div class="media-tab__footer-actions">
        <button
          class="btn btn-studio-theme-space"
          :disabled="selectedAssets.length === 0"
          @click="handleInsert"
        >
          Add Media
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import Folder from '@src/modules/publish/components/media-library/components/Folder'
import isEmpty from 'is-empty'
import FiltersBarState from '@src/modules/publish/components/media-library/components/FiltersBarState'
import VirtualAssets from '@src/modules/publish/components/media-library/components/VirtualAssets'
import PreviewMediaAssetModal from '@src/modules/publish/components/media-library/components/PreviewMediaAssetModal'
import { videoDefaultSharingDetails } from '@src/modules/composer_v2/views/composerInitialState'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'MediaLibraryTab',
  components: {
    Folder,
    SkeletonBox,
    FiltersBarState,
    VirtualAssets,
    PreviewMediaAssetModal,
  },
  mixins: [MediaHelperMixin],
  props: {
    folders: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    filesCount: {
      type: Object,
      default: () => {},
    },
    isFolderFetching: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
    existingMedia: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMediaFetching: false,
      isMediaDeleting: false,
      selectedFolderName: 'All Uploads',
      selectedFolderId: 'all',
      mediaAssets: [],
      mediaMeta: {},
      at_bottom: false,
      selectedAssets: [],
      removeableAssets: [],
      filters: {
        folder: null,
        sort: null,
        type: null,
        search: null,
      },
      currentItemInPreview: {},
      currentImageIndex: -1,
    }
  },
  computed: {
    disabledRight() {
      return this.currentImageIndex === this.mediaAssets.length - 1
    },
    disabledLeft() {
      return this.currentImageIndex === 0
    },
  },
  mounted() {
    this.fetchMediaAssets()

    EventBus.$on('delete-media', async () => {
      if (this.removeableAssets.length === 0) return
      this.isMediaDeleting = true
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
      this.loadPreviousImage(true)

      await this.removeMediaAssetsHelper(
        {
          ids: this.removeableAssets,
        },
        false,
        (status, removed) => {
          if (status && removed.length > 0) {
            this.fetchMediaAssets()
            this.$emit('fetch-folder')
            EventBus.$emit('refetch-media-limits')
            this.selectedAssets = []
          }
        }
      )

      this.isMediaDeleting = false
    })

    EventBus.$on('archive-media', async () => {
      if (this.removeableAssets.length === 0) return
      this.isMediaDeleting = true
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
      this.loadPreviousImage(true)
      await this.removeMediaAssetsHelper(
        {
          ids: this.removeableAssets,
        },
        true,
        (status, removed) => {
          if (status && removed.length > 0) {
            this.fetchMediaAssets()
            this.$emit('fetch-folder')
            EventBus.$emit('refetch-media-limits')
            this.selectedAssets = []
          }
        }
      )

      this.isMediaDeleting = false
    })
  },
  beforeDestroy() {
    // Clearing State
    this.isMediaFetching = false
    this.isMediaDeleting = false

    this.mediaAssets = []
    this.mediaMeta = {}
    this.at_bottom = false
    this.selectedAssets = []
    this.selectedFolderId = 'all'

    EventBus.$off('delete-media')
    EventBus.$off('archive-media')
  },
  methods: {
    handleInsert() {
      console.debug('Method::handleInsert')

      const itemsToInsert = this.mediaAssets.filter((asset) =>
        this.selectedAssets.includes(asset._id)
      )

      // Setting the selected items in state
      this.setWidgetSelectedUploadedFilesItems([...itemsToInsert])

      // insertFile
      this.insertFile(this.source, this.existingMedia)
    },
    changeType(type) {
      console.debug('change log')
      this.filters.type = type
      this.fetchMediaAssets()
    },
    changeSort(sort) {
      this.filters.sort = sort
      this.fetchMediaAssets()
    },
    changeSearch(search) {
      this.filters.search = search
      this.fetchMediaAssets()
    },
    async removeBulk() {
      console.log('removeBulk', this.selectedAssets)
      this.removeableAssets = this.selectedAssets
      this.$bvModal.show('mediaRemovalConfirmationDialog')
    },
    unSelectAll() {
      this.selectedAssets = []
      const elements = document.querySelectorAll('[data-item-selected="true"]')

      elements.forEach((el) => {
        el.setAttribute('data-item-selected', false)
      })
    },
    selectItem(element) {
      console.debug('selectItem', element)
      const id = element.getAttribute('id')

      const isExist = this.selectedAssets.filter((item) => item === id)

      if (!isExist.length) {
        this.selectedAssets.push(id)
        element.setAttribute('data-item-selected', true)
      } else {
        const index = this.selectedAssets.indexOf(id)
        this.selectedAssets.splice(index, 1)
        element.setAttribute('data-item-selected', false)
      }
    },
    async deleteItem(id, key) {
      this.removeableAssets = [id]
      this.$bvModal.show('mediaRemovalConfirmationDialog')
    },
    isVideo(mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },
    viewItem(item) {
      console.log('METHOD::viewItem ~ item -> ', item)
      this.currentItemInPreview = item
      this.currentImageIndex = this.mediaAssets.findIndex(
        (media) => media._id === item._id
      )
      this.$bvModal.show('preview-media-asset-modal-composer')
    },
    handleClick(folderId, isRoot, name) {
      console.debug('Method:handleClick', folderId)

      const rootElement = this.$refs.foldersList

      this.selectedFolderName = name
      this.selectedFolderId = folderId
      this.fetchMediaAssets(true)

      this.toggleActiveClass(rootElement, folderId)
    },
    toggleActiveClass(el, folderId) {
      console.log(el, this.selectedFolderId)
      el.querySelectorAll('.stack-folder__item').forEach((item) => {
        item.classList.remove('active')
      })

      el.querySelectorAll('.media-library-sidebar__option').forEach((item) => {
        item.classList.remove('active')
      })

      if (this.selectedFolderId === 'uncategorized') {
        document
          .getElementById('media-tab-uncategorized-upload')
          .classList.add('active')
      } else if (this.selectedFolderId === 'all') {
        document.getElementById('media-tab-all-upload').classList.add('active')
      } else {
        const currentElement = document.getElementById(folderId)
        currentElement
          .querySelector('.stack-folder__item')
          .classList.add('active')
      }
    },
    async handleScroll(el) {
      const buffer = 10
      const scrollHeight = el.target.scrollHeight
      const totalHeight = el.target.offsetHeight + el.target.scrollTop + buffer

      if (
        totalHeight >= scrollHeight &&
        !this.at_bottom &&
        !isEmpty(this.mediaMeta) &&
        !this.mediaMeta.completed
      ) {
        this.at_bottom = true
        console.log('at the bottom')

        await this.fetchMediaAssets(false)
        this.at_bottom = false
      }
    },
    async fetchMediaAssets(clear = true, page = 1, limit = 40) {
      if (clear) {
        this.isMediaFetching = true
        this.mediaAssets = []
        this.mediaMeta = {}
        this.selectedAssets = []
      }

      let currentPage = page
      let nextPage = page
      let total = null
      let isCompleted = false

      if (!isEmpty(this.mediaMeta)) {
        currentPage = this.mediaMeta.current_page
        nextPage = currentPage + 1
        total = this.mediaMeta.total
        isCompleted = this.mediaMeta.completed
      }

      const payloadFilters = {
        page: nextPage,
        limit: limit,
      }

      // Adding filters in payload
      if (!isEmpty(this.filters)) {
        for (const filterName in this.filters) {
          if (!(filterName === 'type' && this.filters[filterName] === 'all')) {
            payloadFilters[filterName] = this.filters[filterName]
          }

          // Folder Case
          if (filterName === 'folder') {
            if (this.filters[filterName] === 'uncategorized') {
              payloadFilters.folder_id = null
            } else if (
              this.filters[filterName] !== 'all' &&
              this.filters[filterName] !== null
            ) {
              payloadFilters.folder_id = this.filters[filterName]
            }
          }
        }
      }

      if (this.selectedFolderId === 'uncategorized') {
        payloadFilters.folder_id = null
      } else if (this.selectedFolderId !== 'all') {
        payloadFilters.folder_id = this.selectedFolderId
      }

      if (total === null || !isCompleted) {
        await this.fetchMediaHelper(payloadFilters, (status, media) => {
          if (clear) {
            this.mediaAssets = []
          }

          if (media.data.length) {
            // Saving Media Files
            this.mediaAssets = [...this.mediaAssets, ...media.data]

            // Saving Media Meta
            this.mediaMeta = {
              current_page: media.current_page,
              count: this.mediaAssets.length,
              total: media.total,
              completed: media.total === media.to,
            }
          }
        })
      }

      if (clear) {
        this.isMediaFetching = false
      }
    },
    /**
     * Loads next available image for preview. Fetches more media /w pagination when available
     * @returns {Promise<void>}
     */
    async loadNextImage() {
      if (this.currentImageIndex === this.mediaAssets.length - 1) return
      this.currentImageIndex = this.mediaAssets.findIndex(
        (item) => item._id === this.currentItemInPreview._id
      )
      // when to fetch more media.
      if (
        this.currentImageIndex < this.mediaAssets.length - 5 &&
        this.mediaAssets.length - 1 - this.currentImageIndex === 5
      ) {
        // fetch more media and enable api loader
        if (this.isMediaFetching) return
        await this.fetchMediaAssets(
          false,
          this.selectedFolder,
          this.combinedFilters
        )
        this.at_bottom = false

        if (!this.isAllSelected.total) {
          this.isAllSelected.visible = false
        } else {
          this.selectAllVisibleItems()
        }
      }
      this.currentItemInPreview = this.mediaAssets[++this.currentImageIndex]
    },
    /**
     * Loads preview image for preview on click and on current media removal
     */
    loadPreviousImage(afterDelete = false) {
      if (afterDelete && this.currentImageIndex === 0) {
        this.$bvModal.hide('preview-media-asset-modal-composer')
        return
      }
      if (!afterDelete && this.currentImageIndex <= 0) return
      this.currentImageIndex = this.mediaAssets.findIndex(
        (item) => item._id === this.currentItemInPreview?._id
      )
      this.currentItemInPreview = this.mediaAssets[--this.currentImageIndex]
    },
    async shareItem(item) {
      this.$bvModal.hide('preview-media-asset-modal-composer')
      if (item === true) {
        this.setWidgetSelectedUploadedFilesItems(
          this.mediaAssets.filter((asset) => this.selected.includes(asset._id))
        )
      } else this.setWidgetSelectedUploadedFilesItems([item])
      if (
        (await this.insertFile('share-media', {
          image: [],
          video: videoDefaultSharingDetails,
        })) !== false
      ) {
        this.$bvModal.show('composer-modal')
      }
    },
  },
}
</script>
