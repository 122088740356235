<template>
  <div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Pinterest'"
          :current="1"
          :total="total"
        ></report-header>

        <engagement-boxes
          :pins="metrics.aggregations.pins_count"
          :engagement="metrics.aggregations.total_engagement"
          :boards="metrics.insights.total_boards"
          :followers="metrics.insights.total_followers"
          :following="metrics.insights.total_following"
        ></engagement-boxes>

        <followers
          :followers="metrics.aggregations.followers_growth"
          :total_followers="metrics.insights.total_followers"
        ></followers>

        <published-posts-with-engagement
          :posts="metrics.aggregations.published_posts"
          engagement_message="Avg. Engagement"
        ></published-posts-with-engagement>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Pinterest'"
          :current="2"
          :total="total"
        ></report-header>
        <engagement-rate
          :posts="metrics.aggregations.published_posts"
          :comments="metrics.aggregations.comments_count"
          :repins="metrics.aggregations.repins_count"
          :total_engagement="metrics.aggregations.total_engagement"
        ></engagement-rate>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Pinterest'"
          :current="3"
          :total="total"
        ></report-header>
        <content-distribution-daily
          :distribution="metrics.aggregations.content_distribution_daily"
        ></content-distribution-daily>
        <heatmap-chart
          :engagement="metrics.aggregations.total_engagement"
          :best_time="metrics.aggregations.best_time"
        ></heatmap-chart>
      </div>
    </div>
    <div class="page_view">
      <div class="subpage">
        <report-header
          :report_name="'Pinterest'"
          :current="4"
          :total="total"
        ></report-header>
        <top-posts :posts="metrics.top_posts" :report="true"></top-posts>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ReportHeader from '../ReportHeader'
import EngagementBoxes from '../../pinterest/statistics/EngagementBoxes'
import Followers from '../../pinterest/charts/Followers'
import EngagementRate from '../../pinterest/charts/EngagementRate'
import TopPosts from '../../pinterest/statistics/TopPosts'
import PublishedPostsWithEngagement from '../../common/charts/PublishedPostsWithEngagement'
import ContentDistributionDaily from '../../common/charts/ContentDistributionDaily'
import HeatmapChart from '../../common/charts/HeatmapChart'

export default {
  data () {
    return {
      total: 4
    }
  },
  props: {
    metrics: {}
  },
  components: {
    ReportHeader,
    EngagementBoxes,
    Followers,
    PublishedPostsWithEngagement,
    EngagementRate,
    TopPosts,
    ContentDistributionDaily,
    HeatmapChart
  }
}
</script>
