<template>
  <div class="articles_list">
    <div class="article_inner clear">
      <beat-loader
        :color="'#436aff'"
        v-if="getQuoteLoader.search.content.quoteLoader"
      ></beat-loader>
      <template v-else>
        <div
          class="ds_quote_box_grid fb_quote_box_grid article_box_grid"
          v-for="post in getQuotes"
        >
          <div class="box_inner">
            <div class="image_block">
              <div
                class="img"
                v-lazy:background-image="backgroundQuoteImageURL(post._source)"
              ></div>
            </div>
            <div class="content_block">
              <p
                style="min-height: 84px"
                class="desc"
                v-html="limitTextLength(post._source.quote, 183)"
              ></p>
              <!---->
              <p class="author_name" style="min-height: 21px">
                <template v-if="post._source.author">
                  - {{ post._source.author }}</template
                >
                <template v-else> - Anonymous</template>
              </p>

              <div class="btn_block d-flex text-center">
                <div class="btn_block_inner">
                  <div
                    class="article_share_dropdown article_post_dropdown inline_block dropdown default_style_dropdown"
                  >
                    <div
                      class="dropdown_header"
                      data-toggle="dropdown"
                      @click="initializeQuotesPostCreation(post._source)"
                    >
                      <button
                        class="btn light_gray"
                        v-tooltip.top-center="'Share this post'"
                      >
                        <i class="icon-Share_icon d-block"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div
          class="no_data_found_content"
          v-if="
            getQuoteLoader.search.content.infiniteLoader &&
            getQuotes.length == 0
          "
        >
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <h2>No results found</h2>
            <p
              >No results found for your query. Please try to change your search
              query.</p
            >
          </div>
        </div>
        <div
          class="no_data_found_content"
          v-if="
            getQuoteLoader.search.content.infiniteLoader &&
            getQuotes.length <= 19
          "
        >
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <p
              >No More results found for your query. Please try to change your
              search query.</p
            >
          </div>
        </div>

        <infinite-loading v-else @infinite="onInfinite" ref="infiniteLoading">
          <span slot="spinner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>

          <div slot="no-more" class="not_found_text">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <h2>No more results found</h2>
                <p>No more results found for your query.</p>
              </div>
            </div>
          </div>
          <div slot="no-results">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <!--<h2>No more results found</h2>-->
                <p v-if="getQuotes.length == 0"
                  >No results found for your query.</p
                >
                <p v-else>No more results found for your query.</p>
                <p
                  >Please try a different search string or change search
                  filters.</p
                >
              </div>
            </div>
          </div>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading
  },

  data () {
    return {}
  },
  created () {
    this.initializeQuotes()

    //      this.getWebQuotes()
  },
  computed: {
    ...mapGetters([
      'getQuotes',
      'getQuoteLoader',
      'getQuoteScroll',
      'getQuotePage'
    ])
  },

  methods: {
    ...mapActions([
      'setQuoteLoader',
      'setQuotesPage',
      'setQuotesSearch',
      'setQuotes',
      'setQuoteInfiniteLoader'
    ]),
    /*
     * loader for the show more page.
     * */
    onInfinite ($state) {
      console.log('On infinite', this.getQuoteScroll, this.getQuotePage)
      if (this.getQuoteScroll) {
        // if (this.getCurationTab == 'Article') {
        this.setQuotesPage(this.getQuotePage + 1)
        this.getWebQuotes($state)
        // }
      }
    }
  },

  watch: {}
}
</script>
