export const baseUrl = process.env.VUE_APP_BASE_URL
export const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN
export const authenticationBaseUrl = process.env.VUE_APP_AUTHENTICATION_BASE_URL
export const accountBaseUrl = process.env.VUE_APP_ACCOUNT_BASE_URL
export const settingsBaseUrl = process.env.VUE_APP_SETTINGS_BASE_URL
export const integrationsBaseURL = process.env.VUE_APP_INTEGRATIONS_BASE_URL
export const discoveryBaseUrl = process.env.VUE_APP_DISCOVERY_BASE_URL
export const feederBaseURL = process.env.VUE_APP_FEEDER_BASE_URL
export const influencerBaseURL = process.env.VUE_APP_INFLUENCER_BASE_URL
export const automationBaseUrl = process.env.VUE_APP_AUTOMATION_BASE_URL
export const composerBaseUrl = process.env.VUE_APP_COMPOSER_BASE_URL
export const plannerBaseUrl = process.env.VUE_APP_PLANNER_BASE_URL
export const storageBaseUrl = process.env.VUE_APP_STORAGE_BASE_URL
export const analyticsBaseUrl = process.env.VUE_APP_ANALYTICS_BASE_URL
export const adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL
export const billingBaseUrl = process.env.VUE_APP_BILLING_BASE_URL

export const loginURL = authenticationBaseUrl + 'login'
export const registerURL = authenticationBaseUrl + 'register'
export const accountExistURL = authenticationBaseUrl + 'accountExist'
export const isAuthenticatedURL = authenticationBaseUrl + 'isAuthenticated'
export const refreshTokenURL = authenticationBaseUrl + 'refresh'
export const verifyEmailURL = authenticationBaseUrl + 'verifyEmail/code'
export const forgotPasswordURL = authenticationBaseUrl + 'forgotPassword'
export const resetPasswordTokenURL =
  authenticationBaseUrl + 'resetPasswordToken'
export const recoverPasswordURL = authenticationBaseUrl + 'recoverPassword'
export const resendVerificationEmail =
  authenticationBaseUrl + 'resendVerificationEmail'
export const ssoLinksURL = integrationsBaseURL + 'fetchSSOLinks'
export const loginWithMagicLinkURL = authenticationBaseUrl + 'magicLink'
export const checkValidMagicTokenURL = authenticationBaseUrl + 'checkMagictoken'
export const setPasswordURL = authenticationBaseUrl + 'setPassword'
// export const publicPath = process.env.BASE_URL

export const aiImageGenerationApiUrl = plannerBaseUrl + 'fetchAiImages'

export const fetchAllCaptionsApiUrl = plannerBaseUrl + 'caption/fetchAll'

export const saveCaptionApiUrl = plannerBaseUrl + 'caption/save'

export const deleteCaptionApiUrl = plannerBaseUrl + 'caption/'

export const updateCaptionApiUrl = plannerBaseUrl + 'caption/update'

export const usedCaptionApiUrl = plannerBaseUrl + 'caption/used/'

export const APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT
export const APP_RELEASE_VERSION = process.env.VUE_APP_RELEASE_VERSION
export const DROPBOX_APP_KEY = process.env.VUE_APP_DROPBOX_APP_KEY

export const RELEASE_WIDGET_VERSION = process.env.VUE_APP_RELEASE_WIDGET_VERSION

export const TEXT_IMAGE_PREVIEW_URL = process.env.VUE_APP_TEXT_IMAGE_PREVIEW
