<template>
  <div>
    <chart-render
      :id="'insta_publishing_highlight'"
      :options="publishingBehaviour()"
      :ref_value="'instagram_publishing_chart'"
      :is_content_available="!isAudienceAvailable"
    ></chart-render>
  </div>
</template>

<script>
import ChartRender from '../../common/ChartRender'
import moment from 'moment'
import {
  darkBlueColor,
  darkGreenColor,
  darkYellowColor,
  lineChart,
  lineChartTooltip,
  lineLegend,
  linePlotOptions,
  splineCredits,
  splineLegend,
  splineTooltip,
  splineXAxis,
  splineYAxis,
  stackedColumnChart,
  stackedPlotOptions
} from '../../common/helper'
import { INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH } from '@common/constants/messages'

export default {
  props: {
    publishing_behaviour: {}
  },
  data () {
    return {
      tooltip_message: INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH
    }
  },
  computed: {
    isAudienceAvailable () {
      return true
    }
  },
  components: {
    ChartRender
  },
  methods: {
    mediaExists (array, key) {
      return array.some((e) => e.key === key)
    },
    publishingBehaviour () {
      const publishingBehaviour = {
        image: [],
        video: [],
        carousel_album: []
      }

      const keys = ['image', 'video', 'carousel_album']
      const lineChartTooltipModified = lineChartTooltip()
      // lineChartTooltipModified.pointFormat = '<p class="engagement_point"><span>{point.y:f}</span></p>'
      this.publishing_behaviour.buckets.forEach((item) => {
        if (item.doc_count > 0) {
          item.total_of_media_type.buckets.forEach((bucket) => {
            publishingBehaviour[bucket.key].push([
              Date.parse(item.key_as_string.split(' ')[0]),
              bucket.doc_count
            ])
            console.log(publishingBehaviour[bucket.key])
          })
        }
      })

      return {
        credits: splineCredits(),
        global: {
          timezoneOffset: this.getActiveWorkspace.timezone
        },
        tooltip: splineTooltip(),
        chart: stackedColumnChart('#insta_publishing_highlight'),
        legend: splineLegend(),

        colors: ['#3bca7a', '#fbdf46', '#536eff'],
        title: false,
        xAxis: splineXAxis(),
        yAxis: splineYAxis(),
        plotOptions: stackedPlotOptions(),
        series: [
          {
            name: 'Published Photos',
            data: publishingBehaviour.image,
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            color: darkGreenColor()
          },
          {
            name: 'Published Videos',
            data: publishingBehaviour.video,
            color: darkYellowColor()
          },
          {
            name: 'Published Carousels',
            data: publishingBehaviour.carousel_album,
            color: darkBlueColor()
          }
        ]
      }
    }
  }
}
</script>

<style scoped></style>
