<template>
  <div class="analytics-performance-box">
    <div class="analytics-performance-box__header">
      <div>
        <h3>{{ account.name }}</h3>
        <p>{{ getProfileType(account.account_type) }}</p>
      </div>
      <div>
        <img
          @error="
            $event.target.src = fallbackImagePreview(account.name[0])
          "
          :src="channelImage(account, account.account_type)"
          alt=""
        />
        <i :class="getIconClass(account.account_type)"></i>
      </div>
    </div>

    <div class="analytics-performance-box__body">
      <div class="d-flex">
        <div class="analytics-performance-box__label">Likes</div>
        <div class="flex-grow-1">
          <span
            class="green"
            :style="'width: ' + getWidth(account.reactions) + '%'"
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{ account.reactions }}
        </div>
      </div>
      <div class="d-flex">
        <div class="analytics-performance-box__label">Comments</div>
        <div class="flex-grow-1">
          <span
            class="blue"
            :style="'width: ' + getWidth(account.comments) + '%'"
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{ account.comments }}
        </div>
      </div>
      <div class="d-flex">
        <div class="analytics-performance-box__label">{{
          account.account_type === 'twitter' ? 'Retweets' : 'Shares'
        }}</div>
        <div class="flex-grow-1">
          <span
            class="yellow"
            :style="'width: ' + getWidth(account.reposts) + '%'"
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{ account.reposts }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsPerformanceBox',
  props: ['account'],
  data () {
    return {
      overall: []
    }
  },
  methods: {
    getWidth (value) {
      const total =
        this.account.reposts + this.account.comments + this.account.reactions
      if (total === 0) {
        return 0
      }
      return (value / total) * 100
    }
  }
}
</script>
