/**
 * Fetches the approval status of a plan item
 * @param data
 * @param itemRequired
 * @param storeData
 * @returns {boolean|T}
 */
export const checkApprovalStatus = (
  data,
  itemRequired = false,
  storeData = null
) => {
  if (data?.approvers) {
    const approvalUser = data.approvers.find(
      (user) => user.user_id === storeData?.getters.getProfile._id
    )
    if (approvalUser && approvalUser.status === 'pending') {
      if (itemRequired) return approvalUser
      return true
    }
  }
  return false
}
