<script>
import { mapGetters, mapActions } from 'vuex'
import FacebookPreview from './FacebookPreview'
import TwitterPreview from './TwitterPreview'
import LinkedinPreview from './LinkedinPreview'
import PinterestPreview from './PinterestPreview'
import TumblrPreview from './TumblrPreview'
import InstagramPreview from './InstagramPreview'
import GmbPreview from './GmbPreview'
import { social } from '../../../../store/states/mutation-types'
import integrations from '@common/lib/integrations'
import YoutubePreview from './YoutubePreview'
import FacebookCarouselPreview from "@src/modules/publish/components/posting/social/previews/FacebookCarouselPreview";
import TiktokPreview from "@src/modules/publish/components/posting/social/previews/TiktokPreview";
export default {
  props: ['type'],

  components: {
    TiktokPreview,
    FacebookCarouselPreview,
    FacebookPreview,
    TwitterPreview,
    LinkedinPreview,
    PinterestPreview,
    TumblrPreview,
    InstagramPreview,
    GmbPreview,
    YoutubePreview
  },
  data () {
    return {
      activeTabIndex: -1,
      integrations: integrations,
      PreviewMode: 'Desktop'
    }
  },

  created () {
    console.debug('created_type', this.type)
    if ((this.type && this.type === 'approval') || this.activeTabIndex === -1) {
      this.recheckActiveSharingPreviewTab()
    }
  },
  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getSocialSharingSelection',
      'getPublishTimeOptions',
      'getAccountSelection',
      'getInstagramSharingDetails',
      'getGmbSharingDetails',
      'getGmbAccounts',
      'getYoutubeOptions',
      'getYoutubeSharingDetails',
      'getTiktokSharingDetails',
      'getSocialSharingFirstComment',
      'getCarouselOptions'
    ])
  },
  watch: {
    'getAccountSelection.facebook' () {
      console.debug('Watch::getAccountSelection.facebook')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.twitter' () {
      console.debug('Watch::getAccountSelection.twitter')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.pinterest' () {
      console.debug('Watch::getAccountSelection.pinterest')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.linkedin' (val) {
      console.debug('Watch::getAccountSelection.linkedin', val)
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.tumblr' () {
      console.debug('Watch::getAccountSelection.tumblr')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.instagram' () {
      console.debug('Watch::getAccountSelection.instagram')
      this.recheckActiveSharingPreviewTab()
    },
    'getAccountSelection.gmb' () {
      console.debug('Watch::getAccountSelection.gmb')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.youtube' () {
      console.debug('Watch::getAccountSelection.youtube')
      this.recheckActiveSharingPreviewTab()
    },

    'getAccountSelection.tiktok' () {
      console.debug('Watch::getAccountSelection.tiktok')
      this.recheckActiveSharingPreviewTab()
    },

    // @waqas::evergreen
    // getScheduleSelectionEvergreen (value) {
    //   console.debug('Watch::getScheduleSelectionEvergreen')
    //   this.recheckActiveSharingPreviewTab()
    // },
    'getPublishTimeOptions.time_type' (value) {
      console.debug('Watch::getScheduleSelectionTime')
      this.recheckActiveSharingPreviewTab()
    }
  },

  methods: {
    ...mapActions([
      'setFacebookPreviewAccount',
      'setTwitterPreviewAccount',
      'setLinkedinPreviewAccount',
      'setPinterestPreviewAccount',
      'setTumblrPreviewAccount',
      'setInstagramPreviewAccount',
      'setYoutubePreviewAccount',
      'setTiktokPreviewAccount'
    ]),

    recheckActiveSharingPreviewTab () {
      console.debug('Method::recheckActiveSharingPreviewTab')

      const stateObject = this
      setTimeout(function () {
        let index = $('.sharing_preview_tabs').find('a.active').index()
        if (stateObject.type && stateObject.type === 'approval') {
          index = $('.sharing_preview_tabs_approval').find('a.active').index()
        }
        if (index === -1) {
          if (stateObject.previewAccountCheck('Facebook')) {
            stateObject.activeTabIndex = 0
          } else if (stateObject.previewAccountCheck('Twitter')) {
            stateObject.activeTabIndex = 1
          } else if (stateObject.previewAccountCheck('Linkedin')) {
            stateObject.activeTabIndex = 2
          } else if (stateObject.previewAccountCheck('Pinterest')) {
            stateObject.activeTabIndex = 3
          } else if (stateObject.previewAccountCheck('Tumblr')) {
            stateObject.activeTabIndex = 4
          } else if (stateObject.previewAccountCheck('Instagram')) {
            stateObject.activeTabIndex = 5
          } else if (stateObject.previewAccountCheck('Gmb')) {
            stateObject.activeTabIndex = 6
          } else if (stateObject.previewAccountCheck('Youtube')) {
            stateObject.activeTabIndex = 7
          } else if (stateObject.previewAccountCheck('Tiktok')) {
            stateObject.activeTabIndex = 8
          }
        }
      }, 200)
    },

    showSocialPreviewTab (index) {
      this.activeTabIndex = index
    },

    singleBoxActivePreviewTab (type) {
      if (
        type === 'Facebook' &&
        this.showPreviewCheck('Facebook') &&
        this.activeTabIndex === 0
      ) {
        return true
      } else if (
        type === 'Twitter' &&
        this.showPreviewCheck('Twitter') &&
        this.activeTabIndex === 1
      ) {
        return true
      } else if (
        type === 'Linkedin' &&
        this.showPreviewCheck('Linkedin') &&
        this.activeTabIndex === 2
      ) {
        return true
      } else if (
        type === 'Pinterest' &&
        this.showPreviewCheck('Pinterest') &&
        this.activeTabIndex === 3
      ) {
        return true
      } else if (
        type === 'Tumblr' &&
        this.showPreviewCheck('Tumblr') &&
        this.activeTabIndex === 4
      ) {
        return true
      } else if (
        type === 'Instagram' &&
        this.showPreviewCheck('Instagram') &&
        this.activeTabIndex === 5
      ) {
        return true
      } else if (
        type === 'Gmb' &&
        this.showPreviewCheck('Gmb') &&
        this.activeTabIndex === 6
      ) {
        return true
      } else if (
        type === 'Youtube' &&
        this.showPreviewCheck('Youtube') &&
        this.activeTabIndex === 7
      ) {
        return true
      } else if (
          type === 'Tiktok' &&
          this.showPreviewCheck('Tiktok') &&
          this.activeTabIndex === 8
      ) {
        return true
      }

      return false
    },

    showPreviewCheck (type) {
      if (this.getCommonBoxOptions.status) {
        switch (type) {
          case 'Facebook':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.pinterest_options ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Facebook') || ( this.getCarouselOptions.is_carousel_post && this.getCarouselOptions.cards.length > 0)
            ) {
              return true
            }
            break
          case 'Twitter':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Twitter')
            ) {
              return true
            }
            break
          case 'Linkedin':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Linkedin')
            ) {
              return true
            }
            break
          case 'Pinterest':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.pinterest_title ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Pinterest')
            ) {
              return true
            }
            break
          case 'Tumblr':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Tumblr')
            ) {
              return true
            }
            break
          case 'Instagram':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Instagram')
            ) {
              return true
            }
            break
          case 'Youtube':
            if (
              (this.getCommonSharingDetails.message ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Youtube')
            ) {
              return true
            }
            break
          case 'Tiktok':
            if ((this.getCommonSharingDetails.video && this.getCommonSharingDetails.video.link) && this.previewAccountCheck('Tiktok')) {
              return true
            }
            break
          case 'Gmb':
            if (
              (this.getCommonSharingDetails.message ||
                this.getCommonSharingDetails.url ||
                this.getCommonSharingDetails.image.length > 0 ||
                (this.getCommonSharingDetails.video &&
                  this.getCommonSharingDetails.video.link)) &&
              this.previewAccountCheck('Gmb')
            ) {
              return true
            }
            break
        }
      } else {
        if (
          type === 'Facebook' ||
          this.getSocialSharingSelection.activeTab === 'facebook_tab'
        ) {
          if (
            (this.getFacebookSharingDetails.message ||
              this.getFacebookSharingDetails.url ||
              this.getFacebookSharingDetails.image.length > 0 ||
              (this.getFacebookSharingDetails.video &&
                this.getFacebookSharingDetails.video.link)) &&
            this.previewAccountCheck('Facebook') || ( this.getCarouselOptions.is_carousel_post && this.getCarouselOptions.cards.length > 0)
          ) {
            return true
          }
        } else if (
          type === 'Twitter' ||
          this.getSocialSharingSelection.activeTab === 'twitter_tab'
        ) {
          if (
            (this.getTwitterSharingDetails.message ||
              this.getTwitterSharingDetails.url ||
              this.getTwitterSharingDetails.image.length > 0 ||
              (this.getTwitterSharingDetails.video &&
                this.getTwitterSharingDetails.video.link)) &&
            this.previewAccountCheck('Twitter')
          ) {
            return true
          }
        } else if (
          type === 'Linkedin' ||
          this.getSocialSharingSelection.activeTab === 'linkedin_tab'
        ) {
          if (
            (this.getLinkedinSharingDetails.message ||
              this.getLinkedinSharingDetails.url ||
              this.getLinkedinSharingDetails.image ||
              (this.getLinkedinSharingDetails.video &&
                this.getLinkedinSharingDetails.video.link)) &&
            this.previewAccountCheck('Linkedin')
          ) {
            return true
          }
        } else if (
          type === 'Pinterest' ||
          this.getSocialSharingSelection.activeTab === 'pinterest_tab'
        ) {
          if (
            (this.getPinterestSharingDetails.message ||
              this.getPinterestSharingDetails.pinterest_options ||
              this.getPinterestSharingDetails.image ||
              (this.getPinterestSharingDetails.video &&
                this.getPinterestSharingDetails.video.link)) &&
            this.previewAccountCheck('Pinterest')
          ) {
            return true
          }
        } else if (
          type === 'Tumblr' ||
          this.getSocialSharingSelection.activeTab === 'tumblr_tab'
        ) {
          if (
            (this.getTumblrSharingDetails.message ||
              this.getTumblrSharingDetails.url ||
              this.getTumblrSharingDetails.image ||
              (this.getTumblrSharingDetails.video &&
                this.getTumblrSharingDetails.video.link)) &&
            this.previewAccountCheck('Tumblr')
          ) {
            return true
          }
        } else if (
          type === 'Instagram' ||
          this.getSocialSharingSelection.activeTab === 'instagram_tab'
        ) {
          if (
            (this.getInstagramSharingDetails.message ||
              this.getInstagramSharingDetails.url ||
              this.getInstagramSharingDetails.image.length > 0 ||
              (this.getInstagramSharingDetails.video &&
                this.getInstagramSharingDetails.video.link)) &&
            this.previewAccountCheck('Instagram')
          ) {
            return true
          }
        } else if (
          type === 'Youtube' ||
          this.getSocialSharingSelection.activeTab === 'youtube_tab'
        ) {
          if (
            (this.getYoutubeSharingDetails.message ||
              (this.getYoutubeSharingDetails.video &&
                this.getYoutubeSharingDetails.video.link)) &&
            this.previewAccountCheck('Youtube')
          ) {
            return true
          }
        } else if (
          type === 'Gmb' ||
          this.getSocialSharingSelection.activeTab === 'gmb_tab'
        ) {
          if (
            (this.getGmbSharingDetails.message ||
              this.getGmbSharingDetails.url ||
              this.getGmbSharingDetails.image.length > 0 ||
              (this.getGmbSharingDetails.video &&
                this.getGmbSharingDetails.video.link)) &&
            this.previewAccountCheck('Gmb')
          ) {
            return true
          }
        } else if (
            type === 'Tiktok' ||
            this.getSocialSharingSelection.activeTab === 'tiktok_tab'
        ) {
          if (
              (this.getTiktokSharingDetails.message ||
                  (this.getTiktokSharingDetails.video &&
                      this.getTiktokSharingDetails.video.link)) &&
              this.previewAccountCheck('Tiktok')
          ) {
            return true
          }
        }
      }

      return false
    },

    previewAccountCheck (type) {
      let check = false
      switch (type) {
        case 'Facebook':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.facebook.profile_selection.length) {
            //   this.setFacebookPreviewAccount(selectedEvergreenPlatforms.facebook.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.facebook.length > 0
          ) {
            let account = this.getAccountSelection.facebook[0]
            const firstCommentAccounts =
              this.getSocialSharingFirstComment.first_comment_accounts
            if (firstCommentAccounts && firstCommentAccounts.length > 0) {
              $(this.getAccountSelection.facebook).each(function (index, el) {
                if (firstCommentAccounts.indexOf(el) !== -1) {
                  account = el
                  return false
                }
              })
            }

            this.setFacebookPreviewAccount(
              this.getFacebookPlatformByID(account)
            )
            check = true
          }
          break
        case 'Twitter':
          if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.twitter.length > 0
          ) {
            const account = this.getAccountSelection.twitter[0]
            this.setTwitterPreviewAccount(this.getTwitterPlatformByID(account))
            check = true
          }
          break
        case 'Linkedin':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.linkedin.profile_selection.length) {
            //   this.setLinkedinPreviewAccount(selectedEvergreenPlatforms.linkedin.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.linkedin.length > 0
          ) {
            const account = this.getAccountSelection.linkedin[0]
            this.setLinkedinPreviewAccount(
              this.getLinkedinPlatformByID(account)
            )
            check = true
          }
          break
        case 'Pinterest':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.pinterest.profile_selection.length) {
            //   this.setPinterestPreviewAccount(selectedEvergreenPlatforms.pinterest.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.pinterest.length > 0
          ) {
            const account = this.getAccountSelection.pinterest[0]
            this.setPinterestPreviewAccount(
              this.getPinterestPlatformByID(account)
            )
            check = true
          }
          break
        case 'Tumblr':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.tumblr.profile_selection.length) {
            //   this.setTumblrPreviewAccount(selectedEvergreenPlatforms.tumblr.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.tumblr.length > 0
          ) {
            const account = this.getAccountSelection.tumblr[0]
            this.setTumblrPreviewAccount(
              this.getTumblrPlatformByName(account, 'Profile')
            )
            check = true
          }
          break
        case 'Instagram':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.instagram.profile_selection.length) {
            //   this.setInstagramPreviewAccount(selectedEvergreenPlatforms.instagram.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.instagram.length > 0
          ) {
            let account = this.getAccountSelection.instagram[0]
            const firstCommentAccounts =
              this.getSocialSharingFirstComment.first_comment_accounts
            $(this.getAccountSelection.instagram).each(function (index, el) {
              if (firstCommentAccounts.indexOf(el) !== -1) {
                account = el
                return false
              }
            })
            this.setInstagramPreviewAccount(
              this.getInstagramPlatformByID(account)
            )
            check = true
          }
          break
        case 'Youtube':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.youtube.length > 0
          ) {
            const account = this.getAccountSelection.youtube[0]
            this.setYoutubePreviewAccount(this.getYoutubePlatformByID(account))
            check = true
          }
          break
        case 'Tiktok':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.tiktok.length > 0
          ) {
            const account = this.getAccountSelection.tiktok[0]
            this.setTiktokPreviewAccount(this.getTiktokPlatformByID(account))
            check = true
          }
          break
        case 'Gmb':
          if (this.getPublishTimeOptions.time_type === 'evergreen') {
            // @waqas::evergreen
            // let selectedEvergreenPlatforms = this.selectedEvergreenPlatforms
            // if (selectedEvergreenPlatforms.instagram.profile_selection.length) {
            //   this.setInstagramPreviewAccount(selectedEvergreenPlatforms.instagram.profile_selection[0])
            //   check = true
            // }
          } else if (
            this.getPublishTimeOptions.time_type !== 'evergreen' &&
            this.getAccountSelection.gmb.length > 0
          ) {
            const account = this.getAccountSelection.gmb[0]
            this.$store.commit(
              social.SET_GMB_PREVIEW_ACCOUNT_SELECTION,
              this.integrations.getPlatformByID(
                this.getGmbAccounts.all_items,
                'name',
                account
              )
            )
            check = true
          }
          break
      }

      return check
    }
  }
}
</script>

<template>
  <div :class="{ approval_preview_box: type && type === 'approval' }">
    <div class="align-items-center d-flex p-3">
      <h5><b>Post Preview</b></h5>

      <div class="text-center ml-auto">
        <div class="radio_tabs_buttons">
          <input
            type="radio"
            name="PreviewMode"
            value="Desktop"
            v-model="PreviewMode"
            class="radio-tab"
            id="DesktopView"
          />
          <label for="DesktopView"><i class="icon-desktop"></i> Desktop</label>

          <input
            type="radio"
            name="PreviewMode"
            value="Mobile"
            v-model="PreviewMode"
            class="radio-tab"
            id="MobileView"
          />
          <label for="MobileView"><i class="icon-mobile"></i> Mobile</label>
        </div>
      </div>
    </div>

    <div class="social_preview_block">
      <template
        v-if="
          (showPreviewCheck('Facebook') === false &&
            showPreviewCheck('Twitter') === false &&
            showPreviewCheck('Linkedin') === false &&
            showPreviewCheck('Pinterest') === false &&
            showPreviewCheck('Tumblr') === false &&
            showPreviewCheck('Instagram') === false &&
            showPreviewCheck('Youtube') === false &&
            showPreviewCheck('Tiktok') === false &&
            showPreviewCheck('Gmb') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'facebook_tab' &&
            showPreviewCheck('Facebook') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'twitter_tab' &&
            showPreviewCheck('Twitter') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'linkedin_tab' &&
            showPreviewCheck('Linkedin') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'pinterest_tab' &&
            showPreviewCheck('Pinterest') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'tumblr_tab' &&
            showPreviewCheck('Tumblr') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'instagram_tab' &&
            showPreviewCheck('Instagram') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'youtube_tab' &&
            showPreviewCheck('Youtube') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'tiktok_tab' &&
            showPreviewCheck('Tiktok') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === 'gmb_tab' &&
            showPreviewCheck('Gmb') === false) ||
          (getCommonBoxOptions.status === false &&
            getSocialSharingSelection.activeTab === '')
        "
      >
        <img
          class="social_preview_block__social_preview_img"
          src="../../../../../../assets/img/states/no-preview-icon.svg"
          alt=""
        />
        <p
          v-if="
            getAccountSelection.facebook.length ||
            getAccountSelection.twitter.length ||
            getAccountSelection.linkedin.length ||
            getAccountSelection.pinterest.length ||
            getAccountSelection.tumblr.length ||
            getAccountSelection.instagram.length ||
            getAccountSelection.gmb.length
          "
          class="social_preview_block__social_preview_text"
        >
          You have not composed any message or attached any image/video yet.
        </p>

        <p v-else class="social_preview_block__social_preview_text">
          You have not selected any social accounts yet.
        </p>
      </template>

      <template v-else>
        <div
          :class="{
            'd-none':
              (showPreviewCheck('Facebook') === false &&
                showPreviewCheck('Twitter') === false &&
                showPreviewCheck('Linkedin') === false &&
                showPreviewCheck('Pinterest') === false &&
                showPreviewCheck('Tumblr') === false &&
                showPreviewCheck('Instagram') === false &&
                showPreviewCheck('Youtube') === false &&
                showPreviewCheck('Tiktok') === false &&
                showPreviewCheck('Gmb') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'facebook_tab' &&
                previewAccountCheck('Facebook') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'twitter_tab' &&
                previewAccountCheck('Twitter') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'linkedin_tab' &&
                previewAccountCheck('Linkedin') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'pinterest_tab' &&
                previewAccountCheck('Pinterest') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'tumblr_tab' &&
                previewAccountCheck('Tumblr') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'instagram_tab' &&
                previewAccountCheck('Instagram') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'youtube_tab' &&
                previewAccountCheck('Youtube') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'tiktok_tab' &&
                previewAccountCheck('Tiktok') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === 'gmb_tab' &&
                previewAccountCheck('Gmb') === false) ||
              (getCommonBoxOptions.status === false &&
                getSocialSharingSelection.activeTab === ''),
          }"
          class="social_preview_block__social_preview_inner"
          v-if="getCommonBoxOptions.status"
        >
          <div
            class="social_preview_block__social_preview_inner__tab_links"
            :class="{
              sharing_preview_tabs_approval: type && type === 'approval',
            }"
            role="tablist"
          >
            <a
              class="social_preview_block__social_preview_inner__tab_links__facebook_tab"
              href="#facebook_tab"
              :class="{ active: activeTabIndex === 0 }"
              v-if="showPreviewCheck('Facebook')"
              @click="showSocialPreviewTab(0)"
            >
              <i class="icon_block cs-facebook"></i> <br />
              <span>Facebook</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__twitter_tab"
              href="#twitter_tab"
              :class="{ active: activeTabIndex === 1 }"
              v-if="showPreviewCheck('Twitter')"
              @click="showSocialPreviewTab(1)"
            >
              <i class="icon_block cs-twitter"></i> <br />
              <span>Twitter</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__linkedin_tab"
              href="#linkedin_tab"
              :class="{ active: activeTabIndex === 2 }"
              v-if="showPreviewCheck('Linkedin')"
              @click="showSocialPreviewTab(2)"
            >
              <i class="icon_block cs-linkedin"></i> <br />
              <span>Linkedin</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__pinterest_tab"
              href="#pinterest_tab"
              :class="{ active: activeTabIndex === 3 }"
              v-if="showPreviewCheck('Pinterest')"
              @click="showSocialPreviewTab(3)"
            >
              <i class="icon_block cs-pinterest"></i> <br />
              <span>Pinterest</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__tumblr_tab"
              href="#tumblr_tab"
              :class="{ active: activeTabIndex === 4 }"
              v-if="showPreviewCheck('Tumblr')"
              @click="showSocialPreviewTab(4)"
            >
              <i class="icon_block cs-tumblr"></i> <br />
              <span>Tumblr</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__instagram_tab"
              href="#instagram_tab"
              :class="{ active: activeTabIndex === 5 }"
              v-if="showPreviewCheck('Instagram')"
              @click="showSocialPreviewTab(5)"
            >
              <i class="icon_block cs-instagram"></i> <br />
              <span>Instagram</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__gmb_tab"
              href="#gmb_tab"
              :class="{ active: activeTabIndex === 6 }"
              v-if="showPreviewCheck('Gmb')"
              @click="showSocialPreviewTab(6)"
            >
              <i class="icon_block fab fa-google"></i> <br />
              <span>GMB</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__youtube_tab"
              href="#youtube_tab"
              :class="{ active: activeTabIndex === 7 }"
              v-if="showPreviewCheck('Youtube')"
              @click="showSocialPreviewTab(7)"
            >
              <i class="icon_block cs-youtube"></i> <br />
              <span>Youtube</span>
            </a>

            <a
                class="social_preview_block__social_preview_inner__tab_links__youtube_tab"
                href="#tiktok_tab"
                :class="{ active: activeTabIndex === 8 }"
                v-if="showPreviewCheck('Tiktok')"
                @click="showSocialPreviewTab(8)"
            >
              <i class="icon_block cs-tiktok">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" style="width: 10px;">
                  <path fill="#00000" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
                </svg>
              </i> <br />
              <span>Tiktok</span>
            </a>
          </div>

          <div
            class="social_preview_block__social_preview_inner__social_post_preview"
          >
            <div :class="{ mobile_post_preview: PreviewMode === 'Mobile' }">
              <div
                class="preview_contain"
                :class="`${ PreviewMode === 'Mobile' && !singleBoxActivePreviewTab('Tiktok') ? 'mobile_contain' : 'mobile_contain mobile_tiktok'}`"


              >
                <facebook-carousel-preview
                    v-if="singleBoxActivePreviewTab('Facebook') && getCarouselOptions.is_carousel_post && getCarouselOptions.cards.length > 0 && getCarouselOptions.accounts.length > 0"
                    :carousel="getCarouselOptions"
                    :description="getCommonSharingDetails.message"
                ></facebook-carousel-preview>
                <div v-else>
                  <facebook-preview
                      :details="getCommonSharingDetails"
                      v-if="singleBoxActivePreviewTab('Facebook')"
                  ></facebook-preview>
                </div>

                <twitter-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Twitter')"
                ></twitter-preview>
                <linkedin-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Linkedin')"
                ></linkedin-preview>
                <pinterest-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Pinterest')"
                ></pinterest-preview>
                <tumblr-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Tumblr')"
                ></tumblr-preview>
                <instagram-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Instagram')"
                ></instagram-preview>
                <gmb-preview
                  :details="getCommonSharingDetails"
                  v-if="singleBoxActivePreviewTab('Gmb')"
                ></gmb-preview>
                <youtube-preview
                  :details="getCommonSharingDetails"
                  :options="getYoutubeOptions"
                  :account="
                    getYoutubePlatformByID(getAccountSelection.youtube[0])
                  "
                  v-if="singleBoxActivePreviewTab('Youtube')"
                ></youtube-preview>
                <tiktok-preview
                    :previewMode="PreviewMode"
                    :details="getCommonSharingDetails"
                    :account="getTiktokPlatformByID(getAccountSelection.tiktok[0])"
                    v-if="singleBoxActivePreviewTab('Tiktok')"
                ></tiktok-preview>
              </div>
            </div>
          </div>
        </div>

        <div class="social_preview_block__social_preview_inner" v-else>
          <div
            class="social_preview_block__social_preview_inner__tab_links"
            role="tablist"
          >
            <a
              class="social_preview_block__social_preview_inner__tab_links__facebook_tab active"
              id="facebook_tab_p"
              href="#facebook_tab"
              v-if="
                showPreviewCheck('Facebook') &&
                getSocialSharingSelection.activeTab === 'facebook_tab'
              "
            >
              <i class="icon_block cs-facebook"></i> <br />
              <span>Facebook</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__twitter_tab active"
              id="twitter_tab_p"
              href="#twitter_tab"
              v-if="
                showPreviewCheck('Twitter') &&
                getSocialSharingSelection.activeTab === 'twitter_tab'
              "
            >
              <i class="icon_block cs-twitter"></i><br />
              <span>Twitter</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__linkedin_tab active"
              id="linkedin_tab_p"
              href="#linkedin_tab"
              v-if="
                showPreviewCheck('Linkedin') &&
                getSocialSharingSelection.activeTab === 'linkedin_tab'
              "
            >
              <i class="icon_block cs-linkedin"></i><br />
              <span>Linkedin</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__pinterest_tab active"
              id="pinterest_tab_p"
              href="#pinterest_tab"
              v-if="
                showPreviewCheck('Pinterest') &&
                getSocialSharingSelection.activeTab === 'pinterest_tab'
              "
            >
              <i class="icon_block cs-pinterest"></i><br />
              <span>Pinterest</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__tumblr_tab active"
              id="tumblr_tab_p"
              href="#tumblr_tab"
              v-if="
                showPreviewCheck('Tumblr') &&
                getSocialSharingSelection.activeTab === 'tumblr_tab'
              "
            >
              <i class="icon_block cs-tumblr"></i><br />
              <span>Tumblr</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__instagram_tab active"
              id="instagram_tab_p"
              href="#instagram_tab"
              v-if="
                showPreviewCheck('Instagram') &&
                getSocialSharingSelection.activeTab === 'instagram_tab'
              "
            >
              <i class="icon_block cs-instagram"></i><br />
              <span>Instagram</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__gmb_tab active"
              id="gmb_tab_p"
              href="#gmb_tab"
              v-if="
                showPreviewCheck('Gmb') &&
                getSocialSharingSelection.activeTab === 'gmb_tab'
              "
            >
              <i class="fab fa-google"></i><br />
              <span>GMB</span>
            </a>

            <a
              class="social_preview_block__social_preview_inner__tab_links__youtube_tab active"
              id="youtube_tab_p"
              href="#youtube_tab"
              v-if="
                showPreviewCheck('Youtube') &&
                getSocialSharingSelection.activeTab === 'youtube_tab'
              "
            >
              <i class="icon_block cs-youtube"></i><br />
              <span>Youtube</span>
            </a>
            <a
                class="social_preview_block__social_preview_inner__tab_links__youtube_tab active"
                id="tiktok_tab_p"
                href="#tiktok_tab"
                v-if="
                showPreviewCheck('Tiktok') &&
                getSocialSharingSelection.activeTab === 'tiktok_tab'
              "
            >
              <i class="icon_block cs-tiktok">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" style="width: 10px;">
                  <path fill="#00000" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
                </svg>
              </i><br />
              <span>Tiktok</span>
            </a>
          </div>

          <div
            class="social_preview_block__social_preview_inner__social_post_preview social_preview_no_tab"
          >
            <div :class="{ mobile_post_preview: PreviewMode === 'Mobile' }">
              <div
                class="preview_contain"
                :class="{ mobile_contain: PreviewMode === 'Mobile' }"
              >
                <facebook-carousel-preview
                    v-if="showPreviewCheck('Facebook') && getSocialSharingSelection.activeTab === 'facebook_tab' && (getCarouselOptions.is_carousel_post && getCarouselOptions.cards.length > 0 && getCarouselOptions.accounts.length > 0)"
                    :carousel="getCarouselOptions"
                    :description="getFacebookSharingDetails.message"
                ></facebook-carousel-preview>
                <div v-else>
                <facebook-preview
                  :details="getFacebookSharingDetails"
                  v-if="
                    showPreviewCheck('Facebook') &&
                    getSocialSharingSelection.activeTab === 'facebook_tab'
                  "
                ></facebook-preview>
                </div>
                <twitter-preview
                  :details="getTwitterSharingDetails"
                  v-if="
                    showPreviewCheck('Twitter') &&
                    getSocialSharingSelection.activeTab === 'twitter_tab'
                  "
                ></twitter-preview>
                <linkedin-preview
                  :details="getLinkedinSharingDetails"
                  v-if="
                    showPreviewCheck('Linkedin') &&
                    getSocialSharingSelection.activeTab === 'linkedin_tab'
                  "
                ></linkedin-preview>
                <pinterest-preview
                  :details="getPinterestSharingDetails"
                  v-if="
                    showPreviewCheck('Pinterest') &&
                    getSocialSharingSelection.activeTab === 'pinterest_tab'
                  "
                ></pinterest-preview>
                <tumblr-preview
                  :details="getTumblrSharingDetails"
                  v-if="
                    showPreviewCheck('Tumblr') &&
                    getSocialSharingSelection.activeTab === 'tumblr_tab'
                  "
                ></tumblr-preview>
                <instagram-preview
                  :details="getInstagramSharingDetails"
                  v-if="
                    showPreviewCheck('Instagram') &&
                    getSocialSharingSelection.activeTab === 'instagram_tab'
                  "
                ></instagram-preview>
                <gmb-preview
                  :details="getGmbSharingDetails"
                  v-if="
                    showPreviewCheck('Gmb') &&
                    getSocialSharingSelection.activeTab === 'gmb_tab'
                  "
                ></gmb-preview>
                <youtube-preview
                  :details="getYoutubeSharingDetails"
                  :options="getYoutubeOptions"
                  :account="
                    getYoutubePlatformByID(getAccountSelection.youtube[0])
                  "
                  v-if="
                    showPreviewCheck('Youtube') &&
                    getSocialSharingSelection.activeTab === 'youtube_tab'
                  "
                ></youtube-preview>

                <tiktok-preview
                    :previewMode="PreviewMode"
                    :details="getTiktokSharingDetails"
                    :account="
                    getTiktokPlatformByID(getAccountSelection.tiktok[0])
                  "
                    v-if="
                    showPreviewCheck('Tiktok') &&
                    getSocialSharingSelection.activeTab === 'tiktok_tab'
                  "
                ></tiktok-preview>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
