<script>
import { mapGetters, mapActions } from 'vuex'
import ListingFilters from './ListingFilters'
import SelectedPlatforms from '../../SelectedPlatforms'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
import { evergreen } from '../../../store/automation-mutation-type'
import ConfirmationBox from '@common/components/dialogs/ConfirmationBox'
import { confirmation } from '@common/store/mutation-types'
import PlatformFilters from '../../../../publish/components/PlatformFilters'
import {
  AUTOMATION_REMOVAL_ERROR,
  AUTOMATION_REMOVAL_SUCCESS
} from '@common/constants/messages'
import {social} from "@src/modules/publish/store/states/mutation-types";
import ChannelsTooltip from "@src/components/common/ChannelsTooltip.vue";

export default {
  components: {
    ConfirmBox,
    SelectedPlatforms,
    ListingFilters,
    PlatformFilters,
    ConfirmationBox,
    ChannelsTooltip
  },

  created () {
    this.initializeEvergreenAutomationListingSection()
  },

  computed: {
    ...mapGetters([
      'getEvergreenAutomationListing',
      'getAutomationLoaders',
      'getWorkspaces',
      'getProfile',
      'getConfirmationPaylaod',
      'getAllAutomations'
    ])
  },

  methods: {
    ...mapActions([
      'processEvergreenAutomationPagination',
      'changeEvergreenAutomationStatus',
      'removeEvergreenAutomation',
      'setRemoveEvergreenAutomationLoader',
      'hideConfirmationBox',
      'setConfirmActionStage',
      'evergreenAutomationBulkOperation',
      'refetchEvergreenAutomation',
      'setEvergreenAutomationBulkOperationLoader'
    ]),

    /**
     * remove automation payload, add index in payload to splice in future
     * @param automation
     * @param index
     * @return automation
     */
    removeEvergreenAutomationPayload (automation, index) {
      automation.removalIndex = index
      return automation
    },

    /*
     *save remove automation payload and show confirmation modal
     */
    removeEvergreen (payload) {
      console.debug('METHOD:removeEvergreen')
      this.$store.commit(confirmation.SET_PAYLOAD, payload)
      // eslint-disable-next-line no-undef
      $('#removeEvergreenAutomation').modal('show')
    },

    /**
     * select all for bulk operation
     * ignore lock automation to select
     */
    changeEvergreenAutomationSelectAllStatus () {
      console.debug('Method:changeEvergreenAutomationSelectAllStatus')

      const selectedAutomation = []

      const automations = this.getEvergreenAutomationListing.items
      if (this.getEvergreenAutomationListing.select_all) {
        // eslint-disable-next-line no-undef
        $(automations).each(function (i, el) {
          if (el.lock === 0) {
            // ignore lock automations
            selectedAutomation.push(el._id)
          }
        })
      }
      // saving selected automation ids in states
      this.$store.commit(
        evergreen.SET_EVERGREEN_SELECTED_AUTOMATION,
        selectedAutomation
      )
    },

    /**
     * edit/duplicate evergreen automation
     * @param data(copy, id)
     */
    async editEvergreenAutomation (data) {
      if (data.copy) {
        let name = data.automation.name.trim()
        const lastcharater = name.slice(-1)

        if (isNaN(lastcharater)) {
          name = name + ' 1'
        } else {
          name = name.substring(0, name.length - 1).trim()
          name = name + ' ' + (parseInt(lastcharater) + 1)
        }
        data.automation.name = name
        data.automation._id = ''
      }
      if (data.automation.schedule_options.time_type === 'content_category') {
        this.$store.dispatch(
          'nextAvailableSlot',
          data.automation.content_category_id
        )
      }
      this.$store.commit(evergreen.SET_EVERGREEN_ID, data.automation._id)
      this.$store.commit(evergreen.SET_EVERGREEN_NAME, data.automation.name)
      this.$store.commit(evergreen.SET_EVERGREEN_POSTS, data.automation.posts)
      this.$store.commit(evergreen.SET_EVERGREEN_STATUS, data.automation.status)
      this.$store.commit(
        evergreen.SET_EVERGREEN_CYCLE_GAP,
        data.automation.cycleGap
      )
      this.$store.commit(
        evergreen.SET_EVERGREEN_CYCLE_GAP_STATUS,
        data.automation.cycleGapStatus
      )
      this.$store.commit(
        evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE,
        data.automation.content_category_id
      )
      this.processAutomationScheduleOptions(data.automation.schedule_options)
      this.$store.commit(
        'setAccountSelection',
        data.automation.account_selection
      )
      this.$store.commit(social.SET_PINTEREST_ACCOUNT_SELECTION,[])

      if (data.automation._id) {
        this.$router.push({
          name: 'saveEvergreenAutomationAccounts',
          params: { evergreenId: data.automation._id }
        })
      } else {
        this.$router.push({ name: 'saveEvergreenAutomationAccounts' })
      }
      this.$store.commit('setFetchEvergreenAutomationLoader', false)
    },

    /**
     * remove automation method callback after confirmation
     * @param payload
     */
    async removeEvergreenCallback (payload) {
      console.debug('METHOD:removeEvergreenCallback', payload)
      if (payload.status) {
        const automation = this.getConfirmationPaylaod
        // send request to remove automation
        const response = await this.$store.dispatch(
          'removeEvergreenAutomation',
          automation
        )
        if (response) {
          if (response.data.status === true) {
            this.$store.commit(confirmation.SET_STAGE, 'success')
            // remove this in future when we combine confirmationBoxes
            this.$store.commit('setConfirmActionStage', 'success')
            this.alertMessage(AUTOMATION_REMOVAL_SUCCESS, 'success')

            // removing automation from states
            this.getEvergreenAutomationListing.items.splice(
              automation.removalIndex,
              1
            )
            this.fetchAutomationsCounts() // get automation count for planner

            // also update plan automation limit
            const stateObject = this
            if (this.getAllAutomations.fetch_status) {
              // eslint-disable-next-line no-undef
              $(this.getAllAutomations.evergreen).each(function (index, el) {
                if (el._id === automation._id) {
                  stateObject.getAllAutomations.evergreen.splice(index, 1)
                  return false
                }
              })
            }
          } else {
            this.alertMessage(AUTOMATION_REMOVAL_ERROR, 'error')
            this.$store.commit(confirmation.SET_STAGE, 'fail')
            // remove this in future when we combine confirmationBoxes
            this.$store.commit('setConfirmActionStage', 'fail')
          }
          this.setRemoveEvergreenAutomationLoader(false)
          this.$store.dispatch(
            'hideConfirmationBox',
            'removeEvergreenAutomation'
          )
        }
      } else {
        // eslint-disable-next-line no-undef
        $('#' + payload.type).modal('hide')
      }
    },

    /**
     * bulk operations automation method callback after confirmation
     * @param payload
     */
    async evergreenAutomationBulkOperationCallback (payload) {
      console.debug('METHOD:evergreenAutomationBulkOperation', payload)
      if (payload.status) {
        const operation = this.getConfirmationPaylaod
        // send request to perform bulk operation on automations
        const response = await this.$store.dispatch(
          'evergreenAutomationBulkOperation',
          operation
        )
        if (response) {
          if (response.data.status === true) {
            // hide confirmation modal
            this.$store.commit(confirmation.SET_STAGE, 'success')
            // remove this in future when we combine confirmationBoxes
            this.$store.commit('setConfirmActionStage', 'success')

            this.alertMessage('Operation is performed successfully.', 'success')
            // reset selection states
            this.$store.commit(evergreen.SET_EVERGREEN_SELECTED_AUTOMATION, [])
            this.$store.commit(
              evergreen.SET_EVERGREEN_LISTING_SELECT_ALL,
              false
            )
            this.$store.dispatch('refetchEvergreenAutomation') // refetch automation
            this.fetchAutomationsCounts() // fetch automation count planner
            if (operation === 'Delete') {
              // this.setPlannerEvergreenAutomationsFetchStatus(false)//todo::refactor
            }
          } else {
            this.alertMessage(
              'Unable to perform operation.Please try again.',
              'error'
            )
            this.$store.commit(confirmation.SET_STAGE, 'fail')
            // remove this in future when we combine confirmationBoxes
            this.$store.commit('setConfirmActionStage', 'fail')
          }
          this.setEvergreenAutomationBulkOperationLoader(false)
          this.$store.dispatch(
            'hideConfirmationBox',
            'evergreenAutomationBulkOperation'
          )
        }
      } else {
        // eslint-disable-next-line no-undef
        $('#' + payload.type).modal('hide')
      }
    }
  }
}
</script>

<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <confirm-box
        id="removeEvergreenAutomation"
        :callBackAction="removeEvergreenCallback"
        confirmText="Yes"
        cancelText="No"
      >
        <template slot="header">
          <h2>Remove Campaign</h2>
        </template>

        <template slot="footer">
          <p class="text-center">This action cannot be undone.</p>
        </template>
      </confirm-box>

      <confirm-box
        id="evergreenAutomationBulkOperation"
        confirmText="Yes"
        cancelText="No"
        :callBackAction="evergreenAutomationBulkOperationCallback"
      >
        <template slot="header">
          <h2>Perform bulk operation</h2>
        </template>
        <template slot="footer">
          <p class="text-center">This action cannot be undone.</p>
        </template>
      </confirm-box>

      <div class="top_content_listing">
        <div class="d-flex align-items-center">
          <div class="col-md-4 text-left nopad-l">
            <router-link
              class="btn btn-studio-theme-grey-space"
              :to="{
                name: 'automation',
                params: { workspace: getWorkspaces.activeWorkspace.slug },
              }"
            >
              <i
                style="color: #ffffff"
                class="cs-angle-left icon_left arrow_right_active"
              ></i>
              <span>Back</span>
            </router-link>
          </div>

          <h2 class="col-md-4 text-center">View Existing Campaigns</h2>
          <p class="col-md-4 text-right nopad-r"
            >Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            (
            <clock
              timeFormat="hh:mm:ss A"
              :timezone="getWorkspaces.activeWorkspace.timezone"
            ></clock>
            )
          </p>
        </div>

        <img
          src="../../../../../assets/img/automation/article_social.svg"
          alt=""
        />
        <h3>Evergreen</h3>
        <p
          >Recycle your evergreen posts at pre-defined time intervals over and
          over again for increased engagement.</p
        >
      </div>

      <div class="automation_listing">
        <div class="page_inner">
          <listing-filters></listing-filters>

          <div class="table_box">
            <table class="table-sm table-fixed">
              <thead>
                <tr>
                  <th class="text-center" style="min-width: 50px; width: 60px">
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label
                          for="select_all"
                          :class="{
                            disabled:
                              getEvergreenAutomationListing.items.length < 1,
                          }"
                        >
                          <input
                            id="select_all"
                            type="checkbox"
                            :disabled="
                              getEvergreenAutomationListing.items.length < 1
                            "
                            v-model="getEvergreenAutomationListing.select_all"
                            @change="changeEvergreenAutomationSelectAllStatus()"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </th>
                  <th style="width: 160px" class="text-center">Status</th>
                  <th>Name</th>
                  <th class="text-center" style="width: 200px"
                    >Platforms Selected</th
                  >
                  <th class="text-center"
                    >Next Refill Time
                    <i
                      class="fas fa-info-circle"
                      style="margin-left: 5px"
                      v-tooltip.top-center="
                        'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'
                      "
                    ></i
                  ></th>
                  <th class="text-center">Published Posts</th>
                  <th style="text-align: center; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    getAutomationLoaders.fetchEvergreenAutomations ||
                    getAutomationLoaders.fetchEvergreenAutomation
                  "
                >
                  <td colspan="7" class="text-center">
                    <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
                  </td>
                </tr>

                <template
                  v-else-if="getEvergreenAutomationListing.items.length"
                >
                  <tr
                    :class="{ pause_automation: !automation.status }"
                    v-for="(
                      automation, index
                    ) in getEvergreenAutomationListing.items"
                    :key="index"
                  >
                    <td class="text-center">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label :for="index">
                            <input
                              :id="index"
                              type="checkbox"
                              :checked="
                                getEvergreenAutomationListing.selected.includes(
                                  automation._id
                                )
                              "
                              :disabled="automation.lock !== 0"
                              @change="
                                changeAutomationSelection(
                                  getEvergreenAutomationListing.selected,
                                  automation._id,
                                  'Evergreen'
                                )
                              "
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>

                      <!--<div class="checkbox_input_image">-->
                      <!--<input :id="index"  type="checkbox" :checked="getEvergreenAutomationListing.selected.includes(automation._id)" :disabled="automation.lock !== 0" @change="changeAutomationSelection(getEvergreenAutomationListing.selected,automation._id,'Evergreen')">-->
                      <!--<label :for="index" class="no_text checkbox_left"></label>-->
                      <!--</div>-->
                    </td>
                    <td class="text-center">
                      <label
                        class="switch-radio"
                        v-tooltip.top-center="'Toggle ON/OFF'"
                      >
                        <input
                          type="checkbox"
                          :disabled="automation.lock !== 0"
                          id="automation_toggle"
                          v-model="automation.status"
                          @change="changeEvergreenAutomationStatus(automation)"
                        />
                        <div
                          class="slider round"
                          :class="{ disabled: automation.lock !== 0 }"
                        ></div>
                      </label>
                    </td>
                    <td>{{ automation.name }}</td>
                    <td>
                      <ChannelsTooltip :accounts="automation.account_selection"
                                       :channels_size="'28px'"></ChannelsTooltip>
                    </td>
                    <td class="text-center">{{
                      automationNextRefill(automation, 'EvergreenAutomation')
                    }}</td>
                    <td class="text-center" v-if="automation.postedCount">{{
                      automation.postedCount
                    }}</td>
                    <td class="text-center" v-else>0</td>

                    <td class="text-center">
                      <!--with_loader-->
                      <div class="d-flex align-items-center">
                        <clip-loader
                          class="inline_loader mr-2"
                          v-if="automation.lock"
                          color="#4165ed"
                          :size="'16px'"
                        ></clip-loader>
                        <template>
                          <i
                            v-tooltip.top-center="'View Posts'"
                            class="action_icon sm icon-Listview_icon"
                            @click="
                              viewAutomationPosts(
                                automation._id,
                                'evergreen_automation'
                              )
                            "
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Edit'"
                            v-if="
                              automation.policy && automation.policy.can_edit
                            "
                            class="action_icon sm icon-edit-cs"
                            @click="
                              editEvergreenAutomation({
                                automation: automation,
                                copy: false,
                              })
                            "
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Duplicate'"
                            v-if="
                              automation.policy && automation.policy.can_edit
                            "
                            class="action_icon sm fa fa-files-o"
                            @click="
                              editEvergreenAutomation({
                                automation: automation,
                                copy: true,
                              })
                            "
                          >
                          </i>

                          <i
                            v-tooltip.top-center="'Remove'"
                            v-if="
                              automation.policy && automation.policy.can_remove
                            "
                            class="action_icon sm icon-delete-cs"
                            @click.prevent="
                              removeEvergreen(
                                removeEvergreenAutomationPayload(
                                  automation,
                                  index
                                )
                              )
                            "
                          >
                          </i>
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>

                <tr v-else>
                  <td
                    colspan="7"
                    class="text-center"
                    v-if="getEvergreenAutomationListing.search"
                    >No results found for your search query, please try
                    again.</td
                  >
                  <td colspan="7" class="text-center" v-else
                    >You have not created any automation yet.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="field_group text-center"
            v-if="
              getEvergreenAutomationListing.nextPage ||
              getEvergreenAutomationListing.previousPage
            "
          >
            <button
              class="btn btn-studio-theme-transparent-grey mr-1"
              :disabled="!getEvergreenAutomationListing.previousPage"
              @click="processEvergreenAutomationPagination('previous')"
            >
              <i class="icon_left cs-angle-left"></i>
              <span>Prev</span>
            </button>

            <button
              class="btn btn-studio-theme-space"
              :disabled="!getEvergreenAutomationListing.nextPage"
              @click="processEvergreenAutomationPagination('next')"
            >
              <span>Next</span>
              <i class="icon_right arrow_right_active cs-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
