<template>
  <div>
    <b-tabs
      nav-class="analytics-tabs"
      content-class="mt-3 analytics-tabs-content"
    >
      <div v-for="(account, key) in all_accounts" :key="key">
        <b-tab :active="key === 'overall'" lazy>
          <template slot="title">
            {{ capitalizeFirstLetter(key) }}
            <b-badge variant="light">{{ account.length }}</b-badge>
          </template>
          <div v-if="account.length < 1">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p>No data found.</p>
            </div>
          </div>
          <div
            v-else
            class="row"
            :class="{ 'analytics-performance-report': type === 'report' }"
          >
            <template v-for="(acc, i) in account">
              <template v-if="i < show_more_limit">
                <div :class="account.length > 2 ? 'col-4' : 'col-6'" :key="i">
                  <AnalyticsPerformanceBox :account="acc" />
                </div>
              </template>
            </template>
            <template v-if="type !== 'report'">
              <div class="col-12 d-flex justify_center mt-3">
                <button
                  v-if="!(active_account_limit >= account.length)"
                  class="btn btn-studio-theme-grey-space btn-size-small"
                  @click.prevent="show_more = !show_more"
                >
                  {{
                    show_more_limit === active_account_limit
                      ? 'Show More'
                      : 'Show Less'
                  }}
                </button>
              </div>
            </template>
          </div>
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

import AnalyticsPerformanceBox from '@src/modules/analytics/views/overview/components/AnalyticsPerformanceBox'

export default {
  mixins: [analyticsUtilsMixin],
  name: 'AnalyticsAccountPerformance',
  props: ['account_performance', 'accounts', 'type'],
  data () {
    return {
      show_more: false,
      active_account_limit: 6,
      show_more_limit: 6,
      overall: [],
      all_accounts: {
        overall: [],
        facebook: [],
        linkedin: [],
        twitter: [],
        instagram: [],
        pinterest: []
      }
    }
  },
  mounted () {
    if (this.type === 'report') {
      this.active_account_limit = this.accounts.length
    }
    this.show_more_limit = this.active_account_limit
    this.getOverallAccounts()
  },
  components: {
    AnalyticsPerformanceBox
  },
  computed: {
    ...mapGetters([
      'getFacebookPagesList',
      'getInstagramAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestBoards'
    ])
  },
  methods: {
    getOverallAccounts () {
      let types = ['overall', 'facebook', 'instagram', 'linkedin', 'twitter', 'pinterest']
      if (this.type === 'report') {
        types = ['overall']
        this.all_accounts = {
          overall: []
        }
      }
      for (const type in types) {
        for (const account in this.accounts) {
          if (
            types[type] === 'overall' ||
            this.accounts[account].account_type === types[type]
          ) {
            const field =
              this.accounts[account][
                this.accounts[account].account_type + '_id'
              ]
            const response = this.findAccount(field)

            this.accounts[account].comments = response
              ? response.comments.value
              : 0
            this.accounts[account].reposts = response
              ? response.reposts.value
              : 0
            this.accounts[account].reactions = response
              ? response.reactions.value
              : 0
            this.accounts[account].total_engagement = response
              ? response.total_engagement.value
              : 0
            this.all_accounts[types[type]].push(this.accounts[account])
          }
        }
      }
    },
    findAccount (id) {
      if (this.account_performance) {
        return this.account_performance.find(
          (x) => String(x.key) === String(id)
        )
      }
    },
    getWidth (value, total) {
      if (total === 0) {
        return 0
      }
      return (value / total) * 100
    }
  },
  watch: {
    show_more (newVal, oldVal) {
      if (newVal) {
        this.show_more_limit = this.accounts.length
      } else {
        this.show_more_limit = this.active_account_limit
      }
    }
  }
}
</script>
