<script>
import { mapGetters } from 'vuex'
import { integrations } from '../../store/states/mutation-types'

export default {
  computed: {
    ...mapGetters(['getFeedly']),
    saveFeedlyTopicLoaderStatus () {
      return this.save_feedly_topic_loader
    }
  },
  data () {
    return {
      save_feedly_topic_loader: false
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('import_feedly')
    },

    checkTopicValidForCreate (topic) {
      let valid = false
      topic.feeds.forEach(function (element) {
        if (element.status == true) {
          valid = true
          return valid
        }
      })
      return valid
    },

    setTopicAll (status, index) {
      const topics = this.getFeedly.topics
      const requested_topic = topics[index]
      requested_topic.status = status

      let feed_index = 0
      requested_topic.feeds.forEach(function (element) {
        requested_topic.feeds[feed_index].status = status
        feed_index++
      })
      topics[index] = requested_topic
      this.$store.commit(integrations.SET_FEEDLY_TOPICS, topics)
    },

    async saveFeedlyTopics () {
      this.save_feedly_topic_loader = true
      const payload = {
        topics: this.getFeedly.topics,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }
      const response = await this.$store.dispatch('saveFeedlyTopics', payload)
      if (response) {
        this.$bvModal.hide('import_feedly')
      }
      this.save_feedly_topic_loader = false
    }
  }
}
</script>

<template>
  <b-modal
    id="import_feedly"
    modal-class=" normal_modal import_feedly"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Import Feeds</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="hideModal()"
        >&times;</button
      >
    </div>

    <div class="modal_body basic_form m_t_15">
      <div class="clearfix"></div>
      <div class="permissions_option_panel">
        <p class="gray_text">
          Share your blog post on social networks. This helps in extending your
          reach and getting traffic to your blog post. Sharing it multiple times
          allows your post to reach a large number of fans/followers.
        </p>
        <div
          class="panel-group"
          id="feedly_accordion"
          data-collapse-type="manual"
        >
          <div
            class="panel panel-default"
            v-for="(topic, index) in getFeedly.topics"
          >
            <div class="panel-heading feed_main">
              <div class="panel-title d-flex align-items-center">
                <div class="flex_left d-flex align-items-center">
                  <span
                    data-toggle="collapse"
                    :data-target="'#feed_id' + index"
                    class="dropdown_arrow"
                  >
                    <i class="cs-angle-down"></i>
                  </span>
                  <div class="profile_picture ml-4">
                    <div class="text_block">
                      <p class="text">FEED</p>
                      <p class="sub_text">{{ topic.label }}</p>
                    </div>
                  </div>
                </div>
                <div class="flex_right ml-auto">
                  <i
                    style="color: #ff5722"
                    v-if="!checkTopicValidForCreate(topic)"
                    class="fa fa-times custom_tooltip"
                  >
                    <div class="tool_tip_box import-feed-error">
                      <div class="inner_box">
                        You have not selected any feed yet, your custom topic
                        will not be created if you do not select any feed.
                      </div>
                    </div>
                  </i>
                  <div class="checkbox_input_image" v-else>
                    <input
                      type="checkbox"
                      v-model="topic.status"
                      :id="topic.id"
                      @change="setTopicAll(topic.status, index)"
                    />
                    <label
                      :for="topic.id"
                      class="no_text checkbox_left"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div :id="'feed_id' + index" class="panel-collapse feed_child">
              <div class="panel-body">
                <div class="section">
                  <div class="items" v-for="feed in topic.feeds">
                    <div class="option_row d-flex align-items-center">
                      <div class="row_left">
                        <div class="profile_picture">
                          <div class="picture_block">
                            <img
                              v-if="feed.feed_data && feed.feed_data.iconUrl"
                              :src="feed.feed_data.iconUrl"
                              alt=""
                            />
                            <img
                              v-else
                              src="../../../../assets/img/profile_default.svg"
                              alt=""
                            />
                          </div>
                          <div class="text_block">
                            <p class="title">{{
                              limitTextLength(feed.feed_data.title, 80)
                            }}</p>
                            <p class="url"
                              >Web URL :
                              <a href="">{{
                                limitTextLength(feed.feed_data.website, 80)
                              }}</a></p
                            >
                            <p class="url feed_url"
                              >Feed URL :
                              <a href="">{{
                                limitTextLength(feed.feed, 80)
                              }}</a></p
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row_right">
                        <div class="checkbox_input_image">
                          <input
                            v-model="feed.status"
                            :id="topic.id + feed.feed_data.id"
                            type="checkbox"
                          />
                          <label
                            :for="topic.id + feed.feed_data.id"
                            class="no_text checkbox_left"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-md-12 col-sm-12 input_field">
        <button
          class="btn blue_gradient large_btn btn_loader loader_right"
          :disabled="saveFeedlyTopicLoaderStatus"
          @click.prevent="saveFeedlyTopics()"
        >
          <span>Save</span>
          <clip-loader
            :color="'#ffffff'"
            :size="'16px'"
            v-if="saveFeedlyTopicLoaderStatus"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.import-feed-error {
  padding: 1.125rem 8px;
  font-weight: 400;
  text-align: center;
  color: #ff0000;
  margin: 4px;
  width: 250px;
  background: #ffffff;
}
</style>
