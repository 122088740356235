export const planner = {
  SET_ITEMS: 'SET_PLANS_ITEMS',
  SET_CALENDAR_DATE_RANGE: 'SET_CALENDAR_DATE_RANGE',
  SET_PAGE: 'SET_PLANS_PAGE',
  SET_TYPE: 'SET_PLANS_TYPE',
  SET_CHECK_ALL_STATUSES: 'SET_PLANS_CHECK_ALL_STATUSES',
  SET_DATE_RANGE: 'SET_PLANS_DATE_RANGE',
  SET_ORDER: 'SET_PLANS_ORDER',
  SET_TOTAL_ITEMS: 'SET_PLANS_TOTAL_ITEMS',
  SET_CHECK_ALL: 'SET_PLANS_CHECK_ALL',
  SET_CHECK_ALL_ITEMS: 'SET_PLANS_CHECK_ALL_ITEMS',
  SET_SELECTED_PLANS: 'SET_SELECTED_PLANS',
  SET_CONTENT_CATEGORIES: 'SET_PLANS_CONTENT_CATEGORIES',
  SET_LABELS: 'SET_PLANS_LABELS',
  SET_COUNT: 'SET_PLANS_COUNT',
  SET_ALL_AUTOMATIONS_SEARCH: 'SET_ALL_AUTOMATIONS_SEARCH',
  SET_AUTOMATIONS_SELECT_ALL_STATUS: 'SET_PLANS_AUTOMATIONS_SELECT_ALL_STATUS',
  SET_SELECTED_AUTOMATIONS: 'SET_PLANS_SELECTED_AUTOMATIONS',
  SET_FETCH_AUTOMATIONS_STATUS: 'SET_PLANS_FETCH_AUTOMATIONS_STATUS',
  SET_ALL_ARTICLE_AUTOMATIONS: 'SET_ALL_ARTICLE_AUTOMATIONS',
  SET_ALL_VIDEO_AUTOMATIONS: 'SET_ALL_VIDEO_AUTOMATIONS',
  SET_ALL_EVERGREEN_AUTOMATIONS: 'SET_ALL_EVERGREEN_AUTOMATIONS',
  SET_ALL_RSS_AUTOMATIONS: 'SET_ALL_RSS_AUTOMATIONS',
  ADD_TO_ALL_RSS_AUTOMATIONS: 'ADD_TO_ALL_RSS_AUTOMATIONS',
  UPDATE_TO_ALL_RSS_AUTOMATIONS: 'UPDATE_TO_ALL_RSS_AUTOMATIONS',
  SET_CONFIRMATION_PLAN: 'SET_CONFIRMATION_PLAN',
  SET_DEFAULT_LOADER: 'SET_DEFAULT_PLANNER_LOADER',
  SET_FETCH_AUTOMATIONS_LOADER: 'SET_PLANS_FETCH_AUTOMATIONS_LOADER',
  SET_FETCH_PLANS_LOADER: 'SET_FETCH_PLANS_LOADER',
  SET_PAGINATION_SCROLL: 'SET_PLANS_PAGINATION_SCROLL',
  SET_EDIT_PLAN_LOADER: 'SET_EDIT_PLAN_LOADER',
  SET_CHANGE_PLAN_STATUS_LOADER: 'SET_CHANGE_PLAN_STATUS_LOADER',
  SET_RESCHEDULE_PLAN_LOADER: 'SET_RESCHEDULE_PLAN_LOADER',
  SET_CHECK_ALL_ASSIGNED_MEMBERS: 'SET_CHECK_ALL_ASSIGNED_MEMBERS',
  SET_CHECK_ALL_CREATED_MEMBERS: 'SET_CHECK_ALL_CREATED_MEMBERS',
  SET_CHECK_ALL_LABELS: 'SET_CHECK_ALL_LABELS',
  SET_CHECK_ALL_CAMPAIGNS: 'SET_CHECK_ALL_CAMPAIGNS',
  SET_CHECK_ALL_CATEGORIES: 'SET_CHECK_ALL_CATEGORIES',

  // save Filters
  SET_PLANNER_ACTIVE_FILTER: 'SET_PLANNER_ACTIVE_FILTER'
}
