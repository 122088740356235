var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center px-3 py-2"},[_c('img',{staticClass:"rounded-full object-cover border border-solid border-black",attrs:{"width":"40","height":"40","src":_vm.state.activeInboxData.inbox_details.posted_by.image
          ? _vm.state.activeInboxData.inbox_details.posted_by.image
          : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',"alt":""},on:{"error":function($event){$event.target.src = _vm.fallbackImagePreview(
          _vm.commonMethod
            .getPostedByName(
              _vm.state.activeInboxData.inbox_details.posted_by
                ? _vm.state.activeInboxData.inbox_details.posted_by
                : ''
            )[0]
            .toUpperCase(),
          '63A3F2',
          'white'
        )}}}),_c('div',{staticClass:"px-2 font-bold"},[_vm._v(" "+_vm._s(_vm.state.activeInboxData.inbox_details.posted_by.name)+" "),(_vm.state.activeInboxData.inbox_details.posted_by.headline)?_c('span',{staticClass:"block font-normal text-xs text-gray-800"},[_vm._v(" "+_vm._s(_vm.state.activeInboxData.inbox_details.posted_by.headline)+" ")]):_vm._e(),_c('span',{staticClass:"block font-normal text-xs text-gray-800"},[(_vm.state.activeInboxData.created_at)?_c('span',[_vm._v(_vm._s(_vm._f("publishedOnlyDateWithWeekdayAndMonth")(_vm.getWorkspaceTimeZoneTime( _vm.state.activeInboxData.created_at, 'YYYY-MM-DD HH:mm:ss' ))))]):_c('span',[_vm._v(_vm._s(_vm.getWorkspaceTimezonCurrentTime('D MMM YYYY'))+".")])])])]),(_vm.getDescription)?_c('div',{staticClass:"items-center px-3 py-2"},[_c('span',{staticClass:"whitespace-pre-wrap select-none",domProps:{"innerHTML":_vm._s(_vm.getDescription)}}),(_vm.getDescription.length > _vm.state.char && _vm.state.load)?_c('span',{staticClass:"text-blue-300 cursor-pointer",on:{"click":function($event){return _vm.changeLoadMore(false)}}},[_vm._v(" Show More")]):_vm._e(),(_vm.getDescription.length > _vm.state.char && !_vm.state.load)?_c('span',{staticClass:"text-blue-300 cursor-pointer ml-1",on:{"click":function($event){return _vm.changeLoadMore(true)}}},[_vm._v(" Show Less")]):_vm._e()]):_vm._e(),(
      _vm.state.activeInboxData.element_details.post_attachment !== null &&
      _vm.state.activeInboxData.element_details.post_attachment.length > 0 &&
      _vm.state.activeInboxData.element_details.post_attachment[0] !== null
    )?[(
        _vm.state.activeInboxData.element_details.post_attachment[0]['type'] ===
        'images'
      )?_c('div',{staticClass:"flex flex-wrap relative overflow-hidden"},[_vm._l((_vm.state.activeInboxData.element_details
          .post_attachment),function(image,index){return [(index <= 4)?_c('div',{key:index,staticClass:"flex-img bg-cover bg-no-repeat bg-center h-[14rem]",class:{
            'img-five':
              _vm.state.activeInboxData.element_details.post_attachment.length >=
                5 && index > 1,
          },style:({ 'background-image': 'url(' + image.url + ')' })},[(
              _vm.state.activeInboxData.element_details.post_attachment.length >
                5 && index === 4
            )?_c('span',{staticClass:"block w-full h-full",staticStyle:{"background-color":"rgba(0, 0, 0, 0.4)"}}):_vm._e(),_c('img',{staticClass:"max-w-full max-h-full w-full opacity-0",attrs:{"src":image.url,"alt":""},on:{"error":(event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }}})]):_c('div',{key:index,staticClass:"absolute p-2 text-3xl font-semibold text-white",staticStyle:{"transform":"translate(-50%, -50%)","right":"9%","bottom":"13%"}},[_vm._v("+"+_vm._s(_vm.state.activeInboxData.element_details.post_attachment.length - 5))])]})],2):_vm._e(),(
        _vm.state.activeInboxData.element_details.post_attachment.length === 1 &&
        _vm.state.activeInboxData.element_details.post_attachment[0]['type'] !==
          'images'
      )?[(
          _vm.state.activeInboxData.element_details.post_attachment[0]['type'] ===
          'article'
        )?_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"link_bg"},[(
              _vm.state.activeInboxData.element_details.post_attachment[0].url
            )?_c('div',{staticClass:"flex-img bg-cover bg-no-repeat bg-center h-[22rem]",style:({
              'background-image':
                'url(' +
                _vm.state.activeInboxData.element_details.post_attachment[0].url +
                ')',
            })},[_c('img',{staticClass:"max-w-full max-h-full w-full opacity-0",attrs:{"src":_vm.state.activeInboxData.element_details.post_attachment[0].url,"alt":""},on:{"error":(event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }}})]):_vm._e(),_c('div',{staticClass:"content mt-0.5 px-2"},[_c('a',{staticClass:"font-bold text-lg !text-black-900",attrs:{"target":"_blank","href":_vm.state.activeInboxData.element_details.post_attachment[0]
                  .source}},[_vm._v(_vm._s(_vm.state.activeInboxData.element_details.post_attachment[0].title)+" ")]),_c('p',{staticClass:"text-sm mt-1"},[_vm._v(_vm._s(_vm.state.activeInboxData.element_details.post_attachment[0] .description))])])])]):_vm._e(),(
          _vm.state.activeInboxData.element_details.post_attachment[0]['type'] ===
            'videos' &&
          _vm.checkForValidVideo(
            _vm.state.activeInboxData.element_details.post_attachment[0].url
          )
        )?_c('div',{staticClass:"_video"},[_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.state.activeInboxData.element_details.post_attachment[0].url,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.state.activeInboxData.element_details.post_attachment[0].url,"type":"video/ogg"}}),_vm._v(" Your browser does not support HTML5 video. ")])]):_vm._e()]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }