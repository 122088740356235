<script>
import { mapGetters } from 'vuex'
import ListItem from './ListItem'

export default {
  components: {
    ListItem
  },
  computed: {
    ...mapGetters(['getPlans']),
    selectedPlansDates () {
      const dates = []
      this.getPlans.items.forEach((plan) => {
        if (plan.execution_time) {
          let date = this.getWorkspaceTimeZoneTime(
            plan.execution_time.date,
            'YYYY-MM-DD HH:mm:ss'
          )
          date = date.substr(0, 10)
          if (dates.indexOf(date) === -1) dates.push(date)
        }
      })
      return dates
    }
  },
  methods: {
    hasExecutionTime (item) {
      return item.execution_time && item.execution_time.date
    },
    checkPlanDateInclusion (day, date) {
      date = this.getWorkspaceTimeZoneTime(date, 'YYYY-MM-DD HH:mm:ss')
      date = date.substr(0, 10)
      return date === day
    }
  }
}
</script>
<template>
  <div>
    <div class="item_box" v-for="(date, key) in selectedPlansDates" :key="key">
      <div class="item_box_inner">
        <div class="top_date">
          <h3>{{ date | publishedOnlyDateWithShortWeekdayAndMonth }}</h3>
        </div>

        <template v-for="(item, index) in getPlans.items">
          <template v-if="hasExecutionTime(item)">
            <template
              v-if="checkPlanDateInclusion(date, item.execution_time.date)"
            >
              <transition name="fade-list-item">
                <list-item :item="item" :index="index"></list-item>
              </transition>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
