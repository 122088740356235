/* eslint-disable */
<template>
  <div class="setting_publishing_queue_component select-none">
    <div class="component_inner">
      <div class="ps_box">
        <div class="px-4 py-0 xl:flex md:h-full xl:pr-0 md:block">
          <div
            class="category_list pt-2 xl:w-[23%] xl:max-w-[284px] lg:w-full md:w-full lg:h-full md:mb-5"
          >
            <template v-if="getContentCategoryLoaders.retrieve_categories">
              <beat-loader :color="'#436aff'" class="p-4"></beat-loader>
            </template>
            <div
              v-else-if="getContentCategoryList.length === 0"
              class="p-4 pt-5 text-center flex flex-col justify-center items-center"
            >
              <span class="my-5">
                You have not created any category yet, please create one
                first.</span
              >
              <button
                class="btn btn-studio-theme-space w-full"
                data-cy="add-category"
                @click="addCategoryModal"
              >
                <span class="add_icon_white w-full">
                  <span class="icon"></span>
                  <span>Add New Category</span>
                </span>
              </button>
            </div>
            <template v-else>
              <div class="relative">
                <div
                  class="d-flex align-items-center p-2 border-solid border-b-[1px] border-b-[#989eb5] border-opacity-10 border-r-0 border-l-0 border-t-0"
                >
                  <h2
                    class="flex items-baseline xl:text-[18px] font-medium text-[#3a4557] responsive-heading sm:text-base"
                    >Categories
                    <span class="text-xs ml-2.5"
                      ><a
                        href="https://www.youtube.com/watch?v=WjQuov7MhoY&t=135s"
                        target="_blank"
                        class="hover:text-[#3395ff]"
                        >Watch Video</a
                      >
                      |
                      <a
                        href="https://docs.contentstudio.io/article/595-how-to-create-content-categories"
                        target="_blank"
                        class="hover:text-[#3395ff]"
                        >Read Help doc</a
                      ></span
                    ></h2
                  >
                </div>
                <div
                  class="flex justify-between items-baseline w-full p-[0.725rem] mt-2"
                >
                  <button
                    class="btn btn-studio-theme-space py-[0.4rem] px-[0.5rem]"
                    data-cy="add-category"
                    @click="addCategoryModal"
                  >
                    <span class="add_icon_white">
                      <span class="icon"></span>
                      <span>Add New Category</span>
                    </span>
                  </button>
                  <span
                    class="text-[0.85rem] ml-0.5 text-[#007bff] cursor-pointer font-medium underline hover:text-[#3395ff]"
                    @click="showAll"
                    >View All</span
                  >
                </div>

                <div class="lg:max-h-[76vh] sm:max-h-[56vh] overflow-y-auto">
                  <div
                    v-for="(category, index) in getSortedContentCategoriesList"
                    :key="`sorted_content_category_list_${index}`"
                    class="category_item w-full xl:w-full lg:w-1/4 md:w-1/3 sm:w-full last:mb-4"
                    data-cy="existing-categories"
                  >
                    <div
                      class="c_inner sm:min-h-[124px]"
                      :class="[
                        'category_border_' + category.color,
                        active_category_id === category._id
                          ? `box_shadow_${category.color}`
                          : '',
                        {
                          active_category: active_category_id === category._id,
                        },
                      ]"
                    >
                      <div class="d-flex align-items-center">
                        <div
                          class="flex overflow-hidden text-truncate max-w-[75%]"
                        >
                          <span
                            v-tooltip="category.name"
                            class="text heading w-full truncate block"
                            >{{ category.name }}</span
                          >
                          <span
                            v-if="category.category_state === 'global'"
                            class="ml-auto"
                          >
                            <i
                              v-tooltip="'This is a Global Category'"
                              class="far fa-crown text-google font-black"
                            ></i>
                          </span>
                        </div>
                        <span
                          class="remove_icon ml-auto icons content_categories_actions text-right"
                        >
                          <template
                            v-if="
                              category.category_state === 'global' &&
                              isEnableGlobalCategoryOption
                            "
                          >
                            <span
                              v-tooltip="'Edit Category'"
                              class="category_icon icon-edit-cs"
                              @click="editCategoryModal(category)"
                            ></span>

                            <span
                              v-tooltip="'Remove Category'"
                              class="reddit icon-delete-cs"
                              @click="removeGlobalCategoryModal(category)"
                            ></span>
                          </template>

                          <template
                            v-else-if="category.category_state === 'local'"
                          >
                            <span
                              v-tooltip="'Edit Category'"
                              class="category_icon icon-edit-cs"
                              @click="editCategoryModal(category)"
                            ></span>

                            <span
                              v-tooltip="'Remove Category'"
                              class="reddit icon-delete-cs"
                              data-cy="remove-category"
                              @click="removeCategoryModal(category._id)"
                            ></span>
                          </template>
                        </span>
                      </div>
                      <div class="flex justify-content-center mt-2">
                        <template
                          v-for="(account, idx) in limitSlotsAccounts(category)"
                        >
                          <div
                            v-if="
                              idx <= 5 && channelItem(account.id, account.type)
                            "
                            :key="`channels_${idx}`"
                            class="user_box"
                          >
                            <div
                              v-tooltip="{
                                content: channelTooltipHTML(
                                  account.type,
                                  channelItem(account.id, account.type)
                                ),
                                classes: 'team_tooltip',
                              }"
                              class="d-inline-block mr-3"
                            >
                              <individual-channel-tooltip
                                :account="channelItem(account.id, account.type)"
                                :type="account.type"
                                :show-icon="false"
                              >
                              </individual-channel-tooltip>
                            </div>
                          </div>
                        </template>
                        <div
                          v-if="limitSlotsAccounts(category).length > 6"
                          class="user_box relative"
                        >
                          <p class="number"
                            >+{{ limitSlotsAccounts(category).length - 6 }}</p
                          >
                        </div>
                      </div>
                      <div class="mt-2 d-flex align-items-center">
                        <div class="flex">
                          <span class="text flex sub-text text-right"
                            >Total Slots:
                            <b class="ml-1">{{ category.slots_count }}</b></span
                          >
                          <span
                            class="category_icon_text blue_txt text sub-text"
                            @click="
                              onlyActiveCategory(category._id, category.name)
                            "
                            >View</span
                          >
                          <span class="text sub-text">|</span>
                          <span
                            class="category_icon_text remove text sub-text"
                            @click="deleteCategorySlotsModal(category)"
                            >Remove</span
                          >
                        </div>
                      </div>

                      <div class="d-flex align-items-center mt-2">
                        <div class="d-flex align-items-center">
                          <span class="text sub-text"
                            >Posts in Category:
                            <b>{{ category.posts_count }}</b></span
                          >
                          <span
                            v-if="category.posts_count === 0"
                            v-tooltip="'No posts to get shuffled'"
                            class="opacity-60 pl-2 sub-text"
                            >Shuffle</span
                          >
                          <span
                            v-else
                            class="category_icon_text blue_txt text sub-text"
                            @click="shuffleCategoryPostsModal(category._id)"
                            >Shuffle</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            id="time-grid-calendar"
            class="w-full lg:h-full lg:max-h-[87vh] sm:max-h-[56vh] overflow-auto !overflow-x-hidden px-2 pb-4 mt-4 mb-0 sm:mb-4"
          >
            <TimeGridCalendar
              :get-sorted-content-categories-list="
                getSortedContentCategoriesList
              "
              :slots="getSlots"
              :limit-slots-accounts="limitSlotsAccounts"
              :remove-slot-modal="removeSlotModal"
              :edit-slot-modal="editSlotModal"
              :add-slot-modal="addSlotModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import { swalAttributes } from '@common/constants/common-attributes'
import { EventBus } from '@common/lib/event-bus'

// constants
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'

// components
import { TimeGridCalendar } from '.'

export default {
  components: { TimeGridCalendar },
  data() {
    return {
      currentCategoryName: '',
      category_id: null,
      slot: {
        slotId: null,
        weekday: null,
        index: null,
        categoryId: null,
      },
      active_category_id: null,
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategoryList',
      'getContentCategorySlots',
      'getActiveWorkspace',
      'getContentCategoryLoaders',
    ]),
    getSortedContentCategoriesList() {
      return sortBy(this.getContentCategoryList, function (item) {
        return [item.name?.toLowerCase()]
      })
    },

    /**
     * return list of all slots into single array
     * @returns {*[]}
     */
    getSlots() {
      let arr = []
      // we are converting multiple array against keys of object into single array
      // eslint-disable-next-line no-unused-expressions
      Object.values(this.getContentCategorySlots)?.forEach((item) => {
        arr = [...arr, ...item]
      })
      // appending index key with starting time of event
      arr.forEach((item, idx) => {
        item.idx = idx
        item.className = 'test'
      })
      // sorting array in ascending order
      arr.sort((a, b) => a.minute - b.minute)

      if (this.active_category_id) {
        arr = arr.filter((item) => item.category_id === this.active_category_id)
      }
      return arr
    },
  },
  watch: {
    'getActiveWorkspace._id'() {
      this.showAll()
    },
  },

  created() {},
  mounted() {
    this.$store.dispatch('fetchContentCategories') // removing due to duplicate calling
    this.$store.dispatch('fetchContentCategoriesSlots')
  },
  methods: {
    getCategoryName(name) {
      this.currentCategoryName = `<span>${name}</span> category`
    },
    removeCategoryModal(categoryId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this category?', {
          title: 'Remove Category',
          ...swalAttributes(),
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('deleteCategory', categoryId)
          }
        })
        .catch()
    },
    removeGlobalCategoryModal(category) {
      EventBus.$emit('remove-global-content-category', {
        categoryId: category._id,
        globalContentCategoryId: category.global_content_category_id,
      })
      this.$bvModal.show('remove_global_category_confirmation')
    },
    addCategoryModal(info) {
      this.$store.commit(contentCategoriesTypes.RESET_CONTENT_CATEGORY_ADD)
      this.$bvModal.show('add_category')
    },
    editCategoryModal(category) {
      this.$store.commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_VALUE,
        category
      )
      this.$bvModal.show('add_category')
    },
    shuffleCategoryPostsModal(categoryId) {
      this.$bvModal
        .msgBoxConfirm(
          'By performing this action, your existing scheduled posts belonging to this category will be shuffled.',
          {
            title: 'Shuffle Posts',
            ...swalAttributes(),
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch('shuffleCategory', categoryId)
          }
        })
        .catch()
    },
    deleteCategorySlotsModal(category) {
      const message =
        category.category_state === 'local'
          ? 'By performing this action, your existing category slots will be removed.'
          : 'By performing this action, your global category slots will be removed from all workspaces.'
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Shuffle Posts',
          ...swalAttributes(),
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('deleteAllCategorySlots', category._id)
          }
        })
        .catch()
    },

    limitSlotsAccounts(accounts) {
      const accountsList = []
      if (accounts) {
        const socialChannels = [
          'facebook',
          'twitter',
          'pinterest',
          'linkedin',
          'gmb',
          'instagram',
        ]
        socialChannels.forEach((social) => {
          if (typeof accounts[social] !== 'undefined' && accounts[social]) {
            accounts[social].forEach((item) => {
              accountsList.push({
                id: item,
                type: social,
              })
            })
          }
        })
      }
      return accountsList
    },
    addSlotModal(selectInfo) {
      this.$store.commit(contentCategoriesTypes.SELECT_CATEGORY, selectInfo)
      this.$bvModal.show('add_slot')
    },
    onlyActiveCategory(categoryId = null, categoryName = null) {
      if (!categoryId) {
        this.currentCategoryName = 'All'
        this.active_category_id = null
      } else {
        this.active_category_id = categoryId
        this.currentCategoryName = categoryName
      }
    },
    /**
     * show all events irrespective of their categories
     */
    showAll() {
      this.currentCategoryName = 'All'
      this.active_category_id = null
    },
    async removeSlotModal(slotPayload) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this slot?', {
          title: 'Remove Slot',
          ...swalAttributes(),
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('deleteCategorySlot', slotPayload)
          }
        })
        .catch()
    },
    editSlotModal(slot) {
      this.$store.commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_SLOT,
        slot
      )
      this.$bvModal.show('add_slot')
    },
  },
}
</script>

<style lang="less">
.category_item {
  .c_inner {
    span {
      padding-left: 5px !important;
    }
  }
  .selected {
    box-shadow: 0px 0px 5px 2px rgba(250, 106, 182, 0.75);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(250, 106, 182, 0.75);
    -moz-box-shadow: 0px 0px 5px 2px rgba(250, 106, 182, 0.75);
  }
}
.global_category_crown {
  color: #989eb5 !important;
}

/*writing custom media queries to handle responsiveness at laptop screen  */
@media screen and (min-width: 1280px) and (max-width: 1600px) {
  //css code
  .setting_publishing_queue_component {
    .category_list {
      width: 30% !important;
    }
    .ps_box .category_list .category_item .c_inner .sub-text,
    .setting_publishing_queue_component
      .ps_box
      .category_list
      .category_item
      .c_inner
      .sub-text {
      font-size: 0.75rem;
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .responsive-heading {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 1180px) {
  .category_item .c_inner span {
    padding-left: 4px !important;
  }

  .ps_box .category_list .category_item .c_inner .text,
  .setting_publishing_queue_component
    .ps_box
    .category_list
    .category_item
    .c_inner
    .sub-text {
    font-size: 0.8rem !important;
  }

  .ps_box .category_list .category_item .c_inner .heading {
    font-size: 0.95rem !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1270px) {
  .setting_publishing_queue_component .category_list {
    width: 100% !important;
  }
}

@media screen and (min-width: 675px) and (max-width: 840px) {
  .ps_box .category_list .category_item {
    width: 50% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .setting_publishing_queue_component .ps_box .category_list .category_item {
    height: 9.625rem;
  }
}
@media screen and (max-width: 1280px) {
  .setting_publishing_queue_component .ps_box .category_list .category_item {
    padding: 0.5rem 0.45rem;
  }
}
</style>
