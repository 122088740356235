var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.getPosts),function(post,index){return _c('div',{key:index,staticClass:"topic_content_box _article",class:{ dragable_box: !(_vm.$route.name === 'composerBlog') }},[(post._source)?_c('div',{staticClass:"box_inner",attrs:{"draggable":_vm.$route.name === 'composerBlog'},on:{"dragstart":function($event){return _vm.dragstartHandler($event, post)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"image_block"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.backgroundImageURL(post._source)),expression:"backgroundImageURL(post._source)",arg:"background-image"}],staticClass:"img"})]),_c('div',{staticClass:"content_block"},[_c('div',{staticClass:"content_block_inner"},[_c('h3',{attrs:{"title":post._source.title},on:{"click":function($event){$event.preventDefault();return _vm.articlePreview({ id: post._source.id, index: index })}}},[_vm._v(_vm._s(_vm.limitTextLength(post._source.title, 65)))]),_c('div',{staticClass:"detail_box d-flex align-items-center"},[_c('a',{staticClass:"domain_name",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.trimURL(post._source.domain_url)))])]),_c('div',{staticClass:"detail_box d-flex align-items-center"},[_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.$filters.relative(post._source.post_date)))]),_c('p',{staticClass:"author_name"},[(post._source.author)?[_vm._v(" By "),_c('span',{domProps:{"innerHTML":_vm._s(post._source.author)}}),_vm._l((post._source.twitter_accounts),function(account,index){return (post._source.twitter_accounts && index < 1)?_c('a',{attrs:{"target":"_blank","href":_vm.getTwitterLink(account)}},[_vm._v(_vm._s(account))]):_vm._e()})]:(
                    post._source.twitter_accounts &&
                    post._source.twitter_accounts.length > 0
                  )?[_vm._v(" By "),_vm._l((post._source.twitter_accounts),function(account,index){return (post._source.twitter_accounts && index < 1)?_c('a',{attrs:{"target":"_blank","href":_vm.getTwitterLink(account)}},[_vm._v(_vm._s(account))]):_vm._e()})]:_vm._e()],2)])])])]),_c('div',{staticClass:"footer clear"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Engagement'),expression:"'Engagement'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[_c('i',{staticClass:"icon-engagement-ico"}),(post._source)?_c('span',{staticClass:"state_txt"},[_vm._v(_vm._s(_vm.intToString(_vm.calculateEngagements(post._source))))]):_c('span',{staticClass:"state_txt"},[_vm._v("0")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Sentiment'),expression:"'Sentiment'",modifiers:{"top-center":true}}],staticClass:"social_detail"},[(post._source.sentiment && post._source.sentiment.length > 0)?[(post._source.sentiment[0].label == 'negative')?_c('i',{staticClass:"cs-sad-face sad"}):_vm._e(),(
                post._source.sentiment[0].label == 'positive' &&
                post._source.sentiment[0].probability >= 0.65
              )?_c('i',{staticClass:"cs-happy-face smile"}):(
                post._source.sentiment[0].label == 'positive' &&
                post._source.sentiment[0].probability < 0.65
              )?_c('i',{staticClass:"cs-normal-face normal"}):_vm._e(),(post._source.sentiment[0].label == 'neutral')?_c('i',{staticClass:"cs-normal-face normal"}):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"hide d-none editor_dragable_box editor_article_block",attrs:{"contenteditable":"false"}},[_vm._m(0,true),_c('div',{staticClass:"editor_dragable_box_inner clear"},[_c('h2',[_vm._v(_vm._s(_vm.limitTextLength(post._source.title, 0)))]),_c('p',[_c('img',{staticStyle:{"width":"225px","float":"left","margin-left":"0.625rem","margin-right":"0.625rem","margin-top":"5px","margin-bottom":"5px"},attrs:{"src":_vm.backgroundImageURL(post._source),"alt":""}})]),(post._source.description)?_c('p',[_vm._v(_vm._s(_vm.limitTextLength(_vm.removeHtmlTags(post._source.description), 200)))]):_vm._e(),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"target":"_blank","href":post._source.url}},[_vm._v("Read more")]),_c('br'),_vm._m(1,true)])])]):_vm._e()])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_option no_flickr"},[_c('div',{staticClass:"right_block"},[_c('i',{staticClass:"handler fa fa-hand-paper-o editor_tooltip",attrs:{"title":"Move"}}),_c('i',{staticClass:"edit_box fa fa-check-circle editor_tooltip green",attrs:{"title":"Merge"}}),_c('i',{staticClass:"remove_box fa fa-times-circle editor_tooltip red",attrs:{"title":"Remove"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('br')])
}]

export { render, staticRenderFns }