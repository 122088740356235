<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-2">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-violet"
      >
        <img
          src="@assets/img/integration/tiktok-icon.svg"
          alt=" "
          class="w-5"
        />
      </div>
    </div>
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.platform_logo"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-3 font-extrabold text-black">
        {{ account.platform_name }}
      </div>
      <span
        class="ml-auto font-normal border-pink-500 text-pink-600 px-2"
        style="border: 1px solid rgb(254, 44, 85)"
        >Follow</span
      >
    </div>
    <div class="flex w-full px-3 pb-2">
      <div class="overflow-hidden">
        <video
          class="w-full rounded-lg preview__tiktok-video"
          :poster="
            detail.video.thumbnail
              ? detail.video.thumbnail
              : '@src/assets/img/no_data_images/no_media_found.svg'
          "
          controls
        >
          <source :src="detail.video.link" type="video/mp4" />
          <source :src="detail.video.link" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="mt-auto pl-4 text-center">
        <div
          class="flex rounded-full justify-center items-center text-black text-lg font-normal bg-gray-200 my-2 w-12 h-12"
        >
          <i class="fas fa-heart"></i> </div
        ><span class="text-xs font-bold text-gray-800">355.5K</span>
        <div
          class="flex rounded-full justify-center items-center text-black text-lg font-normal bg-gray-200 my-2 w-12 h-12"
        >
          <i class="fas fa-comment-dots"></i> </div
        ><span class="text-xs font-bold text-gray-800">1455</span>
        <div
          class="flex rounded-full justify-center items-center text-black text-lg font-normal bg-gray-200 my-2 w-12 h-12"
        >
          <i class="fas fa-share"></i> </div
        ><span class="text-xs font-bold text-gray-800">55.5K</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TikTokPreview',
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.preview__tiktok-video {
  width: 100%;
  min-height: 550px;
  max-width: 350px;
  background-color: black;
}
</style>
