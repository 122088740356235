<template>
  <div class="authentication_component">
    <div class="auth_right login_block setPassword_block">
      <button type="button" class="close" @click.prevent="logout">×</button>
      <div class="d-flex align-items-center height" style="overflow: auto">
        <div class="_wrapper m-auto">
          <div class="right_logo d-block">
            <img src="../../assets/img/logo/logo_text_logo.png" alt="" />
          </div>
          <div class="auth_right_inner">
            <div class="top_block">
              <h2>Welcome! You are just one step away!</h2>
              <p>To keep your account secured and fully accessible,</p>
              <p>we'd love if you'd be up for adding password.</p>
            </div>

            <div class="line_input clear">
              <form>
                <div class="flex flex-col space-y-3 mb-3">
                  <div class="flex flex-row space-x-2">
                    <label
                      for="first-name"
                      class="flex flex-col space-y-1 w-full"
                    >
                      <div class="mb-2 font-medium"
                        >First name<span class="text-red-600 ml-1">*</span></div
                      >
                      <CstInputFields
                        size="large"
                        placeholder="e.g Carl"
                        @change="
                          (val) => {
                            firstName = val
                          }
                        "
                      ></CstInputFields>
                    </label>
                    <label
                      for="last-name"
                      class="flex flex-col space-y-1 w-full"
                    >
                      <div class="mb-2 font-medium"
                        >Last name<span class="text-red-600 ml-1">*</span></div
                      >
                      <CstInputFields
                        size="large"
                        placeholder="e.g Frederick"
                        @change="
                          (val) => {
                            lastName = val
                          }
                        "
                      ></CstInputFields>
                    </label>
                  </div>
                  <label for="email" class="flex flex-col space-y-1">
                    <div class="mb-2 font-medium"
                      >E-mail<span class="text-red-600 ml-1">*</span></div
                    >
                    <CstInputFields
                      class="!bg-gray-500 !text-gray-700"
                      type="email"
                      size="large"
                      placeholder="e.g example@domain.com"
                      :value="getEmail"
                      disabled="disabled"
                    ></CstInputFields>
                  </label>
                  <label for="password" class="flex flex-col space-y-1">
                    <div class="mb-2 font-medium"
                      >Password<span class="text-red-600 ml-1">*</span></div
                    >
                    <CstInputFields
                      type="password"
                      size="large"
                      placeholder="e.g ********"
                      @change="
                        (val) => {
                          password = val
                        }
                      "
                    ></CstInputFields>
                  </label>
                  <label for="confirm-password" class="flex flex-col space-y-1">
                    <div class="mb-2 font-medium"
                      >Confirm password<span class="text-red-600 ml-1"
                        >*</span
                      ></div
                    >
                    <CstInputFields
                      type="password"
                      size="large"
                      placeholder="e.g ********"
                      @change="
                        (val) => {
                          cpassword = val
                        }
                      "
                    ></CstInputFields>
                  </label>
                </div>
                <div class="btn_block">
                  <button
                    class="btn btn_loader loader_right"
                    :disabled="loader || disable"
                    @click.prevent="setPassword"
                  >
                    <span v-if="loader">Processing</span>
                    <span v-else>Sign in</span>
                    <img
                      v-if="loader"
                      style="width: 20px; margin-left: 8px"
                      src="../../assets/img/common/gif_loader_white.gif"
                      alt=""
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setPasswordURL } from '@src/config/api-utils'
import CstInputFields from '@ui/Input/CstInputFields'
import { mapGetters } from 'vuex'

export default {
  components: { CstInputFields },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      cpassword: '',
      loader: false,
      disable: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    getEmail() {
      return this.getProfile?.email
    },
  },
  methods: {
    async setPassword() {
      if (this.firstName === '') {
        return this.alertMessage('Please enter first name', 'error')
      }
      if (this.lastName === '') {
        return this.alertMessage('Please enter last name', 'error')
      }
      if (this.password === '') {
        return this.alertMessage('Please enter password.', 'error')
      }
      if (this.cpassword === '') {
        return this.alertMessage('Please confirm your password.', 'error')
      }
      if (this.password !== this.cpassword) {
        return this.alertMessage(
          'password and confirm password is not matched.',
          'error'
        )
      }
      this.loader = true

      this.postRequest(
        setPasswordURL,
        {
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
        },
        (response) => {
          this.loader = false
          if (response.data.status === false)
            return this.alertMessage(response.data.message, 'error')
          this.getProfile.need_to_reset_password = false
          this.fetchWorkspaces(true)
          this.disable = true
        },
        (error) => {
          console.debug('Exception in setPassword', error)
          this.loader = false
          this.alertMessage(
            'Uh-oh! An unknown error occurred, support has been notified.',
            'error'
          )
        }
      )
    },
  },
}
</script>
