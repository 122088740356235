var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.displayBillingNotification &&
    _vm.$store.getters.getBillingActionRequiredStatus
  )?_c('div',{staticClass:"top_head_slider top_head_offer_bar top_side w-max -ml-20"},[_c('div',{staticClass:"notificationCarousel pink d-flex align-items-center justify-content-between w-100"},[(_vm.showDefault)?_c('div',{staticClass:"d-flex align-items-center justify-content-between make-text-center"},[(_vm.getWorkspaceSuperAdminDetails()._id === _vm.getProfile._id)?[_vm._m(0),_c('button',{staticClass:"ml-4 btn btn_white large_btn ml-auto",staticStyle:{"background":"#ff9300","color":"#fff","margin-left":"10px !important"},on:{"click":function($event){return _vm.$router.push({ name: 'plan' })}}},[_vm._v(" View Billing Details ")])]:[_vm._m(1)]],2):_c('div',{staticClass:"d-flex align-items-center justify-content-between make-text-center"},[(_vm.getWorkspaceSuperAdminDetails()._id === _vm.getProfile._id)?[_c('div',{staticClass:"text"},[(_vm.dateRetry)?_c('p',{staticClass:"d-inline-block align-middle"},[_c('strong',[_vm._v("Action Required:")]),_vm._v(" We have tried charging your card "+_vm._s(_vm.attemptNumber)+" times now with no success. Please update your card details before "+_vm._s(_vm.dateRetry)+" to avoid account suspension. ")]):_c('p',{staticClass:"d-inline-block align-middle"},[_c('strong',[_vm._v("Action Required:")]),_vm._v(" We have tried charging your card "+_vm._s(_vm.attemptNumber)+" times now with no success, account has been set for Cancellation. ")])]),(_vm.updateUrl)?_c('button',{staticClass:"ml-4 btn btn_white large_btn ml-auto",staticStyle:{"background":"#ff9300","color":"#fff","margin-left":"10px !important"},on:{"click":function($event){return _vm.updateOrCancelButtonPress(_vm.updateUrl)}}},[_vm._v(" Update Details ")]):_vm._e()]:[_vm._m(2)]],2),_c('button',{staticClass:"close close_slider",staticStyle:{"font-size":"26px !important","color":"#fff","right":"12px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('SET_BILLING_ACTION_REQUIRED_STATUS', false)}}},[_vm._v("× ")])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"d-inline-block align-middle"},[_c('strong',[_vm._v("Action Required:")]),_vm._v(" We've been trying to charge your card several times with no success. Please update your credit card to proceed with the charge. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"d-inline-block align-middle"},[_c('strong',[_vm._v("Action Required:")]),_vm._v(" You have outstanding dues, please ask your account owner to clear the pending dues. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"d-inline-block align-middle"},[_c('strong',[_vm._v("Action Required:")]),_vm._v(" You have outstanding dues, please ask your account owner to clear the pending dues. ")])])
}]

export { render, staticRenderFns }