const AnalyticsMain = () =>
  import(/* webpackChunkName: "AnalyticsMain" */ '../../views/AnalyticsMain')

const AnalyticsOverview = () =>
  import(
    /* webpackChunkName: "AnalyticsOverview" */ '../../views/overview/AnalyticsOverview'
  )
const FacebookOverview = () =>
  import(
    /* webpackChunkName: "FacebookAnalyticsOverview" */ '../../views/facebook/FacebookMain'
  )
const TwitterOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '../../views/twitter/TwitterMain'
  )
const LinkedInOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '../../views/linkedin/LinkedInMain'
  )

export const analyticsRoutesV2 = {
  path: '/:workspace/v2/analytics',
  component: AnalyticsMain,
  name: 'analytics_v2',
  redirect: '/:workspace/v2/analytics/overview',
  meta: {
    title: 'Analytics'
  },
  children: [
    {
      path: 'overview/',
      component: AnalyticsOverview,
      name: 'analytics_overview',
      meta: {
        title: 'Analytics overview'
      }
    },
    {
      path: 'facebook/:accountId?',
      component: FacebookOverview,
      name: 'analytics_facebook_v2',
      meta: {
        title: 'Facebook Analytics overview'
      }
    },
    {
      path: 'twitter/',
      component: TwitterOverview,
      name: 'analytics_twitter',
      meta: {
        title: 'Twitter Analytics overview'
      }
    },
    {
      path: 'linkedin/',
      component: LinkedInOverview,
      name: 'analytics_linkedin',
      meta: {
        title: 'LinkedIn Analytics overview'
      }
    }
  ]
}
