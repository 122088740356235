<template>
  <div class="pagination_style_inner">
    <template v-if="showAllPages" v-for="index in total">
      <a
        href="javascript:;"
        @click.prevent="$parent.callInfluencerPagination(type, index)"
        :class="{ active: page === index }"
        >{{ index }}</a
      >
    </template>

    <template v-if="!showAllPages">
      <a
        v-if="showFirstPage"
        href="javascript:;"
        @click.prevent="$parent.callInfluencerPagination(type, 1)"
        >1</a
      >
      <a
        v-if="showDotsAfterFirstPage"
        href="javascript:;"
        class="dotted"
        @click.prevent="$parent.callInfluencerPagination(type, page - 2)"
        >...</a
      >

      <template v-for="item in getVisiblePages">
        <a
          href="javascript:;"
          :class="{ active: page === item }"
          @click.prevent="$parent.callInfluencerPagination(type, item)"
          >{{ item }}</a
        >
      </template>

      <a
        v-if="showDotsBeforeLastPage"
        href="javascript:;"
        class="dotted"
        @click.prevent="$parent.callInfluencerPagination(type, page + 2)"
        >...</a
      >
      <a
        v-if="showLastPage"
        href="javascript:;"
        @click.prevent="$parent.callInfluencerPagination(type, total)"
        >{{ total }}</a
      >
    </template>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    total: 0,
    page: 0,
    type: ''
  },
  data () {
    return {}
  },
  computed: {
    showAllPages () {
      return this.total < 6
    },
    showFirstPage () {
      return this.page > 2
    },
    showDotsAfterFirstPage () {
      return this.page > 3
    },
    showLastPage () {
      return this.page < this.total - 1
    },
    showDotsBeforeLastPage () {
      return this.showLastPage && this.page < this.total - 2
    },
    getVisiblePages () {
      let pageCutLow = this.page - 1
      let pageCutHigh = this.page + 1
      const visiblePages = []

      if (this.page === 1) pageCutHigh += 2
      else if (this.page === 2) pageCutHigh += 1

      // Determine how many pages to show before the current page index
      if (this.page === this.total) pageCutLow -= 2
      else if (this.page === this.total - 1) pageCutLow -= 1

      // Output the indexes for pages that fall inside the range of pageCutLow
      // and pageCutHigh
      for (let p = pageCutLow; p <= pageCutHigh; p++) {
        if (p === 0) p += 1
        if (p > this.total) continue
        visiblePages.push(p)
      }
      return visiblePages
    }
  }
}
</script>
