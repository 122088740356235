import { shortener } from './mutation-types'
import proxy from '@common/lib/http-common'
import {
  changeBitlyBaseURL,
  changeDefaultShortenerURL,
  saveReplugShortenerSettingsURL
} from '../../config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
const defaultBitlyAccountsState = {
  username: '',
  _id: '',
  base: '',
  domain_options: ['bit.ly', 'bitly.com', 'j.mp']
}
export default {
  state: {
    bitly: JSON.parse(JSON.stringify(defaultBitlyAccountsState))
  },
  getters: {
    getBitlyAccount: (state) => state.bitly
  },
  actions: {
    changeDefaultShortener (
      { commit, getters, rootGetters, dispatch },
      shortener
    ) {
      console.debug('Method:changeDefaultShortener')
      const active_workspace = getters.getWorkspaces.activeWorkspace
      const current_shortener = active_workspace.shortener
      active_workspace.shortener = shortener
      proxy
        .post(changeDefaultShortenerURL, {
          shortener: shortener,
          workspace_id: active_workspace._id
        })
        .then((response) => {
          if (!response.data.status) {
            active_workspace.shortener = current_shortener
            dispatch('toastNotification', {
              message: response.data.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.debug('Exception in changeDefaultShortener', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
        })
    },

    changeBitlyBase ({ commit, getters, rootGetters, dispatch }, base) {
      console.debug('Action:changeBitlyBase')
      commit(shortener.SET_BITLY_BASE, base)
      proxy
        .post(changeBitlyBaseURL, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          base: base
        })
        .then((response) => {
          if (response.data.status) {
            dispatch('toastNotification', {
              message: response.data.message,
              type: 'success'
            })
          } else {
            dispatch('toastNotification', {
              message: response.data.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.debug('Exception in changeBitlyBase', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
        })
    },

    async saveReplugShortenerSettings (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Method:saveReplugShortenerSettings')
      return proxy
        .post(saveReplugShortenerSettingsURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            getters.getReplug.item.brand = getters.getReplug.selected_brand._id
            getters.getReplug.item.cta = getters.getReplug.selected_cta._id
            return true
          }
          return false
        })
        .catch((error) => {
          console.debug('Exception in saveReplugShortenerSettings', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    setDefaultShortenerStates ({ commit }) {
      commit(shortener.SET_BITLY_ACCOUNT, null)
    }
  },
  mutations: {
    [shortener.SET_BITLY_ACCOUNT] (state, account) {
      if (account) {
        state.bitly = account
      } else {
        state.bitly = JSON.parse(JSON.stringify(defaultBitlyAccountsState))
      }
    },

    [shortener.SET_BITLY_BASE] (state, base) {
      state.bitly.base = base
    }
  }
}
