<template>
  <div class="planner-aside flex flex-col flex-grow-1 flex-shrink-0">
    <div class="flex justify-between items-center p-3 cursor-pointer">
      <h3 class="text-base font-semibold">Post Status</h3>

      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide(modalId)"
      >
        &times;
      </button>
    </div>

    <div class="planner-list-item-statuses">
      <ItemDetails
        v-if="hasAccountSelection"
        :item="{ ...item, is_active: true }"
      />
      <div v-else class="text-center my-5"> No Account Selection Found! </div>
    </div>
  </div>
</template>

<script>
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import { mapGetters } from 'vuex'
import ItemDetails from '@src/modules/planner/components/view/list/ItemDetails'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
export default {
  name: 'PlannerAside',
  components: {
    ItemDetails,
  },
  mixins: [Helpers],
  props: {
    modalId: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      integrations: blogIntegrationsNames,
    }
  },
  computed: {
    ...mapGetters(['getSocialIntegrationsNames']),

    hasAccountSelection() {
      // If blog post
      if (this.item.blog_selection) {
        for (let i = 0; i < this.integrations.length; i++) {
          if (this.hasSpecificBlogSelection(this.integrations[i])) {
            console.log('has specific blog selection', this.integrations[i])
            return true
          }
        }
      }

      // If social post
      if (!this.item.blog_selection) {
        for (let i = 0; i < this.getSocialIntegrationsNames.length; i++) {
          if (
            this.hasSpecificSocialSelection(this.getSocialIntegrationsNames[i])
          ) {
            console.log(
              'has specific social selection',
              this.getSocialIntegrationsNames[i]
            )
            return true
          }
        }
      }

      return false
    },
  },
  methods: {
    hasSpecificBlogSelection(type) {
      if (this.item.blog_selection) {
        return (
          this.item.blog_selection[type] &&
          this.item.blog_selection[type].website
        )
      }
      return false
    },
    hasSpecificSocialSelection(type) {
      if (this.item.account_selection) {
        return (
          this.item.account_selection[type] &&
          this.item.account_selection[type].length
        )
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped></style>
