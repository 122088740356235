<template>
  <div>
    <div class="compare-box">
      <div class="compare-box__header flex">
        <div class="compare-box__platform">
          <i class="fab fa-facebook-f"></i>
        </div>
        {{ title }}
        <div class="ml-auto">
          <i
            class="fa fa-info info_hover"
            v-b-popover.hover.html.topleft="desc"
          ></i>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1">
      <div :class="chartData.length > 1 ? (chartData.length > 2 ? 'col-span-4':'col-span-6') :'col-span-12'" v-for="(value, idx) in chartData" v-if="isActive" :key="idx">
        <PieInnerChart
          :title="value['name']"
          v-on:mounted="getPieRef"
          :chartRef="value['name'] + idx"
          :data="value['data']"
          :colors="color"
          :seriesName="title"
          class="flex-center-center"
          style="flex-direction: column"
          :width="270"
          :height="270"
        />
        <div class="d-flex justify_center analytics-pie-legends pie-region">
          <template
            v-for="(obj, i) in setAliasLegends(
              legends[value['name'] + idx],
              idx
            )"
          >
            <div
              class="analytics-pie-legends__detail-item"
              :key="i"
              :class="obj.y === 0 ? 'disable-legend' : ''"
              @click="legendClick($event, obj, obj.name !== 'No Data Found')"
            >
              <div class="analytics-pie-legends__detail-label v-metrics">
                <span
                  :style="{ borderColor: obj.color }"
                  class="analytics-pie-legends__detail-label-icon"
                ></span>
                {{ capitalizeFirstLetter(obj.name) }}
              </div>
              <div
                class="analytics-pie-legends__detail-value"
                v-if="obj.name !== 'No Data Found'"
              >
                {{ obj.y }}
              </div>
            </div>
          </template>
        </div>
        <hr v-if="idx + 1 !== chartData.length" />
      </div></div>
      <div
        v-if="!isActive"
        class="opacity-70"
        style="display: flex; justify-content: center"
      >
        <clip-loader
          class="ml-2"
          :color="'#5773fa'"
          :size="'20px'"
        ></clip-loader>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.disable-legend {
  pointer-events: none;
  opacity: 0.7;
  user-select: none;
}
.pie-region {
  margin: auto;
  max-width: 515px;
}
hr {
  width: 54%;
  border-color: #f0f0f0;
}
</style>
<script>
import PieInnerChart from '../common/Infographics/PieInnerChart'
import {themeColors} from "@src/theme";
export default {
  name: 'competitorPieChart',
  components: {
    PieInnerChart
  },
  props: { title: String, series: Array, chartRef: String, desc: String },
  data () {
    return {
      chartData: [],
      isActive: false,
      legends: {},
      color: [
        themeColors.blue[200],
        themeColors.green[400],
        themeColors.purple[400],
        themeColors.pink[500],
        themeColors.orange[500],
        themeColors.red[500],
      ],
    }
  },
  watch: {
    series (newValue, oldValue) {
      if (newValue.length > 0 || oldValue.length > 0) {
        console.log('watcheronthewall', newValue, oldValue)
        this.getChartData(newValue)
      }
    }
  },
  mounted () {
    this.getChartData(this.series)
  },
  methods: {
    legendClick (event, point, flag) {
      const element = event.target

      if (element) {
        element.classList.toggle('analytics-pie-legends__detail-item--fade')
      }

      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    setAliasLegends (legendList, idx) {
      if (typeof legendList === 'undefined') {
        return legendList
      }
      console.log('okaysss', legendList)
      if (legendList[0].name === 'No Data Found') {
        if (this.chartData[idx].length > 0) {
          const aliasLegends = []
          for (let j = 0; j < this.chartData[idx].length; j++) {
            aliasLegends.push({
              name: this.chartData[idx].data[j][0],
              color: this.color[j],
              y: 0
            })
          }
          legendList = aliasLegends
        }
      }
      return legendList
    },
    getChartData (data) {
      const dataAlias = []
      if (data.length > 0) {
        data.forEach((dataObj) => {
          dataAlias.push({
            data: dataObj.pieData,
            length: dataObj.pieData.length,
            name: dataObj.name
          })
        })
        this.chartData = dataAlias
        this.isActive = true
      } else {
        this.legends = {}
        this.chartData = dataAlias
        this.isActive = false
      }
    }
  }
}
</script>
