var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds_card_container engagement_follower_block"},[_c('div',{staticClass:"card_v1"},[_c('div',{staticClass:"card_inner"},[_vm._m(0),_c('div',{staticClass:"card_content",class:{ card_content_blur: _vm.isZero() }},[(
            _vm.getInsightsArticlesPublishedPostsCount.length == 0 &&
            _vm.getInsightsArticlesPublishedEngagement.length == 0
          )?[_c('div',{staticClass:"no_data_for_chart"},[_vm._v(_vm._s(_vm.noDataMessage))])]:_vm._e(),_c('div',{class:{
            blur_div:
              _vm.getInsightsArticlesPublishedPostsCount.length == 0 &&
              _vm.getInsightsArticlesPublishedEngagement.length == 0,
          }},[_c('highcharts',{ref:"highcharts_articles_published",attrs:{"options":_vm.articlesPublishedOverTime}})],1)],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_head"},[_c('h2',[_vm._v("Articles Published Over Time & Engagement")]),_c('ul',{staticClass:"ds_item_highlight",attrs:{"id":"engagement_follower_item_highlight"}})])
}]

export { render, staticRenderFns }