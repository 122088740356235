<template>
  <b-tabs
    v-model="tabIndex"
    nav-class="analytics-tabs analytics-main-tabs"
    content-class="mt-3 analytics-tabs-content  "
  >
    <b-tab title="Overview" href="#overview" lazy>
      <slot name="overview"></slot>
    </b-tab>
    <b-tab title="Demographics" href="#demographics" lazy>
      <slot name="demographics"></slot>
    </b-tab>
    <b-tab title="Posts" href="#posts" lazy>
      <slot name="posts"></slot>
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'FacebookTabs',
  data () {
    return {
      tabIndex: 0,
      tabs: ['#overview', '#demographics', '#posts']
    }
  },
  watch: {
    tabIndex (val) {
      location.hash = this.tabs[val]
    }
  },
  mounted () {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }
}
</script>

<style scoped></style>
